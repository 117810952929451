import { firebase_app } from "../data/config";

// Allow to spare API's call by saving info

export const getNoteSymbolFirebase = async (ticker) => {
  var dataNote = {};
  var valid = false;
  const currentTime = Date.now();
  await firebase_app
    .firestore()
    .collection("MA_Note")
    .doc(ticker)
    .get()
    .then((doc) => {
      if (doc.exists) {
        dataNote = doc.data();
        if (currentTime - dataNote.lastUpdate < 1 * 60 * 60 * 1000) {
          valid = true;
        }
      }
    })
    .catch(() => {});
  return { valid, data: dataNote };
};

export const saveNoteSymbol = async ({ ticker, data }) => {
  const currentTime = Date.now();
  let dataToSave = data;
  dataToSave.lastUpdate = currentTime;
  await firebase_app.firestore().collection("MA_Note").doc(ticker).set(dataToSave);

  return true;
};
