import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

const RankScreener = ({ listFilter, onChangeRanking, initialConfig }) => {
  const [listRank, setListRank] = useState([]);
  const [variable, setVariable] = useState(""); // ne marche pas car listFilter est dynamique
  const [operator, setOperator] = useState("Ascending");
  const [nameVariable, setNameVariable] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [typeRank, setTypeRank] = useState("arithmetic");
  const [somethingHasChanged, setSomethingHasChanged] = useState(false);

  useEffect(() => {
    if (Object.keys(initialConfig).length > 0) {
      setListRank(initialConfig.listRank);
      setTypeRank(initialConfig.typeRank);
    }
  }, [initialConfig]);

  //   setNameVariable(listFilter[0].name);
  useEffect(() => {
    if (Object.keys(initialConfig).length > 0) {
      setListRank(initialConfig.listRank);
      setTypeRank(initialConfig.typeRank);
    } else {
      setListRank([]);
      setVariable("");
      setOperator("Ascending");
      setNameVariable("");
      setBtnDisabled(true);
    }
  }, [listFilter]);
  // Function Parent

  const getRanking = () => {
    onChangeRanking({ listRank: listRank, typeRank });
    setBtnDisabled(true);
  };

  const addTypeRank = (source) => {
    if (typeRank !== source) {
      setTypeRank(source);
      setBtnDisabled(false);
      setSomethingHasChanged(true);
    }
  };

  const addRanking = () => {
    var listRankTmp = [...listRank];
    listRankTmp = listRankTmp.filter((e) => e.variable !== variable);

    const newFilter = { id: Math.floor(Math.random() * 1000000), variable: variable, operator: operator, name: nameVariable };
    listRankTmp.push(newFilter);

    setListRank(listRankTmp);
    setBtnDisabled(false);
    setSomethingHasChanged(false);
  };
  const deleteFilter = (id) => {
    var listRankTmp = [...listRank];
    listRankTmp = listRankTmp.filter((e) => e.id !== id);
    setListRank(listRankTmp);
    setBtnDisabled(false);
  };

  const handleChangeVariable = (event) => {
    const varTmp = listFilter.filter((e) => e.variable === event.target.value);

    setVariable(event.target.value);
    setNameVariable(varTmp[0].name);
    setSomethingHasChanged(true);
  };
  const handleChangeOperator = (event) => {
    setOperator(event.target.value);
    setSomethingHasChanged(true);
  };

  const rankingOperator = ["Ascending", "Descending"];

  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          {/* Partie 1 - Choix du type de Ranking system Arithm or Geometric */}
          <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>SORTING</h2>

          {/* Choix des parametres Ranking  */}
          <h2 className="m-t-20 " style={{ color: "black", fontSize: "1rem" }}>
            PARAMETERS
          </h2>
          <p>The ranking is limited to the selected parameters only.</p>
          {/* Map current filter Version 2 */}
          <div className="row m-b-20 col">
            {listRank.map((e) => {
              return (
                <div key={e.id} className="pill-parameters" onClick={() => deleteFilter(e.id)}>
                  {`${e.name} : ${e.operator === "Ascending" ? "Lower the better" : "Higher the better"}`}
                  <FontAwesomeIcon className="m-l-10" icon={faTrashAlt} />
                </div>
              );
            })}
          </div>
          {/* Map current filter  */}
          {/* <div>
            {listRank.map((e) => {
              return (
                <div key={e.id} className={isMobile ? "row m-l-5  p-b-10" : "row m-l-10  p-b-10 listSelectedFilter  "}>
                  {!isMobile && <div className="col-1 listSelectedFilterElement"></div>}
                  <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
                    <div className="rbt-input-main form-control rbt-input " style={{ opacity: "0.5" }}>
                      {e.name}
                    </div>
                  </div>

                  <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
                    <div className="rbt-input-main form-control rbt-input " style={{ opacity: "0.5" }}>
                      {e.operator === "Ascending" ? "Lower the better" : "Higher the better"}
                    </div>
                  </div>
                  <div onClick={() => deleteFilter(e.id)} style={{ margin: "auto", textAlign: "center", cursor: "pointer", marginRight: "10px", fontSize: "large" }}>
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </div>
              );
            })}
          </div> */}
          {/* Empty filter  */}
          <div className={isMobile ? "row m-l-5" : "row m-l-10"}>
            {/* {!isMobile && <div className="col-1 listSelectedFilterElementFinal"></div>} */}
            <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
              <select className="rbt-input-main form-control rbt-input " value={variable} onChange={handleChangeVariable}>
                <option value={""}>Select an Option</option>
                {listFilter.map((variable) => {
                  if (typeof variable.condition === "number") {
                    return (
                      <option key={variable.id} value={variable.variable}>
                        {variable.name}
                      </option>
                    );
                  }
                  return "";
                })}
              </select>
            </div>
            <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
              <select className="rbt-input-main form-control rbt-input " value={operator} onChange={handleChangeOperator}>
                {rankingOperator.map((option, index) => {
                  return (
                    <option key={index} value={option}>
                      {option === "Ascending" ? "Lower the better" : "Higher the better"}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="" style={{ margin: "auto", textAlign: "center", opacity: "0", marginRight: "10px", fontSize: "large" }}>
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>

          <div className="row p-l-20 p-r-20  m-t-20 justify-content-md-center">
            <div className={isMobile ? "col-12 text-center m-t-20" : " col-6 text-center m-t-20"}>
              <button className={`btn btn-block btn-light btnPrimary ${variable === "" && "btnDisabled"}`} disabled={variable === ""} onClick={addRanking}>
                <FontAwesomeIcon icon={faPlus} />

                {somethingHasChanged ? "  Add - Changes detected" : "  Add"}
              </button>

              <button className={`btn m-t-20 m-b-20 btn-block btnSecondary ${btnDisabled && "btnDisabled"}`} disabled={btnDisabled} onClick={getRanking}>
                {btnDisabled ? "Sorted !" : "Sort Tickers"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RankScreener;
