import React, { Component, useEffect, useState } from "react";
import SummaryRadar from "../../symbolPage/analysis/widgetAnalysis/summaryRadar";
import { fetchLogoUrl } from "../../../../tools/fetchLogoCompany";
import { isMobile } from "react-device-detect";
import { getNoteSymbolFirebase, saveNoteSymbol } from "../../../../firebase-marketadvisor/firebase-CRUD-note";
import { getNoteForTickers } from "../../tools/functionCalculNoteTickers";
import { Link } from "react-router-dom";

import { useQueryClient, useQuery } from "@tanstack/react-query";

const CardRadarSimple = ({ data }) => {
  // const [elementWatchlist, setElementWatchlist] = useState({});
  const [loaded, setLoaded] = useState(false);

  const queryClient = useQueryClient();

  const queryNoteSymbolMA = useQuery({ enabled: !!data?.symbol, queryKey: ["symbol", "radar", data.symbol], queryFn: () => getNoteMA(data) });

  const getNoteMA = (ticker) => {
    return new Promise(async (res, rej) => {
      let { valid, data } = await getNoteSymbolFirebase(ticker.symbol);
      let { note, price, PE } = data;
      let elementWatchlist = {};
      if (!valid) {
        getNoteForTickers({ symbol: ticker.symbol, queryClient: queryClient }).then((data) => {
          let { note, price, PE } = data;
          saveNoteSymbol({ ticker: ticker.symbol, data: { note, price, PE } });

          elementWatchlist = {
            symbol: ticker.symbol,
            name: ticker.name,
            dataToPlot: [note.earning, note.stability, note.price, note.timing],
            note,
            price,
            PE,
          };

          res(elementWatchlist);
        });
      } else {
        elementWatchlist = {
          symbol: ticker.symbol,
          name: ticker.name,
          dataToPlot: [note.earning, note.stability, note.price, note.timing],
          note,
          price,
          PE,
        };

        res(elementWatchlist);
      }
    });
  };

  // useEffect(() => {
  //   const retrieveData = async (ticker) => {
  //     let { valid, data } = await getNoteSymbolFirebase(ticker.symbol);
  //     let { note, price, PE } = data;
  //     let elementWatchlist = {};
  //     if (!valid) {
  //       getNoteForTickers({ symbol: ticker.symbol, queryClient: queryClient }).then((data) => {
  //         let { note, price, PE } = data;
  //         saveNoteSymbol({ ticker: ticker.symbol, data: { note, price, PE } });

  //         elementWatchlist = {
  //           symbol: ticker.symbol,
  //           name: ticker.name,
  //           dataToPlot: [note.earning, note.stability, note.price, note.timing],
  //           note,
  //           price,
  //           PE,
  //         };
  //         setElementWatchlist(elementWatchlist);
  //         setLoaded(true);
  //       });
  //     } else {
  //       elementWatchlist = {
  //         symbol: ticker.symbol,
  //         name: ticker.name,
  //         dataToPlot: [note.earning, note.stability, note.price, note.timing],
  //         note,
  //         price,
  //         PE,
  //       };
  //       setElementWatchlist(elementWatchlist);
  //       setLoaded(true);
  //     }

  //     return elementWatchlist;
  //   };

  //   retrieveData(data);
  // }, [data]);

  var logoPath = fetchLogoUrl(data.symbol);

  return (
    <div className={isMobile ? "col-12" : "col-4"} key={data.symbol}>
      <div className="card card-body">
        <div className="row">
          <div className={isMobile ? "" : "col-1"}></div>
          <div className={isMobile ? "col-12 mx-auto text-center" : "col-4"} style={isMobile ? {} : { display: "contents" }}>
            <Link to={"/symbol/" + data.symbol}>
              <img
                src={logoPath}
                alt="new"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                }}
                style={{ maxWidth: "50px" }}
              />
            </Link>
          </div>
          <div className={isMobile ? "col-12 p-0 mx-auto" : "col-6 p-l-5 p-r-5 mx-auto"} style={isMobile ? { textAlign: "center", whiteSpace: "nowrap", overflow: "hidden" } : { whiteSpace: "nowrap", overflow: "hidden" }}>
            <div className="maincolortext" style={{ fontSize: "0.9rem", fontWeight: "400" }}>
              <Link to={"/symbol/" + data.symbol} className="maincolortext">
                <div style={{ fontWeight: "bold" }}>{`${data.symbol}`}</div>

                <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${data.name}`}</div>
              </Link>

              <div className="m-r-10 secondcolortext">{`${queryNoteSymbolMA.isSuccess ? queryNoteSymbolMA.data?.price?.price : "-"}${queryNoteSymbolMA.isSuccess ? queryNoteSymbolMA.data?.price?.currency : ""}`}</div>

              <div className={queryNoteSymbolMA?.data?.price?.variation < 0 ? "negative" : "positive"}>
                <i className={queryNoteSymbolMA?.data?.price?.variation < 0 ? "fa fa-long-arrow-down" : "fa fa-long-arrow-up"}></i>
                {`  ${queryNoteSymbolMA.isSuccess ? parseInt(100 * 100 * queryNoteSymbolMA.data?.price?.variation) / 100 : "-"} %`}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 m-t-20">{queryNoteSymbolMA.isSuccess ? <SummaryRadar valueChart={queryNoteSymbolMA.data.dataToPlot} /> : <SummaryRadar valueChart={[0, 0, 0, 0]} />}</div>
      </div>
    </div>
  );
};

export default CardRadarSimple;
