import { getFinancialData, getGeneralInfo, getAverageIndustry } from "../../../firebase-marketadvisor/firebase-financial-data";
import { firebase_app } from "../../../data/config";
import { calculFinancialData } from "./functionCalcFinancialRatio";
import { getGlobalNote, getScore } from "../symbolPage/analysis/widgetAnalysis/toolWidget";
import { calculateRSI, calculateStoch, caculateMovingAverage, getScoreInterestCoverRatio, getScorePE } from "../symbolPage/analysis/widgetAnalysis/toolWidget";

import { useQueryClient } from "@tanstack/react-query";
const getMomentum = async (ticker) => {
  const getHistorical = firebase_app.functions().httpsCallable("getHistorical");
  const res = await getHistorical({ symbol: ticker });

  // Calcul MA 89 and 21
  const dataPriceOHLC = res.data;
  const dataPrice = res.data.map((e) => {
    return e.close;
  });

  const MA89 = caculateMovingAverage(dataPrice, 89);
  const MA21 = caculateMovingAverage(dataPrice, 21);
  const stoch89 = calculateStoch(dataPriceOHLC, 89);
  const RSI89 = calculateRSI(dataPrice, 89);

  const currentPos89 = dataPrice.slice(-1)[0] - MA89.slice(-1)[0];
  const currentPos21 = dataPrice.slice(-1)[0] - MA21.slice(-1)[0];

  // Note Momentum
  var valueMomentum = "";
  var noteMomentum = "";
  if (currentPos89 > 0 && currentPos21 > 0) {
    valueMomentum = "Uptrend";
    noteMomentum = 85;
  }
  if (currentPos89 > 0 && currentPos21 < 0) {
    valueMomentum = "Uncertain";
    noteMomentum = 65;
  }
  if (currentPos89 < 0 && currentPos21 > 0) {
    valueMomentum = "Uncertain";
    noteMomentum = 35;
  }
  if (currentPos89 < 0 && currentPos21 < 0) {
    valueMomentum = "Downtrend";
    noteMomentum = 15;
  }

  // Note Stochastic
  var valueStochastic = "";
  var noteStochastic = "";
  if (RSI89 > 45) {
    if (stoch89 > 95) {
      valueStochastic = "Overbought";
      noteStochastic = 35;
    }
    if (stoch89 > 50 && stoch89 <= 95) {
      valueStochastic = "Uptrend";
      noteStochastic = 85;
    }
    if (stoch89 > 30 && stoch89 <= 50) {
      valueStochastic = "Uptrend";
      noteStochastic = 60;
    }
    if (stoch89 <= 30) {
      valueStochastic = "Downtrend";
      noteStochastic = 35;
    }
  } else {
    if (stoch89 > 50) {
      valueStochastic = "Uncertain";
      noteStochastic = 35;
    }
    if (stoch89 > 30 && stoch89 <= 50) {
      valueStochastic = "Downtrend";
      noteStochastic = 35;
    }
    if (stoch89 <= 30) {
      valueStochastic = "Downtrend";
      noteStochastic = 15;
    }
  }

  return { noteMomentum, valueMomentum, noteStochastic, valueStochastic, dataPrice };
};

export const getNoteForTickers = async ({ symbol, queryClient }) => {
  const getPriceAndDataSymbol = firebase_app.functions().httpsCallable("getPriceAndDataSymbol");

  const informationCompany = await getGeneralInfo("AAPL");
  const financial = await getFinancialData(symbol);
  const averageSector = await getAverageIndustry({ sector: informationCompany.Sector, industry: informationCompany.Industry });

  let dataYahooFinance = [];
  if (queryClient) {
    dataYahooFinance = queryClient.getQueryData(["symbol", "informationTicker", symbol]);

    if (dataYahooFinance) {
      dataYahooFinance = dataYahooFinance.data;
    } else {
      dataYahooFinance = await getPriceAndDataSymbol({ symbol });
    }
  } else {
    dataYahooFinance = await getPriceAndDataSymbol({ symbol });
  }

  var myKey = Object.keys(financial).sort().reverse();
  var listYearAvailable = [];
  for (let i = 0; i < myKey.length; i++) {
    listYearAvailable.push(myKey[i]);
  }
  var year = myKey[0];
  const { grossMargin, operatingMargin, netMargin, ROA, ROE, interestCoverageRatio, quickRatio, eqAssetRatio, altmanZScore, priceEquity, priceSales } = calculFinancialData(financial, year, {
    marketCap: dataYahooFinance.data.marketCap,
    price: dataYahooFinance.data.price,
  });
  const { noteMomentum, valueMomentum, noteStochastic, valueStochastic, dataPrice } = await getMomentum(symbol);

  // Note Earning

  const earning = [
    {
      indicator: "Gross Margin",
      score: getScore({ average: averageSector?.grossMargin, value: grossMargin, percent: true }),
    },
    {
      indicator: "Operating Margin",
      score: getScore({ average: averageSector?.operatingMargin, value: operatingMargin, percent: true }),
    },
    {
      indicator: "Net Margin",
      score: getScore({ average: averageSector?.netMargin, value: netMargin, percent: true }),
    },
    {
      indicator: "ROA",
      score: getScore({ average: averageSector?.ROA, value: ROA, percent: true }),
    },
    {
      indicator: "ROE",
      score: getScore({ average: averageSector?.ROE, value: ROE, percent: true }),
    },
  ];
  const stability = [
    {
      indicator: "Interest Coverage",
      score: getScoreInterestCoverRatio({ value: interestCoverageRatio }),
    },
    {
      indicator: "Quick Ratio",
      score: getScore({ average: { "25%": 0.7, "50%": 0.9, "75%": 1, dir: "asc" }, value: quickRatio, percent: true }),
    },
    {
      indicator: "Equity To Assets",
      score: getScore({ average: averageSector?.eqAssetRatio, value: eqAssetRatio, percent: true }),
    },
    {
      indicator: "Altman Z-Score",
      score: getScore({ average: { "25%": 0, "50%": 2, "75%": 3, dir: "asc" }, value: altmanZScore, percent: false }),
    },
  ];

  const price = [
    {
      indicator: "PE",
      score: getScorePE({ value: dataYahooFinance.data.PE }),
    },
    {
      indicator: "Price/Equity",
      score: getScore({ average: averageSector?.priceEquity, value: priceEquity, percent: true }),
    },
    {
      indicator: "Price/Sales",
      score: getScore({ average: averageSector?.priceEquity, value: priceEquity, percent: true }),
    },
  ];

  const momentum = [
    {
      indicator: "Momentum",
      score: noteMomentum,
    },
    {
      indicator: "Trends",
      score: noteStochastic,
    },
  ];

  const noteEarning = getGlobalNote({ data: earning });
  const noteStability = getGlobalNote({ data: stability });
  const notePrice = getGlobalNote({ data: price });
  const noteTiming = getGlobalNote({ data: momentum });

  var note = {
    earning: noteEarning.noteGlobal,
    stability: noteStability.noteGlobal,
    price: notePrice.noteGlobal,
    timing: noteTiming.noteGlobal,
  };

  return { note, price: { price: dataYahooFinance.data.price, variation: dataYahooFinance.data.variationPercent, currency: dataYahooFinance.data.currencySymbol, historical: dataPrice }, PE: dataYahooFinance.data.PE };
  // Note Stability
};
