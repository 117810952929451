import React, { Component } from "react";

import SummaryRadar from "./summaryRadar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

class SummaryNote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      note: this.props.data.note,
      subscription: this.props.data.subscription,
    };
  }

  render() {
    var dataToPlot = [this.props.data.note?.earning?.value, this.props.data.note?.stability?.value, this.props.data.note?.price?.value, this.props.data.note?.momentum?.value, this.props.data.note?.growth?.value];
    // if (this.state.subscription === false) {
    //   dataToPlot = [0, 0, 0, 0];
    // }

    return (
      <div>
        <SummaryRadar valueChart={dataToPlot} />

        {/* {this.state.subscription === false && (
          <div className="text-center" style={{ position: "absolute", bottom: "0", height: "80%", width: "100%", backgroundColor: "#ffffff96", backdropFilter: "blur(3px)" }}>
            <div style={{ position: "relative", top: "40%", transform: "translateY(-50%)", fontSize: "50px", color: "#898989" }}>
              <FontAwesomeIcon icon={faLock} />
            </div>
          </div>
        )} */}
      </div>
    );
  }
}

export default SummaryNote;
