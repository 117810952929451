import React from "react";

const Footer = (props) => {
  return (
    <div style={{ marginLeft: "270px", marginRight: "10px", marginBottom: "5px" }}>
      <div className="card">
        <div className="card-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 footer-copyright text-center">
                <div className="mb-0">
                  <p className=" h3 m-t-5 m-b-0" style={{ color: "#230d575e", fontSize: "20px" }}>
                    <span>
                      market<span style={{ color: "#d4d1db" }}>advisor</span> - V2.0.3
                    </span>{" "}
                  </p>

                  <a target="_blank" rel="noopener noreferrer" href="https://marketadvisor.io/terms-of-use.html" style={{ color: "#d4d1db" }}>
                    Terms Of Use
                  </a>
                  {" - "}
                  <a target="_blank" rel="noopener noreferrer" href="https://marketadvisor.io/privacy.html" style={{ color: "#d4d1db" }}>
                    Privacy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
