import React, { Component, useContext } from "react";
import { ETFContext } from "../utils/etfContext";
import Loader from "../../tools/loader";

const CardDescriptionETF = () => {
  const data = useContext(ETFContext);

  if (Object.keys(data).length === 0) {
    return <Loader />;
  } else {
    return (
      <div className="card">
        <div className="card-body">
          <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>DESCRIPTION</h2>
          {data.Description}
        </div>
      </div>
    );
  }
};

export default CardDescriptionETF;
