import React, { Fragment, Component } from "react";
// import DataTable from 'react-data-table-component';
import { firebase_app } from "../../../../data/config";
import { getAccessLimitation } from "../../../../firebase-marketadvisor/firebase-data-user";

class LimitationCard extends Component {
  state = { loading: true };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        getAccessLimitation(user).then((access) => {
          this.setState({ access, loading: false });
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <div className="card m-t-10">
          <div className="container">
            <div className="col-sm-12 col-xl-12">
              <div className="card-body ">
                <h4>
                  <strong>Usage & Limitation</strong>
                </h4>

                {this.state.loading === false && (
                  <ul className={"m-b-20 m-t-20"} style={{ fontSize: "0.9rem" }}>
                    <li style={{ borderBottom: "#a1a1a1 solid 1px" }}>
                      <div className="row m-5">
                        <div className="col-6 text-center">
                          <strong>Symbol Visualisation</strong>
                        </div>
                        {this.state.access.symbol > 10000 ? <div className="col-6 text-center">Unlimited</div> : <div className="col-6 text-center">{this.state.access.symbol} left</div>}
                      </div>
                    </li>
                    <li style={{ borderBottom: "#a1a1a1 solid 1px" }}>
                      <div className="row m-5">
                        <div className="col-6 text-center">
                          <strong>Screener Visualisation</strong>
                        </div>

                        {this.state.access.symbol > 10000 ? <div className="col-6 text-center">Unlimited</div> : <div className="col-6 text-center">{this.state.access.screener} left</div>}
                      </div>
                    </li>
                    <li style={{ borderBottom: "#a1a1a1 solid 1px" }}>
                      <div className="row m-5">
                        <div className="col-6 text-center">
                          <strong>Guru Screener</strong>
                        </div>
                        <div className="col-6 text-center">{this.state.access.guruScreener ? "Yes" : "No"}</div>
                      </div>
                    </li>

                    <li style={{ borderBottom: "#a1a1a1 solid 1px" }}>
                      <div className="row m-5">
                        <div className="col-6 text-center">
                          <strong>Advanced Portfolio</strong>
                        </div>
                        <div className="col-6 text-center">{this.state.access.portfolio ? "Yes" : "No"}</div>
                      </div>
                    </li>

                    <li style={{ borderBottom: "#a1a1a1 solid 1px" }}>
                      <div className="row m-5">
                        <div className="col-6 text-center">
                          <strong>Dividend Calendar</strong>
                        </div>
                        <div className="col-6 text-center">{this.state.access.dividend ? "Yes" : "No"}</div>
                      </div>
                    </li>
                    <li style={{ borderBottom: "#a1a1a1 solid 1px" }}>
                      <div className="row m-5">
                        <div className="col-6 text-center">
                          <strong>Financial Data</strong>
                        </div>
                        <div className="col-6 text-center">{this.state.access.financialData ? "+30 Years" : "3 Years"}</div>
                      </div>
                    </li>
                    <li style={{ borderBottom: "#a1a1a1 solid 1px" }}>
                      <div className="row m-5">
                        <div className="col-6 text-center">
                          <strong>Next Reset in</strong>
                        </div>
                        {this.state.access.portfolio ? (
                          <div className="col-6 text-center">{parseInt(30 - (Date.now() - this.state.access.lastUpdate) / (1000 * 60 * 60 * 24))} day(s)</div>
                        ) : (
                          <div className="col-6 text-center"> Only for Paid plan</div>
                        )}
                      </div>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LimitationCard;
