import React, { Component, useContext } from "react";
import { ETFContext } from "../utils/etfContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose, faMultiply, faStar } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../tools/loader";

const CardCaracteristics = () => {
  const data = useContext(ETFContext);

  if (Object.keys(data).length === 0) {
    return <Loader />;
  } else {
    return (
      <div className="card">
        <div className="card-body">
          <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>KEY FIGURES</h2>
          <div className="row m-l-5 m-r-5">
            <div className="col-6 p-l-0 p-r-10 " style={{ borderRight: "0.5px solid ", borderColor: "#7777774f" }}>
              <div className="row">
                <h6 className="col" style={{ color: "#777", fontWeight: "400" }}>
                  Assets
                </h6>
                <h6 className="col-auto" style={{ color: "#1f1f1f", fontWeight: "400" }}>
                  {parseInt(parseFloat(data.TotalAssets) / 1e6)} M$
                </h6>
              </div>
              <div className="row">
                <h6 className="col" style={{ color: "#777", fontWeight: "400" }}>
                  Morning Star
                </h6>
                <h6 className="col-auto" style={{ color: "#1f1f1f", fontWeight: "400" }}>
                  {/* {parseInt(parseFloat(data.MorningStar.Ratio)) + "/5"} */}
                  <FontAwesomeIcon icon={faStar} style={parseInt(parseFloat(data.MorningStar.Ratio)) >= 1 ? { color: "#1098f7", fontSize: "0.8rem" } : { color: "#c1e0f5", fontSize: "0.8rem" }} />
                  <FontAwesomeIcon icon={faStar} style={parseInt(parseFloat(data.MorningStar.Ratio)) >= 2 ? { color: "#1098f7", fontSize: "0.8rem" } : { color: "#c1e0f5", fontSize: "0.8rem" }} />
                  <FontAwesomeIcon icon={faStar} style={parseInt(parseFloat(data.MorningStar.Ratio)) >= 3 ? { color: "#1098f7", fontSize: "0.8rem" } : { color: "#c1e0f5", fontSize: "0.8rem" }} />
                  <FontAwesomeIcon icon={faStar} style={parseInt(parseFloat(data.MorningStar.Ratio)) >= 4 ? { color: "#1098f7", fontSize: "0.8rem" } : { color: "#c1e0f5", fontSize: "0.8rem" }} />
                  <FontAwesomeIcon icon={faStar} style={parseInt(parseFloat(data.MorningStar.Ratio)) >= 5 ? { color: "#1098f7", fontSize: "0.8rem" } : { color: "#c1e0f5", fontSize: "0.8rem" }} />
                </h6>
              </div>
              <div className="row">
                <h6 className="col" style={{ color: "#777", fontWeight: "400" }}>
                  Return
                </h6>
                <h6 className="col-auto" style={{ color: "#1f1f1f", fontWeight: "400" }}>
                  {parseFloat(data.Performance.Returns_YTD) + "%"}
                </h6>
              </div>
            </div>

            <div className="col-6 p-l-10 p-r-0">
              <div className="row">
                <h6 className="col " style={{ color: "#777", fontWeight: "400" }}>
                  Expense Ratio
                </h6>
                <h6 className="col-auto" style={{ color: "#1f1f1f", fontWeight: "400" }}>
                  {parseInt(1000000 * parseFloat(data.NetExpenseRatio)) / 10000 + "%"}
                </h6>
              </div>
              <div className="row">
                <h6 className="col" style={{ color: "#777", fontWeight: "400" }}>
                  Dividend
                </h6>
                <h6 className="col-auto" style={{ color: "#1f1f1f", fontWeight: "400" }}>
                  {parseFloat(data.Yield) > 0 ? <div> {parseInt(100 * parseFloat(data.Yield)) / 100}%</div> : <FontAwesomeIcon style={{ marginLeft: "5px", color: "red", fontWeight: "500", fontSize: "1.2rem" }} icon={faClose} />}
                </h6>
              </div>
              <div className="row">
                <h6 className="col" style={{ color: "#777", fontWeight: "400" }}>
                  Volatility
                </h6>
                <h6 className="col-auto" style={{ color: "#1f1f1f", fontWeight: "400" }}>
                  {parseFloat(data.Performance["1y_Volatility"]) + "%"}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CardCaracteristics;
