export const dbSearch = [
  { id: 0, s: "TSLA", n: "Tesla Inc", c: "US" },
  { id: 1, s: "AAPL", n: "Apple Inc", c: "US" },
  { id: 2, s: "NVDA", n: "NVIDIA Corporation", c: "US" },
  { id: 3, s: "AMZN", n: "Amazon.com Inc", c: "US" },
  { id: 4, s: "PLTR", n: "Palantir Technologies Inc", c: "US" },
  { id: 5, s: "NIO", n: "Nio Inc Class A ADR", c: "US" },
  { id: 6, s: "AMD", n: "Advanced Micro Devices Inc", c: "US" },
  { id: 7, s: "MSFT", n: "Microsoft Corporation", c: "US" },
  { id: 8, s: "TGT", n: "Target Corporation", c: "US" },
  { id: 9, s: "SOFI", n: "SoFi Technologies Inc.", c: "US" },
  { id: 10, s: "AI", n: "C3 Ai Inc", c: "US" },
  { id: 11, s: "F", n: "Ford Motor Company", c: "US" },
  { id: 12, s: "CVNA", n: "Carvana", c: "US" },
  { id: 13, s: "META", n: "Meta Platforms Inc.", c: "US" },
  { id: 14, s: "GOOG", n: "Alphabet Inc Class C", c: "US" },
  { id: 15, s: "DIS", n: "Walt Disney Company", c: "US" },
  { id: 16, s: "INTC", n: "Intel Corporation", c: "US" },
  { id: 17, s: "T", n: "AT&T Inc", c: "US" },
  { id: 18, s: "PYPL", n: "PayPal Holdings Inc", c: "US" },
  { id: 19, s: "NFLX", n: "Netflix Inc", c: "US" },
  { id: 20, s: "VZ", n: "Verizon Communications Inc", c: "US" },
  { id: 21, s: "COIN", n: "Coinbase Global Inc", c: "US" },
  { id: 22, s: "ADBE", n: "Adobe Systems Incorporated", c: "US" },
  { id: 23, s: "BABA", n: "Alibaba Group Holding", c: "US" },
  { id: 24, s: "GME", n: "GameStop Co", c: "US" },
  { id: 25, s: "ORCL", n: "Oracle Corporation", c: "US" },
  { id: 26, s: "BAC", n: "Bank of America Co", c: "US" },
  { id: 27, s: "BA", n: "The Boeing Company", c: "US" },
  { id: 28, s: "PBR", n: "Petroleo Brasileiro Petrobras SA ADR", c: "US" },
  { id: 29, s: "PLUG", n: "Plug Power Inc", c: "US" },
  { id: 30, s: "THS", n: "Treehouse Foods Inc", c: "US" },
  { id: 31, s: "XOM", n: "Exxon Mobil Co", c: "US" },
  { id: 32, s: "KO", n: "The Coca-Cola Company", c: "US" },
  { id: 33, s: "BUD", n: "Anheuser Busch Inbev NV ADR", c: "US" },
  { id: 34, s: "PFE", n: "Pfizer Inc", c: "US" },
  { id: 35, s: "CHPT", n: "ChargePoint Holdings Inc", c: "US" },
  { id: 36, s: "QCOM", n: "Qualcomm Incorporated", c: "US" },
  { id: 37, s: "MMM", n: "3M Company", c: "US" },
  { id: 38, s: "DVN", n: "Devon Energy Corporation", c: "US" },
  { id: 39, s: "POST", n: "Post Holdings Inc", c: "US" },
  { id: 40, s: "DAL", n: "Delta Air Lines Inc", c: "US" },
  { id: 41, s: "IEP", n: "Icahn Enterprises LP", c: "US" },
  { id: 42, s: "WMT", n: "Walmart Inc", c: "US" },
  { id: 43, s: "DE", n: "Deere & Company", c: "US" },
  { id: 44, s: "CPB", n: "Campbell Soup Company", c: "US" },
  { id: 45, s: "GOOGL", n: "Alphabet Inc Class A", c: "US" },
  { id: 46, s: "TSM", n: "Taiwan Semiconductor Manufacturing", c: "US" },
  { id: 47, s: "IBM", n: "International Business Machines", c: "US" },
  { id: 48, s: "ILMN", n: "Illumina Inc", c: "US" },
  { id: 49, s: "GERN", n: "Geron Corporation", c: "US" },
  { id: 50, s: "MPW", n: "Medical Properties Trust Inc", c: "US" },
  { id: 51, s: "SAVA", n: "Cassava Sciences Inc", c: "US" },
  { id: 52, s: "SHOP", n: "Shopify Inc", c: "US" },
  { id: 53, s: "MU", n: "Micron Technology Inc", c: "US" },
  { id: 54, s: "MULN", n: "Mullen Automotive Inc", c: "US" },
  { id: 55, s: "BIIB", n: "Biogen Inc", c: "US" },
  { id: 56, s: "LCID", n: "Lucid Group Inc", c: "US" },
  { id: 57, s: "NKLA", n: "Nikola Co", c: "US" },
  { id: 58, s: "PARA", n: "Paramount Global Class B", c: "US" },
  { id: 59, s: "MO", n: "Altria Group", c: "US" },
  { id: 60, s: "UPST", n: "Upstart Holdings Inc", c: "US" },
  { id: 61, s: "RIVN", n: "Rivian Automotive Inc", c: "US" },
  { id: 62, s: "SNOW", n: "Snowflake Inc.", c: "US" },
  { id: 63, s: "ZIM", n: "ZIM Integrated Shipping Services", c: "US" },
  { id: 64, s: "ET", n: "Energy Transfer Equity LP", c: "US" },
  { id: 65, s: "GM", n: "General Motors Company", c: "US" },
  { id: 66, s: "AVGO", n: "Broadcom Inc", c: "US" },
  { id: 67, s: "CCL", n: "Carnival Corporation", c: "US" },
  { id: 68, s: "SMCI", n: "Super Micro Computer Inc", c: "US" },
  { id: 69, s: "TLRY", n: "Tilray Inc", c: "US" },
  { id: 70, s: "OXY", n: "Occidental Petroleum Corporation", c: "US" },
  { id: 71, s: "JNJ", n: "Johnson & Johnson", c: "US" },
  { id: 72, s: "PATH", n: "Uipath Inc", c: "US" },
  { id: 73, s: "JPM", n: "JPMorgan Chase &", c: "US" },
  { id: 74, s: "AHI", n: "Advanced Health Intelligence", c: "US" },
  { id: 75, s: "NVAX", n: "Novavax Inc", c: "US" },
  { id: 76, s: "GE", n: "General Electric Company", c: "US" },
  { id: 77, s: "CVX", n: "Chevron Co", c: "US" },
  { id: 78, s: "CRWD", n: "Crowdstrike Holdings Inc", c: "US" },
  { id: 79, s: "PACW", n: "PacWest BanCo", c: "US" },
  { id: 80, s: "BRK-B", n: "Berkshire Hathaway Inc", c: "US" },
  { id: 81, s: "U", n: "Unity Software Inc", c: "US" },
  { id: 82, s: "CRM", n: "Salesforce.com Inc", c: "US" },
  { id: 83, s: "QS", n: "Quantumscape Co", c: "US" },
  { id: 84, s: "COST", n: "Costco Wholesale Co", c: "US" },
  { id: 85, s: "AMC", n: "AMC Entertainment Holdings Inc", c: "US" },
  { id: 86, s: "DOCU", n: "DocuSign Inc", c: "US" },
  { id: 87, s: "NKE", n: "Nike Inc", c: "US" },
  { id: 88, s: "ABBV", n: "AbbVie Inc", c: "US" },
  { id: 89, s: "HD", n: "Home Depot Inc", c: "US" },
  { id: 90, s: "PG", n: "Procter & Gamble Company", c: "US" },
  { id: 91, s: "CS", n: "Cisco Systems Inc", c: "US" },
  { id: 92, s: "O", n: "Realty Income Co", c: "US" },
  { id: 93, s: "UBER", n: "Uber Technologies Inc", c: "US" },
  { id: 94, s: "C", n: "Citigroup Inc", c: "US" },
  { id: 95, s: "ABNB", n: "Airbnb Inc", c: "US" },
  { id: 96, s: "WBD", n: "Warner Bros Discovery Inc", c: "US" },
  { id: 97, s: "CVS", n: "CVS Health Co", c: "US" },
  { id: 98, s: "ENPH", n: "Enphase Energy Inc", c: "US" },
  { id: 99, s: "SBUX", n: "Starbucks Corporation", c: "US" },
  { id: 100, s: "AFRM", n: "Affirm Holdings Inc", c: "US" },
  { id: 101, s: "EPD", n: "Enterprise Products Partners LP", c: "US" },
  { id: 102, s: "MRVL", n: "Marvell Technology Group", c: "US" },
  { id: 103, s: "V", n: "Visa Inc. Class A", c: "US" },
  { id: 104, s: "CAT", n: "Caterpillar Inc", c: "US" },
  { id: 105, s: "ROKU", n: "Roku Inc", c: "US" },
  { id: 106, s: "AAL", n: "American Airlines Group", c: "US" },
  { id: 107, s: "SQ", n: "Block Inc", c: "US" },
  { id: 108, s: "PEP", n: "PepsiCo Inc", c: "US" },
  { id: 109, s: "TEVA", n: "Teva Pharma Industries ADR", c: "US" },
  { id: 110, s: "LLY", n: "Eli Lilly and Company", c: "US" },
  { id: 111, s: "MCD", n: "McDonald’s Corporation", c: "US" },
  { id: 112, s: "WBA", n: "Walgreens Boots Alliance Inc", c: "US" },
  { id: 113, s: "UNH", n: "UnitedHealth Group Incorporated", c: "US" },
  { id: 114, s: "MRNA", n: "Moderna Inc", c: "US" },
  { id: 115, s: "ALB", n: "Albemarle Co", c: "US" },
  { id: 116, s: "CRSP", n: "Crispr Therapeutics AG", c: "US" },
  { id: 117, s: "LUMN", n: "Lumen Technologies Inc", c: "US" },
  { id: 118, s: "DISH", n: "DISH Network Corporation", c: "US" },
  { id: 119, s: "AAP", n: "Advance Auto Parts Inc", c: "US" },
  { id: 120, s: "PANW", n: "Palo Alto Networks Inc", c: "US" },
  { id: 121, s: "IMGN", n: "ImmunoGen Inc", c: "US" },
  { id: 122, s: "SOUN", n: "SoundHound AI Inc", c: "US" },
  { id: 123, s: "DG", n: "Dollar General Corporation", c: "US" },
  { id: 124, s: "AGEN", n: "Agenus Inc", c: "US" },
  { id: 125, s: "MARA", n: "Marathon Digital Holdings Inc", c: "US" },
  { id: 126, s: "USB", n: "U.S. BanCo", c: "US" },
  { id: 127, s: "SPCE", n: "Virgin Galactic Holdings Inc", c: "US" },
  { id: 128, s: "ASML", n: "ASML Holding NV ADR", c: "US" },
  { id: 129, s: "NEE", n: "Nextera Energy Inc", c: "US" },
  { id: 130, s: "CLF", n: "Cleveland-Cliffs Inc", c: "US" },
  { id: 131, s: "AGNC", n: "AGNC Investment Co", c: "US" },
  { id: 132, s: "ONCY", n: "Oncolytics Biotech Inc", c: "US" },
  { id: 133, s: "VTRS", n: "Viatris Inc", c: "US" },
  { id: 134, s: "MRK", n: "Merck & Company Inc", c: "US" },
  { id: 135, s: "WFC", n: "Wells Fargo & Company", c: "US" },
  { id: 136, s: "SCHW", n: "Charles Schwab Co", c: "US" },
  { id: 137, s: "BTI", n: "British American Tobacco p.l.c.", c: "US" },
  { id: 138, s: "EVGO", n: "Evgo Inc", c: "US" },
  { id: 139, s: "KEY", n: "KeyCo", c: "US" },
  { id: 140, s: "LMT", n: "Lockheed Martin Corporation", c: "US" },
  { id: 141, s: "FSR", n: "Fisker Inc", c: "US" },
  { id: 142, s: "IONQ", n: "IONQ Inc", c: "US" },
  { id: 143, s: "DWAC", n: "Digital World Acquisition Co", c: "US" },
  { id: 144, s: "FFIE", n: "Faraday Future Intelligent Electric Inc", c: "US" },
  { id: 145, s: "KMI", n: "Kinder Morgan Inc", c: "US" },
  { id: 146, s: "NET", n: "Cloudflare Inc", c: "US" },
  { id: 147, s: "LAC", n: "Lithium Americas Co", c: "US" },
  { id: 148, s: "RTX", n: "Raytheon Technologies Co", c: "US" },
  { id: 149, s: "SNAP", n: "Snap Inc", c: "US" },
  { id: 150, s: "RIO", n: "Rio Tinto ADR", c: "US" },
  { id: 151, s: "JD", n: "JD.com Inc Adr", c: "US" },
  { id: 152, s: "PXD", n: "Pioneer Natural Resources", c: "US" },
  { id: 153, s: "KHC", n: "Kraft Heinz", c: "US" },
  { id: 154, s: "TWLO", n: "Twilio Inc", c: "US" },
  { id: 155, s: "AMAT", n: "Applied Materials Inc", c: "US" },
  { id: 156, s: "PNPL", n: "Pineapple Express", c: "US" },
  { id: 157, s: "DNA", n: "Ginkgo Bioworks Holdings", c: "US" },
  { id: 158, s: "DKNG", n: "DraftKings Inc", c: "US" },
  { id: 159, s: "ONON", n: "On Holding", c: "US" },
  { id: 160, s: "LAZR", n: "Luminar Technologies", c: "US" },
  { id: 161, s: "NOW", n: "ServiceNow Inc", c: "US" },
  { id: 162, s: "BYND", n: "Beyond Meat Inc", c: "US" },
  { id: 163, s: "ON", n: "ON Semiconductor Corporation", c: "US" },
  { id: 164, s: "UAL", n: "United Airlines Holdings Inc", c: "US" },
  { id: 165, s: "NLY", n: "Annaly Capital Management Inc", c: "US" },
  { id: 166, s: "TDOC", n: "Teladoc Inc", c: "US" },
  { id: 167, s: "AMGN", n: "Amgen Inc", c: "US" },
  { id: 168, s: "ZM", n: "Zoom Video Communications Inc", c: "US" },
  { id: 169, s: "GEHC", n: "GE HealthCare Technologies Inc.", c: "US" },
  { id: 170, s: "BMY", n: "Bristol-Myers Squibb Company", c: "US" },
  { id: 171, s: "FCEL", n: "FuelCell Energy Inc", c: "US" },
  { id: 172, s: "CCI", n: "Crown Castle", c: "US" },
  { id: 173, s: "APRN", n: "Blue Apron Holdings, Inc. Class A Common Stock", c: "US" },
  { id: 174, s: "ENB", n: "Enbridge Inc", c: "US" },
  { id: 175, s: "CBRL", n: "Cracker Barrel Old Country Store", c: "US" },
  { id: 176, s: "BP", n: "BP PLC ADR", c: "US" },
  { id: 177, s: "IOT", n: "Samsara Inc", c: "US" },
  { id: 178, s: "XPEV", n: "Xpeng Inc", c: "US" },
  { id: 179, s: "FCX", n: "Freeport-McMoran Copper & Gold Inc", c: "US" },
  { id: 180, s: "PBR-A", n: "Petróleo Brasileiro S.A. - Petrobras", c: "US" },
  { id: 181, s: "NOK", n: "Nokia Corp ADR", c: "US" },
  { id: 182, s: "LUV", n: "Southwest Airlines Company", c: "US" },
  { id: 183, s: "BHP", n: "BHP Group Limited", c: "US" },
  { id: 184, s: "RBLX", n: "Roblox Co", c: "US" },
  { id: 185, s: "ETRN", n: "Equitrans Midstream Co", c: "US" },
  { id: 186, s: "MVIS", n: "Microvision Inc", c: "US" },
  { id: 187, s: "BB", n: "BlackBerry", c: "US" },
  { id: 188, s: "ZS", n: "Zscaler Inc", c: "US" },
  { id: 189, s: "MA", n: "Mastercard Inc", c: "US" },
  { id: 190, s: "GTLB", n: "Gitlab Inc", c: "US" },
  { id: 191, s: "ARCC", n: "Ares Capital Corporation", c: "US" },
  { id: 192, s: "SHEL", n: "Shell PLC ADR", c: "US" },
  { id: 193, s: "ABR", n: "Arbor Realty Trust", c: "US" },
  { id: 194, s: "TFC", n: "Truist Financial Co", c: "US" },
  { id: 195, s: "S", n: "SentinelOne Inc", c: "US" },
  { id: 196, s: "SYM", n: "Symbotic Inc", c: "US" },
  { id: 197, s: "UPS", n: "United Parcel Service Inc", c: "US" },
  { id: 198, s: "NEM", n: "Newmont Goldcorp Co", c: "US" },
  { id: 199, s: "ETSY", n: "Etsy Inc", c: "US" },
  { id: 200, s: "BRZE", n: "Braze Inc", c: "US" },
  { id: 201, s: "RIOT", n: "Riot Blockchain Inc", c: "US" },
  { id: 202, s: "BLNK", n: "Blink Charging", c: "US" },
  { id: 203, s: "TTD", n: "Trade Desk Inc", c: "US" },
  { id: 204, s: "BLK", n: "BlackRock Inc", c: "US" },
  { id: 205, s: "VRTX", n: "Vertex Pharmaceuticals Inc", c: "US" },
  { id: 206, s: "KR", n: "Kroger Company", c: "US" },
  { id: 207, s: "CELH", n: "Celsius Holdings Inc", c: "US" },
  { id: 208, s: "CGC", n: "Canopy Growth Co", c: "US" },
  { id: 209, s: "ATVI", n: "Activision Blizzard Inc", c: "US" },
  { id: 210, s: "LOW", n: "Lowe's Companies Inc", c: "US" },
  { id: 211, s: "TGTX", n: "TG Therapeutics Inc", c: "US" },
  { id: 212, s: "PM", n: "Philip Morris International Inc", c: "US" },
  { id: 213, s: "DOW", n: "Dow Inc", c: "US" },
  { id: 214, s: "MDT", n: "Medtronic PLC", c: "US" },
  { id: 215, s: "GOLD", n: "Barrick Gold Co", c: "US" },
  { id: 216, s: "AVXL", n: "Anavex Life Sciences Co", c: "US" },
  { id: 217, s: "ELV", n: "Elevance Health Inc", c: "US" },
  { id: 218, s: "PTON", n: "Peloton Interactive Inc", c: "US" },
  { id: 219, s: "GS", n: "Goldman Sachs Group Inc", c: "US" },
  { id: 220, s: "NVO", n: "Novo Nordisk A/S", c: "US" },
  { id: 221, s: "GILD", n: "Gilead Sciences Inc", c: "US" },
  { id: 222, s: "GOEV", n: "Canoo Inc", c: "US" },
  { id: 223, s: "STEM", n: "Stem Inc", c: "US" },
  { id: 224, s: "GEVO", n: "Gevo Inc", c: "US" },
  { id: 225, s: "TSN", n: "Tyson Foods Inc", c: "US" },
  { id: 226, s: "HPQ", n: "HP Inc", c: "US" },
  { id: 227, s: "RVPH", n: "Reviva Pharmaceuticals Holdings Inc.", c: "US" },
  { id: 228, s: "FTNT", n: "Fortinet Inc", c: "US" },
  { id: 229, s: "ABT", n: "Abbott Laboratories", c: "US" },
  { id: 230, s: "RIG", n: "Transocean", c: "US" },
  { id: 231, s: "AUR", n: "Aurora Innovation Inc", c: "US" },
  { id: 232, s: "VLO", n: "Valero Energy Corporation", c: "US" },
  { id: 233, s: "BX", n: "Blackstone Group Inc", c: "US" },
  { id: 234, s: "VALE", n: "Vale SA ADR", c: "US" },
  { id: 235, s: "AXP", n: "American Express Company", c: "US" },
  { id: 236, s: "ARR", n: "ARMOUR Residential REIT Inc", c: "US" },
  { id: 237, s: "RCL", n: "Royal Caribbean Cruises", c: "US" },
  { id: 238, s: "SMG", n: "Scotts Miracle-Gro Company", c: "US" },
  { id: 239, s: "PINS", n: "Pinterest Inc", c: "US" },
  { id: 240, s: "EL", n: "Estee Lauder Companies Inc", c: "US" },
  { id: 241, s: "MELI", n: "MercadoLibre Inc.", c: "US" },
  { id: 242, s: "PL", n: "Planet Labs PBC", c: "US" },
  { id: 243, s: "ANET", n: "Arista Networks", c: "US" },
  { id: 244, s: "TXN", n: "Texas Instruments Incorporated", c: "US" },
  { id: 245, s: "COP", n: "ConocoPhillips", c: "US" },
  { id: 246, s: "CMG", n: "Chipotle Mexican Grill Inc", c: "US" },
  { id: 247, s: "MDB", n: "MongoDB", c: "US" },
  { id: 248, s: "MS", n: "Morgan Stanley", c: "US" },
  { id: 249, s: "LULU", n: "Lululemon Athletica Inc.", c: "US" },
  { id: 250, s: "ADM", n: "Archer-Daniels-Midland Company", c: "US" },
  { id: 251, s: "WAL", n: "Western Alliance Bancorporation", c: "US" },
  { id: 252, s: "VOD", n: "Vodafone Group PLC ADR", c: "US" },
  { id: 253, s: "SIRI", n: "Sirius XM Holding Inc", c: "US" },
  { id: 254, s: "SENS", n: "Senseonics Holdings Inc", c: "US" },
  { id: 255, s: "NNOX", n: "Nano X Imaging Ltd", c: "US" },
  { id: 256, s: "TMUS", n: "T-Mobile US Inc", c: "US" },
  { id: 257, s: "NYCB", n: "New York Community Bancorp Inc", c: "US" },
  { id: 258, s: "BNGO", n: "Bionano Genomics Inc", c: "US" },
  { id: 259, s: "SO", n: "Southern Company", c: "US" },
  { id: 260, s: "OKTA", n: "Okta Inc", c: "US" },
  { id: 261, s: "TM", n: "Toyota Motor Corporation ADR", c: "US" },
  { id: 262, s: "LI", n: "Li Auto Inc", c: "US" },
  { id: 263, s: "HON", n: "Honeywell International Inc", c: "US" },
  { id: 264, s: "AMT", n: "American Tower Co", c: "US" },
  { id: 265, s: "CHWY", n: "Chewy Inc", c: "US" },
  { id: 266, s: "M", n: "Macy’s Inc", c: "US" },
  { id: 267, s: "OKE", n: "ONEOK Inc", c: "US" },
  { id: 268, s: "DDOG", n: "Datadog Inc", c: "US" },
  { id: 269, s: "CTRA", n: "Coterra Energy Inc", c: "US" },
  { id: 270, s: "DUK", n: "Duke Energy Corporation", c: "US" },
  { id: 271, s: "CMCSA", n: "Comcast Co", c: "US" },
  { id: 272, s: "SE", n: "Sea", c: "US" },
  { id: 273, s: "ISRG", n: "Intuitive Surgical Inc", c: "US" },
  { id: 274, s: "ENVX", n: "Enovix Co", c: "US" },
  { id: 275, s: "AXSM", n: "Axsome Therapeutics Inc", c: "US" },
  { id: 276, s: "MTCH", n: "Match Group Inc", c: "US" },
  { id: 277, s: "VFC", n: "VF Corporation", c: "US" },
  { id: 278, s: "BIDU", n: "Baidu Inc", c: "US" },
  { id: 279, s: "RKLB", n: "Rocket Lab USA Inc.", c: "US" },
  { id: 280, s: "GLW", n: "Corning Incorporated", c: "US" },
  { id: 281, s: "MRO", n: "Marathon Oil Corporation", c: "US" },
  { id: 282, s: "FSLR", n: "First Solar Inc", c: "US" },
  { id: 283, s: "LRCX", n: "Lam Research Co", c: "US" },
  { id: 284, s: "AEHR", n: "Aehr Test Systems", c: "US" },
  { id: 285, s: "SPG", n: "Simon Property Group Inc", c: "US" },
  { id: 286, s: "ACN", n: "Accenture plc", c: "US" },
  { id: 287, s: "SQM", n: "Sociedad Quimica y Minera de Chile SA ADR B", c: "US" },
  { id: 288, s: "BROS", n: "Dutch Bros Inc", c: "US" },
  { id: 289, s: "EXAS", n: "EXACT Sciences Corporation", c: "US" },
  { id: 290, s: "ACB", n: "Aurora Cannabis Inc", c: "US" },
  { id: 291, s: "HOOD", n: "Robinhood Markets Inc", c: "US" },
  { id: 292, s: "RGTI", n: "Rigetti Computing Inc", c: "US" },
  { id: 293, s: "PDD", n: "Pinduoduo", c: "US" },
  { id: 294, s: "MAIN", n: "Main Street Capital Corporation", c: "US" },
  { id: 295, s: "CUZ", n: "Cousins Properties Incorporated", c: "US" },
  { id: 296, s: "FDX", n: "FedEx Corporation", c: "US" },
  { id: 297, s: "STLA", n: "Stellantis NV", c: "US" },
  { id: 298, s: "X", n: "United States Steel Corporation", c: "US" },
  { id: 299, s: "IIPR", n: "Innovative Industrial Properties Inc", c: "US" },
  { id: 300, s: "YELL", n: "Yellow Co", c: "US" },
  { id: 301, s: "ULTA", n: "Ulta Beauty Inc", c: "US" },
  { id: 302, s: "D", n: "Dominion Energy Inc", c: "US" },
  { id: 303, s: "SRPT", n: "Sarepta Therapeutics Inc", c: "US" },
  { id: 304, s: "PLD", n: "Prologis Inc", c: "US" },
  { id: 305, s: "SIEN", n: "Sientra Inc", c: "US" },
  { id: 306, s: "HAL", n: "Halliburton Company", c: "US" },
  { id: 307, s: "OPEN", n: "Opendoor Technologies Inc", c: "US" },
  { id: 308, s: "WPC", n: "W P Carey Inc", c: "US" },
  { id: 309, s: "BRK-A", n: "Berkshire Hathaway Inc", c: "US" },
  { id: 310, s: "TELL", n: "Tellurian Inc", c: "US" },
  { id: 311, s: "SBSW", n: "Sibanye Gold ADR", c: "US" },
  { id: 312, s: "INTU", n: "Intuit Inc", c: "US" },
  { id: 313, s: "WMB", n: "Williams Companies Inc", c: "US" },
  { id: 314, s: "LYFT", n: "LYFT Inc", c: "US" },
  { id: 315, s: "PRU", n: "Prudential Financial Inc", c: "US" },
  { id: 316, s: "IDEX", n: "Ideanomics Inc", c: "US" },
  { id: 317, s: "BE", n: "Bloom Energy Co", c: "US" },
  { id: 318, s: "HUM", n: "Humana Inc", c: "US" },
  { id: 319, s: "DHC", n: "Diversified Healthcare Trust", c: "US" },
  { id: 320, s: "HBAN", n: "Huntington Bancshares Incorporated", c: "US" },
  { id: 321, s: "TMO", n: "Thermo Fisher Scientific Inc", c: "US" },
  { id: 322, s: "INO", n: "Inovio Pharmaceuticals Inc", c: "US" },
  { id: 323, s: "SLG", n: "SL Green Realty Co", c: "US" },
  { id: 324, s: "LYB", n: "LyondellBasell Industries NV", c: "US" },
  { id: 325, s: "CMA", n: "Comerica Inc", c: "US" },
  { id: 326, s: "IBRX", n: "Immunitybio Inc", c: "US" },
  { id: 327, s: "UNP", n: "Union Pacific Corporation", c: "US" },
  { id: 328, s: "MOS", n: "The Mosaic Company", c: "US" },
  { id: 329, s: "ACLS", n: "Axcelis Technologies Inc", c: "US" },
  { id: 330, s: "APA", n: "APA Corporation", c: "US" },
  { id: 331, s: "SNDL", n: "SNDL Inc.", c: "US" },
  { id: 332, s: "RITM", n: "Rithm Capital Corp.", c: "US" },
  { id: 333, s: "PSX", n: "Phillips 66", c: "US" },
  { id: 334, s: "NNDM", n: "Nano Dimension", c: "US" },
  { id: 335, s: "KSS", n: "Kohls Co", c: "US" },
  { id: 336, s: "FL", n: "Foot Locker Inc", c: "US" },
  { id: 337, s: "LEN", n: "Lennar Corporation", c: "US" },
  { id: 338, s: "MP", n: "MP Materials Co", c: "US" },
  { id: 339, s: "SPOT", n: "Spotify Technology SA", c: "US" },
  { id: 340, s: "BIG", n: "Big Lots Inc", c: "US" },
  { id: 341, s: "FANG", n: "Diamondback Energy Inc", c: "US" },
  { id: 342, s: "AITX", n: "Artificial Intelligence Technology Solutions Inc", c: "US" },
  { id: 343, s: "NUE", n: "Nucor Co", c: "US" },
  { id: 344, s: "MPC", n: "Marathon Petroleum Co", c: "US" },
  { id: 345, s: "GD", n: "General Dynamics Corporation", c: "US" },
  { id: 346, s: "CROX", n: "Crocs Inc", c: "US" },
  { id: 347, s: "FLNC", n: "Fluence Energy Inc", c: "US" },
  { id: 348, s: "ARDX", n: "Ardelyx Inc", c: "US" },
  { id: 349, s: "CL", n: "Colgate-Palmolive Company", c: "US" },
  { id: 350, s: "LXRX", n: "Lexicon Pharmaceuticals Inc", c: "US" },
  { id: 351, s: "MDGL", n: "Madrigal Pharmaceuticals Inc", c: "US" },
  { id: 352, s: "EQT", n: "EQT Corporation", c: "US" },
  { id: 353, s: "DLR", n: "Digital Realty Trust Inc", c: "US" },
  { id: 354, s: "SLB", n: "Schlumberger NV", c: "US" },
  { id: 355, s: "CCJ", n: "Cameco Co", c: "US" },
  { id: 356, s: "EQNR", n: "Equinor ASA ADR", c: "US" },
  { id: 357, s: "SU", n: "Suncor Energy Inc", c: "US" },
  { id: 358, s: "RAD", n: "Rite Aid Corporation", c: "US" },
  { id: 359, s: "VKTX", n: "Viking Therapeutics Inc", c: "US" },
  { id: 360, s: "MSTR", n: "MicroStrategy Incorporated", c: "US" },
  { id: 361, s: "BNTX", n: "BioNTech SE", c: "US" },
  { id: 362, s: "APLD", n: "Applied Blockchain Inc", c: "US" },
  { id: 363, s: "DXCM", n: "DexCom Inc", c: "US" },
  { id: 364, s: "COF", n: "Capital One Financial Corporation", c: "US" },
  { id: 365, s: "CFLT", n: "Confluent Inc", c: "US" },
  { id: 366, s: "DOCN", n: "DigitalOcean Holdings Inc", c: "US" },
  { id: 367, s: "FRCB", n: "First Republic Bank", c: "US" },
  { id: 368, s: "MPLX", n: "MPLX LP", c: "US" },
  { id: 369, s: "CALM", n: "Cal-Maine Foods Inc", c: "US" },
  { id: 370, s: "LTHM", n: "Livent Co", c: "US" },
  { id: 371, s: "XELA", n: "Exela Technologies Inc", c: "US" },
  { id: 372, s: "BYDDY", n: "BYD Co ADR", c: "US" },
  { id: 373, s: "MCHP", n: "Microchip Technology Inc", c: "US" },
  { id: 374, s: "LIN", n: "Linde PLC", c: "US" },
  { id: 375, s: "SABR", n: "Sabre Corpo", c: "US" },
  { id: 376, s: "IOVA", n: "Iovance Biotherapeutics Inc", c: "US" },
  { id: 377, s: "NOC", n: "Northrop Grumman Corporation", c: "US" },
  { id: 378, s: "BBAI", n: "BigBearai Holdings Inc", c: "US" },
  { id: 379, s: "AMTX", n: "Aemetis Inc", c: "US" },
  { id: 380, s: "SBLK", n: "Star Bulk Carriers Co", c: "US" },
  { id: 381, s: "PSEC", n: "Prospect Capital Corporation", c: "US" },
  { id: 382, s: "SWN", n: "Southwestern Energy Company", c: "US" },
  { id: 383, s: "AMRN", n: "Amarin Corporation PLC", c: "US" },
  { id: 384, s: "WM", n: "Waste Management Inc", c: "US" },
  { id: 385, s: "NTR", n: "Nutrien", c: "US" },
  { id: 386, s: "FUBO", n: "Fubotv Inc", c: "US" },
  { id: 387, s: "IRM", n: "Iron Mountain Incorporated", c: "US" },
  { id: 388, s: "NWL", n: "Newell Brands Inc", c: "US" },
  { id: 389, s: "VICI", n: "VICI Properties Inc", c: "US" },
  { id: 390, s: "MDLZ", n: "Mondelez International Inc", c: "US" },
  { id: 391, s: "DELL", n: "Dell Technologies Inc", c: "US" },
  { id: 392, s: "EPAM", n: "EPAM Systems Inc", c: "US" },
  { id: 393, s: "W", n: "Wayfair Inc", c: "US" },
  { id: 394, s: "ARWR", n: "Arrowhead Pharmaceuticals Inc", c: "US" },
  { id: 395, s: "WKHS", n: "Workhorse Group Inc", c: "US" },
  { id: 396, s: "SGEN", n: "Seagen Inc", c: "US" },
  { id: 397, s: "CI", n: "Cigna Co", c: "US" },
  { id: 398, s: "UL", n: "Unilever PLC ADR", c: "US" },
  { id: 399, s: "FITB", n: "Fifth Third BanCo", c: "US" },
  { id: 400, s: "ZION", n: "Zions Bancorporation", c: "US" },
  { id: 401, s: "SWK", n: "Stanley Black & Decker Inc", c: "US" },
  { id: 402, s: "AA", n: "Alcoa Co", c: "US" },
  { id: 403, s: "GSK", n: "GlaxoSmithKline PLC ADR", c: "US" },
  { id: 404, s: "NVTA", n: "Invitae Co", c: "US" },
  { id: 405, s: "UBS", n: "UBS Group AG", c: "US" },
  { id: 406, s: "SONY", n: "Sony Group Co", c: "US" },
  { id: 407, s: "CIM", n: "Chimera Investment Corporation", c: "US" },
  { id: 408, s: "WPRT", n: "Westport Fuel Systems Inc", c: "US" },
  { id: 409, s: "REGN", n: "Regeneron Pharmaceuticals Inc", c: "US" },
  { id: 410, s: "ALLY", n: "Ally Financial Inc", c: "US" },
  { id: 411, s: "CPE", n: "Callon Petroleum Company", c: "US" },
  { id: 412, s: "VXRT", n: "Vaxart Inc", c: "US" },
  { id: 413, s: "APD", n: "Air Products and Chemicals Inc", c: "US" },
  { id: 414, s: "UNIT", n: "Uniti Group Inc", c: "US" },
  { id: 415, s: "INMD", n: "InMode", c: "US" },
  { id: 416, s: "JBLU", n: "JetBlue Airways Co", c: "US" },
  { id: 417, s: "STWD", n: "Starwood Property Trust Inc", c: "US" },
  { id: 418, s: "MNST", n: "Monster Beverage Co", c: "US" },
  { id: 419, s: "CDNS", n: "Cadence Design Systems Inc", c: "US" },
  { id: 420, s: "ETN", n: "Eaton Corporation PLC", c: "US" },
  { id: 421, s: "TROW", n: "T. Rowe Price Group Inc", c: "US" },
  { id: 422, s: "EDIT", n: "Editas Medicine Inc", c: "US" },
  { id: 423, s: "NTLA", n: "Intellia Therapeutics Inc", c: "US" },
  { id: 424, s: "UAN", n: "CVR Partners LP", c: "US" },
  { id: 425, s: "MVST", n: "Microvast Holdings Inc", c: "US" },
  { id: 426, s: "HSY", n: "Hershey", c: "US" },
  { id: 427, s: "GIS", n: "General Mills Inc", c: "US" },
  { id: 428, s: "FVRR", n: "Fiverr International", c: "US" },
  { id: 429, s: "WDC", n: "Western Digital Corporation", c: "US" },
  { id: 430, s: "ELF", n: "ELF Beauty Inc", c: "US" },
  { id: 431, s: "CLOV", n: "Clover Health Investments Co", c: "US" },
  { id: 432, s: "ARLP", n: "Alliance Resource Partners LP", c: "US" },
  { id: 433, s: "OGN", n: "Organon &", c: "US" },
  { id: 434, s: "OHI", n: "Omega Healthcare Investors Inc", c: "US" },
  { id: 435, s: "SPLK", n: "Splunk Inc", c: "US" },
  { id: 436, s: "LNC", n: "Lincoln National Corporation", c: "US" },
  { id: 437, s: "EMR", n: "Emerson Electric Company", c: "US" },
  { id: 438, s: "DHR", n: "Danaher Corporation", c: "US" },
  { id: 439, s: "FHN", n: "First Horizon National Corporation", c: "US" },
  { id: 440, s: "AEP", n: "American Electric Power Company Inc", c: "US" },
  { id: 441, s: "MCRB", n: "Seres Therapeutics Inc", c: "US" },
  { id: 442, s: "SLS", n: "Sellas Life Sciences Group Inc", c: "US" },
  { id: 443, s: "KLAC", n: "KLA-Tencor Corporation", c: "US" },
  { id: 444, s: "PERI", n: "Perion Network", c: "US" },
  { id: 445, s: "HPE", n: "Hewlett Packard Enterprise", c: "US" },
  { id: 446, s: "PNC", n: "PNC Financial Services Group Inc", c: "US" },
  { id: 447, s: "ADI", n: "Analog Devices Inc", c: "US" },
  { id: 448, s: "MMP", n: "Magellan Midstream Partners LP", c: "US" },
  { id: 449, s: "SMR", n: "Nuscale Power Co", c: "US" },
  { id: 450, s: "HL", n: "Hecla Mining Company", c: "US" },
  { id: 451, s: "CPNG", n: "Coupang LLC", c: "US" },
  { id: 452, s: "BKNG", n: "Booking Holdings Inc", c: "US" },
  { id: 453, s: "OXLC", n: "Oxford Lane Capital Co", c: "US" },
  { id: 454, s: "VRM", n: "Vroom Inc", c: "US" },
  { id: 455, s: "TTE", n: "TotalEnergies SE ADR", c: "US" },
  { id: 456, s: "LEV", n: "Lion Electric Co", c: "US" },
  { id: 457, s: "EOG", n: "EOG Resources Inc", c: "US" },
  { id: 458, s: "CFG", n: "Citizens Financial Group Inc", c: "US" },
  { id: 459, s: "WE", n: "WeWork Inc", c: "US" },
  { id: 460, s: "BBY", n: "Best Buy Co. Inc", c: "US" },
  { id: 461, s: "MET", n: "MetLife Inc", c: "US" },
  { id: 462, s: "TECK", n: "Teck Resources Class B", c: "US" },
  { id: 463, s: "TCOM", n: "Trip.com Group ADR", c: "US" },
  { id: 464, s: "RUN", n: "Sunrun Inc", c: "US" },
  { id: 465, s: "APPS", n: "Digital Turbine Inc", c: "US" },
  { id: 466, s: "AZN", n: "AstraZeneca PLC ADR", c: "US" },
  { id: 467, s: "DQ", n: "Daqo New Energy Corp ADR", c: "US" },
  { id: 468, s: "SWAV", n: "Shockwave Medical Inc", c: "US" },
  { id: 469, s: "NDAQ", n: "Nasdaq Inc", c: "US" },
  { id: 470, s: "IP", n: "International Paper", c: "US" },
  { id: 471, s: "ADP", n: "Automatic Data Processing Inc", c: "US" },
  { id: 472, s: "VEEV", n: "Veeva Systems Inc Class A", c: "US" },
  { id: 473, s: "LMND", n: "Lemonade Inc", c: "US" },
  { id: 474, s: "SJT", n: "San Juan Basin Royalty Trust", c: "US" },
  { id: 475, s: "CF", n: "CF Industries Holdings Inc", c: "US" },
  { id: 476, s: "KMB", n: "Kimberly-Clark Corporation", c: "US" },
  { id: 477, s: "MTTR", n: "Matterport Inc", c: "US" },
  { id: 478, s: "K", n: "Kellanova", c: "US" },
  { id: 479, s: "NXPI", n: "NXP Semiconductors NV", c: "US" },
  { id: 480, s: "LEG", n: "Leggett & Platt Incorporated", c: "US" },
  { id: 481, s: "HTGC", n: "Hercules Capital Inc", c: "US" },
  { id: 482, s: "TAP", n: "Molson Coors Brewing Co Class B", c: "US" },
  { id: 483, s: "TD", n: "Toronto Dominion Bank", c: "US" },
  { id: 484, s: "FSK", n: "FS KKR Capital Co", c: "US" },
  { id: 485, s: "AEM", n: "Agnico Eagle Mines Limited", c: "US" },
  { id: 486, s: "005930.KS", n: "Samsung Electronics", c: "Korea" },
  { id: 487, s: "MAR", n: "Marriott International Inc", c: "US" },
  { id: 488, s: "DPZ", n: "Domino’s Pizza Inc", c: "US" },
  { id: 489, s: "SPWR", n: "SunPower Corporation", c: "US" },
  { id: 490, s: "SLDP", n: "Solid Power Inc", c: "US" },
  { id: 491, s: "UNFI", n: "United Natural Foods Inc", c: "US" },
  { id: 492, s: "OPK", n: "Opko Health Inc", c: "US" },
  { id: 493, s: "TTOO", n: "T2 Biosystms Inc", c: "US" },
  { id: 494, s: "BXP", n: "Boston Properties Inc", c: "US" },
  { id: 495, s: "ACHR", n: "Archer Aviation Inc", c: "US" },
  { id: 496, s: "CSX", n: "CSX Corporation", c: "US" },
  { id: 497, s: "SWKS", n: "Skyworks Solutions Inc", c: "US" },
  { id: 498, s: "LNG", n: "Cheniere Energy Inc", c: "US" },
  { id: 499, s: "AZO", n: "AutoZone Inc", c: "US" },
  { id: 500, s: "SC", n: "Southern Copper Corporation", c: "US" },
  { id: 501, s: "PSTG", n: "Pure Storage Inc", c: "US" },
  { id: 502, s: "CHK", n: "Chesapeake Energy Co", c: "US" },
  { id: 503, s: "OCUL", n: "Ocular Therapeutix Inc", c: "US" },
  { id: 504, s: "PSNY", n: "Polestar Automotive Holding UK PLC Class A ADS", c: "US" },
  { id: 505, s: "UEC", n: "Uranium Energy Co", c: "US" },
  { id: 506, s: "UMC", n: "United Microelectronics", c: "US" },
  { id: 507, s: "SEDG", n: "SolarEdge Technologies Inc", c: "US" },
  { id: 508, s: "SMAR", n: "Smartsheet Inc", c: "US" },
  { id: 509, s: "EXPE", n: "Expedia Group Inc.", c: "US" },
  { id: 510, s: "PLNT", n: "Planet Fitness Inc", c: "US" },
  { id: 511, s: "MESO", n: "Mesoblast", c: "US" },
  { id: 512, s: "CRH", n: "CRH PLC ADR", c: "US" },
  { id: 513, s: "PSA", n: "Public Storage", c: "US" },
  { id: 514, s: "CANO", n: "Cano Health Inc", c: "US" },
  { id: 515, s: "OUST", n: "Ouster Inc", c: "US" },
  { id: 516, s: "CFRX", n: "ContraFect Co", c: "US" },
  { id: 517, s: "WOLF", n: "Wolfspeed Inc", c: "US" },
  { id: 518, s: "TJX", n: "The TJX Companies Inc", c: "US" },
  { id: 519, s: "QM", n: "Quantum Corporation", c: "US" },
  { id: 520, s: "HMC", n: "Honda Motor Co ADR", c: "US" },
  { id: 521, s: "APP", n: "Applovin Co", c: "US" },
  { id: 522, s: "DLTR", n: "Dollar Tree Inc", c: "US" },
  { id: 523, s: "BLDR", n: "Builders FirstSource Inc", c: "US" },
  { id: 524, s: "BYDDF", n: "BYD Company Limited", c: "US" },
  { id: 525, s: "MCK", n: "McKesson Corporation", c: "US" },
  { id: 526, s: "CIEN", n: "Ciena Co", c: "US" },
  { id: 527, s: "CSIQ", n: "Canadian Solar Inc", c: "US" },
  { id: 528, s: "OPRA", n: "Opera", c: "US" },
  { id: 529, s: "PAAS", n: "Pan American Silver Corp.", c: "US" },
  { id: 530, s: "GT", n: "Goodyear Tire & Rubber", c: "US" },
  { id: 531, s: "RC", n: "Ready Capital Co", c: "US" },
  { id: 532, s: "TCEHY", n: "Tencent Holdings ADR", c: "US" },
  { id: 533, s: "L", n: "Loews Co", c: "US" },
  { id: 534, s: "HCP", n: "Hashicorp Inc", c: "US" },
  { id: 535, s: "SNPS", n: "Synopsys Inc", c: "US" },
  { id: 536, s: "CWH", n: "Camping World Holdings Inc", c: "US" },
  { id: 537, s: "TWO", n: "Two Harbors Investments Co", c: "US" },
  { id: 538, s: "ASTS", n: "Ast Spacemobile Inc", c: "US" },
  { id: 539, s: "A", n: "Agilent Technologies Inc", c: "US" },
  { id: 540, s: "STAG", n: "STAG Industrial Inc", c: "US" },
  { id: 541, s: "ODFL", n: "Old Dominion Freight Line Inc", c: "US" },
  { id: 542, s: "UA", n: "Under Armour Inc C", c: "US" },
  { id: 543, s: "AR", n: "Antero Resources Co", c: "US" },
  { id: 544, s: "INTT", n: "inTest Corporation", c: "US" },
  { id: 545, s: "COHR", n: "Coherent Inc", c: "US" },
  { id: 546, s: "JBL", n: "Jabil Circuit Inc", c: "US" },
  { id: 547, s: "DEO", n: "Diageo PLC ADR", c: "US" },
  { id: 548, s: "SMTC", n: "Semtech Corporation", c: "US" },
  { id: 549, s: "AUPH", n: "Aurinia Pharmaceuticals Inc", c: "US" },
  { id: 550, s: "EC", n: "Ecopetrol SA ADR", c: "US" },
  { id: 551, s: "PHM", n: "PulteGroup Inc", c: "US" },
  { id: 552, s: "DHI", n: "DR Horton Inc", c: "US" },
  { id: 553, s: "TEAM", n: "Atlassian Corp Plc", c: "US" },
  { id: 554, s: "ZTS", n: "Zoetis Inc", c: "US" },
  { id: 555, s: "KOD", n: "Kodiak Sciences Inc", c: "US" },
  { id: 556, s: "PFLT", n: "PennantPark Floating Rate Capital", c: "US" },
  { id: 557, s: "AKBA", n: "Akebia Ther", c: "US" },
  { id: 558, s: "TOL", n: "Toll Brothers Inc", c: "US" },
  { id: 559, s: "JWN", n: "Nordstrom Inc", c: "US" },
  { id: 560, s: "EQIX", n: "Equinix Inc", c: "US" },
  { id: 561, s: "SNOA", n: "Sonoma Pharmaceuticals Inc", c: "US" },
  { id: 562, s: "WU", n: "Western Union", c: "US" },
  { id: 563, s: "SPGI", n: "S&P Global Inc", c: "US" },
  { id: 564, s: "ASO", n: "Academy Sports Outdoors Inc", c: "US" },
  { id: 565, s: "WISH", n: "Contextlogic Inc", c: "US" },
  { id: 566, s: "DSX", n: "Diana Shipping inc", c: "US" },
  { id: 567, s: "SAVE", n: "Spirit Airlines Inc", c: "US" },
  { id: 568, s: "VERU", n: "Veru Inc", c: "US" },
  { id: 569, s: "PAA", n: "Plains All American Pipeline LP", c: "US" },
  { id: 570, s: "SGML", n: "Sigma Lithium Resources Co", c: "US" },
  { id: 571, s: "EBAY", n: "eBay Inc", c: "US" },
  { id: 572, s: "BARK", n: "Original Bark", c: "US" },
  { id: 573, s: "NVS", n: "Novartis AG ADR", c: "US" },
  { id: 574, s: "NSRGY", n: "Nestle SA ADR", c: "US" },
  { id: 575, s: "ARE", n: "Alexandria Real Estate Equities Inc", c: "US" },
  { id: 576, s: "OMF", n: "OneMain Holdings Inc", c: "US" },
  { id: 577, s: "GMDA", n: "Gamida Cell", c: "US" },
  { id: 578, s: "SYK", n: "Stryker Corporation", c: "US" },
  { id: 579, s: "DD", n: "Dupont De Nemours Inc", c: "US" },
  { id: 580, s: "HBI", n: "Hanesbrands Inc", c: "US" },
  { id: 581, s: "DDD", n: "3D Systems Corporation", c: "US" },
  { id: 582, s: "DASH", n: "DoorDash, Inc. Class A Common Stock", c: "US" },
  { id: 583, s: "BLDP", n: "Ballard Power Systems Inc", c: "US" },
  { id: 584, s: "GPS", n: "Gap Inc", c: "US" },
  { id: 585, s: "CPRX", n: "Catalyst Pharmaceuticals Inc", c: "US" },
  { id: 586, s: "SBGI", n: "Sinclair Broadcast Group Inc", c: "US" },
  { id: 587, s: "BIOR", n: "Biora Therapeutics Inc", c: "US" },
  { id: 588, s: "AXON", n: "Axon Enterprise Inc.", c: "US" },
  { id: 589, s: "PLX", n: "Protalix Biotherapeutics Inc", c: "US" },
  { id: 590, s: "DM", n: "Desktop Metal Inc", c: "US" },
  { id: 591, s: "RMBS", n: "Rambus Inc", c: "US" },
  { id: 592, s: "IFF", n: "International Flavors & Fragrances Inc", c: "US" },
  { id: 593, s: "ORGN", n: "Origin Materials Inc", c: "US" },
  { id: 594, s: "VOW3.DE", n: "Volkswagen AG VZO O.N.", c: "GER" },
  { id: 595, s: "GNRC", n: "Generac Holdings Inc", c: "US" },
  { id: 596, s: "WDAY", n: "Workday Inc", c: "US" },
  { id: 597, s: "RHM.DE", n: "Rheinmetall AG", c: "GER" },
  { id: 598, s: "MSB", n: "Mesabi Trust", c: "US" },
  { id: 599, s: "MQ", n: "Marqeta Inc", c: "US" },
  { id: 600, s: "PCG", n: "PG&E Co", c: "US" },
  { id: 601, s: "CLX", n: "The Clorox Company", c: "US" },
  { id: 602, s: "CP", n: "Canadian Pacific Railway", c: "US" },
  { id: 603, s: "CMI", n: "Cummins Inc", c: "US" },
  { id: 604, s: "FIS", n: "Fidelity National Information Services Inc", c: "US" },
  { id: 605, s: "FSLY", n: "Fastly Inc", c: "US" },
  { id: 606, s: "URI", n: "United Rentals Inc", c: "US" },
  { id: 607, s: "CAG", n: "ConAgra Foods Inc", c: "US" },
  { id: 608, s: "MC.PA", n: "LVMH Moët Hennessy - Louis Vuitton Société Européenne", c: "FR" },
  { id: 609, s: "VMW", n: "VMware Inc", c: "US" },
  { id: 610, s: "WPM", n: "Wheaton Precious Metals Co", c: "US" },
  { id: 611, s: "QUBT", n: "Quantum Computing Inc", c: "US" },
  { id: 612, s: "CTVA", n: "Corteva Inc", c: "US" },
  { id: 613, s: "BXMT", n: "Blackstone Mortgage Trust Inc", c: "US" },
  { id: 614, s: "IQ", n: "iQIYI Inc", c: "US" },
  { id: 615, s: "HUBS", n: "HubSpot Inc", c: "US" },
  { id: 616, s: "ABCL", n: "Abcellera Biologics Inc", c: "US" },
  { id: 617, s: "WEN", n: "The Wendy’s", c: "US" },
  { id: 618, s: "MSCI", n: "MSCI Inc", c: "US" },
  { id: 619, s: "BYD", n: "Boyd Gaming Corporation", c: "US" },
  { id: 620, s: "TS", n: "Tractor Supply Company", c: "US" },
  { id: 621, s: "CGEN", n: "Compugen", c: "US" },
  { id: 622, s: "SUN", n: "Sunoco LP", c: "US" },
  { id: 623, s: "BTU", n: "Peabody Energy Co", c: "US" },
  { id: 624, s: "WYNN", n: "Wynn Resorts Limited", c: "US" },
  { id: 625, s: "ALK", n: "Alaska Air Group Inc", c: "US" },
  { id: 626, s: "ADSK", n: "Autodesk Inc", c: "US" },
  { id: 627, s: "CVI", n: "CVR Energy Inc", c: "US" },
  { id: 628, s: "STX", n: "Seagate Technology PLC", c: "US" },
  { id: 629, s: "PLL", n: "Piedmont Lithium ADR", c: "US" },
  { id: 630, s: "ZOM", n: "Zomedica Pharmaceuticals Co", c: "US" },
  { id: 631, s: "CTXR", n: "Citius Pharmaceuticals Inc", c: "US" },
  { id: 632, s: "ORC", n: "Orchid Island Capital Inc.", c: "US" },
  { id: 633, s: "OMER", n: "Omeros Corporation", c: "US" },
  { id: 634, s: "HRL", n: "Hormel Foods Corporation", c: "US" },
  { id: 635, s: "PBF", n: "PBF Energy Inc", c: "US" },
  { id: 636, s: "WSM", n: "Williams-Sonoma Inc", c: "US" },
  { id: 637, s: "STM", n: "STMicroelectronics NV ADR", c: "US" },
  { id: 638, s: "CARR", n: "Carrier Global Co", c: "US" },
  { id: 639, s: "AVDL", n: "Avadel Pharmaceuticals PLC", c: "US" },
  { id: 640, s: "SSYS", n: "Stratasys", c: "US" },
  { id: 641, s: "CVM", n: "CEL-SCI Co", c: "US" },
  { id: 642, s: "STZ", n: "Constellation Brands Inc Class A", c: "US" },
  { id: 643, s: "HIMX", n: "Himax Technologies Inc", c: "US" },
  { id: 644, s: "TER", n: "Teradyne Inc", c: "US" },
  { id: 645, s: "TOST", n: "Toast Inc", c: "US" },
  { id: 646, s: "GSIT", n: "GSI Technology Inc", c: "US" },
  { id: 647, s: "VTNR", n: "Vertex Energy Inc", c: "US" },
  { id: 648, s: "COKE", n: "Coca-Cola Consolidated Inc.", c: "US" },
  { id: 649, s: "INFY", n: "Infosys ADR", c: "US" },
  { id: 650, s: "TMC", n: "TMC the metals company Inc", c: "US" },
  { id: 651, s: "BN", n: "Brookfield Co", c: "US" },
  { id: 652, s: "GWW", n: "WW Grainger Inc", c: "US" },
  { id: 653, s: "BNS", n: "Bank of Nova Scotia", c: "US" },
  { id: 654, s: "EXTR", n: "Extreme Networks Inc", c: "US" },
  { id: 655, s: "YEXT", n: "Yext Inc", c: "US" },
  { id: 656, s: "WHR", n: "Whirlpool Corporation", c: "US" },
  { id: 657, s: "AFL", n: "Aflac Incorporated", c: "US" },
  { id: 658, s: "MNDY", n: "Monday.Com", c: "US" },
  { id: 659, s: "AG", n: "First Majestic Silver Co", c: "US" },
  { id: 660, s: "MGM", n: "MGM Resorts International", c: "US" },
  { id: 661, s: "ARRY", n: "Array Technologies Inc", c: "US" },
  { id: 662, s: "DVAX", n: "Dynavax Technologies Corporation", c: "US" },
  { id: 663, s: "TTWO", n: "Take-Two Interactive Software Inc", c: "US" },
  { id: 664, s: "OCGN", n: "Ocugen Inc", c: "US" },
  { id: 665, s: "UI", n: "Ubiquiti Networks Inc", c: "US" },
  { id: 666, s: "BSM", n: "Black Stone Minerals LP", c: "US" },
  { id: 667, s: "BK", n: "Bank of New York Mellon", c: "US" },
  { id: 668, s: "CLFD", n: "Clearfield Inc", c: "US" },
  { id: 669, s: "ACRX", n: "AcelRx Pharmaceuticals Inc", c: "US" },
  { id: 670, s: "SJM", n: "JM Smucker Company", c: "US" },
  { id: 671, s: "INCY", n: "Incyte Corporation", c: "US" },
  { id: 672, s: "JMIA", n: "Jumia Technologies AG", c: "US" },
  { id: 673, s: "OSK", n: "Oshkosh Corporation", c: "US" },
  { id: 674, s: "CEG", n: "Constellation Energy Co", c: "US" },
  { id: 675, s: "CSWC", n: "Capital Southwest Corporation", c: "US" },
  { id: 676, s: "LVS", n: "Las Vegas Sands Co", c: "US" },
  { id: 677, s: "SHW", n: "Sherwin-Williams", c: "US" },
  { id: 678, s: "MED", n: "MEDIFAST INC", c: "US" },
  { id: 679, s: "EXPI", n: "eXp World Holdings Inc", c: "US" },
  { id: 680, s: "DX", n: "Dynex Capital Inc", c: "US" },
  { id: 681, s: "KD", n: "Kyndryl Holdings Inc", c: "US" },
  { id: 682, s: "EMAN", n: "eMagin Corporation", c: "US" },
  { id: 683, s: "AYX", n: "Alteryx Inc", c: "US" },
  { id: 684, s: "PH", n: "Parker-Hannifin Corporation", c: "US" },
  { id: 685, s: "CB", n: "Chubb", c: "US" },
  { id: 686, s: "BILL", n: "Bill Com Holdings Inc", c: "US" },
  { id: 687, s: "LPG", n: "Dorian LPG", c: "US" },
  { id: 688, s: "NTDOY", n: "Nintendo Co ADR", c: "US" },
  { id: 689, s: "BAM", n: "Brookfield Asset Management Inc", c: "US" },
  { id: 690, s: "GTE", n: "Gran Tierra Energy Inc", c: "US" },
  { id: 691, s: "EOSE", n: "Eos Energy Enterprises Inc", c: "US" },
  { id: 692, s: "MRKR", n: "Marker Therapeutics Inc", c: "US" },
  { id: 693, s: "HRZN", n: "Horizon Technology Finance", c: "US" },
  { id: 694, s: "YUM", n: "Yum! Brands Inc", c: "US" },
  { id: 695, s: "KKR", n: "KKR & Co LP", c: "US" },
  { id: 696, s: "RKT", n: "Rocket Companies Inc", c: "US" },
  { id: 697, s: "RPRX", n: "Royalty Pharma Plc", c: "US" },
  { id: 698, s: "MPWR", n: "Monolithic Power Systems Inc", c: "US" },
  { id: 699, s: "PENN", n: "Penn National Gaming Inc", c: "US" },
  { id: 700, s: "SKLZ", n: "Skillz Platform Inc", c: "US" },
  { id: 701, s: "TRKA", n: "Troika Media Group Inc", c: "US" },
  { id: 702, s: "LHX", n: "L3Harris Technologies Inc", c: "US" },
  { id: 703, s: "AIG", n: "American International Group Inc", c: "US" },
  { id: 704, s: "KNF", n: "Knife River Corporation", c: "US" },
  { id: 705, s: "DFS", n: "Discover Financial Services", c: "US" },
  { id: 706, s: "CAH", n: "Cardinal Health Inc", c: "US" },
  { id: 707, s: "Z", n: "Zillow Group Inc Class C", c: "US" },
  { id: 708, s: "BEN", n: "Franklin Resources Inc", c: "US" },
  { id: 709, s: "GLBE", n: "Global-E Online", c: "US" },
  { id: 710, s: "BLUE", n: "Bluebird bio Inc", c: "US" },
  { id: 711, s: "EFC", n: "Ellington Financial LLC", c: "US" },
  { id: 712, s: "ALGM", n: "Allegro Microsystems Inc", c: "US" },
  { id: 713, s: "CPRT", n: "Copart Inc", c: "US" },
  { id: 714, s: "DT", n: "Dynatrace Holdings LLC", c: "US" },
  { id: 715, s: "KSCP", n: "Knightscope Inc", c: "US" },
  { id: 716, s: "BKR", n: "Baker Hughes", c: "US" },
  { id: 717, s: "PACB", n: "Pacific Biosciences of California", c: "US" },
  { id: 718, s: "LNTH", n: "Lantheus Holdings Inc", c: "US" },
  { id: 719, s: "BCRX", n: "BioCryst Pharmaceuticals Inc", c: "US" },
  { id: 720, s: "EMN", n: "Eastman Chemical Company", c: "US" },
  { id: 721, s: "DKS", n: "Dick’s Sporting Goods Inc", c: "US" },
  { id: 722, s: "HIBB", n: "Hibbett Sports Inc", c: "US" },
  { id: 723, s: "EPR", n: "EPR Properties", c: "US" },
  { id: 724, s: "VNO", n: "Vornado Realty Trust", c: "US" },
  { id: 725, s: "GSAT", n: "Globalstar Inc", c: "US" },
  { id: 726, s: "MTB", n: "M&T Bank Co", c: "US" },
  { id: 727, s: "ADS.DE", n: "adidas AG", c: "GER" },
  { id: 728, s: "GEN", n: "Gen Digital Inc.", c: "US" },
  { id: 729, s: "RF", n: "Regions Financial Corporation", c: "US" },
  { id: 730, s: "PPL", n: "PPL Corporation", c: "US" },
  { id: 731, s: "CTLT", n: "Catalent Inc", c: "US" },
  { id: 732, s: "ALGN", n: "Align Technology Inc", c: "US" },
  { id: 733, s: "WY", n: "Weyerhaeuser Company", c: "US" },
  { id: 734, s: "GNK", n: "Genco Shipping & Trading", c: "US" },
  { id: 735, s: "ECL", n: "Ecolab Inc", c: "US" },
  { id: 736, s: "TNET", n: "TriNet Group Inc", c: "US" },
  { id: 737, s: "LKNCY", n: "Luckin Coffee", c: "US" },
  { id: 738, s: "ED", n: "Consolidated Edison Inc", c: "US" },
  { id: 739, s: "ORLY", n: "O’Reilly Automotive Inc", c: "US" },
  { id: 740, s: "CME", n: "CME Group Inc", c: "US" },
  { id: 741, s: "BKSY", n: "Blacksky Technology Inc", c: "US" },
  { id: 742, s: "LOVE", n: "The Lovesac Company", c: "US" },
  { id: 743, s: "VS", n: "Victoria's Secret &", c: "US" },
  { id: 744, s: "KDP", n: "Keurig Dr Pepper Inc", c: "US" },
  { id: 745, s: "CLNE", n: "Clean Energy Fuels Co", c: "US" },
  { id: 746, s: "ITW", n: "Illinois Tool Works Inc", c: "US" },
  { id: 747, s: "WGO", n: "Winnebago Industries Inc", c: "US" },
  { id: 748, s: "NTNX", n: "Nutanix Inc", c: "US" },
  { id: 749, s: "ABEV", n: "Ambev SA ADR", c: "US" },
  { id: 750, s: "OTLY", n: "Oatly Group AB  ADR", c: "US" },
  { id: 751, s: "BGFV", n: "Big 5 Sporting Goods Corporation", c: "US" },
  { id: 752, s: "CNC", n: "Centene Co", c: "US" },
  { id: 753, s: "LICY", n: "LiCycle Holdings Co", c: "US" },
  { id: 754, s: "SYY", n: "Sysco Corporation", c: "US" },
  { id: 755, s: "NGL", n: "NGL Energy Partners LP", c: "US" },
  { id: 756, s: "EGIO", n: "Edgio Inc", c: "US" },
  { id: 757, s: "ALDX", n: "Aldeyra The", c: "US" },
  { id: 758, s: "COO", n: "The Cooper Companies, Inc. Common Stock", c: "US" },
  { id: 759, s: "BDN", n: "Brandywine Realty Trust", c: "US" },
  { id: 760, s: "HOG", n: "Harley-Davidson Inc", c: "US" },
  { id: 761, s: "MMAT", n: "Meta Materials Inc", c: "US" },
  { id: 762, s: "MSI", n: "Motorola Solutions Inc", c: "US" },
  { id: 763, s: "RDFN", n: "Redfin Co", c: "US" },
  { id: 764, s: "BAX", n: "Baxter International Inc", c: "US" },
  { id: 765, s: "VNDA", n: "Vanda Pharmaceuticals Inc", c: "US" },
  { id: 766, s: "EVA", n: "Enviva Partners LP", c: "US" },
  { id: 767, s: "LTC", n: "LTC Properties Inc", c: "US" },
  { id: 768, s: "EXC", n: "Exelon Corporation", c: "US" },
  { id: 769, s: "VTLE", n: "Vital Energy Inc.", c: "US" },
  { id: 770, s: "BDX", n: "Becton Dickinson and Company", c: "US" },
  { id: 771, s: "ROK", n: "Rockwell Automation Inc", c: "US" },
  { id: 772, s: "IVR", n: "Invesco Mortgage Capital Inc", c: "US" },
  { id: 773, s: "CX", n: "Cemex SAB de CV ADR", c: "US" },
  { id: 774, s: "PCAR", n: "PACCAR Inc", c: "US" },
  { id: 775, s: "BHC", n: "Bausch Health Companies Inc", c: "US" },
  { id: 776, s: "CHTR", n: "Charter Communications Inc", c: "US" },
  { id: 777, s: "GPC", n: "Genuine Parts", c: "US" },
  { id: 778, s: "QSR", n: "Restaurant Brands International Inc", c: "US" },
  { id: 779, s: "RH", n: "RH", c: "US" },
  { id: 780, s: "KMX", n: "CarMax Inc", c: "US" },
  { id: 781, s: "KGC", n: "Kinross Gold Corporation", c: "US" },
  { id: 782, s: "FARM", n: "Farmer Bros.", c: "US" },
  { id: 783, s: "OVV", n: "Ovintiv Inc", c: "US" },
  { id: 784, s: "VUZI", n: "Vuzix Corp Cmn Stk", c: "US" },
  { id: 785, s: "NSC", n: "Norfolk Southern Corporation", c: "US" },
  { id: 786, s: "DOCS", n: "Doximity Inc", c: "US" },
  { id: 787, s: "ICPT", n: "Intercept Pharmaceuticals Inc", c: "US" },
  { id: 788, s: "SLI", n: "Standard Lithium", c: "US" },
  { id: 789, s: "T.TO", n: "Telus Co", c: "CA" },
  { id: 790, s: "SIMO", n: "Silicon Motion Technology", c: "US" },
  { id: 791, s: "SDGR", n: "Schrodinger Inc", c: "US" },
  { id: 792, s: "VRT", n: "Vertiv Holdings", c: "US" },
  { id: 793, s: "PAYC", n: "Paycom Soft", c: "US" },
  { id: 794, s: "HIMS", n: "Hims Hers Health Inc", c: "US" },
  { id: 795, s: "ASAN", n: "Asana Inc", c: "US" },
  { id: 796, s: "BFLY", n: "Butterfly Network Inc", c: "US" },
  { id: 797, s: "CRK", n: "Comstock Resources Inc", c: "US" },
  { id: 798, s: "SAP", n: "SAP SE ADR", c: "US" },
  { id: 799, s: "IMPP", n: "Imperial Petroleum Inc", c: "US" },
  { id: 800, s: "UP", n: "Wheels Up Experience Inc", c: "US" },
  { id: 801, s: "CNQ", n: "Canadian Natural Resources", c: "US" },
  { id: 802, s: "EDAP", n: "EDAP TMS SA", c: "US" },
  { id: 803, s: "HYMC", n: "Hycroft Mining Holding Corporation", c: "US" },
  { id: 804, s: "NFE", n: "New Fortress Energy LLC", c: "US" },
  { id: 805, s: "HSBC", n: "HSBC Holdings PLC ADR", c: "US" },
  { id: 806, s: "ICL", n: "ICL Israel Chemicals", c: "US" },
  { id: 807, s: "YPF", n: "YPF Sociedad Anonima", c: "US" },
  { id: 808, s: "BTG", n: "B2Gold Co", c: "US" },
  { id: 809, s: "BMW.DE", n: "Bayerische Motoren Werke Aktiengesellschaft", c: "GER" },
  { id: 810, s: "ACI", n: "Albertsons Companies", c: "US" },
  { id: 811, s: "OTLK", n: "OUTLOOK THERAPEUTICS INC", c: "US" },
  { id: 812, s: "NICE", n: "Nice ADR", c: "US" },
  { id: 813, s: "GEO", n: "Geo Group Inc", c: "US" },
  { id: 814, s: "COMP", n: "Compass Inc", c: "US" },
  { id: 815, s: "RACE", n: "Ferrari NV", c: "US" },
  { id: 816, s: "HUT", n: "Hut 8 Mining Co", c: "US" },
  { id: 817, s: "FBIO", n: "Fortress Biotech Inc", c: "US" },
  { id: 818, s: "DHT", n: "DHT Holdings Inc", c: "US" },
  { id: 819, s: "UUUU", n: "Energy Fuels Inc", c: "US" },
  { id: 820, s: "CLSK", n: "CleanSpark Inc", c: "US" },
  { id: 821, s: "UGI", n: "UGI Corporation", c: "US" },
  { id: 822, s: "FMC", n: "FMC Corporation", c: "US" },
  { id: 823, s: "SBR", n: "Sabine Royalty Trust", c: "US" },
  { id: 824, s: "OZSC", n: "Ozop Surgical Co", c: "US" },
  { id: 825, s: "HYZN", n: "Hyzon Motors Inc", c: "US" },
  { id: 826, s: "JKS", n: "JinkoSolar Holding Company Limited", c: "US" },
  { id: 827, s: "LLAP", n: "Terran Orbital Co", c: "US" },
  { id: 828, s: "DECK", n: "Deckers Outdoor Corporation", c: "US" },
  { id: 829, s: "MBG.DE", n: "Mercedes-Benz Group AG", c: "GER" },
  { id: 830, s: "SNY", n: "Sanofi ADR", c: "US" },
  { id: 831, s: "LEVI", n: "Levi Strauss & Co Class A", c: "US" },
  { id: 832, s: "GPK", n: "Graphic Packaging Holding Company", c: "US" },
  { id: 833, s: "GSL", n: "Global Ship Lease Inc", c: "US" },
  { id: 834, s: "HPP", n: "Hudson Pacific Properties Inc", c: "US" },
  { id: 835, s: "NRG", n: "NRG Energy Inc.", c: "US" },
  { id: 836, s: "KTOS", n: "Kratos Defense & Security Solutions", c: "US" },
  { id: 837, s: "MODG", n: "Callaway Golf Company", c: "US" },
  { id: 838, s: "STLD", n: "Steel Dynamics Inc", c: "US" },
  { id: 839, s: "LWLG", n: "Lightwave Logic Inc", c: "US" },
  { id: 840, s: "GOOD", n: "Gladstone Commercial Corporation", c: "US" },
  { id: 841, s: "TXRH", n: "Texas Roadhouse Inc", c: "US" },
  { id: 842, s: "PCRFY", n: "Panasonic Corp PK", c: "US" },
  { id: 843, s: "ME", n: "23Andme Holding", c: "US" },
  { id: 844, s: "YETI", n: "YETI Holdings Inc", c: "US" },
  { id: 845, s: "CRVS", n: "Corvus Pharmaceuticals Inc", c: "US" },
  { id: 846, s: "EFTR", n: "Effector Therapeutics Inc", c: "US" },
  { id: 847, s: "FREY", n: "FREYR Battery SA", c: "US" },
  { id: 848, s: "DAVA", n: "Endava", c: "US" },
  { id: 849, s: "PBT", n: "Permian Basin Royalty Trust", c: "US" },
  { id: 850, s: "HCMC", n: "Healthier Choices Management Corp.", c: "US" },
  { id: 851, s: "RVLV", n: "Revolve Group LLC", c: "US" },
  { id: 852, s: "TD.TO", n: "Toronto Dominion Bank", c: "CA" },
  { id: 853, s: "DBX", n: "Dropbox Inc", c: "US" },
  { id: 854, s: "NVTS", n: "Navitas Semiconductor Co", c: "US" },
  { id: 855, s: "MRNS", n: "Marinus Pharmaceuticals Inc", c: "US" },
  { id: 856, s: "AES", n: "The AES Corporation", c: "US" },
  { id: 857, s: "ASRT", n: "Assertio Therapeutics Inc", c: "US" },
  { id: 858, s: "SKX", n: "Skechers USA Inc", c: "US" },
  { id: 859, s: "CTSH", n: "Cognizant Technology Solutions Corp Class A", c: "US" },
  { id: 860, s: "LAND", n: "Gladstone Land Corporation", c: "US" },
  { id: 861, s: "CHGG", n: "Chegg Inc", c: "US" },
  { id: 862, s: "GGB", n: "Gerdau SA ADR", c: "US" },
  { id: 863, s: "REI", n: "Ring Energy Inc", c: "US" },
  { id: 864, s: "DBI", n: "Designer Brands Inc", c: "US" },
  { id: 865, s: "BWA", n: "BorgWarner Inc", c: "US" },
  { id: 866, s: "BPT", n: "BP Prudhoe Bay Royalty Trust", c: "US" },
  { id: 867, s: "DINO", n: "HF Sinclair Co", c: "US" },
  { id: 868, s: "CVE", n: "Cenovus Energy Inc", c: "US" },
  { id: 869, s: "PUBM", n: "Pubmatic Inc", c: "US" },
  { id: 870, s: "ANSS", n: "ANSYS Inc", c: "US" },
  { id: 871, s: "XRX", n: "Xerox Co", c: "US" },
  { id: 872, s: "NTAP", n: "NetApp Inc", c: "US" },
  { id: 873, s: "EXEL", n: "Exelixis Inc", c: "US" },
  { id: 874, s: "ESTC", n: "Elastic NV", c: "US" },
  { id: 875, s: "ARVL", n: "Arrival Vault USA Inc", c: "US" },
  { id: 876, s: "TUP", n: "Tupperware Brands Corporation", c: "US" },
  { id: 877, s: "EH", n: "Ehang Holdings", c: "US" },
  { id: 878, s: "RIGL", n: "Rigel Pharmaceuticals Inc", c: "US" },
  { id: 879, s: "COTY", n: "Coty Inc", c: "US" },
  { id: 880, s: "NEP", n: "Nextera Energy Partners LP", c: "US" },
  { id: 881, s: "ATNM", n: "Actinium Pharmaceuticals Inc", c: "US" },
  { id: 882, s: "HRTX", n: "Heron Therapeuti", c: "US" },
  { id: 883, s: "CHRS", n: "Coherus BioSciences Inc", c: "US" },
  { id: 884, s: "OXSQ", n: "Oxford Square Capital Co", c: "US" },
  { id: 885, s: "AMSC", n: "American Superconductor Corporation", c: "US" },
  { id: 886, s: "TRMD", n: "Torm PLC Class A", c: "US" },
  { id: 887, s: "FROG", n: "Jfrog Ltd", c: "US" },
  { id: 888, s: "KOPN", n: "Kopin Corporation", c: "US" },
  { id: 889, s: "PGR", n: "Progressive Co", c: "US" },
  { id: 890, s: "INDI", n: "indie Semiconductor Inc", c: "US" },
  { id: 891, s: "EGY", n: "Vaalco Energy Inc", c: "US" },
  { id: 892, s: "VIST", n: "Vista Oil Gas ADR", c: "US" },
  { id: 893, s: "AWK", n: "American Water Works", c: "US" },
  { id: 894, s: "XYL", n: "Xylem Inc", c: "US" },
  { id: 895, s: "EA", n: "Electronic Arts Inc", c: "US" },
  { id: 896, s: "DB", n: "Deutsche Bank AG NA O.N.", c: "US" },
  { id: 897, s: "BEAM", n: "Beam Therapeutics Inc", c: "US" },
  { id: 898, s: "VET", n: "Vermilion Energy Inc.", c: "US" },
  { id: 899, s: "WIX", n: "Wix.Com", c: "US" },
  { id: 900, s: "VTGN", n: "VistaGen Therapeutics Inc", c: "US" },
  { id: 901, s: "ENB.TO", n: "Enbridge Inc", c: "CA" },
  { id: 902, s: "JXN", n: "Jackson Financial Inc", c: "US" },
  { id: 903, s: "LSCC", n: "Lattice Semiconductor Corporation", c: "US" },
  { id: 904, s: "AKAM", n: "Akamai Technologies Inc", c: "US" },
  { id: 905, s: "ASC", n: "Ardmore Shpng", c: "US" },
  { id: 906, s: "VNA.DE", n: "Vonovia SE", c: "GER" },
  { id: 907, s: "APO", n: "Apollo Global Management LLC Class A", c: "US" },
  { id: 908, s: "LILM", n: "Lilium NV", c: "US" },
  { id: 909, s: "FAST", n: "Fastenal Company", c: "US" },
  { id: 910, s: "XEL", n: "Xcel Energy Inc", c: "US" },
  { id: 911, s: "INSW", n: "International Seaways Inc", c: "US" },
  { id: 912, s: "HYLN", n: "Hyliion Holdings Co", c: "US" },
  { id: 913, s: "MTN", n: "Vail Resorts Inc", c: "US" },
  { id: 914, s: "ALL", n: "The Allstate Corporation", c: "US" },
  { id: 915, s: "CTAS", n: "Cintas Corporation", c: "US" },
  { id: 916, s: "IDCC", n: "InterDigital Inc", c: "US" },
  { id: 917, s: "ALT", n: "Altimmune Inc", c: "US" },
  { id: 918, s: "GSBD", n: "Goldman Sachs BDC Inc", c: "US" },
  { id: 919, s: "CDXC", n: "Chromadex Co", c: "US" },
  { id: 920, s: "HCA", n: "HCA Holdings Inc", c: "US" },
  { id: 921, s: "ORI", n: "Old Republic International Co", c: "US" },
  { id: 922, s: "KBH", n: "KB Home", c: "US" },
  { id: 923, s: "BGS", n: "B&G Foods Inc", c: "US" },
  { id: 924, s: "HLT", n: "Hilton Worldwide Holdings Inc", c: "US" },
  { id: 925, s: "M", n: "Moodys Corporation", c: "US" },
  { id: 926, s: "TRP", n: "TC Energy Co", c: "US" },
  { id: 927, s: "GRMN", n: "Garmin", c: "US" },
  { id: 928, s: "AMR", n: "Alpha Metallurgical Resources Inc", c: "US" },
  { id: 929, s: "LW", n: "Lamb Weston Holdings Inc", c: "US" },
  { id: 930, s: "WING", n: "Wingstop Inc", c: "US" },
  { id: 931, s: "RY", n: "Royal Bank of CA", c: "US" },
  { id: 932, s: "ASX", n: "ASE Industrial Holding Co ADR", c: "US" },
  { id: 933, s: "CPA", n: "Copa Holdings SA", c: "US" },
  { id: 934, s: "KNSL", n: "Kinsale Capital Group Inc", c: "US" },
  { id: 935, s: "CMC.V", n: "Cielo Waste Solutions Co", c: "CA" },
  { id: 936, s: "ERIC", n: "Telefonaktiebolaget LM Ericsson B ADR", c: "US" },
  { id: 937, s: "OXM", n: "Oxford Industries Inc", c: "US" },
  { id: 938, s: "BJ", n: "BJs Wholesale Club Holdings Inc", c: "US" },
  { id: 939, s: "TPL", n: "Texas Pacific Land Trust", c: "US" },
  { id: 940, s: "GH", n: "Guardant Health Inc", c: "US" },
  { id: 941, s: "AUUD", n: "Auddia Inc", c: "US" },
  { id: 942, s: "CXM", n: "Sprinklr Inc", c: "US" },
  { id: 943, s: "NAK", n: "Northern Dynasty Minerals", c: "US" },
  { id: 944, s: "ALLO", n: "Allogene Therapeutics Inc", c: "US" },
  { id: 945, s: "TOI", n: "Oncology Institute Inc", c: "US" },
  { id: 946, s: "EW", n: "Edwards Lifesciences Co", c: "US" },
  { id: 947, s: "CC", n: "Chemours", c: "US" },
  { id: 948, s: "EGLE", n: "Eagle Bulk Shipping Inc", c: "US" },
  { id: 949, s: "PWR", n: "Quanta Services Inc", c: "US" },
  { id: 950, s: "KEYS", n: "Keysight Technologies Inc", c: "US" },
  { id: 951, s: "CZR", n: "Caesars Entertainment Corporation", c: "US" },
  { id: 952, s: "DRI", n: "Darden Restaurants Inc", c: "US" },
  { id: 953, s: "CWEN", n: "Clearway Energy Inc Class C", c: "US" },
  { id: 954, s: "CAR", n: "Avis Budget Group Inc", c: "US" },
  { id: 955, s: "HLN", n: "Haleon plc", c: "US" },
  { id: 956, s: "BSX", n: "Boston Scientific Co", c: "US" },
  { id: 957, s: "JCI", n: "Johnson Controls International PLC", c: "US" },
  { id: 958, s: "ARI", n: "Apollo Commercial Real Estate Finance Inc", c: "US" },
  { id: 959, s: "OPI", n: "Office Properties Income Trust", c: "US" },
  { id: 960, s: "OSTK", n: "Overstockcom Inc", c: "US" },
  { id: 961, s: "TSEM", n: "Tower Semiconductor", c: "US" },
  { id: 962, s: "ZBRA", n: "Zebra Technologies Corporation", c: "US" },
  { id: 963, s: "BILI", n: "Bilibili Inc", c: "US" },
  { id: 964, s: "STNG", n: "Scorpio Tankers Inc", c: "US" },
  { id: 965, s: "RY.TO", n: "Royal Bank of CA", c: "CA" },
  { id: 966, s: "IBKR", n: "Interactive Brokers Group Inc", c: "US" },
  { id: 967, s: "PAYX", n: "Paychex Inc", c: "US" },
  { id: 968, s: "APPN", n: "Appian Co", c: "US" },
  { id: 969, s: "DUOL", n: "Duolingo Inc", c: "US" },
  { id: 970, s: "MNKD", n: "MannKind Co", c: "US" },
  { id: 971, s: "CG", n: "Carlyle Group Inc", c: "US" },
  { id: 972, s: "HES", n: "Hess Corporation", c: "US" },
  { id: 973, s: "OGI", n: "OrganiGram Holdings Inc", c: "US" },
  { id: 974, s: "NYMT", n: "New York Mortgage Trust Inc", c: "US" },
  { id: 975, s: "AVB", n: "AvalonBay Communities Inc", c: "US" },
  { id: 976, s: "DEA", n: "Eerly Govt Ppty Inc", c: "US" },
  { id: 977, s: "INFN", n: "Infinera Corporation", c: "US" },
  { id: 978, s: "KODK", n: "Eastman Kodak", c: "US" },
  { id: 979, s: "ABEO", n: "Abeona Therapeutics Inc", c: "US" },
  { id: 980, s: "VMAR", n: "Vision Marine Technologies Inc", c: "US" },
  { id: 981, s: "WIRE", n: "Encore Wire Corporation", c: "US" },
  { id: 982, s: "NRT", n: "North European Oil Royalty Trust", c: "US" },
  { id: 983, s: "CO", n: "Vita Coco Company Inc", c: "US" },
  { id: 984, s: "FRT", n: "Federal Realty Investment Trust", c: "US" },
  { id: 985, s: "SAGE", n: "Sage Therapeutic", c: "US" },
  { id: 986, s: "TRV", n: "The Travelers Companies Inc", c: "US" },
  { id: 987, s: "MKC", n: "McCormick & Company Incorporated", c: "US" },
  { id: 988, s: "MGY", n: "Magnolia Oil & Gas Co", c: "US" },
  { id: 989, s: "TDS", n: "Telephone and Data Systems Inc", c: "US" },
  { id: 990, s: "MIMO", n: "Airspan Networks Holdings Inc", c: "US" },
  { id: 991, s: "GNW", n: "Genworth Financial Inc", c: "US" },
  { id: 992, s: "VOD.L", n: "Vodafone Group PLC", c: "UK" },
  { id: 993, s: "CNSP", n: "Cns Pharmaceuticals Inc", c: "US" },
  { id: 994, s: "LU", n: "Lufax Holding", c: "US" },
  { id: 995, s: "LDOS", n: "Leidos Holdings Inc", c: "US" },
  { id: 996, s: "FNF", n: "Fidelity National Financial Inc", c: "US" },
  { id: 997, s: "HTZ", n: "Hertz Global Holdings Inc", c: "US" },
  { id: 998, s: "MFC", n: "Manulife Financial Co", c: "US" },
  { id: 999, s: "SFIX", n: "Stitch Fix", c: "US" },
  { id: 1000, s: "FGEN", n: "FibroGen Inc", c: "US" },
  { id: 1001, s: "STT", n: "State Street Co", c: "US" },
  { id: 1002, s: "WES", n: "Western Midstream Partners LP", c: "US" },
  { id: 1003, s: "DCFC", n: "Tritium Dcfc", c: "US" },
  { id: 1004, s: "SRE", n: "Sempra Energy", c: "US" },
  { id: 1005, s: "PD", n: "Pagerduty Inc", c: "US" },
  { id: 1006, s: "VLY", n: "Valley National BanCo", c: "US" },
  { id: 1007, s: "CGRN", n: "Capstone Green Energy Co", c: "US" },
  { id: 1008, s: "CNXC", n: "Concentrix Corporation", c: "US" },
  { id: 1009, s: "JNPR", n: "Juniper Networks Inc", c: "US" },
  { id: 1010, s: "PBI", n: "Pitney Bowes Inc", c: "US" },
  { id: 1011, s: "SNA", n: "Snap-On Inc", c: "US" },
  { id: 1012, s: "UAA", n: "Under Armour Inc A", c: "US" },
  { id: 1013, s: "AM", n: "Antero Midstream Partners LP", c: "US" },
  { id: 1014, s: "RMTI", n: "Rockwell Medical Inc", c: "US" },
  { id: 1015, s: "GPN", n: "Global Payments Inc", c: "US" },
  { id: 1016, s: "KRP", n: "Kimbell Royalty Partners LP", c: "US" },
  { id: 1017, s: "BAS.DE", n: "BASF SE", c: "GER" },
  { id: 1018, s: "GPRO", n: "GoPro Inc", c: "US" },
  { id: 1019, s: "POOL", n: "Pool Corporation", c: "US" },
  { id: 1020, s: "CNI", n: "Canadian National Railway", c: "US" },
  { id: 1021, s: "ASTR", n: "Astra Space Inc", c: "US" },
  { id: 1022, s: "FE", n: "FirstEnergy Corporation", c: "US" },
  { id: 1023, s: "TEX", n: "Terex Corporation", c: "US" },
  { id: 1024, s: "XXII", n: "22nd Century Group Inc", c: "US" },
  { id: 1025, s: "TME", n: "Tencent Music Entertainment Group", c: "US" },
  { id: 1026, s: "SPR", n: "Spirit Aerosystems Holdings Inc", c: "US" },
  { id: 1027, s: "CEQP", n: "Crestwood Equity Partners LP", c: "US" },
  { id: 1028, s: "HP", n: "Helmerich and Payne Inc", c: "US" },
  { id: 1029, s: "TSP", n: "Tusimple Holdings Inc", c: "US" },
  { id: 1030, s: "CLDX", n: "Celldex Therapeutics Inc", c: "US" },
  { id: 1031, s: "TWST", n: "Twist Bioscience Co", c: "US" },
  { id: 1032, s: "BATS.L", n: "British American Tobacco PLC", c: "UK" },
  { id: 1033, s: "VIPS", n: "Vipshop Holdings Limited", c: "US" },
  { id: 1034, s: "ATKR", n: "Atkore International Group Inc", c: "US" },
  { id: 1035, s: "GROM", n: "Grom Social Enterprises Inc", c: "US" },
  { id: 1036, s: "SAN", n: "Banco Santander SA ADR", c: "US" },
  { id: 1037, s: "FCNCA", n: "First Citizens BancShares Inc", c: "US" },
  { id: 1038, s: "WW", n: "WW International Inc", c: "US" },
  { id: 1039, s: "TRIP", n: "TripAdvisor Inc", c: "US" },
  { id: 1040, s: "GLAD", n: "Gladstone Capital Corporation", c: "US" },
  { id: 1041, s: "EWBC", n: "East West Bancorp Inc", c: "US" },
  { id: 1042, s: "SAND", n: "Sandstorm Gold N", c: "US" },
  { id: 1043, s: "AC.TO", n: "Air CA", c: "CA" },
  { id: 1044, s: "DDS", n: "Dillards Inc", c: "US" },
  { id: 1045, s: "SSTK", n: "Shutterstock", c: "US" },
  { id: 1046, s: "ATRA", n: "Atara Biotherapeutics Inc", c: "US" },
  { id: 1047, s: "CE", n: "Celanese Corporation", c: "US" },
  { id: 1048, s: "GNS", n: "Genius Group", c: "US" },
  { id: 1049, s: "MGNI", n: "Magnite Inc", c: "US" },
  { id: 1050, s: "MDU", n: "MDU Resources Group Inc", c: "US" },
  { id: 1051, s: "ICE", n: "Intercontinental Exchange Inc", c: "US" },
  { id: 1052, s: "CRON", n: "Cronos Group Inc", c: "US" },
  { id: 1053, s: "BNS.TO", n: "Bank of Nova Scotia", c: "CA" },
  { id: 1054, s: "EXR", n: "Extra Space Storage Inc", c: "US" },
  { id: 1055, s: "IPI", n: "Intrepid Potash Inc", c: "US" },
  { id: 1056, s: "DOV", n: "Dover Corporation", c: "US" },
  { id: 1057, s: "DTE", n: "DTE Energy Company", c: "US" },
  { id: 1058, s: "WELL.TO", n: "WELL Health Technologies Co", c: "CA" },
  { id: 1059, s: "HUBB", n: "Hubbell Inc", c: "US" },
  { id: 1060, s: "MNMD", n: "Mind Medicine Inc", c: "US" },
  { id: 1061, s: "MKL", n: "Markel Corporation", c: "US" },
  { id: 1062, s: "WELL", n: "Welltower Inc", c: "US" },
  { id: 1063, s: "AB", n: "AllianceBernstein Holding L.P.", c: "US" },
  { id: 1064, s: "PTLO", n: "Portillo's Inc", c: "US" },
  { id: 1065, s: "BMO", n: "Bank of Montreal", c: "US" },
  { id: 1066, s: "BCE", n: "BCE Inc", c: "US" },
  { id: 1067, s: "BTE", n: "Baytex Energy Co", c: "US" },
  { id: 1068, s: "VSTM", n: "Verastem Inc", c: "US" },
  { id: 1069, s: "PAYO", n: "Payoneer Global Inc", c: "US" },
  { id: 1070, s: "FIVE", n: "Five Below Inc", c: "US" },
  { id: 1071, s: "AG", n: "AGCO Corporation", c: "US" },
  { id: 1072, s: "DNN", n: "Denison Mines Co", c: "US" },
  { id: 1073, s: "TTC", n: "Toro", c: "US" },
  { id: 1074, s: "HAS", n: "Hasbro Inc", c: "US" },
  { id: 1075, s: "INVZ", n: "Innoviz Technologies", c: "US" },
  { id: 1076, s: "MFA", n: "MFA Financial Inc", c: "US" },
  { id: 1077, s: "QD", n: "Qudian Inc", c: "US" },
  { id: 1078, s: "TPVG", n: "Triplepoint Venture Growth BDC Co", c: "US" },
  { id: 1079, s: "TGB", n: "Taseko Mines", c: "US" },
  { id: 1080, s: "TD-PFE.TO", n: "Toronto Dominion Bank Pref E", c: "CA" },
  { id: 1081, s: "CHMI", n: "Cherry Hill Mortgage Investment Co", c: "US" },
  { id: 1082, s: "QRVO", n: "Qorvo Inc", c: "US" },
  { id: 1083, s: "GNL", n: "Global Net Lease, Inc.", c: "US" },
  { id: 1084, s: "BIOL", n: "BIOLASE Inc", c: "US" },
  { id: 1085, s: "VTR", n: "Ventas Inc", c: "US" },
  { id: 1086, s: "ACRE", n: "Ares Commercial Real Estate", c: "US" },
  { id: 1087, s: "GALT", n: "Galectin Therapeutics Inc", c: "US" },
  { id: 1088, s: "CAKE", n: "The Cheesecake Factory", c: "US" },
  { id: 1089, s: "CENN", n: "Cenntro Electric Group", c: "US" },
  { id: 1090, s: "VWAGY", n: "Volkswagen AG 1/10 ADR", c: "US" },
  { id: 1091, s: "CIR", n: "CIRCOR International Inc", c: "US" },
  { id: 1092, s: "FI", n: "Fiserv Inc.", c: "US" },
  { id: 1093, s: "FLEX", n: "Flex", c: "US" },
  { id: 1094, s: "CMRX", n: "Chimerix Inc", c: "US" },
  { id: 1095, s: "GMRE", n: "Global Medical REIT Inc", c: "US" },
  { id: 1096, s: "SOND", n: "Sonder Holdings Inc", c: "US" },
  { id: 1097, s: "ACAD", n: "ACADIA Pharmaceuticals Inc", c: "US" },
  { id: 1098, s: "CERS", n: "Cerus Corporation", c: "US" },
  { id: 1099, s: "AMSWA", n: "American Software Inc", c: "US" },
  { id: 1100, s: "POWW", n: "Ammo Inc", c: "US" },
  { id: 1101, s: "SURG", n: "Surgepays Inc", c: "US" },
  { id: 1102, s: "NOVA", n: "Sunnova Energy International Inc", c: "US" },
  { id: 1103, s: "LVMUY", n: "LVMH Moet Hennessy Louis Vuitton SA ADR", c: "US" },
  { id: 1104, s: "MBOT", n: "Microbot Medical Inc", c: "US" },
  { id: 1105, s: "VFF", n: "Village Farms International Inc", c: "US" },
  { id: 1106, s: "PLAY", n: "Dave & Buster’s Entertainment", c: "US" },
  { id: 1107, s: "2330.TW", n: "Taiwan Semicon", c: "TW" },
  { id: 1108, s: "INOD", n: "Innodata Inc", c: "US" },
  { id: 1109, s: "HALO", n: "Halozyme Therapeutics Inc", c: "US" },
  { id: 1110, s: "INSG", n: "Inseego Co", c: "US" },
  { id: 1111, s: "ATOS", n: "Atossa Genetics Inc", c: "US" },
  { id: 1112, s: "SU.TO", n: "Suncor Energy Inc", c: "CA" },
  { id: 1113, s: "CEIX", n: "Consol Energy Inc", c: "US" },
  { id: 1114, s: "SBRA", n: "Sabra Healthcare REIT Inc", c: "US" },
  { id: 1115, s: "ADC", n: "Agree Realty Corporation", c: "US" },
  { id: 1116, s: "PGEN", n: "Precigen Inc", c: "US" },
  { id: 1117, s: "SGMO", n: "Sangamo Therapeutics Inc", c: "US" },
  { id: 1118, s: "ABUS", n: "Arbutus Biopharma Co", c: "US" },
  { id: 1119, s: "ARCH", n: "Arch Resources Inc", c: "US" },
  { id: 1120, s: "ESS", n: "Essex Property Trust Inc", c: "US" },
  { id: 1121, s: "MLI", n: "Mueller Industries Inc", c: "US" },
  { id: 1122, s: "NVR", n: "NVR Inc", c: "US" },
  { id: 1123, s: "CDE", n: "Coeur Mining Inc", c: "US" },
  { id: 1124, s: "OLMA", n: "Olema Pharmaceuticals Inc", c: "US" },
  { id: 1125, s: "OLPX", n: "Olaplex Holdings Inc", c: "US" },
  { id: 1126, s: "CRT", n: "Cross Timbers Royalty Trust", c: "US" },
  { id: 1127, s: "GLNCY", n: "Glencore PLC ADR", c: "US" },
  { id: 1128, s: "AEVA", n: "Aeva Technologies Inc", c: "US" },
  { id: 1129, s: "UNM", n: "Unum Group", c: "US" },
  { id: 1130, s: "BITF", n: "Bitfarms", c: "US" },
  { id: 1131, s: "UPWK", n: "Upwork Inc", c: "US" },
  { id: 1132, s: "ADAP", n: "Adaptimmune Therapeutics Plc", c: "US" },
  { id: 1133, s: "GAIN", n: "Gladstone Investment Corporation", c: "US" },
  { id: 1134, s: "RXRX", n: "Recursion Pharmaceuticals Inc", c: "US" },
  { id: 1135, s: "NOVN", n: "Novan Inc", c: "US" },
  { id: 1136, s: "TPR", n: "Tapestry Inc", c: "US" },
  { id: 1137, s: "RNG", n: "Ringcentral Inc", c: "US" },
  { id: 1138, s: "MOMO", n: "Hello Group Inc", c: "US" },
  { id: 1139, s: "FNV", n: "Franco-Nevada Corporation", c: "US" },
  { id: 1140, s: "FI", n: "Fair Isaac Corporation", c: "US" },
  { id: 1141, s: "HASI", n: "Hannon Armstrong Sustainable Infrastructure Capital Inc", c: "US" },
  { id: 1142, s: "SCPH", n: "Scpharmaceuticals Inc", c: "US" },
  { id: 1143, s: "ASXC", n: "Asensus Surgical Inc", c: "US" },
  { id: 1144, s: "API", n: "Agora Inc", c: "US" },
  { id: 1145, s: "FATE", n: "Fate Therapeutics Inc", c: "US" },
  { id: 1146, s: "HWM", n: "Howmet Aerospace Inc", c: "US" },
  { id: 1147, s: "PPG", n: "PPG Industries Inc", c: "US" },
  { id: 1148, s: "GFI", n: "Gold Fields ADR", c: "US" },
  { id: 1149, s: "HDB", n: "HDFC Bank Limited ADR", c: "US" },
  { id: 1150, s: "APHLF", n: "Alpha Lithium Co", c: "US" },
  { id: 1151, s: "OLN", n: "Olin Corporation", c: "US" },
  { id: 1152, s: "WTRG", n: "Essential Utilities Inc", c: "US" },
  { id: 1153, s: "PI", n: "Impinj Inc", c: "US" },
  { id: 1154, s: "ELEV", n: "Elevation Oncology Inc", c: "US" },
  { id: 1155, s: "NOKIA.HE", n: "Nokia Oyj", c: "Finland" },
  { id: 1156, s: "JEF", n: "Jefferies Financial Group Inc", c: "US" },
  { id: 1157, s: "BAYN.DE", n: "Bayer AG NA", c: "GER" },
  { id: 1158, s: "AQN", n: "Algonquin Power & Utilities Co", c: "US" },
  { id: 1159, s: "SVRA", n: "Savara Inc", c: "US" },
  { id: 1160, s: "OLED", n: "Universal Display", c: "US" },
  { id: 1161, s: "GOTU", n: "Gaotu Techedu Inc DRC", c: "US" },
  { id: 1162, s: "HITI", n: "High Tide Inc", c: "US" },
  { id: 1163, s: "ADMA", n: "ADMA Biologics Inc", c: "US" },
  { id: 1164, s: "MAA", n: "Mid-America Apartment Communities Inc", c: "US" },
  { id: 1165, s: "ADVM", n: "Adverum Biotechnologies Inc", c: "US" },
  { id: 1166, s: "DAC", n: "Danaos Corporation", c: "US" },
  { id: 1167, s: "ETNB", n: "89bio Inc", c: "US" },
  { id: 1168, s: "AFCG", n: "AFC Gamma Inc", c: "US" },
  { id: 1169, s: "CNK", n: "Cinemark Holdings Inc", c: "US" },
  { id: 1170, s: "WOOF", n: "Pet Acquisition LLC", c: "US" },
  { id: 1171, s: "DXC", n: "DXC Technology", c: "US" },
  { id: 1172, s: "WEC", n: "WEC Energy Group Inc", c: "US" },
  { id: 1173, s: "CRBU", n: "Caribou Biosciences Inc", c: "US" },
  { id: 1174, s: "ACMR", n: "Acm Research Inc", c: "US" },
  { id: 1175, s: "RYCEY", n: "Rolls Royce Holdings plc", c: "US" },
  { id: 1176, s: "NOG", n: "Northern Oil & Gas Inc", c: "US" },
  { id: 1177, s: "PEN", n: "Penumbra Inc", c: "US" },
  { id: 1178, s: "BMI", n: "Badger Meter Inc", c: "US" },
  { id: 1179, s: "KLIC", n: "Kulicke and Soffa Industries Inc", c: "US" },
  { id: 1180, s: "GGG", n: "Graco Inc", c: "US" },
  { id: 1181, s: "MEDP", n: "Medpace Holdings Inc", c: "US" },
  { id: 1182, s: "PBA", n: "Pembina Pipeline Co", c: "US" },
  { id: 1183, s: "GLOB", n: "Globant SA", c: "US" },
  { id: 1184, s: "ENSV", n: "Enservco", c: "US" },
  { id: 1185, s: "PLAB", n: "Photronics Inc", c: "US" },
  { id: 1186, s: "PEG", n: "Public Service Enterprise Group Inc", c: "US" },
  { id: 1187, s: "FLR", n: "Fluor Corporation", c: "US" },
  { id: 1188, s: "LPX", n: "Louisiana-Pacific Corporation", c: "US" },
  { id: 1189, s: "DTC", n: "Solo Brands Inc", c: "US" },
  { id: 1190, s: "IGT", n: "International Game Technology PLC", c: "US" },
  { id: 1191, s: "GWH", n: "ESS Tech Inc", c: "US" },
  { id: 1192, s: "LL", n: "LL Flooring Holdings Inc", c: "US" },
  { id: 1193, s: "GRPN", n: "Groupon Inc", c: "US" },
  { id: 1194, s: "BBD", n: "Banco Bradesco SA ADR", c: "US" },
  { id: 1195, s: "IR", n: "Ingersoll Rand Inc", c: "US" },
  { id: 1196, s: "APLE", n: "Apple Hospitality REIT Inc", c: "US" },
  { id: 1197, s: "UNCY", n: "Unicycive Therapeutics Inc", c: "US" },
  { id: 1198, s: "OSCR", n: "Oscar Health Inc", c: "US" },
  { id: 1199, s: "BLPH", n: "Bellerophon Therapeutics Inc", c: "US" },
  { id: 1200, s: "SBNY", n: "Signature Bank", c: "US" },
  { id: 1201, s: "IDXX", n: "IDEXX Laboratories Inc", c: "US" },
  { id: 1202, s: "QRTEA", n: "Qurate Retail Inc Series A", c: "US" },
  { id: 1203, s: "GRTX", n: "Galera Therapeutics Inc", c: "US" },
  { id: 1204, s: "MBUU", n: "Malibu Boats Inc", c: "US" },
  { id: 1205, s: "CIVI", n: "Civitas Resources Inc", c: "US" },
  { id: 1206, s: "CHRD", n: "Chord Energy Co", c: "US" },
  { id: 1207, s: "RCEL", n: "Avita Medical", c: "US" },
  { id: 1208, s: "XFLT", n: "XAI Octagon Floating Rate & Alternative Income Term Trust", c: "US" },
  { id: 1209, s: "ESTE", n: "Earthstone Energy Inc", c: "US" },
  { id: 1210, s: "PR", n: "Permian Resources Corporation", c: "US" },
  { id: 1211, s: "VGR", n: "Vector Group", c: "US" },
  { id: 1212, s: "NVT", n: "nVent Electric PLC", c: "US" },
  { id: 1213, s: "BOX", n: "Box Inc", c: "US" },
  { id: 1214, s: "NS", n: "NuStar Energy LP", c: "US" },
  { id: 1215, s: "TRUP", n: "Trupanion Inc", c: "US" },
  { id: 1216, s: "CNHI", n: "CNH Industrial N.V.", c: "US" },
  { id: 1217, s: "SIVBQ", n: "SVB Financial Group", c: "US" },
  { id: 1218, s: "MDC", n: "MDC Holdings Inc", c: "US" },
  { id: 1219, s: "HLGN", n: "Heliogen Inc", c: "US" },
  { id: 1220, s: "SKYT", n: "Skywater Technology Inc", c: "US" },
  { id: 1221, s: "OCSL", n: "Oaktree Specialty Lending Co", c: "US" },
  { id: 1222, s: "TAK", n: "Takeda Pharmaceutical Co ADR", c: "US" },
  { id: 1223, s: "AEE", n: "Ameren Co", c: "US" },
  { id: 1224, s: "ROP", n: "Roper Technologies Inc. Common Stock", c: "US" },
  { id: 1225, s: "MXL", n: "MaxLinear Inc", c: "US" },
  { id: 1226, s: "REAL", n: "TheRealReal Inc", c: "US" },
  { id: 1227, s: "CRSR", n: "Corsair Gaming Inc", c: "US" },
  { id: 1228, s: "CTRE", n: "Caretrust Inc", c: "US" },
  { id: 1229, s: "HOTH", n: "Hoth Therapeutics Inc", c: "US" },
  { id: 1230, s: "NOV", n: "NOV Inc.", c: "US" },
  { id: 1231, s: "SOL", n: "ReneSola", c: "US" },
  { id: 1232, s: "WRK", n: "WestRock", c: "US" },
  { id: 1233, s: "FFIV", n: "F5 Networks Inc", c: "US" },
  { id: 1234, s: "MMC", n: "Marsh & McLennan Companies Inc", c: "US" },
  { id: 1235, s: "SCYX", n: "Scynexis Inc", c: "US" },
  { id: 1236, s: "WAT", n: "Waters Corporation", c: "US" },
  { id: 1237, s: "ILUS", n: "Ilustrato Pictures", c: "US" },
  { id: 1238, s: "ADN", n: "Advent Technologies Holdings Inc", c: "US" },
  { id: 1239, s: "EGO", n: "Eldorado Gold Co", c: "US" },
  { id: 1240, s: "VMEO", n: "Vimeo Inc", c: "US" },
  { id: 1241, s: "MGA", n: "Magna International Inc", c: "US" },
  { id: 1242, s: "VSAT", n: "ViaSat Inc", c: "US" },
  { id: 1243, s: "CHRW", n: "CH Robinson Worldwide Inc", c: "US" },
  { id: 1244, s: "TEL.OL", n: "Telenor ASA", c: "Norway" },
  { id: 1245, s: "OTIS", n: "Otis Worldwide Co", c: "US" },
  { id: 1246, s: "BMRN", n: "Biomarin Pharmaceutical Inc", c: "US" },
  { id: 1247, s: "NTES", n: "NetEase Inc", c: "US" },
  { id: 1248, s: "AMKR", n: "Amkor Technology Inc", c: "US" },
  { id: 1249, s: "VSH", n: "Vishay Intertechnology Inc", c: "US" },
  { id: 1250, s: "BXSL", n: "Blackstone Secured Lending Fund", c: "US" },
  { id: 1251, s: "PII", n: "Polaris Industries Inc", c: "US" },
  { id: 1252, s: "ESPR", n: "Esperion Therapeutics Inc", c: "US" },
  { id: 1253, s: "PFG", n: "Principal Financial Group Inc", c: "US" },
  { id: 1254, s: "BCS", n: "Barclays PLC ADR", c: "US" },
  { id: 1255, s: "RRC", n: "Range Resources Co", c: "US" },
  { id: 1256, s: "UHAL", n: "U-Haul Holding Company", c: "US" },
  { id: 1257, s: "AULT", n: "Ault Alliance Inc.", c: "US" },
  { id: 1258, s: "HR", n: "Healthcare Realty Trust Incorporated", c: "US" },
  { id: 1259, s: "XFOR", n: "X4 Pharmaceuticals Inc", c: "US" },
  { id: 1260, s: "ITUB", n: "Itau Unibanco Banco Holding SA", c: "US" },
  { id: 1261, s: "ETR", n: "Entergy Corporation", c: "US" },
  { id: 1262, s: "RWT", n: "Redwood Trust Inc", c: "US" },
  { id: 1263, s: "ASML.AS", n: "ASML Holding NV", c: "Netherlands" },
  { id: 1264, s: "CHD", n: "Church & Dwight Company Inc", c: "US" },
  { id: 1265, s: "MLM", n: "Martin Marietta Materials Inc", c: "US" },
  { id: 1266, s: "THO", n: "Thor Industries Inc", c: "US" },
  { id: 1267, s: "MREO", n: "Mereo BioPharma Group PLC ADR", c: "US" },
  { id: 1268, s: "BTAI", n: "BioXcel Therapeutics Inc", c: "US" },
  { id: 1269, s: "APTO", n: "Aptose Biosciences Inc", c: "US" },
  { id: 1270, s: "STAA", n: "STAAR Surgical Company", c: "US" },
  { id: 1271, s: "SHLS", n: "Shoals Technologies Group Inc", c: "US" },
  { id: 1272, s: "AMPS", n: "Altus Power Inc", c: "US" },
  { id: 1273, s: "MTH", n: "Meritage Corporation", c: "US" },
  { id: 1274, s: "CSL", n: "Carlisle Companies Incorporated", c: "US" },
  { id: 1275, s: "SYF", n: "Synchrony Financial", c: "US" },
  { id: 1276, s: "TCPC", n: "BlackRock TCP Capital Co", c: "US" },
  { id: 1277, s: "ES", n: "Eversource Energy", c: "US" },
  { id: 1278, s: "E", n: "Eni SpA ADR", c: "US" },
  { id: 1279, s: "ZI", n: "ZoomInfo Technologies Inc", c: "US" },
  { id: 1280, s: "MOD", n: "Modine Manufacturing Company", c: "US" },
  { id: 1281, s: "BKE", n: "Buckle Inc", c: "US" },
  { id: 1282, s: "EQR", n: "Equity Residential", c: "US" },
  { id: 1283, s: "FRBK", n: "Republic First Bancorp Inc", c: "US" },
  { id: 1284, s: "EYEN", n: "Eyenovia Inc", c: "US" },
  { id: 1285, s: "PRTS", n: "CarParts.Com Inc", c: "US" },
  { id: 1286, s: "BLRX", n: "BioLineRx", c: "US" },
  { id: 1287, s: "BBWI", n: "Bath & Body Works Inc.", c: "US" },
  { id: 1288, s: "AEO", n: "American Eagle Outfitters Inc", c: "US" },
  { id: 1289, s: "OC", n: "Owens Corning Inc", c: "US" },
  { id: 1290, s: "RGLD", n: "Royal Gold Inc", c: "US" },
  { id: 1291, s: "IRDM", n: "Iridium Communications Inc", c: "US" },
  { id: 1292, s: "IONS", n: "Ionis Pharmaceuticals Inc", c: "US" },
  { id: 1293, s: "APH", n: "Amphenol Corporation", c: "US" },
  { id: 1294, s: "MPAA", n: "Motorcar Parts of America Inc", c: "US" },
  { id: 1295, s: "GLP", n: "Global Partners LP", c: "US" },
  { id: 1296, s: "AJG", n: "Arthur J Gallagher &", c: "US" },
  { id: 1297, s: "INTA", n: "Intapp Inc", c: "US" },
  { id: 1298, s: "SDC", n: "SmileDirectClub Inc", c: "US" },
  { id: 1299, s: "HZNP", n: "Horizon Pharma PLC", c: "US" },
  { id: 1300, s: "TNXP", n: "Tonix Pharmaceuticals Holding Co", c: "US" },
  { id: 1301, s: "RSG", n: "Republic Services Inc", c: "US" },
  { id: 1302, s: "TMDX", n: "TransMedics Group Inc", c: "US" },
  { id: 1303, s: "GTLS", n: "Chart Industries Inc", c: "US" },
  { id: 1304, s: "HYSR", n: "SunHydrogen Inc.", c: "US" },
  { id: 1305, s: "ENS", n: "Enersys", c: "US" },
  { id: 1306, s: "IPG", n: "Interpublic Group of Companies Inc", c: "US" },
  { id: 1307, s: "AAOI", n: "Applied Opt", c: "US" },
  { id: 1308, s: "AN", n: "AutoNation Inc", c: "US" },
  { id: 1309, s: "IDAI", n: "Trust Stamp Inc", c: "US" },
  { id: 1310, s: "LH", n: "Laboratory Corporation of America Holdings", c: "US" },
  { id: 1311, s: "BOH", n: "Bank of Hawaii Corporation", c: "US" },
  { id: 1312, s: "IQV", n: "IQVIA Holdings Inc", c: "US" },
  { id: 1313, s: "MITK", n: "Mitek Systems Inc", c: "US" },
  { id: 1314, s: "HA", n: "Hawaiian Holdings Inc", c: "US" },
  { id: 1315, s: "PZZA", n: "Papa John's International Inc", c: "US" },
  { id: 1316, s: "PNNT", n: "PennantPark Investment Corporation", c: "US" },
  { id: 1317, s: "BAK", n: "Braskem SA Class A", c: "US" },
  { id: 1318, s: "NNN", n: "National Retail Properties Inc", c: "US" },
  { id: 1319, s: "MT", n: "ArcelorMittal SA ADR", c: "US" },
  { id: 1320, s: "HDSN", n: "Hudson Technologies Inc", c: "US" },
  { id: 1321, s: "CELZ", n: "Creative Medical Technology Holdings Inc", c: "US" },
  { id: 1322, s: "SHOP.TO", n: "Shopify Inc", c: "CA" },
  { id: 1323, s: "GGAL", n: "Grupo Financiero Galicia SA ADR", c: "US" },
  { id: 1324, s: "RLF.SW", n: "Relief Therapeutics Holding AG", c: "Switzerland" },
  { id: 1325, s: "TXT", n: "Textron Inc", c: "US" },
  { id: 1326, s: "CRNC", n: "Cerence Inc", c: "US" },
  { id: 1327, s: "DAR", n: "Darling Ingredients Inc", c: "US" },
  { id: 1328, s: "AMKBY", n: "AP Moeller-Maersk AS", c: "US" },
  { id: 1329, s: "KBR", n: "KBR Inc", c: "US" },
  { id: 1330, s: "HNST", n: "Honest Company Inc", c: "US" },
  { id: 1331, s: "WTI", n: "W&T Offshore Inc", c: "US" },
  { id: 1332, s: "FND", n: "Floor & Decor Holdings Inc", c: "US" },
  { id: 1333, s: "CAN", n: "Canaan Inc", c: "US" },
  { id: 1334, s: "AGL", n: "agilon health Inc", c: "US" },
  { id: 1335, s: "FOX", n: "Fox Corp Class B", c: "US" },
  { id: 1336, s: "BCE.TO", n: "BCE Inc", c: "CA" },
  { id: 1337, s: "AFMD", n: "Affimed NV", c: "US" },
  { id: 1338, s: "GTN", n: "Gray Television Inc", c: "US" },
  { id: 1339, s: "VRTV", n: "Veritiv Cor", c: "US" },
  { id: 1340, s: "PNR", n: "Pentair PLC", c: "US" },
  { id: 1341, s: "CQP", n: "Cheniere Energy Partners LP", c: "US" },
  { id: 1342, s: "BWXT", n: "BWX Technologies Inc", c: "US" },
  { id: 1343, s: "CMPS", n: "Compass Pathways Plc", c: "US" },
  { id: 1344, s: "COMM", n: "CommScope Holding Co Inc", c: "US" },
  { id: 1345, s: "HDVY", n: "Health Discovery Cp", c: "US" },
  { id: 1346, s: "SOLO", n: "Electrameccanica Vehicles Co", c: "US" },
  { id: 1347, s: "EIX", n: "Edison International", c: "US" },
  { id: 1348, s: "LEU", n: "Centrus Energy", c: "US" },
  { id: 1349, s: "CPG", n: "Crescent Point Energy Corp.", c: "US" },
  { id: 1350, s: "CMC", n: "Commercial Metals Company", c: "US" },
  { id: 1351, s: "STRL", n: "Sterling Construction Company Inc", c: "US" },
  { id: 1352, s: "BBIG", n: "Vinco Ventures Inc", c: "US" },
  { id: 1353, s: "WAB", n: "Westinghouse Air Brake Technologies Co", c: "US" },
  { id: 1354, s: "UWMC", n: "UWM Holdings Co", c: "US" },
  { id: 1355, s: "VLD", n: "Velo3D Inc", c: "US" },
  { id: 1356, s: "FOUR", n: "Shift4 Payments Inc", c: "US" },
  { id: 1357, s: "SWBI", n: "Smith & Wesson Brands Inc", c: "US" },
  { id: 1358, s: "MASI", n: "Masimo Corporation", c: "US" },
  { id: 1359, s: "ARES", n: "Ares Management LP", c: "US" },
  { id: 1360, s: "SAM", n: "Boston Beer Company Inc", c: "US" },
  { id: 1361, s: "ADPT", n: "Adaptive Biotechnologies Co", c: "US" },
  { id: 1362, s: "GRWG", n: "GrowGeneration Co", c: "US" },
  { id: 1363, s: "BEPC", n: "Brookfield Renewable Co", c: "US" },
  { id: 1364, s: "ZBH", n: "Zimmer Biomet Holdings Inc", c: "US" },
  { id: 1365, s: "SIE.DE", n: "Siemens Aktiengesellschaft", c: "GER" },
  { id: 1366, s: "NRGV", n: "Energy Vault Holdings Inc", c: "US" },
  { id: 1367, s: "FIGS", n: "Figs Inc", c: "US" },
  { id: 1368, s: "HRB", n: "H&R Block Inc", c: "US" },
  { id: 1369, s: "EXPR", n: "Express Inc", c: "US" },
  { id: 1370, s: "CRCT", n: "Cricut Inc", c: "US" },
  { id: 1371, s: "DOC", n: "Physicians Realty Trust", c: "US" },
  { id: 1372, s: "ODC", n: "Oil-Dri Corporation Of America", c: "US" },
  { id: 1373, s: "CASY", n: "Caseys General Stores Inc", c: "US" },
  { id: 1374, s: "LC", n: "LendingClub Co", c: "US" },
  { id: 1375, s: "LITE", n: "Lumentum Holdings Inc", c: "US" },
  { id: 1376, s: "LFMD", n: "LifeMD Inc", c: "US" },
  { id: 1377, s: "TT", n: "Trane Technologies plc", c: "US" },
  { id: 1378, s: "BIO", n: "Bio-Rad Laboratories Inc", c: "US" },
  { id: 1379, s: "CBRE", n: "CBRE Group Inc Class A", c: "US" },
  { id: 1380, s: "ITCI", n: "Intracellular Th", c: "US" },
  { id: 1381, s: "FSM", n: "Fortuna Silver Mines Inc", c: "US" },
  { id: 1382, s: "ATHX", n: "Athersys Inc", c: "US" },
  { id: 1383, s: "H", n: "Hyatt Hotels Corporation", c: "US" },
  { id: 1384, s: "SIX", n: "Six Flags Entertainment New", c: "US" },
  { id: 1385, s: "EVLV", n: "Evolv Technologies Holdings Inc", c: "US" },
  { id: 1386, s: "FIVN", n: "Five9 Inc", c: "US" },
  { id: 1387, s: "AMAM", n: "Ambrx Biopharma Inc. American Depositary Shares", c: "US" },
  { id: 1388, s: "BF-B", n: "Brown-Forman Corporation", c: "US" },
  { id: 1389, s: "SPH", n: "Suburban Propane Partners LP", c: "US" },
  { id: 1390, s: "LYG", n: "Lloyds Banking Group PLC ADR", c: "US" },
  { id: 1391, s: "OCUP", n: "Ocuphire Pharma Inc", c: "US" },
  { id: 1392, s: "MAC", n: "Macerich Company", c: "US" },
  { id: 1393, s: "DOLE", n: "Dole PLC", c: "US" },
  { id: 1394, s: "PKG", n: "Packaging Corp of America", c: "US" },
  { id: 1395, s: "SONN", n: "Sonnet Biotherapeutics Holdings Inc", c: "US" },
  { id: 1396, s: "VERI", n: "Veritone Inc", c: "US" },
  { id: 1397, s: "EDR", n: "Endeavor Group Holdings Inc", c: "US" },
  { id: 1398, s: "HAIN", n: "The Hain Celestial Group Inc", c: "US" },
  { id: 1399, s: "SU.PA", n: "Schneider Electric S.E.", c: "FR" },
  { id: 1400, s: "OBE", n: "Obsidian Energy", c: "US" },
  { id: 1401, s: "PDSB", n: "PDS Biotechnology Co", c: "US" },
  { id: 1402, s: "TAL", n: "TAL Education Group", c: "US" },
  { id: 1403, s: "AQB", n: "AquaBounty Technologies Inc", c: "US" },
  { id: 1404, s: "YY", n: "YY Inc Class A", c: "US" },
  { id: 1405, s: "JAZZ", n: "Jazz Pharmaceuticals PLC", c: "US" },
  { id: 1406, s: "NVEI", n: "Nuvei Co", c: "US" },
  { id: 1407, s: "TNDM", n: "Tandem Diabetes Care Inc", c: "US" },
  { id: 1408, s: "APLS", n: "Apellis Pharmaceuticals Inc", c: "US" },
  { id: 1409, s: "SELB", n: "Selecta Biosciences Inc", c: "US" },
  { id: 1410, s: "SD", n: "SandRidge Energy Inc", c: "US" },
  { id: 1411, s: "AGX", n: "Argan Inc", c: "US" },
  { id: 1412, s: "FUTU", n: "Futu Holdings", c: "US" },
  { id: 1413, s: "NVEC", n: "NVE Corporation", c: "US" },
  { id: 1414, s: "ABI.BR", n: "Anheuser Busch Inbev SA NV", c: "Belgium" },
  { id: 1415, s: "XERS", n: "Xeris Pharmaceuticals Inc", c: "US" },
  { id: 1416, s: "BZH", n: "Beazer Homes USA Inc", c: "US" },
  { id: 1417, s: "CEI", n: "Camber Energy Inc", c: "US" },
  { id: 1418, s: "EXPD", n: "Expeditors International of Washington Inc", c: "US" },
  { id: 1419, s: "SFM", n: "Sprouts Farmers Market LLC", c: "US" },
  { id: 1420, s: "MAT", n: "Mattel Inc", c: "US" },
  { id: 1421, s: "J", n: "Jacobs Solutions Inc.", c: "US" },
  { id: 1422, s: "TEF", n: "Telefonica SA ADR", c: "US" },
  { id: 1423, s: "JOAN", n: "Joann Inc", c: "US" },
  { id: 1424, s: "TSLX", n: "Sixth Street Specialty Lending Inc", c: "US" },
  { id: 1425, s: "WDS", n: "Woodside Energy Group", c: "US" },
  { id: 1426, s: "PHG", n: "Koninklijke Philips NV ADR", c: "US" },
  { id: 1427, s: "CKPT", n: "Checkpoint Therapeutics Inc", c: "US" },
  { id: 1428, s: "NKTR", n: "Nektar Therapeutics", c: "US" },
  { id: 1429, s: "GLPI", n: "Gaming & Leisure Properties", c: "US" },
  { id: 1430, s: "WSO", n: "Watsco Inc", c: "US" },
  { id: 1431, s: "TS", n: "Tenaris SA ADR", c: "US" },
  { id: 1432, s: "FLO", n: "Flowers Foods Inc", c: "US" },
  { id: 1433, s: "BIRD", n: "Allbirds Inc", c: "US" },
  { id: 1434, s: "SSL", n: "Sasol", c: "US" },
  { id: 1435, s: "AKRO", n: "Akero Therapeutics Inc", c: "US" },
  { id: 1436, s: "ALSN", n: "Allison Transmission Holdings Inc", c: "US" },
  { id: 1437, s: "CSU.TO", n: "Constellation Software Inc.", c: "CA" },
  { id: 1438, s: "LIDR", n: "Aeye Inc", c: "US" },
  { id: 1439, s: "SHAK", n: "Shake Shack Inc", c: "US" },
  { id: 1440, s: "DNUT", n: "Krispy Kreme Inc", c: "US" },
  { id: 1441, s: "TCS", n: "Container Store Group Inc", c: "US" },
  { id: 1442, s: "AUMN", n: "Golden Minerals Company", c: "US" },
  { id: 1443, s: "LAD", n: "Lithia Motors Inc", c: "US" },
  { id: 1444, s: "BAH", n: "Booz Allen Hamilton Holding", c: "US" },
  { id: 1445, s: "GNPX", n: "Genprex Inc", c: "US" },
  { id: 1446, s: "COHU", n: "Cohu Inc", c: "US" },
  { id: 1447, s: "SUNW", n: "Sunworks Inc", c: "US" },
  { id: 1448, s: "DTE.DE", n: "Deutsche Telekom AG", c: "GER" },
  { id: 1449, s: "CHDN", n: "Churchill Downs Incorporated", c: "US" },
  { id: 1450, s: "RLX", n: "RLX Technology Inc", c: "US" },
  { id: 1451, s: "ARDS", n: "Aridis Pharmaceuticals Inc", c: "US" },
  { id: 1452, s: "BRY", n: "Berry Petroleum Co", c: "US" },
  { id: 1453, s: "FORD", n: "Forward Industries Inc", c: "US" },
  { id: 1454, s: "ZYME", n: "Zymeworks Inc. Common Stock", c: "US" },
  { id: 1455, s: "HII", n: "Huntington Ingalls Industries Inc", c: "US" },
  { id: 1456, s: "NEWT", n: "Newtek Business Services Co", c: "US" },
  { id: 1457, s: "KOF", n: "Coca-Cola Femsa SAB de CV ADR", c: "US" },
  { id: 1458, s: "UVV", n: "Universal Corporation", c: "US" },
  { id: 1459, s: "MAN", n: "ManpowerGroup Inc", c: "US" },
  { id: 1460, s: "CGTX", n: "Cognition Therapeutics Inc", c: "US" },
  { id: 1461, s: "ZEV", n: "Lightning eMotors Inc.", c: "US" },
  { id: 1462, s: "CCOI", n: "Cogent Communications Group Inc", c: "US" },
  { id: 1463, s: "AON", n: "Aon PLC", c: "US" },
  { id: 1464, s: "BMBL", n: "Bumble Inc", c: "US" },
  { id: 1465, s: "UFPT", n: "UFP Technologies Inc", c: "US" },
  { id: 1466, s: "ONTO", n: "Onto Innovation Inc", c: "US" },
  { id: 1467, s: "ROG.SW", n: "Roche Holding AG", c: "Switzerland" },
  { id: 1468, s: "BELFB", n: "Bel Fuse B Inc", c: "US" },
  { id: 1469, s: "WMS", n: "Advanced Drainage Systems Inc", c: "US" },
  { id: 1470, s: "BOOT", n: "Boot Barn Holdings Inc", c: "US" },
  { id: 1471, s: "ENIC", n: "Enel Chile SA ADR", c: "US" },
  { id: 1472, s: "AOS", n: "Smith AO Corporation", c: "US" },
  { id: 1473, s: "VMC", n: "Vulcan Materials Company", c: "US" },
  { id: 1474, s: "SM", n: "SM Energy", c: "US" },
  { id: 1475, s: "ACER", n: "Acer Therapeutics Inc", c: "US" },
  { id: 1476, s: "SICP", n: "Silvergate Capital Co", c: "US" },
  { id: 1477, s: "AWLIF", n: "Ameriwest Lithium Inc", c: "US" },
  { id: 1478, s: "MDGS", n: "Medigus ADR", c: "US" },
  { id: 1479, s: "TX", n: "Ternium SA ADR", c: "US" },
  { id: 1480, s: "VRSN", n: "VeriSign Inc", c: "US" },
  { id: 1481, s: "ORMP", n: "Oramed Pharmaceuticals Inc", c: "US" },
  { id: 1482, s: "ANF", n: "Abercrombie & Fitch Company", c: "US" },
  { id: 1483, s: "IMMR", n: "Immersion Corporation", c: "US" },
  { id: 1484, s: "TKWY.AS", n: "Just Eat Takeaway", c: "Netherlands" },
  { id: 1485, s: "HUN", n: "Huntsman Corporation", c: "US" },
  { id: 1486, s: "ING", n: "ING Group NV ADR", c: "US" },
  { id: 1487, s: "SEV", n: "Sono Group NV", c: "US" },
  { id: 1488, s: "RYI", n: "Ryerson Holding Co", c: "US" },
  { id: 1489, s: "AVAV", n: "AeroVironment Inc", c: "US" },
  { id: 1490, s: "EPM", n: "Evolution Petroleum Corporation Inc", c: "US" },
  { id: 1491, s: "XMTR", n: "Xometry Inc", c: "US" },
  { id: 1492, s: "VST", n: "Vistra Energy Co", c: "US" },
  { id: 1493, s: "AY", n: "Atlantica Sustainable Infrastructure PLC", c: "US" },
  { id: 1494, s: "VHC", n: "VirnetX Holding Co", c: "US" },
  { id: 1495, s: "HIVE", n: "HIVE Blockchain Technologies", c: "US" },
  { id: 1496, s: "MTDR", n: "Matador Resources Company", c: "US" },
  { id: 1497, s: "ARHS", n: "Arhaus Inc", c: "US" },
  { id: 1498, s: "CIG", n: "Companhia Energetica de Minas Gerais CEMIG Pref ADR", c: "US" },
  { id: 1499, s: "SVC", n: "Service Properties Trust", c: "US" },
  { id: 1500, s: "CM", n: "Canadian Imperial Bank Of Commerce", c: "US" },
  { id: 1501, s: "WCC", n: "WESCO International Inc", c: "US" },
  { id: 1502, s: "MARK", n: "Remark Holdings Inc", c: "US" },
  { id: 1503, s: "DV", n: "DoubleVerify Holdings Inc", c: "US" },
  { id: 1504, s: "SONO", n: "Sonos Inc", c: "US" },
  { id: 1505, s: "SIDU", n: "Sidus Space Inc", c: "US" },
  { id: 1506, s: "PEAK", n: "Healthpeak Properties Inc", c: "US" },
  { id: 1507, s: "TRMB", n: "Trimble Inc", c: "US" },
  { id: 1508, s: "PTC", n: "PTC Inc", c: "US" },
  { id: 1509, s: "EBS", n: "Emergent Biosolutions Inc", c: "US" },
  { id: 1510, s: "MAERSK-B.", n: "A.P. Møller - Mærsk A/S", c: "Denmark" },
  { id: 1511, s: "IRBT", n: "iRobot Corporation", c: "US" },
  { id: 1512, s: "PRTK", n: "Paratek Pharmaceuticals Inc", c: "US" },
  { id: 1513, s: "NWH-UN.TO", n: "NorthWest Healthcare Properties Real Estate Investment Trust", c: "CA" },
  { id: 1514, s: "EAT", n: "Brinker International Inc", c: "US" },
  { id: 1515, s: "GRBK", n: "Green Brick Partners Inc", c: "US" },
  { id: 1516, s: "TDG", n: "Transdigm Group Incorporated", c: "US" },
  { id: 1517, s: "CHKP", n: "Check Point Software Technologies", c: "US" },
  { id: 1518, s: "BEEM", n: "Beam Global", c: "US" },
  { id: 1519, s: "NTRS", n: "Northern Trust Corporation", c: "US" },
  { id: 1520, s: "AMP", n: "Ameriprise Financial Inc", c: "US" },
  { id: 1521, s: "NXST", n: "Nexstar Broadcasting Group Inc", c: "US" },
  { id: 1522, s: "WEED.TO", n: "Canopy Growth Co", c: "CA" },
  { id: 1523, s: "NESN.SW", n: "Nestlé S.A.", c: "Switzerland" },
  { id: 1524, s: "BASE", n: "Couchbase Inc", c: "US" },
  { id: 1525, s: "SCM", n: "Stellus Capital Investment", c: "US" },
  { id: 1526, s: "MOH", n: "Molina Healthcare Inc", c: "US" },
  { id: 1527, s: "NOVO-B.", n: "Novo Nordisk A/S", c: "Denmark" },
  { id: 1528, s: "GTHX", n: "G1 Therapeutics Inc", c: "US" },
  { id: 1529, s: "ROST", n: "Ross Stores Inc", c: "US" },
  { id: 1530, s: "TEL", n: "TE Connectivity", c: "US" },
  { id: 1531, s: "IRNT", n: "Ironnet Inc", c: "US" },
  { id: 1532, s: "ISUN", n: "iSun Inc", c: "US" },
  { id: 1533, s: "DRS", n: "Leonardo DRS Inc. Common Stock", c: "US" },
  { id: 1534, s: "INMB", n: "INmune Bio Inc", c: "US" },
  { id: 1535, s: "OLLI", n: "Ollie's Bargain Outlet Hldg", c: "US" },
  { id: 1536, s: "TDC", n: "Teradata Co", c: "US" },
  { id: 1537, s: "ENZC", n: "Enzolytics Inc", c: "US" },
  { id: 1538, s: "OMEX", n: "Odyssey Marine Exploration Inc", c: "US" },
  { id: 1539, s: "ADT", n: "ADT Inc", c: "US" },
  { id: 1540, s: "MTNB", n: "Matinas BioPharma Holdings Inc", c: "US" },
  { id: 1541, s: "KRC", n: "Kilroy Realty Co", c: "US" },
  { id: 1542, s: "AME", n: "Ametek Inc", c: "US" },
  { id: 1543, s: "NCR", n: "NCR Co", c: "US" },
  { id: 1544, s: "CLH", n: "Clean Harbors Inc", c: "US" },
  { id: 1545, s: "JFIN", n: "Jiayin Group Inc", c: "US" },
  { id: 1546, s: "DMLP", n: "Dorchester Minerals LP", c: "US" },
  { id: 1547, s: "LYV", n: "Live Nation Entertainment Inc", c: "US" },
  { id: 1548, s: "CRL", n: "Charles River Laboratories", c: "US" },
  { id: 1549, s: "FLGC", n: "Flora Growth Co", c: "US" },
  { id: 1550, s: "IFX.DE", n: "Infineon Technologies AG", c: "GER" },
  { id: 1551, s: "RS", n: "Reliance Steel & Aluminum", c: "US" },
  { id: 1552, s: "EXP", n: "Eagle Materials Inc", c: "US" },
  { id: 1553, s: "NI", n: "NiSource Inc", c: "US" },
  { id: 1554, s: "HYMTF", n: "Hyundai Motor Co DRC", c: "US" },
  { id: 1555, s: "SAP.TO", n: "Saputo Inc", c: "CA" },
  { id: 1556, s: "OTEX", n: "Open Text Co", c: "US" },
  { id: 1557, s: "BIMBOA.MX", n: "Grupo Bimbo S.A.B. de C.V", c: "Mexi" },
  { id: 1558, s: "DPRO", n: "Draganfly Inc", c: "US" },
  { id: 1559, s: "WBX", n: "Wallbox NV", c: "US" },
  { id: 1560, s: "AMPH", n: "Amphastar P", c: "US" },
  { id: 1561, s: "NRXP", n: "NRX Pharmaceuticals Inc", c: "US" },
  { id: 1562, s: "ATO", n: "Atmos Energy Corporation", c: "US" },
  { id: 1563, s: "TPH", n: "TRI Pointe Homes Inc", c: "US" },
  { id: 1564, s: "SAR", n: "Saratoga Investment Co", c: "US" },
  { id: 1565, s: "NVMI", n: "Nova", c: "US" },
  { id: 1566, s: "TBLA", n: "Taboola", c: "US" },
  { id: 1567, s: "SBB-B.ST", n: "Samhällsbyggnadsbolaget i Norden AB (publ)", c: "Sweden" },
  { id: 1568, s: "ULCC", n: "Frontier Group Holdings Inc", c: "US" },
  { id: 1569, s: "MRTX", n: "Mirati Ther", c: "US" },
  { id: 1570, s: "AAON", n: "AAON Inc", c: "US" },
  { id: 1571, s: "EYPT", n: "Eyepoint Pharmaceuticals Inc", c: "US" },
  { id: 1572, s: "EURN", n: "Euronav NV", c: "US" },
  { id: 1573, s: "BBDC", n: "Barings BDC Inc", c: "US" },
  { id: 1574, s: "PVH", n: "PVH Co", c: "US" },
  { id: 1575, s: "LNT", n: "Alliant Energy Co", c: "US" },
  { id: 1576, s: "DNMR", n: "Danimer Scientific Inc", c: "US" },
  { id: 1577, s: "DVA", n: "DaVita HealthCare Partners Inc", c: "US" },
  { id: 1578, s: "SNV", n: "Synovus Financial Co", c: "US" },
  { id: 1579, s: "CGNX", n: "Cognex Corporation", c: "US" },
  { id: 1580, s: "ILPT", n: "Industrial Logistics Properties Trust", c: "US" },
  { id: 1581, s: "LKQ", n: "LKQ Corporation", c: "US" },
  { id: 1582, s: "FPI", n: "Farmland Partners Inc", c: "US" },
  { id: 1583, s: "LPTX", n: "Leap Therapeutics Inc", c: "US" },
  { id: 1584, s: "LPLA", n: "LPL Financial Holdings Inc", c: "US" },
  { id: 1585, s: "MNTS", n: "Momentus Inc", c: "US" },
  { id: 1586, s: "FDMT", n: "4D Molecular Therapeutics Inc", c: "US" },
  { id: 1587, s: "NEO", n: "NeoGenomics Inc", c: "US" },
  { id: 1588, s: "LGND", n: "Ligand Pharmaceuticals Incorporated", c: "US" },
  { id: 1589, s: "CPTN", n: "Cepton Inc", c: "US" },
  { id: 1590, s: "SCI", n: "Service Corporation International", c: "US" },
  { id: 1591, s: "SIGA", n: "SIGA Technologies Inc", c: "US" },
  { id: 1592, s: "RNAZ", n: "Transcode Therapeutics Inc", c: "US" },
  { id: 1593, s: "CVAC", n: "CureVac NV", c: "US" },
  { id: 1594, s: "OWL", n: "Blue Owl Capital Inc", c: "US" },
  { id: 1595, s: "XPO", n: "XPO Logistics Inc", c: "US" },
  { id: 1596, s: "SKT", n: "Tanger Factory Outlet Centers Inc", c: "US" },
  { id: 1597, s: "FTK", n: "Flotek Industries Inc", c: "US" },
  { id: 1598, s: "AGI", n: "Alamos Gold Inc", c: "US" },
  { id: 1599, s: "ZLAB", n: "Zai Lab", c: "US" },
  { id: 1600, s: "DBRG", n: "Digitalbridge Group Inc", c: "US" },
  { id: 1601, s: "VERV", n: "Verve Therapeutics Inc", c: "US" },
  { id: 1602, s: "LUNA", n: "Luna Innovations Incorporated", c: "US" },
  { id: 1603, s: "PCOR", n: "Procore Technologies Inc", c: "US" },
  { id: 1604, s: "OP", n: "Oceanpal Inc", c: "US" },
  { id: 1605, s: "LPSN", n: "LivePerson Inc", c: "US" },
  { id: 1606, s: "DFN.TO", n: "Dividend 15 Split Co", c: "CA" },
  { id: 1607, s: "HEI", n: "Heico Corporation", c: "US" },
  { id: 1608, s: "TNK", n: "Teekay Tankers", c: "US" },
  { id: 1609, s: "SACH", n: "Sachem Capital Co", c: "US" },
  { id: 1610, s: "KPTI", n: "Karyopharm Therapeutics Inc", c: "US" },
  { id: 1611, s: "PBYI", n: "Puma Biotechnology Inc", c: "US" },
  { id: 1612, s: "PLTK", n: "Playtika Holding Co", c: "US" },
  { id: 1613, s: "PK", n: "Park Hotels & Resorts Inc", c: "US" },
  { id: 1614, s: "MAS", n: "Masco Corporation", c: "US" },
  { id: 1615, s: "OZK", n: "Bank Ozk", c: "US" },
  { id: 1616, s: "BAYRY", n: "Bayer AG PK", c: "US" },
  { id: 1617, s: "POAHY", n: "Porsche Automobile Holding SE", c: "US" },
  { id: 1618, s: "TGNA", n: "Tegna Inc", c: "US" },
  { id: 1619, s: "CARA", n: "Cara Therapeutic", c: "US" },
  { id: 1620, s: "PETS", n: "PetMed Express Inc", c: "US" },
  { id: 1621, s: "OGE", n: "OGE Energy Corporation", c: "US" },
  { id: 1622, s: "CTRM", n: "Castor Maritime Inc", c: "US" },
  { id: 1623, s: "PRI", n: "Primerica Inc", c: "US" },
  { id: 1624, s: "ARCT", n: "Arcturus Therapeutics Holdings Inc", c: "US" },
  { id: 1625, s: "GRIL", n: "Muscle Maker Inc", c: "US" },
  { id: 1626, s: "SLRC", n: "SLR Investment Co", c: "US" },
  { id: 1627, s: "OMC", n: "Omnicom Group Inc", c: "US" },
  { id: 1628, s: "HIG", n: "Hartford Financial Services Group", c: "US" },
  { id: 1629, s: "KIM", n: "Kimco Realty Corporation", c: "US" },
  { id: 1630, s: "CDW", n: "CDW Co", c: "US" },
  { id: 1631, s: "GECC", n: "Great Elm Capital Co", c: "US" },
  { id: 1632, s: "RL", n: "Ralph Lauren Corp Class A", c: "US" },
  { id: 1633, s: "GBDC", n: "Golub Capital BDC Inc", c: "US" },
  { id: 1634, s: "MTZ", n: "MasTec Inc", c: "US" },
  { id: 1635, s: "RVTY", n: "Revvity Inc.", c: "US" },
  { id: 1636, s: "LCTX", n: "Lineage Cell Therapeutics Inc", c: "US" },
  { id: 1637, s: "FIX", n: "Comfort Systems USA Inc", c: "US" },
  { id: 1638, s: "LIFE", n: "aTyr Pharma Inc", c: "US" },
  { id: 1639, s: "EGHT", n: "8x8 Inc Common Stock", c: "US" },
  { id: 1640, s: "CRDF", n: "Cardiff Oncology Inc", c: "US" },
  { id: 1641, s: "BSY", n: "Bentley Systems Inc", c: "US" },
  { id: 1642, s: "JBHT", n: "JB Hunt Transport Services Inc", c: "US" },
  { id: 1643, s: "MUX", n: "McEwen Mining Inc.", c: "US" },
  { id: 1644, s: "STE", n: "STERIS plc", c: "US" },
  { id: 1645, s: "HUMA", n: "Humacyte Inc", c: "US" },
  { id: 1646, s: "R", n: "Ryder System Inc", c: "US" },
  { id: 1647, s: "WWE", n: "World Wrestling Entertainment Inc", c: "US" },
  { id: 1648, s: "SSRM", n: "SSR Mining Inc", c: "US" },
  { id: 1649, s: "VRSK", n: "Verisk Analytics Inc", c: "US" },
  { id: 1650, s: "AIR.PA", n: "Airbus Group SE", c: "FR" },
  { id: 1651, s: "BNP.PA", n: "BNP Paribas SA", c: "FR" },
  { id: 1652, s: "GOL", n: "Gol Linhas Aereas Inteligentes SA ADR", c: "US" },
  { id: 1653, s: "SG", n: "Sweetgreen Inc", c: "US" },
  { id: 1654, s: "MAXN", n: "Maxeon Solar Technologies", c: "US" },
  { id: 1655, s: "AMBP", n: "Ardagh Metal Packaging SA", c: "US" },
  { id: 1656, s: "BIVI", n: "Biovie Inc", c: "US" },
  { id: 1657, s: "GPMT", n: "Granite Point Mortgage Trust Inc", c: "US" },
  { id: 1658, s: "CLMT", n: "Calumet Specialty Products Partners", c: "US" },
  { id: 1659, s: "PAM", n: "Pampa Energia SA ADR", c: "US" },
  { id: 1660, s: "MLSS", n: "Milestone Scientific", c: "US" },
  { id: 1661, s: "QSI", n: "QuantumSi Inc", c: "US" },
  { id: 1662, s: "CYBN", n: "Cybin Inc", c: "US" },
  { id: 1663, s: "DARE", n: "Dare Bioscience Inc", c: "US" },
  { id: 1664, s: "PLM", n: "Polymet Mining Co", c: "US" },
  { id: 1665, s: "RMD", n: "ResMed Inc", c: "US" },
  { id: 1666, s: "ALNY", n: "Alnylam Pharmaceuticals Inc", c: "US" },
  { id: 1667, s: "OPAD", n: "Offerpad Solutions Inc", c: "US" },
  { id: 1668, s: "MUSA", n: "Murphy USA Inc", c: "US" },
  { id: 1669, s: "HEPA", n: "Hepion Pharmaceuticals Inc", c: "US" },
  { id: 1670, s: "ZYXI", n: "Zynex Inc", c: "US" },
  { id: 1671, s: "SBOW", n: "SilverBow Resources Inc", c: "US" },
  { id: 1672, s: "INVA", n: "Innoviva Inc", c: "US" },
  { id: 1673, s: "CIO", n: "City Office", c: "US" },
  { id: 1674, s: "LSPD", n: "Lightspeed Commerce Inc", c: "US" },
  { id: 1675, s: "MANH", n: "Manhattan Associates Inc", c: "US" },
  { id: 1676, s: "VVV", n: "Valvoline Inc", c: "US" },
  { id: 1677, s: "MTD", n: "Mettler-Toledo International Inc", c: "US" },
  { id: 1678, s: "NGG", n: "National Grid PLC ADR", c: "US" },
  { id: 1679, s: "PAH3.DE", n: "Porsche Automobil Holding SE", c: "GER" },
  { id: 1680, s: "CDTX", n: "Cidara Therapeutics Inc", c: "US" },
  { id: 1681, s: "RAIN", n: "Rain Therapeutics Inc", c: "US" },
  { id: 1682, s: "TRVN", n: "Trevena Inc", c: "US" },
  { id: 1683, s: "MHK", n: "Mohawk Industries Inc", c: "US" },
  { id: 1684, s: "TREX", n: "Trex Company Inc", c: "US" },
  { id: 1685, s: "IBN", n: "ICICI Bank Limited", c: "US" },
  { id: 1686, s: "BAND", n: "Bandwidth Inc", c: "US" },
  { id: 1687, s: "HIW", n: "Highwoods Properties Inc", c: "US" },
  { id: 1688, s: "WRB", n: "W. R. Berkley Co", c: "US" },
  { id: 1689, s: "BKKT", n: "Bakkt Holdings Inc", c: "US" },
  { id: 1690, s: "ELAN", n: "Elanco Animal Health", c: "US" },
  { id: 1691, s: "ACM", n: "Aecom Technology Corporation", c: "US" },
  { id: 1692, s: "MGNX", n: "MacroGenics Inc", c: "US" },
  { id: 1693, s: "AD.AS", n: "Koninklijke Ahold Delhaize NV", c: "Netherlands" },
  { id: 1694, s: "TR", n: "Tootsie Roll Industries Inc", c: "US" },
  { id: 1695, s: "PHUN", n: "Phunware Inc", c: "US" },
  { id: 1696, s: "VEON", n: "VEON", c: "US" },
  { id: 1697, s: "STMZF", n: "Scottish Mortgage Investment Trust PLC", c: "US" },
  { id: 1698, s: "ENTG", n: "Entegris Inc", c: "US" },
  { id: 1699, s: "CMTL", n: "Comtech Telecommunications Co", c: "US" },
  { id: 1700, s: "VMI", n: "Valmont Industries Inc", c: "US" },
  { id: 1701, s: "YUMC", n: "Yum CH Holdings Inc", c: "US" },
  { id: 1702, s: "RELL", n: "Richardson Electronics", c: "US" },
  { id: 1703, s: "CERT", n: "Certara Inc", c: "US" },
  { id: 1704, s: "ARW", n: "Arrow Electronics Inc", c: "US" },
  { id: 1705, s: "UFPI", n: "Ufp Industries Inc", c: "US" },
  { id: 1706, s: "BRO", n: "Brown & Brown Inc", c: "US" },
  { id: 1707, s: "VNOM", n: "Viper Energy Ut", c: "US" },
  { id: 1708, s: "CNP", n: "CenterPoint Energy Inc", c: "US" },
  { id: 1709, s: "COSM", n: "Cosmos Health Inc.", c: "US" },
  { id: 1710, s: "STR", n: "Sitio Royalties Corp.", c: "US" },
  { id: 1711, s: "PNW", n: "Pinnacle West Capital Co", c: "US" },
  { id: 1712, s: "LTCH", n: "Latch Inc", c: "US" },
  { id: 1713, s: "BMEA", n: "Biomea Fusion Inc", c: "US" },
  { id: 1714, s: "ORSTED.", n: "Orsted A/S", c: "Denmark" },
  { id: 1715, s: "INSP", n: "Inspire Medical Systems Inc", c: "US" },
  { id: 1716, s: "SMMT", n: "Summit Therapeutics PLC", c: "US" },
  { id: 1717, s: "YAR.OL", n: "Yara International ASA", c: "Norway" },
  { id: 1718, s: "NTR.TO", n: "Nutrien", c: "CA" },
  { id: 1719, s: "KC", n: "Kingsoft Cloud Holdings Ltd", c: "US" },
  { id: 1720, s: "WFRD", n: "Weatherford International PLC", c: "US" },
  { id: 1721, s: "AVT", n: "Avnet Inc", c: "US" },
  { id: 1722, s: "EQX", n: "Equinox Gold Co", c: "US" },
  { id: 1723, s: "FYBR", n: "Frontier Communications Parent Inc", c: "US" },
  { id: 1724, s: "VIR", n: "Vir Biotechnology Inc", c: "US" },
  { id: 1725, s: "ONC.TO", n: "Oncolytics Biotech Inc", c: "CA" },
  { id: 1726, s: "CNR.TO", n: "Canadian National Railway", c: "CA" },
  { id: 1727, s: "BALL", n: "Ball Corporation", c: "US" },
  { id: 1728, s: "HOLX", n: "Hologic Inc", c: "US" },
  { id: 1729, s: "BZFD", n: "BuzzFeed Inc", c: "US" },
  { id: 1730, s: "SAIA", n: "Saia Inc", c: "US" },
  { id: 1731, s: "ADYEN.AS", n: "Adyen NV", c: "Netherlands" },
  { id: 1732, s: "TWOU", n: "2U Inc", c: "US" },
  { id: 1733, s: "CRNT", n: "Ceragon Networks", c: "US" },
  { id: 1734, s: "PRT", n: "PermRock Royalty Trust", c: "US" },
  { id: 1735, s: "TDW", n: "Tidewater Inc", c: "US" },
  { id: 1736, s: "AGFY", n: "Agrify Co", c: "US" },
  { id: 1737, s: "NRBO", n: "Neurobo Pharmaceuticals Inc", c: "US" },
  { id: 1738, s: "VRAY", n: "ViewRay Inc", c: "US" },
  { id: 1739, s: "QUIK", n: "QuickLogic Corporation", c: "US" },
  { id: 1740, s: "URBN", n: "Urban Outfitters Inc", c: "US" },
  { id: 1741, s: "AHT", n: "Ashford Hospitality Trust Inc", c: "US" },
  { id: 1742, s: "SID", n: "Companhia Siderurgica Nacional ADR", c: "US" },
  { id: 1743, s: "EXAI", n: "Exscientia ADR", c: "US" },
  { id: 1744, s: "BAVA.", n: "Bavarian Nordic", c: "Denmark" },
  { id: 1745, s: "AQST", n: "Aquestive Therapeutics Inc", c: "US" },
  { id: 1746, s: "PMTS", n: "CPI Card Group Inc", c: "US" },
  { id: 1747, s: "RGR", n: "Sturm Ruger & Company Inc", c: "US" },
  { id: 1748, s: "NIU", n: "Niu Technologies", c: "US" },
  { id: 1749, s: "NEXT", n: "Nextdecade Co", c: "US" },
  { id: 1750, s: "YOU", n: "Clear Secure Inc", c: "US" },
  { id: 1751, s: "BRCC", n: "BRC Inc.", c: "US" },
  { id: 1752, s: "BBD-B.TO", n: "Bombardier Inc", c: "CA" },
  { id: 1753, s: "DZSI", n: "DZS Inc", c: "US" },
  { id: 1754, s: "NDSN", n: "Nordson Corporation", c: "US" },
  { id: 1755, s: "IFRX", n: "InflaRx N.V.", c: "US" },
  { id: 1756, s: "BBIO", n: "BridgeBio Pharma Inc", c: "US" },
  { id: 1757, s: "CTLP", n: "Cantaloupe Inc", c: "US" },
  { id: 1758, s: "HLIT", n: "Harmonic Inc", c: "US" },
  { id: 1759, s: "AZ", n: "A2Z Smart Technologies Co", c: "US" },
  { id: 1760, s: "SANM", n: "Sanmina Corporation", c: "US" },
  { id: 1761, s: "TALK", n: "Talkspace Inc", c: "US" },
  { id: 1762, s: "RHI", n: "Robert Half International Inc", c: "US" },
  { id: 1763, s: "SEE", n: "Sealed Air Corporation", c: "US" },
  { id: 1764, s: "ANVS", n: "Annovis Bio Inc", c: "US" },
  { id: 1765, s: "TH", n: "Target Hospitality Co", c: "US" },
  { id: 1766, s: "EPSN", n: "Epsilon Energy", c: "US" },
  { id: 1767, s: "MX", n: "MagnaChip Semiconductor", c: "US" },
  { id: 1768, s: "ATUS", n: "Altice USA Inc", c: "US" },
  { id: 1769, s: "SYNA", n: "Synaptics Incorporated", c: "US" },
  { id: 1770, s: "CM.TO", n: "Canadian Imperial Bank Of Commerce", c: "CA" },
  { id: 1771, s: "HUYA", n: "HUYA Inc", c: "US" },
  { id: 1772, s: "QLYS", n: "Qualys Inc", c: "US" },
  { id: 1773, s: "TUI1.DE", n: "TUI AG", c: "GER" },
  { id: 1774, s: "KRO", n: "Kronos Worldwide Inc", c: "US" },
  { id: 1775, s: "KRYS", n: "Krystal Biotech Inc", c: "US" },
  { id: 1776, s: "UTZ", n: "Utz Brands Inc", c: "US" },
  { id: 1777, s: "ARQT", n: "Arcutis Biotherapeutics Inc", c: "US" },
  { id: 1778, s: "CPT", n: "Camden Property Trust", c: "US" },
  { id: 1779, s: "BLDE", n: "Blade Air Mobility Inc", c: "US" },
  { id: 1780, s: "LTH", n: "Life Time Group Holdings Inc", c: "US" },
  { id: 1781, s: "QDEL", n: "Quidel Corporation", c: "US" },
  { id: 1782, s: "ROOT", n: "Root Inc", c: "US" },
  { id: 1783, s: "SAIC", n: "Science Applications International Co", c: "US" },
  { id: 1784, s: "TMHC", n: "Taylor Morn Home", c: "US" },
  { id: 1785, s: "TOU.TO", n: "Tourmaline Oil Corp.", c: "CA" },
  { id: 1786, s: "DRRX", n: "Durect Corporation", c: "US" },
  { id: 1787, s: "KNDI", n: "Kandi Technologies Group Inc", c: "US" },
  { id: 1788, s: "IAG", n: "IAMGold Corporation", c: "US" },
  { id: 1789, s: "RYAM", n: "Rayonier Advanced Materials", c: "US" },
  { id: 1790, s: "VSTO", n: "Vista Outdoor Inc", c: "US" },
  { id: 1791, s: "IREN", n: "Iris Energy", c: "US" },
  { id: 1792, s: "FUV", n: "Arcimoto Inc", c: "US" },
  { id: 1793, s: "BIPC", n: "Brookfield Infrastructure Co", c: "US" },
  { id: 1794, s: "REXR", n: "Rexford Industrial Realty Inc", c: "US" },
  { id: 1795, s: "ACOR", n: "Acorda Therapeutics Inc", c: "US" },
  { id: 1796, s: "RMS.PA", n: "Hermes International SCA", c: "FR" },
  { id: 1797, s: "PLCE", n: "Children’s Place Inc", c: "US" },
  { id: 1798, s: "ALV.DE", n: "Allianz SE VNA O.N.", c: "GER" },
  { id: 1799, s: "PVL", n: "Permianville Royalty Trust", c: "US" },
  { id: 1800, s: "BURL", n: "Burlington Stores Inc", c: "US" },
  { id: 1801, s: "CSGP", n: "CoStar Group Inc", c: "US" },
  { id: 1802, s: "OR.PA", n: "L’Oreal SA", c: "FR" },
  { id: 1803, s: "UBSG.SW", n: "UBS Group AG", c: "Switzerland" },
  { id: 1804, s: "ALTO", n: "Alto Ingredients Inc", c: "US" },
  { id: 1805, s: "DAKT", n: "Daktronics Inc", c: "US" },
  { id: 1806, s: "PRGO", n: "Perrigo Company PLC", c: "US" },
  { id: 1807, s: "KPLT", n: "Katapult Holdings Inc", c: "US" },
  { id: 1808, s: "NT", n: "Natura & Co Holding SA", c: "US" },
  { id: 1809, s: "BR", n: "Broadridge Financial Solutions Inc", c: "US" },
  { id: 1810, s: "CINF", n: "Cincinnati Financial Corporation", c: "US" },
  { id: 1811, s: "AEG", n: "Aegon NV ADR", c: "US" },
  { id: 1812, s: "TWI", n: "Titan International Inc", c: "US" },
  { id: 1813, s: "KALA", n: "Kala Pharmaceuticals Inc", c: "US" },
  { id: 1814, s: "NERV", n: "Minerva Neurosciences Inc", c: "US" },
  { id: 1815, s: "AU", n: "AngloGold Ashanti plc", c: "US" },
  { id: 1816, s: "IBIO", n: "Ibio Inc", c: "US" },
  { id: 1817, s: "NSA", n: "National Storage Affiliates Trust", c: "US" },
  { id: 1818, s: "RENT", n: "Rent the Runway Inc", c: "US" },
  { id: 1819, s: "AGRO", n: "Adecoagro SA", c: "US" },
  { id: 1820, s: "AXTI", n: "AXT Inc", c: "US" },
  { id: 1821, s: "HESM", n: "Hess Midstream Partners LP", c: "US" },
  { id: 1822, s: "TRGP", n: "Targa Resources Inc", c: "US" },
  { id: 1823, s: "PAG", n: "Penske Automotive Group Inc", c: "US" },
  { id: 1824, s: "SSNC", n: "SS&C Technologies Holdings Inc", c: "US" },
  { id: 1825, s: "AMED", n: "Amedisys Inc", c: "US" },
  { id: 1826, s: "SIEGY", n: "Siemens AG ADR", c: "US" },
  { id: 1827, s: "ROL", n: "Rollins Inc", c: "US" },
  { id: 1828, s: "FUN", n: "Cedar Fair LP", c: "US" },
  { id: 1829, s: "NRP", n: "Natural Resource Partners LP", c: "US" },
  { id: 1830, s: "AQN.TO", n: "Algonquin Power & Utilities Co", c: "CA" },
  { id: 1831, s: "KOS", n: "Kosmos Energy", c: "US" },
  { id: 1832, s: "QURE", n: "Uniqure NV", c: "US" },
  { id: 1833, s: "VIRT", n: "Virtu Financial Inc", c: "US" },
  { id: 1834, s: "CRMD", n: "CorMedix Inc", c: "US" },
  { id: 1835, s: "NKTX", n: "Nkarta Inc", c: "US" },
  { id: 1836, s: "AQMS", n: "Aqua Metals Inc", c: "US" },
  { id: 1837, s: "RICK", n: "RCI Hospitality Holdings Inc", c: "US" },
  { id: 1838, s: "BMO.TO", n: "Bank of Montreal", c: "CA" },
  { id: 1839, s: "MUFG", n: "Mitsubishi UFJ Financial Group Inc ADR", c: "US" },
  { id: 1840, s: "DOX", n: "Amdocs", c: "US" },
  { id: 1841, s: "CGNT", n: "Cognyte Software", c: "US" },
  { id: 1842, s: "PODD", n: "Insulet Corporation", c: "US" },
  { id: 1843, s: "HEAR", n: "Turtle Beach Co", c: "US" },
  { id: 1844, s: "LE", n: "Lincoln Electric Holdings Inc", c: "US" },
  { id: 1845, s: "LSPD.TO", n: "Lightspeed Commerce Inc", c: "CA" },
  { id: 1846, s: "BBVA", n: "Banco Bilbao Viscaya Argentaria SA ADR", c: "US" },
  { id: 1847, s: "TRP.TO", n: "TC Energy Co", c: "CA" },
  { id: 1848, s: "PLBY", n: "Plby Group Inc", c: "US" },
  { id: 1849, s: "CHS", n: "Chicos FAS Inc", c: "US" },
  { id: 1850, s: "300750.SZ", n: "Contemporary Amperex Technology Co Class A", c: "CH" },
  { id: 1851, s: "NEWR", n: "New Relic Inc", c: "US" },
  { id: 1852, s: "TU", n: "Telus Co", c: "US" },
  { id: 1853, s: "ATD.TO", n: "Alimentation Couchen Tard Inc A", c: "CA" },
  { id: 1854, s: "BHP.AX", n: "BHP Group Limited", c: "Australia" },
  { id: 1855, s: "ZAL.DE", n: "Zalando SE", c: "GER" },
  { id: 1856, s: "SITM", n: "Sitime Corporation", c: "US" },
  { id: 1857, s: "NARI", n: "Inari Medical Inc", c: "US" },
  { id: 1858, s: "LAMR", n: "Lamar Advertising Company", c: "US" },
  { id: 1859, s: "BTE.TO", n: "Baytex Energy Co", c: "CA" },
  { id: 1860, s: "PAGP", n: "Plains GP Holdings LP", c: "US" },
  { id: 1861, s: "DMRC", n: "Digimarc Corporation", c: "US" },
  { id: 1862, s: "KIND", n: "Nextdoor Holdings Inc", c: "US" },
  { id: 1863, s: "PARAA", n: "Paramount Global Class A", c: "US" },
  { id: 1864, s: "NFG", n: "National Fuel Gas Company", c: "US" },
  { id: 1865, s: "THC", n: "Tenet Healthcare Corporation", c: "US" },
  { id: 1866, s: "CALX", n: "Calix Inc", c: "US" },
  { id: 1867, s: "RRGB", n: "Red Robin Gourmet Burgers Inc", c: "US" },
  { id: 1868, s: "MMMB", n: "MamaMancini's Holdings Inc", c: "US" },
  { id: 1869, s: "AMTI", n: "Applied Molecular Transport Inc", c: "US" },
  { id: 1870, s: "MOBQ", n: "Mobiquity Technologies Inc", c: "US" },
  { id: 1871, s: "EXK", n: "Endeavour Silver Corp.", c: "US" },
  { id: 1872, s: "ELTK", n: "Eltek", c: "US" },
  { id: 1873, s: "CLS", n: "Celestica Inc.", c: "US" },
  { id: 1874, s: "AZUL", n: "Azul SA", c: "US" },
  { id: 1875, s: "RXT", n: "Rackspace Technology Inc", c: "US" },
  { id: 1876, s: "CNQ.TO", n: "Canadian Natural Resources", c: "CA" },
  { id: 1877, s: "CTSO", n: "Cytosorbents Crp", c: "US" },
  { id: 1878, s: "MLKN", n: "MillerKnoll Inc", c: "US" },
  { id: 1879, s: "VOC", n: "VOC Energy Trust", c: "US" },
  { id: 1880, s: "BESI.AS", n: "BE Semiconductor Industries NV", c: "Netherlands" },
  { id: 1881, s: "MORF", n: "Morphic Holding Inc", c: "US" },
  { id: 1882, s: "EDU", n: "New Oriental Education & Technology", c: "US" },
  { id: 1883, s: "IART", n: "Integra LifeSciences Holdings", c: "US" },
  { id: 1884, s: "SPIR", n: "Spire Global Inc", c: "US" },
  { id: 1885, s: "PHVS", n: "Pharvaris BV", c: "US" },
  { id: 1886, s: "BKH", n: "Black Hills Corporation", c: "US" },
  { id: 1887, s: "WULF", n: "Terawulf Inc", c: "US" },
  { id: 1888, s: "RYAAY", n: "Ryanair Holdings PLC ADR", c: "US" },
  { id: 1889, s: "NVRO", n: "Nevro Co", c: "US" },
  { id: 1890, s: "TENX", n: "Tenax Therapeutics Inc", c: "US" },
  { id: 1891, s: "ACVA", n: "ACV Auctions Inc", c: "US" },
  { id: 1892, s: "DFH", n: "Dream Finders Homes Inc", c: "US" },
  { id: 1893, s: "SDIG", n: "Stronghold Digital Mining Inc", c: "US" },
  { id: 1894, s: "AIRC", n: "Apartment Income REIT Co", c: "US" },
  { id: 1895, s: "AYTU", n: "Aytu BioScience Inc", c: "US" },
  { id: 1896, s: "FLGT", n: "Fulgent Genetics Inc", c: "US" },
  { id: 1897, s: "ML", n: "Melco Resorts & Entertainment", c: "US" },
  { id: 1898, s: "JKHY", n: "Jack Henry & Associates Inc", c: "US" },
  { id: 1899, s: "CCF", n: "Chase Corporation", c: "US" },
  { id: 1900, s: "ALIT", n: "Alight Inc", c: "US" },
  { id: 1901, s: "CDLX", n: "Cardlytics Inc", c: "US" },
  { id: 1902, s: "IDYA", n: "Ideaya Biosciences Inc", c: "US" },
  { id: 1903, s: "EBIX", n: "Ebix Inc", c: "US" },
  { id: 1904, s: "BIGC", n: "Bigcommerce Holdings Inc", c: "US" },
  { id: 1905, s: "LADR", n: "Ladder Capital Corp Class A", c: "US" },
  { id: 1906, s: "GWRS", n: "Global Water Resources Inc", c: "US" },
  { id: 1907, s: "DOL.TO", n: "Dollarama Inc", c: "CA" },
  { id: 1908, s: "VYGR", n: "Voyager Therapeutics Inc", c: "US" },
  { id: 1909, s: "TRIN", n: "Trinity Capital Inc", c: "US" },
  { id: 1910, s: "ALZN", n: "Alzamend Neuro Inc", c: "US" },
  { id: 1911, s: "ARGX", n: "argenx NV ADR", c: "US" },
  { id: 1912, s: "BOWL", n: "Bowlero Co", c: "US" },
  { id: 1913, s: "CHEK", n: "Check Cap", c: "US" },
  { id: 1914, s: "TXG", n: "10X Genomics Inc", c: "US" },
  { id: 1915, s: "AIM", n: "AIM ImmunoTech Inc", c: "US" },
  { id: 1916, s: "EVRG", n: "Evergy Inc. Common Stock", c: "US" },
  { id: 1917, s: "ZUO", n: "Zuora Inc", c: "US" },
  { id: 1918, s: "IEX", n: "IDEX Corporation", c: "US" },
  { id: 1919, s: "WB", n: "Weibo Co", c: "US" },
  { id: 1920, s: "WIT", n: "Wipro Limited ADR", c: "US" },
  { id: 1921, s: "SBAC", n: "SBA Communications Co", c: "US" },
  { id: 1922, s: "MKSI", n: "MKS Instruments Inc", c: "US" },
  { id: 1923, s: "PDM", n: "Piedmont Office Realty Trust Inc", c: "US" },
  { id: 1924, s: "ACIW", n: "ACI Worldwide Inc", c: "US" },
  { id: 1925, s: "BVN", n: "Compania de Minas Buenaventura SAA ADR", c: "US" },
  { id: 1926, s: "CMRE", n: "Costamare Inc", c: "US" },
  { id: 1927, s: "ATI", n: "Allegheny Technologies Incorporated", c: "US" },
  { id: 1928, s: "KER.PA", n: "Kering SA", c: "FR" },
  { id: 1929, s: "CUBE", n: "CubeSmart", c: "US" },
  { id: 1930, s: "NRDY", n: "Nerdy Inc", c: "US" },
  { id: 1931, s: "ZG", n: "Zillow Group Inc", c: "US" },
  { id: 1932, s: "LVTX", n: "LAVA Therapeutics NV", c: "US" },
  { id: 1933, s: "RDY", n: "Dr. Reddy’s Laboratories ADR", c: "US" },
  { id: 1934, s: "DGX", n: "Quest Diagnostics Incorporated", c: "US" },
  { id: 1935, s: "NGD", n: "New Gold Inc", c: "US" },
  { id: 1936, s: "ERAS", n: "Erasca Inc", c: "US" },
  { id: 1937, s: "DADA", n: "Dada Nexus", c: "US" },
  { id: 1938, s: "AMCX", n: "AMC Networks Inc", c: "US" },
  { id: 1939, s: "GNLN", n: "Greenlane Holdings Inc", c: "US" },
  { id: 1940, s: "KNTE", n: "Kinnate Biopharma Inc", c: "US" },
  { id: 1941, s: "ADTN", n: "ADTRAN Inc", c: "US" },
  { id: 1942, s: "TROX", n: "Tronox Holdings PLC", c: "US" },
  { id: 1943, s: "AROW", n: "Arrow Financial Corporation", c: "US" },
  { id: 1944, s: "SSSS", n: "SuRo Capital Co", c: "US" },
  { id: 1945, s: "BEAT", n: "Heartbeam Inc", c: "US" },
  { id: 1946, s: "GEL", n: "Genesis Energy LP", c: "US" },
  { id: 1947, s: "TFSL", n: "TFS Financial Corporation", c: "US" },
  { id: 1948, s: "TLYS", n: "Tillys Inc", c: "US" },
  { id: 1949, s: "RDW", n: "Redwire Co", c: "US" },
  { id: 1950, s: "GOOS", n: "CA Goose Holdings Inc", c: "US" },
  { id: 1951, s: "HOOK", n: "Hookipa Pharma Inc", c: "US" },
  { id: 1952, s: "ERF", n: "Enerplus Corporation", c: "US" },
  { id: 1953, s: "NCMI", n: "National CineMedia Inc", c: "US" },
  { id: 1954, s: "VLN", n: "Valens", c: "US" },
  { id: 1955, s: "GLE.PA", n: "Societe Generale S.A.", c: "FR" },
  { id: 1956, s: "GREE", n: "Greenidge Generation Holdings Inc", c: "US" },
  { id: 1957, s: "PCT", n: "Purecycle Technologies Holdings Co", c: "US" },
  { id: 1958, s: "PUM.DE", n: "PUMA SE", c: "GER" },
  { id: 1959, s: "CRKN", n: "Crown Electrokinetics Corp.", c: "US" },
  { id: 1960, s: "FTS.TO", n: "Fortis Inc", c: "CA" },
  { id: 1961, s: "NINE", n: "Nine Energy Service Inc", c: "US" },
  { id: 1962, s: "VTSI", n: "VirTra Inc", c: "US" },
  { id: 1963, s: "CAMT", n: "Camtek", c: "US" },
  { id: 1964, s: "FRSH", n: "Freshworks Inc", c: "US" },
  { id: 1965, s: "JAGX", n: "Jaguar Animal Health Inc", c: "US" },
  { id: 1966, s: "MSA", n: "MSA Safety", c: "US" },
  { id: 1967, s: "SMWB", n: "SimilarWeb", c: "US" },
  { id: 1968, s: "MHO", n: "M/I Homes Inc", c: "US" },
  { id: 1969, s: "TAST", n: "Carrols Restaurant Group Inc", c: "US" },
  { id: 1970, s: "CYBR", n: "CyberArk Software", c: "US" },
  { id: 1971, s: "AMN", n: "AMN Healthcare Services Inc", c: "US" },
  { id: 1972, s: "FTAI", n: "Fortress Transp & Infra Inv", c: "US" },
  { id: 1973, s: "DMTK", n: "DermTech Inc", c: "US" },
  { id: 1974, s: "HMST", n: "HomeStreet Inc", c: "US" },
  { id: 1975, s: "GNTX", n: "Gentex Corporation", c: "US" },
  { id: 1976, s: "BLMN", n: "Bloomin Brands Inc", c: "US" },
  { id: 1977, s: "RJF", n: "Raymond James Financial Inc.", c: "US" },
  { id: 1978, s: "CAL", n: "Caleres Inc", c: "US" },
  { id: 1979, s: "ATAI", n: "ATAI Life Sciences BV", c: "US" },
  { id: 1980, s: "AUGX", n: "Augmedix Inc", c: "US" },
  { id: 1981, s: "MTR", n: "Mesa Royalty Trust", c: "US" },
  { id: 1982, s: "HBM", n: "Hudbay Minerals Inc.", c: "US" },
  { id: 1983, s: "SPOK", n: "Spok Holdings Inc", c: "US" },
  { id: 1984, s: "PDO", n: "Pimco Dynamic Income Opportunities Fund", c: "US" },
  { id: 1985, s: "SANA", n: "Sana Biotechnology Inc", c: "US" },
  { id: 1986, s: "KURA", n: "Kura Oncology Inc", c: "US" },
  { id: 1987, s: "RNW", n: "Renew Energy Global PLC", c: "US" },
  { id: 1988, s: "REKR", n: "Rekor Systems Inc", c: "US" },
  { id: 1989, s: "IT", n: "Gartner Inc", c: "US" },
  { id: 1990, s: "KTB", n: "Kontoor Brands Inc", c: "US" },
  { id: 1991, s: "XOS", n: "Xos Inc", c: "US" },
  { id: 1992, s: "GL", n: "Globe Life Inc", c: "US" },
  { id: 1993, s: "CELU", n: "Celularity Inc", c: "US" },
  { id: 1994, s: "AVO", n: "Mission Produce Inc", c: "US" },
  { id: 1995, s: "BHG", n: "Bright Health Group Inc", c: "US" },
  { id: 1996, s: "AMLX", n: "Amylyx Pharmaceuticals Inc", c: "US" },
  { id: 1997, s: "BCLI", n: "Brainstorm Cell Therapeutics Inc", c: "US" },
  { id: 1998, s: "WSR", n: "Whitestone REIT", c: "US" },
  { id: 1999, s: "AVY", n: "Avery Dennison Co", c: "US" },
  { id: 2000, s: "PRPH", n: "ProPhase Labs Inc", c: "US" },
  { id: 2001, s: "NXE", n: "NexGen Energy.", c: "US" },
  { id: 2002, s: "MJNA", n: "Medical Marijuana I", c: "US" },
  { id: 2003, s: "GSM", n: "Ferroglobe PLC", c: "US" },
  { id: 2004, s: "CRIS", n: "Curis Inc", c: "US" },
  { id: 2005, s: "VWS.", n: "Vestas Wind Systems A/S", c: "Denmark" },
  { id: 2006, s: "AEMD", n: "Aethlon Medical Inc", c: "US" },
  { id: 2007, s: "DOYU", n: "DouYu International Holdings", c: "US" },
  { id: 2008, s: "INVH", n: "Invitation Homes Inc", c: "US" },
  { id: 2009, s: "NFGC", n: "New Found Gold Co", c: "US" },
  { id: 2010, s: "GBLI", n: "Global Indemnity PLC", c: "US" },
  { id: 2011, s: "AABB", n: "Asia Broadband Inc", c: "US" },
  { id: 2012, s: "LODE", n: "Comstock Mining Inc", c: "US" },
  { id: 2013, s: "RCMT", n: "RCM Technologies Inc", c: "US" },
  { id: 2014, s: "SVM", n: "Silvercorp Metals Inc", c: "US" },
  { id: 2015, s: "DK", n: "Delek US Energy Inc", c: "US" },
  { id: 2016, s: "GMAB", n: "Genmab AS", c: "US" },
  { id: 2017, s: "AER", n: "AerCap Holdings NV", c: "US" },
  { id: 2018, s: "VEEE", n: "Twin Vee Powercats", c: "US" },
  { id: 2019, s: "ELBM", n: "Electra Battery Materials Co", c: "US" },
  { id: 2020, s: "LBRT", n: "Liberty Oilfield Services Inc", c: "US" },
  { id: 2021, s: "BBW", n: "Build-A-Bear Workshop Inc", c: "US" },
  { id: 2022, s: "CATC", n: "Cambridge BanCo", c: "US" },
  { id: 2023, s: "LXU", n: "Lsb Industries Inc", c: "US" },
  { id: 2024, s: "STEP", n: "Stepstone Group Inc", c: "US" },
  { id: 2025, s: "ABCM", n: "Abcam PLC", c: "US" },
  { id: 2026, s: "HCC", n: "Warrior Met Coal Inc", c: "US" },
  { id: 2027, s: "AFG", n: "American Financial Group Inc", c: "US" },
  { id: 2028, s: "SLGC", n: "Somalogic Inc", c: "US" },
  { id: 2029, s: "ALLE", n: "Allegion PLC", c: "US" },
  { id: 2030, s: "OPTT", n: "Ocean Power Technologies Inc", c: "US" },
  { id: 2031, s: "FNCH", n: "Finch Therapeutics Group Inc", c: "US" },
  { id: 2032, s: "SOS", n: "SOS Limited", c: "US" },
  { id: 2033, s: "METC", n: "Ramaco Resources Inc", c: "US" },
  { id: 2034, s: "EME", n: "EMCOR Group Inc", c: "US" },
  { id: 2035, s: "BEKE", n: "Ke Holdings Inc", c: "US" },
  { id: 2036, s: "DIN", n: "Dine Brands Global Inc", c: "US" },
  { id: 2037, s: "LOB", n: "Live Oak Bancshares Inc", c: "US" },
  { id: 2038, s: "PTGX", n: "Protagonist Therapeutics Inc", c: "US" },
  { id: 2039, s: "HST", n: "Host Hotels & Resorts Inc", c: "US" },
  { id: 2040, s: "ATER", n: "Aterian Inc", c: "US" },
  { id: 2041, s: "ZDGE", n: "Zedge Inc", c: "US" },
  { id: 2042, s: "DRMA", n: "Dermata Therapeutics Inc", c: "US" },
  { id: 2043, s: "GRTS", n: "Gritstone Oncology Inc", c: "US" },
  { id: 2044, s: "AMRK", n: "Amark Preci", c: "US" },
  { id: 2045, s: "MRAM", n: "Everspin Technologies Inc", c: "US" },
  { id: 2046, s: "CODX", n: "Co-Diagnostics Inc", c: "US" },
  { id: 2047, s: "LHA.DE", n: "Deutsche Lufthansa AG", c: "GER" },
  { id: 2048, s: "PSWW", n: "Principal Solar Inc", c: "US" },
  { id: 2049, s: "UTHR", n: "United Therapeutics Corporation", c: "US" },
  { id: 2050, s: "HGEN", n: "Humanigen Inc", c: "US" },
  { id: 2051, s: "WBS", n: "Webster Financial Corporation", c: "US" },
  { id: 2052, s: "JACK", n: "Jack In The Box Inc", c: "US" },
  { id: 2053, s: "NTRA", n: "Natera Inc", c: "US" },
  { id: 2054, s: "ZUMZ", n: "Zumiez Inc", c: "US" },
  { id: 2055, s: "CGBD", n: "Carlyle Secured Lending Inc", c: "US" },
  { id: 2056, s: "ABSI", n: "Absci Co", c: "US" },
  { id: 2057, s: "NEOG", n: "Neogen Corporation", c: "US" },
  { id: 2058, s: "BCC", n: "Boise Cascad Llc", c: "US" },
  { id: 2059, s: "MATX", n: "Matson Inc", c: "US" },
  { id: 2060, s: "NA9.DE", n: "Nagarro SE", c: "GER" },
  { id: 2061, s: "IRWD", n: "Ironwood Pharmaceuticals Inc", c: "US" },
  { id: 2062, s: "RBBN", n: "Ribbon Communications Inc", c: "US" },
  { id: 2063, s: "FDS", n: "FactSet Research Systems Inc", c: "US" },
  { id: 2064, s: "CR", n: "Crane Company", c: "US" },
  { id: 2065, s: "KIRK", n: "Kirklands Inc", c: "US" },
  { id: 2066, s: "CBOE", n: "Cboe Global Markets Inc", c: "US" },
  { id: 2067, s: "ERJ", n: "Embraer SA ADR", c: "US" },
  { id: 2068, s: "DAWN", n: "Day One Biopharmaceuticals Inc", c: "US" },
  { id: 2069, s: "NXGL", n: "Nexgel Inc", c: "US" },
  { id: 2070, s: "REPX", n: "Riley Exploration Permian Inc", c: "US" },
  { id: 2071, s: "NSSC", n: "NAPCO Security Technologies Inc", c: "US" },
  { id: 2072, s: "ATZ.TO", n: "Aritzia Inc", c: "CA" },
  { id: 2073, s: "SHIP", n: "Seanergy Maritime Holdings Co", c: "US" },
  { id: 2074, s: "NAPA", n: "Duckhorn Portfolio Inc", c: "US" },
  { id: 2075, s: "RETA", n: "Reata Pharmaceuticals Inc", c: "US" },
  { id: 2076, s: "MKTX", n: "MarketAxess Holdings Inc", c: "US" },
  { id: 2077, s: "RPD", n: "Rapid7 Inc", c: "US" },
  { id: 2078, s: "FLYW", n: "Flywire Co", c: "US" },
  { id: 2079, s: "INKT", n: "Mink Therapeutics Inc", c: "US" },
  { id: 2080, s: "COIHY", n: "Croda International PLC ADR", c: "US" },
  { id: 2081, s: "MBIO", n: "Mustang Bio Inc", c: "US" },
  { id: 2082, s: "PKX", n: "POSCO Holdings Inc", c: "US" },
  { id: 2083, s: "PRSO", n: "Peraso Inc", c: "US" },
  { id: 2084, s: "HTCR", n: "HeartCore Enterprises Inc", c: "US" },
  { id: 2085, s: "ULVR.L", n: "Unilever PLC", c: "UK" },
  { id: 2086, s: "ASPN", n: "Aspen Aerogels Inc", c: "US" },
  { id: 2087, s: "GROW", n: "US Global Investors Inc", c: "US" },
  { id: 2088, s: "TK", n: "Teekay Corporation", c: "US" },
  { id: 2089, s: "AKTS", n: "Akoustis Technologies Inc", c: "US" },
  { id: 2090, s: "MATV", n: "Mativ Holdings Inc.", c: "US" },
  { id: 2091, s: "GORO", n: "Gold Resource Corporation", c: "US" },
  { id: 2092, s: "SNX", n: "Synnex Corporation", c: "US" },
  { id: 2093, s: "EAST", n: "Eastside Distilling Inc", c: "US" },
  { id: 2094, s: "GXO", n: "GXO Logistics Inc", c: "US" },
  { id: 2095, s: "GRCL", n: "Gracell Biotechnologies Inc.", c: "US" },
  { id: 2096, s: "PPC", n: "Pilgrims Pride Co", c: "US" },
  { id: 2097, s: "JLL", n: "Jones Lang LaSalle Incorporated", c: "US" },
  { id: 2098, s: "TDY", n: "Teledyne Technologies Incorporated", c: "US" },
  { id: 2099, s: "VEOEY", n: "Veolia Environnement SA ADR", c: "US" },
  { id: 2100, s: "WASH", n: "Washington Trust Bancorp Inc", c: "US" },
  { id: 2101, s: "FLS", n: "Flowserve Corporation", c: "US" },
  { id: 2102, s: "RCKT", n: "Rocket Pharmaceuticals Inc", c: "US" },
  { id: 2103, s: "OI", n: "O-I Glass Inc", c: "US" },
  { id: 2104, s: "SLQT", n: "Selectquote Inc", c: "US" },
  { id: 2105, s: "LOGI", n: "Logitech International SA", c: "US" },
  { id: 2106, s: "WLY", n: "John Wiley & Sons", c: "US" },
  { id: 2107, s: "CNM", n: "Core & Main Inc", c: "US" },
  { id: 2108, s: "TASK", n: "Taskus Inc", c: "US" },
  { id: 2109, s: "RGEN", n: "Repligen Corporation", c: "US" },
  { id: 2110, s: "RDHL", n: "Redhill Biopharma", c: "US" },
  { id: 2111, s: "TNYA", n: "Tenaya Therapeutics Inc", c: "US" },
  { id: 2112, s: "HLAG.DE", n: "Hapag Lloyd AG", c: "GER" },
  { id: 2113, s: "VCSA", n: "Vacasa Inc", c: "US" },
  { id: 2114, s: "AMTD", n: "AMTD IDEA Group", c: "US" },
  { id: 2115, s: "RILY", n: "B. Riley Financial Inc", c: "US" },
  { id: 2116, s: "MNSO", n: "Miniso Group Holding Ltd", c: "US" },
  { id: 2117, s: "ASC.L", n: "ASOS Plc", c: "UK" },
  { id: 2118, s: "ONTX", n: "Onconova Therapeutics Inc", c: "US" },
  { id: 2119, s: "FTI", n: "TechnipFMC PLC", c: "US" },
  { id: 2120, s: "WHD", n: "Cactus Inc", c: "US" },
  { id: 2121, s: "YJ", n: "Yunji Inc", c: "US" },
  { id: 2122, s: "CRWS", n: "Crown Crafts Inc", c: "US" },
  { id: 2123, s: "WFG", n: "West Fraser Timber", c: "US" },
  { id: 2124, s: "CLRO", n: "ClearOne Inc", c: "US" },
  { id: 2125, s: "EQH", n: "Axa Equitable Holdings Inc", c: "US" },
  { id: 2126, s: "CRUS", n: "Cirrus Logic Inc", c: "US" },
  { id: 2127, s: "OTTR", n: "Otter Tail Corporation", c: "US" },
  { id: 2128, s: "VIRX", n: "Viracta Therapeutics Inc", c: "US" },
  { id: 2129, s: "GASS", n: "StealthGas Inc", c: "US" },
  { id: 2130, s: "WLK", n: "Westlake Chemical Corporation", c: "US" },
  { id: 2131, s: "ANGO", n: "AngioDynamics Inc", c: "US" },
  { id: 2132, s: "FFWM", n: "First Foundation Inc.", c: "US" },
  { id: 2133, s: "AIV", n: "Apartment Investment and Management", c: "US" },
  { id: 2134, s: "UROY", n: "Uranium Royalty Co", c: "US" },
  { id: 2135, s: "NX", n: "Quanex Building Products", c: "US" },
  { id: 2136, s: "RR.L", n: "Rolls-Royce Holdings PLC", c: "UK" },
  { id: 2137, s: "HEP", n: "Holly Energy Partners LP", c: "US" },
  { id: 2138, s: "ALLR", n: "Allarity Therapeutics Inc", c: "US" },
  { id: 2139, s: "TECH", n: "Bio-Techne Co", c: "US" },
  { id: 2140, s: "GOVX", n: "GeoVax Labs Inc", c: "US" },
  { id: 2141, s: "GTX", n: "Garrett Motion Inc", c: "US" },
  { id: 2142, s: "GDDY", n: "Godaddy Inc", c: "US" },
  { id: 2143, s: "EMBRAC-B.ST", n: "Embracer Group AB (publ)", c: "Sweden" },
  { id: 2144, s: "ITI", n: "Iteris Inc", c: "US" },
  { id: 2145, s: "ARAV", n: "Aravive Inc", c: "US" },
  { id: 2146, s: "WCN", n: "Waste Connections Inc", c: "US" },
  { id: 2147, s: "BGNE", n: "BeiGene", c: "US" },
  { id: 2148, s: "WST", n: "West Pharmaceutical Services Inc", c: "US" },
  { id: 2149, s: "FRGE", n: "Forge Global Holdings Inc", c: "US" },
  { id: 2150, s: "TNL", n: "Travel + Leisure", c: "US" },
  { id: 2151, s: "AX", n: "Axos Financial Inc", c: "US" },
  { id: 2152, s: "CUBI", n: "Customers Bancorp Inc", c: "US" },
  { id: 2153, s: "COUR", n: "Coursera Inc", c: "US" },
  { id: 2154, s: "CUK", n: "Carnival Plc ADS", c: "US" },
  { id: 2155, s: "TRHC", n: "Tabula Rasa HealthCare Inc", c: "US" },
  { id: 2156, s: "ARBE", n: "Arbe Robotics", c: "US" },
  { id: 2157, s: "LZB", n: "La-Z-Boy Incorporated", c: "US" },
  { id: 2158, s: "PACK", n: "Ranpak Holdings Co", c: "US" },
  { id: 2159, s: "USFD", n: "US Foods Holding Co", c: "US" },
  { id: 2160, s: "RBA", n: "RB Global Inc.", c: "US" },
  { id: 2161, s: "BC", n: "Brunswick Corporation", c: "US" },
  { id: 2162, s: "GOLF", n: "Acushnet Holdings Co", c: "US" },
  { id: 2163, s: "AIZ", n: "Assurant Inc", c: "US" },
  { id: 2164, s: "MUR", n: "Murphy Oil Corporation", c: "US" },
  { id: 2165, s: "AML.L", n: "Aston Martin Lagonda Global Holdings PLC", c: "UK" },
  { id: 2166, s: "VBIV", n: "VBI Vaccines Inc", c: "US" },
  { id: 2167, s: "SEEL", n: "Seelos Therapeutics Inc", c: "US" },
  { id: 2168, s: "SMFG", n: "Sumitomo Mitsui Financial Group Inc", c: "US" },
  { id: 2169, s: "PATK", n: "Patrick Industries Inc", c: "US" },
  { id: 2170, s: "ADIL", n: "Adial Pharmaceuticals Inc", c: "US" },
  { id: 2171, s: "MRVI", n: "Maravai Lifesciences Holdings Inc", c: "US" },
  { id: 2172, s: "CMS", n: "CMS Energy Corporation", c: "US" },
  { id: 2173, s: "PEY.TO", n: "Peyto Exploration&Development Co", c: "CA" },
  { id: 2174, s: "BRSP", n: "Brightspire Capital Inc", c: "US" },
  { id: 2175, s: "SLVM", n: "Sylvamo Co", c: "US" },
  { id: 2176, s: "SRG", n: "Seritage Growth Properties", c: "US" },
  { id: 2177, s: "SNGX", n: "Soligenix Inc", c: "US" },
  { id: 2178, s: "OCCI", n: "OFS Credit Company Inc", c: "US" },
  { id: 2179, s: "NWN", n: "Northwest Natural Gas", c: "US" },
  { id: 2180, s: "GDRX", n: "Goodrx Holdings Inc", c: "US" },
  { id: 2181, s: "GGT", n: "Gabelli MultiMedia Mutual Fund", c: "US" },
  { id: 2182, s: "VIV", n: "Telefonica Brasil SA ADR", c: "US" },
  { id: 2183, s: "FOXA", n: "Fox Corp Class A", c: "US" },
  { id: 2184, s: "ALGT", n: "Allegiant Travel Company", c: "US" },
  { id: 2185, s: "REE", n: "Ree Automotive Holding Inc", c: "US" },
  { id: 2186, s: "SB", n: "Safe Bulkers Inc", c: "US" },
  { id: 2187, s: "KNOP", n: "KNOT Offshore Partners LP", c: "US" },
  { id: 2188, s: "SYRS", n: "Syros Pharmaceuticals Inc", c: "US" },
  { id: 2189, s: "GROY", n: "Gold Royalty Co", c: "US" },
  { id: 2190, s: "IRTC", n: "iRhythm Technologies Inc", c: "US" },
  { id: 2191, s: "PNFP", n: "Pinnacle Financial Partners Inc", c: "US" },
  { id: 2192, s: "HGBL", n: "Heritage Global Inc", c: "US" },
  { id: 2193, s: "CSTL", n: "Castle Biosciences Inc", c: "US" },
  { id: 2194, s: "PHAT", n: "Phathom Pharmaceuticals Inc", c: "US" },
  { id: 2195, s: "UDR", n: "UDR Inc", c: "US" },
  { id: 2196, s: "CURO", n: "Curo Group Holdings Co", c: "US" },
  { id: 2197, s: "QFIN", n: "360 Finance Inc", c: "US" },
  { id: 2198, s: "MKFG", n: "Markforged Holding Co", c: "US" },
  { id: 2199, s: "VIRI", n: "Virios Therapeutics Llc", c: "US" },
  { id: 2200, s: "DANSKE.", n: "Danske Bank A/S", c: "Denmark" },
  { id: 2201, s: "IHG", n: "InterContinental Hotels Group PLC ADR", c: "US" },
  { id: 2202, s: "AI.PA", n: "Air Liquide SA", c: "FR" },
  { id: 2203, s: "IHRT", n: "iHeartMedia Inc Class A", c: "US" },
  { id: 2204, s: "ABM", n: "ABM Industries Incorporated", c: "US" },
  { id: 2205, s: "PPSI", n: "Pioneer Power Solutions Inc.", c: "US" },
  { id: 2206, s: "DTIL", n: "Precision BioSciences Inc", c: "US" },
  { id: 2207, s: "HE", n: "Hawaiian Electric Industries Inc", c: "US" },
  { id: 2208, s: "CODI", n: "Compass Diversified Holdings", c: "US" },
  { id: 2209, s: "AUDC", n: "AudioCodes", c: "US" },
  { id: 2210, s: "PFGC", n: "Performance Food Group", c: "US" },
  { id: 2211, s: "L.TO", n: "Loblaw Companies Limited", c: "CA" },
  { id: 2212, s: "TCRT", n: "Alaunos Therapeutics Inc", c: "US" },
  { id: 2213, s: "BKCC", n: "BlackRock Kelso Capital Corporation", c: "US" },
  { id: 2214, s: "RECO.V", n: "Reconnaissance Energy Africa", c: "CA" },
  { id: 2215, s: "ARCB", n: "ArcBest Co", c: "US" },
  { id: 2216, s: "DTM", n: "DT Midstream Inc", c: "US" },
  { id: 2217, s: "AL", n: "Air Lease Corporation", c: "US" },
  { id: 2218, s: "NVEI.TO", n: "Nuvei Co", c: "CA" },
  { id: 2219, s: "INGR", n: "Ingredion Incorporated", c: "US" },
  { id: 2220, s: "YELP", n: "Yelp Inc", c: "US" },
  { id: 2221, s: "SUI", n: "Sun Communities Inc", c: "US" },
  { id: 2222, s: "FINV", n: "FinVolution Group", c: "US" },
  { id: 2223, s: "SPWH", n: "Sportsmans", c: "US" },
  { id: 2224, s: "MFC.TO", n: "Manulife Financial Co", c: "CA" },
  { id: 2225, s: "GLYC", n: "GlycoMimetics Inc", c: "US" },
  { id: 2226, s: "ETD", n: "Ethan Allen Interiors Inc", c: "US" },
  { id: 2227, s: "WIMI", n: "WiMi Hologram Cloud Inc", c: "US" },
  { id: 2228, s: "MVO", n: "MV Oil Trust", c: "US" },
  { id: 2229, s: "WOR", n: "Worthington Industries Inc", c: "US" },
  { id: 2230, s: "G", n: "Genesco Inc", c: "US" },
  { id: 2231, s: "KRTX", n: "Karuna Therapeutics Inc", c: "US" },
  { id: 2232, s: "WMK", n: "Weis Markets Inc", c: "US" },
  { id: 2233, s: "SON", n: "Sonoco Products Company", c: "US" },
  { id: 2234, s: "SMFL", n: "Smart for Life Inc", c: "US" },
  { id: 2235, s: "DRD", n: "DRDGOLD ADR", c: "US" },
  { id: 2236, s: "EBF", n: "Ennis Inc", c: "US" },
  { id: 2237, s: "KREF", n: "KKR Real Estate Finance Trust Inc", c: "US" },
  { id: 2238, s: "BRKR", n: "Bruker Corporation", c: "US" },
  { id: 2239, s: "EADSY", n: "Airbus Group NV", c: "US" },
  { id: 2240, s: "TYL", n: "Tyler Technologies Inc", c: "US" },
  { id: 2241, s: "GILT", n: "Gilat Satellite Networks", c: "US" },
  { id: 2242, s: "RELX", n: "Relx PLC ADR", c: "US" },
  { id: 2243, s: "BERY", n: "Berry Global Group Inc", c: "US" },
  { id: 2244, s: "GIVN.SW", n: "Givaudan SA", c: "Switzerland" },
  { id: 2245, s: "TEVA.TA", n: "Teva Pharmaceutical Industries", c: "Israel" },
  { id: 2246, s: "DO", n: "Diamond Offshore Drilling Inc", c: "US" },
  { id: 2247, s: "MOWI.OL", n: "Mowi ASA", c: "Norway" },
  { id: 2248, s: "BHF", n: "Brighthouse Financial Inc", c: "US" },
  { id: 2249, s: "AAT", n: "American Assets Trust Inc", c: "US" },
  { id: 2250, s: "NATI", n: "National Instruments Corporation", c: "US" },
  { id: 2251, s: "STGW", n: "Stagwell Inc", c: "US" },
  { id: 2252, s: "AVPT", n: "Avepoint Inc", c: "US" },
  { id: 2253, s: "DPLS", n: "Darkpulse Inc", c: "US" },
  { id: 2254, s: "RLI", n: "RLI Co", c: "US" },
  { id: 2255, s: "HMY", n: "Harmony Gold Mining Company Limited", c: "US" },
  { id: 2256, s: "USAC", n: "USA Compression Partners LP", c: "US" },
  { id: 2257, s: "TRVG", n: "Trivago NV", c: "US" },
  { id: 2258, s: "LGEN.L", n: "Legal & General Group PLC", c: "UK" },
  { id: 2259, s: "CFR", n: "Cullen/Frost Bankers Inc", c: "US" },
  { id: 2260, s: "WNC", n: "Wabash National Corporation", c: "US" },
  { id: 2261, s: "MRSN", n: "Mersana Therapeutics Inc", c: "US" },
  { id: 2262, s: "VNT", n: "Vontier Co", c: "US" },
  { id: 2263, s: "BOC", n: "Boston Omaha Co", c: "US" },
  { id: 2264, s: "MSGE", n: "Madison Square Garden Entertainment Co", c: "US" },
  { id: 2265, s: "BKD", n: "Brookdale Senior Living Inc", c: "US" },
  { id: 2266, s: "KULR", n: "KULR Technology Group Inc", c: "US" },
  { id: 2267, s: "SKIN", n: "Beauty Health", c: "US" },
  { id: 2268, s: "BECN", n: "Beacon Roofing Supply Inc", c: "US" },
  { id: 2269, s: "SLF", n: "Sun Life Financial Inc.", c: "US" },
  { id: 2270, s: "RCI-B.TO", n: "Rogers Communications Inc", c: "CA" },
  { id: 2271, s: "TTEK", n: "Tetra Tech Inc", c: "US" },
  { id: 2272, s: "PTEN", n: "Patterson-UTI Energy Inc", c: "US" },
  { id: 2273, s: "NVST", n: "Envista Holdings Co", c: "US" },
  { id: 2274, s: "PMT", n: "PennyMac Mortgage Investment Trust", c: "US" },
  { id: 2275, s: "PIRS", n: "Pieris Pharmaceuticals Inc", c: "US" },
  { id: 2276, s: "CABA", n: "Cabaletta Bio Inc", c: "US" },
  { id: 2277, s: "AVTR", n: "Avantor Inc", c: "US" },
  { id: 2278, s: "PSTL", n: "Postal Realty Trust Inc", c: "US" },
  { id: 2279, s: "GSY.TO", n: "goeasy", c: "CA" },
  { id: 2280, s: "VBLT", n: "Vascular Biogenics", c: "US" },
  { id: 2281, s: "PFC", n: "Premier Financial Co", c: "US" },
  { id: 2282, s: "GIII", n: "G-III Apparel Group", c: "US" },
  { id: 2283, s: "UHS", n: "Universal Health Services Inc", c: "US" },
  { id: 2284, s: "PRIM", n: "Primoris Services Corporation", c: "US" },
  { id: 2285, s: "MTW", n: "Manitowoc Company Inc", c: "US" },
  { id: 2286, s: "XPEL", n: "Xpel Inc", c: "US" },
  { id: 2287, s: "TEQ.ST", n: "Teqnion AB", c: "Sweden" },
  { id: 2288, s: "ITT", n: "ITT Inc", c: "US" },
  { id: 2289, s: "VPLAY-B.ST", n: "Viaplay Group AB Series B", c: "Sweden" },
  { id: 2290, s: "AWR", n: "American States Water Company", c: "US" },
  { id: 2291, s: "TV", n: "Grupo Televisa SAB ADR", c: "US" },
  { id: 2292, s: "WWW", n: "Wolverine World Wide Inc", c: "US" },
  { id: 2293, s: "INUV", n: "Inuvo Inc", c: "US" },
  { id: 2294, s: "TSLA.NE", n: "Tesla Inc CDR", c: "CA" },
  { id: 2295, s: "ASTI", n: "Ascent Solar Technologies Inc.", c: "US" },
  { id: 2296, s: "TRTX", n: "TPG RE Finance Trust Inc", c: "US" },
  { id: 2297, s: "YNDX", n: "Yandex NV", c: "US" },
  { id: 2298, s: "OR", n: "Osisko Gold Ro", c: "US" },
  { id: 2299, s: "SOBR", n: "Sobr Safe Inc", c: "US" },
  { id: 2300, s: "UBX", n: "Unity Biotechnology Inc", c: "US" },
  { id: 2301, s: "SPT", n: "Sprout Social Inc", c: "US" },
  { id: 2302, s: "MIRO", n: "Miromatrix Medical Inc", c: "US" },
  { id: 2303, s: "DERM", n: "Journey Medical Co", c: "US" },
  { id: 2304, s: "DAN", n: "Dana Inc", c: "US" },
  { id: 2305, s: "KEN", n: "Kenon Holdings", c: "US" },
  { id: 2306, s: "BFH", n: "Bread Financial Holdings Inc", c: "US" },
  { id: 2307, s: "MESA", n: "Mesa Air Group Inc", c: "US" },
  { id: 2308, s: "CTMX", n: "CytomX Therapeutics Inc", c: "US" },
  { id: 2309, s: "BSTZ", n: "BlackRock Science and Technology Trust II", c: "US" },
  { id: 2310, s: "ARMK", n: "Aramark Holdings", c: "US" },
  { id: 2311, s: "NSANY", n: "Nissan Motor Co ADR", c: "US" },
  { id: 2312, s: "APAM", n: "Artisan Partners Asset Management Inc", c: "US" },
  { id: 2313, s: "ASH", n: "Ashland Global Holdings Inc", c: "US" },
  { id: 2314, s: "BDC", n: "Belden Inc", c: "US" },
  { id: 2315, s: "IAC", n: "IAC Inc.", c: "US" },
  { id: 2316, s: "HYFM", n: "Hydrofarm Holdings Group Inc", c: "US" },
  { id: 2317, s: "NMFC", n: "New Mountain Finance Corporation", c: "US" },
  { id: 2318, s: "TEP.PA", n: "Teleperformance SE", c: "FR" },
  { id: 2319, s: "JBSS", n: "John B Sanfilippo & Son Inc", c: "US" },
  { id: 2320, s: "ESLT", n: "Elbit Systems", c: "US" },
  { id: 2321, s: "CTO", n: "CTO Realty Growth Inc", c: "US" },
  { id: 2322, s: "PSN.L", n: "Persimmon PLC", c: "UK" },
  { id: 2323, s: "BRFS", n: "BRF SA ADR", c: "US" },
  { id: 2324, s: "ZYNE", n: "Zynerba Pharmaceuticals Inc", c: "US" },
  { id: 2325, s: "UCTT", n: "Ultra Clean Holdings Inc", c: "US" },
  { id: 2326, s: "FARO", n: "FARO Technologies Inc", c: "US" },
  { id: 2327, s: "ALXO", n: "Alx Oncology Holdings ", c: "US" },
  { id: 2328, s: "CAPL", n: "Crossamerica Partners LP", c: "US" },
  { id: 2329, s: "FOXF", n: "Fox Factory Holding Co", c: "US" },
  { id: 2330, s: "LEA", n: "Lear Corporation", c: "US" },
  { id: 2331, s: "VCEL", n: "Vericel Corp Ord", c: "US" },
  { id: 2332, s: "RVNC", n: "Revance The", c: "US" },
  { id: 2333, s: "UHT", n: "Universal Health Realty Income Trust", c: "US" },
  { id: 2334, s: "MCFT", n: "MCBC Holdings Inc", c: "US" },
  { id: 2335, s: "NYT", n: "New York Times Company", c: "US" },
  { id: 2336, s: "TKR", n: "Timken Company", c: "US" },
  { id: 2337, s: "BLIN", n: "Bridgeline Digital Inc", c: "US" },
  { id: 2338, s: "LFUS", n: "Littelfuse Inc", c: "US" },
  { id: 2339, s: "BWEN", n: "Broadwind Energy Inc", c: "US" },
  { id: 2340, s: "BZUN", n: "Baozun Inc", c: "US" },
  { id: 2341, s: "EARN", n: "Ellington Residential Mortgage", c: "US" },
  { id: 2342, s: "HNI", n: "HNI Co", c: "US" },
  { id: 2343, s: "FIZZ", n: "National Beverage Co", c: "US" },
  { id: 2344, s: "DIOD", n: "Diodes Incorporated", c: "US" },
  { id: 2345, s: "CRC", n: "California Resources Co", c: "US" },
  { id: 2346, s: "SKY", n: "Skyline Corporation", c: "US" },
  { id: 2347, s: "CXW", n: "CoreCivic Inc", c: "US" },
  { id: 2348, s: "PCH", n: "PotlatchDeltic Co", c: "US" },
  { id: 2349, s: "TSVT", n: "2Seventy Bio Inc", c: "US" },
  { id: 2350, s: "NEL.OL", n: "Nel ASA", c: "Norway" },
  { id: 2351, s: "FDUS", n: "Fidus Investment Co", c: "US" },
  { id: 2352, s: "BGXX", n: "Bright Green Co", c: "US" },
  { id: 2353, s: "USDP", n: "USD Partners LP", c: "US" },
  { id: 2354, s: "WTER", n: "Alkaline Water Company Inc", c: "US" },
  { id: 2355, s: "HOPE", n: "Hope Bancorp Inc", c: "US" },
  { id: 2356, s: "PLPC", n: "Preformed Line Products Company", c: "US" },
  { id: 2357, s: "TPG", n: "TPG Inc", c: "US" },
  { id: 2358, s: "ATOM", n: "Atomera Inc", c: "US" },
  { id: 2359, s: "TAIT", n: "Taitron Components Incorporated", c: "US" },
  { id: 2360, s: "RBOT", n: "Vicarious Surgical Inc.", c: "US" },
  { id: 2361, s: "NHI", n: "National Health Investors Inc", c: "US" },
  { id: 2362, s: "BL", n: "Bausch + Lomb Co", c: "US" },
  { id: 2363, s: "DENN", n: "Denny’s Co", c: "US" },
  { id: 2364, s: "INFI", n: "Infinity Pharmaceuticals Inc", c: "US" },
  { id: 2365, s: "HZO", n: "MarineMax Inc", c: "US" },
  { id: 2366, s: "HPK", n: "Highpeak Energy Acquisition Co", c: "US" },
  { id: 2367, s: "AMRX", n: "Amneal Pharmaceuticals Inc Class A", c: "US" },
  { id: 2368, s: "VZIO", n: "Vizio Holding Co", c: "US" },
  { id: 2369, s: "CTS.TO", n: "Converge Technology Solutions Co", c: "CA" },
  { id: 2370, s: "MAXD", n: "Max Sound Co", c: "US" },
  { id: 2371, s: "ESEA", n: "Euroseas", c: "US" },
  { id: 2372, s: "PGNY", n: "Progyny Inc", c: "US" },
  { id: 2373, s: "SA", n: "Seabridge Gold Inc.", c: "US" },
  { id: 2374, s: "TPX", n: "Tempur Sealy International Inc", c: "US" },
  { id: 2375, s: "INPX", n: "Inpixon", c: "US" },
  { id: 2376, s: "MDXG", n: "MiMedx Group Inc", c: "US" },
  { id: 2377, s: "TALO", n: "Talos Energy", c: "US" },
  { id: 2378, s: "TIGR", n: "Up Fintech Holding", c: "US" },
  { id: 2379, s: "LOCL", n: "Local Bounti Co", c: "US" },
  { id: 2380, s: "CURI", n: "Curiositystream Inc.", c: "US" },
  { id: 2381, s: "RCI", n: "Rogers Communications Inc", c: "US" },
  { id: 2382, s: "RPM", n: "RPM International Inc", c: "US" },
  { id: 2383, s: "HSIC", n: "Henry Schein Inc", c: "US" },
  { id: 2384, s: "AT1.DE", n: "Aroundtown SA", c: "GER" },
  { id: 2385, s: "PIII", n: "P3 Health Partners Inc", c: "US" },
  { id: 2386, s: "CBIO", n: "Catalyst Biosciences Inc", c: "US" },
  { id: 2387, s: "ALC", n: "Alcon AG", c: "US" },
  { id: 2388, s: "SHEL.L", n: "Shell plc", c: "UK" },
  { id: 2389, s: "PCTY", n: "Paylocity Holdng", c: "US" },
  { id: 2390, s: "RMNI", n: "Rimini Street Inc", c: "US" },
  { id: 2391, s: "FHB", n: "First Hawaiian Inc", c: "US" },
  { id: 2392, s: "INAB", n: "In8bio Inc", c: "US" },
  { id: 2393, s: "POL", n: "Polished.com Inc.", c: "US" },
  { id: 2394, s: "GNE", n: "Genie Energy", c: "US" },
  { id: 2395, s: "CCEP", n: "Coca-Cola European Partners PLC", c: "US" },
  { id: 2396, s: "SBSI", n: "Southside Bancshares Inc", c: "US" },
  { id: 2397, s: "TRI", n: "Thomson Reuters Co", c: "US" },
  { id: 2398, s: "RCUS", n: "Arcus Biosciences Inc", c: "US" },
  { id: 2399, s: "CRDA.L", n: "Croda International PLC", c: "UK" },
  { id: 2400, s: "CIG-C", n: "Energy of Minas Gerais Co DRC", c: "US" },
  { id: 2401, s: "AGLE", n: "Aeglea Bio Therapeutics Inc", c: "US" },
  { id: 2402, s: "IPW", n: "iPower Inc", c: "US" },
  { id: 2403, s: "NN.AS", n: "NN Group NV", c: "Netherlands" },
  { id: 2404, s: "COLB", n: "Columbia Banking System Inc", c: "US" },
  { id: 2405, s: "SRC", n: "Spirit Realty Capital Inc", c: "US" },
  { id: 2406, s: "ACON", n: "Aclarion Inc", c: "US" },
  { id: 2407, s: "TELIA.ST", n: "Telia Company AB", c: "Sweden" },
  { id: 2408, s: "SPXC", n: "SPX Co", c: "US" },
  { id: 2409, s: "PALI", n: "Palisade Bio Inc", c: "US" },
  { id: 2410, s: "ABBN.SW", n: "ABB", c: "Switzerland" },
  { id: 2411, s: "HT", n: "Hersha Hospitality Trust", c: "US" },
  { id: 2412, s: "NUTX", n: "Nutex Health Inc", c: "US" },
  { id: 2413, s: "WD", n: "Walker & Dunlop Inc", c: "US" },
  { id: 2414, s: "ARLO", n: "Arlo Technologies", c: "US" },
  { id: 2415, s: "BN.PA", n: "Danone SA", c: "FR" },
  { id: 2416, s: "HRI", n: "Herc Holdings Inc", c: "US" },
  { id: 2417, s: "GWRE", n: "Guidewire Software Inc", c: "US" },
  { id: 2418, s: "FRPT", n: "Freshpet Inc", c: "US" },
  { id: 2419, s: "ITRI", n: "Itron Inc", c: "US" },
  { id: 2420, s: "MCB", n: "Metropolitan Bank Holding", c: "US" },
  { id: 2421, s: "ORA", n: "Ormat Technologies Inc", c: "US" },
  { id: 2422, s: "TITN", n: "Titan Machinery Inc", c: "US" },
  { id: 2423, s: "ERIE", n: "Erie Indemnity Company", c: "US" },
  { id: 2424, s: "ORAN", n: "Orange SA ADR", c: "US" },
  { id: 2425, s: "FWONK", n: "Liberty Media Corporation Series C Liberty Formula One Common Stock", c: "US" },
  { id: 2426, s: "AVA", n: "Avista Corporation", c: "US" },
  { id: 2427, s: "CSV", n: "Carriage Services Inc", c: "US" },
  { id: 2428, s: "AXL", n: "American Axle & Manufacturing", c: "US" },
  { id: 2429, s: "GDYN", n: "Grid Dynamics Holdings Inc", c: "US" },
  { id: 2430, s: "ZNTL", n: "Zentalis Pharmaceuticals Llc", c: "US" },
  { id: 2431, s: "IE", n: "Ivanhoe Electric Inc.", c: "US" },
  { id: 2432, s: "NBIX", n: "Neurocrine Biosciences Inc", c: "US" },
  { id: 2433, s: "ADYEY", n: "Adyen NV", c: "US" },
  { id: 2434, s: "ALFEN.AS", n: "Alfen Beheer BV", c: "Netherlands" },
  { id: 2435, s: "ALLK", n: "Allakos Inc", c: "US" },
  { id: 2436, s: "PSNL", n: "Personalis Inc", c: "US" },
  { id: 2437, s: "AGR", n: "Avangrid Inc", c: "US" },
  { id: 2438, s: "ALRM", n: "Alarm.com Holdings Inc", c: "US" },
  { id: 2439, s: "PTN", n: "Palatin Technologies Inc", c: "US" },
  { id: 2440, s: "MTG", n: "MGIC Investment Co", c: "US" },
  { id: 2441, s: "FNB", n: "F.N.B. Co", c: "US" },
  { id: 2442, s: "MASS", n: "908 Devices Inc", c: "US" },
  { id: 2443, s: "EMBC", n: "Embecta Co", c: "US" },
  { id: 2444, s: "SLCA", n: "US Silica Holdings Inc", c: "US" },
  { id: 2445, s: "SFT", n: "Shift Technologies Inc.", c: "US" },
  { id: 2446, s: "ENSG", n: "The Ensign Group Inc", c: "US" },
  { id: 2447, s: "ASM", n: "Avino Silver & Gold Mines", c: "US" },
  { id: 2448, s: "WCP.TO", n: "Whitecap Resources Inc.", c: "CA" },
  { id: 2449, s: "CIB", n: "Bancolombia SA ADR", c: "US" },
  { id: 2450, s: "LO", n: "El Pollo Loco Holdings Inc", c: "US" },
  { id: 2451, s: "TRZ.TO", n: "Transat AT Inc", c: "CA" },
  { id: 2452, s: "DRTT", n: "Dirtt Environmen", c: "US" },
  { id: 2453, s: "ATHA", n: "Athira Pharma Inc", c: "US" },
  { id: 2454, s: "VIAV", n: "Viavi Solutions Inc", c: "US" },
  { id: 2455, s: "CWST", n: "Casella Waste Systems Inc", c: "US" },
  { id: 2456, s: "AROC", n: "Archrock Inc", c: "US" },
  { id: 2457, s: "URG", n: "Ur Energy Inc", c: "US" },
  { id: 2458, s: "NAUT", n: "Nautilus Biotechnology Inc", c: "US" },
  { id: 2459, s: "ANDE", n: "The Andersons Inc", c: "US" },
  { id: 2460, s: "LTRX", n: "Lantronix Inc", c: "US" },
  { id: 2461, s: "AVRO", n: "AVROBIO Inc", c: "US" },
  { id: 2462, s: "SYBX", n: "Synlogic Inc", c: "US" },
  { id: 2463, s: "CLSD", n: "Clearside Biomedical Inc", c: "US" },
  { id: 2464, s: "AMWL", n: "American Well Co", c: "US" },
  { id: 2465, s: "RELY", n: "Remitly Global Inc", c: "US" },
  { id: 2466, s: "WLKP", n: "Westlake Chemical Partners LP", c: "US" },
  { id: 2467, s: "CV", n: "Cavco Industries Inc", c: "US" },
  { id: 2468, s: "ASPS", n: "Altisource Portfolio Solutions SA", c: "US" },
  { id: 2469, s: "CYTH", n: "Cyclo Therapeutics Inc", c: "US" },
  { id: 2470, s: "DBK.DE", n: "Deutsche Bank Aktiengesellschaft", c: "GER" },
  { id: 2471, s: "UIS", n: "Unisys Corporation", c: "US" },
  { id: 2472, s: "AMX", n: "America Movil SAB de CV ADR", c: "US" },
  { id: 2473, s: "DY", n: "Dycom Industries Inc", c: "US" },
  { id: 2474, s: "NCNO", n: "nCino Inc", c: "US" },
  { id: 2475, s: "SUM", n: "Summit Materials Inc", c: "US" },
  { id: 2476, s: "SAP.DE", n: "SAP SE", c: "GER" },
  { id: 2477, s: "HVT", n: "Haverty Furniture Companies Inc", c: "US" },
  { id: 2478, s: "RSI", n: "Rush Street Interactive Inc", c: "US" },
  { id: 2479, s: "CYH", n: "Community Health Systems Inc", c: "US" },
  { id: 2480, s: "BFRI", n: "Biofrontera Inc", c: "US" },
  { id: 2481, s: "POW.TO", n: "Power Corporation Of CA", c: "CA" },
  { id: 2482, s: "NOMD", n: "Nomad Foods", c: "US" },
  { id: 2483, s: "FLWS", n: "1-800 FLOWERS.COM Inc", c: "US" },
  { id: 2484, s: "PPL.TO", n: "Pembina Pipeline Co", c: "CA" },
  { id: 2485, s: "FMX", n: "Fomento Economico Mexicano", c: "US" },
  { id: 2486, s: "CNX", n: "CNX Resources Co", c: "US" },
  { id: 2487, s: "PYXS", n: "Pyxis Oncology Inc", c: "US" },
  { id: 2488, s: "ABX.TO", n: "Barrick Gold Co", c: "CA" },
  { id: 2489, s: "ONCR", n: "Oncorus Inc", c: "US" },
  { id: 2490, s: "CACI", n: "CACI International Inc", c: "US" },
  { id: 2491, s: "KORE", n: "KORE Group Holdings Inc", c: "US" },
  { id: 2492, s: "ARC", n: "ARC Document Solutions Inc", c: "US" },
  { id: 2493, s: "TLS", n: "Telos Co", c: "US" },
  { id: 2494, s: "MC", n: "Moelis &", c: "US" },
  { id: 2495, s: "EMKR", n: "EMCORE Corporation", c: "US" },
  { id: 2496, s: "INZY", n: "Inozyme Pharma Inc", c: "US" },
  { id: 2497, s: "LITH.V", n: "Lithium Chile Inc", c: "CA" },
  { id: 2498, s: "UNTC", n: "Unit Co", c: "US" },
  { id: 2499, s: "XRAY", n: "Dentsply Sirona Inc", c: "US" },
  { id: 2500, s: "BL", n: "Blackline Inc", c: "US" },
  { id: 2501, s: "BCH", n: "Banco De Chile", c: "US" },
  { id: 2502, s: "AVIR", n: "Atea Pharmaceuticals Inc", c: "US" },
  { id: 2503, s: "DRVN", n: "Driven Brands Holdings Inc", c: "US" },
  { id: 2504, s: "UAVS", n: "Ageagle Aerial Systems Inc", c: "US" },
  { id: 2505, s: "TRNO", n: "Terreno Realty Corporation", c: "US" },
  { id: 2506, s: "PTVE", n: "Pactiv Evergreen Inc", c: "US" },
  { id: 2507, s: "BLD", n: "Topbuild Co", c: "US" },
  { id: 2508, s: "CVGW", n: "Calavo Growers Inc", c: "US" },
  { id: 2509, s: "CHE", n: "Chemed Co", c: "US" },
  { id: 2510, s: "SQSP", n: "Squarespace Inc", c: "US" },
  { id: 2511, s: "TELA", n: "Tela Bio Inc", c: "US" },
  { id: 2512, s: "CATO", n: "Cato Corporation", c: "US" },
  { id: 2513, s: "GEF", n: "Greif Bros Corporation", c: "US" },
  { id: 2514, s: "HOV", n: "Hovnanian Enterprises Inc", c: "US" },
  { id: 2515, s: "DOMO", n: "Domo Inc", c: "US" },
  { id: 2516, s: "TFX", n: "Teleflex Incorporated", c: "US" },
  { id: 2517, s: "BRBR", n: "Bellring Brands LLC", c: "US" },
  { id: 2518, s: "FRSX", n: "Foresight Autonomous Holdings ADR", c: "US" },
  { id: 2519, s: "ALKS", n: "Alkermes Plc", c: "US" },
  { id: 2520, s: "CISO", n: "Cerberus Cyber Sentinel Co", c: "US" },
  { id: 2521, s: "CMLS", n: "Cumulus Media Inc Class A", c: "US" },
  { id: 2522, s: "CD", n: "Chindata Group Holdings", c: "US" },
  { id: 2523, s: "CDMO", n: "Avid Bioservices Inc", c: "US" },
  { id: 2524, s: "SSUMY", n: "Sumitomo Corp ADR", c: "US" },
  { id: 2525, s: "CNDT", n: "Conduent Inc", c: "US" },
  { id: 2526, s: "FMG.AX", n: "Fortescue Metals Group", c: "Australia" },
  { id: 2527, s: "DLX", n: "Deluxe Corporation", c: "US" },
  { id: 2528, s: "BLBD", n: "Blue Bird Co", c: "US" },
  { id: 2529, s: "FTV", n: "Fortive Co", c: "US" },
  { id: 2530, s: "OLO", n: "Olo Inc", c: "US" },
  { id: 2531, s: "SF", n: "Stifel Financial Corporation", c: "US" },
  { id: 2532, s: "SNBR", n: "Sleep Number Co", c: "US" },
  { id: 2533, s: "HSDT", n: "Helius Medical Technologies Inc Class A", c: "US" },
  { id: 2534, s: "YMM", n: "Full Truck Alliance Co ADR", c: "US" },
  { id: 2535, s: "TPC", n: "Tutor Perini Corporation", c: "US" },
  { id: 2536, s: "ADES", n: "Advanced Emissions Solutions Inc", c: "US" },
  { id: 2537, s: "LPCN", n: "Lipocine Inc", c: "US" },
  { id: 2538, s: "ALTR", n: "Altair Engineering Inc", c: "US" },
  { id: 2539, s: "ATIP", n: "ATI Physical Therapy Inc", c: "US" },
  { id: 2540, s: "WH", n: "Wyndham Hotels & Resorts Inc", c: "US" },
  { id: 2541, s: "DXPE", n: "DXP Enterprises Inc", c: "US" },
  { id: 2542, s: "CBT", n: "Cabot Corporation", c: "US" },
  { id: 2543, s: "CAPR", n: "Capricor Therapeutics Inc", c: "US" },
  { id: 2544, s: "AF.PA", n: "Air FR-KLM SA", c: "FR" },
  { id: 2545, s: "SRTS", n: "Sensus Healthcare Inc", c: "US" },
  { id: 2546, s: "CMP", n: "Compass Minerals International Inc", c: "US" },
  { id: 2547, s: "TRN", n: "Trinity Industries Inc", c: "US" },
  { id: 2548, s: "GMED", n: "Globus Medical", c: "US" },
  { id: 2549, s: "SXC", n: "SunCoke Energy Inc", c: "US" },
  { id: 2550, s: "LMB", n: "Limbach Holdings Inc", c: "US" },
  { id: 2551, s: "TWNK", n: "Hostess Brands Inc", c: "US" },
  { id: 2552, s: "IMO", n: "Imperial Oil", c: "US" },
  { id: 2553, s: "RRX", n: "Regal Beloit Corporation", c: "US" },
  { id: 2554, s: "PINC", n: "Premier Inc", c: "US" },
  { id: 2555, s: "SCS", n: "Steelcase Inc", c: "US" },
  { id: 2556, s: "WHF", n: "WhiteHorse Finance", c: "US" },
  { id: 2557, s: "WATT", n: "Energous Corporation", c: "US" },
  { id: 2558, s: "AZPN", n: "Aspen Technology Inc", c: "US" },
  { id: 2559, s: "USM", n: "United States Cellular Corporation", c: "US" },
  { id: 2560, s: "BRLT", n: "Brilliant Earth Group Inc", c: "US" },
  { id: 2561, s: "HLTH", n: "Cue Health Inc", c: "US" },
  { id: 2562, s: "CEVA", n: "CEVA Inc", c: "US" },
  { id: 2563, s: "FTS", n: "Fortis Inc", c: "US" },
  { id: 2564, s: "NRIX", n: "Nurix Therapeutics Inc", c: "US" },
  { id: 2565, s: "VERA", n: "Vera Therapeutics Inc", c: "US" },
  { id: 2566, s: "PRPO", n: "Precipio Inc", c: "US" },
  { id: 2567, s: "GMBL", n: "Esports Entertainment Group Inc", c: "US" },
  { id: 2568, s: "REG", n: "Regency Centers Corporation", c: "US" },
  { id: 2569, s: "ITOS", n: "Iteos Therapeutics Inc", c: "US" },
  { id: 2570, s: "TRX", n: "Tanzanian Royalty Exploration Co", c: "US" },
  { id: 2571, s: "AIR", n: "AAR Co", c: "US" },
  { id: 2572, s: "EFX", n: "Equifax Inc", c: "US" },
  { id: 2573, s: "CCAP", n: "Crescent Capital BDC Inc", c: "US" },
  { id: 2574, s: "ALV", n: "Autoliv Inc", c: "US" },
  { id: 2575, s: "LESL", n: "Leslies Inc", c: "US" },
  { id: 2576, s: "FLT", n: "Fleetcor Technologies Inc", c: "US" },
  { id: 2577, s: "AGRX", n: "Agile Thrpe", c: "US" },
  { id: 2578, s: "BLND", n: "Blend Labs Inc", c: "US" },
  { id: 2579, s: "CVE.TO", n: "Cenovus Energy Inc", c: "CA" },
  { id: 2580, s: "XAIR", n: "Beyond Air Inc", c: "US" },
  { id: 2581, s: "PRTA", n: "Prothena Corporation plc", c: "US" },
  { id: 2582, s: "GSK.L", n: "GlaxoSmithKline PLC", c: "UK" },
  { id: 2583, s: "BV", n: "BrightView Holdings", c: "US" },
  { id: 2584, s: "ENR", n: "Energizer Holdings Inc", c: "US" },
  { id: 2585, s: "PRVA", n: "Privia Health Group Inc", c: "US" },
  { id: 2586, s: "OESX", n: "Orion Energy Systems Inc", c: "US" },
  { id: 2587, s: "LANC", n: "Lancaster Colony Corporation", c: "US" },
  { id: 2588, s: "LBTYA", n: "Liberty Global PLC", c: "US" },
  { id: 2589, s: "EVR", n: "Evercore Partners Inc", c: "US" },
  { id: 2590, s: "NJR", n: "NewJersey Resources Corporation", c: "US" },
  { id: 2591, s: "GFF", n: "Griffon Corporation", c: "US" },
  { id: 2592, s: "PGRE", n: "Paramount Group Inc", c: "US" },
  { id: 2593, s: "NWSA", n: "News Corp A", c: "US" },
  { id: 2594, s: "IMVT", n: "Immunovant Inc", c: "US" },
  { id: 2595, s: "AYRO", n: "AYRO Inc", c: "US" },
  { id: 2596, s: "JOE", n: "St Joe Company", c: "US" },
  { id: 2597, s: "GOSS", n: "Gossamer Bio Inc", c: "US" },
  { id: 2598, s: "CACC", n: "Credit Acceptance Corporation", c: "US" },
  { id: 2599, s: "POET", n: "POET Technologies Inc", c: "US" },
  { id: 2600, s: "CUE", n: "Cue Biopharma", c: "US" },
  { id: 2601, s: "TMBR", n: "Timber Pharmaceuticals Inc", c: "US" },
  { id: 2602, s: "EVER", n: "EverQuote Inc Class A", c: "US" },
  { id: 2603, s: "SPTN", n: "SpartanNash", c: "US" },
  { id: 2604, s: "SILV", n: "SilverCrest Metals Inc", c: "US" },
  { id: 2605, s: "GOGO", n: "Gogo Inc", c: "US" },
  { id: 2606, s: "TSCO.L", n: "Tesco PLC", c: "UK" },
  { id: 2607, s: "NVOS", n: "Novo Integrated Sciences Inc", c: "US" },
  { id: 2608, s: "RWAY", n: "Runway Growth Finance Co", c: "US" },
  { id: 2609, s: "EAF", n: "GrafTech International", c: "US" },
  { id: 2610, s: "ALPP", n: "Alpine 4 Holdings Inc", c: "US" },
  { id: 2611, s: "FUL", n: "H B Fuller Company", c: "US" },
  { id: 2612, s: "MPLN", n: "MultiPlan Corporation", c: "US" },
  { id: 2613, s: "FULT", n: "Fulton Financial Corporation", c: "US" },
  { id: 2614, s: "DSKE", n: "Daseke Inc", c: "US" },
  { id: 2615, s: "TREE", n: "Lendingtree Inc", c: "US" },
  { id: 2616, s: "SWX", n: "Southwest Gas Holdings Inc", c: "US" },
  { id: 2617, s: "KNX", n: "Knight Transportation Inc", c: "US" },
  { id: 2618, s: "WDFC", n: "WD-40 Company", c: "US" },
  { id: 2619, s: "CP.TO", n: "Canadian Pacific Railway", c: "CA" },
  { id: 2620, s: "TWKS", n: "Thoughtworks Holding Inc", c: "US" },
  { id: 2621, s: "ANGI", n: "ANGI Homeservices Inc", c: "US" },
  { id: 2622, s: "LSTR", n: "Landstar System Inc", c: "US" },
  { id: 2623, s: "VIEW", n: "View Inc", c: "US" },
  { id: 2624, s: "SKYW", n: "SkyWest Inc", c: "US" },
  { id: 2625, s: "DCI", n: "Donaldson Company Inc", c: "US" },
  { id: 2626, s: "HI", n: "Hillenbrand Inc", c: "US" },
  { id: 2627, s: "CRS", n: "Carpenter Technology Corporation", c: "US" },
  { id: 2628, s: "2317.TW", n: "Hon Hai Precision Industry", c: "TW" },
  { id: 2629, s: "RES", n: "RPC Inc", c: "US" },
  { id: 2630, s: "HOFT", n: "Hooker Furniture Corporation", c: "US" },
  { id: 2631, s: "MDRX", n: "Allscripts Healthcare Solutions Inc", c: "US" },
  { id: 2632, s: "AMST", n: "Amesite Operating ", c: "US" },
  { id: 2633, s: "ATEN", n: "A10 Network", c: "US" },
  { id: 2634, s: "UGP", n: "Ultrapar Participacoes SA ADR", c: "US" },
  { id: 2635, s: "VAC", n: "Marriot Vacations Worldwide", c: "US" },
  { id: 2636, s: "MTSI", n: "MACOM Technology Solutions Holdings Inc", c: "US" },
  { id: 2637, s: "SPSC", n: "SPS Commerce Inc", c: "US" },
  { id: 2638, s: "SIRC", n: "Solar Integrated Roofing Co", c: "US" },
  { id: 2639, s: "ANIP", n: "ANI Pharmaceuticals Inc", c: "US" },
  { id: 2640, s: "IPGP", n: "IPG Photonics Corporation", c: "US" },
  { id: 2641, s: "MCRI", n: "Monarch Casino & Resort Inc", c: "US" },
  { id: 2642, s: "ATXG", n: "Addentax Group Co", c: "US" },
  { id: 2643, s: "FOLD", n: "Amicus Therapeutics Inc", c: "US" },
  { id: 2644, s: "LND", n: "Brasilagro Adr", c: "US" },
  { id: 2645, s: "CON.DE", n: "Continental Aktiengesellschaft", c: "GER" },
  { id: 2646, s: "MAPS", n: "WM Technology Inc", c: "US" },
  { id: 2647, s: "XPOF", n: "Xponential Fitness Inc", c: "US" },
  { id: 2648, s: "PRPL", n: "Purple Innovation Inc", c: "US" },
  { id: 2649, s: "IBE.MC", n: "Iberdrola S.A.", c: "Spain" },
  { id: 2650, s: "BRID", n: "Bridgford Foods Corporation", c: "US" },
  { id: 2651, s: "OBLG", n: "Oblong Inc", c: "US" },
  { id: 2652, s: "FNKO", n: "Funko Inc", c: "US" },
  { id: 2653, s: "PRCH", n: "Porch Group Inc", c: "US" },
  { id: 2654, s: "CHUY", n: "Chuy's Holdings Inc", c: "US" },
  { id: 2655, s: "UN01.DE", n: "Uniper SE", c: "GER" },
  { id: 2656, s: "LABP", n: "Landos Biopharma Inc", c: "US" },
  { id: 2657, s: "RIO.L", n: "Rio Tinto PLC", c: "UK" },
  { id: 2658, s: "MFG", n: "Mizuho Financial Group Inc.", c: "US" },
  { id: 2659, s: "TSHA", n: "Taysha Gene Therapies Inc", c: "US" },
  { id: 2660, s: "PED", n: "PEDEVCO Co", c: "US" },
  { id: 2661, s: "CDXS", n: "Codexis Inc", c: "US" },
  { id: 2662, s: "FXLV", n: "F45 Training Holdings Inc", c: "US" },
  { id: 2663, s: "OMIC", n: "Singular Genomics Systems Inc", c: "US" },
  { id: 2664, s: "POAI", n: "Predictive Oncology Inc", c: "US" },
  { id: 2665, s: "ARVN", n: "Arvinas Inc", c: "US" },
  { id: 2666, s: "NAVI", n: "Navient Co", c: "US" },
  { id: 2667, s: "TTI", n: "Tetra Technologies Inc", c: "US" },
  { id: 2668, s: "HQI", n: "Hirequest Inc", c: "US" },
  { id: 2669, s: "GP", n: "GreenPower Motor Company Inc", c: "US" },
  { id: 2670, s: "VWE", n: "Vintage Wine Estates Inc", c: "US" },
  { id: 2671, s: "BOXL", n: "Boxlight Corp Class A", c: "US" },
  { id: 2672, s: "VERX", n: "Vertex", c: "US" },
  { id: 2673, s: "VAR1.DE", n: "Varta AG", c: "GER" },
  { id: 2674, s: "ONEW", n: "Onewater Marine", c: "US" },
  { id: 2675, s: "GCI", n: "Gannett Co Inc", c: "US" },
  { id: 2676, s: "ACB.TO", n: "Aurora Cannabis Inc", c: "CA" },
  { id: 2677, s: "CUTR", n: "Cutera Inc", c: "US" },
  { id: 2678, s: "CEMEXCPO.MX", n: "CEMEX S.A.B. de C.V.", c: "Mexi" },
  { id: 2679, s: "ABG", n: "Asbury Automotive Group Inc", c: "US" },
  { id: 2680, s: "AVDX", n: "AvidXchange Holdings Inc", c: "US" },
  { id: 2681, s: "ERII", n: "Energy Recovery Inc", c: "US" },
  { id: 2682, s: "ALYA", n: "Alithya Group inc Class A", c: "US" },
  { id: 2683, s: "SEAC", n: "SeaChange International Inc", c: "US" },
  { id: 2684, s: "FOSL", n: "Fossil Group Inc", c: "US" },
  { id: 2685, s: "CARG", n: "CarGurus", c: "US" },
  { id: 2686, s: "CAMP", n: "CalAmp Co", c: "US" },
  { id: 2687, s: "ICCM", n: "Icecure Medical", c: "US" },
  { id: 2688, s: "ZVIA", n: "Zevia Pbc", c: "US" },
  { id: 2689, s: "ENLC", n: "EnLink Midstream LLC", c: "US" },
  { id: 2690, s: "GES", n: "Guess? Inc", c: "US" },
  { id: 2691, s: "OII", n: "Oceaneering International Inc", c: "US" },
  { id: 2692, s: "ACHV", n: "Achieve Life Sciences Inc", c: "US" },
  { id: 2693, s: "ERO", n: "Ero Copper Co", c: "US" },
  { id: 2694, s: "VCNX", n: "Vaccinex Inc", c: "US" },
  { id: 2695, s: "EVEX", n: "Eve Holding Inc", c: "US" },
  { id: 2696, s: "TRU", n: "TransUnion", c: "US" },
  { id: 2697, s: "WWD", n: "Woodward Inc", c: "US" },
  { id: 2698, s: "HQY", n: "HealthEquity Inc", c: "US" },
  { id: 2699, s: "EHC", n: "Encompass Health Co", c: "US" },
  { id: 2700, s: "ACA", n: "Arcosa Inc", c: "US" },
  { id: 2701, s: "GPI", n: "Group 1 Automotive Inc", c: "US" },
  { id: 2702, s: "CS.PA", n: "AXA SA", c: "FR" },
  { id: 2703, s: "HTOO", n: "Fusion Fuel Green PLC", c: "US" },
  { id: 2704, s: "BIEL", n: "Bioelectronics Co", c: "US" },
  { id: 2705, s: "LYTS", n: "LSI Industries Inc", c: "US" },
  { id: 2706, s: "MYRG", n: "MYR Group Inc", c: "US" },
  { id: 2707, s: "NMIH", n: "NMI Holdings Inc", c: "US" },
  { id: 2708, s: "COLM", n: "Columbia Sportswear Company", c: "US" },
  { id: 2709, s: "NWE", n: "NorthWestern Corporation", c: "US" },
  { id: 2710, s: "ARAY", n: "Accuray Incorporated", c: "US" },
  { id: 2711, s: "EVC", n: "Entravision Communications", c: "US" },
  { id: 2712, s: "WINT", n: "Windtree Therapeutics Inc", c: "US" },
  { id: 2713, s: "FWONA", n: "Liberty Media Corporation Series A Liberty Formula One Common Stock", c: "US" },
  { id: 2714, s: "AUTL", n: "Autolus Therapeutics", c: "US" },
  { id: 2715, s: "VRA", n: "Vera Bradley Inc", c: "US" },
  { id: 2716, s: "SUZ", n: "Suzano Papel e Celulose SA ADR", c: "US" },
  { id: 2717, s: "EXRO.TO", n: "Exro Technologies Inc", c: "CA" },
  { id: 2718, s: "EVO.ST", n: "Evolution AB (publ)", c: "Sweden" },
  { id: 2719, s: "TKC", n: "Turkcell Iletisim Hizmetleri AS", c: "US" },
  { id: 2720, s: "ALRN", n: "Aileron Therapeutics Inc", c: "US" },
  { id: 2721, s: "VRNS", n: "Varonis Systems", c: "US" },
  { id: 2722, s: "TOPS", n: "Top Ships Inc", c: "US" },
  { id: 2723, s: "ICLR", n: "ICON PLC", c: "US" },
  { id: 2724, s: "SLAB", n: "Silicon Laboratories Inc", c: "US" },
  { id: 2725, s: "WTTR", n: "Select Energy Services Inc", c: "US" },
  { id: 2726, s: "FMS", n: "Fresenius Medical Care Corporation", c: "US" },
  { id: 2727, s: "LQDA", n: "Liquidia Technologies Inc", c: "US" },
  { id: 2728, s: "NMTC", n: "Neuroone Medical Technologies Co", c: "US" },
  { id: 2729, s: "CENX", n: "Century Aluminum Company", c: "US" },
  { id: 2730, s: "HTHIY", n: "Hitachi ADR", c: "US" },
  { id: 2731, s: "SMSI", n: "Smith Micro Software Inc", c: "US" },
  { id: 2732, s: "PESI", n: "Perma-Fix Environmental Svcs Inc", c: "US" },
  { id: 2733, s: "RARE", n: "Ultragenyx", c: "US" },
  { id: 2734, s: "ALE", n: "Allete Inc", c: "US" },
  { id: 2735, s: "SWI", n: "SolarWinds Co", c: "US" },
  { id: 2736, s: "MCI", n: "Barings Corporate Investors", c: "US" },
  { id: 2737, s: "COOP", n: "Mr. Cooper Group Inc", c: "US" },
  { id: 2738, s: "XPER", n: "Xperi Co", c: "US" },
  { id: 2739, s: "HFG.DE", n: "HelloFresh SE", c: "GER" },
  { id: 2740, s: "EIGR", n: "Eiger Biopharmaceuticals Inc", c: "US" },
  { id: 2741, s: "WWR", n: "Westwater Resources Inc", c: "US" },
  { id: 2742, s: "CDNA", n: "Caredx Inc", c: "US" },
  { id: 2743, s: "RSKD", n: "Riskified", c: "US" },
  { id: 2744, s: "ACET", n: "Adicet Bio Inc", c: "US" },
  { id: 2745, s: "AMPL", n: "Amplitude Inc", c: "US" },
  { id: 2746, s: "NMG", n: "Nouveau Monde Graphite Inc", c: "US" },
  { id: 2747, s: "SMRT", n: "SmartRent Inc", c: "US" },
  { id: 2748, s: "FAT", n: "FAT Brands Inc", c: "US" },
  { id: 2749, s: "AEIS", n: "Advanced Energy Industries Inc", c: "US" },
  { id: 2750, s: "FORTUM.HE", n: "Fortum Oyj", c: "Finland" },
  { id: 2751, s: "HSBA.L", n: "HSBC Holdings PLC", c: "UK" },
  { id: 2752, s: "BSBR", n: "Banco Santander Brasil SA ADR", c: "US" },
  { id: 2753, s: "BXC", n: "BlueLinx Holdings Inc", c: "US" },
  { id: 2754, s: "IMMP", n: "Immutep ADR", c: "US" },
  { id: 2755, s: "MGIC", n: "Magic Software Enterprises", c: "US" },
  { id: 2756, s: "CCK", n: "Crown Holdings Inc", c: "US" },
  { id: 2757, s: "NC", n: "NACCO Industries Inc", c: "US" },
  { id: 2758, s: "GTY", n: "Getty Realty Corporation", c: "US" },
  { id: 2759, s: "SMLP", n: "Summit Midstream Partners LP", c: "US" },
  { id: 2760, s: "OIS", n: "Oil States International Inc", c: "US" },
  { id: 2761, s: "GRVY", n: "Gravity", c: "US" },
  { id: 2762, s: "PARR", n: "Par Pacific Holdings Inc", c: "US" },
  { id: 2763, s: "OUT", n: "Outfront Media Inc", c: "US" },
  { id: 2764, s: "PLS.AX", n: "Pilbara Minerals", c: "Australia" },
  { id: 2765, s: "SFTBY", n: "SoftBank Group Co", c: "US" },
  { id: 2766, s: "TARS", n: "Tarsus Pharmaceuticals Inc", c: "US" },
  { id: 2767, s: "LAC.TO", n: "Lithium Americas Co", c: "CA" },
  { id: 2768, s: "RHP", n: "Ryman Hospitality Properties Inc", c: "US" },
  { id: 2769, s: "NUS", n: "Nu Skin Enterprises Inc", c: "US" },
  { id: 2770, s: "TOUR", n: "Tuniu Co", c: "US" },
  { id: 2771, s: "EVRI", n: "Everi Holdings Inc", c: "US" },
  { id: 2772, s: "SES", n: "SES AI Co", c: "US" },
  { id: 2773, s: "POWI", n: "Power Integrations Inc", c: "US" },
  { id: 2774, s: "MSGS", n: "Madison Square Garden Sports Co", c: "US" },
  { id: 2775, s: "MPX", n: "Marine Products Corporation", c: "US" },
  { id: 2776, s: "LDI", n: "Loandepot Inc", c: "US" },
  { id: 2777, s: "FLUX", n: "Flux Power Holdings Inc", c: "US" },
  { id: 2778, s: "SEAS", n: "SeaWorld Entertainment Inc", c: "US" },
  { id: 2779, s: "ATEX", n: "Anterix Inc", c: "US" },
  { id: 2780, s: "PMGM", n: "Priveterra Acquisition Co", c: "US" },
  { id: 2781, s: "PLYA", n: "Playa Hotels & Resorts BV", c: "US" },
  { id: 2782, s: "BRDS", n: "Bird Global", c: "US" },
  { id: 2783, s: "ARX.TO", n: "ARC Resources.", c: "CA" },
  { id: 2784, s: "NKT.", n: "NKT A/S", c: "Denmark" },
  { id: 2785, s: "III", n: "Information Services Group Inc", c: "US" },
  { id: 2786, s: "NDLS", n: "Noodles & Company", c: "US" },
  { id: 2787, s: "EAR", n: "Eargo Inc", c: "US" },
  { id: 2788, s: "PRLB", n: "Proto Labs Inc", c: "US" },
  { id: 2789, s: "NSIT", n: "Insight Enterprises Inc", c: "US" },
  { id: 2790, s: "AIT", n: "Applied Industrial Technologies", c: "US" },
  { id: 2791, s: "LRN", n: "Stride Inc", c: "US" },
  { id: 2792, s: "RNMBY", n: "Rheinmetall AG ADR", c: "US" },
  { id: 2793, s: "VIE.PA", n: "Veolia Environnement VE SA", c: "FR" },
  { id: 2794, s: "EMX", n: "EMX Royalty Co", c: "US" },
  { id: 2795, s: "RVT", n: "Royce Value Closed Fund", c: "US" },
  { id: 2796, s: "ACHL", n: "Achilles Therapeutics PLC ADR", c: "US" },
  { id: 2797, s: "OMCL", n: "Omnicell Inc", c: "US" },
  { id: 2798, s: "JJSF", n: "J & J Snack Foods Co", c: "US" },
  { id: 2799, s: "EPRT", n: "Essential Properties Realty Trust Inc", c: "US" },
  { id: 2800, s: "TCRX", n: "Tscan Therapeutics Inc", c: "US" },
  { id: 2801, s: "AXDX", n: "Accelerate Diagnostics Inc", c: "US" },
  { id: 2802, s: "REVG", n: "Rev Group Inc", c: "US" },
  { id: 2803, s: "HWKN", n: "Hawkins Inc", c: "US" },
  { id: 2804, s: "MSB.AX", n: "Mesoblast", c: "Australia" },
  { id: 2805, s: "GRN.TO", n: "Greenlane Renewables Inc", c: "CA" },
  { id: 2806, s: "AC", n: "Acco Brands Corporation", c: "US" },
  { id: 2807, s: "CRGY", n: "Crescent Energy", c: "US" },
  { id: 2808, s: "NSTG", n: "NanoString Technologies Inc", c: "US" },
  { id: 2809, s: "OPCH", n: "Option Care Health Inc", c: "US" },
  { id: 2810, s: "BARC.L", n: "Barclays PLC", c: "UK" },
  { id: 2811, s: "WISA", n: "WiSA Technologies Inc", c: "US" },
  { id: 2812, s: "SRAD", n: "Sportradar Group AG", c: "US" },
  { id: 2813, s: "EHTH", n: "eHealth Inc", c: "US" },
  { id: 2814, s: "REPL", n: "Replimune Group Inc", c: "US" },
  { id: 2815, s: "LEV.TO", n: "Lion Electric Co", c: "CA" },
  { id: 2816, s: "TSE", n: "Trinseo SA", c: "US" },
  { id: 2817, s: "ARBK", n: "Argo Blockchain PLC ADR", c: "US" },
  { id: 2818, s: "BRT", n: "BRT Realty Trust", c: "US" },
  { id: 2819, s: "TYRES.HE", n: "Nokian Renkaat Oyj", c: "Finland" },
  { id: 2820, s: "IBP", n: "Installed Building Products Inc", c: "US" },
  { id: 2821, s: "PRMW", n: "Primo Water Co", c: "US" },
  { id: 2822, s: "LOTB.BR", n: "Lotus Bakeries", c: "Belgium" },
  { id: 2823, s: "CHCT", n: "Community Healthcare Trust Inc", c: "US" },
  { id: 2824, s: "GHM", n: "Graham Corporation", c: "US" },
  { id: 2825, s: "BCEL", n: "Atreca Inc", c: "US" },
  { id: 2826, s: "CDAY", n: "Ceridian HCM Holding Inc", c: "US" },
  { id: 2827, s: "TBLT", n: "Toughbuilt Industries Inc", c: "US" },
  { id: 2828, s: "DGLY", n: "Digital Ally Inc", c: "US" },
  { id: 2829, s: "EQNR.OL", n: "Equinor ASA", c: "Norway" },
  { id: 2830, s: "KROS", n: "Keros Therapeutics Inc", c: "US" },
  { id: 2831, s: "GDOT", n: "Green Dot Corporation", c: "US" },
  { id: 2832, s: "PRAX", n: "Praxis Precision Medicines Inc", c: "US" },
  { id: 2833, s: "RYN", n: "Rayonier Inc", c: "US" },
  { id: 2834, s: "TZOO", n: "Travelzoo", c: "US" },
  { id: 2835, s: "FSS", n: "Federal Signal Corporation", c: "US" },
  { id: 2836, s: "KZR", n: "Kezar Life Sciences Inc", c: "US" },
  { id: 2837, s: "HWC", n: "Hancock Whitney Co", c: "US" },
  { id: 2838, s: "KARO", n: "Karooooo", c: "US" },
  { id: 2839, s: "TDUP", n: "ThredUp Inc", c: "US" },
  { id: 2840, s: "COEP", n: "Coeptis Therapeutics Inc", c: "US" },
  { id: 2841, s: "EBC", n: "Eastern Bankshares Inc", c: "US" },
  { id: 2842, s: "ELS", n: "Equity Lifestyle Properties Inc", c: "US" },
  { id: 2843, s: "AAN", n: "The Aaron's Company Inc.", c: "US" },
  { id: 2844, s: "IMOS", n: "ChipMOS Technologies Inc", c: "US" },
  { id: 2845, s: "NTGR", n: "NETGEAR Inc", c: "US" },
  { id: 2846, s: "BICO.ST", n: "BICO Group AB", c: "Sweden" },
  { id: 2847, s: "VERB", n: "VERB TECHNOLOGY COMPANY INC", c: "US" },
  { id: 2848, s: "SOVO", n: "Sovos Brands Inc", c: "US" },
  { id: 2849, s: "KTTA", n: "Pasithea Therapeutics Co", c: "US" },
  { id: 2850, s: "UMI.BR", n: "Umicore S.A.", c: "Belgium" },
  { id: 2851, s: "FRU.TO", n: "Freehold Royalties.", c: "CA" },
  { id: 2852, s: "ACA.PA", n: "Credit Agricole SA", c: "FR" },
  { id: 2853, s: "YSG", n: "Yatsen Holding", c: "US" },
  { id: 2854, s: "MMS", n: "Maximus Inc", c: "US" },
  { id: 2855, s: "GKOS", n: "Glaukos Co", c: "US" },
  { id: 2856, s: "RYTM", n: "Rhythm Pharmaceuticals Inc", c: "US" },
  { id: 2857, s: "AEL", n: "American Equity Investment Life Holding", c: "US" },
  { id: 2858, s: "ESRT", n: "Empire State Realty Trust Inc", c: "US" },
  { id: 2859, s: "PINE", n: "Alpine Income Property Trust Inc", c: "US" },
  { id: 2860, s: "HRMY", n: "Harmony Biosciences Holdings", c: "US" },
  { id: 2861, s: "KYN", n: "Kayne Anderson MLP Investment Closed Fund", c: "US" },
  { id: 2862, s: "PSN", n: "Parsons Co", c: "US" },
  { id: 2863, s: "LTRY", n: "Lottery.com Inc. Common Stock", c: "US" },
  { id: 2864, s: "GIB", n: "CGI Inc", c: "US" },
  { id: 2865, s: "PXLW", n: "Pixelworks Inc", c: "US" },
  { id: 2866, s: "LVWR", n: " LiveWire Group Inc.", c: "US" },
  { id: 2867, s: "ONL", n: "Orion Office Reit Inc", c: "US" },
  { id: 2868, s: "CADE", n: "Cadence BanCo", c: "US" },
  { id: 2869, s: "PHX", n: "PHX Minerals Inc", c: "US" },
  { id: 2870, s: "APYX", n: "Apyx Medical Inc", c: "US" },
  { id: 2871, s: "INFA", n: "Informatica Inc", c: "US" },
  { id: 2872, s: "OPFI", n: "OppFi Inc", c: "US" },
  { id: 2873, s: "GDS", n: "GDS Holdings", c: "US" },
  { id: 2874, s: "BP.L", n: "BP PLC", c: "UK" },
  { id: 2875, s: "SSD", n: "Simpson Manufacturing Company Inc", c: "US" },
  { id: 2876, s: "PE", n: "Phillips Edison & Co Inc", c: "US" },
  { id: 2877, s: "B", n: "Barnes Group Inc", c: "US" },
  { id: 2878, s: "SEAT", n: "Vivid Seats Inc", c: "US" },
  { id: 2879, s: "VRNT", n: "Verint Systems Inc", c: "US" },
  { id: 2880, s: "SINT", n: "SINTX Technologies Inc", c: "US" },
  { id: 2881, s: "ARIS", n: "Aris Water Solutions Inc", c: "US" },
  { id: 2882, s: "ASYS", n: "Amtech Systems Inc", c: "US" },
  { id: 2883, s: "CSSE", n: "Chicken Soup for the Soul Entertainment", c: "US" },
  { id: 2884, s: "LCUT", n: "Lifetime Brands Inc", c: "US" },
  { id: 2885, s: "WKEY", n: "Wisekey International Holding AG", c: "US" },
  { id: 2886, s: "AVID", n: "Avid Technology Inc", c: "US" },
  { id: 2887, s: "LSEG.L", n: "London Stock Exchange Group PLC", c: "UK" },
  { id: 2888, s: "CPAC", n: "Cementos Pacasmayo SAA ADR", c: "US" },
  { id: 2889, s: "EPAZ", n: "Epazz Inc", c: "US" },
  { id: 2890, s: "PSTX", n: "Poseida Therapeutics Inc", c: "US" },
  { id: 2891, s: "NBM.V", n: "Neo Battery Materials Ord Shs", c: "CA" },
  { id: 2892, s: "MIND", n: "Mind Technology Inc", c: "US" },
  { id: 2893, s: "FOBI.V", n: "Fobi AI Inc", c: "CA" },
  { id: 2894, s: "TENB", n: "Tenable Holdings Inc", c: "US" },
  { id: 2895, s: "DNOW", n: "Now Inc", c: "US" },
  { id: 2896, s: "GFL", n: "Gfl Environmental Holdings Inc", c: "US" },
  { id: 2897, s: "SEMR", n: "Semrush Holdings Inc", c: "US" },
  { id: 2898, s: "HXL", n: "Hexcel Corporation", c: "US" },
  { id: 2899, s: "IAS", n: "Integral Ad Science Holding LLC", c: "US" },
  { id: 2900, s: "RBC", n: "RBC Bearings Incorporated", c: "US" },
  { id: 2901, s: "BMWYY", n: "Bayerische Motoren Werke AG ADR", c: "US" },
  { id: 2902, s: "HCCI", n: "Heritage-Crystal Clean Inc", c: "US" },
  { id: 2903, s: "ATTO", n: "Atento SA", c: "US" },
  { id: 2904, s: "CLBT", n: "Cellebrite DI", c: "US" },
  { id: 2905, s: "VRNA", n: "Verona Pharma PLC ADR", c: "US" },
  { id: 2906, s: "TGI", n: "Triumph Group Inc", c: "US" },
  { id: 2907, s: "OFS", n: "OFS Capital Co", c: "US" },
  { id: 2908, s: "ZWS", n: "Zurn Elkay Water Solutions Corporation", c: "US" },
  { id: 2909, s: "SCVL", n: "Shoe Carnival Inc", c: "US" },
  { id: 2910, s: "VE", n: "Veeco Instruments Inc", c: "US" },
  { id: 2911, s: "SR", n: "Spire Inc", c: "US" },
  { id: 2912, s: "LSXMA", n: "Liberty Media Corporation Series A Liberty SiriusXM Common Stock", c: "US" },
  { id: 2913, s: "RGA", n: "Reinsurance Group of America", c: "US" },
  { id: 2914, s: "SILC", n: "Silicom", c: "US" },
  { id: 2915, s: "600519.SS", n: "Kweichow Moutai", c: "CH" },
  { id: 2916, s: "CBA.AX", n: "Commonwealth Bank Of Australia", c: "Australia" },
  { id: 2917, s: "CBAY", n: "Cymabay Therapeu", c: "US" },
  { id: 2918, s: "ZEUS", n: "Olympic Steel Inc", c: "US" },
  { id: 2919, s: "PNT", n: "POINT Biopharma Global Inc", c: "US" },
  { id: 2920, s: "VIA", n: "Via Renewables Inc", c: "US" },
  { id: 2921, s: "DLEKG.TA", n: "Delek Group", c: "Israel" },
  { id: 2922, s: "TARO", n: "Taro Pharmaceutical Industries", c: "US" },
  { id: 2923, s: "KPRX", n: "Kiora Pharmaceuticals Inc", c: "US" },
  { id: 2924, s: "BOKF", n: "BOK Financial Corporation", c: "US" },
  { id: 2925, s: "GPOR", n: "Gulfport Energy Operating Co", c: "US" },
  { id: 2926, s: "VLVLY", n: "Volvo AB ADR", c: "US" },
  { id: 2927, s: "ASIX", n: "AdvanSix Inc", c: "US" },
  { id: 2928, s: "VOLCAR-B.ST", n: "Volvo Car AB (publ.)", c: "Sweden" },
  { id: 2929, s: "CCCS", n: "CCC Intelligent Solutions Holdings Inc. Common Stock", c: "US" },
  { id: 2930, s: "WERN", n: "Werner Enterprises Inc", c: "US" },
  { id: 2931, s: "ONVO", n: "Organovo Holdings Inc", c: "US" },
  { id: 2932, s: "NWBI", n: "Northwest Bancshares Inc", c: "US" },
  { id: 2933, s: "WMC", n: "Western Asset Mortgage Capital", c: "US" },
  { id: 2934, s: "BNL", n: "Broadstone Net Lease Inc", c: "US" },
  { id: 2935, s: "ARKO", n: "Arko Co", c: "US" },
  { id: 2936, s: "ROAD", n: "Construction Partners Inc", c: "US" },
  { id: 2937, s: "P911.DE", n: "Porsche AG", c: "GER" },
  { id: 2938, s: "MYMD", n: "MyMD Pharmaceuticals Inc", c: "US" },
  { id: 2939, s: "MTA", n: "Metalla Royalty & Streaming", c: "US" },
  { id: 2940, s: "AC.MX", n: "Arca Continental S.A.B. de C.V", c: "Mexi" },
  { id: 2941, s: "ADMP", n: "Adamis Pharma", c: "US" },
  { id: 2942, s: "VRCA", n: "Verrica Pharmaceuticals Inc", c: "US" },
  { id: 2943, s: "SNDR", n: "Schneider National Inc", c: "US" },
  { id: 2944, s: "STERV.HE", n: "Stora Enso Oyj R", c: "Finland" },
  { id: 2945, s: "OM", n: "Outset Medical Inc", c: "US" },
  { id: 2946, s: "STRC", n: "Sarcos Technology and Robotics", c: "US" },
  { id: 2947, s: "FORM", n: "FormFactor Inc", c: "US" },
  { id: 2948, s: "RDN", n: "Radian Group Inc", c: "US" },
  { id: 2949, s: "DEN", n: "Denbury Resources Inc", c: "US" },
  { id: 2950, s: "CBAT", n: "CBAK Energy Technology Inc", c: "US" },
  { id: 2951, s: "APPF", n: "Appfolio Inc", c: "US" },
  { id: 2952, s: "DMAC", n: "DiaMedica Therapeutics Inc", c: "US" },
  { id: 2953, s: "CW", n: "Curtiss-Wright Corporation", c: "US" },
  { id: 2954, s: "2454.TW", n: "MediaTek Inc", c: "TW" },
  { id: 2955, s: "SRCL", n: "Stericycle Inc", c: "US" },
  { id: 2956, s: "ROVR", n: "Rover Group Inc", c: "US" },
  { id: 2957, s: "HLI", n: "Houlihan Lokey Inc", c: "US" },
  { id: 2958, s: "PLSE", n: "Pulse Biosciences Inc", c: "US" },
  { id: 2959, s: "BA.L", n: "BAE Systems plc", c: "UK" },
  { id: 2960, s: "APOG", n: "Apogee Enterprises Inc", c: "US" },
  { id: 2961, s: "WINA", n: "Winmark Corporation", c: "US" },
  { id: 2962, s: "AYI", n: "Acuity Brands Inc", c: "US" },
  { id: 2963, s: "GMS", n: "GMS Inc", c: "US" },
  { id: 2964, s: "BXRX", n: "Baudax Bio Inc", c: "US" },
  { id: 2965, s: "MMSI", n: "Merit Medical Systems Inc", c: "US" },
  { id: 2966, s: "NG", n: "NovaGold Resources Inc", c: "US" },
  { id: 2967, s: "PLRX", n: "Pliant Therapeutics Inc", c: "US" },
  { id: 2968, s: "DNLI", n: "Denali Therapeutics Inc", c: "US" },
  { id: 2969, s: "VVOS", n: "Vivos Therapeutics Inc", c: "US" },
  { id: 2970, s: "BWV", n: "Blue Water Vaccines Inc", c: "US" },
  { id: 2971, s: "CLDT", n: "Chatham Lodging Trust REIT", c: "US" },
  { id: 2972, s: "KYMR", n: "Kymera Therapeutics Inc", c: "US" },
  { id: 2973, s: "CCU", n: "Compania Cervecerias Unidas SA ADR", c: "US" },
  { id: 2974, s: "ASUR", n: "Asure Software Inc", c: "US" },
  { id: 2975, s: "NESTE.HE", n: "Neste Oil Oyj", c: "Finland" },
  { id: 2976, s: "ENZ", n: "Enzo Biochem Inc", c: "US" },
  { id: 2977, s: "PEGA", n: "Pegasystems Inc", c: "US" },
  { id: 2978, s: "RGS", n: "Regis Corporation", c: "US" },
  { id: 2979, s: "DNB", n: "Dun & Bradstreet Holdings Inc.", c: "US" },
  { id: 2980, s: "CONN", n: "Conns Inc", c: "US" },
  { id: 2981, s: "NXTP", n: "Nextplay Technologies Inc", c: "US" },
  { id: 2982, s: "KFY", n: "Korn Ferry", c: "US" },
  { id: 2983, s: "BMA", n: "Banco Macro SA B ADR", c: "US" },
  { id: 2984, s: "OCDO.L", n: "Ocado Group PLC", c: "UK" },
  { id: 2985, s: "CYTK", n: "Cytokinetics Inc", c: "US" },
  { id: 2986, s: "LXP", n: "LXP Industrial Trust", c: "US" },
  { id: 2987, s: "MSM", n: "MSC Industrial Direct Company Inc", c: "US" },
  { id: 2988, s: "KMT", n: "Kennametal Inc", c: "US" },
  { id: 2989, s: "DKL", n: "Delek Logistics Partners LP", c: "US" },
  { id: 2990, s: "ZD", n: "Ziff Davis Inc", c: "US" },
  { id: 2991, s: "MORN", n: "Morningstar Inc", c: "US" },
  { id: 2992, s: "MIDD", n: "Middleby Co", c: "US" },
  { id: 2993, s: "SLM", n: "SLM Co", c: "US" },
  { id: 2994, s: "FWRD", n: "Forward Air Corporation", c: "US" },
  { id: 2995, s: "CNA", n: "CNA Financial Corporation", c: "US" },
  { id: 2996, s: "SNN", n: "Smith & Nephew SNATS Inc", c: "US" },
  { id: 2997, s: "CLPT", n: "Clearpoint Neuro Inc", c: "US" },
  { id: 2998, s: "UPH", n: "Uphealth Inc", c: "US" },
  { id: 2999, s: "INSM", n: "Insmed Inc", c: "US" },
  { id: 3000, s: "CCRN", n: "Cross Country Healthcare Inc", c: "US" },
  { id: 3001, s: "EVLO", n: "Evelo Biosciences Inc", c: "US" },
  { id: 3002, s: "ALX", n: "Alexanders Inc", c: "US" },
  { id: 3003, s: "UDMY", n: "Udemy Inc", c: "US" },
  { id: 3004, s: "WALMEX.MX", n: "Wal-Mart de México S.A.B. de C.V", c: "Mexi" },
  { id: 3005, s: "LSXMK", n: "Liberty Media Corporation Series C Liberty SiriusXM Common Stock", c: "US" },
  { id: 3006, s: "HARP", n: "Harpoon Therapeutics Inc", c: "US" },
  { id: 3007, s: "TKA.DE", n: "thyssenkrupp AG", c: "GER" },
  { id: 3008, s: "BIT", n: "Blackrock Multi Sector Income Closed Fund", c: "US" },
  { id: 3009, s: "PGTI", n: "PGT Innovations Inc", c: "US" },
  { id: 3010, s: "KRON", n: "Kronos Bio Inc", c: "US" },
  { id: 3011, s: "MITT", n: "AG Mortgage Investment Trust Inc", c: "US" },
  { id: 3012, s: "PRAA", n: "PRA Group Inc", c: "US" },
  { id: 3013, s: "VET.TO", n: "Vermilion Energy Inc.", c: "CA" },
  { id: 3014, s: "ENSC", n: "Ensysce Biosciences Inc", c: "US" },
  { id: 3015, s: "BRDCY", n: "Bridgestone Corp ADR", c: "US" },
  { id: 3016, s: "LII", n: "Lennox International Inc", c: "US" },
  { id: 3017, s: "RBI.VI", n: "Raiffeisen Bank International AG", c: "Austria" },
  { id: 3018, s: "PSHG", n: "Performance Shipping Inc", c: "US" },
  { id: 3019, s: "QUAD", n: "Quad Graphics Inc", c: "US" },
  { id: 3020, s: "FANUY", n: "Fanuc Corporation", c: "US" },
  { id: 3021, s: "ICAD", n: "icad inc", c: "US" },
  { id: 3022, s: "EL.PA", n: "EssilorLuxottica S. A.", c: "FR" },
  { id: 3023, s: "MMYT", n: "MakeMyTrip Limited", c: "US" },
  { id: 3024, s: "MRU.TO", n: "Metro Inc.", c: "CA" },
  { id: 3025, s: "IMKTA", n: "Ingles Markets Incorporated", c: "US" },
  { id: 3026, s: "PROF", n: "Profound Medical Co", c: "US" },
  { id: 3027, s: "TVE.TO", n: "Tamarack Valley Energy", c: "CA" },
  { id: 3028, s: "IPAR", n: "Inter Parfums Inc", c: "US" },
  { id: 3029, s: "DPSI", n: "DecisionPoint Systems Inc", c: "US" },
  { id: 3030, s: "SYTA", n: "Siyata Mobile Inc", c: "US" },
  { id: 3031, s: "OPTN", n: "OptiNose", c: "US" },
  { id: 3032, s: "TAC", n: "TransAlta Co", c: "US" },
  { id: 3033, s: "NOVN.SW", n: "Novartis AG", c: "Switzerland" },
  { id: 3034, s: "TISI", n: "Team Inc", c: "US" },
  { id: 3035, s: "PRM", n: "Perimeter Solutions SA", c: "US" },
  { id: 3036, s: "WMG", n: "Warner Music Group", c: "US" },
  { id: 3037, s: "TUPRS.IS", n: "Turkiye Petrol Rafinerileri AS", c: "Turkey" },
  { id: 3038, s: "SEVN", n: "Seven Hills Realty Trust", c: "US" },
  { id: 3039, s: "CBSH", n: "Commerce Bancshares Inc", c: "US" },
  { id: 3040, s: "ALG", n: "Alamo Group Inc", c: "US" },
  { id: 3041, s: "DEI", n: "Douglas Emmett Inc", c: "US" },
  { id: 3042, s: "AMRC", n: "Ameresco Inc", c: "US" },
  { id: 3043, s: "TXMD", n: "TherapeuticsMD Inc", c: "US" },
  { id: 3044, s: "RMR", n: "RMR Group Inc", c: "US" },
  { id: 3045, s: "KOSS", n: "Koss Corporation", c: "US" },
  { id: 3046, s: "SOI", n: "Solaris Oilfield Infrastructure Inc", c: "US" },
  { id: 3047, s: "C", n: "Clear Channel Outdoor Holdings Inc", c: "US" },
  { id: 3048, s: "OCI.AS", n: "OCI NV", c: "Netherlands" },
  { id: 3049, s: "HROW", n: "Harrow Health Inc", c: "US" },
  { id: 3050, s: "INSE", n: "Inspired Entertainment Inc", c: "US" },
  { id: 3051, s: "AZTA", n: "Azenta Inc", c: "US" },
  { id: 3052, s: "BSET", n: "Bassett Furniture Industries", c: "US" },
  { id: 3053, s: "BPMC", n: "Blueprint Medicines Co", c: "US" },
  { id: 3054, s: "VINC", n: "Vincerx Pharma Inc", c: "US" },
  { id: 3055, s: "ATSG", n: "Air Transport Services Group Inc", c: "US" },
  { id: 3056, s: "ACCD", n: "Accolade Inc", c: "US" },
  { id: 3057, s: "CODA", n: "Coda Octopus Group Inc", c: "US" },
  { id: 3058, s: "COGT", n: "Cogent Biosciences Inc", c: "US" },
  { id: 3059, s: "CNTX", n: "Context Therapeutics Inc", c: "US" },
  { id: 3060, s: "YORW", n: "The York Water Company", c: "US" },
  { id: 3061, s: "OSUR", n: "OraSure Technologies Inc", c: "US" },
  { id: 3062, s: "NPI.TO", n: "Northland Power Inc.", c: "CA" },
  { id: 3063, s: "IDT", n: "IDT Corporation", c: "US" },
  { id: 3064, s: "WK", n: "Workiva Inc", c: "US" },
  { id: 3065, s: "TW", n: "Tradeweb Markets Inc", c: "US" },
  { id: 3066, s: "AKRBF", n: "Aker BP ASA", c: "US" },
  { id: 3067, s: "FIBK", n: "First Interstate BancSystem Inc", c: "US" },
  { id: 3068, s: "NDA-FI.HE", n: "Nordea Bank Abp", c: "Finland" },
  { id: 3069, s: "ADTH", n: "AdTheorent Holding Company Inc", c: "US" },
  { id: 3070, s: "ODP", n: "ODP Co", c: "US" },
  { id: 3071, s: "ZH", n: "Zhihu Inc ADR", c: "US" },
  { id: 3072, s: "ALHC", n: "Alignment Healthcare LLC", c: "US" },
  { id: 3073, s: "IGMS", n: "IGM Biosciences Inc", c: "US" },
  { id: 3074, s: "ENGI.PA", n: "Engie SA", c: "FR" },
  { id: 3075, s: "ORGO", n: "Organogenesis Holdings Inc", c: "US" },
  { id: 3076, s: "BANF", n: "BancFirst Corporation", c: "US" },
  { id: 3077, s: "PHGE", n: "Biomx Inc", c: "US" },
  { id: 3078, s: "REZI", n: "Resideo Technologies Inc", c: "US" },
  { id: 3079, s: "UXIN", n: "Uxin", c: "US" },
  { id: 3080, s: "BIOA-B.ST", n: "BioArctic AB (publ)", c: "Sweden" },
  { id: 3081, s: "SIFY", n: "Sify Technologies Limited", c: "US" },
  { id: 3082, s: "SPB", n: "Spectrum Brands Holdings Inc", c: "US" },
  { id: 3083, s: "NETI", n: "Eneti Inc", c: "US" },
  { id: 3084, s: "ACST", n: "Acasti Pharma Inc", c: "US" },
  { id: 3085, s: "CCCC", n: "C4 Therapeutics Inc", c: "US" },
  { id: 3086, s: "RAVE", n: "Rave Restaurant Group Inc", c: "US" },
  { id: 3087, s: "LYC.AX", n: "Lynas Rare Earths", c: "Australia" },
  { id: 3088, s: "REVB", n: "Revelation Biosciences Inc", c: "US" },
  { id: 3089, s: "BHIL", n: "Benson Hill Inc", c: "US" },
  { id: 3090, s: "ZTO", n: "ZTO Express (Cayman) Inc", c: "US" },
  { id: 3091, s: "ALK-B.", n: "ALK-Abelló A/S", c: "Denmark" },
  { id: 3092, s: "SY1.DE", n: "Symrise AG", c: "GER" },
  { id: 3093, s: "B", n: "Brinks Company", c: "US" },
  { id: 3094, s: "GO", n: "Grocery Outlet Holding Co", c: "US" },
  { id: 3095, s: "RMBL", n: "RumbleON Inc", c: "US" },
  { id: 3096, s: "CYCC", n: "Cyclacel Pharmaceuticals Inc", c: "US" },
  { id: 3097, s: "GPRE", n: "Green Plains Renewable Energy Inc", c: "US" },
  { id: 3098, s: "ENX.PA", n: "Euronext N.V.", c: "FR" },
  { id: 3099, s: "BALY", n: "Bally's Co", c: "US" },
  { id: 3100, s: "CGX.TO", n: "Cineplex Inc.", c: "CA" },
  { id: 3101, s: "ENR.DE", n: "Siemens Energy AG", c: "GER" },
  { id: 3102, s: "AXNX", n: "Axonics Modulation Technologies Inc", c: "US" },
  { id: 3103, s: "FELE", n: "Franklin Electric Co Inc", c: "US" },
  { id: 3104, s: "SEIC", n: "SEI Investments Company", c: "US" },
  { id: 3105, s: "REYN", n: "Reynolds Consumer Products Inc", c: "US" },
  { id: 3106, s: "BRX", n: "Brixmor Property", c: "US" },
  { id: 3107, s: "SQNS", n: "Sequans Communications SA", c: "US" },
  { id: 3108, s: "FTCI", n: "FTC Solar Inc", c: "US" },
  { id: 3109, s: "STRM", n: "Streamline Health Solutions Inc", c: "US" },
  { id: 3110, s: "BBAR", n: "BBVA Banco FRs SA ADR", c: "US" },
  { id: 3111, s: "ASTL", n: "Algoma Steel Group Inc", c: "US" },
  { id: 3112, s: "AWI", n: "Armstrong World Industries Inc", c: "US" },
  { id: 3113, s: "GTBP", n: "GT Biopharma Inc", c: "US" },
  { id: 3114, s: "FRHC", n: "Freedom Holding Co", c: "US" },
  { id: 3115, s: "AOMR", n: "Angel Oak Mortgage Inc", c: "US" },
  { id: 3116, s: "H.TO", n: "Hydro One", c: "CA" },
  { id: 3117, s: "PRGS", n: "Progress Software Corporation", c: "US" },
  { id: 3118, s: "MFIN", n: "Medallion Financial Co", c: "US" },
  { id: 3119, s: "CWEN-A", n: "Clearway Energy Inc", c: "US" },
  { id: 3120, s: "LLOY.L", n: "Lloyds Banking Group PLC", c: "UK" },
  { id: 3121, s: "IMAX", n: "Imax Co", c: "US" },
  { id: 3122, s: "STXS", n: "Stereotaxis Inc", c: "US" },
  { id: 3123, s: "MRCC", n: "Monroe Capital Co", c: "US" },
  { id: 3124, s: "TCMD", n: "Tactile Systems Technology Inc", c: "US" },
  { id: 3125, s: "WRBY", n: "Warby Parker Inc", c: "US" },
  { id: 3126, s: "DUOT", n: "Duos Technologies Group Inc", c: "US" },
  { id: 3127, s: "LFST.V", n: "Lifeist Wellness Inc", c: "CA" },
  { id: 3128, s: "POR", n: "Portland General Electric", c: "US" },
  { id: 3129, s: "CVGI", n: "Commercial Vehicle Group Inc", c: "US" },
  { id: 3130, s: "ALVO", n: "Alvotech", c: "US" },
  { id: 3131, s: "BODY", n: "Beachbody Company Inc", c: "US" },
  { id: 3132, s: "AFX.DE", n: "Carl Zeiss Meditec AG", c: "GER" },
  { id: 3133, s: "RCRT", n: "Recruiter.Com Group Inc", c: "US" },
  { id: 3134, s: "GBCI", n: "Glacier Bancorp Inc", c: "US" },
  { id: 3135, s: "YRD", n: "Yirendai", c: "US" },
  { id: 3136, s: "AMG", n: "Affiliated Managers Group Inc", c: "US" },
  { id: 3137, s: "BJRI", n: "BJs Restaurants Inc", c: "US" },
  { id: 3138, s: "SBFM", n: "Sunshine Biopharma Inc", c: "US" },
  { id: 3139, s: "RDNT", n: "RadNet Inc", c: "US" },
  { id: 3140, s: "OZON", n: "Ozon Holdings PLC", c: "US" },
  { id: 3141, s: "CRESY", n: "Cresud SACIF y A", c: "US" },
  { id: 3142, s: "KLXE", n: "KLX Energy Services Holdings Inc", c: "US" },
  { id: 3143, s: "OCFT", n: "Oneconnect Financial Technology", c: "US" },
  { id: 3144, s: "LZ", n: "LegalZoom.com Inc", c: "US" },
  { id: 3145, s: "RYSAS.IS", n: "Reysas Tasimacilik ve Lojistik Ticaret AS", c: "Turkey" },
  { id: 3146, s: "ORTX", n: "Orchard Therapeutics PLC", c: "US" },
  { id: 3147, s: "SINCH.ST", n: "Sinch AB", c: "Sweden" },
  { id: 3148, s: "MAG", n: "MAG Silver Co", c: "US" },
  { id: 3149, s: "ACT", n: "Enact Holdings Inc", c: "US" },
  { id: 3150, s: "GXXM", n: "Gex Management Inc", c: "US" },
  { id: 3151, s: "ERIC-B.ST", n: "Telefonaktiebolaget LM Ericsson (publ)", c: "Sweden" },
  { id: 3152, s: "MCW", n: "Mister Car Wash Inc", c: "US" },
  { id: 3153, s: "BCSF", n: "Bain Capital Specialty Finance Inc", c: "US" },
  { id: 3154, s: "APG", n: "Api Group Co", c: "US" },
  { id: 3155, s: "CRI", n: "Carter’s Inc", c: "US" },
  { id: 3156, s: "KRNT", n: "Kornit Digital", c: "US" },
  { id: 3157, s: "GROV", n: "Virgin Group Acquisition Corp II", c: "US" },
  { id: 3158, s: "NA.TO", n: "National Bank of CA", c: "CA" },
  { id: 3159, s: "AVAL", n: "Grupo Aval", c: "US" },
  { id: 3160, s: "ANIX", n: "Anixa Biosciences Inc", c: "US" },
  { id: 3161, s: "CCS", n: "Century Communities Inc", c: "US" },
  { id: 3162, s: "DGE.L", n: "Diageo PLC", c: "UK" },
  { id: 3163, s: "ACOMO.AS", n: "Amsterdam Commodities NV", c: "Netherlands" },
  { id: 3164, s: "BLKB", n: "Blackbaud Inc", c: "US" },
  { id: 3165, s: "GLT", n: "Glatfelter", c: "US" },
  { id: 3166, s: "YARIY", n: "Yara International ASA", c: "US" },
  { id: 3167, s: "EIF.TO", n: "Exchange Income Corporation", c: "CA" },
  { id: 3168, s: "AAIC", n: "Arlington Asset Investment Co", c: "US" },
  { id: 3169, s: "DNNGY", n: "Orsted A/S ADR", c: "US" },
  { id: 3170, s: "FBIN", n: "Fortune Brands Innovations Inc.", c: "US" },
  { id: 3171, s: "SATS", n: "EchoStar Corporation", c: "US" },
  { id: 3172, s: "AH", n: "Adapthealth Co", c: "US" },
  { id: 3173, s: "VCTR", n: "Victory Capital Holdings Inc", c: "US" },
  { id: 3174, s: "WTFC", n: "Wintrust Financial Corporation", c: "US" },
  { id: 3175, s: "ETON", n: "Eton Pharmaceuticals Inc", c: "US" },
  { id: 3176, s: "AGYS", n: "Agilysys Inc", c: "US" },
  { id: 3177, s: "FR", n: "First Industrial Realty Trust Inc", c: "US" },
  { id: 3178, s: "SAFE", n: "Safehold Inc", c: "US" },
  { id: 3179, s: "VLCN", n: "Volcon Inc", c: "US" },
  { id: 3180, s: "ITX.MC", n: "Industria de Diseno Textil SA", c: "Spain" },
  { id: 3181, s: "LCII", n: "LCI Industries", c: "US" },
  { id: 3182, s: "ANAB", n: "AnaptysBio Inc", c: "US" },
  { id: 3183, s: "PKN.WA", n: "Polski Koncern Naftowy ORLEN SA", c: "Poland" },
  { id: 3184, s: "ML", n: "MoneyLion Inc", c: "US" },
  { id: 3185, s: "BMEZ", n: "BlackRock Health Sciences Trust II", c: "US" },
  { id: 3186, s: "NEU", n: "NewMarket Corporation", c: "US" },
  { id: 3187, s: "GO", n: "GoHealth Inc.", c: "US" },
  { id: 3188, s: "DOMA", n: "Doma Holdings Inc", c: "US" },
  { id: 3189, s: "CRGE", n: "Charge Enterprises Inc", c: "US" },
  { id: 3190, s: "JSPR", n: "Jasper Therapeutics Inc", c: "US" },
  { id: 3191, s: "RIO.AX", n: "Rio Tinto", c: "Australia" },
  { id: 3192, s: "NZYM-B.", n: "Novozymes A/S", c: "Denmark" },
  { id: 3193, s: "AV.L", n: "Aviva PLC", c: "UK" },
  { id: 3194, s: "NILI.V", n: "Surge Battery Metals Inc", c: "CA" },
  { id: 3195, s: "SXT", n: "Sensient Technologies Corporation", c: "US" },
  { id: 3196, s: "BCDA", n: "Biocardia Inc", c: "US" },
  { id: 3197, s: "EB", n: "Eventbrite Inc Class A", c: "US" },
  { id: 3198, s: "ESAB", n: "ESAB Co", c: "US" },
  { id: 3199, s: "STN", n: "Stantec Inc", c: "US" },
  { id: 3200, s: "GVA", n: "Granite Construction Incorporated", c: "US" },
  { id: 3201, s: "ONB", n: "Old National BanCo", c: "US" },
  { id: 3202, s: "NEMAKA.MX", n: "Nemak S. A. B. de C. V", c: "Mexi" },
  { id: 3203, s: "OROCF", n: "Allkem", c: "US" },
  { id: 3204, s: "KALU", n: "Kaiser Aluminum Corporation", c: "US" },
  { id: 3205, s: "TOI.V", n: "Topicus.com Inc", c: "CA" },
  { id: 3206, s: "TG", n: "Tredegar Corporation", c: "US" },
  { id: 3207, s: "VEDU", n: "Visionary Education Technology Holdings Group Inc", c: "US" },
  { id: 3208, s: "AZEK", n: "Azek Company Inc", c: "US" },
  { id: 3209, s: "UGRO", n: "Urban-Gro Inc", c: "US" },
  { id: 3210, s: "ALVR", n: "Allovir Inc", c: "US" },
  { id: 3211, s: "WISE.L", n: "Wise plc", c: "UK" },
  { id: 3212, s: "ELP", n: "Companhia Paranaense de Energia Pref ADR", c: "US" },
  { id: 3213, s: "MYGN", n: "Myriad Genetics Inc", c: "US" },
  { id: 3214, s: "SAN.PA", n: "Sanofi SA", c: "FR" },
  { id: 3215, s: "LNW", n: "Light & Wonder Inc", c: "US" },
  { id: 3216, s: "GPP", n: "Green Plains Partners LP", c: "US" },
  { id: 3217, s: "FWBI", n: "First Wave BioPharma Inc", c: "US" },
  { id: 3218, s: "RRR", n: "Red Rock Resorts Inc", c: "US" },
  { id: 3219, s: "APCX", n: "Apptech Co", c: "US" },
  { id: 3220, s: "TTE.PA", n: "TotalEnergies SE", c: "FR" },
  { id: 3221, s: "WEX", n: "Wex Inc", c: "US" },
  { id: 3222, s: "LNN", n: "Lindsay Corporation", c: "US" },
  { id: 3223, s: "TGS", n: "Transportadora de Gas del Sur SA ADR", c: "US" },
  { id: 3224, s: "BLCM", n: "Bellicum Pharmaceuticals Inc", c: "US" },
  { id: 3225, s: "VOW.DE", n: "Volkswagen AG", c: "GER" },
  { id: 3226, s: "CASA", n: "Casa Systems Inc", c: "US" },
  { id: 3227, s: "JILL", n: "J.Jill Inc", c: "US" },
  { id: 3228, s: "KRT", n: "Karat Packaging Inc", c: "US" },
  { id: 3229, s: "SITE", n: "SiteOne Landscape Supply Inc", c: "US" },
  { id: 3230, s: "CBK.DE", n: "Commerzbank AG", c: "GER" },
  { id: 3231, s: "WTW", n: "Willis Towers Watson PLC", c: "US" },
  { id: 3232, s: "TERN", n: "Terns Pharmaceuticals Inc", c: "US" },
  { id: 3233, s: "OSIS", n: "OSI Systems Inc", c: "US" },
  { id: 3234, s: "MOV", n: "Movado Group Inc", c: "US" },
  { id: 3235, s: "ERF.PA", n: "Eurofins Scientific SE", c: "FR" },
  { id: 3236, s: "NVX", n: "Novonix ADR", c: "US" },
  { id: 3237, s: "EE", n: "Excelerate Energy Inc", c: "US" },
  { id: 3238, s: "HAE", n: "Haemonetics Corporation", c: "US" },
  { id: 3239, s: "OMV.VI", n: "OMV Aktiengesellschaft", c: "Austria" },
  { id: 3240, s: "MRCY", n: "Mercury Systems Inc", c: "US" },
  { id: 3241, s: "AGM", n: "Federal Agricultural Mortgage", c: "US" },
  { id: 3242, s: "ASB", n: "Associated Banc-Co", c: "US" },
  { id: 3243, s: "RNA", n: "Avidity Biosciences Inc", c: "US" },
  { id: 3244, s: "MBI", n: "MBIA Inc", c: "US" },
  { id: 3245, s: "HEPS", n: "D-MARKET Electronic Services & Trading ADR", c: "US" },
  { id: 3246, s: "HEN3.DE", n: "Henkel AG & Co. KGaA vz. (Pref Shares)", c: "GER" },
  { id: 3247, s: "LPL", n: "LG Display", c: "US" },
  { id: 3248, s: "VYNE", n: "Vyne Therapeutics Inc", c: "US" },
  { id: 3249, s: "NSP", n: "Insperity Inc", c: "US" },
  { id: 3250, s: "BRBY.L", n: "Burberry Group PLC", c: "UK" },
  { id: 3251, s: "AAL.L", n: "Anglo American PLC", c: "UK" },
  { id: 3252, s: "TECK-B.TO", n: "Teck Resources Limited", c: "CA" },
  { id: 3253, s: "NVVE", n: "Nuvve Holding Co", c: "US" },
  { id: 3254, s: "ANY", n: "Sphere 3D Co", c: "US" },
  { id: 3255, s: "STCN", n: "Steel Connect Inc", c: "US" },
  { id: 3256, s: "EYE", n: "National Vision Holdings Inc", c: "US" },
  { id: 3257, s: "CIFR", n: "Cipher Mining Inc", c: "US" },
  { id: 3258, s: "DBGI", n: "Digital Brands Group Inc", c: "US" },
  { id: 3259, s: "CURV", n: "Torrid Holdings Inc", c: "US" },
  { id: 3260, s: "KEY.TO", n: "Keyera Corp.", c: "CA" },
  { id: 3261, s: "KW", n: "Kennedy-Wilson Holdings Inc", c: "US" },
  { id: 3262, s: "XHR", n: "Xenia Hotels & Resorts Inc", c: "US" },
  { id: 3263, s: "PUMP", n: "ProPetro Holding Co", c: "US" },
  { id: 3264, s: "RUI.PA", n: "Rubis SCA", c: "FR" },
  { id: 3265, s: "RI.PA", n: "Pernod Ricard S.A.", c: "FR" },
  { id: 3266, s: "SLNO", n: "Soleno Therapeutics Inc", c: "US" },
  { id: 3267, s: "NRDS", n: "Nerdwallet Inc", c: "US" },
  { id: 3268, s: "EXPO", n: "Exponent Inc", c: "US" },
  { id: 3269, s: "IRMD", n: "Iradimed", c: "US" },
  { id: 3270, s: "RUSHA", n: "Rush Enterprises A Inc", c: "US" },
  { id: 3271, s: "SND", n: "Smart Sand Inc", c: "US" },
  { id: 3272, s: "CFFN", n: "Capitol Federal Financial Inc", c: "US" },
  { id: 3273, s: "ETST", n: "Earth Science Tech Inc", c: "US" },
  { id: 3274, s: "TSQ", n: "Townsquare Media Inc", c: "US" },
  { id: 3275, s: "KYCCF", n: "Keyence", c: "US" },
  { id: 3276, s: "LI.V", n: "American Lithium Co", c: "CA" },
  { id: 3277, s: "KCHOL.IS", n: "Koc Holding AS", c: "Turkey" },
  { id: 3278, s: "EBS.VI", n: "Erste Group Bank AG", c: "Austria" },
  { id: 3279, s: "SOLB.BR", n: "Solvay SA", c: "Belgium" },
  { id: 3280, s: "AAMC", n: "Altisource Asset Management Co", c: "US" },
  { id: 3281, s: "LEGN", n: "Legend Biotech Co", c: "US" },
  { id: 3282, s: "HEIA.AS", n: "Heineken", c: "Netherlands" },
  { id: 3283, s: "CLPR", n: "Clipper Realty Inc", c: "US" },
  { id: 3284, s: "SMP", n: "Standard Motor Products Inc", c: "US" },
  { id: 3285, s: "CYN", n: "Cyngn Inc", c: "US" },
  { id: 3286, s: "ROG", n: "Rogers Corporation", c: "US" },
  { id: 3287, s: "ENER.MC", n: "Grupo Ecoener S.A.", c: "Spain" },
  { id: 3288, s: "PRFT", n: "Perficient Inc", c: "US" },
  { id: 3289, s: "EEFT", n: "Euronet Worldwide Inc", c: "US" },
  { id: 3290, s: "INTZ", n: "Intrusion Inc", c: "US" },
  { id: 3291, s: "SE", n: "Secoo Holding", c: "US" },
  { id: 3292, s: "CHX", n: "ChampionX Corporation", c: "US" },
  { id: 3293, s: "CA.PA", n: "Carrefour SA", c: "FR" },
  { id: 3294, s: "MCY", n: "Mercury General Corporation", c: "US" },
  { id: 3295, s: "PD", n: "Patterson Companies Inc", c: "US" },
  { id: 3296, s: "VC", n: "Visteon Co", c: "US" },
  { id: 3297, s: "TRVI", n: "Trevi Therapeutics  Inc", c: "US" },
  { id: 3298, s: "RANI", n: "Rani Therapeutics Holdings Inc", c: "US" },
  { id: 3299, s: "CNS", n: "Cohen & Steers Inc", c: "US" },
  { id: 3300, s: "ELOX", n: "Eloxx Pharmaceuticals Inc", c: "US" },
  { id: 3301, s: "AREC", n: "American Resources Corp Class A", c: "US" },
  { id: 3302, s: "CRNX", n: "Crinetics Pharmaceuticals Inc", c: "US" },
  { id: 3303, s: "NHY.OL", n: "Norsk Hydro ASA", c: "Norway" },
  { id: 3304, s: "HEMP", n: "Hemp Inc", c: "US" },
  { id: 3305, s: "ECDP", n: "Eco Depot Inc", c: "US" },
  { id: 3306, s: "TSLV.V", n: "Tier One Silver Inc", c: "CA" },
  { id: 3307, s: "ZIP", n: "Ziprecruiter Inc", c: "US" },
  { id: 3308, s: "COLD", n: "Americold Realty Trust", c: "US" },
  { id: 3309, s: "BSGM", n: "Biosig Technologies Inc", c: "US" },
  { id: 3310, s: "CHH", n: "Choice Hotels International Inc", c: "US" },
  { id: 3311, s: "SMPL", n: "Simply Good Foods", c: "US" },
  { id: 3312, s: "PDFS", n: "PDF Solutions Inc", c: "US" },
  { id: 3313, s: "VLGEA", n: "Village Super Market Inc", c: "US" },
  { id: 3314, s: "URGN", n: "UroGen Pharma", c: "US" },
  { id: 3315, s: "PBLA", n: "Panbela Therapeutics Inc", c: "US" },
  { id: 3316, s: "APLT", n: "Applied Therapeutics Inc", c: "US" },
  { id: 3317, s: "NWG", n: "Natwest Group PLC", c: "US" },
  { id: 3318, s: "VICR", n: "Vicor Corporation", c: "US" },
  { id: 3319, s: "VITL", n: "Vital Farms Inc", c: "US" },
  { id: 3320, s: "DOOO", n: "BRP Inc", c: "US" },
  { id: 3321, s: "DND.TO", n: "Dye & Durham", c: "CA" },
  { id: 3322, s: "USEG", n: "US Energy Co", c: "US" },
  { id: 3323, s: "RLAY", n: "Relay Therapeutics Inc", c: "US" },
  { id: 3324, s: "JYNT", n: "The Joint Co", c: "US" },
  { id: 3325, s: "FLIC", n: "First of Long Island Co", c: "US" },
  { id: 3326, s: "ATNF", n: "180 Life Sciences Co", c: "US" },
  { id: 3327, s: "CARS", n: "Cars.com Inc", c: "US" },
  { id: 3328, s: "TUSK", n: "Mammoth Energy Services Inc", c: "US" },
  { id: 3329, s: "ACHC", n: "Acadia Healthcare Company Inc", c: "US" },
  { id: 3330, s: "RHC.V", n: "Royal Helium.", c: "CA" },
  { id: 3331, s: "WSC", n: "Willscot Mobile Mini Holdings Corp A", c: "US" },
  { id: 3332, s: "CORR", n: "Corenergy Infras", c: "US" },
  { id: 3333, s: "SGU", n: "Star Gas Partners LP", c: "US" },
  { id: 3334, s: "LWAY", n: "Lifeway Foods Inc", c: "US" },
  { id: 3335, s: "BRKL", n: "Brookline Bancorp Inc", c: "US" },
  { id: 3336, s: "NWS", n: "News Corp B", c: "US" },
  { id: 3337, s: "CGEM", n: "Cullinan Oncology LLC", c: "US" },
  { id: 3338, s: "BIOC", n: "Biocept Inc", c: "US" },
  { id: 3339, s: "SUPN", n: "Supernus Pharmaceuticals Inc", c: "US" },
  { id: 3340, s: "GAU", n: "Galiano Gold Inc", c: "US" },
  { id: 3341, s: "PCRX", n: "Pacira Pharmaceuticals Inc", c: "US" },
  { id: 3342, s: "CFR.SW", n: "Compagnie Financière Richemont SA", c: "Switzerland" },
  { id: 3343, s: "AVGR", n: "Avinger Inc", c: "US" },
  { id: 3344, s: "GLSI", n: "Greenwich Lifesciences Inc", c: "US" },
  { id: 3345, s: "PEN.AX", n: "Peninsula Energy", c: "Australia" },
  { id: 3346, s: "PRLD", n: "Prelude Therapeutics Inc", c: "US" },
  { id: 3347, s: "ADV", n: "Advantage Solutions Inc", c: "US" },
  { id: 3348, s: "NM", n: "Navios Maritime Holdings Inc", c: "US" },
  { id: 3349, s: "RWLK", n: "Rewalk Robotics", c: "US" },
  { id: 3350, s: "TALS", n: "Talaris Therapeutics Inc", c: "US" },
  { id: 3351, s: "SCWX", n: "Secureworks Co", c: "US" },
  { id: 3352, s: "OMI", n: "Owens & Minor Inc", c: "US" },
  { id: 3353, s: "HYPR", n: "Hyperfine Inc", c: "US" },
  { id: 3354, s: "ZURN.SW", n: "Zurich Insurance Group AG", c: "Switzerland" },
  { id: 3355, s: "VS", n: "Versus Systems Inc", c: "US" },
  { id: 3356, s: "SAH", n: "Sonic Automotive Inc", c: "US" },
  { id: 3357, s: "BEST", n: "BEST Inc", c: "US" },
  { id: 3358, s: "DGII", n: "Digi International Inc", c: "US" },
  { id: 3359, s: "MTLS", n: "Materialise NV", c: "US" },
  { id: 3360, s: "THMO", n: "Thermogenesis Holdings Inc", c: "US" },
  { id: 3361, s: "PMCB", n: "PharmaCyte Biotech Inc", c: "US" },
  { id: 3362, s: "EVAX", n: "Evaxion Biotech AS", c: "US" },
  { id: 3363, s: "TTEC", n: "TTEC Holdings Inc", c: "US" },
  { id: 3364, s: "IMB.L", n: "Imperial Brands PLC", c: "UK" },
  { id: 3365, s: "HBB", n: "Hamilton Beach Brands Holding Co Class A", c: "US" },
  { id: 3366, s: "ANNX", n: "Annexon Inc", c: "US" },
  { id: 3367, s: "ALO.PA", n: "Alstom S.A.", c: "FR" },
  { id: 3368, s: "HILS", n: "Hillstream Biopharma Inc", c: "US" },
  { id: 3369, s: "NGVC", n: "Natural Grocers by Vitamin Cottage Inc", c: "US" },
  { id: 3370, s: "IMUX", n: "Immunic Inc", c: "US" },
  { id: 3371, s: "ADCT", n: "ADC Therapeutics SA", c: "US" },
  { id: 3372, s: "AKE.AX", n: "Allkem", c: "Australia" },
  { id: 3373, s: "GRC", n: "Gorman-Rupp Company", c: "US" },
  { id: 3374, s: "STRR", n: "Star Equity Holdings Inc", c: "US" },
  { id: 3375, s: "AMPY", n: "Amplify Energy Co", c: "US" },
  { id: 3376, s: "KRUS", n: "Kura Sushi USA Inc", c: "US" },
  { id: 3377, s: "HCSG", n: "Healthcare Services Group Inc", c: "US" },
  { id: 3378, s: "VOYA", n: "Voya Financial Inc", c: "US" },
  { id: 3379, s: "UBSI", n: "United Bankshares Inc", c: "US" },
  { id: 3380, s: "ACLX", n: "Arcellx Inc", c: "US" },
  { id: 3381, s: "FONR", n: "Fonar Corporation", c: "US" },
  { id: 3382, s: "KMPR", n: "Kemper Corporation", c: "US" },
  { id: 3383, s: "BCBP", n: "BCB Bancorp Inc", c: "US" },
  { id: 3384, s: "MI-UN.TO", n: "Minto Apartment Real Estate Investment Trust", c: "CA" },
  { id: 3385, s: "BIR.TO", n: "Birchcliff Energy.", c: "CA" },
  { id: 3386, s: "CHEF", n: "The Chefs Warehouse Inc", c: "US" },
  { id: 3387, s: "CLNN", n: "Clene Inc.", c: "US" },
  { id: 3388, s: "CNNA", n: "Cann American Co", c: "US" },
  { id: 3389, s: "NREF", n: "Nexpoint Real Estate Finance Inc", c: "US" },
  { id: 3390, s: "TEDU", n: "Tarena Intl Adr", c: "US" },
  { id: 3391, s: "RYAN", n: "Ryan Specialty Group Holdings Inc", c: "US" },
  { id: 3392, s: "TUYA", n: "Tuya Inc ADR", c: "US" },
  { id: 3393, s: "SIKA.SW", n: "Sika AG", c: "Switzerland" },
  { id: 3394, s: "PEPG", n: "PepGen", c: "US" },
  { id: 3395, s: "UVE", n: "Universal Insurance Holdings Inc", c: "US" },
  { id: 3396, s: "GATX", n: "GATX Corporation", c: "US" },
  { id: 3397, s: "JVA", n: "Coffee Holding Co Inc", c: "US" },
  { id: 3398, s: "SJW", n: "SJW Corporation", c: "US" },
  { id: 3399, s: "OSS", n: "One Stop Systems Inc", c: "US" },
  { id: 3400, s: "LRS.AX", n: "Latin Resources", c: "Australia" },
  { id: 3401, s: "BRC", n: "Brady Corporation", c: "US" },
  { id: 3402, s: "HEIJM.AS", n: "Heijmans NV", c: "Netherlands" },
  { id: 3403, s: "HUBG", n: "Hub Group Inc", c: "US" },
  { id: 3404, s: "RGP", n: "Resources Connection Inc", c: "US" },
  { id: 3405, s: "PIPR", n: "Piper Sandler Companies", c: "US" },
  { id: 3406, s: "BHE", n: "Benchmark Electronics Inc", c: "US" },
  { id: 3407, s: "QIWI", n: "QIWI plc", c: "US" },
  { id: 3408, s: "IIIN", n: "Insteel Industries Inc", c: "US" },
  { id: 3409, s: "LFST", n: "Lifestance Health Group Inc", c: "US" },
  { id: 3410, s: "BVS", n: "Bioventus Inc", c: "US" },
  { id: 3411, s: "VRRM", n: "Verra Mobility Co", c: "US" },
  { id: 3412, s: "EGP", n: "EastGroup Properties Inc", c: "US" },
  { id: 3413, s: "AGS.BR", n: "ageas SA/NV", c: "Belgium" },
  { id: 3414, s: "QSR.TO", n: "Restaurant Brands International Inc", c: "CA" },
  { id: 3415, s: "VRAR", n: "Glimpse Group Inc", c: "US" },
  { id: 3416, s: "RCKY", n: "Rocky Brands Inc", c: "US" },
  { id: 3417, s: "BVH", n: "Bluegreen Vacations Holding Co", c: "US" },
  { id: 3418, s: "SMLR", n: "Semler Scientific Inc", c: "US" },
  { id: 3419, s: "XIN", n: "Xinyuan Real Estate", c: "US" },
  { id: 3420, s: "HNHPF", n: "Hon Hai Precision Industry Co ADR", c: "US" },
  { id: 3421, s: "FAF", n: "First American Corporation", c: "US" },
  { id: 3422, s: "AKR", n: "Acadia Realty Trust", c: "US" },
  { id: 3423, s: "CJT.TO", n: "Cargojet Inc", c: "CA" },
  { id: 3424, s: "FHI", n: "Federated Investors Inc B", c: "US" },
  { id: 3425, s: "OPGN", n: "OpGen Inc", c: "US" },
  { id: 3426, s: "AHH", n: "Armada Hflr Pr", c: "US" },
  { id: 3427, s: "MCOA", n: "Marijuana Company Of America Inc", c: "US" },
  { id: 3428, s: "HCWB", n: "HCW Biologics Inc", c: "US" },
  { id: 3429, s: "WTS", n: "Watts Water Technologies Inc", c: "US" },
  { id: 3430, s: "DOUG", n: "Douglas Elliman Inc", c: "US" },
  { id: 3431, s: "VHI", n: "Valhi Inc", c: "US" },
  { id: 3432, s: "RPHM", n: "Reneo Pharmaceuticals Inc", c: "US" },
  { id: 3433, s: "ENLV", n: "Enlivex Therapeutics", c: "US" },
  { id: 3434, s: "LTRN", n: "Lantern Pharma Inc", c: "US" },
  { id: 3435, s: "TVTX", n: "Travere Therapeutics Inc", c: "US" },
  { id: 3436, s: "HM-B.ST", n: "H & M Hennes & Mauritz AB (publ)", c: "Sweden" },
  { id: 3437, s: "CGAU", n: "Centerra Gold Inc", c: "US" },
  { id: 3438, s: "BBCP", n: "Concrete Pumping Holdings Class A", c: "US" },
  { id: 3439, s: "REFI", n: "Chicago Atlantic Real Estate Finance Inc", c: "US" },
  { id: 3440, s: "MSEX", n: "Middlesex Water Company", c: "US" },
  { id: 3441, s: "VPG", n: "Vishay Precision Group Inc", c: "US" },
  { id: 3442, s: "CIX", n: "CompX International Inc", c: "US" },
  { id: 3443, s: "SLGN", n: "Silgan Holdings Inc", c: "US" },
  { id: 3444, s: "DRQ", n: "Dril-Quip Inc", c: "US" },
  { id: 3445, s: "TSOI", n: "Therapeutic Solutions International Inc", c: "US" },
  { id: 3446, s: "PFS", n: "Provident Financial Services Inc", c: "US" },
  { id: 3447, s: "EXLS", n: "ExlService Holdings Inc", c: "US" },
  { id: 3448, s: "SHC", n: "Sotera Health", c: "US" },
  { id: 3449, s: "SCL", n: "Stepan Company", c: "US" },
  { id: 3450, s: "SNCE", n: "Science 37 Holdings Inc", c: "US" },
  { id: 3451, s: "BWMX", n: "Betterware De Mexico SA de CV", c: "US" },
  { id: 3452, s: "LOMA", n: "Loma Negra Compania Industrial Argentina SA ADR", c: "US" },
  { id: 3453, s: "MEIP", n: "MEI Pharma Inc", c: "US" },
  { id: 3454, s: "UBI.PA", n: "Ubisoft Entertainment", c: "FR" },
  { id: 3455, s: "BAESY", n: "BAE Systems PLC", c: "US" },
  { id: 3456, s: "SRU-UN.TO", n: "SmartCentres Real Estate Investment Trust", c: "CA" },
  { id: 3457, s: "000660.KS", n: "SK Hynix Inc", c: "Korea" },
  { id: 3458, s: "AMPE", n: "Ampio Pharm", c: "US" },
  { id: 3459, s: "SCPL", n: "Sciplay Co", c: "US" },
  { id: 3460, s: "LAW", n: "CS Disco LLC", c: "US" },
  { id: 3461, s: "HOUS", n: "Anywhere Real Estate Inc", c: "US" },
  { id: 3462, s: "VOR", n: "Vor Biopharma Inc", c: "US" },
  { id: 3463, s: "VOLV-B.ST", n: "AB Volvo (publ)", c: "Sweden" },
  { id: 3464, s: "DLB", n: "Dolby Laboratories", c: "US" },
  { id: 3465, s: "AED.BR", n: "Aedifica", c: "Belgium" },
  { id: 3466, s: "ALSEA.MX", n: "Alsea S.A.B. de C.V", c: "Mexi" },
  { id: 3467, s: "GFNORTEO.MX", n: "Grupo Financiero Banorte S.A.B. de C.V", c: "Mexi" },
  { id: 3468, s: "UONE", n: "Urban One", c: "US" },
  { id: 3469, s: "GULTU", n: "Gulf Coast", c: "US" },
  { id: 3470, s: "EMA.TO", n: "Emera Inc.", c: "CA" },
  { id: 3471, s: "NTCT", n: "NetScout Systems Inc", c: "US" },
  { id: 3472, s: "YI", n: "111 Inc", c: "US" },
  { id: 3473, s: "CLIQ.DE", n: "Cliq Digital AG", c: "GER" },
  { id: 3474, s: "PWM.V", n: "Power Metals Co", c: "CA" },
  { id: 3475, s: "AR.TO", n: "Argonaut Gold Inc", c: "CA" },
  { id: 3476, s: "CRBP", n: "Corbus Pharmaceuticals Holding", c: "US" },
  { id: 3477, s: "USLM", n: "United States Lime & Minerals Inc", c: "US" },
  { id: 3478, s: "WOW.AX", n: "Woolworths", c: "Australia" },
  { id: 3479, s: "PRX.AS", n: "Prosus", c: "Netherlands" },
  { id: 3480, s: "OFC", n: "Corporate Office Properties Trust", c: "US" },
  { id: 3481, s: "NATH", n: "Nathans Famous Inc", c: "US" },
  { id: 3482, s: "TPIC", n: "TPI Composites Inc", c: "US" },
  { id: 3483, s: "LGO", n: "Largo Resources", c: "US" },
  { id: 3484, s: "ASM.AS", n: "ASM International NV", c: "Netherlands" },
  { id: 3485, s: "YAL.AX", n: "Yancoal Australia", c: "Australia" },
  { id: 3486, s: "SBH", n: "Sally Beauty Holdings Inc", c: "US" },
  { id: 3487, s: "YNV.V", n: "Ynvisible Interactive Inc", c: "CA" },
  { id: 3488, s: "DM.V", n: "Datametrex AI", c: "CA" },
  { id: 3489, s: "TIXT", n: "TELUS International", c: "US" },
  { id: 3490, s: "PB", n: "Prosperity Bancshares Inc", c: "US" },
  { id: 3491, s: "NAS.OL", n: "Norwegian Air Shuttle ASA", c: "Norway" },
  { id: 3492, s: "UMG.AS", n: "Universal Music Group N.V.", c: "Netherlands" },
  { id: 3493, s: "CRAWA", n: "Crawford United Corporation", c: "US" },
  { id: 3494, s: "IFC.TO", n: "Intact Financial Corporation", c: "CA" },
  { id: 3495, s: "VOXX", n: "VOXX International Corporation", c: "US" },
  { id: 3496, s: "CDR.WA", n: "CD PROJEKT SA", c: "Poland" },
  { id: 3497, s: "FSZ.TO", n: "Fiera Capital Corporation", c: "CA" },
  { id: 3498, s: "LTRPA", n: "Liberty Tri", c: "US" },
  { id: 3499, s: "AMH", n: "American Homes 4 Rent", c: "US" },
  { id: 3500, s: "USNA", n: "USANA Health Sciences Inc", c: "US" },
  { id: 3501, s: "CANG", n: "Cango Inc", c: "US" },
  { id: 3502, s: "REP.MC", n: "Repsol", c: "Spain" },
  { id: 3503, s: "BNED", n: "Barnes & Noble Education Inc", c: "US" },
  { id: 3504, s: "BHR", n: "Braemar Hotel & Resorts Inc", c: "US" },
  { id: 3505, s: "DESP", n: "Despegar.com Co", c: "US" },
  { id: 3506, s: "GDNP.V", n: "Good Natured Products Inc", c: "CA" },
  { id: 3507, s: "QTRX", n: "Quanterix Co", c: "US" },
  { id: 3508, s: "POWL", n: "Powell Industries Inc", c: "US" },
  { id: 3509, s: "IMTX", n: "Immatics NV", c: "US" },
  { id: 3510, s: "WEAV", n: "Weave Communications Inc", c: "US" },
  { id: 3511, s: "HAFC", n: "Hanmi Financial Corporation", c: "US" },
  { id: 3512, s: "ASND", n: "Ascendis Pharma AS", c: "US" },
  { id: 3513, s: "GRIN", n: "Grindrod Shipping Holdings", c: "US" },
  { id: 3514, s: "TTMI", n: "TTM Technologies Inc", c: "US" },
  { id: 3515, s: "DCOM", n: "Dime Community Bancshares Inc", c: "US" },
  { id: 3516, s: "RPAY", n: "Repay Holdings Co", c: "US" },
  { id: 3517, s: "MSGM", n: "Motorsport Gaming Us LLC", c: "US" },
  { id: 3518, s: "SESG.PA", n: "SES S. A.", c: "FR" },
  { id: 3519, s: "ML.PA", n: "Compagnie Generale des Etablissements Michelin SCA", c: "FR" },
  { id: 3520, s: "BNTC", n: "Benitec Biopharma ADR", c: "US" },
  { id: 3521, s: "VISL", n: "Vislink Technologies Inc", c: "US" },
  { id: 3522, s: "UTRS", n: "Minerva Surgical Inc", c: "US" },
  { id: 3523, s: "ADTX", n: "Aditxt Inc.", c: "US" },
  { id: 3524, s: "INVE", n: "Identiv Inc", c: "US" },
  { id: 3525, s: "BANR", n: "Banner Corporation", c: "US" },
  { id: 3526, s: "IX", n: "Orix Corp Ads", c: "US" },
  { id: 3527, s: "UPLD", n: "Upland Software Inc", c: "US" },
  { id: 3528, s: "BBSI", n: "Barrett Business Services Inc", c: "US" },
  { id: 3529, s: "NNI", n: "Nelnet Inc", c: "US" },
  { id: 3530, s: "SHB-A.ST", n: "Svenska Handelsbanken AB (publ)", c: "Sweden" },
  { id: 3531, s: "ST", n: "Sensata Technologies Holding NV", c: "US" },
  { id: 3532, s: "GBX", n: "Greenbrier Companies Inc", c: "US" },
  { id: 3533, s: "THYAO.IS", n: "Turkish Airlines", c: "Turkey" },
  { id: 3534, s: "COHN", n: "Cohen & Company Inc", c: "US" },
  { id: 3535, s: "EVK.DE", n: "Evonik Industries AG", c: "GER" },
  { id: 3536, s: "NOTV", n: "Inotiv Inc", c: "US" },
  { id: 3537, s: "SYH.V", n: "Skyharbour Resources", c: "CA" },
  { id: 3538, s: "FTN.TO", n: "Financial 15 Split Co", c: "CA" },
  { id: 3539, s: "CASH", n: "Meta Financial Group Inc", c: "US" },
  { id: 3540, s: "BSFC", n: "Blue Star Foods Co", c: "US" },
  { id: 3541, s: "SALM.OL", n: "SalMar ASA", c: "Norway" },
  { id: 3542, s: "GMEXICOB.MX", n: "Grupo México S.A.B. de C.V", c: "Mexi" },
  { id: 3543, s: "IRT", n: "Independence Realty Trust Inc", c: "US" },
  { id: 3544, s: "ABN.AS", n: "ABN Amro Group NV", c: "Netherlands" },
  { id: 3545, s: "QNRX", n: "Quoin Pharmaceuticals DRC", c: "US" },
  { id: 3546, s: "MCLD", n: "mCloud Technologies Co", c: "US" },
  { id: 3547, s: "ALLG", n: "Allego US Inc", c: "US" },
  { id: 3548, s: "AKRBP.OL", n: "Aker BP ASA", c: "Norway" },
  { id: 3549, s: "PTCT", n: "PTC Therapeutics Inc", c: "US" },
  { id: 3550, s: "SPRO", n: "Spero Therapeutics Inc", c: "US" },
  { id: 3551, s: "RAMP", n: "Liveramp Holdings Inc", c: "US" },
  { id: 3552, s: "ISPC", n: "iSpecimen Inc", c: "US" },
  { id: 3553, s: "ISIG", n: "Insignia Systems Inc", c: "US" },
  { id: 3554, s: "RNO.PA", n: "Renault SA", c: "FR" },
  { id: 3555, s: "LAB", n: "Standard Biotools Inc", c: "US" },
  { id: 3556, s: "CATY", n: "Cathay General BanCo", c: "US" },
  { id: 3557, s: "GRFS", n: "Grifols SA ADR", c: "US" },
  { id: 3558, s: "LMNR", n: "Limoneira", c: "US" },
  { id: 3559, s: "PEBO", n: "Peoples Bancorp Inc", c: "US" },
  { id: 3560, s: "OCX", n: "OncoCyte Co", c: "US" },
  { id: 3561, s: "SAFT", n: "Safety Insurance Group Inc", c: "US" },
  { id: 3562, s: "373220.KS", n: "LG Energy Solution", c: "Korea" },
  { id: 3563, s: "FCN", n: "FTI Consulting Inc", c: "US" },
  { id: 3564, s: "TPST", n: "Tempest Therapeutics Inc", c: "US" },
  { id: 3565, s: "LGMK", n: "LogicMark Inc", c: "US" },
  { id: 3566, s: "BKU", n: "BankUnited Inc", c: "US" },
  { id: 3567, s: "PLOW", n: "Douglas Dynamics Inc", c: "US" },
  { id: 3568, s: "ETWO", n: "E2open Parent Holdings Inc", c: "US" },
  { id: 3569, s: "VXTR.V", n: "Voxtur Analytics Co", c: "CA" },
  { id: 3570, s: "APDN", n: "Applied DNA Sciences Inc", c: "US" },
  { id: 3571, s: "DOCS.L", n: "Dr. Martens PLC", c: "UK" },
  { id: 3572, s: "AIAD", n: "Aiadvertising Inc", c: "US" },
  { id: 3573, s: "SWTX", n: "SpringWorks Therapeutics Inc", c: "US" },
  { id: 3574, s: "CBZ", n: "CBIZ Inc", c: "US" },
  { id: 3575, s: "PROX.BR", n: "Proximus NV", c: "Belgium" },
  { id: 3576, s: "KAHOT.OL", n: "Kahoot As", c: "Norway" },
  { id: 3577, s: "PFBC", n: "Preferred Bank", c: "US" },
  { id: 3578, s: "CTKB", n: "Cytek Biosciences Inc", c: "US" },
  { id: 3579, s: "YAYO", n: "Yayyo Inc", c: "US" },
  { id: 3580, s: "SBEV", n: "Splash Beverage Group Inc", c: "US" },
  { id: 3581, s: "RVMD", n: "Revolution Medicines Inc", c: "US" },
  { id: 3582, s: "ECOR", n: "Electrocore LLC", c: "US" },
  { id: 3583, s: "NNBR", n: "NN Inc", c: "US" },
  { id: 3584, s: "KLTR", n: "Kaltura Inc", c: "US" },
  { id: 3585, s: "PAC", n: "Grupo Aeroportuario del Pacifico SAB De CV ADR", c: "US" },
  { id: 3586, s: "AEM.TO", n: "Agnico Eagle Mines Limited", c: "CA" },
  { id: 3587, s: "AZZ", n: "AZZ Incorporated", c: "US" },
  { id: 3588, s: "DHER.DE", n: "Delivery Hero SE", c: "GER" },
  { id: 3589, s: "STER", n: "Sterling Check Co", c: "US" },
  { id: 3590, s: "STVN", n: "Stevanato Group SpA", c: "US" },
  { id: 3591, s: "ATR", n: "AptarGroup Inc", c: "US" },
  { id: 3592, s: "OCC", n: "Optical Cable Corporation", c: "US" },
  { id: 3593, s: "RGNX", n: "Regenxbio Inc", c: "US" },
  { id: 3594, s: "2357.TW", n: "Asustek Computer Inc", c: "TW" },
  { id: 3595, s: "HOFV", n: "Hall of Fame Resort Entertainment", c: "US" },
  { id: 3596, s: "CWT", n: "California Water Service Group", c: "US" },
  { id: 3597, s: "CWK", n: "Cushman & Wakefield plc", c: "US" },
  { id: 3598, s: "DOC.V", n: "CloudMD Software & Services Inc", c: "CA" },
  { id: 3599, s: "AVNW", n: "Aviat Networks Inc", c: "US" },
  { id: 3600, s: "MYE", n: "Myers Industries Inc", c: "US" },
  { id: 3601, s: "CLRB", n: "Cellectar Biosciences Inc", c: "US" },
  { id: 3602, s: "WSP.TO", n: "WSP Global Inc", c: "CA" },
  { id: 3603, s: "EGRX", n: "Eagle Pharmaceuticals Inc", c: "US" },
  { id: 3604, s: "BATRA", n: "Atlanta Braves Holdings Inc. Series A Common Stock", c: "US" },
  { id: 3605, s: "AL", n: "Alico Inc", c: "US" },
  { id: 3606, s: "CETY", n: "Clean Energy Technologies Inc. Common Stock", c: "US" },
  { id: 3607, s: "AJX", n: "Great Ajax Co", c: "US" },
  { id: 3608, s: "CLVR", n: "Clever Leaves Holdings Inc", c: "US" },
  { id: 3609, s: "GXE.TO", n: "Gear Energy", c: "CA" },
  { id: 3610, s: "AOIFF", n: "Africa Oil Co", c: "US" },
  { id: 3611, s: "CVRX", n: "CVRx Inc", c: "US" },
  { id: 3612, s: "NGS", n: "Natural Gas Services Group Inc", c: "US" },
  { id: 3613, s: "ITOCY", n: "Itochu Corp ADR", c: "US" },
  { id: 3614, s: "INTRUM.ST", n: "Intrum Justitia AB", c: "Sweden" },
  { id: 3615, s: "DSV.", n: "DSV Panalpina A/S", c: "Denmark" },
  { id: 3616, s: "LE", n: "Lands’ End Inc", c: "US" },
  { id: 3617, s: "BW", n: "Babcock & Wilcox Enterprises Inc", c: "US" },
  { id: 3618, s: "NEON", n: "Neonode Inc", c: "US" },
  { id: 3619, s: "SASA.IS", n: "SASA Polyester Sanayi AS", c: "Turkey" },
  { id: 3620, s: "SMT.L", n: "Scottish Mortgage Investment Trust PLC", c: "UK" },
  { id: 3621, s: "AVNT", n: "Avient Co", c: "US" },
  { id: 3622, s: "MG.TO", n: "Magna International Inc", c: "CA" },
  { id: 3623, s: "NAVB", n: "Navidea Biopharmaceuticals Inc", c: "US" },
  { id: 3624, s: "CTBI", n: "Community Trust Bancorp Inc", c: "US" },
  { id: 3625, s: "FWRG", n: "First Watch Restaurant Group Inc", c: "US" },
  { id: 3626, s: "SUP", n: "Superior Industries International", c: "US" },
  { id: 3627, s: "PLPL", n: "Plandai Biotech", c: "US" },
  { id: 3628, s: "PKOH", n: "Park Ohio Holdings Co", c: "US" },
  { id: 3629, s: "ITRM", n: "Iterum Therapeutics PLC", c: "US" },
  { id: 3630, s: "AKYA", n: "Akoya Biosciences Inc", c: "US" },
  { id: 3631, s: "PAYS", n: "Paysign Inc", c: "US" },
  { id: 3632, s: "LIZI", n: "Lizhi Inc", c: "US" },
  { id: 3633, s: "300274.SZ", n: "Sungrow Power Supply", c: "CH" },
  { id: 3634, s: "RXST", n: "Rxsight Inc", c: "US" },
  { id: 3635, s: "EREGL.IS", n: "Eregli Demir ve Celik Fabrikalari TAS", c: "Turkey" },
  { id: 3636, s: "SISE.IS", n: "Turkiye Sise ve Cam Fabrikalari AS", c: "Turkey" },
  { id: 3637, s: "LITB", n: "LightInTheBox Holding Co ARD", c: "US" },
  { id: 3638, s: "PNDORA.", n: "Pandora A/S", c: "Denmark" },
  { id: 3639, s: "RBGLY", n: "Reckitt Benckiser Group PLC ADR", c: "US" },
  { id: 3640, s: "MITSY", n: "Mitsui & Company", c: "US" },
  { id: 3641, s: "SSP", n: "E. W. Scripps Co Class A", c: "US" },
  { id: 3642, s: "FATH", n: "Fathom Digital Manufacturing Co", c: "US" },
  { id: 3643, s: "DEF.V", n: "Defiance Silver Co", c: "CA" },
  { id: 3644, s: "FFH.TO", n: "Fairfax Financial Holdings", c: "CA" },
  { id: 3645, s: "CMCOM.AS", n: "CM.com NV", c: "Netherlands" },
  { id: 3646, s: "ZETA", n: "Zeta Global Holdings Co", c: "US" },
  { id: 3647, s: "KMDA", n: "Kamada", c: "US" },
  { id: 3648, s: "DYA.TO", n: "DynaCERT Inc.", c: "CA" },
  { id: 3649, s: "MP1.AX", n: "Megaport", c: "Australia" },
  { id: 3650, s: "CBD", n: "Companhia Brasileira de Distribuição", c: "US" },
  { id: 3651, s: "ZFOX", n: "ZeroFox Holdings Inc.", c: "US" },
  { id: 3652, s: "WBC.AX", n: "Westpac Banking Co", c: "Australia" },
  { id: 3653, s: "ARTNA", n: "Artesian Resources Corporation", c: "US" },
  { id: 3654, s: "IRS", n: "IRSA Inversiones Y Representaciones", c: "US" },
  { id: 3655, s: "ABOS", n: "Acumen Pharmaceuticals Inc", c: "US" },
  { id: 3656, s: "BRN.AX", n: "Brainchip Holdings", c: "Australia" },
  { id: 3657, s: "NPO", n: "Enpro Industries", c: "US" },
  { id: 3658, s: "SREN.SW", n: "Swiss Re AG", c: "Switzerland" },
  { id: 3659, s: "IPSC", n: "Century Therapeutics Inc", c: "US" },
  { id: 3660, s: "IDA", n: "IDACORP Inc", c: "US" },
  { id: 3661, s: "HGV", n: "Hilton Grand Vacations Inc", c: "US" },
  { id: 3662, s: "MOG-A", n: "Moog Inc", c: "US" },
  { id: 3663, s: "CSWI", n: "CSW Industrials Inc", c: "US" },
  { id: 3664, s: "LTBR", n: "Lightbridge Co", c: "US" },
  { id: 3665, s: "CTRN", n: "Citi Trends Inc", c: "US" },
  { id: 3666, s: "DLTH", n: "Duluth Holdings Inc", c: "US" },
  { id: 3667, s: "QGEN", n: "Qiagen NV", c: "US" },
  { id: 3668, s: "TEO", n: "Telecom Argentina SA ADR", c: "US" },
  { id: 3669, s: "PAY", n: "Paymentus Holdings Inc", c: "US" },
  { id: 3670, s: "AGS", n: "PlayAGS Inc", c: "US" },
  { id: 3671, s: "ENVB", n: "Enveric Biosciences Inc", c: "US" },
  { id: 3672, s: "VTVT", n: "vTv Therapeutics Inc", c: "US" },
  { id: 3673, s: "ESI", n: "Element Solutions Inc", c: "US" },
  { id: 3674, s: "GIL", n: "Gildan Activewear Inc.", c: "US" },
  { id: 3675, s: "ATEC", n: "Alphatec Holdings Inc", c: "US" },
  { id: 3676, s: "EZJ.L", n: "EasyJet PLC", c: "UK" },
  { id: 3677, s: "HEI.DE", n: "HeidelbergCement AG", c: "GER" },
  { id: 3678, s: "HEES", n: "H&E Equipment Services Inc", c: "US" },
  { id: 3679, s: "MWA", n: "Mueller Water Products", c: "US" },
  { id: 3680, s: "LMFA", n: "LM Funding America Inc", c: "US" },
  { id: 3681, s: "PWFL", n: "PowerFleet Inc", c: "US" },
  { id: 3682, s: "IESC", n: "IES Holdings Inc", c: "US" },
  { id: 3683, s: "CNO", n: "CNO Financial Group Inc", c: "US" },
  { id: 3684, s: "REI-UN.TO", n: "RioCan Real Estate Investment Trust", c: "CA" },
  { id: 3685, s: "LVO", n: "LiveOne Inc", c: "US" },
  { id: 3686, s: "WKSP", n: "Worksport", c: "US" },
  { id: 3687, s: "ALOT", n: "AstroNova Inc", c: "US" },
  { id: 3688, s: "ONTF", n: "ON24 Inc", c: "US" },
  { id: 3689, s: "DRTS", n: "Alpha Tau Medical", c: "US" },
  { id: 3690, s: "SSE.L", n: "SSE PLC", c: "UK" },
  { id: 3691, s: "KOP", n: "Koppers Holdings Inc", c: "US" },
  { id: 3692, s: "SAS.ST", n: "SAS AB", c: "Sweden" },
  { id: 3693, s: "MEC", n: "Mayville Engineering Co Inc", c: "US" },
  { id: 3694, s: "WNS", n: "WNS Holdings", c: "US" },
  { id: 3695, s: "NMRK", n: "Newmark Group Inc", c: "US" },
  { id: 3696, s: "KEX", n: "Kirby Corporation", c: "US" },
  { id: 3697, s: "RPTX", n: "Repare Therapeutics Inc", c: "US" },
  { id: 3698, s: "EOG.V", n: "Eco Atlantic Oil & Gas", c: "CA" },
  { id: 3699, s: "POU.TO", n: "Paramount Resources.", c: "CA" },
  { id: 3700, s: "INF.AX", n: "Infinity Lithium Corporation", c: "Australia" },
  { id: 3701, s: "SONM", n: "Sonim Technologies Inc", c: "US" },
  { id: 3702, s: "HSII", n: "Heidrick & Struggles International", c: "US" },
  { id: 3703, s: "PAVM", n: "PAVmed Inc", c: "US" },
  { id: 3704, s: "USAP", n: "Universal Stainless & Alloy", c: "US" },
  { id: 3705, s: "RWE.DE", n: "RWE AG", c: "GER" },
  { id: 3706, s: "ASN.AX", n: "Anson Resources", c: "Australia" },
  { id: 3707, s: "PSMT", n: "PriceSmart Inc", c: "US" },
  { id: 3708, s: "ENVA", n: "Enova International Inc", c: "US" },
  { id: 3709, s: "EGLX", n: "Enthusiast Gaming Holdings Inc", c: "US" },
  { id: 3710, s: "STKL", n: "SunOpta Inc.", c: "US" },
  { id: 3711, s: "PHI", n: "PLDT Inc ADR", c: "US" },
  { id: 3712, s: "AVD", n: "American Vanguard Corporation", c: "US" },
  { id: 3713, s: "GLBS", n: "Globus Maritime", c: "US" },
  { id: 3714, s: "IZEA", n: "IZEA Inc", c: "US" },
  { id: 3715, s: "LYRA", n: "Lyra Therapeutics Inc", c: "US" },
  { id: 3716, s: "LYEL", n: "Lyell Immunopharma Inc", c: "US" },
  { id: 3717, s: "MDNA", n: "Medicenna Therapeutics Co", c: "US" },
  { id: 3718, s: "THG", n: "The Hanover Insurance Group Inc", c: "US" },
  { id: 3719, s: "ASLE", n: "AerSale Co", c: "US" },
  { id: 3720, s: "ASR", n: "Grupo Aeroportuario del Sureste SAB de CV ADR", c: "US" },
  { id: 3721, s: "QH", n: "Quhuo Ltd", c: "US" },
  { id: 3722, s: "ABRA.V", n: "AbraSilver Resource Co", c: "CA" },
  { id: 3723, s: "SCTL", n: "Societal CDMO Inc", c: "US" },
  { id: 3724, s: "MMLP", n: "Martin Midstream Partners LP", c: "US" },
  { id: 3725, s: "AREB", n: "American Rebel Holdings Inc", c: "US" },
  { id: 3726, s: "SASR", n: "Sandy Spring Bancorp Inc", c: "US" },
  { id: 3727, s: "CJR-B.TO", n: "Corus Entertainment Inc", c: "CA" },
  { id: 3728, s: "APEI", n: "American Public Education Inc", c: "US" },
  { id: 3729, s: "STRO", n: "Sutro Biopharma", c: "US" },
  { id: 3730, s: "UUU", n: "Universal Security Instruments Inc", c: "US" },
  { id: 3731, s: "ICM.V", n: "Iconic Minerals", c: "CA" },
  { id: 3732, s: "HRBR", n: "Harbor Diversified", c: "US" },
  { id: 3733, s: "FUSN", n: "Fusion Pharmaceuticals Inc", c: "US" },
  { id: 3734, s: "ATRO", n: "Astronics Corporation", c: "US" },
  { id: 3735, s: "NLS", n: "Nautilus Group Inc", c: "US" },
  { id: 3736, s: "IPHA", n: "Innate Pharma", c: "US" },
  { id: 3737, s: "MLR", n: "Miller Industries Inc", c: "US" },
  { id: 3738, s: "INKW", n: "Greene Concepts Inc", c: "US" },
  { id: 3739, s: "SOI.PA", n: "Soitec SA", c: "FR" },
  { id: 3740, s: "FULC", n: "Fulcrum Therapeutics Inc", c: "US" },
  { id: 3741, s: "ZNWD.L", n: "Zinnwald Lithium PLC", c: "UK" },
  { id: 3742, s: "MYO", n: "Myomo Inc", c: "US" },
  { id: 3743, s: "TRMR", n: "Tremor Video Inc", c: "US" },
  { id: 3744, s: "IMMX", n: "Immix Biopharma Inc", c: "US" },
  { id: 3745, s: "BK.TO", n: "Canadian Banc Co", c: "CA" },
  { id: 3746, s: "SYNH", n: "INC Research Holdings Inc", c: "US" },
  { id: 3747, s: "ICUI", n: "ICU Medical Inc", c: "US" },
  { id: 3748, s: "CVBF", n: "CVB Financial Corporation", c: "US" },
  { id: 3749, s: "DMP.AX", n: "Domino'S Pizza Enterprises", c: "Australia" },
  { id: 3750, s: "ACTG", n: "Acacia Research Corporation", c: "US" },
  { id: 3751, s: "UCBI", n: "United Community Banks Inc", c: "US" },
  { id: 3752, s: "CWBR", n: "CohBar Inc", c: "US" },
  { id: 3753, s: "PCTI", n: "PC-Tel Inc", c: "US" },
  { id: 3754, s: "CPG.TO", n: "Crescent Point Energy Corp.", c: "CA" },
  { id: 3755, s: "SIGI", n: "Selective Insurance Group Inc", c: "US" },
  { id: 3756, s: "MGEE", n: "MGE Energy Inc", c: "US" },
  { id: 3757, s: "FBRT", n: "Franklin BSP Realty Trust Inc", c: "US" },
  { id: 3758, s: "MGRC", n: "McGrath RentCo", c: "US" },
  { id: 3759, s: "MEI", n: "Methode Electronics Inc", c: "US" },
  { id: 3760, s: "VVX", n: "V2X Inc", c: "US" },
  { id: 3761, s: "TOM2.AS", n: "TomTom NV", c: "Netherlands" },
  { id: 3762, s: "PHIA.AS", n: "Koninklijke Philips NV", c: "Netherlands" },
  { id: 3763, s: "PZG", n: "Paramount Gold Nevada Co", c: "US" },
  { id: 3764, s: "JAKK", n: "JAKKS Pacific Inc", c: "US" },
  { id: 3765, s: "SENEA", n: "Seneca Foods Corp A", c: "US" },
  { id: 3766, s: "XOMA", n: "XOMA Co", c: "US" },
  { id: 3767, s: "UMH", n: "UMH Properties Inc", c: "US" },
  { id: 3768, s: "USAS", n: "Americas Silver Co", c: "US" },
  { id: 3769, s: "EGBN", n: "Eagle Bancorp Inc", c: "US" },
  { id: 3770, s: "AIP", n: "Arteris Inc", c: "US" },
  { id: 3771, s: "OCFC", n: "OceanFirst Financial Co", c: "US" },
  { id: 3772, s: "CVLT", n: "CommVault Systems Inc", c: "US" },
  { id: 3773, s: "WRAP", n: "Wrap Technologies Inc", c: "US" },
  { id: 3774, s: "HLX", n: "Helix Energy Solutions Group Inc", c: "US" },
  { id: 3775, s: "GLPG", n: "Galapagos NV ADR", c: "US" },
  { id: 3776, s: "ENFN", n: "Enfusion Inc", c: "US" },
  { id: 3777, s: "IVDA", n: "Iveda Solutions Inc", c: "US" },
  { id: 3778, s: "PASG", n: "Passage Bio Inc", c: "US" },
  { id: 3779, s: "VCYT", n: "Veracyte Inc", c: "US" },
  { id: 3780, s: "CXB.TO", n: "Calibre Mining Co", c: "CA" },
  { id: 3781, s: "LASR", n: "nLIGHT Inc", c: "US" },
  { id: 3782, s: "SPENN.", n: "SPENN Technology AS", c: "Denmark" },
  { id: 3783, s: "NGVT", n: "Ingevity Co", c: "US" },
  { id: 3784, s: "FFN.TO", n: "North American Financial 15", c: "CA" },
  { id: 3785, s: "SWED-A.ST", n: "Swedbank AB (publ)", c: "Sweden" },
  { id: 3786, s: "ANZ.AX", n: "Australia and New Zealand Banking Group", c: "Australia" },
  { id: 3787, s: "MSBHF", n: "Mitsubishi Corp.", c: "US" },
  { id: 3788, s: "FOR", n: "Forestar Group Inc", c: "US" },
  { id: 3789, s: "SBM.V", n: "Sirona Biochem Co", c: "CA" },
  { id: 3790, s: "CPS", n: "Cooper Stnd", c: "US" },
  { id: 3791, s: "CVLG", n: "Covenant Logistics Group Inc", c: "US" },
  { id: 3792, s: "DASTY", n: "Dassault Systemes SA", c: "US" },
  { id: 3793, s: "ASTE", n: "Astec Industries Inc", c: "US" },
  { id: 3794, s: "TFII", n: "TFI International Inc", c: "US" },
  { id: 3795, s: "DSP", n: "Viant Technology Inc", c: "US" },
  { id: 3796, s: "RPT", n: "RPT Realty", c: "US" },
  { id: 3797, s: "HHS", n: "Harte Hanks Inc", c: "US" },
  { id: 3798, s: "VRC.AX", n: "Volt Resources", c: "Australia" },
  { id: 3799, s: "DXYN", n: "The Dixie Group Inc", c: "US" },
  { id: 3800, s: "JOUT", n: "Johnson Outdoors Inc", c: "US" },
  { id: 3801, s: "DNORD.", n: "Dampskibsselskabet Norden AS", c: "Denmark" },
  { id: 3802, s: "MNDO", n: "MIND CTI", c: "US" },
  { id: 3803, s: "SNAX", n: "Stryve Foods Inc", c: "US" },
  { id: 3804, s: "CDZI", n: "Cadiz Inc", c: "US" },
  { id: 3805, s: "CRDL", n: "Cardiol Therapeutics Inc Class A", c: "US" },
  { id: 3806, s: "AUSAF", n: "Australis Capital Inc", c: "US" },
  { id: 3807, s: "SURG.V", n: "Surge Copper Co", c: "CA" },
  { id: 3808, s: "VWAPY", n: "Volkswagen AG Pref 1/10 ADR", c: "US" },
  { id: 3809, s: "BN.TO", n: "Brookfield Corporation", c: "CA" },
  { id: 3810, s: "LAKE", n: "Lakeland Industries Inc", c: "US" },
  { id: 3811, s: "VIVE", n: "Viveve Medical Inc", c: "US" },
  { id: 3812, s: "SRRK", n: "Scholar Rock Holding Co", c: "US" },
  { id: 3813, s: "BLZE", n: "Backblaze Inc", c: "US" },
  { id: 3814, s: "JAMF", n: "Jamf Holding", c: "US" },
  { id: 3815, s: "WPP", n: "WPP PLC ADR", c: "US" },
  { id: 3816, s: "AOI.TO", n: "Africa Oil Co", c: "CA" },
  { id: 3817, s: "KNTK", n: "Kinetik Holdings Inc", c: "US" },
  { id: 3818, s: "F", n: "Aberdeen Global IF Inc", c: "US" },
  { id: 3819, s: "COLL", n: "Collegium Pharmaceutical Inc", c: "US" },
  { id: 3820, s: "ADNT", n: "Adient PLC", c: "US" },
  { id: 3821, s: "X.TO", n: "TMX Group Limited", c: "CA" },
  { id: 3822, s: "CHL.AX", n: "Camplify Holdings", c: "Australia" },
  { id: 3823, s: "PAHC", n: "Phibro Animal Health Corporation", c: "US" },
  { id: 3824, s: "GIPR", n: "Generation Income Properties Inc", c: "US" },
  { id: 3825, s: "ORA.PA", n: "Orange SA", c: "FR" },
  { id: 3826, s: "CTC-A.TO", n: "Canadian Tire Corporation Limited", c: "CA" },
  { id: 3827, s: "NOU.V", n: "Nouveau Monde Graphite Inc", c: "CA" },
  { id: 3828, s: "MGPI", n: "MGP Ingredients Inc", c: "US" },
  { id: 3829, s: "WSBC", n: "WesBanco Inc", c: "US" },
  { id: 3830, s: "FCFS", n: "FirstCash Inc", c: "US" },
  { id: 3831, s: "LOPE", n: "Grand Canyon Education Inc", c: "US" },
  { id: 3832, s: "UPONOR.HE", n: "Uponor Oyj", c: "Finland" },
  { id: 3833, s: "FOA", n: "Finance of America Companies Inc", c: "US" },
  { id: 3834, s: "ACRS", n: "Aclaris Therapeutics Inc", c: "US" },
  { id: 3835, s: "CU.TO", n: "Canadian Utilities Limited", c: "CA" },
  { id: 3836, s: "BLDP.TO", n: "Ballard Power Systems Inc", c: "CA" },
  { id: 3837, s: "IMCR", n: "Immunocore Holdings", c: "US" },
  { id: 3838, s: "CHCI", n: "Comstock Holding Companies Inc", c: "US" },
  { id: 3839, s: "ALEC", n: "Alector Inc", c: "US" },
  { id: 3840, s: "ORK.OL", n: "Orkla ASA", c: "Norway" },
  { id: 3841, s: "BNR.DE", n: "Brenntag SE", c: "GER" },
  { id: 3842, s: "GLDG", n: "GoldMining Inc", c: "US" },
  { id: 3843, s: "GLDD", n: "Great Lakes Dredge & Dock", c: "US" },
  { id: 3844, s: "BCPC", n: "Balchem Corporation", c: "US" },
  { id: 3845, s: "SHL.DE", n: "Siemens Healthineers AG", c: "GER" },
  { id: 3846, s: "TIMB", n: "TIM Participacoes SA", c: "US" },
  { id: 3847, s: "JHX", n: "James Hardie Industries PLC ADR", c: "US" },
  { id: 3848, s: "EQRX", n: "EQRx Inc", c: "US" },
  { id: 3849, s: "LIGHT.AS", n: "Signify NV", c: "Netherlands" },
  { id: 3850, s: "DMS", n: "Digital Media Solutions Inc", c: "US" },
  { id: 3851, s: "LOOP", n: "Loop Industries Inc", c: "US" },
  { id: 3852, s: "OLP", n: "One Liberty Properties Inc", c: "US" },
  { id: 3853, s: "SVE.V", n: "Silver One Resources Inc", c: "CA" },
  { id: 3854, s: "RAIL", n: "Freightcar America Inc", c: "US" },
  { id: 3855, s: "EVOK", n: "Evoke Pharma Inc", c: "US" },
  { id: 3856, s: "ORIC", n: "Oric Pharmaceuticals Inc", c: "US" },
  { id: 3857, s: "OGS", n: "One Gas Inc", c: "US" },
  { id: 3858, s: "DSS", n: "DSS Inc", c: "US" },
  { id: 3859, s: "OSPN", n: "OneSpan Inc", c: "US" },
  { id: 3860, s: "PEGY", n: "Pineapple Holdings Inc", c: "US" },
  { id: 3861, s: "PXS", n: "Pyxis Tankers Inc", c: "US" },
  { id: 3862, s: "AP-UN.TO", n: "Allied Properties Real Estate Investment Trust", c: "CA" },
  { id: 3863, s: "VATE", n: "Innovate Co", c: "US" },
  { id: 3864, s: "MUV2.DE", n: "Münchener Rück AG", c: "GER" },
  { id: 3865, s: "NEXE.V", n: "NEXE Innovations Inc", c: "CA" },
  { id: 3866, s: "CLB", n: "Core Laboratories NV", c: "US" },
  { id: 3867, s: "LBRDK", n: "Liberty Broadband Srs C", c: "US" },
  { id: 3868, s: "LXEH", n: "Lixiang Education Holding", c: "US" },
  { id: 3869, s: "VNET", n: "VNET Group Inc DRC", c: "US" },
  { id: 3870, s: "FSTR", n: "LB Foster Company", c: "US" },
  { id: 3871, s: "TGAN", n: "Transphorm Technology Inc", c: "US" },
  { id: 3872, s: "PHASQ", n: "PhaseBio Pharmaceuticals Inc.", c: "US" },
  { id: 3873, s: "MKS.L", n: "Marks and Spencer Group PLC", c: "UK" },
  { id: 3874, s: "KNC.V", n: "K9 Gold Co", c: "CA" },
  { id: 3875, s: "CASS", n: "Cass Information Systems Inc", c: "US" },
  { id: 3876, s: "AKA", n: "AKA Brands Holding Co", c: "US" },
  { id: 3877, s: "BWMN", n: "Bowman Consulting Group", c: "US" },
  { id: 3878, s: "NEXT.TO", n: "NextSource Materials Inc", c: "CA" },
  { id: 3879, s: "PSTV", n: "Plus Therapeutics Inc", c: "US" },
  { id: 3880, s: "BB.TO", n: "BlackBerry", c: "CA" },
  { id: 3881, s: "CCB", n: "Coastal Financial Co", c: "US" },
  { id: 3882, s: "DCPH", n: "Deciphera Pharmaceuticals LLC", c: "US" },
  { id: 3883, s: "KMTUY", n: "Komatsu", c: "US" },
  { id: 3884, s: "GNENF", n: "Ganfeng Lithium", c: "US" },
  { id: 3885, s: "UG", n: "United-Guardian Inc", c: "US" },
  { id: 3886, s: "SOF.BR", n: "Sofina Société Anonyme", c: "Belgium" },
  { id: 3887, s: "CPF", n: "Central Pacific Financial Co", c: "US" },
  { id: 3888, s: "SAMPO.HE", n: "Sampo Oyj A", c: "Finland" },
  { id: 3889, s: "TC", n: "TuanChe ADR", c: "US" },
  { id: 3890, s: "EOAN.DE", n: "E.ON SE", c: "GER" },
  { id: 3891, s: "COMS", n: "COMSovereign Holding Co", c: "US" },
  { id: 3892, s: "SNEX", n: "Stonex Group Inc", c: "US" },
  { id: 3893, s: "TRC", n: "Tejon Ranch", c: "US" },
  { id: 3894, s: "LUNMF", n: "Lundin Mining Corporation", c: "US" },
  { id: 3895, s: "FYI.AX", n: "FYI Resources", c: "Australia" },
  { id: 3896, s: "IGG.L", n: "IG Group Holdings PLC", c: "UK" },
  { id: 3897, s: "CMB.V", n: "CMC Metals", c: "CA" },
  { id: 3898, s: "KELYA", n: "Kelly Services A Inc", c: "US" },
  { id: 3899, s: "POLA", n: "Polar Power Inc", c: "US" },
  { id: 3900, s: "NCPL", n: "Netcapital Inc", c: "US" },
  { id: 3901, s: "SSB", n: "South State Co", c: "US" },
  { id: 3902, s: "ALYI", n: "Alternet Systems Inc", c: "US" },
  { id: 3903, s: "VBX.F", n: "Voltabox AG", c: "GER" },
  { id: 3904, s: "KFS", n: "Kingsway Financial Services Inc", c: "US" },
  { id: 3905, s: "SBS", n: "Companhia de Saneamento Basico do Estado de Sao Paulo SABESP ADR", c: "US" },
  { id: 3906, s: "AGIO", n: "Agios Pharm", c: "US" },
  { id: 3907, s: "OUT1V.HE", n: "Outokumpu Oyj", c: "Finland" },
  { id: 3908, s: "OMGA", n: "Omega Therapeutics Inc", c: "US" },
  { id: 3909, s: "AVNS", n: "Avanos Medical Inc", c: "US" },
  { id: 3910, s: "KAI", n: "Kadant Inc", c: "US" },
  { id: 3911, s: "IPT.V", n: "IMPACT Silver Corp.", c: "CA" },
  { id: 3912, s: "MDWD", n: "Mediwound", c: "US" },
  { id: 3913, s: "KB", n: "KB Financial Group Inc", c: "US" },
  { id: 3914, s: "PLG", n: "Platinum Group Metals", c: "US" },
  { id: 3915, s: "USAU", n: "US Gold Co", c: "US" },
  { id: 3916, s: "PBPB", n: "Potbelly", c: "US" },
  { id: 3917, s: "ALTG", n: "Alta Equipment Group Inc", c: "US" },
  { id: 3918, s: "DME.V", n: "Desert Mountain Energy Co", c: "CA" },
  { id: 3919, s: "MELE.BR", n: "Melexis NV", c: "Belgium" },
  { id: 3920, s: "VVPR", n: "VivoPower International PLC", c: "US" },
  { id: 3921, s: "LIF.TO", n: "Labrador Iron Ore Royalty Co", c: "CA" },
  { id: 3922, s: "BWB", n: "Bridgewater Bancshares Inc", c: "US" },
  { id: 3923, s: "CMT", n: "Core Molding Technologies Inc", c: "US" },
  { id: 3924, s: "HBNC", n: "Horizon BanCo", c: "US" },
  { id: 3925, s: "KLR", n: "Kaleyra Inc", c: "US" },
  { id: 3926, s: "AHG", n: "Akso Health Group ADR", c: "US" },
  { id: 3927, s: "ACXP", n: "Acurx Pharmaceuticals LLC", c: "US" },
  { id: 3928, s: "PLXS", n: "Plexus Co", c: "US" },
  { id: 3929, s: "ELBM.V", n: "Electra Battery Materials Co", c: "CA" },
  { id: 3930, s: "SGMA", n: "SigmaTron International Inc", c: "US" },
  { id: 3931, s: "VIV.PA", n: "Vivendi SA", c: "FR" },
  { id: 3932, s: "EXFY", n: "Expensify Inc", c: "US" },
  { id: 3933, s: "FIXX", n: "Homology Medicines Inc", c: "US" },
  { id: 3934, s: "CAAP", n: "Corporacion America Airports", c: "US" },
  { id: 3935, s: "CABO", n: "Cable One Inc", c: "US" },
  { id: 3936, s: "HIVE.V", n: "HIVE Blockchain Technologies", c: "CA" },
  { id: 3937, s: "YMAB", n: "Y mAbs Therapeutics", c: "US" },
  { id: 3938, s: "MDV", n: "Modiv Inc", c: "US" },
  { id: 3939, s: "BPOP", n: "Popular Inc", c: "US" },
  { id: 3940, s: "MKTW", n: "Marketwise Inc", c: "US" },
  { id: 3941, s: "ENG", n: "ENGlobal Corporation", c: "US" },
  { id: 3942, s: "KAMN", n: "Kaman Corporation", c: "US" },
  { id: 3943, s: "CORT", n: "Corcept Therapeutics Incorporated", c: "US" },
  { id: 3944, s: "SGRY", n: "Surgery Partners Inc", c: "US" },
  { id: 3945, s: "MRK.DE", n: "Merck & Company Inc", c: "GER" },
  { id: 3946, s: "GTES", n: "Gates Industrial Corporation plc", c: "US" },
  { id: 3947, s: "HURN", n: "Huron Consulting Group Inc", c: "US" },
  { id: 3948, s: "INND", n: "Innerscope Advertising Agency Inc", c: "US" },
  { id: 3949, s: "BTCM", n: "BIT Mining", c: "US" },
  { id: 3950, s: "SELF", n: "Global Self Storage Inc", c: "US" },
  { id: 3951, s: "VVI", n: "Viad Co", c: "US" },
  { id: 3952, s: "ATH.TO", n: "Athabasca Oil Co", c: "CA" },
  { id: 3953, s: "EU", n: "enCore Energy Corp.", c: "US" },
  { id: 3954, s: "STSS", n: "Sharps Technology Inc", c: "US" },
  { id: 3955, s: "SHOO", n: "Steven Madden", c: "US" },
  { id: 3956, s: "VALE3.SA", n: "Vale S.A.", c: "Brazil" },
  { id: 3957, s: "ATO.PA", n: "Atos SE", c: "FR" },
  { id: 3958, s: "HOLN.SW", n: "Holcim AG", c: "Switzerland" },
  { id: 3959, s: "HUSA", n: "Houston American Energy Corporation", c: "US" },
  { id: 3960, s: "LSEA", n: "Landsea Homes Co", c: "US" },
  { id: 3961, s: "PLUS", n: "ePlus inc", c: "US" },
  { id: 3962, s: "ARNGF", n: "Argonaut Gold Inc", c: "US" },
  { id: 3963, s: "NLLSF", n: "Nel ASA", c: "US" },
  { id: 3964, s: "IONM", n: "Assure Holdings Co", c: "US" },
  { id: 3965, s: "DSGX", n: "Descartes Systems Group Inc", c: "US" },
  { id: 3966, s: "CNVCF", n: "Bioharvest Sciences Inc", c: "US" },
  { id: 3967, s: "CNMD", n: "CONMED Corporation", c: "US" },
  { id: 3968, s: "MIRM", n: "Mirum Pharmaceuticals Inc", c: "US" },
  { id: 3969, s: "NUMI.TO", n: "Numinus Wellness Inc", c: "CA" },
  { id: 3970, s: "FFIC", n: "Flushing Financial Corporation", c: "US" },
  { id: 3971, s: "RAND.AS", n: "Randstad NV", c: "Netherlands" },
  { id: 3972, s: "NTOIY", n: "Neste Oyj", c: "US" },
  { id: 3973, s: "WTB.L", n: "Whitbread PLC", c: "UK" },
  { id: 3974, s: "ABVC", n: "ABVC Biopharma Inc", c: "US" },
  { id: 3975, s: "EQC", n: "Equity Commonwealth", c: "US" },
  { id: 3976, s: "ZIP.AX", n: "Zip Co Limited", c: "Australia" },
  { id: 3977, s: "EAM.V", n: "East Africa Metals Inc.", c: "CA" },
  { id: 3978, s: "OTP.BD", n: "OTP Bank Nyrt", c: "Hungary" },
  { id: 3979, s: "XNET", n: "Xunlei Adr", c: "US" },
  { id: 3980, s: "LBRDA", n: "Liberty Broadband Srs A", c: "US" },
  { id: 3981, s: "ASGN", n: "ASGN Inc", c: "US" },
  { id: 3982, s: "TILE", n: "Interface Inc", c: "US" },
  { id: 3983, s: "SBGSY", n: "Schneider Electric SA", c: "US" },
  { id: 3984, s: "TRST", n: "TrustCo Bank Corp NY", c: "US" },
  { id: 3985, s: "EPC", n: "Edgewell Personal Care", c: "US" },
  { id: 3986, s: "IAUX", n: "I-80 Gold Co", c: "US" },
  { id: 3987, s: "ROCK", n: "Gibraltar Industries Inc", c: "US" },
  { id: 3988, s: "HAPB.V", n: "Hapbee Technologies Inc", c: "CA" },
  { id: 3989, s: "CLLS", n: "Cellectis SA", c: "US" },
  { id: 3990, s: "FOMC", n: "Fomo Co", c: "US" },
  { id: 3991, s: "VTYX", n: "Ventyx Biosciences Inc", c: "US" },
  { id: 3992, s: "NBY", n: "NovaBay Pharmaceuticals Inc", c: "US" },
  { id: 3993, s: "AEYE", n: "AudioEye Inc", c: "US" },
  { id: 3994, s: "SNDX", n: "Syndax Pharmaceuticals Inc", c: "US" },
  { id: 3995, s: "TMQ", n: "Trilogy Metals Inc", c: "US" },
  { id: 3996, s: "FMNB", n: "Farmers National Banc Co", c: "US" },
  { id: 3997, s: "KOFUBL.MX", n: "Coca-Cola FEMSA S.A.B. de C.V", c: "Mexi" },
  { id: 3998, s: "CZFS", n: "Citizens Financial Services Inc. Common Stock", c: "US" },
  { id: 3999, s: "TCX", n: "Tucows Inc.", c: "US" },
  { id: 4000, s: "CRTO", n: "Criteo Sa", c: "US" },
  { id: 4001, s: "TPB", n: "Turning Point Brands Inc", c: "US" },
  { id: 4002, s: "AGRI", n: "AgriFORCE Growing Systems", c: "US" },
  { id: 4003, s: "SIS.TO", n: "Savaria Corporation", c: "CA" },
  { id: 4004, s: "WORX", n: "Scworx Co", c: "US" },
  { id: 4005, s: "TEF.MC", n: "Telefonica", c: "Spain" },
  { id: 4006, s: "AC.PA", n: "Accor S. A.", c: "FR" },
  { id: 4007, s: "EPAC", n: "Enerpac Tool Group Co", c: "US" },
  { id: 4008, s: "MD", n: "Mednax Inc", c: "US" },
  { id: 4009, s: "LMAT", n: "LeMaitre Vascular Inc", c: "US" },
  { id: 4010, s: "MEG.TO", n: "MEG Energy Co", c: "CA" },
  { id: 4011, s: "RCM", n: "R1 RCM Inc", c: "US" },
  { id: 4012, s: "PTMN", n: "Portman Ridge Finance Co", c: "US" },
  { id: 4013, s: "SWIM", n: "Latham Group Inc", c: "US" },
  { id: 4014, s: "WAFD", n: "Washington Federal Inc", c: "US" },
  { id: 4015, s: "MACK", n: "Merrimack Pharmaceuticals Inc", c: "US" },
  { id: 4016, s: "AXGN", n: "Axogen Inc", c: "US" },
  { id: 4017, s: "KAVL", n: "Kaival Brands Innovations Group Inc", c: "US" },
  { id: 4018, s: "HLLY", n: "Holley Inc", c: "US" },
  { id: 4019, s: "WRR.V", n: "Walker River Resources Corp.", c: "CA" },
  { id: 4020, s: "LEAS", n: "Strategic Asset Leasing Inc", c: "US" },
  { id: 4021, s: "KT", n: "KT Corporation", c: "US" },
  { id: 4022, s: "HAYN", n: "Haynes International Inc", c: "US" },
  { id: 4023, s: "BOOM", n: "Dmc Global Inc", c: "US" },
  { id: 4024, s: "UMBF", n: "UMB Financial Corporation", c: "US" },
  { id: 4025, s: "RLJ", n: "RLJ Lodging Trust", c: "US" },
  { id: 4026, s: "LIVE", n: "Live Ventures Inc", c: "US" },
  { id: 4027, s: "CLVLY", n: "Clinuvel Pharmaceuticals ADR", c: "US" },
  { id: 4028, s: "ATGE", n: "Adtalem Global Education Inc", c: "US" },
  { id: 4029, s: "LIAN", n: "LianBio ADR", c: "US" },
  { id: 4030, s: "DTG.DE", n: "Daimler Truck Holding AG", c: "GER" },
  { id: 4031, s: "SKM", n: "SK Telecom Co ADR", c: "US" },
  { id: 4032, s: "ATRI", n: "ATRION Corporation", c: "US" },
  { id: 4033, s: "AKTX", n: "Akari Therapeutics PLC", c: "US" },
  { id: 4034, s: "OPY", n: "Oppenheimer Holdings Inc", c: "US" },
  { id: 4035, s: "TMST", n: "Timkensteel Co", c: "US" },
  { id: 4036, s: "SUPV", n: "Grupo Supervielle SA", c: "US" },
  { id: 4037, s: "PSO", n: "Pearson PLC ADR", c: "US" },
  { id: 4038, s: "ENV", n: "Envestnet Inc", c: "US" },
  { id: 4039, s: "BBAS3.SA", n: "Banco do Brasil S.A.", c: "Brazil" },
  { id: 4040, s: "ADUS", n: "Addus HomeCare Corporation", c: "US" },
  { id: 4041, s: "ASSA-B.ST", n: "ASSA ABLOY AB (publ)", c: "Sweden" },
  { id: 4042, s: "LIQT", n: "LiqTech International Inc", c: "US" },
  { id: 4043, s: "HQH", n: "Tekla Healthcare Investors", c: "US" },
  { id: 4044, s: "MODN", n: "Model N Inc", c: "US" },
  { id: 4045, s: "IOSP", n: "Innospec Inc", c: "US" },
  { id: 4046, s: "BTTX", n: "Better Therapeutics Inc", c: "US" },
  { id: 4047, s: "GHC", n: "Graham Holdings", c: "US" },
  { id: 4048, s: "WDS.AX", n: "Woodside Energy Group", c: "Australia" },
  { id: 4049, s: "DB1.DE", n: "Deutsche Börse AG", c: "GER" },
  { id: 4050, s: "SGHT", n: "Sight Sciences Inc", c: "US" },
  { id: 4051, s: "WILC", n: "G Willi-Food International", c: "US" },
  { id: 4052, s: "NFI.TO", n: "NFI Group Inc", c: "CA" },
  { id: 4053, s: "WHC.AX", n: "Whitehaven Coal", c: "Australia" },
  { id: 4054, s: "BTZ", n: "BlackRock Credit Allocation Income Trust", c: "US" },
  { id: 4055, s: "PHIO", n: "Phio Pharmaceuticals Co", c: "US" },
  { id: 4056, s: "SYA.AX", n: "Sayona Mining", c: "Australia" },
  { id: 4057, s: "REFR", n: "Research Frontiers Incorporated", c: "US" },
  { id: 4058, s: "BLX", n: "Foreign Trade Bank of Latin America Inc", c: "US" },
  { id: 4059, s: "SRDX", n: "SurModics Inc", c: "US" },
  { id: 4060, s: "SNTI", n: "Senti Biosciences Inc", c: "US" },
  { id: 4061, s: "BANC", n: "Banc of California Inc", c: "US" },
  { id: 4062, s: "KWR", n: "Quaker Chemical Corporation", c: "US" },
  { id: 4063, s: "FF", n: "FutureFuel Co", c: "US" },
  { id: 4064, s: "NXP", n: "Nuveen Select Tax-Free Income Portfolio", c: "US" },
  { id: 4065, s: "ATHM", n: "Autohome Inc", c: "US" },
  { id: 4066, s: "MG", n: "Mistras Group Inc", c: "US" },
  { id: 4067, s: "SCX", n: "LS Starrett Company", c: "US" },
  { id: 4068, s: "CYAN", n: "Cyanotech Corporation", c: "US" },
  { id: 4069, s: "CION", n: "Cion Investment Co", c: "US" },
  { id: 4070, s: "WF", n: "Woori Financial Group Inc", c: "US" },
  { id: 4071, s: "GRUMAB.MX", n: "Gruma S.A.B. de C.V", c: "Mexi" },
  { id: 4072, s: "ALD.AX", n: "Ampol", c: "Australia" },
  { id: 4073, s: "DSY.PA", n: "Dassault Systemes SE", c: "FR" },
  { id: 4074, s: "FRE.DE", n: "Fresenius SE & Co. KGaA", c: "GER" },
  { id: 4075, s: "CJ.TO", n: "Cardinal Energy", c: "CA" },
  { id: 4076, s: "MATW", n: "Matthews International Corporation", c: "US" },
  { id: 4077, s: "DG.PA", n: "Vinci S.A.", c: "FR" },
  { id: 4078, s: "RGLS", n: "Regulus Therapeutics Inc", c: "US" },
  { id: 4079, s: "HTBK", n: "Heritage Commerce Co", c: "US" },
  { id: 4080, s: "QTWO", n: "Q2 Holdings", c: "US" },
  { id: 4081, s: "KEP", n: "Korea Electric Power Corp ADR", c: "US" },
  { id: 4082, s: "SBMO.AS", n: "SBM Offshore NV", c: "Netherlands" },
  { id: 4083, s: "PRO", n: "PROS Holdings Inc", c: "US" },
  { id: 4084, s: "TNC", n: "Tennant Company", c: "US" },
  { id: 4085, s: "LFT", n: "Lument Finance Trust Inc", c: "US" },
  { id: 4086, s: "SLDB", n: "Solid Biosciences LLC", c: "US" },
  { id: 4087, s: "MRTN", n: "Marten Transport", c: "US" },
  { id: 4088, s: "LNVGY", n: "Lenovo Group PK", c: "US" },
  { id: 4089, s: "TDCX", n: "TDCX Inc ADR", c: "US" },
  { id: 4090, s: "SNES", n: "SenesTech Inc", c: "US" },
  { id: 4091, s: "ALIM", n: "Alimera Sciences Inc", c: "US" },
  { id: 4092, s: "PEB", n: "Pebblebrook Hotel Trust", c: "US" },
  { id: 4093, s: "NOVT", n: "Novanta Inc", c: "US" },
  { id: 4094, s: "SLF.TO", n: "Sun Life Financial Inc.", c: "CA" },
  { id: 4095, s: "AUDA", n: "Audacy Inc.", c: "US" },
  { id: 4096, s: "UFAB", n: "Unique Fabricating Inc", c: "US" },
  { id: 4097, s: "DLPN", n: "Dolphin Entertainment Inc", c: "US" },
  { id: 4098, s: "ESCA", n: "Escalade Incorporated", c: "US" },
  { id: 4099, s: "BHP.L", n: "BHP Group Limited", c: "UK" },
  { id: 4100, s: "NUWE", n: "Nuwellis Inc", c: "US" },
  { id: 4101, s: "OVID", n: "Ovid Therapeutics Inc", c: "US" },
  { id: 4102, s: "NMTR", n: "9 Meters Biopharma Inc", c: "US" },
  { id: 4103, s: "CSAN", n: "Cosan SA ADR", c: "US" },
  { id: 4104, s: "PDN.AX", n: "Paladin Energy", c: "Australia" },
  { id: 4105, s: "FLL", n: "Full House Resorts Inc", c: "US" },
  { id: 4106, s: "EXO.AS", n: "Exor N.V.", c: "Netherlands" },
  { id: 4107, s: "WLMS", n: "Williams Industrial Services Group Inc", c: "US" },
  { id: 4108, s: "LFLY", n: "Leafly Holdings Inc", c: "US" },
  { id: 4109, s: "ENG.MC", n: "Enagás S.A.", c: "Spain" },
  { id: 4110, s: "MERC", n: "Mercer International Inc", c: "US" },
  { id: 4111, s: "AMEH", n: "Apollo Medical Holdings Inc", c: "US" },
  { id: 4112, s: "PHNX.L", n: "Phoenix Group Holdings PLC", c: "UK" },
  { id: 4113, s: "PKE", n: "Park Electrochemical Corporation", c: "US" },
  { id: 4114, s: "SHPW", n: "Shapeways Holdings Inc. Common Stock", c: "US" },
  { id: 4115, s: "CTS", n: "CTS Corporation", c: "US" },
  { id: 4116, s: "LX", n: "Lexinfintech Holdings", c: "US" },
  { id: 4117, s: "PNM", n: "PNM Resources Inc", c: "US" },
  { id: 4118, s: "MYNA", n: "Mynaric AG ADR", c: "US" },
  { id: 4119, s: "SEM", n: "Select Medical Holdings", c: "US" },
  { id: 4120, s: "COOK", n: "Traeger Inc", c: "US" },
  { id: 4121, s: "PW", n: "Power REIT", c: "US" },
  { id: 4122, s: "EZPW", n: "EZCORP Inc", c: "US" },
  { id: 4123, s: "MOGO", n: "Mogo Inc", c: "US" },
  { id: 4124, s: "ALKT", n: "Alkami Technology Inc", c: "US" },
  { id: 4125, s: "601012.SS", n: "Xian LONGi Silicon Materials Co", c: "CH" },
  { id: 4126, s: "SGE.L", n: "Sage Group PLC", c: "UK" },
  { id: 4127, s: "HBIO", n: "Harvard Bioscience Inc", c: "US" },
  { id: 4128, s: "BVXV", n: "BiondVax Pharmaceuticals ADR", c: "US" },
  { id: 4129, s: "VALN", n: "Valneva SE ADR", c: "US" },
  { id: 4130, s: "KE", n: "Kimball Electronics", c: "US" },
  { id: 4131, s: "ISCTR.IS", n: "Turkiye Is Bankasi AS Class C", c: "Turkey" },
  { id: 4132, s: "UPM.HE", n: "UPM-Kymmene Oyj", c: "Finland" },
  { id: 4133, s: "MZDAY", n: "Mazda Motor Corp ADR", c: "US" },
  { id: 4134, s: "STOK", n: "Stoke Therapeutics Inc", c: "US" },
  { id: 4135, s: "DDL", n: "Dingdong ADR", c: "US" },
  { id: 4136, s: "GIC", n: "Global Industrial", c: "US" },
  { id: 4137, s: "RAND", n: "Rand Capital Co", c: "US" },
  { id: 4138, s: "MRIN", n: "Marin Software Inc", c: "US" },
  { id: 4139, s: "PIXY", n: "ShiftPixy Inc", c: "US" },
  { id: 4140, s: "FCPT", n: "Four Corners Property Trust Inc", c: "US" },
  { id: 4141, s: "CCO.TO", n: "Cameco Co", c: "CA" },
  { id: 4142, s: "CAAS", n: "CH Automotive Systems Inc", c: "US" },
  { id: 4143, s: "PETR3.SA", n: "Petróleo Brasileiro S.A. - Petrobras", c: "Brazil" },
  { id: 4144, s: "PAR", n: "PAR Technology Corporation", c: "US" },
  { id: 4145, s: "MNTK", n: "Montauk Renewables Inc", c: "US" },
  { id: 4146, s: "CSTM", n: "Constellium Nv", c: "US" },
  { id: 4147, s: "CIGI", n: "Colliers International Group Inc Bats", c: "US" },
  { id: 4148, s: "IMXI", n: "International Money Express Inc", c: "US" },
  { id: 4149, s: "TY", n: "Tri Continental Closed Fund", c: "US" },
  { id: 4150, s: "AWH", n: "Aspira Womens Health Inc", c: "US" },
  { id: 4151, s: "OTMO", n: "Otonomo Technologies", c: "US" },
  { id: 4152, s: "AP", n: "Ampco-Pittsburgh Corporation", c: "US" },
  { id: 4153, s: "REAX", n: "Real Brokerage Inc", c: "US" },
  { id: 4154, s: "VRTS", n: "Virtus Investment Partners Inc", c: "US" },
  { id: 4155, s: "DH", n: "Definitive Healthcare Co", c: "US" },
  { id: 4156, s: "KTRA", n: "Kintara Therapeutics Inc", c: "US" },
  { id: 4157, s: "NWC.TO", n: "North West Company Inc", c: "CA" },
  { id: 4158, s: "BH", n: "Biglari Holdings Inc", c: "US" },
  { id: 4159, s: "D", n: "Ducommun Incorporated", c: "US" },
  { id: 4160, s: "SOON.SW", n: "Sonova H Ag", c: "Switzerland" },
  { id: 4161, s: "AZN.L", n: "AstraZeneca PLC", c: "UK" },
  { id: 4162, s: "MINM", n: "Minim Inc", c: "US" },
  { id: 4163, s: "LYSCF", n: "Lynas Rare Earths", c: "US" },
  { id: 4164, s: "2603.TW", n: "Evergreen Marine Corp TW", c: "TW" },
  { id: 4165, s: "ANGPY", n: "Anglo American Platinum ADR", c: "US" },
  { id: 4166, s: "ZIMV", n: "ZimVie Inc", c: "US" },
  { id: 4167, s: "MNPR", n: "Monopar Therapeutics Inc", c: "US" },
  { id: 4168, s: "BBLG", n: "Bone Biologics Co", c: "US" },
  { id: 4169, s: "DCBO", n: "Docebo Inc", c: "US" },
  { id: 4170, s: "INBK", n: "First Internet BanCo", c: "US" },
  { id: 4171, s: "AOUT", n: "American Outdoor Brands Inc", c: "US" },
  { id: 4172, s: "QIPT", n: "Quipt Home Medical Co", c: "US" },
  { id: 4173, s: "IAG.L", n: "International Consolidated Airlines Group S.A", c: "UK" },
  { id: 4174, s: "FENC", n: "Fennec Pharmaceuticals Inc", c: "US" },
  { id: 4175, s: "BOL.ST", n: "Boliden AB", c: "Sweden" },
  { id: 4176, s: "GWO.TO", n: "Great-West Lifeco Inc.", c: "CA" },
  { id: 4177, s: "FRST", n: "Primis Financial Co", c: "US" },
  { id: 4178, s: "GRPH", n: "Graphite Bio Inc", c: "US" },
  { id: 4179, s: "PX", n: "P10 Inc", c: "US" },
  { id: 4180, s: "CANF", n: "Can Fite Biopharma ADR", c: "US" },
  { id: 4181, s: "GIFI", n: "Gulf Island Fabrication Inc", c: "US" },
  { id: 4182, s: "CEPU", n: "Central Puerto", c: "US" },
  { id: 4183, s: "CPX.TO", n: "Capital Power Corporation", c: "CA" },
  { id: 4184, s: "FTEK", n: "Fuel Tech Inc", c: "US" },
  { id: 4185, s: "KRNY", n: "Kearny Financial Co", c: "US" },
  { id: 4186, s: "APTX", n: "Aptinyx Inc", c: "US" },
  { id: 4187, s: "SRI", n: "Stoneridge Inc", c: "US" },
  { id: 4188, s: "PRQR", n: "ProQR Therapeutics BV", c: "US" },
  { id: 4189, s: "TEMN.SW", n: "Temenos Group AG", c: "Switzerland" },
  { id: 4190, s: "CDI.PA", n: "Christian Dior SE", c: "FR" },
  { id: 4191, s: "FISI", n: "Financial Institutions Inc", c: "US" },
  { id: 4192, s: "BFI", n: "Burgerfi International Inc", c: "US" },
  { id: 4193, s: "GSHD", n: "Goosehead Insurance Inc", c: "US" },
  { id: 4194, s: "FEAM", n: "5E Advanced Materials Inc", c: "US" },
  { id: 4195, s: "SGML.V", n: "Sigma Lithium Resources Co", c: "CA" },
  { id: 4196, s: "SIBN", n: "Si-Bone Inc", c: "US" },
  { id: 4197, s: "SLGG", n: "Super League Gaming Inc", c: "US" },
  { id: 4198, s: "NL", n: "NL Industries Inc", c: "US" },
  { id: 4199, s: "PXMD", n: "PaxMedica Inc. Common Stock", c: "US" },
  { id: 4200, s: "NMR", n: "Nomura Holdings Inc ADR", c: "US" },
  { id: 4201, s: "BRDG", n: "Bridge Investment Group Holdings Inc", c: "US" },
  { id: 4202, s: "TNT-UN.TO", n: "True North Commercial Real Estate Investment Trust", c: "CA" },
  { id: 4203, s: "SITC", n: "Site Centers Co", c: "US" },
  { id: 4204, s: "BFAM", n: "Bright Horizons Family Solutions Inc", c: "US" },
  { id: 4205, s: "SNCR", n: "Synchronoss Technologies Inc", c: "US" },
  { id: 4206, s: "BMRC", n: "Bank of Marin BanCo", c: "US" },
  { id: 4207, s: "TKAT", n: "Takung Art", c: "US" },
  { id: 4208, s: "DOOR", n: "Masonite International Co", c: "US" },
  { id: 4209, s: "EVBG", n: "Everbridge Inc", c: "US" },
  { id: 4210, s: "FCF", n: "First Commonwealth Financial", c: "US" },
  { id: 4211, s: "VYST", n: "Vystar Co", c: "US" },
  { id: 4212, s: "HLIO", n: "Helios Technologies Inc", c: "US" },
  { id: 4213, s: "GLMD", n: "Galmed Pharmaceuticals", c: "US" },
  { id: 4214, s: "VQS", n: "Viq Solutions Inc", c: "US" },
  { id: 4215, s: "OPRT", n: "Oportun Financial Co", c: "US" },
  { id: 4216, s: "EBR", n: "Centrais Electricas Brasileiras SA", c: "US" },
  { id: 4217, s: "CAP.PA", n: "Capgemini SE", c: "FR" },
  { id: 4218, s: "MHH", n: "Mastech Holdings Inc", c: "US" },
  { id: 4219, s: "JOB", n: "GEE Group Inc", c: "US" },
  { id: 4220, s: "XCUR", n: "Exicure Inc", c: "US" },
  { id: 4221, s: "SOFO", n: "Sonic Foundry Inc", c: "US" },
  { id: 4222, s: "ANIK", n: "Anika Therapeutics Inc", c: "US" },
  { id: 4223, s: "CNSL", n: "Consolidated Communications", c: "US" },
  { id: 4224, s: "EONGY", n: "E.ON SE ADR", c: "US" },
  { id: 4225, s: "MGTA", n: "Magenta Therapeutics Inc", c: "US" },
  { id: 4226, s: "LKFN", n: "Lakeland Financial Corporation", c: "US" },
  { id: 4227, s: "MYNZ", n: "Mainz Biomed BV", c: "US" },
  { id: 4228, s: "EGAN", n: "eGain Corporation", c: "US" },
  { id: 4229, s: "GMAB.", n: "Genmab A/S", c: "Denmark" },
  { id: 4230, s: "GAME", n: "GameSquare Holdings Inc.", c: "US" },
  { id: 4231, s: "CAC", n: "Camden National Corporation", c: "US" },
  { id: 4232, s: "CLAR", n: "Clarus Co", c: "US" },
  { id: 4233, s: "TCON", n: "TRACON Pharmaceuticals Inc", c: "US" },
  { id: 4234, s: "PKI.TO", n: "Parkland Fuel Corporation", c: "CA" },
  { id: 4235, s: "OPRX", n: "OPTIMIZERx Co", c: "US" },
  { id: 4236, s: "REX", n: "REX American Resources Corporation", c: "US" },
  { id: 4237, s: "XIOR.BR", n: "Xior Student Housing NV", c: "Belgium" },
  { id: 4238, s: "BKYI", n: "BIO-Key International Inc", c: "US" },
  { id: 4239, s: "RLGT", n: "Radiant Logistics Inc", c: "US" },
  { id: 4240, s: "NVGS", n: "Navigator Holdings", c: "US" },
  { id: 4241, s: "CHR.", n: "Chr. Hansen Holding A/S", c: "Denmark" },
  { id: 4242, s: "ITGR", n: "Integer Holdings Co", c: "US" },
  { id: 4243, s: "NVCT", n: "Nuvectis Pharma Inc", c: "US" },
  { id: 4244, s: "LABS.TO", n: "Medipharm Labs Co", c: "CA" },
  { id: 4245, s: "NTST", n: "Netstreit Co", c: "US" },
  { id: 4246, s: "SFNC", n: "Simmons First National Corporation", c: "US" },
  { id: 4247, s: "AMUN.PA", n: "Amundi SA", c: "FR" },
  { id: 4248, s: "ESMT", n: "EngageSmart LLC", c: "US" },
  { id: 4249, s: "SLNH", n: "Soluna Holdings Inc", c: "US" },
  { id: 4250, s: "NCM.AX", n: "Newcrest Mining", c: "Australia" },
  { id: 4251, s: "EFSC", n: "Enterprise Financial Services", c: "US" },
  { id: 4252, s: "WCN.TO", n: "Waste Connections Inc", c: "CA" },
  { id: 4253, s: "ARMP", n: "Armata Pharmaceuticals Inc", c: "US" },
  { id: 4254, s: "TRMK", n: "Trustmark Corporation", c: "US" },
  { id: 4255, s: "SDPI", n: "Superior Drilling Products Inc", c: "US" },
  { id: 4256, s: "TBBK", n: "The Bancorp Inc", c: "US" },
  { id: 4257, s: "LITM", n: "Snow Lake Resources", c: "US" },
  { id: 4258, s: "FRD", n: "Friedman Industries Inc", c: "US" },
  { id: 4259, s: "JG", n: "Aurora Mobile", c: "US" },
  { id: 4260, s: "NMRD", n: "Nemaura Medical Inc", c: "US" },
  { id: 4261, s: "DXLG", n: "Destination XL Group Inc", c: "US" },
  { id: 4262, s: "CYRX", n: "Cryoport Inc", c: "US" },
  { id: 4263, s: "HMNY", n: "Helios and Matheson Analytics Inc", c: "US" },
  { id: 4264, s: "ICVX", n: "Icosavax Inc", c: "US" },
  { id: 4265, s: "HTHT", n: "Huazhu Group", c: "US" },
  { id: 4266, s: "ECVT", n: "Ecovyst Inc", c: "US" },
  { id: 4267, s: "HAYW", n: "Hayward Holdings Inc", c: "US" },
  { id: 4268, s: "LEN-B", n: "Lennar Corporation", c: "US" },
  { id: 4269, s: "PFIE", n: "Profire Ene", c: "US" },
  { id: 4270, s: "OWLT", n: "Owlet Inc", c: "US" },
  { id: 4271, s: "EDSA", n: "Edesa Biotech Inc", c: "US" },
  { id: 4272, s: "CNXN", n: "PC Connection Inc", c: "US" },
  { id: 4273, s: "PLMR", n: "Palomar Holdings Inc", c: "US" },
  { id: 4274, s: "BUSE", n: "First Busey Co", c: "US" },
  { id: 4275, s: "000270.KS", n: "Kia Co", c: "Korea" },
  { id: 4276, s: "LPRO", n: "Open Lending Co", c: "US" },
  { id: 4277, s: "BUI", n: "BlackRock Utility & Infrastructure Trust", c: "US" },
  { id: 4278, s: "YCBD", n: "cbdMD Inc", c: "US" },
  { id: 4279, s: "AGL.AX", n: "AGL Energy", c: "Australia" },
  { id: 4280, s: "CSGS", n: "CSG Systems International Inc", c: "US" },
  { id: 4281, s: "VNRX", n: "Volitionrx", c: "US" },
  { id: 4282, s: "TLIS", n: "Talis Biomedical Co", c: "US" },
  { id: 4283, s: "NAB.AX", n: "National Australia Bank", c: "Australia" },
  { id: 4284, s: "OB", n: "Outbrain Inc", c: "US" },
  { id: 4285, s: "MBRX", n: "Moleculin Biotech Inc", c: "US" },
  { id: 4286, s: "TFFP", n: "Tff Pharmaceuticals Inc", c: "US" },
  { id: 4287, s: "NEXA", n: "Nexa Resources SA", c: "US" },
  { id: 4288, s: "DORM", n: "Dorman Products Inc", c: "US" },
  { id: 4289, s: "MOTS", n: "Motus GI Holdings Inc", c: "US" },
  { id: 4290, s: "TTGT", n: "TechTarget Inc", c: "US" },
  { id: 4291, s: "BRN", n: "Barnwell Industries Inc", c: "US" },
  { id: 4292, s: "VERO", n: "Venus Concept Inc", c: "US" },
  { id: 4293, s: "NG.L", n: "National Grid PLC", c: "UK" },
  { id: 4294, s: "WABC", n: "Westamerica Bancorporation", c: "US" },
  { id: 4295, s: "THR", n: "Thermon Group Holdings Inc", c: "US" },
  { id: 4296, s: "FREQ", n: "Frequency Therapeutics Inc", c: "US" },
  { id: 4297, s: "FRCOY", n: "Fast Retailing Co ADR", c: "US" },
  { id: 4298, s: "JBT", n: "John Bean Technologies Corporation", c: "US" },
  { id: 4299, s: "JELD", n: "Jeld-Wen Holding Inc", c: "US" },
  { id: 4300, s: "AMBC", n: "Ambac Financial Group Inc", c: "US" },
  { id: 4301, s: "MNG.L", n: "M&G Plc", c: "UK" },
  { id: 4302, s: "ICFI", n: "ICF International Inc", c: "US" },
  { id: 4303, s: "ORN", n: "Orion Group Holdings Inc", c: "US" },
  { id: 4304, s: "GATO", n: "Gatos Silver Inc", c: "US" },
  { id: 4305, s: "CHDRAUIB.MX", n: "Grupo Comercial Chedraui S.A.B. de C.V", c: "Mexi" },
  { id: 4306, s: "CNTTQ", n: "CannTrust Holdings Inc", c: "US" },
  { id: 4307, s: "PRDO", n: "Perdoceo Education Co", c: "US" },
  { id: 4308, s: "JBI", n: "Janus International Group Inc", c: "US" },
  { id: 4309, s: "AKE.PA", n: "Arkema SA", c: "FR" },
  { id: 4310, s: "HY", n: "Hyster-Yale Materials Handling Inc", c: "US" },
  { id: 4311, s: "YALA", n: "Yalla Group Ltd", c: "US" },
  { id: 4312, s: "FTDR", n: "Frontdoor Inc", c: "US" },
  { id: 4313, s: "LGIH", n: "LGI Homes", c: "US" },
  { id: 4314, s: "BATL", n: "Battalion Oil Co", c: "US" },
  { id: 4315, s: "SFBS", n: "ServisFirst Bancshares Inc", c: "US" },
  { id: 4316, s: "KPN.AS", n: "Koninklijke KPN NV", c: "Netherlands" },
  { id: 4317, s: "ESLT.TA", n: "Elbit Systems", c: "Israel" },
  { id: 4318, s: "PCVX", n: "Vaxcyte Inc", c: "US" },
  { id: 4319, s: "DLNG", n: "Dynagas LNG Partners LP", c: "US" },
  { id: 4320, s: "TBI", n: "TrueBlue Inc", c: "US" },
  { id: 4321, s: "EVGN", n: "Evogene", c: "US" },
  { id: 4322, s: "CETX", n: "Cemtrex Inc", c: "US" },
  { id: 4323, s: "EVVTY", n: "Evolution Gaming Group AB  ADR", c: "US" },
  { id: 4324, s: "CRN.AX", n: "Coronado Global Resources Inc", c: "Australia" },
  { id: 4325, s: "DOLLF", n: "Dolly Varden Silver Corporation", c: "US" },
  { id: 4326, s: "QTCOM.HE", n: "Qt Group Oyj", c: "Finland" },
  { id: 4327, s: "AMWD", n: "American Woodmark Corporation", c: "US" },
  { id: 4328, s: "NXRT", n: "Nexpoint Residential Trust Inc", c: "US" },
  { id: 4329, s: "VLRS", n: "Volaris", c: "US" },
  { id: 4330, s: "DKILY", n: "Daikin Industries ADR", c: "US" },
  { id: 4331, s: "SCU", n: "Sculptor Capital Management Inc", c: "US" },
  { id: 4332, s: "JBGS", n: "JBG SMITH Properties", c: "US" },
  { id: 4333, s: "BHK", n: "BlackRock Core Bond Closed Fund", c: "US" },
  { id: 4334, s: "GLUE", n: "Monte Rosa Therapeutics Inc", c: "US" },
  { id: 4335, s: "EBET", n: "Ebet Inc", c: "US" },
  { id: 4336, s: "EDRY", n: "EuroDry", c: "US" },
  { id: 4337, s: "BF-A", n: "Brown-Forman Corporation", c: "US" },
  { id: 4338, s: "AGN.AS", n: "Aegon NV", c: "Netherlands" },
  { id: 4339, s: "KNT.TO", n: "K92 Mining Inc", c: "CA" },
  { id: 4340, s: "IBTX", n: "Independent Bank Group Inc", c: "US" },
  { id: 4341, s: "TOMZ", n: "TOMI Environmental Solutions Inc", c: "US" },
  { id: 4342, s: "FSP", n: "Franklin Street Properties Co", c: "US" },
  { id: 4343, s: "FUJIY", n: "FUJIFILM Holdings Co", c: "US" },
  { id: 4344, s: "HTRO.ST", n: "Hexatronic Group AB", c: "Sweden" },
  { id: 4345, s: "FRFHF", n: "Fairfax Financial Holdings", c: "US" },
  { id: 4346, s: "CRVL", n: "CorVel Co", c: "US" },
  { id: 4347, s: "FTK.DE", n: "flatexDEGIRO AG", c: "GER" },
  { id: 4348, s: "SAN.MC", n: "Banco Santander", c: "Spain" },
  { id: 4349, s: "SPORTS.MX", n: "Grupo Sports World S.A.B. de C.V", c: "Mexi" },
  { id: 4350, s: "VSEC", n: "VSE Corporation", c: "US" },
  { id: 4351, s: "AFIB", n: "Acutus Medical Inc", c: "US" },
  { id: 4352, s: "GEBN.SW", n: "Geberit AG", c: "Switzerland" },
  { id: 4353, s: "BSAC", n: "Banco Santander Chile", c: "US" },
  { id: 4354, s: "ESE", n: "ESCO Technologies Inc", c: "US" },
  { id: 4355, s: "CF.TO", n: "Canaccord Genuity Group Inc", c: "CA" },
  { id: 4356, s: "DTST", n: "Data Storage Co", c: "US" },
  { id: 4357, s: "KRG", n: "Kite Realty Group Trust", c: "US" },
  { id: 4358, s: "NPK", n: "National Presto Industries Inc", c: "US" },
  { id: 4359, s: "BGR", n: "BlackRock Energy and Resources Closed Fund", c: "US" },
  { id: 4360, s: "GN.", n: "GN Store Nord", c: "Denmark" },
  { id: 4361, s: "GHRS", n: "GH Research PLC", c: "US" },
  { id: 4362, s: "WOW", n: "WideOpenWest Inc", c: "US" },
  { id: 4363, s: "PCYG", n: "Park City Group Inc", c: "US" },
  { id: 4364, s: "LEE", n: "Lee Enterprises Incorporated", c: "US" },
  { id: 4365, s: "PLYM", n: "Plymouth Industrial REIT Inc", c: "US" },
  { id: 4366, s: "KXS.TO", n: "Kinaxis Inc", c: "CA" },
  { id: 4367, s: "MNDI.L", n: "Mondi PLC", c: "UK" },
  { id: 4368, s: "RUBY", n: "Rubius Therapeutics Inc", c: "US" },
  { id: 4369, s: "FREE", n: "Whole Earth Brands Inc", c: "US" },
  { id: 4370, s: "DLOC", n: "Digital Locations Inc", c: "US" },
  { id: 4371, s: "IVN.TO", n: "Ivanhoe Mines.", c: "CA" },
  { id: 4372, s: "AEZS", n: "Aeterna Zentaris Inc", c: "US" },
  { id: 4373, s: "EIG", n: "Employers Holdings Inc", c: "US" },
  { id: 4374, s: "HOWL", n: "Werewolf Therapeutics Inc", c: "US" },
  { id: 4375, s: "ROIC", n: "Retail Opportunity Investments", c: "US" },
  { id: 4376, s: "JRSH", n: "Jerash Holdings US Inc", c: "US" },
  { id: 4377, s: "TACT", n: "TransAct Technologies Incorporated", c: "US" },
  { id: 4378, s: "COCP", n: "Cocrystal Pharma Inc", c: "US" },
  { id: 4379, s: "BZ", n: "Kanzhun ADR", c: "US" },
  { id: 4380, s: "PZA.TO", n: "Pizza Pizza Royalty Corp.", c: "CA" },
  { id: 4381, s: "SERA", n: "Sera Prognostics Inc", c: "US" },
  { id: 4382, s: "CCRD", n: "CoreCard Co", c: "US" },
  { id: 4383, s: "AMBU-B.", n: "Ambu A/S", c: "Denmark" },
  { id: 4384, s: "002594.SZ", n: "BYD Co Class A", c: "CH" },
  { id: 4385, s: "LNSR", n: "LENSAR Inc", c: "US" },
  { id: 4386, s: "HSTO", n: "Histogen Inc", c: "US" },
  { id: 4387, s: "EVH", n: "Evolent Health Inc", c: "US" },
  { id: 4388, s: "WRN", n: "Western Copper and Gold Co", c: "US" },
  { id: 4389, s: "ZTEK", n: "ZEN Graphene Solutions", c: "US" },
  { id: 4390, s: "INDB", n: "Independent Bank", c: "US" },
  { id: 4391, s: "SGO.PA", n: "Compagnie de Saint-Gobain S.A.", c: "FR" },
  { id: 4392, s: "AMOT", n: "Allied Motion Technologies Inc", c: "US" },
  { id: 4393, s: "DSGN", n: "Design Therapeutics Inc", c: "US" },
  { id: 4394, s: "EDUC", n: "Educational Development Corporation", c: "US" },
  { id: 4395, s: "XENE", n: "Xenon Pharmaceuticals Inc", c: "US" },
  { id: 4396, s: "KNEBV.HE", n: "KONE Oyj", c: "Finland" },
  { id: 4397, s: "FTAIN", n: "Fortress Transportation and Preferred Series C", c: "US" },
  { id: 4398, s: "MXCT", n: "MaxCyte Inc", c: "US" },
  { id: 4399, s: "MARPS", n: "Marine Petroleum Trust", c: "US" },
  { id: 4400, s: "NFG.V", n: "New Found Gold Co", c: "CA" },
  { id: 4401, s: "SHYF", n: "Shyft Group Inc", c: "US" },
  { id: 4402, s: "BCTX", n: "Briacell Therapeutics Co", c: "US" },
  { id: 4403, s: "PRCT", n: "Procept Biorobotics Co", c: "US" },
  { id: 4404, s: "ATRC", n: "AtriCure Inc", c: "US" },
  { id: 4405, s: "PXT.TO", n: "Parex Resources Inc", c: "CA" },
  { id: 4406, s: "051910.KS", n: "LG Chemicals", c: "Korea" },
  { id: 4407, s: "ELA", n: "Envela Co", c: "US" },
  { id: 4408, s: "PNTG", n: "Pennant Group Inc", c: "US" },
  { id: 4409, s: "ELE.MC", n: "Endesa SA", c: "Spain" },
  { id: 4410, s: "DYN", n: "Dyne Therapeutics Inc", c: "US" },
  { id: 4411, s: "IMAB", n: "I-Mab", c: "US" },
  { id: 4412, s: "KRBP", n: "Kiromic Biopharma Inc", c: "US" },
  { id: 4413, s: "USPH", n: "US Physicalrapy Inc", c: "US" },
  { id: 4414, s: "ARE.TO", n: "Aecon Group Inc.", c: "CA" },
  { id: 4415, s: "TRIB", n: "Trinity Biotech plc", c: "US" },
  { id: 4416, s: "FLES", n: "4 Less Group Inc", c: "US" },
  { id: 4417, s: "GENE", n: "Genetic Technologies", c: "US" },
  { id: 4418, s: "CBU", n: "Community Bank System Inc", c: "US" },
  { id: 4419, s: "1COV.DE", n: "Covestro AG", c: "GER" },
  { id: 4420, s: "SALM", n: "Salem Media Group Inc", c: "US" },
  { id: 4421, s: "SLR.MC", n: "Solaria Energía y Medio Ambiente S.A", c: "Spain" },
  { id: 4422, s: "BACHOCOB.MX", n: "Industrias Bachoco S.A.B. de C.V", c: "Mexi" },
  { id: 4423, s: "PCRFF", n: "Panasonic Co", c: "US" },
  { id: 4424, s: "APT", n: "Alpha Pro Tech", c: "US" },
  { id: 4425, s: "HGTY", n: "Hagerty Inc", c: "US" },
  { id: 4426, s: "BKTI", n: "BK Technologies Inc", c: "US" },
  { id: 4427, s: "RKT.L", n: "Reckitt Benckiser Group PLC", c: "UK" },
  { id: 4428, s: "LBTYK", n: "Liberty Global PLC Class C", c: "US" },
  { id: 4429, s: "SPB.TO", n: "Superior Plus Co", c: "CA" },
  { id: 4430, s: "XYF", n: "X Financial Class A", c: "US" },
  { id: 4431, s: "HQL", n: "Tekla Life Sciences Investors", c: "US" },
  { id: 4432, s: "CXDO", n: "Crexendo Inc", c: "US" },
  { id: 4433, s: "FUJHY", n: "Subaru Corp ADR", c: "US" },
  { id: 4434, s: "LB.TO", n: "Laurentian Bank Of CA", c: "CA" },
  { id: 4435, s: "MOR", n: "MorphoSys AG ADR", c: "US" },
  { id: 4436, s: "INVO", n: "INVO Bioscience Inc", c: "US" },
  { id: 4437, s: "CMPR", n: "Cimpress NV", c: "US" },
  { id: 4438, s: "MRAI", n: "Marpai Inc", c: "US" },
  { id: 4439, s: "352820.KS", n: "HYBE Co.", c: "Korea" },
  { id: 4440, s: "KESKOB.HE", n: "Kesko Oyj", c: "Finland" },
  { id: 4441, s: "UBFO", n: "United Security Bancshares", c: "US" },
  { id: 4442, s: "SGRO.L", n: "Segro Plc", c: "UK" },
  { id: 4443, s: "APVO", n: "Aptevo Therapeutics Inc", c: "US" },
  { id: 4444, s: "TIL", n: "Tanker Investments", c: "US" },
  { id: 4445, s: "ICAGY", n: "International Consolidated Airlines Group S.A. ADR", c: "US" },
  { id: 4446, s: "BTCY", n: "Biotricity Inc", c: "US" },
  { id: 4447, s: "IDN", n: "Intellicheck Mobilisa Inc", c: "US" },
  { id: 4448, s: "SXI", n: "Standex International Corporation", c: "US" },
  { id: 4449, s: "SEER", n: "Seer Inc", c: "US" },
  { id: 4450, s: "RNLX", n: "Renalytix AI", c: "US" },
  { id: 4451, s: "SOPA", n: "Society Pass Inc", c: "US" },
  { id: 4452, s: "NTRR", n: "Neutra Co", c: "US" },
  { id: 4453, s: "JAPAY", n: "Japan Tobacco ADR", c: "US" },
  { id: 4454, s: "HNOI", n: "HNO International Inc", c: "US" },
  { id: 4455, s: "006400.KS", n: "Samsung SDI", c: "Korea" },
  { id: 4456, s: "EMP-A.TO", n: "Empire Company Limited", c: "CA" },
  { id: 4457, s: "AEY", n: "ADDvantage Technologies Group Inc", c: "US" },
  { id: 4458, s: "WKME", n: "Walkme", c: "US" },
  { id: 4459, s: "SON.LS", n: "Sonae SGPS SA", c: "Portugal" },
  { id: 4460, s: "SGBX", n: "SG Blocks Inc", c: "US" },
  { id: 4461, s: "KAR", n: "KAR Auction Services Inc", c: "US" },
  { id: 4462, s: "LIXT", n: "Lixte Biotechnology Holdings Inc", c: "US" },
  { id: 4463, s: "STC", n: "Stewart Information Services Co", c: "US" },
  { id: 4464, s: "PQEFF", n: "Petroteq Energy Inc", c: "US" },
  { id: 4465, s: "FNGR", n: "FingerMotion Inc", c: "US" },
  { id: 4466, s: "HR-UN.TO", n: "H&R Real Estate Investment Trust", c: "CA" },
  { id: 4467, s: "SRT", n: "StarTek Inc", c: "US" },
  { id: 4468, s: "CBL", n: "CBL & Associates Properties Inc", c: "US" },
  { id: 4469, s: "TNGX", n: "Tango Therapeutics Inc", c: "US" },
  { id: 4470, s: "AAF.L", n: "Airtel Africa Plc", c: "UK" },
  { id: 4471, s: "NUVL", n: "Nuvalent Inc", c: "US" },
  { id: 4472, s: "AXLA", n: "Axcella Health Inc", c: "US" },
  { id: 4473, s: "WES.AX", n: "Wesfarmers", c: "Australia" },
  { id: 4474, s: "HIPO", n: "Hippo Holdings Inc", c: "US" },
  { id: 4475, s: "FEMSAUBD.MX", n: "Fomento Económico Mexicano S.A.B. de C.V", c: "Mexi" },
  { id: 4476, s: "GELYY", n: "Geely Automobile Holdings ADR", c: "US" },
  { id: 4477, s: "ASMB", n: "Assembly Biosciences Inc", c: "US" },
  { id: 4478, s: "ACIU", n: "AC Immune", c: "US" },
  { id: 4479, s: "STKS", n: "One Group Hospitality Inc", c: "US" },
  { id: 4480, s: "FRGI", n: "Fiesta Restaurant Group Inc", c: "US" },
  { id: 4481, s: "COLR.BR", n: "Etablissementen Franz Colruyt NV", c: "Belgium" },
  { id: 4482, s: "HLNE", n: "Hamilton Lane Inc", c: "US" },
  { id: 4483, s: "DCTH", n: "Delcath Systems Inc", c: "US" },
  { id: 4484, s: "ATS", n: "ATS Corporation", c: "US" },
  { id: 4485, s: "NXGN", n: "Nextgen Healthcare Inc", c: "US" },
  { id: 4486, s: "ATXS", n: "Astria Therapeutics Inc", c: "US" },
  { id: 4487, s: "3231.TW", n: "Wistron Co", c: "TW" },
  { id: 4488, s: "CMPO", n: "CompoSecure Inc", c: "US" },
  { id: 4489, s: "NBTB", n: "NBT Bancorp Inc", c: "US" },
  { id: 4490, s: "CIA", n: "Citizens Inc", c: "US" },
  { id: 4491, s: "TRT", n: "Trio-Tech International", c: "US" },
  { id: 4492, s: "2354.TW", n: "Foxconn Technology", c: "TW" },
  { id: 4493, s: "SJ.TO", n: "Stella-Jones Inc.", c: "CA" },
  { id: 4494, s: "PNG.V", n: "Kraken Robotics Inc", c: "CA" },
  { id: 4495, s: "FBP", n: "First BanCo", c: "US" },
  { id: 4496, s: "DFFN", n: "Diffusion Pharmaceuticals Inc", c: "US" },
  { id: 4497, s: "PYCR", n: "Paycor HCM Inc", c: "US" },
  { id: 4498, s: "CUV.AX", n: "Clinuvel Pharmaceuticals", c: "Australia" },
  { id: 4499, s: "BTB-UN.TO", n: "BTB Real Estate Investment Trust", c: "CA" },
  { id: 4500, s: "SILK", n: "Silk Road Medical Inc", c: "US" },
  { id: 4501, s: "SOHU", n: "Sohu.Com Inc", c: "US" },
  { id: 4502, s: "PLC.TO", n: "Park Lawn Co", c: "CA" },
  { id: 4503, s: "RKDA", n: "Arcadia Biosciences Inc", c: "US" },
  { id: 4504, s: "NVG.LS", n: "The Navigator Company SA", c: "Portugal" },
  { id: 4505, s: "LIFCO-B.ST", n: "Lifco AB (publ)", c: "Sweden" },
  { id: 4506, s: "AHT.L", n: "Ashtead Group PLC", c: "UK" },
  { id: 4507, s: "MPCC.OL", n: "MPC Container Ships ASA", c: "Norway" },
  { id: 4508, s: "MTEM", n: "Molecular Templates Inc", c: "US" },
  { id: 4509, s: "TMP", n: "Tompkins Financial Corporation", c: "US" },
  { id: 4510, s: "KUKE", n: "Kuke Music Holding", c: "US" },
  { id: 4511, s: "TEL2-B.ST", n: "Tele2 AB (publ)", c: "Sweden" },
  { id: 4512, s: "JOY.TO", n: "Journey Energy Inc", c: "CA" },
  { id: 4513, s: "ELK.OL", n: "Elkem ASA", c: "Norway" },
  { id: 4514, s: "DIE.BR", n: "D'Ieteren Group SA", c: "Belgium" },
  { id: 4515, s: "ABCB", n: "Ameris BanCo", c: "US" },
  { id: 4516, s: "MNOV", n: "MediciNova Inc", c: "US" },
  { id: 4517, s: "LONN.SW", n: "Lonza Group AG", c: "Switzerland" },
  { id: 4518, s: "AKAN", n: "Akanda Co", c: "US" },
  { id: 4519, s: "LYSDY", n: "Lynas Rare Earths ADR", c: "US" },
  { id: 4520, s: "CAST.ST", n: "Castellum AB", c: "Sweden" },
  { id: 4521, s: "KFRC", n: "Kforce Inc", c: "US" },
  { id: 4522, s: "KN", n: "Knowles Cor", c: "US" },
  { id: 4523, s: "MOZ.TO", n: "Marathon Gold Corporation", c: "CA" },
  { id: 4524, s: "FFIN", n: "First Financial Bankshares Inc", c: "US" },
  { id: 4525, s: "INGA.AS", n: "ING Groep NV", c: "Netherlands" },
  { id: 4526, s: "BCYC", n: "Bicycle Therapeutics", c: "US" },
  { id: 4527, s: "LINK", n: "Interlink Electronics Inc", c: "US" },
  { id: 4528, s: "THRY", n: "Thryv Holdings Inc", c: "US" },
  { id: 4529, s: "PRA", n: "ProAssurance Corporation", c: "US" },
  { id: 4530, s: "CVU", n: "CPI Aerostructures Inc", c: "US" },
  { id: 4531, s: "AREN", n: "Arena Group Holdings Inc", c: "US" },
  { id: 4532, s: "PTSI", n: "PAM Transportation Services Inc", c: "US" },
  { id: 4533, s: "RELI", n: "Reliance Global Group Inc", c: "US" },
  { id: 4534, s: "RHT.V", n: "Reliq Health Technologies Inc", c: "CA" },
  { id: 4535, s: "VALU", n: "Value Line Inc", c: "US" },
  { id: 4536, s: "NEX.PA", n: "Nexans S.A.", c: "FR" },
  { id: 4537, s: "VGZ", n: "Vista Gold Corporation", c: "US" },
  { id: 4538, s: "MAERSK-A.", n: "A.P. Møller - Mærsk A/S", c: "Denmark" },
  { id: 4539, s: "BSQR", n: "BSQUARE Corporation", c: "US" },
  { id: 4540, s: "SQN.SW", n: "Swissquote Group Holding SA", c: "Switzerland" },
  { id: 4541, s: "ADEN.SW", n: "Adecco Group AG Class N", c: "Switzerland" },
  { id: 4542, s: "WNEB", n: "Western New England Bancorp Inc", c: "US" },
  { id: 4543, s: "FORR", n: "Forrester Research Inc", c: "US" },
  { id: 4544, s: "DML.TO", n: "Denison Mines Co", c: "CA" },
  { id: 4545, s: "DALN", n: "Dallasnews Co", c: "US" },
  { id: 4546, s: "MAX", n: "MediaAlpha Inc.", c: "US" },
  { id: 4547, s: "HLMN", n: "Hillman Solutions Co", c: "US" },
  { id: 4548, s: "SOHO", n: "Sotherly Hotels Inc", c: "US" },
  { id: 4549, s: "MBTN.SW", n: "Meyer Burger Tech AG", c: "Switzerland" },
  { id: 4550, s: "PDS", n: "Precision Drilling Corporation", c: "US" },
  { id: 4551, s: "SANN.SW", n: "Santhera Pharmaceuticals Holding AG", c: "Switzerland" },
  { id: 4552, s: "DBTX", n: "Decibel Therapeutics Inc", c: "US" },
  { id: 4553, s: "MIST", n: "Milestone Pharmaceuticals Inc", c: "US" },
  { id: 4554, s: "DATS", n: "DatChat Inc", c: "US" },
  { id: 4555, s: "BTTR", n: "Better Choice Company Inc", c: "US" },
  { id: 4556, s: "NAAS", n: "Naas Technology Inc ADR", c: "US" },
  { id: 4557, s: "JYSK.", n: "Jyske Bank A/S", c: "Denmark" },
  { id: 4558, s: "AUSS.OL", n: "Austevoll Seafood ASA", c: "Norway" },
  { id: 4559, s: "QNST", n: "QuinStreet Inc", c: "US" },
  { id: 4560, s: "PRMRF", n: "Paramount Resources.", c: "US" },
  { id: 4561, s: "CSNA3.SA", n: "Companhia Siderúrgica Nacional", c: "Brazil" },
  { id: 4562, s: "QBIO", n: "Q BioMed Inc", c: "US" },
  { id: 4563, s: "CMAX", n: "CareMax Inc", c: "US" },
  { id: 4564, s: "XELB", n: "Xcel Brands Inc", c: "US" },
  { id: 4565, s: "ISPO", n: "Inspirato Inc", c: "US" },
  { id: 4566, s: "HOMB", n: "Home BancShares Inc", c: "US" },
  { id: 4567, s: "PIM", n: "Putnam Master Intermediate Income Trust", c: "US" },
  { id: 4568, s: "PDYPY", n: "Flutter Entertainment PLC ADR", c: "US" },
  { id: 4569, s: "UAMY", n: "United States Antimony Corporation", c: "US" },
  { id: 4570, s: "CO.PA", n: "Casino Guichard Perrachon SA", c: "FR" },
  { id: 4571, s: "RCAT", n: "Red Cat Holdings Inc", c: "US" },
  { id: 4572, s: "NEWH", n: "Newhydrogen Inc", c: "US" },
  { id: 4573, s: "PCCYF", n: "PetroCH Co Class H", c: "US" },
  { id: 4574, s: "BME", n: "BlackRock Health Sciences Trust", c: "US" },
  { id: 4575, s: "BTO.TO", n: "B2Gold Co", c: "CA" },
  { id: 4576, s: "MYPS", n: "Playstudios Inc", c: "US" },
  { id: 4577, s: "DAVE", n: "Dave Inc", c: "US" },
  { id: 4578, s: "SALT.V", n: "Atlas Salt Inc", c: "CA" },
  { id: 4579, s: "OSW", n: "OneSpaWorld Holdings", c: "US" },
  { id: 4580, s: "RDWR", n: "Radware", c: "US" },
  { id: 4581, s: "BYRN", n: "Byrna Technologies Inc", c: "US" },
  { id: 4582, s: "UEEC", n: "United Health Products Inc", c: "US" },
  { id: 4583, s: "MTX", n: "Minerals Technologies Inc", c: "US" },
  { id: 4584, s: "MYTIL.AT", n: "Mytilineos S.A", c: "Greece" },
  { id: 4585, s: "PGS.OL", n: "PGS ASA", c: "Norway" },
  { id: 4586, s: "UFI", n: "Unifi Inc", c: "US" },
  { id: 4587, s: "RNST", n: "Renasant Corporation", c: "US" },
  { id: 4588, s: "STBA", n: "S&T Bancorp Inc", c: "US" },
  { id: 4589, s: "GPT.AX", n: "GPT Group", c: "Australia" },
  { id: 4590, s: "LRMR", n: "Larimar Therapeutics Inc", c: "US" },
  { id: 4591, s: "TRUE", n: "TrueCar Inc", c: "US" },
  { id: 4592, s: "FR.PA", n: "Valeo SA", c: "FR" },
  { id: 4593, s: "PTPI", n: "Petros Pharmaceuticals Inc", c: "US" },
  { id: 4594, s: "HTLD", n: "Heartland Express Inc", c: "US" },
  { id: 4595, s: "NVEE", n: "NV5 Global Inc", c: "US" },
  { id: 4596, s: "CLW", n: "Clearwater Paper Corporation", c: "US" },
  { id: 4597, s: "OMQS", n: "Omniq Co", c: "US" },
  { id: 4598, s: "TIVC", n: "Tivic Health Systems Inc", c: "US" },
  { id: 4599, s: "CPK", n: "Chesapeake Utilities Corporation", c: "US" },
  { id: 4600, s: "HVN.AX", n: "Harvey Norman Holdings", c: "Australia" },
  { id: 4601, s: "ELYS", n: "Elys Game Technology Co", c: "US" },
  { id: 4602, s: "PETQ", n: "PetIQ Inc", c: "US" },
  { id: 4603, s: "GHSI", n: "Guardion Health Sciences Inc", c: "US" },
  { id: 4604, s: "WFG.TO", n: "West Fraser Timber", c: "CA" },
  { id: 4605, s: "CVV", n: "CVD Equipment Corporation", c: "US" },
  { id: 4606, s: "CHR.TO", n: "Chorus Aviation Inc", c: "CA" },
  { id: 4607, s: "LOAN", n: "Manhattan Bridge Capital Inc", c: "US" },
  { id: 4608, s: "AVTX", n: "Avalo Therapeutics Inc", c: "US" },
  { id: 4609, s: "BJDX", n: "Bluejay Diagnostics Inc", c: "US" },
  { id: 4610, s: "STG.", n: "Scandinavian Tobacco Group A/S", c: "Denmark" },
  { id: 4611, s: "WPRT.TO", n: "Westport Fuel Systems Inc", c: "CA" },
  { id: 4612, s: "GTIM", n: "Good Times Restaurants Inc", c: "US" },
  { id: 4613, s: "LPTH", n: "LightPath Technologies Inc", c: "US" },
  { id: 4614, s: "NOA", n: "North American Construction Group", c: "US" },
  { id: 4615, s: "SLRX", n: "Salarius Pharmaceuticals Inc", c: "US" },
  { id: 4616, s: "NDVA.V", n: "Indiva", c: "CA" },
  { id: 4617, s: "ASTC", n: "Astrotech Co", c: "US" },
  { id: 4618, s: "VEV", n: "Vicinity Motor Co", c: "US" },
  { id: 4619, s: "OOMA", n: "Ooma Inc", c: "US" },
  { id: 4620, s: "AVH.AX", n: "Avita Therapeutics Inc", c: "Australia" },
  { id: 4621, s: "MEG", n: "Montrose Environmental Grp", c: "US" },
  { id: 4622, s: "AVAH", n: "Aveanna Healthcare Holdings Inc", c: "US" },
  { id: 4623, s: "FBNC", n: "First BanCo", c: "US" },
  { id: 4624, s: "CNNE", n: "Cannae Holdings Inc", c: "US" },
  { id: 4625, s: "UTL", n: "UNITIL Corporation", c: "US" },
  { id: 4626, s: "INM", n: "InMed Pharmaceuticals Inc", c: "US" },
  { id: 4627, s: "CWAN", n: "Clearwater Analytics Holdings Inc", c: "US" },
  { id: 4628, s: "SNPMF", n: "CH Petroleum & Chemical Corp Class H", c: "US" },
  { id: 4629, s: "ASAI", n: "Sendas Distribuidora SA", c: "US" },
  { id: 4630, s: "BLFS", n: "BioLife Solutions Inc", c: "US" },
  { id: 4631, s: "NXT.L", n: "Next PLC", c: "UK" },
  { id: 4632, s: "EDN", n: "Empresa Distribuidora y Comercializadora Norte SA ADR", c: "US" },
  { id: 4633, s: "RNGR", n: "Ranger Energy Services Inc", c: "US" },
  { id: 4634, s: "SDF.DE", n: "K+S Aktiengesellschaft", c: "GER" },
  { id: 4635, s: "NABL", n: "N-Able Inc", c: "US" },
  { id: 4636, s: "SHCR", n: "Sharecare Inc", c: "US" },
  { id: 4637, s: "ENTA", n: "Enanta Pharmaceuticals Inc", c: "US" },
  { id: 4638, s: "DRCT", n: "Direct Digital Holdings Inc", c: "US" },
  { id: 4639, s: "PRG", n: "PROG Holdings Inc", c: "US" },
  { id: 4640, s: "UE", n: "Urban Edge Properties", c: "US" },
  { id: 4641, s: "RBCAA", n: "Republic Bancorp Inc", c: "US" },
  { id: 4642, s: "AIRS", n: "Airsculpt Technologies Inc", c: "US" },
  { id: 4643, s: "AMS.MC", n: "Amadeus IT Group S.A", c: "Spain" },
  { id: 4644, s: "BRP", n: "Brp Group Inc", c: "US" },
  { id: 4645, s: "EEX", n: "Emerald Expositions Events Inc", c: "US" },
  { id: 4646, s: "RSI.TO", n: "Rogers Sugar Inc.", c: "CA" },
  { id: 4647, s: "SSTI", n: "Shotspotter Inc", c: "US" },
  { id: 4648, s: "DEMANT.", n: "Demant A/S", c: "Denmark" },
  { id: 4649, s: "GOOS.TO", n: "CA Goose Holdings Inc", c: "CA" },
  { id: 4650, s: "CTV", n: "Innovid Corp.", c: "US" },
  { id: 4651, s: "DCGO", n: "DocGo Inc", c: "US" },
  { id: 4652, s: "KIP.V", n: "Kiplin Metals Inc", c: "CA" },
  { id: 4653, s: "INCR", n: "INC Research Holdings Inc", c: "US" },
  { id: 4654, s: "PROC", n: "Procaps Group SA", c: "US" },
  { id: 4655, s: "PDX.ST", n: "Paradox Interactive AB (publ)", c: "Sweden" },
  { id: 4656, s: "WVE", n: "Wave Life Sciences", c: "US" },
  { id: 4657, s: "AGE", n: "AgeX Therapeutics Inc", c: "US" },
  { id: 4658, s: "SVW.AX", n: "Seven Group Holdings", c: "Australia" },
  { id: 4659, s: "MCS", n: "Marcus Corporation", c: "US" },
  { id: 4660, s: "BHLB", n: "Berkshire Hills Bancorp Inc", c: "US" },
  { id: 4661, s: "SLNG", n: "Stabilis Solutions Inc", c: "US" },
  { id: 4662, s: "TIGO", n: "Millicom International Cellular SA", c: "US" },
  { id: 4663, s: "ADSE", n: "Ads Tec Energy PLC", c: "US" },
  { id: 4664, s: "ACO-X.TO", n: "AT", c: "CA" },
  { id: 4665, s: "CCLP", n: "CSI Compressco LP", c: "US" },
  { id: 4666, s: "TRMLF", n: "Tourmaline Oil Corp.", c: "US" },
  { id: 4667, s: "FSV", n: "FirstService Co", c: "US" },
  { id: 4668, s: "LIND", n: "Lindblad Expeditions Holdings Inc", c: "US" },
  { id: 4669, s: "THRM", n: "Gentherm Inc", c: "US" },
  { id: 4670, s: "UNAM", n: "Unico American Corporation", c: "US" },
  { id: 4671, s: "GURE", n: "Gulf Resources Inc", c: "US" },
  { id: 4672, s: "FBK", n: "FB Financial Co", c: "US" },
  { id: 4673, s: "PYR", n: "PyroGenesis CA Inc.", c: "US" },
  { id: 4674, s: "ABF.L", n: "Associated British Foods PLC", c: "UK" },
  { id: 4675, s: "SECU-B.ST", n: "Securitas AB", c: "Sweden" },
  { id: 4676, s: "MTRN", n: "Materion Corporation", c: "US" },
  { id: 4677, s: "AADI", n: "Aadi Bioscience Inc", c: "US" },
  { id: 4678, s: "CMPX", n: "Compass Therapeutics Inc.", c: "US" },
  { id: 4679, s: "THM", n: "International Tower Hill Mines", c: "US" },
  { id: 4680, s: "PMVP", n: "Pmv Pharmaceuticals Inc", c: "US" },
  { id: 4681, s: "PPBI", n: "Pacific Premier Bancorp Inc", c: "US" },
  { id: 4682, s: "GNSS", n: "Genasys Inc", c: "US" },
  { id: 4683, s: "LVLU", n: "Lulu's Fashion Lounge Holdings Inc", c: "US" },
  { id: 4684, s: "OTEX.TO", n: "Open Text Co", c: "CA" },
  { id: 4685, s: "ECPG", n: "Encore Capital Group Inc", c: "US" },
  { id: 4686, s: "ACR", n: "Acres Commercial Realty Co", c: "US" },
  { id: 4687, s: "LAUR", n: "Laureate Education Inc", c: "US" },
  { id: 4688, s: "SCHL", n: "Scholastic Corporation", c: "US" },
  { id: 4689, s: "CDRE", n: "Cadre Holdings Inc", c: "US" },
  { id: 4690, s: "UNF", n: "Unifirst Corporation", c: "US" },
  { id: 4691, s: "MLP", n: "Maui Land & Pineapple Company Inc", c: "US" },
  { id: 4692, s: "HESAY", n: "Hermes International SA", c: "US" },
  { id: 4693, s: "COLO-B.", n: "Coloplast A/S", c: "Denmark" },
  { id: 4694, s: "FXPO.L", n: "Ferrexpo PLC", c: "UK" },
  { id: 4695, s: "CM", n: "Columbus McKinnon Corporation", c: "US" },
  { id: 4696, s: "CBDL", n: "Cbd Life Sciences Inc", c: "US" },
  { id: 4697, s: "ASRNL.AS", n: "ASR Nederland NV", c: "Netherlands" },
  { id: 4698, s: "EVCM", n: "EverCommerce Inc", c: "US" },
  { id: 4699, s: "SLHN.SW", n: "Swiss Life Holding AG", c: "Switzerland" },
  { id: 4700, s: "ALEX", n: "Alexander & Baldwin Holdings Inc", c: "US" },
  { id: 4701, s: "APX.AX", n: "Appen", c: "Australia" },
  { id: 4702, s: "SNCY", n: "Sun Country Airlines Holdings Inc", c: "US" },
  { id: 4703, s: "CARV", n: "Carver Bancorp Inc", c: "US" },
  { id: 4704, s: "MDA.TO", n: "MDA", c: "CA" },
  { id: 4705, s: "INN", n: "Summit Hotel Properties Inc", c: "US" },
  { id: 4706, s: "SAND.ST", n: "Sandvik AB", c: "Sweden" },
  { id: 4707, s: "LANDP", n: "Gladstone Land Corporation 6.00% Series C Cumulative Redeemable Preferred Stock", c: "US" },
  { id: 4708, s: "002074.SZ", n: "Gotion High tech", c: "CH" },
  { id: 4709, s: "NYC", n: "New York City REIT Inc", c: "US" },
  { id: 4710, s: "FTT.TO", n: "Finning International Inc.", c: "CA" },
  { id: 4711, s: "HNRG", n: "Hallador Energy Company", c: "US" },
  { id: 4712, s: "S.TO", n: "Sherritt International Corporation", c: "CA" },
  { id: 4713, s: "URW.PA", n: "Unibail-Rodamco-Westfield", c: "FR" },
  { id: 4714, s: "MTL.TO", n: "Mullen Group.", c: "CA" },
  { id: 4715, s: "VOLARA.MX", n: "Controladora Vuela Compañía de Aviación S.A.B. de C.V", c: "Mexi" },
  { id: 4716, s: "FLNT", n: "Fluent Inc", c: "US" },
  { id: 4717, s: "CHKR", n: "Chesapeake Granite Wash Trust", c: "US" },
  { id: 4718, s: "CENT", n: "Central Garden & Pet Company", c: "US" },
  { id: 4719, s: "ACS.MC", n: "ACS Actividades de Construccion y Servicios SA", c: "Spain" },
  { id: 4720, s: "NCNA", n: "NuCana PLC", c: "US" },
  { id: 4721, s: "FAST.AS", n: "Fastned B.V.", c: "Netherlands" },
  { id: 4722, s: "AMG.AS", n: "AMG Advanced Metallurgical Group NV", c: "Netherlands" },
  { id: 4723, s: "ATLO", n: "Ames National Corporation", c: "US" },
  { id: 4724, s: "UEIC", n: "Universal Electronics Inc", c: "US" },
  { id: 4725, s: "HOUR", n: "Hour Loop Inc", c: "US" },
  { id: 4726, s: "BRLL", n: "Barrel Energy Inc", c: "US" },
  { id: 4727, s: "AMPG", n: "Amplitech Group Inc", c: "US" },
  { id: 4728, s: "CERE", n: "Cerevel Therapeutics Holdings Inc", c: "US" },
  { id: 4729, s: "DGS.TO", n: "Dividend Growth Split Co", c: "CA" },
  { id: 4730, s: "002230.SZ", n: "Iflytek", c: "CH" },
  { id: 4731, s: "LSF", n: "Laird Superfood Inc", c: "US" },
  { id: 4732, s: "HCDI", n: "Harbor Custom Development Inc", c: "US" },
  { id: 4733, s: "SII", n: "Sprott Inc.", c: "US" },
  { id: 4734, s: "ULH", n: "Universal Logistics Holdings Inc", c: "US" },
  { id: 4735, s: "NGM", n: "NGM Biopharmaceuticals Inc", c: "US" },
  { id: 4736, s: "SVRE", n: "SaverOne 2014. American Depositary Shares", c: "US" },
  { id: 4737, s: "CASI", n: "CASI Pharmaceuticals Inc", c: "US" },
  { id: 4738, s: "AWRE", n: "Aware Inc", c: "US" },
  { id: 4739, s: "ONCT", n: "Oncternal Therapeutics Inc", c: "US" },
  { id: 4740, s: "AGUA.MX", n: "Grupo Rotoplas SAB de CV", c: "Mexi" },
  { id: 4741, s: "WDH", n: "Waterdrop Inc ADR", c: "US" },
  { id: 4742, s: "TIETO.HE", n: "TietoEVRY Co", c: "Finland" },
  { id: 4743, s: "NDRA", n: "ENDRA Life Sciences Inc", c: "US" },
  { id: 4744, s: "ALPMY", n: "Astellas Pharma Inc", c: "US" },
  { id: 4745, s: "CLIR", n: "ClearSign Combustion Corporation", c: "US" },
  { id: 4746, s: "SINGY", n: "Singapore Airlines", c: "US" },
  { id: 4747, s: "CLGN", n: "Collplant Biotechnologies", c: "US" },
  { id: 4748, s: "MRUS", n: "Merus BV", c: "US" },
  { id: 4749, s: "CET", n: "Central Securities Corporation", c: "US" },
  { id: 4750, s: "OEC", n: "Orion Engineered Carbons SA", c: "US" },
  { id: 4751, s: "OMRNY", n: "OMRON Corp ADR", c: "US" },
  { id: 4752, s: "BCOV", n: "Brightcove Inc", c: "US" },
  { id: 4753, s: "RLYB", n: "Rallybio Co", c: "US" },
  { id: 4754, s: "XNCR", n: "Xencor Inc", c: "US" },
  { id: 4755, s: "STAN.L", n: "Standard Chartered PLC", c: "UK" },
  { id: 4756, s: "SKIL", n: "Skillsoft Corp.", c: "US" },
  { id: 4757, s: "ALLT", n: "Allot Communications", c: "US" },
  { id: 4758, s: "AHR.V", n: "Amarc Resources.", c: "CA" },
  { id: 4759, s: "HCAT", n: "Health Catalyst Inc", c: "US" },
  { id: 4760, s: "FSI", n: "Flexible Solutions International Inc", c: "US" },
  { id: 4761, s: "SGC", n: "Superior Uniform Group Inc", c: "US" },
  { id: 4762, s: "CHE-UN.TO", n: "Chemtrade Logistics Income Fund", c: "CA" },
  { id: 4763, s: "VPK.AS", n: "Koninklijke Vopak NV", c: "Netherlands" },
  { id: 4764, s: "PVS.AX", n: "Pivotal Systems Corporation Inc", c: "Australia" },
  { id: 4765, s: "EVTC", n: "Evertec Inc", c: "US" },
  { id: 4766, s: "BLTE", n: "Belite Bio Inc ADR", c: "US" },
  { id: 4767, s: "ALFA.ST", n: "Alfa Laval AB", c: "Sweden" },
  { id: 4768, s: "NHTC", n: "Natural Health Trend", c: "US" },
  { id: 4769, s: "COL.AX", n: "Coles Group", c: "Australia" },
  { id: 4770, s: "HONE", n: "HarborOne Bancorp Inc", c: "US" },
  { id: 4771, s: "ENGH.TO", n: "Enghouse Systems", c: "CA" },
  { id: 4772, s: "PHR", n: "Phreesia Inc", c: "US" },
  { id: 4773, s: "NWPX", n: "Northwest Pipe Company", c: "US" },
  { id: 4774, s: "SCOR", n: "Comscore Inc", c: "US" },
  { id: 4775, s: "BRAG", n: "Bragg Gaming Group Inc", c: "US" },
  { id: 4776, s: "ACNB", n: "ACNB Corporation", c: "US" },
  { id: 4777, s: "FANH", n: "Fanhua Inc", c: "US" },
  { id: 4778, s: "RENE.LS", n: "REN - Redes Energéticas Nacionais SGPS S.A", c: "Portugal" },
  { id: 4779, s: "ANA.MC", n: "Acciona", c: "Spain" },
  { id: 4780, s: "PGHN.SW", n: "Partners Group Holding AG", c: "Switzerland" },
  { id: 4781, s: "CHEMM.", n: "ChemoMetec A/S", c: "Denmark" },
  { id: 4782, s: "CSL.AX", n: "CSL", c: "Australia" },
  { id: 4783, s: "UONEK", n: "Urban One Inc Class D", c: "US" },
  { id: 4784, s: "LLL.AX", n: "Leo Lithium", c: "Australia" },
  { id: 4785, s: "ASLN", n: "Aslan Pharmaceuticals ADR", c: "US" },
  { id: 4786, s: "TIXT.TO", n: "TELUS International", c: "CA" },
  { id: 4787, s: "PHO.OL", n: "Photocure", c: "Norway" },
  { id: 4788, s: "CNC.V", n: "CA Nickel Company Inc", c: "CA" },
  { id: 4789, s: "LUCD", n: "Lucid Diagnostics Inc", c: "US" },
  { id: 4790, s: "PPTA", n: "Perpetua Resources Co", c: "US" },
  { id: 4791, s: "CNTY", n: "Century Casinos Inc", c: "US" },
  { id: 4792, s: "NNVC", n: "NanoViricides Inc", c: "US" },
  { id: 4793, s: "JD.L", n: "JD Sports Fashion PLC", c: "UK" },
  { id: 4794, s: "JWEL.TO", n: "Jamieson Wellness Inc", c: "CA" },
  { id: 4795, s: "TW.L", n: "Taylor Wimpey PLC", c: "UK" },
  { id: 4796, s: "FM.TO", n: "First Quantum Minerals", c: "CA" },
  { id: 4797, s: "SOPH", n: "Sophia Genetics SA", c: "US" },
  { id: 4798, s: "IBOC", n: "International Bancshares Corporation", c: "US" },
  { id: 4799, s: "EKSO", n: "Ekso Bionics Holdings Inc", c: "US" },
  { id: 4800, s: "KICK", n: "HPN Holdings Inc", c: "US" },
  { id: 4801, s: "BATRK", n: "Atlanta Braves Holdings Inc. Series C Common Stock", c: "US" },
  { id: 4802, s: "OGI.TO", n: "OrganiGram Holdings Inc", c: "CA" },
  { id: 4803, s: "RNXT", n: "RenovoRx Inc", c: "US" },
  { id: 4804, s: "STMPA.PA", n: "STMicroelectronics N.V.", c: "FR" },
  { id: 4805, s: "FORTY", n: "Formula Systems 1985 ADR", c: "US" },
  { id: 4806, s: "CMCT", n: "Creative Media & Community Trust Corporation", c: "US" },
  { id: 4807, s: "QUIS.V", n: "Quisitive Technology Solutions Inc", c: "CA" },
  { id: 4808, s: "ATXI", n: "Avenue Therapeutics Inc", c: "US" },
  { id: 4809, s: "IGC", n: "India Globalization Capital Inc", c: "US" },
  { id: 4810, s: "PWSC", n: "Powerschool Holdings Inc", c: "US" },
  { id: 4811, s: "CULP", n: "Culp Inc", c: "US" },
  { id: 4812, s: "RKUNY", n: "Rakuten Inc ADR", c: "US" },
  { id: 4813, s: "TATT", n: "Tat Techno", c: "US" },
  { id: 4814, s: "TAYD", n: "Taylor Devices Inc", c: "US" },
  { id: 4815, s: "CTOS", n: "Custom Truck One Source Inc", c: "US" },
  { id: 4816, s: "BC8.DE", n: "Bechtle AG", c: "GER" },
  { id: 4817, s: "OGEN", n: "Oragenics Inc", c: "US" },
  { id: 4818, s: "AC", n: "Associated Capital Group Inc", c: "US" },
  { id: 4819, s: "TOYOF", n: "Toyota Motor Co", c: "US" },
  { id: 4820, s: "SCWO", n: "374Water Inc. Common Stock", c: "US" },
  { id: 4821, s: "CCC.L", n: "Computacenter PLC", c: "UK" },
  { id: 4822, s: "IBDRY", n: "Iberdrola SA", c: "US" },
  { id: 4823, s: "ECV.DE", n: "Encavis AG", c: "GER" },
  { id: 4824, s: "LEXX", n: "Lexaria Bioscience Co", c: "US" },
  { id: 4825, s: "DXF", n: "Dunxin Financial Holdings", c: "US" },
  { id: 4826, s: "CRMT", n: "Americas Car-Mart Inc", c: "US" },
  { id: 4827, s: "TOELY", n: "Tokyo Electron PK", c: "US" },
  { id: 4828, s: "SEB", n: "Seaboard Corporation", c: "US" },
  { id: 4829, s: "RFIL", n: "RF Industries", c: "US" },
  { id: 4830, s: "BBAJIOO.MX", n: "Banco del Bajío S.A. Institución de Banca Múltiple", c: "Mexi" },
  { id: 4831, s: "BOLT", n: "Bolt Biotherapeutics", c: "US" },
  { id: 4832, s: "EML", n: "Eastern", c: "US" },
  { id: 4833, s: "MGLU3.SA", n: "Magazine Luiza S.A", c: "Brazil" },
  { id: 4834, s: "ATAT", n: "Atour Lifestyle Holdings Limited American Depositary Shares", c: "US" },
  { id: 4835, s: "TWEKA.AS", n: "TKH Group NV", c: "Netherlands" },
  { id: 4836, s: "WN.TO", n: "George Weston Limited", c: "CA" },
  { id: 4837, s: "PETV", n: "PetVivo Holdings Inc", c: "US" },
  { id: 4838, s: "AURA", n: "Aura Biosciences Inc", c: "US" },
  { id: 4839, s: "BDL", n: "Flanigans Enterprises Inc", c: "US" },
  { id: 4840, s: "CFP.TO", n: "Canfor Corporation", c: "CA" },
  { id: 4841, s: "MTRX", n: "Matrix Service", c: "US" },
  { id: 4842, s: "INVE-B.ST", n: "Investor AB ser. B", c: "Sweden" },
  { id: 4843, s: "ENTX", n: "Entera Bio", c: "US" },
  { id: 4844, s: "AGIL", n: "AgileThought Inc", c: "US" },
  { id: 4845, s: "INGN", n: "Inogen Inc", c: "US" },
  { id: 4846, s: "BGSF", n: "BG Staffing Inc", c: "US" },
  { id: 4847, s: "CH", n: "City Holding Company", c: "US" },
  { id: 4848, s: "SCRYY", n: "SCOR PK", c: "US" },
  { id: 4849, s: "CNTB", n: "Connect Biopharma Holdings", c: "US" },
  { id: 4850, s: "SANW", n: "S&W Seed Company", c: "US" },
  { id: 4851, s: "LRLCY", n: "L’Oreal Co ADR", c: "US" },
  { id: 4852, s: "VNCE", n: "Vince Holding Co", c: "US" },
  { id: 4853, s: "VLT", n: "Invesco High Income Trust II", c: "US" },
  { id: 4854, s: "VONOY", n: "Vonovia SE ADR", c: "US" },
  { id: 4855, s: "PLUS.L", n: "Plus500", c: "UK" },
  { id: 4856, s: "HIFS", n: "Hingham Institution for Savings", c: "US" },
  { id: 4857, s: "AMK", n: "AssetMark Financial Holdings Inc", c: "US" },
  { id: 4858, s: "OMAB", n: "Grupo Aeroportuario del Centro Norte SAB de CV", c: "US" },
  { id: 4859, s: "MSBI", n: "Midland States Bancorp Inc", c: "US" },
  { id: 4860, s: "TSBK", n: "Timberland Bancorp Inc", c: "US" },
  { id: 4861, s: "DLHC", n: "DLH Holdings Co", c: "US" },
  { id: 4862, s: "EOLS", n: "Evolus Inc", c: "US" },
  { id: 4863, s: "SUNL", n: "Sunlight Financial Holdings Inc", c: "US" },
  { id: 4864, s: "BPTH", n: "Bio Path Holdings Inc", c: "US" },
  { id: 4865, s: "CPSH", n: "Cps Technologies", c: "US" },
  { id: 4866, s: "HEXA-B.ST", n: "Hexagon AB (publ)", c: "Sweden" },
  { id: 4867, s: "CUERVO.MX", n: "Becle S.A.B. de C.V", c: "Mexi" },
  { id: 4868, s: "HO.PA", n: "Thales S.A.", c: "FR" },
  { id: 4869, s: "AIH", n: "Aesthetic Medical Intl Holding", c: "US" },
  { id: 4870, s: "RPID", n: "Rapid Micro Biosystems Inc", c: "US" },
  { id: 4871, s: "VREX", n: "Varex Imaging Co", c: "US" },
  { id: 4872, s: "SHOT.ST", n: "Scandic Hotels Group AB (publ)", c: "Sweden" },
  { id: 4873, s: "NAOV", n: "NanoVibronix Inc", c: "US" },
  { id: 4874, s: "EDP.LS", n: "EDP - Energias de Portugal S.A.", c: "Portugal" },
  { id: 4875, s: "NURO", n: "NeuroMetrix Inc", c: "US" },
  { id: 4876, s: "RM", n: "Regional Management Co", c: "US" },
  { id: 4877, s: "GHL", n: "Greenhill & Co Inc", c: "US" },
  { id: 4878, s: "MRC", n: "MRC Global Inc", c: "US" },
  { id: 4879, s: "HTLF", n: "Heartland Financial USA Inc", c: "US" },
  { id: 4880, s: "DWF.L", n: "DWF Group PLC", c: "UK" },
  { id: 4881, s: "ATEYY", n: "Advantest Corp DRC", c: "US" },
  { id: 4882, s: "JBH.AX", n: "JB Hi-Fi", c: "Australia" },
  { id: 4883, s: "CARL-B.", n: "Carlsberg A/S", c: "Denmark" },
  { id: 4884, s: "CIZN", n: "Citizens Holding Company", c: "US" },
  { id: 4885, s: "NSYS", n: "Nortech Systems Incorporated", c: "US" },
  { id: 4886, s: "TOM.OL", n: "Tomra Systems ASA", c: "Norway" },
  { id: 4887, s: "FC", n: "Franklin Covey Company", c: "US" },
  { id: 4888, s: "LUN.TO", n: "Lundin Mining Corporation", c: "CA" },
  { id: 4889, s: "XBIO", n: "Xenetic Biosciences Inc", c: "US" },
  { id: 4890, s: "MIR", n: "Mirion Technologies Inc", c: "US" },
  { id: 4891, s: "RFL", n: "Rafael Holdings Inc Class B", c: "US" },
  { id: 4892, s: "INST", n: "Instructure Holdings Inc", c: "US" },
  { id: 4893, s: "NVX.AX", n: "Novonix", c: "Australia" },
  { id: 4894, s: "SYBT", n: "Stock Yards Bancorp Inc", c: "US" },
  { id: 4895, s: "GIB-A.TO", n: "CGI Inc", c: "CA" },
  { id: 4896, s: "MDWT", n: "Midwest Holding Inc", c: "US" },
  { id: 4897, s: "WHEN", n: "World Health Energy", c: "US" },
  { id: 4898, s: "UNCRY", n: "UniCredit SpA ADR", c: "US" },
  { id: 4899, s: "NPNYY", n: "Nippon Yusen Kabushiki Kaisha", c: "US" },
  { id: 4900, s: "XPON", n: "Expion360 Inc", c: "US" },
  { id: 4901, s: "OPBK", n: "OP BanCo", c: "US" },
  { id: 4902, s: "GOGY", n: "Golden Grail Technology Co", c: "US" },
  { id: 4903, s: "CVR", n: "Chicago Rivet & Machine", c: "US" },
  { id: 4904, s: "LNR.TO", n: "Linamar Corporation", c: "CA" },
  { id: 4905, s: "UTMD", n: "Utah Medical Products Inc", c: "US" },
  { id: 4906, s: "3034.TW", n: "Novatek Microelectronics Co", c: "TW" },
  { id: 4907, s: "VWDRY", n: "Vestas Wind Systems AS", c: "US" },
  { id: 4908, s: "SQL", n: "SeqLL Inc", c: "US" },
  { id: 4909, s: "NXPL", n: "Nextplat Co", c: "US" },
  { id: 4910, s: "VMD", n: "Viemed Healthcare Inc", c: "US" },
  { id: 4911, s: "ALGS", n: "Aligos Therapeutics Inc", c: "US" },
  { id: 4912, s: "MYSZ", n: "My Size Inc", c: "US" },
  { id: 4913, s: "UHR.SW", n: "Swatch Group AG", c: "Switzerland" },
  { id: 4914, s: "AWX", n: "Avalon Holdings Corporation", c: "US" },
  { id: 4915, s: "RVSB", n: "Riverview Bancorp Inc", c: "US" },
  { id: 4916, s: "SGA", n: "Saga Communications Inc", c: "US" },
  { id: 4917, s: "STIM", n: "Neuronetics Inc", c: "US" },
  { id: 4918, s: "REED", n: "Reed's Inc", c: "US" },
  { id: 4919, s: "NDM.TO", n: "Northern Dynasty Minerals", c: "CA" },
  { id: 4920, s: "ORLA", n: "Orla Mining", c: "US" },
  { id: 4921, s: "TCL.AX", n: "Transurban Group", c: "Australia" },
  { id: 4922, s: "TCBI", n: "Texas Capital Bancshares Inc", c: "US" },
  { id: 4923, s: "WLN.PA", n: "Worldline SA", c: "FR" },
  { id: 4924, s: "NNOMF", n: "Nano One Materials Co", c: "US" },
  { id: 4925, s: "TRI.TO", n: "Thomson Reuters Co", c: "CA" },
  { id: 4926, s: "IFP.TO", n: "Interfor Co", c: "CA" },
  { id: 4927, s: "CAR-UN.TO", n: "Canadian Apartment Properties Real Estate Investment Trust", c: "CA" },
  { id: 4928, s: "DRH", n: "Diamondrock Hospitality Company", c: "US" },
  { id: 4929, s: "IAG.TO", n: "iA Financial Corporation Inc", c: "CA" },
  { id: 4930, s: "MMMM", n: "Mineral Mountain Mining & Milling", c: "US" },
  { id: 4931, s: "CREX", n: "Creative Realities Inc", c: "US" },
  { id: 4932, s: "DHX", n: "DHI Group Inc", c: "US" },
  { id: 4933, s: "SPRC", n: "Scisparc", c: "US" },
  { id: 4934, s: "KVHI", n: "KVH Industries Inc", c: "US" },
  { id: 4935, s: "SBRY.L", n: "J Sainsbury PLC", c: "UK" },
  { id: 4936, s: "BWEL", n: "Boswell J G", c: "US" },
  { id: 4937, s: "NWG.L", n: "NatWest Group PLC", c: "UK" },
  { id: 4938, s: "CRAI", n: "CRA International Inc", c: "US" },
  { id: 4939, s: "CAS.TO", n: "Cascades Inc.", c: "CA" },
  { id: 4940, s: "SLP", n: "Simulations Plus Inc", c: "US" },
  { id: 4941, s: "EQBK", n: "Equity Bancshares Inc.", c: "US" },
  { id: 4942, s: "TPG.AX", n: "TPG Telecom", c: "Australia" },
  { id: 4943, s: "NTWK", n: "NetSol Technologies Inc", c: "US" },
  { id: 4944, s: "MTY.TO", n: "MTY Food Group Inc", c: "CA" },
  { id: 4945, s: "FEC.TO", n: "Frontera Energy Co", c: "CA" },
  { id: 4946, s: "FXNC", n: "First National Co", c: "US" },
  { id: 4947, s: "SP", n: "SP Plus Co", c: "US" },
  { id: 4948, s: "EFT", n: "Eaton Vance Floating Rate Income Closed Fund", c: "US" },
  { id: 4949, s: "GB", n: "Global Blue Group Holding", c: "US" },
  { id: 4950, s: "CLBK", n: "Columbia Financial Inc", c: "US" },
  { id: 4951, s: "SKYX", n: "SKYX Platforms Co", c: "US" },
  { id: 4952, s: "TFII.TO", n: "TFI International Inc", c: "CA" },
  { id: 4953, s: "SDR.L", n: "Schroders PLC", c: "UK" },
  { id: 4954, s: "WKL.AS", n: "Wolters Kluwer N.V.", c: "Netherlands" },
  { id: 4955, s: "BFIT.AS", n: "Basic Fit NV", c: "Netherlands" },
  { id: 4956, s: "HFWA", n: "Heritage Financial Corporation", c: "US" },
  { id: 4957, s: "DNB.OL", n: "DnB ASA", c: "Norway" },
  { id: 4958, s: "BY", n: "Byline Bancorp Inc", c: "US" },
  { id: 4959, s: "LGVN", n: "Longeveron LLC", c: "US" },
  { id: 4960, s: "LINC", n: "Lincoln Educational Services", c: "US" },
  { id: 4961, s: "ONEX.TO", n: "Onex Co", c: "CA" },
  { id: 4962, s: "VAPO", n: "Vapotherm Inc", c: "US" },
  { id: 4963, s: "CRD-A", n: "Crawford & Company", c: "US" },
  { id: 4964, s: "AMNB", n: "American National Bankshares Inc", c: "US" },
  { id: 4965, s: "EQ", n: "Equillium Inc", c: "US" },
  { id: 4966, s: "OSG", n: "Overseas Shipholding Group Inc", c: "US" },
  { id: 4967, s: "OFIX", n: "Orthofix Medical Inc", c: "US" },
  { id: 4968, s: "FRME", n: "First Merchants Corporation", c: "US" },
  { id: 4969, s: "NUZE", n: "Nuzee Inc", c: "US" },
  { id: 4970, s: "USIO", n: "Usio Inc", c: "US" },
  { id: 4971, s: "NBSE", n: "NeuBase Therapeutics Inc", c: "US" },
  { id: 4972, s: "FENG", n: "Phoenix New Media Limited", c: "US" },
  { id: 4973, s: "LBPH", n: "Longboard Pharmaceuticals Inc", c: "US" },
  { id: 4974, s: "SAF.PA", n: "Safran SA", c: "FR" },
  { id: 4975, s: "WRLD", n: "World Acceptance Corporation", c: "US" },
  { id: 4976, s: "MPV", n: "Barings Participation Investors (the Trust)", c: "US" },
  { id: 4977, s: "TOWN", n: "Towne Bank", c: "US" },
  { id: 4978, s: "VBTX", n: "Veritex Holdings Inc", c: "US" },
  { id: 4979, s: "ITRN", n: "Ituran Location and Control", c: "US" },
  { id: 4980, s: "SNPX", n: "Synaptogenix Inc", c: "US" },
  { id: 4981, s: "LOV", n: "Spark Networks SE", c: "US" },
  { id: 4982, s: "NEPH", n: "Nephros Inc", c: "US" },
  { id: 4983, s: "MSC", n: "Studio City International Holdings", c: "US" },
  { id: 4984, s: "QLGN", n: "Qualigen Therapeutics Inc", c: "US" },
  { id: 4985, s: "DSNKY", n: "Daiichi Sankyo Co ADR", c: "US" },
  { id: 4986, s: "BNRG", n: "Brenmiller Energy Ordinary Shares", c: "US" },
  { id: 4987, s: "RVLP", n: "RVL Pharmaceuticals PLC", c: "US" },
  { id: 4988, s: "MRRTY", n: "Marfrig Global Foods SA", c: "US" },
  { id: 4989, s: "KBC.BR", n: "KBC Groep NV", c: "Belgium" },
  { id: 4990, s: "K.TO", n: "Kinross Gold Co", c: "CA" },
  { id: 4991, s: "SGY.TO", n: "Surge Energy Inc.", c: "CA" },
  { id: 4992, s: "GTCH", n: "GBT Technologies Inc", c: "US" },
  { id: 4993, s: "BHB", n: "Bar Harbor Bankshares Inc", c: "US" },
  { id: 4994, s: "CBDD", n: "Cbd of Denver Inc", c: "US" },
  { id: 4995, s: "PRFX", n: "Painreform Ltd", c: "US" },
  { id: 4996, s: "AIRI", n: "Air Industries Group", c: "US" },
  { id: 4997, s: "GEG", n: "Great Elm Group Inc", c: "US" },
  { id: 4998, s: "ALPN", n: "Alpine Immune Sciences Inc", c: "US" },
  { id: 4999, s: "ISNPY", n: "Intesa Sanpaolo SpA PK", c: "US" },
  { id: 5000, s: "GDEN", n: "Golden Entertainment Inc", c: "US" },
  { id: 5001, s: "AGTI", n: "Agiliti Inc", c: "US" },
  { id: 5002, s: "HWX.TO", n: "Headwater Exploration Inc", c: "CA" },
  { id: 5003, s: "NR", n: "Newpark Resources Inc", c: "US" },
  { id: 5004, s: "HVCW", n: "Harrison Vickers and Waterman Inc", c: "US" },
  { id: 5005, s: "ECTM", n: "ECA Marcellus Trust I Unit", c: "US" },
  { id: 5006, s: "CAE.TO", n: "CAE Inc.", c: "CA" },
  { id: 5007, s: "TYRA", n: "Tyra Biosciences Inc", c: "US" },
  { id: 5008, s: "BELFA", n: "Bel Fuse A Inc", c: "US" },
  { id: 5009, s: "BTCS", n: "BTCS Inc", c: "US" },
  { id: 5010, s: "SAAB-B.ST", n: "Saab AB (publ)", c: "Sweden" },
  { id: 5011, s: "OREA.TO", n: "Orea Mining Co", c: "CA" },
  { id: 5012, s: "RMCF", n: "Rocky Mountain Chocolate Factory", c: "US" },
  { id: 5013, s: "LBAI", n: "Lakeland Bancorp Inc", c: "US" },
  { id: 5014, s: "EVO", n: "Evotec SE ADR", c: "US" },
  { id: 5015, s: "GRG.L", n: "Greggs PLC", c: "UK" },
  { id: 5016, s: "CNA.L", n: "Centrica PLC", c: "UK" },
  { id: 5017, s: "BIMI", n: "Bimi International Medical Inc", c: "US" },
  { id: 5018, s: "TTNDY", n: "Techtronic Industries ADR", c: "US" },
  { id: 5019, s: "HSCS", n: "Heart Test Laboratories Inc. Common Stock", c: "US" },
  { id: 5020, s: "VRDN", n: "Viridian Therapeutics Inc", c: "US" },
  { id: 5021, s: "KBNT", n: "Kubient Inc", c: "US" },
  { id: 5022, s: "IMO.TO", n: "Imperial Oil", c: "CA" },
  { id: 5023, s: "NIBE-B.ST", n: "NIBE Industrier AB (publ)", c: "Sweden" },
  { id: 5024, s: "LCNB", n: "LCNB Corporation", c: "US" },
  { id: 5025, s: "ORGS", n: "Orgenesis Inc", c: "US" },
  { id: 5026, s: "EXPN.L", n: "Experian PLC", c: "UK" },
  { id: 5027, s: "HOCPY", n: "Hoya Co", c: "US" },
  { id: 5028, s: "TWIN", n: "Twin Disc Incorporated", c: "US" },
  { id: 5029, s: "SRT3.DE", n: "Sartorius Aktiengesellschaft", c: "GER" },
  { id: 5030, s: "TLS.AX", n: "Telstra Corporation.", c: "Australia" },
  { id: 5031, s: "VZLA", n: "Vizsla Resources Co", c: "US" },
  { id: 5032, s: "WLFC", n: "Willis Lease Finance Corporation", c: "US" },
  { id: 5033, s: "VOW.OL", n: "Vow ASA", c: "Norway" },
  { id: 5034, s: "GCARSOA1.MX", n: "Grupo Carso S.A.B. de C.V", c: "Mexi" },
  { id: 5035, s: "HBM.TO", n: "HudBay Minerals Inc", c: "CA" },
  { id: 5036, s: "HLMA.L", n: "Halma PLC", c: "UK" },
  { id: 5037, s: "ULBI", n: "Ultralife Corporation", c: "US" },
  { id: 5038, s: "FLXS", n: "Flexsteel Industries Inc", c: "US" },
  { id: 5039, s: "SY", n: "So-Young International Inc", c: "US" },
  { id: 5040, s: "JANX", n: "Janux Therapeutics Inc", c: "US" },
  { id: 5041, s: "NLTX", n: "Neoleukin Therapeutics", c: "US" },
  { id: 5042, s: "BCAB", n: "Bioatla Inc", c: "US" },
  { id: 5043, s: "HFFG", n: "Hf Foods Group Inc", c: "US" },
  { id: 5044, s: "KALV", n: "Kalvista Pharmaceuticals Inc", c: "US" },
  { id: 5045, s: "WSFS", n: "WSFS Financial Corporation", c: "US" },
  { id: 5046, s: "CZNC", n: "Citizens & Northern Co", c: "US" },
  { id: 5047, s: "RMAX", n: "Re Max Holding", c: "US" },
  { id: 5048, s: "LKE.AX", n: "Lake Resources NL", c: "Australia" },
  { id: 5049, s: "035420.KS", n: "Naver Corporation", c: "Korea" },
  { id: 5050, s: "BARN.SW", n: "Barry Callebaut AG", c: "Switzerland" },
  { id: 5051, s: "IINN", n: "Inspira Technologies Oxy BHN", c: "US" },
  { id: 5052, s: "PAAS.TO", n: "Pan American Silver Co", c: "CA" },
  { id: 5053, s: "BAKKA.OL", n: "P/f Bakkafrost", c: "Norway" },
  { id: 5054, s: "BOSC", n: "BOS Better Online Solutions", c: "US" },
  { id: 5055, s: "PCYO", n: "Pure Cycle Corporation", c: "US" },
  { id: 5056, s: "ITRK.L", n: "Intertek Group PLC", c: "UK" },
  { id: 5057, s: "EVF", n: "Eaton Vance Senior Income Closed Fund", c: "US" },
  { id: 5058, s: "NOAH", n: "Noah Holdings", c: "US" },
  { id: 5059, s: "688303.SS", n: "Xinjiang Daqo New Energy", c: "CH" },
  { id: 5060, s: "SCSC", n: "ScanSource Inc", c: "US" },
  { id: 5061, s: "DRUG", n: "Bright Minds Biosciences Inc", c: "US" },
  { id: 5062, s: "STRA", n: "Strategic Education Inc", c: "US" },
  { id: 5063, s: "DAIO", n: "Data I/O Corporation", c: "US" },
  { id: 5064, s: "FIA1S.HE", n: "Finnair Oyj", c: "Finland" },
  { id: 5065, s: "CYCN", n: "Cyclerion Therapeutics Inc", c: "US" },
  { id: 5066, s: "FTHM", n: "Fathom Holdings Inc", c: "US" },
  { id: 5067, s: "GMGI", n: "Golden Matrix Group Inc", c: "US" },
  { id: 5068, s: "CVEO", n: "Civeo Co", c: "US" },
  { id: 5069, s: "SANP", n: "Santo Mining Co", c: "US" },
  { id: 5070, s: "SQFT", n: "Presidio Property Trust", c: "US" },
  { id: 5071, s: "QYOU.V", n: "Qyou Media Inc", c: "CA" },
  { id: 5072, s: "FFBC", n: "First Financial BanCo", c: "US" },
  { id: 5073, s: "CTKYY", n: "CooTek Cayman Inc", c: "US" },
  { id: 5074, s: "ICD", n: "Independence Contract Drilling Inc", c: "US" },
  { id: 5075, s: "IFS", n: "Intercorp Financial Services Inc", c: "US" },
  { id: 5076, s: "SMTI", n: "Sanara Medtech Inc", c: "US" },
  { id: 5077, s: "OBSN.SW", n: "ObsEva SA", c: "Switzerland" },
  { id: 5078, s: "BABY.TO", n: "Else Nutrition Holdings Inc", c: "CA" },
  { id: 5079, s: "SIEB", n: "Siebert Financial Co", c: "US" },
  { id: 5080, s: "SHO", n: "Sunstone Hotel Investors Inc", c: "US" },
  { id: 5081, s: "ICMB", n: "Investcorp Credit Management BDC Inc", c: "US" },
  { id: 5082, s: "AIXA.DE", n: "AIXTRON SE", c: "GER" },
  { id: 5083, s: "TGLO", n: "theglobe.com Inc", c: "US" },
  { id: 5084, s: "ACEL", n: "Accel Entertainment Inc", c: "US" },
  { id: 5085, s: "VIRC", n: "Virco Manufacturing Corporation", c: "US" },
  { id: 5086, s: "FLT.V", n: "Drone Delivery CA Co", c: "CA" },
  { id: 5087, s: "RGF", n: "Real Good Food Company Inc", c: "US" },
  { id: 5088, s: "CYBL", n: "Cyberlux Co", c: "US" },
  { id: 5089, s: "AXR", n: "AMREP Corporation", c: "US" },
  { id: 5090, s: "NEXI", n: "Neximmune Inc", c: "US" },
  { id: 5091, s: "NICE.TA", n: "Nice", c: "Israel" },
  { id: 5092, s: "BANX", n: "Arrowmark Financial Co", c: "US" },
  { id: 5093, s: "AW-UN.TO", n: "A&W Revenue Royalties Income Fund", c: "CA" },
  { id: 5094, s: "SSAB-B.ST", n: "SSAB AB (publ)", c: "Sweden" },
  { id: 5095, s: "TAEE11.SA", n: "Transmissora Aliança de Energia Elétrica S.A", c: "Brazil" },
  { id: 5096, s: "MEL.MC", n: "Melia Hotels", c: "Spain" },
  { id: 5097, s: "IVT", n: "Inventrust Properties Co", c: "US" },
  { id: 5098, s: "KPT.TO", n: "KP Tissue Inc", c: "CA" },
  { id: 5099, s: "RAPT", n: "RAPT Therapeutics Inc", c: "US" },
  { id: 5100, s: "MLAB", n: "Mesa Laboratories Inc", c: "US" },
  { id: 5101, s: "ERC", n: "Allspring Multi Sector Income Fund", c: "US" },
  { id: 5102, s: "NDA.DE", n: "Aurubis AG", c: "GER" },
  { id: 5103, s: "NKL.V", n: "Nickel 28 Capital Co", c: "CA" },
  { id: 5104, s: "NHC.AX", n: "New Hope Corporation", c: "Australia" },
  { id: 5105, s: "IKNA", n: "Ikena Oncology Inc", c: "US" },
  { id: 5106, s: "LQDT", n: "Liquidity Services Inc", c: "US" },
  { id: 5107, s: "TSRI", n: "TSR Inc", c: "US" },
  { id: 5108, s: "WYY", n: "Widepoint C", c: "US" },
  { id: 5109, s: "CPIX", n: "Cumberland Pharmaceuticals Inc", c: "US" },
  { id: 5110, s: "WHLM", n: "Wilhelmina", c: "US" },
  { id: 5111, s: "ALEAF", n: "Aleafia Health Inc", c: "US" },
  { id: 5112, s: "5483.TWO", n: "Sino-American Silicon Products", c: "TW" },
  { id: 5113, s: "AUB", n: "Atlantic Union Bankshares Co", c: "US" },
  { id: 5114, s: "DNP.WA", n: "Dino Polska SA", c: "Poland" },
  { id: 5115, s: "IKT", n: "Inhibikase Therapeutics Inc", c: "US" },
  { id: 5116, s: "BRAG.TO", n: "Bragg Gaming Group Inc", c: "CA" },
  { id: 5117, s: "HRX.TO", n: "Heroux-Devtek Inc.", c: "CA" },
  { id: 5118, s: "MBR.WA", n: "Mo-Bruk SA", c: "Poland" },
  { id: 5119, s: "BDTX", n: "Black Diamond Therapeutics Inc", c: "US" },
  { id: 5120, s: "EQB.TO", n: "Equitable Group Inc.", c: "CA" },
  { id: 5121, s: "SNDA", n: "Sonida Senior Living Inc", c: "US" },
  { id: 5122, s: "LISN.SW", n: "Chocoladefabriken Lindt & Spruengli AG N", c: "Switzerland" },
  { id: 5123, s: "YVR", n: "Liquid Media Group", c: "US" },
  { id: 5124, s: "NIC", n: "Nicolet Bankshares Inc.", c: "US" },
  { id: 5125, s: "SHG", n: "Shinhan Financial Group", c: "US" },
  { id: 5126, s: "ELDN", n: "Eledon Pharmaceuticals Inc", c: "US" },
  { id: 5127, s: "2308.TW", n: "Delta Electronics Inc", c: "TW" },
  { id: 5128, s: "BMRA", n: "Biomerica Inc", c: "US" },
  { id: 5129, s: "FROTO.IS", n: "Ford Otomotiv Sanayi AS", c: "Turkey" },
  { id: 5130, s: "MEOH", n: "Methanex Corporation", c: "US" },
  { id: 5131, s: "SVT", n: "Servotronics Inc", c: "US" },
  { id: 5132, s: "GBTG", n: "Global Business Travel Group Inc", c: "US" },
  { id: 5133, s: "RCO.PA", n: "Remy Cointreau", c: "FR" },
  { id: 5134, s: "BFS", n: "Saul Centers Inc", c: "US" },
  { id: 5135, s: "MIGI", n: "Mawson Infrastructure Group Inc", c: "US" },
  { id: 5136, s: "ARTL", n: "Artelo Biosciences Inc", c: "US" },
  { id: 5137, s: "AGFX.L", n: "Argentex Group PLC", c: "UK" },
  { id: 5138, s: "TSU.TO", n: "Trisura Group", c: "CA" },
  { id: 5139, s: "MIELY", n: "Mitsubishi Electric Corp ADR", c: "US" },
  { id: 5140, s: "DARK.L", n: "Darktrace PLC", c: "UK" },
  { id: 5141, s: "RNECY", n: "Renesas Electronics Corp ADR", c: "US" },
  { id: 5142, s: "GVP", n: "GSE Systems Inc", c: "US" },
  { id: 5143, s: "SGSN.SW", n: "SGS SA", c: "Switzerland" },
  { id: 5144, s: "KNIN.SW", n: "Kuehne & Nagel", c: "Switzerland" },
  { id: 5145, s: "PTK.V", n: "POET Technologies Inc", c: "CA" },
  { id: 5146, s: "NEM.DE", n: "Nemetschek AG O.N.", c: "GER" },
  { id: 5147, s: "KUBTY", n: "Kubota Corp ADR", c: "US" },
  { id: 5148, s: "2303.TW", n: "United Microelectronics Corporation", c: "TW" },
  { id: 5149, s: "MIN", n: "MFS Intermediate Income Trust", c: "US" },
  { id: 5150, s: "MTEX", n: "Mannatech Incorporated", c: "US" },
  { id: 5151, s: "DRIO", n: "DarioHealth Co", c: "US" },
  { id: 5152, s: "NWLI", n: "National Western Life Insurance", c: "US" },
  { id: 5153, s: "SKF-B.ST", n: "AB SKF (publ)", c: "Sweden" },
  { id: 5154, s: "KFFB", n: "Kentucky First Federal BanCo", c: "US" },
  { id: 5155, s: "CWB.TO", n: "Canadian Western Bank", c: "CA" },
  { id: 5156, s: "MBIN", n: "Merchants BanCo", c: "US" },
  { id: 5157, s: "FCBC", n: "First Community Bancshares Inc", c: "US" },
  { id: 5158, s: "CRBN.AS", n: "Corbion NV", c: "Netherlands" },
  { id: 5159, s: "CAE", n: "CAE Inc.", c: "US" },
  { id: 5160, s: "ATCO-A.ST", n: "Atlas Copco AB Series A", c: "Sweden" },
  { id: 5161, s: "FHTX", n: "Foghorn Therapeutics Inc", c: "US" },
  { id: 5162, s: "BDT.TO", n: "Bird Construction Inc.", c: "CA" },
  { id: 5163, s: "PJT", n: "PJT Partners Inc", c: "US" },
  { id: 5164, s: "WAF.DE", n: "Siltronic AG", c: "GER" },
  { id: 5165, s: "SLX.AX", n: "Silex Systems", c: "Australia" },
  { id: 5166, s: "DWNI.DE", n: "Deutsche Wohnen SE", c: "GER" },
  { id: 5167, s: "AKZA.AS", n: "Akzo Nobel NV", c: "Netherlands" },
  { id: 5168, s: "THFF", n: "First Financial Corporation Indiana", c: "US" },
  { id: 5169, s: "IMCC", n: "Im Cannabis Co", c: "US" },
  { id: 5170, s: "CALT", n: "Calliditas Therapeutics", c: "US" },
  { id: 5171, s: "NPSNY", n: "Naspers ADR", c: "US" },
  { id: 5172, s: "BLBX", n: "Blackboxstocks Inc", c: "US" },
  { id: 5173, s: "EN.PA", n: "Bouygues SA", c: "FR" },
  { id: 5174, s: "AVL.TO", n: "Avalon Advanced Materials", c: "CA" },
  { id: 5175, s: "HALL", n: "Hallmark Financial Services Inc", c: "US" },
  { id: 5176, s: "WHLR", n: "Wheeler Real Estate Investment Trust Inc", c: "US" },
  { id: 5177, s: "BBRW", n: "Brewbilt Manufacturing Inc", c: "US" },
  { id: 5178, s: "BBGI", n: "Beasley Broadcast Group Inc", c: "US" },
  { id: 5179, s: "FNOX.ST", n: "Fortnox AB", c: "Sweden" },
  { id: 5180, s: "ESP", n: "Espey Mfg & Electronics Co", c: "US" },
  { id: 5181, s: "HBR.L", n: "Harbour Energy PLC", c: "UK" },
  { id: 5182, s: "XTB.WA", n: "X Trade Brokers Dom Maklerski SA", c: "Poland" },
  { id: 5183, s: "SKA-B.ST", n: "Skanska AB (publ)", c: "Sweden" },
  { id: 5184, s: "ASPU", n: "Aspen Group Inc", c: "US" },
  { id: 5185, s: "GCBC", n: "Greene County Bancorp Inc", c: "US" },
  { id: 5186, s: "300218.SZ", n: "Anhui ANLI Artificial Leather", c: "CH" },
  { id: 5187, s: "TLK", n: "Telkom Indonesia (Persero) Tbk PT ADR Rep 100 B", c: "US" },
  { id: 5188, s: "TMCI", n: "Treace Medical Concepts Inc", c: "US" },
  { id: 5189, s: "LGHL", n: "Lion Group Holding", c: "US" },
  { id: 5190, s: "PHIL", n: "PHI Group Inc", c: "US" },
  { id: 5191, s: "WLDN", n: "Willdan Group Inc", c: "US" },
  { id: 5192, s: "UVSP", n: "Univest Corporation Pennsylvania", c: "US" },
  { id: 5193, s: "PBH", n: "Prestige Brand Holdings Inc", c: "US" },
  { id: 5194, s: "PMT.AX", n: "Patriot Battery Metals Inc.", c: "Australia" },
  { id: 5195, s: "ASELS.IS", n: "Aselsan Elektronik Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 5196, s: "TNON", n: "Tenon Medical Inc", c: "US" },
  { id: 5197, s: "HCMLY", n: "Lafargeholcim ADR", c: "US" },
  { id: 5198, s: "NOM.OL", n: "Nordic Mining ASA", c: "Norway" },
  { id: 5199, s: "ZAP.OL", n: "Zaptec AS", c: "Norway" },
  { id: 5200, s: "AMAL", n: "Amalgamated Bank", c: "US" },
  { id: 5201, s: "RQHTF", n: "Reliq Health Technologies Inc", c: "US" },
  { id: 5202, s: "LAZY", n: "Lazydays Holdings Inc", c: "US" },
  { id: 5203, s: "GENC", n: "Gencor Industries Inc", c: "US" },
  { id: 5204, s: "VRPX", n: "Virpax Pharmaceuticals Inc", c: "US" },
  { id: 5205, s: "WEGE3.SA", n: "WEG S.A", c: "Brazil" },
  { id: 5206, s: "CXO.AX", n: "Core Lithium", c: "Australia" },
  { id: 5207, s: "III.L", n: "3I Group PLC", c: "UK" },
  { id: 5208, s: "CMCM", n: "Cheetah Mobile Inc", c: "US" },
  { id: 5209, s: "VICA", n: "Rafina Innovations Inc", c: "US" },
  { id: 5210, s: "MMI", n: "Marcus & Millichap Inc", c: "US" },
  { id: 5211, s: "SPRB", n: "Spruce Biosciences Inc", c: "US" },
  { id: 5212, s: "ZEN.V", n: "ZEN Graphene Solutions", c: "CA" },
  { id: 5213, s: "HSTM", n: "HealthStream Inc", c: "US" },
  { id: 5214, s: "WRT1V.HE", n: "Wartsila Oyj Abp", c: "Finland" },
  { id: 5215, s: "ALRS", n: "Alerus Financial Co", c: "US" },
  { id: 5216, s: "ALPEKA.MX", n: "ALPEK S.A.B. de C.V", c: "Mexi" },
  { id: 5217, s: "DFIN", n: "Donnelley Financial Solutions Inc", c: "US" },
  { id: 5218, s: "WTC.AX", n: "WiseTech Global Limited", c: "Australia" },
  { id: 5219, s: "FA", n: "First Advantage Co", c: "US" },
  { id: 5220, s: "TCN.TO", n: "Tricon Capital Group Inc", c: "CA" },
  { id: 5221, s: "VJET", n: "Voxeljet Ag", c: "US" },
  { id: 5222, s: "EVD.DE", n: "CTS Eventim AG & Co. KGaA", c: "GER" },
  { id: 5223, s: "TLEVISACPO.MX", n: "Grupo Televisa S.A.B", c: "Mexi" },
  { id: 5224, s: "QRHC", n: "Quest Resource Holding Co", c: "US" },
  { id: 5225, s: "OTGLY", n: "CD Projekt SA", c: "US" },
  { id: 5226, s: "TTSH", n: "Tile Shop Holdings Inc", c: "US" },
  { id: 5227, s: "FET", n: "Forum Energy Technologies Inc", c: "US" },
  { id: 5228, s: "SNC.TO", n: "SNC-Lavalin Group Inc.", c: "CA" },
  { id: 5229, s: "GLTO", n: "Galecto Inc", c: "US" },
  { id: 5230, s: "SESL.PA", n: "SES Imagotag", c: "FR" },
  { id: 5231, s: "KYOCY", n: "Kyocera Corporation ADR", c: "US" },
  { id: 5232, s: "GSI.V", n: "Gatekeeper Systems Inc", c: "CA" },
  { id: 5233, s: "ABR.V", n: "Arbor Metals Co", c: "CA" },
  { id: 5234, s: "YQ", n: "17 Education Technology Group Inc", c: "US" },
  { id: 5235, s: "LEDS", n: "SemiLEDS Corporation", c: "US" },
  { id: 5236, s: "NKR.OL", n: "Nekkar Asa", c: "Norway" },
  { id: 5237, s: "IPWR", n: "Ideal Power Inc", c: "US" },
  { id: 5238, s: "GABC", n: "German American Bancorp Inc", c: "US" },
  { id: 5239, s: "MNRO", n: "Monro Muffler Brake Inc", c: "US" },
  { id: 5240, s: "ZEAL.", n: "Zealand Pharma A/S", c: "Denmark" },
  { id: 5241, s: "PUK", n: "Prudential Public Limited Company", c: "US" },
  { id: 5242, s: "DSGR", n: "Distribution Solutions Group Inc", c: "US" },
  { id: 5243, s: "DRX.TO", n: "ADF Group Inc.", c: "CA" },
  { id: 5244, s: "ITRG", n: "Integra Resources Co", c: "US" },
  { id: 5245, s: "XALL", n: "Xalles Holdings Inc", c: "US" },
  { id: 5246, s: "BGI", n: "Birks Group Inc", c: "US" },
  { id: 5247, s: "ONT.L", n: "Oxford Nanopore Technologies", c: "UK" },
  { id: 5248, s: "SIA.TO", n: "Sienna Senior Living Inc", c: "CA" },
  { id: 5249, s: "MTEK", n: "Maris Tech", c: "US" },
  { id: 5250, s: "UHAL-B", n: "U-Haul Holding Company", c: "US" },
  { id: 5251, s: "DGICA", n: "Donegal Group A Inc", c: "US" },
  { id: 5252, s: "MICS", n: "The Singing Machine Company Inc. Common Stock", c: "US" },
  { id: 5253, s: "MEI.AX", n: "Meteoric Resources NL", c: "Australia" },
  { id: 5254, s: "PYPD", n: "PolyPid", c: "US" },
  { id: 5255, s: "STO.AX", n: "Santos", c: "Australia" },
  { id: 5256, s: "HRTG", n: "Heritage Insurance Hldgs Inc", c: "US" },
  { id: 5257, s: "IPDN", n: "Professional Diversity Network Inc", c: "US" },
  { id: 5258, s: "CPSS", n: "Consumer Portfolio Services Inc", c: "US" },
  { id: 5259, s: "NSPR", n: "InspireMD Inc", c: "US" },
  { id: 5260, s: "VAPR", n: "Vaporbrands Intl Inc", c: "US" },
  { id: 5261, s: "OPAP.AT", n: "Greek Organization of Football Prognostics S.A.", c: "Greece" },
  { id: 5262, s: "EXE.TO", n: "Extendicare Inc", c: "CA" },
  { id: 5263, s: "DXR", n: "Daxor Corporation", c: "US" },
  { id: 5264, s: "INFU", n: "InfuSystems Holdings Inc", c: "US" },
  { id: 5265, s: "NEDAP.AS", n: "NV Nederlandsche Apparatenfabriek Nedap", c: "Netherlands" },
  { id: 5266, s: "THTX", n: "Theratechnologies Inc.", c: "US" },
  { id: 5267, s: "TRUE-B.ST", n: "Truecaller AB (publ)", c: "Sweden" },
  { id: 5268, s: "GMG.AX", n: "Goodman Group", c: "Australia" },
  { id: 5269, s: "TIPT", n: "Tiptree Inc", c: "US" },
  { id: 5270, s: "SOBI.ST", n: "Swedish Orphan Biovitrum AB (publ)", c: "Sweden" },
  { id: 5271, s: "WCH.DE", n: "Wacker Chemie AG", c: "GER" },
  { id: 5272, s: "SKE", n: "Skeena Resources", c: "US" },
  { id: 5273, s: "SOT-UN.TO", n: "Slate Office REIT", c: "CA" },
  { id: 5274, s: "CPU.AX", n: "Computershare.", c: "Australia" },
  { id: 5275, s: "FR.TO", n: "First Majestic Silver Co", c: "CA" },
  { id: 5276, s: "NDX1.DE", n: "Nordex SE", c: "GER" },
  { id: 5277, s: "IAG.MC", n: "International Consolidated Airlines Group S.A", c: "Spain" },
  { id: 5278, s: "MOGU", n: "MOGU Inc", c: "US" },
  { id: 5279, s: "NJDCY", n: "Nidec Corporation", c: "US" },
  { id: 5280, s: "HCM", n: "HUTCHMED  DRC", c: "US" },
  { id: 5281, s: "DBVT", n: "DBV Technologies", c: "US" },
  { id: 5282, s: "QAN.AX", n: "Qantas Airways", c: "Australia" },
  { id: 5283, s: "AMSF", n: "AMERISAFE Inc", c: "US" },
  { id: 5284, s: "MVBF", n: "MVB Financial Co", c: "US" },
  { id: 5285, s: "EDBL", n: "Edible Garden AG Inc", c: "US" },
  { id: 5286, s: "GEOS", n: "Geospace Technologies Corporation", c: "US" },
  { id: 5287, s: "ALC.SW", n: "Alcon AG", c: "Switzerland" },
  { id: 5288, s: "DIR-UN.TO", n: "Dream Industrial Real Estate Investment Trust", c: "CA" },
  { id: 5289, s: "LIVN", n: "LivaNova PLC", c: "US" },
  { id: 5290, s: "BLND.L", n: "British Land Company PLC", c: "UK" },
  { id: 5291, s: "GTLL", n: "Global Techs", c: "US" },
  { id: 5292, s: "CNTG", n: "Centogene B V", c: "US" },
  { id: 5293, s: "DYNT", n: "Dynatronics Corporation", c: "US" },
  { id: 5294, s: "FNLC", n: "First Bancorp Inc", c: "US" },
  { id: 5295, s: "FTFT", n: "Future Fintech Group Inc", c: "US" },
  { id: 5296, s: "ELAL.TA", n: "El Al Israel Airlines", c: "Israel" },
  { id: 5297, s: "NVIV", n: "InVivo Therapeutics Holdings Co", c: "US" },
  { id: 5298, s: "OLB", n: "OLB Group Inc", c: "US" },
  { id: 5299, s: "BNR", n: "Burning Rock Biotech Ltd", c: "US" },
  { id: 5300, s: "STAF", n: "Staffing 360 Solutions Inc", c: "US" },
  { id: 5301, s: "DDI", n: "Doubledown Interactive ", c: "US" },
  { id: 5302, s: "AUID", n: "Ipsidy Inc", c: "US" },
  { id: 5303, s: "CPHI", n: "CH Pharma Holdings Inc", c: "US" },
  { id: 5304, s: "HEO.TO", n: "H2O Innovation Inc", c: "CA" },
  { id: 5305, s: "NUVB", n: "Nuvation Bio Inc", c: "US" },
  { id: 5306, s: "PFMT", n: "Performant Financial Corporation", c: "US" },
  { id: 5307, s: "TCN", n: "Tricon Capital Group Inc", c: "US" },
  { id: 5308, s: "FL.V", n: "Frontier Lithium Inc", c: "CA" },
  { id: 5309, s: "ACKB.BR", n: "Ackermans & Van Haaren NV", c: "Belgium" },
  { id: 5310, s: "BEI.DE", n: "Beiersdorf Aktiengesellschaft", c: "GER" },
  { id: 5311, s: "IIIV", n: "i3 Verticals Inc", c: "US" },
  { id: 5312, s: "FPH", n: "Five Point Holdings LLC", c: "US" },
  { id: 5313, s: "YAMCY", n: "Yamaha Corp DRC", c: "US" },
  { id: 5314, s: "MODV", n: "ModivCare Inc", c: "US" },
  { id: 5315, s: "FING-B.ST", n: "Fingerprint Cards AB (publ)", c: "Sweden" },
  { id: 5316, s: "MF", n: "Missfresh ADR", c: "US" },
  { id: 5317, s: "BOTJ", n: "Bank of the James Financial Group", c: "US" },
  { id: 5318, s: "FINW", n: "Finwise BanCo", c: "US" },
  { id: 5319, s: "OSK.TO", n: "Osisko Mining Co", c: "CA" },
  { id: 5320, s: "EUROB.AT", n: "Eurobank Ergasias Services and Holdings S.A.", c: "Greece" },
  { id: 5321, s: "ID", n: "PARTS iD Inc", c: "US" },
  { id: 5322, s: "ARCLK.IS", n: "Arcelik AS", c: "Turkey" },
  { id: 5323, s: "AKO-B", n: "Embotelladora Andina S.A", c: "US" },
  { id: 5324, s: "PRDSY", n: "Prada Spa PK", c: "US" },
  { id: 5325, s: "GBLB.BR", n: "Groep Brussel Lambert NV", c: "Belgium" },
  { id: 5326, s: "TYG", n: "Tortoise Energy Infrastructure Closed Fund", c: "US" },
  { id: 5327, s: "ERBB", n: "American Green Inc", c: "US" },
  { id: 5328, s: "PATI", n: "Patriot Transportation Holding Inc", c: "US" },
  { id: 5329, s: "CRE.V", n: "Critical Elements Corporation", c: "CA" },
  { id: 5330, s: "APYP", n: "AppYea Inc", c: "US" },
  { id: 5331, s: "CZG.PR", n: "Colt CZ Group SE", c: "Czech Republic" },
  { id: 5332, s: "BOE.AX", n: "Boss Energy Limited", c: "Australia" },
  { id: 5333, s: "IMM.AX", n: "Immutep", c: "Australia" },
  { id: 5334, s: "CCOLA.IS", n: "Coca-Cola Icecek AS", c: "Turkey" },
  { id: 5335, s: "JMAT.L", n: "Johnson Matthey PLC", c: "UK" },
  { id: 5336, s: "AE", n: "Adams Resources & Energy Inc", c: "US" },
  { id: 5337, s: "YTEN", n: "Yield10 Bioscience Inc", c: "US" },
  { id: 5338, s: "LEGH", n: "Legacy Housing Co", c: "US" },
  { id: 5339, s: "MMT", n: "MFS Multimarket Income Trust", c: "US" },
  { id: 5340, s: "NGMS", n: "Neogames SA", c: "US" },
  { id: 5341, s: "SNPO", n: "Snap One Holdings Co", c: "US" },
  { id: 5342, s: "OFLX", n: "Omega Flex Inc", c: "US" },
  { id: 5343, s: "FBRX", n: "Forte Biosciences Inc", c: "US" },
  { id: 5344, s: "SPLP", n: "Steel Partners Holdings LP", c: "US" },
  { id: 5345, s: "OPTI", n: "Optec International Inc", c: "US" },
  { id: 5346, s: "LEG.DE", n: "LEG Immobilien SE", c: "GER" },
  { id: 5347, s: "ASTA", n: "Astra Veda Corporation", c: "US" },
  { id: 5348, s: "AHI.AX", n: "Advanced Health Intelligence", c: "Australia" },
  { id: 5349, s: "ASAI.ST", n: "Artificial Solutions International AB (publ)", c: "Sweden" },
  { id: 5350, s: "BEZQ.TA", n: "Bezeq Israeli Telecommunication Co", c: "Israel" },
  { id: 5351, s: "S32.AX", n: "South32", c: "Australia" },
  { id: 5352, s: "LFVN", n: "Lifevantage Corporation", c: "US" },
  { id: 5353, s: "BIRG.IR", n: "Bank of Ireland Group PLC", c: "Ireland" },
  { id: 5354, s: "SASI", n: "Sigma Labs Inc", c: "US" },
  { id: 5355, s: "VPER", n: "Viper Networks Inc", c: "US" },
  { id: 5356, s: "LIAB.ST", n: "Lindab International AB", c: "Sweden" },
  { id: 5357, s: "IDS.L", n: "Royal Mail PLC", c: "UK" },
  { id: 5358, s: "DYL.AX", n: "Deep Yellow", c: "Australia" },
  { id: 5359, s: "FRLN", n: "Freeline Therapeutics Holdings Plc", c: "US" },
  { id: 5360, s: "TPET", n: "Trio Petroleum Corp.", c: "US" },
  { id: 5361, s: "JDEP.AS", n: "Jde Peets Nv", c: "Netherlands" },
  { id: 5362, s: "HEI-A", n: "HEICO Corporation", c: "US" },
  { id: 5363, s: "MLNK", n: "Meridianlink Inc", c: "US" },
  { id: 5364, s: "POLI.TA", n: "Bank Hapoalim", c: "Israel" },
  { id: 5365, s: "ESSA", n: "ESSA Bancorp Inc", c: "US" },
  { id: 5366, s: "OVLY", n: "Oak Valley BanCo", c: "US" },
  { id: 5367, s: "SKG.L", n: "Smurfit Kappa Group PLC", c: "UK" },
  { id: 5368, s: "HERDEZ.MX", n: "Grupo Herdez S.A.B. de C.V", c: "Mexi" },
  { id: 5369, s: "XPRO", n: "Expro Group Holdings NV", c: "US" },
  { id: 5370, s: "SFTBF", n: "Softbank Group Corp.", c: "US" },
  { id: 5371, s: "PDLB", n: "Ponce Financial Group Inc", c: "US" },
  { id: 5372, s: "NGPHF", n: "Northern Graphite Corporation", c: "US" },
  { id: 5373, s: "FN.TO", n: "First National Financial Co", c: "CA" },
  { id: 5374, s: "VALMT.HE", n: "Valmet Oyj", c: "Finland" },
  { id: 5375, s: "TRNS", n: "Transcat Inc", c: "US" },
  { id: 5376, s: "ELMD", n: "Electromed Inc", c: "US" },
  { id: 5377, s: "LTR.AX", n: "Liontown Resources", c: "Australia" },
  { id: 5378, s: "EFR", n: "Eaton Vance Senior Floating Rate Closed Fund", c: "US" },
  { id: 5379, s: "MAP.MC", n: "Mapfre", c: "Spain" },
  { id: 5380, s: "ISSC", n: "Innovative Solutions and Support", c: "US" },
  { id: 5381, s: "SBCF", n: "Seacoast Banking Corporation of Florida", c: "US" },
  { id: 5382, s: "LBS.TO", n: "Life & Banc Split Co", c: "CA" },
  { id: 5383, s: "GRA.TO", n: "NanoXplore Inc", c: "CA" },
  { id: 5384, s: "UU.L", n: "United Utilities Group PLC", c: "UK" },
  { id: 5385, s: "VTKLY", n: "VTech Holdings ADR", c: "US" },
  { id: 5386, s: "RBB", n: "RBB BanCo", c: "US" },
  { id: 5387, s: "APRE", n: "Aprea Therapeutics Inc", c: "US" },
  { id: 5388, s: "HMN", n: "Horace Mann Educators Corporation", c: "US" },
  { id: 5389, s: "BPTS", n: "Biophytis", c: "US" },
  { id: 5390, s: "IHT", n: "InnSuites Hospitality Trust", c: "US" },
  { id: 5391, s: "AIM.TO", n: "Aimia Inc", c: "CA" },
  { id: 5392, s: "DLA", n: "Delta Apparel Inc", c: "US" },
  { id: 5393, s: "CUEN", n: "Cuentas Inc", c: "US" },
  { id: 5394, s: "KGF.L", n: "Kingfisher PLC", c: "UK" },
  { id: 5395, s: "HCKT", n: "The Hackett Group Inc", c: "US" },
  { id: 5396, s: "CNOB", n: "ConnectOne Bancorp Inc", c: "US" },
  { id: 5397, s: "DBSDY", n: "DBS Group Holdings ADR", c: "US" },
  { id: 5398, s: "NVNO", n: "enVVeno Medical Co", c: "US" },
  { id: 5399, s: "GRF.MC", n: "Grifols S.A", c: "Spain" },
  { id: 5400, s: "066570.KS", n: "LG Electronics Inc", c: "Korea" },
  { id: 5401, s: "NRIM", n: "Northrim BanCorp Inc", c: "US" },
  { id: 5402, s: "PCSA", n: "Processa Pharmaceuticals Inc", c: "US" },
  { id: 5403, s: "WG.L", n: "John Wood Group PLC", c: "UK" },
  { id: 5404, s: "UCB.BR", n: "UCB SA", c: "Belgium" },
  { id: 5405, s: "NTDTY", n: "NTT Data Corp ADR", c: "US" },
  { id: 5406, s: "BAR.BR", n: "Barco NV", c: "Belgium" },
  { id: 5407, s: "CCSI", n: "Consensus Cloud Solutions Inc", c: "US" },
  { id: 5408, s: "PE&OLES.MX", n: "Industrias Peñoles S.A.B. de C.V", c: "Mexi" },
  { id: 5409, s: "AEF", n: "Abrdn Emerging Markets Equity Income Fund Inc", c: "US" },
  { id: 5410, s: "NEPT", n: "Neptune Wellness Solutions Inc", c: "US" },
  { id: 5411, s: "NTZ", n: "Natuzzi SpA", c: "US" },
  { id: 5412, s: "GRAT.V", n: "Gratomic Inc", c: "CA" },
  { id: 5413, s: "DOO.TO", n: "BRP Inc", c: "CA" },
  { id: 5414, s: "SST", n: "System1 Inc", c: "US" },
  { id: 5415, s: "JTKWY", n: "Just Eat Takeaway.com NV ADR", c: "US" },
  { id: 5416, s: "688599.SS", n: "Trina Solar", c: "CH" },
  { id: 5417, s: "RSSS", n: "Research Solutions Inc", c: "US" },
  { id: 5418, s: "SHEN", n: "Shenandoah Telecommunications", c: "US" },
  { id: 5419, s: "MFH", n: "Mercurity Fintech Holding Inc ADR", c: "US" },
  { id: 5420, s: "BEI-UN.TO", n: "Boardwalk Real Estate Investment Trust", c: "CA" },
  { id: 5421, s: "EPR.OL", n: "Europris ASA", c: "Norway" },
  { id: 5422, s: "SNSE", n: "Sensei Biotherapeutics Inc", c: "US" },
  { id: 5423, s: "SCHA.OL", n: "Schibsted ASA A", c: "Norway" },
  { id: 5424, s: "KTCC", n: "Key Tronic Corporation", c: "US" },
  { id: 5425, s: "AEI", n: "Alset Ehome International Inc", c: "US" },
  { id: 5426, s: "SKYH", n: "Sky Harbour Group Co", c: "US" },
  { id: 5427, s: "RSLS", n: "ReShape Lifesciences Inc", c: "US" },
  { id: 5428, s: "CFB", n: "CrossFirst Bankshares Inc", c: "US" },
  { id: 5429, s: "KIDS", n: "Orthopediatrics Co", c: "US" },
  { id: 5430, s: "VIOT", n: "Viomi Technology ADR", c: "US" },
  { id: 5431, s: "SHECY", n: "Shin-Etsu Chemical Co ADR", c: "US" },
  { id: 5432, s: "DOAS.IS", n: "Dogus Otomotiv Servis ve Ticaret AS", c: "Turkey" },
  { id: 5433, s: "VACC", n: "Vaccitech PLC ADR", c: "US" },
  { id: 5434, s: "SGAMY", n: "Sega Sammy Holdings Inc ADR", c: "US" },
  { id: 5435, s: "CING", n: "Cingulate Inc", c: "US" },
  { id: 5436, s: "INDP", n: "Indaptus Therapeutics Inc", c: "US" },
  { id: 5437, s: "ZGN", n: "Ermenegildo Zegna NV", c: "US" },
  { id: 5438, s: "B3SA3.SA", n: "B3 S.A. - Brasil Bolsa Balcão", c: "Brazil" },
  { id: 5439, s: "INO-UN.TO", n: "Inovalis Real Estate Investment Trust", c: "CA" },
  { id: 5440, s: "MYTE", n: "MYT Netherlands Parent BV", c: "US" },
  { id: 5441, s: "SNT", n: "Senstar Technologies", c: "US" },
  { id: 5442, s: "COFB.BR", n: "Cofinimmo SA", c: "Belgium" },
  { id: 5443, s: "SPFI", n: "South Plains Financial Inc", c: "US" },
  { id: 5444, s: "WVVI", n: "Willamette Valley Vineyards Inc", c: "US" },
  { id: 5445, s: "CREG", n: "Smart Powerr Co", c: "US" },
  { id: 5446, s: "BMTX", n: "Bm Technologies Inc", c: "US" },
  { id: 5447, s: "NIC.AX", n: "Nickel Industries Limited", c: "Australia" },
  { id: 5448, s: "TRYG.", n: "Tryg A/S", c: "Denmark" },
  { id: 5449, s: "ALPHA.AT", n: "Alpha Services and Holdings S.A.", c: "Greece" },
  { id: 5450, s: "PFSI", n: "PennyMac Finl Svcs Inc", c: "US" },
  { id: 5451, s: "SGL.DE", n: "SGL Carbon SE", c: "GER" },
  { id: 5452, s: "005380.KS", n: "Hyundai Motor", c: "Korea" },
  { id: 5453, s: "NRSN", n: "Neurosense Therapeutics", c: "US" },
  { id: 5454, s: "SNPW", n: "Sun Pacific Holding Co", c: "US" },
  { id: 5455, s: "SCGLY", n: "Societe Generale ADR", c: "US" },
  { id: 5456, s: "BVI.PA", n: "Bureau Veritas SA", c: "FR" },
  { id: 5457, s: "CIVB", n: "Civista Bancshares Inc", c: "US" },
  { id: 5458, s: "ESQ", n: "Esquire Financial Holdings Inc", c: "US" },
  { id: 5459, s: "ALL.AX", n: "Aristocrat Leisure", c: "Australia" },
  { id: 5460, s: "MFG.AX", n: "Magellan Financial Group", c: "Australia" },
  { id: 5461, s: "NTTYY", n: "Nippon Telegraph and Telephone Corp ADR", c: "US" },
  { id: 5462, s: "PPBT", n: "Purple Biotech", c: "US" },
  { id: 5463, s: "CCNE", n: "CNB Financial Corporation", c: "US" },
  { id: 5464, s: "CHMG", n: "Chemung Financial Co", c: "US" },
  { id: 5465, s: "FRBA", n: "First Bank", c: "US" },
  { id: 5466, s: "PNL.AS", n: "PostNL NV", c: "Netherlands" },
  { id: 5467, s: "ORRF", n: "Orrstown Financial Services Inc", c: "US" },
  { id: 5468, s: "STMN.SW", n: "Straumann Holding AG", c: "Switzerland" },
  { id: 5469, s: "FUTR.L", n: "Future PLC", c: "UK" },
  { id: 5470, s: "EUZ.DE", n: "Eckert & Ziegler Strahlen- und Medizintechnik AG", c: "GER" },
  { id: 5471, s: "QBR-B.TO", n: "Quebecor Inc", c: "CA" },
  { id: 5472, s: "WOSG.L", n: "Watches Of Switzerland Group PLC", c: "UK" },
  { id: 5473, s: "GAPB.MX", n: "Grupo Aeroportuario del Pacífico S.A.B. de C.V", c: "Mexi" },
  { id: 5474, s: "DNO.OL", n: "Dno ASA", c: "Norway" },
  { id: 5475, s: "KERN", n: "Akerna Co", c: "US" },
  { id: 5476, s: "WAWI.OL", n: "Wallenius Wilhelmsen Logistics ASA", c: "Norway" },
  { id: 5477, s: "GBAB", n: "Guggenheim Taxable Municipal Managed Duration Trust", c: "US" },
  { id: 5478, s: "HCI", n: "HCI Group Inc", c: "US" },
  { id: 5479, s: "HITI.V", n: "High Tide Inc", c: "CA" },
  { id: 5480, s: "AXXA", n: "RCABS Inc", c: "US" },
  { id: 5481, s: "BBTV.TO", n: "BBTV Holdings Inc", c: "CA" },
  { id: 5482, s: "SHL.AX", n: "Sonic Healthcare", c: "Australia" },
  { id: 5483, s: "DIBS", n: "1Stdibs.Com Inc", c: "US" },
  { id: 5484, s: "ITP", n: "IT Tech Packaging Inc", c: "US" },
  { id: 5485, s: "ORG.AX", n: "Origin Energy", c: "Australia" },
  { id: 5486, s: "STR.VI", n: "Strabag SE", c: "Austria" },
  { id: 5487, s: "000333.SZ", n: "Midea Group", c: "CH" },
  { id: 5488, s: "CMTG", n: "Claros Mortgage Trust Inc", c: "US" },
  { id: 5489, s: "HCANF", n: "Halo Collective Inc", c: "US" },
  { id: 5490, s: "EWTX", n: "Edgewise Therapeutics Inc", c: "US" },
  { id: 5491, s: "FGF", n: "FG Financial Group", c: "US" },
  { id: 5492, s: "OKYO", n: "OKYO Pharma ADR", c: "US" },
  { id: 5493, s: "QBE.AX", n: "Qbe Insurance Group", c: "Australia" },
  { id: 5494, s: "GCTK", n: "GlucoTrack Inc", c: "US" },
  { id: 5495, s: "TWOH", n: "Two Hands Co", c: "US" },
  { id: 5496, s: "GAW.L", n: "Games Workshop Group PLC", c: "UK" },
  { id: 5497, s: "BFST", n: "Business First Bancshares Inc", c: "US" },
  { id: 5498, s: "GIGM", n: "Giga Media", c: "US" },
  { id: 5499, s: "MDRR", n: "Medalist Diversified Reit Inc", c: "US" },
  { id: 5500, s: "FMAO", n: "Farmers & Merchants Bancorp Inc", c: "US" },
  { id: 5501, s: "NLSP", n: "NLS Pharmaceutics AG", c: "US" },
  { id: 5502, s: "BALD-B.ST", n: "Fastighets AB Balder (publ)", c: "Sweden" },
  { id: 5503, s: "GBIO", n: "Generation Bio ", c: "US" },
  { id: 5504, s: "QEDN", n: "Qed Connect Inc", c: "US" },
  { id: 5505, s: "STAR-B.ST", n: "Starbreeze AB (publ)", c: "Sweden" },
  { id: 5506, s: "CMRA", n: "Comera Life Sciences Holdings Inc", c: "US" },
  { id: 5507, s: "ATLC", n: "Atlanticus Holdings Corporation", c: "US" },
  { id: 5508, s: "IMRN", n: "Immuron ADR", c: "US" },
  { id: 5509, s: "STB.OL", n: "Storebrand ASA", c: "Norway" },
  { id: 5510, s: "LCE.V", n: "Century Lithium Corp.", c: "CA" },
  { id: 5511, s: "QFOR.TO", n: "Q4 Inc", c: "CA" },
  { id: 5512, s: "ILST", n: "Intl Star Inc", c: "US" },
  { id: 5513, s: "SIF", n: "SIFCO Industries Inc", c: "US" },
  { id: 5514, s: "BPOST.BR", n: "Bpost NV", c: "Belgium" },
  { id: 5515, s: "ESI.TO", n: "Ensign Energy Services Inc", c: "CA" },
  { id: 5516, s: "JUN3.DE", n: "Jungheinrich AG O.N.VZO", c: "GER" },
  { id: 5517, s: "MCR", n: "MFS Charter Income Trust", c: "US" },
  { id: 5518, s: "WNDW", n: "SolarWindow Technologies Inc", c: "US" },
  { id: 5519, s: "FPAY", n: "FlexShopper Inc", c: "US" },
  { id: 5520, s: "MLX.AX", n: "Metals X", c: "Australia" },
  { id: 5521, s: "AATC", n: "Autoscope Technologies Co", c: "US" },
  { id: 5522, s: "SORIANAB.MX", n: "Organización Soriana S. A. B. de C. V", c: "Mexi" },
  { id: 5523, s: "688099.SS", n: "Amlogic Shanghai ", c: "CH" },
  { id: 5524, s: "WA1.AX", n: "WA1 Resources", c: "Australia" },
  { id: 5525, s: "DOM.ST", n: "Dometic Group AB (publ)", c: "Sweden" },
  { id: 5526, s: "DSG.TO", n: "Descartes Systems Group Inc", c: "CA" },
  { id: 5527, s: "WEYS", n: "Weyco Group Inc", c: "US" },
  { id: 5528, s: "AALB.AS", n: "Aalberts Industries NV", c: "Netherlands" },
  { id: 5529, s: "SXP.TO", n: "Supremex Inc.", c: "CA" },
  { id: 5530, s: "PFSW", n: "PFSweb Inc", c: "US" },
  { id: 5531, s: "MQG.AX", n: "Macquarie Group", c: "Australia" },
  { id: 5532, s: "TVK.TO", n: "Terravest Capital Inc", c: "CA" },
  { id: 5533, s: "EM", n: "Smart Share Global ADR", c: "US" },
  { id: 5534, s: "FDR.MC", n: "Fluidra", c: "Spain" },
  { id: 5535, s: "JAN", n: "Janone Inc", c: "US" },
  { id: 5536, s: "IVAC", n: "Intevac Inc", c: "US" },
  { id: 5537, s: "WED.V", n: "Westaim Co", c: "CA" },
  { id: 5538, s: "CABK.MC", n: "Caixabank SA", c: "Spain" },
  { id: 5539, s: "MDIA", n: "Mediaco Holding Inc", c: "US" },
  { id: 5540, s: "IPL.AX", n: "Incitec Pivot", c: "Australia" },
  { id: 5541, s: "RLMD", n: "Relmada Therapeutics Inc", c: "US" },
  { id: 5542, s: "PEYUF", n: "Peyto Exploration&Development Co", c: "US" },
  { id: 5543, s: "ANTO.L", n: "Antofagasta PLC", c: "UK" },
  { id: 5544, s: "BCAN", n: "BYND Cannasoft Enterprises Inc.", c: "US" },
  { id: 5545, s: "WDP.BR", n: "Warehouses de Pauw Comm VA", c: "Belgium" },
  { id: 5546, s: "BHLL", n: "Bunker Hill Mining Co", c: "US" },
  { id: 5547, s: "SFE", n: "Safeguard Scientifics Inc. Common Stock", c: "US" },
  { id: 5548, s: "BLFY", n: "Blue Foundry BanCo", c: "US" },
  { id: 5549, s: "AMS", n: "American Shared Hospital Srvcs", c: "US" },
  { id: 5550, s: "WAVE", n: "Eco Wave Power Global AB  ADR", c: "US" },
  { id: 5551, s: "BRTX", n: "BioRestorative Therapies Inc", c: "US" },
  { id: 5552, s: "MIXT", n: "Mix Telemats", c: "US" },
  { id: 5553, s: "PGC", n: "Peapack-Gladstone Financial", c: "US" },
  { id: 5554, s: "LEO", n: "BNY Mellon Strategic Municipals Inc", c: "US" },
  { id: 5555, s: "KGH.WA", n: "KGHM Polska Miedz SA", c: "Poland" },
  { id: 5556, s: "XDSL", n: "mPhase Technologies Inc", c: "US" },
  { id: 5557, s: "2382.TW", n: "Quanta Computer Inc", c: "TW" },
  { id: 5558, s: "XGN", n: "Exagen Inc", c: "US" },
  { id: 5559, s: "IMPL", n: "Impel Neuropharma Inc", c: "US" },
  { id: 5560, s: "TSAT", n: "Telesat Co", c: "US" },
  { id: 5561, s: "002460.SZ", n: "Jiangxi Ganfeng Lithium", c: "CH" },
  { id: 5562, s: "LXS.DE", n: "LANXESS Aktiengesellschaft", c: "GER" },
  { id: 5563, s: "HLN.L", n: "Haleon PLC", c: "UK" },
  { id: 5564, s: "CBAN", n: "Colony Bankcorp Inc", c: "US" },
  { id: 5565, s: "RIBT", n: "Ricebran Tech", c: "US" },
  { id: 5566, s: "EQT.ST", n: "EQT AB (publ)", c: "Sweden" },
  { id: 5567, s: "NBHC", n: "National Bank Holdings Corporation", c: "US" },
  { id: 5568, s: "RMT", n: "Royce Micro Cap Closed Fund", c: "US" },
  { id: 5569, s: "2882.TW", n: "Cathay Financial Holding", c: "TW" },
  { id: 5570, s: "FTXP", n: "Foothills Exploration Inc", c: "US" },
  { id: 5571, s: "BAER.SW", n: "Julius Baer Gruppe AG", c: "Switzerland" },
  { id: 5572, s: "WJG.L", n: "Watkin Jones PLC", c: "UK" },
  { id: 5573, s: "AFLYY", n: "Air FR KLM SA", c: "US" },
  { id: 5574, s: "FLRY3.SA", n: "Fleury S.A", c: "Brazil" },
  { id: 5575, s: "CIBUS.ST", n: "Cibus Nordic Real Estate AB (publ)", c: "Sweden" },
  { id: 5576, s: "HURC", n: "Hurco Companies Inc", c: "US" },
  { id: 5577, s: "SCMN.SW", n: "Swisscom AG", c: "Switzerland" },
  { id: 5578, s: "FVL.TO", n: "Freegold Ventures Limited", c: "CA" },
  { id: 5579, s: "SOMA.V", n: "Soma Gold Co", c: "CA" },
  { id: 5580, s: "SBT", n: "Sterling Bancorp Inc", c: "US" },
  { id: 5581, s: "PROSY", n: "Prosus NV ADR", c: "US" },
  { id: 5582, s: "OTRK", n: "Ontrak Inc", c: "US" },
  { id: 5583, s: "RBL.AX", n: "RedBubble", c: "Australia" },
  { id: 5584, s: "JCTCF", n: "Jewett-Cameron Trading Company", c: "US" },
  { id: 5585, s: "IPA", n: "Immunoprecise Antibodies", c: "US" },
  { id: 5586, s: "IGO.AX", n: "IGO", c: "Australia" },
  { id: 5587, s: "DNTL.TO", n: "dentalcorp Holdings", c: "CA" },
  { id: 5588, s: "PSM.DE", n: "Prosiebensat 1 Media AG", c: "GER" },
  { id: 5589, s: "XBIT", n: "XBiotech Inc", c: "US" },
  { id: 5590, s: "ADJ.DE", n: "Adler Group SA", c: "GER" },
  { id: 5591, s: "BDI.TO", n: "Black Diamond Group Limited", c: "CA" },
  { id: 5592, s: "XLY.TO", n: "Auxly Cannabis Group Inc", c: "CA" },
  { id: 5593, s: "AUVI", n: "Applied UV Inc", c: "US" },
  { id: 5594, s: "IBS.LS", n: "Ibersol SGPS", c: "Portugal" },
  { id: 5595, s: "AWE.L", n: "Alphawave IP Group PLC", c: "UK" },
  { id: 5596, s: "NXTC", n: "NextCure  Inc", c: "US" },
  { id: 5597, s: "NVFY", n: "Nova Lifestyle I", c: "US" },
  { id: 5598, s: "UFCS", n: "United Fire Group Inc", c: "US" },
  { id: 5599, s: "GALP.LS", n: "Galp Energia SGPS S.A.", c: "Portugal" },
  { id: 5600, s: "EDEN.PA", n: "Edenred SA", c: "FR" },
  { id: 5601, s: "PHAR", n: "Pharming Group NV", c: "US" },
  { id: 5602, s: "BLW", n: "BlackRock Limited Duration Income Trust", c: "US" },
  { id: 5603, s: "GNTA", n: "Genenta Science SpA ADR", c: "US" },
  { id: 5604, s: "AKO-A", n: "Embotelladora Andina S.A", c: "US" },
  { id: 5605, s: "NHC", n: "National HealthCare Corporation", c: "US" },
  { id: 5606, s: "STRS", n: "Stratus Properties Inc", c: "US" },
  { id: 5607, s: "RMLI.TA", n: "Rami Levi", c: "Israel" },
  { id: 5608, s: "FUU.V", n: "Fission 3.0 Co", c: "CA" },
  { id: 5609, s: "TCBK", n: "TriCo Bancshares", c: "US" },
  { id: 5610, s: "ICP.L", n: "Intermediate Capital Group PLC", c: "UK" },
  { id: 5611, s: "GF.SW", n: "Georg Fischer AG", c: "Switzerland" },
  { id: 5612, s: "GNT", n: "GAMCO Natural Resources Gold and Income Closed Fund", c: "US" },
  { id: 5613, s: "BFIN", n: "BankFinancial Corporation", c: "US" },
  { id: 5614, s: "HBT", n: "Hbt Financial Inc", c: "US" },
  { id: 5615, s: "ARKR", n: "Ark Restaurants Co", c: "US" },
  { id: 5616, s: "NATR", n: "Natures Sunshine Products Inc", c: "US" },
  { id: 5617, s: "NWFL", n: "Norwood Financial Co", c: "US" },
  { id: 5618, s: "VTOL", n: "Bristow Group Inc", c: "US" },
  { id: 5619, s: "ABDN.L", n: "Abrdn PLC", c: "UK" },
  { id: 5620, s: "HNNA", n: "Hennessy Ad", c: "US" },
  { id: 5621, s: "DAO", n: "Youdao Inc", c: "US" },
  { id: 5622, s: "DCC.L", n: "DCC plc", c: "UK" },
  { id: 5623, s: "CSN.L", n: "Chesnara", c: "UK" },
  { id: 5624, s: "ODV", n: "Osisko Development Corp.", c: "US" },
  { id: 5625, s: "TEM1V.HE", n: "Tecnotree Oyj", c: "Finland" },
  { id: 5626, s: "SMIN.L", n: "Smiths Group PLC", c: "UK" },
  { id: 5627, s: "EXXRF", n: "Exor NV", c: "US" },
  { id: 5628, s: "NCMGY", n: "Newcrest Mining PK", c: "US" },
  { id: 5629, s: "HRT", n: "HireRight Holdings Co", c: "US" },
  { id: 5630, s: "LUNG", n: "Pulmonx Co", c: "US" },
  { id: 5631, s: "CSPI", n: "CSP Inc", c: "US" },
  { id: 5632, s: "D-UN.TO", n: "Dream Office Real Estate Investment Trust", c: "CA" },
  { id: 5633, s: "GMWKF", n: "Games Workshop Group PLC", c: "US" },
  { id: 5634, s: "PPRUY", n: "Kering SA", c: "US" },
  { id: 5635, s: "AUTO.L", n: "Auto Trader Group plc", c: "UK" },
  { id: 5636, s: "NBTX", n: "Nanobiotix", c: "US" },
  { id: 5637, s: "11B.WA", n: "11 bit studios S.A", c: "Poland" },
  { id: 5638, s: "2368.TW", n: "Gold Circuit Electronics", c: "TW" },
  { id: 5639, s: "QSEP", n: "QS Energy Inc", c: "US" },
  { id: 5640, s: "GGII", n: "Green Globe International Inc", c: "US" },
  { id: 5641, s: "IBCP", n: "Independent Bank Corporation", c: "US" },
  { id: 5642, s: "AJAX.AS", n: "AFC Ajax NV", c: "Netherlands" },
  { id: 5643, s: "TPCS", n: "TechPrecision Corporation Common stock", c: "US" },
  { id: 5644, s: "SHAPE.", n: "Shape Robotics AS", c: "Denmark" },
  { id: 5645, s: "CSAN3.SA", n: "Cosan S.A", c: "Brazil" },
  { id: 5646, s: "BDSX", n: "Biodesix Inc", c: "US" },
  { id: 5647, s: "DJ", n: "Daily Journal Co", c: "US" },
  { id: 5648, s: "ANEB", n: "Anebulo Pharmaceuticals Inc", c: "US" },
  { id: 5649, s: "CTZ.V", n: "NamSys Inc", c: "CA" },
  { id: 5650, s: "WSTL", n: "Westell Technologies Inc", c: "US" },
  { id: 5651, s: "CCL.L", n: "Carnival PLC", c: "UK" },
  { id: 5652, s: "SWRM", n: "Appswarm Inc", c: "US" },
  { id: 5653, s: "FMBH", n: "First Mid Illinois Bancshares Inc", c: "US" },
  { id: 5654, s: "086520.KQ", n: "Ecopro Co.", c: "Korea" },
  { id: 5655, s: "MTX.DE", n: "MTU Aero Engines AG", c: "GER" },
  { id: 5656, s: "SDI.L", n: "SDI Group plc", c: "UK" },
  { id: 5657, s: "VRE", n: "Veris Residential Inc", c: "US" },
  { id: 5658, s: "PIK", n: "Kidpik Co", c: "US" },
  { id: 5659, s: "TIH.TO", n: "Toromont Industries.", c: "CA" },
  { id: 5660, s: "RGBP", n: "Regen BioPharma Inc", c: "US" },
  { id: 5661, s: "INT.ST", n: "Intellego Technologies AB", c: "Sweden" },
  { id: 5662, s: "ARU.AX", n: "Arafura Resources", c: "Australia" },
  { id: 5663, s: "LPI.AX", n: "Lithium Power International", c: "Australia" },
  { id: 5664, s: "IDG.TO", n: "Indigo Books & Music Inc.", c: "CA" },
  { id: 5665, s: "PREV-B.ST", n: "Prevas AB", c: "Sweden" },
  { id: 5666, s: "ECMPA.AS", n: "Eurocommercial Properties N.V.", c: "Netherlands" },
  { id: 5667, s: "CPH.TO", n: "Cipher Pharmaceuticals Inc", c: "CA" },
  { id: 5668, s: "QCRH", n: "QCR Holdings Inc", c: "US" },
  { id: 5669, s: "DUFRY", n: "Dufry AG ADR", c: "US" },
  { id: 5670, s: "BTMD", n: "biote Co", c: "US" },
  { id: 5671, s: "SCKT", n: "Socket Mobile Inc", c: "US" },
  { id: 5672, s: "TARA", n: "Protara Therapeutics Inc", c: "US" },
  { id: 5673, s: "AMTB", n: "Amerant Bancorp Inc.", c: "US" },
  { id: 5674, s: "CAVR", n: "CAVU Resources Inc", c: "US" },
  { id: 5675, s: "JM.ST", n: "JM AB (publ)", c: "Sweden" },
  { id: 5676, s: "FOOD.TO", n: "Goodfood Market Co", c: "CA" },
  { id: 5677, s: "BIM.PA", n: "Biomerieux SA", c: "FR" },
  { id: 5678, s: "NPCE", n: "Neuropace Inc", c: "US" },
  { id: 5679, s: "MRE.TO", n: "Martinrea International Inc.", c: "CA" },
  { id: 5680, s: "ENOV", n: "Enovis Co", c: "US" },
  { id: 5681, s: "KITS.TO", n: "Kits Eyecare", c: "CA" },
  { id: 5682, s: "NOD.OL", n: "Nordic Semiconductor ASA", c: "Norway" },
  { id: 5683, s: "FRES.L", n: "Fresnillo PLC", c: "UK" },
  { id: 5684, s: "BDORY", n: "Banco Do Brasil SA", c: "US" },
  { id: 5685, s: "WPM.TO", n: "Wheaton Precious Metals Co", c: "CA" },
  { id: 5686, s: "NOBI.ST", n: "Nobia AB", c: "Sweden" },
  { id: 5687, s: "ETE.AT", n: "National Bank of Greece S.A", c: "Greece" },
  { id: 5688, s: "ADM.L", n: "Admiral Group PLC", c: "UK" },
  { id: 5689, s: "ALTR.LS", n: "Altri SGPS SA", c: "Portugal" },
  { id: 5690, s: "ORNBV.HE", n: "Orion Oyj B", c: "Finland" },
  { id: 5691, s: "SHBI", n: "Shore Bancshares Inc", c: "US" },
  { id: 5692, s: "WSBF", n: "Waterstone Financial Inc", c: "US" },
  { id: 5693, s: "VRME", n: "VerifyMe Inc", c: "US" },
  { id: 5694, s: "SFOR.L", n: "S4 Capital PLC", c: "UK" },
  { id: 5695, s: "GCMG", n: "GCM Grosvenor Inc", c: "US" },
  { id: 5696, s: "DPH.L", n: "Dechra Pharmaceuticals PLC", c: "UK" },
  { id: 5697, s: "NETW.L", n: "Network International Holdings PLC", c: "UK" },
  { id: 5698, s: "TNET.BR", n: "Telenet Group Holding NV", c: "Belgium" },
  { id: 5699, s: "SIX2.DE", n: "Sixt SE", c: "GER" },
  { id: 5700, s: "BTZI", n: "Mcig Inc", c: "US" },
  { id: 5701, s: "CSR", n: "Centerspace", c: "US" },
  { id: 5702, s: "UNB", n: "Union Bankshares Inc", c: "US" },
  { id: 5703, s: "041510.KQ", n: "SM Entertainment Co.", c: "Korea" },
  { id: 5704, s: "CARE", n: "Carter Bank and Trust", c: "US" },
  { id: 5705, s: "PHOE.TA", n: "The Phoenix Holdings.", c: "Israel" },
  { id: 5706, s: "TMK.AX", n: "TMK Energy", c: "Australia" },
  { id: 5707, s: "3008.TW", n: "LARGAN Precision", c: "TW" },
  { id: 5708, s: "SF.ST", n: "Stillfront Group AB (publ)", c: "Sweden" },
  { id: 5709, s: "BRG.AX", n: "Breville Group", c: "Australia" },
  { id: 5710, s: "AGAE", n: "Allied Gaming & Entertainment Inc.", c: "US" },
  { id: 5711, s: "FJTSY", n: "Fujitsu ADR", c: "US" },
  { id: 5712, s: "WHGLY", n: "WH Group ADR", c: "US" },
  { id: 5713, s: "SPCB", n: "Supercom", c: "US" },
  { id: 5714, s: "LSBK", n: "Lake Shore Bancorp Inc", c: "US" },
  { id: 5715, s: "AAPL.NE", n: "Apple Inc CDR", c: "CA" },
  { id: 5716, s: "ROTH.PA", n: "Rothschild & Co SCA", c: "FR" },
  { id: 5717, s: "TASE.TA", n: "Tel Aviv Stock Exchange", c: "Israel" },
  { id: 5718, s: "FURY", n: "Fury Gold Mines", c: "US" },
  { id: 5719, s: "601318.SS", n: "Ping An Insurance Group Co of CH", c: "CH" },
  { id: 5720, s: "ALD.PA", n: "ALD S.A", c: "FR" },
  { id: 5721, s: "DESN.SW", n: "Dottikon Es Holding AG", c: "Switzerland" },
  { id: 5722, s: "REMEDY.HE", n: "Remedy Entertainment Oyj", c: "Finland" },
  { id: 5723, s: "ALCRB.PA", n: "Carbios", c: "FR" },
  { id: 5724, s: "SABS", n: "SAB Biotherapeutics Inc", c: "US" },
  { id: 5725, s: "VESTA.MX", n: "Corporación Inmobiliaria Vesta S.A.B. de C.V", c: "Mexi" },
  { id: 5726, s: "HIK.L", n: "Hikma Pharmaceuticals PLC", c: "UK" },
  { id: 5727, s: "ALFAA.MX", n: "Alfa S.A.B. de C.V", c: "Mexi" },
  { id: 5728, s: "TCBP", n: "TC BioPharm Holdings PLC", c: "US" },
  { id: 5729, s: "2376.TW", n: "Gigabyte Technology", c: "TW" },
  { id: 5730, s: "EURN.BR", n: "Euronav NV", c: "Belgium" },
  { id: 5731, s: "EVN", n: "Eaton Vance Municipal Income Closed Fund", c: "US" },
  { id: 5732, s: "NXE.TO", n: "NexGen Energy.", c: "CA" },
  { id: 5733, s: "VLE.TO", n: "Valeura Energy Inc", c: "CA" },
  { id: 5734, s: "LLG.V", n: "Mason Graphite Inc.", c: "CA" },
  { id: 5735, s: "NVA.TO", n: "NuVista Energy.", c: "CA" },
  { id: 5736, s: "TKLF", n: "Yoshitsu Co ADR", c: "US" },
  { id: 5737, s: "HNRC", n: "Houston Natural Resources Co", c: "US" },
  { id: 5738, s: "NEWA-B.ST", n: "New Wave Group AB (publ)", c: "Sweden" },
  { id: 5739, s: "IMNM", n: "Immunome Inc", c: "US" },
  { id: 5740, s: "TTCM", n: "Tautachrome Inc", c: "US" },
  { id: 5741, s: "HIRU", n: "Hiru Corporation", c: "US" },
  { id: 5742, s: "LVOX", n: "LiveVox Holdings Inc", c: "US" },
  { id: 5743, s: "SOTK", n: "Sono-Tek Co", c: "US" },
  { id: 5744, s: "TRDA", n: "Entrada Therapeutics Inc", c: "US" },
  { id: 5745, s: "JHI", n: "John Hancock Investors Closed Fund", c: "US" },
  { id: 5746, s: "NST.AX", n: "Northern Star Resources", c: "Australia" },
  { id: 5747, s: "ODF.OL", n: "Odfjell SE", c: "Norway" },
  { id: 5748, s: "HARVIA.HE", n: "Harvia Oyj", c: "Finland" },
  { id: 5749, s: "BITTI.HE", n: "Bittium Oyj", c: "Finland" },
  { id: 5750, s: "RED.MC", n: "Redeia Corporacion S.A.", c: "Spain" },
  { id: 5751, s: "SGR.AX", n: "Star Entertainment Group", c: "Australia" },
  { id: 5752, s: "FECCF", n: "Frontera Energy Co", c: "US" },
  { id: 5753, s: "ALQ.AX", n: "ALS", c: "Australia" },
  { id: 5754, s: "BAT.AX", n: "Battery Minerals", c: "Australia" },
  { id: 5755, s: "PULM", n: "Pulmatrix Inc", c: "US" },
  { id: 5756, s: "LOG.MC", n: "Cia de Distribucion Integral", c: "Spain" },
  { id: 5757, s: "MMEX", n: "MMEX Resources Co", c: "US" },
  { id: 5758, s: "IMCD.AS", n: "IMCD NV", c: "Netherlands" },
  { id: 5759, s: "BT-A.L", n: "BT Group Plc", c: "UK" },
  { id: 5760, s: "KIN.BR", n: "Kinepolis Group NV", c: "Belgium" },
  { id: 5761, s: "SGRP", n: "SPAR Group Inc", c: "US" },
  { id: 5762, s: "LI.PA", n: "Klepierre SA", c: "FR" },
  { id: 5763, s: "SRCE", n: "1st Source Corporation", c: "US" },
  { id: 5764, s: "IIP-UN.TO", n: "InterRent Real Estate Investment Trust", c: "CA" },
  { id: 5765, s: "SVI.TO", n: "Storage Vault CA Inc", c: "CA" },
  { id: 5766, s: "BWFG", n: "Bankwell Financial Group Inc", c: "US" },
  { id: 5767, s: "WTBA", n: "West Bancorporation", c: "US" },
  { id: 5768, s: "CELC", n: "Celcuity LLC", c: "US" },
  { id: 5769, s: "GURU.TO", n: "Guru Organic Energy Co", c: "CA" },
  { id: 5770, s: "KGX.DE", n: "Kion Group AG", c: "GER" },
  { id: 5771, s: "JMT.LS", n: "Jeronimo Martins SGPS SA", c: "Portugal" },
  { id: 5772, s: "601138.SS", n: "Foxconn Industrial Internet", c: "CH" },
  { id: 5773, s: "MRM.AX", n: "MMA Offshore", c: "Australia" },
  { id: 5774, s: "BOQ.AX", n: "Bank Of Queensland.", c: "Australia" },
  { id: 5775, s: "IH", n: "Ihuman Inc", c: "US" },
  { id: 5776, s: "MONI", n: "World Oil Group", c: "US" },
  { id: 5777, s: "ELISA.HE", n: "Elisa Oyj", c: "Finland" },
  { id: 5778, s: "NXT.AX", n: "Nextdc", c: "Australia" },
  { id: 5779, s: "OMAB.MX", n: "Grupo Aeroportuario del Centro Norte S.A.B. de C.V", c: "Mexi" },
  { id: 5780, s: "SHA.DE", n: "Schaeffler AG Pref", c: "GER" },
  { id: 5781, s: "TUI.L", n: "TUI AG", c: "UK" },
  { id: 5782, s: "RDVT", n: "Red Violet Inc", c: "US" },
  { id: 5783, s: "JMS.AX", n: "Jupiter Mines", c: "Australia" },
  { id: 5784, s: "EWCZ", n: "European Wax Center Inc", c: "US" },
  { id: 5785, s: "LBC", n: "Luther Burbank", c: "US" },
  { id: 5786, s: "PMD", n: "Psychemedics Corporation", c: "US" },
  { id: 5787, s: "AGRO3.SA", n: "BrasilAgro - Companhia Brasileira de Propriedades Agrícolas", c: "Brazil" },
  { id: 5788, s: "RVP", n: "Retractable Technologies Inc", c: "US" },
  { id: 5789, s: "KINS", n: "Kingstone Companies Inc", c: "US" },
  { id: 5790, s: "EVT.DE", n: "Evotec SE", c: "GER" },
  { id: 5791, s: "CPZ", n: "Calamos LongShort Equity & Dynamic Income Trust", c: "US" },
  { id: 5792, s: "HOT.DE", n: "HOCHTIEF Aktiengesellschaft", c: "GER" },
  { id: 5793, s: "ISS.", n: "ISS A/S", c: "Denmark" },
  { id: 5794, s: "KRR.TO", n: "Karora Resources Inc", c: "CA" },
  { id: 5795, s: "BVB.DE", n: "Borussia Dortmund GmbH & Co KGaA", c: "GER" },
  { id: 5796, s: "GDL.TO", n: "Goodfellow Inc.", c: "CA" },
  { id: 5797, s: "MDXH", n: "MDxHealth SA ADR", c: "US" },
  { id: 5798, s: "ELD.TO", n: "Eldorado Gold Co", c: "CA" },
  { id: 5799, s: "OPXS", n: "Optex Systems Holdings Inc. Common Stock", c: "US" },
  { id: 5800, s: "STG", n: "Sunlands Technology Group", c: "US" },
  { id: 5801, s: "ALA.TO", n: "AltaGas", c: "CA" },
  { id: 5802, s: "GSVR.V", n: "Guanajuato Silver Company", c: "CA" },
  { id: 5803, s: "TBNK", n: "Territorial Bancorp Inc", c: "US" },
  { id: 5804, s: "VGCX.TO", n: "Victoria Gold Co", c: "CA" },
  { id: 5805, s: "NBRV", n: "Nabriva Therapeutics AG", c: "US" },
  { id: 5806, s: "BMN.AX", n: "Bannerman Resources", c: "Australia" },
  { id: 5807, s: "KRX.IR", n: "Kingspan Group", c: "Ireland" },
  { id: 5808, s: "VAXX", n: "Vaxxinity Inc", c: "US" },
  { id: 5809, s: "BU.TO", n: "Burcon NutraScience Corporation", c: "CA" },
  { id: 5810, s: "THRX", n: "Theseus Pharmaceuticals Inc", c: "US" },
  { id: 5811, s: "BYFC", n: "Broadway Financial Corporation", c: "US" },
  { id: 5812, s: "PME.AX", n: "Pro Medicus", c: "Australia" },
  { id: 5813, s: "SCAN.V", n: "Liberty Defense Holdings", c: "CA" },
  { id: 5814, s: "BETS-B.ST", n: "Betsson AB", c: "Sweden" },
  { id: 5815, s: "NICK", n: "Nicholas Financial Inc", c: "US" },
  { id: 5816, s: "SONX", n: "Sonendo Inc", c: "US" },
  { id: 5817, s: "LSG.OL", n: "Leroy Seafood Group ASA", c: "Norway" },
  { id: 5818, s: "SFST", n: "Southern First Bancshares Inc", c: "US" },
  { id: 5819, s: "MOVE", n: "Movano Inc", c: "US" },
  { id: 5820, s: "VIVK", n: "Vivakor Inc", c: "US" },
  { id: 5821, s: "RFF.AX", n: "Rural Funds Group", c: "Australia" },
  { id: 5822, s: "CVII", n: "Churchill Capital Corp VII", c: "US" },
  { id: 5823, s: "TXP.TO", n: "Touchstone Exploration Inc", c: "CA" },
  { id: 5824, s: "RHIM.L", n: "RHI Magnesita NV", c: "UK" },
  { id: 5825, s: "MRFG3.SA", n: "Marfrig Global Foods S.A", c: "Brazil" },
  { id: 5826, s: "PSMMY", n: "Persimmon Plc", c: "US" },
  { id: 5827, s: "BLE", n: "BlackRock MIT II", c: "US" },
  { id: 5828, s: "AKBNK.IS", n: "Akbank TAS", c: "Turkey" },
  { id: 5829, s: "ORP.PA", n: "Orpea SA", c: "FR" },
  { id: 5830, s: "VIB3.DE", n: "Villeroy & Boch AG", c: "GER" },
  { id: 5831, s: "ESSITY-B.ST", n: "Essity AB (publ)", c: "Sweden" },
  { id: 5832, s: "BCV", n: "Bancroft Fund Limited", c: "US" },
  { id: 5833, s: "STAB", n: "Statera Biopharma Inc", c: "US" },
  { id: 5834, s: "VXIT", n: "VirExit Technologies Inc", c: "US" },
  { id: 5835, s: "INBX", n: "Inhibrx Inc", c: "US" },
  { id: 5836, s: "600438.SS", n: "Tongwei", c: "CH" },
  { id: 5837, s: "VTXB", n: "Vortex Brands", c: "US" },
  { id: 5838, s: "GBR", n: "New Concept Energy Inc", c: "US" },
  { id: 5839, s: "SECARE.ST", n: "Swedencare publ AB", c: "Sweden" },
  { id: 5840, s: "RG", n: "RGC Resources Inc", c: "US" },
  { id: 5841, s: "ARYN.SW", n: "Aryzta AG", c: "Switzerland" },
  { id: 5842, s: "EP", n: "Empire Petroleum Co", c: "US" },
  { id: 5843, s: "ENC.MC", n: "ENCE Energía y Celulosa S.A", c: "Spain" },
  { id: 5844, s: "HWDN.L", n: "Howden Joinery Group Plc", c: "UK" },
  { id: 5845, s: "TMV.DE", n: "TeamViewer AG", c: "GER" },
  { id: 5846, s: "CCL-B.TO", n: "CCL Industries Inc", c: "CA" },
  { id: 5847, s: "AENA.MC", n: "Aena SA", c: "Spain" },
  { id: 5848, s: "MBWM", n: "Mercantile Bank Corporation", c: "US" },
  { id: 5849, s: "OX2.ST", n: "OX2 AB", c: "Sweden" },
  { id: 5850, s: "IVA", n: "Inventiva Sa", c: "US" },
  { id: 5851, s: "FRPH", n: "Frp Holdings Ord", c: "US" },
  { id: 5852, s: "SLR.AX", n: "Silver Lake Resources", c: "Australia" },
  { id: 5853, s: "OPOF", n: "Old Point Financial Corporation", c: "US" },
  { id: 5854, s: "MPB", n: "Mid Penn BanCo", c: "US" },
  { id: 5855, s: "GREENH.", n: "Green Hydrogen Systems AS", c: "Denmark" },
  { id: 5856, s: "BIREF", n: "Birchcliff Energy.", c: "US" },
  { id: 5857, s: "AIN", n: "Albany International Corporation", c: "US" },
  { id: 5858, s: "TRS", n: "TriMas Corporation", c: "US" },
  { id: 5859, s: "CHT", n: "Chunghwa Telecom", c: "US" },
  { id: 5860, s: "STTK", n: "Shattuck Labs Inc", c: "US" },
  { id: 5861, s: "CALA", n: "Calithera Biosciences Inc", c: "US" },
  { id: 5862, s: "LAND.L", n: "Land Securities Group PLC", c: "UK" },
  { id: 5863, s: "FT", n: "Franklin Universal Closed Fund", c: "US" },
  { id: 5864, s: "HAUTO.OL", n: "Hoegh Autoliners ASA", c: "Norway" },
  { id: 5865, s: "HIHI", n: "Holiday Island Holdings Inc", c: "US" },
  { id: 5866, s: "ACLLF", n: "At", c: "US" },
  { id: 5867, s: "REL.L", n: "Relx PLC", c: "UK" },
  { id: 5868, s: "IMUC", n: "ImmunoCellular Therapeutics", c: "US" },
  { id: 5869, s: "ASOMY", n: "ASOS plc PK", c: "US" },
  { id: 5870, s: "002459.SZ", n: "JA Solar Technology", c: "CH" },
  { id: 5871, s: "HCTI", n: "Healthcare Triangle Inc", c: "US" },
  { id: 5872, s: "RYCEF", n: "Rolls-Royce Holdings PLC", c: "US" },
  { id: 5873, s: "JAPAF", n: "Japan Tobacco Inc", c: "US" },
  { id: 5874, s: "ENLAY", n: "ENEL Societa per Azioni", c: "US" },
  { id: 5875, s: "OSBC", n: "Old Second Bancorp Inc", c: "US" },
  { id: 5876, s: "SANG", n: "Sangoma Technologies Co", c: "US" },
  { id: 5877, s: "PEBK", n: "Peoples Bancorp of North Carolina", c: "US" },
  { id: 5878, s: "ATNI", n: "ATN International Inc", c: "US" },
  { id: 5879, s: "VACN.SW", n: "VAT Group AG", c: "Switzerland" },
  { id: 5880, s: "EAPI.PA", n: "Euroapi SAS", c: "FR" },
  { id: 5881, s: "DBM.TO", n: "Doman Building Materials Group", c: "CA" },
  { id: 5882, s: "CTEC.L", n: "ConvaTec Group PLC", c: "UK" },
  { id: 5883, s: "BDEV.L", n: "Barratt Developments PLC", c: "UK" },
  { id: 5884, s: "RILBA.", n: "Ringkjoebing Landbobank A/S", c: "Denmark" },
  { id: 5885, s: "EGLX.TO", n: "Enthusiast Gaming Holdings Inc", c: "CA" },
  { id: 5886, s: "GDI.TO", n: "GDI Integrated", c: "CA" },
  { id: 5887, s: "JDW.L", n: "J D Wetherspoon PLC", c: "UK" },
  { id: 5888, s: "ITM.L", n: "ITM Power", c: "UK" },
  { id: 5889, s: "EQX.TO", n: "Equinox Gold Co", c: "CA" },
  { id: 5890, s: "IGM.TO", n: "IGM Financial Inc.", c: "CA" },
  { id: 5891, s: "FPE.DE", n: "Fuchs Petrolub SE", c: "GER" },
  { id: 5892, s: "KOG.OL", n: "Kongsberg Gruppen ASA", c: "Norway" },
  { id: 5893, s: "HLF.TO", n: "High Liner Foods Incorporated", c: "CA" },
  { id: 5894, s: "HLUN-B.", n: "H Lundbeck A/S", c: "Denmark" },
  { id: 5895, s: "HOLCO.AS", n: "Holland Colours NV", c: "Netherlands" },
  { id: 5896, s: "SVNLY", n: "Svenska Handelsbanken PK", c: "US" },
  { id: 5897, s: "TA.TO", n: "TransAlta Co", c: "CA" },
  { id: 5898, s: "KIMBERA.MX", n: "Kimberly-Clark de México S. A. B. de C. V", c: "Mexi" },
  { id: 5899, s: "TCL-A.TO", n: "Transcontinental Inc", c: "CA" },
  { id: 5900, s: "VMO", n: "Invesco Municipal Opportunity Trust", c: "US" },
  { id: 5901, s: "PYR.TO", n: "PyroGenesis CA Inc.", c: "CA" },
  { id: 5902, s: "VUL.AX", n: "Vulcan Energy Resources", c: "Australia" },
  { id: 5903, s: "XAU.TO", n: "GoldMoney Inc", c: "CA" },
  { id: 5904, s: "FRHLF", n: "Freehold Royalties.", c: "US" },
  { id: 5905, s: "BRW.V", n: "Brunswick Exploration Inc", c: "CA" },
  { id: 5906, s: "8069.TWO", n: "E Ink Holdings", c: "TW" },
  { id: 5907, s: "SVFD", n: "Save Foods Inc", c: "US" },
  { id: 5908, s: "PONGF", n: "Atari SA", c: "US" },
  { id: 5909, s: "WEF.TO", n: "Western Forest Products Inc", c: "CA" },
  { id: 5910, s: "FME.DE", n: "Fresenius Medical Care AG & Co. KGaA", c: "GER" },
  { id: 5911, s: "BNZL.L", n: "Bunzl PLC", c: "UK" },
  { id: 5912, s: "APGMF", n: "Applied Graphene Materials Plc", c: "US" },
  { id: 5913, s: "MRL.MC", n: "Merlin Properties SOCIMI SA", c: "Spain" },
  { id: 5914, s: "ACU", n: "Acme United Corporation", c: "US" },
  { id: 5915, s: "COH.AX", n: "Cochlear", c: "Australia" },
  { id: 5916, s: "BSIG", n: "Brightsphere Investment Group Inc", c: "US" },
  { id: 5917, s: "GHST", n: "GHST World Inc", c: "US" },
  { id: 5918, s: "IDEXY", n: "Industria de Diseno Textil SA ADR", c: "US" },
  { id: 5919, s: "PYPL.NE", n: "PayPal Holdings Inc CDR", c: "CA" },
  { id: 5920, s: "SEB-A.ST", n: "Skandinaviska Enskilda Banken AB (publ)", c: "Sweden" },
  { id: 5921, s: "GANX", n: "Gain Therapeutics Inc", c: "US" },
  { id: 5922, s: "UNTY", n: "Unity Bancorp Inc", c: "US" },
  { id: 5923, s: "TAVHL.IS", n: "TAV Havalimanlari Holding", c: "Turkey" },
  { id: 5924, s: "FUND", n: "Sprott Focus Trust", c: "US" },
  { id: 5925, s: "ARTW", n: "Arts-Way Manufacturing Co Inc", c: "US" },
  { id: 5926, s: "RROS.ST", n: "Rottneros AB", c: "Sweden" },
  { id: 5927, s: "SLMN.V", n: "Solis Minerals", c: "CA" },
  { id: 5928, s: "EPIX", n: "ESSA Pharma Inc", c: "US" },
  { id: 5929, s: "FGBI", n: "First Guaranty Bancshares Inc", c: "US" },
  { id: 5930, s: "SMHI", n: "SEACOR Marine Holdings Inc", c: "US" },
  { id: 5931, s: "005490.KS", n: "POSCO Holdings", c: "Korea" },
  { id: 5932, s: "ATHE", n: "Alterity Therapeutics", c: "US" },
  { id: 5933, s: "GRT-UN.TO", n: "Granite Real Estate Investment Trust", c: "CA" },
  { id: 5934, s: "REG1V.HE", n: "Revenio Group Corporation", c: "Finland" },
  { id: 5935, s: "NXMR", n: "Nextmart Inc", c: "US" },
  { id: 5936, s: "GLO.TO", n: "Global Atomic Co", c: "CA" },
  { id: 5937, s: "PBH.TO", n: "Premium Brands Holdings Corporation", c: "CA" },
  { id: 5938, s: "TLX.AX", n: "Telix Pharmaceuticals", c: "Australia" },
  { id: 5939, s: "NAII", n: "Natural Alternatives International", c: "US" },
  { id: 5940, s: "TWE.AX", n: "Treasury Wine Estates", c: "Australia" },
  { id: 5941, s: "JRONY", n: "Jeronimo Martins SGPS SA ADR", c: "US" },
  { id: 5942, s: "BB.PA", n: "Société BIC SA", c: "FR" },
  { id: 5943, s: "MHGVY", n: "Mowi ASA ADR", c: "US" },
  { id: 5944, s: "NETC.", n: "Netcompany Group A/S", c: "Denmark" },
  { id: 5945, s: "CCA.TO", n: "Cogeco Communications Inc", c: "CA" },
  { id: 5946, s: "OFG", n: "OFG BanCo", c: "US" },
  { id: 5947, s: "SCYR.MC", n: "Sacyr SA", c: "Spain" },
  { id: 5948, s: "SSAB-A.ST", n: "SSAB AB (publ)", c: "Sweden" },
  { id: 5949, s: "AX-UN.TO", n: "Artis Real Estate Investment Trust", c: "CA" },
  { id: 5950, s: "QRTEB", n: "Qurate Retail Inc Series B", c: "US" },
  { id: 5951, s: "CTPNV.AS", n: "CTP N.V.", c: "Netherlands" },
  { id: 5952, s: "BYOC", n: "Beyond Commerce Inc", c: "US" },
  { id: 5953, s: "MNSB", n: "Mainstreet Bank", c: "US" },
  { id: 5954, s: "PCB", n: "PCB BanCo", c: "US" },
  { id: 5955, s: "KZIA", n: "Kazia Therapeutics ADR", c: "US" },
  { id: 5956, s: "BBVA.MC", n: "Banco Bilbao Vizcaya Argentaria SA", c: "Spain" },
  { id: 5957, s: "HPNN", n: "Hop-On Inc", c: "US" },
  { id: 5958, s: "CBRAIN.", n: "cBrain A/S", c: "Denmark" },
  { id: 5959, s: "AAV.TO", n: "Advantage Oil & Gas.", c: "CA" },
  { id: 5960, s: "IPCO.TO", n: "International Petroleum Co", c: "CA" },
  { id: 5961, s: "MFI.TO", n: "Maple Leaf Foods Inc.", c: "CA" },
  { id: 5962, s: "INVP.L", n: "Investec PLC", c: "UK" },
  { id: 5963, s: "IMTE", n: "Integrated Media Technology", c: "US" },
  { id: 5964, s: "SMBC", n: "Southern Missouri Bancorp Inc", c: "US" },
  { id: 5965, s: "SANION.ST", n: "Saniona AB", c: "Sweden" },
  { id: 5966, s: "IVZ.AX", n: "Invictus Energy", c: "Australia" },
  { id: 5967, s: "INNV", n: "InnovAge Holding Co", c: "US" },
  { id: 5968, s: "VISM", n: "Visium Technologies Inc", c: "US" },
  { id: 5969, s: "CTY1S.HE", n: "Citycon Oyj", c: "Finland" },
  { id: 5970, s: "OBE.TO", n: "Obsidian Energy", c: "CA" },
  { id: 5971, s: "MBCN", n: "Middlefield Banc", c: "US" },
  { id: 5972, s: "BFC", n: "Bank First National Co", c: "US" },
  { id: 5973, s: "HYQ.DE", n: "Hypoport SE", c: "GER" },
  { id: 5974, s: "MSN", n: "Emerson Radio Corporation", c: "US" },
  { id: 5975, s: "BESIY", n: "BE Semiconductor Industries NV ADR", c: "US" },
  { id: 5976, s: "SVNDY", n: "Seven & i Holdings Co ADR", c: "US" },
  { id: 5977, s: "3711.TW", n: "ASE Industrial Holding", c: "TW" },
  { id: 5978, s: "2353.TW", n: "Acer Inc", c: "TW" },
  { id: 5979, s: "BTT", n: "Blackrock Municipal Target Term Closed Fund", c: "US" },
  { id: 5980, s: "ABIO", n: "ARCA Biopharma Inc", c: "US" },
  { id: 5981, s: "METSO.HE", n: "Metso Oyj", c: "Finland" },
  { id: 5982, s: "SNFCA", n: "Security National Financial", c: "US" },
  { id: 5983, s: "S92.DE", n: "SMA Solar Technology AG", c: "GER" },
  { id: 5984, s: "CBOX.L", n: "Cake Box Holdings PLC", c: "UK" },
  { id: 5985, s: "IOBT", n: "IO Biotech Inc", c: "US" },
  { id: 5986, s: "SCATC.OL", n: "Scatec Solar OL", c: "Norway" },
  { id: 5987, s: "KBCA.BR", n: "KBC Ancora", c: "Belgium" },
  { id: 5988, s: "CIX.TO", n: "CI Financial Co", c: "CA" },
  { id: 5989, s: "LGL", n: "LGL Group Inc", c: "US" },
  { id: 5990, s: "PDEX", n: "Pro-Dex Inc", c: "US" },
  { id: 5991, s: "FIRE.ST", n: "Firefly AB (publ)", c: "Sweden" },
  { id: 5992, s: "ALBRK.IS", n: "Albaraka Turk Katilim Bankasi AS", c: "Turkey" },
  { id: 5993, s: "LUMO", n: "Lumos Pharma Inc", c: "US" },
  { id: 5994, s: "BILI-A.ST", n: "Bilia AB (publ)", c: "Sweden" },
  { id: 5995, s: "FNCB", n: "FNCB Bancorp Inc", c: "US" },
  { id: 5996, s: "MOL.BD", n: "MOL Nyrt.", c: "Hungary" },
  { id: 5997, s: "SZU.DE", n: "Südzucker AG", c: "GER" },
  { id: 5998, s: "CFBK", n: "CF Bankshares Inc", c: "US" },
  { id: 5999, s: "JUPW", n: "Jupiter Wellness Inc", c: "US" },
  { id: 6000, s: "TKOI", n: "Telkonet Inc", c: "US" },
  { id: 6001, s: "HUSQ-B.ST", n: "Husqvarna AB (publ)", c: "Sweden" },
  { id: 6002, s: "AUBN", n: "Auburn National Bancorporation Inc", c: "US" },
  { id: 6003, s: "HHH", n: "Howard Hughes Corporation", c: "US" },
  { id: 6004, s: "CRYBF", n: "Cryptoblox Technologies Inc", c: "US" },
  { id: 6005, s: "RHE", n: "Regional Health Properties Inc", c: "US" },
  { id: 6006, s: "SYNT.L", n: "Synthomer plc", c: "UK" },
  { id: 6007, s: "OROVY", n: "Orient Overseas International ADR", c: "US" },
  { id: 6008, s: "ABEV3.SA", n: "Ambev S.A.", c: "Brazil" },
  { id: 6009, s: "BIOVIC-B.ST", n: "Biovica International AB (publ)", c: "Sweden" },
  { id: 6010, s: "ALCC", n: "AltC Acquisition Co", c: "US" },
  { id: 6011, s: "601857.SS", n: "PetroCH Co Class A", c: "CH" },
  { id: 6012, s: "NB.TO", n: "Niocorp Developments.", c: "CA" },
  { id: 6013, s: "AXFO.ST", n: "Axfood AB", c: "Sweden" },
  { id: 6014, s: "TONR", n: "TonnerOne World Holdings Inc", c: "US" },
  { id: 6015, s: "122870.KQ", n: "YG Entertainment Inc", c: "Korea" },
  { id: 6016, s: "NEN", n: "New England Realty Associates LP", c: "US" },
  { id: 6017, s: "TOASO.IS", n: "Tofas Turk Otomobil Fabrikasi AS", c: "Turkey" },
  { id: 6018, s: "NN", n: "Nextnav Acquisition Co", c: "US" },
  { id: 6019, s: "OCO.V", n: "Oroco Resource Co", c: "CA" },
  { id: 6020, s: "ATCO-B.ST", n: "Atlas Copco AB Series B", c: "Sweden" },
  { id: 6021, s: "SDE.TO", n: "Spartan Delta Co", c: "CA" },
  { id: 6022, s: "TCS.TO", n: "TECSYS Inc.", c: "CA" },
  { id: 6023, s: "UNQL", n: "Unique Logistics International Inc", c: "US" },
  { id: 6024, s: "SOUHY", n: "South32 ADR", c: "US" },
  { id: 6025, s: "HICN.TA", n: "Highcon Systems", c: "Israel" },
  { id: 6026, s: "VLK.AS", n: "Van Lanschot NV", c: "Netherlands" },
  { id: 6027, s: "NETZ.NE", n: "Carbon Streaming Co", c: "CA" },
  { id: 6028, s: "2881.TW", n: "Fubon Financial Holding", c: "TW" },
  { id: 6029, s: "CCEL", n: "CryoCell International Inc", c: "US" },
  { id: 6030, s: "LTMCF", n: "Lithium Chile Inc", c: "US" },
  { id: 6031, s: "HSON", n: "Hudson Global Inc", c: "US" },
  { id: 6032, s: "DMGI.V", n: "Dmg Blockchain Solutions Inc", c: "CA" },
  { id: 6033, s: "TOSYY", n: "Toshiba Corp PK", c: "US" },
  { id: 6034, s: "ITR.V", n: "Integra Resources Co", c: "CA" },
  { id: 6035, s: "5347.TWO", n: "Vanguard International Semiconductor", c: "TW" },
  { id: 6036, s: "TLF", n: "Tandy Leather Factory Inc", c: "US" },
  { id: 6037, s: "TRI.PA", n: "Trigano S.A.", c: "FR" },
  { id: 6038, s: "GTH", n: "Genetron Holdings", c: "US" },
  { id: 6039, s: "PVBC", n: "Provident Bancorp Inc", c: "US" },
  { id: 6040, s: "HTH", n: "Hilltop Holdings Inc", c: "US" },
  { id: 6041, s: "HAG.DE", n: "Hensoldt Ag", c: "GER" },
  { id: 6042, s: "FNA", n: "Paragon 28 Inc", c: "US" },
  { id: 6043, s: "OCN", n: "Ocwen Financial Corporation", c: "US" },
  { id: 6044, s: "ITH.L", n: "Ithaca Energy PLC", c: "UK" },
  { id: 6045, s: "SRZN", n: "Surrozen Inc", c: "US" },
  { id: 6046, s: "ONTEX.BR", n: "Ontex Group NV", c: "Belgium" },
  { id: 6047, s: "TESB.BR", n: "Tessenderlo", c: "Belgium" },
  { id: 6048, s: "BME.L", n: "B&M European Value Retail SA", c: "UK" },
  { id: 6049, s: "MCBC", n: "Macatawa Bank Corporation", c: "US" },
  { id: 6050, s: "QTRH.TO", n: "Quarterhill Inc", c: "CA" },
  { id: 6051, s: "GIL.TO", n: "Gildan Activewear Inc.", c: "CA" },
  { id: 6052, s: "GHG", n: "GreenTree Hospitality Group", c: "US" },
  { id: 6053, s: "CNXA", n: "Connexa Sports Technologies Inc. Common Stock", c: "US" },
  { id: 6054, s: "AVTE", n: "Aerovate Therapeutics Inc", c: "US" },
  { id: 6055, s: "002466.SZ", n: "Sichuan Tianqi Lithium Industries Inc", c: "CH" },
  { id: 6056, s: "MITRA.BR", n: "Mithra Pharmaceuticals SA", c: "Belgium" },
  { id: 6057, s: "AEFES.IS", n: "Anadolu Efes Biracilik ve Malt Sanayi AS", c: "Turkey" },
  { id: 6058, s: "IPO.TO", n: "InPlay Oil Co", c: "CA" },
  { id: 6059, s: "CM-PQ.TO", n: "Canadian Imperial Bank of Commerce Pref Q", c: "CA" },
  { id: 6060, s: "JRV.AX", n: "Jervois Mining", c: "Australia" },
  { id: 6061, s: "OERL.SW", n: "OC Oerlikon Corp AG", c: "Switzerland" },
  { id: 6062, s: "SN.L", n: "Smith & Nephew PLC", c: "UK" },
  { id: 6063, s: "KRZ.IR", n: "Kerry Group", c: "Ireland" },
  { id: 6064, s: "NK.PA", n: "Imerys SA", c: "FR" },
  { id: 6065, s: "FSV.TO", n: "FirstService Co", c: "CA" },
  { id: 6066, s: "KRN.DE", n: "Krones AG", c: "GER" },
  { id: 6067, s: "VITR.ST", n: "Vitrolife AB", c: "Sweden" },
  { id: 6068, s: "AIRG", n: "Airgain Inc", c: "US" },
  { id: 6069, s: "ADN.TO", n: "Acadian Timber Co", c: "CA" },
  { id: 6070, s: "OYL.V", n: "CGX Energy Inc.", c: "CA" },
  { id: 6071, s: "ROCK-B.", n: "ROCKWOOL International A/S", c: "Denmark" },
  { id: 6072, s: "TEG.DE", n: "TAG Immobilien AG", c: "GER" },
  { id: 6073, s: "ELPE.AT", n: "Hellenic Petroleum S.A", c: "Greece" },
  { id: 6074, s: "GNFT", n: "Genfit", c: "US" },
  { id: 6075, s: "USNZY", n: "Usinas Siderurgicas de Minas Gerais", c: "US" },
  { id: 6076, s: "GSBC", n: "Great Southern Bancorp Inc", c: "US" },
  { id: 6077, s: "TF.TO", n: "Timbercreek Financial Co", c: "CA" },
  { id: 6078, s: "BQ", n: "Boqii Holding Limited", c: "US" },
  { id: 6079, s: "ABXX.NE", n: "Abaxx Technologies Inc", c: "CA" },
  { id: 6080, s: "EFN.TO", n: "Element Fleet Management Co", c: "CA" },
  { id: 6081, s: "IDIA.SW", n: "Idorsia", c: "Switzerland" },
  { id: 6082, s: "STRS.TA", n: "Strauss Group", c: "Israel" },
  { id: 6083, s: "HUH1V.HE", n: "Huhtamaki Oyj", c: "Finland" },
  { id: 6084, s: "GVOLT.LS", n: "Greenvolt Energias Renovaveis SA", c: "Portugal" },
  { id: 6085, s: "VINO", n: "Gaucho Group Holdings Inc", c: "US" },
  { id: 6086, s: "EFGN.SW", n: "EFG International AG", c: "Switzerland" },
  { id: 6087, s: "300450.SZ", n: "Wuxi Lead Auto Equipment", c: "CH" },
  { id: 6088, s: "CNE.TO", n: "Canacol Energy.", c: "CA" },
  { id: 6089, s: "SPGYF", n: "Whitecap Resources Inc.", c: "US" },
  { id: 6090, s: "NEO.TO", n: "Neo Performance Materials Inc", c: "CA" },
  { id: 6091, s: "601988.SS", n: "Bank of CH A", c: "CH" },
  { id: 6092, s: "AZRG.TA", n: "Azrieli Group", c: "Israel" },
  { id: 6093, s: "DUFN.SW", n: "Dufry AG", c: "Switzerland" },
  { id: 6094, s: "CLN.SW", n: "Clariant AG", c: "Switzerland" },
  { id: 6095, s: "ADXN", n: "Addex Therapeutics", c: "US" },
  { id: 6096, s: "GAM", n: "General American Investors Closed Fund", c: "US" },
  { id: 6097, s: "TTDKY", n: "TDK Corp ADR", c: "US" },
  { id: 6098, s: "IHG.L", n: "InterContinental Hotels Group PLC", c: "UK" },
  { id: 6099, s: "CHP-UN.TO", n: "Choice Properties Real Estate Investment Trust", c: "CA" },
  { id: 6100, s: "AAU", n: "Almaden Minerals", c: "US" },
  { id: 6101, s: "STEV", n: "Stevia Co", c: "US" },
  { id: 6102, s: "SYR.AX", n: "Syrah Resources", c: "Australia" },
  { id: 6103, s: "XPL", n: "Solitario Exploration & Royalty Co", c: "US" },
  { id: 6104, s: "MDI.TO", n: "Major Drilling Group International", c: "CA" },
  { id: 6105, s: "BBSE3.SA", n: "BB Seguridade Participacoes SA", c: "Brazil" },
  { id: 6106, s: "VMC.V", n: "Vicinity Motor Co", c: "CA" },
  { id: 6107, s: "BXB.AX", n: "Brambles", c: "Australia" },
  { id: 6108, s: "GPV.V", n: "GreenPower Motor Company Inc", c: "CA" },
  { id: 6109, s: "3443.TW", n: "Global Unichip Co", c: "TW" },
  { id: 6110, s: "JBSS3.SA", n: "JBS S.A", c: "Brazil" },
  { id: 6111, s: "PIF.TO", n: "Polaris Infrastructure Inc", c: "CA" },
  { id: 6112, s: "PNE.TO", n: "Pine Cliff Energy", c: "CA" },
  { id: 6113, s: "IRE.AX", n: "Iress", c: "Australia" },
  { id: 6114, s: "000651.SZ", n: "Gree Electric Appliances Inc of Zhuhai", c: "CH" },
  { id: 6115, s: "NMT.AX", n: "Neometals", c: "Australia" },
  { id: 6116, s: "ASX.AX", n: "ASX", c: "Australia" },
  { id: 6117, s: "PRU.L", n: "Prudential PLC", c: "UK" },
  { id: 6118, s: "NOS.LS", n: "NOS S.G.P.S. S.A.", c: "Portugal" },
  { id: 6119, s: "NEWP", n: "New Pacific Metals Co", c: "US" },
  { id: 6120, s: "HLS.TO", n: "HLS Therapeutics Inc", c: "CA" },
  { id: 6121, s: "BUCN.SW", n: "Bucher Industries AG", c: "Switzerland" },
  { id: 6122, s: "XTC.TO", n: "Exco Technologies Limited", c: "CA" },
  { id: 6123, s: "601888.SS", n: "CH International Travel Service Co", c: "CH" },
  { id: 6124, s: "BAB.L", n: "Babcock International Group PLC", c: "UK" },
  { id: 6125, s: "FERN", n: "Fernhill Co", c: "US" },
  { id: 6126, s: "DIT", n: "AMCON Distributing Company", c: "US" },
  { id: 6127, s: "BBBT", n: "Digital Development Partners Inc", c: "US" },
  { id: 6128, s: "PUB.PA", n: "Publicis Groupe SA", c: "FR" },
  { id: 6129, s: "SRAX", n: "SRAX Inc", c: "US" },
  { id: 6130, s: "CTHR", n: "Charles & Colvard", c: "US" },
  { id: 6131, s: "M4N.DE", n: "Muehlhan AG", c: "GER" },
  { id: 6132, s: "FCU.TO", n: "Fission Uranium Co", c: "CA" },
  { id: 6133, s: "BIPC.TO", n: "Brookfield Infrastructure Co", c: "CA" },
  { id: 6134, s: "DWSN", n: "Dawson Geophysical Company", c: "US" },
  { id: 6135, s: "B4B.DE", n: "Metro AG", c: "GER" },
  { id: 6136, s: "ENGIY", n: "Engie SA ADR", c: "US" },
  { id: 6137, s: "BNOX", n: "Bionomics ADR", c: "US" },
  { id: 6138, s: "TURN", n: "180 Degree Capital Co", c: "US" },
  { id: 6139, s: "PET.TO", n: "Pet Valu Holdings", c: "CA" },
  { id: 6140, s: "017670.KS", n: "SK Telecom", c: "Korea" },
  { id: 6141, s: "PRK", n: "Park National Corporation", c: "US" },
  { id: 6142, s: "D6H.DE", n: "DATAGROUP SE", c: "GER" },
  { id: 6143, s: "PFC.L", n: "Petrofac", c: "UK" },
  { id: 6144, s: "LXFR", n: "Luxfer Holdings PLC", c: "US" },
  { id: 6145, s: "PINFRA.MX", n: "Promotora y Operadora de Infraestructura S. A. B. de C. V", c: "Mexi" },
  { id: 6146, s: "AACG", n: "ATA Creativity Global", c: "US" },
  { id: 6147, s: "MN.V", n: "Manganese X Energy Co", c: "CA" },
  { id: 6148, s: "EADSF", n: "Airbus Group SE", c: "US" },
  { id: 6149, s: "MEGACPO.MX", n: "Megacable Holdings S. A. B. de C. V", c: "Mexi" },
  { id: 6150, s: "PKBK", n: "Parke Bancorp Inc", c: "US" },
  { id: 6151, s: "FLTR.L", n: "Flutter Entertainment PLC", c: "UK" },
  { id: 6152, s: "AGFB.BR", n: "AGFA Gevaert NV", c: "Belgium" },
  { id: 6153, s: "LAND.SW", n: "Landis+Gyr Group AG", c: "Switzerland" },
  { id: 6154, s: "068270.KS", n: "Celltrion Inc", c: "Korea" },
  { id: 6155, s: "HBH.DE", n: "Hornbach Holding VZO O.N.", c: "GER" },
  { id: 6156, s: "NTIC", n: "Northern Technologies", c: "US" },
  { id: 6157, s: "FCUV", n: "Focus Universal Inc", c: "US" },
  { id: 6158, s: "LILY34.SA", n: "Eli Lilly and Company", c: "Brazil" },
  { id: 6159, s: "MDVL", n: "Medavail Holdings Inc", c: "US" },
  { id: 6160, s: "DCBO.TO", n: "Docebo Inc", c: "CA" },
  { id: 6161, s: "PREC.PA", n: "Precia S.A", c: "FR" },
  { id: 6162, s: "SGMD", n: "Sugarmade Inc", c: "US" },
  { id: 6163, s: "CVLY", n: "Codorus Valley Bancorp Inc", c: "US" },
  { id: 6164, s: "EVI", n: "EVI Industries Inc", c: "US" },
  { id: 6165, s: "NNGRY", n: "NN Group NV ADR", c: "US" },
  { id: 6166, s: "ILU.AX", n: "Iluka Resources", c: "Australia" },
  { id: 6167, s: "CNTA", n: "Centessa Pharmaceuticals PLC ADR", c: "US" },
  { id: 6168, s: "SLN", n: "Silence Therapeutics PLC", c: "US" },
  { id: 6169, s: "WOR.AX", n: "Worley", c: "Australia" },
  { id: 6170, s: "BHC.TO", n: "Bausch Health Companies Inc", c: "CA" },
  { id: 6171, s: "LUC.TO", n: "Lucara Diamond Co", c: "CA" },
  { id: 6172, s: "CBAV.MC", n: "Clinica Baviera", c: "Spain" },
  { id: 6173, s: "AUOTY", n: "AU Optronics Co", c: "US" },
  { id: 6174, s: "PHD", n: "Pioneer Floating Rate Trust", c: "US" },
  { id: 6175, s: "AAME", n: "Atlantic American Corporation", c: "US" },
  { id: 6176, s: "BPRN", n: "Bank Of Princeton", c: "US" },
  { id: 6177, s: "ATHOF", n: "Athabasca Oil Co", c: "US" },
  { id: 6178, s: "RETC", n: "12 Retech Co", c: "US" },
  { id: 6179, s: "CNET", n: "ZW Data Action Technologies Inc", c: "US" },
  { id: 6180, s: "3037.TW", n: "Unimicron Technology Co", c: "TW" },
  { id: 6181, s: "HNSA.ST", n: "Hansa Biopharma AB", c: "Sweden" },
  { id: 6182, s: "EVN.AX", n: "Evolution Mining", c: "Australia" },
  { id: 6183, s: "MGM.V", n: "Maple Gold Mines", c: "CA" },
  { id: 6184, s: "AIMD", n: "Ainos Inc", c: "US" },
  { id: 6185, s: "VOE.VI", n: "Voestalpine AG", c: "Austria" },
  { id: 6186, s: "002475.SZ", n: "Luxshare Precision Industry", c: "CH" },
  { id: 6187, s: "HLOC", n: "World Technology Co", c: "US" },
  { id: 6188, s: "FEMY", n: "Femasys Inc", c: "US" },
  { id: 6189, s: "SMT.TO", n: "Sierra Metals Inc.", c: "CA" },
  { id: 6190, s: "VDRM", n: "Viaderma Inc", c: "US" },
  { id: 6191, s: "FSEA", n: "First Seacoast BanCo", c: "US" },
  { id: 6192, s: "OWUV", n: "One World Universe Inc", c: "US" },
  { id: 6193, s: "AKU", n: "Akumin Inc", c: "US" },
  { id: 6194, s: "FNWB", n: "First Northwest BanCo", c: "US" },
  { id: 6195, s: "IRIX", n: "IRIDEX Corporation", c: "US" },
  { id: 6196, s: "CGO.TO", n: "Cogeco Inc.", c: "CA" },
  { id: 6197, s: "CNE.L", n: "Capricorn Energy PLC", c: "UK" },
  { id: 6198, s: "RMED", n: "RA Medical Systems", c: "US" },
  { id: 6199, s: "BRC.V", n: "Blackrock Silver Co", c: "CA" },
  { id: 6200, s: "LME.V", n: "Laurion Mineral Exploration Inc", c: "CA" },
  { id: 6201, s: "PSSA3.SA", n: "Porto Seguro S.A", c: "Brazil" },
  { id: 6202, s: "SMIT", n: "Schmitt Industries Inc", c: "US" },
  { id: 6203, s: "NRC", n: "National Research Co", c: "US" },
  { id: 6204, s: "FSKRS.HE", n: "Fiskars Oyj Abp", c: "Finland" },
  { id: 6205, s: "VBK.DE", n: "VERBIO Vereinigte BioEnergie AG", c: "GER" },
  { id: 6206, s: "CNFR", n: "Conifer Holding Inc", c: "US" },
  { id: 6207, s: "MONET.PR", n: "Moneta Money Bank AS", c: "Czech Republic" },
  { id: 6208, s: "LR.PA", n: "Legrand SA", c: "FR" },
  { id: 6209, s: "GARAN.IS", n: "Turkiye Garanti Bankasi A.S.", c: "Turkey" },
  { id: 6210, s: "SOFF.OL", n: "Solstad Offsho", c: "Norway" },
  { id: 6211, s: "WMPN", n: "William Penn Bancorp Inc", c: "US" },
  { id: 6212, s: "VBNK", n: "VersaBank", c: "US" },
  { id: 6213, s: "CPSI", n: "Computer Programs and Systems Inc", c: "US" },
  { id: 6214, s: "IMRX", n: "Immuneering Co", c: "US" },
  { id: 6215, s: "8932.TWO", n: "Max Zipper", c: "TW" },
  { id: 6216, s: "600104.SS", n: "SAIC Motor Co", c: "CH" },
  { id: 6217, s: "CCBG", n: "Capital City Bank Group", c: "US" },
  { id: 6218, s: "WJX.TO", n: "Wajax Corporation", c: "CA" },
  { id: 6219, s: "HCITY.MX", n: "Hoteles City Express S.A.B. de C.V", c: "Mexi" },
  { id: 6220, s: "TXG.TO", n: "Torex Gold Resources Inc", c: "CA" },
  { id: 6221, s: "AMZN.NE", n: "Amazon.com CDR", c: "CA" },
  { id: 6222, s: "CHN.AX", n: "Chalice Mining Limited", c: "Australia" },
  { id: 6223, s: "SRP.L", n: "Serco Group", c: "UK" },
  { id: 6224, s: "EBTC", n: "Enterprise Bancorp Inc", c: "US" },
  { id: 6225, s: "VIGL", n: "Vigil Neuroscience Inc", c: "US" },
  { id: 6226, s: "MEDS", n: "Trxade Group Inc", c: "US" },
  { id: 6227, s: "NTGY.MC", n: "Naturgy Energy Group SA", c: "Spain" },
  { id: 6228, s: "CGEMY", n: "Capgemini SE ADR", c: "US" },
  { id: 6229, s: "SGLY", n: "Singularity Future Technology", c: "US" },
  { id: 6230, s: "SUS.L", n: "S&U plc", c: "UK" },
  { id: 6231, s: "DHIL", n: "Diamond Hill Investment Group Inc", c: "US" },
  { id: 6232, s: "RNVA", n: "Rennova Health Inc", c: "US" },
  { id: 6233, s: "SENEB", n: "Seneca Foods Corp B", c: "US" },
  { id: 6234, s: "PRL.TO", n: "Propel Holdings Inc", c: "CA" },
  { id: 6235, s: "ZIVO", n: "Zivo Bioscience Inc", c: "US" },
  { id: 6236, s: "LUMI.TA", n: "Bank Leumi Le-Israel B.M", c: "Israel" },
  { id: 6237, s: "FLME", n: "Flame Acquisition Co", c: "US" },
  { id: 6238, s: "RCRUY", n: "Recruit Holdings Co ADR", c: "US" },
  { id: 6239, s: "IQ.V", n: "AirIQ Inc", c: "CA" },
  { id: 6240, s: "VGM", n: "Invesco Trust For Inv Grade Munis", c: "US" },
  { id: 6241, s: "PBFS", n: "Pioneer Bancorp Inc", c: "US" },
  { id: 6242, s: "LPE.PA", n: "Laurent-Perrier", c: "FR" },
  { id: 6243, s: "VLI.V", n: "Vision Lithium Inc", c: "CA" },
  { id: 6244, s: "CHC.AX", n: "Charter Hall Group", c: "Australia" },
  { id: 6245, s: "DPWW", n: "Diego Pellicer Worldwide Inc", c: "US" },
  { id: 6246, s: "DLG.L", n: "Direct Line Insurance Group plc", c: "UK" },
  { id: 6247, s: "RMV.L", n: "Rightmove PLC", c: "UK" },
  { id: 6248, s: "AVTX.AS", n: "Avantium Holding BV", c: "Netherlands" },
  { id: 6249, s: "BWAY", n: "Brainsway", c: "US" },
  { id: 6250, s: "CRH.L", n: "CRH PLC", c: "UK" },
  { id: 6251, s: "MXC", n: "Mexco Energy Corporation", c: "US" },
  { id: 6252, s: "ELUX-B.ST", n: "AB Electrolux (publ)", c: "Sweden" },
  { id: 6253, s: "BVNNF", n: "Bevcanna Enterprises Inc", c: "US" },
  { id: 6254, s: "RDI", n: "Reading International Inc", c: "US" },
  { id: 6255, s: "ALKM", n: "Alkame Holdings Inc", c: "US" },
  { id: 6256, s: "FFNW", n: "First Financial Northwest Inc", c: "US" },
  { id: 6257, s: "MCPHY.PA", n: "Mcphy Energy", c: "FR" },
  { id: 6258, s: "CEAD", n: "CEA Industries Inc", c: "US" },
  { id: 6259, s: "UTI", n: "Universal Technical Institute Inc", c: "US" },
  { id: 6260, s: "CSBR", n: "Champions Oncology Inc", c: "US" },
  { id: 6261, s: "MEGEF", n: "MEG Energy Co", c: "US" },
  { id: 6262, s: "IMPN.SW", n: "Implenia AG", c: "Switzerland" },
  { id: 6263, s: "ARIS.TO", n: "Aris Gold Co", c: "CA" },
  { id: 6264, s: "MUEL", n: "Paul Mueller", c: "US" },
  { id: 6265, s: "LNF.TO", n: "Leon's Furniture Limited", c: "CA" },
  { id: 6266, s: "WAVD", n: "Wavedancer Inc", c: "US" },
  { id: 6267, s: "CRT-UN.TO", n: "CT Real Estate Investment Trust", c: "CA" },
  { id: 6268, s: "MRAAY", n: "Murata Manufacturing Inc", c: "US" },
  { id: 6269, s: "XLO", n: "Xilio Development Inc", c: "US" },
  { id: 6270, s: "SBGSF", n: "Schneider Electric SE", c: "US" },
  { id: 6271, s: "ULTHF", n: "United Lithium Co", c: "US" },
  { id: 6272, s: "GBNH", n: "Greenbrook TMS Inc", c: "US" },
  { id: 6273, s: "COE", n: "51Talk Online Education Group", c: "US" },
  { id: 6274, s: "LMNL", n: "Liminal BioSciences Inc", c: "US" },
  { id: 6275, s: "SQZ.L", n: "Serica Energy PLC", c: "UK" },
  { id: 6276, s: "SOLG.L", n: "SolGold PLC", c: "UK" },
  { id: 6277, s: "SUN.AX", n: "Suncorp Group", c: "Australia" },
  { id: 6278, s: "WILD.TO", n: "WildBrain", c: "CA" },
  { id: 6279, s: "VCM.TO", n: "Vecima Networks Inc.", c: "CA" },
  { id: 6280, s: "IOR", n: "Income Opportunity Realty Investors", c: "US" },
  { id: 6281, s: "DE.V", n: "Decisive Dividend Co", c: "CA" },
  { id: 6282, s: "QQ.L", n: "Qinetiq Group PLC", c: "UK" },
  { id: 6283, s: "SOLR.V", n: "Solar Alliance Energy Inc", c: "CA" },
  { id: 6284, s: "1303.TW", n: "Nan Ya Plastics Co", c: "TW" },
  { id: 6285, s: "ERMAY", n: "Eramet SA ADR", c: "US" },
  { id: 6286, s: "SES.TO", n: "Secure Energy Services Inc.", c: "CA" },
  { id: 6287, s: "MMED.NE", n: "Mind Medicine Inc", c: "CA" },
  { id: 6288, s: "PSK.TO", n: "PrairieSky Royalty", c: "CA" },
  { id: 6289, s: "KYNC", n: "KYN Capital Group Inc", c: "US" },
  { id: 6290, s: "INCH.L", n: "Inchcape PLC", c: "UK" },
  { id: 6291, s: "600111.SS", n: "CH Northern Rare Earth Group High-Tech", c: "CH" },
  { id: 6292, s: "FEIM", n: "Frequency Electronics Inc", c: "US" },
  { id: 6293, s: "SVM.AX", n: "Sovereign Metals", c: "Australia" },
  { id: 6294, s: "SLS.TO", n: "Solaris Resources Inc", c: "CA" },
  { id: 6295, s: "BKT.MC", n: "Bankinter", c: "Spain" },
  { id: 6296, s: "ALV.V", n: "Alvopetro Energy", c: "CA" },
  { id: 6297, s: "TNDO.TA", n: "Tondo Smart", c: "Israel" },
  { id: 6298, s: "FLT.AX", n: "Flight Centre", c: "Australia" },
  { id: 6299, s: "CENTA", n: "Central Garden & Pet Company A", c: "US" },
  { id: 6300, s: "HTO.AT", n: "Hellenic Telecommunications Organization S.A", c: "Greece" },
  { id: 6301, s: "ANRG.TO", n: "Anaergia Inc", c: "CA" },
  { id: 6302, s: "ELD.AX", n: "Elders", c: "Australia" },
  { id: 6303, s: "UBCP", n: "United Bancorp Inc", c: "US" },
  { id: 6304, s: "BGEO.L", n: "Bank of Georgia Group PLC", c: "UK" },
  { id: 6305, s: "A5G.IR", n: "AIB Group PLC", c: "Ireland" },
  { id: 6306, s: "DGHI", n: "Digihost Technology Inc", c: "US" },
  { id: 6307, s: "FNV.TO", n: "Franco-Nevada Corporation", c: "CA" },
  { id: 6308, s: "FIL.TO", n: "Filo Mining Co", c: "CA" },
  { id: 6309, s: "VER.VI", n: "VERBUND AG", c: "Austria" },
  { id: 6310, s: "2886.TW", n: "Mega Financial Holding", c: "TW" },
  { id: 6311, s: "CSTE", n: "Caesarstone", c: "US" },
  { id: 6312, s: "GTEH", n: "Gentech Holdings Inc", c: "US" },
  { id: 6313, s: "PMM", n: "Putnam Managed Municipal Income Closed Fund", c: "US" },
  { id: 6314, s: "BOLSAA.MX", n: "Bolsa Mexicana de Valores S.A.B. de C.V", c: "Mexi" },
  { id: 6315, s: "ALFA.L", n: "Alfa Financial Software Holdings PLC", c: "UK" },
  { id: 6316, s: "GRDM.V", n: "Grid Metals Co", c: "CA" },
  { id: 6317, s: "CRR-UN.TO", n: "Crombie Real Estate Investment Trust", c: "CA" },
  { id: 6318, s: "IIM", n: "Invesco Value Municipal Income Closed Fund", c: "US" },
  { id: 6319, s: "FSFG", n: "First Savings Financial Group Inc", c: "US" },
  { id: 6320, s: "CADL", n: "Candel Therapeutics Inc", c: "US" },
  { id: 6321, s: "RYA.IR", n: "Ryanair Holdings plc", c: "Ireland" },
  { id: 6322, s: "FLS.", n: "FLSmidth & Co.", c: "Denmark" },
  { id: 6323, s: "GRNQ", n: "GreenPro Capital Co", c: "US" },
  { id: 6324, s: "LIVEPOLC-1.MX", n: "El Puerto de Liverpool S.A.B. de C.V", c: "Mexi" },
  { id: 6325, s: "PHARM.AS", n: "Pharming Group NV", c: "Netherlands" },
  { id: 6326, s: "SAHOL.IS", n: "Haci Omer Sabanci Holding AS", c: "Turkey" },
  { id: 6327, s: "CG.TO", n: "Centerra Gold Inc", c: "CA" },
  { id: 6328, s: "NOW.V", n: "NowVertical Group Inc", c: "CA" },
  { id: 6329, s: "MOVI3.SA", n: "Movida Participações S.A", c: "Brazil" },
  { id: 6330, s: "BEKB.BR", n: "NV Bekaert SA", c: "Belgium" },
  { id: 6331, s: "CS.TO", n: "Capstone Mining Co", c: "CA" },
  { id: 6332, s: "AG1.DE", n: "AUTO1 Group SE", c: "GER" },
  { id: 6333, s: "BHPLF", n: "BHP Group Limited", c: "US" },
  { id: 6334, s: "BOL.PA", n: "Bollore SA", c: "FR" },
  { id: 6335, s: "BLRX.TA", n: "BioLine RX", c: "Israel" },
  { id: 6336, s: "TKNO", n: "Alpha Teknova Inc", c: "US" },
  { id: 6337, s: "BOSS.DE", n: "Hugo Boss AG", c: "GER" },
  { id: 6338, s: "STLC.TO", n: "Stelco Holdings Inc", c: "CA" },
  { id: 6339, s: "GEVI", n: "General Environmental Management Inc", c: "US" },
  { id: 6340, s: "SFZN.SW", n: "Siegfried Holding", c: "Switzerland" },
  { id: 6341, s: "CMBN.SW", n: "Cembra Money Bank AG", c: "Switzerland" },
  { id: 6342, s: "LABB.MX", n: "Genomma Lab Internacional S.A.B. de C.V", c: "Mexi" },
  { id: 6343, s: "NCK.AX", n: "Nick Scali", c: "Australia" },
  { id: 6344, s: "HYT.AX", n: "HyTerra.", c: "Australia" },
  { id: 6345, s: "SLM.AX", n: "Solis Minerals DRC", c: "Australia" },
  { id: 6346, s: "RS.TO", n: "Real Estate & E-Commerce Split Co", c: "CA" },
  { id: 6347, s: "DIV.TO", n: "Diversified Royalty Co", c: "CA" },
  { id: 6348, s: "MOFG", n: "MidWestOne Financial Group Inc", c: "US" },
  { id: 6349, s: "ELSE", n: "Electro-Sensors Inc", c: "US" },
  { id: 6350, s: "SPIE.PA", n: "SPIE SA", c: "FR" },
  { id: 6351, s: "PWP", n: "Perella Weinberg Partners", c: "US" },
  { id: 6352, s: "DNZOY", n: "Denso Corp ADR", c: "US" },
  { id: 6353, s: "CTGO", n: "Contango ORE Inc", c: "US" },
  { id: 6354, s: "GEI.TO", n: "Gibson Energy Inc.", c: "CA" },
  { id: 6355, s: "MDG1.DE", n: "Medigene AG", c: "GER" },
  { id: 6356, s: "CNPOF", n: "RIV Capital Inc", c: "US" },
  { id: 6357, s: "HOTEL.MX", n: "Grupo Hotelero Santa Fe SAB", c: "Mexi" },
  { id: 6358, s: "AFAGR.HE", n: "Afarak Group Oyj", c: "Finland" },
  { id: 6359, s: "FKWL", n: "Franklin Wireless Co", c: "US" },
  { id: 6360, s: "NOUV", n: "Nouveau Life Pharmaceuticals Inc", c: "US" },
  { id: 6361, s: "BOOZT.ST", n: "Boozt AB", c: "Sweden" },
  { id: 6362, s: "SYIEY", n: "Symrise Ag PK", c: "US" },
  { id: 6363, s: "TOH.V", n: "Total Helium", c: "CA" },
  { id: 6364, s: "STN.TO", n: "Stantec Inc", c: "CA" },
  { id: 6365, s: "ERFSF", n: "Eurofins Scientific SE", c: "US" },
  { id: 6366, s: "CWEB.TO", n: "Charlotte's Web Holdings Inc", c: "CA" },
  { id: 6367, s: "SPX.L", n: "Spirax-Sarco Engineering PLC", c: "UK" },
  { id: 6368, s: "ADE.OL", n: "Adevinta ASA", c: "Norway" },
  { id: 6369, s: "ALTN.L", n: "AltynGold plc", c: "UK" },
  { id: 6370, s: "SLGL", n: "Sol Gel Technologies", c: "US" },
  { id: 6371, s: "HOVE.", n: "Hove AS", c: "Denmark" },
  { id: 6372, s: "BDGI.TO", n: "Badger Infrastructure Solutions", c: "CA" },
  { id: 6373, s: "WR1.AX", n: "Winsome Resources", c: "Australia" },
  { id: 6374, s: "IMU.AX", n: "Imugene", c: "Australia" },
  { id: 6375, s: "PUYI", n: "Puyi Inc ADR", c: "US" },
  { id: 6376, s: "PCELL.ST", n: "Powercell Sweden", c: "Sweden" },
  { id: 6377, s: "AZYO", n: "Aziyo Biologics Inc", c: "US" },
  { id: 6378, s: "CURN", n: "Currency Exchange International Co", c: "US" },
  { id: 6379, s: "NTRB", n: "Nutriband Inc", c: "US" },
  { id: 6380, s: "KRMD", n: "Repro Med Systems Inc", c: "US" },
  { id: 6381, s: "BRFH", n: "Barfresh Food Group Inc", c: "US" },
  { id: 6382, s: "IPG.AX", n: "IPD Group", c: "Australia" },
  { id: 6383, s: "MULT3.SA", n: "Multiplan Empreendimentos Imobiliários S.A", c: "Brazil" },
  { id: 6384, s: "MGROS.IS", n: "Migros Ticaret AS", c: "Turkey" },
  { id: 6385, s: "UBQU", n: "Ubiquitech Software", c: "US" },
  { id: 6386, s: "PFX", n: "Phenixfin Corporation", c: "US" },
  { id: 6387, s: "600019.SS", n: "Baoshan Iron & Steel", c: "CH" },
  { id: 6388, s: "UMICY", n: "Umicore SA ADR", c: "US" },
  { id: 6389, s: "SCHN.SW", n: "Schindler Holding AG", c: "Switzerland" },
  { id: 6390, s: "AAK.ST", n: "AAK AB", c: "Sweden" },
  { id: 6391, s: "CIA.AX", n: "Champion Iron", c: "Australia" },
  { id: 6392, s: "HFBL", n: "Home Federal Bancorp Louisiana", c: "US" },
  { id: 6393, s: "OCG.V", n: "Outcrop Gold Co", c: "CA" },
  { id: 6394, s: "PALT", n: "Paltalk Inc", c: "US" },
  { id: 6395, s: "FVI.TO", n: "Fortuna Silver Mines Inc", c: "CA" },
  { id: 6396, s: "VTSC.DE", n: "Vitesco Technologies Group Aktiengesellschaft", c: "GER" },
  { id: 6397, s: "SMBK", n: "SmartFinancial Inc", c: "US" },
  { id: 6398, s: "CGA", n: "CH Green Agriculture Inc", c: "US" },
  { id: 6399, s: "HHFA.DE", n: "Hamburger Hafen Und Logistik A-SP", c: "GER" },
  { id: 6400, s: "VLA.PA", n: "Valneva", c: "FR" },
  { id: 6401, s: "NCTY", n: "The9 ADR", c: "US" },
  { id: 6402, s: "PRNDY", n: "Pernod Ricard SA.", c: "US" },
  { id: 6403, s: "UBXN.SW", n: "U Blox Holding AG", c: "Switzerland" },
  { id: 6404, s: "COR.LS", n: "Corticeira Amorim", c: "Portugal" },
  { id: 6405, s: "SLBEN.LS", n: "Benfica", c: "Portugal" },
  { id: 6406, s: "TUWOY", n: "Tullow Oil PLC ADR", c: "US" },
  { id: 6407, s: "PWOD", n: "Penns Woods Bancorp Inc", c: "US" },
  { id: 6408, s: "FFARM.AS", n: "ForFarmers NV", c: "Netherlands" },
  { id: 6409, s: "APPS.MC", n: "Applus Servicios Tecnologicos", c: "Spain" },
  { id: 6410, s: "KCO.DE", n: "Klöckner & Co SE", c: "GER" },
  { id: 6411, s: "HALB", n: "Tykhe Co", c: "US" },
  { id: 6412, s: "EZTC3.SA", n: "EZTEC Empreendimentos e Participações S.A", c: "Brazil" },
  { id: 6413, s: "NKSH", n: "National Bankshares Inc", c: "US" },
  { id: 6414, s: "RVRC.ST", n: "RVRC Holding AB", c: "Sweden" },
  { id: 6415, s: "ALCTL.IS", n: "Alcatel Lucent Teletas Telekomunikasyon AS", c: "Turkey" },
  { id: 6416, s: "GWLLF", n: "Great Wall Motor", c: "US" },
  { id: 6417, s: "DYAI", n: "Dyadic International Inc", c: "US" },
  { id: 6418, s: "OXIG.L", n: "Oxford Instruments PLC", c: "UK" },
  { id: 6419, s: "NECB", n: "Northeast Community Bancorp Inc", c: "US" },
  { id: 6420, s: "BUILD.ST", n: "BuildData Group AB", c: "Sweden" },
  { id: 6421, s: "GETI-B.ST", n: "Getinge AB ser. B", c: "Sweden" },
  { id: 6422, s: "GSF.OL", n: "Grieg Seafood ASA", c: "Norway" },
  { id: 6423, s: "KEMPOWR.HE", n: "Kempower Oyj", c: "Finland" },
  { id: 6424, s: "KMP-UN.TO", n: "Killam Apartment Real Estate Investment Trust", c: "CA" },
  { id: 6425, s: "SFBC", n: "Sound Financial Bancorp Inc", c: "US" },
  { id: 6426, s: "INDT.ST", n: "Indutrade AB", c: "Sweden" },
  { id: 6427, s: "MRD.TO", n: "Melcor Developments.", c: "CA" },
  { id: 6428, s: "XRTX", n: "XORTX Therapeutics Inc", c: "US" },
  { id: 6429, s: "DWAHY", n: "Daiwa House Industry Co ADR", c: "US" },
  { id: 6430, s: "UBSFY", n: "UbiSoft Entertainment Inc", c: "US" },
  { id: 6431, s: "TOBII.ST", n: "Tobii AB", c: "Sweden" },
  { id: 6432, s: "035720.KS", n: "Kakao Co", c: "Korea" },
  { id: 6433, s: "TOKMAN.HE", n: "Tokmanni Group Oyj", c: "Finland" },
  { id: 6434, s: "BPT.AX", n: "Beach Energy", c: "Australia" },
  { id: 6435, s: "TTNP", n: "Titan Pharmaceuticals Inc", c: "US" },
  { id: 6436, s: "WFCF", n: "Where Food Comes From Inc", c: "US" },
  { id: 6437, s: "ET.TO", n: "Evertz Technologies Limited", c: "CA" },
  { id: 6438, s: "BTA", n: "BlackRock Long-Term Municipal Advantage Trust", c: "US" },
  { id: 6439, s: "KIND-SDB.ST", n: "Kindred Group plc", c: "Sweden" },
  { id: 6440, s: "FRAGUAB.MX", n: "Corporativo Fragua S.A.B. de C.V", c: "Mexi" },
  { id: 6441, s: "AND.TO", n: "Andlauer Healthcare Gr", c: "CA" },
  { id: 6442, s: "BSRR", n: "Sierra BanCo", c: "US" },
  { id: 6443, s: "LARK", n: "Landmark Bancorp Inc", c: "US" },
  { id: 6444, s: "600887.SS", n: "Inner Mongolia Yili Industrial Group", c: "CH" },
  { id: 6445, s: "GOMX.ST", n: "GomSpace Group AB", c: "Sweden" },
  { id: 6446, s: "NRDBY", n: "Nordea Bank Abp ADR", c: "US" },
  { id: 6447, s: "AYA.TO", n: "Aya Gold & Silver Inc", c: "CA" },
  { id: 6448, s: "CGCBV.HE", n: "Cargotec Oyj", c: "Finland" },
  { id: 6449, s: "BANT", n: "Bantek Inc", c: "US" },
  { id: 6450, s: "TPHS", n: "Trinity Place Holdings Inc", c: "US" },
  { id: 6451, s: "RUSHB", n: "Rush Enterprises B Inc", c: "US" },
  { id: 6452, s: "ENS.TO", n: "E Split Corp Class A", c: "CA" },
  { id: 6453, s: "CTIB", n: "Yunhong CTI", c: "US" },
  { id: 6454, s: "KVSA", n: "Khosla Ventures Acquisition", c: "US" },
  { id: 6455, s: "TLPFY", n: "Teleperformance PK", c: "US" },
  { id: 6456, s: "PMO", n: "Putnam Municipal Opportunities Trust", c: "US" },
  { id: 6457, s: "AZA.ST", n: "Avanza Bank Holding AB (publ)", c: "Sweden" },
  { id: 6458, s: "AURE.PA", n: "Aurea SA", c: "FR" },
  { id: 6459, s: "FNLPF", n: "Fresnillo PLC", c: "US" },
  { id: 6460, s: "UTDI.DE", n: "United Internet AG NA", c: "GER" },
  { id: 6461, s: "LGO.TO", n: "Largo Resources", c: "CA" },
  { id: 6462, s: "DEME.BR", n: "DEME Group NV", c: "Belgium" },
  { id: 6463, s: "2630.TW", n: "Air Asia", c: "TW" },
  { id: 6464, s: "TCW.TO", n: "Trican Well Service.", c: "CA" },
  { id: 6465, s: "SVT.L", n: "Severn Trent PLC", c: "UK" },
  { id: 6466, s: "SCG.AX", n: "Scentre Group", c: "Australia" },
  { id: 6467, s: "GISSAA.MX", n: "Grupo Industrial Saltillo S.A.B. de C.V", c: "Mexi" },
  { id: 6468, s: "MAL.TO", n: "Magellan Aerospace Corporation", c: "CA" },
  { id: 6469, s: "CFSB", n: "CFSB Bancorp Inc", c: "US" },
  { id: 6470, s: "DS.TO", n: "Dividend Select 15 Co", c: "CA" },
  { id: 6471, s: "NFBK", n: "Northfield Bancorp Inc", c: "US" },
  { id: 6472, s: "KOFOL.PR", n: "Kofola CeskoSlovensko as", c: "Czech Republic" },
  { id: 6473, s: "FORT.L", n: "Forterra PLC", c: "UK" },
  { id: 6474, s: "BANB.SW", n: "Bachem Holding AG", c: "Switzerland" },
  { id: 6475, s: "ENEV3.SA", n: "Eneva S.A", c: "Brazil" },
  { id: 6476, s: "JFU", n: "9F Inc", c: "US" },
  { id: 6477, s: "ADT.AX", n: "Adriatic Metals Plc", c: "Australia" },
  { id: 6478, s: "OVBC", n: "Ohio Valley Banc Co", c: "US" },
  { id: 6479, s: "ICLK", n: "iClick Interactive Asia Group", c: "US" },
  { id: 6480, s: "CRON.TO", n: "Cronos Group Inc", c: "CA" },
  { id: 6481, s: "JIN.AX", n: "Jumbo Interactive", c: "Australia" },
  { id: 6482, s: "MFF.AX", n: "MFF Capital Investments", c: "Australia" },
  { id: 6483, s: "2884.TW", n: "E.SUN Financial Holding", c: "TW" },
  { id: 6484, s: "2412.TW", n: "Chunghwa Telecom", c: "TW" },
  { id: 6485, s: "ICL.TA", n: "ICL Israel Chemicals", c: "Israel" },
  { id: 6486, s: "CPINV.BR", n: "Care Property Invest NV", c: "Belgium" },
  { id: 6487, s: "SSBK", n: "Southern States Bancshares Inc", c: "US" },
  { id: 6488, s: "EMO.V", n: "Emerita Resources Co", c: "CA" },
  { id: 6489, s: "SRT.DE", n: "Sartorius Aktiengesellschaft", c: "GER" },
  { id: 6490, s: "CTG", n: "Computer Task Group Incorporated", c: "US" },
  { id: 6491, s: "RCK.V", n: "Rock Tech Lithium Inc", c: "CA" },
  { id: 6492, s: "EMMA", n: "Emmaus Life Sciences Inc", c: "US" },
  { id: 6493, s: "MHI", n: "Pioneer Municipal High Income Trust", c: "US" },
  { id: 6494, s: "OCLN", n: "OriginClear Inc", c: "US" },
  { id: 6495, s: "RHC.AX", n: "Ramsay Health Care", c: "Australia" },
  { id: 6496, s: "ISV.TO", n: "Information Services Corporation", c: "CA" },
  { id: 6497, s: "KIT.OL", n: "Kitron ASA", c: "Norway" },
  { id: 6498, s: "RBO.PA", n: "Roche Bobois", c: "FR" },
  { id: 6499, s: "SAMS.PA", n: "Samse SA", c: "FR" },
  { id: 6500, s: "GRCU", n: "Green Cures & Botanical Distribution Inc", c: "US" },
  { id: 6501, s: "RENT3.SA", n: "Localiza Rent a Car S.A", c: "Brazil" },
  { id: 6502, s: "600028.SS", n: "CH Petroleum & Chemical Corp Class A", c: "CH" },
  { id: 6503, s: "BBDO", n: "Banco Bradesco S/A ADR", c: "US" },
  { id: 6504, s: "CMMB", n: "Chemomab Therapeutics DRC", c: "US" },
  { id: 6505, s: "002156.SZ", n: "TongFu Microelectronics", c: "CH" },
  { id: 6506, s: "VCI.V", n: "Vitreous Glass Inc", c: "CA" },
  { id: 6507, s: "SWON.SW", n: "Softwareone Holding", c: "Switzerland" },
  { id: 6508, s: "AAPL34.SA", n: "Apple Inc", c: "Brazil" },
  { id: 6509, s: "002493.SZ", n: "Rongsheng Petrochemical", c: "CH" },
  { id: 6510, s: "ARCAD.AS", n: "Arcadis N.V.", c: "Netherlands" },
  { id: 6511, s: "TCBX", n: "Third Coast Bancshares Inc", c: "US" },
  { id: 6512, s: "CALN.SW", n: "Calida Holding AG", c: "Switzerland" },
  { id: 6513, s: "THULE.ST", n: "Thule Group AB", c: "Sweden" },
  { id: 6514, s: "MKP.TO", n: "MCAN Mortgage Corporation", c: "CA" },
  { id: 6515, s: "TKAMY", n: "Thyssenkrupp AG ADR", c: "US" },
  { id: 6516, s: "GALE.SW", n: "Galenica Sante AG", c: "Switzerland" },
  { id: 6517, s: "KDK.V", n: "Kodiak Copper Co", c: "CA" },
  { id: 6518, s: "OXUR.BR", n: "Oxurion NV", c: "Belgium" },
  { id: 6519, s: "ADH.AX", n: "Adairs", c: "Australia" },
  { id: 6520, s: "MUX.DE", n: "Mutares SE & Co. KGaA", c: "GER" },
  { id: 6521, s: "KSB3.DE", n: "KSB SE & Co. KGaA", c: "GER" },
  { id: 6522, s: "OIA", n: "Invesco Municipal Income Opportunities Closed Fund Class Common", c: "US" },
  { id: 6523, s: "MITQ", n: "Moving iMage Technologies Inc", c: "US" },
  { id: 6524, s: "HDD.DE", n: "Heidelberger Druckmaschinen Aktiengesellschaft", c: "GER" },
  { id: 6525, s: "OLE.MC", n: "Deoleo S.A", c: "Spain" },
  { id: 6526, s: "VAC.PA", n: "Pierre et Vacances SA", c: "FR" },
  { id: 6527, s: "INE.TO", n: "Innergex Renewable Energy Inc.", c: "CA" },
  { id: 6528, s: "FIE.DE", n: "Fielmann Aktiengesellschaft", c: "GER" },
  { id: 6529, s: "EFR.TO", n: "Energy Fuels Inc", c: "CA" },
  { id: 6530, s: "603033.SS", n: "Zhejiang Sanwei Rubber Item", c: "CH" },
  { id: 6531, s: "MCHX", n: "Marchex Inc", c: "US" },
  { id: 6532, s: "SSNT", n: "SilverSun Technologies Inc", c: "US" },
  { id: 6533, s: "GENN", n: "Genesis Healthcare Inc", c: "US" },
  { id: 6534, s: "MCBS", n: "MetroCity Bankshares", c: "US" },
  { id: 6535, s: "HUGE", n: "FSD Pharma Inc Class B", c: "US" },
  { id: 6536, s: "BCP.LS", n: "Banco Comercial Portugues", c: "Portugal" },
  { id: 6537, s: "ATC.AX", n: "Altech Batteries Limited", c: "Australia" },
  { id: 6538, s: "DIM.PA", n: "Sartorius Stedim Biotech SA", c: "FR" },
  { id: 6539, s: "GCC.MX", n: "Grupo Cementos de Chihuahua S.A.B. de C.V", c: "Mexi" },
  { id: 6540, s: "IPN.PA", n: "Ipsen SA", c: "FR" },
  { id: 6541, s: "CUR.V", n: "International Consolidated Uranium Inc", c: "CA" },
  { id: 6542, s: "DFDS.", n: "DFDS A/S", c: "Denmark" },
  { id: 6543, s: "PNGAY", n: "Ping An Insurance Company of CH", c: "US" },
  { id: 6544, s: "TGGI", n: "Trans Global Grp In", c: "US" },
  { id: 6545, s: "ESOA", n: "Energy Services Of America Co", c: "US" },
  { id: 6546, s: "ERA.PA", n: "Eramet SA", c: "FR" },
  { id: 6547, s: "RXL.PA", n: "Rexel S.A", c: "FR" },
  { id: 6548, s: "NCU.TO", n: "Nevada Copper Corp.", c: "CA" },
  { id: 6549, s: "000625.SZ", n: "Chongqing Changan Automobile", c: "CH" },
  { id: 6550, s: "FUNC", n: "First United Corporation", c: "US" },
  { id: 6551, s: "KINV-B.ST", n: "Kinnevik Investment AB ser. B", c: "Sweden" },
  { id: 6552, s: "STRT", n: "Strattec Security Corporation", c: "US" },
  { id: 6553, s: "ITIC", n: "Investors Title Company", c: "US" },
  { id: 6554, s: "FSBC", n: "Five Star BanCo", c: "US" },
  { id: 6555, s: "EXPRS2.ST", n: "ExpreS2ion Biotech Holding AB", c: "Sweden" },
  { id: 6556, s: "EIN3.DE", n: "Einhell GER AG", c: "GER" },
  { id: 6557, s: "002241.SZ", n: "Goertek Inc", c: "CH" },
  { id: 6558, s: "MIR.V", n: "MedMira Inc", c: "CA" },
  { id: 6559, s: "SAMG", n: "Silvercrest Asset Management Group Inc", c: "US" },
  { id: 6560, s: "ATE.PA", n: "Alten SA", c: "FR" },
  { id: 6561, s: "SYPR", n: "Sypris Solutions Inc", c: "US" },
  { id: 6562, s: "LVC.WA", n: "Livechat Software SA", c: "Poland" },
  { id: 6563, s: "SGR-UN.TO", n: "Slate Grocery REIT", c: "CA" },
  { id: 6564, s: "ELEKTRA.MX", n: "Grupo Elektra S.A.B. de C.V", c: "Mexi" },
  { id: 6565, s: "CPHC", n: "Canterbury Park Holding Corporation", c: "US" },
  { id: 6566, s: "AUCOY", n: "Polymetal International PLC ADR", c: "US" },
  { id: 6567, s: "ORE.TO", n: "Orezone Gold Co", c: "CA" },
  { id: 6568, s: "CSTR", n: "Capstar Financial Holdings Inc", c: "US" },
  { id: 6569, s: "DBG.PA", n: "Derichebourg", c: "FR" },
  { id: 6570, s: "300760.SZ", n: "Shenzhen Mindray Bio-Medical Electronics Co Class A", c: "CH" },
  { id: 6571, s: "OMVKY", n: "OMV AG PK", c: "US" },
  { id: 6572, s: "FIHO12.MX", n: "FibraHotel", c: "Mexi" },
  { id: 6573, s: "BCAL", n: "Southern California Bancorp Common Stock", c: "US" },
  { id: 6574, s: "AAC.AX", n: "Australian Agricultural Company", c: "Australia" },
  { id: 6575, s: "WIE.VI", n: "Wienerberger AG", c: "Austria" },
  { id: 6576, s: "COFS", n: "ChoiceOne Financial Services Inc", c: "US" },
  { id: 6577, s: "PERI.TA", n: "Perion Network", c: "Israel" },
  { id: 6578, s: "GYC.DE", n: "Grand City Properties SA", c: "GER" },
  { id: 6579, s: "LEHTO.HE", n: "Lehto Group Oyj", c: "Finland" },
  { id: 6580, s: "GRX", n: "Gabelli Healthcare & WellnessRx Trust", c: "US" },
  { id: 6581, s: "BMY.L", n: "Bloomsbury Publishing Plc", c: "UK" },
  { id: 6582, s: "AX1.AX", n: "Accent Group", c: "Australia" },
  { id: 6583, s: "PHE.L", n: "PowerHouse Energy Group Plc", c: "UK" },
  { id: 6584, s: "DHG.IR", n: "Dalata Hotel Group Plc", c: "Ireland" },
  { id: 6585, s: "YKLTY", n: "Yakult Honsha Co ADR", c: "US" },
  { id: 6586, s: "HDELY", n: "HeidelbergCement AG ADR", c: "US" },
  { id: 6587, s: "UKW.L", n: "Greencoat UK Wind PLC", c: "UK" },
  { id: 6588, s: "ETER3.SA", n: "Eternit S.A", c: "Brazil" },
  { id: 6589, s: "MFV", n: "MFS Special Value Trust", c: "US" },
  { id: 6590, s: "VGP.BR", n: "VGP NV", c: "Belgium" },
  { id: 6591, s: "SUZB3.SA", n: "Suzano S.A.", c: "Brazil" },
  { id: 6592, s: "CZWI", n: "Citizens Community Bancorp Inc", c: "US" },
  { id: 6593, s: "VODPF", n: "Vodafone Group PLC", c: "US" },
  { id: 6594, s: "KEMIRA.HE", n: "Kemira Oyj", c: "Finland" },
  { id: 6595, s: "BCVN.SW", n: "Banque Cantonale", c: "Switzerland" },
  { id: 6596, s: "COTN.SW", n: "Comet Holding AG", c: "Switzerland" },
  { id: 6597, s: "VZLA.V", n: "Vizsla Resources Co", c: "CA" },
  { id: 6598, s: "MLFB", n: "Major League Football Inc", c: "US" },
  { id: 6599, s: "ROOT.TO", n: "Roots Co", c: "CA" },
  { id: 6600, s: "CBNK", n: "Capital BanCo", c: "US" },
  { id: 6601, s: "MAV", n: "Pioneer Municipal High Income Advantage Trust", c: "US" },
  { id: 6602, s: "SSL.TO", n: "Sandstorm Gold N", c: "CA" },
  { id: 6603, s: "AIRT", n: "Air T Inc", c: "US" },
  { id: 6604, s: "COV.PA", n: "Covivio SA", c: "FR" },
  { id: 6605, s: "ICP1V.HE", n: "Incap Oyj", c: "Finland" },
  { id: 6606, s: "WATR.V", n: "Current Water Technologies Inc", c: "CA" },
  { id: 6607, s: "CMCX.L", n: "CMC Markets PLC", c: "UK" },
  { id: 6608, s: "DF.TO", n: "Dividend 15 Split Corp II", c: "CA" },
  { id: 6609, s: "FNWD", n: "Finward BanCo", c: "US" },
  { id: 6610, s: "BOS.TO", n: "AirBoss of America Corp.", c: "CA" },
  { id: 6611, s: "STOR-B.ST", n: "Storskogen Group AB Series B", c: "Sweden" },
  { id: 6612, s: "CLNX.MC", n: "Cellnex Telecom SA", c: "Spain" },
  { id: 6613, s: "KOA.OL", n: "Kongsberg Automotive Holding ASA", c: "Norway" },
  { id: 6614, s: "CPG.L", n: "Compass Group PLC", c: "UK" },
  { id: 6615, s: "002415.SZ", n: "Hangzhou Hikvision Digital Technology", c: "CH" },
  { id: 6616, s: "DUO", n: "Fangdd Network Group", c: "US" },
  { id: 6617, s: "RSHN", n: "Rushnet Inc", c: "US" },
  { id: 6618, s: "CMU", n: "MFS High Yield Municipal Closed Fund", c: "US" },
  { id: 6619, s: "CVG.TO", n: "Clairvest Group Inc.", c: "CA" },
  { id: 6620, s: "OPHLY", n: "Ono Pharmaceutical", c: "US" },
  { id: 6621, s: "CMGR", n: "Clubhouse Media Group Inc", c: "US" },
  { id: 6622, s: "TLW.L", n: "Tullow Oil PLC", c: "UK" },
  { id: 6623, s: "FUND.V", n: "Katipult Technology Co", c: "CA" },
  { id: 6624, s: "2912.TW", n: "President Chain Store Co", c: "TW" },
  { id: 6625, s: "HMS.ST", n: "HMS Networks AB (publ)", c: "Sweden" },
  { id: 6626, s: "EGIE3.SA", n: "Engie Brasil Energia S.A", c: "Brazil" },
  { id: 6627, s: "MOR.DE", n: "Morphosys AG O.N.", c: "GER" },
  { id: 6628, s: "CGEN.TA", n: "Compugen", c: "Israel" },
  { id: 6629, s: "TMR.AX", n: "Tempus Resources", c: "Australia" },
  { id: 6630, s: "FBMS", n: "The First Bancshares Inc", c: "US" },
  { id: 6631, s: "002069.SZ", n: "Zoneco Group", c: "CH" },
  { id: 6632, s: "BRE.TO", n: "Bridgemarq Real Estate Services Inc", c: "CA" },
  { id: 6633, s: "MACE", n: "Mace Security Intl", c: "US" },
  { id: 6634, s: "CSH-UN.TO", n: "Chartwell Retirement Residences", c: "CA" },
  { id: 6635, s: "600584.SS", n: "JCET Group", c: "CH" },
  { id: 6636, s: "HTIA", n: "Healthcare Trust Inc PR", c: "US" },
  { id: 6637, s: "PTRS", n: "Partners BanCo", c: "US" },
  { id: 6638, s: "CEL.TA", n: "Cellcom Israel", c: "Israel" },
  { id: 6639, s: "ROO.L", n: "Deliveroo Holdings PLC", c: "UK" },
  { id: 6640, s: "ATS.VI", n: "AT & S Austria Technologie & Systemtechnik Aktiengesellschaft", c: "Austria" },
  { id: 6641, s: "TLG.AX", n: "Talga Resources", c: "Australia" },
  { id: 6642, s: "603259.SS", n: "WuXi AppTec", c: "CH" },
  { id: 6643, s: "ZEPP", n: "Zepp Health Co", c: "US" },
  { id: 6644, s: "TELNY", n: "Telenor ASA ADR", c: "US" },
  { id: 6645, s: "SCT.L", n: "Softcat plc", c: "UK" },
  { id: 6646, s: "ICCH", n: "ICC Holdings Inc", c: "US" },
  { id: 6647, s: "YKBNK.IS", n: "Yapi ve Kredi Bankasi AS", c: "Turkey" },
  { id: 6648, s: "FUM.L", n: "Futura Medical", c: "UK" },
  { id: 6649, s: "RRL.AX", n: "Regis Resources", c: "Australia" },
  { id: 6650, s: "VEL", n: "Velocity Financial Llc", c: "US" },
  { id: 6651, s: "ZAZZ-B.ST", n: "ZAZZ Energy of Sweden AB", c: "Sweden" },
  { id: 6652, s: "PVT.V", n: "Pivotree Inc", c: "CA" },
  { id: 6653, s: "PNRG", n: "PrimeEnergy Corporation", c: "US" },
  { id: 6654, s: "NEOEN.PA", n: "Neoen SA", c: "FR" },
  { id: 6655, s: "MIN.AX", n: "Mineral Resources", c: "Australia" },
  { id: 6656, s: "WITH.HE", n: "WITHSECURE", c: "Finland" },
  { id: 6657, s: "ICCC", n: "ImmuCell Corporation", c: "US" },
  { id: 6658, s: "LUKN.SW", n: "Luzerner Kantonalbank AG", c: "Switzerland" },
  { id: 6659, s: "IRES.IR", n: "Irish Residential Properties", c: "Ireland" },
  { id: 6660, s: "BACHY", n: "Bank of CH ADR", c: "US" },
  { id: 6661, s: "SMAWF", n: "Siemens AG Class N", c: "US" },
  { id: 6662, s: "MYFW", n: "First Western Financial Inc", c: "US" },
  { id: 6663, s: "CEU.TO", n: "CES Energy Solutions Co", c: "CA" },
  { id: 6664, s: "600585.SS", n: "Anhui Conch Cement", c: "CH" },
  { id: 6665, s: "SSY", n: "SunLink Health Systems Inc", c: "US" },
  { id: 6666, s: "BKG.L", n: "The Berkeley Group Holdings plc", c: "UK" },
  { id: 6667, s: "HL.L", n: "Hargreaves Lansdown plc", c: "UK" },
  { id: 6668, s: "CJPRY", n: "Central Japan Railway", c: "US" },
  { id: 6669, s: "HUBN.SW", n: "Huber+suhner AG", c: "Switzerland" },
  { id: 6670, s: "JFN.SW", n: "Jungfraubahn Holding AG", c: "Switzerland" },
  { id: 6671, s: "SOLAR-B.", n: "Solar A/S", c: "Denmark" },
  { id: 6672, s: "MNS.AX", n: "Magnis Resources", c: "Australia" },
  { id: 6673, s: "NSCI.V", n: "Nanalysis Scientific Co", c: "CA" },
  { id: 6674, s: "HBCP", n: "Home Bancorp Inc", c: "US" },
  { id: 6675, s: "NET-UN.V", n: "Canadian Net Real Estate Investment Trust", c: "CA" },
  { id: 6676, s: "SMDS.L", n: "DS Smith PLC", c: "UK" },
  { id: 6677, s: "LIO.V", n: "Lion One Metals Limited", c: "CA" },
  { id: 6678, s: "AEGN.AT", n: "Aegean Airlines S.A.", c: "Greece" },
  { id: 6679, s: "88E.AX", n: "88 Energy", c: "Australia" },
  { id: 6680, s: "RS1.L", n: "RS GROUP PLC", c: "UK" },
  { id: 6681, s: "LUCK.V", n: "Real Luck Group", c: "CA" },
  { id: 6682, s: "PFIN", n: "P&F Industries Inc", c: "US" },
  { id: 6683, s: "RIOCF", n: "Riocan REIT", c: "US" },
  { id: 6684, s: "MRGE", n: "Mirage Energy Co", c: "US" },
  { id: 6685, s: "RERE", n: "ATRenew Inc DRC", c: "US" },
  { id: 6686, s: "JEN.DE", n: "Jenoptik AG", c: "GER" },
  { id: 6687, s: "EKTA-B.ST", n: "Elekta AB (publ)", c: "Sweden" },
  { id: 6688, s: "TORXF", n: "Torex Gold Resources Inc", c: "US" },
  { id: 6689, s: "RACY", n: "Relativity Acquisition Co", c: "US" },
  { id: 6690, s: "KHOLY", n: "Koc Holdings AS", c: "US" },
  { id: 6691, s: "NFLX.NE", n: "Netflix Inc CDR", c: "CA" },
  { id: 6692, s: "PSON.L", n: "Pearson PLC", c: "UK" },
  { id: 6693, s: "WINE.L", n: "Naked Wines plc", c: "UK" },
  { id: 6694, s: "ELI.BR", n: "Elia Group SA/NV", c: "Belgium" },
  { id: 6695, s: "FNTN.DE", n: "freenet AG", c: "GER" },
  { id: 6696, s: "BRBS", n: "Blue Ridge Bankshares Inc", c: "US" },
  { id: 6697, s: "TKTT.PA", n: "Tarkett SA", c: "FR" },
  { id: 6698, s: "NA9.F", n: "Nagarro SE", c: "GER" },
  { id: 6699, s: "CRD-B", n: "Crawford & Company", c: "US" },
  { id: 6700, s: "IQE.L", n: "IQE PLC", c: "UK" },
  { id: 6701, s: "NVO.TO", n: "Novo Resources Co", c: "CA" },
  { id: 6702, s: "SMR.AX", n: "Stanmore Resources Limited", c: "Australia" },
  { id: 6703, s: "PPT", n: "Putnam Premier Income Trust", c: "US" },
  { id: 6704, s: "SW.PA", n: "Sodexo SA", c: "FR" },
  { id: 6705, s: "2377.TW", n: "Micro-Star International", c: "TW" },
  { id: 6706, s: "ARG.TO", n: "Amerigo Resources.", c: "CA" },
  { id: 6707, s: "NYXH", n: "Nyxoah", c: "US" },
  { id: 6708, s: "AVN.V", n: "Avanti Energy Inc", c: "CA" },
  { id: 6709, s: "OTSKY", n: "Otsuka Holdings Co ADR", c: "US" },
  { id: 6710, s: "CFRUY", n: "Compagnie Financiere Richemont", c: "US" },
  { id: 6711, s: "NCMGF", n: "Newcrest Mining", c: "US" },
  { id: 6712, s: "MOH.AT", n: "Motor Oil (Hellas) Corinth Refineries S.A", c: "Greece" },
  { id: 6713, s: "CR.TO", n: "Crew Energy Inc", c: "CA" },
  { id: 6714, s: "ELG.DE", n: "Elmos Semiconductor SE", c: "GER" },
  { id: 6715, s: "SIM.", n: "Simcorp A/S", c: "Denmark" },
  { id: 6716, s: "2458.TW", n: "Elan Microelectronics Co", c: "TW" },
  { id: 6717, s: "002381.SZ", n: "Zhejiang Double Arrow Rubber", c: "CH" },
  { id: 6718, s: "OR.TO", n: "Osisko Gold Ro", c: "CA" },
  { id: 6719, s: "DRR.AX", n: "Deterra Royalties", c: "Australia" },
  { id: 6720, s: "REAL.TO", n: "Real Matters Inc", c: "CA" },
  { id: 6721, s: "9921.TW", n: "Giant Manufacturing", c: "TW" },
  { id: 6722, s: "ENOG.L", n: "Energean Oil & Gas PLC", c: "UK" },
  { id: 6723, s: "SYDB.", n: "Sydbank A/S", c: "Denmark" },
  { id: 6724, s: "EBR-B", n: "Centrais Elétricas Brasileiras S.A. - Eletrobrás", c: "US" },
  { id: 6725, s: "089590.KS", n: "Jeju Air", c: "Korea" },
  { id: 6726, s: "MSFT.NE", n: "Microsoft Corp CDR", c: "CA" },
  { id: 6727, s: "METSB.HE", n: "Metsa Board Oyj B", c: "Finland" },
  { id: 6728, s: "YIT.HE", n: "YIT Oyj", c: "Finland" },
  { id: 6729, s: "ASHTY", n: "Ashtead Gro", c: "US" },
  { id: 6730, s: "MRO.L", n: "Melrose Industries PLC", c: "UK" },
  { id: 6731, s: "DWS.DE", n: "DWS Group GmbH & Co. KGaA", c: "GER" },
  { id: 6732, s: "HNR1.DE", n: "Hannover Rück SE", c: "GER" },
  { id: 6733, s: "IPS.PA", n: "Ipsos SA", c: "FR" },
  { id: 6734, s: "PSI.TO", n: "Pason Systems Inc.", c: "CA" },
  { id: 6735, s: "PHT", n: "Pioneer High Income Closed Fund", c: "US" },
  { id: 6736, s: "ALS.TO", n: "Altius Minerals Corporation", c: "CA" },
  { id: 6737, s: "3702.TW", n: "WPG Holdings", c: "TW" },
  { id: 6738, s: "PNBK", n: "Patriot National Bancorp Inc", c: "US" },
  { id: 6739, s: "NSTB", n: "Northern Star Investment Corp II", c: "US" },
  { id: 6740, s: "TECN.SW", n: "Tecan Group AG", c: "Switzerland" },
  { id: 6741, s: "020150.KS", n: "Lotte Energy Materials Corporation", c: "Korea" },
  { id: 6742, s: "NXI.PA", n: "Nexity", c: "FR" },
  { id: 6743, s: "CARD.L", n: "Card Factory PLC", c: "UK" },
  { id: 6744, s: "CGG.PA", n: "CGG SA", c: "FR" },
  { id: 6745, s: "SIM", n: "Grupo Simec SAB de CV ADR", c: "US" },
  { id: 6746, s: "MEDI.OL", n: "Medistim ASA", c: "Norway" },
  { id: 6747, s: "605499.SS", n: "Eastroc Beverage Group", c: "CH" },
  { id: 6748, s: "AKSO.OL", n: "Aker Solutions ASA", c: "Norway" },
  { id: 6749, s: "ABG.OL", n: "ABG Sundal Collier Holding ASA", c: "Norway" },
  { id: 6750, s: "EXAE.AT", n: "Hellenic Exchanges - Athens Stock Exchange SA", c: "Greece" },
  { id: 6751, s: "FEVR.L", n: "Fevertree Drinks Plc", c: "UK" },
  { id: 6752, s: "AUTLANB.MX", n: "Compañía Minera Autlán S.A.B. de C.V", c: "Mexi" },
  { id: 6753, s: "KCR.HE", n: "Konecranes Plc", c: "Finland" },
  { id: 6754, s: "QIA.DE", n: "Qiagen NV", c: "GER" },
  { id: 6755, s: "AMBO", n: "Ambow Education Holding", c: "US" },
  { id: 6756, s: "BLX.TO", n: "Boralex Inc", c: "CA" },
  { id: 6757, s: "ISTR", n: "Investar Holding Co", c: "US" },
  { id: 6758, s: "SOY.TO", n: "SunOpta Inc.", c: "CA" },
  { id: 6759, s: "MRBK", n: "Meridian Bank", c: "US" },
  { id: 6760, s: "BILL.ST", n: "BillerudKorsnas AB", c: "Sweden" },
  { id: 6761, s: "DVLP", n: "Golden Developing Solutions Inc", c: "US" },
  { id: 6762, s: "TPEIR.AT", n: "Piraeus Financial Holdings S.A", c: "Greece" },
  { id: 6763, s: "ACX.MC", n: "Acerinox", c: "Spain" },
  { id: 6764, s: "ISCNF", n: "Isracann Biosciences Inc", c: "US" },
  { id: 6765, s: "GL9.IR", n: "Glanbia PLC", c: "Ireland" },
  { id: 6766, s: "RSG.AX", n: "Resolute Mining", c: "Australia" },
  { id: 6767, s: "HARL.TA", n: "Harel Insurance Investments & Financial Services", c: "Israel" },
  { id: 6768, s: "SSKN", n: "STRATA Skin Sciences Inc", c: "US" },
  { id: 6769, s: "CCOJY", n: "Coca-Cola Bottlers Japan Inc ADR", c: "US" },
  { id: 6770, s: "INS.DE", n: "Instone Real Estate Group AG", c: "GER" },
  { id: 6771, s: "DXS.AX", n: "Dexus", c: "Australia" },
  { id: 6772, s: "VMD.TO", n: "Viemed Healthcare Inc", c: "CA" },
  { id: 6773, s: "EVBN", n: "Evans Bancorp Inc", c: "US" },
  { id: 6774, s: "MFM", n: "MFS Municipal Income Trust", c: "US" },
  { id: 6775, s: "PGSUS.IS", n: "Pegasus Hava Tasimaciligi AS", c: "Turkey" },
  { id: 6776, s: "EDXC", n: "Endexx Co", c: "US" },
  { id: 6777, s: "SECU.V", n: "SSC Security Services Co", c: "CA" },
  { id: 6778, s: "PFIS", n: "Peoples Fin", c: "US" },
  { id: 6779, s: "CKHUY", n: "CK Hutchison Holdings ADR", c: "US" },
  { id: 6780, s: "GBF.DE", n: "Bilfinger SE", c: "GER" },
  { id: 6781, s: "SAHN.SW", n: "Schaffner Holding AG", c: "Switzerland" },
  { id: 6782, s: "AAVVF", n: "Advantage Oil & Gas.", c: "US" },
  { id: 6783, s: "HUIZ", n: "Huize Holding Ltd", c: "US" },
  { id: 6784, s: "EUR.AX", n: "European Lithium Limited", c: "Australia" },
  { id: 6785, s: "GBNY", n: "Generations Bancorp NY Inc", c: "US" },
  { id: 6786, s: "BEPC.TO", n: "Brookfield Renewable Co", c: "CA" },
  { id: 6787, s: "XTLB", n: "XTL Biopharmaceuticals ADR", c: "US" },
  { id: 6788, s: "SCA-B.ST", n: "Svenska Cellulosa Aktiebolaget SCA (publ)", c: "Sweden" },
  { id: 6789, s: "WCN.AX", n: "White Cliff Minerals", c: "Australia" },
  { id: 6790, s: "MAU.V", n: "Montage Gold Co", c: "CA" },
  { id: 6791, s: "TIE.V", n: "Coloured Ties Capital Inc", c: "CA" },
  { id: 6792, s: "AORT", n: "Artivion Inc", c: "US" },
  { id: 6793, s: "OGN.V", n: "Orogen Royalties Inc", c: "CA" },
  { id: 6794, s: "VITROA.MX", n: "Vitro S.A.B. de C.V", c: "Mexi" },
  { id: 6795, s: "CDA.AX", n: "Codan", c: "Australia" },
  { id: 6796, s: "ALU.AX", n: "Altium", c: "Australia" },
  { id: 6797, s: "VEOEF", n: "Veolia Environnement S.A", c: "US" },
  { id: 6798, s: "EMSN.SW", n: "EMS-CHEMIE HOLDING AG", c: "Switzerland" },
  { id: 6799, s: "SKRR.V", n: "SKRR Exploration Inc", c: "CA" },
  { id: 6800, s: "PLBC", n: "Plumas BanCo", c: "US" },
  { id: 6801, s: "SMNNY", n: "Shimano Inc ADR", c: "US" },
  { id: 6802, s: "FDBC", n: "Fidelity D&D Bancorp Inc", c: "US" },
  { id: 6803, s: "JET2.L", n: "Jet2 PLC", c: "UK" },
  { id: 6804, s: "SCR.PA", n: "SCOR SE", c: "FR" },
  { id: 6805, s: "LEVE3.SA", n: "MAHLE Metal Leve S.A", c: "Brazil" },
  { id: 6806, s: "GFC.PA", n: "Gecina SA", c: "FR" },
  { id: 6807, s: "DNGDF", n: "Dynacor Gold Mines Inc.", c: "US" },
  { id: 6808, s: "BLU.AX", n: "Blue Energy", c: "Australia" },
  { id: 6809, s: "NICU.V", n: "Magna Mining Inc", c: "CA" },
  { id: 6810, s: "FCC.MC", n: "Fomento de Construcciones y Contratas SA", c: "Spain" },
  { id: 6811, s: "CUSI", n: "Cuisine Solutions Inc", c: "US" },
  { id: 6812, s: "CZO.V", n: "Ceapro", c: "CA" },
  { id: 6813, s: "CYT", n: "Cyteir Therapeutics Inc", c: "US" },
  { id: 6814, s: "VLKAF", n: "Volkswagen AG", c: "US" },
  { id: 6815, s: "WEIR.L", n: "Weir Group PLC", c: "UK" },
  { id: 6816, s: "NRBT", n: "Novus Robotics Inc", c: "US" },
  { id: 6817, s: "SAB.MC", n: "Banco de Sabadell S.A", c: "Spain" },
  { id: 6818, s: "OGC.TO", n: "OceanaGold Corporation", c: "CA" },
  { id: 6819, s: "ADAG", n: "Adagene Inc", c: "US" },
  { id: 6820, s: "4728.TWO", n: "Sunmax Biotechnology", c: "TW" },
  { id: 6821, s: "LEJU", n: "Leju Holdings Limited", c: "US" },
  { id: 6822, s: "OPTOMED.HE", n: "Optomed PLC", c: "Finland" },
  { id: 6823, s: "035900.KQ", n: "JYP Entertainment Corporation", c: "Korea" },
  { id: 6824, s: "HYPRO.OL", n: "HydrogenPro AS", c: "Norway" },
  { id: 6825, s: "ASCL.L", n: "Ascential PLC", c: "UK" },
  { id: 6826, s: "002027.SZ", n: "Focus Media Information Technology", c: "CH" },
  { id: 6827, s: "CCH.L", n: "Coca Cola HBC AG", c: "UK" },
  { id: 6828, s: "000977.SZ", n: "Inspur Electronic Info Industr", c: "CH" },
  { id: 6829, s: "FUR.AS", n: "Fugro N.V.", c: "Netherlands" },
  { id: 6830, s: "MTRO.L", n: "Metro Bank PLC", c: "UK" },
  { id: 6831, s: "SIOX", n: "Sio Gene Therapies Inc", c: "US" },
  { id: 6832, s: "TGS.OL", n: "TGS NOPEC Geophysical Company ASA", c: "Norway" },
  { id: 6833, s: "AUST", n: "Austin Gold Co", c: "US" },
  { id: 6834, s: "UCU.V", n: "Ucore Rare Metals Inc", c: "CA" },
  { id: 6835, s: "6208.TWO", n: "Highlight Tech", c: "TW" },
  { id: 6836, s: "TNZ.TO", n: "Tenaz Energy Corp.", c: "CA" },
  { id: 6837, s: "DEC.L", n: "Diversified Energy Company PLC", c: "UK" },
  { id: 6838, s: "2383.TW", n: "Elite Material", c: "TW" },
  { id: 6839, s: "SSREY", n: "Swiss Re", c: "US" },
  { id: 6840, s: "CCV", n: "Churchill Capital V Co", c: "US" },
  { id: 6841, s: "BNE.TO", n: "Bonterra Energy Co", c: "CA" },
  { id: 6842, s: "GCT.TA", n: "G City", c: "Israel" },
  { id: 6843, s: "AI.TO", n: "Atrium Mortgage Investment Co", c: "CA" },
  { id: 6844, s: "BFZ", n: "BlackRock California Municipal Income Closed Fund", c: "US" },
  { id: 6845, s: "BURBY", n: "Burberry Group Plc", c: "US" },
  { id: 6846, s: "TCI", n: "Transcontinental Realty Investors", c: "US" },
  { id: 6847, s: "SEKEY", n: "Seiko Epson Corp ADR", c: "US" },
  { id: 6848, s: "SGP.AX", n: "Stockland Corporation", c: "Australia" },
  { id: 6849, s: "GDP.L", n: "Goldplat PLC", c: "UK" },
  { id: 6850, s: "SK.PA", n: "SEB SA", c: "FR" },
  { id: 6851, s: "BHV", n: "BlackRock Virginia MBT", c: "US" },
  { id: 6852, s: "BHRB", n: "Burke & Herbert Financial Services Corp. Common Stock", c: "US" },
  { id: 6853, s: "CRAYN.OL", n: "Crayon Group Holding ASA", c: "Norway" },
  { id: 6854, s: "NCSM", n: "NCS Multistage Holdings Inc", c: "US" },
  { id: 6855, s: "XTNT", n: "Xtant Medical Holdings Inc", c: "US" },
  { id: 6856, s: "ECOR.L", n: "Ecora Resources PLC", c: "UK" },
  { id: 6857, s: "603392.SS", n: "Beijing Wantai Biological Pharmacy Enterprise", c: "CH" },
  { id: 6858, s: "INS.F", n: "Instone Real Estate Group AG", c: "GER" },
  { id: 6859, s: "DFY.TO", n: "Definity Financial Co", c: "CA" },
  { id: 6860, s: "BLOZF", n: "Cannabix Technologies Inc", c: "US" },
  { id: 6861, s: "SDRY.L", n: "Superdry PLC", c: "UK" },
  { id: 6862, s: "GDVM", n: "Global Develpmts", c: "US" },
  { id: 6863, s: "MAVI.IS", n: "Mavi Giyim Sanayi Ve Ticaret AS", c: "Turkey" },
  { id: 6864, s: "TSCDY", n: "Tesco PLC", c: "US" },
  { id: 6865, s: "RSVR", n: "Reservoir Media Inc", c: "US" },
  { id: 6866, s: "MTRN.TA", n: "Maytronics", c: "Israel" },
  { id: 6867, s: "CGRA", n: "Cgrowth Capital Inc", c: "US" },
  { id: 6868, s: "FCL.AX", n: "Fineos Corporation Holdings Plc", c: "Australia" },
  { id: 6869, s: "AEIN.DE", n: "Allgeier SE", c: "GER" },
  { id: 6870, s: "EML.AX", n: "EML Payments", c: "Australia" },
  { id: 6871, s: "EMMN.SW", n: "Emmi AG", c: "Switzerland" },
  { id: 6872, s: "PGZ", n: "Principal Real Estate Income Closed Fund", c: "US" },
  { id: 6873, s: "DV.V", n: "Dolly Varden Silver Corporation", c: "CA" },
  { id: 6874, s: "BMW3.DE", n: "Bayerische Motoren Werke Aktiengesellschaft", c: "GER" },
  { id: 6875, s: "BKW.SW", n: "BKW AG", c: "Switzerland" },
  { id: 6876, s: "SHCAY", n: "Sharp Corp ADR", c: "US" },
  { id: 6877, s: "GTN-A", n: "Gray Television Inc", c: "US" },
  { id: 6878, s: "KRKR", n: "36Kr Holdings Inc", c: "US" },
  { id: 6879, s: "600690.SS", n: "Qingdao Haier", c: "CH" },
  { id: 6880, s: "TAIG.TO", n: "Taiga Motors Co", c: "CA" },
  { id: 6881, s: "AIF.TO", n: "Altus Group Limited", c: "CA" },
  { id: 6882, s: "ARAFF", n: "Arafura Resources", c: "US" },
  { id: 6883, s: "FYB.DE", n: "Formycon AG", c: "GER" },
  { id: 6884, s: "ISDR", n: "Issuer Direct Co", c: "US" },
  { id: 6885, s: "LREN3.SA", n: "Lojas Renner S.A", c: "Brazil" },
  { id: 6886, s: "PURA", n: "Puration Inc", c: "US" },
  { id: 6887, s: "WEB.AX", n: "Webjet", c: "Australia" },
  { id: 6888, s: "PLZ-UN.TO", n: "Plaza Retail REIT", c: "CA" },
  { id: 6889, s: "VEA.AX", n: "Viva Energy Group", c: "Australia" },
  { id: 6890, s: "IEL.AX", n: "IDP Education", c: "Australia" },
  { id: 6891, s: "PEV", n: "Phoenix Motor Inc. Common Stock", c: "US" },
  { id: 6892, s: "PBHC", n: "Pathfinder Bancorp Inc", c: "US" },
  { id: 6893, s: "RMM", n: "RiverNorth Managed Duration Municipal Income Fund Inc", c: "US" },
  { id: 6894, s: "SMMF", n: "Summit Financial Group Inc", c: "US" },
  { id: 6895, s: "GOOG.NE", n: "Alphabet Inc CDR", c: "CA" },
  { id: 6896, s: "FGR.PA", n: "Eiffage SA", c: "FR" },
  { id: 6897, s: "ETL.PA", n: "Eutelsat Communications SA", c: "FR" },
  { id: 6898, s: "HENKY", n: "Henkel AG & Co KGAA", c: "US" },
  { id: 6899, s: "ASRV", n: "AmeriServ Financial Inc", c: "US" },
  { id: 6900, s: "CKN.L", n: "Clarkson", c: "UK" },
  { id: 6901, s: "AGR.L", n: "Assura Plc", c: "UK" },
  { id: 6902, s: "KUT.V", n: "Redishred Capital Co", c: "CA" },
  { id: 6903, s: "RMHB", n: "Rocky Mountain High Brands Inc", c: "US" },
  { id: 6904, s: "MEDV.NE", n: "Medivolve Inc", c: "CA" },
  { id: 6905, s: "FUSB", n: "First US Bancshares Inc", c: "US" },
  { id: 6906, s: "INTO.BR", n: "Intervest Offices - Ware", c: "Belgium" },
  { id: 6907, s: "AKER.OL", n: "Aker ASA", c: "Norway" },
  { id: 6908, s: "FTLF", n: "FitLife Brands, Inc. Common Stock", c: "US" },
  { id: 6909, s: "036570.KS", n: "NCsoft Co", c: "Korea" },
  { id: 6910, s: "FUNN", n: "Amfil Technologies", c: "US" },
  { id: 6911, s: "VCISY", n: "Vinci SA ADR", c: "US" },
  { id: 6912, s: "2395.TW", n: "Advantech", c: "TW" },
  { id: 6913, s: "AETUF", n: "ARC Resources.", c: "US" },
  { id: 6914, s: "BRBL", n: "Brewbilt Brewing", c: "US" },
  { id: 6915, s: "LOGN.SW", n: "Logitech International S.A.", c: "Switzerland" },
  { id: 6916, s: "ISO.V", n: "Isoenergy", c: "CA" },
  { id: 6917, s: "BNY", n: "BlackRock New York Municipal Income Trust", c: "US" },
  { id: 6918, s: "VOLVF", n: "Volvo AB ser. B", c: "US" },
  { id: 6919, s: "RAA.DE", n: "RATIONAL Aktiengesellschaft", c: "GER" },
  { id: 6920, s: "UQA.VI", n: "UNIQA Insurance Group AG", c: "Austria" },
  { id: 6921, s: "BSVN", n: "Bank7 Co", c: "US" },
  { id: 6922, s: "PCTL", n: "PCT", c: "US" },
  { id: 6923, s: "BG.VI", n: "BAWAG Group AG", c: "Austria" },
  { id: 6924, s: "EMGE", n: "Emergent Health Co", c: "US" },
  { id: 6925, s: "EQTL3.SA", n: "Equatorial Energia S.A", c: "Brazil" },
  { id: 6926, s: "RDW.L", n: "Redrow PLC", c: "UK" },
  { id: 6927, s: "COFA.PA", n: "Coface SA", c: "FR" },
  { id: 6928, s: "ELC", n: "Entergy Louisiana LLC Pref", c: "US" },
  { id: 6929, s: "MGLD", n: "Marygold Companies Inc", c: "US" },
  { id: 6930, s: "TPZ.TO", n: "Topaz Energy Co", c: "CA" },
  { id: 6931, s: "GLJ.DE", n: "Grenke AG", c: "GER" },
  { id: 6932, s: "TCBS", n: "Texas Community Bancshares Inc", c: "US" },
  { id: 6933, s: "XPF.L", n: "XP Factory PLC", c: "UK" },
  { id: 6934, s: "003670.KS", n: "Posco Chemical", c: "Korea" },
  { id: 6935, s: "AEP.V", n: "Atlas Engineered Products", c: "CA" },
  { id: 6936, s: "DRRSF", n: "Arianne Phosphate Inc", c: "US" },
  { id: 6937, s: "PRIO3.SA", n: "Petro Rio S.A.", c: "Brazil" },
  { id: 6938, s: "SWGAY", n: "Swatch Group AG ADR", c: "US" },
  { id: 6939, s: "CLA.AX", n: "Celsius Resources", c: "Australia" },
  { id: 6940, s: "MNTX", n: "Manitex International Inc", c: "US" },
  { id: 6941, s: "MRL.L", n: "Marlowe plc", c: "UK" },
  { id: 6942, s: "SOR", n: "Source Capital Closed Fund", c: "US" },
  { id: 6943, s: "CLS.TO", n: "Celestica Inc.", c: "CA" },
  { id: 6944, s: "2609.TW", n: "Yang Ming Marine Transport Co", c: "TW" },
  { id: 6945, s: "NGC.V", n: "Northern Graphite Corporation", c: "CA" },
  { id: 6946, s: "SOMMY", n: "Sumitomo Chemical Co ADR", c: "US" },
  { id: 6947, s: "FORA", n: "Forian Inc", c: "US" },
  { id: 6948, s: "MEX.L", n: "Tortilla Mexican Grill PLC", c: "UK" },
  { id: 6949, s: "CATG.PA", n: "SA Catana Group", c: "FR" },
  { id: 6950, s: "PPTA.TO", n: "Perpetua Resources Co", c: "CA" },
  { id: 6951, s: "000720.KS", n: "Hyundai Engineering & Construction", c: "Korea" },
  { id: 6952, s: "EBMT", n: "Eagle Bancorp Montana Inc", c: "US" },
  { id: 6953, s: "LTX.WA", n: "Zakłady Lentex SA", c: "Poland" },
  { id: 6954, s: "BIEI", n: "Premier Biomedical Inc", c: "US" },
  { id: 6955, s: "LPK.DE", n: "LPKF Laser & Electronics AG", c: "GER" },
  { id: 6956, s: "PD.TO", n: "Precision Drilling Corporation", c: "CA" },
  { id: 6957, s: "UUGRY", n: "United Utilities Group PLC ADR", c: "US" },
  { id: 6958, s: "BRG.OL", n: "Borregaard ASA", c: "Norway" },
  { id: 6959, s: "SRGHY", n: "Shoprite Holdings ADR", c: "US" },
  { id: 6960, s: "000858.SZ", n: "Wuliangye Yibin", c: "CH" },
  { id: 6961, s: "EFX.TO", n: "Enerflex.", c: "CA" },
  { id: 6962, s: "RADI", n: "Radius Global Infrastructure Inc", c: "US" },
  { id: 6963, s: "APA.AX", n: "APA Group", c: "Australia" },
  { id: 6964, s: "CFFI", n: "C&F Financial Corporation", c: "US" },
  { id: 6965, s: "RRBI", n: "Red River Bancshares Inc", c: "US" },
  { id: 6966, s: "SEM.LS", n: "Semapa", c: "Portugal" },
  { id: 6967, s: "SZG.DE", n: "Salzgitter AG", c: "GER" },
  { id: 6968, s: "GIA", n: "GigCapital 5 Inc. Common Stock", c: "US" },
  { id: 6969, s: "PMMAF", n: "Puma SE", c: "US" },
  { id: 6970, s: "IAG.AX", n: "Insurance Australia Group", c: "Australia" },
  { id: 6971, s: "DRNG", n: "Vopia Inc", c: "US" },
  { id: 6972, s: "SBC.TO", n: "Brompton Split Banc Co", c: "CA" },
  { id: 6973, s: "RUS.TO", n: "Russel Metals Inc.", c: "CA" },
  { id: 6974, s: "ECF", n: "Ellsworth Convertible Growth & IF", c: "US" },
  { id: 6975, s: "000758.SZ", n: "CH Nonferrous Metal Industry’s Foreign Engineering and Construction", c: "CH" },
  { id: 6976, s: "GNC.AX", n: "GrainCo", c: "Australia" },
  { id: 6977, s: "AAZ.V", n: "Azincourt Uranium Inc", c: "CA" },
  { id: 6978, s: "FER.MC", n: "Ferrovial", c: "Spain" },
  { id: 6979, s: "2867.TW", n: "Mercuries Life Insurance", c: "TW" },
  { id: 6980, s: "HOM-UN.TO", n: "BSR Real Estate Investment Trust", c: "CA" },
  { id: 6981, s: "AZELIO.ST", n: "Azelio AB", c: "Sweden" },
  { id: 6982, s: "HAPV3.SA", n: "Hapvida Participações e Investimentos S.A.", c: "Brazil" },
  { id: 6983, s: "CXE", n: "MFS High Income Municipal Closed Fund", c: "US" },
  { id: 6984, s: "FDBL", n: "Friendable Inc", c: "US" },
  { id: 6985, s: "TPK.L", n: "Travis Perkins PLC", c: "UK" },
  { id: 6986, s: "MSVB", n: "Mid-Southern Bancorp Inc", c: "US" },
  { id: 6987, s: "AAZ.L", n: "Anglo Asian Mining Plc", c: "UK" },
  { id: 6988, s: "F3C.DE", n: "SFC Energy AG", c: "GER" },
  { id: 6989, s: "NSAV", n: "NSAV Holding Inc", c: "US" },
  { id: 6990, s: "SAV.L", n: "Savannah Resources Plc", c: "UK" },
  { id: 6991, s: "GESAN.IS", n: "Girisim Elektrik Taahhut Ticaret & Sanayi AS", c: "Turkey" },
  { id: 6992, s: "HIPH", n: "American Premium Water Co", c: "US" },
  { id: 6993, s: "PBB.DE", n: "Deutsche Pfandbriefbank AG", c: "GER" },
  { id: 6994, s: "CDDRF", n: "Headwater Exploration Inc", c: "US" },
  { id: 6995, s: "PRTH", n: "Priority Technology Holdings Inc", c: "US" },
  { id: 6996, s: "GNC.L", n: "Greencore Group", c: "UK" },
  { id: 6997, s: "NTIP", n: "Network-1 Technologies Inc", c: "US" },
  { id: 6998, s: "MF.PA", n: "Wendel", c: "FR" },
  { id: 6999, s: "PROV", n: "Provident Financial Holdings Inc", c: "US" },
  { id: 7000, s: "HPUR.OL", n: "Hexagon Purus As", c: "Norway" },
  { id: 7001, s: "INCA.V", n: "Inca One Gold Co", c: "CA" },
  { id: 7002, s: "EGG.AX", n: "Enero Group", c: "Australia" },
  { id: 7003, s: "BMW.F", n: "Bayerische Motoren Werke Aktiengesellschaft", c: "GER" },
  { id: 7004, s: "ST5.DE", n: "STEICO SE", c: "GER" },
  { id: 7005, s: "1SN.L", n: "First Tin PLC", c: "UK" },
  { id: 7006, s: "YSN.DE", n: "secunet Security Networks Aktiengesellschaft", c: "GER" },
  { id: 7007, s: "MGL.AX", n: "Magontec", c: "Australia" },
  { id: 7008, s: "600276.SS", n: "Jiangsu Hengrui Medicine", c: "CH" },
  { id: 7009, s: "IFMK", n: "iFresh Inc", c: "US" },
  { id: 7010, s: "ADN1.DE", n: "Adesso SE", c: "GER" },
  { id: 7011, s: "DSCT.TA", n: "Israel Discount Bank", c: "Israel" },
  { id: 7012, s: "601628.SS", n: "CH Life Insurance Co A", c: "CH" },
  { id: 7013, s: "CCW.V", n: "CA Silver Cobalt Works Inc", c: "CA" },
  { id: 7014, s: "BIOG-B.ST", n: "BioGaia AB (publ)", c: "Sweden" },
  { id: 7015, s: "009780.KQ", n: "MSC Co.", c: "Korea" },
  { id: 7016, s: "BRK.AX", n: "Brookside Energy", c: "Australia" },
  { id: 7017, s: "CEVI.ST", n: "CellaVision AB", c: "Sweden" },
  { id: 7018, s: "TENERGY.AT", n: "Terna Energy Societe Anonyme Commercial Technical Company", c: "Greece" },
  { id: 7019, s: "VISA.NE", n: "Visa Inc CDR", c: "CA" },
  { id: 7020, s: "29M.AX", n: "29Metals", c: "Australia" },
  { id: 7021, s: "002955.SZ", n: "Hitevision ", c: "CH" },
  { id: 7022, s: "IMUN", n: "Immune Therapeutics Inc", c: "US" },
  { id: 7023, s: "NPIFF", n: "Northland Power Inc.", c: "US" },
  { id: 7024, s: "ALMB.", n: "Alm. Brand", c: "Denmark" },
  { id: 7025, s: "TUNE.L", n: "Focusrite Plc", c: "UK" },
  { id: 7026, s: "DPM.TO", n: "Dundee Precious Metals Inc", c: "CA" },
  { id: 7027, s: "000009.SZ", n: "CH Baoan Group", c: "CH" },
  { id: 7028, s: "GUD.TO", n: "Knight Therapeutics Inc", c: "CA" },
  { id: 7029, s: "KEPL3.SA", n: "Kepler Weber S.A", c: "Brazil" },
  { id: 7030, s: "LTRPB", n: "Liberty Tripadvisor Holdings Inc", c: "US" },
  { id: 7031, s: "CVCY", n: "Central Valley Community BanCo", c: "US" },
  { id: 7032, s: "TOTS3.SA", n: "TOTVS S.A", c: "Brazil" },
  { id: 7033, s: "207940.KS", n: "Samsung Biologics", c: "Korea" },
  { id: 7034, s: "GVSI", n: "Good Vibrations Shoes Inc", c: "US" },
  { id: 7035, s: "SPSN.SW", n: "Swiss Prime Site AG", c: "Switzerland" },
  { id: 7036, s: "XAM.AX", n: "Xanadu Mines", c: "Australia" },
  { id: 7037, s: "DX.L", n: "DX (Group) plc", c: "UK" },
  { id: 7038, s: "UTG.L", n: "Unite Group PLC", c: "UK" },
  { id: 7039, s: "EIOF.OL", n: "Eidesvik Offshore ASA", c: "Norway" },
  { id: 7040, s: "GSC1.DE", n: "Gesco AG", c: "GER" },
  { id: 7041, s: "601088.SS", n: "CH Shenhua Energy", c: "CH" },
  { id: 7042, s: "SAFE.L", n: "Safestore Holdings Plc", c: "UK" },
  { id: 7043, s: "NCRA", n: "Nocera Inc", c: "US" },
  { id: 7044, s: "IFAN", n: "IFAN Financial Inc", c: "US" },
  { id: 7045, s: "KOJAMO.HE", n: "Kojamo", c: "Finland" },
  { id: 7046, s: "BONAV-B.ST", n: "Bonava AB (publ)", c: "Sweden" },
  { id: 7047, s: "ZZ-B.ST", n: "Zinzino AB Series B", c: "Sweden" },
  { id: 7048, s: "SBO.OL", n: "Selvaag Bolig ASA", c: "Norway" },
  { id: 7049, s: "KWHIY", n: "Kawasaki Heavy Industries ADR", c: "US" },
  { id: 7050, s: "NOVR.V", n: "Nova Royalty Co", c: "CA" },
  { id: 7051, s: "RBREW.", n: "Royal Unibrew A/S", c: "Denmark" },
  { id: 7052, s: "THLLY", n: "Thales SA ADR", c: "US" },
  { id: 7053, s: "WM.TO", n: "Wallbridge Mining Company Limited", c: "CA" },
  { id: 7054, s: "BSBK", n: "Bogota Financial Co", c: "US" },
  { id: 7055, s: "CUB.TO", n: "CubicFarm Systems Co", c: "CA" },
  { id: 7056, s: "2888.TW", n: "Shin Kong Financial Holding", c: "TW" },
  { id: 7057, s: "BOBET.IS", n: "Bogazici Beton Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 7058, s: "OLK", n: "Olink Holding AB  ADR", c: "US" },
  { id: 7059, s: "KIST.L", n: "Kistos PLC", c: "UK" },
  { id: 7060, s: "PERR.PA", n: "Gérard Perrier Industrie S.A", c: "FR" },
  { id: 7061, s: "VYNT", n: "Vyant Bio Inc", c: "US" },
  { id: 7062, s: "NHK.TO", n: "Nighthawk Gold Co", c: "CA" },
  { id: 7063, s: "IGP.V", n: "Imperial Ginseng Products", c: "CA" },
  { id: 7064, s: "STC.TO", n: "Sangoma Technologies Co", c: "CA" },
  { id: 7065, s: "TSEM.TA", n: "Tower Semiconductor", c: "Israel" },
  { id: 7066, s: "PCLO.V", n: "Pharmacielo", c: "CA" },
  { id: 7067, s: "RZLT", n: "Rezolute Inc", c: "US" },
  { id: 7068, s: "CVCB3.SA", n: "CVC Brasil Operadora e Agencia", c: "Brazil" },
  { id: 7069, s: "SSU", n: "SIGNA Sports United BV", c: "US" },
  { id: 7070, s: "HAS.L", n: "Hays plc", c: "UK" },
  { id: 7071, s: "HWBK", n: "Hawthorn Bancshares Inc", c: "US" },
  { id: 7072, s: "TMICY", n: "Trend Micro Inc ADR", c: "US" },
  { id: 7073, s: "TKA.VI", n: "Telekom Austria AG", c: "Austria" },
  { id: 7074, s: "BEAN.SW", n: "Belimo Holding", c: "Switzerland" },
  { id: 7075, s: "GLU", n: "Gabelli Global Utility & Income Tr", c: "US" },
  { id: 7076, s: "EOF.AX", n: "Ecofibre", c: "Australia" },
  { id: 7077, s: "AXE.AX", n: "Archer Exploration", c: "Australia" },
  { id: 7078, s: "ADP.PA", n: "Aeroports de Paris SA", c: "FR" },
  { id: 7079, s: "KBCSY", n: "KBC Groep NV ADR", c: "US" },
  { id: 7080, s: "PETS.L", n: "Pets at Home Group Plc", c: "UK" },
  { id: 7081, s: "PEGA.V", n: "Pegasus Resources Inc", c: "CA" },
  { id: 7082, s: "OKEA.OL", n: "Okea ASA", c: "Norway" },
  { id: 7083, s: "SPT.L", n: "Spirent Communications plc", c: "UK" },
  { id: 7084, s: "VPT.V", n: "VentriPoint Diagnostics", c: "CA" },
  { id: 7085, s: "PYYX", n: "Pyxus International Inc", c: "US" },
  { id: 7086, s: "CURY.L", n: "Currys PLC", c: "UK" },
  { id: 7087, s: "NB2.DE", n: "Northern Data AG", c: "GER" },
  { id: 7088, s: "TLX.DE", n: "Talanx AG", c: "GER" },
  { id: 7089, s: "JAG.TO", n: "Jaguar Mining Inc", c: "CA" },
  { id: 7090, s: "4938.TW", n: "Pegatron Co", c: "TW" },
  { id: 7091, s: "EQS", n: "Equus Total Return Closed Fund", c: "US" },
  { id: 7092, s: "PHP.L", n: "Primary Health Properties", c: "UK" },
  { id: 7093, s: "RGD.V", n: "Reunion Gold Corporation", c: "CA" },
  { id: 7094, s: "AFRY.ST", n: "Afry AB", c: "Sweden" },
  { id: 7095, s: "EQS.PA", n: "Equasens Société anonyme", c: "FR" },
  { id: 7096, s: "KTF", n: "DWS Municipal Income Trust", c: "US" },
  { id: 7097, s: "BBY.L", n: "Balfour Beatty plc", c: "UK" },
  { id: 7098, s: "TVC", n: "Tennessee Valley Authority Pref", c: "US" },
  { id: 7099, s: "JUP.L", n: "Jupiter Fund Management Plc", c: "UK" },
  { id: 7100, s: "TOP.", n: "Topdanmark A/S", c: "Denmark" },
  { id: 7101, s: "PPC.AT", n: "Public Power Corporation S.A.", c: "Greece" },
  { id: 7102, s: "BBOX.L", n: "Tritax Big Box REIT plc", c: "UK" },
  { id: 7103, s: "GENTERA.MX", n: "Gentera S.A.B. de C.V", c: "Mexi" },
  { id: 7104, s: "HYBT", n: "Pacific WebWorks Inc", c: "US" },
  { id: 7105, s: "688396.SS", n: "CH Resources Microelectronics", c: "CH" },
  { id: 7106, s: "DNG.TO", n: "Dynacor Gold Mines Inc.", c: "CA" },
  { id: 7107, s: "CFIV", n: "CF Acquisition IV Co", c: "US" },
  { id: 7108, s: "SLVR.V", n: "Silver Tiger Metals Inc", c: "CA" },
  { id: 7109, s: "NBS.L", n: "Nationwide Building Society", c: "UK" },
  { id: 7110, s: "051900.KS", n: "LG Household & Healthcare", c: "Korea" },
  { id: 7111, s: "CAMX.ST", n: "Camurus AB", c: "Sweden" },
  { id: 7112, s: "HVT-A", n: "Haverty Furniture Companies Inc", c: "US" },
  { id: 7113, s: "PRV-UN.TO", n: "Pro Real Estate Investment Trust", c: "CA" },
  { id: 7114, s: "NGMD.V", n: "NuGen Medical Devices Inc", c: "CA" },
  { id: 7115, s: "AIHS", n: "Senmiao Technology", c: "US" },
  { id: 7116, s: "ANPDY", n: "ANTA Sports Products ADR", c: "US" },
  { id: 7117, s: "ERE-UN.TO", n: "European Residential Real Estate Investment Trust", c: "CA" },
  { id: 7118, s: "BKSC", n: "Bank of South Carolina Co", c: "US" },
  { id: 7119, s: "033780.KS", n: "KT&G Co", c: "Korea" },
  { id: 7120, s: "GMLPF", n: "Golar LNG Partners LP Series A Pref", c: "US" },
  { id: 7121, s: "DTF", n: "DTF Tax Free Income Closed Fund", c: "US" },
  { id: 7122, s: "GDSI", n: "Global Digital Soltn", c: "US" },
  { id: 7123, s: "HAI.TO", n: "Haivision Systems Inc", c: "CA" },
  { id: 7124, s: "2498.TW", n: "HTC Co", c: "TW" },
  { id: 7125, s: "MALT.PA", n: "Malteries Franco-Belges Société Anonyme", c: "FR" },
  { id: 7126, s: "JT", n: "Jianpu Technology Inc", c: "US" },
  { id: 7127, s: "KSC.AX", n: "K & S Corporation", c: "Australia" },
  { id: 7128, s: "AKSA.IS", n: "Aksa Akrilik Kimya Sanayi AS", c: "Turkey" },
  { id: 7129, s: "FRAF", n: "Franklin Financial Services Co", c: "US" },
  { id: 7130, s: "SARN.TA", n: "Sarine Technologies", c: "Israel" },
  { id: 7131, s: "PKO.WA", n: "Powszechna Kasa Oszczednosci Bank Polski SA", c: "Poland" },
  { id: 7132, s: "SK3.IR", n: "Smurfit Kappa Group PLC", c: "Ireland" },
  { id: 7133, s: "KWS.L", n: "Keywords Studios PLC", c: "UK" },
  { id: 7134, s: "DUST.ST", n: "Dustin Group AB", c: "Sweden" },
  { id: 7135, s: "FPAFY", n: "First Pacific Company", c: "US" },
  { id: 7136, s: "TLSY.TA", n: "Telsys", c: "Israel" },
  { id: 7137, s: "NANO.TO", n: "Nano One Materials Co", c: "CA" },
  { id: 7138, s: "000099.SZ", n: "Citic Offshore Helicopter", c: "CH" },
  { id: 7139, s: "2379.TW", n: "Realtek Semiconductor Co", c: "TW" },
  { id: 7140, s: "600036.SS", n: "CH Merchants Bank", c: "CH" },
  { id: 7141, s: "ADDT-B.ST", n: "Addtech AB (publ.)", c: "Sweden" },
  { id: 7142, s: "JAMN", n: "Jammin Java Co", c: "US" },
  { id: 7143, s: "AMY.V", n: "American Manganese Inc", c: "CA" },
  { id: 7144, s: "259960.KS", n: "Krafton Inc", c: "Korea" },
  { id: 7145, s: "MGYR", n: "Magyar Bancorp Inc", c: "US" },
  { id: 7146, s: "2356.TW", n: "Inventec Co", c: "TW" },
  { id: 7147, s: "WPK.TO", n: "Winpak.", c: "CA" },
  { id: 7148, s: "HUM.L", n: "Hummingbird Resources PLC", c: "UK" },
  { id: 7149, s: "BMXI", n: "Brookmount Explorations Inc", c: "US" },
  { id: 7150, s: "1101.TW", n: "Taiwan Cement Co", c: "TW" },
  { id: 7151, s: "MGDDY", n: "Compagnie Generale des Etablissements Michelin SCA ADR", c: "US" },
  { id: 7152, s: "GAL.AX", n: "Galileo Mining", c: "Australia" },
  { id: 7153, s: "PENTA.IS", n: "Penta Teknoloji Urunleri Dagitim Ticaret AS", c: "Turkey" },
  { id: 7154, s: "VAKFN.IS", n: "Vakif Finansal Kiralama AS", c: "Turkey" },
  { id: 7155, s: "ANZU", n: "Anzu Special Acquisition Corp I", c: "US" },
  { id: 7156, s: "IPE.WA", n: "Ipopema Securities SA", c: "Poland" },
  { id: 7157, s: "SVA.TO", n: "Sernova Co", c: "CA" },
  { id: 7158, s: "FGR.AX", n: "First Graphene", c: "Australia" },
  { id: 7159, s: "GXI.DE", n: "Gerresheimer AG", c: "GER" },
  { id: 7160, s: "BES.V", n: "Braille Energy Systems Inc", c: "CA" },
  { id: 7161, s: "KTY.WA", n: "Grupa KĘTY SA", c: "Poland" },
  { id: 7162, s: "BVZN.SW", n: "BVZ Holding AG", c: "Switzerland" },
  { id: 7163, s: "ADRT", n: "Ault Disruptive Technologies Co", c: "US" },
  { id: 7164, s: "PRS.OL", n: "Prosafe SE", c: "Norway" },
  { id: 7165, s: "PNPN.V", n: "Power Nickel Inc", c: "CA" },
  { id: 7166, s: "ESE.V", n: "ESE Entertainment Inc", c: "CA" },
  { id: 7167, s: "BWY.L", n: "Bellway PLC", c: "UK" },
  { id: 7168, s: "EU.V", n: "enCore Energy Corp.", c: "CA" },
  { id: 7169, s: "FURY.TO", n: "Fury Gold Mines", c: "CA" },
  { id: 7170, s: "BEZ.L", n: "Beazley plc", c: "UK" },
  { id: 7171, s: "AO.L", n: "Ao World", c: "UK" },
  { id: 7172, s: "GGD.TO", n: "GoGold Resources Inc.", c: "CA" },
  { id: 7173, s: "FRAS.L", n: "Frasers Group PLC", c: "UK" },
  { id: 7174, s: "688005.SS", n: "Ningbo Ronbay New Energy Tech", c: "CH" },
  { id: 7175, s: "ETX", n: "Eaton Vance Municipal Income Term Closed Fund", c: "US" },
  { id: 7176, s: "INPP.L", n: "International Public Partnership", c: "UK" },
  { id: 7177, s: "2880.TW", n: "Hua Nan Financial Holdings", c: "TW" },
  { id: 7178, s: "ELE.V", n: "Elemental Royalties Co", c: "CA" },
  { id: 7179, s: "CSUAY", n: "CH Shenhua Energy", c: "US" },
  { id: 7180, s: "GCC.OL", n: "Gram Car Carriers AS", c: "Norway" },
  { id: 7181, s: "HELI.V", n: "First Helium Inc", c: "CA" },
  { id: 7182, s: "JSE.L", n: "Jadestone Energy Inc", c: "UK" },
  { id: 7183, s: "INCT", n: "InCapta Inc", c: "US" },
  { id: 7184, s: "BCART.BR", n: "Biocartis Group NV", c: "Belgium" },
  { id: 7185, s: "FRSX.TA", n: "Foresight Autonomous Holdings", c: "Israel" },
  { id: 7186, s: "NWARF", n: "Norwegian Air Shuttle ASA", c: "US" },
  { id: 7187, s: "MEQ.TO", n: "Mainstreet Equity Corp.", c: "CA" },
  { id: 7188, s: "CEC.DE", n: "Ceconomy AG", c: "GER" },
  { id: 7189, s: "GXU.V", n: "GoviEx Uranium Inc", c: "CA" },
  { id: 7190, s: "BEDU", n: "Bright Scholar Education Holdings Class A", c: "US" },
  { id: 7191, s: "6470.TWO", n: "U-Media Communications", c: "TW" },
  { id: 7192, s: "TRN.L", n: "Trainline Plc", c: "UK" },
  { id: 7193, s: "SGTM", n: "National Storm Recovery Inc", c: "US" },
  { id: 7194, s: "INF.L", n: "Informa PLC", c: "UK" },
  { id: 7195, s: "VICO.ST", n: "Vicore Pharma Holding AB (publ)", c: "Sweden" },
  { id: 7196, s: "RNU.AX", n: "Renascor Resources", c: "Australia" },
  { id: 7197, s: "GEF-B", n: "Greif Inc", c: "US" },
  { id: 7198, s: "HRPK.DE", n: "7C Solarparken AG", c: "GER" },
  { id: 7199, s: "VK.PA", n: "Vallourec", c: "FR" },
  { id: 7200, s: "GFINBURO.MX", n: "Grupo Financiero Inbursa S.A.B. de C.V", c: "Mexi" },
  { id: 7201, s: "PPIH", n: "Perma-Pipe International Holdings Inc", c: "US" },
  { id: 7202, s: "IOG.L", n: "Independent Oil & Gas Plc", c: "UK" },
  { id: 7203, s: "LSAK", n: "Lesaka Technologies Inc", c: "US" },
  { id: 7204, s: "UUU.DE", n: "3U Holding AG", c: "GER" },
  { id: 7205, s: "TPE.DE", n: "PVA TePla AG", c: "GER" },
  { id: 7206, s: "PNDX-B.ST", n: "Pandox AB (publ)", c: "Sweden" },
  { id: 7207, s: "MIN.TO", n: "Excelsior Mining Co", c: "CA" },
  { id: 7208, s: "SSBI", n: "Summit State Bank", c: "US" },
  { id: 7209, s: "LIME.ST", n: "Lime Technologies AB (publ)", c: "Sweden" },
  { id: 7210, s: "RAIL3.SA", n: "Rumo S.A", c: "Brazil" },
  { id: 7211, s: "SAPMY", n: "Saipem SpA ADR", c: "US" },
  { id: 7212, s: "GLBZ", n: "Glen Burnie BanCo", c: "US" },
  { id: 7213, s: "USMJ", n: "North American Cannabis Holdings Inc", c: "US" },
  { id: 7214, s: "IQI", n: "Invesco Quality Municipal Income Closed Fund", c: "US" },
  { id: 7215, s: "FLY.V", n: "FLYHT Aerospace Solutions", c: "CA" },
  { id: 7216, s: "REA.AX", n: "Rea Group", c: "Australia" },
  { id: 7217, s: "IROQ", n: "IF Bancorp Inc", c: "US" },
  { id: 7218, s: "ELYM", n: "Eliem Therapeutics Inc", c: "US" },
  { id: 7219, s: "ZZZ.TO", n: "Sleep Country CA Hldg Inc", c: "CA" },
  { id: 7220, s: "CGS.AX", n: "CogState", c: "Australia" },
  { id: 7221, s: "BTBD", n: "Bt Brands Inc", c: "US" },
  { id: 7222, s: "DLN.L", n: "Derwent London PLC", c: "UK" },
  { id: 7223, s: "KCT.L", n: "Kin and Carta PLC", c: "UK" },
  { id: 7224, s: "EMIS.L", n: "EMIS Group PLC", c: "UK" },
  { id: 7225, s: "PT", n: "Pintec Technology Holdings", c: "US" },
  { id: 7226, s: "GPJA", n: "Georgia Power", c: "US" },
  { id: 7227, s: "1301.TW", n: "Formosa Plastics Co", c: "TW" },
  { id: 7228, s: "HTBI", n: "HomeTrust Bancshares Inc", c: "US" },
  { id: 7229, s: "ROVI.MC", n: "Laboratorios Farmaceuticos ROVI", c: "Spain" },
  { id: 7230, s: "JMSB", n: "John Marshall Bancorp Inc", c: "US" },
  { id: 7231, s: "PSYC", n: "Global Payout Inc.", c: "US" },
  { id: 7232, s: "ASB.AX", n: "Austal", c: "Australia" },
  { id: 7233, s: "MAPT", n: "Maptelligent Inc", c: "US" },
  { id: 7234, s: "LOGO.IS", n: "Logo Yazilim Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 7235, s: "SRAIL.SW", n: "Stadler Rail AG", c: "Switzerland" },
  { id: 7236, s: "SPN.V", n: "Snipp Interactive Inc", c: "CA" },
  { id: 7237, s: "RTN.L", n: "Restaurant Group PLC", c: "UK" },
  { id: 7238, s: "CALTX.ST", n: "Calliditas Therapeutics", c: "Sweden" },
  { id: 7239, s: "AFE.V", n: "Africa Energy Co", c: "CA" },
  { id: 7240, s: "DRM.TO", n: "Dream Unlimited Co", c: "CA" },
  { id: 7241, s: "BCOW", n: "1895 of Wisconsin Inc BanCo", c: "US" },
  { id: 7242, s: "WWT.V", n: "Water Ways Technologies Inc", c: "CA" },
  { id: 7243, s: "UCL", n: "Ucloudlink Group Inc", c: "US" },
  { id: 7244, s: "NGEX.V", n: "Ngex Minerals Ltd", c: "CA" },
  { id: 7245, s: "WISH.V", n: "Wishpond Technologies", c: "CA" },
  { id: 7246, s: "002812.SZ", n: "Yunnan Chuangxin New Material", c: "CH" },
  { id: 7247, s: "VKQ", n: "Invesco Municipal Trust", c: "US" },
  { id: 7248, s: "ANFGF", n: "Antofagasta PLC", c: "US" },
  { id: 7249, s: "3481.TW", n: "Innolux Co", c: "TW" },
  { id: 7250, s: "ROVIO.HE", n: "Rovio Entertainment", c: "Finland" },
  { id: 7251, s: "GTT.PA", n: "Gaztransport & Technigaz SAS", c: "FR" },
  { id: 7252, s: "CCP.L", n: "Celtic PLC", c: "UK" },
  { id: 7253, s: "VNTH", n: "Nano Mobile Healthcare Inc", c: "US" },
  { id: 7254, s: "GVDNY", n: "Givaudan SA ADR", c: "US" },
  { id: 7255, s: "KAR.AX", n: "Karoon Energy", c: "Australia" },
  { id: 7256, s: "ILM1.DE", n: "Medios AG", c: "GER" },
  { id: 7257, s: "002507.SZ", n: "Chongqing Fuling Zhacai Group", c: "CH" },
  { id: 7258, s: "LEHN.SW", n: "Lem Holding SA", c: "Switzerland" },
  { id: 7259, s: "KNB.L", n: "Kanabo Group PLC", c: "UK" },
  { id: 7260, s: "HIGH.V", n: "Highgold Mining Inc", c: "CA" },
  { id: 7261, s: "WBT.AX", n: "Weebit Nano", c: "Australia" },
  { id: 7262, s: "CKX", n: "CKX Lands Inc", c: "US" },
  { id: 7263, s: "TNE.AX", n: "Technology One", c: "Australia" },
  { id: 7264, s: "SCANA.OL", n: "Scana ASA", c: "Norway" },
  { id: 7265, s: "RF.PA", n: "Eurazeo", c: "FR" },
  { id: 7266, s: "HELN.SW", n: "Helvetia Holding AG", c: "Switzerland" },
  { id: 7267, s: "CTC.TO", n: "Canadian Tire Corporation Limited", c: "CA" },
  { id: 7268, s: "FWZ.V", n: "Fireweed Zinc", c: "CA" },
  { id: 7269, s: "ITV.L", n: "ITV PLC", c: "UK" },
  { id: 7270, s: "AUB.PA", n: "Aubay Société Anonyme", c: "FR" },
  { id: 7271, s: "688111.SS", n: "Beijing Kingsoft Office Software In", c: "CH" },
  { id: 7272, s: "GTG.AX", n: "Genetic Technologies", c: "Australia" },
  { id: 7273, s: "NWPN", n: "Now Co", c: "US" },
  { id: 7274, s: "MKKGY", n: "Merck KGaA ADR", c: "US" },
  { id: 7275, s: "TCBC", n: "TC Bancshares Inc", c: "US" },
  { id: 7276, s: "AROC.ST", n: "AroCell AB", c: "Sweden" },
  { id: 7277, s: "MCHOY", n: "MultiChoice Group ADR", c: "US" },
  { id: 7278, s: "AGY.AX", n: "Argosy Minerals", c: "Australia" },
  { id: 7279, s: "WHG", n: "Westwood Holdings Group Inc", c: "US" },
  { id: 7280, s: "ACAZF", n: "Acadian Timber Co", c: "US" },
  { id: 7281, s: "MKL.AX", n: "Mighty Kingdom", c: "Australia" },
  { id: 7282, s: "SMID", n: "Smith-Midland Co", c: "US" },
  { id: 7283, s: "011790.KS", n: "SKC Co.", c: "Korea" },
  { id: 7284, s: "EYDAP.AT", n: "Athens Water Supply and Sewerage Company S.A", c: "Greece" },
  { id: 7285, s: "INAQ", n: "Insight Acquisition Corp. Class A Common Stock", c: "US" },
  { id: 7286, s: "AIU", n: "Meta Data", c: "US" },
  { id: 7287, s: "PPJE", n: "PPJ Healthcare Enterprises Inc", c: "US" },
  { id: 7288, s: "601668.SS", n: "CH State Construction Engineering Co", c: "CH" },
  { id: 7289, s: "WFSTF", n: "Western Forest Products Inc", c: "US" },
  { id: 7290, s: "AFC.L", n: "AFC Energy plc", c: "UK" },
  { id: 7291, s: "PNI.AX", n: "Pinnacle Investment Management Group", c: "Australia" },
  { id: 7292, s: "SPRS", n: "Surge Components Inc", c: "US" },
  { id: 7293, s: "EMMS", n: "Emmis Communications Co", c: "US" },
  { id: 7294, s: "6278.TW", n: "Taiwan Surface Mounting Technology Co", c: "TW" },
  { id: 7295, s: "GOOD.L", n: "Good Energy Group PLC", c: "UK" },
  { id: 7296, s: "CAF.MC", n: "Construcciones y Auxiliar de Ferrocarriles S.A.", c: "Spain" },
  { id: 7297, s: "AZE.BR", n: "Azelis Group NV", c: "Belgium" },
  { id: 7298, s: "MSLH.L", n: "Marshalls PLC", c: "UK" },
  { id: 7299, s: "EDEN.L", n: "Eden Research plc", c: "UK" },
  { id: 7300, s: "MRG-UN.TO", n: "Morguard North American Residential Real Estate Investment Trust", c: "CA" },
  { id: 7301, s: "FCAP", n: "First Capital Inc", c: "US" },
  { id: 7302, s: "ASURB.MX", n: "Grupo Aeroportuario del Sureste S. A. B. de C. V", c: "Mexi" },
  { id: 7303, s: "EG7.ST", n: "Enad Global 7 AB", c: "Sweden" },
  { id: 7304, s: "DUE.DE", n: "Dürr Aktiengesellschaft", c: "GER" },
  { id: 7305, s: "E2N.MU", n: "Endor AG", c: "GER" },
  { id: 7306, s: "CE2.DE", n: "CropEnergies AG", c: "GER" },
  { id: 7307, s: "FLYN.V", n: "Flying Nickel Mining Co", c: "CA" },
  { id: 7308, s: "I3E.L", n: "I3 Energy PLC", c: "UK" },
  { id: 7309, s: "PANR.L", n: "Pantheon Resources", c: "UK" },
  { id: 7310, s: "FABG.ST", n: "Fabege AB", c: "Sweden" },
  { id: 7311, s: "SRL.AX", n: "Sunrise Energy Metals", c: "Australia" },
  { id: 7312, s: "VONN.SW", n: "Vontobel Holding", c: "Switzerland" },
  { id: 7313, s: "AGX.V", n: "Silver X Mining Co", c: "CA" },
  { id: 7314, s: "RWA.L", n: "Robert Walters", c: "UK" },
  { id: 7315, s: "FAE.MC", n: "Faes Farma SA", c: "Spain" },
  { id: 7316, s: "RCH.TO", n: "Richelieu Hardware.", c: "CA" },
  { id: 7317, s: "KEI.TO", n: "Kolibri Global Energy Inc", c: "CA" },
  { id: 7318, s: "2344.TW", n: "Winbond Electronics Co", c: "TW" },
  { id: 7319, s: "CICHY", n: "CH Construction Bank Co", c: "US" },
  { id: 7320, s: "GLAD.V", n: "Gladiator Metals Co", c: "CA" },
  { id: 7321, s: "DOCM.SW", n: "DocMorris AG", c: "Switzerland" },
  { id: 7322, s: "BRG-B.ST", n: "Bergs Timber AB (publ)", c: "Sweden" },
  { id: 7323, s: "LUG.TO", n: "Lundin Gold Inc", c: "CA" },
  { id: 7324, s: "SMY.V", n: "Search Minerals Inc", c: "CA" },
  { id: 7325, s: "GMX.TO", n: "Globex Mining Enterprises Inc.", c: "CA" },
  { id: 7326, s: "ALFPC.PA", n: "Fountaine Pajo", c: "FR" },
  { id: 7327, s: "GJF.OL", n: "Gjensidige Forsikring ASA", c: "Norway" },
  { id: 7328, s: "RWS.L", n: "RWS Holdings PLC", c: "UK" },
  { id: 7329, s: "005090.KS", n: "SGC Energy", c: "Korea" },
  { id: 7330, s: "RITE", n: "Mineralrite Corporat", c: "US" },
  { id: 7331, s: "RWWI", n: "Rand Worldwide Inc", c: "US" },
  { id: 7332, s: "CALB", n: "California BanCo", c: "US" },
  { id: 7333, s: "DOS.V", n: "Dios Exploration Inc.", c: "CA" },
  { id: 7334, s: "NGD.TO", n: "New Gold Inc", c: "CA" },
  { id: 7335, s: "GROW.L", n: "Draper Esprit PLC", c: "UK" },
  { id: 7336, s: "BLD.AX", n: "Boral.", c: "Australia" },
  { id: 7337, s: "VBG-B.ST", n: "VBG Group AB (publ)", c: "Sweden" },
  { id: 7338, s: "PAY.TO", n: "Payfare Inc", c: "CA" },
  { id: 7339, s: "MOLN", n: "Molecular Partners AG ADR", c: "US" },
  { id: 7340, s: "TECO.OL", n: "Teco 2030 Asa", c: "Norway" },
  { id: 7341, s: "688521.SS", n: "VeriSilicon Microelectronics Shanghai", c: "CH" },
  { id: 7342, s: "4735.TWO", n: "AViTA Corporation", c: "TW" },
  { id: 7343, s: "NABZY", n: "National Australia Bank ADR", c: "US" },
  { id: 7344, s: "2618.TW", n: "Eva Airways Co", c: "TW" },
  { id: 7345, s: "HYW", n: "Hywin Holdings ADR", c: "US" },
  { id: 7346, s: "EPEN.ST", n: "Ependion AB", c: "Sweden" },
  { id: 7347, s: "PUCK", n: "Goal Acquisitions Co", c: "US" },
  { id: 7348, s: "NBN", n: "Northeast BanCo", c: "US" },
  { id: 7349, s: "AAPJ", n: "AAP Inc", c: "US" },
  { id: 7350, s: "RAIVV.HE", n: "Raisio Oyj Vaihto-osake", c: "Finland" },
  { id: 7351, s: "AOF.DE", n: "ATOSS Software AG", c: "GER" },
  { id: 7352, s: "RMBI", n: "Richmond Mutual Bancorporation Inc", c: "US" },
  { id: 7353, s: "FCNE", n: "Four Corners Inc", c: "US" },
  { id: 7354, s: "4906.TW", n: "Gemtek Technology", c: "TW" },
  { id: 7355, s: "VII", n: "7GC & Holdings Co Inc", c: "US" },
  { id: 7356, s: "PBL.TO", n: "Pollard Banknote Limited", c: "CA" },
  { id: 7357, s: "CMSC", n: "CMS Energy Co", c: "US" },
  { id: 7358, s: "MX.TO", n: "Methanex Corporation", c: "CA" },
  { id: 7359, s: "MIPS.ST", n: "MIPS AB", c: "Sweden" },
  { id: 7360, s: "NRZ.AX", n: "Neurizer", c: "Australia" },
  { id: 7361, s: "360.AX", n: "Life360 Inc", c: "Australia" },
  { id: 7362, s: "EZFL", n: "EzFill Holdings Inc", c: "US" },
  { id: 7363, s: "RGT", n: "Royce Global Value Trust Inc", c: "US" },
  { id: 7364, s: "4DS.AX", n: "4DS Memory", c: "Australia" },
  { id: 7365, s: "NRO.PA", n: "Neurones", c: "FR" },
  { id: 7366, s: "NAN.AX", n: "Nanosonics", c: "Australia" },
  { id: 7367, s: "4919.TW", n: "Nuvoton Technology Co", c: "TW" },
  { id: 7368, s: "BELA.AT", n: "Jumbo S.A.", c: "Greece" },
  { id: 7369, s: "BAMNB.AS", n: "Koninklijke BAM Groep NV", c: "Netherlands" },
  { id: 7370, s: "DPMLF", n: "Dundee Precious Metals Inc", c: "US" },
  { id: 7371, s: "VGLS", n: "Vg Life Sciences Inc", c: "US" },
  { id: 7372, s: "SEOAY", n: "Stora Enso Oyj PK", c: "US" },
  { id: 7373, s: "SAE.V", n: "Sable Resources", c: "CA" },
  { id: 7374, s: "MODD", n: "Modular Medical Inc", c: "US" },
  { id: 7375, s: "WEW.DE", n: "Westwing Group AG", c: "GER" },
  { id: 7376, s: "2324.TW", n: "Compal Electronics Inc", c: "TW" },
  { id: 7377, s: "BYL.TO", n: "Baylin Technologies Inc", c: "CA" },
  { id: 7378, s: "DTSS", n: "Datasea Inc", c: "US" },
  { id: 7379, s: "ABC.AX", n: "Adbri", c: "Australia" },
  { id: 7380, s: "SXS.L", n: "Spectris PLC", c: "UK" },
  { id: 7381, s: "CFW.TO", n: "Calfrac Well Services.", c: "CA" },
  { id: 7382, s: "IPSEY", n: "Ipsen SA ADR", c: "US" },
  { id: 7383, s: "MATAS.", n: "Matas A/S", c: "Denmark" },
  { id: 7384, s: "EDV.AX", n: "Endeavour Group", c: "Australia" },
  { id: 7385, s: "VASCONI.MX", n: "Grupo Vasconia S.A.B", c: "Mexi" },
  { id: 7386, s: "MRM", n: "Medirom Healthcare Technologies Inc", c: "US" },
  { id: 7387, s: "PHX.TO", n: "PHX Energy Services Corporation", c: "CA" },
  { id: 7388, s: "AMBEA.ST", n: "Ambea AB", c: "Sweden" },
  { id: 7389, s: "LEON.SW", n: "Leonteq AG", c: "Switzerland" },
  { id: 7390, s: "GYRO", n: "Gyrodyne Company of America Inc", c: "US" },
  { id: 7391, s: "GDLNF", n: "Greenland Minerals And Energy", c: "US" },
  { id: 7392, s: "GNTY", n: "Guaranty Bancshares Inc.", c: "US" },
  { id: 7393, s: "KBX.DE", n: "Knorr-Bremse Aktiengesellschaft", c: "GER" },
  { id: 7394, s: "021240.KS", n: "COWAY Co.", c: "Korea" },
  { id: 7395, s: "BKN", n: "BlackRock Investment Quality Municipal Closed Fund", c: "US" },
  { id: 7396, s: "MAV.TO", n: "Mav Beauty Brands Inc", c: "CA" },
  { id: 7397, s: "MMK.VI", n: "Mayr-Melnhof Karton AG", c: "Austria" },
  { id: 7398, s: "WSRC", n: "Western Sierra Mining Co", c: "US" },
  { id: 7399, s: "ECEZ", n: "Ecosciences Inc", c: "US" },
  { id: 7400, s: "AFK.OL", n: "Arendals Fossekompani ASA", c: "Norway" },
  { id: 7401, s: "CLA-B.ST", n: "Cloetta AB (publ)", c: "Sweden" },
  { id: 7402, s: "PTIX", n: "Protagenic Therapeutics", c: "US" },
  { id: 7403, s: "SFIO", n: "Starfleet Innotech Inc", c: "US" },
  { id: 7404, s: "SFRGY", n: "Salvatore Ferragamo SpA ADR", c: "US" },
  { id: 7405, s: "VQS.TO", n: "Viq Solutions Inc", c: "CA" },
  { id: 7406, s: "EMBR3.SA", n: "Embraer S.A", c: "Brazil" },
  { id: 7407, s: "Y.TO", n: "Yellow Pages Limited", c: "CA" },
  { id: 7408, s: "CSR.AX", n: "CSR", c: "Australia" },
  { id: 7409, s: "AERO.SW", n: "Montana Aerospace AG", c: "Switzerland" },
  { id: 7410, s: "GRND3.SA", n: "Grendene S.A", c: "Brazil" },
  { id: 7411, s: "HPQ.V", n: "HPQ Silicon Resources Inc", c: "CA" },
  { id: 7412, s: "BOTX.ST", n: "Botnia Exploration Holding AB", c: "Sweden" },
  { id: 7413, s: "ACLN.SW", n: "Accelleron Industries AG", c: "Switzerland" },
  { id: 7414, s: "BCML", n: "BayCom Co", c: "US" },
  { id: 7415, s: "SWKH", n: "SWK Holdings Co", c: "US" },
  { id: 7416, s: "USRM", n: "US Stem Cell Inc", c: "US" },
  { id: 7417, s: "AINC", n: "Ashford Inc", c: "US" },
  { id: 7418, s: "ARL", n: "American Realty Investors Inc", c: "US" },
  { id: 7419, s: "SVM.TO", n: "Silvercorp Metals Inc", c: "CA" },
  { id: 7420, s: "ENTG.V", n: "Entourage Health Co", c: "CA" },
  { id: 7421, s: "NUGS", n: "Cannabis Strategic Ventures", c: "US" },
  { id: 7422, s: "GPX.ST", n: "Gasporox AB (publ)", c: "Sweden" },
  { id: 7423, s: "ERIC-A.ST", n: "Telefonaktiebolaget LM Ericsson (publ)", c: "Sweden" },
  { id: 7424, s: "HEIO.AS", n: "Heineken Holding NV", c: "Netherlands" },
  { id: 7425, s: "GAIA", n: "Gaia Inc", c: "US" },
  { id: 7426, s: "BUFAB.ST", n: "Bufab Holding AB", c: "Sweden" },
  { id: 7427, s: "RED.AX", n: "Red 5", c: "Australia" },
  { id: 7428, s: "WGX.AX", n: "Westgold Resources", c: "Australia" },
  { id: 7429, s: "CLST", n: "Catalyst Bancorp Inc", c: "US" },
  { id: 7430, s: "BOSN.SW", n: "Bossard Holding AG", c: "Switzerland" },
  { id: 7431, s: "BAS.F", n: "BASF SE", c: "GER" },
  { id: 7432, s: "APTY", n: "APT Systems Inc", c: "US" },
  { id: 7433, s: "285130.KS", n: "SK Chemicals", c: "Korea" },
  { id: 7434, s: "SLLDY", n: "Sanlam PK", c: "US" },
  { id: 7435, s: "AXTELCPO.MX", n: "Axtel S.A.B. de C.V", c: "Mexi" },
  { id: 7436, s: "NBGIF", n: "National Bank of Greece SA", c: "US" },
  { id: 7437, s: "ESK.V", n: "Eskay Mining Co", c: "CA" },
  { id: 7438, s: "PMZ-UN.TO", n: "Primaris Retail R.E. Invest. Tr. Units", c: "CA" },
  { id: 7439, s: "TKO.TO", n: "Taseko Mines", c: "CA" },
  { id: 7440, s: "BIMAS.IS", n: "BIM Birlesik Magazalar AS", c: "Turkey" },
  { id: 7441, s: "MGYOY", n: "MOL PLC ADR", c: "US" },
  { id: 7442, s: "SOMLY", n: "Secom Co ADR", c: "US" },
  { id: 7443, s: "CJET.NE", n: "CA Jetlines Operations", c: "CA" },
  { id: 7444, s: "HEM.V", n: "Hemostemix Inc", c: "CA" },
  { id: 7445, s: "AXL.V", n: "Arrow Exploration Co", c: "CA" },
  { id: 7446, s: "RBKB", n: "Rhinebeck Bancorp Inc", c: "US" },
  { id: 7447, s: "DB.V", n: "Decibel Cannabis Company Inc", c: "CA" },
  { id: 7448, s: "RCG", n: "RENN Fund Inc", c: "US" },
  { id: 7449, s: "DBOEY", n: "Deutsche Boerse AG ADR", c: "US" },
  { id: 7450, s: "RDCM", n: "Radcom", c: "US" },
  { id: 7451, s: "VIAO", n: "Via Optronics Ag", c: "US" },
  { id: 7452, s: "HMNF", n: "HMN Financial Inc", c: "US" },
  { id: 7453, s: "NPAPER.ST", n: "Nordic Paper Holding AB", c: "Sweden" },
  { id: 7454, s: "AVL.AX", n: "Australian Vanadium", c: "Australia" },
  { id: 7455, s: "ELM.L", n: "Elementis PLC", c: "UK" },
  { id: 7456, s: "CVX.V", n: "Cematrix Corporation", c: "CA" },
  { id: 7457, s: "ODD.V", n: "Odd Burger Co", c: "CA" },
  { id: 7458, s: "RSPI", n: "RespireRx Pharmaceuticals Inc", c: "US" },
  { id: 7459, s: "POST.VI", n: "Oesterr. Post AG", c: "Austria" },
  { id: 7460, s: "LNZ.VI", n: "Lenzing Aktiengesellschaft", c: "Austria" },
  { id: 7461, s: "HOC.L", n: "Hochschild Mining plc", c: "UK" },
  { id: 7462, s: "ELLXF", n: "Elixinol Global", c: "US" },
  { id: 7463, s: "BALN.SW", n: "Baloise Holding AG", c: "Switzerland" },
  { id: 7464, s: "FBIZ", n: "First Business Financial Services", c: "US" },
  { id: 7465, s: "DOM.L", n: "Domino’s Pizza Group PLC", c: "UK" },
  { id: 7466, s: "AOT.TO", n: "Ascot Resources.", c: "CA" },
  { id: 7467, s: "FC", n: "First Community Corporation", c: "US" },
  { id: 7468, s: "FATBB", n: "FAT Brands Inc", c: "US" },
  { id: 7469, s: "8046.TW", n: "Nan Ya Printed Circuit Board Co", c: "TW" },
  { id: 7470, s: "CLPBY", n: "Coloplast A", c: "US" },
  { id: 7471, s: "ENR.F", n: "Siemens Energy AG", c: "GER" },
  { id: 7472, s: "DTE.F", n: "Deutsche Telekom AG", c: "GER" },
  { id: 7473, s: "GLB.AX", n: "Globe International", c: "Australia" },
  { id: 7474, s: "VIS.MC", n: "Viscofan", c: "Spain" },
  { id: 7475, s: "HEN.DE", n: "Henkel AG & Co. KGaA", c: "GER" },
  { id: 7476, s: "600309.SS", n: "Wanhua Chemical Group", c: "CH" },
  { id: 7477, s: "AAPT", n: "All American Pet", c: "US" },
  { id: 7478, s: "2408.TW", n: "Nanya Technology Co", c: "TW" },
  { id: 7479, s: "MKO.V", n: "Mako Mining Co", c: "CA" },
  { id: 7480, s: "PCT.L", n: "Polar Capital Technology Trust", c: "UK" },
  { id: 7481, s: "ZPTAF", n: "Surge Energy Inc.", c: "US" },
  { id: 7482, s: "PDG.L", n: "Pendragon PLC", c: "UK" },
  { id: 7483, s: "300433.SZ", n: "Lens Technology", c: "CH" },
  { id: 7484, s: "BOPO", n: "Biopower Operations Co", c: "US" },
  { id: 7485, s: "ENJSA.IS", n: "Enerjisa Enerji AS", c: "Turkey" },
  { id: 7486, s: "084990.KQ", n: "Helixmith Co.", c: "Korea" },
  { id: 7487, s: "CRG.IR", n: "CRH PLC", c: "Ireland" },
  { id: 7488, s: "OPT", n: "Opthea", c: "US" },
  { id: 7489, s: "2834.TW", n: "Taiwan Business Bank", c: "TW" },
  { id: 7490, s: "WCG.AX", n: "Webcentral Group", c: "Australia" },
  { id: 7491, s: "MLP.DE", n: "MLP SE", c: "GER" },
  { id: 7492, s: "085370.KQ", n: "Lutronic Corporation", c: "Korea" },
  { id: 7493, s: "SWP.AX", n: "Swoop Holdings", c: "Australia" },
  { id: 7494, s: "GUAR3.SA", n: "Guararapes Confecções S.A", c: "Brazil" },
  { id: 7495, s: "NCP.TO", n: "Nickel Creek Platinum Co", c: "CA" },
  { id: 7496, s: "096770.KS", n: "SK Innovation", c: "Korea" },
  { id: 7497, s: "BUB.AX", n: "Bubs Australia", c: "Australia" },
  { id: 7498, s: "MTO.L", n: "Mitie Group PLC", c: "UK" },
  { id: 7499, s: "ALESK.PA", n: "Esker SA", c: "FR" },
  { id: 7500, s: "CRRFY", n: "Carrefour SA PK", c: "US" },
  { id: 7501, s: "CWBC", n: "Community West Bancshares", c: "US" },
  { id: 7502, s: "3515.TW", n: "ASRock Inc", c: "TW" },
  { id: 7503, s: "PMV.AX", n: "Premier Investments", c: "Australia" },
  { id: 7504, s: "PPGN.SW", n: "PolyPeptide Group AG", c: "Switzerland" },
  { id: 7505, s: "LISP.SW", n: "Chocoladefabriken Lindt & Spruengli AG Part", c: "Switzerland" },
  { id: 7506, s: "ULTI.OL", n: "Ultimovacs ASA", c: "Norway" },
  { id: 7507, s: "YOU.DE", n: "ABOUT YOU Holding AG", c: "GER" },
  { id: 7508, s: "DDDX", n: "3Dx Industries Inc", c: "US" },
  { id: 7509, s: "ICOA", n: "ICOA Inc", c: "US" },
  { id: 7510, s: "MURGY", n: "Muenchener Rueckver Ges", c: "US" },
  { id: 7511, s: "AUMBF", n: "1911 Gold Co", c: "US" },
  { id: 7512, s: "601669.SS", n: "Power Construction Corp of CH", c: "CH" },
  { id: 7513, s: "GHLD", n: "Guild Holdings ", c: "US" },
  { id: 7514, s: "IVS.V", n: "Inventus Mining Co", c: "CA" },
  { id: 7515, s: "CRARY", n: "Credit Agricole SA PK", c: "US" },
  { id: 7516, s: "NOG.L", n: "Nostrum Oil & Gas PLC", c: "UK" },
  { id: 7517, s: "DLEA.TA", n: "Delek Automotive Systems", c: "Israel" },
  { id: 7518, s: "DGL.AX", n: "DGL Group", c: "Australia" },
  { id: 7519, s: "WWI.OL", n: "Wilh Wilhelmsen Holding ASA A", c: "Norway" },
  { id: 7520, s: "1215.TW", n: "Charoen Pokphand Enterprise Taiwan", c: "TW" },
  { id: 7521, s: "PRN.TO", n: "Profound Medical Co", c: "CA" },
  { id: 7522, s: "SGIOY", n: "Shionogi & Co ADR", c: "US" },
  { id: 7523, s: "VCV", n: "Invesco California Value Muni Inc Tr", c: "US" },
  { id: 7524, s: "AAGIY", n: "AIA Group ADR", c: "US" },
  { id: 7525, s: "SSVR.V", n: "Summa Silver Co", c: "CA" },
  { id: 7526, s: "EFOI", n: "Energy Focu", c: "US" },
  { id: 7527, s: "PHM.MC", n: "Pharma Mar S.A.", c: "Spain" },
  { id: 7528, s: "VPV", n: "Invesco Pennsylvania Value Municipal Income Trust", c: "US" },
  { id: 7529, s: "NSTS", n: "NSTS Bancorp Inc", c: "US" },
  { id: 7530, s: "VDMCY", n: "Vodacom Group PK", c: "US" },
  { id: 7531, s: "BYM", n: "BlackRock Municipal Income Quality Closed Fund", c: "US" },
  { id: 7532, s: "BIOT.ST", n: "Biotage AB", c: "Sweden" },
  { id: 7533, s: "LOVE.V", n: "Cannara Biotech Inc", c: "CA" },
  { id: 7534, s: "1216.TW", n: "Uni-President Enterprises Co", c: "TW" },
  { id: 7535, s: "EAC", n: "Edify Acquisition Co", c: "US" },
  { id: 7536, s: "YPSN.SW", n: "Ypsomed Holding AG", c: "Switzerland" },
  { id: 7537, s: "HLS.AX", n: "Healius", c: "Australia" },
  { id: 7538, s: "SWP.PA", n: "Sword Group S.E", c: "FR" },
  { id: 7539, s: "ARGX.BR", n: "Argen-X", c: "Belgium" },
  { id: 7540, s: "EMH.AX", n: "European Metals Holdings", c: "Australia" },
  { id: 7541, s: "PNN.L", n: "Pennon Group", c: "UK" },
  { id: 7542, s: "4737.TW", n: "Bionime Co", c: "TW" },
  { id: 7543, s: "LNSTY", n: "London Stock Exchange ADR", c: "US" },
  { id: 7544, s: "TTKOM.IS", n: "Turk Telekomunikasyon AS", c: "Turkey" },
  { id: 7545, s: "CAMB.BR", n: "Campine", c: "Belgium" },
  { id: 7546, s: "ALNPY", n: "ANA Holdings Inc ADR", c: "US" },
  { id: 7547, s: "MOGO.TO", n: "Mogo Inc", c: "CA" },
  { id: 7548, s: "BIOPOR.", n: "BioPorto", c: "Denmark" },
  { id: 7549, s: "FHYD.V", n: "First Hydrogen Co", c: "CA" },
  { id: 7550, s: "IMMO.BR", n: "Immobel", c: "Belgium" },
  { id: 7551, s: "PEN.OL", n: "Panoro Energy ASA", c: "Norway" },
  { id: 7552, s: "600900.SS", n: "CH Yangtze Power", c: "CH" },
  { id: 7553, s: "BO.", n: "Bang & Olufsen", c: "Denmark" },
  { id: 7554, s: "FAGR.BR", n: "Fagron NV", c: "Belgium" },
  { id: 7555, s: "SNBN.SW", n: "Schweizerische Nationalbank", c: "Switzerland" },
  { id: 7556, s: "LEHKQ", n: "LEHMAN BROTHERS HOLDINGS CAP TS", c: "US" },
  { id: 7557, s: "NOEJ.DE", n: "Norma Group AG NA O.N.", c: "GER" },
  { id: 7558, s: "HTOOW", n: "Fusion Fuel Green PLC", c: "US" },
  { id: 7559, s: "STJ.L", n: "St. James's Place plc", c: "UK" },
  { id: 7560, s: "MMGR-B.ST", n: "Momentum Group AB (publ)", c: "Sweden" },
  { id: 7561, s: "CNF", n: "CNFinance Holdings", c: "US" },
  { id: 7562, s: "300014.SZ", n: "EVE Energy", c: "CH" },
  { id: 7563, s: "GYM.L", n: "The GYM Group PLC", c: "UK" },
  { id: 7564, s: "1526.TW", n: "Sun Race Sturmey-Archer Inc", c: "TW" },
  { id: 7565, s: "5880.TW", n: "Taiwan Cooperative Financial Holding", c: "TW" },
  { id: 7566, s: "OTOEL.AT", n: "Autohellas S.A.", c: "Greece" },
  { id: 7567, s: "ANIOY", n: "Acerinox SA ADR", c: "US" },
  { id: 7568, s: "DGWR", n: "Critic Clothing Inc", c: "US" },
  { id: 7569, s: "CTT.LS", n: "CTT Correios de Portugal SA", c: "Portugal" },
  { id: 7570, s: "OVH.PA", n: "Ovh Groupe SAS", c: "FR" },
  { id: 7571, s: "GL1.AX", n: "Global Lithium Resources", c: "Australia" },
  { id: 7572, s: "CBFV", n: "CB Financial Services Inc", c: "US" },
  { id: 7573, s: "DEFN.V", n: "Defense Metals Co", c: "CA" },
  { id: 7574, s: "NLH.V", n: "Nova Leap Health Co", c: "CA" },
  { id: 7575, s: "MXG.TO", n: "Maxim Power Corp.", c: "CA" },
  { id: 7576, s: "PLYZ", n: "Plyzer Technologies Inc", c: "US" },
  { id: 7577, s: "CMAKY", n: "CH Minsh", c: "US" },
  { id: 7578, s: "GLPEY", n: "Galp Energa", c: "US" },
  { id: 7579, s: "PNE3.DE", n: "PNE AG", c: "GER" },
  { id: 7580, s: "ESM.TO", n: "Euro Sun Mining Inc", c: "CA" },
  { id: 7581, s: "NXU.DE", n: "Nexus AG", c: "GER" },
  { id: 7582, s: "GRKP.SW", n: "Graubuendner Kantonalbank", c: "Switzerland" },
  { id: 7583, s: "DSE.AX", n: "Dropsuite", c: "Australia" },
  { id: 7584, s: "MSLOY", n: "Mitsui OSK Lines ADR", c: "US" },
  { id: 7585, s: "NSARO", n: "NSTAR Electric Company", c: "US" },
  { id: 7586, s: "EDT.TO", n: "Spectral Med Inc", c: "CA" },
  { id: 7587, s: "GNCL.TA", n: "Gencell", c: "Israel" },
  { id: 7588, s: "ENX", n: "Eaton Vance New York MBF", c: "US" },
  { id: 7589, s: "FRACTL.ST", n: "Fractal Gaming Group AB", c: "Sweden" },
  { id: 7590, s: "BNCH.V", n: "Benchmark Metals Inc", c: "CA" },
  { id: 7591, s: "ROOD.AS", n: "RoodMicrotec N.V", c: "Netherlands" },
  { id: 7592, s: "EMV.AX", n: "EMvision Medical Devices", c: "Australia" },
  { id: 7593, s: "CEMAT.", n: "Cemat A/S", c: "Denmark" },
  { id: 7594, s: "SBFG", n: "SB Financial Group Inc", c: "US" },
  { id: 7595, s: "CADUA.MX", n: "Corpovael S.A.B. de C.V", c: "Mexi" },
  { id: 7596, s: "300677.SZ", n: "Shandong Intco Medical Products", c: "CH" },
  { id: 7597, s: "NTG.", n: "NTG Nordic Transport Group A/S", c: "Denmark" },
  { id: 7598, s: "002186.SZ", n: "CH Quanjude Group", c: "CH" },
  { id: 7599, s: "TSGTY", n: "Tsingtao Brewery", c: "US" },
  { id: 7600, s: "BACA", n: "Berenson Acquisition Corp I", c: "US" },
  { id: 7601, s: "HMU.DE", n: "HMS Bergbau AG", c: "GER" },
  { id: 7602, s: "PLW.WA", n: "PLAYWAY SA", c: "Poland" },
  { id: 7603, s: "BYIT.L", n: "Bytes Technology", c: "UK" },
  { id: 7604, s: "ALARK.IS", n: "Alarko Holding AS", c: "Turkey" },
  { id: 7605, s: "8TRA.DE", n: "Traton SE", c: "GER" },
  { id: 7606, s: "CLAS-B.ST", n: "Clas Ohlson AB (publ)", c: "Sweden" },
  { id: 7607, s: "BBB.V", n: "Brixton Metals Corporation", c: "CA" },
  { id: 7608, s: "ECCC", n: "Eagle Point Credit Company Inc Preferred", c: "US" },
  { id: 7609, s: "NLFSK.", n: "Nilfisk Holding A/S", c: "Denmark" },
  { id: 7610, s: "LOT.AX", n: "Lotus Resources", c: "Australia" },
  { id: 7611, s: "SVL.AX", n: "Silver Mines", c: "Australia" },
  { id: 7612, s: "FQVLF", n: "First Quantum Minerals", c: "US" },
  { id: 7613, s: "NEXTA.BR", n: "Nextensa NV", c: "Belgium" },
  { id: 7614, s: "ERF.TO", n: "Enerplus Corporation", c: "CA" },
  { id: 7615, s: "MKTAY", n: "Makita Co", c: "US" },
  { id: 7616, s: "DUR.AX", n: "Duratec Ltd", c: "Australia" },
  { id: 7617, s: "SKIS-B.ST", n: "SkiStar AB (publ)", c: "Sweden" },
  { id: 7618, s: "FRA.DE", n: "Fraport AG", c: "GER" },
  { id: 7619, s: "SMCAY", n: "SMC Corp Japan", c: "US" },
  { id: 7620, s: "032500.KQ", n: "Kmw Inc", c: "Korea" },
  { id: 7621, s: "CKG.V", n: "Chesapeake Gold Corp.", c: "CA" },
  { id: 7622, s: "VTY.L", n: "Vistry Group PLC", c: "UK" },
  { id: 7623, s: "CUBB", n: "Customers Bancorp Inc", c: "US" },
  { id: 7624, s: "HLVX", n: "Hillevax Inc", c: "US" },
  { id: 7625, s: "RBGP.L", n: "RBG Holdings PLC", c: "UK" },
  { id: 7626, s: "FOSUF", n: "Fosun International", c: "US" },
  { id: 7627, s: "BDOUY", n: "BDO Unibank Inc ADR", c: "US" },
  { id: 7628, s: "XBRANE.ST", n: "Xbrane Biopharma AB", c: "Sweden" },
  { id: 7629, s: "2891.TW", n: "CTBC Financial Holding", c: "TW" },
  { id: 7630, s: "OBT", n: "Orange County Bancorp Inc", c: "US" },
  { id: 7631, s: "IGEX", n: "Indo Global Exchange", c: "US" },
  { id: 7632, s: "SAVE.ST", n: "Nordnet AB", c: "Sweden" },
  { id: 7633, s: "VZN.SW", n: "VZ Holding AG", c: "Switzerland" },
  { id: 7634, s: "BBUC.TO", n: "Brookfield Business Co", c: "CA" },
  { id: 7635, s: "1527.TW", n: "Basso Industry Co", c: "TW" },
  { id: 7636, s: "ALSN.SW", n: "Also Holding AG", c: "Switzerland" },
  { id: 7637, s: "GENM.TO", n: "Generation Mining", c: "CA" },
  { id: 7638, s: "AFBI", n: "Affinity Bancshares Inc", c: "US" },
  { id: 7639, s: "ALBLD.PA", n: "Bilendi", c: "FR" },
  { id: 7640, s: "036800.KQ", n: "Nice Information & Telecommunication Inc", c: "Korea" },
  { id: 7641, s: "VLS.L", n: "Velocys plc", c: "UK" },
  { id: 7642, s: "316140.KS", n: "Woori Financial Group Inc", c: "Korea" },
  { id: 7643, s: "QLT.L", n: "Quilter PLC", c: "UK" },
  { id: 7644, s: "PEX.V", n: "Pacific Ridge Exploration", c: "CA" },
  { id: 7645, s: "PHOT", n: "Growlife Inc", c: "US" },
  { id: 7646, s: "BREZ", n: "Breeze Holdings Acquisition Co", c: "US" },
  { id: 7647, s: "GSFI", n: "Green Stream Holdings Inc", c: "US" },
  { id: 7648, s: "ABWN", n: "Airborne Wireless Network", c: "US" },
  { id: 7649, s: "FSBW", n: "FS Bancorp Inc", c: "US" },
  { id: 7650, s: "KPG.AX", n: "Kelly Partners Group Holdings", c: "Australia" },
  { id: 7651, s: "GET.PA", n: "Getlink SE", c: "FR" },
  { id: 7652, s: "COK.DE", n: "Cancom SE", c: "GER" },
  { id: 7653, s: "BBN.AX", n: "Baby Bunting Group", c: "Australia" },
  { id: 7654, s: "RMSL", n: "REMSleep Holdings Inc", c: "US" },
  { id: 7655, s: "MGN.OL", n: "Magnora ASA", c: "Norway" },
  { id: 7656, s: "003490.KS", n: "Korean Air Lines", c: "Korea" },
  { id: 7657, s: "TITAN.HE", n: "Titanium Oyj", c: "Finland" },
  { id: 7658, s: "GWHP", n: "Global Wholehealth Partners Co", c: "US" },
  { id: 7659, s: "IDVV", n: "International Endeavors Co", c: "US" },
  { id: 7660, s: "BEN.PA", n: "Bénéteau S.A.", c: "FR" },
  { id: 7661, s: "BELL.SW", n: "Bell AG", c: "Switzerland" },
  { id: 7662, s: "MONT.BR", n: "Montea C.V.A.", c: "Belgium" },
  { id: 7663, s: "VIG.VI", n: "Vienna Insurance Group AG", c: "Austria" },
  { id: 7664, s: "ERD.TO", n: "Erdene Resource Development Co", c: "CA" },
  { id: 7665, s: "BOT.AX", n: "Botanix Pharmaceuticals", c: "Australia" },
  { id: 7666, s: "PEXIP.OL", n: "Pexip Holding ASA", c: "Norway" },
  { id: 7667, s: "EVS.BR", n: "EVS Broadcast Equipment SA", c: "Belgium" },
  { id: 7668, s: "CAR.AX", n: "Carsales.Com", c: "Australia" },
  { id: 7669, s: "CN.V", n: "Condor Resources Inc", c: "CA" },
  { id: 7670, s: "APEMY", n: "Aperam PK", c: "US" },
  { id: 7671, s: "601398.SS", n: "Industrial and Commercial Bank of CH", c: "CH" },
  { id: 7672, s: "PUUILO.HE", n: "Puuilo Oyj", c: "Finland" },
  { id: 7673, s: "ORA.AX", n: "Orora", c: "Australia" },
  { id: 7674, s: "LAGR-B.ST", n: "Lagercrantz Group AB (publ)", c: "Sweden" },
  { id: 7675, s: "DOW.AX", n: "Downer Edi", c: "Australia" },
  { id: 7676, s: "ICCT", n: "iCoreConnect Inc. Common stock", c: "US" },
  { id: 7677, s: "GGP.L", n: "Greatland Gold plc", c: "UK" },
  { id: 7678, s: "BION.SW", n: "BB Biotech AG", c: "Switzerland" },
  { id: 7679, s: "CIGI.TO", n: "Colliers International Group Inc Bats", c: "CA" },
  { id: 7680, s: "EMC", n: "Global X Funds", c: "US" },
  { id: 7681, s: "LIS.V", n: "Lithium South Development Co", c: "CA" },
  { id: 7682, s: "STRLNG.ST", n: "Swedish Stirling AB", c: "Sweden" },
  { id: 7683, s: "MMM.AX", n: "Marley Spoon AG", c: "Australia" },
  { id: 7684, s: "ALMY.AT", n: "Alumil Aluminium Industry S.A", c: "Greece" },
  { id: 7685, s: "ENLV.TA", n: "Enlivex Therapeutics", c: "Israel" },
  { id: 7686, s: "LATO-B.ST", n: "Investment AB Latour (publ)", c: "Sweden" },
  { id: 7687, s: "RFX.AX", n: "RedFlow", c: "Australia" },
  { id: 7688, s: "LLC.AX", n: "Lendlease Group", c: "Australia" },
  { id: 7689, s: "GL", n: "Global Links Co", c: "US" },
  { id: 7690, s: "3176.TWO", n: "Medigen Biotechnology", c: "TW" },
  { id: 7691, s: "3293.TWO", n: "International Games System", c: "TW" },
  { id: 7692, s: "300782.SZ", n: "Maxscend Microelectronics", c: "CH" },
  { id: 7693, s: "2409.TW", n: "AU Optronics", c: "TW" },
  { id: 7694, s: "LCFY", n: "Locafy", c: "US" },
  { id: 7695, s: "OLVAS.HE", n: "Olvi Oyj A", c: "Finland" },
  { id: 7696, s: "CTD.AX", n: "Corporate Travel Managment", c: "Australia" },
  { id: 7697, s: "AM.PA", n: "Dassault Aviation SA", c: "FR" },
  { id: 7698, s: "FDEV.L", n: "Frontier Developments Plc", c: "UK" },
  { id: 7699, s: "TIPS", n: "Tianrong Internet Products and Services Inc", c: "US" },
  { id: 7700, s: "VLN.TO", n: "Velan Inc.", c: "CA" },
  { id: 7701, s: "IMA.AX", n: "Image Resources NL", c: "Australia" },
  { id: 7702, s: "JHS", n: "John Hancock Income Securities Closed Fund", c: "US" },
  { id: 7703, s: "ANDR", n: "Andrea Electronic Cp", c: "US" },
  { id: 7704, s: "TBB", n: "AT&T Inc", c: "US" },
  { id: 7705, s: "SWG.WA", n: "Seco/Warwick SA", c: "Poland" },
  { id: 7706, s: "FINMY", n: "Leonardo SpA ADR", c: "US" },
  { id: 7707, s: "ZWACK.BD", n: "Zwack Unicum Likoripari es Kereskedelmi Nyrt.", c: "Hungary" },
  { id: 7708, s: "STGO.TO", n: "Steppe Gold", c: "CA" },
  { id: 7709, s: "1519.TW", n: "Fortune Electric", c: "TW" },
  { id: 7710, s: "002371.SZ", n: "NAURA Technology Group", c: "CH" },
  { id: 7711, s: "TAH.AX", n: "Tabcorp Holdings", c: "Australia" },
  { id: 7712, s: "ICL.AX", n: "Iceni Gold", c: "Australia" },
  { id: 7713, s: "YGR.TO", n: "Yangarra Resources", c: "CA" },
  { id: 7714, s: "URW.AX", n: "Unibail Rodamco Westfield", c: "Australia" },
  { id: 7715, s: "TAVHY", n: "Tav Havalimanlari Holding AS", c: "US" },
  { id: 7716, s: "MOB.ST", n: "Moberg Pharma AB (publ)", c: "Sweden" },
  { id: 7717, s: "SLI.V", n: "Standard Lithium", c: "CA" },
  { id: 7718, s: "BIKE.DE", n: "Bike24 Holding AG", c: "GER" },
  { id: 7719, s: "AMP.AX", n: "AMP", c: "Australia" },
  { id: 7720, s: "ALVMG.PA", n: "Visiomed Group", c: "FR" },
  { id: 7721, s: "603799.SS", n: "Zhejiang Huayou Cobalt", c: "CH" },
  { id: 7722, s: "AEWU.L", n: "AEW UK REIT Plc", c: "UK" },
  { id: 7723, s: "BCGE.SW", n: "Banque Cantonale de Geneve", c: "Switzerland" },
  { id: 7724, s: "VMUK.L", n: "Virgin Money UK PLC", c: "UK" },
  { id: 7725, s: "WATR.L", n: "Water Intelligence plc", c: "UK" },
  { id: 7726, s: "WIX.L", n: "Wickes Group PLC", c: "UK" },
  { id: 7727, s: "ALDRV.PA", n: "Drone Volt SA", c: "FR" },
  { id: 7728, s: "DPLM.L", n: "Diploma PLC", c: "UK" },
  { id: 7729, s: "ISI.TA", n: "Imagesat International ISI", c: "Israel" },
  { id: 7730, s: "SONVY", n: "Sonova Holding AG", c: "US" },
  { id: 7731, s: "EESH", n: "Eestech Inc", c: "US" },
  { id: 7732, s: "3036.TW", n: "WT Microelectronics", c: "TW" },
  { id: 7733, s: "3035.TW", n: "Faraday Technology Co", c: "TW" },
  { id: 7734, s: "ALCLA.PA", n: "Clasquin", c: "FR" },
  { id: 7735, s: "DCTA.L", n: "Directa Plus PLC", c: "UK" },
  { id: 7736, s: "ZYT.L", n: "Zytronic", c: "UK" },
  { id: 7737, s: "FVCB", n: "FVCBankcorp Inc", c: "US" },
  { id: 7738, s: "TREL-B.ST", n: "Trelleborg AB (publ)", c: "Sweden" },
  { id: 7739, s: "GOFPY", n: "Greek Org of Football Prognostics", c: "US" },
  { id: 7740, s: "HKXCY", n: "Hong Kong Exchange & Clearing", c: "US" },
  { id: 7741, s: "SWAG", n: "Software Acquisition Group III Inc", c: "US" },
  { id: 7742, s: "IMG.TO", n: "IAMGold Corporation", c: "CA" },
  { id: 7743, s: "BAFN", n: "Bayfirst Financial Co", c: "US" },
  { id: 7744, s: "CWR.L", n: "Ceres Power Holdings PLC", c: "UK" },
  { id: 7745, s: "CPI.L", n: "Capita PLC", c: "UK" },
  { id: 7746, s: "6257.TW", n: "Sigurd Microelectronics Co", c: "TW" },
  { id: 7747, s: "ALTA.PA", n: "Altarea SCA", c: "FR" },
  { id: 7748, s: "NHOA.PA", n: "Nhoa SA", c: "FR" },
  { id: 7749, s: "024110.KS", n: "Industrial Bank Of Korea", c: "Korea" },
  { id: 7750, s: "CGC.AX", n: "Costa Group Holdings", c: "Australia" },
  { id: 7751, s: "A3M.MC", n: "Atresmedia Corporación de Medios de Comunicación S.A.", c: "Spain" },
  { id: 7752, s: "N91.L", n: "Ninety One PLC", c: "UK" },
  { id: 7753, s: "ESBA", n: "Empire State Realty OP LP ES", c: "US" },
  { id: 7754, s: "NSS", n: "NuStar Logistics 7.625% Fixed To Floating Rate Subordinated Notes Exp 15 Jan 2043", c: "US" },
  { id: 7755, s: "3227.TWO", n: "PixArt Imaging", c: "TW" },
  { id: 7756, s: "RICOY", n: "Ricoh Company", c: "US" },
  { id: 7757, s: "GHIX", n: "Gores Holdings IX Inc", c: "US" },
  { id: 7758, s: "BORK", n: "Bourque Indts Inc", c: "US" },
  { id: 7759, s: "KEL.TO", n: "Kelt Exploration.", c: "CA" },
  { id: 7760, s: "RZB", n: "Reinsurance Group of America Inc", c: "US" },
  { id: 7761, s: "ALMDG.PA", n: "MGI Digital", c: "FR" },
  { id: 7762, s: "601919.SS", n: "COSCO SHIPPING Holdings", c: "CH" },
  { id: 7763, s: "2885.TW", n: "Yuanta Financial Holdings", c: "TW" },
  { id: 7764, s: "601225.SS", n: "Shaanxi Coal Industry", c: "CH" },
  { id: 7765, s: "AFG.OL", n: "AF Gruppen ASA", c: "Norway" },
  { id: 7766, s: "PARB.OL", n: "Pareto Bank ASA", c: "Norway" },
  { id: 7767, s: "KNOS.L", n: "Kainos Group PLC", c: "UK" },
  { id: 7768, s: "JDG.L", n: "Judges Scientific Plc", c: "UK" },
  { id: 7769, s: "BVXP.L", n: "Bioventix", c: "UK" },
  { id: 7770, s: "MGA.TO", n: "Mega Uranium.", c: "CA" },
  { id: 7771, s: "NHE.AX", n: "Noble Helium", c: "Australia" },
  { id: 7772, s: "OIZ.IR", n: "Origin Enterprises Plc", c: "Ireland" },
  { id: 7773, s: "PREC.ST", n: "Precise Biometrics AB", c: "Sweden" },
  { id: 7774, s: "SDVKY", n: "Sandvik AB ADR", c: "US" },
  { id: 7775, s: "000001.SZ", n: "Ping An Bank", c: "CH" },
  { id: 7776, s: "BLLB", n: "Bell Buckle Holdings", c: "US" },
  { id: 7777, s: "HTWS.L", n: "Helios Towers Plc", c: "UK" },
  { id: 7778, s: "HUNT.OL", n: "Hunter Group ASA", c: "Norway" },
  { id: 7779, s: "GRSL.V", n: "GR Silver Mining", c: "CA" },
  { id: 7780, s: "VVV3.DE", n: "ÖKOWORLD AG", c: "GER" },
  { id: 7781, s: "HYPE3.SA", n: "Hypera S.A", c: "Brazil" },
  { id: 7782, s: "300223.SZ", n: "Ingenic Semiconductor", c: "CH" },
  { id: 7783, s: "NEOM", n: "NeoMedia Technologies Inc", c: "US" },
  { id: 7784, s: "KWBT", n: "Kiwa Bio-Tech Products Group Co", c: "US" },
  { id: 7785, s: "PEI.V", n: "Prospera Energy Inc", c: "CA" },
  { id: 7786, s: "NINOY", n: "Nikon Co", c: "US" },
  { id: 7787, s: "KRI.WA", n: "Kredyt Inkaso SA", c: "Poland" },
  { id: 7788, s: "TOUP.PA", n: "Touax SCA", c: "FR" },
  { id: 7789, s: "AZJ.AX", n: "Aurizon Holdings", c: "Australia" },
  { id: 7790, s: "ZUR.TA", n: "Zur Shamir Holdings", c: "Israel" },
  { id: 7791, s: "TD-PFK.TO", n: "Toronto-Dominion Bank Pref Series 20", c: "CA" },
  { id: 7792, s: "UNMA", n: "Unum Group", c: "US" },
  { id: 7793, s: "ETG.DE", n: "EnviTec Biogas AG", c: "GER" },
  { id: 7794, s: "EKOP.BR", n: "Ekopak BV", c: "Belgium" },
  { id: 7795, s: "LIO.L", n: "Liontrust Asset Management", c: "UK" },
  { id: 7796, s: "1326.TW", n: "Formosa Chemicals & Fibre Co", c: "TW" },
  { id: 7797, s: "NETC", n: "Nabors Energy Transition Co", c: "US" },
  { id: 7798, s: "015760.KS", n: "Korea Electric Power Co", c: "Korea" },
  { id: 7799, s: "ODET.PA", n: "Compagnie de l'Odet", c: "FR" },
  { id: 7800, s: "GNFT.PA", n: "Genfit", c: "FR" },
  { id: 7801, s: "ALGAU.PA", n: "Gaussin", c: "FR" },
  { id: 7802, s: "FALC", n: "FalconStor Software Inc", c: "US" },
  { id: 7803, s: "FO.V", n: "Falcon Oil & Gas.", c: "CA" },
  { id: 7804, s: "DAN.V", n: "Arianne Phosphate Inc", c: "CA" },
  { id: 7805, s: "ATC.WA", n: "Arctic Paper SA", c: "Poland" },
  { id: 7806, s: "MPNGY", n: "Meituan ADR", c: "US" },
  { id: 7807, s: "GBT.PA", n: "Guerbet S. A.", c: "FR" },
  { id: 7808, s: "EMR.AX", n: "Emerald Resources NL", c: "Australia" },
  { id: 7809, s: "MBB.DE", n: "MBB SE", c: "GER" },
  { id: 7810, s: "MOZN.SW", n: "mobilezone ag", c: "Switzerland" },
  { id: 7811, s: "NWHUF", n: "Northwest Healthcare Properties REIT", c: "US" },
  { id: 7812, s: "KLBAY", n: "Klabin Sa A", c: "US" },
  { id: 7813, s: "WHA.AS", n: "Wereldhave NV", c: "Netherlands" },
  { id: 7814, s: "AGESY", n: "ageas SA/NV", c: "US" },
  { id: 7815, s: "RSLV.V", n: "Reyna Silver Co", c: "CA" },
  { id: 7816, s: "SUR.DE", n: "Surteco Group SE", c: "GER" },
  { id: 7817, s: "ELLO", n: "Ellomay Capital", c: "US" },
  { id: 7818, s: "ALIF-B.ST", n: "AddLife AB (publ)", c: "Sweden" },
  { id: 7819, s: "LBC.V", n: "Libero Copper Co", c: "CA" },
  { id: 7820, s: "RO.SW", n: "Roche Holding AG", c: "Switzerland" },
  { id: 7821, s: "088800.KQ", n: "Ace Technologies Co", c: "Korea" },
  { id: 7822, s: "AXM.V", n: "AXMIN Inc.", c: "CA" },
  { id: 7823, s: "600389.SS", n: "Nantong Jiangshan Agrochemical & Chemicals", c: "CH" },
  { id: 7824, s: "SGKN.SW", n: "St Galler Kantonalbank AG", c: "Switzerland" },
  { id: 7825, s: "SFSN.SW", n: "SFS Group AG", c: "Switzerland" },
  { id: 7826, s: "SENS.SW", n: "Sensirion Holding AG", c: "Switzerland" },
  { id: 7827, s: "MCP.AX", n: "Mcpherson'S", c: "Australia" },
  { id: 7828, s: "ARB.L", n: "Argo Blockchain PLC", c: "UK" },
  { id: 7829, s: "LNDNF", n: "Lundin Energy AB", c: "US" },
  { id: 7830, s: "BYD.TO", n: "Boyd Group Services Inc.", c: "CA" },
  { id: 7831, s: "SRMX", n: "Saddle Ranch Media Inc", c: "US" },
  { id: 7832, s: "RKN.AX", n: "Reckon", c: "Australia" },
  { id: 7833, s: "BRKM3.SA", n: "Braskem S.A", c: "Brazil" },
  { id: 7834, s: "003240.KS", n: "Taekwang Ind", c: "Korea" },
  { id: 7835, s: "3189.TW", n: "Kinsus Interconnect Technology Co", c: "TW" },
  { id: 7836, s: "GGI.V", n: "Garibaldi Resources Corp.", c: "CA" },
  { id: 7837, s: "SUN.SW", n: "Sulzer AG", c: "Switzerland" },
  { id: 7838, s: "INTK", n: "Industrial Nanotech", c: "US" },
  { id: 7839, s: "688390.SS", n: "Jiangsu Goodwe Power Supply Technology", c: "CH" },
  { id: 7840, s: "IDCBY", n: "Industrial Commercial Bank of CH", c: "US" },
  { id: 7841, s: "GNS.L", n: "Genus PLC", c: "UK" },
  { id: 7842, s: "THG.L", n: "THG Holdings PLC", c: "UK" },
  { id: 7843, s: "VIVHY", n: "Vivendi SA PK", c: "US" },
  { id: 7844, s: "ALDOL.PA", n: "Dolfines SAS", c: "FR" },
  { id: 7845, s: "LAM.TO", n: "Laramide Resources.", c: "CA" },
  { id: 7846, s: "ASA.OL", n: "Atlantic Sapphire As", c: "Norway" },
  { id: 7847, s: "BBUC", n: "Brookfield Business Co", c: "US" },
  { id: 7848, s: "REX.AX", n: "Regional Express Holdings", c: "Australia" },
  { id: 7849, s: "CCM", n: "Concord Medical Services Holdings", c: "US" },
  { id: 7850, s: "LGGNY", n: "Legal & General Group Plc", c: "US" },
  { id: 7851, s: "INDERES.HE", n: "Inderes Oyj", c: "Finland" },
  { id: 7852, s: "BELCO.OL", n: "Belships", c: "Norway" },
  { id: 7853, s: "TTRAK.IS", n: "Turk Traktor ve Ziraat Makineleri AS", c: "Turkey" },
  { id: 7854, s: "ATLKY", n: "Atlas Copco AB", c: "US" },
  { id: 7855, s: "LOOMIS.ST", n: "Loomis AB ser. B", c: "Sweden" },
  { id: 7856, s: "HCXY", n: "Hercules Capital Inc", c: "US" },
  { id: 7857, s: "NOTE.ST", n: "NOTE AB", c: "Sweden" },
  { id: 7858, s: "SAPR3.SA", n: "Companhia de Saneamento do Paraná - SANEPAR", c: "Brazil" },
  { id: 7859, s: "FTAIO", n: "Fortress Transportation and Preferred Series B", c: "US" },
  { id: 7860, s: "ITHUF", n: "iAnthus Capital Holdings Inc", c: "US" },
  { id: 7861, s: "SXI.TO", n: "Synex International Inc.", c: "CA" },
  { id: 7862, s: "000617.SZ", n: "CNPC Capital", c: "CH" },
  { id: 7863, s: "SMCE", n: "SMC Entertainment Inc", c: "US" },
  { id: 7864, s: "DCM.TO", n: "Data Communications Management", c: "CA" },
  { id: 7865, s: "TEA.V", n: "Tearlach Resources", c: "CA" },
  { id: 7866, s: "ENENTO.HE", n: "Enento Group Plc", c: "Finland" },
  { id: 7867, s: "PEUG.PA", n: "Peugeot Invest SA", c: "FR" },
  { id: 7868, s: "DDT", n: "Dillards Capital Trust I", c: "US" },
  { id: 7869, s: "PAPIL.IS", n: "Papilon Savunma Guvenlik Sistemleri", c: "Turkey" },
  { id: 7870, s: "SNEJF", n: "Sony Co", c: "US" },
  { id: 7871, s: "005935.KS", n: "Samsung Electronics Co Pref", c: "Korea" },
  { id: 7872, s: "ING.AX", n: "Inghams Group", c: "Australia" },
  { id: 7873, s: "TK.V", n: "Tinka Resources Limited", c: "CA" },
  { id: 7874, s: "PIERCE.ST", n: "Pierce Group AB", c: "Sweden" },
  { id: 7875, s: "IVC.AX", n: "Invocare", c: "Australia" },
  { id: 7876, s: "WHR.L", n: "Warehouse REIT plc", c: "UK" },
  { id: 7877, s: "2474.TW", n: "Catcher Technology", c: "TW" },
  { id: 7878, s: "HALKB.IS", n: "Turkiye Halk Bankasi", c: "Turkey" },
  { id: 7879, s: "PDI.AX", n: "Predictive Discovery", c: "Australia" },
  { id: 7880, s: "CENER.BR", n: "Cenergy Holdings SA", c: "Belgium" },
  { id: 7881, s: "6220.TWO", n: "YFC-BonEagle Electric", c: "TW" },
  { id: 7882, s: "INDV.L", n: "Indivior PLC", c: "UK" },
  { id: 7883, s: "ELIOR.PA", n: "Elior SCA", c: "FR" },
  { id: 7884, s: "BVS.AX", n: "Bravura Solutions", c: "Australia" },
  { id: 7885, s: "MGIC.TA", n: "Magic Software Enterprises", c: "Israel" },
  { id: 7886, s: "SDIP-B.ST", n: "Sdiptech AB (publ)", c: "Sweden" },
  { id: 7887, s: "PAGE.L", n: "Pagegroup PLC", c: "UK" },
  { id: 7888, s: "PNP.TO", n: "Pinetree Capital", c: "CA" },
  { id: 7889, s: "PTBL3.SA", n: "PBG S.A", c: "Brazil" },
  { id: 7890, s: "601099.SS", n: "Pacific Securities", c: "CH" },
  { id: 7891, s: "002714.SZ", n: "Muyuan Foodstuff", c: "CH" },
  { id: 7892, s: "SIFG.AS", n: "Sif Holding NV", c: "Netherlands" },
  { id: 7893, s: "STOCKA.HE", n: "Stockmann Oyj Abp B", c: "Finland" },
  { id: 7894, s: "HYDRA.AS", n: "Hydratec Industries NV", c: "Netherlands" },
  { id: 7895, s: "RET.BR", n: "Retail Estates - Sicafi", c: "Belgium" },
  { id: 7896, s: "SISI", n: "Shineco Inc", c: "US" },
  { id: 7897, s: "CEL.AX", n: "Challenger Gold Limited", c: "Australia" },
  { id: 7898, s: "KNI.AX", n: "Kuniko", c: "Australia" },
  { id: 7899, s: "RECT.BR", n: "Recticel", c: "Belgium" },
  { id: 7900, s: "ERO.TO", n: "Ero Copper Co", c: "CA" },
  { id: 7901, s: "RTO.L", n: "Rentokil Initial PLC", c: "UK" },
  { id: 7902, s: "LAU.AX", n: "Lindsay Australia", c: "Australia" },
  { id: 7903, s: "000150.KS", n: "Doosan", c: "Korea" },
  { id: 7904, s: "VASTN.AS", n: "Vastned Retail NV", c: "Netherlands" },
  { id: 7905, s: "OLT.OL", n: "Olav Thon Eien", c: "Norway" },
  { id: 7906, s: "CBG.L", n: "Close Brothers Group plc", c: "UK" },
  { id: 7907, s: "ESNT.L", n: "Essentra PLC", c: "UK" },
  { id: 7908, s: "CZBS", n: "Citizens Bancshares Co", c: "US" },
  { id: 7909, s: "VLX.L", n: "Volex Plc", c: "UK" },
  { id: 7910, s: "LPEN.TO", n: "Loop Energy Inc", c: "CA" },
  { id: 7911, s: "ANTE", n: "Airnet Technology Inc", c: "US" },
  { id: 7912, s: "VCT.L", n: "Victrex plc", c: "UK" },
  { id: 7913, s: "VANQ.L", n: "Vanquis Banking Group PLC", c: "UK" },
  { id: 7914, s: "GICSAB.MX", n: "Grupo Gicsa S.A. de C.V", c: "Mexi" },
  { id: 7915, s: "CLOUD.OL", n: "Cloudberry Clean Energy As", c: "Norway" },
  { id: 7916, s: "ELET3.SA", n: "Centrais Elétricas Brasileiras S.A. - Eletrobrás", c: "Brazil" },
  { id: 7917, s: "DR.TO", n: "Medical Facilities Corporation", c: "CA" },
  { id: 7918, s: "CTY.L", n: "City Of London Investment Trust", c: "UK" },
  { id: 7919, s: "APLP.TA", n: "Apollo Power", c: "Israel" },
  { id: 7920, s: "DRT.TO", n: "Dirtt Environmen", c: "CA" },
  { id: 7921, s: "MPIR", n: "Empire Diversified Energy Inc", c: "US" },
  { id: 7922, s: "VKI", n: "Invesco Advantage MIT II", c: "US" },
  { id: 7923, s: "APS.TO", n: "Aptose Biosciences Inc", c: "CA" },
  { id: 7924, s: "GIMB.BR", n: "GIMV NV", c: "Belgium" },
  { id: 7925, s: "FSX.V", n: "Fosterville South Exploration", c: "CA" },
  { id: 7926, s: "WINR", n: "Simplicity Esports and Gaming", c: "US" },
  { id: 7927, s: "HICL.L", n: "HICL Infrastructure Company", c: "UK" },
  { id: 7928, s: "ZIGN.ST", n: "Zignsec AB", c: "Sweden" },
  { id: 7929, s: "C0M.F", n: "Compleo Charging Solutions AG", c: "GER" },
  { id: 7930, s: "ENLT.TA", n: "Enlight Renewable Energy", c: "Israel" },
  { id: 7931, s: "BIRG.L", n: "Bank of Ireland Group PLC", c: "UK" },
  { id: 7932, s: "600990.SS", n: "Anhui Sun Create Electronics", c: "CH" },
  { id: 7933, s: "VRLA.PA", n: "Verallia", c: "FR" },
  { id: 7934, s: "NXC", n: "Nuveen California Select Tax Free Income Closed Fund", c: "US" },
  { id: 7935, s: "BXBLY", n: "Brambles ADR", c: "US" },
  { id: 7936, s: "CWY.AX", n: "Cleanaway Waste Management", c: "Australia" },
  { id: 7937, s: "MVNT", n: "Visual Healthcare Co", c: "US" },
  { id: 7938, s: "TUPY3.SA", n: "Tupy S.A", c: "Brazil" },
  { id: 7939, s: "601288.SS", n: "Agricultural Bank of CH Class A", c: "CH" },
  { id: 7940, s: "AGLX.OL", n: "Agilyx AS", c: "Norway" },
  { id: 7941, s: "000830.SZ", n: "Luxi Chemical Group", c: "CH" },
  { id: 7942, s: "RADL3.SA", n: "Raia Drogasil S.A", c: "Brazil" },
  { id: 7943, s: "ASCN.SW", n: "Ascom Holding AG", c: "Switzerland" },
  { id: 7944, s: "2301.TW", n: "Lite-On Technology Co", c: "TW" },
  { id: 7945, s: "SZZL", n: "Sizzle Acquisition Co", c: "US" },
  { id: 7946, s: "KIE.L", n: "Kier Group PLC", c: "UK" },
  { id: 7947, s: "KHNGY", n: "Kuehne & Nagel International AG ADR", c: "US" },
  { id: 7948, s: "SITOWS.HE", n: "Sitowise Group Oyj", c: "Finland" },
  { id: 7949, s: "FCR-UN.TO", n: "First Capital Real Estate Investment Trust", c: "CA" },
  { id: 7950, s: "TLGN", n: "Totally Green Inc", c: "US" },
  { id: 7951, s: "KELYB", n: "Kelly Services B Inc", c: "US" },
  { id: 7952, s: "CXH", n: "MFS Investment Grade Municipal Trust", c: "US" },
  { id: 7953, s: "HCH.AX", n: "Hot Chili", c: "Australia" },
  { id: 7954, s: "KIROY", n: "Kumba Iron Ore PK", c: "US" },
  { id: 7955, s: "SUBC.OL", n: "Subsea 7 S.A.", c: "Norway" },
  { id: 7956, s: "600460.SS", n: "Hangzhou Silan Microelectronics", c: "CH" },
  { id: 7957, s: "PHLL.L", n: "Petershill Partners PLC", c: "UK" },
  { id: 7958, s: "VAIAS.HE", n: "Vaisala Oyj A", c: "Finland" },
  { id: 7959, s: "PIPE.TO", n: "Pipestone Energy Co", c: "CA" },
  { id: 7960, s: "002340.SZ", n: "GEM", c: "CH" },
  { id: 7961, s: "DOTD.L", n: "Dotdigital Group Plc", c: "UK" },
  { id: 7962, s: "SMMYY", n: "Sumitomo Metal Mining Co ADR", c: "US" },
  { id: 7963, s: "MSON-B.ST", n: "Midsona AB (publ)", c: "Sweden" },
  { id: 7964, s: "MTS.MC", n: "ArcelorMittal SA", c: "Spain" },
  { id: 7965, s: "FF.TO", n: "First Mining Gold Co", c: "CA" },
  { id: 7966, s: "MYX.AX", n: "Mayne Pharma Group", c: "Australia" },
  { id: 7967, s: "UZU.DE", n: "Uzin Utz AG", c: "GER" },
  { id: 7968, s: "KDDIY", n: "KDDI Corp PK", c: "US" },
  { id: 7969, s: "BNB.BR", n: "Banque nationale de Belgique Société anonyme", c: "Belgium" },
  { id: 7970, s: "MTNOY", n: "MTN Group PK", c: "US" },
  { id: 7971, s: "BSK.V", n: "Blue Sky Uranium Co", c: "CA" },
  { id: 7972, s: "SHVA.TA", n: "Automatic Bank Services", c: "Israel" },
  { id: 7973, s: "VINO.L", n: "Virgin Wines UK PLC", c: "UK" },
  { id: 7974, s: "JSM", n: "Navient Corporation SR NT 6% 121543", c: "US" },
  { id: 7975, s: "EMR.L", n: "Empresaria Group plc", c: "UK" },
  { id: 7976, s: "THNPY", n: "Technip Energies NV ADR", c: "US" },
  { id: 7977, s: "BCHN.SW", n: "Burckhardt Compression", c: "Switzerland" },
  { id: 7978, s: "PSLV.TO", n: "Sprott Physical Silver", c: "CA" },
  { id: 7979, s: "ALDE.V", n: "Aldebaran Resources Inc", c: "CA" },
  { id: 7980, s: "MYCR.ST", n: "Mycronic publ AB", c: "Sweden" },
  { id: 7981, s: "2535.TW", n: "Da-Cin Construction", c: "TW" },
  { id: 7982, s: "IXHL", n: "Incannex Healthcare ADR", c: "US" },
  { id: 7983, s: "NSI.AS", n: "NSI NV", c: "Netherlands" },
  { id: 7984, s: "GSEO.L", n: "VH Global Sustainable Energy Opportunities PLC", c: "UK" },
  { id: 7985, s: "TBC", n: "AT&T Inc ELKS", c: "US" },
  { id: 7986, s: "ELWS.TA", n: "Electreon Wireless", c: "Israel" },
  { id: 7987, s: "WILK.TA", n: "Wilk Technologies", c: "Israel" },
  { id: 7988, s: "GMD.AX", n: "Genesis Minerals", c: "Australia" },
  { id: 7989, s: "ESUN.SW", n: "Edisun Power Europe AG", c: "Switzerland" },
  { id: 7990, s: "HOLM-B.ST", n: "Holmen AB (publ)", c: "Sweden" },
  { id: 7991, s: "ALKAL.PA", n: "Kalray SA", c: "FR" },
  { id: 7992, s: "ATG.L", n: "Auction Technology Group PLC", c: "UK" },
  { id: 7993, s: "CYRE3.SA", n: "Cyrela Brazil Realty S.A. Empreendimentos e Participações", c: "Brazil" },
  { id: 7994, s: "FHZN.SW", n: "Flughafen Zurich", c: "Switzerland" },
  { id: 7995, s: "MHUB.V", n: "Minehub Technologies Inc", c: "CA" },
  { id: 7996, s: "VINE", n: "Fresh Grapes LLC", c: "US" },
  { id: 7997, s: "IHP.L", n: "IntegraFin Holdings plc", c: "UK" },
  { id: 7998, s: "OPHC", n: "OptimumBank Holdings Inc", c: "US" },
  { id: 7999, s: "SQD.V", n: "SQI Diagnostics Inc", c: "CA" },
  { id: 8000, s: "CGI.TO", n: "Canadian General Investments", c: "CA" },
  { id: 8001, s: "CDRO", n: "Codere Online US Co", c: "US" },
  { id: 8002, s: "GREEN.BR", n: "Greenyard NV", c: "Belgium" },
  { id: 8003, s: "ASTL.TO", n: "Algoma Steel Group Inc", c: "CA" },
  { id: 8004, s: "6274.TWO", n: "Taiwan Union Technology", c: "TW" },
  { id: 8005, s: "IXU.AX", n: "IXUP", c: "Australia" },
  { id: 8006, s: "GCG.TO", n: "Guardian Capital Group Limited", c: "CA" },
  { id: 8007, s: "BRFS3.SA", n: "BRF S.A", c: "Brazil" },
  { id: 8008, s: "CCVI", n: "Churchill Capital VI Co", c: "US" },
  { id: 8009, s: "ROSN", n: "Rosinbomb", c: "US" },
  { id: 8010, s: "CIEL3.SA", n: "Cielo S.A", c: "Brazil" },
  { id: 8011, s: "NEOBO.ST", n: "NEOBO FASTIGHETER", c: "Sweden" },
  { id: 8012, s: "MTS.AX", n: "Metcash", c: "Australia" },
  { id: 8013, s: "NBVA.V", n: "Nubeva Technologies", c: "CA" },
  { id: 8014, s: "028260.KS", n: "Samsung C&T Co", c: "Korea" },
  { id: 8015, s: "NCI.V", n: "NTG Clarity Networks Inc.", c: "CA" },
  { id: 8016, s: "8454.TW", n: "momo.com Inc", c: "TW" },
  { id: 8017, s: "BIG.PA", n: "BigBen Interactive", c: "FR" },
  { id: 8018, s: "KOZAL.IS", n: "Koza Altin Isletmeleri AS", c: "Turkey" },
  { id: 8019, s: "GFT.DE", n: "GFT Technologies SE", c: "GER" },
  { id: 8020, s: "300244.SZ", n: "Zhejiang DiAn Diagnostics", c: "CH" },
  { id: 8021, s: "UPR.IR", n: "Uniphar Group PLC", c: "Ireland" },
  { id: 8022, s: "WCU.V", n: "World Copper", c: "CA" },
  { id: 8023, s: "TOT.TO", n: "Total Energy Services Inc.", c: "CA" },
  { id: 8024, s: "LDR.AX", n: "Lode Resources", c: "Australia" },
  { id: 8025, s: "601968.SS", n: "Shanghai Baosteel Packaging", c: "CH" },
  { id: 8026, s: "IPH.PA", n: "Innate Pharma", c: "FR" },
  { id: 8027, s: "ASM.AX", n: "Australian Strategic Materials Ltd", c: "Australia" },
  { id: 8028, s: "HNDAF", n: "Honda Motor", c: "US" },
  { id: 8029, s: "YRIV", n: "Yangtze River Port and Logistics", c: "US" },
  { id: 8030, s: "CHG.L", n: "Chemring Group PLC", c: "UK" },
  { id: 8031, s: "AGI.TO", n: "Alamos Gold Inc", c: "CA" },
  { id: 8032, s: "WAM.AX", n: "Wam Capital", c: "Australia" },
  { id: 8033, s: "MRVE3.SA", n: "MRV Engenharia e Participações S.A", c: "Brazil" },
  { id: 8034, s: "TKOMY", n: "Tokio Marine Holdings Inc", c: "US" },
  { id: 8035, s: "WEX.V", n: "Western Exploration Inc", c: "CA" },
  { id: 8036, s: "FCELB", n: "FuelCell Energy Inc Pref", c: "US" },
  { id: 8037, s: "CAML.L", n: "Central Asia Metals Plc", c: "UK" },
  { id: 8038, s: "PE.V", n: "Pure Energy Minerals", c: "CA" },
  { id: 8039, s: "BRAXF", n: "Braxia Scientific Co", c: "US" },
  { id: 8040, s: "ELF.TO", n: "E-L Financial Co", c: "CA" },
  { id: 8041, s: "2362.TW", n: "Clevo", c: "TW" },
  { id: 8042, s: "FORA.TO", n: "VerticalScope Holdings Inc", c: "CA" },
  { id: 8043, s: "PLAG", n: "Planet Green Holdings Co", c: "US" },
  { id: 8044, s: "NXL.AX", n: "Nuix Ltd", c: "Australia" },
  { id: 8045, s: "FLXT", n: "Flexpoint Sensor Systems Inc", c: "US" },
  { id: 8046, s: "RDDTF", n: "Radient Technologies Inc", c: "US" },
  { id: 8047, s: "BVIC.L", n: "Britvic PLC", c: "UK" },
  { id: 8048, s: "FIND.V", n: "Baselode Energy Co", c: "CA" },
  { id: 8049, s: "BYW6.DE", n: "BayWa AktienGesellschaft Vink.N", c: "GER" },
  { id: 8050, s: "FGP.L", n: "FirstGroup PLC", c: "UK" },
  { id: 8051, s: "3024.TW", n: "Action Electronics", c: "TW" },
  { id: 8052, s: "HAS.AX", n: "Hastings Rare Metals", c: "Australia" },
  { id: 8053, s: "NRR.L", n: "NewRiver REIT plc", c: "UK" },
  { id: 8054, s: "KIQ", n: "Kelso Technologies Inc", c: "US" },
  { id: 8055, s: "002407.SZ", n: "Do-Fluoride Chemicals", c: "CH" },
  { id: 8056, s: "ALTRI.PA", n: "Trilogiq", c: "FR" },
  { id: 8057, s: "3596.TW", n: "Arcadyan Technology Co", c: "TW" },
  { id: 8058, s: "VUK.AX", n: "Virgin Money PLC", c: "Australia" },
  { id: 8059, s: "YASKY", n: "Yaskawa Electric Co", c: "US" },
  { id: 8060, s: "RATO-B.ST", n: "Ratos AB (publ)", c: "Sweden" },
  { id: 8061, s: "USCB", n: "US Century Bank", c: "US" },
  { id: 8062, s: "WTE.TO", n: "Westshore Terminals Investment Co", c: "CA" },
  { id: 8063, s: "300308.SZ", n: "Shandong Zhongji Electrical Equipment", c: "CH" },
  { id: 8064, s: "RBA.TO", n: "Ritchie Bros Auctioneers", c: "CA" },
  { id: 8065, s: "ALYA.TO", n: "Alithya Group inc Class A", c: "CA" },
  { id: 8066, s: "601816.SS", n: "Beijing-Shanghai High Speed Railway", c: "CH" },
  { id: 8067, s: "CFEB.BR", n: "Compagnie d Entreprises CFE SA", c: "Belgium" },
  { id: 8068, s: "THEP.PA", n: "Thermador Groupe SA", c: "FR" },
  { id: 8069, s: "BONG.ST", n: "Bong AB (publ)", c: "Sweden" },
  { id: 8070, s: "WDO.TO", n: "Wesdome Gold Mines.", c: "CA" },
  { id: 8071, s: "INQD", n: "Indoor Harvest Co", c: "US" },
  { id: 8072, s: "251270.KS", n: "Netmarble Games Co", c: "Korea" },
  { id: 8073, s: "CER.L", n: "Cerillion PLC", c: "UK" },
  { id: 8074, s: "SBB-D.ST", n: "Samhaellsbyggnadsbolaget i Norden AB Series D", c: "Sweden" },
  { id: 8075, s: "JLG.AX", n: "Johns Lyng Group", c: "Australia" },
  { id: 8076, s: "GTR.AX", n: "GTI Resources", c: "Australia" },
  { id: 8077, s: "VABK", n: "Virginia National Bankshares Co", c: "US" },
  { id: 8078, s: "LHYFE.PA", n: "Lhyfe SA", c: "FR" },
  { id: 8079, s: "MNGG", n: "Mining Global Inc", c: "US" },
  { id: 8080, s: "INR.AX", n: "ioneer", c: "Australia" },
  { id: 8081, s: "CMSA", n: "CMS Energy Co", c: "US" },
  { id: 8082, s: "STRINV.", n: "Strategic Investments A/S", c: "Denmark" },
  { id: 8083, s: "ORHB", n: "ORHub Inc", c: "US" },
  { id: 8084, s: "MEKKO.HE", n: "Marimekko Oyj", c: "Finland" },
  { id: 8085, s: "3045.TW", n: "Taiwan Mobile", c: "TW" },
  { id: 8086, s: "603501.SS", n: "Will Semiconductor", c: "CH" },
  { id: 8087, s: "LUMEN.ST", n: "LumenRadio AB", c: "Sweden" },
  { id: 8088, s: "DSCV.L", n: "Discoverie Group PLC", c: "UK" },
  { id: 8089, s: "HLE.DE", n: "Hella KGaA Hueck &", c: "GER" },
  { id: 8090, s: "MMAG.L", n: "musicMagpie PLC", c: "UK" },
  { id: 8091, s: "SLCE3.SA", n: "SLC Agrícola S.A", c: "Brazil" },
  { id: 8092, s: "SAFRY", n: "Safran SA", c: "US" },
  { id: 8093, s: "AGD.AX", n: "Austral Gold", c: "Australia" },
  { id: 8094, s: "AACTF", n: "Aurora Solar Technologies Inc", c: "US" },
  { id: 8095, s: "HAW.DE", n: "Hawesko Holding AG", c: "GER" },
  { id: 8096, s: "BFK", n: "BlackRock Municipal Income Closed Fund", c: "US" },
  { id: 8097, s: "JPXGY", n: "Japan Exchange Group Inc ADR", c: "US" },
  { id: 8098, s: "TVE", n: "Tennessee Valley Authority", c: "US" },
  { id: 8099, s: "ELEF.TO", n: "Silver Elephant Mining Co", c: "CA" },
  { id: 8100, s: "LGD.TO", n: "Liberty Gold Co", c: "CA" },
  { id: 8101, s: "CSTR.V", n: "CryptoStar Co", c: "CA" },
  { id: 8102, s: "LUND-B.ST", n: "L E Lundbergföretagen AB (publ)", c: "Sweden" },
  { id: 8103, s: "ACRL", n: "Atacama Resources International Inc", c: "US" },
  { id: 8104, s: "VOS.DE", n: "Vossloh AG", c: "GER" },
  { id: 8105, s: "MING.OL", n: "Sparebank 1 SMN", c: "Norway" },
  { id: 8106, s: "CYBN.NE", n: "Cybin Inc", c: "CA" },
  { id: 8107, s: "VAKBN.IS", n: "Turkiye Vakiflar Bankasi TAO", c: "Turkey" },
  { id: 8108, s: "COB.AX", n: "Cobalt Blue Holdings", c: "Australia" },
  { id: 8109, s: "GOLD.TO", n: "GoldMining Inc", c: "CA" },
  { id: 8110, s: "OPDE.MC", n: "Opdenergy Holding SA", c: "Spain" },
  { id: 8111, s: "GRST", n: "Ethema Health Co", c: "US" },
  { id: 8112, s: "HEIM-PREF.ST", n: "Heimstaden AB Pfd.", c: "Sweden" },
  { id: 8113, s: "TBCP", n: "Thunder Bridge Capital Partners III Inc", c: "US" },
  { id: 8114, s: "MEKO.ST", n: "Mekonomen AB", c: "Sweden" },
  { id: 8115, s: "GLG", n: "TD Holdings Inc", c: "US" },
  { id: 8116, s: "034220.KS", n: "LG Display", c: "Korea" },
  { id: 8117, s: "SHMDF", n: "Shimano Inc", c: "US" },
  { id: 8118, s: "SPPJY", n: "Sappi ADR", c: "US" },
  { id: 8119, s: "688032.SS", n: "Hoymiles Power Electronics Inc", c: "CH" },
  { id: 8120, s: "2883.TW", n: "CH Development Financial Holding Co", c: "TW" },
  { id: 8121, s: "ALK.AX", n: "Alkane Resources", c: "Australia" },
  { id: 8122, s: "BLV.L", n: "Belvoir Group PLC", c: "UK" },
  { id: 8123, s: "PRU.AX", n: "Perseus Mining", c: "Australia" },
  { id: 8124, s: "REH.AX", n: "Reece", c: "Australia" },
  { id: 8125, s: "CAHPF", n: "Evolution Mining", c: "US" },
  { id: 8126, s: "SOW.DE", n: "Software Aktiengesellschaft", c: "GER" },
  { id: 8127, s: "GRE.MC", n: "Grenergy Renovables S.A", c: "Spain" },
  { id: 8128, s: "SJJ.DE", n: "Serviceware SE", c: "GER" },
  { id: 8129, s: "GMZP", n: "Gemz Co", c: "US" },
  { id: 8130, s: "VUX.V", n: "Vital Energy Inc", c: "CA" },
  { id: 8131, s: "5289.TWO", n: "InnoDisk", c: "TW" },
  { id: 8132, s: "PAN.AX", n: "Panoramic Resources", c: "Australia" },
  { id: 8133, s: "086980.KQ", n: "Showbox Co", c: "Korea" },
  { id: 8134, s: "TIGO-SDB.ST", n: "Millicom International Cellular S.A", c: "Sweden" },
  { id: 8135, s: "PBYA", n: "Probility Media Co", c: "US" },
  { id: 8136, s: "VERY", n: "Vericity Inc", c: "US" },
  { id: 8137, s: "PHCG", n: "Pure Harvest Cannabis Group Inc", c: "US" },
  { id: 8138, s: "TCELL.IS", n: "Turkcell Iletisim Hizmetleri AS ORD", c: "Turkey" },
  { id: 8139, s: "AZS.AX", n: "Azure Minerals", c: "Australia" },
  { id: 8140, s: "MDXH.BR", n: "Mdxhealth", c: "Belgium" },
  { id: 8141, s: "APGI", n: "American Pwr Group", c: "US" },
  { id: 8142, s: "NORSE.OL", n: "Norse Atlantic ASA", c: "Norway" },
  { id: 8143, s: "SQ2.AX", n: "Block Inc", c: "Australia" },
  { id: 8144, s: "EYAPS.AT", n: "Thessaloniki Water Supply & Sewerage Co S.A", c: "Greece" },
  { id: 8145, s: "600031.SS", n: "Sany Heavy Industry", c: "CH" },
  { id: 8146, s: "KTN", n: "Credit Enhanced Corts Trust For Aon Capital A GIC", c: "US" },
  { id: 8147, s: "MMMW", n: "Mass Megawat Wind Pw", c: "US" },
  { id: 8148, s: "OLA.TO", n: "Orla Mining", c: "CA" },
  { id: 8149, s: "HMSO.L", n: "Hammerson PLC", c: "UK" },
  { id: 8150, s: "TKL.AX", n: "Traka Resources", c: "Australia" },
  { id: 8151, s: "IDBA", n: "Idex Biometrics ASA ADR", c: "US" },
  { id: 8152, s: "OSI.V", n: "Osino Resources Co", c: "CA" },
  { id: 8153, s: "THRM.V", n: "Therma Bright Inc", c: "CA" },
  { id: 8154, s: "VWSYF", n: "Vestas Wind Systems A/S", c: "US" },
  { id: 8155, s: "TNRG", n: "Thunder Energies Co", c: "US" },
  { id: 8156, s: "GBU.V", n: "Gabriel Resources", c: "CA" },
  { id: 8157, s: "300866.SZ", n: "Anker Innovations Technology ", c: "CH" },
  { id: 8158, s: "CCTL", n: "Coin Citadel", c: "US" },
  { id: 8159, s: "GEKTERNA.AT", n: "GEK TERNA Holdings Real Estate Construction S.A", c: "Greece" },
  { id: 8160, s: "RWI.L", n: "Renewi PLC", c: "UK" },
  { id: 8161, s: "RMD.AX", n: "Resmed Inc DRC", c: "Australia" },
  { id: 8162, s: "EL8.AX", n: "Elevate Uranium", c: "Australia" },
  { id: 8163, s: "601601.SS", n: "CH Pacific Insurance Group", c: "CH" },
  { id: 8164, s: "STEM.L", n: "SThree plc", c: "UK" },
  { id: 8165, s: "BLDV", n: "Blue Diamond Ventures Inc", c: "US" },
  { id: 8166, s: "BNN.DE", n: "BRAIN Biotech AG", c: "GER" },
  { id: 8167, s: "010950.KS", n: "S-Oil Co", c: "Korea" },
  { id: 8168, s: "001040.KS", n: "CJ Co", c: "Korea" },
  { id: 8169, s: "KCN.AX", n: "Kingsgate Consolidated.", c: "Australia" },
  { id: 8170, s: "GPW.WA", n: "Gielda Papierow Wartosciowych w Warszawie SA", c: "Poland" },
  { id: 8171, s: "CRST.L", n: "Crest Nicholson Holdings plc", c: "UK" },
  { id: 8172, s: "EDR.TO", n: "Endeavour Silver Corp.", c: "CA" },
  { id: 8173, s: "RCLF", n: "Rosecliff Acquisition Corp I", c: "US" },
  { id: 8174, s: "O2D.DE", n: "Telefónica Deutschland Holding AG", c: "GER" },
  { id: 8175, s: "HTG.L", n: "Hunting PLC", c: "UK" },
  { id: 8176, s: "UNI.AX", n: "Universal Store Holdings Ltd", c: "Australia" },
  { id: 8177, s: "128940.KS", n: "Hanmi Pharm", c: "Korea" },
  { id: 8178, s: "IPCI.TO", n: "Intellipharmaceutics International Inc", c: "CA" },
  { id: 8179, s: "079170.KQ", n: "Hanchang Ind.Co.Ltd", c: "Korea" },
  { id: 8180, s: "NXS.V", n: "Nexus Gold Co", c: "CA" },
  { id: 8181, s: "MBH.L", n: "Michelmersh Brick Holdings Plc", c: "UK" },
  { id: 8182, s: "JLP.L", n: "Jubilee Platinum", c: "UK" },
  { id: 8183, s: "SUPR.L", n: "Supermarket Income REIT PLC", c: "UK" },
  { id: 8184, s: "RSW.L", n: "Renishaw PLC", c: "UK" },
  { id: 8185, s: "JAPSY", n: "Japan Airlines ADR", c: "US" },
  { id: 8186, s: "001820.KS", n: "Sam Hwa Capaci", c: "Korea" },
  { id: 8187, s: "KGN.AX", n: "Kogan.com", c: "Australia" },
  { id: 8188, s: "1210.TW", n: "Great Wall Enterprise", c: "TW" },
  { id: 8189, s: "HPLT", n: "Home Plate Acquisition Co", c: "US" },
  { id: 8190, s: "AKSEN.IS", n: "Aksa Enerji Uretim AS", c: "Turkey" },
  { id: 8191, s: "BXNG", n: "Bang Holdings Co", c: "US" },
  { id: 8192, s: "9941.TW", n: "Yulon Finance Co", c: "TW" },
  { id: 8193, s: "BUFT.TA", n: "Buff Technologies", c: "Israel" },
  { id: 8194, s: "CMG.TO", n: "Computer Modelling Group.", c: "CA" },
  { id: 8195, s: "GGZ", n: "Gabelli Global Small and Mid Cap Value Closed Fund", c: "US" },
  { id: 8196, s: "CKI.TO", n: "Clarke Inc.", c: "CA" },
  { id: 8197, s: "6488.TWO", n: "GlobalWafers", c: "TW" },
  { id: 8198, s: "FFDF", n: "FFD Financial Co", c: "US" },
  { id: 8199, s: "RBSFY", n: "Rubis SCA ADR", c: "US" },
  { id: 8200, s: "GAV.SW", n: "Carlo Gavazzi Holding AG", c: "Switzerland" },
  { id: 8201, s: "SII.PA", n: "Société pour l'Informatique Industrielle SII S.A", c: "FR" },
  { id: 8202, s: "DRO.AX", n: "DroneShield", c: "Australia" },
  { id: 8203, s: "2892.TW", n: "First Financial Holding", c: "TW" },
  { id: 8204, s: "ADCO.TO", n: "Adcore Inc", c: "CA" },
  { id: 8205, s: "HPOL-B.ST", n: "HEXPOL AB (publ)", c: "Sweden" },
  { id: 8206, s: "QIS.V", n: "Quorum Information Technologies Inc", c: "CA" },
  { id: 8207, s: "ANORA.HE", n: "Anora Group Oyj", c: "Finland" },
  { id: 8208, s: "010400.KS", n: "Woojin I&S", c: "Korea" },
  { id: 8209, s: "CZBT", n: "Citizens Bancorp of Virginia Inc", c: "US" },
  { id: 8210, s: "TM.V", n: "Trigon Metals Inc", c: "CA" },
  { id: 8211, s: "6472.TWO", n: "Bora Pharmaceuticals", c: "TW" },
  { id: 8212, s: "IRBR3.SA", n: "IRB-Brasil Resseguros S.A.", c: "Brazil" },
  { id: 8213, s: "QFUEL.OL", n: "Quantafuel As", c: "Norway" },
  { id: 8214, s: "SKB.DE", n: "Koenig & Bauer AG", c: "GER" },
  { id: 8215, s: "CHYHY", n: "Chr Hansen Holding A/S ADR", c: "US" },
  { id: 8216, s: "FZT", n: "FAST Acquisition II Co", c: "US" },
  { id: 8217, s: "GCEI", n: "Global Clean Energy Inc", c: "US" },
  { id: 8218, s: "BTH.AX", n: "Bigtincan Holdings", c: "Australia" },
  { id: 8219, s: "VNP.TO", n: "5N Plus Inc.", c: "CA" },
  { id: 8220, s: "SMSMED.", n: "Scandinavian Medical Solutions AS", c: "Denmark" },
  { id: 8221, s: "TRY.L", n: "TR Property Investment Trust plc", c: "UK" },
  { id: 8222, s: "LEM.V", n: "Leading Edge Materials Co", c: "CA" },
  { id: 8223, s: "COIC.ST", n: "Concentric AB (publ)", c: "Sweden" },
  { id: 8224, s: "COST.NE", n: "Costco Wholesale Corp CDR", c: "CA" },
  { id: 8225, s: "USLG", n: "US Lighting Group Inc", c: "US" },
  { id: 8226, s: "INDU-A.ST", n: "Industrivarden AB ser. A", c: "Sweden" },
  { id: 8227, s: "BRK.NE", n: "Berkshire Hathaway Inc CDR", c: "CA" },
  { id: 8228, s: "RAP1V.HE", n: "Rapala VMC Oyj", c: "Finland" },
  { id: 8229, s: "SECT-B.ST", n: "Sectra AB (publ)", c: "Sweden" },
  { id: 8230, s: "SXL.AX", n: "Southern Cross Media Group", c: "Australia" },
  { id: 8231, s: "BOSS.F", n: "Hugo Boss AG", c: "GER" },
  { id: 8232, s: "CMR.WA", n: "Comarch SA", c: "Poland" },
  { id: 8233, s: "NXN", n: "Nuveen New York Select Tax-Free Income Portfolio", c: "US" },
  { id: 8234, s: "UNI.MC", n: "Unicaja Banco SA", c: "Spain" },
  { id: 8235, s: "ELLH", n: "Elah Holdings Inc", c: "US" },
  { id: 8236, s: "BSL.AX", n: "Bluescope Steel", c: "Australia" },
  { id: 8237, s: "ACGBF", n: "Agricultural Bank Of CH", c: "US" },
  { id: 8238, s: "NODK", n: "NI Holdings Inc", c: "US" },
  { id: 8239, s: "ORPHA.", n: "Orphazyme A/S", c: "Denmark" },
  { id: 8240, s: "VCT.PA", n: "Vicat S.A", c: "FR" },
  { id: 8241, s: "HAW.AX", n: "Hawthorn Resources", c: "Australia" },
  { id: 8242, s: "BYG.L", n: "Big Yellow Group PLC", c: "UK" },
  { id: 8243, s: "VCP.L", n: "Victoria PLC", c: "UK" },
  { id: 8244, s: "VNV.ST", n: "VNV Global AB", c: "Sweden" },
  { id: 8245, s: "300124.SZ", n: "Shenzhen Inovance Tech", c: "CH" },
  { id: 8246, s: "GTLY.L", n: "Gateley (Holdings) Plc", c: "UK" },
  { id: 8247, s: "MONY.L", n: "Moneysupermarket.Com Group PLC", c: "UK" },
  { id: 8248, s: "ESON.LS", n: "Estoril Sol SGPS SA", c: "Portugal" },
  { id: 8249, s: "STORY-B.ST", n: "Storytel AB (publ)", c: "Sweden" },
  { id: 8250, s: "TOY.TO", n: "Spin Master Co", c: "CA" },
  { id: 8251, s: "WIHN.SW", n: "Wisekey International Holding AG", c: "Switzerland" },
  { id: 8252, s: "WALL-B.ST", n: "Wallenstam AB (publ)", c: "Sweden" },
  { id: 8253, s: "ARZZ3.SA", n: "Arezzo Indústria e Comércio S.A", c: "Brazil" },
  { id: 8254, s: "RWOD", n: "Redwoods Acquisition Co", c: "US" },
  { id: 8255, s: "OP.ST", n: "Oscar Properties Holding AB", c: "Sweden" },
  { id: 8256, s: "6263.TWO", n: "Planet Technology", c: "TW" },
  { id: 8257, s: "YONGA.IS", n: "Yonga Mobilya Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 8258, s: "NXO.V", n: "Nexoptic Technology Co", c: "CA" },
  { id: 8259, s: "2385.TW", n: "Chicony Electronics", c: "TW" },
  { id: 8260, s: "1513.TW", n: "Chung-Hsin Electric & Machinery Mfg Co", c: "TW" },
  { id: 8261, s: "PROF.AT", n: "Profile Systems & Software SA", c: "Greece" },
  { id: 8262, s: "KLDO", n: "Kaleido Biosciences Inc", c: "US" },
  { id: 8263, s: "EOT", n: "Eaton Vance National Municipal Opportunities Closed Fund", c: "US" },
  { id: 8264, s: "ITOX", n: "Iiot-Oxys Inc", c: "US" },
  { id: 8265, s: "KARN.SW", n: "Kardex", c: "Switzerland" },
  { id: 8266, s: "ANOD-B.ST", n: "Addnode Group AB (publ)", c: "Sweden" },
  { id: 8267, s: "RRTL.DE", n: "RTL Group SA", c: "GER" },
  { id: 8268, s: "600406.SS", n: "NARI Technology", c: "CH" },
  { id: 8269, s: "LTNC", n: "Labor Smart Inc", c: "US" },
  { id: 8270, s: "SZLS.TO", n: "StageZero Life Sciences", c: "CA" },
  { id: 8271, s: "OWCP", n: "OWC Pharmaceutical Research Co", c: "US" },
  { id: 8272, s: "INS.L", n: "Instem PLC", c: "UK" },
  { id: 8273, s: "SVS.L", n: "Savills", c: "UK" },
  { id: 8274, s: "IMMNOV.ST", n: "Immunovia publ AB", c: "Sweden" },
  { id: 8275, s: "2337.TW", n: "Macronix International", c: "TW" },
  { id: 8276, s: "AGILC.", n: "Agillic A/S", c: "Denmark" },
  { id: 8277, s: "002555.SZ", n: "37 Interactive Entertainment Network Technology Group", c: "CH" },
  { id: 8278, s: "GOR.AX", n: "Gold Road Resources", c: "Australia" },
  { id: 8279, s: "RANJY", n: "Randstad Holdings NV", c: "US" },
  { id: 8280, s: "QNBC", n: "QNB Co", c: "US" },
  { id: 8281, s: "DWL.L", n: "Dowlais Group Plc", c: "UK" },
  { id: 8282, s: "TSYHY", n: "TravelSky Technology ADR", c: "US" },
  { id: 8283, s: "603806.SS", n: "Hangzhou First PV Material", c: "CH" },
  { id: 8284, s: "HRST", n: "Harvest Oil & Gas Co", c: "US" },
  { id: 8285, s: "BONS.TA", n: "Bonus Biogroup", c: "Israel" },
  { id: 8286, s: "GAM.SW", n: "GAM Holding AG", c: "Switzerland" },
  { id: 8287, s: "SEK.AX", n: "Seek", c: "Australia" },
  { id: 8288, s: "DMP.DE", n: "Dermapharm Holding SE", c: "GER" },
  { id: 8289, s: "047040.KS", n: "Daewoo Engineering & Construction", c: "Korea" },
  { id: 8290, s: "DSK.AX", n: "Dusk Group Ltd", c: "Australia" },
  { id: 8291, s: "LUM.V", n: "Lumina Gold Co", c: "CA" },
  { id: 8292, s: "SWA.DE", n: "Schloss Wachenheim AG", c: "GER" },
  { id: 8293, s: "GENO.ST", n: "Genovis AB (publ.)", c: "Sweden" },
  { id: 8294, s: "BYN", n: "Banyan Acquisition Co", c: "US" },
  { id: 8295, s: "HEKTS.IS", n: "Hektas Ticaret TAS", c: "Turkey" },
  { id: 8296, s: "LTV.V", n: "LeoNovus Inc", c: "CA" },
  { id: 8297, s: "LAB.V", n: "Labrador Gold Co", c: "CA" },
  { id: 8298, s: "TUD.V", n: "Tudor Gold Co", c: "CA" },
  { id: 8299, s: "MSAB-B.ST", n: "Micro Systemation AB (publ)", c: "Sweden" },
  { id: 8300, s: "AKP.AX", n: "Audio Pixels Holdings", c: "Australia" },
  { id: 8301, s: "DRX.L", n: "Drax Group", c: "UK" },
  { id: 8302, s: "OTB.L", n: "On The Beach Group PLC", c: "UK" },
  { id: 8303, s: "CLEMO.ST", n: "Clean Motion AB", c: "Sweden" },
  { id: 8304, s: "SPNO.", n: "Spar Nord Bank", c: "Denmark" },
  { id: 8305, s: "VNA.F", n: "Vonovia SE", c: "GER" },
  { id: 8306, s: "CLIS", n: "Clickstream Co", c: "US" },
  { id: 8307, s: "HUFV-A.ST", n: "Hufvudstaden AB (publ)", c: "Sweden" },
  { id: 8308, s: "IGR.L", n: "IG Design Group plc", c: "UK" },
  { id: 8309, s: "GBRRF", n: "Gabriel Resources", c: "US" },
  { id: 8310, s: "IBDSF", n: "Iberdrola S.A", c: "US" },
  { id: 8311, s: "MEDX.SW", n: "medmix AG", c: "Switzerland" },
  { id: 8312, s: "CMP.AX", n: "Compumedics", c: "Australia" },
  { id: 8313, s: "ASAZY", n: "Assa Abloy AB", c: "US" },
  { id: 8314, s: "6213.TW", n: "ITEQ Co", c: "TW" },
  { id: 8315, s: "ALSGD.PA", n: "Spineguard", c: "FR" },
  { id: 8316, s: "HAVI.OL", n: "Havila Shipping ASA", c: "Norway" },
  { id: 8317, s: "ALMAS.PA", n: "Mastrad", c: "FR" },
  { id: 8318, s: "CULL", n: "Cullman Bancorp Inc.", c: "US" },
  { id: 8319, s: "CRL.L", n: "Creightons Plc", c: "UK" },
  { id: 8320, s: "601800.SS", n: "CH Communications Construction", c: "CH" },
  { id: 8321, s: "CAPMAN.HE", n: "CapMan Oyj B", c: "Finland" },
  { id: 8322, s: "BBSEY", n: "BB Seguridade Participacoes SA", c: "US" },
  { id: 8323, s: "ECONB.BR", n: "Econocom Group SA/NV", c: "Belgium" },
  { id: 8324, s: "PNV.AX", n: "Polynovo", c: "Australia" },
  { id: 8325, s: "LSS.PA", n: "Lectra SA", c: "FR" },
  { id: 8326, s: "COL.MC", n: "Inmobiliaria Colonial SA", c: "Spain" },
  { id: 8327, s: "BXN.AX", n: "Bioxyne", c: "Australia" },
  { id: 8328, s: "BJKAS.IS", n: "Besiktas Futbol Yatirimlari Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 8329, s: "PLL.AX", n: "Piedmont Lithium", c: "Australia" },
  { id: 8330, s: "GSHR.V", n: "Goldshore Resources Inc", c: "CA" },
  { id: 8331, s: "6505.TW", n: "Formosa Petrochemical Co", c: "TW" },
  { id: 8332, s: "SIP.BR", n: "Sipef NV", c: "Belgium" },
  { id: 8333, s: "BLV.PA", n: "Believe SAS", c: "FR" },
  { id: 8334, s: "ZAG.VI", n: "Zumtobel Group AG", c: "Austria" },
  { id: 8335, s: "ARTG.V", n: "Artemis Gold Inc", c: "CA" },
  { id: 8336, s: "CCP.AX", n: "Credit Corp Group", c: "Australia" },
  { id: 8337, s: "FENER.IS", n: "Fenerbahce Futbol AS", c: "Turkey" },
  { id: 8338, s: "SBSP3.SA", n: "Companhia de Saneamento Básico do Estado de São Paulo - SABESP", c: "Brazil" },
  { id: 8339, s: "PH2.AX", n: "Pure Hydrogen Corporation", c: "Australia" },
  { id: 8340, s: "FNAC.PA", n: "Fnac Darty SA", c: "FR" },
  { id: 8341, s: "WYN.L", n: "Wynnstay Group Plc", c: "UK" },
  { id: 8342, s: "SEM.VI", n: "Semperit Aktiengesellschaft Holding", c: "Austria" },
  { id: 8343, s: "086280.KS", n: "Hyundai Glovis", c: "Korea" },
  { id: 8344, s: "DSCR", n: "Discovery Minerals", c: "US" },
  { id: 8345, s: "SNWV", n: "SANUWAVE Health Inc", c: "US" },
  { id: 8346, s: "CLI.L", n: "CLS Holdings plc", c: "UK" },
  { id: 8347, s: "GEA.PA", n: "GEA Grenobl. Elect.", c: "FR" },
  { id: 8348, s: "601186.SS", n: "CH Railway Construction Co", c: "CH" },
  { id: 8349, s: "KCC.OL", n: "Klaveness Combination Carriers ASA", c: "Norway" },
  { id: 8350, s: "002049.SZ", n: "Unigroup Guoxin Microelectronics", c: "CH" },
  { id: 8351, s: "CPFE3.SA", n: "CPFL Energia S.A", c: "Brazil" },
  { id: 8352, s: "SSPG.L", n: "SSP Group PLC", c: "UK" },
  { id: 8353, s: "ACARIX.ST", n: "Acarix A/S", c: "Sweden" },
  { id: 8354, s: "BMBOY", n: "Grupo Bimbo SAB de CV ADR", c: "US" },
  { id: 8355, s: "SEYE.ST", n: "Smart Eye AB", c: "Sweden" },
  { id: 8356, s: "CDIX", n: "Cardiff Lexington Co", c: "US" },
  { id: 8357, s: "WLLAW", n: "Whiting Petroleum Corporation", c: "US" },
  { id: 8358, s: "ABY.AX", n: "Adore Beauty Group Ltd", c: "Australia" },
  { id: 8359, s: "ME.TO", n: "Moneta Gold Inc", c: "CA" },
  { id: 8360, s: "CDA.PA", n: "Compagnie des Alpes SA", c: "FR" },
  { id: 8361, s: "MRNJ", n: "Metatron Inc", c: "US" },
  { id: 8362, s: "CWK.L", n: "Cranswick PLC", c: "UK" },
  { id: 8363, s: "A4N.AX", n: "Alpha HPA", c: "Australia" },
  { id: 8364, s: "ALBIO.PA", n: "Biosynex", c: "FR" },
  { id: 8365, s: "IVA.PA", n: "Inventiva S.A", c: "FR" },
  { id: 8366, s: "ALATA.PA", n: "Atari S.A.", c: "FR" },
  { id: 8367, s: "NRILY", n: "Nomura Research Institute ADR", c: "US" },
  { id: 8368, s: "COA.L", n: "Coats Group PLC", c: "UK" },
  { id: 8369, s: "GOG.V", n: "Golden Tag Resources.", c: "CA" },
  { id: 8370, s: "GIP.V", n: "Green Impact Partners Inc", c: "CA" },
  { id: 8371, s: "5434.TW", n: "Topco Scientific", c: "TW" },
  { id: 8372, s: "BOIL.L", n: "Baron Oil PLC", c: "UK" },
  { id: 8373, s: "600905.SS", n: "CH Three Gorges New Energy Group", c: "CH" },
  { id: 8374, s: "COIN.NE", n: "Tokens.com Co", c: "CA" },
  { id: 8375, s: "GBML.V", n: "Global Battery Metals", c: "CA" },
  { id: 8376, s: "CGF.AX", n: "Challenger", c: "Australia" },
  { id: 8377, s: "LAT.PA", n: "Latécoère S.A", c: "FR" },
  { id: 8378, s: "ABCP", n: "Ambase Co", c: "US" },
  { id: 8379, s: "VAU.V", n: "Viva Gold Co", c: "CA" },
  { id: 8380, s: "DIS.L", n: "Distil Plc", c: "UK" },
  { id: 8381, s: "VID.MC", n: "Vidrala SA", c: "Spain" },
  { id: 8382, s: "007660.KS", n: "Isupetasys", c: "Korea" },
  { id: 8383, s: "600704.SS", n: "Wuchan Zhongda Group", c: "CH" },
  { id: 8384, s: "BIDS.L", n: "Bidstack Group PLC", c: "UK" },
  { id: 8385, s: "AMGO.L", n: "Amigo Holdings PLC", c: "UK" },
  { id: 8386, s: "MBG.F", n: "Mercedes-Benz Group AG", c: "GER" },
  { id: 8387, s: "GGBR3.SA", n: "Gerdau S.A", c: "Brazil" },
  { id: 8388, s: "2887.TW", n: "Taishin Financial Holding", c: "TW" },
  { id: 8389, s: "HAG.F", n: "Hensoldt AG", c: "GER" },
  { id: 8390, s: "2002.TW", n: "CH Steel Co", c: "TW" },
  { id: 8391, s: "CRP.TO", n: "Ceres Global Ag Co", c: "CA" },
  { id: 8392, s: "QHL.AX", n: "Quickstep Holdings", c: "Australia" },
  { id: 8393, s: "MJWL", n: "Majic Wheels Corp.", c: "US" },
  { id: 8394, s: "MCOIF", n: "MultiChoice Group", c: "US" },
  { id: 8395, s: "ADS.F", n: "adidas AG", c: "GER" },
  { id: 8396, s: "HPTO", n: "hopTo Inc", c: "US" },
  { id: 8397, s: "STEP.TO", n: "STEP Energy Services", c: "CA" },
  { id: 8398, s: "002115.SZ", n: "Sunwave Communications", c: "CH" },
  { id: 8399, s: "TXTM", n: "Protext Mobility Inc", c: "US" },
  { id: 8400, s: "FDM.L", n: "FDM Group Holdings PLC", c: "UK" },
  { id: 8401, s: "TBCG.L", n: "TBC Bank Group PLC", c: "UK" },
  { id: 8402, s: "VO.V", n: "ValOre Metals Co", c: "CA" },
  { id: 8403, s: "LVVV", n: "Livewire Ergogenics", c: "US" },
  { id: 8404, s: "MLCT", n: "Vitality Biopharma Inc", c: "US" },
  { id: 8405, s: "SNG.DE", n: "Singulus Technologies AG", c: "GER" },
  { id: 8406, s: "FORN.SW", n: "Forbo Holding AG", c: "Switzerland" },
  { id: 8407, s: "688012.SS", n: "Advanced Micro Fabrication Inc", c: "CH" },
  { id: 8408, s: "MTLF.TA", n: "Matricelf", c: "Israel" },
  { id: 8409, s: "JDAN.", n: "Jeudan", c: "Denmark" },
  { id: 8410, s: "NDA.V", n: "Neptune Digital Assets Co", c: "CA" },
  { id: 8411, s: "SSIC", n: "Silver Spike Investment Co", c: "US" },
  { id: 8412, s: "BAP.AX", n: "Bapcor Limited", c: "Australia" },
  { id: 8413, s: "AIS.AX", n: "Aeris Resources", c: "Australia" },
  { id: 8414, s: "MUN.V", n: "Mundoro Capital Inc", c: "CA" },
  { id: 8415, s: "JBAXY", n: "Julius Baer Group", c: "US" },
  { id: 8416, s: "BSX.TO", n: "Belo Sun Mining Corp.", c: "CA" },
  { id: 8417, s: "FLC.AX", n: "Fluence Corporation", c: "Australia" },
  { id: 8418, s: "SBM.AX", n: "St Barbara", c: "Australia" },
  { id: 8419, s: "EVOL", n: "Evolving Systems Inc", c: "US" },
  { id: 8420, s: "NDEKY", n: "Nitto Denko Co", c: "US" },
  { id: 8421, s: "EGT.V", n: "Eguana Technologies Inc", c: "CA" },
  { id: 8422, s: "MRC.TO", n: "Morguard Corporation", c: "CA" },
  { id: 8423, s: "SOLI.L", n: "Solid State Plc", c: "UK" },
  { id: 8424, s: "VFL", n: "Delaware Investments Florida MIF", c: "US" },
  { id: 8425, s: "PGE.WA", n: "PGE Polska Grupa Energetyczna SA", c: "Poland" },
  { id: 8426, s: "ODV.V", n: "Osisko Development Co", c: "CA" },
  { id: 8427, s: "ALNT.MC", n: "Alantra Partners S.A.", c: "Spain" },
  { id: 8428, s: "SMWH.L", n: "WH Smith PLC", c: "UK" },
  { id: 8429, s: "WDRP", n: "Wanderport Co", c: "US" },
  { id: 8430, s: "ALWEC.PA", n: "WeConnect", c: "FR" },
  { id: 8431, s: "BEEF3.SA", n: "Minerva S.A", c: "Brazil" },
  { id: 8432, s: "300919.SZ", n: "Cngr Advanced Material ", c: "CH" },
  { id: 8433, s: "SMTO3.SA", n: "São Martinho S.A", c: "Brazil" },
  { id: 8434, s: "MOON.L", n: "Moonpig Group PLC", c: "UK" },
  { id: 8435, s: "CORE-B.ST", n: "Corem Property Group AB (publ)", c: "Sweden" },
  { id: 8436, s: "ETCMY", n: "Eutelsat Communications SA ADR", c: "US" },
  { id: 8437, s: "WCFB", n: "WCF Bancorp Inc", c: "US" },
  { id: 8438, s: "ETRGF", n: "Entourage Health Co", c: "US" },
  { id: 8439, s: "ZUGER.SW", n: "Zuger Kantonalbank", c: "Switzerland" },
  { id: 8440, s: "G1A.DE", n: "GEA Group Aktiengesellschaft", c: "GER" },
  { id: 8441, s: "PASAB.MX", n: "Promotora Ambiental S.A.B. de C.V", c: "Mexi" },
  { id: 8442, s: "BEWI.OL", n: "Bewi Asa", c: "Norway" },
  { id: 8443, s: "DEG.AX", n: "De Grey Mining", c: "Australia" },
  { id: 8444, s: "JPEX", n: "JPX Global Inc", c: "US" },
  { id: 8445, s: "WLYB", n: "John Wiley & Sons B", c: "US" },
  { id: 8446, s: "APRU", n: "Apple Rush Company", c: "US" },
  { id: 8447, s: "IFL.AX", n: "Insignia Financial", c: "Australia" },
  { id: 8448, s: "SUV.AX", n: "Suvo Strategic Minerals", c: "Australia" },
  { id: 8449, s: "ARJO-B.ST", n: "Arjo AB (publ)", c: "Sweden" },
  { id: 8450, s: "FOYRK.AT", n: "Fourlis Holdings S.A.", c: "Greece" },
  { id: 8451, s: "RANI3.SA", n: "Irani Papel e Embalagem S.A", c: "Brazil" },
  { id: 8452, s: "PUTKY", n: "United Tractors Tbk PT ADR", c: "US" },
  { id: 8453, s: "SSV.V", n: "Southern Silver Exploration Corp.", c: "CA" },
  { id: 8454, s: "AGHOL.IS", n: "AG Anadolu Group Holding", c: "Turkey" },
  { id: 8455, s: "NYF.ST", n: "Nyfosa AB (publ)", c: "Sweden" },
  { id: 8456, s: "BEW.V", n: "Bewhere Holdings Inc", c: "CA" },
  { id: 8457, s: "9933.TW", n: "CTCI Co", c: "TW" },
  { id: 8458, s: "DEER", n: "Deer Consumer Prodct", c: "US" },
  { id: 8459, s: "SMOP.OL", n: "Smartoptics Group AS", c: "Norway" },
  { id: 8460, s: "RCH.L", n: "Reach PLC", c: "UK" },
  { id: 8461, s: "BZLFY", n: "Bunzl plc", c: "US" },
  { id: 8462, s: "RZR.TA", n: "Razor Labs", c: "Israel" },
  { id: 8463, s: "AVBH", n: "Avidbank Holdings Inc", c: "US" },
  { id: 8464, s: "PRFO.ST", n: "Profoto Holding AB", c: "Sweden" },
  { id: 8465, s: "FTG.TO", n: "Firan Technology Group Corporation", c: "CA" },
  { id: 8466, s: "REFG", n: "Medical Cannabis Pay", c: "US" },
  { id: 8467, s: "002129.SZ", n: "TCL Zhonghuan Renewable Energy Technology", c: "CH" },
  { id: 8468, s: "LAMDA.AT", n: "LAMDA Development S.A.", c: "Greece" },
  { id: 8469, s: "000063.SZ", n: "ZTE Co", c: "CH" },
  { id: 8470, s: "EXM.BR", n: "Exmar NV", c: "Belgium" },
  { id: 8471, s: "DEQ.DE", n: "Deutsche EuroShop AG", c: "GER" },
  { id: 8472, s: "RYGYO.IS", n: "Reysas Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 8473, s: "8299.TWO", n: "Phison Electronics", c: "TW" },
  { id: 8474, s: "EBUS.AS", n: "Ebusco Holding BV", c: "Netherlands" },
  { id: 8475, s: "THQQF", n: "Embracer Group AB", c: "US" },
  { id: 8476, s: "OMG.L", n: "Oxford Metrics plc", c: "UK" },
  { id: 8477, s: "FA.V", n: "Fountain Asset Co", c: "CA" },
  { id: 8478, s: "CFT.SW", n: "Compagnie Financiere Tradition", c: "Switzerland" },
  { id: 8479, s: "MOVE.NE", n: "PowerTap Hydrogen Capital Co", c: "CA" },
  { id: 8480, s: "247540.KQ", n: "Ecopro BM. Co.", c: "Korea" },
  { id: 8481, s: "LOV.AX", n: "Lovisa Holdings", c: "Australia" },
  { id: 8482, s: "RAKR", n: "Rainmaker Worldwide Inc", c: "US" },
  { id: 8483, s: "5269.TW", n: "Asmedia Technology Inc", c: "TW" },
  { id: 8484, s: "DMYD-B.ST", n: "Diamyd Medical AB (publ)", c: "Sweden" },
  { id: 8485, s: "NEP.HM", n: "SLOMAN NEPTUN Schiffahrts-Aktiengesellschaft", c: "GER" },
  { id: 8486, s: "BYN.V", n: "Banyan Gold Co", c: "CA" },
  { id: 8487, s: "FDJ.PA", n: "La Francaise Des Jeux Sa", c: "FR" },
  { id: 8488, s: "GAM.PA", n: "Gaumont SA", c: "FR" },
  { id: 8489, s: "CFGX", n: "Capital Financial Gl", c: "US" },
  { id: 8490, s: "SWRAY", n: "Swire Pacific", c: "US" },
  { id: 8491, s: "AGROUP.ST", n: "Arlandastad Group AB", c: "Sweden" },
  { id: 8492, s: "MMB.PA", n: "Lagardere SCA", c: "FR" },
  { id: 8493, s: "HZR.AX", n: "Hazer Group", c: "Australia" },
  { id: 8494, s: "PAG.L", n: "Paragon Banking Group PLC", c: "UK" },
  { id: 8495, s: "ROS.V", n: "Roscan Gold Co", c: "CA" },
  { id: 8496, s: "TDRN.TA", n: "Tadiran Hldg", c: "Israel" },
  { id: 8497, s: "8086.TWO", n: "Advanced Wireless Semiconductor", c: "TW" },
  { id: 8498, s: "RVG.V", n: "Revival Gold Inc", c: "CA" },
  { id: 8499, s: "FDE.PA", n: "Francaise de l&#39;Energie", c: "FR" },
  { id: 8500, s: "000002.SZ", n: "CH Vanke Co Class A", c: "CH" },
  { id: 8501, s: "MDTR.TA", n: "Mediterranean Towers", c: "Israel" },
  { id: 8502, s: "QEC.TO", n: "Questerre Energy Corporation", c: "CA" },
  { id: 8503, s: "HBRM", n: "Herborium Group Inc", c: "US" },
  { id: 8504, s: "ESSITY-A.ST", n: "Essity AB (publ)", c: "Sweden" },
  { id: 8505, s: "ASHM.L", n: "Ashmore Group Plc", c: "UK" },
  { id: 8506, s: "MTSFY", n: "Mitsui Fudosan Co ADR", c: "US" },
  { id: 8507, s: "OIBR3.SA", n: "Oi S.A", c: "Brazil" },
  { id: 8508, s: "OSB.L", n: "OneSavings Bank PLC", c: "UK" },
  { id: 8509, s: "DTII", n: "Defense Technologies International Co", c: "US" },
  { id: 8510, s: "KZR.AX", n: "Kalamazoo Resources", c: "Australia" },
  { id: 8511, s: "ARBB.L", n: "Arbuthnot Banking Group Plc", c: "UK" },
  { id: 8512, s: "ARBN.SW", n: "Arbonia AG", c: "Switzerland" },
  { id: 8513, s: "EWLL", n: "eWellness Healthcare Co", c: "US" },
  { id: 8514, s: "ENAT3.SA", n: "Enauta Participações S.A", c: "Brazil" },
  { id: 8515, s: "MAB.L", n: "Mitchells & Butlers PLC", c: "UK" },
  { id: 8516, s: "ALTU", n: "Altitude Acquisition Co", c: "US" },
  { id: 8517, s: "ZMM.AX", n: "Zimi", c: "Australia" },
  { id: 8518, s: "FIAC", n: "Focus Impact Acquisition Co", c: "US" },
  { id: 8519, s: "033100.KQ", n: "Cheryong Electric Co.Ltd", c: "Korea" },
  { id: 8520, s: "ALHE.TA", n: "Alony Hetz Properties and Investments", c: "Israel" },
  { id: 8521, s: "BRSAN.IS", n: "Borusan Mannesmann Boru Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 8522, s: "MAD.AX", n: "Mader Group", c: "Australia" },
  { id: 8523, s: "CSAM.OL", n: "Csam Health Group As", c: "Norway" },
  { id: 8524, s: "MHTX", n: "Manhattan Scients", c: "US" },
  { id: 8525, s: "ALUP11.SA", n: "Alupar Investimento SA", c: "Brazil" },
  { id: 8526, s: "SFR.AX", n: "Sandfire Resources NL", c: "Australia" },
  { id: 8527, s: "MGR", n: "Affiliated Managers Group Inc", c: "US" },
  { id: 8528, s: "MTHH.", n: "MT Hoejgaard Holding A/S", c: "Denmark" },
  { id: 8529, s: "LDA.MC", n: "Linea Directa Aseguradora SA Compania de Seguros y Reaseguros", c: "Spain" },
  { id: 8530, s: "EDPFY", n: "EDP Energias de Portugal SA ADR", c: "US" },
  { id: 8531, s: "CMW.AX", n: "Cromwell Property Group", c: "Australia" },
  { id: 8532, s: "WHATS.BR", n: "What's Cooking Group NV/SA", c: "Belgium" },
  { id: 8533, s: "ENO.MC", n: "Elecnor S.A", c: "Spain" },
  { id: 8534, s: "EDV.L", n: "Endeavour Mining Co", c: "UK" },
  { id: 8535, s: "CKF.AX", n: "Collins Foods", c: "Australia" },
  { id: 8536, s: "CKA.AX", n: "Cokal", c: "Australia" },
  { id: 8537, s: "RCW.AX", n: "RightCrowd", c: "Australia" },
  { id: 8538, s: "ALM.MC", n: "Almirall", c: "Spain" },
  { id: 8539, s: "038290.KQ", n: "Macrogen Inc", c: "Korea" },
  { id: 8540, s: "SMCP.PA", n: "Smcp SAS", c: "FR" },
  { id: 8541, s: "CINT.ST", n: "Cint Group AB", c: "Sweden" },
  { id: 8542, s: "BNKR.L", n: "Bankers Investment Trust", c: "UK" },
  { id: 8543, s: "DSDVY", n: "DSV Panalpina A/S ADR", c: "US" },
  { id: 8544, s: "KRFG", n: "King Resources Inc", c: "US" },
  { id: 8545, s: "NDCVF", n: "Nordic Semiconductor ASA", c: "US" },
  { id: 8546, s: "HCVI", n: "Hennessy Capital Investment Corp VI", c: "US" },
  { id: 8547, s: "DGHI.V", n: "Digihost Technology Inc", c: "CA" },
  { id: 8548, s: "BSE.AX", n: "Base Resources", c: "Australia" },
  { id: 8549, s: "PGH.AX", n: "Pact Group Holdings", c: "Australia" },
  { id: 8550, s: "KAU.AX", n: "Kaiser Reef Ltd", c: "Australia" },
  { id: 8551, s: "MYR.AX", n: "Myer Holdings", c: "Australia" },
  { id: 8552, s: "NEC.AX", n: "Nine Entertainment Co Holdings", c: "Australia" },
  { id: 8553, s: "AEVS.SW", n: "Aevis Victoria SA", c: "Switzerland" },
  { id: 8554, s: "HBCYF", n: "HSBC Holdings PLC", c: "US" },
  { id: 8555, s: "SALME.OL", n: "Salmon Evolution Holding AS", c: "Norway" },
  { id: 8556, s: "NBH", n: "Neuberger Berman IMF Inc", c: "US" },
  { id: 8557, s: "SUS.ST", n: "Surgical Science Sweden AB", c: "Sweden" },
  { id: 8558, s: "BSKP.SW", n: "Basler Kantonalbank", c: "Switzerland" },
  { id: 8559, s: "CLBIO.ST", n: "Corline Biomedical AB", c: "Sweden" },
  { id: 8560, s: "IPVF", n: "InterPrivate III Financial Partners Inc", c: "US" },
  { id: 8561, s: "SHC.L", n: "Shaftesbury Capital PLC", c: "UK" },
  { id: 8562, s: "SHI.L", n: "SIG plc", c: "UK" },
  { id: 8563, s: "EPI-B.ST", n: "Epiroc AB (publ)", c: "Sweden" },
  { id: 8564, s: "INSTAL.ST", n: "Instalco Intressenter AB", c: "Sweden" },
  { id: 8565, s: "BRNL.AS", n: "Brunel International NV", c: "Netherlands" },
  { id: 8566, s: "LPHM", n: "Lee Pharmaceuticals Inc", c: "US" },
  { id: 8567, s: "ONCO.ST", n: "Oncopeptides AB", c: "Sweden" },
  { id: 8568, s: "BSLN.SW", n: "Basilea Pharmaceutica AG", c: "Switzerland" },
  { id: 8569, s: "HIAG.SW", n: "HIAG Immobilien Holding AG", c: "Switzerland" },
  { id: 8570, s: "PEAB-B.ST", n: "Peab AB (publ)", c: "Sweden" },
  { id: 8571, s: "JHX.AX", n: "James Hardie Industries PLC", c: "Australia" },
  { id: 8572, s: "MCAG", n: "Mountain Crest Acquisition Corp V", c: "US" },
  { id: 8573, s: "IKTSY", n: "Intertek Group Plc", c: "US" },
  { id: 8574, s: "SPEQT.ST", n: "Speqta publ AB", c: "Sweden" },
  { id: 8575, s: "ZAIRF", n: "Zinc8 Energy Solutions Inc", c: "US" },
  { id: 8576, s: "PAAL-B.", n: "Per Aarsleff Holding A/S", c: "Denmark" },
  { id: 8577, s: "ERBOS.IS", n: "Erbosan Erciyas Boru Sanayi Ve Ticaret AS", c: "Turkey" },
  { id: 8578, s: "603288.SS", n: "Foshan Haitian Flavouring and Food", c: "CH" },
  { id: 8579, s: "SIL.TO", n: "SilverCrest Metals Inc", c: "CA" },
  { id: 8580, s: "GSRAY.IS", n: "Galatasaray Sportif Sinai ve Ticari Yatirimlar AS", c: "Turkey" },
  { id: 8581, s: "NUBI", n: "Nubia Brand International Co", c: "US" },
  { id: 8582, s: "OSI", n: "Osiris Acquisition Co", c: "US" },
  { id: 8583, s: "MGG.V", n: "Minaurum Gold Inc", c: "CA" },
  { id: 8584, s: "SIMECB.MX", n: "Grupo Simec S.A.B. de C.V", c: "Mexi" },
  { id: 8585, s: "ORKLY", n: "Orkla ASA ADR", c: "US" },
  { id: 8586, s: "TRYIY", n: "Toray Industries Inc ADR", c: "US" },
  { id: 8587, s: "PSIX", n: "Power Solutions International Inc", c: "US" },
  { id: 8588, s: "029460.KS", n: "KC Co.", c: "Korea" },
  { id: 8589, s: "AQNA", n: "Algonquin Power Utilities Co", c: "US" },
  { id: 8590, s: "603893.SS", n: "Fuzhou Rockchip Electronics", c: "CH" },
  { id: 8591, s: "CPLF.TO", n: "Copperleaf Technologies Inc", c: "CA" },
  { id: 8592, s: "ADER", n: "26 Capital Acquisition Co", c: "US" },
  { id: 8593, s: "ACCYY", n: "Accor SA", c: "US" },
  { id: 8594, s: "600664.SS", n: "Harbin Pharmaceutical Group", c: "CH" },
  { id: 8595, s: "NWO.DE", n: "New Work SE", c: "GER" },
  { id: 8596, s: "MTLO.V", n: "Martello Technologies Group Inc", c: "CA" },
  { id: 8597, s: "VIVT3.SA", n: "Telefônica Brasil S.A.", c: "Brazil" },
  { id: 8598, s: "CKT.L", n: "Checkit PLC", c: "UK" },
  { id: 8599, s: "ECOM.V", n: "Emerge Commerce", c: "CA" },
  { id: 8600, s: "002185.SZ", n: "Tianshui Huatian Technology", c: "CH" },
  { id: 8601, s: "STND.V", n: "Standard Uranium.", c: "CA" },
  { id: 8602, s: "EMN.V", n: "Euro Manganese Inc", c: "CA" },
  { id: 8603, s: "1605.TW", n: "Walsin Lihwa Co", c: "TW" },
  { id: 8604, s: "601166.SS", n: "Industrial Bank", c: "CH" },
  { id: 8605, s: "ALITL.PA", n: "IT Link", c: "FR" },
  { id: 8606, s: "TI.TO", n: "Titan Mining Co", c: "CA" },
  { id: 8607, s: "SYZ.TO", n: "Sylogist.", c: "CA" },
  { id: 8608, s: "1504.TW", n: "TECO Electric & Machinery", c: "TW" },
  { id: 8609, s: "THX.V", n: "Thor Explorations.", c: "CA" },
  { id: 8610, s: "SOKM.IS", n: "Sok Marketler Ticaret As", c: "Turkey" },
  { id: 8611, s: "FSY.TO", n: "Forsys Metals Corp.", c: "CA" },
  { id: 8612, s: "DIAM.TO", n: "Star Diamond Co", c: "CA" },
  { id: 8613, s: "688169.SS", n: "Beijing Roborock Technology", c: "CH" },
  { id: 8614, s: "BDPT", n: "BioAdaptives Inc", c: "US" },
  { id: 8615, s: "CBOT.PA", n: "CBO Territoria S.A.", c: "FR" },
  { id: 8616, s: "BRD", n: "Beard Energy Transition Acquisition Co", c: "US" },
  { id: 8617, s: "EPSIL.AT", n: "Epsilon Net S.A.", c: "Greece" },
  { id: 8618, s: "TLSNY", n: "Telia Company AB ADR", c: "US" },
  { id: 8619, s: "601865.SS", n: "Flat Glass Group Co.", c: "CH" },
  { id: 8620, s: "272450.KS", n: "Jin Air", c: "Korea" },
  { id: 8621, s: "213500.KS", n: "Hansol Paper", c: "Korea" },
  { id: 8622, s: "TMSNY", n: "Temenos Group AG ADR", c: "US" },
  { id: 8623, s: "OLY.TO", n: "Olympia Financial Group Inc", c: "CA" },
  { id: 8624, s: "300458.SZ", n: "All Winner Technology", c: "CH" },
  { id: 8625, s: "NHHH.V", n: "FuelPositive Co", c: "CA" },
  { id: 8626, s: "600745.SS", n: "Wingtech Technology", c: "CH" },
  { id: 8627, s: "GBRC.V", n: "Gold Bull Resources Co", c: "CA" },
  { id: 8628, s: "CAF.V", n: "Canaf Investments Inc", c: "CA" },
  { id: 8629, s: "CSLLY", n: "CSL", c: "US" },
  { id: 8630, s: "323410.KS", n: "KakaoBank Co", c: "Korea" },
  { id: 8631, s: "SYSR.ST", n: "Systemair AB", c: "Sweden" },
  { id: 8632, s: "300204.SZ", n: "Staidson Beijing Biopharma", c: "CH" },
  { id: 8633, s: "1U1.DE", n: "1&1 AG", c: "GER" },
  { id: 8634, s: "ANDR.VI", n: "Andritz AG", c: "Austria" },
  { id: 8635, s: "TYOYY", n: "Taiyo Yuden Co ADR", c: "US" },
  { id: 8636, s: "PB", n: "People's Bank of Commerce", c: "US" },
  { id: 8637, s: "GLDC.V", n: "Cassiar Gold Co", c: "CA" },
  { id: 8638, s: "WRN.TO", n: "Western Copper and Gold Co", c: "CA" },
  { id: 8639, s: "2345.TW", n: "Accton Technology Co", c: "TW" },
  { id: 8640, s: "NAM.V", n: "New Age Metals Inc", c: "CA" },
  { id: 8641, s: "300012.SZ", n: "Centre Testing Intl Shenzhen", c: "CH" },
  { id: 8642, s: "BTDG", n: "B2digital Inc", c: "US" },
  { id: 8643, s: "QNC.V", n: "Quantum Numbers Inc", c: "CA" },
  { id: 8644, s: "SLAC", n: "Social Leverage Acquisition Corp I Class A Common Stock", c: "US" },
  { id: 8645, s: "300037.SZ", n: "Shenzhen Capchem Tech", c: "CH" },
  { id: 8646, s: "CYDSASAA.MX", n: "Cydsa S.A.B. de C.V", c: "Mexi" },
  { id: 8647, s: "002236.SZ", n: "Zhejiang Dahua Technology", c: "CH" },
  { id: 8648, s: "WRG.TO", n: "Western Energy Services Corp.", c: "CA" },
  { id: 8649, s: "EXN.TO", n: "Excellon Resources Inc.", c: "CA" },
  { id: 8650, s: "TINC.BR", n: "TINC Comm VA", c: "Belgium" },
  { id: 8651, s: "BGBIO.OL", n: "Bergenbio ASA", c: "Norway" },
  { id: 8652, s: "GIVX.TO", n: "Givex Information Technology Group", c: "CA" },
  { id: 8653, s: "SGBLY", n: "Standard Bank Group PK", c: "US" },
  { id: 8654, s: "TH.TO", n: "Theratechnologies Inc.", c: "CA" },
  { id: 8655, s: "AVVIY", n: "Aviva PLC ADR", c: "US" },
  { id: 8656, s: "LINK.OL", n: "Link Mobility Group Holding ASA", c: "Norway" },
  { id: 8657, s: "APM.V", n: "Andean Precious Metals Co", c: "CA" },
  { id: 8658, s: "FP.V", n: "FP Newspapers Inc", c: "CA" },
  { id: 8659, s: "FSECURE.HE", n: "F-SECURE OYJ", c: "Finland" },
  { id: 8660, s: "AWC.AX", n: "Alumina", c: "Australia" },
  { id: 8661, s: "601899.SS", n: "Zijin Mining Group Co Class A", c: "CH" },
  { id: 8662, s: "CCX.AX", n: "City Chic Collective", c: "Australia" },
  { id: 8663, s: "COOR.ST", n: "COOR Service Management AB", c: "Sweden" },
  { id: 8664, s: "GLN.AX", n: "Galan Lithium", c: "Australia" },
  { id: 8665, s: "ICE.AX", n: "Icetana Ltd", c: "Australia" },
  { id: 8666, s: "JRJCY", n: "CH Finance Online Co Limited", c: "US" },
  { id: 8667, s: "NYR.BR", n: "Nyrstar NV", c: "Belgium" },
  { id: 8668, s: "ITM.AX", n: "Itech Minerals", c: "Australia" },
  { id: 8669, s: "BGA.AX", n: "Bega Cheese", c: "Australia" },
  { id: 8670, s: "034730.KS", n: "SK Holdings", c: "Korea" },
  { id: 8671, s: "EBK.DE", n: "EnBW Energie Baden-Württemberg AG", c: "GER" },
  { id: 8672, s: "5519.TW", n: "LongDa Construction & Development Co", c: "TW" },
  { id: 8673, s: "RAUTE.HE", n: "Raute Oyj", c: "Finland" },
  { id: 8674, s: "WRX.TO", n: "Western Resources Co", c: "CA" },
  { id: 8675, s: "EMN.AX", n: "Euro Manganese Inc", c: "Australia" },
  { id: 8676, s: "COST.L", n: "Costain Group PLC", c: "UK" },
  { id: 8677, s: "HOMI.BR", n: "Home Invest Belgium - Sifi", c: "Belgium" },
  { id: 8678, s: "JMHLY", n: "Jardine Matheson Holdings PK", c: "US" },
  { id: 8679, s: "SSE.V", n: "Silver Spruce Resources Inc", c: "CA" },
  { id: 8680, s: "MPL.AX", n: "Medibank Private", c: "Australia" },
  { id: 8681, s: "003550.KS", n: "LG Co", c: "Korea" },
  { id: 8682, s: "PXA.AX", n: "PEXA Group", c: "Australia" },
  { id: 8683, s: "NRM.V", n: "Noram Lithium Co", c: "CA" },
  { id: 8684, s: "FSHOP13.MX", n: "Fibra Shop", c: "Mexi" },
  { id: 8685, s: "DOXA.ST", n: "Doxa AB", c: "Sweden" },
  { id: 8686, s: "ITRO", n: "Itronics Inc", c: "US" },
  { id: 8687, s: "KNCAY", n: "Konica Minolta Inc", c: "US" },
  { id: 8688, s: "PPBT.TA", n: "Purple Biotech", c: "Israel" },
  { id: 8689, s: "KNBWY", n: "Kirin Holdings", c: "US" },
  { id: 8690, s: "BKBEF", n: "Pipestone Energy Co", c: "US" },
  { id: 8691, s: "ZEU.AX", n: "Zeus Resources", c: "Australia" },
  { id: 8692, s: "CONSTI.HE", n: "Consti Yhtiot Oy", c: "Finland" },
  { id: 8693, s: "EDNMY", n: "Edenred Malakoff ADR", c: "US" },
  { id: 8694, s: "SMRT.NE", n: "CoinSmart Financial Inc", c: "CA" },
  { id: 8695, s: "IDR.MC", n: "Indra A", c: "Spain" },
  { id: 8696, s: "VIVR3.SA", n: "Viver Incorporadora e Construtora S.A", c: "Brazil" },
  { id: 8697, s: "603659.SS", n: "Shanghai Putailai New Energy Technology Co.Ltd.", c: "CH" },
  { id: 8698, s: "ALTA.V", n: "Altamira Gold Co", c: "CA" },
  { id: 8699, s: "AMK.V", n: "American Creek Resources", c: "CA" },
  { id: 8700, s: "GRF", n: "Eagle Capital Growth Fund Inc", c: "US" },
  { id: 8701, s: "HILS.L", n: "Hill & Smith Holdings PLC", c: "UK" },
  { id: 8702, s: "8150.TW", n: "ChipMOS Technologies", c: "TW" },
  { id: 8703, s: "BGD.AX", n: "Barton Gold Holdings", c: "Australia" },
  { id: 8704, s: "HNRA", n: "HNR Acquisition Co", c: "US" },
  { id: 8705, s: "QXR.AX", n: "Qx Resources", c: "Australia" },
  { id: 8706, s: "RIT1.TA", n: "Reit 1", c: "Israel" },
  { id: 8707, s: "EAST.ST", n: "Eastnine AB (publ)", c: "Sweden" },
  { id: 8708, s: "600754.SS", n: "Shanghai Jin Jiang International Hotels Development Co A", c: "CH" },
  { id: 8709, s: "FMS.V", n: "Focus Graphite Inc", c: "CA" },
  { id: 8710, s: "HNW", n: "Pioneer Diversified High IT", c: "US" },
  { id: 8711, s: "SMRTG.IS", n: "Smart Gunes Enerjisi Teknolojileri Arastirma Gelistirme Uretim Sanayi ve Ticaret", c: "Turkey" },
  { id: 8712, s: "NHF.AX", n: "NIB Holdings", c: "Australia" },
  { id: 8713, s: "BSL.DE", n: "Basler Aktiengesellschaft", c: "GER" },
  { id: 8714, s: "AMP.MC", n: "Amper S.A.", c: "Spain" },
  { id: 8715, s: "TRJ.AX", n: "Trajan Group Holdings", c: "Australia" },
  { id: 8716, s: "GRLT", n: "Grillit Inc.", c: "US" },
  { id: 8717, s: "REE.AX", n: "Rarex", c: "Australia" },
  { id: 8718, s: "139480.KS", n: "E-Mart", c: "Korea" },
  { id: 8719, s: "ASCCY", n: "Asics Corp ADR", c: "US" },
  { id: 8720, s: "CDEV.TA", n: "Cohen Dev", c: "Israel" },
  { id: 8721, s: "COWI", n: "Coroware Inc", c: "US" },
  { id: 8722, s: "ABVX.PA", n: "Abivax SA", c: "FR" },
  { id: 8723, s: "GEGR", n: "National Health Scan", c: "US" },
  { id: 8724, s: "NUGL", n: "Coresource Strategies Inc", c: "US" },
  { id: 8725, s: "LAT1V.HE", n: "Lassila &Tikanoja Oyj", c: "Finland" },
  { id: 8726, s: "SSDOY", n: "Shiseido Company", c: "US" },
  { id: 8727, s: "INVE-A.ST", n: "Investor AB ser. A", c: "Sweden" },
  { id: 8728, s: "NBO", n: "Neuberger Berman New York IMF Inc", c: "US" },
  { id: 8729, s: "NNIT.", n: "Nnit AS", c: "Denmark" },
  { id: 8730, s: "QOIL", n: "Quest Oil Co", c: "US" },
  { id: 8731, s: "PLAN.SW", n: "Plazza AG", c: "Switzerland" },
  { id: 8732, s: "AWKN.NE", n: "Awakn Life Sciences Co", c: "CA" },
  { id: 8733, s: "LWDB.L", n: "Law Debenture Co", c: "UK" },
  { id: 8734, s: "600346.SS", n: "Hengli Petrochemical", c: "CH" },
  { id: 8735, s: "A4Y.DE", n: "Accentro Real Estate AG", c: "GER" },
  { id: 8736, s: "1736.TW", n: "Johnson Health Tech", c: "TW" },
  { id: 8737, s: "000751.SZ", n: "Huludao Zinc Industry", c: "CH" },
  { id: 8738, s: "SHLE.TO", n: "Source Energy Services", c: "CA" },
  { id: 8739, s: "073190.KQ", n: "Duoback Co.", c: "Korea" },
  { id: 8740, s: "APE.AX", n: "AP Eagers", c: "Australia" },
  { id: 8741, s: "CSSEN", n: "Chicken Soup for The Soul Entertainment 9 50 Notes due 2025", c: "US" },
  { id: 8742, s: "ZLNDY", n: "ZALANDO SE ADR", c: "US" },
  { id: 8743, s: "ICAD.PA", n: "Icade SA", c: "FR" },
  { id: 8744, s: "RBSH", n: "Rebus Holdings Inc.", c: "US" },
  { id: 8745, s: "ITECH.ST", n: "I-Tech", c: "Sweden" },
  { id: 8746, s: "PAT.DE", n: "Patrizia AG", c: "GER" },
  { id: 8747, s: "MPVD.TO", n: "Mountain Province Diamonds Inc", c: "CA" },
  { id: 8748, s: "000709.SZ", n: "HBIS", c: "CH" },
  { id: 8749, s: "SSLZY", n: "Santos ADR", c: "US" },
  { id: 8750, s: "DGICB", n: "Donegal Group B Inc", c: "US" },
  { id: 8751, s: "AMADY", n: "Amadeus IT Holding SA PK", c: "US" },
  { id: 8752, s: "TRE.MC", n: "Tecnicas Reunidas", c: "Spain" },
  { id: 8753, s: "BNEFF", n: "Bonterra Energy Co", c: "US" },
  { id: 8754, s: "DTD2.DE", n: "B+S Banksysteme Aktiengesellschaft", c: "GER" },
  { id: 8755, s: "ILCO.TA", n: "Israel Co", c: "Israel" },
  { id: 8756, s: "BAS.AX", n: "Bass Strait Oil", c: "Australia" },
  { id: 8757, s: "688036.SS", n: "Shenzhen Transsion Holdings ", c: "CH" },
  { id: 8758, s: "PGLD.V", n: "P2 Gold Inc", c: "CA" },
  { id: 8759, s: "NN6.DE", n: "NanoRepro AG", c: "GER" },
  { id: 8760, s: "011070.KS", n: "LG Innotek", c: "Korea" },
  { id: 8761, s: "HBRIY", n: "Harbour Energy PLC ADR", c: "US" },
  { id: 8762, s: "POSI3.SA", n: "Positivo Tecnologia S.A", c: "Brazil" },
  { id: 8763, s: "000425.SZ", n: "XCMG Construction Machinery", c: "CH" },
  { id: 8764, s: "AHEXY", n: "Adecco Group Inc", c: "US" },
  { id: 8765, s: "4760.TWO", n: "Ample Electronic Technology", c: "TW" },
  { id: 8766, s: "WAC.DE", n: "Wacker Neuson SE", c: "GER" },
  { id: 8767, s: "SMHN.DE", n: "SÜSS MicroTec SE", c: "GER" },
  { id: 8768, s: "CBUMY", n: "CH National Building Material Co ADR", c: "US" },
  { id: 8769, s: "RSRV", n: "The Reserve Petroleum Company", c: "US" },
  { id: 8770, s: "VTN", n: "Invesco Trust For Inv Grade NY Munis", c: "US" },
  { id: 8771, s: "TATE.L", n: "Tate & Lyle PLC", c: "UK" },
  { id: 8772, s: "SUR.F", n: "Surteco Group SE", c: "GER" },
  { id: 8773, s: "NEU.WA", n: "Neuca S.A.", c: "Poland" },
  { id: 8774, s: "ICEAIR.IC", n: "Icelandair Group hf", c: "Iceland" },
  { id: 8775, s: "OCANF", n: "OceanaGold Corporation", c: "US" },
  { id: 8776, s: "ANHYT.IS", n: "Anadolu Hayat Emeklilik AS", c: "Turkey" },
  { id: 8777, s: "HFD.L", n: "Halfords Group PLC", c: "UK" },
  { id: 8778, s: "BVFL", n: "BV Financial Inc. Common Stock", c: "US" },
  { id: 8779, s: "NFTA.TA", n: "Naphtha", c: "Israel" },
  { id: 8780, s: "HLI.AX", n: "Helia Group Limited", c: "Australia" },
  { id: 8781, s: "000530.SZ", n: "Bingshan Refrigeration & Heat Transfer Technologies", c: "CH" },
  { id: 8782, s: "SOL.AX", n: "Washington H Soul Pattinson&", c: "Australia" },
  { id: 8783, s: "002558.SZ", n: "Giant Network Group", c: "CH" },
  { id: 8784, s: "ARPL.ST", n: "Arla Plast AB", c: "Sweden" },
  { id: 8785, s: "MCIC", n: "Multicorp Intl Inc.", c: "US" },
  { id: 8786, s: "STM.DE", n: "Stabilus S.A.", c: "GER" },
  { id: 8787, s: "MAPS.V", n: "Prostar Holdings Inc", c: "CA" },
  { id: 8788, s: "PAGERO.ST", n: "Pagero Group AB (Publ)", c: "Sweden" },
  { id: 8789, s: "CTT.AX", n: "Cettire Ltd", c: "Australia" },
  { id: 8790, s: "GCAN", n: "Greater Cannabis Company Inc", c: "US" },
  { id: 8791, s: "PAF.L", n: "Pan African Resources PLC", c: "UK" },
  { id: 8792, s: "VOX.WA", n: "Voxel S.A.", c: "Poland" },
  { id: 8793, s: "GBHL", n: "Global Entertainment Holdings Inc", c: "US" },
  { id: 8794, s: "COP.DE", n: "Compugroup Medical SE & Co. KGaA", c: "GER" },
  { id: 8795, s: "AMC.WA", n: "Amica S.A.", c: "Poland" },
  { id: 8796, s: "ASMLF", n: "ASML Holding NV", c: "US" },
  { id: 8797, s: "IBAB.BR", n: "Ion Beam Applications SA", c: "Belgium" },
  { id: 8798, s: "012330.KS", n: "Hyundai Mobis", c: "Korea" },
  { id: 8799, s: "VINCIT.HE", n: "Vincit Group Oyj", c: "Finland" },
  { id: 8800, s: "CBL.AX", n: "Control Bionics", c: "Australia" },
  { id: 8801, s: "CCE.AX", n: "Carnegie Clean Energy", c: "Australia" },
  { id: 8802, s: "WHSI", n: "Wearable Health Solutions Inc", c: "US" },
  { id: 8803, s: "DATA.L", n: "GlobalData PLC", c: "UK" },
  { id: 8804, s: "CPWR", n: "Ocean Thermal Energy Co", c: "US" },
  { id: 8805, s: "ALLT.TA", n: "Allot Communications", c: "Israel" },
  { id: 8806, s: "TUB.BR", n: "Tubize-Fin", c: "Belgium" },
  { id: 8807, s: "SAXPY", n: "Sampo OYJ", c: "US" },
  { id: 8808, s: "AVNT.TO", n: "Avant Brands Inc", c: "CA" },
  { id: 8809, s: "UKOG.L", n: "UK Oil & Gas PLC", c: "UK" },
  { id: 8810, s: "FLOW.TO", n: "Flow Beverage Co", c: "CA" },
  { id: 8811, s: "E4C.DE", n: "ecotel communication ag", c: "GER" },
  { id: 8812, s: "MOB.AX", n: "Mobilicom", c: "Australia" },
  { id: 8813, s: "PROT.OL", n: "Protector Forsikring ASA", c: "Norway" },
  { id: 8814, s: "SBET", n: "Sharplink Gaming", c: "US" },
  { id: 8815, s: "SOJD", n: "Southern", c: "US" },
  { id: 8816, s: "300122.SZ", n: "Chongqing Zhifei Bio Products", c: "CH" },
  { id: 8817, s: "BCJ.SW", n: "Banque Cantonale Du Jura SA", c: "Switzerland" },
  { id: 8818, s: "SHOW3.SA", n: "T4F Entretenimento S.A", c: "Brazil" },
  { id: 8819, s: "RAI.L", n: "RA International Group", c: "UK" },
  { id: 8820, s: "BEGI", n: "Blackstar Enterprise Group Inc", c: "US" },
  { id: 8821, s: "688180.SS", n: "Shanghai Junshi Biosciences", c: "CH" },
  { id: 8822, s: "IFISH.OL", n: "Ice Fish Farm AS", c: "Norway" },
  { id: 8823, s: "CLDN.L", n: "Caledonia Investments", c: "UK" },
  { id: 8824, s: "PTU.V", n: "Purepoint Uranium Group Inc", c: "CA" },
  { id: 8825, s: "300015.SZ", n: "Aier Eye Hospital Group", c: "CH" },
  { id: 8826, s: "MVES", n: "Movie Studio Inc", c: "US" },
  { id: 8827, s: "TNOM.HE", n: "Talenom Oyj", c: "Finland" },
  { id: 8828, s: "BACHF", n: "Bank of CH H", c: "US" },
  { id: 8829, s: "CZMWY", n: "Carl Zeiss Meditec AG ADR", c: "US" },
  { id: 8830, s: "FMC.V", n: "Forum Energy Metals Co", c: "CA" },
  { id: 8831, s: "6533.TW", n: "Andes Technology Co", c: "TW" },
  { id: 8832, s: "ARIX.L", n: "Arix Bioscience PLC", c: "UK" },
  { id: 8833, s: "GDC.TO", n: "Genesis Land Development Corp.", c: "CA" },
  { id: 8834, s: "600100.SS", n: "Tsinghuatongfang", c: "CH" },
  { id: 8835, s: "RKR.V", n: "ROKMASTER Resources Co", c: "CA" },
  { id: 8836, s: "MSV.TO", n: "Minco Silver Corporation", c: "CA" },
  { id: 8837, s: "SHA.F", n: "Schaeffler AG", c: "GER" },
  { id: 8838, s: "CLOW", n: "Cloudweb Inc", c: "US" },
  { id: 8839, s: "032640.KS", n: "LG Uplus", c: "Korea" },
  { id: 8840, s: "AR1.AX", n: "Austral Resources Australia", c: "Australia" },
  { id: 8841, s: "NVAC", n: "Northview Acquisition Co", c: "US" },
  { id: 8842, s: "PSG.MC", n: "Prosegur", c: "Spain" },
  { id: 8843, s: "YOU.L", n: "YouGov plc", c: "UK" },
  { id: 8844, s: "GFRD.L", n: "Galliford Try PLC", c: "UK" },
  { id: 8845, s: "TDVOX.ST", n: "Tobii Dynavox AB", c: "Sweden" },
  { id: 8846, s: "ALKA.IS", n: "Alkim Kagit Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 8847, s: "688681.SS", n: "Shandong Kehui Power Automation", c: "CH" },
  { id: 8848, s: "RBT.PA", n: "Robertet SA", c: "FR" },
  { id: 8849, s: "688700.SS", n: "Kunshan Dongwei Technology", c: "CH" },
  { id: 8850, s: "VEFAB.ST", n: "Vef AB", c: "Sweden" },
  { id: 8851, s: "AALR3.SA", n: "Centro de Imagem Diagnósticos S.A", c: "Brazil" },
  { id: 8852, s: "ARL.AX", n: "Ardea Resources", c: "Australia" },
  { id: 8853, s: "JMPLY", n: "Johnson Matthey PLC", c: "US" },
  { id: 8854, s: "HEAD.L", n: "Headlam Group", c: "UK" },
  { id: 8855, s: "ARL.DE", n: "Aareal Bank AG", c: "GER" },
  { id: 8856, s: "HGO.AX", n: "Hillgrove Resources", c: "Australia" },
  { id: 8857, s: "MAREL.IC", n: "Marel hf", c: "Iceland" },
  { id: 8858, s: "HOTC.L", n: "Hotel Chocolat Group PLC", c: "UK" },
  { id: 8859, s: "ITP.PA", n: "Interparfums SA", c: "FR" },
  { id: 8860, s: "300243.SZ", n: "Shandong Ruifeng Chemical", c: "CH" },
  { id: 8861, s: "NWC.AX", n: "New World Resources Limited", c: "Australia" },
  { id: 8862, s: "28513K.KS", n: "SK Chemicals Co Pref", c: "Korea" },
  { id: 8863, s: "RHM.F", n: "Rheinmetall AG", c: "GER" },
  { id: 8864, s: "QDT.PA", n: "Quadient SA", c: "FR" },
  { id: 8865, s: "ALC.TO", n: "Algoma Central Corporation", c: "CA" },
  { id: 8866, s: "SPEONE.ST", n: "Spectrumone publ AB", c: "Sweden" },
  { id: 8867, s: "2371.TW", n: "Tatung", c: "TW" },
  { id: 8868, s: "VH2.DE", n: "Friedrich Vorwerk Group SE", c: "GER" },
  { id: 8869, s: "TRST.L", n: "Trustpilot Group PLC", c: "UK" },
  { id: 8870, s: "VESBE.IS", n: "Vestel Beyaz Esya Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 8871, s: "SAAS.L", n: "Microlise Group PLC", c: "UK" },
  { id: 8872, s: "FCIT.L", n: "F&C Investment Trust PLC", c: "UK" },
  { id: 8873, s: "002278.SZ", n: "Shanghai SK Petroleum & Chemical Equipment Co", c: "CH" },
  { id: 8874, s: "VKSC", n: "Viskase Companies Inc", c: "US" },
  { id: 8875, s: "090430.KS", n: "Amorepacific", c: "Korea" },
  { id: 8876, s: "TGHI", n: "Touchpoint Group Holdings Inc", c: "US" },
  { id: 8877, s: "INTE", n: "Integral Acquisition 1 Co", c: "US" },
  { id: 8878, s: "PRQ.TO", n: "Petrus Resources", c: "CA" },
  { id: 8879, s: "1402.TW", n: "Far Eastern New Century Co", c: "TW" },
  { id: 8880, s: "1108.TW", n: "Lucky Cement", c: "TW" },
  { id: 8881, s: "CRFSA.IS", n: "CarrefourSA Carrefour Sabanci Ticaret Merkezi AS", c: "Turkey" },
  { id: 8882, s: "EPY.AX", n: "Earlypay", c: "Australia" },
  { id: 8883, s: "TGLVY", n: "Top Glove Corporation Bhd ADR", c: "US" },
  { id: 8884, s: "AFI.AX", n: "Australian Foundation Investment Company", c: "Australia" },
  { id: 8885, s: "TOTDY", n: "Toto", c: "US" },
  { id: 8886, s: "002008.SZ", n: "Han'S Laser Tech A", c: "CH" },
  { id: 8887, s: "FTEG", n: "For The Earth Co", c: "US" },
  { id: 8888, s: "AEE.AX", n: "Aura Energy", c: "Australia" },
  { id: 8889, s: "RVX.TO", n: "Resverlogix Corp.", c: "CA" },
  { id: 8890, s: "FLUI.ST", n: "Fluicell AB", c: "Sweden" },
  { id: 8891, s: "KLARA-B.ST", n: "KlaraBo Sverige AB Series B", c: "Sweden" },
  { id: 8892, s: "NSP.V", n: "Naturally Splendid Enterprises", c: "CA" },
  { id: 8893, s: "WML.V", n: "Wealth Minerals.", c: "CA" },
  { id: 8894, s: "LRENY", n: "Lojas Renner SA ADR", c: "US" },
  { id: 8895, s: "BMAX.ST", n: "Byggmax Group AB", c: "Sweden" },
  { id: 8896, s: "GNCP", n: "Gncc Capital Inc.", c: "US" },
  { id: 8897, s: "BIOWKS.ST", n: "Bio-Works Technologies AB", c: "Sweden" },
  { id: 8898, s: "PCFBY", n: "Pacific Basin Shipping ADR", c: "US" },
  { id: 8899, s: "EGR.AX", n: "EcoGraf", c: "Australia" },
  { id: 8900, s: "MARS.L", n: "Marston’s PLC", c: "UK" },
  { id: 8901, s: "HOFI.ST", n: "Hoist Finance AB", c: "Sweden" },
  { id: 8902, s: "FNM.ST", n: "Ferronordic AB (publ)", c: "Sweden" },
  { id: 8903, s: "MERY.PA", n: "Mercialys SA", c: "FR" },
  { id: 8904, s: "JDO.AX", n: "Judo Capital Holdings", c: "Australia" },
  { id: 8905, s: "VULC3.SA", n: "Vulcabras Azaleia S.A", c: "Brazil" },
  { id: 8906, s: "PNRG.TA", n: "Phinergy", c: "Israel" },
  { id: 8907, s: "KORDS.IS", n: "Kordsa Global Endustriyel Iplik ve Kord Bezi Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 8908, s: "TMMI", n: "T M M Inc New", c: "US" },
  { id: 8909, s: "MACF.L", n: "Macfarlane Group PLC", c: "UK" },
  { id: 8910, s: "ATEK", n: "Athena Technology Acquisition Corp. II", c: "US" },
  { id: 8911, s: "000661.SZ", n: "Changchun High & New Technology Industries Group Inc", c: "CH" },
  { id: 8912, s: "RAIFY", n: "Raiffeisen Bank International AG ADR", c: "US" },
  { id: 8913, s: "099190.KQ", n: "i-SENS Inc", c: "Korea" },
  { id: 8914, s: "GQG.AX", n: "GQG Partners Inc DRC", c: "Australia" },
  { id: 8915, s: "ARG.PA", n: "Argan SA", c: "FR" },
  { id: 8916, s: "RFX.L", n: "Ramsdens Holdings PLC", c: "UK" },
  { id: 8917, s: "CAMT.TA", n: "Camtek", c: "Israel" },
  { id: 8918, s: "AKR.V", n: "Ackroo Inc.", c: "CA" },
  { id: 8919, s: "WIRTEK.", n: "Wirtek A/S", c: "Denmark" },
  { id: 8920, s: "SOP.PA", n: "Sopra Steria Group SA", c: "FR" },
  { id: 8921, s: "SANOMA.HE", n: "Sanoma Oyj", c: "Finland" },
  { id: 8922, s: "YOW.AX", n: "Yowie Group", c: "Australia" },
  { id: 8923, s: "688006.SS", n: "Zhejiang HangKe Technology", c: "CH" },
  { id: 8924, s: "COV.V", n: "Covalon Technologies.", c: "CA" },
  { id: 8925, s: "BLC.PA", n: "Bastide Le Confort Médical SA", c: "FR" },
  { id: 8926, s: "688021.SS", n: "Aofu Environmental Technology", c: "CH" },
  { id: 8927, s: "QINTER.", n: "Q Interline AS", c: "Denmark" },
  { id: 8928, s: "2851.TW", n: "Central Reinsurance Co", c: "TW" },
  { id: 8929, s: "BLTG.L", n: "Blancco Technology Group plc", c: "UK" },
  { id: 8930, s: "DOGEF", n: "Orsted A/S", c: "US" },
  { id: 8931, s: "GIGA.V", n: "Giga Metals Co", c: "CA" },
  { id: 8932, s: "SEGI", n: "Sycamore Entmt Grp", c: "US" },
  { id: 8933, s: "OXSQL", n: "Oxford Square Capital Corp ELKS", c: "US" },
  { id: 8934, s: "CPHRF", n: "Cipher Pharmaceuticals Inc", c: "US" },
  { id: 8935, s: "NDVAF", n: "Indiva", c: "US" },
  { id: 8936, s: "CCRO3.SA", n: "CCR S.A", c: "Brazil" },
  { id: 8937, s: "IOS.DE", n: "IONOS GROUP N", c: "GER" },
  { id: 8938, s: "CPCAY", n: "Cathay Pacific Airways ADR", c: "US" },
  { id: 8939, s: "IMD.AX", n: "Imdex", c: "Australia" },
  { id: 8940, s: "300626.SZ", n: "Huarui Electrical Appliance", c: "CH" },
  { id: 8941, s: "002271.SZ", n: "Beijing Oriental Yuhong Waterproof Technology", c: "CH" },
  { id: 8942, s: "BZYR", n: "Burzynski Research", c: "US" },
  { id: 8943, s: "MYI.L", n: "Murray International Trust", c: "UK" },
  { id: 8944, s: "HEBA-B.ST", n: "HEBA Fastighets AB (publ)", c: "Sweden" },
  { id: 8945, s: "RCI-A.TO", n: "Rogers Communications Inc", c: "CA" },
  { id: 8946, s: "047050.KS", n: "Posco International Co", c: "Korea" },
  { id: 8947, s: "VR1.AX", n: "Vection Technologies", c: "Australia" },
  { id: 8948, s: "AXPWQ", n: "Axion Power International Inc", c: "US" },
  { id: 8949, s: "TACBY", n: "Tabcorp Holdings ADR", c: "US" },
  { id: 8950, s: "LOU.V", n: "Lucero Energy Co", c: "CA" },
  { id: 8951, s: "RUA.L", n: "Rua Life Sciences PLC", c: "UK" },
  { id: 8952, s: "SCP.LS", n: "Sporting Clube de Portugal - Futebol SAD", c: "Portugal" },
  { id: 8953, s: "EQS.DE", n: "EQS Group AG", c: "GER" },
  { id: 8954, s: "TOELF", n: "Tokyo Electron.", c: "US" },
  { id: 8955, s: "VSAC", n: "Vision Sensing Acquisition Co", c: "US" },
  { id: 8956, s: "CRI.PA", n: "Chargeurs S.A.", c: "FR" },
  { id: 8957, s: "KNR.NE", n: "Kontrol Energy Co", c: "CA" },
  { id: 8958, s: "ALBPS.PA", n: "Biophytis S.A.", c: "FR" },
  { id: 8959, s: "TORM", n: "TOR Minerals International Inc", c: "US" },
  { id: 8960, s: "ECOR3.SA", n: "EcoRodovias Infraestrutura e Logística S.A", c: "Brazil" },
  { id: 8961, s: "033200.KQ", n: "Moatech Co.", c: "Korea" },
  { id: 8962, s: "034020.KS", n: "Doosan Heavy Ind. & Const.", c: "Korea" },
  { id: 8963, s: "OEZVY", n: "Verbund AG ADR", c: "US" },
  { id: 8964, s: "BEN.AX", n: "Bendigo And Adelaide Bank", c: "Australia" },
  { id: 8965, s: "4114.TWO", n: "Synmosa Biopharma", c: "TW" },
  { id: 8966, s: "CYL.AX", n: "Catalyst Metals", c: "Australia" },
  { id: 8967, s: "BLKB.SW", n: "Basellandschaftliche Kantonalbank", c: "Switzerland" },
  { id: 8968, s: "BEKN.SW", n: "Berner Kantonalbank AG", c: "Switzerland" },
  { id: 8969, s: "EVM", n: "Eaton Vance California MBF", c: "US" },
  { id: 8970, s: "PARRO.PA", n: "Parrot", c: "FR" },
  { id: 8971, s: "OEM-B.ST", n: "OEM International AB (publ)", c: "Sweden" },
  { id: 8972, s: "ACAH", n: "Atlantic Coastal Acquisition Co", c: "US" },
  { id: 8973, s: "073240.KS", n: "Kumho Tire", c: "Korea" },
  { id: 8974, s: "WKP.L", n: "Workspace Group PLC", c: "UK" },
  { id: 8975, s: "QLIFE.ST", n: "Qlife Holding AB", c: "Sweden" },
  { id: 8976, s: "PSCAND.ST", n: "Permascand Top Holding AB", c: "Sweden" },
  { id: 8977, s: "AVON.L", n: "Avon Protection PLC", c: "UK" },
  { id: 8978, s: "NOLA-B.ST", n: "Nolato AB (publ)", c: "Sweden" },
  { id: 8979, s: "MTRS.ST", n: "Munters Group AB", c: "Sweden" },
  { id: 8980, s: "UGPA3.SA", n: "Ultrapar Participações S.A", c: "Brazil" },
  { id: 8981, s: "HNR.AX", n: "Hannans Reward", c: "Australia" },
  { id: 8982, s: "LZAGY", n: "Lonza Group AG", c: "US" },
  { id: 8983, s: "MGON", n: "Megola Inc", c: "US" },
  { id: 8984, s: "BSR.V", n: "Bluestone Resources Inc", c: "CA" },
  { id: 8985, s: "KRDMA.IS", n: "Kardemir Karabuk Demir Celik Sanayi ve Ticaret AS Class A", c: "Turkey" },
  { id: 8986, s: "ANN.AX", n: "Ansell", c: "Australia" },
  { id: 8987, s: "DHG.AX", n: "Domain Holdings Australia", c: "Australia" },
  { id: 8988, s: "TRUMY", n: "Terumo Corp ADR", c: "US" },
  { id: 8989, s: "DSM.V", n: "Deep South Resources Inc", c: "CA" },
  { id: 8990, s: "AR9.AX", n: "Archtis", c: "Australia" },
  { id: 8991, s: "MSPC", n: "Metrospaces Inc", c: "US" },
  { id: 8992, s: "KC.V", n: "Kutcho Copper Co", c: "CA" },
  { id: 8993, s: "92E.AX", n: "92 Energy", c: "Australia" },
  { id: 8994, s: "BLG.AX", n: "BluGlass", c: "Australia" },
  { id: 8995, s: "NBW", n: "Neuberger Berman California IMF Inc", c: "US" },
  { id: 8996, s: "ACL.AX", n: "Australian Clinical Labs", c: "Australia" },
  { id: 8997, s: "010140.KS", n: "Samsung Heavy Industries", c: "Korea" },
  { id: 8998, s: "PEAN.SW", n: "Peach Property Group AG", c: "Switzerland" },
  { id: 8999, s: "AL.V", n: "ALX Uranium Co", c: "CA" },
  { id: 9000, s: "MOBN.SW", n: "Mobimo Hldg", c: "Switzerland" },
  { id: 9001, s: "DBAN.DE", n: "Deutsche Beteiligungs AG", c: "GER" },
  { id: 9002, s: "CDD.AX", n: "Cardno", c: "Australia" },
  { id: 9003, s: "NBEN.SW", n: "Nebag ag", c: "Switzerland" },
  { id: 9004, s: "HOME.MC", n: "Neinor Homes SLU", c: "Spain" },
  { id: 9005, s: "3131.TWO", n: "Grand Plastic Technology", c: "TW" },
  { id: 9006, s: "GEST.MC", n: "Gestamp Automocion SA", c: "Spain" },
  { id: 9007, s: "ALRIB.PA", n: "Riber S.A", c: "FR" },
  { id: 9008, s: "IMI.L", n: "IMI PLC", c: "UK" },
  { id: 9009, s: "GROW.V", n: "CO2 Gro Inc", c: "CA" },
  { id: 9010, s: "ECPN", n: "El Capitan Prec Mtls", c: "US" },
  { id: 9011, s: "MQBKY", n: "Macquarie Group ADR", c: "US" },
  { id: 9012, s: "JHSF3.SA", n: "JHSF Participações S.A", c: "Brazil" },
  { id: 9013, s: "MIKO.BR", n: "Miko NV", c: "Belgium" },
  { id: 9014, s: "DTL.AX", n: "Data#3", c: "Australia" },
  { id: 9015, s: "CLV.AX", n: "Clover Corporation", c: "Australia" },
  { id: 9016, s: "006650.KS", n: "Korea Petro Chemical Ind", c: "Korea" },
  { id: 9017, s: "IFX.F", n: "Infineon Technologies AG", c: "GER" },
  { id: 9018, s: "TKFEN.IS", n: "Tekfen Holding AS", c: "Turkey" },
  { id: 9019, s: "CUU.V", n: "Copper Fox Metals Inc.", c: "CA" },
  { id: 9020, s: "AKO.AX", n: "Akora Resources", c: "Australia" },
  { id: 9021, s: "DLB.SG", n: "DLB-Anlageservice AG", c: "GER" },
  { id: 9022, s: "300638.SZ", n: "Fibocom Wireless Inc", c: "CH" },
  { id: 9023, s: "AMC.AX", n: "Amcor PLC", c: "Australia" },
  { id: 9024, s: "AFP.SW", n: "Aluflexpack AG", c: "Switzerland" },
  { id: 9025, s: "ION.V", n: "Ion Energy", c: "CA" },
  { id: 9026, s: "STBP3.SA", n: "Santos Brasil Participações S.A", c: "Brazil" },
  { id: 9027, s: "NBA.LS", n: "Novabase SGPS", c: "Portugal" },
  { id: 9028, s: "UKCM.L", n: "UK Commercial Property Trust", c: "UK" },
  { id: 9029, s: "AXW.PA", n: "Axway Software", c: "FR" },
  { id: 9030, s: "MONDY", n: "Mondi PLC ADR", c: "US" },
  { id: 9031, s: "GCO.MC", n: "Grupo Catalana Occidente", c: "Spain" },
  { id: 9032, s: "TEP.L", n: "Telecom Plus PLC", c: "UK" },
  { id: 9033, s: "M5Z.DE", n: "Manz AG", c: "GER" },
  { id: 9034, s: "KNRS.SW", n: "Kinarus Therapeutics Holding AG", c: "Switzerland" },
  { id: 9035, s: "PAOG", n: "Pao Group Inc", c: "US" },
  { id: 9036, s: "APELY", n: "Alps Electric", c: "US" },
  { id: 9037, s: "GBZ.AX", n: "GBM Resources", c: "Australia" },
  { id: 9038, s: "JHG.AX", n: "Janus Henderson Group PLC DRC", c: "Australia" },
  { id: 9039, s: "LGBS", n: "Legends Business Grp", c: "US" },
  { id: 9040, s: "SFAST.ST", n: "Stenhus Fastigheter I Norden AB", c: "Sweden" },
  { id: 9041, s: "RECSI.OL", n: "REC Silicon ASA", c: "Norway" },
  { id: 9042, s: "BMXC", n: "Bemax Inc", c: "US" },
  { id: 9043, s: "GBG.L", n: "GB Group plc", c: "UK" },
  { id: 9044, s: "HLMS.TA", n: "Holmes Place International", c: "Israel" },
  { id: 9045, s: "GRI.L", n: "Grainger PLC", c: "UK" },
  { id: 9046, s: "LEL.AX", n: "Lithium Energy", c: "Australia" },
  { id: 9047, s: "BOTY", n: "Lingerie Fighting Championships Inc", c: "US" },
  { id: 9048, s: "688981.SS", n: "Semiconductor Manufacturing Intl", c: "CH" },
  { id: 9049, s: "INCR.TA", n: "Intercure", c: "Israel" },
  { id: 9050, s: "BPIRY", n: "Piraeus Bank SA ADR", c: "US" },
  { id: 9051, s: "SNL.AX", n: "Supply Network", c: "Australia" },
  { id: 9052, s: "TRAIN-B.ST", n: "Train Alliance Sweden AB Series B", c: "Sweden" },
  { id: 9053, s: "2388.TW", n: "VIA Technologies Inc", c: "TW" },
  { id: 9054, s: "STG.AX", n: "Straker Limited", c: "Australia" },
  { id: 9055, s: "BRVO", n: "Bravo Multinational Inc", c: "US" },
  { id: 9056, s: "STEF-B.ST", n: "Stendörren Fastigheter AB (publ)", c: "Sweden" },
  { id: 9057, s: "KRI.AT", n: "Kri-Kri Milk Industry S.A", c: "Greece" },
  { id: 9058, s: "HZM.L", n: "Horizonte Minerals", c: "UK" },
  { id: 9059, s: "AVCT.L", n: "Avacta Group PLC", c: "UK" },
  { id: 9060, s: "SAUHY", n: "Straumann Holding AG ADR", c: "US" },
  { id: 9061, s: "PTXKY", n: "XL Axiata Tbk PT ADR", c: "US" },
  { id: 9062, s: "1218.TW", n: "Taisun Enterprise", c: "TW" },
  { id: 9063, s: "TOR.WA", n: "Torpol SA", c: "Poland" },
  { id: 9064, s: "BLOE.L", n: "Block Energy PLC", c: "UK" },
  { id: 9065, s: "GVC.TO", n: "Glacier Media Inc.", c: "CA" },
  { id: 9066, s: "PORT", n: "Southport Acquisition Co", c: "US" },
  { id: 9067, s: "PAY.L", n: "PayPoint plc", c: "UK" },
  { id: 9068, s: "RCP.L", n: "RIT Capital Partners", c: "UK" },
  { id: 9069, s: "WHK.AX", n: "WhiteHawk", c: "Australia" },
  { id: 9070, s: "VESTL.IS", n: "Vestel Elektronik Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 9071, s: "6577.TWO", n: "Promate Solutions", c: "TW" },
  { id: 9072, s: "PRRY", n: "Planet Resource Re", c: "US" },
  { id: 9073, s: "GRR.AX", n: "Grange Resources Limited", c: "Australia" },
  { id: 9074, s: "APR.WA", n: "Auto Partner SA", c: "Poland" },
  { id: 9075, s: "EXL.DE", n: "Exasol AG", c: "GER" },
  { id: 9076, s: "PTBS", n: "Potomac Bancshares Inc", c: "US" },
  { id: 9077, s: "THST", n: "Truett-Hurst Inc", c: "US" },
  { id: 9078, s: "CGGYY", n: "CGG SA ADR", c: "US" },
  { id: 9079, s: "APN.WA", n: "Aplisens SA", c: "Poland" },
  { id: 9080, s: "ADV.DE", n: "Adva Optical Networking SE", c: "GER" },
  { id: 9081, s: "AKEL-D.ST", n: "Akelius Residential Property AB (publ)", c: "Sweden" },
  { id: 9082, s: "002352.SZ", n: "S.F. Holding Co.", c: "CH" },
  { id: 9083, s: "600383.SS", n: "Gemdale Co", c: "CH" },
  { id: 9084, s: "6548.TWO", n: "Chang Wah Technology", c: "TW" },
  { id: 9085, s: "HNNMY", n: "H & M Hennes & Mauritz AB ADR", c: "US" },
  { id: 9086, s: "TORC.V", n: "TinOne Resources Inc", c: "CA" },
  { id: 9087, s: "HEM.ST", n: "Hemnet Group AB", c: "Sweden" },
  { id: 9088, s: "GREEN.ST", n: "Green Landscaping Group AB (publ)", c: "Sweden" },
  { id: 9089, s: "000937.SZ", n: "Jizhong Energy Resources", c: "CH" },
  { id: 9090, s: "PETKM.IS", n: "Petkim Petrokimya Holding AS", c: "Turkey" },
  { id: 9091, s: "IRRX", n: "Integrated Rail and Resources Acquisition Co", c: "US" },
  { id: 9092, s: "ACRO", n: "Acropolis Infrastructure Acquisition Co", c: "US" },
  { id: 9093, s: "QLTU.TA", n: "Qualitau", c: "Israel" },
  { id: 9094, s: "MOTR.L", n: "Motorpoint Group PLC", c: "UK" },
  { id: 9095, s: "1232.TW", n: "TTET Union Co", c: "TW" },
  { id: 9096, s: "VCX.AX", n: "Vicinity Centres Re", c: "Australia" },
  { id: 9097, s: "PUBC", n: "PureBase Co", c: "US" },
  { id: 9098, s: "IVSO.ST", n: "Invisio Communications AB", c: "Sweden" },
  { id: 9099, s: "CAWW", n: "CCA Industries Inc", c: "US" },
  { id: 9100, s: "SOLT.ST", n: "SolTech Energy Sweden AB", c: "Sweden" },
  { id: 9101, s: "IMM.L", n: "ImmuPharma PLC", c: "UK" },
  { id: 9102, s: "WAS.WA", n: "Wasko S.A.", c: "Poland" },
  { id: 9103, s: "SIVE.ST", n: "Sivers IMA Holding AB", c: "Sweden" },
  { id: 9104, s: "NTI.OL", n: "Norsk Titanium AS", c: "Norway" },
  { id: 9105, s: "TRK.WA", n: "Trakcja PRKiI S.A.", c: "Poland" },
  { id: 9106, s: "HUM.ST", n: "Humana AB (publ)", c: "Sweden" },
  { id: 9107, s: "TSK.TO", n: "Talisker Resources", c: "CA" },
  { id: 9108, s: "000875.SZ", n: "Jilin Power Share", c: "CH" },
  { id: 9109, s: "8271.TW", n: "Apacer Technology Inc", c: "TW" },
  { id: 9110, s: "TLT1V.HE", n: "Teleste Oyj", c: "Finland" },
  { id: 9111, s: "601985.SS", n: "CH National Nuclear Power", c: "CH" },
  { id: 9112, s: "ISLAX.OL", n: "Icelandic Salmon As", c: "Norway" },
  { id: 9113, s: "DN.TO", n: "Delta 9 Cannabis Inc", c: "CA" },
  { id: 9114, s: "BDNNY", n: "Boliden AB ADR", c: "US" },
  { id: 9115, s: "DNE.L", n: "Dunedin Enterprise Investment Trust PLC", c: "UK" },
  { id: 9116, s: "ALNG.OL", n: "Awilco LNG ASA", c: "Norway" },
  { id: 9117, s: "FDGE.TO", n: "Farmers Edge Inc", c: "CA" },
  { id: 9118, s: "ADT1.L", n: "Adriatic Metals", c: "UK" },
  { id: 9119, s: "SCHO.", n: "Schouw & Co.", c: "Denmark" },
  { id: 9120, s: "ESKN.L", n: "Esken", c: "UK" },
  { id: 9121, s: "MDF.TO", n: "mdf Commerce Inc", c: "CA" },
  { id: 9122, s: "YGRAF", n: "Yangarra Resources", c: "US" },
  { id: 9123, s: "LAVI.AT", n: "Lavipharm S.A", c: "Greece" },
  { id: 9124, s: "ADOOY", n: "Adaro Energy Tbk PT ADR", c: "US" },
  { id: 9125, s: "600488.SS", n: "Tianjin Tianyao Pharmaceuticals", c: "CH" },
  { id: 9126, s: "INA.AX", n: "Ingenia Communities Group", c: "Australia" },
  { id: 9127, s: "SMFKY", n: "Smurfit Kappa Group Plc", c: "US" },
  { id: 9128, s: "QPR1V.HE", n: "QPR Software Oyj", c: "Finland" },
  { id: 9129, s: "NGEN.V", n: "NervGen Pharma Co", c: "CA" },
  { id: 9130, s: "300820.SZ", n: "Sichuan Injet Electric", c: "CH" },
  { id: 9131, s: "BEZ.AX", n: "Besra Gold Inc", c: "Australia" },
  { id: 9132, s: "105560.KS", n: "KB Financial Group", c: "Korea" },
  { id: 9133, s: "CFISH.ST", n: "Crunchfish AB", c: "Sweden" },
  { id: 9134, s: "PCA.PA", n: "PCAS SA", c: "FR" },
  { id: 9135, s: "ENKAI.IS", n: "ENKA Insaat ve Sanayi AS", c: "Turkey" },
  { id: 9136, s: "002202.SZ", n: "Xinjiang Goldwind Science & Technology Co Class A", c: "CH" },
  { id: 9137, s: "TWM.TO", n: "Tidewater Midstream and Infrastructure", c: "CA" },
  { id: 9138, s: "URE.TO", n: "Ur Energy Inc", c: "CA" },
  { id: 9139, s: "TML.TO", n: "Treasury Metals Inc", c: "CA" },
  { id: 9140, s: "ABQQ", n: "AB International Group Co", c: "US" },
  { id: 9141, s: "RAL.PA", n: "Rallye SA", c: "FR" },
  { id: 9142, s: "5876.TW", n: "Shanghai Commercial & Savings Bank", c: "TW" },
  { id: 9143, s: "TSL.TO", n: "Tree Island Steel.", c: "CA" },
  { id: 9144, s: "JETR", n: "Star Jets International Inc", c: "US" },
  { id: 9145, s: "ESINQ", n: "ITT Educational Services Inc", c: "US" },
  { id: 9146, s: "SWTQ.SW", n: "Schweiter Technologies AG", c: "Switzerland" },
  { id: 9147, s: "FID.AX", n: "Fiducian Group", c: "Australia" },
  { id: 9148, s: "002064.SZ", n: "Zhejiang Huafon Spandex", c: "CH" },
  { id: 9149, s: "EKGYO.IS", n: "Emlak Konut Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 9150, s: "VATN.SW", n: "Valiant Holding AG", c: "Switzerland" },
  { id: 9151, s: "3PL.AX", n: "3P Learning", c: "Australia" },
  { id: 9152, s: "SGM.AX", n: "Sims", c: "Australia" },
  { id: 9153, s: "WFC.TO", n: "Wall Financial Corporation", c: "CA" },
  { id: 9154, s: "EKTAY", n: "Elekta AB ADR", c: "US" },
  { id: 9155, s: "BHG.ST", n: "BHG Group AB (publ)", c: "Sweden" },
  { id: 9156, s: "AMD.NE", n: "Advanced Micro Devices Inc CDR", c: "CA" },
  { id: 9157, s: "HSTR.V", n: "Heliostar Metals", c: "CA" },
  { id: 9158, s: "3406.TW", n: "Genius Electronic Optical", c: "TW" },
  { id: 9159, s: "RWEOY", n: "RWE AG PK", c: "US" },
  { id: 9160, s: "NTCO3.SA", n: "Natura &Co Holding S.A", c: "Brazil" },
  { id: 9161, s: "600812.SS", n: "North CH Pharmaceutical", c: "CH" },
  { id: 9162, s: "GEM.L", n: "Gemfields Plc", c: "UK" },
  { id: 9163, s: "AENZ", n: "Aenza SAA", c: "US" },
  { id: 9164, s: "ROS.VI", n: "Rosenbauer International AG", c: "Austria" },
  { id: 9165, s: "VOLV-A.ST", n: "AB Volvo (publ)", c: "Sweden" },
  { id: 9166, s: "FCT.AX", n: "Firstwave Cloud Technology", c: "Australia" },
  { id: 9167, s: "004990.KS", n: "Lotte Co", c: "Korea" },
  { id: 9168, s: "SAE.TA", n: "Shufersal", c: "Israel" },
  { id: 9169, s: "BBDC3.SA", n: "Banco Bradesco S.A.", c: "Brazil" },
  { id: 9170, s: "OIII.V", n: "O3 Mining Inc", c: "CA" },
  { id: 9171, s: "GMM.DE", n: "Grammer AG", c: "GER" },
  { id: 9172, s: "SEI.V", n: "Sintana Energy Inc", c: "CA" },
  { id: 9173, s: "CEV", n: "Eaton Vance California MIT", c: "US" },
  { id: 9174, s: "VMC.AX", n: "Venus Metals Corporation", c: "Australia" },
  { id: 9175, s: "MAKTK.IS", n: "Makina Takim Endustrisi AS", c: "Turkey" },
  { id: 9176, s: "SGO.V", n: "Sonoro Gold Co", c: "CA" },
  { id: 9177, s: "ATDS", n: "Data443 Risk Mitigation Inc", c: "US" },
  { id: 9178, s: "KOM.WA", n: "Komputronik S.A.", c: "Poland" },
  { id: 9179, s: "6221.TWO", n: "Genesis Technology", c: "TW" },
  { id: 9180, s: "RIEN.SW", n: "Rieter Holding AG", c: "Switzerland" },
  { id: 9181, s: "AWRD.ST", n: "Awardit AB", c: "Sweden" },
  { id: 9182, s: "LCA", n: "Landcadia Holdings II Inc", c: "US" },
  { id: 9183, s: "FMM-B.ST", n: "FM Mattsson Mora Group AB (publ)", c: "Sweden" },
  { id: 9184, s: "BCII", n: "Blockchain Industries Inc", c: "US" },
  { id: 9185, s: "ANZLY", n: "Air New Zealand ADR", c: "US" },
  { id: 9186, s: "STGN.SW", n: "Starrag Group Holding AG", c: "Switzerland" },
  { id: 9187, s: "LTHM.L", n: "James Latham PLC", c: "UK" },
  { id: 9188, s: "MGNS.L", n: "Morgan Sindall Group PLC", c: "UK" },
  { id: 9189, s: "MUM.DE", n: "Mensch und Maschine Software SE", c: "GER" },
  { id: 9190, s: "3479.TWO", n: "Avalue Technology", c: "TW" },
  { id: 9191, s: "ODHN.SW", n: "Orascom Development Holding AG", c: "Switzerland" },
  { id: 9192, s: "NANO.PA", n: "Nanobiotix S.A", c: "FR" },
  { id: 9193, s: "WEST.OL", n: "Western Bulk Chartering AS", c: "Norway" },
  { id: 9194, s: "STRN.SW", n: "Schlatter Industries AG", c: "Switzerland" },
  { id: 9195, s: "IDOGEN.ST", n: "Idogen AB", c: "Sweden" },
  { id: 9196, s: "RSID3.SA", n: "Rossi Residencial S.A", c: "Brazil" },
  { id: 9197, s: "BWNG.L", n: "N Brown Group PLC", c: "UK" },
  { id: 9198, s: "PAT.AX", n: "Patriot Lithium.", c: "Australia" },
  { id: 9199, s: "ELVT.V", n: "Elevation Gold Mining Co", c: "CA" },
  { id: 9200, s: "FAR.AX", n: "FAR", c: "Australia" },
  { id: 9201, s: "5306.TW", n: "KMC (Kuei Meng) International Inc", c: "TW" },
  { id: 9202, s: "SSAAY", n: "SSAB AB ADR", c: "US" },
  { id: 9203, s: "WAF.AX", n: "West African Resources", c: "Australia" },
  { id: 9204, s: "PCA.L", n: "Palace Capital PLC", c: "UK" },
  { id: 9205, s: "KHOTF", n: "Kahoot As", c: "US" },
  { id: 9206, s: "ALELIO.ST", n: "Alelion Energy Systems AB", c: "Sweden" },
  { id: 9207, s: "6223.TWO", n: "MPI Corporation", c: "TW" },
  { id: 9208, s: "SKBNK.IS", n: "Sekerbank TAS", c: "Turkey" },
  { id: 9209, s: "EGEEN.IS", n: "Ege Endustri ve Ticaret AS", c: "Turkey" },
  { id: 9210, s: "OBL.AX", n: "Omni Bridgeway", c: "Australia" },
  { id: 9211, s: "NUF.AX", n: "Nufarm", c: "Australia" },
  { id: 9212, s: "6285.TW", n: "Wistron NeWeb Co", c: "TW" },
  { id: 9213, s: "EXSR", n: "Exchange Bank", c: "US" },
  { id: 9214, s: "DIC.DE", n: "DIC Asset AG", c: "GER" },
  { id: 9215, s: "UWAY.TA", n: "Userway", c: "Israel" },
  { id: 9216, s: "DR0.DE", n: "Deutsche Rohstoff AG", c: "GER" },
  { id: 9217, s: "CLOE", n: "Clover Leaf Capital Co", c: "US" },
  { id: 9218, s: "FISK", n: "Empire State Realty OP LP 250", c: "US" },
  { id: 9219, s: "GRX.AX", n: "GreenX Metals", c: "Australia" },
  { id: 9220, s: "ACP.WA", n: "Asseco Poland S.A.", c: "Poland" },
  { id: 9221, s: "RIO.V", n: "Rio2", c: "CA" },
  { id: 9222, s: "EXMT", n: "Anything Tech Media", c: "US" },
  { id: 9223, s: "EXETF", n: "Extendicare Inc", c: "US" },
  { id: 9224, s: "002793.SZ", n: "Luoxin Pharmaceuticals Group Stock", c: "CH" },
  { id: 9225, s: "RML.V", n: "Rusoro Mining", c: "CA" },
  { id: 9226, s: "ROCK.V", n: "Rockridge Resources", c: "CA" },
  { id: 9227, s: "EKT.DE", n: "Energiekontor AG", c: "GER" },
  { id: 9228, s: "DEC.PA", n: "JC Decaux SA", c: "FR" },
  { id: 9229, s: "600599.SS", n: "Panda Financial Holding Co", c: "CH" },
  { id: 9230, s: "MGR.AX", n: "Mirvac Group", c: "Australia" },
  { id: 9231, s: "MBK.WA", n: "mBank SA", c: "Poland" },
  { id: 9232, s: "RDEIY", n: "Red Electrica Corporacion SA ADR", c: "US" },
  { id: 9233, s: "108490.KQ", n: "Robotis Co.Ltd", c: "Korea" },
  { id: 9234, s: "ARRW", n: "Arrowroot Acquisition Co", c: "US" },
  { id: 9235, s: "XIM.V", n: "Ximen Mining Co", c: "CA" },
  { id: 9236, s: "145020.KQ", n: "Hugel Inc", c: "Korea" },
  { id: 9237, s: "PFP.AX", n: "Propel Funeral Partners", c: "Australia" },
  { id: 9238, s: "LRA.V", n: "Lara Exploration.", c: "CA" },
  { id: 9239, s: "601818.SS", n: "CH Everbright Bank", c: "CH" },
  { id: 9240, s: "SZL.AX", n: "Sezzle Inc", c: "Australia" },
  { id: 9241, s: "AAGH", n: "America Great Health", c: "US" },
  { id: 9242, s: "MTG-B.ST", n: "Modern Times Group Mtg AB", c: "Sweden" },
  { id: 9243, s: "ELIX.L", n: "Elixirr International Plc", c: "UK" },
  { id: 9244, s: "IPF.L", n: "International Personal Finance PLC", c: "UK" },
  { id: 9245, s: "KUD.SW", n: "Kudelski", c: "Switzerland" },
  { id: 9246, s: "IRI.AX", n: "Integrated Research", c: "Australia" },
  { id: 9247, s: "NORVA.ST", n: "Norva24 Group AB", c: "Sweden" },
  { id: 9248, s: "IOF.L", n: "Iofina plc", c: "UK" },
  { id: 9249, s: "XTRM", n: "Extreme Biodiesel In", c: "US" },
  { id: 9250, s: "DKSH.SW", n: "DKSH Holding AG", c: "Switzerland" },
  { id: 9251, s: "PMT.TO", n: "Perpetual Energy Inc", c: "CA" },
  { id: 9252, s: "ACGBY", n: "Agricultural Bank of CH PK", c: "US" },
  { id: 9253, s: "NNRX", n: "Nutranomics Inc", c: "US" },
  { id: 9254, s: "AGE.AX", n: "Alligator Energy", c: "Australia" },
  { id: 9255, s: "197140.KQ", n: "DigiCAP Co.", c: "Korea" },
  { id: 9256, s: "FUPBY", n: "Fuchs Petrolub SE ADR", c: "US" },
  { id: 9257, s: "2327.TW", n: "Yageo Co", c: "TW" },
  { id: 9258, s: "TLGO.MC", n: "Talgo SA", c: "Spain" },
  { id: 9259, s: "CPHN.SW", n: "CPH Chemie und Papier Holding", c: "Switzerland" },
  { id: 9260, s: "CPYYY", n: "Centrica PLC ADR", c: "US" },
  { id: 9261, s: "ADA.AX", n: "Adacel Tech", c: "Australia" },
  { id: 9262, s: "AKN.AX", n: "Auking Mining", c: "Australia" },
  { id: 9263, s: "AXI.AX", n: "Axiom Properties", c: "Australia" },
  { id: 9264, s: "3533.TW", n: "Lotes", c: "TW" },
  { id: 9265, s: "MMV.V", n: "Mineral Mountain Resources", c: "CA" },
  { id: 9266, s: "FTAIP", n: "Fortress Transportation and Preferred Series A", c: "US" },
  { id: 9267, s: "BYS.SW", n: "Bystronic AG", c: "Switzerland" },
  { id: 9268, s: "CANB", n: "Can B Co", c: "US" },
  { id: 9269, s: "DIRR3.SA", n: "Direcional Engenharia S.A", c: "Brazil" },
  { id: 9270, s: "PFE.AX", n: "Pantera Minerals", c: "Australia" },
  { id: 9271, s: "SOF-B.ST", n: "Softronic AB", c: "Sweden" },
  { id: 9272, s: "HOCN.SW", n: "Hochdorf Holding AG", c: "Switzerland" },
  { id: 9273, s: "SYME.L", n: "Supply@Me Capital PLC", c: "UK" },
  { id: 9274, s: "MZTF.TA", n: "Mizrahi Tefahot", c: "Israel" },
  { id: 9275, s: "RA.MX", n: "Regional S.A.B. de C.V", c: "Mexi" },
  { id: 9276, s: "DERIM.IS", n: "Derimod Konfeksiyon Ayakkabi Deri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 9277, s: "THRL.L", n: "Target Healthcare REIT", c: "UK" },
  { id: 9278, s: "AHCHY", n: "Anhui Conch Cement", c: "US" },
  { id: 9279, s: "004800.KS", n: "Hyosung Co", c: "Korea" },
  { id: 9280, s: "AIC", n: "Arlington Asset Investment Corp ELKS", c: "US" },
  { id: 9281, s: "AUB.AX", n: "AUB Group", c: "Australia" },
  { id: 9282, s: "VSVS.L", n: "Vesuvius PLC", c: "UK" },
  { id: 9283, s: "600549.SS", n: "Xiamen Tungsten", c: "CH" },
  { id: 9284, s: "GPM.V", n: "GPM Metals Inc", c: "CA" },
  { id: 9285, s: "GLRS.TA", n: "Globrands Group", c: "Israel" },
  { id: 9286, s: "2615.TW", n: "Wan Hai Lines", c: "TW" },
  { id: 9287, s: "DIS.NE", n: "Walt Disney Co CDR", c: "CA" },
  { id: 9288, s: "SITS", n: "Southern ITS International Inc", c: "US" },
  { id: 9289, s: "STBFY", n: "Suntory Beverage & Food", c: "US" },
  { id: 9290, s: "BWP.AX", n: "BWP Trust", c: "Australia" },
  { id: 9291, s: "ENER", n: "Accretion Acquisition Co", c: "US" },
  { id: 9292, s: "DBRM", n: "Daybreak Oil and Gas Inc", c: "US" },
  { id: 9293, s: "600066.SS", n: "Zhengzhou Yutong Bus", c: "CH" },
  { id: 9294, s: "603758.SS", n: "Chongqing QinAn M&E PLC", c: "CH" },
  { id: 9295, s: "CML.L", n: "CML Microsystems Plc", c: "UK" },
  { id: 9296, s: "ROCL", n: "Roth CH Acquisition V", c: "US" },
  { id: 9297, s: "241820.KQ", n: "PCL Inc", c: "Korea" },
  { id: 9298, s: "CAD.V", n: "Colonial Coal International Co", c: "CA" },
  { id: 9299, s: "OCLG", n: "Oncologix Tech Inc", c: "US" },
  { id: 9300, s: "BOLS.AS", n: "Lucas Bols Holding BV", c: "Netherlands" },
  { id: 9301, s: "SUL.AX", n: "Super Retail Group", c: "Australia" },
  { id: 9302, s: "BKI.TO", n: "Black Iron Inc.", c: "CA" },
  { id: 9303, s: "DEFI.NE", n: "Valour Inc", c: "CA" },
  { id: 9304, s: "HSQVY", n: "Husqvarna AB", c: "US" },
  { id: 9305, s: "CLIS.TA", n: "Clal Insurance Enterprises Holdings", c: "Israel" },
  { id: 9306, s: "2449.TW", n: "King Yuan Electronics", c: "TW" },
  { id: 9307, s: "EBOX.L", n: "Tritax EuroBox PLC GBp", c: "UK" },
  { id: 9308, s: "603882.SS", n: "Guangzhou Kingmed Diagnostics Group", c: "CH" },
  { id: 9309, s: "AEP.L", n: "Anglo-Eastern Plantations Plc", c: "UK" },
  { id: 9310, s: "ERGO.L", n: "Ergomed Plc", c: "UK" },
  { id: 9311, s: "688018.SS", n: "Espressif Systems Shanghai", c: "CH" },
  { id: 9312, s: "RBLN-B.", n: "Roblon A/S", c: "Denmark" },
  { id: 9313, s: "RF1.AX", n: "Regal Investment Fund", c: "Australia" },
  { id: 9314, s: "LGT.MC", n: "Lingotes", c: "Spain" },
  { id: 9315, s: "688159.SS", n: "Shenzhen Neoway Technology", c: "CH" },
  { id: 9316, s: "ALCOR.PA", n: "Biocorp Production SA", c: "FR" },
  { id: 9317, s: "MYPK3.SA", n: "Iochpe-Maxion S.A", c: "Brazil" },
  { id: 9318, s: "CLX.AX", n: "CTI Logistics", c: "Australia" },
  { id: 9319, s: "603880.SS", n: "Jiangsu Nanfang Medical", c: "CH" },
  { id: 9320, s: "GGG.V", n: "G6 Materials Co", c: "CA" },
  { id: 9321, s: "DVRG.L", n: "Deepverge PLC", c: "UK" },
  { id: 9322, s: "VALOE.HE", n: "Valoe Co", c: "Finland" },
  { id: 9323, s: "LIGT3.SA", n: "Light S.A", c: "Brazil" },
  { id: 9324, s: "ANG.L", n: "Angling Direct PLC", c: "UK" },
  { id: 9325, s: "ECN.TO", n: "ECN Capital Co", c: "CA" },
  { id: 9326, s: "SPKKY", n: "Spark New Zealand ADR", c: "US" },
  { id: 9327, s: "AQUA.TA", n: "Aquarius Engines AM", c: "Israel" },
  { id: 9328, s: "PWH.AX", n: "PWR Holdings", c: "Australia" },
  { id: 9329, s: "CTG.L", n: "Christie Group plc", c: "UK" },
  { id: 9330, s: "SHED.L", n: "Urban Logistics Reit PLC", c: "UK" },
  { id: 9331, s: "ETU.V", n: "E2Gold Inc", c: "CA" },
  { id: 9332, s: "MBAC", n: "M3-Brigade Acquisition II Co", c: "US" },
  { id: 9333, s: "CHLL.L", n: "Chill Brands Group PLC", c: "UK" },
  { id: 9334, s: "FRLA", n: "Fortune Rise Acquisition Co", c: "US" },
  { id: 9335, s: "MALRY", n: "Mineral Res", c: "US" },
  { id: 9336, s: "SVR.AX", n: "Solvar.", c: "Australia" },
  { id: 9337, s: "TCOA", n: "Trajectory Alpha Acquisition Co", c: "US" },
  { id: 9338, s: "PRIM.TA", n: "Prime Energy PE", c: "Israel" },
  { id: 9339, s: "PZOL.TA", n: "Paz Oil", c: "Israel" },
  { id: 9340, s: "ACQ.TO", n: "AutoCA Inc", c: "CA" },
  { id: 9341, s: "FAG.ST", n: "Fagerhult AB", c: "Sweden" },
  { id: 9342, s: "JXT.AX", n: "Jaxsta", c: "Australia" },
  { id: 9343, s: "603236.SS", n: "Quectel Wireless Solutions ", c: "CH" },
  { id: 9344, s: "ULG.WA", n: "Ultimate Games SA", c: "Poland" },
  { id: 9345, s: "CREAF", n: "Creative Technology", c: "US" },
  { id: 9346, s: "600845.SS", n: "Shanghai Baosight Software Co A", c: "CH" },
  { id: 9347, s: "JYC.AX", n: "Joyce Corporation", c: "Australia" },
  { id: 9348, s: "600006.SS", n: "Dongfeng Automobile", c: "CH" },
  { id: 9349, s: "BBC.AX", n: "BNK Banking Corporation", c: "Australia" },
  { id: 9350, s: "CBSM.PA", n: "Soc Centrale Bois Scieries Manche SA", c: "FR" },
  { id: 9351, s: "OLCLY", n: "Oriental Land Co ADR", c: "US" },
  { id: 9352, s: "AZFL", n: "Amazonas Florestal", c: "US" },
  { id: 9353, s: "IPO.L", n: "IP Group", c: "UK" },
  { id: 9354, s: "NSKOG.OL", n: "Norske Skog Asa", c: "Norway" },
  { id: 9355, s: "DBO.AX", n: "Diablo Resources", c: "Australia" },
  { id: 9356, s: "XIACY", n: "Xiaomi Corp ADR", c: "US" },
  { id: 9357, s: "3362.TWO", n: "Ability Opto-Electronics Technology", c: "TW" },
  { id: 9358, s: "IBST.L", n: "Ibstock PLC", c: "UK" },
  { id: 9359, s: "INH.DE", n: "Indus Holding AG", c: "GER" },
  { id: 9360, s: "TOPPY", n: "Toppan Printing", c: "US" },
  { id: 9361, s: "000004.SZ", n: "Shenzhen CAU Technology", c: "CH" },
  { id: 9362, s: "TPW.AX", n: "Temple & Webster Group", c: "Australia" },
  { id: 9363, s: "XXL.OL", n: "XXL ASA", c: "Norway" },
  { id: 9364, s: "BARU.V", n: "Baru Gold Co", c: "CA" },
  { id: 9365, s: "VIRP.PA", n: "Virbac SA", c: "FR" },
  { id: 9366, s: "600030.SS", n: "CITIC Securities", c: "CH" },
  { id: 9367, s: "REDD.L", n: "Redde Northgate PLC", c: "UK" },
  { id: 9368, s: "SVIK.ST", n: "Studsvik AB", c: "Sweden" },
  { id: 9369, s: "ININ.TA", n: "Inter Industries", c: "Israel" },
  { id: 9370, s: "LFAP", n: "LGBTQ Loyalty Holdings Inc", c: "US" },
  { id: 9371, s: "PLAT.AT", n: "Thrace Plastics Holding and Commercial S.A", c: "Greece" },
  { id: 9372, s: "LEXI.V", n: "Lithium Energi Exploration Inc", c: "CA" },
  { id: 9373, s: "9917.TW", n: "Taiwan Secom", c: "TW" },
  { id: 9374, s: "KRM.AX", n: "Kingsrose Mining", c: "Australia" },
  { id: 9375, s: "ELSTR.AT", n: "Elastron S.A. - Steel Service Centers", c: "Greece" },
  { id: 9376, s: "CDA.V", n: "Canuc Resources Co", c: "CA" },
  { id: 9377, s: "PL8.AX", n: "Plato Income Maximiser", c: "Australia" },
  { id: 9378, s: "BRGC", n: "Brightrock Gold Co", c: "US" },
  { id: 9379, s: "CVV.AX", n: "Caravel Minerals", c: "Australia" },
  { id: 9380, s: "ROAG", n: "Rogue One Inc", c: "US" },
  { id: 9381, s: "TBLMY", n: "Tiger Brands PK", c: "US" },
  { id: 9382, s: "KZA.AX", n: "Kazia Therapeutics", c: "Australia" },
  { id: 9383, s: "HFG.L", n: "Hilton Food Group Plc", c: "UK" },
  { id: 9384, s: "CCB.V", n: "CA Carbon Inc.", c: "CA" },
  { id: 9385, s: "020560.KS", n: "Asiana Airline", c: "Korea" },
  { id: 9386, s: "MMT.PA", n: "Métropole Télévision S.A.", c: "FR" },
  { id: 9387, s: "VP.L", n: "Vp plc", c: "UK" },
  { id: 9388, s: "TKA.F", n: "thyssenkrupp AG", c: "GER" },
  { id: 9389, s: "EXR.AX", n: "Elixir Petroleum", c: "Australia" },
  { id: 9390, s: "FORW", n: "Forwardly Inc", c: "US" },
  { id: 9391, s: "NWX.V", n: "Newport Exploration", c: "CA" },
  { id: 9392, s: "ALLN.SW", n: "Allreal Holding", c: "Switzerland" },
  { id: 9393, s: "VIT-B.ST", n: "Vitec Software Group AB (publ)", c: "Sweden" },
  { id: 9394, s: "NOHAL.OL", n: "Nordic Halibut AS", c: "Norway" },
  { id: 9395, s: "TBL.TO", n: "Taiga Building Products.", c: "CA" },
  { id: 9396, s: "ALC.AX", n: "Alcidion Group", c: "Australia" },
  { id: 9397, s: "RAIL.ST", n: "Railcare Group AB (publ)", c: "Sweden" },
  { id: 9398, s: "VZUG.SW", n: "V Zug Holding Ag", c: "Switzerland" },
  { id: 9399, s: "ZEHN.SW", n: "Zehnder", c: "Switzerland" },
  { id: 9400, s: "2428.TW", n: "Thinking Electronic Industrial", c: "TW" },
  { id: 9401, s: "5903.TWO", n: "Taiwan FamilyMart", c: "TW" },
  { id: 9402, s: "SMECF", n: "SMC Co", c: "US" },
  { id: 9403, s: "SKBN.TA", n: "Shikun & Binui", c: "Israel" },
  { id: 9404, s: "GRNH", n: "Greengro Tech", c: "US" },
  { id: 9405, s: "GURN.SW", n: "Gurit Holding AG", c: "Switzerland" },
  { id: 9406, s: "BOR.L", n: "Borders & Southern Petroleum plc", c: "UK" },
  { id: 9407, s: "2451.TW", n: "Transcend Information Inc", c: "TW" },
  { id: 9408, s: "688536.SS", n: "3Peak Inc", c: "CH" },
  { id: 9409, s: "BNTGY", n: "Brenntag AG ADR", c: "US" },
  { id: 9410, s: "2059.TW", n: "King Slide Works", c: "TW" },
  { id: 9411, s: "STO3.DE", n: "Sto SE & Co. KGaA", c: "GER" },
  { id: 9412, s: "MGAM.L", n: "Morgan Advanced Materials plc", c: "UK" },
  { id: 9413, s: "FEX.AX", n: "Fenix Resources", c: "Australia" },
  { id: 9414, s: "HIPR.TA", n: "Hiper Global", c: "Israel" },
  { id: 9415, s: "IPIC", n: "Ipic Entertainment Inc", c: "US" },
  { id: 9416, s: "PRS", n: "Prudential Financial Inc", c: "US" },
  { id: 9417, s: "EGL.LS", n: "Mota-Engil SGPS S.A", c: "Portugal" },
  { id: 9418, s: "NSPT", n: "Newron Sport", c: "US" },
  { id: 9419, s: "ZIJMY", n: "Zijin Mining Group Co ADR", c: "US" },
  { id: 9420, s: "JST.DE", n: "JOST Werke AG", c: "GER" },
  { id: 9421, s: "SKFRY", n: "AB SKF", c: "US" },
  { id: 9422, s: "SPEC.ST", n: "SpectraCure AB", c: "Sweden" },
  { id: 9423, s: "FSJ.L", n: "James Fisher and Sons PLC", c: "UK" },
  { id: 9424, s: "ASAI.L", n: "ASA International Group PLC", c: "UK" },
  { id: 9425, s: "DLAR.L", n: "De La Rue PLC", c: "UK" },
  { id: 9426, s: "FCP.LS", n: "Futebol Clube do Porto - Futebol S.A.D", c: "Portugal" },
  { id: 9427, s: "MND.TO", n: "Mandalay Resources Co", c: "CA" },
  { id: 9428, s: "ESK.AX", n: "Etherstack plc", c: "Australia" },
  { id: 9429, s: "000155.KS", n: "Doosan Pref Shs", c: "Korea" },
  { id: 9430, s: "EVIO", n: "EVIO Inc", c: "US" },
  { id: 9431, s: "HXG.AX", n: "Hexagon Resources", c: "Australia" },
  { id: 9432, s: "FPC.V", n: "Falco Resources", c: "CA" },
  { id: 9433, s: "SNEL.TA", n: "Synel M.L.L Payway", c: "Israel" },
  { id: 9434, s: "SFD.TO", n: "NXT Energy Solutions Inc", c: "CA" },
  { id: 9435, s: "2610.TW", n: "CH Airlines", c: "TW" },
  { id: 9436, s: "688187.SS", n: "Zhuzhou CRRC Times Electric", c: "CH" },
  { id: 9437, s: "FPX.V", n: "FPX Nickel Co", c: "CA" },
  { id: 9438, s: "GIB.AX", n: "GIBB River Diamonds", c: "Australia" },
  { id: 9439, s: "601066.SS", n: "CH Securities", c: "CH" },
  { id: 9440, s: "2801.TW", n: "Chang Hwa Commercial Bank", c: "TW" },
  { id: 9441, s: "2206.TW", n: "Sanyang Motor", c: "TW" },
  { id: 9442, s: "CCE.V", n: "Commerce Resources Corp.", c: "CA" },
  { id: 9443, s: "3022.TW", n: "IEI Integration Co", c: "TW" },
  { id: 9444, s: "GTVH", n: "Golden Triangle Ventures Inc", c: "US" },
  { id: 9445, s: "ALBA.TA", n: "Al-Bad Massuot Yitzhak", c: "Israel" },
  { id: 9446, s: "EHVVF", n: "Ehave Inc", c: "US" },
  { id: 9447, s: "HLEE.SW", n: "Highlight Event Entertainment", c: "Switzerland" },
  { id: 9448, s: "RANI.TA", n: "Rani Zim Shopping Centers", c: "Israel" },
  { id: 9449, s: "ENRG.TA", n: "Energix Renewable Energies", c: "Israel" },
  { id: 9450, s: "TER.AX", n: "Terracom", c: "Australia" },
  { id: 9451, s: "HLO.AX", n: "Helloworld", c: "Australia" },
  { id: 9452, s: "HLUN-A.", n: "H Lundbeck A/S", c: "Denmark" },
  { id: 9453, s: "RLT.AX", n: "RENERGEN", c: "Australia" },
  { id: 9454, s: "5287.TWO", n: "Addcn Technology", c: "TW" },
  { id: 9455, s: "ARF.AX", n: "Arena REIT", c: "Australia" },
  { id: 9456, s: "DTC.AX", n: "Damstra Holdings", c: "Australia" },
  { id: 9457, s: "4979.TWO", n: "LuxNet", c: "TW" },
  { id: 9458, s: "GLBX.TA", n: "Glassbox", c: "Israel" },
  { id: 9459, s: "MAKS.MC", n: "Making Science Group S.A.", c: "Spain" },
  { id: 9460, s: "VLID3.SA", n: "Valid Soluções S.A", c: "Brazil" },
  { id: 9461, s: "AVG.AX", n: "Australian Vintage", c: "Australia" },
  { id: 9462, s: "2727.TW", n: "Wowprime Co", c: "TW" },
  { id: 9463, s: "FIBI.TA", n: "First International Bank of Israel", c: "Israel" },
  { id: 9464, s: "STEAV.HE", n: "Stora Enso Oyj A", c: "Finland" },
  { id: 9465, s: "HWEL", n: "Healthwell Acquisition Corp I", c: "US" },
  { id: 9466, s: "SPI.L", n: "Spire Healthcare Group Plc", c: "UK" },
  { id: 9467, s: "1796.TWO", n: "GeneFerm Biotechnology", c: "TW" },
  { id: 9468, s: "8436.TWO", n: "TCI", c: "TW" },
  { id: 9469, s: "FCGD", n: "First Colombia Gold Co", c: "US" },
  { id: 9470, s: "011780.KS", n: "Kumho Petro Chemical", c: "Korea" },
  { id: 9471, s: "ALHUN.PA", n: "Hunyvers SA", c: "FR" },
  { id: 9472, s: "QCCU.V", n: "QC Copper and Gold Inc", c: "CA" },
  { id: 9473, s: "GUI.PA", n: "Guillemot Corporation S.A", c: "FR" },
  { id: 9474, s: "DUB.AX", n: "Dubber Co", c: "Australia" },
  { id: 9475, s: "ACUVI.ST", n: "Acuvi AB", c: "Sweden" },
  { id: 9476, s: "TIV.", n: "Tivoli A/S", c: "Denmark" },
  { id: 9477, s: "TET.L", n: "Treatt PLC", c: "UK" },
  { id: 9478, s: "688008.SS", n: "Montage Technology", c: "CH" },
  { id: 9479, s: "SVEG.OL", n: "Sparebanken Vest", c: "Norway" },
  { id: 9480, s: "ROR.L", n: "Rotork PLC", c: "UK" },
  { id: 9481, s: "PBBK", n: "PB Bankshares Inc", c: "US" },
  { id: 9482, s: "ADX.AX", n: "Adx Energy", c: "Australia" },
  { id: 9483, s: "FLAG", n: "First Light Acquisition Group Inc", c: "US" },
  { id: 9484, s: "6732.TWO", n: "Sensortek Technology Co", c: "TW" },
  { id: 9485, s: "GWM.V", n: "Galway Metals Inc.", c: "CA" },
  { id: 9486, s: "BNIX", n: "Bannix Acquisition Co", c: "US" },
  { id: 9487, s: "NPHC", n: "Nutra Pharma Co", c: "US" },
  { id: 9488, s: "329180.KS", n: "Hyundai Heavy Industries", c: "Korea" },
  { id: 9489, s: "FBR.AX", n: "FBR", c: "Australia" },
  { id: 9490, s: "ALGIL.PA", n: "Groupe Guillin SA", c: "FR" },
  { id: 9491, s: "LATF", n: "Latteno Food Corp.", c: "US" },
  { id: 9492, s: "CHW.TO", n: "Chesswood Group Limited", c: "CA" },
  { id: 9493, s: "6491.TW", n: "Pegavision", c: "TW" },
  { id: 9494, s: "010130.KS", n: "Korea Zinc Inc", c: "Korea" },
  { id: 9495, s: "GOT.V", n: "Goliath Resources", c: "CA" },
  { id: 9496, s: "COVH.PA", n: "Covivio Hotels", c: "FR" },
  { id: 9497, s: "WLD.AX", n: "Wellard", c: "Australia" },
  { id: 9498, s: "ROMI3.SA", n: "Indústrias Romi S.A", c: "Brazil" },
  { id: 9499, s: "026890.KS", n: "Stic Investments Inc", c: "Korea" },
  { id: 9500, s: "6669.TW", n: "Wiwynn Co", c: "TW" },
  { id: 9501, s: "600600.SS", n: "Tsingtao Brewery", c: "CH" },
  { id: 9502, s: "ADEX", n: "Adit EdTech Acquisition Cor", c: "US" },
  { id: 9503, s: "022100.KQ", n: "Posco ICT Company", c: "Korea" },
  { id: 9504, s: "ISN.SW", n: "Intershop Holding AG", c: "Switzerland" },
  { id: 9505, s: "CASH.MC", n: "Prosegur Cash S.A", c: "Spain" },
  { id: 9506, s: "603709.SS", n: "Zoy Home Furnishing", c: "CH" },
  { id: 9507, s: "ASH.AX", n: "Ashley Services Group", c: "Australia" },
  { id: 9508, s: "ALVER.PA", n: "Vergnet", c: "FR" },
  { id: 9509, s: "KOD.L", n: "Kodal Minerals PLC", c: "UK" },
  { id: 9510, s: "6446.TWO", n: "PharmaEssentia", c: "TW" },
  { id: 9511, s: "ELS.AX", n: "Elsight", c: "Australia" },
  { id: 9512, s: "MUV2.F", n: "Münchener Rück AG", c: "GER" },
  { id: 9513, s: "DTG.F", n: "Daimler Truck Holding AG", c: "GER" },
  { id: 9514, s: "ISGI.V", n: "InsuraGuest Technologies Inc", c: "CA" },
  { id: 9515, s: "PSPN.SW", n: "PSP Swiss Property AG", c: "Switzerland" },
  { id: 9516, s: "NWL.AX", n: "Netwealth Group", c: "Australia" },
  { id: 9517, s: "CSS.AX", n: "Clean Seas Seafood", c: "Australia" },
  { id: 9518, s: "AMI.AX", n: "Aurelia Metals", c: "Australia" },
  { id: 9519, s: "IMPC.TA", n: "ImpacxIo", c: "Israel" },
  { id: 9520, s: "LIS.AX", n: "LiS Energy", c: "Australia" },
  { id: 9521, s: "AMA.AX", n: "AMA Group", c: "Australia" },
  { id: 9522, s: "ALPN.SW", n: "Alpine Select AG", c: "Switzerland" },
  { id: 9523, s: "LEO.V", n: "Lion Copper and Gold Co", c: "CA" },
  { id: 9524, s: "UZE", n: "United States Cellular Preferred 5.500% due 2070", c: "US" },
  { id: 9525, s: "REC.L", n: "Record PLC", c: "UK" },
  { id: 9526, s: "KTN.V", n: "Kootenay Silver Inc", c: "CA" },
  { id: 9527, s: "DUUO", n: "Duo World Inc", c: "US" },
  { id: 9528, s: "DUKB", n: "Duke Energy Co", c: "US" },
  { id: 9529, s: "PHAR.L", n: "Pharos Energy plc", c: "UK" },
  { id: 9530, s: "NVZMY", n: "Novozymes AS", c: "US" },
  { id: 9531, s: "XAUMF", n: "GoldMoney Inc", c: "US" },
  { id: 9532, s: "DRETF", n: "Dream Office Real Estate Investment Trust", c: "US" },
  { id: 9533, s: "AVVH", n: "AVVAA World Health Care Products Inc", c: "US" },
  { id: 9534, s: "ONCI", n: "On4 Communications Inc", c: "US" },
  { id: 9535, s: "SBSAA", n: "Spanish Broadcasting System Inc", c: "US" },
  { id: 9536, s: "AM7.AX", n: "Arcadia Minerals DRC", c: "Australia" },
  { id: 9537, s: "CGG.TO", n: "CH Gold International Resources", c: "CA" },
  { id: 9538, s: "214680.KQ", n: "DRTECH Corporation", c: "Korea" },
  { id: 9539, s: "000120.KS", n: "CJ Korea Express", c: "Korea" },
  { id: 9540, s: "BRKN.SW", n: "Burkhalter Holding AG", c: "Switzerland" },
  { id: 9541, s: "SALT-B.ST", n: "SaltX Technology Holding AB", c: "Sweden" },
  { id: 9542, s: "WTII", n: "Water Technologies International Inc", c: "US" },
  { id: 9543, s: "ANTIN.PA", n: "Antin IP SA", c: "FR" },
  { id: 9544, s: "ARGEO.OL", n: "Argeo AS", c: "Norway" },
  { id: 9545, s: "ANE.MC", n: "Corporacion Acciona Energias Renovables SA", c: "Spain" },
  { id: 9546, s: "ADX.MC", n: "Audax Renovables S.A.", c: "Spain" },
  { id: 9547, s: "BIRM.TA", n: "Birman Wood and Hardware", c: "Israel" },
  { id: 9548, s: "603444.SS", n: "G-bits Network Technology Xiamen", c: "CH" },
  { id: 9549, s: "688262.SS", n: "C*Core Technology", c: "CH" },
  { id: 9550, s: "CIMSA.IS", n: "Cimsa Cimento Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 9551, s: "600016.SS", n: "CH Minsheng Banking Co", c: "CH" },
  { id: 9552, s: "MSVT.TA", n: "Massivit 3D Printing Technologies", c: "Israel" },
  { id: 9553, s: "MSBI.TA", n: "Hamashbir 365 Holdings", c: "Israel" },
  { id: 9554, s: "KMDA.TA", n: "Kamada", c: "Israel" },
  { id: 9555, s: "2351.TW", n: "SDI Co", c: "TW" },
  { id: 9556, s: "ISMEN.IS", n: "Is Yatirim Menkul Degerler AS", c: "Turkey" },
  { id: 9557, s: "SXE.AX", n: "Southern Cross Electrical", c: "Australia" },
  { id: 9558, s: "PZU.WA", n: "Powszechny Zaklad Ubezpieczen SA", c: "Poland" },
  { id: 9559, s: "DDR.AX", n: "Dicker Data", c: "Australia" },
  { id: 9560, s: "ISBIR.IS", n: "Isbir Holding AS", c: "Turkey" },
  { id: 9561, s: "600009.SS", n: "Shanghai International Airport", c: "CH" },
  { id: 9562, s: "166090.KQ", n: "Hana Materials Inc", c: "Korea" },
  { id: 9563, s: "600011.SS", n: "Huaneng Power International Inc", c: "CH" },
  { id: 9564, s: "WSU.DE", n: "WashTec AG", c: "GER" },
  { id: 9565, s: "688301.SS", n: "iRay Technology", c: "CH" },
  { id: 9566, s: "PRSNF", n: "Personas Social Inc", c: "US" },
  { id: 9567, s: "CYL.V", n: "Ceylon Graphite Co", c: "CA" },
  { id: 9568, s: "ADHC", n: "American Diversified Holdings Co", c: "US" },
  { id: 9569, s: "BFNH", n: "BioForce Nanosciences Holdings Inc", c: "US" },
  { id: 9570, s: "FSRX", n: "Finserv Acquisition Corp II", c: "US" },
  { id: 9571, s: "FRZT", n: "Freeze Tag Inc", c: "US" },
  { id: 9572, s: "OMM.V", n: "Omineca Mining and Metals", c: "CA" },
  { id: 9573, s: "AII.TO", n: "Almonty Industries Inc", c: "CA" },
  { id: 9574, s: "MTUAY", n: "MTU Aero Engines AG", c: "US" },
  { id: 9575, s: "FAME", n: "Flamemaster Corporation", c: "US" },
  { id: 9576, s: "IGEN", n: "IGEN Networks Co", c: "US" },
  { id: 9577, s: "ASAGF", n: "Australian Agricultural Company", c: "US" },
  { id: 9578, s: "MRC.L", n: "The Mercantile Investment Trust plc", c: "UK" },
  { id: 9579, s: "CD.V", n: "Cantex Mine Development Co", c: "CA" },
  { id: 9580, s: "RAFA", n: "Rafarma Pharmaceuticals Inc", c: "US" },
  { id: 9581, s: "EDY.V", n: "Eddy Smart Home Solutions", c: "CA" },
  { id: 9582, s: "BYNO", n: "Bynordic Acquisition Co", c: "US" },
  { id: 9583, s: "IGO.V", n: "Independence Gold Co", c: "CA" },
  { id: 9584, s: "HITC", n: "Healthcare Integrated Technologies Inc", c: "US" },
  { id: 9585, s: "CELJF", n: "Cellcom Israel", c: "US" },
  { id: 9586, s: "WGNR", n: "Wegener Corporation", c: "US" },
  { id: 9587, s: "SOJC", n: "Southern", c: "US" },
  { id: 9588, s: "TKGSY", n: "Tokyo Gas", c: "US" },
  { id: 9589, s: "BYAGF", n: "Banyan Gold Co", c: "US" },
  { id: 9590, s: "TMKR", n: "Tastemaker Acquisition Co", c: "US" },
  { id: 9591, s: "CCUR", n: "CCUR Holdings Inc", c: "US" },
  { id: 9592, s: "TNGL", n: "Tonogold Resources Inc", c: "US" },
  { id: 9593, s: "GBT.TO", n: "BMTC Group Inc", c: "CA" },
  { id: 9594, s: "CBI.TA", n: "Clal Biotechnology Industries", c: "Israel" },
  { id: 9595, s: "PEAS.V", n: "Global Food and Ingredients", c: "CA" },
  { id: 9596, s: "PERU.V", n: "Chakana Copper Co", c: "CA" },
  { id: 9597, s: "PRYM.V", n: "Prime Mining Co", c: "CA" },
  { id: 9598, s: "ATST.L", n: "Alliance Trust PLC", c: "UK" },
  { id: 9599, s: "WINK.L", n: "M Winkworth PLC", c: "UK" },
  { id: 9600, s: "WTAN.L", n: "Witan Investment Trust", c: "UK" },
  { id: 9601, s: "CTTAY", n: "Continental AG PK", c: "US" },
  { id: 9602, s: "PCCWY", n: "PCCW Limited", c: "US" },
  { id: 9603, s: "CNDA", n: "Concord Acquisition II Co", c: "US" },
  { id: 9604, s: "AZT.V", n: "Aztec Minerals Co", c: "CA" },
  { id: 9605, s: "SIPC", n: "Sipp Industries New", c: "US" },
  { id: 9606, s: "LIT.V", n: "Argentina Lithium & Energy Co", c: "CA" },
  { id: 9607, s: "OBD.L", n: "Oxford Biodynamics PLC", c: "UK" },
  { id: 9608, s: "CERT.V", n: "Cerrado Gold Co", c: "CA" },
  { id: 9609, s: "MAI.V", n: "Minera Alamos Inc", c: "CA" },
  { id: 9610, s: "UPI.V", n: "Universal PropTech Inc", c: "CA" },
  { id: 9611, s: "GATC.L", n: "GATTACA Plc", c: "UK" },
  { id: 9612, s: "BCG.L", n: "Baltic Classifieds Group PLC", c: "UK" },
  { id: 9613, s: "TAO.V", n: "TAG Oil.", c: "CA" },
  { id: 9614, s: "WGO.V", n: "White Gold Co", c: "CA" },
  { id: 9615, s: "CGS.L", n: "Castings PLC", c: "UK" },
  { id: 9616, s: "CREI.L", n: "Custodian REIT PLC", c: "UK" },
  { id: 9617, s: "SAG.V", n: "Sterling Metals Co", c: "CA" },
  { id: 9618, s: "LFAC", n: "LF Capital Acquisition II Co", c: "US" },
  { id: 9619, s: "ENQ.L", n: "Enquest Plc", c: "UK" },
  { id: 9620, s: "FDP.L", n: "FD Technologies Plc", c: "UK" },
  { id: 9621, s: "LNVGF", n: "Lenovo Group", c: "US" },
  { id: 9622, s: "YES.V", n: "CHAR Technologies", c: "CA" },
  { id: 9623, s: "AKH.OL", n: "Aker Horizons AS", c: "Norway" },
  { id: 9624, s: "RTON", n: "Right On Brands Inc", c: "US" },
  { id: 9625, s: "CNTC.TA", n: "Cannabotech", c: "Israel" },
  { id: 9626, s: "STRO.OL", n: "Strongpoint ASA", c: "Norway" },
  { id: 9627, s: "300059.SZ", n: "East Money Information", c: "CH" },
  { id: 9628, s: "DSRT.OL", n: "Desert Control AS", c: "Norway" },
  { id: 9629, s: "DASA3.SA", n: "Diagnósticos da América S.A", c: "Brazil" },
  { id: 9630, s: "TSAT.TO", n: "Telesat Co", c: "CA" },
  { id: 9631, s: "EURA.ST", n: "Euroafrica Digital Ventures AB", c: "Sweden" },
  { id: 9632, s: "IS7.DE", n: "InTiCa Systems AG", c: "GER" },
  { id: 9633, s: "1314.TW", n: "CH Petrochemical Development Co", c: "TW" },
  { id: 9634, s: "THNC.TO", n: "Thinkific Labs Inc", c: "CA" },
  { id: 9635, s: "FRAM-B.ST", n: "Fram Skandinavien AB Series B", c: "Sweden" },
  { id: 9636, s: "GAPW-B.ST", n: "Gapwaves AB Series B", c: "Sweden" },
  { id: 9637, s: "EZZ.AX", n: "EZZ Life Science Holdings", c: "Australia" },
  { id: 9638, s: "ESR.AX", n: "Estrella Resources", c: "Australia" },
  { id: 9639, s: "CGRA3.SA", n: "Grazziotin S.A", c: "Brazil" },
  { id: 9640, s: "TON.AX", n: "Triton Minerals", c: "Australia" },
  { id: 9641, s: "086790.KS", n: "Hana Financial", c: "Korea" },
  { id: 9642, s: "M12.DE", n: "M1 Kliniken AG", c: "GER" },
  { id: 9643, s: "IPH.AX", n: "IPH", c: "Australia" },
  { id: 9644, s: "CMIG3.SA", n: "Companhia Energética de Minas Gerais", c: "Brazil" },
  { id: 9645, s: "IPT.AX", n: "Impact Minerals", c: "Australia" },
  { id: 9646, s: "1580.TWO", n: "Sinmag Equipment", c: "TW" },
  { id: 9647, s: "KFAST-B.ST", n: "K-Fast Holding AB (publ)", c: "Sweden" },
  { id: 9648, s: "CSMG3.SA", n: "Companhia de Saneamento de Minas Gerais", c: "Brazil" },
  { id: 9649, s: "000831.SZ", n: "CH Minmetals Rare Earth", c: "CH" },
  { id: 9650, s: "OPCE.TA", n: "OPC Energy", c: "Israel" },
  { id: 9651, s: "NRGN.TA", n: "Nrgene Technologies", c: "Israel" },
  { id: 9652, s: "VERK.HE", n: "Verkkokauppa.com Oyj", c: "Finland" },
  { id: 9653, s: "BGL.AX", n: "Bellevue Gold", c: "Australia" },
  { id: 9654, s: "TLC.AX", n: "Lottery Corporation", c: "Australia" },
  { id: 9655, s: "AUC.AX", n: "Ausgold", c: "Australia" },
  { id: 9656, s: "CLW.AX", n: "Charter Hall Long WALE REIT", c: "Australia" },
  { id: 9657, s: "603015.SS", n: "Ningbo Techmation", c: "CH" },
  { id: 9658, s: "PET.AX", n: "Phoslock Environmental Technologies Limited", c: "Australia" },
  { id: 9659, s: "TFF.PA", n: "Tonnellerie Francois Freres SA", c: "FR" },
  { id: 9660, s: "SABFG.PR", n: "SAB Finance as", c: "Czech Republic" },
  { id: 9661, s: "PGO.AX", n: "Pacgold", c: "Australia" },
  { id: 9662, s: "CBR.AX", n: "Carbon Revolution", c: "Australia" },
  { id: 9663, s: "601998.SS", n: "CH Citic Bank Corp Class A", c: "CH" },
  { id: 9664, s: "3682.TW", n: "Asia Pacific Telecom", c: "TW" },
  { id: 9665, s: "3653.TW", n: "Jentech Precision Industrial", c: "TW" },
  { id: 9666, s: "BCN.AX", n: "Beacon Minerals", c: "Australia" },
  { id: 9667, s: "ADMCM.HE", n: "Admicom Oyj", c: "Finland" },
  { id: 9668, s: "603083.SS", n: "CIG ShangHai Co Class A", c: "CH" },
  { id: 9669, s: "ATEB.BR", n: "Atenor SA", c: "Belgium" },
  { id: 9670, s: "600837.SS", n: "Haitong Securities", c: "CH" },
  { id: 9671, s: "6166.TW", n: "ADLINK Technology Inc", c: "TW" },
  { id: 9672, s: "BOREO.HE", n: "Boreo Oyj", c: "Finland" },
  { id: 9673, s: "601689.SS", n: "Ningbo Tuopu Group", c: "CH" },
  { id: 9674, s: "PBH.AX", n: "Pointsbet Holdings", c: "Australia" },
  { id: 9675, s: "BIS.AX", n: "Bisalloy Steel Group", c: "Australia" },
  { id: 9676, s: "TUKAS.IS", n: "Tukas Gida Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 9677, s: "COMB.BR", n: "Compagnie du Bois Sauvage SA", c: "Belgium" },
  { id: 9678, s: "MBWS.PA", n: "Marie Brizard Wine & Spirits", c: "FR" },
  { id: 9679, s: "ORI.AX", n: "Orica", c: "Australia" },
  { id: 9680, s: "TEND3.SA", n: "Construtora Tenda S.A", c: "Brazil" },
  { id: 9681, s: "LPP.WA", n: "LPP S.A.", c: "Poland" },
  { id: 9682, s: "NFLX34.SA", n: "Netflix Inc", c: "Brazil" },
  { id: 9683, s: "EEG.AX", n: "Empire Energy Group", c: "Australia" },
  { id: 9684, s: "TGSAS.IS", n: "TGS Dis Ticaret AS", c: "Turkey" },
  { id: 9685, s: "BAHI3.SA", n: "Bahema Educação S.A", c: "Brazil" },
  { id: 9686, s: "112610.KS", n: "CS Wind Co", c: "Korea" },
  { id: 9687, s: "TOHN.SW", n: "Tornos Holding AG", c: "Switzerland" },
  { id: 9688, s: "IG6.AX", n: "International Graphite", c: "Australia" },
  { id: 9689, s: "000581.SZ", n: "Weifu High-Technology Group", c: "CH" },
  { id: 9690, s: "REALFI.ST", n: "Realfiction Holding AB", c: "Sweden" },
  { id: 9691, s: "VIO.BR", n: "Viohalco S.A", c: "Belgium" },
  { id: 9692, s: "PSD.TO", n: "Pulse Seismic Inc", c: "CA" },
  { id: 9693, s: "600299.SS", n: "Bluestar Adisseo", c: "CH" },
  { id: 9694, s: "CQR.AX", n: "Charter Hall Retail Reit", c: "Australia" },
  { id: 9695, s: "SDF.AX", n: "Steadfast Group", c: "Australia" },
  { id: 9696, s: "CQE.AX", n: "Charter Hall Education Trust", c: "Australia" },
  { id: 9697, s: "GIGANTE.MX", n: "Grupo Gigante S. A. B. de C. V", c: "Mexi" },
  { id: 9698, s: "688256.SS", n: "Cambricon Technologies Co", c: "CH" },
  { id: 9699, s: "GNRS.TA", n: "Generation Capital", c: "Israel" },
  { id: 9700, s: "ZOREN.IS", n: "Zorlu Enerji Elektrik Uretim AS", c: "Turkey" },
  { id: 9701, s: "ERRIA.", n: "Erria A/S", c: "Denmark" },
  { id: 9702, s: "KRE.", n: "Kreditbanken A/S", c: "Denmark" },
  { id: 9703, s: "FIBIH.TA", n: "FIBI Holdings", c: "Israel" },
  { id: 9704, s: "ALCLS.PA", n: "Cellectis", c: "FR" },
  { id: 9705, s: "COG.AX", n: "COG Financial Services", c: "Australia" },
  { id: 9706, s: "COD.AX", n: "Coda Minerals Ltd", c: "Australia" },
  { id: 9707, s: "MARI.TO", n: "Marimaca Copper Co", c: "CA" },
  { id: 9708, s: "PGN.DE", n: "paragon GmbH & Co. KGaA", c: "GER" },
  { id: 9709, s: "ELTR.TA", n: "Electra", c: "Israel" },
  { id: 9710, s: "CI.ST", n: "Cell Impact AB", c: "Sweden" },
  { id: 9711, s: "GFSA3.SA", n: "Gafisa S.A", c: "Brazil" },
  { id: 9712, s: "H2O.DE", n: "Enapter AG", c: "GER" },
  { id: 9713, s: "ACWN.DE", n: "A.S. Création Tapeten AG", c: "GER" },
  { id: 9714, s: "NIVI-B.ST", n: "Nivika Fastigheter AB Series B", c: "Sweden" },
  { id: 9715, s: "ZUGN.SW", n: "Zug Estates Holding AG", c: "Switzerland" },
  { id: 9716, s: "ENEA.ST", n: "Enea AB", c: "Sweden" },
  { id: 9717, s: "3529.TWO", n: "eMemory Technology", c: "TW" },
  { id: 9718, s: "5274.TWO", n: "Aspeed Technology", c: "TW" },
  { id: 9719, s: "005290.KQ", n: "Dongjin Semichem Co.", c: "Korea" },
  { id: 9720, s: "6125.TWO", n: "Kenmec Mechanical Engineering", c: "TW" },
  { id: 9721, s: "GLV.AX", n: "Global Oil & Gas", c: "Australia" },
  { id: 9722, s: "RICHT.BD", n: "RICHTER GEDEON CHE", c: "Hungary" },
  { id: 9723, s: "5425.TWO", n: "Taiwan Semiconductor", c: "TW" },
  { id: 9724, s: "5403.TWO", n: "Dimerco Data System", c: "TW" },
  { id: 9725, s: "DEX.DE", n: "Delticom AG", c: "GER" },
  { id: 9726, s: "EPI-A.ST", n: "Epiroc AB (publ)", c: "Sweden" },
  { id: 9727, s: "ACT.DE", n: "AlzChem Group AG", c: "GER" },
  { id: 9728, s: "TKBP.SW", n: "Thurgauer Kantonalbank", c: "Switzerland" },
  { id: 9729, s: "6570.TWO", n: "Aplex Technology", c: "TW" },
  { id: 9730, s: "BIOGAS.ST", n: "Scandinavian Biogas Fuels International AB", c: "Sweden" },
  { id: 9731, s: "009150.KS", n: "Samsung Electro-Mechanics", c: "Korea" },
  { id: 9732, s: "SOFW.TA", n: "Sofwave Medical", c: "Israel" },
  { id: 9733, s: "GWI2.DE", n: "GERRY WEBER International AG", c: "GER" },
  { id: 9734, s: "KZBGY.IS", n: "Kizilbuk Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 9735, s: "AGESA.IS", n: "Agesa Hayat ve Emeklilik AS", c: "Turkey" },
  { id: 9736, s: "ALIV-SDB.ST", n: "Autoliv Inc", c: "Sweden" },
  { id: 9737, s: "SNG.L", n: "Synairgen plc", c: "UK" },
  { id: 9738, s: "MERG.V", n: "Metal Energy Co", c: "CA" },
  { id: 9739, s: "300211.SZ", n: "Jiangsu Yitong High-tech", c: "CH" },
  { id: 9740, s: "AXN.AX", n: "Alliance Nickel.", c: "Australia" },
  { id: 9741, s: "BEIA-B.ST", n: "Beijer Alma AB (publ)", c: "Sweden" },
  { id: 9742, s: "5864.TWO", n: "Concord International Securities", c: "TW" },
  { id: 9743, s: "NXR.L", n: "Norcros Plc", c: "UK" },
  { id: 9744, s: "ELHA.AT", n: "Elvalhalcor Hellenic Copper and Aluminium Industry S.A", c: "Greece" },
  { id: 9745, s: "3504.TW", n: "Young Optics Inc", c: "TW" },
  { id: 9746, s: "NDEV", n: "Novus Acquisition and Development Co", c: "US" },
  { id: 9747, s: "002050.SZ", n: "Zhejiang Sanhua", c: "CH" },
  { id: 9748, s: "ELUXY", n: "Electrolux AB Class B ADR", c: "US" },
  { id: 9749, s: "CANTA.ST", n: "Cantargia AB", c: "Sweden" },
  { id: 9750, s: "001880.KS", n: "DL Construction", c: "Korea" },
  { id: 9751, s: "ENDV", n: "Endonovo Therapeutics Inc", c: "US" },
  { id: 9752, s: "ENGGY", n: "Enagas SA", c: "US" },
  { id: 9753, s: "002100.KS", n: "Kyung Nong Corporation", c: "Korea" },
  { id: 9754, s: "LMR.V", n: "Lomiko Metals Inc", c: "CA" },
  { id: 9755, s: "BEES3.SA", n: "Banestes S.A - Banco do Estado do Espírito Santo", c: "Brazil" },
  { id: 9756, s: "BET.AX", n: "Betmakers Technology Group", c: "Australia" },
  { id: 9757, s: "ANIM3.SA", n: "GAEC Educacao SA", c: "Brazil" },
  { id: 9758, s: "EMIL-B.ST", n: "Fastighetsbolaget Emilshus AB", c: "Sweden" },
  { id: 9759, s: "ASBFY", n: "Associated British Foods plc", c: "US" },
  { id: 9760, s: "091810.KS", n: "T'way Air", c: "Korea" },
  { id: 9761, s: "1473.TW", n: "Tainan Enterprises", c: "TW" },
  { id: 9762, s: "MEDICAB.MX", n: "Médica Sur S.A.B. de C.V", c: "Mexi" },
  { id: 9763, s: "A1M.AX", n: "AIC Mines Limited", c: "Australia" },
  { id: 9764, s: "METN.SW", n: "Metall Zug AG", c: "Switzerland" },
  { id: 9765, s: "CRBJY", n: "CH Resources Land ADR", c: "US" },
  { id: 9766, s: "2707.TW", n: "Formosa International Hotels Co", c: "TW" },
  { id: 9767, s: "MED.SW", n: "Medartis Holding AG", c: "Switzerland" },
  { id: 9768, s: "ECSL", n: "Ecare Solutions Inc", c: "US" },
  { id: 9769, s: "MTG.SW", n: "Meier Tobler Group AG", c: "Switzerland" },
  { id: 9770, s: "3RG.WA", n: "3R Games SA", c: "Poland" },
  { id: 9771, s: "SSON.L", n: "Smithson Investment Trust PLC", c: "UK" },
  { id: 9772, s: "BITE", n: "Bite Acquisition Co", c: "US" },
  { id: 9773, s: "KLD.V", n: "Kenorland Minerals", c: "CA" },
  { id: 9774, s: "PASO", n: "Patient Access Solutions Inc", c: "US" },
  { id: 9775, s: "SFRX", n: "Seafarer Exploration", c: "US" },
  { id: 9776, s: "TKO.PA", n: "Tikehau Capital", c: "FR" },
  { id: 9777, s: "601700.SS", n: "Fengfan Power", c: "CH" },
  { id: 9778, s: "BRKH", n: "BurTech Acquisition Co", c: "US" },
  { id: 9779, s: "CMSD", n: "CMS Energy Co", c: "US" },
  { id: 9780, s: "2347.TW", n: "Synnex Technology International Co", c: "TW" },
  { id: 9781, s: "IMMOU.BR", n: "Immo Moury SICAF Immobiliere Publique de Droit Belge", c: "Belgium" },
  { id: 9782, s: "TWLV", n: "Twelve Seas Investment Co II", c: "US" },
  { id: 9783, s: "030200.KS", n: "KT Corporation", c: "Korea" },
  { id: 9784, s: "018880.KS", n: "Hanon Systems", c: "Korea" },
  { id: 9785, s: "ORY.MC", n: "Oryzon Genomics SA", c: "Spain" },
  { id: 9786, s: "2332.TW", n: "D-Link Co", c: "TW" },
  { id: 9787, s: "ADK.V", n: "Diagnos Inc", c: "CA" },
  { id: 9788, s: "AAT.V", n: "ATI AirTest Technologies Inc", c: "CA" },
  { id: 9789, s: "601633.SS", n: "Great Wall Motor", c: "CH" },
  { id: 9790, s: "COVTY", n: "Covestro ADR", c: "US" },
  { id: 9791, s: "5EA.AX", n: "5E Advanced Materials Inc DRC", c: "Australia" },
  { id: 9792, s: "RWL.WA", n: "Rawlplug S.A.", c: "Poland" },
  { id: 9793, s: "MCRNT.TA", n: "Micronet", c: "Israel" },
  { id: 9794, s: "ATRLJ-B.ST", n: "Atrium Ljungberg AB (publ)", c: "Sweden" },
  { id: 9795, s: "NVMI.TA", n: "Nova", c: "Israel" },
  { id: 9796, s: "IGI", n: "Western Asset Investment Grade Defined Opportunity Closed Fund", c: "US" },
  { id: 9797, s: "GRC.TO", n: "Gold Springs Resource Co", c: "CA" },
  { id: 9798, s: "MCB.TO", n: "Mccoy Global Inc", c: "CA" },
  { id: 9799, s: "IMAQ", n: "International Media Acquisition Co", c: "US" },
  { id: 9800, s: "IFUS", n: "Impact Fusion International Inc", c: "US" },
  { id: 9801, s: "NOFR.TA", n: "OY Nofar Energy", c: "Israel" },
  { id: 9802, s: "M7T.AX", n: "Mach7 Technologies", c: "Australia" },
  { id: 9803, s: "IJJP", n: "Ijj Corporation", c: "US" },
  { id: 9804, s: "LIN.AX", n: "Lindian Resources", c: "Australia" },
  { id: 9805, s: "WRTBY", n: "Wartsila Oyj Abp ADR", c: "US" },
  { id: 9806, s: "HAMAT.TA", n: "Hamat Group", c: "Israel" },
  { id: 9807, s: "MGF.AX", n: "Magellan Global Fund Unit", c: "Australia" },
  { id: 9808, s: "LBL.AX", n: "LaserBond", c: "Australia" },
  { id: 9809, s: "ALX.AX", n: "Atlas Arteria", c: "Australia" },
  { id: 9810, s: "GAU.TO", n: "Galiano Gold Inc", c: "CA" },
  { id: 9811, s: "NXSN.TA", n: "Next Vision Stabilized Systems", c: "Israel" },
  { id: 9812, s: "HMPQ", n: "HempAmericana Inc", c: "US" },
  { id: 9813, s: "NOVC.TO", n: "Nova Cannabis Inc", c: "CA" },
  { id: 9814, s: "NUAG.TO", n: "New Pacific Metals Co", c: "CA" },
  { id: 9815, s: "MXC.AX", n: "MGC Pharmaceuticals", c: "Australia" },
  { id: 9816, s: "SMT.TA", n: "Summit", c: "Israel" },
  { id: 9817, s: "REKA.TA", n: "Rekah Pharmaceutical Industry", c: "Israel" },
  { id: 9818, s: "DNYA.TA", n: "Danya Cebus", c: "Israel" },
  { id: 9819, s: "PBP.AX", n: "Probiotec", c: "Australia" },
  { id: 9820, s: "KOMN.SW", n: "Komax Holding AG", c: "Switzerland" },
  { id: 9821, s: "CFX.TO", n: "Canfor Pulp Products Inc.", c: "CA" },
  { id: 9822, s: "FORTY.TA", n: "Formula", c: "Israel" },
  { id: 9823, s: "ESN.TO", n: "Essential Energy Services.", c: "CA" },
  { id: 9824, s: "PTNR.TA", n: "Partner", c: "Israel" },
  { id: 9825, s: "SHOM.TA", n: "EN Shoham Business", c: "Israel" },
  { id: 9826, s: "CLPHY", n: "CLP Holdings", c: "US" },
  { id: 9827, s: "RM.L", n: "RM Plc", c: "UK" },
  { id: 9828, s: "RKH.L", n: "Rockhopper Exploration", c: "UK" },
  { id: 9829, s: "AZRN.AS", n: "Azerion Group N.V.", c: "Netherlands" },
  { id: 9830, s: "600992.SS", n: "Guizhou Wire Rope", c: "CH" },
  { id: 9831, s: "ES.PA", n: "Esso S.A.F.", c: "FR" },
  { id: 9832, s: "600919.SS", n: "Bank of Jiangsu", c: "CH" },
  { id: 9833, s: "FRXI.V", n: "FRX Innovations Inc", c: "CA" },
  { id: 9834, s: "RAT.L", n: "Rathbone Brothers PLC", c: "UK" },
  { id: 9835, s: "PZC.L", n: "PZ Cussons PLC", c: "UK" },
  { id: 9836, s: "5388.TW", n: "Sercomm Co", c: "TW" },
  { id: 9837, s: "PMAM3.SA", n: "Paranapanema S.A", c: "Brazil" },
  { id: 9838, s: "ALSPW.PA", n: "Spineway", c: "FR" },
  { id: 9839, s: "PIN.L", n: "Pantheon International PLC", c: "UK" },
  { id: 9840, s: "ODPV3.SA", n: "Odontoprev S.A", c: "Brazil" },
  { id: 9841, s: "BUR.PA", n: "Burelle SA", c: "FR" },
  { id: 9842, s: "HANNF", n: "Hannan Metals", c: "US" },
  { id: 9843, s: "002142.SZ", n: "Bank of Ningbo", c: "CH" },
  { id: 9844, s: "DVSPF", n: "Dividend 15 Split Co", c: "US" },
  { id: 9845, s: "6282.TW", n: "Acbel Polytech Inc", c: "TW" },
  { id: 9846, s: "ATEME.PA", n: "ATEME SA", c: "FR" },
  { id: 9847, s: "ALTVO.PA", n: "Evolis", c: "FR" },
  { id: 9848, s: "PEP.WA", n: "PolEnergia S.A.", c: "Poland" },
  { id: 9849, s: "ARV.AX", n: "Artemis Resources", c: "Australia" },
  { id: 9850, s: "TURB.TA", n: "Turbogen Chp", c: "Israel" },
  { id: 9851, s: "ONEXF", n: "Onex Co", c: "US" },
  { id: 9852, s: "603399.SS", n: "Jinzhou Jixiang Molybdenum", c: "CH" },
  { id: 9853, s: "INA.SW", n: "Ina Invest Holding AG", c: "Switzerland" },
  { id: 9854, s: "MJLB", n: "Ultrack Systems Inc", c: "US" },
  { id: 9855, s: "VRX.AX", n: "VRX Silica", c: "Australia" },
  { id: 9856, s: "LAMM-B.ST", n: "Lammhults Design Group AB (publ)", c: "Sweden" },
  { id: 9857, s: "LCLEAN.ST", n: "Lifeclean International AB", c: "Sweden" },
  { id: 9858, s: "VHM.AX", n: "VHM.", c: "Australia" },
  { id: 9859, s: "MACK-B.ST", n: "Mackmyra Svensk Whisky AB Series B", c: "Sweden" },
  { id: 9860, s: "RAY-B.ST", n: "RaySearch Laboratories AB (publ)", c: "Sweden" },
  { id: 9861, s: "MNTN", n: "Everest Consolidator Acquisition Co", c: "US" },
  { id: 9862, s: "KMK.L", n: "Kromek Group PLC", c: "UK" },
  { id: 9863, s: "KLR.L", n: "Keller Group PLC", c: "UK" },
  { id: 9864, s: "KEFI.L", n: "KEFI Gold and Copper Plc", c: "UK" },
  { id: 9865, s: "CLZ.AX", n: "Classic Minerals", c: "Australia" },
  { id: 9866, s: "NXH.V", n: "Next Hydrogen Solutions Inc.", c: "CA" },
  { id: 9867, s: "ORX.ST", n: "Orexo AB", c: "Sweden" },
  { id: 9868, s: "HPG.AX", n: "Hipages Group Holdings Ltd", c: "Australia" },
  { id: 9869, s: "TPE.WA", n: "Tauron Polska Energia SA", c: "Poland" },
  { id: 9870, s: "GVR.IR", n: "Glenveagh Properties PLC", c: "Ireland" },
  { id: 9871, s: "PROF-B.ST", n: "ProfilGruppen AB (publ)", c: "Sweden" },
  { id: 9872, s: "ADE.DE", n: "Bitcoin Group SE", c: "GER" },
  { id: 9873, s: "GGDVY", n: "Guangdong Investment", c: "US" },
  { id: 9874, s: "GEN.L", n: "Genuit Group plc", c: "UK" },
  { id: 9875, s: "PPT.AX", n: "Perpetual", c: "Australia" },
  { id: 9876, s: "CGT.L", n: "Capital Gearing Trust", c: "UK" },
  { id: 9877, s: "ABCA.PA", n: "ABC arbitrage SA", c: "FR" },
  { id: 9878, s: "KNSW", n: "Knightswan Acquisition Co", c: "US" },
  { id: 9879, s: "SRI.V", n: "Sparton Resources Inc", c: "CA" },
  { id: 9880, s: "GWA.AX", n: "GWA Group", c: "Australia" },
  { id: 9881, s: "CAJ.AX", n: "Capitol Health", c: "Australia" },
  { id: 9882, s: "JETPAK.ST", n: "Jetpak Top Holding AB", c: "Sweden" },
  { id: 9883, s: "IVACC.ST", n: "Intervacc AB", c: "Sweden" },
  { id: 9884, s: "LTG.L", n: "Learning Technologies Group plc", c: "UK" },
  { id: 9885, s: "OSCI", n: "Osceola Gold Inc", c: "US" },
  { id: 9886, s: "RELV", n: "Reliv International Inc", c: "US" },
  { id: 9887, s: "SINT.ST", n: "SinterCast AB", c: "Sweden" },
  { id: 9888, s: "MBSC", n: "M3 Brigade Acquisition III Co", c: "US" },
  { id: 9889, s: "ANSLY", n: "Ansell ADR", c: "US" },
  { id: 9890, s: "PHA.V", n: "Premier Health of America Inc", c: "CA" },
  { id: 9891, s: "DRW3.DE", n: "Draegerwerk VZO O.N.", c: "GER" },
  { id: 9892, s: "CIOXY", n: "Cielo SA", c: "US" },
  { id: 9893, s: "SIGHT.PA", n: "Gensight Biologics SA", c: "FR" },
  { id: 9894, s: "THUNDR.ST", n: "Thunderful Group AB", c: "Sweden" },
  { id: 9895, s: "SWM.AX", n: "Seven West Media", c: "Australia" },
  { id: 9896, s: "MASN", n: "Maison Luxe Inc", c: "US" },
  { id: 9897, s: "SVY.AX", n: "Stavely Minerals", c: "Australia" },
  { id: 9898, s: "VERT-B.ST", n: "Vertiseit AB Series B", c: "Sweden" },
  { id: 9899, s: "SUIC", n: "AJ Greentech Holdings", c: "US" },
  { id: 9900, s: "VIMIAN.ST", n: "Vimian Group AB", c: "Sweden" },
  { id: 9901, s: "BEZ.DE", n: "Berentzen Gruppe AG", c: "GER" },
  { id: 9902, s: "BAGFS.IS", n: "Bagfas Bandirma Gubre Fabrikalari AS", c: "Turkey" },
  { id: 9903, s: "APPH.DE", n: "Apontis Pharma AG", c: "GER" },
  { id: 9904, s: "SRX.AX", n: "Sierra Rutile Holdings Limited", c: "Australia" },
  { id: 9905, s: "ULM.WA", n: "ULMA Construccion Polska SA", c: "Poland" },
  { id: 9906, s: "600588.SS", n: "Yonyou Network Technology", c: "CH" },
  { id: 9907, s: "TEN.WA", n: "TEN SQUARE GAMES SA", c: "Poland" },
  { id: 9908, s: "ALNLF.PA", n: "Neolife SA", c: "FR" },
  { id: 9909, s: "LXI.L", n: "LXI REIT plc", c: "UK" },
  { id: 9910, s: "KIGRY", n: "Kion Group AG ADR", c: "US" },
  { id: 9911, s: "600000.SS", n: "Shanghai Pudong Development Bank", c: "CH" },
  { id: 9912, s: "000652.SZ", n: "Tianjin Economic-technological Development Area", c: "CH" },
  { id: 9913, s: "1227.TW", n: "Standard Foods Co", c: "TW" },
  { id: 9914, s: "000550.SZ", n: "Jiangling Motors Co", c: "CH" },
  { id: 9915, s: "600048.SS", n: "Poly Real Estate Group", c: "CH" },
  { id: 9916, s: "SCN.AX", n: "Scorpion Minerals", c: "Australia" },
  { id: 9917, s: "FLN.AX", n: "Freelancer", c: "Australia" },
  { id: 9918, s: "ELMRA.OL", n: "Elmera Group ASA", c: "Norway" },
  { id: 9919, s: "RMX.AX", n: "Red Mountain Mining", c: "Australia" },
  { id: 9920, s: "087010.KQ", n: "Peptron Inc", c: "Korea" },
  { id: 9921, s: "ALCAR.PA", n: "Carmat", c: "FR" },
  { id: 9922, s: "WSP.AX", n: "Whispir", c: "Australia" },
  { id: 9923, s: "EVSV", n: "Enviro-Serv Inc", c: "US" },
  { id: 9924, s: "SNT.WA", n: "Synektik S.A.", c: "Poland" },
  { id: 9925, s: "ALPHA.PA", n: "Pharnext SA", c: "FR" },
  { id: 9926, s: "UNICY", n: "Unicharm Co", c: "US" },
  { id: 9927, s: "DASTF", n: "Dassault Systemes SE", c: "US" },
  { id: 9928, s: "MAU.PA", n: "Etablissements Maurel et Prom SA", c: "FR" },
  { id: 9929, s: "300315.SZ", n: "Ourpalm", c: "CH" },
  { id: 9930, s: "ADN.AX", n: "Andromeda Metals", c: "Australia" },
  { id: 9931, s: "ARA.MX", n: "Consorcio ARA S. A. B. de C. V.", c: "Mexi" },
  { id: 9932, s: "336260.KS", n: "Doosan Fuel Cell ", c: "Korea" },
  { id: 9933, s: "CLXN.SW", n: "Crealogix Holding AG", c: "Switzerland" },
  { id: 9934, s: "3027.TW", n: "Billion Electric", c: "TW" },
  { id: 9935, s: "DOKA.SW", n: "Dorma Kaba Holding AG", c: "Switzerland" },
  { id: 9936, s: "2049.TW", n: "Hiwin Technologies Co", c: "TW" },
  { id: 9937, s: "HUB.AX", n: "Hub24", c: "Australia" },
  { id: 9938, s: "PRPM", n: "Protek Capital Inc.", c: "US" },
  { id: 9939, s: "AJXA", n: "Great Ajax Corp. 7.25 CV SR NT 24", c: "US" },
  { id: 9940, s: "2207.TW", n: "Hotai Motor", c: "TW" },
  { id: 9941, s: "KID.OL", n: "Kid ASA", c: "Norway" },
  { id: 9942, s: "NONG.OL", n: "Sparebank 1 Nord-Norge", c: "Norway" },
  { id: 9943, s: "TLT.V", n: "Theralase Technologies Inc.", c: "CA" },
  { id: 9944, s: "SKAKO.", n: "SKAKO A/S", c: "Denmark" },
  { id: 9945, s: "CLIG.L", n: "City Of London Investment Group", c: "UK" },
  { id: 9946, s: "8420.TWO", n: "Launch Technologies", c: "TW" },
  { id: 9947, s: "GTOR", n: "Ggtoor Inc", c: "US" },
  { id: 9948, s: "600392.SS", n: "Shenghe Resources Holding", c: "CH" },
  { id: 9949, s: "JUN", n: "Juniper II Co", c: "US" },
  { id: 9950, s: "SGNL.TO", n: "Signal Gold Inc", c: "CA" },
  { id: 9951, s: "ATT.L", n: "Allianz Technology Trust PLC", c: "UK" },
  { id: 9952, s: "VIO.V", n: "Societe d Exploration Miniere Vior Inc", c: "CA" },
  { id: 9953, s: "AGR.VI", n: "AGRANA Beteiligungs-Aktiengesellschaft", c: "Austria" },
  { id: 9954, s: "VM.V", n: "Voyageur Pharmaceuticals", c: "CA" },
  { id: 9955, s: "SREA", n: "DBA Sempra 5.750% Junior Subordinated Notes due 2079", c: "US" },
  { id: 9956, s: "NAVA.OL", n: "Navamedic ASA", c: "Norway" },
  { id: 9957, s: "AMIGY", n: "Admiral Group PLC ADR", c: "US" },
  { id: 9958, s: "SFX.AX", n: "Sheffield Resources", c: "Australia" },
  { id: 9959, s: "GAMA.L", n: "Gamma Communications PLC", c: "UK" },
  { id: 9960, s: "1229.TW", n: "Lien Hwa Industrial Co", c: "TW" },
  { id: 9961, s: "EOS.AX", n: "Electro Optic Systems Holdings", c: "Australia" },
  { id: 9962, s: "SKF.AX", n: "Skyfii", c: "Australia" },
  { id: 9963, s: "9904.TW", n: "Pou Chen Co", c: "TW" },
  { id: 9964, s: "GFOO", n: "Genufood Energy", c: "US" },
  { id: 9965, s: "ALHAF.PA", n: "Haffner Energy SA", c: "FR" },
  { id: 9966, s: "ROCK-A.", n: "ROCKWOOL International A/S", c: "Denmark" },
  { id: 9967, s: "ALSMY", n: "Alstom PK", c: "US" },
  { id: 9968, s: "NTEL.OL", n: "Nortel As", c: "Norway" },
  { id: 9969, s: "PROXI.OL", n: "Proximar Seafood AS", c: "Norway" },
  { id: 9970, s: "AB.PA", n: "AB Science S.A", c: "FR" },
  { id: 9971, s: "2911.TW", n: "Les Enphants", c: "TW" },
  { id: 9972, s: "RPMG3.SA", n: "Refinaria de Petróleos de Manguinhos S.A", c: "Brazil" },
  { id: 9973, s: "SAA.L", n: "M&C Saatchi", c: "UK" },
  { id: 9974, s: "NLG1V.HE", n: "Nurminen Logistics Oyj", c: "Finland" },
  { id: 9975, s: "RAM.LS", n: "Ramada Investimentos e Industria S.A", c: "Portugal" },
  { id: 9976, s: "SUTI", n: "Sutimco International Inc", c: "US" },
  { id: 9977, s: "PHR.LS", n: "Pharol SGPS SA", c: "Portugal" },
  { id: 9978, s: "SOU.V", n: "Southern Energy Co", c: "CA" },
  { id: 9979, s: "3092.TW", n: "Hotron Precision Electronic Industrial", c: "TW" },
  { id: 9980, s: "2889.TW", n: "IBF Financial Holdings", c: "TW" },
  { id: 9981, s: "PON1V.HE", n: "Ponsse Oyj 1", c: "Finland" },
  { id: 9982, s: "RNK.L", n: "Rank Group PLC", c: "UK" },
  { id: 9983, s: "CYAP", n: "Cyber Apps World Inc", c: "US" },
  { id: 9984, s: "MVF.AX", n: "Monash IVF Group", c: "Australia" },
  { id: 9985, s: "6589.TWO", n: "EirGenix", c: "TW" },
  { id: 9986, s: "CESDF", n: "CES Energy Solutions Co", c: "US" },
  { id: 9987, s: "600507.SS", n: "Fangda Special Steel Technology", c: "CH" },
  { id: 9988, s: "3703.TW", n: "Continental Holdings Co", c: "TW" },
  { id: 9989, s: "AMN.AX", n: "Agrimin", c: "Australia" },
  { id: 9990, s: "AJQ.AX", n: "Armour Energy", c: "Australia" },
  { id: 9991, s: "BPT.L", n: "Bridgepoint Group Plc", c: "UK" },
  { id: 9992, s: "TXGN.SW", n: "TX Group AG", c: "Switzerland" },
  { id: 9993, s: "PRCNF", n: "Pure Global Cannabis Inc", c: "US" },
  { id: 9994, s: "SCHP.SW", n: "Schindler Ps", c: "Switzerland" },
  { id: 9995, s: "DLYT", n: "Dais Analytic Co", c: "US" },
  { id: 9996, s: "TIBN.SW", n: "Bergbahnen Engelberg Truebsee Titlis Bet AG", c: "Switzerland" },
  { id: 9997, s: "KNE.V", n: "Kane Biotech Inc", c: "CA" },
  { id: 9998, s: "BBN.SW", n: "Bellevue Group AG", c: "Switzerland" },
  { id: 9999, s: "TECN3.SA", n: "Technos S.A", c: "Brazil" },
  { id: 10000, s: "600490.SS", n: "Pengxin International Mining", c: "CH" },
  { id: 10001, s: "000725.SZ", n: "BOE Technology Group", c: "CH" },
  { id: 10002, s: "CLTN.SW", n: "Coltene Holding AG", c: "Switzerland" },
  { id: 10003, s: "MAW.TO", n: "Mawson Gold", c: "CA" },
  { id: 10004, s: "ACB.AX", n: "A-Cap Energy", c: "Australia" },
  { id: 10005, s: "SKF-A.ST", n: "AB SKF (publ)", c: "Sweden" },
  { id: 10006, s: "AUSI", n: "Aura Systems Inc", c: "US" },
  { id: 10007, s: "SRR.V", n: "Source Rock Royalties", c: "CA" },
  { id: 10008, s: "EVT.AX", n: "Event Hospitality and Entertainment", c: "Australia" },
  { id: 10009, s: "MVP.TO", n: "MediaValet Inc", c: "CA" },
  { id: 10010, s: "DEV.AX", n: "DevEx Resources", c: "Australia" },
  { id: 10011, s: "601238.SS", n: "Guangzhou Automobile Group Co Class A", c: "CH" },
  { id: 10012, s: "IGAS.L", n: "IGas Energy PLC", c: "UK" },
  { id: 10013, s: "NVA.AX", n: "Nova Minerals", c: "Australia" },
  { id: 10014, s: "NLM.F", n: "FRoSTA Aktiengesellschaft", c: "GER" },
  { id: 10015, s: "AMAR3.SA", n: "Marisa Lojas S.A", c: "Brazil" },
  { id: 10016, s: "BKI.AX", n: "BKI Investment Company", c: "Australia" },
  { id: 10017, s: "PGZ.V", n: "Pan Global Resources Inc", c: "CA" },
  { id: 10018, s: "BKW.AX", n: "Brickworks", c: "Australia" },
  { id: 10019, s: "3260.TWO", n: "Adata Technology", c: "TW" },
  { id: 10020, s: "ATE.TO", n: "Antibe Therapeutics Inc.", c: "CA" },
  { id: 10021, s: "APSI", n: "Aqua Power Systems Inc", c: "US" },
  { id: 10022, s: "3380.TW", n: "Alpha Networks Inc", c: "TW" },
  { id: 10023, s: "000014.SZ", n: "Shahe Industry", c: "CH" },
  { id: 10024, s: "600579.SS", n: "KraussMaffei", c: "CH" },
  { id: 10025, s: "CFF.TO", n: "Conifex Timber Inc", c: "CA" },
  { id: 10026, s: "OCDGF", n: "Ocado Group PLC", c: "US" },
  { id: 10027, s: "CASP.L", n: "Caspian Sunrise plc", c: "UK" },
  { id: 10028, s: "688385.SS", n: "Shanghai Fudan Microelectronics Group", c: "CH" },
  { id: 10029, s: "STX.L", n: "Shield Therapeutics plc", c: "UK" },
  { id: 10030, s: "SSRM.TO", n: "SSR Mining Inc", c: "CA" },
  { id: 10031, s: "006110.KS", n: "Sam-A Aluminiu", c: "Korea" },
  { id: 10032, s: "GSPI", n: "Green Star Products", c: "US" },
  { id: 10033, s: "JDSPY", n: "JD Sports Fashion PLC", c: "US" },
  { id: 10034, s: "SSMXY", n: "Sysmex Co", c: "US" },
  { id: 10035, s: "SRL.V", n: "Salazar Resources Limited", c: "CA" },
  { id: 10036, s: "BAG.L", n: "A.G.Barr PLC", c: "UK" },
  { id: 10037, s: "SPOL.OL", n: "SpareBank 1 Østlandet", c: "Norway" },
  { id: 10038, s: "SEER3.SA", n: "Ser Educacional Sa", c: "Brazil" },
  { id: 10039, s: "041520.KQ", n: "e-LITECOM CO.", c: "Korea" },
  { id: 10040, s: "JBFCY", n: "Jollibee Foods Corp ADR", c: "US" },
  { id: 10041, s: "HESG", n: "Health Sciences Gr", c: "US" },
  { id: 10042, s: "XYIGY", n: "Xinyi Glass Holdings", c: "US" },
  { id: 10043, s: "SLV.WA", n: "Selvita S.A.", c: "Poland" },
  { id: 10044, s: "TNR.V", n: "TNR Gold Corp.", c: "CA" },
  { id: 10045, s: "IOD.AX", n: "Iodm", c: "Australia" },
  { id: 10046, s: "003816.SZ", n: "CGN Power", c: "CH" },
  { id: 10047, s: "KAMUX.HE", n: "Kamux Suomi Oy", c: "Finland" },
  { id: 10048, s: "RMC.AX", n: "Resimac Group", c: "Australia" },
  { id: 10049, s: "CAI.VI", n: "CA Immobilien Anlagen AG", c: "Austria" },
  { id: 10050, s: "KRDMB.IS", n: "Kardemir Karabuk Demir Celik Sanayi ve Ticaret AS Class B", c: "Turkey" },
  { id: 10051, s: "ENSU.OL", n: "Ensurge Micropower ASA", c: "Norway" },
  { id: 10052, s: "PPS.L", n: "Proton Power Systems PLC", c: "UK" },
  { id: 10053, s: "CVSG.L", n: "CVS Group Plc", c: "UK" },
  { id: 10054, s: "OPVS", n: "NanoFlex Power Co", c: "US" },
  { id: 10055, s: "ALVGO.PA", n: "VOGO SA", c: "FR" },
  { id: 10056, s: "EQVA.OL", n: "Eqva ASA", c: "Norway" },
  { id: 10057, s: "MLTM.TA", n: "Malam Team", c: "Israel" },
  { id: 10058, s: "SLIGR.AS", n: "Sligro Food Group NV", c: "Netherlands" },
  { id: 10059, s: "SSH1V.HE", n: "SSH Communications Security Oyj", c: "Finland" },
  { id: 10060, s: "600782.SS", n: "Xinyu Iron & Steel", c: "CH" },
  { id: 10061, s: "688690.SS", n: "Suzhou Nanomicro Technology", c: "CH" },
  { id: 10062, s: "5007.TW", n: "San Shing Fastech Co", c: "TW" },
  { id: 10063, s: "BOUV.OL", n: "Bouvet", c: "Norway" },
  { id: 10064, s: "ISVLF", n: "IMPACT Silver Corp.", c: "US" },
  { id: 10065, s: "3264.TWO", n: "Ardentec", c: "TW" },
  { id: 10066, s: "002400.SZ", n: "Guangdong Advertising", c: "CH" },
  { id: 10067, s: "KREVF", n: "Keppel REIT", c: "US" },
  { id: 10068, s: "ALMGI.PA", n: "Mg Internation", c: "FR" },
  { id: 10069, s: "GDF.AX", n: "Garda Diversified Ppty Fund", c: "Australia" },
  { id: 10070, s: "KRYAY", n: "Kerry Group PLC ADR", c: "US" },
  { id: 10071, s: "CURR", n: "Cure Pharmaceutical Holding Co", c: "US" },
  { id: 10072, s: "003035.SZ", n: "CH Southern Power Grid Energy Efficiency & Clean Energy", c: "CH" },
  { id: 10073, s: "TOA.WA", n: "Toya SA", c: "Poland" },
  { id: 10074, s: "ALNOV.PA", n: "Novacyt", c: "FR" },
  { id: 10075, s: "SWDBY", n: "Swedbank AB", c: "US" },
  { id: 10076, s: "PPHP", n: "PHP Ventures Acquisition Co", c: "US" },
  { id: 10077, s: "HYDI", n: "Hydromer Inc", c: "US" },
  { id: 10078, s: "RECI.L", n: "Real Estate Credit Investments Limited", c: "UK" },
  { id: 10079, s: "TEK.L", n: "Tekcapital plc", c: "UK" },
  { id: 10080, s: "IFCN.SW", n: "Inficon Holding", c: "Switzerland" },
  { id: 10081, s: "SCMWY", n: "SwissCom AG", c: "US" },
  { id: 10082, s: "3706.TW", n: "MiTAC Holdings Co", c: "TW" },
  { id: 10083, s: "HLDCY", n: "Henderson Land Development", c: "US" },
  { id: 10084, s: "JAX.V", n: "Jaxon Mining Inc", c: "CA" },
  { id: 10085, s: "ALTHX.PA", n: "Theranexus SA", c: "FR" },
  { id: 10086, s: "HUDL.OL", n: "Huddlestock Fintech As", c: "Norway" },
  { id: 10087, s: "2890.TW", n: "Sinopac Financial Holdings", c: "TW" },
  { id: 10088, s: "SM.V", n: "Sierra Madre Gold and Silver", c: "CA" },
  { id: 10089, s: "HPR.AX", n: "High Peak Royalties", c: "Australia" },
  { id: 10090, s: "GSPE", n: "GulfSlope Energy Inc", c: "US" },
  { id: 10091, s: "TLG.TO", n: "Troilus Gold Co", c: "CA" },
  { id: 10092, s: "VNET.L", n: "Vianet Group Plc", c: "UK" },
  { id: 10093, s: "PLNT.TA", n: "Plantarc Bio", c: "Israel" },
  { id: 10094, s: "HMX.AX", n: "Hammer Metals", c: "Australia" },
  { id: 10095, s: "VAHN.SW", n: "Vaudoise Assurances Holding SA", c: "Switzerland" },
  { id: 10096, s: "AUTN.SW", n: "Autoneum Holding AG", c: "Switzerland" },
  { id: 10097, s: "SSOF", n: "Sixty Six Oilfield Services Inc", c: "US" },
  { id: 10098, s: "APGN.SW", n: "APG SGA SA", c: "Switzerland" },
  { id: 10099, s: "200771.SZ", n: "Hangzhou Steam Turbine", c: "CH" },
  { id: 10100, s: "AGM-A", n: "Federal Agricultural Mortgage Corporation", c: "US" },
  { id: 10101, s: "ACRI-B.ST", n: "Acrinova AB Series B", c: "Sweden" },
  { id: 10102, s: "5536.TWO", n: "Acter", c: "TW" },
  { id: 10103, s: "MXC.WA", n: "Maxcom SA", c: "Poland" },
  { id: 10104, s: "5285.TW", n: "Jih Lin Technology", c: "TW" },
  { id: 10105, s: "000568.SZ", n: "Luzhou Lao Jiao", c: "CH" },
  { id: 10106, s: "SEA.TO", n: "Seabridge Gold Inc.", c: "CA" },
  { id: 10107, s: "ROBIT.HE", n: "Robit Oyj", c: "Finland" },
  { id: 10108, s: "TFW.L", n: "FW Thorpe PLC", c: "UK" },
  { id: 10109, s: "TIR.L", n: "Tiger Royalties and investments Plc", c: "UK" },
  { id: 10110, s: "EVN.VI", n: "EVN AG", c: "Austria" },
  { id: 10111, s: "SCDX.TA", n: "Scodix", c: "Israel" },
  { id: 10112, s: "BYW.DE", n: "BayWa AktienGesellschaft", c: "GER" },
  { id: 10113, s: "2GB.F", n: "2G Energy AG", c: "GER" },
  { id: 10114, s: "SOHOB", n: "Sotherly Hotels Inc Series B Pref", c: "US" },
  { id: 10115, s: "HKY.OL", n: "Havila Kystruten AS", c: "Norway" },
  { id: 10116, s: "CSQ.DE", n: "creditshelf Aktiengesellschaft", c: "GER" },
  { id: 10117, s: "QUAL3.SA", n: "Qualicorp Consultoria e Corretora de Seguros S.A", c: "Brazil" },
  { id: 10118, s: "601328.SS", n: "Bank of Communications", c: "CH" },
  { id: 10119, s: "002152.SZ", n: "GRG Banking Equipment", c: "CH" },
  { id: 10120, s: "189300.KQ", n: "Intellian Technologies Inc", c: "Korea" },
  { id: 10121, s: "000538.SZ", n: "Yunnan Baiyao Group", c: "CH" },
  { id: 10122, s: "000895.SZ", n: "Henan Shuanghui Investment & Development", c: "CH" },
  { id: 10123, s: "LUDN.TA", n: "Ludan Engineering Co.", c: "Israel" },
  { id: 10124, s: "AAG.V", n: "Aftermath Silver", c: "CA" },
  { id: 10125, s: "PARKEN.", n: "PARKEN Sport & Entertainment", c: "Denmark" },
  { id: 10126, s: "MEGA3.SA", n: "Omega Energia S.A.", c: "Brazil" },
  { id: 10127, s: "016360.KS", n: "Samsung Securities", c: "Korea" },
  { id: 10128, s: "ETM.AX", n: "Energy Transition Minerals", c: "Australia" },
  { id: 10129, s: "009520.KQ", n: "POSCO M-TECH Co.", c: "Korea" },
  { id: 10130, s: "MEAL3.SA", n: "International Meal Company Alimentação S.A", c: "Brazil" },
  { id: 10131, s: "SKJE.", n: "Skjern Bank A/S", c: "Denmark" },
  { id: 10132, s: "SGI.AX", n: "Stealth Global Holdings", c: "Australia" },
  { id: 10133, s: "PEG.L", n: "Petards Group plc", c: "UK" },
  { id: 10134, s: "KDST.TA", n: "Kadimastem", c: "Israel" },
  { id: 10135, s: "NUSMF", n: "Nautilus Minerals Inc", c: "US" },
  { id: 10136, s: "SGF.AX", n: "SG Fleet Group", c: "Australia" },
  { id: 10137, s: "RTX.", n: "RTX A/S", c: "Denmark" },
  { id: 10138, s: "AFPW", n: "Alumifuel Pwr Corp.", c: "US" },
  { id: 10139, s: "600999.SS", n: "CH Merchants Securities", c: "CH" },
  { id: 10140, s: "ATT.ST", n: "Attendo AB (publ)", c: "Sweden" },
  { id: 10141, s: "ARG.AX", n: "Argo Investments", c: "Australia" },
  { id: 10142, s: "DIA.MC", n: "Distribuidora Internacional de Alimentación S.A", c: "Spain" },
  { id: 10143, s: "EGGF", n: "EG Acquisition Co", c: "US" },
  { id: 10144, s: "PVSP", n: "Pervasip Co", c: "US" },
  { id: 10145, s: "AUSA.TA", n: "Aura Smart Air", c: "Israel" },
  { id: 10146, s: "SDG.AX", n: "Sunland Group", c: "Australia" },
  { id: 10147, s: "AMBS", n: "Amarantus Bioscience Holdings Inc", c: "US" },
  { id: 10148, s: "ARB.AX", n: "ARB Corporation", c: "Australia" },
  { id: 10149, s: "AUN.AX", n: "Aurumin Ltd", c: "Australia" },
  { id: 10150, s: "BINV.ST", n: "BioInvent International AB", c: "Sweden" },
  { id: 10151, s: "EMU.AX", n: "Emu NL", c: "Australia" },
  { id: 10152, s: "BLND.TA", n: "Blender Financial Technologies", c: "Israel" },
  { id: 10153, s: "SEII", n: "Sharing Economy International Inc", c: "US" },
  { id: 10154, s: "034950.KQ", n: "Korea Ratings Co.", c: "Korea" },
  { id: 10155, s: "ITUB3.SA", n: "Itaú Unibanco Holding S.A", c: "Brazil" },
  { id: 10156, s: "003230.KS", n: "Sam Yang Foods", c: "Korea" },
  { id: 10157, s: "VJBA.", n: "Vestjysk Bank A/S", c: "Denmark" },
  { id: 10158, s: "CYB.TO", n: "Cymbria Corporation", c: "CA" },
  { id: 10159, s: "300745.SZ", n: "Shinry Technologies Co Class A", c: "CH" },
  { id: 10160, s: "004430.KS", n: "Songwon Industrial", c: "Korea" },
  { id: 10161, s: "SYAB.DE", n: "Synlab AG", c: "GER" },
  { id: 10162, s: "BONEX.ST", n: "Bonesupport Holding AB", c: "Sweden" },
  { id: 10163, s: "JBNK.TA", n: "Jerusalem", c: "Israel" },
  { id: 10164, s: "YFI.V", n: "Edgewater Wireless Systems Inc", c: "CA" },
  { id: 10165, s: "ROYMY", n: "Royal Mail PLC ADR", c: "US" },
  { id: 10166, s: "AVA.AX", n: "Ava Risk Group", c: "Australia" },
  { id: 10167, s: "RDIB", n: "Reading International B Inc", c: "US" },
  { id: 10168, s: "ISCD.TA", n: "Isracard", c: "Israel" },
  { id: 10169, s: "EUROC.AT", n: "Euroconsultants SA", c: "Greece" },
  { id: 10170, s: "601018.SS", n: "Ningbo Zhoushan Port", c: "CH" },
  { id: 10171, s: "BLTZ.TA", n: "Blitz Technologies", c: "Israel" },
  { id: 10172, s: "300751.SZ", n: "Suzhou Maxwell Technologies Co Class A", c: "CH" },
  { id: 10173, s: "300759.SZ", n: "Pharmaron Beijing", c: "CH" },
  { id: 10174, s: "EXHI", n: "Exlites Holdings International Inc", c: "US" },
  { id: 10175, s: "1795.TW", n: "Lotus Pharmaceutical", c: "TW" },
  { id: 10176, s: "AR3.AX", n: "Australian Rare Earths", c: "Australia" },
  { id: 10177, s: "EDIN.L", n: "Edinburgh Investment Trust", c: "UK" },
  { id: 10178, s: "EMPR.V", n: "Empress Royalty Co", c: "CA" },
  { id: 10179, s: "EMLD", n: "FTAC Emerald Acquisition Co", c: "US" },
  { id: 10180, s: "AW1.AX", n: "American West Metals", c: "Australia" },
  { id: 10181, s: "002790.SZ", n: "Xiamen R&T Plumbing Technology", c: "CH" },
  { id: 10182, s: "AUIS.TA", n: "ES Australia Israel Holdings", c: "Israel" },
  { id: 10183, s: "GMS.L", n: "Gulf Marine Services PLC", c: "UK" },
  { id: 10184, s: "VAN.BR", n: "Van de Velde NV", c: "Belgium" },
  { id: 10185, s: "E25.AX", n: "Element 25", c: "Australia" },
  { id: 10186, s: "UPG.BR", n: "Unifiedpost Group SA", c: "Belgium" },
  { id: 10187, s: "GEBHY", n: "Genting Berhad", c: "US" },
  { id: 10188, s: "ABE.WA", n: "AB S.A.", c: "Poland" },
  { id: 10189, s: "SSG.AX", n: "Shaver Shop Group", c: "Australia" },
  { id: 10190, s: "SSM.AX", n: "Service Stream", c: "Australia" },
  { id: 10191, s: "XVIVO.ST", n: "Xvivo Perfusion AB", c: "Sweden" },
  { id: 10192, s: "6202.TW", n: "Holtek Semiconductor Inc", c: "TW" },
  { id: 10193, s: "AIZN", n: "Assurant Inc", c: "US" },
  { id: 10194, s: "NPSCY", n: "Nippon Steel Corp ADR", c: "US" },
  { id: 10195, s: "097950.KS", n: "CJ Cheiljedang", c: "Korea" },
  { id: 10196, s: "EVGN.V", n: "EverGen Infrastructure Co", c: "CA" },
  { id: 10197, s: "GAHC", n: "Global Arena Holding Inc", c: "US" },
  { id: 10198, s: "ZAC.V", n: "Zacatecas Silver Co", c: "CA" },
  { id: 10199, s: "AZR.V", n: "Azarga Metals Co", c: "CA" },
  { id: 10200, s: "PLUR.V", n: "Plurilock Security Inc", c: "CA" },
  { id: 10201, s: "SVED-B.ST", n: "Svedbergs i Dalstorp AB (publ)", c: "Sweden" },
  { id: 10202, s: "BYON.TA", n: "Beyon 3D", c: "Israel" },
  { id: 10203, s: "RJDG", n: "Rjd Green Inc", c: "US" },
  { id: 10204, s: "QABSY", n: "Qantas Airways ADR", c: "US" },
  { id: 10205, s: "DXB.AX", n: "Dimerix", c: "Australia" },
  { id: 10206, s: "GIX.TA", n: "Gix Internet", c: "Israel" },
  { id: 10207, s: "055550.KS", n: "Shinhan Financial Group", c: "Korea" },
  { id: 10208, s: "JOMA.ST", n: "John Mattson Fastighetsforetagen AB", c: "Sweden" },
  { id: 10209, s: "138490.KS", n: "Kolon Plastics", c: "Korea" },
  { id: 10210, s: "KTN.DE", n: "Kontron AG", c: "GER" },
  { id: 10211, s: "KAR.ST", n: "Karnov Group AB", c: "Sweden" },
  { id: 10212, s: "NETI-B.ST", n: "Net Insight AB (publ)", c: "Sweden" },
  { id: 10213, s: "DIOS.ST", n: "Dios Fastigheter AB", c: "Sweden" },
  { id: 10214, s: "BCB.AX", n: "Bowen Coking Coal Ltd", c: "Australia" },
  { id: 10215, s: "PPAAY", n: "Perusahaan Gas Negara Persero Tbk PT ADR", c: "US" },
  { id: 10216, s: "MNKS.L", n: "Monks Investment Trust PLC", c: "UK" },
  { id: 10217, s: "NICL.L", n: "Nichols", c: "UK" },
  { id: 10218, s: "FASTAT.ST", n: "Fastator AB", c: "Sweden" },
  { id: 10219, s: "MRN.PA", n: "Mersen SA", c: "FR" },
  { id: 10220, s: "300676.SZ", n: "BGI Genomics", c: "CH" },
  { id: 10221, s: "STAR-A.ST", n: "Starbreeze AB (publ)", c: "Sweden" },
  { id: 10222, s: "STIL.ST", n: "Stille AB", c: "Sweden" },
  { id: 10223, s: "PLRB.V", n: "Pluribus Technologies Co", c: "CA" },
  { id: 10224, s: "LTSH", n: "Ladenburg Thalmann Financial Se", c: "US" },
  { id: 10225, s: "002024.SZ", n: "Suning Commerce Group", c: "CH" },
  { id: 10226, s: "DTZ.AX", n: "Dotz Nano", c: "Australia" },
  { id: 10227, s: "LCLP", n: "Life Clips Inc", c: "US" },
  { id: 10228, s: "EDDY.V", n: "Edison Lithium Co", c: "CA" },
  { id: 10229, s: "ODAS.IS", n: "ODAS Elektrik Uretim Sanayi Ticaret AS", c: "Turkey" },
  { id: 10230, s: "140860.KQ", n: "Parksystems Co", c: "Korea" },
  { id: 10231, s: "CAML3.SA", n: "Camil Alimentos S.A", c: "Brazil" },
  { id: 10232, s: "LPD.AX", n: "Lepidi", c: "Australia" },
  { id: 10233, s: "DUNI.ST", n: "Duni AB", c: "Sweden" },
  { id: 10234, s: "DORL.TA", n: "Doral Group Renewable Energy Resources", c: "Israel" },
  { id: 10235, s: "ASCE.BR", n: "Ascencio (D)", c: "Belgium" },
  { id: 10236, s: "MWWC", n: "Marketing Worldwide", c: "US" },
  { id: 10237, s: "FSNB", n: "Fusion Acquisition Corp II", c: "US" },
  { id: 10238, s: "NWH.AX", n: "NRW Holdings", c: "Australia" },
  { id: 10239, s: "OPHR.V", n: "Ophir Gold Co", c: "CA" },
  { id: 10240, s: "FPAR-A.ST", n: "FastPartner AB (publ)", c: "Sweden" },
  { id: 10241, s: "CNTL.TA", n: "Continual", c: "Israel" },
  { id: 10242, s: "BVA.V", n: "Bravada Gold Corporation", c: "CA" },
  { id: 10243, s: "CMSQY", n: "Computershare ADR", c: "US" },
  { id: 10244, s: "TMH.AX", n: "Market Herald", c: "Australia" },
  { id: 10245, s: "WGN.AX", n: "Wagners Holding Company", c: "Australia" },
  { id: 10246, s: "3374.TWO", n: "Xintec", c: "TW" },
  { id: 10247, s: "TOY.AX", n: "Toys R Us ANZ Limited", c: "Australia" },
  { id: 10248, s: "VOIS", n: "Mind Solutions Inc", c: "US" },
  { id: 10249, s: "LOOP.L", n: "LoopUp Group PLC", c: "UK" },
  { id: 10250, s: "UMG.AX", n: "United Malt Group", c: "Australia" },
  { id: 10251, s: "161390.KS", n: "Hankook Tire", c: "Korea" },
  { id: 10252, s: "CABGY", n: "Carlsberg AS", c: "US" },
  { id: 10253, s: "PLAZ-B.ST", n: "Platzer Fastigheter Holding AB (publ)", c: "Sweden" },
  { id: 10254, s: "CYAD.BR", n: "Celyad SA", c: "Belgium" },
  { id: 10255, s: "4174.TWO", n: "OBI Pharma", c: "TW" },
  { id: 10256, s: "KGH.L", n: "Knights Group Holdings plc", c: "UK" },
  { id: 10257, s: "SAVE.PA", n: "Savencia SA", c: "FR" },
  { id: 10258, s: "DIPL.TA", n: "Diplomat Holdings", c: "Israel" },
  { id: 10259, s: "MEDHLP.ST", n: "Medhelp Care AB", c: "Sweden" },
  { id: 10260, s: "CDT.AX", n: "Castle Minerals", c: "Australia" },
  { id: 10261, s: "SEFER.PA", n: "Sergeferrari G", c: "FR" },
  { id: 10262, s: "MONOY", n: "Monotaro", c: "US" },
  { id: 10263, s: "S30.PA", n: "Solutions 30 SE", c: "FR" },
  { id: 10264, s: "CVV.V", n: "CanAlaska Uranium", c: "CA" },
  { id: 10265, s: "000792.SZ", n: "Qinghai Salt Lake Industry Co.Ltd", c: "CH" },
  { id: 10266, s: "LNK.AX", n: "Link Administration Hldg", c: "Australia" },
  { id: 10267, s: "300603.SZ", n: "Leon Technology", c: "CH" },
  { id: 10268, s: "DECB.BR", n: "Deceuninck", c: "Belgium" },
  { id: 10269, s: "CNQ.AX", n: "Clean TeQ Water Limited", c: "Australia" },
  { id: 10270, s: "CGEO.L", n: "Georgia Capital PLC", c: "UK" },
  { id: 10271, s: "FFN-PA.TO", n: "North American Financial 15 Split Co", c: "CA" },
  { id: 10272, s: "OSUN.OL", n: "Ocean Sun As", c: "Norway" },
  { id: 10273, s: "FTE.WA", n: "Fabryki Mebli Forte S.A.", c: "Poland" },
  { id: 10274, s: "GOW.AX", n: "Gowing Bros", c: "Australia" },
  { id: 10275, s: "000529.SZ", n: "Guangdong Guanghong Holdings", c: "CH" },
  { id: 10276, s: "6182.TWO", n: "Wafer Works", c: "TW" },
  { id: 10277, s: "1309.TW", n: "Taita Chemical", c: "TW" },
  { id: 10278, s: "300129.SZ", n: "Shanghai Taisheng Wind Power", c: "CH" },
  { id: 10279, s: "FORZ.TO", n: "Forza Petroleum", c: "CA" },
  { id: 10280, s: "CCEP.L", n: "Coca-Cola Europacific Partners PLC", c: "UK" },
  { id: 10281, s: "PEG.", n: "Pharma Equity Group A/S Cl A", c: "Denmark" },
  { id: 10282, s: "FCH.L", n: "Funding Circle Holdings PLC", c: "UK" },
  { id: 10283, s: "REVO.V", n: "RevoluGROUP CA Inc", c: "CA" },
  { id: 10284, s: "ECEL.L", n: "Eurocell PLC", c: "UK" },
  { id: 10285, s: "SFC.AX", n: "Schaffer Corporation", c: "Australia" },
  { id: 10286, s: "AJINY", n: "Ajinomoto Co Inc ADR", c: "US" },
  { id: 10287, s: "CNZN.TA", n: "Canzon Israel", c: "Israel" },
  { id: 10288, s: "048410.KQ", n: "Hyundai Bioscience Co.", c: "Korea" },
  { id: 10289, s: "RDY.AX", n: "Readytech Holdings", c: "Australia" },
  { id: 10290, s: "SKE.TO", n: "Skeena Resources", c: "CA" },
  { id: 10291, s: "GBE.AX", n: "Globe Metals & Mining", c: "Australia" },
  { id: 10292, s: "INTR.V", n: "Intrepid Metals Co", c: "CA" },
  { id: 10293, s: "OMG.V", n: "Omai Gold Mines Co", c: "CA" },
  { id: 10294, s: "CISY.TA", n: "C I Systems", c: "Israel" },
  { id: 10295, s: "HAR.V", n: "Harfang Exploration Inc", c: "CA" },
  { id: 10296, s: "QST.V", n: "Questor Technology Inc.", c: "CA" },
  { id: 10297, s: "BOWL.L", n: "Hollywood Bowl Group PLC", c: "UK" },
  { id: 10298, s: "RFG.AX", n: "Retail Food Group", c: "Australia" },
  { id: 10299, s: "101160.KQ", n: "Worldex Industry & Trading Co.", c: "Korea" },
  { id: 10300, s: "SGC.V", n: "Solstice Gold Co", c: "CA" },
  { id: 10301, s: "ALPDX.PA", n: "Piscines Desjoyaux SA", c: "FR" },
  { id: 10302, s: "600663.SS", n: "Shanghai Lujiazui Finance & Trade Zone Development Co A", c: "CH" },
  { id: 10303, s: "ODFB.OL", n: "Odfjell B", c: "Norway" },
  { id: 10304, s: "CGM.PA", n: "Cegedim SA", c: "FR" },
  { id: 10305, s: "RMS.AX", n: "Ramelius Resources", c: "Australia" },
  { id: 10306, s: "600089.SS", n: "Tbea", c: "CH" },
  { id: 10307, s: "SZGPY", n: "Salzgitter AG ADR", c: "US" },
  { id: 10308, s: "SZSMF", n: "Santacruz Silv", c: "US" },
  { id: 10309, s: "U-U.TO", n: "Sprott Physical Uranium Trust", c: "CA" },
  { id: 10310, s: "XX.V", n: "Avante Logixx Inc", c: "CA" },
  { id: 10311, s: "GFTU.L", n: "Grafton Group plc", c: "UK" },
  { id: 10312, s: "SRG.AX", n: "SRG Global", c: "Australia" },
  { id: 10313, s: "TMQ.TO", n: "Trilogy Metals Inc", c: "CA" },
  { id: 10314, s: "601020.SS", n: "Tibet Huayu Mining", c: "CH" },
  { id: 10315, s: "EG7.IR", n: "FBD Holdings PLC", c: "Ireland" },
  { id: 10316, s: "AMB.WA", n: "Ambra SA", c: "Poland" },
  { id: 10317, s: "RCG.TO", n: "RF Capital Group Inc", c: "CA" },
  { id: 10318, s: "RDS.V", n: "Ressources Minieres Radisson Inc", c: "CA" },
  { id: 10319, s: "600050.SS", n: "CH United Network Communications", c: "CH" },
  { id: 10320, s: "GPE.L", n: "GREAT PORTLAND ESTATES PLC", c: "UK" },
  { id: 10321, s: "SPQ.AX", n: "Superior Resources", c: "Australia" },
  { id: 10322, s: "CRR.AX", n: "Critical Resources", c: "Australia" },
  { id: 10323, s: "PHH2.F", n: "Paul Hartmann AG", c: "GER" },
  { id: 10324, s: "DRE.AX", n: "Dreadnought Resources", c: "Australia" },
  { id: 10325, s: "EQB-PC.TO", n: "EQB Inc Preferred Series 3", c: "CA" },
  { id: 10326, s: "GWIND.IS", n: "Galata Wind Enerji Anonim Sirket", c: "Turkey" },
  { id: 10327, s: "SYAB.F", n: "SYNLAB AG", c: "GER" },
  { id: 10328, s: "ROOF.V", n: "Northstar Clean Technologies Inc", c: "CA" },
  { id: 10329, s: "ROK.V", n: "ROK Resources Inc", c: "CA" },
  { id: 10330, s: "MDR.AX", n: "Medadvisor", c: "Australia" },
  { id: 10331, s: "RX.V", n: "Biosyent Inc.", c: "CA" },
  { id: 10332, s: "MIL.WA", n: "Bank Millennium S.A.", c: "Poland" },
  { id: 10333, s: "RZE.V", n: "Razor Energy Co", c: "CA" },
  { id: 10334, s: "QNT.WA", n: "Quantum Software SA", c: "Poland" },
  { id: 10335, s: "MRX.DE", n: "Mister Spex SE", c: "GER" },
  { id: 10336, s: "DNLM.L", n: "Dunelm Group PLC", c: "UK" },
  { id: 10337, s: "FC.TO", n: "Firm Capital Mortgage Invest Co", c: "CA" },
  { id: 10338, s: "600703.SS", n: "Sanan Optoelectronics", c: "CH" },
  { id: 10339, s: "ATEA.OL", n: "Atea ASA", c: "Norway" },
  { id: 10340, s: "SZLMY", n: "Swiss Life Holding AG ADR", c: "US" },
  { id: 10341, s: "GMIN.V", n: "G Mining Ventures Co", c: "CA" },
  { id: 10342, s: "GMFI", n: "Aetherium Acquisition Co", c: "US" },
  { id: 10343, s: "PACT.ST", n: "Proact IT Group AB", c: "Sweden" },
  { id: 10344, s: "POS.VI", n: "PORR AG", c: "Austria" },
  { id: 10345, s: "SLF-PJ.TO", n: "Sun Life Financial Inc", c: "CA" },
  { id: 10346, s: "DMRE.DE", n: "Demire Deutsche Mittelstand Real Estate AG", c: "GER" },
  { id: 10347, s: "IBT.L", n: "International Biotechnology Trust plc", c: "UK" },
  { id: 10348, s: "LRV.AX", n: "Larvotto Resources", c: "Australia" },
  { id: 10349, s: "DMT.V", n: "Small Pharma Inc", c: "CA" },
  { id: 10350, s: "IDX.AX", n: "Integral Diagnostics", c: "Australia" },
  { id: 10351, s: "600193.SS", n: "Shanghai Prosolar Resources Development", c: "CH" },
  { id: 10352, s: "AGTK", n: "Agritek Holdings Inc", c: "US" },
  { id: 10353, s: "IHR.L", n: "Impact Healthcare REIT PLC", c: "UK" },
  { id: 10354, s: "PML.V", n: "Panoro Minerals.", c: "CA" },
  { id: 10355, s: "600415.SS", n: "Zhejiang CH Commodities City Group", c: "CH" },
  { id: 10356, s: "MDM.PA", n: "Maisons du Monde SAS", c: "FR" },
  { id: 10357, s: "000062.SZ", n: "Shenzhen Huaqiang Industry", c: "CH" },
  { id: 10358, s: "AOGO", n: "Arogo Capital Acquisition Co", c: "US" },
  { id: 10359, s: "241560.KS", n: "Doosan Bobcat Inc", c: "Korea" },
  { id: 10360, s: "601390.SS", n: "CH Railway Group Class A", c: "CH" },
  { id: 10361, s: "600395.SS", n: "Guizhou Panjiang Refined Coal", c: "CH" },
  { id: 10362, s: "AVAP.L", n: "Avation PLC", c: "UK" },
  { id: 10363, s: "BIRD.L", n: "Blackbird PLC", c: "UK" },
  { id: 10364, s: "BWAY.TA", n: "Brainsway", c: "Israel" },
  { id: 10365, s: "HME.V", n: "Hemisphere Energy Corporation", c: "CA" },
  { id: 10366, s: "KARSN.IS", n: "Karsan Otomotiv Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 10367, s: "YORK.V", n: "York Harbour Metals Inc", c: "CA" },
  { id: 10368, s: "DUI.AX", n: "Diversified United Investment", c: "Australia" },
  { id: 10369, s: "IKA.L", n: "Ilika plc", c: "UK" },
  { id: 10370, s: "BRND.TA", n: "Brand Group (M.G)", c: "Israel" },
  { id: 10371, s: "BRMG.TA", n: "Brimag -L", c: "Israel" },
  { id: 10372, s: "LAMOR.HE", n: "Lamor Corporation Oyj", c: "Finland" },
  { id: 10373, s: "POND.V", n: "Pond Technologies Holdings Inc", c: "CA" },
  { id: 10374, s: "HTC.V", n: "HTC Purenergy Inc.", c: "CA" },
  { id: 10375, s: "BULL.TA", n: "Bull Trading And Investment", c: "Israel" },
  { id: 10376, s: "POS.AX", n: "Poseidon Nickel", c: "Australia" },
  { id: 10377, s: "DYG.V", n: "Dynasty Gold Co", c: "CA" },
  { id: 10378, s: "HEVI.V", n: "Helium Evolution Inc", c: "CA" },
  { id: 10379, s: "OYAKC.IS", n: "Oyak Cimento Fabrikalari AS", c: "Turkey" },
  { id: 10380, s: "HSP.L", n: "Hargreaves Services Plc", c: "UK" },
  { id: 10381, s: "600221.SS", n: "Hainan Airlines Co A", c: "CH" },
  { id: 10382, s: "FRTG", n: "Frontera Group Inc", c: "US" },
  { id: 10383, s: "054210.KQ", n: "Elentec Co.", c: "Korea" },
  { id: 10384, s: "KONTR.IS", n: "Kontrolmatik Teknoloji Enerji ve Mu", c: "Turkey" },
  { id: 10385, s: "GIL.DE", n: "DMG MORI AKTIENGESELLSCHAFT", c: "GER" },
  { id: 10386, s: "4105.TWO", n: "TTY Biopharm", c: "TW" },
  { id: 10387, s: "000683.SZ", n: "Inner Mongolia Yuan Xing Energy", c: "CH" },
  { id: 10388, s: "SAGA-B.ST", n: "AB Sagax (publ)", c: "Sweden" },
  { id: 10389, s: "JGCCY", n: "JGC Co", c: "US" },
  { id: 10390, s: "DELX.V", n: "DelphX Capital Markets Inc", c: "CA" },
  { id: 10391, s: "NOHO.HE", n: "NoHo Partners Oyj", c: "Finland" },
  { id: 10392, s: "8043.TWO", n: "Honey Hope Honesty Enterprise", c: "TW" },
  { id: 10393, s: "2314.TW", n: "Microelectronics Technology Inc", c: "TW" },
  { id: 10394, s: "APVS", n: "Applied Visual Sciences Inc", c: "US" },
  { id: 10395, s: "ACCL.TA", n: "Accel Solutions Group", c: "Israel" },
  { id: 10396, s: "OREGE.PA", n: "Orege Société Anonyme", c: "FR" },
  { id: 10397, s: "JAY.L", n: "Bluejay Mining PLC", c: "UK" },
  { id: 10398, s: "CTM.AX", n: "Centaurus Metals", c: "Australia" },
  { id: 10399, s: "IMC.AX", n: "Immuron", c: "Australia" },
  { id: 10400, s: "000036.SZ", n: "CH Union Holdings", c: "CH" },
  { id: 10401, s: "CPV.AX", n: "ClearVue Technologies", c: "Australia" },
  { id: 10402, s: "ICTY", n: "Eyecity.Com Inc", c: "US" },
  { id: 10403, s: "GFL.TO", n: "Gfl Environmental Holdings Inc", c: "CA" },
  { id: 10404, s: "NWST.V", n: "Northwest Copper Co", c: "CA" },
  { id: 10405, s: "601766.SS", n: "CRRC Corp Class A", c: "CH" },
  { id: 10406, s: "NGS.ST", n: "NGS Group AB (publ)", c: "Sweden" },
  { id: 10407, s: "CUSN.V", n: "Cornish Metals Inc.", c: "CA" },
  { id: 10408, s: "TPD.AX", n: "Talon Petroleum", c: "Australia" },
  { id: 10409, s: "NFGAB.ST", n: "Nordic Flanges Group AB", c: "Sweden" },
  { id: 10410, s: "CSURE.TA", n: "Cannassure Therapeutics", c: "Israel" },
  { id: 10411, s: "ANP.L", n: "Anpario Plc", c: "UK" },
  { id: 10412, s: "601801.SS", n: "Anhui Xinhua Media", c: "CH" },
  { id: 10413, s: "VIK1V.HE", n: "Viking Line Abp", c: "Finland" },
  { id: 10414, s: "KINO.L", n: "Kinovo PLC", c: "UK" },
  { id: 10415, s: "PEK.AX", n: "Peak Resources", c: "Australia" },
  { id: 10416, s: "002709.SZ", n: "Guangzhou Tinci Materials Technology", c: "CH" },
  { id: 10417, s: "242040.KQ", n: "Namu Tech Co.Ltd", c: "Korea" },
  { id: 10418, s: "DIFI.TA", n: "Direct Finance TA", c: "Israel" },
  { id: 10419, s: "MCAP.ST", n: "MedCap AB (publ)", c: "Sweden" },
  { id: 10420, s: "PSC.AX", n: "Prospect Resources", c: "Australia" },
  { id: 10421, s: "MEB.AX", n: "Medibio", c: "Australia" },
  { id: 10422, s: "KNG.AX", n: "Kingsland Minerals", c: "Australia" },
  { id: 10423, s: "CXAC", n: "C5 Acquisition Co", c: "US" },
  { id: 10424, s: "4904.TW", n: "Far EasTone Telecommunications", c: "TW" },
  { id: 10425, s: "6670.TW", n: "Fusheng Precision", c: "TW" },
  { id: 10426, s: "ROHCY", n: "Rohm Co ADR", c: "US" },
  { id: 10427, s: "BOUL.ST", n: "Boule Diagnostics AB", c: "Sweden" },
  { id: 10428, s: "BHKLY", n: "BOC Hong Kong Holdings ADR", c: "US" },
  { id: 10429, s: "RIII.L", n: "Rights and Issues Investment Trust Public Limited Company", c: "UK" },
  { id: 10430, s: "SGAPY", n: "Singapore Telecommunications PK", c: "US" },
  { id: 10431, s: "EJPRY", n: "East Japan Railway Co ADR", c: "US" },
  { id: 10432, s: "EBKDY", n: "Erste Group Bank AG PK", c: "US" },
  { id: 10433, s: "ANG.AX", n: "Austin Engineering", c: "Australia" },
  { id: 10434, s: "BLN.TO", n: "Blackline Safety Co", c: "CA" },
  { id: 10435, s: "041460.KQ", n: "Korea Electronic Certification Authority Inc", c: "Korea" },
  { id: 10436, s: "BERG-B.ST", n: "Bergman & Beving AB (publ)", c: "Sweden" },
  { id: 10437, s: "BIG.V", n: "Bald Eagle Gold Co", c: "CA" },
  { id: 10438, s: "MPE.L", n: "M.P.Evans Group", c: "UK" },
  { id: 10439, s: "A11.AX", n: "Atlantic Lithium.", c: "Australia" },
  { id: 10440, s: "ROQ.L", n: "Roquefort Investments PLC", c: "UK" },
  { id: 10441, s: "SAGA.L", n: "Saga plc", c: "UK" },
  { id: 10442, s: "CPLE3.SA", n: "Companhia Paranaense de Energia - COPEL", c: "Brazil" },
  { id: 10443, s: "SONO.TA", n: "Sonovia", c: "Israel" },
  { id: 10444, s: "ATTBF", n: "Abattis Bioceuticals Co", c: "US" },
  { id: 10445, s: "004000.KS", n: "Lotte Fine Chemical", c: "Korea" },
  { id: 10446, s: "SFB", n: "Stifel Financial Corporation 5.20% Senior Notes due 2047", c: "US" },
  { id: 10447, s: "JSLG3.SA", n: "JSL S.A", c: "Brazil" },
  { id: 10448, s: "011170.KS", n: "Lotte Chemical Co", c: "Korea" },
  { id: 10449, s: "KORE.V", n: "Kore Mining", c: "CA" },
  { id: 10450, s: "YAHOY", n: "Z Holdings Corp ADR", c: "US" },
  { id: 10451, s: "6235.TW", n: "Waffer Technology Co", c: "TW" },
  { id: 10452, s: "6115.TW", n: "I-Sheng Electric Wire & Cable", c: "TW" },
  { id: 10453, s: "APM.AX", n: "APM Human Services International", c: "Australia" },
  { id: 10454, s: "000887.SZ", n: "Anhui Zhongding Sealing Parts", c: "CH" },
  { id: 10455, s: "OHLA.MC", n: "Obrascon Huarte Lain SA", c: "Spain" },
  { id: 10456, s: "DRGV", n: "Dragon Capital Grp", c: "US" },
  { id: 10457, s: "ATYG", n: "Atlas Technology Grp", c: "US" },
  { id: 10458, s: "RSSFF", n: "Affinor Growers Inc", c: "US" },
  { id: 10459, s: "ORV.TO", n: "Orvana Minerals Corp.", c: "CA" },
  { id: 10460, s: "MIKN.SW", n: "Mikron Holding AG", c: "Switzerland" },
  { id: 10461, s: "UNIP3.SA", n: "Unipar Carbocloro S.A", c: "Brazil" },
  { id: 10462, s: "601958.SS", n: "Jinduicheng Molybdenum", c: "CH" },
  { id: 10463, s: "BDRFY", n: "Beiersdorf AG ADR", c: "US" },
  { id: 10464, s: "SRB.L", n: "Serabi Gold PLC", c: "UK" },
  { id: 10465, s: "ARTTF", n: "Artemis Resources", c: "US" },
  { id: 10466, s: "DKSC", n: "Dakshidin Corporatio", c: "US" },
  { id: 10467, s: "8478.TW", n: "Alexander Marine", c: "TW" },
  { id: 10468, s: "FMNJ", n: "Franklin Mining Inc", c: "US" },
  { id: 10469, s: "TST.L", n: "Touchstar plc", c: "UK" },
  { id: 10470, s: "000005.SZ", n: "Shenzhen Fountain Co", c: "CH" },
  { id: 10471, s: "FERRO.ST", n: "Ferroamp Elektronik AB", c: "Sweden" },
  { id: 10472, s: "FTON.SW", n: "Feintool International Holding", c: "Switzerland" },
  { id: 10473, s: "KRR.AX", n: "King River Resources", c: "Australia" },
  { id: 10474, s: "TMPL.L", n: "Temple Bar Investment Trust", c: "UK" },
  { id: 10475, s: "RIBS", n: "Southern Concepts Restaurant Group Inc", c: "US" },
  { id: 10476, s: "BML.AX", n: "Boab Metals", c: "Australia" },
  { id: 10477, s: "BRIL.TA", n: "Brill Shoe Industries", c: "Israel" },
  { id: 10478, s: "300549.SZ", n: "Jouder Precision Industry Kunshan", c: "CH" },
  { id: 10479, s: "TKUN.TA", n: "Tikun Olam Cannbit Pharmaceuticals", c: "Israel" },
  { id: 10480, s: "601939.SS", n: "CH Construction Bank", c: "CH" },
  { id: 10481, s: "BWLK.V", n: "Boardwalktech Software Co", c: "CA" },
  { id: 10482, s: "LOGI-B.ST", n: "Logistea AB Series B", c: "Sweden" },
  { id: 10483, s: "GPG.ST", n: "Genova Property Group AB", c: "Sweden" },
  { id: 10484, s: "QNCO.TA", n: "Queenco-L", c: "Israel" },
  { id: 10485, s: "300620.SZ", n: "Advanced Fiber Resources Zhuhai", c: "CH" },
  { id: 10486, s: "TRIN.L", n: "Trinity Exploration and Production PLC", c: "UK" },
  { id: 10487, s: "TPGM.TA", n: "Top Gum Industries", c: "Israel" },
  { id: 10488, s: "CANB.V", n: "CABis Capital Inc", c: "CA" },
  { id: 10489, s: "AMM.TO", n: "Almaden Minerals", c: "CA" },
  { id: 10490, s: "MDP.TO", n: "Medexus Pharmaceuticals Inc", c: "CA" },
  { id: 10491, s: "VMT.AX", n: "Vmoto", c: "Australia" },
  { id: 10492, s: "EVE.SW", n: "Evolva Holding SA", c: "Switzerland" },
  { id: 10493, s: "BSX.AX", n: "Blackstone Minerals", c: "Australia" },
  { id: 10494, s: "PRSK.TA", n: "Prashkovsky", c: "Israel" },
  { id: 10495, s: "FELTY", n: "Fuji Electric Co ADR", c: "US" },
  { id: 10496, s: "MEGP.L", n: "Me Group International PLC", c: "UK" },
  { id: 10497, s: "HAUP", n: "Hauppauge Digital OTC", c: "US" },
  { id: 10498, s: "3324.TWO", n: "Auras Technology", c: "TW" },
  { id: 10499, s: "1MC.AX", n: "Morella Corporation", c: "Australia" },
  { id: 10500, s: "COSMO.IS", n: "Cosmos Yatirim Holding AS", c: "Turkey" },
  { id: 10501, s: "STU.V", n: "Stuhini Exploration", c: "CA" },
  { id: 10502, s: "002938.SZ", n: "Avary Holding Shenzhen Co Class A", c: "CH" },
  { id: 10503, s: "BLRN.TA", n: "Bladeranger", c: "Israel" },
  { id: 10504, s: "ALTF.TA", n: "Altshuler Shaham Financial", c: "Israel" },
  { id: 10505, s: "ALMA.TA", n: "Almogim Holdings", c: "Israel" },
  { id: 10506, s: "4543.TWO", n: "Man Zai Industrial", c: "TW" },
  { id: 10507, s: "HCW.AX", n: "Healthco Healthcare and Wellness Reit Unit", c: "Australia" },
  { id: 10508, s: "HE8.AX", n: "Helios Energy", c: "Australia" },
  { id: 10509, s: "PPR.TO", n: "Prairie Provident Resources Inc", c: "CA" },
  { id: 10510, s: "SUBCY", n: "Subsea 7 SA ADR", c: "US" },
  { id: 10511, s: "AOJ-B.", n: "Broedrene A&O Johansen A/S Series B", c: "Denmark" },
  { id: 10512, s: "WDDD", n: "Worlds Inc", c: "US" },
  { id: 10513, s: "097955.KS", n: "CJ CheilJedang Co", c: "Korea" },
  { id: 10514, s: "TIFS.L", n: "TI Fluid Systems PLC", c: "UK" },
  { id: 10515, s: "HNMR.TA", n: "Hanan Mor", c: "Israel" },
  { id: 10516, s: "ENR.AX", n: "Encounter Resources", c: "Australia" },
  { id: 10517, s: "BBT.WA", n: "BoomBit S.A", c: "Poland" },
  { id: 10518, s: "SDY.L", n: "Speedy Hire PLC", c: "UK" },
  { id: 10519, s: "CIG.WA", n: "CI Games SA", c: "Poland" },
  { id: 10520, s: "HARL.L", n: "Harland & Wolff Group Holdings PLC", c: "UK" },
  { id: 10521, s: "URAL", n: "United Rail Inc", c: "US" },
  { id: 10522, s: "NTHOL.IS", n: "Net Holding AS", c: "Turkey" },
  { id: 10523, s: "JCQ.PA", n: "Jacquet Metal Service SA", c: "FR" },
  { id: 10524, s: "BOY.L", n: "Bodycote PLC", c: "UK" },
  { id: 10525, s: "BRKT.TA", n: "Bareket Capital", c: "Israel" },
  { id: 10526, s: "600115.SS", n: "CH Eastern Airlines Co", c: "CH" },
  { id: 10527, s: "300149.SZ", n: "ChemPartner PharmaTech", c: "CH" },
  { id: 10528, s: "MAM.AX", n: "Microequities Asset Management Group", c: "Australia" },
  { id: 10529, s: "HMC.AX", n: "Home Consortium", c: "Australia" },
  { id: 10530, s: "SHV.AX", n: "Select Harvests", c: "Australia" },
  { id: 10531, s: "EOL.AX", n: "Energy One", c: "Australia" },
  { id: 10532, s: "1304.TW", n: "USI Co", c: "TW" },
  { id: 10533, s: "112040.KQ", n: "Wemade Co.Ltd", c: "Korea" },
  { id: 10534, s: "CCZ", n: "Comcast Holdings Co", c: "US" },
  { id: 10535, s: "3217.TWO", n: "Argosy Research", c: "TW" },
  { id: 10536, s: "SKY.AX", n: "Sky Metals", c: "Australia" },
  { id: 10537, s: "CLIME-B.ST", n: "Climeon AB (publ)", c: "Sweden" },
  { id: 10538, s: "603077.SS", n: "Sichuan Hebang Biotechnology", c: "CH" },
  { id: 10539, s: "AEIN.F", n: "Allgeier SE", c: "GER" },
  { id: 10540, s: "SMJ.L", n: "J Smart Co Contractors PLC", c: "UK" },
  { id: 10541, s: "ELUX-A.ST", n: "AB Electrolux (publ)", c: "Sweden" },
  { id: 10542, s: "AL8.AX", n: "Alderan Resources", c: "Australia" },
  { id: 10543, s: "AXE.V", n: "Acceleware", c: "CA" },
  { id: 10544, s: "O4B.DE", n: "OVB Holding AG", c: "GER" },
  { id: 10545, s: "TAEE3.SA", n: "Transmissora Aliança de Energia Elétrica S.A", c: "Brazil" },
  { id: 10546, s: "BGE.AX", n: "Bridge SaaS.", c: "Australia" },
  { id: 10547, s: "688188.SS", n: "Shanghai Friendess Electronics Tech", c: "CH" },
  { id: 10548, s: "PPYA", n: "Papaya Growth Opportunity Corp I", c: "US" },
  { id: 10549, s: "VTU.L", n: "Vertu Motors Plc", c: "UK" },
  { id: 10550, s: "BFC.AX", n: "Beston Global Food", c: "Australia" },
  { id: 10551, s: "ZIJMF", n: "Zijin Mining Group Co-H", c: "US" },
  { id: 10552, s: "002304.SZ", n: "Jiangsu Yanghe Brewery Joint-Stock", c: "CH" },
  { id: 10553, s: "SBS.DE", n: "STRATEC Biomedical AG", c: "GER" },
  { id: 10554, s: "RSEL.TA", n: "RSL Electronics", c: "Israel" },
  { id: 10555, s: "RINO", n: "RINO International Corporation", c: "US" },
  { id: 10556, s: "EKOBOT.ST", n: "Ekobot AB", c: "Sweden" },
  { id: 10557, s: "ROL.SW", n: "Von Roll Holding AG", c: "Switzerland" },
  { id: 10558, s: "MGI.V", n: "Magnum Goldcorp Inc", c: "CA" },
  { id: 10559, s: "603185.SS", n: "Wuxi Shangji Automation", c: "CH" },
  { id: 10560, s: "ARON.SW", n: "Arundel AG", c: "Switzerland" },
  { id: 10561, s: "TEXF.BR", n: "Texaf SA", c: "Belgium" },
  { id: 10562, s: "AXP.AX", n: "AXP Energy Limited", c: "Australia" },
  { id: 10563, s: "ADVN.SW", n: "Adval Tech Holding AG", c: "Switzerland" },
  { id: 10564, s: "NIOX.L", n: "NIOX Group PLC", c: "UK" },
  { id: 10565, s: "LXR.TO", n: "LXRandCo Inc", c: "CA" },
  { id: 10566, s: "KARE.AT", n: "Karelia Tobacco Company Inc", c: "Greece" },
  { id: 10567, s: "AD8.AX", n: "Audinate Group", c: "Australia" },
  { id: 10568, s: "603260.SS", n: "Hoshine Silicon Ind", c: "CH" },
  { id: 10569, s: "KSI.TO", n: "Kneat.com Inc", c: "CA" },
  { id: 10570, s: "SAX.DE", n: "Ströer SE & Co. KGaA", c: "GER" },
  { id: 10571, s: "2903.TW", n: "Far Eastern Department Stores", c: "TW" },
  { id: 10572, s: "LAMPS.AT", n: "Lampsa Hellenic Hotels S.A", c: "Greece" },
  { id: 10573, s: "LCL.AX", n: "LCL Resources", c: "Australia" },
  { id: 10574, s: "000546.SZ", n: "Jinyuan Cement", c: "CH" },
  { id: 10575, s: "EQLS.L", n: "Equals Group PLC", c: "UK" },
  { id: 10576, s: "FAL.AX", n: "Falcon Metals", c: "Australia" },
  { id: 10577, s: "CRTM.L", n: "Critical Metals Plc", c: "UK" },
  { id: 10578, s: "ELCRE.TA", n: "Electra Real Estate", c: "Israel" },
  { id: 10579, s: "000739.SZ", n: "Apeloa Pharmaceutical", c: "CH" },
  { id: 10580, s: "BIG.TA", n: "Big Shopping Centers", c: "Israel" },
  { id: 10581, s: "ESP.L", n: "Empiric Student Property Plc", c: "UK" },
  { id: 10582, s: "DISI.TA", n: "Discount Investment Co", c: "Israel" },
  { id: 10583, s: "000807.SZ", n: "Yunnan Aluminium", c: "CH" },
  { id: 10584, s: "SDR.AX", n: "SiteMinder Limited", c: "Australia" },
  { id: 10585, s: "LIK.DE", n: "Limes Schlosskliniken AG", c: "GER" },
  { id: 10586, s: "FOLGF", n: "Falcon Oil & Gas.", c: "US" },
  { id: 10587, s: "AVRT.TA", n: "Avrot Industries", c: "Israel" },
  { id: 10588, s: "BYE.AX", n: "Byron Energy", c: "Australia" },
  { id: 10589, s: "096530.KQ", n: "Seegene Inc", c: "Korea" },
  { id: 10590, s: "WIA.AX", n: "WIA Gold", c: "Australia" },
  { id: 10591, s: "CDX.AX", n: "Cardiex", c: "Australia" },
  { id: 10592, s: "FCAP.L", n: "finnCap Group PLC", c: "UK" },
  { id: 10593, s: "SPIR.AT", n: "The House of Agriculture Spiroy S.A.", c: "Greece" },
  { id: 10594, s: "POLHO.IS", n: "Polisan Holding AS", c: "Turkey" },
  { id: 10595, s: "AVCN.TO", n: "Avicanna Inc", c: "CA" },
  { id: 10596, s: "IOX.OL", n: "Interoil Exploration and Production ASA", c: "Norway" },
  { id: 10597, s: "001570.KS", n: "Kumyang", c: "Korea" },
  { id: 10598, s: "CLAB.TA", n: "CompuLab", c: "Israel" },
  { id: 10599, s: "KLS.AX", n: "Kelsian Group", c: "Australia" },
  { id: 10600, s: "000570.SZ", n: "Changchai", c: "CH" },
  { id: 10601, s: "RRI.V", n: "Riverside Resources Inc", c: "CA" },
  { id: 10602, s: "NAYP.AT", n: "Nafpaktos Textile Industry S.A", c: "Greece" },
  { id: 10603, s: "GOD.OL", n: "Goodtech", c: "Norway" },
  { id: 10604, s: "MSI.L", n: "MS INTERNATIONAL plc", c: "UK" },
  { id: 10605, s: "KITW.L", n: "Kitwave Group PLC", c: "UK" },
  { id: 10606, s: "ELTEL.ST", n: "Eltel AB", c: "Sweden" },
  { id: 10607, s: "BGT.AX", n: "Bio-Gene Technology", c: "Australia" },
  { id: 10608, s: "PWI.TO", n: "Sustainable Power & Infrastructure Split Co", c: "CA" },
  { id: 10609, s: "GC1.AX", n: "Glennon Small Companies", c: "Australia" },
  { id: 10610, s: "EWIND.OL", n: "Edda Wind ASA", c: "Norway" },
  { id: 10611, s: "SGLLV.AX", n: "Ricegrowers Limited", c: "Australia" },
  { id: 10612, s: "GSDHO.IS", n: "GSD Holding AS", c: "Turkey" },
  { id: 10613, s: "HDII", n: "Hypertension Diagnostics Inc", c: "US" },
  { id: 10614, s: "PBY.DE", n: "Publity AG", c: "GER" },
  { id: 10615, s: "000021.SZ", n: "Shenzhen Kaifa Technology", c: "CH" },
  { id: 10616, s: "NCC-B.ST", n: "NCC AB (publ)", c: "Sweden" },
  { id: 10617, s: "PFV.DE", n: "Pfeiffer Vacuum Technology O.N.", c: "GER" },
  { id: 10618, s: "ARR.OL", n: "Arribatec Solutions ASA", c: "Norway" },
  { id: 10619, s: "ASGLY", n: "AGC Inc ADR", c: "US" },
  { id: 10620, s: "EUA.L", n: "Eurasia Mining", c: "UK" },
  { id: 10621, s: "K2F.AX", n: "K2FLY", c: "Australia" },
  { id: 10622, s: "SMCRT.OL", n: "SmartCraft ASA", c: "Norway" },
  { id: 10623, s: "GRLF", n: "Green Leaf Innovations Inc", c: "US" },
  { id: 10624, s: "6443.TW", n: "TSEC Co", c: "TW" },
  { id: 10625, s: "QUB.AX", n: "Qube Holdings", c: "Australia" },
  { id: 10626, s: "JRL.AX", n: "Jindalee Resources", c: "Australia" },
  { id: 10627, s: "AFGE", n: "American Financial Group AFGE", c: "US" },
  { id: 10628, s: "MDIA3.SA", n: "M. Dias Branco S.A. Indústria e Comércio de Alimentos", c: "Brazil" },
  { id: 10629, s: "6414.TW", n: "Ennoconn Co", c: "TW" },
  { id: 10630, s: "AJB.L", n: "AJ Bell plc", c: "UK" },
  { id: 10631, s: "000793.SZ", n: "Huawen Media Investment Co", c: "CH" },
  { id: 10632, s: "091580.KQ", n: "Sangsin Energy Display Precision Co.Ltd", c: "Korea" },
  { id: 10633, s: "FCSS.L", n: "Fidelity CH Special Situations PLC", c: "UK" },
  { id: 10634, s: "COPP-B.ST", n: "Copperstone Resources AB Series B", c: "Sweden" },
  { id: 10635, s: "MFG.V", n: "Mayfair Gold Co", c: "CA" },
  { id: 10636, s: "VIVE.ST", n: "Vivesto AB", c: "Sweden" },
  { id: 10637, s: "HUMX.TA", n: "Human Xtensions", c: "Israel" },
  { id: 10638, s: "LCY.AX", n: "Legacy Iron Ore", c: "Australia" },
  { id: 10639, s: "KAI.AX", n: "Kairos Minerals", c: "Australia" },
  { id: 10640, s: "HAIDE.AT", n: "Haidemenos S.A.", c: "Greece" },
  { id: 10641, s: "MOAR3.SA", n: "Monteiro Aranha S.A", c: "Brazil" },
  { id: 10642, s: "EHE.AX", n: "Estia Health", c: "Australia" },
  { id: 10643, s: "GUD.AX", n: "GUD Holdings", c: "Australia" },
  { id: 10644, s: "6464.TW", n: "Taiwan Optical Platform", c: "TW" },
  { id: 10645, s: "GT1.AX", n: "Green Technology Metals", c: "Australia" },
  { id: 10646, s: "MYTHY", n: "Mytilineos Holdings SA ADR", c: "US" },
  { id: 10647, s: "MAHA-A.ST", n: "Maha Energy AB (publ)", c: "Sweden" },
  { id: 10648, s: "CYB1.ST", n: "Cyber Security 1 AB", c: "Sweden" },
  { id: 10649, s: "CVC.AX", n: "CVC", c: "Australia" },
  { id: 10650, s: "230240.KQ", n: "Hana Financial 7 SPAC", c: "Korea" },
  { id: 10651, s: "BRPR3.SA", n: "BR Properties S.A", c: "Brazil" },
  { id: 10652, s: "000037.SZ", n: "Shenzhen Nanshan Power", c: "CH" },
  { id: 10653, s: "000039.SZ", n: "CH International Marine Containers Group", c: "CH" },
  { id: 10654, s: "FSTA.L", n: "Fuller Smith & Turner", c: "UK" },
  { id: 10655, s: "GFOR", n: "Graf Acquisition Corp IV", c: "US" },
  { id: 10656, s: "CUF.AX", n: "Fe", c: "Australia" },
  { id: 10657, s: "CCT.L", n: "Character Group", c: "UK" },
  { id: 10658, s: "TLM.AX", n: "Talisman Mining", c: "Australia" },
  { id: 10659, s: "QNBFB.IS", n: "Qnb Finansbank AS", c: "Turkey" },
  { id: 10660, s: "NPPXF", n: "Nippon Telegraph & Telephone Co", c: "US" },
  { id: 10661, s: "121850.KQ", n: "KOYJ Co.", c: "Korea" },
  { id: 10662, s: "SPOT.V", n: "Goldspot Discoveries Co", c: "CA" },
  { id: 10663, s: "EGFEY", n: "Eurobank Ergasias SA", c: "US" },
  { id: 10664, s: "RANA.OL", n: "Rana Gruber AS", c: "Norway" },
  { id: 10665, s: "EPIC.L", n: "Ediston Ppty Invest Co plc", c: "UK" },
  { id: 10666, s: "TIE.AX", n: "Tietto Minerals Limited", c: "Australia" },
  { id: 10667, s: "000065.SZ", n: "NORINCO International Cooperation", c: "CH" },
  { id: 10668, s: "NWTT", n: "Nw Tech Capital Inc", c: "US" },
  { id: 10669, s: "OFSA3.SA", n: "Ouro Fino Saúde Animal Participações S.A", c: "Brazil" },
  { id: 10670, s: "SPL.AX", n: "Starpharma Holdings", c: "Australia" },
  { id: 10671, s: "CYP.AX", n: "Cynata Therapeutics", c: "Australia" },
  { id: 10672, s: "688767.SS", n: "Hangzhou Biotest Biotech", c: "CH" },
  { id: 10673, s: "000639.SZ", n: "Xiwang Foodstuffs", c: "CH" },
  { id: 10674, s: "PUGE", n: "Puget Technologies Inc", c: "US" },
  { id: 10675, s: "ATROB", n: "Astronics Corp Cl B", c: "US" },
  { id: 10676, s: "RUG.ST", n: "Rugvista Group AB", c: "Sweden" },
  { id: 10677, s: "MGDL.TA", n: "Migdal Insurance", c: "Israel" },
  { id: 10678, s: "TGO.TO", n: "TeraGo Inc.", c: "CA" },
  { id: 10679, s: "IQG.L", n: "IQGeo Group PLC", c: "UK" },
  { id: 10680, s: "688819.SS", n: "Tianneng Battery Group", c: "CH" },
  { id: 10681, s: "PRM.L", n: "Proteome Sciences PLC", c: "UK" },
  { id: 10682, s: "688567.SS", n: "Farasis Energy Gan Zhou", c: "CH" },
  { id: 10683, s: "005830.KS", n: "DB Insurance", c: "Korea" },
  { id: 10684, s: "NXFR.TA", n: "Nextferm Technologies", c: "Israel" },
  { id: 10685, s: "AONNY", n: "Aeon Co ADR", c: "US" },
  { id: 10686, s: "BAKK.L", n: "Bakkavor Group PLC", c: "UK" },
  { id: 10687, s: "BRAM.TA", n: "Bram Indus", c: "Israel" },
  { id: 10688, s: "TELB3.SA", n: "Telecomunicações Brasileiras S.A. - TELEBRÁS", c: "Brazil" },
  { id: 10689, s: "PODP.L", n: "Pod Point Group Holdings PLC", c: "UK" },
  { id: 10690, s: "PNL.L", n: "Personal Assets Trust plc", c: "UK" },
  { id: 10691, s: "RAK.V", n: "Rackla Metals Inc", c: "CA" },
  { id: 10692, s: "HAV.AX", n: "Havilah Resources", c: "Australia" },
  { id: 10693, s: "ELST", n: "Electronic Systems Technology Inc", c: "US" },
  { id: 10694, s: "046310.KQ", n: "BG T&A", c: "Korea" },
  { id: 10695, s: "COLUM.", n: "Columbus A/S", c: "Denmark" },
  { id: 10696, s: "RLS.ST", n: "RLS Global AB (publ)", c: "Sweden" },
  { id: 10697, s: "RBG.L", n: "Revolution Bars Group", c: "UK" },
  { id: 10698, s: "005300.KS", n: "Lotte Chilsung Beverage", c: "Korea" },
  { id: 10699, s: "ALLIGO-B.ST", n: "Alligo AB Series B", c: "Sweden" },
  { id: 10700, s: "VEI.OL", n: "Veidekke ASA", c: "Norway" },
  { id: 10701, s: "000635.SZ", n: "Ningxia Younglight Chemicals", c: "CH" },
  { id: 10702, s: "WCMK.DE", n: "WCM Beteiligungs- und Grundbesitz-AG", c: "GER" },
  { id: 10703, s: "TIG.AX", n: "Tigers Realm Coal", c: "Australia" },
  { id: 10704, s: "QOR.AX", n: "Qoria.", c: "Australia" },
  { id: 10705, s: "WHY.V", n: "West High Yield Resources", c: "CA" },
  { id: 10706, s: "CZN.AX", n: "Corazon Mining", c: "Australia" },
  { id: 10707, s: "NURI.TA", n: "Nur Ink Innovations", c: "Israel" },
  { id: 10708, s: "RCN.L", n: "Redcentric", c: "UK" },
  { id: 10709, s: "ZIL2.DE", n: "Elringklinger AG NA O.N.", c: "GER" },
  { id: 10710, s: "6239.TW", n: "Powertech Technology Inc", c: "TW" },
  { id: 10711, s: "2GB.DE", n: "2G Energy AG", c: "GER" },
  { id: 10712, s: "TAU.V", n: "Thesis Gold Inc", c: "CA" },
  { id: 10713, s: "DLI.AX", n: "Delta Lithium", c: "Australia" },
  { id: 10714, s: "000776.SZ", n: "GF Securities", c: "CH" },
  { id: 10715, s: "688200.SS", n: "Beijing Huafeng Test & Control Technology", c: "CH" },
  { id: 10716, s: "ZDC.V", n: "Zedcor Energy", c: "CA" },
  { id: 10717, s: "KSTN.TA", n: "Keystone Reit", c: "Israel" },
  { id: 10718, s: "PFM.V", n: "ProntoForms Corporation", c: "CA" },
  { id: 10719, s: "PGE.V", n: "Stillwater Critical Minerals Co", c: "CA" },
  { id: 10720, s: "4133.TW", n: "Abnova Taiwan Co", c: "TW" },
  { id: 10721, s: "PPE.AX", n: "People Infrastructure", c: "Australia" },
  { id: 10722, s: "DNKEY", n: "Danske Bank A/S ADR", c: "US" },
  { id: 10723, s: "SCS.L", n: "ScS group plc", c: "UK" },
  { id: 10724, s: "ACX.DE", n: "bet-at-home.com AG", c: "GER" },
  { id: 10725, s: "NXTG.TA", n: "Nextage Therapeutics", c: "Israel" },
  { id: 10726, s: "WKBN.SW", n: "Banque Cantonale du Valais", c: "Switzerland" },
  { id: 10727, s: "PAR.AX", n: "Paradigm Biopharmaceuticals", c: "Australia" },
  { id: 10728, s: "000786.SZ", n: "Beijing New Building Materials Public", c: "CH" },
  { id: 10729, s: "IFM.AX", n: "Infomedia", c: "Australia" },
  { id: 10730, s: "VILN.SW", n: "Villars Holding SA", c: "Switzerland" },
  { id: 10731, s: "VETN.SW", n: "Vetropack Holding SA", c: "Switzerland" },
  { id: 10732, s: "688312.SS", n: "Shenzhen Yanmade Technology Inc", c: "CH" },
  { id: 10733, s: "TRBE.V", n: "Tribe Property Technologies Inc", c: "CA" },
  { id: 10734, s: "002311.SZ", n: "Guangdong Haid Group", c: "CH" },
  { id: 10735, s: "006260.KS", n: "LS Co", c: "Korea" },
  { id: 10736, s: "TTZ.V", n: "Total Telcom Inc", c: "CA" },
  { id: 10737, s: "SCT.LS", n: "Toyota Caetano", c: "Portugal" },
  { id: 10738, s: "RNEW11.SA", n: "Renova Energia S.A", c: "Brazil" },
  { id: 10739, s: "TDSA.LS", n: "Teixeira Duarte", c: "Portugal" },
  { id: 10740, s: "TUO.V", n: "Teuton Resources Corp.", c: "CA" },
  { id: 10741, s: "688139.SS", n: "Qingdao Haier Biomedical ", c: "CH" },
  { id: 10742, s: "AZLCZ", n: "Aztec Land Comb Cert", c: "US" },
  { id: 10743, s: "TRIS3.SA", n: "Trisul S.A", c: "Brazil" },
  { id: 10744, s: "058610.KQ", n: "SPG Co.", c: "Korea" },
  { id: 10745, s: "IMII", n: "Inception Mining Inc", c: "US" },
  { id: 10746, s: "ORON.SW", n: "Orior AG", c: "Switzerland" },
  { id: 10747, s: "TRIAN-B.ST", n: "Fastighets AB Trianon (publ)", c: "Sweden" },
  { id: 10748, s: "UPSALE.ST", n: "Upsales Technology AB", c: "Sweden" },
  { id: 10749, s: "041920.KQ", n: "MEDIANA Co.Ltd", c: "Korea" },
  { id: 10750, s: "002384.SZ", n: "Suzhou Dongshan Precision Manufacturing", c: "CH" },
  { id: 10751, s: "SRV1V.HE", n: "SRV Group plc", c: "Finland" },
  { id: 10752, s: "3059.TW", n: "Altek Co", c: "TW" },
  { id: 10753, s: "KLIN.SW", n: "Klingelnberg AG", c: "Switzerland" },
  { id: 10754, s: "B8A.DE", n: "BAVARIA Industries Group AG", c: "GER" },
  { id: 10755, s: "ASY.L", n: "Andrews Sykes Group plc", c: "UK" },
  { id: 10756, s: "INRN.SW", n: "Interroll Holding AG", c: "Switzerland" },
  { id: 10757, s: "STA.AX", n: "Strandline Resources", c: "Australia" },
  { id: 10758, s: "005680.KS", n: "Samyoung Elec", c: "Korea" },
  { id: 10759, s: "STLN.SW", n: "Swiss Steel Holding AG", c: "Switzerland" },
  { id: 10760, s: "KRED", n: "Konared Corporation", c: "US" },
  { id: 10761, s: "SCHB.OL", n: "Schibsted ASA B", c: "Norway" },
  { id: 10762, s: "KOTMY", n: "Koito Manufacturing Co ADR", c: "US" },
  { id: 10763, s: "2439.TW", n: "Merry Electronics", c: "TW" },
  { id: 10764, s: "FRA.F", n: "Fraport AG", c: "GER" },
  { id: 10765, s: "ABCE", n: "ABCO Energy Inc", c: "US" },
  { id: 10766, s: "3221.TWO", n: "Tai-Saw Technology", c: "TW" },
  { id: 10767, s: "TTFNF", n: "TotalEnergies SE", c: "US" },
  { id: 10768, s: "LBIRD.PA", n: "Lumibird SA", c: "FR" },
  { id: 10769, s: "004780.KQ", n: "Daeryuk Can Co.", c: "Korea" },
  { id: 10770, s: "GBF.F", n: "Bilfinger SE", c: "GER" },
  { id: 10771, s: "CBGL", n: "Cannabis Global Inc", c: "US" },
  { id: 10772, s: "LOCAL.PA", n: "Solocal Group SA", c: "FR" },
  { id: 10773, s: "601233.SS", n: "TongKun Group", c: "CH" },
  { id: 10774, s: "1319.TW", n: "Tong Yang Industry", c: "TW" },
  { id: 10775, s: "039490.KS", n: "Kiwoom", c: "Korea" },
  { id: 10776, s: "2108.TW", n: "Nantex Industry", c: "TW" },
  { id: 10777, s: "E.TO", n: "Enterprise Group Inc", c: "CA" },
  { id: 10778, s: "601319.SS", n: "People's Insurance of CH", c: "CH" },
  { id: 10779, s: "CCFN", n: "CCFNB Bancorp Inc", c: "US" },
  { id: 10780, s: "WOLV", n: "Wolverine Technologies Co", c: "US" },
  { id: 10781, s: "1477.TW", n: "Makalot Industrial", c: "TW" },
  { id: 10782, s: "600606.SS", n: "Greenland Holdings Co", c: "CH" },
  { id: 10783, s: "TRR.L", n: "Trident Resources PLC", c: "UK" },
  { id: 10784, s: "STEC.TA", n: "Sure-Tech Investments LP", c: "Israel" },
  { id: 10785, s: "LACR.PA", n: "Lacroix Group SA", c: "FR" },
  { id: 10786, s: "300293.SZ", n: "Shenyang Blue Silver Ind Auto", c: "CH" },
  { id: 10787, s: "030000.KS", n: "Cheil Worldwide", c: "Korea" },
  { id: 10788, s: "LHA.F", n: "Deutsche Lufthansa AG", c: "GER" },
  { id: 10789, s: "TIGBUR.TA", n: "Tigbur - Temporary Professional Personnel", c: "Israel" },
  { id: 10790, s: "TIGI.TA", n: "Tigi", c: "Israel" },
  { id: 10791, s: "2352.TW", n: "Qisda Co", c: "TW" },
  { id: 10792, s: "601811.SS", n: "Xinhua Winshare Publishing and Media", c: "CH" },
  { id: 10793, s: "SQI.PA", n: "SQLI SA", c: "FR" },
  { id: 10794, s: "KGR.F", n: "LEWAG Holding Aktiengesellschaft", c: "GER" },
  { id: 10795, s: "HBMN.SW", n: "HBM Healthcare Investments", c: "Switzerland" },
  { id: 10796, s: "ACSAY", n: "ACS Actividades De Construccion Y Servicios SA ADR", c: "US" },
  { id: 10797, s: "267250.KS", n: "Hyundai Heavy Industries Holdings", c: "Korea" },
  { id: 10798, s: "047810.KS", n: "Korea Aerospace Industries", c: "Korea" },
  { id: 10799, s: "1905.TW", n: "Chung Hwa Pulp Co", c: "TW" },
  { id: 10800, s: "FEW.F", n: "Funkwerk AG", c: "GER" },
  { id: 10801, s: "AU.V", n: "Aurion Resources", c: "CA" },
  { id: 10802, s: "ALI.WA", n: "Altustfi", c: "Poland" },
  { id: 10803, s: "AUAU.V", n: "Allegiant Gold", c: "CA" },
  { id: 10804, s: "MTEL.BD", n: "Magyar Telekom PLC", c: "Hungary" },
  { id: 10805, s: "INF.PA", n: "Infotel SA", c: "FR" },
  { id: 10806, s: "2015.TW", n: "Feng Hsin Steel", c: "TW" },
  { id: 10807, s: "600498.SS", n: "Fiberhome Telecommunication Technologies", c: "CH" },
  { id: 10808, s: "010690.KS", n: "Hwashin", c: "Korea" },
  { id: 10809, s: "UTRN.TA", n: "Utron", c: "Israel" },
  { id: 10810, s: "1104.TW", n: "Universal Cement Co", c: "TW" },
  { id: 10811, s: "3017.TW", n: "Asia Vital Components", c: "TW" },
  { id: 10812, s: "TATGD.IS", n: "Tat Gida Sanayi AS", c: "Turkey" },
  { id: 10813, s: "ABB.AX", n: "Aussie Broadband", c: "Australia" },
  { id: 10814, s: "TSTL.L", n: "Tristel", c: "UK" },
  { id: 10815, s: "LIT.AX", n: "Lithium Australia NL", c: "Australia" },
  { id: 10816, s: "PAL.VI", n: "Palfinger AG", c: "Austria" },
  { id: 10817, s: "002922.SZ", n: "Eaglerise Electric & Electronic CH Co Class A", c: "CH" },
  { id: 10818, s: "AG1.F", n: "AUTO1 Group SE", c: "GER" },
  { id: 10819, s: "009540.KS", n: "Korea Shipbuilding & Offshore Engineering", c: "Korea" },
  { id: 10820, s: "009450.KS", n: "Kyung Dong Navien", c: "Korea" },
  { id: 10821, s: "SBO.VI", n: "Schoeller-Bleckmann Oilfield Equipment Aktiengesellschaft", c: "Austria" },
  { id: 10822, s: "002552.SZ", n: "Baoding Heavy Industry", c: "CH" },
  { id: 10823, s: "TUB.MC", n: "Tubacex S.A", c: "Spain" },
  { id: 10824, s: "ULC.F", n: "UNITEDLABELS Aktiengesellschaft", c: "GER" },
  { id: 10825, s: "AACAY", n: "AAC Technologies Holdings Inc", c: "US" },
  { id: 10826, s: "CGY.TO", n: "Calian Technologies", c: "CA" },
  { id: 10827, s: "GFP.TO", n: "GreenFirst Forest Products Inc", c: "CA" },
  { id: 10828, s: "BNT1.F", n: "EYEMAXX Real Estate AG", c: "GER" },
  { id: 10829, s: "SRNW", n: "Stratos Renewables Co", c: "US" },
  { id: 10830, s: "COE.AX", n: "Cooper Energy", c: "Australia" },
  { id: 10831, s: "ILK1.SG", n: "KlickOwn AG", c: "GER" },
  { id: 10832, s: "ALPAR.PA", n: "Groupe Parot SA", c: "FR" },
  { id: 10833, s: "MLG.AX", n: "MLG OZ", c: "Australia" },
  { id: 10834, s: "1234.TW", n: "Hey-Song Co", c: "TW" },
  { id: 10835, s: "024880.KQ", n: "Korea Parts & Fasteners Co.Ltd", c: "Korea" },
  { id: 10836, s: "YPSN.MC", n: "Prevision Sanitaria Nacional Gestion SOCIMI S.A.", c: "Spain" },
  { id: 10837, s: "MND.AX", n: "Monadelphous Group", c: "Australia" },
  { id: 10838, s: "002956.SZ", n: "Guilin Seamild Foods", c: "CH" },
  { id: 10839, s: "WCW.L", n: "Walker Crips Group PLC", c: "UK" },
  { id: 10840, s: "MQR.AX", n: "Marquee Resources", c: "Australia" },
  { id: 10841, s: "6134.TWO", n: "Wanshih Electronic", c: "TW" },
  { id: 10842, s: "TPT.L", n: "Topps Tiles PLC", c: "UK" },
  { id: 10843, s: "CIHKY", n: "CH Merchants Bank", c: "US" },
  { id: 10844, s: "6227.TWO", n: "Galaxy Far East", c: "TW" },
  { id: 10845, s: "1102.TW", n: "Asia Cement Co", c: "TW" },
  { id: 10846, s: "1305.TW", n: "CH General Plastics Co", c: "TW" },
  { id: 10847, s: "SCRH", n: "Scores Hldg Co Inc", c: "US" },
  { id: 10848, s: "QLRD.TA", n: "Quicklizard", c: "Israel" },
  { id: 10849, s: "MMA.AX", n: "Maronan Metals", c: "Australia" },
  { id: 10850, s: "AAGRY", n: "Astra Agro Lestari TBK", c: "US" },
  { id: 10851, s: "WWG.F", n: "Gelsenwasser AG", c: "GER" },
  { id: 10852, s: "FT.TO", n: "Fortune Minerals Limited", c: "CA" },
  { id: 10853, s: "600258.SS", n: "BTG Hotels Group", c: "CH" },
  { id: 10854, s: "601728.SS", n: "CH Telecom Co", c: "CH" },
  { id: 10855, s: "AR4.F", n: "AURELIUS Equity Opportunities SE & Co. KGaA", c: "GER" },
  { id: 10856, s: "601699.SS", n: "Shanxi LuAn Environmental Energy Development", c: "CH" },
  { id: 10857, s: "FNTS.PA", n: "Finatis Société Anonyme", c: "FR" },
  { id: 10858, s: "EWK.V", n: "Earthworks Industries Inc", c: "CA" },
  { id: 10859, s: "DRW3.F", n: "Drägerwerk AG & Co. KGaA", c: "GER" },
  { id: 10860, s: "DRW8.F", n: "Drägerwerk AG & Co. KGaA", c: "GER" },
  { id: 10861, s: "MAY.AX", n: "Melbana Energy", c: "Australia" },
  { id: 10862, s: "603517.SS", n: "Juewei Food", c: "CH" },
  { id: 10863, s: "CEV.MC", n: "Compania Espanola de Viviendas en Alquiler SA", c: "Spain" },
  { id: 10864, s: "1734.TW", n: "Sinphar Pharmaceutical", c: "TW" },
  { id: 10865, s: "ATT.WA", n: "Grupa Azoty SA", c: "Poland" },
  { id: 10866, s: "601009.SS", n: "Bank of Nanjing", c: "CH" },
  { id: 10867, s: "300833.SZ", n: "Guangzhou Haoyang Electronic ", c: "CH" },
  { id: 10868, s: "2633.TW", n: "Taiwan High Speed Rail Co", c: "TW" },
  { id: 10869, s: "603486.SS", n: "Ecovacs Robotics", c: "CH" },
  { id: 10870, s: "ASE.WA", n: "Asseco South Eastern Europe S.A.", c: "Poland" },
  { id: 10871, s: "1802.TW", n: "Taiwan Glass Ind Co", c: "TW" },
  { id: 10872, s: "DBO.TO", n: "D-Box Technologies Inc.", c: "CA" },
  { id: 10873, s: "UAMM", n: "Ua Multimedia Inc", c: "US" },
  { id: 10874, s: "RCMN.F", n: "Rcm Beteiligungs AG", c: "GER" },
  { id: 10875, s: "300776.SZ", n: "Wuhan DR Laser Technology Co", c: "CH" },
  { id: 10876, s: "025000.KS", n: "KPX Chemical", c: "Korea" },
  { id: 10877, s: "TRAN.TA", n: "Trucknet Enterprise", c: "Israel" },
  { id: 10878, s: "DIC.F", n: "DIC Asset AG", c: "GER" },
  { id: 10879, s: "1722.TW", n: "Taiwan Fertilizer", c: "TW" },
  { id: 10880, s: "FBF.V", n: "Fab Form Industries", c: "CA" },
  { id: 10881, s: "000898.SZ", n: "Angang Steel Co Class A", c: "CH" },
  { id: 10882, s: "601966.SS", n: "Shandong Linglong Tyre", c: "CH" },
  { id: 10883, s: "BRAV.ST", n: "Bravida Holding AB", c: "Sweden" },
  { id: 10884, s: "COS.AX", n: "Cosol Ltd", c: "Australia" },
  { id: 10885, s: "SPM.DE", n: "Splendid Medien AG", c: "GER" },
  { id: 10886, s: "WLLW.TO", n: "Willow Biosciences Inc", c: "CA" },
  { id: 10887, s: "BORG.ST", n: "Bjorn Borg AB", c: "Sweden" },
  { id: 10888, s: "OLV.V", n: "Olivut Resources", c: "CA" },
  { id: 10889, s: "RET.V", n: "Reitmans CA", c: "CA" },
  { id: 10890, s: "YAK.V", n: "Mongolia Growth Group.", c: "CA" },
  { id: 10891, s: "MDNA.TO", n: "Medicenna Therapeutics Co", c: "CA" },
  { id: 10892, s: "BLCO.TO", n: "Bausch + Lomb Co", c: "CA" },
  { id: 10893, s: "FRAN.L", n: "Franchise Brands PLC", c: "UK" },
  { id: 10894, s: "PYNKF", n: "Perimeter Medical Imaging AI Inc", c: "US" },
  { id: 10895, s: "BLGO.TA", n: "Buligo Capital", c: "Israel" },
  { id: 10896, s: "QBY.DE", n: "q.beyond AG", c: "GER" },
  { id: 10897, s: "MMHD.TA", n: "Menora Miv Hld", c: "Israel" },
  { id: 10898, s: "DCR.WA", n: "Decora SA", c: "Poland" },
  { id: 10899, s: "603690.SS", n: "PNC Process Systems", c: "CH" },
  { id: 10900, s: "JG.V", n: "Japan Gold Co", c: "CA" },
  { id: 10901, s: "DB1.F", n: "Deutsche Börse AG", c: "GER" },
  { id: 10902, s: "QB7.DE", n: "Quirin Privatbank AG", c: "GER" },
  { id: 10903, s: "A3D.AX", n: "Aurora Labs", c: "Australia" },
  { id: 10904, s: "MLL.F", n: "Müller - Die lila Logistik AG", c: "GER" },
  { id: 10905, s: "1609.TW", n: "Ta Ya Electric Wire & Cable", c: "TW" },
  { id: 10906, s: "EVT.F", n: "Evotec SE", c: "GER" },
  { id: 10907, s: "SGPYY", n: "Sage Group PLC ADR", c: "US" },
  { id: 10908, s: "MUT.F", n: "Mineralbrunnen Überkingen-Teinach GmbH & Co. KGaA", c: "GER" },
  { id: 10909, s: "3680.TWO", n: "Gudeng Precision Industrial", c: "TW" },
  { id: 10910, s: "TRII", n: "Trio Resources Inc", c: "US" },
  { id: 10911, s: "2312.TW", n: "Kinpo Electronics Inc", c: "TW" },
  { id: 10912, s: "3289.TWO", n: "Integrated Service Technology", c: "TW" },
  { id: 10913, s: "DCMC.V", n: "Dore Copper Mining Co", c: "CA" },
  { id: 10914, s: "300629.SZ", n: "King-Strong New Material Technology", c: "CH" },
  { id: 10915, s: "PBHG", n: "Pbs Holding Inc.", c: "US" },
  { id: 10916, s: "175330.KS", n: "Jb Financial", c: "Korea" },
  { id: 10917, s: "601658.SS", n: "Postal Savings Bank of CH", c: "CH" },
  { id: 10918, s: "069620.KS", n: "Daewoong Pharma", c: "Korea" },
  { id: 10919, s: "MAK.F", n: "Maternus-Kliniken Aktiengesellschaft", c: "GER" },
  { id: 10920, s: "2233.TW", n: "Turvo International", c: "TW" },
  { id: 10921, s: "601688.SS", n: "Huatai Securities", c: "CH" },
  { id: 10922, s: "000901.SZ", n: "Aerospace Hi-Tech Holding Group", c: "CH" },
  { id: 10923, s: "A1N.AX", n: "ARN Media Limited", c: "Australia" },
  { id: 10924, s: "WLFD.TA", n: "Willy Food", c: "Israel" },
  { id: 10925, s: "CLVRW", n: "Clever Leaves Holdings Inc", c: "US" },
  { id: 10926, s: "ML2.F", n: "Ming Le Sports AG", c: "GER" },
  { id: 10927, s: "FUSE.V", n: "Fuse Cobalt Inc", c: "CA" },
  { id: 10928, s: "300207.SZ", n: "Sunwoda Electronic", c: "CH" },
  { id: 10929, s: "300017.SZ", n: "Wangsu Science Tech", c: "CH" },
  { id: 10930, s: "KG.V", n: "Klondike Gold Co", c: "CA" },
  { id: 10931, s: "CLA.PA", n: "Claranova SE", c: "FR" },
  { id: 10932, s: "2838A.TW", n: "Union Bank of Taiwan Pref", c: "TW" },
  { id: 10933, s: "CRM.NE", n: "Salesforce.Com Inc CDR", c: "CA" },
  { id: 10934, s: "ICHB.MX", n: "Industrias CH S. A. B. de C. V", c: "Mexi" },
  { id: 10935, s: "CLIN", n: "Clean Earth Acquisitions Co", c: "US" },
  { id: 10936, s: "SHGR.TA", n: "Shagrir Group Vehicle Services", c: "Israel" },
  { id: 10937, s: "600809.SS", n: "Shanxi Xinghuacun Fen Wine Factory", c: "CH" },
  { id: 10938, s: "5312.TWO", n: "Formosa Optical Technology", c: "TW" },
  { id: 10939, s: "CRZBY", n: "Commerzbank AG PK", c: "US" },
  { id: 10940, s: "MFRISCOA-1.MX", n: "Minera Frisco S.A.B. de C.V", c: "Mexi" },
  { id: 10941, s: "BOI.PA", n: "Boiron SA", c: "FR" },
  { id: 10942, s: "IBM.NE", n: "International Business Machines Corp CDR", c: "CA" },
  { id: 10943, s: "600867.SS", n: "Tonghua Dongbao Pharmaceutical", c: "CH" },
  { id: 10944, s: "CPPKF", n: "Copperbank Resources Co", c: "US" },
  { id: 10945, s: "IZS.WA", n: "Izostal SA", c: "Poland" },
  { id: 10946, s: "CVE-PB.TO", n: "Cenovus Energy Inc Preferred Series 2", c: "CA" },
  { id: 10947, s: "SMWN.F", n: "SM Wirtschaftsberatungs AG", c: "GER" },
  { id: 10948, s: "EPIT.TA", n: "Epitomee Medical", c: "Israel" },
  { id: 10949, s: "VLKPF", n: "Volkswagen AG VZO O.N.", c: "US" },
  { id: 10950, s: "300572.SZ", n: "Shenzhen Anche Technologies", c: "CH" },
  { id: 10951, s: "LMP.L", n: "LondonMetric Property Plc", c: "UK" },
  { id: 10952, s: "078930.KS", n: "GS Holdings", c: "Korea" },
  { id: 10953, s: "3483.TWO", n: "Forcecon Technology", c: "TW" },
  { id: 10954, s: "INWI.ST", n: "Inwido AB", c: "Sweden" },
  { id: 10955, s: "LOK.L", n: "Lok’N Store Group", c: "UK" },
  { id: 10956, s: "EVGN.TA", n: "Evogene", c: "Israel" },
  { id: 10957, s: "BWR.V", n: "Black Widow Resources Inc", c: "CA" },
  { id: 10958, s: "IGPK", n: "Integrated Cannabis Solutions Inc", c: "US" },
  { id: 10959, s: "IXR.AX", n: "Ionic Rare Earths", c: "Australia" },
  { id: 10960, s: "300613.SZ", n: "Shanghai Fullhan Microelectronics", c: "CH" },
  { id: 10961, s: "300565.SZ", n: "Shenzhen Kexin Communication Technologies", c: "CH" },
  { id: 10962, s: "VETO.PA", n: "Vetoquinol", c: "FR" },
  { id: 10963, s: "3540.TWO", n: "Thermaltake Technology", c: "TW" },
  { id: 10964, s: "3508.TWO", n: "Ways Technical", c: "TW" },
  { id: 10965, s: "VRS.AX", n: "Veris Ltd", c: "Australia" },
  { id: 10966, s: "300560.SZ", n: "Zhong Fu Tong", c: "CH" },
  { id: 10967, s: "KNOW.ST", n: "Know IT AB", c: "Sweden" },
  { id: 10968, s: "039560.KQ", n: "DASAN Networks Inc", c: "Korea" },
  { id: 10969, s: "DLTI.TA", n: "Delta Israel Brands", c: "Israel" },
  { id: 10970, s: "FPRUY", n: "Fraport AG Frankfurt Airport Services Worldwide ADR", c: "US" },
  { id: 10971, s: "AEET.L", n: "Aquila Energy Efficiency Trust PLC", c: "UK" },
  { id: 10972, s: "300498.SZ", n: "Guangdong Wens Foodstuff Group", c: "CH" },
  { id: 10973, s: "CBE.AX", n: "Cobre", c: "Australia" },
  { id: 10974, s: "300488.SZ", n: "EST Tools", c: "CH" },
  { id: 10975, s: "300615.SZ", n: "Xdc Industries Shenzhen", c: "CH" },
  { id: 10976, s: "AAD.DE", n: "Amadeus Fire AG", c: "GER" },
  { id: 10977, s: "BRO.V", n: "Barksdale Resources Co", c: "CA" },
  { id: 10978, s: "TSRYY", n: "Treasury Wine Estates PK", c: "US" },
  { id: 10979, s: "300781.SZ", n: "Guangdong Insight Brand Marketing Group", c: "CH" },
  { id: 10980, s: "SKTAS.IS", n: "Soktas Tekstil Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 10981, s: "CET.TO", n: "Cathedral Energy Services", c: "CA" },
  { id: 10982, s: "PCZ.DE", n: "ProCredit Holding AG & Co KGaA", c: "GER" },
  { id: 10983, s: "002023.SZ", n: "Sichuan Haite High-tech", c: "CH" },
  { id: 10984, s: "300700.SZ", n: "Changsha DIALINE New Material Sci & Tech", c: "CH" },
  { id: 10985, s: "SPI.V", n: "Canadian Spirit Resources Inc", c: "CA" },
  { id: 10986, s: "2392.TW", n: "Cheng Uei Precision Industry", c: "TW" },
  { id: 10987, s: "688255.SS", n: "Hangzhou Kaierda Welding Robot", c: "CH" },
  { id: 10988, s: "TKNSA.IS", n: "Teknosa Ic ve Dis Ticaret AS", c: "Turkey" },
  { id: 10989, s: "VHI.TO", n: "Vitalhub Co", c: "CA" },
  { id: 10990, s: "000761.SZ", n: "Bengang Steel Plates", c: "CH" },
  { id: 10991, s: "000882.SZ", n: "Beijing Hualian Department Store", c: "CH" },
  { id: 10992, s: "688272.SS", n: "Beijing Fjr Optoelectronic Technology", c: "CH" },
  { id: 10993, s: "600536.SS", n: "CH National Software & Service", c: "CH" },
  { id: 10994, s: "000089.SZ", n: "Shenzhen Airport", c: "CH" },
  { id: 10995, s: "001217.SZ", n: "Anhui Huaertai Chemical", c: "CH" },
  { id: 10996, s: "MOX.V", n: "Morien Resources Co", c: "CA" },
  { id: 10997, s: "TRP-PE.TO", n: "TC Energy Corp Pref Series 9", c: "CA" },
  { id: 10998, s: "SEYKM.IS", n: "Seyitler Kimya Sanayi AS", c: "Turkey" },
  { id: 10999, s: "688095.SS", n: "Fujian Foxit Software Development Joint Stock", c: "CH" },
  { id: 11000, s: "688103.SS", n: "Kunshan Guoli Electronic Technology", c: "CH" },
  { id: 11001, s: "REG.V", n: "Regulus Resources Inc", c: "CA" },
  { id: 11002, s: "AMLM", n: "American Lithium Minerals Inc", c: "US" },
  { id: 11003, s: "603001.SS", n: "Zhejiang Aokang Shoes", c: "CH" },
  { id: 11004, s: "600547.SS", n: "Shandong Gold Mining", c: "CH" },
  { id: 11005, s: "DEZ.DE", n: "DEUTZ Aktiengesellschaft", c: "GER" },
  { id: 11006, s: "3413.TW", n: "Foxsemicon Integrated Technology Inc", c: "TW" },
  { id: 11007, s: "688121.SS", n: "Shanghai Supezet Engineering Technology Co", c: "CH" },
  { id: 11008, s: "IMASM.IS", n: "Imas Makina Sanayi AS", c: "Turkey" },
  { id: 11009, s: "600897.SS", n: "Xiamen International Airport", c: "CH" },
  { id: 11010, s: "600732.SS", n: "Shanghai Xinmei Real Estate Co Class A", c: "CH" },
  { id: 11011, s: "2406.TW", n: "Gigastorage Co", c: "TW" },
  { id: 11012, s: "OCN.AX", n: "Oceana Lithium", c: "Australia" },
  { id: 11013, s: "HAYD.L", n: "Haydale Graphene Industries", c: "UK" },
  { id: 11014, s: "300698.SZ", n: "Wanma Technology", c: "CH" },
  { id: 11015, s: "MEMS.PA", n: "Memscap Regpt", c: "FR" },
  { id: 11016, s: "DEX.V", n: "Almadex Minerals", c: "CA" },
  { id: 11017, s: "TG1.AX", n: "Techgen Metals", c: "Australia" },
  { id: 11018, s: "IDOX.L", n: "IDOX plc", c: "UK" },
  { id: 11019, s: "HSW.L", n: "Hostelworld Group PLC", c: "UK" },
  { id: 11020, s: "SVOL-B.ST", n: "Svolder AB (publ)", c: "Sweden" },
  { id: 11021, s: "HHSE", n: "Hanover House Inc.", c: "US" },
  { id: 11022, s: "SWEC-B.ST", n: "Sweco AB (publ)", c: "Sweden" },
  { id: 11023, s: "300300.SZ", n: "Strait Innovation Internet", c: "CH" },
  { id: 11024, s: "SYNACT.ST", n: "SynAct Pharma AB", c: "Sweden" },
  { id: 11025, s: "SYM.AX", n: "Symbio Holdings", c: "Australia" },
  { id: 11026, s: "DTR.AX", n: "Dateline Resources", c: "Australia" },
  { id: 11027, s: "4168.TWO", n: "GlycoNex", c: "TW" },
  { id: 11028, s: "4126.TWO", n: "Pacific Hospital Supply", c: "TW" },
  { id: 11029, s: "300479.SZ", n: "Shandong Synthesis Electronic Technology", c: "CH" },
  { id: 11030, s: "300380.SZ", n: "Shanghai Amarsoft Information & Technolgy", c: "CH" },
  { id: 11031, s: "RENEW.ST", n: "Re:NewCell AB (publ)", c: "Sweden" },
  { id: 11032, s: "CSX.V", n: "Clean Seed Capital Group", c: "CA" },
  { id: 11033, s: "CLG.AX", n: "Close The Loop Inc", c: "Australia" },
  { id: 11034, s: "123890.KS", n: "Korea Asset In Trust", c: "Korea" },
  { id: 11035, s: "DANH.TA", n: "Dan Hotels", c: "Israel" },
  { id: 11036, s: "CRMT.TA", n: "Carmit", c: "Israel" },
  { id: 11037, s: "CMG.AX", n: "Critical Minerals Group", c: "Australia" },
  { id: 11038, s: "CMM.AX", n: "Capricorn Metals", c: "Australia" },
  { id: 11039, s: "CPTP.TA", n: "Capital Point", c: "Israel" },
  { id: 11040, s: "CNB.AX", n: "Carnaby Resources", c: "Australia" },
  { id: 11041, s: "CNJ.AX", n: "Coni", c: "Australia" },
  { id: 11042, s: "WILC.TA", n: "G Willi-Food International", c: "Israel" },
  { id: 11043, s: "OLG.PA", n: "Olympique Lyonnais Groupe SA", c: "FR" },
  { id: 11044, s: "002020.SZ", n: "Zhejiang Jingxin Pharmaceutical", c: "CH" },
  { id: 11045, s: "AFN.L", n: "ADVFN Plc", c: "UK" },
  { id: 11046, s: "300323.SZ", n: "HC Semitek Co", c: "CH" },
  { id: 11047, s: "AZM.V", n: "Azimut Exploration Inc.", c: "CA" },
  { id: 11048, s: "282330.KS", n: "BGF Retail", c: "Korea" },
  { id: 11049, s: "001630.KS", n: "Chongkundang", c: "Korea" },
  { id: 11050, s: "MOUR.BR", n: "Moury Construct SA", c: "Belgium" },
  { id: 11051, s: "SVRT.TA", n: "Savoreat", c: "Israel" },
  { id: 11052, s: "300697.SZ", n: "Jiangyin Electrical Alloy", c: "CH" },
  { id: 11053, s: "300686.SZ", n: "Shenzhen CDL Precision Technology", c: "CH" },
  { id: 11054, s: "BAY.V", n: "Aston Bay Holdings", c: "CA" },
  { id: 11055, s: "HANZA.ST", n: "Hanza AB", c: "Sweden" },
  { id: 11056, s: "BCM.V", n: "Bear Creek Mining Corporation", c: "CA" },
  { id: 11057, s: "042660.KS", n: "Daewoo Shipbuilding", c: "Korea" },
  { id: 11058, s: "042700.KS", n: "Hanmi Semicon", c: "Korea" },
  { id: 11059, s: "BLY.AX", n: "Boart Longyear", c: "Australia" },
  { id: 11060, s: "GOHO.TA", n: "Golden House", c: "Israel" },
  { id: 11061, s: "001045.KS", n: "Cj Corp Pref ", c: "Korea" },
  { id: 11062, s: "BNZ.AX", n: "Benz Mining Co", c: "Australia" },
  { id: 11063, s: "YOJ.AX", n: "Yojee", c: "Australia" },
  { id: 11064, s: "300635.SZ", n: "SinoDaan", c: "CH" },
  { id: 11065, s: "G5EN.ST", n: "G5 Entertainment publ AB", c: "Sweden" },
  { id: 11066, s: "XTC.AX", n: "Xantippe Resources", c: "Australia" },
  { id: 11067, s: "MYHI", n: "Mountain High Acquisitions Co", c: "US" },
  { id: 11068, s: "OD6.AX", n: "OD6 Metals", c: "Australia" },
  { id: 11069, s: "CRB.V", n: "Cariboo Rose Resources", c: "CA" },
  { id: 11070, s: "VNUE", n: "VNUE Inc", c: "US" },
  { id: 11071, s: "CXU.AX", n: "Cauldron Energy", c: "Australia" },
  { id: 11072, s: "248170.KS", n: "Sempio Foods", c: "Korea" },
  { id: 11073, s: "CXZ.AX", n: "Connexion Media", c: "Australia" },
  { id: 11074, s: "TMKRU", n: "Tastemaker Acquisition Co", c: "US" },
  { id: 11075, s: "4113.TWO", n: "We&Win Diversification", c: "TW" },
  { id: 11076, s: "281820.KS", n: "KCTech", c: "Korea" },
  { id: 11077, s: "HGGGQ", n: "hhgregg Inc", c: "US" },
  { id: 11078, s: "8072.TW", n: "AV Tech Co", c: "TW" },
  { id: 11079, s: "002150.SZ", n: "Jiangsu Tongrun Equipment Technology", c: "CH" },
  { id: 11080, s: "2104.TW", n: "International CSRC Investment Holdings", c: "TW" },
  { id: 11081, s: "000932.SZ", n: "Hunan Valin Steel", c: "CH" },
  { id: 11082, s: "HBP.TO", n: "Helix BioPharma Corp.", c: "CA" },
  { id: 11083, s: "8358.TWO", n: "Co-Tech Development", c: "TW" },
  { id: 11084, s: "688728.SS", n: "GalaxyCore Inc", c: "CH" },
  { id: 11085, s: "OAM.V", n: "Overactive Media Co", c: "CA" },
  { id: 11086, s: "3580.TWO", n: "UVAT Technology", c: "TW" },
  { id: 11087, s: "002160.SZ", n: "Jiangsu Alcha Aluminium", c: "CH" },
  { id: 11088, s: "KRDMD.IS", n: "Kardemir Karabuk Demir Celik Sanayi ve Ticaret AS Class D", c: "Turkey" },
  { id: 11089, s: "603833.SS", n: "Oppein Home Group Inc", c: "CH" },
  { id: 11090, s: "RBY.TO", n: "Rubellite Energy Inc", c: "CA" },
  { id: 11091, s: "6155.TW", n: "King Core Electronics Inc", c: "TW" },
  { id: 11092, s: "688733.SS", n: "Anhui Estone Materials Technology", c: "CH" },
  { id: 11093, s: "G24.DE", n: "Scout24 AG", c: "GER" },
  { id: 11094, s: "603676.SS", n: "Tibet Weixinkang Medicine", c: "CH" },
  { id: 11095, s: "2338.TW", n: "Taiwan Mask Co", c: "TW" },
  { id: 11096, s: "002180.SZ", n: "Ninestar Co", c: "CH" },
  { id: 11097, s: "688779.SS", n: "Hunan Changyuan Lico", c: "CH" },
  { id: 11098, s: "600875.SS", n: "Dongfang Electric Corp Class A", c: "CH" },
  { id: 11099, s: "002256.SZ", n: "Shenzhen Sunrise New Energy", c: "CH" },
  { id: 11100, s: "WIL.V", n: "Wilton Resources Inc", c: "CA" },
  { id: 11101, s: "688701.SS", n: "Zhejiang Zone King Environmental Sci&Tech", c: "CH" },
  { id: 11102, s: "603601.SS", n: "Chongqing Zaisheng Tech Co", c: "CH" },
  { id: 11103, s: "688538.SS", n: "Everdisplay Optronics Shanghai", c: "CH" },
  { id: 11104, s: "PSUD", n: "Petrosun Inc", c: "US" },
  { id: 11105, s: "002231.SZ", n: "Allwin Telecommunication", c: "CH" },
  { id: 11106, s: "DXT.TO", n: "Dexterra Group Inc", c: "CA" },
  { id: 11107, s: "HEDEF.IS", n: "Hedef Holdings AS", c: "Turkey" },
  { id: 11108, s: "ERWE.DE", n: "ERWE Immobilien AG", c: "GER" },
  { id: 11109, s: "XTD.TO", n: "TDb Split Co", c: "CA" },
  { id: 11110, s: "002148.SZ", n: "Beijing Bewinner Communications", c: "CH" },
  { id: 11111, s: "SAM.TO", n: "Starcore International Mines", c: "CA" },
  { id: 11112, s: "8928.TWO", n: "Dynamic Precision Industry", c: "TW" },
  { id: 11113, s: "688588.SS", n: "Linkage Software", c: "CH" },
  { id: 11114, s: "LA.V", n: "Los Andes Copper Limited", c: "CA" },
  { id: 11115, s: "2542.TW", n: "Highwealth Construction Co", c: "TW" },
  { id: 11116, s: "EGSER.IS", n: "Ege Seramik Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 11117, s: "002305.SZ", n: "Wuhan Langold Real Estate", c: "CH" },
  { id: 11118, s: "UMDK.DE", n: "UMT United Mobility Technology AG", c: "GER" },
  { id: 11119, s: "603899.SS", n: "Shanghai M&G Stationery Inc", c: "CH" },
  { id: 11120, s: "002310.SZ", n: "Beijing Orient Landscape", c: "CH" },
  { id: 11121, s: "000058.SZ", n: "Shenzhen SEG", c: "CH" },
  { id: 11122, s: "SILV.V", n: "Silver Valley Metals Co", c: "CA" },
  { id: 11123, s: "RCAC", n: "Revelstone Capital Acquisition Co", c: "US" },
  { id: 11124, s: "9945.TW", n: "Ruentex Development", c: "TW" },
  { id: 11125, s: "000910.SZ", n: "Dare Technology", c: "CH" },
  { id: 11126, s: "KEC.TO", n: "Kiwetinohk Energy Co", c: "CA" },
  { id: 11127, s: "UGD.V", n: "Unigold Inc.", c: "CA" },
  { id: 11128, s: "RRRI", n: "Rock Ridge Resources Inc", c: "US" },
  { id: 11129, s: "688363.SS", n: "Bloomage Biotechnology Co", c: "CH" },
  { id: 11130, s: "SNM.V", n: "ShaMaran Petroleum Co", c: "CA" },
  { id: 11131, s: "EFN-PA.TO", n: "Element Fleet Management Corp Pref A", c: "CA" },
  { id: 11132, s: "000756.SZ", n: "Shandong Xinhua Pharmaceutical", c: "CH" },
  { id: 11133, s: "PRMW.TO", n: "Primo Water Co", c: "CA" },
  { id: 11134, s: "E8X.DE", n: "elexxion AG", c: "GER" },
  { id: 11135, s: "000525.SZ", n: "Nanjing Red Sun", c: "CH" },
  { id: 11136, s: "000528.SZ", n: "Guangxi Liugong Machinery", c: "CH" },
  { id: 11137, s: "688379.SS", n: "Hangzhou Huaguang Advanced Welding Materials", c: "CH" },
  { id: 11138, s: "TSKB.IS", n: "Turkiye Sinai Kalkinma Bankasi AS", c: "Turkey" },
  { id: 11139, s: "6579.TW", n: "AAEON Technology Inc", c: "TW" },
  { id: 11140, s: "000690.SZ", n: "Guangdong Baolihua New Energy Stock", c: "CH" },
  { id: 11141, s: "000532.SZ", n: "Zhuhai Huajin Capital", c: "CH" },
  { id: 11142, s: "4536.TW", n: "Topkey Co", c: "TW" },
  { id: 11143, s: "KWS.DE", n: "KWS SAAT SE & Co. KGaA", c: "GER" },
  { id: 11144, s: "SKHSY", n: "Sekisui House ADR", c: "US" },
  { id: 11145, s: "688619.SS", n: "ROPEOK Technology Group", c: "CH" },
  { id: 11146, s: "MMA.V", n: "Midnight Sun Mining Co", c: "CA" },
  { id: 11147, s: "RFAC", n: "RF Acquisition Co", c: "US" },
  { id: 11148, s: "OPS.TO", n: "Opsens Inc", c: "CA" },
  { id: 11149, s: "002482.SZ", n: "Shenzhen Grandland Decoration Group", c: "CH" },
  { id: 11150, s: "9914.TW", n: "Merida Industry", c: "TW" },
  { id: 11151, s: "605399.SS", n: "Jiangxi Chen Guang New Materials", c: "CH" },
  { id: 11152, s: "2897.TW", n: "O-Bank", c: "TW" },
  { id: 11153, s: "605167.SS", n: "Jiangsu Libert Inc", c: "CH" },
  { id: 11154, s: "9911.TW", n: "Taiwan Sakura Co", c: "TW" },
  { id: 11155, s: "2455.TW", n: "Visual Photonics Epitaxy", c: "TW" },
  { id: 11156, s: "TELL.AT", n: "Bank of Greece", c: "Greece" },
  { id: 11157, s: "9910.TW", n: "Feng Tay Enterprises", c: "TW" },
  { id: 11158, s: "002476.SZ", n: "Shandong Polymer Biochemicals", c: "CH" },
  { id: 11159, s: "RBNW", n: "Renewable Energy and Power Inc", c: "US" },
  { id: 11160, s: "605567.SS", n: "Springsnow Food Group", c: "CH" },
  { id: 11161, s: "002471.SZ", n: "Jiangsu Zhongchao Cable", c: "CH" },
  { id: 11162, s: "PUMA.V", n: "Puma Exploration Inc", c: "CA" },
  { id: 11163, s: "MVAI.V", n: "Minerva Intelligence Inc", c: "CA" },
  { id: 11164, s: "BAYN.F", n: "Bayer AG NA", c: "GER" },
  { id: 11165, s: "002396.SZ", n: "Fujian Star Net Communic", c: "CH" },
  { id: 11166, s: "000100.SZ", n: "TCL Co", c: "CH" },
  { id: 11167, s: "688090.SS", n: "Guangzhou Risong Intelligent Technology Holding", c: "CH" },
  { id: 11168, s: "PDV.TO", n: "Prime Dividend Co", c: "CA" },
  { id: 11169, s: "688182.SS", n: "Jiangsu Cai Qin Technology", c: "CH" },
  { id: 11170, s: "CMBTN.IS", n: "Cimbeton Hazirbeton ve Prefabrik Yapi Elemanlari Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 11171, s: "NKG.V", n: "Nevada King Gold Co", c: "CA" },
  { id: 11172, s: "6128.TW", n: "General Plastic Industrial", c: "TW" },
  { id: 11173, s: "2401.TW", n: "Sunplus Technology", c: "TW" },
  { id: 11174, s: "VBNK.TO", n: "VersaBank", c: "CA" },
  { id: 11175, s: "002012.SZ", n: "Zhejiang Kan Specialities Material", c: "CH" },
  { id: 11176, s: "BRAV", n: "Bravada Intl", c: "US" },
  { id: 11177, s: "002484.SZ", n: "Nantong Jianghai Capacitor", c: "CH" },
  { id: 11178, s: "600597.SS", n: "Bright Dairy & Food", c: "CH" },
  { id: 11179, s: "600879.SS", n: "CH Aerospace Times Electronics", c: "CH" },
  { id: 11180, s: "600702.SS", n: "Sichuan Tuopai Shede Wine", c: "CH" },
  { id: 11181, s: "002117.SZ", n: "Tungkong Inc", c: "CH" },
  { id: 11182, s: "688622.SS", n: "Guangzhou Hexin Instrument", c: "CH" },
  { id: 11183, s: "002198.SZ", n: "Guangdong Jiaying Pharmaceutical", c: "CH" },
  { id: 11184, s: "2809.TW", n: "King’s Town Bank", c: "TW" },
  { id: 11185, s: "WIN.V", n: "Windfall Geotek Inc", c: "CA" },
  { id: 11186, s: "600436.SS", n: "Zhangzhou Pientzehuang Pharmaceutical", c: "CH" },
  { id: 11187, s: "RMRK", n: "Rimrock Gold Co", c: "US" },
  { id: 11188, s: "000778.SZ", n: "Xinxing Ductile Iron Pipes", c: "CH" },
  { id: 11189, s: "688265.SS", n: "Shanghai Model Organisms Center Inc", c: "CH" },
  { id: 11190, s: "1904.TW", n: "Cheng Loong Co", c: "TW" },
  { id: 11191, s: "BHAC", n: "Barington/Hilco Acquisition", c: "US" },
  { id: 11192, s: "688013.SS", n: "Touchstone International Medical Science", c: "CH" },
  { id: 11193, s: "002426.SZ", n: "Suzhou Victory Precision Manufacture", c: "CH" },
  { id: 11194, s: "001207.SZ", n: "Shandong Link Science and Technology", c: "CH" },
  { id: 11195, s: "1723.TW", n: "CH Steel Chemical Co", c: "TW" },
  { id: 11196, s: "PLAKR.AT", n: "Plastika Kritis S.A.", c: "Greece" },
  { id: 11197, s: "001211.SZ", n: "Suncha Technology", c: "CH" },
  { id: 11198, s: "KA8.DE", n: "Klassik Radio AG", c: "GER" },
  { id: 11199, s: "SPQS", n: "Sportsquest Inc", c: "US" },
  { id: 11200, s: "605100.SS", n: "Power HF", c: "CH" },
  { id: 11201, s: "AFN.TO", n: "Ag Growth International Inc", c: "CA" },
  { id: 11202, s: "688067.SS", n: "AVE Science & Technology", c: "CH" },
  { id: 11203, s: "688069.SS", n: "Wuxi Delinhai Environmental Technology", c: "CH" },
  { id: 11204, s: "BIKE.F", n: "Bike24 Holding AG", c: "GER" },
  { id: 11205, s: "688071.SS", n: "Shanghai W Ibeda High Tech Group", c: "CH" },
  { id: 11206, s: "RIHT", n: "Rightscorp Inc", c: "US" },
  { id: 11207, s: "2002A.TW", n: "CH Steel Corp Pref", c: "TW" },
  { id: 11208, s: "KCC.V", n: "Kincora Copper", c: "CA" },
  { id: 11209, s: "NEV.V", n: "Nevada Sunrise Gold Co", c: "CA" },
  { id: 11210, s: "FRTN", n: "Fortran Co", c: "US" },
  { id: 11211, s: "DIAH.ST", n: "Diadrom Holding AB", c: "Sweden" },
  { id: 11212, s: "POM.PA", n: "Compagnie Plastic Omnium SE", c: "FR" },
  { id: 11213, s: "PNN.AX", n: "Power Minerals", c: "Australia" },
  { id: 11214, s: "SYNE", n: "Synthesis Energy Systems Inc", c: "US" },
  { id: 11215, s: "IDEX.OL", n: "Idex ASA", c: "Norway" },
  { id: 11216, s: "ARAD.TA", n: "Arad Investment & Industrial Development", c: "Israel" },
  { id: 11217, s: "CKHGY", n: "Capitec Bank Holdings ADR", c: "US" },
  { id: 11218, s: "GPH.V", n: "Graphite One Inc", c: "CA" },
  { id: 11219, s: "300069.SZ", n: "Zhejiang Jinlihua Electric", c: "CH" },
  { id: 11220, s: "CGO.L", n: "Contango Holdings PLC", c: "UK" },
  { id: 11221, s: "002951.SZ", n: "Sichuan Jinshi Technology Co Class A", c: "CH" },
  { id: 11222, s: "BILN.L", n: "Billington Holdings PLC", c: "UK" },
  { id: 11223, s: "KESKOA.HE", n: "Kesko Oyj", c: "Finland" },
  { id: 11224, s: "600223.SS", n: "Lushang Property", c: "CH" },
  { id: 11225, s: "ACRO.TA", n: "Kvutzat Acro.", c: "Israel" },
  { id: 11226, s: "IB.V", n: "IBC Advanced Alloys Co", c: "CA" },
  { id: 11227, s: "JINFF", n: "CH Gold International Resources", c: "US" },
  { id: 11228, s: "600297.SS", n: "CH Grand Automotive Services", c: "CH" },
  { id: 11229, s: "003005.SZ", n: "Beijing Jingyeda Technology ", c: "CH" },
  { id: 11230, s: "8044.TWO", n: "PChome Online", c: "TW" },
  { id: 11231, s: "ILC.V", n: "International Lithium Co", c: "CA" },
  { id: 11232, s: "PPG.AX", n: "Pro Pac Packaging", c: "Australia" },
  { id: 11233, s: "ABRA.TA", n: "Abra Information Technologies", c: "Israel" },
  { id: 11234, s: "IND.AX", n: "Indus Energy Nl", c: "Australia" },
  { id: 11235, s: "ITNS", n: "Itonis Inc", c: "US" },
  { id: 11236, s: "054050.KQ", n: "Nongwoobio Co.", c: "Korea" },
  { id: 11237, s: "HOKCY", n: "Hong Kong and CH Gas Co ADR", c: "US" },
  { id: 11238, s: "IIA.VI", n: "IMMOFINANZ AG", c: "Austria" },
  { id: 11239, s: "8089.TWO", n: "Comtrend Co", c: "TW" },
  { id: 11240, s: "GXYYY", n: "Galaxy Entertainment Group ADR", c: "US" },
  { id: 11241, s: "APH.L", n: "Alliance Pharma plc", c: "UK" },
  { id: 11242, s: "600362.SS", n: "Jiangxi Copper Co Class A", c: "CH" },
  { id: 11243, s: "020400.KQ", n: "Daedong Metals Co.", c: "Korea" },
  { id: 11244, s: "VSST", n: "Voice Assist Inc", c: "US" },
  { id: 11245, s: "CRW.L", n: "Craneware Plc", c: "UK" },
  { id: 11246, s: "ARIN.TA", n: "Ari Real Estate Arena Investment", c: "Israel" },
  { id: 11247, s: "OPL.WA", n: "OrangePL", c: "Poland" },
  { id: 11248, s: "WBRBY", n: "Wienerberger Baustoffindustrie", c: "US" },
  { id: 11249, s: "CIZ.L", n: "Cizzle Biotechnology Holdings PLC", c: "UK" },
  { id: 11250, s: "HXGBY", n: "Hexagon AB ADR", c: "US" },
  { id: 11251, s: "GNG.AX", n: "GR Engineering Services", c: "Australia" },
  { id: 11252, s: "MHC.AX", n: "Manhattan Co", c: "Australia" },
  { id: 11253, s: "CIC.L", n: "Conygar Investment Co PLC", c: "UK" },
  { id: 11254, s: "CHRT.L", n: "Cohort", c: "UK" },
  { id: 11255, s: "ALMET.PA", n: "Methanor", c: "FR" },
  { id: 11256, s: "6163.TWO", n: "Hwacom Systems", c: "TW" },
  { id: 11257, s: "GNM.AX", n: "Great Northern Minerals", c: "Australia" },
  { id: 11258, s: "AMD.TA", n: "Amiad Water Systems", c: "Israel" },
  { id: 11259, s: "6175.TWO", n: "Liton Technology", c: "TW" },
  { id: 11260, s: "MGH.AX", n: "MAAS Group Holdings", c: "Australia" },
  { id: 11261, s: "OTOVO.OL", n: "Otovo AS", c: "Norway" },
  { id: 11262, s: "GEM.AX", n: "G8 Education", c: "Australia" },
  { id: 11263, s: "RLF.AX", n: "RLF AgTech", c: "Australia" },
  { id: 11264, s: "300065.SZ", n: "Beijing Highlander Digital Technology", c: "CH" },
  { id: 11265, s: "AYAL.TA", n: "Ayalon Holdings", c: "Israel" },
  { id: 11266, s: "AURA.TA", n: "Aura Investments", c: "Israel" },
  { id: 11267, s: "5351.TWO", n: "Etron Technology", c: "TW" },
  { id: 11268, s: "SAU.AX", n: "Southern Gold", c: "Australia" },
  { id: 11269, s: "ASGR.TA", n: "Aspen Group", c: "Israel" },
  { id: 11270, s: "SZKMY", n: "Suzuki Motor Corp ADR", c: "US" },
  { id: 11271, s: "DFS.L", n: "DFS Furniture PLC", c: "UK" },
  { id: 11272, s: "FRM.AX", n: "Farm Pride Foods", c: "Australia" },
  { id: 11273, s: "086820.KQ", n: "Biosolution Co.Ltd", c: "Korea" },
  { id: 11274, s: "GLFH", n: "Galenfeha Inc", c: "US" },
  { id: 11275, s: "ELABS.OL", n: "Elliptic Laboratories AS", c: "Norway" },
  { id: 11276, s: "300003.SZ", n: "Lepu Medical Tech Beijing", c: "CH" },
  { id: 11277, s: "ALUMA.TA", n: "Aluma Infrastructure Fund 2020", c: "Israel" },
  { id: 11278, s: "6667.TWO", n: "Trusval Technology", c: "TW" },
  { id: 11279, s: "PLT.OL", n: "Polight ASA", c: "Norway" },
  { id: 11280, s: "DOM.WA", n: "Dom Development S.A.", c: "Poland" },
  { id: 11281, s: "ESI.PA", n: "ESI Group SA", c: "FR" },
  { id: 11282, s: "EIFF.PA", n: "Societe de la Tour Eiffel SA", c: "FR" },
  { id: 11283, s: "4995.TWO", n: "Litemax Electronics", c: "TW" },
  { id: 11284, s: "BOTI.TA", n: "Bonei Hatichon Civil Engineering and Infrastructures", c: "Israel" },
  { id: 11285, s: "BLSR.TA", n: "Blue Square Real Estate", c: "Israel" },
  { id: 11286, s: "CLN.WA", n: "Celon Pharma SA", c: "Poland" },
  { id: 11287, s: "GHT.L", n: "Gresham Computing", c: "UK" },
  { id: 11288, s: "FLTR.IR", n: "Flutter Entertainment PLC", c: "Ireland" },
  { id: 11289, s: "300214.SZ", n: "Shandong Rike Chemical", c: "CH" },
  { id: 11290, s: "NNL.AX", n: "Nordic Nickel", c: "Australia" },
  { id: 11291, s: "DBC.WA", n: "Firma Oponiarska DĘBICA SA", c: "Poland" },
  { id: 11292, s: "ERM.AX", n: "Emmerson Resources", c: "Australia" },
  { id: 11293, s: "GMAA.L", n: "Gama Aviation PLC", c: "UK" },
  { id: 11294, s: "EMX.V", n: "EMX Royalty Co", c: "CA" },
  { id: 11295, s: "BKRY.TA", n: "Bikurey Hasade Holdings", c: "Israel" },
  { id: 11296, s: "SIQ.AX", n: "SmartGroup Corporation", c: "Australia" },
  { id: 11297, s: "EQTY.V", n: "Equity Metals Co", c: "CA" },
  { id: 11298, s: "NOL.AX", n: "NobleOak Life", c: "Australia" },
  { id: 11299, s: "5014.TWO", n: "Chain Chon Industrial", c: "TW" },
  { id: 11300, s: "06N.WA", n: "Magna Polonia SA", c: "Poland" },
  { id: 11301, s: "EGL.AX", n: "The Environmental Group", c: "Australia" },
  { id: 11302, s: "035760.KQ", n: "CJ ENM CO.", c: "Korea" },
  { id: 11303, s: "PENNEO.", n: "Penneo AS", c: "Denmark" },
  { id: 11304, s: "300270.SZ", n: "Joyware Electronics", c: "CH" },
  { id: 11305, s: "XANO-B.ST", n: "XANO Industri AB (publ)", c: "Sweden" },
  { id: 11306, s: "WIHL.ST", n: "Wihlborgs Fastigheter AB", c: "Sweden" },
  { id: 11307, s: "300277.SZ", n: "Shenzhen Hirisun Technology Inc", c: "CH" },
  { id: 11308, s: "036930.KQ", n: "JUSUNG ENGINEERING Co.", c: "Korea" },
  { id: 11309, s: "FRXB", n: "Forest Road Acquisition Corp II", c: "US" },
  { id: 11310, s: "FOXT.L", n: "Foxtons Group Plc", c: "UK" },
  { id: 11311, s: "300176.SZ", n: "Guangdong Hongteo Accurate Technology", c: "CH" },
  { id: 11312, s: "PNOR.OL", n: "Petronor E&P", c: "Norway" },
  { id: 11313, s: "SRK.AX", n: "Strike Resources", c: "Australia" },
  { id: 11314, s: "GSN.AX", n: "Great Southern Mining", c: "Australia" },
  { id: 11315, s: "RAS.AX", n: "Ragusa Minerals", c: "Australia" },
  { id: 11316, s: "6245.TWO", n: "Lanner Electronics", c: "TW" },
  { id: 11317, s: "SRBNK.OL", n: "Sparebank 1 SR Bank ASA", c: "Norway" },
  { id: 11318, s: "BRWM.L", n: "Blackrock World Mining Trust Plc", c: "UK" },
  { id: 11319, s: "GUS.V", n: "Angus Ventures Inc", c: "CA" },
  { id: 11320, s: "HM1.AX", n: "Hearts and Minds Investments", c: "Australia" },
  { id: 11321, s: "600060.SS", n: "Hisense Electric", c: "CH" },
  { id: 11322, s: "HKSAV.HE", n: "HKScan Oyj A", c: "Finland" },
  { id: 11323, s: "CHGCY", n: "Chugai Pharmaceutical Co ADR", c: "US" },
  { id: 11324, s: "AKTIA.HE", n: "Aktia Bank Abp", c: "Finland" },
  { id: 11325, s: "STHC", n: "Southcorp Capital Inc", c: "US" },
  { id: 11326, s: "SSTT", n: "SStartrade Tech Inc", c: "US" },
  { id: 11327, s: "ALMA.HE", n: "Alma Media Oyj", c: "Finland" },
  { id: 11328, s: "ATRAV.HE", n: "Atria Oyj A", c: "Finland" },
  { id: 11329, s: "AFRE.TA", n: "Africa Israel Residences", c: "Israel" },
  { id: 11330, s: "EFECTE.HE", n: "Efecte Oyj", c: "Finland" },
  { id: 11331, s: "EQV1V.HE", n: "eQ Oyj", c: "Finland" },
  { id: 11332, s: "AFPR.TA", n: "Afi Properties", c: "Israel" },
  { id: 11333, s: "HZN.AX", n: "Horizon Oil", c: "Australia" },
  { id: 11334, s: "6469.TWO", n: "Great Tree Pharmacy", c: "TW" },
  { id: 11335, s: "BON.PA", n: "Bonduelle S.C.A.", c: "FR" },
  { id: 11336, s: "HLTOY", n: "Hellenic Telecommunications Org", c: "US" },
  { id: 11337, s: "ORPB", n: "Oregon Pacific BanCo", c: "US" },
  { id: 11338, s: "FRED.V", n: "Fredonia Mining Inc", c: "CA" },
  { id: 11339, s: "ERA.AX", n: "Energy Resources Of Australia", c: "Australia" },
  { id: 11340, s: "300192.SZ", n: "Suzhou Kingswood Printing Ink", c: "CH" },
  { id: 11341, s: "GELN.L", n: "Gelion PLC", c: "UK" },
  { id: 11342, s: "FAB.L", n: "Fusion Antibodies PLC", c: "UK" },
  { id: 11343, s: "TAR.WA", n: "Tarczynski S.A.", c: "Poland" },
  { id: 11344, s: "ADGR.TA", n: "Adgar Investments and Development", c: "Israel" },
  { id: 11345, s: "HMLA", n: "Homeland Resources", c: "US" },
  { id: 11346, s: "GMD.MX", n: "Grupo Mexicano de Desarrollo S.A.B", c: "Mexi" },
  { id: 11347, s: "MISH.TA", n: "Mivtach Shamir", c: "Israel" },
  { id: 11348, s: "MIFT.TA", n: "Millennium Food-Tech LP", c: "Israel" },
  { id: 11349, s: "XPDB", n: "Power & Digital Infrastructure Acquisition II Co", c: "US" },
  { id: 11350, s: "SFRN.TA", n: "Sufrin Holdings", c: "Israel" },
  { id: 11351, s: "WHI.L", n: "W.H.Ireland Group", c: "UK" },
  { id: 11352, s: "VBSN.SW", n: "IVF Hartmann Holding AG", c: "Switzerland" },
  { id: 11353, s: "GMI.SW", n: "Groupe Minoteries SA", c: "Switzerland" },
  { id: 11354, s: "008350.KS", n: "Namsun Alumini", c: "Korea" },
  { id: 11355, s: "FREN.SW", n: "Fundamenta Real Estate AG", c: "Switzerland" },
  { id: 11356, s: "TAVI.L", n: "Tavistock Investments Plc", c: "UK" },
  { id: 11357, s: "PTBL.TA", n: "Propert & Buil", c: "Israel" },
  { id: 11358, s: "PMNT.TA", n: "Payment Financial Technologies", c: "Israel" },
  { id: 11359, s: "STAF.L", n: "Staffline Group Plc", c: "UK" },
  { id: 11360, s: "DAE.SW", n: "Daetwyl I", c: "Switzerland" },
  { id: 11361, s: "PEHN.SW", n: "Private Equity Holding AG", c: "Switzerland" },
  { id: 11362, s: "ADV.AX", n: "Ardiden", c: "Australia" },
  { id: 11363, s: "TPIS3.SA", n: "Triunfo Participações e Investimentos S.A", c: "Brazil" },
  { id: 11364, s: "RAVD.TA", n: "Ravad", c: "Israel" },
  { id: 11365, s: "TGMA3.SA", n: "Tegma Gestão Logística S.A", c: "Brazil" },
  { id: 11366, s: "TTG.L", n: "TT Electronics Plc", c: "UK" },
  { id: 11367, s: "TYM.L", n: "Tertiary Minerals Plc", c: "UK" },
  { id: 11368, s: "TYMN.L", n: "Tyman Plc", c: "UK" },
  { id: 11369, s: "009440.KS", n: "Kc Green Holdi", c: "Korea" },
  { id: 11370, s: "SPEX.SW", n: "Spexis AG", c: "Switzerland" },
  { id: 11371, s: "SNR.L", n: "Senior PLC", c: "UK" },
  { id: 11372, s: "ORL.TA", n: "Bazan Oil Refineries", c: "Israel" },
  { id: 11373, s: "SEIT.L", n: "Sdcl Energy Efficiency Income Trust PLC", c: "UK" },
  { id: 11374, s: "UHRN.SW", n: "Swatch Group AG Class N", c: "Switzerland" },
  { id: 11375, s: "VARN.SW", n: "Varia US Properties", c: "Switzerland" },
  { id: 11376, s: "PNVL3.SA", n: "Dimed S.A. Distribuidora de Medicamentos", c: "Brazil" },
  { id: 11377, s: "AAJ.AX", n: "Aruma Resources", c: "Australia" },
  { id: 11378, s: "SOHO.L", n: "Triple Point Social Housing REIT PLC", c: "UK" },
  { id: 11379, s: "YNGA.L", n: "Young & Co’S Brewery A", c: "UK" },
  { id: 11380, s: "JHD.L", n: "James Halstead PLC", c: "UK" },
  { id: 11381, s: "AMRK.TA", n: "Amir Marketing and Investments in Agriculture", c: "Israel" },
  { id: 11382, s: "NWM.AX", n: "Norwest Minerals", c: "Australia" },
  { id: 11383, s: "PHLOG-B.ST", n: "Pharmacolog i Uppsala AB Series B", c: "Sweden" },
  { id: 11384, s: "170900.KS", n: "Dong-A St", c: "Korea" },
  { id: 11385, s: "CSX.AX", n: "Cleanspace Holdings Ltd", c: "Australia" },
  { id: 11386, s: "300399.SZ", n: "Jiangxi Tianli Technology INC", c: "CH" },
  { id: 11387, s: "AET.L", n: "Afentra PLC", c: "UK" },
  { id: 11388, s: "CU6.AX", n: "Clarity Pharmaceuticals", c: "Australia" },
  { id: 11389, s: "3577.TWO", n: "ICP DAS", c: "TW" },
  { id: 11390, s: "IVX.V", n: "Inventronics", c: "CA" },
  { id: 11391, s: "STRG.TA", n: "Storage Drop Storage Technologies", c: "Israel" },
  { id: 11392, s: "CLAV.ST", n: "Clavister Holding AB (publ.)", c: "Sweden" },
  { id: 11393, s: "AIRE.SW", n: "Airesis SA", c: "Switzerland" },
  { id: 11394, s: "ALI.AX", n: "Argo Global Listed Infrastructure", c: "Australia" },
  { id: 11395, s: "ADXN.SW", n: "Addex Therapeutics", c: "Switzerland" },
  { id: 11396, s: "009830.KS", n: "Hanwha Solutions", c: "Korea" },
  { id: 11397, s: "OGCP", n: "Empire State Realty OP LP", c: "US" },
  { id: 11398, s: "ODTC", n: "Odonate Therapeutics", c: "US" },
  { id: 11399, s: "SANB3.SA", n: "Banco Santander (Brasil) S.A", c: "Brazil" },
  { id: 11400, s: "ALY.AX", n: "Alchemy Resources", c: "Australia" },
  { id: 11401, s: "NTML.TA", n: "Neto Malinda", c: "Israel" },
  { id: 11402, s: "UBS.MC", n: "Urbas Grupo Financiero SA", c: "Spain" },
  { id: 11403, s: "LIC.AX", n: "Lifestyle Communities", c: "Australia" },
  { id: 11404, s: "ALG.AX", n: "Ardent Leisure", c: "Australia" },
  { id: 11405, s: "RBD.L", n: "Reabold Resources Plc", c: "UK" },
  { id: 11406, s: "XTEPY", n: "Xtep International Holdings ADR", c: "US" },
  { id: 11407, s: "UTRX", n: "Unitronix Corporation", c: "US" },
  { id: 11408, s: "SOL.MC", n: "Soltec Power Holdings S.A", c: "Spain" },
  { id: 11409, s: "ADG.AX", n: "Adelong Gold.", c: "Australia" },
  { id: 11410, s: "NVSEF", n: "Novartis AG", c: "US" },
  { id: 11411, s: "011810.KS", n: "STX Corporation", c: "Korea" },
  { id: 11412, s: "300905.SZ", n: "Poly Plastic Masterbatch Suzhou", c: "CH" },
  { id: 11413, s: "SMAG.TA", n: "Smart Agro LP", c: "Israel" },
  { id: 11414, s: "ALZCUR.ST", n: "AlzeCure Pharma", c: "Sweden" },
  { id: 11415, s: "012450.KS", n: "Hanwha Aerospace", c: "Korea" },
  { id: 11416, s: "ANNE-B.ST", n: "Annehem Fastigheter AB", c: "Sweden" },
  { id: 11417, s: "004020.KS", n: "Hyundai Steel", c: "Korea" },
  { id: 11418, s: "ARISE.ST", n: "Arise Windpower AB", c: "Sweden" },
  { id: 11419, s: "14D.AX", n: "1414 Degrees", c: "Australia" },
  { id: 11420, s: "URBI.MX", n: "Urbi Desarrollos Urbanos S.A.B. de C.V", c: "Mexi" },
  { id: 11421, s: "LFG.AX", n: "Liberty Financial Group Pty", c: "Australia" },
  { id: 11422, s: "300999.SZ", n: "Yihai Kerry Arawana Holdings", c: "CH" },
  { id: 11423, s: "014130.KS", n: "Han Express", c: "Korea" },
  { id: 11424, s: "OXB.L", n: "Oxford BioMedica PLC", c: "UK" },
  { id: 11425, s: "KAFR.TA", n: "Kafrit", c: "Israel" },
  { id: 11426, s: "HBOR3.SA", n: "Helbor Empreendimentos S.A", c: "Brazil" },
  { id: 11427, s: "BEIJ-B.ST", n: "Beijer Ref AB (publ)", c: "Sweden" },
  { id: 11428, s: "CPNFF", n: "Euro Sun Mining Inc", c: "US" },
  { id: 11429, s: "300903.SZ", n: "Guangdong Kingshine Electronic Tech", c: "CH" },
  { id: 11430, s: "014680.KS", n: "Hansol Chemica", c: "Korea" },
  { id: 11431, s: "AE.V", n: "American Eagle Gold Co", c: "CA" },
  { id: 11432, s: "1565.TWO", n: "St.Shine Optical", c: "TW" },
  { id: 11433, s: "1586.TWO", n: "CH Fineblanking Technology", c: "TW" },
  { id: 11434, s: "4171.TWO", n: "GeneReach Biotechnology", c: "TW" },
  { id: 11435, s: "300278.SZ", n: "Huachangda Intelligent Equipment Group", c: "CH" },
  { id: 11436, s: "LYL.AX", n: "Lycopodium", c: "Australia" },
  { id: 11437, s: "GMPR", n: "Gourmet Provisions International Co", c: "US" },
  { id: 11438, s: "003030.SZ", n: "Zuming Bean Products", c: "CH" },
  { id: 11439, s: "SEL.WA", n: "Selena FM S.A.", c: "Poland" },
  { id: 11440, s: "ALPIX.PA", n: "Pixium Vision SA", c: "FR" },
  { id: 11441, s: "AMX.TA", n: "Automax Motors", c: "Israel" },
  { id: 11442, s: "6121.TWO", n: "Simplo Technology", c: "TW" },
  { id: 11443, s: "ALZ.ST", n: "Alzinova AB", c: "Sweden" },
  { id: 11444, s: "SOLR.TA", n: "Solaer Israel", c: "Israel" },
  { id: 11445, s: "SPEN.TA", n: "Shapir Engineering Industry", c: "Israel" },
  { id: 11446, s: "ELMA", n: "Elmer Bancorp Inc", c: "US" },
  { id: 11447, s: "CIE.MC", n: "CIE Automotive S.A.", c: "Spain" },
  { id: 11448, s: "LEVI.TA", n: "Levinstein Eng", c: "Israel" },
  { id: 11449, s: "FRAS3.SA", n: "Fras-le S.A", c: "Brazil" },
  { id: 11450, s: "ELEK", n: "CH Xuefeng Environmental Engineering Inc", c: "US" },
  { id: 11451, s: "016380.KS", n: "Dongbu Steel", c: "Korea" },
  { id: 11452, s: "BONAS.ST", n: "Bonasudden Holding AB", c: "Sweden" },
  { id: 11453, s: "300832.SZ", n: "Shenzhen New Industries Biomedical", c: "CH" },
  { id: 11454, s: "ANK.V", n: "Angkor Resources Co", c: "CA" },
  { id: 11455, s: "ISHI.TA", n: "Israel Shipyards", c: "Israel" },
  { id: 11456, s: "300818.SZ", n: "Jiangxi Naipu Mining Machinery", c: "CH" },
  { id: 11457, s: "UATG", n: "Umbra Applied Technologies Group Inc", c: "US" },
  { id: 11458, s: "AIR.V", n: "Clean Air Metals Inc", c: "CA" },
  { id: 11459, s: "300808.SZ", n: "Guangdong Dp", c: "CH" },
  { id: 11460, s: "3105.TWO", n: "WIN Semiconductors", c: "TW" },
  { id: 11461, s: "AEDAS.MC", n: "Aedas Homes SL", c: "Spain" },
  { id: 11462, s: "BRIN-B.ST", n: "Brinova Fastigheter AB (publ)", c: "Sweden" },
  { id: 11463, s: "002790.KS", n: "Amore Group", c: "Korea" },
  { id: 11464, s: "ECR.MC", n: "Ercros", c: "Spain" },
  { id: 11465, s: "300877.SZ", n: "Anhui Jinchun Nonwoven ", c: "CH" },
  { id: 11466, s: "300875.SZ", n: "Tianjin Jieqiang Power Equipment", c: "CH" },
  { id: 11467, s: "300798.SZ", n: "Jiangsu Jinji Industrial", c: "CH" },
  { id: 11468, s: "AZL.AX", n: "Arizona Lithium", c: "Australia" },
  { id: 11469, s: "ENO", n: "Entergy New Orleans LLC Pref", c: "US" },
  { id: 11470, s: "AYM.AX", n: "Australia United Mining", c: "Australia" },
  { id: 11471, s: "LDX.AX", n: "Lumos Diagnostics Holdings", c: "Australia" },
  { id: 11472, s: "NMGX", n: "Nano Magic Inc", c: "US" },
  { id: 11473, s: "INRM.TA", n: "Inrom Construction Industries", c: "Israel" },
  { id: 11474, s: "ALM.ST", n: "ALM Equity AB (publ)", c: "Sweden" },
  { id: 11475, s: "HANK.V", n: "Hank Payments Co", c: "CA" },
  { id: 11476, s: "LMG.AX", n: "Latrobe Magnesium", c: "Australia" },
  { id: 11477, s: "BGO.L", n: "Bango plc", c: "UK" },
  { id: 11478, s: "OCL.AX", n: "Objective Co", c: "Australia" },
  { id: 11479, s: "EKI.PA", n: "Ekinops SA", c: "FR" },
  { id: 11480, s: "KRN.TO", n: "Karnalyte Resources Inc.", c: "CA" },
  { id: 11481, s: "WNBD", n: "Winning Brands Co", c: "US" },
  { id: 11482, s: "SAP.F", n: "SAP SE", c: "GER" },
  { id: 11483, s: "DAD.WA", n: "Dadelo S.A.", c: "Poland" },
  { id: 11484, s: "TAYA.TA", n: "Taya Inv-L", c: "Israel" },
  { id: 11485, s: "IBX.AX", n: "Imagion Biosystems", c: "Australia" },
  { id: 11486, s: "600143.SS", n: "Kingfa Sci&Tech", c: "CH" },
  { id: 11487, s: "SLGN.TA", n: "Solegreen", c: "Israel" },
  { id: 11488, s: "600960.SS", n: "Shandong Binzhou Bohai Piston", c: "CH" },
  { id: 11489, s: "PPS.AX", n: "Praemium", c: "Australia" },
  { id: 11490, s: "SPRG.TA", n: "Spring Ventures", c: "Israel" },
  { id: 11491, s: "TOPS.TA", n: "Top Systems-L", c: "Israel" },
  { id: 11492, s: "600971.SS", n: "Anhui Hengyuan Coal Industry and Electricity Power", c: "CH" },
  { id: 11493, s: "MUSTI.HE", n: "Musti Group Oyj", c: "Finland" },
  { id: 11494, s: "4741.TWO", n: "Jetbest", c: "TW" },
  { id: 11495, s: "4736.TWO", n: "Taidoc Technology", c: "TW" },
  { id: 11496, s: "TBIX.V", n: "TrustBIX Inc", c: "CA" },
  { id: 11497, s: "TGOPY", n: "3i Group PLC ADR", c: "US" },
  { id: 11498, s: "HSN.AX", n: "Hansen Technologies", c: "Australia" },
  { id: 11499, s: "065660.KQ", n: "Anterogen.Co.Ltd", c: "Korea" },
  { id: 11500, s: "AHFD", n: "Active Health Foods", c: "US" },
  { id: 11501, s: "4580.TWO", n: "Value Valves", c: "TW" },
  { id: 11502, s: "BONEH.HE", n: "BBS-Bioactive Bone Substitutes Oyj", c: "Finland" },
  { id: 11503, s: "600161.SS", n: "Beijing Tiantan Biological Products Co", c: "CH" },
  { id: 11504, s: "600150.SS", n: "CH CSSC Holdings", c: "CH" },
  { id: 11505, s: "TALN", n: "Talon International Inc", c: "US" },
  { id: 11506, s: "6547.TWO", n: "Medigen Vaccine Biologics", c: "TW" },
  { id: 11507, s: "STM.F", n: "Stabilus S.A.", c: "GER" },
  { id: 11508, s: "000820.SZ", n: "Shenwu Energy Saving", c: "CH" },
  { id: 11509, s: "TMD.TO", n: "Titan Medical Inc", c: "CA" },
  { id: 11510, s: "IDNT.TA", n: "Identi Healthcare", c: "Israel" },
  { id: 11511, s: "GAGR.TA", n: "Gaon Group", c: "Israel" },
  { id: 11512, s: "600594.SS", n: "Guizhou Yibai Pharmaceutical", c: "CH" },
  { id: 11513, s: "6156.TWO", n: "Song Shang Electronics", c: "TW" },
  { id: 11514, s: "000828.SZ", n: "Dongguan Development Holdings", c: "CH" },
  { id: 11515, s: "ALMOU.PA", n: "Moulinvest", c: "FR" },
  { id: 11516, s: "INTL.TA", n: "Intelicanna", c: "Israel" },
  { id: 11517, s: "ISOP.TA", n: "Israel Op L", c: "Israel" },
  { id: 11518, s: "ELSPC.TA", n: "Elspec", c: "Israel" },
  { id: 11519, s: "ALMER.PA", n: "Sapmer", c: "FR" },
  { id: 11520, s: "IBI.TA", n: "IBI Inv House", c: "Israel" },
  { id: 11521, s: "ASM.TO", n: "Avino Silver & Gold Mines", c: "CA" },
  { id: 11522, s: "000819.SZ", n: "Yueyang Xingchang Petro-Chemical", c: "CH" },
  { id: 11523, s: "TGTR.TA", n: "Together Startup Network", c: "Israel" },
  { id: 11524, s: "HLAN.TA", n: "Hilan", c: "Israel" },
  { id: 11525, s: "G107.TA", n: "Group 107", c: "Israel" },
  { id: 11526, s: "KLIL.TA", n: "Klil Industries", c: "Israel" },
  { id: 11527, s: "ALSAS.PA", n: "Stradim Espace Finances SA", c: "FR" },
  { id: 11528, s: "ALSAF.PA", n: "Safe Orthopaedics SA", c: "FR" },
  { id: 11529, s: "1476.TW", n: "Eclat Textile", c: "TW" },
  { id: 11530, s: "IGTA", n: "Inception Growth Acquisition", c: "US" },
  { id: 11531, s: "ACSO.L", n: "Accesso Technology Group PLC", c: "UK" },
  { id: 11532, s: "ALPRO.PA", n: "Prodware", c: "FR" },
  { id: 11533, s: "AMAN.TA", n: "Amanet Management & Systems", c: "Israel" },
  { id: 11534, s: "LAHAV.TA", n: "Lahav LR Real Estate", c: "Israel" },
  { id: 11535, s: "1313.TW", n: "UPC Technology Co", c: "TW" },
  { id: 11536, s: "ALA-PG.TO", n: "Altagas Pref G", c: "CA" },
  { id: 11537, s: "ABDP.L", n: "Ab Dynamics", c: "UK" },
  { id: 11538, s: "OPT.TO", n: "Optiva Inc", c: "CA" },
  { id: 11539, s: "FLYS.TA", n: "Flying Spark", c: "Israel" },
  { id: 11540, s: "FRDN.TA", n: "Fridenson", c: "Israel" },
  { id: 11541, s: "KNFM.TA", n: "Knafaim", c: "Israel" },
  { id: 11542, s: "BDZ.WA", n: "Elektrocieplownia Bedzin", c: "Poland" },
  { id: 11543, s: "3044.TW", n: "Tripod Technology Co", c: "TW" },
  { id: 11544, s: "IZMDC.IS", n: "Izmir Demir Celik Sanayi AS", c: "Turkey" },
  { id: 11545, s: "C7A.AX", n: "Clara Resources Australia.", c: "Australia" },
  { id: 11546, s: "VIP.L", n: "Value & Income Trust", c: "UK" },
  { id: 11547, s: "ADC.AX", n: "ACDC Metals.", c: "Australia" },
  { id: 11548, s: "002502.SZ", n: "Dinglong Culture", c: "CH" },
  { id: 11549, s: "2915.TW", n: "Ruentex Industries", c: "TW" },
  { id: 11550, s: "REOS", n: "ReoStar Energy Co", c: "US" },
  { id: 11551, s: "AIXA.F", n: "AIXTRON SE", c: "GER" },
  { id: 11552, s: "3005.TW", n: "Getac Technology Co", c: "TW" },
  { id: 11553, s: "JSCPY", n: "JSR Co", c: "US" },
  { id: 11554, s: "DBOX.L", n: "Digitalbox PLC", c: "UK" },
  { id: 11555, s: "2404.TW", n: "United Integrated Services", c: "TW" },
  { id: 11556, s: "688772.SS", n: "Zhuhai CosMX Battery", c: "CH" },
  { id: 11557, s: "SCOP.TA", n: "Scope Metals Group", c: "Israel" },
  { id: 11558, s: "VIPR.V", n: "Silver Viper Minerals Co", c: "CA" },
  { id: 11559, s: "RMN.TA", n: "Ram-On Investments and Holdings", c: "Israel" },
  { id: 11560, s: "002511.SZ", n: "C&S Paper", c: "CH" },
  { id: 11561, s: "CAT.AX", n: "Catapult Grp Intl", c: "Australia" },
  { id: 11562, s: "VSR.AX", n: "Voltaic Strategic Resources.", c: "Australia" },
  { id: 11563, s: "UNRG", n: "United Energy Co", c: "US" },
  { id: 11564, s: "BC8.F", n: "Bechtle AG", c: "GER" },
  { id: 11565, s: "ARZGY", n: "Assicurazioni Generali SpA ADR", c: "US" },
  { id: 11566, s: "010620.KS", n: "Hyundai Mipo Dockyard", c: "Korea" },
  { id: 11567, s: "BEI.F", n: "Beiersdorf Aktiengesellschaft", c: "GER" },
  { id: 11568, s: "605111.SS", n: "Wuxi Nce Power", c: "CH" },
  { id: 11569, s: "MSMY", n: "Mc Endvrs", c: "US" },
  { id: 11570, s: "3010.TW", n: "Wah Lee Industrial Co", c: "TW" },
  { id: 11571, s: "FHLT", n: "Future Health Esg Co", c: "US" },
  { id: 11572, s: "OVCHY", n: "Overseas Chinese Banking Corp ADR", c: "US" },
  { id: 11573, s: "MTA.V", n: "Metalla Royalty & Streaming", c: "CA" },
  { id: 11574, s: "FREJA.ST", n: "Freja eID Group AB", c: "Sweden" },
  { id: 11575, s: "HBLN.SW", n: "Hypothekarbank Lenzburg AG", c: "Switzerland" },
  { id: 11576, s: "JNGO.TA", n: "Jungo Connectivity", c: "Israel" },
  { id: 11577, s: "ROBO.TA", n: "Robogroup", c: "Israel" },
  { id: 11578, s: "CRRX.TO", n: "CareRx Co", c: "CA" },
  { id: 11579, s: "RTSN.TA", n: "Rotem Shani Entrepreneurship and Investments", c: "Israel" },
  { id: 11580, s: "ALRPR.TA", n: "Alrov Properties & Lodgings", c: "Israel" },
  { id: 11581, s: "NANOFH.HE", n: "Nanoform Finland Plc", c: "Finland" },
  { id: 11582, s: "600518.SS", n: "Kangmei Pharmaceutical", c: "CH" },
  { id: 11583, s: "SLCL.TA", n: "Silver Castle Holdings", c: "Israel" },
  { id: 11584, s: "WTMA", n: "Welsbach Technology Metals Acquisition Co", c: "US" },
  { id: 11585, s: "ALFBA.PA", n: "Fashion Bel Air S.A", c: "FR" },
  { id: 11586, s: "PNAX.TA", n: "Panaxia Labs Israel", c: "Israel" },
  { id: 11587, s: "TBNGY", n: "Bukit Asam Tbk PT ADR", c: "US" },
  { id: 11588, s: "PMVM.TA", n: "Pomvom", c: "Israel" },
  { id: 11589, s: "PLSN.TA", n: "Plasson Indus", c: "Israel" },
  { id: 11590, s: "AMS.L", n: "Advanced Medical Solutions Group plc", c: "UK" },
  { id: 11591, s: "GG.V", n: "Galane Gold", c: "CA" },
  { id: 11592, s: "PHTM.TA", n: "Photomyne", c: "Israel" },
  { id: 11593, s: "ALGEC.PA", n: "GECI International SA", c: "FR" },
  { id: 11594, s: "POFCY", n: "Petrofac ADR", c: "US" },
  { id: 11595, s: "ORTC.TA", n: "O.R.T.", c: "Israel" },
  { id: 11596, s: "PIQ.AX", n: "Proteomics Intl Labs", c: "Australia" },
  { id: 11597, s: "ORAD.TA", n: "Orad-M", c: "Israel" },
  { id: 11598, s: "WHN.V", n: "Westhaven Ventures Inc", c: "CA" },
  { id: 11599, s: "PCI.AX", n: "Perpetual Credit Income Trust", c: "Australia" },
  { id: 11600, s: "AREC.L", n: "Arecor Therapeutics PLC", c: "UK" },
  { id: 11601, s: "CARM.PA", n: "Carmila SA", c: "FR" },
  { id: 11602, s: "000885.SZ", n: "City Development Environment", c: "CH" },
  { id: 11603, s: "FSG.WA", n: "FASING SA", c: "Poland" },
  { id: 11604, s: "DBV.PA", n: "DBV Technologies S.A.", c: "FR" },
  { id: 11605, s: "PAMPALO.HE", n: "Endomines Finland Oyj", c: "Finland" },
  { id: 11606, s: "600523.SS", n: "Guizhou Guihang Automotive Components", c: "CH" },
  { id: 11607, s: "SEMG.TA", n: "Seach Medical Group", c: "Israel" },
  { id: 11608, s: "OPAL.TA", n: "Opal Balance", c: "Israel" },
  { id: 11609, s: "600096.SS", n: "Yunnan Yuntianhua", c: "CH" },
  { id: 11610, s: "ALDR.PA", n: "Delta Drone SA", c: "FR" },
  { id: 11611, s: "GLTL.TA", n: "Gilat Telecom Global", c: "Israel" },
  { id: 11612, s: "ISD.V", n: "iSign Media Solutions Inc", c: "CA" },
  { id: 11613, s: "GILT.TA", n: "Gilat Satellite Networks", c: "Israel" },
  { id: 11614, s: "NKL.AX", n: "Nickel X Limited", c: "Australia" },
  { id: 11615, s: "HRGI.OL", n: "Horisont Energi AS", c: "Norway" },
  { id: 11616, s: "MHJ.AX", n: "Michael Hill International", c: "Australia" },
  { id: 11617, s: "AAA.PA", n: "Alan Allman Associates SA", c: "FR" },
  { id: 11618, s: "ROX.V", n: "Canstar Resources Inc", c: "CA" },
  { id: 11619, s: "KNOS", n: "Kronos Advanced Technologies Inc", c: "US" },
  { id: 11620, s: "DMX.V", n: "District Metals Co", c: "CA" },
  { id: 11621, s: "GOZ.AX", n: "Growthpoint Properties Australia", c: "Australia" },
  { id: 11622, s: "SMGZY", n: "Smiths Group Plc", c: "US" },
  { id: 11623, s: "VGM.OL", n: "Vow Green Metals AS", c: "Norway" },
  { id: 11624, s: "OTS.OL", n: "Oceanteam ASA", c: "Norway" },
  { id: 11625, s: "CMPGY", n: "Compass Group PLC ADR", c: "US" },
  { id: 11626, s: "DLP.V", n: "DLP Resources Inc", c: "CA" },
  { id: 11627, s: "PSG.F", n: "PharmaSGP Holding SE", c: "GER" },
  { id: 11628, s: "DBG.V", n: "Doubleview Gold Co", c: "CA" },
  { id: 11629, s: "GCY.AX", n: "Gascoyne Resources", c: "Australia" },
  { id: 11630, s: "UNC.TO", n: "United Corporations Limited", c: "CA" },
  { id: 11631, s: "GUBRF.IS", n: "Gubre Fabrikalari TAS", c: "Turkey" },
  { id: 11632, s: "600257.SS", n: "Dahu Aquaculture", c: "CH" },
  { id: 11633, s: "601229.SS", n: "Bank of Shanghai", c: "CH" },
  { id: 11634, s: "078340.KQ", n: "Com2uS Corporation", c: "Korea" },
  { id: 11635, s: "000928.SZ", n: "Sinosteel Engineering and Technology", c: "CH" },
  { id: 11636, s: "3672.TWO", n: "Connection Technology Systems", c: "TW" },
  { id: 11637, s: "MGX.AX", n: "Mount Gibson Iron", c: "Australia" },
  { id: 11638, s: "IMR.V", n: "iMetal Resources Inc", c: "CA" },
  { id: 11639, s: "GMPW", n: "GiveMePower Co", c: "US" },
  { id: 11640, s: "CBP.L", n: "Curtis Banks", c: "UK" },
  { id: 11641, s: "MLSRP.PA", n: "Speed Rabbit Pizza SA", c: "FR" },
  { id: 11642, s: "600337.SS", n: "Markor International Home Furnishings", c: "CH" },
  { id: 11643, s: "GRV.AX", n: "Greenvale Energy", c: "Australia" },
  { id: 11644, s: "III.TO", n: "Imperial Metals Corporation", c: "CA" },
  { id: 11645, s: "PYT.VI", n: "Polytec Holding AG", c: "Austria" },
  { id: 11646, s: "000963.SZ", n: "Huadong Medicine", c: "CH" },
  { id: 11647, s: "000636.SZ", n: "Guangdong Fenghua Advanced Technology Holding", c: "CH" },
  { id: 11648, s: "EMGS.OL", n: "Electromagnetic Geoservices ASA", c: "Norway" },
  { id: 11649, s: "WSTEP.OL", n: "Webstep ASA", c: "Norway" },
  { id: 11650, s: "GRNG.ST", n: "Gränges AB (publ)", c: "Sweden" },
  { id: 11651, s: "MEDCL.PA", n: "Medincell SA", c: "FR" },
  { id: 11652, s: "6642.TWO", n: "Fuzetec Technology", c: "TW" },
  { id: 11653, s: "2201.TW", n: "Yulon Motor", c: "TW" },
  { id: 11654, s: "VAKKO.IS", n: "Vakko Tekstil ve Hazir Giyim Sanayi Isletmeleri AS", c: "Turkey" },
  { id: 11655, s: "EIM.IC", n: "Eimskipafélag Íslands hf", c: "Iceland" },
  { id: 11656, s: "NUMND.OL", n: "Nordic Unmanned As", c: "Norway" },
  { id: 11657, s: "BRK.L", n: "Brooks Macdonald Group", c: "UK" },
  { id: 11658, s: "ROVR.V", n: "Rover Metals Co", c: "CA" },
  { id: 11659, s: "ENTRA.OL", n: "Entra ASA", c: "Norway" },
  { id: 11660, s: "ABLZF", n: "ABB", c: "US" },
  { id: 11661, s: "068760.KQ", n: "Celltrion Pharm Inc", c: "Korea" },
  { id: 11662, s: "600348.SS", n: "Shan XI Hua Yang Group New Energy", c: "CH" },
  { id: 11663, s: "CFFS", n: "CF Acquisition VII Co", c: "US" },
  { id: 11664, s: "6679.TWO", n: "Zilltek Technology Co", c: "TW" },
  { id: 11665, s: "600418.SS", n: "Anhui Jianghuai Automobile Group Co", c: "CH" },
  { id: 11666, s: "018120.KQ", n: "Jinro Distillers Co.", c: "Korea" },
  { id: 11667, s: "600206.SS", n: "Grinm Advanced Materials", c: "CH" },
  { id: 11668, s: "300324.SZ", n: "Beijing Watertek Information Technology", c: "CH" },
  { id: 11669, s: "600420.SS", n: "Shanghai Shyndec Pharmaceutical", c: "CH" },
  { id: 11670, s: "JZZI", n: "Axihub Inc", c: "US" },
  { id: 11671, s: "FTZ.AX", n: "Fertoz", c: "Australia" },
  { id: 11672, s: "SME.V", n: "Sama Resources Inc.", c: "CA" },
  { id: 11673, s: "FWD.AX", n: "Fleetwood Corporation", c: "Australia" },
  { id: 11674, s: "4506.TWO", n: "Golden Friends", c: "TW" },
  { id: 11675, s: "MCE.AX", n: "Matrix Composites & Engineering", c: "Australia" },
  { id: 11676, s: "066970.KQ", n: "L&F Co.", c: "Korea" },
  { id: 11677, s: "MLCMB.PA", n: "Compagnie Du Mont-Blanc", c: "FR" },
  { id: 11678, s: "PARP.PA", n: "Groupe Partouche SA", c: "FR" },
  { id: 11679, s: "HLX.AX", n: "Helix Resources", c: "Australia" },
  { id: 11680, s: "GHY.AX", n: "Gold Hydrogen.", c: "Australia" },
  { id: 11681, s: "000596.SZ", n: "Anhui Gujing Distillery", c: "CH" },
  { id: 11682, s: "KOV.AX", n: "Korvest", c: "Australia" },
  { id: 11683, s: "OEC.AX", n: "Orbital Corporation", c: "Australia" },
  { id: 11684, s: "1231.TW", n: "Lian Hwa Foods Co", c: "TW" },
  { id: 11685, s: "AJIT.L", n: "abrdn Japan Investment Trust PLC", c: "UK" },
  { id: 11686, s: "PRM.TO", n: "Big Pharma Split Corp Class A", c: "CA" },
  { id: 11687, s: "6188.TWO", n: "Quanta Storage", c: "TW" },
  { id: 11688, s: "600008.SS", n: "Beijing Capital", c: "CH" },
  { id: 11689, s: "TUF.F", n: "Thai Union Group PCL DRC", c: "GER" },
  { id: 11690, s: "000800.SZ", n: "Faw Car", c: "CH" },
  { id: 11691, s: "MMS.AX", n: "Mcmillan Shakespeare", c: "Australia" },
  { id: 11692, s: "ACD.TO", n: "Accord Financial Corp.", c: "CA" },
  { id: 11693, s: "LSCO.TA", n: "Lesi", c: "Israel" },
  { id: 11694, s: "MCLL.TA", n: "Michlol Finance", c: "Israel" },
  { id: 11695, s: "MEDN.TA", n: "Mehadrin", c: "Israel" },
  { id: 11696, s: "5530.TWO", n: "Lungyen Life Service", c: "TW" },
  { id: 11697, s: "GTWO.V", n: "G2 Goldfields Inc", c: "CA" },
  { id: 11698, s: "AUGR.PA", n: "Augros Cosmetic Packaging SA", c: "FR" },
  { id: 11699, s: "NXTM.TA", n: "Nextcom", c: "Israel" },
  { id: 11700, s: "000869.SZ", n: "Yantai Changyu Pioneer Wine", c: "CH" },
  { id: 11701, s: "IINX", n: "Ionix Technology Inc", c: "US" },
  { id: 11702, s: "1233.TW", n: "Ten Ren Tea", c: "TW" },
  { id: 11703, s: "ARD.TA", n: "Arad", c: "Israel" },
  { id: 11704, s: "ARDM.TA", n: "Aerodrome Group", c: "Israel" },
  { id: 11705, s: "KCC.AX", n: "Kincora Copper DRC", c: "Australia" },
  { id: 11706, s: "ASHO.TA", n: "Ashot Ashkelon Industries", c: "Israel" },
  { id: 11707, s: "600018.SS", n: "Shanghai International Port Group", c: "CH" },
  { id: 11708, s: "KGD.AX", n: "Kula Gold", c: "Australia" },
  { id: 11709, s: "1536.TW", n: "Hota Industrial Mfg.", c: "TW" },
  { id: 11710, s: "MVP.WA", n: "Marvipol S.A.", c: "Poland" },
  { id: 11711, s: "600010.SS", n: "Inner Mongolia BaoTou Steel Union", c: "CH" },
  { id: 11712, s: "1308.TW", n: "Asia Polymer Co", c: "TW" },
  { id: 11713, s: "LHIS.TA", n: "Lachish", c: "Israel" },
  { id: 11714, s: "ALSMA.PA", n: "SMAIO SA", c: "FR" },
  { id: 11715, s: "AEGOF", n: "Aegon N.V", c: "US" },
  { id: 11716, s: "POM.TO", n: "Polymet Mining Co", c: "CA" },
  { id: 11717, s: "MRHL.TA", n: "Merchavia Holdings and Investments", c: "Israel" },
  { id: 11718, s: "2313.TW", n: "Compeq Manufacturing", c: "TW" },
  { id: 11719, s: "600256.SS", n: "Guanghui Energy", c: "CH" },
  { id: 11720, s: "LSF.AX", n: "L1 Long Short Fund", c: "Australia" },
  { id: 11721, s: "ICNB", n: "Iconic Brands Inc", c: "US" },
  { id: 11722, s: "SEV.V", n: "Spectra7 Microsystems Inc", c: "CA" },
  { id: 11723, s: "SKP.V", n: "Strikepoint Gold Inc", c: "CA" },
  { id: 11724, s: "GIGA.OL", n: "Gigante Salmon AS", c: "Norway" },
  { id: 11725, s: "MAQ.AX", n: "Macquarie Technology Group Limited", c: "Australia" },
  { id: 11726, s: "GLLI", n: "Globalink Investment Inc", c: "US" },
  { id: 11727, s: "361610.KS", n: "SK IE Technology", c: "Korea" },
  { id: 11728, s: "WFS.TO", n: "World Financial Split Co", c: "CA" },
  { id: 11729, s: "2014.TW", n: "Chung Hung Steel Co", c: "TW" },
  { id: 11730, s: "600489.SS", n: "Zhongjin Gold Co", c: "CH" },
  { id: 11731, s: "8446.TWO", n: "HIM International Music", c: "TW" },
  { id: 11732, s: "002906.SZ", n: "Foryou Co", c: "CH" },
  { id: 11733, s: "ENS-PA.TO", n: "E Split Co", c: "CA" },
  { id: 11734, s: "NUHCM.IS", n: "Nuh Cimento Sanayi AS", c: "Turkey" },
  { id: 11735, s: "028080.KQ", n: "Humax Holdings Co.", c: "Korea" },
  { id: 11736, s: "NTI.AX", n: "Neurotech International", c: "Australia" },
  { id: 11737, s: "000626.SZ", n: "Grand Industrial Holding", c: "CH" },
  { id: 11738, s: "000936.SZ", n: "Jiangsu Huaxicun", c: "CH" },
  { id: 11739, s: "600345.SS", n: "Wuhan Yangtze Communication Industry Group", c: "CH" },
  { id: 11740, s: "DSLV.V", n: "Denarius Silver Co", c: "CA" },
  { id: 11741, s: "RBSPF", n: "NatWest Group PLC", c: "US" },
  { id: 11742, s: "RAD.AX", n: "Radiopharm Theranostics", c: "Australia" },
  { id: 11743, s: "SIC.V", n: "Sokoman Minerals Co", c: "CA" },
  { id: 11744, s: "600500.SS", n: "Sinochem International Co", c: "CH" },
  { id: 11745, s: "IMCO.TA", n: "IMCO Industries", c: "Israel" },
  { id: 11746, s: "ENDR.V", n: "Enduro Metals Co", c: "CA" },
  { id: 11747, s: "BRIH.TA", n: "Rav Bariach 08 Industries", c: "Israel" },
  { id: 11748, s: "XFLS", n: "Xfuels Inc", c: "US" },
  { id: 11749, s: "GLKBN.SW", n: "Glarner Kantonalbank", c: "Switzerland" },
  { id: 11750, s: "002624.SZ", n: "Perfect World", c: "CH" },
  { id: 11751, s: "DGTW", n: "DigitalTown Inc", c: "US" },
  { id: 11752, s: "OBEL.BR", n: "Orange Belgium SA", c: "Belgium" },
  { id: 11753, s: "WAYS.ST", n: "Waystream Holding AB (publ)", c: "Sweden" },
  { id: 11754, s: "CNCT", n: "CH Teletech Holding Inc", c: "US" },
  { id: 11755, s: "B.V", n: "BCM Resources Co", c: "CA" },
  { id: 11756, s: "DVP.AX", n: "Develop Global", c: "Australia" },
  { id: 11757, s: "AZS.V", n: "Arizona Silver Exploration Inc", c: "CA" },
  { id: 11758, s: "000403.SZ", n: "Pacific Shuanglin Bio pharmacy", c: "CH" },
  { id: 11759, s: "2436.TW", n: "Weltrend Semiconductor Inc", c: "TW" },
  { id: 11760, s: "GLCVY.IS", n: "Gelecek Varlik Yonetimi AS", c: "Turkey" },
  { id: 11761, s: "BONZ", n: "Bonanza Goldfields", c: "US" },
  { id: 11762, s: "LVCA", n: "Lake Victoria Mining", c: "US" },
  { id: 11763, s: "HAT.L", n: "H&T Group plc", c: "UK" },
  { id: 11764, s: "AYEN.IS", n: "Ayen Enerji AS", c: "Turkey" },
  { id: 11765, s: "3218.TWO", n: "Universal Vision Biotechnology", c: "TW" },
  { id: 11766, s: "LVGI", n: "Limitless Venture", c: "US" },
  { id: 11767, s: "WYLD.ST", n: "Wyld Networks AB", c: "Sweden" },
  { id: 11768, s: "000413.SZ", n: "Tunghsu Optoelectronic Technology", c: "CH" },
  { id: 11769, s: "NYXH.BR", n: "Nyxoah", c: "Belgium" },
  { id: 11770, s: "032830.KS", n: "Samsung Life", c: "Korea" },
  { id: 11771, s: "WAVE.PA", n: "Wavestone S.A.", c: "FR" },
  { id: 11772, s: "109080.KQ", n: "Opticis Company Limited", c: "Korea" },
  { id: 11773, s: "000553.SZ", n: "ADAMA", c: "CH" },
  { id: 11774, s: "BTLCY", n: "British Land Company", c: "US" },
  { id: 11775, s: "000555.SZ", n: "Digital CH Information Service", c: "CH" },
  { id: 11776, s: "BURCA", n: "Burnham Holdings Inc", c: "US" },
  { id: 11777, s: "FHE.AX", n: "FRONTIER ENERGY LIMITED", c: "Australia" },
  { id: 11778, s: "FIN.AX", n: "Fin Resources", c: "Australia" },
  { id: 11779, s: "GJR", n: "Strats SM Trust for Procter & Gamble Securities Series 2006-1 GIC", c: "US" },
  { id: 11780, s: "SLCJY", n: "SLC Agricola SA ADR", c: "US" },
  { id: 11781, s: "KTPPF", n: "Katipult Technology Co", c: "US" },
  { id: 11782, s: "601778.SS", n: "Jinko Power Technology", c: "CH" },
  { id: 11783, s: "BCS.OL", n: "Bergen Carbon Solutions A/S", c: "Norway" },
  { id: 11784, s: "3211.TWO", n: "Dynapack International Technology", c: "TW" },
  { id: 11785, s: "AVU.V", n: "Avrupa Minerals", c: "CA" },
  { id: 11786, s: "PDGO", n: "Paradigm Oil And Gas", c: "US" },
  { id: 11787, s: "GETB.L", n: "GetBusy PLC", c: "UK" },
  { id: 11788, s: "NIHK", n: "Video River Networks", c: "US" },
  { id: 11789, s: "8112.TW", n: "Supreme Electronics", c: "TW" },
  { id: 11790, s: "AWON", n: "A1 Group Inc", c: "US" },
  { id: 11791, s: "CTT.ST", n: "CTT Systems AB", c: "Sweden" },
  { id: 11792, s: "OLIT", n: "Omnilit Acquisition Co", c: "US" },
  { id: 11793, s: "KYLO.AT", n: "Loulis Mills S.A.", c: "Greece" },
  { id: 11794, s: "MNC.V", n: "Magnetic North Acquisition Co", c: "CA" },
  { id: 11795, s: "GSJ.MC", n: "Grupo Empresarial San Jose SA", c: "Spain" },
  { id: 11796, s: "LIPI.ST", n: "Lipidor Ab", c: "Sweden" },
  { id: 11797, s: "FSNUY", n: "Fresenius SE & Co KGaA ADR", c: "US" },
  { id: 11798, s: "GLE.L", n: "MJ Gleeson plc", c: "UK" },
  { id: 11799, s: "BHS.V", n: "Bayhorse Silver Inc", c: "CA" },
  { id: 11800, s: "FLOB.BR", n: "Floridienne", c: "Belgium" },
  { id: 11801, s: "DBK.F", n: "Deutsche Bank Aktiengesellschaft", c: "GER" },
  { id: 11802, s: "A1OS.DE", n: "All For One Steeb AG", c: "GER" },
  { id: 11803, s: "RDU.V", n: "Radius Gold Inc.", c: "CA" },
  { id: 11804, s: "603020.SS", n: "Apple Flavor&Fragrance Grp", c: "CH" },
  { id: 11805, s: "LRDC", n: "Laredo Oil Inc", c: "US" },
  { id: 11806, s: "GEGP", n: "Gold Ent Group New", c: "US" },
  { id: 11807, s: "EEL.AX", n: "ENRG Elements.", c: "Australia" },
  { id: 11808, s: "BRM.V", n: "Biorem Inc", c: "CA" },
  { id: 11809, s: "SLZ.AX", n: "Sultan Resources", c: "Australia" },
  { id: 11810, s: "AMLH", n: "American Leisure Holdings Inc", c: "US" },
  { id: 11811, s: "000507.SZ", n: "Zhuhai Port", c: "CH" },
  { id: 11812, s: "601992.SS", n: "BBMG Corp Class A", c: "CH" },
  { id: 11813, s: "601989.SS", n: "CH Shipbuilding Industry", c: "CH" },
  { id: 11814, s: "HRPK.F", n: "7C Solarparken AG", c: "GER" },
  { id: 11815, s: "C.V", n: "Contact Gold Co", c: "CA" },
  { id: 11816, s: "EM2.AX", n: "Eagle Mountain Mining", c: "Australia" },
  { id: 11817, s: "ENX.AX", n: "Enegex NL", c: "Australia" },
  { id: 11818, s: "BFISH.OL", n: "Biofish Holding AS", c: "Norway" },
  { id: 11819, s: "FRB.AX", n: "Firebird Metals", c: "Australia" },
  { id: 11820, s: "TCSA3.SA", n: "Tecnisa S.A", c: "Brazil" },
  { id: 11821, s: "TBN.AX", n: "Tamboran Resources", c: "Australia" },
  { id: 11822, s: "SYNSAM.ST", n: "Synsam AB", c: "Sweden" },
  { id: 11823, s: "FULO", n: "FullNet Communications Inc", c: "US" },
  { id: 11824, s: "MXSG", n: "Mexus Gold Us", c: "US" },
  { id: 11825, s: "COM.MC", n: "Catenon S.A", c: "Spain" },
  { id: 11826, s: "IVPG.L", n: "Invesco Perpetual Glbl Equity", c: "UK" },
  { id: 11827, s: "200470.KQ", n: "Hisem", c: "Korea" },
  { id: 11828, s: "PCSV", n: "Pcs Edventures Com", c: "US" },
  { id: 11829, s: "RWC.AX", n: "Reliance Worldwide Corporation", c: "Australia" },
  { id: 11830, s: "173940.KQ", n: "FNC Entertainment", c: "Korea" },
  { id: 11831, s: "AQNB", n: "Algonquin Power Utilities Co", c: "US" },
  { id: 11832, s: "CUE.AX", n: "CUE Energy Resources", c: "Australia" },
  { id: 11833, s: "QUIA.ST", n: "QuiaPEG Pharmaceuticals Holding AB", c: "Sweden" },
  { id: 11834, s: "FUWAY", n: "Furukawa Electric Co ADR", c: "US" },
  { id: 11835, s: "PLY.V", n: "Playfair Mining.", c: "CA" },
  { id: 11836, s: "HLCL.L", n: "Helical Bar Plc", c: "UK" },
  { id: 11837, s: "PRIC-B.ST", n: "Pricer AB (publ)", c: "Sweden" },
  { id: 11838, s: "AVHOL.IS", n: "Avrupa Yatirim Holding AS", c: "Turkey" },
  { id: 11839, s: "2492.TW", n: "Walsin Technology Co", c: "TW" },
  { id: 11840, s: "BLXX", n: "Blox Inc", c: "US" },
  { id: 11841, s: "DBF.AX", n: "Duxton Broadacre Farms", c: "Australia" },
  { id: 11842, s: "GAMC", n: "Golden Arrow Merger Co", c: "US" },
  { id: 11843, s: "TGF.AX", n: "Tribeca Global Natural Resources", c: "Australia" },
  { id: 11844, s: "INSE.L", n: "Inspired Plc", c: "UK" },
  { id: 11845, s: "SGSOY", n: "SGS SA", c: "US" },
  { id: 11846, s: "017940.KS", n: "E1 Corporation", c: "Korea" },
  { id: 11847, s: "FXCNY", n: "FIH Mobile ADR", c: "US" },
  { id: 11848, s: "CZR.AX", n: "Czr Resources", c: "Australia" },
  { id: 11849, s: "QAIR.ST", n: "Qleanair Holding AB", c: "Sweden" },
  { id: 11850, s: "TFBANK.ST", n: "TF Bank AB", c: "Sweden" },
  { id: 11851, s: "2480.TW", n: "Stark Technology Inc", c: "TW" },
  { id: 11852, s: "HHI.L", n: "Henderson High Income Trust", c: "UK" },
  { id: 11853, s: "000066.SZ", n: "CH Greatwall Computer Shenzhen", c: "CH" },
  { id: 11854, s: "SDS.ST", n: "Seamless Distribution Systems AB", c: "Sweden" },
  { id: 11855, s: "SWEC-A.ST", n: "Sweco AB (publ)", c: "Sweden" },
  { id: 11856, s: "000531.SZ", n: "Guangzhou Hengyun Enterprises Holding", c: "CH" },
  { id: 11857, s: "DEST.L", n: "Destiny Pharma PLC", c: "UK" },
  { id: 11858, s: "CTM.V", n: "Canterra Minerals Corporation", c: "CA" },
  { id: 11859, s: "KBHL.", n: "Copenhagen Airports AS", c: "Denmark" },
  { id: 11860, s: "FRIGO.IS", n: "Frigo-Pak Gida Maddeleri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 11861, s: "601881.SS", n: "CH Galaxy Securities Co Class A", c: "CH" },
  { id: 11862, s: "EQT.AX", n: "EQT Holdings", c: "Australia" },
  { id: 11863, s: "CBLU.V", n: "Clear Blue Technologies International Inc", c: "CA" },
  { id: 11864, s: "ESS.AX", n: "Essential Metals", c: "Australia" },
  { id: 11865, s: "CBR.V", n: "Cabral Gold Inc", c: "CA" },
  { id: 11866, s: "SGQ.AX", n: "St George Mining", c: "Australia" },
  { id: 11867, s: "601901.SS", n: "Founder Securities", c: "CH" },
  { id: 11868, s: "TNG.PA", n: "Transgene SA", c: "FR" },
  { id: 11869, s: "601898.SS", n: "CH Coal Energy", c: "CH" },
  { id: 11870, s: "300500.SZ", n: "Tus-Design Group Co Class A", c: "CH" },
  { id: 11871, s: "EGPRO.IS", n: "Ege Profil Ticaret ve Sanayi AS", c: "Turkey" },
  { id: 11872, s: "CYC.AX", n: "Cyclopharm", c: "Australia" },
  { id: 11873, s: "000534.SZ", n: "Wedge Industrial", c: "CH" },
  { id: 11874, s: "ETX.L", n: "e-therapeutics plc", c: "UK" },
  { id: 11875, s: "SPEL.PA", n: "Foncière Volta", c: "FR" },
  { id: 11876, s: "000541.SZ", n: "Foshan Electrical and Lighting", c: "CH" },
  { id: 11877, s: "SBS.", n: "Scandinavian Brake Systems A/S", c: "Denmark" },
  { id: 11878, s: "300502.SZ", n: "Eoptolink Technology Inc", c: "CH" },
  { id: 11879, s: "CEV.HM", n: "Centrotec SE", c: "GER" },
  { id: 11880, s: "POOL.V", n: "Pool Safe Inc", c: "CA" },
  { id: 11881, s: "DCC.AX", n: "Digitalx", c: "Australia" },
  { id: 11882, s: "QIIWI.ST", n: "Qiiwi Games AB (publ)", c: "Sweden" },
  { id: 11883, s: "QLIRO.ST", n: "Qliro AB", c: "Sweden" },
  { id: 11884, s: "CEK.DE", n: "CeoTronics AG", c: "GER" },
  { id: 11885, s: "CFC.DE", n: "UET United Electronic Technology AG", c: "GER" },
  { id: 11886, s: "2538.TW", n: "Kee Tai Properties", c: "TW" },
  { id: 11887, s: "8033.TW", n: "Thunder Tiger Co", c: "TW" },
  { id: 11888, s: "SPRINT.ST", n: "Sprint Bioscience AB", c: "Sweden" },
  { id: 11889, s: "8028.TW", n: "Phoenix Silicon International Co", c: "TW" },
  { id: 11890, s: "PPERY", n: "Bank Mandiri Persero Tbk PT ADR", c: "US" },
  { id: 11891, s: "CIP.AX", n: "Centuria Industrial Reit Unit", c: "Australia" },
  { id: 11892, s: "CI1.AX", n: "Credit Intelligence", c: "Australia" },
  { id: 11893, s: "VAL.AX", n: "Valor Resources", c: "Australia" },
  { id: 11894, s: "IC8.DE", n: "InCity Immobilien AG", c: "GER" },
  { id: 11895, s: "MCOV-B.ST", n: "Medicover AB (publ)", c: "Sweden" },
  { id: 11896, s: "CHK.AX", n: "Cohiba Minerals", c: "Australia" },
  { id: 11897, s: "XTR.L", n: "Xtract Resources PLC", c: "UK" },
  { id: 11898, s: "688505.SS", n: "Shanghai Fudan-Zhangjiang Bio-Pharmaceutical", c: "CH" },
  { id: 11899, s: "CF1.AX", n: "Complii Fintech Solutions", c: "Australia" },
  { id: 11900, s: "000012.SZ", n: "CSG Holding", c: "CH" },
  { id: 11901, s: "VIT.AX", n: "Vitura Health Limited", c: "Australia" },
  { id: 11902, s: "FTC.AX", n: "TTG Fintech", c: "Australia" },
  { id: 11903, s: "USI.MC", n: "Umbrella Solar Investment SA", c: "Spain" },
  { id: 11904, s: "CL8.AX", n: "Carly Holdings", c: "Australia" },
  { id: 11905, s: "011200.KS", n: "HMM", c: "Korea" },
  { id: 11906, s: "CTPCY", n: "Citic ADR", c: "US" },
  { id: 11907, s: "MLLUY", n: "Metallurgical Corporation of CH ADR", c: "US" },
  { id: 11908, s: "603918.SS", n: "Shanghai Golden Bridge", c: "CH" },
  { id: 11909, s: "ARTE", n: "Artemis Strategic Investment Co", c: "US" },
  { id: 11910, s: "BVB.F", n: "Borussia Dortmund GmbH & Co KGaA", c: "GER" },
  { id: 11911, s: "VERZ34.SA", n: "Verizon Communications Inc", c: "Brazil" },
  { id: 11912, s: "MSCL.V", n: "Satellos Bioscience Inc", c: "CA" },
  { id: 11913, s: "TEG.L", n: "Ten Entertainment Group PLC", c: "UK" },
  { id: 11914, s: "EAI", n: "Entergy Arkansas LLC Deb 2066", c: "US" },
  { id: 11915, s: "6412.TW", n: "Chicony Power Technology", c: "TW" },
  { id: 11916, s: "ATH.AX", n: "Alterity Therapeutics", c: "Australia" },
  { id: 11917, s: "ATORX.ST", n: "Alligator Bioscience AB", c: "Sweden" },
  { id: 11918, s: "ECOX", n: "Eco Innovation Group Inc", c: "US" },
  { id: 11919, s: "ATP.AX", n: "Atlas Pearls and Perfumes", c: "Australia" },
  { id: 11920, s: "4766.TW", n: "Nan Pao Resins Chemical", c: "TW" },
  { id: 11921, s: "002259.SZ", n: "Sichuan Shengda Forestry Industry", c: "CH" },
  { id: 11922, s: "NOCR.V", n: "Norden Crown Metals Co", c: "CA" },
  { id: 11923, s: "ZNO.AX", n: "Zoono Group", c: "Australia" },
  { id: 11924, s: "PRO.MC", n: "Proeduca Altus S.A", c: "Spain" },
  { id: 11925, s: "NCAB.ST", n: "NCAB Group", c: "Sweden" },
  { id: 11926, s: "SHRS.L", n: "Shires Income", c: "UK" },
  { id: 11927, s: "688009.SS", n: "Railway Signal Communication", c: "CH" },
  { id: 11928, s: "AFG.AX", n: "Australian Finance Group", c: "Australia" },
  { id: 11929, s: "SCOO", n: "School Specialty Inc", c: "US" },
  { id: 11930, s: "EDHN.SW", n: "Energiedienst Holding AG", c: "Switzerland" },
  { id: 11931, s: "TRCS.L", n: "Tracsis Plc", c: "UK" },
  { id: 11932, s: "3030.TW", n: "Test Research Inc", c: "TW" },
  { id: 11933, s: "TRP.L", n: "Tower Resources plc", c: "UK" },
  { id: 11934, s: "3023.TW", n: "Sinbon Electronics", c: "TW" },
  { id: 11935, s: "3019.TW", n: "Asia Optical Co Inc", c: "TW" },
  { id: 11936, s: "PACV", n: "Pacific Ventures Group Inc", c: "US" },
  { id: 11937, s: "000010.SZ", n: "Shenzhen Ecobeauty", c: "CH" },
  { id: 11938, s: "CWYUF", n: "Smart REIT", c: "US" },
  { id: 11939, s: "LBE.L", n: "Longboat Energy Plc", c: "UK" },
  { id: 11940, s: "NUG.V", n: "Nulegacy Gold Corporation", c: "CA" },
  { id: 11941, s: "JGC.L", n: "Jupiter Green Investment Trust PLC", c: "UK" },
  { id: 11942, s: "227950.KQ", n: "N2Tech Co.", c: "Korea" },
  { id: 11943, s: "226950.KQ", n: "OliX PharmaceuticalsInc", c: "Korea" },
  { id: 11944, s: "226400.KQ", n: "Osteonic Co.", c: "Korea" },
  { id: 11945, s: "NXLV.V", n: "NexLiving Communities Inc", c: "CA" },
  { id: 11946, s: "000027.SZ", n: "Shenzhen Energy Group", c: "CH" },
  { id: 11947, s: "INVEXA.MX", n: "INVEX Controladora S.A.B. de C.V", c: "Mexi" },
  { id: 11948, s: "MVC.MC", n: "Metrovacesa SA", c: "Spain" },
  { id: 11949, s: "9958.TW", n: "Century Iron And Steel Industrial", c: "TW" },
  { id: 11950, s: "CNI.AX", n: "Centuria Capital", c: "Australia" },
  { id: 11951, s: "NJOB.ST", n: "NetJobs Group AB", c: "Sweden" },
  { id: 11952, s: "CRHCF", n: "CRH plc", c: "US" },
  { id: 11953, s: "JNEO.L", n: "Journeo PLC", c: "UK" },
  { id: 11954, s: "000032.SZ", n: "Shenzhen Sed Industry", c: "CH" },
  { id: 11955, s: "2634.TW", n: "Aerospace Industrial Development Co", c: "TW" },
  { id: 11956, s: "ATR.AX", n: "Astron Corporation", c: "Australia" },
  { id: 11957, s: "CICN.SW", n: "Cicor Technologies", c: "Switzerland" },
  { id: 11958, s: "INTB3.SA", n: "Intelbras S.A. - Indústria de Telecomunicação Eletrônica Brasileira", c: "Brazil" },
  { id: 11959, s: "ATOM3.SA", n: "Atom Empreendimentos e Participações S.A", c: "Brazil" },
  { id: 11960, s: "EPRO-B.ST", n: "Electrolux Professional AB (publ)", c: "Sweden" },
  { id: 11961, s: "ALPA3.SA", n: "Alpargatas S.A", c: "Brazil" },
  { id: 11962, s: "BCCI", n: "Baristas Coffee Company Inc", c: "US" },
  { id: 11963, s: "AMZO34.SA", n: "Amazon.com Inc", c: "Brazil" },
  { id: 11964, s: "NECA", n: "New Amer Energy", c: "US" },
  { id: 11965, s: "NREN.SW", n: "Novavest Real Estate Ag", c: "Switzerland" },
  { id: 11966, s: "3376.TW", n: "Shin Zu Shing", c: "TW" },
  { id: 11967, s: "FHER3.SA", n: "Fertilizantes Heringer S.A", c: "Brazil" },
  { id: 11968, s: "OFN.SW", n: "Orell Fuessli Holding AG", c: "Switzerland" },
  { id: 11969, s: "PEDU.SW", n: "Perrot Duval Holding SA", c: "Switzerland" },
  { id: 11970, s: "002007.SZ", n: "Hualan Biological EngineeringInc", c: "CH" },
  { id: 11971, s: "688126.SS", n: "National Silicon Industry Group", c: "CH" },
  { id: 11972, s: "002365.SZ", n: "Qianjiang Yongan Pharmaceutical", c: "CH" },
  { id: 11973, s: "NAH.L", n: "NAHL Group PLC", c: "UK" },
  { id: 11974, s: "NDBKY", n: "Nedbank Group", c: "US" },
  { id: 11975, s: "007810.KS", n: "Korea Circuit Co..", c: "Korea" },
  { id: 11976, s: "LLQ.SW", n: "Lalique Group SA", c: "Switzerland" },
  { id: 11977, s: "USIM3.SA", n: "Usinas Siderúrgicas de Minas Gerais S.A", c: "Brazil" },
  { id: 11978, s: "BLX.AX", n: "Beacon Lighting Group", c: "Australia" },
  { id: 11979, s: "8163.TW", n: "Darfon Electronics Co", c: "TW" },
  { id: 11980, s: "BIOSGN.ST", n: "Biosergen AS", c: "Sweden" },
  { id: 11981, s: "ESPGY", n: "Esprit Holdings", c: "US" },
  { id: 11982, s: "SFPN.SW", n: "SF Urban Properties AG", c: "Switzerland" },
  { id: 11983, s: "AVHI", n: "Achari Ventures Holdings Corp I", c: "US" },
  { id: 11984, s: "ELRA", n: "Elray Resources Inc", c: "US" },
  { id: 11985, s: "VLRT.SW", n: "Valartis Group AG", c: "Switzerland" },
  { id: 11986, s: "688298.SS", n: "Zhejiang Orient Gene Biotech", c: "CH" },
  { id: 11987, s: "006360.KS", n: "GS Engineering & Construction", c: "Korea" },
  { id: 11988, s: "EMP", n: "Entergy Mississippi LLC", c: "US" },
  { id: 11989, s: "688499.SS", n: "Guangdong Lyric Robot Automation", c: "CH" },
  { id: 11990, s: "FRIGO.AT", n: "Frigoglass S.A.I.C.", c: "Greece" },
  { id: 11991, s: "ELGEK.AT", n: "Elgeka S.A", c: "Greece" },
  { id: 11992, s: "WARN.SW", n: "Warteck Invest", c: "Switzerland" },
  { id: 11993, s: "PLUN.ST", n: "ProstaLund AB", c: "Sweden" },
  { id: 11994, s: "ACCON.ST", n: "Acconeer AB", c: "Sweden" },
  { id: 11995, s: "RTMVY", n: "Rightmove Plc", c: "US" },
  { id: 11996, s: "900940.SS", n: "Greattown Holdings B", c: "CH" },
  { id: 11997, s: "688408.SS", n: "Arctech Solar Holding", c: "CH" },
  { id: 11998, s: "ASMVY", n: "Asm Pacific Technology ADR", c: "US" },
  { id: 11999, s: "RRTS", n: "Roadrunner Transportation Systems Inc", c: "US" },
  { id: 12000, s: "BFG.ST", n: "BYGGFAKTA GROUP Nordic HoldCo AB", c: "Sweden" },
  { id: 12001, s: "BIOT.AT", n: "Bioter S.A.", c: "Greece" },
  { id: 12002, s: "MILS3.SA", n: "Mills Estruturas e Serviços de Engenharia S.A", c: "Brazil" },
  { id: 12003, s: "ONDO.L", n: "Ondo InsurTech PLC", c: "UK" },
  { id: 12004, s: "6409.TW", n: "Voltronic Power Technology Co", c: "TW" },
  { id: 12005, s: "4968.TW", n: "RichWave Technology Co", c: "TW" },
  { id: 12006, s: "ABLI.ST", n: "Abliva AB", c: "Sweden" },
  { id: 12007, s: "PHI.L", n: "Pacific Horizon Investment Trust", c: "UK" },
  { id: 12008, s: "DROOF", n: "Deliveroo Holdings PLC", c: "US" },
  { id: 12009, s: "002280.SZ", n: "Hangzhou New Century Info Tech", c: "CH" },
  { id: 12010, s: "PCAR3.SA", n: "Companhia Brasileira de Distribuição", c: "Brazil" },
  { id: 12011, s: "PYC.L", n: "Physiomics Plc", c: "UK" },
  { id: 12012, s: "6176.TW", n: "Radiant Opto-Electronics Co", c: "TW" },
  { id: 12013, s: "ANP.AX", n: "Antisense Therapeutics", c: "Australia" },
  { id: 12014, s: "005070.KS", n: "Cosmoam&T", c: "Korea" },
  { id: 12015, s: "900929.SS", n: "Shanghai Jinjiang International Travel", c: "CH" },
  { id: 12016, s: "DTW", n: "DTE Energy Co Pref Series E", c: "US" },
  { id: 12017, s: "CTAC.AS", n: "Ctac N.V", c: "Netherlands" },
  { id: 12018, s: "QBT.L", n: "Quantum Blockchain Technologies Plc", c: "UK" },
  { id: 12019, s: "NUUU", n: "Rejuvel Bio-Sciences Inc", c: "US" },
  { id: 12020, s: "ATDRY", n: "Auto Trader Group PLC ADR", c: "US" },
  { id: 12021, s: "6196.TW", n: "Marketech International Co", c: "TW" },
  { id: 12022, s: "NSE.AS", n: "New Sources Energy N.V", c: "Netherlands" },
  { id: 12023, s: "ARA.AX", n: "Ariadne Australia", c: "Australia" },
  { id: 12024, s: "PRSR.L", n: "PRS Reit PLC", c: "UK" },
  { id: 12025, s: "MBX.TO", n: "Microbix Biosystems Inc.", c: "CA" },
  { id: 12026, s: "DURC-B.ST", n: "Duroc AB (publ)", c: "Sweden" },
  { id: 12027, s: "ASO.AX", n: "Aston Minerals", c: "Australia" },
  { id: 12028, s: "LVT.AX", n: "Livetiles", c: "Australia" },
  { id: 12029, s: "001740.KS", n: "SK Networks", c: "Korea" },
  { id: 12030, s: "000883.SZ", n: "Hubei Energy Group", c: "CH" },
  { id: 12031, s: "ASQ.AX", n: "Australian Silica Quartz Group", c: "Australia" },
  { id: 12032, s: "ESLOY", n: "Essilor International SA", c: "US" },
  { id: 12033, s: "RIMO.TA", n: "Rimoni", c: "Israel" },
  { id: 12034, s: "VHAQ", n: "Viveon Health Acquisition Co", c: "US" },
  { id: 12035, s: "PSG.DE", n: "PharmaSGP Holding SE", c: "GER" },
  { id: 12036, s: "CAG.ST", n: "CAG Group AB (publ)", c: "Sweden" },
  { id: 12037, s: "HMD.AX", n: "HeraMED", c: "Australia" },
  { id: 12038, s: "KWG.DE", n: "KHD Humboldt Wedag International AG", c: "GER" },
  { id: 12039, s: "000210.KS", n: "DL Holdings CO..", c: "Korea" },
  { id: 12040, s: "BDT.DE", n: "Bertrandt Aktiengesellschaft", c: "GER" },
  { id: 12041, s: "000650.SZ", n: "Renhe Pharmacy", c: "CH" },
  { id: 12042, s: "GTC.L", n: "Getech Group", c: "UK" },
  { id: 12043, s: "DVR.AX", n: "Diverger", c: "Australia" },
  { id: 12044, s: "WISE.ST", n: "Wise Group AB", c: "Sweden" },
  { id: 12045, s: "XSPRAY.ST", n: "XSpray Pharma AB", c: "Sweden" },
  { id: 12046, s: "000409.SZ", n: "Yunding Technology", c: "CH" },
  { id: 12047, s: "SRN.AX", n: "Surefire Resources NL", c: "Australia" },
  { id: 12048, s: "LPSB3.SA", n: "LPS Brasil - Consultoria de Imóveis S.A", c: "Brazil" },
  { id: 12049, s: "BERA.IS", n: "Bera Holding AS", c: "Turkey" },
  { id: 12050, s: "GPH.L", n: "Global Ports Holding PLC", c: "UK" },
  { id: 12051, s: "EARI", n: "Entertainment Arts Research Inc", c: "US" },
  { id: 12052, s: "000926.SZ", n: "Hubei Fuxing Science and Technology", c: "CH" },
  { id: 12053, s: "ADGO", n: "Advantego Corporation", c: "US" },
  { id: 12054, s: "MBH.AX", n: "Maggie Beer Holdings", c: "Australia" },
  { id: 12055, s: "ELAN-B.ST", n: "Elanders AB (publ)", c: "Sweden" },
  { id: 12056, s: "000420.SZ", n: "Jilin Chemical Fibre", c: "CH" },
  { id: 12057, s: "MNDL3.SA", n: "Mundial S.A. - Produtos de Consumo", c: "Brazil" },
  { id: 12058, s: "MAH.AX", n: "Macmahon Holdings", c: "Australia" },
  { id: 12059, s: "6288.TW", n: "Excellence Optoelectronic Inc", c: "TW" },
  { id: 12060, s: "WYGPY", n: "Worley Parsons", c: "US" },
  { id: 12061, s: "OOA.L", n: "Octopus Aim Vct Plc", c: "UK" },
  { id: 12062, s: "MUL.L", n: "Mulberry Group PLC", c: "UK" },
  { id: 12063, s: "AS2.AX", n: "Askari Metals", c: "Australia" },
  { id: 12064, s: "BIT.AX", n: "Biotron", c: "Australia" },
  { id: 12065, s: "001979.SZ", n: "CH Merchants Shekou Industrial Zone Holdings", c: "CH" },
  { id: 12066, s: "001965.SZ", n: "Merchant Express Class A", c: "CH" },
  { id: 12067, s: "000868.SZ", n: "Anhui Ankai Automobile", c: "CH" },
  { id: 12068, s: "CRAD-B.ST", n: "C-Rad AB (publ)", c: "Sweden" },
  { id: 12069, s: "001440.KS", n: "Taihan Electric Wire", c: "Korea" },
  { id: 12070, s: "SELEC.IS", n: "Selcuk Ecza Deposu Ticaret ve Sanayi AS", c: "Turkey" },
  { id: 12071, s: "000919.SZ", n: "Jinling Pharmaceutical", c: "CH" },
  { id: 12072, s: "CCM.TO", n: "Canagold Resources", c: "CA" },
  { id: 12073, s: "CEEB3.SA", n: "Companhiade Eletricidade do Estado da Bahia - COELBA", c: "Brazil" },
  { id: 12074, s: "POLY.TA", n: "Polygon-L", c: "Israel" },
  { id: 12075, s: "ECCX", n: "Eagle Point Credit Company Inc", c: "US" },
  { id: 12076, s: "LSR.AX", n: "Lodestar Minerals", c: "Australia" },
  { id: 12077, s: "003850.KS", n: "Boryung Pharm", c: "Korea" },
  { id: 12078, s: "MMG.V", n: "Metallic Minerals Co", c: "CA" },
  { id: 12079, s: "LOGN3.SA", n: "Log-In Logística Intermodal S.A", c: "Brazil" },
  { id: 12080, s: "LKO.AX", n: "Lakes Oil NL", c: "Australia" },
  { id: 12081, s: "KCDMY", n: "Kimberly-Clark de Mexi", c: "US" },
  { id: 12082, s: "FODELIA.HE", n: "Fodelia", c: "Finland" },
  { id: 12083, s: "000421.SZ", n: "Nanjing Zhongbei Group", c: "CH" },
  { id: 12084, s: "DETEC.HE", n: "Detection Technology OY", c: "Finland" },
  { id: 12085, s: "IBC.AX", n: "Ironbark Capital", c: "Australia" },
  { id: 12086, s: "TD-PFB.TO", n: "Toronto Dominion Bank Pref B", c: "CA" },
  { id: 12087, s: "TERRNT-B.ST", n: "Terranet AB", c: "Sweden" },
  { id: 12088, s: "PRN.AX", n: "Perenti Limited", c: "Australia" },
  { id: 12089, s: "TETY.ST", n: "Tethys Oil AB", c: "Sweden" },
  { id: 12090, s: "DOV1V.HE", n: "Dovre Group Plc", c: "Finland" },
  { id: 12091, s: "SXG.AX", n: "Southern Cross Gold", c: "Australia" },
  { id: 12092, s: "HEMO.L", n: "Hemogenyx Pharmaceuticals PLC", c: "UK" },
  { id: 12093, s: "000157.SZ", n: "Zoomlion Heavy Industry Science and Technology", c: "CH" },
  { id: 12094, s: "BST.DE", n: "Bastei Lübbe AG", c: "GER" },
  { id: 12095, s: "MAQC", n: "Maquia Capital Acquisition Co", c: "US" },
  { id: 12096, s: "PRS.AX", n: "Prospech", c: "Australia" },
  { id: 12097, s: "HTG.AX", n: "Harvest Technology Group", c: "Australia" },
  { id: 12098, s: "HIO.AX", n: "Hawsons Iron", c: "Australia" },
  { id: 12099, s: "GATE", n: "Marblegate Acquisition Co", c: "US" },
  { id: 12100, s: "BOOT.L", n: "Henry Boot PLC", c: "UK" },
  { id: 12101, s: "000159.SZ", n: "Xinjiang International Industry", c: "CH" },
  { id: 12102, s: "000657.SZ", n: "CH Tungsten and Hightech Materials", c: "CH" },
  { id: 12103, s: "SNG.AX", n: "Siren Gold", c: "Australia" },
  { id: 12104, s: "SMN.AX", n: "Structural Monitoring Systems PLC", c: "Australia" },
  { id: 12105, s: "BEN.L", n: "Bens Creek Group PLC", c: "UK" },
  { id: 12106, s: "000536.SZ", n: "CPT Technology Group", c: "CH" },
  { id: 12107, s: "000539.SZ", n: "Guangdong Electric Power Development", c: "CH" },
  { id: 12108, s: "000601.SZ", n: "Guangdong Shaoneng Group", c: "CH" },
  { id: 12109, s: "EZL.AX", n: "Euroz", c: "Australia" },
  { id: 12110, s: "ETEK", n: "Eco-Tek Group Inc.", c: "US" },
  { id: 12111, s: "ICG.AX", n: "Inca Minerals", c: "Australia" },
  { id: 12112, s: "CSH.DE", n: "CENIT Aktiengesellschaft", c: "GER" },
  { id: 12113, s: "000666.SZ", n: "Jingwei Textile Machinery", c: "CH" },
  { id: 12114, s: "000088.SZ", n: "Shenzhen Yan Tian Port Holdings", c: "CH" },
  { id: 12115, s: "068050.KQ", n: "Pan Entertainment Co.", c: "Korea" },
  { id: 12116, s: "AAG.DE", n: "Aumann AG", c: "GER" },
  { id: 12117, s: "EBR.AX", n: "Ebr Systems Inc CDR", c: "Australia" },
  { id: 12118, s: "GHE.L", n: "Gresham House PLC", c: "UK" },
  { id: 12119, s: "ECF.AX", n: "Elanor Commercial Property Fund", c: "Australia" },
  { id: 12120, s: "HFR.AX", n: "Highfield Resources", c: "Australia" },
  { id: 12121, s: "EDE.AX", n: "Eden Energy", c: "Australia" },
  { id: 12122, s: "000633.SZ", n: "Xinjiang Hejin Holding", c: "CH" },
  { id: 12123, s: "GDR.L", n: "genedrive plc", c: "UK" },
  { id: 12124, s: "LRGR", n: "Luminar Media Group Inc", c: "US" },
  { id: 12125, s: "TRP-PF.TO", n: "TC Energy Corp Pref Series 2", c: "CA" },
  { id: 12126, s: "QPM.AX", n: "Queensland Pacific Metals", c: "Australia" },
  { id: 12127, s: "BRYAT.IS", n: "Borusan Yatirim ve Pazarlama AS", c: "Turkey" },
  { id: 12128, s: "GERS", n: "Greenshift Co", c: "US" },
  { id: 12129, s: "EIQ.AX", n: "ECHOIQ LIMITED", c: "Australia" },
  { id: 12130, s: "ALBAV.HE", n: "Alandsbanken Abp A", c: "Finland" },
  { id: 12131, s: "HCFT.L", n: "Highcroft Investments PLC REIT", c: "UK" },
  { id: 12132, s: "000503.SZ", n: "CH Reform Health Management and Services Group", c: "CH" },
  { id: 12133, s: "BOXE.L", n: "Tritax EuroBox PLC GBp", c: "UK" },
  { id: 12134, s: "KATMR.IS", n: "Katmerciler Arac Ustu Ekipman Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 12135, s: "TDO.AX", n: "3D Oil", c: "Australia" },
  { id: 12136, s: "KELAS.HE", n: "Kesla Oyj A", c: "Finland" },
  { id: 12137, s: "000078.SZ", n: "Shenzhen Neptunus Bioengineering", c: "CH" },
  { id: 12138, s: "000672.SZ", n: "Gansu Shangfeng Cement", c: "CH" },
  { id: 12139, s: "TECT.V", n: "Tectonic Metals Inc", c: "CA" },
  { id: 12140, s: "PLCR.TA", n: "Plasto-Cargal Group", c: "Israel" },
  { id: 12141, s: "000933.SZ", n: "Henan Shenhuo Coal & Power", c: "CH" },
  { id: 12142, s: "PLRM.TA", n: "Palram", c: "Israel" },
  { id: 12143, s: "MED.DE", n: "MEDICLIN Aktiengesellschaft", c: "GER" },
  { id: 12144, s: "GCL.TO", n: "Colabor Group Inc.", c: "CA" },
  { id: 12145, s: "4C.ST", n: "4C Group AB", c: "Sweden" },
  { id: 12146, s: "M7U.DE", n: "Nynomic AG", c: "GER" },
  { id: 12147, s: "APZ.AX", n: "Aspen Group Unit", c: "Australia" },
  { id: 12148, s: "WIN.AX", n: "Widgie Nickel", c: "Australia" },
  { id: 12149, s: "9908.TW", n: "Great Taipei Gas Co", c: "TW" },
  { id: 12150, s: "LEG.AX", n: "Legend Mining", c: "Australia" },
  { id: 12151, s: "GDV-PA.TO", n: "Global Dividend Growth Split Co", c: "CA" },
  { id: 12152, s: "PIRI.L", n: "Pires Investments PLC", c: "UK" },
  { id: 12153, s: "AQI.AX", n: "Alicanto Minerals", c: "Australia" },
  { id: 12154, s: "BMO-PW.TO", n: "Bank of Montreal Pref W", c: "CA" },
  { id: 12155, s: "000837.SZ", n: "Qinchuan Machine Tool & Tool Group Share", c: "CH" },
  { id: 12156, s: "ZLD.AX", n: "Zelira Therapeutics", c: "Australia" },
  { id: 12157, s: "MM1.AX", n: "Midas Minerals", c: "Australia" },
  { id: 12158, s: "MLVN.L", n: "Malvern International", c: "UK" },
  { id: 12159, s: "ARE.AX", n: "Argonaut Resources N L", c: "Australia" },
  { id: 12160, s: "FPIP.ST", n: "FormPipe Software AB", c: "Sweden" },
  { id: 12161, s: "000880.KS", n: "Hanwha", c: "Korea" },
  { id: 12162, s: "LODZ.TA", n: "Lodzia", c: "Israel" },
  { id: 12163, s: "6214.TW", n: "Systex Co", c: "TW" },
  { id: 12164, s: "YEOTK.IS", n: "Yeo Teknoloji Enerji ve Endustri AS", c: "Turkey" },
  { id: 12165, s: "LEOF.TA", n: "Levinski Ofer", c: "Israel" },
  { id: 12166, s: "WEGZY", n: "WEG SA ADR", c: "US" },
  { id: 12167, s: "032190.KQ", n: "Daou Data Co", c: "Korea" },
  { id: 12168, s: "ASXFY", n: "ASX Limited ADR", c: "US" },
  { id: 12169, s: "MKG.AX", n: "Mako Gold", c: "Australia" },
  { id: 12170, s: "001140.KS", n: "Kukbo Trans", c: "Korea" },
  { id: 12171, s: "PFIZ34.SA", n: "Pfizer Inc", c: "Brazil" },
  { id: 12172, s: "000969.SZ", n: "Advanced Technology & Materials", c: "CH" },
  { id: 12173, s: "WNR.AX", n: "Wingara AG", c: "Australia" },
  { id: 12174, s: "EPWN.L", n: "Epwin Group PLC", c: "UK" },
  { id: 12175, s: "8464.TW", n: "Nien Made Enterprise", c: "TW" },
  { id: 12176, s: "000856.SZ", n: "Tangshan Jidong Equipment Engineering", c: "CH" },
  { id: 12177, s: "EWRK.ST", n: "eWork Group AB", c: "Sweden" },
  { id: 12178, s: "6230.TW", n: "Chaun-Choung Technology Co", c: "TW" },
  { id: 12179, s: "LGM.AX", n: "Legacy Minerals Holdings", c: "Australia" },
  { id: 12180, s: "MTRX.TA", n: "Matrix", c: "Israel" },
  { id: 12181, s: "AAIGF", n: "AIA Group", c: "US" },
  { id: 12182, s: "8422.TW", n: "Cleanaway", c: "TW" },
  { id: 12183, s: "MRCH.L", n: "Merchants Trust PLC", c: "UK" },
  { id: 12184, s: "6243.TW", n: "Ene Technology Inc", c: "TW" },
  { id: 12185, s: "MSHR.TA", n: "Mishorim Real Estate Investments", c: "Israel" },
  { id: 12186, s: "EPRX.TO", n: "Eupraxia Pharmaceuticals Inc", c: "CA" },
  { id: 12187, s: "002146.SZ", n: "Risesun Real Estate Development", c: "CH" },
  { id: 12188, s: "UNIT.TA", n: "Unitronics", c: "Israel" },
  { id: 12189, s: "MTX.V", n: "Metalex Ventures.", c: "CA" },
  { id: 12190, s: "000850.SZ", n: "Anhui Huamao Textile", c: "CH" },
  { id: 12191, s: "ISRS.TA", n: "Isras Investment Company", c: "Israel" },
  { id: 12192, s: "MBK.DE", n: "Merkur PrivatBank KgaA", c: "GER" },
  { id: 12193, s: "BTC.AX", n: "BTC Health Limited", c: "Australia" },
  { id: 12194, s: "ACAD.ST", n: "AcadeMedia AB (publ)", c: "Sweden" },
  { id: 12195, s: "WSI.AX", n: "Weststar Industrial", c: "Australia" },
  { id: 12196, s: "HELIO.ST", n: "Heliospectra publ AB", c: "Sweden" },
  { id: 12197, s: "MWSNF", n: "Mawson Gold", c: "US" },
  { id: 12198, s: "WQG.AX", n: "WCM Global Growth", c: "Australia" },
  { id: 12199, s: "BPM.AX", n: "Bpm Minerals Ltd", c: "Australia" },
  { id: 12200, s: "MIDW.L", n: "Midwich Group PLC", c: "UK" },
  { id: 12201, s: "MATHIO.AT", n: "Mathios Refractories S.A.", c: "Greece" },
  { id: 12202, s: "YBOX.TA", n: "Ybox Real Estate", c: "Israel" },
  { id: 12203, s: "VCTR.TA", n: "Victory Supermarket Chain", c: "Israel" },
  { id: 12204, s: "WLAN", n: "Wialan Technologies", c: "US" },
  { id: 12205, s: "VRDS.TA", n: "Veridis Environment", c: "Israel" },
  { id: 12206, s: "WINV", n: "WinVest Acquisition Co", c: "US" },
  { id: 12207, s: "TRPZ.TA", n: "Turpaz Industries", c: "Israel" },
  { id: 12208, s: "XTLB.TA", n: "XTL Biopharmaceuticals", c: "Israel" },
  { id: 12209, s: "TTK.DE", n: "Takkt AG", c: "GER" },
  { id: 12210, s: "OIT.L", n: "Odyssean Investment Trust PLC", c: "UK" },
  { id: 12211, s: "AUQ.AX", n: "Alara Resources", c: "Australia" },
  { id: 12212, s: "TRLT.TA", n: "Tera Light", c: "Israel" },
  { id: 12213, s: "AUT.AX", n: "Auteco Minerals", c: "Australia" },
  { id: 12214, s: "EVEN3.SA", n: "Even Construtora e Incorporadora S.A", c: "Brazil" },
  { id: 12215, s: "AUZ.AX", n: "Australian Mines", c: "Australia" },
  { id: 12216, s: "000640.KS", n: "Donga Socio Holdings", c: "Korea" },
  { id: 12217, s: "AV1.AX", n: "Adveritas", c: "Australia" },
  { id: 12218, s: "ZMH.TA", n: "Z.M.H Hammerman", c: "Israel" },
  { id: 12219, s: "6582.TW", n: "Shin Foong Specialty and Applied Materials", c: "TW" },
  { id: 12220, s: "MAP.V", n: "Maple Peak Investments Inc", c: "CA" },
  { id: 12221, s: "TOEN.TA", n: "Tomer Energy Royalties 2012", c: "Israel" },
  { id: 12222, s: "THES.TA", n: "Thirdeye Systems", c: "Israel" },
  { id: 12223, s: "ZNKL.TA", n: "Zanlakol", c: "Israel" },
  { id: 12224, s: "SHMM.TA", n: "Shamaym Improve", c: "Israel" },
  { id: 12225, s: "8081.TW", n: "Global Mixed-Mode Technology Inc", c: "TW" },
  { id: 12226, s: "000861.SZ", n: "Guangdong Highsun Group", c: "CH" },
  { id: 12227, s: "000917.SZ", n: "Hunan TV & Broadcast Intermediary", c: "CH" },
  { id: 12228, s: "BAZA3.SA", n: "Banco da Amazônia S.A", c: "Brazil" },
  { id: 12229, s: "ATS.AX", n: "Australis Oil & Gas", c: "Australia" },
  { id: 12230, s: "NFG.L", n: "Next 15 Group PLC", c: "UK" },
  { id: 12231, s: "MDL.V", n: "Medallion Resources", c: "CA" },
  { id: 12232, s: "BACTI-B.ST", n: "Bactiguard Holding AB (publ)", c: "Sweden" },
  { id: 12233, s: "NAWI.TA", n: "Nawi Brothers Group", c: "Israel" },
  { id: 12234, s: "8215.TW", n: "BenQ Materials Co", c: "TW" },
  { id: 12235, s: "LGP.AX", n: "Little Green Pharma", c: "Australia" },
  { id: 12236, s: "PYT", n: "PPLUS Trust Series GSC 2 Pref", c: "US" },
  { id: 12237, s: "VCBDQ", n: "Vitalibis Inc", c: "US" },
  { id: 12238, s: "002046.SZ", n: "Sinomach Precision Industry", c: "CH" },
  { id: 12239, s: "TECT.TA", n: "Tectona", c: "Israel" },
  { id: 12240, s: "NOHO", n: "Novation Hldgs Inc.", c: "US" },
  { id: 12241, s: "AVR.AX", n: "Anteris Technologies", c: "Australia" },
  { id: 12242, s: "ITSA3.SA", n: "Itaúsa - Investimentos Itaú SA", c: "Brazil" },
  { id: 12243, s: "BURE.ST", n: "Bure Equity AB", c: "Sweden" },
  { id: 12244, s: "OTMP.L", n: "OnTheMarket plc", c: "UK" },
  { id: 12245, s: "NWF.AX", n: "Newfield Resources", c: "Australia" },
  { id: 12246, s: "DRDR.TO", n: "MCI Onehealth Technologies Inc", c: "CA" },
  { id: 12247, s: "ECRO", n: "Ecc Cap. Corp.", c: "US" },
  { id: 12248, s: "NTU.AX", n: "Northern Minerals", c: "Australia" },
  { id: 12249, s: "SPDV.TA", n: "Speedvalue", c: "Israel" },
  { id: 12250, s: "IATR.AT", n: "Athens Medical C.S.A.", c: "Greece" },
  { id: 12251, s: "000890.SZ", n: "Jiangsu Fasten", c: "CH" },
  { id: 12252, s: "ENJ", n: "Entergy New Orleans Inc Pref", c: "US" },
  { id: 12253, s: "LKY.V", n: "Lucky Minerals Inc", c: "CA" },
  { id: 12254, s: "CRFB3.SA", n: "Atacadão S.A", c: "Brazil" },
  { id: 12255, s: "000889.SZ", n: "ZJBC Information Technology", c: "CH" },
  { id: 12256, s: "ASCK", n: "Auscrete Co", c: "US" },
  { id: 12257, s: "SHNP.TA", n: "Schnapp", c: "Israel" },
  { id: 12258, s: "000544.SZ", n: "Central Plains Environment Protection", c: "CH" },
  { id: 12259, s: "RHI.AX", n: "Red Hill Iron", c: "Australia" },
  { id: 12260, s: "090350.KS", n: "Noroo Paint", c: "Korea" },
  { id: 12261, s: "012630.KS", n: "HDC Holdings", c: "Korea" },
  { id: 12262, s: "2489.TW", n: "Amtran Technology", c: "TW" },
  { id: 12263, s: "069960.KS", n: "Hyundai Department", c: "Korea" },
  { id: 12264, s: "TFI.PA", n: "Television Francaise 1 SA", c: "FR" },
  { id: 12265, s: "2375.TW", n: "Kaimei Electronic Co", c: "TW" },
  { id: 12266, s: "084680.KS", n: "E-World", c: "Korea" },
  { id: 12267, s: "018670.KS", n: "Sk Gas", c: "Korea" },
  { id: 12268, s: "601933.SS", n: "Yonghui Superstores", c: "CH" },
  { id: 12269, s: "020000.KS", n: "Handsome", c: "Korea" },
  { id: 12270, s: "ALKEY.PA", n: "Keyrus SA", c: "FR" },
  { id: 12271, s: "ILM1.F", n: "Medios AG", c: "GER" },
  { id: 12272, s: "1312.TW", n: "Grand Pacific Petrochemical Co", c: "TW" },
  { id: 12273, s: "ALB.MC", n: "Corporacion Financiera Alba SA", c: "Spain" },
  { id: 12274, s: "600026.SS", n: "COSCO Shipping Energy Transportation", c: "CH" },
  { id: 12275, s: "002674.SZ", n: "Xingye Leather Technology", c: "CH" },
  { id: 12276, s: "023530.KS", n: "Lotte Shopping", c: "Korea" },
  { id: 12277, s: "VMX.PA", n: "Verimatrix", c: "FR" },
  { id: 12278, s: "002631.SZ", n: "Der International Home Furnishing", c: "CH" },
  { id: 12279, s: "2501.TW", n: "Cathay Real Estate Development", c: "TW" },
  { id: 12280, s: "EUK3.F", n: "EUROKAI GmbH & Co. KGaA", c: "GER" },
  { id: 12281, s: "601969.SS", n: "Hainan Mining", c: "CH" },
  { id: 12282, s: "WEB.BR", n: "Warehouses Estates Belgium SCA", c: "Belgium" },
  { id: 12283, s: "3152.TWO", n: "Advanced Ceramic X", c: "TW" },
  { id: 12284, s: "600298.SS", n: "Angel Yeast", c: "CH" },
  { id: 12285, s: "2812.TW", n: "Taichung Commercial Bank", c: "TW" },
  { id: 12286, s: "300389.SZ", n: "Shenzhen Absen Optoelectronic", c: "CH" },
  { id: 12287, s: "600039.SS", n: "Sichuan Road & Bridge Group", c: "CH" },
  { id: 12288, s: "2486.TW", n: "I-Chiun Precision Industry", c: "TW" },
  { id: 12289, s: "600015.SS", n: "Hua Xia Bank", c: "CH" },
  { id: 12290, s: "BOWFF", n: "Boardwalk Real Estate Investment Trust", c: "US" },
  { id: 12291, s: "2355.TW", n: "Chin-Poon Industrial", c: "TW" },
  { id: 12292, s: "300466.SZ", n: "Saimo Electric", c: "CH" },
  { id: 12293, s: "2614.TW", n: "Eastern Media International Co", c: "TW" },
  { id: 12294, s: "MNV6.F", n: "Mainova AG", c: "GER" },
  { id: 12295, s: "018260.KS", n: "Samsung SDS", c: "Korea" },
  { id: 12296, s: "300557.SZ", n: "Wuhan Ligong Guangke", c: "CH" },
  { id: 12297, s: "603279.SS", n: "Jingjin Environmental Protection", c: "CH" },
  { id: 12298, s: "AUMB.V", n: "1911 Gold Co", c: "CA" },
  { id: 12299, s: "SHMN", n: "Sohm Inc.", c: "US" },
  { id: 12300, s: "300727.SZ", n: "Ningbo Runhe High-Tech Materials Co Class A", c: "CH" },
  { id: 12301, s: "603233.SS", n: "Dashenlin Pharm Grp", c: "CH" },
  { id: 12302, s: "6261.TWO", n: "Youngtek Electronics", c: "TW" },
  { id: 12303, s: "603050.SS", n: "Shijiazhuang Kelin Elec", c: "CH" },
  { id: 12304, s: "300713.SZ", n: "Shenzhen Increase Technology Co Class A", c: "CH" },
  { id: 12305, s: "600557.SS", n: "Jiangsu Kanion Pharmaceutical", c: "CH" },
  { id: 12306, s: "2457.TW", n: "Phihong Technology", c: "TW" },
  { id: 12307, s: "CMWAY", n: "Commonwealth Bank of Australia PK", c: "US" },
  { id: 12308, s: "HG1.F", n: "HOMAG Group AG", c: "GER" },
  { id: 12309, s: "603019.SS", n: "Dawning Information Industry", c: "CH" },
  { id: 12310, s: "BLM.V", n: "BluMetric Environmental Inc", c: "CA" },
  { id: 12311, s: "603058.SS", n: "Yongji Printing", c: "CH" },
  { id: 12312, s: "600570.SS", n: "Hundsun Technologies Inc", c: "CH" },
  { id: 12313, s: "2441.TW", n: "Greatek Electronics Inc", c: "TW" },
  { id: 12314, s: "BCU.V", n: "Bell Copper Co", c: "CA" },
  { id: 12315, s: "AGI.AX", n: "Ainsworth Game Technology", c: "Australia" },
  { id: 12316, s: "EMM.V", n: "Giyani Metals Co", c: "CA" },
  { id: 12317, s: "ABS.WA", n: "Asseco Business Solutions S.A.", c: "Poland" },
  { id: 12318, s: "HUD.V", n: "Hudson Resources Inc.", c: "CA" },
  { id: 12319, s: "RPC.WA", n: "Zaklady Magnezytowe Ropczyce S.A.", c: "Poland" },
  { id: 12320, s: "ONE.SW", n: "One Swiss Bank SA", c: "Switzerland" },
  { id: 12321, s: "3356.TW", n: "GeoVision Inc", c: "TW" },
  { id: 12322, s: "EXE.PA", n: "Exel Industries", c: "FR" },
  { id: 12323, s: "ETF.V", n: "Eastfield Resources", c: "CA" },
  { id: 12324, s: "600985.SS", n: "Huaibei Mining Holdings", c: "CH" },
  { id: 12325, s: "300195.SZ", n: "Masterwork Machinery", c: "CH" },
  { id: 12326, s: "007575.KS", n: "Ilyang Pharmaceutical", c: "Korea" },
  { id: 12327, s: "1789.TW", n: "ScinoPharm TW", c: "TW" },
  { id: 12328, s: "SES.WA", n: "Sescom SA", c: "Poland" },
  { id: 12329, s: "DLCR", n: "Kibush Capital Co", c: "US" },
  { id: 12330, s: "4541.TWO", n: "Magnate Technology", c: "TW" },
  { id: 12331, s: "SBES", n: "South Beach Spirits Inc", c: "US" },
  { id: 12332, s: "002410.SZ", n: "Glodon Software", c: "CH" },
  { id: 12333, s: "1409.TW", n: "Shinkong Synthetic Fiber Co", c: "TW" },
  { id: 12334, s: "3016.TW", n: "Episil-Precision Inc", c: "TW" },
  { id: 12335, s: "600167.SS", n: "Luenmei Quantum", c: "CH" },
  { id: 12336, s: "3707.TWO", n: "Episil Holding", c: "TW" },
  { id: 12337, s: "002542.SZ", n: "CH Zhonghua Geotechnical Engineering", c: "CH" },
  { id: 12338, s: "HAN.V", n: "Hannan Metals", c: "CA" },
  { id: 12339, s: "002556.SZ", n: "Anhui Huilong Agricultural Means of Production", c: "CH" },
  { id: 12340, s: "3691.TWO", n: "Gigasolar Materials", c: "TW" },
  { id: 12341, s: "XPS.L", n: "XPS Pensions Group PLC", c: "UK" },
  { id: 12342, s: "DIA.V", n: "Margaret Lake Diamonds Inc", c: "CA" },
  { id: 12343, s: "AJX.AX", n: "Alexium International Group", c: "Australia" },
  { id: 12344, s: "DLPX", n: "Delphax Technologies Inc", c: "US" },
  { id: 12345, s: "ALAGP.PA", n: "Agripower FR Sa", c: "FR" },
  { id: 12346, s: "2027.TW", n: "Ta Chen Stainless Pipe", c: "TW" },
  { id: 12347, s: "008930.KS", n: "Hanmi Science", c: "Korea" },
  { id: 12348, s: "1909.TW", n: "Long Chen Paper", c: "TW" },
  { id: 12349, s: "IDIP.PA", n: "IDI S.C.A.", c: "FR" },
  { id: 12350, s: "002432.SZ", n: "Andon Health", c: "CH" },
  { id: 12351, s: "2006.TW", n: "Tung Ho Steel Enterprise Co", c: "TW" },
  { id: 12352, s: "TM17.L", n: "Team17", c: "UK" },
  { id: 12353, s: "601111.SS", n: "Air CH Class A", c: "CH" },
  { id: 12354, s: "3330.F", n: "DECHENG TECHNOL. INH O.N.", c: "GER" },
  { id: 12355, s: "PFH", n: "Prudential Financial Inc Preferred", c: "US" },
  { id: 12356, s: "EPIC.SW", n: "EPIC Suisse AG", c: "Switzerland" },
  { id: 12357, s: "ELMN.SW", n: "Elma Electronic AG", c: "Switzerland" },
  { id: 12358, s: "OTEC", n: "Oceantech Acquisitions I Co", c: "US" },
  { id: 12359, s: "6104.TWO", n: "Genesyslogic", c: "TW" },
  { id: 12360, s: "601169.SS", n: "Bank of Beijing", c: "CH" },
  { id: 12361, s: "TRI.L", n: "Trifast plc", c: "UK" },
  { id: 12362, s: "SNX.WA", n: "SUNEX S.A.", c: "Poland" },
  { id: 12363, s: "TRX.L", n: "Tissue Regenix Group Plc", c: "UK" },
  { id: 12364, s: "601077.SS", n: "Chongqing Rural Commercial Bank", c: "CH" },
  { id: 12365, s: "ABA.F", n: "ALBA SE", c: "GER" },
  { id: 12366, s: "3042.TW", n: "TXC Co", c: "TW" },
  { id: 12367, s: "1805.TW", n: "Better Life Group", c: "TW" },
  { id: 12368, s: "UN01.F", n: "Uniper SE", c: "GER" },
  { id: 12369, s: "SPEC.L", n: "Inspecs Group plc", c: "UK" },
  { id: 12370, s: "600926.SS", n: "Bank of Hangzhou", c: "CH" },
  { id: 12371, s: "LEG.F", n: "LEG Immobilien SE", c: "GER" },
  { id: 12372, s: "601788.SS", n: "Everbright Securities", c: "CH" },
  { id: 12373, s: "6148.TWO", n: "Azion Corporation", c: "TW" },
  { id: 12374, s: "601702.SS", n: "Shanghai Huafon Aluminium Co", c: "CH" },
  { id: 12375, s: "KNG.V", n: "Kingsmen Resources", c: "CA" },
  { id: 12376, s: "USNU", n: "US NeuroSurgical Holdings Inc", c: "US" },
  { id: 12377, s: "AMX.AX", n: "Aerometrex Ltd", c: "Australia" },
  { id: 12378, s: "SHG.L", n: "Shanta Gold Limited", c: "UK" },
  { id: 12379, s: "300024.SZ", n: "SIASUN Robot Automation", c: "CH" },
  { id: 12380, s: "600339.SS", n: "CH Petroleum Engineering Co", c: "CH" },
  { id: 12381, s: "1702.TW", n: "Namchow Chemical Industrial", c: "TW" },
  { id: 12382, s: "FLU.VI", n: "Flughafen Wien Aktiengesellschaft", c: "Austria" },
  { id: 12383, s: "1560.TW", n: "Kinik", c: "TW" },
  { id: 12384, s: "3704.TW", n: "Unizyx Holding Co", c: "TW" },
  { id: 12385, s: "002832.SZ", n: "BIEM.L .FDLKK Garment", c: "CH" },
  { id: 12386, s: "MEX.WA", n: "Mex Polska S.A.", c: "Poland" },
  { id: 12387, s: "GAL.V", n: "Galantas Gold Co", c: "CA" },
  { id: 12388, s: "IPO.WA", n: "Intersport Polska S.A.", c: "Poland" },
  { id: 12389, s: "GMA.V", n: "Geomega Resources Inc", c: "CA" },
  { id: 12390, s: "ALVU.PA", n: "Vente Unique.com", c: "FR" },
  { id: 12391, s: "600329.SS", n: "Tianjin Zhongxin Pharmaceutical Group Co", c: "CH" },
  { id: 12392, s: "TR9.F", n: "OTRS AG", c: "GER" },
  { id: 12393, s: "1710.TW", n: "Oriental Union Chemical Co", c: "TW" },
  { id: 12394, s: "TDCB", n: "Third Century BanCo", c: "US" },
  { id: 12395, s: "NXFIL.AS", n: "NX Filtration Holding BV", c: "Netherlands" },
  { id: 12396, s: "600880.SS", n: "Chengdu B-ray Media", c: "CH" },
  { id: 12397, s: "SBSI.L", n: "Schroder BSC Social Impact Trust PLC", c: "UK" },
  { id: 12398, s: "PMBY", n: "Postd Merchant Banque", c: "US" },
  { id: 12399, s: "5508.TWO", n: "Yungshin Construction & Development", c: "TW" },
  { id: 12400, s: "RCSL3.SA", n: "Recrusul S/A", c: "Brazil" },
  { id: 12401, s: "PCR.WA", n: "PCC Rokita SA", c: "Poland" },
  { id: 12402, s: "688561.SS", n: "Qi An Xin Technology Group Inc", c: "CH" },
  { id: 12403, s: "3576.TW", n: "United Renewable Energy", c: "TW" },
  { id: 12404, s: "000547.SZ", n: "Addsino", c: "CH" },
  { id: 12405, s: "DNPLY", n: "Dai Nippon Printing Co ADR", c: "US" },
  { id: 12406, s: "POLR.L", n: "Polar Capital Holdings plc", c: "UK" },
  { id: 12407, s: "PMP.L", n: "Portmeirion Group PLC", c: "UK" },
  { id: 12408, s: "005880.KS", n: "Korea Line", c: "Korea" },
  { id: 12409, s: "6112.TW", n: "Sysage Technology", c: "TW" },
  { id: 12410, s: "6005.TW", n: "Capital Securities Co", c: "TW" },
  { id: 12411, s: "SRT.F", n: "Sartorius Aktiengesellschaft", c: "GER" },
  { id: 12412, s: "6116.TW", n: "Hannstar Display Co", c: "TW" },
  { id: 12413, s: "600741.SS", n: "HUAYU Automotive Systems", c: "CH" },
  { id: 12414, s: "1503.TW", n: "Shihlin Electric & Engineering Co", c: "TW" },
  { id: 12415, s: "600422.SS", n: "KPC Pharmaceuticals Inc", c: "CH" },
  { id: 12416, s: "5469.TW", n: "HannStar Board Co", c: "TW" },
  { id: 12417, s: "3583.TW", n: "Scientech Co", c: "TW" },
  { id: 12418, s: "600705.SS", n: "AVIC Capital", c: "CH" },
  { id: 12419, s: "200869.SZ", n: "Yantai Changyu Pioneer Wine", c: "CH" },
  { id: 12420, s: "SCAR3.SA", n: "São Carlos Empreendimentos e Participações S.A", c: "Brazil" },
  { id: 12421, s: "1712.TW", n: "Sinon Co", c: "TW" },
  { id: 12422, s: "4438.TW", n: "Quang Viet Enterprise", c: "TW" },
  { id: 12423, s: "600353.SS", n: "Chengdu Xuguang Electronics", c: "CH" },
  { id: 12424, s: "KRU.WA", n: "Kruk SA", c: "Poland" },
  { id: 12425, s: "COH.PA", n: "Coheris SA", c: "FR" },
  { id: 12426, s: "200625.SZ", n: "Chongqing Changan Automobile", c: "CH" },
  { id: 12427, s: "DNAX", n: "DNA Brands Inc", c: "US" },
  { id: 12428, s: "TMV.F", n: "TeamViewer AG", c: "GER" },
  { id: 12429, s: "PSRU", n: "Valiant Eagle Inc", c: "US" },
  { id: 12430, s: "PRES.L", n: "Pressure Technologies Plc", c: "UK" },
  { id: 12431, s: "2323.TW", n: "CMC Magnetics Co", c: "TW" },
  { id: 12432, s: "603585.SS", n: "Suli", c: "CH" },
  { id: 12433, s: "600537.SS", n: "EGing Photovoltaic Technology", c: "CH" },
  { id: 12434, s: "603505.SS", n: "CH Kings Resources Group", c: "CH" },
  { id: 12435, s: "300529.SZ", n: "Jafron Biomedical", c: "CH" },
  { id: 12436, s: "6411.TWO", n: "Amazing Microelectronic", c: "TW" },
  { id: 12437, s: "SKHHY", n: "Sonic Healthcare ADR", c: "US" },
  { id: 12438, s: "603662.SS", n: "Keli Sensing Technology Ningbo", c: "CH" },
  { id: 12439, s: "GAM.MC", n: "General de Alquiler de Maquinaria SA", c: "Spain" },
  { id: 12440, s: "TNTRQ", n: "Tintri Inc", c: "US" },
  { id: 12441, s: "204320.KS", n: "Mando Co", c: "Korea" },
  { id: 12442, s: "U2K.WA", n: "Unima 2000 Systemy Teleinformatyczne SA", c: "Poland" },
  { id: 12443, s: "MDF.MC", n: "Duro Felguera", c: "Spain" },
  { id: 12444, s: "ZAP.WA", n: "Grupa Azoty Zaklady Azotowe Pulawy SA", c: "Poland" },
  { id: 12445, s: "ALDNE.PA", n: "DONTNOD Entertainment SA", c: "FR" },
  { id: 12446, s: "601636.SS", n: "Zhuzhou Kibing Group", c: "CH" },
  { id: 12447, s: "601666.SS", n: "Pingdingshan Tianan Coal Mining", c: "CH" },
  { id: 12448, s: "300418.SZ", n: "Beijing Kunlun Tech", c: "CH" },
  { id: 12449, s: "PIG.PA", n: "Haulotte Group SA", c: "FR" },
  { id: 12450, s: "LRE.MC", n: "Lar Espana Real Estate", c: "Spain" },
  { id: 12451, s: "143210.KS", n: "Hands Corporation", c: "Korea" },
  { id: 12452, s: "CDTI", n: "CDTi Advanced Materials Inc", c: "US" },
  { id: 12453, s: "UNT.WA", n: "Unimot S.A", c: "Poland" },
  { id: 12454, s: "PBSFY", n: "ProSiebenSat1 Media AG", c: "US" },
  { id: 12455, s: "002736.SZ", n: "Guosen Securities", c: "CH" },
  { id: 12456, s: "EBK.F", n: "EnBW Energie Baden-Württemberg AG", c: "GER" },
  { id: 12457, s: "DOM.MC", n: "Global Dominion Access S.A", c: "Spain" },
  { id: 12458, s: "AOF.AX", n: "Australian Unity Office Fund", c: "Australia" },
  { id: 12459, s: "603040.SS", n: "Hangzhou XZB Tech", c: "CH" },
  { id: 12460, s: "5009.TWO", n: "Gloria Material Technology", c: "TW" },
  { id: 12461, s: "SXYAY", n: "Sika AG ADR", c: "US" },
  { id: 12462, s: "SGPS3.SA", n: "Springs Global Participações S.A", c: "Brazil" },
  { id: 12463, s: "SWA.F", n: "Schloss Wachenheim AG", c: "GER" },
  { id: 12464, s: "1707.TW", n: "Grape King Bio", c: "TW" },
  { id: 12465, s: "SCHO.L", n: "Scholium Group Plc", c: "UK" },
  { id: 12466, s: "5522.TW", n: "Farglory Land Development", c: "TW" },
  { id: 12467, s: "M0YN.MU", n: "Mynaric AG", c: "GER" },
  { id: 12468, s: "ILI.V", n: "Imagine Lithium Inc", c: "CA" },
  { id: 12469, s: "5471.TW", n: "Sonix Technology", c: "TW" },
  { id: 12470, s: "8415.TWO", n: "Brighton-Best International TW", c: "TW" },
  { id: 12471, s: "SIGN.SW", n: "SIG Combibloc Group AG", c: "Switzerland" },
  { id: 12472, s: "2548.TW", n: "Huaku Development", c: "TW" },
  { id: 12473, s: "DHER.F", n: "Delivery Hero SE", c: "GER" },
  { id: 12474, s: "2607.TW", n: "Evergreen International Storage & Transport Co", c: "TW" },
  { id: 12475, s: "2606.TW", n: "U-Ming Marine Transport Co", c: "TW" },
  { id: 12476, s: "KFR.V", n: "Kingfisher Metals Co", c: "CA" },
  { id: 12477, s: "300493.SZ", n: "Shanghai Fortune Techgroup", c: "CH" },
  { id: 12478, s: "KDSX.V", n: "Kadestone Capital Co", c: "CA" },
  { id: 12479, s: "ALEMV.PA", n: "Emova Group SA", c: "FR" },
  { id: 12480, s: "AEC.V", n: "Anfield Resources Inc", c: "CA" },
  { id: 12481, s: "2367.TW", n: "Unitech Printed Circuit Board Co", c: "TW" },
  { id: 12482, s: "603605.SS", n: "Proya Cosmetics Co Class A", c: "CH" },
  { id: 12483, s: "EBRO.MC", n: "Ebro Foods", c: "Spain" },
  { id: 12484, s: "603986.SS", n: "GigaDevice Semiconductor(Beiji", c: "CH" },
  { id: 12485, s: "2393.TW", n: "Everlight Electronics", c: "TW" },
  { id: 12486, s: "CTRYY", n: "Country Garden Holdings Company ADR", c: "US" },
  { id: 12487, s: "DCN.AX", n: "Dacian Gold", c: "Australia" },
  { id: 12488, s: "000753.SZ", n: "Fujian Zhangzhou Development", c: "CH" },
  { id: 12489, s: "BIOV.TA", n: "Bio-View", c: "Israel" },
  { id: 12490, s: "9940.TW", n: "Sinyi Realty Inc", c: "TW" },
  { id: 12491, s: "BKFR.TA", n: "Bait Bakfar", c: "Israel" },
  { id: 12492, s: "OM1.AX", n: "Omnia Metals Group", c: "Australia" },
  { id: 12493, s: "000767.SZ", n: "Shanxi Zhangze Electric Power", c: "CH" },
  { id: 12494, s: "MIDS.ST", n: "Midsummer AB (publ)", c: "Sweden" },
  { id: 12495, s: "000782.SZ", n: "Guangdong Xinhui Meida Nylon", c: "CH" },
  { id: 12496, s: "MEAB-B.ST", n: "Malmbergs Elektriska AB (publ)", c: "Sweden" },
  { id: 12497, s: "000016.SZ", n: "Konka Group", c: "CH" },
  { id: 12498, s: "CANF.TA", n: "Can Fite Biopharma", c: "Israel" },
  { id: 12499, s: "CAST.TA", n: "Castro", c: "Israel" },
  { id: 12500, s: "AGT.L", n: "AVI Global Trust PLC", c: "UK" },
  { id: 12501, s: "9939.TW", n: "Taiwan Hon Chuan Enterprise", c: "TW" },
  { id: 12502, s: "ZOMD.V", n: "Zoomd Technologies", c: "CA" },
  { id: 12503, s: "CMDR.TA", n: "Computer Direct", c: "Israel" },
  { id: 12504, s: "KSB.DE", n: "KSB SE & Co. KGaA", c: "GER" },
  { id: 12505, s: "CMER.TA", n: "C. Mer Industries", c: "Israel" },
  { id: 12506, s: "JDC.DE", n: "JDC Group AG", c: "GER" },
  { id: 12507, s: "000797.SZ", n: "CH Wuyi", c: "CH" },
  { id: 12508, s: "DANE.TA", n: "Danel (Adir Yeoshua)", c: "Israel" },
  { id: 12509, s: "LDG.L", n: "Logistics Development Group PLC", c: "UK" },
  { id: 12510, s: "DIMRI.TA", n: "Y.H. Dimri Construction & Development", c: "Israel" },
  { id: 12511, s: "000008.SZ", n: "CH High-Speed Railway Technology", c: "CH" },
  { id: 12512, s: "ECP.TA", n: "Electra Co Pr", c: "Israel" },
  { id: 12513, s: "MRGYO.IS", n: "Marti Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 12514, s: "URF.AX", n: "US Masters Residential Property Fund", c: "Australia" },
  { id: 12515, s: "000750.SZ", n: "Sealand Securities", c: "CH" },
  { id: 12516, s: "WC8.AX", n: "Wildcat Resources", c: "Australia" },
  { id: 12517, s: "044960.KQ", n: "Eagle Veterinary Technology Co.Ltd", c: "Korea" },
  { id: 12518, s: "WAR.AX", n: "Wam Strategic Value", c: "Australia" },
  { id: 12519, s: "CAA.AX", n: "Capral", c: "Australia" },
  { id: 12520, s: "GLTC.TA", n: "Galileo Tech", c: "Israel" },
  { id: 12521, s: "FLMP", n: "Flame Seal Products Inc", c: "US" },
  { id: 12522, s: "ZPAS", n: "Zoompass Holdings Inc", c: "US" },
  { id: 12523, s: "RGGI", n: "Resgreen Group International Inc", c: "US" },
  { id: 12524, s: "GRAC.TA", n: "Grace Breeding", c: "Israel" },
  { id: 12525, s: "OGD.TO", n: "Orbit Garant Drilling Inc.", c: "CA" },
  { id: 12526, s: "000815.SZ", n: "MCC Meili Paper Industry", c: "CH" },
  { id: 12527, s: "000818.SZ", n: "Hangjin Technology", c: "CH" },
  { id: 12528, s: "000100.KS", n: "Yuhan Corporation", c: "Korea" },
  { id: 12529, s: "MPARK.IS", n: "MLP Saglik Hizmetleri AS", c: "Turkey" },
  { id: 12530, s: "IDIN.TA", n: "IDI Insurance Company", c: "Israel" },
  { id: 12531, s: "ILDR.TA", n: "Israel Land Development Urban Renewal", c: "Israel" },
  { id: 12532, s: "MTRY", n: "Monterey Bio Acquisition Co", c: "US" },
  { id: 12533, s: "IMMU.ST", n: "Mendus AB (publ)", c: "Sweden" },
  { id: 12534, s: "LUCE.L", n: "Luceco plc", c: "UK" },
  { id: 12535, s: "FDIT", n: "Findit Inc", c: "US" },
  { id: 12536, s: "NBY.V", n: "Niobay Metals Inc", c: "CA" },
  { id: 12537, s: "CLU.AX", n: "Cluey Ltd", c: "Australia" },
  { id: 12538, s: "ELRN.TA", n: "Elron Electronic Industries", c: "Israel" },
  { id: 12539, s: "290270.KQ", n: "Hunesion Co.Ltd", c: "Korea" },
  { id: 12540, s: "NANEXA.ST", n: "Nanexa AB", c: "Sweden" },
  { id: 12541, s: "IBT-B.ST", n: "Infant Bacterial Therapeutics AB (publ)", c: "Sweden" },
  { id: 12542, s: "POD.AX", n: "Podium Minerals", c: "Australia" },
  { id: 12543, s: "KTEK.DE", n: "Katek Se", c: "GER" },
  { id: 12544, s: "FAN.L", n: "Volution Group plc", c: "UK" },
  { id: 12545, s: "GJO", n: "Strats SM Trust for Wal Mart Stores Inc Securities Series 2005-4 GIC", c: "US" },
  { id: 12546, s: "INDES.IS", n: "Indeks Bilgisayar Sistemleri Muhendislik Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 12547, s: "GMN.AX", n: "Gold Mountain", c: "Australia" },
  { id: 12548, s: "000537.SZ", n: "Tianjin Guangyu Development", c: "CH" },
  { id: 12549, s: "091990.KQ", n: "Celltrion Healthcare Co.", c: "Korea" },
  { id: 12550, s: "000563.SZ", n: "Shaanxi International Trust", c: "CH" },
  { id: 12551, s: "ELIMP.OL", n: "Elektroimportoren As", c: "Norway" },
  { id: 12552, s: "FXG.AX", n: "Felix Gold", c: "Australia" },
  { id: 12553, s: "BONHR.OL", n: "Bonheur", c: "Norway" },
  { id: 12554, s: "DIA.L", n: "Dialight plc", c: "UK" },
  { id: 12555, s: "KTH", n: "Structured Products Corp Corts Tr for Peco Energy Cap Tr III GIC", c: "US" },
  { id: 12556, s: "093640.KQ", n: "Tamul Multimedia Co.", c: "Korea" },
  { id: 12557, s: "000560.SZ", n: "5I5j Holding Group", c: "CH" },
  { id: 12558, s: "FME.AX", n: "Future Metals NL", c: "Australia" },
  { id: 12559, s: "CTO.L", n: "T Clarke PLC", c: "UK" },
  { id: 12560, s: "FLX.AX", n: "Felix Group Holdings", c: "Australia" },
  { id: 12561, s: "000558.SZ", n: "Lander Sports Development", c: "CH" },
  { id: 12562, s: "FG1.AX", n: "Flynn Gold", c: "Australia" },
  { id: 12563, s: "ECK.L", n: "Eckoh Plc", c: "UK" },
  { id: 12564, s: "FFF.AX", n: "Forbidden Foods Ltd", c: "Australia" },
  { id: 12565, s: "HDLY.OL", n: "Huddly AS", c: "Norway" },
  { id: 12566, s: "RML.AX", n: "Resolution Minerals", c: "Australia" },
  { id: 12567, s: "EKF.L", n: "EKF Diagnostics Holdings Plc", c: "UK" },
  { id: 12568, s: "100120.KQ", n: "Vieworks Co.", c: "Korea" },
  { id: 12569, s: "IWS.OL", n: "Integrated Wind Solutions AS", c: "Norway" },
  { id: 12570, s: "CAR.L", n: "Carclo plc", c: "UK" },
  { id: 12571, s: "000506.SZ", n: "Zhongrun Resources Investment", c: "CH" },
  { id: 12572, s: "CAU.L", n: "Centaur Media", c: "UK" },
  { id: 12573, s: "FNX.L", n: "Fonix Mobile plc", c: "UK" },
  { id: 12574, s: "SMD.V", n: "Strategic Metals", c: "CA" },
  { id: 12575, s: "GNP.AX", n: "Genusplus Group", c: "Australia" },
  { id: 12576, s: "GNX.AX", n: "Genex Power", c: "Australia" },
  { id: 12577, s: "000524.SZ", n: "Guangzhou Dongfang Hotel", c: "CH" },
  { id: 12578, s: "IEYHO.IS", n: "Isiklar Enerji ve Yapi Holding AS", c: "Turkey" },
  { id: 12579, s: "GJH", n: "Strats Trust US Cellular GIC", c: "US" },
  { id: 12580, s: "NACON.PA", n: "Nacon Sa", c: "FR" },
  { id: 12581, s: "SHJ.AX", n: "Shine Corporate", c: "Australia" },
  { id: 12582, s: "VRUS", n: "Verus International Inc", c: "US" },
  { id: 12583, s: "000518.SZ", n: "Jiangsu Sihuan Bioengineering", c: "CH" },
  { id: 12584, s: "DGGYO.IS", n: "Dogus Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 12585, s: "FOUR.L", n: "4Imprint Group Plc", c: "UK" },
  { id: 12586, s: "RISH.OL", n: "Gc Rieber Ship", c: "Norway" },
  { id: 12587, s: "LLESF", n: "Lend Lease Group", c: "US" },
  { id: 12588, s: "DEVA.IS", n: "Deva Holding AS", c: "Turkey" },
  { id: 12589, s: "SIV.AX", n: "Siv Capital", c: "Australia" },
  { id: 12590, s: "000514.SZ", n: "Chongqing Yukaifa", c: "CH" },
  { id: 12591, s: "RDN.AX", n: "Raiden Resources", c: "Australia" },
  { id: 12592, s: "USA.TO", n: "Americas Silver Co", c: "CA" },
  { id: 12593, s: "114190.KQ", n: "KANGWON Co.", c: "Korea" },
  { id: 12594, s: "AMAO", n: "American Acquisition Opportunity Inc. Class A Common Stock", c: "US" },
  { id: 12595, s: "SLA.AX", n: "Silk Laser Australia", c: "Australia" },
  { id: 12596, s: "GPOVY", n: "Grupo Carso SAB de CV ADR", c: "US" },
  { id: 12597, s: "ENT.AX", n: "Enterprise Metals", c: "Australia" },
  { id: 12598, s: "K2A-B.ST", n: "K2A Knaust & Andersson Fastigheter AB (publ)", c: "Sweden" },
  { id: 12599, s: "MOVINN.", n: "Movinn AS", c: "Denmark" },
  { id: 12600, s: "VYS.AX", n: "Vysarn", c: "Australia" },
  { id: 12601, s: "000030.SZ", n: "FAWER Automotive Parts", c: "CH" },
  { id: 12602, s: "2204.TW", n: "CH Motor Co", c: "TW" },
  { id: 12603, s: "302440.KS", n: "SK Bioscience", c: "Korea" },
  { id: 12604, s: "2905.TW", n: "Mercuries & Associates Holding", c: "TW" },
  { id: 12605, s: "300342.SZ", n: "Changshu Tianyin Electromechan", c: "CH" },
  { id: 12606, s: "BGMD", n: "BG Medicine Inc", c: "US" },
  { id: 12607, s: "010060.KS", n: "OCI", c: "Korea" },
  { id: 12608, s: "WTE.L", n: "Westmount Energy Limited", c: "UK" },
  { id: 12609, s: "PMI.L", n: "Premier Miton Group plc", c: "UK" },
  { id: 12610, s: "MTC.AX", n: "Metalstech", c: "Australia" },
  { id: 12611, s: "IVX.DE", n: "InVision Aktiengesellschaft", c: "GER" },
  { id: 12612, s: "FC9.DE", n: "FCR Immobilien AG", c: "GER" },
  { id: 12613, s: "CTE.AX", n: "Cryosite", c: "Australia" },
  { id: 12614, s: "000717.SZ", n: "Sgis Songshan", c: "CH" },
  { id: 12615, s: "MEDAF", n: "Medaro Mining Co", c: "US" },
  { id: 12616, s: "ANCR.L", n: "Animalcare Group Plc", c: "UK" },
  { id: 12617, s: "OOR.V", n: "Opus One Resources Inc", c: "CA" },
  { id: 12618, s: "PILA.ST", n: "Pila Pharma AB", c: "Sweden" },
  { id: 12619, s: "VXL.V", n: "Vaxil Bio", c: "CA" },
  { id: 12620, s: "000723.SZ", n: "Shanxi Meijin Energy", c: "CH" },
  { id: 12621, s: "JGGI.L", n: "JP Morgan Global Growth & Income PLC", c: "UK" },
  { id: 12622, s: "PCOM-B.ST", n: "Precomp Solutions AB Series B", c: "Sweden" },
  { id: 12623, s: "AILN.TA", n: "Axilion Smart Mobility", c: "Israel" },
  { id: 12624, s: "CR9.AX", n: "Corella Resources", c: "Australia" },
  { id: 12625, s: "214370.KQ", n: "Caregen Co.Ltd", c: "Korea" },
  { id: 12626, s: "MGAFF", n: "Mega Uranium.", c: "US" },
  { id: 12627, s: "6561.TWO", n: "Chief Telecom Inc", c: "TW" },
  { id: 12628, s: "CGUD", n: "Com-Guard.Com Inc", c: "US" },
  { id: 12629, s: "3003.TW", n: "KS Terminals Inc", c: "TW" },
  { id: 12630, s: "3014.TW", n: "ITE Tech Inc", c: "TW" },
  { id: 12631, s: "045100.KQ", n: "Hanyang ENG Co.", c: "Korea" },
  { id: 12632, s: "DHR.V", n: "Discovery Harbour Resources Co", c: "CA" },
  { id: 12633, s: "ZOO.L", n: "Zoo Digital Group Plc", c: "UK" },
  { id: 12634, s: "CTBK", n: "City Bank", c: "US" },
  { id: 12635, s: "A2B.AX", n: "A2B Australia", c: "Australia" },
  { id: 12636, s: "PRS.MC", n: "Promotora de Informaciones SA", c: "Spain" },
  { id: 12637, s: "DGTL.V", n: "DGTL Holdings Inc", c: "CA" },
  { id: 12638, s: "601567.SS", n: "Ningbo Sanxing Medical Electric", c: "CH" },
  { id: 12639, s: "CBK.F", n: "Commerzbank AG", c: "GER" },
  { id: 12640, s: "002863.SZ", n: "Zhejiang Jinfei Kaida Wheel", c: "CH" },
  { id: 12641, s: "XAR.L", n: "Xaar plc", c: "UK" },
  { id: 12642, s: "600131.SS", n: "State Grid Information&Communication", c: "CH" },
  { id: 12643, s: "4123.TWO", n: "Center Laboratories", c: "TW" },
  { id: 12644, s: "600618.SS", n: "Shanghai Chlor-Alkali Chemical Co A", c: "CH" },
  { id: 12645, s: "WIZC3.SA", n: "Wiz Soluções e Corretagem de Seguros S.A", c: "Brazil" },
  { id: 12646, s: "1434.TW", n: "Formosa Taffeta", c: "TW" },
  { id: 12647, s: "EBM.V", n: "Eastwood Bio-Medical CA Inc", c: "CA" },
  { id: 12648, s: "VAL.L", n: "ValiRx plc", c: "UK" },
  { id: 12649, s: "4147.TWO", n: "TaiMed Biologics", c: "TW" },
  { id: 12650, s: "600166.SS", n: "Beiqi Foton Motor", c: "CH" },
  { id: 12651, s: "LOUP.PA", n: "Societe LDC SA", c: "FR" },
  { id: 12652, s: "3006.TW", n: "Elite Semiconductor Memory Technology Inc", c: "TW" },
  { id: 12653, s: "326030.KS", n: "Sk Biopharmaceuticals ", c: "Korea" },
  { id: 12654, s: "DDEJF", n: "Dundee Co", c: "US" },
  { id: 12655, s: "JMI.L", n: "JPMorgan Smaller Companies Investment Trust Plc", c: "UK" },
  { id: 12656, s: "2103.TW", n: "TSRC Co", c: "TW" },
  { id: 12657, s: "NYTTO.ST", n: "Svenska Nyttobostader AB", c: "Sweden" },
  { id: 12658, s: "000708.SZ", n: "CITIC Pacific Special Steel Group", c: "CH" },
  { id: 12659, s: "000050.SZ", n: "Tianma Microelectronics", c: "CH" },
  { id: 12660, s: "CXM.AX", n: "Centrex Metals", c: "Australia" },
  { id: 12661, s: "000049.SZ", n: "Shenzhen Desay Battery Technology", c: "CH" },
  { id: 12662, s: "195990.KQ", n: "Ugint", c: "Korea" },
  { id: 12663, s: "CVW.AX", n: "Clearview Wealth", c: "Australia" },
  { id: 12664, s: "000705.SZ", n: "Zhejiang Zhenyuan Share", c: "CH" },
  { id: 12665, s: "SGR-U.TO", n: "Slate Grocery REIT", c: "CA" },
  { id: 12666, s: "PBX.V", n: "PowerBand Solutions Inc", c: "CA" },
  { id: 12667, s: "REDW.ST", n: "Redwood Pharma AB", c: "Sweden" },
  { id: 12668, s: "AGRIP", n: "AgriBank FCB", c: "US" },
  { id: 12669, s: "SIILI.HE", n: "Siili Solutions Oyj", c: "Finland" },
  { id: 12670, s: "QLOSR-B.ST", n: "Qlosr Group AB Series B", c: "Sweden" },
  { id: 12671, s: "IPEL.L", n: "Impellam Group Plc", c: "UK" },
  { id: 12672, s: "ASCI.L", n: "Aberdeen Smaller Companies Income Trust PLC", c: "UK" },
  { id: 12673, s: "PROGEN.ST", n: "Prostatype Genomics AB", c: "Sweden" },
  { id: 12674, s: "DCG.AX", n: "Decmil Group", c: "Australia" },
  { id: 12675, s: "AUDC.TA", n: "AudioCodes", c: "Israel" },
  { id: 12676, s: "PVS-PH.TO", n: "Partners Value Split Corp Pref Class AA", c: "CA" },
  { id: 12677, s: "MSADY", n: "MS&AD Insurance Group Holdings PK", c: "US" },
  { id: 12678, s: "ACRL.L", n: "Accrol Group Holdings PLC", c: "UK" },
  { id: 12679, s: "000810.SZ", n: "Skyworth Digital", c: "CH" },
  { id: 12680, s: "KTA.DE", n: "Knaus Tabbert AG", c: "GER" },
  { id: 12681, s: "GTK.DE", n: "Tonkens Agrar AG", c: "GER" },
  { id: 12682, s: "KAPIAB.ST", n: "Kalleback Property Invest AB", c: "Sweden" },
  { id: 12683, s: "IRON.L", n: "Ironveld Plc", c: "UK" },
  { id: 12684, s: "READ.ST", n: "Readly International AB", c: "Sweden" },
  { id: 12685, s: "CY5.AX", n: "Cygnus Gold", c: "Australia" },
  { id: 12686, s: "TTALO.HE", n: "Terveystalo Oy", c: "Finland" },
  { id: 12687, s: "CPN.AX", n: "Caspin Resources", c: "Australia" },
  { id: 12688, s: "NMAN.ST", n: "Nederman Holding AB", c: "Sweden" },
  { id: 12689, s: "SMC.TO", n: "Sulliden Minerals SA", c: "CA" },
  { id: 12690, s: "000727.SZ", n: "TPV Technology", c: "CH" },
  { id: 12691, s: "NZN.V", n: "Nevada Zinc Co", c: "CA" },
  { id: 12692, s: "000729.SZ", n: "Beijing Yanjing Brewery", c: "CH" },
  { id: 12693, s: "H5E.DE", n: "HELMA Eigenheimbau Aktiengesellschaft", c: "GER" },
  { id: 12694, s: "KAM.AX", n: "K2 Asset Management Holdings", c: "Australia" },
  { id: 12695, s: "000733.SZ", n: "CH Zhenhua Group Science & Technology", c: "CH" },
  { id: 12696, s: "AQX.L", n: "Aquis Exchange PLC", c: "UK" },
  { id: 12697, s: "NVI.V", n: "Novra Technologies Inc", c: "CA" },
  { id: 12698, s: "000040.SZ", n: "Tunghsu Azure Renewable Energy", c: "CH" },
  { id: 12699, s: "H9Y.DE", n: "HanseYachts AG", c: "GER" },
  { id: 12700, s: "KLKIM.IS", n: "Kalekim Kimyevi Maddeler Sanayi & Ticaret AS", c: "Turkey" },
  { id: 12701, s: "DLX.DE", n: "Delignit AG", c: "GER" },
  { id: 12702, s: "SWP.TO", n: "Swiss Water Decaffeinated Coffee Inc", c: "CA" },
  { id: 12703, s: "IGL.AX", n: "IVE Group", c: "Australia" },
  { id: 12704, s: "SCST.ST", n: "Scandi Standard publ AB", c: "Sweden" },
  { id: 12705, s: "DAF.AX", n: "Discovery Africa", c: "Australia" },
  { id: 12706, s: "OKDBV.HE", n: "Oriola-KD Oyj B", c: "Finland" },
  { id: 12707, s: "NXTMH.HE", n: "Nexstim Oyj", c: "Finland" },
  { id: 12708, s: "000060.SZ", n: "Shenzhen Zhongjin Lingnan Nonfemet", c: "CH" },
  { id: 12709, s: "SDOS.ST", n: "ScandiDos AB", c: "Sweden" },
  { id: 12710, s: "RTSL", n: "Rapid Therapeutic Science Lab inc", c: "US" },
  { id: 12711, s: "005385.KS", n: "Hyundai Motor Co.. Pfd. Series 1", c: "Korea" },
  { id: 12712, s: "5608.TW", n: "Shih Wei Navigation", c: "TW" },
  { id: 12713, s: "002192.SZ", n: "Youngy", c: "CH" },
  { id: 12714, s: "RENE.L", n: "ReNeuron Group plc", c: "UK" },
  { id: 12715, s: "BEVER.AS", n: "Bever Holding NV", c: "Netherlands" },
  { id: 12716, s: "4961.TW", n: "Fitipower Integrated Technology Inc", c: "TW" },
  { id: 12717, s: "AAC.ST", n: "AAC Clyde Space AB (publ)", c: "Sweden" },
  { id: 12718, s: "PRIM.L", n: "Primorus Investments plc", c: "UK" },
  { id: 12719, s: "PFP.L", n: "Pathfinder Minerals PLC", c: "UK" },
  { id: 12720, s: "ECV.DU", n: "Encavis AG", c: "GER" },
  { id: 12721, s: "PFRM3.SA", n: "Profarma Distribuidora de Produtos Farmacêuticos S.A", c: "Brazil" },
  { id: 12722, s: "REDX.L", n: "Redx Pharma Plc", c: "UK" },
  { id: 12723, s: "ACAST.ST", n: "Acast AB", c: "Sweden" },
  { id: 12724, s: "NSANF", n: "Nissan Motor Co..", c: "US" },
  { id: 12725, s: "002281.SZ", n: "Accelink Technologies", c: "CH" },
  { id: 12726, s: "002110.SZ", n: "Sansteel MinGuang Co Fujian", c: "CH" },
  { id: 12727, s: "ASG.AX", n: "Autosports Group", c: "Australia" },
  { id: 12728, s: "KS.V", n: "Klondike Silver Co", c: "CA" },
  { id: 12729, s: "000949.SZ", n: "Xinxiang Chemical Fiber", c: "CH" },
  { id: 12730, s: "000952.SZ", n: "Hubei Guangji Pharmaceutical", c: "CH" },
  { id: 12731, s: "RDT.L", n: "Rosslyn Data Technologies plc", c: "UK" },
  { id: 12732, s: "YOC.DE", n: "YOC AG", c: "GER" },
  { id: 12733, s: "YUNSA.IS", n: "Yunsa Yunlu Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 12734, s: "004105.KS", n: "Taeyang Metal Industrial", c: "Korea" },
  { id: 12735, s: "004170.KS", n: "Shinsegae", c: "Korea" },
  { id: 12736, s: "000935.SZ", n: "Sichuan Shuangma Cement", c: "CH" },
  { id: 12737, s: "SSEZY", n: "SSE PLC ADR", c: "US" },
  { id: 12738, s: "ORDI.AS", n: "Ordina NV", c: "Netherlands" },
  { id: 12739, s: "SBRE.L", n: "Sabre Insurance Group PLC", c: "UK" },
  { id: 12740, s: "035890.KQ", n: "Seohee Construction Co.", c: "Korea" },
  { id: 12741, s: "WFAFY", n: "Wesfarmers ADR", c: "US" },
  { id: 12742, s: "8390.TWO", n: "Jiin Yeeh Ding Enterprise", c: "TW" },
  { id: 12743, s: "AQX.AX", n: "Alice Queen", c: "Australia" },
  { id: 12744, s: "ULC.DE", n: "UNITEDLABELS Aktiengesellschaft", c: "GER" },
  { id: 12745, s: "LGC.V", n: "Lavras Gold Co", c: "CA" },
  { id: 12746, s: "600419.SS", n: "Xinjiang Tianrun Dairy", c: "CH" },
  { id: 12747, s: "PTCHF", n: "PureTech Health plc", c: "US" },
  { id: 12748, s: "DPUKY", n: "Domino’s Pizza Group PLC", c: "US" },
  { id: 12749, s: "600340.SS", n: "CH Fortune Land Development", c: "CH" },
  { id: 12750, s: "6416.TW", n: "Caswell Inc", c: "TW" },
  { id: 12751, s: "688518.SS", n: "Shenzhen United Winners Laser", c: "CH" },
  { id: 12752, s: "GOAU3.SA", n: "Metalurgica Gerdau S.A", c: "Brazil" },
  { id: 12753, s: "ASKH", n: "Astika Holdings", c: "US" },
  { id: 12754, s: "DNTUY", n: "Dentsu Inc ADR", c: "US" },
  { id: 12755, s: "600338.SS", n: "Tibet Summit Resources", c: "CH" },
  { id: 12756, s: "INX.V", n: "In-Touch Survey Systems.", c: "CA" },
  { id: 12757, s: "005950.KS", n: "Isu Chemical", c: "Korea" },
  { id: 12758, s: "OBS.VI", n: "Oberbank AG", c: "Austria" },
  { id: 12759, s: "4551.TW", n: "Global PMX", c: "TW" },
  { id: 12760, s: "8182.TWO", n: "Harmony Electronics", c: "TW" },
  { id: 12761, s: "B3.ST", n: "B3 Consulting Group AB", c: "Sweden" },
  { id: 12762, s: "688425.SS", n: "CH Railway Construction Heavy Industry Co", c: "CH" },
  { id: 12763, s: "OSI.L", n: "Osirium Technologies PLC", c: "UK" },
  { id: 12764, s: "NAG.AX", n: "Nagambie Mining", c: "Australia" },
  { id: 12765, s: "MAN.VI", n: "Josef Manner & Comp. AG", c: "Austria" },
  { id: 12766, s: "ASP.AX", n: "Aspermont", c: "Australia" },
  { id: 12767, s: "RGD.L", n: "Real Good Food", c: "UK" },
  { id: 12768, s: "000921.SZ", n: "Hisense Kelon Electrical Holdings", c: "CH" },
  { id: 12769, s: "QTX.L", n: "Quartix Holdings Plc", c: "UK" },
  { id: 12770, s: "ATWT", n: "ATWEC Technologies Inc", c: "US" },
  { id: 12771, s: "PXS.L", n: "Provexis plc", c: "UK" },
  { id: 12772, s: "ANX.AX", n: "Anax Metals", c: "Australia" },
  { id: 12773, s: "004920.KS", n: "CItech", c: "Korea" },
  { id: 12774, s: "ADDV-A.ST", n: "ADDvise Group AB A", c: "Sweden" },
  { id: 12775, s: "000925.SZ", n: "UniTTEC", c: "CH" },
  { id: 12776, s: "8016.TW", n: "Sitronix Technology Co", c: "TW" },
  { id: 12777, s: "EQFN", n: "Equitable Financial Co", c: "US" },
  { id: 12778, s: "NPPTF", n: "Neptune Digital Assets Co", c: "US" },
  { id: 12779, s: "002293.SZ", n: "Luolai Home Textile", c: "CH" },
  { id: 12780, s: "SYZ.DE", n: "SYZYGY AG", c: "GER" },
  { id: 12781, s: "FFH-PH.TO", n: "Fairfax Financial Holdings Pref H", c: "CA" },
  { id: 12782, s: "SCLP.L", n: "Scancell Hldgs Plc", c: "UK" },
  { id: 12783, s: "T5O.DE", n: "CytoTools AG", c: "GER" },
  { id: 12784, s: "DIMAND.AT", n: "Dimand S.A.", c: "Greece" },
  { id: 12785, s: "CFN.LS", n: "Cofina SGPS", c: "Portugal" },
  { id: 12786, s: "AUR.AX", n: "Auris Minerals", c: "Australia" },
  { id: 12787, s: "SCE.L", n: "Surface Transforms Plc", c: "UK" },
  { id: 12788, s: "SBTX.L", n: "SkinBioTherapeutics PLC", c: "UK" },
  { id: 12789, s: "TGT.DE", n: "11880 Solutions AG", c: "GER" },
  { id: 12790, s: "CENTR.AT", n: "Centric Holdings S.A", c: "Greece" },
  { id: 12791, s: "6552.TW", n: "JMC Electronics", c: "TW" },
  { id: 12792, s: "HLPPY", n: "Hang Lung Properties", c: "US" },
  { id: 12793, s: "DMF", n: "Bny Mellon Municipal Income Inc", c: "US" },
  { id: 12794, s: "6592.TW", n: "Hotai Finance Co", c: "TW" },
  { id: 12795, s: "BUSER.ST", n: "Bambuser AB", c: "Sweden" },
  { id: 12796, s: "000931.SZ", n: "Beijing Centergate Technologies Holding", c: "CH" },
  { id: 12797, s: "SAPGF", n: "SAP SE", c: "US" },
  { id: 12798, s: "NTOG.L", n: "Nostra Terra Oil and Gas Company PLC", c: "UK" },
  { id: 12799, s: "ETG.TO", n: "Entree Resources", c: "CA" },
  { id: 12800, s: "IUR.MU", n: "KAP AG", c: "GER" },
  { id: 12801, s: "ASEKY", n: "Aisin Seiki", c: "US" },
  { id: 12802, s: "AVJ.AX", n: "AVJennings Limited", c: "Australia" },
  { id: 12803, s: "NET.AX", n: "Netlinkz", c: "Australia" },
  { id: 12804, s: "3708.TW", n: "Swancor Holding", c: "TW" },
  { id: 12805, s: "SDV.L", n: "Chelverton UK Dividend Trust PLC", c: "UK" },
  { id: 12806, s: "MAX.V", n: "Max Resource Co", c: "CA" },
  { id: 12807, s: "VG8.DE", n: "Varengold Bank AG", c: "GER" },
  { id: 12808, s: "NPPNY", n: "Nippon Shinyaku Co ADR", c: "US" },
  { id: 12809, s: "SYT.DE", n: "Softing AG", c: "GER" },
  { id: 12810, s: "002840.KS", n: "Miwon Commerci", c: "Korea" },
  { id: 12811, s: "RTC.DE", n: "RealTech AG", c: "GER" },
  { id: 12812, s: "SOS.L", n: "Sosandar Plc", c: "UK" },
  { id: 12813, s: "SMS.L", n: "Smart Metering Systems plc", c: "UK" },
  { id: 12814, s: "002710.KS", n: "Tcc Steel", c: "Korea" },
  { id: 12815, s: "SMRT.L", n: "Smartspace Software plc", c: "UK" },
  { id: 12816, s: "INLOT.AT", n: "Intralot S.A. Integrated Lottery Systems and Services", c: "Greece" },
  { id: 12817, s: "SML.L", n: "Strategic Minerals Plc", c: "UK" },
  { id: 12818, s: "SF3.DE", n: "STS Group AG", c: "GER" },
  { id: 12819, s: "NSCI.L", n: "NetScientific plc", c: "UK" },
  { id: 12820, s: "002339.SZ", n: "Integrated Electronic Systems Lab", c: "CH" },
  { id: 12821, s: "PZOO", n: "Pazoo Inc", c: "US" },
  { id: 12822, s: "000929.SZ", n: "Lanzhou Huanghe Enterprise", c: "CH" },
  { id: 12823, s: "688208.SS", n: "Autel Intelligent Technology Co", c: "CH" },
  { id: 12824, s: "KEKR.AT", n: "Kekrops S.A.", c: "Greece" },
  { id: 12825, s: "013120.KQ", n: "DongWon Development Co.Ltd", c: "Korea" },
  { id: 12826, s: "000923.SZ", n: "HBIS Resources", c: "CH" },
  { id: 12827, s: "006800.KS", n: "Mirae Asset Daewoo Securities", c: "Korea" },
  { id: 12828, s: "SHOE.L", n: "Shoe Zone PLC", c: "UK" },
  { id: 12829, s: "BRAP3.SA", n: "Bradespar S.A", c: "Brazil" },
  { id: 12830, s: "CRED-A.ST", n: "Creades AB", c: "Sweden" },
  { id: 12831, s: "ERAO", n: "Energy Revenue Amer", c: "US" },

  { id: 12832, s: "SB1.MU", n: "Smartbroker Holding AG", c: "GER" },

  { id: 12833, s: "688202.SS", n: "Shanghai Medicilon Inc", c: "CH" },
  { id: 12834, s: "SNWS.L", n: "Smiths News PLC", c: "UK" },
  { id: 12835, s: "CFIVU", n: "CF Acquisition Corp IV", c: "US" },
  { id: 12836, s: "IFC-PI.TO", n: "Intact Financial Ser 9 Non cum Pref", c: "CA" },
  { id: 12837, s: "NBB.L", n: "Norman Broadbent Plc", c: "UK" },
  { id: 12838, s: "000922.SZ", n: "Harbin Electric Coporation Jiamusi Electric Machine", c: "CH" },
  { id: 12839, s: "CMOTEC-B.ST", n: "Scandinavian ChemoTech AB Series B", c: "Sweden" },
  { id: 12840, s: "CLS-B.ST", n: "Clinical Laserthermia Systems AB (publ)", c: "Sweden" },
  { id: 12841, s: "AX8.AX", n: "Accelerate Resources", c: "Australia" },
  { id: 12842, s: "CAT-B.ST", n: "Catella AB (publ)", c: "Sweden" },
  { id: 12843, s: "4916.TW", n: "Parpro Co", c: "TW" },
  { id: 12844, s: "000913.SZ", n: "Zhejiang Qianjiang Motorcycle", c: "CH" },
  { id: 12845, s: "000948.SZ", n: "Yunnan Nantian Electronics Information", c: "CH" },
  { id: 12846, s: "TNPV.TA", n: "Technoplus Ventures", c: "Israel" },
  { id: 12847, s: "001450.KS", n: "Hyundai Mar&Fi", c: "Korea" },
  { id: 12848, s: "002388.SZ", n: "Shenzhen Sunyes Electronic Manufacturing Holding", c: "CH" },
  { id: 12849, s: "UCAS3.SA", n: "Unicasa Indústria de Móveis S.A", c: "Brazil" },
  { id: 12850, s: "ENGCON-B.ST", n: "engcon AB Cl B", c: "Sweden" },
  { id: 12851, s: "MTVW.L", n: "Mountview Estates PLC", c: "UK" },
  { id: 12852, s: "001914.SZ", n: "CH Merchants Property Operation & Service", c: "CH" },
  { id: 12853, s: "000915.SZ", n: "Shandong Shanda Wit Science and Technology", c: "CH" },
  { id: 12854, s: "SUP.L", n: "Supreme PLC", c: "UK" },
  { id: 12855, s: "WDHR", n: "WeedHire International Inc", c: "US" },
  { id: 12856, s: "MOVE.SW", n: "Medacta Group SA", c: "Switzerland" },
  { id: 12857, s: "SUN.L", n: "Surgical Innovations Group plc", c: "UK" },
  { id: 12858, s: "MDN.DE", n: "Medion AG", c: "GER" },
  { id: 12859, s: "TSPOR.IS", n: "Trabzonspor Sportif Yatirim ve Futbol Isletmeciligi Ticaret AS", c: "Turkey" },
  { id: 12860, s: "000480.KS", n: "Chosun Refractories", c: "Korea" },
  { id: 12861, s: "WEB.L", n: "Webis Holdings plc", c: "UK" },
  { id: 12862, s: "SVE.L", n: "Starvest", c: "UK" },
  { id: 12863, s: "2913.TW", n: "Taiwan Tea Co", c: "TW" },
  { id: 12864, s: "W7L.L", n: "Warpaint London PLC", c: "UK" },
  { id: 12865, s: "600153.SS", n: "Xiamen C&D Inc", c: "CH" },
  { id: 12866, s: "131030.KQ", n: "DHP Korea Co.", c: "Korea" },
  { id: 12867, s: "002497.SZ", n: "Sichuan Yahua Industrial Group", c: "CH" },
  { id: 12868, s: "000905.SZ", n: "Xiamen Port Development", c: "CH" },
  { id: 12869, s: "600165.SS", n: "Ningxia Xinri Hengli Steel Wire Rope", c: "CH" },
  { id: 12870, s: "DCLT", n: "Data Call Technologi", c: "US" },
  { id: 12871, s: "AGR.F", n: "AGROB Immobilien AG", c: "GER" },
  { id: 12872, s: "IRRAS.ST", n: "Irras AB", c: "Sweden" },
  { id: 12873, s: "WC1.AX", n: "West Cobar Metals", c: "Australia" },
  { id: 12874, s: "IRIS.ST", n: "Irisity AB", c: "Sweden" },
  { id: 12875, s: "C1X.AX", n: "Cosmos Exploration", c: "Australia" },
  { id: 12876, s: "KABE-B.ST", n: "KABE Group AB (publ.)", c: "Sweden" },
  { id: 12877, s: "MTL.L", n: "Metals Exploration Plc", c: "UK" },
  { id: 12878, s: "BKY.AX", n: "Berkeley Energy", c: "Australia" },
  { id: 12879, s: "RMII.L", n: "RM Infrastructure Income PLC", c: "UK" },
  { id: 12880, s: "OSP2.DE", n: "USU Software AG", c: "GER" },
  { id: 12881, s: "CAI.AX", n: "Calidus Resources", c: "Australia" },
  { id: 12882, s: "XTE.AX", n: "Xtek", c: "Australia" },
  { id: 12883, s: "GARO.ST", n: "Garo AB", c: "Sweden" },
  { id: 12884, s: "BPH.AX", n: "BPH Energy", c: "Australia" },
  { id: 12885, s: "600187.SS", n: "Heilongjiang InterCH Water Treatment", c: "CH" },
  { id: 12886, s: "AGC.AX", n: "Australian Gold and Copper", c: "Australia" },
  { id: 12887, s: "8926.TW", n: "Taiwan Cogeneration Co", c: "TW" },
  { id: 12888, s: "1COV.F", n: "Covestro AG", c: "GER" },
  { id: 12889, s: "600501.SS", n: "Aerosun Co", c: "CH" },
  { id: 12890, s: "MRZ.V", n: "Mirasol Resources", c: "CA" },
  { id: 12891, s: "RILYN", n: "B. Riley Financial Inc", c: "US" },
  { id: 12892, s: "006840.KS", n: "Ak Holdings", c: "Korea" },
  { id: 12893, s: "STVG.L", n: "STV Group plc", c: "UK" },
  { id: 12894, s: "688107.SS", n: "Shanghai Anlogic Infotech", c: "CH" },
  { id: 12895, s: "P4O.DE", n: "Plan Optik AG", c: "GER" },
  { id: 12896, s: "NFN.DE", n: "NFON AG", c: "GER" },
  { id: 12897, s: "NSC.AX", n: "NAOS Small Cap Opportunities Company", c: "Australia" },
  { id: 12898, s: "DVYSR.ST", n: "Devyser Diagnostics AB", c: "Sweden" },
  { id: 12899, s: "LYK.AX", n: "Lykos Metals", c: "Australia" },
  { id: 12900, s: "688177.SS", n: "Bio-Thera Solutions", c: "CH" },
  { id: 12901, s: "SAPX", n: "Seven Arts Entertainment Inc", c: "US" },
  { id: 12902, s: "600439.SS", n: "Henan Rebecca Hair Products", c: "CH" },
  { id: 12903, s: "NAR.L", n: "Northamber PLC", c: "UK" },
  { id: 12904, s: "SPO.L", n: "Sportech PLC", c: "UK" },
  { id: 12905, s: "3532.TW", n: "Formosa Sumco Technology Co", c: "TW" },
  { id: 12906, s: "BEL.AX", n: "Bentley Capital", c: "Australia" },
  { id: 12907, s: "MMY.V", n: "Monument Mining Limited", c: "CA" },
  { id: 12908, s: "PU11.DE", n: "The Social Chain AG", c: "GER" },
  { id: 12909, s: "007310.KS", n: "Ottogi", c: "Korea" },
  { id: 12910, s: "OBS.DE", n: "Orbis AG", c: "GER" },
  { id: 12911, s: "WLDBF", n: "WildBrain", c: "US" },
  { id: 12912, s: "600177.SS", n: "Youngor Group", c: "CH" },
  { id: 12913, s: "DCAC", n: "Daniels Corporate Advisory Company Inc", c: "US" },
  { id: 12914, s: "A62.F", n: "IGP ADVANTAG AG EO 1", c: "GER" },
  { id: 12915, s: "002915.SZ", n: "Zhejiang Zhongxin Fluoride Materials Co Class A", c: "CH" },
  { id: 12916, s: "MAI.L", n: "Maintel Holdings Plc", c: "UK" },
  { id: 12917, s: "ACTINVRB.MX", n: "Corporación Actinver S. A. B. de C. V", c: "Mexi" },
  { id: 12918, s: "TRGYO.IS", n: "Torunlar Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 12919, s: "DEWM", n: "Dewmar Intl Bmc Inc", c: "US" },
  { id: 12920, s: "DGMLF", n: "De Grey Mining", c: "US" },
  { id: 12921, s: "UPGS.L", n: "UP Global Sourcing Holdings PLC", c: "UK" },
  { id: 12922, s: "M0YN.DE", n: "Mynaric AG", c: "GER" },
  { id: 12923, s: "UJO.L", n: "Union Jack Oil plc", c: "UK" },
  { id: 12924, s: "TUZA.TA", n: "Teuza A Fairchild Technology Venture", c: "Israel" },
  { id: 12925, s: "MAC.L", n: "Marechale Capital Plc", c: "UK" },
  { id: 12926, s: "039340.KQ", n: "Korea Business News Co.", c: "Korea" },
  { id: 12927, s: "000908.SZ", n: "Hunan Jingfeng Pharmaceutical", c: "CH" },
  { id: 12928, s: "002468.SZ", n: "STO Express", c: "CH" },
  { id: 12929, s: "HUMBLE.ST", n: "Humble Group AB", c: "Sweden" },
  { id: 12930, s: "600141.SS", n: "Hubei Xingfa Chemicals Group", c: "CH" },
  { id: 12931, s: "MAB1.L", n: "Mortgage Advice", c: "UK" },
  { id: 12932, s: "DEDI.ST", n: "Dedicare AB (publ)", c: "Sweden" },
  { id: 12933, s: "CYXO.ST", n: "Cyxone AB", c: "Sweden" },
  { id: 12934, s: "600132.SS", n: "Chongqing Brewery", c: "CH" },
  { id: 12935, s: "600388.SS", n: "Fujian Longking", c: "CH" },
  { id: 12936, s: "VAF.LS", n: "VAA Vista Alegre Atlantis SGPS SA", c: "Portugal" },
  { id: 12937, s: "030530.KQ", n: "Wonik Holdings Co.", c: "Korea" },
  { id: 12938, s: "002097.SZ", n: "Sunward Intelligent Equipment", c: "CH" },
  { id: 12939, s: "MYS.AX", n: "Mystate", c: "Australia" },
  { id: 12940, s: "AMH.AX", n: "AMCIL", c: "Australia" },
  { id: 12941, s: "REDE3.SA", n: "Rede Energia Participações S.A", c: "Brazil" },
  { id: 12942, s: "002100.SZ", n: "Xinjiang Tecon Animal Husbandry Bio-Technology", c: "CH" },
  { id: 12943, s: "V3V.DE", n: "VITA 34 AG", c: "GER" },
  { id: 12944, s: "V6C.DE", n: "Viscom AG", c: "GER" },
  { id: 12945, s: "WMG.AX", n: "Western Mines Group", c: "Australia" },
  { id: 12946, s: "002913.SZ", n: "Aoshikang Technology Co Class A", c: "CH" },
  { id: 12947, s: "000909.SZ", n: "Soyea Technology", c: "CH" },
  { id: 12948, s: "MCE.DE", n: "MEDIQON Group AG", c: "GER" },
  { id: 12949, s: "KLS.TO", n: "Kelso Technologies Inc", c: "CA" },
  { id: 12950, s: "600508.SS", n: "Shanghai Datun Energy Resources", c: "CH" },
  { id: 12951, s: "039860.KQ", n: "NanoenTekInc", c: "Korea" },
  { id: 12952, s: "KOLL.ST", n: "Kollect on Demand Holding Ab", c: "Sweden" },
  { id: 12953, s: "TGI.TA", n: "TGI Infrastructures", c: "Israel" },
  { id: 12954, s: "002917.SZ", n: "Shenzhen King Explorer Science and Technology Corp Class A", c: "CH" },
  { id: 12955, s: "000007.SZ", n: "ShenZhen Zero-Seven", c: "CH" },
  { id: 12956, s: "000902.SZ", n: "Hubei Xinyangfeng Fertilizer", c: "CH" },
  { id: 12957, s: "LIKE.L", n: "Likewise Group PLC", c: "UK" },
  { id: 12958, s: "ECO.TO", n: "EcoSynthetix Inc", c: "CA" },
  { id: 12959, s: "000006.SZ", n: "Shenzhen Zhenye Group", c: "CH" },
  { id: 12960, s: "000903.SZ", n: "Kunming Yunnei Power", c: "CH" },
  { id: 12961, s: "TMRP.TA", n: "Tamar Petroleum", c: "Israel" },
  { id: 12962, s: "VELA.L", n: "Vela Technologies Plc", c: "UK" },
  { id: 12963, s: "HI.V", n: "Highland Copper Company Inc.", c: "CA" },
  { id: 12964, s: "TTAM.TA", n: "Tiv Taam", c: "Israel" },
  { id: 12965, s: "LSL.L", n: "LSL Property Services Plc", c: "UK" },
  { id: 12966, s: "MANO.L", n: "Manolete Partners PLC", c: "UK" },
  { id: 12967, s: "TRB.L", n: "Tribal Group plc", c: "UK" },
  { id: 12968, s: "VILR.TA", n: "Villar", c: "Israel" },
  { id: 12969, s: "FCOB", n: "1st Colonial Bancorp Inc", c: "US" },
  { id: 12970, s: "BTI.AX", n: "Bailador Technology Invest", c: "Australia" },
  { id: 12971, s: "WPR.AX", n: "Waypoint REIT", c: "Australia" },
  { id: 12972, s: "SATOK.AT", n: "Sato office and Houseware supplies S.A.", c: "Greece" },
  { id: 12973, s: "MAK.DE", n: "Maternus-Kliniken Aktiengesellschaft", c: "GER" },
  { id: 12974, s: "VISN.TA", n: "Vision Sigma", c: "Israel" },
  { id: 12975, s: "MER.L", n: "Mears Group plc", c: "UK" },
  { id: 12976, s: "RGLG", n: "UHF Logistics Group Inc", c: "US" },
  { id: 12977, s: "VTNA.TA", n: "Vitania", c: "Israel" },
  { id: 12978, s: "9925.TW", n: "Taiwan Shin Kong Security", c: "TW" },
  { id: 12979, s: "2617.TW", n: "Taiwan Navigation", c: "TW" },
  { id: 12980, s: "WWH.L", n: "Worldwide Healthcare Trust Plc", c: "UK" },
  { id: 12981, s: "000893.SZ", n: "Asia Potash International Investment Guangzhou", c: "CH" },
  { id: 12982, s: "RPAC.TA", n: "Rapac Communication & Infrastructure", c: "Israel" },
  { id: 12983, s: "YNGN.L", n: "Young & Co.'s Brewery P.L.C", c: "UK" },
  { id: 12984, s: "AFYON.IS", n: "Afyon Cimento Sanayi TAS", c: "Turkey" },
  { id: 12985, s: "TOR.AX", n: "Torque Metals", c: "Australia" },
  { id: 12986, s: "603985.SS", n: "Jiangyin Hengrun Heavy", c: "CH" },
  { id: 12987, s: "ABA.AX", n: "Auswide Bank", c: "Australia" },
  { id: 12988, s: "CHM.AX", n: "Chimeric Therapeutics", c: "Australia" },
  { id: 12989, s: "ROTS.TA", n: "Rotshtein", c: "Israel" },
  { id: 12990, s: "000020.SZ", n: "Shenzhen Zhongheng Huafa Co Class A", c: "CH" },
  { id: 12991, s: "SNFL.TA", n: "Sunflow Sustain", c: "Israel" },
  { id: 12992, s: "SANO1.TA", n: "Sano Bruno's Enterprises", c: "Israel" },
  { id: 12993, s: "000026.SZ", n: "Fiyta Holdings", c: "CH" },
  { id: 12994, s: "600094.SS", n: "Greattown Holdings A", c: "CH" },
  { id: 12995, s: "HP3A.DE", n: "Ringmetall SE", c: "GER" },
  { id: 12996, s: "000017.SZ", n: "CH Bicycle Co Holdings", c: "CH" },
  { id: 12997, s: "CPT.AX", n: "Cipherpoint", c: "Australia" },
  { id: 12998, s: "SNCM.TA", n: "Suny Cellular Communication", c: "Israel" },
  { id: 12999, s: "TETMT.IS", n: "Tetamat Gida Yatirimlari AS", c: "Turkey" },
  { id: 13000, s: "600110.SS", n: "Nuode Investment", c: "CH" },
  { id: 13001, s: "000028.SZ", n: "CH National Accord Medicines Co", c: "CH" },
  { id: 13002, s: "603876.SS", n: "Jiangsu Dingsheng New Material Joint-Stock", c: "CH" },
  { id: 13003, s: "SHAN.TA", n: "Shaniv", c: "Israel" },
  { id: 13004, s: "IBU.DE", n: "IBU tec advanced materials AG", c: "GER" },
  { id: 13005, s: "KCR.L", n: "KCR Residential Reit PLC", c: "UK" },
  { id: 13006, s: "ZEG.L", n: "Zegona Communications Plc", c: "UK" },
  { id: 13007, s: "MITEY", n: "Mitsubishi Estate Co ADR", c: "US" },
  { id: 13008, s: "H9W.DE", n: "HWA AG", c: "GER" },
  { id: 13009, s: "000888.SZ", n: "Emei Shan Tourism", c: "CH" },
  { id: 13010, s: "MHLA", n: "Maiden Holdings", c: "US" },
  { id: 13011, s: "WRAC", n: "Williams Rowland Acquisition Co", c: "US" },
  { id: 13012, s: "MVIR.ST", n: "Medivir AB", c: "Sweden" },
  { id: 13013, s: "GME.DE", n: "Geratherm Medical AG", c: "GER" },
  { id: 13014, s: "STRAX.ST", n: "Strax AB", c: "Sweden" },
  { id: 13015, s: "WYNMY", n: "Wynn Macau ADR", c: "US" },
  { id: 13016, s: "ORBI.TA", n: "Orbit Technologies", c: "Israel" },
  { id: 13017, s: "603290.SS", n: "StarPower Semiconductor", c: "CH" },
  { id: 13018, s: "VRTC", n: "Veritec Inc", c: "US" },
  { id: 13019, s: "TITC.BR", n: "Titan Cement International SA", c: "Belgium" },
  { id: 13020, s: "QFTA", n: "Quantum FinTech Acquisition Corporation", c: "US" },
  { id: 13021, s: "000158.SZ", n: "Shijiazhuang Changshan Textile", c: "CH" },
  { id: 13022, s: "OKR.AX", n: "Okapi Resources", c: "Australia" },
  { id: 13023, s: "033240.KS", n: "Jahwa Electron", c: "Korea" },
  { id: 13024, s: "TRANS.ST", n: "Transtema Group AB", c: "Sweden" },
  { id: 13025, s: "ONE.TA", n: "One Software Technologies", c: "Israel" },
  { id: 13026, s: "2481.TW", n: "Pan Jit International Inc", c: "TW" },
  { id: 13027, s: "C1V.DE", n: "mVISE AG", c: "GER" },
  { id: 13028, s: "000156.SZ", n: "Wasu Media Holding", c: "CH" },
  { id: 13029, s: "6270.TWO", n: "Professional Computer Technology", c: "TW" },
  { id: 13030, s: "HERC.L", n: "Hercules Site Services PLC", c: "UK" },
  { id: 13031, s: "BKRKY", n: "Bank Rakyat", c: "US" },
  { id: 13032, s: "000155.SZ", n: "Sichuan Chemical", c: "CH" },
  { id: 13033, s: "OSS.V", n: "OneSoft Solutions Inc", c: "CA" },
  { id: 13034, s: "000783.SZ", n: "Changjiang Securities", c: "CH" },
  { id: 13035, s: "9944.TW", n: "Shinih Enterprise", c: "TW" },
  { id: 13036, s: "LKY.AX", n: "Locksley Resources", c: "Australia" },
  { id: 13037, s: "ETHC.NE", n: "Ether Capital Co", c: "CA" },
  { id: 13038, s: "BSL.F", n: "Basler Aktiengesellschaft", c: "GER" },
  { id: 13039, s: "CDVIQ", n: "Cal Dive International Inc", c: "US" },
  { id: 13040, s: "2731.TW", n: "Lion Travel Service", c: "TW" },
  { id: 13041, s: "CWL.TO", n: "Caldwell Partners International Inc", c: "CA" },
  { id: 13042, s: "CRWN.TO", n: "Crown Capital Partners Inc", c: "CA" },
  { id: 13043, s: "SLARL.TA", n: "Sella Real Estate", c: "Israel" },
  { id: 13044, s: "000899.SZ", n: "Jiangxi Ganneng", c: "CH" },
  { id: 13045, s: "RVL.TA", n: "Raval ACS", c: "Israel" },
  { id: 13046, s: "000897.SZ", n: "Tianjin Jinbin Development", c: "CH" },
  { id: 13047, s: "1213.TW", n: "Oceanic Beverages Co Inc", c: "TW" },
  { id: 13048, s: "217270.KQ", n: "Daishin Balance 1", c: "Korea" },
  { id: 13049, s: "2636.TW", n: "T3EX Global Holdings Co", c: "TW" },
  { id: 13050, s: "WSFL", n: "Woodstock Holdings Inc", c: "US" },
  { id: 13051, s: "000025.SZ", n: "Shenzhen Tellus Holding", c: "CH" },
  { id: 13052, s: "603727.SS", n: "Bomesc Offshore Engineering", c: "CH" },
  { id: 13053, s: "ITN.DE", n: "Intertainment AG", c: "GER" },
  { id: 13054, s: "RMON.TA", n: "Rimon Consulting & Management Services", c: "Israel" },
  { id: 13055, s: "CVHL", n: "Cv Holdings Inc", c: "US" },
  { id: 13056, s: "TOE.AX", n: "Toro Energy", c: "Australia" },
  { id: 13057, s: "NIM.V", n: "Nicola Mining Inc", c: "CA" },
  { id: 13058, s: "000035.SZ", n: "CH Tianying Inc", c: "CH" },
  { id: 13059, s: "3DA.AX", n: "Amaero International Ltd", c: "Australia" },
  { id: 13060, s: "OML.V", n: "Omni-Lite Industries CA Inc.", c: "CA" },
  { id: 13061, s: "ARAO", n: "AuraSource Inc", c: "US" },
  { id: 13062, s: "CEA.F", n: "FRIWO AG", c: "GER" },
  { id: 13063, s: "SPNTC.TA", n: "Spuntech", c: "Israel" },
  { id: 13064, s: "D6H.F", n: "DATAGROUP SE", c: "GER" },
  { id: 13065, s: "TBORG.IS", n: "Turk Tuborg Bira ve Malt Sanayi AS", c: "Turkey" },
  { id: 13066, s: "PENG-B.ST", n: "Projektengagemang Sweden AB (publ)", c: "Sweden" },
  { id: 13067, s: "CRS.AX", n: "Caprice Resources", c: "Australia" },
  { id: 13068, s: "TR1.MC", n: "Tier1 Technology S.A.", c: "Spain" },
  { id: 13069, s: "FRS.DE", n: "FORIS AG", c: "GER" },
  { id: 13070, s: "AKCNS.IS", n: "Akcansa Cimento Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 13071, s: "2836.TW", n: "Bank of Kaohsiung", c: "TW" },
  { id: 13072, s: "228850.KQ", n: "Rayence Co.", c: "Korea" },
  { id: 13073, s: "000011.SZ", n: "Shenzhen Properties & Resources Development Group", c: "CH" },
  { id: 13074, s: "002602.SZ", n: "Zhejiang Century Huatong Group", c: "CH" },
  { id: 13075, s: "603897.SS", n: "Zhejiang Grandwall Electric Science&Technology Co Class A", c: "CH" },
  { id: 13076, s: "600072.SS", n: "CSSC Steel Structure Engineering", c: "CH" },
  { id: 13077, s: "9942.TW", n: "Nak Sealing Technologies Co", c: "TW" },
  { id: 13078, s: "OOOO.V", n: "OOOOO Entertainment Commerce", c: "CA" },
  { id: 13079, s: "000019.SZ", n: "Shenzhen Shenbao Industrial", c: "CH" },
  { id: 13080, s: "2849.TW", n: "EnTie Commercial Bank", c: "TW" },
  { id: 13081, s: "KEYS.L", n: "Keystone Law Group PLC", c: "UK" },
  { id: 13082, s: "STG.TA", n: "STG International", c: "Israel" },
  { id: 13083, s: "PROMO.ST", n: "Promore Pharma AB", c: "Sweden" },
  { id: 13084, s: "CII.AX", n: "CI Resources", c: "Australia" },
  { id: 13085, s: "SCC.TA", n: "Space Com", c: "Israel" },
  { id: 13086, s: "GTY.DE", n: "Gateway Real Estate AG", c: "GER" },
  { id: 13087, s: "JSG.L", n: "Johnson Service Group Plc", c: "UK" },
  { id: 13088, s: "TOP.AX", n: "Thorney Opportunities", c: "Australia" },
  { id: 13089, s: "000900.SZ", n: "Xiandai Investment", c: "CH" },
  { id: 13090, s: "FH.NE", n: "Filament Health Co", c: "CA" },
  { id: 13091, s: "IXX.DE", n: "init innovation in traffic systems SE", c: "GER" },
  { id: 13092, s: "TEAKCPO.MX", n: "Proteak Uno S.A.B. de C.V", c: "Mexi" },
  { id: 13093, s: "000023.SZ", n: "Shenzhen Universe Group", c: "CH" },
  { id: 13094, s: "2838.TW", n: "Union Bank of TW", c: "TW" },
  { id: 13095, s: "GRF-P.MC", n: "Grifols SA", c: "Spain" },
  { id: 13096, s: "CDP.AX", n: "Carindale Property Trust", c: "Australia" },
  { id: 13097, s: "603708.SS", n: "Jiajiayue Group", c: "CH" },
  { id: 13098, s: "FF24.DE", n: "Fast Finance 24 Holding AG", c: "GER" },
  { id: 13099, s: "SRFT.TA", n: "Sarfati", c: "Israel" },
  { id: 13100, s: "241770.KQ", n: "Mecaro Co.", c: "Korea" },
  { id: 13101, s: "PRCO-B.ST", n: "Precio Fishbone AB (publ)", c: "Sweden" },
  { id: 13102, s: "MPRA", n: "Mercato Partners Acquisition Co", c: "US" },
  { id: 13103, s: "FEV.DE", n: "FORTEC Elektronik AG", c: "GER" },
  { id: 13104, s: "YHNF.TA", n: "M Yochananof and Sons", c: "Israel" },
  { id: 13105, s: "600126.SS", n: "Hang Zhou Iron & Steel", c: "CH" },
  { id: 13106, s: "VALUEGFO.MX", n: "Value Grupo Financiero S.A.B. de C.V", c: "Mexi" },
  { id: 13107, s: "PRO.ST", n: "Promimic AB", c: "Sweden" },
  { id: 13108, s: "277070.KQ", n: "Lindeman Asia Investment Co.", c: "Korea" },
  { id: 13109, s: "CLE.AX", n: "Cyclone Metals Limited", c: "Australia" },
  { id: 13110, s: "240810.KQ", n: "Wonik Ips Co.", c: "Korea" },
  { id: 13111, s: "CTO.AX", n: "Citigold Corporation", c: "Australia" },
  { id: 13112, s: "2845.TW", n: "Far Eastern International Bank", c: "TW" },
  { id: 13113, s: "TAS.AX", n: "Tasman Resources", c: "Australia" },
  { id: 13114, s: "DGR.AX", n: "DGR Global", c: "Australia" },
  { id: 13115, s: "POLP.TA", n: "Polyram Plastic Industries", c: "Israel" },
  { id: 13116, s: "MAG.TO", n: "MAG Silver Co", c: "CA" },
  { id: 13117, s: "SLP-B.ST", n: "Swedish Logistic Property AB Series B", c: "Sweden" },
  { id: 13118, s: "SLEEP.ST", n: "Sleep Cycle AB", c: "Sweden" },
  { id: 13119, s: "PRMG.TA", n: "Primotec Group", c: "Israel" },
  { id: 13120, s: "PRTC.TA", n: "Priortech", c: "Israel" },
  { id: 13121, s: "JAN.L", n: "Jangada Mines Plc", c: "UK" },
  { id: 13122, s: "000045.SZ", n: "Shenzhen Textile Holdings", c: "CH" },
  { id: 13123, s: "CVL.AX", n: "Civmec", c: "Australia" },
  { id: 13124, s: "EMH.DE", n: "pferdewetten.de AG", c: "GER" },
  { id: 13125, s: "600070.SS", n: "Zhejiang Furun", c: "CH" },
  { id: 13126, s: "CNGL", n: "Canna Global Acquisition Co", c: "US" },
  { id: 13127, s: "000046.SZ", n: "Oceanwide Holdings", c: "CH" },
  { id: 13128, s: "CUP.AX", n: "Countplus", c: "Australia" },
  { id: 13129, s: "DUE.F", n: "Dürr Aktiengesellschaft", c: "GER" },
  { id: 13130, s: "ADZN.V", n: "Adventus Mining Co", c: "CA" },
  { id: 13131, s: "603610.SS", n: "Keeson Technology Co", c: "CH" },
  { id: 13132, s: "MHC-U.TO", n: "Flagship Communities Real Estate Investment Trust", c: "CA" },
  { id: 13133, s: "000042.SZ", n: "Shenzhen Centralcon Investment Holding", c: "CH" },
  { id: 13134, s: "QUART.ST", n: "Quartiers Properties AB", c: "Sweden" },
  { id: 13135, s: "000886.SZ", n: "Hainan Expressway", c: "CH" },
  { id: 13136, s: "RLCO.TA", n: "Ralco Agencies", c: "Israel" },
  { id: 13137, s: "002611.SZ", n: "Guangdong Dongfang Precision Science & Technology", c: "CH" },
  { id: 13138, s: "000048.SZ", n: "ShenZhen Kondarl Group", c: "CH" },
  { id: 13139, s: "600526.SS", n: "Zhejiang Feida Environmental Science & Technology", c: "CH" },
  { id: 13140, s: "ADMG", n: "Adamant DRI Processing and Minerals Group", c: "US" },
  { id: 13141, s: "IPX.L", n: "Impax Asset Management Group Plc", c: "UK" },
  { id: 13142, s: "000880.SZ", n: "Weichai Heavy Machinery", c: "CH" },
  { id: 13143, s: "603500.SS", n: "Zhejiang Tiantai Xianghe Industrial", c: "CH" },
  { id: 13144, s: "SCIB.ST", n: "Scibase AB", c: "Sweden" },
  { id: 13145, s: "CYG.AX", n: "Coventry Group", c: "Australia" },
  { id: 13146, s: "WTKWY", n: "Wolters Kluwer NV", c: "US" },
  { id: 13147, s: "600029.SS", n: "CH Southern Airlines Co Class A", c: "CH" },
  { id: 13148, s: "CHNC", n: "CH Infrastructure Construction Co", c: "US" },
  { id: 13149, s: "DJW.AX", n: "Djerriwarrh Investments", c: "Australia" },
  { id: 13150, s: "2067.TWO", n: "Chia Yi Steel", c: "TW" },
  { id: 13151, s: "603396.SS", n: "Yingkou Jinchen Mach", c: "CH" },
  { id: 13152, s: "ALEXA.PA", n: "Exacompta Clairefontaine", c: "FR" },
  { id: 13153, s: "LIO.AX", n: "Lion Energy", c: "Australia" },
  { id: 13154, s: "ANZ.V", n: "Alianza Minerals", c: "CA" },
  { id: 13155, s: "SHB-B.ST", n: "Svenska Handelsbanken AB (publ)", c: "Sweden" },
  { id: 13156, s: "603477.SS", n: "Leshan Giantstar Farming & Husbandry Co", c: "CH" },
  { id: 13157, s: "SFTR.ST", n: "Safeture AB", c: "Sweden" },
  { id: 13158, s: "ALEUP.PA", n: "Europlasma SA", c: "FR" },
  { id: 13159, s: "ALQ.MC", n: "Alquiber Quality SA", c: "Spain" },
  { id: 13160, s: "000061.SZ", n: "Shenzhen Agricultural Products", c: "CH" },
  { id: 13161, s: "2640.TWO", n: "Taiwan Taxi", c: "TW" },
  { id: 13162, s: "182360.KQ", n: "Cube Entertainment Inc", c: "Korea" },
  { id: 13163, s: "DAL.AX", n: "Dalaroo Metals", c: "Australia" },
  { id: 13164, s: "INHC.L", n: "Induction Healthcare Group PLC", c: "UK" },
  { id: 13165, s: "D2O.AX", n: "Duxton Water", c: "Australia" },
  { id: 13166, s: "SPRN", n: "Supernova Energy Inc", c: "US" },
  { id: 13167, s: "6279.TWO", n: "Hu Lane Associate", c: "TW" },
  { id: 13168, s: "000068.SZ", n: "Shenzhen Huakong Seg", c: "CH" },
  { id: 13169, s: "INSP.L", n: "Inspirit Energy Holdings Plc", c: "UK" },
  { id: 13170, s: "TCG.AX", n: "Turaco Gold", c: "Australia" },
  { id: 13171, s: "PCBT.TA", n: "P.C.B Tec", c: "Israel" },
  { id: 13172, s: "STABL.ST", n: "Stayble Therapeutics AB", c: "Sweden" },
  { id: 13173, s: "HUW.L", n: "Helios Underwriting PLC", c: "UK" },
  { id: 13174, s: "603379.SS", n: "Zhejiang Sanmei Chemical Industry", c: "CH" },
  { id: 13175, s: "TBR.AX", n: "Tribune Resources", c: "Australia" },
  { id: 13176, s: "154040.KQ", n: "Solueta", c: "Korea" },
  { id: 13177, s: "PEN.TA", n: "Peninsula Group", c: "Israel" },
  { id: 13178, s: "000070.SZ", n: "Shenzhen SDG Information", c: "CH" },
  { id: 13179, s: "STRNY", n: "Severn Trent PLC PK", c: "US" },
  { id: 13180, s: "000877.SZ", n: "Xinjiang Tianshan Cement", c: "CH" },
  { id: 13181, s: "ECF.DE", n: "Mountain Alliance AG", c: "GER" },
  { id: 13182, s: "ECK.DE", n: "LUDWIG BECK am Rathauseck - Textilhaus Feldmeier AG", c: "GER" },
  { id: 13183, s: "000056.SZ", n: "Shenzhen Wongtee International Enterprise", c: "CH" },
  { id: 13184, s: "HDD.L", n: "Hardide PLC", c: "UK" },
  { id: 13185, s: "NALA.TA", n: "Nala Digital Commerce", c: "Israel" },
  { id: 13186, s: "000415.SZ", n: "Bohai Leasing", c: "CH" },
  { id: 13187, s: "000411.SZ", n: "Zhejiang Int'L Group", c: "CH" },
  { id: 13188, s: "SRZ.AX", n: "Stellar Resources", c: "Australia" },
  { id: 13189, s: "QPT.V", n: "Quest PharmaTech Inc", c: "CA" },
  { id: 13190, s: "300684.SZ", n: "Jones Tech PLC Class A", c: "CH" },
  { id: 13191, s: "000862.SZ", n: "Ning Xia Yin Xing Energy", c: "CH" },
  { id: 13192, s: "131220.KQ", n: "DAIHAN Scientific Co.", c: "Korea" },
  { id: 13193, s: "6244.TWO", n: "Motech Industries", c: "TW" },
  { id: 13194, s: "NISA.TA", n: "Nissan", c: "Israel" },
  { id: 13195, s: "000408.SZ", n: "Zangge Holding", c: "CH" },
  { id: 13196, s: "GTK.F", n: "TONKENS AGRAR AG", c: "GER" },
  { id: 13197, s: "000407.SZ", n: "Shandong Shengli", c: "CH" },
  { id: 13198, s: "B7E.DE", n: "Blue Cap AG", c: "GER" },
  { id: 13199, s: "NXGN.TA", n: "Nextgen", c: "Israel" },
  { id: 13200, s: "ELB.DE", n: "elumeo SE", c: "GER" },
  { id: 13201, s: "000983.SZ", n: "Shanxi Xishan Coal and Electricity Power", c: "CH" },
  { id: 13202, s: "300763.SZ", n: "Ningbo Ginlong Technologies Co Class A", c: "CH" },
  { id: 13203, s: "ORIN.TA", n: "Orian Sh.M.", c: "Israel" },
  { id: 13204, s: "000153.SZ", n: "Anhui Fengyuan Pharmaceutical", c: "CH" },
  { id: 13205, s: "AEAE", n: "Altenergy Acquisition Co", c: "US" },
  { id: 13206, s: "ORON.TA", n: "Oron Group Investments & Holdings", c: "Israel" },
  { id: 13207, s: "ATOM.V", n: "Atomic Minerals Co", c: "CA" },
  { id: 13208, s: "000151.SZ", n: "CH National Complete Plant Import & Export Co", c: "CH" },
  { id: 13209, s: "603338.SS", n: "Zhejiang Dingli Mach", c: "CH" },
  { id: 13210, s: "TAL.AX", n: "Talius Group.", c: "Australia" },
  { id: 13211, s: "600023.SS", n: "Zhejiang Zheneng Electric Power", c: "CH" },
  { id: 13212, s: "CORE.DE", n: "Coreo AG", c: "GER" },
  { id: 13213, s: "ALGEV.PA", n: "Gevelot", c: "FR" },
  { id: 13214, s: "PAYT.TA", n: "Payton -L", c: "Israel" },
  { id: 13215, s: "000416.SZ", n: "Minsheng Holdings", c: "CH" },
  { id: 13216, s: "000417.SZ", n: "Hefei Department Store Group", c: "CH" },
  { id: 13217, s: "000410.SZ", n: "Shenyang Machine Tool", c: "CH" },
  { id: 13218, s: "000096.SZ", n: "Shenzhen Guangju Energy", c: "CH" },
  { id: 13219, s: "AGMR.V", n: "Silver Mountain Resources Inc", c: "CA" },
  { id: 13220, s: "000055.SZ", n: "CH Fangda Group", c: "CH" },
  { id: 13221, s: "000881.SZ", n: "CGN Nuclear Technology Development", c: "CH" },
  { id: 13222, s: "600057.SS", n: "Xiamen Xiangyu", c: "CH" },
  { id: 13223, s: "MDIBY", n: "Mediobanca Banca di Credito Finanziario SpA ADR", c: "US" },
  { id: 13224, s: "600535.SS", n: "Tasly Pharmaceutical Group", c: "CH" },
  { id: 13225, s: "014830.KS", n: "UNID Company", c: "Korea" },
  { id: 13226, s: "019570.KQ", n: "Leaders Technology Investment Co.", c: "Korea" },
  { id: 13227, s: "HSS.L", n: "HSS Hire Group PLC", c: "UK" },
  { id: 13228, s: "000090.SZ", n: "Shenzhen Tagen Group", c: "CH" },
  { id: 13229, s: "NTO.TA", n: "Neto M.E Holdings", c: "Israel" },
  { id: 13230, s: "VSSAB-B.ST", n: "Viking Supply Ships AB (publ)", c: "Sweden" },
  { id: 13231, s: "000404.SZ", n: "Huayi Compressor", c: "CH" },
  { id: 13232, s: "2442.TW", n: "Jean", c: "TW" },
  { id: 13233, s: "000863.SZ", n: "Sanxiang", c: "CH" },
  { id: 13234, s: "603069.SS", n: "Hainan Haiqi Transportation Group", c: "CH" },
  { id: 13235, s: "NTGR.TA", n: "Netanel Group", c: "Israel" },
  { id: 13236, s: "SHOM", n: "Southern Home Medicl", c: "US" },
  { id: 13237, s: "MPC.TO", n: "Madison Pacific Properties Inc.", c: "CA" },
  { id: 13238, s: "DXC.AX", n: "Dexus Convenience Retail REIT", c: "Australia" },
  { id: 13239, s: "STX.AX", n: "Strike Energy", c: "Australia" },
  { id: 13240, s: "NVLG.TA", n: "Novolog Pharm-Up 1966", c: "Israel" },
  { id: 13241, s: "000401.SZ", n: "Tangshan Jidong Cement", c: "CH" },
  { id: 13242, s: "000400.SZ", n: "XJ Electric", c: "CH" },
  { id: 13243, s: "000338.SZ", n: "Weichai Power Co Class A", c: "CH" },
  { id: 13244, s: "VESTUM.ST", n: "Vestum AB (publ)", c: "Sweden" },
  { id: 13245, s: "000301.SZ", n: "Jiangsu Wujiang CH Eastern Silk Market", c: "CH" },
  { id: 13246, s: "W5.ST", n: "W5 Solutions AB", c: "Sweden" },
  { id: 13247, s: "TSGTF", n: "Tsingtao Brewery Company Limited", c: "US" },
  { id: 13248, s: "DIIBF", n: "Dorel Industries Inc", c: "US" },
  { id: 13249, s: "000584.SZ", n: "Jiangsu Youli Investment Holding", c: "CH" },
  { id: 13250, s: "MLL.DE", n: "Müller - Die lila Logistik AG", c: "GER" },
  { id: 13251, s: "082640.KS", n: "Tongyang Life", c: "Korea" },
  { id: 13252, s: "3354.TWO", n: "Microcosm Technology", c: "TW" },
  { id: 13253, s: "MLRN.TA", n: "MLRN Projects and Trade", c: "Israel" },
  { id: 13254, s: "FFARMS.", n: "First Farms A/S", c: "Denmark" },
  { id: 13255, s: "BCOM.TA", n: "B Communications", c: "Israel" },
  { id: 13256, s: "078935.KS", n: "GS Holdings Co", c: "Korea" },
  { id: 13257, s: "AZRT.TA", n: "Reit Azorim HF Living", c: "Israel" },
  { id: 13258, s: "AMPLI.PA", n: "Amplitude Surgical SAS", c: "FR" },
  { id: 13259, s: "601952.SS", n: "Jiangsu Provincial Agricultural Reclamation and Development", c: "CH" },
  { id: 13260, s: "OML.AX", n: "oOh!Media", c: "Australia" },
  { id: 13261, s: "MDUNDO.", n: "Mdundo Com As", c: "Denmark" },
  { id: 13262, s: "000519.SZ", n: "North Industries Group Red Arrow", c: "CH" },
  { id: 13263, s: "SHE.AX", n: "Stonehorse Energy", c: "Australia" },
  { id: 13264, s: "000848.SZ", n: "He Bei Cheng De LoLo", c: "CH" },
  { id: 13265, s: "MCMN.TA", n: "Michman Basad", c: "Israel" },
  { id: 13266, s: "000520.SZ", n: "Chang Jiang Shipping Group Phoenix", c: "CH" },
  { id: 13267, s: "600007.SS", n: "CH World Trade Center", c: "CH" },
  { id: 13268, s: "MAXO.TA", n: "Max Stock", c: "Israel" },
  { id: 13269, s: "000521.SZ", n: "Changhong Meiling", c: "CH" },
  { id: 13270, s: "000523.SZ", n: "Lonkey Industrial Co Guangzhou", c: "CH" },
  { id: 13271, s: "000738.SZ", n: "Aecc Aero Engine Control", c: "CH" },
  { id: 13272, s: "601916.SS", n: "CH Zheshang Bank ", c: "CH" },
  { id: 13273, s: "LVPR.TA", n: "Levinstein Prop", c: "Israel" },
  { id: 13274, s: "CFX.TA", n: "Cofix Group", c: "Israel" },
  { id: 13275, s: "000789.SZ", n: "Jiangxi Wannianqing Cement", c: "CH" },
  { id: 13276, s: "KIL.AX", n: "Kiland", c: "Australia" },
  { id: 13277, s: "LUZN.TA", n: "Amos Luzon Development and Energy Group", c: "Israel" },
  { id: 13278, s: "IXX.F", n: "init innovation in traffic systems SE", c: "GER" },
  { id: 13279, s: "FA.L", n: "Fireangel Safety Technology Group PLC", c: "UK" },
  { id: 13280, s: "000533.SZ", n: "Guangdong Macro", c: "CH" },
  { id: 13281, s: "000791.SZ", n: "GEPIC Energy Development", c: "CH" },
  { id: 13282, s: "OTKAR.IS", n: "Otokar Otomotiv ve Savunma Sanayi AS", c: "Turkey" },
  { id: 13283, s: "603009.SS", n: "Shanghai Beite Technology", c: "CH" },
  { id: 13284, s: "300539.SZ", n: "Ningbo Henghe Mould", c: "CH" },
  { id: 13285, s: "GHH.L", n: "Gooch & Housego Plc", c: "UK" },
  { id: 13286, s: "MLSR.TA", n: "Melisron", c: "Israel" },
  { id: 13287, s: "000430.SZ", n: "Zhang Jia Jie Tourism Group", c: "CH" },
  { id: 13288, s: "000429.SZ", n: "Guangdong Provincial Expressway Development", c: "CH" },
  { id: 13289, s: "MRIN.TA", n: "YD More Investments", c: "Israel" },
  { id: 13290, s: "BPO-PI.TO", n: "Brookfield Office Properties Inc Pref Class AAA Series II", c: "CA" },
  { id: 13291, s: "AB9.DE", n: "ABO Wind AG", c: "GER" },
  { id: 13292, s: "603037.SS", n: "Shanghai Carthane", c: "CH" },
  { id: 13293, s: "XGL.AX", n: "Xamble Group Limited", c: "Australia" },
  { id: 13294, s: "GLR.L", n: "Galileo Resources Plc", c: "UK" },
  { id: 13295, s: "AIEA.L", n: "Airea Plc", c: "UK" },
  { id: 13296, s: "MVNE.TA", n: "Mivne Real Estate KD", c: "Israel" },
  { id: 13297, s: "2421.TW", n: "Sunonwealth Electric Machine Industry", c: "TW" },
  { id: 13298, s: "SHPMY", n: "Shanghai Pharmaceuticals Holding Co ADR", c: "US" },
  { id: 13299, s: "FLUX.BR", n: "Fluxys Belgium", c: "Belgium" },
  { id: 13300, s: "051630.KS", n: "Chin Yang Chem", c: "Korea" },
  { id: 13301, s: "HEI.F", n: "HeidelbergCement AG", c: "GER" },
  { id: 13302, s: "GFIN.L", n: "Gfinity PLC", c: "UK" },
  { id: 13303, s: "000422.SZ", n: "Hubei Yihua Chemical Industry", c: "CH" },
  { id: 13304, s: "ACT.", n: "DecideAct A/S", c: "Denmark" },
  { id: 13305, s: "3284.TWO", n: "Top High Image", c: "TW" },
  { id: 13306, s: "WIG1.F", n: "Sporttotal AG", c: "GER" },
  { id: 13307, s: "3285.TWO", n: "Microtips Technology", c: "TW" },
  { id: 13308, s: "XNGSY", n: "ENN Energy Holdings", c: "US" },
  { id: 13309, s: "000423.SZ", n: "Shan Dong Dong-E E-Jiao", c: "CH" },
  { id: 13310, s: "000426.SZ", n: "Inner Mongolia Xingye Mining", c: "CH" },
  { id: 13311, s: "000860.SZ", n: "Beijing Shunxin Agriculture", c: "CH" },
  { id: 13312, s: "000779.SZ", n: "Gansu Engineering Consulting Group", c: "CH" },
  { id: 13313, s: "MSKE.TA", n: "Meshek Energy-Renewable Energies", c: "Israel" },
  { id: 13314, s: "000428.SZ", n: "Huatian Hotel Group", c: "CH" },
  { id: 13315, s: "TREP", n: "Trucept Inc", c: "US" },
  { id: 13316, s: "CMD.V", n: "Commander Resources", c: "CA" },
  { id: 13317, s: "300534.SZ", n: "Gansu Longshenrongfa Pharmaceutical Industry", c: "CH" },
  { id: 13318, s: "HOR.AX", n: "Horseshoe Metals", c: "Australia" },
  { id: 13319, s: "600713.SS", n: "NanJing Pharmaceutical", c: "CH" },
  { id: 13320, s: "VVL.OL", n: "Voss Veksel og Landmandsbank ASA", c: "Norway" },
  { id: 13321, s: "002777.SZ", n: "Sichuan Jiuyuan Yinhai Software", c: "CH" },
  { id: 13322, s: "GKL.TA", n: "Global Knafaim Leasing", c: "Israel" },
  { id: 13323, s: "DUNI.TA", n: "Duniec", c: "Israel" },
  { id: 13324, s: "GRUSF", n: "Grown Rogue International Inc", c: "US" },
  { id: 13325, s: "HGV.AX", n: "Hygrovest", c: "Australia" },
  { id: 13326, s: "AYM.L", n: "Anglesey Mining", c: "UK" },
  { id: 13327, s: "STNG.V", n: "Stinger Resources Inc", c: "CA" },
  { id: 13328, s: "1817.TW", n: "Sanitar", c: "TW" },
  { id: 13329, s: "VOLUE.OL", n: "Volue AS", c: "Norway" },
  { id: 13330, s: "VISTN.OL", n: "Vistin Pharma ASA", c: "Norway" },
  { id: 13331, s: "000698.SZ", n: "Shenyang Chemical Industry", c: "CH" },
  { id: 13332, s: "000700.SZ", n: "Jiangnan Mould & Plastic Technology", c: "CH" },
  { id: 13333, s: "ISDMR.IS", n: "Iskenderun Demir ve Celik AS", c: "Turkey" },
  { id: 13334, s: "000813.SZ", n: "Dezhan HealthCare", c: "CH" },
  { id: 13335, s: "000572.SZ", n: "Haima Automobile Group", c: "CH" },
  { id: 13336, s: "BRH.L", n: "Braveheart Investment Group", c: "UK" },
  { id: 13337, s: "ARION.IC", n: "Arion banki hf", c: "Iceland" },
  { id: 13338, s: "HIT.AX", n: "Hitech Group Australia", c: "Australia" },
  { id: 13339, s: "HLA.AX", n: "Healthia", c: "Australia" },
  { id: 13340, s: "601100.SS", n: "Jiangsu Hengli Hydraulic", c: "CH" },
  { id: 13341, s: "000697.SZ", n: "Shaanxi Ligeance Mineral Resources", c: "CH" },
  { id: 13342, s: "KAOOY", n: "Kao Corp ADR", c: "US" },
  { id: 13343, s: "ALR.WA", n: "Alior Bank S.A.", c: "Poland" },
  { id: 13344, s: "TNX.TO", n: "TRX Gold Co", c: "CA" },
  { id: 13345, s: "ECNR.TA", n: "Econergy Renewable Energy", c: "Israel" },
  { id: 13346, s: "000695.SZ", n: "Tianjin Binhai Energy & Development", c: "CH" },
  { id: 13347, s: "000637.SZ", n: "Maoming Petro-Chemical Shihua", c: "CH" },
  { id: 13348, s: "PTX.AX", n: "Prescient Therapeutics", c: "Australia" },
  { id: 13349, s: "AAIF.L", n: "abrdn Asian Income Fund Limited", c: "UK" },
  { id: 13350, s: "000829.SZ", n: "Telling Telecommunication Holding", c: "CH" },
  { id: 13351, s: "VTX.V", n: "Vertex Resource Group", c: "CA" },
  { id: 13352, s: "EPN.AX", n: "Epsilon Healthcare", c: "Australia" },
  { id: 13353, s: "300600.SZ", n: "Changshu Ruite Electric", c: "CH" },
  { id: 13354, s: "MGDA.TA", n: "Migdalor Alternative Products", c: "Israel" },
  { id: 13355, s: "000757.SZ", n: "Sichuan Haowu Electromechanical", c: "CH" },
  { id: 13356, s: "000788.SZ", n: "PKU HealthCare Co", c: "CH" },
  { id: 13357, s: "FRP.L", n: "Frp Advisory Group Plc", c: "UK" },
  { id: 13358, s: "QSAM", n: "Qsam Biosciences Inc", c: "US" },
  { id: 13359, s: "600004.SS", n: "Guangzhou Baiyun International Airport", c: "CH" },
  { id: 13360, s: "MGRT.TA", n: "MeguREIT Israel", c: "Israel" },
  { id: 13361, s: "5443.TWO", n: "Gallant Precision Machining", c: "TW" },
  { id: 13362, s: "DAGHL.IS", n: "Dagi Yatirim Holding AS", c: "Turkey" },
  { id: 13363, s: "5439.TWO", n: "First Hi-tec Enterprise", c: "TW" },
  { id: 13364, s: "FPO.L", n: "First Property Group plc", c: "UK" },
  { id: 13365, s: "000510.SZ", n: "Sichuan Jinlu Group", c: "CH" },
  { id: 13366, s: "000755.SZ", n: "Shanxi Sanwei Group", c: "CH" },
  { id: 13367, s: "MGOR.TA", n: "Mega Or", c: "Israel" },
  { id: 13368, s: "000516.SZ", n: "Xian International Medical Investment", c: "CH" },
  { id: 13369, s: "EPM.AX", n: "Eclipse Metals", c: "Australia" },
  { id: 13370, s: "CANX.V", n: "CANEX Metals Inc", c: "CA" },
  { id: 13371, s: "VRAP.PA", n: "Vranken-Pommery Monopole Société Anonyme", c: "FR" },
  { id: 13372, s: "600770.SS", n: "Jiangsu Zongyi", c: "CH" },
  { id: 13373, s: "000852.SZ", n: "Sinopec Oilfield Equipment Co", c: "CH" },
  { id: 13374, s: "FNTL.L", n: "Fintel PLC", c: "UK" },
  { id: 13375, s: "HUSCO.", n: "HusCompagniet AS", c: "Denmark" },
  { id: 13376, s: "SIO.AX", n: "Simonds Group", c: "Australia" },
  { id: 13377, s: "000517.SZ", n: "Rongan Property", c: "CH" },
  { id: 13378, s: "000851.SZ", n: "Gohigh Data Networks Technology", c: "CH" },
  { id: 13379, s: "FLO.L", n: "Flowtech Fluidpower plc", c: "UK" },
  { id: 13380, s: "600773.SS", n: "Tibet Urban Development and Investment", c: "CH" },
  { id: 13381, s: "3489.TWO", n: "Sun Brothers Development", c: "TW" },
  { id: 13382, s: "EP1.AX", n: "E&P Financial Group", c: "Australia" },
  { id: 13383, s: "BHNY.TA", n: "Beeio Honey", c: "Israel" },
  { id: 13384, s: "LNNGY", n: "Li Ning Co ADR", c: "US" },
  { id: 13385, s: "601997.SS", n: "Bank of Guiyang", c: "CH" },
  { id: 13386, s: "000504.SZ", n: "Nanhua Bio Medicine", c: "CH" },
  { id: 13387, s: "OFX.AX", n: "OFX Group", c: "Australia" },
  { id: 13388, s: "000721.SZ", n: "XiAn Catering", c: "CH" },
  { id: 13389, s: "ZONE.V", n: "Zonetail Inc", c: "CA" },
  { id: 13390, s: "EX1.AX", n: "Exopharm", c: "Australia" },
  { id: 13391, s: "ERCB.IS", n: "Erciyas Celik Boru Sanayi A.S.", c: "Turkey" },
  { id: 13392, s: "000564.SZ", n: "CCOOP Group", c: "CH" },
  { id: 13393, s: "2328.TW", n: "Pan-International Industrial Co", c: "TW" },
  { id: 13394, s: "EXL.AX", n: "Elixinol Wellness Limited", c: "Australia" },
  { id: 13395, s: "000543.SZ", n: "An Hui Wenergy", c: "CH" },
  { id: 13396, s: "1614.TW", n: "Taiwan Sanyo Electric", c: "TW" },
  { id: 13397, s: "QBIEY", n: "QBE Insurance Group ADR", c: "US" },
  { id: 13398, s: "6190.TWO", n: "Wonderful Hi-Tech", c: "TW" },
  { id: 13399, s: "002739.SZ", n: "Wanda Cinema Line Co", c: "CH" },
  { id: 13400, s: "PMG.L", n: "Parkmead Group PLC", c: "UK" },
  { id: 13401, s: "300425.SZ", n: "Scimee Sci &Tech", c: "CH" },
  { id: 13402, s: "LBRA.TA", n: "Libra Insurance Company", c: "Israel" },
  { id: 13403, s: "CML.V", n: "CaNickel Mining", c: "CA" },
  { id: 13404, s: "000557.SZ", n: "Ningxia Western Venture Industrial", c: "CH" },
  { id: 13405, s: "EBQ.L", n: "Ebiquity Plc", c: "UK" },
  { id: 13406, s: "EAH.L", n: "Eco Animal Health Group Plc", c: "UK" },
  { id: 13407, s: "KRDI.TA", n: "Kardan Israel", c: "Israel" },
  { id: 13408, s: "000728.SZ", n: "Guoyuan Securities", c: "CH" },
  { id: 13409, s: "VRG.WA", n: "VRG SA", c: "Poland" },
  { id: 13410, s: "TTO.DE", n: "TTL Beteiligungs- und Grundbesitz-AG", c: "GER" },
  { id: 13411, s: "000836.SZ", n: "Tianjin Xinmao Science and Technology", c: "CH" },
  { id: 13412, s: "300474.SZ", n: "Changsha Jingjia Microelectronics", c: "CH" },
  { id: 13413, s: "RIWI.V", n: "Riwi Co", c: "CA" },
  { id: 13414, s: "000795.SZ", n: "Innuovo Technology Co Class A", c: "CH" },
  { id: 13415, s: "GLAE", n: "Glassbridge Enterprises Inc", c: "US" },
  { id: 13416, s: "GFG.V", n: "GFG Resources Inc", c: "CA" },
  { id: 13417, s: "000980.SZ", n: "Zotye Automobile", c: "CH" },
  { id: 13418, s: "VUL.V", n: "Vulcan Minerals Inc.", c: "CA" },
  { id: 13419, s: "ENSRI.IS", n: "Ensari Deri Gida Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 13420, s: "WEW.F", n: "Westwing Group AG", c: "GER" },
  { id: 13421, s: "300497.SZ", n: "Jiangxi Fushine Pharmaceutical", c: "CH" },
  { id: 13422, s: "000545.SZ", n: "Jilin Gpro Titanium Industry", c: "CH" },
  { id: 13423, s: "000838.SZ", n: "CASIN Real Estate Development Group", c: "CH" },
  { id: 13424, s: "002831.SZ", n: "ShenZhen YUTO Packaging Technology", c: "CH" },
  { id: 13425, s: "JZ6.F", n: "DT.EIGENHEIM UNION INH ON", c: "GER" },
  { id: 13426, s: "ESEN.IS", n: "Esenboga Elektrik Uretim AS", c: "Turkey" },
  { id: 13427, s: "GGO.V", n: "Galleon Gold Co", c: "CA" },
  { id: 13428, s: "WTN.WA", n: "Wittchen SA", c: "Poland" },
  { id: 13429, s: "100790.KQ", n: "Mirae Asset Venture Investment Co.", c: "Korea" },
  { id: 13430, s: "DE1.HM", n: "Deufol SE", c: "GER" },
  { id: 13431, s: "EUKYO.IS", n: "Euro Kapital Yatirim Ortakligi AS", c: "Turkey" },
  { id: 13432, s: "LBTL.TA", n: "Libental", c: "Israel" },
  { id: 13433, s: "000548.SZ", n: "Hunan Investment Group", c: "CH" },
  { id: 13434, s: "PWF-PH.TO", n: "Power Financial Corp Pref H", c: "CA" },
  { id: 13435, s: "5371.TWO", n: "Coretronic", c: "TW" },
  { id: 13436, s: "000799.SZ", n: "Jiugui Liquor", c: "CH" },
  { id: 13437, s: "RISMA.", n: "Risma Systems AS", c: "Denmark" },
  { id: 13438, s: "000735.SZ", n: "Luoniushan", c: "CH" },
  { id: 13439, s: "000551.SZ", n: "Create Technology & Science", c: "CH" },
  { id: 13440, s: "5609.TWO", n: "Dimerco Express", c: "TW" },
  { id: 13441, s: "2373.TW", n: "Aurora Co", c: "TW" },
  { id: 13442, s: "092200.KS", n: "DAE-IL Corporation", c: "Korea" },
  { id: 13443, s: "ASHG.TA", n: "Ashtrom Group", c: "Israel" },
  { id: 13444, s: "ISCN.TA", n: "Israel CA", c: "Israel" },
  { id: 13445, s: "KFM.AX", n: "Kingfisher Mining Ltd", c: "Australia" },
  { id: 13446, s: "000566.SZ", n: "Hainan Haiyao", c: "CH" },
  { id: 13447, s: "ETP.L", n: "Eneraqua Technologies PLC", c: "UK" },
  { id: 13448, s: "000839.SZ", n: "CITIC Guoan Information Industry", c: "CH" },
  { id: 13449, s: "ESYS.L", n: "Essensys PLC", c: "UK" },
  { id: 13450, s: "KED.AX", n: "Keypath Education International Inc DRC", c: "Australia" },
  { id: 13451, s: "000565.SZ", n: "Chongqing Sanxia Paints", c: "CH" },
  { id: 13452, s: "2342.TW", n: "Mosel Vitelic Inc", c: "TW" },
  { id: 13453, s: "000796.SZ", n: "Hna-Caissa Travel Group", c: "CH" },
  { id: 13454, s: "SB1.DE", n: "Smartbroker Holding AG", c: "GER" },
  { id: 13455, s: "COBH.BR", n: "Brouwerij Handelsmaatschappij NV", c: "Belgium" },
  { id: 13456, s: "000833.SZ", n: "Guangxi Guitang Group", c: "CH" },
  { id: 13457, s: "MIPAZ.IS", n: "Milpa Ticari ve Sinai Urunler Pazarlama Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 13458, s: "BEG.L", n: "Begbies Traynor Group PLC", c: "UK" },
  { id: 13459, s: "000737.SZ", n: "North Copper Shanxi", c: "CH" },
  { id: 13460, s: "NTT.WA", n: "NTT System SA", c: "Poland" },
  { id: 13461, s: "NVA.WA", n: "PA Nova S.A.", c: "Poland" },
  { id: 13462, s: "000777.SZ", n: "SUFA Technology Industry Co CNNC", c: "CH" },
  { id: 13463, s: "SOR.AX", n: "Strategic Elements", c: "Australia" },
  { id: 13464, s: "603003.SS", n: "Lonyer Fuels", c: "CH" },
  { id: 13465, s: "PPL-PI.TO", n: "Pembina Pipeline Corp Pref I", c: "CA" },
  { id: 13466, s: "MRG.TA", n: "Multi Retail Group", c: "Israel" },
  { id: 13467, s: "MPP.TA", n: "More Provident Funds", c: "Israel" },
  { id: 13468, s: "122990.KQ", n: "WiSoL CO.LTD", c: "Korea" },
  { id: 13469, s: "000488.SZ", n: "Shandong Chenming Paper Holdings", c: "CH" },
  { id: 13470, s: "MNRT.TA", n: "Menivim The New REIT", c: "Israel" },
  { id: 13471, s: "PERL", n: "Perla Group International Inc", c: "US" },
  { id: 13472, s: "FTC.L", n: "Filtronic", c: "UK" },
  { id: 13473, s: "MNIN.TA", n: "Mendelson Infrastructures and Industries", c: "Israel" },
  { id: 13474, s: "000498.SZ", n: "Shandong Hi-Speed Road&Bridge", c: "CH" },
  { id: 13475, s: "MNIF.TA", n: "Menif Financial Services", c: "Israel" },
  { id: 13476, s: "LIBY", n: "Liberty Resources Acquisition Co", c: "US" },
  { id: 13477, s: "000501.SZ", n: "Wushang Group", c: "CH" },
  { id: 13478, s: "KNB.AX", n: "Koonenberry Gold", c: "Australia" },
  { id: 13479, s: "5457.TWO", n: "Speed Tech", c: "TW" },
  { id: 13480, s: "000766.SZ", n: "Tonghua Golden-Horse Pharmaceutical Industry", c: "CH" },
  { id: 13481, s: "BTCW.V", n: "Bitcoin Well Inc", c: "CA" },
  { id: 13482, s: "BPOPM", n: "Popular Capital Trust II Pref", c: "US" },
  { id: 13483, s: "000859.SZ", n: "Anhui Guofeng Plastic Industry", c: "CH" },
  { id: 13484, s: "MMAN.TA", n: "Maman- Cargo Terminals & Handling", c: "Israel" },
  { id: 13485, s: "BTI.V", n: "biOasis Technologies Inc", c: "CA" },
  { id: 13486, s: "000561.SZ", n: "Shaanxi Fenghuo Electronics", c: "CH" },
  { id: 13487, s: "098460.KQ", n: "Koh Young Technology Inc", c: "Korea" },
  { id: 13488, s: "ISTA.TA", n: "Issta Lines", c: "Israel" },
  { id: 13489, s: "AFTM", n: "Aftermaster Inc", c: "US" },
  { id: 13490, s: "000559.SZ", n: "Wanxiang Qianchao", c: "CH" },
  { id: 13491, s: "DIG.L", n: "Dunedin Income Growth Investment Trust PLC", c: "UK" },
  { id: 13492, s: "RE.PA", n: "Colas SA", c: "FR" },
  { id: 13493, s: "NCF.TO", n: "Northcliff Resources", c: "CA" },
  { id: 13494, s: "FML.AX", n: "Focus Minerals", c: "Australia" },
  { id: 13495, s: "000798.SZ", n: "CNFC Overseas Fishery", c: "CH" },
  { id: 13496, s: "KARE.TA", n: "Kardan Real Es", c: "Israel" },
  { id: 13497, s: "KRUR.TA", n: "Kerur Holdings", c: "Israel" },
  { id: 13498, s: "000726.SZ", n: "Lu Thai Textile", c: "CH" },
  { id: 13499, s: "SCHP.PA", n: "Seche Environnem.", c: "FR" },
  { id: 13500, s: "300487.SZ", n: "Xian Sunresin New Materials", c: "CH" },
  { id: 13501, s: "LAPD.TA", n: "Lapidoth", c: "Israel" },
  { id: 13502, s: "145720.KS", n: "Dentium", c: "Korea" },
  { id: 13503, s: "B2H.OL", n: "B2holding ASA", c: "Norway" },
  { id: 13504, s: "FSG.AX", n: "Field Solutions Holdings", c: "Australia" },
  { id: 13505, s: "000722.SZ", n: "Hunan Fazhan Industrial", c: "CH" },
  { id: 13506, s: "PSDTC.IS", n: "Pergamon Status Dis Ticaret AS", c: "Turkey" },
  { id: 13507, s: "PRC.PA", n: "Artmarket.com SA", c: "FR" },
  { id: 13508, s: "000552.SZ", n: "Gansu Jingyuan Coal Industry and Electricity Power", c: "CH" },
  { id: 13509, s: "VOW3.F", n: "Volkswagen AG", c: "GER" },
  { id: 13510, s: "300071.SZ", n: "Spearhead Integrated Marketing", c: "CH" },
  { id: 13511, s: "000731.SZ", n: "Sichuan Meifeng Chemical Industry", c: "CH" },
  { id: 13512, s: "KGN.WA", n: "Kogeneracja SA", c: "Poland" },
  { id: 13513, s: "601838.SS", n: "Bank of Chengdu", c: "CH" },
  { id: 13514, s: "WMA.", n: "WindowMaster International AS", c: "Denmark" },
  { id: 13515, s: "ISRO.TA", n: "Isrotel -L", c: "Israel" },
  { id: 13516, s: "GENIL.IS", n: "Gen Ilac ve Saglik Urunleri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 13517, s: "2360.TW", n: "Chroma ATE Inc", c: "TW" },
  { id: 13518, s: "600601.SS", n: "Founder Technology Group", c: "CH" },
  { id: 13519, s: "PTM.AX", n: "Platinum Asset Management", c: "Australia" },
  { id: 13520, s: "3268.TWO", n: "Higher Way Electronic", c: "TW" },
  { id: 13521, s: "GLPL.TA", n: "Golan Plastic", c: "Israel" },
  { id: 13522, s: "2105.TW", n: "Cheng Shin Rubber Ind.", c: "TW" },
  { id: 13523, s: "000716.SZ", n: "Nanfang Black Sesame Group", c: "CH" },
  { id: 13524, s: "ILDC.TA", n: "Israel Land Development Company", c: "Israel" },
  { id: 13525, s: "000822.SZ", n: "Shandong Haihua", c: "CH" },
  { id: 13526, s: "000600.SZ", n: "Jointo Energy Investment Co Hebei", c: "CH" },
  { id: 13527, s: "000620.SZ", n: "Macrolink Real Estate", c: "CH" },
  { id: 13528, s: "000619.SZ", n: "Wuhu Conch Profiles and Science", c: "CH" },
  { id: 13529, s: "EAC.V", n: "Earth Alive Clean Technologies Inc", c: "CA" },
  { id: 13530, s: "GRL.AX", n: "Godolphin Resources", c: "Australia" },
  { id: 13531, s: "SBN.TO", n: "S Split Co", c: "CA" },
  { id: 13532, s: "000803.SZ", n: "BECE Legend Group", c: "CH" },
  { id: 13533, s: "DNA.TA", n: "DNA Biomed Solns", c: "Israel" },
  { id: 13534, s: "ING.WA", n: "ING Bank Śląski SA", c: "Poland" },
  { id: 13535, s: "VGZ.TO", n: "Vista Gold Corporation", c: "CA" },
  { id: 13536, s: "2241.TW", n: "Amulaire Thermal Technology", c: "TW" },
  { id: 13537, s: "2106.TW", n: "Kenda Rubber Industrial", c: "TW" },
  { id: 13538, s: "HGG.TA", n: "Hagag Group", c: "Israel" },
  { id: 13539, s: "000816.SZ", n: "JiangSu JiangHuai Engine", c: "CH" },
  { id: 13540, s: "000802.SZ", n: "Beijing Jingxi Culture&Tourism", c: "CH" },
  { id: 13541, s: "IPB", n: "Merrill Lynch Depositor Inc IC", c: "US" },
  { id: 13542, s: "IXC.AX", n: "Invex Therapeutics", c: "Australia" },
  { id: 13543, s: "078890.KQ", n: "Kaonmedia", c: "Korea" },
  { id: 13544, s: "SPINN.HE", n: "Spinnova Oy", c: "Finland" },
  { id: 13545, s: "URB.TO", n: "Urbana Corporation", c: "CA" },
  { id: 13546, s: "601231.SS", n: "Universal Scientific Industrial Shanghai", c: "CH" },
  { id: 13547, s: "000809.SZ", n: "Tieling Newcity Investment Holding", c: "CH" },
  { id: 13548, s: "2101.TW", n: "Nankang Rubber Tire Co", c: "TW" },
  { id: 13549, s: "GSM.AX", n: "Golden State Mining", c: "Australia" },
  { id: 13550, s: "GYST", n: "Graystone Company Inc", c: "US" },
  { id: 13551, s: "RCT.AX", n: "Reef Casino Trust", c: "Australia" },
  { id: 13552, s: "HRS.WA", n: "Herkules S.A.", c: "Poland" },
  { id: 13553, s: "DG.V", n: "Dixie Gold Inc", c: "CA" },
  { id: 13554, s: "000582.SZ", n: "Beibuwan Port", c: "CH" },
  { id: 13555, s: "1417.TW", n: "Carnival Industrial Co", c: "TW" },
  { id: 13556, s: "000576.SZ", n: "Guangdong Ganhua Science & Industry", c: "CH" },
  { id: 13557, s: "000801.SZ", n: "Sichuan Jiuzhou Electronic", c: "CH" },
  { id: 13558, s: "CNN.L", n: "Caledonian Trust PLC", c: "UK" },
  { id: 13559, s: "000591.SZ", n: "CECEP Solar Energy", c: "CH" },
  { id: 13560, s: "000590.SZ", n: "Tus Pharmaceutical Group", c: "CH" },
  { id: 13561, s: "000589.SZ", n: "Gui Zhou Tyre", c: "CH" },
  { id: 13562, s: "000573.SZ", n: "DongGuan Winnerway Industrial Zone", c: "CH" },
  { id: 13563, s: "194370.KS", n: "JS Co", c: "Korea" },
  { id: 13564, s: "000720.SZ", n: "Shandong Xinneng Taishan Power Generation", c: "CH" },
  { id: 13565, s: "000719.SZ", n: "Central CH Land Media", c: "CH" },
  { id: 13566, s: "AMTE.L", n: "Amte Power PLC", c: "UK" },
  { id: 13567, s: "BUI.PA", n: "Barbara Bui SA", c: "FR" },
  { id: 13568, s: "G6M.AX", n: "Group 6 Metals", c: "Australia" },
  { id: 13569, s: "000826.SZ", n: "Tus-Sound Environmental Resources", c: "CH" },
  { id: 13570, s: "CSSG.L", n: "Croma Security Solutions Group Plc", c: "UK" },
  { id: 13571, s: "000571.SZ", n: "Sundiro Holding", c: "CH" },
  { id: 13572, s: "IFXY", n: "Infrax Systems Inc", c: "US" },
  { id: 13573, s: "000718.SZ", n: "Suning Universal", c: "CH" },
  { id: 13574, s: "KSBI", n: "KS Bancorp Inc", c: "US" },
  { id: 13575, s: "000681.SZ", n: "Visual CH Group", c: "CH" },
  { id: 13576, s: "601611.SS", n: "CH Nuclear Engineering", c: "CH" },
  { id: 13577, s: "600868.SS", n: "Guangdong Meiyan Jixiang Hydropower", c: "CH" },
  { id: 13578, s: "000586.SZ", n: "Sichuan Huiyuan Optical Com", c: "CH" },
  { id: 13579, s: "RSS.V", n: "Resaas Services Inc", c: "CA" },
  { id: 13580, s: "DFV.DE", n: "DFV Deutsche Familienversicherung AG", c: "GER" },
  { id: 13581, s: "CPX.DE", n: "capsensixx AG", c: "GER" },
  { id: 13582, s: "002851.SZ", n: "Shenzhen Megmeet Electrical", c: "CH" },
  { id: 13583, s: "002747.SZ", n: "Nanjing ESTUN Automation", c: "CH" },
  { id: 13584, s: "600873.SS", n: "MeiHua Holdings Group", c: "CH" },
  { id: 13585, s: "ILX.TA", n: "Ilex Medical", c: "Israel" },
  { id: 13586, s: "UNI.TO", n: "Unisync Co", c: "CA" },
  { id: 13587, s: "SCANFL.HE", n: "Scanfil Oyj", c: "Finland" },
  { id: 13588, s: "000599.SZ", n: "Qingdao Doublestar", c: "CH" },
  { id: 13589, s: "600211.SS", n: "Tibet Rhodiola Pharmaceutical Holding", c: "CH" },
  { id: 13590, s: "IOCJY", n: "Iochpe Maxion SA ADR", c: "US" },
  { id: 13591, s: "000610.SZ", n: "XiAn Tourism", c: "CH" },
  { id: 13592, s: "1419.TW", n: "Shinkong Textile", c: "TW" },
  { id: 13593, s: "VPH.TO", n: "Valeo Pharma Inc", c: "CA" },
  { id: 13594, s: "NDX1.F", n: "Nordex SE", c: "GER" },
  { id: 13595, s: "HDIV.L", n: "Henderson Diversified Income", c: "UK" },
  { id: 13596, s: "MYDS.TA", n: "Mydas Real Estate Investments", c: "Israel" },
  { id: 13597, s: "688027.SS", n: "QuantumCTek", c: "CH" },
  { id: 13598, s: "000911.SZ", n: "Nanning Sugar Industry", c: "CH" },
  { id: 13599, s: "HGKGY", n: "Power Assets Holdings", c: "US" },
  { id: 13600, s: "000912.SZ", n: "Sichuan Lutianhua", c: "CH" },
  { id: 13601, s: "MSP.V", n: "Minaean SP Construction Co", c: "CA" },
  { id: 13602, s: "001120.KS", n: "LX International Co", c: "Korea" },
  { id: 13603, s: "8481.TW", n: "Transart Graphics", c: "TW" },
  { id: 13604, s: "PRSI", n: "Portsmouth Square Inc", c: "US" },
  { id: 13605, s: "SOL.WA", n: "Solar Co. S.A.", c: "Poland" },
  { id: 13606, s: "MZX.DE", n: "Masterflex SE", c: "GER" },
  { id: 13607, s: "ULKER.IS", n: "Ulker Biskuvi Sanayi AS", c: "Turkey" },
  { id: 13608, s: "3049.TW", n: "HannsTouch Solution Inc", c: "TW" },
  { id: 13609, s: "NUC.AX", n: "Nuchev", c: "Australia" },
  { id: 13610, s: "600213.SS", n: "Yangzhou Asiastar Bus", c: "CH" },
  { id: 13611, s: "BLZ.AX", n: "Blaze International", c: "Australia" },
  { id: 13612, s: "600246.SS", n: "Beijing Vantone Real Estate", c: "CH" },
  { id: 13613, s: "HDN.AX", n: "Homeco Daily Needs Reit", c: "Australia" },
  { id: 13614, s: "MUB.DE", n: "Mühlbauer Holding AG", c: "GER" },
  { id: 13615, s: "008060.KS", n: "Daeduck Co..", c: "Korea" },
  { id: 13616, s: "008770.KS", n: "Hotel Shilla", c: "Korea" },
  { id: 13617, s: "BCOMF", n: "B Communications", c: "US" },
  { id: 13618, s: "BRI.AX", n: "Big River Industries", c: "Australia" },
  { id: 13619, s: "000650.KS", n: "Chunil Express", c: "Korea" },
  { id: 13620, s: "000609.SZ", n: "Beijing Mainstreets Investment", c: "CH" },
  { id: 13621, s: "600633.SS", n: "Zhejiang Daily Media Group", c: "CH" },
  { id: 13622, s: "2208.TW", n: "CSBC Corp TW", c: "TW" },
  { id: 13623, s: "ADME.L", n: "Adm Energy PLC", c: "UK" },
  { id: 13624, s: "DRAL.TA", n: "Dor Alon", c: "Israel" },
  { id: 13625, s: "GVYM.TA", n: "GavYam Lands Co", c: "Israel" },
  { id: 13626, s: "SMB.F", n: "Schwälbchen Molkerei Jakob Berz AG", c: "GER" },
  { id: 13627, s: "000419.SZ", n: "Changsha Tongcheng Holdings", c: "CH" },
  { id: 13628, s: "000615.SZ", n: "Aoyuan Beauty Valley Technology", c: "CH" },
  { id: 13629, s: "KRONT.IS", n: "Kron Telekomunikasyon Hizmetleri AS", c: "Turkey" },
  { id: 13630, s: "HMAM.TA", n: "Hamama", c: "Israel" },
  { id: 13631, s: "000712.SZ", n: "Guangdong Golden Dragon Development Inc", c: "CH" },
  { id: 13632, s: "HRON.TA", n: "Hiron-Trade Investments & Industrial Buildings", c: "Israel" },
  { id: 13633, s: "HOD.TA", n: "Hod Assaf Industries", c: "Israel" },
  { id: 13634, s: "SGMD.V", n: "Salona Global Medical Device Co", c: "CA" },
  { id: 13635, s: "000711.SZ", n: "HeiLongJiang Kingland Technology", c: "CH" },
  { id: 13636, s: "000603.SZ", n: "Shengda Mining", c: "CH" },
  { id: 13637, s: "000821.SZ", n: "J.S. Corrugating Machinery", c: "CH" },
  { id: 13638, s: "TMOAY", n: "TomTom NV ADR", c: "US" },
  { id: 13639, s: "000605.SZ", n: "Bohai Water Industry", c: "CH" },
  { id: 13640, s: "GOSY", n: "GeckoSystems International Co", c: "US" },
  { id: 13641, s: "000607.SZ", n: "Huamei Holding", c: "CH" },
  { id: 13642, s: "NORBT.OL", n: "Norbit ASA", c: "Norway" },
  { id: 13643, s: "601515.SS", n: "Shantou Dongfeng Printing", c: "CH" },
  { id: 13644, s: "000710.SZ", n: "Berry Genomics", c: "CH" },
  { id: 13645, s: "2227.TW", n: "Yulon Nissan Motor", c: "TW" },
  { id: 13646, s: "000612.SZ", n: "Jiaozuo Wanfang Aluminum", c: "CH" },
  { id: 13647, s: "NSOL.OL", n: "Norsk Solar AS", c: "Norway" },
  { id: 13648, s: "6146.TWO", n: "Sporton International", c: "TW" },
  { id: 13649, s: "000608.SZ", n: "Yang Guang", c: "CH" },
  { id: 13650, s: "MXHN.F", n: "MAX Automation SE", c: "GER" },
  { id: 13651, s: "2211.TW", n: "Evergreen Steel Co", c: "TW" },
  { id: 13652, s: "TCHC", n: "Tech Central Inc", c: "US" },
  { id: 13653, s: "ART.L", n: "Artisanal Spirits Company PLC", c: "UK" },
  { id: 13654, s: "BNR.AX", n: "Bulletin Resources", c: "Australia" },
  { id: 13655, s: "HBSI", n: "Highlands Bankshares Inc", c: "US" },
  { id: 13656, s: "000686.SZ", n: "Northeast Securities", c: "CH" },
  { id: 13657, s: "000707.SZ", n: "Hubei Shuanghuan Science and Technology Stock", c: "CH" },
  { id: 13658, s: "KGYO.IS", n: "Yapi Kredi Koray Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 13659, s: "000668.SZ", n: "Rongfeng Holding Group", c: "CH" },
  { id: 13660, s: "ACP.L", n: "Armadale Capital Plc", c: "UK" },
  { id: 13661, s: "EMCO.TA", n: "E & M Computing", c: "Israel" },
  { id: 13662, s: "BIOM.L", n: "Biome Technologies Plc", c: "UK" },
  { id: 13663, s: "EMDV.TA", n: "Emilia Devel", c: "Israel" },
  { id: 13664, s: "ENDY.TA", n: "Endymed", c: "Israel" },
  { id: 13665, s: "200056.SZ", n: "Shenzhen Wongtee International Enterprise", c: "CH" },
  { id: 13666, s: "000638.SZ", n: "Vanfund Urban Investment & Development", c: "CH" },
  { id: 13667, s: "ABDX.L", n: "Abingdon Health Plc", c: "UK" },
  { id: 13668, s: "000685.SZ", n: "Zhongshan Public Utilities Group", c: "CH" },
  { id: 13669, s: "CPS.WA", n: "Cyfrowy Polsat SA", c: "Poland" },
  { id: 13670, s: "EQTL.TA", n: "Equital", c: "Israel" },
  { id: 13671, s: "BKS.L", n: "Beeks Trading Corporation", c: "UK" },
  { id: 13672, s: "JBK", n: "Goldman Sachs Capital I Securities-Backed Series 2004-6 Trust", c: "US" },
  { id: 13673, s: "000669.SZ", n: "PetroCH Jinhong Energy Investment", c: "CH" },
  { id: 13674, s: "6023.TWO", n: "Yuanta Futures", c: "TW" },
  { id: 13675, s: "BBSN.L", n: "Brave Bison Group PLC", c: "UK" },
  { id: 13676, s: "000676.SZ", n: "Genimous Technology", c: "CH" },
  { id: 13677, s: "EUR.WA", n: "Eurocash S.A.", c: "Poland" },
  { id: 13678, s: "4953.TWO", n: "Wistron Information Technology & Services", c: "TW" },
  { id: 13679, s: "THNK.V", n: "Think Research Corporation", c: "CA" },
  { id: 13680, s: "ELMR.TA", n: "El-Mor Electric Installation & Services 1986", c: "Israel" },
  { id: 13681, s: "000677.SZ", n: "CHTC Helon", c: "CH" },
  { id: 13682, s: "ELCO.TA", n: "El", c: "Israel" },
  { id: 13683, s: "000678.SZ", n: "Xiangyang Automobile Bearing", c: "CH" },
  { id: 13684, s: "LAPWALL.HE", n: "LapWall Oyj", c: "Finland" },
  { id: 13685, s: "600893.SS", n: "AECC Aviation Power", c: "CH" },
  { id: 13686, s: "000679.SZ", n: "Dalian Friendship Group", c: "CH" },
  { id: 13687, s: "HPI.AX", n: "Hotel Property Investments", c: "Australia" },
  { id: 13688, s: "CJREF", n: "Corus Entertainment Inc", c: "US" },
  { id: 13689, s: "000680.SZ", n: "Shantui Construction Machinery", c: "CH" },
  { id: 13690, s: "GOLF.TA", n: "Golf & Co Group", c: "Israel" },
  { id: 13691, s: "RG8.AX", n: "REGAL ASIAN INVESTMENTS LIMITED", c: "Australia" },
  { id: 13692, s: "000632.SZ", n: "Fujian Sanmu Group", c: "CH" },
  { id: 13693, s: "VCU.V", n: "Vizsla Copper Co", c: "CA" },
  { id: 13694, s: "P911.F", n: "Porsche AG", c: "GER" },
  { id: 13695, s: "GSFI.TA", n: "Gan Shmuel", c: "Israel" },
  { id: 13696, s: "000703.SZ", n: "Hengyi Petrochemical", c: "CH" },
  { id: 13697, s: "073640.KQ", n: "TERA SCIENCE Co.LTD", c: "Korea" },
  { id: 13698, s: "000622.SZ", n: "Hengli Industrial Development Group", c: "CH" },
  { id: 13699, s: "GOSS.TA", n: "G1 Secure Solutions", c: "Israel" },
  { id: 13700, s: "000623.SZ", n: "Jilin Aodong Pharmaceutical Group", c: "CH" },
  { id: 13701, s: "CAD.L", n: "Cadogan Petroleum plc", c: "UK" },
  { id: 13702, s: "RAC.AX", n: "Race Oncology", c: "Australia" },
  { id: 13703, s: "000702.SZ", n: "Hunan Zhenghong Science and Technology Develop", c: "CH" },
  { id: 13704, s: "073570.KQ", n: "WI Co..", c: "Korea" },
  { id: 13705, s: "ASND.TO", n: "Ascendant Resources Inc", c: "CA" },
  { id: 13706, s: "GMLA.TA", n: "Gamla Harel Residential Real-Estate", c: "Israel" },
  { id: 13707, s: "QRI.AX", n: "Qualitas Real Estate Income Fund", c: "Australia" },
  { id: 13708, s: "HCT.AX", n: "Holista CollTech", c: "Australia" },
  { id: 13709, s: "IPC.AX", n: "Imperial Pacific", c: "Australia" },
  { id: 13710, s: "600642.SS", n: "Shenergy", c: "CH" },
  { id: 13711, s: "000628.SZ", n: "ChengDu Hi-Tech Development", c: "CH" },
  { id: 13712, s: "000629.SZ", n: "Pangang Group Vanadium Titanium & Resources", c: "CH" },
  { id: 13713, s: "GNGR.TA", n: "Ginegar", c: "Israel" },
  { id: 13714, s: "000631.SZ", n: "Shunfa Hengye Co", c: "CH" },
  { id: 13715, s: "SII.TO", n: "Sprott Inc.", c: "CA" },
  { id: 13716, s: "BYOT.L", n: "Byotrol Plc", c: "UK" },
  { id: 13717, s: "600651.SS", n: "Shanghai Feilo Acoustics", c: "CH" },
  { id: 13718, s: "AHKSY", n: "Asahi Kaisei Co", c: "US" },
  { id: 13719, s: "QML.AX", n: "QMines", c: "Australia" },
  { id: 13720, s: "QIP.AX", n: "QANTM Intellectual Property", c: "Australia" },
  { id: 13721, s: "JNSH", n: "JNS Holdings Co", c: "US" },
  { id: 13722, s: "FW.V", n: "Flow Capital Co", c: "CA" },
  { id: 13723, s: "000682.SZ", n: "Dongfang Electronics", c: "CH" },
  { id: 13724, s: "000665.SZ", n: "Hubei Radio and Television Information Network", c: "CH" },
  { id: 13725, s: "BMK.L", n: "Benchmark Holdings Plc", c: "UK" },
  { id: 13726, s: "300110.SZ", n: "Huaren Pharmaceutical", c: "CH" },
  { id: 13727, s: "KKPNY", n: "Koninklijke KPN NV ADR", c: "US" },
  { id: 13728, s: "000567.SZ", n: "Hainan Haide Industry", c: "CH" },
  { id: 13729, s: "APTD.L", n: "Aptitude Software Group PLC", c: "UK" },
  { id: 13730, s: "JGH.AX", n: "Jade Gas Holdings", c: "Australia" },
  { id: 13731, s: "601555.SS", n: "Soochow Securities", c: "CH" },
  { id: 13732, s: "000595.SZ", n: "Baota Industry", c: "CH" },
  { id: 13733, s: "000823.SZ", n: "Guangdong Goworld", c: "CH" },
  { id: 13734, s: "GDI.AX", n: "GDI Property Group", c: "Australia" },
  { id: 13735, s: "MTU.PA", n: "Manitou BF S.A.", c: "FR" },
  { id: 13736, s: "000593.SZ", n: "Delong Composite Energy Group", c: "CH" },
  { id: 13737, s: "CNC.L", n: "Concurrent Technologies Plc", c: "UK" },
  { id: 13738, s: "5245.TWO", n: "WiseChip Semiconductor", c: "TW" },
  { id: 13739, s: "000592.SZ", n: "Zhongfu Straits Pingtan Development", c: "CH" },
  { id: 13740, s: "000713.SZ", n: "Hefei Fengle Seed", c: "CH" },
  { id: 13741, s: "API.L", n: "abrdn Property Income Trust.", c: "UK" },
  { id: 13742, s: "GDG.AX", n: "Generation Development Group", c: "Australia" },
  { id: 13743, s: "000715.SZ", n: "Zhongxing Shenyang Commercial Building Group", c: "CH" },
  { id: 13744, s: "RIC.AX", n: "Ridley Corporation", c: "Australia" },
  { id: 13745, s: "CPC.L", n: "City Pub Group PLC", c: "UK" },
  { id: 13746, s: "IES.TA", n: "I.E.S Holdings", c: "Israel" },
  { id: 13747, s: "IDMO.TA", n: "Idomoo", c: "Israel" },
  { id: 13748, s: "000597.SZ", n: "Northeast Pharmaceutical Group", c: "CH" },
  { id: 13749, s: "ICON.TA", n: "Icon Group", c: "Israel" },
  { id: 13750, s: "MAS.OL", n: "Masoval AS", c: "Norway" },
  { id: 13751, s: "000598.SZ", n: "Chengdu Xingrong Investment", c: "CH" },
  { id: 13752, s: "RGS.AX", n: "Regeneus", c: "Australia" },
  { id: 13753, s: "600682.SS", n: "Nanjing Xinjiekou Department Store", c: "CH" },
  { id: 13754, s: "EXPO.TA", n: "Export Inv", c: "Israel" },
  { id: 13755, s: "CAFO.PA", n: "Centrale d'Achat Française pour l'Outre-Mer Société Anonyme", c: "FR" },
  { id: 13756, s: "000663.SZ", n: "Fujian Yongan Forestry Group Joint-Stock", c: "CH" },
  { id: 13757, s: "000692.SZ", n: "Shenyang Huitian Thermal Power", c: "CH" },
  { id: 13758, s: "BIOBV.HE", n: "Biohit Oyj B", c: "Finland" },
  { id: 13759, s: "TBRD.V", n: "Thunderbird Entertainment Group Inc", c: "CA" },
  { id: 13760, s: "ASPO.HE", n: "Aspo Oyj", c: "Finland" },
  { id: 13761, s: "EPL.V", n: "Eagle Plains Resources.", c: "CA" },
  { id: 13762, s: "BPM.L", n: "B P Marsh and Partners PLC", c: "UK" },
  { id: 13763, s: "JJ.V", n: "Jackpot Digital Inc", c: "CA" },
  { id: 13764, s: "ALBBV.HE", n: "Alandsbanken Abp B", c: "Finland" },
  { id: 13765, s: "IIQ.AX", n: "Inoviq", c: "Australia" },
  { id: 13766, s: "6111.TWO", n: "Softstar Entertainment", c: "TW" },
  { id: 13767, s: "FTAL.TA", n: "Fattal 1998 Holdings", c: "Israel" },
  { id: 13768, s: "000691.SZ", n: "Gansu Yatai Industrial Development", c: "CH" },
  { id: 13769, s: "600679.SS", n: "Shanghai Phoenix Enterprise Group Co A", c: "CH" },
  { id: 13770, s: "CMGO", n: "CMG Holdings Group Inc", c: "US" },
  { id: 13771, s: "KSN.AX", n: "Kingston Resources", c: "Australia" },
  { id: 13772, s: "KMPUR.IS", n: "Kimteks Poliuretan Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 13773, s: "6147.TWO", n: "Chipbond Technology", c: "TW" },
  { id: 13774, s: "000655.SZ", n: "Shandong Jinling Mining", c: "CH" },
  { id: 13775, s: "000659.SZ", n: "Zhuhai Zhongfu Enterprise", c: "CH" },
  { id: 13776, s: "FGAS.TA", n: "Fire & Gas Detection Technologies", c: "Israel" },
  { id: 13777, s: "000812.SZ", n: "Shaanxi Jinye Science Technology and Education Group", c: "CH" },
  { id: 13778, s: "RBGPF", n: "Reckitt Benckiser Group PLC", c: "US" },
  { id: 13779, s: "000688.SZ", n: "Guocheng Mining", c: "CH" },
  { id: 13780, s: "1711.TW", n: "Everlight Chemical Industrial Co", c: "TW" },
  { id: 13781, s: "IGIFF", n: "IGM Financial Inc.", c: "US" },
  { id: 13782, s: "058470.KQ", n: "LEENO Industrial Inc", c: "Korea" },
  { id: 13783, s: "601006.SS", n: "Daqin Railway", c: "CH" },
  { id: 13784, s: "SBS.F", n: "Stratec SE", c: "GER" },
  { id: 13785, s: "000811.SZ", n: "Yantai Moon", c: "CH" },
  { id: 13786, s: "4714.TWO", n: "U-Best Polymer Industry", c: "TW" },
  { id: 13787, s: "MORG.OL", n: "Sparebanken Møre", c: "Norway" },
  { id: 13788, s: "FPH.DE", n: "Francotyp-Postalia Holding AG", c: "GER" },
  { id: 13789, s: "TMT.AX", n: "Technology Metals Australia", c: "Australia" },
  { id: 13790, s: "AZ.V", n: "A2Z Smart Technologies Co", c: "CA" },
  { id: 13791, s: "6191.TW", n: "Global Brands Manufacture", c: "TW" },
  { id: 13792, s: "CNR.L", n: "Condor Gold Plc", c: "UK" },
  { id: 13793, s: "CRLO.PA", n: "Caisse Regionale de Credit Agricole Mutuel Loire HauteLoire", c: "FR" },
  { id: 13794, s: "CODE.L", n: "Northcoders Group PLC", c: "UK" },
  { id: 13795, s: "DRV.L", n: "Driver Group", c: "UK" },
  { id: 13796, s: "COG.L", n: "Cambridge Cognition Holdings Plc", c: "UK" },
  { id: 13797, s: "9938.TW", n: "Taiwan Paiho", c: "TW" },
  { id: 13798, s: "HDP.PA", n: "Les Hôtels de Paris SA", c: "FR" },
  { id: 13799, s: "CROS.PA", n: "Crosswood", c: "FR" },
  { id: 13800, s: "TTT.AX", n: "Titomic", c: "Australia" },
  { id: 13801, s: "AVCR.V", n: "Avricore Health Inc", c: "CA" },
  { id: 13802, s: "HCO.PA", n: "High Co. SA", c: "FR" },
  { id: 13803, s: "GSHP3.SA", n: "General Shopping e Outlets do Brasil S.A.", c: "Brazil" },
  { id: 13804, s: "9937.TW", n: "National Petroleum", c: "TW" },
  { id: 13805, s: "4721.TWO", n: "Mechema Chemicals Int", c: "TW" },
  { id: 13806, s: "3178.TWO", n: "Gongin Precision Industrial", c: "TW" },
  { id: 13807, s: "CARA.OL", n: "Carasent ASA", c: "Norway" },
  { id: 13808, s: "BMA.OL", n: "Byggma", c: "Norway" },
  { id: 13809, s: "DEMG.L", n: "Deltex Medical Group", c: "UK" },
  { id: 13810, s: "JIM.L", n: "Jarvis Securities", c: "UK" },
  { id: 13811, s: "9907.TW", n: "Ton Yi Industrial Co", c: "TW" },
  { id: 13812, s: "SOLV.BR", n: "Solvac S.A.", c: "Belgium" },
  { id: 13813, s: "JMF.L", n: "JPMorgan Mid Cap Investment Trust", c: "UK" },
  { id: 13814, s: "JII.L", n: "JPMorgan Indian Inv Trust", c: "UK" },
  { id: 13815, s: "TPC.AX", n: "TPC Consolidated", c: "Australia" },
  { id: 13816, s: "VBC.AX", n: "Verbrec", c: "Australia" },
  { id: 13817, s: "NPTH.V", n: "NeuPath Health Inc", c: "CA" },
  { id: 13818, s: "FOR.V", n: "Fortune Bay Co", c: "CA" },
  { id: 13819, s: "NVX.V", n: "NV Gold Co", c: "CA" },
  { id: 13820, s: "CBDY", n: "Target Group Inc", c: "US" },
  { id: 13821, s: "2702.TW", n: "Hotel Holiday Garden", c: "TW" },
  { id: 13822, s: "GOODY.IS", n: "Goodyear Lastikleri TAS", c: "Turkey" },
  { id: 13823, s: "VID.L", n: "Videndum Plc", c: "UK" },
  { id: 13824, s: "LOOK.L", n: "Lookers PLC", c: "UK" },
  { id: 13825, s: "2231.TW", n: "Cub Elecparts Inc", c: "TW" },
  { id: 13826, s: "RPX.V", n: "Red Pine Exploration Inc", c: "CA" },
  { id: 13827, s: "MUX.F", n: "Mutares SE & Co. KGaA", c: "GER" },
  { id: 13828, s: "VIC.L", n: "Victorian Plumbing Group PLC", c: "UK" },
  { id: 13829, s: "VDTK.L", n: "Verditek PLC", c: "UK" },
  { id: 13830, s: "8131.TW", n: "Formosa Advanced Technologies", c: "TW" },
  { id: 13831, s: "INFREA.ST", n: "Infrea AB", c: "Sweden" },
  { id: 13832, s: "INDU-C.ST", n: "Industrivarden AB ser. C", c: "Sweden" },
  { id: 13833, s: "RBX.V", n: "Res Robex Inc", c: "CA" },
  { id: 13834, s: "RESURS.ST", n: "Resurs Holding AB", c: "Sweden" },
  { id: 13835, s: "OZM.AX", n: "Ozaurum Resources", c: "Australia" },
  { id: 13836, s: "BONTQ", n: "Bon-Ton Stores Inc", c: "US" },
  { id: 13837, s: "MLETA.PA", n: "Metalliance SA", c: "FR" },
  { id: 13838, s: "3085.TWO", n: "Newretail", c: "TW" },
  { id: 13839, s: "HXCK.DE", n: "Ernst Russ AG", c: "GER" },
  { id: 13840, s: "3081.TWO", n: "LandMark Optoelectronics", c: "TW" },
  { id: 13841, s: "KBT.L", n: "K3 Business Technology Group PLC", c: "UK" },
  { id: 13842, s: "4945.TWO", n: "Sentelic Co", c: "TW" },
  { id: 13843, s: "INSTA.OL", n: "Instabank ASA", c: "Norway" },
  { id: 13844, s: "MWB.DE", n: "mwb fairtrade Wertpapierhandelsbank AG", c: "GER" },
  { id: 13845, s: "AI.MC", n: "Airtificial Intelligence Structures SA", c: "Spain" },
  { id: 13846, s: "QFG.BR", n: "Quest For Growth - Pricaf", c: "Belgium" },
  { id: 13847, s: "4417.TWO", n: "King Chou Marine Technology", c: "TW" },
  { id: 13848, s: "NU.V", n: "NeutriSci International Inc", c: "CA" },
  { id: 13849, s: "G1A.F", n: "GEA Group Aktiengesellschaft", c: "GER" },
  { id: 13850, s: "STATT.OL", n: "Statt Torsk AS", c: "Norway" },
  { id: 13851, s: "3169.TWO", n: "ASIX Electronics", c: "TW" },
  { id: 13852, s: "ARSAN.IS", n: "Arsan Tekstil Ticaret ve Sanayi AS", c: "Turkey" },
  { id: 13853, s: "HLE.F", n: "Hella KGaA Hueck &", c: "GER" },
  { id: 13854, s: "BKHT.DE", n: "Brockhaus Capital Mgmt AG", c: "GER" },
  { id: 13855, s: "IVPU.L", n: "Invesco Perpetual UK Equity", c: "UK" },
  { id: 13856, s: "GRG.V", n: "Golden Arrow Resources Co", c: "CA" },
  { id: 13857, s: "BIJ.DE", n: "Bijou Brigitte modische Accessoires Aktiengesellschaft", c: "GER" },
  { id: 13858, s: "ALSTW.PA", n: "Streamwide", c: "FR" },
  { id: 13859, s: "SCT.AX", n: "Scout Security", c: "Australia" },
  { id: 13860, s: "5904.TWO", n: "Poya International", c: "TW" },
  { id: 13861, s: "5534.TW", n: "Chong Hong Construction", c: "TW" },
  { id: 13862, s: "PYC.AX", n: "PYC Therapeutics", c: "Australia" },
  { id: 13863, s: "ELLA.L", n: "Ecclesiastical Insurance Office plc", c: "UK" },
  { id: 13864, s: "MANTEX.ST", n: "Mantex AB", c: "Sweden" },
  { id: 13865, s: "REJL-B.ST", n: "Rejlers AB (publ)", c: "Sweden" },
  { id: 13866, s: "6153.TW", n: "Career Technology MFG", c: "TW" },
  { id: 13867, s: "ALTHO.PA", n: "Metavisio (Thomson Computing)", c: "FR" },
  { id: 13868, s: "GDP.V", n: "Golden Pursuit Resources", c: "CA" },
  { id: 13869, s: "3228.TWO", n: "RDC Semiconductor", c: "TW" },
  { id: 13870, s: "PAN.V", n: "Pangolin Diamonds Corp.", c: "CA" },
  { id: 13871, s: "BKQNY", n: "Bank Of Queensland ADR", c: "US" },
  { id: 13872, s: "TWD.AX", n: "Tamawood", c: "Australia" },
  { id: 13873, s: "AMZ.V", n: "Azucar Minerals", c: "CA" },
  { id: 13874, s: "POLY.ST", n: "PolyPlank publ AB", c: "Sweden" },
  { id: 13875, s: "EFF.DE", n: "Deutsche Effecten- und Wechsel-Beteiligungsgesellschaft AG", c: "GER" },
  { id: 13876, s: "REG.AX", n: "Regis Healthcare", c: "Australia" },
  { id: 13877, s: "AMX.V", n: "Amex Exploration Inc", c: "CA" },
  { id: 13878, s: "GLYHO.IS", n: "Global Yatirim Holding AS", c: "Turkey" },
  { id: 13879, s: "PROB.ST", n: "Probi AB", c: "Sweden" },
  { id: 13880, s: "EBE.DE", n: "aifinyo AG", c: "GER" },
  { id: 13881, s: "5607.TW", n: "Farglory FTZ Investment Holding", c: "TW" },
  { id: 13882, s: "MILDEF.ST", n: "MilDef Group AB", c: "Sweden" },
  { id: 13883, s: "NTEK-B.ST", n: "Novotek AB", c: "Sweden" },
  { id: 13884, s: "ISHA.DE", n: "INTERSHOP Communications Aktiengesellschaft", c: "GER" },
  { id: 13885, s: "PAX.ST", n: "Paxman AB", c: "Sweden" },
  { id: 13886, s: "4743.TWO", n: "Oneness Biotech", c: "TW" },
  { id: 13887, s: "3141.TWO", n: "Ultra Chip", c: "TW" },
  { id: 13888, s: "EOS.PA", n: "ACTEOS S.A", c: "FR" },
  { id: 13889, s: "CSH.F", n: "CENIT Aktiengesellschaft", c: "GER" },
  { id: 13890, s: "4513.TWO", n: "Falcon Machine Tools", c: "TW" },
  { id: 13891, s: "6269.TW", n: "Flexium Interconnect Inc", c: "TW" },
  { id: 13892, s: "ELIS.PA", n: "Elis SA", c: "FR" },
  { id: 13893, s: "LDF.DE", n: "Linus Digital Finance AG", c: "GER" },
  { id: 13894, s: "JUST.L", n: "Just Group plc", c: "UK" },
  { id: 13895, s: "DNM.L", n: "Dianomi PLC", c: "UK" },
  { id: 13896, s: "MTB.V", n: "Mountain Boy Minerals", c: "CA" },
  { id: 13897, s: "6271.TW", n: "Tong Hsing Electronic Industries", c: "TW" },
  { id: 13898, s: "TUA.AX", n: "Tuas Ltd", c: "Australia" },
  { id: 13899, s: "DAM.F", n: "DATA MODUL Aktiengesellschaft Produktion und Vertrieb von elektronischen Systemen", c: "GER" },
  { id: 13900, s: "TVL.AX", n: "Touch Ventures", c: "Australia" },
  { id: 13901, s: "ASPZ", n: "Asia Pptys Inc", c: "US" },
  { id: 13902, s: "AASB.OL", n: "Aasen Sparebank", c: "Norway" },
  { id: 13903, s: "ECR.L", n: "ECR Minerals plc", c: "UK" },
  { id: 13904, s: "2417.TW", n: "AVerMedia Technologies Inc", c: "TW" },
  { id: 13905, s: "2414.TW", n: "Unitech Computer", c: "TW" },
  { id: 13906, s: "CARR.L", n: "Carr's Group plc", c: "UK" },
  { id: 13907, s: "CAM.L", n: "Camellia Plc", c: "UK" },
  { id: 13908, s: "ATSYH.IS", n: "Atlantis Yatirim Holding AS", c: "Turkey" },
  { id: 13909, s: "5464.TWO", n: "Lin Horn Technology", c: "TW" },
  { id: 13910, s: "HEN3.F", n: "Henkel AG & Co. KGaA vz. (Pref Shares)", c: "GER" },
  { id: 13911, s: "2850.TW", n: "Shinkong Insurance", c: "TW" },
  { id: 13912, s: "ADD.V", n: "Arctic Star Exploration Co", c: "CA" },
  { id: 13913, s: "IUR.DE", n: "KAP AG", c: "GER" },
  { id: 13914, s: "BRKSN.IS", n: "Berkosan Yalitim ve Tecrit Maddeleri Uretim ve Ticaret AS", c: "Turkey" },
  { id: 13915, s: "RKV.V", n: "Rakovina Therapeutics Inc", c: "CA" },
  { id: 13916, s: "6276.TWO", n: "Antec Inc", c: "TW" },
  { id: 13917, s: "6180.TWO", n: "Gamania Digital Entertainment", c: "TW" },
  { id: 13918, s: "GLA1V.HE", n: "Glaston Oyj Abp", c: "Finland" },
  { id: 13919, s: "LSX.F", n: "LS telcom AG", c: "GER" },
  { id: 13920, s: "ITX.L", n: "Itaconix plc", c: "UK" },
  { id: 13921, s: "2340.TW", n: "Optotech Co", c: "TW" },
  { id: 13922, s: "HONBS.HE", n: "Honkarakenne Oyj B", c: "Finland" },
  { id: 13923, s: "IFA1V.HE", n: "Innofactor Oyj", c: "Finland" },
  { id: 13924, s: "BIOG.L", n: "The Biotech Growth Trust PLC", c: "UK" },
  { id: 13925, s: "PPC.AX", n: "Peet", c: "Australia" },
  { id: 13926, s: "2329.TW", n: "Orient Semiconductor Electronics", c: "TW" },
  { id: 13927, s: "6204.TWO", n: "Taiwan Alpha Electronic", c: "TW" },
  { id: 13928, s: "MNOW.V", n: "Mednow Inc", c: "CA" },
  { id: 13929, s: "SGA.AX", n: "Sarytogan Graphite", c: "Australia" },
  { id: 13930, s: "CAV1V.HE", n: "Caverion Ord", c: "Finland" },
  { id: 13931, s: "LFI.L", n: "London Finance and Investment Group PLC", c: "UK" },
  { id: 13932, s: "RLIA.MC", n: "Realia", c: "Spain" },
  { id: 13933, s: "BRCK.L", n: "Brickability Group Plc", c: "UK" },
  { id: 13934, s: "8341.TW", n: "Sunny Friend Environmental Technology", c: "TW" },
  { id: 13935, s: "4128.TWO", n: "Microbio", c: "TW" },
  { id: 13936, s: "CRHKY", n: "CH Resources Beer Holdings Co ADR", c: "US" },
  { id: 13937, s: "ALPRI.PA", n: "Prismaflex Intl", c: "FR" },
  { id: 13938, s: "5234.TW", n: "Daxin Materials Co", c: "TW" },
  { id: 13939, s: "AMNL", n: "Applied Minerals Inc", c: "US" },
  { id: 13940, s: "NHH.MC", n: "NH Hoteles", c: "Spain" },
  { id: 13941, s: "HOMEX.MX", n: "Desarrolladora Homex S.A.B. de C.V", c: "Mexi" },
  { id: 13942, s: "SPG.", n: "SP Group A/S", c: "Denmark" },
  { id: 13943, s: "LMKG.ST", n: "LMK Group AB", c: "Sweden" },
  { id: 13944, s: "NXT.MC", n: "Nueva Expresión Textil S.A.", c: "Spain" },
  { id: 13945, s: "VLS.AX", n: "Vita Life Sciences", c: "Australia" },
  { id: 13946, s: "ALLUX.PA", n: "Installux", c: "FR" },
  { id: 13947, s: "AJ91.DE", n: "DocCheck AG", c: "GER" },
  { id: 13948, s: "LEC.F", n: "Lechwerke AG", c: "GER" },
  { id: 13949, s: "ALST", n: "Allstar Health Brands Inc", c: "US" },
  { id: 13950, s: "TZL.AX", n: "TZ", c: "Australia" },
  { id: 13951, s: "101170.KQ", n: "Woorim Machinery Co.", c: "Korea" },
  { id: 13952, s: "601866.SS", n: "Cosco Shipping Development", c: "CH" },
  { id: 13953, s: "FAU.AX", n: "First Au", c: "Australia" },
  { id: 13954, s: "100030.KQ", n: "Mobileleader Co.Ltd", c: "Korea" },
  { id: 13955, s: "FDV.AX", n: "Frontier Digital Ventures", c: "Australia" },
  { id: 13956, s: "VSPC", n: "Viaspace Inc", c: "US" },
  { id: 13957, s: "111110.KS", n: "Hojeon", c: "Korea" },
  { id: 13958, s: "FFG.AX", n: "Fatfish Internet Group", c: "Australia" },
  { id: 13959, s: "FFI.AX", n: "FFI Holdings", c: "Australia" },
  { id: 13960, s: "FFT.AX", n: "Future First Technologies Limited", c: "Australia" },
  { id: 13961, s: "600371.SS", n: "Wanxiang Doneed", c: "CH" },
  { id: 13962, s: "000961.SZ", n: "Jiangsu Zhongnan Construction Group", c: "CH" },
  { id: 13963, s: "HBM.HM", n: "HORNBACH Baumarkt AG", c: "GER" },
  { id: 13964, s: "SCAPE.", n: "Scape Technologies A/S", c: "Denmark" },
  { id: 13965, s: "ALMRB.PA", n: "Mr Bricolage", c: "FR" },
  { id: 13966, s: "SCO.MC", n: "Seresco S.A.", c: "Spain" },
  { id: 13967, s: "2363.TW", n: "Silicon Integrated Systems Co", c: "TW" },
  { id: 13968, s: "EEZY.HE", n: "Eezy PLC", c: "Finland" },
  { id: 13969, s: "8222.TW", n: "Aero Win Technology Co", c: "TW" },
  { id: 13970, s: "GBMO.MX", n: "Corporativo GBM S.A.B. de C.V", c: "Mexi" },
  { id: 13971, s: "6161.TWO", n: "Jetway Information", c: "TW" },
  { id: 13972, s: "ALTUU", n: "Altitude Acquisition Co", c: "US" },
  { id: 13973, s: "VPR.AX", n: "Volt Power Group", c: "Australia" },
  { id: 13974, s: "EGEPO.IS", n: "Nasmed Ozel Saglik Hizmetleri Ticaret AS", c: "Turkey" },
  { id: 13975, s: "8213.TW", n: "Taiwan Printed Circuit Board Techvest", c: "TW" },
  { id: 13976, s: "2855.TW", n: "President Securities Co", c: "TW" },
  { id: 13977, s: "HGT.L", n: "HgCapital Trust PLC", c: "UK" },
  { id: 13978, s: "BSEG", n: "Big Screen Entertainment Group Inc", c: "US" },
  { id: 13979, s: "BUCIM.IS", n: "Bursa Cimento Fabrikasi AS", c: "Turkey" },
  { id: 13980, s: "ZPHR.L", n: "Zephyr Energy PLC", c: "UK" },
  { id: 13981, s: "4915.TW", n: "Primax Electronics", c: "TW" },
  { id: 13982, s: "FIH.L", n: "FIH Group PLC", c: "UK" },
  { id: 13983, s: "WSR.AX", n: "Westar Resources Ltd", c: "Australia" },
  { id: 13984, s: "SCOUT.ST", n: "Scout Gaming Group AB", c: "Sweden" },
  { id: 13985, s: "2031.TW", n: "Hsin Kuang Steel", c: "TW" },
  { id: 13986, s: "WWG.AX", n: "Wiseway Group", c: "Australia" },
  { id: 13987, s: "ORTI-B.ST", n: "Ortivus AB ser. B", c: "Sweden" },
  { id: 13988, s: "ALCYB.PA", n: "Cybergun SA", c: "FR" },
  { id: 13989, s: "ARC.L", n: "Arcontech Group Plc", c: "UK" },
  { id: 13990, s: "ANGS.L", n: "Angus Energy PLC", c: "UK" },
  { id: 13991, s: "ALCOG.PA", n: "Cogra 48 Société Anonyme", c: "FR" },
  { id: 13992, s: "2028.TW", n: "Wei Chih Steel Industrial", c: "TW" },
  { id: 13993, s: "EOAN.F", n: "E.ON SE", c: "GER" },
  { id: 13994, s: "ATY.L", n: "Athelney Trust plc", c: "UK" },
  { id: 13995, s: "ALHGR.PA", n: "Hoffmann Green Cement Technologies", c: "FR" },
  { id: 13996, s: "ALDLS.PA", n: "DLSI", c: "FR" },
  { id: 13997, s: "RVLY.V", n: "Rivalry Co", c: "CA" },
  { id: 13998, s: "WIN.L", n: "Wincanton", c: "UK" },
  { id: 13999, s: "ALGEN.PA", n: "Genoway", c: "FR" },
  { id: 14000, s: "VIH1.DE", n: "VIB Vermögen AG", c: "GER" },
  { id: 14001, s: "JOBS.ST", n: "Job Solution Sweden Holding AB", c: "Sweden" },
  { id: 14002, s: "OKDAV.HE", n: "Oriola-KD Oyj A", c: "Finland" },
  { id: 14003, s: "WAK.AX", n: "WA Kaolin", c: "Australia" },
  { id: 14004, s: "2504.TW", n: "Goldsun Building Materials", c: "TW" },
  { id: 14005, s: "WAT.AX", n: "Water", c: "Australia" },
  { id: 14006, s: "SIX3.DE", n: "Sixt SE", c: "GER" },
  { id: 14007, s: "GSTC", n: "AngioSoma Inc", c: "US" },
  { id: 14008, s: "SAXG.ST", n: "Saxlund Group AB", c: "Sweden" },
  { id: 14009, s: "FKYS", n: "First Keystone Co", c: "US" },
  { id: 14010, s: "LASP.", n: "Laan & Spar Bank AS", c: "Denmark" },
  { id: 14011, s: "2034.TW", n: "YC Inox", c: "TW" },
  { id: 14012, s: "IUR.F", n: "KAP AG", c: "GER" },
  { id: 14013, s: "WRKS.L", n: "Works co uk PLC", c: "UK" },
  { id: 14014, s: "TAV.HM", n: "Trade & Value AG", c: "GER" },
  { id: 14015, s: "FONU", n: "Fonu2 Inc", c: "US" },
  { id: 14016, s: "SKEL.IC", n: "Skel fjárfestingafélag hf.", c: "Iceland" },
  { id: 14017, s: "GSP.V", n: "Gensource Potash Corporation", c: "CA" },
  { id: 14018, s: "6138.TWO", n: "Anpec Electronics", c: "TW" },
  { id: 14019, s: "6140.TWO", n: "Dimension Computer Technology", c: "TW" },
  { id: 14020, s: "FSRXU", n: "FinServ Acquisition II Co", c: "US" },
  { id: 14021, s: "MLBAT.PA", n: "Batla Minerals SA", c: "FR" },
  { id: 14022, s: "DOHOL.IS", n: "Dogan Sirketler Grubu Holding AS", c: "Turkey" },
  { id: 14023, s: "IUG.L", n: "Intelligent Ultrasound Group PLC", c: "UK" },
  { id: 14024, s: "VMM.AX", n: "Viridis Mining and Minerals", c: "Australia" },
  { id: 14025, s: "KREATE.HE", n: "Kreate Group Oyj", c: "Finland" },
  { id: 14026, s: "PSAN.DE", n: "PSI Software AG", c: "GER" },
  { id: 14027, s: "IMPC.ST", n: "Impact Coatings publ AB", c: "Sweden" },
  { id: 14028, s: "WOT.AX", n: "Wotso Property", c: "Australia" },
  { id: 14029, s: "TRD.L", n: "Triad Group PLC", c: "UK" },
  { id: 14030, s: "2613.TW", n: "CH Container Terminal Co", c: "TW" },
  { id: 14031, s: "NDA.F", n: "Aurubis AG", c: "GER" },
  { id: 14032, s: "SCOT.V", n: "Scottie Resources Co", c: "CA" },
  { id: 14033, s: "SOLTEQ.HE", n: "Solteq PLC", c: "Finland" },
  { id: 14034, s: "SSABAH.HE", n: "SSAB AB ser. A", c: "Finland" },
  { id: 14035, s: "GIFX", n: "Gifa Inc", c: "US" },
  { id: 14036, s: "IOM.L", n: "iomart Group plc", c: "UK" },
  { id: 14037, s: "WKT.AX", n: "Walkabout Resources", c: "Australia" },
  { id: 14038, s: "TND.L", n: "Tandem Group", c: "UK" },
  { id: 14039, s: "2102.TW", n: "Federal Co", c: "TW" },
  { id: 14040, s: "4755.TW", n: "San Fu Chemical", c: "TW" },
  { id: 14041, s: "8070.TW", n: "Chang Wah Electromaterials Inc", c: "TW" },
  { id: 14042, s: "CHF.L", n: "Chesterfield Resources PLC", c: "UK" },
  { id: 14043, s: "AZNCF", n: "AstraZeneca PLC", c: "US" },
  { id: 14044, s: "AKBM.OL", n: "Aker Biomarine AS", c: "Norway" },
  { id: 14045, s: "GDST", n: "Goldenstone Acquisition", c: "US" },
  { id: 14046, s: "ALBKK.PA", n: "Baikowski SASU", c: "FR" },
  { id: 14047, s: "2520.TW", n: "Kindom Construction Co", c: "TW" },
  { id: 14048, s: "TRPL3.SA", n: "CTEEP - Companhia de Transmissão de Energia Elétrica Paulista S.A", c: "Brazil" },
  { id: 14049, s: "OMX.AX", n: "Orange Minerals NL", c: "Australia" },
  { id: 14050, s: "2608.TW", n: "Kerry TJ Logistics", c: "TW" },
  { id: 14051, s: "2891B.TW", n: "CTBC Financial Holding Co Pref", c: "TW" },
  { id: 14052, s: "HENOY", n: "Henkel Ag A", c: "US" },
  { id: 14053, s: "JCFN.TA", n: "Jacob Finance And Investments", c: "Israel" },
  { id: 14054, s: "1726.TW", n: "Yung Chi Paint & Varnish Mfg", c: "TW" },
  { id: 14055, s: "SPE.L", n: "Sopheon plc", c: "UK" },
  { id: 14056, s: "4142.TW", n: "Adimmune Co", c: "TW" },
  { id: 14057, s: "AAS.L", n: "Abrdn Asia Focus PLC", c: "UK" },
  { id: 14058, s: "1773.TW", n: "Shiny Chemical Industrial", c: "TW" },
  { id: 14059, s: "TBK.V", n: "Trailbreaker Resources", c: "CA" },
  { id: 14060, s: "GABR.", n: "Gabriel Holding", c: "Denmark" },
  { id: 14061, s: "1903.TW", n: "Shihlin Paper Co", c: "TW" },
  { id: 14062, s: "BCUR.TA", n: "Erika Carmel", c: "Israel" },
  { id: 14063, s: "INSG.L", n: "Insig Ai PLC", c: "UK" },
  { id: 14064, s: "FLUG-B.", n: "Flügger group A/S", c: "Denmark" },
  { id: 14065, s: "TKU.V", n: "Tarku Resources", c: "CA" },
  { id: 14066, s: "BRAN.TA", n: "Baran Group", c: "Israel" },
  { id: 14067, s: "XRF.AX", n: "XRF Scientific", c: "Australia" },
  { id: 14068, s: "PUM.F", n: "PUMA SE", c: "GER" },
  { id: 14069, s: "3317.TWO", n: "Niko Semiconductor", c: "TW" },
  { id: 14070, s: "ALALO.PA", n: "Acheter Louer", c: "FR" },
  { id: 14071, s: "2881B.TW", n: "Fubon Financial Holding Co Pref B", c: "TW" },
  { id: 14072, s: "2881A.TW", n: "Fubon Financial Holding Co Pref", c: "TW" },
  { id: 14073, s: "1808.TW", n: "Run Long Construction", c: "TW" },
  { id: 14074, s: "AGL.L", n: "ANGLE plc", c: "UK" },
  { id: 14075, s: "HMGS.TA", n: "Homebiogas", c: "Israel" },
  { id: 14076, s: "STS.L", n: "Securities Trust of Scotland plc", c: "UK" },
  { id: 14077, s: "4739.TW", n: "Coremax Co", c: "TW" },
  { id: 14078, s: "VOLO.ST", n: "Volati AB", c: "Sweden" },
  { id: 14079, s: "CWB-PD.TO", n: "Canadian Western Bank", c: "CA" },
  { id: 14080, s: "STK.AX", n: "Strickland Metals", c: "Australia" },
  { id: 14081, s: "L-PB.TO", n: "Loblaw Companies", c: "CA" },
  { id: 14082, s: "3552.TWO", n: "Tung Thih Electronic", c: "TW" },
  { id: 14083, s: "AERO.ST", n: "Svenska Aerogel Holding AB (publ)", c: "Sweden" },
  { id: 14084, s: "CE.ST", n: "Cortus Energy AB (publ)", c: "Sweden" },
  { id: 14085, s: "CDON.ST", n: "CDON AB", c: "Sweden" },
  { id: 14086, s: "CATE.ST", n: "Catena AB", c: "Sweden" },
  { id: 14087, s: "1225.TW", n: "Formosa Oilseed Processing", c: "TW" },
  { id: 14088, s: "MARV.V", n: "Marvel Discovery Co", c: "CA" },
  { id: 14089, s: "SDG.PA", n: "Synergie SE", c: "FR" },
  { id: 14090, s: "DTCWY", n: "Deutsche Wohnen SE ADR", c: "US" },
  { id: 14091, s: "MSBR34.SA", n: "Morgan Stanley", c: "Brazil" },
  { id: 14092, s: "VTR.PA", n: "Vitura S.A.", c: "FR" },
  { id: 14093, s: "2515.TW", n: "BES Engineering", c: "TW" },
  { id: 14094, s: "4549.TWO", n: "FineTek", c: "TW" },
  { id: 14095, s: "SPI.VI", n: "S IMMO AG", c: "Austria" },
  { id: 14096, s: "FOX.TA", n: "Fox-Wizel", c: "Israel" },
  { id: 14097, s: "HILL.V", n: "Hill Street Beverage Company Inc", c: "CA" },
  { id: 14098, s: "2511.TW", n: "Prince Housing & Development Co", c: "TW" },
  { id: 14099, s: "CEW3.MU", n: "HPI AG", c: "GER" },
  { id: 14100, s: "MOLN.SW", n: "Molecular Partners AG", c: "Switzerland" },
  { id: 14101, s: "MIND.L", n: "Mind Gym", c: "UK" },
  { id: 14102, s: "CBKCQ", n: "Christopher & Banks Corporation", c: "US" },
  { id: 14103, s: "RY8.DE", n: "Roy Ceramics AG", c: "GER" },
  { id: 14104, s: "ALBI.PA", n: "Gascogne SA", c: "FR" },
  { id: 14105, s: "HGGE.TA", n: "Hagag Europe Development ZF", c: "Israel" },
  { id: 14106, s: "1907.TW", n: "YFY Inc", c: "TW" },
  { id: 14107, s: "AFM.L", n: "Alpha Financial Markets Consulting PLC", c: "UK" },
  { id: 14108, s: "2882A.TW", n: "Cathay Financial Holding Co Pref A", c: "TW" },
  { id: 14109, s: "ECPA.TA", n: "Ecoppia Scientific", c: "Israel" },
  { id: 14110, s: "1718.TW", n: "CH Man-Made Fiber Co", c: "TW" },
  { id: 14111, s: "DAB.", n: "Danske Andelskassers Bank A/S", c: "Denmark" },
  { id: 14112, s: "2013.TW", n: "CH Steel Structure", c: "TW" },
  { id: 14113, s: "3694.TW", n: "AzureWave Technologies Inc", c: "TW" },
  { id: 14114, s: "SOI.L", n: "Schroder Oriental Income Fund", c: "UK" },
  { id: 14115, s: "SIE.F", n: "Siemens Aktiengesellschaft", c: "GER" },
  { id: 14116, s: "VIS.V", n: "Visionstate Co", c: "CA" },
  { id: 14117, s: "ISKPL.IS", n: "Isik Plastik Sanayi ve Dis Ticaret Pazarlama AS", c: "Turkey" },
  { id: 14118, s: "EAC.", n: "EAC Invest A/S", c: "Denmark" },
  { id: 14119, s: "SDF.F", n: "K+S Aktiengesellschaft", c: "GER" },
  { id: 14120, s: "AUGN.TA", n: "Augwind Energy Tech Storage", c: "Israel" },
  { id: 14121, s: "VGD.V", n: "Visible Gold Mines Inc", c: "CA" },
  { id: 14122, s: "3357.TWO", n: "Tai-Tech Advanced Electronics", c: "TW" },
  { id: 14123, s: "LMG.V", n: "Lincoln Mining Co", c: "CA" },
  { id: 14124, s: "SFL.ST", n: "Safello Group AB", c: "Sweden" },
  { id: 14125, s: "MTEC.L", n: "Made Tech Group PLC", c: "UK" },
  { id: 14126, s: "WUHN", n: "Wuhan General Gr Chn", c: "US" },
  { id: 14127, s: "1717.TW", n: "Eternal Materials", c: "TW" },
  { id: 14128, s: "SUP.V", n: "Northern Superior Resources Inc", c: "CA" },
  { id: 14129, s: "WMMVF", n: "Wal Mart de Mexico SAB de CV", c: "US" },
  { id: 14130, s: "4576.TW", n: "Hiwin Mikrosystem Co", c: "TW" },
  { id: 14131, s: "6576.TWO", n: "Foresee Pharmaceuticals", c: "TW" },
  { id: 14132, s: "SRAC.TA", n: "SR Accord", c: "Israel" },
  { id: 14133, s: "2010.TW", n: "Chun Yuan Steel Industry", c: "TW" },
  { id: 14134, s: "2605.TW", n: "Sincere Navigation Co", c: "TW" },
  { id: 14135, s: "FG.ST", n: "Fasadgruppen Group AB", c: "Sweden" },
  { id: 14136, s: "MOS.L", n: "Mobile Streams Plc", c: "UK" },
  { id: 14137, s: "MNL.L", n: "Manchester and London Investment Trust plc", c: "UK" },
  { id: 14138, s: "EYE.AX", n: "Nova Eye Medical", c: "Australia" },
  { id: 14139, s: "3701.TW", n: "FIC Global Inc", c: "TW" },
  { id: 14140, s: "HGYN", n: "Hong Yuan Holding Group", c: "US" },
  { id: 14141, s: "EFNC.TA", n: "Erech Finance Cahalacha", c: "Israel" },
  { id: 14142, s: "2524.TW", n: "King’s Town Construction", c: "TW" },
  { id: 14143, s: "ELLO.TA", n: "Ellomay Capital", c: "Israel" },
  { id: 14144, s: "BIJ.F", n: "Bijou Brigitte modische Accessoires Aktiengesellschaft", c: "GER" },
  { id: 14145, s: "2887E.TW", n: "Taishin Financial Holding Co Pref", c: "TW" },
  { id: 14146, s: "ETGA.TA", n: "Etga Group", c: "Israel" },
  { id: 14147, s: "FBRT.TA", n: "FMS Enterprises Migun", c: "Israel" },
  { id: 14148, s: "RHK.F", n: "RHÖN-KLINIKUM Aktiengesellschaft", c: "GER" },
  { id: 14149, s: "AAU.L", n: "Ariana Resources plc", c: "UK" },
  { id: 14150, s: "6803.TWO", n: "ECOVE Environment Co", c: "TW" },
  { id: 14151, s: "ING.L", n: "Ingenta plc", c: "UK" },
  { id: 14152, s: "2009.TW", n: "First Copper Technology", c: "TW" },
  { id: 14153, s: "NWS.AX", n: "News Corp B DRC", c: "Australia" },
  { id: 14154, s: "MEK.V", n: "Metals Creek Resources Co", c: "CA" },
  { id: 14155, s: "TEDE.TA", n: "Tedea Technological Development and Automation", c: "Israel" },
  { id: 14156, s: "IBT.V", n: "IBEX Technologies Inc", c: "CA" },
  { id: 14157, s: "SDG.L", n: "Sanderson Design Group PLC", c: "UK" },
  { id: 14158, s: "STH.V", n: "Stelmine CA", c: "CA" },
  { id: 14159, s: "IGP.L", n: "Intercede Group", c: "UK" },
  { id: 14160, s: "3714.TW", n: "Ennostar Inc", c: "TW" },
  { id: 14161, s: "1701.TW", n: "CH Chemical & Pharmaceutical", c: "TW" },
  { id: 14162, s: "UPSL.TA", n: "Upsellon Brands Holdings", c: "Israel" },
  { id: 14163, s: "DMAN", n: "Innovativ Media Group Inc", c: "US" },
  { id: 14164, s: "WESR.TA", n: "Wesure Global Tech", c: "Israel" },
  { id: 14165, s: "YAAC.TA", n: "Yaacobi Brothers Group YSB", c: "Israel" },
  { id: 14166, s: "DMAQ", n: "Deep Medicine Acquisition Co", c: "US" },
  { id: 14167, s: "6530.TWO", n: "Axcen Photonics", c: "TW" },
  { id: 14168, s: "DANT.", n: "Dantax", c: "Denmark" },
  { id: 14169, s: "GYLD-A.", n: "Gyldendal A/S", c: "Denmark" },
  { id: 14170, s: "WI.V", n: "Western Investment Company of CA Limited", c: "CA" },
  { id: 14171, s: "1103.TW", n: "Chia Hsin Cement Co", c: "TW" },
  { id: 14172, s: "MZZ.AX", n: "Matador Mining", c: "Australia" },
  { id: 14173, s: "TMG.AX", n: "Trigg Minerals Limited", c: "Australia" },
  { id: 14174, s: "9957.TWO", n: "Yieh United Steel", c: "TW" },
  { id: 14175, s: "NSR.AX", n: "National Storage REIT", c: "Australia" },
  { id: 14176, s: "CORE-PREF.ST", n: "Corem Property Group AB (publ)", c: "Sweden" },
  { id: 14177, s: "RCDO.L", n: "Ricardo", c: "UK" },
  { id: 14178, s: "BRSR3.SA", n: "Banco do Estado do Rio Grande do Sul S.A", c: "Brazil" },
  { id: 14179, s: "NGI.AX", n: "Navigator Global Investments", c: "Australia" },
  { id: 14180, s: "8433.TWO", n: "Bon Fame", c: "TW" },
  { id: 14181, s: "3592.TW", n: "Raydium Semi-Conductor", c: "TW" },
  { id: 14182, s: "SPZ.AX", n: "Smart Parking", c: "Australia" },
  { id: 14183, s: "PTY.L", n: "Parity Group", c: "UK" },
  { id: 14184, s: "8908.TWO", n: "Shinhsiung Natural Gas", c: "TW" },
  { id: 14185, s: "2545.TW", n: "Huang Hsiang Construction Co", c: "TW" },
  { id: 14186, s: "PRV.L", n: "Porvair plc", c: "UK" },
  { id: 14187, s: "8917.TWO", n: "Hsin Tai Gas", c: "TW" },
  { id: 14188, s: "CTA.V", n: "Centaurus Energy Inc", c: "CA" },
  { id: 14189, s: "CSPN.V", n: "Carespan Health Inc", c: "CA" },
  { id: 14190, s: "6706.TW", n: "Fittech", c: "TW" },
  { id: 14191, s: "PHYS.TO", n: "Sprott Physical Gold", c: "CA" },
  { id: 14192, s: "8255.TWO", n: "Actron Technology", c: "TW" },
  { id: 14193, s: "NANO.L", n: "Nanoco Group plc", c: "UK" },
  { id: 14194, s: "SOV.AX", n: "Sovereign Cloud Holdings Ltd", c: "Australia" },
  { id: 14195, s: "ACWN.F", n: "A.S. Création Tapeten AG", c: "GER" },
  { id: 14196, s: "NSB.AX", n: "Neuroscientific Biopharmaceuticals", c: "Australia" },
  { id: 14197, s: "INFI.V", n: "Infinitum Copper Co", c: "CA" },
  { id: 14198, s: "SHWK.DE", n: "SHS VIVEON AG", c: "GER" },
  { id: 14199, s: "8074.TWO", n: "Aurona Industries", c: "TW" },
  { id: 14200, s: "SCB.LS", n: "Sporting Clube de Braga - Futebol SAD", c: "Portugal" },
  { id: 14201, s: "ISATR.IS", n: "Turkiye Is Bankasi AS Class A", c: "Turkey" },
  { id: 14202, s: "KTCG.VI", n: "Kapsch Traffic", c: "Austria" },
  { id: 14203, s: "CBG.V", n: "Chibougamau Independent Mines Inc", c: "CA" },
  { id: 14204, s: "DRFS", n: "Dr. Foods Inc.", c: "US" },
  { id: 14205, s: "PNSUT.IS", n: "Pinar Sut Mamulleri Sanayi AS", c: "Turkey" },
  { id: 14206, s: "BBD-PD.TO", n: "Bombardier Inc Pref D", c: "CA" },
  { id: 14207, s: "SCL.AX", n: "Schrole Group", c: "Australia" },
  { id: 14208, s: "6177.TW", n: "Da-Li Development", c: "TW" },
  { id: 14209, s: "AKAST.OL", n: "Akastor ASA", c: "Norway" },
  { id: 14210, s: "H5E.F", n: "HELMA Eigenheimbau Aktiengesellschaft", c: "GER" },
  { id: 14211, s: "2035.TWO", n: "Tang Eng Iron Works", c: "TW" },
  { id: 14212, s: "WUW.DE", n: "Wüstenrot & Württembergische AG", c: "GER" },
  { id: 14213, s: "ALGYO.IS", n: "Alarko Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 14214, s: "ARTE.PA", n: "Artea SA", c: "FR" },
  { id: 14215, s: "2820.TW", n: "CH Bills Finance Co", c: "TW" },
  { id: 14216, s: "GMV.V", n: "GMV Minerals Inc", c: "CA" },
  { id: 14217, s: "5381.TWO", n: "Uniplus Electronics", c: "TW" },
  { id: 14218, s: "MPH.V", n: "Medicure Inc", c: "CA" },
  { id: 14219, s: "1785.TWO", n: "Solar Applied Materials Technology", c: "TW" },
  { id: 14220, s: "BWB.F", n: "Baader Bank Aktiengesellschaft", c: "GER" },
  { id: 14221, s: "2023.TW", n: "Yieh Phui Enterprise", c: "TW" },
  { id: 14222, s: "AXIS.TO", n: "Axis Auto Finance Inc", c: "CA" },
  { id: 14223, s: "6510.TWO", n: "Chunghwa Precision Test Tech", c: "TW" },
  { id: 14224, s: "SIX.AX", n: "Sprintex Limited", c: "Australia" },
  { id: 14225, s: "SNX.ST", n: "Premium Snacks Nordic AB", c: "Sweden" },
  { id: 14226, s: "PEA.TO", n: "Pieridae Energy", c: "CA" },
  { id: 14227, s: "PARSN.IS", n: "Parsan Makina Parcalari Sanayi AS", c: "Turkey" },
  { id: 14228, s: "CWC.V", n: "CWC Well Services Corp.", c: "CA" },
  { id: 14229, s: "3033.TW", n: "Weikeng Industrial", c: "TW" },
  { id: 14230, s: "PVL.PA", n: "Plastiques du Val de Loire", c: "FR" },
  { id: 14231, s: "OSP-PA.TO", n: "Brompton Oil Split Co", c: "CA" },
  { id: 14232, s: "MD.V", n: "Midland Exploration Inc", c: "CA" },
  { id: 14233, s: "APR-UN.TO", n: "Automotive Properties Real Estate Investment Trust", c: "CA" },
  { id: 14234, s: "TEM.AX", n: "Tempest Minerals", c: "Australia" },
  { id: 14235, s: "ASCU.TO", n: "Arizona Sonoran Copper Company Inc", c: "CA" },
  { id: 14236, s: "GCAT.L", n: "Caracal Gold PLC", c: "UK" },
  { id: 14237, s: "XTM.V", n: "Transition Metals Co", c: "CA" },
  { id: 14238, s: "SP8.AX", n: "Streamplay Studio.", c: "Australia" },
  { id: 14239, s: "MAH.V", n: "Marksmen Energy Inc", c: "CA" },
  { id: 14240, s: "ECF.F", n: "Mountain Alliance AG", c: "GER" },
  { id: 14241, s: "YATAS.IS", n: "Yatas Yatak ve Yorgan Sanayi Ticaret AS", c: "Turkey" },
  { id: 14242, s: "GBERY", n: "Geberit AG ADR", c: "US" },
  { id: 14243, s: "3526.TWO", n: "Alltop Technology", c: "TW" },
  { id: 14244, s: "6531.TW", n: "AP Memory Technology Co", c: "TW" },
  { id: 14245, s: "CNX.V", n: "Callinex Mines", c: "CA" },
  { id: 14246, s: "FRTA3.SA", n: "Pomifrutas S/A", c: "Brazil" },
  { id: 14247, s: "3130.TW", n: "104 Corporation", c: "TW" },
  { id: 14248, s: "1440.TW", n: "Tainan Spinning", c: "TW" },
  { id: 14249, s: "2597.TW", n: "Ruentex Engineering & Construction", c: "TW" },
  { id: 14250, s: "EFIR", n: "EGPI Firecreek Inc", c: "US" },
  { id: 14251, s: "GMTN.TO", n: "Gold Mountain Mining Co", c: "CA" },
  { id: 14252, s: "VG8.F", n: "Varengold Bank AG", c: "GER" },
  { id: 14253, s: "IMP.TO", n: "Intermap Technologies Co", c: "CA" },
  { id: 14254, s: "SVG.AX", n: "Savannah Goldfields.", c: "Australia" },
  { id: 14255, s: "CSO.V", n: "Corsa Coal Co", c: "CA" },
  { id: 14256, s: "STUDBO.ST", n: "Studentbostader I Sverige AB", c: "Sweden" },
  { id: 14257, s: "1532.TW", n: "CH Metal Products", c: "TW" },
  { id: 14258, s: "CRI.V", n: "Churchill Resources Inc", c: "CA" },
  { id: 14259, s: "3545.TW", n: "FocalTech Systems", c: "TW" },
  { id: 14260, s: "PIP.L", n: "PipeHawk plc", c: "UK" },
  { id: 14261, s: "PUS.MU", n: "PULSION Medical Systems SE", c: "GER" },
  { id: 14262, s: "3530.TW", n: "Silicon Optronics Inc", c: "TW" },
  { id: 14263, s: "SNGYO.IS", n: "Sinpas Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 14264, s: "8924.TWO", n: "O-TA Precision Industry", c: "TW" },
  { id: 14265, s: "DSEEY", n: "Daiwa Securities Group Inc ADR", c: "US" },
  { id: 14266, s: "XINT.ST", n: "Xintela AB", c: "Sweden" },
  { id: 14267, s: "8933.TWO", n: "Ideal Bike", c: "TW" },
  { id: 14268, s: "V3V.F", n: "VITA 34 AG", c: "GER" },
  { id: 14269, s: "NWT.L", n: "Newmark Security plc", c: "UK" },
  { id: 14270, s: "DOC.VI", n: "DO & CO Aktiengesellschaft", c: "Austria" },
  { id: 14271, s: "ZC.V", n: "Zimtu Capital Co", c: "CA" },
  { id: 14272, s: "3026.TW", n: "Holy Stone Enterprise", c: "TW" },
  { id: 14273, s: "PPL-PS.TO", n: "Pembina Pipeline Corp Pref Series 19 Class A", c: "CA" },
  { id: 14274, s: "2534.TW", n: "Hung Sheng Construction", c: "TW" },
  { id: 14275, s: "PPL-PO.TO", n: "Pembina Pipeline Corp Pref Series 15 Class A", c: "CA" },
  { id: 14276, s: "RKW.L", n: "Rockwood Realisation PLC", c: "UK" },
  { id: 14277, s: "2539.TW", n: "Sakura Development", c: "TW" },
  { id: 14278, s: "RICA.L", n: "Ruffer Investment Company Limited", c: "UK" },
  { id: 14279, s: "3031.TW", n: "Bright Led Electronics Co", c: "TW" },
  { id: 14280, s: "NIBAS.IS", n: "Nigbas Nigde Beton Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 14281, s: "3645.TW", n: "Taimide Tech Inc", c: "TW" },
  { id: 14282, s: "DRIL.ST", n: "Drillcon AB", c: "Sweden" },
  { id: 14283, s: "3491.TWO", n: "Universal Microwave Technology", c: "TW" },
  { id: 14284, s: "2540.TW", n: "JSL Construction & Development", c: "TW" },
  { id: 14285, s: "CEDO3.SA", n: "Companhia de Fiacao e Tecidos Cedro Cachoeira", c: "Brazil" },
  { id: 14286, s: "1312A.TW", n: "Grand Pacific Petrochemical Corp Pref", c: "TW" },
  { id: 14287, s: "PROC.L", n: "Procook Group PLC", c: "UK" },
  { id: 14288, s: "MRT-UN.TO", n: "Morguard Real Estate Investment Trust", c: "CA" },
  { id: 14289, s: "3541.TWO", n: "Cypress Technology", c: "TW" },
  { id: 14290, s: "LORD.V", n: "St James Gold Co", c: "CA" },
  { id: 14291, s: "ALPCOT-B.ST", n: "Alpcot Holding AB Series B", c: "Sweden" },
  { id: 14292, s: "VANGD.IS", n: "Vanet Gida Sanayi Ic ve Dis Ticaret AS", c: "Turkey" },
  { id: 14293, s: "STW.ST", n: "SeaTwirl AB", c: "Sweden" },
  { id: 14294, s: "WFA.F", n: "WENG FINE ART AG NA O.N.", c: "GER" },
  { id: 14295, s: "IFA.TO", n: "iFabric Co", c: "CA" },
  { id: 14296, s: "COR.V", n: "Camino Minerals Corporation", c: "CA" },
  { id: 14297, s: "ARCA.ST", n: "Arcario AB", c: "Sweden" },
  { id: 14298, s: "LUPA3.SA", n: "Lupatech S.A", c: "Brazil" },
  { id: 14299, s: "1446.TW", n: "Hong Ho Precision Textile", c: "TW" },
  { id: 14300, s: "LGRDY", n: "Legrand SA ADR", c: "US" },
  { id: 14301, s: "M2R.AX", n: "Miramar Resources Ltd", c: "Australia" },
  { id: 14302, s: "EXP.AX", n: "Experience", c: "Australia" },
  { id: 14303, s: "000990.SZ", n: "Chengzhi Shareholding", c: "CH" },
  { id: 14304, s: "DCX.AX", n: "Discovex Resources", c: "Australia" },
  { id: 14305, s: "DDB.AX", n: "Dynamic Drill And Blast Holding", c: "Australia" },
  { id: 14306, s: "171120.KQ", n: "Lion Chemtech Co.", c: "Korea" },
  { id: 14307, s: "VPRB", n: "VPR Brands LP", c: "US" },
  { id: 14308, s: "DDT.AX", n: "DataDot Technology", c: "Australia" },
  { id: 14309, s: "603393.SS", n: "Xintai Ntrl Gas", c: "CH" },
  { id: 14310, s: "160550.KQ", n: "Next Entertainment World Co.", c: "Korea" },
  { id: 14311, s: "DEM.AX", n: "De.mem", c: "Australia" },
  { id: 14312, s: "000069.SZ", n: "Shenzhen Overseas Chinese Town", c: "CH" },
  { id: 14313, s: "603383.SS", n: "Fujian Apex Software", c: "CH" },
  { id: 14314, s: "DGH.AX", n: "Desane Group Holdings", c: "Australia" },
  { id: 14315, s: "CXL.AX", n: "Calix", c: "Australia" },
  { id: 14316, s: "CWX.AX", n: "Carawine Resources Limited", c: "Australia" },
  { id: 14317, s: "300887.SZ", n: "Pony Testing International Group", c: "CH" },
  { id: 14318, s: "CVN.AX", n: "Carnarvon Petroleum", c: "Australia" },
  { id: 14319, s: "CNW.AX", n: "Cirrus Networks Holdings", c: "Australia" },
  { id: 14320, s: "603859.SS", n: "Nancal Energy-Saving Tech", c: "CH" },
  { id: 14321, s: "COF.AX", n: "Centuria Metropolitan REIT", c: "Australia" },
  { id: 14322, s: "COI.AX", n: "Comet Ridge", c: "Australia" },
  { id: 14323, s: "217190.KQ", n: "Genesem Inc", c: "Korea" },
  { id: 14324, s: "COO.AX", n: "Corum Group", c: "Australia" },
  { id: 14325, s: "COY.AX", n: "Coppermoly", c: "Australia" },
  { id: 14326, s: "603838.SS", n: "Guang Dong Sitong Group", c: "CH" },
  { id: 14327, s: "000988.SZ", n: "Huagong Tech", c: "CH" },
  { id: 14328, s: "8IH.AX", n: "8I Holdings", c: "Australia" },
  { id: 14329, s: "CPO.AX", n: "Culpeo Minerals", c: "Australia" },
  { id: 14330, s: "000031.SZ", n: "Grandjoy Holdings Group", c: "CH" },
  { id: 14331, s: "603779.SS", n: "Wei Long Grape Wine", c: "CH" },
  { id: 14332, s: "603776.SS", n: "Changzhou Youon Pubilic Bicycle System", c: "CH" },
  { id: 14333, s: "221980.KQ", n: "KD Chem Co.", c: "Korea" },
  { id: 14334, s: "000985.SZ", n: "Daqing Huake", c: "CH" },
  { id: 14335, s: "DC2.AX", n: "Dc Two Ltd", c: "Australia" },
  { id: 14336, s: "293780.KQ", n: "AptaBio Therapeutics Inc", c: "Korea" },
  { id: 14337, s: "000989.SZ", n: "Jiuzhitang", c: "CH" },
  { id: 14338, s: "605318.SS", n: "Fsilon Furnishing and Construction Materials Co", c: "CH" },
  { id: 14339, s: "313760.KQ", n: "Willings Co.", c: "Korea" },
  { id: 14340, s: "ADD.AX", n: "Adavale Resources", c: "Australia" },
  { id: 14341, s: "CAN.AX", n: "Cann Group", c: "Australia" },
  { id: 14342, s: "002518.SZ", n: "Shenzhen Kstar Science & Technology", c: "CH" },
  { id: 14343, s: "605136.SS", n: "Shanghai Lili & Beauty Cosmetics", c: "CH" },
  { id: 14344, s: "CAY.AX", n: "Canyon Resources", c: "Australia" },
  { id: 14345, s: "CAZ.AX", n: "Cazaly Resources", c: "Australia" },
  { id: 14346, s: "ACU.AX", n: "Acumentis Group", c: "Australia" },
  { id: 14347, s: "ACR.AX", n: "Acrux", c: "Australia" },
  { id: 14348, s: "299900.KQ", n: "Wysiwyg Studios Co.", c: "Korea" },
  { id: 14349, s: "PACI", n: "Proof Acquisition I Co", c: "US" },
  { id: 14350, s: "ACF.AX", n: "Acrow Formwork and Construction Services", c: "Australia" },
  { id: 14351, s: "DBI.AX", n: "Dalrymple Bay Infrastructure Ltd", c: "Australia" },
  { id: 14352, s: "224060.KQ", n: "Kodi-M", c: "Korea" },
  { id: 14353, s: "PBCRY", n: "Bank Central Asia Tbk PT ADR", c: "US" },
  { id: 14354, s: "BUY.AX", n: "Bounty Oil and Gas NL", c: "Australia" },
  { id: 14355, s: "018500.KS", n: "Dongwon Metal", c: "Korea" },
  { id: 14356, s: "603595.SS", n: "Zhejiang Tony Electronic", c: "CH" },
  { id: 14357, s: "015860.KS", n: "Iljin Holdings", c: "Korea" },
  { id: 14358, s: "603518.SS", n: "Jinhong Fashion Group", c: "CH" },
  { id: 14359, s: "CYQ.AX", n: "Cycliq Group Limited", c: "Australia" },
  { id: 14360, s: "300846.SZ", n: "Capitalonline Data Service ", c: "CH" },
  { id: 14361, s: "300840.SZ", n: "Qingdao Kutesmart ", c: "CH" },
  { id: 14362, s: "603489.SS", n: "Bafang Electric Suzhou", c: "CH" },
  { id: 14363, s: "UBSFF", n: "Ubisoft Entertainment", c: "US" },
  { id: 14364, s: "018470.KS", n: "Choil Aluminum", c: "Korea" },
  { id: 14365, s: "CR1.AX", n: "Constellation Resources", c: "Australia" },
  { id: 14366, s: "000034.SZ", n: "Digital CH Group", c: "CH" },
  { id: 14367, s: "3DP.AX", n: "Pointerra", c: "Australia" },
  { id: 14368, s: "ARD.AX", n: "Argent Minerals", c: "Australia" },
  { id: 14369, s: "AU1.AX", n: "Agency Group Australia", c: "Australia" },
  { id: 14370, s: "RYKKY", n: "Ryohin Keikaku", c: "US" },
  { id: 14371, s: "PSTVY", n: "Postal Savings Bank of CH Co ADR", c: "US" },
  { id: 14372, s: "688508.SS", n: "Wuxi Chipown Micro-electronics", c: "CH" },
  { id: 14373, s: "688787.SS", n: "Beijing Haitian Ruisheng Science Technology", c: "CH" },
  { id: 14374, s: "APC.AX", n: "Australian Potash", c: "Australia" },
  { id: 14375, s: "AON.AX", n: "Apollo Minerals", c: "Australia" },
  { id: 14376, s: "AQC.AX", n: "Australian Pacific Coal", c: "Australia" },
  { id: 14377, s: "AQN.AX", n: "Aquirian", c: "Australia" },
  { id: 14378, s: "AQZ.AX", n: "Alliance Aviation Services", c: "Australia" },
  { id: 14379, s: "RTNB", n: "root9B Holdings Inc", c: "US" },
  { id: 14380, s: "AOA.AX", n: "Ausmon Resources", c: "Australia" },
  { id: 14381, s: "AO1.AX", n: "Assetowl", c: "Australia" },
  { id: 14382, s: "ANR.AX", n: "Anatara Lifesciences", c: "Australia" },
  { id: 14383, s: "AZY.AX", n: "Antipa Minerals", c: "Australia" },
  { id: 14384, s: "005257.KS", n: "Green Cross Holdings Preference Shares", c: "Korea" },
  { id: 14385, s: "002111.SZ", n: "Weihai Guangtai Airport Equipment", c: "CH" },
  { id: 14386, s: "ANL.AX", n: "Amani Gold", c: "Australia" },
  { id: 14387, s: "002219.SZ", n: "Hengkang Medical Group", c: "CH" },
  { id: 14388, s: "AND.AX", n: "Ansarada Group", c: "Australia" },
  { id: 14389, s: "002103.SZ", n: "Guangbo Group Stock", c: "CH" },
  { id: 14390, s: "AMO.AX", n: "Ambertech", c: "Australia" },
  { id: 14391, s: "AT1.AX", n: "Atomo Diagnostics", c: "Australia" },
  { id: 14392, s: "AME.AX", n: "Alto Metals", c: "Australia" },
  { id: 14393, s: "AMD.AX", n: "Arrow Minerals", c: "Australia" },
  { id: 14394, s: "ADMIE.AT", n: "Admie Holding S.A", c: "Greece" },
  { id: 14395, s: "OCAXU", n: "OCA Acquisition Co", c: "US" },
  { id: 14396, s: "NHMD", n: "Nate's Food", c: "US" },
  { id: 14397, s: "NHNKY", n: "Nihon Kohden Corp ADR", c: "US" },
  { id: 14398, s: "688227.SS", n: "Bingo Software", c: "CH" },
  { id: 14399, s: "CST.AX", n: "Castile Resources Ltd", c: "Australia" },
  { id: 14400, s: "AUK.AX", n: "Aumake", c: "Australia" },
  { id: 14401, s: "1ST.AX", n: "1st Group", c: "Australia" },
  { id: 14402, s: "CTP.AX", n: "Central Petroleum", c: "Australia" },
  { id: 14403, s: "MDRM", n: "Modern Mobility Aids", c: "US" },
  { id: 14404, s: "CUL.AX", n: "Cullen Resources", c: "Australia" },
  { id: 14405, s: "CUS.AX", n: "Copper Search", c: "Australia" },
  { id: 14406, s: "BWF.AX", n: "BlackWall Property Funds", c: "Australia" },
  { id: 14407, s: "BUX.AX", n: "Buxton Resources", c: "Australia" },
  { id: 14408, s: "600742.SS", n: "Changchun Faway Automobile Components", c: "CH" },
  { id: 14409, s: "002314.SZ", n: "Shenzhen New Nanshan Holding Group", c: "CH" },
  { id: 14410, s: "002092.SZ", n: "Xinjiang Zhongtai Chemical", c: "CH" },
  { id: 14411, s: "AUH.AX", n: "AustCH Holdings", c: "Australia" },
  { id: 14412, s: "AUI.AX", n: "Australian United Investment", c: "Australia" },
  { id: 14413, s: "688366.SS", n: "Shanghai Haohai Biological Tech", c: "CH" },
  { id: 14414, s: "688360.SS", n: "Zhejiang Damon Technology", c: "CH" },
  { id: 14415, s: "AL3.AX", n: "Aml3d Pty Ltd", c: "Australia" },
  { id: 14416, s: "AVC.AX", n: "Auctus Alternative Investments", c: "Australia" },
  { id: 14417, s: "AVE.AX", n: "Avecho Biotechnology", c: "Australia" },
  { id: 14418, s: "002299.SZ", n: "Fujian Sunner Development", c: "CH" },
  { id: 14419, s: "OGFGY", n: "Origin Energy ADR", c: "US" },
  { id: 14420, s: "OHAA", n: "OPY Acquisition I Co", c: "US" },
  { id: 14421, s: "AVW.AX", n: "Avira Energy", c: "Australia" },
  { id: 14422, s: "BBL.AX", n: "Brisbane Broncos", c: "Australia" },
  { id: 14423, s: "ALA.AX", n: "Arovella Therapeutics", c: "Australia" },
  { id: 14424, s: "AWJ.AX", n: "Auric Mining", c: "Australia" },
  { id: 14425, s: "002028.SZ", n: "Sieyuan Electric", c: "CH" },
  { id: 14426, s: "006390.KS", n: "Hyundai Cement", c: "Korea" },
  { id: 14427, s: "NLBS", n: "Nutralife Biosciences Inc", c: "US" },
  { id: 14428, s: "INTRK.AT", n: "Intracom Holdings S.A.", c: "Greece" },
  { id: 14429, s: "CAE.AX", n: "Cannindah Resources", c: "Australia" },
  { id: 14430, s: "ADO.AX", n: "Anteo Diagnostics", c: "Australia" },
  { id: 14431, s: "BUR.AX", n: "Burley Minerals", c: "Australia" },
  { id: 14432, s: "BYH.AX", n: "Bryah Resources", c: "Australia" },
  { id: 14433, s: "600662.SS", n: "Shanghai Qiangsheng Holding", c: "CH" },
  { id: 14434, s: "200429.SZ", n: "Guangdong Provincial Expressway Development", c: "CH" },
  { id: 14435, s: "600302.SS", n: "XiAn Typical Industries", c: "CH" },
  { id: 14436, s: "SFS.WA", n: "Sfinks Polska S.A.", c: "Poland" },
  { id: 14437, s: "LSA.AX", n: "Lachlan Star", c: "Australia" },
  { id: 14438, s: "200055.SZ", n: "CH Fangda Group", c: "CH" },
  { id: 14439, s: "VIN.WA", n: "Gielda Praw Majatkowych Vindexus S.A.", c: "Poland" },
  { id: 14440, s: "600487.SS", n: "Hengtong Optic Electric", c: "CH" },
  { id: 14441, s: "SUTNY", n: "Sumitomo Mitsui Trust Holdings PK", c: "US" },
  { id: 14442, s: "LSX.AX", n: "Lion Selection Group", c: "Australia" },
  { id: 14443, s: "M4M.AX", n: "Macro Metals.", c: "Australia" },
  { id: 14444, s: "600697.SS", n: "Chang Chun Eurasia Group", c: "CH" },
  { id: 14445, s: "600688.SS", n: "Sinopec Shanghai Petrochemical Co Class A", c: "CH" },
  { id: 14446, s: "600073.SS", n: "Shanghai Maling Aquarius", c: "CH" },
  { id: 14447, s: "600516.SS", n: "FangDa Carbon New Material", c: "CH" },
  { id: 14448, s: "002966.SZ", n: "Bank of Suzhou", c: "CH" },
  { id: 14449, s: "SOLLY", n: "Shui On Land ADR", c: "US" },
  { id: 14450, s: "037230.KQ", n: "Hankukpackage Co.", c: "Korea" },
  { id: 14451, s: "600674.SS", n: "Sichuan Chuantou Energy", c: "CH" },
  { id: 14452, s: "600444.SS", n: "Sinomach General Machinery Science Technology", c: "CH" },
  { id: 14453, s: "600459.SS", n: "Sino-Platinum Metals", c: "CH" },
  { id: 14454, s: "600683.SS", n: "Metro Investment Development", c: "CH" },
  { id: 14455, s: "WOJ.WA", n: "Wojas SA", c: "Poland" },
  { id: 14456, s: "ICBU", n: "Imd Companies", c: "US" },
  { id: 14457, s: "LVH.AX", n: "LiveHire", c: "Australia" },
  { id: 14458, s: "KTA.AX", n: "Krakatoa Resources", c: "Australia" },
  { id: 14459, s: "600658.SS", n: "Beijing Electronic Zone Investment and Development Group", c: "CH" },
  { id: 14460, s: "600325.SS", n: "Huafa Industrial Co Zhuhai", c: "CH" },
  { id: 14461, s: "002839.SZ", n: "Jiangsu Zhangjiagang Rural Commercial Bank", c: "CH" },
  { id: 14462, s: "LRK.AX", n: "Lark Distilling", c: "Australia" },
  { id: 14463, s: "KPO.AX", n: "Kalina Power", c: "Australia" },
  { id: 14464, s: "PEO.WA", n: "Bank Polska Kasa Opieki SA", c: "Poland" },
  { id: 14465, s: "002941.SZ", n: "Xinjiang Communications Construction Group Co Class A", c: "CH" },
  { id: 14466, s: "LV1.AX", n: "Live Verdure", c: "Australia" },
  { id: 14467, s: "EVS.AX", n: "Envirosuite", c: "Australia" },
  { id: 14468, s: "029960.KQ", n: "Korea Environment Technology Co.", c: "Korea" },
  { id: 14469, s: "600386.SS", n: "Beijing Bashi Media", c: "CH" },
  { id: 14470, s: "MTB.AX", n: "Mount Burgess Mining NL", c: "Australia" },
  { id: 14471, s: "600133.SS", n: "Wuhan East Lake High Technology Group", c: "CH" },
  { id: 14472, s: "600136.SS", n: "Wuhan DDMC Culture", c: "CH" },
  { id: 14473, s: "000959.SZ", n: "Beijing Shougang", c: "CH" },
  { id: 14474, s: "KOR.AX", n: "Korab Resources", c: "Australia" },
  { id: 14475, s: "600378.SS", n: "Haohua Chemical Science & Technology Co", c: "CH" },
  { id: 14476, s: "HOMU", n: "Houmu Holdings", c: "US" },
  { id: 14477, s: "LNU.AX", n: "Linius Technologies", c: "Australia" },
  { id: 14478, s: "LLO.AX", n: "Lion One Metals", c: "Australia" },
  { id: 14479, s: "024800.KQ", n: "Yoosung T&S Co.", c: "Korea" },
  { id: 14480, s: "600653.SS", n: "Liaoning Shenhua Holdings", c: "CH" },
  { id: 14481, s: "000953.SZ", n: "Guangxi Hechi Chemical", c: "CH" },
  { id: 14482, s: "002939.SZ", n: "CH Great Wall Securities Co Class A", c: "CH" },
  { id: 14483, s: "600506.SS", n: "Korla Pear", c: "CH" },
  { id: 14484, s: "046390.KQ", n: "Samhwa Networks Co.", c: "Korea" },
  { id: 14485, s: "LCT.AX", n: "Living Cell Technologies", c: "Australia" },
  { id: 14486, s: "025550.KQ", n: "Hankuk Steel Wire Co.", c: "Korea" },
  { id: 14487, s: "600130.SS", n: "Ningbo Bird", c: "CH" },
  { id: 14488, s: "LBT.AX", n: "LBT Innovations", c: "Australia" },
  { id: 14489, s: "600566.SS", n: "Hubei Jumpcan Pharmaceutical", c: "CH" },
  { id: 14490, s: "600730.SS", n: "CH Hi-Tech Group", c: "CH" },
  { id: 14491, s: "600129.SS", n: "Chongqing Taiji Industry Group", c: "CH" },
  { id: 14492, s: "600581.SS", n: "Xinjiang Ba Yi Iron & Steel", c: "CH" },
  { id: 14493, s: "002990.SZ", n: "Maxvision Technology Co", c: "CH" },
  { id: 14494, s: "600399.SS", n: "Fushun Special Steel", c: "CH" },
  { id: 14495, s: "600116.SS", n: "Chongqing Three Gorges Water Conservancy and Electric Power", c: "CH" },
  { id: 14496, s: "600025.SS", n: "Huaneng Lancang River Hydropower Inc Class A", c: "CH" },
  { id: 14497, s: "A8G.AX", n: "Australasian Metals Limited", c: "Australia" },
  { id: 14498, s: "CCZ.AX", n: "Castillo Copper", c: "Australia" },
  { id: 14499, s: "CD1.AX", n: "CD Private Equity Fund I", c: "Australia" },
  { id: 14500, s: "CDM.AX", n: "Cadence Capital", c: "Australia" },
  { id: 14501, s: "002541.SZ", n: "Anhui Honglu Steel Construction Group", c: "CH" },
  { id: 14502, s: "CDR.AX", n: "Codrus Minerals", c: "Australia" },
  { id: 14503, s: "CG1.AX", n: "Carbonxt Group", c: "Australia" },
  { id: 14504, s: "002568.SZ", n: "Shanghai Bairun Flavor & Fragrance", c: "CH" },
  { id: 14505, s: "AAU.AX", n: "Antilles Gold", c: "Australia" },
  { id: 14506, s: "CHR.AX", n: "Charger Metals NL", c: "Australia" },
  { id: 14507, s: "603887.SS", n: "Shanghai Chengdi Constr", c: "CH" },
  { id: 14508, s: "603963.SS", n: "Dali Pharmaceutical", c: "CH" },
  { id: 14509, s: "253450.KQ", n: "Studio Dragon Corporation", c: "Korea" },
  { id: 14510, s: "CIN.AX", n: "Carlton Investments", c: "Australia" },
  { id: 14511, s: "CLT.AX", n: "Cellnet Group", c: "Australia" },
  { id: 14512, s: "CCR.AX", n: "Credit Clear Ltd", c: "Australia" },
  { id: 14513, s: "237690.KQ", n: "ST Pharm Co.Ltd", c: "Korea" },
  { id: 14514, s: "MJNE", n: "MJ Holdings Inc", c: "US" },
  { id: 14515, s: "CMD.AX", n: "Cassius Mining", c: "Australia" },
  { id: 14516, s: "011785.KS", n: "Kumho Petro Chemical", c: "Korea" },
  { id: 14517, s: "SDRC", n: "Sidney Resources Co", c: "US" },
  { id: 14518, s: "ABV.AX", n: "Advanced Braking Technology", c: "Australia" },
  { id: 14519, s: "ABX.AX", n: "ABx Group", c: "Australia" },
  { id: 14520, s: "MRPLY", n: "Mr Price Group", c: "US" },
  { id: 14521, s: "CCA.AX", n: "Change Financial Limited", c: "Australia" },
  { id: 14522, s: "002478.SZ", n: "Jiangsu Changbao Steeltube", c: "CH" },
  { id: 14523, s: "VMHG", n: "Victory Marine Holdings Co", c: "US" },
  { id: 14524, s: "600376.SS", n: "Beijing Capital Development", c: "CH" },
  { id: 14525, s: "ADS.AX", n: "Adslot", c: "Australia" },
  { id: 14526, s: "CCV.AX", n: "Cash Converters International", c: "Australia" },
  { id: 14527, s: "ABE.AX", n: "Australian Bond Exchange Holdings", c: "Australia" },
  { id: 14528, s: "LPE.AX", n: "Locality Planning Energy Holdings", c: "Australia" },
  { id: 14529, s: "WEDXF", n: "The Westaim Corporation", c: "US" },
  { id: 14530, s: "TRI.WA", n: "Triton Development S.A.", c: "Poland" },
  { id: 14531, s: "600502.SS", n: "Anhui Water Resources Development", c: "CH" },
  { id: 14532, s: "200505.SZ", n: "HaiNan Pearl River Holdings Co Class B", c: "CH" },
  { id: 14533, s: "600109.SS", n: "Sinolink Securities", c: "CH" },
  { id: 14534, s: "STGZ", n: "Stargaze Entertainment Group Inc", c: "US" },
  { id: 14535, s: "039290.KQ", n: "InfoBank Corporation", c: "Korea" },
  { id: 14536, s: "600173.SS", n: "Wolong Real Estate Group", c: "CH" },
  { id: 14537, s: "WPL.WA", n: "Wirtualna Polska Holding SA", c: "Poland" },
  { id: 14538, s: "LAM.AX", n: "Laramide Resources", c: "Australia" },
  { id: 14539, s: "200468.SZ", n: "Nanjing Putian Telecommunications Co Class B", c: "CH" },
  { id: 14540, s: "600332.SS", n: "Guangzhou Baiyunshan Pharmaceutical Holdings", c: "CH" },
  { id: 14541, s: "IFJPY", n: "Informa PLC ADR", c: "US" },
  { id: 14542, s: "600184.SS", n: "North Electro-Optic", c: "CH" },
  { id: 14543, s: "000878.SZ", n: "Yunnan Copper", c: "CH" },
  { id: 14544, s: "AAR.AX", n: "Astral Resources NL", c: "Australia" },
  { id: 14545, s: "600038.SS", n: "Avicopter PLC", c: "CH" },
  { id: 14546, s: "033500.KQ", n: "Dongsung FineTec Co.", c: "Korea" },
  { id: 14547, s: "600593.SS", n: "Dalian Sunasia Tourism Holding", c: "CH" },
  { id: 14548, s: "044490.KQ", n: "Taewoong Co.Ltd", c: "Korea" },
  { id: 14549, s: "600192.SS", n: "Lanzhou Greatwall Electrical", c: "CH" },
  { id: 14550, s: "600079.SS", n: "Humanwell Healthcare Group", c: "CH" },
  { id: 14551, s: "002984.SZ", n: "Qingdao Sentury Tire ", c: "CH" },
  { id: 14552, s: "MCL.AX", n: "Mighty Craft", c: "Australia" },
  { id: 14553, s: "600208.SS", n: "Xinhu Zhongbao", c: "CH" },
  { id: 14554, s: "LFS.AX", n: "Latitude Financial Services Group Limited", c: "Australia" },
  { id: 14555, s: "600277.SS", n: "Elion Energy", c: "CH" },
  { id: 14556, s: "601858.SS", n: "CH Science Publishing & Media", c: "CH" },
  { id: 14557, s: "KNM.AX", n: "Kneomedia", c: "Australia" },
  { id: 14558, s: "BBX.AX", n: "BBX Minerals", c: "Australia" },
  { id: 14559, s: "002582.SZ", n: "Haoxiangni Jujube", c: "CH" },
  { id: 14560, s: "BOL.AX", n: "Boom Logistics", c: "Australia" },
  { id: 14561, s: "KME.AX", n: "Kip McGrath Education Centres", c: "Australia" },
  { id: 14562, s: "GES.AX", n: "Genesis Resources", c: "Australia" },
  { id: 14563, s: "085660.KQ", n: "CHA Biotech Co.", c: "Korea" },
  { id: 14564, s: "064350.KS", n: "Hyundai Rotem", c: "Korea" },
  { id: 14565, s: "603045.SS", n: "Fuda Alloy Materials", c: "CH" },
  { id: 14566, s: "E79.AX", n: "E79 Gold Mines", c: "Australia" },
  { id: 14567, s: "603032.SS", n: "Delixi Xinjiang Transp", c: "CH" },
  { id: 14568, s: "ECS.AX", n: "ECS Botanics Holdings", c: "Australia" },
  { id: 14569, s: "ECT.AX", n: "Environmental Clean Technologies", c: "Australia" },
  { id: 14570, s: "EDC.AX", n: "Eildon Capital", c: "Australia" },
  { id: 14571, s: "126640.KQ", n: "Hwashin Precision Engineering Co.", c: "Korea" },
  { id: 14572, s: "123860.KQ", n: "AnaPass Inc", c: "Korea" },
  { id: 14573, s: "EFE.AX", n: "Eastern Iron", c: "Australia" },
  { id: 14574, s: "EGH.AX", n: "Eureka Group Holdings", c: "Australia" },
  { id: 14575, s: "EGY.AX", n: "Energy Technologies Limited", c: "Australia" },
  { id: 14576, s: "066575.KS", n: "Lg Electronics Pref", c: "Korea" },
  { id: 14577, s: "248070.KS", n: "SoluM", c: "Korea" },
  { id: 14578, s: "ELE.AX", n: "Elmore Limited", c: "Australia" },
  { id: 14579, s: "300623.SZ", n: "Jiangsu Jiejie Microelectronics", c: "CH" },
  { id: 14580, s: "300619.SZ", n: "Foshan Golden Milky Way Intelligent Equipment", c: "CH" },
  { id: 14581, s: "121600.KQ", n: "Advanced Nano Products Co.", c: "Korea" },
  { id: 14582, s: "EMD.AX", n: "Emyria", c: "Australia" },
  { id: 14583, s: "EME.AX", n: "Energy Metals", c: "Australia" },
  { id: 14584, s: "EMT.AX", n: "Emetals", c: "Australia" },
  { id: 14585, s: "ENA.AX", n: "Ensurance", c: "Australia" },
  { id: 14586, s: "ENN.AX", n: "Elanor Investors Group", c: "Australia" },
  { id: 14587, s: "601995.SS", n: "CH International Capital Co", c: "CH" },
  { id: 14588, s: "SIOLY", n: "Sino-Ocean Group Holding ADR", c: "US" },
  { id: 14589, s: "E33.AX", n: "East 33", c: "Australia" },
  { id: 14590, s: "603056.SS", n: "Deppon Logistics", c: "CH" },
  { id: 14591, s: "GFL.AX", n: "Global Masters Fund Limited", c: "Australia" },
  { id: 14592, s: "IVR.AX", n: "Investigator Resources", c: "Australia" },
  { id: 14593, s: "IBS.WA", n: "Noble Financials SA", c: "Poland" },
  { id: 14594, s: "600928.SS", n: "Bank of Xi'An", c: "CH" },
  { id: 14595, s: "600769.SS", n: "Wuhan Xianglong Power Industry", c: "CH" },
  { id: 14596, s: "600755.SS", n: "Xiamen ITG Group Co", c: "CH" },
  { id: 14597, s: "600751.SS", n: "HNA Technology Co A", c: "CH" },
  { id: 14598, s: "300010.SZ", n: "Beijing Lanxum Tech", c: "CH" },
  { id: 14599, s: "JAY.AX", n: "Jayride Group", c: "Australia" },
  { id: 14600, s: "JAN.AX", n: "Janison Education Group", c: "Australia" },
  { id: 14601, s: "JAL.AX", n: "Jameson Resources", c: "Australia" },
  { id: 14602, s: "IVX.AX", n: "Invion", c: "Australia" },
  { id: 14603, s: "ICN.AX", n: "Icon Energy", c: "Australia" },
  { id: 14604, s: "ICR.AX", n: "Intelicare Holdings", c: "Australia" },
  { id: 14605, s: "DAT.WA", n: "Datawalk SA", c: "Poland" },
  { id: 14606, s: "ID8.AX", n: "Identitii", c: "Australia" },
  { id: 14607, s: "IDA.AX", n: "Indiana Resources", c: "Australia" },
  { id: 14608, s: "IDT.AX", n: "IDT Australia", c: "Australia" },
  { id: 14609, s: "IRX.AX", n: "Inhalerx", c: "Australia" },
  { id: 14610, s: "600918.SS", n: "Zhongtai Securities", c: "CH" },
  { id: 14611, s: "600903.SS", n: "Guizhou Gas Group Corporation Class A", c: "CH" },
  { id: 14612, s: "060370.KQ", n: "KT Submarine Co.", c: "Korea" },
  { id: 14613, s: "IEC.AX", n: "Intra Energy Corporation", c: "Australia" },
  { id: 14614, s: "ENE.WA", n: "Centrum Medyczne ENEL-MED SA", c: "Poland" },
  { id: 14615, s: "ENT.WA", n: "Enter Air S.A.", c: "Poland" },
  { id: 14616, s: "FEE.WA", n: "Feerum S.A.", c: "Poland" },
  { id: 14617, s: "300118.SZ", n: "Risen Energy", c: "CH" },
  { id: 14618, s: "600888.SS", n: "Xinjiang Joinworld", c: "CH" },
  { id: 14619, s: "IMPQ.AX", n: "eInvest Future Impact Small Caps", c: "Australia" },
  { id: 14620, s: "IMR.AX", n: "Imricor Medical Systems Inc", c: "Australia" },
  { id: 14621, s: "000701.SZ", n: "Xiamen Xinde", c: "CH" },
  { id: 14622, s: "IRD.AX", n: "Iron Road", c: "Australia" },
  { id: 14623, s: "036460.KS", n: "Korea Gas Corporation", c: "Korea" },
  { id: 14624, s: "131290.KQ", n: "TSE Co.", c: "Korea" },
  { id: 14625, s: "603327.SS", n: "Sichuan Furong Technology", c: "CH" },
  { id: 14626, s: "GEN.AX", n: "Genmin", c: "Australia" },
  { id: 14627, s: "FSA.AX", n: "FSA Group", c: "Australia" },
  { id: 14628, s: "180640.KS", n: "Hanjinkal", c: "Korea" },
  { id: 14629, s: "192650.KS", n: "DreamTech", c: "Korea" },
  { id: 14630, s: "G1A.AX", n: "Galena Mining", c: "Australia" },
  { id: 14631, s: "601619.SS", n: "Ningxia Jiaze Renewables Co", c: "CH" },
  { id: 14632, s: "G88.AX", n: "Golden Mile Resources", c: "Australia" },
  { id: 14633, s: "GAP.AX", n: "Gale Pacific", c: "Australia" },
  { id: 14634, s: "GBR.AX", n: "Great Boulder Resources", c: "Australia" },
  { id: 14635, s: "601600.SS", n: "Aluminum Corp of CH", c: "CH" },
  { id: 14636, s: "GCR.AX", n: "Golden Cross Resources", c: "Australia" },
  { id: 14637, s: "601577.SS", n: "Bank of Changsha", c: "CH" },
  { id: 14638, s: "GDA.AX", n: "Good Drinks Australia", c: "Australia" },
  { id: 14639, s: "GED.AX", n: "Golden Deeps", c: "Australia" },
  { id: 14640, s: "FNX.AX", n: "Finexia Financial Group", c: "Australia" },
  { id: 14641, s: "145270.KS", n: "Ktop Reit", c: "Korea" },
  { id: 14642, s: "300459.SZ", n: "Zhejiang Jinke Peroxides", c: "CH" },
  { id: 14643, s: "109860.KQ", n: "Dongil Metal Co.", c: "Korea" },
  { id: 14644, s: "FGL.AX", n: "Frugl Group", c: "Australia" },
  { id: 14645, s: "EQR.AX", n: "EQ Resources", c: "Australia" },
  { id: 14646, s: "300571.SZ", n: "Hangzhou Anysoft Information Technology", c: "CH" },
  { id: 14647, s: "081660.KS", n: "Fila Holdings Co", c: "Korea" },
  { id: 14648, s: "ERL.AX", n: "Empire Resources", c: "Australia" },
  { id: 14649, s: "ERW.AX", n: "Errawarra Resources Ltd", c: "Australia" },
  { id: 14650, s: "082740.KS", n: "HSD Engine", c: "Korea" },
  { id: 14651, s: "601900.SS", n: "Southern Publishing&Media", c: "CH" },
  { id: 14652, s: "688055.SS", n: "InfoVision Optoelectronics Kunshan", c: "CH" },
  { id: 14653, s: "300552.SZ", n: "Vanjee Technology", c: "CH" },
  { id: 14654, s: "088260.KS", n: "E KOCREF CR REIT", c: "Korea" },
  { id: 14655, s: "FRS.AX", n: "Forrestania Resources", c: "Australia" },
  { id: 14656, s: "FRI.AX", n: "Finbar Group", c: "Australia" },
  { id: 14657, s: "DM1.AX", n: "Desert Metals Ltd", c: "Australia" },
  { id: 14658, s: "603105.SS", n: "Zhejiang Xinneng Photovoltaic Technology", c: "CH" },
  { id: 14659, s: "DME.AX", n: "Dome Gold Mines", c: "Australia" },
  { id: 14660, s: "DOC.AX", n: "Doctor Care Anywhere Group Plc", c: "Australia" },
  { id: 14661, s: "144960.KQ", n: "New Power Plasma Co.Ltd", c: "Korea" },
  { id: 14662, s: "029780.KS", n: "Samsung Card Co..", c: "Korea" },
  { id: 14663, s: "DRA.AX", n: "DRA Global", c: "Australia" },
  { id: 14664, s: "DRX.AX", n: "Diatreme Resources", c: "Australia" },
  { id: 14665, s: "300719.SZ", n: "Beijing Andawell Science & Technology Co Class A", c: "CH" },
  { id: 14666, s: "002649.SZ", n: "Beyondsoft Co", c: "CH" },
  { id: 14667, s: "DTM.AX", n: "Dart Mining NL", c: "Australia" },
  { id: 14668, s: "603169.SS", n: "Lanzhou LS Heavy Equipment", c: "CH" },
  { id: 14669, s: "603129.SS", n: "Zhejiang CFMoto Power", c: "CH" },
  { id: 14670, s: "603108.SS", n: "Shanghai Runda Med Tech", c: "CH" },
  { id: 14671, s: "DUG.AX", n: "Dug Technology Ltd", c: "Australia" },
  { id: 14672, s: "DVL.AX", n: "DorsaVi", c: "Australia" },
  { id: 14673, s: "PEXNY", n: "PTT Exploration & Production", c: "US" },
  { id: 14674, s: "603063.SS", n: "Shenzhen Hopewind Elec", c: "CH" },
  { id: 14675, s: "131970.KQ", n: "Tesna Inc", c: "Korea" },
  { id: 14676, s: "DXI.AX", n: "Dexus Industria REIT Unit", c: "Australia" },
  { id: 14677, s: "DXN.AX", n: "Dxn", c: "Australia" },
  { id: 14678, s: "071050.KS", n: "Korea Investment Holdings", c: "Korea" },
  { id: 14679, s: "300576.SZ", n: "Shenzhen Rongda Photosensitive & Technology", c: "CH" },
  { id: 14680, s: "078520.KS", n: "Able C&C", c: "Korea" },
  { id: 14681, s: "138040.KS", n: "Meritz Financi", c: "Korea" },
  { id: 14682, s: "FOR.AX", n: "Forager Australian Shares Fund", c: "Australia" },
  { id: 14683, s: "FOS.AX", n: "FOS Capital", c: "Australia" },
  { id: 14684, s: "138930.KS", n: "BNK Financial Group Inc", c: "Korea" },
  { id: 14685, s: "FPP.AX", n: "Fat Prophets Global Property Fund", c: "Australia" },
  { id: 14686, s: "139130.KS", n: "Dgb Financial", c: "Korea" },
  { id: 14687, s: "MSW.WA", n: "Mostostal Warszawa S.A.", c: "Poland" },
  { id: 14688, s: "DOU.AX", n: "Douugh", c: "Australia" },
  { id: 14689, s: "KKO.AX", n: "Kinetiko Energy", c: "Australia" },
  { id: 14690, s: "HRZ.AX", n: "Horizon Minerals Limited", c: "Australia" },
  { id: 14691, s: "AKM.AX", n: "Aspire Mining", c: "Australia" },
  { id: 14692, s: "BDX.AX", n: "BCAL Diagnostics", c: "Australia" },
  { id: 14693, s: "BEX.AX", n: "BikeExchange Pty", c: "Australia" },
  { id: 14694, s: "007070.KS", n: "Gs Retail", c: "Korea" },
  { id: 14695, s: "BFG.AX", n: "Bell Financial Group", c: "Australia" },
  { id: 14696, s: "688122.SS", n: "Western Superconducting Tech", c: "CH" },
  { id: 14697, s: "LEBEK.AT", n: "N. Leventeris S.A.", c: "Greece" },
  { id: 14698, s: "AJL.AX", n: "AJ Lucas Group", c: "Australia" },
  { id: 14699, s: "BHD.AX", n: "Benjamin Hornigold", c: "Australia" },
  { id: 14700, s: "BIR.AX", n: "Bir Financial Limited", c: "Australia" },
  { id: 14701, s: "002001.SZ", n: "Zhejiang Nhu", c: "CH" },
  { id: 14702, s: "AIM.AX", n: "AiMedia Technologies", c: "Australia" },
  { id: 14703, s: "AHX.AX", n: "Apiam Animal Health", c: "Australia" },
  { id: 14704, s: "BKG.AX", n: "Booktopia Group Ltd", c: "Australia" },
  { id: 14705, s: "007700.KS", n: "F&F Holdings Co.", c: "Korea" },
  { id: 14706, s: "001390.KS", n: "Kg Chemical", c: "Korea" },
  { id: 14707, s: "688082.SS", n: "ACM Research Shanghai Inc", c: "CH" },
  { id: 14708, s: "603380.SS", n: "Suzhou Etron Technologies", c: "CH" },
  { id: 14709, s: "603360.SS", n: "Dalian Bio-Chem", c: "CH" },
  { id: 14710, s: "300768.SZ", n: "Hangzhou DPtech Technologies", c: "CH" },
  { id: 14711, s: "601010.SS", n: "Wenfeng Great World Chain Development Co", c: "CH" },
  { id: 14712, s: "601155.SS", n: "Seazen Holdings", c: "CH" },
  { id: 14713, s: "601127.SS", n: "Chongqing Sokon Ind Grp", c: "CH" },
  { id: 14714, s: "601117.SS", n: "CH Natl Chem Engineering", c: "CH" },
  { id: 14715, s: "HHR.AX", n: "Hartshead Resources NL", c: "Australia" },
  { id: 14716, s: "1AT.WA", n: "Atal SA", c: "Poland" },
  { id: 14717, s: "HIQ.AX", n: "HitiQ", c: "Australia" },
  { id: 14718, s: "601068.SS", n: "CH Aluminum International Engineering Co", c: "CH" },
  { id: 14719, s: "HNG.AX", n: "Hancock & Gore", c: "Australia" },
  { id: 14720, s: "BCT.AX", n: "Bluechiip", c: "Australia" },
  { id: 14721, s: "BCC.AX", n: "Beam Communications Holdings", c: "Australia" },
  { id: 14722, s: "OJIPY", n: "Oji Holdings Corp ADR", c: "US" },
  { id: 14723, s: "AFW.AX", n: "Applyflow", c: "Australia" },
  { id: 14724, s: "AGN.AX", n: "Argenica Therapeutics", c: "Australia" },
  { id: 14725, s: "BMM.AX", n: "Balkan Mining and Minerals", c: "Australia" },
  { id: 14726, s: "BMO.AX", n: "Bastion Minerals", c: "Australia" },
  { id: 14727, s: "KIN.AX", n: "KIN Mining NL", c: "Australia" },
  { id: 14728, s: "BMT.AX", n: "Beamtree Holdings", c: "Australia" },
  { id: 14729, s: "AGH.AX", n: "Althea Group Holdings", c: "Australia" },
  { id: 14730, s: "BNL.AX", n: "Blue Star Helium", c: "Australia" },
  { id: 14731, s: "000990.KS", n: "DB HiTek", c: "Korea" },
  { id: 14732, s: "BOA.AX", n: "Boadicea Resources", c: "Australia" },
  { id: 14733, s: "BOD.AX", n: "Bod Science Limited", c: "Australia" },
  { id: 14734, s: "000850.KS", n: "Hwacheon Mac", c: "Korea" },
  { id: 14735, s: "BPP.AX", n: "Babylon Pump & Power", c: "Australia" },
  { id: 14736, s: "BC8.AX", n: "Black Cat Syndicate", c: "Australia" },
  { id: 14737, s: "BRU.AX", n: "Buru Energy", c: "Australia" },
  { id: 14738, s: "BCA.AX", n: "Black Canyon", c: "Australia" },
  { id: 14739, s: "UNVC", n: "Univec Inc", c: "US" },
  { id: 14740, s: "BSA.AX", n: "BSA", c: "Australia" },
  { id: 14741, s: "PRODEA.AT", n: "Prodea Real Estate Investment Co.", c: "Greece" },
  { id: 14742, s: "SCNA", n: "Smart Cannabis Co", c: "US" },
  { id: 14743, s: "AFA.AX", n: "ASF Group", c: "Australia" },
  { id: 14744, s: "AEV.AX", n: "Avenira", c: "Australia" },
  { id: 14745, s: "SCPPF", n: "S4 Capital plc", c: "US" },
  { id: 14746, s: "AEF.AX", n: "Australian Ethical Investment", c: "Australia" },
  { id: 14747, s: "BTR.AX", n: "Brightstar Resources", c: "Australia" },
  { id: 14748, s: "BMG.AX", n: "BMG Resources", c: "Australia" },
  { id: 14749, s: "002399.SZ", n: "Shenzhen Hepalink Pharmaceutical", c: "CH" },
  { id: 14750, s: "AHC.AX", n: "Austco Healthcare", c: "Australia" },
  { id: 14751, s: "AHF.AX", n: "Australian Dairy Farms Group", c: "Australia" },
  { id: 14752, s: "THCP", n: "Thunder Bridge Capital Partners IV Inc", c: "US" },
  { id: 14753, s: "BCI.AX", n: "BCI Minerals Limited", c: "Australia" },
  { id: 14754, s: "SNMRY", n: "Snam SpA ADR", c: "US" },
  { id: 14755, s: "GPR.AX", n: "Geopacific Resources Limited", c: "Australia" },
  { id: 14756, s: "601228.SS", n: "GZ Port", c: "CH" },
  { id: 14757, s: "300299.SZ", n: "Fuchun Technology", c: "CH" },
  { id: 14758, s: "GSS.AX", n: "Genetic Signatures", c: "Australia" },
  { id: 14759, s: "GTN.AX", n: "Gtn", c: "Australia" },
  { id: 14760, s: "601211.SS", n: "Guotai Junan Securities", c: "CH" },
  { id: 14761, s: "002774.SZ", n: "IFE Elevators", c: "CH" },
  { id: 14762, s: "601158.SS", n: "Chongqing Water Group", c: "CH" },
  { id: 14763, s: "GVF.AX", n: "Global Value Fund", c: "Australia" },
  { id: 14764, s: "000627.SZ", n: "Hubei Biocause Pharmaceutical", c: "CH" },
  { id: 14765, s: "IBG.AX", n: "Ironbark Zinc", c: "Australia" },
  { id: 14766, s: "ICI.AX", n: "iCandy Interactive", c: "Australia" },
  { id: 14767, s: "600795.SS", n: "GD Power Development", c: "CH" },
  { id: 14768, s: "JCS.AX", n: "JCurve Solutions", c: "Australia" },
  { id: 14769, s: "JWW.WA", n: "Jwwinvest", c: "Poland" },
  { id: 14770, s: "INCC", n: "International Consolidated Companies Inc", c: "US" },
  { id: 14771, s: "JNO.AX", n: "Juno Minerals", c: "Australia" },
  { id: 14772, s: "JTL.AX", n: "Jayex Technology", c: "Australia" },
  { id: 14773, s: "ILKAY", n: "Iluka Resources ADR", c: "US" },
  { id: 14774, s: "600855.SS", n: "Aerospace Cf", c: "CH" },
  { id: 14775, s: "600807.SS", n: "Jinan High tech Development", c: "CH" },
  { id: 14776, s: "600803.SS", n: "ENN Ecological Holdings", c: "CH" },
  { id: 14777, s: "300039.SZ", n: "Shanghai Kaibao Pharmaceutical", c: "CH" },
  { id: 14778, s: "600801.SS", n: "Huaxin Cement Co A", c: "CH" },
  { id: 14779, s: "KBC.AX", n: "Keybridge Capital", c: "Australia" },
  { id: 14780, s: "ATP.WA", n: "Atlanta Poland SA", c: "Poland" },
  { id: 14781, s: "600929.SS", n: "Snowsky Salt Industry Group", c: "CH" },
  { id: 14782, s: "KGL.AX", n: "KGL Resources", c: "Australia" },
  { id: 14783, s: "IIJIY", n: "Internet Initiative Japan Inc ADR", c: "US" },
  { id: 14784, s: "300030.SZ", n: "Guangzhou Improve Med Instrument", c: "CH" },
  { id: 14785, s: "GSR.AX", n: "Greenstone Resources", c: "Australia" },
  { id: 14786, s: "DLM.AX", n: "Dominion Minerals", c: "Australia" },
  { id: 14787, s: "307950.KS", n: "Hyundai Autoever Co", c: "Korea" },
  { id: 14788, s: "601156.SS", n: "Eastern Air Logistics", c: "CH" },
  { id: 14789, s: "HT8.AX", n: "Harris Technology Group", c: "Australia" },
  { id: 14790, s: "600998.SS", n: "Jointown Pharmaceutical Group", c: "CH" },
  { id: 14791, s: "300199.SZ", n: "Hybio Pharmaceutical", c: "CH" },
  { id: 14792, s: "HUM.AX", n: "Humm Group", c: "Australia" },
  { id: 14793, s: "HXL.AX", n: "Hexima", c: "Australia" },
  { id: 14794, s: "HYD.AX", n: "Hydrix", c: "Australia" },
  { id: 14795, s: "600976.SS", n: "Jianmin Pharmaceutical Group", c: "CH" },
  { id: 14796, s: "CAR.WA", n: "Inter Cars S.A.", c: "Poland" },
  { id: 14797, s: "IAM.AX", n: "Complii FinTech Solutions", c: "Australia" },
  { id: 14798, s: "600967.SS", n: "Inner Mongolia First Machinery Group", c: "CH" },
  { id: 14799, s: "HAR.AX", n: "Haranga Resources", c: "Australia" },
  { id: 14800, s: "300160.SZ", n: "Jiangsu Xiuqiang Glasswork", c: "CH" },
  { id: 14801, s: "GWR.AX", n: "GWR Group", c: "Australia" },
  { id: 14802, s: "600933.SS", n: "IKD Co Class A", c: "CH" },
  { id: 14803, s: "GW1.AX", n: "Greenwing Resources", c: "Australia" },
  { id: 14804, s: "GLL.AX", n: "Galilee Energy", c: "Australia" },
  { id: 14805, s: "267270.KS", n: "Hyundai Construction Equipment", c: "Korea" },
  { id: 14806, s: "601377.SS", n: "Industrial Securities", c: "CH" },
  { id: 14807, s: "601456.SS", n: "Guolian Securities", c: "CH" },
  { id: 14808, s: "GMR.AX", n: "Golden Rim Resources", c: "Australia" },
  { id: 14809, s: "GML.AX", n: "Gateway Mining", c: "Australia" },
  { id: 14810, s: "GLH.AX", n: "Global Health", c: "Australia" },
  { id: 14811, s: "GLA.AX", n: "Gladiator Resources", c: "Australia" },
  { id: 14812, s: "601333.SS", n: "Guangshen Railway", c: "CH" },
  { id: 14813, s: "GGX.AX", n: "Gas2grid", c: "Australia" },
  { id: 14814, s: "GGE.AX", n: "Grand Gulf Energy", c: "Australia" },
  { id: 14815, s: "LONE.V", n: "Lithium One Metals Inc", c: "CA" },
  { id: 14816, s: "IHC.L", n: "Inspiration Healthcare Group PLC", c: "UK" },
  { id: 14817, s: "TGA.AX", n: "Thorn Group", c: "Australia" },
  { id: 14818, s: "002088.SZ", n: "Shandong Luyang Share", c: "CH" },
  { id: 14819, s: "SERT.ST", n: "Serstech AB", c: "Sweden" },
  { id: 14820, s: "WCE.V", n: "WesCan Energy Co", c: "CA" },
  { id: 14821, s: "WLMIY", n: "Wilmar International", c: "US" },
  { id: 14822, s: "000962.SZ", n: "Ningxia Orient Tantalum Industry", c: "CH" },
  { id: 14823, s: "UBK.DE", n: "UmweltBank AG", c: "GER" },
  { id: 14824, s: "TGH.AX", n: "Terragen Holdings", c: "Australia" },
  { id: 14825, s: "LRD.AX", n: "Lord Resources", c: "Australia" },
  { id: 14826, s: "MARTI.IS", n: "Marti Otel Isletmeleri AS", c: "Turkey" },
  { id: 14827, s: "002081.SZ", n: "Suzhou Gold Mantis Construction Decoration", c: "CH" },
  { id: 14828, s: "MAALT.IS", n: "Marmaris Altinyunus Turistik Tesisleri AS", c: "Turkey" },
  { id: 14829, s: "RS-PA.TO", n: "Real Estate & E-Commerce Split Co", c: "CA" },
  { id: 14830, s: "OTEC.OL", n: "Otello Corporation ASA", c: "Norway" },
  { id: 14831, s: "105550.KQ", n: "Truwin", c: "Korea" },
  { id: 14832, s: "TELO.V", n: "Telo Genomics Co", c: "CA" },
  { id: 14833, s: "PPM.AX", n: "Pepper Money", c: "Australia" },
  { id: 14834, s: "038620.KQ", n: "WIZ CORP Inc", c: "Korea" },
  { id: 14835, s: "PFC.V", n: "PetroFrontier Co", c: "CA" },
  { id: 14836, s: "POL.OL", n: "Polaris Media", c: "Norway" },
  { id: 14837, s: "RDS.AX", n: "Redstone Resources", c: "Australia" },
  { id: 14838, s: "GBQ.HM", n: "GBK Beteiligungen AG", c: "GER" },
  { id: 14839, s: "CEL.L", n: "Celadon Pharmaceuticals PLC", c: "UK" },
  { id: 14840, s: "000987.SZ", n: "Guangzhou Friendship Group", c: "CH" },
  { id: 14841, s: "FONDIA.HE", n: "Fondia Oyj", c: "Finland" },
  { id: 14842, s: "NYKD.OL", n: "Nykode Therapeutics ASA", c: "Norway" },
  { id: 14843, s: "BISI.L", n: "Bisichi Mining PLC", c: "UK" },
  { id: 14844, s: "FPE3.HM", n: "Fuchs Petrolub SE", c: "GER" },
  { id: 14845, s: "KOSK", n: "One Step Vending Co", c: "US" },
  { id: 14846, s: "SER.AX", n: "Strategic Energy Resources", c: "Australia" },
  { id: 14847, s: "ALA-PB.TO", n: "Altagas Pref B", c: "CA" },
  { id: 14848, s: "002290.KQ", n: "Samil Enterprise Co.", c: "Korea" },
  { id: 14849, s: "TNY.AX", n: "Tinybeans Group", c: "Australia" },
  { id: 14850, s: "WAM.V", n: "Western Alaska Minerals Co", c: "CA" },
  { id: 14851, s: "TFL.AX", n: "TasFoods", c: "Australia" },
  { id: 14852, s: "BIZIM.IS", n: "Bizim Toptan Satis Magazalari AS", c: "Turkey" },
  { id: 14853, s: "040610.KQ", n: "SG&G Corporation", c: "Korea" },
  { id: 14854, s: "CPI.AT", n: "CPI Computer Peripherals International", c: "Greece" },
  { id: 14855, s: "MERKO.IS", n: "Merko Gida Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 14856, s: "MIATK.IS", n: "Mia Teknoloji AS", c: "Turkey" },
  { id: 14857, s: "MAF.AX", n: "MA Financial Group", c: "Australia" },
  { id: 14858, s: "MAP.AX", n: "Microba Life Sciences", c: "Australia" },
  { id: 14859, s: "OZKGY.IS", n: "Ozak Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 14860, s: "002126.SZ", n: "Zhejiang Yinlun Machinery", c: "CH" },
  { id: 14861, s: "002127.SZ", n: "NanJi ECommerce", c: "CH" },
  { id: 14862, s: "AAB.TO", n: "Aberdeen International Inc.", c: "CA" },
  { id: 14863, s: "PPY.AX", n: "Papyrus Australia", c: "Australia" },
  { id: 14864, s: "ORTI-A.ST", n: "Ortivus AB ser. A", c: "Sweden" },
  { id: 14865, s: "ZON.V", n: "Zonte Metals Inc", c: "CA" },
  { id: 14866, s: "TMX.AX", n: "Terrain Minerals", c: "Australia" },
  { id: 14867, s: "170790.KQ", n: "Piolink Inc", c: "Korea" },
  { id: 14868, s: "TEK.AX", n: "Thorney Technologies", c: "Australia" },
  { id: 14869, s: "MERCN.IS", n: "Mercan Kimya Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 14870, s: "TEG.AX", n: "Triangle Energy Global", c: "Australia" },
  { id: 14871, s: "VSR.V", n: "Vanstar Mining Resources Inc", c: "CA" },
  { id: 14872, s: "LM8.AX", n: "Lunnon Metals", c: "Australia" },
  { id: 14873, s: "VML.V", n: "Viscount Mining Corp.", c: "CA" },
  { id: 14874, s: "LPS.V", n: "Legend Power Systems Inc", c: "CA" },
  { id: 14875, s: "KRTEK.IS", n: "Karsu Tekstil Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 14876, s: "TAM.AX", n: "Tanami Gold NL", c: "Australia" },
  { id: 14877, s: "TAR.AX", n: "Taruga Gold", c: "Australia" },
  { id: 14878, s: "002075.SZ", n: "Jiangsu Shagang", c: "CH" },
  { id: 14879, s: "BIOKA.AT", n: "Biokarpet S.A.", c: "Greece" },
  { id: 14880, s: "HRN.L", n: "Hornby PLC", c: "UK" },
  { id: 14881, s: "040160.KQ", n: "NuriFlex Co.Ltd", c: "Korea" },
  { id: 14882, s: "LKMNH.IS", n: "Lokman Hekim Engurusag Saglik Turizm Egitim Hizmetleri ve Insaat Taahhut AS", c: "Turkey" },
  { id: 14883, s: "AVE.AT", n: "AVE S.A.", c: "Greece" },
  { id: 14884, s: "TCF.AX", n: "360 Capital Mortgage REIT", c: "Australia" },
  { id: 14885, s: "TD1.AX", n: "Tali Digital", c: "Australia" },
  { id: 14886, s: "079650.KQ", n: "Seosan Corporation", c: "Korea" },
  { id: 14887, s: "076610.KQ", n: "Haesung Optics Co.", c: "Korea" },
  { id: 14888, s: "KLYG", n: "Kelyniam Global Inc", c: "US" },
  { id: 14889, s: "QPM.V", n: "Quebec Precious Metals Corporation", c: "CA" },
  { id: 14890, s: "065440.KQ", n: "ELUON Corporation", c: "Korea" },
  { id: 14891, s: "RDAR", n: "RAADR Inc", c: "US" },
  { id: 14892, s: "ANGEN.IS", n: "Anatolia Tani ve Biyoteknoloji Urunleri Arastirma Gelistirme Sanayi ve Ticaret", c: "Turkey" },
  { id: 14893, s: "CBX.L", n: "Cellular Goods PLC", c: "UK" },
  { id: 14894, s: "078070.KQ", n: "Ubiquoss Holdings Inc", c: "Korea" },
  { id: 14895, s: "064820.KQ", n: "Cape Industries", c: "Korea" },
  { id: 14896, s: "POR.V", n: "Portofino Resources Inc", c: "CA" },
  { id: 14897, s: "066620.KQ", n: "Kukbo Design Co.", c: "Korea" },
  { id: 14898, s: "067770.KQ", n: "Sejin T.S Co.Ltd", c: "Korea" },
  { id: 14899, s: "GRXXF", n: "Oz Lithium Co", c: "US" },
  { id: 14900, s: "BFREN.IS", n: "Bosch Fren Sistemleri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 14901, s: "BRKO.IS", n: "Birko Birlesik Koyunlulular Mensucat Ticaret ve Sanayi AS", c: "Turkey" },
  { id: 14902, s: "068790.KQ", n: "DMS Co.Ltd", c: "Korea" },
  { id: 14903, s: "KDDIF", n: "KDDI Corp.", c: "US" },
  { id: 14904, s: "PV1.AX", n: "Provaris Energy", c: "Australia" },
  { id: 14905, s: "PINK.V", n: "Perimeter Medical Imaging AI Inc", c: "CA" },
  { id: 14906, s: "000526.SZ", n: "Xiamen Insight Investment", c: "CH" },
  { id: 14907, s: "RCE.AX", n: "Recce", c: "Australia" },
  { id: 14908, s: "QVE.AX", n: "QV Equities", c: "Australia" },
  { id: 14909, s: "S66.AX", n: "Star Combo Pharma", c: "Australia" },
  { id: 14910, s: "MOS.V", n: "Mobi724 Global Solutions Inc", c: "CA" },
  { id: 14911, s: "TOT.AX", n: "360 Capital Total Return Fund", c: "Australia" },
  { id: 14912, s: "086900.KQ", n: "Medy-Tox Inc", c: "Korea" },
  { id: 14913, s: "CRCL.L", n: "Corcel PLC", c: "UK" },
  { id: 14914, s: "HGEA.DE", n: "hGears AG", c: "GER" },
  { id: 14915, s: "HPC.AX", n: "Hydration Pharmaceuticals Company", c: "Australia" },
  { id: 14916, s: "065530.KQ", n: "Wiable Co", c: "Korea" },
  { id: 14917, s: "R8R.AX", n: "Regener8 Resources NL", c: "Australia" },
  { id: 14918, s: "BWB.DE", n: "Baader Bank Aktiengesellschaft", c: "GER" },
  { id: 14919, s: "PSI.AX", n: "PSC Insurance Group", c: "Australia" },
  { id: 14920, s: "ALISA.HE", n: "Alisa Pankki Oyj", c: "Finland" },
  { id: 14921, s: "BOOK.L", n: "Literacy Capital PLC", c: "UK" },
  { id: 14922, s: "000513.SZ", n: "Livzon Pharmaceutical Group Inc", c: "CH" },
  { id: 14923, s: "BZT.L", n: "Bezant Resources Plc", c: "UK" },
  { id: 14924, s: "070590.KQ", n: "Hansol Inticube Co.", c: "Korea" },
  { id: 14925, s: "PRM.AX", n: "Prominence Energy NL", c: "Australia" },
  { id: 14926, s: "AYGAZ.IS", n: "Aygaz AS", c: "Turkey" },
  { id: 14927, s: "060250.KQ", n: "NHN KCP Co", c: "Korea" },
  { id: 14928, s: "MARAS.HE", n: "Martela Oyj A", c: "Finland" },
  { id: 14929, s: "ARIZ", n: "Arisz Acquisition Co", c: "US" },
  { id: 14930, s: "BEM.L", n: "Beowulf Mining", c: "UK" },
  { id: 14931, s: "059120.KQ", n: "Ajinextek Co.", c: "Korea" },
  { id: 14932, s: "CORO.L", n: "Coro Energy PLC", c: "UK" },
  { id: 14933, s: "NIXU.HE", n: "Nixu Oyj", c: "Finland" },
  { id: 14934, s: "IHL.AX", n: "Incannex Healthcare", c: "Australia" },
  { id: 14935, s: "086390.KQ", n: "UniTest Incorporation", c: "Korea" },
  { id: 14936, s: "LYTIX.OL", n: "Lytix Biopharma AS", c: "Norway" },
  { id: 14937, s: "060900.KQ", n: "Daehan Green Power Corporation", c: "Korea" },
  { id: 14938, s: "FDD.HM", n: "Pilkington Deutschland AG", c: "GER" },
  { id: 14939, s: "REM.AX", n: "Remsense Technologies", c: "Australia" },
  { id: 14940, s: "078940.KQ", n: "Codenature Inc", c: "Korea" },
  { id: 14941, s: "HYGG.AX", n: "Hyperion Global Growth Companies Fund (Managed Fund)", c: "Australia" },
  { id: 14942, s: "PDM.V", n: "Palladium One Mining", c: "CA" },
  { id: 14943, s: "MULTI.OL", n: "Multiconsult AS", c: "Norway" },
  { id: 14944, s: "FORMT.IS", n: "Formet Celik Kapi Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 14945, s: "078860.KQ", n: "IokCompany Co.", c: "Korea" },
  { id: 14946, s: "071850.KQ", n: "Castec Korea", c: "Korea" },
  { id: 14947, s: "BUT.L", n: "Brunner Investment Trust", c: "UK" },
  { id: 14948, s: "AYDEM.IS", n: "Aydem Yenilenebilir Enerji AS", c: "Turkey" },
  { id: 14949, s: "HAL.AX", n: "Halo Technologies Holdings", c: "Australia" },
  { id: 14950, s: "000981.SZ", n: "Yinyi Real Estate", c: "CH" },
  { id: 14951, s: "ISB.IC", n: "Íslandsbanki hf.", c: "Iceland" },
  { id: 14952, s: "BOD.L", n: "Botswana Diamonds plc", c: "UK" },
  { id: 14953, s: "ESCOM.IS", n: "Escort Teknoloji Yatirim AS", c: "Turkey" },
  { id: 14954, s: "QFR.OL", n: "Q-Free ASA", c: "Norway" },
  { id: 14955, s: "HRTIS.HE", n: "Herantis Pharma Oyj", c: "Finland" },
  { id: 14956, s: "KHG.HE", n: "KH Group Oyj", c: "Finland" },
  { id: 14957, s: "KPCPY", n: "Kasikornbank Public", c: "US" },
  { id: 14958, s: "060480.KQ", n: "Kukil Metal Co.", c: "Korea" },
  { id: 14959, s: "060380.KQ", n: "Dongyang S.Tec Co.Ltd", c: "Korea" },
  { id: 14960, s: "ORES.ST", n: "Investment AB Oresund", c: "Sweden" },
  { id: 14961, s: "CAF.PA", n: "Caisse Régionale de Crédit Agricole Mutuel de Paris et d'Ile-de-FR", c: "FR" },
  { id: 14962, s: "TOU.AX", n: "Tlou Energy", c: "Australia" },
  { id: 14963, s: "UNIBAP.ST", n: "Unibap AB", c: "Sweden" },
  { id: 14964, s: "YATRY", n: "Yamato Holdings Co ADR", c: "US" },
  { id: 14965, s: "VPLAY-A.ST", n: "Viaplay Group AB Series A", c: "Sweden" },
  { id: 14966, s: "127120.KQ", n: "DNA Link Inc", c: "Korea" },
  { id: 14967, s: "KNFRT.IS", n: "Konfrut Gida Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 14968, s: "128540.KQ", n: "Ecocab Co.", c: "Korea" },
  { id: 14969, s: "SPN.AX", n: "Sparc Technologies", c: "Australia" },
  { id: 14970, s: "130500.KQ", n: "Gumho NT", c: "Korea" },
  { id: 14971, s: "ZHUD", n: "Zhuding International", c: "US" },
  { id: 14972, s: "GMR.L", n: "Gaming Realms plc", c: "UK" },
  { id: 14973, s: "MCI.V", n: "Minnova Co", c: "CA" },
  { id: 14974, s: "ZENO", n: "Zenosense Inc", c: "US" },
  { id: 14975, s: "SRJ.AX", n: "Srj Technologies Group Plc", c: "Australia" },
  { id: 14976, s: "MASS.V", n: "Masivo Silver Co", c: "CA" },
  { id: 14977, s: "SSR.AX", n: "Ssr Mining Inc", c: "Australia" },
  { id: 14978, s: "043200.KQ", n: "Paru Co.", c: "Korea" },
  { id: 14979, s: "USS.V", n: "Uniserve Communications Co", c: "CA" },
  { id: 14980, s: "SW1.AX", n: "Swift Networks Group", c: "Australia" },
  { id: 14981, s: "ONCOZ.ST", n: "Oncozenge AB", c: "Sweden" },
  { id: 14982, s: "041830.KQ", n: "InBody Co.Ltd", c: "Korea" },
  { id: 14983, s: "KONKA.IS", n: "Konya Kagit Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 14984, s: "ELLAKTOR.AT", n: "Ellaktor S.A", c: "Greece" },
  { id: 14985, s: "VOLAB.ST", n: "Veg of Lund AB", c: "Sweden" },
  { id: 14986, s: "VIVA.ST", n: "Viva Wine Group AB", c: "Sweden" },
  { id: 14987, s: "VIMAB.ST", n: "VIMAB Group AB", c: "Sweden" },
  { id: 14988, s: "SUH.AX", n: "Southern Hemisphere Mining", c: "Australia" },
  { id: 14989, s: "140520.KQ", n: "Daechang Steel", c: "Korea" },
  { id: 14990, s: "MAS.V", n: "MAS Gold Co", c: "CA" },
  { id: 14991, s: "SUNPH.AX", n: "Suncorp Group Limited", c: "Australia" },
  { id: 14992, s: "VENZ.V", n: "Venzee Technologies Inc", c: "CA" },
  { id: 14993, s: "OAK.AX", n: "Oakridge International", c: "Australia" },
  { id: 14994, s: "TROAX.ST", n: "Troax Group AB", c: "Sweden" },
  { id: 14995, s: "HEFT.L", n: "Henderson European Focus Trust Plc", c: "UK" },
  { id: 14996, s: "HERB", n: "Yasheng Group", c: "US" },
  { id: 14997, s: "PXFG", n: "Phoenix Footwear Group Inc", c: "US" },
  { id: 14998, s: "ZAAG", n: "ZA Group Inc", c: "US" },
  { id: 14999, s: "USCU.V", n: "US Copper Co", c: "CA" },
  { id: 15000, s: "042940.KQ", n: "Sangji Caelum Inc", c: "Korea" },
  { id: 15001, s: "ST1.AX", n: "Spirit Telecom", c: "Australia" },
  { id: 15002, s: "603055.SS", n: "Zhejiang Taihua New Material", c: "CH" },
  { id: 15003, s: "300042.SZ", n: "Netac Tech", c: "CH" },
  { id: 15004, s: "LWB.WA", n: "Lubelski Wegiel Bogdanka S.A.", c: "Poland" },
  { id: 15005, s: "603036.SS", n: "Jiangsu Rutong Petro-Machinery", c: "CH" },
  { id: 15006, s: "300033.SZ", n: "Hithink RoyalFlush Info Network", c: "CH" },
  { id: 15007, s: "MGT.WA", n: "Mangata Holding S.A.", c: "Poland" },
  { id: 15008, s: "603029.SS", n: "Shandong Swan Cotton", c: "CH" },
  { id: 15009, s: "GNG.V", n: "Golden Goliath Resources.", c: "CA" },
  { id: 15010, s: "GAME.V", n: "GameSquare Holdings Inc.", c: "CA" },
  { id: 15011, s: "300020.SZ", n: "Enjoyor", c: "CH" },
  { id: 15012, s: "002662.SZ", n: "Beijing WKW Automotive Parts", c: "CH" },
  { id: 15013, s: "GTC.WA", n: "Globe Trade Centre S.A.", c: "Poland" },
  { id: 15014, s: "002654.SZ", n: "Shenzhen Mason Technologies", c: "CH" },
  { id: 15015, s: "001212.SZ", n: "Sinostone Guangdong", c: "CH" },
  { id: 15016, s: "603159.SS", n: "Shanghai Yahong Moulding", c: "CH" },
  { id: 15017, s: "GXI.F", n: "Gerresheimer AG", c: "GER" },
  { id: 15018, s: "603059.SS", n: "Perfect Group Corp Class A", c: "CH" },
  { id: 15019, s: "GGM.V", n: "Granada Gold Mine Inc", c: "CA" },
  { id: 15020, s: "603089.SS", n: "ADD Industry Zhejiang", c: "CH" },
  { id: 15021, s: "042500.KQ", n: "RingNet Co.", c: "Korea" },
  { id: 15022, s: "STM.AX", n: "Sunstone Metals", c: "Australia" },
  { id: 15023, s: "002010.SZ", n: "Zhejiang Transfar", c: "CH" },
  { id: 15024, s: "M3BK.DE", n: "Pyramid AG", c: "GER" },
  { id: 15025, s: "603988.SS", n: "SEC Electric Mach", c: "CH" },
  { id: 15026, s: "3149.TW", n: "G-Tech Optoelectronics Co", c: "TW" },
  { id: 15027, s: "IMP.WA", n: "Impera Capital S.A.", c: "Poland" },
  { id: 15028, s: "2443.TW", n: "Lead Data Inc", c: "TW" },
  { id: 15029, s: "300079.SZ", n: "Sumavision Technologies", c: "CH" },
  { id: 15030, s: "ITB.WA", n: "Interbud-Lublin SA", c: "Poland" },
  { id: 15031, s: "GIL.F", n: "DMG MORI AKTIENGESELLSCHAFT", c: "GER" },
  { id: 15032, s: "BOREF", n: "Borealis Exploration", c: "US" },
  { id: 15033, s: "BSD.PA", n: "Bourse Direct SA", c: "FR" },
  { id: 15034, s: "TBIIF", n: "Tobii AB", c: "US" },
  { id: 15035, s: "137940.KQ", n: "NextEye Co.", c: "Korea" },
  { id: 15036, s: "ALEFRM.", n: "Alefarm Brewing AS", c: "Denmark" },
  { id: 15037, s: "8011.TW", n: "Tai Tung Communication", c: "TW" },
  { id: 15038, s: "KLGYO.IS", n: "Kiler Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 15039, s: "V3S.DE", n: "Vectron Systems AG", c: "GER" },
  { id: 15040, s: "NPR.AX", n: "Newmark REIT Management Unit", c: "Australia" },
  { id: 15041, s: "037400.KQ", n: "Wooree Lighting Co.Ltd", c: "Korea" },
  { id: 15042, s: "LYN.AX", n: "Lycaon Resources", c: "Australia" },
  { id: 15043, s: "KWE.V", n: "Kwesst Micro Systems Inc", c: "CA" },
  { id: 15044, s: "IXI.L", n: "IXICO PLC", c: "UK" },
  { id: 15045, s: "203450.KQ", n: "Union Community Co.", c: "Korea" },
  { id: 15046, s: "ITS.L", n: "in Style Group PLC", c: "UK" },
  { id: 15047, s: "LR.V", n: "Luminex Resources Co", c: "CA" },
  { id: 15048, s: "GARPY", n: "Golden Agri-Resources", c: "US" },
  { id: 15049, s: "PULS.TA", n: "Pulsenmore", c: "Israel" },
  { id: 15050, s: "SLC.AX", n: "Superloop", c: "Australia" },
  { id: 15051, s: "115570.KQ", n: "StarFlex Co.", c: "Korea" },
  { id: 15052, s: "LLLI", n: "Lamperd Less Lethal", c: "US" },
  { id: 15053, s: "SFQ.DE", n: "SAF Holland SA", c: "GER" },
  { id: 15054, s: "IBGR", n: "Nexus Energy Services Inc", c: "US" },
  { id: 15055, s: "005710.KQ", n: "Daewon Sanup Co.", c: "Korea" },
  { id: 15056, s: "QLINEA.ST", n: "Q linea AB", c: "Sweden" },
  { id: 15057, s: "TMB.AX", n: "Tambourah Metals", c: "Australia" },
  { id: 15058, s: "NXTCL.ST", n: "NextCell Pharma AB", c: "Sweden" },
  { id: 15059, s: "AEZS.TO", n: "Aeterna Zentaris Inc", c: "CA" },
  { id: 15060, s: "002130.SZ", n: "Shenzhen Woer Heat-Shrinkable Material", c: "CH" },
  { id: 15061, s: "214180.KQ", n: "Human N Inc.", c: "Korea" },
  { id: 15062, s: "SEAF.ST", n: "Seafire AB", c: "Sweden" },
  { id: 15063, s: "036690.KQ", n: "COMMAX Co.Ltd", c: "Korea" },
  { id: 15064, s: "TGP.AX", n: "360 Capital Group", c: "Australia" },
  { id: 15065, s: "TIP.AX", n: "Teaminvest Private Group", c: "Australia" },
  { id: 15066, s: "THR.AX", n: "Thor Energy Plc", c: "Australia" },
  { id: 15067, s: "PTM.TO", n: "Platinum Group Metals", c: "CA" },
  { id: 15068, s: "TI1.AX", n: "Tombador Iron", c: "Australia" },
  { id: 15069, s: "005670.KQ", n: "FOODWELL Co.", c: "Korea" },
  { id: 15070, s: "191410.KQ", n: "Ryukil C&S", c: "Korea" },
  { id: 15071, s: "TIA.AX", n: "Tian AN Australia", c: "Australia" },
  { id: 15072, s: "DONKEY.", n: "Donkeyrepublic Holding AS", c: "Denmark" },
  { id: 15073, s: "120240.KQ", n: "Daejung Chemicals & Metals Co.Ltd", c: "Korea" },
  { id: 15074, s: "RMTD", n: "Remote Dynamics Inc", c: "US" },
  { id: 15075, s: "002030.SZ", n: "Da An Gene Co of Sun Yat-Sen University", c: "CH" },
  { id: 15076, s: "002019.SZ", n: "Yifan Xinfu Pharmaceutical", c: "CH" },
  { id: 15077, s: "FIF.L", n: "Finsbury Food Group", c: "UK" },
  { id: 15078, s: "046940.KQ", n: "Woowon Development Co.", c: "Korea" },
  { id: 15079, s: "SHN.AX", n: "Sunshine Metal Limited", c: "Australia" },
  { id: 15080, s: "KYP.AX", n: "Kinatico.", c: "Australia" },
  { id: 15081, s: "TMG.V", n: "Thermal Energy International Inc.", c: "CA" },
  { id: 15082, s: "SHP.AX", n: "South Harz Potash", c: "Australia" },
  { id: 15083, s: "046210.KQ", n: "Panagene Inc", c: "Korea" },
  { id: 15084, s: "SIH.AX", n: "Sihayo Gold", c: "Australia" },
  { id: 15085, s: "ACC.L", n: "Access Intelligence Plc", c: "UK" },
  { id: 15086, s: "HH.", n: "H+H International A/S", c: "Denmark" },
  { id: 15087, s: "SKN.AX", n: "Skin Elements", c: "Australia" },
  { id: 15088, s: "SLB.AX", n: "Stelar Metals", c: "Australia" },
  { id: 15089, s: "FED.", n: "Fast Ejendom", c: "Denmark" },
  { id: 15090, s: "SHO.AX", n: "SportsHero", c: "Australia" },
  { id: 15091, s: "8114.TW", n: "Posiflex Technology Inc", c: "TW" },
  { id: 15092, s: "AIRE.L", n: "Alternative Income REIT PLC", c: "UK" },
  { id: 15093, s: "NEWCAP.", n: "Newcap Holding A/S", c: "Denmark" },
  { id: 15094, s: "SP3.AX", n: "Spectur", c: "Australia" },
  { id: 15095, s: "ESG.", n: "Ennogie Solar Group A/S", c: "Denmark" },
  { id: 15096, s: "4BB.L", n: "4BASEBIO UK SOCIETAS", c: "UK" },
  { id: 15097, s: "FTV.L", n: "Foresight VCT PLC", c: "UK" },
  { id: 15098, s: "EHL.AX", n: "Emeco Holdings", c: "Australia" },
  { id: 15099, s: "SOM.AX", n: "SomnoMed", c: "Australia" },
  { id: 15100, s: "044180.KQ", n: "KD Corporation", c: "Korea" },
  { id: 15101, s: "SPG.TO", n: "Spark Power Group Inc", c: "CA" },
  { id: 15102, s: "NEXCOM.", n: "Nexcom AS", c: "Denmark" },
  { id: 15103, s: "002044.SZ", n: "Meinian Onehealth Healthcare Holdings", c: "CH" },
  { id: 15104, s: "SPA.AX", n: "Spacetalk", c: "Australia" },
  { id: 15105, s: "FTF.L", n: "Foresight Enterprise VCT PLC", c: "UK" },
  { id: 15106, s: "OCT.AX", n: "Octava Minerals", c: "Australia" },
  { id: 15107, s: "AATG.L", n: "Albion Technology & General VCT PLC", c: "UK" },
  { id: 15108, s: "115440.KQ", n: "WooriNet Inc", c: "Korea" },
  { id: 15109, s: "050760.KQ", n: "Spolytech Co.", c: "Korea" },
  { id: 15110, s: "032940.KQ", n: "Wonik Corporation", c: "Korea" },
  { id: 15111, s: "STA.V", n: "Sanatana Resources", c: "CA" },
  { id: 15112, s: "AFLT3.SA", n: "Afluente Transmissão de Energia Elétrica S.A", c: "Brazil" },
  { id: 15113, s: "023460.KQ", n: "CNH Co.", c: "Korea" },
  { id: 15114, s: "YYAPI.IS", n: "Yesil Yapi Endustrisi AS", c: "Turkey" },
  { id: 15115, s: "AVM.AX", n: "Advance Metals Limited", c: "Australia" },
  { id: 15116, s: "NTQ.L", n: "Enteq Upstream", c: "UK" },
  { id: 15117, s: "NUM.L", n: "Numis Co", c: "UK" },
  { id: 15118, s: "NWF.L", n: "N.W.F Group", c: "UK" },
  { id: 15119, s: "ENGI3.SA", n: "Energisa S.A", c: "Brazil" },
  { id: 15120, s: "AVD.AX", n: "Avada Group", c: "Australia" },
  { id: 15121, s: "3454.TW", n: "Vivotek Inc", c: "TW" },
  { id: 15122, s: "ENMT3.SA", n: "Energisa Mato Grosso - Distribuidora de Energia S/A", c: "Brazil" },
  { id: 15123, s: "002372.SZ", n: "Zhejiang Weixing New Building Materials", c: "CH" },
  { id: 15124, s: "OAP3.L", n: "Octopus Apollo VCT PLC", c: "UK" },
  { id: 15125, s: "OHG.L", n: "One Heritage Group PLC", c: "UK" },
  { id: 15126, s: "000960.SZ", n: "Yunnan Tin", c: "CH" },
  { id: 15127, s: "FESA3.SA", n: "Cia de Ferro Ligas da Bahia - FERBASA", c: "Brazil" },
  { id: 15128, s: "002342.SZ", n: "Juli Sling", c: "CH" },
  { id: 15129, s: "CEBR3.SA", n: "Companhia Energética de Brasília - CEB", c: "Brazil" },
  { id: 15130, s: "319660.KQ", n: "PSK Inc", c: "Korea" },
  { id: 15131, s: "HPMM", n: "Hemp Naturals Inc", c: "US" },
  { id: 15132, s: "HOT.MU", n: "HOCHTIEF Aktiengesellschaft", c: "GER" },
  { id: 15133, s: "EPIS-B.ST", n: "Episurf Medical AB (publ)", c: "Sweden" },
  { id: 15134, s: "ENZY.ST", n: "Enzymatica publ AB", c: "Sweden" },
  { id: 15135, s: "024910.KQ", n: "Kyung Chang Industrial Co.", c: "Korea" },
  { id: 15136, s: "018000.KQ", n: "Unison Co.", c: "Korea" },
  { id: 15137, s: "001470.KS", n: "Sambu Const", c: "Korea" },
  { id: 15138, s: "MOM.AX", n: "Moab Minerals.", c: "Australia" },
  { id: 15139, s: "002315.SZ", n: "Focus Technology", c: "CH" },
  { id: 15140, s: "EOX.TO", n: "Euromax Resources", c: "CA" },
  { id: 15141, s: "IPOK.MU", n: "Heidelberger Beteiligungsholding AG", c: "GER" },
  { id: 15142, s: "EMBELL.ST", n: "Embellence Group AB", c: "Sweden" },
  { id: 15143, s: "NDVLY", n: "New World Development Co ADR", c: "US" },
  { id: 15144, s: "HQGE", n: "HQ Global Education Inc", c: "US" },
  { id: 15145, s: "APER3.SA", n: "Alper Consultoria e Corretora de Seguros S.A", c: "Brazil" },
  { id: 15146, s: "688289.SS", n: "Sansure Biotech Inc", c: "CH" },
  { id: 15147, s: "EGIEY", n: "Engie Brasil Energia SA", c: "US" },
  { id: 15148, s: "002386.SZ", n: "Yibin Tianyuan Group", c: "CH" },
  { id: 15149, s: "BONAV-A.ST", n: "Bonava AB (publ)", c: "Sweden" },
  { id: 15150, s: "VFF.MU", n: "Vereinigte Filzfabriken AG", c: "GER" },
  { id: 15151, s: "CTSA3.SA", n: "Companhia Tecidos Santanensea", c: "Brazil" },
  { id: 15152, s: "002350.KS", n: "Nexen Tire", c: "Korea" },
  { id: 15153, s: "4104.TW", n: "Excelsior Medical", c: "TW" },
  { id: 15154, s: "MPAC.L", n: "MPAC Group PLC", c: "UK" },
  { id: 15155, s: "002294.SZ", n: "Shenzhen Salubris Pharmaceuticals", c: "CH" },
  { id: 15156, s: "NAC.AX", n: "NAOS Absolute Opportunities", c: "Australia" },
  { id: 15157, s: "LCS.TO", n: "Brompton Lifeco Split Co", c: "CA" },
  { id: 15158, s: "KJELL.ST", n: "Kjell Group AB", c: "Sweden" },
  { id: 15159, s: "VSL.AX", n: "Vulcan Steel", c: "Australia" },
  { id: 15160, s: "VTI.AX", n: "Visioneering Technologies Inc", c: "Australia" },
  { id: 15161, s: "MXR.AX", n: "Maximus Resources", c: "Australia" },
  { id: 15162, s: "002212.SZ", n: "Topsec Technologies Group Inc", c: "CH" },
  { id: 15163, s: "VR8.AX", n: "Vanadium Resources", c: "Australia" },
  { id: 15164, s: "002217.SZ", n: "Holitech Technology", c: "CH" },
  { id: 15165, s: "VVA.AX", n: "Viva Leisure", c: "Australia" },
  { id: 15166, s: "AWSL", n: "Atlantic Wind & Solar Inc", c: "US" },
  { id: 15167, s: "000951.SZ", n: "Sinotruk Jinan Truck", c: "CH" },
  { id: 15168, s: "CRIV3.SA", n: "Financeira Alfa S.A. - Crédito Financiamento e Investimentos", c: "Brazil" },
  { id: 15169, s: "CGAS3.SA", n: "Companhia de Gás de São Paulo - COMGÁS", c: "Brazil" },
  { id: 15170, s: "688158.SS", n: "UCloud Technology", c: "CH" },
  { id: 15171, s: "002880.KS", n: "Dayou A-Tech", c: "Korea" },
  { id: 15172, s: "CLINE-B.ST", n: "Cline Scientific AB Series B", c: "Sweden" },
  { id: 15173, s: "NCC.L", n: "NCC Group plc", c: "UK" },
  { id: 15174, s: "YAPRK.IS", n: "Yaprak Sut ve Besi Ciftlikleri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 15175, s: "023160.KQ", n: "Tae Kwang Corporation", c: "Korea" },
  { id: 15176, s: "002349.SZ", n: "Nantong Jinghua Pharmaceutical", c: "CH" },
  { id: 15177, s: "000938.SZ", n: "Unisplendour Co", c: "CH" },
  { id: 15178, s: "AZI.AX", n: "Alta Zinc", c: "Australia" },
  { id: 15179, s: "CIRCHE.ST", n: "CirChem AB", c: "Sweden" },
  { id: 15180, s: "021880.KQ", n: "Mason Capital Corporation", c: "Korea" },
  { id: 15181, s: "NGHT.L", n: "Nightcap PLC", c: "UK" },
  { id: 15182, s: "002720.KS", n: "Kukje Pharma", c: "Korea" },
  { id: 15183, s: "CINIS.ST", n: "Cinis Fertilizer AB", c: "Sweden" },
  { id: 15184, s: "ETRXF", n: "Etrion Corporation", c: "US" },
  { id: 15185, s: "002324.SZ", n: "Shanghai Pret Composites", c: "CH" },
  { id: 15186, s: "002326.SZ", n: "Zhejiang Yongtai Technology", c: "CH" },
  { id: 15187, s: "NIL-B.ST", n: "Niloerngruppen AB Series B", c: "Sweden" },
  { id: 15188, s: "TTI.AX", n: "Traffic Technologies", c: "Australia" },
  { id: 15189, s: "BNG.TO", n: "Bengal Energy", c: "CA" },
  { id: 15190, s: "225430.KQ", n: "IBKS No3 SPAC", c: "Korea" },
  { id: 15191, s: "NG.TO", n: "NovaGold Resources Inc", c: "CA" },
  { id: 15192, s: "QTXB", n: "Quantrx Biomedical", c: "US" },
  { id: 15193, s: "TRS.AX", n: "The Reject Shop", c: "Australia" },
  { id: 15194, s: "TSO.AX", n: "Tesoro Gold", c: "Australia" },
  { id: 15195, s: "002153.SZ", n: "Beijing Shiji Information Technology", c: "CH" },
  { id: 15196, s: "TSI.AX", n: "Top Shelf International Holdings", c: "Australia" },
  { id: 15197, s: "MSON-A.ST", n: "Midsona AB (publ)", c: "Sweden" },
  { id: 15198, s: "POLTK.IS", n: "Politeknik Metal Sanayi ve", c: "Turkey" },
  { id: 15199, s: "NOA.TO", n: "North American Construction Group", c: "CA" },
  { id: 15200, s: "MR-UN.TO", n: "Melcor Real Estate Investment Trust", c: "CA" },
  { id: 15201, s: "NIO.ST", n: "Nordic Iron Ore", c: "Sweden" },
  { id: 15202, s: "MOMENT.ST", n: "Moment Group AB", c: "Sweden" },
  { id: 15203, s: "900921.SS", n: "Danhua Chemical Technology Co B", c: "CH" },
  { id: 15204, s: "HXGCF", n: "Hexagon Composites ASA", c: "US" },
  { id: 15205, s: "688722.SS", n: "Beijing Tongyizhong New Material Technology Co", c: "CH" },
  { id: 15206, s: "238200.KQ", n: "BIFIDO Co.", c: "Korea" },
  { id: 15207, s: "MINEST.ST", n: "Minesto AB", c: "Sweden" },
  { id: 15208, s: "4931.TWO", n: "STL Technology", c: "TW" },
  { id: 15209, s: "239610.KQ", n: "HLscience Co.", c: "Korea" },
  { id: 15210, s: "UBI.AX", n: "Universal Biosensors Inc.", c: "Australia" },
  { id: 15211, s: "TZN.AX", n: "Terramin Australia.", c: "Australia" },
  { id: 15212, s: "UBN.AX", n: "Urbanise com", c: "Australia" },
  { id: 15213, s: "BRY.TO", n: "Bri-Chem Corp.", c: "CA" },
  { id: 15214, s: "TVN.AX", n: "Tivan.", c: "Australia" },
  { id: 15215, s: "KP2.L", n: "Kore Potash Plc", c: "UK" },
  { id: 15216, s: "900914.SS", n: "Shanghai Jin Jiang International Industrial Investment Co B", c: "CH" },
  { id: 15217, s: "217330.KQ", n: "Cytogen Inc", c: "Korea" },
  { id: 15218, s: "002138.SZ", n: "Shenzhen Sunlord Electronics", c: "CH" },
  { id: 15219, s: "TYM.AX", n: "Tymlez Group", c: "Australia" },
  { id: 15220, s: "VAR.AX", n: "Variscan Mines", c: "Australia" },
  { id: 15221, s: "234920.KQ", n: "Zaigle Co.", c: "Korea" },
  { id: 15222, s: "238120.KQ", n: "Aligned Genetics Inc", c: "Korea" },
  { id: 15223, s: "BIOM3.SA", n: "Biomm S.A", c: "Brazil" },
  { id: 15224, s: "MCT.AX", n: "Metalicity", c: "Australia" },
  { id: 15225, s: "FPWM", n: "1st Prestige Wealth Management Inc", c: "US" },
  { id: 15226, s: "000965.SZ", n: "Tianjin Tianbao Infrastructure", c: "CH" },
  { id: 15227, s: "NAIT.L", n: "The North American Income Trust plc", c: "UK" },
  { id: 15228, s: "NFYEF", n: "NFI Group Inc", c: "US" },
  { id: 15229, s: "002335.SZ", n: "Xiamen Kehua Hengsheng", c: "CH" },
  { id: 15230, s: "002336.SZ", n: "Renrenle Commercial Group", c: "CH" },
  { id: 15231, s: "002327.SZ", n: "Shenzhen Fuanna Bedding and Furnishing", c: "CH" },
  { id: 15232, s: "688248.SS", n: "CH Southern Power Grid Technology", c: "CH" },
  { id: 15233, s: "BMEB3.SA", n: "Banco Mercantil do Brasil S.A", c: "Brazil" },
  { id: 15234, s: "HWO.TO", n: "High Arctic Energy Services Inc.", c: "CA" },
  { id: 15235, s: "BMKS3.SA", n: "Bicicletas Monark S.A", c: "Brazil" },
  { id: 15236, s: "BNBR3.SA", n: "Banco do Nordeste do Brasil S.A", c: "Brazil" },
  { id: 15237, s: "002337.SZ", n: "Tianjin Saixiang Technology", c: "CH" },
  { id: 15238, s: "HONT", n: "Honat Bancorp Inc", c: "US" },
  { id: 15239, s: "COLL.ST", n: "Collector AB (publ)", c: "Sweden" },
  { id: 15240, s: "EIK.IC", n: "Eik fasteignafélag hf", c: "Iceland" },
  { id: 15241, s: "NEPA.ST", n: "Nepa AB", c: "Sweden" },
  { id: 15242, s: "002151.SZ", n: "Beijing BDStar Navigation", c: "CH" },
  { id: 15243, s: "TYR.AX", n: "Tyro Payments", c: "Australia" },
  { id: 15244, s: "BCT.TO", n: "Briacell Therapeutics Co", c: "CA" },
  { id: 15245, s: "000970.SZ", n: "Beijing Zhong Ke San Huan High-Tech", c: "CH" },
  { id: 15246, s: "033320.KQ", n: "JCH Systems Inc", c: "Korea" },
  { id: 15247, s: "002158.SZ", n: "Shanghai Hanbell Precise Machinery", c: "CH" },
  { id: 15248, s: "TTM.AX", n: "Titan Minerals", c: "Australia" },
  { id: 15249, s: "TYX.AX", n: "Tyranna Resources", c: "Australia" },
  { id: 15250, s: "002179.SZ", n: "AVIC Jonhon Optronic Technology", c: "CH" },
  { id: 15251, s: "TRT.AX", n: "Todd River Resources", c: "Australia" },
  { id: 15252, s: "6136.TW", n: "Fullerton Technology", c: "TW" },
  { id: 15253, s: "002157.SZ", n: "Jiangxi Zhengbang Technology", c: "CH" },
  { id: 15254, s: "BPO-PE.TO", n: "Brookfield Office Properties Inc Pref E", c: "CA" },
  { id: 15255, s: "PKART.IS", n: "Plastikkart Akilli Kart Iletisim Sistemleri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 15256, s: "NC1.AX", n: "NICO Resources", c: "Australia" },
  { id: 15257, s: "237750.KQ", n: "PNC Technologies co.", c: "Korea" },
  { id: 15258, s: "309930.KQ", n: "OHEIM INT Co.Ltd", c: "Korea" },
  { id: 15259, s: "002223.SZ", n: "Jiangsu Yuyue Medical Equipment & Supply", c: "CH" },
  { id: 15260, s: "OXT.AX", n: "Orexplore Technologies", c: "Australia" },
  { id: 15261, s: "NOB.V", n: "Noble Mineral Exploration Inc", c: "CA" },
  { id: 15262, s: "RMI.AX", n: "Resource Mining Corporation", c: "Australia" },
  { id: 15263, s: "HMONY.OL", n: "Harmonychain AS", c: "Norway" },
  { id: 15264, s: "HEX.OL", n: "Hexagon Composites ASA", c: "Norway" },
  { id: 15265, s: "HPHA.DE", n: "Heidelberg Pharma AG", c: "GER" },
  { id: 15266, s: "054940.KQ", n: "EXA E&C Inc", c: "Korea" },
  { id: 15267, s: "9930.TW", n: "CHC Resources Co", c: "TW" },
  { id: 15268, s: "RP.V", n: "RepliCel Life Sciences Inc", c: "CA" },
  { id: 15269, s: "GAS.AX", n: "State Gas", c: "Australia" },
  { id: 15270, s: "LEI.DE", n: "Leifheit Aktiengesellschaft", c: "GER" },
  { id: 15271, s: "EXTX.OL", n: "Exact Therapeutics AS", c: "Norway" },
  { id: 15272, s: "LQAG.DE", n: "Laiqon AG", c: "GER" },
  { id: 15273, s: "ENDUR.OL", n: "Endur ASA", c: "Norway" },
  { id: 15274, s: "ITTOY", n: "Itochu Techno-Solutions Co", c: "US" },
  { id: 15275, s: "RR1.AX", n: "Reach Resources", c: "Australia" },
  { id: 15276, s: "NCX.V", n: "NorthIsle Copper and Gold Inc", c: "CA" },
  { id: 15277, s: "PMC.AX", n: "Platinum Capital", c: "Australia" },
  { id: 15278, s: "058630.KQ", n: "Mgame Co", c: "Korea" },
  { id: 15279, s: "HUNGF", n: "Huaneng Power International Inc", c: "US" },
  { id: 15280, s: "RV.V", n: "Pathfinder Ventures Inc", c: "CA" },
  { id: 15281, s: "QUEST.AT", n: "Quest Holdings S.A", c: "Greece" },
  { id: 15282, s: "053270.KQ", n: "Guyoung Technology Co.", c: "Korea" },
  { id: 15283, s: "091440.KQ", n: "Telefield Inc", c: "Korea" },
  { id: 15284, s: "SKYG.V", n: "Sky Gold Co", c: "CA" },
  { id: 15285, s: "BOR.OL", n: "Borgestad A", c: "Norway" },
  { id: 15286, s: "RZI.AX", n: "Raiz Invest", c: "Australia" },
  { id: 15287, s: "8996.TW", n: "Kaori Heat Treatment", c: "TW" },
  { id: 15288, s: "AZT.OL", n: "Arcticzymes Technologies ASA", c: "Norway" },
  { id: 15289, s: "053060.KQ", n: "Saedong Co.Ltd", c: "Korea" },
  { id: 15290, s: "AURG.OL", n: "Aurskog Sparebank", c: "Norway" },
  { id: 15291, s: "ASL.L", n: "Aberforth Smaller Companies Trust PLC", c: "UK" },
  { id: 15292, s: "PFT.AX", n: "Pure Foods Tasmania", c: "Australia" },
  { id: 15293, s: "053160.KQ", n: "FreeMs Corporation", c: "Korea" },
  { id: 15294, s: "052600.KQ", n: "Hannet Co.", c: "Korea" },
  { id: 15295, s: "HDFGS.IS", n: "Hedef Girisim Sermayesi", c: "Turkey" },
  { id: 15296, s: "RLG.AX", n: "Roolife Group", c: "Australia" },
  { id: 15297, s: "ANDF.OL", n: "Andfjord Salmon AS", c: "Norway" },
  { id: 15298, s: "PAP.AT", n: "Papoutsanis S.A", c: "Greece" },
  { id: 15299, s: "VIRO.", n: "ViroGates A/S", c: "Denmark" },
  { id: 15300, s: "TWC.TO", n: "TWC Enterprises", c: "CA" },
  { id: 15301, s: "FEG.AX", n: "Far East Gold", c: "Australia" },
  { id: 15302, s: "HEGIY", n: "Hengan International Group Company ADR", c: "US" },
  { id: 15303, s: "AMSC.OL", n: "American Shipping Company ASA", c: "Norway" },
  { id: 15304, s: "DPP.L", n: "DP Poland Plc", c: "UK" },
  { id: 15305, s: "SR.V", n: "Strategic Resources Inc", c: "CA" },
  { id: 15306, s: "052300.KQ", n: "CHOROKBAEM COMPANY Co.", c: "Korea" },
  { id: 15307, s: "FGX.AX", n: "Future Generation Investment Company", c: "Australia" },
  { id: 15308, s: "095910.KQ", n: "S-Energy Co.Ltd", c: "Korea" },
  { id: 15309, s: "PCG.AX", n: "Pengana Capital Group", c: "Australia" },
  { id: 15310, s: "HENI", n: "Hinto Energy Inc", c: "US" },
  { id: 15311, s: "096240.KQ", n: "CHUNGDAHM Learning Inc", c: "Korea" },
  { id: 15312, s: "OLYMP.AT", n: "Technical Olympic S.A", c: "Greece" },
  { id: 15313, s: "FGG.AX", n: "Future Generation Global", c: "Australia" },
  { id: 15314, s: "AMAT.L", n: "Amati AIM VCT plc", c: "UK" },
  { id: 15315, s: "SEC.AX", n: "Spheria Emerging Companies", c: "Australia" },
  { id: 15316, s: "RIAS-B.", n: "RIAS A/S", c: "Denmark" },
  { id: 15317, s: "IZFAS.IS", n: "Izmir Firca Sanayi ve Ticaret", c: "Turkey" },
  { id: 15318, s: "LGFRY", n: "Longfor Properties Co ADR", c: "US" },
  { id: 15319, s: "AVIA.TA", n: "Aviation Links", c: "Israel" },
  { id: 15320, s: "001872.SZ", n: "CH Merchants Port Group", c: "CH" },
  { id: 15321, s: "TGAMES.", n: "TROPHY GAMES Development AS", c: "Denmark" },
  { id: 15322, s: "TCM.", n: "TCM Group", c: "Denmark" },
  { id: 15323, s: "049070.KQ", n: "Intops Co.", c: "Korea" },
  { id: 15324, s: "100700.KQ", n: "Sewoon Medical Co.", c: "Korea" },
  { id: 15325, s: "049120.KQ", n: "Fine DNC Co.", c: "Korea" },
  { id: 15326, s: "8210.TW", n: "Chenbro Micom", c: "TW" },
  { id: 15327, s: "SPKSJF.", n: "Sparekassen Sjaelland-Fyn A/S", c: "Denmark" },
  { id: 15328, s: "SEG.AX", n: "Sports Entertainment Group", c: "Australia" },
  { id: 15329, s: "TD-PFJ.TO", n: "Toronto-Dominion Bank Pref Series 18", c: "CA" },
  { id: 15330, s: "GJP", n: "STRATS SM Trust for Dominion Resources Inc Securities Series 2005-6 IC", c: "US" },
  { id: 15331, s: "SEN.AX", n: "Senetas Corporation", c: "Australia" },
  { id: 15332, s: "101670.KQ", n: "Korea SE Co", c: "Korea" },
  { id: 15333, s: "SHRP.V", n: "Sherpa II Holdings Co", c: "CA" },
  { id: 15334, s: "PGM.AX", n: "Platina Resources", c: "Australia" },
  { id: 15335, s: "SFR.V", n: "Sandfire Resources America Inc", c: "CA" },
  { id: 15336, s: "ZAG.AX", n: "Zuleika Gold", c: "Australia" },
  { id: 15337, s: "LFE.TO", n: "Canadian Life Companies Split Co", c: "CA" },
  { id: 15338, s: "688656.SS", n: "HOB Biotech Group Co", c: "CH" },
  { id: 15339, s: "285490.KQ", n: "NOVATECH Co.", c: "Korea" },
  { id: 15340, s: "VMS.AX", n: "Venture Minerals", c: "Australia" },
  { id: 15341, s: "CCO.AX", n: "Calmer Co. International", c: "Australia" },
  { id: 15342, s: "002242.SZ", n: "Joyoung", c: "CH" },
  { id: 15343, s: "LTI.L", n: "Lindsell Train Investment Trust Plc", c: "UK" },
  { id: 15344, s: "RVVQF", n: "Ravenquest Biomed Inc", c: "US" },
  { id: 15345, s: "IAR-B.ST", n: "IAR Systems Group AB (publ)", c: "Sweden" },
  { id: 15346, s: "FRAG.ST", n: "Fragbite Group AB", c: "Sweden" },
  { id: 15347, s: "HSCC", n: "Homeland Security Cp", c: "US" },
  { id: 15348, s: "YPB.AX", n: "YPB Group", c: "Australia" },
  { id: 15349, s: "YRL.AX", n: "Yandal Resources", c: "Australia" },
  { id: 15350, s: "000970.KS", n: "Korea Cast Pip", c: "Korea" },
  { id: 15351, s: "025320.KQ", n: "Synopex Inc", c: "Korea" },
  { id: 15352, s: "MPVDF", n: "Mountain Province Diamonds Inc", c: "US" },
  { id: 15353, s: "272290.KQ", n: "INNOX Advanced Materials Co.Ltd", c: "Korea" },
  { id: 15354, s: "LN.TO", n: "Loncor Resources Inc", c: "CA" },
  { id: 15355, s: "688711.SS", n: "Macmic Science & Technology", c: "CH" },
  { id: 15356, s: "WEC.AX", n: "White Energy Company", c: "Australia" },
  { id: 15357, s: "TRCAS.IS", n: "Turcas Petrol AS", c: "Turkey" },
  { id: 15358, s: "DLCG.TO", n: "Dominion Lending Centres Inc", c: "CA" },
  { id: 15359, s: "MRZLF", n: "Mirasol Resources", c: "US" },
  { id: 15360, s: "290720.KQ", n: "Foodnamoo. Inc", c: "Korea" },
  { id: 15361, s: "VN8.AX", n: "Vonex", c: "Australia" },
  { id: 15362, s: "263700.KQ", n: "Carelabs Co.Ltd", c: "Korea" },
  { id: 15363, s: "002195.SZ", n: "Shanghai 2345 Network Holding Group", c: "CH" },
  { id: 15364, s: "032280.KQ", n: "Samil Co.Ltd", c: "Korea" },
  { id: 15365, s: "VEE.AX", n: "VEEM", c: "Australia" },
  { id: 15366, s: "CRDL.TO", n: "Cardiol Therapeutics Inc Class A", c: "CA" },
  { id: 15367, s: "5203.TW", n: "Cyberlink", c: "TW" },
  { id: 15368, s: "VKA.AX", n: "Viking Mines", c: "Australia" },
  { id: 15369, s: "SB1.DU", n: "Smartbroker Holding AG", c: "GER" },
  { id: 15370, s: "Z2U.AX", n: "Zoom2u Technologies", c: "Australia" },
  { id: 15371, s: "ZEO.AX", n: "Zeotech", c: "Australia" },
  { id: 15372, s: "SBBC.V", n: "Simply Better Brands Co", c: "CA" },
  { id: 15373, s: "ZGL.AX", n: "Zicom Group", c: "Australia" },
  { id: 15374, s: "MMI.AX", n: "Metro Mining", c: "Australia" },
  { id: 15375, s: "WWI.AX", n: "West Wits Mining", c: "Australia" },
  { id: 15376, s: "GUARD.ST", n: "Guard Therapeutics International AB", c: "Sweden" },
  { id: 15377, s: "002264.SZ", n: "New Huadu Supercenter", c: "CH" },
  { id: 15378, s: "002266.SZ", n: "Zhefu Holding Group", c: "CH" },
  { id: 15379, s: "002268.SZ", n: "Westone Information Industry Inc", c: "CH" },
  { id: 15380, s: "X2M.AX", n: "X2M Connect", c: "Australia" },
  { id: 15381, s: "XF1.AX", n: "Xref", c: "Australia" },
  { id: 15382, s: "017510.KQ", n: "Semyung Electric Machinery Co.Ltd", c: "Korea" },
  { id: 15383, s: "FBEC", n: "Fbec Worldwide", c: "US" },
  { id: 15384, s: "MME.AX", n: "MoneyMe.", c: "Australia" },
  { id: 15385, s: "170920.KQ", n: "LTC Co.Ltd", c: "Korea" },
  { id: 15386, s: "002131.SZ", n: "Leo Group", c: "CH" },
  { id: 15387, s: "CYN.L", n: "CQS Natural Resources Growth and Income plc", c: "UK" },
  { id: 15388, s: "002387.SZ", n: "Visionox Technology Inc", c: "CH" },
  { id: 15389, s: "026040.KQ", n: "J.ESTINA Co.Ltd", c: "Korea" },
  { id: 15390, s: "215600.KQ", n: "SillaJen Inc", c: "Korea" },
  { id: 15391, s: "ELR.TO", n: "Eastern Platinum Limited", c: "CA" },
  { id: 15392, s: "YAR.AX", n: "Yari Minerals.", c: "Australia" },
  { id: 15393, s: "ZMI.AX", n: "Zinc of Ireland NL", c: "Australia" },
  { id: 15394, s: "4108.TW", n: "Phytohealth Co", c: "TW" },
  { id: 15395, s: "NBST", n: "Newbury Street Acquisition Co", c: "US" },
  { id: 15396, s: "ZNC.AX", n: "Zenith Minerals", c: "Australia" },
  { id: 15397, s: "688345.SS", n: "Guangdong Greenway Technology", c: "CH" },
  { id: 15398, s: "4106.TW", n: "Wellell Inc", c: "TW" },
  { id: 15399, s: "VBS.AX", n: "Vectus Biosystems", c: "Australia" },
  { id: 15400, s: "14D.MU", n: "tokentus investment AG", c: "GER" },
  { id: 15401, s: "688399.SS", n: "Jiangsu Bioperfectus Technologies", c: "CH" },
  { id: 15402, s: "XST.AX", n: "Xstate Resources", c: "Australia" },
  { id: 15403, s: "WLE.AX", n: "Wam Leaders", c: "Australia" },
  { id: 15404, s: "WML.AX", n: "Woomera Mining", c: "Australia" },
  { id: 15405, s: "000906.SZ", n: "Zheshang Development Group", c: "CH" },
  { id: 15406, s: "RXEEY", n: "Rexel SA ADR", c: "US" },
  { id: 15407, s: "CSR.WA", n: "Caspar", c: "Poland" },
  { id: 15408, s: "177830.KQ", n: "Pavonine", c: "Korea" },
  { id: 15409, s: "FARM.V", n: "Deveron UAS Co", c: "CA" },
  { id: 15410, s: "MIO.MC", n: "Media Investment Optimization SL", c: "Spain" },
  { id: 15411, s: "MLC.NE", n: "Mount Logan Capital Inc", c: "CA" },
  { id: 15412, s: "CYDX", n: "Cyduct Diagnostics Inc", c: "US" },
  { id: 15413, s: "ACW.AX", n: "Actinogen Medical", c: "Australia" },
  { id: 15414, s: "603982.SS", n: "Nanjing Chervon Auto Precision Technology", c: "CH" },
  { id: 15415, s: "009970.KS", n: "Youngone Holdi", c: "Korea" },
  { id: 15416, s: "IREN.SW", n: "Investis Holding SA", c: "Switzerland" },
  { id: 15417, s: "002563.SZ", n: "Zhejiang Semir Garment", c: "CH" },
  { id: 15418, s: "002572.SZ", n: "Suofeiya Home Collection", c: "CH" },
  { id: 15419, s: "603999.SS", n: "Duzhe Publishing & Media Co", c: "CH" },
  { id: 15420, s: "TIME.L", n: "Time Finance PLC", c: "UK" },
  { id: 15421, s: "TMO.L", n: "Time Out Group plc", c: "UK" },
  { id: 15422, s: "AFL.AX", n: "AF Legal Group", c: "Australia" },
  { id: 15423, s: "002578.SZ", n: "Fujian Minfa Aluminium", c: "CH" },
  { id: 15424, s: "4DX.AX", n: "4Dmedical Ltd", c: "Australia" },
  { id: 15425, s: "603992.SS", n: "Xiamen Solex High-Tech Industries", c: "CH" },
  { id: 15426, s: "012030.KS", n: "DB INC", c: "Korea" },
  { id: 15427, s: "A1G.AX", n: "African Gold", c: "Australia" },
  { id: 15428, s: "600067.SS", n: "Citychamp Dartong", c: "CH" },
  { id: 15429, s: "603958.SS", n: "Harson Trading CH", c: "CH" },
  { id: 15430, s: "300531.SZ", n: "Shenzhen Urovo Technology", c: "CH" },
  { id: 15431, s: "THVB", n: "Thomasville Bancshares Inc", c: "US" },
  { id: 15432, s: "3527.TWO", n: "Macroblock", c: "TW" },
  { id: 15433, s: "CMRB.MX", n: "CMR S.A.B. de C.V", c: "Mexi" },
  { id: 15434, s: "ACQ.AX", n: "Acorn Capital Invest Fund", c: "Australia" },
  { id: 15435, s: "CULTIBAB.MX", n: "Organización Cultiba S.A.B. de C.V", c: "Mexi" },
  { id: 15436, s: "YENT.MC", n: "Entre Cua Socimi", c: "Spain" },
  { id: 15437, s: "YATO.MC", n: "Atom Hoteles Socimi", c: "Spain" },
  { id: 15438, s: "603966.SS", n: "Eurocrane CH", c: "CH" },
  { id: 15439, s: "011000.KS", n: "GeneOne Life Science Inc", c: "Korea" },
  { id: 15440, s: "SPH.MC", n: "Profithol S.A.", c: "Spain" },
  { id: 15441, s: "CTTH", n: "CTT Pharmaceutical Holdings Inc", c: "US" },
  { id: 15442, s: "RIO.MC", n: "Bodegas Riojanas S.A", c: "Spain" },
  { id: 15443, s: "011230.KS", n: "Samwha Elec", c: "Korea" },
  { id: 15444, s: "ZIN.L", n: "Zinc Media Group", c: "UK" },
  { id: 15445, s: "TRAK.L", n: "Trakm8 Holdings PLC", c: "UK" },
  { id: 15446, s: "ADY.AX", n: "Admiralty Resources NL.", c: "Australia" },
  { id: 15447, s: "WHRL3.SA", n: "Whirlpool S.A", c: "Brazil" },
  { id: 15448, s: "ADR.AX", n: "Adherium", c: "Australia" },
  { id: 15449, s: "CNDB", n: "Concord Acquisition Corp III", c: "US" },
  { id: 15450, s: "300755.SZ", n: "Vats Liquor Chain Store Management Joint Stock", c: "CH" },
  { id: 15451, s: "TYIDY", n: "Toyota Industries Corporation", c: "US" },
  { id: 15452, s: "002600.SZ", n: "Lingyi iTech Guangdong", c: "CH" },
  { id: 15453, s: "603800.SS", n: "Suzhou Douson Drilling & Production Equipment", c: "CH" },
  { id: 15454, s: "TYEKF", n: "Thyssenkrupp AG O.N.", c: "US" },
  { id: 15455, s: "603798.SS", n: "Qingdao Copton Technology", c: "CH" },
  { id: 15456, s: "019170.KS", n: "Shin Poong Pharm.Co.Ltd.", c: "Korea" },
  { id: 15457, s: "603867.SS", n: "Zhejiang Xinhua Chemical", c: "CH" },
  { id: 15458, s: "AZK.MC", n: "Azkoyen", c: "Spain" },
  { id: 15459, s: "017550.KS", n: "Soosan Heavy I", c: "Korea" },
  { id: 15460, s: "603929.SS", n: "L&K Engineering Suzhou", c: "CH" },
  { id: 15461, s: "012750.KS", n: "S-1 Co", c: "Korea" },
  { id: 15462, s: "1CG.AX", n: "One Click Group.", c: "Australia" },
  { id: 15463, s: "1AG.AX", n: "Alterra", c: "Australia" },
  { id: 15464, s: "PCWLF", n: "PCCW Limited", c: "US" },
  { id: 15465, s: "300766.SZ", n: "Zhejiang Merit Interactive Network Technology Co Class A", c: "CH" },
  { id: 15466, s: "025530.KS", n: "Sjm Holdings", c: "Korea" },
  { id: 15467, s: "603938.SS", n: "Tangshan Sunfar Silicon Ind", c: "CH" },
  { id: 15468, s: "OWVI", n: "One World Ventures Inc", c: "US" },
  { id: 15469, s: "VLG.L", n: "Venture Life Group PLC", c: "UK" },
  { id: 15470, s: "VRS.L", n: "Versarien PLC", c: "UK" },
  { id: 15471, s: "WIL.L", n: "Wilmington PLC", c: "UK" },
  { id: 15472, s: "AEI.AX", n: "Aeris Environmental", c: "Australia" },
  { id: 15473, s: "TENG.L", n: "Ten Lifestyle Group PLC", c: "UK" },
  { id: 15474, s: "IBG.MC", n: "Iberpapel Gestion SA", c: "Spain" },
  { id: 15475, s: "480S.MC", n: "Soluciones Cuatroochenta S.L.", c: "Spain" },
  { id: 15476, s: "603858.SS", n: "Shandong Buchang Pharmaceuticals", c: "CH" },
  { id: 15477, s: "AQS.V", n: "Aequus Pharmaceuticals Inc", c: "CA" },
  { id: 15478, s: "CON.F", n: "Continental Aktiengesellschaft", c: "GER" },
  { id: 15479, s: "603829.SS", n: "Jiangsu Luokai Mechanical & Electrical Co Class A", c: "CH" },
  { id: 15480, s: "603825.SS", n: "Hylink Digital Solution", c: "CH" },
  { id: 15481, s: "603811.SS", n: "Zhejiang Cheng Yi Pharmaceutical", c: "CH" },
  { id: 15482, s: "601003.SS", n: "Liuzhou Iron & Steel", c: "CH" },
  { id: 15483, s: "600977.SS", n: "CH Film", c: "CH" },
  { id: 15484, s: "600233.SS", n: "YTO Express Group", c: "CH" },
  { id: 15485, s: "600916.SS", n: "CH National Gold Group Gold Jewellery", c: "CH" },
  { id: 15486, s: "002925.SZ", n: "Xiamen Intretech Inc Class A", c: "CH" },
  { id: 15487, s: "601368.SS", n: "Guangxi Nanning Waterworks", c: "CH" },
  { id: 15488, s: "6417.TWO", n: "Securitag Assembly Group", c: "TW" },
  { id: 15489, s: "601366.SS", n: "Qingdao Liqun Department Store Group", c: "CH" },
  { id: 15490, s: "601595.SS", n: "Shanghai Film", c: "CH" },
  { id: 15491, s: "GSS.V", n: "Gossan Resources", c: "CA" },
  { id: 15492, s: "600138.SS", n: "CH CYTS Tours Holding", c: "CH" },
  { id: 15493, s: "600162.SS", n: "Shenzhen Heungkong Holding", c: "CH" },
  { id: 15494, s: "600965.SS", n: "Fortune Ng Fung Food Hebei", c: "CH" },
  { id: 15495, s: "600963.SS", n: "Yueyang Forest & Paper", c: "CH" },
  { id: 15496, s: "600261.SS", n: "Zhejiang Yankon Group", c: "CH" },
  { id: 15497, s: "002797.SZ", n: "First Capital Securities Co Class A", c: "CH" },
  { id: 15498, s: "SFD1.F", n: "Softline AG", c: "GER" },
  { id: 15499, s: "8024.TWO", n: "Alpha Microelectronics", c: "TW" },
  { id: 15500, s: "600278.SS", n: "Orient International Enterprise", c: "CH" },
  { id: 15501, s: "601519.SS", n: "Shanghai DZH", c: "CH" },
  { id: 15502, s: "MTX.F", n: "MTU Aero Engines AG", c: "GER" },
  { id: 15503, s: "002926.SZ", n: "Huaxi Securities Co Class A", c: "CH" },
  { id: 15504, s: "ALHRS.PA", n: "Hydrogen Refueling Solutions", c: "FR" },
  { id: 15505, s: "LXS.F", n: "LANXESS Aktiengesellschaft", c: "GER" },
  { id: 15506, s: "ALKLA.PA", n: "Klarsen SA", c: "FR" },
  { id: 15507, s: "6195.TWO", n: "Scan-D", c: "TW" },
  { id: 15508, s: "002945.SZ", n: "CHLin Securities", c: "CH" },
  { id: 15509, s: "ALI2S.PA", n: "i2S SA", c: "FR" },
  { id: 15510, s: "GUG.V", n: "Gungnir Resources Inc", c: "CA" },
  { id: 15511, s: "601615.SS", n: "Ming Yang Smart Energy Group", c: "CH" },
  { id: 15512, s: "ALFOC.PA", n: "Focus Home Interactive SA", c: "FR" },
  { id: 15513, s: "600021.SS", n: "Shanghai Electric Power", c: "CH" },
  { id: 15514, s: "MRK.F", n: "Merck & Company Inc", c: "GER" },
  { id: 15515, s: "ALCJ.PA", n: "Crossject", c: "FR" },
  { id: 15516, s: "601599.SS", n: "Zhewen Pictures Group", c: "CH" },
  { id: 15517, s: "600032.SS", n: "Zhejiang Provincial New Energy Investment Group", c: "CH" },
  { id: 15518, s: "002929.SZ", n: "Runjian Communication Co Class A", c: "CH" },
  { id: 15519, s: "600280.SS", n: "Nanjing Central Emporium Group Stocks", c: "CH" },
  { id: 15520, s: "600908.SS", n: "Wuxi Rural Commercial Bank", c: "CH" },
  { id: 15521, s: "600230.SS", n: "Cangzhou Dahua", c: "CH" },
  { id: 15522, s: "600898.SS", n: "Gome Telecom Equipment", c: "CH" },
  { id: 15523, s: "601058.SS", n: "Sailun Jinyu Group", c: "CH" },
  { id: 15524, s: "600185.SS", n: "Gree Real Estate", c: "CH" },
  { id: 15525, s: "1809.TW", n: "CH Glaze", c: "TW" },
  { id: 15526, s: "600188.SS", n: "Yankuang Energy Group", c: "CH" },
  { id: 15527, s: "601016.SS", n: "CECEP Wind-Power Corp", c: "CH" },
  { id: 15528, s: "601015.SS", n: "Shaanxi Heimao Coking", c: "CH" },
  { id: 15529, s: "002916.SZ", n: "Shennan Circuits Co Class A", c: "CH" },
  { id: 15530, s: "ALAFY.PA", n: "Afyren SAS", c: "FR" },
  { id: 15531, s: "AKW.PA", n: "Akwel SA", c: "FR" },
  { id: 15532, s: "601000.SS", n: "Tangshan Port Group", c: "CH" },
  { id: 15533, s: "RWE.F", n: "RWE AG", c: "GER" },
  { id: 15534, s: "600218.SS", n: "Anhui Quanchai Engine", c: "CH" },
  { id: 15535, s: "SNNAQ", n: "Sienna Biopharmaceuticals Inc", c: "US" },
  { id: 15536, s: "AFME.PA", n: "Affluent Medical SAS", c: "FR" },
  { id: 15537, s: "600989.SS", n: "Ningxia Baofeng Energy Group", c: "CH" },
  { id: 15538, s: "SNMN", n: "SNM Gobal Holdings", c: "US" },
  { id: 15539, s: "600178.SS", n: "Harbin Dongan Auto Engine", c: "CH" },
  { id: 15540, s: "600176.SS", n: "CH Jushi", c: "CH" },
  { id: 15541, s: "600877.SS", n: "CETC Energy Joint Stock", c: "CH" },
  { id: 15542, s: "600292.SS", n: "Spic Yuanda Environmental Protection", c: "CH" },
  { id: 15543, s: "600892.SS", n: "Dasheng Times Cultural Investment", c: "CH" },
  { id: 15544, s: "8042.TWO", n: "Taiwan Chinsan Electronic Industrial", c: "TW" },
  { id: 15545, s: "600300.SS", n: "V V Food & Beverage", c: "CH" },
  { id: 15546, s: "600301.SS", n: "Nanning Chemical Industry", c: "CH" },
  { id: 15547, s: "600884.SS", n: "Ningbo Shanshan", c: "CH" },
  { id: 15548, s: "002891.SZ", n: "Yantai CH Pet Foods", c: "CH" },
  { id: 15549, s: "600172.SS", n: "Henan Huanghe Whirlwind", c: "CH" },
  { id: 15550, s: "600316.SS", n: "Jiangxi Hongdu Aviation Industry", c: "CH" },
  { id: 15551, s: "1617.TW", n: "Jung Shing Wire", c: "TW" },
  { id: 15552, s: "600966.SS", n: "Shandong Bohui Paper Industry", c: "CH" },
  { id: 15553, s: "600968.SS", n: "CNOOC Energy Technology & Services", c: "CH" },
  { id: 15554, s: "1731.TW", n: "Maywufa", c: "TW" },
  { id: 15555, s: "600239.SS", n: "Yunnan Metropolitan Real Estate Development", c: "CH" },
  { id: 15556, s: "1AD.AX", n: "AdAlta", c: "Australia" },
  { id: 15557, s: "PPCCY", n: "PICC Property and Casualty", c: "US" },
  { id: 15558, s: "300902.SZ", n: "Guoanda ", c: "CH" },
  { id: 15559, s: "BILD.V", n: "Builddirect.com Tehcnologies Inc", c: "CA" },
  { id: 15560, s: "002489.SZ", n: "Yotrio Group", c: "CH" },
  { id: 15561, s: "SLED3.SA", n: "Saraiva Livreiros S.A. - em Recuperação Judicial", c: "Brazil" },
  { id: 15562, s: "SDP.L", n: "Schroder Asia Pacific Fund", c: "UK" },
  { id: 15563, s: "SDX.L", n: "SDX Energy Inc", c: "UK" },
  { id: 15564, s: "ALB.AX", n: "Albion Resources Limited", c: "Australia" },
  { id: 15565, s: "RRR.L", n: "Red Rock Resources", c: "UK" },
  { id: 15566, s: "TWODY", n: "Taylor Wimpey PLC ADR", c: "US" },
  { id: 15567, s: "ZRGYO.IS", n: "Ziraat Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 15568, s: "002603.SZ", n: "Shijiazhuang Yiling Pharmaceutical", c: "CH" },
  { id: 15569, s: "603611.SS", n: "Zhejiang Noblelift Equip", c: "CH" },
  { id: 15570, s: "CBV.V", n: "Cobra Venture Co", c: "CA" },
  { id: 15571, s: "UNBL.PA", n: "Unibel S.A", c: "FR" },
  { id: 15572, s: "BRG.V", n: "Brigadier Gold", c: "CA" },
  { id: 15573, s: "VANTI.PA", n: "VANTIVA SA", c: "FR" },
  { id: 15574, s: "603619.SS", n: "Zhongman Petro & Natural Gas", c: "CH" },
  { id: 15575, s: "BFCH", n: "BitFrontier Capital Holdings Inc", c: "US" },
  { id: 15576, s: "UPIN", n: "Universal Power Industry Co", c: "US" },
  { id: 15577, s: "DPSM", n: "3D Pioneer Systems", c: "US" },
  { id: 15578, s: "RESI.L", n: "Residential Secure Income plc", c: "UK" },
  { id: 15579, s: "BMM.F", n: "Brüder Mannesmann Aktiengesellschaft", c: "GER" },
  { id: 15580, s: "AIV.AX", n: "ActivEX", c: "Australia" },
  { id: 15581, s: "300738.SZ", n: "Guangdong Aofei Data Technology Co Class A", c: "CH" },
  { id: 15582, s: "605222.SS", n: "Shanghai QiFan Cable", c: "CH" },
  { id: 15583, s: "002500.SZ", n: "Shanxi Securities", c: "CH" },
  { id: 15584, s: "AN1.AX", n: "Anagenics", c: "Australia" },
  { id: 15585, s: "AMT.AX", n: "Allegra Orthopaedics", c: "Australia" },
  { id: 15586, s: "3025.TW", n: "Loop Telecommunication International Inc", c: "TW" },
  { id: 15587, s: "002455.SZ", n: "Jiangsu Baichuan HighTech New Materials", c: "CH" },
  { id: 15588, s: "002465.SZ", n: "Guangzhou Haige Communications Group Incorporated", c: "CH" },
  { id: 15589, s: "RDNI3.SA", n: "RNI Negócios Imobiliários S.A", c: "Brazil" },
  { id: 15590, s: "AML.AX", n: "Aeon Metals", c: "Australia" },
  { id: 15591, s: "ROL.L", n: "Rotala PLC", c: "UK" },
  { id: 15592, s: "AOF.F", n: "ATOSS Software AG", c: "GER" },
  { id: 15593, s: "603650.SS", n: "Red Avenue New Materials Group", c: "CH" },
  { id: 15594, s: "068290.KS", n: "Samsung Publis", c: "Korea" },
  { id: 15595, s: "007815.KS", n: "Korea Circuit", c: "Korea" },
  { id: 15596, s: "3332.TWO", n: "Cincon Electronics", c: "TW" },
  { id: 15597, s: "603696.SS", n: "Anji Foodstuff", c: "CH" },
  { id: 15598, s: "300642.SZ", n: "Tellgen Co", c: "CH" },
  { id: 15599, s: "OPTI.BR", n: "Crescent NV", c: "Belgium" },
  { id: 15600, s: "3226.TWO", n: "Topower", c: "TW" },
  { id: 15601, s: "2701.TW", n: "Wanhwa Enterprise", c: "TW" },
  { id: 15602, s: "TCHCS.PA", n: "TECHNICOLOR CREATIVE STUDIOS SA", c: "FR" },
  { id: 15603, s: "300547.SZ", n: "Sichuan Chuanhuan Technology", c: "CH" },
  { id: 15604, s: "603606.SS", n: "Ningbo Orient Wires and Cables", c: "CH" },
  { id: 15605, s: "603686.SS", n: "Fujian Longma Envi Sanitation", c: "CH" },
  { id: 15606, s: "603608.SS", n: "Topscore Fashion", c: "CH" },
  { id: 15607, s: "052690.KS", n: "KEPCO Engineering & Construction", c: "Korea" },
  { id: 15608, s: "053690.KS", n: "Hanmiglobal", c: "Korea" },
  { id: 15609, s: "603688.SS", n: "Jiangsu Pacific Quartz", c: "CH" },
  { id: 15610, s: "BMAC", n: "Black Mountain Acquisition Co", c: "US" },
  { id: 15611, s: "603155.SS", n: "Xinyaqiang Silicon Chemistry", c: "CH" },
  { id: 15612, s: "BLEG", n: "Branded Legacy Inc", c: "US" },
  { id: 15613, s: "603655.SS", n: "Changzhou Langbo Seal Polytron Technologies Co Class A", c: "CH" },
  { id: 15614, s: "300674.SZ", n: "Yusys Technologies Co Class A", c: "CH" },
  { id: 15615, s: "300605.SZ", n: "HengFeng Information Technology", c: "CH" },
  { id: 15616, s: "603698.SS", n: "Changzheng Engineering", c: "CH" },
  { id: 15617, s: "603700.SS", n: "Ningbo Water Meter", c: "CH" },
  { id: 15618, s: "300658.SZ", n: "Xiamen Yanjan New Material", c: "CH" },
  { id: 15619, s: "603706.SS", n: "Xinjiang East Universe Group Gas", c: "CH" },
  { id: 15620, s: "603628.SS", n: "Clenergy Xiamen Technology", c: "CH" },
  { id: 15621, s: "300661.SZ", n: "SG Micro Co", c: "CH" },
  { id: 15622, s: "603737.SS", n: "Skshu Paint", c: "CH" },
  { id: 15623, s: "JEN.BR", n: "Jensen-Group", c: "Belgium" },
  { id: 15624, s: "300662.SZ", n: "Beijing Career International", c: "CH" },
  { id: 15625, s: "3499.TWO", n: "Globalsat Worldcom", c: "TW" },
  { id: 15626, s: "BCF.V", n: "Builders Capital Mortgage Co", c: "CA" },
  { id: 15627, s: "300604.SZ", n: "Hangzhou Chang Chuan Technology", c: "CH" },
  { id: 15628, s: "CANS.V", n: "Wildpack Beverage Inc", c: "CA" },
  { id: 15629, s: "VIL.PA", n: "VIEL & Cie société anonyme", c: "FR" },
  { id: 15630, s: "605069.SS", n: "Beijing ZEHO Waterfront Ecological Environment Treatment", c: "CH" },
  { id: 15631, s: "605077.SS", n: "Zhejiang Huakang Pharmaceutical", c: "CH" },
  { id: 15632, s: "300896.SZ", n: "Imeik Technology Development ", c: "CH" },
  { id: 15633, s: "688050.SS", n: "Eyebright Medical Technology Beijing", c: "CH" },
  { id: 15634, s: "PDGR3.SA", n: "PDG Realty S.A. Empreendimentos e Participações", c: "Brazil" },
  { id: 15635, s: "004560.KS", n: "Hyundai Bngste", c: "Korea" },
  { id: 15636, s: "PLAS3.SA", n: "Plascar Participações Industriais S.A", c: "Brazil" },
  { id: 15637, s: "002422.SZ", n: "Sichuan Kelun Pharmaceutical", c: "CH" },
  { id: 15638, s: "POMO3.SA", n: "Marcopolo S.A", c: "Brazil" },
  { id: 15639, s: "NWGC", n: "New World Gold Cp", c: "US" },
  { id: 15640, s: "DRUNF", n: "Dream Unlimited Co", c: "US" },
  { id: 15641, s: "NEDSE.AS", n: "NedSense Enterprises NV", c: "Netherlands" },
  { id: 15642, s: "002429.SZ", n: "Shenzhen MTC", c: "CH" },
  { id: 15643, s: "KENDR.AS", n: "Kendrion NV", c: "Netherlands" },
  { id: 15644, s: "002430.SZ", n: "Hangzhou Hangyang", c: "CH" },
  { id: 15645, s: "ANO.AX", n: "Advance ZincTek Limited", c: "Australia" },
  { id: 15646, s: "8TRA.F", n: "Traton SE", c: "GER" },
  { id: 15647, s: "688049.SS", n: "Actions Technology", c: "CH" },
  { id: 15648, s: "004370.KS", n: "Nongshim", c: "Korea" },
  { id: 15649, s: "OSXB3.SA", n: "OSX Brasil S.A", c: "Brazil" },
  { id: 15650, s: "02P.F", n: "Pearl Gold AG", c: "GER" },
  { id: 15651, s: "ADDV-B.ST", n: "ADDvise Group B", c: "Sweden" },
  { id: 15652, s: "603888.SS", n: "Xinhuanet", c: "CH" },
  { id: 15653, s: "IZER.MC", n: "Izertis Sa", c: "Spain" },
  { id: 15654, s: "603915.SS", n: "Jiangsu Guomao Reducer", c: "CH" },
  { id: 15655, s: "603908.SS", n: "Comefly Outdoor", c: "CH" },
  { id: 15656, s: "300871.SZ", n: "Wuhan Hvsen Biotechnology", c: "CH" },
  { id: 15657, s: "016090.KS", n: "Dae Hyun", c: "Korea" },
  { id: 15658, s: "016450.KS", n: "Hansae Yes24", c: "Korea" },
  { id: 15659, s: "603885.SS", n: "Juneyao Airlines", c: "CH" },
  { id: 15660, s: "DTARF", n: "Delta Resources", c: "US" },
  { id: 15661, s: "603881.SS", n: "Shanghai AtHub", c: "CH" },
  { id: 15662, s: "603879.SS", n: "Yongyue Science & Tech", c: "CH" },
  { id: 15663, s: "603912.SS", n: "Nanjing Canatal Data Centre Environmental Tech Co Class A", c: "CH" },
  { id: 15664, s: "AGR.AX", n: "Aguia Resources", c: "Australia" },
  { id: 15665, s: "BNR.F", n: "Brenntag SE", c: "GER" },
  { id: 15666, s: "KURN.SW", n: "Kuros Biosciences AG", c: "Switzerland" },
  { id: 15667, s: "AGTIRA-B.ST", n: "Agtira AB", c: "Sweden" },
  { id: 15668, s: "ATX.AX", n: "Amplia Therapeutics", c: "Australia" },
  { id: 15669, s: "MCHN.SW", n: "MCH Group AG", c: "Switzerland" },
  { id: 15670, s: "INEP3.SA", n: "Inepar S.A. Indústria e Construções", c: "Brazil" },
  { id: 15671, s: "BRILL.AS", n: "Koninklijke Brill NV", c: "Netherlands" },
  { id: 15672, s: "3032.TW", n: "Compucase Enterprise", c: "TW" },
  { id: 15673, s: "2906.TW", n: "Collins", c: "TW" },
  { id: 15674, s: "006405.KS", n: "Samsung Sdi Co Pref", c: "Korea" },
  { id: 15675, s: "605178.SS", n: "Beijing New Space Technology", c: "CH" },
  { id: 15676, s: "SNT.L", n: "Sabien Technology Group Plc", c: "UK" },
  { id: 15677, s: "B4B.F", n: "Metro AG", c: "GER" },
  { id: 15678, s: "SPA.L", n: "1Spatial PLC", c: "UK" },
  { id: 15679, s: "AKG.AX", n: "Academies Australasia Group", c: "Australia" },
  { id: 15680, s: "SPR.L", n: "Springfield Properties Plc", c: "UK" },
  { id: 15681, s: "SIXH.L", n: "600 Group", c: "UK" },
  { id: 15682, s: "002531.SZ", n: "Titan Wind Energy Suzhou", c: "CH" },
  { id: 15683, s: "007340.KS", n: "DTR Automotive", c: "Korea" },
  { id: 15684, s: "605116.SS", n: "Aurisco Pharmaceutical", c: "CH" },
  { id: 15685, s: "AHN.AX", n: "Athena Resources Limited", c: "Australia" },
  { id: 15686, s: "DQJCY", n: "Pan Pacific International Holdings Corp ADR", c: "US" },
  { id: 15687, s: "EBCOY", n: "Ebara Corp ADR", c: "US" },
  { id: 15688, s: "ARN.AX", n: "Aldoro Resources", c: "Australia" },
  { id: 15689, s: "005305.KS", n: "Lotte Chilsung Beverage", c: "Korea" },
  { id: 15690, s: "JFEN3.SA", n: "João Fortes Engenharia S.A.", c: "Brazil" },
  { id: 15691, s: "JOPA3.SA", n: "JOSAPAR Joaquim Oliveira S.A. Participações", c: "Brazil" },
  { id: 15692, s: "003650.KS", n: "Mi Chang Oil", c: "Korea" },
  { id: 15693, s: "002398.SZ", n: "Lets Holding Group", c: "CH" },
  { id: 15694, s: "003690.KS", n: "Korean Reinsu", c: "Korea" },
  { id: 15695, s: "ASW.AX", n: "Advanced Share Registry", c: "Australia" },
  { id: 15696, s: "ASR.AX", n: "Asra Minerals", c: "Australia" },
  { id: 15697, s: "ART.AX", n: "Airtasker", c: "Australia" },
  { id: 15698, s: "AQ.ST", n: "AQ Group AB", c: "Sweden" },
  { id: 15699, s: "MNPR3.SA", n: "Minupar Participações S.A", c: "Brazil" },
  { id: 15700, s: "3057.TW", n: "Promise Technology Inc", c: "TW" },
  { id: 15701, s: "SIX3.HA", n: "Sixt SE", c: "GER" },
  { id: 15702, s: "002406.SZ", n: "Xuchang Yuandong Drive Shaft", c: "CH" },
  { id: 15703, s: "688056.SS", n: "Beijing LabTech Instruments", c: "CH" },
  { id: 15704, s: "600064.SS", n: "Nanjing Gaoke", c: "CH" },
  { id: 15705, s: "603618.SS", n: "Hangzhou Cable", c: "CH" },
  { id: 15706, s: "601336.SS", n: "New CH Life Insurance", c: "CH" },
  { id: 15707, s: "603458.SS", n: "Guizhou Transp Planning", c: "CH" },
  { id: 15708, s: "155900.KS", n: "Badaro No19", c: "Korea" },
  { id: 15709, s: "603598.SS", n: "Inly Media", c: "CH" },
  { id: 15710, s: "603596.SS", n: "Bethel Automotive Safety Systems Co Class A", c: "CH" },
  { id: 15711, s: "093240.KS", n: "Hyungji Elite", c: "Korea" },
  { id: 15712, s: "096760.KS", n: "Jw Holdings", c: "Korea" },
  { id: 15713, s: "096775.KS", n: "SK Innovation Co Pref", c: "Korea" },
  { id: 15714, s: "097520.KS", n: "Mcnex Co..", c: "Korea" },
  { id: 15715, s: "CPAU.V", n: "Copaur Minerals Inc", c: "CA" },
  { id: 15716, s: "100090.KS", n: "Samkang M&T", c: "Korea" },
  { id: 15717, s: "SELER.PA", n: "Selectirente", c: "FR" },
  { id: 15718, s: "603568.SS", n: "Zhejiang Weiming Environment Protection", c: "CH" },
  { id: 15719, s: "603358.SS", n: "Huada Automotive Tech Co", c: "CH" },
  { id: 15720, s: "603565.SS", n: "Shanghai Zhonggu Logistics", c: "CH" },
  { id: 15721, s: "CRBK.V", n: "Carebook Technologies Inc", c: "CA" },
  { id: 15722, s: "117580.KS", n: "Daesung Energy", c: "Korea" },
  { id: 15723, s: "3609.TWO", n: "Hep Tech", c: "TW" },
  { id: 15724, s: "300413.SZ", n: "Mango Excellent Media", c: "CH" },
  { id: 15725, s: "603345.SS", n: "Fujian Anjoy Foods", c: "CH" },
  { id: 15726, s: "603359.SS", n: "Jiangsu Dongzhu Landscape", c: "CH" },
  { id: 15727, s: "195870.KS", n: "Haesung DS", c: "Korea" },
  { id: 15728, s: "002625.SZ", n: "Kuang Chi Technologies", c: "CH" },
  { id: 15729, s: "204210.KS", n: "Mode Tour Real Estate Investment Trust Inc", c: "Korea" },
  { id: 15730, s: "ORAP.PA", n: "Orapi SA", c: "FR" },
  { id: 15731, s: "DCOP.V", n: "District Copper Co", c: "CA" },
  { id: 15732, s: "300373.SZ", n: "Yangzhou Yangjie Electronic Technology", c: "CH" },
  { id: 15733, s: "603387.SS", n: "Getein Biotech Inc", c: "CH" },
  { id: 15734, s: "603386.SS", n: "Guangdong Champion Asia Electronics", c: "CH" },
  { id: 15735, s: "300370.SZ", n: "Sichuan Etrol Technologies", c: "CH" },
  { id: 15736, s: "603369.SS", n: "Jiangsu King's Luck Brewery Joint-Stock", c: "CH" },
  { id: 15737, s: "603366.SS", n: "Solareast Holdings", c: "CH" },
  { id: 15738, s: "300357.SZ", n: "Zhejiang Wolwo Bio-Pharmaceutical", c: "CH" },
  { id: 15739, s: "300354.SZ", n: "DongHua Testing Technology", c: "CH" },
  { id: 15740, s: "268280.KS", n: "Miwon Specialty Chemical", c: "Korea" },
  { id: 15741, s: "300350.SZ", n: "HPF", c: "CH" },
  { id: 15742, s: "603530.SS", n: "Jiangsu Shemar Electric", c: "CH" },
  { id: 15743, s: "300455.SZ", n: "Beijing Ctrowell Technology Co", c: "CH" },
  { id: 15744, s: "CUCO.V", n: "Central African Gold Inc", c: "CA" },
  { id: 15745, s: "300446.SZ", n: "Baoding Lucky Innovative Materials", c: "CH" },
  { id: 15746, s: "603203.SS", n: "Quick Intelligent Equipment", c: "CH" },
  { id: 15747, s: "PJET", n: "Priority Aviation", c: "US" },
  { id: 15748, s: "ATG.WA", n: "ATM Grupa S.A.", c: "Poland" },
  { id: 15749, s: "603192.SS", n: "Shanghai Huide Science & Technology", c: "CH" },
  { id: 15750, s: "BHW.WA", n: "Bank Handlowy w Warszawie SA", c: "Poland" },
  { id: 15751, s: "603186.SS", n: "Zhejiang Huazheng New Material", c: "CH" },
  { id: 15752, s: "300185.SZ", n: "Tongyu Heavy Industry", c: "CH" },
  { id: 15753, s: "BMC.WA", n: "Bumech S.A.", c: "Poland" },
  { id: 15754, s: "603179.SS", n: "Jiangsu Xinquan Automotive Trim", c: "CH" },
  { id: 15755, s: "300174.SZ", n: "Fujian Yuanli Active Carbon", c: "CH" },
  { id: 15756, s: "EUR.PA", n: "Euro Ressources S.A.", c: "FR" },
  { id: 15757, s: "603269.SS", n: "Jiangsu Seagull Cooling Tower", c: "CH" },
  { id: 15758, s: "PHMMF", n: "Pharma Mar SAU", c: "US" },
  { id: 15759, s: "300251.SZ", n: "Beijing Enlight Media", c: "CH" },
  { id: 15760, s: "300253.SZ", n: "Shanghai Kingstar Winning Software", c: "CH" },
  { id: 15761, s: "603208.SS", n: "Jiangshan Oupai Door Industry", c: "CH" },
  { id: 15762, s: "4533.TWO", n: "Shieh Yih Machinery Industry", c: "TW" },
  { id: 15763, s: "300212.SZ", n: "Beijing E Hualu Info Tech", c: "CH" },
  { id: 15764, s: "300345.SZ", n: "Hunan Huamin Holdings", c: "CH" },
  { id: 15765, s: "PSAT.PA", n: "Passat Société Anonyme", c: "FR" },
  { id: 15766, s: "PROAC.PA", n: "Proactis SA", c: "FR" },
  { id: 15767, s: "603499.SS", n: "Shanghai Sunglow Packaging Technology Co Co Class A", c: "CH" },
  { id: 15768, s: "3587.TWO", n: "Materials Analysis Technology", c: "TW" },
  { id: 15769, s: "300482.SZ", n: "Guangzhou Wondfo Biotech", c: "CH" },
  { id: 15770, s: "BWLLY", n: "BW LPG ADR", c: "US" },
  { id: 15771, s: "603351.SS", n: "Nanjing Well Pharmaceutical", c: "CH" },
  { id: 15772, s: "603217.SS", n: "Yuanli Chemical Group", c: "CH" },
  { id: 15773, s: "603267.SS", n: "Beijing Yuanliu Hongyuan Electronic Technology", c: "CH" },
  { id: 15774, s: "2485.TW", n: "Zinwell Corporation", c: "TW" },
  { id: 15775, s: "300238.SZ", n: "Guanhao Biotech", c: "CH" },
  { id: 15776, s: "FNTN.F", n: "freenet AG", c: "GER" },
  { id: 15777, s: "ALL.WA", n: "Ailleron S.A.", c: "Poland" },
  { id: 15778, s: "4503.TWO", n: "Gold Rain Enterprises", c: "TW" },
  { id: 15779, s: "192820.KS", n: "Cosmax Inc", c: "Korea" },
  { id: 15780, s: "OSE.PA", n: "OSE Pharma SA", c: "FR" },
  { id: 15781, s: "300398.SZ", n: "Shanghai Phichem Material Co Class A", c: "CH" },
  { id: 15782, s: "TLX.WA", n: "Talex S.A.", c: "Poland" },
  { id: 15783, s: "GOFL.V", n: "Goldflare Exploration Inc", c: "CA" },
  { id: 15784, s: "ALWIT.PA", n: "Witbe Net SA", c: "FR" },
  { id: 15785, s: "RNK.WA", n: "Rank Progress S.A.", c: "Poland" },
  { id: 15786, s: "002690.SZ", n: "Hefei Meiya Optoelectronic Technology Inc", c: "CH" },
  { id: 15787, s: "003026.SZ", n: "Zhejiang Mtcn Technology ", c: "CH" },
  { id: 15788, s: "2380.TW", n: "Avision Inc", c: "TW" },
  { id: 15789, s: "SGN.WA", n: "Sygnity SA", c: "Poland" },
  { id: 15790, s: "002695.SZ", n: "Jiangxi Huangshanghuang Group Food", c: "CH" },
  { id: 15791, s: "SKL.WA", n: "Skyline Investment S.A.", c: "Poland" },
  { id: 15792, s: "601886.SS", n: "Jangho Group", c: "CH" },
  { id: 15793, s: "601878.SS", n: "Zheshang Securities", c: "CH" },
  { id: 15794, s: "SPL.WA", n: "Santander Bank Polska SA", c: "Poland" },
  { id: 15795, s: "002696.SZ", n: "Baiyang Aquatic Group Inc", c: "CH" },
  { id: 15796, s: "601872.SS", n: "CH Merchants Energy Shipping", c: "CH" },
  { id: 15797, s: "002697.SZ", n: "Chengdu Hongqi Chain", c: "CH" },
  { id: 15798, s: "603027.SS", n: "Qianhe Condiment&Food", c: "CH" },
  { id: 15799, s: "300109.SZ", n: "Boai NKY Pharmaceuticals", c: "CH" },
  { id: 15800, s: "GOB.WA", n: "Gobarto S.A.", c: "Poland" },
  { id: 15801, s: "EDI.WA", n: "ED Invest S.A.", c: "Poland" },
  { id: 15802, s: "FG.V", n: "Falcon Gold Co", c: "CA" },
  { id: 15803, s: "NC5A.F", n: "NorCom Information Technology GmbH & Co. KGaA", c: "GER" },
  { id: 15804, s: "DEL.WA", n: "Delko S.A.", c: "Poland" },
  { id: 15805, s: "300144.SZ", n: "Songcheng Performance Develop", c: "CH" },
  { id: 15806, s: "300142.SZ", n: "Walvax BioTech", c: "CH" },
  { id: 15807, s: "ECH.WA", n: "Echo Investment SA", c: "Poland" },
  { id: 15808, s: "603121.SS", n: "Shanghai Sinotec", c: "CH" },
  { id: 15809, s: "GKI.WA", n: "Immobile", c: "Poland" },
  { id: 15810, s: "ENG.WA", n: "Energa S.A.", c: "Poland" },
  { id: 15811, s: "ENP.WA", n: "Energoaparatura S.A.", c: "Poland" },
  { id: 15812, s: "603115.SS", n: "Nantong Haixing Electronics", c: "CH" },
  { id: 15813, s: "603113.SS", n: "Jinneng Science&Tech", c: "CH" },
  { id: 15814, s: "DPAM.PA", n: "Les Docks des Pétroles d'Ambès -SA", c: "FR" },
  { id: 15815, s: "002659.SZ", n: "Beijing Kaiwen Education Technology", c: "CH" },
  { id: 15816, s: "KBX.F", n: "Knorr-Bremse AG", c: "GER" },
  { id: 15817, s: "002989.SZ", n: "Shenzhen Strongteam Decoration Engineering", c: "CH" },
  { id: 15818, s: "DAU.V", n: "Desert Gold Ventures Inc", c: "CA" },
  { id: 15819, s: "ALMLB.PA", n: "Miliboo SA", c: "FR" },
  { id: 15820, s: "HNR1.F", n: "Hannover Rück SE", c: "GER" },
  { id: 15821, s: "ALREW.PA", n: "Reworld Media", c: "FR" },
  { id: 15822, s: "002673.SZ", n: "Western Securities", c: "CH" },
  { id: 15823, s: "200028.SZ", n: "CH National Accord Medicines Co", c: "CH" },
  { id: 15824, s: "200025.SZ", n: "Shenzhen Tellus Holding", c: "CH" },
  { id: 15825, s: "200020.SZ", n: "Shenzhen Zhongheng Huafa Co Class B", c: "CH" },
  { id: 15826, s: "HOT.F", n: "HOCHTIEF Aktiengesellschaft", c: "GER" },
  { id: 15827, s: "200016.SZ", n: "Konka Group", c: "CH" },
  { id: 15828, s: "SIGO", n: "Sunset Island Group Inc", c: "US" },
  { id: 15829, s: "200012.SZ", n: "CSG Holding", c: "CH" },
  { id: 15830, s: "ALSEN.PA", n: "Sensorion SA", c: "FR" },
  { id: 15831, s: "002653.SZ", n: "Xizang Haisco Pharmaceutical Group", c: "CH" },
  { id: 15832, s: "300172.SZ", n: "Nanjing CEC Environmental Protect", c: "CH" },
  { id: 15833, s: "CCC.WA", n: "CCC S.A.", c: "Poland" },
  { id: 15834, s: "SGDH", n: "Sgd Holdings", c: "US" },
  { id: 15835, s: "200512.SZ", n: "TsannKuen (CH) Enterprise Co.", c: "CH" },
  { id: 15836, s: "300168.SZ", n: "Wonders Information", c: "CH" },
  { id: 15837, s: "PGM.WA", n: "PMPG Polskie Media S.A.", c: "Poland" },
  { id: 15838, s: "PNBI", n: "Pioneer Bankshares Inc", c: "US" },
  { id: 15839, s: "SVNDF", n: "Seven & i Holdings Co..", c: "US" },
  { id: 15840, s: "002707.SZ", n: "Beijing Utour International Travel Service", c: "CH" },
  { id: 15841, s: "6170.TWO", n: "Welldone", c: "TW" },
  { id: 15842, s: "BRNE", n: "Borneo Resource Investments", c: "US" },
  { id: 15843, s: "601965.SS", n: "CH Automotive Engineering Research Institute", c: "CH" },
  { id: 15844, s: "ICP.F", n: "Panamax AG", c: "GER" },
  { id: 15845, s: "ALWED.PA", n: "Wedia SA", c: "FR" },
  { id: 15846, s: "200550.SZ", n: "Jiangling Motors Co", c: "CH" },
  { id: 15847, s: "201872.SZ", n: "CH Merchants Port Group", c: "CH" },
  { id: 15848, s: "603006.SS", n: "Shanghai Lianming Machinery", c: "CH" },
  { id: 15849, s: "5512.TWO", n: "Rich Development", c: "TW" },
  { id: 15850, s: "603005.SS", n: "CH Wafer Level Csp", c: "CH" },
  { id: 15851, s: "PAT.WA", n: "Patentus SA", c: "Poland" },
  { id: 15852, s: "GRB.V", n: "Greenbriar Capital Co", c: "CA" },
  { id: 15853, s: "TMBXF", n: "Tombstone Exploration Co", c: "US" },
  { id: 15854, s: "601928.SS", n: "Jiangsu Phoenix Publishing & Media Co", c: "CH" },
  { id: 15855, s: "EUZ.F", n: "Eckert & Ziegler Strahlen- und Medizintechnik AG", c: "GER" },
  { id: 15856, s: "600531.SS", n: "Henan Yuguang Gold & Lead", c: "CH" },
  { id: 15857, s: "600648.SS", n: "Shanghai Waigaoqiao Free Trade Zone Group Co A", c: "CH" },
  { id: 15858, s: "1456.TW", n: "I-Hwa Industrial", c: "TW" },
  { id: 15859, s: "600568.SS", n: "Zhongzhu Medical Holdings", c: "CH" },
  { id: 15860, s: "600699.SS", n: "Ningbo Joyson Electronic Co", c: "CH" },
  { id: 15861, s: "600565.SS", n: "Chongqing Dima Industry", c: "CH" },
  { id: 15862, s: "600756.SS", n: "Inspur Software", c: "CH" },
  { id: 15863, s: "TIMA.F", n: "ZEAL Network SE", c: "GER" },
  { id: 15864, s: "600750.SS", n: "Jiangzhong Pharmaceutical", c: "CH" },
  { id: 15865, s: "600499.SS", n: "Keda Clean Energy", c: "CH" },
  { id: 15866, s: "8931.TWO", n: "Ta-Yuan Cogeneration", c: "TW" },
  { id: 15867, s: "1524.TW", n: "Gordon Auto Body Parts", c: "TW" },
  { id: 15868, s: "600509.SS", n: "Xinjiang Tianfu Energy", c: "CH" },
  { id: 15869, s: "1201.TW", n: "Wei Chuan Foods Co", c: "TW" },
  { id: 15870, s: "600731.SS", n: "Hunan Haili Chemical", c: "CH" },
  { id: 15871, s: "600727.SS", n: "Shandong Lubei Chemical", c: "CH" },
  { id: 15872, s: "600622.SS", n: "Everbright Jiabao", c: "CH" },
  { id: 15873, s: "600620.SS", n: "Shanghai Tianchen", c: "CH" },
  { id: 15874, s: "1418.TW", n: "Tong-Hwa Synthetic Fiber", c: "TW" },
  { id: 15875, s: "SPMYY", n: "Spirent Communications plc ADR", c: "US" },
  { id: 15876, s: "600582.SS", n: "Tian Di Science & Technology", c: "CH" },
  { id: 15877, s: "600684.SS", n: "Guangzhou Pearl River Industrial Development", c: "CH" },
  { id: 15878, s: "UTDI.F", n: "United Internet AG NA", c: "GER" },
  { id: 15879, s: "600586.SS", n: "Shandong Jinjing Science and Technology Stock", c: "CH" },
  { id: 15880, s: "WAF.F", n: "Siltronic AG", c: "GER" },
  { id: 15881, s: "CCOB", n: "First American Silver Co", c: "US" },
  { id: 15882, s: "600668.SS", n: "Zhejiang Jianfeng Group", c: "CH" },
  { id: 15883, s: "600616.SS", n: "Shanghai Jinfeng Wine", c: "CH" },
  { id: 15884, s: "1444.TW", n: "Lealea Enterprise", c: "TW" },
  { id: 15885, s: "VOW.F", n: "Volkswagen AG", c: "GER" },
  { id: 15886, s: "600661.SS", n: "Shanghai Xin Nanyang", c: "CH" },
  { id: 15887, s: "002850.SZ", n: "Shenzhen Kedali Industry", c: "CH" },
  { id: 15888, s: "600657.SS", n: "Cinda Real Estate", c: "CH" },
  { id: 15889, s: "600612.SS", n: "Lao Feng Xiang Co A", c: "CH" },
  { id: 15890, s: "600871.SS", n: "Sinopec Oilfield Service Co", c: "CH" },
  { id: 15891, s: "600540.SS", n: "Xinjiang Sayram Modern Agriculture", c: "CH" },
  { id: 15892, s: "WSU.F", n: "WashTec AG", c: "GER" },
  { id: 15893, s: "1512.TW", n: "Jui Li Enterprise", c: "TW" },
  { id: 15894, s: "600085.SS", n: "Beijing Tongrentang", c: "CH" },
  { id: 15895, s: "ALDUB.PA", n: "Encres Dubuit SA", c: "FR" },
  { id: 15896, s: "NXU.F", n: "Nexus AG", c: "GER" },
  { id: 15897, s: "ALDLT.PA", n: "Delta Plus Group SA", c: "FR" },
  { id: 15898, s: "600105.SS", n: "Jiangsu Etern", c: "CH" },
  { id: 15899, s: "601198.SS", n: "Dongxing Sec", c: "CH" },
  { id: 15900, s: "002765.SZ", n: "Chongqing Landai Powertrain Co", c: "CH" },
  { id: 15901, s: "SMTOY", n: "Sumitomo Electric Industries ADR", c: "US" },
  { id: 15902, s: "600118.SS", n: "CH Spacesat", c: "CH" },
  { id: 15903, s: "PAL.F", n: "Pantaflix AG", c: "GER" },
  { id: 15904, s: "600127.SS", n: "JinJian Cereals Industry", c: "CH" },
  { id: 15905, s: "600128.SS", n: "Jiangsu Holly Co", c: "CH" },
  { id: 15906, s: "6523.TWO", n: "DR.Wu Skincare", c: "TW" },
  { id: 15907, s: "603337.SS", n: "Jack Sewing", c: "CH" },
  { id: 15908, s: "ALCUR.PA", n: "Arcure SA", c: "FR" },
  { id: 15909, s: "601298.SS", n: "Qingdao Port International", c: "CH" },
  { id: 15910, s: "601326.SS", n: "Qinhuangdao Port", c: "CH" },
  { id: 15911, s: "600078.SS", n: "Jiangsu Chengxing Phosph-Chemical", c: "CH" },
  { id: 15912, s: "600560.SS", n: "Beijing AriTime Intelligent Control", c: "CH" },
  { id: 15913, s: "TTR1.F", n: "technotrans SE", c: "GER" },
  { id: 15914, s: "600711.SS", n: "Chengtun Mining Group", c: "CH" },
  { id: 15915, s: "2HRA.SG", n: "H&R GmbH & Co. KGaA", c: "GER" },
  { id: 15916, s: "600550.SS", n: "Baoding Tianwei Baobian Electric", c: "CH" },
  { id: 15917, s: "600710.SS", n: "Sumec Co", c: "CH" },
  { id: 15918, s: "ZAL.F", n: "Zalando SE", c: "GER" },
  { id: 15919, s: "600561.SS", n: "Jiangxi Changyun", c: "CH" },
  { id: 15920, s: "600076.SS", n: "Kangxin New Materials", c: "CH" },
  { id: 15921, s: "600717.SS", n: "Tianjin Port", c: "CH" },
  { id: 15922, s: "600872.SS", n: "Jonjee Hi-tech Industrial and Commercial Holding", c: "CH" },
  { id: 15923, s: "600322.SS", n: "Tianjin Realty Development Group", c: "CH" },
  { id: 15924, s: "SOW.F", n: "Software Aktiengesellschaft", c: "GER" },
  { id: 15925, s: "ALEMG.PA", n: "Euromedis Groupe SA", c: "FR" },
  { id: 15926, s: "ALECP.PA", n: "EuropaCo", c: "FR" },
  { id: 15927, s: "600580.SS", n: "Wolong Electric Group", c: "CH" },
  { id: 15928, s: "OHB.F", n: "OHB SE", c: "GER" },
  { id: 15929, s: "600759.SS", n: "Geo-Jade Petroleum Co", c: "CH" },
  { id: 15930, s: "EVK.F", n: "Evonik Industries AG", c: "GER" },
  { id: 15931, s: "603603.SS", n: "Poten Environment Group", c: "CH" },
  { id: 15932, s: "BWOWF", n: "Wowjoint Holdings Limited", c: "US" },
  { id: 15933, s: "002727.SZ", n: "Yunnan Hongxiang Yixintang", c: "CH" },
  { id: 15934, s: "8349.TWO", n: "QST International", c: "TW" },
  { id: 15935, s: "600822.SS", n: "Shanghai Material Trading Co A", c: "CH" },
  { id: 15936, s: "600820.SS", n: "Shanghai Tunnel Engineering", c: "CH" },
  { id: 15937, s: "002827.SZ", n: "Tibet GaoZheng Explosive", c: "CH" },
  { id: 15938, s: "600814.SS", n: "Hangzhou Jiebai Group", c: "CH" },
  { id: 15939, s: "600409.SS", n: "Tangshan Sanyou Chemical Industries", c: "CH" },
  { id: 15940, s: "600410.SS", n: "Beijing Teamsun Technology", c: "CH" },
  { id: 15941, s: "600808.SS", n: "Maanshan Iron & Steel", c: "CH" },
  { id: 15942, s: "P911.SG", n: "Porsche AG", c: "GER" },
  { id: 15943, s: "SVE.F", n: "Shareholder Value Beteiligungen AG", c: "GER" },
  { id: 15944, s: "SY1.F", n: "Symrise AG", c: "GER" },
  { id: 15945, s: "600794.SS", n: "Zhangjiagang Freetrade Science and Technology", c: "CH" },
  { id: 15946, s: "603328.SS", n: "Guangdong Ellington Electronics Technology", c: "CH" },
  { id: 15947, s: "402340.KS", n: "SK Square", c: "Korea" },
  { id: 15948, s: "600452.SS", n: "Chongqing Fuling Electric Power Industrial", c: "CH" },
  { id: 15949, s: "603298.SS", n: "Hangcha Group", c: "CH" },
  { id: 15950, s: "298050.KS", n: "Hyosung Advanced Materials Co", c: "Korea" },
  { id: 15951, s: "298690.KS", n: "Air Busan", c: "Korea" },
  { id: 15952, s: "600761.SS", n: "Anhui Heli", c: "CH" },
  { id: 15953, s: "298040.KS", n: "Hyosung Heavy Industries Co", c: "Korea" },
  { id: 15954, s: "603335.SS", n: "Guangdong Dcenti Auto-Parts Stock", c: "CH" },
  { id: 15955, s: "DMI.V", n: "Diamcor Mining", c: "CA" },
  { id: 15956, s: "LTA.PA", n: "Altamir SCA", c: "FR" },
  { id: 15957, s: "MLERO.PA", n: "Euroland Corporate SA", c: "FR" },
  { id: 15958, s: "LNA.PA", n: "LNA Sante SA", c: "FR" },
  { id: 15959, s: "603323.SS", n: "Jiangsu Wujiang Rural Coml Bk", c: "CH" },
  { id: 15960, s: "603322.SS", n: "Guangdong Super Telecom", c: "CH" },
  { id: 15961, s: "300296.SZ", n: "Leyard Optoelectronic", c: "CH" },
  { id: 15962, s: "603320.SS", n: "Zhejiang Dibay Electric", c: "CH" },
  { id: 15963, s: "603317.SS", n: "Sichuan Teway Food Group", c: "CH" },
  { id: 15964, s: "603316.SS", n: "Zhejiang Chengbang Landscape", c: "CH" },
  { id: 15965, s: "600792.SS", n: "Yunnan Coal & Energy", c: "CH" },
  { id: 15966, s: "300314.SZ", n: "Ningbo David Medical Device", c: "CH" },
  { id: 15967, s: "600455.SS", n: "ButOne Information Corp XiAn", c: "CH" },
  { id: 15968, s: "600343.SS", n: "Shaanxi Aerospace Power Hi-Tech", c: "CH" },
  { id: 15969, s: "600350.SS", n: "Shandong Hi-speed", c: "CH" },
  { id: 15970, s: "600352.SS", n: "Zhejiang Longsheng Group", c: "CH" },
  { id: 15971, s: "600847.SS", n: "Chongqing Wanli New Energy", c: "CH" },
  { id: 15972, s: "600844.SS", n: "Danhua Chemical Technology Co A", c: "CH" },
  { id: 15973, s: "002816.SZ", n: "Shenzhen HeKeda Precision Cleaning Equipment", c: "CH" },
  { id: 15974, s: "600838.SS", n: "Shanghai Join Buy", c: "CH" },
  { id: 15975, s: "002818.SZ", n: "Chengdu Fusen Noble-House Industrial", c: "CH" },
  { id: 15976, s: "600836.SS", n: "Shanghai Jielong Industry Group Co", c: "CH" },
  { id: 15977, s: "002821.SZ", n: "Asymchem Laboratories Tian Jin", c: "CH" },
  { id: 15978, s: "600373.SS", n: "Chinese Universe Publishing And Media", c: "CH" },
  { id: 15979, s: "600763.SS", n: "Top Choice Medical Investment Co Inc", c: "CH" },
  { id: 15980, s: "600375.SS", n: "Hanma Technology Group", c: "CH" },
  { id: 15981, s: "1540.TW", n: "Roundtop Machinery Industries", c: "TW" },
  { id: 15982, s: "600828.SS", n: "Maoye Commercial", c: "CH" },
  { id: 15983, s: "ADKO.VI", n: "Addiko Bank AG", c: "Austria" },
  { id: 15984, s: "ST5.F", n: "STEICO SE", c: "GER" },
  { id: 15985, s: "600370.SS", n: "Jiangsu Sanfangxiang Industry", c: "CH" },
  { id: 15986, s: "600859.SS", n: "Wangfujing", c: "CH" },
  { id: 15987, s: "600829.SS", n: "HPGC Renmintongtai Pharmaceutical Co", c: "CH" },
  { id: 15988, s: "002813.SZ", n: "Shenzhen RoadRover Technology", c: "CH" },
  { id: 15989, s: "600774.SS", n: "Wuhan Hanshang Group", c: "CH" },
  { id: 15990, s: "600475.SS", n: "Wuxi Huaguang Boiler", c: "CH" },
  { id: 15991, s: "600776.SS", n: "Eastern Communications Co A", c: "CH" },
  { id: 15992, s: "600768.SS", n: "Ningbo Fubang", c: "CH" },
  { id: 15993, s: "600824.SS", n: "Shanghai Yimin Commerce Group", c: "CH" },
  { id: 15994, s: "600387.SS", n: "HY Energy Group", c: "CH" },
  { id: 15995, s: "600869.SS", n: "Far East Smarter Energy", c: "CH" },
  { id: 15996, s: "600777.SS", n: "Shandong Xinchao Energy", c: "CH" },
  { id: 15997, s: "600862.SS", n: "Avic Aviation High Technology", c: "CH" },
  { id: 15998, s: "600865.SS", n: "Baida Group", c: "CH" },
  { id: 15999, s: "600467.SS", n: "Shandong Homey Aquatic Development", c: "CH" },
  { id: 16000, s: "1616.TW", n: "Evertop Wire Cable Co", c: "TW" },
  { id: 16001, s: "MOH.AX", n: "Moho Resources", c: "Australia" },
  { id: 16002, s: "023770.KQ", n: "PLAYWITH Inc", c: "Korea" },
  { id: 16003, s: "023900.KQ", n: "Pungguk Ethanol Industrial Co.", c: "Korea" },
  { id: 16004, s: "HOKCF", n: "Hong Kong and CH Gas", c: "US" },
  { id: 16005, s: "032960.KQ", n: "Dongil Technology", c: "Korea" },
  { id: 16006, s: "MTO.AX", n: "MotorCycle Holdings", c: "Australia" },
  { id: 16007, s: "021040.KQ", n: "dhSteel", c: "Korea" },
  { id: 16008, s: "MTL.AX", n: "Manalto Limited", c: "Australia" },
  { id: 16009, s: "MTH.AX", n: "Mithril Resources", c: "Australia" },
  { id: 16010, s: "019770.KQ", n: "Seoyon Topmetal Co.", c: "Korea" },
  { id: 16011, s: "MKR.AX", n: "Manuka Resources Ltd", c: "Australia" },
  { id: 16012, s: "MRI.AX", n: "My Rewards International", c: "Australia" },
  { id: 16013, s: "010280.KQ", n: "Ssangyong Information & Communications Co", c: "Korea" },
  { id: 16014, s: "007820.KQ", n: "SMCore.Inc", c: "Korea" },
  { id: 16015, s: "031310.KQ", n: "Eyesvision Co", c: "Korea" },
  { id: 16016, s: "MIR.AX", n: "Mirrabooka Investments", c: "Australia" },
  { id: 16017, s: "NIS.AX", n: "Nickelsearch", c: "Australia" },
  { id: 16018, s: "010240.KQ", n: "Heungkuk Metaltech Co.Ltd", c: "Korea" },
  { id: 16019, s: "MDI.AX", n: "Middle Island Resources", c: "Australia" },
  { id: 16020, s: "035080.KQ", n: "Interpark Co.", c: "Korea" },
  { id: 16021, s: "NIM.AX", n: "Nimy Resources", c: "Australia" },
  { id: 16022, s: "HLDVF", n: "Henderson Land", c: "US" },
  { id: 16023, s: "034810.KQ", n: "Haesung Industrial Co.", c: "Korea" },
  { id: 16024, s: "033540.KQ", n: "Paratech Company Limited", c: "Korea" },
  { id: 16025, s: "MVP.AX", n: "Medical Developments International", c: "Australia" },
  { id: 16026, s: "NFL.AX", n: "Norfolk Metals.", c: "Australia" },
  { id: 16027, s: "NES.AX", n: "Nelson Resources", c: "Australia" },
  { id: 16028, s: "MGA.AX", n: "MetalsGrove Mining", c: "Australia" },
  { id: 16029, s: "MOGL.AX", n: "Montaka Global Long Only Equites Fund (Managed Fund)", c: "Australia" },
  { id: 16030, s: "043710.KQ", n: "Seouleaguer Co.", c: "Korea" },
  { id: 16031, s: "025900.KQ", n: "Dongwha Enterprise Co.Ltd", c: "Korea" },
  { id: 16032, s: "HSNGY", n: "Hang Seng Bank", c: "US" },
  { id: 16033, s: "007720.KQ", n: "Daemyung Sonoseason Co.Ltd", c: "Korea" },
  { id: 16034, s: "039830.KQ", n: "Aurora World Corporation", c: "Korea" },
  { id: 16035, s: "036170.KQ", n: "Cloud Air Co.Ltd", c: "Korea" },
  { id: 16036, s: "014570.KQ", n: "Korean Drug Co.", c: "Korea" },
  { id: 16037, s: "039840.KQ", n: "DIO Corporation", c: "Korea" },
  { id: 16038, s: "WQNI", n: "WQN Inc", c: "US" },
  { id: 16039, s: "048830.KQ", n: "NPK Co.Ltd", c: "Korea" },
  { id: 16040, s: "048770.KQ", n: "TPC Mechatronics Corporation", c: "Korea" },
  { id: 16041, s: "KLI.AX", n: "Killi Resources", c: "Australia" },
  { id: 16042, s: "AZRM.TA", n: "Azorim Investment Development and Construction", c: "Israel" },
  { id: 16043, s: "048470.KQ", n: "Daedong Steel Co.", c: "Korea" },
  { id: 16044, s: "OMA.AX", n: "Omega Oil & Gas.", c: "Australia" },
  { id: 16045, s: "047770.KQ", n: "Codes Combine Co.", c: "Korea" },
  { id: 16046, s: "046890.KQ", n: "Seoul Semiconductor Co.", c: "Korea" },
  { id: 16047, s: "OIL.AX", n: "Optiscan Imaging", c: "Australia" },
  { id: 16048, s: "044340.KQ", n: "Winix Inc", c: "Korea" },
  { id: 16049, s: "ODE.AX", n: "Odessa Minerals", c: "Australia" },
  { id: 16050, s: "LGL.AX", n: "Lynch Group Holdings", c: "Australia" },
  { id: 16051, s: "XCRT", n: "Union Dental Holdings Inc", c: "US" },
  { id: 16052, s: "042040.KQ", n: "KPM TECH Co.", c: "Korea" },
  { id: 16053, s: "NXG.AX", n: "Nexgen Energy DRC", c: "Australia" },
  { id: 16054, s: "NXD.AX", n: "NextEd Group.", c: "Australia" },
  { id: 16055, s: "LNR.AX", n: "Lanthanein Resources Limited", c: "Australia" },
  { id: 16056, s: "AVIV.TA", n: "Mordechai Aviv Taasiot Beniyah 1973", c: "Israel" },
  { id: 16057, s: "MAU.AX", n: "Magnetic Resources NL", c: "Australia" },
  { id: 16058, s: "NNG.AX", n: "NEXION Group", c: "Australia" },
  { id: 16059, s: "027710.KQ", n: "FarmStory Co.", c: "Korea" },
  { id: 16060, s: "MX1.AX", n: "Micro-X", c: "Australia" },
  { id: 16061, s: "MMC.AX", n: "Mitre Mining Corporation", c: "Australia" },
  { id: 16062, s: "012860.KQ", n: "Mobase Electronics Co.Ltd", c: "Korea" },
  { id: 16063, s: "069080.KQ", n: "Webzen Inc", c: "Korea" },
  { id: 16064, s: "MHK.AX", n: "Metal Hawk", c: "Australia" },
  { id: 16065, s: "MI6.AX", n: "Minerals 260", c: "Australia" },
  { id: 16066, s: "PWR.AX", n: "Peter Warren Automotive Holdings", c: "Australia" },
  { id: 16067, s: "067920.KQ", n: "IGLOO SECURITY Inc", c: "Korea" },
  { id: 16068, s: "PVT.AX", n: "Pivotal Metals.", c: "Australia" },
  { id: 16069, s: "BRIM.IC", n: "Brim hf", c: "Iceland" },
  { id: 16070, s: "067390.KQ", n: "AeroSpace Technology of Korea", c: "Korea" },
  { id: 16071, s: "067310.KQ", n: "HANA Micron Inc", c: "Korea" },
  { id: 16072, s: "HLF.AX", n: "Halo Food", c: "Australia" },
  { id: 16073, s: "FESTI.IC", n: "Festi hf", c: "Iceland" },
  { id: 16074, s: "KATX", n: "KAT Exploration Inc", c: "US" },
  { id: 16075, s: "066980.KQ", n: "E& Corporation CO.", c: "Korea" },
  { id: 16076, s: "060150.KQ", n: "INSUN Environmental New Technology Co.", c: "Korea" },
  { id: 16077, s: "PNM.AX", n: "Pacific Nickel Mines", c: "Australia" },
  { id: 16078, s: "BASC.L", n: "Brown Advisory US Smaller Companies PLC", c: "UK" },
  { id: 16079, s: "ATS.L", n: "Artemis Alpha Trust", c: "UK" },
  { id: 16080, s: "PGF.AX", n: "PM Capital Global Opportunities Fund Limited", c: "Australia" },
  { id: 16081, s: "AST.L", n: "Ascent Resources plc", c: "UK" },
  { id: 16082, s: "052900.KQ", n: "KMH Hitech Co.", c: "Korea" },
  { id: 16083, s: "JAV.AX", n: "Javelin Minerals", c: "Australia" },
  { id: 16084, s: "PE1.AX", n: "Pengana Private Equity Trust", c: "Australia" },
  { id: 16085, s: "INOH", n: "In Ovations Hldgs In", c: "US" },
  { id: 16086, s: "052260.KQ", n: "HYUNDAI BIOLAND Co.Ltd", c: "Korea" },
  { id: 16087, s: "PCL.AX", n: "Pancontinental Oil & Gas NL", c: "Australia" },
  { id: 16088, s: "HDIH", n: "H-D International Holdings Group", c: "US" },
  { id: 16089, s: "ALU.L", n: "The Alumasc Group plc", c: "UK" },
  { id: 16090, s: "050890.KQ", n: "SOLiD Inc", c: "Korea" },
  { id: 16091, s: "OSL.AX", n: "Oncosil Medical", c: "Australia" },
  { id: 16092, s: "036670.KQ", n: "KCI Limited", c: "Korea" },
  { id: 16093, s: "ANLT.TA", n: "Analyst IMS Investment Management Services", c: "Israel" },
  { id: 16094, s: "ALT.L", n: "Altitude Group Plc", c: "UK" },
  { id: 16095, s: "BRSA.L", n: "Blackrock North American Income Trust PLC", c: "UK" },
  { id: 16096, s: "BSC.L", n: "British Smaller Companies Vct 2 Plc", c: "UK" },
  { id: 16097, s: "069410.KQ", n: "nTels Co.", c: "Korea" },
  { id: 16098, s: "065170.KQ", n: "Next Bt Co.", c: "Korea" },
  { id: 16099, s: "MKAX.AX", n: "Montaka Global Extension Fund", c: "Australia" },
  { id: 16100, s: "KKC.AX", n: "Kkr Credit Income Fund", c: "Australia" },
  { id: 16101, s: "OPL.AX", n: "Opyl", c: "Australia" },
  { id: 16102, s: "ARYT.TA", n: "Aryt Industries", c: "Israel" },
  { id: 16103, s: "REITIR.IC", n: "Reitir fasteignafélag hf", c: "Iceland" },
  { id: 16104, s: "SJOVA.IC", n: "Sjóvá-Almennar tryggingar hf", c: "Iceland" },
  { id: 16105, s: "066590.KQ", n: "Woosu AMS Co.Ltd", c: "Korea" },
  { id: 16106, s: "PSQ.AX", n: "Pacific Smiles Group", c: "Australia" },
  { id: 16107, s: "066130.KQ", n: "Haatz Inc", c: "Korea" },
  { id: 16108, s: "AALLON.HE", n: "Aallon Group Oyj", c: "Finland" },
  { id: 16109, s: "HRE.AX", n: "Heavy Rare Earths", c: "Australia" },
  { id: 16110, s: "APETIT.HE", n: "Apetit Oyj", c: "Finland" },
  { id: 16111, s: "065450.KQ", n: "Victek Co.", c: "Korea" },
  { id: 16112, s: "JVCZY", n: "JVC Kenwood Corp ADR", c: "US" },
  { id: 16113, s: "TRE.OL", n: "Treasure ASA", c: "Norway" },
  { id: 16114, s: "065060.KQ", n: "GNCO Co.", c: "Korea" },
  { id: 16115, s: "064760.KQ", n: "Tokai Carbon Korea Co.", c: "Korea" },
  { id: 16116, s: "PR1.AX", n: "Pure Resources", c: "Australia" },
  { id: 16117, s: "GOFORE.HE", n: "Gofore Oyj", c: "Finland" },
  { id: 16118, s: "PPK.AX", n: "PPK Group", c: "Australia" },
  { id: 16119, s: "BGCG.L", n: "Baillie Gifford CH Growth Trust PLC", c: "UK" },
  { id: 16120, s: "HMG.AX", n: "Hamelin Gold", c: "Australia" },
  { id: 16121, s: "060260.KQ", n: "Nuvotec", c: "Korea" },
  { id: 16122, s: "PUR.AX", n: "Pursuit Minerals", c: "Australia" },
  { id: 16123, s: "KVIKA.IC", n: "Kvika banki hf", c: "Iceland" },
  { id: 16124, s: "KGDEY", n: "Kingdee International Software Group Co ADR", c: "US" },
  { id: 16125, s: "BSFA.L", n: "BSF Enterprise Plc", c: "UK" },
  { id: 16126, s: "QAL.AX", n: "Qualitas", c: "Australia" },
  { id: 16127, s: "007680.KQ", n: "Daewon Co.", c: "Korea" },
  { id: 16128, s: "MAVEN.ST", n: "Maven Wireless Sweden AB", c: "Sweden" },
  { id: 16129, s: "M3M.AX", n: "M3 Mining", c: "Australia" },
  { id: 16130, s: "007330.KQ", n: "Pureun Mutual Savings Bank Co.", c: "Korea" },
  { id: 16131, s: "SSG.OL", n: "Self Storage Group ASA", c: "Norway" },
  { id: 16132, s: "115610.KQ", n: "IMAGIS Co.", c: "Korea" },
  { id: 16133, s: "SMS.AX", n: "Star Minerals", c: "Australia" },
  { id: 16134, s: "TRP.AX", n: "Tissue Repair", c: "Australia" },
  { id: 16135, s: "NOSA.ST", n: "Nosa Med AB", c: "Sweden" },
  { id: 16136, s: "FRMO", n: "Frmo Co", c: "US" },
  { id: 16137, s: "MGCLF", n: "MGC Pharmaceuticals", c: "US" },
  { id: 16138, s: "NP3.ST", n: "NP3 Fastigheter AB", c: "Sweden" },
  { id: 16139, s: "TRE.AX", n: "TOUBANI RESOURCES INC.", c: "Australia" },
  { id: 16140, s: "CPM.AX", n: "Cooper Metals", c: "Australia" },
  { id: 16141, s: "208350.KQ", n: "KB SPAC 5", c: "Korea" },
  { id: 16142, s: "JEMI.L", n: "JPMorgan Global Emerging Markets Investment Trust PLC", c: "UK" },
  { id: 16143, s: "JEDT.L", n: "JPMorgan Euro Small Companies Trust Plc", c: "UK" },
  { id: 16144, s: "CTQ.AX", n: "Careteq Limited", c: "Australia" },
  { id: 16145, s: "RIGHTB.ST", n: "RightBridge Ventures Group AB", c: "Sweden" },
  { id: 16146, s: "MDMN", n: "Medinah Minerals Inc", c: "US" },
  { id: 16147, s: "RIZZO-B.ST", n: "Rizzo Group AB (publ)", c: "Sweden" },
  { id: 16148, s: "RLOS-B.ST", n: "RanLOS AB Series B", c: "Sweden" },
  { id: 16149, s: "196490.KQ", n: "DA Technology", c: "Korea" },
  { id: 16150, s: "MHNC", n: "Maiden Holdings North America ELKS", c: "US" },
  { id: 16151, s: "CWP.AX", n: "Cedar Woods Properties", c: "Australia" },
  { id: 16152, s: "218410.KQ", n: "NH SPAC 8", c: "Korea" },
  { id: 16153, s: "CMX.AX", n: "ChemX Materials", c: "Australia" },
  { id: 16154, s: "263750.KQ", n: "Pearl Abyss Co", c: "Korea" },
  { id: 16155, s: "MAV.ST", n: "Mavshack publ AB", c: "Sweden" },
  { id: 16156, s: "META.ST", n: "Metacon AB", c: "Sweden" },
  { id: 16157, s: "MIDW-B.ST", n: "Midway Holding AB ser. B", c: "Sweden" },
  { id: 16158, s: "UVA.AX", n: "Uvre Limited", c: "Australia" },
  { id: 16159, s: "MNTC.ST", n: "Mentice AB", c: "Sweden" },
  { id: 16160, s: "083310.KQ", n: "LOT VACUUM Co.", c: "Korea" },
  { id: 16161, s: "CMET.L", n: "Capital Metals PLC", c: "UK" },
  { id: 16162, s: "GMVHY", n: "Entain DRC PLC", c: "US" },
  { id: 16163, s: "082800.KQ", n: "Vivozon Healthcare Inc", c: "Korea" },
  { id: 16164, s: "GRE.AX", n: "Greentech Metals", c: "Australia" },
  { id: 16165, s: "ARTS.TA", n: "Airtouch Solar", c: "Israel" },
  { id: 16166, s: "RING.OL", n: "Sparebank 1 Ringerike Hadeland", c: "Norway" },
  { id: 16167, s: "078160.KQ", n: "MEDIPOST Co.", c: "Korea" },
  { id: 16168, s: "078150.KQ", n: "HB Technology CO.LTD", c: "Korea" },
  { id: 16169, s: "SAGA.OL", n: "Saga Pure ASA", c: "Norway" },
  { id: 16170, s: "RC1.AX", n: "Redcastle Resources", c: "Australia" },
  { id: 16171, s: "KKOYY", n: "Kesko Oyj ADR", c: "US" },
  { id: 16172, s: "RBR.AX", n: "RBR Group", c: "Australia" },
  { id: 16173, s: "SIKRI.OL", n: "Sikri Holding AS", c: "Norway" },
  { id: 16174, s: "073540.KQ", n: "Frtek Co.Ltd", c: "Korea" },
  { id: 16175, s: "REP.AX", n: "RAM Essential Services Property Fund Unit", c: "Australia" },
  { id: 16176, s: "089850.KQ", n: "UBIVELOX Inc", c: "Korea" },
  { id: 16177, s: "083450.KQ", n: "Global Standard Technology Limited", c: "Korea" },
  { id: 16178, s: "NBX.OL", n: "Norwegian Block Exchange AS", c: "Norway" },
  { id: 16179, s: "RON.AX", n: "Ronin Resources", c: "Australia" },
  { id: 16180, s: "ROC.AX", n: "RocketBoots", c: "Australia" },
  { id: 16181, s: "CSFS.L", n: "Cornerstone FS PLC", c: "UK" },
  { id: 16182, s: "HBC.OL", n: "Hofseth Biocare ASA", c: "Norway" },
  { id: 16183, s: "GCM.AX", n: "Garda Capital Group", c: "Australia" },
  { id: 16184, s: "GMEV", n: "Great CH Mania Holdings Inc", c: "US" },
  { id: 16185, s: "CPH2.L", n: "Clean Power Hydrogen PLC", c: "UK" },
  { id: 16186, s: "086060.KQ", n: "GeneBioTech Co.", c: "Korea" },
  { id: 16187, s: "086040.KQ", n: "Biotoxtech Co.", c: "Korea" },
  { id: 16188, s: "085810.KQ", n: "Alticast Corporation", c: "Korea" },
  { id: 16189, s: "085670.KQ", n: "NewFlex Technology Co.", c: "Korea" },
  { id: 16190, s: "COM.L", n: "Comptoir Group PLC", c: "UK" },
  { id: 16191, s: "LUMI.OL", n: "Lumi Gruppen AS", c: "Norway" },
  { id: 16192, s: "MODTX.ST", n: "Modus Therapeutics Holding AB", c: "Sweden" },
  { id: 16193, s: "UNT.AX", n: "Unith.", c: "Australia" },
  { id: 16194, s: "NBZ.ST", n: "Northbaze Group AB", c: "Sweden" },
  { id: 16195, s: "HGEN.L", n: "Hydrogenone Capital Growth PLC", c: "UK" },
  { id: 16196, s: "SEDANA.ST", n: "Sedana Medical AB", c: "Sweden" },
  { id: 16197, s: "SENZA.ST", n: "SenzaGen AB", c: "Sweden" },
  { id: 16198, s: "IIG.L", n: "Intuitive Investments Group Plc", c: "UK" },
  { id: 16199, s: "IGV.L", n: "The Income & Growth VCT plc", c: "UK" },
  { id: 16200, s: "IGE.L", n: "Image Scan Holdings Plc", c: "UK" },
  { id: 16201, s: "IGC.L", n: "India Capital Growth Fund", c: "UK" },
  { id: 16202, s: "171090.KQ", n: "SUNIC SYSTEM Co.", c: "Korea" },
  { id: 16203, s: "171010.KQ", n: "Ram Technology Co.", c: "Korea" },
  { id: 16204, s: "IAT.L", n: "Invesco Asia Trust", c: "UK" },
  { id: 16205, s: "153490.KQ", n: "Wooree E&L Co.", c: "Korea" },
  { id: 16206, s: "151860.KQ", n: "KG Eco Technology Services Co.", c: "Korea" },
  { id: 16207, s: "TANGI.ST", n: "Tangiamo Touch Technology AB", c: "Sweden" },
  { id: 16208, s: "TEL2-A.ST", n: "Tele2 AB (publ)", c: "Sweden" },
  { id: 16209, s: "H2G.AX", n: "GreenHy2.", c: "Australia" },
  { id: 16210, s: "SEB-C.ST", n: "Skandinaviska Enskilda Banken AB (publ)", c: "Sweden" },
  { id: 16211, s: "GRPS", n: "Gold River Prods Inc", c: "US" },
  { id: 16212, s: "LOM.AX", n: "Lucapa Diamond", c: "Australia" },
  { id: 16213, s: "IDGC", n: "Idglobal Co", c: "US" },
  { id: 16214, s: "WJRYY", n: "West Japan Railway Co ADR", c: "US" },
  { id: 16215, s: "LPM.AX", n: "Lithium Plus Minerals", c: "Australia" },
  { id: 16216, s: "039030.KQ", n: "EO Technics Co.", c: "Korea" },
  { id: 16217, s: "LRL.AX", n: "Labyrinth Resources Limited", c: "Australia" },
  { id: 16218, s: "NTD.AX", n: "National Tyre & Wheel", c: "Australia" },
  { id: 16219, s: "003380.KQ", n: "Harim Holdings Co.", c: "Korea" },
  { id: 16220, s: "005160.KQ", n: "Dongkuk Industries Co.", c: "Korea" },
  { id: 16221, s: "NSM.AX", n: "North Stawell Minerals Ltd", c: "Australia" },
  { id: 16222, s: "037760.KQ", n: "Cenit Co.", c: "Korea" },
  { id: 16223, s: "M2M.AX", n: "Mt Malcolm Mines NL", c: "Australia" },
  { id: 16224, s: "187220.KQ", n: "DT&C Co.", c: "Korea" },
  { id: 16225, s: "MCNO", n: "Mucinno Holding Inc", c: "US" },
  { id: 16226, s: "236200.KQ", n: "Suprema Inc", c: "Korea" },
  { id: 16227, s: "C5H.IR", n: "Cairn Homes PLC", c: "Ireland" },
  { id: 16228, s: "NCC-A.ST", n: "NCC AB (publ)", c: "Sweden" },
  { id: 16229, s: "MLCG", n: "ML Capital Group Inc", c: "US" },
  { id: 16230, s: "KDNC.L", n: "Cadence Minerals PLC", c: "UK" },
  { id: 16231, s: "NICO.ST", n: "Nicoccino Holding AB", c: "Sweden" },
  { id: 16232, s: "NILAR.ST", n: "Nilar International AB", c: "Sweden" },
  { id: 16233, s: "SLH.AX", n: "Silk Logistics Holdings", c: "Australia" },
  { id: 16234, s: "S2M.ST", n: "S2Medical AB", c: "Sweden" },
  { id: 16235, s: "FTII", n: "Futuretech II Acquisition Co", c: "US" },
  { id: 16236, s: "STP.AX", n: "Step One Clothing", c: "Australia" },
  { id: 16237, s: "GUN.L", n: "Gunsynd PLC", c: "UK" },
  { id: 16238, s: "GSF.L", n: "Gore Street Energy Storage Fund Plc", c: "UK" },
  { id: 16239, s: "XMR.ST", n: "XMReality AB", c: "Sweden" },
  { id: 16240, s: "LRSV", n: "Link Reservations Inc", c: "US" },
  { id: 16241, s: "KMR.IR", n: "Kenmare Resources PLC", c: "Ireland" },
  { id: 16242, s: "HHV.L", n: "Hargreave Hale Aim Vct PLC", c: "UK" },
  { id: 16243, s: "AGF-B.", n: "AGF A/S", c: "Denmark" },
  { id: 16244, s: "GDWN.L", n: "Goodwin PLC", c: "UK" },
  { id: 16245, s: "AQP.", n: "Aquaporin AS", c: "Denmark" },
  { id: 16246, s: "BACTIQ.", n: "Bactiquant AS", c: "Denmark" },
  { id: 16247, s: "SPD.AX", n: "Southern Palladium Limited", c: "Australia" },
  { id: 16248, s: "GCL.L", n: "Geiger Counter Limited", c: "UK" },
  { id: 16249, s: "BRAINP.", n: "Brain+ ApS", c: "Denmark" },
  { id: 16250, s: "LOGL", n: "Legend Oil & Gas", c: "US" },
  { id: 16251, s: "LOCC", n: "Live Oak Crestview Climate Acquisition Co", c: "US" },
  { id: 16252, s: "SNS.AX", n: "Sensen Networks", c: "Australia" },
  { id: 16253, s: "HEIT.L", n: "Harmony Energy Income Trust PLC", c: "UK" },
  { id: 16254, s: "SAGA-A.ST", n: "AB Sagax (publ)", c: "Sweden" },
  { id: 16255, s: "GASE", n: "Gase Energy Inc", c: "US" },
  { id: 16256, s: "ALK.L", n: "Alkemy Capital Investments Plc", c: "UK" },
  { id: 16257, s: "300371.SZ", n: "Huizhong Instrumentation", c: "CH" },
  { id: 16258, s: "053800.KQ", n: "AhnLab Inc", c: "Korea" },
  { id: 16259, s: "229640.KS", n: "Ls Cable & System Asia", c: "Korea" },
  { id: 16260, s: "PDRX", n: "Pd-Rx Pharmaceutical", c: "US" },
  { id: 16261, s: "BMK.V", n: "MacDonald Mines Exploration", c: "CA" },
  { id: 16262, s: "BITK.V", n: "Blockchaink2 Co", c: "CA" },
  { id: 16263, s: "047400.KS", n: "Union Materials Co", c: "Korea" },
  { id: 16264, s: "3511.TWO", n: "Simula Technology", c: "TW" },
  { id: 16265, s: "3516.TWO", n: "Ado Optronics", c: "TW" },
  { id: 16266, s: "084690.KS", n: "Daesang Holdings", c: "Korea" },
  { id: 16267, s: "084870.KS", n: "TBH Global", c: "Korea" },
  { id: 16268, s: "300477.SZ", n: "Beijing Hezong Science&Technology", c: "CH" },
  { id: 16269, s: "108670.KS", n: "LX Hausys", c: "Korea" },
  { id: 16270, s: "108320.KS", n: "LX Semicon Co..", c: "Korea" },
  { id: 16271, s: "3546.TWO", n: "Userjoy Technology", c: "TW" },
  { id: 16272, s: "102280.KS", n: "SBW Inc", c: "Korea" },
  { id: 16273, s: "SEC.PA", n: "Soditech SA", c: "FR" },
  { id: 16274, s: "092230.KS", n: "Kpx Holdings", c: "Korea" },
  { id: 16275, s: "092220.KS", n: "Kec Corporation", c: "Korea" },
  { id: 16276, s: "VANL.L", n: "Van Elle Holdings PLC", c: "UK" },
  { id: 16277, s: "3531.TWO", n: "Shian Yih Electronic Industry", c: "TW" },
  { id: 16278, s: "TOLWF", n: "Trican Well Service.", c: "US" },
  { id: 16279, s: "090370.KS", n: "MetaLabs", c: "Korea" },
  { id: 16280, s: "300530.SZ", n: "Hunan Lead Power Dazhi Technology Inc", c: "CH" },
  { id: 16281, s: "SRP.PA", n: "SRP Groupe SA", c: "FR" },
  { id: 16282, s: "CMI.V", n: "C-Com Satellite Systems Inc.", c: "CA" },
  { id: 16283, s: "CHY.V", n: "Cypress Hills Resource Co", c: "CA" },
  { id: 16284, s: "3522.TWO", n: "Toplus Global", c: "TW" },
  { id: 16285, s: "CGD.V", n: "Carlin Gold Corporation", c: "CA" },
  { id: 16286, s: "CMIL.V", n: "Capella Minerals", c: "CA" },
  { id: 16287, s: "300311.SZ", n: "Surfilter Network Technology", c: "CH" },
  { id: 16288, s: "TPFG.L", n: "Property Franchise Group PLC", c: "UK" },
  { id: 16289, s: "3322.TWO", n: "Joinsoon Electronics MFG", c: "TW" },
  { id: 16290, s: "BRAU.V", n: "Big Ridge Gold Co", c: "CA" },
  { id: 16291, s: "068400.KS", n: "SK Rent A Car", c: "Korea" },
  { id: 16292, s: "3693.TWO", n: "AIC Inc", c: "TW" },
  { id: 16293, s: "306200.KS", n: "Seah Steel Co", c: "Korea" },
  { id: 16294, s: "298000.KS", n: "Hyosung Chemical Co", c: "Korea" },
  { id: 16295, s: "300333.SZ", n: "Sinosun Tech", c: "CH" },
  { id: 16296, s: "MLCLP.PA", n: "Colipays Reunion SA", c: "FR" },
  { id: 16297, s: "300339.SZ", n: "Jiangsu Hoperun Software", c: "CH" },
  { id: 16298, s: "MLEDR.PA", n: "Compagnie des Eaux de Royan", c: "FR" },
  { id: 16299, s: "280360.KS", n: "Lotte Confectionery", c: "Korea" },
  { id: 16300, s: "MLIML.PA", n: "Imalliance SA", c: "FR" },
  { id: 16301, s: "271980.KS", n: "Jeil Pharmaceutical", c: "Korea" },
  { id: 16302, s: "267290.KS", n: "Kyungdong City Gas", c: "Korea" },
  { id: 16303, s: "4107.TWO", n: "Bioteque", c: "TW" },
  { id: 16304, s: "MLLCB.PA", n: "Les Constructeurs Du Bois SA", c: "FR" },
  { id: 16305, s: "4102.TWO", n: "Yung Zip Chemical Ind", c: "TW" },
  { id: 16306, s: "3685.TWO", n: "Tradetool Auto", c: "TW" },
  { id: 16307, s: "3323.TWO", n: "Celxpert Energy", c: "TW" },
  { id: 16308, s: "3628.TWO", n: "Ablerex Electronics", c: "TW" },
  { id: 16309, s: "300475.SZ", n: "Shannon Semiconductor Technology", c: "CH" },
  { id: 16310, s: "SACI.PA", n: "Fiducial Office Solutions", c: "FR" },
  { id: 16311, s: "300476.SZ", n: "Victory Giant Technology Huizhou", c: "CH" },
  { id: 16312, s: "CBLT.V", n: "CBLT Inc", c: "CA" },
  { id: 16313, s: "300570.SZ", n: "T&S Communications", c: "CH" },
  { id: 16314, s: "079550.KS", n: "LIG Nex1", c: "Korea" },
  { id: 16315, s: "CAPT.V", n: "Capitan Mining Inc", c: "CA" },
  { id: 16316, s: "074610.KS", n: "ENPlus", c: "Korea" },
  { id: 16317, s: "300587.SZ", n: "Zhejiang Tiantie Industry", c: "CH" },
  { id: 16318, s: "300596.SZ", n: "Rianlon Co", c: "CH" },
  { id: 16319, s: "3441.TWO", n: "Unique Optical Industrial", c: "TW" },
  { id: 16320, s: "BANI.BR", n: "Banimmo SA", c: "Belgium" },
  { id: 16321, s: "DIFTY", n: "Daito Trust Construction", c: "US" },
  { id: 16322, s: "001940.KS", n: "Kisco Holdings", c: "Korea" },
  { id: 16323, s: "DIGN.ST", n: "Dignitana AB", c: "Sweden" },
  { id: 16324, s: "C29.AX", n: "C29 Metals", c: "Australia" },
  { id: 16325, s: "NIMU", n: "Non-Invasive Monitoring Systems Inc", c: "US" },
  { id: 16326, s: "B4P.AX", n: "Beforepay Group", c: "Australia" },
  { id: 16327, s: "NICH", n: "Nitches Inc", c: "US" },
  { id: 16328, s: "BBT.AX", n: "Bluebet Holdings", c: "Australia" },
  { id: 16329, s: "EICA", n: "Eagle Point Income Company Inc Preferred Series A", c: "US" },
  { id: 16330, s: "001250.KS", n: "Gs Global", c: "Korea" },
  { id: 16331, s: "001070.KS", n: "Taihan Textile", c: "Korea" },
  { id: 16332, s: "BVR.AX", n: "Bellavista Resources", c: "Australia" },
  { id: 16333, s: "IMP-A-SDB.ST", n: "Implantica AG", c: "Sweden" },
  { id: 16334, s: "LST.L", n: "Light Science Technologies Holdings PLC", c: "UK" },
  { id: 16335, s: "VMSI", n: "Vita Mobile Systems Inc", c: "US" },
  { id: 16336, s: "000080.KS", n: "Hite Jinro", c: "Korea" },
  { id: 16337, s: "INTEG-B.ST", n: "Integrum AB Series B", c: "Sweden" },
  { id: 16338, s: "000040.KS", n: "KR Motors Co..", c: "Korea" },
  { id: 16339, s: "AYA.AX", n: "Artrya", c: "Australia" },
  { id: 16340, s: "ITAB.ST", n: "ITAB Shop Concept AB ser. B", c: "Sweden" },
  { id: 16341, s: "FNNCF", n: "Financial 15 Split Co", c: "US" },
  { id: 16342, s: "FOJCY", n: "Fortum Oyj ADR", c: "US" },
  { id: 16343, s: "CBH.AX", n: "Coolabah Metals", c: "Australia" },
  { id: 16344, s: "298380.KQ", n: "ABL Bio Inc", c: "Korea" },
  { id: 16345, s: "CBO.AX", n: "Cobram Estate Olives", c: "Australia" },
  { id: 16346, s: "LAS.L", n: "London & Associated Properties", c: "UK" },
  { id: 16347, s: "LINC.ST", n: "Linc AB", c: "Sweden" },
  { id: 16348, s: "WHF.AX", n: "Whitefield", c: "Australia" },
  { id: 16349, s: "EXS.ST", n: "Exsitec Holding AB", c: "Sweden" },
  { id: 16350, s: "MDZ.L", n: "MediaZest plc", c: "UK" },
  { id: 16351, s: "HEMC.ST", n: "Hemcheck Sweden AB", c: "Sweden" },
  { id: 16352, s: "079970.KQ", n: "TOBESOFT Co.", c: "Korea" },
  { id: 16353, s: "ENTI", n: "Encounter Technologi", c: "US" },
  { id: 16354, s: "CHECK.ST", n: "Checkin.com Group AB", c: "Sweden" },
  { id: 16355, s: "DIVIO-B.ST", n: "Divio Technologies AB Series B", c: "Sweden" },
  { id: 16356, s: "001380.KS", n: "SG Choongbang", c: "Korea" },
  { id: 16357, s: "DSNO.ST", n: "Desenio Group AB", c: "Sweden" },
  { id: 16358, s: "N4P.L", n: "N4 Pharma PLC", c: "UK" },
  { id: 16359, s: "ESYL", n: "Easylink Solutions C", c: "US" },
  { id: 16360, s: "MUT.L", n: "Murray Income Trust", c: "UK" },
  { id: 16361, s: "AZEV3.SA", n: "Azevedo & Travassos S.A", c: "Brazil" },
  { id: 16362, s: "BIM.AX", n: "Bindi Metals Limited", c: "Australia" },
  { id: 16363, s: "BIO.AX", n: "Biome Australia", c: "Australia" },
  { id: 16364, s: "001510.KS", n: "Sk Securities", c: "Korea" },
  { id: 16365, s: "EUTLF", n: "Eutelsat Communications SA", c: "US" },
  { id: 16366, s: "EMPLI.ST", n: "Emplicure AB", c: "Sweden" },
  { id: 16367, s: "EOLU-B.ST", n: "Eolus Vind AB (publ)", c: "Sweden" },
  { id: 16368, s: "EPTI.ST", n: "Epti AB", c: "Sweden" },
  { id: 16369, s: "MTE.L", n: "Montanaro European Smaller Companies Trust plc", c: "UK" },
  { id: 16370, s: "CPAC-SPAC.ST", n: "Creaspac AB", c: "Sweden" },
  { id: 16371, s: "CSRN3.SA", n: "Companhia Energética do Rio Grande do Norte - COSERN", c: "Brazil" },
  { id: 16372, s: "001270.KS", n: "Bookook Sec", c: "Korea" },
  { id: 16373, s: "002200.KS", n: "Export Packing", c: "Korea" },
  { id: 16374, s: "002240.KS", n: "Kis Wire", c: "Korea" },
  { id: 16375, s: "BOMILL.ST", n: "BoMill AB", c: "Sweden" },
  { id: 16376, s: "003030.KS", n: "Seah Steel Co", c: "Korea" },
  { id: 16377, s: "BULTEN.ST", n: "Bulten AB", c: "Sweden" },
  { id: 16378, s: "EMBR", n: "Embarr Downs Inc", c: "US" },
  { id: 16379, s: "ENDO", n: "Endocan Co", c: "US" },
  { id: 16380, s: "002960.KS", n: "Hankook Shell Oil", c: "Korea" },
  { id: 16381, s: "002920.KS", n: "Yoo Sung Ent", c: "Korea" },
  { id: 16382, s: "CARE.ST", n: "Careium AB", c: "Sweden" },
  { id: 16383, s: "NTBR.L", n: "Northern Bear Plc", c: "UK" },
  { id: 16384, s: "002820.KS", n: "Sun&L", c: "Korea" },
  { id: 16385, s: "METEX.PA", n: "Metabolic Explorer SA", c: "FR" },
  { id: 16386, s: "300384.SZ", n: "Beijing Sanlian Hope Shin-Gosen Technical Service", c: "CH" },
  { id: 16387, s: "IPX.AX", n: "IperionX Limited", c: "Australia" },
  { id: 16388, s: "3558.TWO", n: "Senao Networks", c: "TW" },
  { id: 16389, s: "ARCH.V", n: "Arch Biopartners Inc", c: "CA" },
  { id: 16390, s: "300813.SZ", n: "Zhejiang Tailin Bioengineering ", c: "CH" },
  { id: 16391, s: "019180.KS", n: "THN Corporation", c: "Korea" },
  { id: 16392, s: "APN.V", n: "Altiplano Metals Inc", c: "CA" },
  { id: 16393, s: "2733.TWO", n: "Vigor Kobo", c: "TW" },
  { id: 16394, s: "ANE.V", n: "Altan Nevada Minerals", c: "CA" },
  { id: 16395, s: "2235.TWO", n: "I Yuan Precision Ind", c: "TW" },
  { id: 16396, s: "017960.KS", n: "Hankuk Carbon", c: "Korea" },
  { id: 16397, s: "300821.SZ", n: "Shandong Dongyue Organosilicon Mat", c: "CH" },
  { id: 16398, s: "026960.KS", n: "Dongsuh", c: "Korea" },
  { id: 16399, s: "017390.KS", n: "Seoul City Gas", c: "Korea" },
  { id: 16400, s: "WEHB.BR", n: "Wereldhav B-Sicafi", c: "Belgium" },
  { id: 16401, s: "300741.SZ", n: "Huabao Flavours & Fragrances Co Class A", c: "CH" },
  { id: 16402, s: "INCLU.BR", n: "Inclusio Sca", c: "Belgium" },
  { id: 16403, s: "3234.TWO", n: "TrueLight", c: "TW" },
  { id: 16404, s: "KEYW.BR", n: "Keyware Technologies NV", c: "Belgium" },
  { id: 16405, s: "3230.TWO", n: "Jiin Ming Industry", c: "TW" },
  { id: 16406, s: "035250.KS", n: "Kangwon Land", c: "Korea" },
  { id: 16407, s: "300688.SZ", n: "Dark Horse Beijing Technology", c: "CH" },
  { id: 16408, s: "035000.KS", n: "GIIR Inc", c: "Korea" },
  { id: 16409, s: "034300.KS", n: "Shinsegae Cons", c: "Korea" },
  { id: 16410, s: "034120.KS", n: "Seoul Broadcasting System", c: "Korea" },
  { id: 16411, s: "300706.SZ", n: "Fujian Acetron New Materials", c: "CH" },
  { id: 16412, s: "033530.KS", n: "Sejong Industrial", c: "Korea" },
  { id: 16413, s: "SEQUA.BR", n: "Sequana Medical NV", c: "Belgium" },
  { id: 16414, s: "SOFT.BR", n: "Softimat S.A.", c: "Belgium" },
  { id: 16415, s: "032350.KS", n: "Lotte Tour Dev", c: "Korea" },
  { id: 16416, s: "030720.KS", n: "Dong Won Fish", c: "Korea" },
  { id: 16417, s: "AUU.V", n: "Gold79 Mines", c: "CA" },
  { id: 16418, s: "UAPC", n: "United Amern Pete Cp", c: "US" },
  { id: 16419, s: "ALC.MC", n: "Altia Consultores S.A.", c: "Spain" },
  { id: 16420, s: "300805.SZ", n: "Guangdong Brandmax Marketing", c: "CH" },
  { id: 16421, s: "INL.AX", n: "Innlanz", c: "Australia" },
  { id: 16422, s: "054040.KQ", n: "Korea Computer Inc", c: "Korea" },
  { id: 16423, s: "RELAIS.HE", n: "Relais Group Oyj", c: "Finland" },
  { id: 16424, s: "JCYGY", n: "Jardine Cycle & Carriage ADR", c: "US" },
  { id: 16425, s: "JBTC", n: "Jonestown Bank and Trust Co of Jonestown Pennsylvania", c: "US" },
  { id: 16426, s: "PLY.AX", n: "Playside Studios Ltd", c: "Australia" },
  { id: 16427, s: "IMI.AX", n: "Infinity Mining", c: "Australia" },
  { id: 16428, s: "056360.KQ", n: "Comunication Weaver Co.Ltd", c: "Korea" },
  { id: 16429, s: "PIHLIS.HE", n: "Pihlajalinna Oy", c: "Finland" },
  { id: 16430, s: "JAQCU", n: "Jupiter Acquisition Co", c: "US" },
  { id: 16431, s: "JADA", n: "Jadeart Group Inc", c: "US" },
  { id: 16432, s: "VEN.AX", n: "Vintage Energy", c: "Australia" },
  { id: 16433, s: "PIC.AX", n: "Perpetual Eq Invest", c: "Australia" },
  { id: 16434, s: "054670.KQ", n: "Daehan New Pharm Co.", c: "Korea" },
  { id: 16435, s: "AUTG.L", n: "Autins Group plc", c: "UK" },
  { id: 16436, s: "300803.SZ", n: "Beijing Compass Technology Develop", c: "CH" },
  { id: 16437, s: "054450.KQ", n: "Telechips Inc", c: "Korea" },
  { id: 16438, s: "PHO.AX", n: "Phos", c: "Australia" },
  { id: 16439, s: "IPD.AX", n: "Impedimed", c: "Australia" },
  { id: 16440, s: "AUK.L", n: "Aukett Fitzroy Robinson Group", c: "UK" },
  { id: 16441, s: "REKA.HE", n: "Reka Industrial Oyj", c: "Finland" },
  { id: 16442, s: "PIA.AX", n: "Pengana International Equities", c: "Australia" },
  { id: 16443, s: "VG1.AX", n: "VGI Partners Global Investments", c: "Australia" },
  { id: 16444, s: "264660.KQ", n: "C&G Hi Tech Co.Ltd", c: "Korea" },
  { id: 16445, s: "267980.KQ", n: "Maeil Dairies Co.", c: "Korea" },
  { id: 16446, s: "3114.TWO", n: "Howteh Technology", c: "TW" },
  { id: 16447, s: "024900.KS", n: "Duck Yang Ind", c: "Korea" },
  { id: 16448, s: "3088.TWO", n: "Axiomtek", c: "TW" },
  { id: 16449, s: "ARH.V", n: "Altima Resources", c: "CA" },
  { id: 16450, s: "300733.SZ", n: "Chengdu Xiling Power Science & Technology Incorporated Co Class A", c: "CH" },
  { id: 16451, s: "300748.SZ", n: "Jl Mag Rare-Earth Co Class A", c: "CH" },
  { id: 16452, s: "300671.SZ", n: "Shenzhen Fine Made Electronics Group", c: "CH" },
  { id: 16453, s: "163560.KS", n: "Drb Industrial", c: "Korea" },
  { id: 16454, s: "PCLB", n: "Pinnacle Bancshares Inc", c: "US" },
  { id: 16455, s: "ABI.V", n: "Abcourt Mines Inc.", c: "CA" },
  { id: 16456, s: "301060.SZ", n: "Shanghai Labway Clinical Laboratory", c: "CH" },
  { id: 16457, s: "GIGA.MC", n: "Gigas Hosting S.A", c: "Spain" },
  { id: 16458, s: "COGNY", n: "Cogna Educacao SA ADR", c: "US" },
  { id: 16459, s: "TILT.NE", n: "TILT Holdings Inc", c: "CA" },
  { id: 16460, s: "MYCO.NE", n: "Mydecine Innovations Group Inc", c: "CA" },
  { id: 16461, s: "BEX.V", n: "Benton Resources Inc", c: "CA" },
  { id: 16462, s: "HYL.BR", n: "Hyloris Developmentsen Sa", c: "Belgium" },
  { id: 16463, s: "TRRE", n: "Terra Energy Res", c: "US" },
  { id: 16464, s: "3623.TWO", n: "Transtouch Technology", c: "TW" },
  { id: 16465, s: "185750.KS", n: "Chong Kun Dang Pharmaceutical", c: "Korea" },
  { id: 16466, s: "PAR.PA", n: "Groupe Partouche SA", c: "FR" },
  { id: 16467, s: "161890.KS", n: "Korea Kolmar", c: "Korea" },
  { id: 16468, s: "ADZ.V", n: "Adamera Minerals Corp.", c: "CA" },
  { id: 16469, s: "300427.SZ", n: "Xiamen Red Phase Instruments Inc", c: "CH" },
  { id: 16470, s: "POXEL.PA", n: "Poxel SA", c: "FR" },
  { id: 16471, s: "CWV.V", n: "Crown Point Energy Inc", c: "CA" },
  { id: 16472, s: "300432.SZ", n: "Mianyang Fulin Precision Machining", c: "CH" },
  { id: 16473, s: "CVB.V", n: "Compass Gold Co", c: "CA" },
  { id: 16474, s: "CUI.V", n: "Currie Rose Resources Inc", c: "CA" },
  { id: 16475, s: "300442.SZ", n: "Shanghai Precise Packaging", c: "CH" },
  { id: 16476, s: "300456.SZ", n: "Sai MicroElectronics Inc", c: "CH" },
  { id: 16477, s: "PEGRY", n: "Pennon Group PLC ADR", c: "US" },
  { id: 16478, s: "300464.SZ", n: "Guangdong SACA Precision Manufacturing", c: "CH" },
  { id: 16479, s: "300468.SZ", n: "Shenzhen Forms Syntron Information", c: "CH" },
  { id: 16480, s: "300473.SZ", n: "Fuxin Dare Automotive Parts", c: "CH" },
  { id: 16481, s: "TMRAY", n: "Tomra Systems ASA ADR", c: "US" },
  { id: 16482, s: "300893.SZ", n: "Zhejiang Songyuan Automotive Safety", c: "CH" },
  { id: 16483, s: "014790.KS", n: "Halla Eng&Cons", c: "Korea" },
  { id: 16484, s: "2063.TWO", n: "Sheh Kai Precision", c: "TW" },
  { id: 16485, s: "009835.KS", n: "Hanwha Chemical Corp Pref", c: "Korea" },
  { id: 16486, s: "PBEGF", n: "Touchstone Exploration Inc", c: "US" },
  { id: 16487, s: "CPWY", n: "Clean Energy Pathway", c: "US" },
  { id: 16488, s: "011500.KS", n: "Hannong Chem", c: "Korea" },
  { id: 16489, s: "ZTF.L", n: "Zotefoams PLC", c: "UK" },
  { id: 16490, s: "ZED.L", n: "Zenova Group PLC", c: "UK" },
  { id: 16491, s: "YADV.MC", n: "Advero Properties SOCIMI S.A.", c: "Spain" },
  { id: 16492, s: "YDOA.MC", n: "Inversiones Doalca SOCIMI SA", c: "Spain" },
  { id: 16493, s: "YGOP.MC", n: "Grupo Ortiz Properties Socimi", c: "Spain" },
  { id: 16494, s: "YIPS.MC", n: "Inversa Prime SOCIMI S.A.", c: "Spain" },
  { id: 16495, s: "ACE.AX", n: "Acusensus", c: "Australia" },
  { id: 16496, s: "YMAT.MC", n: "Matritense Real Estate SOCIMI S.A.", c: "Spain" },
  { id: 16497, s: "YMRE.MC", n: "Meridia Real Estate III Socimi SA", c: "Spain" },
  { id: 16498, s: "010040.KS", n: "Korea Refract", c: "Korea" },
  { id: 16499, s: "VLE.L", n: "Volvere PLC", c: "UK" },
  { id: 16500, s: "014820.KS", n: "Dongwon System", c: "Korea" },
  { id: 16501, s: "009420.KS", n: "Hanall Biopharma", c: "Korea" },
  { id: 16502, s: "VAST.L", n: "Vast Resources PLC", c: "UK" },
  { id: 16503, s: "012320.KS", n: "Kyungdong Gas", c: "Korea" },
  { id: 16504, s: "BST.MC", n: "Biotechnology Assets S.A.", c: "Spain" },
  { id: 16505, s: "KOM.MC", n: "Plasticos Compuestos S.A.", c: "Spain" },
  { id: 16506, s: "CPMD", n: "CannaPharmaRx Inc", c: "US" },
  { id: 16507, s: "ALY.V", n: "AnalytixInsight Inc", c: "CA" },
  { id: 16508, s: "300849.SZ", n: "Zhejiang Jinsheng New Materials ", c: "CH" },
  { id: 16509, s: "300850.SZ", n: "Luoyang Xinqianglian Slewing Bearin", c: "CH" },
  { id: 16510, s: "600636.SS", n: "CH Reform Culture Holdings", c: "CH" },
  { id: 16511, s: "015230.KS", n: "Daechang Forgi", c: "Korea" },
  { id: 16512, s: "1784.TWO", n: "Bionet", c: "TW" },
  { id: 16513, s: "1742.TWO", n: "Taiwan Wax", c: "TW" },
  { id: 16514, s: "CDL.L", n: "Cloudbreak Discovery PLC", c: "UK" },
  { id: 16515, s: "600563.SS", n: "Xiamen Faratronic", c: "CH" },
  { id: 16516, s: "GPLS", n: "Geopulse Exploration Inc", c: "US" },
  { id: 16517, s: "600367.SS", n: "Guizhou Redstar Developing", c: "CH" },
  { id: 16518, s: "600306.SS", n: "Shenyang Commercial City", c: "CH" },
  { id: 16519, s: "600305.SS", n: "Jiangsu Hengshun Vinegar-Industry", c: "CH" },
  { id: 16520, s: "600303.SS", n: "Liaoning SG Automotive Group", c: "CH" },
  { id: 16521, s: "8050.TWO", n: "IBASE Technology", c: "TW" },
  { id: 16522, s: "600295.SS", n: "Inner Mongolia ERDOS Resources", c: "CH" },
  { id: 16523, s: "600313.SS", n: "Zhongnongfa Seed Industry Group", c: "CH" },
  { id: 16524, s: "600293.SS", n: "Hubei Sanxia New Building Materials", c: "CH" },
  { id: 16525, s: "600289.SS", n: "Bright Oceans Inter Telecom Co", c: "CH" },
  { id: 16526, s: "600288.SS", n: "Daheng New Epoch Technology Inc", c: "CH" },
  { id: 16527, s: "600287.SS", n: "Jiangsu Sainty Co", c: "CH" },
  { id: 16528, s: "600285.SS", n: "Henan Lingrui Pharmaceutical", c: "CH" },
  { id: 16529, s: "600284.SS", n: "Shanghai Pudong Road & Bridge Construction", c: "CH" },
  { id: 16530, s: "600283.SS", n: "Qian Jiang Water Resources Development", c: "CH" },
  { id: 16531, s: "600282.SS", n: "Nanjing Iron & Steel", c: "CH" },
  { id: 16532, s: "600281.SS", n: "Shanxi Huayang New Material", c: "CH" },
  { id: 16533, s: "600290.SS", n: "Huayi Electric", c: "CH" },
  { id: 16534, s: "600359.SS", n: "Xinjiang Talimu Agriculture Development", c: "CH" },
  { id: 16535, s: "600318.SS", n: "Anhui Xinli Finance", c: "CH" },
  { id: 16536, s: "600321.SS", n: "Rightway Holdings", c: "CH" },
  { id: 16537, s: "600356.SS", n: "Mudanjiang Hengfeng Paper", c: "CH" },
  { id: 16538, s: "600355.SS", n: "Routon Electronic", c: "CH" },
  { id: 16539, s: "600354.SS", n: "Gansu Dunhuang Seed", c: "CH" },
  { id: 16540, s: "600351.SS", n: "Yabao Pharmaceutical Group", c: "CH" },
  { id: 16541, s: "FACC.VI", n: "Facc AG", c: "Austria" },
  { id: 16542, s: "8088.TWO", n: "Panram International", c: "TW" },
  { id: 16543, s: "CGAC", n: "Code Green Apparel Co", c: "US" },
  { id: 16544, s: "OTV.VI", n: "Ottakringer Getränke AG", c: "Austria" },
  { id: 16545, s: "600319.SS", n: "Yaxing Chem", c: "CH" },
  { id: 16546, s: "600336.SS", n: "Aucma", c: "CH" },
  { id: 16547, s: "600307.SS", n: "Gansu Jiu Steel Group Hongxing Iron & Steel", c: "CH" },
  { id: 16548, s: "600308.SS", n: "Shandong Huatai Paper Industry Shareholding", c: "CH" },
  { id: 16549, s: "600310.SS", n: "Guangxi Guidong Eletric Power", c: "CH" },
  { id: 16550, s: "8906.TWO", n: "Forward Graphic Enterprise", c: "TW" },
  { id: 16551, s: "600365.SS", n: "Tonghua Grape Wine", c: "CH" },
  { id: 16552, s: "600363.SS", n: "Jiangxi Lianchuang Opto-electronic Science & Technology", c: "CH" },
  { id: 16553, s: "8109.TWO", n: "P-Duke Technology", c: "TW" },
  { id: 16554, s: "600372.SS", n: "CH Avionics Systems", c: "CH" },
  { id: 16555, s: "600361.SS", n: "Beijing Hualian Hypermarket", c: "CH" },
  { id: 16556, s: "600400.SS", n: "Jiangsu Hongdou Industrial", c: "CH" },
  { id: 16557, s: "600405.SS", n: "Beijing Dynamic Power", c: "CH" },
  { id: 16558, s: "002873.SZ", n: "Guiyang Xintian Pharmaceutical", c: "CH" },
  { id: 16559, s: "600458.SS", n: "Zhuzhou Times New Material Technology", c: "CH" },
  { id: 16560, s: "600456.SS", n: "BaoJi Titanium Industry", c: "CH" },
  { id: 16561, s: "600449.SS", n: "Ningxia Building Materials Group", c: "CH" },
  { id: 16562, s: "600448.SS", n: "Huafang", c: "CH" },
  { id: 16563, s: "600446.SS", n: "Shenzhen Kingdom SCI Tech", c: "CH" },
  { id: 16564, s: "8905.TWO", n: "Eagle Cold Storage Enterprise", c: "TW" },
  { id: 16565, s: "600358.SS", n: "CH United Travel", c: "CH" },
  { id: 16566, s: "600403.SS", n: "Henan Dayou Energy", c: "CH" },
  { id: 16567, s: "600435.SS", n: "North Navigation Control Technology", c: "CH" },
  { id: 16568, s: "600429.SS", n: "Beijing Sanyuan Foods", c: "CH" },
  { id: 16569, s: "600428.SS", n: "COSCO SHIPPING Specialized Carriers", c: "CH" },
  { id: 16570, s: "002879.SZ", n: "Chang Lan Electric Technology", c: "CH" },
  { id: 16571, s: "600425.SS", n: "Xinjiang Qingsong Building Materials and Chemicals Group", c: "CH" },
  { id: 16572, s: "600423.SS", n: "Liuzhou Chemical Industry", c: "CH" },
  { id: 16573, s: "8424.TWO", n: "Wellpool", c: "TW" },
  { id: 16574, s: "600416.SS", n: "Xiangtan Electric Manufacturing", c: "CH" },
  { id: 16575, s: "600408.SS", n: "Shanxi Antai Group", c: "CH" },
  { id: 16576, s: "600433.SS", n: "Guangdong Guanhao High-Tech", c: "CH" },
  { id: 16577, s: "600461.SS", n: "Jiangxi Hongcheng Waterworks", c: "CH" },
  { id: 16578, s: "600360.SS", n: "JiLin Sino-Microelectronics", c: "CH" },
  { id: 16579, s: "600333.SS", n: "Changchun Gas", c: "CH" },
  { id: 16580, s: "600331.SS", n: "Hongda", c: "CH" },
  { id: 16581, s: "600330.SS", n: "TDG Holding", c: "CH" },
  { id: 16582, s: "600603.SS", n: "Guanghui Logistics", c: "CH" },
  { id: 16583, s: "600628.SS", n: "Shanghai New World", c: "CH" },
  { id: 16584, s: "600626.SS", n: "Shanghai Shenda", c: "CH" },
  { id: 16585, s: "600624.SS", n: "Shanghai Fudan Forward S&T", c: "CH" },
  { id: 16586, s: "600623.SS", n: "Shanghai Huayi Group Corp A", c: "CH" },
  { id: 16587, s: "600621.SS", n: "Shanghai CHfortune", c: "CH" },
  { id: 16588, s: "600596.SS", n: "Zhejiang XinAn Chemical Industrial Group", c: "CH" },
  { id: 16589, s: "600619.SS", n: "Shanghai Highly Group Co A", c: "CH" },
  { id: 16590, s: "600615.SS", n: "Shanghai Fenghwa Group", c: "CH" },
  { id: 16591, s: "600613.SS", n: "Shanghai Shenqi Pharmaceutical Investment Management Co A", c: "CH" },
  { id: 16592, s: "600611.SS", n: "Dazhong Transportation Group Co A", c: "CH" },
  { id: 16593, s: "600608.SS", n: "Shanghai Broadband Technology", c: "CH" },
  { id: 16594, s: "600605.SS", n: "Shanghai Huitong Energy", c: "CH" },
  { id: 16595, s: "600604.SS", n: "Shanghai Shibei Hi-Tech Co A", c: "CH" },
  { id: 16596, s: "600602.SS", n: "Inesa Intelligent Tech Inc A", c: "CH" },
  { id: 16597, s: "600630.SS", n: "Shanghai Dragon Co", c: "CH" },
  { id: 16598, s: "600617.SS", n: "Shanxi Guoxin Energy Co A", c: "CH" },
  { id: 16599, s: "600559.SS", n: "Hebei Hengshui Laobaigan Liquor", c: "CH" },
  { id: 16600, s: "600558.SS", n: "Atlantic CH Welding Consumables Inc", c: "CH" },
  { id: 16601, s: "600556.SS", n: "Inmyshow Digital Technology Group", c: "CH" },
  { id: 16602, s: "600497.SS", n: "Yunnan Chihong Zinc&Germanium", c: "CH" },
  { id: 16603, s: "600496.SS", n: "Changjiang & Jinggong Steel Building Group", c: "CH" },
  { id: 16604, s: "600495.SS", n: "Jinxi Axle", c: "CH" },
  { id: 16605, s: "600493.SS", n: "Fujian Fynex Textile Science & Technology", c: "CH" },
  { id: 16606, s: "600491.SS", n: "Long Yuan Construction Group", c: "CH" },
  { id: 16607, s: "HOT.SG", n: "HOCHTIEF Aktiengesellschaft", c: "GER" },
  { id: 16608, s: "002869.SZ", n: "Shenzhen Genvict Technologies", c: "CH" },
  { id: 16609, s: "HRU.SG", n: "Horus AG", c: "GER" },
  { id: 16610, s: "600503.SS", n: "Deluxe Family", c: "CH" },
  { id: 16611, s: "600629.SS", n: "Arcplus Group PLC", c: "CH" },
  { id: 16612, s: "600635.SS", n: "Shanghai DaZhong Public Utilities Group", c: "CH" },
  { id: 16613, s: "600328.SS", n: "Inner Mongolia Lantai Industrial", c: "CH" },
  { id: 16614, s: "600583.SS", n: "Offshore Oil Engineering", c: "CH" },
  { id: 16615, s: "600327.SS", n: "Wuxi Commercial Mansion Grand Orient", c: "CH" },
  { id: 16616, s: "600326.SS", n: "Tibet Tianlu", c: "CH" },
  { id: 16617, s: "UBS.VI", n: "UBM Development AG", c: "Austria" },
  { id: 16618, s: "600323.SS", n: "Grandblue Environment", c: "CH" },
  { id: 16619, s: "600335.SS", n: "Sinomach Automobile", c: "CH" },
  { id: 16620, s: "600463.SS", n: "Beijing Airport High-Tech Park", c: "CH" },
  { id: 16621, s: "600468.SS", n: "Tianjin Benefo Tejing Electric", c: "CH" },
  { id: 16622, s: "600469.SS", n: "Aeolus Tyre", c: "CH" },
  { id: 16623, s: "600590.SS", n: "Tellhow Sci-Tech", c: "CH" },
  { id: 16624, s: "600589.SS", n: "Guangdong Rongtai Industry", c: "CH" },
  { id: 16625, s: "600587.SS", n: "Shinva Medical Instrument", c: "CH" },
  { id: 16626, s: "002852.SZ", n: "Daodaoquan Grain and Oil", c: "CH" },
  { id: 16627, s: "1310.TW", n: "Taiwan Styrene Monomer Co", c: "TW" },
  { id: 16628, s: "600578.SS", n: "Beijing Jingneng Power", c: "CH" },
  { id: 16629, s: "600598.SS", n: "Heilongjiang Agriculture", c: "CH" },
  { id: 16630, s: "600577.SS", n: "Tongling Jingd", c: "CH" },
  { id: 16631, s: "600592.SS", n: "Fujian Longxi Bearing Group", c: "CH" },
  { id: 16632, s: "600576.SS", n: "Zhejiang Whwh", c: "CH" },
  { id: 16633, s: "600573.SS", n: "FuJian YanJing HuiQuan Brewery", c: "CH" },
  { id: 16634, s: "600572.SS", n: "Zhejiang CONBA Pharmaceutical", c: "CH" },
  { id: 16635, s: "600571.SS", n: "Sunyard System Engineering", c: "CH" },
  { id: 16636, s: "WUW.F", n: "Wüstenrot & Württembergische AG", c: "GER" },
  { id: 16637, s: "600569.SS", n: "Anyang Iron & Steel Inc", c: "CH" },
  { id: 16638, s: "600567.SS", n: "Anhui Shanying Paper Industry", c: "CH" },
  { id: 16639, s: "YOU.F", n: "ABOUT YOU Holding AG", c: "GER" },
  { id: 16640, s: "600575.SS", n: "Huaihe Energy Group", c: "CH" },
  { id: 16641, s: "600562.SS", n: "Glarun Technology", c: "CH" },
  { id: 16642, s: "600595.SS", n: "Henan Zhongfu Industrial", c: "CH" },
  { id: 16643, s: "600366.SS", n: "Ningbo Yunsheng", c: "CH" },
  { id: 16644, s: "600368.SS", n: "Guangxi Wuzhou Communications", c: "CH" },
  { id: 16645, s: "600482.SS", n: "CH Shipbuilding Industry Group Power", c: "CH" },
  { id: 16646, s: "600369.SS", n: "Southwest Securities", c: "CH" },
  { id: 16647, s: "BOW.WA", n: "Bowim S.A.", c: "Poland" },
  { id: 16648, s: "4729.TWO", n: "Mildex Optical", c: "TW" },
  { id: 16649, s: "CCE.WA", n: "Clean & Carbon Energy S.A.", c: "Poland" },
  { id: 16650, s: "300167.SZ", n: "Shenzhen Dvision Video Communica", c: "CH" },
  { id: 16651, s: "FII.PA", n: "Lisi S.A", c: "FR" },
  { id: 16652, s: "4432.TWO", n: "Hakers Enterprise", c: "TW" },
  { id: 16653, s: "300229.SZ", n: "Beijing TRS Information Tech", c: "CH" },
  { id: 16654, s: "OPUS.BD", n: "OPUS GLOBAL Nyrt", c: "Hungary" },
  { id: 16655, s: "4161.TWO", n: "Bioptik Technology", c: "TW" },
  { id: 16656, s: "ECR.V", n: "Cartier Resources Inc", c: "CA" },
  { id: 16657, s: "339770.KS", n: "Kyochon F And B", c: "Korea" },
  { id: 16658, s: "300294.SZ", n: "CH Resources Boya Bio pharmaceutical Group", c: "CH" },
  { id: 16659, s: "KOF.PA", n: "Kaufman Et Broad", c: "FR" },
  { id: 16660, s: "EDM.V", n: "EDM Resources Inc", c: "CA" },
  { id: 16661, s: "363280.KS", n: "TY Holdings", c: "Korea" },
  { id: 16662, s: "INFE.PA", n: "Compagnie Industrielle et Financière d'Entreprises SA", c: "FR" },
  { id: 16663, s: "300275.SZ", n: "Chongqing Mas Sci&Tech", c: "CH" },
  { id: 16664, s: "375500.KS", n: "DL E&C", c: "Korea" },
  { id: 16665, s: "4175.TWO", n: "MedFirst Healthcare Services lnc", c: "TW" },
  { id: 16666, s: "300272.SZ", n: "Shanghai Canature Environmental", c: "CH" },
  { id: 16667, s: "300271.SZ", n: "Beijing Thunisoft", c: "CH" },
  { id: 16668, s: "IMDA.PA", n: "Immobiliere Dassault SA", c: "FR" },
  { id: 16669, s: "300269.SZ", n: "Shenzhen Liantronics", c: "CH" },
  { id: 16670, s: "400760.KS", n: "NH All One REIT", c: "Korea" },
  { id: 16671, s: "300256.SZ", n: "Jiangxi Firstar Panel Technology", c: "CH" },
  { id: 16672, s: "ANY.BD", n: "ANY Security Printing PLC", c: "Hungary" },
  { id: 16673, s: "300254.SZ", n: "Shanxi C&Y Pharmaceutical Group", c: "CH" },
  { id: 16674, s: "CIG.BD", n: "CIG Pannonia Life Insurance PLC", c: "Hungary" },
  { id: 16675, s: "4205.TWO", n: "Chung Hwa Food Industrial", c: "TW" },
  { id: 16676, s: "300180.SZ", n: "Huafon Microfibre Shanghai", c: "CH" },
  { id: 16677, s: "300188.SZ", n: "Xiamen Meiya Pico Information", c: "CH" },
  { id: 16678, s: "300190.SZ", n: "Jiangsu WELLE Environmental", c: "CH" },
  { id: 16679, s: "ALTBG.PA", n: "Blockchain Group SA", c: "FR" },
  { id: 16680, s: "001060.KS", n: "Jw Pharmac", c: "Korea" },
  { id: 16681, s: "200992.SZ", n: "Shandong Zhonglu Oceanic Fisheries", c: "CH" },
  { id: 16682, s: "ALTUV.PA", n: "BIO UV Group", c: "FR" },
  { id: 16683, s: "300001.SZ", n: "Qingdao TGOOD Electric", c: "CH" },
  { id: 16684, s: "300002.SZ", n: "Beijing Ultrapower Software", c: "CH" },
  { id: 16685, s: "200539.SZ", n: "Guangdong Electric Power Devel", c: "CH" },
  { id: 16686, s: "5465.TWO", n: "Loyalty Founder Enterprise", c: "TW" },
  { id: 16687, s: "5703.TWO", n: "Landis Taipei Hotel", c: "TW" },
  { id: 16688, s: "200706.SZ", n: "Wafangdian Bearing", c: "CH" },
  { id: 16689, s: "PBF.WA", n: "PBS Finanse SA", c: "Poland" },
  { id: 16690, s: "GRZ.V", n: "Gold Reserve Inc", c: "CA" },
  { id: 16691, s: "5701.TWO", n: "Janfusun Fancyworld", c: "TW" },
  { id: 16692, s: "PPS.WA", n: "Przedsiebiorstwo Przemyslu Spozywczego PEPEES S.A.", c: "Poland" },
  { id: 16693, s: "600137.SS", n: "Sichuan Langsha Holding", c: "CH" },
  { id: 16694, s: "BDX.WA", n: "Budimex S.A.", c: "Poland" },
  { id: 16695, s: "ALVDM.PA", n: "Voyageurs du Monde SA", c: "FR" },
  { id: 16696, s: "PIAIF", n: "Ping An Insurance", c: "US" },
  { id: 16697, s: "4502.TWO", n: "Jian Sin Industrial", c: "TW" },
  { id: 16698, s: "GRVO.PA", n: "Graines Voltz S.A", c: "FR" },
  { id: 16699, s: "300219.SZ", n: "Guangzhou Hongli Opto Electron", c: "CH" },
  { id: 16700, s: "PIFMY", n: "Indofood Sukses Makmur Tbk PT ADR", c: "US" },
  { id: 16701, s: "ENA.V", n: "Enablence Technologies Inc", c: "CA" },
  { id: 16702, s: "300209.SZ", n: "TIZA Information Industry Corporation Inc", c: "CH" },
  { id: 16703, s: "ENEV.V", n: "Enerev5 Metals Inc", c: "CA" },
  { id: 16704, s: "4534.TWO", n: "Trinity Precision Technology", c: "TW" },
  { id: 16705, s: "ACT.WA", n: "Action SA", c: "Poland" },
  { id: 16706, s: "300197.SZ", n: "CECEP Techand Ecology&Environment", c: "CH" },
  { id: 16707, s: "BCM.WA", n: "Betacom S.A.", c: "Poland" },
  { id: 16708, s: "300161.SZ", n: "Wuhan Huazhong Numerical Control", c: "CH" },
  { id: 16709, s: "EXG.V", n: "ExGen Resources Inc", c: "CA" },
  { id: 16710, s: "CRJ.WA", n: "Creepy Jar SA", c: "Poland" },
  { id: 16711, s: "300075.SZ", n: "Beijing eGOVA", c: "CH" },
  { id: 16712, s: "300130.SZ", n: "Xgd Inc", c: "CH" },
  { id: 16713, s: "ENA.WA", n: "Enea S.A.", c: "Poland" },
  { id: 16714, s: "4933.TWO", n: "Ubright Optronics", c: "TW" },
  { id: 16715, s: "300119.SZ", n: "Tianjin Ringpu Bio Tech", c: "CH" },
  { id: 16716, s: "300115.SZ", n: "Shenzhen Everwin Precision Tech", c: "CH" },
  { id: 16717, s: "GIF.WA", n: "Gaming Factory S.A.", c: "Poland" },
  { id: 16718, s: "300112.SZ", n: "Shenzhen Maxonic Auto Control", c: "CH" },
  { id: 16719, s: "300100.SZ", n: "Ningbo Shuanglin Auto Parts", c: "CH" },
  { id: 16720, s: "GA.V", n: "General Assembly Holdings", c: "CA" },
  { id: 16721, s: "300094.SZ", n: "Zhanjiang Guolian Aquatic Products", c: "CH" },
  { id: 16722, s: "5015.TWO", n: "Rodex Fasteners", c: "TW" },
  { id: 16723, s: "IZO.WA", n: "Izolacja Jarocin SA", c: "Poland" },
  { id: 16724, s: "JSW.WA", n: "Jastrzebska Spotka Weglowa SA", c: "Poland" },
  { id: 16725, s: "200054.SZ", n: "Chongqing Jianshe Motorcycle", c: "CH" },
  { id: 16726, s: "300139.SZ", n: "Beijing Fuxing Xiaocheng Electronic Technology", c: "CH" },
  { id: 16727, s: "600103.SS", n: "Fujian Qingshan Paper Industry", c: "CH" },
  { id: 16728, s: "SVTE", n: "Service Team Inc", c: "US" },
  { id: 16729, s: "600397.SS", n: "Anyuan Coal Industry Group", c: "CH" },
  { id: 16730, s: "600396.SS", n: "Shenyang Jinshan Energy", c: "CH" },
  { id: 16731, s: "600391.SS", n: "AECC Aero Science and Technology", c: "CH" },
  { id: 16732, s: "600390.SS", n: "Minmetals Capital", c: "CH" },
  { id: 16733, s: "600382.SS", n: "Guangdong Mingzhu Group", c: "CH" },
  { id: 16734, s: "600381.SS", n: "Qinghai Spring Medicinal Resources Technology", c: "CH" },
  { id: 16735, s: "600380.SS", n: "Joincare Pharmaceutical Group Industry", c: "CH" },
  { id: 16736, s: "600379.SS", n: "Shaanxi Baoguang Vacuum Electronic Apparatus", c: "CH" },
  { id: 16737, s: "600398.SS", n: "HLA GROUP Co", c: "CH" },
  { id: 16738, s: "AMAG.VI", n: "AMAG Austria Metall AG", c: "Austria" },
  { id: 16739, s: "IXI.V", n: "Indigo Exploration Inc.", c: "CA" },
  { id: 16740, s: "300136.SZ", n: "Shenzhen Sunway Communication", c: "CH" },
  { id: 16741, s: "EAH.WA", n: "Esotiq & Henderson S.A.", c: "Poland" },
  { id: 16742, s: "300153.SZ", n: "Shanghai Cooltech Power", c: "CH" },
  { id: 16743, s: "300019.SZ", n: "Chengdu Guibao Science Tech", c: "CH" },
  { id: 16744, s: "TAPM", n: "Tapinator Inc", c: "US" },
  { id: 16745, s: "300058.SZ", n: "BlueFocus Communication Group", c: "CH" },
  { id: 16746, s: "LAB.WA", n: "Labo Print SA", c: "Poland" },
  { id: 16747, s: "300049.SZ", n: "Inner Mongolia Furui Med Sci", c: "CH" },
  { id: 16748, s: "BASS.PA", n: "Bassac", c: "FR" },
  { id: 16749, s: "300040.SZ", n: "Harbin Jiuzhou Electrical", c: "CH" },
  { id: 16750, s: "AVT.PA", n: "Avenir Telecom S.A", c: "FR" },
  { id: 16751, s: "GLDL.V", n: "Gold Line Resources", c: "CA" },
  { id: 16752, s: "MAK.WA", n: "Makarony Polskie S.A.", c: "Poland" },
  { id: 16753, s: "ARTO.PA", n: "Artois Nom.", c: "FR" },
  { id: 16754, s: "MDG.WA", n: "Medicalg", c: "Poland" },
  { id: 16755, s: "300025.SZ", n: "Hangzhou Huaxing Chuangye Communication Technology", c: "CH" },
  { id: 16756, s: "300021.SZ", n: "Gansu Dayu Water saving Group", c: "CH" },
  { id: 16757, s: "MON.WA", n: "Monnari Trade S.A.", c: "Poland" },
  { id: 16758, s: "DVL.WA", n: "Develia S.A", c: "Poland" },
  { id: 16759, s: "300018.SZ", n: "Wuhan Zhongyuan Huadian Sci Tech", c: "CH" },
  { id: 16760, s: "MRC.WA", n: "Mercator Medical S.A.", c: "Poland" },
  { id: 16761, s: "5392.TWO", n: "AVY Precision Technology", c: "TW" },
  { id: 16762, s: "CLCGY", n: "Clicks Group", c: "US" },
  { id: 16763, s: "300013.SZ", n: "Jiangsu Xinning Modern Logist", c: "CH" },
  { id: 16764, s: "NNG.WA", n: "Nanogroup SA", c: "Poland" },
  { id: 16765, s: "KPD.WA", n: "Koszalinskie Przedsiebiorstwo Przemyslu Drzewnego SA", c: "Poland" },
  { id: 16766, s: "NVT.WA", n: "NOVITA SA", c: "Poland" },
  { id: 16767, s: "GER.V", n: "Glen Eagle Resources Inc", c: "CA" },
  { id: 16768, s: "KCH.WA", n: "Krakchemia SA", c: "Poland" },
  { id: 16769, s: "ELEC.PA", n: "Électricite de Strasbourg Société Anonyme", c: "FR" },
  { id: 16770, s: "300145.SZ", n: "Nanfang Pump Industry", c: "CH" },
  { id: 16771, s: "EGR.PA", n: "Transition Evergreen", c: "FR" },
  { id: 16772, s: "600486.SS", n: "Jiangsu Yangnong Chemical", c: "CH" },
  { id: 16773, s: "600481.SS", n: "Shuangliang Eco-Energy Systems", c: "CH" },
  { id: 16774, s: "080520.KQ", n: "ODTech Co.", c: "Korea" },
  { id: 16775, s: "600225.SS", n: "Tianjin Songjiang", c: "CH" },
  { id: 16776, s: "6643.TWO", n: "M31 Technology Co", c: "TW" },
  { id: 16777, s: "600203.SS", n: "Fujian Furi Electronics", c: "CH" },
  { id: 16778, s: "600202.SS", n: "Harbin Air Conditioning", c: "CH" },
  { id: 16779, s: "600163.SS", n: "Zhongmin Energy", c: "CH" },
  { id: 16780, s: "600160.SS", n: "Zhejiang Juhua", c: "CH" },
  { id: 16781, s: "600159.SS", n: "Beijing Dalong Weiye Real Estate Development", c: "CH" },
  { id: 16782, s: "600158.SS", n: "CH Sports Industry Group", c: "CH" },
  { id: 16783, s: "600157.SS", n: "Wintime Energy", c: "CH" },
  { id: 16784, s: "600156.SS", n: "Hunan Huasheng", c: "CH" },
  { id: 16785, s: "600152.SS", n: "Veken Elite", c: "CH" },
  { id: 16786, s: "600151.SS", n: "Aerospace Auto", c: "CH" },
  { id: 16787, s: "CGSI", n: "CGS International Inc", c: "US" },
  { id: 16788, s: "ALBLU.PA", n: "Bluelinea SA", c: "FR" },
  { id: 16789, s: "ALCHI.PA", n: "Alchimie Sa", c: "FR" },
  { id: 16790, s: "600135.SS", n: "Lucky Film", c: "CH" },
  { id: 16791, s: "ALCOF.PA", n: "Cofidur SA", c: "FR" },
  { id: 16792, s: "600125.SS", n: "CH Railway Tielong Container Logistics", c: "CH" },
  { id: 16793, s: "600123.SS", n: "Shanxi Lanhua Sci-Tech Venture", c: "CH" },
  { id: 16794, s: "600121.SS", n: "Zhengzhou Coal Industry & Electric Power", c: "CH" },
  { id: 16795, s: "002918.SZ", n: "Monalisa Group Co Class A", c: "CH" },
  { id: 16796, s: "600119.SS", n: "Y.U.D. Yangtze River Investment Industry", c: "CH" },
  { id: 16797, s: "600148.SS", n: "Changchun Yidong Clutch", c: "CH" },
  { id: 16798, s: "ALBFR.PA", n: "Sidetrade", c: "FR" },
  { id: 16799, s: "600168.SS", n: "Wuhan Sanzhen Industry Holding", c: "CH" },
  { id: 16800, s: "ALBDM.PA", n: "Bd Multimedia", c: "FR" },
  { id: 16801, s: "600201.SS", n: "Jinyu Bio-Technology", c: "CH" },
  { id: 16802, s: "600200.SS", n: "Jiangsu Wuzhong Industrial", c: "CH" },
  { id: 16803, s: "600199.SS", n: "Anhui Golden Seed Winery", c: "CH" },
  { id: 16804, s: "600198.SS", n: "Datang Telecom Technology", c: "CH" },
  { id: 16805, s: "ACAN.PA", n: "Acanthe Développement", c: "FR" },
  { id: 16806, s: "600243.SS", n: "Qinghaihuading Industrial", c: "CH" },
  { id: 16807, s: "ADUX.PA", n: "AdUX SA", c: "FR" },
  { id: 16808, s: "600271.SS", n: "Aisino Co", c: "CH" },
  { id: 16809, s: "6761.TWO", n: "Wendell Industrial Co.", c: "TW" },
  { id: 16810, s: "600220.SS", n: "Jiangsu Sunshine", c: "CH" },
  { id: 16811, s: "600219.SS", n: "Shandong Nanshan Aluminium", c: "CH" },
  { id: 16812, s: "600217.SS", n: "CH Resources and Environment", c: "CH" },
  { id: 16813, s: "600216.SS", n: "Zhejiang Medicine", c: "CH" },
  { id: 16814, s: "600215.SS", n: "Paslin Digital Technology", c: "CH" },
  { id: 16815, s: "HMLO.V", n: "Hemlo Explorers Inc", c: "CA" },
  { id: 16816, s: "600212.SS", n: "Gresgying Digital Energy Technology", c: "CH" },
  { id: 16817, s: "600210.SS", n: "Shanghai Zijiang Enterprise Group", c: "CH" },
  { id: 16818, s: "600222.SS", n: "Henan Taloph Pharmaceutical Stock", c: "CH" },
  { id: 16819, s: "600207.SS", n: "Henan Ancai Hi-tech", c: "CH" },
  { id: 16820, s: "600237.SS", n: "Anhui Tongfeng Electronics", c: "CH" },
  { id: 16821, s: "600241.SS", n: "Liaoning Shidai Wanheng", c: "CH" },
  { id: 16822, s: "600269.SS", n: "Jiangxi Ganyue Expressway", c: "CH" },
  { id: 16823, s: "002895.SZ", n: "Guizhou Chanhen Chemical Co", c: "CH" },
  { id: 16824, s: "600268.SS", n: "Guodian Nanjing Automation", c: "CH" },
  { id: 16825, s: "600267.SS", n: "Zhejiang Hisun Pharmaceutical", c: "CH" },
  { id: 16826, s: "600266.SS", n: "Beijing Urban Construction Investment & Development", c: "CH" },
  { id: 16827, s: "600265.SS", n: "Yunnan Jinggu Forestry", c: "CH" },
  { id: 16828, s: "600262.SS", n: "Inner Mongolia North Hauler Joint Stock", c: "CH" },
  { id: 16829, s: "600259.SS", n: "Rising Nonferrous Metals Share", c: "CH" },
  { id: 16830, s: "600238.SS", n: "Hainan Yedao Group", c: "CH" },
  { id: 16831, s: "ABNX.PA", n: "Abionyx Pharma SA", c: "FR" },
  { id: 16832, s: "600255.SS", n: "Xinke Material", c: "CH" },
  { id: 16833, s: "600252.SS", n: "Guangxi Wuzhou Zhongheng Group", c: "CH" },
  { id: 16834, s: "600251.SS", n: "Xinjiang Guannong Fruit & Antler Group", c: "CH" },
  { id: 16835, s: "600250.SS", n: "Nanjing Textiles Import & Export Co", c: "CH" },
  { id: 16836, s: "600249.SS", n: "Liuzhou Liangmianzhen", c: "CH" },
  { id: 16837, s: "600197.SS", n: "Xinjiang Yilite Industry", c: "CH" },
  { id: 16838, s: "600195.SS", n: "CH Animal Husbandry Industry", c: "CH" },
  { id: 16839, s: "ALAGR.PA", n: "Agrogeneration", c: "FR" },
  { id: 16840, s: "SEQ.AX", n: "Sequoia Financial Group", c: "Australia" },
  { id: 16841, s: "094860.KQ", n: "NEORIGIN Co.", c: "Korea" },
  { id: 16842, s: "KSRYY", n: "Kose Corp ADR", c: "US" },
  { id: 16843, s: "095190.KQ", n: "ENERGY&MACHINERY KOREA Co.Ltd", c: "Korea" },
  { id: 16844, s: "095700.KQ", n: "Genexine Inc", c: "Korea" },
  { id: 16845, s: "EQN.AX", n: "Equinox Resources Limited", c: "Australia" },
  { id: 16846, s: "FGT.L", n: "Finsbury Growth & Income Trust", c: "UK" },
  { id: 16847, s: "SHG.AX", n: "Singular Health Group", c: "Australia" },
  { id: 16848, s: "FEN.L", n: "Frenkel Topping Group", c: "UK" },
  { id: 16849, s: "SHA.AX", n: "SHAPE Australia Co", c: "Australia" },
  { id: 16850, s: "EV1.AX", n: "Evolution Energy Minerals", c: "Australia" },
  { id: 16851, s: "EVG.AX", n: "Evion Group NL", c: "Australia" },
  { id: 16852, s: "EST.L", n: "East Star Resources PLC", c: "UK" },
  { id: 16853, s: "ROV.", n: "Rovsing A/S", c: "Denmark" },
  { id: 16854, s: "SDI.AX", n: "SDI", c: "Australia" },
  { id: 16855, s: "090460.KQ", n: "BH Co.", c: "Korea" },
  { id: 16856, s: "099320.KQ", n: "Satrec Initiative Co.", c: "Korea" },
  { id: 16857, s: "EISB.L", n: "East Imperial PLC", c: "UK" },
  { id: 16858, s: "ACR.OL", n: "Axactor SE", c: "Norway" },
  { id: 16859, s: "EAAS.L", n: "Eenergy Group PLC", c: "UK" },
  { id: 16860, s: "GJS", n: "STRATSSM Certificates series supplement 2006-2 Trust IC", c: "US" },
  { id: 16861, s: "GJT", n: "STRATS SM Trust for Allstate Corp Securities Series 2006-3 IC", c: "US" },
  { id: 16862, s: "095500.KQ", n: "MNtech Co.", c: "Korea" },
  { id: 16863, s: "115480.KQ", n: "CU Medical Systems Inc", c: "Korea" },
  { id: 16864, s: "089890.KQ", n: "KOSES Co.Ltd", c: "Korea" },
  { id: 16865, s: "GEM.OL", n: "Green Minerals AS", c: "Norway" },
  { id: 16866, s: "CLX.L", n: "Calnex Solutions Plc", c: "UK" },
  { id: 16867, s: "081580.KQ", n: "Sungwoo Electronics Co.", c: "Korea" },
  { id: 16868, s: "NORTH.OL", n: "North Energy ASA", c: "Norway" },
  { id: 16869, s: "CTUK.L", n: "CT UK Capital And Income Investment Trust Plc", c: "UK" },
  { id: 16870, s: "090470.KQ", n: "JASTECH", c: "Korea" },
  { id: 16871, s: "600191.SS", n: "Baotou Huazi Industry", c: "CH" },
  { id: 16872, s: "300855.SZ", n: "Jiangsu Toland Alloy ", c: "CH" },
  { id: 16873, s: "600190.SS", n: "Jinzhou Port Co A", c: "CH" },
  { id: 16874, s: "600189.SS", n: "Jilin Forest", c: "CH" },
  { id: 16875, s: "600186.SS", n: "Lotus Health Group", c: "CH" },
  { id: 16876, s: "ALAMG.PA", n: "Auplata SA", c: "FR" },
  { id: 16877, s: "600183.SS", n: "Shengyi Technology", c: "CH" },
  { id: 16878, s: "600182.SS", n: "Giti Tire Co", c: "CH" },
  { id: 16879, s: "600180.SS", n: "CCS Supply Chain Management", c: "CH" },
  { id: 16880, s: "600179.SS", n: "Antong Holdings", c: "CH" },
  { id: 16881, s: "ALAQU.PA", n: "Aquila SA", c: "FR" },
  { id: 16882, s: "ALARF.PA", n: "Adeunis", c: "FR" },
  { id: 16883, s: "600171.SS", n: "Sh Belling", c: "CH" },
  { id: 16884, s: "ALDAR.PA", n: "Damartex", c: "FR" },
  { id: 16885, s: "334890.KS", n: "Igis Value Plus Reit ", c: "Korea" },
  { id: 16886, s: "322000.KS", n: "Hyundai Energy Solutions", c: "Korea" },
  { id: 16887, s: "CYAN.L", n: "Cyanconnode Holdings PLC", c: "UK" },
  { id: 16888, s: "DORE.L", n: "Downing Renewables & Infrastructure Trust PLC", c: "UK" },
  { id: 16889, s: "094480.KQ", n: "GalaxiaMoneytree Co.Ltd", c: "Korea" },
  { id: 16890, s: "AYFIE.OL", n: "Ayfie Group AS", c: "Norway" },
  { id: 16891, s: "S2R.AX", n: "S2 Resources", c: "Australia" },
  { id: 16892, s: "093380.KQ", n: "Pungkang Co.", c: "Korea" },
  { id: 16893, s: "RXM.AX", n: "Rex Minerals", c: "Australia" },
  { id: 16894, s: "092730.KQ", n: "NeoPharm CO.", c: "Korea" },
  { id: 16895, s: "FRE.AX", n: "Firebrick Pharma", c: "Australia" },
  { id: 16896, s: "RUL.AX", n: "RPMGlobal Holdings", c: "Australia" },
  { id: 16897, s: "RTH.AX", n: "Ras Technology Holdings", c: "Australia" },
  { id: 16898, s: "FTL.AX", n: "Firetail Resources", c: "Australia" },
  { id: 16899, s: "EAM.OL", n: "EAM Solar ASA", c: "Norway" },
  { id: 16900, s: "091120.KQ", n: "EM-Tech.CO.", c: "Korea" },
  { id: 16901, s: "600235.SS", n: "Minfeng Special Paper", c: "CH" },
  { id: 16902, s: "600226.SS", n: "Zhejiang Shenghua Biok Biology", c: "CH" },
  { id: 16903, s: "600480.SS", n: "Lingyun Ind", c: "CH" },
  { id: 16904, s: "600227.SS", n: "Guizhou Chitianhua", c: "CH" },
  { id: 16905, s: "STOHF", n: "Equinor ASA", c: "US" },
  { id: 16906, s: "600063.SS", n: "Anhui Wanwei Updated High-tech Material Industry", c: "CH" },
  { id: 16907, s: "600062.SS", n: "CH Resources Double-Crane Pharmaceutical", c: "CH" },
  { id: 16908, s: "ALENT.PA", n: "Entreparticuli", c: "FR" },
  { id: 16909, s: "600059.SS", n: "Zhejiang Guyuelongshan Shaoxing Wine", c: "CH" },
  { id: 16910, s: "600058.SS", n: "Minmetals Development", c: "CH" },
  { id: 16911, s: "ALEO2.PA", n: "Eo2 Société Anonyme", c: "FR" },
  { id: 16912, s: "600071.SS", n: "Phenix Optical", c: "CH" },
  { id: 16913, s: "600056.SS", n: "CH Meheco", c: "CH" },
  { id: 16914, s: "600054.SS", n: "Huangshan Tourism Development Co A", c: "CH" },
  { id: 16915, s: "600053.SS", n: "Kunwu Jiuding Investment Holdings", c: "CH" },
  { id: 16916, s: "600051.SS", n: "Ningbo United Group", c: "CH" },
  { id: 16917, s: "ALERS.PA", n: "Eurobio Scientific SA", c: "FR" },
  { id: 16918, s: "ALESA.PA", n: "Ecoslops SA", c: "FR" },
  { id: 16919, s: "6292.TWO", n: "Axis Corporation", c: "TW" },
  { id: 16920, s: "600035.SS", n: "Hubei Chutian Expressway", c: "CH" },
  { id: 16921, s: "002932.SZ", n: "Wuhan Easy Diagnosis Biomedicine Co Class A", c: "CH" },
  { id: 16922, s: "600055.SS", n: "Beijing Wandong Medical Technology", c: "CH" },
  { id: 16923, s: "ALGBE.PA", n: "Glob Bioenergi", c: "FR" },
  { id: 16924, s: "600075.SS", n: "Xinjiang Tianye", c: "CH" },
  { id: 16925, s: "600080.SS", n: "Ginwa Enterprise Group Inc", c: "CH" },
  { id: 16926, s: "600113.SS", n: "Zhe Jiang Dong Ri", c: "CH" },
  { id: 16927, s: "600112.SS", n: "Guizhou Changzheng Tiancheng Holding", c: "CH" },
  { id: 16928, s: "ALDBT.PA", n: "DBT SA", c: "FR" },
  { id: 16929, s: "600108.SS", n: "Gansu Yasheng Industrial Group", c: "CH" },
  { id: 16930, s: "600107.SS", n: "Hubei Mailyard Share", c: "CH" },
  { id: 16931, s: "600106.SS", n: "Chongqing Road & Bridge", c: "CH" },
  { id: 16932, s: "PFBN", n: "Pacific Alliance Bank", c: "US" },
  { id: 16933, s: "600101.SS", n: "Sichuan Mingxing Electric Power", c: "CH" },
  { id: 16934, s: "6425.TWO", n: "Easy Field", c: "TW" },
  { id: 16935, s: "600272.SS", n: "Shanghai Kai Kai Industry Co A", c: "CH" },
  { id: 16936, s: "600273.SS", n: "Zhejiang Jiahua Energy Chemical Industry", c: "CH" },
  { id: 16937, s: "600539.SS", n: "Taiyuan Lionhead Cement", c: "CH" },
  { id: 16938, s: "600479.SS", n: "ZhuZhou QianJin Pharmaceutical", c: "CH" },
  { id: 16939, s: "600478.SS", n: "Hunan Corun New Energy", c: "CH" },
  { id: 16940, s: "600477.SS", n: "Hang Xiao Steel Structure", c: "CH" },
  { id: 16941, s: "600476.SS", n: "Hunan Copote Science Technology", c: "CH" },
  { id: 16942, s: "600470.SS", n: "Anhui Liuguo Chemical", c: "CH" },
  { id: 16943, s: "600483.SS", n: "Fujian Funeng", c: "CH" },
  { id: 16944, s: "600505.SS", n: "Sichuan Xichang Electric Power", c: "CH" },
  { id: 16945, s: "IZ.V", n: "International Zeolite Co", c: "CA" },
  { id: 16946, s: "600510.SS", n: "Black Peony Group", c: "CH" },
  { id: 16947, s: "600552.SS", n: "Triumph Science & Technology", c: "CH" },
  { id: 16948, s: "600551.SS", n: "Time Publishing and Media Co Class A", c: "CH" },
  { id: 16949, s: "600546.SS", n: "Shanxi Coal International Energy Group", c: "CH" },
  { id: 16950, s: "600543.SS", n: "Gansu Mogao Industrial Development", c: "CH" },
  { id: 16951, s: "600538.SS", n: "Beihai Gofar Marine Biological Industry", c: "CH" },
  { id: 16952, s: "ABEO.PA", n: "Abeo SAS", c: "FR" },
  { id: 16953, s: "600533.SS", n: "Nanjing Chixia Development", c: "CH" },
  { id: 16954, s: "600529.SS", n: "Shandong Pharmaceutical Glass", c: "CH" },
  { id: 16955, s: "600528.SS", n: "CH Railway Hi-tech Industry Co", c: "CH" },
  { id: 16956, s: "600527.SS", n: "Jiangsu Jiangnan High Polymer Fiber", c: "CH" },
  { id: 16957, s: "600525.SS", n: "Changyuan Group", c: "CH" },
  { id: 16958, s: "1203.TW", n: "Ve Wong Co", c: "TW" },
  { id: 16959, s: "600522.SS", n: "Jiangsu Zhongtian Technology", c: "CH" },
  { id: 16960, s: "600520.SS", n: "WenYi Trinity Technology", c: "CH" },
  { id: 16961, s: "9962.TWO", n: "Yeou Yih Steel", c: "TW" },
  { id: 16962, s: "600515.SS", n: "Hainan HNA Infrastructure Investment Group", c: "CH" },
  { id: 16963, s: "600512.SS", n: "Tengda Construction Group", c: "CH" },
  { id: 16964, s: "002868.SZ", n: "Lifecome Biochemistry", c: "CH" },
  { id: 16965, s: "600279.SS", n: "Chongqing Gangjiu", c: "CH" },
  { id: 16966, s: "ALECO.PA", n: "Ecomiam SA", c: "FR" },
  { id: 16967, s: "600099.SS", n: "Linhai", c: "CH" },
  { id: 16968, s: "600097.SS", n: "Shanghai Kaichuang Marine International", c: "CH" },
  { id: 16969, s: "VRC.WA", n: "Vercom SA", c: "Poland" },
  { id: 16970, s: "6246.TWO", n: "Taiwan Thick-Film Ind", c: "TW" },
  { id: 16971, s: "ALHRG.PA", n: "Herige SA", c: "FR" },
  { id: 16972, s: "600012.SS", n: "Anhui Expressway", c: "CH" },
  { id: 16973, s: "ALHYP.PA", n: "Hipay Group SA", c: "FR" },
  { id: 16974, s: "6207.TWO", n: "Laser Tek Taiwan", c: "TW" },
  { id: 16975, s: "ZMT.WA", n: "Zamet Industry SA", c: "Poland" },
  { id: 16976, s: "ZEP.WA", n: "Zespol Elektrowni Patnow Adamow Konin SA", c: "Poland" },
  { id: 16977, s: "XTP.WA", n: "XTPL SA", c: "Poland" },
  { id: 16978, s: "002957.SZ", n: "Shenzhen Colibri Technologies", c: "CH" },
  { id: 16979, s: "ALLEC.PA", n: "Cogelec SA", c: "FR" },
  { id: 16980, s: "ALLHB.PA", n: "Les Hotels Bav", c: "FR" },
  { id: 16981, s: "ALLIX.PA", n: "Wallix Group SA", c: "FR" },
  { id: 16982, s: "SVAD", n: "Silverton Adventures", c: "US" },
  { id: 16983, s: "SVLKF", n: "Silver Lake Resources", c: "US" },
  { id: 16984, s: "6150.TWO", n: "TUL Corporation", c: "TW" },
  { id: 16985, s: "002977.SZ", n: "Chengdu Tianjian Technology", c: "CH" },
  { id: 16986, s: "ALMIB.PA", n: "Amoeba SA", c: "FR" },
  { id: 16987, s: "002988.SZ", n: "Guangdong Haomei New Material Co Lt", c: "CH" },
  { id: 16988, s: "6154.TWO", n: "Sunfar Computer", c: "TW" },
  { id: 16989, s: "002921.SZ", n: "Shandong Liancheng Precision Manufacturing Co Class A", c: "CH" },
  { id: 16990, s: "200030.SZ", n: "FAWER Automotive Parts", c: "CH" },
  { id: 16991, s: "ALDBL.PA", n: "Bernard Loisea", c: "FR" },
  { id: 16992, s: "600117.SS", n: "Xining Special Steel", c: "CH" },
  { id: 16993, s: "002903.SZ", n: "Yuhuan CNC Machine Tool", c: "CH" },
  { id: 16994, s: "600232.SS", n: "Zhejiang Golden Eagle", c: "CH" },
  { id: 16995, s: "600231.SS", n: "Lingyuan Iron & Steel", c: "CH" },
  { id: 16996, s: "600229.SS", n: "Qingdao Citymedia", c: "CH" },
  { id: 16997, s: "AELIS.PA", n: "Aelis Farma SA", c: "FR" },
  { id: 16998, s: "TIM.WA", n: "Tim S.A.", c: "Poland" },
  { id: 16999, s: "ALNRG.PA", n: "Energisme", c: "FR" },
  { id: 17000, s: "CHEOY", n: "CochLear ADR", c: "US" },
  { id: 17001, s: "ALORD.PA", n: "Ordissimo", c: "FR" },
  { id: 17002, s: "600095.SS", n: "Xiangcai", c: "CH" },
  { id: 17003, s: "600088.SS", n: "CH Television Media", c: "CH" },
  { id: 17004, s: "600084.SS", n: "Citic Guoan Wine", c: "CH" },
  { id: 17005, s: "600083.SS", n: "Jiangsu Boxin Investing & Holdings", c: "CH" },
  { id: 17006, s: "600082.SS", n: "Tianjin Hi-Tech Development", c: "CH" },
  { id: 17007, s: "600081.SS", n: "Dongfeng Electronic Technology", c: "CH" },
  { id: 17008, s: "600098.SS", n: "Guangzhou Development Group Inc", c: "CH" },
  { id: 17009, s: "002933.SZ", n: "Beijing Emerging Eastern Aviation Equipment Co Class A", c: "CH" },
  { id: 17010, s: "6272.TWO", n: "Wieson Technologies", c: "TW" },
  { id: 17011, s: "6259.TWO", n: "Bull Will", c: "TW" },
  { id: 17012, s: "003006.SZ", n: "Chongqing Baiya Sanitary Products", c: "CH" },
  { id: 17013, s: "003010.SZ", n: "Guangzhou Ruoyuchen Information Tec", c: "CH" },
  { id: 17014, s: "SNK.WA", n: "Stomil Sanok SA", c: "Poland" },
  { id: 17015, s: "003019.SZ", n: "Tes Touch Embedded Solutions", c: "CH" },
  { id: 17016, s: "003023.SZ", n: "Chengdu Rainbow Appliance Gr", c: "CH" },
  { id: 17017, s: "SHO.WA", n: "Shoper S.A.", c: "Poland" },
  { id: 17018, s: "003021.SZ", n: "Henzhen Zhaowei Machinery And Elect", c: "CH" },
  { id: 17019, s: "ALPCV.PA", n: "Cerinnov Group SA", c: "FR" },
  { id: 17020, s: "002993.SZ", n: "Dongguan Aohai Technology ", c: "CH" },
  { id: 17021, s: "ALPJT.PA", n: "Poujoulat S.A.", c: "FR" },
  { id: 17022, s: "ALPLA.PA", n: "Erold SA", c: "FR" },
  { id: 17023, s: "RVU.WA", n: "Ryvu Therapeutics SA", c: "Poland" },
  { id: 17024, s: "6109.TWO", n: "Atech OEM", c: "TW" },
  { id: 17025, s: "003027.SZ", n: "Tongxing Environmental Protection", c: "CH" },
  { id: 17026, s: "ALQGC.PA", n: "Quantum Genomics SA", c: "FR" },
  { id: 17027, s: "003039.SZ", n: "Guangdong Shunkong Development", c: "CH" },
  { id: 17028, s: "003042.SZ", n: "Shandong Sino-Agri United Biotechnology", c: "CH" },
  { id: 17029, s: "ALREA.PA", n: "Realites", c: "FR" },
  { id: 17030, s: "BMR.AX", n: "Ballymore Resources", c: "Australia" },
  { id: 17031, s: "017370.KS", n: "Wooshin System", c: "Korea" },
  { id: 17032, s: "FBCD", n: "Fbc Hldg Inc", c: "US" },
  { id: 17033, s: "603786.SS", n: "Keboda Technology", c: "CH" },
  { id: 17034, s: "601139.SS", n: "Shenzhen Gas Co", c: "CH" },
  { id: 17035, s: "VVC.V", n: "VVC Exploration Corp.", c: "CA" },
  { id: 17036, s: "600883.SS", n: "Yunnan Bowin Technology Industry", c: "CH" },
  { id: 17037, s: "600882.SS", n: "Shanghai Milkground Food Tech", c: "CH" },
  { id: 17038, s: "ISGSY.IS", n: "Is Girisim Sermayesi Yatirim Ortakligi AS", c: "Turkey" },
  { id: 17039, s: "600707.SS", n: "Caihong Display Devices", c: "CH" },
  { id: 17040, s: "600785.SS", n: "Yinchuan Xinhua Commercial Group", c: "CH" },
  { id: 17041, s: "600708.SS", n: "Bright Real Estate Group", c: "CH" },
  { id: 17042, s: "KARTN.IS", n: "Kartonsan Karton Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 17043, s: "601069.SS", n: "Western Gold", c: "CH" },
  { id: 17044, s: "TEM.V", n: "Tembo Gold Co", c: "CA" },
  { id: 17045, s: "600895.SS", n: "Shanghai Zhangjiang Hi-Tech Park Development", c: "CH" },
  { id: 17046, s: "600780.SS", n: "Top Energy Shanxi", c: "CH" },
  { id: 17047, s: "600894.SS", n: "Guangzhou Guangri Stock", c: "CH" },
  { id: 17048, s: "601086.SS", n: "Gansu Guofang Gongmao(Grp)", c: "CH" },
  { id: 17049, s: "600712.SS", n: "Nanning Department Store", c: "CH" },
  { id: 17050, s: "TUCLK.IS", n: "Tugcelik Aluminyum ve Metal", c: "Turkey" },
  { id: 17051, s: "PU11.F", n: "The Social Chain AG", c: "GER" },
  { id: 17052, s: "TD-PFL.TO", n: "Toronto-Dominion Bank Pref Series 22", c: "CA" },
  { id: 17053, s: "601098.SS", n: "CH South Publishing & Media Group", c: "CH" },
  { id: 17054, s: "1906.TW", n: "Baolong International", c: "TW" },
  { id: 17055, s: "600889.SS", n: "Nanjing Chemical Fibre", c: "CH" },
  { id: 17056, s: "TURSG.IS", n: "Turkiye Sigorta AS", c: "Turkey" },
  { id: 17057, s: "VTT.V", n: "Vendetta Mining Co", c: "CA" },
  { id: 17058, s: "601101.SS", n: "Beijing Haohua Energy Resource", c: "CH" },
  { id: 17059, s: "TBX.V", n: "Turmalina Metals Co", c: "CA" },
  { id: 17060, s: "601106.SS", n: "CH First Heavy Industries", c: "CH" },
  { id: 17061, s: "600784.SS", n: "Luyin Investment Group", c: "CH" },
  { id: 17062, s: "NA-PE.TO", n: "National Bank of CA Pref Series 40", c: "CA" },
  { id: 17063, s: "600885.SS", n: "Hongfa Technology", c: "CH" },
  { id: 17064, s: "002775.SZ", n: "Shenzhen Wenke Landscape", c: "CH" },
  { id: 17065, s: "601137.SS", n: "Ningbo Boway Alloy Material", c: "CH" },
  { id: 17066, s: "603803.SS", n: "Raisecom Technology", c: "CH" },
  { id: 17067, s: "603815.SS", n: "Anhui Gourgen Traffic Construction", c: "CH" },
  { id: 17068, s: "603813.SS", n: "GuangDong GenSho Logistics", c: "CH" },
  { id: 17069, s: "ABO.DU", n: "Clearwise AG", c: "GER" },
  { id: 17070, s: "603810.SS", n: "Jiangsu Fengshan Group", c: "CH" },
  { id: 17071, s: "603809.SS", n: "Chengdu Haoneng Tech", c: "CH" },
  { id: 17072, s: "603778.SS", n: "Beijing Qianjing Landscape", c: "CH" },
  { id: 17073, s: "603808.SS", n: "Shenzhen Ellassay Fashion", c: "CH" },
  { id: 17074, s: "603801.SS", n: "Zbom Cabinets", c: "CH" },
  { id: 17075, s: "PUODY", n: "Promotora y Operadora de Infraestructura SAB de CV ADR", c: "US" },
  { id: 17076, s: "603797.SS", n: "Guangdong Liantai Environmental Protection", c: "CH" },
  { id: 17077, s: "603789.SS", n: "Xingguang Agricultural Mach", c: "CH" },
  { id: 17078, s: "603788.SS", n: "Ningbo Gaofa Automotive Ctrl", c: "CH" },
  { id: 17079, s: "603787.SS", n: "Jiangsu Xinri E-Vehicle", c: "CH" },
  { id: 17080, s: "601949.SS", n: "CH Publishing & Media Hldg", c: "CH" },
  { id: 17081, s: "601128.SS", n: "Jiangsu Changshu Rural Commercial Bank", c: "CH" },
  { id: 17082, s: "3167.TW", n: "Ta Liang Technology", c: "TW" },
  { id: 17083, s: "600823.SS", n: "Shanghai Shimao", c: "CH" },
  { id: 17084, s: "SIX2.F", n: "Sixt SE", c: "GER" },
  { id: 17085, s: "601108.SS", n: "Caitong Securities", c: "CH" },
  { id: 17086, s: "600706.SS", n: "XiAn Qujiang Cultural Tourism", c: "CH" },
  { id: 17087, s: "601113.SS", n: "Yiwu Huading Nylon", c: "CH" },
  { id: 17088, s: "601116.SS", n: "Sanjiang Shopping Club", c: "CH" },
  { id: 17089, s: "SWA.V", n: "Sarama Resource", c: "CA" },
  { id: 17090, s: "601118.SS", n: "CH Hainan Rubber Industry Group", c: "CH" },
  { id: 17091, s: "SNPHY", n: "Santen Pharmaceutical", c: "US" },
  { id: 17092, s: "TUI1.F", n: "TUI AG", c: "GER" },
  { id: 17093, s: "ENB-PFG.TO", n: "Enbridge Inc Pref 15", c: "CA" },
  { id: 17094, s: "601126.SS", n: "Beijing Sifang Automation", c: "CH" },
  { id: 17095, s: "000978.SZ", n: "Guilin Tourism Co", c: "CH" },
  { id: 17096, s: "USAK.IS", n: "Usak Seramik Sanayi AS", c: "Turkey" },
  { id: 17097, s: "SRG.V", n: "SRG Graphite Inc", c: "CA" },
  { id: 17098, s: "600694.SS", n: "Dashang", c: "CH" },
  { id: 17099, s: "SRA.V", n: "Stria Lithium Inc", c: "CA" },
  { id: 17100, s: "1708.TW", n: "Sesoda Co", c: "TW" },
  { id: 17101, s: "600787.SS", n: "CMST Development", c: "CH" },
  { id: 17102, s: "601162.SS", n: "Tianfeng Securities", c: "CH" },
  { id: 17103, s: "601163.SS", n: "Triangle Tyre", c: "CH" },
  { id: 17104, s: "600789.SS", n: "Shandong Lukang Pharmaceutical", c: "CH" },
  { id: 17105, s: "600790.SS", n: "Zhejiang CH Light & Textile Industrial City Group", c: "CH" },
  { id: 17106, s: "601168.SS", n: "Western Mining", c: "CH" },
  { id: 17107, s: "SPA.V", n: "Spanish Mountain Gold", c: "CA" },
  { id: 17108, s: "601177.SS", n: "Hangzhou Advance Gearbox Group", c: "CH" },
  { id: 17109, s: "601179.SS", n: "CH XD Electric", c: "CH" },
  { id: 17110, s: "ENB-PV.TO", n: "Enbridge Inc Pref 1", c: "CA" },
  { id: 17111, s: "600696.SS", n: "Shanghai Guijiu", c: "CH" },
  { id: 17112, s: "KBL.TO", n: "K-Bro Linen Inc.", c: "CA" },
  { id: 17113, s: "600881.SS", n: "Jilin Yatai Group", c: "CH" },
  { id: 17114, s: "KERVT.IS", n: "Kerevitas Gida Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 17115, s: "600778.SS", n: "Xinjiang Youhao Group", c: "CH" },
  { id: 17116, s: "600748.SS", n: "Shanghai Industrial Development", c: "CH" },
  { id: 17117, s: "600981.SS", n: "Jiangsu High Hope International Group Co", c: "CH" },
  { id: 17118, s: "KOZAA.IS", n: "Koza Anadolu Metal Madencilik Isletmeleri AS", c: "Turkey" },
  { id: 17119, s: "600982.SS", n: "Ningbo Thermal Power", c: "CH" },
  { id: 17120, s: "000968.SZ", n: "Shanxi Blue Flame Holding", c: "CH" },
  { id: 17121, s: "600746.SS", n: "Jiangsu SOPO Chemical", c: "CH" },
  { id: 17122, s: "600983.SS", n: "Whirlpool CH", c: "CH" },
  { id: 17123, s: "600984.SS", n: "Shaanxi Construction Machinery", c: "CH" },
  { id: 17124, s: "600744.SS", n: "Datang HuaYin Electric Power", c: "CH" },
  { id: 17125, s: "600743.SS", n: "Hua Yuan Property", c: "CH" },
  { id: 17126, s: "1735.TW", n: "Evermore Chemical Industry", c: "TW" },
  { id: 17127, s: "INFO.IS", n: "Info Yatirim AS", c: "Turkey" },
  { id: 17128, s: "600698.SS", n: "Hunan Tyen Machinery Co A", c: "CH" },
  { id: 17129, s: "601200.SS", n: "Shanghai Environment Group", c: "CH" },
  { id: 17130, s: "601226.SS", n: "Huadian Heavy Industries", c: "CH" },
  { id: 17131, s: "IHLGM.IS", n: "Ihlas Gayrimenkul Proje Gelistirme ve Ticaret AS", c: "Turkey" },
  { id: 17132, s: "601208.SS", n: "Sichuan Em Technology", c: "CH" },
  { id: 17133, s: "IHEVA.IS", n: "Ihlas Ev Aletleri Imalat Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 17134, s: "601212.SS", n: "Baiyin Nonferrous Group", c: "CH" },
  { id: 17135, s: "SML.V", n: "Southstone Minerals", c: "CA" },
  { id: 17136, s: "RCG-PB.TO", n: "RF Capital Group Pref", c: "CA" },
  { id: 17137, s: "600876.SS", n: "Luoyang Glass", c: "CH" },
  { id: 17138, s: "600693.SS", n: "Fujian Dongbai Group", c: "CH" },
  { id: 17139, s: "1529.TW", n: "Klingon Aerospace Inc", c: "TW" },
  { id: 17140, s: "601216.SS", n: "Inner Mongolia Junzheng Energy & Chemical Group", c: "CH" },
  { id: 17141, s: "600692.SS", n: "Shang Hai Ya Tong", c: "CH" },
  { id: 17142, s: "601218.SS", n: "Jiangsu SINOJIT Wind Energy Technology", c: "CH" },
  { id: 17143, s: "601222.SS", n: "Jiangsu Linyang Energy", c: "CH" },
  { id: 17144, s: "600874.SS", n: "Tianjin Capital Environmental Protection Group", c: "CH" },
  { id: 17145, s: "NA-PW.TO", n: "National Bank of CA Pref W", c: "CA" },
  { id: 17146, s: "600691.SS", n: "Yangmei Chemical", c: "CH" },
  { id: 17147, s: "601236.SS", n: "Hongta Securities", c: "CH" },
  { id: 17148, s: "NWX.F", n: "Nordwest Handel AG", c: "GER" },
  { id: 17149, s: "002843.SZ", n: "Bichamp Cutting Technology Hunan", c: "CH" },
  { id: 17150, s: "RALYH.IS", n: "Ral Yatirim Holding AS", c: "Turkey" },
  { id: 17151, s: "601199.SS", n: "Jiangsu Jiangnan Water", c: "CH" },
  { id: 17152, s: "SHWK.F", n: "SHS VIVEON AG", c: "GER" },
  { id: 17153, s: "600791.SS", n: "BEH Property", c: "CH" },
  { id: 17154, s: "601188.SS", n: "Heilongjiang Transport Development", c: "CH" },
  { id: 17155, s: "1475.TW", n: "Big Sunshine", c: "TW" },
  { id: 17156, s: "UBK.F", n: "UmweltBank AG", c: "GER" },
  { id: 17157, s: "INVES.IS", n: "Investco Holding AS", c: "Turkey" },
  { id: 17158, s: "000920.SZ", n: "Vontron Technology", c: "CH" },
  { id: 17159, s: "603816.SS", n: "Jason Furniture(Hangzhou)", c: "CH" },
  { id: 17160, s: "603777.SS", n: "Shanghai Laiyifen", c: "CH" },
  { id: 17161, s: "600987.SS", n: "Zhejiang Hangmin", c: "CH" },
  { id: 17162, s: "603901.SS", n: "Hangzhou Youngsun Intelligent", c: "CH" },
  { id: 17163, s: "900946.SS", n: "Hunan Tyen Machinery Co B", c: "CH" },
  { id: 17164, s: "LL.V", n: "CA Rare Earth Co", c: "CA" },
  { id: 17165, s: "002096.SZ", n: "Hunan Nanling Industrial Explosive Materials", c: "CH" },
  { id: 17166, s: "603703.SS", n: "Zhejiang Shengyang Science", c: "CH" },
  { id: 17167, s: "002101.SZ", n: "Guangdong Hongtu Technology Holdings", c: "CH" },
  { id: 17168, s: "603721.SS", n: "TVZone Media", c: "CH" },
  { id: 17169, s: "603668.SS", n: "Fujian Tianma Science and Technology Group", c: "CH" },
  { id: 17170, s: "603665.SS", n: "Zhejiang Kanglongda Special", c: "CH" },
  { id: 17171, s: "603600.SS", n: "UE Furniture", c: "CH" },
  { id: 17172, s: "603602.SS", n: "Hangzhou Zongheng Commun", c: "CH" },
  { id: 17173, s: "DWNI.F", n: "Deutsche Wohnen SE", c: "GER" },
  { id: 17174, s: "TIMA.DE", n: "ZEAL Network SE", c: "GER" },
  { id: 17175, s: "603607.SS", n: "Zhejiang Jinghua Laser Technology Co Class A", c: "CH" },
  { id: 17176, s: "603609.SS", n: "LiaonWellhope Agri-Tech", c: "CH" },
  { id: 17177, s: "603612.SS", n: "Sunstone Development", c: "CH" },
  { id: 17178, s: "603613.SS", n: "Beijing United Information Technology", c: "CH" },
  { id: 17179, s: "603615.SS", n: "Chahua Modern Housewares", c: "CH" },
  { id: 17180, s: "603616.SS", n: "Beijing Hanjian Heshan Pipeline", c: "CH" },
  { id: 17181, s: "603617.SS", n: "Junhe Pumps Holding", c: "CH" },
  { id: 17182, s: "603626.SS", n: "Kunshan Kersen Science & Tech", c: "CH" },
  { id: 17183, s: "AVAX.AT", n: "Avax S.A", c: "Greece" },
  { id: 17184, s: "002078.SZ", n: "Shan Dong Sun Paper Industry Joint Stock", c: "CH" },
  { id: 17185, s: "603629.SS", n: "Jiangsu Lettall Electronic", c: "CH" },
  { id: 17186, s: "603630.SS", n: "Lafang CH", c: "CH" },
  { id: 17187, s: "603633.SS", n: "Shanghai Laimu Electronics", c: "CH" },
  { id: 17188, s: "603663.SS", n: "Sanxiang Advanced Materials", c: "CH" },
  { id: 17189, s: "603661.SS", n: "Zhejiang Henglin Chair Industry Co Class A", c: "CH" },
  { id: 17190, s: "DGR.F", n: "Deutsche Grundstücksauktionen AG", c: "GER" },
  { id: 17191, s: "603658.SS", n: "Autobio Diagnostics", c: "CH" },
  { id: 17192, s: "603707.SS", n: "Nanjing King-friend Biochemical Pharmaceutical", c: "CH" },
  { id: 17193, s: "900945.SS", n: "Hainan Airlines Co B", c: "CH" },
  { id: 17194, s: "603711.SS", n: "Xiangpiaopiao Food", c: "CH" },
  { id: 17195, s: "603685.SS", n: "Zhejiang Chenfeng Science and Technology Co Class A", c: "CH" },
  { id: 17196, s: "605365.SS", n: "Leedarson IoT Technology Inc", c: "CH" },
  { id: 17197, s: "002320.SZ", n: "Hainan Strait Shipping", c: "CH" },
  { id: 17198, s: "002068.SZ", n: "Jiangxi Black Cat Carbon Black", c: "CH" },
  { id: 17199, s: "603903.SS", n: "Csd Water Service", c: "CH" },
  { id: 17200, s: "603900.SS", n: "Leysen Jewellery Inc", c: "CH" },
  { id: 17201, s: "BLSFY", n: "BlueScope Steel ADR", c: "US" },
  { id: 17202, s: "AKRIT.AT", n: "Akritas S.A", c: "Greece" },
  { id: 17203, s: "603677.SS", n: "Qijing Machinery", c: "CH" },
  { id: 17204, s: "603678.SS", n: "Fujian Huoju Electronic", c: "CH" },
  { id: 17205, s: "603679.SS", n: "Sichuan Huati Lighting Tech", c: "CH" },
  { id: 17206, s: "603680.SS", n: "KTK Group", c: "CH" },
  { id: 17207, s: "603681.SS", n: "Shanghai Yongguan Adhesive Products Co", c: "CH" },
  { id: 17208, s: "603683.SS", n: "Shanghai Smith Adhesive New", c: "CH" },
  { id: 17209, s: "603687.SS", n: "Zhejiang Great Shengda Packaging", c: "CH" },
  { id: 17210, s: "603669.SS", n: "Lionco Pharm Grp", c: "CH" },
  { id: 17211, s: "603689.SS", n: "Anhui Province Natural Gas Development", c: "CH" },
  { id: 17212, s: "900957.SS", n: "Shanghai Lingyun Industries Development", c: "CH" },
  { id: 17213, s: "603693.SS", n: "Jiangsu New Energy Development", c: "CH" },
  { id: 17214, s: "603697.SS", n: "Youyou Foods ", c: "CH" },
  { id: 17215, s: "603699.SS", n: "Neway Valve suzhou", c: "CH" },
  { id: 17216, s: "900953.SS", n: "Kama", c: "CH" },
  { id: 17217, s: "603701.SS", n: "Zhejiang Dehong Automotive", c: "CH" },
  { id: 17218, s: "603718.SS", n: "Shanghai Hile Bio-tech", c: "CH" },
  { id: 17219, s: "900939.SS", n: "Shanghai Huili Building Materials", c: "CH" },
  { id: 17220, s: "603717.SS", n: "Tianyu Ecology & Landscape", c: "CH" },
  { id: 17221, s: "603716.SS", n: "Wuhan Thalys Med Tech", c: "CH" },
  { id: 17222, s: "603713.SS", n: "Milkyway Chemical Supply Chain Service", c: "CH" },
  { id: 17223, s: "603712.SS", n: "TianJin 712 Communication & Broadcasting", c: "CH" },
  { id: 17224, s: "603657.SS", n: "Jinhua Chunguang Technology", c: "CH" },
  { id: 17225, s: "002085.SZ", n: "Zhejiang Wanfeng Auto Wheel", c: "CH" },
  { id: 17226, s: "603667.SS", n: "Zhejiang XCC Group", c: "CH" },
  { id: 17227, s: "603773.SS", n: "WG Tech JiangXi", c: "CH" },
  { id: 17228, s: "ATLCY", n: "Atlas Copco ADR", c: "US" },
  { id: 17229, s: "603822.SS", n: "Zhejiang Jiaao Enprotech", c: "CH" },
  { id: 17230, s: "603889.SS", n: "Zhejiang Xinao Textiles Inc", c: "CH" },
  { id: 17231, s: "603886.SS", n: "Ganso", c: "CH" },
  { id: 17232, s: "603883.SS", n: "Laobaixing Pharmacy", c: "CH" },
  { id: 17233, s: "SDXAY", n: "Sodexo PK", c: "US" },
  { id: 17234, s: "603878.SS", n: "Jiang Su Wujin Stainless Steel Pipe Group", c: "CH" },
  { id: 17235, s: "603877.SS", n: "Ningbo Peace Bird Fashion", c: "CH" },
  { id: 17236, s: "900904.SS", n: "Shanghai Shenqi Pharmaceutical Investment Management Co B", c: "CH" },
  { id: 17237, s: "CLIQ.F", n: "Cliq Digital AG", c: "GER" },
  { id: 17238, s: "603819.SS", n: "Changzhou Shenli Electrical Machine Incorporated", c: "CH" },
  { id: 17239, s: "603818.SS", n: "Qumei Furniture Group", c: "CH" },
  { id: 17240, s: "603817.SS", n: "Haixia Envrnmnt", c: "CH" },
  { id: 17241, s: "603768.SS", n: "Hefei Changqing Machinery", c: "CH" },
  { id: 17242, s: "603895.SS", n: "Shanghai Tianyong Engineering", c: "CH" },
  { id: 17243, s: "603767.SS", n: "Zhejiang Zomax Transmission", c: "CH" },
  { id: 17244, s: "603766.SS", n: "Loncin Motor", c: "CH" },
  { id: 17245, s: "603757.SS", n: "Zhejiang Dayuan Pumps Ind", c: "CH" },
  { id: 17246, s: "603755.SS", n: "Qingdao Richen Food", c: "CH" },
  { id: 17247, s: "CSQ.F", n: "creditshelf Aktiengesellschaft", c: "GER" },
  { id: 17248, s: "603739.SS", n: "Qingdao Vland Biotech", c: "CH" },
  { id: 17249, s: "603738.SS", n: "Hubei TKD Crystal Electn", c: "CH" },
  { id: 17250, s: "900937.SS", n: "Huadian Ener-B", c: "CH" },
  { id: 17251, s: "603733.SS", n: "Xianhe", c: "CH" },
  { id: 17252, s: "603730.SS", n: "Shanghai Daimay Auto Interior", c: "CH" },
  { id: 17253, s: "603729.SS", n: "Shanghai Longyun Advg & Media", c: "CH" },
  { id: 17254, s: "603728.SS", n: "Shanghai Moons' Elec", c: "CH" },
  { id: 17255, s: "900932.SS", n: "Shanghai Lujiazui Finance & Trade Zone Development Co B", c: "CH" },
  { id: 17256, s: "603890.SS", n: "Suzhou Chunqiu Electronic Technology Co Class A", c: "CH" },
  { id: 17257, s: "603896.SS", n: "Zhejiang Shouxiangu Pharm", c: "CH" },
  { id: 17258, s: "603656.SS", n: "Hefei Taihe Optoelectronic", c: "CH" },
  { id: 17259, s: "BTBB.DU", n: "Sleepz AG", c: "GER" },
  { id: 17260, s: "002082.SZ", n: "Wanbangde Pharmaceutical Holding Group", c: "CH" },
  { id: 17261, s: "603648.SS", n: "Shanghai Shine-Link Intl", c: "CH" },
  { id: 17262, s: "603639.SS", n: "Hailir Pesticides and Chemicals Group", c: "CH" },
  { id: 17263, s: "603638.SS", n: "Yantai Eddie Precision Mach", c: "CH" },
  { id: 17264, s: "603637.SS", n: "Zhenhai PetroChem Engineering", c: "CH" },
  { id: 17265, s: "603636.SS", n: "Linewell Software", c: "CH" },
  { id: 17266, s: "002084.SZ", n: "Guangzhou Seagull Kitchen And Bath Products", c: "CH" },
  { id: 17267, s: "603722.SS", n: "Wuxi Acryl Technology", c: "CH" },
  { id: 17268, s: "603725.SS", n: "Guangdong Tianan New Material", c: "CH" },
  { id: 17269, s: "603726.SS", n: "Zhejiang Langdi Group", c: "CH" },
  { id: 17270, s: "603823.SS", n: "Lily Group", c: "CH" },
  { id: 17271, s: "603826.SS", n: "Fujian Kuncai Material Tech", c: "CH" },
  { id: 17272, s: "603828.SS", n: "Suzhou Kelida Bldg &", c: "CH" },
  { id: 17273, s: "603839.SS", n: "Anzheng Fashion", c: "CH" },
  { id: 17274, s: "CDZ0.F", n: "MHP HOTEL AG INH O.N.", c: "GER" },
  { id: 17275, s: "603843.SS", n: "Zhengping Road&Bridge Constr", c: "CH" },
  { id: 17276, s: "603848.SS", n: "Guangdong Hotata Technology Group Co Class A", c: "CH" },
  { id: 17277, s: "603855.SS", n: "Warom Technology Inc", c: "CH" },
  { id: 17278, s: "603856.SS", n: "Shandong Donghong Pipe Ind", c: "CH" },
  { id: 17279, s: "2739.TW", n: "My Humble House Hospitality Management Consulting", c: "TW" },
  { id: 17280, s: "603860.SS", n: "RoadMain T", c: "CH" },
  { id: 17281, s: "603861.SS", n: "GuangZhou BaiYun Elec Equip", c: "CH" },
  { id: 17282, s: "603863.SS", n: "Guangdong Songyang Recycle Resources", c: "CH" },
  { id: 17283, s: "603866.SS", n: "Shenyang Toly Bread", c: "CH" },
  { id: 17284, s: "603868.SS", n: "Shanghai Flyco Electrical Appliance", c: "CH" },
  { id: 17285, s: "603869.SS", n: "Beibu Gulf Tourism Co", c: "CH" },
  { id: 17286, s: "603871.SS", n: "Jiayou International Logistics", c: "CH" },
  { id: 17287, s: "603898.SS", n: "Holike", c: "CH" },
  { id: 17288, s: "600986.SS", n: "Zhewen Interactive Group", c: "CH" },
  { id: 17289, s: "600988.SS", n: "Chifeng Jilong Gold Mining", c: "CH" },
  { id: 17290, s: "3013.TW", n: "Chenming Mold Industrial Co", c: "TW" },
  { id: 17291, s: "LQAG.F", n: "LAIQON AG INH O.N.", c: "GER" },
  { id: 17292, s: "BTDPY", n: "Barratt Developments PLC", c: "US" },
  { id: 17293, s: "ECILC.IS", n: "EIS Eczacibasi Ilac Sinai ve Finansal Yatirimlar Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 17294, s: "AAGC", n: "All American Gld Crp", c: "US" },
  { id: 17295, s: "601869.SS", n: "Yangtze Optical Fibre and Cable Joint Stock", c: "CH" },
  { id: 17296, s: "DURDO.IS", n: "Duran-Dogan Basim ve Ambalaj Sanayi AS", c: "Turkey" },
  { id: 17297, s: "600816.SS", n: "Anxin Trust", c: "CH" },
  { id: 17298, s: "DITAS.IS", n: "Ditas Dogan Yedek Parca Imalat ve Teknik AS", c: "Turkey" },
  { id: 17299, s: "000955.SZ", n: "Xinlong Holding Group", c: "CH" },
  { id: 17300, s: "600650.SS", n: "Shanghai Jin Jiang International Industrial Investment Co A", c: "CH" },
  { id: 17301, s: "DIRIT.IS", n: "Diriteks Dirilis Tekstil Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 17302, s: "600826.SS", n: "DLG Exhibitions & Events Co", c: "CH" },
  { id: 17303, s: "GBAR.TO", n: "Monarch Mining Co", c: "CA" },
  { id: 17304, s: "600825.SS", n: "Shanghai Xinhua Media", c: "CH" },
  { id: 17305, s: "600649.SS", n: "Shanghai SMI Holding", c: "CH" },
  { id: 17306, s: "601880.SS", n: "Liaoning Port", c: "CH" },
  { id: 17307, s: "SE3.HM", n: "Smart Equity AG", c: "GER" },
  { id: 17308, s: "600673.SS", n: "Guangdong Hec Technology Holding Co Class A", c: "CH" },
  { id: 17309, s: "600848.SS", n: "Shanghai Lingang Holdings Co A", c: "CH" },
  { id: 17310, s: "LUS1.F", n: "LANG + SCHWARZ AG NA O.N.", c: "GER" },
  { id: 17311, s: "1445.TW", n: "Universal Textile", c: "TW" },
  { id: 17312, s: "RRR-UN.V", n: "R&R Real Estate Investment Trust", c: "CA" },
  { id: 17313, s: "VERUS.IS", n: "Verusa Holding AS", c: "Turkey" },
  { id: 17314, s: "601500.SS", n: "Jiangsu General Science Tech", c: "CH" },
  { id: 17315, s: "601512.SS", n: "CH-Singapore Suzhou Industrial Park Development Group", c: "CH" },
  { id: 17316, s: "600802.SS", n: "Fujian Cement Inc", c: "CH" },
  { id: 17317, s: "601518.SS", n: "Jilin Expressway", c: "CH" },
  { id: 17318, s: "00088K.KS", n: "Hanwha Corp Preferred", c: "Korea" },
  { id: 17319, s: "600804.SS", n: "Dr Peng Telecom and Media Group", c: "CH" },
  { id: 17320, s: "601566.SS", n: "Joeone", c: "CH" },
  { id: 17321, s: "601860.SS", n: "Jiangsu Zijin Rural Commercial Bank", c: "CH" },
  { id: 17322, s: "ZNG.V", n: "Group Eleven Resources Co", c: "CA" },
  { id: 17323, s: "SKLTY", n: "Seek ADR", c: "US" },
  { id: 17324, s: "600645.SS", n: "Vcanbio Cell & Gene Engineering Co", c: "CH" },
  { id: 17325, s: "000971.SZ", n: "Gosun Holding", c: "CH" },
  { id: 17326, s: "1441.TW", n: "Tah Tong Textile", c: "TW" },
  { id: 17327, s: "600831.SS", n: "Shaanxi Broadcast & TV Network Intermediary Group", c: "CH" },
  { id: 17328, s: "600818.SS", n: "Zhonglu Co A", c: "CH" },
  { id: 17329, s: "AEG.TO", n: "Aegis Brands Inc", c: "CA" },
  { id: 17330, s: "600819.SS", n: "Shanghai Yaohua Pilkington Glass Group Co A", c: "CH" },
  { id: 17331, s: "CLEBI.IS", n: "Celebi Hava Servisi AS", c: "Turkey" },
  { id: 17332, s: "000505.SZ", n: "Hainan Pearl River Holdings", c: "CH" },
  { id: 17333, s: "600647.SS", n: "Shanghai Tongda Venture Capital", c: "CH" },
  { id: 17334, s: "BSOKE.IS", n: "Batisoke Soke Cimento Sanayi TAS", c: "Turkey" },
  { id: 17335, s: "ITN.F", n: "Intertainment AG", c: "GER" },
  { id: 17336, s: "PETUN.IS", n: "Pinar Entegre Et ve Un Sanayi AS", c: "Turkey" },
  { id: 17337, s: "601890.SS", n: "Asian Star Anchor Chain Co Jiangsu", c: "CH" },
  { id: 17338, s: "600644.SS", n: "Leshan Electric Power", c: "CH" },
  { id: 17339, s: "600815.SS", n: "Xiamen XGMA Machinery", c: "CH" },
  { id: 17340, s: "600821.SS", n: "Nyocor", c: "CH" },
  { id: 17341, s: "600643.SS", n: "Shanghai AJ Group", c: "CH" },
  { id: 17342, s: "BMSTL.IS", n: "Bms Birlesik Metal Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 17343, s: "600641.SS", n: "Shanghai Wanye Enterprises", c: "CH" },
  { id: 17344, s: "601929.SS", n: "JiShi Media", c: "CH" },
  { id: 17345, s: "600640.SS", n: "New Guomai Digital Culture", c: "CH" },
  { id: 17346, s: "BMSCH.IS", n: "BMS Celik Hasir Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 17347, s: "BLCYT.IS", n: "Bilici Yatirim Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 17348, s: "601908.SS", n: "Beijing Jingyuntong Technology", c: "CH" },
  { id: 17349, s: "2374.TW", n: "Ability Enterprise", c: "TW" },
  { id: 17350, s: "000958.SZ", n: "SPIC Dongfang New Energy Co", c: "CH" },
  { id: 17351, s: "601882.SS", n: "Ningbo Haitian Precision Machinery", c: "CH" },
  { id: 17352, s: "600830.SS", n: "Sunny Loan Top", c: "CH" },
  { id: 17353, s: "ICE.TO", n: "Canlan Ice Sports Corp.", c: "CA" },
  { id: 17354, s: "600681.SS", n: "Bestsun Energy", c: "CH" },
  { id: 17355, s: "GOLTS.IS", n: "Goltas Goller Bolgesi Cimento Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 17356, s: "WIG1.DE", n: "Sporttotal AG", c: "GER" },
  { id: 17357, s: "600851.SS", n: "Shanghai Haixin Group Co A", c: "CH" },
  { id: 17358, s: "MBH3.F", n: "Maschinenfabrik Berthold Hermle AG", c: "GER" },
  { id: 17359, s: "601665.SS", n: "Qilu Bank", c: "CH" },
  { id: 17360, s: "600675.SS", n: "CH Enterprise", c: "CH" },
  { id: 17361, s: "EPLAS.IS", n: "Egeplast Ege Plastik Ticaret ve Sanayi AS", c: "Turkey" },
  { id: 17362, s: "600857.SS", n: "Ningbo Zhongbai", c: "CH" },
  { id: 17363, s: "MCE.F", n: "MEDIQON Group AG", c: "GER" },
  { id: 17364, s: "MOR.F", n: "MorphoSys AG", c: "GER" },
  { id: 17365, s: "JUGR.V", n: "Juggernaut Exploration", c: "CA" },
  { id: 17366, s: "601608.SS", n: "CITIC Heavy Industries", c: "CH" },
  { id: 17367, s: "600850.SS", n: "CETC Digital Technology", c: "CH" },
  { id: 17368, s: "601606.SS", n: "Anhui Great Wall Military Industry", c: "CH" },
  { id: 17369, s: "MRSHL.IS", n: "Marshall Boya ve Vernik Sanayi AS", c: "Turkey" },
  { id: 17370, s: "600853.SS", n: "Longjian Road & Bridge", c: "CH" },
  { id: 17371, s: "601579.SS", n: "Kuaijishan Shaoxing Rice Wine", c: "CH" },
  { id: 17372, s: "600805.SS", n: "Jiangsu Yueda Investment", c: "CH" },
  { id: 17373, s: "SMBMY", n: "Sembcorp Marine ADR", c: "US" },
  { id: 17374, s: "MSGYO.IS", n: "Mistral Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 17375, s: "601618.SS", n: "Metallurgical Corporation of CH", c: "CH" },
  { id: 17376, s: "601588.SS", n: "Beijing North Star Co Class A", c: "CH" },
  { id: 17377, s: "000975.SZ", n: "Yintai Resources", c: "CH" },
  { id: 17378, s: "601616.SS", n: "Shanghai Guangdian Electric Group", c: "CH" },
  { id: 17379, s: "600676.SS", n: "Shanghai Jiao Yun Group", c: "CH" },
  { id: 17380, s: "600858.SS", n: "Inzone Group", c: "CH" },
  { id: 17381, s: "000976.SZ", n: "Guangdong Kaiping Chunhui", c: "CH" },
  { id: 17382, s: "002740.SZ", n: "Fujian Ideal Jewelry Industrial", c: "CH" },
  { id: 17383, s: "RADLY", n: "Raia Drogasil SA ADR", c: "US" },
  { id: 17384, s: "BCE-PS.TO", n: "Bce Inc Pref S", c: "CA" },
  { id: 17385, s: "1453.TW", n: "Ta Jiang", c: "TW" },
  { id: 17386, s: "600863.SS", n: "Inner Mongolia MengDian HuaNeng Thermal Power Co", c: "CH" },
  { id: 17387, s: "600861.SS", n: "Beijing Urban Rural Commercial Group", c: "CH" },
  { id: 17388, s: "SCLT.V", n: "Searchlight Resources Inc", c: "CA" },
  { id: 17389, s: "GLBMD.IS", n: "Global Menkul Degerler AS", c: "Turkey" },
  { id: 17390, s: "SBX.DE", n: "SynBiotic SE", c: "GER" },
  { id: 17391, s: "MSH.F", n: "WASGAU Produktions & Handels AG", c: "GER" },
  { id: 17392, s: "GENTS.IS", n: "Gentas Genel Metal Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 17393, s: "WVM.V", n: "West Vault Mining", c: "CA" },
  { id: 17394, s: "IAF-PB.TO", n: "Industrial Alliance Insurance and Financial Services Inc Pref Series B", c: "CA" },
  { id: 17395, s: "601388.SS", n: "Ye Chiu Metal Recycling CH", c: "CH" },
  { id: 17396, s: "600678.SS", n: "Sichuan Golden Summit Group Joint Stock", c: "CH" },
  { id: 17397, s: "601369.SS", n: "XiAn Shaangu Power", c: "CH" },
  { id: 17398, s: "600800.SS", n: "Tian Jin Global Magnetic Card", c: "CH" },
  { id: 17399, s: "NTG.F", n: "Nabaltec AG", c: "GER" },
  { id: 17400, s: "NEM.F", n: "Nemetschek SE", c: "GER" },
  { id: 17401, s: "600793.SS", n: "Yibin Paper Industry", c: "CH" },
  { id: 17402, s: "SAY.V", n: "Sparta Capital", c: "CA" },
  { id: 17403, s: "GSDDE.IS", n: "GSD Denizcilik Gayrimenkul Insaat Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 17404, s: "600689.SS", n: "Shanghai Sanmao Enterprise Group Co A", c: "CH" },
  { id: 17405, s: "600866.SS", n: "Starlake Bioscience Co Inc Zhaoqing Guangdong", c: "CH" },
  { id: 17406, s: "601330.SS", n: "Dynagreen Environmental Protection Group", c: "CH" },
  { id: 17407, s: "600796.SS", n: "Zhejiang Qianjiang Biochemical", c: "CH" },
  { id: 17408, s: "2HRA.DE", n: "H&R GmbH & Co. KGaA", c: "GER" },
  { id: 17409, s: "600686.SS", n: "Xiamen King Long Motor Group", c: "CH" },
  { id: 17410, s: "BIO.DE", n: "Biotest Aktiengesellschaft", c: "GER" },
  { id: 17411, s: "600797.SS", n: "Insigma", c: "CH" },
  { id: 17412, s: "601339.SS", n: "Bros Eastern", c: "CH" },
  { id: 17413, s: "RTM.V", n: "RT Minerals Co", c: "CA" },
  { id: 17414, s: "002762.SZ", n: "Jinfa Labi Maternity & Baby Articles", c: "CH" },
  { id: 17415, s: "600798.SS", n: "Ningbo Marine", c: "CH" },
  { id: 17416, s: "RTH.V", n: "Rathdowney Resources", c: "CA" },
  { id: 17417, s: "BMO-PF.TO", n: "Bank of Montreal Pref Series 46", c: "CA" },
  { id: 17418, s: "600841.SS", n: "Shanghai Diesel Engine Co A", c: "CH" },
  { id: 17419, s: "600764.SS", n: "CH Marine Information Electronics", c: "CH" },
  { id: 17420, s: "600843.SS", n: "Shang Gong Group Co A", c: "CH" },
  { id: 17421, s: "SMPNY", n: "Sompo Holdings Inc ADR", c: "US" },
  { id: 17422, s: "600739.SS", n: "Liaoning Cheng Da", c: "CH" },
  { id: 17423, s: "600737.SS", n: "COFCO Tunhe Sugar", c: "CH" },
  { id: 17424, s: "SAYAS.IS", n: "Say Yenilenebilir Enerji Ekipmanları Sanayi ve Ticaret Anonim Şirketi", c: "Turkey" },
  { id: 17425, s: "ACAQ", n: "Athena Consumer Acquisition Co", c: "US" },
  { id: 17426, s: "601011.SS", n: "Baotailong New Materials", c: "CH" },
  { id: 17427, s: "SHL.F", n: "Siemens Healthineers AG", c: "GER" },
  { id: 17428, s: "R1B.F", n: "Rubean AG", c: "GER" },
  { id: 17429, s: "600901.SS", n: "Jiangsu Financial Leasing Co Class A", c: "CH" },
  { id: 17430, s: "TORQ.V", n: "Torq Resources Inc", c: "CA" },
  { id: 17431, s: "600726.SS", n: "Huadian Energy", c: "CH" },
  { id: 17432, s: "600725.SS", n: "Yunnan Yunwei Co Class A", c: "CH" },
  { id: 17433, s: "600724.SS", n: "Ningbo Fuda", c: "CH" },
  { id: 17434, s: "TORO.V", n: "Pucara Gold Ltd", c: "CA" },
  { id: 17435, s: "600722.SS", n: "HeBei Jinniu Chemical Industry", c: "CH" },
  { id: 17436, s: "601019.SS", n: "Shandong Publishing & Media", c: "CH" },
  { id: 17437, s: "600721.SS", n: "Xinjiang Baihuacun", c: "CH" },
  { id: 17438, s: "600720.SS", n: "Gansu Qilianshan Cement Group", c: "CH" },
  { id: 17439, s: "KFEIN.IS", n: "Kafein Yazilim", c: "Turkey" },
  { id: 17440, s: "TLA.V", n: "Titan Logix Corp.", c: "CA" },
  { id: 17441, s: "600719.SS", n: "Dalian Thermal Power", c: "CH" },
  { id: 17442, s: "BNS-PI.TO", n: "Bank of Nova Scotia Pref Series 40", c: "CA" },
  { id: 17443, s: "601021.SS", n: "Spring Airlines", c: "CH" },
  { id: 17444, s: "600716.SS", n: "Jiangsu Phoenix Property Investment", c: "CH" },
  { id: 17445, s: "600728.SS", n: "Pci-Suntek Technology", c: "CH" },
  { id: 17446, s: "600738.SS", n: "Lanzhou Minbai Shareholding Group", c: "CH" },
  { id: 17447, s: "600909.SS", n: "Huaan Securities", c: "CH" },
  { id: 17448, s: "601008.SS", n: "Jiangsu Lianyungang Port", c: "CH" },
  { id: 17449, s: "600993.SS", n: "Mayinglong Pharmaceutical Group", c: "CH" },
  { id: 17450, s: "600760.SS", n: "Avic Shenyang Aircraft", c: "CH" },
  { id: 17451, s: "SAX.F", n: "Ströer SE & Co. KGaA", c: "GER" },
  { id: 17452, s: "600975.SS", n: "Hunan New Wellful", c: "CH" },
  { id: 17453, s: "600753.SS", n: "Fujian Oriental Silver Star Investment", c: "CH" },
  { id: 17454, s: "000967.SZ", n: "Infore Environment Technology Group", c: "CH" },
  { id: 17455, s: "600765.SS", n: "AVIC Heavy Machinery", c: "CH" },
  { id: 17456, s: "002836.SZ", n: "Guangdong New Grand Long Packing", c: "CH" },
  { id: 17457, s: "600917.SS", n: "Chongqing Gas Grp Co", c: "CH" },
  { id: 17458, s: "SELGD.IS", n: "Selcuk Gida Endustri Ihracat Ithalat AS", c: "Turkey" },
  { id: 17459, s: "UGE.V", n: "UGE International", c: "CA" },
  { id: 17460, s: "600980.SS", n: "BGRIMM Science and Technology", c: "CH" },
  { id: 17461, s: "600740.SS", n: "ShanXi Coking", c: "CH" },
  { id: 17462, s: "600979.SS", n: "Guangan", c: "CH" },
  { id: 17463, s: "KONYA.IS", n: "Konya Cimento Sanayi AS", c: "Turkey" },
  { id: 17464, s: "BUI.TO", n: "Buhler Industries Inc.", c: "CA" },
  { id: 17465, s: "600961.SS", n: "Zhuzhou Smelter Group", c: "CH" },
  { id: 17466, s: "002837.SZ", n: "Shenzhen Envicool Technology", c: "CH" },
  { id: 17467, s: "600757.SS", n: "Changjiang Publishing & Media", c: "CH" },
  { id: 17468, s: "600936.SS", n: "Guangxi Radio and Television Information Network Co", c: "CH" },
  { id: 17469, s: "600962.SS", n: "SDIC Zhonglu Fruit Juice", c: "CH" },
  { id: 17470, s: "600959.SS", n: "Jiangsu Broadcasting Cable inf", c: "CH" },
  { id: 17471, s: "SNKRN.IS", n: "Senkron Guvenlik ve Iletisim Sistemleri AS", c: "Turkey" },
  { id: 17472, s: "600758.SS", n: "Liaoning Hongyang Energy Resource Invest", c: "CH" },
  { id: 17473, s: "600749.SS", n: "Tibet Tourism", c: "CH" },
  { id: 17474, s: "CPX-PK.TO", n: "Capital Power Co", c: "CA" },
  { id: 17475, s: "002798.SZ", n: "D&O Home Collection", c: "CH" },
  { id: 17476, s: "USHA.V", n: "Usha Resources", c: "CA" },
  { id: 17477, s: "600955.SS", n: "Lihuayi Weiyuan Chemical", c: "CH" },
  { id: 17478, s: "600969.SS", n: "Hunan Chen Dian International Development", c: "CH" },
  { id: 17479, s: "600970.SS", n: "Sinoma International Engineering", c: "CH" },
  { id: 17480, s: "600939.SS", n: "Chongqing Constr Engineering", c: "CH" },
  { id: 17481, s: "600973.SS", n: "Baosheng", c: "CH" },
  { id: 17482, s: "600736.SS", n: "Suzhou New District Hi Tech Industrial", c: "CH" },
  { id: 17483, s: "600995.SS", n: "Yunnan Wenshan Electric Power", c: "CH" },
  { id: 17484, s: "600996.SS", n: "Guizhou Broadcasting&TV Info", c: "CH" },
  { id: 17485, s: "600671.SS", n: "Hangzhou TianMuShan Pharmaceutical Enterprise", c: "CH" },
  { id: 17486, s: "600833.SS", n: "Shanghai No1 Pharmacy", c: "CH" },
  { id: 17487, s: "EGGUB.IS", n: "Ege Gubre Sanayi AS", c: "Turkey" },
  { id: 17488, s: "002705.SZ", n: "Guangdong Xinbao Electrical Appliances Holdings", c: "CH" },
  { id: 17489, s: "002847.SZ", n: "Yankershop Food", c: "CH" },
  { id: 17490, s: "600654.SS", n: "CH Security & Fire Co Class A", c: "CH" },
  { id: 17491, s: "ECZYT.IS", n: "Eczacibasi Yatirim Holding Ortakligi AS", c: "Turkey" },
  { id: 17492, s: "STG.F", n: "STINAG Stuttgart Invest AG", c: "GER" },
  { id: 17493, s: "002703.SZ", n: "Zhejiang Shibao", c: "CH" },
  { id: 17494, s: "600811.SS", n: "Orient Group Inc", c: "CH" },
  { id: 17495, s: "ORCAY.IS", n: "Orcay Ortakoy Cay Sanayi & Ticaret AS", c: "Turkey" },
  { id: 17496, s: "601777.SS", n: "Lifan Industry Group", c: "CH" },
  { id: 17497, s: "601677.SS", n: "Henan Mingtai Al.Industrial", c: "CH" },
  { id: 17498, s: "601678.SS", n: "Befar Group", c: "CH" },
  { id: 17499, s: "2331.TW", n: "Elitegroup Computer Systems", c: "TW" },
  { id: 17500, s: "600835.SS", n: "Shanghai Mechanical & Electrical Industry Co A", c: "CH" },
  { id: 17501, s: "000973.SZ", n: "FSPG Hi-Tech", c: "CH" },
  { id: 17502, s: "600846.SS", n: "Shanghai Tongji Science&Technology Industrial", c: "CH" },
  { id: 17503, s: "VKING.IS", n: "Viking Kagit ve Seluloz AS", c: "Turkey" },
  { id: 17504, s: "601696.SS", n: "Boc International CH ", c: "CH" },
  { id: 17505, s: "601698.SS", n: "CH Satellite Communications", c: "CH" },
  { id: 17506, s: "MZHLD.IS", n: "Mazhar Zorlu Holding AS", c: "Turkey" },
  { id: 17507, s: "600667.SS", n: "Wuxi Taiji Industry", c: "CH" },
  { id: 17508, s: "BCE-PQ.TO", n: "Bce Inc Pref Q", c: "CA" },
  { id: 17509, s: "600666.SS", n: "Aurora Optoelectronics", c: "CH" },
  { id: 17510, s: "SNRY", n: "Solar Energy Initiat", c: "US" },
  { id: 17511, s: "601718.SS", n: "Jihua Group Co", c: "CH" },
  { id: 17512, s: "600665.SS", n: "Tande", c: "CH" },
  { id: 17513, s: "000762.SZ", n: "Tibet Mineral Development", c: "CH" },
  { id: 17514, s: "YGT.V", n: "Gold Terra Resource Co", c: "CA" },
  { id: 17515, s: "000972.SZ", n: "Xinjiang Chalkis", c: "CH" },
  { id: 17516, s: "600735.SS", n: "Shandong Hiking International Co Class A", c: "CH" },
  { id: 17517, s: "600771.SS", n: "GuangYuYuan Chinese Herbal Medicine", c: "CH" },
  { id: 17518, s: "600997.SS", n: "Kailuan Energy Chemical", c: "CH" },
  { id: 17519, s: "1752.TW", n: "Nang Kuang Pharmaceutical", c: "TW" },
  { id: 17520, s: "KLMSN.IS", n: "Klimasan Klima Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 17521, s: "600734.SS", n: "Start Group", c: "CH" },
  { id: 17522, s: "TVI.V", n: "TVI Pacific Inc", c: "CA" },
  { id: 17523, s: "601001.SS", n: "Jinneng Holding Shanxi Coal Industry", c: "CH" },
  { id: 17524, s: "601002.SS", n: "Gem-Year Industrial", c: "CH" },
  { id: 17525, s: "LB-PH.TO", n: "Laurentian Bank of CA Pref H", c: "CA" },
  { id: 17526, s: "BPO-PY.TO", n: "Brookfield Office Properties Inc Pref Y", c: "CA" },
  { id: 17527, s: "KUTPO.IS", n: "Kutahya Porselen Sanayi AS", c: "Turkey" },
  { id: 17528, s: "600733.SS", n: "Baic Bluepark New Energy Technology", c: "CH" },
  { id: 17529, s: "601005.SS", n: "Chongqing Iron & Steel Co Class A", c: "CH" },
  { id: 17530, s: "1760.TW", n: "Panion & BF Biotech Inc", c: "TW" },
  { id: 17531, s: "601007.SS", n: "Jinling Hotel Co", c: "CH" },
  { id: 17532, s: "NATEN.IS", n: "Naturel Yenilenebilir Enerji Ticare", c: "Turkey" },
  { id: 17533, s: "1522.TW", n: "TYC Brother Industrial", c: "TW" },
  { id: 17534, s: "600729.SS", n: "Chongqing Department Store", c: "CH" },
  { id: 17535, s: "HURGZ.IS", n: "Hurriyet Gazetecilik ve Matbaacilik AS", c: "Turkey" },
  { id: 17536, s: "SCE.F", n: "Schweizer Electronic AG", c: "GER" },
  { id: 17537, s: "601311.SS", n: "Camel Group", c: "CH" },
  { id: 17538, s: "601789.SS", n: "Ningbo Construction", c: "CH" },
  { id: 17539, s: "601798.SS", n: "Lanpec Technologies", c: "CH" },
  { id: 17540, s: "601799.SS", n: "Changzhou Xingyu Automotive Lighting Systems", c: "CH" },
  { id: 17541, s: "1443.TW", n: "Lily Textile", c: "TW" },
  { id: 17542, s: "LEH.F", n: "LEHNER INVESTMENTS AG", c: "GER" },
  { id: 17543, s: "YESIL.IS", n: "Yesil Yatirim Holding AS", c: "Turkey" },
  { id: 17544, s: "600810.SS", n: "Shen Ma Industry", c: "CH" },
  { id: 17545, s: "QCAAF", n: "Quinsam Capital Co", c: "US" },
  { id: 17546, s: "AGR3.F", n: "AGROB Immobilien AG", c: "GER" },
  { id: 17547, s: "GOZDE.IS", n: "Gozde Girisim Sermayesi Yatirim Ortakligi AS", c: "Turkey" },
  { id: 17548, s: "ADC.F", n: "AdCapital AG", c: "GER" },
  { id: 17549, s: "603331.SS", n: "Zhejiang Baida Precision Mnfg", c: "CH" },
  { id: 17550, s: "603258.SS", n: "Hangzhou Electn Soul Network", c: "CH" },
  { id: 17551, s: "603239.SS", n: "Zhejiang Xiantong Rubber&Plastic", c: "CH" },
  { id: 17552, s: "603238.SS", n: "Nbond Nonwovens", c: "CH" },
  { id: 17553, s: "NBG6.DE", n: "NÜRNBERGER Beteiligungs-AG", c: "GER" },
  { id: 17554, s: "FPE3.F", n: "Fuchs Petrolub SE Preference Shares", c: "GER" },
  { id: 17555, s: "603232.SS", n: "Shanghai Koal Software", c: "CH" },
  { id: 17556, s: "603229.SS", n: "Zhejiang Ausun Pharmaceutical", c: "CH" },
  { id: 17557, s: "603256.SS", n: "Grace Fabric Technology", c: "CH" },
  { id: 17558, s: "603356.SS", n: "Xuancheng Valin Precision Technology", c: "CH" },
  { id: 17559, s: "603357.SS", n: "Anhui Transport Consulting &", c: "CH" },
  { id: 17560, s: "8103.TW", n: "CviLux Co", c: "TW" },
  { id: 17561, s: "603516.SS", n: "Beijing Tricolor Technology", c: "CH" },
  { id: 17562, s: "ECV.F", n: "Encavis AG", c: "GER" },
  { id: 17563, s: "RNWR", n: "808 Renewable Energy Co", c: "US" },
  { id: 17564, s: "603519.SS", n: "Jiangsu Liba Enterprise Joint-Stock", c: "CH" },
  { id: 17565, s: "603520.SS", n: "Zhejiang Starry Pharm", c: "CH" },
  { id: 17566, s: "603527.SS", n: "Anhui Zhongyuan New Materials", c: "CH" },
  { id: 17567, s: "603528.SS", n: "Nanjing Doron Technology Co", c: "CH" },
  { id: 17568, s: "002620.SZ", n: "Shenzhen Ruihe Construction Decoration", c: "CH" },
  { id: 17569, s: "603533.SS", n: "IReader Technology", c: "CH" },
  { id: 17570, s: "603535.SS", n: "Guangzhou Jiacheng", c: "CH" },
  { id: 17571, s: "603536.SS", n: "Shandong Huifa Foodstuff", c: "CH" },
  { id: 17572, s: "603538.SS", n: "Ningbo Menovo Pharm", c: "CH" },
  { id: 17573, s: "002056.SZ", n: "Hengdian Group DMEGC Magnetics", c: "CH" },
  { id: 17574, s: "603555.SS", n: "Guirenniao", c: "CH" },
  { id: 17575, s: "603556.SS", n: "Hexing Electrical", c: "CH" },
  { id: 17576, s: "603557.SS", n: "Qibu", c: "CH" },
  { id: 17577, s: "002059.SZ", n: "Yunnan Tourism", c: "CH" },
  { id: 17578, s: "603588.SS", n: "Beijing GeoEnviron Engineering", c: "CH" },
  { id: 17579, s: "603286.SS", n: "Jiangsu Riying Electns", c: "CH" },
  { id: 17580, s: "8443.TW", n: "Shui-Mu International", c: "TW" },
  { id: 17581, s: "603266.SS", n: "Ningbo Tianlong Electronics", c: "CH" },
  { id: 17582, s: "8940.TW", n: "New Palace International", c: "TW" },
  { id: 17583, s: "603329.SS", n: "Shanghai Ace Invest&Dvlp", c: "CH" },
  { id: 17584, s: "2496.TW", n: "Success Prime Co", c: "TW" },
  { id: 17585, s: "001896.SZ", n: "Henan Yuneng Holdings", c: "CH" },
  { id: 17586, s: "603326.SS", n: "Nanjing OLO Home Furnishing", c: "CH" },
  { id: 17587, s: "603332.SS", n: "Suzhou Longjie Special Fiber", c: "CH" },
  { id: 17588, s: "603355.SS", n: "Kingclean Electric", c: "CH" },
  { id: 17589, s: "603289.SS", n: "Tederic Machinery", c: "CH" },
  { id: 17590, s: "603283.SS", n: "Suzhou Secote Precision Electronic Co Class A", c: "CH" },
  { id: 17591, s: "603200.SS", n: "Shanghai Emperor of Cleaning Hi-Tech", c: "CH" },
  { id: 17592, s: "MUSH.V", n: "Good Shroom Co Inc", c: "CA" },
  { id: 17593, s: "MUR.V", n: "Murchison Minerals", c: "CA" },
  { id: 17594, s: "2477.TW", n: "Meiloon Industrial", c: "TW" },
  { id: 17595, s: "603214.SS", n: "Shanghai Aiyingshi", c: "CH" },
  { id: 17596, s: "PPA.AT", n: "Piraeus Port Authority S.A", c: "Greece" },
  { id: 17597, s: "603268.SS", n: "Guangdong Songfa Ceramics", c: "CH" },
  { id: 17598, s: "MTH.V", n: "Mammoth Resources Co", c: "CA" },
  { id: 17599, s: "603218.SS", n: "Riyue Heavy Industry", c: "CH" },
  { id: 17600, s: "603220.SS", n: "CH Bester Group Telecom", c: "CH" },
  { id: 17601, s: "603222.SS", n: "Zhejiang Chimin Pharm", c: "CH" },
  { id: 17602, s: "603223.SS", n: "Hengtong Logistic", c: "CH" },
  { id: 17603, s: "PETRO.AT", n: "Petros Petropoulos AEBE", c: "Greece" },
  { id: 17604, s: "603225.SS", n: "Xinfengming Group", c: "CH" },
  { id: 17605, s: "603226.SS", n: "Vohringer Home Technology", c: "CH" },
  { id: 17606, s: "603227.SS", n: "Xinjiang Xuefeng Sci-Tech", c: "CH" },
  { id: 17607, s: "603228.SS", n: "Shenzhen Kinwong Electronic", c: "CH" },
  { id: 17608, s: "FHW.F", n: "Fernheizwerk Neukölln Aktiengesellschaft", c: "GER" },
  { id: 17609, s: "603278.SS", n: "Shandong Daye Co Class A", c: "CH" },
  { id: 17610, s: "603277.SS", n: "Yindu Kitchen Equipment", c: "CH" },
  { id: 17611, s: "603587.SS", n: "Dazzle Fashion", c: "CH" },
  { id: 17612, s: "603586.SS", n: "Shandong Gold Phoenix", c: "CH" },
  { id: 17613, s: "LTX.V", n: "Labrador Technologies Inc", c: "CA" },
  { id: 17614, s: "603466.SS", n: "Shanghai Fengyuzhu Exhibition Co Class A", c: "CH" },
  { id: 17615, s: "MDX.V", n: "MedX Health Co", c: "CA" },
  { id: 17616, s: "603496.SS", n: "EmbedWay Tech(Shanghai)Co", c: "CH" },
  { id: 17617, s: "002040.SZ", n: "Nanjing Port", c: "CH" },
  { id: 17618, s: "603488.SS", n: "Flying Technology", c: "CH" },
  { id: 17619, s: "2530.TW", n: "Delpha Construction", c: "TW" },
  { id: 17620, s: "603416.SS", n: "WuXi Xinje Electric", c: "CH" },
  { id: 17621, s: "2527.TW", n: "Hung Ching Development & Construction", c: "TW" },
  { id: 17622, s: "603456.SS", n: "Zhejiang Jiuzhou Pharm", c: "CH" },
  { id: 17623, s: "002038.SZ", n: "Beijing SL Pharmaceutical", c: "CH" },
  { id: 17624, s: "002032.SZ", n: "Zhejiang Supor", c: "CH" },
  { id: 17625, s: "MENE.V", n: "Mene Inc", c: "CA" },
  { id: 17626, s: "603429.SS", n: "Anhui Genuine New Materials", c: "CH" },
  { id: 17627, s: "603421.SS", n: "Qingdao Topscomm Commun Inc", c: "CH" },
  { id: 17628, s: "603589.SS", n: "Anhui Kouzi Distillery", c: "CH" },
  { id: 17629, s: "8021.TW", n: "Topoint Technology", c: "TW" },
  { id: 17630, s: "603198.SS", n: "Anhui Yingjia Distillery", c: "CH" },
  { id: 17631, s: "603196.SS", n: "Ribo Fashion Group", c: "CH" },
  { id: 17632, s: "603023.SS", n: "Harbin VITI Electronics Co", c: "CH" },
  { id: 17633, s: "603025.SS", n: "BeiJing DaHao Technology Co Lt", c: "CH" },
  { id: 17634, s: "603026.SS", n: "Shandong Shida Shenghua Chem", c: "CH" },
  { id: 17635, s: "DEF.DE", n: "Defama Deutsche Fachmarkt AG", c: "GER" },
  { id: 17636, s: "603028.SS", n: "Jiangsu Safety Wire Rope", c: "CH" },
  { id: 17637, s: "ALKIM.IS", n: "Alkim Alkali Kimya AS", c: "Turkey" },
  { id: 17638, s: "603030.SS", n: "Shanghai Trendzone Decor Grp", c: "CH" },
  { id: 17639, s: "603031.SS", n: "Anhui Andeli Department Store", c: "CH" },
  { id: 17640, s: "2419.TW", n: "Hitron Technologies Inc", c: "TW" },
  { id: 17641, s: "HBD1.F", n: "Omiris AG", c: "GER" },
  { id: 17642, s: "AOXG", n: "Aoxing Pharmaceutical Company Inc", c: "US" },
  { id: 17643, s: "EDD3.F", n: "edding AG", c: "GER" },
  { id: 17644, s: "8039.TW", n: "Taiflex Scientific", c: "TW" },
  { id: 17645, s: "603583.SS", n: "Zhejiang Jiecang Linear Motion Technology", c: "CH" },
  { id: 17646, s: "603398.SS", n: "Guangdong Banbao Toy", c: "CH" },
  { id: 17647, s: "603580.SS", n: "AA Industrial Belting", c: "CH" },
  { id: 17648, s: "603515.SS", n: "Opple Lighting", c: "CH" },
  { id: 17649, s: "603579.SS", n: "Shanghai Rongtai Health Tech", c: "CH" },
  { id: 17650, s: "603577.SS", n: "Qingdao Huijintong Power", c: "CH" },
  { id: 17651, s: "603569.SS", n: "Beijing Changjiu Logistics", c: "CH" },
  { id: 17652, s: "603567.SS", n: "Heilongjiang ZBD Pharmaceutical", c: "CH" },
  { id: 17653, s: "603566.SS", n: "Pulike Biological Engineering", c: "CH" },
  { id: 17654, s: "603559.SS", n: "Zhongtong Guomai Communication", c: "CH" },
  { id: 17655, s: "603558.SS", n: "Zhejiang Jasan Holding Group", c: "CH" },
  { id: 17656, s: "603578.SS", n: "Three Stars NM", c: "CH" },
  { id: 17657, s: "603508.SS", n: "Henan Thinker Automation", c: "CH" },
  { id: 17658, s: "603507.SS", n: "Jiangsu Zhenjiang New Energy Equipment Co Class A", c: "CH" },
  { id: 17659, s: "603506.SS", n: "Nacity Property Service", c: "CH" },
  { id: 17660, s: "002627.SZ", n: "Hubeiyichang Transportation Group", c: "CH" },
  { id: 17661, s: "603199.SS", n: "Anhui Jiuhuashan Tourism Dvlp", c: "CH" },
  { id: 17662, s: "603389.SS", n: "A-Zenith Home Furnishings", c: "CH" },
  { id: 17663, s: "603388.SS", n: "Zhejiang Yuancheng Landscape", c: "CH" },
  { id: 17664, s: "603385.SS", n: "Huida Sanitary Ware", c: "CH" },
  { id: 17665, s: "2509.TW", n: "Chainqui Construction Development", c: "TW" },
  { id: 17666, s: "S9I.DE", n: "Stemmer Imaging AG", c: "GER" },
  { id: 17667, s: "603378.SS", n: "Asia Cuanon Tech (Shanghai)", c: "CH" },
  { id: 17668, s: "603368.SS", n: "Guangxi Liuzhou Pharm", c: "CH" },
  { id: 17669, s: "603367.SS", n: "Cisen Pharmaceutical", c: "CH" },
  { id: 17670, s: "603365.SS", n: "Shanghai Shuixing Home Textile Co Class A", c: "CH" },
  { id: 17671, s: "603363.SS", n: "Fujian Aonong Biological Technology Group Incorporation", c: "CH" },
  { id: 17672, s: "MJS.V", n: "Majestic Gold Corp.", c: "CA" },
  { id: 17673, s: "2505.TW", n: "Kuo Yang Construction", c: "TW" },
  { id: 17674, s: "603377.SS", n: "Eastern Pioneer Auto Driving S", c: "CH" },
  { id: 17675, s: "603330.SS", n: "Tianyang Hot Mel", c: "CH" },
  { id: 17676, s: "603333.SS", n: "Sunway", c: "CH" },
  { id: 17677, s: "603038.SS", n: "Huali Industries", c: "CH" },
  { id: 17678, s: "NIIN.DE", n: "niiio finance group AG", c: "GER" },
  { id: 17679, s: "SAL.L", n: "SpaceandPeople Plc", c: "UK" },
  { id: 17680, s: "MAR.LS", n: "Martifer SGPS S.A", c: "Portugal" },
  { id: 17681, s: "SRES.L", n: "Sunrise Resources Plc", c: "UK" },
  { id: 17682, s: "RUR.L", n: "Rurelec", c: "UK" },
  { id: 17683, s: "SST.L", n: "The Scottish Oriental Smaller Companies Trust plc", c: "UK" },
  { id: 17684, s: "SUH.L", n: "Sutton Harbour Holdings plc", c: "UK" },
  { id: 17685, s: "008870.KS", n: "Kumbi Corporation", c: "Korea" },
  { id: 17686, s: "TIGT.L", n: "Troy Income & Growth Trust Plc", c: "UK" },
  { id: 17687, s: "TIDE.L", n: "Crimson Tide plc", c: "UK" },
  { id: 17688, s: "THRU.L", n: "Thruvision Group PLC", c: "UK" },
  { id: 17689, s: "008700.KS", n: "Anam Electroni", c: "Korea" },
  { id: 17690, s: "DKDCA", n: "Data Knights Acquisition Co", c: "US" },
  { id: 17691, s: "TGR.L", n: "Tirupati Graphite plc", c: "UK" },
  { id: 17692, s: "OPST", n: "Opt-Sciences Co", c: "US" },
  { id: 17693, s: "TAST.L", n: "Tasty Plc", c: "UK" },
  { id: 17694, s: "LECN.SW", n: "Leclanche SA", c: "Switzerland" },
  { id: 17695, s: "TAM.L", n: "Tatton Asset Management plc", c: "UK" },
  { id: 17696, s: "AHK.AX", n: "Ark Mines Limited", c: "Australia" },
  { id: 17697, s: "SYM.L", n: "Symphony Environmental Technologies plc", c: "UK" },
  { id: 17698, s: "SVM.L", n: "SVM UK Emerging Fund plc", c: "UK" },
  { id: 17699, s: "007690.KS", n: "Kukdo Chemical", c: "Korea" },
  { id: 17700, s: "UNXLQ", n: "Uni-Pixel Inc", c: "US" },
  { id: 17701, s: "AHL.AX", n: "Adrad Holdings", c: "Australia" },
  { id: 17702, s: "STB.L", n: "Secure Trust Bank PLC", c: "UK" },
  { id: 17703, s: "RST.L", n: "Restore plc", c: "UK" },
  { id: 17704, s: "OASMY", n: "Oasmia Pharmaceutical AB", c: "US" },
  { id: 17705, s: "NZERF", n: "New Zealand Energy Co", c: "US" },
  { id: 17706, s: "PFD.L", n: "Premier Foods PLC", c: "UK" },
  { id: 17707, s: "PEN.L", n: "Pennant International Group plc", c: "UK" },
  { id: 17708, s: "ALM.AX", n: "Alma Metals", c: "Australia" },
  { id: 17709, s: "SAR.L", n: "Sareum Hldgs Plc", c: "UK" },
  { id: 17710, s: "SBDS.L", n: "Silver Bullet Data Services Group PLC", c: "UK" },
  { id: 17711, s: "BRX.AX", n: "Belararox", c: "Australia" },
  { id: 17712, s: "ABO.F", n: "CLEARVISE AG INH O.N.", c: "GER" },
  { id: 17713, s: "000860.KS", n: "Kangnam Jevisco", c: "Korea" },
  { id: 17714, s: "FSPORT.ST", n: "Fsport AB", c: "Sweden" },
  { id: 17715, s: "NBRI", n: "North Bay Resources", c: "US" },
  { id: 17716, s: "000810.KS", n: "Samsung Fire & Marine Insurance", c: "Korea" },
  { id: 17717, s: "XRG.AX", n: "xReality Group", c: "Australia" },
  { id: 17718, s: "GHUS-B.ST", n: "Götenehus Group AB (publ)", c: "Sweden" },
  { id: 17719, s: "000590.KS", n: "Cs Holdings", c: "Korea" },
  { id: 17720, s: "MYRX", n: "Myrexis Inc Cmn Stk", c: "US" },
  { id: 17721, s: "WZR.AX", n: "WISR", c: "Australia" },
  { id: 17722, s: "WYX.AX", n: "Western Yilgarn NL", c: "Australia" },
  { id: 17723, s: "000490.KS", n: "Daedong Ind", c: "Korea" },
  { id: 17724, s: "MGCI.L", n: "M&G Credit Income Investment Trust PLC", c: "UK" },
  { id: 17725, s: "000230.KS", n: "Ildong Holdings", c: "Korea" },
  { id: 17726, s: "006340.KS", n: "Daewon Cable", c: "Korea" },
  { id: 17727, s: "003410.KS", n: "Ssangyong Cement Industrial Co.", c: "Korea" },
  { id: 17728, s: "ORCH.L", n: "Orchard Funding Group PLC", c: "UK" },
  { id: 17729, s: "BEGR.ST", n: "BE Group AB", c: "Sweden" },
  { id: 17730, s: "006890.KS", n: "Taekyung Chem", c: "Korea" },
  { id: 17731, s: "SOU.L", n: "Sound Energy PLC", c: "UK" },
  { id: 17732, s: "006740.KS", n: "Young Poong Pa", c: "Korea" },
  { id: 17733, s: "SMV.L", n: "Smoove PLC", c: "UK" },
  { id: 17734, s: "006570.KS", n: "Daelim Trading", c: "Korea" },
  { id: 17735, s: "UOVEY", n: "United Overseas Bank ADR", c: "US" },
  { id: 17736, s: "006380.KS", n: "Capro Corporation", c: "Korea" },
  { id: 17737, s: "SIS.L", n: "Science in Sport plc", c: "UK" },
  { id: 17738, s: "SFR.L", n: "Severfield PLC", c: "UK" },
  { id: 17739, s: "SEEN.L", n: "SEEEN PLC", c: "UK" },
  { id: 17740, s: "004150.KS", n: "Hansol Holdings", c: "Korea" },
  { id: 17741, s: "ALIG.ST", n: "Alimak Hek Group AB", c: "Sweden" },
  { id: 17742, s: "PEBB.L", n: "The Pebble Group PLC", c: "UK" },
  { id: 17743, s: "603309.SS", n: "Well Lead Med", c: "CH" },
  { id: 17744, s: "ACE.ST", n: "Ascelia Pharma AB (publ)", c: "Sweden" },
  { id: 17745, s: "005180.KS", n: "Binggrae", c: "Korea" },
  { id: 17746, s: "600637.SS", n: "Shanghai Oriental Pearl Media", c: "CH" },
  { id: 17747, s: "019540.KQ", n: "Ilji Technology Co.", c: "Korea" },
  { id: 17748, s: "600639.SS", n: "Shanghai Jinqiao Export Processing Zone Development Co A", c: "CH" },
  { id: 17749, s: "603297.SS", n: "Ningbo Yongxin Optics", c: "CH" },
  { id: 17750, s: "603299.SS", n: "Jingsu Jingshen Salt&Chem", c: "CH" },
  { id: 17751, s: "603300.SS", n: "Zhejiang Huatie Constr Safety", c: "CH" },
  { id: 17752, s: "603301.SS", n: "Zhende Medical", c: "CH" },
  { id: 17753, s: "603303.SS", n: "Hengdian Group Tospo Lighting", c: "CH" },
  { id: 17754, s: "603305.SS", n: "Ningbo Xusheng Auto Technology", c: "CH" },
  { id: 17755, s: "603306.SS", n: "HMT(Xiamen)New Technical", c: "CH" },
  { id: 17756, s: "603308.SS", n: "Anhui Yingliu", c: "CH" },
  { id: 17757, s: "603311.SS", n: "Zhejiang Goldensea Envi Tech C", c: "CH" },
  { id: 17758, s: "POLX.L", n: "Polarean Imaging Plc", c: "UK" },
  { id: 17759, s: "603313.SS", n: "Healthcare", c: "CH" },
  { id: 17760, s: "603315.SS", n: "Liaoning Fu-An", c: "CH" },
  { id: 17761, s: "001696.SZ", n: "Chongqing Zongshen Power Machinery", c: "CH" },
  { id: 17762, s: "603318.SS", n: "Shuifa Energas Gas", c: "CH" },
  { id: 17763, s: "603319.SS", n: "Hunan Oil Pump", c: "CH" },
  { id: 17764, s: "603321.SS", n: "Zhejiang Meilun Elevator", c: "CH" },
  { id: 17765, s: "002635.SZ", n: "Suzhou Anjie Technology", c: "CH" },
  { id: 17766, s: "MMN.V", n: "Monarca Minerals Inc", c: "CA" },
  { id: 17767, s: "603348.SS", n: "Guangdong Wencan Die Casting", c: "CH" },
  { id: 17768, s: "8201.TW", n: "Inventec Besta", c: "TW" },
  { id: 17769, s: "603339.SS", n: "Nantong Square Cold Chain Equipment", c: "CH" },
  { id: 17770, s: "603336.SS", n: "Great-Sun Foods", c: "CH" },
  { id: 17771, s: "LOGISMOS.AT", n: "Logismos Information Systems S.A", c: "Greece" },
  { id: 17772, s: "PMGR.L", n: "Portmeirion Group", c: "UK" },
  { id: 17773, s: "004890.KS", n: "Dongil Ind", c: "Korea" },
  { id: 17774, s: "NTIOF", n: "National Bank of CA", c: "US" },
  { id: 17775, s: "AEGIR.ST", n: "Aegirbio AB", c: "Sweden" },
  { id: 17776, s: "PEB.L", n: "Pebble Beach Systems Group PLC", c: "UK" },
  { id: 17777, s: "PCTN.L", n: "Picton Property Income", c: "UK" },
  { id: 17778, s: "PCIP.L", n: "PCI-PAL PLC", c: "UK" },
  { id: 17779, s: "PBEE.L", n: "Pensionbee Group PLC", c: "UK" },
  { id: 17780, s: "ATA.AX", n: "Atturra", c: "Australia" },
  { id: 17781, s: "PAC.L", n: "Pacific Assets Trust plc", c: "UK" },
  { id: 17782, s: "NPSKY", n: "NSK ADR", c: "US" },
  { id: 17783, s: "OTV2.L", n: "Octopus Titan VCT PLC", c: "UK" },
  { id: 17784, s: "BAWAT.ST", n: "Bawat Water Technologies AB", c: "Sweden" },
  { id: 17785, s: "BEAMMW-B.ST", n: "Beammwave AB B", c: "Sweden" },
  { id: 17786, s: "AUE.AX", n: "Aurum Resources", c: "Australia" },
  { id: 17787, s: "NORD3.SA", n: "Nordon Indústrias Metalúrgicas S/A", c: "Brazil" },
  { id: 17788, s: "PGOO.L", n: "Proven Growth and Income Vct PLC", c: "UK" },
  { id: 17789, s: "ACTI.ST", n: "Active Biotech AB", c: "Sweden" },
  { id: 17790, s: "PRTC.L", n: "PureTech Health plc", c: "UK" },
  { id: 17791, s: "005750.KS", n: "Daelim B&", c: "Korea" },
  { id: 17792, s: "RNWH.L", n: "Renew Holdings plc", c: "UK" },
  { id: 17793, s: "RNO.L", n: "Renold", c: "UK" },
  { id: 17794, s: "005610.KS", n: "SPC Samlip", c: "Korea" },
  { id: 17795, s: "URCFF", n: "Vanadian Energy Co", c: "US" },
  { id: 17796, s: "RENX.L", n: "Renalytix AI plc", c: "UK" },
  { id: 17797, s: "BBED.AS", n: "Beter Bed Holding N.V", c: "Netherlands" },
  { id: 17798, s: "DGB.AS", n: "DGB Group NV", c: "Netherlands" },
  { id: 17799, s: "TON.L", n: "Titon Holdings Plc", c: "UK" },
  { id: 17800, s: "EAS2P.AS", n: "Ease2pay NV", c: "Netherlands" },
  { id: 17801, s: "ENVI.AS", n: "Envipco Holding NV", c: "Netherlands" },
  { id: 17802, s: "RBN.L", n: "Robinson plc", c: "UK" },
  { id: 17803, s: "PXEN.L", n: "Prospex Energy PLC", c: "UK" },
  { id: 17804, s: "603035.SS", n: "Changshu Automotive Trim", c: "CH" },
  { id: 17805, s: "005940.KS", n: "NH Investment & Securities", c: "Korea" },
  { id: 17806, s: "603039.SS", n: "Shanghai Weaver Network", c: "CH" },
  { id: 17807, s: "603976.SS", n: "ChongQing Zhengchuan Pharmaceutical Packaging", c: "CH" },
  { id: 17808, s: "002172.SZ", n: "Jiangsu Aoyang Technology", c: "CH" },
  { id: 17809, s: "603916.SS", n: "Sobute New Materials", c: "CH" },
  { id: 17810, s: "688786.SS", n: "Jiangxi Yuean Advanced Materials", c: "CH" },
  { id: 17811, s: "603917.SS", n: "Ningbo Heli Mould Technology Co Class A", c: "CH" },
  { id: 17812, s: "002174.SZ", n: "YOUZU Interactive", c: "CH" },
  { id: 17813, s: "603920.SS", n: "Olympic Circuit Technology", c: "CH" },
  { id: 17814, s: "603922.SS", n: "Suzhou Jin Hong Shun Auto Parts Co Class A", c: "CH" },
  { id: 17815, s: "603926.SS", n: "Zhejiang Tieliu Clutch", c: "CH" },
  { id: 17816, s: "PTOP", n: "Peer To Peer Network", c: "US" },
  { id: 17817, s: "603927.SS", n: "Sinosoft", c: "CH" },
  { id: 17818, s: "603928.SS", n: "Suzhou Xingye Material Tech", c: "CH" },
  { id: 17819, s: "688718.SS", n: "Wave Cyber Shanghai", c: "CH" },
  { id: 17820, s: "603931.SS", n: "Hangzhou Greenda Electronic Materials", c: "CH" },
  { id: 17821, s: "603933.SS", n: "Fujian Raynen Tech", c: "CH" },
  { id: 17822, s: "603936.SS", n: "Bomin Electronics", c: "CH" },
  { id: 17823, s: "603937.SS", n: "Jiangsu Lidao New Material Co Class A", c: "CH" },
  { id: 17824, s: "603967.SS", n: "CH Master Logistics", c: "CH" },
  { id: 17825, s: "688607.SS", n: "CareRay Digital Medical Technology", c: "CH" },
  { id: 17826, s: "SRT.DU", n: "Sartorius Aktiengesellschaft", c: "GER" },
  { id: 17827, s: "603960.SS", n: "Shanghai Kelai Mechatronics", c: "CH" },
  { id: 17828, s: "4976.TW", n: "Calin Technology", c: "TW" },
  { id: 17829, s: "603968.SS", n: "Nantong Acetic Acid Chemical", c: "CH" },
  { id: 17830, s: "4994.TW", n: "X-Legend Entertainment", c: "TW" },
  { id: 17831, s: "603956.SS", n: "WPG (Shanghai) Smart Water Public Co.", c: "CH" },
  { id: 17832, s: "603955.SS", n: "Daqian Ecology & Landscape", c: "CH" },
  { id: 17833, s: "5258.TW", n: "Castles Technology", c: "TW" },
  { id: 17834, s: "PTOI", n: "Plastic2Oil Inc", c: "US" },
  { id: 17835, s: "002579.SZ", n: "Huizhou CH Eagle Electronic Technology", c: "CH" },
  { id: 17836, s: "603939.SS", n: "Yifeng Pharmacy", c: "CH" },
  { id: 17837, s: "603909.SS", n: "Holsin Engineering Consulting", c: "CH" },
  { id: 17838, s: "002207.SZ", n: "Xinjiang Zhundong Petroleum", c: "CH" },
  { id: 17839, s: "603969.SS", n: "Tianjin Silvery Dragon", c: "CH" },
  { id: 17840, s: "4956.TW", n: "Epileds Technologies Inc", c: "TW" },
  { id: 17841, s: "603977.SS", n: "Jiangxi Guotai Civilian", c: "CH" },
  { id: 17842, s: "603978.SS", n: "Shenzhen Sunxing Light Alloys", c: "CH" },
  { id: 17843, s: "603979.SS", n: "JCHX Mining Management", c: "CH" },
  { id: 17844, s: "603980.SS", n: "Zhejiang Jihua Group", c: "CH" },
  { id: 17845, s: "SSS.DU", n: "Schulte-Schlagbaum AG", c: "GER" },
  { id: 17846, s: "603983.SS", n: "Guangdong Marubi Biotechnology", c: "CH" },
  { id: 17847, s: "KGS.V", n: "Kingman Minerals", c: "CA" },
  { id: 17848, s: "688595.SS", n: "Chipsea Technologies Shenzhen Co", c: "CH" },
  { id: 17849, s: "002225.SZ", n: "Puyang Refractories Group", c: "CH" },
  { id: 17850, s: "603987.SS", n: "Shanghai Kindly Entrp Dvlp Grp", c: "CH" },
  { id: 17851, s: "688580.SS", n: "Nanjing Vishee Medical Technology", c: "CH" },
  { id: 17852, s: "688577.SS", n: "Zhe Jiang Headman Machinery", c: "CH" },
  { id: 17853, s: "600638.SS", n: "Shanghai New Huang Pu Real Estate", c: "CH" },
  { id: 17854, s: "002265.SZ", n: "Yunnan Xiyi Industrial", c: "CH" },
  { id: 17855, s: "A8N.MU", n: "aovo Touristik AG", c: "GER" },
  { id: 17856, s: "605009.SS", n: "Hangzhou Haoyue Personal Care", c: "CH" },
  { id: 17857, s: "002573.SZ", n: "Beijing SPC Environment Protection Tech", c: "CH" },
  { id: 17858, s: "002577.SZ", n: "Shenzhen Rapoo Technology", c: "CH" },
  { id: 17859, s: "8TRA.MU", n: "Traton SE", c: "GER" },
  { id: 17860, s: "603970.SS", n: "Sino-Argi Leading Biosciences", c: "CH" },
  { id: 17861, s: "603998.SS", n: "Hunan Fangsheng Pharm", c: "CH" },
  { id: 17862, s: "603997.SS", n: "Ningbo Jifeng Auto Parts", c: "CH" },
  { id: 17863, s: "603991.SS", n: "Shanghai Originaldow Advanced", c: "CH" },
  { id: 17864, s: "002244.SZ", n: "Hangzhou Binjiang Real Estate Group", c: "CH" },
  { id: 17865, s: "AUKNY", n: "Auckland International Airport ADR", c: "US" },
  { id: 17866, s: "603990.SS", n: "Suzhou Medsys Tech", c: "CH" },
  { id: 17867, s: "603989.SS", n: "Hunan Aihua Group", c: "CH" },
  { id: 17868, s: "688519.SS", n: "Nanya New Material Technology", c: "CH" },
  { id: 17869, s: "603959.SS", n: "Hunan Baili Engineering Sci", c: "CH" },
  { id: 17870, s: "BKGFY", n: "Berkeley Group Holdings PLC ADR", c: "US" },
  { id: 17871, s: "688501.SS", n: "Qingdao Daneng Environmental Protection Equipment", c: "CH" },
  { id: 17872, s: "688398.SS", n: "Fujian SuperTech Advanced Material", c: "CH" },
  { id: 17873, s: "002444.SZ", n: "Hangzhou Great Star Industrial", c: "CH" },
  { id: 17874, s: "688246.SS", n: "Goodwill E Health Info", c: "CH" },
  { id: 17875, s: "002508.SZ", n: "Hangzhou Robam Appliances", c: "CH" },
  { id: 17876, s: "688356.SS", n: "Jenkem Technology", c: "CH" },
  { id: 17877, s: "002517.SZ", n: "Kingnet Network", c: "CH" },
  { id: 17878, s: "688365.SS", n: "Hangzhou Raycloud Technology", c: "CH" },
  { id: 17879, s: "B8F.F", n: "Biofrontera AG", c: "GER" },
  { id: 17880, s: "605158.SS", n: "Zhejiang Huada New Materials", c: "CH" },
  { id: 17881, s: "002506.SZ", n: "GCL System Integration Technology", c: "CH" },
  { id: 17882, s: "002530.SZ", n: "JC Finance & Tax Interconnect Holdings", c: "CH" },
  { id: 17883, s: "002285.SZ", n: "Shenzhen Worldunion Properties Consultancy Inc", c: "CH" },
  { id: 17884, s: "688377.SS", n: "Nanjing Develop Advanced Manufacturing", c: "CH" },
  { id: 17885, s: "PSSS", n: "Puissant Industries Inc", c: "US" },
  { id: 17886, s: "4555.TW", n: "Taiwan Chelic Co", c: "TW" },
  { id: 17887, s: "3060.TW", n: "Min Aik Technology", c: "TW" },
  { id: 17888, s: "605118.SS", n: "Xiamen Leading Optics", c: "CH" },
  { id: 17889, s: "603906.SS", n: "Jiangsu Lopal Tech", c: "CH" },
  { id: 17890, s: "FPE3.MU", n: "Fuchs Petrolub SE", c: "GER" },
  { id: 17891, s: "ALV.F", n: "Allianz SE", c: "GER" },
  { id: 17892, s: "002334.SZ", n: "Shenzhen INVT Electric", c: "CH" },
  { id: 17893, s: "002333.SZ", n: "ZYF Lopsking Aluminum", c: "CH" },
  { id: 17894, s: "605598.SS", n: "Shanghai Geoharbour Construction Group", c: "CH" },
  { id: 17895, s: "002321.SZ", n: "Henan Huaying Agricultural Development", c: "CH" },
  { id: 17896, s: "002469.SZ", n: "Shandong Sunway Petrochemical Engineering", c: "CH" },
  { id: 17897, s: "3002.TW", n: "Ahoku Electronic", c: "TW" },
  { id: 17898, s: "BDVSY", n: "Bidvest Group PK", c: "US" },
  { id: 17899, s: "603041.SS", n: "Jiangsu Maysta Chemical", c: "CH" },
  { id: 17900, s: "002472.SZ", n: "Zhejiang Shuanghuan Driveline", c: "CH" },
  { id: 17901, s: "75S.F", n: "SDM SE INH O.N.", c: "GER" },
  { id: 17902, s: "3050.TW", n: "U-Tech Media Co", c: "TW" },
  { id: 17903, s: "688488.SS", n: "Jiangsu Aidea Pharmaceutical", c: "CH" },
  { id: 17904, s: "SAXJY", n: "Sa Sa International Holdings ADR", c: "US" },
  { id: 17905, s: "002338.SZ", n: "Changchun UP Optotech", c: "CH" },
  { id: 17906, s: "688017.SS", n: "Leader Harmonious Drive Systems", c: "CH" },
  { id: 17907, s: "002341.SZ", n: "Xinlun New Materials", c: "CH" },
  { id: 17908, s: "688229.SS", n: "Bonree Data Technology", c: "CH" },
  { id: 17909, s: "3588.TW", n: "Leadtrend Technology Co", c: "TW" },
  { id: 17910, s: "002427.SZ", n: "Zhejiang Unifull Industrial Fibre", c: "CH" },
  { id: 17911, s: "SCHYY", n: "Sands CH ADR", c: "US" },
  { id: 17912, s: "3557.TW", n: "Jia Wei Lifestyle Inc", c: "TW" },
  { id: 17913, s: "1U1.F", n: "1&1 AG", c: "GER" },
  { id: 17914, s: "688199.SS", n: "Tianjin Jiuri New Materials ", c: "CH" },
  { id: 17915, s: "14D.F", n: "tokentus investment AG", c: "GER" },
  { id: 17916, s: "002351.SZ", n: "Edifier Technology", c: "CH" },
  { id: 17917, s: "688033.SS", n: "Beijing Tianyishangjia New Material Co", c: "CH" },
  { id: 17918, s: "002409.SZ", n: "Jiangsu Yoke Technology", c: "CH" },
  { id: 17919, s: "002367.SZ", n: "Canny Elevator", c: "CH" },
  { id: 17920, s: "002354.SZ", n: "Dalian Zeus Entertainment", c: "CH" },
  { id: 17921, s: "002362.SZ", n: "Hanwang Technology", c: "CH" },
  { id: 17922, s: "002392.SZ", n: "Beijing Lier High Temperature Materials", c: "CH" },
  { id: 17923, s: "3229.TW", n: "Cheer Time Enterprise", c: "TW" },
  { id: 17924, s: "3311.TW", n: "Silitech Technology Co", c: "TW" },
  { id: 17925, s: "688088.SS", n: "ArcSoft Co", c: "CH" },
  { id: 17926, s: "002394.SZ", n: "Jiangsu Lianfa Textile", c: "CH" },
  { id: 17927, s: "3312.TW", n: "G.M.I Technology Inc", c: "TW" },
  { id: 17928, s: "MBG.HA", n: "Mercedes-Benz Group AG", c: "GER" },
  { id: 17929, s: "002404.SZ", n: "Zhejiang Jiaxin Silk Co", c: "CH" },
  { id: 17930, s: "688116.SS", n: "Jiangsu Cnano Technology", c: "CH" },
  { id: 17931, s: "3419.TW", n: "Wha Yu Industrial", c: "TW" },
  { id: 17932, s: "688065.SS", n: "Cathay Biotech Inc", c: "CH" },
  { id: 17933, s: "RVCB", n: "River Valley Community BanCo", c: "US" },
  { id: 17934, s: "688367.SS", n: "Hefei Gocom Information Technology", c: "CH" },
  { id: 17935, s: "603599.SS", n: "Anhui Guangxin Agrochemical", c: "CH" },
  { id: 17936, s: "603161.SS", n: "Kehua Holdings Co Class A", c: "CH" },
  { id: 17937, s: "SHWGY", n: "Shandong Weigao Group Medical Polymer Co ADR", c: "US" },
  { id: 17938, s: "601999.SS", n: "Northern United Publishing & Media Group", c: "CH" },
  { id: 17939, s: "PRG.V", n: "Precipitate Gold Co", c: "CA" },
  { id: 17940, s: "603000.SS", n: "People.Cn", c: "CH" },
  { id: 17941, s: "BOUYY", n: "Bouygues SA ADR", c: "US" },
  { id: 17942, s: "603018.SS", n: "Jiangsu Province Commun", c: "CH" },
  { id: 17943, s: "603017.SS", n: "ARTS Group", c: "CH" },
  { id: 17944, s: "C3RY.DE", n: "Cherry AG", c: "GER" },
  { id: 17945, s: "603016.SS", n: "Wuxi Xinhongtai ElecTech", c: "CH" },
  { id: 17946, s: "002671.SZ", n: "Shandong Longquan Pipeline Engineering", c: "CH" },
  { id: 17947, s: "603013.SS", n: "Yapp Automotive Systems", c: "CH" },
  { id: 17948, s: "CWC.DE", n: "CEWE Stiftung & Co. KGaA", c: "GER" },
  { id: 17949, s: "603012.SS", n: "Shanghai Chuangli Group", c: "CH" },
  { id: 17950, s: "603010.SS", n: "Zhejiang Wansheng", c: "CH" },
  { id: 17951, s: "603008.SS", n: "Xilinmen Furniture", c: "CH" },
  { id: 17952, s: "603007.SS", n: "Jiangsu Flowers King", c: "CH" },
  { id: 17953, s: "POPR.V", n: "PopReach Co", c: "CA" },
  { id: 17954, s: "2413.TW", n: "Universal Microelectronics", c: "TW" },
  { id: 17955, s: "603002.SS", n: "Epoxy Base Electronic Material Co", c: "CH" },
  { id: 17956, s: "603011.SS", n: "Hefei Metalforming Mach Tool", c: "CH" },
  { id: 17957, s: "AKSGY.IS", n: "Akis Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 17958, s: "603085.SS", n: "Zhejiang Tiancheng Control", c: "CH" },
  { id: 17959, s: "603086.SS", n: "Shandong Cynda Chemcial", c: "CH" },
  { id: 17960, s: "603139.SS", n: "Shaanxi Kanghui Pharm", c: "CH" },
  { id: 17961, s: "KSC.DE", n: "Kps AG", c: "GER" },
  { id: 17962, s: "NBR.V", n: "Nubian Resources", c: "CA" },
  { id: 17963, s: "NAU.V", n: "NevGold Co", c: "CA" },
  { id: 17964, s: "603156.SS", n: "Hebei Yangyuan ZhiHui Beverage", c: "CH" },
  { id: 17965, s: "603158.SS", n: "Changzhou Tenglong Auto Parts", c: "CH" },
  { id: 17966, s: "601996.SS", n: "Guangxi Fenglin Wood Industry Group", c: "CH" },
  { id: 17967, s: "PTE.V", n: "Pioneering Technology Co", c: "CA" },
  { id: 17968, s: "HNL.F", n: "Dr. Hönle AG", c: "GER" },
  { id: 17969, s: "603053.SS", n: "Chengdu Gas Group", c: "CH" },
  { id: 17970, s: "603043.SS", n: "Guangzhou Restaurants Group", c: "CH" },
  { id: 17971, s: "603590.SS", n: "Beijing Konruns Pharmaceutical", c: "CH" },
  { id: 17972, s: "603042.SS", n: "Nanjing Huamai Tech", c: "CH" },
  { id: 17973, s: "603080.SS", n: "Xinjiang Torch Gas", c: "CH" },
  { id: 17974, s: "603079.SS", n: "Zhejiang Shengda Pharm", c: "CH" },
  { id: 17975, s: "AKSUE.IS", n: "Aksu Enerji ve Ticaret AS", c: "Turkey" },
  { id: 17976, s: "603078.SS", n: "Jiangyin Jianghua Microelectronics Materials", c: "CH" },
  { id: 17977, s: "603076.SS", n: "Ningbo Lehui International Engineering Equipment Co Class A", c: "CH" },
  { id: 17978, s: "603068.SS", n: "Beken Co", c: "CH" },
  { id: 17979, s: "603022.SS", n: "Shanghai Xintonglian Packaging", c: "CH" },
  { id: 17980, s: "603067.SS", n: "Hubei Zhenhua Chemical", c: "CH" },
  { id: 17981, s: "603060.SS", n: "CH Building Material", c: "CH" },
  { id: 17982, s: "GSC1.F", n: "Gesco AG", c: "GER" },
  { id: 17983, s: "2438.TW", n: "Enlight Co", c: "TW" },
  { id: 17984, s: "000982.SZ", n: "Ningxia Zhongyin Cashmere", c: "CH" },
  { id: 17985, s: "APAJF", n: "APA Group", c: "US" },
  { id: 17986, s: "AKYHO.IS", n: "Akdeniz Yatirim Holding AS", c: "Turkey" },
  { id: 17987, s: "603081.SS", n: "Zhejiang Dafeng Industrial", c: "CH" },
  { id: 17988, s: "603021.SS", n: "Shandong Huapeng Glass", c: "CH" },
  { id: 17989, s: "CRZK.DE", n: "CR Capital AG", c: "GER" },
  { id: 17990, s: "BIOEN.IS", n: "Biotrend Cevre ve Enerji Yatirimlari AS", c: "Turkey" },
  { id: 17991, s: "601975.SS", n: "Nanjing Tanker Co", c: "CH" },
  { id: 17992, s: "ADL.DE", n: "ADLER Real Estate AG", c: "GER" },
  { id: 17993, s: "RDG.V", n: "Ridgeline Minerals Co", c: "CA" },
  { id: 17994, s: "BASGZ.IS", n: "Baskent Dogalgaz Dagitim Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 17995, s: "601990.SS", n: "Nanjing Securities", c: "CH" },
  { id: 17996, s: "ART.DE", n: "artnet AG Namens-Aktien o.N.", c: "GER" },
  { id: 17997, s: "QMC.V", n: "QMC Quantum Minerals Co", c: "CA" },
  { id: 17998, s: "000993.SZ", n: "Fujian MinDong Electric Power", c: "CH" },
  { id: 17999, s: "603066.SS", n: "Nanjing Inform Storage Equip", c: "CH" },
  { id: 18000, s: "603165.SS", n: "Rongsheng Paper", c: "CH" },
  { id: 18001, s: "603131.SS", n: "Shanghai Hugong Elec(Group)", c: "CH" },
  { id: 18002, s: "603096.SS", n: "Thinkingdom Media Group", c: "CH" },
  { id: 18003, s: "603103.SS", n: "Hengdian Entertainment Co Class A", c: "CH" },
  { id: 18004, s: "OMI.V", n: "Orosur Mining Inc", c: "CA" },
  { id: 18005, s: "OPW.V", n: "Opawica Explorations Inc", c: "CA" },
  { id: 18006, s: "AKENR.IS", n: "Akenerji Elektrik Uretim AS", c: "Turkey" },
  { id: 18007, s: "603088.SS", n: "JDM Jingda Mach(Ningbo)", c: "CH" },
  { id: 18008, s: "002663.SZ", n: "Pubang Landscape Architecture", c: "CH" },
  { id: 18009, s: "OTC.V", n: "Ocumetics Technology Co", c: "CA" },
  { id: 18010, s: "PA.V", n: "Palamina Co", c: "CA" },
  { id: 18011, s: "603090.SS", n: "Hongsheng Heat Exchanger Mnfg", c: "CH" },
  { id: 18012, s: "603197.SS", n: "Shanghai Baolong Automotive Co", c: "CH" },
  { id: 18013, s: "OGO.V", n: "Organto Foods Incorporated", c: "CA" },
  { id: 18014, s: "603106.SS", n: "Cashway Technology", c: "CH" },
  { id: 18015, s: "603128.SS", n: "CTS International Logistics Co", c: "CH" },
  { id: 18016, s: "AGYO.IS", n: "Atakule Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 18017, s: "603127.SS", n: "Joinn Laboratories(CH)", c: "CH" },
  { id: 18018, s: "603126.SS", n: "Sinoma Energy Conservation", c: "CH" },
  { id: 18019, s: "603123.SS", n: "Beijing Cuiwei Tower", c: "CH" },
  { id: 18020, s: "NMI.V", n: "Namibia Critical Metals Inc", c: "CA" },
  { id: 18021, s: "GGS.F", n: "Gigaset AG", c: "GER" },
  { id: 18022, s: "603118.SS", n: "Shenzhen Gongjin Electn", c: "CH" },
  { id: 18023, s: "HNL.DE", n: "Dr. Hönle AG", c: "GER" },
  { id: 18024, s: "603111.SS", n: "Nanjing Kangni Mechanical & Electrical", c: "CH" },
  { id: 18025, s: "603110.SS", n: "New East New Materials", c: "CH" },
  { id: 18026, s: "ACSEL.IS", n: "Aciselsan Acipayam Seluloz Sanayi Ve Ticaret AS", c: "Turkey" },
  { id: 18027, s: "KALO.V", n: "Kalo Gold Co", c: "CA" },
  { id: 18028, s: "ADEL.IS", n: "Adel Kalemcilik Ticaret ve Sanayi AS", c: "Turkey" },
  { id: 18029, s: "603117.SS", n: "Jiangsu Wanlin Modern Logistic", c: "CH" },
  { id: 18030, s: "603098.SS", n: "Center International Group", c: "CH" },
  { id: 18031, s: "603116.SS", n: "Zhejiang Red Dragonfly Footwear", c: "CH" },
  { id: 18032, s: "603099.SS", n: "Changbai Mountain Tourism", c: "CH" },
  { id: 18033, s: "NEWU.V", n: "Newtopia Inc", c: "CA" },
  { id: 18034, s: "603100.SS", n: "Chongqing Chuanyi Automation", c: "CH" },
  { id: 18035, s: "000995.SZ", n: "Gansu Huangtai Wine-marketing Industry Co..", c: "CH" },
  { id: 18036, s: "603166.SS", n: "Guilin Fuda", c: "CH" },
  { id: 18037, s: "603168.SS", n: "Zhejiang Shapuaisi Pharmaceutical", c: "CH" },
  { id: 18038, s: "000996.SZ", n: "CH CIFCO Investment", c: "CH" },
  { id: 18039, s: "603177.SS", n: "Zhejiang Tuna Environmental Science & Technology", c: "CH" },
  { id: 18040, s: "MYID.V", n: "Reklaim", c: "CA" },
  { id: 18041, s: "002652.SZ", n: "Suzhou Yangtze New Materials", c: "CH" },
  { id: 18042, s: "001206.SZ", n: "Tianjin Yiyi Hygiene Products", c: "CH" },
  { id: 18043, s: "603189.SS", n: "Shanghai Wondertek Software", c: "CH" },
  { id: 18044, s: "603188.SS", n: "Jiangsu Yabang Dyestuff", c: "CH" },
  { id: 18045, s: "603187.SS", n: "Qingdao Hiron Commercial Cold Chain", c: "CH" },
  { id: 18046, s: "000999.SZ", n: "CH Resources Sanjiu Medical & Pharmaceutical", c: "CH" },
  { id: 18047, s: "LSX.DE", n: "LS telcom AG", c: "GER" },
  { id: 18048, s: "SIDMA.AT", n: "Sidma S.A. Steel Products", c: "Greece" },
  { id: 18049, s: "603178.SS", n: "Ningbo Shenglong Automotive Powertrain System", c: "CH" },
  { id: 18050, s: "603101.SS", n: "Xinjiang Winka Times Dept", c: "CH" },
  { id: 18051, s: "TATT.AT", n: "Attica Bank S.A.", c: "Greece" },
  { id: 18052, s: "603133.SS", n: "Tanyuan Technology", c: "CH" },
  { id: 18053, s: "603136.SS", n: "Jiangsu Tianmu Lake Tourism", c: "CH" },
  { id: 18054, s: "603138.SS", n: "Beijing Vastdata Technology", c: "CH" },
  { id: 18055, s: "000997.SZ", n: "Fujian Newland Computer", c: "CH" },
  { id: 18056, s: "603167.SS", n: "Bohai Ferry", c: "CH" },
  { id: 18057, s: "603181.SS", n: "Zhejiang Huangma Technology", c: "CH" },
  { id: 18058, s: "603183.SS", n: "Suzhou Institute of Building Science Group", c: "CH" },
  { id: 18059, s: "2468.TW", n: "Fortune Information Systems Co", c: "TW" },
  { id: 18060, s: "603180.SS", n: "Xiamen Goldenhome", c: "CH" },
  { id: 18061, s: "033130.KQ", n: "DIGITAL CHOSUN Inc", c: "Korea" },
  { id: 18062, s: "054930.KQ", n: "Yooshin Engineering Corporation", c: "Korea" },
  { id: 18063, s: "JAM.L", n: "JPMorgan American Investment Trust", c: "UK" },
  { id: 18064, s: "MCB.L", n: "McBride plc", c: "UK" },
  { id: 18065, s: "HUSQ-A.ST", n: "Husqvarna AB (publ)", c: "Sweden" },
  { id: 18066, s: "ICO.ST", n: "Iconovo", c: "Sweden" },
  { id: 18067, s: "063080.KQ", n: "GAMEVIL Inc", c: "Korea" },
  { id: 18068, s: "060720.KQ", n: "KHVATEC Co.Ltd", c: "Korea" },
  { id: 18069, s: "INDEX.ST", n: "InDex Pharmaceuticals Holding AB", c: "Sweden" },
  { id: 18070, s: "INISS-B.ST", n: "Inission AB (publ)", c: "Sweden" },
  { id: 18071, s: "059210.KQ", n: "Meta Biomed Co.", c: "Korea" },
  { id: 18072, s: "058820.KQ", n: "CMG Pharmaceutical Co.", c: "Korea" },
  { id: 18073, s: "032850.KQ", n: "BIT Computer Co.", c: "Korea" },
  { id: 18074, s: "GUS.L", n: "Gusbourne PLC", c: "UK" },
  { id: 18075, s: "054800.KQ", n: "IDIS Holdings Co.", c: "Korea" },
  { id: 18076, s: "KAN.ST", n: "Kancera AB (publ)", c: "Sweden" },
  { id: 18077, s: "064480.KQ", n: "BRIDGETEC Co", c: "Korea" },
  { id: 18078, s: "066900.KQ", n: "DAP Corporation", c: "Korea" },
  { id: 18079, s: "ITIM.L", n: "Itim Group PLC", c: "UK" },
  { id: 18080, s: "033640.KQ", n: "Nepes Corporation", c: "Korea" },
  { id: 18081, s: "033310.KQ", n: "M2N Co.Ltd", c: "Korea" },
  { id: 18082, s: "033290.KQ", n: "COWELL FASHION Co.Ltd", c: "Korea" },
  { id: 18083, s: "WEGRY", n: "Weir Group PLC", c: "US" },
  { id: 18084, s: "065350.KQ", n: "Shinsung Delta Tech Co.Ltd", c: "Korea" },
  { id: 18085, s: "RATO-A.ST", n: "Ratos AB (publ)", c: "Sweden" },
  { id: 18086, s: "053700.KQ", n: "Sambo Motors Co.", c: "Korea" },
  { id: 18087, s: "HIFA-B.ST", n: "Hifab Group AB Series B", c: "Sweden" },
  { id: 18088, s: "065650.KQ", n: "Medifron DBT Co.", c: "Korea" },
  { id: 18089, s: "049480.KQ", n: "Openbase Inc", c: "Korea" },
  { id: 18090, s: "049430.KQ", n: "Komelon Corporation", c: "Korea" },
  { id: 18091, s: "NEXAM.ST", n: "Nexam Chemical Holding AB", c: "Sweden" },
  { id: 18092, s: "GROC.L", n: "Greenroc Mining PLC", c: "UK" },
  { id: 18093, s: "048530.KQ", n: "iNtRON Biotechnology Inc", c: "Korea" },
  { id: 18094, s: "NGENIC.ST", n: "Ngenic AB", c: "Sweden" },
  { id: 18095, s: "047080.KQ", n: "HANBIT SOFT Inc", c: "Korea" },
  { id: 18096, s: "046120.KQ", n: "Orbitech Co.", c: "Korea" },
  { id: 18097, s: "045660.KQ", n: "Atec Co.", c: "Korea" },
  { id: 18098, s: "NP3-PREF.ST", n: "NP3 Fastigheter AB (publ)", c: "Sweden" },
  { id: 18099, s: "045300.KQ", n: "Sungwoo Techron. Co.Ltd", c: "Korea" },
  { id: 18100, s: "OPTI.ST", n: "OptiCept Technologies AB", c: "Sweden" },
  { id: 18101, s: "043610.KQ", n: "Genie Music Corporation", c: "Korea" },
  { id: 18102, s: "043260.KQ", n: "Sungho Electronics Co", c: "Korea" },
  { id: 18103, s: "053980.KQ", n: "Osangjaiel Co.", c: "Korea" },
  { id: 18104, s: "LGRS.L", n: "Loungers PLC", c: "UK" },
  { id: 18105, s: "KLAR.ST", n: "Klaria Pharma Holding AB", c: "Sweden" },
  { id: 18106, s: "HAYPP.ST", n: "Haypp Grp Ab", c: "Sweden" },
  { id: 18107, s: "066670.KQ", n: "Display Tech Co.", c: "Korea" },
  { id: 18108, s: "HDW-B.ST", n: "Hitech & Development Wireless Sweden Holding AB (publ)", c: "Sweden" },
  { id: 18109, s: "HEART.ST", n: "Real Heart", c: "Sweden" },
  { id: 18110, s: "MERC.L", n: "Mercia Technologies PLC", c: "UK" },
  { id: 18111, s: "HEGR.ST", n: "Hedera Group publ AB", c: "Sweden" },
  { id: 18112, s: "065710.KQ", n: "Seoho Electric Co.Ltd", c: "Korea" },
  { id: 18113, s: "HEXI.ST", n: "Hexicon AB", c: "Sweden" },
  { id: 18114, s: "032750.KQ", n: "SAMJIN Co.", c: "Korea" },
  { id: 18115, s: "038540.KQ", n: "Sangsangin Co.", c: "Korea" },
  { id: 18116, s: "GRIO.L", n: "Ground Rents Income Fund PLC", c: "UK" },
  { id: 18117, s: "HWG.L", n: "Harworth Group PLC", c: "UK" },
  { id: 18118, s: "038060.KQ", n: "Lumens Co.", c: "Korea" },
  { id: 18119, s: "STWK.ST", n: "Stockwik Forvaltning AB", c: "Sweden" },
  { id: 18120, s: "037350.KQ", n: "Sungdo Engineering & Construction Co.", c: "Korea" },
  { id: 18121, s: "036890.KQ", n: "JINSUNG T.E.C. Inc", c: "Korea" },
  { id: 18122, s: "036810.KQ", n: "Fine Semitech Co", c: "Korea" },
  { id: 18123, s: "036640.KQ", n: "HRS Co.", c: "Korea" },
  { id: 18124, s: "036620.KQ", n: "GAMSUNG Corporation Co.", c: "Korea" },
  { id: 18125, s: "TESSIN.ST", n: "Tessin Nordic Holding AB", c: "Sweden" },
  { id: 18126, s: "036190.KQ", n: "Geumhwa Plant Service & Construction Co.", c: "Korea" },
  { id: 18127, s: "TOL.ST", n: "Toleranzia AB", c: "Sweden" },
  { id: 18128, s: "TRAC-B.ST", n: "AB Traction", c: "Sweden" },
  { id: 18129, s: "1U1.HA", n: "1&1 AG", c: "GER" },
  { id: 18130, s: "ICGT.L", n: "ICG Enterprise Trust PLC", c: "UK" },
  { id: 18131, s: "001550.KS", n: "Cho Bi", c: "Korea" },
  { id: 18132, s: "001755.KS", n: "Hanyang Securities", c: "Korea" },
  { id: 18133, s: "ALG.WA", n: "All In! Games S.A.", c: "Poland" },
  { id: 18134, s: "AGO.WA", n: "Agora S.A.", c: "Poland" },
  { id: 18135, s: "OSP2.F", n: "USU Software AG", c: "GER" },
  { id: 18136, s: "PAH3.F", n: "Porsche Automobil Holding SE", c: "GER" },
  { id: 18137, s: "PAT.F", n: "Patrizia AG", c: "GER" },
  { id: 18138, s: "PDA.F", n: "PRO DV AG", c: "GER" },
  { id: 18139, s: "049960.KQ", n: "Cell Biotech Co.", c: "Korea" },
  { id: 18140, s: "PEH.F", n: "PEH Wertpapier AG", c: "GER" },
  { id: 18141, s: "PFV.F", n: "Pfeiffer Vacuum Technology AG", c: "GER" },
  { id: 18142, s: "SAGA-D.ST", n: "AB Sagax (publ)", c: "Sweden" },
  { id: 18143, s: "PIT.F", n: "Pittler Maschinenfabrik AG", c: "GER" },
  { id: 18144, s: "ZAPLOX.ST", n: "Zaplox AB", c: "Sweden" },
  { id: 18145, s: "024740.KQ", n: "Hanil Forging Industrial Co.", c: "Korea" },
  { id: 18146, s: "031510.KQ", n: "Austem Company", c: "Korea" },
  { id: 18147, s: "030350.KQ", n: "Dragonfly GF Co.", c: "Korea" },
  { id: 18148, s: "029480.KQ", n: "Lix Solution Co.Ltd", c: "Korea" },
  { id: 18149, s: "028300.KQ", n: "HLB Co.", c: "Korea" },
  { id: 18150, s: "027360.KQ", n: "Aju IB Investment Co.", c: "Korea" },
  { id: 18151, s: "8GW.IR", n: "Great Western Mining Corp Plc", c: "Ireland" },
  { id: 18152, s: "025980.KQ", n: "Ananti Inc", c: "Korea" },
  { id: 18153, s: "025950.KQ", n: "Dongsin Engineering & Construction", c: "Korea" },
  { id: 18154, s: "GRP.IR", n: "Greencoat Renewables PLC", c: "Ireland" },
  { id: 18155, s: "025440.KQ", n: "Daesung Eltec Co.", c: "Korea" },
  { id: 18156, s: "AAB.", n: "Aalborg Boldspilklub A/S", c: "Denmark" },
  { id: 18157, s: "034230.KQ", n: "Paradise Co.", c: "Korea" },
  { id: 18158, s: "038880.KQ", n: "iA Inc", c: "Korea" },
  { id: 18159, s: "035600.KQ", n: "Kginicis Co.Ltd", c: "Korea" },
  { id: 18160, s: "TRAD.ST", n: "TradeDoubler AB", c: "Sweden" },
  { id: 18161, s: "035810.KQ", n: "EASY HOLDINGS Co.", c: "Korea" },
  { id: 18162, s: "SCA-A.ST", n: "Svenska Cellulosa Aktiebolaget SCA (publ)", c: "Sweden" },
  { id: 18163, s: "SEZI.ST", n: "Senzime AB", c: "Sweden" },
  { id: 18164, s: "SILEON.ST", n: "Sileon AB", c: "Sweden" },
  { id: 18165, s: "039980.KQ", n: "Leenos Co", c: "Korea" },
  { id: 18166, s: "039740.KQ", n: "Korea Information Engineering Services Co.", c: "Korea" },
  { id: 18167, s: "039610.KQ", n: "HS Valve Co.", c: "Korea" },
  { id: 18168, s: "039010.KQ", n: "Hyundai HT CoLtd", c: "Korea" },
  { id: 18169, s: "038950.KQ", n: "FINEDIGITAL Inc", c: "Korea" },
  { id: 18170, s: "NAXS.ST", n: "NAXS Nordic Access Buyout Fund AB", c: "Sweden" },
  { id: 18171, s: "KSLAV.HE", n: "Keskisuomalainen Oyj A", c: "Finland" },
  { id: 18172, s: "051160.KQ", n: "GaeaSoft Co", c: "Korea" },
  { id: 18173, s: "051360.KQ", n: "TOVIS Co.Ltd", c: "Korea" },
  { id: 18174, s: "GREENM.", n: "GreenMobility A/S", c: "Denmark" },
  { id: 18175, s: "GRLA.", n: "Groenlandsbanken AS", c: "Denmark" },
  { id: 18176, s: "016250.KQ", n: "SGC eTEC E&C Co.", c: "Korea" },
  { id: 18177, s: "HAIVER.", n: "Handelsinvest Verden", c: "Denmark" },
  { id: 18178, s: "016100.KQ", n: "Leaders Cosmetics Co.", c: "Korea" },
  { id: 18179, s: "HARB-B.", n: "Harboes Bryggeri A/S", c: "Denmark" },
  { id: 18180, s: "015750.KQ", n: "Sungwoo Hitech Co.", c: "Korea" },
  { id: 18181, s: "HART.", n: "Brødrene Hartmann A/S", c: "Denmark" },
  { id: 18182, s: "HYDRCT.", n: "Hydract AS", c: "Denmark" },
  { id: 18183, s: "IMPERO.", n: "Impero AS", c: "Denmark" },
  { id: 18184, s: "013030.KQ", n: "Hy-Lok Corporation", c: "Korea" },
  { id: 18185, s: "FEV.L", n: "Fidelity European Values", c: "UK" },
  { id: 18186, s: "012340.KQ", n: "Nuintek Co.Ltd", c: "Korea" },
  { id: 18187, s: "010170.KQ", n: "Taihan Fiber Optics Co.", c: "Korea" },
  { id: 18188, s: "NRDF.", n: "Nordfyns Bank A/S", c: "Denmark" },
  { id: 18189, s: "008830.KQ", n: "Daedong Gear Co.", c: "Korea" },
  { id: 18190, s: "FAS.L", n: "Fidelity Asian Values", c: "UK" },
  { id: 18191, s: "ODICO.", n: "Odico A/S", c: "Denmark" },
  { id: 18192, s: "BOLT.TA", n: "BioLight Life Sciences", c: "Israel" },
  { id: 18193, s: "ALBA.L", n: "Alba Mineral Resources", c: "UK" },
  { id: 18194, s: "GENT.OL", n: "Gentian Diagnostics AS", c: "Norway" },
  { id: 18195, s: "BEMO.L", n: "Baring Emerging Europe Plc", c: "UK" },
  { id: 18196, s: "CAL.L", n: "Capital & Regional PLC", c: "UK" },
  { id: 18197, s: "SKAND.OL", n: "Skandia Greenpower AS", c: "Norway" },
  { id: 18198, s: "SKUE.OL", n: "Skue Sparebank", c: "Norway" },
  { id: 18199, s: "016920.KQ", n: "CAS Corporation", c: "Korea" },
  { id: 18200, s: "017480.KQ", n: "Samhyun Steel Co.", c: "Korea" },
  { id: 18201, s: "FYNBK.", n: "Fynske Bank A/S", c: "Denmark" },
  { id: 18202, s: "XCLL", n: "Xcelmobility Inc.", c: "US" },
  { id: 18203, s: "002230.KQ", n: "PS Tec. Co.", c: "Korea" },
  { id: 18204, s: "001810.KQ", n: "Moorim SP Co.", c: "Korea" },
  { id: 18205, s: "AKKO.BD", n: "AKKO Invest Nyrt", c: "Hungary" },
  { id: 18206, s: "EMAN.L", n: "Everyman Media Group plc", c: "UK" },
  { id: 18207, s: "ELCO.L", n: "Eleco PLC", c: "UK" },
  { id: 18208, s: "000250.KQ", n: "Sam Chun Dang Pharm. Co.", c: "Korea" },
  { id: 18209, s: "AIRX.OL", n: "Airthings ASA", c: "Norway" },
  { id: 18210, s: "DXRX.L", n: "Diaceutics PLC", c: "UK" },
  { id: 18211, s: "WSHE", n: "E Debit Global Co", c: "US" },
  { id: 18212, s: "WTBDY", n: "Whitbread PLC ADR", c: "US" },
  { id: 18213, s: "DSW.L", n: "DSW Capital PLC", c: "UK" },
  { id: 18214, s: "ZICX", n: "Zicix Co", c: "US" },
  { id: 18215, s: "ENALYZ.", n: "Enalyzer A/S", c: "Denmark" },
  { id: 18216, s: "AADV.L", n: "Albion Development VCT PLC", c: "UK" },
  { id: 18217, s: "D4T4.L", n: "D4t4 Solutions PLC", c: "UK" },
  { id: 18218, s: "AIE.L", n: "Ashoka India Equity Investment Trust PLC", c: "UK" },
  { id: 18219, s: "DVD.OL", n: "Deep Value Driller AS", c: "Norway" },
  { id: 18220, s: "ELO.OL", n: "Elopak AS", c: "Norway" },
  { id: 18221, s: "007370.KQ", n: "Jin Yang Pharmaceutical Co.", c: "Korea" },
  { id: 18222, s: "BSEN.TA", n: "Bet Shemesh Engines Holdings 1997", c: "Israel" },
  { id: 18223, s: "PARKST-A.", n: "Park Street A/S", c: "Denmark" },
  { id: 18224, s: "NTR-B.", n: "NTR Holding A/S", c: "Denmark" },
  { id: 18225, s: "019550.KQ", n: "SBI Investment KOREA Co.", c: "Korea" },
  { id: 18226, s: "DKIGI.", n: "Danske Invest Global", c: "Denmark" },
  { id: 18227, s: "SNOR.OL", n: "Sparebank 1 Nordvest", c: "Norway" },
  { id: 18228, s: "EXL1V.HE", n: "Exel Composites Oyj", c: "Finland" },
  { id: 18229, s: "SOAG.OL", n: "Sparebank 1 Ostfold Akershus", c: "Norway" },
  { id: 18230, s: "PUMU.HE", n: "PunaMusta Media Oyj", c: "Finland" },
  { id: 18231, s: "MNTR.OL", n: "Mintra Holding As", c: "Norway" },
  { id: 18232, s: "DJUR.", n: "Djurslands Bank", c: "Denmark" },
  { id: 18233, s: "CMH.L", n: "Chamberlin Plc", c: "UK" },
  { id: 18234, s: "TAALA.HE", n: "Taaleri Oyj", c: "Finland" },
  { id: 18235, s: "SUY1V.HE", n: "Suominen Oyj", c: "Finland" },
  { id: 18236, s: "AT.L", n: "Ashtead Technology Holdings PLC", c: "UK" },
  { id: 18237, s: "ATR.L", n: "Schroders Investment Trusts - Schroder Asian Total Return Investment Company plc", c: "UK" },
  { id: 18238, s: "CLON.L", n: "Clontarf Energy Plc", c: "UK" },
  { id: 18239, s: "NRC.OL", n: "NRC Group ASA", c: "Norway" },
  { id: 18240, s: "RAIKV.HE", n: "Raisio Oyj", c: "Finland" },
  { id: 18241, s: "AVG.L", n: "Avingtrans Plc", c: "UK" },
  { id: 18242, s: "PCIB.OL", n: "PCI Biotech Holding ASA", c: "Norway" },
  { id: 18243, s: "AOM.L", n: "ActiveOps PLC", c: "UK" },
  { id: 18244, s: "PIIPPO.HE", n: "Piippo OYJ", c: "Finland" },
  { id: 18245, s: "ASLI.L", n: "abrdn European Logistics Income PLC", c: "UK" },
  { id: 18246, s: "021650.KQ", n: "Cubic Korea Inc", c: "Korea" },
  { id: 18247, s: "CONFRZ.", n: "Conferize A/S", c: "Denmark" },
  { id: 18248, s: "GACB.L", n: "General Accident plc 7 7/8% CUM IRRD PRF GBP1", c: "UK" },
  { id: 18249, s: "LEND.L", n: "Sancus Lending Group", c: "UK" },
  { id: 18250, s: "LEVEL.ST", n: "Nordic LEVEL Group AB", c: "Sweden" },
  { id: 18251, s: "052460.KQ", n: "iCRAFT Co.", c: "Korea" },
  { id: 18252, s: "052400.KQ", n: "Kona I Co.Ltd", c: "Korea" },
  { id: 18253, s: "KZG.L", n: "Kazera Global PLC", c: "UK" },
  { id: 18254, s: "MAGI.ST", n: "MAG Interactive AB (publ)", c: "Sweden" },
  { id: 18255, s: "ARR.L", n: "Aurora Investment Trust plc", c: "UK" },
  { id: 18256, s: "ANX.L", n: "Anexo Group Plc", c: "UK" },
  { id: 18257, s: "ESENSE.HE", n: "Enersense International Oyj", c: "Finland" },
  { id: 18258, s: "XPLRA.OL", n: "Xplora Technologies As", c: "Norway" },
  { id: 18259, s: "ELEAV.HE", n: "Elecster Oyj A", c: "Finland" },
  { id: 18260, s: "BPCR.L", n: "BioPharma Credit PLC", c: "UK" },
  { id: 18261, s: "SUNSB.OL", n: "Sunndal Sparebank", c: "Norway" },
  { id: 18262, s: "VIS.IC", n: "Vátryggingafélag Íslands hf", c: "Iceland" },
  { id: 18263, s: "SVN.IC", n: "Sildarvinnslan Hf.", c: "Iceland" },
  { id: 18264, s: "SIMINN.IC", n: "Síminn hf", c: "Iceland" },
  { id: 18265, s: "REGINN.IC", n: "Reginn hf", c: "Iceland" },
  { id: 18266, s: "TECH.OL", n: "Techstep ASA", c: "Norway" },
  { id: 18267, s: "BRGE.L", n: "BlackRock Greater Europe Investment Trust plc", c: "UK" },
  { id: 18268, s: "BVT.L", n: "Baronsmead Venture Trust Plc", c: "UK" },
  { id: 18269, s: "HAGA.IC", n: "Hagar hf", c: "Iceland" },
  { id: 18270, s: "WWIB.OL", n: "Wilh Wilhelmsen Holding ASA B", c: "Norway" },
  { id: 18271, s: "ACKR.TA", n: "Ackerstein Group", c: "Israel" },
  { id: 18272, s: "BRSC.L", n: "Blackrock Smaller Companies Trust PLC", c: "UK" },
  { id: 18273, s: "BBH.L", n: "Bellevue Healthcare Trust PLC", c: "UK" },
  { id: 18274, s: "OMASP.HE", n: "Oma Saastopankki Oyj", c: "Finland" },
  { id: 18275, s: "ORTHEX.HE", n: "Orthex Oyj", c: "Finland" },
  { id: 18276, s: "OVARO.HE", n: "Ovaro Kiinteistosijoitus Oyj", c: "Finland" },
  { id: 18277, s: "AVER.TA", n: "Averbuch Formica Center", c: "Israel" },
  { id: 18278, s: "GRONG.OL", n: "Grong Sparebank", c: "Norway" },
  { id: 18279, s: "ARAN.TA", n: "Aran Research and Development 1982", c: "Israel" },
  { id: 18280, s: "CRPR.L", n: "James Cropper PLC", c: "UK" },
  { id: 18281, s: "CREO.L", n: "Creo Medical Group PLC", c: "UK" },
  { id: 18282, s: "ITERA.OL", n: "Itera ASA", c: "Norway" },
  { id: 18283, s: "PKB.F", n: "Park & Bellheimer AG", c: "GER" },
  { id: 18284, s: "600149.SS", n: "Langfang Development", c: "CH" },
  { id: 18285, s: "PSM.F", n: "ProSiebenSat.1 Media SE", c: "GER" },
  { id: 18286, s: "404990.KS", n: "Shinhan Seobu T&D REIT", c: "Korea" },
  { id: 18287, s: "ADN1.F", n: "Adesso SE", c: "GER" },
  { id: 18288, s: "ADV.F", n: "ADVA Optical Networking SE", c: "GER" },
  { id: 18289, s: "600022.SS", n: "Shandong Iron and Steel", c: "CH" },
  { id: 18290, s: "ZEF.F", n: "ZhongDe Waste Technology AG", c: "GER" },
  { id: 18291, s: "CORE.F", n: "Coreo AG", c: "GER" },
  { id: 18292, s: "CTNK.F", n: "centrotherm international AG", c: "GER" },
  { id: 18293, s: "GFT.F", n: "GFT Technologies SE", c: "GER" },
  { id: 18294, s: "GLJ.F", n: "Grenke AG", c: "GER" },
  { id: 18295, s: "KCI.WA", n: "KCI SA", c: "Poland" },
  { id: 18296, s: "GRF.F", n: "Greiffenberger AG", c: "GER" },
  { id: 18297, s: "IFI.WA", n: "IFirma SA", c: "Poland" },
  { id: 18298, s: "IDM.WA", n: "Dom Maklerski IDM SA", c: "Poland" },
  { id: 18299, s: "H24.F", n: "home24 SE", c: "GER" },
  { id: 18300, s: "H2O.F", n: "Enapter AG", c: "GER" },
  { id: 18301, s: "HBH.F", n: "Hornbach Holding VZO O.N.", c: "GER" },
  { id: 18302, s: "HDR.WA", n: "HYDROTOR SA", c: "Poland" },
  { id: 18303, s: "GTN.WA", n: "Getin Holding SA", c: "Poland" },
  { id: 18304, s: "HMU.F", n: "HMS Bergbau AG", c: "GER" },
  { id: 18305, s: "HYI.F", n: "Hyrican Informationssysteme Aktiengesellschaft", c: "GER" },
  { id: 18306, s: "FMG.WA", n: "One SA", c: "Poland" },
  { id: 18307, s: "ETL.WA", n: "Eurotel SA", c: "Poland" },
  { id: 18308, s: "ERB.WA", n: "Erbud SA", c: "Poland" },
  { id: 18309, s: "IFS.F", n: "Infas Holding AG", c: "GER" },
  { id: 18310, s: "INH.F", n: "INDUS Holding AG", c: "GER" },
  { id: 18311, s: "JUMP.V", n: "Leveljump Healthcare Co", c: "CA" },
  { id: 18312, s: "AAD.F", n: "Amadeus FiRe AG", c: "GER" },
  { id: 18313, s: "A7A.F", n: "FinLab AG", c: "GER" },
  { id: 18314, s: "A1OS.F", n: "All For One Steeb AG", c: "GER" },
  { id: 18315, s: "600196.SS", n: "Shanghai Fosun Pharmaceutical Group", c: "CH" },
  { id: 18316, s: "C3RY.F", n: "CHERRY SE O.N.", c: "GER" },
  { id: 18317, s: "CEC.F", n: "Ceconomy AG", c: "GER" },
  { id: 18318, s: "RBW.WA", n: "Rainbow Tours SA", c: "Poland" },
  { id: 18319, s: "UNI.WA", n: "UNIBEP S.A.", c: "Poland" },
  { id: 18320, s: "ALX.F", n: "Alexanderwerk Aktiengesellschaft", c: "GER" },
  { id: 18321, s: "VOT.WA", n: "Votum SA", c: "Poland" },
  { id: 18322, s: "AFX.F", n: "Carl Zeiss Meditec AG", c: "GER" },
  { id: 18323, s: "600377.SS", n: "Jiangsu Expressway", c: "CH" },
  { id: 18324, s: "600248.SS", n: "Shaanxi Yanchang Petroleum Chemical Engineering", c: "CH" },
  { id: 18325, s: "600236.SS", n: "Guangxi Guiguan Electric Power", c: "CH" },
  { id: 18326, s: "600234.SS", n: "Kexin Development Co Shanxi", c: "CH" },
  { id: 18327, s: "600114.SS", n: "NBTM New Materials Group", c: "CH" },
  { id: 18328, s: "9TS.F", n: "TUBESOLAR AG INH O.N.", c: "GER" },
  { id: 18329, s: "TKA.HA", n: "thyssenkrupp AG", c: "GER" },
  { id: 18330, s: "600052.SS", n: "Zhejiang Guangsha", c: "CH" },
  { id: 18331, s: "600037.SS", n: "Beijing Gehua CATV Network", c: "CH" },
  { id: 18332, s: "600033.SS", n: "Fujian Expressway Development", c: "CH" },
  { id: 18333, s: "COP.F", n: "ConocoPhillips", c: "GER" },
  { id: 18334, s: "XD4.HA", n: "Strabag SE", c: "GER" },
  { id: 18335, s: "600020.SS", n: "Henan Zhongyuan Expressway", c: "CH" },
  { id: 18336, s: "SUHJY", n: "Sun Hung Kai Properties", c: "US" },
  { id: 18337, s: "600017.SS", n: "Rizhao Port", c: "CH" },
  { id: 18338, s: "93M1.F", n: "MPH Health Care AG", c: "GER" },
  { id: 18339, s: "9JK.F", n: "INTL SCHOOL AUGS.AG NA ON", c: "GER" },
  { id: 18340, s: "IPOK.F", n: "Heidelberger Beteiligungsholding AG", c: "GER" },
  { id: 18341, s: "IS7.F", n: "InTiCa Systems AG", c: "GER" },
  { id: 18342, s: "JDC.F", n: "JDC Group AG", c: "GER" },
  { id: 18343, s: "009320.KS", n: "Daewoo Elec", c: "Korea" },
  { id: 18344, s: "MZA.WA", n: "Muza S.A", c: "Poland" },
  { id: 18345, s: "001750.KS", n: "Hanyang Sec", c: "Korea" },
  { id: 18346, s: "ZIL2.F", n: "ElringKlinger AG", c: "GER" },
  { id: 18347, s: "ABHA.SG", n: "Hasen-Immobilien AG", c: "GER" },
  { id: 18348, s: "010120.KS", n: "LS Electric", c: "Korea" },
  { id: 18349, s: "ERA.V", n: "Elcora Advanced Materials Co", c: "CA" },
  { id: 18350, s: "009900.KS", n: "Myoung Shin Industrial ", c: "Korea" },
  { id: 18351, s: "FGA.PA", n: "Figeac Aero SA", c: "FR" },
  { id: 18352, s: "009680.KS", n: "Motonic", c: "Korea" },
  { id: 18353, s: "FLY.PA", n: "Fonciere Lyonnaise", c: "FR" },
  { id: 18354, s: "009415.KS", n: "Tae Young Engineering&Constructin Co Preference Shares", c: "Korea" },
  { id: 18355, s: "009290.KS", n: "Kwangdong Phar", c: "Korea" },
  { id: 18356, s: "FIE.F", n: "Fielmann Aktiengesellschaft", c: "GER" },
  { id: 18357, s: "FPG.PA", n: "Union Technologies Informatique Group S.A", c: "FR" },
  { id: 18358, s: "GALIM.PA", n: "Galimmo SA", c: "FR" },
  { id: 18359, s: "GJAJ.PA", n: "Groupe JAJ", c: "FR" },
  { id: 18360, s: "008490.KS", n: "Suheung Capsul", c: "Korea" },
  { id: 18361, s: "EMR.V", n: "Emergent Metals Co", c: "CA" },
  { id: 18362, s: "GLO.PA", n: "GL Events SA", c: "FR" },
  { id: 18363, s: "008110.KS", n: "Daidong Elec", c: "Korea" },
  { id: 18364, s: "GV.PA", n: "Genomic Vision Société Anonyme", c: "FR" },
  { id: 18365, s: "007980.KS", n: "Pan-Pacific", c: "Korea" },
  { id: 18366, s: "003310.KQ", n: "Daejoo Inc", c: "Korea" },
  { id: 18367, s: "007590.KS", n: "Dongbang Agro", c: "Korea" },
  { id: 18368, s: "FME.F", n: "Fresenius Medical Care AG & Co. KGaA", c: "GER" },
  { id: 18369, s: "LKD.WA", n: "Lokum Deweloper SA", c: "Poland" },
  { id: 18370, s: "EKP.WA", n: "Elkop SA", c: "Poland" },
  { id: 18371, s: "DEZ.F", n: "DEUTZ Aktiengesellschaft", c: "GER" },
  { id: 18372, s: "KMP.WA", n: "Przedsiebiorstwo Produkcyjno Handlowe Kompap SA", c: "Poland" },
  { id: 18373, s: "FTK.F", n: "flatexDEGIRO AG", c: "GER" },
  { id: 18374, s: "FRE.F", n: "Fresenius SE & Co. KGaA", c: "GER" },
  { id: 18375, s: "FNG.F", n: "Fritz Nols AG", c: "GER" },
  { id: 18376, s: "CWC.F", n: "CEWE Stiftung & Co. KGaA", c: "GER" },
  { id: 18377, s: "CY1K.F", n: "SBF AG", c: "GER" },
  { id: 18378, s: "PMP.WA", n: "Pamapol S.A.", c: "Poland" },
  { id: 18379, s: "DBI.F", n: "DT.BIOTECH INNOV.AG O.N.", c: "GER" },
  { id: 18380, s: "PKP.WA", n: "PKP Cargo S.A.", c: "Poland" },
  { id: 18381, s: "DEF.F", n: "DEFAMA Deutsche Fachmarkt AG", c: "GER" },
  { id: 18382, s: "PHN.WA", n: "Polski Holding Nieruchomości SA", c: "Poland" },
  { id: 18383, s: "OTS.WA", n: "OT Logistics S.A", c: "Poland" },
  { id: 18384, s: "LRQ.WA", n: "Larq SA", c: "Poland" },
  { id: 18385, s: "EDL.F", n: "Edel SE & Co. KGaA", c: "GER" },
  { id: 18386, s: "EFF.F", n: "Deutsche Effecten- und Wechsel-Beteiligungsgesellschaft AG", c: "GER" },
  { id: 18387, s: "CPU2.F", n: "CPU Softwarehouse AG", c: "GER" },
  { id: 18388, s: "EIN3.F", n: "Einhell GER AG", c: "GER" },
  { id: 18389, s: "MSZ.WA", n: "Mostostal Zabrze - Holding SA", c: "Poland" },
  { id: 18390, s: "ELG.F", n: "Elmos Semiconductor SE", c: "GER" },
  { id: 18391, s: "MSP.WA", n: "MOSTOSTAL Płock SA", c: "Poland" },
  { id: 18392, s: "ENGL.F", n: "EV Digital Invest AG", c: "GER" },
  { id: 18393, s: "EVD.F", n: "CTS Eventim AG & Co. KGaA", c: "GER" },
  { id: 18394, s: "MAB.WA", n: "Mabion S.A.", c: "Poland" },
  { id: 18395, s: "FEV.F", n: "FORTEC Elektronik AG", c: "GER" },
  { id: 18396, s: "RLP.WA", n: "Relpol SA", c: "Poland" },
  { id: 18397, s: "RMK.WA", n: "REMAK SA", c: "Poland" },
  { id: 18398, s: "BYW.F", n: "BayWa Aktiengesellschaft", c: "GER" },
  { id: 18399, s: "U1DA.F", n: "CLOCKCHAIN AG INH O.N.", c: "GER" },
  { id: 18400, s: "MUM.F", n: "Mensch und Maschine Software SE", c: "GER" },
  { id: 18401, s: "MUT3.F", n: "Mineralbrunnen Überkingen-Teinach GmbH & Co. KGaA", c: "GER" },
  { id: 18402, s: "MVV1.F", n: "MVV Energie AG", c: "GER" },
  { id: 18403, s: "ATD.WA", n: "Atende S.A.", c: "Poland" },
  { id: 18404, s: "JK.V", n: "Just Kitchen Holdings Co", c: "CA" },
  { id: 18405, s: "ARH.WA", n: "Archicom S.A.", c: "Poland" },
  { id: 18406, s: "APT.WA", n: "Apator S.A.", c: "Poland" },
  { id: 18407, s: "NXR.F", n: "NEXR TECHN.SE INH O.N.", c: "GER" },
  { id: 18408, s: "S92.F", n: "SMA Solar Technology AG", c: "GER" },
  { id: 18409, s: "348950.KS", n: "Jr Global Reit", c: "Korea" },
  { id: 18410, s: "SFX.F", n: "Solar-Fabrik Aktiengesellschaft", c: "GER" },
  { id: 18411, s: "200880.KS", n: "Seoyon E Hwa", c: "Korea" },
  { id: 18412, s: "BBD.WA", n: "BBI Development SA", c: "Poland" },
  { id: 18413, s: "181710.KS", n: "Nhn Entertain", c: "Korea" },
  { id: 18414, s: "UMDK.F", n: "UMT United Mobility Technology AG", c: "GER" },
  { id: 18415, s: "161000.KS", n: "Aekyung Petrochemical", c: "Korea" },
  { id: 18416, s: "VAR1.F", n: "Varta AG", c: "GER" },
  { id: 18417, s: "VBK.F", n: "VERBIO Vereinigte BioEnergie AG", c: "GER" },
  { id: 18418, s: "145990.KS", n: "Samyang Cor", c: "Korea" },
  { id: 18419, s: "145210.KS", n: "Saehwa IMC", c: "Korea" },
  { id: 18420, s: "VG0K.F", n: "Vivanco Gruppe AG", c: "GER" },
  { id: 18421, s: "VOS.F", n: "Vossloh AG", c: "GER" },
  { id: 18422, s: "VRL.F", n: "NET DIGITAL AG INH O.N.", c: "GER" },
  { id: 18423, s: "VVV3.F", n: "ÖKOWORLD AG", c: "GER" },
  { id: 18424, s: "MUB.F", n: "Mühlbauer Holding AG", c: "GER" },
  { id: 18425, s: "BFT.WA", n: "Benefit Systems SA", c: "Poland" },
  { id: 18426, s: "128820.KS", n: "Daesung Ind", c: "Korea" },
  { id: 18427, s: "NOEJ.F", n: "Norma Group AG NA O.N.", c: "GER" },
  { id: 18428, s: "QB7.F", n: "Quirin Privatbank AG", c: "GER" },
  { id: 18429, s: "395400.KS", n: "SK Reit", c: "Korea" },
  { id: 18430, s: "QBY.F", n: "q.beyond AG", c: "GER" },
  { id: 18431, s: "383220.KS", n: "F&F", c: "Korea" },
  { id: 18432, s: "381970.KS", n: "K Car", c: "Korea" },
  { id: 18433, s: "RAA.F", n: "RATIONAL Aktiengesellschaft", c: "GER" },
  { id: 18434, s: "RGB.F", n: "Regenbogen AG", c: "GER" },
  { id: 18435, s: "353200.KS", n: "Daeduck Electronics ", c: "Korea" },
  { id: 18436, s: "O4B.F", n: "OVB Holding AG", c: "GER" },
  { id: 18437, s: "S4AA.F", n: "SMT SCHARF AG NA O.N.", c: "GER" },
  { id: 18438, s: "O2D.F", n: "Telefónica Deutschland Holding AG", c: "GER" },
  { id: 18439, s: "KSB.F", n: "KSB SE & Co. KGaA", c: "GER" },
  { id: 18440, s: "BIO.WA", n: "Bioton S.A.", c: "Poland" },
  { id: 18441, s: "LEI.F", n: "Leifheit Aktiengesellschaft", c: "GER" },
  { id: 18442, s: "CPG.WA", n: "Capital Park S.A.", c: "Poland" },
  { id: 18443, s: "M12.F", n: "M1 Kliniken AG", c: "GER" },
  { id: 18444, s: "TEUCF", n: "Box Ships Inc", c: "US" },
  { id: 18445, s: "CLD.WA", n: "Cloud Technologies S.A", c: "Poland" },
  { id: 18446, s: "CIE.WA", n: "Ciech S.A.", c: "Poland" },
  { id: 18447, s: "TEZNY", n: "Terna Rete Elettrica Nazionale", c: "US" },
  { id: 18448, s: "MDN.F", n: "Medion AG", c: "GER" },
  { id: 18449, s: "MED.F", n: "MEDICLIN Aktiengesellschaft", c: "GER" },
  { id: 18450, s: "BMX.WA", n: "Biomaxima", c: "Poland" },
  { id: 18451, s: "MLP.F", n: "MLP SE", c: "GER" },
  { id: 18452, s: "WAC.F", n: "Wacker Neuson SE", c: "GER" },
  { id: 18453, s: "126560.KS", n: "Hyundai Hcn", c: "Korea" },
  { id: 18454, s: "BTBB.F", n: "SLEEPZ AG", c: "GER" },
  { id: 18455, s: "SVNLF", n: "Svenska Handelsbanken AB A", c: "US" },
  { id: 18456, s: "264900.KS", n: "Crown Confectionery", c: "Korea" },
  { id: 18457, s: "TLIK.F", n: "TELES Informationstechnologien AG", c: "GER" },
  { id: 18458, s: "234080.KS", n: "Jw Life Science Co", c: "Korea" },
  { id: 18459, s: "TLX.F", n: "Talanx AG", c: "GER" },
  { id: 18460, s: "226320.KS", n: "It's Skin", c: "Korea" },
  { id: 18461, s: "TPE.F", n: "PVA TePla AG", c: "GER" },
  { id: 18462, s: "272210.KS", n: "Hanwha Systems", c: "Korea" },
  { id: 18463, s: "KGX.F", n: "KION Group AG", c: "GER" },
  { id: 18464, s: "JEN.F", n: "Jenoptik AG", c: "GER" },
  { id: 18465, s: "ARL.F", n: "Aareal Bank AG", c: "GER" },
  { id: 18466, s: "TSG.WA", n: "TESGAS S.A.", c: "Poland" },
  { id: 18467, s: "TBL.WA", n: "T-Bull S.A", c: "Poland" },
  { id: 18468, s: "TGT.F", n: "11880 Solutions AG", c: "GER" },
  { id: 18469, s: "B4B3.F", n: "Metro AG", c: "GER" },
  { id: 18470, s: "STX.WA", n: "Stalexport Autostrady SA", c: "Poland" },
  { id: 18471, s: "B7E.F", n: "Blue Cap AG", c: "GER" },
  { id: 18472, s: "STS.WA", n: "Satis Group S.A.", c: "Poland" },
  { id: 18473, s: "KLM.V", n: "Kermode Resources", c: "CA" },
  { id: 18474, s: "BBI.F", n: "BBI Bürgerliches Brauhaus Immobilien AG", c: "GER" },
  { id: 18475, s: "STP.WA", n: "Stalprodukt SA", c: "Poland" },
  { id: 18476, s: "BFV.F", n: "Berliner Effektengesellschaft AG", c: "GER" },
  { id: 18477, s: "BLH.F", n: "BREMER LAGERHAUS-GESELLSCHAFT -Aktiengesellschaft von 1877-", c: "GER" },
  { id: 18478, s: "SKA.WA", n: "Fabryka Farb i Lakierów ŚNIEŻKA SA", c: "Poland" },
  { id: 18479, s: "SCP.WA", n: "Scope Fluidics SA", c: "Poland" },
  { id: 18480, s: "TIS.F", n: "tiscon AG", c: "GER" },
  { id: 18481, s: "TEG.F", n: "TAG Immobilien AG", c: "GER" },
  { id: 18482, s: "WCH.F", n: "Wacker Chemie AG", c: "GER" },
  { id: 18483, s: "33626K.KS", n: "Doosan Fuel Cell Co.. Pfd. Series 1", c: "Korea" },
  { id: 18484, s: "WCMK.F", n: "WCM Beteiligungs- und Grundbesitz-AG", c: "GER" },
  { id: 18485, s: "123690.KS", n: "Hankook Cosmet", c: "Korea" },
  { id: 18486, s: "WOLF.F", n: "META WOLF AG INH O.N.", c: "GER" },
  { id: 18487, s: "XTP.F", n: "Sino AG", c: "GER" },
  { id: 18488, s: "YOC.F", n: "YOC AG", c: "GER" },
  { id: 18489, s: "YSN.F", n: "secunet Security Networks Aktiengesellschaft", c: "GER" },
  { id: 18490, s: "TTO.F", n: "TTL Beteiligungs- und Grundbesitz-AG", c: "GER" },
  { id: 18491, s: "210980.KS", n: "SK D&D", c: "Korea" },
  { id: 18492, s: "TTK.F", n: "Takkt AG", c: "GER" },
  { id: 18493, s: "214390.KS", n: "Kyongbo Pharmaceutical", c: "Korea" },
  { id: 18494, s: "SGF.F", n: "SGT German Private Equity GmbH & Co. KGaA", c: "GER" },
  { id: 18495, s: "SIM0.F", n: "SIMONA AG O.N.", c: "GER" },
  { id: 18496, s: "267260.KS", n: "Hyundai Electric & Energy Systems", c: "Korea" },
  { id: 18497, s: "TLOG", n: "TetraLogic Pharmaceuticals Co", c: "US" },
  { id: 18498, s: "SLSA.F", n: "SOLUTIANCE AG NA O.N.", c: "GER" },
  { id: 18499, s: "308170.KS", n: "Central Motek", c: "Korea" },
  { id: 18500, s: "SPT6.F", n: "Sparta AG", c: "GER" },
  { id: 18501, s: "SRT3.F", n: "Sartorius Aktiengesellschaft", c: "GER" },
  { id: 18502, s: "SSH.F", n: "Südwestdeutsche Salzwerke AG", c: "GER" },
  { id: 18503, s: "293480.KS", n: "Hana Pharm", c: "Korea" },
  { id: 18504, s: "286940.KS", n: "Lotte Data Communication Company", c: "Korea" },
  { id: 18505, s: "KRN.F", n: "Krones AG", c: "GER" },
  { id: 18506, s: "SZG.F", n: "Salzgitter AG", c: "GER" },
  { id: 18507, s: "271940.KS", n: "Iljin HySolus", c: "Korea" },
  { id: 18508, s: "002680.KQ", n: "Hantop Inc", c: "Korea" },
  { id: 18509, s: "INS.MU", n: "Instone Real Estate Group AG", c: "GER" },
  { id: 18510, s: "ENSI.L", n: "EnSilica PLC", c: "UK" },
  { id: 18511, s: "005860.KQ", n: "Hanil Feed Co.", c: "Korea" },
  { id: 18512, s: "688108.SS", n: "Sino Medical Sciences Technology In", c: "CH" },
  { id: 18513, s: "SNG.V", n: "Silver Range Resources", c: "CA" },
  { id: 18514, s: "RMYHY", n: "Ramsay Health Care ADR", c: "US" },
  { id: 18515, s: "601028.SS", n: "Shandong Yulong Gold", c: "CH" },
  { id: 18516, s: "ASCO.AT", n: "As Commercial Industrial Company of Computers and Toys S.A.", c: "Greece" },
  { id: 18517, s: "605117.SS", n: "Ningbo Deye Technology", c: "CH" },
  { id: 18518, s: "ASTAK.AT", n: "Alpha Astika Akinita S.A", c: "Greece" },
  { id: 18519, s: "SMSEY", n: "Samsonite International SA ADR", c: "US" },
  { id: 18520, s: "601360.SS", n: "360 Security Technology Inc", c: "CH" },
  { id: 18521, s: "601963.SS", n: "Bank of Chongqing", c: "CH" },
  { id: 18522, s: "601375.SS", n: "Central CH Securities", c: "CH" },
  { id: 18523, s: "ANEK.AT", n: "Anonimi Naftiliaki Etaria Kritis S.A", c: "Greece" },
  { id: 18524, s: "601991.SS", n: "Datang International Power Generation Co Class A", c: "CH" },
  { id: 18525, s: "BMO-PT.TO", n: "Bank of Montreal Pref T", c: "CA" },
  { id: 18526, s: "SEGXF", n: "Segro Plc", c: "US" },
  { id: 18527, s: "688105.SS", n: "Nanjing Vazyme Biotech", c: "CH" },
  { id: 18528, s: "SGZ.V", n: "Sego Resources Inc", c: "CA" },
  { id: 18529, s: "SKBSY", n: "Skanska AB ser. B ADR", c: "US" },
  { id: 18530, s: "XRTX.V", n: "XORTX Therapeutics Inc", c: "CA" },
  { id: 18531, s: "600958.SS", n: "Orient Sec", c: "CH" },
  { id: 18532, s: "HYW.HM", n: "HYDROTEC GES.F.WASSERT", c: "GER" },
  { id: 18533, s: "MKR.V", n: "Melkior Resources Inc.", c: "CA" },
  { id: 18534, s: "RHK.DE", n: "RHÖN-KLINIKUM Aktiengesellschaft", c: "GER" },
  { id: 18535, s: "SMWN.DE", n: "SM Wirtschaftsberatungs AG", c: "GER" },
  { id: 18536, s: "605296.SS", n: "Yunnan Shennong Agricultural Industry Group", c: "CH" },
  { id: 18537, s: "EVROF.AT", n: "Evrofarma SA", c: "Greece" },
  { id: 18538, s: "ROBK", n: "Rotate Black Inc", c: "US" },
  { id: 18539, s: "XGC.V", n: "Xali Gold Co", c: "CA" },
  { id: 18540, s: "GAG.DE", n: "GORE German Office Real Estate AG", c: "GER" },
  { id: 18541, s: "EPIL.AT", n: "Selected Textiles S.A.", c: "Greece" },
  { id: 18542, s: "688528.SS", n: "Chengdu Qinchuan IoT Technology", c: "CH" },
  { id: 18543, s: "MDM.V", n: "Madoro Metals Co", c: "CA" },
  { id: 18544, s: "900918.SS", n: "Shanghai Yaohua Pilkington Glass Group Co B", c: "CH" },
  { id: 18545, s: "RLYG.V", n: "Riley Gold Co", c: "CA" },
  { id: 18546, s: "BIG1.MU", n: "BIO-GATE", c: "GER" },
  { id: 18547, s: "SDR.V", n: "Stroud Resources", c: "CA" },
  { id: 18548, s: "688091.SS", n: "Shanghai Yizhong Pharmaceutical", c: "CH" },
  { id: 18549, s: "OM.V", n: "Osisko Metals Inc", c: "CA" },
  { id: 18550, s: "PWF-PZ.TO", n: "Power Financial Co", c: "CA" },
  { id: 18551, s: "688232.SS", n: "Guotai Epoint Software", c: "CH" },
  { id: 18552, s: "PZS.DE", n: "Scherzer & Co. AG", c: "GER" },
  { id: 18553, s: "SRC.V", n: "Stakeholder Gold Co", c: "CA" },
  { id: 18554, s: "RLTR", n: "Reeltime Rentals Inc", c: "US" },
  { id: 18555, s: "IKTIN.AT", n: "Iktinos Hellas S.A. Greek Marble Industry Technical and Touristic Company", c: "Greece" },
  { id: 18556, s: "688800.SS", n: "Suzhou Recodeal Interconnect System", c: "CH" },
  { id: 18557, s: "605277.SS", n: "Xinya Electronic", c: "CH" },
  { id: 18558, s: "WMK.V", n: "Whitemud Resources Inc", c: "CA" },
  { id: 18559, s: "688789.SS", n: "Hangzhou Honghua Digital Technology Stock Company", c: "CH" },
  { id: 18560, s: "INKAT.AT", n: "Intracom Constructions Societe Anonyme Technical and Steel Constructions", c: "Greece" },
  { id: 18561, s: "TKA.DU", n: "thyssenkrupp AG", c: "GER" },
  { id: 18562, s: "688520.SS", n: "Sinocelltech Group", c: "CH" },
  { id: 18563, s: "900917.SS", n: "Shanghai Haixin Group Co B", c: "CH" },
  { id: 18564, s: "605218.SS", n: "Ways Electron", c: "CH" },
  { id: 18565, s: "ATRUST.AT", n: "Alpha Trust Mutual Fund and Alternative Investment Fund Management S.A", c: "Greece" },
  { id: 18566, s: "LUS1.DE", n: "Lang & Schwarz Aktiengesellschaft", c: "GER" },
  { id: 18567, s: "BRF-PB.TO", n: "Brookfield Renewable Power Preferred Equity Inc Pref B", c: "CA" },
  { id: 18568, s: "EMA-PF.TO", n: "Emera Inc Pref F", c: "CA" },
  { id: 18569, s: "900916.SS", n: "Shanghai Phoenix Enterprise Group Co B", c: "CH" },
  { id: 18570, s: "688239.SS", n: "Guizhou Aviation Technical Development", c: "CH" },
  { id: 18571, s: "KEPEN.AT", n: "Flour Mills Kepenos S.A.", c: "Greece" },
  { id: 18572, s: "603666.SS", n: "Yijiahe Technology", c: "CH" },
  { id: 18573, s: "600864.SS", n: "Harbin Hatou Investment", c: "CH" },
  { id: 18574, s: "600860.SS", n: "Beijing Jingcheng Machinery Electric", c: "CH" },
  { id: 18575, s: "EIT-PA.TO", n: "Canoe EIT Income Fund Pref", c: "CA" },
  { id: 18576, s: "ORM.V", n: "Orford Mining Co", c: "CA" },
  { id: 18577, s: "MONT.V", n: "Montfort Capital Co", c: "CA" },
  { id: 18578, s: "688776.SS", n: "Guoguang Electric Co Chengdu", c: "CH" },
  { id: 18579, s: "ORS.V", n: "Orestone Mining Co", c: "CA" },
  { id: 18580, s: "688551.SS", n: "Hefei Kewell Power System", c: "CH" },
  { id: 18581, s: "MMM.V", n: "Minco Capital Co", c: "CA" },
  { id: 18582, s: "NBLC.V", n: "Nobel29 Resources Co", c: "CA" },
  { id: 18583, s: "603112.SS", n: "Shanxi Huaxiang Group", c: "CH" },
  { id: 18584, s: "SNTX", n: "Suntex Enterprises Inc", c: "US" },
  { id: 18585, s: "688081.SS", n: "Wuhan Xingtu Xinke Electronics", c: "CH" },
  { id: 18586, s: "PAG.DE", n: "PREOS Global Office Real Estate & Technology AG", c: "GER" },
  { id: 18587, s: "605366.SS", n: "Jiangxi Hungpai New Material", c: "CH" },
  { id: 18588, s: "HEN.DU", n: "Henkel AG & Co. KGaA", c: "GER" },
  { id: 18589, s: "KBU.HM", n: "TAG Colonia-Immobilien AG", c: "GER" },
  { id: 18590, s: "601828.SS", n: "Red Star Macalline Group Corp Class A", c: "CH" },
  { id: 18591, s: "688768.SS", n: "Anhui Ronds Science & Technology Inc", c: "CH" },
  { id: 18592, s: "CU-PH.TO", n: "Canadian Utilities Pref H", c: "CA" },
  { id: 18593, s: "688087.SS", n: "Shandong Intco Recycling Resources", c: "CH" },
  { id: 18594, s: "605333.SS", n: "Kunshan Huguang Auto Harness", c: "CH" },
  { id: 18595, s: "REYG.V", n: "Reyna Gold Co", c: "CA" },
  { id: 18596, s: "688313.SS", n: "Henan Shijia Photons Technology", c: "CH" },
  { id: 18597, s: "ITH.TO", n: "International Tower Hill Mines", c: "CA" },
  { id: 18598, s: "NOC.V", n: "Norseman Capital", c: "CA" },
  { id: 18599, s: "688737.SS", n: "Sinocat Environmental Technology", c: "CH" },
  { id: 18600, s: "VRR.V", n: "VR Resources", c: "CA" },
  { id: 18601, s: "900947.SS", n: "Shanghai Zhenhua Heavy Industries Co B", c: "CH" },
  { id: 18602, s: "605398.SS", n: "Shanghai New Centurion Network Information Technology", c: "CH" },
  { id: 18603, s: "ENB-PFC.TO", n: "Enbridge Inc Pref 11", c: "CA" },
  { id: 18604, s: "PAL.DE", n: "Pantaflix AG", c: "GER" },
  { id: 18605, s: "601827.SS", n: "Chongqing Sanfeng Environment Group Co", c: "CH" },
  { id: 18606, s: "BCE-PN.TO", n: "Bce Inc Pref N", c: "CA" },
  { id: 18607, s: "900920.SS", n: "Shanghai Diesel Engine Co B", c: "CH" },
  { id: 18608, s: "STS.V", n: "South Star Mining Co", c: "CA" },
  { id: 18609, s: "INTEK.AT", n: "Ideal Group S.A", c: "Greece" },
  { id: 18610, s: "688266.SS", n: "Suzhou Zelgen Biopharmaceuticals", c: "CH" },
  { id: 18611, s: "OHB.DE", n: "OHB SE", c: "GER" },
  { id: 18612, s: "688059.SS", n: "Zhuzhou Huarui Precision Cutting Tools", c: "CH" },
  { id: 18613, s: "ENB-PFK.TO", n: "Enbridge Inc Pref Series 19", c: "CA" },
  { id: 18614, s: "PHC.V", n: "Pathway Health Co", c: "CA" },
  { id: 18615, s: "AX-PI.TO", n: "Artis Real Estate Investment Trust", c: "CA" },
  { id: 18616, s: "NA-PC.TO", n: "National Bank of CA", c: "CA" },
  { id: 18617, s: "601825.SS", n: "Shanghai Rural Commercial Bank", c: "CH" },
  { id: 18618, s: "PEAK.V", n: "Sun Peak Metals Co", c: "CA" },
  { id: 18619, s: "605369.SS", n: "Zhejiang Gongdong Medical Technology", c: "CH" },
  { id: 18620, s: "605368.SS", n: "Henan Lantian Gas", c: "CH" },
  { id: 18621, s: "N4G.DE", n: "NAGA Group AG", c: "GER" },
  { id: 18622, s: "SRE.V", n: "Saville Resources Inc", c: "CA" },
  { id: 18623, s: "SNNC", n: "Sibannac Inc", c: "US" },
  { id: 18624, s: "SUI.V", n: "Superior Mining International Co", c: "CA" },
  { id: 18625, s: "MODA.AT", n: "N. Varveris-Moda Bagno S.A", c: "Greece" },
  { id: 18626, s: "DFTK.DE", n: "DF Deutsche Forfait AG", c: "GER" },
  { id: 18627, s: "ENTER.AT", n: "Entersoft SA", c: "Greece" },
  { id: 18628, s: "MOTO.AT", n: "Emporiki Eisagogiki Aftokiniton Ditrohon kai Mihanon Thalassis Societe Anonyme", c: "Greece" },
  { id: 18629, s: "600718.SS", n: "Neusoft Co", c: "CH" },
  { id: 18630, s: "XD4.DE", n: "Strabag SE", c: "GER" },
  { id: 18631, s: "4DS.DE", n: "Daldrup & Söhne Aktiengesellschaft", c: "GER" },
  { id: 18632, s: "TCO.V", n: "Transatlantic Mining Co", c: "CA" },
  { id: 18633, s: "PHNM.V", n: "Phenom Resources Co", c: "CA" },
  { id: 18634, s: "600714.SS", n: "Qinghai Jinrui Mineral Development", c: "CH" },
  { id: 18635, s: "SLFPY", n: "Standard Life Aberdeen PLC ADR", c: "US" },
  { id: 18636, s: "SOLCF", n: "SOL Global Investments Co", c: "US" },
  { id: 18637, s: "MAE.V", n: "Maritime Resources Corp.", c: "CA" },
  { id: 18638, s: "688030.SS", n: "Hillstone Networks", c: "CH" },
  { id: 18639, s: "MF8.HM", n: "Cash.Medien AG", c: "GER" },
  { id: 18640, s: "605589.SS", n: "Jinan Shengquan Group Share Holding", c: "CH" },
  { id: 18641, s: "TD-PFM.TO", n: "Toronto-Dominion Bank Pref Series 24", c: "CA" },
  { id: 18642, s: "688128.SS", n: "CH National Electric Apparatus R", c: "CH" },
  { id: 18643, s: "605166.SS", n: "Hangzhou Juheshun New Material", c: "CH" },
  { id: 18644, s: "688388.SS", n: "Guangdong Jia Yuan Technology", c: "CH" },
  { id: 18645, s: "688026.SS", n: "Guangzhou Jet Bio-Filtration", c: "CH" },
  { id: 18646, s: "688023.SS", n: "DBAPPSecurity", c: "CH" },
  { id: 18647, s: "SWVK.MU", n: "SolarWorld Aktiengesellschaft", c: "GER" },
  { id: 18648, s: "688019.SS", n: "Anji Microelectronics Tech", c: "CH" },
  { id: 18649, s: "688556.SS", n: "Qingdao Gaoce Technology", c: "CH" },
  { id: 18650, s: "MIG.AT", n: "Marfin Investment Group Holdings S.A", c: "Greece" },
  { id: 18651, s: "ELBE.AT", n: "Elve S.A", c: "Greece" },
  { id: 18652, s: "KTO.V", n: "K2 Gold Corporation", c: "CA" },
  { id: 18653, s: "688185.SS", n: "Cansino Biologics Inc", c: "CH" },
  { id: 18654, s: "688178.SS", n: "Nanjing Wondux Environmental Protection Technology Co", c: "CH" },
  { id: 18655, s: "688280.SS", n: "Jing Jin Electric Technologies", c: "CH" },
  { id: 18656, s: "603529.SS", n: "AIMA Technology Group", c: "CH" },
  { id: 18657, s: "NWO.DU", n: "New Work SE", c: "GER" },
  { id: 18658, s: "T3T1.DE", n: "Seven Principles AG", c: "GER" },
  { id: 18659, s: "WFA.DE", n: "Weng Fine Art AG", c: "GER" },
  { id: 18660, s: "DAR.DE", n: "DATRON AG", c: "GER" },
  { id: 18661, s: "RY-PS.TO", n: "Royal Bank of CA Pref 1st", c: "CA" },
  { id: 18662, s: "OLTH.AT", n: "Thessaloniki Port Authority SA", c: "Greece" },
  { id: 18663, s: "600609.SS", n: "Shenyang Jinbei Automotive", c: "CH" },
  { id: 18664, s: "TD-PFI.TO", n: "Toronto-Dominion Bank Pref Series 16", c: "CA" },
  { id: 18665, s: "688029.SS", n: "Micro Tech Nanjing", c: "CH" },
  { id: 18666, s: "93M1.DE", n: "MPH Health Care AG", c: "GER" },
  { id: 18667, s: "PREMIA.AT", n: "Premia S.A.", c: "Greece" },
  { id: 18668, s: "688001.SS", n: "Suzhou HYC Technology", c: "CH" },
  { id: 18669, s: "IFC-PE.TO", n: "Intact Financial Corp Pref Series 5", c: "CA" },
  { id: 18670, s: "PMKR.V", n: "Playmaker Capital Inc", c: "CA" },
  { id: 18671, s: "900926.SS", n: "Shanghai Baosight Software Co B", c: "CH" },
  { id: 18672, s: "900938.SS", n: "HNA Technology Co B", c: "CH" },
  { id: 18673, s: "TD-PFC.TO", n: "Toronto Dominion Bank Pref C", c: "CA" },
  { id: 18674, s: "PPL-PQ.TO", n: "Pembina Pipeline Corp Pref Series 17 Class A", c: "CA" },
  { id: 18675, s: "NTG.DE", n: "Nabaltec AG", c: "GER" },
  { id: 18676, s: "605599.SS", n: "Beijing Caishikou Department Store", c: "CH" },
  { id: 18677, s: "000402.SZ", n: "Financial Street Holdings", c: "CH" },
  { id: 18678, s: "RSL2.DE", n: "R. Stahl AG NA O.N.", c: "GER" },
  { id: 18679, s: "688335.SS", n: "Shanghai CEO Environmental Protection Technology", c: "CH" },
  { id: 18680, s: "BPS-PB.TO", n: "Brookfield Property Split Corp Pref B", c: "CA" },
  { id: 18681, s: "REE.V", n: "E Tech Resources Inc", c: "CA" },
  { id: 18682, s: "MBQ.DE", n: "Mobotix AG", c: "GER" },
  { id: 18683, s: "KZD.V", n: "Kaizen Discovery", c: "CA" },
  { id: 18684, s: "600521.SS", n: "Zhejiang Huahai Pharmaceutical", c: "CH" },
  { id: 18685, s: "MRK.DU", n: "Merck & Company Inc", c: "GER" },
  { id: 18686, s: "TRASTOR.AT", n: "Trastor Real Estate Investment Company SA", c: "Greece" },
  { id: 18687, s: "VEIN.V", n: "Pasofino Gold Limited", c: "CA" },
  { id: 18688, s: "A6T.DE", n: "artec technologies AG", c: "GER" },
  { id: 18689, s: "SPRV", n: "Supurva Healthcare Group Inc", c: "US" },
  { id: 18690, s: "600517.SS", n: "State Grid Yingda", c: "CH" },
  { id: 18691, s: "VIS.AT", n: "VIS Containers Manufacturing Co.", c: "Greece" },
  { id: 18692, s: "600513.SS", n: "Jiangsu Lianhuan Pharmaceutical", c: "CH" },
  { id: 18693, s: "VRL.DE", n: "Net Digital AG", c: "GER" },
  { id: 18694, s: "603219.SS", n: "Ningbo Fujia Industrial", c: "CH" },
  { id: 18695, s: "603324.SS", n: "Shanghai Sheng Jian Environment Technology", c: "CH" },
  { id: 18696, s: "VCT.V", n: "Volt Carbon Technologies Inc", c: "CA" },
  { id: 18697, s: "XYLEK.AT", n: "Interwood-Xylemporia A.T.E.N.E.", c: "Greece" },
  { id: 18698, s: "TGH.V", n: "Tornado Global Hydrovacs", c: "CA" },
  { id: 18699, s: "FFH-PJ.TO", n: "Fairfax Financial Holdings Pref J", c: "CA" },
  { id: 18700, s: "688569.SS", n: "Beijing Tieke Shougang Railway Tech", c: "CH" },
  { id: 18701, s: "688598.SS", n: "KBC Corporation", c: "CH" },
  { id: 18702, s: "603919.SS", n: "Jinhui Liquor", c: "CH" },
  { id: 18703, s: "KVO.MU", n: "Oberstdorfer Bergbahn AG", c: "GER" },
  { id: 18704, s: "NIO.V", n: "Niocan Inc", c: "CA" },
  { id: 18705, s: "TLIK.DE", n: "TELES Informationstechnologien AG", c: "GER" },
  { id: 18706, s: "PPL-PG.TO", n: "Pembina Pipeline Corp Pref G", c: "CA" },
  { id: 18707, s: "KBX.HM", n: "Knorr-Bremse Aktiengesellschaft", c: "GER" },
  { id: 18708, s: "TUF.V", n: "Honey Badger Silver Inc", c: "CA" },
  { id: 18709, s: "900915.SS", n: "Zhonglu Co B", c: "CH" },
  { id: 18710, s: "RE.V", n: "RE Royalties", c: "CA" },
  { id: 18711, s: "BIOSK.AT", n: "Unibios Holdings S.A", c: "Greece" },
  { id: 18712, s: "S6P.DE", n: "Spielvereinigung Unterhaching Fussb", c: "GER" },
  { id: 18713, s: "IVU.DE", n: "IVU Traffic Technologies AG", c: "GER" },
  { id: 18714, s: "MVV1.DE", n: "MVV Energie AG", c: "GER" },
  { id: 18715, s: "688586.SS", n: "Hefei Jianghang Aircraft Equipment Corporation", c: "CH" },
  { id: 18716, s: "000752.SZ", n: "Tibet Galaxy Science & Technology Development", c: "CH" },
  { id: 18717, s: "ZUM.V", n: "ZoomerMedia Limited", c: "CA" },
  { id: 18718, s: "PPL-PFA.TO", n: "Pembina Pipeline Corp Pref Series 21", c: "CA" },
  { id: 18719, s: "000768.SZ", n: "AVIC Aircraft", c: "CH" },
  { id: 18720, s: "UDC.DE", n: "UniDevice AG", c: "GER" },
  { id: 18721, s: "AKU.TO", n: "Akumin Inc", c: "CA" },
  { id: 18722, s: "688597.SS", n: "Beijing Yupont Electric Power Technology", c: "CH" },
  { id: 18723, s: "AMMN.DE", n: "Grounds Real Estate Development AG", c: "GER" },
  { id: 18724, s: "PIC-PA.TO", n: "Premium Income Corp Pref A", c: "CA" },
  { id: 18725, s: "BRIQ.AT", n: "BriQ Properties Real Estate Investment Company", c: "Greece" },
  { id: 18726, s: "000876.SZ", n: "New Hope Liuhe", c: "CH" },
  { id: 18727, s: "000892.SZ", n: "H&R Century Union Co", c: "CH" },
  { id: 18728, s: "PUL.V", n: "Pulse Oil Co", c: "CA" },
  { id: 18729, s: "600511.SS", n: "CH National Medicines Co", c: "CH" },
  { id: 18730, s: "QUAL.AT", n: "Quality & Reliability A.B.E.E", c: "Greece" },
  { id: 18731, s: "000166.SZ", n: "Shenwan Hongyuan Group", c: "CH" },
  { id: 18732, s: "688369.SS", n: "Beijing Seeyon Internet Software Cp", c: "CH" },
  { id: 18733, s: "TYSB.OL", n: "Tysnes Sparebank", c: "Norway" },
  { id: 18734, s: "ARL.HA", n: "Aareal Bank AG", c: "GER" },
  { id: 18735, s: "117730.KQ", n: "T-Robotics.Co.Ltd", c: "Korea" },
  { id: 18736, s: "000390.KS", n: "Samhwa Paint", c: "Korea" },
  { id: 18737, s: "ATY.V", n: "Atico Mining Corporation", c: "CA" },
  { id: 18738, s: "ASG.V", n: "Aurora Spine Co", c: "CA" },
  { id: 18739, s: "000910.KS", n: "Union Corporation", c: "Korea" },
  { id: 18740, s: "ART.V", n: "ARHT Media Inc", c: "CA" },
  { id: 18741, s: "ADZ.MC", n: "Adolfo Dominguez SA", c: "Spain" },
  { id: 18742, s: "000700.KS", n: "Eusu Holdings", c: "Korea" },
  { id: 18743, s: "228760.KQ", n: "Genomictree Inc.", c: "Korea" },
  { id: 18744, s: "000670.KS", n: "YoungPoong", c: "Korea" },
  { id: 18745, s: "AGIL.MC", n: "Agile Content SA", c: "Spain" },
  { id: 18746, s: "000430.KS", n: "Daewonkangup", c: "Korea" },
  { id: 18747, s: "000400.KS", n: "Lotte Non-Life", c: "Korea" },
  { id: 18748, s: "000325.KS", n: "Noroo Holdings", c: "Korea" },
  { id: 18749, s: "AUEN.V", n: "Golden Sky Minerals Co", c: "CA" },
  { id: 18750, s: "000320.KS", n: "Norooholdings", c: "Korea" },
  { id: 18751, s: "APGO.V", n: "Apollo Silver Co", c: "CA" },
  { id: 18752, s: "ARM.MC", n: "Arima Real Estate SOCIMI SA", c: "Spain" },
  { id: 18753, s: "VLPNY", n: "Voestalpine AG PK", c: "US" },
  { id: 18754, s: "ATRY.MC", n: "Atrys Health SL", c: "Spain" },
  { id: 18755, s: "000105.KS", n: "Yuhan Corp Preferred", c: "Korea" },
  { id: 18756, s: "VITX", n: "VitanaX Inc", c: "US" },
  { id: 18757, s: "067160.KQ", n: "AfreecaTV Co.", c: "Korea" },
  { id: 18758, s: "LLYC.MC", n: "Llorente & Cuenca S.A.", c: "Spain" },
  { id: 18759, s: "MA.NE", n: "Mastercard Inc CDR", c: "CA" },
  { id: 18760, s: "UAV.L", n: "Unicorn AIM VCT plc", c: "UK" },
  { id: 18761, s: "001340.KS", n: "Paik Kwang Ind", c: "Korea" },
  { id: 18762, s: "SPA.BR", n: "Société de Services de Participations de Direction et d'Elaboration", c: "Belgium" },
  { id: 18763, s: "189980.KQ", n: "Hyungkuk F&B", c: "Korea" },
  { id: 18764, s: "258830.KQ", n: "Sejong Medical Co.", c: "Korea" },
  { id: 18765, s: "299660.KQ", n: "Cellid Co.", c: "Korea" },
  { id: 18766, s: "CLR.MC", n: "CLERHP Estructuras S.A", c: "Spain" },
  { id: 18767, s: "DOMO.MC", n: "Domo Activos Socimi SA", c: "Spain" },
  { id: 18768, s: "290650.KQ", n: "L&C Bio Co.", c: "Korea" },
  { id: 18769, s: "290120.KQ", n: "Dayou AP Co.Ltd", c: "Korea" },
  { id: 18770, s: "278280.KQ", n: "Chunbo Co.", c: "Korea" },
  { id: 18771, s: "275630.KQ", n: "SSR Inc", c: "Korea" },
  { id: 18772, s: "265560.KQ", n: "YOUNGHWA TECH Co.", c: "Korea" },
  { id: 18773, s: "264450.KQ", n: "Ubiquoss Inc", c: "Korea" },
  { id: 18774, s: "263720.KQ", n: "D&C Media Co.Ltd", c: "Korea" },
  { id: 18775, s: "ENRS.MC", n: "Enerside Energy S.A.", c: "Spain" },
  { id: 18776, s: "256150.KQ", n: "Handok Clean Tech Co.", c: "Korea" },
  { id: 18777, s: "AVG.V", n: "Avidian Gold Co", c: "CA" },
  { id: 18778, s: "253840.KQ", n: "Sugentech Inc", c: "Korea" },
  { id: 18779, s: "ADE.V", n: "Adex Mining Inc", c: "CA" },
  { id: 18780, s: "246710.KQ", n: "T&R Biofab Co.", c: "Korea" },
  { id: 18781, s: "243840.KQ", n: "Shin Heung Energy & Electronics Co.Ltd", c: "Korea" },
  { id: 18782, s: "AALI.V", n: "Advance Gold Co", c: "CA" },
  { id: 18783, s: "MOLY.NE", n: "Greenland Resources Inc", c: "CA" },
  { id: 18784, s: "308100.KQ", n: "Castelbajac Co.", c: "Korea" },
  { id: 18785, s: "317850.KQ", n: "DAEMO Engineering Co.", c: "Korea" },
  { id: 18786, s: "ATSI.MC", n: "Aplicaciones y Tratamiento de Sistemas S.A.", c: "Spain" },
  { id: 18787, s: "000075.KS", n: "Samyang Holdings Co", c: "Korea" },
  { id: 18788, s: "001515.KS", n: "SK Securities Co Preference Shares", c: "Korea" },
  { id: 18789, s: "190510.KQ", n: "Namuga Co.", c: "Korea" },
  { id: 18790, s: "187270.KQ", n: "Shinhwa Contech Co.", c: "Korea" },
  { id: 18791, s: "CITY.MC", n: "Club De Futbol Intercity Sad", c: "Spain" },
  { id: 18792, s: "206560.KQ", n: "Dexter", c: "Korea" },
  { id: 18793, s: "220260.KQ", n: "LIG-ES SPAC", c: "Korea" },
  { id: 18794, s: "217730.KQ", n: "Kangstem Biotech Co.", c: "Korea" },
  { id: 18795, s: "PRM.MC", n: "Prim S.A.", c: "Spain" },
  { id: 18796, s: "PVA.MC", n: "Pescanova SA", c: "Spain" },
  { id: 18797, s: "214450.KQ", n: "Pharma Reaserch Prod", c: "Korea" },
  { id: 18798, s: "227100.KQ", n: "Design Co.", c: "Korea" },
  { id: 18799, s: "214430.KQ", n: "i3system Inc", c: "Korea" },
  { id: 18800, s: "208340.KQ", n: "PharmAbcine Inc", c: "Korea" },
  { id: 18801, s: "TRG.MC", n: "Tubos Reunidos S.A", c: "Spain" },
  { id: 18802, s: "207760.KQ", n: "Mr. Blue Corporation", c: "Korea" },
  { id: 18803, s: "VOC.MC", n: "Vocento", c: "Spain" },
  { id: 18804, s: "206400.KQ", n: "Korea SPAC 2", c: "Korea" },
  { id: 18805, s: "MONI.MC", n: "Mondo TV Studios S A U", c: "Spain" },
  { id: 18806, s: "YLFG.MC", n: "La Finca Global Assets SOCIMI S.A.", c: "Spain" },
  { id: 18807, s: "YMHRE.MC", n: "Millenium Hotels Real Estate I SOCIMI S.A.", c: "Spain" },
  { id: 18808, s: "204020.KQ", n: "GRITEE Inc", c: "Korea" },
  { id: 18809, s: "201490.KQ", n: "Me2on Co.", c: "Korea" },
  { id: 18810, s: "WPS.L", n: "WAG Payment Solutions PLC", c: "UK" },
  { id: 18811, s: "SEC.MC", n: "Secuoya Grupo de Comunicación S.A", c: "Spain" },
  { id: 18812, s: "001520.KS", n: "Tongyang", c: "Korea" },
  { id: 18813, s: "GOTL-B.ST", n: "Gotland Rederi AB Series B", c: "Sweden" },
  { id: 18814, s: "HAV-B.ST", n: "Havsfrun Investment AB (publ)", c: "Sweden" },
  { id: 18815, s: "SIG.", n: "Scandinavian Investment Group A/S", c: "Denmark" },
  { id: 18816, s: "005990.KQ", n: "Maeil Holdings Co.", c: "Korea" },
  { id: 18817, s: "221840.KQ", n: "Hizeaero Co.", c: "Korea" },
  { id: 18818, s: "222800.KQ", n: "SIMMTECH Co.", c: "Korea" },
  { id: 18819, s: "184230.KQ", n: "SGA Solutions Co.Ltd", c: "Korea" },
  { id: 18820, s: "SERE.L", n: "Schroder European Reit Plc", c: "UK" },
  { id: 18821, s: "179900.KQ", n: "UTI Inc", c: "Korea" },
  { id: 18822, s: "179290.KQ", n: "M.I.TECH Co.Ltd", c: "Korea" },
  { id: 18823, s: "178320.KQ", n: "Seojin System Co.Ltd", c: "Korea" },
  { id: 18824, s: "174900.KQ", n: "AbClon Inc", c: "Korea" },
  { id: 18825, s: "SSTY.L", n: "Safestay PLC", c: "UK" },
  { id: 18826, s: "155650.KQ", n: "Ymc Co.", c: "Korea" },
  { id: 18827, s: "SREI.L", n: "Schroder Real Estate Investment Trust", c: "UK" },
  { id: 18828, s: "153460.KQ", n: "Nable Communications Inc", c: "Korea" },
  { id: 18829, s: "150900.KQ", n: "FASOO.COM", c: "Korea" },
  { id: 18830, s: "SKAN.SW", n: "SKAN Group AG", c: "Switzerland" },
  { id: 18831, s: "147830.KQ", n: "Cheryong Industrial Co.Ltd", c: "Korea" },
  { id: 18832, s: "144510.KQ", n: "Green Cross Lab Cell Corporation", c: "Korea" },
  { id: 18833, s: "224110.KQ", n: "Atec T& Co.", c: "Korea" },
  { id: 18834, s: "143240.KQ", n: "SaraminHR Co.Ltd", c: "Korea" },
  { id: 18835, s: "143160.KQ", n: "Intelligent Digital Integrated Security Co.", c: "Korea" },
  { id: 18836, s: "142760.KQ", n: "Bioleaders Corporation", c: "Korea" },
  { id: 18837, s: "XLS.SW", n: "Xlife Sciences AG", c: "Switzerland" },
  { id: 18838, s: "ZUBN.SW", n: "Zueblin Immobilien Holding AG", c: "Switzerland" },
  { id: 18839, s: "URAH.L", n: "URA Holdings PLC", c: "UK" },
  { id: 18840, s: "VCAP.L", n: "Vector Capital PLC", c: "UK" },
  { id: 18841, s: "196170.KQ", n: "ALTEOGEN Inc", c: "Korea" },
  { id: 18842, s: "ASWN.SW", n: "Asmallworld AG", c: "Switzerland" },
  { id: 18843, s: "225570.KQ", n: "NH SPAC 8", c: "Korea" },
  { id: 18844, s: "JPM.NE", n: "JPMorgan Chase & Co CDR", c: "CA" },
  { id: 18845, s: "300120.KQ", n: "LAON PEOPLE Inc", c: "Korea" },
  { id: 18846, s: "AIS.V", n: "AIS Resources", c: "CA" },
  { id: 18847, s: "115960.KQ", n: "Yonwoo Co.", c: "Korea" },
  { id: 18848, s: "123840.KQ", n: "Hanil Vacuum Co.", c: "Korea" },
  { id: 18849, s: "SAG.L", n: "Science Group plc", c: "UK" },
  { id: 18850, s: "133750.KQ", n: "MegaMD Co.", c: "Korea" },
  { id: 18851, s: "131760.KQ", n: "Finetek Co.", c: "Korea" },
  { id: 18852, s: "ROOF.L", n: "Atrato Onsite Energy PLC", c: "UK" },
  { id: 18853, s: "131180.KQ", n: "Dilli Illustrate Inc", c: "Korea" },
  { id: 18854, s: "131090.KQ", n: "Secuve Co.", c: "Korea" },
  { id: 18855, s: "130580.KQ", n: "Nice D&B Co.", c: "Korea" },
  { id: 18856, s: "RE.L", n: "R.E.A. Holdings plc", c: "UK" },
  { id: 18857, s: "RCGH.L", n: "RC365 Holding PLC", c: "UK" },
  { id: 18858, s: "PRE.L", n: "Pensana Rare Earths Plc", c: "UK" },
  { id: 18859, s: "124500.KQ", n: "Itcen Co.", c: "Korea" },
  { id: 18860, s: "POLB.L", n: "Poolbeg Pharma PLC", c: "UK" },
  { id: 18861, s: "IPR.LS", n: "Impresa - Sociedade Gestora de Participações Sociais S.A", c: "Portugal" },
  { id: 18862, s: "123570.KQ", n: "eMnet Inc", c: "Korea" },
  { id: 18863, s: "123420.KQ", n: "SundayToz Co", c: "Korea" },
  { id: 18864, s: "VALUE.AS", n: "Value8 N.V", c: "Netherlands" },
  { id: 18865, s: "123330.KQ", n: "Genic Co.", c: "Korea" },
  { id: 18866, s: "123040.KQ", n: "MS Autotech Co.Ltd", c: "Korea" },
  { id: 18867, s: "123010.KQ", n: "POLARIS WORKS Inc", c: "Korea" },
  { id: 18868, s: "PIER.L", n: "Brighton Pier Group PLC", c: "UK" },
  { id: 18869, s: "PHC.L", n: "Plant Health Care", c: "UK" },
  { id: 18870, s: "094840.KQ", n: "Suprema HQ Inc", c: "Korea" },
  { id: 18871, s: "094360.KQ", n: "Chips&Media Inc", c: "Korea" },
  { id: 18872, s: "BOKUS.ST", n: "Bokusgruppen AB", c: "Sweden" },
  { id: 18873, s: "LIG.LS", n: "Lisgrafica", c: "Portugal" },
  { id: 18874, s: "138070.KQ", n: "Shinjin Sm Co.Ltd", c: "Korea" },
  { id: 18875, s: "ECC-B.ST", n: "Ecoclime Group AB Series B", c: "Sweden" },
  { id: 18876, s: "AVT-B.ST", n: "AVTECH Sweden AB Series B", c: "Sweden" },
  { id: 18877, s: "115500.KQ", n: "Korea Computer & Systems Inc", c: "Korea" },
  { id: 18878, s: "115160.KQ", n: "Humax Co.", c: "Korea" },
  { id: 18879, s: "110990.KQ", n: "Digital Imaging Technology INC", c: "Korea" },
  { id: 18880, s: "109960.KQ", n: "Aprogen Healthcare & Games Inc", c: "Korea" },
  { id: 18881, s: "109610.KQ", n: "S.Y.PANEL", c: "Korea" },
  { id: 18882, s: "108380.KQ", n: "DAEYANG ELECTRIC.Co.Ltd", c: "Korea" },
  { id: 18883, s: "108230.KQ", n: "TOPTEC Co.", c: "Korea" },
  { id: 18884, s: "104200.KQ", n: "NHN Bugs Co", c: "Korea" },
  { id: 18885, s: "104040.KQ", n: "Daesung Finetec Co.Ltd", c: "Korea" },
  { id: 18886, s: "102710.KQ", n: "ENF Technology Co.", c: "Korea" },
  { id: 18887, s: "101390.KQ", n: "IM Co.Ltd", c: "Korea" },
  { id: 18888, s: "100660.KQ", n: "Seoam Machinery Industry Co.Ltd", c: "Korea" },
  { id: 18889, s: "ACQ-SPAC.ST", n: "ACQ Bure AB", c: "Sweden" },
  { id: 18890, s: "099410.KQ", n: "Dongbang Ship Machinery Co.", c: "Korea" },
  { id: 18891, s: "098660.KQ", n: "STO Co.", c: "Korea" },
  { id: 18892, s: "097800.KQ", n: "Winpac Inc", c: "Korea" },
  { id: 18893, s: "096690.KQ", n: "Aroot Co.", c: "Korea" },
  { id: 18894, s: "BIM.ST", n: "BIMobject AB", c: "Sweden" },
  { id: 18895, s: "095660.KQ", n: "NEOWIZ", c: "Korea" },
  { id: 18896, s: "095340.KQ", n: "ISC Co.", c: "Korea" },
  { id: 18897, s: "OCTP.L", n: "Oxford Cannabinoid Technologies Holdings PLC", c: "UK" },
  { id: 18898, s: "ADVBOX.ST", n: "Adventure Box Technology AB", c: "Sweden" },
  { id: 18899, s: "OBC.L", n: "Online Blockchain PLC", c: "UK" },
  { id: 18900, s: "119500.KQ", n: "Formetal Co.", c: "Korea" },
  { id: 18901, s: "080720.KQ", n: "Union Korea Pharm Co.", c: "Korea" },
  { id: 18902, s: "080530.KQ", n: "Kodi-S", c: "Korea" },
  { id: 18903, s: "307930.KQ", n: "Company K Partners Limited", c: "Korea" },
  { id: 18904, s: "088290.KQ", n: "Ewon Comfortech Co.", c: "Korea" },
  { id: 18905, s: "092040.KQ", n: "Amicogen Inc", c: "Korea" },
  { id: 18906, s: "BTS-B.ST", n: "BTS Group AB (publ)", c: "Sweden" },
  { id: 18907, s: "090850.KQ", n: "Ezwelfare", c: "Korea" },
  { id: 18908, s: "NTEA.L", n: "Northern Electric plc 8.061P(NET)CUM IRRD PRF 1P", c: "UK" },
  { id: 18909, s: "CBTT-B.ST", n: "Christian Berner Tech Trade AB (publ)", c: "Sweden" },
  { id: 18910, s: "CCOR-B.ST", n: "Concordia Maritime AB (publ)", c: "Sweden" },
  { id: 18911, s: "138580.KQ", n: "BusinessOn Communication Co.", c: "Korea" },
  { id: 18912, s: "089600.KQ", n: "Nasmedia Co.", c: "Korea" },
  { id: 18913, s: "CHRO.ST", n: "ChromoGenics AB", c: "Sweden" },
  { id: 18914, s: "CISH.ST", n: "Clean Industry Solutions Holding Europe AB", c: "Sweden" },
  { id: 18915, s: "089010.KQ", n: "CHEMTRONICS.CO.Ltd", c: "Korea" },
  { id: 18916, s: "WAKE", n: "Wake Forest Bancshares Inc", c: "US" },
  { id: 18917, s: "092600.KQ", n: "NEXTCHIP Co.", c: "Korea" },
  { id: 18918, s: "CORE-A.ST", n: "Corem Property Group AB (publ)", c: "Sweden" },
  { id: 18919, s: "084650.KQ", n: "LabGenomics Co.", c: "Korea" },
  { id: 18920, s: "084370.KQ", n: "Eugene Technology Co.Ltd", c: "Korea" },
  { id: 18921, s: "NAS.L", n: "North Atlantic Smaller Companies Investment Trust PLC", c: "UK" },
  { id: 18922, s: "CTEK.ST", n: "Ctek AB", c: "Sweden" },
  { id: 18923, s: "007120.KS", n: "Mirae ING", c: "Korea" },
  { id: 18924, s: "DE.ST", n: "Dala Energi AB", c: "Sweden" },
  { id: 18925, s: "NSI.L", n: "New Star Investment Trust plc", c: "UK" },
  { id: 18926, s: "SBO.L", n: "Schroder British Opportunities Trus", c: "UK" },
  { id: 18927, s: "140410.KQ", n: "Mezzion Pharma Co.Ltd", c: "Korea" },
  { id: 18928, s: "SCP.L", n: "Schroder UK Mid Cap Fund PLC", c: "UK" },
  { id: 18929, s: "092130.KQ", n: "e-Credible Co.", c: "Korea" },
  { id: 18930, s: "092870.KQ", n: "Exicon Co.", c: "Korea" },
  { id: 18931, s: "080420.KQ", n: "Moda-InnoChips Co.", c: "Korea" },
  { id: 18932, s: "FPAR-D.ST", n: "FastPartner AB Series D", c: "Sweden" },
  { id: 18933, s: "EGTX.ST", n: "Egetis Therapeutics AB (publ)", c: "Sweden" },
  { id: 18934, s: "080010.KQ", n: "eSang Networks Co.Ltd", c: "Korea" },
  { id: 18935, s: "MTW.L", n: "Mattioli Woods plc", c: "UK" },
  { id: 18936, s: "078600.KQ", n: "Daejoo Electronic Materials Co.", c: "Korea" },
  { id: 18937, s: "ENRO.ST", n: "Eniro AB", c: "Sweden" },
  { id: 18938, s: "MTC.L", n: "Mothercare PLC", c: "UK" },
  { id: 18939, s: "MRIT.L", n: "Merit Group PLC", c: "UK" },
  { id: 18940, s: "FAGA.ST", n: "Fantasma Games AB", c: "Sweden" },
  { id: 18941, s: "074600.KQ", n: "Wonik QnC Corporation", c: "Korea" },
  { id: 18942, s: "FLEXQ.ST", n: "FlexQube AB", c: "Sweden" },
  { id: 18943, s: "MIRI.L", n: "Mirriad Advertising PLC", c: "UK" },
  { id: 18944, s: "MIN.L", n: "Minoan Group", c: "UK" },
  { id: 18945, s: "NWOR.L", n: "National World PLC", c: "UK" },
  { id: 18946, s: "GBK.ST", n: "Goodbye Kansas Group AB (publ)", c: "Sweden" },
  { id: 18947, s: "068930.KQ", n: "Digital Daesung Co.", c: "Korea" },
  { id: 18948, s: "067900.KQ", n: "Y-Entec Co.", c: "Korea" },
  { id: 18949, s: "067630.KQ", n: "HLB Life Science Co.", c: "Korea" },
  { id: 18950, s: "067280.KQ", n: "Multicampus Corporation", c: "Korea" },
  { id: 18951, s: "067170.KQ", n: "Autech Corporation", c: "Korea" },
  { id: 18952, s: "082270.KQ", n: "GemVax&KAEL Co.Ltd", c: "Korea" },
  { id: 18953, s: "DIST.ST", n: "DistIT AB (publ)", c: "Sweden" },
  { id: 18954, s: "DIABIO.ST", n: "Diagonal Bio AB", c: "Sweden" },
  { id: 18955, s: "083650.KQ", n: "BHI Co.", c: "Korea" },
  { id: 18956, s: "093520.KQ", n: "MAKUS Inc", c: "Korea" },
  { id: 18957, s: "007460.KS", n: "Aprogen KIC Inc", c: "Korea" },
  { id: 18958, s: "PNLSN.IS", n: "Panelsan Cati Cephe Sistemleri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 18959, s: "ELEC.V", n: "Electric Royalties", c: "CA" },
  { id: 18960, s: "CIBY", n: "Cibl Inc.", c: "US" },
  { id: 18961, s: "NXS.AX", n: "Next Science", c: "Australia" },
  { id: 18962, s: "NYM.AX", n: "Narryer Metals", c: "Australia" },
  { id: 18963, s: "NZS.AX", n: "New Zealand Coastal Seafoods", c: "Australia" },
  { id: 18964, s: "1219.TW", n: "Fwusow Industry", c: "TW" },
  { id: 18965, s: "300416.SZ", n: "Suzhou Sushi Testing Instrument", c: "CH" },
  { id: 18966, s: "3594.TWO", n: "Arbor Technology", c: "TW" },
  { id: 18967, s: "OAR.AX", n: "OAR Resources", c: "Australia" },
  { id: 18968, s: "OCC.AX", n: "Orthocell", c: "Australia" },
  { id: 18969, s: "LCE.AX", n: "London City Equities", c: "Australia" },
  { id: 18970, s: "2387.TW", n: "Sunrex Technology Co", c: "TW" },
  { id: 18971, s: "KLSYN.IS", n: "Koleksiyon Mobilya Sanayi AS", c: "Turkey" },
  { id: 18972, s: "OCLDY", n: "Orica ADR", c: "US" },
  { id: 18973, s: "002487.SZ", n: "Dajin Heavy Industry Co", c: "CH" },
  { id: 18974, s: "ODM.AX", n: "Odin Metals", c: "Australia" },
  { id: 18975, s: "L1M.AX", n: "Lightning Minerals", c: "Australia" },
  { id: 18976, s: "ODY.AX", n: "Odyssey Energy", c: "Australia" },
  { id: 18977, s: "1217.TW", n: "AGV Products Co", c: "TW" },
  { id: 18978, s: "002838.SZ", n: "Shandong Dawn Polymer", c: "CH" },
  { id: 18979, s: "300394.SZ", n: "Suzhou TFC Optical Communication", c: "CH" },
  { id: 18980, s: "KSTUR.IS", n: "Kustur Kusadasi Turizm Endustrisi AS", c: "Turkey" },
  { id: 18981, s: "AGLXY", n: "AGL Energy", c: "US" },
  { id: 18982, s: "MMCAS.IS", n: "MMC Sanayi ve Ticari Yatirimlar AS", c: "Turkey" },
  { id: 18983, s: "NSX.AX", n: "Nsx", c: "Australia" },
  { id: 18984, s: "3004.TW", n: "National Aerospace Fasteners Co", c: "TW" },
  { id: 18985, s: "300395.SZ", n: "Hubei Feilihua Quartz Glass", c: "CH" },
  { id: 18986, s: "NXM.AX", n: "Nexus Minerals", c: "Australia" },
  { id: 18987, s: "1220.TW", n: "Tai Roun Products", c: "TW" },
  { id: 18988, s: "MNDRS.IS", n: "Menderes Tekstil Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 18989, s: "002505.SZ", n: "Pengdu Agriculture & Animal Husbandry", c: "CH" },
  { id: 18990, s: "NRX.AX", n: "Noronex", c: "Australia" },
  { id: 18991, s: "300374.SZ", n: "CH Railway Prefabricated Construction", c: "CH" },
  { id: 18992, s: "1470.TW", n: "Evertex Fabrinology", c: "TW" },
  { id: 18993, s: "OYYAT.IS", n: "Oyak Yatirim Menkul Degerler AS", c: "Turkey" },
  { id: 18994, s: "OLVRF", n: "Olivut Resources", c: "US" },
  { id: 18995, s: "300369.SZ", n: "Nsfocus Information Technology", c: "CH" },
  { id: 18996, s: "2506.TW", n: "Pacific Construction", c: "TW" },
  { id: 18997, s: "HYSNY", n: "Hysan Development", c: "US" },
  { id: 18998, s: "MCM.AX", n: "MC Mining", c: "Australia" },
  { id: 18999, s: "PINSU.IS", n: "Pinar Su Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19000, s: "002845.SZ", n: "Shenzhen TXD Technology", c: "CH" },
  { id: 19001, s: "1455.TW", n: "Zig Sheng Industrial", c: "TW" },
  { id: 19002, s: "300391.SZ", n: "Kangyue Technology", c: "CH" },
  { id: 19003, s: "PENGD.IS", n: "Penguen Gida Sanayi AS", c: "Turkey" },
  { id: 19004, s: "NME.AX", n: "Nex Metals Explorations", c: "Australia" },
  { id: 19005, s: "NML.AX", n: "Navarre Minerals", c: "Australia" },
  { id: 19006, s: "MCA.AX", n: "Murray Cod Australia", c: "Australia" },
  { id: 19007, s: "MBK.AX", n: "Metal Bank", c: "Australia" },
  { id: 19008, s: "6016.TWO", n: "Concord Securities", c: "TW" },
  { id: 19009, s: "3666.TWO", n: "Optivision Technology", c: "TW" },
  { id: 19010, s: "PAMEL.IS", n: "Pamel Yenilenebilir Elektrik Uretim AS", c: "Turkey" },
  { id: 19011, s: "002501.SZ", n: "Jilin Liyuan Precision Manufacturing", c: "CH" },
  { id: 19012, s: "MAT.AX", n: "Matsa Resources", c: "Australia" },
  { id: 19013, s: "2399.TW", n: "Biostar Microtech International Co", c: "TW" },
  { id: 19014, s: "MEGAP.IS", n: "Mega Polietilen Kopuk Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19015, s: "LRT.AX", n: "Lowell Resources Fund", c: "Australia" },
  { id: 19016, s: "300263.SZ", n: "Luoyang Longhua Heat Trans Energy", c: "CH" },
  { id: 19017, s: "MSI.AX", n: "Multistack International", c: "Australia" },
  { id: 19018, s: "MM8.AX", n: "Medallion Metals", c: "Australia" },
  { id: 19019, s: "MXI.AX", n: "Maxiparts", c: "Australia" },
  { id: 19020, s: "1414.TW", n: "Tung Ho Textile", c: "TW" },
  { id: 19021, s: "MXO.AX", n: "Motio", c: "Australia" },
  { id: 19022, s: "TMPOL.IS", n: "Temapol Polimer Plastik ve Insaat Sanayi Ticaret AS", c: "Turkey" },
  { id: 19023, s: "MLS.AX", n: "Metals Australia", c: "Australia" },
  { id: 19024, s: "MVL.AX", n: "Marvel Gold", c: "Australia" },
  { id: 19025, s: "MLM.AX", n: "Metallica Minerals", c: "Australia" },
  { id: 19026, s: "MOZ.AX", n: "Mosaic Brands", c: "Australia" },
  { id: 19027, s: "MPA.AX", n: "Mad Paws Holdings", c: "Australia" },
  { id: 19028, s: "300319.SZ", n: "Shenzhen Microgate Technology", c: "CH" },
  { id: 19029, s: "1436.TW", n: "HUA YU LIEN Development", c: "TW" },
  { id: 19030, s: "TRILC.IS", n: "Turk Ilac ve Serum Sanayi AS", c: "Turkey" },
  { id: 19031, s: "300304.SZ", n: "Jiangsu Yunyi Electric", c: "CH" },
  { id: 19032, s: "MRZ.AX", n: "Mont Royal Resources", c: "Australia" },
  { id: 19033, s: "4120.TWO", n: "Orient Europharma", c: "TW" },
  { id: 19034, s: "MRR.AX", n: "Minrex Resources", c: "Australia" },
  { id: 19035, s: "MRQ.AX", n: "MRG Metals", c: "Australia" },
  { id: 19036, s: "YKSLN.IS", n: "Yukselen Celik As", c: "Turkey" },
  { id: 19037, s: "YGYO.IS", n: "Yesil Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 19038, s: "MTM.AX", n: "MTM Critical Metals Limited", c: "Australia" },
  { id: 19039, s: "002524.SZ", n: "Guangzheng Group", c: "CH" },
  { id: 19040, s: "300328.SZ", n: "Dongguan Eontec", c: "CH" },
  { id: 19041, s: "MRD.AX", n: "Mount Ridley Mines", c: "Australia" },
  { id: 19042, s: "002846.SZ", n: "Guangdong Enpack Packaging", c: "CH" },
  { id: 19043, s: "1442.TW", n: "Advancetek Enterprise", c: "TW" },
  { id: 19044, s: "NUH.AX", n: "Nuheara", c: "Australia" },
  { id: 19045, s: "002492.SZ", n: "Zhuhai Winbase International Chemical Tank Terminal", c: "CH" },
  { id: 19046, s: "300405.SZ", n: "Liaoning Kelong Fine ChemicalInc", c: "CH" },
  { id: 19047, s: "300261.SZ", n: "Aba Chemicals Co", c: "CH" },
  { id: 19048, s: "LUKSK.IS", n: "Luks Kadife Ticaret ve Sanayi AS", c: "Turkey" },
  { id: 19049, s: "002853.SZ", n: "Guangdong Piano Customized Furniture", c: "CH" },
  { id: 19050, s: "3615.TWO", n: "AimCore Technology", c: "TW" },
  { id: 19051, s: "002860.SZ", n: "Hangzhou Star Shuaier Electric Appliance", c: "CH" },
  { id: 19052, s: "002842.SZ", n: "Guangdong Xianglu Tungsten", c: "CH" },
  { id: 19053, s: "3611.TWO", n: "TSC Auto ID Technology", c: "TW" },
  { id: 19054, s: "2495.TW", n: "Infortrend Technology Inc", c: "TW" },
  { id: 19055, s: "LM1.AX", n: "Leeuwin Metals.", c: "Australia" },
  { id: 19056, s: "LINK.IS", n: "Link Bilgisayar Sistemleri Yazilimi ve Donanimi Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19057, s: "KPELY", n: "Keppel Corporation Limited", c: "US" },
  { id: 19058, s: "002528.SZ", n: "Shenzhen Infinova", c: "CH" },
  { id: 19059, s: "2908.TW", n: "Test Rite International", c: "TW" },
  { id: 19060, s: "MEA.AX", n: "McGrath", c: "Australia" },
  { id: 19061, s: "MOT.AX", n: "Metrics Income Opportunities Trust Unit", c: "Australia" },
  { id: 19062, s: "002515.SZ", n: "Jinzi Ham", c: "CH" },
  { id: 19063, s: "300335.SZ", n: "Guangzhou Devotion Therm Tech", c: "CH" },
  { id: 19064, s: "300337.SZ", n: "Yinbang Clad Material", c: "CH" },
  { id: 19065, s: "MWY.AX", n: "Midway", c: "Australia" },
  { id: 19066, s: "ULUSE.IS", n: "Ulusoy Elektrik Imalat", c: "Turkey" },
  { id: 19067, s: "ULUFA.IS", n: "Ulusal Faktoring AS", c: "Turkey" },
  { id: 19068, s: "MNB.AX", n: "Minbos Resources", c: "Australia" },
  { id: 19069, s: "2405.TW", n: "Shuttle Inc", c: "TW" },
  { id: 19070, s: "M24.AX", n: "Mamba Exploration", c: "Australia" },
  { id: 19071, s: "300387.SZ", n: "Hubei Forbon Technology", c: "CH" },
  { id: 19072, s: "NPM.AX", n: "New Peak Metals Limited", c: "Australia" },
  { id: 19073, s: "300851.SZ", n: "Beijing Jiaoda Signal Technology", c: "CH" },
  { id: 19074, s: "2444.TW", n: "Abocom Systems Inc", c: "TW" },
  { id: 19075, s: "5515.TW", n: "Chien Kuo Construction", c: "TW" },
  { id: 19076, s: "300864.SZ", n: "Nanjing University Environmental", c: "CH" },
  { id: 19077, s: "002154.SZ", n: "Zhejiang Baoxiniao Garment", c: "CH" },
  { id: 19078, s: "5236.TWO", n: "Sunplus Innovation Technology Inc.", c: "TW" },
  { id: 19079, s: "6542.TWO", n: "GameSparcs", c: "TW" },
  { id: 19080, s: "5478.TWO", n: "Soft-World International", c: "TW" },
  { id: 19081, s: "6183.TW", n: "Trade-Van Information Services", c: "TW" },
  { id: 19082, s: "002408.SZ", n: "Zibo Qixiang Tengda Chemical", c: "CH" },
  { id: 19083, s: "MSV.AX", n: "Mitchell Services", c: "Australia" },
  { id: 19084, s: "002171.SZ", n: "Anhui Jingcheng Copper Share", c: "CH" },
  { id: 19085, s: "002173.SZ", n: "Innovative Medical Management", c: "CH" },
  { id: 19086, s: "5344.TWO", n: "Vate Technology", c: "TW" },
  { id: 19087, s: "3055.TW", n: "Spirox Co", c: "TW" },
  { id: 19088, s: "300856.SZ", n: "Nanjing Cosmos Chemical ", c: "CH" },
  { id: 19089, s: "6142.TW", n: "Cameo Communications Inc", c: "TW" },
  { id: 19090, s: "USL.AX", n: "Unico Silver.", c: "Australia" },
  { id: 19091, s: "1815.TWO", n: "Fulltech Fiber Glass", c: "TW" },
  { id: 19092, s: "300858.SZ", n: "Beijing Scitop Bio Tech ", c: "CH" },
  { id: 19093, s: "002182.SZ", n: "Nanjing Yunhai Special Metals", c: "CH" },
  { id: 19094, s: "300870.SZ", n: "Shenzhen Honor Electronic ", c: "CH" },
  { id: 19095, s: "300078.SZ", n: "Hangzhou Century", c: "CH" },
  { id: 19096, s: "6506.TWO", n: "Shuang-Bang Industrial", c: "TW" },
  { id: 19097, s: "002763.SZ", n: "Shenzhen Huijie Group", c: "CH" },
  { id: 19098, s: "002206.SZ", n: "Zhejiang Hailide New Material", c: "CH" },
  { id: 19099, s: "300891.SZ", n: "Guangdong Huiyun Titanium Industry", c: "CH" },
  { id: 19100, s: "002713.SZ", n: "Dong Yi Ri Sheng Home Decoration Group", c: "CH" },
  { id: 19101, s: "300878.SZ", n: "Wecome Pharmaceutical", c: "CH" },
  { id: 19102, s: "002343.SZ", n: "Ciwen Media", c: "CH" },
  { id: 19103, s: "6210.TWO", n: "Kintech Electronics", c: "TW" },
  { id: 19104, s: "002971.SZ", n: "Hubei Heyuan Gas ", c: "CH" },
  { id: 19105, s: "002344.SZ", n: "Haining CH Leather Market", c: "CH" },
  { id: 19106, s: "NKRKY", n: "Nokian Tyres Plc ADR", c: "US" },
  { id: 19107, s: "3528.TW", n: "Answer Technology", c: "TW" },
  { id: 19108, s: "6198.TWO", n: "Averlogic Technologies", c: "TW" },
  { id: 19109, s: "002276.SZ", n: "Zhejiang Wanma", c: "CH" },
  { id: 19110, s: "NAFS", n: "North America Frac Sand Inc", c: "US" },
  { id: 19111, s: "CLPHF", n: "CLP Holdings", c: "US" },
  { id: 19112, s: "MPML", n: "MPM Technologies Inc", c: "US" },
  { id: 19113, s: "300880.SZ", n: "Ningbo Jianan Electronics ", c: "CH" },
  { id: 19114, s: "MPXOF", n: "MPX International Co", c: "US" },
  { id: 19115, s: "300070.SZ", n: "Beijing Originwater Technology", c: "CH" },
  { id: 19116, s: "002187.SZ", n: "Guangzhou Grandbuy", c: "CH" },
  { id: 19117, s: "1595.TWO", n: "Chime Ball Technology", c: "TW" },
  { id: 19118, s: "002190.SZ", n: "Sichuan Chengfei Integration Technology Co", c: "CH" },
  { id: 19119, s: "CDO.AX", n: "Cadence Opportunities Fund", c: "Australia" },
  { id: 19120, s: "4999.TW", n: "Sinher Technology Inc", c: "TW" },
  { id: 19121, s: "6508.TWO", n: "Huikwang", c: "TW" },
  { id: 19122, s: "002197.SZ", n: "SZZT Electronics", c: "CH" },
  { id: 19123, s: "002987.SZ", n: "Northking Information Technology", c: "CH" },
  { id: 19124, s: "002201.SZ", n: "Jiangsu Jiuding New Material", c: "CH" },
  { id: 19125, s: "5309.TWO", n: "Sysgration", c: "TW" },
  { id: 19126, s: "002255.SZ", n: "Suzhou Hailu Heavy Industry", c: "CH" },
  { id: 19127, s: "6174.TWO", n: "Aker Technology", c: "TW" },
  { id: 19128, s: "MTRKS.IS", n: "Matriks Bilgi Dagitim Hizmetleri AS", c: "Turkey" },
  { id: 19129, s: "MAN.AX", n: "Mandrake Resources", c: "Australia" },
  { id: 19130, s: "300051.SZ", n: "Xiamen 35Com Tech", c: "CH" },
  { id: 19131, s: "4952.TW", n: "Generalplus Technology Inc", c: "TW" },
  { id: 19132, s: "002232.SZ", n: "Qiming Information Technology", c: "CH" },
  { id: 19133, s: "6457.TWO", n: "Hycon Technology", c: "TW" },
  { id: 19134, s: "3056.TW", n: "ZongTai Real Estate Development", c: "TW" },
  { id: 19135, s: "300043.SZ", n: "Rastar Group", c: "CH" },
  { id: 19136, s: "COR", n: "Cencora Inc.", c: "US" },
  { id: 19137, s: "002252.SZ", n: "Shanghai RAAS Blood Products Co Class A", c: "CH" },
  { id: 19138, s: "NXGB", n: "Pyramidion Technology Group Inc", c: "US" },
  { id: 19139, s: "300625.SZ", n: "Guangdong PAK Corporation", c: "CH" },
  { id: 19140, s: "300173.SZ", n: "Funeng Oriental Equipment Technology", c: "CH" },
  { id: 19141, s: "ORGE.IS", n: "Orge Enerji Elektrik Taahhut AS", c: "Turkey" },
  { id: 19142, s: "002405.SZ", n: "Navinfo", c: "CH" },
  { id: 19143, s: "002498.SZ", n: "Qingdao Hanhe Cable", c: "CH" },
  { id: 19144, s: "NOR.AX", n: "Norwood Systems Limited", c: "Australia" },
  { id: 19145, s: "MAG.AX", n: "Magmatic Resources", c: "Australia" },
  { id: 19146, s: "NETAS.IS", n: "Netas Telekomunikasyon AS", c: "Turkey" },
  { id: 19147, s: "300383.SZ", n: "Beijing Sinnet Technology", c: "CH" },
  { id: 19148, s: "MAET.AX", n: "Munro Global Growth Fund (Hedged Fund)", c: "Australia" },
  { id: 19149, s: "NOU.AX", n: "Noumi", c: "Australia" },
  { id: 19150, s: "NOV.AX", n: "Novatti Group", c: "Australia" },
  { id: 19151, s: "BRGO", n: "Bergio Intl Inc", c: "US" },
  { id: 19152, s: "002529.SZ", n: "Jiangxi Haiyuan Composites Technology", c: "CH" },
  { id: 19153, s: "NOX.AX", n: "Noxopharm", c: "Australia" },
  { id: 19154, s: "4960.TW", n: "Cheng Mei Materials Technology Co", c: "TW" },
  { id: 19155, s: "002229.SZ", n: "Hongbo", c: "CH" },
  { id: 19156, s: "002402.SZ", n: "Shenzhen H&T Intelligent Control", c: "CH" },
  { id: 19157, s: "4545.TW", n: "Min Aik Precision Industrial", c: "TW" },
  { id: 19158, s: "002982.SZ", n: "Hunan Xiangjia Animal Husbandry", c: "CH" },
  { id: 19159, s: "POCHTECB.MX", n: "Grupo Pochteca S.A.B. de C.V", c: "Mexi" },
  { id: 19160, s: "2434.TW", n: "Mospec Semiconductor Co", c: "TW" },
  { id: 19161, s: "2852.TW", n: "First Insurance", c: "TW" },
  { id: 19162, s: "5356.TWO", n: "Sirtec International", c: "TW" },
  { id: 19163, s: "300035.SZ", n: "Hunan Zhongke Electric", c: "CH" },
  { id: 19164, s: "FBIP", n: "FNB Bancorp Inc", c: "US" },
  { id: 19165, s: "4562.TW", n: "Ying Han Technology", c: "TW" },
  { id: 19166, s: "2431.TW", n: "Lien Chang Electronic Enterprise", c: "TW" },
  { id: 19167, s: "002272.SZ", n: "Sichuan Crun", c: "CH" },
  { id: 19168, s: "002761.SZ", n: "Zhejiang Construction Investment Group", c: "CH" },
  { id: 19169, s: "002668.SZ", n: "Guangdong Homa Appliances", c: "CH" },
  { id: 19170, s: "BVERS", n: "Beaver Coal", c: "US" },
  { id: 19171, s: "002209.SZ", n: "Guangzhou Tech-Long Packing Machine", c: "CH" },
  { id: 19172, s: "AUNXF", n: "AXP Energy", c: "US" },
  { id: 19173, s: "002248.SZ", n: "Weihai Huadong Automation", c: "CH" },
  { id: 19174, s: "VTM.AX", n: "Victory Metals", c: "Australia" },
  { id: 19175, s: "1259.TWO", n: "An-Shin Food Services", c: "TW" },
  { id: 19176, s: "FNMFM", n: "Federal National Mortgage Association", c: "US" },
  { id: 19177, s: "WAA.AX", n: "Wam Active", c: "Australia" },
  { id: 19178, s: "2038.TW", n: "Hai Kwang Enterprise Co", c: "TW" },
  { id: 19179, s: "4967.TW", n: "Team Group Inc", c: "TW" },
  { id: 19180, s: "1240.TWO", n: "Morn Sun Feed Mill Co", c: "TW" },
  { id: 19181, s: "002227.SZ", n: "Shenzhen Auto Electric Power Plant", c: "CH" },
  { id: 19182, s: "MRC.AX", n: "Mineral Commodities", c: "Australia" },
  { id: 19183, s: "300331.SZ", n: "SVG Optronics", c: "CH" },
  { id: 19184, s: "002516.SZ", n: "Kuangda Technology Group", c: "CH" },
  { id: 19185, s: "8923.TWO", n: "CH Times Publishing", c: "TW" },
  { id: 19186, s: "8937.TWO", n: "Her Chee Industrial", c: "TW" },
  { id: 19187, s: "2482.TW", n: "Uniform Industrial Co", c: "TW" },
  { id: 19188, s: "300239.SZ", n: "Baotou Dongbao Bio-Tech", c: "CH" },
  { id: 19189, s: "300471.SZ", n: "Chengdu Huaqi Houpu Holding", c: "CH" },
  { id: 19190, s: "4305.TWO", n: "Shih Kuen Plastics", c: "TW" },
  { id: 19191, s: "IHYAY.IS", n: "Ihlas Yayin Holding A.S.", c: "Turkey" },
  { id: 19192, s: "300235.SZ", n: "Shenzhen Kingsun Science Tech", c: "CH" },
  { id: 19193, s: "300230.SZ", n: "Shanghai Yongli Belting", c: "CH" },
  { id: 19194, s: "IHGZT.IS", n: "Ihlas Gazetecilik AS", c: "Turkey" },
  { id: 19195, s: "PGD.AX", n: "Peregrine Gold", c: "Australia" },
  { id: 19196, s: "IDGYO.IS", n: "Idealist Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 19197, s: "003029.SZ", n: "Jilin University Zhengyuan Info Tech", c: "CH" },
  { id: 19198, s: "IPEKE.IS", n: "Ipek Dogal Enerji Kaynaklari Arastirma ve Uretim AS", c: "Turkey" },
  { id: 19199, s: "PGG.AX", n: "Partners Group Global Income Fund", c: "Australia" },
  { id: 19200, s: "ICBCT.IS", n: "ICBC Turkey Bank AS", c: "Turkey" },
  { id: 19201, s: "ERSU.IS", n: "ERSU Meyve ve Gida Sanayi AS", c: "Turkey" },
  { id: 19202, s: "IPRC", n: "Imperial Res Inc", c: "US" },
  { id: 19203, s: "300461.SZ", n: "Tanac Automation", c: "CH" },
  { id: 19204, s: "HUNER.IS", n: "Hun Yenilenebilir Enerji Uretim AS", c: "Turkey" },
  { id: 19205, s: "8938.TWO", n: "Advanced International Multitech", c: "TW" },
  { id: 19206, s: "1514.TW", n: "Allis Electric", c: "TW" },
  { id: 19207, s: "002486.SZ", n: "Shanghai Challenge Textile", c: "CH" },
  { id: 19208, s: "KAREL.IS", n: "Karel Elektronik Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19209, s: "002861.SZ", n: "Hubei Yingtong Telecommunication Cable", c: "CH" },
  { id: 19210, s: "PAC.AX", n: "Pacific Current Group", c: "Australia" },
  { id: 19211, s: "ESCAR.IS", n: "Escar Turizm Tasimacilik Ticaret AS", c: "Turkey" },
  { id: 19212, s: "IZINV.IS", n: "Iz Hayvancilik Tarim ve Gida Sanayi Ticaret AS", c: "Turkey" },
  { id: 19213, s: "6114.TWO", n: "Juic International", c: "TW" },
  { id: 19214, s: "3388.TWO", n: "Topco Technologies", c: "TW" },
  { id: 19215, s: "ARZUM.IS", n: "Arzum Elektrikli Ev Aletleri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19216, s: "300584.SZ", n: "Nanjing Hicin Pharmaceutical", c: "CH" },
  { id: 19217, s: "RBX.AX", n: "Resource Base", c: "Australia" },
  { id: 19218, s: "300563.SZ", n: "Shenyu Communication Technology Inc", c: "CH" },
  { id: 19219, s: "002823.SZ", n: "Shenzhen Kaizhong Precision Technology", c: "CH" },
  { id: 19220, s: "4716.TWO", n: "Daily Polymer", c: "TW" },
  { id: 19221, s: "BANVT.IS", n: "Banvit Bandirma Vitaminli Yem Sanayi AS", c: "Turkey" },
  { id: 19222, s: "BALAT.IS", n: "Balatacilar Balatacilik Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19223, s: "300569.SZ", n: "Qingdao Tianneng Heavy Industries", c: "CH" },
  { id: 19224, s: "002888.SZ", n: "HiVi Acoustics Technology", c: "CH" },
  { id: 19225, s: "KGSPY", n: "Kingspan Group PLC ADR", c: "US" },
  { id: 19226, s: "002968.SZ", n: "Chongqing New Dazheng Property Grp", c: "CH" },
  { id: 19227, s: "002457.SZ", n: "Ningxia Qinglong Pipes Industry", c: "CH" },
  { id: 19228, s: "3484.TWO", n: "Solteam Electronics", c: "TW" },
  { id: 19229, s: "300573.SZ", n: "Shenyang Xingqi Pharmaceutical", c: "CH" },
  { id: 19230, s: "300575.SZ", n: "Jiangsu Flag Chemical Industry", c: "CH" },
  { id: 19231, s: "4726.TWO", n: "Mycenax Biotech", c: "TW" },
  { id: 19232, s: "300580.SZ", n: "Wuxi Best Precision Machinery", c: "CH" },
  { id: 19233, s: "300581.SZ", n: "XiAn ChenXi Aviation Technology Co", c: "CH" },
  { id: 19234, s: "AVTUR.IS", n: "Avrasya Petrol ve Turistik Tesisler Yatirimlar AS", c: "Turkey" },
  { id: 19235, s: "ATLAS.IS", n: "Atlas Menkul Kiymetler Yatirim Ortakligi AS", c: "Turkey" },
  { id: 19236, s: "300194.SZ", n: "Fuan Pharmaceutical Group", c: "CH" },
  { id: 19237, s: "8916.TWO", n: "Kwong Lung Enterprise", c: "TW" },
  { id: 19238, s: "KAPLM.IS", n: "Kaplamin Ambalaj Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19239, s: "002864.SZ", n: "Shaanxi Panlong Pharmaceutical Group Limited By Share Class A", c: "CH" },
  { id: 19240, s: "002445.SZ", n: "Zhongnanhong Culture Group", c: "CH" },
  { id: 19241, s: "1535.TW", n: "CH Ecotek Co", c: "TW" },
  { id: 19242, s: "300525.SZ", n: "Fujian Boss Software Development", c: "CH" },
  { id: 19243, s: "FADE.IS", n: "Fade Gida Yatirim Sanayi Ticaret As", c: "Turkey" },
  { id: 19244, s: "ETYAT.IS", n: "Euro Trend Yatirim Ortakligi AS", c: "Turkey" },
  { id: 19245, s: "ETILR.IS", n: "Etiler İnci Bufe Gida Sanayi ve Dis Ticaret AS", c: "Turkey" },
  { id: 19246, s: "PNR.AX", n: "Pantoro", c: "Australia" },
  { id: 19247, s: "3537.TWO", n: "Podak", c: "TW" },
  { id: 19248, s: "DOMR", n: "Dominion Resources Black Warrior Trust", c: "US" },
  { id: 19249, s: "PLT.AX", n: "Plenti Group Ltd", c: "Australia" },
  { id: 19250, s: "300225.SZ", n: "Shanghai Kinlita Chemical", c: "CH" },
  { id: 19251, s: "300496.SZ", n: "Thunder Software Technology", c: "CH" },
  { id: 19252, s: "002617.SZ", n: "Roshow Technology", c: "CH" },
  { id: 19253, s: "HLGYO.IS", n: "Halk Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 19254, s: "300519.SZ", n: "Zhejiang Xinguang Pharmaceutical", c: "CH" },
  { id: 19255, s: "300226.SZ", n: "Shanghai Ganglian E Commerce", c: "CH" },
  { id: 19256, s: "1533.TW", n: "Mobiletron Electronics", c: "TW" },
  { id: 19257, s: "300492.SZ", n: "Cendes Co Class A", c: "CH" },
  { id: 19258, s: "300489.SZ", n: "Optics Technology Holding", c: "CH" },
  { id: 19259, s: "ALV.AX", n: "Alvo Minerals", c: "Australia" },
  { id: 19260, s: "HATEK.IS", n: "Hateks Hatay Tekstil Isletmeleri AS", c: "Turkey" },
  { id: 19261, s: "300484.SZ", n: "ShenZhen V&T Technologies", c: "CH" },
  { id: 19262, s: "300228.SZ", n: "Zhangjiagang Furui Special Equip", c: "CH" },
  { id: 19263, s: "002835.SZ", n: "Shenzhen TVT Digital Technology", c: "CH" },
  { id: 19264, s: "300483.SZ", n: "Sino Prima Gas Technology", c: "CH" },
  { id: 19265, s: "00680K.KS", n: "Mirae Asset Daewoo Co Pref", c: "Korea" },
  { id: 19266, s: "GEDIK.IS", n: "Gedik Yatirim Menkul Degerler AS", c: "Turkey" },
  { id: 19267, s: "GEDZA.IS", n: "Gediz Ambalaj Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19268, s: "ISYAT.IS", n: "Is Yatirim Ortakligi AS", c: "Turkey" },
  { id: 19269, s: "300240.SZ", n: "Jiangsu Feiliks Intl Logistics", c: "CH" },
  { id: 19270, s: "300437.SZ", n: "Henan Qingshuiyuan Technology", c: "CH" },
  { id: 19271, s: "ISSEN.IS", n: "Isbir Sentetik Dokuma Sanayi AS", c: "Turkey" },
  { id: 19272, s: "300242.SZ", n: "KAISA JiaYun Technology Inc", c: "CH" },
  { id: 19273, s: "002866.SZ", n: "Jiangsu Transimage Technology", c: "CH" },
  { id: 19274, s: "OPH.AX", n: "Ophir High Conviction Fund", c: "Australia" },
  { id: 19275, s: "300445.SZ", n: "Beijing ConST Instruments Technology Inc", c: "CH" },
  { id: 19276, s: "300451.SZ", n: "B-Soft", c: "CH" },
  { id: 19277, s: "3567.TWO", n: "Etrend Hightech", c: "TW" },
  { id: 19278, s: "300241.SZ", n: "Shenzhen Refond Optoelectronics", c: "CH" },
  { id: 19279, s: "ISFIN.IS", n: "Is Finansal Kiralama AS", c: "Turkey" },
  { id: 19280, s: "CBRI", n: "CMTSU Liquidation Inc", c: "US" },
  { id: 19281, s: "HTTBT.IS", n: "Hitit Bilgisayar Hizmetleri AS", c: "Turkey" },
  { id: 19282, s: "300516.SZ", n: "Hubei Jiuzhiyang Infrared System", c: "CH" },
  { id: 19283, s: "002481.SZ", n: "Yan Tai Shuang Ta Food", c: "CH" },
  { id: 19284, s: "PKO.AX", n: "Peako", c: "Australia" },
  { id: 19285, s: "002871.SZ", n: "Qingdao Weflo Valve", c: "CH" },
  { id: 19286, s: "1537.TW", n: "Kung Long Batteries Industrial", c: "TW" },
  { id: 19287, s: "300501.SZ", n: "Shanghai Haishun New Pharmaceutical Packaging", c: "CH" },
  { id: 19288, s: "300503.SZ", n: "Guangzhou Haozhi Industrial", c: "CH" },
  { id: 19289, s: "1582.TW", n: "Syncmold Enterprise Co", c: "TW" },
  { id: 19290, s: "GEREL.IS", n: "Gersan Elektrik Ticaret ve Sanayi AS", c: "Turkey" },
  { id: 19291, s: "300512.SZ", n: "Hangzhou Zhongya Machinery", c: "CH" },
  { id: 19292, s: "PNC.AX", n: "Pioneer Credit", c: "Australia" },
  { id: 19293, s: "300513.SZ", n: "Beijing E-techstar", c: "CH" },
  { id: 19294, s: "002610.SZ", n: "Jiangsu Akcome Science & Technology", c: "CH" },
  { id: 19295, s: "200553.SZ", n: "ADAMA B", c: "CH" },
  { id: 19296, s: "MPR.AX", n: "Mpower Group", c: "Australia" },
  { id: 19297, s: "1325.TW", n: "Universal Inc", c: "TW" },
  { id: 19298, s: "300285.SZ", n: "Shandong Sinocera Func Material", c: "CH" },
  { id: 19299, s: "ACOPY", n: "The A2 Milk Company", c: "US" },
  { id: 19300, s: "300281.SZ", n: "Guangdong Jinming Machinery", c: "CH" },
  { id: 19301, s: "TDGYO.IS", n: "Trend Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 19302, s: "300347.SZ", n: "Hangzhou Tigermed Consulting", c: "CH" },
  { id: 19303, s: "MIL.AX", n: "Millennium Services Group", c: "Australia" },
  { id: 19304, s: "300279.SZ", n: "Wuxi Hodgen Technology", c: "CH" },
  { id: 19305, s: "SONME.IS", n: "Sonmez Filament Sentetik Iplik ve Elyaf Sanayi AS", c: "Turkey" },
  { id: 19306, s: "SODSN.IS", n: "Sodas Sodyum Sanayi AS", c: "Turkey" },
  { id: 19307, s: "1339.TW", n: "Y.C.C. Parts MFG", c: "TW" },
  { id: 19308, s: "NAE.AX", n: "New Age Exploration", c: "Australia" },
  { id: 19309, s: "NAM.AX", n: "Namoi Cotton Co-Operative", c: "Australia" },
  { id: 19310, s: "NC6.AX", n: "Nanollose", c: "Australia" },
  { id: 19311, s: "HMLN", n: "Hamlin Bank and Trust Company", c: "US" },
  { id: 19312, s: "SILVR.IS", n: "Silverline Endustri ve Ticaret AS", c: "Turkey" },
  { id: 19313, s: "SELVA.IS", n: "Selva Gida Sanayi AS", c: "Turkey" },
  { id: 19314, s: "MGU.AX", n: "Magnum Mining and Exploration", c: "Australia" },
  { id: 19315, s: "MGT.AX", n: "Magnetite Mines", c: "Australia" },
  { id: 19316, s: "SEKUR.IS", n: "Sekuro Plastik Ambalaj Sanayi AS", c: "Turkey" },
  { id: 19317, s: "OLY.AX", n: "Olympio Metals.", c: "Australia" },
  { id: 19318, s: "300430.SZ", n: "Beijing Chieftain Control Engineering Technology", c: "CH" },
  { id: 19319, s: "4197.TWO", n: "Visgeneer", c: "TW" },
  { id: 19320, s: "300546.SZ", n: "Shenzhen Emperor Technology", c: "CH" },
  { id: 19321, s: "002883.SZ", n: "Jiangsu Zhongshe Group", c: "CH" },
  { id: 19322, s: "HUNTF", n: "Hunter Maritime Acquisition Co", c: "US" },
  { id: 19323, s: "NCC.AX", n: "NAOS Emerging Opportunities", c: "Australia" },
  { id: 19324, s: "CELHA.IS", n: "Celik Halat ve Tel Sanayi AS", c: "Turkey" },
  { id: 19325, s: "MFD.AX", n: "Mayfield Childcare", c: "Australia" },
  { id: 19326, s: "300332.SZ", n: "Top Resource Conservation Engineering", c: "CH" },
  { id: 19327, s: "000966.SZ", n: "CHN Energy Changyuan Electric Power", c: "CH" },
  { id: 19328, s: "300368.SZ", n: "Hebei Huijin Electromechanical", c: "CH" },
  { id: 19329, s: "MYE.AX", n: "Metarock Group", c: "Australia" },
  { id: 19330, s: "1315.TW", n: "Tah Hsin Industrial Co", c: "TW" },
  { id: 19331, s: "SARKY.IS", n: "Sarkuysan Elektrolitik Bakir Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19332, s: "SANKO.IS", n: "Sanko Pazarlama Ithalat Ihracat AS", c: "Turkey" },
  { id: 19333, s: "2402.TW", n: "Ichia Technologies Inc", c: "TW" },
  { id: 19334, s: "SANEL.IS", n: "SAN-EL Muhendislik Elektrik Taahhut Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19335, s: "4109.TWO", n: "Jia Jie Biomedical", c: "TW" },
  { id: 19336, s: "SAMAT.IS", n: "Saray Matbaacilik Kagitcilik Kirtasiyecilik Ticaret ve Sanayi AS", c: "Turkey" },
  { id: 19337, s: "MEU.AX", n: "Marmota Energy", c: "Australia" },
  { id: 19338, s: "300291.SZ", n: "Beijing Hualu Baina Film TV", c: "CH" },
  { id: 19339, s: "MEM.AX", n: "Memphasys", c: "Australia" },
  { id: 19340, s: "4173.TWO", n: "Arich Enterprise", c: "TW" },
  { id: 19341, s: "MEL.AX", n: "Metgas", c: "Australia" },
  { id: 19342, s: "MEK.AX", n: "Meeka Metals Limited", c: "Australia" },
  { id: 19343, s: "300359.SZ", n: "Qtone Education Group Guangdong", c: "CH" },
  { id: 19344, s: "MEG.AX", n: "Megado Minerals Limited", c: "Australia" },
  { id: 19345, s: "MEC.AX", n: "Morphic Ethical Equities Fund", c: "Australia" },
  { id: 19346, s: "HLLGY", n: "HELLA GmbH & Co KgaA ADR", c: "US" },
  { id: 19347, s: "PRZMA.IS", n: "Prizma Pres Matbaacilik Yayincilik Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19348, s: "PRKAB.IS", n: "Turk Prysmian Kablo ve Sistemleri AS", c: "Turkey" },
  { id: 19349, s: "300351.SZ", n: "Zhejiang Yonggui Electric Equipment", c: "CH" },
  { id: 19350, s: "PUA.AX", n: "Peak Minerals", c: "Australia" },
  { id: 19351, s: "BURCE.IS", n: "Burcelik Bursa Celik Dokum Sanayi AS", c: "Turkey" },
  { id: 19352, s: "002815.SZ", n: "Shenzhen Suntak Circuit Technology", c: "CH" },
  { id: 19353, s: "ARDYZ.IS", n: "Ard Grup Bilisim Teknolojileri AS", c: "Turkey" },
  { id: 19354, s: "8432.TWO", n: "tsh biopharmoration", c: "TW" },
  { id: 19355, s: "DOBUR.IS", n: "Dogan Burda Dergi Yayincilik ve Pazarlama AS", c: "Turkey" },
  { id: 19356, s: "002638.SZ", n: "Dongguan Kingsun Optoelectronic", c: "CH" },
  { id: 19357, s: "002533.SZ", n: "Goldcup Electric Apparatus", c: "CH" },
  { id: 19358, s: "DGATE.IS", n: "Datagate Bilgisayar Malzemeleri Ticaret AS", c: "Turkey" },
  { id: 19359, s: "DESPC.IS", n: "Despec Bilgisayar Pazarlama ve Ticaret AS", c: "Turkey" },
  { id: 19360, s: "PXS.AX", n: "Pharmaxis", c: "Australia" },
  { id: 19361, s: "JPPHY", n: "Japan Post Holdings Co ADR", c: "US" },
  { id: 19362, s: "PXX.AX", n: "Polarx", c: "Australia" },
  { id: 19363, s: "BASCM.IS", n: "Bastas Baskent Cimento Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19364, s: "ARENA.IS", n: "Arena Bilgisayar Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19365, s: "002446.SZ", n: "Guangdong Shenglu Telecommunication Tech", c: "CH" },
  { id: 19366, s: "DYOBY.IS", n: "DYO Boya Fabrikalari Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19367, s: "300184.SZ", n: "Wuhan PS Information Tech", c: "CH" },
  { id: 19368, s: "RCR.AX", n: "Rincon Resources", c: "Australia" },
  { id: 19369, s: "ANELE.IS", n: "Anel Elektrik Proje Taahhut ve Ticaret AS", c: "Turkey" },
  { id: 19370, s: "300601.SZ", n: "Shenzhen Kangtai Biological Products", c: "CH" },
  { id: 19371, s: "RDG.AX", n: "Resource Development Group", c: "Australia" },
  { id: 19372, s: "300586.SZ", n: "Malion New Materials", c: "CH" },
  { id: 19373, s: "300182.SZ", n: "Beijing Jetsen Tech", c: "CH" },
  { id: 19374, s: "ALCAR.IS", n: "Alarko Carrier Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19375, s: "3373.TWO", n: "Radiant Innovation", c: "TW" },
  { id: 19376, s: "KNYJY", n: "Kone Oyj ADR", c: "US" },
  { id: 19377, s: "300607.SZ", n: "Guangdong Topstar Technology", c: "CH" },
  { id: 19378, s: "002686.SZ", n: "Zhejiang Yilida Ventilator", c: "CH" },
  { id: 19379, s: "4510.TWO", n: "Kao Fong Machinery", c: "TW" },
  { id: 19380, s: "300548.SZ", n: "Broadex Technologies", c: "CH" },
  { id: 19381, s: "BOSSA.IS", n: "Bossa Ticaret ve Sanayi Isletmeleri TAS", c: "Turkey" },
  { id: 19382, s: "BTCIM.IS", n: "Baticim Bati Anadolu Cimento Sanayi AS", c: "Turkey" },
  { id: 19383, s: "PRCX", n: "Resort Savers Inc", c: "US" },
  { id: 19384, s: "8421.TWO", n: "Xu Yuan Packaging Technology", c: "TW" },
  { id: 19385, s: "8410.TWO", n: "Sentien Printing Factory", c: "TW" },
  { id: 19386, s: "4702.TWO", n: "Allied Industrial", c: "TW" },
  { id: 19387, s: "4707.TWO", n: "Pan Asia Chemical", c: "TW" },
  { id: 19388, s: "8403.TWO", n: "ShareHope Medicine", c: "TW" },
  { id: 19389, s: "8401.TWO", n: "Bai Sha Technology", c: "TW" },
  { id: 19390, s: "002539.SZ", n: "Chengdu Wintrue Holding", c: "CH" },
  { id: 19391, s: "8354.TWO", n: "Koan Hao Technology", c: "TW" },
  { id: 19392, s: "8277.TWO", n: "Unifosa", c: "TW" },
  { id: 19393, s: "1597.TW", n: "Chieftek Precision", c: "TW" },
  { id: 19394, s: "002679.SZ", n: "Fujian Jinsen Forestry", c: "CH" },
  { id: 19395, s: "PTL.AX", n: "Pental", c: "Australia" },
  { id: 19396, s: "DESA.IS", n: "Desa Deri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 19397, s: "300527.SZ", n: "CH Harzone Industry Co", c: "CH" },
  { id: 19398, s: "IHICY", n: "IHI Corp ADR", c: "US" },
  { id: 19399, s: "EMKEL.IS", n: "EMEK Elektrik Endustrisi AS", c: "Turkey" },
  { id: 19400, s: "2546.TW", n: "Kedge Construction", c: "TW" },
  { id: 19401, s: "1583.TW", n: "Goodway Machine Co", c: "TW" },
  { id: 19402, s: "300222.SZ", n: "Csg Smart Science&Technology", c: "CH" },
  { id: 19403, s: "PRO.AX", n: "Prophecy International Holdings", c: "Australia" },
  { id: 19404, s: "002636.SZ", n: "Goldenmax International Technology", c: "CH" },
  { id: 19405, s: "JXHLY", n: "Eneos Holdings Inc ADR", c: "US" },
  { id: 19406, s: "EQLB", n: "Eq Energy Drink Inc", c: "US" },
  { id: 19407, s: "300558.SZ", n: "Betta Pharmaceuticals", c: "CH" },
  { id: 19408, s: "BZQIY", n: "Bezeq Co", c: "US" },
  { id: 19409, s: "300721.SZ", n: "Jiangsu Yida Chemical Co Class A", c: "CH" },
  { id: 19410, s: "024090.KS", n: "DCM Co", c: "Korea" },
  { id: 19411, s: "ALOBR.PA", n: "Ober SA", c: "FR" },
  { id: 19412, s: "023450.KS", n: "Dongnam Chem", c: "Korea" },
  { id: 19413, s: "ALPOU.PA", n: "Poulaillon SA", c: "FR" },
  { id: 19414, s: "023350.KS", n: "Korea Eng Cons", c: "Korea" },
  { id: 19415, s: "ALPRG.PA", n: "Prologue", c: "FR" },
  { id: 19416, s: "ALQWA.PA", n: "Qwamplify SA", c: "FR" },
  { id: 19417, s: "021050.KS", n: "Seowon", c: "Korea" },
  { id: 19418, s: "ALROC.PA", n: "Roctool", c: "FR" },
  { id: 19419, s: "020120.KS", n: "KidariStudio Inc", c: "Korea" },
  { id: 19420, s: "019680.KS", n: "Daekyo", c: "Korea" },
  { id: 19421, s: "019440.KS", n: "Seah Spe Steel", c: "Korea" },
  { id: 19422, s: "019175.KS", n: "Shinpoong Pharmaceutical", c: "Korea" },
  { id: 19423, s: "ALTHE.PA", n: "Theradiag SA", c: "FR" },
  { id: 19424, s: "ALTUR.PA", n: "Altur Investissement SAC", c: "FR" },
  { id: 19425, s: "017800.KS", n: "Hyundai Elevator", c: "Korea" },
  { id: 19426, s: "016710.KS", n: "Daesung Holdin", c: "Korea" },
  { id: 19427, s: "016590.KS", n: "Shindaeyang Pa", c: "Korea" },
  { id: 19428, s: "016580.KS", n: "Whanin Pharm", c: "Korea" },
  { id: 19429, s: "015890.KS", n: "Tae Kyung Ind", c: "Korea" },
  { id: 19430, s: "GQC.V", n: "GoldQuest Mining Corp.", c: "CA" },
  { id: 19431, s: "UCBJY", n: "UCB SA ADR", c: "US" },
  { id: 19432, s: "AREIT.PA", n: "Altareit", c: "FR" },
  { id: 19433, s: "ALNFL.PA", n: "NFL Biosciences SAS", c: "FR" },
  { id: 19434, s: "ALNEV.PA", n: "Neovacs S.A.", c: "FR" },
  { id: 19435, s: "ALFLO.PA", n: "Florentaise SA", c: "FR" },
  { id: 19436, s: "035150.KS", n: "Baiksan", c: "Korea" },
  { id: 19437, s: "115390.KS", n: "Lock&Lock", c: "Korea" },
  { id: 19438, s: "ALESE.PA", n: "Entech SE SAS", c: "FR" },
  { id: 19439, s: "111770.KS", n: "Youngone", c: "Korea" },
  { id: 19440, s: "107590.KS", n: "Miwon Holdings Co.", c: "Korea" },
  { id: 19441, s: "ARO.SG", n: "Arcandor AG", c: "GER" },
  { id: 19442, s: "TOKIF", n: "Optima Medical Innovations Co", c: "US" },
  { id: 19443, s: "102460.KS", n: "Reyon Pharmaceutical", c: "Korea" },
  { id: 19444, s: "102260.KS", n: "Dongsung Corporation", c: "Korea" },
  { id: 19445, s: "TOKUY", n: "Tokyu Corp ADR", c: "US" },
  { id: 19446, s: "DMP.SG", n: "Dermapharm Holding SE", c: "GER" },
  { id: 19447, s: "DWB.SG", n: "Dinkelacker AG", c: "GER" },
  { id: 19448, s: "095570.KS", n: "AJ Networks", c: "Korea" },
  { id: 19449, s: "092440.KS", n: "Kishin Corporation", c: "Korea" },
  { id: 19450, s: "089860.KS", n: "Lotte Rental", c: "Korea" },
  { id: 19451, s: "MRK.SG", n: "Merck & Company Inc", c: "GER" },
  { id: 19452, s: "NST.SG", n: "Norddeutsche Steingut AG", c: "GER" },
  { id: 19453, s: "TPII", n: "Triad Pro Innovators Inc", c: "US" },
  { id: 19454, s: "088350.KS", n: "Hanwha Life", c: "Korea" },
  { id: 19455, s: "085310.KS", n: "NK Co.", c: "Korea" },
  { id: 19456, s: "BKS.VI", n: "BKS Bank AG", c: "Austria" },
  { id: 19457, s: "078000.KS", n: "Telcoware", c: "Korea" },
  { id: 19458, s: "077500.KS", n: "Uniquest", c: "Korea" },
  { id: 19459, s: "FQT.VI", n: "Frequentis AG", c: "Austria" },
  { id: 19460, s: "072130.KS", n: "Uangel", c: "Korea" },
  { id: 19461, s: "071970.KS", n: "Stx Heavy Indu", c: "Korea" },
  { id: 19462, s: "IPG.V", n: "Imperial Mining Group", c: "CA" },
  { id: 19463, s: "ALMND.PA", n: "Montagne et Neige Developpement SA", c: "FR" },
  { id: 19464, s: "ALMIL.PA", n: "1000mercis SA", c: "FR" },
  { id: 19465, s: "GWA.V", n: "Gowest Gold", c: "CA" },
  { id: 19466, s: "4980.TWO", n: "Jorjin Technologies", c: "TW" },
  { id: 19467, s: "300120.SZ", n: "Tianjin Jingwei Electric Wire", c: "CH" },
  { id: 19468, s: "002063.SZ", n: "YGSOFT Inc", c: "CH" },
  { id: 19469, s: "4973.TWO", n: "Silicon Power Computer & Communications", c: "TW" },
  { id: 19470, s: "6581.TW", n: "Taiwan Steel Union", c: "TW" },
  { id: 19471, s: "LXENF", n: "Lithium Energi Exploration Inc", c: "US" },
  { id: 19472, s: "002065.SZ", n: "Dhc Software", c: "CH" },
  { id: 19473, s: "002061.SZ", n: "Zhejiang Jiangshan Chemical", c: "CH" },
  { id: 19474, s: "300753.SZ", n: "Jiangsu Apon Medical Technology Co Class A", c: "CH" },
  { id: 19475, s: "002420.SZ", n: "Guangzhou Echom Sci&Tech", c: "CH" },
  { id: 19476, s: "300765.SZ", n: "CSPC Innovation Pharmaceutical Co Class A", c: "CH" },
  { id: 19477, s: "DMM.AX", n: "DMC Mining", c: "Australia" },
  { id: 19478, s: "300117.SZ", n: "Beijing JIAYU Door Window Curtain", c: "CH" },
  { id: 19479, s: "PLFX", n: "Pulse Evolution Co", c: "US" },
  { id: 19480, s: "300771.SZ", n: "Shenzhen Zhilai Sci and Tech", c: "CH" },
  { id: 19481, s: "3086.TWO", n: "Wayi International Digital Entertainment", c: "TW" },
  { id: 19482, s: "300761.SZ", n: "Jiangsu Lihua Animal Husbandry", c: "CH" },
  { id: 19483, s: "300743.SZ", n: "Hangzhou Todaytec Digital Co Class A", c: "CH" },
  { id: 19484, s: "002995.SZ", n: "Beijing Quanshi World Online Net", c: "CH" },
  { id: 19485, s: "6143.TWO", n: "Netronix", c: "TW" },
  { id: 19486, s: "SLS.AX", n: "Solstice Minerals", c: "Australia" },
  { id: 19487, s: "GGBXF", n: "Green Growth Brands Inc", c: "US" },
  { id: 19488, s: "3205.TWO", n: "Sagittarius Life Science", c: "TW" },
  { id: 19489, s: "002031.SZ", n: "Greatoo Inc", c: "CH" },
  { id: 19490, s: "300131.SZ", n: "ShenZhen Yitoa Intelligent Control", c: "CH" },
  { id: 19491, s: "GDQMF", n: "GoldQuest Mining Corp.", c: "US" },
  { id: 19492, s: "002801.SZ", n: "Hangzhou Weiguang Electronic", c: "CH" },
  { id: 19493, s: "TSPG", n: "TGI Solar Power Group Inc", c: "US" },
  { id: 19494, s: "ALINN.PA", n: "Innelec Multimedia", c: "FR" },
  { id: 19495, s: "034590.KS", n: "Incheon City G", c: "Korea" },
  { id: 19496, s: "GUN.V", n: "Gunpoint Exploration", c: "CA" },
  { id: 19497, s: "TSUKY", n: "Toyo Suisan Kaisha ADR", c: "US" },
  { id: 19498, s: "ALGLD.PA", n: "Gold By Gold", c: "FR" },
  { id: 19499, s: "ALGRO.PA", n: "Grolleau SAS", c: "FR" },
  { id: 19500, s: "ALHF.PA", n: "HF Company SA", c: "FR" },
  { id: 19501, s: "ALHIT.PA", n: "Hitechpros", c: "FR" },
  { id: 19502, s: "ALHPI.PA", n: "Hopium SAS", c: "FR" },
  { id: 19503, s: "033250.KS", n: "Chasys", c: "Korea" },
  { id: 19504, s: "034830.KS", n: "Korea Real Estate Investment & Trust", c: "Korea" },
  { id: 19505, s: "ALIDS.PA", n: "Idsud S.A", c: "FR" },
  { id: 19506, s: "ALJXR.PA", n: "Archos", c: "FR" },
  { id: 19507, s: "600827.SS", n: "Shanghai Bailian Group Co A", c: "CH" },
  { id: 19508, s: "028670.KS", n: "Pan Ocean", c: "Korea" },
  { id: 19509, s: "02826K.KS", n: "Samsung C and T 1P Pref", c: "Korea" },
  { id: 19510, s: "028100.KS", n: "Donga Geologic", c: "Korea" },
  { id: 19511, s: "ALLGO.PA", n: "Largo SAS", c: "FR" },
  { id: 19512, s: "027740.KS", n: "Maniker", c: "Korea" },
  { id: 19513, s: "027410.KS", n: "BGF Retail", c: "Korea" },
  { id: 19514, s: "ALLOG.PA", n: "Logic Instrume", c: "FR" },
  { id: 19515, s: "026940.KS", n: "Bookook Steel", c: "Korea" },
  { id: 19516, s: "025820.KS", n: "Lee Ku Ind", c: "Korea" },
  { id: 19517, s: "ALIMO.PA", n: "Groupimo SA", c: "FR" },
  { id: 19518, s: "000957.SZ", n: "Zhongtong Bus Holding", c: "CH" },
  { id: 19519, s: "IFX.V", n: "Imaflex Inc", c: "CA" },
  { id: 19520, s: "IEI.V", n: "Imperial Equities Inc.", c: "CA" },
  { id: 19521, s: "071950.KS", n: "KOAS Co.", c: "Korea" },
  { id: 19522, s: "ABO.BR", n: "ABO Group", c: "Belgium" },
  { id: 19523, s: "010660.KS", n: "Hwacheon Machi", c: "Korea" },
  { id: 19524, s: "006660.KS", n: "Samsung Climat", c: "Korea" },
  { id: 19525, s: "006060.KS", n: "Hwaseung Industries", c: "Korea" },
  { id: 19526, s: "006040.KS", n: "Dongwon Ind", c: "Korea" },
  { id: 19527, s: "CBIT.V", n: "Cathedra Bitcoin Inc", c: "CA" },
  { id: 19528, s: "CBI.V", n: "Colibri Resource Co", c: "CA" },
  { id: 19529, s: "VLTSA.PA", n: "Voltalia SA", c: "FR" },
  { id: 19530, s: "WAGA.PA", n: "Waga Energy SA", c: "FR" },
  { id: 19531, s: "VGID", n: "V Group Inc", c: "US" },
  { id: 19532, s: "XIL.PA", n: "Xilam Animation", c: "FR" },
  { id: 19533, s: "003200.KS", n: "Ilshin Spinnin", c: "Korea" },
  { id: 19534, s: "ACCB.BR", n: "Accentis", c: "Belgium" },
  { id: 19535, s: "EURS.PA", n: "Foncière Euris SA", c: "FR" },
  { id: 19536, s: "002785.KS", n: "Chinhung International Inc", c: "Korea" },
  { id: 19537, s: "BIOS.BR", n: "BioSenic S.A.", c: "Belgium" },
  { id: 19538, s: "002620.KS", n: "Jeil Pharm", c: "Korea" },
  { id: 19539, s: "BMM.V", n: "Black Mammoth Metals Co", c: "CA" },
  { id: 19540, s: "BMET.V", n: "BeMetals Co", c: "CA" },
  { id: 19541, s: "BKMT.V", n: "BlockMint Technologies Inc", c: "CA" },
  { id: 19542, s: "002220.KS", n: "Hanil Iron & S", c: "Korea" },
  { id: 19543, s: "BGF.V", n: "Beauce Gold Fields Inc", c: "CA" },
  { id: 19544, s: "IEP.BR", n: "Iep Invest", c: "Belgium" },
  { id: 19545, s: "002025.KS", n: "Kolon Co", c: "Korea" },
  { id: 19546, s: "001800.KS", n: "ORION Holdings Co", c: "Korea" },
  { id: 19547, s: "EXN.PA", n: "Exclusive Networks SA", c: "FR" },
  { id: 19548, s: "010955.KS", n: "S-Oil Corp Pref", c: "Korea" },
  { id: 19549, s: "001780.KS", n: "Aluko", c: "Korea" },
  { id: 19550, s: "013890.KS", n: "Zinus Inc", c: "Korea" },
  { id: 19551, s: "FALG.PA", n: "Fermentalg SA", c: "FR" },
  { id: 19552, s: "2349.TW", n: "Ritek Co", c: "TW" },
  { id: 19553, s: "IDL.PA", n: "ID Logistics Group SA", c: "FR" },
  { id: 19554, s: "010640.KS", n: "Chinyang Poly", c: "Korea" },
  { id: 19555, s: "010780.KS", n: "IS Dongseo", c: "Korea" },
  { id: 19556, s: "014910.KS", n: "Sungmoon Elect", c: "Korea" },
  { id: 19557, s: "GETT.V", n: "GETT Gold Inc", c: "CA" },
  { id: 19558, s: "GEMC.V", n: "Global Energy Metals Co", c: "CA" },
  { id: 19559, s: "014440.KS", n: "Youngbo Chem", c: "Korea" },
  { id: 19560, s: "014285.KS", n: "Kumkang Kind", c: "Korea" },
  { id: 19561, s: "014160.KS", n: "Dae Young Pkg", c: "Korea" },
  { id: 19562, s: "013870.KS", n: "Gmb Korea", c: "Korea" },
  { id: 19563, s: "011090.KS", n: "Enex Co.Ltd", c: "Korea" },
  { id: 19564, s: "CEN.PA", n: "Groupe CRIT SA", c: "FR" },
  { id: 19565, s: "012510.KS", n: "Douzone Bizon", c: "Korea" },
  { id: 19566, s: "DPT.PA", n: "ST Dupont", c: "FR" },
  { id: 19567, s: "012200.KS", n: "Keyang Elec Ma", c: "Korea" },
  { id: 19568, s: "012170.KS", n: "Ascendio", c: "Korea" },
  { id: 19569, s: "012160.KS", n: "Young Heung I&", c: "Korea" },
  { id: 19570, s: "011930.KS", n: "Shinsung E&G Co.", c: "Korea" },
  { id: 19571, s: "011760.KS", n: "Hyundai Co", c: "Korea" },
  { id: 19572, s: "011390.KS", n: "Busan Ind", c: "Korea" },
  { id: 19573, s: "011300.KS", n: "SEONGAN CO.", c: "Korea" },
  { id: 19574, s: "011210.KS", n: "Hyundai Wia", c: "Korea" },
  { id: 19575, s: "BAU.V", n: "Blue Star Gold Co", c: "CA" },
  { id: 19576, s: "001770.KS", n: "Shin Hwa Silup", c: "Korea" },
  { id: 19577, s: "071840.KS", n: "Lotte Himart", c: "Korea" },
  { id: 19578, s: "051600.KS", n: "Kepco Plant S&", c: "Korea" },
  { id: 19579, s: "GLDN.V", n: "Golden Ridge Resources", c: "CA" },
  { id: 19580, s: "ASY.PA", n: "Assystem S.A.", c: "FR" },
  { id: 19581, s: "071320.KS", n: "Korea District Heating Co", c: "Korea" },
  { id: 19582, s: "070960.KS", n: "Yong Pyong Resort Co", c: "Korea" },
  { id: 19583, s: "069460.KS", n: "Daeho Al Co.", c: "Korea" },
  { id: 19584, s: "AL2SI.PA", n: "2CRSI S.A.", c: "FR" },
  { id: 19585, s: "069260.KS", n: "Huchems", c: "Korea" },
  { id: 19586, s: "064960.KS", n: "SNT Motiv", c: "Korea" },
  { id: 19587, s: "060980.KS", n: "Halla Holdings Co", c: "Korea" },
  { id: 19588, s: "058860.KS", n: "KTIS Corporation", c: "Korea" },
  { id: 19589, s: "051915.KS", n: "LG Chem", c: "Korea" },
  { id: 19590, s: "049770.KS", n: "Dongwon F & B", c: "Korea" },
  { id: 19591, s: "DEC.V", n: "Decade Resources", c: "CA" },
  { id: 19592, s: "044450.KS", n: "Kss Line", c: "Korea" },
  { id: 19593, s: "044380.KS", n: "Jooyontech", c: "Korea" },
  { id: 19594, s: "042670.KS", n: "Hyundai Doosan Infracore", c: "Korea" },
  { id: 19595, s: "GZD.V", n: "Grizzly Discoveries Inc", c: "CA" },
  { id: 19596, s: "037710.KS", n: "Gwangjushinseg", c: "Korea" },
  { id: 19597, s: "GYM.V", n: "TUT Fitness Group Inc", c: "CA" },
  { id: 19598, s: "ALDVI.PA", n: "Advicenne", c: "FR" },
  { id: 19599, s: "037270.KS", n: "Yg Plus Inc", c: "Korea" },
  { id: 19600, s: "036530.KS", n: "SNT Holdings", c: "Korea" },
  { id: 19601, s: "ALENO.PA", n: "Enogia SAS", c: "FR" },
  { id: 19602, s: "035510.KS", n: "Shinsegae I&C", c: "Korea" },
  { id: 19603, s: "015020.KS", n: "E-Star", c: "Korea" },
  { id: 19604, s: "CNO.V", n: "California Nanotechnologies Co", c: "CA" },
  { id: 19605, s: "003610.KS", n: "Pang Rim Spinn", c: "Korea" },
  { id: 19606, s: "MLVER.PA", n: "Verney-Carron S.A", c: "FR" },
  { id: 19607, s: "003720.KS", n: "Sam Young Chem", c: "Korea" },
  { id: 19608, s: "003780.KS", n: "Chin Yang Ind", c: "Korea" },
  { id: 19609, s: "STF.PA", n: "Stef SA", c: "FR" },
  { id: 19610, s: "LIN.PA", n: "Linedata Services S.A.", c: "FR" },
  { id: 19611, s: "EAU.V", n: "Engineer Gold Mines", c: "CA" },
  { id: 19612, s: "MAAT.PA", n: "Maat Pharma SA", c: "FR" },
  { id: 19613, s: "MLFTI.PA", n: "FR Tourisme Immobilier SA", c: "FR" },
  { id: 19614, s: "MLIMP.PA", n: "Imprimerie Chirat Société Anonyme", c: "FR" },
  { id: 19615, s: "MLOCT.PA", n: "Octopus Robots SA", c: "FR" },
  { id: 19616, s: "005389.KS", n: "Hyundai Motor S3 Pref", c: "Korea" },
  { id: 19617, s: "URLOF", n: "NameSilo Technologies Co", c: "US" },
  { id: 19618, s: "005250.KS", n: "Green Cross", c: "Korea" },
  { id: 19619, s: "SFPI.PA", n: "Groupe Sfpi", c: "FR" },
  { id: 19620, s: "005010.KS", n: "Husteel", c: "Korea" },
  { id: 19621, s: "GLD.V", n: "GoldOn Resources", c: "CA" },
  { id: 19622, s: "MRM.PA", n: "M.R.M. SA", c: "FR" },
  { id: 19623, s: "004690.KS", n: "Samchully", c: "Korea" },
  { id: 19624, s: "004540.KS", n: "Kleannara", c: "Korea" },
  { id: 19625, s: "PID.PA", n: "Paragon Id SA", c: "FR" },
  { id: 19626, s: "PWG.PA", n: "Prodways Group SA", c: "FR" },
  { id: 19627, s: "004310.KS", n: "Hyundai Pharm", c: "Korea" },
  { id: 19628, s: "CVW.V", n: "CVW CleanTech Inc", c: "CA" },
  { id: 19629, s: "CTEK.V", n: "CLEANTEK Industries Inc", c: "CA" },
  { id: 19630, s: "SABE.PA", n: "Saint Jean Groupe Société anonyme", c: "FR" },
  { id: 19631, s: "3202.TWO", n: "High-Tek Harness Enterprise", c: "TW" },
  { id: 19632, s: "CPS.V", n: "Canadian Premium Sand Inc", c: "CA" },
  { id: 19633, s: "LOGQ", n: "Logicquest Technology Inc", c: "US" },
  { id: 19634, s: "300141.SZ", n: "Suzhou Industrial Park Heshun Electric", c: "CH" },
  { id: 19635, s: "300680.SZ", n: "Wuxi Longsheng Technology", c: "CH" },
  { id: 19636, s: "1721.TW", n: "Sunko Ink", c: "TW" },
  { id: 19637, s: "300682.SZ", n: "Longshine Technology", c: "CH" },
  { id: 19638, s: "300683.SZ", n: "Wuhan Hiteck Biological Pharma", c: "CH" },
  { id: 19639, s: "001218.SZ", n: "Hunan Resun", c: "CH" },
  { id: 19640, s: "LEHNQ", n: "Lehman Brothers Holdings Capital Trust VI", c: "US" },
  { id: 19641, s: "AKFGY.IS", n: "Akfen Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 19642, s: "6206.TW", n: "Flytech Technology", c: "TW" },
  { id: 19643, s: "002596.SZ", n: "Hainan RuiZe New Building Material", c: "CH" },
  { id: 19644, s: "002309.SZ", n: "Zhongli Science and Technology Group", c: "CH" },
  { id: 19645, s: "CIEB.MX", n: "Corporación Interamericana de Entretenimiento S.A.B. de C.V", c: "Mexi" },
  { id: 19646, s: "002930.SZ", n: "Guangdong Great River Smarter Logistics Co Class A", c: "CH" },
  { id: 19647, s: "002313.SZ", n: "SUNSEA Telecommunications", c: "CH" },
  { id: 19648, s: "CYRBY", n: "Cyrela Brazil Realty SA", c: "US" },
  { id: 19649, s: "6275.TWO", n: "Yen Sun Technology", c: "TW" },
  { id: 19650, s: "CYVF", n: "Crystal Valley Financial Co", c: "US" },
  { id: 19651, s: "300008.SZ", n: "Bestway Marine & Energy Technology", c: "CH" },
  { id: 19652, s: "002746.SZ", n: "Shandong Xiantan", c: "CH" },
  { id: 19653, s: "002745.SZ", n: "MLS", c: "CH" },
  { id: 19654, s: "ESVIF", n: "Ensign Energy Services Inc", c: "US" },
  { id: 19655, s: "002328.SZ", n: "Shanghai Xinpeng Industry", c: "CH" },
  { id: 19656, s: "NESRF", n: "Northern Star Resources", c: "US" },
  { id: 19657, s: "3686.TW", n: "Danen Technology Co", c: "TW" },
  { id: 19658, s: "002574.SZ", n: "Zhejiang Ming Jewelry", c: "CH" },
  { id: 19659, s: "002743.SZ", n: "Anhui Fuhuang Steel Structure", c: "CH" },
  { id: 19660, s: "002546.SZ", n: "Nanjing Xinlian Electronics", c: "CH" },
  { id: 19661, s: "1713.TW", n: "Cathay Chemical Works Inc", c: "TW" },
  { id: 19662, s: "300673.SZ", n: "Petpal Pet Nutrition Technology", c: "CH" },
  { id: 19663, s: "300164.SZ", n: "Tong Oil Tools", c: "CH" },
  { id: 19664, s: "300694.SZ", n: "Wuxi Lihu Corporation Limited Class A", c: "CH" },
  { id: 19665, s: "LIGA", n: "Lig Assets Inc.", c: "US" },
  { id: 19666, s: "LKHLY", n: "Lonking Holdings ADR", c: "US" },
  { id: 19667, s: "3041.TW", n: "ALi Co", c: "TW" },
  { id: 19668, s: "003009.SZ", n: "Shaanxi Zhongtian Rocket Technology ", c: "CH" },
  { id: 19669, s: "300703.SZ", n: "Cre8 Direct Ningbo", c: "CH" },
  { id: 19670, s: "8110.TW", n: "Walton Advanced Engineering Inc", c: "TW" },
  { id: 19671, s: "SHM.AX", n: "Shriro Holdings", c: "Australia" },
  { id: 19672, s: "002548.SZ", n: "Shenzhen Jinxinnong Feed", c: "CH" },
  { id: 19673, s: "002022.SZ", n: "Shanghai Kehua Bio-Engineering", c: "CH" },
  { id: 19674, s: "8374.TW", n: "Ace Pillar", c: "TW" },
  { id: 19675, s: "3038.TW", n: "Emerging Display Technologies Co", c: "TW" },
  { id: 19676, s: "LBTI", n: "Lithium & Boron Technology Inc", c: "US" },
  { id: 19677, s: "3232.TWO", n: "Sentronic International", c: "TW" },
  { id: 19678, s: "002805.SZ", n: "Shandong Fengyuan Chemical", c: "CH" },
  { id: 19679, s: "300666.SZ", n: "Konfoong Materials International", c: "CH" },
  { id: 19680, s: "300163.SZ", n: "Ningbo Xianfeng New Material", c: "CH" },
  { id: 19681, s: "200017.SZ", n: "CH Bicycle Co Holdings", c: "CH" },
  { id: 19682, s: "001208.SZ", n: "Hunan Valin Wire & Cable", c: "CH" },
  { id: 19683, s: "FND.AX", n: "Finders Resources Limited", c: "Australia" },
  { id: 19684, s: "002799.SZ", n: "XiAn Global Printing", c: "CH" },
  { id: 19685, s: "003012.SZ", n: "Guangdong Dongpeng Holdings", c: "CH" },
  { id: 19686, s: "300672.SZ", n: "Goke Microelectronics", c: "CH" },
  { id: 19687, s: "002439.SZ", n: "Beijing Venustech Inc", c: "CH" },
  { id: 19688, s: "OXBC", n: "Oxford Bank Corporation", c: "US" },
  { id: 19689, s: "3094.TW", n: "Davicom Semiconductor Inc", c: "TW" },
  { id: 19690, s: "EWPMF", n: "East West Petroleum Co", c: "US" },
  { id: 19691, s: "WFCO34.SA", n: "Wells Fargo & Company", c: "Brazil" },
  { id: 19692, s: "002366.SZ", n: "Taihai Manoir Nuclear Equipment", c: "CH" },
  { id: 19693, s: "002724.SZ", n: "Ocean’s King Lighting Science & Technology", c: "CH" },
  { id: 19694, s: "2348.TW", n: "Hiyes International", c: "TW" },
  { id: 19695, s: "3432.TW", n: "Tai Twun Enterprise", c: "TW" },
  { id: 19696, s: "6186.TWO", n: "Shin Ruenn Development", c: "TW" },
  { id: 19697, s: "300011.SZ", n: "Beijing Dinghan Tech", c: "CH" },
  { id: 19698, s: "002383.SZ", n: "Beijing UniStrong Science & Technology", c: "CH" },
  { id: 19699, s: "002373.SZ", n: "CH TransInfo Technology", c: "CH" },
  { id: 19700, s: "002376.SZ", n: "Shandong New Beiyang Information Technology", c: "CH" },
  { id: 19701, s: "002377.SZ", n: "Hubei Guochuang Hi-tech Material", c: "CH" },
  { id: 19702, s: "002379.SZ", n: "Shandong Hongchuang Aluminum Industry Holding", c: "CH" },
  { id: 19703, s: "CSXXY", n: "Carsales.Com ADR", c: "US" },
  { id: 19704, s: "6187.TWO", n: "All Ring Tech", c: "TW" },
  { id: 19705, s: "002576.SZ", n: "Jiangsu Tongda Power Technology", c: "CH" },
  { id: 19706, s: "6203.TWO", n: "Sea Sonic Electronics", c: "TW" },
  { id: 19707, s: "ATV.AX", n: "ActivePort Group", c: "Australia" },
  { id: 19708, s: "6240.TWO", n: "Sung Gang Asset Management", c: "TW" },
  { id: 19709, s: "BRGE3.SA", n: "Consórcio Alfa de Administração S.A", c: "Brazil" },
  { id: 19710, s: "3605.TW", n: "Aces Electronics", c: "TW" },
  { id: 19711, s: "EQTL", n: "Equitech International Corporation", c: "US" },
  { id: 19712, s: "2316.TW", n: "WUS Printed Circuit", c: "TW" },
  { id: 19713, s: "002899.SZ", n: "Impulse Qingdao Health Tech", c: "CH" },
  { id: 19714, s: "002972.SZ", n: "Shenzhen Keanda Electronic Techno", c: "CH" },
  { id: 19715, s: "3591.TW", n: "Edison Opto Co", c: "TW" },
  { id: 19716, s: "002970.SZ", n: "Streamax Technology ", c: "CH" },
  { id: 19717, s: "5452.TWO", n: "Unic Technology", c: "TW" },
  { id: 19718, s: "EXBX", n: "Exobox Tech Co", c: "US" },
  { id: 19719, s: "300027.SZ", n: "Huayi Brothers Media Co", c: "CH" },
  { id: 19720, s: "4540.TW", n: "TBI Motion Technology", c: "TW" },
  { id: 19721, s: "002279.SZ", n: "Beijing Join-Cheer Software", c: "CH" },
  { id: 19722, s: "4532.TW", n: "Rechi Precision", c: "TW" },
  { id: 19723, s: "2228.TW", n: "Iron Force Industrial", c: "TW" },
  { id: 19724, s: "4306.TW", n: "Yem Chio", c: "TW" },
  { id: 19725, s: "002760.SZ", n: "Anhui Fengxing Wear Resistant Materials", c: "CH" },
  { id: 19726, s: "4164.TW", n: "CHC Healthcare Group", c: "TW" },
  { id: 19727, s: "002980.SZ", n: "Shenzhen Everbest Machinery Indus", c: "CH" },
  { id: 19728, s: "3062.TW", n: "CyberTAN Technology Inc", c: "TW" },
  { id: 19729, s: "300032.SZ", n: "Jinlong Machinery Electronic", c: "CH" },
  { id: 19730, s: "4119.TW", n: "SCI Pharmtech Inc", c: "TW" },
  { id: 19731, s: "002290.SZ", n: "Suzhou Hesheng Special Material", c: "CH" },
  { id: 19732, s: "002364.SZ", n: "Hangzhou Zhongheng Electric", c: "CH" },
  { id: 19733, s: "002752.SZ", n: "Sunrise Group", c: "CH" },
  { id: 19734, s: "002292.SZ", n: "Guangdong Alpha Animation and Culture", c: "CH" },
  { id: 19735, s: "BM8.AX", n: "Battery Age Minerals.", c: "Australia" },
  { id: 19736, s: "5386.TWO", n: "Albatron Technology", c: "TW" },
  { id: 19737, s: "ERIXF", n: "Telefonaktiebolaget LM Ericsson Class B", c: "US" },
  { id: 19738, s: "3617.TW", n: "CyberPower Systems Inc", c: "TW" },
  { id: 19739, s: "3607.TW", n: "Coxon Precise Industrial", c: "TW" },
  { id: 19740, s: "002940.SZ", n: "Zhejiang Anglikang Pharmaceutical Co Class A", c: "CH" },
  { id: 19741, s: "3501.TW", n: "Well Shin Technology", c: "TW" },
  { id: 19742, s: "002738.SZ", n: "Sinomine Resource Exploration", c: "CH" },
  { id: 19743, s: "002363.SZ", n: "Shandong Longji Machinery", c: "CH" },
  { id: 19744, s: "300693.SZ", n: "Shenzhen Sinexcel Electric", c: "CH" },
  { id: 19745, s: "002979.SZ", n: "CH Leadshine Technology", c: "CH" },
  { id: 19746, s: "200725.SZ", n: "BOE Tech Group", c: "CH" },
  { id: 19747, s: "3219.TWO", n: "Service & Quality Technology", c: "TW" },
  { id: 19748, s: "002144.SZ", n: "Hongda High-Tech Holding", c: "CH" },
  { id: 19749, s: "300831.SZ", n: "Xian Peri Power Semiconductor Cond", c: "CH" },
  { id: 19750, s: "5210.TWO", n: "APEX International Financial Engineering Res & Tech", c: "TW" },
  { id: 19751, s: "BCCLF", n: "Becle SA de CV", c: "US" },
  { id: 19752, s: "EMS.AX", n: "Eastern Metals", c: "Australia" },
  { id: 19753, s: "300095.SZ", n: "Jiangxi Huawu Brake", c: "CH" },
  { id: 19754, s: "6281.TW", n: "E-Life Mall Co", c: "TW" },
  { id: 19755, s: "300783.SZ", n: "Three Squirrels", c: "CH" },
  { id: 19756, s: "300789.SZ", n: "TangYuan Electric", c: "CH" },
  { id: 19757, s: "2916.TWO", n: "Munsin Garment", c: "TW" },
  { id: 19758, s: "300802.SZ", n: "Jutze Intelligent Technology", c: "CH" },
  { id: 19759, s: "5493.TWO", n: "San Lien Technology", c: "TW" },
  { id: 19760, s: "002907.SZ", n: "Chongqing Pharscin Pharmaceutical", c: "CH" },
  { id: 19761, s: "300113.SZ", n: "Hangzhou Shunwang Tech", c: "CH" },
  { id: 19762, s: "002414.SZ", n: "Wuhan Guide Infrared", c: "CH" },
  { id: 19763, s: "6477.TW", n: "ANJI Technology Co.", c: "TW" },
  { id: 19764, s: "2705.TW", n: "Leofoo Development", c: "TW" },
  { id: 19765, s: "300111.SZ", n: "Zhejiang Sunflower Light Energy", c: "CH" },
  { id: 19766, s: "5011.TWO", n: "OFCO Industrial", c: "TW" },
  { id: 19767, s: "300810.SZ", n: "Beijing Zhongkehaixun Digital S T", c: "CH" },
  { id: 19768, s: "300811.SZ", n: "Poco Holding ", c: "CH" },
  { id: 19769, s: "POSC", n: "Positron Co", c: "US" },
  { id: 19770, s: "002791.SZ", n: "Guangdong Kinlong Hardware Products", c: "CH" },
  { id: 19771, s: "300817.SZ", n: "Zhejiang Sf Oilless Bearing ", c: "CH" },
  { id: 19772, s: "300096.SZ", n: "YLZ Information Tech", c: "CH" },
  { id: 19773, s: "2008.TW", n: "Kao Hsiung Chang Iron & Steel Co", c: "TW" },
  { id: 19774, s: "002141.SZ", n: "Infund Holding", c: "CH" },
  { id: 19775, s: "2364.TW", n: "Twinhead International Co", c: "TW" },
  { id: 19776, s: "002416.SZ", n: "Shenzhen Aisidi", c: "CH" },
  { id: 19777, s: "002998.SZ", n: "Elite Color Environmental Resources ", c: "CH" },
  { id: 19778, s: "300724.SZ", n: "Shenzhen SC New Energy Technology Corp Class A", c: "CH" },
  { id: 19779, s: "2369.TW", n: "Lingsen Precision Industries", c: "TW" },
  { id: 19780, s: "002041.SZ", n: "Shandong Denghai Seeds", c: "CH" },
  { id: 19781, s: "300726.SZ", n: "Zhuzhou Hongda Electronics Corp Class A", c: "CH" },
  { id: 19782, s: "002423.SZ", n: "COFCO Capital Holdings", c: "CH" },
  { id: 19783, s: "300732.SZ", n: "Henan Provincial Communications Planning & Design Institute Co Class A", c: "CH" },
  { id: 19784, s: "APS.AX", n: "Allup Silica", c: "Australia" },
  { id: 19785, s: "002601.SZ", n: "LB Group", c: "CH" },
  { id: 19786, s: "4939.TWO", n: "Asia Electronic Material", c: "TW" },
  { id: 19787, s: "300777.SZ", n: "Sinofibers Technology", c: "CH" },
  { id: 19788, s: "002080.SZ", n: "Sinoma Science & Technology", c: "CH" },
  { id: 19789, s: "002133.SZ", n: "Cosmos Group", c: "CH" },
  { id: 19790, s: "002112.SZ", n: "San Bian Science & Technology", c: "CH" },
  { id: 19791, s: "5489.TWO", n: "Dynacolor", c: "TW" },
  { id: 19792, s: "2596.TWO", n: "ReaLy Development & Construction", c: "TW" },
  { id: 19793, s: "MEDH", n: "MedX Holdings Inc", c: "US" },
  { id: 19794, s: "002120.SZ", n: "YUNDA Holding", c: "CH" },
  { id: 19795, s: "002122.SZ", n: "Tianma Bearing Group", c: "CH" },
  { id: 19796, s: "002778.SZ", n: "Jiangsu Gaoke Petrochemical", c: "CH" },
  { id: 19797, s: "CRD.AX", n: "CONRAD ASIA ENERGY", c: "Australia" },
  { id: 19798, s: "300083.SZ", n: "Guangdong Create Century Intelligent Equipment Group Co", c: "CH" },
  { id: 19799, s: "300080.SZ", n: "Henan Yicheng New Energy", c: "CH" },
  { id: 19800, s: "PCMC", n: "Public Company Management Co", c: "US" },
  { id: 19801, s: "2740.TWO", n: "Mr. Onion", c: "TW" },
  { id: 19802, s: "300819.SZ", n: "Jiangsu Jujie Microfiber Technology", c: "CH" },
  { id: 19803, s: "300137.SZ", n: "Hebei Sailhero Environmental", c: "CH" },
  { id: 19804, s: "002609.SZ", n: "Shenzhen Jieshun Science and Technology Industry", c: "CH" },
  { id: 19805, s: "002382.SZ", n: "Blue Sail Medical", c: "CH" },
  { id: 19806, s: "REZ.AX", n: "Resources Energy Group", c: "Australia" },
  { id: 19807, s: "1611.TW", n: "CH Electric Manufacturing Co", c: "TW" },
  { id: 19808, s: "300171.SZ", n: "Shanghai Tofflon Science Tech", c: "CH" },
  { id: 19809, s: "RGN.AX", n: "Region Group Limited", c: "Australia" },
  { id: 19810, s: "300628.SZ", n: "Yealink Network Technology Co", c: "CH" },
  { id: 19811, s: "1615.TW", n: "Dah San Electric Wire & Cable", c: "TW" },
  { id: 19812, s: "3306.TWO", n: "RoyalTek", c: "TW" },
  { id: 19813, s: "300631.SZ", n: "Jiangsu Jiuwu Hi-Tech", c: "CH" },
  { id: 19814, s: "9946.TW", n: "San Far Property", c: "TW" },
  { id: 19815, s: "300633.SZ", n: "SonoScape Medical Co", c: "CH" },
  { id: 19816, s: "300634.SZ", n: "Richinfo Technology Co Class A", c: "CH" },
  { id: 19817, s: "GCX.AX", n: "GCX Metals Limited", c: "Australia" },
  { id: 19818, s: "GMNI", n: "Gemini Group Global Co", c: "US" },
  { id: 19819, s: "GDC.AX", n: "Global Data Centre Group", c: "Australia" },
  { id: 19820, s: "002889.SZ", n: "Shenzhen Easttop Supply Chain Management", c: "CH" },
  { id: 19821, s: "2459.TW", n: "Audix Co", c: "TW" },
  { id: 19822, s: "300636.SZ", n: "Jiangxi Synergy Pharmaceutical", c: "CH" },
  { id: 19823, s: "6224.TW", n: "Polytronics Technology Co", c: "TW" },
  { id: 19824, s: "RXL.AX", n: "Rox Resources", c: "Australia" },
  { id: 19825, s: "001202.SZ", n: "Guangdong Jushen Logistics", c: "CH" },
  { id: 19826, s: "SDV.AX", n: "Scidev", c: "Australia" },
  { id: 19827, s: "LLBO", n: "Lifeline Biotechnologies Inc", c: "US" },
  { id: 19828, s: "8261.TW", n: "Advanced Power Electronics Co", c: "TW" },
  { id: 19829, s: "300624.SZ", n: "Wondershare Technology Co Class A", c: "CH" },
  { id: 19830, s: "RIE.AX", n: "Riedel Resources", c: "Australia" },
  { id: 19831, s: "2642.TW", n: "Taiwan Pelican Express", c: "TW" },
  { id: 19832, s: "300645.SZ", n: "Zhejiang Zhengyuan Zhihui Technology", c: "CH" },
  { id: 19833, s: "PDOS", n: "Platinum Studios Inc", c: "US" },
  { id: 19834, s: "9924.TW", n: "Taiwan Fu Hsing Industrial", c: "TW" },
  { id: 19835, s: "8066.TWO", n: "Lifestyle Global Enterprise", c: "TW" },
  { id: 19836, s: "3290.TWO", n: "Donpon Precision", c: "TW" },
  { id: 19837, s: "300639.SZ", n: "Guangdong Hybribio Biotech", c: "CH" },
  { id: 19838, s: "300640.SZ", n: "Profit Cultural & Creative Group", c: "CH" },
  { id: 19839, s: "3287.TWO", n: "Kworld Computer", c: "TW" },
  { id: 19840, s: "300170.SZ", n: "Hand Enterprise Solutions", c: "CH" },
  { id: 19841, s: "RPL.AX", n: "Regal Funds Management Pty Limited", c: "Australia" },
  { id: 19842, s: "002806.SZ", n: "Guangdong Huafeng New Energy Technology", c: "CH" },
  { id: 19843, s: "300644.SZ", n: "Nanjing Julong Science & Technology Co Class A", c: "CH" },
  { id: 19844, s: "LGSXY", n: "Light SA ADR", c: "US" },
  { id: 19845, s: "RTR.AX", n: "Rumble Resources", c: "Australia" },
  { id: 19846, s: "4747.TWO", n: "Johnson Chemical Pharmaceutical Works", c: "TW" },
  { id: 19847, s: "002892.SZ", n: "Hunan Keli Motor", c: "CH" },
  { id: 19848, s: "000998.SZ", n: "Yuan Longping High-tech Agriculture", c: "CH" },
  { id: 19849, s: "300660.SZ", n: "Jiangsu Leili Motor Co", c: "CH" },
  { id: 19850, s: "9905.TW", n: "Great CH Metal Industry", c: "TW" },
  { id: 19851, s: "GLEC", n: "Global Ecology Co", c: "US" },
  { id: 19852, s: "3272.TWO", n: "Good Way Technology", c: "TW" },
  { id: 19853, s: "300652.SZ", n: "HangZhou Radical Energy Saving Technology", c: "CH" },
  { id: 19854, s: "8034.TWO", n: "Opnet Technologies", c: "TW" },
  { id: 19855, s: "002567.SZ", n: "Tangrenshen Group", c: "CH" },
  { id: 19856, s: "605011.SS", n: "Hangzhou Cogeneration Group", c: "CH" },
  { id: 19857, s: "605018.SS", n: "Zhejiang Changhua Auto Parts", c: "CH" },
  { id: 19858, s: "002569.SZ", n: "Zhejiang Busen Garments", c: "CH" },
  { id: 19859, s: "002570.SZ", n: "Beingmate Baby & Child Food", c: "CH" },
  { id: 19860, s: "002571.SZ", n: "Anhui Deli Household Glass", c: "CH" },
  { id: 19861, s: "002590.SZ", n: "Zhejiang Vie Science & Technology", c: "CH" },
  { id: 19862, s: "605005.SS", n: "CWB Automotive Electronics", c: "CH" },
  { id: 19863, s: "605008.SS", n: "Ningbo Changhong Polymer Scientific & Technical Inc", c: "CH" },
  { id: 19864, s: "605006.SS", n: "Shandong Fiberglass Group", c: "CH" },
  { id: 19865, s: "002575.SZ", n: "Guangdong Qunxing Toys Joint-Stock", c: "CH" },
  { id: 19866, s: "002598.SZ", n: "Shandong Zhangqiu Blower", c: "CH" },
  { id: 19867, s: "002648.SZ", n: "Zhejiang Satellite Petrochemical", c: "CH" },
  { id: 19868, s: "002599.SZ", n: "Beijing Shengtong Printing", c: "CH" },
  { id: 19869, s: "603719.SS", n: "Bestore", c: "CH" },
  { id: 19870, s: "002566.SZ", n: "Jilin Jian Yisheng Pharmaceutical", c: "CH" },
  { id: 19871, s: "002560.SZ", n: "Henan Tong-Da Cable", c: "CH" },
  { id: 19872, s: "002565.SZ", n: "Shanghai Luxin Packing Materials Science&Technology", c: "CH" },
  { id: 19873, s: "002591.SZ", n: "Jiangxi Hengda Hi-Tech", c: "CH" },
  { id: 19874, s: "002588.SZ", n: "Stanley Fertilizer", c: "CH" },
  { id: 19875, s: "002592.SZ", n: "Nanning Baling Technology", c: "CH" },
  { id: 19876, s: "002593.SZ", n: "Xiamen Sunrise Wheel Group", c: "CH" },
  { id: 19877, s: "002595.SZ", n: "Shandong Himile Mechanical Science & Technology", c: "CH" },
  { id: 19878, s: "SEEK", n: "Thedirectory.Com Inc", c: "US" },
  { id: 19879, s: "002597.SZ", n: "Anhui Jinhe Industrial", c: "CH" },
  { id: 19880, s: "603950.SS", n: "Xiangyang Changyuan Donggu Industry", c: "CH" },
  { id: 19881, s: "605020.SS", n: "Zhejiang Yonghe Refrigerant", c: "CH" },
  { id: 19882, s: "603993.SS", n: "CH Molybdenum Co Class A", c: "CH" },
  { id: 19883, s: "605001.SS", n: "Qingdao Victall Railway", c: "CH" },
  { id: 19884, s: "002559.SZ", n: "Jiangsu Yawei Machine Tool", c: "CH" },
  { id: 19885, s: "002629.SZ", n: "Zhejiang Renzhi", c: "CH" },
  { id: 19886, s: "002561.SZ", n: "Shanghai Xujiahui Commercial", c: "CH" },
  { id: 19887, s: "002562.SZ", n: "Brother Enterprises Holding", c: "CH" },
  { id: 19888, s: "002589.SZ", n: "Shandong Realcan Pharmaceutical", c: "CH" },
  { id: 19889, s: "002608.SZ", n: "Jiangsu Guoxin Co", c: "CH" },
  { id: 19890, s: "002630.SZ", n: "CH Western Power Industrial", c: "CH" },
  { id: 19891, s: "603353.SS", n: "Hunan Heshun Petroleum", c: "CH" },
  { id: 19892, s: "002605.SZ", n: "Shanghai Yaoji Playing Card", c: "CH" },
  { id: 19893, s: "002606.SZ", n: "Dalian Insulator Group", c: "CH" },
  { id: 19894, s: "002607.SZ", n: "Offcn Education Technology", c: "CH" },
  { id: 19895, s: "603682.SS", n: "Shanghai Golden Union Commercial Management", c: "CH" },
  { id: 19896, s: "603660.SS", n: "Suzhou Keda Technology", c: "CH" },
  { id: 19897, s: "002612.SZ", n: "Lancy", c: "CH" },
  { id: 19898, s: "002613.SZ", n: "Luoyang Northglass Technology", c: "CH" },
  { id: 19899, s: "002614.SZ", n: "Xiamen Comfort Science&Technology Group", c: "CH" },
  { id: 19900, s: "002615.SZ", n: "ZheJiang Haers Vacuum Containers", c: "CH" },
  { id: 19901, s: "002616.SZ", n: "Guangdong Chant Group Inc", c: "CH" },
  { id: 19902, s: "603551.SS", n: "AUPU Home Style Co", c: "CH" },
  { id: 19903, s: "002587.SZ", n: "Shenzhen Aoto Electronics", c: "CH" },
  { id: 19904, s: "002623.SZ", n: "Changzhou Almaden", c: "CH" },
  { id: 19905, s: "002621.SZ", n: "Dalian Sunlight Machinery", c: "CH" },
  { id: 19906, s: "002622.SZ", n: "Rongyu Group", c: "CH" },
  { id: 19907, s: "SFLM", n: "SFLMaven Co", c: "US" },
  { id: 19908, s: "603408.SS", n: "Runner Xiamen Co", c: "CH" },
  { id: 19909, s: "002626.SZ", n: "Xiamen Kingdomway Group", c: "CH" },
  { id: 19910, s: "002640.SZ", n: "Global Top E Commerce", c: "CH" },
  { id: 19911, s: "002632.SZ", n: "Daoming Optics&Chemical", c: "CH" },
  { id: 19912, s: "002633.SZ", n: "Shenke Slide Bearing Co", c: "CH" },
  { id: 19913, s: "002634.SZ", n: "ZheJiang BangJie Digital Knitting Share", c: "CH" },
  { id: 19914, s: "002637.SZ", n: "Zhejiang Zanyu Technology", c: "CH" },
  { id: 19915, s: "002628.SZ", n: "Chengdu Road & Bridge Engineering", c: "CH" },
  { id: 19916, s: "603213.SS", n: "Zhejiang Oceanking Development", c: "CH" },
  { id: 19917, s: "002641.SZ", n: "Era", c: "CH" },
  { id: 19918, s: "SGGKY", n: "Singapore Technologies Engineering ADR", c: "US" },
  { id: 19919, s: "002642.SZ", n: "Ronglian Group", c: "CH" },
  { id: 19920, s: "002643.SZ", n: "Valiant", c: "CH" },
  { id: 19921, s: "002644.SZ", n: "Lanzhou Foci Pharmaceutical", c: "CH" },
  { id: 19922, s: "002645.SZ", n: "Jiangsu Huahong Technology", c: "CH" },
  { id: 19923, s: "002646.SZ", n: "Qinghai Huzhu Barley Wine", c: "CH" },
  { id: 19924, s: "002639.SZ", n: "Fujian Snowman", c: "CH" },
  { id: 19925, s: "002580.SZ", n: "Shandong Sacred Sun Power Sources", c: "CH" },
  { id: 19926, s: "002667.SZ", n: "AnShan Heavy Duty Mining Machinery", c: "CH" },
  { id: 19927, s: "002586.SZ", n: "Zhejiang Reclaim Construction Group", c: "CH" },
  { id: 19928, s: "002585.SZ", n: "Jiangsu Shuangxing Color Plastic New Materials", c: "CH" },
  { id: 19929, s: "002665.SZ", n: "Beijing Shouhang Resources Saving", c: "CH" },
  { id: 19930, s: "002666.SZ", n: "Guangdong Delian Group", c: "CH" },
  { id: 19931, s: "603048.SS", n: "Zhejiang Liming Intelligent Manufacturing", c: "CH" },
  { id: 19932, s: "002669.SZ", n: "Shanghai Kangda New Materials", c: "CH" },
  { id: 19933, s: "002694.SZ", n: "Goody Science & Technology", c: "CH" },
  { id: 19934, s: "002650.SZ", n: "Jiajia Food Group", c: "CH" },
  { id: 19935, s: "601877.SS", n: "Zhejiang Chint Electrics", c: "CH" },
  { id: 19936, s: "002698.SZ", n: "Harbin Boshi Automation", c: "CH" },
  { id: 19937, s: "601717.SS", n: "Zhengzhou Coal Mining Machinery Group", c: "CH" },
  { id: 19938, s: "002728.SZ", n: "Teyi Pharmaceutical Group", c: "CH" },
  { id: 19939, s: "002729.SZ", n: "Hollyland CH Electronics Technology Co", c: "CH" },
  { id: 19940, s: "002730.SZ", n: "Dianguang Explosion-proof Technology", c: "CH" },
  { id: 19941, s: "002731.SZ", n: "Shenyang Cuihua Gold and Silver Jewelry", c: "CH" },
  { id: 19942, s: "002732.SZ", n: "Guangdong Yantang Dairy", c: "CH" },
  { id: 19943, s: "002733.SZ", n: "Shenzhen Center Power Tech", c: "CH" },
  { id: 19944, s: "002664.SZ", n: "Xinzhi Motor", c: "CH" },
  { id: 19945, s: "603087.SS", n: "Gan & Lee Pharmaceuticals", c: "CH" },
  { id: 19946, s: "603095.SS", n: "Zhejiang Yuejian Intelligent Equipment", c: "CH" },
  { id: 19947, s: "002651.SZ", n: "Chengdu Leejun Industrial", c: "CH" },
  { id: 19948, s: "SITOQ", n: "SITO Mobile", c: "US" },
  { id: 19949, s: "002693.SZ", n: "Hainan Shuangcheng Pharmaceut", c: "CH" },
  { id: 19950, s: "603790.SS", n: "Argus Shanghai Textile Chemicals", c: "CH" },
  { id: 19951, s: "002672.SZ", n: "Dongjiang Environmental", c: "CH" },
  { id: 19952, s: "SHTDY", n: "SINOPHARM GROUP CO ADR", c: "US" },
  { id: 19953, s: "002670.SZ", n: "Guosheng Financial Holding Inc", c: "CH" },
  { id: 19954, s: "603195.SS", n: "Gongniu Group", c: "CH" },
  { id: 19955, s: "002661.SZ", n: "Chen Ke Ming Food Manufacturing", c: "CH" },
  { id: 19956, s: "603171.SS", n: "Servyou Software Group", c: "CH" },
  { id: 19957, s: "603160.SS", n: "Shenzhen Goodix Technology Co Class A", c: "CH" },
  { id: 19958, s: "002655.SZ", n: "Shandong Gettop Acoustic", c: "CH" },
  { id: 19959, s: "002657.SZ", n: "Sinodata", c: "CH" },
  { id: 19960, s: "002658.SZ", n: "Beijing SDL Technology", c: "CH" },
  { id: 19961, s: "002660.SZ", n: "Moso Power Supply Technology", c: "CH" },
  { id: 19962, s: "002726.SZ", n: "Shandong Longda Meat Foodstuff", c: "CH" },
  { id: 19963, s: "002734.SZ", n: "Limin Chemical", c: "CH" },
  { id: 19964, s: "002737.SZ", n: "Sunflower Pharmaceuticals Group", c: "CH" },
  { id: 19965, s: "601868.SS", n: "CH Energy Engineering Co", c: "CH" },
  { id: 19966, s: "002716.SZ", n: "Chenzhou Jingui Silver Industry", c: "CH" },
  { id: 19967, s: "002717.SZ", n: "Lingnan Landscape", c: "CH" },
  { id: 19968, s: "002718.SZ", n: "Zhejiang Youpon Integrated Ceiling", c: "CH" },
  { id: 19969, s: "002719.SZ", n: "Maiquer Group", c: "CH" },
  { id: 19970, s: "002721.SZ", n: "Beijing Kingee Culture Development", c: "CH" },
  { id: 19971, s: "002722.SZ", n: "Geron", c: "CH" },
  { id: 19972, s: "002356.SZ", n: "Shenzhen Haoningda Meters", c: "CH" },
  { id: 19973, s: "601808.SS", n: "CH Oilfield Services Class A", c: "CH" },
  { id: 19974, s: "603212.SS", n: "Cybrid Technologies Inc", c: "CH" },
  { id: 19975, s: "002647.SZ", n: "Rendong Holdings", c: "CH" },
  { id: 19976, s: "002581.SZ", n: "Shandong Sinobioway Biomedicine", c: "CH" },
  { id: 19977, s: "603948.SS", n: "Zhejiang Jianye Chemical", c: "CH" },
  { id: 19978, s: "002583.SZ", n: "Hytera Communications Co", c: "CH" },
  { id: 19979, s: "002584.SZ", n: "Xilong Chemical", c: "CH" },
  { id: 19980, s: "002715.SZ", n: "Huaiji Dengyun Auto-parts Holding", c: "CH" },
  { id: 19981, s: "002712.SZ", n: "Simei Media", c: "CH" },
  { id: 19982, s: "601686.SS", n: "Tianjin You Fa Steel Pipe Group Stock", c: "CH" },
  { id: 19983, s: "601727.SS", n: "Shanghai Electric Group", c: "CH" },
  { id: 19984, s: "002741.SZ", n: "Guangdong Guanghua Sci-Tech", c: "CH" },
  { id: 19985, s: "002742.SZ", n: "Chongqing Sansheng Building Materials", c: "CH" },
  { id: 19986, s: "601609.SS", n: "Ningbo Jintian Copper Group", c: "CH" },
  { id: 19987, s: "601607.SS", n: "Shanghai Pharmaceuticals Holding", c: "CH" },
  { id: 19988, s: "002735.SZ", n: "Shenzhen Prince New Materials", c: "CH" },
  { id: 19989, s: "002725.SZ", n: "Zhejiang Yueling", c: "CH" },
  { id: 19990, s: "002723.SZ", n: "Kennede Electronics MFG", c: "CH" },
  { id: 19991, s: "SKPO", n: "Skye Petroleum Inc", c: "US" },
  { id: 19992, s: "002699.SZ", n: "Meisheng Cultural & Creative Co", c: "CH" },
  { id: 19993, s: "002700.SZ", n: "Xinjiang Haoyuan Natural Gas", c: "CH" },
  { id: 19994, s: "002701.SZ", n: "ORG Packaging", c: "CH" },
  { id: 19995, s: "002702.SZ", n: "HaiXin Foods", c: "CH" },
  { id: 19996, s: "002706.SZ", n: "Shanghai Liangxin Electrical", c: "CH" },
  { id: 19997, s: "002708.SZ", n: "Changzhou NRB Co", c: "CH" },
  { id: 19998, s: "603439.SS", n: "Guizhou Sanli Pharmaceutical", c: "CH" },
  { id: 19999, s: "000759.SZ", n: "Zhongbai Holdings Group", c: "CH" },
  { id: 20000, s: "002355.SZ", n: "Shandong Xingmin Wheel", c: "CH" },
  { id: 20001, s: "002353.SZ", n: "Yantai Jereh Oilfield Services Group", c: "CH" },
  { id: 20002, s: "001288.SZ", n: "Sichuan Zigong Conveying Machine Group", c: "CH" },
  { id: 20003, s: "MOYZK.AT", n: "EL. D. Mouzakis S.A", c: "Greece" },
  { id: 20004, s: "RIVU", n: "Rivulet Media Inc", c: "US" },
  { id: 20005, s: "MEVA.AT", n: "Mevaco S.A", c: "Greece" },
  { id: 20006, s: "MEDIC.AT", n: "Medicon Hellas S.A.", c: "Greece" },
  { id: 20007, s: "002002.SZ", n: "Hongda Xingye", c: "CH" },
  { id: 20008, s: "002003.SZ", n: "Zhejiang Weixing Industrial Development", c: "CH" },
  { id: 20009, s: "002004.SZ", n: "Huapont Life Sciences", c: "CH" },
  { id: 20010, s: "001318.SZ", n: "Jiangxi Sunshine Dairy", c: "CH" },
  { id: 20011, s: "001205.SZ", n: "Nanjing Shenghang Shipping", c: "CH" },
  { id: 20012, s: "001203.SZ", n: "Inner Mongolia Dazhong Mining", c: "CH" },
  { id: 20013, s: "001201.SZ", n: "Dongrui Food Group", c: "CH" },
  { id: 20014, s: "000927.SZ", n: "CH Railway Materials", c: "CH" },
  { id: 20015, s: "QTCI", n: "Quantum Capital Inc", c: "US" },
  { id: 20016, s: "000825.SZ", n: "Shanxi Taigang Stainless Steel", c: "CH" },
  { id: 20017, s: "PROFK.AT", n: "Pipe Works L. Girakian Profil S.A.", c: "Greece" },
  { id: 20018, s: "001267.SZ", n: "Hui Lyu Ecological Technology Groups", c: "CH" },
  { id: 20019, s: "001219.SZ", n: "Qingdao Foods", c: "CH" },
  { id: 20020, s: "002114.SZ", n: "Yunnan luoping Zinc & Electricity", c: "CH" },
  { id: 20021, s: "900924.SS", n: "Shang Gong Group Co B", c: "CH" },
  { id: 20022, s: "900923.SS", n: "Shanghai Bailian Group Co B", c: "CH" },
  { id: 20023, s: "900922.SS", n: "Shanghai Sanmao Enterprise Group Co B", c: "CH" },
  { id: 20024, s: "002132.SZ", n: "Henan Hengxing Science & Technology", c: "CH" },
  { id: 20025, s: "002134.SZ", n: "Tianjin Printronics Circuit Co", c: "CH" },
  { id: 20026, s: "002135.SZ", n: "Zhejiang Southeast Space Frame", c: "CH" },
  { id: 20027, s: "002163.SZ", n: "Hainan Development Holdings Nanhai", c: "CH" },
  { id: 20028, s: "MPITR.AT", n: "Bitros Holding S.A", c: "Greece" },
  { id: 20029, s: "ELTON.AT", n: "Elton International Trading Company S.A", c: "Greece" },
  { id: 20030, s: "002058.SZ", n: "Shanghai Welltech Automation", c: "CH" },
  { id: 20031, s: "PRD.AT", n: "Proodeftiki S.A.", c: "Greece" },
  { id: 20032, s: "PAIR.AT", n: "E. Pairis S.A", c: "Greece" },
  { id: 20033, s: "001215.SZ", n: "Zhengzhou Qianweiyangchu Food", c: "CH" },
  { id: 20034, s: "NAKAS.AT", n: "Philippos Nakas S.A", c: "Greece" },
  { id: 20035, s: "000785.SZ", n: "Easyhome New Retail Group", c: "CH" },
  { id: 20036, s: "QUAN", n: "Quantum Int. Co", c: "US" },
  { id: 20037, s: "000736.SZ", n: "CCCG Real Estate Co", c: "CH" },
  { id: 20038, s: "002052.SZ", n: "Shenzhen Coship Electronics", c: "CH" },
  { id: 20039, s: "002043.SZ", n: "Dehua TB New Decoration Material", c: "CH" },
  { id: 20040, s: "FIER.AT", n: "Fieratex S.A.", c: "Greece" },
  { id: 20041, s: "002037.SZ", n: "Poly Union Chemical Holding Group", c: "CH" },
  { id: 20042, s: "002045.SZ", n: "Guoguang Electric", c: "CH" },
  { id: 20043, s: "002048.SZ", n: "Ningbo Huaxiang Electronic", c: "CH" },
  { id: 20044, s: "002051.SZ", n: "CH Camc Engineering", c: "CH" },
  { id: 20045, s: "002053.SZ", n: "Yunnan Salt & Salt Chemical Industry", c: "CH" },
  { id: 20046, s: "FLEXO.AT", n: "Flexopack Société Anonyme Commercial and Industrial Plastics Company", c: "Greece" },
  { id: 20047, s: "002054.SZ", n: "Dymatic Chemicals Inc", c: "CH" },
  { id: 20048, s: "002055.SZ", n: "Shenzhen Deren Electronic", c: "CH" },
  { id: 20049, s: "002057.SZ", n: "Sinosteel Anhui Tianyuan Technology", c: "CH" },
  { id: 20050, s: "002047.SZ", n: "Shen Zhen Bauing Construction Holding Group", c: "CH" },
  { id: 20051, s: "002691.SZ", n: "Jikai Equipment Manufacturing", c: "CH" },
  { id: 20052, s: "002036.SZ", n: "LianChuang Electronic Technology", c: "CH" },
  { id: 20053, s: "002042.SZ", n: "Huafu Top Dyed Melange Yarn", c: "CH" },
  { id: 20054, s: "RMLRF", n: "Ramelius Resources", c: "US" },
  { id: 20055, s: "000630.SZ", n: "Tongling Nonferrous Metals Group", c: "CH" },
  { id: 20056, s: "SPACE.AT", n: "Space Hellas SA", c: "Greece" },
  { id: 20057, s: "000554.SZ", n: "Sinopec Shandong Taishan Petroleum", c: "CH" },
  { id: 20058, s: "000509.SZ", n: "Huasu Holdings", c: "CH" },
  { id: 20059, s: "000059.SZ", n: "North Huajin Chemical Industries", c: "CH" },
  { id: 20060, s: "000029.SZ", n: "ShenZhen Special Economic Zone Real Estate & Properties Group", c: "CH" },
  { id: 20061, s: "REPO", n: "National Asset Recovery Co", c: "US" },
  { id: 20062, s: "VOSYS.AT", n: "Vogiatzoglou Systems S.A", c: "Greece" },
  { id: 20063, s: "SAR.AT", n: "Gr. Sarantis S.A.", c: "Greece" },
  { id: 20064, s: "002039.SZ", n: "Guizhou Qianyuan Power", c: "CH" },
  { id: 20065, s: "REVOIL.AT", n: "Revoil S.A", c: "Greece" },
  { id: 20066, s: "002005.SZ", n: "Elec-Tech International", c: "CH" },
  { id: 20067, s: "002060.SZ", n: "Guangdong No2 Hydropower Engineering", c: "CH" },
  { id: 20068, s: "002006.SZ", n: "Zhejiang Jinggong Science Technology", c: "CH" },
  { id: 20069, s: "002009.SZ", n: "Miracle Automation Engineering", c: "CH" },
  { id: 20070, s: "GEBKA.AT", n: "General Commercial & Industrial S.A", c: "Greece" },
  { id: 20071, s: "002128.SZ", n: "Huolinhe Opencut Coal Industry Corp of Inner Mongolia", c: "CH" },
  { id: 20072, s: "900925.SS", n: "Shanghai Mechanical & Electrical Industry Co B", c: "CH" },
  { id: 20073, s: "002125.SZ", n: "Xiangtan Electrochemical Scientific", c: "CH" },
  { id: 20074, s: "DROME.AT", n: "Dromeas SA", c: "Greece" },
  { id: 20075, s: "002090.SZ", n: "Wiscom System", c: "CH" },
  { id: 20076, s: "002089.SZ", n: "Suzhou New Sea Union Telecom Technology", c: "CH" },
  { id: 20077, s: "ELIN.AT", n: "Elinoil Hellenic Petroleum Company S.A", c: "Greece" },
  { id: 20078, s: "EKTER.AT", n: "Ekter SA", c: "Greece" },
  { id: 20079, s: "002066.SZ", n: "Ruitai Materials Technology", c: "CH" },
  { id: 20080, s: "002067.SZ", n: "Zhejiang Jingxing Paper Joint Stock", c: "CH" },
  { id: 20081, s: "DOMIK.AT", n: "Domiki Kritis S.A.", c: "Greece" },
  { id: 20082, s: "002102.SZ", n: "Fujian Guanfu Modern Household Wares", c: "CH" },
  { id: 20083, s: "002072.SZ", n: "Kairuide Holding", c: "CH" },
  { id: 20084, s: "002073.SZ", n: "Mesnac", c: "CH" },
  { id: 20085, s: "002076.SZ", n: "CNlight", c: "CH" },
  { id: 20086, s: "002077.SZ", n: "Jiangsu Dagang", c: "CH" },
  { id: 20087, s: "ATTICA.AT", n: "Attica Holdings S.A", c: "Greece" },
  { id: 20088, s: "002079.SZ", n: "Suzhou Good-Ark Electronics", c: "CH" },
  { id: 20089, s: "002091.SZ", n: "Jiangsu Guotai International Group Guomao", c: "CH" },
  { id: 20090, s: "002109.SZ", n: "Shaanxi Xinghua Chemistry", c: "CH" },
  { id: 20091, s: "002083.SZ", n: "Sunvim Group", c: "CH" },
  { id: 20092, s: "002098.SZ", n: "Fujian SBS Zipper Science Tech", c: "CH" },
  { id: 20093, s: "PSPW", n: "3Power Energy Group Inc", c: "US" },
  { id: 20094, s: "002093.SZ", n: "Guomai Technologies Inc", c: "CH" },
  { id: 20095, s: "002094.SZ", n: "Qingdao Kingking Applied Chemistry", c: "CH" },
  { id: 20096, s: "002095.SZ", n: "Zhejiang Netsun", c: "CH" },
  { id: 20097, s: "900952.SS", n: "Jinzhou Port Co B", c: "CH" },
  { id: 20098, s: "900948.SS", n: "Inner Mongolia Yitai Coal", c: "CH" },
  { id: 20099, s: "002099.SZ", n: "Zhejiang Hisoar Pharmaceutical", c: "CH" },
  { id: 20100, s: "002108.SZ", n: "Cangzhou Mingzhu Plastic", c: "CH" },
  { id: 20101, s: "900943.SS", n: "Shanghai Kai Kai Industry Co B", c: "CH" },
  { id: 20102, s: "900942.SS", n: "Huangshan Tourism Development Co B", c: "CH" },
  { id: 20103, s: "002104.SZ", n: "Hengbao", c: "CH" },
  { id: 20104, s: "002105.SZ", n: "HL Corp Shenzhen", c: "CH" },
  { id: 20105, s: "002106.SZ", n: "Shenzhen Laibao High-Technology", c: "CH" },
  { id: 20106, s: "002107.SZ", n: "Shandong Wohua Pharmaceutical", c: "CH" },
  { id: 20107, s: "ATEK.AT", n: "Attica Publications S.A", c: "Greece" },
  { id: 20108, s: "002086.SZ", n: "Shandong Oriental Ocean Sci-Tech", c: "CH" },
  { id: 20109, s: "002124.SZ", n: "Ningbo Tech-Bank", c: "CH" },
  { id: 20110, s: "002136.SZ", n: "AnHui Annada Titanium Industry", c: "CH" },
  { id: 20111, s: "002159.SZ", n: "Wuhan Sante Cableway Group", c: "CH" },
  { id: 20112, s: "900901.SS", n: "Inesa Intelligent Tech Inc B", c: "CH" },
  { id: 20113, s: "002162.SZ", n: "Everjoy Health Group", c: "CH" },
  { id: 20114, s: "900906.SS", n: "Guizhou Zhongyida", c: "CH" },
  { id: 20115, s: "002139.SZ", n: "Shenzhen Topband", c: "CH" },
  { id: 20116, s: "002137.SZ", n: "Shenzhen Sea Star Technology", c: "CH" },
  { id: 20117, s: "002116.SZ", n: "CH Haisum Engineering", c: "CH" },
  { id: 20118, s: "900903.SS", n: "Dazhong Transportation Group Co B", c: "CH" },
  { id: 20119, s: "900934.SS", n: "Shanghai Jin Jiang International Hotels Development Co B", c: "CH" },
  { id: 20120, s: "002119.SZ", n: "Ningbo Kangqiang Electronics", c: "CH" },
  { id: 20121, s: "900928.SS", n: "Shanghai Lingang Holdings Co B", c: "CH" },
  { id: 20122, s: "002121.SZ", n: "Shenzhen Clou Electronics", c: "CH" },
  { id: 20123, s: "900927.SS", n: "Shanghai Material Trading Co B", c: "CH" },
  { id: 20124, s: "002123.SZ", n: "Montnets Cloud Technology Group", c: "CH" },
  { id: 20125, s: "900902.SS", n: "Shanghai Shibei Hi-Tech Co B", c: "CH" },
  { id: 20126, s: "900905.SS", n: "Lao Feng Xiang Co B", c: "CH" },
  { id: 20127, s: "002087.SZ", n: "Henan Xinye Textile", c: "CH" },
  { id: 20128, s: "900913.SS", n: "Shanxi Guoxin Energy Co B", c: "CH" },
  { id: 20129, s: "ANDRO.AT", n: "Alpha Trust Andromeda Investment Trust S.A", c: "Greece" },
  { id: 20130, s: "900941.SS", n: "Eastern Communications Co B", c: "CH" },
  { id: 20131, s: "002062.SZ", n: "Hongrun Construction Group", c: "CH" },
  { id: 20132, s: "900936.SS", n: "Inner Mongolia ERDOS Resources Co B", c: "CH" },
  { id: 20133, s: "PVBK", n: "Pacific Valley Bank", c: "US" },
  { id: 20134, s: "002145.SZ", n: "CNNC Hua Yuan Titanium Dioxide", c: "CH" },
  { id: 20135, s: "900912.SS", n: "Shanghai Waigaoqiao Free Trade Zone Group Co B", c: "CH" },
  { id: 20136, s: "002155.SZ", n: "Hunan Gold Co", c: "CH" },
  { id: 20137, s: "002149.SZ", n: "Western Metal Materials", c: "CH" },
  { id: 20138, s: "900911.SS", n: "Shanghai Jinqiao Export Processing Zone Development Co B", c: "CH" },
  { id: 20139, s: "900910.SS", n: "Shanghai Highly Group Co B", c: "CH" },
  { id: 20140, s: "900909.SS", n: "Shanghai Huayi Group Corp B", c: "CH" },
  { id: 20141, s: "002140.SZ", n: "East CH Engineering Science and Technology", c: "CH" },
  { id: 20142, s: "900908.SS", n: "Shanghai Chlor-Alkali Chemical Co B", c: "CH" },
  { id: 20143, s: "002692.SZ", n: "Yuancheng Cable", c: "CH" },
  { id: 20144, s: "089140.KQ", n: "NexturnBioscience Co.", c: "Korea" },
  { id: 20145, s: "601918.SS", n: "CH Coal Xinji Energy", c: "CH" },
  { id: 20146, s: "081150.KQ", n: "Tplex Co.", c: "Korea" },
  { id: 20147, s: "071280.KQ", n: "Rorze Systems Corporation", c: "Korea" },
  { id: 20148, s: "072990.KQ", n: "HCT Co.", c: "Korea" },
  { id: 20149, s: "073110.KQ", n: "LMS Co.", c: "Korea" },
  { id: 20150, s: "073560.KQ", n: "Woorison F&G Co.", c: "Korea" },
  { id: 20151, s: "080160.KQ", n: "Modetour Network Inc", c: "Korea" },
  { id: 20152, s: "080000.KQ", n: "SNU Precision Co.", c: "Korea" },
  { id: 20153, s: "079960.KQ", n: "Dongyang E&P Inc", c: "Korea" },
  { id: 20154, s: "079940.KQ", n: "GABIA Inc", c: "Korea" },
  { id: 20155, s: "079810.KQ", n: "DE&T Co.", c: "Korea" },
  { id: 20156, s: "079370.KQ", n: "Zeus Co.Ltd", c: "Korea" },
  { id: 20157, s: "WCIG", n: "Wee-Cig International Co", c: "US" },
  { id: 20158, s: "079000.KQ", n: "Watos Corea Co.", c: "Korea" },
  { id: 20159, s: "KLYCY", n: "Kunlun Energy Co PK", c: "US" },
  { id: 20160, s: "078350.KQ", n: "Hanyang Digitech Co.", c: "Korea" },
  { id: 20161, s: "078140.KQ", n: "Daebongls.Co.Ltd", c: "Korea" },
  { id: 20162, s: "067730.KQ", n: "Logisys Inc", c: "Korea" },
  { id: 20163, s: "067990.KQ", n: "Deutsch Motors Inc", c: "Korea" },
  { id: 20164, s: "068240.KQ", n: "Dawonsys Co.Ltd", c: "Korea" },
  { id: 20165, s: "072020.KQ", n: "Choong Ang Vaccine Laboratory Co.", c: "Korea" },
  { id: 20166, s: "080580.KQ", n: "Okins Electronics Co.", c: "Korea" },
  { id: 20167, s: "080220.KQ", n: "Jeju Semiconductor Co", c: "Korea" },
  { id: 20168, s: "072870.KQ", n: "MegaStudy Co.", c: "Korea" },
  { id: 20169, s: "072770.KQ", n: "Yulho Co.", c: "Korea" },
  { id: 20170, s: "072520.KQ", n: "GenNBio Inc", c: "Korea" },
  { id: 20171, s: "072470.KQ", n: "Woory Industrial Holdings Co.Ltd", c: "Korea" },
  { id: 20172, s: "071670.KQ", n: "A-Tech Solution Co.", c: "Korea" },
  { id: 20173, s: "069140.KQ", n: "Nuriplan Co.", c: "Korea" },
  { id: 20174, s: "HCF.AX", n: "H&G High Conviction.", c: "Australia" },
  { id: 20175, s: "072950.KQ", n: "Vissem Electronics Co.", c: "Korea" },
  { id: 20176, s: "071460.KQ", n: "WINIADIMCHAE Co.Ltd", c: "Korea" },
  { id: 20177, s: "071200.KQ", n: "INFINITT Healthcare Co.", c: "Korea" },
  { id: 20178, s: "069920.KQ", n: "ISE Commerce Company Limited", c: "Korea" },
  { id: 20179, s: "069510.KQ", n: "ESTec Corporation", c: "Korea" },
  { id: 20180, s: "078020.KQ", n: "EBEST Investment & Securities Co.", c: "Korea" },
  { id: 20181, s: "077360.KQ", n: "Duksan Hi Metal Co.Ltd", c: "Korea" },
  { id: 20182, s: "076080.KQ", n: "Welcron Hantec Co.Ltd", c: "Korea" },
  { id: 20183, s: "605388.SS", n: "JuneYao Dairy", c: "CH" },
  { id: 20184, s: "605266.SS", n: "Yunnan Jianzhijia Health-Chain", c: "CH" },
  { id: 20185, s: "605259.SS", n: "Lutian Machinery", c: "CH" },
  { id: 20186, s: "605255.SS", n: "Ningbo Tip Rubber Technology", c: "CH" },
  { id: 20187, s: "605228.SS", n: "Shentong Technology Group", c: "CH" },
  { id: 20188, s: "002495.SZ", n: "Guangdong Jialong Food", c: "CH" },
  { id: 20189, s: "002510.SZ", n: "Tian Jin Motor Dies", c: "CH" },
  { id: 20190, s: "002479.SZ", n: "Zhejiang Fuchunjiang Environmental Thermoelectric", c: "CH" },
  { id: 20191, s: "605288.SS", n: "Changzhou Kaidi Electrical Inc", c: "CH" },
  { id: 20192, s: "688002.SS", n: "Raytron Technology", c: "CH" },
  { id: 20193, s: "002458.SZ", n: "Shandong Yisheng Livestock & Poultry Breeding", c: "CH" },
  { id: 20194, s: "PRDL", n: "Profitable Develop", c: "US" },
  { id: 20195, s: "002461.SZ", n: "Guangzhou Zhujiang Brewery", c: "CH" },
  { id: 20196, s: "002462.SZ", n: "Cachet Pharmaceutical", c: "CH" },
  { id: 20197, s: "002463.SZ", n: "WUS Printed Circuit Kunshan", c: "CH" },
  { id: 20198, s: "605287.SS", n: "Decai Decoration", c: "CH" },
  { id: 20199, s: "002496.SZ", n: "Jiangsu Huifeng Agrochemical", c: "CH" },
  { id: 20200, s: "075970.KQ", n: "Dongkuk Refractories & Steel Co.", c: "Korea" },
  { id: 20201, s: "605376.SS", n: "Jiangsu Boqian New Materials Stock", c: "CH" },
  { id: 20202, s: "075130.KQ", n: "Plantynet Co.", c: "Korea" },
  { id: 20203, s: "074430.KQ", n: "Aminologics Co.Ltd", c: "Korea" },
  { id: 20204, s: "080470.KQ", n: "Sungchang Autotech Co.", c: "Korea" },
  { id: 20205, s: "PWN.AX", n: "Parkway Minerals NL", c: "Australia" },
  { id: 20206, s: "103590.KS", n: "Iljin Electric", c: "Korea" },
  { id: 20207, s: "300486.SZ", n: "OMH SCIENCE Group", c: "CH" },
  { id: 20208, s: "002485.SZ", n: "Cedar Development", c: "CH" },
  { id: 20209, s: "002494.SZ", n: "Huasi Agricultural Development", c: "CH" },
  { id: 20210, s: "002488.SZ", n: "Zhejiang Jingu", c: "CH" },
  { id: 20211, s: "002490.SZ", n: "Shandong Molong Petroleum Machinery", c: "CH" },
  { id: 20212, s: "002491.SZ", n: "Tongding Interconnection Information", c: "CH" },
  { id: 20213, s: "605358.SS", n: "Hangzhou Lion Electronics", c: "CH" },
  { id: 20214, s: "605337.SS", n: "Zhe Jiang Li Zi Yuan Food", c: "CH" },
  { id: 20215, s: "002483.SZ", n: "Jiangsu Rainbow Heavy Industries", c: "CH" },
  { id: 20216, s: "067290.KQ", n: "JW Shinyak Corporation", c: "Korea" },
  { id: 20217, s: "088910.KQ", n: "Dongwoo Farm To Table Co.Ltd", c: "Korea" },
  { id: 20218, s: "002480.SZ", n: "Chengdu Xinzhu Road&Bridge Machinery", c: "CH" },
  { id: 20219, s: "GCI.AX", n: "Gryphon Capital Income Trust", c: "Australia" },
  { id: 20220, s: "056700.KQ", n: "Shinwha Intertek Co", c: "Korea" },
  { id: 20221, s: "056190.KQ", n: "SFA Engineering Corporation", c: "Korea" },
  { id: 20222, s: "056080.KQ", n: "Yujin Robot Co.", c: "Korea" },
  { id: 20223, s: "054950.KQ", n: "JVM Co.", c: "Korea" },
  { id: 20224, s: "054920.KQ", n: "Hancom WITH Inc", c: "Korea" },
  { id: 20225, s: "054780.KQ", n: "KEYEAST.Co.Ltd", c: "Korea" },
  { id: 20226, s: "ITVPY", n: "ITV PLC ADR", c: "US" },
  { id: 20227, s: "054630.KQ", n: "Advanced Digital Chips Inc", c: "Korea" },
  { id: 20228, s: "054620.KQ", n: "APS Holdings Corporation", c: "Korea" },
  { id: 20229, s: "054540.KQ", n: "SAMYOUNG M-Tek Co.", c: "Korea" },
  { id: 20230, s: "054410.KQ", n: "Korea Plasma Technology U Co.Ltd", c: "Korea" },
  { id: 20231, s: "054300.KQ", n: "Panstar Enterprise Co.Ltd", c: "Korea" },
  { id: 20232, s: "054180.KQ", n: "MEDICOX Co.", c: "Korea" },
  { id: 20233, s: "IIGF.AX", n: "Intelligent Investor Australian Equity Growth Fund (Managed Fund)", c: "Australia" },
  { id: 20234, s: "092460.KQ", n: "Hanla IMS Co.", c: "Korea" },
  { id: 20235, s: "057030.KQ", n: "YBM Net Inc", c: "Korea" },
  { id: 20236, s: "057540.KQ", n: "Omnisystem Co.Ltd", c: "Korea" },
  { id: 20237, s: "057680.KQ", n: "T Scientific Co.Ltd", c: "Korea" },
  { id: 20238, s: "052710.KQ", n: "Amotech Co.", c: "Korea" },
  { id: 20239, s: "002034.SZ", n: "Wangneng Environment", c: "CH" },
  { id: 20240, s: "053280.KQ", n: "YES24 Co.Ltd", c: "Korea" },
  { id: 20241, s: "ISDCF", n: "Verimatrix", c: "US" },
  { id: 20242, s: "053260.KQ", n: "Keum Kang Steel Co.", c: "Korea" },
  { id: 20243, s: "053030.KQ", n: "BINEX Co.", c: "Korea" },
  { id: 20244, s: "052790.KQ", n: "Actoz Soft Co.Ltd", c: "Korea" },
  { id: 20245, s: "052330.KQ", n: "Kortek Corporation", c: "Korea" },
  { id: 20246, s: "058110.KQ", n: "MEKICS", c: "Korea" },
  { id: 20247, s: "052220.KQ", n: "iMBC Co.Ltd", c: "Korea" },
  { id: 20248, s: "052020.KQ", n: "STCube Inc", c: "Korea" },
  { id: 20249, s: "051980.KQ", n: "JOONGANG DNM Co.Ltd", c: "Korea" },
  { id: 20250, s: "053050.KQ", n: "GSE Co.", c: "Korea" },
  { id: 20251, s: "054090.KQ", n: "Samjin LND Co.", c: "Korea" },
  { id: 20252, s: "ITCFY", n: "Investec ADR", c: "US" },
  { id: 20253, s: "067080.KQ", n: "DAE HWA Pharmaceutical Co.", c: "Korea" },
  { id: 20254, s: "067570.KQ", n: "NVH Korea Inc", c: "Korea" },
  { id: 20255, s: "086670.KQ", n: "BMT Co.", c: "Korea" },
  { id: 20256, s: "090360.KQ", n: "Robostar Co.Ltd", c: "Korea" },
  { id: 20257, s: "092070.KQ", n: "DNF Co.Ltd", c: "Korea" },
  { id: 20258, s: "091700.KQ", n: "Partron Co.", c: "Korea" },
  { id: 20259, s: "091590.KQ", n: "Nam Hwa Construction Co.Ltd", c: "Korea" },
  { id: 20260, s: "091340.KQ", n: "S&K Polytec Co.", c: "Korea" },
  { id: 20261, s: "090710.KQ", n: "Hyulim ROBOT Co.Ltd", c: "Korea" },
  { id: 20262, s: "090410.KQ", n: "Duckshin Housing Co.", c: "Korea" },
  { id: 20263, s: "090150.KQ", n: "KWANGJIN WINTEC CO.", c: "Korea" },
  { id: 20264, s: "087600.KQ", n: "Pixelplus. Co.", c: "Korea" },
  { id: 20265, s: "089980.KQ", n: "Sang-A Frontec Co.Ltd", c: "Korea" },
  { id: 20266, s: "089970.KQ", n: "Adaptive Plasma Technology Co", c: "Korea" },
  { id: 20267, s: "089790.KQ", n: "JT Corporation", c: "Korea" },
  { id: 20268, s: "089230.KQ", n: "The E&M Co.", c: "Korea" },
  { id: 20269, s: "089150.KQ", n: "Korea Computer Terminal Inc", c: "Korea" },
  { id: 20270, s: "089030.KQ", n: "Techwing Inc", c: "Korea" },
  { id: 20271, s: "088130.KQ", n: "Dong A Eltek Co.", c: "Korea" },
  { id: 20272, s: "086960.KQ", n: "Hancom MDS Inc", c: "Korea" },
  { id: 20273, s: "WARFY", n: "Wharf Holdings", c: "US" },
  { id: 20274, s: "KRYPY", n: "Kerry Properties ADR", c: "US" },
  { id: 20275, s: "086450.KQ", n: "DongKook Pharmaceutical Co.", c: "Korea" },
  { id: 20276, s: "085910.KQ", n: "Neo Technical System Co.", c: "Korea" },
  { id: 20277, s: "WBWB", n: "WU BA Superior Products Holding Group Inc", c: "US" },
  { id: 20278, s: "084730.KQ", n: "THINKWARE Corporation", c: "Korea" },
  { id: 20279, s: "084180.KQ", n: "Soosung Innovation Co..", c: "Korea" },
  { id: 20280, s: "086890.KQ", n: "ISU Abxis Co.", c: "Korea" },
  { id: 20281, s: "083930.KQ", n: "Avaco Co.", c: "Korea" },
  { id: 20282, s: "084110.KQ", n: "Huons Global Co.", c: "Korea" },
  { id: 20283, s: "083790.KQ", n: "CrystalGenomics Inc", c: "Korea" },
  { id: 20284, s: "083660.KQ", n: "Gentrogroup Co..", c: "Korea" },
  { id: 20285, s: "083550.KQ", n: "KM Corporation", c: "Korea" },
  { id: 20286, s: "082920.KQ", n: "VITZROCELL Co.Ltd", c: "Korea" },
  { id: 20287, s: "082850.KQ", n: "Wooree Bio Co.Ltd", c: "Korea" },
  { id: 20288, s: "082210.KQ", n: "Optrontec Inc", c: "Korea" },
  { id: 20289, s: "002467.SZ", n: "Net263", c: "CH" },
  { id: 20290, s: "605588.SS", n: "Keystone Technology", c: "CH" },
  { id: 20291, s: "002689.SZ", n: "Shenyang Brilliant Elevator Group", c: "CH" },
  { id: 20292, s: "002357.SZ", n: "Sichuan Fulin Transportation Group", c: "CH" },
  { id: 20293, s: "002435.SZ", n: "Changjiang Runfa Machinery", c: "CH" },
  { id: 20294, s: "002436.SZ", n: "Shenzhen Fastprint Circuit Tech", c: "CH" },
  { id: 20295, s: "002437.SZ", n: "Harbin Gloria Pharmaceuticals", c: "CH" },
  { id: 20296, s: "002438.SZ", n: "Jiangsu Shentong Valve", c: "CH" },
  { id: 20297, s: "688011.SS", n: "Harbin Xinguang Optic Electronics", c: "CH" },
  { id: 20298, s: "002440.SZ", n: "Zhejiang Runtu", c: "CH" },
  { id: 20299, s: "002441.SZ", n: "Zhongyeda Electric", c: "CH" },
  { id: 20300, s: "002434.SZ", n: "Zhejiang Wanliyang Transmission", c: "CH" },
  { id: 20301, s: "002442.SZ", n: "Longxing Chemical Stock", c: "CH" },
  { id: 20302, s: "688010.SS", n: "Fujian Forecam Optics", c: "CH" },
  { id: 20303, s: "688007.SS", n: "Appotronics Co", c: "CH" },
  { id: 20304, s: "688004.SS", n: "Beijing Bohui Science & Technology", c: "CH" },
  { id: 20305, s: "002448.SZ", n: "ZYNP Co", c: "CH" },
  { id: 20306, s: "002449.SZ", n: "Foshan Nationstar Optoelectronics", c: "CH" },
  { id: 20307, s: "002451.SZ", n: "Shanghai Morn Electric Equipment", c: "CH" },
  { id: 20308, s: "688068.SS", n: "Beijing Hotgen Biotech ", c: "CH" },
  { id: 20309, s: "002403.SZ", n: "Zhejiang Aishida Electric", c: "CH" },
  { id: 20310, s: "605055.SS", n: "Zhejiang Yingfeng Technology", c: "CH" },
  { id: 20311, s: "002368.SZ", n: "Taiji Computer Co", c: "CH" },
  { id: 20312, s: "002360.SZ", n: "Shanxi Tond Chemical", c: "CH" },
  { id: 20313, s: "002361.SZ", n: "Anhui Shenjian New Materials", c: "CH" },
  { id: 20314, s: "688166.SS", n: "BrightGene Bio Medical Technology C", c: "CH" },
  { id: 20315, s: "688165.SS", n: "EFORT Intelligent Equipment", c: "CH" },
  { id: 20316, s: "688138.SS", n: "Shenzhen Qingyi Photomask Ltd", c: "CH" },
  { id: 20317, s: "688127.SS", n: "Zhejiang Lante Optics", c: "CH" },
  { id: 20318, s: "002369.SZ", n: "Shenzhen Zowee Tech", c: "CH" },
  { id: 20319, s: "688077.SS", n: "Earth-Panda Advanced Magnetic Material", c: "CH" },
  { id: 20320, s: "002370.SZ", n: "Zhejiang Yatai Pharmaceutical", c: "CH" },
  { id: 20321, s: "688112.SS", n: "Siglent Technologies", c: "CH" },
  { id: 20322, s: "SBMSF", n: "Danakali", c: "US" },
  { id: 20323, s: "002374.SZ", n: "Shandong Lipeng", c: "CH" },
  { id: 20324, s: "002375.SZ", n: "Zhejiang Yasha Decoration", c: "CH" },
  { id: 20325, s: "688110.SS", n: "Dosilicon", c: "CH" },
  { id: 20326, s: "002452.SZ", n: "Hunan Changgao High Voltage Switchgear Group", c: "CH" },
  { id: 20327, s: "002443.SZ", n: "Zhejiang Kingland Pipeline and Technologies", c: "CH" },
  { id: 20328, s: "002433.SZ", n: "Guangdong Taiantang Pharmaceutical", c: "CH" },
  { id: 20329, s: "601921.SS", n: "Zhejiang Publishing & Media", c: "CH" },
  { id: 20330, s: "605050.SS", n: "Friend", c: "CH" },
  { id: 20331, s: "002557.SZ", n: "Chacha Food", c: "CH" },
  { id: 20332, s: "605033.SS", n: "Shaanxi Meibang Pharmaceutical Group", c: "CH" },
  { id: 20333, s: "002676.SZ", n: "Guangdong Sunwill Precising Plastic", c: "CH" },
  { id: 20334, s: "002677.SZ", n: "Zhejiang Meida Industrial", c: "CH" },
  { id: 20335, s: "002678.SZ", n: "Guangzhou Pearl River Piano Group", c: "CH" },
  { id: 20336, s: "002681.SZ", n: "Shenzhen Fenda Technology", c: "CH" },
  { id: 20337, s: "688016.SS", n: "MicroPort Endovascular MedTech", c: "CH" },
  { id: 20338, s: "002682.SZ", n: "Fujian Longzhou Transportation", c: "CH" },
  { id: 20339, s: "002683.SZ", n: "Guangdong HongDa Blasting", c: "CH" },
  { id: 20340, s: "002675.SZ", n: "Yantai Dongcheng Pharmaceutical", c: "CH" },
  { id: 20341, s: "002685.SZ", n: "Wuxi Huadong Heavy Machinery", c: "CH" },
  { id: 20342, s: "002687.SZ", n: "Zhejiang Giuseppe Garment", c: "CH" },
  { id: 20343, s: "002688.SZ", n: "Jinhe Biotechnology", c: "CH" },
  { id: 20344, s: "688075.SS", n: "Assure Tech Hangzhou", c: "CH" },
  { id: 20345, s: "002428.SZ", n: "Yunnan Lincang Xinyuan Germanium Industrial", c: "CH" },
  { id: 20346, s: "688015.SS", n: "Traffic Control Technology", c: "CH" },
  { id: 20347, s: "SCFR", n: "Security First Intl", c: "US" },
  { id: 20348, s: "002431.SZ", n: "Palm Landscape Architecture", c: "CH" },
  { id: 20349, s: "688066.SS", n: "Beijing Piesat Information Technology", c: "CH" },
  { id: 20350, s: "688058.SS", n: "Beijing Baolande Software Co", c: "CH" },
  { id: 20351, s: "688038.SS", n: "Wuhan Citms Technology", c: "CH" },
  { id: 20352, s: "002412.SZ", n: "Hunan Hansen Pharmaceutical", c: "CH" },
  { id: 20353, s: "002413.SZ", n: "Jiangsu Changfa Refrigeration", c: "CH" },
  { id: 20354, s: "002418.SZ", n: "Zhe Jiang Kangsheng", c: "CH" },
  { id: 20355, s: "688020.SS", n: "Guangzhou Fangbang Electronics", c: "CH" },
  { id: 20356, s: "002419.SZ", n: "Rainbow Department Store", c: "CH" },
  { id: 20357, s: "688028.SS", n: "Beijing Worldia Diamond Tools", c: "CH" },
  { id: 20358, s: "002421.SZ", n: "Shenzhen Das Intellitech", c: "CH" },
  { id: 20359, s: "002424.SZ", n: "Guizhou Bailing Group Pharmaceutical", c: "CH" },
  { id: 20360, s: "002425.SZ", n: "Kaiser CH Holding", c: "CH" },
  { id: 20361, s: "688022.SS", n: "Suzhou Harmontronics Auto Tech", c: "CH" },
  { id: 20362, s: "002358.SZ", n: "Henan Senyuan Electric", c: "CH" },
  { id: 20363, s: "002378.SZ", n: "Chongyi Zhangyuan Tungsten", c: "CH" },
  { id: 20364, s: "002470.SZ", n: "Kingenta Ecological Engineering Group", c: "CH" },
  { id: 20365, s: "688106.SS", n: "Suzhou Jinhong Gas", c: "CH" },
  { id: 20366, s: "605081.SS", n: "Shanghai Taihe Water Environmental Technology Development", c: "CH" },
  { id: 20367, s: "605066.SS", n: "Zhejiang Tengen Electrics", c: "CH" },
  { id: 20368, s: "002549.SZ", n: "Hunan Kaimeite Gases", c: "CH" },
  { id: 20369, s: "002550.SZ", n: "Changzhou Qianhong Biopharma", c: "CH" },
  { id: 20370, s: "002551.SZ", n: "Shenzhen Glory Medical", c: "CH" },
  { id: 20371, s: "605056.SS", n: "Xianheng International Science & Technology", c: "CH" },
  { id: 20372, s: "002553.SZ", n: "Jiangsu Nanfang Bearing", c: "CH" },
  { id: 20373, s: "002554.SZ", n: "CH Oil HBP Science & Technology", c: "CH" },
  { id: 20374, s: "002547.SZ", n: "SuZhou ChunXing Precision Mechanical", c: "CH" },
  { id: 20375, s: "002537.SZ", n: "HyUnion Holding", c: "CH" },
  { id: 20376, s: "002536.SZ", n: "Feilong Auto Components", c: "CH" },
  { id: 20377, s: "002535.SZ", n: "Linzhou Heavy Machinery Group", c: "CH" },
  { id: 20378, s: "605199.SS", n: "Hainan Huluwa Pharmaceutical Group", c: "CH" },
  { id: 20379, s: "605198.SS", n: "Yantai North Andre Juice", c: "CH" },
  { id: 20380, s: "605188.SS", n: "Jiangxi Guoguang Commercial Chains", c: "CH" },
  { id: 20381, s: "002538.SZ", n: "Anhui Sierte Fertilizer Industry", c: "CH" },
  { id: 20382, s: "002545.SZ", n: "Qingdao East Steel Tower Stock", c: "CH" },
  { id: 20383, s: "002544.SZ", n: "Cetc Potevio Science&Technology", c: "CH" },
  { id: 20384, s: "605580.SS", n: "Hengsheng Energy", c: "CH" },
  { id: 20385, s: "605577.SS", n: "Heilongjiang Publishing & Media", c: "CH" },
  { id: 20386, s: "605566.SS", n: "Hangzhou Flariant", c: "CH" },
  { id: 20387, s: "002474.SZ", n: "Fujian Rongji Software", c: "CH" },
  { id: 20388, s: "605555.SS", n: "Ningbo Dechang Electrical Machinery Made", c: "CH" },
  { id: 20389, s: "605507.SS", n: "Guobang Pharma", c: "CH" },
  { id: 20390, s: "SDEC", n: "Smart Decision Inc", c: "US" },
  { id: 20391, s: "002512.SZ", n: "Tatwah Smartech", c: "CH" },
  { id: 20392, s: "002543.SZ", n: "Guangdong Vanward New Electric", c: "CH" },
  { id: 20393, s: "002513.SZ", n: "Jiangsu Lanfeng Bio-chemical", c: "CH" },
  { id: 20394, s: "002514.SZ", n: "Jiangsu Boamax Technologies Group", c: "CH" },
  { id: 20395, s: "605108.SS", n: "Tongqinglou Dining", c: "CH" },
  { id: 20396, s: "002540.SZ", n: "Jiangsu Asia Pacific Light Alloy Technology", c: "CH" },
  { id: 20397, s: "605099.SS", n: "CoCreation Grass", c: "CH" },
  { id: 20398, s: "605088.SS", n: "GSP Automotive Group Wenzhou", c: "CH" },
  { id: 20399, s: "605168.SS", n: "Three's Company Media Group", c: "CH" },
  { id: 20400, s: "002519.SZ", n: "Jiangsu Yinhe Electronics", c: "CH" },
  { id: 20401, s: "002520.SZ", n: "ZheJiang RIFA Digital Precision Machinery", c: "CH" },
  { id: 20402, s: "002395.SZ", n: "Wuxi Double Elephant Micro Fibre Material", c: "CH" },
  { id: 20403, s: "688096.SS", n: "Jiangsu Jingyuan Environmental Protection", c: "CH" },
  { id: 20404, s: "688093.SS", n: "Suzhou Shihua New Material Technology", c: "CH" },
  { id: 20405, s: "002389.SZ", n: "Aerospace CH UAV", c: "CH" },
  { id: 20406, s: "PRXIQ", n: "Premier Exhibitions Inc", c: "US" },
  { id: 20407, s: "002390.SZ", n: "Guizhou Xinbang Pharmaceutical", c: "CH" },
  { id: 20408, s: "002393.SZ", n: "Tianjin Lisheng Pharmaceutical", c: "CH" },
  { id: 20409, s: "688085.SS", n: "Shanghai Sanyou Medical", c: "CH" },
  { id: 20410, s: "002385.SZ", n: "Beijing Dabeinong Technology Group", c: "CH" },
  { id: 20411, s: "002397.SZ", n: "Hunan Mendale Hometextile", c: "CH" },
  { id: 20412, s: "688083.SS", n: "ZWSOFT Guangzhou", c: "CH" },
  { id: 20413, s: "688080.SS", n: "Beijing InHand Networks Technology", c: "CH" },
  { id: 20414, s: "002401.SZ", n: "COSCO SHIPPING Technology", c: "CH" },
  { id: 20415, s: "002391.SZ", n: "Jiangsu Changqing Agrochemical", c: "CH" },
  { id: 20416, s: "002380.SZ", n: "Nanjing Sciyon Automation Group", c: "CH" },
  { id: 20417, s: "688098.SS", n: "Shanghai Shen Lian Biomedical Co", c: "CH" },
  { id: 20418, s: "688100.SS", n: "Willfar Information Technology", c: "CH" },
  { id: 20419, s: "002521.SZ", n: "Qifeng New Material", c: "CH" },
  { id: 20420, s: "605128.SS", n: "Shanghai Yanpu Metal Products", c: "CH" },
  { id: 20421, s: "002522.SZ", n: "Zhejiang Zhongcheng Packing Material", c: "CH" },
  { id: 20422, s: "002523.SZ", n: "Zhuzhou Tianqiao Crane", c: "CH" },
  { id: 20423, s: "002526.SZ", n: "Shandong Mining Machinery Group", c: "CH" },
  { id: 20424, s: "002527.SZ", n: "Shanghai STEP Electric Co", c: "CH" },
  { id: 20425, s: "605162.SS", n: "Zhejiang Xinzhonggang Thermal Power", c: "CH" },
  { id: 20426, s: "605138.SS", n: "Zhejiang Sunrise Garment Group", c: "CH" },
  { id: 20427, s: "605123.SS", n: "Wuxi Paike New Materials Technology", c: "CH" },
  { id: 20428, s: "688101.SS", n: "Suntar Environmental Technology", c: "CH" },
  { id: 20429, s: "002532.SZ", n: "Tianshan Aluminum Group", c: "CH" },
  { id: 20430, s: "002534.SZ", n: "Xizi Clean Energy Equipment Manufacturing", c: "CH" },
  { id: 20431, s: "002456.SZ", n: "Shenzhen O-Film Tech", c: "CH" },
  { id: 20432, s: "688003.SS", n: "Suzhou TZTEK Technology", c: "CH" },
  { id: 20433, s: "002454.SZ", n: "Songz Automobile Air Conditioning", c: "CH" },
  { id: 20434, s: "002453.SZ", n: "Great CHsoft Technology", c: "CH" },
  { id: 20435, s: "002035.SZ", n: "Vatti Co", c: "CH" },
  { id: 20436, s: "300289.SZ", n: "Beijing Leadman Biochemistry", c: "CH" },
  { id: 20437, s: "KYSA.AT", n: "Flour Mills C. Sarantopoulos S.A.", c: "Greece" },
  { id: 20438, s: "300397.SZ", n: "XiAn Tian He Defense Technology", c: "CH" },
  { id: 20439, s: "120110.KS", n: "Kolon Industries Inc", c: "Korea" },
  { id: 20440, s: "300467.SZ", n: "Sichuan Xunyou Network Technology", c: "CH" },
  { id: 20441, s: "120030.KS", n: "Chosun Welding", c: "Korea" },
  { id: 20442, s: "300469.SZ", n: "Trust Alliance Information Development Inc Shanghai", c: "CH" },
  { id: 20443, s: "300470.SZ", n: "Sinoseal Holding", c: "CH" },
  { id: 20444, s: "119650.KS", n: "Kc Cottrell", c: "Korea" },
  { id: 20445, s: "300472.SZ", n: "Beijing New Universal Science and Technology", c: "CH" },
  { id: 20446, s: "300465.SZ", n: "Global Infotech", c: "CH" },
  { id: 20447, s: "118000.KS", n: "Chorokbaem Healthcare", c: "Korea" },
  { id: 20448, s: "108675.KS", n: "LX Hausys Preferred", c: "Korea" },
  { id: 20449, s: "300478.SZ", n: "Hangzhou Gaoxin Rubber & Plastic Materials", c: "CH" },
  { id: 20450, s: "300480.SZ", n: "Zhengzhou GL Tech", c: "CH" },
  { id: 20451, s: "300481.SZ", n: "Puyang Huicheng Electronic Material", c: "CH" },
  { id: 20452, s: "105840.KS", n: "Woojin", c: "Korea" },
  { id: 20453, s: "105630.KS", n: "Hansae", c: "Korea" },
  { id: 20454, s: "300441.SZ", n: "Ningbo Baosi Energy Equipment", c: "CH" },
  { id: 20455, s: "300439.SZ", n: "Ningbo MedicalSystem Biotechnology", c: "CH" },
  { id: 20456, s: "192400.KS", n: "Cuckoo Electronics", c: "Korea" },
  { id: 20457, s: "300406.SZ", n: "Beijing Strong Biotechnologies Inc", c: "CH" },
  { id: 20458, s: "183190.KS", n: "Asiacement", c: "Korea" },
  { id: 20459, s: "300400.SZ", n: "Shenzhen JT Automation Equipment", c: "CH" },
  { id: 20460, s: "300401.SZ", n: "Zhejiang Garden Bio-chemical High-tech", c: "CH" },
  { id: 20461, s: "300402.SZ", n: "Nanjing Baose", c: "CH" },
  { id: 20462, s: "300403.SZ", n: "Jiangmen iDear-Hanyu Electrical Joint-Stock", c: "CH" },
  { id: 20463, s: "300404.SZ", n: "Guangzhou Boji Medical and Biotechnological", c: "CH" },
  { id: 20464, s: "300407.SZ", n: "Tianjin Keyvia Electric", c: "CH" },
  { id: 20465, s: "300438.SZ", n: "Guangzhou Great Power Energy&Technology", c: "CH" },
  { id: 20466, s: "300408.SZ", n: "Chaozhou Three-circle Group", c: "CH" },
  { id: 20467, s: "300409.SZ", n: "Guangdong Dowstone Technology", c: "CH" },
  { id: 20468, s: "300410.SZ", n: "Guangdong Zhengye Technology", c: "CH" },
  { id: 20469, s: "300411.SZ", n: "Zhejiang Jindun Fans", c: "CH" },
  { id: 20470, s: "300412.SZ", n: "Zhejiang Canaan Technology", c: "CH" },
  { id: 20471, s: "178920.KS", n: "Skckolonpi Inc", c: "Korea" },
  { id: 20472, s: "300485.SZ", n: "Beijing Science Sun Pharmaceutical", c: "CH" },
  { id: 20473, s: "114090.KS", n: "Grand Korea Leisure Co.", c: "Korea" },
  { id: 20474, s: "120115.KS", n: "Kolon Industries Inc", c: "Korea" },
  { id: 20475, s: "300316.SZ", n: "Zhejiang Jingsheng Mech Electric", c: "CH" },
  { id: 20476, s: "300440.SZ", n: "Chengdu Yunda Technology", c: "CH" },
  { id: 20477, s: "300085.SZ", n: "Shenzhen InfoGem Technologies", c: "CH" },
  { id: 20478, s: "300393.SZ", n: "Jolywood Suzhou Sunwatt", c: "CH" },
  { id: 20479, s: "300390.SZ", n: "Suzhou TA&A Ultra Clean Technology", c: "CH" },
  { id: 20480, s: "300313.SZ", n: "Xinjiang Tianshan Animal Husbandry Bio-engineering", c: "CH" },
  { id: 20481, s: "TKYMY", n: "Tokuyama Corp ADR", c: "US" },
  { id: 20482, s: "300317.SZ", n: "Shenzhen Jiawei Photovoltaic Lighting", c: "CH" },
  { id: 20483, s: "300460.SZ", n: "Guangdong Failong Crystal Technology", c: "CH" },
  { id: 20484, s: "300318.SZ", n: "Beijing Bohui Innovation Technology", c: "CH" },
  { id: 20485, s: "317400.KS", n: "Xi S And D Inc", c: "Korea" },
  { id: 20486, s: "300320.SZ", n: "Jiangyin Haida Rubber and Plastic Co Class A", c: "CH" },
  { id: 20487, s: "330590.KS", n: "Lotte Reit", c: "Korea" },
  { id: 20488, s: "300321.SZ", n: "Shandong Tongda Island New Materials", c: "CH" },
  { id: 20489, s: "300326.SZ", n: "Shanghai Kinetic Medical", c: "CH" },
  { id: 20490, s: "122900.KS", n: "Imarketkorea", c: "Korea" },
  { id: 20491, s: "123700.KS", n: "SJM", c: "Korea" },
  { id: 20492, s: "300463.SZ", n: "Maccura Biotechnology", c: "CH" },
  { id: 20493, s: "300447.SZ", n: "Nanjing Quanxin Cable Technology", c: "CH" },
  { id: 20494, s: "300462.SZ", n: "Shanghai Huaming Intelligent Terminal Equipment", c: "CH" },
  { id: 20495, s: "136490.KS", n: "Sunjin", c: "Korea" },
  { id: 20496, s: "300443.SZ", n: "Shandong Laiwu Jinlei Wind Power Technology", c: "CH" },
  { id: 20497, s: "300444.SZ", n: "Beijing Sojo Electric", c: "CH" },
  { id: 20498, s: "134790.KS", n: "Sidiz Inc", c: "Korea" },
  { id: 20499, s: "134380.KS", n: "Miwon Chemical", c: "Korea" },
  { id: 20500, s: "300448.SZ", n: "Guangzhou Haoyun Security Technologies", c: "CH" },
  { id: 20501, s: "300457.SZ", n: "Shenzhen Yinghe Technology", c: "CH" },
  { id: 20502, s: "300449.SZ", n: "Beijing Hanbang Technology Co", c: "CH" },
  { id: 20503, s: "133820.KS", n: "Finebesteel", c: "Korea" },
  { id: 20504, s: "130660.KS", n: "Korea Electronic Power Industrial Development", c: "Korea" },
  { id: 20505, s: "300452.SZ", n: "Anhui Sunhere Pharmaceutical Excipients", c: "CH" },
  { id: 20506, s: "300453.SZ", n: "Jiangxi Sanxin Medtec", c: "CH" },
  { id: 20507, s: "300454.SZ", n: "Sangfor Technologies Inc Class A", c: "CH" },
  { id: 20508, s: "192080.KS", n: "DoubleU Games", c: "Korea" },
  { id: 20509, s: "300396.SZ", n: "Dirui Industrial", c: "CH" },
  { id: 20510, s: "300720.KS", n: "Hanilcmt ", c: "Korea" },
  { id: 20511, s: "300414.SZ", n: "Sichuan Zhongguang Lightning Protection Technologies", c: "CH" },
  { id: 20512, s: "CAV.WA", n: "Cavatina Holding S.A.", c: "Poland" },
  { id: 20513, s: "BST.WA", n: "Best SA", c: "Poland" },
  { id: 20514, s: "300175.SZ", n: "Lontrue", c: "CH" },
  { id: 20515, s: "BRS.WA", n: "Boryszew SA", c: "Poland" },
  { id: 20516, s: "300177.SZ", n: "Hi target Navigation Tech", c: "CH" },
  { id: 20517, s: "300179.SZ", n: "SF Diamond", c: "CH" },
  { id: 20518, s: "BOS.WA", n: "Bank Ochrony Środowiska SA", c: "Poland" },
  { id: 20519, s: "CDL.WA", n: "CDRL SA", c: "Poland" },
  { id: 20520, s: "300157.SZ", n: "LandOcean Energy Services", c: "CH" },
  { id: 20521, s: "300155.SZ", n: "Guangdong Anjubao Digital Tech", c: "CH" },
  { id: 20522, s: "300154.SZ", n: "Shenzhen Riland Industry", c: "CH" },
  { id: 20523, s: "300135.SZ", n: "Jiangsu Baoli Asphalt", c: "CH" },
  { id: 20524, s: "ELT.WA", n: "Elektrotim SA", c: "Poland" },
  { id: 20525, s: "300138.SZ", n: "Chenguang Biotech Group", c: "CH" },
  { id: 20526, s: "EFK.WA", n: "Korporacja Gospodarcza efekt S.A", c: "Poland" },
  { id: 20527, s: "CFI.WA", n: "CFI Holding SA", c: "Poland" },
  { id: 20528, s: "300158.SZ", n: "Shanxi Zhendong Pharmaceutical", c: "CH" },
  { id: 20529, s: "300169.SZ", n: "Changzhou Tiansheng New Materials", c: "CH" },
  { id: 20530, s: "300283.SZ", n: "Wenzhou Hongfeng Electrical Alloy", c: "CH" },
  { id: 20531, s: "HEL.WA", n: "Helio SA", c: "Poland" },
  { id: 20532, s: "300101.SZ", n: "Chengdu CORPRO Technology", c: "CH" },
  { id: 20533, s: "300102.SZ", n: "Xiamen Changelight", c: "CH" },
  { id: 20534, s: "300103.SZ", n: "XiAn Dagang Road Machinery", c: "CH" },
  { id: 20535, s: "300105.SZ", n: "Yantai Longyuan Power Tech", c: "CH" },
  { id: 20536, s: "ELZ.WA", n: "Zaklady Urzadzen Komputerowych ELZAB S.A", c: "Poland" },
  { id: 20537, s: "300132.SZ", n: "Fujian Green Pine", c: "CH" },
  { id: 20538, s: "300166.SZ", n: "Business intelligence of Oriental Nations Co", c: "CH" },
  { id: 20539, s: "300134.SZ", n: "Shenzhen Tatfook Tech", c: "CH" },
  { id: 20540, s: "300159.SZ", n: "Xinjiang Machinery Research Inst", c: "CH" },
  { id: 20541, s: "CPD.WA", n: "CPD SA", c: "Poland" },
  { id: 20542, s: "300162.SZ", n: "Ledman Optoelectronic", c: "CH" },
  { id: 20543, s: "CPA.WA", n: "Capital Partners S.A.", c: "Poland" },
  { id: 20544, s: "300165.SZ", n: "Jiangsu Skyray Instrument", c: "CH" },
  { id: 20545, s: "300140.SZ", n: "Cecep Environmental Protection Equipment", c: "CH" },
  { id: 20546, s: "DPL.WA", n: "Drozapol-Profil SA", c: "Poland" },
  { id: 20547, s: "300143.SZ", n: "INKON Life Technology", c: "CH" },
  { id: 20548, s: "TNEN", n: "True North Energy Co", c: "US" },
  { id: 20549, s: "300424.SZ", n: "Guangzhou Hangxin Aviation Technology", c: "CH" },
  { id: 20550, s: "145995.KS", n: "Samyang Corp Preference Shares", c: "Korea" },
  { id: 20551, s: "155660.KS", n: "DSR Co", c: "Korea" },
  { id: 20552, s: "300426.SZ", n: "Zhejiang Talent Television&Film", c: "CH" },
  { id: 20553, s: "300429.SZ", n: "Changzhou Tronly New Electronic Materials", c: "CH" },
  { id: 20554, s: "140910.KS", n: "Kwang Hee Self-Administered Real Estate", c: "Korea" },
  { id: 20555, s: "300434.SZ", n: "Sichuan Goldstone Orient New Material Equipment", c: "CH" },
  { id: 20556, s: "300422.SZ", n: "Guangxi Bossco Environmental Protection Technology", c: "CH" },
  { id: 20557, s: "300435.SZ", n: "Hangzhou Zhongtai Cryogenic Technology Co", c: "CH" },
  { id: 20558, s: "300436.SZ", n: "Fujian Cosunter Pharmaceutical", c: "CH" },
  { id: 20559, s: "137310.KS", n: "SD Biosensor Inc", c: "Korea" },
  { id: 20560, s: "300428.SZ", n: "Lizhong Sitong Light Alloys Group", c: "CH" },
  { id: 20561, s: "300417.SZ", n: "Nanhua Instruments", c: "CH" },
  { id: 20562, s: "300415.SZ", n: "Guangdong Yizumi Precision Machinery", c: "CH" },
  { id: 20563, s: "300423.SZ", n: "Sunfly Intelligent Technology", c: "CH" },
  { id: 20564, s: "300421.SZ", n: "Jiangsu LiXing General Steel Ball", c: "CH" },
  { id: 20565, s: "DGA.WA", n: "DGA Spólka Akcyjna", c: "Poland" },
  { id: 20566, s: "300151.SZ", n: "Shenzhen Changhong Tech", c: "CH" },
  { id: 20567, s: "DEK.WA", n: "Dekpol SA", c: "Poland" },
  { id: 20568, s: "300146.SZ", n: "By health", c: "CH" },
  { id: 20569, s: "300147.SZ", n: "Xiangxue Pharmaceutical", c: "CH" },
  { id: 20570, s: "300148.SZ", n: "Tangel Publishing", c: "CH" },
  { id: 20571, s: "CZT.WA", n: "Czerwona Torebka", c: "Poland" },
  { id: 20572, s: "300150.SZ", n: "Beijing Century Real Tech", c: "CH" },
  { id: 20573, s: "300152.SZ", n: "Xiongan Kerong Environment Technology", c: "CH" },
  { id: 20574, s: "300420.SZ", n: "Jiangsu Wuyang Parking Industry Group", c: "CH" },
  { id: 20575, s: "CTX.WA", n: "Captor Therapeutics S.A.", c: "Poland" },
  { id: 20576, s: "300133.SZ", n: "Zhejiang Huace Film TV", c: "CH" },
  { id: 20577, s: "300284.SZ", n: "Jsti Group", c: "CH" },
  { id: 20578, s: "TKPHF", n: "Takeda Pharmaceutical Co..", c: "US" },
  { id: 20579, s: "300286.SZ", n: "Acrel", c: "CH" },
  { id: 20580, s: "300419.SZ", n: "Beijing InterAct Technology", c: "CH" },
  { id: 20581, s: "300327.SZ", n: "Sino Wealth Electronic", c: "CH" },
  { id: 20582, s: "300329.SZ", n: "Hailun Piano", c: "CH" },
  { id: 20583, s: "300098.SZ", n: "Gosuncn Technology Group Co Class A", c: "CH" },
  { id: 20584, s: "002890.SZ", n: "Shandong Hongyu Agricultural Machinery", c: "CH" },
  { id: 20585, s: "002865.SZ", n: "Hainan Drinda Automotive Trim", c: "CH" },
  { id: 20586, s: "002867.SZ", n: "Chow Tai Seng Jewellery", c: "CH" },
  { id: 20587, s: "SPXCY", n: "Singapore Exchange ADR", c: "US" },
  { id: 20588, s: "002870.SZ", n: "Guangdong Senssun Weighing Apparatus Group", c: "CH" },
  { id: 20589, s: "600462.SS", n: "Hubei Geoway Investment", c: "CH" },
  { id: 20590, s: "002872.SZ", n: "Tiansheng Pharmaceutical Group", c: "CH" },
  { id: 20591, s: "SPXSY", n: "Spirax-Sarco Engineering PLC", c: "US" },
  { id: 20592, s: "002875.SZ", n: "Annil", c: "CH" },
  { id: 20593, s: "002876.SZ", n: "Shenzhen Sunnypol Optoelectronics", c: "CH" },
  { id: 20594, s: "002877.SZ", n: "Wuxi Smart Auto-control Engineering", c: "CH" },
  { id: 20595, s: "002878.SZ", n: "Beijing Yuanlong Yato Culture Dissemination", c: "CH" },
  { id: 20596, s: "600426.SS", n: "Shandong Hualu Hengsheng Chemical", c: "CH" },
  { id: 20597, s: "002880.SZ", n: "Shenzhen Weiguang Biological Products", c: "CH" },
  { id: 20598, s: "002908.SZ", n: "Guangdong Tecsun Science & Technology", c: "CH" },
  { id: 20599, s: "002857.SZ", n: "SMS Electric Co Zhengzhou", c: "CH" },
  { id: 20600, s: "002862.SZ", n: "Shifeng Cultural Development", c: "CH" },
  { id: 20601, s: "600545.SS", n: "Saurer Intelligent Technology Co Class A", c: "CH" },
  { id: 20602, s: "002859.SZ", n: "Zhejiang Jiemei Electronic and Technology", c: "CH" },
  { id: 20603, s: "002901.SZ", n: "Double Medical Technology Inc", c: "CH" },
  { id: 20604, s: "600312.SS", n: "Henan Pinggao Electric", c: "CH" },
  { id: 20605, s: "002884.SZ", n: "Guangdong LingXiao Pump Industry", c: "CH" },
  { id: 20606, s: "002893.SZ", n: "Beijing HuaYuanYiTong Thermal Technology", c: "CH" },
  { id: 20607, s: "002897.SZ", n: "Wenzhou Yihua Connector", c: "CH" },
  { id: 20608, s: "002898.SZ", n: "Zhuhai Sailong Pharmaceutical", c: "CH" },
  { id: 20609, s: "002900.SZ", n: "Harbin Medisan Pharmaceutical", c: "CH" },
  { id: 20610, s: "002902.SZ", n: "Dongguan Mentech Optical & Magnetic", c: "CH" },
  { id: 20611, s: "002858.SZ", n: "Shanghai Lisheng Racing", c: "CH" },
  { id: 20612, s: "002905.SZ", n: "Guangzhou Jinyi Media Co", c: "CH" },
  { id: 20613, s: "600228.SS", n: "Fanli Digital Technology", c: "CH" },
  { id: 20614, s: "002896.SZ", n: "Ningbo ZhongDa Leader Intelligent Transmission", c: "CH" },
  { id: 20615, s: "SRCRF", n: "Scorpio Gold Co", c: "US" },
  { id: 20616, s: "002882.SZ", n: "Jinlongyu Group Co Class A", c: "CH" },
  { id: 20617, s: "002881.SZ", n: "Shenzhen MeiG Smart Technology", c: "CH" },
  { id: 20618, s: "002909.SZ", n: "Guangzhou Jointas Chemical Joint Stock", c: "CH" },
  { id: 20619, s: "002911.SZ", n: "Foran Energy Group", c: "CH" },
  { id: 20620, s: "002946.SZ", n: "New Hope Dairy", c: "CH" },
  { id: 20621, s: "002920.SZ", n: "Huizhou Desay SV Automotive Co Class A", c: "CH" },
  { id: 20622, s: "002912.SZ", n: "Shenzhen Sinovatio Technology Co Class A", c: "CH" },
  { id: 20623, s: "600170.SS", n: "Shanghai Construction Group", c: "CH" },
  { id: 20624, s: "600169.SS", n: "Taiyuan Heavy Industry", c: "CH" },
  { id: 20625, s: "600155.SS", n: "Polaris Bay Group", c: "CH" },
  { id: 20626, s: "600120.SS", n: "Zhejiang Orient Holdings", c: "CH" },
  { id: 20627, s: "002919.SZ", n: "Mingchen Health Co Class A", c: "CH" },
  { id: 20628, s: "600061.SS", n: "SDIC Essence Holdings", c: "CH" },
  { id: 20629, s: "002942.SZ", n: "Zhejiang XinNong Chemical Co Class A", c: "CH" },
  { id: 20630, s: "002923.SZ", n: "Zhuhai Rundu Pharmaceutical Co Class A", c: "CH" },
  { id: 20631, s: "002927.SZ", n: "Guizhou Taiyong Changzheng Technology Co Class A", c: "CH" },
  { id: 20632, s: "002928.SZ", n: "CH Express Airlines Co Class A", c: "CH" },
  { id: 20633, s: "STSN", n: "Stemsation International Inc", c: "US" },
  { id: 20634, s: "002931.SZ", n: "Zhejiang Fenglong Electric Co Class A", c: "CH" },
  { id: 20635, s: "600027.SS", n: "Huadian Power International Corp Class A", c: "CH" },
  { id: 20636, s: "002937.SZ", n: "Ningbo Sunrise Elc Technology Co Class A", c: "CH" },
  { id: 20637, s: "002943.SZ", n: "Hunan Yujing Machinery Co Class A", c: "CH" },
  { id: 20638, s: "002947.SZ", n: "Suzhou Hengmingda Electronic Technology", c: "CH" },
  { id: 20639, s: "002953.SZ", n: "GuangDong Rifeng Electric Cable", c: "CH" },
  { id: 20640, s: "002948.SZ", n: "Bank of Qingdao", c: "CH" },
  { id: 20641, s: "002949.SZ", n: "Shenzhen Capol International & Associates", c: "CH" },
  { id: 20642, s: "002950.SZ", n: "Allmed Medical Products Co Class A", c: "CH" },
  { id: 20643, s: "ZUK.WA", n: "Zaklady Urzadzen Kotlowych Staporkow SA", c: "Poland" },
  { id: 20644, s: "002952.SZ", n: "Yes Optoelectronics Co Class A", c: "CH" },
  { id: 20645, s: "SURRY", n: "Sun Art Retail Group ADR", c: "US" },
  { id: 20646, s: "ZUE.WA", n: "ZUE SA", c: "Poland" },
  { id: 20647, s: "PNPFF", n: "Pinetree Capital", c: "US" },
  { id: 20648, s: "WWL.WA", n: "WAWEL SA", c: "Poland" },
  { id: 20649, s: "002958.SZ", n: "Qingdao Rural Commercial Bank Corp Class A", c: "CH" },
  { id: 20650, s: "002959.SZ", n: "Bear Electric Appliance", c: "CH" },
  { id: 20651, s: "002960.SZ", n: "Jade Bird Fire Alarm", c: "CH" },
  { id: 20652, s: "002962.SZ", n: "W olf Photoelectric Technology", c: "CH" },
  { id: 20653, s: "002963.SZ", n: "Hes Technology Group", c: "CH" },
  { id: 20654, s: "600315.SS", n: "Shanghai Jahwa United", c: "CH" },
  { id: 20655, s: "600320.SS", n: "Shanghai Zhenhua Heavy Industries Co A", c: "CH" },
  { id: 20656, s: "298020.KS", n: "Hyosung TNC Co", c: "Korea" },
  { id: 20657, s: "002887.SZ", n: "Tianjin LVYIN Landscape and Ecology Construction", c: "CH" },
  { id: 20658, s: "300302.SZ", n: "Toyou Feiji Electronics", c: "CH" },
  { id: 20659, s: "300303.SZ", n: "Shenzhen Jufei Optoelectronics", c: "CH" },
  { id: 20660, s: "336370.KS", n: "Solus Advanced Materials", c: "Korea" },
  { id: 20661, s: "300305.SZ", n: "Jiangsu Yuxing Film Tech", c: "CH" },
  { id: 20662, s: "293940.KS", n: "Shinhan Alpha REIT", c: "Korea" },
  { id: 20663, s: "TMXN", n: "Trimax Co", c: "US" },
  { id: 20664, s: "300334.SZ", n: "Tianjin Motimo Membrane Tech", c: "CH" },
  { id: 20665, s: "284740.KS", n: "Cuckoo Homesys", c: "Korea" },
  { id: 20666, s: "249420.KS", n: "Il Dong Pharmaceutical", c: "Korea" },
  { id: 20667, s: "244920.KS", n: "A Plus Asset Advisor", c: "Korea" },
  { id: 20668, s: "227840.KS", n: "Hyundai C&F Inc", c: "Korea" },
  { id: 20669, s: "300375.SZ", n: "Tianjin Pengling Rubber Hose", c: "CH" },
  { id: 20670, s: "300376.SZ", n: "East Group", c: "CH" },
  { id: 20671, s: "300377.SZ", n: "Shenzhen Ysstech Info-Tech", c: "CH" },
  { id: 20672, s: "300378.SZ", n: "Digiwin Software", c: "CH" },
  { id: 20673, s: "300301.SZ", n: "Shenzhen Changfang Light Emitting", c: "CH" },
  { id: 20674, s: "33637K.KS", n: "Doosan Solus Co Pref 1P", c: "Korea" },
  { id: 20675, s: "33637L.KS", n: "Doosan Solus Co Pref 2P", c: "Korea" },
  { id: 20676, s: "300288.SZ", n: "Longmaster Information Tech", c: "CH" },
  { id: 20677, s: "294870.KS", n: "Hyundai Development", c: "Korea" },
  { id: 20678, s: "300322.SZ", n: "Huizhou Speed Wireless Technology", c: "CH" },
  { id: 20679, s: "300310.SZ", n: "Guangdong Eastone Century Technology", c: "CH" },
  { id: 20680, s: "300307.SZ", n: "Ningbo Cixing", c: "CH" },
  { id: 20681, s: "300306.SZ", n: "Hangzhou Everfine Photo E Info", c: "CH" },
  { id: 20682, s: "300287.SZ", n: "Beijing Philisense Tech", c: "CH" },
  { id: 20683, s: "053300.KQ", n: "Korea Information Certificate Authority Inc", c: "Korea" },
  { id: 20684, s: "300298.SZ", n: "Sinocare Inc", c: "CH" },
  { id: 20685, s: "300290.SZ", n: "Bringspring Science and Tech", c: "CH" },
  { id: 20686, s: "350520.KS", n: "Igis Residence Reit ", c: "Korea" },
  { id: 20687, s: "300292.SZ", n: "Wutong Holding Group", c: "CH" },
  { id: 20688, s: "344820.KS", n: "Kcc Glass Co", c: "Korea" },
  { id: 20689, s: "300295.SZ", n: "Everyday Network", c: "CH" },
  { id: 20690, s: "338100.KS", n: "Nh Prime Reit ", c: "Korea" },
  { id: 20691, s: "300366.SZ", n: "Sichuan Troy Information Technology", c: "CH" },
  { id: 20692, s: "300379.SZ", n: "Beijing Tongtech", c: "CH" },
  { id: 20693, s: "300381.SZ", n: "Guangdong VTR Bio-Tech", c: "CH" },
  { id: 20694, s: "26490K.KS", n: "Crown Confectionery Co Pref", c: "Korea" },
  { id: 20695, s: "271560.KS", n: "Orion Co", c: "Korea" },
  { id: 20696, s: "300352.SZ", n: "Beijing VRV Software Co", c: "CH" },
  { id: 20697, s: "300353.SZ", n: "Kyland Technology", c: "CH" },
  { id: 20698, s: "267850.KS", n: "Asiana IDT Inc", c: "Korea" },
  { id: 20699, s: "300355.SZ", n: "M Grass Ecology and Environment Group", c: "CH" },
  { id: 20700, s: "300358.SZ", n: "Truking Technology", c: "CH" },
  { id: 20701, s: "300360.SZ", n: "Hangzhou Sunrise Technology", c: "CH" },
  { id: 20702, s: "300348.SZ", n: "Shenzhen Sunline Tech", c: "CH" },
  { id: 20703, s: "300338.SZ", n: "Changsha Kaiyuan Instruments", c: "CH" },
  { id: 20704, s: "300084.SZ", n: "Lanzhou Haimo Technologies", c: "CH" },
  { id: 20705, s: "300082.SZ", n: "Liaoning Oxiranchem Inc", c: "CH" },
  { id: 20706, s: "300081.SZ", n: "Hengxin Mobile Business", c: "CH" },
  { id: 20707, s: "002885.SZ", n: "Shenzhen JingQuanHua Electronics", c: "CH" },
  { id: 20708, s: "002886.SZ", n: "Shenzhen WOTE Advanced Materials", c: "CH" },
  { id: 20709, s: "300349.SZ", n: "Goldcard High-Tech", c: "CH" },
  { id: 20710, s: "300346.SZ", n: "Jiangsu Nata Opto Electr Material", c: "CH" },
  { id: 20711, s: "300382.SZ", n: "Suzhou SLAC Precision Equipment", c: "CH" },
  { id: 20712, s: "300365.SZ", n: "Beijing Forever Technology", c: "CH" },
  { id: 20713, s: "214330.KS", n: "Kumho HT Inc", c: "Korea" },
  { id: 20714, s: "300385.SZ", n: "Wuxi Xuelang Environmental Technology", c: "CH" },
  { id: 20715, s: "300386.SZ", n: "Feitian Technologies", c: "CH" },
  { id: 20716, s: "300388.SZ", n: "CECEP Guozhen Environmental Protection Technology", c: "CH" },
  { id: 20717, s: "210540.KS", n: "DY Power Co", c: "Korea" },
  { id: 20718, s: "214420.KS", n: "Tonymoly", c: "Korea" },
  { id: 20719, s: "300364.SZ", n: "COL Digital Publishing Group", c: "CH" },
  { id: 20720, s: "272550.KS", n: "Samyang Packaging", c: "Korea" },
  { id: 20721, s: "300363.SZ", n: "Porton Fine Chemicals", c: "CH" },
  { id: 20722, s: "300340.SZ", n: "Jiangmen Kanhoo Industry", c: "CH" },
  { id: 20723, s: "300341.SZ", n: "Motic Xiamen Electric Group", c: "CH" },
  { id: 20724, s: "282690.KS", n: "Dong Ah Tire & Rubber", c: "Korea" },
  { id: 20725, s: "300343.SZ", n: "Lecron Energy Saving Materials", c: "CH" },
  { id: 20726, s: "300344.SZ", n: "Cubic Digital Technology", c: "CH" },
  { id: 20727, s: "300099.SZ", n: "Uroica Mining Safety Eng", c: "CH" },
  { id: 20728, s: "300097.SZ", n: "Dalian Zhiyun Automation", c: "CH" },
  { id: 20729, s: "002011.SZ", n: "Zhejiang DunAn Artificial Environment", c: "CH" },
  { id: 20730, s: "002348.SZ", n: "Goldlok Toys Holdings Guangdong", c: "CH" },
  { id: 20731, s: "002325.SZ", n: "Shenzhen Hongtao Decoration", c: "CH" },
  { id: 20732, s: "688285.SS", n: "CH Railway High speed Electrification Equipment", c: "CH" },
  { id: 20733, s: "002308.SZ", n: "Vtron Technologies", c: "CH" },
  { id: 20734, s: "002165.SZ", n: "Nanjing Hongbaoli Co", c: "CH" },
  { id: 20735, s: "002263.SZ", n: "Zhejiang Great Southeast", c: "CH" },
  { id: 20736, s: "002261.SZ", n: "Talkweb Information System", c: "CH" },
  { id: 20737, s: "002193.SZ", n: "Shandong Jining Ruyi Woolen Textile", c: "CH" },
  { id: 20738, s: "002194.SZ", n: "Wuhan Fingu Electronic Technology", c: "CH" },
  { id: 20739, s: "688680.SS", n: "Shanghai HIUV New Materials", c: "CH" },
  { id: 20740, s: "002196.SZ", n: "Zhejiang Founder Motor", c: "CH" },
  { id: 20741, s: "688670.SS", n: "Jiangsu GDK Biotechnology", c: "CH" },
  { id: 20742, s: "688669.SS", n: "Polyrocks Chemical", c: "CH" },
  { id: 20743, s: "002199.SZ", n: "Zhejiang East Crystal Electronic", c: "CH" },
  { id: 20744, s: "688687.SS", n: "Beijing Kawin Technology Share-holding", c: "CH" },
  { id: 20745, s: "002200.SZ", n: "Yunnan Yuntou Ecology and Environment Technology", c: "CH" },
  { id: 20746, s: "688286.SS", n: "Memsensing Microsystems Suzhou CH", c: "CH" },
  { id: 20747, s: "002323.SZ", n: "Shandong Yabo Technology", c: "CH" },
  { id: 20748, s: "002322.SZ", n: "Ningbo Ligong Online Monitoring Technology", c: "CH" },
  { id: 20749, s: "002312.SZ", n: "Sichuan Development Lomon", c: "CH" },
  { id: 20750, s: "688181.SS", n: "Beijing Bayi Space LCD Technology", c: "CH" },
  { id: 20751, s: "688196.SS", n: "Longyan Zhuoyue New Energy", c: "CH" },
  { id: 20752, s: "002330.SZ", n: "Shan Dong Delisi Food", c: "CH" },
  { id: 20753, s: "002329.SZ", n: "Royal Group", c: "CH" },
  { id: 20754, s: "688278.SS", n: "Xiamen Amoytop Biotech", c: "CH" },
  { id: 20755, s: "688305.SS", n: "KEDE Numerical Control", c: "CH" },
  { id: 20756, s: "688300.SS", n: "Novoray Corporation", c: "CH" },
  { id: 20757, s: "688288.SS", n: "Hangzhou Hopechart Iot Technology", c: "CH" },
  { id: 20758, s: "688299.SS", n: "Ningbo Solartron Technology", c: "CH" },
  { id: 20759, s: "002316.SZ", n: "Shenzhen Keybridge Communications", c: "CH" },
  { id: 20760, s: "002317.SZ", n: "Guangdong Zhongsheng Pharmaceutical", c: "CH" },
  { id: 20761, s: "002318.SZ", n: "Zhejiang JIULI Hi-tech Metals", c: "CH" },
  { id: 20762, s: "002319.SZ", n: "Letong Chemical", c: "CH" },
  { id: 20763, s: "688296.SS", n: "Zhejiang Heda Technology", c: "CH" },
  { id: 20764, s: "688621.SS", n: "Beijing Sun Novo Pharmaceutical Research", c: "CH" },
  { id: 20765, s: "002203.SZ", n: "Zhejiang Hailiang", c: "CH" },
  { id: 20766, s: "002204.SZ", n: "Dalian Huarui Heavy Industry Group", c: "CH" },
  { id: 20767, s: "688600.SS", n: "Anhui Wanyi Science and Technology", c: "CH" },
  { id: 20768, s: "688766.SS", n: "Puya Semiconductor Shanghai", c: "CH" },
  { id: 20769, s: "002183.SZ", n: "Eternal Asia Supply Chain Management", c: "CH" },
  { id: 20770, s: "002184.SZ", n: "Shanghai HiTech Control System", c: "CH" },
  { id: 20771, s: "688707.SS", n: "Guizhou Zhenhua E chem Inc", c: "CH" },
  { id: 20772, s: "688697.SS", n: "Neway CNC Equipment Suzhou", c: "CH" },
  { id: 20773, s: "002188.SZ", n: "Bus Online", c: "CH" },
  { id: 20774, s: "002262.SZ", n: "Jiangsu Nhwa Pharmaceutical", c: "CH" },
  { id: 20775, s: "002178.SZ", n: "Shanghai Yanhua Smartech Group", c: "CH" },
  { id: 20776, s: "002210.SZ", n: "Shenzhen Feima International Supply Chain", c: "CH" },
  { id: 20777, s: "002213.SZ", n: "Shenzhen Terca Technology", c: "CH" },
  { id: 20778, s: "002239.SZ", n: "Aotecar New Energy Technology", c: "CH" },
  { id: 20779, s: "002240.SZ", n: "Shenzhen Chengxin Lithium Group", c: "CH" },
  { id: 20780, s: "688559.SS", n: "Shenzhen Hymson Laser Intelligent Equipments", c: "CH" },
  { id: 20781, s: "688558.SS", n: "Nantong Guosheng Intelligence Technology Group", c: "CH" },
  { id: 20782, s: "002181.SZ", n: "Guangdong Guangzhou Daily Media", c: "CH" },
  { id: 20783, s: "002177.SZ", n: "Guangzhou KingTeller Technology", c: "CH" },
  { id: 20784, s: "002205.SZ", n: "XinJiang GuoTong Pipeline", c: "CH" },
  { id: 20785, s: "002189.SZ", n: "Costar Group", c: "CH" },
  { id: 20786, s: "688617.SS", n: "APT Medical Inc", c: "CH" },
  { id: 20787, s: "688601.SS", n: "Wuxi ETEK Microelectronics", c: "CH" },
  { id: 20788, s: "002208.SZ", n: "Hefei Urban Construction Development", c: "CH" },
  { id: 20789, s: "688665.SS", n: "Cubic Sensor and Instrument", c: "CH" },
  { id: 20790, s: "002191.SZ", n: "Shenzhen Jinjia Color Printing Group", c: "CH" },
  { id: 20791, s: "688689.SS", n: "Changzhou Galaxy Century Microelectronics", c: "CH" },
  { id: 20792, s: "002167.SZ", n: "Guangdong Orient Zirconic Ind Sci & Tech", c: "CH" },
  { id: 20793, s: "002176.SZ", n: "Jiangxi Special Electric Motor", c: "CH" },
  { id: 20794, s: "002168.SZ", n: "Shenzhen Hifuture Electric", c: "CH" },
  { id: 20795, s: "002169.SZ", n: "Guangzhou Zhiguang Electric", c: "CH" },
  { id: 20796, s: "002170.SZ", n: "Shenzhen Batian Ecotypic Engineering", c: "CH" },
  { id: 20797, s: "688798.SS", n: "Shanghai Awinic Technology", c: "CH" },
  { id: 20798, s: "688778.SS", n: "XTC New Energy Materials Xiamen", c: "CH" },
  { id: 20799, s: "002175.SZ", n: "Oriental Times Media Co", c: "CH" },
  { id: 20800, s: "002350.SZ", n: "Beijing Creative Distribution Automation", c: "CH" },
  { id: 20801, s: "002347.SZ", n: "Taier Heavy Industry", c: "CH" },
  { id: 20802, s: "002245.SZ", n: "Jiangsu Aucksun", c: "CH" },
  { id: 20803, s: "002346.SZ", n: "Shanghai Zhezhong Construction", c: "CH" },
  { id: 20804, s: "002288.SZ", n: "Guangdong Chaohua Technology", c: "CH" },
  { id: 20805, s: "002289.SZ", n: "Success Electronics", c: "CH" },
  { id: 20806, s: "688350.SS", n: "Jiangsu Feymer Technology", c: "CH" },
  { id: 20807, s: "002291.SZ", n: "Foshan Saturday Shoes", c: "CH" },
  { id: 20808, s: "688338.SS", n: "Beijing Succeeder Technology Inc", c: "CH" },
  { id: 20809, s: "688333.SS", n: "Xi an Bright Laser Tech", c: "CH" },
  { id: 20810, s: "002295.SZ", n: "Guangdong Jingyi Metal", c: "CH" },
  { id: 20811, s: "002287.SZ", n: "Tibet Cheezheng Tibetan Medicine", c: "CH" },
  { id: 20812, s: "002296.SZ", n: "HeNan Splendor Science & Technology", c: "CH" },
  { id: 20813, s: "002298.SZ", n: "Anhui Xinlong Electrical", c: "CH" },
  { id: 20814, s: "688321.SS", n: "Shenzhen Chipscreen Biosciences ", c: "CH" },
  { id: 20815, s: "002300.SZ", n: "Fujian Nanping Sun Cable", c: "CH" },
  { id: 20816, s: "002301.SZ", n: "Shenzhen Comix Group", c: "CH" },
  { id: 20817, s: "002302.SZ", n: "CH West Construction Group", c: "CH" },
  { id: 20818, s: "002303.SZ", n: "Shenzhen MYS Environmental Protection & Technology", c: "CH" },
  { id: 20819, s: "002166.SZ", n: "Guilin Layn Natural Ingredients Co", c: "CH" },
  { id: 20820, s: "002164.SZ", n: "Ningbo Donly", c: "CH" },
  { id: 20821, s: "SMEV", n: "Simulated Environmen", c: "US" },
  { id: 20822, s: "002021.SZ", n: "Zoje Resources Investment", c: "CH" },
  { id: 20823, s: "KORDE.AT", n: "Kordellos Ch. Bros S.A", c: "Greece" },
  { id: 20824, s: "002014.SZ", n: "Huangshan Novel", c: "CH" },
  { id: 20825, s: "002015.SZ", n: "GCL Energy Technology", c: "CH" },
  { id: 20826, s: "002016.SZ", n: "Guangdong Shirongzhaoye", c: "CH" },
  { id: 20827, s: "002017.SZ", n: "Eastcompeace Technology", c: "CH" },
  { id: 20828, s: "KLM.AT", n: "I.Kloukinas-I.Lappas S.A", c: "Greece" },
  { id: 20829, s: "KAMP.AT", n: "Reds S.A", c: "Greece" },
  { id: 20830, s: "LANAC.AT", n: "Lanakam S.A", c: "Greece" },
  { id: 20831, s: "INTERCO.AT", n: "Intercontinental International Real Estate Investment Company", c: "Greece" },
  { id: 20832, s: "002025.SZ", n: "Guizhou Space Appliance", c: "CH" },
  { id: 20833, s: "002026.SZ", n: "Shandong Weida Machinery", c: "CH" },
  { id: 20834, s: "ILYDA.AT", n: "Ilyda SA", c: "Greece" },
  { id: 20835, s: "002029.SZ", n: "Fujian Septwolves Industry", c: "CH" },
  { id: 20836, s: "002033.SZ", n: "LiJiang YuLong Tourism", c: "CH" },
  { id: 20837, s: "688318.SS", n: "Shenzhen Fortune Trend Technology", c: "CH" },
  { id: 20838, s: "002297.SZ", n: "Hunan Boyun New Materials", c: "CH" },
  { id: 20839, s: "002286.SZ", n: "Baolingbao Biology", c: "CH" },
  { id: 20840, s: "688257.SS", n: "Shareate Tools", c: "CH" },
  { id: 20841, s: "002306.SZ", n: "Cloud Live Technology Group", c: "CH" },
  { id: 20842, s: "688516.SS", n: "Wuxi Autowell Technology", c: "CH" },
  { id: 20843, s: "002307.SZ", n: "Xinjiang Beixin Road&Bridge Group", c: "CH" },
  { id: 20844, s: "688309.SS", n: "Niutech Environment Technology Co", c: "CH" },
  { id: 20845, s: "002332.SZ", n: "Zhejiang Xianju Pharmaceutical", c: "CH" },
  { id: 20846, s: "688277.SS", n: "Tinavi Medical Technologies", c: "CH" },
  { id: 20847, s: "688236.SS", n: "Beijing Chunlizhengda Medical Instruments", c: "CH" },
  { id: 20848, s: "002282.SZ", n: "Bosun Tools", c: "CH" },
  { id: 20849, s: "688233.SS", n: "Thinkon Semiconductor Jinzhou Co", c: "CH" },
  { id: 20850, s: "688230.SS", n: "Shanghai Prisemi Electronics", c: "CH" },
  { id: 20851, s: "002331.SZ", n: "Anhui Wantong Technology", c: "CH" },
  { id: 20852, s: "688226.SS", n: "Wetown Electric Group", c: "CH" },
  { id: 20853, s: "688186.SS", n: "Zhangjiagang Guangda Special Material", c: "CH" },
  { id: 20854, s: "002345.SZ", n: "Guangdong CHJ Industry", c: "CH" },
  { id: 20855, s: "002283.SZ", n: "Tianrun Crankshaft", c: "CH" },
  { id: 20856, s: "688378.SS", n: "Jilin OLED Material Tech", c: "CH" },
  { id: 20857, s: "688368.SS", n: "Shanghai Bright Power Semiconductor", c: "CH" },
  { id: 20858, s: "002270.SZ", n: "Huaming Power Equipment", c: "CH" },
  { id: 20859, s: "002284.SZ", n: "ZHEJIANG ASIA-PACIFIC MECHANICAL & ELECTRONIC", c: "CH" },
  { id: 20860, s: "688513.SS", n: "Chengdu Easton Biopharmaceuticals", c: "CH" },
  { id: 20861, s: "688511.SS", n: "Sichuan Tianwei Electronic", c: "CH" },
  { id: 20862, s: "002267.SZ", n: "Shaanxi Provincial Natural Gas", c: "CH" },
  { id: 20863, s: "688509.SS", n: "Zhengyuan Geomatics Group", c: "CH" },
  { id: 20864, s: "002269.SZ", n: "Shanghai Metersbonwe Fashion&Accessories", c: "CH" },
  { id: 20865, s: "688500.SS", n: "HCR", c: "CH" },
  { id: 20866, s: "688393.SS", n: "Guangzhou LBP Medicine Science & Technology", c: "CH" },
  { id: 20867, s: "688466.SS", n: "GreenTech Environmental", c: "CH" },
  { id: 20868, s: "002273.SZ", n: "Zhejiang Crystal Optech", c: "CH" },
  { id: 20869, s: "002274.SZ", n: "Jiangsu Huachang Chemical", c: "CH" },
  { id: 20870, s: "002275.SZ", n: "Guilin Sanjin Pharmaceutical", c: "CH" },
  { id: 20871, s: "RYHTY", n: "Ryman Healthcare ADR", c: "US" },
  { id: 20872, s: "002277.SZ", n: "Hunan Friendship&Apollo Commercial", c: "CH" },
  { id: 20873, s: "002243.SZ", n: "Shenzhen Beauty Star", c: "CH" },
  { id: 20874, s: "002246.SZ", n: "North Chemical Industries", c: "CH" },
  { id: 20875, s: "HMI.WA", n: "HM Inwest S.A", c: "Poland" },
  { id: 20876, s: "08N.WA", n: "NFI Octava SA", c: "Poland" },
  { id: 20877, s: "300258.SZ", n: "Jiangsu Pacific Precision", c: "CH" },
  { id: 20878, s: "300257.SZ", n: "Zhejiang Kaishan Compressor", c: "CH" },
  { id: 20879, s: "300237.SZ", n: "Shandong Meichen Science & Technology", c: "CH" },
  { id: 20880, s: "RABA.BD", n: "Raba Jarmuipari Holding Nyrt", c: "Hungary" },
  { id: 20881, s: "PERGY.BD", n: "PANNERGY PLC", c: "Hungary" },
  { id: 20882, s: "TIXC", n: "Tix Co", c: "US" },
  { id: 20883, s: "GSP.BD", n: "Graphisoft Park SE", c: "Hungary" },
  { id: 20884, s: "PGENY", n: "Pigeon Corp ADR", c: "US" },
  { id: 20885, s: "300245.SZ", n: "Shanghai Dragonnet Tech", c: "CH" },
  { id: 20886, s: "300246.SZ", n: "Guangdong Biolight Meditech", c: "CH" },
  { id: 20887, s: "300247.SZ", n: "Youngy Health", c: "CH" },
  { id: 20888, s: "300248.SZ", n: "Newcapec Electronics", c: "CH" },
  { id: 20889, s: "300249.SZ", n: "Sichuan Yimikang Environmental Tech", c: "CH" },
  { id: 20890, s: "300250.SZ", n: "Hangzhou CNCR-IT", c: "CH" },
  { id: 20891, s: "ENEFI.BD", n: "ENEFI Energiahatekonysagi Nyrt", c: "Hungary" },
  { id: 20892, s: "300259.SZ", n: "Suntront Tech", c: "CH" },
  { id: 20893, s: "377300.KS", n: "kakaopay Corp.", c: "Korea" },
  { id: 20894, s: "300282.SZ", n: "Sansheng Intellectual Education Technology", c: "CH" },
  { id: 20895, s: "300268.SZ", n: "Joyvio Agriculture Development", c: "CH" },
  { id: 20896, s: "452260.KS", n: "Hanwha Galleria Corp.", c: "Korea" },
  { id: 20897, s: "300262.SZ", n: "Shanghai Safbon Water Service", c: "CH" },
  { id: 20898, s: "300264.SZ", n: "AVIT", c: "CH" },
  { id: 20899, s: "300265.SZ", n: "Jiangsu Tongguang Electronic Wire&Cable", c: "CH" },
  { id: 20900, s: "300266.SZ", n: "Xingyuan Environment Technology", c: "CH" },
  { id: 20901, s: "300267.SZ", n: "Hunan Er Kang Pharmaceutical", c: "CH" },
  { id: 20902, s: "300260.SZ", n: "Kunshan Kinglai Hygienic Materials", c: "CH" },
  { id: 20903, s: "300280.SZ", n: "Jiangsu Zitian Media Technology", c: "CH" },
  { id: 20904, s: "383800.KS", n: "LX Holdings Co", c: "Korea" },
  { id: 20905, s: "TKOMF", n: "Tokio Marine Holdings Inc.", c: "US" },
  { id: 20906, s: "300276.SZ", n: "HuBei SanFeng Intelligent Conveying Equipment", c: "CH" },
  { id: 20907, s: "365550.KS", n: "Esr Kendall Square Reit ", c: "Korea" },
  { id: 20908, s: "357250.KS", n: "Mirae Asset Maps Reit 1 ", c: "Korea" },
  { id: 20909, s: "357120.KS", n: "Koramco Energy Plus Reit", c: "Korea" },
  { id: 20910, s: "300252.SZ", n: "Kingsignal Technology", c: "CH" },
  { id: 20911, s: "DELTA.BD", n: "Delta Technologies Nyrt", c: "Hungary" },
  { id: 20912, s: "APPEN.BD", n: "Appeninn Nyrt.", c: "Hungary" },
  { id: 20913, s: "300086.SZ", n: "Hainan Honz Pharmaceutical", c: "CH" },
  { id: 20914, s: "ENI.WA", n: "Energoinstal S.A.", c: "Poland" },
  { id: 20915, s: "EMC.WA", n: "EMC Instytut Medyczny SA", c: "Poland" },
  { id: 20916, s: "300121.SZ", n: "Shandong Yanggu Huatai Chemical", c: "CH" },
  { id: 20917, s: "300108.SZ", n: "Ji Yao Holding Group", c: "CH" },
  { id: 20918, s: "300107.SZ", n: "Hebei Jianxin Chemical", c: "CH" },
  { id: 20919, s: "300106.SZ", n: "Xinjiang West Animal Husbandry", c: "CH" },
  { id: 20920, s: "300087.SZ", n: "Winall Hi tech Seed", c: "CH" },
  { id: 20921, s: "300127.SZ", n: "Chengdu Galaxy Magnets", c: "CH" },
  { id: 20922, s: "300088.SZ", n: "Wuhu Token Sciences", c: "CH" },
  { id: 20923, s: "300091.SZ", n: "JiangSu Jin Tong Ling Fluid Mach", c: "CH" },
  { id: 20924, s: "300092.SZ", n: "Sichuan Kexin Mechanic Electric", c: "CH" },
  { id: 20925, s: "300093.SZ", n: "Gansu Golden Glass Technologies", c: "CH" },
  { id: 20926, s: "ICE.WA", n: "Medinice S.A", c: "Poland" },
  { id: 20927, s: "HRP.WA", n: "Harper Hygienics S.A.", c: "Poland" },
  { id: 20928, s: "300128.SZ", n: "Suzhou Jinfu New Material", c: "CH" },
  { id: 20929, s: "300126.SZ", n: "Shanghai KEN Tools", c: "CH" },
  { id: 20930, s: "300255.SZ", n: "Hebei Changshan Biochem Pharma", c: "CH" },
  { id: 20931, s: "300114.SZ", n: "Zhonghang Electronic Measuring Instruments", c: "CH" },
  { id: 20932, s: "BIP.WA", n: "Bio Planet SA", c: "Poland" },
  { id: 20933, s: "300183.SZ", n: "Qingdao Eastsoft Communic Tech", c: "CH" },
  { id: 20934, s: "BNP.WA", n: "BNP Paribas Bank Polska S.A.", c: "Poland" },
  { id: 20935, s: "300181.SZ", n: "Zhejiang Jolly Pharmaceutical", c: "CH" },
  { id: 20936, s: "GOP.WA", n: "Games Operators SA", c: "Poland" },
  { id: 20937, s: "GLC.WA", n: "Global Cosmed SA", c: "Poland" },
  { id: 20938, s: "GEA.WA", n: "Grenevia S.A.", c: "Poland" },
  { id: 20939, s: "300125.SZ", n: "Lingda Group", c: "CH" },
  { id: 20940, s: "300116.SZ", n: "Baoli New Energy Technology", c: "CH" },
  { id: 20941, s: "FRO.WA", n: "Ferro S.A.", c: "Poland" },
  { id: 20942, s: "FER.WA", n: "Ferrum S.A.", c: "Poland" },
  { id: 20943, s: "GRN.WA", n: "Grodno S.A.", c: "Poland" },
  { id: 20944, s: "EUC.WA", n: "Europejskie Centrum Odszkodowań SA", c: "Poland" },
  { id: 20945, s: "300123.SZ", n: "YaGuang Technology Group", c: "CH" },
  { id: 20946, s: "300236.SZ", n: "Shanghai Sinyang Semiconductor", c: "CH" },
  { id: 20947, s: "300234.SZ", n: "Zhejiang Kaier New Materials", c: "CH" },
  { id: 20948, s: "002238.SZ", n: "Shenzhen Topway Video Communication", c: "CH" },
  { id: 20949, s: "300198.SZ", n: "Fujian Superpipe", c: "CH" },
  { id: 20950, s: "002222.SZ", n: "Castech Inc", c: "CH" },
  { id: 20951, s: "688589.SS", n: "Leaguer Shenzhen MicroElectronics Co", c: "CH" },
  { id: 20952, s: "002224.SZ", n: "Sanlux", c: "CH" },
  { id: 20953, s: "002226.SZ", n: "Anhui Jiangnan Chemical Industry", c: "CH" },
  { id: 20954, s: "688585.SS", n: "Swancor Advanced Materials", c: "CH" },
  { id: 20955, s: "002228.SZ", n: "Xiamen Hexing Packaging Printing", c: "CH" },
  { id: 20956, s: "688579.SS", n: "Dareway Software", c: "CH" },
  { id: 20957, s: "688568.SS", n: "Geovis Technology", c: "CH" },
  { id: 20958, s: "688566.SS", n: "Jiangsu Jibeier Pharmaceutical", c: "CH" },
  { id: 20959, s: "002233.SZ", n: "Guangdong Tapai Group", c: "CH" },
  { id: 20960, s: "002234.SZ", n: "Shandong Minhe Animal Husbandry", c: "CH" },
  { id: 20961, s: "002211.SZ", n: "Shanghai Hongda New Material", c: "CH" },
  { id: 20962, s: "104700.KS", n: "KISCO Co", c: "Korea" },
  { id: 20963, s: "601598.SS", n: "Sinotrans Class A", c: "CH" },
  { id: 20964, s: "002749.SZ", n: "Sichuan Guoguang Agrochemical", c: "CH" },
  { id: 20965, s: "002221.SZ", n: "Oriental Energy", c: "CH" },
  { id: 20966, s: "688596.SS", n: "Shanghai GenTech", c: "CH" },
  { id: 20967, s: "002218.SZ", n: "Shenzhen Topraysolar", c: "CH" },
  { id: 20968, s: "688526.SS", n: "Wuhan Keqian Biology", c: "CH" },
  { id: 20969, s: "002247.SZ", n: "Zhejiang Dilong New Material", c: "CH" },
  { id: 20970, s: "002249.SZ", n: "Zhongshan Broad-Ocean Motor", c: "CH" },
  { id: 20971, s: "002250.SZ", n: "Lianhe Chemical Technology", c: "CH" },
  { id: 20972, s: "002251.SZ", n: "Better Life Commercial Chain Share", c: "CH" },
  { id: 20973, s: "002253.SZ", n: "Wisesoft", c: "CH" },
  { id: 20974, s: "002254.SZ", n: "Yantai Tayho Advanced Materials", c: "CH" },
  { id: 20975, s: "002258.SZ", n: "Lier Chemical", c: "CH" },
  { id: 20976, s: "002216.SZ", n: "Sanquan Food", c: "CH" },
  { id: 20977, s: "688553.SS", n: "Sichuan Huiyu Pharmaceutical", c: "CH" },
  { id: 20978, s: "002237.SZ", n: "Shandong Humon Smelting", c: "CH" },
  { id: 20979, s: "PTAIY", n: "Astra International Tbk PT", c: "US" },
  { id: 20980, s: "002235.SZ", n: "Xiamen Anne Co", c: "CH" },
  { id: 20981, s: "002214.SZ", n: "Zhe Jiang Dali Technology", c: "CH" },
  { id: 20982, s: "002215.SZ", n: "Shenzhen Noposion Agrochemicals", c: "CH" },
  { id: 20983, s: "APL.WA", n: "Ampli SA w upadlosci ukladowej", c: "Poland" },
  { id: 20984, s: "300215.SZ", n: "Suzhou Electrical Apparatus Sci", c: "CH" },
  { id: 20985, s: "300217.SZ", n: "Zhenjiang Dongfang Electric Heat", c: "CH" },
  { id: 20986, s: "AWM.WA", n: "Airway", c: "Poland" },
  { id: 20987, s: "300191.SZ", n: "Sino Geophysical", c: "CH" },
  { id: 20988, s: "300193.SZ", n: "Shenzhen Jasic Tech", c: "CH" },
  { id: 20989, s: "BAH.WA", n: "British Automotive Holding S.A.", c: "Poland" },
  { id: 20990, s: "B24.WA", n: "Brand 24 SA", c: "Poland" },
  { id: 20991, s: "300196.SZ", n: "Jiangsu Changhai Compos Material", c: "CH" },
  { id: 20992, s: "300210.SZ", n: "Anshan Senyuan Road Bridge", c: "CH" },
  { id: 20993, s: "ATR.WA", n: "Atrem S.A.", c: "Poland" },
  { id: 20994, s: "300187.SZ", n: "Yonker Environmental Protect", c: "CH" },
  { id: 20995, s: "300200.SZ", n: "Beijing Comens New Materials", c: "CH" },
  { id: 20996, s: "300201.SZ", n: "Xuzhou Handler Special Vehicle", c: "CH" },
  { id: 20997, s: "300203.SZ", n: "Focused Photonics Hangzhou Inc", c: "CH" },
  { id: 20998, s: "300205.SZ", n: "Wuhan Tianyu Info Industry", c: "CH" },
  { id: 20999, s: "300206.SZ", n: "Edan Instruments Inc", c: "CH" },
  { id: 21000, s: "ART.WA", n: "Artifex Mundi SA", c: "Poland" },
  { id: 21001, s: "300189.SZ", n: "Hainan Shennong Technology", c: "CH" },
  { id: 21002, s: "300208.SZ", n: "Qingdao Hengshun Zhongsheng", c: "CH" },
  { id: 21003, s: "APE.WA", n: "APS Energia S.A.", c: "Poland" },
  { id: 21004, s: "300227.SZ", n: "Shenzhen Sunshine Laser & Electronics Technology", c: "CH" },
  { id: 21005, s: "ANR.WA", n: "Answear.com S.A.", c: "Poland" },
  { id: 21006, s: "300220.SZ", n: "Wuhan Golden Laser", c: "CH" },
  { id: 21007, s: "300221.SZ", n: "Guangdong Silvere Sci and Tech", c: "CH" },
  { id: 21008, s: "300213.SZ", n: "Beijing Jiaxun Feihong Electrical", c: "CH" },
  { id: 21009, s: "THKLY", n: "THK Co ADR", c: "US" },
  { id: 21010, s: "ADV.WA", n: "Adiuvo Investment SA", c: "Poland" },
  { id: 21011, s: "ACG.WA", n: "AC S.A.", c: "Poland" },
  { id: 21012, s: "TGRR", n: "Tiger Reef Inc", c: "US" },
  { id: 21013, s: "AAT.WA", n: "Alta S.A.", c: "Poland" },
  { id: 21014, s: "THYCY", n: "Taiheiyo Cement Co", c: "US" },
  { id: 21015, s: "300231.SZ", n: "Beijing Trust Far Tech", c: "CH" },
  { id: 21016, s: "300232.SZ", n: "Unilumin Group", c: "CH" },
  { id: 21017, s: "300224.SZ", n: "Yantai Zhenghai Magnetic Mat", c: "CH" },
  { id: 21018, s: "300233.SZ", n: "Shandong Jincheng Pharmaceutical and Chemical", c: "CH" },
  { id: 21019, s: "053290.KQ", n: "Neungyule Education Inc", c: "Korea" },
  { id: 21020, s: "024940.KQ", n: "PN Poong Nyun Co.", c: "Korea" },
  { id: 21021, s: "053580.KQ", n: "WebCash Corporation", c: "Korea" },
  { id: 21022, s: "037560.KS", n: "LG HelloVision Co", c: "Korea" },
  { id: 21023, s: "300650.SZ", n: "Tecnon Fujian Commercial Lighting", c: "CH" },
  { id: 21024, s: "300651.SZ", n: "Jiangsu Jinling Sports Equipment", c: "CH" },
  { id: 21025, s: "300653.SZ", n: "Yantai Zhenghai Bio-Tech", c: "CH" },
  { id: 21026, s: "300654.SZ", n: "Shandong Astro-century Education&Technology", c: "CH" },
  { id: 21027, s: "300655.SZ", n: "Suzhou Crystal Clear Chemical", c: "CH" },
  { id: 21028, s: "300656.SZ", n: "Shenzhen MinDe Electronics Technology", c: "CH" },
  { id: 21029, s: "300657.SZ", n: "XiaMen HongXin Electron-tech", c: "CH" },
  { id: 21030, s: "300659.SZ", n: "Zhongfu Information Inc", c: "CH" },
  { id: 21031, s: "044820.KS", n: "Cosmax", c: "Korea" },
  { id: 21032, s: "300663.SZ", n: "Client Service International Inc", c: "CH" },
  { id: 21033, s: "055490.KS", n: "TAPEX INC", c: "Korea" },
  { id: 21034, s: "300690.SZ", n: "Shandong Shuangyi Technology", c: "CH" },
  { id: 21035, s: "300585.SZ", n: "Nanjing Aolian Ae&Ea", c: "CH" },
  { id: 21036, s: "300583.SZ", n: "Shandong Sito Bio-technology", c: "CH" },
  { id: 21037, s: "300515.SZ", n: "Hunan Sundy Science and Technology", c: "CH" },
  { id: 21038, s: "300649.SZ", n: "Hangzhou Landscape Architecture Design Institute", c: "CH" },
  { id: 21039, s: "300648.SZ", n: "Fujian Nebula Electronics", c: "CH" },
  { id: 21040, s: "300647.SZ", n: "Shenzhen Fluence Technology PLC", c: "CH" },
  { id: 21041, s: "300689.SZ", n: "Shenzhen Chengtian Weiye Technology", c: "CH" },
  { id: 21042, s: "300678.SZ", n: "Chengdu Information Technology of Chinese Academy of Sciences", c: "CH" },
  { id: 21043, s: "036580.KS", n: "Farms", c: "Korea" },
  { id: 21044, s: "300681.SZ", n: "Zhuhai Enpower Electric", c: "CH" },
  { id: 21045, s: "TRSI", n: "Trophy Resources Inc", c: "US" },
  { id: 21046, s: "300685.SZ", n: "Amoy Diagnostics", c: "CH" },
  { id: 21047, s: "300687.SZ", n: "Guangzhou SiE Consulting", c: "CH" },
  { id: 21048, s: "300679.SZ", n: "Electric Connector Technology", c: "CH" },
  { id: 21049, s: "049800.KS", n: "Woojin Plaimm", c: "Korea" },
  { id: 21050, s: "300667.SZ", n: "Beijing Beetech Inc", c: "CH" },
  { id: 21051, s: "300665.SZ", n: "Zhuzhou Feilu High-Tech Materials", c: "CH" },
  { id: 21052, s: "300664.SZ", n: "Penyao Environmental Protection Co Class A", c: "CH" },
  { id: 21053, s: "300641.SZ", n: "Jiangsu Zhengdan Chemical Industry", c: "CH" },
  { id: 21054, s: "051905.KS", n: "LG Household & Healthcare Pref", c: "Korea" },
  { id: 21055, s: "300643.SZ", n: "Hamaton Automotive Technology", c: "CH" },
  { id: 21056, s: "093370.KS", n: "Foosung", c: "Korea" },
  { id: 21057, s: "300517.SZ", n: "Haibo Heavy Engineering Science and Technology", c: "CH" },
  { id: 21058, s: "300518.SZ", n: "Shenzhen Shengxunda Technology Co Class A", c: "CH" },
  { id: 21059, s: "097230.KS", n: "HJ ShipBuilding & Construction", c: "Korea" },
  { id: 21060, s: "300495.SZ", n: "Misho Ecology & Landscape", c: "CH" },
  { id: 21061, s: "101530.KS", n: "Haitai Confectionery and Foods", c: "Korea" },
  { id: 21062, s: "100840.KS", n: "SNTEnergy", c: "Korea" },
  { id: 21063, s: "100250.KS", n: "Chinyang Hold", c: "Korea" },
  { id: 21064, s: "300499.SZ", n: "Guangzhou Goaland Energy Conservation Tech", c: "CH" },
  { id: 21065, s: "100220.KS", n: "Visang Educati", c: "Korea" },
  { id: 21066, s: "095720.KS", n: "Woongjin Thinkbig", c: "Korea" },
  { id: 21067, s: "300491.SZ", n: "Shijiazhuang Tonhe Electronics Technologies", c: "CH" },
  { id: 21068, s: "300504.SZ", n: "Sichuan Tianyi Comheart Telecom Co Class A", c: "CH" },
  { id: 21069, s: "300505.SZ", n: "Kunming Chuan Jin Nuo Chemical", c: "CH" },
  { id: 21070, s: "300506.SZ", n: "Shenzhen Minkave Technology", c: "CH" },
  { id: 21071, s: "300507.SZ", n: "Jiangsu Olive Sensors High-Tech", c: "CH" },
  { id: 21072, s: "300508.SZ", n: "Shanghai Weihong Electronic Technology", c: "CH" },
  { id: 21073, s: "300509.SZ", n: "Jiangsu Newamstar Packaging Machinery", c: "CH" },
  { id: 21074, s: "300494.SZ", n: "Hubei Century Network Technology", c: "CH" },
  { id: 21075, s: "300490.SZ", n: "Hnac Technology", c: "CH" },
  { id: 21076, s: "093050.KS", n: "LF Co", c: "Korea" },
  { id: 21077, s: "090435.KS", n: "Amorepacific Corp Pref", c: "Korea" },
  { id: 21078, s: "300520.SZ", n: "GuoChuang Software", c: "CH" },
  { id: 21079, s: "300521.SZ", n: "Guangzhou Amsky Technology", c: "CH" },
  { id: 21080, s: "300514.SZ", n: "Shenzhen Friendcom Technology Development", c: "CH" },
  { id: 21081, s: "300522.SZ", n: "Suzhou Sunmun Technology", c: "CH" },
  { id: 21082, s: "092780.KS", n: "Dongyang Piston", c: "Korea" },
  { id: 21083, s: "300528.SZ", n: "Omnijoi Media Co", c: "CH" },
  { id: 21084, s: "090355.KS", n: "NOROO Paint & Coatings", c: "Korea" },
  { id: 21085, s: "300510.SZ", n: "Jilin Jinguan Electric Co Class A", c: "CH" },
  { id: 21086, s: "090080.KS", n: "Pyung Hwa Ind", c: "Korea" },
  { id: 21087, s: "300532.SZ", n: "New Trend International Logis-Tech", c: "CH" },
  { id: 21088, s: "300533.SZ", n: "Shenzhen Bingchuan Network", c: "CH" },
  { id: 21089, s: "300523.SZ", n: "Beijing Global Safety Technology", c: "CH" },
  { id: 21090, s: "094280.KS", n: "Hyosung Itx", c: "Korea" },
  { id: 21091, s: "300511.SZ", n: "Shanghai Xuerong Bio-Technology", c: "CH" },
  { id: 21092, s: "300668.SZ", n: "Shenzhen Jiang and Associates Creative Design", c: "CH" },
  { id: 21093, s: "300675.SZ", n: "Shenzhen Institute of Building Research Co Class A", c: "CH" },
  { id: 21094, s: "072710.KS", n: "Nongshim Holdi", c: "Korea" },
  { id: 21095, s: "039130.KS", n: "Hana Tour", c: "Korea" },
  { id: 21096, s: "001525.KS", n: "Tongyang Inc", c: "Korea" },
  { id: 21097, s: "001500.KS", n: "Hyundai Motor Securities Co..", c: "Korea" },
  { id: 21098, s: "001465.KS", n: "BYC", c: "Korea" },
  { id: 21099, s: "001460.KS", n: "BYC Co.", c: "Korea" },
  { id: 21100, s: "002070.KS", n: "Vivien Co", c: "Korea" },
  { id: 21101, s: "014280.KS", n: "Kumkang Kind", c: "Korea" },
  { id: 21102, s: "300901.SZ", n: "Zhejiang Zoenn Design ", c: "CH" },
  { id: 21103, s: "300900.SZ", n: "Guanglian Aviation Industry ", c: "CH" },
  { id: 21104, s: "300616.SZ", n: "Guangzhou Shangpin Home Collection", c: "CH" },
  { id: 21105, s: "300617.SZ", n: "Jiangsu Ankura Smart Transmission Engineering Technology", c: "CH" },
  { id: 21106, s: "300618.SZ", n: "Nanjing Hanrui Cobalt", c: "CH" },
  { id: 21107, s: "300621.SZ", n: "Shenzhen Weiye Decoration Group", c: "CH" },
  { id: 21108, s: "300622.SZ", n: "Doctorglasses Chain", c: "CH" },
  { id: 21109, s: "067830.KS", n: "Savezone I C", c: "Korea" },
  { id: 21110, s: "069640.KS", n: "MK Trend Co..", c: "Korea" },
  { id: 21111, s: "001527.KS", n: "Tongyang Inc", c: "Korea" },
  { id: 21112, s: "001530.KS", n: "DI Dong Il Co", c: "Korea" },
  { id: 21113, s: "001560.KS", n: "Cheil Grinding", c: "Korea" },
  { id: 21114, s: "002020.KS", n: "Kolon Co", c: "Korea" },
  { id: 21115, s: "000815.KS", n: "Samsung Fire And Marine Ins Pref", c: "Korea" },
  { id: 21116, s: "000760.KS", n: "Rifa Ind", c: "Korea" },
  { id: 21117, s: "001065.KS", n: "JW Pharmaceutical Co", c: "Korea" },
  { id: 21118, s: "001420.KS", n: "Tae Won Mulsan", c: "Korea" },
  { id: 21119, s: "NDGPY", n: "Nine Dragons Paper Holdings ADR", c: "US" },
  { id: 21120, s: "001430.KS", n: "SeAH Bestee", c: "Korea" },
  { id: 21121, s: "BDT.AX", n: "Birddog Australia Pty", c: "Australia" },
  { id: 21122, s: "001620.KS", n: "Dongkook Ind", c: "Korea" },
  { id: 21123, s: "001795.KS", n: "TS Corp Preference Shares", c: "Korea" },
  { id: 21124, s: "001790.KS", n: "TS Corporation", c: "Korea" },
  { id: 21125, s: "001725.KS", n: "Shinyoung Securities Pref", c: "Korea" },
  { id: 21126, s: "001720.KS", n: "Shinyoung Securities Co..", c: "Korea" },
  { id: 21127, s: "001685.KS", n: "Daesang Corp Preference Shares", c: "Korea" },
  { id: 21128, s: "001680.KS", n: "Daesang Co", c: "Korea" },
  { id: 21129, s: "300627.SZ", n: "Shanghai Huace Navigation Technology", c: "CH" },
  { id: 21130, s: "300630.SZ", n: "Hainan Poly Pharm", c: "CH" },
  { id: 21131, s: "058850.KS", n: "ktcs corporation", c: "Korea" },
  { id: 21132, s: "057050.KS", n: "Hyundai Home Shopping Network Co", c: "Korea" },
  { id: 21133, s: "071090.KS", n: "Histeel", c: "Korea" },
  { id: 21134, s: "071055.KS", n: "Korea Investment Holdings", c: "Korea" },
  { id: 21135, s: "300606.SZ", n: "Dongguan Golden Sun Abrasives", c: "CH" },
  { id: 21136, s: "069730.KS", n: "Dsr Wire", c: "Korea" },
  { id: 21137, s: "300608.SZ", n: "SI-TECH Information Technology", c: "CH" },
  { id: 21138, s: "300609.SZ", n: "Winner Information Technology Co Inc", c: "CH" },
  { id: 21139, s: "300588.SZ", n: "Xinjiang Sailing Information Technology", c: "CH" },
  { id: 21140, s: "300599.SZ", n: "Guangdong Xiongsu Technology Group", c: "CH" },
  { id: 21141, s: "300637.SZ", n: "Zhejiang Yangfan New Materials", c: "CH" },
  { id: 21142, s: "053210.KS", n: "Kt Skylife", c: "Korea" },
  { id: 21143, s: "300669.SZ", n: "Hangzhou Huning Elevator Parts", c: "CH" },
  { id: 21144, s: "300670.SZ", n: "Jiangsu Daybright Intelligent Electric", c: "CH" },
  { id: 21145, s: "041650.KS", n: "Sangsin Brake", c: "Korea" },
  { id: 21146, s: "039570.KS", n: "I Controls Inc", c: "Korea" },
  { id: 21147, s: "300602.SZ", n: "Shenzhen FRD Science & Technology", c: "CH" },
  { id: 21148, s: "300598.SZ", n: "ArcherMind Technology Nanjing", c: "CH" },
  { id: 21149, s: "300632.SZ", n: "Xiamen Guang Pu Electronics", c: "CH" },
  { id: 21150, s: "300610.SZ", n: "Yangzhou Chenhua New Material", c: "CH" },
  { id: 21151, s: "058730.KS", n: "Development Advance Solution", c: "Korea" },
  { id: 21152, s: "058650.KS", n: "Seah Holdings", c: "Korea" },
  { id: 21153, s: "058430.KS", n: "Posco C&C Stl", c: "Korea" },
  { id: 21154, s: "063160.KS", n: "Ckd Bio", c: "Korea" },
  { id: 21155, s: "300612.SZ", n: "Shunya International Brand Consulting Beijing Co Class A", c: "CH" },
  { id: 21156, s: "300611.SZ", n: "Zhejiang Meili High Technology", c: "CH" },
  { id: 21157, s: "300589.SZ", n: "Jianglong Shipbuilding", c: "CH" },
  { id: 21158, s: "300597.SZ", n: "Jilin Jlu Communication Design Institute", c: "CH" },
  { id: 21159, s: "300590.SZ", n: "Queclink Wireless Solutions", c: "CH" },
  { id: 21160, s: "300591.SZ", n: "Guangdong Wanlima Industry", c: "CH" },
  { id: 21161, s: "300592.SZ", n: "Hunan Huakai Cultural and Creative", c: "CH" },
  { id: 21162, s: "300593.SZ", n: "Beijing Xinleineng Technology", c: "CH" },
  { id: 21163, s: "300594.SZ", n: "Shandong Longertek Technology", c: "CH" },
  { id: 21164, s: "300595.SZ", n: "Autek CH Inc", c: "CH" },
  { id: 21165, s: "300535.SZ", n: "Sichuan Dowell Science and Technology Inc", c: "CH" },
  { id: 21166, s: "300536.SZ", n: "Wuhan Nusun Landscape", c: "CH" },
  { id: 21167, s: "300696.SZ", n: "Chengdu ALD Aviation Manufacturing Co", c: "CH" },
  { id: 21168, s: "017900.KS", n: "Auk Co", c: "Korea" },
  { id: 21169, s: "014580.KS", n: "Taekyung BK", c: "Korea" },
  { id: 21170, s: "300895.SZ", n: "Beijing Topnew Info And Tech ", c: "CH" },
  { id: 21171, s: "014530.KS", n: "Kukdong Oil &", c: "Korea" },
  { id: 21172, s: "300897.SZ", n: "Hangzhou Seck Intelligent Tech", c: "CH" },
  { id: 21173, s: "300898.SZ", n: "Panda Dairy Co", c: "CH" },
  { id: 21174, s: "300899.SZ", n: "Keysino Separation Technology Inc", c: "CH" },
  { id: 21175, s: "014710.KS", n: "Sajo Seafood", c: "Korea" },
  { id: 21176, s: "300881.SZ", n: "Shengtak New Material ", c: "CH" },
  { id: 21177, s: "300879.SZ", n: "Ningbo Daye Garden Machinery ", c: "CH" },
  { id: 21178, s: "014915.KS", n: "Sungmoon Electronics", c: "Korea" },
  { id: 21179, s: "300857.SZ", n: "Sharetronic Data Technology ", c: "CH" },
  { id: 21180, s: "300859.SZ", n: "Western Regions Tourism Development", c: "CH" },
  { id: 21181, s: "300860.SZ", n: "Beijing Fengshangshiji Culture Medi", c: "CH" },
  { id: 21182, s: "300861.SZ", n: "Yangling Metron New Material", c: "CH" },
  { id: 21183, s: "300862.SZ", n: "Anhui Landun Photoelectron ", c: "CH" },
  { id: 21184, s: "300892.SZ", n: "Pinlive Foods ", c: "CH" },
  { id: 21185, s: "300890.SZ", n: "Shenzhen Xfh Technology ", c: "CH" },
  { id: 21186, s: "300882.SZ", n: "Zhejiang Wellsun Intelligent Tech", c: "CH" },
  { id: 21187, s: "016610.KS", n: "DB Financial Investment", c: "Korea" },
  { id: 21188, s: "300822.SZ", n: "Shenzhen Bestek Technology", c: "CH" },
  { id: 21189, s: "300823.SZ", n: "Tjk Machinery Tianjin ", c: "CH" },
  { id: 21190, s: "300824.SZ", n: "Shenzhen Crastal Technology ", c: "CH" },
  { id: 21191, s: "300825.SZ", n: "Iat Automobile Technology ", c: "CH" },
  { id: 21192, s: "016740.KS", n: "Dual", c: "Korea" },
  { id: 21193, s: "300801.SZ", n: "Taihe Technologies", c: "CH" },
  { id: 21194, s: "300853.SZ", n: "Hangzhou Shenhao Technology", c: "CH" },
  { id: 21195, s: "300889.SZ", n: "Shenzhen Exc Led Technology ", c: "CH" },
  { id: 21196, s: "300883.SZ", n: "Ld Intelligent Technology ", c: "CH" },
  { id: 21197, s: "300884.SZ", n: "Dnake Xiamen Intelligent Technology", c: "CH" },
  { id: 21198, s: "300885.SZ", n: "Yangzhou Seashine New Materials ", c: "CH" },
  { id: 21199, s: "300886.SZ", n: "Anhui Hyea Aromas ", c: "CH" },
  { id: 21200, s: "014825.KS", n: "Dongwon Systems Co", c: "Korea" },
  { id: 21201, s: "300888.SZ", n: "Winner Medical ", c: "CH" },
  { id: 21202, s: "300863.SZ", n: "Ningbo Kbe Electrical Technology", c: "CH" },
  { id: 21203, s: "300865.SZ", n: "Chengdu Dahongli Machinery ", c: "CH" },
  { id: 21204, s: "PCLI", n: "Protocall Technologs", c: "US" },
  { id: 21205, s: "300736.SZ", n: "BYBON Group Co Class A", c: "CH" },
  { id: 21206, s: "300729.SZ", n: "Loctek Ergonomic Technology Corp Class A", c: "CH" },
  { id: 21207, s: "032560.KS", n: "Hwang-Kum Stee", c: "Korea" },
  { id: 21208, s: "300730.SZ", n: "Hunan Creator Information Technologies Co Class A", c: "CH" },
  { id: 21209, s: "030210.KS", n: "Daol Investment & Securities", c: "Korea" },
  { id: 21210, s: "029530.KS", n: "Sindoh", c: "Korea" },
  { id: 21211, s: "300735.SZ", n: "DBG Technology Co Class A", c: "CH" },
  { id: 21212, s: "300737.SZ", n: "Keshun Waterproof Technologies Co Class A", c: "CH" },
  { id: 21213, s: "031430.KS", n: "Shinsegae Inte", c: "Korea" },
  { id: 21214, s: "300739.SZ", n: "Sunshine Global Circuits Co Class A", c: "CH" },
  { id: 21215, s: "300740.SZ", n: "SYoung Group", c: "CH" },
  { id: 21216, s: "300731.SZ", n: "Shenzhen Cotran New Material Co Class A", c: "CH" },
  { id: 21217, s: "300720.SZ", n: "Guangdong High Dream Intellectualized Machinery Co Class A", c: "CH" },
  { id: 21218, s: "300718.SZ", n: "Zhejiang Changsheng Sliding Bearings Co Class A", c: "CH" },
  { id: 21219, s: "300717.SZ", n: "Jiangsu Huaxin New Material Co Class A", c: "CH" },
  { id: 21220, s: "030610.KS", n: "Kyobo Securiti", c: "Korea" },
  { id: 21221, s: "300725.SZ", n: "PharmaBlock Sciences Nanjing Inc Class A", c: "CH" },
  { id: 21222, s: "300867.SZ", n: "Shengyuan Environmental Protection", c: "CH" },
  { id: 21223, s: "300876.SZ", n: "Guangdong Modern High Tech Fiber", c: "CH" },
  { id: 21224, s: "300868.SZ", n: "Shenzhen Jame Technology Co", c: "CH" },
  { id: 21225, s: "300869.SZ", n: "Contec Medical Systems ", c: "CH" },
  { id: 21226, s: "015590.KS", n: "Curo", c: "Korea" },
  { id: 21227, s: "015360.KS", n: "Yesco Holdings Co.", c: "Korea" },
  { id: 21228, s: "300872.SZ", n: "Tansun Technology ", c: "CH" },
  { id: 21229, s: "300873.SZ", n: "Hichain Logistics ", c: "CH" },
  { id: 21230, s: "014990.KS", n: "In The F", c: "Korea" },
  { id: 21231, s: "031440.KS", n: "Shinsegae Food", c: "Korea" },
  { id: 21232, s: "300852.SZ", n: "Sihui Fuji Electronics Technology", c: "CH" },
  { id: 21233, s: "300692.SZ", n: "Anhui Zhonghuan Environmental Protection Technology", c: "CH" },
  { id: 21234, s: "300800.SZ", n: "Lihe Technology", c: "CH" },
  { id: 21235, s: "300797.SZ", n: "Ncs Testing Technology", c: "CH" },
  { id: 21236, s: "300722.SZ", n: "Jiangxi Xinyu Guoke Technology Co Class A", c: "CH" },
  { id: 21237, s: "300723.SZ", n: "Yipinhong Pharmaceutical Co Class A", c: "CH" },
  { id: 21238, s: "017810.KS", n: "Pulmuone Holdi", c: "Korea" },
  { id: 21239, s: "018250.KS", n: "Aekyung Industrial", c: "Korea" },
  { id: 21240, s: "300538.SZ", n: "Shenzhen Tongyi Industry", c: "CH" },
  { id: 21241, s: "300816.SZ", n: "Actblue ", c: "CH" },
  { id: 21242, s: "300561.SZ", n: "SGSG Science&technology Co Zhuhai", c: "CH" },
  { id: 21243, s: "084670.KS", n: "Dongyang Expre", c: "Korea" },
  { id: 21244, s: "300540.SZ", n: "Sichuan Shudao Equipment & Technology", c: "CH" },
  { id: 21245, s: "300541.SZ", n: "Beijing Advanced Digital Technology", c: "CH" },
  { id: 21246, s: "300542.SZ", n: "Brilliance Technology", c: "CH" },
  { id: 21247, s: "300543.SZ", n: "Shenzhen Longood Intelligent Electric Co Class A", c: "CH" },
  { id: 21248, s: "300545.SZ", n: "Shenzhen Liande Automatic Equipment", c: "CH" },
  { id: 21249, s: "089470.KS", n: "Hyundai E P", c: "Korea" },
  { id: 21250, s: "088790.KS", n: "Jindo.Co.", c: "Korea" },
  { id: 21251, s: "300550.SZ", n: "Heren Health", c: "CH" },
  { id: 21252, s: "300551.SZ", n: "Shanghai GuAo Electronic Technology", c: "CH" },
  { id: 21253, s: "085620.KS", n: "Mirae Asset Life Insurance", c: "Korea" },
  { id: 21254, s: "300553.SZ", n: "Hangzhou Jizhi Mechatronic", c: "CH" },
  { id: 21255, s: "300554.SZ", n: "NanJing Sanchao Advanced Materials", c: "CH" },
  { id: 21256, s: "300555.SZ", n: "Lootom Telcovideo Network Wuxi", c: "CH" },
  { id: 21257, s: "300562.SZ", n: "Guangdong Transtek Medical Electronics", c: "CH" },
  { id: 21258, s: "079160.KS", n: "CJ CGV", c: "Korea" },
  { id: 21259, s: "300582.SZ", n: "Inventronics Hangzhou Inc", c: "CH" },
  { id: 21260, s: "079980.KS", n: "Huvis Corporation", c: "Korea" },
  { id: 21261, s: "300564.SZ", n: "Zhubo Design", c: "CH" },
  { id: 21262, s: "083420.KS", n: "Kpx Green Chem", c: "Korea" },
  { id: 21263, s: "300566.SZ", n: "Ningbo Exciton Technology", c: "CH" },
  { id: 21264, s: "300567.SZ", n: "Wuhan Jingce Electronic Technology", c: "CH" },
  { id: 21265, s: "300568.SZ", n: "Shenzhen Senior Technology Material", c: "CH" },
  { id: 21266, s: "081000.KS", n: "Iljin Diamond", c: "Korea" },
  { id: 21267, s: "084010.KS", n: "Daehan Steel", c: "Korea" },
  { id: 21268, s: "075180.KS", n: "Saeron Auto", c: "Korea" },
  { id: 21269, s: "079430.KS", n: "Hyundai Livart Furniture", c: "Korea" },
  { id: 21270, s: "077970.KS", n: "Stx Engine", c: "Korea" },
  { id: 21271, s: "300577.SZ", n: "Anhui Korrun", c: "CH" },
  { id: 21272, s: "300578.SZ", n: "BizConf Telecom", c: "CH" },
  { id: 21273, s: "300579.SZ", n: "BeiJing Certificate Authority", c: "CH" },
  { id: 21274, s: "075580.KS", n: "Sejin Heavy Industries", c: "Korea" },
  { id: 21275, s: "300556.SZ", n: "Silkroad Visual Technology", c: "CH" },
  { id: 21276, s: "084695.KS", n: "Daesang Holdings Co Pref", c: "Korea" },
  { id: 21277, s: "300559.SZ", n: "Chengdu Jafaantai Technology", c: "CH" },
  { id: 21278, s: "023000.KS", n: "Samwon Steel", c: "Korea" },
  { id: 21279, s: "300848.SZ", n: "Miracll Chemicals", c: "CH" },
  { id: 21280, s: "016880.KS", n: "Woongjin", c: "Korea" },
  { id: 21281, s: "300828.SZ", n: "Tianjin Ruixin Technology", c: "CH" },
  { id: 21282, s: "300827.SZ", n: "Sineng Electric ", c: "CH" },
  { id: 21283, s: "300826.SZ", n: "Nanjing Res In of Surveying Mapping", c: "CH" },
  { id: 21284, s: "023150.KS", n: "Mhethanol Co..", c: "Korea" },
  { id: 21285, s: "300806.SZ", n: "Jiangsu Sidike New Materials Sci Te", c: "CH" },
  { id: 21286, s: "016800.KS", n: "Fursys", c: "Korea" },
  { id: 21287, s: "300807.SZ", n: "Zhengzhou Tiamaes Technology", c: "CH" },
  { id: 21288, s: "020760.KS", n: "Iljin Display", c: "Korea" },
  { id: 21289, s: "300809.SZ", n: "Hiecise Precision Equipment ", c: "CH" },
  { id: 21290, s: "019685.KS", n: "Daekyo", c: "Korea" },
  { id: 21291, s: "300812.SZ", n: "Shenzhen Etmade Automatic Equipment", c: "CH" },
  { id: 21292, s: "300815.SZ", n: "Eit Environmental Development Group", c: "CH" },
  { id: 21293, s: "300847.SZ", n: "Hg Technologies ", c: "CH" },
  { id: 21294, s: "300845.SZ", n: "Zhengzhou J and T Hi Tech", c: "CH" },
  { id: 21295, s: "300537.SZ", n: "Jiangsu Kuangshun Photosensitivity New-Material Stock", c: "CH" },
  { id: 21296, s: "300835.SZ", n: "Sinomag Technology ", c: "CH" },
  { id: 21297, s: "215480.KQ", n: "Daewoo SBI SPAC 1", c: "Korea" },
  { id: 21298, s: "300691.SZ", n: "Union Optech", c: "CH" },
  { id: 21299, s: "03473K.KS", n: "SK Holdings 1p Pref", c: "Korea" },
  { id: 21300, s: "300830.SZ", n: "Jinxiandai Information Industry ", c: "CH" },
  { id: 21301, s: "017180.KS", n: "Myungmoon Phar", c: "Korea" },
  { id: 21302, s: "017040.KS", n: "Kwang Myung El", c: "Korea" },
  { id: 21303, s: "300836.SZ", n: "Kunshan Top A Intelligent Equipment", c: "CH" },
  { id: 21304, s: "300843.SZ", n: "Shenglan Technology ", c: "CH" },
  { id: 21305, s: "300837.SZ", n: "Zhejiang Zhe Kuang Heavy Industry ", c: "CH" },
  { id: 21306, s: "300838.SZ", n: "Zhejiang Linuo Flow Control Techno", c: "CH" },
  { id: 21307, s: "300829.SZ", n: "Henan Jindan Lactic Acid Technology", c: "CH" },
  { id: 21308, s: "300839.SZ", n: "Ningbo Bohui Chemical Technology", c: "CH" },
  { id: 21309, s: "300841.SZ", n: "Chengdu Kanghua Biological Products", c: "CH" },
  { id: 21310, s: "300842.SZ", n: "Wuxi Dk Electronic Materials ", c: "CH" },
  { id: 21311, s: "000885.KS", n: "Hanwha Corp. Pfd. Series 1", c: "Korea" },
  { id: 21312, s: "000890.KS", n: "Bohae Brewery", c: "Korea" },
  { id: 21313, s: "000950.KS", n: "Chonbang", c: "Korea" },
  { id: 21314, s: "004140.KS", n: "Dongbang Trans", c: "Korea" },
  { id: 21315, s: "010100.KS", n: "Korea Flange", c: "Korea" },
  { id: 21316, s: "009810.KS", n: "Playgram Co.", c: "Korea" },
  { id: 21317, s: "UMCN", n: "UMC Inc", c: "US" },
  { id: 21318, s: "5GG.AX", n: "Pentanet", c: "Australia" },
  { id: 21319, s: "012205.KS", n: "Keyang Electric Machinery", c: "Korea" },
  { id: 21320, s: "300957.SZ", n: "Yunnan Botanee Bio-Technology Group", c: "CH" },
  { id: 21321, s: "300975.SZ", n: "Nanjing Sunlord Electronics Corporation", c: "CH" },
  { id: 21322, s: "301027.SZ", n: "Hualan Group", c: "CH" },
  { id: 21323, s: "301058.SZ", n: "COFCO Engineering & Technology", c: "CH" },
  { id: 21324, s: "013700.KS", n: "Camus Engineering & Construction Inc", c: "Korea" },
  { id: 21325, s: "013580.KS", n: "Kyeryong Const", c: "Korea" },
  { id: 21326, s: "1AE.AX", n: "Aurora Energy Metals", c: "Australia" },
  { id: 21327, s: "013570.KS", n: "DY Corporation", c: "Korea" },
  { id: 21328, s: "013520.KS", n: "Hwaseung Corporation", c: "Korea" },
  { id: 21329, s: "013360.KS", n: "Il Sung Const", c: "Korea" },
  { id: 21330, s: "010420.KS", n: "Hansol Pns", c: "Korea" },
  { id: 21331, s: "010600.KS", n: "Wellbiotec Co..", c: "Korea" },
  { id: 21332, s: "010770.KS", n: "Pyung Hwa Hldg", c: "Korea" },
  { id: 21333, s: "011330.KS", n: "Uni Chem", c: "Korea" },
  { id: 21334, s: "004090.KS", n: "Korea Petroleum Industries", c: "Korea" },
  { id: 21335, s: "006200.KS", n: "Kec Holdings", c: "Korea" },
  { id: 21336, s: "006220.KS", n: "Jeju Bank", c: "Korea" },
  { id: 21337, s: "006280.KS", n: "Green Cross Co", c: "Korea" },
  { id: 21338, s: "006345.KS", n: "Daewon Cable Co.. Preference Shares", c: "Korea" },
  { id: 21339, s: "011420.KS", n: "Galaxia SM Inc", c: "Korea" },
  { id: 21340, s: "011280.KS", n: "Tailim Packaging", c: "Korea" },
  { id: 21341, s: "010820.KS", n: "Firstec", c: "Korea" },
  { id: 21342, s: "PARNF", n: "Parnell Pharmaceuticals Holdings", c: "US" },
  { id: 21343, s: "011155.KS", n: "CJ Seafood Corp. Pfd. Series 1", c: "Korea" },
  { id: 21344, s: "011150.KS", n: "Cj Seafood", c: "Korea" },
  { id: 21345, s: "ULTRF", n: "Ultrapetrol Bahamas", c: "US" },
  { id: 21346, s: "011700.KS", n: "Han Shin Mach", c: "Korea" },
  { id: 21347, s: "010960.KS", n: "Samho Dev", c: "Korea" },
  { id: 21348, s: "012800.KS", n: "Dae Chang", c: "Korea" },
  { id: 21349, s: "1TT.AX", n: "Thrive Tribe Technologies.", c: "Australia" },
  { id: 21350, s: "PCHM", n: "PharmChem Inc", c: "US" },
  { id: 21351, s: "008355.KS", n: "Namsun Aluminium", c: "Korea" },
  { id: 21352, s: "006980.KS", n: "Woosung Feed", c: "Korea" },
  { id: 21353, s: "006880.KS", n: "Singsongholdin", c: "Korea" },
  { id: 21354, s: "006805.KS", n: "Mirae Asset Daewoo", c: "Korea" },
  { id: 21355, s: "UOLGY", n: "UOL Group ADR", c: "US" },
  { id: 21356, s: "006370.KS", n: "Daegu Dept Sto", c: "Korea" },
  { id: 21357, s: "007160.KS", n: "Sajo Ind", c: "Korea" },
  { id: 21358, s: "008420.KS", n: "Moonbae Steel", c: "Korea" },
  { id: 21359, s: "007110.KS", n: "Ilshinstone", c: "Korea" },
  { id: 21360, s: "008600.KS", n: "Willbes", c: "Korea" },
  { id: 21361, s: "009460.KS", n: "Han Chang Pape", c: "Korea" },
  { id: 21362, s: "009410.KS", n: "Taeyoung Const", c: "Korea" },
  { id: 21363, s: "009310.KS", n: "Charm Engine", c: "Korea" },
  { id: 21364, s: "OUKPY", n: "Metso Outotec Corp OTC", c: "US" },
  { id: 21365, s: "009270.KS", n: "Shinwon", c: "Korea" },
  { id: 21366, s: "OMID", n: "Omid Holdings Inc", c: "US" },
  { id: 21367, s: "007210.KS", n: "Byuksan Co", c: "Korea" },
  { id: 21368, s: "012690.KS", n: "Monalisa", c: "Korea" },
  { id: 21369, s: "009470.KS", n: "Samwha Electri", c: "Korea" },
  { id: 21370, s: "012610.KS", n: "Kyungin Synthe", c: "Korea" },
  { id: 21371, s: "UCPA", n: "United Communications Partners Inc", c: "US" },
  { id: 21372, s: "012280.KS", n: "Yeong Hwa Meta", c: "Korea" },
  { id: 21373, s: "009770.KS", n: "Sam Jung Pulp", c: "Korea" },
  { id: 21374, s: "003480.KS", n: "Hanjin Heavy Ind & Const Holdings", c: "Korea" },
  { id: 21375, s: "ADEF.AX", n: "Apostle Dundas Global Equity Fund - Class D Units (Managed Fund)", c: "Australia" },
  { id: 21376, s: "AGX1.AX", n: "Antipodes Global Shares", c: "Australia" },
  { id: 21377, s: "008260.KS", n: "Ni Steel", c: "Korea" },
  { id: 21378, s: "008250.KS", n: "Eagon Ind", c: "Korea" },
  { id: 21379, s: "008040.KS", n: "Sajodongaone", c: "Korea" },
  { id: 21380, s: "007860.KS", n: "Seoyon", c: "Korea" },
  { id: 21381, s: "007570.KS", n: "Ilyang Pharm", c: "Korea" },
  { id: 21382, s: "007540.KS", n: "Sempio Foods", c: "Korea" },
  { id: 21383, s: "007280.KS", n: "Korea Steel", c: "Korea" },
  { id: 21384, s: "004100.KS", n: "Taeyang Metal", c: "Korea" },
  { id: 21385, s: "004250.KS", n: "NPC Co.", c: "Korea" },
  { id: 21386, s: "001020.KS", n: "Papercorea", c: "Korea" },
  { id: 21387, s: "004255.KS", n: "National Plastic Co Preferred", c: "Korea" },
  { id: 21388, s: "005500.KS", n: "Samjin Pharm", c: "Korea" },
  { id: 21389, s: "AMM.AX", n: "Armada Metals", c: "Australia" },
  { id: 21390, s: "006125.KS", n: "SK Discovery", c: "Korea" },
  { id: 21391, s: "006120.KS", n: "SK Discovery", c: "Korea" },
  { id: 21392, s: "006090.KS", n: "Sajo Oyang", c: "Korea" },
  { id: 21393, s: "005965.KS", n: "Dongbu Co", c: "Korea" },
  { id: 21394, s: "005960.KS", n: "Dongbu", c: "Korea" },
  { id: 21395, s: "005945.KS", n: "Nh Investment And Securities 1p", c: "Korea" },
  { id: 21396, s: "005870.KS", n: "Huneed Tech", c: "Korea" },
  { id: 21397, s: "005690.KS", n: "Pharmicell", c: "Korea" },
  { id: 21398, s: "005850.KS", n: "SL Co", c: "Korea" },
  { id: 21399, s: "005810.KS", n: "Poongsan Holdi", c: "Korea" },
  { id: 21400, s: "005800.KS", n: "Shinyoung Wa", c: "Korea" },
  { id: 21401, s: "005745.KS", n: "CrownHaitai Holdings Co Preference Shares", c: "Korea" },
  { id: 21402, s: "005740.KS", n: "Crown Confec", c: "Korea" },
  { id: 21403, s: "004770.KS", n: "Sunny Elec", c: "Korea" },
  { id: 21404, s: "004970.KS", n: "Silla Co.Ltd", c: "Korea" },
  { id: 21405, s: "UVSE", n: "Universal Energy Co", c: "US" },
  { id: 21406, s: "004985.KS", n: "Sungshin Cement", c: "Korea" },
  { id: 21407, s: "002936.SZ", n: "Bank of Zhengzhou Co Class A", c: "CH" },
  { id: 21408, s: "005390.KS", n: "Shinsung Tngsn", c: "Korea" },
  { id: 21409, s: "005387.KS", n: "Hyundai Motor Co Pref", c: "Korea" },
  { id: 21410, s: "005360.KS", n: "Monami", c: "Korea" },
  { id: 21411, s: "005320.KS", n: "Kuk Dong", c: "Korea" },
  { id: 21412, s: "005110.KS", n: "Hanchang", c: "Korea" },
  { id: 21413, s: "005440.KS", n: "Hyundai Green Food", c: "Korea" },
  { id: 21414, s: "004830.KS", n: "Duksung", c: "Korea" },
  { id: 21415, s: "004980.KS", n: "Sungshin Cemen", c: "Korea" },
  { id: 21416, s: "004960.KS", n: "Hanshin Const", c: "Korea" },
  { id: 21417, s: "004910.KS", n: "Cho Kwang Pain", c: "Korea" },
  { id: 21418, s: "004870.KS", n: "TWay Holdings", c: "Korea" },
  { id: 21419, s: "004840.KS", n: "Drb Holding", c: "Korea" },
  { id: 21420, s: "004835.KS", n: "Duksung", c: "Korea" },
  { id: 21421, s: "005725.KS", n: "Nexen Co", c: "Korea" },
  { id: 21422, s: "NYRSY", n: "Nyrstar NV ADR", c: "US" },
  { id: 21423, s: "005720.KS", n: "Nexen Corporation", c: "Korea" },
  { id: 21424, s: "004440.KS", n: "Samil C&S", c: "Korea" },
  { id: 21425, s: "VEMLY", n: "Venture Corporation ADR", c: "US" },
  { id: 21426, s: "003555.KS", n: "LG Corp Preferred", c: "Korea" },
  { id: 21427, s: "004080.KS", n: "Shinhung", c: "Korea" },
  { id: 21428, s: "004545.KS", n: "Kleannara", c: "Korea" },
  { id: 21429, s: "004490.KS", n: "Sebang Global Battery", c: "Korea" },
  { id: 21430, s: "004450.KS", n: "Samhwa Crown", c: "Korea" },
  { id: 21431, s: "NUVI", n: "Emo Capital Co", c: "US" },
  { id: 21432, s: "003520.KS", n: "Yungjin Pharm", c: "Korea" },
  { id: 21433, s: "004415.KS", n: "Seoul Food Ind", c: "Korea" },
  { id: 21434, s: "004410.KS", n: "Seoul Food", c: "Korea" },
  { id: 21435, s: "004380.KS", n: "Samick Thk", c: "Korea" },
  { id: 21436, s: "004365.KS", n: "Sebang", c: "Korea" },
  { id: 21437, s: "004360.KS", n: "Sebang", c: "Korea" },
  { id: 21438, s: "004270.KS", n: "Nam Sung", c: "Korea" },
  { id: 21439, s: "003495.KS", n: "Korean Air Lines", c: "Korea" },
  { id: 21440, s: "003535.KS", n: "Hanwha Investment&Securities Co Pref", c: "Korea" },
  { id: 21441, s: "005820.KS", n: "Wonlim", c: "Korea" },
  { id: 21442, s: "003830.KS", n: "Daehan Synthet", c: "Korea" },
  { id: 21443, s: "004720.KS", n: "PharmGen Science Inc", c: "Korea" },
  { id: 21444, s: "004710.KS", n: "Hansol Technics", c: "Korea" },
  { id: 21445, s: "004700.KS", n: "Cho Kwang Leat", c: "Korea" },
  { id: 21446, s: "003960.KS", n: "Sajodaerim", c: "Korea" },
  { id: 21447, s: "003925.KS", n: "Namyang Dairy Products Co Peffered Series 1", c: "Korea" },
  { id: 21448, s: "003920.KS", n: "Namyang Dairy", c: "Korea" },
  { id: 21449, s: "NRPI", n: "NRP Stone Inc", c: "US" },
  { id: 21450, s: "003540.KS", n: "Daishin Sec", c: "Korea" },
  { id: 21451, s: "003680.KS", n: "Hansung Ent", c: "Korea" },
  { id: 21452, s: "003580.KS", n: "HLB Global", c: "Korea" },
  { id: 21453, s: "004060.KS", n: "SG Corporation", c: "Korea" },
  { id: 21454, s: "003570.KS", n: "SNT Dynamics", c: "Korea" },
  { id: 21455, s: "003547.KS", n: "Daishin Securities Co Pref 2", c: "Korea" },
  { id: 21456, s: "003545.KS", n: "Daishin Securities Co Pref", c: "Korea" },
  { id: 21457, s: "009240.KS", n: "Hanssem", c: "Korea" },
  { id: 21458, s: "009200.KS", n: "Moorim Paper", c: "Korea" },
  { id: 21459, s: "009190.KS", n: "Daiyang Metal", c: "Korea" },
  { id: 21460, s: "009180.KS", n: "Hansol Csn", c: "Korea" },
  { id: 21461, s: "002760.KS", n: "Bo Lak", c: "Korea" },
  { id: 21462, s: "002700.KS", n: "Shinil Electronics", c: "Korea" },
  { id: 21463, s: "002690.KS", n: "Dong Il Steel MFG", c: "Korea" },
  { id: 21464, s: "002630.KS", n: "ORIENTBIO Inc.", c: "Korea" },
  { id: 21465, s: "002600.KS", n: "Choheung", c: "Korea" },
  { id: 21466, s: "002460.KS", n: "Hwa Sung Ind", c: "Korea" },
  { id: 21467, s: "002450.KS", n: "Samick Music I", c: "Korea" },
  { id: 21468, s: "002780.KS", n: "Chin Hung Int'", c: "Korea" },
  { id: 21469, s: "002410.KS", n: "Bumyang Construction", c: "Korea" },
  { id: 21470, s: "002380.KS", n: "KCC Corporation", c: "Korea" },
  { id: 21471, s: "002360.KS", n: "Sh E & C", c: "Korea" },
  { id: 21472, s: "002355.KS", n: "Nexen Tire Prf 1", c: "Korea" },
  { id: 21473, s: "002320.KS", n: "Hanjin Trans", c: "Korea" },
  { id: 21474, s: "002310.KS", n: "Asia Paper", c: "Korea" },
  { id: 21475, s: "002210.KS", n: "Dongsung Pharm", c: "Korea" },
  { id: 21476, s: "NAUBF", n: "National Australia Bank", c: "US" },
  { id: 21477, s: "VIRA", n: "Viratech Co", c: "US" },
  { id: 21478, s: "300906.SZ", n: "Jiangxi Everbright Measurement Cont", c: "CH" },
  { id: 21479, s: "259630.KQ", n: "mPLUS Co", c: "Korea" },
  { id: 21480, s: "263810.KQ", n: "Sangshin Electronics Co.", c: "Korea" },
  { id: 21481, s: "263690.KQ", n: "DRGEM Corporation", c: "Korea" },
  { id: 21482, s: "263050.KQ", n: "Eutilex.Co.Ltd", c: "Korea" },
  { id: 21483, s: "263020.KQ", n: "DK&D Co.Ltd", c: "Korea" },
  { id: 21484, s: "260930.KQ", n: "CTK Co.", c: "Korea" },
  { id: 21485, s: "MOBO", n: "Mobile Lads Co", c: "US" },
  { id: 21486, s: "256940.KQ", n: "KPS Corporation", c: "Korea" },
  { id: 21487, s: "267790.KQ", n: "Barrel Co.", c: "Korea" },
  { id: 21488, s: "255220.KQ", n: "SG Co.", c: "Korea" },
  { id: 21489, s: "253590.KQ", n: "Neosem Inc", c: "Korea" },
  { id: 21490, s: "250060.KQ", n: "Mobiis Co.", c: "Korea" },
  { id: 21491, s: "245620.KQ", n: "Eone Diagnomics Genome Center Co.", c: "Korea" },
  { id: 21492, s: "243070.KQ", n: "Huons Co.", c: "Korea" },
  { id: 21493, s: "241710.KQ", n: "Cosmecca Korea Co.", c: "Korea" },
  { id: 21494, s: "002170.KS", n: "Sam Yang T S", c: "Korea" },
  { id: 21495, s: "002390.KS", n: "Handok", c: "Korea" },
  { id: 21496, s: "002787.KS", n: "Chinhung International Inc. Pfd. Series 2", c: "Korea" },
  { id: 21497, s: "001260.KS", n: "Nam Kwang Cons", c: "Korea" },
  { id: 21498, s: "002150.KS", n: "Dohwa Engin", c: "Korea" },
  { id: 21499, s: "000725.KS", n: "Hyundai Engineering & Construction", c: "Korea" },
  { id: 21500, s: "002140.KS", n: "Korea Ind", c: "Korea" },
  { id: 21501, s: "002030.KS", n: "Asia Cement", c: "Korea" },
  { id: 21502, s: "001290.KS", n: "Sangsangin Investment & Securities", c: "Korea" },
  { id: 21503, s: "001275.KS", n: "Bookook Securities", c: "Korea" },
  { id: 21504, s: "001210.KS", n: "Kumho Electric", c: "Korea" },
  { id: 21505, s: "002900.KS", n: "TYM Corporation", c: "Korea" },
  { id: 21506, s: "001200.KS", n: "Eugene Inv&Sec", c: "Korea" },
  { id: 21507, s: "001130.KS", n: "Daehan Flour Mills", c: "Korea" },
  { id: 21508, s: "001080.KS", n: "Manho Rope&Wir", c: "Korea" },
  { id: 21509, s: "001360.KS", n: "Samsung Pharm", c: "Korea" },
  { id: 21510, s: "001067.KS", n: "JW Pharmaceutical Co", c: "Korea" },
  { id: 21511, s: "00104K.KS", n: "CJ Corp 4P Pref ", c: "Korea" },
  { id: 21512, s: "002870.KS", n: "Shin Poong Pap", c: "Korea" },
  { id: 21513, s: "002990.KS", n: "Kumho Ind", c: "Korea" },
  { id: 21514, s: "002795.KS", n: "Amorepacific Group", c: "Korea" },
  { id: 21515, s: "003120.KS", n: "Ilsung Pharm", c: "Korea" },
  { id: 21516, s: "002810.KS", n: "Sam Yung Tradi", c: "Korea" },
  { id: 21517, s: "003460.KS", n: "Yuhwa Sec", c: "Korea" },
  { id: 21518, s: "003350.KS", n: "Hankook Cosmetics Manufacturing", c: "Korea" },
  { id: 21519, s: "003300.KS", n: "Hanil Cement", c: "Korea" },
  { id: 21520, s: "003220.KS", n: "Daewon Pharm", c: "Korea" },
  { id: 21521, s: "003160.KS", n: "D I Co", c: "Korea" },
  { id: 21522, s: "003090.KS", n: "Daewoong", c: "Korea" },
  { id: 21523, s: "002995.KS", n: "Kumho Industrial", c: "Korea" },
  { id: 21524, s: "003080.KS", n: "Sung Bo Chem", c: "Korea" },
  { id: 21525, s: "003075.KS", n: "Kolon Global Co", c: "Korea" },
  { id: 21526, s: "003070.KS", n: "Kolon Global", c: "Korea" },
  { id: 21527, s: "003060.KS", n: "Aprogen Pharmaceuticals Inc", c: "Korea" },
  { id: 21528, s: "003010.KS", n: "Hae In", c: "Korea" },
  { id: 21529, s: "003000.KS", n: "Bukwang Pharm", c: "Korea" },
  { id: 21530, s: "241690.KQ", n: "UNITEKNO Co.Ltd", c: "Korea" },
  { id: 21531, s: "241520.KQ", n: "DSC Investment Inc", c: "Korea" },
  { id: 21532, s: "237880.KQ", n: "CLIO Cosmetics Co.Ltd", c: "Korea" },
  { id: 21533, s: "290550.KQ", n: "Dk Tech Co.Ltd", c: "Korea" },
  { id: 21534, s: "305090.KQ", n: "Micro Digital Co.", c: "Korea" },
  { id: 21535, s: "000020.KS", n: "Dongwha Pharm.Co.Ltd", c: "Korea" },
  { id: 21536, s: "303030.KQ", n: "Zinitix Co.Ltd", c: "Korea" },
  { id: 21537, s: "290740.KQ", n: "ActRO Co.", c: "Korea" },
  { id: 21538, s: "290670.KQ", n: "Daebo Magnetic Co.Ltd", c: "Korea" },
  { id: 21539, s: "290660.KQ", n: "Neofect Co.Ltd", c: "Korea" },
  { id: 21540, s: "289080.KQ", n: "SV Investment Corporation", c: "Korea" },
  { id: 21541, s: "307180.KQ", n: "IL Science Co.", c: "Korea" },
  { id: 21542, s: "288620.KQ", n: "S-Fuelcell co.", c: "Korea" },
  { id: 21543, s: "293580.KQ", n: "NAU IB Capital", c: "Korea" },
  { id: 21544, s: "C79.AX", n: "Chrysos Co", c: "Australia" },
  { id: 21545, s: "000050.KS", n: "Kyungbangco.Ltd", c: "Korea" },
  { id: 21546, s: "000070.KS", n: "Samyang Holdings", c: "Korea" },
  { id: 21547, s: "000545.KS", n: "Heungkuk Fire & Marine Insurance", c: "Korea" },
  { id: 21548, s: "306620.KQ", n: "Neontech Co.", c: "Korea" },
  { id: 21549, s: "CAM.AX", n: "Clime Capital", c: "Australia" },
  { id: 21550, s: "000520.KS", n: "Samil Pharm", c: "Korea" },
  { id: 21551, s: "008730.KS", n: "Youlchon Chem", c: "Korea" },
  { id: 21552, s: "009160.KS", n: "Simpac", c: "Korea" },
  { id: 21553, s: "009155.KS", n: "Samsung Electro Mechanics Pref", c: "Korea" },
  { id: 21554, s: "009140.KS", n: "Kyung In Elect", c: "Korea" },
  { id: 21555, s: "009070.KS", n: "KCTC Co.", c: "Korea" },
  { id: 21556, s: "008970.KS", n: "Dong Yang Pipe", c: "Korea" },
  { id: 21557, s: "008775.KS", n: "Hotel Shilla", c: "Korea" },
  { id: 21558, s: "ORBN", n: "Oregon Bancorp Inc", c: "US" },
  { id: 21559, s: "317120.KQ", n: "Ranix INC", c: "Korea" },
  { id: 21560, s: "009580.KS", n: "Moorim P&P", c: "Korea" },
  { id: 21561, s: "003475.KS", n: "Yuanta Securities Korea Co Preference Shares", c: "Korea" },
  { id: 21562, s: "003470.KS", n: "Yuanta Sec Kor", c: "Korea" },
  { id: 21563, s: "003465.KS", n: "Yuhwa Securities", c: "Korea" },
  { id: 21564, s: "950170.KQ", n: "JTC Inc", c: "Korea" },
  { id: 21565, s: "950140.KQ", n: "Englewood Lab Inc", c: "Korea" },
  { id: 21566, s: "000540.KS", n: "Heungkuk F&M I", c: "Korea" },
  { id: 21567, s: "000500.KS", n: "Gaon Cable", c: "Korea" },
  { id: 21568, s: "222040.KQ", n: "Cosmax Nbt Inc", c: "Korea" },
  { id: 21569, s: "222110.KQ", n: "PanGen Biotech Inc", c: "Korea" },
  { id: 21570, s: "226340.KQ", n: "HMCIB SPAC 3", c: "Korea" },
  { id: 21571, s: "225530.KQ", n: "Bokwang Industry", c: "Korea" },
  { id: 21572, s: "CMO.AX", n: "Cosmo Metals", c: "Australia" },
  { id: 21573, s: "222980.KQ", n: "Korea Mcnulty", c: "Korea" },
  { id: 21574, s: "222810.KQ", n: "Kyobo 4 Special Purpose Acq", c: "Korea" },
  { id: 21575, s: "230980.KQ", n: "AIIT ONE Co..", c: "Korea" },
  { id: 21576, s: "MITFY", n: "Mitie Group Plc ADR", c: "US" },
  { id: 21577, s: "230360.KQ", n: "Echomarketing Co.Ltd", c: "Korea" },
  { id: 21578, s: "220100.KQ", n: "FutureChem Co.Ltd", c: "Korea" },
  { id: 21579, s: "219130.KQ", n: "TigerElec Co.", c: "Korea" },
  { id: 21580, s: "217820.KQ", n: "NS Co.", c: "Korea" },
  { id: 21581, s: "217620.KQ", n: "Didim Inc", c: "Korea" },
  { id: 21582, s: "217500.KQ", n: "HI SPAC 3", c: "Korea" },
  { id: 21583, s: "215790.KQ", n: "NH SPAC 5", c: "Korea" },
  { id: 21584, s: "228340.KQ", n: "Tongyang Pile Inc", c: "Korea" },
  { id: 21585, s: "265520.KQ", n: "Advanced Process Systems Corporation", c: "Korea" },
  { id: 21586, s: "000370.KS", n: "Hanwha General", c: "Korea" },
  { id: 21587, s: "000180.KS", n: "Sungchang Hold", c: "Korea" },
  { id: 21588, s: "000300.KS", n: "Dayou Plus", c: "Korea" },
  { id: 21589, s: "000240.KS", n: "Hankook Tire Worldwide", c: "Korea" },
  { id: 21590, s: "000227.KS", n: "Yuyu Pharma Inc", c: "Korea" },
  { id: 21591, s: "000225.KS", n: "Yuyu Pharma Inc", c: "Korea" },
  { id: 21592, s: "000220.KS", n: "Yuyu Pharma", c: "Korea" },
  { id: 21593, s: "000215.KS", n: "DL Holdings Co Preferred", c: "Korea" },
  { id: 21594, s: "000157.KS", n: "Doosan", c: "Korea" },
  { id: 21595, s: "270870.KQ", n: "Newtree Co.Ltd", c: "Korea" },
  { id: 21596, s: "MTTRY", n: "Ceconomy AG ADR", c: "US" },
  { id: 21597, s: "000145.KS", n: "HiteJinro Holdings", c: "Korea" },
  { id: 21598, s: "000140.KS", n: "Hitejinro Holdings", c: "Korea" },
  { id: 21599, s: "000087.KS", n: "HiteJinro", c: "Korea" },
  { id: 21600, s: "284620.KQ", n: "Kainos Medicine Inc", c: "Korea" },
  { id: 21601, s: "000680.KS", n: "Ls Networks", c: "Korea" },
  { id: 21602, s: "300695.SZ", n: "Zhejiang Zhaofeng Mechanical and Electronic", c: "CH" },
  { id: 21603, s: "300699.SZ", n: "Weihai Guangwei Composites", c: "CH" },
  { id: 21604, s: "053610.KQ", n: "Protec Co.", c: "Korea" },
  { id: 21605, s: "001840.KQ", n: "EE-HWA Construction Co.", c: "Korea" },
  { id: 21606, s: "119850.KQ", n: "GnCenergy", c: "Korea" },
  { id: 21607, s: "114450.KQ", n: "KPX Lifescience.Co.Ltd", c: "Korea" },
  { id: 21608, s: "111710.KQ", n: "Namhwa Industrial Co.", c: "Korea" },
  { id: 21609, s: "110790.KQ", n: "Creas F&C Co.Ltd", c: "Korea" },
  { id: 21610, s: "110020.KQ", n: "Jeonjinbio Co.", c: "Korea" },
  { id: 21611, s: "109820.KQ", n: "GeneMatrix Inc", c: "Korea" },
  { id: 21612, s: "EPX.AX", n: "EP&T Global", c: "Australia" },
  { id: 21613, s: "109740.KQ", n: "DSK Co.", c: "Korea" },
  { id: 21614, s: "108860.KQ", n: "Selvas Ai Inc.", c: "Korea" },
  { id: 21615, s: "113810.KQ", n: "Dgenx Co.", c: "Korea" },
  { id: 21616, s: "121440.KQ", n: "GOLFZON NEWDIN HOLDINGS Co.", c: "Korea" },
  { id: 21617, s: "122310.KQ", n: "Genoray Co.", c: "Korea" },
  { id: 21618, s: "LNXSF", n: "Lanxess AG", c: "US" },
  { id: 21619, s: "131100.KQ", n: "SKY E&M Co.", c: "Korea" },
  { id: 21620, s: "130740.KQ", n: "TPC Co.", c: "Korea" },
  { id: 21621, s: "114630.KQ", n: "Uno&Company.Ltd", c: "Korea" },
  { id: 21622, s: "114810.KQ", n: "Iones Co.", c: "Korea" },
  { id: 21623, s: "LLESY", n: "Lend Lease Group", c: "US" },
  { id: 21624, s: "WWIO", n: "Wowio Inc", c: "US" },
  { id: 21625, s: "001000.KQ", n: "Silla Textile Co.Ltd", c: "Korea" },
  { id: 21626, s: "000440.KQ", n: "Joong Ang Enervis Co.", c: "Korea" },
  { id: 21627, s: "HFBA", n: "HFB Financial Co", c: "US" },
  { id: 21628, s: "WILYY", n: "Demant A/S ADR", c: "US" },
  { id: 21629, s: "WLWHY", n: "Woolworths Holdings PK", c: "US" },
  { id: 21630, s: "WRIT", n: "WRIT Media Group Inc", c: "US" },
  { id: 21631, s: "006730.KQ", n: "Seobu T&D Co.", c: "Korea" },
  { id: 21632, s: "115450.KQ", n: "Digital Aria", c: "Korea" },
  { id: 21633, s: "IMTL", n: "Image Protect Inc", c: "US" },
  { id: 21634, s: "IMTV", n: "Imagination TV Inc", c: "US" },
  { id: 21635, s: "051780.KQ", n: "Curo Holdings Co.", c: "Korea" },
  { id: 21636, s: "119830.KQ", n: "ITEK Inc", c: "Korea" },
  { id: 21637, s: "EMMG.AX", n: "BetaShares Martin Currie Emerging Markets Fund (managed fund)", c: "Australia" },
  { id: 21638, s: "LMTI", n: "Laser Master International Inc", c: "US" },
  { id: 21639, s: "128660.KQ", n: "PJ Metal Co.", c: "Korea" },
  { id: 21640, s: "127710.KQ", n: "Asia Economy Daily", c: "Korea" },
  { id: 21641, s: "126880.KQ", n: "JNK Heaters Co.", c: "Korea" },
  { id: 21642, s: "094940.KQ", n: "Puloon Technology Inc", c: "Korea" },
  { id: 21643, s: "096870.KQ", n: "LDT Inc", c: "Korea" },
  { id: 21644, s: "096610.KQ", n: "RFsemi Technologies Inc", c: "Korea" },
  { id: 21645, s: "096350.KQ", n: "DaeChang Solution Co.", c: "Korea" },
  { id: 21646, s: "095610.KQ", n: "TES Co.", c: "Korea" },
  { id: 21647, s: "099440.KQ", n: "Smec Co.Ltd", c: "Korea" },
  { id: 21648, s: "095270.KQ", n: "Wave Electronics Co.", c: "Korea" },
  { id: 21649, s: "LBTD", n: "Lotus Bio-Technology Development Co", c: "US" },
  { id: 21650, s: "098120.KQ", n: "Micro Contact Solution Co.", c: "Korea" },
  { id: 21651, s: "094850.KQ", n: "Very Good Tour Co.", c: "Korea" },
  { id: 21652, s: "094820.KQ", n: "Iljin Power Co.", c: "Korea" },
  { id: 21653, s: "094170.KQ", n: "Dongwoon Anatech", c: "Korea" },
  { id: 21654, s: "093920.KQ", n: "SEOWONINTECH.Co.Ltd", c: "Korea" },
  { id: 21655, s: "093320.KQ", n: "KINX Inc", c: "Korea" },
  { id: 21656, s: "094970.KQ", n: "JMT Co.Ltd", c: "Korea" },
  { id: 21657, s: "097870.KQ", n: "HyosungONBCo.Ltd", c: "Korea" },
  { id: 21658, s: "099220.KQ", n: "SDN Company.", c: "Korea" },
  { id: 21659, s: "126700.KQ", n: "HyVision System. Inc", c: "Korea" },
  { id: 21660, s: "EINC.AX", n: "BetaShares Martin Currie Equity Income Fund (managed fund)", c: "Australia" },
  { id: 21661, s: "126600.KQ", n: "Kopla Co.", c: "Korea" },
  { id: 21662, s: "125210.KQ", n: "Amogreentech Co.Ltd", c: "Korea" },
  { id: 21663, s: "123750.KQ", n: "Alton Sports Co.Ltd", c: "Korea" },
  { id: 21664, s: "EG1.AX", n: "EVERGREEN LITHIUM LIMITED", c: "Australia" },
  { id: 21665, s: "LQRCF", n: "Black Mammoth Metals Corporation", c: "US" },
  { id: 21666, s: "123410.KQ", n: "Korea Fuel-Tech Corporation", c: "Korea" },
  { id: 21667, s: "122690.KQ", n: "Seojin Automotive Co.Ltd", c: "Korea" },
  { id: 21668, s: "105330.KQ", n: "KNW Co.", c: "Korea" },
  { id: 21669, s: "122640.KQ", n: "Yest", c: "Korea" },
  { id: 21670, s: "122450.KQ", n: "KMH Co.", c: "Korea" },
  { id: 21671, s: "122350.KQ", n: "Samkee Automotive Co.", c: "Korea" },
  { id: 21672, s: "106240.KQ", n: "Finetechnix. Co.Ltd", c: "Korea" },
  { id: 21673, s: "DYM.AX", n: "Dynamic Metals.", c: "Australia" },
  { id: 21674, s: "106190.KQ", n: "High Tech Pharm Co.", c: "Korea" },
  { id: 21675, s: "001540.KQ", n: "Ahn-Gook Pharmaceutical Co.", c: "Korea" },
  { id: 21676, s: "002800.KQ", n: "Sinsin Pharmaceutical Co.", c: "Korea" },
  { id: 21677, s: "099750.KQ", n: "ezCaretech Co.", c: "Korea" },
  { id: 21678, s: "003100.KQ", n: "SUN KWANG CO.Ltd", c: "Korea" },
  { id: 21679, s: "007530.KQ", n: "Youngsin Metal Industrial Co.", c: "Korea" },
  { id: 21680, s: "007390.KQ", n: "NatureCell Co.Ltd", c: "Korea" },
  { id: 21681, s: "009300.KQ", n: "Sam-A Pharm. Co.", c: "Korea" },
  { id: 21682, s: "012790.KQ", n: "Sinil Pharmaceutical Co.", c: "Korea" },
  { id: 21683, s: "013310.KQ", n: "A-Jin Industry", c: "Korea" },
  { id: 21684, s: "013810.KQ", n: "SPE", c: "Korea" },
  { id: 21685, s: "018290.KQ", n: "VT GMP Co.Ltd", c: "Korea" },
  { id: 21686, s: "017890.KQ", n: "Korea Alcohol Industrial Co.", c: "Korea" },
  { id: 21687, s: "017650.KQ", n: "Daelim Paper Co.Ltd", c: "Korea" },
  { id: 21688, s: "017000.KQ", n: "SHINWON CONSTRUCTION COMPANY", c: "Korea" },
  { id: 21689, s: "016790.KQ", n: "HYUNDAI FEED Inc", c: "Korea" },
  { id: 21690, s: "016600.KQ", n: "Q Capital Partners Co.", c: "Korea" },
  { id: 21691, s: "MXT.AX", n: "Metrics Master Income Trust Unit", c: "Australia" },
  { id: 21692, s: "HNGKY", n: "Hong Kong Land Holdings ADR", c: "US" },
  { id: 21693, s: "HMTC", n: "Homasote", c: "US" },
  { id: 21694, s: "007770.KQ", n: "Hanil Chemical Ind. Co.", c: "Korea" },
  { id: 21695, s: "008290.KQ", n: "Wonpung Mulsan Co.Ltd", c: "Korea" },
  { id: 21696, s: "008370.KQ", n: "Wonpoong Corporation", c: "Korea" },
  { id: 21697, s: "011370.KQ", n: "SEOHAN Const. & Eng.co.Ltd", c: "Korea" },
  { id: 21698, s: "PHL.AX", n: "Propell Holdings", c: "Australia" },
  { id: 21699, s: "051500.KQ", n: "CJ Freshway Corporation", c: "Korea" },
  { id: 21700, s: "ALGW.L", n: "Alpha Growth PLC", c: "UK" },
  { id: 21701, s: "OLI.AX", n: "Oliver's Real Food Limited", c: "Australia" },
  { id: 21702, s: "012620.KQ", n: "Wonil Special Steel Co.", c: "Korea" },
  { id: 21703, s: "011560.KQ", n: "Sebo Manufacturing Engineering & Construction Co", c: "Korea" },
  { id: 21704, s: "011320.KQ", n: "Unick Corporation", c: "Korea" },
  { id: 21705, s: "HKTTY", n: "HKT Trust ADR", c: "US" },
  { id: 21706, s: "011080.KQ", n: "HYUNGJI INNOVATION & CREATIVE Co.Ltd", c: "Korea" },
  { id: 21707, s: "011040.KQ", n: "Kyung Dong Pharmaceutical Co.", c: "Korea" },
  { id: 21708, s: "HKTVY", n: "Hong Kong Television Network", c: "US" },
  { id: 21709, s: "012700.KQ", n: "The LEADCORP Inc", c: "Korea" },
  { id: 21710, s: "010470.KQ", n: "Oricom Inc", c: "Korea" },
  { id: 21711, s: "008470.KQ", n: "Booster Co.", c: "Korea" },
  { id: 21712, s: "015710.KQ", n: "Kocom Co.", c: "Korea" },
  { id: 21713, s: "014970.KQ", n: "SAMRYOONG Co.Ltd", c: "Korea" },
  { id: 21714, s: "014940.KQ", n: "Oriental Precision & Engineering Co.Ltd", c: "Korea" },
  { id: 21715, s: "ODA.AX", n: "Orcoda", c: "Australia" },
  { id: 21716, s: "ABD.L", n: "Abrdn New Dawn Investment Trust PLC", c: "UK" },
  { id: 21717, s: "ACT.L", n: "Actual Experience plc", c: "UK" },
  { id: 21718, s: "OEL.AX", n: "Otto Energy", c: "Australia" },
  { id: 21719, s: "AEG.L", n: "Active Energy Group PLC", c: "UK" },
  { id: 21720, s: "OJC.AX", n: "Original Juice Co..", c: "Australia" },
  { id: 21721, s: "BVC.TA", n: "Batm Advanced Communications", c: "Israel" },
  { id: 21722, s: "NYR.AX", n: "Nyrada Inc", c: "Australia" },
  { id: 21723, s: "ZNNC", n: "Zann Co", c: "US" },
  { id: 21724, s: "WWSG", n: "Worldwide Strategies Inc", c: "US" },
  { id: 21725, s: "006140.KQ", n: "PJ Electronics Co.", c: "Korea" },
  { id: 21726, s: "006050.KQ", n: "Kuk Young G&M Co.", c: "Korea" },
  { id: 21727, s: "004650.KQ", n: "Changhae Ethanol Co.", c: "Korea" },
  { id: 21728, s: "004590.KQ", n: "Hankook Furniture Co.", c: "Korea" },
  { id: 21729, s: "003800.KQ", n: "Ace Bed Company Limited", c: "Korea" },
  { id: 21730, s: "ZSHGY", n: "Zhongsheng Group Holdings ADR", c: "US" },
  { id: 21731, s: "WZZZY", n: "Wizz Air Holdings PLC", c: "US" },
  { id: 21732, s: "014620.KQ", n: "Sung Kwang Bend Co.Ltd", c: "Korea" },
  { id: 21733, s: "006910.KQ", n: "Bosung Power Technology Co.", c: "Korea" },
  { id: 21734, s: "014470.KQ", n: "Bubang Co.", c: "Korea" },
  { id: 21735, s: "014190.KQ", n: "Wonik Cube Co", c: "Korea" },
  { id: 21736, s: "NBI.AX", n: "NB Global Corporate Income Trust", c: "Australia" },
  { id: 21737, s: "013990.KQ", n: "Agabang&Company", c: "Korea" },
  { id: 21738, s: "006920.KQ", n: "Mohenz.Co.Ltd", c: "Korea" },
  { id: 21739, s: "OLL.AX", n: "Openlearning Ltd", c: "Australia" },
  { id: 21740, s: "006620.KQ", n: "DongKoo Bio&Pharma Co.", c: "Korea" },
  { id: 21741, s: "ZNKKY", n: "Zenkoku Hosho Co ADR", c: "US" },
  { id: 21742, s: "YITYY", n: "Yit Oyj ADR", c: "US" },
  { id: 21743, s: "OAU.AX", n: "Ora Gold", c: "Australia" },
  { id: 21744, s: "YLWDF", n: "Yellow Pages Limited", c: "US" },
  { id: 21745, s: "OBM.AX", n: "Ora Banda Mining", c: "Australia" },
  { id: 21746, s: "YMZBY", n: "Yamazaki Baking Co ADR", c: "US" },
  { id: 21747, s: "YUEIY", n: "Yue Yuen Industrial Holdings", c: "US" },
  { id: 21748, s: "FDR.AX", n: "Finder Energy Holdings", c: "Australia" },
  { id: 21749, s: "100130.KQ", n: "Dongkuk Structures & Construction Company Limited", c: "Korea" },
  { id: 21750, s: "034310.KS", n: "NICE Holdings Co.", c: "Korea" },
  { id: 21751, s: "136540.KQ", n: "WINS Co.", c: "Korea" },
  { id: 21752, s: "159580.KQ", n: "Zero to Seven Inc", c: "Korea" },
  { id: 21753, s: "154030.KQ", n: "Asia Seed Co.Ltd", c: "Korea" },
  { id: 21754, s: "153710.KQ", n: "Optipharm.CO.LTD", c: "Korea" },
  { id: 21755, s: "151910.KQ", n: "Nanos Co.", c: "Korea" },
  { id: 21756, s: "189690.KQ", n: "FORCS Co.Ltd", c: "Korea" },
  { id: 21757, s: "215360.KQ", n: "Woory Industrial Co.", c: "Korea" },
  { id: 21758, s: "FPR.AX", n: "FleetPartners Group.", c: "Australia" },
  { id: 21759, s: "092300.KQ", n: "Hyunwoo Industrial Co.", c: "Korea" },
  { id: 21760, s: "064090.KQ", n: "FN REPUBLIC Co.", c: "Korea" },
  { id: 21761, s: "063570.KQ", n: "NICE Total Cash Management Co.", c: "Korea" },
  { id: 21762, s: "063440.KQ", n: "FNC ADD CULTURE. Co.", c: "Korea" },
  { id: 21763, s: "063170.KQ", n: "Seoul Auction Co.", c: "Korea" },
  { id: 21764, s: "JPSWY", n: "Japan Steel Works ADR", c: "US" },
  { id: 21765, s: "061970.KQ", n: "LB Semicon Inc", c: "Korea" },
  { id: 21766, s: "061250.KQ", n: "Hwail Pharmaceutical Co.Ltd", c: "Korea" },
  { id: 21767, s: "DES.AX", n: "Desoto Resources.", c: "Australia" },
  { id: 21768, s: "159910.KQ", n: "Skin n Skin Co.", c: "Korea" },
  { id: 21769, s: "160980.KQ", n: "Cymechs Inc", c: "Korea" },
  { id: 21770, s: "187420.KQ", n: "GenoFocus Inc", c: "Korea" },
  { id: 21771, s: "134580.KQ", n: "Digital Multimedia Technology Co.Ltd", c: "Korea" },
  { id: 21772, s: "139670.KQ", n: "KineMaster Corporation", c: "Korea" },
  { id: 21773, s: "148250.KQ", n: "RN2 Technologies Co.", c: "Korea" },
  { id: 21774, s: "149950.KQ", n: "Avatec Co.", c: "Korea" },
  { id: 21775, s: "149980.KQ", n: "Hironic Co.", c: "Korea" },
  { id: 21776, s: "187790.KQ", n: "Eugene SPAC 1", c: "Korea" },
  { id: 21777, s: "185490.KQ", n: "EyeGene Inc", c: "Korea" },
  { id: 21778, s: "161570.KQ", n: "THE MIDONG Co.", c: "Korea" },
  { id: 21779, s: "183300.KQ", n: "KoMi", c: "Korea" },
  { id: 21780, s: "182400.KQ", n: "ATGen", c: "Korea" },
  { id: 21781, s: "MCCRF", n: "Mccoy Global Inc", c: "US" },
  { id: 21782, s: "177350.KQ", n: "Vessel Co.", c: "Korea" },
  { id: 21783, s: "175250.KQ", n: "Icure Pharmaceutical Incorporation", c: "Korea" },
  { id: 21784, s: "170030.KQ", n: "Hyundai Industrial Co.", c: "Korea" },
  { id: 21785, s: "064240.KQ", n: "Homecast Co.Ltd", c: "Korea" },
  { id: 21786, s: "061040.KQ", n: "RFTech Co.", c: "Korea" },
  { id: 21787, s: "060570.KQ", n: "Dreamus Company", c: "Korea" },
  { id: 21788, s: "065130.KQ", n: "TOP Engineering Co.", c: "Korea" },
  { id: 21789, s: "065690.KQ", n: "PAKERS.Co.Ltd", c: "Korea" },
  { id: 21790, s: "065680.KQ", n: "UJU Electronics Co.", c: "Korea" },
  { id: 21791, s: "065570.KQ", n: "SAMYUNG ENC Co.", c: "Korea" },
  { id: 21792, s: "065510.KQ", n: "Huvitz Co.", c: "Korea" },
  { id: 21793, s: "065500.KQ", n: "Orient Precision Industries Inc", c: "Korea" },
  { id: 21794, s: "065150.KQ", n: "MPDAESAN Inc.", c: "Korea" },
  { id: 21795, s: "064800.KQ", n: "Gemvaxlink Co.", c: "Korea" },
  { id: 21796, s: "065950.KQ", n: "Welcron Company Limited", c: "Korea" },
  { id: 21797, s: "059100.KQ", n: "i-Components Co.", c: "Korea" },
  { id: 21798, s: "067010.KQ", n: "ECSTELECOM Co.", c: "Korea" },
  { id: 21799, s: "059090.KQ", n: "Mi", c: "Korea" },
  { id: 21800, s: "058400.KQ", n: "Korea New Network Co.", c: "Korea" },
  { id: 21801, s: "IQEPF", n: "IQE PLC", c: "US" },
  { id: 21802, s: "053620.KQ", n: "TAEYANG Corporation", c: "Korea" },
  { id: 21803, s: "065770.KQ", n: "CS Corporation", c: "Korea" },
  { id: 21804, s: "066310.KQ", n: "Q.S.I Co.", c: "Korea" },
  { id: 21805, s: "060560.KQ", n: "Home Center Holdings Co.Ltd", c: "Korea" },
  { id: 21806, s: "JTBK", n: "Jetblack Co", c: "US" },
  { id: 21807, s: "060540.KQ", n: "System and Application Technologies Co.", c: "Korea" },
  { id: 21808, s: "060310.KQ", n: "3S KOREA Co.", c: "Korea" },
  { id: 21809, s: "060280.KQ", n: "Curexo Inc", c: "Korea" },
  { id: 21810, s: "060240.KQ", n: "LONGTU KOREA Inc", c: "Korea" },
  { id: 21811, s: "060230.KQ", n: "exax Inc", c: "Korea" },
  { id: 21812, s: "060590.KQ", n: "CTCBIO Inc", c: "Korea" },
  { id: 21813, s: "064260.KQ", n: "Danal Co.", c: "Korea" },
  { id: 21814, s: "066360.KQ", n: "Cherrybro co.Ltd", c: "Korea" },
  { id: 21815, s: "064550.KQ", n: "Bioneer Corporation", c: "Korea" },
  { id: 21816, s: "067000.KQ", n: "JOYCITY Corporation", c: "Korea" },
  { id: 21817, s: "066910.KQ", n: "SONOKONG Co.", c: "Korea" },
  { id: 21818, s: "066790.KQ", n: "Korea Cable T.V Chung-Buk System Co..", c: "Korea" },
  { id: 21819, s: "066700.KQ", n: "Theragen Etex Co.Ltd", c: "Korea" },
  { id: 21820, s: "066430.KQ", n: "Y-Optics Manufacture Co.", c: "Korea" },
  { id: 21821, s: "136480.KQ", n: "HARIM Co.", c: "Korea" },
  { id: 21822, s: "137950.KQ", n: "JC Chemical Co.", c: "Korea" },
  { id: 21823, s: "104830.KQ", n: "WONIK Materials Co.Ltd", c: "Korea" },
  { id: 21824, s: "DUN.AX", n: "Dundas Minerals", c: "Australia" },
  { id: 21825, s: "134060.KQ", n: "e-future.Co.Ltd", c: "Korea" },
  { id: 21826, s: "200780.KQ", n: "BCWORLD PHARM. Co.", c: "Korea" },
  { id: 21827, s: "200710.KQ", n: "ADTechnology Co.Ltd", c: "Korea" },
  { id: 21828, s: "200670.KQ", n: "Humedix", c: "Korea" },
  { id: 21829, s: "200230.KQ", n: "Telcon Inc", c: "Korea" },
  { id: 21830, s: "200130.KQ", n: "Mirae Asset No2 Special", c: "Korea" },
  { id: 21831, s: "198440.KQ", n: "Woori SPAC 3", c: "Korea" },
  { id: 21832, s: "VOPKF", n: "Koninklijke Vopak NV", c: "US" },
  { id: 21833, s: "203650.KQ", n: "Dream Security co.", c: "Korea" },
  { id: 21834, s: "MDLM", n: "Medley Management Inc", c: "US" },
  { id: 21835, s: "194700.KQ", n: "NOVAREX Co.Ltd", c: "Korea" },
  { id: 21836, s: "194480.KQ", n: "Devsisters corporation", c: "Korea" },
  { id: 21837, s: "193250.KQ", n: "Young Bag Micro Motors", c: "Korea" },
  { id: 21838, s: "192440.KQ", n: "Spigen Korea Co.", c: "Korea" },
  { id: 21839, s: "MDCE", n: "Medical Care Technologies Inc", c: "US" },
  { id: 21840, s: "131370.KQ", n: "RSUPPORT Co.", c: "Korea" },
  { id: 21841, s: "093190.KQ", n: "Bixolon Co.", c: "Korea" },
  { id: 21842, s: "105740.KQ", n: "DK-Lok Corporation", c: "Korea" },
  { id: 21843, s: "101930.KQ", n: "Inhwa Precision Co.", c: "Korea" },
  { id: 21844, s: "104620.KQ", n: "Yellow Balloon Tour Co.", c: "Korea" },
  { id: 21845, s: "104480.KQ", n: "TK Chemical Corporation", c: "Korea" },
  { id: 21846, s: "ETR.AX", n: "Entyr.", c: "Australia" },
  { id: 21847, s: "104460.KQ", n: "DYPNF Co.Ltd", c: "Korea" },
  { id: 21848, s: "102940.KQ", n: "Kolon Life Science Inc", c: "Korea" },
  { id: 21849, s: "102120.KQ", n: "ABOV Semiconductor Co.", c: "Korea" },
  { id: 21850, s: "101730.KQ", n: "Wemade Max Co.", c: "Korea" },
  { id: 21851, s: "100590.KQ", n: "Mercury Corporation", c: "Korea" },
  { id: 21852, s: "101490.KQ", n: "S&S TECH Corporation", c: "Korea" },
  { id: 21853, s: "101400.KQ", n: "N Citron Inc", c: "Korea" },
  { id: 21854, s: "101330.KQ", n: "MOBASE", c: "Korea" },
  { id: 21855, s: "101240.KQ", n: "CQV Co.", c: "Korea" },
  { id: 21856, s: "101000.KQ", n: "Sangsangin Industry Co..", c: "Korea" },
  { id: 21857, s: "FBM.AX", n: "Future Battery Minerals.", c: "Australia" },
  { id: 21858, s: "192390.KQ", n: "Winhitech", c: "Korea" },
  { id: 21859, s: "192250.KQ", n: "KB No.2 Special Purpose Acq", c: "Korea" },
  { id: 21860, s: "196700.KQ", n: "WAPS Co.", c: "Korea" },
  { id: 21861, s: "142280.KQ", n: "Green Cross Medical Science Corporation", c: "Korea" },
  { id: 21862, s: "191420.KQ", n: "TegoScience Inc", c: "Korea" },
  { id: 21863, s: "131400.KQ", n: "EV Advanced Material Co.Ltd", c: "Korea" },
  { id: 21864, s: "189860.KQ", n: "SEOJEON ELECTRIC MACHINERY Co.Ltd", c: "Korea" },
  { id: 21865, s: "187870.KQ", n: "DeviceENG.CO.Ltd", c: "Korea" },
  { id: 21866, s: "147760.KQ", n: "Microfriend Inc", c: "Korea" },
  { id: 21867, s: "143540.KQ", n: "YoungWoo DSP Co.Ltd", c: "Korea" },
  { id: 21868, s: "142210.KQ", n: "Unitron Tech", c: "Korea" },
  { id: 21869, s: "205100.KQ", n: "Kyobo With Special Purpose Acq", c: "Korea" },
  { id: 21870, s: "141080.KQ", n: "LegoChem Biosciences Inc", c: "Korea" },
  { id: 21871, s: "141000.KQ", n: "Viatron Technologies Inc", c: "Korea" },
  { id: 21872, s: "LVNSF", n: "LeoNovus Inc", c: "US" },
  { id: 21873, s: "140670.KQ", n: "RS Automation Co.Ltd", c: "Korea" },
  { id: 21874, s: "138610.KQ", n: "NIBEC Co.", c: "Korea" },
  { id: 21875, s: "138080.KQ", n: "OE Solutions Co.", c: "Korea" },
  { id: 21876, s: "204840.KQ", n: "IBKS No2 SPAC", c: "Korea" },
  { id: 21877, s: "205470.KQ", n: "Humasis Co.", c: "Korea" },
  { id: 21878, s: "203690.KQ", n: "Prostemics Co.", c: "Korea" },
  { id: 21879, s: "212560.KQ", n: "NEOOTO Co.", c: "Korea" },
  { id: 21880, s: "204620.KQ", n: "Global Tax Free Co.", c: "Korea" },
  { id: 21881, s: "204630.KQ", n: "Studio Santa Claus Entertainment Co.", c: "Korea" },
  { id: 21882, s: "215100.KQ", n: "RoboRobo Co.", c: "Korea" },
  { id: 21883, s: "215000.KQ", n: "GOLFZON Co.", c: "Korea" },
  { id: 21884, s: "214270.KQ", n: "KB SPAC 7", c: "Korea" },
  { id: 21885, s: "213420.KQ", n: "DukSan Neolux Co.Ltd", c: "Korea" },
  { id: 21886, s: "211270.KQ", n: "Asia Pacific Satellite Inc", c: "Korea" },
  { id: 21887, s: "205500.KQ", n: "KB No4 SPAC", c: "Korea" },
  { id: 21888, s: "208710.KQ", n: "Kyobo 3 SPAC", c: "Korea" },
  { id: 21889, s: "208640.KQ", n: "KB SPAC 6", c: "Korea" },
  { id: 21890, s: "208370.KQ", n: "Hana Must SPAC 2", c: "Korea" },
  { id: 21891, s: "CSF.AX", n: "Catalano Seafood", c: "Australia" },
  { id: 21892, s: "208140.KQ", n: "JUNGDAWN Co.", c: "Korea" },
  { id: 21893, s: "206640.KQ", n: "NH SPAC 2", c: "Korea" },
  { id: 21894, s: "BGS.L", n: "Baillie Gifford Shin Nippon PLC", c: "UK" },
  { id: 21895, s: "BGFD.L", n: "Baillie Gifford Japan Trust", c: "UK" },
  { id: 21896, s: "POL.AX", n: "Polymetals Resources", c: "Australia" },
  { id: 21897, s: "HVRRY", n: "Hannover Re", c: "US" },
  { id: 21898, s: "036200.KQ", n: "UNISEM Co.", c: "Korea" },
  { id: 21899, s: "036120.KQ", n: "SCI Information Service Inc", c: "Korea" },
  { id: 21900, s: "036090.KQ", n: "WIZIT Co.", c: "Korea" },
  { id: 21901, s: "036710.KQ", n: "Simmtech Holdings Co.", c: "Korea" },
  { id: 21902, s: "032540.KQ", n: "TJ media Co.", c: "Korea" },
  { id: 21903, s: "032080.KQ", n: "AztechWB Co.", c: "Korea" },
  { id: 21904, s: "031980.KQ", n: "PSK HOLDINGS Inc", c: "Korea" },
  { id: 21905, s: "023910.KQ", n: "Daihan Pharmaceutical Co.Ltd", c: "Korea" },
  { id: 21906, s: "023790.KQ", n: "Dongil Steel Co.", c: "Korea" },
  { id: 21907, s: "023760.KQ", n: "Han Kook Capital Co.", c: "Korea" },
  { id: 21908, s: "023600.KQ", n: "Sambo Corrugated Board Co.", c: "Korea" },
  { id: 21909, s: "023440.KQ", n: "Jeil Steel Mfg Co.", c: "Korea" },
  { id: 21910, s: "023410.KQ", n: "Eugene Corporation", c: "Korea" },
  { id: 21911, s: "022220.KQ", n: "Jeongsan Aikang Co.Ltd", c: "Korea" },
  { id: 21912, s: "024060.KQ", n: "Hung -Gu Oil", c: "Korea" },
  { id: 21913, s: "036480.KQ", n: "Daesung Microbiological Labs. Co.", c: "Korea" },
  { id: 21914, s: "036540.KQ", n: "SFA Semicon Co.", c: "Korea" },
  { id: 21915, s: "036560.KQ", n: "Young Poong Precision Corporation", c: "Korea" },
  { id: 21916, s: "037950.KQ", n: "Elcomtec Co.", c: "Korea" },
  { id: 21917, s: "032620.KQ", n: "UBcare. Co.", c: "Korea" },
  { id: 21918, s: "033160.KQ", n: "MK Electron Co.", c: "Korea" },
  { id: 21919, s: "032580.KQ", n: "Fidelix Co.", c: "Korea" },
  { id: 21920, s: "036000.KQ", n: "YeaRimDang Publishing Co.", c: "Korea" },
  { id: 21921, s: "036030.KQ", n: "KT Hitel Corporation. Limited", c: "Korea" },
  { id: 21922, s: "038010.KQ", n: "Jeil Technos Co.Ltd", c: "Korea" },
  { id: 21923, s: "037460.KQ", n: "Samji Electronics Co.", c: "Korea" },
  { id: 21924, s: "036630.KQ", n: "Sejong Telecom Inc", c: "Korea" },
  { id: 21925, s: "037440.KQ", n: "Heerim Architects & Planners Co.", c: "Korea" },
  { id: 21926, s: "037370.KQ", n: "EG Corporation", c: "Korea" },
  { id: 21927, s: "037330.KQ", n: "Inzi Display Co.Ltd", c: "Korea" },
  { id: 21928, s: "037070.KQ", n: "Paseco Co.", c: "Korea" },
  { id: 21929, s: "036010.KQ", n: "ABCO Electronics Co.", c: "Korea" },
  { id: 21930, s: "036830.KQ", n: "Soulbrain Holdings Co.", c: "Korea" },
  { id: 21931, s: "021320.KQ", n: "KCC Engineering & Construction Co.", c: "Korea" },
  { id: 21932, s: "020710.KQ", n: "Sigong Tech Co.", c: "Korea" },
  { id: 21933, s: "020180.KQ", n: "Daishin Information & Communications Co.Ltd", c: "Korea" },
  { id: 21934, s: "038070.KQ", n: "Seoulin Bioscience Co.Ltd", c: "Korea" },
  { id: 21935, s: "026150.KQ", n: "Tuksu Engineering & ConstructionLtd", c: "Korea" },
  { id: 21936, s: "025880.KQ", n: "KC Feed Co.", c: "Korea" },
  { id: 21937, s: "025870.KQ", n: "Silla Sg Co.", c: "Korea" },
  { id: 21938, s: "025770.KQ", n: "Korea Information & Communications Co.", c: "Korea" },
  { id: 21939, s: "HRAL", n: "Hear Atlast Holdings Inc", c: "US" },
  { id: 21940, s: "024950.KQ", n: "Samchuly Bicycle Co.", c: "Korea" },
  { id: 21941, s: "038110.KQ", n: "Ecoplastic Corporation", c: "Korea" },
  { id: 21942, s: "027040.KQ", n: "Seoul Electronics & Telecom Co.", c: "Korea" },
  { id: 21943, s: "038390.KQ", n: "RedcapTour Co.", c: "Korea" },
  { id: 21944, s: "038460.KQ", n: "BioSmart Co.Ltd", c: "Korea" },
  { id: 21945, s: "048430.KQ", n: "Yura Tech. Co.", c: "Korea" },
  { id: 21946, s: "047920.KQ", n: "HLB Pharmaceutical Co.", c: "Korea" },
  { id: 21947, s: "047560.KQ", n: "ESTsoft Co", c: "Korea" },
  { id: 21948, s: "047310.KQ", n: "Powerlogics Co.", c: "Korea" },
  { id: 21949, s: "026910.KQ", n: "Kwang Jin Ind. Co.", c: "Korea" },
  { id: 21950, s: "027580.KQ", n: "SANGBO Co", c: "Korea" },
  { id: 21951, s: "019990.KQ", n: "Enertork", c: "Korea" },
  { id: 21952, s: "024840.KQ", n: "Kbi Metal Co.", c: "Korea" },
  { id: 21953, s: "019660.KQ", n: "Globon Co.", c: "Korea" },
  { id: 21954, s: "019590.KQ", n: "M-Venture Investment Inc.", c: "Korea" },
  { id: 21955, s: "019210.KQ", n: "YG-1 Co.", c: "Korea" },
  { id: 21956, s: "019010.KQ", n: "VenueG Co.", c: "Korea" },
  { id: 21957, s: "021080.KQ", n: "Atinum Investment Co.", c: "Korea" },
  { id: 21958, s: "024120.KQ", n: "KB Autosys Co.", c: "Korea" },
  { id: 21959, s: "024850.KQ", n: "PSMC CO.", c: "Korea" },
  { id: 21960, s: "027830.KQ", n: "Daesung Private Equity Inc", c: "Korea" },
  { id: 21961, s: "031860.KQ", n: "NSN Co.", c: "Korea" },
  { id: 21962, s: "HUTCY", n: "Hutchison Telecommunications Hong Kong Holdings ADR", c: "US" },
  { id: 21963, s: "031330.KQ", n: "SAMT Co.", c: "Korea" },
  { id: 21964, s: "030960.KQ", n: "Yangjisa Co.", c: "Korea" },
  { id: 21965, s: "030520.KQ", n: "Hancom Inc", c: "Korea" },
  { id: 21966, s: "030190.KQ", n: "NICE Information Service Co.", c: "Korea" },
  { id: 21967, s: "032680.KQ", n: "Softcen Co.", c: "Korea" },
  { id: 21968, s: "032800.KQ", n: "Fantagio Co", c: "Korea" },
  { id: 21969, s: "PNX.AX", n: "PNX Metals", c: "Australia" },
  { id: 21970, s: "032820.KQ", n: "Woori Technology Inc", c: "Korea" },
  { id: 21971, s: "300778.SZ", n: "Shenzhen New Land", c: "CH" },
  { id: 21972, s: "300779.SZ", n: "Qingdao Huicheng Environmental", c: "CH" },
  { id: 21973, s: "300780.SZ", n: "Sichuan Dawn Precision Technology", c: "CH" },
  { id: 21974, s: "024070.KS", n: "Wiscom", c: "Korea" },
  { id: 21975, s: "023960.KS", n: "Sc Engineering", c: "Korea" },
  { id: 21976, s: "023590.KS", n: "Daou Tech", c: "Korea" },
  { id: 21977, s: "300775.SZ", n: "Xi An Triangle Defens", c: "CH" },
  { id: 21978, s: "300785.SZ", n: "Beijing Zhidemai Technology", c: "CH" },
  { id: 21979, s: "300787.SZ", n: "Ce Link", c: "CH" },
  { id: 21980, s: "300788.SZ", n: "Citic Press Co", c: "CH" },
  { id: 21981, s: "300790.SZ", n: "Yutong Optical", c: "CH" },
  { id: 21982, s: "300791.SZ", n: "Sirio Pharma", c: "CH" },
  { id: 21983, s: "300792.SZ", n: "Onechance", c: "CH" },
  { id: 21984, s: "300795.SZ", n: "Meorient", c: "CH" },
  { id: 21985, s: "300796.SZ", n: "Bsm Chemical", c: "CH" },
  { id: 21986, s: "UAHC", n: "United American Healthcare Co", c: "US" },
  { id: 21987, s: "300747.SZ", n: "Wuhan Raycus Fiber Laser Technologies Co Class A", c: "CH" },
  { id: 21988, s: "028050.KS", n: "Samsung Engineering", c: "Korea" },
  { id: 21989, s: "300709.SZ", n: "Jiangsu Gian Technology", c: "CH" },
  { id: 21990, s: "300701.SZ", n: "Nanyang Senba Optical and Electronic", c: "CH" },
  { id: 21991, s: "300702.SZ", n: "Zhejiang Tianyu Pharmaceutical", c: "CH" },
  { id: 21992, s: "300705.SZ", n: "Hunan Jiudian Pharmaceutical", c: "CH" },
  { id: 21993, s: "033920.KS", n: "Muhak Co.", c: "Korea" },
  { id: 21994, s: "300707.SZ", n: "VT Industrial Technology", c: "CH" },
  { id: 21995, s: "300708.SZ", n: "Focus Lightings Tech", c: "CH" },
  { id: 21996, s: "300710.SZ", n: "Hangzhou Prevail Optoelectronic Equipment", c: "CH" },
  { id: 21997, s: "300799.SZ", n: "Zuojiang Technology", c: "CH" },
  { id: 21998, s: "300711.SZ", n: "GHT Co Class A", c: "CH" },
  { id: 21999, s: "300712.SZ", n: "Fujian Yongfu Power Engineering Co Class A", c: "CH" },
  { id: 22000, s: "033270.KS", n: "United Pharm", c: "Korea" },
  { id: 22001, s: "300715.SZ", n: "Jiangsu Canlon Building Materials", c: "CH" },
  { id: 22002, s: "300716.SZ", n: "Guangdong Guoli Sci&Tech Co Class A", c: "CH" },
  { id: 22003, s: "300742.SZ", n: "Nanjing Yueboo Power System Co Class A", c: "CH" },
  { id: 22004, s: "300786.SZ", n: "Guolin Environmental Tech", c: "CH" },
  { id: 22005, s: "300773.SZ", n: "Lakala Payment", c: "CH" },
  { id: 22006, s: "300772.SZ", n: "Zhejiang Windey", c: "CH" },
  { id: 22007, s: "034940.KQ", n: "ChoA Pharmaceutical Co.Ltd", c: "Korea" },
  { id: 22008, s: "103140.KS", n: "Poongsan", c: "Korea" },
  { id: 22009, s: "215380.KQ", n: "WOOJUNG BIO Inc", c: "Korea" },
  { id: 22010, s: "215200.KQ", n: "MegaStudyEdu Co.", c: "Korea" },
  { id: 22011, s: "035610.KQ", n: "Solborn Inc", c: "Korea" },
  { id: 22012, s: "035460.KQ", n: "Kisan Telecom Co.", c: "Korea" },
  { id: 22013, s: "035200.KQ", n: "PlumbFast Co.", c: "Korea" },
  { id: 22014, s: "000930.SZ", n: "Cofco Biochemical Anhui", c: "CH" },
  { id: 22015, s: "300770.SZ", n: "Guangdong South New Media", c: "CH" },
  { id: 22016, s: "033560.KQ", n: "Bluecom Co.", c: "Korea" },
  { id: 22017, s: "HYPMY", n: "Hypera SA", c: "US" },
  { id: 22018, s: "035620.KQ", n: "Barunson Entertainment & Arts Corporation", c: "Korea" },
  { id: 22019, s: "033170.KQ", n: "Signetics Corporation", c: "Korea" },
  { id: 22020, s: "033050.KQ", n: "Jeong Moon Information. Co.", c: "Korea" },
  { id: 22021, s: "032980.KQ", n: "BYON Co.", c: "Korea" },
  { id: 22022, s: "300746.SZ", n: "Hanjia Design Croup Co Class A", c: "CH" },
  { id: 22023, s: "300769.SZ", n: "Shenzhen Dynanonic", c: "CH" },
  { id: 22024, s: "024720.KS", n: "Kolmar Holding", c: "Korea" },
  { id: 22025, s: "300758.SZ", n: "Anshan Hifichem", c: "CH" },
  { id: 22026, s: "027970.KS", n: "seha corporation", c: "Korea" },
  { id: 22027, s: "300749.SZ", n: "GuangDong Topstrong Living Innovation & Integration Co Class A", c: "CH" },
  { id: 22028, s: "300752.SZ", n: "Shenzhen Longli Technology Co Class A", c: "CH" },
  { id: 22029, s: "025890.KS", n: "Han Kook Steel", c: "Korea" },
  { id: 22030, s: "300756.SZ", n: "Guangdong Jinma Entertainment Co", c: "CH" },
  { id: 22031, s: "300757.SZ", n: "RoboTechnik Intelligent Technology", c: "CH" },
  { id: 22032, s: "025860.KS", n: "Namhae Chemical", c: "Korea" },
  { id: 22033, s: "024890.KS", n: "Dae Won Chem", c: "Korea" },
  { id: 22034, s: "025750.KS", n: "Hansol Homedec", c: "Korea" },
  { id: 22035, s: "300762.SZ", n: "Jushri Technologies Inc Class A", c: "CH" },
  { id: 22036, s: "025620.KS", n: "Jayjun", c: "Korea" },
  { id: 22037, s: "025560.KS", n: "Mirae Corporation", c: "Korea" },
  { id: 22038, s: "025540.KS", n: "Korea Electric Terminal", c: "Korea" },
  { id: 22039, s: "300767.SZ", n: "Yunnan QuakeSafe Seismic Isolation Technologies Co Class A", c: "CH" },
  { id: 22040, s: "KOB.AX", n: "Koba Resources", c: "Australia" },
  { id: 22041, s: "046440.KQ", n: "KG Mobilians Co.", c: "Korea" },
  { id: 22042, s: "046140.KQ", n: "SBS Contents Hub Co.", c: "Korea" },
  { id: 22043, s: "048550.KQ", n: "SM Culture & Contents Co.", c: "Korea" },
  { id: 22044, s: "OPT.AX", n: "Opthea", c: "Australia" },
  { id: 22045, s: "ATRY.TA", n: "Atreyu Capital Markets", c: "Israel" },
  { id: 22046, s: "ARPT.TA", n: "Airport City", c: "Israel" },
  { id: 22047, s: "ORN.AX", n: "Orion Minerals", c: "Australia" },
  { id: 22048, s: "ORR.AX", n: "OreCo", c: "Australia" },
  { id: 22049, s: "ANGL.TA", n: "Salomon A Angel", c: "Israel" },
  { id: 22050, s: "OSX.AX", n: "Osteopore", c: "Australia" },
  { id: 22051, s: "AMOT.TA", n: "Amot Investments", c: "Israel" },
  { id: 22052, s: "ALMD.TA", n: "Allmed Solutions", c: "Israel" },
  { id: 22053, s: "OZZ.AX", n: "Ozz Resources", c: "Australia" },
  { id: 22054, s: "PAA.AX", n: "PharmAust", c: "Australia" },
  { id: 22055, s: "PAB.AX", n: "Patrys", c: "Australia" },
  { id: 22056, s: "AFHL.TA", n: "Afcon Holdings", c: "Israel" },
  { id: 22057, s: "PAI.AX", n: "Platinum Asia Investments", c: "Australia" },
  { id: 22058, s: "PAM.AX", n: "Pan Asia Metals", c: "Australia" },
  { id: 22059, s: "AVGD.TA", n: "Av Gad Holdings", c: "Israel" },
  { id: 22060, s: "ONE.AX", n: "Oneview Healthcare PLC", c: "Australia" },
  { id: 22061, s: "TOMA.PR", n: "Toma as", c: "Czech Republic" },
  { id: 22062, s: "PFG.AX", n: "Prime Financial Group", c: "Australia" },
  { id: 22063, s: "UNITED.HE", n: "United Bankers Oyj", c: "Finland" },
  { id: 22064, s: "TULAV.HE", n: "Tulikivi Oyj A", c: "Finland" },
  { id: 22065, s: "PEC.AX", n: "Perpetual Resources", c: "Australia" },
  { id: 22066, s: "TRH1V.HE", n: "Trainers House Oyj", c: "Finland" },
  { id: 22067, s: "APTA.L", n: "Aptamer Group PLC", c: "UK" },
  { id: 22068, s: "ARTL.L", n: "Alpha Real Trust", c: "UK" },
  { id: 22069, s: "TOIVO.HE", n: "Toivo Group Oyj", c: "Finland" },
  { id: 22070, s: "HCGS", n: "HighCom Global Security Inc", c: "US" },
  { id: 22071, s: "ASIZ.L", n: "Aberforth Split Level Income Trust PLC", c: "UK" },
  { id: 22072, s: "PGC.AX", n: "Paragon Care", c: "Australia" },
  { id: 22073, s: "ASTO.L", n: "Assetco Plc", c: "UK" },
  { id: 22074, s: "PGL.AX", n: "Prospa Group Ltd", c: "Australia" },
  { id: 22075, s: "PEX.AX", n: "Peel Mining", c: "Australia" },
  { id: 22076, s: "WUF1V.HE", n: "Wulff-Yhtiot Oy", c: "Finland" },
  { id: 22077, s: "PGY.AX", n: "Pilot Energy", c: "Australia" },
  { id: 22078, s: "AVGL.TA", n: "Avgol Industries 1953", c: "Israel" },
  { id: 22079, s: "SAGCV.HE", n: "Saga Furs Oyj C", c: "Finland" },
  { id: 22080, s: "PLG.AX", n: "Pearl Gull Iron Limited", c: "Australia" },
  { id: 22081, s: "INVEST.HE", n: "Investors House", c: "Finland" },
  { id: 22082, s: "BGUK.L", n: "Baillie Gifford UK Growth Fund PLC", c: "UK" },
  { id: 22083, s: "PIM.AX", n: "Pinnacle Minerals", c: "Australia" },
  { id: 22084, s: "AXS.L", n: "Accsys Technologies PLC", c: "UK" },
  { id: 22085, s: "PIXX.AX", n: "Platinum International Fund (Quoted Managed Hedge Fund)", c: "Australia" },
  { id: 22086, s: "PURMO.HE", n: "Purmo Group Oyj", c: "Finland" },
  { id: 22087, s: "PNA1V.HE", n: "Panostaja Oyj", c: "Finland" },
  { id: 22088, s: "ACG1V.HE", n: "Aspocomp Group Oyj", c: "Finland" },
  { id: 22089, s: "PARTNE1.HE", n: "Partnera Oyj", c: "Finland" },
  { id: 22090, s: "BAF.L", n: "British and American Investment Trust PLC", c: "UK" },
  { id: 22091, s: "ORNAV.HE", n: "Orion Oyj A", c: "Finland" },
  { id: 22092, s: "BBB.L", n: "Bigblu Broadband PLC", c: "UK" },
  { id: 22093, s: "METSA.HE", n: "Metsa Board Oyj A", c: "Finland" },
  { id: 22094, s: "LEMON.HE", n: "Lemonsoft Oyj", c: "Finland" },
  { id: 22095, s: "HEEROS.HE", n: "Heeros Oyj", c: "Finland" },
  { id: 22096, s: "BRIG.L", n: "BlackRock Income and Growth Investment Trust plc", c: "UK" },
  { id: 22097, s: "AUSC.L", n: "Abrdn UK Smaller Companies Growth Trust PLC", c: "UK" },
  { id: 22098, s: "DIGIA.HE", n: "Digia Oyj", c: "Finland" },
  { id: 22099, s: "BLU.L", n: "Blue Star Capital plc", c: "UK" },
  { id: 22100, s: "BLVN.L", n: "Bowleven PLC", c: "UK" },
  { id: 22101, s: "EVLI.HE", n: "Evli Pankki Oyj", c: "Finland" },
  { id: 22102, s: "ETTE.HE", n: "Etteplan Oyj", c: "Finland" },
  { id: 22103, s: "BMT.L", n: "Braime Group PLC", c: "UK" },
  { id: 22104, s: "DIGIGR.HE", n: "Digitalist Group Oyj", c: "Finland" },
  { id: 22105, s: "PPL.AX", n: "Pureprofile", c: "Australia" },
  { id: 22106, s: "PVW.AX", n: "PVW Resources", c: "Australia" },
  { id: 22107, s: "PRX.AX", n: "Prodigy Gold NL", c: "Australia" },
  { id: 22108, s: "PSL.AX", n: "Paterson Resources", c: "Australia" },
  { id: 22109, s: "SYN.IC", n: "Sýn hf", c: "Iceland" },
  { id: 22110, s: "BRFI.L", n: "BlackRock Frontiers Investment Trust plc", c: "UK" },
  { id: 22111, s: "PVE.AX", n: "PO Valley Energy", c: "Australia" },
  { id: 22112, s: "ZAL.OL", n: "Zalaris ASA", c: "Norway" },
  { id: 22113, s: "PCK.AX", n: "PainChek", c: "Australia" },
  { id: 22114, s: "018310.KQ", n: "Sammok S-Form Co.Ltd", c: "Korea" },
  { id: 22115, s: "018620.KQ", n: "WooGene B&G Co.", c: "Korea" },
  { id: 22116, s: "049630.KQ", n: "Jaeyoung Solutec Co.", c: "Korea" },
  { id: 22117, s: "KAL.AX", n: "Kalgoorlie Gold Mining", c: "Australia" },
  { id: 22118, s: "050120.KQ", n: "ES Cube Co.", c: "Korea" },
  { id: 22119, s: "050110.KQ", n: "CammSys Co", c: "Korea" },
  { id: 22120, s: "050090.KQ", n: "Phoenix Materials Co.", c: "Korea" },
  { id: 22121, s: "049950.KQ", n: "meerecompany Incorporated", c: "Korea" },
  { id: 22122, s: "049720.KQ", n: "Koryo Credit Information Co.", c: "Korea" },
  { id: 22123, s: "049520.KQ", n: "UIL Co.", c: "Korea" },
  { id: 22124, s: "050960.KQ", n: "SOOSAN INT Co.", c: "Korea" },
  { id: 22125, s: "049180.KQ", n: "Cellumed Co.", c: "Korea" },
  { id: 22126, s: "043910.KQ", n: "Nature and Environment Co.", c: "Korea" },
  { id: 22127, s: "018700.KQ", n: "Barunson Co.", c: "Korea" },
  { id: 22128, s: "043370.KQ", n: "PHA Co.", c: "Korea" },
  { id: 22129, s: "043340.KQ", n: "Essen Tech Co.", c: "Korea" },
  { id: 22130, s: "041020.KQ", n: "Polaris Office Co", c: "Korea" },
  { id: 22131, s: "050860.KQ", n: "Asia Technology", c: "Korea" },
  { id: 22132, s: "ILIM", n: "Il2m International Co", c: "US" },
  { id: 22133, s: "040420.KQ", n: "JLS Co.Ltd", c: "Korea" },
  { id: 22134, s: "044780.KQ", n: "HK Co.", c: "Korea" },
  { id: 22135, s: "046070.KQ", n: "Kodaco Co..", c: "Korea" },
  { id: 22136, s: "045520.KQ", n: "Clean & Science co.", c: "Korea" },
  { id: 22137, s: "045510.KQ", n: "Zungwon En-Sys Inc", c: "Korea" },
  { id: 22138, s: "045390.KQ", n: "DAEA TI Co.", c: "Korea" },
  { id: 22139, s: "045340.KQ", n: "Total Soft Bank", c: "Korea" },
  { id: 22140, s: "045060.KQ", n: "Okong Corporation", c: "Korea" },
  { id: 22141, s: "044480.KQ", n: "Blueberry NFT CO.LTD", c: "Korea" },
  { id: 22142, s: "051380.KQ", n: "PC Direct Inc", c: "Korea" },
  { id: 22143, s: "IGSC", n: "IGS Capital Group", c: "US" },
  { id: 22144, s: "048870.KQ", n: "Synergy Innovation Co.", c: "Korea" },
  { id: 22145, s: "048910.KQ", n: "Daewon Media Co.", c: "Korea" },
  { id: 22146, s: "049080.KQ", n: "Gigalane", c: "Korea" },
  { id: 22147, s: "051490.KQ", n: "Nara Mold & Die Co.", c: "Korea" },
  { id: 22148, s: "051390.KQ", n: "Yw Company Limited", c: "Korea" },
  { id: 22149, s: "040910.KQ", n: "ICD Co.", c: "Korea" },
  { id: 22150, s: "040350.KQ", n: "CUROCOM Co.", c: "Korea" },
  { id: 22151, s: "018680.KQ", n: "Seoul Pharma Co.", c: "Korea" },
  { id: 22152, s: "042000.KQ", n: "Cafe24 Co", c: "Korea" },
  { id: 22153, s: "042520.KQ", n: "HansBiomed Corporation", c: "Korea" },
  { id: 22154, s: "042510.KQ", n: "Raonsecure Co.", c: "Korea" },
  { id: 22155, s: "042420.KQ", n: "NEOWIZ HOLDINGS Corporation", c: "Korea" },
  { id: 22156, s: "LGI.AX", n: "LGI", c: "Australia" },
  { id: 22157, s: "042370.KQ", n: "Vitzro Tech Co.", c: "Korea" },
  { id: 22158, s: "042110.KQ", n: "SCD Co.", c: "Korea" },
  { id: 22159, s: "041960.KQ", n: "Komipharm International Co.", c: "Korea" },
  { id: 22160, s: "043090.KQ", n: "Cube&Company Co.", c: "Korea" },
  { id: 22161, s: "041930.KQ", n: "Dong-A Hwa Sung Co.Ltd", c: "Korea" },
  { id: 22162, s: "041910.KQ", n: "EstechPharma Co.", c: "Korea" },
  { id: 22163, s: "041590.KQ", n: "Gemvaxzio Co.", c: "Korea" },
  { id: 22164, s: "WDFN", n: "Woodlands Financial Services", c: "US" },
  { id: 22165, s: "IFBC", n: "Blast Applications", c: "US" },
  { id: 22166, s: "043360.KQ", n: "Digital Graphics Incorporation", c: "Korea" },
  { id: 22167, s: "042600.KQ", n: "Seronics Co.", c: "Korea" },
  { id: 22168, s: "043150.KQ", n: "Value Added Technology Co.", c: "Korea" },
  { id: 22169, s: "LML.AX", n: "Lincoln Minerals Limited", c: "Australia" },
  { id: 22170, s: "039020.KQ", n: "Eagon Holdings Co.Ltd", c: "Korea" },
  { id: 22171, s: "040300.KQ", n: "YTN Co.Ltd", c: "Korea" },
  { id: 22172, s: "039440.KQ", n: "STI Co.", c: "Korea" },
  { id: 22173, s: "IDKOY", n: "Idemitsu Kosan Co ADR", c: "US" },
  { id: 22174, s: "039310.KQ", n: "Sejoong Co.", c: "Korea" },
  { id: 22175, s: "039240.KQ", n: "Gyeongnam Steel Co.", c: "Korea" },
  { id: 22176, s: "039200.KQ", n: "Oscotec Inc", c: "Korea" },
  { id: 22177, s: "038870.KQ", n: "EcoBio Holdings Co.", c: "Korea" },
  { id: 22178, s: "043220.KQ", n: "HLB Power Co.", c: "Korea" },
  { id: 22179, s: "038680.KQ", n: "Snet systems Inc", c: "Korea" },
  { id: 22180, s: "038500.KQ", n: "SAMPYO Cement Co.", c: "Korea" },
  { id: 22181, s: "039420.KQ", n: "KL-Net Co", c: "Korea" },
  { id: 22182, s: "IENT", n: "iEntertainment Network Inc", c: "US" },
  { id: 22183, s: "041190.KQ", n: "Woori Technology Investment Co.", c: "Korea" },
  { id: 22184, s: "041440.KQ", n: "Hyundai Everdigm Co", c: "Korea" },
  { id: 22185, s: "002935.SZ", n: "Chengdu Spaceon Electronics Co Class A", c: "CH" },
  { id: 22186, s: "005430.KS", n: "Korea Air Svc", c: "Korea" },
  { id: 22187, s: "002910.SZ", n: "Lanzhou Zhuangyuan Pasture", c: "CH" },
  { id: 22188, s: "TSG.V", n: "Tristar Gold", c: "CA" },
  { id: 22189, s: "DEAR.ST", n: "Duearity AB", c: "Sweden" },
  { id: 22190, s: "AGRPY", n: "Absa Group ADR", c: "US" },
  { id: 22191, s: "DEVP-B.ST", n: "DevPort AB (publ)", c: "Sweden" },
  { id: 22192, s: "SGLD.TO", n: "Sabre Gold Mines Co", c: "CA" },
  { id: 22193, s: "SFI.TO", n: "Solution Financial Inc", c: "CA" },
  { id: 22194, s: "SEC.TO", n: "Senvest Capital Inc.", c: "CA" },
  { id: 22195, s: "DLAB.ST", n: "Dlaboratory Sweden AB", c: "Sweden" },
  { id: 22196, s: "DORO.ST", n: "DORO AB", c: "Sweden" },
  { id: 22197, s: "NAVF.L", n: "Nippon Active Value Fund Plc", c: "UK" },
  { id: 22198, s: "VPI.V", n: "Vitality Products Inc", c: "CA" },
  { id: 22199, s: "MINI.L", n: "Miton UK MicroCap Trust PLC", c: "UK" },
  { id: 22200, s: "FREEM.ST", n: "Freemelt Holding AB", c: "Sweden" },
  { id: 22201, s: "MAJE.L", n: "Majedie Investments", c: "UK" },
  { id: 22202, s: "FDFT", n: "Foodfest Intl 2000", c: "US" },
  { id: 22203, s: "HOYLU.ST", n: "Hoylu AB", c: "Sweden" },
  { id: 22204, s: "VERT.V", n: "Vertical Explorations Inc", c: "CA" },
  { id: 22205, s: "CX.ST", n: "CombinedX AB", c: "Sweden" },
  { id: 22206, s: "CORE-D.ST", n: "Corem Property Group AB (publ)", c: "Sweden" },
  { id: 22207, s: "CMH.ST", n: "Chordate Medical Holding AB", c: "Sweden" },
  { id: 22208, s: "TWR.V", n: "Tower Resources", c: "CA" },
  { id: 22209, s: "NFX.L", n: "Nuformix plc", c: "UK" },
  { id: 22210, s: "KLNMA.IS", n: "Turkiye Kalkinma Bankasi AS", c: "Turkey" },
  { id: 22211, s: "NET.L", n: "Netcall plc", c: "UK" },
  { id: 22212, s: "CLEM.ST", n: "Clemondo Group AB (publ)", c: "Sweden" },
  { id: 22213, s: "TYMB.V", n: "Tymbal Resources", c: "CA" },
  { id: 22214, s: "CNCJO-B.ST", n: "Concejo AB (publ)", c: "Sweden" },
  { id: 22215, s: "UI.V", n: "Urbanimmersive Inc", c: "CA" },
  { id: 22216, s: "COALA.ST", n: "Coala Life Group AB", c: "Sweden" },
  { id: 22217, s: "COMBI.ST", n: "Combigene AB", c: "Sweden" },
  { id: 22218, s: "VIV.V", n: "Avivagen Inc", c: "CA" },
  { id: 22219, s: "COMPDM.ST", n: "Compodium International AB", c: "Sweden" },
  { id: 22220, s: "EQUEY", n: "Equatorial Energia SA ADR", c: "US" },
  { id: 22221, s: "UFC.V", n: "Urbanfund Co", c: "CA" },
  { id: 22222, s: "PWF-PA.TO", n: "Power Financial Corp Pref A", c: "CA" },
  { id: 22223, s: "LWI.L", n: "Lowland Investment", c: "UK" },
  { id: 22224, s: "PVS-PI.TO", n: "Partners Value Split Co", c: "CA" },
  { id: 22225, s: "PWF-PE.TO", n: "Power Financial Corp Pref E", c: "CA" },
  { id: 22226, s: "FLXP", n: "FlexPower Inc", c: "US" },
  { id: 22227, s: "JDT.ST", n: "JonDeTech Sensors", c: "Sweden" },
  { id: 22228, s: "JLT.ST", n: "JLT Mobile Computers AB (publ)", c: "Sweden" },
  { id: 22229, s: "LMS.L", n: "LMS Capital Plc", c: "UK" },
  { id: 22230, s: "FGROY", n: "FirstGroup PLC ADR", c: "US" },
  { id: 22231, s: "HOLM-A.ST", n: "Holmen AB (publ)", c: "Sweden" },
  { id: 22232, s: "WMI.AX", n: "WAM Microcap", c: "Australia" },
  { id: 22233, s: "LOGP.L", n: "Lansdowne Oil & Gas", c: "UK" },
  { id: 22234, s: "GABA.ST", n: "Gabather AB (publ)", c: "Sweden" },
  { id: 22235, s: "GCOR.ST", n: "Gaming Corps AB", c: "Sweden" },
  { id: 22236, s: "WBE.V", n: "Westbond Enterprises Co", c: "CA" },
  { id: 22237, s: "GENI.ST", n: "Generic Sweden publ AB", c: "Sweden" },
  { id: 22238, s: "MIGO.L", n: "Migo Opportunities Trust PLC", c: "UK" },
  { id: 22239, s: "QBR-A.TO", n: "Quebecor Inc", c: "CA" },
  { id: 22240, s: "IZAFE-B.ST", n: "iZafe Group AB Series B", c: "Sweden" },
  { id: 22241, s: "WAX.AX", n: "WAM Research", c: "Australia" },
  { id: 22242, s: "PVS-PG.TO", n: "Partners Value Split Corp Pref Series 9", c: "CA" },
  { id: 22243, s: "FFZY", n: "Fansfrenzy Co", c: "US" },
  { id: 22244, s: "IDUN-B.ST", n: "Idun Industrier AB Series B", c: "Sweden" },
  { id: 22245, s: "FEEXF", n: "Ferrexpo PLC", c: "US" },
  { id: 22246, s: "INCOAX.ST", n: "InCoax Networks AB", c: "Sweden" },
  { id: 22247, s: "MOBTL.IS", n: "Mobiltel Iletisim Hizmetleri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22248, s: "PVS-PF.TO", n: "Partners Value Split Co", c: "CA" },
  { id: 22249, s: "WGB.AX", n: "WAM Global", c: "Australia" },
  { id: 22250, s: "WMA.AX", n: "Wam Alternative Assets", c: "Australia" },
  { id: 22251, s: "ISOFOL.ST", n: "Isofol Medical AB", c: "Sweden" },
  { id: 22252, s: "INSP.ST", n: "Insplorion AB", c: "Sweden" },
  { id: 22253, s: "LPA.L", n: "LPA Group", c: "UK" },
  { id: 22254, s: "LORD.L", n: "Lords Grp Trading Plc", c: "UK" },
  { id: 22255, s: "IRLAB-A.ST", n: "IRLAB Therapeutics AB (publ)", c: "Sweden" },
  { id: 22256, s: "YEG.V", n: "Yorkton Equity Group Inc", c: "CA" },
  { id: 22257, s: "IS.ST", n: "Image Systems AB", c: "Sweden" },
  { id: 22258, s: "CHARGE.ST", n: "ChargePanel AB", c: "Sweden" },
  { id: 22259, s: "CEDER.ST", n: "Cedergrenska AB", c: "Sweden" },
  { id: 22260, s: "MAGEN.IS", n: "Margun Enerji Uretim Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22261, s: "KIMMR.IS", n: "Ersan Alisveris Hizmetleri & Gida Sanayi Ticaret AS", c: "Turkey" },
  { id: 22262, s: "IPU.L", n: "Invesco Perpetual UK Smaller Companies Investment Trust", c: "UK" },
  { id: 22263, s: "PCILT.IS", n: "Pc Iletisim ve Medya Hizmetleri Sanayi Ticaret AS", c: "Turkey" },
  { id: 22264, s: "SAMPO-SDB.ST", n: "Sampo A SDB", c: "Sweden" },
  { id: 22265, s: "PDV-PA.TO", n: "Prime Dividend Corp Pref A", c: "CA" },
  { id: 22266, s: "RO.ST", n: "Rolling Optics Holding AB (publ)", c: "Sweden" },
  { id: 22267, s: "KAKEL.ST", n: "Kakel Max AB (publ)", c: "Sweden" },
  { id: 22268, s: "RESQ.ST", n: "Resqunit AB", c: "Sweden" },
  { id: 22269, s: "JAGI.L", n: "JPMorgan Asia Growth & Income plc", c: "UK" },
  { id: 22270, s: "QUART-PREF.ST", n: "Quartiers Properties AB (publ)", c: "Sweden" },
  { id: 22271, s: "PURE.ST", n: "Purefun Group AB", c: "Sweden" },
  { id: 22272, s: "JCH.L", n: "JPMorgan Claverhouse Investment Trust Plc", c: "UK" },
  { id: 22273, s: "POLYG.ST", n: "Polygiene AB", c: "Sweden" },
  { id: 22274, s: "TMS.AX", n: "Tennant Minerals NL", c: "Australia" },
  { id: 22275, s: "REATO.ST", n: "Dome Energy AB", c: "Sweden" },
  { id: 22276, s: "PRKME.IS", n: "Park Elektrik Uretim Madencilik Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22277, s: "SBOK.ST", n: "ScandBook Holding AB", c: "Sweden" },
  { id: 22278, s: "PLEX.ST", n: "Plexian AB", c: "Sweden" },
  { id: 22279, s: "PION-B.ST", n: "Pion Group AB Series B", c: "Sweden" },
  { id: 22280, s: "OZGYO.IS", n: "Ozderici Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 22281, s: "NYTTO-PREF.ST", n: "Svenska Nyttobostader AB Pfd.", c: "Sweden" },
  { id: 22282, s: "OBAB.ST", n: "Online Brands Nordic AB", c: "Sweden" },
  { id: 22283, s: "PME.TO", n: "Sentry Select Primary Metals Co", c: "CA" },
  { id: 22284, s: "OGUN-B.ST", n: "Ogunsen AB Series B", c: "Sweden" },
  { id: 22285, s: "ONEF.ST", n: "Oneflow AB", c: "Sweden" },
  { id: 22286, s: "OPTER.ST", n: "Opter AB", c: "Sweden" },
  { id: 22287, s: "ZFR.V", n: "Zephyr Minerals", c: "CA" },
  { id: 22288, s: "JFJ.L", n: "JPMorgan Japanese Investment Trust", c: "UK" },
  { id: 22289, s: "ORGC.ST", n: "Organoclick AB", c: "Sweden" },
  { id: 22290, s: "OSSD.ST", n: "OssDsign AB", c: "Sweden" },
  { id: 22291, s: "OVZON.ST", n: "Ovzon AB", c: "Sweden" },
  { id: 22292, s: "OZRDN.IS", n: "Ozerden Plastik Sanayi", c: "Turkey" },
  { id: 22293, s: "OXE.ST", n: "OXE Marine AB", c: "Sweden" },
  { id: 22294, s: "PHARM.ST", n: "Pharmiva AB", c: "Sweden" },
  { id: 22295, s: "K2A-PREF.ST", n: "K2A Knaust & Andersson Fastigheter AB (publ)", c: "Sweden" },
  { id: 22296, s: "WA8.AX", n: "WARRIEDAR RESOURCES LIMITED", c: "Australia" },
  { id: 22297, s: "MWY.L", n: "Mid Wynd International Investment Trust plc", c: "UK" },
  { id: 22298, s: "FANDY", n: "FirstRand ADR", c: "US" },
  { id: 22299, s: "FIRST-B.ST", n: "First Venture Sweden AB Series B", c: "Sweden" },
  { id: 22300, s: "FAGI", n: "Full Alliance Group Inc", c: "US" },
  { id: 22301, s: "FLAT-B.ST", n: "Flat Capital AB Series B", c: "Sweden" },
  { id: 22302, s: "LIDFA.IS", n: "Lider Faktoring AS", c: "Turkey" },
  { id: 22303, s: "MMIT.L", n: "Mobius Investment Trust PLC", c: "UK" },
  { id: 22304, s: "MIX.L", n: "Mobeus Income And Growth Vct Plc", c: "UK" },
  { id: 22305, s: "FNOVA-B.ST", n: "Fortinova Fastigheter AB Series B", c: "Sweden" },
  { id: 22306, s: "RY-PJ.TO", n: "Royal Bank of CA Pref J", c: "CA" },
  { id: 22307, s: "RY-PH.TO", n: "Royal Bank of CA Pref H", c: "CA" },
  { id: 22308, s: "FOOT-B.ST", n: "Footway Group AB Series B", c: "Sweden" },
  { id: 22309, s: "ERMA.ST", n: "Enorama Pharma AB", c: "Sweden" },
  { id: 22310, s: "FOOT-PREF.ST", n: "Footway Group AB Pfd.", c: "Sweden" },
  { id: 22311, s: "KRGYO.IS", n: "Korfez Gayrimenkul Yatirim", c: "Turkey" },
  { id: 22312, s: "SCY.TO", n: "Scandium Interna", c: "CA" },
  { id: 22313, s: "LIDER.IS", n: "LDR Turizm AS", c: "Turkey" },
  { id: 22314, s: "MNP.L", n: "Martin Currie Global Portfolio Trust plc", c: "UK" },
  { id: 22315, s: "KRSTL.IS", n: "Kristal Kola ve Mesrubat Sanayi Ticaret AS", c: "Turkey" },
  { id: 22316, s: "KRVGD.IS", n: "Kervan Gıda Sanayi Ve Ticaret As", c: "Turkey" },
  { id: 22317, s: "RY-PO.TO", n: "Royal Bank of CA Pref O", c: "CA" },
  { id: 22318, s: "ELIC.ST", n: "Elicera Therapeutics AB", c: "Sweden" },
  { id: 22319, s: "ELLWEE.ST", n: "Ellwee AB", c: "Sweden" },
  { id: 22320, s: "ELN.ST", n: "Ellen AB (publ)", c: "Sweden" },
  { id: 22321, s: "ELON.ST", n: "Elon AB (publ)", c: "Sweden" },
  { id: 22322, s: "ELOS-B.ST", n: "Elos Medtech AB (publ)", c: "Sweden" },
  { id: 22323, s: "EMIL-PREF.ST", n: "Fastighetsbolaget Emilshus AB Pfd", c: "Sweden" },
  { id: 22324, s: "KUYAS.IS", n: "Kuyas Yatirim AS", c: "Turkey" },
  { id: 22325, s: "RY-PN.TO", n: "Royal Bank of CA Pref N", c: "CA" },
  { id: 22326, s: "3597.TWO", n: "Avertronics", c: "TW" },
  { id: 22327, s: "KTSKR.IS", n: "Kutahya Seker Fabrikasi AS", c: "Turkey" },
  { id: 22328, s: "MTU.L", n: "Montanaro UK Smaller Companies Investment Trust PLC", c: "UK" },
  { id: 22329, s: "EFFH.ST", n: "Effnetplattformen Holding AB", c: "Sweden" },
  { id: 22330, s: "RY-PM.TO", n: "Royal Bank of CA Pref M", c: "CA" },
  { id: 22331, s: "MIG3.L", n: "Maven Income And Growth Vct 3 Plc", c: "UK" },
  { id: 22332, s: "GGEO.ST", n: "Guideline Geo AB (publ)", c: "Sweden" },
  { id: 22333, s: "INV.L", n: "The Investment Company plc", c: "UK" },
  { id: 22334, s: "DIVI.L", n: "Diverse Income Trust PLC", c: "UK" },
  { id: 22335, s: "CNS.L", n: "Corero Network Security plc", c: "UK" },
  { id: 22336, s: "RGL.AX", n: "Riversgold", c: "Australia" },
  { id: 22337, s: "FFH-PF.TO", n: "Fairfax Financial Holdings Pref F", c: "CA" },
  { id: 22338, s: "SATS.OL", n: "Sats ASA", c: "Norway" },
  { id: 22339, s: "SB68.OL", n: "Sparebank 68 Grader Nord", c: "Norway" },
  { id: 22340, s: "RB6.AX", n: "Rubix Resources", c: "Australia" },
  { id: 22341, s: "RAG.AX", n: "Ragnar Metals", c: "Australia" },
  { id: 22342, s: "YAYLA.IS", n: "Yayla Enerji Uretim Turizm ve", c: "Turkey" },
  { id: 22343, s: "YBTAS.IS", n: "Yibitas Yozgat Isci Birligi Insaat Malzemeleri Ticaret Sanayi AS", c: "Turkey" },
  { id: 22344, s: "R3D.AX", n: "R3D Resources", c: "Australia" },
  { id: 22345, s: "C4XD.L", n: "C4X Discovery Holdings PLC", c: "UK" },
  { id: 22346, s: "FFH-PM.TO", n: "Fairfax Financial Holdings Pref M", c: "CA" },
  { id: 22347, s: "SOFTX.OL", n: "SoftOx Solutions AS", c: "Norway" },
  { id: 22348, s: "SOR.OL", n: "Sparebanken Sor", c: "Norway" },
  { id: 22349, s: "SPOG.OL", n: "Sparebanken Ost", c: "Norway" },
  { id: 22350, s: "COBR.L", n: "Cobra Resources PLC", c: "UK" },
  { id: 22351, s: "RYD.AX", n: "Ryder Capital", c: "Australia" },
  { id: 22352, s: "RRR.AX", n: "Revolver Resources Holdings", c: "Australia" },
  { id: 22353, s: "RWD.AX", n: "Reward Minerals", c: "Australia" },
  { id: 22354, s: "RPM.AX", n: "RPM Automotive Group", c: "Australia" },
  { id: 22355, s: "EMA-PH.TO", n: "Emera Inc", c: "CA" },
  { id: 22356, s: "CAMBI.OL", n: "Cambi ASA", c: "Norway" },
  { id: 22357, s: "CAN.OL", n: "Canopy Holdings AS", c: "Norway" },
  { id: 22358, s: "RXH.AX", n: "Rewardle Holdings", c: "Australia" },
  { id: 22359, s: "RWL.AX", n: "Rubicon Water", c: "Australia" },
  { id: 22360, s: "ACAI", n: "Safeplus International Holdings", c: "US" },
  { id: 22361, s: "ENB-PA.TO", n: "Enbridge Inc Pref A", c: "CA" },
  { id: 22362, s: "CAPSL.OL", n: "Co2 Capsol AS", c: "Norway" },
  { id: 22363, s: "RVT.AX", n: "Richmond Vanadium Technology.", c: "Australia" },
  { id: 22364, s: "CIRCA.OL", n: "Circa Group AS", c: "Norway" },
  { id: 22365, s: "DELT.L", n: "Deltic Energy PLC", c: "UK" },
  { id: 22366, s: "CYVIZ.OL", n: "Cyviz AS", c: "Norway" },
  { id: 22367, s: "RSH.AX", n: "Respiri", c: "Australia" },
  { id: 22368, s: "SADG.OL", n: "Sandnes Sparebank", c: "Norway" },
  { id: 22369, s: "FN-PB.TO", n: "First National Financial Corp Pref B", c: "CA" },
  { id: 22370, s: "YGGYO.IS", n: "Yeni Gimat Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 22371, s: "IFC-PG.TO", n: "Intact Financial Co", c: "CA" },
  { id: 22372, s: "RFT.AX", n: "Rectifier Technologies", c: "Australia" },
  { id: 22373, s: "NCOD.OL", n: "Norcod As", c: "Norway" },
  { id: 22374, s: "UZERB.IS", n: "Uzertas Boya Sanayi Ticaret ve Yatirim AS", c: "Turkey" },
  { id: 22375, s: "RFA.AX", n: "Rare Foods Australia", c: "Australia" },
  { id: 22376, s: "GNGBY", n: "Getinge Industrier AB", c: "US" },
  { id: 22377, s: "ABBB", n: "Auburn Bancorp Inc", c: "US" },
  { id: 22378, s: "NEXT.OL", n: "Next Biometrics Group ASA", c: "Norway" },
  { id: 22379, s: "ENB-PJ.TO", n: "Enbridge Inc Pref 7", c: "CA" },
  { id: 22380, s: "GNNDY", n: "GN Store Nord A/S ADR", c: "US" },
  { id: 22381, s: "IFC-PF.TO", n: "Intact Financial Corp Pref", c: "CA" },
  { id: 22382, s: "NISB.OL", n: "Nidaros Sparebank", c: "Norway" },
  { id: 22383, s: "VBTYZ.IS", n: "Vbt Yazilim AS", c: "Turkey" },
  { id: 22384, s: "CLCO.L", n: "Cloudcoco Group PLC", c: "UK" },
  { id: 22385, s: "VERTU.IS", n: "Verusaturk Girisim Sermayesi", c: "Turkey" },
  { id: 22386, s: "MOBA.OL", n: "Morrow Bank ASA", c: "Norway" },
  { id: 22387, s: "ROMER.OL", n: "Romerike Sparebank", c: "Norway" },
  { id: 22388, s: "GWO-PT.TO", n: "Great-West Lifeco Inc", c: "CA" },
  { id: 22389, s: "QGL.AX", n: "Quantum Graphite", c: "Australia" },
  { id: 22390, s: "BVX.L", n: "BiVictriX Therapeutics PLC", c: "UK" },
  { id: 22391, s: "GWO-PS.TO", n: "Great West Lifeco Inc Pref S", c: "CA" },
  { id: 22392, s: "FRX.TO", n: "Fennec Pharmaceuticals Inc", c: "CA" },
  { id: 22393, s: "FTS-PM.TO", n: "Fortis Inc Pref M", c: "CA" },
  { id: 22394, s: "FTU.TO", n: "US Financial 15 Split Co", c: "CA" },
  { id: 22395, s: "FTU-PB.TO", n: "US Financial 15 Split Corp Pref B", c: "CA" },
  { id: 22396, s: "QFE.AX", n: "Quickfee", c: "Australia" },
  { id: 22397, s: "ROMSB.OL", n: "Romsdal Sparebank", c: "Norway" },
  { id: 22398, s: "QEM.AX", n: "QEM", c: "Australia" },
  { id: 22399, s: "TEKNA.OL", n: "Tekna Holding AS", c: "Norway" },
  { id: 22400, s: "GRID.TO", n: "Tantalus Systems Holding Inc", c: "CA" },
  { id: 22401, s: "BSV.L", n: "British Smaller Companies Vct Plc", c: "UK" },
  { id: 22402, s: "BWSA.L", n: "Bristol & West plc 8.125% PRF GBP1", c: "UK" },
  { id: 22403, s: "RCL.AX", n: "ReadCloud", c: "Australia" },
  { id: 22404, s: "DGI.L", n: "DG Innovate PLC", c: "UK" },
  { id: 22405, s: "AKVA.OL", n: "Akva Group", c: "Norway" },
  { id: 22406, s: "GIAB.ST", n: "Godsinlosen Nordic AB", c: "Sweden" },
  { id: 22407, s: "DWHT.L", n: "Dewhurst", c: "UK" },
  { id: 22408, s: "MEPET.IS", n: "Mepet Metro Petrol ve Tesisleri Sanayi Ticaret AS", c: "Turkey" },
  { id: 22409, s: "MEDTR.IS", n: "Meditera Tibbi Malzeme Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22410, s: "WLF.V", n: "Wolfden Resources Corporation", c: "CA" },
  { id: 22411, s: "PWF-PT.TO", n: "Power Financial Corp Pref T", c: "CA" },
  { id: 22412, s: "CDMIL.ST", n: "CodeMill AB", c: "Sweden" },
  { id: 22413, s: "TAGM-B.ST", n: "TagMaster AB Series B", c: "Sweden" },
  { id: 22414, s: "HINT.L", n: "Henderson International Income Trust PLC", c: "UK" },
  { id: 22415, s: "TURGG.IS", n: "Turker Proje Gayrimenkul Ve Yatirim Gelistirme AS", c: "Turkey" },
  { id: 22416, s: "ENB-PFE.TO", n: "Enbridge Inc Pref 13", c: "CA" },
  { id: 22417, s: "UFUK.IS", n: "Ufuk Yatirim Yonetim Ve Gayrimenkul AS", c: "Turkey" },
  { id: 22418, s: "ROG.AX", n: "Red Sky Energy", c: "Australia" },
  { id: 22419, s: "GEOS.OL", n: "Golden Energy Offshore Services AS", c: "Norway" },
  { id: 22420, s: "RNX.AX", n: "Renegade Exploration", c: "Australia" },
  { id: 22421, s: "ULAS.IS", n: "Ulaslar Turizm Yatirimlari ve Dayanikli Tuketim Mallari Ticaret Pazarlama AS", c: "Turkey" },
  { id: 22422, s: "RNT.AX", n: "RENT.COM.AU", c: "Australia" },
  { id: 22423, s: "WRK.AX", n: "WRKR", c: "Australia" },
  { id: 22424, s: "MEN.L", n: "President Energy Plc", c: "UK" },
  { id: 22425, s: "MERIT.IS", n: "Merit Turizm Yatirim ve Isletme AS", c: "Turkey" },
  { id: 22426, s: "WINS.V", n: "Winshear Gold Co", c: "CA" },
  { id: 22427, s: "AFYG", n: "Affinity Gold Co", c: "US" },
  { id: 22428, s: "GOTL-A.ST", n: "Gotland Rederi AB Series A", c: "Sweden" },
  { id: 22429, s: "MANAS.IS", n: "Manas Enerji Yonetimi Sanayi & Ticaret AS", c: "Turkey" },
  { id: 22430, s: "MHN.L", n: "Menhaden Capital PLC", c: "UK" },
  { id: 22431, s: "GREAT.ST", n: "Greater Than AB", c: "Sweden" },
  { id: 22432, s: "MARKA.IS", n: "Marka Yatirim Holding AS", c: "Turkey" },
  { id: 22433, s: "MAV4.L", n: "Maven Income and Growth VCT 4 PLC", c: "UK" },
  { id: 22434, s: "METRO.IS", n: "Metro Ticari ve Mali Yatirimlar Holding AS", c: "Turkey" },
  { id: 22435, s: "PWF-PF.TO", n: "Power Financial 5.25 Sr E Prf", c: "CA" },
  { id: 22436, s: "HILB-B.ST", n: "Hilbert Group AB Series B", c: "Sweden" },
  { id: 22437, s: "METUR.IS", n: "Metemtur Yatırım Enerji Turizm ve İnşaat A.Ş.", c: "Turkey" },
  { id: 22438, s: "HIGHCO-B.ST", n: "High Coast Distillery AB", c: "Sweden" },
  { id: 22439, s: "FCIC", n: "FCCC Inc", c: "US" },
  { id: 22440, s: "PRM-PA.TO", n: "Big Pharma Split Co", c: "CA" },
  { id: 22441, s: "RNE.AX", n: "Renu Energy", c: "Australia" },
  { id: 22442, s: "ENB-PFU.TO", n: "Enbridge Inc Pref L", c: "CA" },
  { id: 22443, s: "RMY.AX", n: "RMA Global", c: "Australia" },
  { id: 22444, s: "ECN-PC.TO", n: "ECN Capital Co", c: "CA" },
  { id: 22445, s: "HYN.OL", n: "Hynion AS", c: "Norway" },
  { id: 22446, s: "MELG.OL", n: "Melhus Sparebank", c: "Norway" },
  { id: 22447, s: "FROY.OL", n: "Froy ASA", c: "Norway" },
  { id: 22448, s: "ENB-PFA.TO", n: "Enbridge Inc Pref 9", c: "CA" },
  { id: 22449, s: "EME.L", n: "Empyrean Energy Plc", c: "UK" },
  { id: 22450, s: "TMSN.IS", n: "Tumosan Motor ve Traktor Sanayi AS", c: "Turkey" },
  { id: 22451, s: "YOYO.", n: "Orderyoyo AS", c: "Denmark" },
  { id: 22452, s: "RHT.AX", n: "Resonance Health", c: "Australia" },
  { id: 22453, s: "EFN-PC.TO", n: "Element Fleet Management Corp Pref C", c: "CA" },
  { id: 22454, s: "AEGA.OL", n: "Aega ASA", c: "Norway" },
  { id: 22455, s: "SBR.AX", n: "Sabre Resources", c: "Australia" },
  { id: 22456, s: "SB2.AX", n: "Salter Brothers Emerging", c: "Australia" },
  { id: 22457, s: "EMA-PB.TO", n: "Emera Inc Pref B", c: "CA" },
  { id: 22458, s: "TSGYO.IS", n: "TSKB Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 22459, s: "KRAB.OL", n: "Kraft Bank Asa", c: "Norway" },
  { id: 22460, s: "UNLU.IS", n: "Unlu Yatirim Holding AS", c: "Turkey" },
  { id: 22461, s: "CRWN.L", n: "Crown Place VCT PLC", c: "UK" },
  { id: 22462, s: "RLC.AX", n: "Reedy Lagoon Corporation", c: "Australia" },
  { id: 22463, s: "CRU.L", n: "Coral Products", c: "UK" },
  { id: 22464, s: "HELG.OL", n: "Helgeland Sparebank", c: "Norway" },
  { id: 22465, s: "TUREX.IS", n: "Tureks Turizm Tasimacilik AS", c: "Turkey" },
  { id: 22466, s: "HSPG.OL", n: "Holand og Setskog Sparebank", c: "Norway" },
  { id: 22467, s: "HYON.OL", n: "Hyon AS", c: "Norway" },
  { id: 22468, s: "CRDL.L", n: "Cordel Group PLC", c: "UK" },
  { id: 22469, s: "ENB-PFV.TO", n: "Enbridge Inc Pref 5", c: "CA" },
  { id: 22470, s: "RHY.AX", n: "Rhythm Biosciences", c: "Australia" },
  { id: 22471, s: "JAREN.OL", n: "Jaeren Sparebank", c: "Norway" },
  { id: 22472, s: "ULUUN.IS", n: "Ulusoy Un Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22473, s: "RIM.AX", n: "Rimfire Pacific Mining NL", c: "Australia" },
  { id: 22474, s: "CPP.L", n: "CPPGroup Plc", c: "UK" },
  { id: 22475, s: "UMPAS.IS", n: "Umpas Holding AS", c: "Turkey" },
  { id: 22476, s: "KMCP.OL", n: "Kmc Properties ASA", c: "Norway" },
  { id: 22477, s: "PEGYO.IS", n: "Pera Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 22478, s: "SDIP-PREF.ST", n: "Sdiptech AB (publ)", c: "Sweden" },
  { id: 22479, s: "002965.SZ", n: "Lucky Harvest", c: "CH" },
  { id: 22480, s: "MRX.F", n: "Mister Spex SE", c: "GER" },
  { id: 22481, s: "2397.TW", n: "DFI Inc", c: "TW" },
  { id: 22482, s: "HYQ.F", n: "Hypoport SE", c: "GER" },
  { id: 22483, s: "I7N.F", n: "ItN Nanovation AG", c: "GER" },
  { id: 22484, s: "WUG3.DU", n: "Westag & Getalit AG vz. (Pref Shares)", c: "GER" },
  { id: 22485, s: "IC8.F", n: "InCity Immobilien AG", c: "GER" },
  { id: 22486, s: "WUG.DU", n: "Westag & Getalit AG", c: "GER" },
  { id: 22487, s: "2390.TW", n: "Everspring Industry", c: "TW" },
  { id: 22488, s: "WIG1.DU", n: "Sporttotal AG", c: "GER" },
  { id: 22489, s: "AUMC", n: "Auryn Mining Co", c: "US" },
  { id: 22490, s: "IS8.F", n: "ifa systems AG", c: "GER" },
  { id: 22491, s: "BRYN", n: "Bryn Resources Inc", c: "US" },
  { id: 22492, s: "ISHA.F", n: "INTERSHOP Communications Aktiengesellschaft", c: "GER" },
  { id: 22493, s: "BSSP", n: "Bassline Productions", c: "US" },
  { id: 22494, s: "IVU.F", n: "IVU Traffic Technologies AG", c: "GER" },
  { id: 22495, s: "2358.TW", n: "Ting Sin", c: "TW" },
  { id: 22496, s: "XCX.DU", n: "coinIX GmbH & Co. KGaA", c: "GER" },
  { id: 22497, s: "XD4.DU", n: "Strabag SE", c: "GER" },
  { id: 22498, s: "HXCK.F", n: "ERNST RUSS AG NA O.N.", c: "GER" },
  { id: 22499, s: "MBQ.F", n: "Mobotix AG", c: "GER" },
  { id: 22500, s: "5222.TW", n: "Transcom Inc", c: "TW" },
  { id: 22501, s: "PAG.DU", n: "PREOS Global Office Real Estate & Technology AG", c: "GER" },
  { id: 22502, s: "MFL2.F", n: "Marudai Food Co.", c: "GER" },
  { id: 22503, s: "2321.TW", n: "Tecom", c: "TW" },
  { id: 22504, s: "KWS.F", n: "KWS SAAT SE & Co. KGaA", c: "GER" },
  { id: 22505, s: "RLV.DU", n: "RheinLand Holding AG", c: "GER" },
  { id: 22506, s: "MBK.F", n: "Merkur PrivatBank KgaA", c: "GER" },
  { id: 22507, s: "KSW.F", n: "KST Beteiligungs AG", c: "GER" },
  { id: 22508, s: "MBB.F", n: "MBB SE", c: "GER" },
  { id: 22509, s: "MA10.F", n: "BINECT AG INH O.N.", c: "GER" },
  { id: 22510, s: "M7U.F", n: "Nynomic AG", c: "GER" },
  { id: 22511, s: "BZZUY", n: "Buzzi Unicem SpA ADR", c: "US" },
  { id: 22512, s: "5244.TW", n: "Brightek Optoelectronic", c: "TW" },
  { id: 22513, s: "KWG.F", n: "KHD Humboldt Wedag International AG", c: "GER" },
  { id: 22514, s: "2359.TW", n: "Solomon Technology Co", c: "TW" },
  { id: 22515, s: "KSC.F", n: "KPS AG", c: "GER" },
  { id: 22516, s: "KSB3.F", n: "KSB SE & Co. KGaA", c: "GER" },
  { id: 22517, s: "HPHA.DU", n: "Heidelberg Pharma AG", c: "GER" },
  { id: 22518, s: "2025.TW", n: "Chien Shing Stainless Steel", c: "TW" },
  { id: 22519, s: "2024.TW", n: "Chih Lien Industrial", c: "TW" },
  { id: 22520, s: "PCZ.F", n: "ProCredit Holding AG & Co KGaA", c: "GER" },
  { id: 22521, s: "2022.TW", n: "Tycoons Group Enterprise", c: "TW" },
  { id: 22522, s: "2020.TW", n: "Mayer Steel Pipe Co", c: "TW" },
  { id: 22523, s: "2017.TW", n: "Quintain Steel", c: "TW" },
  { id: 22524, s: "5706.TW", n: "Phoenix Tours International Inc", c: "TW" },
  { id: 22525, s: "HRPK.DU", n: "7C Solarparken AG", c: "GER" },
  { id: 22526, s: "HOZ.DU", n: "Philipp Holzmann AG", c: "GER" },
  { id: 22527, s: "2012.TW", n: "Chun Yu Works &", c: "TW" },
  { id: 22528, s: "PGN.F", n: "paragon GmbH & Co. KGaA", c: "GER" },
  { id: 22529, s: "1810.TW", n: "Hocheng Co", c: "TW" },
  { id: 22530, s: "PZS.F", n: "Scherzer & Co. AG", c: "GER" },
  { id: 22531, s: "PYR.F", n: "PYRUM INNOV.AG NA O.N.", c: "GER" },
  { id: 22532, s: "PBY.F", n: "Publity AG", c: "GER" },
  { id: 22533, s: "ICP.DU", n: "Panamax AG", c: "GER" },
  { id: 22534, s: "TTO.DU", n: "TTL Beteiligungs- und Grundbesitz-AG", c: "GER" },
  { id: 22535, s: "WEW.DU", n: "Westwing Group AG", c: "GER" },
  { id: 22536, s: "2365.TW", n: "KYE Systems Co", c: "TW" },
  { id: 22537, s: "KTEK.F", n: "KATEK SE INH O.N.", c: "GER" },
  { id: 22538, s: "VTSC.DU", n: "Vitesco Technologies Group Aktiengesellschaft", c: "GER" },
  { id: 22539, s: "KCO.F", n: "Klöckner & Co SE", c: "GER" },
  { id: 22540, s: "KA8.F", n: "Klassik Radio AG", c: "GER" },
  { id: 22541, s: "JUN3.F", n: "Jungheinrich Aktiengesellschaft", c: "GER" },
  { id: 22542, s: "4934.TW", n: "Tainergy Tech", c: "TW" },
  { id: 22543, s: "5533.TW", n: "Founding Construction Development", c: "TW" },
  { id: 22544, s: "4930.TW", n: "Star Comgistic Capital", c: "TW" },
  { id: 22545, s: "4942.TW", n: "Chia Chang", c: "TW" },
  { id: 22546, s: "2305.TW", n: "Microtek International Inc", c: "TW" },
  { id: 22547, s: "MSAG.F", n: "MS Industrie AG", c: "GER" },
  { id: 22548, s: "2030.TW", n: "Froch Enterprise", c: "TW" },
  { id: 22549, s: "2029.TW", n: "Sheng Yu Steel", c: "TW" },
  { id: 22550, s: "MPCK.F", n: "MPC Münchmeyer Petersen Capital AG", c: "GER" },
  { id: 22551, s: "BYRG", n: "Buyer Group International Inc", c: "US" },
  { id: 22552, s: "6024.TW", n: "Capital Futures Co", c: "TW" },
  { id: 22553, s: "SBE.DU", n: "SPOBAG AG", c: "GER" },
  { id: 22554, s: "6216.TW", n: "DrayTek Co", c: "TW" },
  { id: 22555, s: "TVD6.F", n: "German Values Property Group AG", c: "GER" },
  { id: 22556, s: "T2G.F", n: "Tradegate AG Wertpapierhandelsbank", c: "GER" },
  { id: 22557, s: "T3T1.F", n: "Seven Principles AG", c: "GER" },
  { id: 22558, s: "T5O.F", n: "CytoTools AG", c: "GER" },
  { id: 22559, s: "TCU.F", n: "TC Unterhaltungselektronik AG", c: "GER" },
  { id: 22560, s: "COP.DU", n: "Compugroup Medical SE & Co. KGaA", c: "GER" },
  { id: 22561, s: "1531.TW", n: "Kaulin Mfg", c: "TW" },
  { id: 22562, s: "1530.TW", n: "Awea Mechantronic", c: "TW" },
  { id: 22563, s: "1528.TW", n: "Anderson Industrial Co", c: "TW" },
  { id: 22564, s: "TION.F", n: "TION RENEWABLES AG", c: "GER" },
  { id: 22565, s: "6192.TW", n: "Lumax International Co", c: "TW" },
  { id: 22566, s: "BYW6.DU", n: "BayWa AktienGesellschaft Vink.N", c: "GER" },
  { id: 22567, s: "1525.TW", n: "Kian Shen Co", c: "TW" },
  { id: 22568, s: "6197.TW", n: "Jess-Link Products", c: "TW" },
  { id: 22569, s: "6189.TW", n: "Promate Electronic", c: "TW" },
  { id: 22570, s: "V6C.F", n: "Viscom AG", c: "GER" },
  { id: 22571, s: "1451.TW", n: "Nien Hsing Textile", c: "TW" },
  { id: 22572, s: "AEIN.DU", n: "Allgeier SE", c: "GER" },
  { id: 22573, s: "UUU.F", n: "3U Holding AG", c: "GER" },
  { id: 22574, s: "1452.TW", n: "Hong Yi Fiber Industry", c: "TW" },
  { id: 22575, s: "1437.TW", n: "GTM Holdings Co", c: "TW" },
  { id: 22576, s: "1438.TW", n: "SanDi Properties", c: "TW" },
  { id: 22577, s: "1439.TW", n: "Chuwa Wool Industry Co TW", c: "TW" },
  { id: 22578, s: "IZZ.V", n: "International Prospect Ventures", c: "CA" },
  { id: 22579, s: "ARL.DU", n: "Aareal Bank AG", c: "GER" },
  { id: 22580, s: "1449.TW", n: "Chia Her Industrial", c: "TW" },
  { id: 22581, s: "ARO.DU", n: "Arcandor AG", c: "GER" },
  { id: 22582, s: "VAO.F", n: "Vapiano SE", c: "GER" },
  { id: 22583, s: "V3S.F", n: "Vectron Systems AG", c: "GER" },
  { id: 22584, s: "UZU.F", n: "Uzin Utz AG", c: "GER" },
  { id: 22585, s: "6226.TW", n: "Para Light Electronics", c: "TW" },
  { id: 22586, s: "1447.TW", n: "Li Peng Enterprise", c: "TW" },
  { id: 22587, s: "6201.TW", n: "Ya Horng Electronic", c: "TW" },
  { id: 22588, s: "BYW.DU", n: "BayWa AktienGesellschaft", c: "GER" },
  { id: 22589, s: "6205.TW", n: "Chant Sincere", c: "TW" },
  { id: 22590, s: "M3BK.F", n: "Pyramid AG", c: "GER" },
  { id: 22591, s: "TIS.DU", n: "tiscon AG", c: "GER" },
  { id: 22592, s: "LIK.F", n: "LIMES SCHLOSSKLINIK. O.N.", c: "GER" },
  { id: 22593, s: "LPK.F", n: "LPKF Laser & Electronics AG", c: "GER" },
  { id: 22594, s: "SWVK.DU", n: "SolarWorld Aktiengesellschaft", c: "GER" },
  { id: 22595, s: "M0YN.F", n: "Mynaric AG", c: "GER" },
  { id: 22596, s: "SIX3.DU", n: "Sixt SE", c: "GER" },
  { id: 22597, s: "SFQ.DU", n: "SAF-Holland SE", c: "GER" },
  { id: 22598, s: "6108.TW", n: "APCB Inc", c: "TW" },
  { id: 22599, s: "M3V.F", n: "MeVis Medical Solutions AG", c: "GER" },
  { id: 22600, s: "M4N.F", n: "Muehlhan AG", c: "GER" },
  { id: 22601, s: "4989.TW", n: "LCY Technology Co", c: "TW" },
  { id: 22602, s: "SBX.DU", n: "SynBiotic SE", c: "GER" },
  { id: 22603, s: "M5S.F", n: "Marna Beteiligungen AG", c: "GER" },
  { id: 22604, s: "M5Z.F", n: "Manz AG", c: "GER" },
  { id: 22605, s: "LDF.F", n: "LINUS DIGITAL FINANCE AG", c: "GER" },
  { id: 22606, s: "HBD1.DU", n: "Philomaxcap AG", c: "GER" },
  { id: 22607, s: "1521.TW", n: "Ta Yih Industrial", c: "TW" },
  { id: 22608, s: "1474.TW", n: "Honmyue Enterprise", c: "TW" },
  { id: 22609, s: "TUR.F", n: "Turbon AG", c: "GER" },
  { id: 22610, s: "6215.TW", n: "Aurotek Co", c: "TW" },
  { id: 22611, s: "B4B.DU", n: "Metro AG", c: "GER" },
  { id: 22612, s: "B4B3.DU", n: "Metro AG", c: "GER" },
  { id: 22613, s: "TSS.F", n: "InnoTec TSS AG", c: "GER" },
  { id: 22614, s: "1506.TW", n: "Right Way Industrial", c: "TW" },
  { id: 22615, s: "6209.TW", n: "Kinko Optical", c: "TW" },
  { id: 22616, s: "FPE3.DU", n: "Fuchs Petrolub SE", c: "GER" },
  { id: 22617, s: "BIO.DU", n: "Biotest Aktiengesellschaft", c: "GER" },
  { id: 22618, s: "1515.TW", n: "Rexon Industrial Co", c: "TW" },
  { id: 22619, s: "1516.TW", n: "Falcon Power", c: "TW" },
  { id: 22620, s: "1517.TW", n: "Lee Chi Enterprises", c: "TW" },
  { id: 22621, s: "CBYI", n: "Cal-Bay Intl Inc", c: "US" },
  { id: 22622, s: "TRU.F", n: "TRAUMHAUS AG INH O.N.", c: "GER" },
  { id: 22623, s: "PWO.F", n: "Progress-Werk Oberkirch AG", c: "GER" },
  { id: 22624, s: "PSAN.F", n: "PSI Software AG", c: "GER" },
  { id: 22625, s: "AQN-PD.TO", n: "Algonquin Power & Utilities Co", c: "CA" },
  { id: 22626, s: "NNH.ST", n: "New Nordic Healthbrands AB (publ)", c: "Sweden" },
  { id: 22627, s: "KOPY.ST", n: "Kopy Goldfields publ AB", c: "Sweden" },
  { id: 22628, s: "L2S.ST", n: "Learning 2 Sleep L2S AB", c: "Sweden" },
  { id: 22629, s: "VNT.AX", n: "Ventia Services Group", c: "Australia" },
  { id: 22630, s: "MISE.ST", n: "Misen Energy AB", c: "Sweden" },
  { id: 22631, s: "LADYLU.ST", n: "LL Lucky Games publ AB", c: "Sweden" },
  { id: 22632, s: "LIDDS.ST", n: "Lidds AB", c: "Sweden" },
  { id: 22633, s: "LIPUM.ST", n: "Lipum AB", c: "Sweden" },
  { id: 22634, s: "LITI.ST", n: "Litium AB (publ)", c: "Sweden" },
  { id: 22635, s: "LOGI-A.ST", n: "Logistea A", c: "Sweden" },
  { id: 22636, s: "LOHILO.ST", n: "Lohilo Foods AB", c: "Sweden" },
  { id: 22637, s: "LABS.L", n: "Life Science REIT PLC", c: "UK" },
  { id: 22638, s: "LPGO.ST", n: "Lipigon Pharmaceuticals AB", c: "Sweden" },
  { id: 22639, s: "PPL-PC.TO", n: "Pembina Pipeline Corp Pref C", c: "CA" },
  { id: 22640, s: "KRS.L", n: "Keras Resources PLC", c: "UK" },
  { id: 22641, s: "LXB.ST", n: "Luxbright AB", c: "Sweden" },
  { id: 22642, s: "KONT.ST", n: "Kontigo Care AB", c: "Sweden" },
  { id: 22643, s: "PPL-PE.TO", n: "Pembina Pipeline Corp Pref E", c: "CA" },
  { id: 22644, s: "NTGAZ.IS", n: "Naturelgaz Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22645, s: "MIDW-A.ST", n: "Midway Holding AB ser. A", c: "Sweden" },
  { id: 22646, s: "KAY.L", n: "Kings Arms Yard Vct Plc", c: "UK" },
  { id: 22647, s: "KAV.L", n: "Kavango Resources PLC", c: "UK" },
  { id: 22648, s: "NORB-B.ST", n: "Nordisk Bergteknik AB Series B", c: "Sweden" },
  { id: 22649, s: "OYAYO.IS", n: "OYAK Yatirim Ortakligi AS", c: "Turkey" },
  { id: 22650, s: "NORDIG.ST", n: "Nord Insuretech Group AB", c: "Sweden" },
  { id: 22651, s: "MOBA.ST", n: "MOBA Network publ AB", c: "Sweden" },
  { id: 22652, s: "KDEV.ST", n: "Karolinska Development AB ser. B", c: "Sweden" },
  { id: 22653, s: "KLIMAT.ST", n: "Klimator AB", c: "Sweden" },
  { id: 22654, s: "KEBNI-B.ST", n: "KebNi AB (publ)", c: "Sweden" },
  { id: 22655, s: "KENH.ST", n: "Kentima Holding publ AB", c: "Sweden" },
  { id: 22656, s: "KILI.ST", n: "Kiliaro AB", c: "Sweden" },
  { id: 22657, s: "LINV.L", n: "Lendinvest PLC", c: "UK" },
  { id: 22658, s: "KINV-A.ST", n: "Kinnevik Investment AB ser. A", c: "Sweden" },
  { id: 22659, s: "PPL-PFE.TO", n: "Pembina Pipeline Corp Pref Series 25", c: "CA" },
  { id: 22660, s: "PPL-PA.TO", n: "Pembina Pipeline Corp Pref A", c: "CA" },
  { id: 22661, s: "MAGLE.ST", n: "Magle Chemoswed Holding Ab", c: "Sweden" },
  { id: 22662, s: "MANG.ST", n: "Mangold Fondkommission AB", c: "Sweden" },
  { id: 22663, s: "HSL.L", n: "Henderson Smaller Cos Inv Tst", c: "UK" },
  { id: 22664, s: "SONE.ST", n: "Sonetel AB", c: "Sweden" },
  { id: 22665, s: "SOZAP.ST", n: "Sozap Publ AB", c: "Sweden" },
  { id: 22666, s: "SPAGO.ST", n: "Spago Nanomedical AB", c: "Sweden" },
  { id: 22667, s: "PKENT.IS", n: "Petrokent Turizm AS", c: "Turkey" },
  { id: 22668, s: "SPIFF.ST", n: "Spiffbet AB", c: "Sweden" },
  { id: 22669, s: "BCE-PL.TO", n: "BCE Inc Pref AL", c: "CA" },
  { id: 22670, s: "BCE-PM.TO", n: "Bce Inc Pref M", c: "CA" },
  { id: 22671, s: "SECI.ST", n: "SECITS Holding AB (publ)", c: "Sweden" },
  { id: 22672, s: "HRI.L", n: "Herald Investment Trust", c: "UK" },
  { id: 22673, s: "HOT.L", n: "Henderson Opportunities Trust plc", c: "UK" },
  { id: 22674, s: "SVOL-A.ST", n: "Svolder AB (publ)", c: "Sweden" },
  { id: 22675, s: "T92.AX", n: "Terra Uranium", c: "Australia" },
  { id: 22676, s: "HNE.L", n: "Henderson Eurotrust", c: "UK" },
  { id: 22677, s: "BCE-PR.TO", n: "BCE Inc Pref R", c: "CA" },
  { id: 22678, s: "ICON.L", n: "Iconic Labs Plc", c: "UK" },
  { id: 22679, s: "TEE.AX", n: "Top End Energy Limited", c: "Australia" },
  { id: 22680, s: "KRPZ.L", n: "Kropz Plc", c: "UK" },
  { id: 22681, s: "SIMRIS-B.ST", n: "Simris Alg AB (publ)", c: "Sweden" },
  { id: 22682, s: "NUGYO.IS", n: "Nurol Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 22683, s: "JUSC.L", n: "JPmorgan US Smaller Companies Investment Trust PLC", c: "UK" },
  { id: 22684, s: "OYLUM.IS", n: "Oylum Sinai Yatirimlar AS", c: "Turkey" },
  { id: 22685, s: "JOG.L", n: "Jersey Oil and Gas PLC", c: "UK" },
  { id: 22686, s: "PEKGY.IS", n: "Peker Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 22687, s: "TG6.AX", n: "TG Metals Limited", c: "Australia" },
  { id: 22688, s: "SKMO.ST", n: "Skane mollan AB", c: "Sweden" },
  { id: 22689, s: "ALA-PH.TO", n: "AltaGas", c: "CA" },
  { id: 22690, s: "FUIG", n: "Fusion Interactive Co", c: "US" },
  { id: 22691, s: "SKOLON.ST", n: "Skolon AB", c: "Sweden" },
  { id: 22692, s: "IEM.L", n: "Impax Environmental Markets PLC", c: "UK" },
  { id: 22693, s: "SODER.ST", n: "Soder Sportfiske AB", c: "Sweden" },
  { id: 22694, s: "OSP.TO", n: "Brompton Oil Split Co", c: "CA" },
  { id: 22695, s: "ALA-PE.TO", n: "Altagas Pref E", c: "CA" },
  { id: 22696, s: "ZBNI.V", n: "Zeb Nickel Co", c: "CA" },
  { id: 22697, s: "NILS.ST", n: "Nilsson Special Vehicles publ AB", c: "Sweden" },
  { id: 22698, s: "5531.TW", n: "Shining Building Business", c: "TW" },
  { id: 22699, s: "NICA.ST", n: "Nanologica AB", c: "Sweden" },
  { id: 22700, s: "MZX.F", n: "Masterflex SE", c: "GER" },
  { id: 22701, s: "MWB.F", n: "mwb fairtrade Wertpapierhandelsbank AG", c: "GER" },
  { id: 22702, s: "JNH.V", n: "Jack Nathan Medical Co", c: "CA" },
  { id: 22703, s: "5283.TW", n: "Heran", c: "TW" },
  { id: 22704, s: "MRX.DU", n: "Mister Spex SE", c: "GER" },
  { id: 22705, s: "NB2.DU", n: "Northern Data AG", c: "GER" },
  { id: 22706, s: "2302.TW", n: "Rectron", c: "TW" },
  { id: 22707, s: "MBG.DU", n: "Mercedes-Benz Group AG", c: "GER" },
  { id: 22708, s: "HPHA.F", n: "Heidelberg Pharma AG", c: "GER" },
  { id: 22709, s: "2114.TW", n: "Hsin Yung Chien", c: "TW" },
  { id: 22710, s: "NN6.F", n: "NANOREPRO AG", c: "GER" },
  { id: 22711, s: "P4O.F", n: "Plan Optik AG", c: "GER" },
  { id: 22712, s: "5525.TW", n: "Sweeten Real Estate Development", c: "TW" },
  { id: 22713, s: "OEL.F", n: "ADM Hamburg Aktiengesellschaft", c: "GER" },
  { id: 22714, s: "2062.TW", n: "Sunspring Metal Co", c: "TW" },
  { id: 22715, s: "NIIN.F", n: "NIIIO FIN.GRP AG NA O.N.", c: "GER" },
  { id: 22716, s: "M0YN.DU", n: "Mynaric AG", c: "GER" },
  { id: 22717, s: "NB2.F", n: "Northern Data AG", c: "GER" },
  { id: 22718, s: "PH6.F", n: "PHILION SE O.N.", c: "GER" },
  { id: 22719, s: "PO1.F", n: "Performance One AG", c: "GER" },
  { id: 22720, s: "CBGPY", n: "Close Brothers Group PLC ADR", c: "US" },
  { id: 22721, s: "PLEK.F", n: "PLENUM AG NA O.N.", c: "GER" },
  { id: 22722, s: "HOT.DU", n: "HOCHTIEF Aktiengesellschaft", c: "GER" },
  { id: 22723, s: "2007.TW", n: "Yieh Hsing Enterprise", c: "TW" },
  { id: 22724, s: "ATLFF", n: "Atlas Copco AB", c: "US" },
  { id: 22725, s: "PNE3.F", n: "PNE AG", c: "GER" },
  { id: 22726, s: "NBG6.F", n: "NÜRNBERGER Beteiligungs-AG", c: "GER" },
  { id: 22727, s: "2032.TW", n: "Sinkang Industries", c: "TW" },
  { id: 22728, s: "2033.TW", n: "Chia Ta World", c: "TW" },
  { id: 22729, s: "PAG.F", n: "PREOS Global Office Real Estate & Technology AG", c: "GER" },
  { id: 22730, s: "KSB.DU", n: "KSB SE & Co. KGaA", c: "GER" },
  { id: 22731, s: "NF4.F", n: "NETFONDS AG VNA O.N.", c: "GER" },
  { id: 22732, s: "KWG.DU", n: "KHD Humboldt Wedag International AG", c: "GER" },
  { id: 22733, s: "2069.TW", n: "Yuen Chang Stainless Steel", c: "TW" },
  { id: 22734, s: "INS.DU", n: "Instone Real Estate Group AG", c: "GER" },
  { id: 22735, s: "2109.TW", n: "Hwa Fong Rubber Ind", c: "TW" },
  { id: 22736, s: "MODEL-B.ST", n: "Modelon AB Cl B", c: "Sweden" },
  { id: 22737, s: "POW-PA.TO", n: "Power Corporation of CA Pref A", c: "CA" },
  { id: 22738, s: "ORMA.IS", n: "Orma Orman Mahsulleri Integre Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22739, s: "NELLY.ST", n: "Nelly Group AB", c: "Sweden" },
  { id: 22740, s: "NEOD.ST", n: "Neodynamics AB", c: "Sweden" },
  { id: 22741, s: "NEOLA.ST", n: "Neola Medical AB", c: "Sweden" },
  { id: 22742, s: "OSMEN.IS", n: "Osmanli Menkul Degerler AS", c: "Turkey" },
  { id: 22743, s: "NETEL.ST", n: "Netel Holding AB", c: "Sweden" },
  { id: 22744, s: "NAIG-B.ST", n: "Nordic Asia Investment Group 1987 AB Series B", c: "Sweden" },
  { id: 22745, s: "NETM-B.ST", n: "Netmore Group AB Series B", c: "Sweden" },
  { id: 22746, s: "NEWBRY.ST", n: "Newbury Pharmaceuticals AB", c: "Sweden" },
  { id: 22747, s: "OSTIM.IS", n: "Ostim Endustriyel Yatirimlar ve Isletme AS", c: "Turkey" },
  { id: 22748, s: "NG.ST", n: "Northgold AB", c: "Sweden" },
  { id: 22749, s: "ZACA.V", n: "Zacapa Resources", c: "CA" },
  { id: 22750, s: "OTTO.IS", n: "Otto Holding AS", c: "Turkey" },
  { id: 22751, s: "NANOC-B.ST", n: "Nanocap Group AB Series B", c: "Sweden" },
  { id: 22752, s: "KEN.L", n: "Kendrick Resources PLC", c: "UK" },
  { id: 22753, s: "OBS.F", n: "Orbis AG", c: "GER" },
  { id: 22754, s: "5521.TW", n: "Kung Sing Engineering Co", c: "TW" },
  { id: 22755, s: "IPOK.DU", n: "Heidelberger Beteiligungsholding AG", c: "GER" },
  { id: 22756, s: "IUR.DU", n: "KAP AG", c: "GER" },
  { id: 22757, s: "5484.TW", n: "EverFocus Electronics Co", c: "TW" },
  { id: 22758, s: "NSN.F", n: "Neschen AG", c: "GER" },
  { id: 22759, s: "KBX.DU", n: "Knorr-Bremse Aktiengesellschaft", c: "GER" },
  { id: 22760, s: "2107.TW", n: "Formosan Rubber Group Inc", c: "TW" },
  { id: 22761, s: "CYM.V", n: "Cymat Technologies.", c: "CA" },
  { id: 22762, s: "MTI.ST", n: "MTI Investment SE", c: "Sweden" },
  { id: 22763, s: "4153.TWO", n: "DIVA Laboratories", c: "TW" },
  { id: 22764, s: "CYF.V", n: "Canyon Creek Food Company", c: "CA" },
  { id: 22765, s: "POW-PB.TO", n: "Power Corporation of CA Pref B", c: "CA" },
  { id: 22766, s: "MOV.ST", n: "Move About Group AB", c: "Sweden" },
  { id: 22767, s: "UOS.AX", n: "United Overseas Australia", c: "Australia" },
  { id: 22768, s: "MTG-A.ST", n: "Modern Times Group Mtg AB", c: "Sweden" },
  { id: 22769, s: "FFH-PD.TO", n: "Fairfax Financial Holdings Pref D", c: "CA" },
  { id: 22770, s: "REACH.OL", n: "Reach Subsea", c: "Norway" },
  { id: 22771, s: "VKGYO.IS", n: "Vakif Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 22772, s: "SRT.HM", n: "Sartorius Aktiengesellschaft", c: "GER" },
  { id: 22773, s: "WUG.HM", n: "Westag & Getalit AG", c: "GER" },
  { id: 22774, s: "WIG1.HM", n: "Sporttotal AG", c: "GER" },
  { id: 22775, s: "WEW.HM", n: "Westwing Group AG", c: "GER" },
  { id: 22776, s: "BURVA.IS", n: "Burcelik Vana Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22777, s: "CANTE.IS", n: "Can2 Termik AS", c: "Turkey" },
  { id: 22778, s: "BAT.V", n: "Batero Gold Co", c: "CA" },
  { id: 22779, s: "BAG.V", n: "Blende Silver Co", c: "CA" },
  { id: 22780, s: "QRF.BR", n: "QRF SCA", c: "Belgium" },
  { id: 22781, s: "3224.TWO", n: "MetaTech AP", c: "TW" },
  { id: 22782, s: "AWM.V", n: "Angel Wing Metals Inc", c: "CA" },
  { id: 22783, s: "AWE.V", n: "Thunderstruck Resources", c: "CA" },
  { id: 22784, s: "ROU.BR", n: "Roularta", c: "Belgium" },
  { id: 22785, s: "CASA.IS", n: "Casa Emtia Petrol Kimyevi ve Turevleri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22786, s: "SCHD.BR", n: "Scheerders van Kerchove's Verenigde fabrieken nv", c: "Belgium" },
  { id: 22787, s: "3213.TWO", n: "Mildef Crete", c: "TW" },
  { id: 22788, s: "MCC.BR", n: "Immo Mcc NV", c: "Belgium" },
  { id: 22789, s: "XD4.HM", n: "Strabag SE", c: "GER" },
  { id: 22790, s: "BEA.V", n: "Belmont Resources Inc", c: "CA" },
  { id: 22791, s: "3188.TWO", n: "Golden Long Teng Development", c: "TW" },
  { id: 22792, s: "2926.TWO", n: "Eslite Spectrum", c: "TW" },
  { id: 22793, s: "CRDFA.IS", n: "Creditwest Faktoring AS", c: "Turkey" },
  { id: 22794, s: "2754.TWO", n: "Kura Sushi Asia", c: "TW" },
  { id: 22795, s: "SIX3.HM", n: "Sixt SE vz. (Pref Shares)", c: "GER" },
  { id: 22796, s: "CEMAS.IS", n: "Cemas Dokum Sanayi AS", c: "Turkey" },
  { id: 22797, s: "BKM.V", n: "Pacific Booker Minerals Inc.", c: "CA" },
  { id: 22798, s: "SMAR.BR", n: "Smartphoto Group NV", c: "Belgium" },
  { id: 22799, s: "3236.TWO", n: "ABC Taiwan Electronics", c: "TW" },
  { id: 22800, s: "BGS.V", n: "Baroyeca Gold & Silver Inc", c: "CA" },
  { id: 22801, s: "123F.DE", n: "123fahrschule SE", c: "GER" },
  { id: 22802, s: "3265.TWO", n: "Winstek Semiconductor", c: "TW" },
  { id: 22803, s: "YOU.HM", n: "ABOUT YOU Holding AG", c: "GER" },
  { id: 22804, s: "BECN.V", n: "BEACN Wizardry & Magic Inc", c: "CA" },
  { id: 22805, s: "3252.TWO", n: "Haiwan International Development", c: "TW" },
  { id: 22806, s: "AVX.V", n: "Altair Resources Inc", c: "CA" },
  { id: 22807, s: "VTSC.HM", n: "Vitesco Technologies Group Aktiengesellschaft", c: "GER" },
  { id: 22808, s: "3207.TWO", n: "Yao Sheng Electronic", c: "TW" },
  { id: 22809, s: "ANNX.ST", n: "Annexin Pharmaceuticals AB", c: "Sweden" },
  { id: 22810, s: "DUTV", n: "Digital Uts Ventures", c: "US" },
  { id: 22811, s: "ALLEI.ST", n: "Alleima AB (publ)", c: "Sweden" },
  { id: 22812, s: "TRP-PI.TO", n: "TC Energy Corp Pref Series 6", c: "CA" },
  { id: 22813, s: "ALM-PREF.ST", n: "ALM Equity AB Pfd.", c: "Sweden" },
  { id: 22814, s: "AMNI.ST", n: "Amniotics AB", c: "Sweden" },
  { id: 22815, s: "TRP-PH.TO", n: "TC Energy Corp Pref Series 4", c: "CA" },
  { id: 22816, s: "ANOT.ST", n: "Anoto Group AB", c: "Sweden" },
  { id: 22817, s: "ABSO.ST", n: "Absolent Group AB", c: "Sweden" },
  { id: 22818, s: "APRNDR.ST", n: "Aprendere Skolor AB", c: "Sweden" },
  { id: 22819, s: "APTR.ST", n: "AppSpotr AB", c: "Sweden" },
  { id: 22820, s: "TRP-PG.TO", n: "TC Energy Corp Pref Series 11", c: "CA" },
  { id: 22821, s: "ISBTR.IS", n: "Turkiye Is Bankasi AS Class B", c: "Turkey" },
  { id: 22822, s: "ALCA.ST", n: "Alcadon Group AB", c: "Sweden" },
  { id: 22823, s: "ARCOMA.ST", n: "Arcoma AB", c: "Sweden" },
  { id: 22824, s: "ALBERT.ST", n: "eEducation Albert AB", c: "Sweden" },
  { id: 22825, s: "AJA-B.ST", n: "Byggmästare Anders J Ahlström Holding AB (publ)", c: "Sweden" },
  { id: 22826, s: "3206.TWO", n: "Kingstate Electronics", c: "TW" },
  { id: 22827, s: "ACRI-A.ST", n: "Acrinova AB", c: "Sweden" },
  { id: 22828, s: "VIH1.HM", n: "VIB Vermögen AG", c: "GER" },
  { id: 22829, s: "VRCI.L", n: "Verici Dx Plc", c: "UK" },
  { id: 22830, s: "HOLD.NE", n: "Immutable Holdings Inc", c: "CA" },
  { id: 22831, s: "ARO.HM", n: "Arcandor AG", c: "GER" },
  { id: 22832, s: "ACOU.ST", n: "AcouSort AB", c: "Sweden" },
  { id: 22833, s: "SPP.V", n: "SPoT Coffee", c: "CA" },
  { id: 22834, s: "ACROUD.ST", n: "Acroud AB", c: "Sweden" },
  { id: 22835, s: "AINO.ST", n: "Aino Health AB", c: "Sweden" },
  { id: 22836, s: "DSFGY", n: "Dah Sing Financial Holdings ADR", c: "US" },
  { id: 22837, s: "SPRX.V", n: "Sparx Technology Inc", c: "CA" },
  { id: 22838, s: "SPX.V", n: "Stellar AfricaGold Inc.", c: "CA" },
  { id: 22839, s: "AGES-B.ST", n: "AGES Industri AB (publ)", c: "Sweden" },
  { id: 22840, s: "INVEO.IS", n: "Inveo Yatirim Holding AS", c: "Turkey" },
  { id: 22841, s: "PGH.L", n: "Personal Group Holdings PLC", c: "UK" },
  { id: 22842, s: "2937.TWO", n: "Gseven", c: "TW" },
  { id: 22843, s: "CONSE.IS", n: "Consus Enerji Isletmeciligi ve Hizmetleri AS", c: "Turkey" },
  { id: 22844, s: "PINT.L", n: "Pantheon Infrastructure PLC", c: "UK" },
  { id: 22845, s: "3071.TWO", n: "Adda Corporation", c: "TW" },
  { id: 22846, s: "B8F.DE", n: "Biofrontera AG", c: "GER" },
  { id: 22847, s: "ALG.DE", n: "ALBIS Leasing AG", c: "GER" },
  { id: 22848, s: "3556.TWO", n: "eGalax_eMPIA Technology", c: "TW" },
  { id: 22849, s: "SBT.PA", n: "Oeneo SA", c: "FR" },
  { id: 22850, s: "3551.TWO", n: "Shih Her Technologies", c: "TW" },
  { id: 22851, s: "3548.TWO", n: "Jarllytec", c: "TW" },
  { id: 22852, s: "CQR.V", n: "Conquest Resources", c: "CA" },
  { id: 22853, s: "RCT.V", n: "Rochester Resources", c: "CA" },
  { id: 22854, s: "CPL.V", n: "Copper Lake Resources", c: "CA" },
  { id: 22855, s: "BEYAZ.IS", n: "Beyaz Filo Oto Kiralama AS", c: "Turkey" },
  { id: 22856, s: "COO.V", n: "Ostrom Climate Solutions Inc", c: "CA" },
  { id: 22857, s: "ANAS", n: "Alternaturals Inc", c: "US" },
  { id: 22858, s: "CLZ.V", n: "Canasil Resources Inc.", c: "CA" },
  { id: 22859, s: "ABO.DE", n: "clearvise AG", c: "GER" },
  { id: 22860, s: "CHS.V", n: "Comprehensive Healthcare Systems Inc", c: "CA" },
  { id: 22861, s: "PXI.V", n: "Planet Ventures Inc", c: "CA" },
  { id: 22862, s: "BAKAB.IS", n: "Bak Ambalaj Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22863, s: "3581.TWO", n: "Zen Voce", c: "TW" },
  { id: 22864, s: "3303.TWO", n: "Univacco Technology", c: "TW" },
  { id: 22865, s: "BRISA.IS", n: "Brisa Bridgestone Sabanci Lastik Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22866, s: "BTLS.BR", n: "Biotalys NV", c: "Belgium" },
  { id: 22867, s: "BOL.V", n: "Bold Ventures Inc", c: "CA" },
  { id: 22868, s: "BMV.V", n: "Bathurst Metals Co", c: "CA" },
  { id: 22869, s: "3310.TWO", n: "Chialin Precision Industrial", c: "TW" },
  { id: 22870, s: "BMR.V", n: "Battery Mineral Resources Co", c: "CA" },
  { id: 22871, s: "3297.TWO", n: "Hunt Electronic", c: "TW" },
  { id: 22872, s: "CASA.V", n: "Casa Minerals Inc", c: "CA" },
  { id: 22873, s: "3294.TWO", n: "Megaforce", c: "TW" },
  { id: 22874, s: "BMEX.V", n: "BMEX Gold Inc", c: "CA" },
  { id: 22875, s: "2INV.DE", n: "2invest AG", c: "GER" },
  { id: 22876, s: "3465.TWO", n: "New Advanced Electronics Technologies", c: "TW" },
  { id: 22877, s: "BME.V", n: "Barsele Minerals Co", c: "CA" },
  { id: 22878, s: "3466.TWO", n: "Skardin Industrial", c: "TW" },
  { id: 22879, s: "3521.TWO", n: "Datavan International", c: "TW" },
  { id: 22880, s: "AAQ1.DE", n: "aap Implantate AG", c: "GER" },
  { id: 22881, s: "TIPI.PA", n: "Tipiak Société Anonyme", c: "FR" },
  { id: 22882, s: "3093.TWO", n: "Taiwan Kong King", c: "TW" },
  { id: 22883, s: "ASTR.V", n: "Astra Exploration Inc", c: "CA" },
  { id: 22884, s: "3128.TWO", n: "Hi Sharp Electronics", c: "TW" },
  { id: 22885, s: "3122.TWO", n: "Megawin Technology", c: "TW" },
  { id: 22886, s: "3118.TWO", n: "Level Biotechnology", c: "TW" },
  { id: 22887, s: "AST.V", n: "Astron Connect Inc", c: "CA" },
  { id: 22888, s: "V33.HM", n: "Vtion Wireless Technology AG", c: "GER" },
  { id: 22889, s: "TKA.HM", n: "thyssenkrupp AG", c: "GER" },
  { id: 22890, s: "3147.TWO", n: "Jetwell Computer", c: "TW" },
  { id: 22891, s: "ARIC.V", n: "Awale Resources", c: "CA" },
  { id: 22892, s: "SWVK.HM", n: "SolarWorld Aktiengesellschaft", c: "GER" },
  { id: 22893, s: "3083.TWO", n: "Chinese Gamer International", c: "TW" },
  { id: 22894, s: "ARD.V", n: "Armada Data Corporation", c: "CA" },
  { id: 22895, s: "3078.TWO", n: "Channel Well Technology", c: "TW" },
  { id: 22896, s: "CMENT.IS", n: "Cimentas Izmir Cimento Fabrikasi TAS", c: "Turkey" },
  { id: 22897, s: "ATW.V", n: "ATW Tech Inc", c: "CA" },
  { id: 22898, s: "CEOEM.IS", n: "CEO Event Medya AS", c: "Turkey" },
  { id: 22899, s: "3512.TWO", n: "Huang Long Development", c: "TW" },
  { id: 22900, s: "CBA.V", n: "Champion Bear Resources.", c: "CA" },
  { id: 22901, s: "CDB.V", n: "Cordoba Minerals Co", c: "CA" },
  { id: 22902, s: "CCCM.V", n: "C3 Metals Inc", c: "CA" },
  { id: 22903, s: "A7A.DE", n: "FinLab AG", c: "GER" },
  { id: 22904, s: "3498.TWO", n: "Usun Technology", c: "TW" },
  { id: 22905, s: "3492.TWO", n: "Advanced Connection Technology", c: "TW" },
  { id: 22906, s: "3490.TWO", n: "Single Well Industrial", c: "TW" },
  { id: 22907, s: "A62.DE", n: "Advantag AG", c: "GER" },
  { id: 22908, s: "3163.TWO", n: "Browave", c: "TW" },
  { id: 22909, s: "2752.TWO", n: "Tofu Restaurant", c: "TW" },
  { id: 22910, s: "BRLSM.IS", n: "Birlesim Muhendislik Isitma Sogutma Havalandirma Sanayi & Ticaret Sirketi AS", c: "Turkey" },
  { id: 22911, s: "BRMEN.IS", n: "Birlik Mensucat Ticaret ve Sanayi Isletmesi AS", c: "Turkey" },
  { id: 22912, s: "CEMTS.IS", n: "Cemtas Celik Makina Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22913, s: "3171.TWO", n: "Xin Chio Global", c: "TW" },
  { id: 22914, s: "VASTB.BR", n: "Vastned Retail Belgium", c: "Belgium" },
  { id: 22915, s: "SOI.V", n: "Sirios Resources Inc.", c: "CA" },
  { id: 22916, s: "RUM.V", n: "Rocky Mountain Liquor Inc", c: "CA" },
  { id: 22917, s: "CCJI.L", n: "CC Japan Income and Growth Trust PLC", c: "UK" },
  { id: 22918, s: "STUD.V", n: "Stallion Gold Co", c: "CA" },
  { id: 22919, s: "SWG.L", n: "Shearwater Group plc", c: "UK" },
  { id: 22920, s: "SURE.L", n: "Sure Ventures PLC", c: "UK" },
  { id: 22921, s: "SSIT.L", n: "Seraphim Space Investment Trust PLC", c: "UK" },
  { id: 22922, s: "AIPUY", n: "Airports of Thailand PCL ADR", c: "US" },
  { id: 22923, s: "RG.V", n: "Romios Gold Resources Inc.", c: "CA" },
  { id: 22924, s: "SRC.L", n: "Sigmaroc PLC", c: "UK" },
  { id: 22925, s: "SRAD.L", n: "Stelrad Group PLC", c: "UK" },
  { id: 22926, s: "AIRYY", n: "Air CH ADR", c: "US" },
  { id: 22927, s: "RK.V", n: "Rockhaven Resources", c: "CA" },
  { id: 22928, s: "SNX.L", n: "Synectics plc", c: "UK" },
  { id: 22929, s: "SND.L", n: "Sondrel (Holdings) PLC", c: "UK" },
  { id: 22930, s: "SJG.L", n: "Schroder Japan Growth Fund", c: "UK" },
  { id: 22931, s: "GLRYH.IS", n: "Guler Yatirim Holding AS", c: "Turkey" },
  { id: 22932, s: "ROI.V", n: "Route1 Inc", c: "CA" },
  { id: 22933, s: "SED.L", n: "Saietta Group PLC", c: "UK" },
  { id: 22934, s: "SYS.L", n: "SysGroup PLC", c: "UK" },
  { id: 22935, s: "REX.V", n: "Orex Minerals Inc", c: "CA" },
  { id: 22936, s: "SYS1.L", n: "System1 Group PLC", c: "UK" },
  { id: 22937, s: "EFGSY", n: "Eiffage SA ADR", c: "US" },
  { id: 22938, s: "AXIC-A.ST", n: "aXichem AB", c: "Sweden" },
  { id: 22939, s: "AXOLOT.ST", n: "Axolot Solutions Holding AB", c: "Sweden" },
  { id: 22940, s: "AYIMA-B.ST", n: "Ayima Group AB Series B", c: "Sweden" },
  { id: 22941, s: "ORR.L", n: "Oriole Resources PLC", c: "UK" },
  { id: 22942, s: "EDESY", n: "Elders ADR", c: "US" },
  { id: 22943, s: "BALCO.ST", n: "Balco Group AB", c: "Sweden" },
  { id: 22944, s: "ORIT.L", n: "Octopus Renewables Infra Trust", c: "UK" },
  { id: 22945, s: "GLINT.LS", n: "Glintt", c: "Portugal" },
  { id: 22946, s: "BBROOM.ST", n: "New Bubbleroom Sweden AB", c: "Sweden" },
  { id: 22947, s: "BESQ.ST", n: "Besqab publ AB", c: "Sweden" },
  { id: 22948, s: "ORCP.L", n: "Oracle Coalfields Plc", c: "UK" },
  { id: 22949, s: "SAIN.L", n: "Scottish American Investment", c: "UK" },
  { id: 22950, s: "RUG.V", n: "Rugby Mining Limited", c: "CA" },
  { id: 22951, s: "XD4.MU", n: "Strabag SE", c: "GER" },
  { id: 22952, s: "GMTAS.IS", n: "Gimat Magazacilik Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 22953, s: "TAN.L", n: "Tanfield Group PLC", c: "UK" },
  { id: 22954, s: "GARFA.IS", n: "Garanti Faktoring AS", c: "Turkey" },
  { id: 22955, s: "B4B3.DE", n: "Metro AG", c: "GER" },
  { id: 22956, s: "THRG.L", n: "Throgmorton Trust Plc", c: "UK" },
  { id: 22957, s: "THR.L", n: "Thor Mining PLC", c: "UK" },
  { id: 22958, s: "TRT.L", n: "Transense Technologies PLC", c: "UK" },
  { id: 22959, s: "ROMJ.V", n: "Rubicon Organics Inc", c: "CA" },
  { id: 22960, s: "SRT.L", n: "SRT Marine Systems plc", c: "UK" },
  { id: 22961, s: "SEC.L", n: "Strategic Equity Capital Closed Fund", c: "UK" },
  { id: 22962, s: "GRSEL.IS", n: "GurSel Turizm Tasimacilik Ve Servis Ticaret AS", c: "Turkey" },
  { id: 22963, s: "TLY.L", n: "Totally PLC", c: "UK" },
  { id: 22964, s: "GRNYO.IS", n: "Garanti Yatirim Ortakligi AS", c: "Turkey" },
  { id: 22965, s: "DMZPY", n: "Domino'S Pizza Enterprises", c: "US" },
  { id: 22966, s: "DRW8.DE", n: "Drägerwerk AG & Co. KGaA", c: "GER" },
  { id: 22967, s: "SCF.L", n: "Schroder Income Growth Fund", c: "UK" },
  { id: 22968, s: "SCIR.L", n: "Scirocco Energy PLC", c: "UK" },
  { id: 22969, s: "FPE3.DE", n: "Fuchs Petrolub SE", c: "GER" },
  { id: 22970, s: "2HRA.HM", n: "H&R GmbH & Co. KGaA", c: "GER" },
  { id: 22971, s: "8TRA.HM", n: "Traton SE", c: "GER" },
  { id: 22972, s: "1U1.HM", n: "1&1 AG", c: "GER" },
  { id: 22973, s: "UEM.L", n: "Utilico Emerging Markets", c: "UK" },
  { id: 22974, s: "TERN.L", n: "Tern Plc", c: "UK" },
  { id: 22975, s: "ARL.HM", n: "Aareal Bank AG", c: "GER" },
  { id: 22976, s: "DELRF", n: "De La Rue PLC", c: "US" },
  { id: 22977, s: "USFP.L", n: "US Solar Fund PLC", c: "UK" },
  { id: 22978, s: "DGIX", n: "Dyna Group International Inc", c: "US" },
  { id: 22979, s: "FLAP.IS", n: "Flap Kongre Toplanti Hizmetleri Otomotiv ve Turizm AS", c: "Turkey" },
  { id: 22980, s: "FMIZP.IS", n: "Federal Mogul Izmit Piston ve Pim Uretim Tesisleri AS", c: "Turkey" },
  { id: 22981, s: "TMOR.L", n: "More Acquisitions PLC", c: "UK" },
  { id: 22982, s: "FONET.IS", n: "Fonet Bilgi Teknolojileri AS", c: "Turkey" },
  { id: 22983, s: "IPOK.DE", n: "Heidelberger Beteiligungsholding AG", c: "GER" },
  { id: 22984, s: "AEIN.HM", n: "Allgeier SE", c: "GER" },
  { id: 22985, s: "TOWN.L", n: "Town Centre Securities PLC", c: "UK" },
  { id: 22986, s: "TNT.L", n: "Tintra PLC", c: "UK" },
  { id: 22987, s: "WALM34.SA", n: "Walmart Inc", c: "Brazil" },
  { id: 22988, s: "ISGYO.IS", n: "Is Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 22989, s: "AVENT-B.ST", n: "Aventura Group AB Series B", c: "Sweden" },
  { id: 22990, s: "MLRZE.LS", n: "Raize Servicos de Gestao SA", c: "Portugal" },
  { id: 22991, s: "AVEN.ST", n: "Avensia publ AB", c: "Sweden" },
  { id: 22992, s: "PORF.AS", n: "B.V. Delftsch Aardewerkfabriek De Porceleyne Fles Anno 1653", c: "Netherlands" },
  { id: 22993, s: "SMN.V", n: "Sun Summit Minerals Co", c: "CA" },
  { id: 22994, s: "SMP.V", n: "Southern Empire Resources Co", c: "CA" },
  { id: 22995, s: "IHAAS.IS", n: "Ihlas Haber Ajansi AS", c: "Turkey" },
  { id: 22996, s: "SNS.V", n: "Select Sands Co", c: "CA" },
  { id: 22997, s: "IHLAS.IS", n: "Ihlas Holding AS", c: "Turkey" },
  { id: 22998, s: "ARCT.ST", n: "Arctic Gold Publ AB", c: "Sweden" },
  { id: 22999, s: "ARCTIC.ST", n: "Arctic Blue Beverages AB", c: "Sweden" },
  { id: 23000, s: "ARION-SDB.ST", n: "Arion banki hf", c: "Sweden" },
  { id: 23001, s: "ONC.L", n: "Oncimmune Holdings plc", c: "UK" },
  { id: 23002, s: "TD-PFD.TO", n: "Toronto Dominion Bank Pref D", c: "CA" },
  { id: 23003, s: "EGYF", n: "Energy Finders Inc", c: "US" },
  { id: 23004, s: "OMIP.L", n: "One Media iP Group Plc", c: "UK" },
  { id: 23005, s: "BIOS.ST", n: "Bioservo Technologies AB", c: "Sweden" },
  { id: 23006, s: "JANTS.IS", n: "Jantsa Jant Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23007, s: "DROP", n: "Fuse Science Inc", c: "US" },
  { id: 23008, s: "SGU.V", n: "Signature Resources", c: "CA" },
  { id: 23009, s: "SDI.V", n: "Stampede Drilling Inc", c: "CA" },
  { id: 23010, s: "RNEP.L", n: "Ecofin US Renewables Infrastructure Trust PLC", c: "UK" },
  { id: 23011, s: "GZNMI.IS", n: "Gezinomi Seyahat Turizm Ticaret AS", c: "Turkey" },
  { id: 23012, s: "RED.LS", n: "Reditus Sociedade Gestora de Participações Sociais S.A", c: "Portugal" },
  { id: 23013, s: "14D.DE", n: "tokentus investment AG", c: "GER" },
  { id: 23014, s: "SAT.V", n: "Asian Television Network International", c: "CA" },
  { id: 23015, s: "SNC.LS", n: "Sonaecom SGPS", c: "Portugal" },
  { id: 23016, s: "SATO.V", n: "SATO Technologies Co", c: "CA" },
  { id: 23017, s: "RLE.L", n: "Real Estate Investors PLC", c: "UK" },
  { id: 23018, s: "LVIDE.AS", n: "Lavide Holding NV", c: "Netherlands" },
  { id: 23019, s: "RGO.L", n: "Riverfort Global Opportunities PLC", c: "UK" },
  { id: 23020, s: "SB.V", n: "Stratabound Minerals Co", c: "CA" },
  { id: 23021, s: "2CUREX.ST", n: "2cureX AB", c: "Sweden" },
  { id: 23022, s: "REAT.L", n: "React Group PLC", c: "UK" },
  { id: 23023, s: "SBIO.V", n: "Sabio Holdings Inc", c: "CA" },
  { id: 23024, s: "PTRO.L", n: "Pelatro PLC", c: "UK" },
  { id: 23025, s: "TD-PFA.TO", n: "Toronto Dominion Bank Pref A", c: "CA" },
  { id: 23026, s: "BOAT.ST", n: "Nimbus Group AB", c: "Sweden" },
  { id: 23027, s: "TG.V", n: "Trifecta Gold", c: "CA" },
  { id: 23028, s: "ASAB.ST", n: "Advanced SolTech Sweden AB", c: "Sweden" },
  { id: 23029, s: "ITTFH.IS", n: "Ittifak Holding AS", c: "Turkey" },
  { id: 23030, s: "OPTI.L", n: "OptiBiotix Health Plc", c: "UK" },
  { id: 23031, s: "PCGH.L", n: "Polar Capital Global Healthcare Trust plc", c: "UK" },
  { id: 23032, s: "AROS.ST", n: "AROS Bostadsutveckling AB", c: "Sweden" },
  { id: 23033, s: "AROS-PREF-B.ST", n: "Aros Bostadsutveckling AB Pfd. B", c: "Sweden" },
  { id: 23034, s: "PCFT.L", n: "Polar Capital Global Financials Trust plc", c: "UK" },
  { id: 23035, s: "EBKOF", n: "Erste Group Bank AG", c: "US" },
  { id: 23036, s: "KARYE.IS", n: "Kartal Yenilenebilir Enerji Uretim AS", c: "Turkey" },
  { id: 23037, s: "STE.V", n: "Starr Peak Exploration", c: "CA" },
  { id: 23038, s: "STMP.V", n: "Stamper Oil & Gas Co", c: "CA" },
  { id: 23039, s: "ASAP.ST", n: "Asarina Pharma AB (publ)", c: "Sweden" },
  { id: 23040, s: "PAT.L", n: "Panthera Resources PLC", c: "UK" },
  { id: 23041, s: "ATIC.ST", n: "Actic Group AB", c: "Sweden" },
  { id: 23042, s: "AUR.ST", n: "Auriant Mining AB (publ)", c: "Sweden" },
  { id: 23043, s: "BINERO.ST", n: "Binero Group AB", c: "Sweden" },
  { id: 23044, s: "CASE.ST", n: "Case Group AB", c: "Sweden" },
  { id: 23045, s: "TCL-B.TO", n: "Transcontinental Inc", c: "CA" },
  { id: 23046, s: "ELRNF", n: "Elron Electronic Industries", c: "US" },
  { id: 23047, s: "BONZUN.ST", n: "Bonzun AB", c: "Sweden" },
  { id: 23048, s: "BRE2.ST", n: "Bredband2 i Skandinavien AB", c: "Sweden" },
  { id: 23049, s: "TDG.V", n: "TDG Gold Co", c: "CA" },
  { id: 23050, s: "NVT.L", n: "Northern Venture Trust", c: "UK" },
  { id: 23051, s: "TIG.V", n: "Triumph Gold Co", c: "CA" },
  { id: 23052, s: "BRILL.ST", n: "Brilliant Future AB", c: "Sweden" },
  { id: 23053, s: "TA-PE.TO", n: "TransAlta Corp Pref E", c: "CA" },
  { id: 23054, s: "CANDLE-B.ST", n: "Candles Scandinavia AB Series B", c: "Sweden" },
  { id: 23055, s: "BTCX.ST", n: "Goobit Group AB", c: "Sweden" },
  { id: 23056, s: "KENT.IS", n: "Kent Gida Maddeleri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23057, s: "BUY.ST", n: "Sweden BuyersClub AB", c: "Sweden" },
  { id: 23058, s: "BYGGP.ST", n: "Bygg Partner i Dalarna Holding AB", c: "Sweden" },
  { id: 23059, s: "KERVN.IS", n: "Kervansaray Yatirim Holding AS", c: "Turkey" },
  { id: 23060, s: "TII.V", n: "Terra Firma Capital Corporation", c: "CA" },
  { id: 23061, s: "3325.TWO", n: "Casing Macron Technology", c: "TW" },
  { id: 23062, s: "BTR.V", n: "BonTerra Resources Inc", c: "CA" },
  { id: 23063, s: "BTT.V", n: "Bitterroot Resources", c: "CA" },
  { id: 23064, s: "SAFKR.IS", n: "Safkar Ege Sogutmacilik Klima Soguk Hava Tesisleri Ihracat Ithalat Sanayi ve Tic", c: "Turkey" },
  { id: 23065, s: "SUMAS.IS", n: "Sumas Suni Tahta ve Mobilya Sanayi AS", c: "Turkey" },
  { id: 23066, s: "TEKTU.IS", n: "Tek-Art Insaat Ticaret Turizm Sanayi ve Yatirimlar AS", c: "Turkey" },
  { id: 23067, s: "CVE-PA.TO", n: "Cenovus Energy Inc Preferred Series 1", c: "CA" },
  { id: 23068, s: "EYE.L", n: "Eagle Eye Solutions Group plc", c: "UK" },
  { id: 23069, s: "TEZOL.IS", n: "Europap Tezol Kagit Sanayi Ve Ticaret AS", c: "Turkey" },
  { id: 23070, s: "SFG.AX", n: "Seafarms Group", c: "Australia" },
  { id: 23071, s: "CVE-PC.TO", n: "Cenovus Energy Inc Preferred Series 3", c: "CA" },
  { id: 23072, s: "EVG.L", n: "Evgen Pharma PLC", c: "UK" },
  { id: 23073, s: "SES.AX", n: "Secos Group", c: "Australia" },
  { id: 23074, s: "PRIMOF.", n: "Prime Office A/S", c: "Denmark" },
  { id: 23075, s: "ESCT.L", n: "The European Smaller Companies Trust PLC", c: "UK" },
  { id: 23076, s: "SIF.", n: "Silkeborg IF Invest A/S", c: "Denmark" },
  { id: 23077, s: "ACCR", n: "Access-Power & Co. Inc", c: "US" },
  { id: 23078, s: "SLXIT.", n: "Seluxit A/S", c: "Denmark" },
  { id: 23079, s: "EOG.L", n: "Europa Oil & Gas Holdings", c: "UK" },
  { id: 23080, s: "CU-X.TO", n: "Canadian Utilities Limited", c: "CA" },
  { id: 23081, s: "CU-PI.TO", n: "Canadian Utilities Pref I", c: "CA" },
  { id: 23082, s: "CTX.TO", n: "Crescita Therapeutics Inc", c: "CA" },
  { id: 23083, s: "LOLB.", n: "Lollands Bank", c: "Denmark" },
  { id: 23084, s: "VOLO-PREF.ST", n: "Volati AB", c: "Sweden" },
  { id: 23085, s: "TRIBO-B.ST", n: "Triboron International AB (publ)", c: "Sweden" },
  { id: 23086, s: "FWD.L", n: "Forward Partners Group PLC", c: "UK" },
  { id: 23087, s: "BPS-PU.TO", n: "Brookfield Property Split Corp Pref U", c: "CA" },
  { id: 23088, s: "CPHCAP-ST.", n: "Copenhagen Capital A/S", c: "Denmark" },
  { id: 23089, s: "LEDIBOND.", n: "LED iBond International AS", c: "Denmark" },
  { id: 23090, s: "SUWEN.IS", n: "Suwen Tekstil Sanayi Pazarlama AS", c: "Turkey" },
  { id: 23091, s: "GJNSY", n: "Gjensidige Forsikring Asa ADR", c: "US" },
  { id: 23092, s: "CM-PY.TO", n: "Canadian Imperial Bank of Commerce Pref 51", c: "CA" },
  { id: 23093, s: "MNBA.", n: "Moens Bank A/S", c: "Denmark" },
  { id: 23094, s: "MONSO.", n: "Monsenso AS", c: "Denmark" },
  { id: 23095, s: "FDBK.L", n: "Feedback PLC", c: "UK" },
  { id: 23096, s: "NORDIC.", n: "Nordic Shipholding A/S", c: "Denmark" },
  { id: 23097, s: "NORTHM.", n: "North Media A/S", c: "Denmark" },
  { id: 23098, s: "CVE-PE.TO", n: "Cenovus Energy Inc Preferred Series 5", c: "CA" },
  { id: 23099, s: "SGC.AX", n: "Sacgas", c: "Australia" },
  { id: 23100, s: "KONSOL.", n: "Konsolidator AS", c: "Denmark" },
  { id: 23101, s: "STENO.", n: "Stenocare AS", c: "Denmark" },
  { id: 23102, s: "SRVGY.IS", n: "Servet Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 23103, s: "SIG.AX", n: "Sigma Pharmaceuticals", c: "Australia" },
  { id: 23104, s: "CM-PT.TO", n: "Canadian Imperial Bank of Commerce Pref 49", c: "CA" },
  { id: 23105, s: "HVID.", n: "Hvidbjerg Bank", c: "Denmark" },
  { id: 23106, s: "CM-PS.TO", n: "Canadian Imperial Bank of Commerce Pref Class A", c: "CA" },
  { id: 23107, s: "SIS.AX", n: "Simble Solutions Limited", c: "Australia" },
  { id: 23108, s: "SIT.AX", n: "Site Group International", c: "Australia" },
  { id: 23109, s: "CEF.TO", n: "Sprott Physical Gold and Silver Trust", c: "CA" },
  { id: 23110, s: "SNPAM.IS", n: "Sonmez Pamuklu Sanayii AS", c: "Turkey" },
  { id: 23111, s: "GYLD-B.", n: "Gyldendal A/S", c: "Denmark" },
  { id: 23112, s: "CM-PO.TO", n: "Canadian Imperial Bank of Commerce Pref O", c: "CA" },
  { id: 23113, s: "MFC-PN.TO", n: "Manulife Financial Corp Pref N", c: "CA" },
  { id: 23114, s: "MFC-PQ.TO", n: "Manulife Financial Corp Pref Class 1 Series 25", c: "CA" },
  { id: 23115, s: "GJ.", n: "Glunz & Jensen", c: "Denmark" },
  { id: 23116, s: "SMART.IS", n: "Smartiks Yazilim AS", c: "Turkey" },
  { id: 23117, s: "FSF.L", n: "Foresight Sustainable Forestry Company PLC", c: "UK" },
  { id: 23118, s: "FIPP.L", n: "Frontier IP Group Plc", c: "UK" },
  { id: 23119, s: "DKIEU.", n: "Danske Invest Europa", c: "Denmark" },
  { id: 23120, s: "FJV.L", n: "Fidelity Japanese Values PLC ORD", c: "UK" },
  { id: 23121, s: "SNX.AX", n: "Sierra Nevada Gold Inc", c: "Australia" },
  { id: 23122, s: "BR.TO", n: "Big Rock Brewery Inc.", c: "CA" },
  { id: 23123, s: "DKIDK.", n: "Danske Invest Danmark", c: "Denmark" },
  { id: 23124, s: "DKIDKIX.", n: "Danske Invest Danmark Indeks", c: "Denmark" },
  { id: 23125, s: "SNC.AX", n: "Sandon Capital Investments", c: "Australia" },
  { id: 23126, s: "DKIEUIX.", n: "Danske Invest Europa Indeks", c: "Denmark" },
  { id: 23127, s: "GGSM", n: "Gold And Gemstone", c: "US" },
  { id: 23128, s: "DKIEUIXBNP.", n: "Danske Invest - Europa Indeks BNP", c: "Denmark" },
  { id: 23129, s: "SMI.AX", n: "Santana Minerals", c: "Australia" },
  { id: 23130, s: "DKIFJIX.", n: "Danske Invest Fjernosten", c: "Denmark" },
  { id: 23131, s: "ADPXY", n: "Audio Pixels Holdings ADR", c: "US" },
  { id: 23132, s: "DKIGLIO.", n: "Danske Invest Globale Lange Indeksobligationer Kl Dkk D H", c: "Denmark" },
  { id: 23133, s: "FSV.L", n: "Fidelity Special Values", c: "UK" },
  { id: 23134, s: "VO2.ST", n: "Vo2 Cap Holding AB", c: "Sweden" },
  { id: 23135, s: "UTG.ST", n: "Unlimited Travel Group UTG AB (publ)", c: "Sweden" },
  { id: 23136, s: "BTU.V", n: "BTU Metals Co", c: "CA" },
  { id: 23137, s: "BMO-PY.TO", n: "Bank of Montreal Pref Y", c: "CA" },
  { id: 23138, s: "SRV.AX", n: "ServCo", c: "Australia" },
  { id: 23139, s: "SRR.AX", n: "Sarama Resources", c: "Australia" },
  { id: 23140, s: "BPO-PA.TO", n: "Brookfield Office Properties Inc Pref A", c: "CA" },
  { id: 23141, s: "GRA.L", n: "Grafenia Plc", c: "UK" },
  { id: 23142, s: "ZORDIX-B.ST", n: "Zordix AB Series B", c: "Sweden" },
  { id: 23143, s: "BPO-PC.TO", n: "Brookfield Office Properties Inc Pref C", c: "CA" },
  { id: 23144, s: "GPL.L", n: "Graft Polymer UK PLC", c: "UK" },
  { id: 23145, s: "WBGR-B.ST", n: "Wästbygg Gruppen AB (publ)", c: "Sweden" },
  { id: 23146, s: "BPO-PG.TO", n: "Brookfield Office Properties Inc", c: "CA" },
  { id: 23147, s: "BPO-PW.TO", n: "Brookfield Office Properties Inc Pref W", c: "CA" },
  { id: 23148, s: "SEKFK.IS", n: "Seker Finansal Kiralama AS", c: "Turkey" },
  { id: 23149, s: "SRH.AX", n: "Saferoads Holdings", c: "Australia" },
  { id: 23150, s: "BPO-PX.TO", n: "Brookfield Office Properties Inc Pref X", c: "CA" },
  { id: 23151, s: "SQX.AX", n: "SQX Resources.", c: "Australia" },
  { id: 23152, s: "BPS-PA.TO", n: "Brookfield Property Split Corp Pref A", c: "CA" },
  { id: 23153, s: "ZICC.ST", n: "Ziccum AB", c: "Sweden" },
  { id: 23154, s: "GRID.L", n: "Gresham House Energy Storage Fund PLC", c: "UK" },
  { id: 23155, s: "SANFM.IS", n: "Sanifoam Sunger Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23156, s: "REC.AX", n: "Recharge Metals", c: "Australia" },
  { id: 23157, s: "VKFYO.IS", n: "Vakif Menkul Kiymet Yatirim Ortakligi AS", c: "Turkey" },
  { id: 23158, s: "RDM.AX", n: "Red Metal", c: "Australia" },
  { id: 23159, s: "EAGR.TO", n: "East Side Games Group Inc", c: "CA" },
  { id: 23160, s: "HOT-U.TO", n: "American Hotel Income Properties LP", c: "CA" },
  { id: 23161, s: "CGW.L", n: "Chelverton Growth Trust PLC", c: "UK" },
  { id: 23162, s: "OBSRV.OL", n: "Observe Medical Asa", c: "Norway" },
  { id: 23163, s: "PHLY.OL", n: "Philly Shipyard ASA", c: "Norway" },
  { id: 23164, s: "BNK-PA.TO", n: "Big Banc Split Co", c: "CA" },
  { id: 23165, s: "NPI-PB.TO", n: "Northland Power Inc Pref B", c: "CA" },
  { id: 23166, s: "CVE-PG.TO", n: "Cenovus Energy Inc Preferred Series 7", c: "CA" },
  { id: 23167, s: "SPVBUSAKL.", n: "Sparindex INDEX Bæredygtige USA KL", c: "Denmark" },
  { id: 23168, s: "WPAY.ST", n: "West International AB", c: "Sweden" },
  { id: 23169, s: "XPC.ST", n: "XP Chemistries AB", c: "Sweden" },
  { id: 23170, s: "YIELD.ST", n: "SaveLend Group AB", c: "Sweden" },
  { id: 23171, s: "IR5B.IR", n: "Irish Continental Group PLC", c: "Ireland" },
  { id: 23172, s: "MLC.IR", n: "Malin Corporation plc", c: "Ireland" },
  { id: 23173, s: "AGAT.", n: "Agat Ejendomme A/S", c: "Denmark" },
  { id: 23174, s: "TSEC.ST", n: "Tempest Security AB", c: "Sweden" },
  { id: 23175, s: "QUAGR.IS", n: "Qua Granite Hayal Yapi ve Urunleri Sanayi Ticaret AS", c: "Turkey" },
  { id: 23176, s: "BMO-PS.TO", n: "Bank of Montreal Pref S", c: "CA" },
  { id: 23177, s: "RAYSG.IS", n: "Ray Sigorta AS", c: "Turkey" },
  { id: 23178, s: "GASNY", n: "Naturgy Energy Group SA ADR", c: "US" },
  { id: 23179, s: "GSC.L", n: "GS Chain PLC", c: "UK" },
  { id: 23180, s: "RNPOL.IS", n: "Rainbow Polikarbonat Sanayi Ticaret AS", c: "Turkey" },
  { id: 23181, s: "TWIIK.ST", n: "Twiik AB", c: "Sweden" },
  { id: 23182, s: "SWF.AX", n: "SelfWealth Limited", c: "Australia" },
  { id: 23183, s: "RODRG.IS", n: "Rodrigo Tekstil Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23184, s: "RTALB.IS", n: "RTA Laboratuvarlari Biyolojik", c: "Turkey" },
  { id: 23185, s: "URBIT.ST", n: "Urb-it AB", c: "Sweden" },
  { id: 23186, s: "USWE.ST", n: "USWE Sports AB", c: "Sweden" },
  { id: 23187, s: "SUM.AX", n: "Summit Minerals", c: "Australia" },
  { id: 23188, s: "GDET", n: "GD Entertainment & Technology Inc", c: "US" },
  { id: 23189, s: "TOURN.ST", n: "Tourn International AB", c: "Sweden" },
  { id: 23190, s: "QNBFL.IS", n: "QNB Finans Finansal Kiralama AS", c: "Turkey" },
  { id: 23191, s: "ASTRLS.", n: "Astralis Group", c: "Denmark" },
  { id: 23192, s: "NA-PG.TO", n: "National Bank of CA Pref Series 42", c: "CA" },
  { id: 23193, s: "BIF.", n: "Broendbyernes IF Fodbold AS", c: "Denmark" },
  { id: 23194, s: "GCM.L", n: "GCM Resources Plc", c: "UK" },
  { id: 23195, s: "CARL-A.", n: "Carlsberg A/S", c: "Denmark" },
  { id: 23196, s: "BPS-PC.TO", n: "Brookfield Property Split Corp Pref C", c: "CA" },
  { id: 23197, s: "SRI.AX", n: "Sipa Resources", c: "Australia" },
  { id: 23198, s: "G4M.L", n: "Gear4music (Holdings) Plc", c: "UK" },
  { id: 23199, s: "STN.AX", n: "Saturn Metals", c: "Australia" },
  { id: 23200, s: "TITA-B.ST", n: "Titania Holding AB Series B", c: "Sweden" },
  { id: 23201, s: "BIK-PA.TO", n: "Bip Investment Co", c: "CA" },
  { id: 23202, s: "TELLUS.ST", n: "Tellusgruppen AB", c: "Sweden" },
  { id: 23203, s: "PSGYO.IS", n: "Pasifik Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 23204, s: "TH1NG.ST", n: "TH1NG AB", c: "Sweden" },
  { id: 23205, s: "GARB", n: "Garb Oil & Pwr Co", c: "US" },
  { id: 23206, s: "BMO-PE.TO", n: "Bank of Montreal Pref Class B", c: "CA" },
  { id: 23207, s: "CM-PP.TO", n: "Canadian Imperial Bank of Commerce Pref P", c: "CA" },
  { id: 23208, s: "TRA.V", n: "Teras Resources Inc", c: "CA" },
  { id: 23209, s: "EMPIR-B.ST", n: "Empir Group AB", c: "Sweden" },
  { id: 23210, s: "CAT-A.ST", n: "Catella AB A", c: "Sweden" },
  { id: 23211, s: "B4B.HM", n: "Metro AG", c: "GER" },
  { id: 23212, s: "RJF.MC", n: "Laboratorio Reig Jofre S.A", c: "Spain" },
  { id: 23213, s: "EDIP.IS", n: "Edip Gayrimenkul Yatirim Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23214, s: "REN.MC", n: "Renta Corporacion Real Estate SA", c: "Spain" },
  { id: 23215, s: "EXL.HM", n: "Exasol AG", c: "GER" },
  { id: 23216, s: "ISUR.MC", n: "Inmobiliaria del Sur SA", c: "Spain" },
  { id: 23217, s: "LIB.MC", n: "Libertas 7 SA", c: "Spain" },
  { id: 23218, s: "LLN.MC", n: "Lleidanetworks Serveis Telematics SA", c: "Spain" },
  { id: 23219, s: "HRPK.HM", n: "7C Solarparken AG", c: "GER" },
  { id: 23220, s: "HPHA.HM", n: "Heidelberg Pharma AG", c: "GER" },
  { id: 23221, s: "HOT.HM", n: "HOCHTIEF Aktiengesellschaft", c: "GER" },
  { id: 23222, s: "HHX.HM", n: "HAMMONIA Schiffsholding AG", c: "GER" },
  { id: 23223, s: "HEN.HM", n: "Henkel AG & Co. KGaA", c: "GER" },
  { id: 23224, s: "EKIZ.IS", n: "Ekiz Kimya Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23225, s: "MCM.MC", n: "Miquel y Costas & Miquel S.A.", c: "Spain" },
  { id: 23226, s: "WSG.L", n: "Westminster Group Plc", c: "UK" },
  { id: 23227, s: "YVIT.MC", n: "Vitruvio Real Estate Socimi", c: "Spain" },
  { id: 23228, s: "YVCP.MC", n: "Veracruz Properties SOCIMI SA", c: "Spain" },
  { id: 23229, s: "BIO.HM", n: "Biotest Aktiengesellschaft", c: "GER" },
  { id: 23230, s: "DMP.HM", n: "Dermapharm Holding SE", c: "GER" },
  { id: 23231, s: "DFV.HM", n: "DFV Deutsche Familienversicherung AG", c: "GER" },
  { id: 23232, s: "COP.HM", n: "Compugroup Medical SE & Co. KGaA", c: "GER" },
  { id: 23233, s: "VYT.MC", n: "Vytrus Biotech S.A.", c: "Spain" },
  { id: 23234, s: "YADR.MC", n: "Adriano Care SOCIMI SAU", c: "Spain" },
  { id: 23235, s: "BYW6.HM", n: "BayWa AktienGesellschaft Vink.N", c: "GER" },
  { id: 23236, s: "CYBQY", n: "Cyberdyne Inc ADR", c: "US" },
  { id: 23237, s: "YVBA.MC", n: "VBARE Iberian Properties SOCIMI SA", c: "Spain" },
  { id: 23238, s: "YORE.MC", n: "Olimpo Real Estate SA", c: "Spain" },
  { id: 23239, s: "B4B3.HM", n: "Metro AG", c: "GER" },
  { id: 23240, s: "YTRA.MC", n: "Trajano Iberia Socimi SA", c: "Spain" },
  { id: 23241, s: "YTST.MC", n: "Testa Residencial SOCIMI S.A.", c: "Spain" },
  { id: 23242, s: "EUHOL.IS", n: "Euro Yatirim Holding AS", c: "Turkey" },
  { id: 23243, s: "CYFL", n: "Century Financial Co", c: "US" },
  { id: 23244, s: "MED.MC", n: "Medcomtech S. A.", c: "Spain" },
  { id: 23245, s: "TMC.V", n: "Trench Metals Co", c: "CA" },
  { id: 23246, s: "ELITE.IS", n: "Elite Naturel Organik Gida Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23247, s: "3390.TWO", n: "Sunflex Tech", c: "TW" },
  { id: 23248, s: "3455.TWO", n: "Utechzone", c: "TW" },
  { id: 23249, s: "3444.TWO", n: "Niching Industrial", c: "TW" },
  { id: 23250, s: "BNTAS.IS", n: "Bantas Bandirma Ambalaj Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23251, s: "3438.TWO", n: "Advanced Analog Technology", c: "TW" },
  { id: 23252, s: "3434.TWO", n: "Acula Technology", c: "TW" },
  { id: 23253, s: "3402.TWO", n: "Wholetech System Hitech", c: "TW" },
  { id: 23254, s: "3379.TWO", n: "Taiwan Benefit", c: "TW" },
  { id: 23255, s: "SFQ.HM", n: "SAF-Holland SE", c: "GER" },
  { id: 23256, s: "75S.DE", n: "SDM SE", c: "GER" },
  { id: 23257, s: "CAG.V", n: "Composite Alliance Group Inc", c: "CA" },
  { id: 23258, s: "3372.TWO", n: "Taiwan IC Packaging", c: "TW" },
  { id: 23259, s: "3363.TWO", n: "FOCI Fiber Optic Communications", c: "TW" },
  { id: 23260, s: "BWCG.V", n: "Blackwolf Copper and Gold", c: "CA" },
  { id: 23261, s: "3360.TWO", n: "Sunnic Technology & Merchandise", c: "TW" },
  { id: 23262, s: "CUSAN.IS", n: "Cuhadaroglu Metal Sanayi ve Pazarlama AS", c: "Turkey" },
  { id: 23263, s: "EUYO.IS", n: "Euro Menkul Kiymet Yatirim Ortakligi AS", c: "Turkey" },
  { id: 23264, s: "H2RB.HM", n: "Halloren Schokoladenfabrik Aktiengesellschaft", c: "GER" },
  { id: 23265, s: "NTH.MC", n: "Naturhouse Health SA", c: "Spain" },
  { id: 23266, s: "MTB.MC", n: "Montebalito S.A", c: "Spain" },
  { id: 23267, s: "EMNIS.IS", n: "Eminis Ambalaj Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23268, s: "FGX.NE", n: "Faircourt Gold Income Co", c: "CA" },
  { id: 23269, s: "NBI.MC", n: "NBI Bearings Europe S.A", c: "Spain" },
  { id: 23270, s: "NEA.MC", n: "Nicolas Correa SA", c: "Spain" },
  { id: 23271, s: "FPE.HM", n: "Fuchs Petrolub SE", c: "GER" },
  { id: 23272, s: "NTX.MC", n: "Netex Knowledge Factory SL", c: "Spain" },
  { id: 23273, s: "EXJ.HM", n: "Enerxy AG", c: "GER" },
  { id: 23274, s: "CSLI", n: "Critical Solutions", c: "US" },
  { id: 23275, s: "CCHW.NE", n: "Columbia Care Inc", c: "CA" },
  { id: 23276, s: "PANG.MC", n: "Pangaea Oncology S.A.", c: "Spain" },
  { id: 23277, s: "PAR.MC", n: "Parlem Telecom Companyia de Telecomunicacions S.A.", c: "Spain" },
  { id: 23278, s: "ALSI", n: "Atlas Resources International Inc", c: "US" },
  { id: 23279, s: "R4.MC", n: "Renta 4 Banco S.A", c: "Spain" },
  { id: 23280, s: "XSG.L", n: "Xeros Technology Group Plc", c: "UK" },
  { id: 23281, s: "DRW8.HM", n: "Drägerwerk AG & Co. KGaA", c: "GER" },
  { id: 23282, s: "SQRL.MC", n: "Squirrel Media SA", c: "Spain" },
  { id: 23283, s: "GGR.MC", n: "Grupo Greening 2022 SA", c: "Spain" },
  { id: 23284, s: "ETC.MC", n: "Energy Solar Tech S.L.", c: "Spain" },
  { id: 23285, s: "FACE.MC", n: "Facephi Biometria SA", c: "Spain" },
  { id: 23286, s: "DZGYO.IS", n: "Deniz Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 23287, s: "ADY.V", n: "Adyton Resources Co", c: "CA" },
  { id: 23288, s: "INS.HM", n: "Instone Real Estate Group AG", c: "GER" },
  { id: 23289, s: "ACU.V", n: "Aurora Solar Technologies Inc", c: "CA" },
  { id: 23290, s: "AAU.V", n: "Angold Resources", c: "CA" },
  { id: 23291, s: "1268.TWO", n: "Hi-Lai Foods", c: "TW" },
  { id: 23292, s: "EDATA.IS", n: "E-Data Teknoloji Pazarlama AS", c: "Turkey" },
  { id: 23293, s: "EAT.MC", n: "AmRest Holdings SE", c: "Spain" },
  { id: 23294, s: "HAN.MC", n: "Hannun SA", c: "Spain" },
  { id: 23295, s: "1777.TWO", n: "SYN-Tech Chem & Pharm", c: "TW" },
  { id: 23296, s: "1788.TWO", n: "Hi-Clearance", c: "TW" },
  { id: 23297, s: "2743.TWO", n: "Richmond International Travel & Tours", c: "TW" },
  { id: 23298, s: "1264.TWO", n: "Tehmag Foods", c: "TW" },
  { id: 23299, s: "1336.TWO", n: "Taihan Precision Technology", c: "TW" },
  { id: 23300, s: "2736.TWO", n: "HOYA Resort Hotel Group", c: "TW" },
  { id: 23301, s: "M5S.HM", n: "Marna Beteiligungen AG", c: "GER" },
  { id: 23302, s: "DNISI.IS", n: "Dinamik Isi Makina Yalitim Malzemeleri Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23303, s: "1599.TWO", n: "Aeon Motor", c: "TW" },
  { id: 23304, s: "MBG.HM", n: "Mercedes-Benz Group AG", c: "GER" },
  { id: 23305, s: "EEP.MC", n: "Euroespes S.A.", c: "Spain" },
  { id: 23306, s: "1593.TWO", n: "Chi Hua Fitness", c: "TW" },
  { id: 23307, s: "AGD.V", n: "Antioquia Gold Inc", c: "CA" },
  { id: 23308, s: "DOGUB.IS", n: "Dogusan Boru Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23309, s: "KD8.HM", n: "Kabel Deutschland Holding AG", c: "GER" },
  { id: 23310, s: "ELZ.MC", n: "Asturiana de Laminados S.A.", c: "Spain" },
  { id: 23311, s: "1570.TWO", n: "De Poan Pneumatic", c: "TW" },
  { id: 23312, s: "KSB.HM", n: "KSB SE & Co. KGaA", c: "GER" },
  { id: 23313, s: "1569.TWO", n: "Bin Chuan Enterprise", c: "TW" },
  { id: 23314, s: "DOKTA.IS", n: "Doktas Dokumculuk Ticaret ve Sanayi AS", c: "Turkey" },
  { id: 23315, s: "END.MC", n: "Endurance Motive S.A.", c: "Spain" },
  { id: 23316, s: "AMEN.MC", n: "Aeternal Mentis SA", c: "Spain" },
  { id: 23317, s: "DAPGM.IS", n: "Dap Gayrimenkul Gelistirme AS", c: "Turkey" },
  { id: 23318, s: "SNG.MC", n: "Singular People S.A.", c: "Spain" },
  { id: 23319, s: "DMSAS.IS", n: "Demisas Dokum Emaye Mamulleri Sanayi AS", c: "Turkey" },
  { id: 23320, s: "MSGL.HM", n: "msg life ag", c: "GER" },
  { id: 23321, s: "MRX.HM", n: "Mister Spex SE", c: "GER" },
  { id: 23322, s: "ALM.V", n: "Alliance Mining Co", c: "CA" },
  { id: 23323, s: "DGNMO.IS", n: "Doganlar Mobilya Grubu Imalat Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23324, s: "1813.TWO", n: "Polylite Taiwan", c: "TW" },
  { id: 23325, s: "MRK.HM", n: "Merck & Company Inc", c: "GER" },
  { id: 23326, s: "DAGI.IS", n: "Dagi Giyim Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 23327, s: "AME.V", n: "Abacus Mining and Exploration Co", c: "CA" },
  { id: 23328, s: "HLZ.MC", n: "Holaluz-Clidom S.A", c: "Spain" },
  { id: 23329, s: "PFE.NE", n: "Pfizer Inc CDR", c: "CA" },
  { id: 23330, s: "SAI.MC", n: "Substrate AI S.A.", c: "Spain" },
  { id: 23331, s: "YU.L", n: "Yu Group PLC", c: "UK" },
  { id: 23332, s: "EOAN.HM", n: "E.ON SE", c: "GER" },
  { id: 23333, s: "CTXAY", n: "Ampol ADR", c: "US" },
  { id: 23334, s: "2061.TWO", n: "Feng Ching Metal", c: "TW" },
  { id: 23335, s: "NB2.HM", n: "Northern Data AG", c: "GER" },
  { id: 23336, s: "2732.TWO", n: "La Kaffa International", c: "TW" },
  { id: 23337, s: "SB1.HM", n: "Smartbroker Holding AG", c: "GER" },
  { id: 23338, s: "2729.TWO", n: "TTFB", c: "TW" },
  { id: 23339, s: "2643.TWO", n: "Soonest Express", c: "TW" },
  { id: 23340, s: "2641.TWO", n: "Franbo Lines", c: "TW" },
  { id: 23341, s: "DARDL.IS", n: "Dardanel Onentas Gida Sanayi AS", c: "Turkey" },
  { id: 23342, s: "ART.MC", n: "Arteche Lantegi Elkartea SA", c: "Spain" },
  { id: 23343, s: "DENGE.IS", n: "Denge Yatirim Holding AS", c: "Turkey" },
  { id: 23344, s: "2230.TWO", n: "CTI Traffic Industries", c: "TW" },
  { id: 23345, s: "2064.TWO", n: "Camellia Metal", c: "TW" },
  { id: 23346, s: "RUC.HM", n: "RUECKER IMMOBILIEN", c: "GER" },
  { id: 23347, s: "2221.TWO", n: "Ta Chia Yung Ho Machine Industry", c: "TW" },
  { id: 23348, s: "2070.TWO", n: "Ching Chan Optical Technology", c: "TW" },
  { id: 23349, s: "NWO.HM", n: "New Work SE", c: "GER" },
  { id: 23350, s: "2066.TWO", n: "Sumeeko Industries", c: "TW" },
  { id: 23351, s: "2065.TWO", n: "Sheh Fung Screws", c: "TW" },
  { id: 23352, s: "1454.TW", n: "Taiwan Taffeta Fabric", c: "TW" },
  { id: 23353, s: "6225.TW", n: "Aiptek International Inc", c: "TW" },
  { id: 23354, s: "UDC.F", n: "UNIDEVICE AG INH O.N.", c: "GER" },
  { id: 23355, s: "6432.TWO", n: "Arlitech Electronic", c: "TW" },
  { id: 23356, s: "ALLEX.PA", n: "Lexibook Linguistic Electronic System SA", c: "FR" },
  { id: 23357, s: "8076.TWO", n: "Firich Enterprises", c: "TW" },
  { id: 23358, s: "8083.TWO", n: "Pro-Hawk", c: "TW" },
  { id: 23359, s: "CDEVY", n: "City Developments", c: "US" },
  { id: 23360, s: "LBI.V", n: "Lions Bay Capital Inc", c: "CA" },
  { id: 23361, s: "6426.TW", n: "Apogee Optocom", c: "TW" },
  { id: 23362, s: "6438.TW", n: "Symtek Automation Asia", c: "TW" },
  { id: 23363, s: "ADN1.SG", n: "Adesso SE", c: "GER" },
  { id: 23364, s: "AOX.SG", n: "alstria office REIT-AG", c: "GER" },
  { id: 23365, s: "6442.TW", n: "EZconn Co", c: "TW" },
  { id: 23366, s: "ARL.SG", n: "Aareal Bank AG", c: "GER" },
  { id: 23367, s: "VEZ.DE", n: "Veganz Group AG", c: "GER" },
  { id: 23368, s: "B4B.SG", n: "Metro AG", c: "GER" },
  { id: 23369, s: "B4B3.SG", n: "Metro AG vz. (Pref Shares)", c: "GER" },
  { id: 23370, s: "6449.TW", n: "APAQ Technology", c: "TW" },
  { id: 23371, s: "ALFLE.PA", n: "Fleury Michon", c: "FR" },
  { id: 23372, s: "PDA.DE", n: "PRO DV AG", c: "GER" },
  { id: 23373, s: "MNTR.V", n: "Mantaro Precious Metals Co", c: "CA" },
  { id: 23374, s: "ALFRE.PA", n: "Freelance.Com", c: "FR" },
  { id: 23375, s: "6423.TWO", n: "Yield Microelectronics", c: "TW" },
  { id: 23376, s: "6418.TWO", n: "Y-S Electronic", c: "TW" },
  { id: 23377, s: "PWO.DE", n: "Progress-Werk Oberkirch AG", c: "GER" },
  { id: 23378, s: "MOON.V", n: "Blue Moon Zinc Co", c: "CA" },
  { id: 23379, s: "6266.TWO", n: "Top Union Electronics", c: "TW" },
  { id: 23380, s: "ALGIR.PA", n: "Signaux Girod", c: "FR" },
  { id: 23381, s: "8249.TW", n: "Creative Sensor Inc", c: "TW" },
  { id: 23382, s: "6294.TWO", n: "Tekom Technologies", c: "TW" },
  { id: 23383, s: "MON.V", n: "Montero Mining and Exploration", c: "CA" },
  { id: 23384, s: "NUVA.DE", n: "Noratis AG", c: "GER" },
  { id: 23385, s: "MNX.V", n: "Manitex Capital Inc", c: "CA" },
  { id: 23386, s: "6284.TWO", n: "INPAQ Technology", c: "TW" },
  { id: 23387, s: "6290.TWO", n: "Longwell", c: "TW" },
  { id: 23388, s: "GXX.V", n: "Gold Basin Resources Co", c: "CA" },
  { id: 23389, s: "BBR.SG", n: "Bürgerliches Brauhaus Ravensburg - Lindau Aktiengesellschaft", c: "GER" },
  { id: 23390, s: "BIO.SG", n: "Biotest Aktiengesellschaft", c: "GER" },
  { id: 23391, s: "BIO3.SG", n: "Biotest Aktiengesellschaft", c: "GER" },
  { id: 23392, s: "1416.TW", n: "Kwong Fong Industries Co", c: "TW" },
  { id: 23393, s: "VROS.DE", n: "Verianos SE", c: "GER" },
  { id: 23394, s: "1432.TW", n: "Trk Co", c: "TW" },
  { id: 23395, s: "1423.TW", n: "Reward Wool Industry Co", c: "TW" },
  { id: 23396, s: "123F.DU", n: "123fahrschule SE", c: "GER" },
  { id: 23397, s: "VH2.F", n: "Friedrich Vorwerk Group SE", c: "GER" },
  { id: 23398, s: "VIB3.F", n: "Villeroy & Boch AG", c: "GER" },
  { id: 23399, s: "VIH1.F", n: "VIB Vermögen AG", c: "GER" },
  { id: 23400, s: "8941.TWO", n: "Grand Hall Enterprise", c: "TW" },
  { id: 23401, s: "1413.TW", n: "Hung Chou Fiber Ind", c: "TW" },
  { id: 23402, s: "1410.TW", n: "Nan Yang Dyeing & Finishing", c: "TW" },
  { id: 23403, s: "IZN.V", n: "InZinc Mining", c: "CA" },
  { id: 23404, s: "Z29.DE", n: "029 GROUP SE INH O.N.", c: "GER" },
  { id: 23405, s: "VQT.F", n: "va-Q-tec AG", c: "GER" },
  { id: 23406, s: "KOVO.V", n: "Kovo Healthtech Co", c: "CA" },
  { id: 23407, s: "1235.TW", n: "Shin Tai Industry", c: "TW" },
  { id: 23408, s: "CPX.SG", n: "capsensixx AG", c: "GER" },
  { id: 23409, s: "BMW3.SG", n: "Bayerische Motoren Werke Aktiengesellschaft vz. (Pref Shs)", c: "GER" },
  { id: 23410, s: "CSQ.SG", n: "creditshelf Aktiengesellschaft", c: "GER" },
  { id: 23411, s: "BTBB.SG", n: "Sleepz AG", c: "GER" },
  { id: 23412, s: "8942.TWO", n: "Xxentria Technology Materials", c: "TW" },
  { id: 23413, s: "9951.TWO", n: "Macauto Industrial", c: "TW" },
  { id: 23414, s: "TTR1.DE", n: "technotrans SE", c: "GER" },
  { id: 23415, s: "DFV.SG", n: "DFV Deutsche Familienversicherung AG", c: "GER" },
  { id: 23416, s: "9960.TWO", n: "Dacome International", c: "TW" },
  { id: 23417, s: "Z29.F", n: "029 GROUP SE INH O.N.", c: "GER" },
  { id: 23418, s: "1110.TW", n: "Southeast Cement", c: "TW" },
  { id: 23419, s: "CESX", n: "Ces Synergies Inc", c: "US" },
  { id: 23420, s: "CCB.SG", n: "INST IPO HOLD (CCB.SG)", c: "GER" },
  { id: 23421, s: "BYW6.SG", n: "BayWa AktienGesellschaft Vink.N", c: "GER" },
  { id: 23422, s: "BYW.SG", n: "BayWa AktienGesellschaft", c: "GER" },
  { id: 23423, s: "LITT.V", n: "Roadman Investments Co", c: "CA" },
  { id: 23424, s: "1109.TW", n: "Hsing Ta Cement", c: "TW" },
  { id: 23425, s: "ALENE.PA", n: "Enertime SAS", c: "FR" },
  { id: 23426, s: "6435.TWO", n: "Sinopower Semiconductor", c: "TW" },
  { id: 23427, s: "1323.TW", n: "Yonyu Plastics", c: "TW" },
  { id: 23428, s: "6441.TWO", n: "Ibase Gaming", c: "TW" },
  { id: 23429, s: "ABLD.PA", n: "AbL Diagnostics SA", c: "FR" },
  { id: 23430, s: "8027.TWO", n: "E&R Engineering", c: "TW" },
  { id: 23431, s: "8032.TWO", n: "Koryo Electronics", c: "TW" },
  { id: 23432, s: "6684.TWO", n: "Algoltek Inc", c: "TW" },
  { id: 23433, s: "6668.TW", n: "Zhong Yang Technology", c: "TW" },
  { id: 23434, s: "ALDNX.PA", n: "DNXCo", c: "FR" },
  { id: 23435, s: "6485.TWO", n: "ASolid Technology", c: "TW" },
  { id: 23436, s: "6248.TWO", n: "TMP Steel", c: "TW" },
  { id: 23437, s: "NF4.DE", n: "Netfonds AG", c: "GER" },
  { id: 23438, s: "MRVL.V", n: "Marvel Biosciences Co", c: "CA" },
  { id: 23439, s: "6242.TWO", n: "Li Kang Biomedical", c: "TW" },
  { id: 23440, s: "ALHYG.PA", n: "Hybrigenics", c: "FR" },
  { id: 23441, s: "6237.TWO", n: "C-Media Electronics", c: "TW" },
  { id: 23442, s: "6234.TWO", n: "Tera Autotech", c: "TW" },
  { id: 23443, s: "6233.TWO", n: "Prolific Technology", c: "TW" },
  { id: 23444, s: "HVG.V", n: "Harvest Gold Co", c: "CA" },
  { id: 23445, s: "HOC.V", n: "Hunter Oil Co", c: "CA" },
  { id: 23446, s: "HMAN.V", n: "Huntsman Exploration Inc", c: "CA" },
  { id: 23447, s: "IBO.V", n: "Universal Ibogaine Inc", c: "CA" },
  { id: 23448, s: "8047.TWO", n: "Great Computer", c: "TW" },
  { id: 23449, s: "6715.TW", n: "Lintes Technology", c: "TW" },
  { id: 23450, s: "ICON.V", n: "International Iconic Gold Exploration Co", c: "CA" },
  { id: 23451, s: "WOL.VI", n: "Wolford Aktiengesellschaft", c: "Austria" },
  { id: 23452, s: "WXF.VI", n: "Warimpex Finanz- und Beteiligungs AG", c: "Austria" },
  { id: 23453, s: "6754.TW", n: "Rich Honour International Designs", c: "TW" },
  { id: 23454, s: "6651.TWO", n: "Cystech Electronics Co", c: "TW" },
  { id: 23455, s: "6683.TWO", n: "Keystone Microtech Co", c: "TW" },
  { id: 23456, s: "6654.TWO", n: "Tian Zheng International Precision Machinery", c: "TW" },
  { id: 23457, s: "6664.TWO", n: "Group Up Industrial", c: "TW" },
  { id: 23458, s: "SHF.DE", n: "SNP Schneider-Neureither & Partner SE", c: "GER" },
  { id: 23459, s: "ALACT.PA", n: "Acticor Biotech SAS", c: "FR" },
  { id: 23460, s: "SIS.DE", n: "First Sensor AG", c: "GER" },
  { id: 23461, s: "ALAGO.PA", n: "E-Pango SA", c: "FR" },
  { id: 23462, s: "NC5A.DE", n: "NorCom Information Technology GmbH & Co. KGaA", c: "GER" },
  { id: 23463, s: "6231.TWO", n: "Insyde Software", c: "TW" },
  { id: 23464, s: "6229.TWO", n: "V-Tac Technology", c: "TW" },
  { id: 23465, s: "6482.TWO", n: "Fun Yours Technology", c: "TW" },
  { id: 23466, s: "6217.TWO", n: "CCP Contact Probe", c: "TW" },
  { id: 23467, s: "ALKEM.PA", n: "Berkem Group SA", c: "FR" },
  { id: 23468, s: "6486.TWO", n: "Interactive Digital Technologies", c: "TW" },
  { id: 23469, s: "8367.TW", n: "Chien Shing Harbour Service", c: "TW" },
  { id: 23470, s: "6265.TWO", n: "Kuen Chaang Uppertech", c: "TW" },
  { id: 23471, s: "8101.TW", n: "Arima Communications Co", c: "TW" },
  { id: 23472, s: "CHFFY", n: "CH Everbright International ADR", c: "US" },
  { id: 23473, s: "ALIMP.PA", n: "Implanet SA", c: "FR" },
  { id: 23474, s: "6465.TWO", n: "ATrack Technology", c: "TW" },
  { id: 23475, s: "6462.TWO", n: "Egis Technology", c: "TW" },
  { id: 23476, s: "6461.TWO", n: "Intech Biopharm", c: "TW" },
  { id: 23477, s: "8104.TW", n: "RiTdisplay Co", c: "TW" },
  { id: 23478, s: "8105.TW", n: "Giantplus Technology", c: "TW" },
  { id: 23479, s: "RCMN.DE", n: "Rcm Beteiligungs AG", c: "GER" },
  { id: 23480, s: "6212.TWO", n: "LI Ming Development Construction", c: "TW" },
  { id: 23481, s: "ALIMR.PA", n: "Immersion SA", c: "FR" },
  { id: 23482, s: "8462.TW", n: "Power Wind Health Industry Incorporated", c: "TW" },
  { id: 23483, s: "ALLAN.PA", n: "Lanson BCC SA", c: "FR" },
  { id: 23484, s: "ALIKO.PA", n: "Ikonisys SA", c: "FR" },
  { id: 23485, s: "8463.TW", n: "Ruentex Materials", c: "TW" },
  { id: 23486, s: "CIIHY", n: "CITIC Securities Co ADR", c: "US" },
  { id: 23487, s: "6219.TWO", n: "Full Wang International Development", c: "TW" },
  { id: 23488, s: "6218.TWO", n: "Hauman Technologies", c: "TW" },
  { id: 23489, s: "ALLDL.PA", n: "Groupe LDLC SA", c: "FR" },
  { id: 23490, s: "MSAG.DE", n: "MS Industrie AG", c: "GER" },
  { id: 23491, s: "8473.TW", n: "Forest Water Environmental Eng", c: "TW" },
  { id: 23492, s: "ALKLK.PA", n: "Kerlink SAS", c: "FR" },
  { id: 23493, s: "6194.TWO", n: "Yufo Electronics", c: "TW" },
  { id: 23494, s: "ALKKO.PA", n: "Kko International SA", c: "FR" },
  { id: 23495, s: "ALGTR.PA", n: "Groupe Tera SA", c: "FR" },
  { id: 23496, s: "MXHN.DE", n: "MAX Automation SE", c: "GER" },
  { id: 23497, s: "GTC.V", n: "Getty Copper Inc.", c: "CA" },
  { id: 23498, s: "1324.TW", n: "Globe Industries Co", c: "TW" },
  { id: 23499, s: "1321.TW", n: "Ocean Plastics", c: "TW" },
  { id: 23500, s: "EWS.V", n: "Environmental Waste International", c: "CA" },
  { id: 23501, s: "SIX3.SG", n: "Sixt SE vz. (Pref Shares)", c: "GER" },
  { id: 23502, s: "4711.TWO", n: "Yong Shun Chemical", c: "TW" },
  { id: 23503, s: "EQ.V", n: "EQ Inc.", c: "CA" },
  { id: 23504, s: "FORSE.PA", n: "Forsee Power SAS", c: "FR" },
  { id: 23505, s: "4575.TWO", n: "Chumpower Machinery Co", c: "TW" },
  { id: 23506, s: "PGDC.V", n: "Patagonia Gold Co", c: "CA" },
  { id: 23507, s: "4568.TWO", n: "Koge Micro Tech", c: "TW" },
  { id: 23508, s: "PGX.V", n: "Prosper Gold Corp.", c: "CA" },
  { id: 23509, s: "4563.TWO", n: "Quaser Machine Tools", c: "TW" },
  { id: 23510, s: "4561.TWO", n: "kenturn Nano Tec", c: "TW" },
  { id: 23511, s: "4556.TWO", n: "Bright Sheland International", c: "TW" },
  { id: 23512, s: "4550.TWO", n: "Chang Jia M&E Engineering", c: "TW" },
  { id: 23513, s: "DIE.DE", n: "Dierig Holding AG", c: "GER" },
  { id: 23514, s: "4433.TWO", n: "Singtex Industrial", c: "TW" },
  { id: 23515, s: "EMNT.V", n: "Eminent Gold Co", c: "CA" },
  { id: 23516, s: "GPE.PA", n: "Groupe Pizzorno Environnement SA", c: "FR" },
  { id: 23517, s: "PGC.V", n: "Plato Gold Corp.", c: "CA" },
  { id: 23518, s: "FIPP.PA", n: "FIPP S.A.", c: "FR" },
  { id: 23519, s: "EDL.DE", n: "Edel SE & Co. KGaA", c: "GER" },
  { id: 23520, s: "M0YN.SG", n: "Mynaric AG", c: "GER" },
  { id: 23521, s: "SB1.SG", n: "Smartbroker Holding AG", c: "GER" },
  { id: 23522, s: "8431.TWO", n: "Superior Plating Technology", c: "TW" },
  { id: 23523, s: "TIS.SG", n: "tiscon AG", c: "GER" },
  { id: 23524, s: "6558.TW", n: "SYNergy ScienTech Co", c: "TW" },
  { id: 23525, s: "TION.DE", n: "TION RENEWABLES AG", c: "GER" },
  { id: 23526, s: "8435.TWO", n: "Zimmite TW", c: "TW" },
  { id: 23527, s: "8440.TWO", n: "E&E Recycling", c: "TW" },
  { id: 23528, s: "FCMC.PA", n: "Ferm. Casino Mun. Canne", c: "FR" },
  { id: 23529, s: "KWG.SG", n: "KHD Humboldt Wedag International AG", c: "GER" },
  { id: 23530, s: "8450.TWO", n: "Pili International Multimedia", c: "TW" },
  { id: 23531, s: "MBG.SG", n: "Mercedes-Benz Group AG", c: "GER" },
  { id: 23532, s: "6185.TWO", n: "Plastron Precision", c: "TW" },
  { id: 23533, s: "MPCK.DE", n: "MPC Münchmeyer Petersen Capital AG", c: "GER" },
  { id: 23534, s: "MTS.V", n: "Metallis Resources Inc.", c: "CA" },
  { id: 23535, s: "PHD.V", n: "Providence Gold Mines", c: "CA" },
  { id: 23536, s: "ALMAD.IS", n: "Altinyag Kombinalari AS", c: "Turkey" },
  { id: 23537, s: "DGR.DE", n: "Deutsche Grundstücksauktionen AG", c: "GER" },
  { id: 23538, s: "FKM.V", n: "Fokus Mining Co", c: "CA" },
  { id: 23539, s: "4974.TWO", n: "Asia Tech Image", c: "TW" },
  { id: 23540, s: "DKUPL.PA", n: "Dekuple", c: "FR" },
  { id: 23541, s: "4972.TWO", n: "Tons Lightology", c: "TW" },
  { id: 23542, s: "GGS.DE", n: "Gigaset AG", c: "GER" },
  { id: 23543, s: "FMN.V", n: "Fidelity Minerals Co", c: "CA" },
  { id: 23544, s: "FMAN.V", n: "Freeman Gold Co", c: "CA" },
  { id: 23545, s: "ARGQ", n: "Argentum 47 Inc", c: "US" },
  { id: 23546, s: "FNR.V", n: "49 North Resources Inc", c: "CA" },
  { id: 23547, s: "G6P.DE", n: "Going Public Media Aktiengesellschaft", c: "GER" },
  { id: 23548, s: "FAIR.V", n: "Fairchild Gold Co", c: "CA" },
  { id: 23549, s: "OZ.V", n: "Outback Goldfields Co", c: "CA" },
  { id: 23550, s: "AKMGY.IS", n: "Akmerkez Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 23551, s: "AKGRT.IS", n: "AK Sigorta AS", c: "Turkey" },
  { id: 23552, s: "EVER.V", n: "Evergold Co", c: "CA" },
  { id: 23553, s: "FNC.V", n: "Fancamp Exploration.", c: "CA" },
  { id: 23554, s: "FRNT.V", n: "FRNT Financial Inc", c: "CA" },
  { id: 23555, s: "PCQ.V", n: "Petrolympic", c: "CA" },
  { id: 23556, s: "FREY.PA", n: "Frey SA", c: "FR" },
  { id: 23557, s: "4523.TWO", n: "Everbrite Technology", c: "TW" },
  { id: 23558, s: "4528.TWO", n: "Chian Hsing Forging Industrial", c: "TW" },
  { id: 23559, s: "GDS.PA", n: "Ramsay Generale De Sante", c: "FR" },
  { id: 23560, s: "ENRG.V", n: "New Energy Metals Co", c: "CA" },
  { id: 23561, s: "4535.TWO", n: "Fine Blanking & Tool", c: "TW" },
  { id: 23562, s: "4538.TWO", n: "WINSON Machinery", c: "TW" },
  { id: 23563, s: "4527.TWO", n: "Kuen Ling Refrigerating Machinery", c: "TW" },
  { id: 23564, s: "FTEC.V", n: "Fintech Select", c: "CA" },
  { id: 23565, s: "ANSGR.IS", n: "Anadolu Anonim Turk Sigorta Sti", c: "Turkey" },
  { id: 23566, s: "EXPL.PA", n: "EPC Groupe", c: "FR" },
  { id: 23567, s: "CMGMY", n: "Chemring Group PLC ADR", c: "US" },
  { id: 23568, s: "ADESE.IS", n: "Adese Gayrimenkul Yatirim AS", c: "Turkey" },
  { id: 23569, s: "5013.TWO", n: "New Best Wire Industrial", c: "TW" },
  { id: 23570, s: "FYL.V", n: "Finlay Minerals", c: "CA" },
  { id: 23571, s: "SGU.SG", n: "Sino-German United AG", c: "GER" },
  { id: 23572, s: "SRT.SG", n: "Sartorius Aktiengesellschaft", c: "GER" },
  { id: 23573, s: "WUG3.F", n: "Westag & Getalit AG", c: "GER" },
  { id: 23574, s: "STG.SG", n: "STINAG Stuttgart Invest AG", c: "GER" },
  { id: 23575, s: "TTO.SG", n: "TTL Beteiligungs- und Grundbesitz-AG", c: "GER" },
  { id: 23576, s: "VHO.SG", n: "VALUE-HOLDINGS (VHO.SG)", c: "GER" },
  { id: 23577, s: "8342.TWO", n: "I Jang Industrial", c: "TW" },
  { id: 23578, s: "WEW.SG", n: "Westwing Group AG", c: "GER" },
  { id: 23579, s: "8289.TWO", n: "Taitien Electronics", c: "TW" },
  { id: 23580, s: "8284.TWO", n: "Mitake Information", c: "TW" },
  { id: 23581, s: "WIG1.SG", n: "Sporttotal AG", c: "GER" },
  { id: 23582, s: "WUG3.SG", n: "Westag & Getalit AG vz. (Pref Shares)", c: "GER" },
  { id: 23583, s: "8240.TWO", n: "Wah Hong Industrial", c: "TW" },
  { id: 23584, s: "8234.TWO", n: "NEXCOM International", c: "TW" },
  { id: 23585, s: "8183.TWO", n: "Info-Tek", c: "TW" },
  { id: 23586, s: "XD4.SG", n: "Strabag SE", c: "GER" },
  { id: 23587, s: "8176.TWO", n: "Z-Com Inc", c: "TW" },
  { id: 23588, s: "8171.TWO", n: "Formosa Electronic Industries", c: "TW" },
  { id: 23589, s: "TOTG.OL", n: "Totens Sparebank", c: "Norway" },
  { id: 23590, s: "TKA.SG", n: "thyssenkrupp AG", c: "GER" },
  { id: 23591, s: "8936.TWO", n: "Kuo Toong International", c: "TW" },
  { id: 23592, s: "6504.TW", n: "Nan Liu Enterprise", c: "TW" },
  { id: 23593, s: "6283.TW", n: "Shun On Electronic", c: "TW" },
  { id: 23594, s: "WUG.F", n: "Westag & Getalit ST O.N.", c: "GER" },
  { id: 23595, s: "1307.TW", n: "San Fang Chemical Industry", c: "TW" },
  { id: 23596, s: "6405.TW", n: "Onano Industrial Co", c: "TW" },
  { id: 23597, s: "WDL1.F", n: "WINDELN.DE SE INH O.N.", c: "GER" },
  { id: 23598, s: "KTR.V", n: "Kintavar Exploration Inc", c: "CA" },
  { id: 23599, s: "1236.TW", n: "Hunya Foods", c: "TW" },
  { id: 23600, s: "XTP.DE", n: "Sino AG", c: "GER" },
  { id: 23601, s: "8080.TWO", n: "Taiwan Ostor", c: "TW" },
  { id: 23602, s: "6277.TW", n: "Aten International", c: "TW" },
  { id: 23603, s: "VTSC.F", n: "Vitesco Technologies Group Aktiengesellschaft", c: "GER" },
  { id: 23604, s: "VSC.F", n: "4SC AG", c: "GER" },
  { id: 23605, s: "1316.TW", n: "Sun Yad Construction", c: "TW" },
  { id: 23606, s: "VROS.F", n: "Verianos SE", c: "GER" },
  { id: 23607, s: "KRL.V", n: "Kainantu Resources", c: "CA" },
  { id: 23608, s: "8147.TWO", n: "Nextronics Engineering", c: "TW" },
  { id: 23609, s: "IRO.V", n: "Inter Rock Minerals Inc", c: "CA" },
  { id: 23610, s: "6655.TW", n: "Keding Enterprises", c: "TW" },
  { id: 23611, s: "HRPK.SG", n: "7C Solarparken AG", c: "GER" },
  { id: 23612, s: "LQWD.V", n: "LQwD FinTech Co", c: "CA" },
  { id: 23613, s: "8930.TWO", n: "CKM Building Material", c: "TW" },
  { id: 23614, s: "8929.TWO", n: "Fu Burg Industrial", c: "TW" },
  { id: 23615, s: "HBD1.SG", n: "Philomaxcap AG", c: "GER" },
  { id: 23616, s: "8927.TWO", n: "North-Star International", c: "TW" },
  { id: 23617, s: "HEN.SG", n: "Henkel AG & Co. KGaA", c: "GER" },
  { id: 23618, s: "ICP.SG", n: "Panamax AG", c: "GER" },
  { id: 23619, s: "EOAN.SG", n: "E.ON SE", c: "GER" },
  { id: 23620, s: "INS.SG", n: "Instone Real Estate Group AG", c: "GER" },
  { id: 23621, s: "IUR.SG", n: "KAP AG", c: "GER" },
  { id: 23622, s: "KBX.SG", n: "Knorr-Bremse AG", c: "GER" },
  { id: 23623, s: "KSB.SG", n: "KSB SE & Co. KGaA", c: "GER" },
  { id: 23624, s: "8489.TWO", n: "Samebest", c: "TW" },
  { id: 23625, s: "8477.TWO", n: "Kuobrothers", c: "TW" },
  { id: 23626, s: "FAK.SG", n: "FALKENSTEIN NEBENW (FAK.SG)", c: "GER" },
  { id: 23627, s: "TRU.DE", n: "Traumhaus AG", c: "GER" },
  { id: 23628, s: "FKA.VI", n: "Frauenthal Holding AG", c: "Austria" },
  { id: 23629, s: "8099.TWO", n: "Tatung System Technologies", c: "TW" },
  { id: 23630, s: "8091.TWO", n: "Feedback Technology", c: "TW" },
  { id: 23631, s: "8092.TWO", n: "Chien Wei Precise Technology", c: "TW" },
  { id: 23632, s: "8096.TWO", n: "CoAsia Microelectronics", c: "TW" },
  { id: 23633, s: "8383.TWO", n: "Chen Full International", c: "TW" },
  { id: 23634, s: "6625.TW", n: "B’IN Live", c: "TW" },
  { id: 23635, s: "6605.TW", n: "Depo Auto Parts Industrial", c: "TW" },
  { id: 23636, s: "8107.TWO", n: "Tayih Lun An", c: "TW" },
  { id: 23637, s: "SWVK.SG", n: "SolarWorld Aktiengesellschaft", c: "GER" },
  { id: 23638, s: "SVE.DE", n: "Shareholder Value Beteiligungen AG", c: "GER" },
  { id: 23639, s: "8111.TWO", n: "Ligitek Electronics", c: "TW" },
  { id: 23640, s: "8121.TWO", n: "Acme Electronics", c: "TW" },
  { id: 23641, s: "8097.TWO", n: "ATW Technology Inc", c: "TW" },
  { id: 23642, s: "DRW8.SG", n: "Drägerwerk AG & Co. KGaA", c: "GER" },
  { id: 23643, s: "LVX.V", n: "Leviathan Gold", c: "CA" },
  { id: 23644, s: "8048.TWO", n: "Ruby Tech", c: "TW" },
  { id: 23645, s: "8049.TWO", n: "Ampire", c: "TW" },
  { id: 23646, s: "6698.TW", n: "FineMat Applied Materials", c: "TW" },
  { id: 23647, s: "002817.SZ", n: "Anhui Huangshan Capsule", c: "CH" },
  { id: 23648, s: "MNC.WA", n: "Mennica Polska S.A.", c: "Poland" },
  { id: 23649, s: "300022.SZ", n: "Gifore Agricultural Machinery Chain", c: "CH" },
  { id: 23650, s: "MLG.WA", n: "MLP Group S.A.", c: "Poland" },
  { id: 23651, s: "MIR.WA", n: "Miraculum SA", c: "Poland" },
  { id: 23652, s: "300026.SZ", n: "Tianjin Chase Sun Pharmaceutical", c: "CH" },
  { id: 23653, s: "MFO.WA", n: "MFO SA", c: "Poland" },
  { id: 23654, s: "300029.SZ", n: "Jiangsu Huasheng Tianlong Photo", c: "CH" },
  { id: 23655, s: "MDI.WA", n: "MDI Energia S.A.", c: "Poland" },
  { id: 23656, s: "MLS.WA", n: "ML System S.A", c: "Poland" },
  { id: 23657, s: "NXG.WA", n: "Nexity Global SA", c: "Poland" },
  { id: 23658, s: "300007.SZ", n: "Henan Hanwei Electronics", c: "CH" },
  { id: 23659, s: "300006.SZ", n: "Chongqing Lummy Pharmaceutical", c: "CH" },
  { id: 23660, s: "PCF.WA", n: "PCF Group SA", c: "Poland" },
  { id: 23661, s: "200570.SZ", n: "Changchai", c: "CH" },
  { id: 23662, s: "200581.SZ", n: "Weifu High Tech Group", c: "CH" },
  { id: 23663, s: "300009.SZ", n: "Anhui Anke BioTech Group", c: "CH" },
  { id: 23664, s: "MOJ.WA", n: "MOJ S.A", c: "Poland" },
  { id: 23665, s: "MRB.WA", n: "Mirbud SA", c: "Poland" },
  { id: 23666, s: "002802.SZ", n: "Wuxi Honghui New Materials Technology", c: "CH" },
  { id: 23667, s: "002820.SZ", n: "Tianjin Guifaxiang 18th Street Mahua Food", c: "CH" },
  { id: 23668, s: "600834.SS", n: "Shanghai Shentong Metro", c: "CH" },
  { id: 23669, s: "002822.SZ", n: "Shenzhen Zhongzhuang Construction Group", c: "CH" },
  { id: 23670, s: "GLG.TO", n: "GLG Life Tech Corporation", c: "CA" },
  { id: 23671, s: "002824.SZ", n: "Guangdong Hoshion Aluminium", c: "CH" },
  { id: 23672, s: "002825.SZ", n: "Shanghai Nar Industrial", c: "CH" },
  { id: 23673, s: "002748.SZ", n: "Jiangxi Selon Industrial", c: "CH" },
  { id: 23674, s: "300016.SZ", n: "Beijing Beilu Pharmaceutical", c: "CH" },
  { id: 23675, s: "WLT.WA", n: "Wielton S.A.", c: "Poland" },
  { id: 23676, s: "WIS.WA", n: "Baltic Bridge S.A.", c: "Poland" },
  { id: 23677, s: "NWG.WA", n: "Newag S.A.", c: "Poland" },
  { id: 23678, s: "NVG.WA", n: "Novavis Group SA", c: "Poland" },
  { id: 23679, s: "PNMXO", n: "Public Service Company of New Mexico PFD 4.58%", c: "US" },
  { id: 23680, s: "MWT.WA", n: "MW Trade S.A.", c: "Poland" },
  { id: 23681, s: "200596.SZ", n: "Anhui Gujing Distillery", c: "CH" },
  { id: 23682, s: "PCE.WA", n: "Grupa Azoty Zaklady Chemiczne Police SA", c: "Poland" },
  { id: 23683, s: "PBX.WA", n: "Poznanska Korporacja Budowlana Pekabex SA", c: "Poland" },
  { id: 23684, s: "300073.SZ", n: "Beijing Easpring Material Tech", c: "CH" },
  { id: 23685, s: "300066.SZ", n: "Jiangxi Sanchuan Water Meter", c: "CH" },
  { id: 23686, s: "300067.SZ", n: "Shanghai Anoky Group", c: "CH" },
  { id: 23687, s: "300068.SZ", n: "Zhejiang Narada Power Source", c: "CH" },
  { id: 23688, s: "PLSI", n: "Phoenix Life Sciences International", c: "US" },
  { id: 23689, s: "KPL.WA", n: "Kino Polska TV SA", c: "Poland" },
  { id: 23690, s: "300072.SZ", n: "Beijing Sanju Environmental", c: "CH" },
  { id: 23691, s: "300074.SZ", n: "AVCON Information Tech", c: "CH" },
  { id: 23692, s: "300063.SZ", n: "Guangdong Sky Dragon Printing Ink Group", c: "CH" },
  { id: 23693, s: "300076.SZ", n: "Ningbo GQY Video Telecom", c: "CH" },
  { id: 23694, s: "300077.SZ", n: "Nations Technologies Inc", c: "CH" },
  { id: 23695, s: "IZB.WA", n: "Izoblok", c: "Poland" },
  { id: 23696, s: "IMS.WA", n: "IMS SA", c: "Poland" },
  { id: 23697, s: "KGL.WA", n: "Korporacja KGL S.A.", c: "Poland" },
  { id: 23698, s: "KVT.WA", n: "Krynica Vitamin SA", c: "Poland" },
  { id: 23699, s: "TBEV", n: "High Performance Beverages", c: "US" },
  { id: 23700, s: "300062.SZ", n: "Ceepower", c: "CH" },
  { id: 23701, s: "200726.SZ", n: "Lu Thai Textile", c: "CH" },
  { id: 23702, s: "ODL.WA", n: "Odlewnie Polskie SA", c: "Poland" },
  { id: 23703, s: "200761.SZ", n: "Bengang Steel Plates", c: "CH" },
  { id: 23704, s: "OTM.WA", n: "Zaklady Przemyslu Cukierniczego Otmuchow S.A.", c: "Poland" },
  { id: 23705, s: "OPN.WA", n: "Oponeo.pl SA", c: "Poland" },
  { id: 23706, s: "OPM.WA", n: "OpTeam S.A.", c: "Poland" },
  { id: 23707, s: "OND.WA", n: "Onde SA", c: "Poland" },
  { id: 23708, s: "OEX.WA", n: "OEX S.A.", c: "Poland" },
  { id: 23709, s: "OBL.WA", n: "Orzeł Biały SA", c: "Poland" },
  { id: 23710, s: "300061.SZ", n: "QITIAN Technology Group", c: "CH" },
  { id: 23711, s: "300004.SZ", n: "Nanfang Ventilator", c: "CH" },
  { id: 23712, s: "300005.SZ", n: "Beijing Toread Outdoor Product", c: "CH" },
  { id: 23713, s: "300031.SZ", n: "Wuxi Boton Technology", c: "CH" },
  { id: 23714, s: "PCX.WA", n: "PCC Exol SA", c: "Poland" },
  { id: 23715, s: "MCR.WA", n: "Mercor S.A.", c: "Poland" },
  { id: 23716, s: "300034.SZ", n: "Beijing Cisri Gaona Materials Tech", c: "CH" },
  { id: 23717, s: "002819.SZ", n: "Beijing Oriental Jicheng", c: "CH" },
  { id: 23718, s: "600839.SS", n: "Sichuan Changhong Electric", c: "CH" },
  { id: 23719, s: "6674.TW", n: "Compal Broadband Networks Inc", c: "TW" },
  { id: 23720, s: "600854.SS", n: "Jiangsu Chunlan Refrigerating Equipment Stock", c: "CH" },
  { id: 23721, s: "002788.SZ", n: "Luyan Pharma", c: "CH" },
  { id: 23722, s: "002776.SZ", n: "Guangdong Bobaolon", c: "CH" },
  { id: 23723, s: "SNDD", n: "RedHawk Holdings Co", c: "US" },
  { id: 23724, s: "SN", n: "SharkNinja Inc.", c: "US" },
  { id: 23725, s: "002750.SZ", n: "Kunming Longjin Pharmaceutical", c: "CH" },
  { id: 23726, s: "601528.SS", n: "Zhejiang Shaoxing RuiFeng Rural Commercial Bank", c: "CH" },
  { id: 23727, s: "002753.SZ", n: "Shanxi Yongdong Chemistry Industry", c: "CH" },
  { id: 23728, s: "002755.SZ", n: "Beijing New Oriental Star Petrochemical Engineering", c: "CH" },
  { id: 23729, s: "002756.SZ", n: "Yongxing Special Stainless Steel", c: "CH" },
  { id: 23730, s: "002757.SZ", n: "Nanxing Furniture Machinery & Equipment", c: "CH" },
  { id: 23731, s: "002758.SZ", n: "ZJAMP Group", c: "CH" },
  { id: 23732, s: "002759.SZ", n: "Guangdong Tonze Electric", c: "CH" },
  { id: 23733, s: "SMFNF", n: "Sumitomo Mitsui Financial", c: "US" },
  { id: 23734, s: "601187.SS", n: "Xiamen Bank", c: "CH" },
  { id: 23735, s: "002766.SZ", n: "Shenzhen Soling Industrial", c: "CH" },
  { id: 23736, s: "600956.SS", n: "CH Suntien Green Energy Co", c: "CH" },
  { id: 23737, s: "002796.SZ", n: "Suzhou Shijia Science & Technology Inc", c: "CH" },
  { id: 23738, s: "002795.SZ", n: "Yorhe Fluid Intelligent Control", c: "CH" },
  { id: 23739, s: "002783.SZ", n: "Hubei Kailong Chemical Group", c: "CH" },
  { id: 23740, s: "002856.SZ", n: "Shenzhen Magic Design & Decoration Engineering", c: "CH" },
  { id: 23741, s: "600548.SS", n: "Shenzhen Expressway", c: "CH" },
  { id: 23742, s: "SNLAY", n: "Sino Land Co ADR", c: "US" },
  { id: 23743, s: "002779.SZ", n: "Zhejiang Zhongjian Technology", c: "CH" },
  { id: 23744, s: "002780.SZ", n: "Beijing Sanfo Outdoor Products", c: "CH" },
  { id: 23745, s: "002782.SZ", n: "Shenzhen Click Technology", c: "CH" },
  { id: 23746, s: "002785.SZ", n: "Xiamen Wanli Stone Stock", c: "CH" },
  { id: 23747, s: "002792.SZ", n: "Tongyu Communication Inc", c: "CH" },
  { id: 23748, s: "002786.SZ", n: "Shenzhen Silver Basis Technology", c: "CH" },
  { id: 23749, s: "601107.SS", n: "Sichuan Expressway", c: "CH" },
  { id: 23750, s: "002787.SZ", n: "Suzhou Hycan Holdings", c: "CH" },
  { id: 23751, s: "002789.SZ", n: "Shenzhen Jianyi Decoration Group", c: "CH" },
  { id: 23752, s: "SNMCY", n: "Suncorp Group ADR", c: "US" },
  { id: 23753, s: "601038.SS", n: "First Tractor", c: "CH" },
  { id: 23754, s: "002767.SZ", n: "Hangzhou Innover Technology", c: "CH" },
  { id: 23755, s: "002768.SZ", n: "Qingdao Gon Technology", c: "CH" },
  { id: 23756, s: "002769.SZ", n: "Shenzhen Prolto Supply Chain Management", c: "CH" },
  { id: 23757, s: "002826.SZ", n: "Tibet Aim Pharm Inc", c: "CH" },
  { id: 23758, s: "002848.SZ", n: "Gospell Digital Technology", c: "CH" },
  { id: 23759, s: "002849.SZ", n: "Zhejiang Viewshine Intelligent Meter", c: "CH" },
  { id: 23760, s: "600610.SS", n: "Shanghai Zhongyida Co A", c: "CH" },
  { id: 23761, s: "002841.SZ", n: "Guangzhou Shiyuan Electronic Technology", c: "CH" },
  { id: 23762, s: "002828.SZ", n: "XinJiang Beiken Energy Engineering Stock", c: "CH" },
  { id: 23763, s: "600817.SS", n: "Yutong Heavy Industries", c: "CH" },
  { id: 23764, s: "SNNF", n: "Seneca Financial Co", c: "US" },
  { id: 23765, s: "600660.SS", n: "Fuyao Glass Industry Group Co Class A", c: "CH" },
  { id: 23766, s: "600886.SS", n: "SDIC Power Holdings", c: "CH" },
  { id: 23767, s: "002807.SZ", n: "Jiangsu Jiangyin Rural Commercial Bank", c: "CH" },
  { id: 23768, s: "002808.SZ", n: "Suzhou Goldengreen Technologies", c: "CH" },
  { id: 23769, s: "002809.SZ", n: "Guangdong Redwall New Materials", c: "CH" },
  { id: 23770, s: "002810.SZ", n: "Shandong Head", c: "CH" },
  { id: 23771, s: "002811.SZ", n: "Shenzhen Asiantime International Construction", c: "CH" },
  { id: 23772, s: "600655.SS", n: "Shanghai Yuyuan Tourist Mart", c: "CH" },
  { id: 23773, s: "600685.SS", n: "CSSC Offshore & Marine Engineering Group", c: "CH" },
  { id: 23774, s: "002771.SZ", n: "Beijing Transtrue Technology Inc", c: "CH" },
  { id: 23775, s: "002830.SZ", n: "Shenzhen Mingdiao Decoration", c: "CH" },
  { id: 23776, s: "002772.SZ", n: "Tianshui Zhongxing Bio-technology", c: "CH" },
  { id: 23777, s: "002773.SZ", n: "Chengdu Kanghong Pharmaceuticals Group", c: "CH" },
  { id: 23778, s: "600906.SS", n: "Caida Securities", c: "CH" },
  { id: 23779, s: "002855.SZ", n: "Dongguan Chitwing Technology", c: "CH" },
  { id: 23780, s: "002800.SZ", n: "Xinjiang Tianshun Supply Chain", c: "CH" },
  { id: 23781, s: "002803.SZ", n: "Xiamen Jihong Package Technology", c: "CH" },
  { id: 23782, s: "600783.SS", n: "Luxin Venture Capital Group", c: "CH" },
  { id: 23783, s: "002840.SZ", n: "Zhejiang Huatong Meat Products", c: "CH" },
  { id: 23784, s: "600779.SS", n: "Sichuan Swellfun", c: "CH" },
  { id: 23785, s: "002833.SZ", n: "Guangzhou KDT Machinery", c: "CH" },
  { id: 23786, s: "600775.SS", n: "Nanjing Panda Electronics", c: "CH" },
  { id: 23787, s: "600766.SS", n: "YanTai Yuancheng Gold", c: "CH" },
  { id: 23788, s: "600715.SS", n: "Cultural Investment Holdings", c: "CH" },
  { id: 23789, s: "002829.SZ", n: "Beijing StarNeto Technology", c: "CH" },
  { id: 23790, s: "300057.SZ", n: "Shantou Wanshun Package Material", c: "CH" },
  { id: 23791, s: "300056.SZ", n: "Xiamen Savings Environmental", c: "CH" },
  { id: 23792, s: "300036.SZ", n: "Beijing SuperMap Software", c: "CH" },
  { id: 23793, s: "LSI.WA", n: "LSI Software S.A.", c: "Poland" },
  { id: 23794, s: "RDN.WA", n: "Redan S.A.", c: "Poland" },
  { id: 23795, s: "003040.SZ", n: "Chutian Dragon", c: "CH" },
  { id: 23796, s: "003041.SZ", n: "Zhejiang Truelove Vogue", c: "CH" },
  { id: 23797, s: "003043.SZ", n: "Suzhou Huaya Intelligence Technology", c: "CH" },
  { id: 23798, s: "RAF.WA", n: "Fabryka Obrabiarek RAFAMET S.A.", c: "Poland" },
  { id: 23799, s: "180101.SZ", n: "Bosera CMSK Industrial Park", c: "CH" },
  { id: 23800, s: "SHD.WA", n: "Soho Development SA", c: "Poland" },
  { id: 23801, s: "BRSD.L", n: "Brandshield Systems PLC", c: "UK" },
  { id: 23802, s: "033830.KQ", n: "Taegu Broadcasting Corporation", c: "Korea" },
  { id: 23803, s: "8155.TWO", n: "Allied Circuit", c: "TW" },
  { id: 23804, s: "6613.TWO", n: "Nova Technology", c: "TW" },
  { id: 23805, s: "SFD1.DE", n: "Softline AG", c: "GER" },
  { id: 23806, s: "MEX.V", n: "Mexican Gold Co", c: "CA" },
  { id: 23807, s: "6612.TWO", n: "Icares Medicus Inc", c: "TW" },
  { id: 23808, s: "6609.TWO", n: "Taiwan Takisawa Technology", c: "TW" },
  { id: 23809, s: "003038.SZ", n: "Anhui Xinbo Aluminum", c: "CH" },
  { id: 23810, s: "003037.SZ", n: "Guangdong Sanhe Pile", c: "CH" },
  { id: 23811, s: "003036.SZ", n: "Zhejiang Taitan", c: "CH" },
  { id: 23812, s: "200011.SZ", n: "Shenzhen Properties & Resources Development Group", c: "CH" },
  { id: 23813, s: "200488.SZ", n: "Shandong Chenming Paper Holdings Class B", c: "CH" },
  { id: 23814, s: "PJP.WA", n: "PJP Makrum S.A.", c: "Poland" },
  { id: 23815, s: "PHR.WA", n: "Pharmena S.A.", c: "Poland" },
  { id: 23816, s: "PRM.WA", n: "Prochem S.A.", c: "Poland" },
  { id: 23817, s: "PXM.WA", n: "Polimex-Mostostal S.A.", c: "Poland" },
  { id: 23818, s: "QRS.WA", n: "Quercus TFI SA", c: "Poland" },
  { id: 23819, s: "SEK.WA", n: "Seko S.A.", c: "Poland" },
  { id: 23820, s: "RFK.WA", n: "RAFAKO SA", c: "Poland" },
  { id: 23821, s: "003025.SZ", n: "Sijin Intelligent Forming Machinery", c: "CH" },
  { id: 23822, s: "RNC.WA", n: "Reino Capital SA", c: "Poland" },
  { id: 23823, s: "003028.SZ", n: "Genbyte Technology Inc", c: "CH" },
  { id: 23824, s: "003031.SZ", n: "Hebei Sinopack Electronic Technolog", c: "CH" },
  { id: 23825, s: "003032.SZ", n: "Jiangsu Chuanzhiboke Education Tech", c: "CH" },
  { id: 23826, s: "003033.SZ", n: "Qingdao Choho Industrial", c: "CH" },
  { id: 23827, s: "6603.TWO", n: "Fu Chun Shin Machinery Manufacture", c: "TW" },
  { id: 23828, s: "6596.TWO", n: "Kuang Hong Arts Management", c: "TW" },
  { id: 23829, s: "ALAUD.PA", n: "Audacia SAS", c: "FR" },
  { id: 23830, s: "SGF.DE", n: "SGT German Private Equity GmbH & Co. KGaA", c: "GER" },
  { id: 23831, s: "ALCBI.PA", n: "Crypto Blockchain Industries SA", c: "FR" },
  { id: 23832, s: "6532.TWO", n: "Calitech", c: "TW" },
  { id: 23833, s: "6538.TWO", n: "Brave C&H Supply", c: "TW" },
  { id: 23834, s: "ALCAP.PA", n: "Capelli SA", c: "FR" },
  { id: 23835, s: "HAM.V", n: "Highwood Asset Management", c: "CA" },
  { id: 23836, s: "RTV.DE", n: "Your Family Entertainment AG", c: "GER" },
  { id: 23837, s: "ALADO.PA", n: "Adomos SA", c: "FR" },
  { id: 23838, s: "6516.TWO", n: "Kingwaytek Technology", c: "TW" },
  { id: 23839, s: "MATE.V", n: "Blockmate Ventures Inc", c: "CA" },
  { id: 23840, s: "8071.TWO", n: "Abico FS", c: "TW" },
  { id: 23841, s: "INEO.V", n: "INEO Tech Co", c: "CA" },
  { id: 23842, s: "8064.TWO", n: "Contrel Technology", c: "TW" },
  { id: 23843, s: "8059.TWO", n: "CastleNet Technology", c: "TW" },
  { id: 23844, s: "8054.TWO", n: "Alcor Micro", c: "TW" },
  { id: 23845, s: "HBK.V", n: "Highbank Resources.", c: "CA" },
  { id: 23846, s: "6509.TWO", n: "Taiwan Hopax Chemsistry MFG", c: "TW" },
  { id: 23847, s: "6578.TWO", n: "Dabomb Protein", c: "TW" },
  { id: 23848, s: "ALBUD.PA", n: "Mint SA", c: "FR" },
  { id: 23849, s: "ALBIZ.PA", n: "Obiz Concept SAS", c: "FR" },
  { id: 23850, s: "HFC.V", n: "Hampton Financial Co", c: "CA" },
  { id: 23851, s: "ALBOA.PA", n: "Boa Concept SA", c: "FR" },
  { id: 23852, s: "6568.TWO", n: "Rafael Microelectronics", c: "TW" },
  { id: 23853, s: "ALBOO.PA", n: "Boostheat", c: "FR" },
  { id: 23854, s: "ALBPK.PA", n: "Broadpeak SA", c: "FR" },
  { id: 23855, s: "SCE.DE", n: "Schweizer Electronic AG", c: "GER" },
  { id: 23856, s: "6640.TWO", n: "Gallant Micro Machining", c: "TW" },
  { id: 23857, s: "6494.TWO", n: "Nyquest Technology", c: "TW" },
  { id: 23858, s: "6496.TWO", n: "Excelsior Biopharma", c: "TW" },
  { id: 23859, s: "MINE.V", n: "Inomin Mines Inc", c: "CA" },
  { id: 23860, s: "6499.TWO", n: "Medeon Biodesign", c: "TW" },
  { id: 23861, s: "ALDMS.PA", n: "Diagnostic Medical Systems SA", c: "FR" },
  { id: 23862, s: "ALDEL.PA", n: "Delfingen", c: "FR" },
  { id: 23863, s: "SWRBY", n: "Swire Pacific ADR", c: "US" },
  { id: 23864, s: "200413.SZ", n: "Tunghsu Optoelectronic Technology", c: "CH" },
  { id: 23865, s: "200058.SZ", n: "Shenzhen SEG", c: "CH" },
  { id: 23866, s: "003007.SZ", n: "Beijing ZznodeTechnologies ", c: "CH" },
  { id: 23867, s: "003000.SZ", n: "Jinzai Food Group", c: "CH" },
  { id: 23868, s: "003001.SZ", n: "Zhongyan Technology ", c: "CH" },
  { id: 23869, s: "003002.SZ", n: "Shanxi Huhua Group ", c: "CH" },
  { id: 23870, s: "003003.SZ", n: "Guangdong Tengen Industrial Group C", c: "CH" },
  { id: 23871, s: "003004.SZ", n: "Beijing Telesound Electronics", c: "CH" },
  { id: 23872, s: "STF.WA", n: "Stalprofil S.A.", c: "Poland" },
  { id: 23873, s: "002999.SZ", n: "Guangdong Tianhe Agricultural Means", c: "CH" },
  { id: 23874, s: "200541.SZ", n: "Foshan Electrical and Lighting", c: "CH" },
  { id: 23875, s: "003008.SZ", n: "Xuchang Ketop Testing Research Ins", c: "CH" },
  { id: 23876, s: "SNW.WA", n: "Sanwil Holding SA", c: "Poland" },
  { id: 23877, s: "003011.SZ", n: "Zhejiang Walrus New Material ", c: "CH" },
  { id: 23878, s: "SKH.WA", n: "Skarbiec Holding S.A.", c: "Poland" },
  { id: 23879, s: "003013.SZ", n: "Guangzhou Metro Design And Research", c: "CH" },
  { id: 23880, s: "003015.SZ", n: "Jiangsu Rijiu Optoelectronics Joint", c: "CH" },
  { id: 23881, s: "200521.SZ", n: "Changhong Meiling Co B", c: "CH" },
  { id: 23882, s: "002967.SZ", n: "Grg Metrology", c: "CH" },
  { id: 23883, s: "003017.SZ", n: "Zhejiang Dayang Biotech Group ", c: "CH" },
  { id: 23884, s: "300048.SZ", n: "Hiconics Drive Tech", c: "CH" },
  { id: 23885, s: "300041.SZ", n: "Hubei Huitian New Materials", c: "CH" },
  { id: 23886, s: "LEN.WA", n: "Lena Lighting S.A.", c: "Poland" },
  { id: 23887, s: "300044.SZ", n: "Shenzhen Sunwin Intelligent", c: "CH" },
  { id: 23888, s: "300045.SZ", n: "Hwa Create Co", c: "CH" },
  { id: 23889, s: "300046.SZ", n: "Hubei Tech Semiconductors", c: "CH" },
  { id: 23890, s: "300047.SZ", n: "Shenzhen Tianyuan Dic Info Tech", c: "CH" },
  { id: 23891, s: "LBW.WA", n: "LUBAWA SA", c: "Poland" },
  { id: 23892, s: "MCI.WA", n: "MCI Management SA", c: "Poland" },
  { id: 23893, s: "300050.SZ", n: "Dingli Communications Co", c: "CH" },
  { id: 23894, s: "LBT.WA", n: "Libet S.A.", c: "Poland" },
  { id: 23895, s: "300052.SZ", n: "Shenzhen Zqgame", c: "CH" },
  { id: 23896, s: "300053.SZ", n: "Zhuhai Orbita Control Eng", c: "CH" },
  { id: 23897, s: "300054.SZ", n: "Hubei Dinglong Chemical", c: "CH" },
  { id: 23898, s: "300055.SZ", n: "Beijing Water Business Doctor", c: "CH" },
  { id: 23899, s: "003016.SZ", n: "Xin Hee ", c: "CH" },
  { id: 23900, s: "SON.WA", n: "Sonel S.A.", c: "Poland" },
  { id: 23901, s: "PRI.WA", n: "Pragma Inkaso S.A.", c: "Poland" },
  { id: 23902, s: "PUR.WA", n: "Pure Biologics Spólka Akcyjna", c: "Poland" },
  { id: 23903, s: "002991.SZ", n: "Gan Yuan Foods", c: "CH" },
  { id: 23904, s: "003018.SZ", n: "Jinfu Technology ", c: "CH" },
  { id: 23905, s: "200530.SZ", n: "Bingshan Refrigeration and Heat Transfer Technologies", c: "CH" },
  { id: 23906, s: "003020.SZ", n: "Hefei Lifeon Pharmaceutical", c: "CH" },
  { id: 23907, s: "003022.SZ", n: "Levima Advanced Materials Co", c: "CH" },
  { id: 23908, s: "200019.SZ", n: "Shenzhen Shenbao Industrial", c: "CH" },
  { id: 23909, s: "PTH.WA", n: "Primetech SA", c: "Poland" },
  { id: 23910, s: "002986.SZ", n: "Hunan Yussen Energy Technology", c: "CH" },
  { id: 23911, s: "200026.SZ", n: "Fiyta Holdings", c: "CH" },
  { id: 23912, s: "PTG.WA", n: "PolTREG S.A.", c: "Poland" },
  { id: 23913, s: "PRT.WA", n: "Protektor S.A.", c: "Poland" },
  { id: 23914, s: "200037.SZ", n: "Shenzhen Nanshan Power", c: "CH" },
  { id: 23915, s: "PWX.WA", n: "Polwax SA", c: "Poland" },
  { id: 23916, s: "200045.SZ", n: "Shenzhen Textile Holdings", c: "CH" },
  { id: 23917, s: "TOW.WA", n: "Tower Investments SA", c: "Poland" },
  { id: 23918, s: "002985.SZ", n: "Beijing Bei Mo Gao Ke Friction Mate", c: "CH" },
  { id: 23919, s: "002997.SZ", n: "Rayhoo Motor Dies ", c: "CH" },
  { id: 23920, s: "002973.SZ", n: "Qiaoyin Environmental Tech", c: "CH" },
  { id: 23921, s: "002996.SZ", n: "Chongqing Shunbo Aluminum ", c: "CH" },
  { id: 23922, s: "002992.SZ", n: "Shenzhen Baoming Technology ", c: "CH" },
  { id: 23923, s: "002969.SZ", n: "Jiamei Food Packaging Chuzhou", c: "CH" },
  { id: 23924, s: "WIK.WA", n: "Wikana SA", c: "Poland" },
  { id: 23925, s: "VVD.WA", n: "Vivid Games S.A", c: "Poland" },
  { id: 23926, s: "VTL.WA", n: "Vistal Gdynia", c: "Poland" },
  { id: 23927, s: "002975.SZ", n: "Zhuhai Bojay Electronics ", c: "CH" },
  { id: 23928, s: "SVLT", n: "Sunvault Energy Inc", c: "US" },
  { id: 23929, s: "002976.SZ", n: "Suzhou Cheersson Precision Metal Fo", c: "CH" },
  { id: 23930, s: "002978.SZ", n: "Sichuan Anning Iron and Titanium", c: "CH" },
  { id: 23931, s: "VGO.WA", n: "Vigo System SA", c: "Poland" },
  { id: 23932, s: "URT.WA", n: "Urteste SA", c: "Poland" },
  { id: 23933, s: "002981.SZ", n: "Risuntek Inc", c: "CH" },
  { id: 23934, s: "002983.SZ", n: "Anhui Coreach Technology ", c: "CH" },
  { id: 23935, s: "4744.TWO", n: "CVC Technologies", c: "TW" },
  { id: 23936, s: "ETE.DE", n: "Ekotechnika AG", c: "GER" },
  { id: 23937, s: "1457.TW", n: "Yi Jinn Industrial", c: "TW" },
  { id: 23938, s: "3051.TW", n: "Optimax Technology Co", c: "TW" },
  { id: 23939, s: "SRT.HA", n: "Sartorius Aktiengesellschaft", c: "GER" },
  { id: 23940, s: "SIX3.MU", n: "Sixt SE", c: "GER" },
  { id: 23941, s: "3518.TW", n: "Paragon Technologies", c: "TW" },
  { id: 23942, s: "4DS.F", n: "Daldrup & Söhne Aktiengesellschaft", c: "GER" },
  { id: 23943, s: "BCMXY", n: "Bank of Communications Co ADR", c: "US" },
  { id: 23944, s: "2INV.F", n: "2INVEST AG NA O.N.", c: "GER" },
  { id: 23945, s: "2HRA.F", n: "H&R GmbH & Co. KGaA", c: "GER" },
  { id: 23946, s: "TKA.MU", n: "thyssenkrupp AG", c: "GER" },
  { id: 23947, s: "STG.MU", n: "STINAG Stuttgart Invest AG", c: "GER" },
  { id: 23948, s: "AAG.F", n: "Aumann AG", c: "GER" },
  { id: 23949, s: "NWO.HA", n: "New Work SE", c: "GER" },
  { id: 23950, s: "3058.TW", n: "Leader Electronics Inc", c: "TW" },
  { id: 23951, s: "XCX.MU", n: "coinIX GmbH & Co. KGaA", c: "GER" },
  { id: 23952, s: "8TRA.HA", n: "Traton SE", c: "GER" },
  { id: 23953, s: "3346.TW", n: "Laster Tech Co", c: "TW" },
  { id: 23954, s: "SBX.MU", n: "SynBiotic SE", c: "GER" },
  { id: 23955, s: "A4Y.F", n: "Accentro Real Estate AG", c: "GER" },
  { id: 23956, s: "3029.TW", n: "Zero One Technology", c: "TW" },
  { id: 23957, s: "3043.TW", n: "Powercom", c: "TW" },
  { id: 23958, s: "VTSC.HA", n: "Vitesco Technologies Group Aktiengesellschaft", c: "GER" },
  { id: 23959, s: "3048.TW", n: "Edom Technology", c: "TW" },
  { id: 23960, s: "3047.TW", n: "Edimax Technology", c: "TW" },
  { id: 23961, s: "VTSC.MU", n: "Vitesco Technologies Group Aktiengesellschaft", c: "GER" },
  { id: 23962, s: "3046.TW", n: "AOPEN Inc", c: "TW" },
  { id: 23963, s: "YOU.HA", n: "ABOUT YOU Holding AG", c: "GER" },
  { id: 23964, s: "123F.F", n: "123fahrschule SE", c: "GER" },
  { id: 23965, s: "3028.TW", n: "Zenitron Co", c: "TW" },
  { id: 23966, s: "27N.F", n: "CANNOVUM AG INH O.N.", c: "GER" },
  { id: 23967, s: "3040.TW", n: "Global View", c: "TW" },
  { id: 23968, s: "3494.TW", n: "HiTi Digital Inc", c: "TW" },
  { id: 23969, s: "TTO.MU", n: "TTL Beteiligungs- und Grundbesitz-AG", c: "GER" },
  { id: 23970, s: "PAG.MU", n: "PREOS Global Office Real Estate & Technology AG", c: "GER" },
  { id: 23971, s: "A6T.F", n: "artec technologies AG", c: "GER" },
  { id: 23972, s: "3338.TW", n: "Taisol Electronics", c: "TW" },
  { id: 23973, s: "3321.TW", n: "Uniflex Technology Inc", c: "TW" },
  { id: 23974, s: "ADN1.HA", n: "Adesso SE", c: "GER" },
  { id: 23975, s: "FPE3.HA", n: "Fuchs Petrolub SE", c: "GER" },
  { id: 23976, s: "3138.TW", n: "Auden Techno", c: "TW" },
  { id: 23977, s: "FPE.HA", n: "Fuchs Petrolub SE", c: "GER" },
  { id: 23978, s: "3437.TW", n: "Advanced Optoelectronic Technology Inc", c: "TW" },
  { id: 23979, s: "3164.TW", n: "GenMont Biotech Inc", c: "TW" },
  { id: 23980, s: "EOAN.HA", n: "E.ON SE", c: "GER" },
  { id: 23981, s: "ECV.HA", n: "Encavis AG", c: "GER" },
  { id: 23982, s: "AAH.F", n: "Ahlers AG", c: "GER" },
  { id: 23983, s: "NB2.HA", n: "Northern Data AG", c: "GER" },
  { id: 23984, s: "AAQ1.F", n: "aap Implantate AG", c: "GER" },
  { id: 23985, s: "3021.TW", n: "Welltend Technology Co", c: "TW" },
  { id: 23986, s: "BEZ.F", n: "Berentzen Gruppe AG", c: "GER" },
  { id: 23987, s: "KUL.MU", n: "Kulmbacher Brauerei Aktien-Gesellschaft", c: "GER" },
  { id: 23988, s: "BHOOY", n: "Boohoo.Com PLC ADR", c: "US" },
  { id: 23989, s: "BHPA", n: "BHPA Inc", c: "US" },
  { id: 23990, s: "WIG1.MU", n: "Sporttotal AG", c: "GER" },
  { id: 23991, s: "HOT.HA", n: "HOCHTIEF Aktiengesellschaft", c: "GER" },
  { id: 23992, s: "3416.TW", n: "WinMate Communication INC", c: "TW" },
  { id: 23993, s: "B4B.HA", n: "Metro AG", c: "GER" },
  { id: 23994, s: "3308.TW", n: "Bestec Power Electronics", c: "TW" },
  { id: 23995, s: "3305.TW", n: "Shenmao Technology Inc", c: "TW" },
  { id: 23996, s: "3296.TW", n: "Powertech Industrial", c: "TW" },
  { id: 23997, s: "WUG3.MU", n: "Westag & Getalit AG vz. (Pref Shares)", c: "GER" },
  { id: 23998, s: "3266.TW", n: "Sunty Development", c: "TW" },
  { id: 23999, s: "3257.TW", n: "Champion Microelectronic Co", c: "TW" },
  { id: 24000, s: "B4B3.HA", n: "Metro AG", c: "GER" },
  { id: 24001, s: "INS.HA", n: "Instone Real Estate Group AG", c: "GER" },
  { id: 24002, s: "3209.TW", n: "Alltek Technology Co", c: "TW" },
  { id: 24003, s: "WUG.MU", n: "Westag & Getalit AG", c: "GER" },
  { id: 24004, s: "MRX.HA", n: "Mister Spex SE", c: "GER" },
  { id: 24005, s: "MRK.HA", n: "Merck & Company Inc", c: "GER" },
  { id: 24006, s: "KBX.HA", n: "Knorr-Bremse Aktiengesellschaft", c: "GER" },
  { id: 24007, s: "3090.TW", n: "Nichidenbo Co", c: "TW" },
  { id: 24008, s: "WEW.MU", n: "Westwing Group AG", c: "GER" },
  { id: 24009, s: "3052.TW", n: "Apex Science & Engineering Co", c: "TW" },
  { id: 24010, s: "BIO3.F", n: "Biotest Aktiengesellschaft", c: "GER" },
  { id: 24011, s: "3054.TW", n: "LIWANLI Innovation", c: "TW" },
  { id: 24012, s: "HHHA.F", n: "HOMES+HOLIDAY AG NA O.N.", c: "GER" },
  { id: 24013, s: "HHFA.F", n: "Hamburger Hafen und Logistik Aktiengesellschaft", c: "GER" },
  { id: 24014, s: "HGEA.F", n: "hGears AG", c: "GER" },
  { id: 24015, s: "GOZ2.F", n: "Gold-Zack AG", c: "GER" },
  { id: 24016, s: "HEN.F", n: "Henkel AG & Co. KGaA", c: "GER" },
  { id: 24017, s: "HDD.F", n: "Heidelberger Druckmaschinen Aktiengesellschaft", c: "GER" },
  { id: 24018, s: "ABHA.MU", n: "HASEN-IMMOBILIEN N", c: "GER" },
  { id: 24019, s: "2420.TW", n: "Zippy Technology Co", c: "TW" },
  { id: 24020, s: "HAW.F", n: "Hawesko Holding AG", c: "GER" },
  { id: 24021, s: "2423.TW", n: "Good Will Instrument", c: "TW" },
  { id: 24022, s: "2424.TW", n: "Lung Hwa Electronics", c: "TW" },
  { id: 24023, s: "2415.TW", n: "CX Technology Co", c: "TW" },
  { id: 24024, s: "CPX.MU", n: "capsensixx AG", c: "GER" },
  { id: 24025, s: "GMM.F", n: "Grammer AG", c: "GER" },
  { id: 24026, s: "AEIN.MU", n: "Allgeier SE", c: "GER" },
  { id: 24027, s: "4746.TW", n: "Formosa Laboratories Inc", c: "TW" },
  { id: 24028, s: "YOU.DU", n: "ABOUT YOU Holding AG", c: "GER" },
  { id: 24029, s: "HIGH.F", n: "CANTOURAGE GROUP SE O.N.", c: "GER" },
  { id: 24030, s: "H9W.F", n: "HWA AG", c: "GER" },
  { id: 24031, s: "ADN1.MU", n: "Adesso SE", c: "GER" },
  { id: 24032, s: "2440.TW", n: "Space Shuttle Hi-Tech", c: "TW" },
  { id: 24033, s: "GWI2.F", n: "GERRY WEBER International AG", c: "GER" },
  { id: 24034, s: "4720.TW", n: "Tex Year Industries Inc", c: "TW" },
  { id: 24035, s: "4722.TW", n: "Qualipoly Chemical Co", c: "TW" },
  { id: 24036, s: "ABK.MU", n: "AKTIENBRAU.KAUFBEUR", c: "GER" },
  { id: 24037, s: "2433.TW", n: "Huxen Co", c: "TW" },
  { id: 24038, s: "2425.TW", n: "Chaintech Technology Co", c: "TW" },
  { id: 24039, s: "H9Y.F", n: "HanseYachts AG", c: "GER" },
  { id: 24040, s: "2430.TW", n: "Tsann Kuen Enterprise", c: "TW" },
  { id: 24041, s: "2429.TW", n: "Abonmax", c: "TW" },
  { id: 24042, s: "HAEK.F", n: "Haemato AG", c: "GER" },
  { id: 24043, s: "2427.TW", n: "Mercuries Data Systems", c: "TW" },
  { id: 24044, s: "2426.TW", n: "Tyntek Co", c: "TW" },
  { id: 24045, s: "4426.TW", n: "Li Cheng Enterprise", c: "TW" },
  { id: 24046, s: "FF24.F", n: "FAST FIN 24 HLDG INH O.N.", c: "GER" },
  { id: 24047, s: "BYW6.MU", n: "BayWa AktienGesellschaft Vink.N", c: "GER" },
  { id: 24048, s: "2462.TW", n: "Taiwan Line Tek Electronic", c: "TW" },
  { id: 24049, s: "G6P.F", n: "Going Public Media Aktiengesellschaft", c: "GER" },
  { id: 24050, s: "4564.TW", n: "Mosa Industrial Co", c: "TW" },
  { id: 24051, s: "FYB.F", n: "Formycon AG", c: "GER" },
  { id: 24052, s: "FSNT.F", n: "Fashionette AG", c: "GER" },
  { id: 24053, s: "2460.TW", n: "Gem Terminal Industry", c: "TW" },
  { id: 24054, s: "2461.TW", n: "K Laser Technology Inc", c: "TW" },
  { id: 24055, s: "2464.TW", n: "Mirle Automation Co", c: "TW" },
  { id: 24056, s: "B4B.MU", n: "Metro AG", c: "GER" },
  { id: 24057, s: "2465.TW", n: "Leadtek Research Inc", c: "TW" },
  { id: 24058, s: "B4B3.MU", n: "Metro AG", c: "GER" },
  { id: 24059, s: "2748.TW", n: "FDC International Hotels Co", c: "TW" },
  { id: 24060, s: "CNW.F", n: "CO.DON AG INH. O.N.", c: "GER" },
  { id: 24061, s: "HRPK.MU", n: "7C Solarparken AG", c: "GER" },
  { id: 24062, s: "3450.TW", n: "Elite Advanced Laser Co", c: "TW" },
  { id: 24063, s: "GAG.F", n: "GORE GERM.OFF.R.E. NA ON", c: "GER" },
  { id: 24064, s: "2453.TW", n: "Syscom Computer Engineering", c: "TW" },
  { id: 24065, s: "4526.TW", n: "Tong-Tai Machine Tool", c: "TW" },
  { id: 24066, s: "FPH.F", n: "Francotyp-Postalia Holding AG", c: "GER" },
  { id: 24067, s: "2484.TW", n: "Siward Crystal Technology", c: "TW" },
  { id: 24068, s: "2483.TW", n: "Excel Cell Electronic", c: "TW" },
  { id: 24069, s: "BYW.MU", n: "BayWa AktienGesellschaft", c: "GER" },
  { id: 24070, s: "BIO.MU", n: "Biotest Aktiengesellschaft", c: "GER" },
  { id: 24071, s: "FPE.F", n: "Fuchs Petrolub SE", c: "GER" },
  { id: 24072, s: "2478.TW", n: "TA-I Technology", c: "TW" },
  { id: 24073, s: "2476.TW", n: "G-Shank Enterprise", c: "TW" },
  { id: 24074, s: "ARL.MU", n: "Aareal Bank AG", c: "GER" },
  { id: 24075, s: "2472.TW", n: "Lelon Electronics Co", c: "TW" },
  { id: 24076, s: "2471.TW", n: "Ares International Co", c: "TW" },
  { id: 24077, s: "FRS.F", n: "FORIS AG", c: "GER" },
  { id: 24078, s: "2467.TW", n: "C Sun Manufacturing", c: "TW" },
  { id: 24079, s: "2466.TW", n: "Cosmo Electronics Co", c: "TW" },
  { id: 24080, s: "2450.TW", n: "Senao International", c: "TW" },
  { id: 24081, s: "BIO.F", n: "Biotest Aktiengesellschaft", c: "GER" },
  { id: 24082, s: "BKEAY", n: "Bank of East Asia Limited", c: "US" },
  { id: 24083, s: "CLZNY", n: "Clariant AG", c: "US" },
  { id: 24084, s: "6164.TW", n: "Ledtech Electronics Co", c: "TW" },
  { id: 24085, s: "1604.TW", n: "Sampo Co", c: "TW" },
  { id: 24086, s: "1587.TW", n: "Cryomax Cooling System Co", c: "TW" },
  { id: 24087, s: "SZU.F", n: "Südzucker AG", c: "GER" },
  { id: 24088, s: "SIS.F", n: "First Sensor AG", c: "GER" },
  { id: 24089, s: "EXJ.DU", n: "Enerxy AG", c: "GER" },
  { id: 24090, s: "6117.TW", n: "In Win Development Inc", c: "TW" },
  { id: 24091, s: "6120.TW", n: "Darwin Precisions Co", c: "TW" },
  { id: 24092, s: "RSL2.F", n: "R. Stahl AG NA O.N.", c: "GER" },
  { id: 24093, s: "RTC.F", n: "RealTech AG", c: "GER" },
  { id: 24094, s: "1786.TW", n: "SciVision Biotech Inc", c: "TW" },
  { id: 24095, s: "1783.TW", n: "Maxigen Biotech Inc", c: "TW" },
  { id: 24096, s: "FPE.DU", n: "Fuchs Petrolub SE", c: "GER" },
  { id: 24097, s: "1776.TW", n: "Headway", c: "TW" },
  { id: 24098, s: "RTML.F", n: "Camerit AG", c: "GER" },
  { id: 24099, s: "1762.TW", n: "Chunghwa Chemical Synthesis & Biotech", c: "TW" },
  { id: 24100, s: "1603.TW", n: "CH Wire & Cable", c: "TW" },
  { id: 24101, s: "DMP.DU", n: "Dermapharm Holding SE", c: "GER" },
  { id: 24102, s: "DFV.DU", n: "DFV Deutsche Familienversicherung AG", c: "GER" },
  { id: 24103, s: "1541.TW", n: "Chang Type Industrial", c: "TW" },
  { id: 24104, s: "1612.TW", n: "Hong Tai Electric Industrial", c: "TW" },
  { id: 24105, s: "DRW8.DU", n: "Drägerwerk AG & Co. KGaA", c: "GER" },
  { id: 24106, s: "6168.TW", n: "Harvatek Co", c: "TW" },
  { id: 24107, s: "1608.TW", n: "Hua Eng Wire & Cable", c: "TW" },
  { id: 24108, s: "STO3.F", n: "Sto SE & Co. KGaA", c: "GER" },
  { id: 24109, s: "6184.TW", n: "Dafeng TV", c: "TW" },
  { id: 24110, s: "1558.TW", n: "Zeng Hsing Industrial", c: "TW" },
  { id: 24111, s: "1598.TW", n: "Dyaco International Inc", c: "TW" },
  { id: 24112, s: "CPX.DU", n: "capsensixx AG", c: "GER" },
  { id: 24113, s: "1568.TW", n: "Tsang Yow Industrial", c: "TW" },
  { id: 24114, s: "SYZ.F", n: "SYZYGY AG", c: "GER" },
  { id: 24115, s: "SIX3.F", n: "Sixt SE", c: "GER" },
  { id: 24116, s: "SYT.F", n: "Softing AG", c: "GER" },
  { id: 24117, s: "JEV.V", n: "Jericho Oil Co", c: "CA" },
  { id: 24118, s: "RTV.F", n: "Your Family Entertainment AG", c: "GER" },
  { id: 24119, s: "RY8.F", n: "Roy Ceramics AG", c: "GER" },
  { id: 24120, s: "1737.TW", n: "Taiyen Biotech", c: "TW" },
  { id: 24121, s: "1468.TW", n: "Chang Ho Fibre Co", c: "TW" },
  { id: 24122, s: "1806.TW", n: "Champion Building Materials", c: "TW" },
  { id: 24123, s: "1539.TW", n: "Chiu Ting Machinery", c: "TW" },
  { id: 24124, s: "1538.TW", n: "Jenn Feng New Energy", c: "TW" },
  { id: 24125, s: "1472.TW", n: "Tri Ocean Textile", c: "TW" },
  { id: 24126, s: "1471.TW", n: "Solytech Enterprise Co", c: "TW" },
  { id: 24127, s: "UCA1.F", n: "U.C.A. AG O.N.", c: "GER" },
  { id: 24128, s: "1467.TW", n: "Tex-Ray Industrial", c: "TW" },
  { id: 24129, s: "EXL.DU", n: "Exasol AG", c: "GER" },
  { id: 24130, s: "1466.TW", n: "Acelon Chemicals & Fiber Co", c: "TW" },
  { id: 24131, s: "1465.TW", n: "Wisher Industrial", c: "TW" },
  { id: 24132, s: "1464.TW", n: "De Licacy Industrial", c: "TW" },
  { id: 24133, s: "1463.TW", n: "Chyang Sheng Dyeing & Finishing", c: "TW" },
  { id: 24134, s: "1460.TW", n: "Everest Textile", c: "TW" },
  { id: 24135, s: "1459.TW", n: "Lan Fa Textile", c: "TW" },
  { id: 24136, s: "SD1.F", n: "CLEAN LOGISTICS SE NA ON", c: "GER" },
  { id: 24137, s: "SB1.F", n: "Smartbroker Holding AG", c: "GER" },
  { id: 24138, s: "S6P.F", n: "Spielvereinigung Unterhaching Fussb", c: "GER" },
  { id: 24139, s: "SGL.F", n: "SGL Carbon SE", c: "GER" },
  { id: 24140, s: "6133.TW", n: "Golden Bridge Electech Inc", c: "TW" },
  { id: 24141, s: "1733.TW", n: "Apex Biotechnology Co", c: "TW" },
  { id: 24142, s: "1732.TW", n: "Mao Bao Inc", c: "TW" },
  { id: 24143, s: "SHF.F", n: "SNP Schneider-Neureither & Partner SE", c: "GER" },
  { id: 24144, s: "1714.TW", n: "Ho Tung Chemical Co", c: "TW" },
  { id: 24145, s: "SGU.F", n: "Sino-German United AG", c: "GER" },
  { id: 24146, s: "1720.TW", n: "Standard Chemical & Pharmaceutical", c: "TW" },
  { id: 24147, s: "1730.TW", n: "Farcent Enterprise", c: "TW" },
  { id: 24148, s: "6141.TW", n: "Plotech", c: "TW" },
  { id: 24149, s: "6152.TW", n: "Prime Electronics & Satellitics Inc", c: "TW" },
  { id: 24150, s: "6139.TW", n: "L&K Engineering", c: "TW" },
  { id: 24151, s: "1725.TW", n: "Yuan Jen Enterprises", c: "TW" },
  { id: 24152, s: "SBX.F", n: "SynBiotic SE", c: "GER" },
  { id: 24153, s: "1727.TW", n: "Chung Hwa Chemical Industrial Works", c: "TW" },
  { id: 24154, s: "6165.TW", n: "Lang Inc", c: "TW" },
  { id: 24155, s: "SPM.F", n: "Splendid Medien AG", c: "GER" },
  { id: 24156, s: "BKHT.F", n: "Brockhaus Capital Management AG", c: "GER" },
  { id: 24157, s: "SPB.F", n: "Sedlmayr Grund und Immobilien AG", c: "GER" },
  { id: 24158, s: "3669.TW", n: "AVer Information Inc", c: "TW" },
  { id: 24159, s: "KBX.MU", n: "Knorr-Bremse Aktiengesellschaft", c: "GER" },
  { id: 24160, s: "KSB.MU", n: "KSB SE & Co. KGaA", c: "GER" },
  { id: 24161, s: "C1V.F", n: "mVISE AG", c: "GER" },
  { id: 24162, s: "MBG.MU", n: "Mercedes-Benz Group AG", c: "GER" },
  { id: 24163, s: "BBHK.F", n: "Deutsche Balaton AG", c: "GER" },
  { id: 24164, s: "3593.TW", n: "Logah Technology Co", c: "TW" },
  { id: 24165, s: "ALG.F", n: "ALBIS Leasing AG", c: "GER" },
  { id: 24166, s: "3550.TW", n: "Copartner Technology Co", c: "TW" },
  { id: 24167, s: "AJ91.F", n: "DocCheck AG", c: "GER" },
  { id: 24168, s: "P911.MU", n: "Porsche AG", c: "GER" },
  { id: 24169, s: "3543.TW", n: "Jochu Technology", c: "TW" },
  { id: 24170, s: "3535.TW", n: "Favite Inc", c: "TW" },
  { id: 24171, s: "NWO.MU", n: "New Work SE", c: "GER" },
  { id: 24172, s: "ADL.F", n: "ADO Properties SA", c: "GER" },
  { id: 24173, s: "3679.TW", n: "Nishoku Technology Inc", c: "TW" },
  { id: 24174, s: "AVOI", n: "Advanced Voice Recognition Systems Inc", c: "US" },
  { id: 24175, s: "BYW6.F", n: "BayWa Aktiengesellschaft", c: "GER" },
  { id: 24176, s: "BST.F", n: "Bastei Lübbe AG", c: "GER" },
  { id: 24177, s: "KUB1.MU", n: "Konsortium AG", c: "GER" },
  { id: 24178, s: "BMW3.F", n: "Bayerische Motoren Werke Aktiengesellschaft", c: "GER" },
  { id: 24179, s: "3622.TW", n: "Young Fast Optoelectronics", c: "TW" },
  { id: 24180, s: "BOG.F", n: "STOCK3 AG NA O.N.", c: "GER" },
  { id: 24181, s: "BPE.F", n: "BP PLC DZ/1 DL-25", c: "GER" },
  { id: 24182, s: "BSS.F", n: "Beta Systems Software AG", c: "GER" },
  { id: 24183, s: "BKLRF", n: "Berkeley Energy", c: "US" },
  { id: 24184, s: "JOX.MU", n: "Jost AG", c: "GER" },
  { id: 24185, s: "CFC.F", n: "UET United Electronic Technology AG", c: "GER" },
  { id: 24186, s: "CEK.F", n: "CeoTronics AG", c: "GER" },
  { id: 24187, s: "2816.TW", n: "Union Insurance", c: "TW" },
  { id: 24188, s: "2832.TW", n: "Taiwan Fire & Marine Insurance", c: "TW" },
  { id: 24189, s: "CE2.F", n: "CropEnergies AG", c: "GER" },
  { id: 24190, s: "BDT.F", n: "Bertrandt Aktiengesellschaft", c: "GER" },
  { id: 24191, s: "ADE.F", n: "Bitcoin Group SE", c: "GER" },
  { id: 24192, s: "ACX.F", n: "bet-at-home.com AG", c: "GER" },
  { id: 24193, s: "3015.TW", n: "FSP Technology Inc", c: "TW" },
  { id: 24194, s: "1709.TW", n: "Formosan Union Chemical Co", c: "TW" },
  { id: 24195, s: "2910.TW", n: "Tonlin Department Store", c: "TW" },
  { id: 24196, s: "APPH.F", n: "APONTIS PHARM. AG INH ON", c: "GER" },
  { id: 24197, s: "2904.TW", n: "Prime Oil Chemical Service Co", c: "TW" },
  { id: 24198, s: "PAT.PA", n: "Patrimoine et Commerce SA", c: "FR" },
  { id: 24199, s: "HP3A.F", n: "RINGMETALL SE NA O.N.", c: "GER" },
  { id: 24200, s: "HVB.F", n: "ÜSTRA Hannoversche Verkehrsbetriebe Aktiengesellschaft", c: "GER" },
  { id: 24201, s: "EOAN.DU", n: "E.ON SE", c: "GER" },
  { id: 24202, s: "ART.F", n: "artnet AG Namens-Aktien o.N.", c: "GER" },
  { id: 24203, s: "SJJ.F", n: "Serviceware SE", c: "GER" },
  { id: 24204, s: "SKB.F", n: "Koenig & Bauer AG", c: "GER" },
  { id: 24205, s: "DTG.DU", n: "Daimler Truck Holding AG", c: "GER" },
  { id: 24206, s: "SMHN.F", n: "SÜSS MicroTec SE", c: "GER" },
  { id: 24207, s: "1618.TW", n: "Hold-Key Electric Wire & Cable", c: "TW" },
  { id: 24208, s: "SNG.F", n: "Singulus Technologies AG", c: "GER" },
  { id: 24209, s: "3563.TW", n: "Machvision Inc", c: "TW" },
  { id: 24210, s: "NBH.MU", n: "Hoftex Group AG", c: "GER" },
  { id: 24211, s: "ACT.F", n: "AlzChem Group AG", c: "GER" },
  { id: 24212, s: "B9B.F", n: "BHB Brauholding Bayern-Mitte AG", c: "GER" },
  { id: 24213, s: "ABX.F", n: "ADV.BLOCKCHAIN AG INH ON", c: "GER" },
  { id: 24214, s: "AB9.F", n: "ABO WIND AG O.N.", c: "GER" },
  { id: 24215, s: "3011.TW", n: "Ji-Haw Industrial", c: "TW" },
  { id: 24216, s: "4764.TW", n: "Double Bond Chemical Ind", c: "TW" },
  { id: 24217, s: "AMA.F", n: "ALTECH ADV.MAT. NA O.N.", c: "GER" },
  { id: 24218, s: "AMMN.F", n: "GROUNDS R.EST. NA O.N.", c: "GER" },
  { id: 24219, s: "BGEM", n: "Blue Gem Enterprise", c: "US" },
  { id: 24220, s: "NB2.MU", n: "Northern Data AG", c: "GER" },
  { id: 24221, s: "B8A.F", n: "BAVARIA Industries Group AG", c: "GER" },
  { id: 24222, s: "MRK.MU", n: "Merck & Company Inc", c: "GER" },
  { id: 24223, s: "2901.TW", n: "Shin Shin", c: "TW" },
  { id: 24224, s: "MRX.MU", n: "Mister Spex SE", c: "GER" },
  { id: 24225, s: "AMI.F", n: "amalphi ag", c: "GER" },
  { id: 24226, s: "MTP.MU", n: "Münchener Tierpark Hellabrunn AG", c: "GER" },
  { id: 24227, s: "GTY.F", n: "Gateway Real Estate AG", c: "GER" },
  { id: 24228, s: "4566.TW", n: "Globaltek Fabrication", c: "TW" },
  { id: 24229, s: "2488.TW", n: "Hanpin Electron", c: "TW" },
  { id: 24230, s: "YOU.MU", n: "ABOUT YOU Holding AG", c: "GER" },
  { id: 24231, s: "5905.TWO", n: "Nan Ren Hu Leisure Amusement", c: "TW" },
  { id: 24232, s: "GSD.V", n: "Devonian Health Group Inc", c: "CA" },
  { id: 24233, s: "NEWO.V", n: "NewOrigin Gold Co", c: "CA" },
  { id: 24234, s: "9926.TW", n: "Shin Hai Gas Co", c: "TW" },
  { id: 24235, s: "5902.TWO", n: "Tait Marketing & Distribution", c: "TW" },
  { id: 24236, s: "5878.TWO", n: "Taiming Assurance Broker", c: "TW" },
  { id: 24237, s: "5523.TWO", n: "Fong Chien Construction", c: "TW" },
  { id: 24238, s: "5529.TWO", n: "MEGA International Development", c: "TW" },
  { id: 24239, s: "ALTXC.PA", n: "Txcom SA", c: "FR" },
  { id: 24240, s: "NGE.V", n: "Nevada Exploration Inc", c: "CA" },
  { id: 24241, s: "5601.TWO", n: "Taiwan Allied Container Terminal", c: "TW" },
  { id: 24242, s: "5603.TWO", n: "Sea & Land Integrated", c: "TW" },
  { id: 24243, s: "6118.TWO", n: "Xander International", c: "TW" },
  { id: 24244, s: "5604.TWO", n: "Chung Lien Transportation", c: "TW" },
  { id: 24245, s: "ALTER.PA", n: "Theradiag SA", c: "FR" },
  { id: 24246, s: "6015.TWO", n: "Horizon Securities", c: "TW" },
  { id: 24247, s: "JZ6.DE", n: "Deutsche Eigenheim Union AG", c: "GER" },
  { id: 24248, s: "NCAU.V", n: "Newcore Gold", c: "CA" },
  { id: 24249, s: "OC.V", n: "Olive Resource Capital Inc", c: "CA" },
  { id: 24250, s: "4162.TWO", n: "PharmaEngine", c: "TW" },
  { id: 24251, s: "4163.TWO", n: "Intai Technology", c: "TW" },
  { id: 24252, s: "4167.TWO", n: "Savior Lifetec", c: "TW" },
  { id: 24253, s: "4138.TWO", n: "Dynamic Medical Technologies", c: "TW" },
  { id: 24254, s: "5016.TWO", n: "Song Ho Industrial", c: "TW" },
  { id: 24255, s: "5201.TWO", n: "K Way Information", c: "TW" },
  { id: 24256, s: "ALPAT.PA", n: "Plant Advanced Technologies SA", c: "FR" },
  { id: 24257, s: "6020.TWO", n: "Tachan Securities", c: "TW" },
  { id: 24258, s: "ALPAU.PA", n: "Paulic Meunerie Sa", c: "FR" },
  { id: 24259, s: "ALPRE.PA", n: "Predilife", c: "FR" },
  { id: 24260, s: "9918.TW", n: "Shin Shin Natural Gas", c: "TW" },
  { id: 24261, s: "6026.TWO", n: "Grand Fortune Securities", c: "TW" },
  { id: 24262, s: "9919.TW", n: "KNH Enterprise", c: "TW" },
  { id: 24263, s: "6021.TWO", n: "Good Finance Securities", c: "TW" },
  { id: 24264, s: "9929.TW", n: "Choice Development Inc", c: "TW" },
  { id: 24265, s: "9928.TW", n: "CH Television", c: "TW" },
  { id: 24266, s: "5704.TWO", n: "Hotel Royal Chihpen", c: "TW" },
  { id: 24267, s: "6126.TWO", n: "Singatron Enterprise", c: "TW" },
  { id: 24268, s: "9906.TW", n: "Hsin Ba Ba Co", c: "TW" },
  { id: 24269, s: "M5S.DE", n: "Marna Beteiligungen AG", c: "GER" },
  { id: 24270, s: "6151.TWO", n: "Ginar Technology", c: "TW" },
  { id: 24271, s: "ALMUN.PA", n: "Munic SA", c: "FR" },
  { id: 24272, s: "6124.TWO", n: "Yeh-Chiang Technology", c: "TW" },
  { id: 24273, s: "ALORA.PA", n: "Altheora SA", c: "FR" },
  { id: 24274, s: "6127.TWO", n: "Leatec Fine Ceramics", c: "TW" },
  { id: 24275, s: "6160.TWO", n: "Cipherlab", c: "TW" },
  { id: 24276, s: "6130.TWO", n: "Singbao International", c: "TW" },
  { id: 24277, s: "9912.TW", n: "Associated Industries CH Inc", c: "TW" },
  { id: 24278, s: "6122.TWO", n: "King Polytechnic Engineering", c: "TW" },
  { id: 24279, s: "ALNXT.PA", n: "Nextedia", c: "FR" },
  { id: 24280, s: "NAR.V", n: "North Arrow Minerals Inc", c: "CA" },
  { id: 24281, s: "ALNSE.PA", n: "NSE SA", c: "FR" },
  { id: 24282, s: "6158.TWO", n: "P-Two Industries", c: "TW" },
  { id: 24283, s: "ALMII.PA", n: "Maison Internationale de lInformatique M2I SAS", c: "FR" },
  { id: 24284, s: "ALSPT.PA", n: "Spartoo SAS", c: "FR" },
  { id: 24285, s: "9902.TW", n: "Tidehold Development", c: "TW" },
  { id: 24286, s: "9927.TW", n: "Thye Ming Industrial", c: "TW" },
  { id: 24287, s: "ALTTI.PA", n: "Travel Technology Interactive", c: "FR" },
  { id: 24288, s: "5520.TWO", n: "Lihtai Construction Enterprise", c: "TW" },
  { id: 24289, s: "ALOSM.PA", n: "Osmozis SAS", c: "FR" },
  { id: 24290, s: "6123.TWO", n: "GrandTech C.G. Systems", c: "TW" },
  { id: 24291, s: "9103.TW", n: "Medtecs International Corporation DRC", c: "TW" },
  { id: 24292, s: "6179.TWO", n: "Ate Energy International", c: "TW" },
  { id: 24293, s: "ALMDT.PA", n: "Mediantechn", c: "FR" },
  { id: 24294, s: "ALMAR.PA", n: "Mare Nostrum", c: "FR" },
  { id: 24295, s: "GSPR.V", n: "GSP Resource Co", c: "CA" },
  { id: 24296, s: "6173.TWO", n: "Prosperity Dielectrics", c: "TW" },
  { id: 24297, s: "6171.TWO", n: "Tacheng Real Estate", c: "TW" },
  { id: 24298, s: "MA10.DE", n: "Binect AG", c: "GER" },
  { id: 24299, s: "ALMDP.PA", n: "Medesis Pharma SA", c: "FR" },
  { id: 24300, s: "ECM.V", n: "Ecolomondo Co", c: "CA" },
  { id: 24301, s: "ASUZU.IS", n: "Anadolu Isuzu Otomotiv Sanayi ve Ticaret AS", c: "Turkey" },
  { id: 24302, s: "2491.TW", n: "Fortune Oriental", c: "TW" },
  { id: 24303, s: "4130.TWO", n: "Genovate Biotechnology", c: "TW" },
  { id: 24304, s: "AVOD.IS", n: "AVOD Kurutulmus Gida ve Tarim Urunleri Sanayi Ticaret AS", c: "Turkey" },
  { id: 24305, s: "PNTR.V", n: "Pantera Silver Co", c: "CA" },
  { id: 24306, s: "MLWEL.PA", n: "Well SA", c: "FR" },
  { id: 24307, s: "AYCES.IS", n: "Altin Yunus Cesme Turistik Tesisler AS", c: "Turkey" },
  { id: 24308, s: "DKL.V", n: "Decklar Resources Inc", c: "CA" },
  { id: 24309, s: "3689.TWO", n: "UD Electronic", c: "TW" },
  { id: 24310, s: "3687.TWO", n: "MacroWell OMG Digital Entertainment", c: "TW" },
  { id: 24311, s: "PRSN.V", n: "Personas Social Inc", c: "CA" },
  { id: 24312, s: "DGO.V", n: "Durango Resources Inc.", c: "CA" },
  { id: 24313, s: "DAC.V", n: "Datable Technology Co", c: "CA" },
  { id: 24314, s: "ORIA.PA", n: "Fiducial Real Estate SA", c: "FR" },
  { id: 24315, s: "PTEC.V", n: "Principal Technologies Inc", c: "CA" },
  { id: 24316, s: "3624.TWO", n: "Viking Tech", c: "TW" },
  { id: 24317, s: "AYES.IS", n: "Ayes Celik Hasir ve Cit Sanayi AS", c: "Turkey" },
  { id: 24318, s: "3625.TWO", n: "C-Tech United", c: "TW" },
  { id: 24319, s: "3709.TWO", n: "Trigold Holdings", c: "TW" },
  { id: 24320, s: "CDZ0.DE", n: "MHP Hotel AG", c: "GER" },
  { id: 24321, s: "3713.TWO", n: "Hsinjing Holding", c: "TW" },
  { id: 24322, s: "ONE.V", n: "01 Communique Laboratory Inc", c: "CA" },
  { id: 24323, s: "4905.TWO", n: "Tainet Communication System", c: "TW" },
  { id: 24324, s: "4907.TWO", n: "Fu Yu Property", c: "TW" },
  { id: 24325, s: "4908.TWO", n: "APAC Opto Electronics", c: "TW" },
  { id: 24326, s: "4909.TWO", n: "New Era Electronics", c: "TW" },
  { id: 24327, s: "4925.TWO", n: "Jmicron Technology", c: "TW" },
  { id: 24328, s: "FSNT.DE", n: "fashionette Ag", c: "GER" },
  { id: 24329, s: "GCC.V", n: "Golden Cariboo Resources", c: "CA" },
  { id: 24330, s: "AVGYO.IS", n: "Avrasya Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 24331, s: "ARASE.IS", n: "Dogu Aras Enerji Yatirimlari AS", c: "Turkey" },
  { id: 24332, s: "PJX.V", n: "PJX Resources Inc", c: "CA" },
  { id: 24333, s: "CEA.DE", n: "FRIWO AG", c: "GER" },
  { id: 24334, s: "MLJSA.PA", n: "JSA Technology SA", c: "FR" },
  { id: 24335, s: "PMX.V", n: "ProAm Explorations Co", c: "CA" },
  { id: 24336, s: "MLSML.PA", n: "Smalto SA", c: "FR" },
  { id: 24337, s: "4111.TWO", n: "Chi Sheng Chemical", c: "TW" },
  { id: 24338, s: "DEAL.V", n: "Playgon Games Inc", c: "CA" },
  { id: 24339, s: "3631.TWO", n: "Chernan Metal Industrial", c: "TW" },
  { id: 24340, s: "JBOG.PA", n: "Jacques Bogart SA", c: "FR" },
  { id: 24341, s: "CYR.DE", n: "Cyan AG", c: "GER" },
  { id: 24342, s: "4192.TWO", n: "SynCore Biotechnology", c: "TW" },
  { id: 24343, s: "INEA.PA", n: "Fonciere Inea", c: "FR" },
  { id: 24344, s: "EGM.V", n: "Engold Mines Inc", c: "CA" },
  { id: 24345, s: "EFV.V", n: "EF EnergyFunders Ventures Inc", c: "CA" },
  { id: 24346, s: "EDG.V", n: "Endurance Gold Co", c: "CA" },
  { id: 24347, s: "DLTA.V", n: "Delta Resources", c: "CA" },
  { id: 24348, s: "ELC.V", n: "Elysee Development Co", c: "CA" },
  { id: 24349, s: "4121.TWO", n: "Rossmax International", c: "TW" },
  { id: 24350, s: "DWS.V", n: "Diamond Estates Wines & Spirits Inc", c: "CA" },
  { id: 24351, s: "ATATP.IS", n: "Atp Ticari Bilgisayar Agi ve Elektrik Guc Kaynaklari Uretim Pazarlama ve Ticaret", c: "Turkey" },
  { id: 24352, s: "ATAGY.IS", n: "Ata Gayrimenkul Yatirim Ortakligi AS", c: "Turkey" },
  { id: 24353, s: "4129.TWO", n: "United Orthopedic", c: "TW" },
  { id: 24354, s: "MHM.PA", n: "Societe Parisienne d’apports En Capital   SPAC SA", c: "FR" },
  { id: 24355, s: "DAM.DE", n: "DATA MODUL Aktiengesellschaft Produktion und Vertrieb von elektronischen Systemen", c: "GER" },
  { id: 24356, s: "4207.TWO", n: "Taiwan Fructose", c: "TW" },
  { id: 24357, s: "3646.TWO", n: "Ant Precision Industry", c: "TW" },
  { id: 24358, s: "ATEKS.IS", n: "Akin Tekstil AS", c: "Turkey" },
  { id: 24359, s: "3663.TWO", n: "ThinTech Materials Technology", c: "TW" },
  { id: 24360, s: "BIO3.DE", n: "Biotest Aktiengesellschaft", c: "GER" },
  { id: 24361, s: "NRG.PA", n: "NRJ Group", c: "FR" },
  { id: 24362, s: "3675.TWO", n: "Eris Technology", c: "TW" },
  { id: 24363, s: "3630.TWO", n: "Newmax Technology", c: "TW" },
  { id: 24364, s: "4430.TWO", n: "Yao I Fabric", c: "TW" },
  { id: 24365, s: "4116.TWO", n: "BenQ Medical Technology", c: "TW" },
  { id: 24366, s: "4303.TWO", n: "Hsinli Chemical Industrial", c: "TW" },
  { id: 24367, s: "4420.TWO", n: "Kwang Ming Silk Mill", c: "TW" },
  { id: 24368, s: "HDF.PA", n: "Hydrogene De FR SA", c: "FR" },
  { id: 24369, s: "4416.TWO", n: "Shanyuan", c: "TW" },
  { id: 24370, s: "4406.TWO", n: "Hsin-Sin Textile", c: "TW" },
  { id: 24371, s: "4401.TWO", n: "Toung LoongTextile Mfg", c: "TW" },
  { id: 24372, s: "PLAN.V", n: "Progressive Planet Solutions Inc", c: "CA" },
  { id: 24373, s: "6144.TWO", n: "Deltamac Taiwan", c: "TW" },
  { id: 24374, s: "ALNMR.PA", n: "NamR SA", c: "FR" },
  { id: 24375, s: "M3V.DE", n: "MeVis Medical Solutions AG", c: "GER" },
  { id: 24376, s: "MVY.V", n: "Moovly Media Inc", c: "CA" },
  { id: 24377, s: "DFV.MU", n: "DFV Deutsche Familienversicherung AG", c: "GER" },
  { id: 24378, s: "2493.TW", n: "Ampoc Far-East", c: "TW" },
  { id: 24379, s: "EXL.F", n: "Exasol AG", c: "GER" },
  { id: 24380, s: "CSQ.MU", n: "creditshelf Aktiengesellschaft", c: "GER" },
  { id: 24381, s: "2497.TW", n: "E-Lead Electronic", c: "TW" },
  { id: 24382, s: "EUX.F", n: "EUWAX Aktiengesellschaft", c: "GER" },
  { id: 24383, s: "ETG.F", n: "EnviTec Biogas AG", c: "GER" },
  { id: 24384, s: "2547.TW", n: "Radium Life Tech", c: "TW" },
  { id: 24385, s: "ETE.F", n: "EKOTECHNIKA AG A O.N.", c: "GER" },
  { id: 24386, s: "EQS.F", n: "EQS Group AG", c: "GER" },
  { id: 24387, s: "KDA.V", n: "KDA Group Inc", c: "CA" },
  { id: 24388, s: "4155.TW", n: "Ok Biotech", c: "TW" },
  { id: 24389, s: "2514.TW", n: "Long Bon International", c: "TW" },
  { id: 24390, s: "EMH.F", n: "pferdewetten.de AG", c: "GER" },
  { id: 24391, s: "2516.TW", n: "New Asia Construction & Development Co", c: "TW" },
  { id: 24392, s: "DMP.MU", n: "Dermapharm Holding SE", c: "GER" },
  { id: 24393, s: "ELB.F", n: "elumeo SE", c: "GER" },
  { id: 24394, s: "DN1.MU", n: "DNI BETEILIGUNGEN", c: "GER" },
  { id: 24395, s: "DTG.MU", n: "Daimler Truck Holding AG", c: "GER" },
  { id: 24396, s: "VEH.F", n: "Valora Effekten Handel AG", c: "GER" },
  { id: 24397, s: "ADN1.DU", n: "Adesso SE", c: "GER" },
  { id: 24398, s: "EBE.F", n: "AIFINYO AG INH O.N.", c: "GER" },
  { id: 24399, s: "ECK.F", n: "LUDWIG BECK am Rathauseck - Textilhaus Feldmeier AG", c: "GER" },
  { id: 24400, s: "2543.TW", n: "Hwang Chang General Contractor", c: "TW" },
  { id: 24401, s: "ECX1.F", n: "EPIGENOMICS AG NA O.N.", c: "GER" },
  { id: 24402, s: "DRW8.MU", n: "Drägerwerk AG & Co. KGaA", c: "GER" },
  { id: 24403, s: "EKT.F", n: "Energiekontor AG", c: "GER" },
  { id: 24404, s: "EIF.F", n: "Eifelhöhen-Klinik AG", c: "GER" },
  { id: 24405, s: "2537.TW", n: "We & Win Development", c: "TW" },
  { id: 24406, s: "2536.TW", n: "Hong Pu Real Estate Development", c: "TW" },
  { id: 24407, s: "BMCS", n: "Biotech Medics Ne", c: "US" },
  { id: 24408, s: "EIS.F", n: "Eisen- und Hüttenwerke AG", c: "GER" },
  { id: 24409, s: "2528.TW", n: "Crowell Development Co", c: "TW" },
  { id: 24410, s: "ERWE.F", n: "ERWE Immobilien AG", c: "GER" },
  { id: 24411, s: "F3C.F", n: "SFC Energy AG", c: "GER" },
  { id: 24412, s: "E8X.F", n: "elexxion AG", c: "GER" },
  { id: 24413, s: "DFTK.F", n: "DF Deutsche Forfait AG", c: "GER" },
  { id: 24414, s: "DTD2.F", n: "B+S Banksysteme Aktiengesellschaft", c: "GER" },
  { id: 24415, s: "DR0.F", n: "Deutsche Rohstoff AG", c: "GER" },
  { id: 24416, s: "DMRE.F", n: "Demire Deutsche Mittelstand Real Estate AG", c: "GER" },
  { id: 24417, s: "DIE.F", n: "Dierig Holding AG", c: "GER" },
  { id: 24418, s: "DFV.F", n: "DFV Deutsche Familienversicherung AG", c: "GER" },
  { id: 24419, s: "E4C.F", n: "ecotel communication ag", c: "GER" },
  { id: 24420, s: "2611.TW", n: "Tze Shin International", c: "TW" },
  { id: 24421, s: "2616.TW", n: "Shan-Loong Transportation", c: "TW" },
  { id: 24422, s: "3712.TW", n: "FIT Holding", c: "TW" },
  { id: 24423, s: "2612.TW", n: "Chinese Maritime Transport", c: "TW" },
  { id: 24424, s: "DEX.F", n: "Delticom AG", c: "GER" },
  { id: 24425, s: "DEQ.F", n: "Deutsche EuroShop AG", c: "GER" },
  { id: 24426, s: "EFS.MU", n: "EFFECTEN-SPIEGEL", c: "GER" },
  { id: 24427, s: "COK.F", n: "Cancom SE", c: "GER" },
  { id: 24428, s: "ECV.MU", n: "Encavis AG", c: "GER" },
  { id: 24429, s: "EOAN.MU", n: "E.ON SE", c: "GER" },
  { id: 24430, s: "2601.TW", n: "First Steamship", c: "TW" },
  { id: 24431, s: "2704.TW", n: "Ambassador Hotel", c: "TW" },
  { id: 24432, s: "HPHA.MU", n: "Heidelberg Pharma AG", c: "GER" },
  { id: 24433, s: "2722.TW", n: "Chateau International Development", c: "TW" },
  { id: 24434, s: "2712.TW", n: "FarGlory Hotel", c: "TW" },
  { id: 24435, s: "HEN.MU", n: "Henkel AG & Co. KGaA", c: "GER" },
  { id: 24436, s: "2706.TW", n: "First Hotel", c: "TW" },
  { id: 24437, s: "CPX.F", n: "capsensixx AG", c: "GER" },
  { id: 24438, s: "CRZK.F", n: "CR Capital AG", c: "GER" },
  { id: 24439, s: "EXL.MU", n: "Exasol AG", c: "GER" },
  { id: 24440, s: "HBD1.MU", n: "Philomaxcap AG", c: "GER" },
  { id: 24441, s: "CYR.F", n: "Cyan AG", c: "GER" },
  { id: 24442, s: "DAR.F", n: "DATRON AG", c: "GER" },
  { id: 24443, s: "3705.TW", n: "YungShin Global Holding Co", c: "TW" },
  { id: 24444, s: "FPE.MU", n: "Fuchs Petrolub SE", c: "GER" },
  { id: 24445, s: "DCIK.F", n: "DCI O.N.", c: "GER" },
  { id: 24446, s: "VEZ.F", n: "Veganz Group AG", c: "GER" },
  { id: 24447, s: "5438.TWO", n: "TECO Image Systems", c: "TW" },
  { id: 24448, s: "9934.TW", n: "Globe Union Industrial Co", c: "TW" },
  { id: 24449, s: "5211.TWO", n: "Penpower Technology", c: "TW" },
  { id: 24450, s: "GENX.V", n: "GenixPharmaceucticals Corporation", c: "CA" },
  { id: 24451, s: "CLPXY", n: "CH Longyuan Power Group Corp ADR", c: "US" },
  { id: 24452, s: "5272.TWO", n: "AMICCOM Electronics", c: "TW" },
  { id: 24453, s: "5206.TWO", n: "Kunyue Development", c: "TW" },
  { id: 24454, s: "GDX.V", n: "Goldex Resources Co", c: "CA" },
  { id: 24455, s: "NZP.V", n: "Chatham Rock Phosphate", c: "CA" },
  { id: 24456, s: "5212.TWO", n: "Hyweb Technology", c: "TW" },
  { id: 24457, s: "5291.TWO", n: "EISO Enterprise", c: "TW" },
  { id: 24458, s: "NZ.V", n: "New Zealand Energy Co", c: "CA" },
  { id: 24459, s: "9943.TW", n: "Holiday Entertainment", c: "TW" },
  { id: 24460, s: "9955.TW", n: "Super Dragon Technology", c: "TW" },
  { id: 24461, s: "5220.TWO", n: "Higgstec", c: "TW" },
  { id: 24462, s: "5230.TWO", n: "Ledlink Optics", c: "TW" },
  { id: 24463, s: "HAEK.DE", n: "Haemato AG", c: "GER" },
  { id: 24464, s: "HHHA.DE", n: "Homes & Holiday AG", c: "GER" },
  { id: 24465, s: "5299.TWO", n: "Excelliance MOS", c: "TW" },
  { id: 24466, s: "5251.TWO", n: "JSW Pacific", c: "TW" },
  { id: 24467, s: "GIT.V", n: "Gitennes Exploration Inc", c: "CA" },
  { id: 24468, s: "ALNTG.PA", n: "Netgem SA", c: "FR" },
  { id: 24469, s: "5516.TWO", n: "Sun-Sea Construction", c: "TW" },
  { id: 24470, s: "9931.TW", n: "Hsin Kao Gas", c: "TW" },
  { id: 24471, s: "5514.TWO", n: "Sunfon Construction", c: "TW" },
  { id: 24472, s: "BALYO.PA", n: "Balyo SA", c: "FR" },
  { id: 24473, s: "5345.TWO", n: "Team Young Advanced Technology", c: "TW" },
  { id: 24474, s: "NPA.V", n: "Alphinat Inc", c: "CA" },
  { id: 24475, s: "5302.TWO", n: "Syntek Semiconductor", c: "TW" },
  { id: 24476, s: "5340.TWO", n: "Baotek Industrial Materials", c: "TW" },
  { id: 24477, s: "5328.TWO", n: "Hua Jung Components", c: "TW" },
  { id: 24478, s: "5321.TWO", n: "United Recommend International", c: "TW" },
  { id: 24479, s: "5315.TWO", n: "United Radiant Technology", c: "TW" },
  { id: 24480, s: "GIII.V", n: "Gen III Oil Co", c: "CA" },
  { id: 24481, s: "GGL.V", n: "GGL Resources Co", c: "CA" },
  { id: 24482, s: "GENI.V", n: "Genius Metals Inc", c: "CA" },
  { id: 24483, s: "5263.TWO", n: "Brogent Technologies", c: "TW" },
  { id: 24484, s: "9935.TW", n: "Ching Feng Home Fashions", c: "TW" },
  { id: 24485, s: "ALWF.PA", n: "Winfarm", c: "FR" },
  { id: 24486, s: "5460.TWO", n: "Mustang Industrial", c: "TW" },
  { id: 24487, s: "ALVG.PA", n: "VEOM Group S.A.", c: "FR" },
  { id: 24488, s: "ARAMI.PA", n: "Aramis SAS", c: "FR" },
  { id: 24489, s: "GPG.V", n: "Grande Portage Resources", c: "CA" },
  { id: 24490, s: "CLHI", n: "CLST Holdings Inc", c: "US" },
  { id: 24491, s: "5410.TWO", n: "Leo Systems", c: "TW" },
  { id: 24492, s: "5426.TWO", n: "Cheng Fwa Industrial", c: "TW" },
  { id: 24493, s: "ALUPG.PA", n: "Upergy", c: "FR" },
  { id: 24494, s: "GR.V", n: "Great Atlantic Resources Co", c: "CA" },
  { id: 24495, s: "5432.TWO", n: "Data International", c: "TW" },
  { id: 24496, s: "NGY.V", n: "Nexera Energy Inc", c: "CA" },
  { id: 24497, s: "ALVIV.PA", n: "Visiativ", c: "FR" },
  { id: 24498, s: "IS8.DE", n: "ifa systems AG", c: "GER" },
  { id: 24499, s: "5450.TWO", n: "Nam Liong Global Co", c: "TW" },
  { id: 24500, s: "2HRA.DU", n: "H&R GmbH & Co. KGaA", c: "GER" },
  { id: 24501, s: "ALU10.PA", n: "U10 SA", c: "FR" },
  { id: 24502, s: "5213.TWO", n: "YeaShin International Development", c: "TW" },
  { id: 24503, s: "ALUVI.PA", n: "UV Germi SA", c: "FR" },
  { id: 24504, s: "5353.TWO", n: "Tailyn Technologies", c: "TW" },
  { id: 24505, s: "HPBK.DE", n: "Heliad Equity Partners GmbH & Co. KGaA", c: "GER" },
  { id: 24506, s: "NNX.V", n: "Nickel North Exploration Co", c: "CA" },
  { id: 24507, s: "ALVAL.PA", n: "Valbiotis SAS", c: "FR" },
  { id: 24508, s: "5474.TWO", n: "Yuan High-Tech Development", c: "TW" },
  { id: 24509, s: "5475.TWO", n: "Glotech Industrial", c: "TW" },
  { id: 24510, s: "5481.TWO", n: "Sino Tactful", c: "TW" },
  { id: 24511, s: "5511.TWO", n: "Te Chang Construction", c: "TW" },
  { id: 24512, s: "ALVAP.PA", n: "Kumulus Vape SA", c: "FR" },
  { id: 24513, s: "5487.TWO", n: "Tontek Design Technology", c: "TW" },
  { id: 24514, s: "5490.TWO", n: "XAC Automation", c: "TW" },
  { id: 24515, s: "ALUNT.PA", n: "Uniti SA", c: "FR" },
  { id: 24516, s: "5498.TWO", n: "Key Ware Electronics", c: "TW" },
  { id: 24517, s: "ALUCI.PA", n: "Lucibel", c: "FR" },
  { id: 24518, s: "GH.TO", n: "Gamehost Inc.", c: "CA" },

  { id: 30000, s: "069660.KO", n: "Kiwoom KOSEF 200 ETF - KR7069660009", c: '"' },
  { id: 30001, s: "091160.KO", n: "Samsung KODEX Semicon ETF - KR7091160002", c: '"' },
  { id: 30002, s: "091170.KO", n: "Samsung KODEX BANKs ETF - KR7091170001", c: '"' },
  { id: 30003, s: "091180.KO", n: "Samsung KODEX AUTOs ETF - KR7091180000", c: '"' },
  { id: 30004, s: "091220.KO", n: "MiraeAsset TIGER BANKS ETF - KR7091220004", c: '"' },
  { id: 30005, s: "091230.KO", n: "MiraeAsset TIGER Semicon ETF - KR7091230003", c: '"' },
  { id: 30006, s: "098560.KO", n: "MiraeAsset TIGER Media&Telecom ETF - KR7098560006", c: '"' },
  { id: 30007, s: "099140.KO", n: "Samsung KODEX China H - KR7099140006", c: '"' },
  { id: 30008, s: "100GBA.SW", n: "UBS(Lux)Fund Solutions – FTSE 100 UCITS ETF(GBP)A-dis CHF - LU0136242590", c: '"' },
  { id: 30009, s: "101280.KO", n: "Samsung KODEX Japan TOPIX100 ETF - KR7101280006", c: '"' },
  { id: 30010, s: "102110.KO", n: "MiraeAsset TIGER 200 ETF - KR7102110004", c: '"' },
  { id: 30011, s: "102960.KO", n: "Samsung KODEX Machinary & Equipment ETF - KR7102960002", c: '"' },
  { id: 30012, s: "102970.KO", n: "Samsung KODEX Securities ETF - KR7102970001", c: '"' },
  { id: 30013, s: "104520.KO", n: "Kiwoom KOSEF Bluechip ETF - KR7104520002", c: '"' },
  { id: 30014, s: "104530.KO", n: "Kiwoom KOSEF High Dividend ETF - KR7104530001", c: '"' },
  { id: 30015, s: "105010.KO", n: "MiraeAsset TIGER Latin ETF - KR7105010003", c: '"' },
  { id: 30016, s: "105190.KO", n: "KIM KINDEX 200 ETF - KR7105190003", c: '"' },
  { id: 30017, s: "105780.KO", n: "KB KBSTAR Top5 Group ETF - KR7105780001", c: '"' },
  { id: 30018, s: "108450.KO", n: "KIM KINDEX Samsung Group SW ETF - KR7108450008", c: '"' },
  { id: 30019, s: "10AB.XETRA", n: "Amundi Index Solutions - Amundi Index MSCI World UCITS ETF DR EUR - LU1737652237", c: '"' },
  { id: 30020, s: "10AH.XETRA", n: "Amundi Index Solutions - Amundi Index MSCI World UCITS ETF DR EUR - LU1737652237", c: '"' },
  { id: 30021, s: "10AJ.XETRA", n: "Amundi Index Solutions - Amundi Index FTSE EPRA NAREIT Global UCITS ETF DR (D)EUR - LU1737652823", c: '"' },
  { id: 30022, s: "10AL.XETRA", n: "Amundi Index Solutions - Amundi Index JP Morgan EMU Govies 1-10 UCITS ETF DR EUR - LU1737653714", c: '"' },
  { id: 30023, s: "10AM.XETRA", n: "Amundi Index Solutions - Amundi Index Global AGG 500M UCITS ETF DR EUR - LU1737654019", c: '"' },
  { id: 30024, s: "114100.KO", n: "KB KBSTAR KTB 3 Year ETF - KR7114100001", c: '"' },
  { id: 30025, s: "114820.KO", n: "MiraeAsset TIGER KTB3 ETF - KR7114820004", c: '"' },
  { id: 30026, s: "117460.KO", n: "Samsung KODEX Energy&Chemicals ETF[EQUITY] - KR7117460006", c: '"' },
  { id: 30027, s: "117700.KO", n: "Samsung KODEX Constructions ETF[Equity] - KR7117700005", c: '"' },
  { id: 30028, s: "122090.KO", n: "Hanwha ARIRANG KOSPI50 ETF(Equity) - KR7122090004", c: '"' },
  { id: 30029, s: "122260.KO", n: "Kiwoom KOSEF MSB ETF[Fixed Income] - KR7122260003", c: '"' },
  { id: 30030, s: "122630.KO", n: "Samsung KODEX Leverage ETF[Equity-Derivatives] - KR7122630007", c: '"' },
  { id: 30031, s: "123320.KO", n: "MiraeAsset TIGER Leverage ETF - KR7123320004", c: '"' },
  { id: 30032, s: "130680.KO", n: "MiraeAsset TIGER WTI Futures ETF - KR7130680002", c: '"' },
  { id: 30033, s: "130730.KO", n: "Kiwoom KOSEF Enhanced Cash ETF[Fixed Income] - KR7130730005", c: '"' },
  { id: 30034, s: "131890.KO", n: "KIM KINDEX Samsung Group Equal Weighted ETF - KR7131890006", c: '"' },
  { id: 30035, s: "133690.KO", n: "MiraeAsset TIGER NASDAQ100 ETF - KR7133690008", c: '"' },
  { id: 30036, s: "137610.KO", n: "MiraeAsset TIGER AGRI Futures ETF - KR7137610002", c: '"' },
  { id: 30037, s: "138520.KO", n: "MiraeAsset TIGER Samsung Group ETF - KR7138520002", c: '"' },
  { id: 30038, s: "138910.KO", n: "Samsung KODEX Copper Futures Special Asset ETF Hedged - KR7138910005", c: '"' },
  { id: 30039, s: "139220.KO", n: "MiraeAsset TIGER 200 Constructions ETF - KR7139220008", c: '"' },
  { id: 30040, s: "139250.KO", n: "MiraeAsset TIGER 200 Energy & Chemicals ETF - KR7139250005", c: '"' },
  { id: 30041, s: "139260.KO", n: "MiraeAsset TIGER 200 IT ETF - KR7139260004", c: '"' },
  { id: 30042, s: "139270.KO", n: "MiraeAsset TIGER 200 Financials ETF - KR7139270003", c: '"' },
  { id: 30043, s: "139280.KO", n: "MiraeAsset TIGER Consumer Staples ETF - KR7139280002", c: '"' },
  { id: 30044, s: "139290.KO", n: "MiraeAsset TIGER 200 Consumer Discretionary ETF - KR7139290001", c: '"' },
  { id: 30045, s: "139310.KO", n: "MiraeAsset TIGER Metal Futures ETF - KR7139310007", c: '"' },
  { id: 30046, s: "139320.KO", n: "MiraeAsset TIGER Precious Metal Futures ETF - KR7139320006", c: '"' },
  { id: 30047, s: "139660.KO", n: "Kiwoom KOSEF USD Inverse Futures ETF - KR7139660005", c: '"' },
  { id: 30048, s: "140570.KO", n: "KB KBSTAR Exporter Securities ETF(Equity) - KR7140570003", c: '"' },
  { id: 30049, s: "140580.KO", n: "KB KBSTAR Prime Industry Securities ETF(Equity) - KR7140580002", c: '"' },
  { id: 30050, s: "140700.KO", n: "Samsung KODEX Insurance ETF[Equity] - KR7140700006", c: '"' },
  { id: 30051, s: "140710.KO", n: "Samsung KODEX Transportation ETF[EQUITY] - KR7140710005", c: '"' },
  { id: 30052, s: "140950.KO", n: "KyoboAXA POWER K100 ETF[Equity] - KR7140950007", c: '"' },
  { id: 30053, s: "143460.KO", n: "KIM KINDEX Value Large ETF - KR7143460004", c: '"' },
  { id: 30054, s: "143850.KO", n: "MiraeAsset TIGER S&P500 Futures ETF - KR7143850006", c: '"' },
  { id: 30055, s: "143860.KO", n: "MiraeAsset TIGER Healthcare ETF - KR7143860005", c: '"' },
  { id: 30056, s: "144600.KO", n: "Samsung KODEX Silver Futures Special Assest ETF Hedged - KR7144600004", c: '"' },
  { id: 30057, s: "145850.KO", n: "Yurie TREX Fundamental 200 ETF - KR7145850004", c: '"' },
  { id: 30058, s: "147970.KO", n: "MiraeAsset TIGER Momentum ETF - KR7147970008", c: '"' },
  { id: 30059, s: "148020.KO", n: "KB KBSTAR 200 Securities ETF(Equity) - KR7148020001", c: '"' },
  { id: 30060, s: "148070.KO", n: "Kiwoom KOSEF 10Y KTB ETF - KR7148070006", c: '"' },
  { id: 30061, s: "150460.KO", n: "MiraeAsset TIGER China Consumer ETF - KR7150460004", c: '"' },
  { id: 30062, s: "152100.KO", n: "Hanwha ARIRANG 200 ETF(Equity) - KR7152100004", c: '"' },
  { id: 30063, s: "152380.KO", n: "Samsung KODEX 10Y F-LKTB ETF - KR7152380002", c: '"' },
  { id: 30064, s: "152500.KO", n: "KIM KINDEX Leverage ETF - KR7152500005", c: '"' },
  { id: 30065, s: "152870.KO", n: "KyoboAXA POWER K200 ETF - KR7152870002", c: '"' },
  { id: 30066, s: "153130.KO", n: "Samsung KODEX KRW Cash ETF - KR7153130000", c: '"' },
  { id: 30067, s: "153270.KO", n: "Kiwoom KOSEF 100 ETF - KR7153270004", c: '"' },
  { id: 30068, s: "156080.KO", n: "Samsung KODEX MSCI Korea ETF[Equity] - KR7156080004", c: '"' },
  { id: 30069, s: "157490.KO", n: "MiraeAsset TIGER Software ETF - KR7157490004", c: '"' },
  { id: 30070, s: "157500.KO", n: "MiraeAsset TIGER Securities ETF - KR7157500000", c: '"' },
  { id: 30071, s: "160580.KO", n: "MiraeAsset TIGER Physical Copper ETF - KR7160580007", c: '"' },
  { id: 30072, s: "161510.KO", n: "Hanwha ARIRANG High Dividend ETF - KR7161510003", c: '"' },
  { id: 30073, s: "166400.KO", n: "MiraeAsset TIGER Covered C200 ETF - KR7166400002", c: '"' },
  { id: 30074, s: "167860.KO", n: "Kiwoom KOSEF 10Y KTB Leverage ETF - KR7167860006", c: '"' },
  { id: 30075, s: "168300.KO", n: "UBSHana KTOP KOSPI50 ETF - KR7168300002", c: '"' },
  { id: 30076, s: "168580.KO", n: "KIM KINDEX China Main Land CSI300 Feeder ETF - KR7168580009", c: '"' },
  { id: 30077, s: "169950.KO", n: "Samsung KODEX FTSE China A50 ETF - KR7169950003", c: '"' },
  { id: 30078, s: "174360.KO", n: "KB KBSTAR China Mainland CSI100 Feeder ETF - KR7174360008", c: '"' },
  { id: 30079, s: "181480.KO", n: "KIM KINDEX Synth-Dow Jones US Real Estate ETF(H) - KR7181480005", c: '"' },
  { id: 30080, s: "182480.KO", n: "MiraeAsset TIGER Synth-MSCI US REIT ETF(H) - KR7182480004", c: '"' },
  { id: 30081, s: "182490.KO", n: "MiraeAsset TIGER Synth-IBOXX Short Term High Yield ETF(H) - KR7182490003", c: '"' },
  { id: 30082, s: "183700.KO", n: "KB KBSTAR Fixed Income Balanced ETF - KR7183700004", c: '"' },
  { id: 30083, s: "183710.KO", n: "KB KBSTAR Equity Balanced ETF - KR7183710003", c: '"' },
  { id: 30084, s: "189400.KO", n: "Hanwha ARIRANG Synth-MSCI AC World ETF(H) - KR7189400005", c: '"' },
  { id: 30085, s: "18M0.F", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 7-10 UCITS ETF - FR0010754184", c: '"' },
  { id: 30086, s: "18M0.XETRA", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 7-10 UCITS ETF - FR0010754184", c: '"' },
  { id: 30087, s: "18M2.F", n: "Amundi ETF MSCI EMU High Dividend UCITS ETF - FR0010717090", c: '"' },
  { id: 30088, s: "18M2.XETRA", n: "Amundi ETF MSCI EMU High Dividend UCITS ETF - FR0010717090", c: '"' },
  { id: 30089, s: "18M3.F", n: "Amundi ETF MSCI Europe Banks UCITS ETF - FR0010688176", c: '"' },
  { id: 30090, s: "18M3.XETRA", n: "Amundi ETF MSCI Europe Banks UCITS ETF - FR0010688176", c: '"' },
  { id: 30091, s: "18M6.F", n: "Amundi ETF MSCI Europe Healthcare UCITS ETF - FR0010688192", c: '"' },
  { id: 30092, s: "18M6.XETRA", n: "Amundi ETF MSCI Europe Healthcare UCITS ETF - FR0010688192", c: '"' },
  { id: 30093, s: "18MG.XETRA", n: "Amundi Index Solutions - Amundi MSCI China - LU1681043912", c: '"' },
  { id: 30094, s: "18MK.F", n: "Amundi Index Solutions - Amundi MSCI India UCITS ETF-C EUR - FR0010713727", c: '"' },
  { id: 30095, s: "18MK.XETRA", n: "Amundi Index Solutions - Amundi MSCI India UCITS ETF-C EUR - LU1681043086", c: '"' },
  { id: 30096, s: "18MM.F", n: "Amundi Funds - Amundi Index MSCI Equity Pacific Ex Japan - FR0010713669", c: '"' },
  { id: 30097, s: "18MM.XETRA", n: "Amundi Index MSCI Pacific Ex Japen SRI PAB - UCITS ETF DR - EUR C - LU1602144906", c: '"' },
  { id: 30098, s: "18MP.XETRA", n: "Amundi ETF MSCI World Ex EMU UCITS ETF - FR0010756114", c: '"' },
  { id: 30099, s: "18MU.F", n: "Amundi ETF Euro Inflation UCITS ETF - FR0010754127", c: '"' },
  { id: 30100, s: "18MU.XETRA", n: "Amundi ETF Euro Inflation UCITS ETF - FR0010754127", c: '"' },
  { id: 30101, s: "18MW.F", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 10-15 UCITS ETF - FR0010754143", c: '"' },
  { id: 30102, s: "18MW.XETRA", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 10-15 UCITS ETF - FR0010754143", c: '"' },
  { id: 30103, s: "18MX.F", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 1-3 UCITS ETF - FR0010754135", c: '"' },
  { id: 30104, s: "18MX.XETRA", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 1-3 UCITS ETF - FR0010754135", c: '"' },
  { id: 30105, s: "18MY.F", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 3-5 UCITS ETF - FR0010754168", c: '"' },
  { id: 30106, s: "18MY.XETRA", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 3-5 UCITS ETF - FR0010754168", c: '"' },
  { id: 30107, s: "18MZ.F", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 5-7 UCITS ETF - FR0010754176", c: '"' },
  { id: 30108, s: "190620.KO", n: "KIM KINDEX Short-Term MSB Money Market ETF - KR7190620005", c: '"' },
  { id: 30109, s: "192090.KO", n: "MiraeAsset TIGER China A300 ETF - KR7192090009", c: '"' },
  { id: 30110, s: "192720.KO", n: "KyoboAXA POWER LVHD ETF - KR7192720001", c: '"' },
  { id: 30111, s: "195930.KO", n: "MiraeAsset TIGER Synth-EURO STOXX 50 ETF(H) - KR7195930003", c: '"' },
  { id: 30112, s: "195970.KO", n: "Hanwha ARIRANG Synth-MSCI EAFE ETF(H) - KR7195970009", c: '"' },
  { id: 30113, s: "195980.KO", n: "Hanwha ARIRANG Synth-MSCI Emerging Markets ETF(H) - KR7195980008", c: '"' },
  { id: 30114, s: "200250.KO", n: "Kiwoom KOSEF Synth-India ETF - KR7200250009", c: '"' },
  { id: 30115, s: "203780.KO", n: "MiraeAsset TIGER NASDAQ BIO ETF - KR7203780002", c: '"' },
  { id: 30116, s: "204450.KO", n: "Samsung KODEX China H Leverage ETF - KR7204450001", c: '"' },
  { id: 30117, s: "210780.KO", n: "MiraeAsset TIGER KOSPI High Dividend ETF - KR7210780003", c: '"' },
  { id: 30118, s: "2B70.XETRA", n: "iShares Nasdaq US Biotechnology UCITS ETF USD (Acc) - IE00BYXG2H39", c: '"' },
  { id: 30119, s: "2B76.XETRA", n: "iShares Automation & Robotics UCITS ETF USD (Acc) - IE00BYZK4552", c: '"' },
  { id: 30120, s: "2B77.XETRA", n: "iShares Ageing Population UCITS Acc - IE00BYZK4669", c: '"' },
  { id: 30121, s: "2B78.XETRA", n: "iShares Healthcare Innovation UCITS ETF USD (Acc) - IE00BYZK4776", c: '"' },
  { id: 30122, s: "2B79.XETRA", n: "iShares Digitalisation UCITS Acc - IE00BYZK4883", c: '"' },
  { id: 30123, s: "2B7A.XETRA", n: "iShares S&P 500 Utilities Sector UCITS ETF USD (Acc) EUR - IE00B4KBBD01", c: '"' },
  { id: 30124, s: "2B7B.XETRA", n: "iShares S&P 500 Materials Sector UCITS ETF USD (Acc) - IE00B4MKCJ84", c: '"' },
  { id: 30125, s: "2B7C.XETRA", n: "iShares S&P 500 Industrials Sector UCITS Acc - IE00B4LN9N13", c: '"' },
  { id: 30126, s: "2B7D.XETRA", n: "iShares S&P 500 Consumer Staples Sector UCITS Acc - IE00B40B8R38", c: '"' },
  { id: 30127, s: "2B7F.XETRA", n: "iShares Automation & Robotics UCITS ETF USD (Dist) - IE00BYWZ0333", c: '"' },
  { id: 30128, s: "2B7J.XETRA", n: "iShares MSCI World SRI UCITS ETF USD (Dist) EUR - IE00BDZZTM54", c: '"' },
  { id: 30129, s: "2B7K.XETRA", n: "iShares MSCI World SRI UCITS ETF EUR (Acc) - IE00BZ173T46", c: '"' },
  { id: 30130, s: "36B1.XETRA", n: "iShares J.P. Morgan ESG $ EM Bond UCITS ETF USD (Dist) - IE00BDDRDW15", c: '"' },
  { id: 30131, s: "36B4.XETRA", n: "iShares MSCI Japan SRI UCITS ETF USD (Dist) - IE00BGDQ0V72", c: '"' },
  { id: 30132, s: "36B5.XETRA", n: "iShares MSCI EM SRI UCITS ETF USD (Dist) - IE00BGDQ0T50", c: '"' },
  { id: 30133, s: "36B6.XETRA", n: "iShares MSCI USA SRI UCITS ETF USD (Dist) - IE00BZ173T46", c: '"' },
  { id: 30134, s: "36B7.XETRA", n: "iShares Global Corp Bond UCITS ETF EUR Hedged (Dist) - IE00BJSFQW37", c: '"' },
  { id: 30135, s: "36BB.XETRA", n: "iShares MSCI World Consumer Discretionary Sector UCITS ETF USD Inc - IE00BJ5JP212", c: '"' },
  { id: 30136, s: "36BD.XETRA", n: "iShares $ Development Bank Bonds UCITS ETF (Acc) - IE00BKRWN659", c: '"' },
  { id: 30137, s: "36BE.XETRA", n: "iShares $ Corp Bond ESG UCITS ETF USD Inc - IE00BK4W7N32", c: '"' },
  { id: 30138, s: "36BZ.F", n: "iShares MSCI China A UCITS USD - IE00BQT3WG13", c: '"' },
  { id: 30139, s: "36BZ.XETRA", n: "iShares MSCI China A UCITS USD - IE00BQT3WG13", c: '"' },
  { id: 30140, s: "3SUD.XETRA", n: "Ishares II PLC - Ishares J.P. Morgan EM Bond UCITS ETF - IE00BJ5JPH63", c: '"' },
  { id: 30141, s: "3SUE.XETRA", n: "iShares MSCI World Consumer Staples Sector UCITS ETF USD (Dist) - IE00BJ5JP329", c: '"' },
  { id: 30142, s: "4UB9.XETRA", n: "UBS (Irl) ETF plc – MSCI World Socially Responsible UCITS ETF (USD) A-dis - IE00BK72HH44", c: '"' },
  { id: 30143, s: "4UBF.XETRA", n: "UBS - Bloomberg Barclays MSCI Euro Area Liquid Corporates Sustainable UCITS - LU1484799843", c: '"' },
  { id: 30144, s: "4UBH.XETRA", n: "UBS (Irl) ETF plc – MSCI World Socially Responsible UCITS ETF (USD) A-acc - IE00BK72HJ67", c: '"' },
  { id: 30145, s: "4UBI.XETRA", n: "UBS (Irl) ETF plc - MSCI USA Socially Responsible UCITS ETF (USD) A-acc - IE00BJXT3C94", c: '"' },
  { id: 30146, s: "4UBK.XETRA", n: "UBS (Irl) ETF plc - MSCI USA Socially Responsible UCITS ETF (USD) A-dis - IE00BJXT3B87", c: '"' },
  { id: 30147, s: "4UBP.XETRA", n: "UBS(Lux)Fund Solutions – Bloomberg MSCI Global Liquid Corp Sust UCITS ETF(USD)A-acc - LU2099991536", c: '"' },
  { id: 30148, s: "4UBQ.XETRA", n: "UBS (Irl) ETF plc - S&P 500 ESG UCITS ETF USD A Acc - IE00BHXMHL11", c: '"' },
  { id: 30149, s: "4UBR.XETRA", n: "UBS(Lux)Fund Solutions – Bloomberg MSCI Global Liquid Corp Sust UCITS ETF(hedged EUR)A-acc - LU2099992260", c: '"' },
  { id: 30150, s: "500.PA", n: "Amundi Index Solutions - Amundi S&P 500 UCITS ETF C EUR - LU1681048804", c: '"' },
  { id: 30151, s: "500P.LSE", n: "Franklin S&P 500 Paris Aligned Climate UCITS ETF GBP - IE00BMDPBZ72", c: '"' },
  { id: 30152, s: "500U.LSE", n: "Amundi Index Solutions - Amundi S&P 500 UCITS ETF C USD - LU1681049018", c: '"' },
  { id: 30153, s: "500U.PA", n: "Amundi Index Solutions - Amundi S&P 500 UCITS ETF C USD - LU1681049018", c: '"' },
  { id: 30154, s: "500X.SW", n: "SPDR S&P 500 ESG Leaders UCITS ETF - IE00BH4GPZ28", c: '"' },
  { id: 30155, s: "50E.PA", n: "HSBC ETFs Public Limited Company - HSBC EURO STOXX 50 UCITS ETF - IE00B4K6B022", c: '"' },
  { id: 30156, s: "540H.F", n: "Amundi ETF MSCI Europe Ex EMU UCITS ETF - FR0010821819", c: '"' },
  { id: 30157, s: "540H.XETRA", n: "Amundi ETF MSCI Europe Ex EMU UCITS ETF - FR0010821819", c: '"' },
  { id: 30158, s: "540K.XETRA", n: "Amundi ETF MSCI UK UCITS ETF - FR0010655761", c: '"' },
  { id: 30159, s: "5ESG.XETRA", n: "Invesco S&P 500 ESG Index ETF - IE00BKS7L097", c: '"' },
  { id: 30160, s: "5HED.XETRA", n: "Ossiam ESG Low Carbon Shiller Barclays CAPE® US Sector UCITS ETF 1A (USD) - IE00BF92LR56", c: '"' },
  { id: 30161, s: "5HEE.XETRA", n: "Ossiam ESG Low Carbon Shiller Barclays CAPE® US Sector UCITS ETF 1A (EUR) - IE00BF92LV92", c: '"' },
  { id: 30162, s: "5HEP.LSE", n: "Ossiam ESG Low Carbon Shiller Barclays CAPE® US Sector UCITS ETF 1A (USD) GBP - IE00BF92LR56", c: '"' },
  { id: 30163, s: "5MVL.XETRA", n: "iShares Edge MSCI EM Value Factor UCITS ETF USD (Acc) - IE00BG0SKF03", c: '"' },
  { id: 30164, s: "5MVW.XETRA", n: "iShares MSCI World Energy Sector UCITS ETF USD Inc - IE00BJ5JP105", c: '"' },
  { id: 30165, s: "5UOA.XETRA", n: "iShares $ Corp Bond ESG UCITS ETF USD Acc - IE00BKKKWJ26", c: '"' },
  { id: 30166, s: "5XYE.XETRA", n: "HAN-GINS Cloud Technology Equal Weight UCITS ETF - Accumulating - IE00BDDRF924", c: '"' },
  { id: 30167, s: "6AQQ.XETRA", n: "Amundi Index Solutions - Amundi Nasdaq-100 ETF-C EUR - LU1681038243", c: '"' },
  { id: 30168, s: "6PSA.F", n: "Invesco Markets III plc - Invesco FTSE RAFI US 1000 UCITS ETF - IE00B23D8S39", c: '"' },
  { id: 30169, s: "6PSA.XETRA", n: "Invesco Markets III plc - Invesco FTSE RAFI US 1000 UCITS ETF - IE00B23D8S39", c: '"' },
  { id: 30170, s: "6PSC.F", n: "Invesco Markets III plc - Invesco FTSE RAFI Europe UCITS ETF - IE00B23D8X81", c: '"' },
  { id: 30171, s: "6PSC.XETRA", n: "Invesco Markets III plc - Invesco FTSE RAFI Europe UCITS ETF - IE00B23D8X81", c: '"' },
  { id: 30172, s: "6PSK.F", n: "Invesco FTSE RAFI Emerging Markets UCITS ETF - IE00B23D9570", c: '"' },
  { id: 30173, s: "6PSK.XETRA", n: "Invesco FTSE RAFI Emerging Markets UCITS ETF - IE00B23D9570", c: '"' },
  { id: 30174, s: "A200.AU", n: "BetaShares Australia 200 - AU00000A2000", c: '"' },
  { id: 30175, s: "A4H8.XETRA", n: "Amundi Index Solutions - Amundi Index Euro Corporate SRI UCITS ETF DR - LU1437018168", c: '"' },
  { id: 30176, s: "AAA.US", n: "Listed Funds Trust - AAF First Priority CLO Bond ETF - US53656F6566", c: '"' },
  { id: 30177, s: "AADR.US", n: "AdvisorShares Dorsey Wright ADR ETF - US00768Y2063", c: '"' },
  { id: 30178, s: "AASU.LSE", n: "Amundi Index Solutions - Amundi MSCI Em Asia UCITS ETF-C USD - LU1681044563", c: '"' },
  { id: 30179, s: "AASU.PA", n: "Amundi Index Solutions - Amundi MSCI Em Asia UCITS ETF-C USD - LU1681044563", c: '"' },
  { id: 30180, s: "AAXJ.US", n: "iShares MSCI All Country Asia ex Japan ETF - US4642881829", c: '"' },
  { id: 30181, s: "ABEQ.US", n: "Absolute Core Strategy ETF - US90470L5681", c: '"' },
  { id: 30182, s: "ACIO.US", n: "Aptus Collared Income Opportunity ETF - US26922A2226", c: '"' },
  { id: 30183, s: "ACSG.US", n: "Xtrackers MSCI ACWI ex USA ESG Leaders Equity ETF - US2330511922", c: '"' },
  { id: 30184, s: "ACSI.US", n: "American Customer Satisfaction ETF - US8863647108", c: '"' },
  { id: 30185, s: "ACTV.US", n: "LeaderShares® Activist Leaders® ETF - US90214Q7170", c: '"' },
  { id: 30186, s: "ACU7.XETRA", n: "Amundi Index Solutions - Amundi MSCI Europe SRI PAB UCITS ETF DR D - LU2059756598", c: '"' },
  { id: 30187, s: "ACUG.XETRA", n: "Amundi Index Solutions - Amundi Index MSCI Emerging Markets SRI PAB UCITS ETF DR D - LU2059756754", c: '"' },
  { id: 30188, s: "ACVF.US", n: "ETF Opportunities Trust - American Conservative Values ETF - US26923N1081", c: '"' },
  { id: 30189, s: "ACWD.LSE", n: "SSgA SPDR MSCI ACWI - IE00B44Z5B48", c: '"' },
  { id: 30190, s: "ACWE.PA", n: "SSgA SPDR MSCI ACWI - IE00B44Z5B48", c: '"' },
  { id: 30191, s: "ACWF.US", n: "iShares MSCI Global Multifactor ETF - US46434V3160", c: '"' },
  { id: 30192, s: "ACWI.LSE", n: "SSgA SPDR ETFs Europe I Public Limited Company - SPDR MSCI ACWI UCITS ETF - IE00B44Z5B48", c: '"' },
  { id: 30193, s: "ACWI.PA", n: "Multi Units Luxembourg - Lyxor MSCI All Country World UCITS ETF - LU1829220216", c: '"' },
  { id: 30194, s: "ACWI.US", n: "iShares MSCI ACWI ETF - US4642882579", c: '"' },
  { id: 30195, s: "ACWL.LSE", n: "Multi Units Luxembourg - Lyxor MSCI All Country World UCITS ETF - LU1829220133", c: '"' },
  { id: 30196, s: "ACWU.LSE", n: "Lyxor MSCI All Country World UCITS C-USD - LU1829220133", c: '"' },
  { id: 30197, s: "ACWV.US", n: "iShares MSCI Global Min Vol Factor ETF - US4642865251", c: '"' },
  { id: 30198, s: "ACWX.US", n: "iShares MSCI ACWI ex U.S. ETF - US4642882405", c: '"' },
  { id: 30199, s: "ACZ.TO", n: "Middlefield US Equity Dividend ETF - CA5962971019", c: '"' },
  { id: 30200, s: "ADIV.US", n: "SmartETFs Asia Pacific Dividend Builder ETF - US4020318436", c: '"' },
  { id: 30201, s: "ADME.US", n: "Aptus Drawdown Managed Equity ETF - US26922A7845", c: '"' },
  { id: 30202, s: "AE50.F", n: "Amundi ETF STOXX Europe 50 UCITS ETF - FR0010790980", c: '"' },
  { id: 30203, s: "AE50.XETRA", n: "Amundi Stoxx Europe 50 UCITS - FR0010790980", c: '"' },
  { id: 30204, s: "AEJ.PA", n: "Lyxor UCITS MSCI AC Asia-Pacific Ex Japan C-E - LU1900068328", c: '"' },
  { id: 30205, s: "AEJL.LSE", n: "Lyxor UCITS MSCI AC Asia-Pacific Ex Japan C-E - LU1900068328", c: '"' },
  { id: 30206, s: "AEMD.LSE", n: "Amundi Index Solutions - Amundi Index MSCI Emerging Markets UCITS ETF DR EUR GBP - LU1737652583", c: '"' },
  { id: 30207, s: "AEMD.XETRA", n: "Amundi Index Solutions - Amundi Index MSCI Emerging Markets UCITS ETF DR EUR - LU1737652583", c: '"' },
  { id: 30208, s: "AEME.LSE", n: "Amundi Index Solutions - Amundi Index MSCI Emerging Markets UCITS ETF DR USD - LU1437017350", c: '"' },
  { id: 30209, s: "AESR.US", n: "Anfield U.S. Equity Sector Rotation ETF - US90214Q7337", c: '"' },
  { id: 30210, s: "AFIF.US", n: "Anfield Universal Fixed Income ETF - US90214Q7667", c: '"' },
  { id: 30211, s: "AFK.US", n: "VanEck Africa Index ETF - US92189F8665", c: '"' },
  { id: 30212, s: "AFLG.US", n: "First Trust Active Factor Large Cap ETF - US33740F8216", c: '"' },
  { id: 30213, s: "AFLT.SW", n: "Amundi Index Solutions - Amundi Floating Rate USD Corporate ESG - UCITS ETF DR - USD (C) USD - LU1681040900", c: '"' },
  { id: 30214, s: "AFMC.US", n: "First Trust Active Factor Mid Cap ETF - US33740F8133", c: '"' },
  { id: 30215, s: "AFRN.PA", n: "Amundi Index Solutions - Amundi Floating Rate EURO Corporate ESG - UCITS ETF DR - EUR (C) - LU1681041114", c: '"' },
  { id: 30216, s: "AFSM.US", n: "First Trust Active Factor Small Cap ETF - US33740F7978", c: '"' },
  { id: 30217, s: "AFTY.US", n: "Pacer CSOP FTSE China A50 ETF - US69374H6264", c: '"' },
  { id: 30218, s: "AGBP.LSE", n: "iShares III Public Limited Company - iShares Global Aggregate Bond UCITS ETF - IE00BF540Y54", c: '"' },
  { id: 30219, s: "AGEB.PA", n: "Amundi Index Solutions - Amundi Global Emerging Bond Markit IBOXX UCITS ETF DR - C - LU1681041205", c: '"' },
  { id: 30220, s: "AGED.LSE", n: "iShares Ageing Population UCITS Acc - IE00BYZK4669", c: '"' },
  { id: 30221, s: "AGES.LSE", n: "iShares IV Public Limited Company - iShares Ageing Population UCITS ETF - IE00BYZK4669", c: '"' },
  { id: 30222, s: "AGG.US", n: "iShares Core U.S. Aggregate Bond ETF - US4642872265", c: '"' },
  { id: 30223, s: "AGGG.LSE", n: "iShares Global Aggregate Bond UCITS Dist - IE00B3F81409", c: '"' },
  { id: 30224, s: "AGGG.SW", n: "iShares Core Global Aggregate Bond UCITS ETF USD (Dist) USD - IE00B3F81409", c: '"' },
  { id: 30225, s: "AGGH.SW", n: "iShares Core Global Aggregate Bond UCITS ETF EUR Hedged (Acc) EUR - IE00BDBRDM35", c: '"' },
  { id: 30226, s: "AGGS.SW", n: "iShares Core Global Aggregate Bond UCITS ETF CHF Hedged(Acc) - IE00BD1JRY91", c: '"' },
  { id: 30227, s: "AGGU.LSE", n: "iShares Global Aggregate Bond UCITS Hedged Acc - IE00BZ043R46", c: '"' },
  { id: 30228, s: "AGGU.SW", n: "iShares Core Global Aggregate Bond UCITS ETF USD Hedged (Acc) USD - IE00BZ043R46", c: '"' },
  { id: 30229, s: "AGGY.US", n: "WisdomTree Yield Enhanced U.S. Aggregate Bond Fund - US97717X5115", c: '"' },
  { id: 30230, s: "AGNG.US", n: "Global X Aging Population ETF - US37954Y7720", c: '"' },
  { id: 30231, s: "AGOX.US", n: "Adaptive Alpha Opportunities ETF - US85521B7423", c: '"' },
  { id: 30232, s: "AGQ.US", n: "ProShares Ultra Silver - US74347W3530", c: '"' },
  { id: 30233, s: "AGZ.US", n: "iShares Agency Bond ETF - US4642881662", c: '"' },
  { id: 30234, s: "AGZD.US", n: "WisdomTree Interest Rate Hedged U.S. Aggregate Bond Fund - US97717W3806", c: '"' },
  { id: 30235, s: "AH50.F", n: "Xtrackers - Harvest FTSE China A-H 50 UCITS ETF - LU1310477036", c: '"' },
  { id: 30236, s: "AH50.LSE", n: "Xtrackers Harvest FTSE China A-H 50 UCITS ETF 1D - LU1310477036", c: '"' },
  { id: 30237, s: "AH50.XETRA", n: "Xtrackers Harvest FTSE China A-H 50 UCITS ETF 1D - LU1310477036", c: '"' },
  { id: 30238, s: "AHYE.F", n: "Amundi Index Solutions - Amundi Euro High Yield Liquid Bond iBoxx - FR0011494822", c: '"' },
  { id: 30239, s: "AHYE.PA", n: "Amundi Index Solutions - Amundi EURO High Yield Bond ESG - UCITS ETF DR - EUR (C) - LU1681040496", c: '"' },
  { id: 30240, s: "AHYE.XETRA", n: "Amundi Index Solutions - Amundi EURO High Yield Liquid Bond IBOXX UCITS ETF-C - LU1681040496", c: '"' },
  { id: 30241, s: "AIA.US", n: "iShares Asia 50 ETF - US4642884302", c: '"' },
  { id: 30242, s: "AIAG.LSE", n: "L&G Artificial Intelligence UCITS ETF - IE00BK5BCD43", c: '"' },
  { id: 30243, s: "AIAI.LSE", n: "L&G Artificial Intelligence UCITS ETF - IE00BK5BCD43", c: '"' },
  { id: 30244, s: "AIAI.SW", n: "L&G Artificial Intelligence UCITS ETF CHF - IE00BK5BCD43", c: '"' },
  { id: 30245, s: "AIEQ.US", n: "AI Powered Equity ETF - US26924G8134", c: '"' },
  { id: 30246, s: "AIQ.US", n: "Global X Artificial Intelligence & Technology ETF - US37954Y6326", c: '"' },
  { id: 30247, s: "AIRR.US", n: "First Trust RBA American Industrial RenaissanceTM ETF - US33738R7044", c: '"' },
  { id: 30248, s: "AIVI.US", n: "WisdomTree International Al Enhanced Value Fund - US97717W7864", c: '"' },
  { id: 30249, s: "AIVL.US", n: "WisdomTree Trust - WisdomTree U.S. AI Enhanced Value Fund - US97717W4069", c: '"' },
  { id: 30250, s: "ALAT.PA", n: "Amundi Index Solutions - Amundi MSCI Em Latin America UCITS ETF-C EUR - LU1681045024", c: '"' },
  { id: 30251, s: "ALAU.LSE", n: "Amundi Index Solutions - Amundi MSCI Em Latin America - LU1681045297", c: '"' },
  { id: 30252, s: "ALAU.PA", n: "Amundi Index Solutions - Amundi MSCI Em Latin America - LU1681045297", c: '"' },
  { id: 30253, s: "ALTL.US", n: "Pacer Lunt Large Cap Alternator ETF - US69374H7171", c: '"' },
  { id: 30254, s: "ALTY.US", n: "Global X Alternative Income ETF - US37954Y8066", c: '"' },
  { id: 30255, s: "AM3A.PA", n: "Amundi Index Solutions - Amundi Govt Bond Highest Rated Euro Investment Grade Ucits ETF EUR C - LU1681046691", c: '"' },
  { id: 30256, s: "AMAL.LSE", n: "Saturna Al-Kawthar Global Focused Equity UCITS ETF - IE00BMYMHS24", c: '"' },
  { id: 30257, s: "AMAP.LSE", n: "Saturna Al-Kawthar Global Focused Equity UCITS ETF GBP - IE00BMYMHS24", c: '"' },
  { id: 30258, s: "AME6.XETRA", n: "Amundi Index Solutions - AMUNDI STOXX EUROPE 600 ESG - UCITS ETF DR - EUR (C) - LU1681040223", c: '"' },
  { id: 30259, s: "AMEA.XETRA", n: "Amundi Index Solutions - Amundi MSCI Em Asia UCITS ETF-C EUR - LU1681044480", c: '"' },
  { id: 30260, s: "AMEC.XETRA", n: "Amundi Index Solutions - Amundi Smart City UCITS ETF-C EUR - LU2037748345", c: '"' },
  { id: 30261, s: "AMED.XETRA", n: "Amundi Index Solutions - Amundi ETF MSCI EMU ESG Leaders Select - LU1602144575", c: '"' },
  { id: 30262, s: "AMEE.F", n: "Amundi ETF MSCI Europe Energy UCITS ETF - FR0010930644", c: '"' },
  { id: 30263, s: "AMEE.XETRA", n: "Amundi ETF MSCI Europe Energy UCITS ETF - FR0010930644", c: '"' },
  { id: 30264, s: "AMEI.XETRA", n: "Amundi Index MSCI Emerging Markets SRI UCITS - LU1861138961", c: '"' },
  { id: 30265, s: "AMEL.XETRA", n: "Amundi Index Solutions - Amundi MSCI Em Latin America UCITS ETF-C EUR - LU1681045024", c: '"' },
  { id: 30266, s: "AMEM.F", n: "Amundi Index Solutions - Amundi MSCI Emerging Markets - LU1681045370", c: '"' },
  { id: 30267, s: "AMEM.XETRA", n: "Amundi MSCI Emerging Markets UCITS - LU1681045370", c: '"' },
  { id: 30268, s: "AMEQ.XETRA", n: "Amundi MSCI Europe Quality Factor UCITS - LU1681041890", c: '"' },
  { id: 30269, s: "AMEW.XETRA", n: "Amundi Index Solutions - Amundi MSCI World UCITS ETF C EUR - LU1681043599", c: '"' },
  { id: 30270, s: "AMJ.US", n: "JPMorgan Alerian MLP Index ETN - US46625H3654", c: '"' },
  { id: 30271, s: "AMLP.US", n: "Alerian MLP ETF - US00162Q4525", c: '"' },
  { id: 30272, s: "AMOM.US", n: "QRAFT AI-Enhanced U.S. Large Cap Momentum ETF - US30151E7803", c: '"' },
  { id: 30273, s: "AMZA.US", n: "InfraCap MLP ETF - US26923G7723", c: '"' },
  { id: 30274, s: "ANEW.US", n: "ProShares MSCI Transformational Changes ETF - US74347G7960", c: '"' },
  { id: 30275, s: "ANGL.US", n: "VanEck Fallen Angel High Yield Bond ETF - US92189F4375", c: '"' },
  { id: 30276, s: "ANRJ.LSE", n: "Amundi ETF MSCI Europe Energy UCITS ETF - FR0010930644", c: '"' },
  { id: 30277, s: "ANRJ.PA", n: "Amundi ETF MSCI Europe Energy UCITS ETF - FR0010930644", c: '"' },
  { id: 30278, s: "ANX.PA", n: "Amundi Index Solutions - Amundi Nasdaq-100 ETF-C EUR - LU1681038243", c: '"' },
  { id: 30279, s: "ANXU.LSE", n: "Amundi Nasdaq-100 UCITS USD - LU1681038326", c: '"' },
  { id: 30280, s: "AOA.US", n: "iShares Core Aggressive Allocation ETF - US4642898591", c: '"' },
  { id: 30281, s: "AOK.US", n: "iShares Core Conservative Allocation ETF - US4642898831", c: '"' },
  { id: 30282, s: "AOM.US", n: "iShares Core Moderate Allocation ETF - US4642898757", c: '"' },
  { id: 30283, s: "AOR.US", n: "iShares Core Growth Allocation ETF - US4642898674", c: '"' },
  { id: 30284, s: "APX.PA", n: "Lyxor UCITS MSCI Asia Ex Japan C-EUR - LU1900068161", c: '"' },
  { id: 30285, s: "ARB.US", n: "AltShares Trust - AltShares Merger Arbitrage ETF - US02210T1088", c: '"' },
  { id: 30286, s: "ARCM.US", n: "Arrow Reserve Capital Management ETF - US0427657194", c: '"' },
  { id: 30287, s: "ARGT.US", n: "Global X MSCI Argentina ETF - US37950E2596", c: '"' },
  { id: 30288, s: "ARKF.US", n: "ARK Fintech Innovation ETF - US00214Q7088", c: '"' },
  { id: 30289, s: "ARKG.US", n: "ARK Genomic Revolution ETF - US00214Q3020", c: '"' },
  { id: 30290, s: "ARKK.US", n: "ARK Innovation ETF - US00214Q1040", c: '"' },
  { id: 30291, s: "ARKQ.US", n: "ARK Autonomous Technology & Robotics ETF - US00214Q2030", c: '"' },
  { id: 30292, s: "ARKW.US", n: "ARK Next Generation Internet ETF - US00214Q4010", c: '"' },
  { id: 30293, s: "ARMR.US", n: "Armor US Equity Index ETF - US30151E7647", c: '"' },
  { id: 30294, s: "ASDV.LSE", n: "SPDR S&P Pan Asia Dividend Aristocrats UCITS - IE00B9KNR336", c: '"' },
  { id: 30295, s: "ASEA.US", n: "Global X FTSE Southeast Asia ETF - US37950E6480", c: '"' },
  { id: 30296, s: "ASET.US", n: "FlexShares Real Assets Allocation Index Fund - US33939L7385", c: '"' },
  { id: 30297, s: "ASHR.LSE", n: "Xtrackers Harvest CSI300 UCITS ETF 1D - LU0875160326", c: '"' },
  { id: 30298, s: "ASHR.US", n: "Xtrackers Harvest CSI 300 China A-Shares ETF - US2330518794", c: '"' },
  { id: 30299, s: "ASHS.US", n: "Xtrackers Harvest CSI 500 China-A Shares Small Cap ETF - US2330517549", c: '"' },
  { id: 30300, s: "ASHX.US", n: "Xtrackers MSCI China A Inclusion Equity ETF - US2330515238", c: '"' },
  { id: 30301, s: "ASI.PA", n: "Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF - Acc - LU1900068914", c: '"' },
  { id: 30302, s: "ASIL.LSE", n: "Multi Units Luxembourg - Lyxor China Enterprise (HSCEI) UCITS ETF - LU1900068914", c: '"' },
  { id: 30303, s: "ASIU.LSE", n: "Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF - Acc - LU1900068914", c: '"' },
  { id: 30304, s: "ASR3.XETRA", n: "BNP Paribas Easy € Corp Bond SRI Fossil Free 1-3Y UCITS ETF Inc - LU2008760592", c: '"' },
  { id: 30305, s: "ASR5.XETRA", n: "BNP Paribas Easy € Corp Bond SRI Fossil Free 3-5Y UCITS ETF - LU2008761053", c: '"' },
  { id: 30306, s: "ASRI.XETRA", n: "BNP Paribas Easy &#226;&#130;&#172; Corp Bond SRI Fossil Free UCITS ETF Acc - LU1859444769", c: '"' },
  { id: 30307, s: "ASRR.XETRA", n: "BNP Paribas Easy MSCI Europe SRI Cap - LU1753045332", c: '"' },
  { id: 30308, s: "ASRUSA.SW", n: "UBS(Lux)Fund Solutions – MSCI USA Socially Responsible UCITS ETF(USD)A-dis - LU0629460089", c: '"' },
  { id: 30309, s: "ASWE.XETRA", n: "Saturna Al-Kawthar Global Focused Equity UCITS ETF - IE00BMYMHS24", c: '"' },
  { id: 30310, s: "ATMP.US", n: "Barclays ETN+ Select MLP ETN - US06742C7231", c: '"' },
  { id: 30311, s: "AUAD.LSE", n: "UBS (Irl) ETF plc - MSCI Australia UCITS ETF (AUD) A-dis - IE00BD4TY345", c: '"' },
  { id: 30312, s: "AUCO.AS", n: "L&G Gold Mining UCITS ETF - IE00B3CNHG25", c: '"' },
  { id: 30313, s: "AUCO.LSE", n: "L&G Gold Mining UCITS ETF - IE00B3CNHG25", c: '"' },
  { id: 30314, s: "AUCO.SW", n: "L&G Gold Mining UCITS ETF CHF - IE00B3CNHG25", c: '"' },
  { id: 30315, s: "AUEM.LSE", n: "Amundi Index Solutions - Amundi MSCI Emerging Markets UCITS ETF-C USD - LU1681045453", c: '"' },
  { id: 30316, s: "AUEM.PA", n: "Amundi Index Solutions - Amundi MSCI Emerging Markets UCITS ETF-C USD - LU1681045453", c: '"' },
  { id: 30317, s: "AUM5.XETRA", n: "Amundi Index Solutions - Amundi S&P 500 UCITS ETF C EUR - LU1681048804", c: '"' },
  { id: 30318, s: "AUMF.AU", n: "iShares Edge MSCI Australia Multifactor ETF - AU00000AUMF0", c: '"' },
  { id: 30319, s: "AUSAUW.SW", n: "UBS (Irl) ETF plc - MSCI Australia UCITS ETF (AUD) A-acc CHF - IE00BD4TY451", c: '"' },
  { id: 30320, s: "AUSAUY.SW", n: "UBS (Irl) ETF plc - MSCI Australia UCITS ETF (AUD) A-dis - IE00BD4TY345", c: '"' },
  { id: 30321, s: "AUSF.US", n: "Global X Adaptive U.S. Factor ETF - US37954Y5740", c: '"' },
  { id: 30322, s: "AUT.PA", n: "Lyxor Index Fund - Lyxor STOXX Europe 600 Automobiles & Parts UCITS ETF Acc - LU1834983394", c: '"' },
  { id: 30323, s: "AVDE.US", n: "Avantis® International Equity ETF - US0250727031", c: '"' },
  { id: 30324, s: "AVDV.US", n: "Avantis® International Small Cap Value ETF - US0250728021", c: '"' },
  { id: 30325, s: "AVEM.US", n: "Avantis® Emerging Markets Equity ETF - US0250726041", c: '"' },
  { id: 30326, s: "AVIG.US", n: "Avantis Core Fixed Income ETF - US0250725621", c: '"' },
  { id: 30327, s: "AVSF.US", n: "American Century ETF Trust - Avantis Short-Term Fixed Income ETF - US0250726876", c: '"' },
  { id: 30328, s: "AVUS.US", n: "Avantis® U.S. Equity ETF - US0250728856", c: '"' },
  { id: 30329, s: "AVUV.US", n: "Avantis® U.S. Small Cap Value ETF - US0250728773", c: '"' },
  { id: 30330, s: "AW1P.XETRA", n: "UBS (Irl) ETF plc - MSCI ACWI Socially Responsible UCITS ETF USD A Acc EUR - IE00BDR55471", c: '"' },
  { id: 30331, s: "AWAT.PA", n: "Lyxor PEA World Water UCITS C - FR0011882364", c: '"' },
  { id: 30332, s: "AWAY.US", n: "ETFMG Travel Tech ETF - US26924G7714", c: '"' },
  { id: 30333, s: "AWESG.SW", n: "UBS (IRL) ETF Plc – MSCI ACWI ESG Universal Low Carbon Select UCITS ETF USD A Dis CHF - IE00BDQZMX67", c: '"' },
  { id: 30334, s: "AWSRI.SW", n: "UBS (Irl) ETF plc - MSCI ACWI Socially Responsible UCITS ETF USD A Dis USD - IE00BDR55364", c: '"' },
  { id: 30335, s: "AWSRIA.SW", n: "UBS (Irl) ETF plc - MSCI ACWI Socially Responsible UCITS ETF USD A Acc - IE00BDR55471", c: '"' },
  { id: 30336, s: "AYE2.XETRA", n: "iShares € High Yield Corp Bond ESG UCITS ETF EUR (Acc) - IE00BJK55C48", c: '"' },
  { id: 30337, s: "AYEM.XETRA", n: "iShares MSCI EM IMI ESG Screened UCITS ETF USD (Acc) - IE00BFNM3P36", c: '"' },
  { id: 30338, s: "AYEP.XETRA", n: "iShares Asia Property Yield UCITS ETF USD (Acc) - IE00BGDPWV87", c: '"' },
  { id: 30339, s: "AYEU.XETRA", n: "iShares Smart City Infrastructure UCITS ETF USD Acc - IE00BKTLJC87", c: '"' },
  { id: 30340, s: "AYEV.XETRA", n: "iShares Smart City Infrastructure UCITS ETF USD Inc - IE00BKTLJB70", c: '"' },
  { id: 30341, s: "AYEW.XETRA", n: "iShares MSCI World Information Technology Sector ESG UCITS ETF USD Inc - IE00BJ5JNY98", c: '"' },
  { id: 30342, s: "AZAA.US", n: "AllianzIM U.S. Large Cap Buffer10 Apr ETF - US00888H1095", c: '"' },
  { id: 30343, s: "AZAL.US", n: "AllianzIM U.S. Large Cap Buffer10 Jul ETF - US00888H3075", c: '"' },
  { id: 30344, s: "AZAO.US", n: "AllianzIM U.S. Large Cap Buffer10 Oct ETF - US00888H6045", c: '"' },
  { id: 30345, s: "AZBA.US", n: "AllianzIM U.S. Large Cap Buffer20 Apr ETF - US00888H2085", c: '"' },
  { id: 30346, s: "AZBL.US", n: "AllianzIM U.S. Large Cap Buffer20 Jul ETF - US00888H4065", c: '"' },
  { id: 30347, s: "AZBO.US", n: "AllianzIM U.S. Large Cap Buffer20 Oct ETF - US00888H5054", c: '"' },
  { id: 30348, s: "B500.F", n: "Amundi Index Solutions - Amundi S&P 500 Buyback - FR0012395473", c: '"' },
  { id: 30349, s: "B500.XETRA", n: "Amundi Index Solutions - Amundi S&P 500 Buyback ETF-C EUR - LU1681048127", c: '"' },
  { id: 30350, s: "BAB.US", n: "Invesco Taxable Municipal Bond ETF - US46138G8050", c: '"' },
  { id: 30351, s: "BAPR.US", n: "Innovator S&P 500 Buffer ETF - April New - US45782C8881", c: '"' },
  { id: 30352, s: "BATT.US", n: "Amplify Lithium & Battery Technology ETF - US0321088058", c: '"' },
  { id: 30353, s: "BAUG.US", n: "Innovator U.S. Equity Buffer ETF - August - US45782C6984", c: '"' },
  { id: 30354, s: "BBAX.US", n: "JPMorgan BetaBuilders Developed Asia Pacific ex-Japan ETF - US46641Q2333", c: '"' },
  { id: 30355, s: "BBC.US", n: "Virtus LifeSci Biotech Clinical Trials ETF - US26923G3011", c: '"' },
  { id: 30356, s: "BBCK.F", n: "Invesco Markets III plc - Invesco Global Buyback Achievers UCITS ETF - IE00BLSNMW37", c: '"' },
  { id: 30357, s: "BBDD.LSE", n: "JPMorgan ETFs (Ireland) ICAV - BetaBuilders US Equity UCITS ETF - USD (dist) GBP - IE00BJK9H860", c: '"' },
  { id: 30358, s: "BBEG.SW", n: "JPMorgan ETFs (Ireland) ICAV BetaBuilders EUR Govt Bond UCITS ETF EUR Acc EUR - IE00BJK9HD13", c: '"' },
  { id: 30359, s: "BBEG.XETRA", n: "JPMorgan ETFs (Ireland) ICAV BetaBuilders EUR Govt Bond UCITS ETF EUR Acc - IE00BJK9HD13", c: '"' },
  { id: 30360, s: "BBEU.US", n: "JPMorgan BetaBuilders Europe ETF - US46641Q1913", c: '"' },
  { id: 30361, s: "BBH.US", n: "VanEck Biotech ETF - US92189F7261", c: '"' },
  { id: 30362, s: "BBIN.US", n: "JPMorgan BetaBuilders International Equity ETF - US46641Q3737", c: '"' },
  { id: 30363, s: "BBJP.US", n: "JPMorgan BetaBuilders Japan ETF - US46641Q2176", c: '"' },
  { id: 30364, s: "BBMC.US", n: "JPMorgan BetaBuilders U.S. Mid Cap Equity ETF - US46641Q3406", c: '"' },
  { id: 30365, s: "BBOZ.AU", n: "BetaShares Australian Equities Strong Bear Hedge - AU00000BBOZ2", c: '"' },
  { id: 30366, s: "BBP.US", n: "Virtus LifeSci Biotech Products ETF - US26923G2021", c: '"' },
  { id: 30367, s: "BBRE.US", n: "JPMorgan BetaBuilders MSCI US REIT - US46641Q7381", c: '"' },
  { id: 30368, s: "BBSA.US", n: "JPMorgan BetaBuilders 1-5 Year U.S. Aggregate Bond ETF - US46641Q2580", c: '"' },
  { id: 30369, s: "BBSU.LSE", n: "JPMorgan ETFs (Ireland) ICAV - BetaBuilders US Equity UCITS ETF - USD (acc) GBP - IE00BJK9H753", c: '"' },
  { id: 30370, s: "BBTR.XETRA", n: "JPMorgan ETFs (Ireland) ICAV BetaBuilders US Treasury Bond UCITS ETF USD Acc - IE00BJK9HH50", c: '"' },
  { id: 30371, s: "BBUD.LSE", n: "JPMorgan ETFs (Ireland) ICAV - BetaBuilders US Equity UCITS ETF - USD (dist) - IE00BJK9H860", c: '"' },
  { id: 30372, s: "BBUD.XETRA", n: "BetaBuilders US Equity UCITS USD Dist - IE00BJK9H860", c: '"' },
  { id: 30373, s: "BBUS.LSE", n: "BetaBuilders US Equity UCITS USD Acc - IE00BJK9H753", c: '"' },
  { id: 30374, s: "BBUS.US", n: "JPMorgan BetaBuilders U.S. Equity ETF - US46641Q3992", c: '"' },
  { id: 30375, s: "BBUS.XETRA", n: "BetaBuilders US Equity UCITS USD Acc - IE00BJK9H753", c: '"' },
  { id: 30376, s: "BCD.US", n: "abrdn Bloomberg All Commodity Longer Dated Strategy K-1 Free ETF - US0032612030", c: '"' },
  { id: 30377, s: "BCHE.SW", n: "Invesco CoinShares Global Blockchain UCITS ETF USD - IE00BGBN6P67", c: '"' },
  { id: 30378, s: "BCHN.LSE", n: "Invesco Markets II PLC - Invesco Elwood Global Blockchain Ucits ETF - IE00BGBN6P67", c: '"' },
  { id: 30379, s: "BCHS.LSE", n: "Invesco CoinShares Global Blockchain UCITS ETF GBP - IE00BGBN6P67", c: '"' },
  { id: 30380, s: "BCI.US", n: "abrdn Bloomberg All Commodity Strategy K-1 Free ETF - US0032611040", c: '"' },
  { id: 30381, s: "BDIV.TO", n: "Brompton Global Dividend Growth ETF - CA11221W1095", c: '"' },
  { id: 30382, s: "BEAR.AU", n: "BetaShares Australian EquitiesBear - AU00000BEAR2", c: '"' },
  { id: 30383, s: "BEPR.TO", n: "Brompton Flaherty & Crumrine Enhanced Investment Grade Preferred ETF - CA11223L1013", c: '"' },
  { id: 30384, s: "BETZ.US", n: "Roundhill Sports Betting & iGaming ETF - US53656F7895", c: '"' },
  { id: 30385, s: "BFIN.TO", n: "Brompton North American Financials Dividend ETF - CA11221X1078", c: '"' },
  { id: 30386, s: "BFIT.US", n: "Global X Health & Wellness ETF - US37954Y7985", c: '"' },
  { id: 30387, s: "BFOR.US", n: "Barron s 400 ETF - US00162Q7262", c: '"' },
  { id: 30388, s: "BFTR.US", n: "BlackRock Future Innovators ETF - US09290C2026", c: '"' },
  { id: 30389, s: "BGRN.US", n: "iShares USD Green Bond ETF - US46435U4408", c: '"' },
  { id: 30390, s: "BGU.TO", n: "Bristol Gate Concentrated US Equity ETF - CA11004A1003", c: '"' },
  { id: 30391, s: "BIB.US", n: "ProShares Ultra Nasdaq Biotechnology - US74347R2141", c: '"' },
  { id: 30392, s: "BIBL.US", n: "Northern Lights Fund Trust IV - Inspire 100 ETF - US66538H5349", c: '"' },
  { id: 30393, s: "BIGT.LSE", n: "L&G Pharma Breakthrough UCITS ETF - IE00BF0H7608", c: '"' },
  { id: 30394, s: "BIL.US", n: "SPDR® Bloomberg 1-3 Month T-Bill ETF - US78468R6633", c: '"' },
  { id: 30395, s: "BIS.US", n: "ProShares UltraShort Nasdaq Biotechnology - US74347G8380", c: '"' },
  { id: 30396, s: "BIV.US", n: "Vanguard Intermediate-Term Bond Index Fund ETF Shares - US9219378190", c: '"' },
  { id: 30397, s: "BIZD.US", n: "VanEck BDC Income ETF - US92189F4110", c: '"' },
  { id: 30398, s: "BJAN.US", n: "Innovator S&P 500 Buffer - January - US45782C4096", c: '"' },
  { id: 30399, s: "BJK.US", n: "VanEck Gaming ETF - US92189F8822", c: '"' },
  { id: 30400, s: "BJUL.US", n: "Innovator U.S. Equity Buffer ETF - July - US45782C7891", c: '"' },
  { id: 30401, s: "BJUN.US", n: "Innovator S&P 500 Buffer ETF - June - US45782C7552", c: '"' },
  { id: 30402, s: "BKAG.US", n: "BNY Mellon Core Bond ETF - US09661T6029", c: '"' },
  { id: 30403, s: "BKEM.US", n: "BNY Mellon ETF Trust - BNY Mellon Emerging Markets Equity ETF - US09661T5039", c: '"' },
  { id: 30404, s: "BKF.US", n: "iShares MSCI BIC ETF - US4642866572", c: '"' },
  { id: 30405, s: "BKHY.US", n: "BNY Mellon High Yield Beta ETF - US09661T8009", c: '"' },
  { id: 30406, s: "BKIE.US", n: "BNY Mellon International Equity ETF - US09661T4040", c: '"' },
  { id: 30407, s: "BKLC.US", n: "BNY Mellon US Large Cap Core Equity ETF - US09661T1079", c: '"' },
  { id: 30408, s: "BKLN.US", n: "Invesco Senior Loan ETF - US46138G5080", c: '"' },
  { id: 30409, s: "BKMC.US", n: "BNY Mellon US Mid Cap Core Equity ETF - US09661T2069", c: '"' },
  { id: 30410, s: "BKSB.US", n: "BNY Mellon ETF Trust - BNY Mellon Short Duration Corporate Bond ETF - US09661T7019", c: '"' },
  { id: 30411, s: "BKSE.US", n: "BNY Mellon ETF Trust - BNY Mellon US Small Cap Core Equity ETF - US09661T3059", c: '"' },
  { id: 30412, s: "BLCN.US", n: "Siren Nasdaq NexGen Economy ETF - US8296582021", c: '"' },
  { id: 30413, s: "BLES.US", n: "Inspire Global Hope ETF - US66538H6586", c: '"' },
  { id: 30414, s: "BLHY.US", n: "Virtus Newfleet High Yield Bond ETF - US92790A2078", c: '"' },
  { id: 30415, s: "BLOK.LSE", n: "First Trust Indxx Innovative Transaction & Process UCITS ETF - IE00BF5DXP42", c: '"' },
  { id: 30416, s: "BLOK.US", n: "Amplify Transformational Data Sharing ETF - US0321086078", c: '"' },
  { id: 30417, s: "BLOV.TO", n: "Brompton North American Low Volatility Dividend ETF - CA11222V1004", c: '"' },
  { id: 30418, s: "BLV.US", n: "Vanguard Long-Term Bond Index Fund ETF Shares - US9219377937", c: '"' },
  { id: 30419, s: "BMAY.US", n: "Innovator S&P 500 Buffer ETF - May - US45782C3262", c: '"' },
  { id: 30420, s: "BND.TO", n: "Purpose Global Bond Fund Series ETF - CA74641B1058", c: '"' },
  { id: 30421, s: "BND.US", n: "Vanguard Total Bond Market Index Fund ETF Shares - US9219378356", c: '"' },
  { id: 30422, s: "BNDC.US", n: "FlexShares Core Select Bond Fund - US33939L6700", c: '"' },
  { id: 30423, s: "BNDW.US", n: "Vanguard Total World Bond ETF - US92206C5655", c: '"' },
  { id: 30424, s: "BNDX.US", n: "Vanguard Total International Bond Index Fund ETF Shares - US92203J4076", c: '"' },
  { id: 30425, s: "BNK.PA", n: "Lyxor Index Fund - Lyxor Stoxx Europe 600 Banks UCITS ETF - LU1834983477", c: '"' },
  { id: 30426, s: "BNKE.LSE", n: "Lyxor EURO STOXX Banks UCITS ETF P Acc GBP - LU1829219390", c: '"' },
  { id: 30427, s: "BNKS.LSE", n: "iShares S&P U.S. Banks - IE00BD3V0B10", c: '"' },
  { id: 30428, s: "BNO.US", n: "United States Brent Oil Fund LP - US91167Q1004", c: '"' },
  { id: 30429, s: "BNXG.XETRA", n: "Invesco CoinShares Global Blockchain UCITS ETF - IE00BGBN6P67", c: '"' },
  { id: 30430, s: "BOCT.US", n: "Innovator S&P 500 Buffer October - US45782C7719", c: '"' },
  { id: 30431, s: "BOIL.US", n: "ProShares Ultra Bloomberg Natural Gas - US74347Y8701", c: '"' },
  { id: 30432, s: "BOND.AU", n: "SPDR S&P/ASX Australian Bond  - AU00000BOND4", c: '"' },
  { id: 30433, s: "BOND.US", n: "PIMCO Active Bond Exchange-Traded Fund - US72201R7750", c: '"' },
  { id: 30434, s: "BOSS.US", n: "Global X Founder-Run Companies ETF - US37954Y6813", c: '"' },
  { id: 30435, s: "BOTZ.US", n: "Global X Robotics & Artificial Intelligence ETF - US37954Y7159", c: '"' },
  { id: 30436, s: "BOUT.US", n: "Innovator ETFs Trust - Innovator IBD Breakout Opportunities ETF - US45782C7636", c: '"' },
  { id: 30437, s: "BPRF.TO", n: "Brompton Flaherty & Crumrine Investment Grade Preferred ETF - CA11221N1096", c: '"' },
  { id: 30438, s: "BREA.TO", n: "Brompton Sustainable Real Assets Dividend ETF - CA11223H1001", c: '"' },
  { id: 30439, s: "BRES.PA", n: "Lyxor Index Fund - Lyxor Stoxx Europe 600 Basic Resources UCITS ETF - LU1834983550", c: '"' },
  { id: 30440, s: "BRF.US", n: "VanEck Brazil Small-Cap ETF - US92189F8251", c: '"' },
  { id: 30441, s: "BRIC.AS", n: "iShares BRIC 50 UCITS - IE00B1W57M07", c: '"' },
  { id: 30442, s: "BRIC.SW", n: "iShares BRIC 50 UCITS ETF USD (Dist) - IE00B1W57M07", c: '"' },
  { id: 30443, s: "BRZ.SW", n: "Amundi Index Solutions - Amundi ETF MSCI Brazil UCITS ETF - LU1437024992", c: '"' },
  { id: 30444, s: "BRZU.US", n: "Direxion Daily MSCI Brazil Bull 2X Shares - US25460G7088", c: '"' },
  { id: 30445, s: "BSCN.US", n: "Invesco BulletShares 2023 Corporate Bond ETF - US46138J8669", c: '"' },
  { id: 30446, s: "BSCO.US", n: "Invesco BulletShares 2024 Corporate Bond ETF - US46138J8412", c: '"' },
  { id: 30447, s: "BSCP.US", n: "Invesco BulletShares 2025 Corporate Bond ETF - US46138J8255", c: '"' },
  { id: 30448, s: "BSCQ.US", n: "Invesco BulletShares 2026 Corporate Bond ETF - US46138J7919", c: '"' },
  { id: 30449, s: "BSJN.US", n: "Invesco BulletShares 2023 High Yield Corporate Bond ETF - US46138J8586", c: '"' },
  { id: 30450, s: "BSJO.US", n: "Invesco BulletShares 2024 High Yield Corporate Bond ETF - US46138J8339", c: '"' },
  { id: 30451, s: "BSJP.US", n: "Invesco BulletShares 2025 High Yield Corporate Bond ETF - US46138J8172", c: '"' },
  { id: 30452, s: "BSJQ.US", n: "Invesco BulletShares 2026 High Yield Corporate Bond ETF - US46138J6358", c: '"' },
  { id: 30453, s: "BSJR.US", n: "Invesco BulletShares 2027 High Yield Corporate Bond ETF - US46138J5855", c: '"' },
  { id: 30454, s: "BSJS.US", n: "Invesco BulletShares 2028 High Yield Corporate Bond ETF - US46138J4528", c: '"' },
  { id: 30455, s: "BSV.US", n: "Vanguard Short-Term Bond Index Fund ETF Shares - US9219378273", c: '"' },
  { id: 30456, s: "BTAL.US", n: "AGFiQ U.S. Market Neutral Anti-Beta Fund - US00110G4082", c: '"' },
  { id: 30457, s: "BTEC.LSE", n: "iShares Nasdaq US Biotechnology UCITS ETF USD (Acc) - IE00BYXG2H39", c: '"' },
  { id: 30458, s: "BTEC.SW", n: "iShares Nasdaq US Biotechnology UCITS ETF USD (Acc) USD - IE00BYXG2H39", c: '"' },
  { id: 30459, s: "BTEC.US", n: "Principal Healthcare Innovators ETF - US74255Y4098", c: '"' },
  { id: 30460, s: "BTEE.LSE", n: "iShares Nasdaq US Biotechnology UCITS ETF USD (Dist) - IE00BDZVHG35", c: '"' },
  { id: 30461, s: "BTEK.LSE", n: "iShares Nasdaq US Biotechnology UCITS ETF USD (Acc) - IE00BYXG2H39", c: '"' },
  { id: 30462, s: "BTEK.US", n: "BlackRock Future Tech ETF - US09290C3016", c: '"' },
  { id: 30463, s: "BTMA.AS", n: "iShares II Public Limited Company - iShares $ Treasury Bond 7-10yr UCITS ETF - IE00B1FZS798", c: '"' },
  { id: 30464, s: "BUFF.US", n: "Innovator Laddered Allocation Power Buffer ETF - US45783Y8140", c: '"' },
  { id: 30465, s: "BUFR.US", n: "First Trust Cboe Vest Fund of Buffer ETFs - US33740F7556", c: '"' },
  { id: 30466, s: "BUG.US", n: "Global X Cybersecurity ETF - US37954Y3844", c: '"' },
  { id: 30467, s: "BUL.US", n: "Pacer US Cash Cows Growth ETF - US69374H6678", c: '"' },
  { id: 30468, s: "BUYB.LSE", n: "Invesco Global Buyback Achievers UCITS ETF - IE00BLSNMW37", c: '"' },
  { id: 30469, s: "BUYZ.US", n: "Franklin Disruptive Commerce ETF - US35473P5382", c: '"' },
  { id: 30470, s: "BWX.US", n: "SPDR® Bloomberg International Treasury Bond ETF - US78464A5166", c: '"' },
  { id: 30471, s: "BWZ.US", n: "SPDR® Bloomberg Short Term International Treasury Bond ETF - US78464A3344", c: '"' },
  { id: 30472, s: "BXF.TO", n: "CI 1-5 Year Laddered Government Strip Bond Index ETF Common - CA12555C1077", c: '"' },
  { id: 30473, s: "BYBU.LSE", n: "Amundi S&P 500 Buyback USD UCITS - LU1681048556", c: '"' },
  { id: 30474, s: "BYBU.PA", n: "Amundi S&P 500 Buyback USD UCITS - LU1681048556", c: '"' },
  { id: 30475, s: "BYLD.US", n: "iShares Yield Optimized Bond ETF - US46434V7872", c: '"' },
  { id: 30476, s: "BZQ.US", n: "ProShares UltraShort MSCI Brazil Capped - US74347G6558", c: '"' },
  { id: 30477, s: "C001.F", n: "Lyxor Core DAX (DR) UCITS ETF - LU0378438732", c: '"' },
  { id: 30478, s: "C001.XETRA", n: "Lyxor Core DAX (DR) UCITS ETF - LU0378438732", c: '"' },
  { id: 30479, s: "C003.F", n: "Lyxor DivDAX® (DR) UCITS ETF - LU0603933895", c: '"' },
  { id: 30480, s: "C003.XETRA", n: "Lyxor DivDAX (DR) UCITS ETF - LU0603933895", c: '"' },
  { id: 30481, s: "C005.F", n: "ComStage - SDAX® UCITS ETF - LU0603942888", c: '"' },
  { id: 30482, s: "C005.XETRA", n: "Lyxor SDAX (DR) UCITS ETF - LU0603942888", c: '"' },
  { id: 30483, s: "C006.F", n: "Lyxor F.A.Z. 100 Index (DR) UCITS ETF - LU0650624025", c: '"' },
  { id: 30484, s: "C006.XETRA", n: "Lyxor F.A.Z. 100 Index (DR) UCITS ETF - LU0650624025", c: '"' },
  { id: 30485, s: "C007.F", n: "Lyxor MDAX® (DR) UCITS ETF - LU1033693638", c: '"' },
  { id: 30486, s: "C007.XETRA", n: "Lyxor MDAX (DR) UCITS ETF - LU1033693638", c: '"' },
  { id: 30487, s: "C024.F", n: "Lyxor FTSE China A50 UCITS ETF - LU0947415054", c: '"' },
  { id: 30488, s: "C030.F", n: "Lyxor DJ Switzerland Titans 30 (DR) UCITS ETF - LU0392496427", c: '"' },
  { id: 30489, s: "C030.XETRA", n: "Lyxor DJ Switzerland Titans 30 (DR) UCITS ETF - LU0392496427", c: '"' },
  { id: 30490, s: "C051.F", n: "Lyxor EURO STOXX Select Dividend 30 UCITS ETF - LU0378434236", c: '"' },
  { id: 30491, s: "C051.XETRA", n: "Lyxor EURO STOXX Select Dividend 30 UCITS ETF - LU0378434236", c: '"' },
  { id: 30492, s: "C071.F", n: "Lyxor Index Fund - Lyxor Stoxx Europe 600 Media UCITS ETF - LU0378436363", c: '"' },
  { id: 30493, s: "C071.XETRA", n: "Lyxor Index Fund - Lyxor STOXX Europe 600 Media UCITS ETF Inc - LU2082995734", c: '"' },
  { id: 30494, s: "C10.PA", n: "Amundi ETF Govt Bond Euro Broad Investment Grade 10-15 UCITS ETF - FR0010754143", c: '"' },
  { id: 30495, s: "C101.F", n: "Multi Units Luxembourg - Lyxor Fed Funds US Dollar Cash UCITS ETF - LU0378437767", c: '"' },
  { id: 30496, s: "C13.PA", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 1-3 UCITS ETF DR - FR0010754135", c: '"' },
  { id: 30497, s: "C1U.PA", n: "Amundi FTSE 100 UCITS - LU1437025023", c: '"' },
  { id: 30498, s: "C2MV.SW", n: "Ossiam Europe ESG Machine Learning ETF 2C (EUR) - LU0811899946", c: '"' },
  { id: 30499, s: "C33.PA", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 3-5 UCITS ETF DR - FR0010754168", c: '"' },
  { id: 30500, s: "C40.PA", n: "Amundi Index Solutions - AMUNDI CAC 40 ESG UCITS ETF DR - EUR (C) - LU1681046931", c: '"' },
  { id: 30501, s: "C40.SW", n: "Amundi Index Solutions - AMUNDI CAC 40 ESG UCITS ETF DR - EUR (C) EUR - LU1681046931", c: '"' },
  { id: 30502, s: "C4D.PA", n: "Amundi Index Solutions - AMUNDI CAC 40 ESG UCITS ETF DR - EUR (D) - LU1681047079", c: '"' },
  { id: 30503, s: "C4DE.SW", n: "Amundi Index Solutions - AMUNDI CAC 40 ESG UCITS ETF DR - EUR (D) EUR - LU1681047079", c: '"' },
  { id: 30504, s: "C50.PA", n: "Amundi Index Solutions - Amundi EURO STOXX 50 UCITS ETF-C EUR - LU1681047236", c: '"' },
  { id: 30505, s: "C50U.LSE", n: "Amundi Index Solutions - Amundi EURO STOXX 50 UCITS ETF-C USD USD - LU1681047400", c: '"' },
  { id: 30506, s: "C50U.PA", n: "Amundi Index Solutions - Amundi EURO STOXX 50 UCITS ETF-C USD - LU1681047400", c: '"' },
  { id: 30507, s: "C50U.SW", n: "Amundi Index Solutions - Amundi EURO STOXX 50 UCITS ETF-C USD USD - LU1681047400", c: '"' },
  { id: 30508, s: "C53.PA", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 5-7 UCITS ETF DR C - FR0010754176", c: '"' },
  { id: 30509, s: "C5E.PA", n: "Amundi Stoxx Europe 50 UCITS - FR0010790980", c: '"' },
  { id: 30510, s: "C6E.PA", n: "Amundi Index Solutions - AMUNDI STOXX EUROPE 600 ESG - UCITS ETF DR - EUR (C) - LU1681040223", c: '"' },
  { id: 30511, s: "C6E.SW", n: "Amundi Index Solutions - AMUNDI STOXX EUROPE 600 ESG - UCITS ETF DR - EUR (C) EUR - LU1681040223", c: '"' },
  { id: 30512, s: "C73.PA", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 7-10 UCITS ETF DR - FR0010754184", c: '"' },
  { id: 30513, s: "CAC.PA", n: "Lyxor UCITS CAC 40 (DR) D-EUR - FR0007052782", c: '"' },
  { id: 30514, s: "CACC.PA", n: "Lyxor CAC 40 (DR) UCITS ETF Acc - FR0013380607", c: '"' },
  { id: 30515, s: "CACG.US", n: "ClearBridge All Cap Growth - US5246821012", c: '"' },
  { id: 30516, s: "CACM.PA", n: "Lyxor UCITS CAC Mid 60 D-EUR - FR0011041334", c: '"' },
  { id: 30517, s: "CACX.LSE", n: "Lyxor CAC 40 (DR) UCITS ETF - FR0007052782", c: '"' },
  { id: 30518, s: "CAGG.TO", n: "CI Yield Enhanced Canada Aggregate Bond Index ETF - CA17167D1042", c: '"' },
  { id: 30519, s: "CAGS.TO", n: "CI Yield Enhanced Canada Short-Term Aggregate Bond Index ETF - CA17166P1080", c: '"' },
  { id: 30520, s: "CALF.US", n: "Pacer US Small Cap Cash Cows 100 ETF - US69374H8575", c: '"' },
  { id: 30521, s: "CANCDA.SW", n: "UBS(Lux)Fund Solutions – MSCI Canada UCITS ETF(CAD)A-dis - LU0446734872", c: '"' },
  { id: 30522, s: "CANE.US", n: "Teucrium Sugar - US88166A4094", c: '"' },
  { id: 30523, s: "CANUKD.SW", n: "UBS(Lux)Fund Solutions – MSCI Canada UCITS ETF(CAD)A-UKdis - LU1107559962", c: '"' },
  { id: 30524, s: "CAPE.PA", n: "Ossiam Shiller Barclays Cape Europe Sector Value TR UCITS - LU1079842321", c: '"' },
  { id: 30525, s: "CAPE.US", n: "Barclays ETN+ Shiller Capet ETN - US06742A6698", c: '"' },
  { id: 30526, s: "CAPS.LSE", n: "First Trust Capital Strength UCITS ETF Class A USD Accumulation GBP - IE00BL0L0D23", c: '"' },
  { id: 30527, s: "CAPU.LSE", n: "Ossiam Lux - Ossiam Shiller Barclays CAPE US Sector Value Trust - LU1079841513", c: '"' },
  { id: 30528, s: "CARZ.US", n: "First Trust S-Network Future Vehicles & Technology ETF - US33734X3098", c: '"' },
  { id: 30529, s: "CASE.LSE", n: "Legal & General UCITS ETF Plc - L&G E Fund MSCI China A UCITS ETF - IE00BHBFDF83", c: '"' },
  { id: 30530, s: "CASH.AS", n: "L&GE Fund MSCI China A UCITS ETF - IE00BHBFDF83", c: '"' },
  { id: 30531, s: "CASH.F", n: "Legal & General UCITS ETF Plc - L&G E Fund MSCI China A UCITS ETF - DE000A1XEFE1", c: '"' },
  { id: 30532, s: "CASH.LSE", n: "Legal & General UCITS ETF Plc - L&G E Fund MSCI China A UCITS ETF - IE00BHBFDF83", c: '"' },
  { id: 30533, s: "CATH.LSE", n: "Lyxor Index Fund - Lyxor MSCI World Catholic Principles ESG (DR) UCITS ETF USD - LU2216829809", c: '"' },
  { id: 30534, s: "CATH.US", n: "Global X S&P 500® Catholic Values ETF - US37954Y8892", c: '"' },
  { id: 30535, s: "CB1EU6.SW", n: "Lyxor 1 STOXX Europe 600 ESG (DR) UCITS ETF EUR - DE000ETF9603", c: '"' },
  { id: 30536, s: "CB1MDX.SW", n: "Lyxor 1 MDAX (DR) UCITS ETF I CHF - DE000ETF9074", c: '"' },
  { id: 30537, s: "CB1TX.SW", n: "Lyxor 1 TecDAX (DR) UCITS ETF I CHF - DE000ETF9082", c: '"' },
  { id: 30538, s: "CB3.PA", n: "Amundi Index Solutions - Amundi Govt Bond Euro Broad Investment Grade UCITS ETF-C - LU1681046261", c: '"' },
  { id: 30539, s: "CB3G.XETRA", n: "Amundi Index Solutions - Amundi Govt Bond Euro Broad Investment Grade UCITS ETF-C - LU1681046261", c: '"' },
  { id: 30540, s: "CB5.LSE", n: "Amundi ETF MSCI Europe Banks UCITS ETF - FR0010688176", c: '"' },
  { id: 30541, s: "CB5.PA", n: "Amundi ETF MSCI Europe Banks UCITS ETF - FR0010688176", c: '"' },
  { id: 30542, s: "CBCH30.SW", n: "Lyxor DJ Switzerland Titans 30 (DR) UCITS ETF - LU0392496427", c: '"' },
  { id: 30543, s: "CBDAX.SW", n: "Lyxor Core DAX (DR) UCITS ETF EUR - LU0378438732", c: '"' },
  { id: 30544, s: "CBE3.LSE", n: "iShares VII PLC - iShares € Govt Bond 1-3yr ETF EUR Acc - IE00B3VTMJ91", c: '"' },
  { id: 30545, s: "CBE7.AS", n: "iShares VII Public Limited Company - iShares Euro Govt Bond 3-7yr UCITS ETF - IE00B3VTML14", c: '"' },
  { id: 30546, s: "CBFAZ.SW", n: "Lyxor F.A.Z. 100 Index (DR) UCITS ETF EUR - LU0650624025", c: '"' },
  { id: 30547, s: "CBGOLD.SW", n: "Lyxor NYSE Arca Gold BUGS (DR) UCITS ETF CHF - LU0488317701", c: '"' },
  { id: 30548, s: "CBH.TO", n: "iShares 1-10Yr Laddered Corporate Bond Common Class - CA46432J1093", c: '"' },
  { id: 30549, s: "CBMDAX.SW", n: "Lyxor MDAX ESG UCITS ETF EUR - LU1033693638", c: '"' },
  { id: 30550, s: "CBND.SW", n: "Goldman Sachs Access China Government Bond UCITS ETF USD Inc USD - IE00BJSBCS90", c: '"' },
  { id: 30551, s: "CBO.TO", n: "iShares 1-5 Year Laddered Corporate Bond Index ETF Common Class - CA46433Q1028", c: '"' },
  { id: 30552, s: "CBON.US", n: "VanEck China Bond ETF - US92189F3799", c: '"' },
  { id: 30553, s: "CBRS.XETRA", n: "First Trust Nasdaq Cybersecurity UCITS ETF Class A USD Accumulation - IE00BF16M727", c: '"' },
  { id: 30554, s: "CBSDAX.SW", n: "Lyxor SDAX (DR) UCITS ETF EUR - LU0603942888", c: '"' },
  { id: 30555, s: "CBSDIV.SW", n: "Lyxor EURO STOXX Select Dividend 30 UCITS ETF EUR - LU0378434236", c: '"' },
  { id: 30556, s: "CBSEU.SW", n: "UBS(Lux)Fund Solutions – Bloomberg MSCI Euro Area Liquid Corp Sust UCITSETF(EUR)A-dis EUR - LU1484799769", c: '"' },
  { id: 30557, s: "CBSUS.SW", n: "UBS(Lux)Fund Solutions – Bloomberg MSCI US Liquid Corp Sust UCITS ETF(USD)A-dis USD - LU1215461085", c: '"' },
  { id: 30558, s: "CBU3.LSE", n: "iShares VII PLC - iShares $ Treasury Bd 1-3y ETF USD Acc B - IE00B3VWN179", c: '"' },
  { id: 30559, s: "CBU7.AS", n: "iShares VII Public Limited Company - iShares $ Treasury Bond 3-7yr UCITS ETF - IE00B3VWN393", c: '"' },
  { id: 30560, s: "CBU7.LSE", n: "iShares $ Treasury Bond 3-7yr UCITS ETF USD (Acc) - IE00B3VWN393", c: '"' },
  { id: 30561, s: "CBUF.XETRA", n: "iShares MSCI World Health Care Sector ESG UCITS ETF USD Inc - IE00BJ5JNZ06", c: '"' },
  { id: 30562, s: "CBUS.SW", n: "UBS(Lux)Fund Solutions – Bloomberg US Liquid Corporates UCITS ETF(USD)A-dis - LU1048316647", c: '"' },
  { id: 30563, s: "CBUS5.SW", n: "UBS(Lux)Fund Solutions – Bloomberg US Liquid Corporates 1-5 Year UCITS ETF(USD)A-dis USD - LU1048314949", c: '"' },
  { id: 30564, s: "CBUSAC.SW", n: "UBS(Lux)Fund Solutions – Bloomberg US Liquid Corporates UCITS ETF(USD)A-acc - LU1048316720", c: '"' },
  { id: 30565, s: "CBVS.SW", n: "Lyxor Portfolio Strategy UCITS ETF EUR - DE000ETF7011", c: '"' },
  { id: 30566, s: "CBVSD.SW", n: "Lyxor Portfolio Strategy Defensiv UCITS ETF (Vermoegensstrategie) EUR - DE000ETF7029", c: '"' },
  { id: 30567, s: "CBVSO.SW", n: "Lyxor Portfolio Strategy Offensiv UCITS ETF (Vermoegensstrategie) EUR - DE000ETF7037", c: '"' },
  { id: 30568, s: "CC1.PA", n: "Amundi Index Solutions - Amundi MSCI China UCITS ETF-C EUR - LU1681043912", c: '"' },
  { id: 30569, s: "CC1U.LSE", n: "Amundi Index Solutions - Amundi MSCI China UCITS ETF-C USD - LU1681044050", c: '"' },
  { id: 30570, s: "CC4.PA", n: "Amundi Index Solutions - Amundi EURO Corporates UCITS ETF DR EUR C - LU1681039647", c: '"' },
  { id: 30571, s: "CCAU.LSE", n: "iShares VII PLC - iShares MSCI Canada ETF USD Acc - IE00B52SF786", c: '"' },
  { id: 30572, s: "CCNS.TO", n: "CIBC Conservative Fixed Income Pool ETF - CA12546Q1081", c: '"' },
  { id: 30573, s: "CCOR.TO", n: "CI DoubleLine Core Plus Fixed Income US$ Fund ETF C$ Hedged Series - CA12548Q1063", c: '"' },
  { id: 30574, s: "CCOR.US", n: "Core Alternative ETF - US53656F8471", c: '"' },
  { id: 30575, s: "CCRE.TO", n: "CIBC Core Fixed Income Pool ETF - CA12546V1076", c: '"' },
  { id: 30576, s: "CCRV.US", n: "iShares Commodity Curve Carry Strategy ETF - US46431W5647", c: '"' },
  { id: 30577, s: "CD5.PA", n: "Amundi Index Solutions - Amundi EURO STOXX 50 UCITS ETF-D EUR - LU1681047319", c: '"' },
  { id: 30578, s: "CD5E.SW", n: "Amundi Index Solutions - Amundi EURO STOXX 50 UCITS ETF-D EUR EUR - LU1681047319", c: '"' },
  { id: 30579, s: "CD8.PA", n: "Amundi ETF MSCI EMU High Dividend UCITS ETF - FR0010717090", c: '"' },
  { id: 30580, s: "CD9.PA", n: "Amundi MSCI Europe High Dividend UCITS - LU1681041973", c: '"' },
  { id: 30581, s: "CD91.F", n: "Lyxor NYSE Arca Gold BUGS (DR) UCITS ETF - LU0488317701", c: '"' },
  { id: 30582, s: "CD91.XETRA", n: "Lyxor NYSE Arca Gold BUGS (DR) UCITS ETF - LU0488317701", c: '"' },
  { id: 30583, s: "CDC.US", n: "VictoryShares US EQ Income Enhanced Volatility Wtd ETF - US92647N8240", c: '"' },
  { id: 30584, s: "CDCE.LSE", n: "SPDR® MSCI Europe Consumer Discretionary UCITS ETF GBP - IE00BKWQ0C77", c: '"' },
  { id: 30585, s: "CDIS.LSE", n: "SPDR® MSCI Europe Consumer Discretionary UCITS ETF - IE00BKWQ0C77", c: '"' },
  { id: 30586, s: "CDL.US", n: "VictoryShares US Large Cap High Div Volatility Wtd ETF - US92647N8653", c: '"' },
  { id: 30587, s: "CDLB.TO", n: "CI DoubleLine Total Return Bond US$ Fund ETF C$ Hedged Series - CA12560C1005", c: '"' },
  { id: 30588, s: "CDZ.TO", n: "iShares S&P/TSX Canadian Dividend Aristocrats Index ETF Common Class - CA46433F1062", c: '"' },
  { id: 30589, s: "CE01.LSE", n: "iShares VII PLC - iShares € Govt Bond 7-10yr ETF EUR Acc - IE00B3VTN290", c: '"' },
  { id: 30590, s: "CE71.LSE", n: "iShares VII PLC - iShares € Govt Bond 3-7yr ETF EUR Acc - IE00B3VTML14", c: '"' },
  { id: 30591, s: "CE8.PA", n: "Amundi Index Solutions - Amundi MSCI World Ex Europe ETF-C EUR - LU1681045537", c: '"' },
  { id: 30592, s: "CEA1.LSE", n: "iShares VII Public Limited Company - iShares MSCI EM Asia UCITS ETF - IE00B5L8K969", c: '"' },
  { id: 30593, s: "CEBL.F", n: "iShares VII Public Limited Company - iShares MSCI EM Asia UCITS ETF - IE00B5L8K969", c: '"' },
  { id: 30594, s: "CEBL.XETRA", n: "iShares VII PLC - iShares MSCI EM Asia ETF USD Acc - IE00B5L8K969", c: '"' },
  { id: 30595, s: "CEC.PA", n: "Multi Units Luxembourg - Lyxor MSCI Eastern Europe Ex Russia UCITS ETF - LU1900066462", c: '"' },
  { id: 30596, s: "CEFA.US", n: "Global X S&P Catholic Values Developed ex-U.S. ETF - US37954Y3687", c: '"' },
  { id: 30597, s: "CEFS.US", n: "Saba Closed-End Funds ETF - US30151E8066", c: '"' },
  { id: 30598, s: "CEMA.LSE", n: "iShares VII PLC - iShares MSCI EM Asia ETF USD Acc - IE00B5L8K969", c: '"' },
  { id: 30599, s: "CEMB.US", n: "iShares J.P. Morgan EM Corporate Bond ETF - US4642862514", c: '"' },
  { id: 30600, s: "CEMG.F", n: "iShares V Public Limited Company - iShares MSCI EM Consumer Growth UCITS ETF - IE00BKM4H197", c: '"' },
  { id: 30601, s: "CEMG.LSE", n: "iShares MSCI Emerging Markets Consumer Growth UCITS - IE00BKM4H197", c: '"' },
  { id: 30602, s: "CEMG.XETRA", n: "iShares MSCI EM Consumer Growth UCITS ETF USD (Acc) - IE00BKM4H197", c: '"' },
  { id: 30603, s: "CEMQ.F", n: "iShares Edge MSCIope Quality Factor UCITS - IE00BQN1K562", c: '"' },
  { id: 30604, s: "CEMQ.XETRA", n: "iShares Edge MSCIope Quality Factor UCITS - IE00BQN1K562", c: '"' },
  { id: 30605, s: "CEMR.F", n: "iShares Edge MSCI Europe Momentum Factor UCITS ETF EUR (Acc) - IE00BQN1K786", c: '"' },
  { id: 30606, s: "CEMR.XETRA", n: "iShares Edge MSCI Europe Momentum Factor UCITS ETF EUR (Acc) - IE00BQN1K786", c: '"' },
  { id: 30607, s: "CEMS.F", n: "iShares MSCI Europe Value Factor UCITS - IE00BQN1K901", c: '"' },
  { id: 30608, s: "CEMS.XETRA", n: "iShares MSCI Europe Value Factor UCITS - IE00BQN1K901", c: '"' },
  { id: 30609, s: "CEMT.F", n: "iShares Edge MSCIope Size Factor UCITS - IE00BQN1KC32", c: '"' },
  { id: 30610, s: "CEMT.XETRA", n: "iShares Edge MSCIope Size Factor UCITS - IE00BQN1KC32", c: '"' },
  { id: 30611, s: "CEMU.AS", n: "iShares VII plc - iShares Core MSCI EMU UCITS ETF - IE00B53QG562", c: '"' },
  { id: 30612, s: "CES1.LSE", n: "iShares MSCI EMU Small Cap UCITS - IE00B3VWMM18", c: '"' },
  { id: 30613, s: "CETF.AU", n: "VanEck FTSE China A50 ETF - AU00000CETF5", c: '"' },
  { id: 30614, s: "CEU.PA", n: "Amundi Index Solutions - Amundi MSCI Europe ESG Broad CTB UCITS ETF DR EUR C - LU1681042609", c: '"' },
  { id: 30615, s: "CEU1.LSE", n: "iShares VII PLC -iShares Core MSCI EMU UCITS ETF EUR (Acc) - IE00B53QG562", c: '"' },
  { id: 30616, s: "CEU2.PA", n: "Amundi Index Solutions - Amundi Index MSCI Europe UCITS ETF DR - LU1437015735", c: '"' },
  { id: 30617, s: "CEUG.XETRA", n: "Amundi Index Solutions - Amundi MSCI Europe ESG Broad CTB UCITS ETF DR EUR C - LU1681042609", c: '"' },
  { id: 30618, s: "CEUR.LSE", n: "Amundi Index Solutions - Amundi MSCI Europe - LU1681042609", c: '"' },
  { id: 30619, s: "CEW.TO", n: "iShares Equal Weight Banc & Lifeco Common Class - CA46433J1084", c: '"' },
  { id: 30620, s: "CEW.US", n: "WisdomTree Emerging Currency Strategy Fund - US97717W1339", c: '"' },
  { id: 30621, s: "CF1.PA", n: "Amundi MSCI France UCITS - FR0010655704", c: '"' },
  { id: 30622, s: "CFA.US", n: "VictoryShares US 500 Volatility Wtd ETF - US92647N7663", c: '"' },
  { id: 30623, s: "CFCV.US", n: "Clearbridge Focus Value ESG ETF - US00509W1053", c: '"' },
  { id: 30624, s: "CFO.US", n: "VictoryShares US 500 Enhanced Volatility Wtd ETF - US92647N7820", c: '"' },
  { id: 30625, s: "CG1.LSE", n: "Amundi ETF DAX UCITS ETF DR - FR0010655712", c: '"' },
  { id: 30626, s: "CG1.PA", n: "Amundi ETF DAX UCITS ETF DR - FR0010655712", c: '"' },
  { id: 30627, s: "CG9.PA", n: "Amundi MSCI Europe Growth UCITS - LU1681042435", c: '"' },
  { id: 30628, s: "CGAA.TO", n: "CI Global Asset Allocation Private Pool ETF - CA12558P1053", c: '"' },
  { id: 30629, s: "CGB.F", n: "db x-trackers II Harvest CSI China Sovereign Bond UCITS DR 1D - LU1094612022", c: '"' },
  { id: 30630, s: "CGB.LSE", n: "db x-trackers II Harvest CSI China Sovereign Bond UCITS DR 1D - LU1094612022", c: '"' },
  { id: 30631, s: "CGB.XETRA", n: "db x-trackers II Harvest CSI China Sovereign Bond UCITS DR 1D - LU1094612022", c: '"' },
  { id: 30632, s: "CGR.TO", n: "iShares Global Real Estate Common Class - CA46433A2065", c: '"' },
  { id: 30633, s: "CGRA.TO", n: "CI Global Real Asset Private Pool - ETF C$ Series - CA12559R1001", c: '"' },
  { id: 30634, s: "CGRE.TO", n: "CI Global REIT Private Pool - ETF C$ Series - CA17164X1033", c: '"' },
  { id: 30635, s: "CGW.US", n: "Invesco S&P Global Water Index ETF - US46138E2634", c: '"' },
  { id: 30636, s: "CH5.LSE", n: "Amundi ETF MSCI Europe Healthcare UCITS ETF - FR0010688192", c: '"' },
  { id: 30637, s: "CH5.PA", n: "Amundi ETF MSCI Europe Healthcare UCITS ETF - FR0010688192", c: '"' },
  { id: 30638, s: "CHAU.US", n: "Direxion Daily CSI 300 China A Share Bull 2X Shares - US25490K8696", c: '"' },
  { id: 30639, s: "CHB.US", n: "Global X China Biotech Innovation ETF - US37954Y2515", c: '"' },
  { id: 30640, s: "CHCORP.SW", n: "iShares Core CHF Corporate Bond ETF (CH) - CH0226976816", c: '"' },
  { id: 30641, s: "CHDVD.SW", n: "iShares Swiss Dividend ETF (CH) - CH0237935637", c: '"' },
  { id: 30642, s: "CHGX.US", n: "Change Finance Diversified Imp US Large Cap Fossil Fuel Free - US26922A5609", c: '"' },
  { id: 30643, s: "CHIC.US", n: "Global X MSCI China Communication Services - US37950E8049", c: '"' },
  { id: 30644, s: "CHIE.US", n: "Global X MSCI China Energy ETF - US37950E5078", c: '"' },
  { id: 30645, s: "CHIH.US", n: "Global X MSCI China Health Care ETF - US37954Y5419", c: '"' },
  { id: 30646, s: "CHII.US", n: "Global X MSCI China Industrials ETF - US37950E7058", c: '"' },
  { id: 30647, s: "CHIK.US", n: "Global X MSCI China Information Technology ETF - US37954Y5336", c: '"' },
  { id: 30648, s: "CHIM.US", n: "Global X MSCI China Materials ETF - US37950E2422", c: '"' },
  { id: 30649, s: "CHIQ.US", n: "Global X MSCI China Consumer Disc ETF - US37950E4089", c: '"' },
  { id: 30650, s: "CHIR.US", n: "Global X MSCI China Real Estate ETF - US37954Y5252", c: '"' },
  { id: 30651, s: "CHIS.US", n: "Global X MSCI China Consumer Staples ETF - US37954Y5583", c: '"' },
  { id: 30652, s: "CHIU.US", n: "Global X MSCI China Utilities ETF - US37954Y5179", c: '"' },
  { id: 30653, s: "CHIX.US", n: "Global X MSCI China Financials ETF - US37950E6068", c: '"' },
  { id: 30654, s: "CHNA.US", n: "Loncar China BioPharma ETF - US26922A3703", c: '"' },
  { id: 30655, s: "CHSPI.SW", n: "iShares Core SPI® ETF (CH) - CH0237935652", c: '"' },
  { id: 30656, s: "CHSRI.SW", n: "UBS ETF (CH) - MSCI Switzerland IMI Socially Responsible (CHF) A-dis - CH0368190739", c: '"' },
  { id: 30657, s: "CHSRIA.SW", n: "UBS ETF (CH) MSCI Switzerland IMI Socially Responsible (CHF) A-acc - CH0492935355", c: '"' },
  { id: 30658, s: "CI2.PA", n: "Amundi Index Solutions - Amundi MSCI India UCITS ETF-C EUR - LU1681043086", c: '"' },
  { id: 30659, s: "CI2U.LSE", n: "Amundi MSCI India UCITS USD - LU1681043169", c: '"' },
  { id: 30660, s: "CI2U.PA", n: "Amundi MSCI India UCITS USD - LU1681043169", c: '"' },
  { id: 30661, s: "CI3.PA", n: "Amundi ETF Euro Inflation UCITS ETF DR - FR0010754127", c: '"' },
  { id: 30662, s: "CIBR.LSE", n: "First Trust Nasdaq Cybersecurity UCITS ETF Class A USD Accumulation - IE00BF16M727", c: '"' },
  { id: 30663, s: "CIBR.US", n: "First Trust NASDAQ Cybersecurity ETF - US33734X8469", c: '"' },
  { id: 30664, s: "CIC.TO", n: "CI Canadian Banks Covered Call Income Class ETF - CA17163X4012", c: '"' },
  { id: 30665, s: "CID.US", n: "VictoryShares International High Div Volatility Wtd ETF - US92647N8810", c: '"' },
  { id: 30666, s: "CIF.TO", n: "iShares Global Infrastructure Common Class - CA46433R1001", c: '"' },
  { id: 30667, s: "CIL.US", n: "VictoryShares International Volatility Wtd ETF - US92647N8406", c: '"' },
  { id: 30668, s: "CINC.TO", n: "CI DoubleLine Income US$ Fund ETF C$ Hedged Series - CA12558U1049", c: '"' },
  { id: 30669, s: "CIND.LSE", n: "iShares VII PLC - iShares Dow Jones Indust Avg ETF USD Acc - IE00B53L4350", c: '"' },
  { id: 30670, s: "CINF.TO", n: "CI Global Infrastructure Private Pool - ETF C$ Series - CA12559B1058", c: '"' },
  { id: 30671, s: "CISB.SW", n: "iShares Smart City Infrastructure UCITS ETF USD Acc USD - IE00BKTLJC87", c: '"' },
  { id: 30672, s: "CITY.AS", n: "Ishares IV PLC - Ishares Smart City Infrastructure Ucits ETF - IE00BKTLJC87", c: '"' },
  { id: 30673, s: "CIZ.US", n: "VictoryShares Developed Enhanced Volatility Wtd ETF - US92647N8166", c: '"' },
  { id: 30674, s: "CJ1.PA", n: "Amundi Index Solutions - Amundi Index MSCI Japan ETF-C EUR - LU1602144732", c: '"' },
  { id: 30675, s: "CJ1U.LSE", n: "Amundi Solutions - Amundi MSCI Japan - LU1602144815", c: '"' },
  { id: 30676, s: "CJ1Y.PA", n: "Amundi Solutions - Amundi MSCI Japan - LU1602144815", c: '"' },
  { id: 30677, s: "CJPU.LSE", n: "iShares VII PLC - iShares MSCI Japan ETF USD Acc - IE00B53QDK08", c: '"' },
  { id: 30678, s: "CLEU.LSE", n: "Lyxor MSCI Europe ESG Climate Transition CTB (DR) UCITS ETF - Acc USD - LU2056738490", c: '"' },
  { id: 30679, s: "CLEU.SW", n: "Lyxor MSCI Europe ESG Climate Transition CTB (DR) UCITS ETF - Acc CHF - LU2056738490", c: '"' },
  { id: 30680, s: "CLF.TO", n: "iShares 1-5Yr Laddered Government Bond Common Class - CA46432E1007", c: '"' },
  { id: 30681, s: "CLG.TO", n: "iShares 1-10Yr Laddered Government Bond Common Class - CA46431U1057", c: '"' },
  { id: 30682, s: "CLIM.LSE", n: "Multi Units Luxembourg - Lyxor Green Bond (DR) UCITS ETF - LU1563454310", c: '"' },
  { id: 30683, s: "CLIM.PA", n: "Lyxor Green Bond DR UCITS C-EUR - LU1563454310", c: '"' },
  { id: 30684, s: "CLIX.US", n: "ProShares Long Online/Short Stores ETF - US74347B3758", c: '"' },
  { id: 30685, s: "CLOU.US", n: "Global X Cloud Computing - US37954Y4420", c: '"' },
  { id: 30686, s: "CLRG.US", n: "IQ Chaikin U.S. Large Cap ETF - US45409B3886", c: '"' },
  { id: 30687, s: "CLTL.US", n: "Invesco Exchange-Traded Fund Trust II - Invesco Treasury Collateral ETF - US73937B3731", c: '"' },
  { id: 30688, s: "CLWD.PA", n: "Lyxor Msci World Climate Change (Dr) Ucits ETF USD Acc - LU2056739464", c: '"' },
  { id: 30689, s: "CLWD.SW", n: "Lyxor Msci World Climate Change (Dr) Ucits ETF USD Acc CHF - LU2056739464", c: '"' },
  { id: 30690, s: "CM9.PA", n: "Amundi ETF MSCI World ex EMU UCITS ETF - FR0010756114", c: '"' },
  { id: 30691, s: "CMB1.LSE", n: "iShares FTSE MIB UCITS - IE00B53L4X51", c: '"' },
  { id: 30692, s: "CMBS.US", n: "iShares CMBS ETF - US46429B3666", c: '"' },
  { id: 30693, s: "CMDY.US", n: "iShares Bloomberg Roll Select Broad Commodity ETF - US46431W5985", c: '"' },
  { id: 30694, s: "CMF.US", n: "iShares California Muni Bond ETF - US4642883569", c: '"' },
  { id: 30695, s: "CMU.LSE", n: "Amundi Index Solutions - Amundi ETF MSCI EMU ESG Leaders Select - LU1602144575", c: '"' },
  { id: 30696, s: "CMU.PA", n: "Amundi MSCI EMU ESG Leaders Select UCITS ETF DR EUR (C) - LU1602144575", c: '"' },
  { id: 30697, s: "CMXUS.SW", n: "Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA Blue UCITS ETF B USD - IE00BJBYDR19", c: '"' },
  { id: 30698, s: "CN.US", n: "Xtrackers MSCI All China Equity ETF - US2330517622", c: '"' },
  { id: 30699, s: "CN1.LSE", n: "Amundi Index Solutions - Amundi MSCI Nordic UCITS ETF-C - LU1681044647", c: '"' },
  { id: 30700, s: "CN1.PA", n: "Amundi Index Solutions - Amundi MSCI Nordic UCITS ETF-C - LU1681044647", c: '"' },
  { id: 30701, s: "CNAA.LSE", n: "Lyxor Fortune SG UCITS MSCI China A DR C - FR0011720911", c: '"' },
  { id: 30702, s: "CNAA.XETRA", n: "Lyxor Hwabao WP MSCI China A (DR) UCITS ETF Acc - FR0011720911", c: '"' },
  { id: 30703, s: "CNAL.LSE", n: "Lyxor Fortune SG UCITS MSCI China A DR C - FR0011720911", c: '"' },
  { id: 30704, s: "CNB.PA", n: "Lyxor UCITS Euro Corporate Bond ex Financials C-EUR - LU1829218822", c: '"' },
  { id: 30705, s: "CNCR.US", n: "Loncar Cancer Immunotherapy ETF - US26922A8264", c: '"' },
  { id: 30706, s: "CNDX.AS", n: "iShares NASDAQ 100 UCITS ETF USD (Acc) - IE00B53SZB19", c: '"' },
  { id: 30707, s: "CNDX.LSE", n: "iShares NASDAQ 100 UCITS ETF USD (Acc) - IE00B53SZB19", c: '"' },
  { id: 30708, s: "CNESG.SW", n: "UBS (Lux) Fund Solutions – MSCI China ESG Universal Low Carbon Select UCITS ETF (USD) A-dis - LU1953188833", c: '"' },
  { id: 30709, s: "CNRG.US", n: "SPDR® Kensho Clean Power ETF - US78468R6559", c: '"' },
  { id: 30710, s: "CNSG.LSE", n: "UBS (Lux) Fund Solutions – MSCI China ESG Universal Low Carbon Select UCITS ETF (USD) A-dis GBP - LU1953188833", c: '"' },
  { id: 30711, s: "CNX1.LSE", n: "iShares NASDAQ 100 UCITS ETF USD (Acc) - IE00B53SZB19", c: '"' },
  { id: 30712, s: "CNXT.US", n: "VanEck ChiNext ETF - US92189F6271", c: '"' },
  { id: 30713, s: "CNY.PA", n: "HSBC MSCI China UCITS ETF - IE00B44T3H88", c: '"' },
  { id: 30714, s: "CNYA.AS", n: "iShares MSCI China A UCITS ETF USD (Acc) EUR - IE00BQT3WG13", c: '"' },
  { id: 30715, s: "CNYA.LSE", n: "iShares MSCI China A UCITS ETF USD (Acc) - IE00BQT3WG13", c: '"' },
  { id: 30716, s: "CNYA.SW", n: "iShares MSCI China A UCITS ETF USD (Acc) USD - IE00BQT3WG13", c: '"' },
  { id: 30717, s: "CNYA.US", n: "iShares MSCI China A ETF - US46434V5140", c: '"' },
  { id: 30718, s: "CNYB.AS", n: "iShares China CNY Bond UCITS ETF USD (Dist) - IE00BYPC1H27", c: '"' },
  { id: 30719, s: "CNYGB.SW", n: "UBS(Lux)Fund Solutions – J.P. Morgan CNY China Government 1-10 Year Bond UCITS ETF(USD)A-acc - LU2095995895", c: '"' },
  { id: 30720, s: "COM.US", n: "Direxion Auspice Broad Commodity Strategy ETF - US25460E3071", c: '"' },
  { id: 30721, s: "COMB.US", n: "GraniteShares Bloomberg Commodity Broad Strategy No K-1 ETF - US38747R1086", c: '"' },
  { id: 30722, s: "COMT.US", n: "iShares GSCI Commodity Dynamic Roll Strategy ETF - US46431W8534", c: '"' },
  { id: 30723, s: "COPX.US", n: "Global X Copper Miners ETF - US37954Y8306", c: '"' },
  { id: 30724, s: "CORC.SW", n: "iShares Global Corp Bond UCITS ETF CHF Hedged (Acc) - IE00BK0TB144", c: '"' },
  { id: 30725, s: "CORN.US", n: "Teucrium Corn Fund - US88166A1025", c: '"' },
  { id: 30726, s: "CORP.LSE", n: "iShares Global Corp Bond UCITS ETF USD (Dist) - IE00B7J7TB45", c: '"' },
  { id: 30727, s: "CORP.US", n: "PIMCO Investment Grade Corporate Bond Index Exchange-Traded Fund - US72201R8170", c: '"' },
  { id: 30728, s: "COUK.LSE", n: "Lyxor iBoxx GBP Liquid Corporates Long Dated UCITS ETF - D-GBP - LU1407891602", c: '"' },
  { id: 30729, s: "COVR.XETRA", n: "PIMCO Covered Bond Source UCITS Income - IE00BF8HV717", c: '"' },
  { id: 30730, s: "COW.US", n: "iPath® Series B Bloomberg Livestock Subindex Total Return - US06746P4981", c: '"' },
  { id: 30731, s: "COWZ.US", n: "Pacer US Cash Cows 100 ETF - US69374H8815", c: '"' },
  { id: 30732, s: "CP9.PA", n: "Amundi Index MSCI Pacific Ex Japen SRI PAB - UCITS ETF DR - EUR C - LU1602144906", c: '"' },
  { id: 30733, s: "CP9G.LSE", n: "Amundi MSCI Pacific ex Japan UCITS DR - LU1602145036", c: '"' },
  { id: 30734, s: "CP9U.LSE", n: "Amundi MSCI Pacific ex Japan UCITS DR - LU1602145036", c: '"' },
  { id: 30735, s: "CP9U.PA", n: "Amundi MSCI Pacific ex Japan UCITS DR - LU1602145036", c: '"' },
  { id: 30736, s: "CPD.TO", n: "iShares S&P/TSX Canadian Preferred Share Index ETF Common Class - CA46431G2053", c: '"' },
  { id: 30737, s: "CPER.US", n: "United States Copper Index Fund LP - US9117181043", c: '"' },
  { id: 30738, s: "CPI.US", n: "IQ Real Return ETF - US45409B6020", c: '"' },
  { id: 30739, s: "CPJ1.LSE", n: "iShares VII PLC - iShares Core MSCI Pac ex-Jpn ETF USD Acc - IE00B52MJY50", c: '"' },
  { id: 30740, s: "CPLS.TO", n: "CIBC Core Plus Fixed Income Pool ETF - CA12547X1024", c: '"' },
  { id: 30741, s: "CPXJ.AS", n: "iShares VII Public Limited Company - iShares Core MSCI Pacific ex Japan UCITS ETF - IE00B52MJY50", c: '"' },
  { id: 30742, s: "CPXJ.LSE", n: "iShares VII PLC - iShares Core MSCI Pac ex-Jpn ETF USD Acc - IE00B52MJY50", c: '"' },
  { id: 30743, s: "CQQQ.US", n: "Invesco China Technology ETF - US46138E8003", c: '"' },
  { id: 30744, s: "CRAK.US", n: "VanEck Oil Refiners ETF - US92189F5851", c: '"' },
  { id: 30745, s: "CRBN.US", n: "iShares MSCI ACWI Low Carbon Target ETF - US46434V4648", c: '"' },
  { id: 30746, s: "CRDT.US", n: "Simplify Exchange Traded Funds - US97717X6931", c: '"' },
  { id: 30747, s: "CRED.AU", n: "BetaShares Australian Investment Grade Corporate Bond ETF - AU0000008203", c: '"' },
  { id: 30748, s: "CRED.US", n: "Columbia ETF Trust I - US19761L1109", c: '"' },
  { id: 30749, s: "CRHG.LSE", n: "iShares Global Corp Bond UCITS ETF GBP Hedged (Dist) - IE00BDFK3G24", c: '"' },
  { id: 30750, s: "CRP.PA", n: "Lyxor UCITS Corporate Bond C-EUR - LU1829219127", c: '"' },
  { id: 30751, s: "CRPA.LSE", n: "iShares Global Corp Bond UCITS ETF USD (Acc) - IE00BFM6TB42", c: '"' },
  { id: 30752, s: "CRPH.SW", n: "iShares Global Corp Bond EUR Hedged UCITS ETF (Dist) EUR - IE00B9M6SJ31", c: '"' },
  { id: 30753, s: "CRPS.LSE", n: "iShares Global Corporate Bond UCITS - IE00B7J7TB45", c: '"' },
  { id: 30754, s: "CRPU.LSE", n: "iShares Global Corp Bond UCITS ETF USD Hedged (Acc) - IE00BF3N6Z78", c: '"' },
  { id: 30755, s: "CRPU.SW", n: "iShares Global Corp Bond UCITS ETF USD Hedged (Acc) USD - IE00BF3N6Z78", c: '"' },
  { id: 30756, s: "CRPX.LSE", n: "Lyxor UCITS Corporate Bond C-EUR - LU1829219127", c: '"' },
  { id: 30757, s: "CS1.LSE", n: "Amundi ETF MSCI Spain UCITS ETF - FR0010655746", c: '"' },
  { id: 30758, s: "CS1.PA", n: "Amundi ETF MSCI Spain UCITS ETF - FR0010655746", c: '"' },
  { id: 30759, s: "CS51.LSE", n: "iShares VII PLC - iShares Core EURO STOXX 50 ETF EUR Acc - IE00B53L3W79", c: '"' },
  { id: 30760, s: "CS9.PA", n: "Amundi Index Solutions - Amundi MSCI Europe Ex Switzerland UCITS ETF-C EUR - LU1681044308", c: '"' },
  { id: 30761, s: "CSA.US", n: "VictoryShares US Small Cap Volatility Wtd ETF - US92647N8323", c: '"' },
  { id: 30762, s: "CSB.US", n: "VictoryShares US Small Cap High Div Volatility Wtd ETF - US92647N8737", c: '"' },
  { id: 30763, s: "CSBGC0.SW", n: "iShares Swiss Domestic Government Bond 7-15 ETF (CH) - CH0016999861", c: '"' },
  { id: 30764, s: "CSBGC3.SW", n: "iShares Swiss Domestic Government Bond 0-3 ETF (CH) - CH0102530786", c: '"' },
  { id: 30765, s: "CSBGE3.SW", n: "iShares VII PLC - iShares € Govt Bond 1-3yr ETF EUR Acc - IE00B3VTMJ91", c: '"' },
  { id: 30766, s: "CSBGE7.SW", n: "iShares VII PLC - iShares € Govt Bond 3-7yr ETF EUR Acc - IE00B3VTML14", c: '"' },
  { id: 30767, s: "CSBGU0.SW", n: "iShares VII PLC - iShares $ Treasury Bd 7-10y ETF USD Acc - IE00B3VWN518", c: '"' },
  { id: 30768, s: "CSBGU3.SW", n: "iShares VII PLC - iShares $ Treasury Bd 1-3y ETF USD Acc B - IE00B3VWN179", c: '"' },
  { id: 30769, s: "CSBGU7.SW", n: "iShares $ Treasury Bond 3-7yr UCITS ETF USD (Acc) - IE00B3VWN393", c: '"' },
  { id: 30770, s: "CSCA.LSE", n: "iShares VII Public Limited Company - iShares MSCI Canada UCITS ETF - IE00B52SF786", c: '"' },
  { id: 30771, s: "CSCA.SW", n: "iShares VII PLC - iShares MSCI Canada ETF USD Acc CAD - IE00B52SF786", c: '"' },
  { id: 30772, s: "CSD.US", n: "Invesco S&P Spin-Off ETF - US46137V1594", c: '"' },
  { id: 30773, s: "CSEMAS.SW", n: "iShares VII PLC - iShares MSCI EM Asia ETF USD Acc - IE00B5L8K969", c: '"' },
  { id: 30774, s: "CSEMU.SW", n: "iShares VII PLC -iShares Core MSCI EMU UCITS ETF EUR (Acc) - IE00B53QG562", c: '"' },
  { id: 30775, s: "CSEMUS.SW", n: "iShares VII PLC - iShares MSCI EMU Small Cap ETF EUR Acc - IE00B3VWMM18", c: '"' },
  { id: 30776, s: "CSF.US", n: "VictoryShares US Discovery Enhanced Volatility Wtd ETF - US92647N7747", c: '"' },
  { id: 30777, s: "CSINDU.SW", n: "iShares VII PLC - iShares Dow Jones Indust Avg ETF USD Acc - IE00B53L4350", c: '"' },
  { id: 30778, s: "CSJP.AS", n: "iShares VII PLC - iShares MSCI Japan ETF USD Acc - IE00B53QDK08", c: '"' },
  { id: 30779, s: "CSJP.LSE", n: "iShares VII PLC - iShares MSCI Japan ETF USD Acc - IE00B53QDK08", c: '"' },
  { id: 30780, s: "CSKR.LSE", n: "iShares VII PLC - iShares MSCI Korea ETF USD Acc - IE00B5W4TY14", c: '"' },
  { id: 30781, s: "CSM.US", n: "ProShares Large Cap Core Plus - US74347R2489", c: '"' },
  { id: 30782, s: "CSMIB.SW", n: "iShares VII PLC - iShares FTSE MIB ETF EUR Acc - IE00B53L4X51", c: '"' },
  { id: 30783, s: "CSML.US", n: "IQ Chaikin U.S. Small Cap ETF - US45409B3969", c: '"' },
  { id: 30784, s: "CSNDX.SW", n: "iShares NASDAQ 100 UCITS ETF USD (Acc) - IE00B53SZB19", c: '"' },
  { id: 30785, s: "CSNKY.SW", n: "iShares VII PLC - iShares Nikkei 225 ETF JPY Acc - IE00B52MJD48", c: '"' },
  { id: 30786, s: "CSP1.LSE", n: "iShares Core S&P 500 UCITS ETF USD (Acc) - IE00B5BMR087", c: '"' },
  { id: 30787, s: "CSPE.LSE", n: "SPDR® MSCI Europe Consumer Staples UCITS ETF GBP - IE00BKWQ0D84", c: '"' },
  { id: 30788, s: "CSPX.AS", n: "iShares Core S&P 500 UCITS ETF USD (Acc) - IE00B5BMR087", c: '"' },
  { id: 30789, s: "CSPX.LSE", n: "iShares Core S&P 500 UCITS ETF USD (Acc) - IE00B5BMR087", c: '"' },
  { id: 30790, s: "CSPXJ.SW", n: "iShares VII PLC - iShares Core MSCI Pac ex-Jpn ETF USD Acc - IE00B52MJY50", c: '"' },
  { id: 30791, s: "CSSLI.SW", n: "iShares SLI® ETF (CH) - CH0031768937", c: '"' },
  { id: 30792, s: "CSSMI.SW", n: "iShares SMI® ETF (CH) - CH0008899764", c: '"' },
  { id: 30793, s: "CSSMIM.SW", n: "iShares SMIM® ETF (CH) - CH0019852802", c: '"' },
  { id: 30794, s: "CSSPX.SW", n: "iShares Core S&P 500 UCITS ETF USD (Acc) - IE00B5BMR087", c: '"' },
  { id: 30795, s: "CSSX5E.SW", n: "iShares VII PLC - iShares Core EURO STOXX 50 ETF EUR Acc - IE00B53L3W79", c: '"' },
  { id: 30796, s: "CST.PA", n: "Lyxor Index Fund - Lyxor Stoxx Europe 600 Construction & Materials UCITS ETF - LU1834983808", c: '"' },
  { id: 30797, s: "CSTP.LSE", n: "SPDR® MSCI Europe Consumer Staples UCITS ETF - IE00BKWQ0D84", c: '"' },
  { id: 30798, s: "CSUK.LSE", n: "iShares VII PLC - iShares MSCI UK ETF GBP Acc - IE00B539F030", c: '"' },
  { id: 30799, s: "CSUK.SW", n: "iShares VII PLC - iShares MSCI UK ETF GBP Acc - IE00B539F030", c: '"' },
  { id: 30800, s: "CSUKS.SW", n: "iShares VII PLC - iShares MSCI UK Small Cap ETF GBP Acc - IE00B3VWLG82", c: '"' },
  { id: 30801, s: "CSUKX.SW", n: "iShares VII PLC - iShares Core FTSE 100 ETF GBP Acc - IE00B53HP851", c: '"' },
  { id: 30802, s: "CSUS.AS", n: "iShares VII PLC - iShares MSCI USA ETF USD Acc - IE00B52SFT06", c: '"' },
  { id: 30803, s: "CSUS.LSE", n: "iShares VII PLC - iShares MSCI USA ETF USD Acc - IE00B52SFT06", c: '"' },
  { id: 30804, s: "CSUS.SW", n: "iShares VII PLC - iShares MSCI USA ETF USD Acc - IE00B52SFT06", c: '"' },
  { id: 30805, s: "CSUSS.SW", n: "iShares VII PLC - iShares MSCI USA Small Cap ESG Enhanced ETF USD Acc - IE00B3VWM098", c: '"' },
  { id: 30806, s: "CSW.PA", n: "Amundi Index Solutions - Amundi MSCI Switzerland UCITS ETF-C EUR - LU1681044720", c: '"' },
  { id: 30807, s: "CSWC.PA", n: "Amundi MSCI Switzerland UCITS - LU1681044993", c: '"' },
  { id: 30808, s: "CSWG.LSE", n: "Amundi Index Solutions - Amundi MSCI Switzerland - LU1681044993", c: '"' },
  { id: 30809, s: "CSWU.LSE", n: "Amundi Index Solutions - Amundi MSCI Switzerland - LU1681044993", c: '"' },
  { id: 30810, s: "CSX5.LSE", n: "iShares VII PLC - iShares Core EURO STOXX 50 ETF EUR Acc - IE00B53L3W79", c: '"' },
  { id: 30811, s: "CSY2.XETRA", n: "Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA ESG Leaders Blue UCITS ETF B USD EUR - IE00BJBYDP94", c: '"' },
  { id: 30812, s: "CSY8.XETRA", n: "Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA Small Cap ESG Leaders Bl UCITS ETF B USD - IE00BMDX0L03", c: '"' },
  { id: 30813, s: "CSY9.XETRA", n: "Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI World ESG Leaders Min Vol Bl UCITS ETF B USD - IE00BMDX0M10", c: '"' },
  { id: 30814, s: "CSYZ.XETRA", n: "Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) FTSE EPRA Nareit Developed Grn Bl UCITS ETF B USD - IE00BMDX0K95", c: '"' },
  { id: 30815, s: "CT2B.AS", n: "iShares Smart City Infrastructure UCITS ETF USD Inc - IE00BKTLJB70", c: '"' },
  { id: 30816, s: "CT2B.LSE", n: "iShares Smart City Infrastructure UCITS ETF USD Inc - IE00BKTLJB70", c: '"' },
  { id: 30817, s: "CTEC.US", n: "Global X CleanTech ETF - US37954Y2283", c: '"' },
  { id: 30818, s: "CU1.LSE", n: "iShares MSCI USA UCITS ETF USD (Acc) - IE00B52SFT06", c: '"' },
  { id: 30819, s: "CU1.PA", n: "Amundi ETF MSCI UK UCITS ETF - FR0010655761", c: '"' },
  { id: 30820, s: "CU2.PA", n: "Amundi Index Solutions - Amundi MSCI USA UCITS ETF-C EUR - LU1681042864", c: '"' },
  { id: 30821, s: "CU2U.LSE", n: "Amundi MSCI USA UCITS USD - LU1681042948", c: '"' },
  { id: 30822, s: "CU2U.PA", n: "Amundi MSCI USA UCITS USD - LU1681042948", c: '"' },
  { id: 30823, s: "CU31.LSE", n: "iShares VII PLC - iShares $ Treasury Bd 1-3y ETF USD Acc B - IE00B3VWN179", c: '"' },
  { id: 30824, s: "CU9.PA", n: "Amundi ETF MSCI Europe ex EMU UCITS ETF - FR0010821819", c: '"' },
  { id: 30825, s: "CUD.TO", n: "iShares US Dividend Growers Index ETF (CAD-Hedged) Common Class - CA46433G1046", c: '"' },
  { id: 30826, s: "CUK.LSE", n: "Amundi ETF MSCI UK UCITS ETF - FR0010655761", c: '"' },
  { id: 30827, s: "CUKS.LSE", n: "iShares VII PLC - iShares MSCI UK Small Cap ETF GBP Acc - IE00B3VWLG82", c: '"' },
  { id: 30828, s: "CUKX.LSE", n: "iShares VII PLC - iShares FTSE 100 ETF GBP Acc - IE00B53HP851", c: '"' },
  { id: 30829, s: "CURE.US", n: "Direxion Daily Healthcare Bull 3X Shares - US25459Y8764", c: '"' },
  { id: 30830, s: "CUS1.LSE", n: "iShares VII PLC - iShares MSCI USA Small Cap ESG Enhanced ETF USD Acc - IE00B3VWM098", c: '"' },
  { id: 30831, s: "CUSS.LSE", n: "iShares VII PLC - iShares MSCI USA Small Cap ESG Enhanced ETF USD Acc - IE00B3VWM098", c: '"' },
  { id: 30832, s: "CUT.US", n: "Invesco MSCI Global Timber ETF - US46138E5454", c: '"' },
  { id: 30833, s: "CV9.PA", n: "Amundi Index Solutions - Amundi MSCI Europe Value Factor UCITS ETF-C - LU1681042518", c: '"' },
  { id: 30834, s: "CVD.TO", n: "iShares Convertible Bond Common Class - CA46432K2056", c: '"' },
  { id: 30835, s: "CVY.US", n: "Invesco Zacks Multi-Asset Income ETF - US46137Y5006", c: '"' },
  { id: 30836, s: "CW8.PA", n: "Amundi Index Solutions - Amundi MSCI World UCITS ETF C EUR - LU1681043599", c: '"' },
  { id: 30837, s: "CW8U.LSE", n: "Amundi MSCI World UCITS USD - LU1681043672", c: '"' },
  { id: 30838, s: "CW8U.PA", n: "Amundi MSCI World UCITS USD - LU1681043672", c: '"' },
  { id: 30839, s: "CWB.US", n: "SPDR® Bloomberg Convertible Securities ETF - US78464A3591", c: '"' },
  { id: 30840, s: "CWE.PA", n: "Amundi Index Solutions - Amundi MSCI World Energy UCITS ETF-C EUR - LU1681046006", c: '"' },
  { id: 30841, s: "CWEB.US", n: "Direxion Daily CSI China Internet Bull 2X Shares - US25460E5050", c: '"' },
  { id: 30842, s: "CWEU.LSE", n: "Amundi Index Solutions - Amundi MSCI World Energy UCITS ETF-C USD - LU1681046188", c: '"' },
  { id: 30843, s: "CWEU.PA", n: "Amundi Index Solutions - Amundi MSCI World Energy UCITS ETF - LU1681046188", c: '"' },
  { id: 30844, s: "CWI.US", n: "SPDR® MSCI ACWI ex-US ETF - US78463X8487", c: '"' },
  { id: 30845, s: "CWS.US", n: "AdvisorShares Focused Equity ETF - US00768Y5603", c: '"' },
  { id: 30846, s: "CXF.TO", n: "CI Canadian Convertible Bond ETF Common - CA12554K1003", c: '"' },
  { id: 30847, s: "CXSE.US", n: "WisdomTree China ex-State-Owned Enterprises Fund - US97717X7194", c: '"' },
  { id: 30848, s: "CYB.US", n: "WisdomTree Chinese Yuan Strategy Fund - US97717W1826", c: '"' },
  { id: 30849, s: "CYBA.AS", n: "iShares China CNY Bond UCITS ETF USD Acc - IE00BKPSFD61", c: '"' },
  { id: 30850, s: "CYBP.LSE", n: "Rize UCITS ICAV - Rize Cybersecurity Data Privacy ETF GBP - IE00BJXRZJ40", c: '"' },
  { id: 30851, s: "CYBR.LSE", n: "Rize UCITS ICAV - Rize Cybersecurity Data Privacy ETF - IE00BJXRZJ40", c: '"' },
  { id: 30852, s: "CYBR.SW", n: "Rize UCITS ICAV - Rize Cybersecurity Data Privacy ETF CHF - IE00BJXRZJ40", c: '"' },
  { id: 30853, s: "CYH.TO", n: "iShares Global Monthly Dividend (CAD Hedged) Common Class - CA46433E1097", c: '"' },
  { id: 30854, s: "CZA.US", n: "Invesco Zacks Mid-Cap ETF - US46137Y4017", c: '"' },
  { id: 30855, s: "D100.XETRA", n: "MULTI-UNITS LUXEMBOURG - Lyxor FTSE 100 UCITS ETF - Inc - LU1650492256", c: '"' },
  { id: 30856, s: "D5BB.F", n: "Xtrackers II - Germany Government Bond UCITS ETF - LU0468896575", c: '"' },
  { id: 30857, s: "D5BB.XETRA", n: "Xtrackers II - Germany Government Bond UCITS ETF - LU0468896575", c: '"' },
  { id: 30858, s: "D5BC.F", n: "Xtrackers II Germany Government Bond 1-3 UCITS ETF - LU0468897110", c: '"' },
  { id: 30859, s: "D5BC.XETRA", n: "Xtrackers II Germany Government Bond 1-3 UCITS ETF - LU0468897110", c: '"' },
  { id: 30860, s: "D5BE.F", n: "Xtrackers II - US Treasuries 1-3 UCITS ETF - LU0429458895", c: '"' },
  { id: 30861, s: "D5BG.F", n: "Xtrackers II - EUR Corporate Bond UCITS ETF - LU0478205379", c: '"' },
  { id: 30862, s: "D5BG.XETRA", n: "Xtrackers II - EUR Corporate Bond UCITS ETF - LU0478205379", c: '"' },
  { id: 30863, s: "D5BH.F", n: "Xtrackers - MSCI Canada UCITS ETF - LU0476289540", c: '"' },
  { id: 30864, s: "D5BH.XETRA", n: "Xtrackers MSCI Canada ESG Screened UCITS ETF 1C - LU0476289540", c: '"' },
  { id: 30865, s: "D5BK.F", n: "Xtrackers FTSE Developed Europe Real Estate UCITS ETF 1C - LU0489337690", c: '"' },
  { id: 30866, s: "D5BK.XETRA", n: "Xtrackers FTSE Developed Europe Real Estate UCITS ETF 1C - LU0489337690", c: '"' },
  { id: 30867, s: "D5BL.F", n: "Xtrackers - MSCI Europe Value UCITS ETF - LU0486851024", c: '"' },
  { id: 30868, s: "D5BL.XETRA", n: "db x-trackers MSCI Europe Value UCITS 1C - LU0486851024", c: '"' },
  { id: 30869, s: "D5BM.F", n: "Xtrackers - S&P 500 Swap UCITS ETF - LU0490618542", c: '"' },
  { id: 30870, s: "D5BM.XETRA", n: "Xtrackers S&P 500 Swap UCITS ETF 1C - LU0490618542", c: '"' },
  { id: 30871, s: "D6RP.XETRA", n: "Deka MSCI World Climate Change ESG UCITS ETF - DE000ETFL581", c: '"' },
  { id: 30872, s: "D6RQ.XETRA", n: "Deka MSCI USA Climate Change ESG UCITS ETF - DE000ETFL573", c: '"' },
  { id: 30873, s: "D6RR.XETRA", n: "Deka MSCI Europe Climate Change ESG UCITS ETF - DE000ETFL565", c: '"' },
  { id: 30874, s: "D6RS.XETRA", n: "Deka MSCI EMU Climate Change ESG UCITS ETF - DE000ETFL557", c: '"' },
  { id: 30875, s: "D6RT.XETRA", n: "Deka MSCI Germany Climate Change ESG UCITS ETF - DE000ETFL540", c: '"' },
  { id: 30876, s: "DALI.US", n: "First Trust Dorsey Wright DALI 1 ETF - US33738R7127", c: '"' },
  { id: 30877, s: "DALT.US", n: "Anfield Capital Diversified Alternatives ETF - US90213U1152", c: '"' },
  { id: 30878, s: "DAX.PA", n: "Multi Units Luxembourg - Lyxor DAX (DR) UCITS ETF - LU0252633754", c: '"' },
  { id: 30879, s: "DAX.US", n: "Global X DAX Germany ETF - US37954Y4917", c: '"' },
  { id: 30880, s: "DAXEX.SW", n: "iShares Core DAX® UCITS ETF (DE) EUR (Acc) CHF - DE0005933931", c: '"' },
  { id: 30881, s: "DAXX.LSE", n: "MULTI-UNITS LUXEMBOURG - Lyxor DAX (DR) UCITS ETF - Acc - LU0252633754", c: '"' },
  { id: 30882, s: "DBA.US", n: "Invesco DB Agriculture Fund - US46140H1068", c: '"' },
  { id: 30883, s: "DBAW.US", n: "Xtrackers MSCI All World ex U.S. Hedged Equity ETF - US2330518208", c: '"' },
  { id: 30884, s: "DBB.US", n: "Invesco DB Base Metals Fund - US46140H7008", c: '"' },
  { id: 30885, s: "DBC.US", n: "Invesco DB Commodity Index Tracking Fund - US46138B1035", c: '"' },
  { id: 30886, s: "DBE.US", n: "Invesco DB Energy Fund - US46140H3049", c: '"' },
  { id: 30887, s: "DBEF.US", n: "Xtrackers MSCI EAFE Hedged Equity ETF - US2330512003", c: '"' },
  { id: 30888, s: "DBEH.US", n: "iMGP DBi Hedge Strategy ETF - US53700T8356", c: '"' },
  { id: 30889, s: "DBEM.US", n: "Xtrackers MSCI Emerging Markets Hedged Equity ETF - US2330511013", c: '"' },
  { id: 30890, s: "DBEU.US", n: "Xtrackers MSCI Europe Hedged Equity ETF - US2330518539", c: '"' },
  { id: 30891, s: "DBEZ.US", n: "Xtrackers MSCI Eurozone Hedged Equity ETF - US2330516970", c: '"' },
  { id: 30892, s: "DBGR.US", n: "Xtrackers MSCI Germany Hedged Equity ETF - US2330514082", c: '"' },
  { id: 30893, s: "DBJP.US", n: "Xtrackers MSCI Japan Hedged Equity ETF - US2330515071", c: '"' },
  { id: 30894, s: "DBLV.US", n: "AdvisorShares DoubleLine Value Equity ETF - US00768Y8185", c: '"' },
  { id: 30895, s: "DBMF.US", n: "iMGP DBi Managed Futures Strategy ETF - US53700T8273", c: '"' },
  { id: 30896, s: "DBO.US", n: "Invesco DB Oil Fund - US46140H4039", c: '"' },
  { id: 30897, s: "DBP.US", n: "Invesco DB Precious Metals Fund - US46140H5028", c: '"' },
  { id: 30898, s: "DBRC.LSE", n: "iShares BRIC 50 UCITS - IE00B1W57M07", c: '"' },
  { id: 30899, s: "DBX0.F", n: "Xtrackers - Portfolio UCITS ETF - LU0397221945", c: '"' },
  { id: 30900, s: "DBX0.XETRA", n: "Xtrackers - Portfolio UCITS ETF - LU0397221945", c: '"' },
  { id: 30901, s: "DBX1.F", n: "Xtrackers - MSCI Emerging Markets Swap UCITS ETF - LU0292107645", c: '"' },
  { id: 30902, s: "DBX1.XETRA", n: "Xtrackers - MSCI Emerging Markets Swap UCITS ETF - LU0292107645", c: '"' },
  { id: 30903, s: "DBX2.F", n: "Xtrackers - MSCI EM Asia Swap UCITS ETF - LU0292107991", c: '"' },
  { id: 30904, s: "DBX2.XETRA", n: "Xtrackers MSCI EM Asia Swap UCITS ETF 1C - LU0292107991", c: '"' },
  { id: 30905, s: "DBX3.F", n: "Xtrackers - MSCI EM Latin America Swap UCITS ETF - LU0292108619", c: '"' },
  { id: 30906, s: "DBX3.XETRA", n: "Xtrackers - MSCI EM Latin America Swap UCITS ETF - LU0292108619", c: '"' },
  { id: 30907, s: "DBX5.F", n: "Xtrackers - MSCI Taiwan UCITS ETF - LU0292109187", c: '"' },
  { id: 30908, s: "DBX5.XETRA", n: "Xtrackers - MSCI Taiwan UCITS ETF - LU0292109187", c: '"' },
  { id: 30909, s: "DBX6.F", n: "Xtrackers - MSCI Brazil UCITS ETF - LU0292109344", c: '"' },
  { id: 30910, s: "DBX6.XETRA", n: "Xtrackers - MSCI Brazil UCITS ETF - LU0292109344", c: '"' },
  { id: 30911, s: "DBX7.F", n: "Xtrackers - Nifty 50 Swap UCITS ETF - LU0292109690", c: '"' },
  { id: 30912, s: "DBX7.XETRA", n: "Xtrackers - Nifty 50 Swap UCITS ETF - LU0292109690", c: '"' },
  { id: 30913, s: "DBX8.F", n: "Xtrackers - MSCI Korea UCITS ETF - LU0292100046", c: '"' },
  { id: 30914, s: "DBX8.XETRA", n: "Xtrackers - MSCI Korea UCITS ETF - LU0292100046", c: '"' },
  { id: 30915, s: "DBX9.F", n: "Xtrackers - FTSE China 50 UCITS ETF - LU0292109856", c: '"' },
  { id: 30916, s: "DBX9.XETRA", n: "Xtrackers - FTSE China 50 UCITS ETF - LU0292109856", c: '"' },
  { id: 30917, s: "DBXA.XETRA", n: "Xtrackers MSCI Europe UCITS ETF 1C - LU0274209237", c: '"' },
  { id: 30918, s: "DBXD.F", n: "Xtrackers - DAX UCITS ETF - LU0274211480", c: '"' },
  { id: 30919, s: "DBXE.F", n: "Xtrackers - Euro Stoxx 50 UCITS ETF - LU0274211217", c: '"' },
  { id: 30920, s: "DBXE.XETRA", n: "Xtrackers - Euro Stoxx 50 UCITS ETF - LU0274211217", c: '"' },
  { id: 30921, s: "DBXF.F", n: "Xtrackers II - Eurozone Government Bond 15-30 UCITS ETF - LU0290357507", c: '"' },
  { id: 30922, s: "DBXF.XETRA", n: "Xtrackers II - Eurozone Government Bond 15-30 UCITS ETF - LU0290357507", c: '"' },
  { id: 30923, s: "DBXG.F", n: "Xtrackers II - Eurozone Government Bond 25+ UCITS ETF - LU0290357846", c: '"' },
  { id: 30924, s: "DBXG.XETRA", n: "Xtrackers II - Eurozone Government Bond 25+ UCITS ETF - LU0290357846", c: '"' },
  { id: 30925, s: "DBXH.F", n: "Xtrackers II Global Inflation-Linked Bond UCITS ETF - LU0290357929", c: '"' },
  { id: 30926, s: "DBXH.XETRA", n: "Xtrackers II Global Inflation-Linked Bond UCITS ETF - LU0290357929", c: '"' },
  { id: 30927, s: "DBXI.XETRA", n: "Xtrackers FTSE MIB UCITS ETF 1D - LU0274212538", c: '"' },
  { id: 30928, s: "DBXJ.F", n: "Xtrackers - MSCI Japan UCITS ETF - LU0274209740", c: '"' },
  { id: 30929, s: "DBXJ.XETRA", n: "Xtrackers - MSCI Japan UCITS ETF - LU0274209740", c: '"' },
  { id: 30930, s: "DBXK.F", n: "Xtrackers II - Eurozone Inflation-Linked Bond UCITS ETF - LU0290358224", c: '"' },
  { id: 30931, s: "DBXK.XETRA", n: "Xtrackers II - Eurozone Inflation-Linked Bond UCITS ETF - LU0290358224", c: '"' },
  { id: 30932, s: "DBXN.F", n: "Xtrackers II - Eurozone Government Bond UCITS ETF - LU0290355717", c: '"' },
  { id: 30933, s: "DBXN.XETRA", n: "Xtrackers II Eurozone Government Bond UCITS ETF 1C EUR - LU0290355717", c: '"' },
  { id: 30934, s: "DBXP.XETRA", n: "Xtrackers II - Eurozone Government Bond 1-3 UCITS ETF - LU0290356871", c: '"' },
  { id: 30935, s: "DBXQ.F", n: "Xtrackers II - Eurozone Government Bond 3-5 UCITS ETF - LU0290356954", c: '"' },
  { id: 30936, s: "DBXQ.XETRA", n: "Xtrackers II - Eurozone Government Bond 3-5 UCITS ETF - LU0290356954", c: '"' },
  { id: 30937, s: "DBXR.F", n: "Xtrackers II - Eurozone Government Bond 5-7 UCITS ETF - LU0290357176", c: '"' },
  { id: 30938, s: "DBXR.XETRA", n: "Xtrackers II - Eurozone Government Bond 5-7 UCITS ETF - LU0290357176", c: '"' },
  { id: 30939, s: "DBXS.F", n: "Xtrackers - Switzerland UCITS ETF - LU0274221281", c: '"' },
  { id: 30940, s: "DBXS.XETRA", n: "Xtrackers - Switzerland UCITS ETF - LU0274221281", c: '"' },
  { id: 30941, s: "DBXU.F", n: "Xtrackers - MSCI USA Swap UCITS ETF - LU0274210672", c: '"' },
  { id: 30942, s: "DBXU.XETRA", n: "Xtrackers MSCI USA Swap UCITS ETF 1C EUR - LU0274210672", c: '"' },
  { id: 30943, s: "DBXW.F", n: "Xtrackers - MSCI World Swap UCITS ETF - LU0274208692", c: '"' },
  { id: 30944, s: "DBXW.XETRA", n: "Xtrackers - MSCI World Swap UCITS ETF - LU0274208692", c: '"' },
  { id: 30945, s: "DBXX.F", n: "Xtrackers - FTSE 100 Income UCITS ETF - LU0292097234", c: '"' },
  { id: 30946, s: "DBXX.XETRA", n: "Xtrackers - FTSE 100 Income UCITS ETF - LU0292097234", c: '"' },
  { id: 30947, s: "DBXY.F", n: "Xtrackers - FTSE 250 UCITS ETF - LU0292097317", c: '"' },
  { id: 30948, s: "DBXY.XETRA", n: "Xtrackers - FTSE 250 UCITS ETF - LU0292097317", c: '"' },
  { id: 30949, s: "DBXZ.F", n: "Xtrackers - FTSE All-Share UCITS ETF - LU0292097747", c: '"' },
  { id: 30950, s: "DBXZ.XETRA", n: "Xtrackers - Xtrackers MSCI UK ESG UCITS ETF 1D - LU0292097747", c: '"' },
  { id: 30951, s: "DBZB.F", n: "Xtrackers II - Global Government Bond UCITS ETF - LU0378818131", c: '"' },
  { id: 30952, s: "DBZB.XETRA", n: "Xtrackers II - Global Government Bond UCITS ETF - LU0378818131", c: '"' },
  { id: 30953, s: "DCC.TO", n: "Desjardins 1-5 Year Laddered Canadian Corporate Bond Index ETF - CA25058M1095", c: '"' },
  { id: 30954, s: "DCP.TO", n: "Desjardins Canadian Preferred Share Index ETF - CA25058Y1034", c: '"' },
  { id: 30955, s: "DCS.TO", n: "Desjardins Canadian Short Term Bond Index ETF - CA25059L1004", c: '"' },
  { id: 30956, s: "DCU.TO", n: "Desjardins Canadian Universe Bond Index ETF - CA25059M1086", c: '"' },
  { id: 30957, s: "DDBB.SW", n: "iShares $ Development Bank Bonds UCITS ETF (Acc) USD - IE00BKRWN659", c: '"' },
  { id: 30958, s: "DDIV.US", n: "First Trust RBA Quality Income - US33738R6962", c: '"' },
  { id: 30959, s: "DDLS.US", n: "WisdomTree Dynamic Currency Hedged International SmallCap Equity Fund - US97717X2716", c: '"' },
  { id: 30960, s: "DDM.US", n: "ProShares Ultra Dow30 - US74347R3057", c: '"' },
  { id: 30961, s: "DDWM.US", n: "WisdomTree Dynamic Currency Hedged International Equity Fund - US97717X2633", c: '"' },
  { id: 30962, s: "DE5A.XETRA", n: "Amundi Index Solutions - Amundi Govt Bond Highest Rated Euro Investment Grade Ucits ETF EUR C - LU1681046691", c: '"' },
  { id: 30963, s: "DEAM.XETRA", n: "Invesco MDAX® UCITS ETF Acc - IE00BHJYDV33", c: '"' },
  { id: 30964, s: "DECR.XETRA", n: "Amundi Index Solutions - Amundi Index Euro AGG Corporate SRI Fund - LU1737653987", c: '"' },
  { id: 30965, s: "DEED.US", n: "First Trust TCW Securitized Plus ETF - US33740U1097", c: '"' },
  { id: 30966, s: "DEEF.US", n: "Xtrackers FTSE Developed ex US Multifactor ETF - US2330515154", c: '"' },
  { id: 30967, s: "DEEP.US", n: "Roundhill Acquirers Deep Value ETF - US26922A7019", c: '"' },
  { id: 30968, s: "DEF.US", n: "Invesco Defensive Equity ETF - US46138J7752", c: '"' },
  { id: 30969, s: "DELF.XETRA", n: "L&G Europe Equity (Responsible Exclusions) UCITS ETF Share Class EUR Accumulating - IE00BKLTRN76", c: '"' },
  { id: 30970, s: "DELG.XETRA", n: "L&G US Equity (Responsible Exclusions) UCITS ETF - IE00BKLWY790", c: '"' },
  { id: 30971, s: "DEM.LSE", n: "WisdomTree Issuer ICAV - WisdomTree Emerging Markets Equity Income UCITS ETF - IE00BQQ3Q067", c: '"' },
  { id: 30972, s: "DEM.US", n: "WisdomTree Emerging Markets High Dividend Fund - US97717W3152", c: '"' },
  { id: 30973, s: "DEMD.LSE", n: "WisdomTree Emerging Markets Equity Income UCITS ETF - IE00BQQ3Q067", c: '"' },
  { id: 30974, s: "DEMR.LSE", n: "WisdomTree Emerging Markets Equity Income UCITS Acc - IE00BDF12W49", c: '"' },
  { id: 30975, s: "DEMS.LSE", n: "WisdomTree Emerging Markets Equity Income UCITS Acc - IE00BDF12W49", c: '"' },
  { id: 30976, s: "DES.US", n: "WisdomTree U.S. SmallCap Dividend Fund - US97717W6049", c: '"' },
  { id: 30977, s: "DEUS.US", n: "Xtrackers Russell US Multifactor ETF - US2330514819", c: '"' },
  { id: 30978, s: "DEW.US", n: "WisdomTree Global High Dividend Fund - US97717W8771", c: '"' },
  { id: 30979, s: "DFAC.US", n: "Dimensional U.S. Core Equity 2 ETF - US25434V7082", c: '"' },
  { id: 30980, s: "DFAS.US", n: "Dimensional U.S. Small Cap ETF - US25434V5003", c: '"' },
  { id: 30981, s: "DFAT.US", n: "Dimensional U.S. Targeted Value ETF - US25434V6092", c: '"' },
  { id: 30982, s: "DFAX.US", n: "Dimensional World ex U.S. Core Equity 2 ETF - US25434V8809", c: '"' },
  { id: 30983, s: "DFE.LSE", n: "WisdomTree Europe SmallCap Dividend UCITS ETF - IE00BQZJC527", c: '"' },
  { id: 30984, s: "DFE.US", n: "WisdomTree Europe SmallCap Dividend Fund - US97717W8698", c: '"' },
  { id: 30985, s: "DFEA.LSE", n: "WisdomTree Europe SmallCap Dividend UCITS ETF - Acc - IE00BDF16114", c: '"' },
  { id: 30986, s: "DFEA.SW", n: "WisdomTree Europe SmallCap Dividend UCITS ETF - Acc EUR - IE00BDF16114", c: '"' },
  { id: 30987, s: "DFEE.LSE", n: "WisdomTree Issuer ICAV - WisdomTree Europe SmallCap Dividend UCITS ETF - IE00BQZJC527", c: '"' },
  { id: 30988, s: "DFEP.LSE", n: "WisdomTree Europe SmallCap Dividend UCITS ETF - Acc - IE00BDF16114", c: '"' },
  { id: 30989, s: "DFIV.US", n: "Dimensional International Value ETF - US25434V8072", c: '"' },
  { id: 30990, s: "DFJ.US", n: "WisdomTree Japan SmallCap Dividend Fund - US97717W8367", c: '"' },
  { id: 30991, s: "DFND.US", n: "Siren DIVCON Dividend Defender ETF - US8296584001", c: '"' },
  { id: 30992, s: "DFNL.US", n: "Davis Select Financial ETF - US23908L1089", c: '"' },
  { id: 30993, s: "DFOB.XETRA", n: "MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 25+Y (DR) UCITS ETF 2 - LU1686832277", c: '"' },
  { id: 30994, s: "DFUS.US", n: "Dimensional U.S. Equity ETF - US25434V4014", c: '"' },
  { id: 30995, s: "DFUV.US", n: "Dimensional US Marketwide Value ETF - US25434V7249", c: '"' },
  { id: 30996, s: "DGIT.LSE", n: "iShares Digitalisation UCITS Acc - IE00BYZK4883", c: '"' },
  { id: 30997, s: "DGRA.LSE", n: "WisdomTree US Quality Dividend Growth UCITS ETF - USD Acc - IE00BZ56RG20", c: '"' },
  { id: 30998, s: "DGRA.SW", n: "WisdomTree US Quality Dividend Growth UCITS ETF - USD Acc USD - IE00BZ56RG20", c: '"' },
  { id: 30999, s: "DGRC.TO", n: "CI WisdomTree Canada Quality Dividend Growth Index ETF - CA17165G1090", c: '"' },
  { id: 31000, s: "DGRE.US", n: "WisdomTree Emerging Markets Quality Dividend Growth Fund - US97717W3236", c: '"' },
  { id: 31001, s: "DGRG.LSE", n: "WisdomTree Issuer ICAV - WisdomTree US Quality Dividend Growth UCITS ETF - IE00BZ56RG20", c: '"' },
  { id: 31002, s: "DGRO.US", n: "iShares Core Dividend Growth ETF - US46434V6213", c: '"' },
  { id: 31003, s: "DGRP.LSE", n: "WisdomTree US Quality Dividend Growth UCITS ETF - USD - IE00BZ56RD98", c: '"' },
  { id: 31004, s: "DGRS.US", n: "WisdomTree U.S. SmallCap Quality Dividend Growth Fund - US97717X6519", c: '"' },
  { id: 31005, s: "DGRW.LSE", n: "WisdomTree US Quality Dividend Growth UCITS ETF - USD - IE00BZ56RD98", c: '"' },
  { id: 31006, s: "DGRW.SW", n: "WisdomTree US Quality Dividend Growth UCITS ETF - USD USD - IE00BZ56RD98", c: '"' },
  { id: 31007, s: "DGRW.US", n: "WisdomTree U.S. Quality Dividend Growth Fund - US97717X6691", c: '"' },
  { id: 31008, s: "DGS.US", n: "WisdomTree Emerging Markets SmallCap Dividend Fund - US97717W2816", c: '"' },
  { id: 31009, s: "DGSD.LSE", n: "WisdomTree Emerging Markets SmallCap Dividend UCITS ETF - IE00BQZJBM26", c: '"' },
  { id: 31010, s: "DGSE.LSE", n: "WisdomTree Issuer ICAV - WisdomTree Emerging Markets SmallCap Dividend UCITS ETF - IE00BQZJBM26", c: '"' },
  { id: 31011, s: "DGSE.SW", n: "WisdomTree Emerging Markets SmallCap Dividend UCITS ETF CHF - IE00BQZJBM26", c: '"' },
  { id: 31012, s: "DGT.US", n: "SPDR® Global Dow ETF - US78464A7063", c: '"' },
  { id: 31013, s: "DGTL.LSE", n: "iShares Digitalisation UCITS Acc - IE00BYZK4883", c: '"' },
  { id: 31014, s: "DH2O.LSE", n: "iShares Global Water UCITS - IE00B1TXK627", c: '"' },
  { id: 31015, s: "DHS.LSE", n: "WisdomTree US Equity Income UCITS ETF - IE00BQZJBQ63", c: '"' },
  { id: 31016, s: "DHS.US", n: "WisdomTree U.S. High Dividend Fund - US97717W2089", c: '"' },
  { id: 31017, s: "DHSA.LSE", n: "WisdomTree US Equity Income UCITS ETF - Acc - IE00BD6RZT93", c: '"' },
  { id: 31018, s: "DHSA.SW", n: "WisdomTree US Equity Income UCITS ETF - Acc USD - IE00BD6RZT93", c: '"' },
  { id: 31019, s: "DHSD.LSE", n: "WisdomTree US Equity Income UCITS ETF - IE00BQZJBQ63", c: '"' },
  { id: 31020, s: "DHSP.LSE", n: "WisdomTree US Equity Income UCITS ETF - Acc - IE00BD6RZT93", c: '"' },
  { id: 31021, s: "DHYD.AS", n: "iShares II plc $ HY Corp Bd ESG UCITS ETF USD (Dist) - IE00BKF09C98", c: '"' },
  { id: 31022, s: "DHYE.AS", n: "iShares $ High Yield Corp Bond ESG UCITS ETF USD (Acc) - IE00BJK55B31", c: '"' },
  { id: 31023, s: "DHYE.SW", n: "iShares $ High Yield Corp Bond ESG UCITS ETF USD (Acc) - IE00BJK55B31", c: '"' },
  { id: 31024, s: "DIA.AS", n: "SPDR Dow Jones Industrial Average ETF Trust - US78467X1090", c: '"' },
  { id: 31025, s: "DIA.US", n: "SPDR Dow Jones Industrial Average ETF Trust - US78467X1090", c: '"' },
  { id: 31026, s: "DIAL.US", n: "Columbia Diversified Fixed Income Allocation - US19761L5084", c: '"' },
  { id: 31027, s: "DIG.US", n: "ProShares Ultra Oil & Gas - US74347G7051", c: '"' },
  { id: 31028, s: "DIGE.LSE", n: "Lyxor Index Fund - Lyxor MSCI Digital Economy ESG Filtered (DR) UCITS ETF Acc USD - LU2023678878", c: '"' },
  { id: 31029, s: "DIGI.LSE", n: "Digital Infrastructure and Connectivity UCITS ETF - Accumulating - IE00BL643144", c: '"' },
  { id: 31030, s: "DIGI.XETRA", n: "Digital Infrastructure and Connectivity UCITS ETF - Accumulating EUR - IE00BL643144", c: '"' },
  { id: 31031, s: "DIM.US", n: "WisdomTree International MidCap Dividend Fund - US97717W7781", c: '"' },
  { id: 31032, s: "DINT.US", n: "Davis Select International ETF - US23908L4059", c: '"' },
  { id: 31033, s: "DISC.TO", n: "BMO Global Consumer Discretionary Hedged to CAD Index ETF - CA05584F1099", c: '"' },
  { id: 31034, s: "DIV.US", n: "Global X SuperDividend U.S. ETF - US37950E2919", c: '"' },
  { id: 31035, s: "DIVB.US", n: "iShares U.S. Dividend and Buyback - US46435U8615", c: '"' },
  { id: 31036, s: "DIVO.US", n: "Amplify CWP Enhanced Dividend Income ETF - US0321084099", c: '"' },
  { id: 31037, s: "DIVS.US", n: "SmartETFs Dividend Builder ETF - US4020318352", c: '"' },
  { id: 31038, s: "DIVY.US", n: "Reality Shares DIVS ETF - US75605A1088", c: '"' },
  { id: 31039, s: "DJAD.XETRA", n: "Lyxor US Treasury 10+Y (DR) UCITS ETF Dist - LU1407890620", c: '"' },
  { id: 31040, s: "DJAM.F", n: "Lyxor UCITS ETF Dow Jones Industrial Average - FR0007056841", c: '"' },
  { id: 31041, s: "DJD.US", n: "Invesco Dow Jones Industrial Average Dividend ETF - US46137V6056", c: '"' },
  { id: 31042, s: "DJEU.LSE", n: "Lyxor UCITS Dow Jones Industrial Average D-EUR - FR0007056841", c: '"' },
  { id: 31043, s: "DJMC.AS", n: "iShares EURO STOXX Mid UCITS - IE00B02KXL92", c: '"' },
  { id: 31044, s: "DJMC.LSE", n: "iShares EURO STOXX Mid UCITS - IE00B02KXL92", c: '"' },
  { id: 31045, s: "DJMC.SW", n: "iShares EURO STOXX Mid UCITS ETF EUR (Dist) CHF - IE00B02KXL92", c: '"' },
  { id: 31046, s: "DJP.US", n: "iPath® Bloomberg Commodity Index Total Return(SM) ETN - US06738C7781", c: '"' },
  { id: 31047, s: "DJRE.AU", n: "SPDR Dow Jones Global Real Estate - AU00000DJRE9", c: '"' },
  { id: 31048, s: "DJSC.AS", n: "iShares EURO STOXX Small UCITS - IE00B02KXM00", c: '"' },
  { id: 31049, s: "DJSC.LSE", n: "iShares EURO STOXX Small UCITS - IE00B02KXM00", c: '"' },
  { id: 31050, s: "DJSC.SW", n: "iShares EURO STOXX Small UCITS ETF EUR (Dist) CHF - IE00B02KXM00", c: '"' },
  { id: 31051, s: "DLN.US", n: "WisdomTree U.S. LargeCap Dividend Fund - US97717W3079", c: '"' },
  { id: 31052, s: "DLS.US", n: "WisdomTree International SmallCap Dividend Fund - US97717W7609", c: '"' },
  { id: 31053, s: "DLTM.LSE", n: "iShares II Public Limited Company - iShares MSCI EM Latin America UCITS ETF - IE00B27YCK28", c: '"' },
  { id: 31054, s: "DMDV.US", n: "AAM S&P Developed Markets High Dividend Value - US26922A3471", c: '"' },
  { id: 31055, s: "DMXF.US", n: "iShares ESG Advanced MSCI EAFE Index ETF - US46436E7590", c: '"' },
  { id: 31056, s: "DNL.US", n: "WisdomTree Global ex-U.S. Quality Dividend Growth Fund - US97717W8441", c: '"' },
  { id: 31057, s: "DOCG.LSE", n: "L&G Healthcare Breakthrough UCITS ETF - IE00BK5BC677", c: '"' },
  { id: 31058, s: "DOCT.LSE", n: "L&G Healthcare Breakthrough UCITS ETF - IE00BK5BC677", c: '"' },
  { id: 31059, s: "DOCT.SW", n: "L&G Healthcare Breakthrough UCITS ETF CHF - IE00BK5BC677", c: '"' },
  { id: 31060, s: "DOG.US", n: "ProShares Short Dow30 - US74347B2354", c: '"' },
  { id: 31061, s: "DOL.US", n: "WisdomTree International LargeCap Dividend Fund - US97717W7948", c: '"' },
  { id: 31062, s: "DON.US", n: "WisdomTree U.S. MidCap Dividend Fund - US97717W5058", c: '"' },
  { id: 31063, s: "DPST.US", n: "Direxion Daily Regional Banks Bull 3X Shares - US25460G8649", c: '"' },
  { id: 31064, s: "DPYA.LSE", n: "iShares Developed Markets Property Yield UCITS ETF USD (Acc) - IE00BFM6T921", c: '"' },
  { id: 31065, s: "DQD.TO", n: "CI WisdomTree U.S. Quality Dividend Growth Variably Hedged Index ETF - CA17166Q1063", c: '"' },
  { id: 31066, s: "DQI.TO", n: "CI WisdomTree International Quality Dividend Growth Variably Hedged Index ETF - CA17166R1047", c: '"' },
  { id: 31067, s: "DRCU.TO", n: "Desjardins RI Active Canadian Bond - Low CO2 ETF - CA25059Q1090", c: '"' },
  { id: 31068, s: "DRDR.LSE", n: "iShares Healthcare Innovation UCITS ETF USD (Acc) - IE00BYZK4776", c: '"' },
  { id: 31069, s: "DRFD.TO", n: "Desjardins RI Developed ex-USA ex-Canada Multifactor - Low CO2 ETF - CA25059J1057", c: '"' },
  { id: 31070, s: "DRFG.TO", n: "Desjardins RI Global Multifactor - Fossil Fuel Reserves Free ETF - CA25061T1093", c: '"' },
  { id: 31071, s: "DRIP.US", n: "Drum Income Plus REIT Plc - US25460G6585", c: '"' },
  { id: 31072, s: "DRMC.TO", n: "Desjardins RI Canada - Low CO2 Index ETF - CA25060H1064", c: '"' },
  { id: 31073, s: "DRMD.TO", n: "Desjardins RI Developed ex-USA ex-Canada Low CO2 Index ETF - CA25060L1076", c: '"' },
  { id: 31074, s: "DRMU.TO", n: "Desjardins RI USA - Low CO2 Index ETF - CA25060W1032", c: '"' },
  { id: 31075, s: "DRN.US", n: "Direxion Daily Real Estate Bull 3X Shares - US25459W7552", c: '"' },
  { id: 31076, s: "DRSK.US", n: "Aptus Defined Risk ETF - US26922A3885", c: '"' },
  { id: 31077, s: "DRUG.AU", n: "BetaShares Global Healthcare Currency Hedged - AU00000DRUG1", c: '"' },
  { id: 31078, s: "DRUP.XETRA", n: "Lyxor Index Fund - Lyxor MSCI Disruptive Technology ESG Filtered (DR) UCITS ETF Acc - LU2023678282", c: '"' },
  { id: 31079, s: "DRV.US", n: "Direxion Daily Real Estate Bear 3X Shares - US25460G4192", c: '"' },
  { id: 31080, s: "DRW.US", n: "WisdomTree Global ex-US Real Estate Fund - US97717W3319", c: '"' },
  { id: 31081, s: "DSI.US", n: "iShares MSCI KLD 400 Social ETF - US4642885705", c: '"' },
  { id: 31082, s: "DSTL.US", n: "Distillate US Fundamental Stability & Value - US26922A3216", c: '"' },
  { id: 31083, s: "DTD.US", n: "WisdomTree U.S. Total Dividend Fund - US97717W1099", c: '"' },
  { id: 31084, s: "DTEC.US", n: "ALPS Disruptive Technologies ETF - US00162Q4780", c: '"' },
  { id: 31085, s: "DTH.US", n: "WisdomTree International High Dividend Fund - US97717W8029", c: '"' },
  { id: 31086, s: "DTLA.LSE", n: "iShares $ Treasury Bond 20+yr UCITS ETF USD (Acc) - IE00BFM6TC58", c: '"' },
  { id: 31087, s: "DTM.AS", n: "VanEck Multi-Asset Conservative Allocation UCITS ETF - NL0009272764", c: '"' },
  { id: 31088, s: "DUG.US", n: "ProShares UltraShort Oil & Gas - US74347G3589", c: '"' },
  { id: 31089, s: "DURA.US", n: "VanEck Morningstar Durable Dividend ETF - US92189H1023", c: '"' },
  { id: 31090, s: "DUSA.US", n: "Davis Select US Equity - US23908L2079", c: '"' },
  { id: 31091, s: "DUST.US", n: "Direxion Daily Gold Miners Index Bear 2X Shares - US25460G8805", c: '"' },
  { id: 31092, s: "DVLU.US", n: "First Trust Dorsey Wright Momentum & Value ETF - US33741L2079", c: '"' },
  { id: 31093, s: "DVOL.US", n: "First Trust Dorsey Wright Momentum & Low Volatility ETF - US33741L1089", c: '"' },
  { id: 31094, s: "DVY.US", n: "iShares Select Dividend ETF - US4642871689", c: '"' },
  { id: 31095, s: "DVYA.US", n: "iShares Asia/Pacific Dividend ETF - US4642862936", c: '"' },
  { id: 31096, s: "DVYE.US", n: "iShares Emerging Markets Dividend ETF - US4642863199", c: '"' },
  { id: 31097, s: "DWAS.US", n: "Invesco DWA SmallCap Momentum ETF - US73936Q7447", c: '"' },
  { id: 31098, s: "DWAT.US", n: "Arrow DWA Tactical: Macro ETF - US0427657921", c: '"' },
  { id: 31099, s: "DWAW.US", n: "AdvisorShares Dorsey Wright FSM All Cap World ETF - US00768Y4796", c: '"' },
  { id: 31100, s: "DWCR.US", n: "Arrow DWA Tactical: International ETF - US0427656857", c: '"' },
  { id: 31101, s: "DWLD.US", n: "Davis Select Worldwide ETF - US23908L3069", c: '"' },
  { id: 31102, s: "DWM.US", n: "WisdomTree International Equity Fund - US97717W7039", c: '"' },
  { id: 31103, s: "DWMC.US", n: "AdvisorShares Dorsey Wright Micro-Cap - US00768Y5371", c: '"' },
  { id: 31104, s: "DWMF.US", n: "WisdomTree International Multifactor - US97717Y7748", c: '"' },
  { id: 31105, s: "DWUS.US", n: "AdvisorShares Dorsey Wright FSM US Core ETF - US00768Y4879", c: '"' },
  { id: 31106, s: "DWX.US", n: "SPDR® S&P International Dividend ETF - US78463X7729", c: '"' },
  { id: 31107, s: "DX2D.XETRA", n: "Xtrackers - LPX Private Equity Swap UCITS ETF - LU0322250712", c: '"' },
  { id: 31108, s: "DX2E.F", n: "Xtrackers - S&P Global Infrastructure Swap UCITS ETF - LU0322253229", c: '"' },
  { id: 31109, s: "DX2E.XETRA", n: "Xtrackers - S&P Global Infrastructure Swap UCITS ETF - LU0322253229", c: '"' },
  { id: 31110, s: "DX2G.XETRA", n: "Xtrackers - CAC 40 UCITS ETF - LU0322250985", c: '"' },
  { id: 31111, s: "DX2I.F", n: "Xtrackers - MSCI Europe Mid Cap UCITS ETF - LU0322253732", c: '"' },
  { id: 31112, s: "DX2I.XETRA", n: "Xtrackers - MSCI Europe Mid Cap UCITS ETF - LU0322253732", c: '"' },
  { id: 31113, s: "DX2J.F", n: "Xtrackers MSCI Europe Small Cap UCITS ETF - LU0322253906", c: '"' },
  { id: 31114, s: "DX2J.XETRA", n: "Xtrackers MSCI Europe Small Cap UCITS ETF - LU0322253906", c: '"' },
  { id: 31115, s: "DX2S.F", n: "Xtrackers - S&P ASX 200 UCITS ETF - LU0328474803", c: '"' },
  { id: 31116, s: "DX2S.XETRA", n: "Xtrackers - S&P ASX 200 UCITS ETF - LU0328474803", c: '"' },
  { id: 31117, s: "DX2X.F", n: "Xtrackers - Stoxx Europe 600 UCITS ETF - LU0328475792", c: '"' },
  { id: 31118, s: "DX2X.XETRA", n: "Xtrackers - Stoxx Europe 600 UCITS ETF - LU0328475792", c: '"' },
  { id: 31119, s: "DX2Z.F", n: "Xtrackers - S&P Select Frontier Swap UCITS ETF - LU0328476410", c: '"' },
  { id: 31120, s: "DX2Z.XETRA", n: "Xtrackers - S&P Select Frontier Swap UCITS ETF - LU0328476410", c: '"' },
  { id: 31121, s: "DXB.TO", n: "Dynamic Active Tactical Bond ETF - CA26801K1012", c: '"' },
  { id: 31122, s: "DXC.TO", n: "Dynamic Active Canadian Dividend ETF - CA26788T1093", c: '"' },
  { id: 31123, s: "DXD.US", n: "ProShares UltraShort Dow30 - US74347G3746", c: '"' },
  { id: 31124, s: "DXF.TO", n: "Dynamic Active Global Financial Services ETF - CA26800W1068", c: '"' },
  { id: 31125, s: "DXG.TO", n: "Dynamic Active Global Dividend ETF - CA26800B1022", c: '"' },
  { id: 31126, s: "DXGE.US", n: "WisdomTree Germany Hedged Equity Fund - US97717W4481", c: '"' },
  { id: 31127, s: "DXJ.US", n: "WisdomTree Japan Hedged Equity Fund - US97717W8516", c: '"' },
  { id: 31128, s: "DXJG.LSE", n: "WisdomTree Japan Equity UCITS ETF - JPY Acc - IE00BYQCZN58", c: '"' },
  { id: 31129, s: "DXJS.US", n: "WisdomTree Japan Hedged SmallCap Equity Fund - US97717W5215", c: '"' },
  { id: 31130, s: "DXJZ.LSE", n: "WisdomTree Japan Equity UCITS ETF - JPY Acc - IE00BYQCZN58", c: '"' },
  { id: 31131, s: "DXJZ.SW", n: "WisdomTree Japan Equity UCITS ETF - JPY Acc USD - IE00BYQCZN58", c: '"' },
  { id: 31132, s: "DXO.TO", n: "Dynamic Active Crossover Bond ETF - CA26789J1003", c: '"' },
  { id: 31133, s: "DXP.TO", n: "Dynamic Active Preferred Shares ETF - CA26800U1003", c: '"' },
  { id: 31134, s: "DXS0.F", n: "Xtrackers - SLI UCITS ETF - LU0322248146", c: '"' },
  { id: 31135, s: "DXS0.XETRA", n: "Xtrackers SLI UCITS ETF 1D EUR - LU0322248146", c: '"' },
  { id: 31136, s: "DXS5.F", n: "Xtrackers - MSCI AC Asia ex Japan Swap UCITS ETF - LU0322252171", c: '"' },
  { id: 31137, s: "DXS6.F", n: "Xtrackers - MSCI Pacific ex Japan UCITS ETF - LU0322252338", c: '"' },
  { id: 31138, s: "DXS6.XETRA", n: "Xtrackers MSCI Pacific ex Japan UCITS ETF 1C - LU0322252338", c: '"' },
  { id: 31139, s: "DXSA.F", n: "Xtrackers - Euro Stoxx Select Dividend 30 UCITS ETF - LU0292095535", c: '"' },
  { id: 31140, s: "DXSA.XETRA", n: "Xtrackers - Euro Stoxx Select Dividend 30 UCITS ETF - LU0292095535", c: '"' },
  { id: 31141, s: "DXSB.XETRA", n: "Xtrackers - Stoxx Global Select Dividend 100 Swap UCITS ETF - LU0292096186", c: '"' },
  { id: 31142, s: "DXSC.F", n: "Xtrackers Stoxx Europe 600 Basic Resources Swap UCITS ETF 1C - LU0292100806", c: '"' },
  { id: 31143, s: "DXSC.XETRA", n: "Xtrackers - Stoxx Europe 600 Basic Resources Swap UCITS ETF - LU0292100806", c: '"' },
  { id: 31144, s: "DXSE.F", n: "Xtrackers Stoxx Europe 600 Health Care Swap UCITS ETF 1C - LU0292103222", c: '"' },
  { id: 31145, s: "DXSE.XETRA", n: "Xtrackers - Stoxx Europe 600 Health Care Swap UCITS ETF - LU0292103222", c: '"' },
  { id: 31146, s: "DXSF.F", n: "Xtrackers - Stoxx Europe 600 Banks Swap UCITS ETF - LU0292103651", c: '"' },
  { id: 31147, s: "DXSF.XETRA", n: "Xtrackers - Stoxx Europe 600 Banks Swap UCITS ETF - LU0292103651", c: '"' },
  { id: 31148, s: "DXSG.F", n: "Xtrackers - Stoxx Europe 600 Telecommunications Swap UCITS ETF - LU0292104030", c: '"' },
  { id: 31149, s: "DXSG.XETRA", n: "Xtrackers - Stoxx Europe 600 Telecommunications Swap UCITS ETF - LU0292104030", c: '"' },
  { id: 31150, s: "DXSH.XETRA", n: "Xtrackers - Stoxx Europe 600 Technology Swap UCITS ETF - LU0292104469", c: '"' },
  { id: 31151, s: "DXSI.XETRA", n: "Xtrackers - Stoxx Europe 600 Utilities Swap UCITS ETF - LU0292104899", c: '"' },
  { id: 31152, s: "DXSK.F", n: "Xtrackers - Stoxx Europe 600 Food & Beverage Swap UCITS ETF - LU0292105359", c: '"' },
  { id: 31153, s: "DXSL.XETRA", n: "Xtrackers - Stoxx Europe 600 Industrial Goods Swap UCITS ETF - LU0292106084", c: '"' },
  { id: 31154, s: "DXSU.F", n: "Xtrackers II USD Emerging Markets Bond UCITS ETF 1C - EUR Hedged - LU0321462953", c: '"' },
  { id: 31155, s: "DXSU.XETRA", n: "Xtrackers II USD Emerging Markets Bond UCITS ETF 1C - EUR Hedged - LU0321462953", c: '"' },
  { id: 31156, s: "DXSW.F", n: "Xtrackers II - iBoxx Germany Covered Bond Swap UCITS ETF - LU0321463506", c: '"' },
  { id: 31157, s: "DXSW.XETRA", n: "Xtrackers II - iBoxx Germany Covered Bond Swap UCITS ETF - LU0321463506", c: '"' },
  { id: 31158, s: "DXU.TO", n: "Dynamic Active U.S. Dividend ETF - CA26801J1049", c: '"' },
  { id: 31159, s: "DXV.TO", n: "Dynamic Active Investment Grade Floating Rate ETF - CA26801N1050", c: '"' },
  { id: 31160, s: "DXZ.TO", n: "Dynamic iShares Active U.S. Mid-Cap - CA26802A1021", c: '"' },
  { id: 31161, s: "DYNF.US", n: "BlackRock US Equity Factor Rotation - US09290C1036", c: '"' },
  { id: 31162, s: "E15G.XETRA", n: "MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 15+Y (DR) UCITS ETF 2 - LU2090062782", c: '"' },
  { id: 31163, s: "E15H.XETRA", n: "Lyxor Core Euro Government Inflation-Linked Bond (DR) UCITS ETF 2 - LU1650491795", c: '"' },
  { id: 31164, s: "E20Y.SW", n: "iShares € Govt Bond 20yr Target Duration UCITS ETF EUR (Dist) - IE00BSKRJX20", c: '"' },
  { id: 31165, s: "E40.PA", n: "BNP Paribas Easy CAC 40® ESG UCITS ETF - FR0010150458", c: '"' },
  { id: 31166, s: "E50EUA.SW", n: "UBS(Lux)Fund Solutions – EURO STOXX 50 UCITS ETF(EUR)A-dis EUR - LU0136234068", c: '"' },
  { id: 31167, s: "E5ESG.SW", n: "UBS(Lux)Fund Solutions – EURO STOXX 50 ESG UCITS ETF(EUR)A-dis EUR - LU1971906802", c: '"' },
  { id: 31168, s: "E903.F", n: "Lyxor 1 - DivDAX UCITS ETF - DE000ETF9033", c: '"' },
  { id: 31169, s: "E907.F", n: "Lyxor 1 - MDAX UCITS ETF - DE000ETF9074", c: '"' },
  { id: 31170, s: "E907.XETRA", n: "Lyxor 1 MDAX (DR) UCITS ETF I - DE000ETF9074", c: '"' },
  { id: 31171, s: "E908.F", n: "Lyxor 1 - TecDAX UCITS ETF - DE000ETF9082", c: '"' },
  { id: 31172, s: "E908.XETRA", n: "Lyxor 1 TecDAX (DR) UCITS ETF I - DE000ETF9082", c: '"' },
  { id: 31173, s: "E909.SW", n: "Lyxor 1 DAX 50 ESG (DR) UCITS ETF I CHF - DE000ETF9090", c: '"' },
  { id: 31174, s: "EABE.LSE", n: "Lyxor Net Zero 2050 S&P Europe Climate PAB (DR) UCITS ETF - Acc GBP - LU2198884491", c: '"' },
  { id: 31175, s: "EABE.XETRA", n: "Lyxor Net Zero 2050 S&P Europe Climate PAB (DR) UCITS ETF - Acc - LU2198884491", c: '"' },
  { id: 31176, s: "EABG.LSE", n: "Lyxor Net Zero 2050 S&P World Climate PAB (DR) UCITS ETF - Acc GBP - LU2198882362", c: '"' },
  { id: 31177, s: "EABG.XETRA", n: "Lyxor Net Zero 2050 S&P World Climate PAB (DR) UCITS ETF - Acc - LU2198882362", c: '"' },
  { id: 31178, s: "EAGG.PA", n: "SPDR Barclays Euro Aggregate Bond UCITS - IE00B41RYL63", c: '"' },
  { id: 31179, s: "EAGG.US", n: "iShares ESG U.S. Aggregate Bond ETF - US46435U5496", c: '"' },
  { id: 31180, s: "EASG.US", n: "Xtrackers MSCI EAFE ESG Leaders Equity ETF - US2330512185", c: '"' },
  { id: 31181, s: "EBBB.PA", n: "Amundi Index Solutions - Amundi Index Euro Corp BBB 1-5 UCITS ETF DR - LU1525418643", c: '"' },
  { id: 31182, s: "EBIZ.US", n: "Global X E-commerce ETF - US37954Y4677", c: '"' },
  { id: 31183, s: "EBLU.US", n: "Ecofin Global Water ESG Fund - US56167N7536", c: '"' },
  { id: 31184, s: "EBND.US", n: "SPDR® Bloomberg Emerging Markets Local Bond ETF - US78464A3914", c: '"' },
  { id: 31185, s: "EBUY.LSE", n: "Lyxor Index Fund - Lyxor MSCI Digital Economy ESG Filtered (DR) UCITS ETF Acc GBP - LU2023678878", c: '"' },
  { id: 31186, s: "EBUY.SW", n: "Lyxor Index Fund - Lyxor MSCI Digital Economy ESG Filtered (DR) UCITS ETF Acc CHF - LU2023678878", c: '"' },
  { id: 31187, s: "EBUY.XETRA", n: "Lyxor Index Fund - Lyxor MSCI Digital Economy ESG Filtered (DR) UCITS ETF Acc - LU2023678878", c: '"' },
  { id: 31188, s: "ECAR.LSE", n: "iShares Electric Vehicles and Driving Technology UCITS ETF USD (Acc) - IE00BGL86Z12", c: '"' },
  { id: 31189, s: "ECH.US", n: "iShares MSCI Chile ETF - US4642866408", c: '"' },
  { id: 31190, s: "ECLN.US", n: "First Trust EIP Carbon Impact ETF - US33738D7057", c: '"' },
  { id: 31191, s: "ECN.PA", n: "BNP Paribas Easy Low Carbon 100 Europe PAB® UCITS ETF - LU1377382368", c: '"' },
  { id: 31192, s: "ECNS.US", n: "iShares MSCI China Small-Cap ETF - US46429B2007", c: '"' },
  { id: 31193, s: "ECOG.LSE", n: "Legal & General UCITS ETF Plc - L&G Ecommerce Logistics UCITS ETF - IE00BF0M6N54", c: '"' },
  { id: 31194, s: "ECOM.LSE", n: "L&G Ecommerce Logistics UCITS ETF - IE00BF0M6N54", c: '"' },
  { id: 31195, s: "ECON.US", n: "Columbia Emerging Markets Consumer ETF - US19762B5093", c: '"' },
  { id: 31196, s: "ECOW.US", n: "Pacer Emerging Markets Cash Cows 100 ETF - US69374H8658", c: '"' },
  { id: 31197, s: "ECR3.XETRA", n: "Amundi Index Solutions - Amundi Index Euro Corporate Sri 0-3 Y UCITS ETF DR - LU2037748774", c: '"' },
  { id: 31198, s: "ECRP.PA", n: "Amundi Index Solutions - Amundi Index Euro AGG Corporate SRI Fund - LU1437018168", c: '"' },
  { id: 31199, s: "EDC.US", n: "Direxion Daily MSCI Emerging Markets Bull 3X Shares - US25490K2814", c: '"' },
  { id: 31200, s: "EDEN.US", n: "iShares MSCI Denmark ETF - US46429B5232", c: '"' },
  { id: 31201, s: "EDEU.PA", n: "BNP Paribas Easy ESG Equity Dividend Europe UCITS ETF Capitalisation - LU1615090864", c: '"' },
  { id: 31202, s: "EDEU.XETRA", n: "BNP Paribas Easy ESG Equity Dividend Europe UCITS ETF Capitalisation - LU1615090864", c: '"' },
  { id: 31203, s: "EDG2.LSE", n: "iShares MSCI EM ESG Enhanced UCITS ETF USD Acc - IE00BHZPJ239", c: '"' },
  { id: 31204, s: "EDIV.US", n: "SPDR® S&P Emerging Markets Dividend ETF - US78463X5335", c: '"' },
  { id: 31205, s: "EDM2.SW", n: "iShares MSCI EM ESG Enhanced UCITS ETF USD Acc - IE00BHZPJ239", c: '"' },
  { id: 31206, s: "EDM2.XETRA", n: "iShares MSCI EM ESG Enhanced UCITS ETF USD Acc - IE00BHZPJ239", c: '"' },
  { id: 31207, s: "EDM4.XETRA", n: "iShares MSCI EMU ESG Enhanced UCITS ETF EUR Acc - IE00BHZPJ015", c: '"' },
  { id: 31208, s: "EDM6.SW", n: "iShares MSCI Europe ESG Enhanced UCITS ETF EUR Acc EUR - IE00BHZPJ783", c: '"' },
  { id: 31209, s: "EDM6.XETRA", n: "iShares MSCI Europe ESG Enhanced UCITS ETF EUR Acc - IE00BHZPJ783", c: '"' },
  { id: 31210, s: "EDMJ.SW", n: "iShares MSCI Japan ESG Enhanced UCITS ETF USD Acc - IE00BHZPJ452", c: '"' },
  { id: 31211, s: "EDMJ.XETRA", n: "iShares MSCI Japan ESG Enhanced UCITS ETF USD Acc - IE00BHZPJ452", c: '"' },
  { id: 31212, s: "EDMU.SW", n: "iShares MSCI USA ESG Enhanced UCITS ETF USD Acc - IE00BHZPJ908", c: '"' },
  { id: 31213, s: "EDMU.XETRA", n: "iShares MSCI USA ESG Enhanced UCITS ETF USD Acc - IE00BHZPJ908", c: '"' },
  { id: 31214, s: "EDMW.XETRA", n: "iShares MSCI World ESG Enhanced UCITS ETF USD Acc - IE00BHZPJ569", c: '"' },
  { id: 31215, s: "EDOC.US", n: "Global X Telemedicine & Digital Health ETF - US37954Y2853", c: '"' },
  { id: 31216, s: "EDOG.US", n: "ALPS Emerging Sector Dividend Dogs ETF - US00162Q6686", c: '"' },
  { id: 31217, s: "EDOW.US", n: "First Trust Dow 30 Equal Weight ETF - US33733A2015", c: '"' },
  { id: 31218, s: "EDUT.US", n: "Global X Education ETF - US37954Y7233", c: '"' },
  { id: 31219, s: "EDV.US", n: "Vanguard Extended Duration Treasury Index Fund ETF Shares - US9219107094", c: '"' },
  { id: 31220, s: "EDVD.LSE", n: "SPDR S&P Emerging Markets Dividend Aristocrats UCITS ETF - IE00B6YX5B26", c: '"' },
  { id: 31221, s: "EDZ.US", n: "Direxion Daily MSCI Emerging Markets Bear 3X Shares - US25459Y4706", c: '"' },
  { id: 31222, s: "EEA.PA", n: "BNP Paribas Easy FTSE EPRA/NAREIT Eurozone Capped UCITS ETF Cap - LU0950381748", c: '"' },
  { id: 31223, s: "EEAA.XETRA", n: "BNP Paribas Easy FTSE EPRA/NAREIT Eurozone Capped UCITS ETF Cap - LU0950381748", c: '"' },
  { id: 31224, s: "EEDG.LSE", n: "iShares MSCI USA ESG Enhanced UCITS ETF USD Inc GBP - IE00BHZPJ890", c: '"' },
  { id: 31225, s: "EEDM.LSE", n: "iShares MSCI EM ESG Enhanced UCITS ETF USD Inc - IE00BHZPJ122", c: '"' },
  { id: 31226, s: "EEDS.LSE", n: "iShares MSCI USA ESG Enhanced UCITS ETF USD Inc - IE00BHZPJ890", c: '"' },
  { id: 31227, s: "EEE.PA", n: "BNP Paribas Easy FTSE EPRA/NAREIT Eurozone Capped UCITS ETF QD Dis - LU0192223062", c: '"' },
  { id: 31228, s: "EEEP.SW", n: "BNP Paribas Easy FTSE EPRA/NAREIT Developed Europe UCITS ETF QD EUR EUR - LU1291091228", c: '"' },
  { id: 31229, s: "EEI.LSE", n: "WisdomTree Issuer ICAV - WisdomTree Europe Equity Income UCITS ETF - IE00BQZJBX31", c: '"' },
  { id: 31230, s: "EEIA.LSE", n: "WisdomTree Europe Equity Income UCITS ETF Acc - IE00BDF16007", c: '"' },
  { id: 31231, s: "EEIA.SW", n: "WisdomTree Europe Equity Income UCITS ETF Acc EUR - IE00BDF16007", c: '"' },
  { id: 31232, s: "EEIE.LSE", n: "WisdomTree Europe Equity Income UCITS ETF - IE00BQZJBX31", c: '"' },
  { id: 31233, s: "EEIP.LSE", n: "WisdomTree Europe Equity Income UCITS ETF Acc - IE00BDF16007", c: '"' },
  { id: 31234, s: "EEJD.LSE", n: "iShares MSCI Japan ESG Enhanced UCITS ETF USD Inc - IE00BHZPJ346", c: '"' },
  { id: 31235, s: "EEJG.LSE", n: "iShares MSCI Japan ESG Enhanced UCITS ETF USD Inc GBP - IE00BHZPJ346", c: '"' },
  { id: 31236, s: "EELV.US", n: "Invesco S&P Emerging Markets Low Volatility ETF - US46138E2972", c: '"' },
  { id: 31237, s: "EEM.US", n: "iShares MSCI Emerging Markets ETF - US4642872349", c: '"' },
  { id: 31238, s: "EEMA.US", n: "iShares MSCI Emerging Markets Asia ETF - US4642864262", c: '"' },
  { id: 31239, s: "EEMD.US", n: "AAM S&P Emerging Markets Sector High Dividend  - US26922A5864", c: '"' },
  { id: 31240, s: "EEMK.PA", n: "BNP Paribas Easy MSCI Emerging ESG Filtered Min TE UCITS ETF EUR Cap - LU1291097779", c: '"' },
  { id: 31241, s: "EEMO.US", n: "Invesco S&P Emerging Markets Momentum ETF - US46138E2899", c: '"' },
  { id: 31242, s: "EEMS.US", n: "iShares MSCI Emerging Markets Small-Cap ETF - US4642864759", c: '"' },
  { id: 31243, s: "EEMU.PA", n: "BNP Paribas Easy MSCI EMU Ex CW UCITS - LU1291098827", c: '"' },
  { id: 31244, s: "EEMV.US", n: "iShares MSCI Emerging Markets Min Vol Factor ETF - US4642865335", c: '"' },
  { id: 31245, s: "EEMX.US", n: "SPDR® MSCI Emerging Markets Fossil Fuel Reserves Free ETF - US78470E2054", c: '"' },
  { id: 31246, s: "EEP.F", n: "BNP Paribas Easy - FTSE EPRA/NAREIT Developed Europe - LU1291091228", c: '"' },
  { id: 31247, s: "EEP.PA", n: "BNP Paribas Easy FTSE EPRA/NAREIT Developed Europe UCITS ETF QD EUR - LU1291091228", c: '"' },
  { id: 31248, s: "EEP.XETRA", n: "BNP Paribas Easy FTSE EPRA Nareit Developed Europe ex UK Green CTB UCITS ETF Cap - LU1291091228", c: '"' },
  { id: 31249, s: "EES.US", n: "WisdomTree U.S. SmallCap Earnings Fund - US97717W5629", c: '"' },
  { id: 31250, s: "EESM.F", n: "BNP Paribas Easy - MSCI Europe Small Caps ex Controversial Weapons - LU1291101555", c: '"' },
  { id: 31251, s: "EESM.PA", n: "BNP Paribas Easy MSCI Europe Small Caps SRI S-Series PAB 5% Capped UCITS ETF Capitalisation - LU1291101555", c: '"' },
  { id: 31252, s: "EESM.XETRA", n: "BNP Paribas Easy MSCI Europe Small Caps SRI S-Series PAB 5% Capped UCITS ETF Capitalisation - LU1291101555", c: '"' },
  { id: 31253, s: "EET.US", n: "ProShares Ultra MSCI Emerging Markets - US74347X3026", c: '"' },
  { id: 31254, s: "EEUD.LSE", n: "iShares MSCI Europe ESG Enhanced UCITS ETF EUR Inc GBP - IE00BHZPJ676", c: '"' },
  { id: 31255, s: "EEUE.PA", n: "BNP Paribas Easy MSCI Europe ESG Filtered Min TE UCITS ETF Cap - LU1291099718", c: '"' },
  { id: 31256, s: "EEUX.F", n: "BNP Paribas Easy - MSCI Europe ex Controversial Weapons - LU1291099718", c: '"' },
  { id: 31257, s: "EEUX.XETRA", n: "BNP Paribas Easy MSCI Europe ESG Filtered Min TE UCITS ETF Cap - LU1291099718", c: '"' },
  { id: 31258, s: "EEV.US", n: "ProShares UltraShort MSCI Emerging Markets - US74347B2842", c: '"' },
  { id: 31259, s: "EEWD.LSE", n: "iShares MSCI World ESG Enhanced UCITS ETF USD Inc - IE00BG11HV38", c: '"' },
  { id: 31260, s: "EEWG.LSE", n: "iShares MSCI World ESG Enhanced UCITS ETF USD Inc GBP - IE00BG11HV38", c: '"' },
  { id: 31261, s: "EEX5.SW", n: "iShares € Corp Bond ex-Financials 1-5yr ESG UCITS ETF EUR (Dist) EUR - IE00B4L5ZY03", c: '"' },
  { id: 31262, s: "EFA.MX", n: "iShares Trust - iShares MSCI EAFE ETF - US4642874659", c: '"' },
  { id: 31263, s: "EFA.US", n: "iShares MSCI EAFE ETF - US4642874659", c: '"' },
  { id: 31264, s: "EFAD.US", n: "ProShares MSCI EAFE Dividend Growers ETF - US74347B8393", c: '"' },
  { id: 31265, s: "EFAS.US", n: "Global X MSCI SuperDividend® EAFE ETF - US37954Y6995", c: '"' },
  { id: 31266, s: "EFAV.US", n: "iShares MSCI EAFE Min Vol Factor ETF - US46429B6891", c: '"' },
  { id: 31267, s: "EFAX.US", n: "SPDR® MSCI EAFE Fossil Fuel Reserves Free ETF - US78470E1064", c: '"' },
  { id: 31268, s: "EFG.US", n: "iShares MSCI EAFE Growth ETF - US4642888857", c: '"' },
  { id: 31269, s: "EFIV.US", n: "SPDR® S&P 500® ESG ETF - US78468R5312", c: '"' },
  { id: 31270, s: "EFNL.US", n: "iShares MSCI Finland ETF - US46429B5158", c: '"' },
  { id: 31271, s: "EFO.US", n: "ProShares Ultra MSCI EAFE - US74347X5005", c: '"' },
  { id: 31272, s: "EFQ2.F", n: "Deka Deutsche Börse EUROGOV France UCITS ETF - DE000ETFL425", c: '"' },
  { id: 31273, s: "EFQ2.XETRA", n: "Deka Deutsche Börse EUROGOV France UCITS ETF - DE000ETFL425", c: '"' },
  { id: 31274, s: "EFQ8.XETRA", n: "Deka iBoxx EUR Liquid Non-Financials Diversified UCITS ETF - DE000ETFL383", c: '"' },
  { id: 31275, s: "EFRN.LSE", n: "iShares € Floating Rate Bond ESG UCITS ETF EUR (Dist) - IE00BF5GB717", c: '"' },
  { id: 31276, s: "EFRN.XETRA", n: "iShares € Floating Rate Bond ESG UCITS ETF EUR (Dist) - IE00BF5GB717", c: '"' },
  { id: 31277, s: "EFU.US", n: "ProShares UltraShort MSCI EAFE - US74348A4756", c: '"' },
  { id: 31278, s: "EFV.US", n: "iShares MSCI EAFE Value ETF - US4642888774", c: '"' },
  { id: 31279, s: "EFZ.US", n: "ProShares Short MSCI EAFE - US74347R3701", c: '"' },
  { id: 31280, s: "EGDM.LSE", n: "iShares MSCI EM ESG Enhanced UCITS ETF USD Inc GBP - IE00BHZPJ122", c: '"' },
  { id: 31281, s: "EGIF.TO", n: "Exemplar Growth and Income Ser ETF - CA30170L1040", c: '"' },
  { id: 31282, s: "EGMW.LSE", n: "iShares MSCI World ESG Enhanced UCITS ETF USD Acc GBP - IE00BHZPJ569", c: '"' },
  { id: 31283, s: "EGOV.PA", n: "Amundi Index Solutions - Amundi Index Bofa Merrill Lynch Euro Govies 1-10 - LU1437018598", c: '"' },
  { id: 31284, s: "EGPT.US", n: "VanEck Egypt Index ETF - US92189F7758", c: '"' },
  { id: 31285, s: "EGRA.LSE", n: "WisdomTree Eurozone Quality Dividend Growth UCITS ETF - EUR Acc - IE00BZ56TQ67", c: '"' },
  { id: 31286, s: "EGRA.SW", n: "WisdomTree Eurozone Quality Dividend Growth UCITS ETF - EUR Acc EUR - IE00BZ56TQ67", c: '"' },
  { id: 31287, s: "EGRG.LSE", n: "WisdomTree Eurozone Quality Dividend Growth UCITS ETF - EUR Acc - IE00BZ56TQ67", c: '"' },
  { id: 31288, s: "EGRP.LSE", n: "WisdomTree Eurozone Quality Dividend Growth UCITS ETF - EUR - IE00BZ56SY76", c: '"' },
  { id: 31289, s: "EGRW.LSE", n: "WisdomTree Eurozone Quality Dividend Growth UCITS ETF - EUR - IE00BZ56SY76", c: '"' },
  { id: 31290, s: "EGUSAS.SW", n: "UBS (Irl) Fund Solutions plc - MSCI Emerging Markets SF UCITS ETF (USD) A-acc - IE00B3Z3FS74", c: '"' },
  { id: 31291, s: "EGV3.XETRA", n: "MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 1-3Y (DR) UCITS ETF 2 - LU1650487926", c: '"' },
  { id: 31292, s: "EGV7.XETRA", n: "MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 5-7Y (DR) UCITS ETF 2 - LU2090062865", c: '"' },
  { id: 31293, s: "EHBA.XETRA", n: "Invesco Euro Corporate Hybrid Bond UCITS ETF Acc - IE00BKWD3B81", c: '"' },
  { id: 31294, s: "EHBD.XETRA", n: "Invesco Euro Corporate Hybrid Bond UCITS ETF Inc - IE00BKWD3966", c: '"' },
  { id: 31295, s: "EHDL.F", n: "Invesco FTSE Emerging Markets High Dividend Low Volatility UCITS ETF - IE00BYYXBF44", c: '"' },
  { id: 31296, s: "EHDL.XETRA", n: "Invesco FTSE Emerging Markets High Dividend Low Volatility UCITS ETF - IE00BYYXBF44", c: '"' },
  { id: 31297, s: "EHDV.F", n: "PowerShares EURO STOXX High Dividend Low Volatility UCITS - IE00BZ4BMM98", c: '"' },
  { id: 31298, s: "EHDV.XETRA", n: "PowerShares EURO STOXX High Dividend Low Volatility UCITS - IE00BZ4BMM98", c: '"' },
  { id: 31299, s: "EHF1.XETRA", n: "Amundi MSCI Europe High Dividend UCITS - LU1681041973", c: '"' },
  { id: 31300, s: "EHYA.AS", n: "iShares € High Yield Corp Bond ESG UCITS ETF EUR (Acc) - IE00BJK55C48", c: '"' },
  { id: 31301, s: "EHYA.SW", n: "iShares € High Yield Corp Bond ESG UCITS ETF EUR (Acc) EUR - IE00BJK55C48", c: '"' },
  { id: 31302, s: "EHYD.AS", n: "iShares II plc € HY Corp Bd ESG UCITS ETF EUR (Dist) - IE00BKLC5874", c: '"' },
  { id: 31303, s: "EIB3.XETRA", n: "Invesco Euro Government Bond 1-3 Year UCITS ETF - IE00BGJWWY63", c: '"' },
  { id: 31304, s: "EIB5.XETRA", n: "Invesco Euro Government Bond 3-5 Year UCITS ETF - IE00BGJWWV33", c: '"' },
  { id: 31305, s: "EIB7.XETRA", n: "Invesco Euro Government Bond 5-7 Year UCITS ETF - IE00BGJWWT11", c: '"' },
  { id: 31306, s: "EIBB.XETRA", n: "Invesco Euro Government Bond UCITS ETF - IE00BGJWWX56", c: '"' },
  { id: 31307, s: "EIBX.XETRA", n: "Invesco Euro Government Bond 7-10 Year UCITS ETF - IE00BGJWWW40", c: '"' },
  { id: 31308, s: "EIDO.US", n: "iShares MSCI Indonesia ETF - US46429B3096", c: '"' },
  { id: 31309, s: "EIMI.LSE", n: "iShares Core MSCI Emerging Markets IMI UCITS - IE00BKM4GZ66", c: '"' },
  { id: 31310, s: "EIMU.LSE", n: "iShares Core MSCI EM IMI UCITS ETF USD (Dist) - IE00BD45KH83", c: '"' },
  { id: 31311, s: "EINC.US", n: "VanEck Energy Income ETF - US92189H8705", c: '"' },
  { id: 31312, s: "EIRL.US", n: "iShares MSCI Ireland ETF - US46429B5075", c: '"' },
  { id: 31313, s: "EIS.US", n: "iShares MSCI Israel ETF - US4642866325", c: '"' },
  { id: 31314, s: "EISR.PA", n: "BNP Paribas Easy MSCI Emerging SRI S-Series PAB 5% Capped UCITS ETF EUR Distribution - LU1659681313", c: '"' },
  { id: 31315, s: "EJAN.US", n: "Innovator MSCI Emerging Markets Power Buffer ETF January - US45782C5168", c: '"' },
  { id: 31316, s: "EJAP.F", n: "BNP Paribas Easy - MSCI Japan ex Controversial Weapons - LU1291102447", c: '"' },
  { id: 31317, s: "EJAP.PA", n: "BNP Paribas Easy MSCI Japan ex CW UCITS ETF Cap - LU1291102447", c: '"' },
  { id: 31318, s: "EJAP.XETRA", n: "BNP Paribas Easy MSCI Japan ESG Filtered Min TE UCITS ETF Cap - LU1291102447", c: '"' },
  { id: 31319, s: "EJUL.US", n: "Innovator MSCI Emerging Markets Power Buffer ETF - July - US45782C7149", c: '"' },
  { id: 31320, s: "EKLD.PA", n: "BNP Paribas Easy - MSCI USA SRI S-Series 5% Capped - LU1291103338", c: '"' },
  { id: 31321, s: "EKLD.XETRA", n: "BNP Paribas Easy MSCI USA SRI S-Series PAB 5% Capped UCITS ETF Capitalisation - LU1291103338", c: '"' },
  { id: 31322, s: "EKUS.XETRA", n: "BNP Paribas Easy MSCI USA SRI S-Series PAB 5% Capped UCITS ETF EUR Distribution - LU1659681669", c: '"' },
  { id: 31323, s: "EL41.XETRA", n: "Deka MSCI USA MC UCITS ETF - DE000ETFL276", c: '"' },
  { id: 31324, s: "EL42.XETRA", n: "Deka MSCI Europe UCITS ETF - DE000ETFL284", c: '"' },
  { id: 31325, s: "EL43.XETRA", n: "Deka MSCI Europe MC UCITS ETF - DE000ETFL292", c: '"' },
  { id: 31326, s: "EL44.XETRA", n: "Deka MSCI Japan UCITS ETF - DE000ETFL300", c: '"' },
  { id: 31327, s: "EL45.XETRA", n: "Deka MSCI Japan Climate Change ESG UCITS ETF - DE000ETFL318", c: '"' },
  { id: 31328, s: "EL46.XETRA", n: "Deka MSCI China ex A Shares UCITS ETF - DE000ETFL326", c: '"' },
  { id: 31329, s: "EL48.F", n: "Deka iBoxx EUR Liquid Germany Covered Diversified UCITS ETF - DE000ETFL359", c: '"' },
  { id: 31330, s: "EL48.XETRA", n: "Deka iBoxx EUR Liquid Germany Covered Diversified UCITS ETF - DE000ETFL359", c: '"' },
  { id: 31331, s: "EL49.F", n: "Deka iBoxx EUR Liquid Corporates Diversified UCITS ETF - DE000ETFL375", c: '"' },
  { id: 31332, s: "EL49.XETRA", n: "Deka iBoxx EUR Liquid Corporates Diversified UCITS ETF - DE000ETFL375", c: '"' },
  { id: 31333, s: "EL4A.XETRA", n: "Deka DAX UCITS ETF - DE000ETFL011", c: '"' },
  { id: 31334, s: "EL4B.XETRA", n: "Deka EURO STOXX 50 UCITS ETF - DE000ETFL029", c: '"' },
  { id: 31335, s: "EL4C.F", n: "Deka STOXX Europe Strong Growth 20 UCITS ETF - DE000ETFL037", c: '"' },
  { id: 31336, s: "EL4C.XETRA", n: "Deka STOXX Europe Strong Growth 20 UCITS ETF - DE000ETFL037", c: '"' },
  { id: 31337, s: "EL4D.F", n: "Deka STOXX Europe Strong Value 20 UCITS ETF - DE000ETFL045", c: '"' },
  { id: 31338, s: "EL4D.XETRA", n: "Deka STOXX Europe Strong Value 20 UCITS ETF - DE000ETFL045", c: '"' },
  { id: 31339, s: "EL4E.XETRA", n: "Deka STOXX Europe Strong Style Composite 40 UCITS ETF - DE000ETFL052", c: '"' },
  { id: 31340, s: "EL4F.F", n: "Deka DAX (ausschüttend) UCITS ETF - DE000ETFL060", c: '"' },
  { id: 31341, s: "EL4F.XETRA", n: "Deka DAX (ausschüttend) UCITS ETF - DE000ETFL060", c: '"' },
  { id: 31342, s: "EL4G.XETRA", n: "Deka EURO STOXX Select Dividend 30 UCITS ETF - DE000ETFL078", c: '"' },
  { id: 31343, s: "EL4H.XETRA", n: "Deka MSCI Europe LC UCITS ETF - DE000ETFL086", c: '"' },
  { id: 31344, s: "EL4I.XETRA", n: "Deka MSCI USA LC UCITS ETF - DE000ETFL094", c: '"' },
  { id: 31345, s: "EL4J.XETRA", n: "Deka MSCI Japan LC UCITS ETF - DE000ETFL102", c: '"' },
  { id: 31346, s: "EL4K.XETRA", n: "Deka iBoxx EUR Liquid Sovereign Diversified 1-10 UCITS ETF - DE000ETFL110", c: '"' },
  { id: 31347, s: "EL4L.XETRA", n: "Deka iBoxx EUR Liquid Sovereign Diversified 1-3 UCITS ETF - DE000ETFL128", c: '"' },
  { id: 31348, s: "EL4M.XETRA", n: "Deka iBoxx EUR Liquid Sovereign Diversified 3-5 UCITS ETF - DE000ETFL136", c: '"' },
  { id: 31349, s: "EL4N.F", n: "Deka iBoxx EUR Liquid Sovereign Diversified 5-7 UCITS ETF - DE000ETFL144", c: '"' },
  { id: 31350, s: "EL4N.XETRA", n: "Deka iBoxx EUR Liquid Sovereign Diversified 5-7 UCITS ETF - DE000ETFL144", c: '"' },
  { id: 31351, s: "EL4P.XETRA", n: "Deka iBoxx EUR Liquid Sovereign Diversified 7-10 UCITS ETF - DE000ETFL151", c: '"' },
  { id: 31352, s: "EL4Q.XETRA", n: "Deka iBoxx EUR Liquid Sovereign Diversified 10+ UCITS ETF - DE000ETFL169", c: '"' },
  { id: 31353, s: "EL4R.F", n: "Deka Deutsche Börse EUROGOV Germany UCITS ETF - DE000ETFL177", c: '"' },
  { id: 31354, s: "EL4R.XETRA", n: "Deka Deutsche B&#195;&#182;rse EUROGOV Germany UCITS ETF - DE000ETFL177", c: '"' },
  { id: 31355, s: "EL4S.F", n: "Deka Deutsche Börse EUROGOV Germany 1-3 UCITS ETF - DE000ETFL185", c: '"' },
  { id: 31356, s: "EL4S.XETRA", n: "Deka Deutsche Börse EUROGOV Germany 1-3 UCITS ETF - DE000ETFL185", c: '"' },
  { id: 31357, s: "EL4T.F", n: "Deka Deutsche Börse EUROGOV Germany 3-5 UCITS ETF - DE000ETFL193", c: '"' },
  { id: 31358, s: "EL4T.XETRA", n: "Deka Deutsche Börse EUROGOV Germany 3-5 UCITS ETF - DE000ETFL193", c: '"' },
  { id: 31359, s: "EL4U.F", n: "Deka Deutsche Börse EUROGOV Germany 5-10 UCITS ETF - DE000ETFL201", c: '"' },
  { id: 31360, s: "EL4U.XETRA", n: "Deka Deutsche Börse EUROGOV Germany 5-10 UCITS ETF - DE000ETFL201", c: '"' },
  { id: 31361, s: "EL4V.F", n: "Deka Deutsche Börse EUROGOV Germany 10+ UCITS ETF - DE000ETFL219", c: '"' },
  { id: 31362, s: "EL4V.XETRA", n: "Deka Deutsche Börse EUROGOV Germany 10+ UCITS ETF - DE000ETFL219", c: '"' },
  { id: 31363, s: "EL4X.F", n: "Deka DAXplus Maximum Dividend UCITS ETF - DE000ETFL235", c: '"' },
  { id: 31364, s: "EL4X.XETRA", n: "Deka DAXplus Maximum Dividend UCITS ETF - DE000ETFL235", c: '"' },
  { id: 31365, s: "EL4Y.XETRA", n: "Deka STOXX Europe 50 UCITS ETF - DE000ETFL250", c: '"' },
  { id: 31366, s: "EL4Z.XETRA", n: "Deka MSCI USA UCITS ETF - DE000ETFL268", c: '"' },
  { id: 31367, s: "ELD.US", n: "WisdomTree Emerging Markets Local Debt Fund - US97717X8671", c: '"' },
  { id: 31368, s: "ELF0.F", n: "Deka DAX ex Financials 30 UCITS - DE000ETFL433", c: '"' },
  { id: 31369, s: "ELF0.XETRA", n: "Deka DAX ex Financials 30 UCITS - DE000ETFL433", c: '"' },
  { id: 31370, s: "ELF1.F", n: "Deka MDAX UCITS ETF - DE000ETFL441", c: '"' },
  { id: 31371, s: "ELF1.XETRA", n: "Deka MDAX® UCITS ETF - DE000ETFL441", c: '"' },
  { id: 31372, s: "ELF5.F", n: "Deka MSCI Europe ex EMU UCITS ETF - DE000ETFL458", c: '"' },
  { id: 31373, s: "ELF5.XETRA", n: "Deka MSCI Europe ex EMU UCITS ETF - DE000ETFL458", c: '"' },
  { id: 31374, s: "ELFA.F", n: "Deka EURO STOXX 50® ESG UCITS ETF - DE000ETFL466", c: '"' },
  { id: 31375, s: "ELFB.F", n: "Deka Oekom Euro Nachhaltigkeit UCITS ETF - DE000ETFL474", c: '"' },
  { id: 31376, s: "ELFB.XETRA", n: "Deka Oekom Euro Nachhaltigkeit UCITS ETF - DE000ETFL474", c: '"' },
  { id: 31377, s: "ELFC.XETRA", n: "Deka EURO iSTOXX® ex Fin Dividend+ UCITS ETF - DE000ETFL482", c: '"' },
  { id: 31378, s: "ELFD.F", n: "Deka Eurozone Rendite Plus 1-10 UCITS ETF - DE000ETFL490", c: '"' },
  { id: 31379, s: "ELFD.XETRA", n: "Deka Eurozone Rendite Plus 1-10 UCITS ETF - DE000ETFL490", c: '"' },
  { id: 31380, s: "ELFE.XETRA", n: "Deka US Treasury 7-10 UCITS ETF - DE000ETFL524", c: '"' },
  { id: 31381, s: "ELFF.XETRA", n: "Deka Euro Corporates 0-3 Liquid UCITS ETF - DE000ETFL532", c: '"' },
  { id: 31382, s: "ELFG.XETRA", n: "Deka Germany 30 UCITS ETF - DE000ETFL516", c: '"' },
  { id: 31383, s: "ELFW.XETRA", n: "Deka MSCI World UCITS ETF - DE000ETFL508", c: '"' },
  { id: 31384, s: "ELLE.LSE", n: "Lyxor Index Fund - Lyxor Global Gender Equality (DR) Ucits ETF - LU1691909508", c: '"' },
  { id: 31385, s: "ELOVD.SW", n: "UBS(Lux)Fund Solutions – Factor MSCI EMU Low Volatility UCITS ETF(EUR)A-dis EUR - LU1215454460", c: '"' },
  { id: 31386, s: "ELOW.PA", n: "SSgA SPDR ETFs Europe II Public Limited Company - SPDR EURO STOXX Low Volatility UCITS ETF - IE00BFTWP510", c: '"' },
  { id: 31387, s: "ELOW.SW", n: "SPDR® EURO STOXX Low Volatility UCITS ETF CHF - IE00BFTWP510", c: '"' },
  { id: 31388, s: "EM1C.XETRA", n: "Vaneck Vectors UCITS ETFs plc - Vaneck Vectors J.P. Morgan EM Local Currency Bond UCITS ETF - IE00BDS67326", c: '"' },
  { id: 31389, s: "EMAD.LSE", n: "SSgA SPDR ETFs Europe I Public Limited Company - SPDR MSCI EM Asia UCITS ETF - IE00B466KX20", c: '"' },
  { id: 31390, s: "EMAE.PA", n: "SPDR® MSCI EM Asia UCITS ETF - IE00B466KX20", c: '"' },
  { id: 31391, s: "EMAS.LSE", n: "SSgA SPDR ETFs Europe I Public Limited Company - SPDR MSCI EM Asia UCITS ETF - IE00B466KX20", c: '"' },
  { id: 31392, s: "EMAS.SW", n: "SPDR® MSCI EM Asia UCITS ETF CHF - IE00B466KX20", c: '"' },
  { id: 31393, s: "EMB.US", n: "iShares J.P. Morgan USD Emerging Markets Bond ETF - US4642882819", c: '"' },
  { id: 31394, s: "EMBD.US", n: "Global X Emerging Markets Bond ETF - US37954Y3505", c: '"' },
  { id: 31395, s: "EMBE.SW", n: "iShares J.P. Morgan $ EM Bond EUR Hedged UCITS ETF (Dist) EUR - IE00B9M6RS56", c: '"' },
  { id: 31396, s: "EMCA.LSE", n: "iShares J.P. Morgan $ EM Corp Bond UCITS ETF USD (Acc) - IE00BFM6TD65", c: '"' },
  { id: 31397, s: "EMCB.US", n: "WisdomTree Emerging Markets Corporate Bond Fund - US97717X7848", c: '"' },
  { id: 31398, s: "EMCP.LSE", n: "iShares J.P. Morgan $ EM Corp Bond UCITS ETF USD (Dist) - IE00B6TLBW47", c: '"' },
  { id: 31399, s: "EMCR.LSE", n: "iShares V Public Limited Company - iShares J.P. Morgan $ EM Corp Bond UCITS ETF - IE00B6TLBW47", c: '"' },
  { id: 31400, s: "EMCR.SW", n: "iShares J.P. Morgan $ EM Corp Bond UCITS ETF USD (Dist) CHF - IE00B6TLBW47", c: '"' },
  { id: 31401, s: "EMCR.US", n: "Xtrackers Emerging Markets Carbon Reduction and Climate Improvers ETF - US2330511922", c: '"' },
  { id: 31402, s: "EMDA.SW", n: "SPDR® Bloomberg Emerging Markets Local Bond UCITS ETF (Acc) CHF - IE00BFWFPY67", c: '"' },
  { id: 31403, s: "EMDD.LSE", n: "SPDR® Bloomberg Emerging Markets Local Bond UCITS ETF (Dist) - IE00B4613386", c: '"' },
  { id: 31404, s: "EMDL.LSE", n: "SSgA SPDR ETFs Europe I Public Limited Company - SPDR Barclays Emerging Markets Local Bond UCITS ETF - IE00B4613386", c: '"' },
  { id: 31405, s: "EMDL.SW", n: "SPDR® Bloomberg Emerging Markets Local Bond UCITS ETF (Dist) CHF - IE00B4613386", c: '"' },
  { id: 31406, s: "EMDV.SW", n: "SPDR S&P Emerging Markets Dividend Aristocrats UCITS ETF CHF - IE00B6YX5B26", c: '"' },
  { id: 31407, s: "EMDV.US", n: "ProShares MSCI Emerging Markets Dividend Growers ETF - US74347B8476", c: '"' },
  { id: 31408, s: "EMEC.XETRA", n: "BNP Paribas Easy ECPI Circular Economy Leaders UCITS ETF Cap - LU1953136527", c: '"' },
  { id: 31409, s: "EMES.LSE", n: "iShares J.P. Morgan ESG $ EM Bond UCITS ETF USD (Dist) - IE00BDDRDW15", c: '"' },
  { id: 31410, s: "EMFM.US", n: "Global X Next Emerging & Frontier ETF - US37950E2182", c: '"' },
  { id: 31411, s: "EMFQ.US", n: "Amplify ETF Trust - Amplify Emerging Markets FinTech ETF - US0321088702", c: '"' },
  { id: 31412, s: "EMGA.LSE", n: "iShares J.P. Morgan EM Local Govt Bond UCITS ETF USD (Acc) - IE00BFZPF546", c: '"' },
  { id: 31413, s: "EMGF.US", n: "iShares MSCI Emerging Markets Multifactor ETF - US46434G8895", c: '"' },
  { id: 31414, s: "EMGU.LSE", n: "iShares Core MSCI EM IMI UCITS ETF USD (Dist) GBP - IE00BD45KH83", c: '"' },
  { id: 31415, s: "EMH5.LSE", n: "SPDR BofA Merrill Lynch 0-5 Emerging Markets Government Bond UCITS - IE00BP46NG52", c: '"' },
  { id: 31416, s: "EMH5.SW", n: "SPDR® ICE BofA 0-5 Year EM USD Government Bond UCITS ETF CHF - IE00BP46NG52", c: '"' },
  { id: 31417, s: "EMHD.LSE", n: "Invesco FTSE Emerging Markets High Dividend Low Volatility UCITS ETF - IE00BYYXBF44", c: '"' },
  { id: 31418, s: "EMHD.PA", n: "Invesco Markets III plc - Invesco FTSE Emerging Markets High Dividend Low Volatility UCITS ETF - IE00BYYXBF44", c: '"' },
  { id: 31419, s: "EMHY.US", n: "iShares J.P. Morgan EM High Yield Bond ETF - US4642862852", c: '"' },
  { id: 31420, s: "EMID.LSE", n: "iShares MSCI Europe Mid Cap UCITS ETF EUR (Dist) - IE00BYXYX745", c: '"' },
  { id: 31421, s: "EMIE.XETRA", n: "UBS(Lux)Fund Solutions – J.P. Morgan USD EM IG ESG Diversified Bond UCITS ETF(hedged EUR)A-acc - LU1974696418", c: '"' },
  { id: 31422, s: "EMIF.US", n: "iShares Emerging Markets Infrastructure ETF - US4642882165", c: '"' },
  { id: 31423, s: "EMIG.XETRA", n: "UBS(Lux)Fund Solutions – J.P. Morgan USD EM IG ESG Diversified Bond UCITS ETF(USD)A-acc - LU1974695790", c: '"' },
  { id: 31424, s: "EMIM.AS", n: "iShares Core MSCI Emerging Markets IMI UCITS - IE00BKM4GZ66", c: '"' },
  { id: 31425, s: "EMIM.LSE", n: "iShares Core MSCI Emerging Markets IMI UCITS - IE00BKM4GZ66", c: '"' },
  { id: 31426, s: "EMKT.AU", n: "VanEck MSCI Multifactor Emerging Markets Eq ETF - AU0000004368", c: '"' },
  { id: 31427, s: "EMKX.F", n: "BNP Paribas Easy - MSCI Emerging Markets ex Controversial Weapons - LU1291097779", c: '"' },
  { id: 31428, s: "EMKX.XETRA", n: "BNP Paribas Easy MSCI Emerging ESG Filtered Min TE UCITS ETF EUR Cap - LU1291097779", c: '"' },
  { id: 31429, s: "EMLB.LSE", n: "PIMCO Emerging Markets Advantage Local Bond Source UCITS - IE00B4P11460", c: '"' },
  { id: 31430, s: "EMLB.SW", n: "PIMCO Emerging Markets Advantage Local Bond UCITS ETF Acc USD USD - IE00B4P11460", c: '"' },
  { id: 31431, s: "EMLC.LSE", n: "VanEck J.P. Morgan EM Loc Curr Bd UCITS ETF USD A - IE00BDS67326", c: '"' },
  { id: 31432, s: "EMLC.SW", n: "VanEck J.P. Morgan EM Local Currency Bond UCITS ETF A USD CHF - IE00BDS67326", c: '"' },
  { id: 31433, s: "EMLC.US", n: "VanEck J.P. Morgan EM Local Currency Bond ETF - US92189H3003", c: '"' },
  { id: 31434, s: "EMLI.LSE", n: "PIMCO Emerging Markets Advantage Local Bond Source UCITS EUR - IE00BH3X8336", c: '"' },
  { id: 31435, s: "EMLI.SW", n: "PIMCO Emerging Markets Advantage Local Bond UCITS ETF Inc USD USD - IE00BH3X8336", c: '"' },
  { id: 31436, s: "EMLOC.SW", n: "UBS(Lux)Fund Solutions – J.P. Morgan EM Multi-Factor Enhanced Local Currency Bd UCITS ETF(USD)A-dis - LU1720938841", c: '"' },
  { id: 31437, s: "EMLOCA.SW", n: "UBS(Lux)Fund Solutions – J.P. Morgan EM Multi-Factor Enhanced Local Currency Bd UCITS ETF(USD)A-acc - LU1720938924", c: '"' },
  { id: 31438, s: "EMLP.LSE", n: "PIMCO ETFs plc - PIMCO Emerging Markets Advantage Local Bond Index UCITS ETF - IE00B4P11460", c: '"' },
  { id: 31439, s: "EMLP.US", n: "First Trust North American Energy Infrastructure Fund - US33738D1019", c: '"' },
  { id: 31440, s: "EMLV.SW", n: "iShares Edge MSCI EM Minimum Volatility UCITS ETF USD (Acc) CHF - IE00B8KGV557", c: '"' },
  { id: 31441, s: "EMMF.US", n: "WisdomTree Emerging Markets Multifactor Fund - US97717Y7821", c: '"' },
  { id: 31442, s: "EMMUKD.SW", n: "UBS(Lux)Fund Solutions – MSCI Emerging Markets UCITS ETF(USD)A-UKdis - LU1126036976", c: '"' },
  { id: 31443, s: "EMMUSA.SW", n: "UBS(Lux)Fund Solutions – MSCI Emerging Markets UCITS ETF(USD)A-dis CHF - LU0480132876", c: '"' },
  { id: 31444, s: "EMMUSC.SW", n: "UBS(Lux)Fund Solutions – MSCI Emerging Markets UCITS ETF(USD)A-acc - LU0950674175", c: '"' },
  { id: 31445, s: "EMMV.LSE", n: "iShares Edge MSCI EM Minimum Volatility UCITS ETF - IE00B8KGV557", c: '"' },
  { id: 31446, s: "EMND.XETRA", n: "iShares MSCI World ESG Enhanced UCITS ETF USD Inc - IE00BG11HV38", c: '"' },
  { id: 31447, s: "EMNE.XETRA", n: "iShares MSCI EMU ESG Enhanced UCITS ETF EUR Inc - IE00BHZPHZ28", c: '"' },
  { id: 31448, s: "EMNJ.XETRA", n: "iShares MSCI Japan ESG Enhanced UCITS ETF USD Inc - IE00BHZPJ346", c: '"' },
  { id: 31449, s: "EMNU.XETRA", n: "iShares MSCI Europe ESG Enhanced UCITS ETF EUR Inc - IE00BHZPJ676", c: '"' },
  { id: 31450, s: "EMOM.PA", n: "BNP Paribas Easy ESG Equity Momentum Europe UCITS ETF Cap - LU1377382012", c: '"' },
  { id: 31451, s: "EMOM.SW", n: "BNP Paribas Easy ESG Equity Momentum Europe UCITS ETF Cap EUR - LU1377382012", c: '"' },
  { id: 31452, s: "EMQP.LSE", n: "EMQQ Emerging Markets Internet & Ecommerce UCITS ETF - Accumulating - IE00BFYN8Y92", c: '"' },
  { id: 31453, s: "EMQQ.LSE", n: "EMQQ Emerging Markets Internet & Ecommerce UCITS ETF - Accumulating - IE00BFYN8Y92", c: '"' },
  { id: 31454, s: "EMQQ.SW", n: "EMQQ Emerging Markets Internet & Ecommerce UCITS ETF - Accumulating CHF - IE00BFYN8Y92", c: '"' },
  { id: 31455, s: "EMQQ.US", n: "EMQQ The Emerging Markets Internet & Ecommerce ETF - US3015058890", c: '"' },
  { id: 31456, s: "EMQQ.XETRA", n: "HANetf ICAV - EMQQ Emerging Markets Internet & Ecommerce UCITS ETF - IE00BFYN8Y92", c: '"' },
  { id: 31457, s: "EMRD.LSE", n: "SSgA SPDR MSCI Emerging Markets UCITS - IE00B469F816", c: '"' },
  { id: 31458, s: "EMRG.PA", n: "SSgA SPDR MSCI Emerging Markets UCITS - IE00B469F816", c: '"' },
  { id: 31459, s: "EMSA.AS", n: "Ishares II PLC - Ishares J.P. Morgan ESG Emerging Bond Ucits ETF - IE00BF553838", c: '"' },
  { id: 31460, s: "EMSA.LSE", n: "iShares J.P. Morgan ESG $ EM Bond UCITS ETF USD (Acc) - IE00BF553838", c: '"' },
  { id: 31461, s: "EMSB.XETRA", n: "PowerShares Emerging Markets USD Bond - IE00BF51K132", c: '"' },
  { id: 31462, s: "EMSD.LSE", n: "SSgA SPDR MSCI Emerging Markets Small Cap - IE00B48X4842", c: '"' },
  { id: 31463, s: "EMSG.US", n: "DBX ETF Trust - Xtrackers MSCI Emerging Markets ESG Leaders Equity ETF - US2330512268", c: '"' },
  { id: 31464, s: "EMSM.XETRA", n: "Invesco Markets plc - Invesco MSCI Emerging Markets UCITS ETF - IE00B3DWVS88", c: '"' },
  { id: 31465, s: "EMSR.PA", n: "BNP Paribas Easy MSCI Emerging SRI S-Series PAB 5% Capped UCITS ETF Capitalisation - LU1291098314", c: '"' },
  { id: 31466, s: "EMSRI.PA", n: "Amundi Index MSCI Emerging Markets SRI UCITS - LU1861138961", c: '"' },
  { id: 31467, s: "EMTL.US", n: "SPDR® DoubleLine® Emerging Markets Fixed Income ETF - US78470P3091", c: '"' },
  { id: 31468, s: "EMUAA.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU UCITS ETF(EUR)A-acc - LU0950668870", c: '"' },
  { id: 31469, s: "EMUD.LSE", n: "iShares MSCI EMU ESG Enhanced UCITS ETF EUR Inc - IE00BHZPHZ28", c: '"' },
  { id: 31470, s: "EMUEUA.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU UCITS ETF(EUR)A-dis EUR - LU0147308422", c: '"' },
  { id: 31471, s: "EMUL.SW", n: "iShares MSCI EMU Large Cap UCITS ETF EUR (Acc) EUR - IE00BCLWRF22", c: '"' },
  { id: 31472, s: "EMUM.SW", n: "iShares MSCI EMU Mid Cap UCITS ETF EUR (Acc) EUR - IE00BCLWRD08", c: '"' },
  { id: 31473, s: "EMUS.XETRA", n: "BNP Paribas Easy MSCI EMU SRI S-Series PAB 5% Capped UCITS ETF Capitalisation - LU1953137681", c: '"' },
  { id: 31474, s: "EMUSRI.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU Socially Responsible UCITS ETF(EUR)A-acc - LU0950674761", c: '"' },
  { id: 31475, s: "EMUUKD.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU UCITS ETF(EUR)A-Ukdis - LU1107559376", c: '"' },
  { id: 31476, s: "EMUX.F", n: "BNP Paribas Easy - MSCI EMU ex Controversial Weapons - LU1291098827", c: '"' },
  { id: 31477, s: "EMUX.XETRA", n: "BNP Paribas Easy MSCI EMU Ex CW UCITS - LU1291098827", c: '"' },
  { id: 31478, s: "EMV.LSE", n: "iShares VI Public Limited Company - iShares Edge MSCI EM Minimum Volatility UCITS ETF - IE00B8KGV557", c: '"' },
  { id: 31479, s: "EMVEUA.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU Value UCITS ETF(EUR)A-dis - LU0446734369", c: '"' },
  { id: 31480, s: "EMVL.LSE", n: "iShares Edge MSCI EM Value Factor UCITS ETF USD (Acc) - IE00BG0SKF03", c: '"' },
  { id: 31481, s: "EMWE.PA", n: "BNP Paribas Easy MSCI World SRI S-Series PAB 5% Capped UCITS ETF EUR Capitalisation - LU1615092217", c: '"' },
  { id: 31482, s: "EMWE.XETRA", n: "BNP Paribas Easy MSCI World SRI S-Series PAB 5% Capped UCITS ETF EUR Capitalisation - LU1615092217", c: '"' },
  { id: 31483, s: "EMXC.US", n: "iShares MSCI Emerging Markets ex China - US46434G7640", c: '"' },
  { id: 31484, s: "EMXF.US", n: "iShares ESG Advanced MSCI EM ETF - US46436E7426", c: '"' },
  { id: 31485, s: "ENAM.PA", n: "BNP Paribas Easy MSCI North America ESG Filtered Min TE UCITS ETF Cap - LU1291104575", c: '"' },
  { id: 31486, s: "ENCC.TO", n: "Horizons Canadian Oil and Gas Equity Covered Call ETF - CA44056V1022", c: '"' },
  { id: 31487, s: "ENFR.US", n: "Alerian Energy Infrastructure ETF - US00162Q6769", c: '"' },
  { id: 31488, s: "ENGE.LSE", n: "SPDR® MSCI Europe Energy UCITS ETF GBP - IE00BKWQ0F09", c: '"' },
  { id: 31489, s: "ENGW.LSE", n: "SPDR® MSCI World Energy UCITS ETF GBP - IE00BYTRR863", c: '"' },
  { id: 31490, s: "ENGY.LSE", n: "SPDR® MSCI Europe Energy UCITS ETF - IE00BKWQ0F09", c: '"' },
  { id: 31491, s: "ENOA.F", n: "BNP Paribas Easy - MSCI North America ex Controversial Weapons - LU1291104575", c: '"' },
  { id: 31492, s: "ENOA.XETRA", n: "BNP Paribas Easy MSCI North America ESG Filtered Min TE UCITS ETF Cap - LU1291104575", c: '"' },
  { id: 31493, s: "ENOR.US", n: "iShares MSCI Norway ETF - US46429B4995", c: '"' },
  { id: 31494, s: "ENTR.US", n: "ERShares Entrepreneurs ETF - US2938288022", c: '"' },
  { id: 31495, s: "ENZL.US", n: "iShares MSCI New Zealand ETF - US4642891232", c: '"' },
  { id: 31496, s: "EPAB.SW", n: "Lyxor Net Zero 2050 S&P Eurozone Climate PAB (DR) UCITS ETF - Acc CHF - LU2195226068", c: '"' },
  { id: 31497, s: "EPEJ.PA", n: "BNP Paribas Easy MSCI Pacific ex Japan ESG Filtered Min TE UCITS ETF Cap - LU1291106356", c: '"' },
  { id: 31498, s: "EPHE.US", n: "iShares MSCI Philippines ETF - US46429B4086", c: '"' },
  { id: 31499, s: "EPI.US", n: "WisdomTree India Earnings Fund - US97717W4226", c: '"' },
  { id: 31500, s: "EPOL.US", n: "iShares MSCI Poland ETF - US46429B6065", c: '"' },
  { id: 31501, s: "EPP.MX", n: "iShares Inc. - iShares MSCI Pacific ex Japan ETF - US4642866655", c: '"' },
  { id: 31502, s: "EPP.US", n: "iShares MSCI Pacific ex Japan ETF - US4642866655", c: '"' },
  { id: 31503, s: "EPRA.LSE", n: "Amundi Index Solutions - Amundi Index FTSE EPRA NAREIT Global UCITS ETF DR - LU1437018838", c: '"' },
  { id: 31504, s: "EPRA.PA", n: "Amundi Index Solutions - Amundi Index FTSE EPRA NAREIT Global UCITS ETF DR - LU1437018838", c: '"' },
  { id: 31505, s: "EPRE.LSE", n: "Amundi Index Solutions - Amundi FTSE EPRA Europe Real Estate UCITS ETF-C - LU1681039480", c: '"' },
  { id: 31506, s: "EPRE.PA", n: "Amundi Index Solutions - Amundi FTSE EPRA Europe Real Estate UCITS ETF-C - LU1681039480", c: '"' },
  { id: 31507, s: "EPRF.US", n: "Innovator S&P Investment Grade Preferred ETF - US45783Y8223", c: '"' },
  { id: 31508, s: "EPS.US", n: "WisdomTree U.S. Earnings 500 Fund - US97717W5884", c: '"' },
  { id: 31509, s: "EPU.US", n: "iShares MSCI Peru ETF - US4642898427", c: '"' },
  { id: 31510, s: "EPV.US", n: "ProShares UltraShort FTSE Europe - US74348A4343", c: '"' },
  { id: 31511, s: "EPVLD.SW", n: "UBS(Lux)Fund Solutions – Factor MSCI EMU Prime Value UCITS ETF(EUR)A-dis EUR - LU1215452928", c: '"' },
  { id: 31512, s: "EQAC.SW", n: "Invesco EQQQ NASDAQ-100 UCITS ETF Acc - IE00BFZXGZ54", c: '"' },
  { id: 31513, s: "EQAL.US", n: "Invesco Russell 1000 Equal Weight ETF - US46138E4200", c: '"' },
  { id: 31514, s: "EQDS.LSE", n: "iShares MSCI Europe Quality Dividend ESG UCITS ETF EUR (Dist) - IE00BYYHSM20", c: '"' },
  { id: 31515, s: "EQL.US", n: "ALPS Equal Sector Weight ETF - US00162Q2057", c: '"' },
  { id: 31516, s: "EQLTD.SW", n: "UBS(Lux)Fund Solutions – Factor MSCI EMU Quality UCITS ETF(EUR)A-dis EUR - LU1215451524", c: '"' },
  { id: 31517, s: "EQQB.XETRA", n: "Invesco EQQQ NASDAQ-100 UCITS ETF Acc - IE00BFZXGZ54", c: '"' },
  { id: 31518, s: "EQQQ.F", n: "PowerShares Global Funds Ireland Public Limited Company - PowerShares EQQQ Nasdaq-100 UCITS ETF - IE0032077012", c: '"' },
  { id: 31519, s: "EQQQ.LSE", n: "Invesco EQQQ NASDAQ-100 UCITS ETF - IE0032077012", c: '"' },
  { id: 31520, s: "EQQQ.PA", n: "Invesco EQQQ NASDAQ-100 UCITS ETF - IE0032077012", c: '"' },
  { id: 31521, s: "EQQQ.XETRA", n: "Invesco EQQQ NASDAQ-100 UCITS ETF - IE0032077012", c: '"' },
  { id: 31522, s: "EQQU.LSE", n: "Invesco EQQQ NASDAQ-100 UCITS ETF - IE0032077012", c: '"' },
  { id: 31523, s: "EQRR.US", n: "ProShares Equities for Rising Rates ETF - US74347B3915", c: '"' },
  { id: 31524, s: "EQUA.PA", n: "BNP Paribas Easy ESG Equity Quality Europe UCITS ETF Cap - LU1377382103", c: '"' },
  { id: 31525, s: "EQWL.US", n: "Invesco S&P 100 Equal Weight ETF - US46137V4499", c: '"' },
  { id: 31526, s: "ERN1.LSE", n: "iShares Euro Ultrashort Bond UCITS - IE00BCRY6557", c: '"' },
  { id: 31527, s: "ERNA.LSE", n: "iShares $ Ultrashort Bond UCITS USD Acc - IE00BGCSB447", c: '"' },
  { id: 31528, s: "ERND.AS", n: "iShares USD Ultrashort Bond UCITS - IE00BCRY6227", c: '"' },
  { id: 31529, s: "ERND.LSE", n: "iShares USD Ultrashort Bond UCITS - IE00BCRY6227", c: '"' },
  { id: 31530, s: "ERND.SW", n: "iShares $ Ultrashort Bond UCITS ETF USD (Dist) CHF - IE00BCRY6227", c: '"' },
  { id: 31531, s: "ERNE.AS", n: "iShares Euro Ultrashort Bond UCITS - IE00BCRY6557", c: '"' },
  { id: 31532, s: "ERNE.LSE", n: "iShares IV Public Limited Company - iShares Euro Ultrashort Bond UCITS ETF - IE00BCRY6557", c: '"' },
  { id: 31533, s: "ERNE.SW", n: "iShares € Ultrashort Bond UCITS ETF EUR (Dist) CHF - IE00BCRY6557", c: '"' },
  { id: 31534, s: "ERNS.LSE", n: "iShares £ Ultrashort Bond UCITS ETF - IE00BCRY6441", c: '"' },
  { id: 31535, s: "ERNU.LSE", n: "iShares USD Ultrashort Bond UCITS - IE00BCRY6227", c: '"' },
  { id: 31536, s: "ERO.LSE", n: "SPDR MSCI Europe UCITS ETF - IE00BKWQ0Q14", c: '"' },
  { id: 31537, s: "ERO.PA", n: "SPDR MSCI Europe UCITS ETF - IE00BKWQ0Q14", c: '"' },
  { id: 31538, s: "EROX.SW", n: "SPDR® MSCI Europe UCITS ETF CHF - IE00BKWQ0Q14", c: '"' },
  { id: 31539, s: "ERSX.US", n: "ERShares NextGen Entrepreneurs ETF - US2938288857", c: '"' },
  { id: 31540, s: "ERTH.US", n: "Invesco MSCI Sustainable Future ETF - US46137V4077", c: '"' },
  { id: 31541, s: "ERX.US", n: "Direxion Daily Energy Bull 2X Shares - US25460G6098", c: '"' },
  { id: 31542, s: "ERY.US", n: "Direxion Daily Energy Bear 2X Shares - US25460E5548", c: '"' },
  { id: 31543, s: "ES15.LSE", n: "iShares Corporate Bond 0-5 UCITS - IE00B5L65R35", c: '"' },
  { id: 31544, s: "ESCEUA.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU Small Cap UCITS ETF(EUR)A-dis EUR - LU0671493277", c: '"' },
  { id: 31545, s: "ESCR.US", n: "Xtrackers Bloomberg US Investment Grade Corporate ESG ETF - US2330517390", c: '"' },
  { id: 31546, s: "ESD.PA", n: "BNP Paribas Easy S&P 500 UCITS C - FR0011550177", c: '"' },
  { id: 31547, s: "ESDD.PA", n: "BNP Paribas Easy S&P 500 UCITS H - FR0011550680", c: '"' },
  { id: 31548, s: "ESDG.LSE", n: "Amundi Index Solutions - Amundi MSCI Europe SRI PAB UCITS ETF DR D GBP - LU2059756598", c: '"' },
  { id: 31549, s: "ESDU.LSE", n: "Amundi Index Solutions - Amundi MSCI Europe SRI PAB UCITS ETF DR D USD - LU2059756598", c: '"' },
  { id: 31550, s: "ESE.PA", n: "EasyETF - BNP Paribas Easy S&P 500 UCITS ETF - FR0011550185", c: '"' },
  { id: 31551, s: "ESEB.US", n: "Xtrackers J.P. Morgan ESG Emerging Markets Sovereign ETF - US2330517135", c: '"' },
  { id: 31552, s: "ESEE.F", n: "BNP Paribas Easy S&P 500 UCITS ETF EUR C - FR0011550185", c: '"' },
  { id: 31553, s: "ESEE.XETRA", n: "BNP Paribas Easy S&P 500 UCITS ETF EUR C - FR0011550185", c: '"' },
  { id: 31554, s: "ESG.US", n: "FlexShares STOXX US ESG Select Index Fund - US33939L6965", c: '"' },
  { id: 31555, s: "ESGA.US", n: "American Century Sustainable Equity ETF - US0250727528", c: '"' },
  { id: 31556, s: "ESGC.TO", n: "Invesco S&P/TSX Composite ESG Index ETF - CA46143X1087", c: '"' },
  { id: 31557, s: "ESGD.US", n: "iShares ESG Aware MSCI EAFE ETF - US46435G5163", c: '"' },
  { id: 31558, s: "ESGE.LSE", n: "Invesco MSCI Europe ESG Universal Screened UCITS ETF GBP - IE00BJQRDL90", c: '"' },
  { id: 31559, s: "ESGE.US", n: "iShares ESG Aware MSCI EM ETF - US46434G8630", c: '"' },
  { id: 31560, s: "ESGE.XETRA", n: "Invesco Markets II PLC - Invesco Msci Europe Esg Universal Screened Ucits Etf - IE00BJQRDL90", c: '"' },
  { id: 31561, s: "ESGEMD.SW", n: "UBS(Lux)Fund Solutions – J.P. Morgan USD EM IG ESG Diversified Bond UCITS ETF(USD)A-acc - LU1974695790", c: '"' },
  { id: 31562, s: "ESGEME.SW", n: "UBS(Lux)Fund Solutions – J.P. Morgan USD EM IG ESG Diversified Bond UCITS ETF(hedged EUR)A-acc EUR - LU1974696418", c: '"' },
  { id: 31563, s: "ESGEMU.SW", n: "UBS(Lux)Fund Solutions – J.P. Morgan EMU Government ESG Liquid Bond UCITS ETF(EUR)A-acc EUR - LU2194286006", c: '"' },
  { id: 31564, s: "ESGG.LSE", n: "Invesco MSCI World ESG Universal Screened UCITS ETF GBP - IE00BJQRDK83", c: '"' },
  { id: 31565, s: "ESGG.US", n: "FlexShares STOXX Global ESG Select Index Fund - US33939L6882", c: '"' },
  { id: 31566, s: "ESGGBD.SW", n: "UBS(Lux)Fund Solutions – J.P. Morgan Global Government ESG Liquid Bond UCITS ETF(hedged USD)A-acc - LU1974694041", c: '"' },
  { id: 31567, s: "ESGGBS.SW", n: "UBS(Lux)Fund Solutions – J.P. Morgan Global Government ESG Liquid Bond UCITS ETF(hedged CHF)A-acc - LU1974694397", c: '"' },
  { id: 31568, s: "ESGI.AU", n: "VanEck MSCI International Sustainable Equity ETF - AU0000001356", c: '"' },
  { id: 31569, s: "ESGL.LSE", n: "Lyxor MSCI Europe ESG Leaders (DR) UCITS ETF - Acc GBP - LU1940199711", c: '"' },
  { id: 31570, s: "ESGL.SW", n: "Lyxor MSCI Europe ESG Leaders (DR) UCITS ETF - Acc CHF - LU1940199711", c: '"' },
  { id: 31571, s: "ESGN.US", n: "Columbia Sustainable International Equity Income ETF - US19761L2016", c: '"' },
  { id: 31572, s: "ESGS.US", n: "Columbia Sustainable U.S. Equity Income ETF - US19761L3006", c: '"' },
  { id: 31573, s: "ESGU.LSE", n: "Invesco MSCI USA ESG Universal Screened UCITS ETF - IE00BJQRDM08", c: '"' },
  { id: 31574, s: "ESGU.US", n: "iShares ESG Aware MSCI USA ETF - US46435G4257", c: '"' },
  { id: 31575, s: "ESGU.XETRA", n: "Invesco MSCI USA ESG Universal Screened UCITS ETF - IE00BJQRDM08", c: '"' },
  { id: 31576, s: "ESGV.US", n: "Vanguard ESG US Stock - US9219107334", c: '"' },
  { id: 31577, s: "ESGW.LSE", n: "Invesco MSCI World ESG Universal Screened UCITS ETF - IE00BJQRDK83", c: '"' },
  { id: 31578, s: "ESGW.XETRA", n: "Invesco Markets II PLC - Invesco MSCI World ESG Universal Screened UCITS ETF - IE00BJQRDK83", c: '"' },
  { id: 31579, s: "ESHY.US", n: "Xtrackers J.P. Morgan ESG USD High Yield Corporate Bond ETF - US2330517473", c: '"' },
  { id: 31580, s: "ESML.US", n: "iShares ESG Aware MSCI USA Small-Cap ETF - US46435U6635", c: '"' },
  { id: 31581, s: "ESPO.LSE", n: "VanEck Vectors Video Gaming and eSports UCITS ETF A USD - IE00BYWQWR46", c: '"' },
  { id: 31582, s: "ESPO.US", n: "VanEck Video Gaming and eSports ETF - US92189F1140", c: '"' },
  { id: 31583, s: "ESREUA.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU Socially Responsible UCITS ETF(EUR)A-dis EUR - LU0629460675", c: '"' },
  { id: 31584, s: "ESRG.LSE", n: "Amundi Index Solutions - Amundi MSCI Europe SRI PAB UCITS ETF DR C GBP - LU1861137484", c: '"' },
  { id: 31585, s: "ESRI.XETRA", n: "BNP Paribas Easy -MSCI Emerging SRI S-Series 5% Capped - LU1291098314", c: '"' },
  { id: 31586, s: "ESRU.LSE", n: "Amundi Index Solutions - Amundi MSCI Europe SRI PAB UCITS ETF DR C USD - LU1861137484", c: '"' },
  { id: 31587, s: "ESSN.SW", n: "Rize Sustainable Future of Food UCITS ETF A USD CHF - IE00BLRPQH31", c: '"' },
  { id: 31588, s: "ESTX.AU", n: "ANZS EURO STOXX 50 - AU00000ESTX4", c: '"' },
  { id: 31589, s: "ETBB.PA", n: "BNP Paribas Easy Euro Stoxx 50 UCITS ETF EUR C/D - FR0012740983", c: '"' },
  { id: 31590, s: "ETBB.SW", n: "BNP Paribas Easy Euro Stoxx 50 UCITS ETF EUR C/D EUR - FR0012740983", c: '"' },
  { id: 31591, s: "ETBB.XETRA", n: "BNP Paribas Easy Euro Stoxx 50 UCITS ETF EUR C/D - FR0012740983", c: '"' },
  { id: 31592, s: "ETDD.PA", n: "BNP Paribas Easy Euro Stoxx 50 UCITS ETF EUR C - FR0012739431", c: '"' },
  { id: 31593, s: "ETDD.SW", n: "BNP Paribas Easy Euro Stoxx 50 UCITS ETF EUR C EUR - FR0012739431", c: '"' },
  { id: 31594, s: "ETDD.XETRA", n: "BNP Paribas Easy Euro Stoxx 50 UCITS ETF EUR C - FR0012739431", c: '"' },
  { id: 31595, s: "ETHI.TO", n: "Horizons Global Sustainability Leaders Index ETF - CA44054C1068", c: '"' },
  { id: 31596, s: "ETHO.US", n: "Etho Climate Leadership U.S. ETF - US26924G8886", c: '"' },
  { id: 31597, s: "ETLH.XETRA", n: "L&G Ecommerce Logistics UCITS ETF - IE00BF0M6N54", c: '"' },
  { id: 31598, s: "ETLI.XETRA", n: "L&G Pharma Breakthrough UCITS ETF - IE00BF0H7608", c: '"' },
  { id: 31599, s: "ETLK.XETRA", n: "L&G Asia Pacific ex Japan Equity UCITS ETF - IE00BFXR5W90", c: '"' },
  { id: 31600, s: "ETLN.XETRA", n: "Legal & General UCITS ETF Plc - L&G Europe Ex UK Equity UCITS ETF - IE00BFXR5V83", c: '"' },
  { id: 31601, s: "ETLQ.XETRA", n: "L&G Global Equity UCITS ETF - IE00BFXR5S54", c: '"' },
  { id: 31602, s: "ETLR.XETRA", n: "L&G Japan Equity UCITS ETF EUR - IE00BFXR5T61", c: '"' },
  { id: 31603, s: "ETLS.XETRA", n: "L&G US Equity UCITS ETF - IE00BFXR5Q31", c: '"' },
  { id: 31604, s: "ETLX.XETRA", n: "L&G Gold Mining UCITS ETF - DE000A0Q8NC8", c: '"' },
  { id: 31605, s: "ETP.TO", n: "First Trust Global Risk Managed Income Common - CA33739T1057", c: '"' },
  { id: 31606, s: "ETSZ.F", n: "Easy ETF - BNP Paribas Easy STOXX Europe 600 UCITS ETF - FR0011550193", c: '"' },
  { id: 31607, s: "ETSZ.XETRA", n: "BNP Paribas Easy Stoxx Europe 600 UCITS ETF EUR C - FR0011550193", c: '"' },
  { id: 31608, s: "ETZ.PA", n: "BNP Paribas Easy Stoxx Europe 600 UCITS ETF EUR C - FR0011550193", c: '"' },
  { id: 31609, s: "ETZD.PA", n: "BNP Paribas Easy Stoxx Europe 600 UCITS H - FR0011550672", c: '"' },
  { id: 31610, s: "EUCO.LSE", n: "SPDR Barclays Euro Corporate Bond UCITS - IE00B3T9LM79", c: '"' },
  { id: 31611, s: "EUCO.PA", n: "SPDR Barclays Euro Corporate Bond UCITS - IE00B3T9LM79", c: '"' },
  { id: 31612, s: "EUDG.US", n: "WisdomTree Europe Quality Dividend Growth Fund - US97717X6105", c: '"' },
  { id: 31613, s: "EUDI.LSE", n: "SPDR® S&P Euro Dividend Aristocrats UCITS ETF - IE00B5M1WJ87", c: '"' },
  { id: 31614, s: "EUDV.LSE", n: "SPDR® S&P Euro Dividend Aristocrats UCITS ETF - IE00B5M1WJ87", c: '"' },
  { id: 31615, s: "EUDV.PA", n: "SSgA SPDR ETFs Europe I Public Limited Company - SPDR S&P Euro Dividend Aristocrats UCITS ETF - IE00B5M1WJ87", c: '"' },
  { id: 31616, s: "EUDV.SW", n: "SPDR® S&P Euro Dividend Aristocrats UCITS ETF CHF - IE00B5M1WJ87", c: '"' },
  { id: 31617, s: "EUDV.US", n: "ProShares MSCI Europe Dividend Growers ETF - US74347B5407", c: '"' },
  { id: 31618, s: "EUE.LSE", n: "iShares Core EURO STOXX 50 UCITS ETF EUR (Dist) - IE0008471009", c: '"' },
  { id: 31619, s: "EUEA.AS", n: "iShares II Public Limited Company - iShares EURO STOXX 50 UCITS ETF - IE0008471009", c: '"' },
  { id: 31620, s: "EUED.XETRA", n: "iShares € Ultrashort Bond ESG UCITS ETF EUR Inc - IE00BJP26D89", c: '"' },
  { id: 31621, s: "EUFM.LSE", n: "UBS(Lux)Fund Solutions – MSCI EMU Select Factor Mix UCITS ETF(EUR)A-acc - LU1804202403", c: '"' },
  { id: 31622, s: "EUFM.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU Select Factor Mix UCITS ETF(EUR)A-acc EUR - LU1804202403", c: '"' },
  { id: 31623, s: "EUFN.US", n: "iShares MSCI Europe Financials ETF - US4642891802", c: '"' },
  { id: 31624, s: "EUHA.SW", n: "PIMCO Euro Short-Term High Yield Corporate Bond UCITS ETF EUR Accumulation EUR - IE00BD8D5G25", c: '"' },
  { id: 31625, s: "EUHD.LSE", n: "PowerShares EURO STOXX High Dividend Low Volatility UCITS - IE00BZ4BMM98", c: '"' },
  { id: 31626, s: "EUHD.PA", n: "PowerShares EURO STOXX High Dividend Low Volatility UCITS - IE00BZ4BMM98", c: '"' },
  { id: 31627, s: "EUHD.SW", n: "Invesco EURO STOXX High Dividend Low Volatility UCITS ETF CHF - IE00BZ4BMM98", c: '"' },
  { id: 31628, s: "EUHI.SW", n: "PIMCO Euro Short-Term High Yield Corporate Bond UCITS ETF EUR Income EUR - IE00BD8D5H32", c: '"' },
  { id: 31629, s: "EUHI.XETRA", n: "PIMCO Euro Short-Term High Yield Corporate Bond UCITS ETF EUR Income - IE00BD8D5H32", c: '"' },
  { id: 31630, s: "EUM.US", n: "ProShares Short MSCI Emerging Markets - US74347R3966", c: '"' },
  { id: 31631, s: "EUMD.LSE", n: "iShares MSCI Europe Mid Cap UCITS ETF EUR (Acc) - IE00BF20LF40", c: '"' },
  { id: 31632, s: "EUMV.LSE", n: "Ossiam Europe ESG Machine Learning ETF UCITS 1C (EUR) - LU0599612842", c: '"' },
  { id: 31633, s: "EUMV.PA", n: "Ossiam Europe ESG Machine Learning ETF UCITS 1C (EUR) - LU0599612842", c: '"' },
  { id: 31634, s: "EUN.LSE", n: "iShares STOXX Europe 50 UCITS - IE0008470928", c: '"' },
  { id: 31635, s: "EUN.SW", n: "iShares STOXX Europe 50 UCITS ETF EUR (Dist) CHF - IE0008470928", c: '"' },
  { id: 31636, s: "EUN0.F", n: "iShares Edge MSCI Europe Minimum Volatility UCITS ETF EUR (Acc) - IE00B86MWN23", c: '"' },
  { id: 31637, s: "EUN0.XETRA", n: "iShares Edge MSCI Europe Minimum Volatility UCITS ETF EUR (Acc) EUR - IE00B86MWN23", c: '"' },
  { id: 31638, s: "EUN1.F", n: "iShares STOXX Europe 50 UCITS ETF - IE0008470928", c: '"' },
  { id: 31639, s: "EUN1.XETRA", n: "iShares STOXX Europe 50 UCITS ETF - IE0008470928", c: '"' },
  { id: 31640, s: "EUN2.F", n: "iShares II Public Limited Company - iShares EURO STOXX 50 UCITS ETF - IE0008471009", c: '"' },
  { id: 31641, s: "EUN2.XETRA", n: "iShares II Public Limited Company - iShares EURO STOXX 50 UCITS ETF - IE0008471009", c: '"' },
  { id: 31642, s: "EUN3.F", n: "iShares III Public Limited Company - iShares Global Government Bond UCITS ETF - IE00B3F81K65", c: '"' },
  { id: 31643, s: "EUN3.XETRA", n: "iShares Global Government Bond UCITS - IE00B3F81K65", c: '"' },
  { id: 31644, s: "EUN4.F", n: "iShares III Public Limited Company - iShares Euro Aggregate Bond UCITS ETF - IE00B3DKXQ41", c: '"' },
  { id: 31645, s: "EUN4.XETRA", n: "iShares Aggregate Bond UCITS - IE00B3DKXQ41", c: '"' },
  { id: 31646, s: "EUN5.F", n: "iShares III Public Limited Company - iShares Core Euro Corporate Bond UCITS ETF - IE00B3F81R35", c: '"' },
  { id: 31647, s: "EUN5.XETRA", n: "iShares Core Corporate Bond UCITS - IE00B3F81R35", c: '"' },
  { id: 31648, s: "EUN6.F", n: "iShares III Public Limited Company - iShares Euro Government Bond 0-1yr UCITS ETF - IE00B3FH7618", c: '"' },
  { id: 31649, s: "EUN6.XETRA", n: "iShares III Public Limited Company - iShares Euro Government Bond 0-1yr UCITS ETF - IE00B3FH7618", c: '"' },
  { id: 31650, s: "EUN8.F", n: "iShares III Public Limited Company - iShares Euro Government Bond 10-15yr UCITS ETF - IE00B4WXJH41", c: '"' },
  { id: 31651, s: "EUN8.XETRA", n: "iShares € Govt Bond 10-15yr UCITS ETF EUR (Dist) - IE00B4WXJH41", c: '"' },
  { id: 31652, s: "EUN9.F", n: "iShares III Public Limited Company - iShares Euro Government Bond 5-7yr UCITS ETF - IE00B4WXJG34", c: '"' },
  { id: 31653, s: "EUN9.XETRA", n: "iShares € Govt Bond 5-7yr UCITS ETF EUR (Dist) - IE00B4WXJG34", c: '"' },
  { id: 31654, s: "EUNA.XETRA", n: "iShares Core Global Aggregate Bond UCITS ETF - IE00BDBRDM35", c: '"' },
  { id: 31655, s: "EUNH.F", n: "iShares III Public Limited Company - iShares Core Euro Government Bond UCITS ETF - IE00B4WXJJ64", c: '"' },
  { id: 31656, s: "EUNH.XETRA", n: "iShares Core € Govt Bond UCITS ETF EUR (Dist) - IE00B4WXJJ64", c: '"' },
  { id: 31657, s: "EUNI.F", n: "iShares III Public Limited Company - iShares MSCI EM Small Cap UCITS ETF - IE00B3F81G20", c: '"' },
  { id: 31658, s: "EUNI.XETRA", n: "iShares MSCI Emerging Market Small Cap UCITS - IE00B3F81G20", c: '"' },
  { id: 31659, s: "EUNJ.XETRA", n: "iShares MSCI Pacific ex-Japan UCITS - IE00B4WXJD03", c: '"' },
  { id: 31660, s: "EUNK.F", n: "iShares III Public Limited Company - iShares MSCI Europe UCITS ETF EUR (Acc) - IE00B4K48X80", c: '"' },
  { id: 31661, s: "EUNK.XETRA", n: "iShares Core MSCI Europe UCITS ETF EUR (Acc) - IE00B4K48X80", c: '"' },
  { id: 31662, s: "EUNL.F", n: "iShares Core MSCI World UCITS ETF USD (Acc) - IE00B4L5Y983", c: '"' },
  { id: 31663, s: "EUNL.XETRA", n: "iShares Core MSCI World UCITS ETF USD (Acc) - IE00B4L5Y983", c: '"' },
  { id: 31664, s: "EUNM.F", n: "iShares MSCI EM UCITS ETF USD (Acc) - IE00B4L5YC18", c: '"' },
  { id: 31665, s: "EUNM.XETRA", n: "iShares MSCI EM UCITS ETF USD (Acc) - IE00B4L5YC18", c: '"' },
  { id: 31666, s: "EUNN.F", n: "iShares III Public Limited Company - iShares Core MSCI Japan IMI UCITS ETF - IE00B4L5YX21", c: '"' },
  { id: 31667, s: "EUNN.XETRA", n: "iShares Core MSCI Japan IMI UCITS ETF USD (Acc) - IE00B4L5YX21", c: '"' },
  { id: 31668, s: "EUNR.F", n: "iShares III Public Limited Company - iShares Euro Corporate Bond ex-Financials UCITS ETF - IE00B4L5ZG21", c: '"' },
  { id: 31669, s: "EUNR.XETRA", n: "iShares Euro Corporate Bond ex-Financials UCITS - IE00B4L5ZG21", c: '"' },
  { id: 31670, s: "EUNS.F", n: "iShares &#226;&#130;&#172; Corp Bond ex-Financials 1-5yr UCITS ETF EUR (Dist) - IE00B4L5ZY03", c: '"' },
  { id: 31671, s: "EUNS.XETRA", n: "iShares Corporate Bond ex-Financials 1-5 UCITS - IE00B4L5ZY03", c: '"' },
  { id: 31672, s: "EUNT.F", n: "iShares III Public Limited Company - iShares Euro Corporate Bond 1-5yr UCITS ETF - IE00B4L60045", c: '"' },
  { id: 31673, s: "EUNT.XETRA", n: "iShares Euro Corporate Bond 1-5Yr UCITS - IE00B4L60045", c: '"' },
  { id: 31674, s: "EUNU.XETRA", n: "iShares Global Aggregate Bond UCITS Dist - IE00B3F81409", c: '"' },
  { id: 31675, s: "EUNW.F", n: "iShares Public Limited Company - iShares Euro High Yield Corp Bond UCITS ETF - IE00B66F4759", c: '"' },
  { id: 31676, s: "EUNW.XETRA", n: "iShares Euro High Yield Corporate Bond UCITS - IE00B66F4759", c: '"' },
  { id: 31677, s: "EUNX.F", n: "iShares II Public Limited Company - iShares US Aggregate Bond UCITS ETF - IE00B44CGS96", c: '"' },
  { id: 31678, s: "EUNX.XETRA", n: "iShares US Aggregate Bond UCITS - IE00B44CGS96", c: '"' },
  { id: 31679, s: "EUNY.F", n: "iShares Emerging Market Dividend UCITS - IE00B652H904", c: '"' },
  { id: 31680, s: "EUNY.XETRA", n: "iShares Emerging Market Dividend UCITS - IE00B652H904", c: '"' },
  { id: 31681, s: "EUNZ.F", n: "iShares Edge MSCI EM Minimum Volatility UCITS ETF USD (Acc) - IE00B8KGV557", c: '"' },
  { id: 31682, s: "EUNZ.XETRA", n: "iShares STOXX Europe 50 UCITS ETF EUR (Dist) - IE00B8KGV557", c: '"' },
  { id: 31683, s: "EUO.US", n: "ProShares UltraShort Euro - US74347W8828", c: '"' },
  { id: 31684, s: "EUPA.LSE", n: "Franklin STOXX Europe 600 Paris Aligned Climate UCITS ETF GBP - IE00BMDPBY65", c: '"' },
  { id: 31685, s: "EUPE.XETRA", n: "Ossiam Shiller Barclays Cape Europe Sector Value TR UCITS - LU1079842321", c: '"' },
  { id: 31686, s: "EURE.SW", n: "SPDR® FTSE EPRA Europe ex UK Real Estate UCITS ETF EUR - IE00BSJCQV56", c: '"' },
  { id: 31687, s: "EUREUA.SW", n: "UBS(Lux)Fund Solutions – MSCI Europe UCITS ETF(EUR)A-dis - LU0446734104", c: '"' },
  { id: 31688, s: "EURL.US", n: "Direxion Daily FTSE Europe Bull 3X Shares - US25459Y2809", c: '"' },
  { id: 31689, s: "EUSA.US", n: "iShares MSCI USA Equal Weighted ETF - US4642866812", c: '"' },
  { id: 31690, s: "EUSB.US", n: "iShares Trust - iShares ESG Advanced Total USD Bond Market ETF - US46436E6196", c: '"' },
  { id: 31691, s: "EUSC.US", n: "WisdomTree Europe Hedged SmallCap Equity Fund - US97717X5529", c: '"' },
  { id: 31692, s: "EUSRI.PA", n: "Amundi Index Solutions - Amundi MSCI Europe SRI PAB UCITS ETF DR C - LU1861137484", c: '"' },
  { id: 31693, s: "EVAE.PA", n: "BNP Paribas Easy ESG Equity Value Europe UCITS ETF Cap - LU1377382285", c: '"' },
  { id: 31694, s: "EVAE.SW", n: "BNP Paribas Easy ESG Equity Value Europe UCITS ETF Cap EUR - LU1377382285", c: '"' },
  { id: 31695, s: "EVAL.LSE", n: "SPDR® MSCI Europe Value UCITS ETF EUR Acc - IE00BSPLC306", c: '"' },
  { id: 31696, s: "EVAL.SW", n: "SPDR® MSCI Europe Value UCITS ETF EUR Acc CHF - IE00BSPLC306", c: '"' },
  { id: 31697, s: "EVNT.US", n: "AltShares Event-Driven ETF - US02210T2078", c: '"' },
  { id: 31698, s: "EVOE.SW", n: "BNP Paribas Easy ESG Equity Low Vol Europe UCITS ETF Cap EUR - LU1377381717", c: '"' },
  { id: 31699, s: "EVOU.PA", n: "BNP Paribas Easy ESG Equity Low Vol US UCITS ETF Cap - LU1377381980", c: '"' },
  { id: 31700, s: "EVOU.SW", n: "BNP Paribas Easy ESG Equity Low Vol US UCITS ETF Cap EUR - LU1377381980", c: '"' },
  { id: 31701, s: "EVUS.US", n: "iShares ESG Aware MSCI USA Value ETF - US46436E2211", c: '"' },
  { id: 31702, s: "EVX.US", n: "VanEck Environmental Services ETF - US92189F3047", c: '"' },
  { id: 31703, s: "EWA.US", n: "iShares MSCI Australia ETF - US4642861037", c: '"' },
  { id: 31704, s: "EWC.US", n: "iShares MSCI Canada ETF - US4642865095", c: '"' },
  { id: 31705, s: "EWCO.US", n: "Invesco S&P 500 Equal Weight Communication Svc - US46137Y6095", c: '"' },
  { id: 31706, s: "EWD.US", n: "iShares MSCI Sweden ETF - US4642867562", c: '"' },
  { id: 31707, s: "EWG.US", n: "iShares MSCI Germany ETF - US4642868065", c: '"' },
  { id: 31708, s: "EWGS.US", n: "iShares MSCI Germany Small-Cap ETF - US46429B4656", c: '"' },
  { id: 31709, s: "EWH.US", n: "iShares MSCI Hong Kong ETF - US4642868719", c: '"' },
  { id: 31710, s: "EWI.US", n: "iShares MSCI Italy ETF - US46434G8309", c: '"' },
  { id: 31711, s: "EWJ.US", n: "iShares MSCI Japan ETF - US46434G8226", c: '"' },
  { id: 31712, s: "EWJV.US", n: "iShares MSCI Japan Value - US46435U3749", c: '"' },
  { id: 31713, s: "EWK.US", n: "iShares MSCI Belgium ETF - US4642863017", c: '"' },
  { id: 31714, s: "EWL.US", n: "iShares MSCI Switzerland ETF - US4642867497", c: '"' },
  { id: 31715, s: "EWLD.PA", n: "Lyxor PEA MSCI World UCITS C - FR0011869353", c: '"' },
  { id: 31716, s: "EWM.US", n: "iShares MSCI Malaysia ETF - US46434G8143", c: '"' },
  { id: 31717, s: "EWMC.US", n: "Invesco S&P MidCap 400® Equal Weight ETF - US46137V2253", c: '"' },
  { id: 31718, s: "EWN.US", n: "iShares MSCI Netherlands ETF - US4642868149", c: '"' },
  { id: 31719, s: "EWO.US", n: "iShares MSCI Austria ETF - US4642862027", c: '"' },
  { id: 31720, s: "EWP.US", n: "iShares MSCI Spain ETF - US4642867646", c: '"' },
  { id: 31721, s: "EWQ.US", n: "iShares MSCI France ETF - US4642867075", c: '"' },
  { id: 31722, s: "EWRD.PA", n: "BNP Paribas Easy MSCI World SRI S-Series PAB 5% Capped UCITS ETF Capitalisation - LU1291108642", c: '"' },
  { id: 31723, s: "EWRD.XETRA", n: "BNP Paribas Easy - MSCI World SRI S-Series 5% Capped - LU1291108642", c: '"' },
  { id: 31724, s: "EWRE.US", n: "Invesco S&P 500® Equal Weight Real Estate ETF - US46137V2907", c: '"' },
  { id: 31725, s: "EWS.US", n: "iShares MSCI Singapore ETF - US46434G7806", c: '"' },
  { id: 31726, s: "EWT.US", n: "iShares MSCI Taiwan ETF - US46434G7723", c: '"' },
  { id: 31727, s: "EWU.US", n: "iShares MSCI United Kingdom ETF - US46435G3341", c: '"' },
  { id: 31728, s: "EWUS.US", n: "iShares MSCI United Kingdom Small-Cap ETF - US46429B4169", c: '"' },
  { id: 31729, s: "EWV.US", n: "ProShares UltraShort MSCI Japan - US74348A4590", c: '"' },
  { id: 31730, s: "EWW.US", n: "iShares MSCI Mexico ETF - US4642868222", c: '"' },
  { id: 31731, s: "EWX.US", n: "SPDR® S&P Emerging Markets Small Cap ETF - US78463X7562", c: '"' },
  { id: 31732, s: "EWY.US", n: "iShares MSCI South Korea ETF - US4642867729", c: '"' },
  { id: 31733, s: "EWZ.US", n: "iShares MSCI Brazil ETF - US4642864007", c: '"' },
  { id: 31734, s: "EWZS.US", n: "iShares MSCI Brazil Small-Cap ETF - US4642891315", c: '"' },
  { id: 31735, s: "EX20.AU", n: "BetaShares Australian Ex-20 Portfolio Diversifier - AU00000EX202", c: '"' },
  { id: 31736, s: "EXH1.F", n: "iShares (DE) I - iShares STOXX Europe 600 Oil & Gas UCITS ETF (DE) - DE000A0H08M3", c: '"' },
  { id: 31737, s: "EXH1.XETRA", n: "iShares STOXX Europe 600 Oil & Gas UCITS ETF (DE) - DE000A0H08M3", c: '"' },
  { id: 31738, s: "EXH2.F", n: "iShares (DE) I - iShares STOXX Europe 600 Financial Services UCITS ETF (DE) - DE000A0H08G5", c: '"' },
  { id: 31739, s: "EXH3.F", n: "iShares (DE) I - iShares STOXX Europe 600 Food & Beverage UCITS ETF (DE) - DE000A0H08H3", c: '"' },
  { id: 31740, s: "EXH3.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Food & Beverage UCITS ETF (DE) - DE000A0H08H3", c: '"' },
  { id: 31741, s: "EXH4.F", n: "iShares (DE) I - iShares STOXX Europe 600 Industrial Goods & Services UCITS ETF (DE) - DE000A0H08J9", c: '"' },
  { id: 31742, s: "EXH4.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Industrial Goods & Services UCITS ETF (DE) - DE000A0H08J9", c: '"' },
  { id: 31743, s: "EXH5.F", n: "iShares STOXX Europe 600 Insurance UCITS ETF (DE) - DE000A0H08K7", c: '"' },
  { id: 31744, s: "EXH5.XETRA", n: "iShares STOXX Europe 600 Insurance UCITS ETF (DE) - DE000A0H08K7", c: '"' },
  { id: 31745, s: "EXH6.XETRA", n: "iShares STOXX Europe 600 Media UCITS ETF (DE) - DE000A0H08L5", c: '"' },
  { id: 31746, s: "EXH7.F", n: "iShares (DE) I - iShares STOXX Europe 600 Personal & Household Goods UCITS ETF (DE) - DE000A0H08N1", c: '"' },
  { id: 31747, s: "EXH7.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Personal & Household Goods UCITS ETF (DE) - DE000A0H08N1", c: '"' },
  { id: 31748, s: "EXH8.F", n: "iShares (DE) I - iShares STOXX Europe 600 Retail UCITS ETF (DE) - DE000A0H08P6", c: '"' },
  { id: 31749, s: "EXH8.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Retail UCITS ETF (DE) - DE000A0H08P6", c: '"' },
  { id: 31750, s: "EXH9.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Utilities UCITS ETF (DE) - DE000A0Q4R02", c: '"' },
  { id: 31751, s: "EXHA.F", n: "iShares eb.rexx Government Germany UCITS ETF (DE) - DE0006289465", c: '"' },
  { id: 31752, s: "EXHA.XETRA", n: "iShares eb.rexx Government Germany UCITS ETF (DE) - DE0006289465", c: '"' },
  { id: 31753, s: "EXHB.XETRA", n: "iShares eb.rexx Government Germany 1.5-2.5yr UCITS ETF (DE) - DE0006289473", c: '"' },
  { id: 31754, s: "EXHC.F", n: "iShares eb.rexx Government Germany 2.5-5.5yr UCITS ETF (DE) - DE0006289481", c: '"' },
  { id: 31755, s: "EXHC.XETRA", n: "iShares eb.rexx Government Germany 2.5-5.5yr UCITS ETF (DE) - DE0006289481", c: '"' },
  { id: 31756, s: "EXHD.F", n: "iShares eb.rexx Government Germany 5.5-10.5yr UCITS ETF (DE) - DE0006289499", c: '"' },
  { id: 31757, s: "EXHD.XETRA", n: "iShares eb.rexx Government Germany 5.5-10.5yr UCITS ETF (DE) - DE0006289499", c: '"' },
  { id: 31758, s: "EXHE.XETRA", n: "iShares Pfandbriefe UCITS ETF (DE) - DE0002635265", c: '"' },
  { id: 31759, s: "EXHF.XETRA", n: "iShares Euro Government Bond Capped 1.5-10.5yr UCITS ETF (DE) - DE000A0H0785", c: '"' },
  { id: 31760, s: "EXI.US", n: "iShares Global Industrials ETF - US4642887297", c: '"' },
  { id: 31761, s: "EXI1.XETRA", n: "iShares SLI UCITS ETF (DE) - DE0005933964", c: '"' },
  { id: 31762, s: "EXI2.XETRA", n: "iShares (DE) I - iShares Dow Jones Global Titans 50 UCITS ETF (DE) - DE0006289382", c: '"' },
  { id: 31763, s: "EXI3.F", n: "iShares Dow Jones Industrial Average UCITS ETF (DE) - DE0006289390", c: '"' },
  { id: 31764, s: "EXI3.XETRA", n: "iShares Dow Jones Industrial Average UCITS ETF (DE) - DE0006289390", c: '"' },
  { id: 31765, s: "EXI5.XETRA", n: "iShares STOXX Europe 600 Real Estate UCITS ETF (DE) - DE000A0Q4R44", c: '"' },
  { id: 31766, s: "EXS1.F", n: "iShares Core DAX® UCITS ETF (DE) EUR (Acc) - DE0005933931", c: '"' },
  { id: 31767, s: "EXS1.XETRA", n: "iShares Core DAX® UCITS ETF (DE) EUR (Acc) - DE0005933931", c: '"' },
  { id: 31768, s: "EXS2.F", n: "iShares TecDAX UCITS ETF (DE) - DE0005933972", c: '"' },
  { id: 31769, s: "EXS2.XETRA", n: "iShares TecDAX UCITS ETF (DE) - DE0005933972", c: '"' },
  { id: 31770, s: "EXS3.F", n: "iShares MDAX® UCITS ETF (DE) - DE0005933923", c: '"' },
  { id: 31771, s: "EXS3.XETRA", n: "iShares MDAX® UCITS ETF (DE) - DE0005933923", c: '"' },
  { id: 31772, s: "EXSA.F", n: "iShares STOXX Europe 600 UCITS ETF (DE) - DEUTSCHE BOERSE", c: '"' },
  { id: 31773, s: "EXSA.XETRA", n: "iShares STOXX Europe 600 UCITS ETF (DE) - DE0002635307", c: '"' },
  { id: 31774, s: "EXSB.SW", n: "iShares DivDAX® UCITS ETF (DE) CHF - DE0002635273", c: '"' },
  { id: 31775, s: "EXSB.XETRA", n: "iShares DivDAX UCITS ETF (DE) - DE0002635273", c: '"' },
  { id: 31776, s: "EXSC.XETRA", n: "iShares STOXX Europe Large 200 UCITS ETF (DE) - DE0005933980", c: '"' },
  { id: 31777, s: "EXSD.F", n: "iShares STOXX Europe Mid 200 UCITS ETF (DE) - DE0005933998", c: '"' },
  { id: 31778, s: "EXSD.XETRA", n: "iShares STOXX Europe Mid 200 UCITS ETF (DE) - DE0005933998", c: '"' },
  { id: 31779, s: "EXSE.XETRA", n: "iShares STOXX Europe Small 200 UCITS ETF (DE) - DE000A0D8QZ7", c: '"' },
  { id: 31780, s: "EXSG.F", n: "iShares EURO STOXX Select Dividend 30 UCITS ETF (DE) - DE0002635281", c: '"' },
  { id: 31781, s: "EXSH.F", n: "iShares STOXX Europe Select Dividend 30 UCITS ETF (DE) - DE0002635299", c: '"' },
  { id: 31782, s: "EXSH.XETRA", n: "iShares STOXX Europe Select Dividend 30 UCITS ETF (DE) - DE0002635299", c: '"' },
  { id: 31783, s: "EXSI.F", n: "iShares EURO STOXX UCITS ETF (DE) - DE000A0D8Q07", c: '"' },
  { id: 31784, s: "EXSI.XETRA", n: "iShares EURO STOXX UCITS ETF (DE) - DE000A0D8Q07", c: '"' },
  { id: 31785, s: "EXV1.F", n: "iShares (DE) I - iShares STOXX Europe 600 Banks UCITS ETF (DE) - DE000A0F5UJ7", c: '"' },
  { id: 31786, s: "EXV1.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Banks UCITS ETF (DE) - DE000A0F5UJ7", c: '"' },
  { id: 31787, s: "EXV2.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Telecommunications UCITS ETF (DE) - DE000A0H08R2", c: '"' },
  { id: 31788, s: "EXV3.F", n: "iShares (DE) I - iShares STOXX Europe 600 Technology UCITS ETF (DE) - DE000A0H08Q4", c: '"' },
  { id: 31789, s: "EXV3.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Technology UCITS ETF (DE) - DE000A0H08Q4", c: '"' },
  { id: 31790, s: "EXV4.F", n: "iShares (DE) I - iShares STOXX Europe 600 Health Care UCITS ETF (DE) - DE000A0Q4R36", c: '"' },
  { id: 31791, s: "EXV4.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Health Care UCITS ETF (DE) - DE000A0Q4R36", c: '"' },
  { id: 31792, s: "EXV5.F", n: "iShares (DE) I - iShares STOXX Europe 600 Automobiles & Parts UCITS ETF (DE) - DE000A0Q4R28", c: '"' },
  { id: 31793, s: "EXV6.F", n: "iShares (DE) I - iShares STOXX Europe 600 Basic Resources UCITS ETF (DE) - DE000A0F5UK5", c: '"' },
  { id: 31794, s: "EXV6.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Basic Resources UCITS ETF (DE) - DE000A0F5UK5", c: '"' },
  { id: 31795, s: "EXV7.F", n: "iShares (DE) I - iShares STOXX Europe 600 Chemicals UCITS ETF (DE) - DE000A0H08E0", c: '"' },
  { id: 31796, s: "EXV7.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Chemicals UCITS ETF (DE) - DE000A0H08E0", c: '"' },
  { id: 31797, s: "EXV8.F", n: "iShares (DE) I - iShares STOXX Europe 600 Construction & Materials UCITS ETF (DE) - DE000A0H08F7", c: '"' },
  { id: 31798, s: "EXV8.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Construction & Materials UCITS ETF (DE) - DE000A0H08F7", c: '"' },
  { id: 31799, s: "EXV9.F", n: "iShares (DE) I - iShares STOXX Europe 600 Travel & Leisure UCITS ETF (DE) - DE000A0H08S0", c: '"' },
  { id: 31800, s: "EXV9.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Travel & Leisure UCITS ETF (DE) - DE000A0H08S0", c: '"' },
  { id: 31801, s: "EXW1.F", n: "iShares EURO STOXX 50 UCITS ETF (DE) - DE0005933956", c: '"' },
  { id: 31802, s: "EXW1.XETRA", n: "iShares EURO STOXX 50 UCITS ETF (DE) - DE0005933956", c: '"' },
  { id: 31803, s: "EXW3.XETRA", n: "iShares STOXX Europe 50 UCITS ETF (DE) - DE0005933949", c: '"' },
  { id: 31804, s: "EXX1.F", n: "iShares EURO STOXX Banks 30-15 UCITS ETF (DE) - DE0006289309", c: '"' },
  { id: 31805, s: "EXX1.XETRA", n: "iShares EURO STOXX Banks 30-15 UCITS ETF (DE) - DE0006289309", c: '"' },
  { id: 31806, s: "EXX5.XETRA", n: "iShares Dow Jones U.S. Select Dividend UCITS ETF (DE) - DE000A0D8Q49", c: '"' },
  { id: 31807, s: "EXX6.XETRA", n: "iShares eb.rexx Government Germany 10.5+yr UCITS ETF (DE) - DE000A0D8Q31", c: '"' },
  { id: 31808, s: "EXX7.XETRA", n: "iShares Nikkei 225 UCITS ETF (DE) - DE000A0H08D2", c: '"' },
  { id: 31809, s: "EXXT.F", n: "iShares NASDAQ-100 UCITS ETF (DE) - DE000A0F5UF5", c: '"' },
  { id: 31810, s: "EXXT.XETRA", n: "iShares NASDAQ-100® UCITS ETF (DE) EUR - DE000A0F5UF5", c: '"' },
  { id: 31811, s: "EXXU.XETRA", n: "iShares Dow Jones China Offshore 50 UCITS ETF (DE) - DE000A0F5UE8", c: '"' },
  { id: 31812, s: "EXXV.F", n: "iShares Dow Jones Eurozone Sustainability Screened UCITS ETF (DE) - DE000A0F5UG3", c: '"' },
  { id: 31813, s: "EXXV.XETRA", n: "iShares Dow Jones Eurozone Sustainability Screened UCITS ETF (DE) - DE000A0F5UG3", c: '"' },
  { id: 31814, s: "EXXW.XETRA", n: "iShares Dow Jones Asia Pacific Select Dividend 30 UCITS ETF (DE) - DE000A0H0744", c: '"' },
  { id: 31815, s: "EXXX.XETRA", n: "iShares ATX UCITS ETF (DE) - DE000A0D8Q23", c: '"' },
  { id: 31816, s: "EYLD.US", n: "Cambria Emerging Shareholder Yield ETF - US1320617061", c: '"' },
  { id: 31817, s: "EZA.US", n: "iShares MSCI South Africa ETF - US4642867802", c: '"' },
  { id: 31818, s: "EZJ.US", n: "ProShares Ultra MSCI Japan - US74347X7084", c: '"' },
  { id: 31819, s: "EZM.US", n: "WisdomTree U.S. MidCap Earnings Fund - US97717W5702", c: '"' },
  { id: 31820, s: "EZU.US", n: "iShares MSCI Eurozone ETF - US4642866085", c: '"' },
  { id: 31821, s: "F1ESG.SW", n: "UBS(Lux)Fund Solutions – SBI® Foreign AAA-BBB 1-5 ESG UCITS ETF(CHF)A-dis - LU0879397742", c: '"' },
  { id: 31822, s: "F5ESG.SW", n: "UBS(Lux)Fund Solutions – SBI® Foreign AAA-BBB 5-10 ESG UCITS ETF(CHF)A-dis - LU0879399441", c: '"' },
  { id: 31823, s: "F701.F", n: "ComStage Vermögensstrategie UCITS ETF - DE000ETF7011", c: '"' },
  { id: 31824, s: "F701.XETRA", n: "Lyxor Portfolio Strategy UCITS ETF - DE000ETF7011", c: '"' },
  { id: 31825, s: "F703.XETRA", n: "Lyxor Portfolio Strategy Offensiv UCITS ETF (Vermoegensstrategie) - DE000ETF7037", c: '"' },
  { id: 31826, s: "FAAR.US", n: "First Trust Alternative Absolute Return Strategy ETF - US33740Y1010", c: '"' },
  { id: 31827, s: "FAB.US", n: "First Trust Multi Cap Value AlphaDEX® Fund - US33733C1080", c: '"' },
  { id: 31828, s: "FAD.US", n: "First Trust Multi Cap Growth AlphaDEX® Fund - US33733F1012", c: '"' },
  { id: 31829, s: "FAHY.F", n: "Invesco Global Funds Ireland plc - Invesco US High Yield Fallen Angels UCITS ETF - IE00BD0Q9673", c: '"' },
  { id: 31830, s: "FAHY.LSE", n: "Invesco US High Yield Fallen Angels UCITS ETF - IE00BD0Q9673", c: '"' },
  { id: 31831, s: "FAHY.XETRA", n: "Invesco Global Funds Ireland plc - Invesco US High Yield Fallen Angels UCITS ETF - IE00BD0Q9673", c: '"' },
  { id: 31832, s: "FAIL.US", n: "Cambria Global Tail Risk ETF - US1320618051", c: '"' },
  { id: 31833, s: "FAIR.AU", n: "BetaShares Australian Sustainability Leaders - AU00000FAIR4", c: '"' },
  { id: 31834, s: "FALN.US", n: "iShares Fallen Angels USD Bond ETF - US46435G4745", c: '"' },
  { id: 31835, s: "FAN.US", n: "First Trust Global Wind Energy ETF - US33736G1067", c: '"' },
  { id: 31836, s: "FAS.US", n: "Direxion Daily Financial Bull 3X Shares - US25459Y6941", c: '"' },
  { id: 31837, s: "FAZ.US", n: "Direxion Daily Financial Bear 3X Shares - US25460E1331", c: '"' },
  { id: 31838, s: "FBCG.US", n: "Fidelity® Blue Chip Growth ETF - US3160923526", c: '"' },
  { id: 31839, s: "FBCV.US", n: "Fidelity® Blue Chip Value ETF - US3160923450", c: '"' },
  { id: 31840, s: "FBND.US", n: "Fidelity® Total Bond ETF - US3161883091", c: '"' },
  { id: 31841, s: "FBT.LSE", n: "First Trust Global Funds Plc - First Trust Nyse Arca Biotechnology Index Ucits ETF - IE00BL0L0H60", c: '"' },
  { id: 31842, s: "FBT.US", n: "First Trust NYSE Arca Biotechnology Index Fund - US33733E2037", c: '"' },
  { id: 31843, s: "FBTU.LSE", n: "First Trust Global Funds Plc - First Trust Nyse Arca Biotechnology Index Ucits ETF - IE00BL0L0H60", c: '"' },
  { id: 31844, s: "FBZ.US", n: "First Trust Brazil AlphaDEX® Fund - US33737J1337", c: '"' },
  { id: 31845, s: "FCA.US", n: "First Trust China AlphaDEX® Fund - US33737J1410", c: '"' },
  { id: 31846, s: "FCAL.US", n: "First Trust California Municipal High Income ETF - US33739P8639", c: '"' },
  { id: 31847, s: "FCCM.TO", n: "Fidelity Canadian Momentum Index ETF - CA31609W1095", c: '"' },
  { id: 31848, s: "FCCQ.TO", n: "Fidelity Canadian High Quality Index ETF - CA31610C1005", c: '"' },
  { id: 31849, s: "FCCV.TO", n: "Fidelity Canadian Value Index ETF - CA31609U1030", c: '"' },
  { id: 31850, s: "FCEF.US", n: "First Trust Income Opportunity ETF - US33740F4090", c: '"' },
  { id: 31851, s: "FCG.US", n: "First Trust Natural Gas ETF - US33733E8075", c: '"' },
  { id: 31852, s: "FCID.TO", n: "Fidelity International High Dividend Index ETF - CA31623D1033", c: '"' },
  { id: 31853, s: "FCIG.TO", n: "Fidelity Global Investment Grade Bond ETF - CA31624P1053", c: '"' },
  { id: 31854, s: "FCIL.TO", n: "Fidelity International Low Volatility Index ETF - CA31624M1023", c: '"' },
  { id: 31855, s: "FCIM.TO", n: "Fidelity International Momentum Index ETF - CA31623V1031", c: '"' },
  { id: 31856, s: "FCIQ.TO", n: "Fidelity International High Quality Index ETF - CA31623X1096", c: '"' },
  { id: 31857, s: "FCIV.TO", n: "Fidelity International Value Index ETF - CA31622Y1088", c: '"' },
  { id: 31858, s: "FCLH.TO", n: "Fidelity U.S. Low Volatility Currency Neutral Index ETF - CA31647N1033", c: '"' },
  { id: 31859, s: "FCMH.TO", n: "Fidelity U.S. Momentum Currency Neutral Index ETF - CA31649R1029", c: '"' },
  { id: 31860, s: "FCOM.US", n: "Fidelity® MSCI Communication Services Index ETF - US3160928731", c: '"' },
  { id: 31861, s: "FCOR.US", n: "Fidelity® Corporate Bond ETF - US3161881012", c: '"' },
  { id: 31862, s: "FCPI.US", n: "Fidelity® Stocks for Inflation ETF - US3160923864", c: '"' },
  { id: 31863, s: "FCRH.TO", n: "Fidelity U.S. Dividend for Rising Rates Currency Neutral Index ETF - CA31644P1018", c: '"' },
  { id: 31864, s: "FCRR.TO", n: "Fidelity U.S. Dividend for Rising Rates Index ETF - CA31644M1086", c: '"' },
  { id: 31865, s: "FCTR.US", n: "First Trust Lunt U.S. Factor Rotation ETF - US33733E8729", c: '"' },
  { id: 31866, s: "FCUD.TO", n: "Fidelity U.S. High Dividend Index ETF - CA31645M1077", c: '"' },
  { id: 31867, s: "FCUH.TO", n: "Fidelity U.S. High Dividend Currency Neutral Index ETF - CA3157401009", c: '"' },
  { id: 31868, s: "FCUL.TO", n: "Fidelity U.S. Low Volatility Index ETF - CA31647B1094", c: '"' },
  { id: 31869, s: "FCUQ.TO", n: "Fidelity U.S. High Quality Index ETF - CA31647C1077", c: '"' },
  { id: 31870, s: "FCUV.TO", n: "Fidelity U.S. Value Index ETF - CA31647E1034", c: '"' },
  { id: 31871, s: "FCVH.TO", n: "Fidelity U.S. Value Currency Neutral Index ETF - CA31646E1043", c: '"' },
  { id: 31872, s: "FCVT.US", n: "First Trust SSI Strategic Convertible Securities ETF - US33739Q5071", c: '"' },
  { id: 31873, s: "FDD.US", n: "First Trust STOXX® European Select Dividend Index Fund - US33735T1097", c: '"' },
  { id: 31874, s: "FDEM.US", n: "Fidelity® Emerging Markets Multifactor ETF - US3160925430", c: '"' },
  { id: 31875, s: "FDEV.US", n: "Fidelity® International Multifactor ETF - US3160925356", c: '"' },
  { id: 31876, s: "FDG.US", n: "American Century ETF Trust - US0250728104", c: '"' },
  { id: 31877, s: "FDHY.US", n: "Fidelity® High Yield Factor ETF - US3160926180", c: '"' },
  { id: 31878, s: "FDIS.US", n: "Fidelity® MSCI Consumer Discretionary Index ETF - US3160922049", c: '"' },
  { id: 31879, s: "FDIV.US", n: "MarketDesk Focused U.S. Dividend ETF - US33739Q3092", c: '"' },
  { id: 31880, s: "FDL.US", n: "First Trust Morningstar Dividend Leaders Index Fund - US3369171091", c: '"' },
  { id: 31881, s: "FDLO.US", n: "Fidelity® Low Volatility Factor ETF - US3160928244", c: '"' },
  { id: 31882, s: "FDM.US", n: "First Trust Dow Jones Select MicroCap Index Fund - US33718M1053", c: '"' },
  { id: 31883, s: "FDMO.US", n: "Fidelity® Momentum Factor ETF - US3160928160", c: '"' },
  { id: 31884, s: "FDN.LSE", n: "First Trust Dow Jones Internet UCITS ETF Class A USD - IE00BG0SSC32", c: '"' },
  { id: 31885, s: "FDN.US", n: "First Trust Dow Jones Internet Index Fund - US33733E3027", c: '"' },
  { id: 31886, s: "FDNE.AS", n: "First Trust Dow Jones Internet UCITS ETF Class A USD EUR - IE00BG0SSC32", c: '"' },
  { id: 31887, s: "FDNI.US", n: "First Trust Dow Jones International Internet ETF - US33734X7701", c: '"' },
  { id: 31888, s: "FDNU.LSE", n: "First Trust Dow Jones Internet UCITS ETF Class A USD - IE00BG0SSC32", c: '"' },
  { id: 31889, s: "FDRR.US", n: "Fidelity® Dividend ETF for Rising Rates - US3160928327", c: '"' },
  { id: 31890, s: "FDT.US", n: "First Trust Developed Markets Ex-US AlphaDEX® Fund - US33737J1741", c: '"' },
  { id: 31891, s: "FDTS.US", n: "First Trust Developed Markets ex-US Small Cap AlphaDEX® Fund - US33737J4067", c: '"' },
  { id: 31892, s: "FDVV.US", n: "Fidelity® High Dividend ETF - US3160928400", c: '"' },
  { id: 31893, s: "FEM.LSE", n: "First Trust Emerging Markets AlphaDEX® UCITS ETF Class A Accumulation - IE00B8X9NX34", c: '"' },
  { id: 31894, s: "FEM.US", n: "First Trust Emerging Markets AlphaDEX® Fund - US33737J1824", c: '"' },
  { id: 31895, s: "FEMB.US", n: "First Trust Emerging Markets Local Currency Bond ETF - US33739P2020", c: '"' },
  { id: 31896, s: "FEMC.SW", n: "Fidelity Emerging Markets Quality Income UCITS ETF USD Inc USD - IE00BYSX4739", c: '"' },
  { id: 31897, s: "FEMD.LSE", n: "Fidelity Emerging Markets Quality Income UCITS ETF USD Inc GBP - IE00BYSX4739", c: '"' },
  { id: 31898, s: "FEME.LSE", n: "Fidelity Emerging Markets Quality Income UCITS ETF USD Inc - IE00BYSX4739", c: '"' },
  { id: 31899, s: "FEMI.LSE", n: "Fidelity Emerging Markets Quality Income UCITS ETF USD Acc - IE00BYSX4846", c: '"' },
  { id: 31900, s: "FEMQ.LSE", n: "Fidelity Emerging Markets Quality Income UCITS ETF USD Acc - IE00BYSX4846", c: '"' },
  { id: 31901, s: "FEMS.US", n: "First Trust Emerging Markets Small Cap AlphaDEX® Fund - US33737J3077", c: '"' },
  { id: 31902, s: "FEMU.LSE", n: "First Trust Global Funds Public Limited Company - First Trust Emerging Markets AlphaDEX UCITS ETF - IE00B8X9NX34", c: '"' },
  { id: 31903, s: "FENY.US", n: "Fidelity® MSCI Energy Index ETF - US3160924029", c: '"' },
  { id: 31904, s: "FEP.US", n: "First Trust Europe AlphaDEX® Fund - US33737J1170", c: '"' },
  { id: 31905, s: "FEQD.LSE", n: "Fidelity Europe Quality Income UCITS ETF EUR Acc - IE00BYSX4283", c: '"' },
  { id: 31906, s: "FEU.US", n: "SPDR STOXX Europe 50 ETF - US78463X1037", c: '"' },
  { id: 31907, s: "FEUD.LSE", n: "First Trust Eurozone AlphaDEX® UCITS ETF Class B Shares - IE00BF2FL590", c: '"' },
  { id: 31908, s: "FEUI.LSE", n: "Fidelity Europe Quality Income UCITS ETF EUR Inc GBP - IE00BYSX4176", c: '"' },
  { id: 31909, s: "FEUI.XETRA", n: "Fidelity Europe Quality Income UCITS ETF EUR Inc - IE00BYSX4176", c: '"' },
  { id: 31910, s: "FEUQ.XETRA", n: "Fidelity Europe Quality Income UCITS ETF EUR Acc - IE00BYSX4283", c: '"' },
  { id: 31911, s: "FEUR.LSE", n: "Fidelity Sustainable Research Enhanced Europe Equity UCITS ETF EUR Acc GBP - IE00BKSBGT50", c: '"' },
  { id: 31912, s: "FEUR.SW", n: "Fidelity Sustainable Research Enhanced Europe Equity UCITS ETF EUR Acc CHF - IE00BKSBGT50", c: '"' },
  { id: 31913, s: "FEUR.XETRA", n: "Fidelity Sustainable Research Enhanced Europe Equity UCITS ETF EUR Acc - IE00BKSBGT50", c: '"' },
  { id: 31914, s: "FEUZ.LSE", n: "First Trust Eurozone AlphaDEX® UCITS ETF Class A Shares - IE00B8X9NY41", c: '"' },
  { id: 31915, s: "FEUZ.US", n: "First Trust Eurozone AlphaDEX® ETF - US33737J5056", c: '"' },
  { id: 31916, s: "FEX.LSE", n: "First Trust US Large Cap Core AlphaDEX® UCITS ETF Class A USD - IE00B8X9NW27", c: '"' },
  { id: 31917, s: "FEX.US", n: "First Trust Large Cap Core AlphaDEX® Fund - US33734K1097", c: '"' },
  { id: 31918, s: "FEXD.LSE", n: "First Trust US Large Cap Core AlphaDEX UCITS Class B - IE00BWTNMB87", c: '"' },
  { id: 31919, s: "FEXU.LSE", n: "First Trust Global Funds Public Limited Company - First Trust US Large Cap Core AlphaDEX UCITS ETF - IE00B8X9NW27", c: '"' },
  { id: 31920, s: "FEZ.US", n: "SPDR® EURO STOXX 50 ETF - US78463X2027", c: '"' },
  { id: 31921, s: "FFIU.US", n: "Fieldstone UVA Unconstrained Medium-Term Fixed Income ETF - US84858T2024", c: '"' },
  { id: 31922, s: "FFR.US", n: "First Trust FTSE EPRA/NAREIT Developed Markets Real Estate Index Fund - US33736N1019", c: '"' },
  { id: 31923, s: "FFTY.US", n: "Innovator IBD® 50 ETF - US45782C1027", c: '"' },
  { id: 31924, s: "FGBL.LSE", n: "First Trust Global Equity Income UCITS ETF Class A USD - IE00BYTH6121", c: '"' },
  { id: 31925, s: "FGBL.PA", n: "First Trust Global Equity Income UCITS ETF Class A USD - IE00BYTH6121", c: '"' },
  { id: 31926, s: "FGD.US", n: "First Trust Dow Jones Global Select Dividend Index Fund - US33734X2009", c: '"' },
  { id: 31927, s: "FGEQ.XETRA", n: "Fidelity Global Quality Income ETF Inc - IE00BYXVGZ48", c: '"' },
  { id: 31928, s: "FGLR.SW", n: "Fidelity Sustainable Research Enhanced Global Equity UCITS ETF USD Acc CHF - IE00BKSBGV72", c: '"' },
  { id: 31929, s: "FGLR.XETRA", n: "Fidelity Sustainable Research Enhanced Global Equity UCITS ETF USD Acc - IE00BKSBGV72", c: '"' },
  { id: 31930, s: "FGLS.LSE", n: "Fidelity Sustainable Research Enhanced Global Equity UCITS ETF USD Acc GBP - IE00BKSBGV72", c: '"' },
  { id: 31931, s: "FGM.US", n: "First Trust Germany AlphaDEX® Fund - US33737J1907", c: '"' },
  { id: 31932, s: "FGQD.LSE", n: "Fidelity Global Quality Income ETF Inc - IE00BYXVGZ48", c: '"' },
  { id: 31933, s: "FGQD.SW", n: "Fidelity Global Quality Income ETF Inc USD - IE00BYXVGZ48", c: '"' },
  { id: 31934, s: "FGQI.LSE", n: "Fidelity Global Quality Income ETF Inc - IE00BYXVGZ48", c: '"' },
  { id: 31935, s: "FHLC.US", n: "Fidelity® MSCI Health Care Index ETF - US3160926008", c: '"' },
  { id: 31936, s: "FHU.TO", n: "First Trust AlphaDEX U.S. Utilities Sector Index ETF - CA33732M1077", c: '"' },
  { id: 31937, s: "FIBR.US", n: "iShares U.S. Fixed Income Balanced Risk Factor ETF - US46435U7963", c: '"' },
  { id: 31938, s: "FID.US", n: "First Trust Intl Multi-Asset Diversified Inc - US33738R6889", c: '"' },
  { id: 31939, s: "FIDI.US", n: "Fidelity® International High Dividend ETF - US3160927253", c: '"' },
  { id: 31940, s: "FIDU.US", n: "Fidelity® MSCI Industrials Index ETF - US3160927097", c: '"' },
  { id: 31941, s: "FIE.TO", n: "iShares Canadian Financial Monthly Income Common Class - CA46433H1029", c: '"' },
  { id: 31942, s: "FIG.TO", n: "CI Investment Grade Bond ETF - CA12556J1012", c: '"' },
  { id: 31943, s: "FILL.US", n: "iShares MSCI Global Energy Producers ETF - US4642863439", c: '"' },
  { id: 31944, s: "FIN.PA", n: "Lyxor Index Fund - Lyxor STOXX Europe 600 Financial Services UCITS ETF - LU1834984798", c: '"' },
  { id: 31945, s: "FINW.LSE", n: "Lyxor MSCI World Financials TR UCITS ETF - C-EUR - LU0533033071", c: '"' },
  { id: 31946, s: "FINW.PA", n: "Lyxor MSCI World Financials TR UCITS ETF - C-EUR - LU0533032859", c: '"' },
  { id: 31947, s: "FINX.US", n: "Global X FinTech ETF - US37954Y8140", c: '"' },
  { id: 31948, s: "FISR.US", n: "SPDR® SSGA Fixed Income Sector Rotation ETF - US78470P5070", c: '"' },
  { id: 31949, s: "FITE.US", n: "SPDR S&P Kensho Future Security - US78468R6716", c: '"' },
  { id: 31950, s: "FIVA.US", n: "Fidelity® International Value Factor ETF - US3160927170", c: '"' },
  { id: 31951, s: "FIVG.US", n: "Defiance 5G Next Gen Connectivity ETF - US26922A2895", c: '"' },
  { id: 31952, s: "FIW.US", n: "First Trust Water ETF - US33733B1008", c: '"' },
  { id: 31953, s: "FIXD.US", n: "First Trust TCW Opportunistic Fixed Income ETF - US33740F8059", c: '"' },
  { id: 31954, s: "FJP.US", n: "First Trust Japan AlphaDEX® Fund - US33737J1584", c: '"' },
  { id: 31955, s: "FKU.LSE", n: "First Trust United Kingdom AlphaDEX UCITS Class A - IE00B8X9NZ57", c: '"' },
  { id: 31956, s: "FKU.US", n: "First Trust United Kingdom AlphaDEX® Fund - US33737J2244", c: '"' },
  { id: 31957, s: "FKUD.LSE", n: "First Trust United Kingdom AlphaDEX® UCITS ETF Class B GBP - IE00BD9N0445", c: '"' },
  { id: 31958, s: "FLAX.US", n: "Franklin FTSE Asia ex Japan ETF - US35473P6604", c: '"' },
  { id: 31959, s: "FLBA.TO", n: "Franklin Liberty Core Balanced ETF - CA35376K1075", c: '"' },
  { id: 31960, s: "FLBL.US", n: "Franklin Liberty Senior Loan ETF - US35473P5952", c: '"' },
  { id: 31961, s: "FLBR.US", n: "Franklin FTSE Brazil ETF - US35473P8352", c: '"' },
  { id: 31962, s: "FLCB.US", n: "Franklin Templeton ETF Trust - Franklin Liberty U.S. Core Bond ETF - US35473P5531", c: '"' },
  { id: 31963, s: "FLCH.US", n: "Franklin FTSE China ETF - US35473P8196", c: '"' },
  { id: 31964, s: "FLCI.TO", n: "Franklin Bissett Corporate Bond Fund ETF - CA35249B1022", c: '"' },
  { id: 31965, s: "FLCO.US", n: "Franklin Liberty Investment Grade Corporate ETF - US35473P6034", c: '"' },
  { id: 31966, s: "FLDR.US", n: "Fidelity® Low Duration Bond Factor ETF - US3161884081", c: '"' },
  { id: 31967, s: "FLEE.US", n: "Franklin FTSE Europe ETF - US35473P6521", c: '"' },
  { id: 31968, s: "FLEH.US", n: "Franklin FTSE Europe Hedged ETF - US35473P6455", c: '"' },
  { id: 31969, s: "FLEQ.LSE", n: "Franklin LibertyQ European Equity UCITS ETF - IE00BFWXDW46", c: '"' },
  { id: 31970, s: "FLES.SW", n: "Franklin Euro Short Maturity UCITS ETF EUR - IE00BFWXDY69", c: '"' },
  { id: 31971, s: "FLGA.TO", n: "Franklin Global Aggregate Bond Active ETF (CAD-Hedged) - CA35377B1067", c: '"' },
  { id: 31972, s: "FLGD.TO", n: "Franklin Global Dividend Quality Index ETF - CA35376A1093", c: '"' },
  { id: 31973, s: "FLGV.US", n: "Franklin Liberty U.S. Treasury Bond ETF - US35473P4880", c: '"' },
  { id: 31974, s: "FLHK.US", n: "Franklin FTSE Hong Kong ETF - US35473P7776", c: '"' },
  { id: 31975, s: "FLHY.US", n: "Franklin Liberty High Yield Corporate ETF - US35473P6299", c: '"' },
  { id: 31976, s: "FLI.TO", n: "CI U.S. & Canada Lifeco Covered Call ETF (Hedged Common Units) - CA12555K1093", c: '"' },
  { id: 31977, s: "FLIA.US", n: "Franklin Liberty International Aggregate Bond ETF - US35473P6117", c: '"' },
  { id: 31978, s: "FLIN.US", n: "Franklin FTSE India ETF - US35473P7693", c: '"' },
  { id: 31979, s: "FLJH.US", n: "Franklin FTSE Japan Hedged ETF - US35473P6372", c: '"' },
  { id: 31980, s: "FLJP.US", n: "Franklin FTSE Japan ETF - US35473P7446", c: '"' },
  { id: 31981, s: "FLLA.US", n: "Franklin FTSE Latin America ETF - US35473P5614", c: '"' },
  { id: 31982, s: "FLLV.US", n: "Franklin Liberty U.S. Low Volatility ETF - US35473P5044", c: '"' },
  { id: 31983, s: "FLM.US", n: "First Trust Global Engineering and Construction ETF - US33736M1036", c: '"' },
  { id: 31984, s: "FLMB.US", n: "Franklin Liberty Federal Tax-Free Bond ETF - US35473P8501", c: '"' },
  { id: 31985, s: "FLMI.US", n: "Franklin Liberty Intermediate Municipal Opportunities ETF - US35473P8683", c: '"' },
  { id: 31986, s: "FLN.US", n: "First Trust Latin America AlphaDEX® Fund - US33737J1253", c: '"' },
  { id: 31987, s: "FLO5.LSE", n: "iShares USD Floating Rate Bond UCITS Dis - IE00BZ048462", c: '"' },
  { id: 31988, s: "FLOT.LSE", n: "iShares II Public Limited Company - iShares $ Floating Rate Bond UCITS ETF - IE00BZ048462", c: '"' },
  { id: 31989, s: "FLOT.SW", n: "iShares $ Floating Rate Bond UCITS ETF USD (Dist) USD - IE00BZ048462", c: '"' },
  { id: 31990, s: "FLOT.US", n: "iShares Floating Rate Bond ETF - US46429B6552", c: '"' },
  { id: 31991, s: "FLQA.LSE", n: "Franklin LibertyQ AC Asia ex Japan UCITS ETF - IE00BFWXDV39", c: '"' },
  { id: 31992, s: "FLQA.SW", n: "Franklin AC Asia ex Japan UCITS ETF USD - IE00BFWXDV39", c: '"' },
  { id: 31993, s: "FLQD.US", n: "Franklin LibertyQ Global Dividend ETF - US35473P3064", c: '"' },
  { id: 31994, s: "FLQE.US", n: "Franklin LibertyQ Emerging Markets ETF - US35473P2074", c: '"' },
  { id: 31995, s: "FLQG.US", n: "Franklin LibertyQ Global Equity ETF - US35473P4054", c: '"' },
  { id: 31996, s: "FLQH.US", n: "Franklin LibertyQ International Equity Hedged ETF - US35473P1084", c: '"' },
  { id: 31997, s: "FLQL.US", n: "Franklin LibertyQ U.S. Equity ETF - US35473P8014", c: '"' },
  { id: 31998, s: "FLQM.US", n: "Franklin LibertyQ U.S. Mid Cap Equity ETF - US35473P8840", c: '"' },
  { id: 31999, s: "FLQS.US", n: "Franklin LibertyQ U.S. Small Cap Equity ETF - US35473P8766", c: '"' },
  { id: 32000, s: "FLRG.SW", n: "Franklin Euro Green Bond UCITS ETF EUR - IE00BHZRR253", c: '"' },
  { id: 32001, s: "FLRG.US", n: "Fidelity Covington Trust - Fidelity U.S. Multifactor ETF - US3160923781", c: '"' },
  { id: 32002, s: "FLRG.XETRA", n: "Franklin Libertyshares ICAV - Franklin Liberty Euro Green Bond Ucits ETF - IE00BHZRR253", c: '"' },
  { id: 32003, s: "FLRK.LSE", n: "Franklin FTSE Korea UCITS ETF GBP - IE00BHZRR030", c: '"' },
  { id: 32004, s: "FLRM.TO", n: "Franklin Liberty Risk Managed Canadian Equity ETF - CA3537691022", c: '"' },
  { id: 32005, s: "FLRN.US", n: "SPDR® Bloomberg Investment Grade Floating Rate ETF - US78468R2004", c: '"' },
  { id: 32006, s: "FLRT.US", n: "Pacer Pacific Asset Floating Rate High Income ETF - US00768Y5942", c: '"' },
  { id: 32007, s: "FLSP.US", n: "Franklin Liberty Systematic Style Premia ETF - US35473P5465", c: '"' },
  { id: 32008, s: "FLTB.US", n: "Fidelity® Limited Term Bond ETF - US3161882002", c: '"' },
  { id: 32009, s: "FLTR.US", n: "VanEck Investment Grade Floating Rate ETF - US92189F4862", c: '"' },
  { id: 32010, s: "FLTW.US", n: "Franklin FTSE Taiwan ETF - US35473P6869", c: '"' },
  { id: 32011, s: "FLUC.SW", n: "Franklin USD Investment Grade Corporate Bond UCITS ETF USD - IE00BFWXDX52", c: '"' },
  { id: 32012, s: "FLUD.US", n: "Franklin Liberty Ultra Short Bond ETF - US35473P4963", c: '"' },
  { id: 32013, s: "FLUS.TO", n: "Franklin U.S. Large Cap Multifactor Index ETF - CA35376P1062", c: '"' },
  { id: 32014, s: "FLV.US", n: "American Century ETF Trust - US0250727940", c: '"' },
  { id: 32015, s: "FLX5.XETRA", n: "Franklin S&P 500 Paris Aligned Climate UCITS ETF - IE00BMDPBZ72", c: '"' },
  { id: 32016, s: "FLXB.LSE", n: "Franklin FTSE Brazil UCITS ETF - IE00BHZRQY00", c: '"' },
  { id: 32017, s: "FLXB.XETRA", n: "Franklin Libertyshares ICAV - Franklin Ftse Brazil Ucits ETF - IE00BHZRQY00", c: '"' },
  { id: 32018, s: "FLXC.LSE", n: "Franklin FTSE China UCITS ETF - IE00BHZRR147", c: '"' },
  { id: 32019, s: "FLXC.XETRA", n: "Franklin Libertyshares ICAV - Franklin Ftse China Ucits ETF - IE00BHZRR147", c: '"' },
  { id: 32020, s: "FLXD.LSE", n: "Franklin European Quality Dividend UCITS ETF - IE00BF2B0L69", c: '"' },
  { id: 32021, s: "FLXD.SW", n: "Franklin European Quality Dividend UCITS ETF - IE00BF2B0L69", c: '"' },
  { id: 32022, s: "FLXD.XETRA", n: "Franklin European Quality Dividend UCITS ETF - IE00BF2B0L69", c: '"' },
  { id: 32023, s: "FLXE.LSE", n: "Franklin LibertyQ Emerging Markets UCITS ETF - IE00BF2B0K52", c: '"' },
  { id: 32024, s: "FLXE.SW", n: "Franklin Emerging Markets UCITS ETF CHF - IE00BF2B0K52", c: '"' },
  { id: 32025, s: "FLXE.XETRA", n: "Franklin Libertyshares Icav - Franklin LibertyQ Emerging Markets Ucits Etf - IE00BF2B0K52", c: '"' },
  { id: 32026, s: "FLXG.LSE", n: "Franklin LibertyQ Global Equity SRI UCITS ETF - IE00BF2B0N83", c: '"' },
  { id: 32027, s: "FLXG.SW", n: "Franklin Global Equity SRI UCITS ETF CHF - IE00BF2B0N83", c: '"' },
  { id: 32028, s: "FLXG.XETRA", n: "Franklin LibertyShares ICAV - Franklin LibertyQ Global Equity SRI UCITS ETF - IE00BF2B0N83", c: '"' },
  { id: 32029, s: "FLXI.LSE", n: "Franklin FTSE India UCITS ETF - IE00BHZRQZ17", c: '"' },
  { id: 32030, s: "FLXI.XETRA", n: "Franklin Libertyshares ICAV - Franklin Ftse India Ucits ETF - IE00BHZRQZ17", c: '"' },
  { id: 32031, s: "FLXK.LSE", n: "Franklin FTSE Korea UCITS ETF - IE00BHZRR030", c: '"' },
  { id: 32032, s: "FLXK.XETRA", n: "Franklin FTSE Korea UCITS ETF - IE00BHZRR030", c: '"' },
  { id: 32033, s: "FLXP.XETRA", n: "Franklin STOXX Europe 600 Paris Aligned Climate UCITS ETF - IE00BMDPBY65", c: '"' },
  { id: 32034, s: "FLXU.LSE", n: "Franklin LibertyQ U.S. Equity UCITS ETF - IE00BF2B0P08", c: '"' },
  { id: 32035, s: "FLXU.SW", n: "Franklin U.S. Equity UCITS ETF CHF - IE00BF2B0P08", c: '"' },
  { id: 32036, s: "FLXX.LSE", n: "Franklin LibertyQ Global Dividend UCITS ETF - IE00BF2B0M76", c: '"' },
  { id: 32037, s: "FLXX.XETRA", n: "Franklin LibertyShares ICAV - Franklin LibertyQ Global Dividend UCITS ETF - IE00BF2B0M76", c: '"' },
  { id: 32038, s: "FM.US", n: "iShares MSCI Frontier and Select EM ETF - US4642861458", c: '"' },
  { id: 32039, s: "FMAT.US", n: "Fidelity® MSCI Materials Index ETF - US3160928814", c: '"' },
  { id: 32040, s: "FMB.US", n: "First Trust Managed Municipal ETF - US33739N1081", c: '"' },
  { id: 32041, s: "FMF.US", n: "First Trust Managed Futures Strategy Fund - US33739G1031", c: '"' },
  { id: 32042, s: "FMHI.US", n: "First Trust Municipal High Income ETF - US33739P3010", c: '"' },
  { id: 32043, s: "FMI.PA", n: "Amundi Index Solutions - Amundi FTSE MIB UCITS ETF-C - LU1681037518", c: '"' },
  { id: 32044, s: "FMIL.US", n: "Fidelity® New Millennium ETF - US3160923609", c: '"' },
  { id: 32045, s: "FNCE.LSE", n: "SPDR® MSCI Europe Financials UCITS ETF GBP - IE00BKWQ0G16", c: '"' },
  { id: 32046, s: "FNCL.LSE", n: "SPDR® MSCI Europe Financials UCITS ETF - IE00BKWQ0G16", c: '"' },
  { id: 32047, s: "FNCL.US", n: "Fidelity® MSCI Financials Index ETF - US3160925018", c: '"' },
  { id: 32048, s: "FNCW.LSE", n: "SPDR® MSCI World Financials UCITS ETF USD Acc GBP - IE00BYTRR970", c: '"' },
  { id: 32049, s: "FNDA.US", n: "Schwab Fundamental U.S. Small Company Index ETF - US8085247638", c: '"' },
  { id: 32050, s: "FNDB.US", n: "Schwab Fundamental U.S. Broad Market Index ETF - US8085247893", c: '"' },
  { id: 32051, s: "FNDC.US", n: "Schwab Fundamental International Small Company Index ETF - US8085247489", c: '"' },
  { id: 32052, s: "FNDE.US", n: "Schwab Fundamental Emerging Markets Large Company Index ETF - US8085247307", c: '"' },
  { id: 32053, s: "FNDF.US", n: "Schwab Fundamental International Large Company Index ETF - US8085247554", c: '"' },
  { id: 32054, s: "FNDX.US", n: "Schwab Fundamental U.S. Large Company Index ETF - US8085247711", c: '"' },
  { id: 32055, s: "FNK.US", n: "First Trust Mid Cap Value AlphaDEX® Fund - US33737M2017", c: '"' },
  { id: 32056, s: "FNX.US", n: "First Trust Mid Cap Core AlphaDEX® Fund - US33735B1089", c: '"' },
  { id: 32057, s: "FNY.US", n: "First Trust Mid Cap Growth AlphaDEX® Fund - US33737M1027", c: '"' },
  { id: 32058, s: "FOGB.LSE", n: "Rize Sustainable Future of Food UCITS ETF A USD - IE00BLRPQH31", c: '"' },
  { id: 32059, s: "FOO.PA", n: "Lyxor Index Fund - Lyxor Stoxx Europe 600 Food & Beverage UCITS ETF - LU1834985845", c: '"' },
  { id: 32060, s: "FOOD.AU", n: "BetaShares Global Agriculture Companies - Currency Hedged - AU00000FOOD3", c: '"' },
  { id: 32061, s: "FOOD.LSE", n: "Rize Sustainable Future of Food UCITS ETF A USD - IE00BLRPQH31", c: '"' },
  { id: 32062, s: "FOVL.US", n: "iShares Focused Value Factor - US46435U3335", c: '"' },
  { id: 32063, s: "FPA.US", n: "First Trust Asia Pacific Ex-Japan AlphaDEX® Fund - US33737J1097", c: '"' },
  { id: 32064, s: "FPE.US", n: "First Trust Preferred Securities and Income ETF - US33739E1082", c: '"' },
  { id: 32065, s: "FPEI.US", n: "First Trust Institutional Preferred Securities and Income ETF - US33739P8555", c: '"' },
  { id: 32066, s: "FPX.LSE", n: "First Trust Global Funds Public Limited Company - First Trust US IPO Index UCITS ETF - IE00BYTH6238", c: '"' },
  { id: 32067, s: "FPX.US", n: "First Trust US Equity Opportunities ETF - US3369201039", c: '"' },
  { id: 32068, s: "FPXE.US", n: "First Trust IPOX Europe Equity Opportunities ETF - US33734X7883", c: '"' },
  { id: 32069, s: "FPXI.US", n: "First Trust International Equity Opportunities ETF - US33734X8535", c: '"' },
  { id: 32070, s: "FQAL.US", n: "Fidelity® Quality Factor ETF - US3160927907", c: '"' },
  { id: 32071, s: "FQC.TO", n: "CI MSCI Canada Quality Index Class ETF - CA17163X3022", c: '"' },
  { id: 32072, s: "FRC3.XETRA", n: "UBS(Lux)Fund Solutions – Bloomberg Euro Inflation Linked 1-10 UCITS ETF(EUR)A-dis - LU1645380368", c: '"' },
  { id: 32073, s: "FRCH.LSE", n: "Franklin FTSE China UCITS ETF GBP - IE00BHZRR147", c: '"' },
  { id: 32074, s: "FRCJ.F", n: "UBS MSCI Japan Socially Responsible UCITS JPY A-dis - LU1230561679", c: '"' },
  { id: 32075, s: "FRCJ.XETRA", n: "UBS MSCI Japan Socially Responsible UCITS JPY A-dis - LU1230561679", c: '"' },
  { id: 32076, s: "FRCK.XETRA", n: "UBS(Lux)Fund Solutions – Bloomberg USD Emerging Markets Sovereign UCITS ETF(hedgedEUR)A-acc - LU1324516308", c: '"' },
  { id: 32077, s: "FRDM.US", n: "Freedom 100 Emerging Markets ETF - US02072L6074", c: '"' },
  { id: 32078, s: "FREL.US", n: "Fidelity® MSCI Real Estate Index ETF - US3160928574", c: '"' },
  { id: 32079, s: "FREM.LSE", n: "Franklin LibertyQ Emerging Markets UCITS ETF - IE00BF2B0K52", c: '"' },
  { id: 32080, s: "FREQ.LSE", n: "Franklin LibertyQ European Equity UCITS ETF - IE00BFWXDW46", c: '"' },
  { id: 32081, s: "FRGD.LSE", n: "Franklin LibertyQ Global Dividend UCITS ETF - IE00BF2B0M76", c: '"' },
  { id: 32082, s: "FRGE.LSE", n: "Franklin LibertyQ Global Equity SRI UCITS ETF - IE00BF2B0N83", c: '"' },
  { id: 32083, s: "FRI.US", n: "First Trust S&P REIT Index Fund - US33734G1085", c: '"' },
  { id: 32084, s: "FRIN.LSE", n: "Franklin FTSE India UCITS ETF GBP - IE00BHZRQZ17", c: '"' },
  { id: 32085, s: "FRNE.F", n: "Amundi Index Solutions - Amundi Floating Rate Euro Corporate 1-3 UCITS ETF - FR0012005734", c: '"' },
  { id: 32086, s: "FRNE.XETRA", n: "Amundi Index Solutions - Amundi Floating Rate EURO Corporate ESG - UCITS ETF DR - EUR (C) EUR - LU1681041114", c: '"' },
  { id: 32087, s: "FRNH.F", n: "Amundi Index Solutions - Amundi ETF Floating Rate USD Corporate - FR0013141462", c: '"' },
  { id: 32088, s: "FRNU.F", n: "Amundi Index Solutions - Amundi ETF Floating Rate USD Corporate - FR0012647451", c: '"' },
  { id: 32089, s: "FRQX.LSE", n: "Franklin LibertyQ AC Asia ex Japan UCITS ETF - IE00BFWXDV39", c: '"' },
  { id: 32090, s: "FRUE.LSE", n: "Franklin LibertyQ U.S. Equity UCITS ETF - IE00BF2B0P08", c: '"' },
  { id: 32091, s: "FRXD.LSE", n: "Franklin LibertyQ European Dividend UCITS ETF EUR - IE00BF2B0L69", c: '"' },
  { id: 32092, s: "FSEU.LSE", n: "iShares Edge MSCI Europe Multifactor UCITS - IE00BZ0PKV06", c: '"' },
  { id: 32093, s: "FSKY.LSE", n: "First Trust Cloud Computing UCITS ETF Class A USD Accumulation - IE00BFD2H405", c: '"' },
  { id: 32094, s: "FSL.TO", n: "First Trust Senior Loan Common - CA33739J1075", c: '"' },
  { id: 32095, s: "FSMB.US", n: "First Trust Short Duration Managed Municipal ETF - US33739P8308", c: '"' },
  { id: 32096, s: "FSMD.US", n: "Fidelity Small-Mid Factor - US3160925273", c: '"' },
  { id: 32097, s: "FSOV.AS", n: "First Trust Low Duration Global Government Bond UCITS ETF - IE00BKS2X317", c: '"' },
  { id: 32098, s: "FSTA.US", n: "Fidelity® MSCI Consumer Staples Index ETF - US3160923039", c: '"' },
  { id: 32099, s: "FSUS.LSE", n: "iShares Edge MSCI USA Multifactor UCITS - IE00BZ0PKS76", c: '"' },
  { id: 32100, s: "FSZ.US", n: "First Trust Switzerland AlphaDEX® Fund - US33737J2327", c: '"' },
  { id: 32101, s: "FTA.US", n: "First Trust Large Cap Value AlphaDEX® Fund - US33735J1016", c: '"' },
  { id: 32102, s: "FTAD.LSE", n: "SPDR® FTSE UK All Share UCITS ETF GBP (Dist) - IE00BD5FCF91", c: '"' },
  { id: 32103, s: "FTAD.SW", n: "SPDR® FTSE UK All Share UCITS ETF GBP (Dist) CHF - IE00BD5FCF91", c: '"' },
  { id: 32104, s: "FTAG.US", n: "First Trust Indxx Global Agriculture ETF - US33734X8121", c: '"' },
  { id: 32105, s: "FTAL.LSE", n: "SPDR FTSE UK All Share UCITS ETF Acc - IE00B7452L46", c: '"' },
  { id: 32106, s: "FTAL.SW", n: "SPDR® FTSE UK All Share UCITS ETF Acc CHF - IE00B7452L46", c: '"' },
  { id: 32107, s: "FTC.US", n: "First Trust Large Cap Growth AlphaDEX® Fund - US33735K1088", c: '"' },
  { id: 32108, s: "FTCS.US", n: "First Trust Capital Strength ETF - US33733E1047", c: '"' },
  { id: 32109, s: "FTDS.US", n: "First Trust Dividend Strength ETF - US33733E7085", c: '"' },
  { id: 32110, s: "FTEC.US", n: "Fidelity® MSCI Information Technology Index ETF - US3160928087", c: '"' },
  { id: 32111, s: "FTEU.LSE", n: "First Trust Eurozone AlphaDEX® UCITS ETF Class A Shares - IE00B8X9NY41", c: '"' },
  { id: 32112, s: "FTGC.US", n: "First Trust Global Tactical Commodity Strategy Fund - US33739H1014", c: '"' },
  { id: 32113, s: "FTGE.XETRA", n: "First Trust Eurozone AlphaDEX® ETF - IE00B8X9NY41", c: '"' },
  { id: 32114, s: "FTGU.XETRA", n: "First Trust US Large Cap Core AlphaDEX® UCITS ETF Class A USD - DE000A2DLXT7", c: '"' },
  { id: 32115, s: "FTHI.US", n: "First Trust BuyWrite Income ETF - US33738R3084", c: '"' },
  { id: 32116, s: "FTLB.US", n: "First Trust Nasdaq BuyWrite Income ETF - US33738R4074", c: '"' },
  { id: 32117, s: "FTLS.US", n: "First Trust Long/Short Equity ETF - US33739P1030", c: '"' },
  { id: 32118, s: "FTQI.US", n: "First Trust Nasdaq BuyWrite Income ETF - US33738R4074", c: '"' },
  { id: 32119, s: "FTRI.US", n: "First Trust Indxx Global Natural Resources Income ETF - US33734X8386", c: '"' },
  { id: 32120, s: "FTSD.US", n: "Franklin Liberty Short Duration U.S. Government ETF - US3535061085", c: '"' },
  { id: 32121, s: "FTSL.US", n: "First Trust Senior Loan Fund - US33738D3098", c: '"' },
  { id: 32122, s: "FTSM.US", n: "First Trust Enhanced Short Maturity ETF - US33739Q4082", c: '"' },
  { id: 32123, s: "FTXD.US", n: "First Trust Nasdaq Retail ETF - US33738R8299", c: '"' },
  { id: 32124, s: "FTXG.US", n: "First Trust Nasdaq Food & Beverage ETF - US33738R8521", c: '"' },
  { id: 32125, s: "FTXH.US", n: "First Trust Nasdaq Pharmaceuticals ETF - US33738R8372", c: '"' },
  { id: 32126, s: "FTXL.US", n: "First Trust Nasdaq Semiconductor ETF - US33738R8117", c: '"' },
  { id: 32127, s: "FTXN.US", n: "First Trust Nasdaq Oil & Gas ETF - US33738R8455", c: '"' },
  { id: 32128, s: "FTXO.US", n: "First Trust Nasdaq Bank ETF - US33738R8604", c: '"' },
  { id: 32129, s: "FTXR.US", n: "First Trust Nasdaq Transportation ETF - US33738R7952", c: '"' },
  { id: 32130, s: "FUD.TO", n: "First Trust AlphaDEX US Dividend Common - CA33740H1082", c: '"' },
  { id: 32131, s: "FUEL.AU", n: "Betashares Global Energy Companies - AU00000FUEL4", c: '"' },
  { id: 32132, s: "FUMB.US", n: "First Trust Ultra Short Duration Municipal - US33740J1043", c: '"' },
  { id: 32133, s: "FUNL.US", n: "CornerCap Fundametrics® Large-Cap ETF FUNL - US89832P2671", c: '"' },
  { id: 32134, s: "FUQA.LSE", n: "Fidelity US Quality Income ETF Acc - IE00BYXVGY31", c: '"' },
  { id: 32135, s: "FUSA.LSE", n: "Fidelity US Quality Income ETF Acc - IE00BYXVGY31", c: '"' },
  { id: 32136, s: "FUSA.XETRA", n: "Fidelity US Quality Income ETF Acc - IE00BYXVGY31", c: '"' },
  { id: 32137, s: "FUSD.LSE", n: "Fidelity UCITS SICAV - Fidelity US Quality Income UCITS ETF - IE00BYXVGX24", c: '"' },
  { id: 32138, s: "FUSD.XETRA", n: "Fidelity UCITS SICAV - Fidelity US Quality Income UCITS ETF - IE00BYXVGX24", c: '"' },
  { id: 32139, s: "FUSI.LSE", n: "Fidelity US Quality Income ETF Inc - IE00BYXVGX24", c: '"' },
  { id: 32140, s: "FUSR.LSE", n: "Fidelity Sustainable Research Enhanced US Equity UCITS ETF USD Acc - IE00BKSBGS44", c: '"' },
  { id: 32141, s: "FUSR.SW", n: "Fidelity Sustainable Research Enhanced US Equity UCITS ETF USD Acc CHF - IE00BKSBGS44", c: '"' },
  { id: 32142, s: "FUSR.XETRA", n: "Fidelity Sustainable Research Enhanced US Equity UCITS ETF USD Acc - IE00BKSBGS44", c: '"' },
  { id: 32143, s: "FUSS.LSE", n: "Fidelity Sustainable Research Enhanced US Equity UCITS ETF USD Acc GBP - IE00BKSBGS44", c: '"' },
  { id: 32144, s: "FUTY.US", n: "Fidelity® MSCI Utilities Index ETF - US3160928657", c: '"' },
  { id: 32145, s: "FV.US", n: "First Trust Dorsey Wright Focus 5 ETF - US33738R6053", c: '"' },
  { id: 32146, s: "FVAL.US", n: "Fidelity® Value Factor ETF - US3160927824", c: '"' },
  { id: 32147, s: "FVC.US", n: "First Trust Dorsey Wright Dynamic Focus 5 ETF - US33738R8786", c: '"' },
  { id: 32148, s: "FVD.LSE", n: "First Trust Value Line® Dividend Index UCITS ETF Class A USD Accumulation - IE00BKVKW020", c: '"' },
  { id: 32149, s: "FVD.US", n: "First Trust Value Line® Dividend Index Fund - US33734H1068", c: '"' },
  { id: 32150, s: "FVD.XETRA", n: "First Trust Value Line Dividend Index UCITS ETF - IE00BKVKW020", c: '"' },
  { id: 32151, s: "FVSH.XETRA", n: "Franklin Libertyshares ICAV - Franklin Liberty Euro Short Maturity Ucits Etf - IE00BFWXDY69", c: '"' },
  { id: 32152, s: "FVSJ.XETRA", n: "Franklin LibertyQ AC Asia ex Japan UCITS ETF - IE00BFWXDV39", c: '"' },
  { id: 32153, s: "FVUB.LSE", n: "Franklin FTSE Brazil UCITS ETF GBP - IE00BHZRQY00", c: '"' },
  { id: 32154, s: "FVUE.XETRA", n: "Franklin LibertyQ European Equity UCITS ETF - IE00BFWXDW46", c: '"' },
  { id: 32155, s: "FVUI.XETRA", n: "Franklin LibertyShares ICAV - Franklin Liberty USD Investment Grade Corporate Bond UCITS ETF - IE00BFWXDX52", c: '"' },
  { id: 32156, s: "FXA.US", n: "Invesco CurrencyShares® Australian Dollar Trust - US46090N1037", c: '"' },
  { id: 32157, s: "FXB.US", n: "Invesco CurrencyShares® British Pound Sterling Trust - US46138M1099", c: '"' },
  { id: 32158, s: "FXC.AS", n: "iShares China Large Cap UCITS - IE00B02KXK85", c: '"' },
  { id: 32159, s: "FXC.LSE", n: "iShares China Large Cap UCITS - IE00B02KXK85", c: '"' },
  { id: 32160, s: "FXC.SW", n: "iShares China Large Cap UCITS ETF USD (Dist) - IE00B02KXK85", c: '"' },
  { id: 32161, s: "FXC.US", n: "Invesco CurrencyShares® Canadian Dollar Trust - US46138T1043", c: '"' },
  { id: 32162, s: "FXD.US", n: "First Trust Consumer Discretionary AlphaDEX® Fund - US33734X1019", c: '"' },
  { id: 32163, s: "FXE.US", n: "Invesco CurrencyShares® Euro Currency Trust - US46138K1034", c: '"' },
  { id: 32164, s: "FXF.US", n: "Invesco CurrencyShares® Swiss Franc Trust - US46138R1086", c: '"' },
  { id: 32165, s: "FXG.US", n: "First Trust Consumer Staples AlphaDEX® Fund - US33734X1191", c: '"' },
  { id: 32166, s: "FXH.US", n: "First Trust Health Care AlphaDEX® Fund - US33734X1431", c: '"' },
  { id: 32167, s: "FXI.US", n: "iShares China Large-Cap ETF - US4642871846", c: '"' },
  { id: 32168, s: "FXL.US", n: "First Trust Technology AlphaDEX® Fund - US33734X1761", c: '"' },
  { id: 32169, s: "FXM.TO", n: "First Asset Morningstar Canada Value Common Class - CA12554N1042", c: '"' },
  { id: 32170, s: "FXN.US", n: "First Trust Energy AlphaDEX® Fund - US33734X1274", c: '"' },
  { id: 32171, s: "FXO.US", n: "First Trust Financials AlphaDEX® Fund - US33734X1357", c: '"' },
  { id: 32172, s: "FXP.US", n: "ProShares UltraShort FTSE China 50 - US74347B2271", c: '"' },
  { id: 32173, s: "FXR.US", n: "First Trust Industrials/Producer Durables AlphaDEX® Fund - US33734X1506", c: '"' },
  { id: 32174, s: "FXU.US", n: "First Trust Utilities AlphaDEX® Fund - US33734X1845", c: '"' },
  { id: 32175, s: "FXY.US", n: "Invesco CurrencyShares® Japanese Yen Trust - US46138W1071", c: '"' },
  { id: 32176, s: "FXZ.US", n: "First Trust Materials AlphaDEX® Fund - US33734X1688", c: '"' },
  { id: 32177, s: "FYC.US", n: "First Trust Small Cap Growth AlphaDEX® Fund - US33737M3007", c: '"' },
  { id: 32178, s: "FYEM.XETRA", n: "Fidelity Emerging Markets Quality Income UCITS ETF USD Acc - IE00BYSX4846", c: '"' },
  { id: 32179, s: "FYEQ.XETRA", n: "Fidelity Emerging Markets Quality Income UCITS ETF USD Inc - IE00BYSX4739", c: '"' },
  { id: 32180, s: "FYLD.US", n: "Cambria Foreign Shareholder Yield ETF - US1320613003", c: '"' },
  { id: 32181, s: "FYT.US", n: "First Trust Small Cap Value AlphaDEX® Fund - US33737M4096", c: '"' },
  { id: 32182, s: "FYX.US", n: "First Trust Small Cap Core AlphaDEX® Fund - US33734Y1091", c: '"' },
  { id: 32183, s: "G2X.F", n: "VanEck Gold Miners UCITS ETF - IE00BQQP9F84", c: '"' },
  { id: 32184, s: "G2X.XETRA", n: "VanEck Gold Miners UCITS ETF - IE00BQQP9F84", c: '"' },
  { id: 32185, s: "G2XJ.F", n: "VanEck Junior Gold Miners UCITS ETF - IE00BQQP9G91", c: '"' },
  { id: 32186, s: "G2XJ.XETRA", n: "VanEck Junior Gold Miners UCITS ETF - IE00BQQP9G91", c: '"' },
  { id: 32187, s: "GAA.US", n: "Cambria Global Asset Allocation ETF - US1320616071", c: '"' },
  { id: 32188, s: "GAAA.LSE", n: "iShares Global AAA-AA Govt Bond UCITS ETF USD (Acc) - IE00BZ171348", c: '"' },
  { id: 32189, s: "GACA.XETRA", n: "Goldman Sachs ActiveBeta US Large Cap Equity UCITS ETF USD Acc - IE00BJ5CNR11", c: '"' },
  { id: 32190, s: "GACB.XETRA", n: "Goldman Sachs ActiveBeta Emerging Market Equity UCITS ETF A USD Acc - IE00BJ5CMD00", c: '"' },
  { id: 32191, s: "GAGG.LSE", n: "Amundi Index Solutions - Amundi Index Barclays Global Agg 500M - LU1437024729", c: '"' },
  { id: 32192, s: "GAGG.PA", n: "Amundi Index Solutions - Amundi Index Barclays Global Agg 500M - LU1437024729", c: '"' },
  { id: 32193, s: "GAL.US", n: "SPDR® SSgA Global Allocation ETF - US78467V4005", c: '"' },
  { id: 32194, s: "GAMR.US", n: "Wedbush ETFMG Video Game Tech ETF - US26924G7060", c: '"' },
  { id: 32195, s: "GASF.XETRA", n: "Goldman Sachs Access China Government Bond UCITS ETF USD Inc - IE00BJSBCS90", c: '"' },
  { id: 32196, s: "GBAL.TO", n: "iShares ESG Balanced ETF Portfolio - CA46436W1068", c: '"' },
  { id: 32197, s: "GBDV.LSE", n: "SPDR S&P Global Dividend Aristocrats UCITS - IE00B9CQXS71", c: '"' },
  { id: 32198, s: "GBF.US", n: "iShares Government/Credit Bond ETF - US4642885960", c: '"' },
  { id: 32199, s: "GBIL.US", n: "Goldman Sachs Access Treasury 0-1 Year ETF - US3814305294", c: '"' },
  { id: 32200, s: "GBRE.LSE", n: "SPDR® Dow Jones Global Real Estate UCITS ETF - IE00B8GF1M35", c: '"' },
  { id: 32201, s: "GBRE.SW", n: "SPDR® Dow Jones Global Real Estate UCITS ETF CHF - IE00B8GF1M35", c: '"' },
  { id: 32202, s: "GC40.F", n: "Amundi Index Solutions - AMUNDI CAC 40 ESG UCITS ETF DR - EUR (C) - LU1681046931", c: '"' },
  { id: 32203, s: "GC40.XETRA", n: "Amundi Index Solutions - AMUNDI CAC 40 ESG UCITS ETF DR - EUR (C) - LU1681046931", c: '"' },
  { id: 32204, s: "GCC.US", n: "WisdomTree Continuous Commodity Index Fund - US97717Y6831", c: '"' },
  { id: 32205, s: "GCLM.LSE", n: "Amundi MSCI World Climate Transition CTB - UCITS ETF DR - USD-C USD - LU1602144492", c: '"' },
  { id: 32206, s: "GCNS.TO", n: "iShares ESG Conservative Balanced ETF Portfolio - CA46437R1064", c: '"' },
  { id: 32207, s: "GCOR.US", n: "Goldman Sachs Access U.S. Aggregate Bond ETF - US38149W1018", c: '"' },
  { id: 32208, s: "GCOW.US", n: "Pacer Global Cash Cows Dividend ETF - US69374H7098", c: '"' },
  { id: 32209, s: "GCVB.LSE", n: "SSgA SPDR ETFs Europe II plc - SPDR Thomson Reuters Global Convertible Bond UCITS ETF - IE00BNH72088", c: '"' },
  { id: 32210, s: "GCVB.SW", n: "SPDR Refinitiv Global Convertible Bond UCITS ETF CHF - IE00BNH72088", c: '"' },
  { id: 32211, s: "GCVC.SW", n: "SPDR Refinitiv Global Convertible Bond CHF Hdg UCITS ETF (Acc) - IE00BDT6FS23", c: '"' },
  { id: 32212, s: "GDGB.LSE", n: "VanEck Gold Miners UCITS ETF - IE00BQQP9F84", c: '"' },
  { id: 32213, s: "GDIG.LSE", n: "VanEck Global Mining UCITS A - IE00BDFBTQ78", c: '"' },
  { id: 32214, s: "GDIG.SW", n: "VanEck Global Mining UCITS ETF A USD CHF - IE00BDFBTQ78", c: '"' },
  { id: 32215, s: "GDIV.US", n: "Harbor Dividend Growth Leaders ETF - US41151J7037", c: '"' },
  { id: 32216, s: "GDMA.US", n: "Alpha Architect Gdsdn Dynmc Mlt-Asst ETF - US02072L8708", c: '"' },
  { id: 32217, s: "GDVD.US", n: "Principal Active Global Dividend Income ETF - US74255Y7067", c: '"' },
  { id: 32218, s: "GDX.LSE", n: "VanEck Gold Miners UCITS ETF - IE00BQQP9F84", c: '"' },
  { id: 32219, s: "GDX.US", n: "VanEck Gold Miners ETF - US92189F1066", c: '"' },
  { id: 32220, s: "GDXJ.LSE", n: "VanEck Junior Gold Miners UCITS ETF - IE00BQQP9G91", c: '"' },
  { id: 32221, s: "GDXJ.SW", n: "VanEck Junior Gold Miners UCITS ETF CHF - IE00BQQP9G91", c: '"' },
  { id: 32222, s: "GDXJ.US", n: "VanEck Junior Gold Miners ETF - US92189F7915", c: '"' },
  { id: 32223, s: "GEAR.AU", n: "BetaShares Geared Australian Equity Hedge - AU00000GEAR1", c: '"' },
  { id: 32224, s: "GEDM.LSE", n: "iShares MSCI EM IMI ESG Screened UCITS ETF USD (Dist) GBP - IE00BFNM3N12", c: '"' },
  { id: 32225, s: "GEM.US", n: "Goldman Sachs ActiveBeta® Emerging Markets Equity ETF - US3814302069", c: '"' },
  { id: 32226, s: "GEND.LSE", n: "Lyxor Global Gender Equality DR UCITS C - LU1691909508", c: '"' },
  { id: 32227, s: "GENDED.SW", n: "UBS (Irl) ETF plc - Global Gender Equality UCITS ETF USD A Dis CHF - IE00BDR5GT91", c: '"' },
  { id: 32228, s: "GENE.LSE", n: "UBS (Irl) ETF Public Limited Company - Global Gender Equality UCITS ETF - IE00BDR5GV14", c: '"' },
  { id: 32229, s: "GENY.US", n: "Principal Millennials ETF - US74255Y5087", c: '"' },
  { id: 32230, s: "GENY.XETRA", n: "Lyxor Index Fund - Lyxor MSCI Millennials ESG Filtered (DR) UCITS ETF - LU2023678449", c: '"' },
  { id: 32231, s: "GEQT.TO", n: "iShares ESG Equity ETF Portfolio - CA46436Q1090", c: '"' },
  { id: 32232, s: "GERM.US", n: "ETFMG Treatments Testing and Advancements ETF - US26924G7631", c: '"' },
  { id: 32233, s: "GFA.LSE", n: "VanEck Global Fallen Angel High Yield Bond UCITS ETF - IE00BF540Z61", c: '"' },
  { id: 32234, s: "GFAA.SW", n: "VanEck Global Fallen Angel High Yield Bond UCITS ETF A USD CHF - IE00BF540Z61", c: '"' },
  { id: 32235, s: "GFEA.XETRA", n: "VanEck Global Fallen Angel High Yield Bond UCITS ETF A USD EUR - IE00BF540Z61", c: '"' },
  { id: 32236, s: "GGMUSY.SW", n: "UBS (Irl) ETF plc - Solactive Global Pure Gold Miners UCITS ETF (USD) A-dis CHF - IE00B7KMNP07", c: '"' },
  { id: 32237, s: "GGOV.LSE", n: "Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies - LU1437016204", c: '"' },
  { id: 32238, s: "GGOV.PA", n: "Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies - LU1437016204", c: '"' },
  { id: 32239, s: "GGRA.LSE", n: "WisdomTree Global Quality Dividend Growth UCITS ETF - USD Acc - IE00BZ56SW52", c: '"' },
  { id: 32240, s: "GGRA.SW", n: "WisdomTree Global Quality Dividend Growth UCITS ETF - USD Acc USD - IE00BZ56SW52", c: '"' },
  { id: 32241, s: "GGRG.LSE", n: "WisdomTree Global Quality Dividend Growth UCITS ETF - USD Acc - IE00BZ56SW52", c: '"' },
  { id: 32242, s: "GGRO.TO", n: "iShares ESG Growth ETF Portfolio - CA46438K1003", c: '"' },
  { id: 32243, s: "GGRP.LSE", n: "WisdomTree Global Quality Dividend Growth UCITS ETF - USD - IE00BZ56RN96", c: '"' },
  { id: 32244, s: "GGRW.LSE", n: "WisdomTree Global Quality Dividend Growth UCITS ETF - USD - IE00BZ56RN96", c: '"' },
  { id: 32245, s: "GGUS.AU", n: "BetaShares Geared US Equity Currency Hedged - AU00000GGUS2", c: '"' },
  { id: 32246, s: "GHYB.US", n: "Goldman Sachs Access High Yield Corporate Bond ETF - US3814304537", c: '"' },
  { id: 32247, s: "GHYC.SW", n: "iShares Global High Yield Corp Bond CHF Hedged UCITS ETF (Dist) - IE00B988C465", c: '"' },
  { id: 32248, s: "GHYG.US", n: "iShares US & Intl High Yield Corp Bond ETF - US4642861789", c: '"' },
  { id: 32249, s: "GHYS.LSE", n: "iShares Global High Yield Corporate Bond GBP Hedged UCITS GBP - IE00B8KQFS66", c: '"' },
  { id: 32250, s: "GIGB.LSE", n: "VanEck Global Mining UCITS A - IE00BDFBTQ78", c: '"' },
  { id: 32251, s: "GIGB.US", n: "Goldman Sachs Access Investment Grade Corporate Bond ETF - US3814304792", c: '"' },
  { id: 32252, s: "GIGE.US", n: "SoFi Gig Economy ETF - US8863645045", c: '"' },
  { id: 32253, s: "GIGR.TO", n: "Guardian i3 Global REIT ETF Hedged - CA40139R1001", c: '"' },
  { id: 32254, s: "GII.US", n: "SPDR® S&P Global Infrastructure ETF - US78463X8552", c: '"' },
  { id: 32255, s: "GIL5.LSE", n: "Lyxor UK Government Bond 0-5Y (DR) UCITS ETF - Dist-GBP - LU1439943090", c: '"' },
  { id: 32256, s: "GILE.LSE", n: "iShares Global Inflation Linked Govt Bond UCITS ETF EUR Hedged (Dist) - IE00BD8PH174", c: '"' },
  { id: 32257, s: "GILI.LSE", n: "Lyxor Core UK Government Inflation-Linked Bond (DR) UCITS ETF - Dist-GBP - LU1407893301", c: '"' },
  { id: 32258, s: "GILS.LSE", n: "Lyxor Core UK Government Bond (DR) UCITS ETF - Dist-GBP - LU1407892592", c: '"' },
  { id: 32259, s: "GILS.PA", n: "Lyxor UCITS Iboxx GBP Gilts - LU1407892592", c: '"' },
  { id: 32260, s: "GIN.LSE", n: "SPDR Morningstar Multi-Asset Global Infrastructure UCITS - IE00BQWJFQ70", c: '"' },
  { id: 32261, s: "GINC.LSE", n: "First Trust Global Equity Income UCITS ETF Class B USD - IE00BD842Y21", c: '"' },
  { id: 32262, s: "GIQG.TO", n: "Guardian i3 Global Quality Growth ETF Hedged - CA40136W1023", c: '"' },
  { id: 32263, s: "GIQU.TO", n: "Guardian i3 US Quality Growth ETF Hedged - CA40090C1005", c: '"' },
  { id: 32264, s: "GISE.SW", n: "Lyxor Core Global Inflation-Linked 1-10Y Bond (DR) UCITS ETF Monthly hedged to EUR Inc - LU1910940268", c: '"' },
  { id: 32265, s: "GIST.SW", n: "Lyxor Core Global Inflation-Linked 1-10Y Bond (DR) UCITS ETF Distribution Dis CHF - LU1910939849", c: '"' },
  { id: 32266, s: "GJGB.LSE", n: "VanEck Junior Gold Miners UCITS ETF - IE00BQQP9G91", c: '"' },
  { id: 32267, s: "GLAC.SW", n: "SPDR® Bloomberg Global Aggregate Bond UCITS ETF CHF Hedged - IE00BF1QPK61", c: '"' },
  { id: 32268, s: "GLAD.SW", n: "SPDR® Bloomberg Global Aggregate Bond UCITS ETF USD Hedged Acc USD - IE00BKC94M46", c: '"' },
  { id: 32269, s: "GLAG.LSE", n: "SPDR Bloomberg Barclays Global Aggregate Bond UCITS USD unhedged - IE00B43QJJ40", c: '"' },
  { id: 32270, s: "GLAG.SW", n: "SPDR® Bloomberg Global Aggregate Bond UCITS ETF USD unhedged USD - IE00B43QJJ40", c: '"' },
  { id: 32271, s: "GLAU.LSE", n: "SPDR® Bloomberg Global Aggregate Bond UCITS ETF USD Hedged - IE00BF1QPH33", c: '"' },
  { id: 32272, s: "GLBL.LSE", n: "SPDR Bloomberg Barclays Global Aggregate Bond UCITS USD unhedged - IE00B43QJJ40", c: '"' },
  { id: 32273, s: "GLCB.LSE", n: "SPDR Thomson Reuters Global Convertible Bond UCITS - IE00BNH72088", c: '"' },
  { id: 32274, s: "GLD.US", n: "SPDR® Gold Shares - US78463V1070", c: '"' },
  { id: 32275, s: "GLDM.PA", n: "Lyxor MSCI ACWI Gold UCITS C EUR - LU0854423687", c: '"' },
  { id: 32276, s: "GLDV.LSE", n: "SSgA SPDR ETFs Europe I Public Limited Company - SPDR S&P Global Dividend Aristocrats UCITS ETF - IE00B9CQXS71", c: '"' },
  { id: 32277, s: "GLDV.SW", n: "SPDR® S&P Global Dividend Aristocrats UCITS ETF CHF - IE00B9CQXS71", c: '"' },
  { id: 32278, s: "GLDVD.SW", n: "UBS (Irl) ETF plc - S&P Dividend Aristocrats ESG Elite UCITS ETF (USD) A-dis - IE00BMP3HG27", c: '"' },
  { id: 32279, s: "GLGG.LSE", n: "L&G Clean Water UCITS ETF - IE00BK5BC891", c: '"' },
  { id: 32280, s: "GLIF.US", n: "AGFiQ Global Infrastructure ETF - US00110G6061", c: '"' },
  { id: 32281, s: "GLIN.US", n: "VanEck India Growth Leaders ETF - US92189F7675", c: '"' },
  { id: 32282, s: "GLL.US", n: "ProShares UltraShort Gold - US74347W3951", c: '"' },
  { id: 32283, s: "GLRA.LSE", n: "SPDR® Dow Jones Global Real Estate UCITS ETF USD Cap - IE00BH4GR342", c: '"' },
  { id: 32284, s: "GLRA.SW", n: "SPDR® Dow Jones Global Real Estate UCITS ETF USD Cap CHF - IE00BH4GR342", c: '"' },
  { id: 32285, s: "GLRE.LSE", n: "SSgA SPDR ETFs Europe I Public Limited Company - SPDR Dow Jones Global Real Estate UCITS ETF - IE00B8GF1M35", c: '"' },
  { id: 32286, s: "GLTL.LSE", n: "SPDR Barclays Capital 15+ Year Gilt - IE00B6YX5L24", c: '"' },
  { id: 32287, s: "GLTR.US", n: "abrdn Physical Precious Metals Basket Shares ETF - US0032631006", c: '"' },
  { id: 32288, s: "GLTS.LSE", n: "SPDR Barclays Capital 1-5 Year Gilt - IE00B6YX5K17", c: '"' },
  { id: 32289, s: "GLTY.LSE", n: "SSgA SPDR Barclays Capital UK Gilt - IE00B3W74078", c: '"' },
  { id: 32290, s: "GLUG.LSE", n: "L&G Clean Water UCITS ETF - IE00BK5BC891", c: '"' },
  { id: 32291, s: "GLUG.SW", n: "L&G Clean Water UCITS ETF CHF - IE00BK5BC891", c: '"' },
  { id: 32292, s: "GLUX.PA", n: "Amundi Index Solutions - Amundi S&P Global Luxury ETF-C EUR - LU1681048630", c: '"' },
  { id: 32293, s: "GLUX.XETRA", n: "Amundi Index Solutions - Amundi S&P Global Luxury ETF-C EUR - LU1681048630", c: '"' },
  { id: 32294, s: "GMF.US", n: "SPDR® S&P Emerging Asia Pacific ETF - US78463X3017", c: '"' },
  { id: 32295, s: "GMOM.US", n: "Cambria Global Momentum ETF - US1320615081", c: '"' },
  { id: 32296, s: "GMVM.XETRA", n: "VanEck Morningstar US Sustainable Wide Moat UCITS ETF - IE00BQQP9H09", c: '"' },
  { id: 32297, s: "GNMA.US", n: "iShares GNMA Bond ETF - US46429B3336", c: '"' },
  { id: 32298, s: "GNOM.US", n: "Global X Genomics & Biotechnology ETF - US37954Y4347", c: '"' },
  { id: 32299, s: "GNR.US", n: "SPDR® S&P Global Natural Resources ETF - US78463X5418", c: '"' },
  { id: 32300, s: "GOAT.LSE", n: "VanEck Morningstar Global Wide Moat UCITS ETF A USD - IE00BL0BMZ89", c: '"' },
  { id: 32301, s: "GOAT.US", n: "VanEck Vectors Morningstar Global Wide Moat ETF - US92189F1223", c: '"' },
  { id: 32302, s: "GOAU.US", n: "U.S. Global GO GOLD and Precious Metal Miners ETF - US26922A7191", c: '"' },
  { id: 32303, s: "GOEX.US", n: "Global X Gold Explorers ETF - US37954Y8637", c: '"' },
  { id: 32304, s: "GOGB.LSE", n: "VanEck Morningstar Global Wide Moat UCITS ETF A USD GBP - IE00BL0BMZ89", c: '"' },
  { id: 32305, s: "GOLB.LSE", n: "Market Access NYSE Arca Gold BUGS Index UCITS ETF - LU0259322260", c: '"' },
  { id: 32306, s: "GOVD.SW", n: "Lyxor Core Global Government Bond (DR) UCITS ETF GBP CHF - LU2099288503", c: '"' },
  { id: 32307, s: "GOVS.PA", n: "SPDR Barclays Capital 1-3 Yr Euro Govt Bond - IE00B6YX5F63", c: '"' },
  { id: 32308, s: "GOVT.US", n: "iShares U.S. Treasury Bond ETF - US46429B2676", c: '"' },
  { id: 32309, s: "GOVY.PA", n: "SSgA SPDR ETFs Europe I Public Limited Company - SPDR Barclays Euro Government Bond UCITS ETF - IE00B3S5XW04", c: '"' },
  { id: 32310, s: "GOVY.SW", n: "SPDR® Bloomberg Euro Government Bond UCITS ETF CHF - IE00B3S5XW04", c: '"' },
  { id: 32311, s: "GOVZ.US", n: "iShares 25+ Year Treasury STRIPS Bond ETF - US46436E5776", c: '"' },
  { id: 32312, s: "GPAB.LSE", n: "Lyxor Net Zero 2050 S&P World Climate PAB (DR) UCITS ETF - Acc - LU2198882362", c: '"' },
  { id: 32313, s: "GPAB.SW", n: "Lyxor Net Zero 2050 S&P World Climate PAB (DR) UCITS ETF - Acc CHF - LU2198882362", c: '"' },
  { id: 32314, s: "GPSA.LSE", n: "iShares MSCI USA ESG Screened UCITS ETF USD (Acc) GBP - IE00BFNM3G45", c: '"' },
  { id: 32315, s: "GQRE.US", n: "FlexShares Global Quality Real Estate Index Fund - US33939L7872", c: '"' },
  { id: 32316, s: "GRE.PA", n: "Multi Units France - Lyxor MSCI Greece UCITS ETF - FR0010405431", c: '"' },
  { id: 32317, s: "GREIT.SW", n: "Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) FTSE EPRA Nareit Developed Grn Bl UCITS ETF B USD - IE00BMDX0K95", c: '"' },
  { id: 32318, s: "GREK.US", n: "Global X MSCI Greece ETF - US37954Y3190", c: '"' },
  { id: 32319, s: "GRES.US", n: "IQ Global Resources ETF - US45409B8836", c: '"' },
  { id: 32320, s: "GRID.US", n: "First Trust NASDAQ® Clean Edge® Smart Grid Infrastructure Index Fund - US33737A1088", c: '"' },
  { id: 32321, s: "GRN.US", n: "iPath® Series B Carbon ETN - US06747C3227", c: '"' },
  { id: 32322, s: "GRNB.US", n: "VanEck Green Bond ETF - US92189F1710", c: '"' },
  { id: 32323, s: "GSEM.SW", n: "Goldman Sachs ActiveBeta Emerging Market Equity UCITS ETF A USD Acc USD - IE00BJ5CMD00", c: '"' },
  { id: 32324, s: "GSEU.US", n: "Goldman Sachs ActiveBeta® Europe Equity ETF - US3814303059", c: '"' },
  { id: 32325, s: "GSEW.US", n: "Goldman Sachs Equal Weight U.S. Large Cap Equity ETF - US3814304388", c: '"' },
  { id: 32326, s: "GSG.US", n: "iShares S&P GSCI Commodity-Indexed Trust - US46428R1077", c: '"' },
  { id: 32327, s: "GSID.US", n: "Goldman Sachs MarketBeta International Equity ETF - US3814301806", c: '"' },
  { id: 32328, s: "GSIE.US", n: "Goldman Sachs ActiveBeta® International Equity ETF - US3814301079", c: '"' },
  { id: 32329, s: "GSIG.US", n: "Goldman Sachs Access Investment Grade Corporate 1-5 Year Bond ETF - US38149W5076", c: '"' },
  { id: 32330, s: "GSJY.US", n: "Goldman Sachs ActiveBeta® Japan Equity ETF - US3814304040", c: '"' },
  { id: 32331, s: "GSLC.SW", n: "Goldman Sachs ActiveBeta US Large Cap Equity UCITS ETF USD Acc USD - IE00BJ5CNR11", c: '"' },
  { id: 32332, s: "GSLC.US", n: "Goldman Sachs ActiveBeta® U.S. Large Cap Equity ETF - US3814305039", c: '"' },
  { id: 32333, s: "GSSC.US", n: "Goldman Sachs ActiveBeta® U.S. Small Cap Equity ETF - US3814306029", c: '"' },
  { id: 32334, s: "GSST.US", n: "Goldman Sachs Access Ultra Short Bond ETF - US3814302309", c: '"' },
  { id: 32335, s: "GSUS.US", n: "Goldman Sachs MarketBeta US Equity ETF - US3814301236", c: '"' },
  { id: 32336, s: "GSY.US", n: "Invesco Ultra Short Duration ETF - US46090A8870", c: '"' },
  { id: 32337, s: "GTIP.US", n: "Goldman Sachs Access Inflation Protected USD Bond ETF - US3814303620", c: '"' },
  { id: 32338, s: "GTO.US", n: "Invesco Total Return Bond ETF - US46090A8045", c: '"' },
  { id: 32339, s: "GUNR.US", n: "FlexShares Morningstar Global Upstream Natural Resources Index Fund - US33939L4077", c: '"' },
  { id: 32340, s: "GURU.US", n: "Global X Guru Index ETF - US37950E3412", c: '"' },
  { id: 32341, s: "GUSH.US", n: "Direxion Daily S&P Oil & Gas Exp. & Prod. Bull 2X Shares - US25460G5009", c: '"' },
  { id: 32342, s: "GVAL.US", n: "Cambria Global Value ETF - US1320614092", c: '"' },
  { id: 32343, s: "GVI.US", n: "iShares Intermediate Government/Credit Bond ETF - US4642886125", c: '"' },
  { id: 32344, s: "GVIP.US", n: "Goldman Sachs Hedge Industry VIP ETF - US3814305450", c: '"' },
  { id: 32345, s: "GWT.PA", n: "Lyxor Index Fund - Lyxor MSCI EMU Growth (DR) UCITS ETF - LU1598688189", c: '"' },
  { id: 32346, s: "GWX.US", n: "SPDR® S&P International Small Cap ETF - US78463X8719", c: '"' },
  { id: 32347, s: "GXC.US", n: "SPDR® S&P China ETF - US78463X4007", c: '"' },
  { id: 32348, s: "GXG.US", n: "Global X MSCI Colombia ETF - US37954Y3273", c: '"' },
  { id: 32349, s: "GXLC.LSE", n: "SPDR® S&P® U.S. Communication Services Select Sector UCITS ETF - IE00BFWFPX50", c: '"' },
  { id: 32350, s: "GXLE.LSE", n: "SPDR® S&P® U.S. Energy Select Sector UCITS ETF GBP - IE00BWBXM492", c: '"' },
  { id: 32351, s: "GXLF.LSE", n: "SPDR® S&P® U.S. Financials Select Sector UCITS ETF GBP - IE00BWBXM500", c: '"' },
  { id: 32352, s: "GXLK.LSE", n: "SPDR® S&P® U.S. Technology Select Sector UCITS ETF GBP - IE00BWBXM948", c: '"' },
  { id: 32353, s: "GXLV.LSE", n: "SPDR® S&P® U.S. Health Care Select Sector UCITS ETF GBP - IE00BWBXM617", c: '"' },
  { id: 32354, s: "GXTG.US", n: "Global X Thematic Growth ETF - US37954Y4180", c: '"' },
  { id: 32355, s: "GYLD.US", n: "Arrow Dow Jones Global Yield ETF - US04273H1041", c: '"' },
  { id: 32356, s: "H410.XETRA", n: "HSBC MSCI Emerging Markets UCITS EUR - IE00B3Z0X395", c: '"' },
  { id: 32357, s: "H411.XETRA", n: "HSBC MSCI AC FAR EAST ex JAPAN UCITS ETF - IE00BBQ2W338", c: '"' },
  { id: 32358, s: "H412.XETRA", n: "HSBC USA Sustainable Equity UCITS ETF - IE00BKY40J65", c: '"' },
  { id: 32359, s: "H413.XETRA", n: "HSBC Europe Sustainable Equity UCITS ETF - IE00BKY55W78", c: '"' },
  { id: 32360, s: "H41C.XETRA", n: "HSBC Developed World Sustainable Equity UCITS ETF - IE00BKY59K37", c: '"' },
  { id: 32361, s: "H41J.XETRA", n: "HSBC Multi Factor Worldwide Equity UCITS - IE00BKZGB098", c: '"' },
  { id: 32362, s: "H41K.XETRA", n: "HSBC MSCI CHINA A UCITS ETF - IE00BF4NQ904", c: '"' },
  { id: 32363, s: "H4Z1.XETRA", n: "HSBC Emerging Market Sustainable Equity UCITS ETF - IE00BKY59G90", c: '"' },
  { id: 32364, s: "H4Z2.XETRA", n: "HSBC Asia Pacific Ex Japan Sustainable Equity UCITS ETF - IE00BKY58G26", c: '"' },
  { id: 32365, s: "H4ZA.XETRA", n: "HSBC EURO STOXX 50 - IE00B4K6B022", c: '"' },
  { id: 32366, s: "H4ZB.XETRA", n: "HSBC FTSE 100 - IE00B42TW061", c: '"' },
  { id: 32367, s: "H4ZC.XETRA", n: "HSBC MSCI Japan - IE00B5VX7566", c: '"' },
  { id: 32368, s: "H4ZD.XETRA", n: "HSBC MSCI USA UCITS EUR - IE00B5WFQ436", c: '"' },
  { id: 32369, s: "H4ZE.XETRA", n: "HSBC MSCI EUROPE UCITS GER - IE00B5BD5K76", c: '"' },
  { id: 32370, s: "H4ZF.F", n: "HSBC S&P 500 UCITS ETF - IE00B5KQNG97", c: '"' },
  { id: 32371, s: "H4ZF.XETRA", n: "HSBC S&P 500 UCITS EUR - IE00B5KQNG97", c: '"' },
  { id: 32372, s: "H4ZG.XETRA", n: "HSBC ETFs Public Limited Company - HSBC MSCI Brazil UCITS ETF - IE00B5W34K94", c: '"' },
  { id: 32373, s: "H4ZH.XETRA", n: "HSBC MSCI Pacific ExJapan - IE00B5SG8Z57", c: '"' },
  { id: 32374, s: "H4ZJ.XETRA", n: "HSBC MSCI World UCITS ETF - IE00B4X9L533", c: '"' },
  { id: 32375, s: "H4ZK.XETRA", n: "HSBC MSCI Turkey UCITS ETF - IE00B5BRQB73", c: '"' },
  { id: 32376, s: "H4ZP.XETRA", n: "HSBC MSCI China UCITS ETF - IE00B44T3H88", c: '"' },
  { id: 32377, s: "H4ZR.F", n: "HSBC ETFs Public Limited Company - HSBC MSCI Canada UCITS ETF - IE00B51B7Z02", c: '"' },
  { id: 32378, s: "H4ZR.XETRA", n: "HSBC MSCI Canada UCITS ETF - IE00B51B7Z02", c: '"' },
  { id: 32379, s: "H4ZT.XETRA", n: "HSBC MSCI Indonesia UCITS ETF - IE00B46G8275", c: '"' },
  { id: 32380, s: "H4ZU.XETRA", n: "HSBC MSCI Taiwan UCITS Capped ETF - IE00B3S1J086", c: '"' },
  { id: 32381, s: "H4ZW.XETRA", n: "HSBC MSCI EM Latin America UCITS ETF - IE00B4TS3815", c: '"' },
  { id: 32382, s: "H50E.LSE", n: "HSBC EURO STOXX 50 UCITS ETF - IE00B4K6B022", c: '"' },
  { id: 32383, s: "H50E.SW", n: "HSBC EURO STOXX 50 UCITS ETF EUR - IE00B4K6B022", c: '"' },
  { id: 32384, s: "HAB.TO", n: "Horizons Active Corporate Bond ETF Common - CA44051F1027", c: '"' },
  { id: 32385, s: "HACK.AU", n: "Betashares Global Cybersecurity - AU00000HACK8", c: '"' },
  { id: 32386, s: "HACK.US", n: "ETFMG Prime Cyber Security ETF - US26924G2012", c: '"' },
  { id: 32387, s: "HAD.TO", n: "Horizons Active Cdn Bond Common Class - CA44051N1134", c: '"' },
  { id: 32388, s: "HAF.TO", n: "Horizons Active Global Fixed Income ETF - CA44052P1172", c: '"' },
  { id: 32389, s: "HAIL.US", n: "SPDR S&P Kensho Smart Mobility - US78468R6898", c: '"' },
  { id: 32390, s: "HAL.TO", n: "Horizons Active Cdn Dividend ETF Common - CA44050P1018", c: '"' },
  { id: 32391, s: "HAP.US", n: "VanEck Natural Resources ETF - US92189F8418", c: '"' },
  { id: 32392, s: "HAUZ.US", n: "Xtrackers International Real Estate ETF - US2330518463", c: '"' },
  { id: 32393, s: "HAWX.US", n: "iShares Currency Hedged MSCI ACWI ex U.S. ETF - US46435G8472", c: '"' },
  { id: 32394, s: "HAZ.TO", n: "Horizons Active Global Dividend Common Class - CA44051G1000", c: '"' },
  { id: 32395, s: "HBA.TO", n: "Hamilton Australian Bank Equal-Weight Index ETF - CA40701D1096", c: '"' },
  { id: 32396, s: "HBAL.TO", n: "Horizons Balanced TRI ETF Portfolio - CA44057P1045", c: '"' },
  { id: 32397, s: "HBB.TO", n: "Horizons CDN Select Universe Bond - CA44055A1093", c: '"' },
  { id: 32398, s: "HBF.TO", n: "Harvest Brand Leaders Plus Income ETF - CA41753L1067", c: '"' },
  { id: 32399, s: "HBRL.SW", n: "HSBC MSCI Brazil UCITS ETF USD - IE00B5W34K94", c: '"' },
  { id: 32400, s: "HBZ.PA", n: "HSBC ETFs Public Limited Company - HSBC MSCI Brazil UCITS ETF - IE00B5W34K94", c: '"' },
  { id: 32401, s: "HCA.TO", n: "Hamilton Canadian Bank Mean Reversion Index ETF - CA40704K1003", c: '"' },
  { id: 32402, s: "HCAD.LSE", n: "HSBC ETFs Public Limited Company - HSBC MSCI Canada UCITS ETF - IE00B51B7Z02", c: '"' },
  { id: 32403, s: "HCAN.LSE", n: "HSBC MSCI Canada UCITS ETF - IE00B51B7Z02", c: '"' },
  { id: 32404, s: "HCAN.PA", n: "HSBC MSCI Canada UCITS ETF - IE00B51B7Z02", c: '"' },
  { id: 32405, s: "HCAN.SW", n: "HSBC MSCI Canada UCITS ETF CAD - IE00B51B7Z02", c: '"' },
  { id: 32406, s: "HCON.TO", n: "Horizons Conservative TRI ETF Portfolio - CA44057Q1028", c: '"' },
  { id: 32407, s: "HCRB.US", n: "Hartford Core Bond ETF - US41653L7010", c: '"' },
  { id: 32408, s: "HCRE.TO", n: "Horizons Equal Weight Canada REIT Index ETF - CA44056F1071", c: '"' },
  { id: 32409, s: "HDAW.US", n: "Xtrackers MSCI All World ex US High Dividend Yield Hedged Equity ETF - US2330515980", c: '"' },
  { id: 32410, s: "HDEF.US", n: "Xtrackers MSCI EAFE High Dividend Yield Equity ETF - US2330516301", c: '"' },
  { id: 32411, s: "HDEM.LSE", n: "Invesco FTSE Emerging Markets High Dividend Low Volatility UCITS ETF - IE00BYYXBF44", c: '"' },
  { id: 32412, s: "HDEU.LSE", n: "PowerShares EURO STOXX High Dividend Low Volatility UCITS - IE00BZ4BMM98", c: '"' },
  { id: 32413, s: "HDG.US", n: "ProShares Hedge Replication ETF - US74347X2945", c: '"' },
  { id: 32414, s: "HDGE.US", n: "AdvisorShares Ranger Equity Bear ETF - US00768Y4127", c: '"' },
  { id: 32415, s: "HDIQ.LSE", n: "iShares MSCI USA Dividend IQ UCITS - IE00BKM4H312", c: '"' },
  { id: 32416, s: "HDLG.LSE", n: "Invesco S&P 500 High Dividend Low Volatility UCITS ETF - IE00BWTN6Y99", c: '"' },
  { id: 32417, s: "HDLV.F", n: "Invesco Markets III plc - Invesco S&P 500 High Dividend Low Volatility UCITS ETF - IE00BWTN6Y99", c: '"' },
  { id: 32418, s: "HDLV.LSE", n: "Invesco S&P 500 High Dividend Low Volatility UCITS ETF - IE00BWTN6Y99", c: '"' },
  { id: 32419, s: "HDLV.PA", n: "Invesco S&P 500 High Dividend Low Volatility UCITS ETF EUR - IE00BWTN6Y99", c: '"' },
  { id: 32420, s: "HDLV.XETRA", n: "Invesco S&P 500 High Dividend Low Volatility UCITS ETF - IE00BWTN6Y99", c: '"' },
  { id: 32421, s: "HDMV.US", n: "First Trust Horizon Managed Volatility Developed International ETF - US33739P8712", c: '"' },
  { id: 32422, s: "HDV.US", n: "iShares Core High Dividend ETF - US46429B6636", c: '"' },
  { id: 32423, s: "HEA.TO", n: "Horizons Enhanced Income US Equity (USD) ETF Common - CA44056T1075", c: '"' },
  { id: 32424, s: "HEAE.LSE", n: "SPDR® MSCI Europe Health Care UCITS ETF GBP - IE00BKWQ0H23", c: '"' },
  { id: 32425, s: "HEAL.LSE", n: "iShares Healthcare Innovation UCITS ETF USD (Acc) - IE00BYZK4776", c: '"' },
  { id: 32426, s: "HEAW.LSE", n: "SPDR® MSCI World Health Care UCITS ETF GBP - IE00BYTRRB94", c: '"' },
  { id: 32427, s: "HEDF.LSE", n: "WisdomTree Europe Equity UCITS ETF - EUR Acc - IE00BYQCZX56", c: '"' },
  { id: 32428, s: "HEDF.SW", n: "WisdomTree Europe Equity UCITS ETF - EUR Acc EUR - IE00BYQCZX56", c: '"' },
  { id: 32429, s: "HEDG.LSE", n: "WisdomTree Issuer ICAV - WisdomTree Europe Equity UCITS ETF - IE00BYQCZX56", c: '"' },
  { id: 32430, s: "HEDJ.US", n: "WisdomTree Europe Hedged Equity Fund - US97717X7012", c: '"' },
  { id: 32431, s: "HEEM.US", n: "iShares Currency Hedged MSCI Emerging Markets ETF - US46434G5099", c: '"' },
  { id: 32432, s: "HEF.TO", n: "Horizons Enhanced Income Financials Common Class - CA4404542058", c: '"' },
  { id: 32433, s: "HEFA.US", n: "iShares Currency Hedged MSCI EAFE ETF - US46434V8037", c: '"' },
  { id: 32434, s: "HEJ.TO", n: "Horizons Enhanced Income International Equity Common Class - CA44057F2052", c: '"' },
  { id: 32435, s: "HELX.US", n: "Franklin Genomic Advancements ETF - US35473P5200", c: '"' },
  { id: 32436, s: "HEP.TO", n: "Horizons Enhanced Income Gold Producers Common Class - CA4405651098", c: '"' },
  { id: 32437, s: "HERD.US", n: "Pacer Cash Cows Fund of Funds ETF - US69374H6595", c: '"' },
  { id: 32438, s: "HERO.US", n: "Global X Video Games & Esports ETF - US37954Y3927", c: '"' },
  { id: 32439, s: "HEU.PA", n: "HSBC ETFs Public Limited Company - HSBC MSCI Europe UCITS ETF - IE00B5BD5K76", c: '"' },
  { id: 32440, s: "HEUR.AU", n: "Betashares WisdomTree Europe - Currency Hedged - AU00000HEUR7", c: '"' },
  { id: 32441, s: "HEWC.US", n: "iShares Currency Hedged MSCI Canada ETF - US46435G7060", c: '"' },
  { id: 32442, s: "HEWG.MX", n: "iShares Trust - iShares Currency Hedged MSCI Germany ETF - US46434V7047", c: '"' },
  { id: 32443, s: "HEWG.US", n: "iShares Currency Hedged MSCI Germany ETF - US46434V7047", c: '"' },
  { id: 32444, s: "HEWJ.US", n: "iShares Currency Hedged MSCI Japan ETF - US46434V8862", c: '"' },
  { id: 32445, s: "HEWU.US", n: "iShares Currency Hedged MSCI United Kingdom ETF - US46435G8621", c: '"' },
  { id: 32446, s: "HEX.TO", n: "Horizons Enhanced Income Equity Common Class - CA44056X2077", c: '"' },
  { id: 32447, s: "HEZU.US", n: "iShares Currency Hedged MSCI Eurozone ETF - US46434V6395", c: '"' },
  { id: 32448, s: "HFG.TO", n: "Hamilton Global Financials ETF - CA40739A1012", c: '"' },
  { id: 32449, s: "HFR.TO", n: "Horizons Active Ultra-Short Term Investment Grade Bond ETF - CA44051H1082", c: '"' },
  { id: 32450, s: "HFXI.US", n: "IQ 50 Percent Hedged FTSE International ETF - US45409B5600", c: '"' },
  { id: 32451, s: "HGGG.TO", n: "Harvest Global Gold Giants Index ETF - CA41755C1041", c: '"' },
  { id: 32452, s: "HGR.TO", n: "Harvest Global REIT Leaders Income ETF - CA41754X1096", c: '"' },
  { id: 32453, s: "HHH.PA", n: "HSBC ETFs Public Limited Company - HSBC S&P 500 UCITS ETF - IE00B5KQNG97", c: '"' },
  { id: 32454, s: "HIDD.LSE", n: "HSBC ETFs Public Limited Company - HSBC MSCI Indonesia UCITS ETF - IE00B46G8275", c: '"' },
  { id: 32455, s: "HIDR.LSE", n: "HSBC ETFs Public Limited Company - HSBC MSCI Indonesia UCITS ETF - IE00B46G8275", c: '"' },
  { id: 32456, s: "HIDR.SW", n: "HSBC MSCI Indonesia UCITS ETF USD - IE00B46G8275", c: '"' },
  { id: 32457, s: "HIGH.LSE", n: "iShares Public Limited Company - iShares Euro High Yield Corp Bond UCITS ETF - IE00BF3N7094", c: '"' },
  { id: 32458, s: "HIPS.US", n: "GraniteShares HIPS US High Income ETF - US38747R3066", c: '"' },
  { id: 32459, s: "HISF.US", n: "First Trust High Income Strategic Focus ETF - US33739Q3092", c: '"' },
  { id: 32460, s: "HJPN.AU", n: "BetaShares WisdomTree Japan - Currency Hedged - AU00000HJPN5", c: '"' },
  { id: 32461, s: "HKDU.SW", n: "UBS(Lux)Fund Solutions – MSCI Hong Kong UCITS ETF(HKD)A-dis USD - LU1169827224", c: '"' },
  { id: 32462, s: "HKOD.LSE", n: "HSBC MSCI KOREA CAPPED UCITS ETF - IE00B3Z0X395", c: '"' },
  { id: 32463, s: "HKOR.LSE", n: "HSBC MSCI KOREA CAPPED UCITS ETF - IE00B3Z0X395", c: '"' },
  { id: 32464, s: "HKOR.SW", n: "HSBC MSCI KOREA CAPPED UCITS ETF USD - IE00B3Z0X395", c: '"' },
  { id: 32465, s: "HLAL.US", n: "Wahed FTSE USA Shariah ETF - US53656F6079", c: '"' },
  { id: 32466, s: "HLPR.TO", n: "Horizons Laddered Canadian Preferred Share Index ETF - CA44055R1029", c: '"' },
  { id: 32467, s: "HLT.PA", n: "Lyxor Index Fund - Lyxor STOXX Europe 600 Healthcare UCITS ETF - LU1834986900", c: '"' },
  { id: 32468, s: "HLTH.LSE", n: "SPDR® MSCI Europe Health Care UCITS ETF - IE00BKWQ0H23", c: '"' },
  { id: 32469, s: "HLTW.LSE", n: "Lyxor UCITS MSCI World Health Care TR C-USD - LU0533033311", c: '"' },
  { id: 32470, s: "HLTW.PA", n: "Multi Units Luxembourg - Lyxor MSCI World Health Care TR UCITS ETF - LU0533033238", c: '"' },
  { id: 32471, s: "HMAD.LSE", n: "HSBC ETFs Public Limited Company - HSBC MSCI AC Far East ex Japan UCITS ETF - IE00BBQ2W338", c: '"' },
  { id: 32472, s: "HMAF.LSE", n: "HSBC MSCI AC FAR EAST ex JAPAN UCITS ETF - IE00BBQ2W338", c: '"' },
  { id: 32473, s: "HMAF.SW", n: "HSBC MSCI AC FAR EAST ex JAPAN UCITS ETF USD - IE00BBQ2W338", c: '"' },
  { id: 32474, s: "HMBR.LSE", n: "HSBC MSCI Brazil UCITS ETF - IE00B5W34K94", c: '"' },
  { id: 32475, s: "HMCA.LSE", n: "HSBC MSCI CHINA A UCITS ETF - IE00BF4NQ904", c: '"' },
  { id: 32476, s: "HMCH.LSE", n: "HSBC MSCI China UCITS ETF - IE00B44T3H88", c: '"' },
  { id: 32477, s: "HMCH.SW", n: "HSBC MSCI China UCITS ETF USD - IE00B44T3H88", c: '"' },
  { id: 32478, s: "HMCT.LSE", n: "HSBC MSCI CHINA A UCITS ETF - IE00BF4NQ904", c: '"' },
  { id: 32479, s: "HMCT.SW", n: "HSBC MSCI CHINA A UCITS ETF USD - IE00BF4NQ904", c: '"' },
  { id: 32480, s: "HMCX.LSE", n: "HSBC ETFs Public Limited Company - HSBC FTSE 250 UCITS ETF - IE00B64PTF05", c: '"' },
  { id: 32481, s: "HMCX.PA", n: "HSBC FTSE 250 UCITS - IE00B64PTF05", c: '"' },
  { id: 32482, s: "HMEF.LSE", n: "HSBC MSCI Emerging Markets UCITS ETF - IE00B5SSQT16", c: '"' },
  { id: 32483, s: "HMEF.SW", n: "HSBC MSCI Emerging Markets UCITS ETF USD - IE00B5SSQT16", c: '"' },
  { id: 32484, s: "HMEM.LSE", n: "HSBC MSCI Emerging Markets UCITS ETF - IE00B5SSQT16", c: '"' },
  { id: 32485, s: "HMEU.LSE", n: "HSBC MSCI Europe UCITS ETF - IE00B5BD5K76", c: '"' },
  { id: 32486, s: "HMEU.SW", n: "HSBC MSCI Europe UCITS ETF EUR - IE00B5BD5K76", c: '"' },
  { id: 32487, s: "HMJD.LSE", n: "HSBC ETFs Public Limited Company - HSBC MSCI Japan UCITS ETF - IE00B5VX7566", c: '"' },
  { id: 32488, s: "HMJP.LSE", n: "HSBC MSCI JAPAN UCITS ETF - IE00B5VX7566", c: '"' },
  { id: 32489, s: "HMJP.SW", n: "HSBC MSCI JAPAN UCITS ETF JPY - IE00B5VX7566", c: '"' },
  { id: 32490, s: "HMLA.LSE", n: "HSBC ETFs Public Limited Company - HSBC MSCI EM Latin America UCITS ETF - IE00B4TS3815", c: '"' },
  { id: 32491, s: "HMLA.PA", n: "HSBC MSCI EM Latin America UCITS ETF - IE00B4TS3815", c: '"' },
  { id: 32492, s: "HMLD.LSE", n: "HSBC MSCI EM Latin America UCITS ETF - IE00B4TS3815", c: '"' },
  { id: 32493, s: "HMOP.US", n: "Hartford Municipal Opportunities ETF - US41653L5030", c: '"' },
  { id: 32494, s: "HMUD.LSE", n: "HSBC ETFs Public Limited Company - HSBC MSCI USA UCITS ETF - IE00B5WFQ436", c: '"' },
  { id: 32495, s: "HMUS.LSE", n: "HSBC MSCI USA UCITS ETF - IE00B5WFQ436", c: '"' },
  { id: 32496, s: "HMUS.SW", n: "HSBC MSCI USA UCITS ETF - IE00B5WFQ436", c: '"' },
  { id: 32497, s: "HMWD.LSE", n: "HSBC MSCI World UCITS ETF - IE00B4X9L533", c: '"' },
  { id: 32498, s: "HMWO.LSE", n: "HSBC ETFs Public Limited Company - HSBC MSCI World UCITS ETF - IE00B4X9L533", c: '"' },
  { id: 32499, s: "HMWO.SW", n: "HSBC MSCI World UCITS ETF USD - IE00B4X9L533", c: '"' },
  { id: 32500, s: "HMXD.LSE", n: "HSBC MSCI Pacific ex Japan UCITS ETF - IE00B5SG8Z57", c: '"' },
  { id: 32501, s: "HMXJ.SW", n: "HSBC MSCI Pacific ex Japan UCITS ETF USD - IE00B5SG8Z57", c: '"' },
  { id: 32502, s: "HNDL.US", n: "Strategy Shares Nasdaq 7 Handl Index ETF - US86280R5063", c: '"' },
  { id: 32503, s: "HOG.TO", n: "Horizons Pipelines & Energy Services Index ETF - CA44056E1007", c: '"' },
  { id: 32504, s: "HOMZ.US", n: "The Hoya Capital Housing ETF - US26922A2309", c: '"' },
  { id: 32505, s: "HPR.TO", n: "Horizons Active Preferred Share CommonClass - CA44051D1078", c: '"' },
  { id: 32506, s: "HPRD.LSE", n: "HSBC FTSE EPRA NAREIT Developed UCITS ETF - IE00B5L01S80", c: '"' },
  { id: 32507, s: "HPRD.SW", n: "HSBC FTSE EPRA NAREIT Developed UCITS ETF USD - IE00B5L01S80", c: '"' },
  { id: 32508, s: "HPRO.LSE", n: "HSBC ETFs Public Limited Company - HSBC FTSE EPRA/NAREIT Developed UCITS ETF - IE00B5L01S80", c: '"' },
  { id: 32509, s: "HSCZ.US", n: "iShares Currency Hedged MSCI EAFE Small-Cap ETF - US46435G8399", c: '"' },
  { id: 32510, s: "HSEF.LSE", n: "HSBC Emerging Market Sustainable Equity UCITS ETF GBP - IE00BKY59G90", c: '"' },
  { id: 32511, s: "HSEM.LSE", n: "HSBC Emerging Market Sustainable Equity UCITS ETF - IE00BKY59G90", c: '"' },
  { id: 32512, s: "HSEP.LSE", n: "HSBC Europe Sustainable Equity UCITS ETF GBP - IE00BKY55W78", c: '"' },
  { id: 32513, s: "HSEU.LSE", n: "HSBC Europe Sustainable Equity UCITS ETF - IE00BKY55W78", c: '"' },
  { id: 32514, s: "HSEU.SW", n: "HSBC Europe Sustainable Equity UCITS ETF EUR - IE00BKY55W78", c: '"' },
  { id: 32515, s: "HSH.TO", n: "Horizons S&P 500 CAD Hedged Index ETF - CA44055D1033", c: '"' },
  { id: 32516, s: "HSJD.LSE", n: "HSBC Japan Sustainable Equity UCITS ETF - IE00BKY55S33", c: '"' },
  { id: 32517, s: "HSJP.LSE", n: "HSBC Japan Sustainable Equity UCITS ETF GBP - IE00BKY55S33", c: '"' },
  { id: 32518, s: "HSL.TO", n: "Horizons Active Floating Rate Senior Loan ETF Class E - CA44052L2075", c: '"' },
  { id: 32519, s: "HSMV.US", n: "First Trust Horizon Managed Volatility Small/Mid ETF - US33741Y1001", c: '"' },
  { id: 32520, s: "HSPD.LSE", n: "HSBC S&P 500 UCITS ETF - IE00B5KQNG97", c: '"' },
  { id: 32521, s: "HSPX.LSE", n: "HSBC S&P 500 UCITS ETF - IE00B5KQNG97", c: '"' },
  { id: 32522, s: "HSPX.SW", n: "HSBC S&P 500 UCITS ETF USD - IE00B5KQNG97", c: '"' },
  { id: 32523, s: "HSUK.LSE", n: "HSBC UK Sustainable Equity UCITS ETF - IE00BKY53D40", c: '"' },
  { id: 32524, s: "HSUS.LSE", n: "HSBC USA Sustainable Equity UCITS ETF GBP - IE00BKY40J65", c: '"' },
  { id: 32525, s: "HSWO.LSE", n: "HSBC Developed World Sustainable Equity UCITS ETF GBP - IE00BKY59K37", c: '"' },
  { id: 32526, s: "HSXJ.LSE", n: "HSBC Asia Pacific Ex Japan Sustainable Equity UCITS ETF GBP - IE00BKY58G26", c: '"' },
  { id: 32527, s: "HTAB.US", n: "Hartford Schroders Tax-Aware Bond ETF - US41653L4041", c: '"' },
  { id: 32528, s: "HTEC.US", n: "Robo Global® Healthcare Technology and Innovation ETF - US3015057231", c: '"' },
  { id: 32529, s: "HTR.PA", n: "HSBC ETFs Public Limited Company - HSBC MSCI Turkey UCITS ETF - IE00B5BRQB73", c: '"' },
  { id: 32530, s: "HTRB.US", n: "Hartford Total Return Bond ETF - US41653L3050", c: '"' },
  { id: 32531, s: "HTRD.LSE", n: "HSBC ETFs Public Limited Company - HSBC MSCI Turkey UCITS ETF - IE00B5BRQB73", c: '"' },
  { id: 32532, s: "HTRY.LSE", n: "HSBC MSCI Turkey UCITS ETF - IE00B5BRQB73", c: '"' },
  { id: 32533, s: "HTRY.SW", n: "HSBC MSCI Turkey UCITS ETF USD - IE00B5BRQB73", c: '"' },
  { id: 32534, s: "HTUS.US", n: "Capitol Series Trust - US3015058064", c: '"' },
  { id: 32535, s: "HTWD.LSE", n: "HSBC MSCI Taiwan UCITS Capped ETF - IE00B3S1J086", c: '"' },
  { id: 32536, s: "HTWN.LSE", n: "HSBC ETFs Public Limited Company - HSBC MSCI Taiwan UCITS ETF - IE00B3S1J086", c: '"' },
  { id: 32537, s: "HTWN.SW", n: "HSBC MSCI Taiwan UCITS Capped ETF USD - IE00B3S1J086", c: '"' },
  { id: 32538, s: "HUBL.TO", n: "Harvest US Bank Leaders Income ETF Class A - CA41755H1038", c: '"' },
  { id: 32539, s: "HUF.TO", n: "Horizons Active Ultra-Short Term US Investment Grade Bond ETF - CA44051R1148", c: '"' },
  { id: 32540, s: "HUKX.LSE", n: "HSBC FTSE 100 UCITS ETF - IE00B42TW061", c: '"' },
  { id: 32541, s: "HUKX.SW", n: "HSBC FTSE 100 UCITS ETF USD - IE00B42TW061", c: '"' },
  { id: 32542, s: "HUM.TO", n: "Hamilton U.S. Mid/Small-Cap Financials ETF - CA4078661028", c: '"' },
  { id: 32543, s: "HUSV.US", n: "First Trust Horizon Managed Volatility Domestic ETF - US33739P8894", c: '"' },
  { id: 32544, s: "HVST.AU", n: "BetaShares Australian Dividend Harvester - AU00000HVST1", c: '"' },
  { id: 32545, s: "HWWA.LSE", n: "HSBC Multi Factor Worldwide Equity UCITS ETF - IE00BKZGB098", c: '"' },
  { id: 32546, s: "HWWD.LSE", n: "HSBC Multi Factor Worldwide Equity UCITS ETF - IE00BKZGB098", c: '"' },
  { id: 32547, s: "HWWD.SW", n: "HSBC Multi Factor Worldwide Equity UCITS ETF USD - IE00BKZGB098", c: '"' },
  { id: 32548, s: "HXDM.TO", n: "Horizons Intl Developed Markets Equity Index ETF - CA44055M1032", c: '"' },
  { id: 32549, s: "HXE.TO", n: "Horizons S&P TSX Capped Energy - CA44055C1059", c: '"' },
  { id: 32550, s: "HXH.TO", n: "Horizons Cdn High Dividend Index ETF - CA44054R1038", c: '"' },
  { id: 32551, s: "HXS.TO", n: "Horizons S&P 500 Index ETF - CA44056A1084", c: '"' },
  { id: 32552, s: "HXT.TO", n: "Horizons S&P/TSX 60 Index ETF - CA44056G1054", c: '"' },
  { id: 32553, s: "HYBB.US", n: "iShares BB Rated Corporate Bond ETF - US46435U4739", c: '"' },
  { id: 32554, s: "HYD.US", n: "VanEck High Yield Muni ETF - US92189H4092", c: '"' },
  { id: 32555, s: "HYDB.US", n: "iShares Edge High Yield Defensive Bond ETF - US46435G2509", c: '"' },
  { id: 32556, s: "HYDW.US", n: "Xtrackers Low Beta High Yield Bond ETF - US2330512672", c: '"' },
  { id: 32557, s: "HYEA.LSE", n: "iShares Public Limited Company - iShares Global High Yield Corp Bond UCITS ETF - IE00BYWZ0440", c: '"' },
  { id: 32558, s: "HYEM.LSE", n: "VanEck Emerging Markets High Yield Bond UCITS ETF USD - IE00BF541080", c: '"' },
  { id: 32559, s: "HYEM.SW", n: "VanEck Emerging Markets High Yield Bond UCITS ETF A USD CHF - IE00BF541080", c: '"' },
  { id: 32560, s: "HYEM.US", n: "VanEck Emerging Markets High Yield Bond ETF - US92189F3534", c: '"' },
  { id: 32561, s: "HYFA.LSE", n: "Invesco US High Yield Fallen Angels UCITS ETF - IE00BD0Q9673", c: '"' },
  { id: 32562, s: "HYG.US", n: "iShares iBoxx $ High Yield Corporate Bond ETF - US4642885135", c: '"' },
  { id: 32563, s: "HYGH.US", n: "iShares Interest Rate Hedged High Yield Bond ETF - US46431W6066", c: '"' },
  { id: 32564, s: "HYGV.US", n: "FlexShares® High Yield Value-Scored Bond Index Fund - US33939L6627", c: '"' },
  { id: 32565, s: "HYHG.US", n: "ProShares High Yield—Interest Rate Hedged - US74348A5415", c: '"' },
  { id: 32566, s: "HYI.TO", n: "Horizons Active High Yield Bond CommonClass - CA43990B2066", c: '"' },
  { id: 32567, s: "HYIH.US", n: "Xtrackers Bloomberg Barclays US Investment Grade Corporate ESG ETF - US2330517473", c: '"' },
  { id: 32568, s: "HYLA.LSE", n: "iShares Global High Yield Corp Bond UCITS Acc - IE00BYWZ0440", c: '"' },
  { id: 32569, s: "HYLA.SW", n: "iShares Global High Yield Corp Bond UCITS ETF USD (Acc) USD - IE00BYWZ0440", c: '"' },
  { id: 32570, s: "HYLB.US", n: "Xtrackers USD High Yield Corporate Bond ETF - US2330514322", c: '"' },
  { id: 32571, s: "HYLC.SW", n: "iShares Global High Yield Corp Bond UCITS ETF CHF Hedged (Acc) - IE00BKBF4J08", c: '"' },
  { id: 32572, s: "HYLD.LSE", n: "iShares Global High Yield Corp Bond UCITS ETF USD (Dist) - IE00B74DQ490", c: '"' },
  { id: 32573, s: "HYLD.SW", n: "iShares Global High Yield Corp Bond UCITS ETF USD (Dist) - IE00B74DQ490", c: '"' },
  { id: 32574, s: "HYLD.US", n: "High Yield ETF - US30151E8140", c: '"' },
  { id: 32575, s: "HYLE.XETRA", n: "iShares Global High Yield Corp Bond UCITS ETF EUR Hedged (Dist) - IE00BJSFR200", c: '"' },
  { id: 32576, s: "HYLS.US", n: "First Trust Tactical High Yield ETF - US33738D4088", c: '"' },
  { id: 32577, s: "HYMB.US", n: "SPDR® Nuveen Bloomberg High Yield Municipal Bond ETF - US78464A2841", c: '"' },
  { id: 32578, s: "HYS.US", n: "PIMCO 0-5 Year High Yield Corporate Bond Index Exchange-Traded Fund - US72201R7834", c: '"' },
  { id: 32579, s: "HYTR.US", n: "CP High Yield Trend ETF - US66538R7228", c: '"' },
  { id: 32580, s: "HYUP.US", n: "Xtrackers High Beta High Yield Bond ETF - US2330512599", c: '"' },
  { id: 32581, s: "HYXE.US", n: "iShares iBoxx $ High Yield ex Oil & Gas Corporate Bond ETF - US46435G4414", c: '"' },
  { id: 32582, s: "HYXF.US", n: "iShares ESG Advanced High Yield Corporate Bond ETF - US46435G4414", c: '"' },
  { id: 32583, s: "HYXU.US", n: "iShares International High Yield Bond ETF - US4642862100", c: '"' },
  { id: 32584, s: "HYZD.US", n: "WisdomTree Interest Rate Hedged High Yield Bond Fund - US97717W4309", c: '"' },
  { id: 32585, s: "IAAA.LSE", n: "iShares Global AAA-AA Government Bond UCITS - IE00B87G8S03", c: '"' },
  { id: 32586, s: "IAAA.SW", n: "iShares Global AAA-AA Govt Bond UCITS ETF USD (Dist) USD - IE00B87G8S03", c: '"' },
  { id: 32587, s: "IAEA.AS", n: "iShares AEX UCITS ETF EUR (Acc) - IE00BMTX2B82", c: '"' },
  { id: 32588, s: "IAEX.AS", n: "iShares AEX UCITS ETF EUR (Dist) - IE00B0M62Y33", c: '"' },
  { id: 32589, s: "IAEX.LSE", n: "iShares AEX UCITS ETF EUR (Dist) - IE00B0M62Y33", c: '"' },
  { id: 32590, s: "IAEX.SW", n: "iShares AEX UCITS ETF EUR (Dist) EUR - IE00B0M62Y33", c: '"' },
  { id: 32591, s: "IAGG.US", n: "iShares Core International Aggregate Bond ETF - US46435G6724", c: '"' },
  { id: 32592, s: "IAI.US", n: "iShares U.S. Broker-Dealers & Securities Exchanges ETF - US4642887941", c: '"' },
  { id: 32593, s: "IAK.US", n: "iShares U.S. Insurance ETF - US4642887867", c: '"' },
  { id: 32594, s: "IAPD.AS", n: "iShares Asia Pacific Dividend UCITS - IE00B14X4T88", c: '"' },
  { id: 32595, s: "IAPD.LSE", n: "iShares Asia Pacific Dividend UCITS - IE00B14X4T88", c: '"' },
  { id: 32596, s: "IAPD.SW", n: "iShares Asia Pacific Dividend UCITS ETF USD (Dist) - IE00B14X4T88", c: '"' },
  { id: 32597, s: "IASH.LSE", n: "iShares MSCI China A UCITS USD - IE00BQT3WG13", c: '"' },
  { id: 32598, s: "IASP.AS", n: "iShares Asia Property Yield UCITS - IE00B1FZS244", c: '"' },
  { id: 32599, s: "IASP.LSE", n: "iShares II Public Limited Company - iShares Asia Property Yield UCITS ETF - IE00B1FZS244", c: '"' },
  { id: 32600, s: "IASP.SW", n: "iShares Asia Property Yield UCITS ETF USD (Dist) - IE00B1FZS244", c: '"' },
  { id: 32601, s: "IAT.US", n: "iShares U.S. Regional Banks ETF - US4642887784", c: '"' },
  { id: 32602, s: "IAU.US", n: "iShares Gold Trust - US4642852044", c: '"' },
  { id: 32603, s: "IAUP.LSE", n: "iShares Gold Producers UCITS ETF USD (Acc) - IE00B6R52036", c: '"' },
  { id: 32604, s: "IAUP.SW", n: "iShares Gold Producers UCITS ETF USD (Acc) CHF - IE00B6R52036", c: '"' },
  { id: 32605, s: "IAUS.LSE", n: "iShares MSCI Australia UCITS ETF USD (Acc) - IE00B5377D42", c: '"' },
  { id: 32606, s: "IB01.LSE", n: "iShares $ Treasury Bond 0-1yr UCITS ETF USD (Acc) - IE00BGSF1X88", c: '"' },
  { id: 32607, s: "IB01.SW", n: "iShares $ Treasury Bond 0-1yr UCITS ETF USD (Acc) USD - IE00BGSF1X88", c: '"' },
  { id: 32608, s: "IBB.US", n: "iShares Biotechnology ETF - US4642875565", c: '"' },
  { id: 32609, s: "IBC0.F", n: "iShares Edge MSCI Europe Multifactor UCITS - IE00BZ0PKV06", c: '"' },
  { id: 32610, s: "IBC0.XETRA", n: "iShares Edge MSCI Europe Multifactor UCITS - IE00BZ0PKV06", c: '"' },
  { id: 32611, s: "IBC3.XETRA", n: "iShares Core MSCI EM IMI UCITS ETF - IE00BD45KH83", c: '"' },
  { id: 32612, s: "100H.LSE", n: "MULTI-UNITS LUXEMBOURG - Lyxor FTSE 100 UCITS ETF - Monthly Hedged to USD - Acc - LU1650492504 ", c: "" },
  { id: 32613, s: "100H.PA", n: "MULTI-UNITS LUXEMBOURG - Lyxor FTSE 100 UCITS ETF - Monthly Hedged to EUR - Acc - LU1650492330 ", c: "" },
  { id: 32614, s: "10AI.XETRA", n: "Amundi Index Solutions - Amundi Index MSCI Europe UCITS ETF DR EUR - LU1737652310 ", c: "" },
  { id: 32615, s: "10AK.XETRA", n: "Amundi Index Solutions - Amundi Index J.P. Morgan GBI Global Govies UCITS ETF DR EUR - LU1737653631 ", c: "" },
  { id: 32616, s: "123310.KO", n: "MiraeAsset TIGER Inverse ETF - KR7123310005 ", c: "" },
  { id: 32617, s: "138230.KO", n: "Kiwoom KOSEF USD Futures ETF - KR7138230008 ", c: "" },
  { id: 32618, s: "139240.KO", n: "MiraeAsset TIGER 200 Steels & Materials ETF - KR7139240006 ", c: "" },
  { id: 32619, s: "145670.KO", n: "KIM KINDEX Inverse ETF - KR7145670006 ", c: "" },
  { id: 32620, s: "176950.KO", n: "Samsung KODEX 10Y F-LKTB Inverse ETF - KR7176950004 ", c: "" },
  { id: 32621, s: "18M1.F", n: "Amundi ETF Govies 0-6 Months EUROMTS Investment Grade UCITS ETF - FR0010754200 ", c: "" },
  { id: 32622, s: "18M1.XETRA", n: "Amundi ETF Govies 0-6 Months EuroMTS Investment Grade UCITS ETF DR - FR0010754200 ", c: "" },
  { id: 32623, s: "18MF.F", n: "Amundi ETF Leveraged MSCI USA Daily UCITS ETF - FR0010755611 ", c: "" },
  { id: 32624, s: "18MF.XETRA", n: "Amundi ETF Leveraged MSCI USA Daily UCITS ETF - FR0010755611 ", c: "" },
  { id: 32625, s: "18MZ.XETRA", n: "Amundi ETF Govt Bond EuroMTS Broad Investment Grade 5-7 UCITS ETF - FR0010754176 ", c: "" },
  { id: 32626, s: "2MSF.AS", n: "Leverage Shares 2x Microsoft ETC A EUR - IE00BF03XY85 ", c: "" },
  { id: 32627, s: "36B3.XETRA", n: "iShares MSCI Europe SRI UCITS ETF EUR (Dist) - IE00BGDPWW94 ", c: "" },
  { id: 32628, s: "36BA.XETRA", n: "iShares $ Corp Bond ESG UCITS ETF EUR Hedged Inc - IE00BH4G7D40 ", c: "" },
  { id: 32629, s: "3SUR.XETRA", n: "iShares MSCI USA SRI UCITS ETF EUR Hedged (Dist) - IE00BZ173V67 ", c: "" },
  { id: 32630, s: "4UB1.XETRA", n: "UBS (Irl) ETF plc – MSCI World Socially Responsible UCITS ETF (hedged to EUR) A-acc - IE00BK72HM96 ", c: "" },
  { id: 32631, s: "540J.XETRA", n: "Amundi Index Solutions - Amundi MSCI Switzerland - LU1681044720 ", c: "" },
  { id: 32632, s: "5ESG.LSE", n: "UBS (Irl) ETF plc - S&P 500 ESG UCITS ETF Hedged to GBP A Dis - IE00BHXMHR72 ", c: "" },
  { id: 32633, s: "5ESGES.SW", n: "UBS (Irl) ETF plc - S&P 500 ESG ELITE UCITS ETF (hedged to CHF) A Acc - IE00BLSN7S42 ", c: "" },
  { id: 32634, s: "5ESGG.SW", n: "UBS (Irl) ETF plc - S&P 500 ESG UCITS ETF Hedged to GBP A Dis GBP - IE00BHXMHR72 ", c: "" },
  { id: 32635, s: "5ESGS.SW", n: "UBS (Irl) ETF plc - S&P 500 ESG UCITS ETF Hedged to CHF A Acc - IE00BHXMHN35 ", c: "" },
  { id: 32636, s: "5HEU.XETRA", n: "Ossiam ESG Shiller Barclays CAPE® Europe Sector UCITS ETF 1D EUR - IE00080CTQA4 ", c: "" },
  { id: 32637, s: "7RIP.XETRA", n: "HANetf ICAV - Airlines Hotels Cruise Lines UCITS ETF - IE00BMFNW783 ", c: "" },
  { id: 32638, s: "9W1.XETRA", n: "BNP PARIBAS EASY - MSCI China Select SRI S-Series 10% Capped UCITS ETF EUR Acculumation - LU2314312849 ", c: "" },
  { id: 32639, s: "9W1A.XETRA", n: "BNP PARIBAS EASY - MSCI China Select SRI S-Series 10% Capped UCITS ETF Accumulation - LU2314312922 ", c: "" },
  { id: 32640, s: "AASI.PA", n: "Amundi Index Solutions - Amundi MSCI Em Asia UCITS ETF-C EUR - LU1681044480 ", c: "" },
  { id: 32641, s: "AAXJ.MX", n: "iShares Trust - iShares MSCI All Country Asia ex Japan ETF - US4642881829 ", c: "" },
  { id: 32642, s: "ACLE.XETRA", n: "AXA IM ACT Climate Equity UCITS ETF - EUR H Acc - IE000E66LX20 ", c: "" },
  { id: 32643, s: "ACPA.SW", n: "UBS (Irl) ETF plc MSCI ACWI Climate Paris Aligned UCITS ETF A USD Acc - IE00BN4Q0P93 ", c: "" },
  { id: 32644, s: "ACUU.XETRA", n: "Amundi Index Solutions - Amundi MSCI AC Far East Ex Japan ESG Leaders Select UCITS ETF DR USD (C) EUR - LU2439119236 ", c: "" },
  { id: 32645, s: "ACWV.MX", n: "iShares Inc. - iShares MSCI Global Min Vol Factor ETF - US4642865251 ", c: "" },
  { id: 32646, s: "ACWX.MX", n: "iShares Trust - iShares MSCI ACWI ex U.S. ETF - US4642882405 ", c: "" },
  { id: 32647, s: "AEEM.PA", n: "Amundi Index Solutions - Amundi MSCI Emerging Markets UCITS ETF-C EUR - LU1681045370 ", c: "" },
  { id: 32648, s: "AEMU.LSE", n: "Amundi Index Solutions - Amundi Index MSCI Emerging Markets UCITS ETF DR USD D denominated units - LU2277591868 ", c: "" },
  { id: 32649, s: "AFLE.PA", n: "Amundi Index Solutions - Amundi Floating Rate USD Corporate ESG - UCITS ETF DR - HEDGED EUR (C) - LU1681041031 ", c: "" },
  { id: 32650, s: "AFLE.SW", n: "Amundi Index Solutions - Amundi Floating Rate USD Corporate ESG - UCITS ETF DR - HEDGED EUR (C) EUR - LU1681041031 ", c: "" },
  { id: 32651, s: "AFLT.PA", n: "Amundi Index Solutions - Amundi Floating Rate USD Corporate ESG - UCITS ETF DR - USD (C) - LU1681040900 ", c: "" },
  { id: 32652, s: "AGG.MX", n: "iShares Trust - iShares Core U.S. Aggregate Bond ETF - US4642872265 ", c: "" },
  { id: 32653, s: "AMEG.LSE", n: "Amundi Index Solutions - Amundi Index MSCI Emerging Markets SRI PAB UCITS ETF DR GBP D - LU2469335371 ", c: "" },
  { id: 32654, s: "AMES.XETRA", n: "Amundi ETF MSCI Spain UCITS ETF - FR0010655746 ", c: "" },
  { id: 32655, s: "ANXU.PA", n: "Amundi Nasdaq-100 UCITS USD - LU1681038326 ", c: "" },
  { id: 32656, s: "APXJ.XETRA", n: "Amundi Index MSCI Pacific Ex Japan SRI PAB - UCITS ETF DR - EUR (D) - LU2402389261 ", c: "" },
  { id: 32657, s: "AQWA.LSE", n: "Global X Clean Water UCITS ETF USD Acc - IE000BWKUES1 ", c: "" },
  { id: 32658, s: "ASIG.AS", n: "iShares $ Asia Investment Grade Corp Bond UCITS ETF USD (Acc) - IE0007G78AC4 ", c: "" },
  { id: 32659, s: "ASRC.XETRA", n: "BNP Paribas Easy JPM ESG EMBI Global Diversified Composite UCITS ETF Capitalisation - LU1547515053 ", c: "" },
  { id: 32660, s: "ASRD.XETRA", n: "BNP Paribas Easy JPM ESG EMBI Global Diversified Composite UCITS ETF H EUR Capitalisation - LU1547515137 ", c: "" },
  { id: 32661, s: "ASRF.XETRA", n: "BNP Paribas Easy € High Yield SRI Fossil Free UCITS ETF EUR Acc - LU2244386053 ", c: "" },
  { id: 32662, s: "ASRG.XETRA", n: "BNP Paribas Easy € Corp Bond SRI Fossil Free UCITS ETF Acc - LU2244386137 ", c: "" },
  { id: 32663, s: "ASRM.XETRA", n: "BNP Paribas Easy FTSE EPRA Nareit Global Developed Green CTB UCITS ETF EUR Capitalisation EUR - LU2365458905 ", c: "" },
  { id: 32664, s: "ASRP.XETRA", n: "BNP Paribas Easy ECPI Global ESG Med Tech UCITS ETF EUR Capitalisation EUR - LU2365457410 ", c: "" },
  { id: 32665, s: "ASRS.XETRA", n: "BNP Paribas Easy ECPI Global ESG Hydrogen Economy UCITS ETF EUR Cap EUR - LU2365458145 ", c: "" },
  { id: 32666, s: "ASRW.XETRA", n: "BNP PARIBAS EASY - MSCI World ESG Filtered Min TE UCITS ETF Acc - LU2446380235 ", c: "" },
  { id: 32667, s: "AT1.LSE", n: "Invesco AT1 Capital Bond ETF - IE00BFZPF322 ", c: "" },
  { id: 32668, s: "AT1S.SW", n: "Invesco AT1 Capital Bond ETF GBP Hedged Dist GBP - IE00BYZLWM19 ", c: "" },
  { id: 32669, s: "AUCHAH.SW", n: "UBS ETF (CH) – Gold (CHF) hedged (CHF) A-dis - CH0106027128 ", c: "" },
  { id: 32670, s: "AUEUAH.SW", n: "UBS ETF (CH) – Gold (EUR) hedged (EUR) A-dis - CH0106027144 ", c: "" },
  { id: 32671, s: "AUHCHA.SW", n: "UBS (Irl) ETF plc - MSCI Australia UCITS ETF (hedged to CHF) A-acc - IE00BX7RS779 ", c: "" },
  { id: 32672, s: "AUHEUA.SW", n: "UBS (Irl) ETF plc - MSCI Australia UCITS ETF (hedged to EUR) A-acc - IE00BWT3KS11 ", c: "" },
  { id: 32673, s: "AUHGBA.SW", n: "UBS (Irl) ETF plc - MSCI Australia UCITS ETF (hedged to GBP) A-acc - IE00BXDZNQ90 ", c: "" },
  { id: 32674, s: "AUHUSA.SW", n: "UBS (Irl) ETF plc - MSCI Australia UCITS ETF (hedged to USD) A-acc - IE00BX7RS555 ", c: "" },
  { id: 32675, s: "AUUSI.SW", n: "UBS ETF (CH) – Gold (USD) A-dis CHF - CH0106027193 ", c: "" },
  { id: 32676, s: "AW10.XETRA", n: "UBS (Irl) ETF plc - MSCI World Climate Paris Aligned UCITS ETF A USD - IE00BN4Q0370 ", c: "" },
  { id: 32677, s: "AW13.XETRA", n: "UBS (Irl) ETF plc - MSCI Europe Climate Paris Aligned UCITS ETF A EUR - IE00BN4Q0933 ", c: "" },
  { id: 32678, s: "AW15.XETRA", n: "UBS (Irl) ETF plc - MSCI Japan Climate Paris Aligned UCITS ETF A JPY - IE00BN4Q0X77 ", c: "" },
  { id: 32679, s: "AW16.XETRA", n: "UBS (Irl) ETF plc - MSCI USA Climate Paris Aligned UCITS ETF A USD - IE00BN4Q0602 ", c: "" },
  { id: 32680, s: "AW1F.XETRA", n: "UBS (Irl) ETF plc MSCI USA ESG Universal Low Carbon Select UCITS ETF A - IE00BNC0M350 ", c: "" },
  { id: 32681, s: "AW1G.XETRA", n: "UBS (Irl) ETF plc MSCI Europe ESG Universal Low Carbon Select UCITS ETF A - IE00BNC0M681 ", c: "" },
  { id: 32682, s: "AW1H.XETRA", n: "UBS (Irl) ETF plc MSCI EMU ESG Universal Low Carbon Select UCITS ETF A - IE00BNC0M913 ", c: "" },
  { id: 32683, s: "AW1I.XETRA", n: "UBS (Irl) ETF plc MSCI Japan ESG Universal Low Carbon Select UCITS ETF A - IE00BNC0MD55 ", c: "" },
  { id: 32684, s: "AW1Q.XETRA", n: "UBS (Irl) ETF plc - MSCI Japan Climate Paris Aligned UCITS ETF A (ir-hedged to EUR) - IE000JHYO4T6 ", c: "" },
  { id: 32685, s: "AW1T.XETRA", n: "UBS(Lux)Fund Solutions - MSCI EMU Value UCITS ETF(EUR)A-acc - LU0950669845 ", c: "" },
  { id: 32686, s: "AW1Z.XETRA", n: "UBS (Irl) ETF plc - MSCI EMU Climate Paris Aligned UCITS ETF A EUR - IE00BN4Q0L55 ", c: "" },
  { id: 32687, s: "AWESGJ.SW", n: "UBS (IRL) ETF Plc – MSCI ACWI ESG Universal Low Carbon Select UCITS ETF Hedged JPY A Acc - IE00BYVHJM24 ", c: "" },
  { id: 32688, s: "AWESGT.SW", n: "UBS (IRL) ETF Plc – MSCI ACWI ESG Universal Low Carbon Select UCITS ETF Hedged CHF A Dis - IE00BDQZN667 ", c: "" },
  { id: 32689, s: "AWSG.LSE", n: "UBS (Irl) ETF plc - MSCI ACWI Socially Responsible UCITS ETF Hedged GBP A Dis - IE00BDR55B48 ", c: "" },
  { id: 32690, s: "AWSR.LSE", n: "UBS (Irl) ETF Public Limited Company - MSCI ACWI Socially Responsible UCITS ETF - IE00BDR55703 ", c: "" },
  { id: 32691, s: "AWSRIJ.SW", n: "UBS (Irl) ETF plc - MSCI ACWI Socially Responsible UCITS ETF Hedged JPY A Acc - IE00BF4NC698 ", c: "" },
  { id: 32692, s: "AWSRIT.SW", n: "UBS (Irl) ETF plc - MSCI ACWI Socially Responsible UCITS ETF Hedged CHF A Dis - IE00BDR55D61 ", c: "" },
  { id: 32693, s: "BATE.XETRA", n: "L&G Battery Value-Chain UCITS ETF - IE00BF0M2Z96 ", c: "" },
  { id: 32694, s: "BATG.LSE", n: "L&G Battery Value-Chain UCITS ETF - IE00BF0M2Z96 ", c: "" },
  { id: 32695, s: "BATG.XETRA", n: "L&G Battery Value-Chain UCITS ETF - IE000HLUHPT1 ", c: "" },
  { id: 32696, s: "BATT.AS", n: "Legal & General UCITS ETF Plc - L&G Battery Value-Chain UCITS ETF - IE00BF0M2Z96 ", c: "" },
  { id: 32697, s: "BATT.LSE", n: "L&G Battery Value-Chain UCITS ETF - IE00BF0M2Z96 ", c: "" },
  { id: 32698, s: "BBCK.XETRA", n: "Invesco Global Buyback Achievers UCITS ETF - IE00BLSNMW37 ", c: "" },
  { id: 32699, s: "BCFE.XETRA", n: "UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF (hedged to EUR) A-acc - IE00BYYLVJ24 ", c: "" },
  { id: 32700, s: "BCFU.XETRA", n: "UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF (USD) A-acc - IE00BYYLVH00 ", c: "" },
  { id: 32701, s: "BCOG.LSE", n: "L&G All Commodities UCITS ETF - IE00BF0BCP69 ", c: "" },
  { id: 32702, s: "BCOM.LSE", n: "L&G All Commodities UCITS ETF - IE00BF0BCP69 ", c: "" },
  { id: 32703, s: "BJLE.XETRA", n: "BNP PARIBAS EASY - ECPI Global ESG Blue Economy UCITS ETF Cap - LU2194447293 ", c: "" },
  { id: 32704, s: "BKCH.LSE", n: "Global X Blockchain UCITS ETF USD Acc - IE000XAGSCY5 ", c: "" },
  { id: 32705, s: "BLUM.XETRA", n: "Rize Ucits ICAV - Rize Medical Cannabis And Life Sciences UCITS ETF - IE00BJXRZ273 ", c: "" },
  { id: 32706, s: "BLV.MX", n: "Vanguard Bond Index Funds - Vanguard Long-Term Bond ETF - US9219377937 ", c: "" },
  { id: 32707, s: "BND.MX", n: "Vanguard Bond Index Funds - Vanguard Total Bond Market ETF - US9219378356 ", c: "" },
  { id: 32708, s: "BNDX.MX", n: "Vanguard Charlotte Funds - Vanguard Total International Bond ETF - US92203J4076 ", c: "" },
  { id: 32709, s: "BNKE.PA", n: "Lyxor UCITS Stoxx Banks C-EUR - LU1829219390 ", c: "" },
  { id: 32710, s: "BNKT.AS", n: "iShares V PLC - iShares S&P U.S. Banks UCITS ETF USD Inc - IE000ZPUEP93 ", c: "" },
  { id: 32711, s: "BSUS.LSE", n: "UBS(Lux)Fund Solutions – Bloomberg MSCI US Liquid Corp Sust UCITS ETF(hedged GBP)A-dis - LU1215461598 ", c: "" },
  { id: 32712, s: "BSX.PA", n: "Lyxor UCITS Stoxx 50 Daily Short - FR0010424135 ", c: "" },
  { id: 32713, s: "BTPS.PA", n: "Lyxor UCITS Daily Double Short BTP - FR0011023621 ", c: "" },
  { id: 32714, s: "BUYB.PA", n: "Invesco Global Buyback Achievers UCITS ETF - IE00BLSNMW37 ", c: "" },
  { id: 32715, s: "BX4.PA", n: "Lyxor UCITS CAC 40 Daily Double Short - FR0010411884 ", c: "" },
  { id: 32716, s: "BXX.PA", n: "Lyxor UCITS Stoxx 50 Daily Double Short - FR0010424143 ", c: "" },
  { id: 32717, s: "BYBE.PA", n: "Amundi Index Solutions - Amundi S&P 500 Buyback ETF-C EUR EUR - LU1681048127 ", c: "" },
  { id: 32718, s: "C024.XETRA", n: "Amundi MSCI China A II ETF EUR - LU2572256746 ", c: "" },
  { id: 32719, s: "C025.XETRA", n: "Amundi MSCI China A II ETF - LU2572256746 ", c: "" },
  { id: 32720, s: "C099.F", n: "Lyxor Bloomberg Equal-weight Commodity ex-Agriculture EUR hedged UCITS ETF - LU1275255799 ", c: "" },
  { id: 32721, s: "C099.XETRA", n: "Amundi Bloomberg Equal-weight Commodity ex-Agriculture UCITS ETF Euro Hedged Acc - LU1900069219 ", c: "" },
  { id: 32722, s: "C101.XETRA", n: "Lyxor Fed Funds US Dollar Cash UCITS ETF - LU2090062352 ", c: "" },
  { id: 32723, s: "C3M.PA", n: "Amundi ETF Govies 0-6 Months EuroMTS Investment Grade UCITS ETF DR - FR0010754200 ", c: "" },
  { id: 32724, s: "CAHCHA.SW", n: "UBS(Lux)Fund Solutions – MSCI Canada UCITS ETF(hedged CHF)A-acc - LU1130155432 ", c: "" },
  { id: 32725, s: "CAHEUA.SW", n: "UBS(Lux)Fund Solutions – MSCI Canada UCITS ETF(hedged EUR)A-acc - LU1130155606 ", c: "" },
  { id: 32726, s: "CAHGBD.SW", n: "UBS(Lux)Fund Solutions – MSCI Canada UCITS ETF(hedged GBP)A-dis - LU1130156596 ", c: "" },
  { id: 32727, s: "CAHUSA.SW", n: "UBS(Lux)Fund Solutions – MSCI Canada UCITS ETF(hedged USD)A-acc - LU1130155861 ", c: "" },
  { id: 32728, s: "CAVE.LSE", n: "VanEck Smart Home Active UCITS ETF A USD Acc - IE000FN81QD2 ", c: "" },
  { id: 32729, s: "CBMEM.SW", n: "Amundi MSCI Emerging Markets II UCITS ETF Inc USD - LU2573966905 ", c: "" },
  { id: 32730, s: "CBSEUD.SW", n: "UBS(Lux)Fund Solutions – Bloomberg MSCI Euro Area Liquid Corp Sust UCITSETF(hedgedUSD)A-dis - LU1484799926 ", c: "" },
  { id: 32731, s: "CBSUSE.SW", n: "UBS(Lux)Fund Solutions – Bloomberg MSCI US Liquid Corp Sust UCITS ETF(hedged EUR)A-acc - LU1215461325 ", c: "" },
  { id: 32732, s: "CBSUSH.SW", n: "UBS(Lux)Fund Solutions – Bloomberg MSCI US Liquid Corp Sust UCITS ETF(hedged GBP)A-dis - LU1215461598 ", c: "" },
  { id: 32733, s: "CBSUST.SW", n: "UBS(Lux)Fund Solutions – Bloomberg MSCI US Liquid Corp Sust UCITS ETF(hedged CHF)A-dis - LU1215461754 ", c: "" },
  { id: 32734, s: "CBUC.XETRA", n: "iShares MSCI USA ESG Enhanced UCITS ETF EUR Hedged Acc - IE000U7L59A3 ", c: "" },
  { id: 32735, s: "CBUD.XETRA", n: "iShares MSCI Europe SRI UCITS ETF EUR Hedged (Dist) - IE000CR424L6 ", c: "" },
  { id: 32736, s: "CBUE.XETRA", n: "iShares $ Treasury Bond 3-7yr UCITS ETF EUR Hedged (Dist) - IE00BGPP6473 ", c: "" },
  { id: 32737, s: "CBUG.AS", n: "iShares VII PLC - iShares $ Treasury Bd 3-7yr ETF GBPHDist - IE00BJJPVP04 ", c: "" },
  { id: 32738, s: "CBUG.LSE", n: "iShares VII PLC - iShares $ Treasury Bd 3-7yr ETF GBPHDist EUR - IE00BJJPVP04 ", c: "" },
  { id: 32739, s: "CBUM.XETRA", n: "iShares S&P 500 ESG UCITS ETF EUR Hedged (Acc) - IE000CR7DJI8 ", c: "" },
  { id: 32740, s: "CBUS5E.SW", n: "UBS(Lux)Fund Solutions – Bloomberg US Liquid Corporates 1-5 Year UCITS ETF(hedged EUR)A-acc - LU1048315243 ", c: "" },
  { id: 32741, s: "CBUS5H.SW", n: "UBS(Lux)Fund Solutions – Bloomberg US Liquid Corporates 1-5 Year UCITS ETF(hedged GBP)A-dis GBP - LU1048315326 ", c: "" },
  { id: 32742, s: "CBUS5S.SW", n: "UBS(Lux)Fund Solutions – Bloomberg US Liquid Corporates 1-5 Year UCITS ETF(hedged CHF)A-acc - LU1048315755 ", c: "" },
  { id: 32743, s: "CBUSAS.SW", n: "Amundi S&P SmallCap 600 ESG UCITS ETF USD Inc CHF - IE000XLJ2JQ9 ", c: "" },
  { id: 32744, s: "CBUSH.SW", n: "UBS(Lux)Fund Solutions – Bloomberg US Liquid Corporates UCITS ETF(hedged GBP)A-dis GBP - LU1048317298 ", c: "" },
  { id: 32745, s: "CBUSS.SW", n: "UBS(Lux)Fund Solutions – Bloomberg US Liquid Corporates UCITS ETF(hedged CHF)A-acc - LU1048317538 ", c: "" },
  { id: 32746, s: "CBUT.XETRA", n: "iShares Blockchain Technology UCITS ETF USD Cap EUR - IE000RDRMSD1 ", c: "" },
  { id: 32747, s: "CC1U.PA", n: "Amundi Index Solutions - Amundi MSCI China UCITS ETF-C USD - LU1681044050 ", c: "" },
  { id: 32748, s: "CCBO.LSE", n: "WisdomTree AT1 CoCo Bond UCITS ETF - USD - IE00BZ0XVF52 ", c: "" },
  { id: 32749, s: "CCBO.SW", n: "WisdomTree AT1 CoCo Bond UCITS ETF - USD - IE00BZ0XVF52 ", c: "" },
  { id: 32750, s: "CCUSAS.SW", n: "UBS (Irl) Fund Solutions plc - CMCI Composite SF UCITS ETF (USD) A-acc - IE00B53H0131 ", c: "" },
  { id: 32751, s: "CE2D.LSE", n: "Amundi Index Solutions - Amundi Index MSCI Europe UCITS ETF DR EUR GBP - LU1737652310 ", c: "" },
  { id: 32752, s: "CEBG.F", n: "iShares VII Public Limited Company - iShares MSCI Mexico Capped UCITS ETF - IE00B5WHFQ43 ", c: "" },
  { id: 32753, s: "CEM.PA", n: "Amundi MSCI Europe Small Cap ESG Climate Net Zero Ambition CTB ETF Acc - LU1681041544 ", c: "" },
  { id: 32754, s: "CESG.LSE", n: "FIRST TRUST GLOBAL FUNDS PLC - First Trust Global Capital Strength ESG Leaders UCITS ETF Class A USD - IE00BKPSPT20 ", c: "" },
  { id: 32755, s: "CEUG.LSE", n: "iShares VII PLC -iShares Core MSCI EMU UCITS ETF GBP H Dist - IE00BG0J9Y53 ", c: "" },
  { id: 32756, s: "CEUU.AS", n: "iShares Core MSCI EMU UCITS USD Hdg Acc - IE00BKBF6616 ", c: "" },
  { id: 32757, s: "CGGD.AS", n: "iShares Global Govt Bond Climate UCITS ETF USD Inc - IE00BL0BMG90 ", c: "" },
  { id: 32758, s: "CGGD.SW", n: "iShares Global Govt Bond Climate UCITS ETF USD Inc USD - IE00BL0BMG90 ", c: "" },
  { id: 32759, s: "CHEUR.SW", n: "UBS(Lux)Fund Solutions – MSCI Switzerland 20/35 UCITS ETF(hedged EUR)A-acc - LU1169830103 ", c: "" },
  { id: 32760, s: "CHEURD.SW", n: "UBS(Lux)Fund Solutions – MSCI Switzerland 20/35 UCITS ETF(hedged EUR)A-dis - LU1169830012 ", c: "" },
  { id: 32761, s: "CHIN.LSE", n: "KraneShares ICBCCS S&P China 500 Index UCITS ETF USD USD - IE0001QF56M0 ", c: "" },
  { id: 32762, s: "CHIP.LSE", n: "KraneShares ICBCCS S&P China 500 Index UCITS ETF USD GBP - IE0001QF56M0 ", c: "" },
  { id: 32763, s: "CHM.PA", n: "Lyxor Index Fund - Lyxor STOXX Europe 600 Chemicals UCITS ETF Acc - LU1834983634 ", c: "" },
  { id: 32764, s: "CHSR.XETRA", n: "UBS(Lux)Fund Solutions – MSCI Switzerland IMI Socially Responsible UCITS ETF(CHF)A-acc - LU2250132763 ", c: "" },
  { id: 32765, s: "CHUSD.SW", n: "UBS(Lux)Fund Solutions – MSCI Switzerland 20/35 UCITS ETF(hedged USD)A-acc - LU1169830368 ", c: "" },
  { id: 32766, s: "CHUSDD.SW", n: "UBS(Lux)Fund Solutions – MSCI Switzerland 20/35 UCITS ETF(hedged USD)A-dis - LU1169830285 ", c: "" },
  { id: 32767, s: "CL2.PA", n: "Amundi ETF Leveraged MSCI USA Daily UCITS ETF - FR0010755611 ", c: "" },
  { id: 32768, s: "CLMA.LSE", n: "iClima Global Decarbonisation Enablers UCITS ETF Acc - IE00BNC1F287 ", c: "" },
  { id: 32769, s: "CLO.LSE", n: "Global X Cloud Computing UCITS ETF USD Acc - IE00BMH5YF48 ", c: "" },
  { id: 32770, s: "CMES.SW", n: "iShares J.P. Morgan ESG $ EM Bond UCITS ETF CHF Hedged (Acc) - IE00BKP5L623 ", c: "" },
  { id: 32771, s: "CMEX.AS", n: "iShares VII Public Limited Company - iShares MSCI Mexico Capped UCITS ETF - IE00B5WHFQ43 ", c: "" },
  { id: 32772, s: "CMFP.LSE", n: "Legal & General UCITS ETF Plc - L&G Longer Dated All Commodities UCITS ETF - IE00B4WPHX27 ", c: "" },
  { id: 32773, s: "CMOD.LSE", n: "Source Bloomberg Commodity UCITS - IE00BD6FTQ80 ", c: "" },
  { id: 32774, s: "CMXC.LSE", n: "iShares VII Public Limited Company - iShares MSCI Mexico Capped UCITS ETF - IE00B5WHFQ43 ", c: "" },
  { id: 32775, s: "CNAA.PA", n: "Lyxor Fortune SG UCITS MSCI China A DR C - FR0011720911 ", c: "" },
  { id: 32776, s: "CNEG.LSE", n: "Amundi MSCI China ESG Leaders Select ETF DR (C) GBP - LU2343997487 ", c: "" },
  { id: 32777, s: "CNEU.LSE", n: "Amundi MSCI China ESG Leaders Select ETF DR (C) - LU2343997487 ", c: "" },
  { id: 32778, s: "CNEW.LSE", n: "VanEck New China ESG UCITS ETF A USD Acc - IE0000H445G8 ", c: "" },
  { id: 32779, s: "COBO.SW", n: "WisdomTree AT1 CoCo Bond UCITS ETF - EUR Hedged EUR - IE00BFNNN236 ", c: "" },
  { id: 32780, s: "COCB.LSE", n: "WisdomTree AT1 CoCo Bond UCITS ETF - USD Acc - IE00BZ0XVG69 ", c: "" },
  { id: 32781, s: "CODO.LSE", n: "WisdomTree AT1 CoCo Bond UCITS ETF - USD Hedged - IE00BFNNN012 ", c: "" },
  { id: 32782, s: "CODO.SW", n: "WisdomTree AT1 CoCo Bond UCITS ETF - USD Hedged USD - IE00BFNNN012 ", c: "" },
  { id: 32783, s: "COGO.SW", n: "WisdomTree AT1 CoCo Bond UCITS ETF - GBP Hedged GBP - IE00BFNNN459 ", c: "" },
  { id: 32784, s: "COMF.AS", n: "L&G Longer Dated All Commodities UCITS ETF EUR - IE00B4WPHX27 ", c: "" },
  { id: 32785, s: "COMF.LSE", n: "L&G Longer Dated All Commodities UCITS ETF - IE00B4WPHX27 ", c: "" },
  { id: 32786, s: "COPX.LSE", n: "Global X Copper Miners UCITS ETF USD Acc - IE0003Z9E2Y3 ", c: "" },
  { id: 32787, s: "CORPS.SW", n: "UBS(Lux)Fund Solutions – Bloomberg MSCI Global Liquid Corp Sust UCITS ETF(hedged USD)A-acc - LU2099991882 ", c: "" },
  { id: 32788, s: "CQQQ.SW", n: "UBS ETF - Solactive China Technology UCITS ETF (USD) A-acc - LU2265794276 ", c: "" },
  { id: 32789, s: "CRB.PA", n: "Lyxor Commodities Refinitiv/CoreCommodity CRB TR UCITS ETF - Acc-EUR - LU1829218749 ", c: "" },
  { id: 32790, s: "CS9.SW", n: "Amundi Index Solutions - Amundi MSCI Europe Ex Switzerland ESG Leaders UCITS ETF-C DR EUR - LU1681044308 ", c: "" },
  { id: 32791, s: "CSGLDC.SW", n: "iShares Gold CHF Hedged ETF (CH) A - CH0104136285 ", c: "" },
  { id: 32792, s: "CSGLDE.SW", n: "iShares Gold EUR Hedged ETF (CH) A - CH0104136319 ", c: "" },
  { id: 32793, s: "CSGOLD.SW", n: "iShares Gold ETF (CH) A - CH0104136236 ", c: "" },
  { id: 32794, s: "CSH.PA", n: "Lyxor Euro Cash UCITS ETF - FR0010510800 ", c: "" },
  { id: 32795, s: "CSKR.SW", n: "iShares VII PLC - iShares MSCI Korea ETF USD Acc - IE00B5W4TY14 ", c: "" },
  { id: 32796, s: "CSMXCP.SW", n: "iShares VII PLC - iShares MSCI Mexico Capped ETF USD Acc - IE00B5WHFQ43 ", c: "" },
  { id: 32797, s: "CSY7.XETRA", n: "Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI World ESG Leaders Blue UCITS ETF BH EUR - IE00BKKFT300 ", c: "" },
  { id: 32798, s: "CSYU.XETRA", n: "Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA Tech 125 ESG Universal Blue UCITS ETF - IE000YKE1AQ5 ", c: "" },
  { id: 32799, s: "CTEC.AS", n: "iShares MSCI China Tech UCITS ETF USD Acc - IE000NFR7C63 ", c: "" },
  { id: 32800, s: "CUIK.XETRA", n: "Amundi MSCI UK IMI SRI PAB UCITS ETF DR EUR D - LU2368674631 ", c: "" },
  { id: 32801, s: "CURE.XETRA", n: "VanEck Genomics and Healthcare Innovators UCITS ETF EUR - IE000B9PQW54 ", c: "" },
  { id: 32802, s: "CXAU.LSE", n: "UBS (Irl) Fund Solutions plc - CMCI Ex-Agriculture SF UCITS ETF - IE00BZ2GV965 ", c: "" },
  { id: 32803, s: "CYBU.AS", n: "iShares China CNY Bond UCITS ETF USD Hedged (Dist) - IE00BKT6RT64 ", c: "" },
  { id: 32804, s: "D5BE.XETRA", n: "db x-trackers II iBoxx US Treasuries 1-3 UCITS - LU0429458895 ", c: "" },
  { id: 32805, s: "D5BI.F", n: "Xtrackers - MSCI Mexico UCITS ETF - LU0476289466 ", c: "" },
  { id: 32806, s: "D5BI.XETRA", n: "Xtrackers MSCI Mexico UCITS ETF 1C - LU0476289466 ", c: "" },
  { id: 32807, s: "DAPP.LSE", n: "VanEck Digital Assets Equity UCITS ETF A USD Acc - IE00BMDKNW35 ", c: "" },
  { id: 32808, s: "DBA.MX", n: "Invesco DB Multi-Sector Commodity Trust - Invesco DB Agriculture Fund - US73936B4086 ", c: "" },
  { id: 32809, s: "DBB.MX", n: "Invesco DB Multi-Sector Commodity Trust - Invesco DB Base Metals Fund - US73936B7055 ", c: "" },
  { id: 32810, s: "DBC.MX", n: "Invesco DB Commodity Index Tracking Fund - US73935S1050 ", c: "" },
  { id: 32811, s: "DBEU.MX", n: "DBX ETF Trust - Xtrackers MSCI Europe Hedged Equity ETF - US2330518539 ", c: "" },
  { id: 32812, s: "DBO.MX", n: "Invesco DB Multi-Sector Commodity Trust - Invesco DB Oil Fund - US73936B5075 ", c: "" },
  { id: 32813, s: "DBPD.F", n: "Xtrackers - ShortDAX x2 Daily Swap UCITS ETF - LU0411075020 ", c: "" },
  { id: 32814, s: "DBPD.XETRA", n: "Xtrackers ShortDAX x2 Daily Swap UCITS ETF 1C - LU0411075020 ", c: "" },
  { id: 32815, s: "DBPE.F", n: "Xtrackers - LevDAX Daily Swap UCITS ETF - LU0411075376 ", c: "" },
  { id: 32816, s: "DBPE.XETRA", n: "Xtrackers LevDAX Daily Swap UCITS ETF 1C - LU0411075376 ", c: "" },
  { id: 32817, s: "DBPG.F", n: "Xtrackers - S&P 500 2x Leveraged Daily Swap UCITS ETF - LU0411078552 ", c: "" },
  { id: 32818, s: "DBPG.XETRA", n: "Xtrackers S&P 500 2x Leveraged Daily Swap UCITS ETF 1C - LU0411078552 ", c: "" },
  { id: 32819, s: "DBPK.F", n: "Xtrackers - S&P 500 2x Inverse Daily Swap UCITS ETF - LU0411078636 ", c: "" },
  { id: 32820, s: "DBPK.XETRA", n: "db x-trackers S&P 500 2x Inverse Daily UCITS 1C - LU0411078636 ", c: "" },
  { id: 32821, s: "DBX4.F", n: "Xtrackers - MSCI EM Europe Middle East & Africa Swap UCITS ETF - LU0292109005 ", c: "" },
  { id: 32822, s: "DBX4.XETRA", n: "Xtrackers - MSCI EM Europe Middle East & Africa Swap UCITS ETF - LU0292109005 ", c: "" },
  { id: 32823, s: "DBXD.XETRA", n: "Xtrackers - DAX UCITS ETF - LU0274211480 ", c: "" },
  { id: 32824, s: "DBXT.XETRA", n: "Xtrackers II - EUR Cash Swap UCITS ETF - LU0290358497 ", c: "" },
  { id: 32825, s: "DBZN.F", n: "Xtrackers - DB Bloomberg Commodity Optimum Yield Swap UCITS ETF - LU0429790743 ", c: "" },
  { id: 32826, s: "DBZN.XETRA", n: "Xtrackers - DB Bloomberg Commodity Optimum Yield Swap UCITS ETF - LU0429790743 ", c: "" },
  { id: 32827, s: "DCCHAS.SW", n: "UBS (Irl) Fund Solutions plc – Bloomberg Commodity Index SF UCITS ETF (hedged to CHF) A-acc - IE00B598DX38 ", c: "" },
  { id: 32828, s: "DCEUAS.SW", n: "UBS (Irl) Fund Solutions plc – Bloomberg Commodity Index SF UCITS ETF (hedged to EUR) A-acc - IE00B58ZM503 ", c: "" },
  { id: 32829, s: "DCGBAS.SW", n: "UBS (Irl) Fund Solutions plc – Bloomberg Commodity Index SF UCITS ETF (hedged to GBP) A-acc - IE00B5993T22 ", c: "" },
  { id: 32830, s: "DCUSAS.SW", n: "UBS (Irl) Fund Solutions plc – Bloomberg Commodity Index SF UCITS ETF (USD) A-acc - IE00B5B3W843 ", c: "" },
  { id: 32831, s: "DDBG.LSE", n: "iShares $ Development Bank Bonds UCITS ETF GBP Hedged (Dist) - IE00BN2B7L13 ", c: "" },
  { id: 32832, s: "DDM.MX", n: "ProShares Trust - ProShares Ultra Dow30 - US74347R3057 ", c: "" },
  { id: 32833, s: "DDOC.XETRA", n: "Global X Telemedicine & Digital Health ETF A - IE00BLR6QB00 ", c: "" },
  { id: 32834, s: "DECD.XETRA", n: "Amundi DAX 50 ESG UCITS ETF DR - EUR (C) - LU2240851688 ", c: "" },
  { id: 32835, s: "DESG.SW", n: "Amundi DAX 50 ESG UCITS ETF DR - EUR (C) EUR - LU2240851688 ", c: "" },
  { id: 32836, s: "DGRW.MX", n: "WisdomTree Trust - WisdomTree U.S. Quality Dividend Growth Fund - US97717X6691 ", c: "" },
  { id: 32837, s: "DHSG.LSE", n: "WisdomTree US Equity Income UCITS ETF - GBP Hedged Acc - IE00BD6RZZ53 ", c: "" },
  { id: 32838, s: "DHYC.SW", n: "iShares $ High Yield Corp Bond ESG UCITS ETF CHF Hedged (Acc) - IE00BMWPV694 ", c: "" },
  { id: 32839, s: "DIA.MX", n: "SPDR Dow Jones Industrial Average ETF Trust - US78467X1090 ", c: "" },
  { id: 32840, s: "DIG.MX", n: "ProShares Trust - ProShares Ultra Oil & Gas - US74347G7051 ", c: "" },
  { id: 32841, s: "DJAM.XETRA", n: "Lyxor UCITS ETF Dow Jones Industrial Average - FR0007056841 ", c: "" },
  { id: 32842, s: "DJE.PA", n: "Lyxor UCITS Dow Jones Industrial Average D-EUR - FR0007056841 ", c: "" },
  { id: 32843, s: "DJSXE.SW", n: "iShares Core EURO STOXX 50 UCITS ETF (DE) EUR - DE0005933956 ", c: "" },
  { id: 32844, s: "DMFC.SW", n: "iShares Edge MSCI World Multifactor UCITS ETF CHF Hedged (Acc) - IE00BF450720 ", c: "" },
  { id: 32845, s: "DNRA.XETRA", n: "AMUNDI MSCI North America ESG Climate Net Zero Ambition CTB UCITS ETF Dist - IE000MJIXFE0 ", c: "" },
  { id: 32846, s: "DOG.MX", n: "ProShares Trust - ProShares Short Dow30 - US74347B2354 ", c: "" },
  { id: 32847, s: "DPAG.LSE", n: "Legal & General UCITS ETF Plc - L&G Digital Payments UCITS ETF GBP - IE00BF92J153 ", c: "" },
  { id: 32848, s: "DPAY.LSE", n: "Legal & General UCITS ETF Plc - L&G Digital Payments UCITS ETF - IE00BF92J153 ", c: "" },
  { id: 32849, s: "DPAY.SW", n: "Legal & General UCITS ETF Plc - L&G Digital Payments UCITS ETF CHF - IE00BF92J153 ", c: "" },
  { id: 32850, s: "DPGA.XETRA", n: "Legal & General UCITS ETF Plc - L&G Digital Payments UCITS ETF - IE00BF92J153 ", c: "" },
  { id: 32851, s: "DPYE.LSE", n: "iShares Developed Markets Property Yield UCITS ETF EUR Hedged (Acc) - IE00BDZVHD04 ", c: "" },
  { id: 32852, s: "DPYG.LSE", n: "iShares Developed Markets Property Yield UCITS ETF GBP Hedged (Dist) - IE00BDZVHC96 ", c: "" },
  { id: 32853, s: "DRGE.XETRA", n: "L&G ESG China CNY Bond UCITS ETF EUR Inc - IE000F472DU7 ", c: "" },
  { id: 32854, s: "DRGN.SW", n: "L&G China CNY Bond UCITS ETF CHF - IE00BLRPQL76 ", c: "" },
  { id: 32855, s: "DRGN.XETRA", n: "L&G ESG China CNY Bond UCITS ETF - IE00BLRPQL76 ", c: "" },
  { id: 32856, s: "DRVE.LSE", n: "Global X Autonomous & Electric Vehicles UCITS ETF USD Acc - IE00BMH5YR69 ", c: "" },
  { id: 32857, s: "DSB.PA", n: "Lyxor UCITS Daily Double Short Bund - FR0010869578 ", c: "" },
  { id: 32858, s: "DSUS.LSE", n: "Lyxor 10Y US Treasury Daily (-2x) Inverse UCITS ETF Acc - FR0011607084 ", c: "" },
  { id: 32859, s: "DTLC.SW", n: "iShares $ Treasury Bond 20+yr UCITS ETF CHF Hedged (Dist) - IE00BD8PH067 ", c: "" },
  { id: 32860, s: "DTLE.LSE", n: "iShares $ Treasury Bond 20+yr UCITS ETF EUR Hedged (Dist) - IE00BD8PGZ49 ", c: "" },
  { id: 32861, s: "DTLE.SW", n: "iShares $ Treasury Bond 20+yr UCITS ETF EUR Hedged (Dist) EUR - IE00BD8PGZ49 ", c: "" },
  { id: 32862, s: "DUG.MX", n: "ProShares Trust - ProShares UltraShort Oil & Gas - US74347G3589 ", c: "" },
  { id: 32863, s: "DVY.MX", n: "iShares Trust - iShares Select Dividend ETF - US4642871689 ", c: "" },
  { id: 32864, s: "DX22.F", n: "Xtrackers II - EUR Cash Swap UCITS ETF - LU0335044896 ", c: "" },
  { id: 32865, s: "DX22.XETRA", n: "Xtrackers II - EUR Cash Swap UCITS ETF - LU0335044896 ", c: "" },
  { id: 32866, s: "DX2K.F", n: "Xtrackers - FTSE 100 Short Daily Swap UCITS ETF - LU0328473581 ", c: "" },
  { id: 32867, s: "DX2K.XETRA", n: "Xtrackers FTSE 100 Short Daily Swap UCITS ETF 1C - LU0328473581 ", c: "" },
  { id: 32868, s: "DXESG.SW", n: "Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) DAX 50 ESG Blue UCITS ETF B EUR Acc - IE00BKVD2J03 ", c: "" },
  { id: 32869, s: "DXJ.LSE", n: "WisdomTree Japan Equity UCITS ETF - USD Hedged - IE00BVXC4854 ", c: "" },
  { id: 32870, s: "DXJA.LSE", n: "WisdomTree Japan Equity UCITS ETF - USD Hedged Acc - IE00BYQCZD50 ", c: "" },
  { id: 32871, s: "DXJD.SW", n: "WisdomTree Japan Equity UCITS ETF - CHF Hedged Acc - IE00BYQCZL35 ", c: "" },
  { id: 32872, s: "DXJP.LSE", n: "WisdomTree Japan Equity UCITS ETF - GBP Hedged - IE00BYQCZF74 ", c: "" },
  { id: 32873, s: "DXJP.SW", n: "WisdomTree Japan Equity UCITS ETF - GBP Hedged GBP - IE00BYQCZF74 ", c: "" },
  { id: 32874, s: "DXS1.F", n: "Xtrackers II - GBP Cash Swap UCITS ETF - LU0321464652 ", c: "" },
  { id: 32875, s: "DXS1.XETRA", n: "Xtrackers II - GBP Cash Swap UCITS ETF - LU0321464652 ", c: "" },
  { id: 32876, s: "DXS3.F", n: "Xtrackers - S&P 500 Inverse Daily Swap UCITS ETF - LU0322251520 ", c: "" },
  { id: 32877, s: "DXS3.XETRA", n: "Xtrackers - S&P 500 Inverse Daily Swap UCITS ETF - LU0322251520 ", c: "" },
  { id: 32878, s: "DXS5.XETRA", n: "Xtrackers - MSCI AC Asia ex Japan Swap UCITS ETF - LU0322252171 ", c: "" },
  { id: 32879, s: "DXSK.XETRA", n: "Xtrackers - Stoxx Europe 600 Food & Beverage Swap UCITS ETF - LU0292105359 ", c: "" },
  { id: 32880, s: "DXSM.XETRA", n: "Xtrackers Bloomberg Commodity ex-Agriculture & Livestock Swap UCITS ETF 1C EUR Hedged - LU0292106167 ", c: "" },
  { id: 32881, s: "DXSN.F", n: "Xtrackers - ShortDAX Daily Swap UCITS ETF - LU0292106241 ", c: "" },
  { id: 32882, s: "DXSN.XETRA", n: "Xtrackers - ShortDAX Daily Swap UCITS ETF - LU0292106241 ", c: "" },
  { id: 32883, s: "DXSP.F", n: "Xtrackers - Euro Stoxx 50 Short Daily Swap UCITS ETF - LU0292106753 ", c: "" },
  { id: 32884, s: "DXSP.XETRA", n: "Xtrackers - Euro Stoxx 50 Short Daily Swap UCITS ETF - LU0292106753 ", c: "" },
  { id: 32885, s: "DXST.XETRA", n: "Xtrackers II - iTraxx Crossover Short Daily Swap UCITS ETF - LU0321462870 ", c: "" },
  { id: 32886, s: "DXSV.F", n: "Xtrackers II - Eurozone Government Bond Short Daily Swap UCITS ETF - LU0321463258 ", c: "" },
  { id: 32887, s: "DXSV.XETRA", n: "Xtrackers II - Eurozone Government Bond Short Daily Swap UCITS ETF - LU0321463258 ", c: "" },
  { id: 32888, s: "DXSZ.F", n: "Xtrackers II - USD Overnight Rate Swap UCITS ETF - LU0321465469 ", c: "" },
  { id: 32889, s: "DXSZ.XETRA", n: "Xtrackers II - USD Overnight Rate Swap UCITS ETF - LU0321465469 ", c: "" },
  { id: 32890, s: "E500.F", n: "Source S&P 500 UCITS Hedged - IE00BRKWGL70 ", c: "" },
  { id: 32891, s: "E500.XETRA", n: "Source S&P 500 UCITS Hedged - IE00BRKWGL70 ", c: "" },
  { id: 32892, s: "E903.XETRA", n: "Lyxor 1 DivDAX (DR) UCITS ETF I - DE000ETF9033 ", c: "" },
  { id: 32893, s: "E909.XETRA", n: "Lyxor 1 DAX 50 ESG (DR) UCITS ETF I - DE000ETF9090 ", c: "" },
  { id: 32894, s: "E960.XETRA", n: "Lyxor 1 STOXX Europe 600 ESG (DR) UCITS ETF - DE000ETF9603 ", c: "" },
  { id: 32895, s: "EAH.XETRA", n: "Lyxor Euro Government Green Bond DR ETF Acc - LU2356220926 ", c: "" },
  { id: 32896, s: "EB3M.SW", n: "iShares J.P. Morgan € EM Bond UCITS ETF EUR Dis EUR - IE00B6TQLL84 ", c: "" },
  { id: 32897, s: "EB3M.XETRA", n: "iShares J.P. Morgan € EM Bond UCITS ETF EUR Dis - IE00B6TQLL84 ", c: "" },
  { id: 32898, s: "ECB.PA", n: "Lyxor Index Fund- Lyxor Euromts Covered Bond Aggregate Ucits Etf - LU1686830065 ", c: "" },
  { id: 32899, s: "ECH.MX", n: "iShares MSCI Chile ETF - US4642866408 ", c: "" },
  { id: 32900, s: "ECR1.XETRA", n: "Amundi Index Solutions Amundi Euro Corp 0-1Y ESG UCITS ETF DR (C) - LU2300294316 ", c: "" },
  { id: 32901, s: "EDC.MX", n: "Direxion Shares ETF Trust - Direxion Daily MSCI Emerging Markets Bull 3X Shares - US25459W3007 ", c: "" },
  { id: 32902, s: "EDOC.LSE", n: "Global X Telemedicine & Digital Health ETF A - IE00BLR6QB00 ", c: "" },
  { id: 32903, s: "EDOC.SW", n: "Global X Telemedicine & Digital Health ETF A CHF - IE00BLR6QB00 ", c: "" },
  { id: 32904, s: "EDZ.MX", n: "Direxion Shares ETF Trust - Direxion Daily MSCI Emerging Markets Bear 3X Shares - US25459W4823 ", c: "" },
  { id: 32905, s: "EEDV.AS", n: "SPDR® S&P® Euro Dividend Aristocrats ESG UCITS ETF EUR - IE00BYTH5T38 ", c: "" },
  { id: 32906, s: "EEEH.SW", n: "BNP Paribas Easy FTSE EPRA/NAREIT Developed Europe UCITS ETF QD H EUR EUR - LU1859445063 ", c: "" },
  { id: 32907, s: "EEM.MX", n: "iShares Inc. - iShares MSCI Emerging Markets ETF - US4642872349 ", c: "" },
  { id: 32908, s: "EEMU.XETRA", n: "Invesco Markets II plc - Invesco MSCI EMU ESG Universal Screened UCITS ETF - IE00BMDBMX02 ", c: "" },
  { id: 32909, s: "EEMV.MX", n: "iShares Inc. - iShares MSCI Emerging Markets Min Vol Factor ETF - US4642865335 ", c: "" },
  { id: 32910, s: "EEPG.XETRA", n: "BNP Paribas Easy FTSE EPRA Nareit Developed Europe ex UK Green CTB UCITS ETF Cap - LU2008763935 ", c: "" },
  { id: 32911, s: "EESGA.SW", n: "UBS (Irl) ETF plc MSCI Europe ESG Universal Low Carbon Select UCITS ETF A - IE00BNC0M681 ", c: "" },
  { id: 32912, s: "EGRE.XETRA", n: "BNP Paribas Easy ESG Growth Europe UCITS ETF EUR Cap - LU2244387887 ", c: "" },
  { id: 32913, s: "EGRW.SW", n: "WisdomTree Eurozone Quality Dividend Growth UCITS ETF - EUR EUR - IE00BZ56SY76 ", c: "" },
  { id: 32914, s: "EGV5.XETRA", n: "MULTI-UNITS LUXEMBOURG - Lyxor Euro Government Bond 3-5Y (DR) UCITS ETF 2 - LU1650488817 ", c: "" },
  { id: 32915, s: "EHYC.SW", n: "iShares € High Yield Corp Bond ESG UCITS ETF CHF Hedged (Acc) - IE00BMWPV587 ", c: "" },
  { id: 32916, s: "EIDO.MX", n: "iShares Trust - iShares MSCI Indonesia ETF - US46429B3096 ", c: "" },
  { id: 32917, s: "EJAH.PA", n: "BNP Paribas Easy MSCI Japan ESG Filtered Min TE UCITS ETF H EUR Cap - LU1481203070 ", c: "" },
  { id: 32918, s: "EJAH.XETRA", n: "BNP Paribas Easy MSCI Japan ESG Filtered Min TE UCITS ETF H EUR Cap - LU1481203070 ", c: "" },
  { id: 32919, s: "EKUS.PA", n: "BNP Paribas Easy MSCI USA SRI S-Series PAB 5% Capped UCITS ETF EUR Distribution - LU1659681669 ", c: "" },
  { id: 32920, s: "EL40.XETRA", n: "Deka MSCI Emerging Markets UCITS ETF - DE000ETFL342 ", c: "" },
  { id: 32921, s: "EL4W.F", n: "Deka Deutsche Börse EUROGOV Germany Money Market UCITS ETF - DE000ETFL227 ", c: "" },
  { id: 32922, s: "EL4W.XETRA", n: "Deka Deutsche Börse EUROGOV Germany Money Market UCITS ETF - DE000ETFL227 ", c: "" },
  { id: 32923, s: "ELCR.XETRA", n: "Lyxor Index Fund - Lyxor Future Mobility (DR) UCITS ETF - LU2023679090 ", c: "" },
  { id: 32924, s: "ELEC.LSE", n: "Electric Vehicle Charging Infrastructure UCITS ETF Accumulating - IE000HMSHYJ6 ", c: "" },
  { id: 32925, s: "ELFA.XETRA", n: "Deka EURO STOXX 50® ESG UCITS ETF - DE000ETFL466 ", c: "" },
  { id: 32926, s: "EMA5.XETRA", n: "L&G ESG Emerging Markets Government Bond (USD) 0-5 Year UCITS ETF EUR - IE00BLRPQP15 ", c: "" },
  { id: 32927, s: "EMAB.XETRA", n: "L&G ESG Emerging Markets Corporate Bond (USD) UCITS ETF - IE00BLRPRF81 ", c: "" },
  { id: 32928, s: "EMB.MX", n: "iShares Trust - iShares J.P. Morgan USD Emerging Markets Bond ETF - US4642882819 ", c: "" },
  { id: 32929, s: "EMBC.SW", n: "iShares J.P. Morgan $ EM Bond CHF Hedged UCITS ETF (Dist) - IE00B9M04V95 ", c: "" },
  { id: 32930, s: "EMBE.LSE", n: "iShares J.P. Morgan Emerging Markets Bond EUR Hedged UCITS - IE00B9M6RS56 ", c: "" },
  { id: 32931, s: "EMD5.SW", n: "L&G ESG Emerging Markets Government Bond (USD) 0-5 Year UCITS ETF CHF - IE00BLRPQP15 ", c: "" },
  { id: 32932, s: "EMDV.LSE", n: "SPDR S&P Emerging Markets Dividend Aristocrats UCITS ETF - IE00B6YX5B26 ", c: "" },
  { id: 32933, s: "EMEH.PA", n: "BNP Paribas Easy Energy & Metals Enhanced Roll - LU1547516291 ", c: "" },
  { id: 32934, s: "EMEH.SW", n: "BNP Paribas Easy Energy & Metals Enhanced Roll UCITS ETF RH EUR Cap EUR - LU1547516291 ", c: "" },
  { id: 32935, s: "EMEH.XETRA", n: "BNP Paribas Easy Energy & Metals Enhanced Roll UCITS ETF RH EUR Cap - LU1547516291 ", c: "" },
  { id: 32936, s: "EMHG.LSE", n: "iShares J.P. Morgan $ EM Bond UCITS ETF GBP Hedged (Dist) - IE00BDFGJ734 ", c: "" },
  { id: 32937, s: "EMLC.MX", n: "VanEck Vectors ETF Trust - VanEck Vectors J.P. Morgan EM Local Currency Bond ETF - US92189H3003 ", c: "" },
  { id: 32938, s: "EMLD.PA", n: "SSgA SPDR ETFs Europe I Public Limited Company - SPDR Barclays Emerging Markets Local Bond UCITS ETF - IE00B4613386 ", c: "" },
  { id: 32939, s: "EMPA.AS", n: "iShares MSCI EMU Paris-Aligned Climate UCITS ETF EUR Acc - IE00BL6K8D99 ", c: "" },
  { id: 32940, s: "EMPA.SW", n: "UBS (Irl) ETF plc MSCI Emerging Markets Climate Paris Aligned UCITS ETF A USD Acc - IE00BN4Q1675 ", c: "" },
  { id: 32941, s: "EMRJ.LSE", n: "Rize Emerging Market Internet and Ecommerce UCITS ETF A USD Acc - IE000HJV1VH3 ", c: "" },
  { id: 32942, s: "EMUC.SW", n: "iShares VII PLC - iShares MSCI EMU CHF Hedged ETF Acc - IE00BWK1SP74 ", c: "" },
  { id: 32943, s: "EMUCHF.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU UCITS ETF(hedged CHF)A-acc - LU1169819635 ", c: "" },
  { id: 32944, s: "EMUPA.SW", n: "UBS (Irl) ETF plc - MSCI EMU Climate Paris Aligned UCITS ETF A EUR EUR - IE00BN4Q0L55 ", c: "" },
  { id: 32945, s: "EMUSD.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU UCITS ETF(hedged USD)A-dis - LU1169819718 ", c: "" },
  { id: 32946, s: "EMUU.LSE", n: "iShares VII PLC - iShares MSCI EMU USD Hedged ETF Acc - IE00BWZN1T31 ", c: "" },
  { id: 32947, s: "EMUUSD.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU UCITS ETF(hedged USD)A-acc - LU1169819809 ", c: "" },
  { id: 32948, s: "EMXU.LSE", n: "Amundi MSCI Emerging Ex China ESG Leaders Select UCITS ETF DR USD Acc - LU2345046655 ", c: "" },
  { id: 32949, s: "ENG.PA", n: "BNP Paribas Easy SICAV - NMX 30 Infrastructure Global - LU1291109293 ", c: "" },
  { id: 32950, s: "ENVR.SW", n: "Rize Environmental Impact 100 UCITS ETF CHF - IE00BLRPRR04 ", c: "" },
  { id: 32951, s: "EPAD.AS", n: "iShares MSCI Europe Paris-Aligned Climate UCITS ETF EUR Dist - IE000HH3SU50 ", c: "" },
  { id: 32952, s: "EPOL.MX", n: "iShares Trust - iShares MSCI Poland ETF - US46429B6065 ", c: "" },
  { id: 32953, s: "EPU.MX", n: "iShares Trust - iShares MSCI Peru ETF - US4642898427 ", c: "" },
  { id: 32954, s: "EQCH.SW", n: "Invesco EQQQ NASDAQ-100 UCITS ETF (CHF Hdg) - IE00BYVTMT69 ", c: "" },
  { id: 32955, s: "EQEU.SW", n: "Invesco EQQQ NASDAQ-100 UCITS ETF (EUR Hdg) EUR - IE00BYVTMS52 ", c: "" },
  { id: 32956, s: "EQEU.XETRA", n: "Invesco EQQQ NASDAQ-100 UCITS ETF (EUR Hdg) - IE00BYVTMS52 ", c: "" },
  { id: 32957, s: "EQGB.LSE", n: "Invesco EQQQ NASDAQ-100 UCITS ETF (GBP Hdg) - IE00BYVTMW98 ", c: "" },
  { id: 32958, s: "EQGB.SW", n: "Invesco EQQQ NASDAQ-100 UCITS ETF (GBP Hdg) GBP - IE00BYVTMW98 ", c: "" },
  { id: 32959, s: "EQLTS.SW", n: "UBS(Lux)Fund Solutions – Factor MSCI EMU Quality UCITS ETF(hedged CHF)A-acc - LU1215452092 ", c: "" },
  { id: 32960, s: "EQQJ.LSE", n: "Invesco NASDAQ Next Generation 100 UCITS ETF Acc - IE00BMD8KP97 ", c: "" },
  { id: 32961, s: "ERUS.MX", n: "iShares Inc. - iShares MSCI Russia ETF - US46434G7988 ", c: "" },
  { id: 32962, s: "ESEH.PA", n: "BNP Paribas Easy S&P 500 UCITS ETF EUR H - FR0013041530 ", c: "" },
  { id: 32963, s: "ESEH.XETRA", n: "BNP Paribas Easy S&P 500 UCITS ETF EUR H - FR0013041530 ", c: "" },
  { id: 32964, s: "ESEM.LSE", n: "Invesco MSCI Emerging Markets ESG Universal Screened UCITS ETF - IE00BMDBMY19 ", c: "" },
  { id: 32965, s: "ESGB.LSE", n: "VanEck Vectors Video Gaming and eSports UCITS ETF A USD - IE00BYWQWR46 ", c: "" },
  { id: 32966, s: "ESGJ.LSE", n: "Invesco Markets II plc - Invesco MSCI Japan ESG Universal Screened UCITS ETF - IE00BMDBMV87 ", c: "" },
  { id: 32967, s: "ESGJ.XETRA", n: "Invesco Markets II plc - Invesco MSCI Japan ESG Universal Screened UCITS ETF - IE00BMDBMV87 ", c: "" },
  { id: 32968, s: "ESGM.XETRA", n: "Invesco MSCI Emerging Markets ESG Universal Screened UCITS ETF - IE00BMDBMY19 ", c: "" },
  { id: 32969, s: "ESGP.LSE", n: "AuAg ESG Gold Mining UCITS ETF GBP - IE00BNTVVR89 ", c: "" },
  { id: 32970, s: "ESGP.XETRA", n: "Invesco Markets II plc - Invesco MSCI Pacific Ex Japan ESG Universal Screened UCITS ETF - IE00BMDBMT65 ", c: "" },
  { id: 32971, s: "ESGX.XETRA", n: "Invesco Markets II plc - Invesco MSCI Europe Ex UK ESG Universal Screened UCITS ETF - IE00BMDBMW94 ", c: "" },
  { id: 32972, s: "ESIC.LSE", n: "iShares MSCI Europe Consumer Discretionary Sector UCITS ETF GBP - IE00BMW42298 ", c: "" },
  { id: 32973, s: "ESIC.XETRA", n: "iShares MSCI Europe Consumer Discretionary Sector UCITS ETF - IE00BMW42298 ", c: "" },
  { id: 32974, s: "ESIE.LSE", n: "iShares MSCI Europe Energy Sector UCITS ETF GBP - IE00BMW42637 ", c: "" },
  { id: 32975, s: "ESIE.XETRA", n: "iShares MSCI Europe Energy Sector UCITS ETF - IE00BMW42637 ", c: "" },
  { id: 32976, s: "ESIF.LSE", n: "iShares MSCI Europe Financials Sector UCITS ETF - IE00BMW42306 ", c: "" },
  { id: 32977, s: "ESIF.XETRA", n: "iShares MSCI Europe Financials Sector UCITS ETF - IE00BMW42306 ", c: "" },
  { id: 32978, s: "ESIH.LSE", n: "iShares MSCI Europe Health Care Sector UCITS ETF - IE00BMW42181 ", c: "" },
  { id: 32979, s: "ESIH.XETRA", n: "iShares MSCI Europe Health Care Sector UCITS ETF - IE00BMW42181 ", c: "" },
  { id: 32980, s: "ESIN.XETRA", n: "iShares MSCI Europe Industrials Sector UCITS ETF EUR Acc - IE00BMW42520 ", c: "" },
  { id: 32981, s: "ESIS.LSE", n: "iShares MSCI Europe Consumer Staples Sector UCITS ETF - IE00BMW42074 ", c: "" },
  { id: 32982, s: "ESIS.XETRA", n: "iShares MSCI Europe Consumer Staples Sector UCITS ETF - IE00BMW42074 ", c: "" },
  { id: 32983, s: "ESIT.LSE", n: "iShares MSCI Europe Information Technology Sector UCITS ETF - IE00BMW42413 ", c: "" },
  { id: 32984, s: "ESIT.XETRA", n: "iShares MSCI Europe Information Technology Sector UCITS ETF - IE00BMW42413 ", c: "" },
  { id: 32985, s: "ESP0.XETRA", n: "VanEck Vectors Video Gaming and eSports UCITS ETF A USD - IE00BYWQWR46 ", c: "" },
  { id: 32986, s: "ESPB.LSE", n: "Invesco MSCI USA ESG Universal Screened UCITS ETF GBP Accumulation - IE000T5R61X5 ", c: "" },
  { id: 32987, s: "ESPJ.LSE", n: "Invesco Markets II plc - Invesco MSCI Pacific Ex Japan ESG Universal Screened UCITS ETF - IE00BMDBMT65 ", c: "" },
  { id: 32988, s: "ESPJ.SW", n: "Invesco Markets II plc - Invesco MSCI Pacific Ex Japan ESG Universal Screened UCITS ETF USD - IE00BMDBMT65 ", c: "" },
  { id: 32989, s: "ESUD.LSE", n: "Invesco MSCI USA ESG Universal Screened UCITS ETF USD Distribution - IE000A8N67F3 ", c: "" },
  { id: 32990, s: "ETL2.XETRA", n: "L&G Longer Dated All Commodities UCITS ETF - IE00B4WPHX27 ", c: "" },
  { id: 32991, s: "ETLF.XETRA", n: "L&G All Commodities UCITS ETF - IE00BF0BCP69 ", c: "" },
  { id: 32992, s: "EU13.LSE", n: "SPDR Barclays Capital 1-3 Yr Euro Govt Bond - IE00B6YX5F63 ", c: "" },
  { id: 32993, s: "EUESG.SW", n: "UBS (Irl) ETF plc MSCI EMU ESG Universal Low Carbon Select UCITS ETF A - IE00BNC0M913 ", c: "" },
  { id: 32994, s: "EUGBPD.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU UCITS ETF(hedged GBP)A-dis GBP - LU1169819981 ", c: "" },
  { id: 32995, s: "EUHA.XETRA", n: "PIMCO Euro Short-Term High Yield Corporate Bond UCITS ETF EUR Accumulation - IE00BD8D5G25 ", c: "" },
  { id: 32996, s: "EUIN.F", n: "Multi Units Luxembourg - Lyxor EUR 2-10Y Inflation Breakeven UCITS ETF - LU1390062831 ", c: "" },
  { id: 32997, s: "EUIN.XETRA", n: "Multi Units Luxembourg - Lyxor EUR 2-10Y Inflation Breakeven UCITS ETF - LU1390062831 ", c: "" },
  { id: 32998, s: "EUNA.AS", n: "iShares STOXX Europe 50 UCITS ETF - IE0008470928 ", c: "" },
  { id: 32999, s: "EURCHA.SW", n: "UBS(Lux)Fund Solutions – MSCI Europe UCITS ETF(hedged CHF)A-acc - LU1589327680 ", c: "" },
  { id: 33000, s: "EURPA.SW", n: "UBS (Irl) ETF plc - MSCI Europe Climate Paris Aligned UCITS ETF A EUR EUR - IE00BN4Q0933 ", c: "" },
  { id: 33001, s: "EUSR.LSE", n: "UBS(Lux)Fund Solutions – MSCI EMU Socially Responsible UCITS ETF(hedged GBP)A-dis - LU1280300853 ", c: "" },
  { id: 33002, s: "EUSRS.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU Socially Responsible UCITS ETF(hedged CHF)A-acc - LU1273642816 ", c: "" },
  { id: 33003, s: "EUSRT.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU Socially Responsible UCITS ETF(hedged CHF)A-dis - LU1273642907 ", c: "" },
  { id: 33004, s: "EUSRU.SW", n: "UBS(Lux)Fund Solutions – MSCI EMU Socially Responsible UCITS ETF(hedged USD)A-acc - LU1280300770 ", c: "" },
  { id: 33005, s: "EUXS.LSE", n: "iShares MSCI Europe Ex UK GBP - IE00BVDPJP67 ", c: "" },
  { id: 33006, s: "EVOE.PA", n: "BNP Paribas Easy ESG Equity Low Vol Europe UCITS ETF Cap - LU1377381717 ", c: "" },
  { id: 33007, s: "EWA.MX", n: "iShares Inc. - iShares MSCI Australia ETF - US4642861037 ", c: "" },
  { id: 33008, s: "EWC.MX", n: "iShares Inc. - iShares MSCI Canada ETF - US4642865095 ", c: "" },
  { id: 33009, s: "EWG.MX", n: "iShares Inc. - iShares MSCI Germany ETF - US4642868065 ", c: "" },
  { id: 33010, s: "EWH.MX", n: "iShares Inc. - iShares MSCI Hong Kong ETF - US4642868719 ", c: "" },
  { id: 33011, s: "EWL.MX", n: "iShares Inc. - iShares MSCI Switzerland ETF - US4642867497 ", c: "" },
  { id: 33012, s: "EWM.MX", n: "iShares Inc. - iShares MSCI Malaysia ETF - US46434G8143 ", c: "" },
  { id: 33013, s: "EWO.MX", n: "iShares Inc. - iShares MSCI Austria ETF - US4642862027 ", c: "" },
  { id: 33014, s: "EWP.MX", n: "iShares Inc. - iShares MSCI Spain ETF - US4642867646 ", c: "" },
  { id: 33015, s: "EWQ.MX", n: "iShares Inc. - iShares MSCI France ETF - US4642867075 ", c: "" },
  { id: 33016, s: "EWSA.AS", n: "iShares MSCI World Small Cap ESG Enhanced UCITS ETF USD Acc - IE000T9EOCL3 ", c: "" },
  { id: 33017, s: "EWY.MX", n: "iShares Inc. - iShares MSCI South Korea ETF - US4642867729 ", c: "" },
  { id: 33018, s: "EWZ.MX", n: "iShares Inc. - iShares MSCI Brazil ETF - US4642864007 ", c: "" },
  { id: 33019, s: "EXH2.XETRA", n: "iShares (DE) I - iShares STOXX Europe 600 Financial Services UCITS ETF (DE) - DE000A0H08G5 ", c: "" },
  { id: 33020, s: "EXIA.SW", n: "iShares DAX® ESG UCITS ETF (DE) EUR - DE000A0Q4R69 ", c: "" },
  { id: 33021, s: "EXIA.XETRA", n: "iShares DAX® ESG UCITS ETF (DE) - DE000A0Q4R69 ", c: "" },
  { id: 33022, s: "EXIB.XETRA", n: "iShares TecDAX® UCITS ETF (DE) Dist - DE000A2QP323 ", c: "" },
  { id: 33023, s: "EXIC.XETRA", n: "iShares Core DAX® UCITS ETF (DE) EUR (Dist) - DE000A2QP331 ", c: "" },
  { id: 33024, s: "EXID.XETRA", n: "iShares MDAX® UCITS ETF (DE) - DE000A2QP349 ", c: "" },
  { id: 33025, s: "EXSG.XETRA", n: "iShares EURO STOXX Select Dividend 30 UCITS ETF (DE) - DE0002635281 ", c: "" },
  { id: 33026, s: "EXV5.XETRA", n: "iShares STOXX Europe 600 Automobiles & Parts UCITS ETF (DE) - DE000A0Q4R28 ", c: "" },
  { id: 33027, s: "EXVM.XETRA", n: "iShares eb.rexx Money Market UCITS ETF (DE) - DE000A0Q4RZ9 ", c: "" },
  { id: 33028, s: "EXXY.XETRA", n: "iShares Diversified Commodity Swap UCITS ETF (DE) - DE000A0H0728 ", c: "" },
  { id: 33029, s: "EZA.MX", n: "iShares Inc. - iShares MSCI South Africa ETF - US4642867802 ", c: "" },
  { id: 33030, s: "EZU.MX", n: "iShares Inc. - iShares MSCI Eurozone ETF - US4642866085 ", c: "" },
  { id: 33031, s: "F4DE.XETRA", n: "Ossiam Food for Biodiversity UCITS ETF Class 1A EUR Acc - IE00BN0YSK89 ", c: "" },
  { id: 33032, s: "F4DU.XETRA", n: "Ossiam Food for Biodiversity UCITS ETF Class 1A USD Acc - IE00BN0YSJ74 ", c: "" },
  { id: 33033, s: "F500.XETRA", n: "Amundi S&P 500 ESG UCITS ETF Acc EUR EUR - IE000KXCEXR3 ", c: "" },
  { id: 33034, s: "F702.XETRA", n: "Lyxor Portfolio Strategy Defensiv UCITS ETF (Vermoegensstrategie) - DE000ETF7029 ", c: "" },
  { id: 33035, s: "FACH.SW", n: "Invesco US High Yield Fallen Angels UCITS ETF (CHF Hdg) - IE00BYVTMY13 ", c: "" },
  { id: 33036, s: "FAEU.SW", n: "Invesco US High Yield Fallen Angels UCITS ETF (EUR Hdg) EUR - IE00BYVTMZ20 ", c: "" },
  { id: 33037, s: "FAEU.XETRA", n: "Invesco US High Yield Fallen Angels UCITS ETF (EUR Hdg) - IE00BYVTMZ20 ", c: "" },
  { id: 33038, s: "FAGB.LSE", n: "Invesco US High Yield Fallen Angels UCITS ETF (GBP Hdg) - IE00BYVTN047 ", c: "" },
  { id: 33039, s: "FAGB.SW", n: "Invesco US High Yield Fallen Angels UCITS ETF (GBP Hdg) GBP - IE00BYVTN047 ", c: "" },
  { id: 33040, s: "FAS.MX", n: "Direxion Shares ETF Trust - Direxion Daily Financial Bull 3X Shares - US25459Y6941 ", c: "" },
  { id: 33041, s: "FASA.LSE", n: "Invesco Markets II plc - Invesco FTSE All Share ESG Climate UCITS ETF Acc - IE0003RA2ZR3 ", c: "" },
  { id: 33042, s: "FASE.LSE", n: "Invesco Markets II plc - Invesco FTSE All Share ESG Climate UCITS ETF - IE00BN7J5Z03 ", c: "" },
  { id: 33043, s: "FAZ.MX", n: "Direxion Shares ETF Trust - Direxion Daily Financial Bear 3X Shares - US25460E2404 ", c: "" },
  { id: 33044, s: "FCSG.AS", n: "FIRST TRUST GLOBAL FUNDS PLC - First Trust Global Capital Strength ESG Leaders UCITS ETF Class A USD EUR - IE00BKPSPT20 ", c: "" },
  { id: 33045, s: "FDD.MX", n: "First Trust Exchange-Traded Fund II - First Trust STOXX European Select Dividend Index Fund - US33735T1097 ", c: "" },
  { id: 33046, s: "FDL.MX", n: "First Trust Exchange-Traded Fund - First Trust Morningstar Dividend Leaders Index Fund - US3369171091 ", c: "" },
  { id: 33047, s: "FDN.MX", n: "First Trust Exchange-Traded Fund - First Trust Dow Jones Internet Index Fund - US33733E3027 ", c: "" },
  { id: 33048, s: "FEDF.LSE", n: "Multi Units Luxembourg - Lyxor Fed Funds US Dollar Cash UCITS ETF - LU1233598447 ", c: "" },
  { id: 33049, s: "FEMR.LSE", n: "Fidelity Sustainable Research Enhanced Emerging Markets Equity UCITS ETF USD Acc - IE00BLRPN388 ", c: "" },
  { id: 33050, s: "FEP.MX", n: "First Trust Exchange-Traded AlphaDEX Fund II - First Trust Europe AlphaDEX Fund - US33737J1170 ", c: "" },
  { id: 33051, s: "FEQP.LSE", n: "Fidelity Europe Quality Income UCITS ETF GBP Hedged Acc - IE00BYSX4408 ", c: "" },
  { id: 33052, s: "FESD.XETRA", n: "Fidelity Sustainable USD EM Bond UCITS ETF USD Inc - IE00BM9GRP64 ", c: "" },
  { id: 33053, s: "FEZ.MX", n: "SPDR Index Shares Funds - SPDR Euro Stoxx 50 ETF - US78463X2027 ", c: "" },
  { id: 33054, s: "FGEU.XETRA", n: "Fidelity Global Quality Income UCITS ETF EUR Hedged Inc - IE00BYV1YH46 ", c: "" },
  { id: 33055, s: "FGLR.LSE", n: "Fidelity Sustainable Research Enhanced Global Equity UCITS ETF USD Acc - IE00BKSBGV72 ", c: "" },
  { id: 33056, s: "FGQC.SW", n: "Fidelity Global Quality Income UCITS ETF CHF Hedged Inc - IE00BMG8GR03 ", c: "" },
  { id: 33057, s: "FGQP.LSE", n: "Fidelity Global Quality Income UCITS ETF GBP Hedged Inc - IE00BYV1YF22 ", c: "" },
  { id: 33058, s: "FLEQ.SW", n: "Franklin European Equity UCITS ETF EUR - IE00BFWXDW46 ", c: "" },
  { id: 33059, s: "FLOA.LSE", n: "iShares $ Floating Rate Bond UCITS Acc USD - IE00BDFGJ627 ", c: "" },
  { id: 33060, s: "FLOS.LSE", n: "iShares $ Floating Rate Bond UCITS ETF - IE00BF7TPM11 ", c: "" },
  { id: 33061, s: "FLWG.LSE", n: "Rize UCITS ICAV - Rize Medical Cannabis & Life Sci ETF - IE00BJXRZ273 ", c: "" },
  { id: 33062, s: "FLWR.LSE", n: "Rize UCITS ICAV - Rize Medical Cannabis & Life Sci ETF - IE00BJXRZ273 ", c: "" },
  { id: 33063, s: "FLWR.SW", n: "Rize UCITS ICAV - Rize Medical Cannabis & Life Sci ETF CHF - IE00BJXRZ273 ", c: "" },
  { id: 33064, s: "FLXT.LSE", n: "Franklin FTSE Taiwan UCITS ETF - IE000CM02H85 ", c: "" },
  { id: 33065, s: "FLXU.XETRA", n: "Franklin LibertyShares ICAV - Franklin LibertyQ US Equity UCITS ETF - IE00BF2B0P08 ", c: "" },
  { id: 33066, s: "FPXR.LSE", n: "Fidelity Sustainable Research Enhanced Pacific ex-Japan Equity UCITS ETF USD Acc - IE00BNGFMY78 ", c: "" },
  { id: 33067, s: "FPXU.PA", n: "First Trust US Equity Opportunities UCITS ETF Class A Accumulation - IE00BYTH6238 ", c: "" },
  { id: 33068, s: "FRNH.XETRA", n: "Amundi Index Solutions - Amundi Floating Rate USD Corporate ESG - UCITS ETF DR - HEDGED EUR (C) - LU1681041031 ", c: "" },
  { id: 33069, s: "FRNU.XETRA", n: "Amundi Index Solutions - Amundi Floating Rate USD Corporate ESG - UCITS ETF DR - USD (C) - LU1681040900 ", c: "" },
  { id: 33070, s: "FSCM.XETRA", n: "Fidelity Sustainable Global Corporate Bond Multifactor UCITS ETF USD Inc - IE00BM9GRM34 ", c: "" },
  { id: 33071, s: "FSEM.SW", n: "Fidelity Sustainable USD EM Bond UCITS ETF USD Inc USD - IE00BM9GRP64 ", c: "" },
  { id: 33072, s: "FSMF.SW", n: "Fidelity Sustainable Global Corporate Bond Paris-Aligned Multifactor UCITS ETF USD Inc USD - IE00BM9GRM34 ", c: "" },
  { id: 33073, s: "FTEK.LSE", n: "Invesco KBW NASDAQ Fintech UCITS ETF - IE00BYMS5W68 ", c: "" },
  { id: 33074, s: "FTFX.LSE", n: "FTSE Fledgling ex Investment Trusts - IE00BD5HBQ97 ", c: "" },
  { id: 33075, s: "FTGS.XETRA", n: "FIRST TRUST GLOBAL FUNDS PLC - First Trust Global Capital Strength ESG Leaders UCITS ETF - IE00BKPSPT20 ", c: "" },
  { id: 33076, s: "FUSP.LSE", n: "Fidelity US Quality Income UCITS ETF GBP Hedged Acc - IE00BYV03C98 ", c: "" },
  { id: 33077, s: "FUSU.XETRA", n: "Fidelity US Quality Income UCITS ETF EUR Hedged Acc - IE00BYV1Y969 ", c: "" },
  { id: 33078, s: "FVD.MX", n: "First Trust Exchange-Traded Fund - First Trust Value Line Dividend Index Fund - US33734H1068 ", c: "" },
  { id: 33079, s: "FXGB.LSE", n: "First Trust Global Funds Public Limited Company - First Trust FactorFX UCITS ETF - IE00BD5HBR05 ", c: "" },
  { id: 33080, s: "FXH.MX", n: "First Trust Exchange-Traded AlphaDEX Fund - First Trust Health Care AlphaDEX Fund - US33734X1431 ", c: "" },
  { id: 33081, s: "FXI.MX", n: "iShares Trust - iShares China Large-Cap ETF - US4642871846 ", c: "" },
  { id: 33082, s: "G1CD.XETRA", n: "Invesco Markets II plc - Invesco Global Clean Energy UCITS ETF Inc - IE00BLRB0028 ", c: "" },
  { id: 33083, s: "GBND.LSE", n: "L&G ESG Green Bond UCITS ETF EUR EUR - IE00BMYDMD58 ", c: "" },
  { id: 33084, s: "GBP5.LSE", n: "L&G ESG GBP Corporate Bond 0-5 Year UCITS ETF - IE00BLRPQN90 ", c: "" },
  { id: 33085, s: "GBPC.LSE", n: "L&G ESG GBP Corporate Bond UCITS ETF - IE00BLRPQM83 ", c: "" },
  { id: 33086, s: "GBS.LSE", n: "Gold Bullion Securities Limited - Gold Bullion Securities - GB00B00FHZ82 ", c: "" },
  { id: 33087, s: "GBS.PA", n: "Gold Bullion Securities ETC - GB00B00FHZ82 ", c: "" },
  { id: 33088, s: "GCED.LSE", n: "Invesco Markets II plc - Invesco Global Clean Energy UCITS ETF Inc - IE00BLRB0028 ", c: "" },
  { id: 33089, s: "GCLE.LSE", n: "Invesco Markets II plc - Invesco Global Clean Energy UCITS ETF Acc - IE00BLRB0242 ", c: "" },
  { id: 33090, s: "GDXJ.MX", n: "VanEck Vectors ETF Trust - VanEck Vectors Junior Gold Miners ETF - US92189F7915 ", c: "" },
  { id: 33091, s: "GEDV.AS", n: "SPDR® S&P® Global Dividend Aristocrats ESG UCITS ETF EUR - IE00BYTH5S21 ", c: "" },
  { id: 33092, s: "GEDV.LSE", n: "SPDR® S&P® Global Dividend Aristocrats ESG UCITS ETF - IE00BYTH5S21 ", c: "" },
  { id: 33093, s: "GENDEG.SW", n: "UBS (Irl) ETF plc - Global Gender Equality UCITS ETF Hedged GBP A Acc - IE00BDR5H297 ", c: "" },
  { id: 33094, s: "GENDET.SW", n: "UBS (Irl) ETF plc - Global Gender Equality UCITS ETF Hedged CHF A Dis - IE00BDR5H305 ", c: "" },
  { id: 33095, s: "GENG.LSE", n: "UBS (Irl) ETF plc - Global Gender Equality UCITS ETF - IE00BDR5H297 ", c: "" },
  { id: 33096, s: "GGRW.SW", n: "WisdomTree Global Quality Dividend Growth UCITS ETF - USD USD - IE00BZ56RN96 ", c: "" },
  { id: 33097, s: "GLAB.LSE", n: "SPDR® Bloomberg Global Aggregate Bond UCITS ETF GBP Hedged - IE00BF1QPJ56 ", c: "" },
  { id: 33098, s: "GLOW.LSE", n: "SPDR STOXX Global Low Volatility UCITS ETF USD UnHgd C - IE00BKDVQ787 ", c: "" },
  { id: 33099, s: "GMF.MX", n: "SPDR Index Shares Funds - SPDR S&P Emerging Asia Pacific ETF - US78463X3017 ", c: "" },
  { id: 33100, s: "GNAR.XETRA", n: "AMUNDI MSCI USA SRI Climate Net Zero Ambition PAB UCITS ETF Acc - IE000R85HL30 ", c: "" },
  { id: 33101, s: "GOVP.LSE", n: "iShares $ Treasury Bond UCITS ETF GBP Hedged (Dist) - IE00BKTN1T54 ", c: "" },
  { id: 33102, s: "GRDU.LSE", n: "First Trust Nasdaq Clean Edge Smart Grid Infrastructure UCITS ETF A USD Acc - IE000J80JTL1 ", c: "" },
  { id: 33103, s: "GRON.SW", n: "iShares € Green Bond UCITS ETF EUR Inc EUR - IE00BMDBMN04 ", c: "" },
  { id: 33104, s: "GRON.XETRA", n: "iShares € Green Bond UCITS ETF EUR Inc - IE00BMDBMN04 ", c: "" },
  { id: 33105, s: "GSCE.SW", n: "BNP Paribas Easy Energy & Metals Enhanced Roll UCITS ETF EUR Cap EUR - LU1291109616 ", c: "" },
  { id: 33106, s: "GSCU.PA", n: "BNP Paribas Easy Energy & Metals Enhanced Roll UCITS ETF USD Cap - LU1291109533 ", c: "" },
  { id: 33107, s: "GSCU.SW", n: "BNP Paribas Easy Energy & Metals Enhanced Roll UCITS ETF USD Cap USD - LU1291109533 ", c: "" },
  { id: 33108, s: "GSDE.F", n: "BNP Paribas Easy Energy & Metals Enhanced Roll - LU1291109616 ", c: "" },
  { id: 33109, s: "GSDE.XETRA", n: "BNP Paribas Easy Energy & Metals Enhanced Roll UCITS ETF EUR Cap - LU1291109616 ", c: "" },
  { id: 33110, s: "GSG.MX", n: "iShares S&P GSCI Commodity-Indexed Trust - US46428R1077 ", c: "" },
  { id: 33111, s: "GSLC.LSE", n: "Goldman Sachs ActiveBeta US Large Cap Equity UCITS ETF USD Acc - IE00BJ5CNR11 ", c: "" },
  { id: 33112, s: "GSPX.AS", n: "iShares VII PLC - iShares Core S&P 500 ETF GBPH Dist GBP - IE00BD8KRH84 ", c: "" },
  { id: 33113, s: "GSPX.LSE", n: "iShares VII PLC - iShares Core S&P 500 UCITS ETF - IE00BD8KRH84 ", c: "" },
  { id: 33114, s: "H1D5.XETRA", n: "Amundi Index Solutions - Amundi S&P 500 UCITS ETF C Hedged EUR - LU1681049109 ", c: "" },
  { id: 33115, s: "H41A.XETRA", n: "HSBC World ESG Biodiversity Screened Equity UCITS ETF USD EUR - IE0002UTLE51 ", c: "" },
  { id: 33116, s: "H4ZL.XETRA", n: "HSBC FTSE EPRA NAREIT Developed UCITS ETF - IE00B5L01S80 ", c: "" },
  { id: 33117, s: "H4ZS.XETRA", n: "HSBC MSCI Mexico Capped UCITS ETF - IE00B3QMYK80 ", c: "" },
  { id: 33118, s: "H4ZX.XETRA", n: "HSBC Hang Seng Tech UCITS ETF - IE00BMWXKN31 ", c: "" },
  { id: 33119, s: "HACK.MX", n: "ETF Series Solutions - ISE Cyber Security ETF - US26924G2012 ", c: "" },
  { id: 33120, s: "HDCH.SW", n: "Invesco S&P 500 High Dividend Low Volatility UCITS ETF (CHF Hdg) - IE00BYVTMX06 ", c: "" },
  { id: 33121, s: "HDGB.LSE", n: "VanEck Hydrogen Economy UCITS ETF A USD - IE00BMDH1538 ", c: "" },
  { id: 33122, s: "HDR0.XETRA", n: "VanEck Hydrogen Economy UCITS ETF A USD - IE00BMDH1538 ", c: "" },
  { id: 33123, s: "HDRO.LSE", n: "VanEck Hydrogen Economy UCITS ETF A USD - IE00BMDH1538 ", c: "" },
  { id: 33124, s: "HDRO.SW", n: "VanEck Hydrogen Economy UCITS ETF A USD CHF - IE00BMDH1538 ", c: "" },
  { id: 33125, s: "HDV.MX", n: "iShares Trust - iShares Core High Dividend ETF - US46429B6636 ", c: "" },
  { id: 33126, s: "HEDD.SW", n: "WisdomTree Europe Equity UCITS ETF - CHF Hedged Acc - IE00BYQCZT11 ", c: "" },
  { id: 33127, s: "HEDJ.LSE", n: "WisdomTree Europe Equity UCITS ETF - USD Hedged - IE00BVXBH163 ", c: "" },
  { id: 33128, s: "HEDJ.SW", n: "WisdomTree Europe Equity UCITS ETF - USD Hedged USD - IE00BVXBH163 ", c: "" },
  { id: 33129, s: "HEDK.LSE", n: "WisdomTree Europe Equity UCITS ETF - USD Hedged Acc - IE00BYQCZP72 ", c: "" },
  { id: 33130, s: "HEDP.LSE", n: "WisdomTree Europe Equity UCITS ETF - GBP Hedged - IE00BYQCZQ89 ", c: "" },
  { id: 33131, s: "HEDP.SW", n: "WisdomTree Europe Equity UCITS ETF - GBP Hedged GBP - IE00BYQCZQ89 ", c: "" },
  { id: 33132, s: "HEMA.LSE", n: "HSBC MSCI Emerging Markets UCITS ETF USD Acc - IE000KCS7J59 ", c: "" },
  { id: 33133, s: "HERU.LSE", n: "Global X Video Games & Esports ETF A - IE00BLR6Q544 ", c: "" },
  { id: 33134, s: "HERU.SW", n: "Global X Video Games & Esports ETF A CHF - IE00BLR6Q544 ", c: "" },
  { id: 33135, s: "HEWJ.MX", n: "iShares Trust - iShares Currency Hedged MSCI Japan ETF - US46434V8862 ", c: "" },
  { id: 33136, s: "HEZU.MX", n: "iShares Trust - iShares Currency Hedged MSCI Eurozone ETF - US46434V6395 ", c: "" },
  { id: 33137, s: "HIHC.SW", n: "iShares € High Yield Corp Bond UCITS ETF CHF Hedged (Acc) - IE00BK6NC514 ", c: "" },
  { id: 33138, s: "HJAP.XETRA", n: "HSBC MSCI Japan Climate Paris Aligned UCITS ETF - IE000UU299V4 ", c: "" },
  { id: 33139, s: "HLQD.LSE", n: "iShares $ Corp Bond Interest Rate Hedged UCITS ETF USD (Acc) - IE00BZ173W74 ", c: "" },
  { id: 33140, s: "HMCD.LSE", n: "HSBC MSCI China UCITS ETF - IE00B44T3H88 ", c: "" },
  { id: 33141, s: "HMED.LSE", n: "HSBC ETFs Public Limited Company - HSBC MSCI Mexico Capped UCITS ETF - IE00B3QMYK80 ", c: "" },
  { id: 33142, s: "HMEX.LSE", n: "HSBC MSCI Mexico Capped UCITS ETF - IE00B3QMYK80 ", c: "" },
  { id: 33143, s: "HMEX.SW", n: "HSBC MSCI Mexico Capped UCITS ETF USD - IE00B3QMYK80 ", c: "" },
  { id: 33144, s: "HMXJ.LSE", n: "HSBC ETFs Public Limited Company - HSBC MSCI Pacific ex Japan UCITS ETF - IE00B5SG8Z57 ", c: "" },
  { id: 33145, s: "HPAE.LSE", n: "HSBC MSCI EUROPE CLIMATE PARIS ALIGNED UCITS ETF GBP - IE00BP2C0316 ", c: "" },
  { id: 33146, s: "HPAE.XETRA", n: "HSBC MSCI EUROPE CLIMATE PARIS ALIGNED UCITS ETF - IE00BP2C0316 ", c: "" },
  { id: 33147, s: "HPAJ.LSE", n: "HSBC MSCI AC Asia Pacific Ex Japan Climate Paris Aligned UCITS ETF USD - IE000XFORJ80 ", c: "" },
  { id: 33148, s: "HPAO.LSE", n: "HSBC MSCI WORLD CLIMATE PARIS ALIGNED UCITS ETF GBP - IE00BP2C1V62 ", c: "" },
  { id: 33149, s: "HPAS.LSE", n: "HSBC MSCI USA CLIMATE PARIS ALIGNED UCITS ETF GBP - IE00BP2C1S34 ", c: "" },
  { id: 33150, s: "HPAU.LSE", n: "HSBC MSCI USA CLIMATE PARIS ALIGNED UCITS ETF - IE00BP2C1S34 ", c: "" },
  { id: 33151, s: "HPAU.XETRA", n: "HSBC MSCI USA CLIMATE PARIS ALIGNED UCITS ETF - IE00BP2C1S34 ", c: "" },
  { id: 33152, s: "HPAW.LSE", n: "HSBC MSCI WORLD CLIMATE PARIS ALIGNED UCITS ETF - IE00BP2C1V62 ", c: "" },
  { id: 33153, s: "HPAW.XETRA", n: "HSBC MSCI WORLD CLIMATE PARIS ALIGNED UCITS ETF - IE00BP2C1V62 ", c: "" },
  { id: 33154, s: "HSTC.LSE", n: "HSBC Hang Seng Tech UCITS ETF - IE00BMWXKN31 ", c: "" },
  { id: 33155, s: "HSTE.LSE", n: "HSBC Hang Seng Tech UCITS ETF - IE00BMWXKN31 ", c: "" },
  { id: 33156, s: "HSTE.PA", n: "HSBC ETFS PLC - HSBC Hang Seng Tech UCITS ETF - IE00BMWXKN31 ", c: "" },
  { id: 33157, s: "HSXU.LSE", n: "HSBC Europe Ex UK Sustainable Equity UCITS ETF EUR (Dist) - IE00BKY58625 ", c: "" },
  { id: 33158, s: "HTWO.LSE", n: "L&G Hydrogen Economy UCITS ETF-USD Acc - IE00BMYDM794 ", c: "" },
  { id: 33159, s: "HY3M.XETRA", n: "VanEck Emerging Markets High Yield Bond UCITS ETF USD - IE00BF541080 ", c: "" },
  { id: 33160, s: "HYBB.PA", n: "Amundi Index Solutions - Amundi EURO High Yield Bond ESG UCITS ETF EUR Dist - LU1215415214 ", c: "" },
  { id: 33161, s: "HYG.MX", n: "iShares Trust - iShares iBoxx $ High Yield Corporate Bond ETF - US4642885135 ", c: "" },
  { id: 33162, s: "HYGE.XETRA", n: "Invesco Markets II plc - Invesco USD High Yield Corporate Bond ESG UCITS ETF - IE00BJP5NL42 ", c: "" },
  { id: 33163, s: "HYGN.LSE", n: "Global X Hydrogen UCITS ETF USD Acc - IE0002RPS3K2 ", c: "" },
  { id: 33164, s: "HYGU.LSE", n: "iShares € High Yield Corp Bond UCITS ETF USD Hedged (Acc) - IE00BF3NC260 ", c: "" },
  { id: 33165, s: "HYS.MX", n: "PIMCO ETF Trust - PIMCO 0-5 Year High Yield Corporate Bond Index Exchange-Traded Fund - US72201R7834 ", c: "" },
  { id: 33166, s: "IAI.MX", n: "iShares Trust - iShares U.S. Broker-Dealers & Securities Exchanges ETF - US4642887941 ", c: "" },
  { id: 33167, s: "IAT.MX", n: "iShares U.S. Regional Banks ETF - US4642887784 ", c: "" },
  { id: 33168, s: "IAT1.SW", n: "Invesco AT1 Capital Bond ETF USD - IE00BFZPF322 ", c: "" },
  { id: 33169, s: "IAU.MX", n: "iShares Gold Trust - US4642851053 ", c: "" },
  { id: 33170, s: "IBB.MX", n: "iShares Trust - iShares Biotechnology ETF - US4642875565 ", c: "" },
  { id: 33171, s: "IBB1.XETRA", n: "iShares $ Treasury Bond 7-10yr UCITS ETF EUR Hedged (Dist) - IE00BGPP6697 ", c: "" },
  { id: 33172, s: "IBC2.XETRA", n: "iShares II Public Limited Company - iShares $ High Yield Corporate Bond UCITS ETF - IE00BF3N7102 ", c: "" },
  { id: 33173, s: "IBCG.XETRA", n: "iShares MSCI Japan EUR Hedged UCITS - IE00B42Z5J44 ", c: "" },
  { id: 33174, s: "IBCH.XETRA", n: "iShares MSCI World EUR Hedged UCITS - IE00B441G979 ", c: "" },
  { id: 33175, s: "IBCJ.XETRA", n: "iShares MSCI Poland UCITS - IE00B4M7GH52 ", c: "" },
  { id: 33176, s: "IBGM.LSE", n: "iShareso Government Bond 7-10 UCITS - IE00B1FZS806 ", c: "" },
  { id: 33177, s: "IBTC.SW", n: "iShares $ Treasury Bond 1-3yr UCITS ETF CHF Hedged (Acc) - IE00BK7XZ629 ", c: "" },
  { id: 33178, s: "IBTE.LSE", n: "iShares $ Treasury Bond 1-3yr UCITS ETF EUR Hedged (Acc) - IE00BDFK1573 ", c: "" },
  { id: 33179, s: "IBTG.LSE", n: "iShares $ Treasury Bond 1-3yr UCITS ETF GBP Hedged (Dist) - IE00BDFK1N50 ", c: "" },
  { id: 33180, s: "ICNT.XETRA", n: "Invesco MSCI China Technology All Shares Stock Connect UCITS ETF EUR - IE00BM8QS095 ", c: "" },
  { id: 33181, s: "ICOM.LSE", n: "iShares Diversified Commodity Swap UCITS ETF - IE00BDFL4P12 ", c: "" },
  { id: 33182, s: "ICW5.XETRA", n: "ICBC Credit Suisse WisdomTree S&P China 500 UCITS ETF Class B USD Inc - LU1440654330 ", c: "" },
  { id: 33183, s: "IDBB.SW", n: "iShares $ Development Bank Bonds UCITS ETF CHF Hedged (Acc) - IE00BKT1CR43 ", c: "" },
  { id: 33184, s: "IDJG.AS", n: "iShares EURO Total Market Growth Large UCITS - IE00B0M62V02 ", c: "" },
  { id: 33185, s: "IDSE.AS", n: "iShares MSCI Europe SRI UCITS ETF USD (Acc) - IE00BMDBMG37 ", c: "" },
  { id: 33186, s: "IDTC.SW", n: "iShares $ Treasury Bond 7-10yr UCITS ETF CHF Hedged (Acc) - IE00BK7Y2R57 ", c: "" },
  { id: 33187, s: "IDTG.LSE", n: "iShares IV Public Limited Company - iShares $ Treasury Bond 20+yr UCITS ETF - IE00BK5MT033 ", c: "" },
  { id: 33188, s: "IDTL.SW", n: "iShares $ Treasury Bond 20+yr UCITS ETF USD (Dist) USD - IE00BSKRJZ44 ", c: "" },
  { id: 33189, s: "IEAC.SW", n: "iShares Core € Corp Bond UCITS ETF EUR (Dist) - IE00B3F81R35 ", c: "" },
  { id: 33190, s: "IEAH.LSE", n: "iShares Core € Corp Bond UCITS ETF - IE00BDFJYN35 ", c: "" },
  { id: 33191, s: "IECH.SW", n: "iShares Core € Corp Bond UCITS ETF CHF Hedged (Acc) - IE00BK6NC407 ", c: "" },
  { id: 33192, s: "IEF.MX", n: "iShares Trust - iShares 7-10 Year Treasury Bond ETF - US4642874402 ", c: "" },
  { id: 33193, s: "IEFA.MX", n: "iShares Trust - iShares Core MSCI EAFE ETF - US46432F8427 ", c: "" },
  { id: 33194, s: "IEFS.LSE", n: "iShares Edge MSCIope Size Factor UCITS - IE00BQN1KC32 ", c: "" },
  { id: 33195, s: "IEGE.AS", n: "iShares III Public Limited Company - iShares Euro Government Bond 0-1yr UCITS ETF - IE00B3FH7618 ", c: "" },
  { id: 33196, s: "IEMG.MX", n: "iShares Inc. - iShares Core MSCI Emerging Markets ETF - US46434G1031 ", c: "" },
  { id: 33197, s: "IEO.MX", n: "iShares Trust - iShares U.S. Oil & Gas Exploration & Production ETF - US4642888519 ", c: "" },
  { id: 33198, s: "IEV.MX", n: "iShares Trust - iShares Europe ETF - US4642878619 ", c: "" },
  { id: 33199, s: "IFRB.LSE", n: "iShares V Public Limited Company - iShares France Government Bond UCITS ETF - IE00B7LGZ558 ", c: "" },
  { id: 33200, s: "IGAE.AS", n: "iShares J.P. Morgan $ EM Investment Grade Bond UCITS ETF - IE00BKPT2T41 ", c: "" },
  { id: 33201, s: "IGDA.LSE", n: "Invesco Markets II plc - Invesco Dow Jones Islamic Global Developed Markets UCITS ETF USD Acc - IE000UOXRAM8 ", c: "" },
  { id: 33202, s: "IGF.MX", n: "iShares Trust - iShares Global Infrastructure ETF - US4642883726 ", c: "" },
  { id: 33203, s: "IGLC.SW", n: "iShares Global Govt Bond UCITS ETF CHF Hedged (Acc) - IE00BMDBMM96 ", c: "" },
  { id: 33204, s: "IGSD.LSE", n: "iShares $ Short Duration Corp Bond UCITS ETF USD (Dist) - IE00BCRY5Y77 ", c: "" },
  { id: 33205, s: "IGTM.LSE", n: "iShares $ Treasury Bond 7-10yr UCITS ETF USD - IE00BGPP6580 ", c: "" },
  { id: 33206, s: "IGUS.LSE", n: "iShares V Public Limited Company - iShares S&P 500 GBP Hedged UCITS ETF - IE00B3Y8X563 ", c: "" },
  { id: 33207, s: "IGWD.LSE", n: "iShares MSCI World GBP Hedged UCITS - IE00B42YS929 ", c: "" },
  { id: 33208, s: "IHF.MX", n: "iShares Trust - iShares U.S. Healthcare Providers ETF - US4642888287 ", c: "" },
  { id: 33209, s: "IHHG.LSE", n: "iShares $ High Yield Corp Bond UCITS ETF GBP Hedged (Dist) - IE00BDFJYL11 ", c: "" },
  { id: 33210, s: "IHI.MX", n: "iShares Trust - iShares U.S. Medical Devices ETF - US4642888105 ", c: "" },
  { id: 33211, s: "IHYC.SW", n: "iShares $ High Yield Corp Bond UCITS ETF CHF Hedged (Acc) - IE00BK6M1392 ", c: "" },
  { id: 33212, s: "IHYE.LSE", n: "iShares $ High Yield Corp Bond UCITS ETF EUR Hedged (Dist) - IE00BF3N7102 ", c: "" },
  { id: 33213, s: "IJH.MX", n: "iShares Trust - iShares Core S&P Mid-Cap ETF - US4642875078 ", c: "" },
  { id: 33214, s: "IJPC.SW", n: "iShares MSCI Japan CHF Hedged UCITS ETF (Acc) - IE00B8J37J31 ", c: "" },
  { id: 33215, s: "IJPD.LSE", n: "iShares Public Limited Company - iShares MSCI Japan USD Hedged UCITS ETF (Acc) - IE00BCLWRG39 ", c: "" },
  { id: 33216, s: "IJPE.AS", n: "iShares MSCI Japan EUR Hedged UCITS - IE00B42Z5J44 ", c: "" },
  { id: 33217, s: "IJPE.LSE", n: "iShares MSCI Japan EUR Hedged UCITS - IE00B42Z5J44 ", c: "" },
  { id: 33218, s: "IJPH.LSE", n: "iShares MSCI Japan GBP Hedged UCITS - IE00B7XYN974 ", c: "" },
  { id: 33219, s: "IJR.MX", n: "iShares Trust - iShares Core S&P Small-Cap ETF - US4642878049 ", c: "" },
  { id: 33220, s: "IKSA.LSE", n: "iShares MSCI Saudi Arabia Capped UCITS ETF USD (Acc) - IE00BYYR0489 ", c: "" },
  { id: 33221, s: "IKSD.LSE", n: "iShares MSCI Saudi Arabia Capped UCITS ETF USD (Dist) - IE00BJ5JPJ87 ", c: "" },
  { id: 33222, s: "ILF.MX", n: "iShares Trust - iShares Latin America 40 ETF - US4642873909 ", c: "" },
  { id: 33223, s: "IMBE.AS", n: "iShares US Mortgage Backed Securities UCITS ETF EUR Hedged (Acc) - IE00BKP5L409 ", c: "" },
  { id: 33224, s: "INDA.MX", n: "iShares Trust - iShares MSCI India ETF - US46429B5984 ", c: "" },
  { id: 33225, s: "INDY.MX", n: "iShares Trust - iShares India 50 ETF - US4642895290 ", c: "" },
  { id: 33226, s: "INFL.LSE", n: "Lyxor EUR 2-10 Inflation Expectations UCITS C-EUR - LU1390062245 ", c: "" },
  { id: 33227, s: "INFL.PA", n: "Lyxor EUR 2-10 Inflation Expectations UCITS C-EUR - LU1390062245 ", c: "" },
  { id: 33228, s: "INFU.PA", n: "Lyxor US$ 10Y Inflation Expectations UCITS ETF - C-USD EUR - LU1390062831 ", c: "" },
  { id: 33229, s: "INRA.AS", n: "iShares Global Clean Energy UCITS ETF USD (Acc) - IE000U58J0M1 ", c: "" },
  { id: 33230, s: "IPRA.AS", n: "iShares Listed Private Equity UCITS ETF USD (Acc) - IE000D8FCSD8 ", c: "" },
  { id: 33231, s: "IQQ6.XETRA", n: "iShares Developed Markets Property Yield UCITS - IE00B1FZS350 ", c: "" },
  { id: 33232, s: "IQSA.LSE", n: "Invesco Quantitative Strategies ESG Global Equity Multi-Factor UCITS ETF USD Acc - IE00BJQRDN15 ", c: "" },
  { id: 33233, s: "IQSE.XETRA", n: "Invesco Quantitative Strategies ESG Global Equity Multi-Factor UCITS ETF EUR PfHedged Acc - IE00BJQRDP39 ", c: "" },
  { id: 33234, s: "IRCP.AS", n: "iShares Euro Corporate Bond Interest Rate Hedged UCITS - IE00B6X2VY59 ", c: "" },
  { id: 33235, s: "IRCP.LSE", n: "iShares Euro Corporate Bond Interest Rate Hedged UCITS - IE00B6X2VY59 ", c: "" },
  { id: 33236, s: "IRCP.SW", n: "iShares € Corp Bond Interest Rate Hedged ESG UCITS ETF EUR (Dist) CHF - IE00B6X2VY59 ", c: "" },
  { id: 33237, s: "IS07.XETRA", n: "iShares Edge MSCI World Multifactor UCITS Hedged Acc - IE00BYXPXK00 ", c: "" },
  { id: 33238, s: "IS0Y.XETRA", n: "iShares Euro Corporate Bond Interest Rate Hedged UCITS - IE00B6X2VY59 ", c: "" },
  { id: 33239, s: "IS3F.XETRA", n: "iShares $ Corp Bond Interest Rate Hedged UCITS ETF USD (Dist) - IE00BCLWRB83 ", c: "" },
  { id: 33240, s: "IS3R.XETRA", n: "iShares MSCI World Momentum Factor UCITS - IE00BP3QZ825 ", c: "" },
  { id: 33241, s: "ISDE.LSE", n: "iShares MSCI Emerging Markets Islamic UCITS - IE00B27YCP72 ", c: "" },
  { id: 33242, s: "ISDU.LSE", n: "iShares MSCI USA Islamic UCITS - IE00B296QM64 ", c: "" },
  { id: 33243, s: "ISFD.LSE", n: "iShares Core FTSE 100 UCITS USD Hedged Acc - IE00BYZ28W67 ", c: "" },
  { id: 33244, s: "ISUN.LSE", n: "Invesco Solar Energy UCITS ETF - IE00BM8QRZ79 ", c: "" },
  { id: 33245, s: "ISUS.LSE", n: "iShares MSCI USA Islamic UCITS - IE00B296QM64 ", c: "" },
  { id: 33246, s: "ITA.MX", n: "iShares Trust - iShares U.S. Aerospace & Defense ETF - US4642887602 ", c: "" },
  { id: 33247, s: "ITB.MX", n: "iShares Trust - iShares U.S. Home Construction ETF - US4642887529 ", c: "" },
  { id: 33248, s: "ITEH.LSE", n: "iShares Italy Govt Bond UCITS ETF USD Hedged (Acc) - IE00BFMM9235 ", c: "" },
  { id: 33249, s: "ITOT.MX", n: "iShares Core S&P Total U.S. Stock Market ETF - US4642871507 ", c: "" },
  { id: 33250, s: "ITPG.LSE", n: "iShares II Public Limited Company - iShares $ TIPS UCITS ETF - IE00BDZVH859 ", c: "" },
  { id: 33251, s: "IU0E.XETRA", n: "iShares $ Corp Bond SRI 0-3yr UCITS ETF EUR Hedged (Acc) - IE00BG5QQ390 ", c: "" },
  { id: 33252, s: "IUAE.LSE", n: "iShares US Aggregate Bond UCITS ETF EUR Hedged (Acc) - IE00BDFJYM28 ", c: "" },
  { id: 33253, s: "IUES.AS", n: "iShares V Public Limited Company - iShares S&P 500 EUR Hedged UCITS ETF - IE00B3ZW0K18 ", c: "" },
  { id: 33254, s: "IUGA.LSE", n: "iShares US Aggregate Bond UCITS ETF GBP Hedged (Dist) - IE00BDFGJ841 ", c: "" },
  { id: 33255, s: "IUHE.AS", n: "iShares S&P 500 Health Care Sector UCITS ETF EUR Hedged (Dist) - IE00BMBKBZ46 ", c: "" },
  { id: 33256, s: "IUKD.SW", n: "iShares UK Dividend UCITS ETF GBP (Dist) CHF - IE00B0M63060 ", c: "" },
  { id: 33257, s: "IUKP.LSE", n: "iShares UK Property UCITS - IE00B1TXLS18 ", c: "" },
  { id: 33258, s: "IUKP.SW", n: "iShares UK Property UCITS ETF GBP (Dist) CHF - IE00B1TXLS18 ", c: "" },
  { id: 33259, s: "IUME.AS", n: "iShares MSCI USA Momentum Factor ESG UCITS ETF USD Acc - IE0002PA3YE5 ", c: "" },
  { id: 33260, s: "IUSC.SW", n: "iShares S&P 500 CHF Hedged UCITS ETF (Acc) - IE00B88DZ566 ", c: "" },
  { id: 33261, s: "IUSE.LSE", n: "iShares V Public Limited Company - iShares S&P 500 EUR Hedged UCITS ETF - IE00B3ZW0K18 ", c: "" },
  { id: 33262, s: "IUSP.LSE", n: "iShares US Property Yield UCITS - IE00B1FZSF77 ", c: "" },
  { id: 33263, s: "IUSV.XETRA", n: "iShares $ Treasury Bond 20+yr UCITS ETF EUR Hedged (Dist) EUR - IE00BD8PGZ49 ", c: "" },
  { id: 33264, s: "IUSW.XETRA", n: "iShares MSCI Saudi Arabia Capped UCITS ETF USD (Dist) - IE00BJ5JPJ87 ", c: "" },
  { id: 33265, s: "IUVE.AS", n: "iShares MSCI USA Value Factor ESG UCITS ETF - IE000OKVTDF7 ", c: "" },
  { id: 33266, s: "IVE.MX", n: "iShares Trust - iShares S&P 500 Value ETF - US4642874089 ", c: "" },
  { id: 33267, s: "IVV.MX", n: "iShares Trust - iShares Core S&P 500 ETF - US4642872000 ", c: "" },
  { id: 33268, s: "IVW.MX", n: "iShares Trust - iShares S&P 500 Growth ETF - US4642873099 ", c: "" },
  { id: 33269, s: "IWDC.SW", n: "iShares MSCI World CHF Hedged UCITS ETF (Acc) - IE00B8BVCK12 ", c: "" },
  { id: 33270, s: "IWDE.AS", n: "iShares MSCI World EUR Hedged UCITS - IE00B441G979 ", c: "" },
  { id: 33271, s: "IWDE.LSE", n: "iShares MSCI World EUR Hedged UCITS - IE00B441G979 ", c: "" },
  { id: 33272, s: "IWDG.LSE", n: "iShares Core MSCI World UCITS ETF GBP Hedged (Dist) - IE00BD45YS76 ", c: "" },
  { id: 33273, s: "IWFV.LSE", n: "iShares MSCI World Value Factor UCITS - IE00BP3QZB59 ", c: "" },
  { id: 33274, s: "IWLE.XETRA", n: "iShares Core MSCI World UCITS ETF EUR Hedged (Dist) - IE00BKBF6H24 ", c: "" },
  { id: 33275, s: "IWM.MX", n: "iShares Trust - iShares Russell 2000 ETF - US4642876555 ", c: "" },
  { id: 33276, s: "IXC.MX", n: "iShares Trust - iShares Global Energy ETF - US4642873412 ", c: "" },
  { id: 33277, s: "IXJ.MX", n: "iShares Trust - iShares Global Healthcare ETF - US4642873255 ", c: "" },
  { id: 33278, s: "IXN.MX", n: "iShares Trust - iShares Global Tech ETF - US4642872919 ", c: "" },
  { id: 33279, s: "IYC.MX", n: "iShares Trust - iShares U.S. Consumer Services ETF - US4642875805 ", c: "" },
  { id: 33280, s: "IYE.MX", n: "iShares Trust - iShares U.S. Energy ETF - US4642877967 ", c: "" },
  { id: 33281, s: "IYF.MX", n: "iShares Trust - iShares U.S. Financials ETF - US4642877884 ", c: "" },
  { id: 33282, s: "IYG.MX", n: "iShares Trust - iShares U.S. Financial Services ETF - US4642877702 ", c: "" },
  { id: 33283, s: "IYH.MX", n: "iShares Trust - iShares U.S. Healthcare ETF - US4642877629 ", c: "" },
  { id: 33284, s: "IYR.MX", n: "iShares Trust - iShares U.S. Real Estate ETF - US4642877397 ", c: "" },
  { id: 33285, s: "IYT.MX", n: "iShares Trust - iShares U.S. Transportation ETF - US4642871929 ", c: "" },
  { id: 33286, s: "IYW.MX", n: "iShares Trust - iShares U.S. Technology ETF - US4642877215 ", c: "" },
  { id: 33287, s: "J1GR.XETRA", n: "Amundi Index Solutions - Amundi Index MSCI Japan ETF-C EUR - LU1602144732 ", c: "" },
  { id: 33288, s: "JARG.LSE", n: "Amundi Index Solutions - Amundi Index MSCI Japan SRI PAB UCITS ETF DR GBP (D) - LU2469335025 ", c: "" },
  { id: 33289, s: "JARH.XETRA", n: "Amundi Index Solutions - Amundi Index MSCI Japan SRI PAB UCITS ETF DR Hedged (C) - LU2269164310 ", c: "" },
  { id: 33290, s: "JBPACA.SW", n: "Swisscanto ETF Precious Metal - Swisscanto ETF Precious Metal Physical Palladium CHF A - CH0106407239 ", c: "" },
  { id: 33291, s: "JBPAEA.SW", n: "Swisscanto ETF Precious Metal - Swisscanto ETF Precious Metal Physical Palladium EUR A - CH0106407213 ", c: "" },
  { id: 33292, s: "JBPAUA.SW", n: "Swisscanto ETF Precious Metal - Swisscanto ETF Precious Metal Physical Palladium USD A - CH0106407205 ", c: "" },
  { id: 33293, s: "JBSICA.SW", n: "Swisscanto ETF Precious Metal - Swisscanto ETF Precious Metal Physical Silver CHF A - CH0106405894 ", c: "" },
  { id: 33294, s: "JBSICX.SW", n: "Swisscanto ETF Precious Metal - Swisscanto ETF Precious Metal Physical Silver CHF AX - CH0106406199 ", c: "" },
  { id: 33295, s: "JBSIEA.SW", n: "Swisscanto ETF Precious Metal - Swisscanto ETF Precious Metal Physical Silver EUR A - CH0106405860 ", c: "" },
  { id: 33296, s: "JBSIGA.SW", n: "Swisscanto ETF Precious Metal - Swisscanto ETF Precious Metal Physical Silver GBP A - CH0106405928 ", c: "" },
  { id: 33297, s: "JBSIUA.SW", n: "Swisscanto ETF Precious Metal - Swisscanto ETF Precious Metal Physical Silver USD A - CH0106405845 ", c: "" },
  { id: 33298, s: "JBSIUX.SW", n: "Swisscanto ETF Precious Metal - Swisscanto ETF Precious Metal Physical Silver USD AX - CH0106405951 ", c: "" },
  { id: 33299, s: "JEDI.LSE", n: "VanEck Space Innovators UCITS ETF A USD Acc - IE000YU9K6K2 ", c: "" },
  { id: 33300, s: "JGEP.LSE", n: "JPMorgan ETFs (Ireland) ICAV - Global Research Enhanced Index Equity UCITS ETF GBP Hedged Acc - IE000D9DX847 ", c: "" },
  { id: 33301, s: "JMBC.SW", n: "JPMorgan ETFs (Ireland) ICAV - USD Emerging Markets Sovereign Bond UCITS ETF CHF (acc) Hedged - IE00BL2F2474 ", c: "" },
  { id: 33302, s: "JNHD.XETRA", n: "MULTI-UNITS LUXEMBOURG - Lyxor Core MSCI Japan (DR) UCITS ETF - Daily Hedged to EUR - Dist - LU2133056387 ", c: "" },
  { id: 33303, s: "JNK.MX", n: "SPDR Series Trust - SPDR Bloomberg Barclays High Yield Bond ETF - US78468R6229 ", c: "" },
  { id: 33304, s: "JNKE.SW", n: "SPDR® Bloomberg Euro High Yield Bond UCITS ETF CHF - IE00B6YX5M31 ", c: "" },
  { id: 33305, s: "JP4H.XETRA", n: "Amundi JPX-Nikkei 400 EUR Daily Hedged UCITS - LU1681039134 ", c: "" },
  { id: 33306, s: "JPCT.LSE", n: "JPM Carbon Transition Global Equity (CTB) UCITS ETF - USD (acc) - IE00BMDWYZ92 ", c: "" },
  { id: 33307, s: "JPCT.XETRA", n: "JPMorgan ETFs (Ireland) ICAV - Carbon Transition Global Equity UCITS ETF - IE00BMDWYZ92 ", c: "" },
  { id: 33308, s: "JPEH.LSE", n: "SPDR® MSCI Japan EUR Hdg UCITS ETF - IE00BZ0G8C04 ", c: "" },
  { id: 33309, s: "JPESG.SW", n: "UBS (Irl) ETF plc MSCI Japan ESG Universal Low Carbon Select UCITS ETF A - IE00BNC0MD55 ", c: "" },
  { id: 33310, s: "JPEUR.SW", n: "UBS(Lux)Fund Solutions – MSCI Japan UCITS ETF(hedged EUR)A-dis - LU1169822001 ", c: "" },
  { id: 33311, s: "JPGBPD.SW", n: "UBS(Lux)Fund Solutions – MSCI Japan UCITS ETF(hedged GBP)A-dis GBP - LU1169822340 ", c: "" },
  { id: 33312, s: "JPHC.PA", n: "Amundi Index Solutions - Amundi JPX-Nikkei 400 UCITS ETF-C CHF Hedged - LU1681047665 ", c: "" },
  { id: 33313, s: "JPHC.SW", n: "Amundi Index Solutions - Amundi JPX-Nikkei 400 UCITS ETF-C CHF Hedged CHF - LU1681047665 ", c: "" },
  { id: 33314, s: "JPHE.SW", n: "Amundi Index Solutions - Amundi JPX-Nikkei 400 UCITS ETF-C EUR Hedged EUR - LU1681039134 ", c: "" },
  { id: 33315, s: "JPHG.LSE", n: "Amundi JPX-Nikkei 400 GBP Daily Hedged UCITS - LU1681039308 ", c: "" },
  { id: 33316, s: "JPHG.SW", n: "Amundi Index Solutions - Amundi JPX-Nikkei 400 UCITS ETF-C GBP Hedged GBP - LU1681039308 ", c: "" },
  { id: 33317, s: "JPHU.LSE", n: "Amundi JPX-Nikkei 400 USD Daily Hedged UCITS - LU1681039217 ", c: "" },
  { id: 33318, s: "JPHU.SW", n: "Amundi Index Solutions - Amundi JPX-Nikkei 400 UCITS ETF-C USD Hedged USD - LU1681039217 ", c: "" },
  { id: 33319, s: "JPMB.SW", n: "JPMorgan ETFs (Ireland) ICAV - USD Emerging Markets Sovereign Bond UCITS ETF USD (dist) USD - IE00BDFC6G93 ", c: "" },
  { id: 33320, s: "JPNCHF.SW", n: "UBS(Lux)Fund Solutions – MSCI Japan UCITS ETF(hedged CHF)A-acc - LU1169821888 ", c: "" },
  { id: 33321, s: "JPNE.XETRA", n: "Amundi Index Solutions - Amundi Index MSCI Japan SRI PAB UCITS ETF DR HEDGED EUR (C) - LU1646360542 ", c: "" },
  { id: 33322, s: "JPNH.F", n: "Multi Units France - Lyxor Japan (Topix) (DR) UCITS ETF - FR0011475078 ", c: "" },
  { id: 33323, s: "JPNH.PA", n: "Lyxor UCITS Japan Topix Daily Hedged D-EUR - FR0011475078 ", c: "" },
  { id: 33324, s: "JPNH.XETRA", n: "Lyxor UCITS Japan Topix Daily Hedged D-EUR - FR0011475078 ", c: "" },
  { id: 33325, s: "JPPS.XETRA", n: "JPMorgan Ireland ICAV - USD Ultra-Short Income UCITS USD dist - IE00BDFC6Q91 ", c: "" },
  { id: 33326, s: "JPSG.LSE", n: "iShares MSCI Japan SRI UCITS ETF GBP Hedged (Acc) - IE0007VCNJW3 ", c: "" },
  { id: 33327, s: "JPSRE.SW", n: "UBS(Lux)Fund Solutions – MSCI Japan Socially Responsible UCITS ETF(hedged EUR)A-acc - LU1273488715 ", c: "" },
  { id: 33328, s: "JPSRT.SW", n: "UBS(Lux)Fund Solutions – MSCI Japan Socially Responsible UCITS ETF(hedged CHF)A-dis - LU1273488475 ", c: "" },
  { id: 33329, s: "JPSRU.SW", n: "UBS(Lux)Fund Solutions – MSCI Japan Socially Responsible UCITS ETF(hedged USD)A-acc - LU1273489440 ", c: "" },
  { id: 33330, s: "JPUSD.SW", n: "UBS(Lux)Fund Solutions – MSCI Japan UCITS ETF(hedged USD)A-acc - LU1169822779 ", c: "" },
  { id: 33331, s: "JPXU.LSE", n: "Lyxor UCITS JPX-Nikkei 400 Daily USD - LU1646359882 ", c: "" },
  { id: 33332, s: "JPXX.LSE", n: "Lyxor UCITS JPX-Nikkei 400 Daily GBP - LU1646359619 ", c: "" },
  { id: 33333, s: "JRDC.LSE", n: "JPM China A Research Enhanced Index Equity (ESG) UCITS ETF USD Distribution - IE000DS9ZCL4 ", c: "" },
  { id: 33334, s: "JRDG.LSE", n: "JPMorgan ETFs (Ireland) ICAV - Global Research Enhanced Index Equity UCITS ETF - USD (dist) GBX - IE000HFXP0D2 ", c: "" },
  { id: 33335, s: "JRDM.LSE", n: "JPMorgan ETFs (Ireland) ICAV - Global Emerging Markets Research Enh Idx Eq (ESG) UCITS ETF USD Inc GBX - IE000Y4K4833 ", c: "" },
  { id: 33336, s: "JREA.LSE", n: "JPM AC Asia Pacific ex Japan Research Enhanced Index Equity (ESG) UCITS ETF USD Accumulation - IE00BMDV7354 ", c: "" },
  { id: 33337, s: "JREC.LSE", n: "JPM China A Research Enhanced Index Equity (ESG) UCITS ETF USD Accumulation - IE00BMDV7578 ", c: "" },
  { id: 33338, s: "JRED.LSE", n: "JPMorgan ETFs (Ireland) ICAV - Europe Research Enhanced Index Equity UCITS ETF - EUR (dist) EUR - IE000WGK3YY5 ", c: "" },
  { id: 33339, s: "JRED.XETRA", n: "JPMorgan ETFs (Ireland) ICAV - Europe Research Enhanced Index Equity UCITS ETF - EUR (dist) - IE000WGK3YY5 ", c: "" },
  { id: 33340, s: "JREI.LSE", n: "JPMorgan ETFs (Ireland) ICAV - Japan Research Enhanced Index Equity (ESG) UCITS ETF USD (Inc) - IE00005YSIA4 ", c: "" },
  { id: 33341, s: "JREJ.LSE", n: "JPMorgan ETFs (Ireland) ICAV - Japan Research Enhanced Index Equity (ESG) UCITS ETF USD (acc) - IE00BP2NF958 ", c: "" },
  { id: 33342, s: "JREX.LSE", n: "JPM AC Asia Pacific ex Japan Research Enhanced Index Equity (ESG) UCITS ETF USD Distribution - IE000P334X90 ", c: "" },
  { id: 33343, s: "JRGD.LSE", n: "JPMorgan ETFs (Ireland) ICAV - Global Research Enhanced Index Equity UCITS ETF - USD (dist) - IE000HFXP0D2 ", c: "" },
  { id: 33344, s: "JRGD.XETRA", n: "JPMorgan ETFs (Ireland) ICAV - Global Research Enhanced Index Equity UCITS ETF - USD (dist) - IE000HFXP0D2 ", c: "" },
  { id: 33345, s: "JRGE.XETRA", n: "JPMorgan ETFs (Ireland) ICAV - Global Research Enhanced Index Equity UCITS ETF EUR Hedged Acc - IE0000UW95D6 ", c: "" },
  { id: 33346, s: "JRMD.LSE", n: "JPMorgan ETFs (Ireland) ICAV - Global Emerging Markets Research Enh Idx Eq (ESG) UCITS ETF USD Inc - IE000Y4K4833 ", c: "" },
  { id: 33347, s: "JRZD.LSE", n: "JPM Eurozone Research Enhanced Index Equity (ESG) UCITS ETF - EUR (dis) EUR - IE000783LRG9 ", c: "" },
  { id: 33348, s: "JUHC.SW", n: "JPMorgan ETFs (Ireland) ICAV - US Research Enhanced Index Equity UCITS ETF - CHF Hedged (acc) - IE000K54GGV6 ", c: "" },
  { id: 33349, s: "JUKC.LSE", n: "JPMorgan ETFs (Ireland) ICAV - UK Equity Core UCITS ETF GBP Acc - IE0009YQE5W1 ", c: "" },
  { id: 33350, s: "JUKE.LSE", n: "JPMorgan ETFs (Ireland) ICAV - UK Equity Core UCITS ETF GBP Inc - IE000TZT3JJ0 ", c: "" },
  { id: 33351, s: "JUPI.XETRA", n: "Amundi Index Solutions - AUMNDI MSCI JAPAN ESG CLIMATE NET ZERO AMBITION CTB UCITS ETF Dist EUR - LU2300294746 ", c: "" },
  { id: 33352, s: "KARS.LSE", n: "KraneShares Electric Vehicles & Future Mobility ESG Screened UCITS ETF USD - IE000YUAPTQ0 ", c: "" },
  { id: 33353, s: "KRE.MX", n: "SPDR® S&P Regional Banking ETF - US78464A6982 ", c: "" },
  { id: 33354, s: "KROP.LSE", n: "Global X AgTech & Food Innovation UCITS ETF USD Acc - IE000EBFYWX3 ", c: "" },
  { id: 33355, s: "KSTR.LSE", n: "KraneShares ICBCCS SSE Star Market 50 Index UCITS ETF - IE00BKPJY434 ", c: "" },
  { id: 33356, s: "KURE.LSE", n: "KraneShares MSCI All China Health Care Index UCITS ETF USD - IE00BL6K0S82 ", c: "" },
  { id: 33357, s: "L100.PA", n: "Multi Units Luxembourg - Lyxor FTSE 100 UCITS Fund - LU1650492173 ", c: "" },
  { id: 33358, s: "L8I3.F", n: "Lyxor Euro Cash UCITS ETF - FR0010510800 ", c: "" },
  { id: 33359, s: "L8I3.XETRA", n: "Lyxor Euro Cash UCITS ETF - FR0010510800 ", c: "" },
  { id: 33360, s: "LCDE.XETRA", n: "BNP Paribas Easy - Low Carbon 100 Eurozone PAB UCITS ETF EUR Acc - LU2194448267 ", c: "" },
  { id: 33361, s: "LCJG.LSE", n: "Lyxor Core MSCI Japan (DR) UCITS ETF Daily Hedged to GBP - Acc - LU1781541682 ", c: "" },
  { id: 33362, s: "LCJP.LSE", n: "Lyxor Core MSCI Japan (DR) UCITS ETF - LU1781541252 ", c: "" },
  { id: 33363, s: "LCUJ.XETRA", n: "Lyxor Core MSCI Japan (DR) UCITS ETF - LU1781541252 ", c: "" },
  { id: 33364, s: "LDAG.LSE", n: "L&G Quality Equity Dividends ESG Exclusions Asia Pacific ex-Japan UCITS ETF - IE00BMYDMB35 ", c: "" },
  { id: 33365, s: "LDAP.LSE", n: "L&G Quality Equity Dividends ESG Exclusions Asia Pacific ex-Japan UCITS ETF GBP - IE00BMYDMB35 ", c: "" },
  { id: 33366, s: "LDCC.SW", n: "PIMCO Low Duration US Corporate Bond Source Ucits ETF CHF (Hedged) Acc - IE00BWC52F58 ", c: "" },
  { id: 33367, s: "LDCE.XETRA", n: "PIMCO Low Duration Euro Corporate Bond Source UCITS Income - IE00BP9F2J32 ", c: "" },
  { id: 33368, s: "LDEU.LSE", n: "L&G Quality Equity Dividends ESG Exclusions Europe ex-UK UCITS ETF - IE00BMYDM919 ", c: "" },
  { id: 33369, s: "LDUK.LSE", n: "L&G Quality Equity Dividends ESG Exclusions UK UCITS ETF - IE00BMYDM802 ", c: "" },
  { id: 33370, s: "LEMV.LSE", n: "Ossiam Europe ESG Machine Learning ETF UCITS 1C (EUR) - LU0599612842 ", c: "" },
  { id: 33371, s: "LGCF.LSE", n: "Invesco Markets plc - Invesco Commodity Composite UCITS ETF - IE00B4TXPP71 ", c: "" },
  { id: 33372, s: "LGCU.LSE", n: "Invesco Markets plc - Invesco Commodity Composite UCITS ETF - IE00B4TXPP71 ", c: "" },
  { id: 33373, s: "LGGA.XETRA", n: "L&G Quality Equity Dividends ESG Exclusions Asia Pacific ex-Japan UCITS ETF EUR - IE00BMYDMB35 ", c: "" },
  { id: 33374, s: "LGGE.XETRA", n: "L&G Quality Equity Dividends ESG Exclusions Europe ex-UK UCITS ETF EUR - IE00BMYDM919 ", c: "" },
  { id: 33375, s: "LGWT.XETRA", n: "Lyxor Index Fund - Lyxor MSCI EMU Growth (DR) UCITS ETF Dist - LU1598688189 ", c: "" },
  { id: 33376, s: "LHKG.XETRA", n: "Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF - Inc - LU1900067940 ", c: "" },
  { id: 33377, s: "LITU.LSE", n: "Global X Lithium & Battery Tech UCITS ETF USD Acc - IE00BLCHJN13 ", c: "" },
  { id: 33378, s: "LOWD.XETRA", n: "BNP PARIBAS EASY - Low Carbon 300 World PAB UCITS ETF EUR Acc - LU2194449075 ", c: "" },
  { id: 33379, s: "LQCE.SW", n: "iShares $ Corp Bond UCITS ETF CHF Hedged (Acc) - IE00BK7Y2Q41 ", c: "" },
  { id: 33380, s: "LQD.MX", n: "iShares Trust - iShares iBoxx $ Investment Grade Corporate Bond ETF - US4642872422 ", c: "" },
  { id: 33381, s: "LQDG.LSE", n: "iShares $ Corp Bond Interest Rate Hedged UCITS ETF USD (Dist) - IE00BCLWRB83 ", c: "" },
  { id: 33382, s: "LQDH.AS", n: "iShares $ Corp Bond Interest Rate Hedged UCITS ETF USD (Dist) - IE00BCLWRB83 ", c: "" },
  { id: 33383, s: "LQDH.LSE", n: "iShares $ Corp Bond Interest Rate Hedged UCITS ETF USD (Dist) - IE00BCLWRB83 ", c: "" },
  { id: 33384, s: "LQDH.SW", n: "iShares $ Corp Bond Interest Rate Hedged UCITS ETF USD (Dist) CHF - IE00BCLWRB83 ", c: "" },
  { id: 33385, s: "LQEE.LSE", n: "iShares $ Corp Bond UCITS ETF EUR Hedged (Dist) - IE00BF3N6Y61 ", c: "" },
  { id: 33386, s: "LQGH.LSE", n: "iShares $ Corp Bond UCITS ETF - IE00BDFK3736 ", c: "" },
  { id: 33387, s: "LQQ.PA", n: "Lyxor UCITS NASDAQ-100 Daily Leverage - FR0010342592 ", c: "" },
  { id: 33388, s: "LSK7.F", n: "Lyxor UCITS Stoxx 50 Daily Short - FR0010424135 ", c: "" },
  { id: 33389, s: "LSK7.XETRA", n: "Lyxor UCITS Stoxx 50 Daily Short - FR0010424135 ", c: "" },
  { id: 33390, s: "LSK8.F", n: "LYXOR EURO STOXX 50 DAILY (-2X) INVERSE UCITS ETF - FR0010424143 ", c: "" },
  { id: 33391, s: "LSK8.XETRA", n: "LYXOR EURO STOXX 50 DAILY (-2X) INVERSE UCITS ETF - FR0010424143 ", c: "" },
  { id: 33392, s: "LUK2.LSE", n: "L&G FTSE 100 Leveraged (Daily 2x) UCITS ETF - IE00B4QNJJ23 ", c: "" },
  { id: 33393, s: "LVC.PA", n: "Lyxor UCITS Daily Leverage CAC 40 - FR0010592014 ", c: "" },
  { id: 33394, s: "LVD.PA", n: "Multi Units Luxembourg - Lyxor Daily LevDAX UCITS ETF - LU0252634307 ", c: "" },
  { id: 33395, s: "LVE.PA", n: "Lyxor UCITS Stoxx 50 Daily Leverage - FR0010468983 ", c: "" },
  { id: 33396, s: "LVLE.XETRA", n: "Invesco Markets II plc - Invesco Quantitative Strats Glbl Eq Lw Vol Lw Crbn UCITS ETF EUR PfHdg Acc - IE000XIBT2R7 ", c: "" },
  { id: 33397, s: "LVO.AS", n: "Multi Units Luxembourg - Lyxor S&P 500 VIX Futures Enhanced Roll UCITS ETF - LU0832435464 ", c: "" },
  { id: 33398, s: "LYFINW.SW", n: "Lyxor MSCI World Financials TR UCITS ETF - C-USD USD - LU0533033071 ", c: "" },
  { id: 33399, s: "LYHSI.SW", n: "Lyxor MSCI China ESG Leaders Extra (DR) UCITS ETF - Inc USD - LU1900067940 ", c: "" },
  { id: 33400, s: "LYMZ.F", n: "Lyxor UCITS ETF Euro Stoxx 50 Daily Leverage - FR0010468983 ", c: "" },
  { id: 33401, s: "LYMZ.XETRA", n: "Lyxor UCITS ETF Euro Stoxx 50 Daily Leverage - FR0010468983 ", c: "" },
  { id: 33402, s: "LYQK.F", n: "Multi Units France - Lyxor Bund Daily (-2X) Inverse UCITS ETF - FR0010869578 ", c: "" },
  { id: 33403, s: "LYUSA.SW", n: "Amundi MSCI USA ESG Climate Net Zero Ambition CTB UCITS ETF Dis EUR - IE000QQ8Z0D8 ", c: "" },
  { id: 33404, s: "LYUSG.SW", n: "Lyxor Core US TIPS (DR) UCITS ETF - Monthly Hedged D-GBP GBX - LU1452600601 ", c: "" },
  { id: 33405, s: "LYXD.XETRA", n: "Lyxor EuroMTS 7-10 Investment Grade DR UCITS C - LU1287023185 ", c: "" },
  { id: 33406, s: "LYXE.XETRA", n: "Lyxor Index Fund - Lyxor ESG USD High Yield (DR) UCITS ETF Monthly Hedged to EUR - Dist EUR - LU1435356495 ", c: "" },
  { id: 33407, s: "LYY7.XETRA", n: "Multi Units Luxembourg - Lyxor DAX (DR) UCITS ETF - LU0252633754 ", c: "" },
  { id: 33408, s: "LYY8.F", n: "Multi Units Luxembourg - Lyxor Daily LevDAX UCITS ETF - LU0252634307 ", c: "" },
  { id: 33409, s: "LYY8.XETRA", n: "Multi Units Luxembourg - Lyxor Daily LevDAX UCITS ETF - LU0252634307 ", c: "" },
  { id: 33410, s: "LYYB.XETRA", n: "Lyxor UCITS MSCI USA D-EUR - FR0010296061 ", c: "" },
  { id: 33411, s: "M9SA.XETRA", n: "Market Access Rogers International Commodity Index UCITS ETF - LU0249326488 ", c: "" },
  { id: 33412, s: "MACU.AS", n: "BlackRock ESG Multi-Asset Conservative Portfolio UCITS ETF USD Hedged (Acc) - IE00BLB2GQ94 ", c: "" },
  { id: 33413, s: "MAGU.AS", n: "BlackRock ESG Multi-Asset Growth Portfolio UCITS ETF USD Hedged (Acc) - IE00BLB2GS19 ", c: "" },
  { id: 33414, s: "MCHI.MX", n: "iShares Trust - iShares MSCI China ETF - US46429B6719 ", c: "" },
  { id: 33415, s: "MCHN.LSE", n: "Invesco MSCI China All Shares Connect UCITS ETF - IE00BK80XL30 ", c: "" },
  { id: 33416, s: "MCHN.XETRA", n: "Invesco MSCI China All Shares Connect UCITS ETF EUR - IE00BK80XL30 ", c: "" },
  { id: 33417, s: "MCHT.LSE", n: "Invesco MSCI China Technology All Shares Stock Connect UCITS ETF - IE00BM8QS095 ", c: "" },
  { id: 33418, s: "MCHT.SW", n: "Invesco MSCI China Technology All Shares Stock Connect UCITS ETF USD - IE00BM8QS095 ", c: "" },
  { id: 33419, s: "MCTS.LSE", n: "Invesco MSCI China Technology All Shares Stock Connect UCITS ETF GBP - IE00BM8QS095 ", c: "" },
  { id: 33420, s: "MDBC.SW", n: "UBS(Lux)Fund Solutions – Sustainable Development Bank Bonds UCITS ETF(hedged CHF)A-acc - LU1852211561 ", c: "" },
  { id: 33421, s: "MDBE.SW", n: "UBS(Lux)Fund Solutions – Sustainable Development Bank Bonds UCITS ETF(hedged EUR)A-acc - LU1852211991 ", c: "" },
  { id: 33422, s: "METR.LSE", n: "ETC Group Global Metaverse UCITS ETF USD Accumulation - IE000KDY10O3 ", c: "" },
  { id: 33423, s: "MEUD.PA", n: "Lyxor Core STOXX Europe 600 (DR) - UCITS ETF Acc - LU0908500753 ", c: "" },
  { id: 33424, s: "MODU.AS", n: "BlackRock ESG Multi-Asset Moderate Portfolio UCITS ETF USD Hedged (Acc) - IE00BLB2GT26 ", c: "" },
  { id: 33425, s: "MOED.XETRA", n: "BNP Paribas Easy - Equity Momentum Europe - LU1481201538 ", c: "" },
  { id: 33426, s: "MPAB.AS", n: "iShares MSCI EMU Paris-Aligned Climate UCITS ETF EUR Dist - IE000FOSCLU1 ", c: "" },
  { id: 33427, s: "MPXG.LSE", n: "Amundi Index MSCI Pacific Ex Japan SRI PAB - UCITS ETF DR - GBP (D) - LU2469335298 ", c: "" },
  { id: 33428, s: "MSAU.LSE", n: "Invesco MSCI Saudi Arabia ETF - IE00BFWMQ331 ", c: "" },
  { id: 33429, s: "MSEC.SW", n: "Lyxor Euro Stoxx 50 (DR) UCITS ETF Daily Hedged to CHF Acc - FR0012399731 ", c: "" },
  { id: 33430, s: "MSEU.LSE", n: "Lyxor UCITS Euro Stoxx 50 Dailly USD - FR0012399806 ", c: "" },
  { id: 33431, s: "MSEX.LSE", n: "Lyxor UCITS Euro Stoxx 50 Dailly GBP - FR0012399772 ", c: "" },
  { id: 33432, s: "MSRU.LSE", n: "Amundi Index Solutions - Amundi Index MSCI Emerging Markets SRI PAB UCITS ETF DR C - LU1861138961 ", c: "" },
  { id: 33433, s: "MVEC.SW", n: "iShares Edge MSCI World Minimum Volatility ESG UCITS ETF CHF Hedged Acc - IE00BMH5T269 ", c: "" },
  { id: 33434, s: "MVLD.AS", n: "iShares Edge MSCI Europe Minimum Volatility ESG UCITS ETF USD Hedged (Acc) - IE00BMDBM966 ", c: "" },
  { id: 33435, s: "MVSH.SW", n: "iShares Edge MSCI World Minimum Volatility UCITS ETF CHF Hedged (Acc) - IE00BD1JRZ09 ", c: "" },
  { id: 33436, s: "MVUS.LSE", n: "iShares VI Public Limited Company - iShares Edge S&P 500 Minimum Volatility UCITS ETF - IE00B6SPMN59 ", c: "" },
  { id: 33437, s: "MVWE.SW", n: "iShares Edge MSCI World Minimum Volatility ESG UCITS ETF EUR Hedged Acc - IE00BMH5VP31 ", c: "" },
  { id: 33438, s: "MWOE.XETRA", n: "Amundi MSCI World UCITS ETF DR USD Inc EUR - IE000CNSFAR2 ", c: "" },
  { id: 33439, s: "MWSH.XETRA", n: "Amundi Index Solutions - Amundi Index MSCI World SRI PAB UCITS ETF DR Hedged EUR C - LU2249056297 ", c: "" },
  { id: 33440, s: "MXCN.SW", n: "Invesco MSCI China All Shares Connect UCITS ETF USD - IE00BK80XL30 ", c: "" },
  { id: 33441, s: "N4US.LSE", n: "Invesco JPX-Nikkei 400 UCITS ETF (USD Hdg) - IE00BVGC6751 ", c: "" },
  { id: 33442, s: "NDXH.PA", n: "Amundi Nasdaq-100 EUR Hedged Daily UCITS - LU1681038599 ", c: "" },
  { id: 33443, s: "NESG.LSE", n: "Invesco Markets II plc - Invesco NASDAQ-100 ESG UCITS ETF USD Acc - IE000COQKPO9 ", c: "" },
  { id: 33444, s: "NQSE.XETRA", n: "iShares VII PLC - iShares NASDAQ 100 ETF EUR Hdg Acc - IE00BYVQ9F29 ", c: "" },
  { id: 33445, s: "NRAM.PA", n: "AMUNDI MSCI North America ESG Climate Net Zero Ambition CTB UCITS ETF Acc EUR - IE000MYCJA42 ", c: "" },
  { id: 33446, s: "NS4E.XETRA", n: "Source JPX-Nikkei 400 UCITS EUR Hedged - IE00BVGC6645 ", c: "" },
  { id: 33447, s: "OIH.MX", n: "VanEck Vectors ETF Trust - VanEck Vectors Oil Services ETF - US92189H6071 ", c: "" },
  { id: 33448, s: "OILCHA.SW", n: "UBS (CH) Fund Solutions – CMCI Oil SF ETF (CHF) A-dis - CH0116015352 ", c: "" },
  { id: 33449, s: "OILUSA.SW", n: "UBS (CH) Fund Solutions – CMCI Oil SF ETF (USD) A-dis - CH0109967858 ", c: "" },
  { id: 33450, s: "OP2E.XETRA", n: "Ossiam Lux Ossiam MSCI EMU NR - LU1847674733 ", c: "" },
  { id: 33451, s: "OP4E.XETRA", n: "Ossiam Lux Ossiam MSCI Europe ex EMU NR - LU1655103486 ", c: "" },
  { id: 33452, s: "OP5H.XETRA", n: "OSSIAM MSCI JAPAN NR 1C Hedged Index EUR - LU1655103726 ", c: "" },
  { id: 33453, s: "OP6E.XETRA", n: "Ossiam Lux Bloomberg Asia Pacific Ex Japan PAB NR C EUR Acc - LU2491210618 ", c: "" },
  { id: 33454, s: "OP7E.XETRA", n: "Ossiam Bloomberg USA PAB UCITS ETF - 1A EUR Acc - IE000IIED424 ", c: "" },
  { id: 33455, s: "PABS.LSE", n: "Lyxor Net Zero 2050 S&P 500 Climate PAB (DR) UCITS ETF USD Inc - LU2198883501 ", c: "" },
  { id: 33456, s: "PAEU.SW", n: "Invesco MSCI Europe ESG Climate Paris Aligned UCITS EUR Acc - IE000TI21P14 ", c: "" },
  { id: 33457, s: "PAJP.LSE", n: "Invesco MSCI Japan ESG Climate Paris Aligned UCITS USD Acc - IE000I8IKC59 ", c: "" },
  { id: 33458, s: "PAUS.LSE", n: "Invesco MSCI USA ESG Climate Paris Aligned UCITS USD Acc - IE000RLUE8E9 ", c: "" },
  { id: 33459, s: "PAVG.LSE", n: "Global X U.S. Infrastructure Development UCITS ETF USD Dis GBP - IE00BLCHJ641 ", c: "" },
  { id: 33460, s: "PAWD.LSE", n: "Invesco MSCI World ESG Climate Paris Aligned UCITS USD Acc - IE000V93BNU0 ", c: "" },
  { id: 33461, s: "PCOM.LSE", n: "WisdomTree Broad Commodities UCITS ETF - USD Acc - IE00BKY4W127 ", c: "" },
  { id: 33462, s: "PDJE.PA", n: "Lyxor PEA Dow Jones Industrial Average UCITS C - FR0011869270 ", c: "" },
  { id: 33463, s: "PDSE.XETRA", n: "PowerShares Preferred Shares UCITS EUR Hedged Dist - IE00BDT8V027 ", c: "" },
  { id: 33464, s: "PETZ.LSE", n: "Rize Pet Care UCITS ETF A USD Acc - IE000QRNAC66 ", c: "" },
  { id: 33465, s: "PEU.PA", n: "Invesco Markets III plc - Invesco EuroMTS Cash 3 Months UCITS ETF - IE00B3BPCH51 ", c: "" },
  { id: 33466, s: "PFF.MX", n: "iShares Trust - iShares Preferred and Income Securities ETF - US4642886877 ", c: "" },
  { id: 33467, s: "PJEU.F", n: "Invesco Markets III plc - Invesco EuroMTS Cash 3 Months UCITS ETF - IE00B3BPCH51 ", c: "" },
  { id: 33468, s: "PJEU.XETRA", n: "Invesco Markets III plc - Invesco EuroMTS Cash 3 Months UCITS ETF - IE00B3BPCH51 ", c: "" },
  { id: 33469, s: "PLAY.AS", n: "iShares Digital Entertainment and Education UCITS ETF USD (Acc) - IE00023EZQ82 ", c: "" },
  { id: 33470, s: "PLUSA.SW", n: "UBS ETF (CH) – Palladium (USD) A-dis - CH0118929022 ", c: "" },
  { id: 33471, s: "PMEH.PA", n: "Lyxor UCITS PEA FTSE EPRA/NAREIT Developed Europe C - FR0011869304 ", c: "" },
  { id: 33472, s: "PMNT.LSE", n: "Rize Digital Payments Economy UCITS ETF A USD Accumulation - IE00BLRPRQ96 ", c: "" },
  { id: 33473, s: "PPLT.MX", n: "Aberdeen Standard Platinum ETF Trust - Aberdeen Standard Physical Platinum Shares ETF - US0032601066 ", c: "" },
  { id: 33474, s: "PRAC.LSE", n: "Invesco Preferred Shares UCITS ETF USD Acc - IE00BG482169 ", c: "" },
  { id: 33475, s: "PRAM.LSE", n: "Amundi Prime Emerging Markets UCITS ETF DR (C) - LU2300295123 ", c: "" },
  { id: 33476, s: "PRAM.XETRA", n: "Amundi Prime Emerging Markets UCITS ETF DR (C) - LU2300295123 ", c: "" },
  { id: 33477, s: "PRFC.SW", n: "Invesco Preferred Shares UCITS ETF CHF Hedged Acc - IE00BDT8TZ34 ", c: "" },
  { id: 33478, s: "PRFD.LSE", n: "PowerShares Preferred Shares UCITS - IE00BDVJF675 ", c: "" },
  { id: 33479, s: "PRFD.SW", n: "Invesco Preferred Shares UCITS ETF USD - IE00BDVJF675 ", c: "" },
  { id: 33480, s: "PRFE.SW", n: "Invesco Preferred Shares UCITS ETF EUR Hedged Dist EUR - IE00BDT8V027 ", c: "" },
  { id: 33481, s: "PRFP.LSE", n: "PowerShares Preferred Shares UCITS - IE00BDVJF675 ", c: "" },
  { id: 33482, s: "PSQ.MX", n: "ProShares Trust - ProShares Short QQQ - US74347B7148 ", c: "" },
  { id: 33483, s: "PSRF.LSE", n: "Invesco Markets III plc - Invesco FTSE RAFI US 1000 UCITS ETF - IE00B23D8S39 ", c: "" },
  { id: 33484, s: "PTUSA.SW", n: "UBS ETF (CH) – Platinum (USD) A-dis CHF - CH0116014934 ", c: "" },
  { id: 33485, s: "PUIG.XETRA", n: "Invesco USD Corporate Bond ESG UCITS ETF - IE00BF51K025 ", c: "" },
  { id: 33486, s: "PXJSRI.SW", n: "UBS (Irl) ETF plc - MSCI Pacific (ex Japan) IMI Socially Responsible UCITS USD A-Acc - IE000BKMMHF9 ", c: "" },
  { id: 33487, s: "QDFD.LSE", n: "FlexShares® Developed Markets High Dividend Climate ESG UCITS ETF GBP - IE00BMYDBM76 ", c: "" },
  { id: 33488, s: "QDIV.LSE", n: "iShares MSCI USA Dividend IQ UCITS - IE00BKM4H312 ", c: "" },
  { id: 33489, s: "QDVK.XETRA", n: "iShares S&P 500 USD Consumer Discretionary Sector UCITS - IE00B4MCHD36 ", c: "" },
  { id: 33490, s: "QDVN.XETRA", n: "iShares Sustainable MSCI Japan SRI EUR Hedged UCITS - IE00BYVJRQ85 ", c: "" },
  { id: 33491, s: "QLD.MX", n: "ProShares Trust - ProShares Ultra QQQ - US74347R2067 ", c: "" },
  { id: 33492, s: "QQQ.MX", n: "Invesco QQQ Trust Series 1 - US73935A1043 ", c: "" },
  { id: 33493, s: "QTEC.MX", n: "First Trust Exchange-Traded Fund - First Trust NASDAQ-100-Technology Sector Index Fund - US3373451026 ", c: "" },
  { id: 33494, s: "QUAL.MX", n: "iShares Trust - iShares MSCI USA Quality Factor ETF - US46432F3394 ", c: "" },
  { id: 33495, s: "QVFD.LSE", n: "FlexShares® Developed Markets Low Volatility Climate ESG UCITS ETF GBP - IE00BMYDBG17 ", c: "" },
  { id: 33496, s: "QVFD.XETRA", n: "FlexShares® Developed Markets Low Volatility Climate ESG UCITS ETF - IE00BMYDBG17 ", c: "" },
  { id: 33497, s: "QVFE.XETRA", n: "FlexShares® Emerging Markets Low Volatility Climate ESG UCITS ETF - IE00BMYDBN83 ", c: "" },
  { id: 33498, s: "R2SC.LSE", n: "SPDR® Russell 2000 US Small Cap UCITS ETF - IE00BJ38QD84 ", c: "" },
  { id: 33499, s: "RAYS.LSE", n: "Invesco Solar Energy UCITS ETF - IE00BM8QRZ79 ", c: "" },
  { id: 33500, s: "RAYZ.LSE", n: "Global X Solar UCITS ETF USD Acc - IE000XD7KCJ7 ", c: "" },
  { id: 33501, s: "REMX.LSE", n: "VanEck Rare Earth and Strategic Metals UCITS ETF A USD Acc - IE0002PG6CA6 ", c: "" },
  { id: 33502, s: "RENW.LSE", n: "L&G Clean Energy UCITS ETF USD Acc - IE00BK5BCH80 ", c: "" },
  { id: 33503, s: "RGLDO.SW", n: "Raiffeisen ETF Solid Gold Ounces A CHF - CH0134034849 ", c: "" },
  { id: 33504, s: "RGLDOH.SW", n: "Raiffeisen ETF Solid Gold OuncesH CHF Hedged - CH0134034864 ", c: "" },
  { id: 33505, s: "RGLDS.SW", n: "Raiffeisen ETF Solid Gold A USD - CH0134034930 ", c: "" },
  { id: 33506, s: "RGLDSH.SW", n: "Raiffeisen ETF Solid Gold H CHF Hedged - CH0134034906 ", c: "" },
  { id: 33507, s: "ROLL.LSE", n: "iShares Bloomberg Roll Select Commodity Swap UCITS ETF - IE00BZ1NCS44 ", c: "" },
  { id: 33508, s: "RSX.MX", n: "VanEck Vectors ETF Trust - VanEck Vectors Russia ETF - US57060U5065 ", c: "" },
  { id: 33509, s: "S0LR.XETRA", n: "Invesco Solar Energy UCITS ETF - IE00BM8QRZ79 ", c: "" },
  { id: 33510, s: "S2HGBD.SW", n: "UBS(Lux)Fund Solutions – MSCI Switzerland 20/35 UCITS ETF(hedged GBP)A-dis GBP - LU1169830442 ", c: "" },
  { id: 33511, s: "S500.PA", n: "Amundi S&P 500 ESG UCITS ETF Acc EUR - IE000KXCEXR3 ", c: "" },
  { id: 33512, s: "S5EG.LSE", n: "UBS (Irl) ETF plc - S&P 500 ESG ELITE UCITS ETF (hedged to GBP) A Inc - IE00BLSN7X94 ", c: "" },
  { id: 33513, s: "S5SG.XETRA", n: "UBS (Irl) ETF plc - S&P 500 ESG UCITS ETF Hedged to EUR A Acc - IE00BHXMHQ65 ", c: "" },
  { id: 33514, s: "SADA.LSE", n: "Amundi Index Solutions - Amundi Index MSCI EM Asia SRI PAB UCITS ETF DR USD (D) - LU2300294589 ", c: "" },
  { id: 33515, s: "SADA.XETRA", n: "Amundi Index Solutions - Amundi Index MSCI EM Asia SRI PAB UCITS ETF DR USD (D) - LU2300294589 ", c: "" },
  { id: 33516, s: "SADH.XETRA", n: "Amundi Index Solutions - Amundi MSCI USA ESG Leaders Select ETF DR Hedged EUR - LU2153616326 ", c: "" },
  { id: 33517, s: "SBEG.LSE", n: "UBS(Lux)Fund Solutions – Bloomberg USD Emerging Markets Sovereign UCITS ETF(hedgedGBP)A-dis - LU1324516480 ", c: "" },
  { id: 33518, s: "SBEMH.SW", n: "UBS(Lux)Fund Solutions – Bloomberg USD Emerging Markets Sovereign UCITS ETF(hedgedGBP)A-dis - LU1324516480 ", c: "" },
  { id: 33519, s: "SBEMS.SW", n: "UBS(Lux)Fund Solutions – Bloomberg USD Emerging Markets Sovereign UCITS ETF(hedgedCHF)A-acc - LU1324516720 ", c: "" },
  { id: 33520, s: "SBIM.XETRA", n: "Amundi Index Solutions - Amundi MSCI Emerging ESG Broad CTB UCITS ETF DR USD (C) EUR - LU2109787049 ", c: "" },
  { id: 33521, s: "SC0D.XETRA", n: "Invesco Markets plc - Invesco EURO STOXX 50 UCITS ETF - IE00B60SWX25 ", c: "" },
  { id: 33522, s: "SCWS.SW", n: "iShares MSCI World SRI UCITS ETF CHF Hedged (Dist) - IE00BMZ17V16 ", c: "" },
  { id: 33523, s: "SDIU.LSE", n: "Global X SuperDividend UCITS ETF USD Cap - IE000YICM5P9 ", c: "" },
  { id: 33524, s: "SDIV.LSE", n: "Global X SuperDividend UCITS ETF USD Dis - IE00077FRP95 ", c: "" },
  { id: 33525, s: "SDIV.SW", n: "Global X SuperDividend UCITS ETF USD Dis CHF - IE00077FRP95 ", c: "" },
  { id: 33526, s: "SDUE.LSE", n: "iShares MSCI Europe ESG Screened UCITS ETF - IE00BFNM3F38 ", c: "" },
  { id: 33527, s: "SDUS.LSE", n: "iShares MSCI USA ESG Screened UCITS ETF USD (Dist) - IE00BFNM3H51 ", c: "" },
  { id: 33528, s: "SEMB.LSE", n: "iShares J.P. Morgan Emerging Market Bond UCITS - IE00B2NPKV68 ", c: "" },
  { id: 33529, s: "SESW.SW", n: "iShares MSCI World SRI UCITS ETF EUR Hedged (Dist) - IE00BMZ17W23 ", c: "" },
  { id: 33530, s: "SGLN.LSE", n: "iShares Physical Gold ETC - IE00B4ND3602 ", c: "" },
  { id: 33531, s: "SGQX.LSE", n: "Lyxor SG Global Quality Income NTR UCITS ETF - Monthly Hedged C-GBP - LU1040688639 ", c: "" },
  { id: 33532, s: "SGSU.SW", n: "iShares $ Corp Bond 0-3yr ESG UCITS ETF GBP Hedged (Dist) GBP - IE00BK7Y2S64 ", c: "" },
  { id: 33533, s: "SGWS.LSE", n: "iShares MSCI World SRI UCITS ETF GBP Hedged (Dist) GBP - IE00BMZ17T93 ", c: "" },
  { id: 33534, s: "SGWS.SW", n: "iShares MSCI World SRI UCITS ETF GBP Hedged (Dist) - IE00BMZ17T93 ", c: "" },
  { id: 33535, s: "SHC.PA", n: "Lyxor UCITS Daily Short CAC 40 - FR0010591362 ", c: "" },
  { id: 33536, s: "SHEMC.SW", n: "UBS(Lux)Fund Solutions – J.P. Morgan USD EM Diversified Bond 1-5 UCITS ETF(hedged CHF)A-acc - LU1645386217 ", c: "" },
  { id: 33537, s: "SHV.MX", n: "iShares Trust - iShares Short Treasury Bond ETF - US4642886794 ", c: "" },
  { id: 33538, s: "SHY.MX", n: "iShares Trust - iShares 1-3 Year Treasury Bond ETF - US4642874576 ", c: "" },
  { id: 33539, s: "SHYG.MX", n: "iShares Trust - iShares 0-5 Year High Yield Corporate Bond ETF - US46434V4077 ", c: "" },
  { id: 33540, s: "SILV.LSE", n: "Global X Silver Miners UCITS ETF USD Acc - IE000UL6CLP7 ", c: "" },
  { id: 33541, s: "SIZE.MX", n: "iShares Trust - iShares MSCI USA Size Factor ETF - US46432F3709 ", c: "" },
  { id: 33542, s: "SJPE.AS", n: "iShares Core MSCI Japan IMI UCITS ETF EUR Hedged (Acc) - IE00BKT6FV49 ", c: "" },
  { id: 33543, s: "SLQD.MX", n: "iShares Trust - iShares 0-5 Year Investment Grade Corporate Bond ETF - US46434V1008 ", c: "" },
  { id: 33544, s: "SLV.MX", n: "iShares Silver Trust - US46428Q1094 ", c: "" },
  { id: 33545, s: "SMGB.LSE", n: "VanEck Semiconductor UCITS ETF - IE00BMC38736 ", c: "" },
  { id: 33546, s: "SMH.LSE", n: "VanEck Semiconductor UCITS ETF - IE00BMC38736 ", c: "" },
  { id: 33547, s: "SMHV.SW", n: "VanEck Semiconductor UCITS ETF CHF - IE00BMC38736 ", c: "" },
  { id: 33548, s: "SMLP.XETRA", n: "Invesco Morningstar US Energy Infrastructure MLP UCITS ETF - IE00B94ZB998 ", c: "" },
  { id: 33549, s: "SOLR.SW", n: "Invesco Solar Energy UCITS ETF USD - IE00BM8QRZ79 ", c: "" },
  { id: 33550, s: "SP2D.XETRA", n: "Invesco S&P 500 Equal Weight Index ETF Inc - IE00BM8QRY62 ", c: "" },
  { id: 33551, s: "SP500E.SW", n: "UBS (Irl) ETF plc - S&P 500 UCITS ETF (hedged to EUR) A-acc - IE00BD34DK07 ", c: "" },
  { id: 33552, s: "SP500H.SW", n: "UBS (Irl) ETF plc - S&P 500 UCITS ETF (hedged to GBP) A-dis - IE00BD34DL14 ", c: "" },
  { id: 33553, s: "SP500S.SW", n: "UBS (Irl) ETF plc - S&P 500 UCITS ETF (hedged to CHF) A-acc - IE00BD34DB16 ", c: "" },
  { id: 33554, s: "SPED.LSE", n: "Invesco S&P 500 Equal Weight Index ETF Inc - IE00BM8QRY62 ", c: "" },
  { id: 33555, s: "SPEH.LSE", n: "iShares Spain Govt Bond UCITS ETF USD Hedged (Acc) - IE00BFMM8Y81 ", c: "" },
  { id: 33556, s: "SPEQ.LSE", n: "Invesco S&P 500 Equal Weight Index ETF Acc - IE00BNGJJT35 ", c: "" },
  { id: 33557, s: "SPESGE.SW", n: "UBS (Irl) ETF plc - S&P 500 ESG ELITE UCITS ETF (USD) A Acc - IE00BLSN7P11 ", c: "" },
  { id: 33558, s: "SPF7.XETRA", n: "SPDR MSCI Emerging Markets Climate Paris Aligned UCITS ETF EUR - IE00BYTH5263 ", c: "" },
  { id: 33559, s: "SPFD.XETRA", n: "SPDR® Bloomberg Emerging Markets Local Bond UCITS ETF EUR (Acc) - IE00BK8JH525 ", c: "" },
  { id: 33560, s: "SPGP.LSE", n: "iShares V Public Limited Company - iShares Gold Producers UCITS ETF - IE00B6R52036 ", c: "" },
  { id: 33561, s: "SPLW.LSE", n: "Invesco S&P 500 Low Volatility Index ETF - IE00BKW9SX35 ", c: "" },
  { id: 33562, s: "SPP1.XETRA", n: "SPDR® MSCI ACWI EUR Hdg UCITS ETF (Acc) - IE00BF1B7389 ", c: "" },
  { id: 33563, s: "SPPD.XETRA", n: "SPDR® S&P US Dividend Aristocrats UCITS ETF EUR Dis - IE00B979GK47 ", c: "" },
  { id: 33564, s: "SPPE.XETRA", n: "SPDR S&P 500 UCITS ETF EUR Acc H - IE00BYYW2V44 ", c: "" },
  { id: 33565, s: "SPUD.LSE", n: "SPDR® MSCI USA Climate Paris Aligned UCITS ETF USD Acc - IE00BYTH5719 ", c: "" },
  { id: 33566, s: "SPXP.LSE", n: "Invesco S&P 500 UCITS ETF - IE00B3YCGJ38 ", c: "" },
  { id: 33567, s: "SPY.MX", n: "SPDR S&P 500 ETF Trust - US78462F1030 ", c: "" },
  { id: 33568, s: "SPYU.XETRA", n: "SPDR® MSCI Europe Utilities UCITS ETF - IE00BKWQ0P07 ", c: "" },
  { id: 33569, s: "SRUG.LSE", n: "UBS (Irl) ETF plc - MSCI USA Socially Responsible UCITS ETF (hedged to GBP) A-dis - IE00BJXT3H40 ", c: "" },
  { id: 33570, s: "SRWG.LSE", n: "UBS (Irl) ETF plc – MSCI World Socially Responsible UCITS ETF (hedged to GBP) A-dis - IE00BK72HN04 ", c: "" },
  { id: 33571, s: "STEA.LSE", n: "PIMCO Short-Term High Yield Corporate Bond Index Source UCITS ETF EUR (Hedged) Inc - IE00BD26N851 ", c: "" },
  { id: 33572, s: "STHC.SW", n: "PIMCO Short-Term High Yield Corporate Bond Index Source UCITS ETF CHF (Hedged) Acc - IE00BWC52G65 ", c: "" },
  { id: 33573, s: "STHE.LSE", n: "PIMCO Short-Term High Yield Corporate Bond Source UCITS EUR Hedged Class - IE00BF8HV600 ", c: "" },
  { id: 33574, s: "STHE.SW", n: "PIMCO Short-Term High Yield Corporate Bond Index Source UCITS ETF Inc EUR Hedged EUR - IE00BF8HV600 ", c: "" },
  { id: 33575, s: "STHS.LSE", n: "PIMCO Short-Term High Yield Corporate Bond Source UCITS Hedged Inc - IE00BYXVWC37 ", c: "" },
  { id: 33576, s: "STHS.SW", n: "PIMCO Short-Term High Yield Corporate Bond Index Source UCITS ETF GBP (Hedged) Inc GBP - IE00BYXVWC37 ", c: "" },
  { id: 33577, s: "STRX.SW", n: "SPDR® MSCI Europe Consumer Discretionary UCITS ETF CHF - IE00BKWQ0C77 ", c: "" },
  { id: 33578, s: "STXH.XETRA", n: "Lyxor STOXX Europe 600 DR UCITS Monthly Hedged D-EUR - LU1574142243 ", c: "" },
  { id: 33579, s: "SUAP.LSE", n: "iShares MSCI USA SRI UCITS ETF GBP Hedged (Dist) - IE00BMWPV470 ", c: "" },
  { id: 33580, s: "SUK2.LSE", n: "L&G FTSE 100 Super Short Strategy (Daily 2x) UCITS ETF - IE00B4QNK008 ", c: "" },
  { id: 33581, s: "SUOC.SW", n: "iShares € Corp Bond ESG UCITS ETF CHF Hedged (Acc) - IE00BMDBMP28 ", c: "" },
  { id: 33582, s: "SUWU.SW", n: "iShares MSCI World SRI UCITS ETF USD Hedged (Dist) - IE00BMZ17X30 ", c: "" },
  { id: 33583, s: "SVUSA.SW", n: "UBS ETF (CH) – Silver (USD) A-dis - CH0118929048 ", c: "" },
  { id: 33584, s: "SWEUAH.SW", n: "UBS ETF (CH) – MSCI Switzerland hedged to EUR (EUR) A-dis - CH0226274204 ", c: "" },
  { id: 33585, s: "SWUSAH.SW", n: "UBS ETF (CH) – MSCI Switzerland hedged to USD (USD) A-dis - CH0226274212 ", c: "" },
  { id: 33586, s: "SXR0.XETRA", n: "iShares Edge MSCI World Minimum Volatility UCITS Hedged Acc - IE00BYXPXL17 ", c: "" },
  { id: 33587, s: "SXRS.XETRA", n: "iShares Diversified Commodity Swap UCITS ETF - IE00BDFL4P12 ", c: "" },
  { id: 33588, s: "T1EU.XETRA", n: "Invesco US Treasury Bond 0-1 Year UCITS ETF EUR Hdg Acc - IE00BLCH1X54 ", c: "" },
  { id: 33589, s: "T3GB.SW", n: "Invesco US Treasury Bond 1-3 Year UCITS ETF GBP Hdg Dist GBP - IE00BF2FNL98 ", c: "" },
  { id: 33590, s: "T3MP.LSE", n: "JPMorgan ETFs (Ireland) ICAV - Climate Change Solutions UCITS ETF - IE000O8S1EX4 ", c: "" },
  { id: 33591, s: "TABC.XETRA", n: "Tabula EUR IG Bond Paris-Aligned Climate UCITS ETF (EUR) - Acc - IE00BN4GXL63 ", c: "" },
  { id: 33592, s: "TANN.XETRA", n: "HANetf ICAV - Solar Energy UCITS ETF EUR - IE00BMFNWC33 ", c: "" },
  { id: 33593, s: "TANP.LSE", n: "HANetf ICAV - Solar Energy UCITS ETF GBP - IE00BMFNWC33 ", c: "" },
  { id: 33594, s: "TEMP.SW", n: "JPMorgan ETFs (Ireland) ICAV - Climate Change Solutions UCITS ETF USD - IE000O8S1EX4 ", c: "" },
  { id: 33595, s: "THD.MX", n: "iShares Inc. - iShares MSCI Thailand ETF - US4642866242 ", c: "" },
  { id: 33596, s: "TI5G.LSE", n: "iShares $ TIPS 0-5 UCITS ETF GBP Hedged (Dist) - IE00BDZVHB89 ", c: "" },
  { id: 33597, s: "TINC.SW", n: "Tabula US Enhanced Infla UCITS ETF CHF H - IE00BKX90W50 ", c: "" },
  { id: 33598, s: "TIP.MX", n: "iShares Trust - iShares TIPS Bond ETF - US4642871762 ", c: "" },
  { id: 33599, s: "TIPH.LSE", n: "Lyxor US TIPS DR UCITS Monthly Hedged D-GBP - LU1452600601 ", c: "" },
  { id: 33600, s: "TLH.MX", n: "iShares Trust - iShares 10-20 Year Treasury Bond ETF - US4642886539 ", c: "" },
  { id: 33601, s: "TLT.MX", n: "iShares Trust - iShares 20+ Year Treasury Bond ETF - US4642874329 ", c: "" },
  { id: 33602, s: "TNOW.LSE", n: "Lyxor MSCI World Information Technology TR UCITS - LU0533033741 ", c: "" },
  { id: 33603, s: "TPHC.PA", n: "Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C CHF Hedged - LU1681038169 ", c: "" },
  { id: 33604, s: "TPHC.SW", n: "Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C CHF Hedged CHF - LU1681038169 ", c: "" },
  { id: 33605, s: "TPHG.LSE", n: "Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C GBP Hedged - LU1681038086 ", c: "" },
  { id: 33606, s: "TPHG.SW", n: "Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C GBP Hedged GBP - LU1681038086 ", c: "" },
  { id: 33607, s: "TPHU.LSE", n: "Amundi Japan Topix UCITS Daily Hedged - LU1681037948 ", c: "" },
  { id: 33608, s: "TPHU.SW", n: "Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C USD Hedged USD - LU1681037948 ", c: "" },
  { id: 33609, s: "TPXH.PA", n: "Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C EUR Hedged - LU1681037864 ", c: "" },
  { id: 33610, s: "TPXH.SW", n: "Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C EUR Hedged EUR - LU1681037864 ", c: "" },
  { id: 33611, s: "TR7S.SW", n: "Invesco US Treasury Bond 3-7 Year UCITS ETF GBP Hdg Dist GBP - IE00BF2GC043 ", c: "" },
  { id: 33612, s: "TRDE.XETRA", n: "Invesco US Treasury Bond 7-10 Year UCITS ETF EUR Hedged Dist - IE00BF2FN869 ", c: "" },
  { id: 33613, s: "TREG.LSE", n: "VanEck Vectors ETFs N.V. - VanEck Vectors Global Real Estate UCITS ETF - NL0009690239 ", c: "" },
  { id: 33614, s: "TRGB.SW", n: "Invesco US Treasury Bond UCITS ETF GBP Hdg Dist GBP - IE00BF2GFM70 ", c: "" },
  { id: 33615, s: "TRIP.LSE", n: "HANetf ICAV - The Travel UCITS ETF GBP - IE00BMFNW783 ", c: "" },
  { id: 33616, s: "TRS3.LSE", n: "SPDR Barclays 1-3 US Treasury Bond UCITS - IE00BC7GZJ81 ", c: "" },
  { id: 33617, s: "TRXE.SW", n: "Invesco US Treasury Bond 7-10 Year UCITS ETF EUR Hedged Dist - IE00BF2FN869 ", c: "" },
  { id: 33618, s: "TRXS.SW", n: "Invesco US Treasury Bond 7-10 Year UCITS ETF GBP Hedged Dist GBP - IE00BF2FNB90 ", c: "" },
  { id: 33619, s: "TSWE.XETRA", n: "VanEck Sustainable World Equal Weight UCITS ETF - NL0010408704 ", c: "" },
  { id: 33620, s: "TTPX.XETRA", n: "Amundi Index Solutions - Amundi Japan Topix UCITS ETF-C EUR Hedged - LU1681037864 ", c: "" },
  { id: 33621, s: "TUR.MX", n: "iShares Inc. - iShares MSCI Turkey ETF - US4642867158 ", c: "" },
  { id: 33622, s: "TWM.MX", n: "ProShares Trust - ProShares UltraShort Russell2000 - US74347G6897 ", c: "" },
  { id: 33623, s: "UB03.LSE", n: "UBS - FTSE 100 UCITS GBP A-dis - LU0136242590 ", c: "" },
  { id: 33624, s: "UB06.LSE", n: "UBS MSCI EMU UCITS EUR A-dis - LU0147308422 ", c: "" },
  { id: 33625, s: "UB0D.LSE", n: "UBS(Lux)Fund Solutions – MSCI Japan UCITS ETF(hedged GBP)A-dis - LU1169822340 ", c: "" },
  { id: 33626, s: "UB0E.LSE", n: "UBS MSCI EMU UCITS hedged A-acc - LU1169820138 ", c: "" },
  { id: 33627, s: "UB0F.LSE", n: "UBS(Lux)Fund Solutions – MSCI EMU UCITS ETF(hedged GBP)A-dis - LU1169819981 ", c: "" },
  { id: 33628, s: "UB12.LSE", n: "UBS MSCI Europe UCITS EUR A-dis - LU0446734104 ", c: "" },
  { id: 33629, s: "UB17.LSE", n: "UBS MSCI EMU Value UCITS EUR Adis - LU0446734369 ", c: "" },
  { id: 33630, s: "UB30.LSE", n: "UBS MSCI Emerging Markets UCITS - LU0480132876 ", c: "" },
  { id: 33631, s: "UBTL.LSE", n: "UBS(Lux)Fund Solutions – Bloomberg TIPS 10+ UCITS ETF(USD)A-dis - LU1459802754 ", c: "" },
  { id: 33632, s: "UBTP.LSE", n: "UBS Bloomberg Barclays TIPS 1-10 UCITS hedged A-acc GBP - LU1459801947 ", c: "" },
  { id: 33633, s: "UBUJ.F", n: "UBS (Irl) ETF Public Limited Company - MSCI USA hedged to EUR UCITS ETF - IE00BD4TYG73 ", c: "" },
  { id: 33634, s: "UBUV.F", n: "UBS (Irl) ETF Public Limited Company - Factor MSCI USA Low Volatility UCITS ETF - IE00BWT3KJ20 ", c: "" },
  { id: 33635, s: "UBUW.F", n: "UBS (Irl) ETF Public Limited Company - Factor MSCI USA Prime Value UCITS ETF - IE00BWT3KL42 ", c: "" },
  { id: 33636, s: "UBUX.F", n: "UBS (Irl) ETF Public Limited Company - Factor MSCI USA Quality UCITS ETF - IE00BWT3KN65 ", c: "" },
  { id: 33637, s: "UBXX.LSE", n: "UBS(Lux)Fund Solutions – J.P. Morgan USD EM Diversified Bond 1-5 UCITS ETF(hedged GBP)A-dis - LU1645386647 ", c: "" },
  { id: 33638, s: "UC04.LSE", n: "UBS Irl plc - MSCI USA UCITS USD A-dis - IE00B77D4428 ", c: "" },
  { id: 33639, s: "UC15.LSE", n: "UBS (Irl) Fund Solutions plc - CMCI Composite SF UCITS ETF - IE00B53H0131 ", c: "" },
  { id: 33640, s: "UC63.LSE", n: "UBS MSCI United Kingdom UCITS A-dis - LU0937836467 ", c: "" },
  { id: 33641, s: "UC82.LSE", n: "UBS(Lux)Fund Solutions – Bloomberg US Liquid Corporates 1-5 Year UCITS ETF(hedged GBP)A-dis - LU1048315326 ", c: "" },
  { id: 33642, s: "UC85.LSE", n: "UBS Barclays US Liquid Corporates UCITS hedged - LU1048317298 ", c: "" },
  { id: 33643, s: "UC87.LSE", n: "UBS(Lux)Fund Solutions – MSCI Canada UCITS ETF(hedged GBP)A-acc - LU1130156323 ", c: "" },
  { id: 33644, s: "UC90.LSE", n: "UBS (Irl) Fund Solutions plc - CMCI Composite SF UCITS ETF - IE00B50XJX92 ", c: "" },
  { id: 33645, s: "UC93.LSE", n: "UBS MSCI Switzerland 20/35 UCITS hedged A-acc - LU1169830525 ", c: "" },
  { id: 33646, s: "UC94.LSE", n: "UBS(Lux)Fund Solutions – MSCI Switzerland 20/35 UCITS ETF(hedged GBP)A-dis - LU1169830442 ", c: "" },
  { id: 33647, s: "UCRH.XETRA", n: "Amundi Index Solutions - Amundi Index US Corp SRI UCITS ETF DR Hedged EUR Inc - LU2297533809 ", c: "" },
  { id: 33648, s: "UD06.LSE", n: "UBS (Irl) Fund Solutions plc - Bloomberg Commodity CMCI SF UCITS ETF (hedged to GBP) A-acc - IE00BF0V4615 ", c: "" },
  { id: 33649, s: "UD08.LSE", n: "UBS (Irl) Fund Solutions plc - CMCI Ex-Agriculture SF UCITs ETF (hedged to GBP) A-acc - IE00BYT5CX00 ", c: "" },
  { id: 33650, s: "UDIV.XETRA", n: "Global X SuperDividend UCITS ETF USD Dis - IE00077FRP95 ", c: "" },
  { id: 33651, s: "UEDV.AS", n: "SPDR® S&P® U.S. Dividend Aristocrats ESG UCITS ETF EUR - IE00BYTH5R14 ", c: "" },
  { id: 33652, s: "UEDV.LSE", n: "SPDR® S&P® U.S. Dividend Aristocrats ESG UCITS ETF - IE00BYTH5R14 ", c: "" },
  { id: 33653, s: "UEEA.XETRA", n: "iShares MSCI World SRI UCITS ETF EUR Hedged (Dist) - IE00BMZ17W23 ", c: "" },
  { id: 33654, s: "UEEF.XETRA", n: "iShares $ High Yield Corp Bond ESG UCITS ETF EUR Hedged (Acc) - IE00BMDFDY08 ", c: "" },
  { id: 33655, s: "UEEG.XETRA", n: "iShares $ Development Bank Bonds UCITS ETF EUR Hedged (Acc) - IE00BMCZLH06 ", c: "" },
  { id: 33656, s: "UEF0.XETRA", n: "UBS Barclays US Liquid Corporates UCITS hedged to EUR A-acc - LU1048317025 ", c: "" },
  { id: 33657, s: "UEQ3.XETRA", n: "UBS (Irl) Fund Solutions plc – CMCI Composite SF UCITS ETF (hedged to GBP) A-acc - IE00B50XJX92 ", c: "" },
  { id: 33658, s: "UEQU.F", n: "UBS (Irl) Fund Solutions plc - CMCI Ex-Agriculture SF UCITS ETF - IE00BZ2GV965 ", c: "" },
  { id: 33659, s: "UET1.XETRA", n: "UBS(Lux)Fund Solutions – MSCI USA Socially Responsible UCITS ETF(hedged EUR)A-dis - LU1280303014 ", c: "" },
  { id: 33660, s: "UET5.XETRA", n: "UBS(Lux)Fund Solutions – EURO STOXX 50 ESG UCITS ETF(EUR)A-dis - LU1971906802 ", c: "" },
  { id: 33661, s: "UFMA.XETRA", n: "UBS(Lux)Fund Solutions – MSCI Japan UCITS ETF(hedged EUR)A-acc - LU1169822266 ", c: "" },
  { id: 33662, s: "UFMB.XETRA", n: "UBS(Lux)Fund Solutions – MSCI United Kingdom UCITS ETF(hedged EUR)A-acc - LU1169821292 ", c: "" },
  { id: 33663, s: "UFOP.LSE", n: "Procure Space ETF GBP - IE00BLH3CV30 ", c: "" },
  { id: 33664, s: "UIC1.XETRA", n: "UBS ETF - Solactive China Technology UCITS ETF (hedged to EUR) A-acc - LU2265794946 ", c: "" },
  { id: 33665, s: "UIFH.XETRA", n: "Amundi Index Solutions - Amundi Index US Gov Inflation-Linked Bond UCITS ETF DR Hedged - LU2339917168 ", c: "" },
  { id: 33666, s: "UIM2.XETRA", n: "UBS(Lux)Fund Solutions – Factor MSCI EMU Quality UCITS ETF(EUR)A-dis - LU1215451524 ", c: "" },
  { id: 33667, s: "UINF.F", n: "Multi Units Luxembourg - Lyxor US$ 10Y Inflation Breakeven UCITS ETF - LU1390062245 ", c: "" },
  { id: 33668, s: "UINF.XETRA", n: "Lyxor US$ 10Y Inflation Expectations UCITS ETF - C-USD - LU1390062245 ", c: "" },
  { id: 33669, s: "UIQK.F", n: "UBS (Irl) Fund Solutions plc - CMCI Composite SF UCITS ETF - IE00B53H0131 ", c: "" },
  { id: 33670, s: "UIQL.XETRA", n: "UBS Bloomberg Barclays EUR High Quality Liquid Assets 1-5 Bond UCITS EUR A Dis - LU1805389258 ", c: "" },
  { id: 33671, s: "UIW1.XETRA", n: "UBS(Lux)Fund Solutions – MSCI Europe Socially Responsible UCITS ETF(EUR)A-acc - LU2206597804 ", c: "" },
  { id: 33672, s: "UIW2.XETRA", n: "UBS(Lux)Fund Solutions – MSCI Europe Socially Responsible UCITS ETF(EUR)A-dis - LU2206597713 ", c: "" },
  { id: 33673, s: "UIW3.XETRA", n: "UBS(Lux)Fund Solutions – MSCI Europe Socially Responsible UCITS ETF(hedged EUR)A-acc - LU2206598109 ", c: "" },
  { id: 33674, s: "UKCHF.SW", n: "UBS(Lux)Fund Solutions – MSCI United Kingdom UCITS ETF(hedged CHF)A-acc - LU1169820997 ", c: "" },
  { id: 33675, s: "UKEL.LSE", n: "iShares MSCI UK IMI ESG Leaders UCITS ETF GBP (Dist) - IE00BMDBMH44 ", c: "" },
  { id: 33676, s: "UKG5.LSE", n: "L&G UK Gilt 0-5 Year UCITS ETF - IE00BLRPQK69 ", c: "" },
  { id: 33677, s: "UKPH.XETRA", n: "iShares UK Property UCITS ETF EUR Hedged (Acc) - IE000FI414K7 ", c: "" },
  { id: 33678, s: "UKRE.LSE", n: "iShares MSCI Target UK Real Estate UCITS - IE00BRHZ0398 ", c: "" },
  { id: 33679, s: "UKSRE.SW", n: "UBS (Irl) ETF plc - MSCI United Kingdom IMI Socially Responsible UCITS ETF (hedged to EUR) A-acc - IE00BYNQMK61 ", c: "" },
  { id: 33680, s: "UKSRT.SW", n: "UBS (Irl) ETF plc - MSCI United Kingdom IMI Socially Responsible UCITS ETF (hedged to CHF) A-dis - IE00BZ0RTB90 ", c: "" },
  { id: 33681, s: "UKUSD.SW", n: "UBS(Lux)Fund Solutions – MSCI United Kingdom UCITS ETF(hedged USD)A-acc - LU1169821458 ", c: "" },
  { id: 33682, s: "ULOVE.SW", n: "UBS (Irl) ETF plc - Factor MSCI USA Low Volatility UCITS ETF (hedged to EUR) A-acc - IE00BWT3KJ20 ", c: "" },
  { id: 33683, s: "UNG.MX", n: "United States Natural Gas Fund LP - US9123183009 ", c: "" },
  { id: 33684, s: "UPAB.AS", n: "iShares S&P 500 Paris-Aligned Climate UCITS ETF USD Acc - IE00BMXC7V63 ", c: "" },
  { id: 33685, s: "UPAD.LSE", n: "iShares S&P 500 Paris-Aligned Climate UCITS ETF USD Dist - IE000G4PH2B1 ", c: "" },
  { id: 33686, s: "UPVL.LSE", n: "UBS (Irl) ETF plc - Factor MSCI USA Prime Value UCITS ETF (hedged to GBP) A-dis - IE00BXDZNH00 ", c: "" },
  { id: 33687, s: "UPVLE.SW", n: "UBS (Irl) ETF plc - Factor MSCI USA Prime Value UCITS ETF (hedged to EUR) A-acc - IE00BWT3KL42 ", c: "" },
  { id: 33688, s: "UPVLH.SW", n: "UBS (Irl) ETF plc - Factor MSCI USA Prime Value UCITS ETF (hedged to GBP) A-dis GBP - IE00BXDZNH00 ", c: "" },
  { id: 33689, s: "UQLT.LSE", n: "UBS (Irl) ETF plc - Factor MSCI USA Quality UCITS ETF (hedged to GBP) A-dis - IE00BXDZNK39 ", c: "" },
  { id: 33690, s: "UQLTE.SW", n: "UBS (Irl) ETF plc - Factor MSCI USA Quality UCITS ETF (hedged to EUR) A-acc - IE00BWT3KN65 ", c: "" },
  { id: 33691, s: "UQLTH.SW", n: "UBS (Irl) ETF plc - Factor MSCI USA Quality UCITS ETF (hedged to GBP) A-dis GBP - IE00BXDZNK39 ", c: "" },
  { id: 33692, s: "URE.MX", n: "ProShares Trust - ProShares Ultra Real Estate - US74347X6250 ", c: "" },
  { id: 33693, s: "URNM.LSE", n: "Sprott Uranium Miners UCITS ETF USD Accumulating - IE0005YK6564 ", c: "" },
  { id: 33694, s: "URNU.LSE", n: "Global X Uranium UCITS ETF USD Acc - IE000NDWFGA5 ", c: "" },
  { id: 33695, s: "URTH.MX", n: "iShares Inc. - iShares MSCI World ETF - US4642863926 ", c: "" },
  { id: 33696, s: "USA.PA", n: "Lyxor UCITS MSCI USA D-EUR - FR0010296061 ", c: "" },
  { id: 33697, s: "USAB.XETRA", n: "L&G ESG USD Corporate Bond UCITS ETF EUR - IE00BLRPRD67 ", c: "" },
  { id: 33698, s: "USAC.PA", n: "Amundi MSCI USA ESG Climate Net Zero Ambition CTB UCITS ETF Acc EUR - IE0006IP4XZ8 ", c: "" },
  { id: 33699, s: "USAPA.SW", n: "UBS (Irl) ETF plc - MSCI USA Climate Paris Aligned UCITS ETF A USD EUR - IE00BN4Q0602 ", c: "" },
  { id: 33700, s: "USAU.LSE", n: "Lyxor UCITS MSCI USA D-EUR - FR0010296061 ", c: "" },
  { id: 33701, s: "USCHWH.SW", n: "UBS (Irl) ETF plc - MSCI USA hedged to CHF UCITS ETF (CHF) A-acc - IE00BD4TYL27 ", c: "" },
  { id: 33702, s: "USDC.SW", n: "L&G ESG USD Corporate Bond UCITS ETF CHF - IE00BLRPRD67 ", c: "" },
  { id: 33703, s: "USESGA.SW", n: "UBS (Irl) ETF plc MSCI USA ESG Universal Low Carbon Select UCITS ETF A - IE00BNC0M350 ", c: "" },
  { id: 33704, s: "USEUWH.SW", n: "UBS (Irl) ETF plc - MSCI USA hedged to EUR UCITS ETF (EUR) A-acc - IE00BD4TYG73 ", c: "" },
  { id: 33705, s: "USEUYH.SW", n: "UBS (Irl) ETF plc - MSCI USA hedged to EUR UCITS ETF (EUR) A-dis - IE00BD4TYF66 ", c: "" },
  { id: 33706, s: "USGBPA.SW", n: "UBS (Irl) ETF plc - MSCI USA UCITS ETF (hedged to GBP) A-acc - IE00BYQ00Y50 ", c: "" },
  { id: 33707, s: "USIH.PA", n: "Lyxor Index Fund - Lyxor ESG USD Corporate Bond (DR) UCITS ETF - Monthly Hedged to EUR - Dist - LU1285960032 ", c: "" },
  { id: 33708, s: "USMUFE.SW", n: "UBS (Irl) ETF plc - MSCI USA Select Factor Mix UCITS ETF (hedged to EUR) A-acc - IE00BDGV0746 ", c: "" },
  { id: 33709, s: "USMUFS.SW", n: "UBS (Irl) ETF plc - MSCI USA Select Factor Mix UCITS ETF (hedged to CHF) A-acc - IE00BDGV0C91 ", c: "" },
  { id: 33710, s: "USMV.MX", n: "iShares Trust - iShares MSCI USA Min Vol Factor ETF - US46429B6974 ", c: "" },
  { id: 33711, s: "USO.MX", n: "United States Oil Fund LP - US91232N2071 ", c: "" },
  { id: 33712, s: "USPG.LSE", n: "UBS (Irl) ETF plc - S&P 500 UCITS ETF (hedged to GBP) A-dis - IE00BD34DL14 ", c: "" },
  { id: 33713, s: "USRI.PA", n: "AMUNDI MSCI USA SRI Climate Net Zero Ambition PAB UCITS ETF Acc EUR - IE000R85HL30 ", c: "" },
  { id: 33714, s: "USSRE.SW", n: "UBS (Irl) ETF plc - MSCI USA Socially Responsible UCITS ETF (hedged to EUR) A-dis - IE00BJXT3F26 ", c: "" },
  { id: 33715, s: "USSRF.SW", n: "UBS(Lux)Fund Solutions – MSCI USA Socially Responsible UCITS ETF(hedged EUR)A-dis - LU1280303014 ", c: "" },
  { id: 33716, s: "USSRIC.SW", n: "UBS (Irl) ETF plc - MSCI USA Socially Responsible UCITS ETF (hedged to CHF) A-acc - IE00BJXT3L85 ", c: "" },
  { id: 33717, s: "USSRS.SW", n: "UBS(Lux)Fund Solutions – MSCI USA Socially Responsible UCITS ETF(hedged CHF)A-acc - LU1273641503 ", c: "" },
  { id: 33718, s: "UST.PA", n: "Multi Units Luxembourg- Lyxor Nasdaq-100 UCITS ETF - LU1829221024 ", c: "" },
  { id: 33719, s: "USTEC.SW", n: "Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI USA Tech 125 ESG Universal Blue UCITS ETF B - IE000YKE1AQ5 ", c: "" },
  { id: 33720, s: "USTP.XETRA", n: "Ossiam US Steepener UCITS ETF 1C (USD) Cap - LU1965301184 ", c: "" },
  { id: 33721, s: "UUP.MX", n: "Invesco DB US Dollar Index Trust - Invesco DB US Dollar Index Bullish Fund - US73936D1072 ", c: "" },
  { id: 33722, s: "V20D.AS", n: "Vanguard LifeStrategy 20% Equity UCITS ETF (EUR) Distributing - IE00BMVB5L14 ", c: "" },
  { id: 33723, s: "V20D.XETRA", n: "Vanguard LifeStrategy 20% Equity UCITS ETF (EUR) Distributing - IE00BMVB5L14 ", c: "" },
  { id: 33724, s: "V3AA.LSE", n: "Vanguard ESG Global All Cap UCITS ETF (USD) Accumulating - IE00BNG8L278 ", c: "" },
  { id: 33725, s: "V3AL.LSE", n: "Vanguard ESG Global All Cap UCITS ETF (USD) Distributing - IE00BNG8L385 ", c: "" },
  { id: 33726, s: "V3AL.SW", n: "Vanguard ESG Global All Cap UCITS ETF (USD) Distributing CHF - IE00BNG8L385 ", c: "" },
  { id: 33727, s: "V3AL.XETRA", n: "Vanguard ESG Global All Cap UCITS ETF (USD) Distributing - IE00BNG8L385 ", c: "" },
  { id: 33728, s: "V3DA.XETRA", n: "Vanguard ESG Developed Europe All Cap UCITS ETF EUR Accumulation - IE000QUOSE01 ", c: "" },
  { id: 33729, s: "V3DL.XETRA", n: "Vanguard ESG Developed Europe All Cap UCITS ETF EUR Income - IE000NRGX9M3 ", c: "" },
  { id: 33730, s: "V3GE.XETRA", n: "Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF EUR Hedged Inc - IE00BNDS1Q47 ", c: "" },
  { id: 33731, s: "V3GF.XETRA", n: "Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF EUR Hedged Acc - IE00BNDS1P30 ", c: "" },
  { id: 33732, s: "V3GH.SW", n: "Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF CHF Hedged Inc - IE00BNDS1S60 ", c: "" },
  { id: 33733, s: "V3GX.SW", n: "Vanguard Funds PLC - Vanguard ESG Global Corporate Bond UCITS ETF CHF Hedged Acc - IE00BNDS1R53 ", c: "" },
  { id: 33734, s: "V3ML.XETRA", n: "Vanguard ESG Emerging Markets All Cap UCITS ETF USD Inc EUR - IE0001VXZTV7 ", c: "" },
  { id: 33735, s: "V3PL.XETRA", n: "Vanguard ESG Developed Asia Pacific All Cap UCITS ETF USD Income EUR - IE0008T6IUX0 ", c: "" },
  { id: 33736, s: "V3YL.XETRA", n: "Vanguard ESG North America All Cap UCITS ETF USD Income EUR - IE000L2ZNB07 ", c: "" },
  { id: 33737, s: "V40D.AS", n: "Vanguard LifeStrategy 40% Equity UCITS ETF (EUR) Distributing - IE00BMVB5N38 ", c: "" },
  { id: 33738, s: "V40D.XETRA", n: "Vanguard LifeStrategy 40% Equity UCITS ETF (EUR) Distributing - IE00BMVB5N38 ", c: "" },
  { id: 33739, s: "V60D.AS", n: "Vanguard LifeStrategy 60% Equity UCITS ETF (EUR) Distributing - IE00BMVB5Q68 ", c: "" },
  { id: 33740, s: "V60D.XETRA", n: "Vanguard LifeStrategy 60% Equity UCITS ETF (EUR) Distributing - IE00BMVB5Q68 ", c: "" },
  { id: 33741, s: "V80D.XETRA", n: "Vanguard LifeStrategy 80% Equity UCITS ETF (EUR) Distributing - IE00BMVB5S82 ", c: "" },
  { id: 33742, s: "VAW.MX", n: "Vanguard World Fund - Vanguard Materials ETF - US92204A8018 ", c: "" },
  { id: 33743, s: "VB.MX", n: "Vanguard Index Funds - Vanguard Small-Cap ETF - US9229087518 ", c: "" },
  { id: 33744, s: "VCR.MX", n: "Vanguard World Fund - Vanguard Consumer Discretionary ETF - US92204A1088 ", c: "" },
  { id: 33745, s: "VCSH.MX", n: "Vanguard Scottsdale Funds - Vanguard Short-Term Corporate Bond ETF - US92206C4096 ", c: "" },
  { id: 33746, s: "VDC.MX", n: "Vanguard World Fund - Vanguard Consumer Staples ETF - US92204A2078 ", c: "" },
  { id: 33747, s: "VDCE.XETRA", n: "Vanguard USD Corporate Bond UCITS ETF EUR Hedged Accumulation - IE00BGYWFL94 ", c: "" },
  { id: 33748, s: "VDE.MX", n: "Vanguard World Fund - Vanguard Energy ETF - US92204A3068 ", c: "" },
  { id: 33749, s: "VDTE.XETRA", n: "Vanguard USD Treasury Bond UCITS ETF EUR Hedged Accumulation - IE00BMX0B631 ", c: "" },
  { id: 33750, s: "VEA.MX", n: "Vanguard Tax-Managed Funds - Vanguard FTSE Developed Markets ETF - US9219438580 ", c: "" },
  { id: 33751, s: "VEU.MX", n: "Vanguard International Equity Index Funds - Vanguard FTSE All-World ex-US ETF - US9220427754 ", c: "" },
  { id: 33752, s: "VFEM.AS", n: "Vanguard FTSE Emerging Markets UCITS - IE00B3VVMM84 ", c: "" },
  { id: 33753, s: "VFH.MX", n: "Vanguard World Fund - Vanguard Financials ETF - US92204A4058 ", c: "" },
  { id: 33754, s: "VGK.MX", n: "Vanguard International Equity Index Funds - Vanguard FTSE Europe ETF - US9220428745 ", c: "" },
  { id: 33755, s: "VGSH.MX", n: "Vanguard Scottsdale Funds - Vanguard Short-Term Treasury ETF - US92206C1027 ", c: "" },
  { id: 33756, s: "VGT.MX", n: "Vanguard World Fund - Vanguard Information Technology ETF - US92204A7028 ", c: "" },
  { id: 33757, s: "VGUE.XETRA", n: "Vanguard U.K. Gilt UCITS ETF EUR Hedged Accumulation - IE00BMX0B524 ", c: "" },
  { id: 33758, s: "VHT.MX", n: "Vanguard World Fund - Vanguard Health Care ETF - US92204A5048 ", c: "" },
  { id: 33759, s: "VJPE.XETRA", n: "Vanguard FTSE Japan UCITS ETF EUR Hedged Accumulation - IE00BFMXYY33 ", c: "" },
  { id: 33760, s: "VJPU.LSE", n: "Vanguard FTSE Japan UCITS ETF USD Hedged Accumulation - IE00BFMXZJ56 ", c: "" },
  { id: 33761, s: "VLUE.MX", n: "iShares Trust - iShares MSCI USA Value Factor ETF - US46432F3881 ", c: "" },
  { id: 33762, s: "VNQ.MX", n: "Vanguard Specialized Funds - Vanguard Real Estate ETF - US9229085538 ", c: "" },
  { id: 33763, s: "VNQI.MX", n: "Vanguard International Equity Index Funds - Vanguard Global ex-U.S. Real Estate ETF - US9220426764 ", c: "" },
  { id: 33764, s: "VOLT.LSE", n: "WisdomTree Battery Solutions UCITS ETF - USD Acc - IE00BKLF1R75 ", c: "" },
  { id: 33765, s: "VOO.MX", n: "Vanguard Index Funds - Vanguard S&P 500 ETF - US9229083632 ", c: "" },
  { id: 33766, s: "VOOL.XETRA", n: "Lyxor S&P 500 VIX Futures Enhanced Roll UCITS ETF - C-EUR - LU0832435464 ", c: "" },
  { id: 33767, s: "VPAC.LSE", n: "Invesco Variable Rate Preferred Shares UCITS ETF USD - IE00BHJYDT11 ", c: "" },
  { id: 33768, s: "VPAC.SW", n: "Invesco Variable Rate Preferred Shares UCITS ETF USD USD - IE00BHJYDT11 ", c: "" },
  { id: 33769, s: "VPL.MX", n: "Vanguard International Equity Index Funds - Vanguard FTSE Pacific ETF - US9220428661 ", c: "" },
  { id: 33770, s: "VRPS.LSE", n: "Invesco Variable Rate Preferred Shares UCITS ETF - IE00BG21M733 ", c: "" },
  { id: 33771, s: "VRPS.SW", n: "Invesco Variable Rate Preferred Shares UCITS ETF USD - IE00BG21M733 ", c: "" },
  { id: 33772, s: "VT.MX", n: "Vanguard International Equity Index Funds - Vanguard Total World Stock ETF - US9220427424 ", c: "" },
  { id: 33773, s: "VTI.MX", n: "Vanguard Index Funds - Vanguard Total Stock Market ETF - US9229087690 ", c: "" },
  { id: 33774, s: "VTIP.MX", n: "Vanguard Malvern Funds - Vanguard Short-Term Inflation-Protected Securities ETF - US9220208055 ", c: "" },
  { id: 33775, s: "VTV.MX", n: "Vanguard Index Funds - Vanguard Value ETF - US9229087443 ", c: "" },
  { id: 33776, s: "VUG.MX", n: "Vanguard Index Funds - Vanguard Growth ETF - US9229087369 ", c: "" },
  { id: 33777, s: "VUTY.LSE", n: "Vanguard USD Treasury Bond UCITS USD - IE00BZ163M45 ", c: "" },
  { id: 33778, s: "VVSM.XETRA", n: "VanEck Semiconductor UCITS ETF - IE00BMC38736 ", c: "" },
  { id: 33779, s: "VWO.MX", n: "Vanguard International Equity Index Funds - Vanguard FTSE Emerging Markets ETF - US9220428588 ", c: "" },
  { id: 33780, s: "VWOB.MX", n: "Vanguard Whitehall Funds - Vanguard Emerging Markets Government Bond ETF - US9219468850 ", c: "" },
  { id: 33781, s: "VXUS.MX", n: "Vanguard STAR Funds - Vanguard Total International Stock ETF - US9219097683 ", c: "" },
  { id: 33782, s: "VYM.MX", n: "Vanguard Whitehall Funds - Vanguard High Dividend Yield ETF - US9219464065 ", c: "" },
  { id: 33783, s: "WCBR.LSE", n: "WisdomTree Cybersecurity UCITS ETF USD Acc - IE00BLPK3577 ", c: "" },
  { id: 33784, s: "WCMS.SW", n: "iShares MSCI World Communication Services Sector ESG UCITS ETF USD Inc USD - IE00BJ5JP436 ", c: "" },
  { id: 33785, s: "WCOA.LSE", n: "WisdomTree Enhanced Commodity UCITS ETF - USD Acc - IE00BYMLZY74 ", c: "" },
  { id: 33786, s: "WCOG.LSE", n: "WisdomTree Enhanced Commodity UCITS ETF - USD - IE00BZ1GHD37 ", c: "" },
  { id: 33787, s: "WCOM.LSE", n: "WisdomTree Enhanced Commodity UCITS ETF - GBP Hedged Acc - IE00BG88WH84 ", c: "" },
  { id: 33788, s: "WDESGC.SW", n: "Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI World ESG Leaders Blue UCITS ETF BH CHF - IE00BKKFT292 ", c: "" },
  { id: 33789, s: "WDESGE.SW", n: "Credit Suisse Index Fund (IE) ETF ICAV - CSIF (IE) MSCI World ESG Leaders Blue UCITS ETF BH EUR - IE00BKKFT300 ", c: "" },
  { id: 33790, s: "WDNA.LSE", n: "WisdomTree BioRevolution UCITS ETF - USD Acc - IE000O8KMPM1 ", c: "" },
  { id: 33791, s: "WELB.XETRA", n: "Amundi MSCI ACWI SRI PAB UCITS ETF DR USD Acc - IE0000ZVYDH0 ", c: "" },
  { id: 33792, s: "WELC.XETRA", n: "Amundi S&P Global Consumer Discretionary ESG UCITS ETFDR EUR Inc - IE00061J0RC6 ", c: "" },
  { id: 33793, s: "WELD.XETRA", n: "Amundi S&P Global Utilities ESG UCITS ETF DR EUR Acc - IE000PMX0MW6 ", c: "" },
  { id: 33794, s: "WELE.XETRA", n: "Amundi S&P 500 Equal Weight ESG Leaders UCITS ETF - IE000LAP5Z18 ", c: "" },
  { id: 33795, s: "WELG.XETRA", n: "Amundi S&P Global Health Care ESG UCITS ETF DR EUR Inc - IE000JKS50V3 ", c: "" },
  { id: 33796, s: "WELH.XETRA", n: "Amundi S&P Global Industrials ESG UCITS ETF DR EUR Acc - IE000LTA2082 ", c: "" },
  { id: 33797, s: "WELI.XETRA", n: "Amundi S&P Global Materials ESG UCITS ETF DR EUR Acc - IE000FCGBU62 ", c: "" },
  { id: 33798, s: "WELJ.XETRA", n: "Amundi S&P Global Consumer Discretionary ESG UCITS ETF DR EUR Acc - IE000NM0ALX6 ", c: "" },
  { id: 33799, s: "WELK.XETRA", n: "Amundi S&P Global Financials ESG UCITS ETF DR EUR Acc - IE000KYX7IP4 ", c: "" },
  { id: 33800, s: "WELL.XETRA", n: "HAN-GINS Indxx Healthcare Megatrend Equal Weight UCITS ETF - IE000GEHNQU9 ", c: "" },
  { id: 33801, s: "WELM.XETRA", n: "Amundi S&P Global Consumer Staples ESG UCITS ETF DR EUR Inc - IE0005NYD352 ", c: "" },
  { id: 33802, s: "WELN.XETRA", n: "Amundi S&P Global Energy Carbon Reduced UCITS ETF DR EUR Acc - IE000J0LN0R5 ", c: "" },
  { id: 33803, s: "WELP.XETRA", n: "Amundi S&P Global Energy Carbon Reduced UCITS ETF DR EUR Inc - IE0009SJ3GE3 ", c: "" },
  { id: 33804, s: "WELQ.XETRA", n: "Amundi S&P Global Utilities ESG UCITS ETF DR EUR Inc - IE00052T92P8 ", c: "" },
  { id: 33805, s: "WELR.XETRA", n: "Amundi S&P Global Communication Services ESG UCITS ETF DR EUR Inc - IE000ANYHV73 ", c: "" },
  { id: 33806, s: "WELS.XETRA", n: "Amundi S&P Global Health Care ESG UCITS ETF DR EUR Acc - IE0006FM6MI8 ", c: "" },
  { id: 33807, s: "WELT.XETRA", n: "Amundi S&P Global Industrials ESG UCITS ETF DR EUR Inc - IE00026BEVM6 ", c: "" },
  { id: 33808, s: "WELU.XETRA", n: "Amundi S&P Global Information Technology ESG UCITS ETF DR EUR Acc - IE000E7EI9P0 ", c: "" },
  { id: 33809, s: "WELV.XETRA", n: "Amundi S&P Global Materials ESG UCITS ETF DR EUR Inc - IE000WP7CVZ7 ", c: "" },
  { id: 33810, s: "WELW.XETRA", n: "Amundi S&P Global Consumer Staples ESG UCITS ETF DR EUR Acc - IE000ZIJ5B20 ", c: "" },
  { id: 33811, s: "WELX.XETRA", n: "Amundi S&P Global Communication Services ESG UCITS ETF DR EUR Acc - IE000EFHIFG3 ", c: "" },
  { id: 33812, s: "WELY.XETRA", n: "Amundi S&P Global Financials ESG UCITS ETF DR EUR Inc - IE000ENYES77 ", c: "" },
  { id: 33813, s: "WENE.SW", n: "iShares MSCI World Energy Sector ESG UCITS ETF USD Inc USD - IE000MELAE65 ", c: "" },
  { id: 33814, s: "WFNS.AS", n: "iShares MSCI World Financials Sector ESG UCITS ETF USD Inc USD - IE00BJ5JP097 ", c: "" },
  { id: 33815, s: "WGES.LSE", n: "Lyxor MSCI World ESG Leaders Extra (DR) UCITS ETF - Inc - LU1799934499 ", c: "" },
  { id: 33816, s: "WGES.XETRA", n: "Lyxor MSCI World ESG Leaders Extra (DR) UCITS ETF - Inc EUR - LU1799934499 ", c: "" },
  { id: 33817, s: "WING.LSE", n: "iShares Fallen Angels High Yield Corp Bond UCITS ETF USD (Dist) - IE00BYM31M36 ", c: "" },
  { id: 33818, s: "WINS.AS", n: "iShares MSCI World Industrials Sector ESG UCITS ETF - IE00BJ5JP659 ", c: "" },
  { id: 33819, s: "WLD.PA", n: "Lyxor UCITS MSCI World D-EUR - FR0010315770 ", c: "" },
  { id: 33820, s: "WLDH.PA", n: "Lyxor MSCI World UCITS Monthly Hedged D - FR0011660927 ", c: "" },
  { id: 33821, s: "WLDU.LSE", n: "Lyxor MSCI World UCITS ETF Monthly Hedged to USD Dist - FR0011669845 ", c: "" },
  { id: 33822, s: "WMTS.SW", n: "iShares MSCI World Materials Sector ESG UCITS ETF USD Inc USD - IE00BJ5JP766 ", c: "" },
  { id: 33823, s: "WMVG.LSE", n: "iShares Edge MSCI World Minimum Volatility UCITS ETF GBP Hedged (Acc) - IE00BGPP8L80 ", c: "" },
  { id: 33824, s: "WNDY.LSE", n: "Global X Wind Energy UCITS ETF USD Acc - IE000JNHCBM6 ", c: "" },
  { id: 33825, s: "WNER.LSE", n: "WisdomTree New Economy Real Estate UCITS ETF - USD - IE000X9TLGN8 ", c: "" },
  { id: 33826, s: "WOOD.MX", n: "iShares Trust - iShares Global Timber & Forestry ETF - US4642881746 ", c: "" },
  { id: 33827, s: "WOPA.SW", n: "UBS (Irl) ETF plc - MSCI World Climate Paris Aligned UCITS ETF A USD EUR - IE00BN4Q0370 ", c: "" },
  { id: 33828, s: "WPAB.AS", n: "iShares MSCI World Paris-Aligned Climate UCITS ETF USD Acc - IE00BMXC7W70 ", c: "" },
  { id: 33829, s: "WPAD.AS", n: "iShares MSCI World Paris-Aligned Climate UCITS ETF USD Inc - IE00BN92ZL31 ", c: "" },
  { id: 33830, s: "WRCY.LSE", n: "WisdomTree Recycling Decarbonisation UCITS ETF USD Acc - IE000LG4J7E7 ", c: "" },
  { id: 33831, s: "WRDUSW.SW", n: "UBS (Irl) ETF plc - MSCI World UCITS ETF (USD) A-acc - IE00BD4TXV59 ", c: "" },
  { id: 33832, s: "WRLD.XETRA", n: "Rize Environmental Impact 100 UCITS ETF EUR - IE00BLRPRR04 ", c: "" },
  { id: 33833, s: "WSCR.LSE", n: "UBS (Irl) ETF plc - MSCI World Small Cap Socially Responsible UCITS ETF USD A Dis GBP - IE00BKSCBW67 ", c: "" },
  { id: 33834, s: "WSCSRI.SW", n: "UBS (Irl) ETF plc - UBS (Irl) ETF plc MSCI World Small Cap Socially Responsible UCITS ETF USD A Acc - IE00BKSCBX74 ", c: "" },
  { id: 33835, s: "WSRIC.SW", n: "UBS (Irl) ETF plc – MSCI World Socially Responsible UCITS ETF (hedged to CHF) A-acc - IE00BK72HT65 ", c: "" },
  { id: 33836, s: "WTAI.LSE", n: "WisdomTree Artificial Intelligence UCITS ETF - USD Acc - IE00BDVPNG13 ", c: "" },
  { id: 33837, s: "WTDH.F", n: "WisdomTree Europe Equity UCITS ETF - USD Hedged - IE00BVXBH163 ", c: "" },
  { id: 33838, s: "WTDH.XETRA", n: "WisdomTree Europe Equity UCITS ETF - USD Hedged - IE00BVXBH163 ", c: "" },
  { id: 33839, s: "WTDI.XETRA", n: "WisdomTree AT1 CoCo Bond UCITS ETF - USD - IE00BZ0XVF52 ", c: "" },
  { id: 33840, s: "WTDX.F", n: "WisdomTree Japan Equity UCITS ETF - USD Hedged - IE00BVXC4854 ", c: "" },
  { id: 33841, s: "WTDX.XETRA", n: "WisdomTree Japan Equity UCITS ETF - USD Hedged - IE00BVXC4854 ", c: "" },
  { id: 33842, s: "WTDY.F", n: "WisdomTree Issuer ICAV - WisdomTree US Equity Income UCITS ETF - IE00BD6RZW23 ", c: "" },
  { id: 33843, s: "WTDY.XETRA", n: "WisdomTree Issuer ICAV - WisdomTree US Equity Income UCITS ETF - IE00BD6RZW23 ", c: "" },
  { id: 33844, s: "WTEB.XETRA", n: "WisdomTree Issuer ICAV - WisdomTree AT1 CoCo Bond UCITS ETF - IE00BFNNN236 ", c: "" },
  { id: 33845, s: "WTER.XETRA", n: "WisdomTree New Economy Real Estate UCITS ETF - USD EUR - IE000X9TLGN8 ", c: "" },
  { id: 33846, s: "WTIF.F", n: "WisdomTree Issuer ICAV - WisdomTree Japan Equity UCITS ETF - IE00BYQCZJ13 ", c: "" },
  { id: 33847, s: "WTRE.LSE", n: "WisdomTree New Economy Real Estate UCITS ETF - USD Acc - IE000MO2MB07 ", c: "" },
  { id: 33848, s: "WYLD.SW", n: "WisdomTree EUR Aggregate Bond ESG Enhanced Yield UCITS ETF - EUR EUR - IE00BD49R912 ", c: "" },
  { id: 33849, s: "X014.XETRA", n: "Amundi Index Solutions - Amundi MSCI Pacific ESG Climate Net Zero Ambition CTB ETF EUR - LU2572257397 ", c: "" },
  { id: 33850, s: "X026.XETRA", n: "Amundi MSCI Europe Small Cap ESG Climate Net Zero Ambition CTB ETF - LU2572257470 ", c: "" },
  { id: 33851, s: "XAAG.XETRA", n: "Invesco Bloomberg Commodity ex-Agriculture UCITS ETF - IE00BYXYX521 ", c: "" },
  { id: 33852, s: "XAT1.SW", n: "Invesco AT1 Capital Bond ETF EUR Hedged Dist EUR - IE00BFZPF439 ", c: "" },
  { id: 33853, s: "XAT1.XETRA", n: "Invesco AT1 Capital Bond ETF EUR Hedged Dist - IE00BFZPF439 ", c: "" },
  { id: 33854, s: "XBAK.F", n: "Xtrackers MSCI Pakistan Swap UCITS ETF 1C - LU0659579147 ", c: "" },
  { id: 33855, s: "XBAK.LSE", n: "Xtrackers MSCI Pakistan Swap UCITS ETF 1C USD - LU0659579147 ", c: "" },
  { id: 33856, s: "XBAK.XETRA", n: "Xtrackers MSCI Pakistan Swap UCITS ETF 1C - LU0659579147 ", c: "" },
  { id: 33857, s: "XBCU.LSE", n: "db x-trackers DBLCI - OY Balanced UCITS 2C - LU0460391732 ", c: "" },
  { id: 33858, s: "XBGG.LSE", n: "db x-trackers II Barclays Global Aggregate Bond UCITS 3D - LU0942970368 ", c: "" },
  { id: 33859, s: "XBI.MX", n: "SPDR Series Trust - SPDR S&P Biotech ETF - US78464A8707 ", c: "" },
  { id: 33860, s: "XBO2.F", n: "Xtrackers II - Italy Government Bond 0-1 Swap UCITS ETF - LU0613540268 ", c: "" },
  { id: 33861, s: "XBO2.XETRA", n: "Xtrackers II Italy Government Bond 0-1 Swap UCITS ETF 1C - LU0613540268 ", c: "" },
  { id: 33862, s: "XCS2.F", n: "Xtrackers II - Australia Government Bond UCITS ETF - LU0494592974 ", c: "" },
  { id: 33863, s: "XCS3.F", n: "Xtrackers - MSCI Malaysia UCITS ETF - LU0514694370 ", c: "" },
  { id: 33864, s: "XCS3.LSE", n: "Xtrackers MSCI Malaysia UCITS ETF 1C - LU0514694370 ", c: "" },
  { id: 33865, s: "XCS3.XETRA", n: "Xtrackers MSCI Malaysia UCITS ETF 1C - LU0514694370 ", c: "" },
  { id: 33866, s: "XCS7.LSE", n: "Xtrackers MSCI China UCITS ETF 1D - LU2456436083 ", c: "" },
  { id: 33867, s: "XCS7.XETRA", n: "Xtrackers MSCI China UCITS ETF 1D EUR - LU2456436083 ", c: "" },
  { id: 33868, s: "XCTE.LSE", n: "Xtrackers Harvest MSCI China Tech 100 UCITS ETF 1C - LU2376679564 ", c: "" },
  { id: 33869, s: "XCTE.SW", n: "Xtrackers Harvest MSCI China Tech 100 UCITS ETF 1C CHF - LU2376679564 ", c: "" },
  { id: 33870, s: "XCTE.XETRA", n: "Xtrackers Harvest MSCI China Tech 100 UCITS ETF - LU2376679564 ", c: "" },
  { id: 33871, s: "XCX3.LSE", n: "Xtrackers MSCI Malaysia UCITS ETF 1C - LU0514694370 ", c: "" },
  { id: 33872, s: "XD5D.LSE", n: "db x-trackers MSCI EMU UCITS USD - LU1127514245 ", c: "" },
  { id: 33873, s: "XD5D.SW", n: "Xtrackers MSCI EMU UCITS ETF 1C - USD Hedged USD - LU1127514245 ", c: "" },
  { id: 33874, s: "XD5S.LSE", n: "db x-trackers MSCI EMU UCITS DR 2C - LU1127516455 ", c: "" },
  { id: 33875, s: "XD9C.SW", n: "Xtrackers MSCI USA UCITS ETF 3C - CHF Hedged - IE00BG04LZ52 ", c: "" },
  { id: 33876, s: "XD9D.SW", n: "Xtrackers MSCI USA UCITS ETF 1D - IE00BK1PV445 ", c: "" },
  { id: 33877, s: "XD9D.XETRA", n: "Xtrackers MSCI USA UCITS ETF 1D - IE00BK1PV445 ", c: "" },
  { id: 33878, s: "XD9E.XETRA", n: "Xtrackers MSCI USA UCITS ETF 2C - EUR Hedged - IE00BG04M077 ", c: "" },
  { id: 33879, s: "XDBG.LSE", n: "Xtrackers - Bloomberg Commodity ex-Agriculture & Livestock Swap UCITS ETF - LU0460391906 ", c: "" },
  { id: 33880, s: "XDCH.SW", n: "Xtrackers DAX ESG Screened UCITS ETF 4C CHF Hedged - LU1221102491 ", c: "" },
  { id: 33881, s: "XDDA.XETRA", n: "Xtrackers DAX UCITS ETF 1D EUR - LU1349386927 ", c: "" },
  { id: 33882, s: "XDEE.XETRA", n: "Xtrackers S&P 500 Equal Weight UCITS ETF 2C EUR Hedged - IE0002EI5AG0 ", c: "" },
  { id: 33883, s: "XDEW.XETRA", n: "Xtrackers S&P 500 Equal Weight UCITS ETF 1C - IE00BLNMYC90 ", c: "" },
  { id: 33884, s: "XDGE.F", n: "Xtrackers (IE) Public Limited Company - Xtrackers USD Corporate Bond UCITS ETF - IE00BZ036J45 ", c: "" },
  { id: 33885, s: "XDGE.XETRA", n: "db x-trackers USD Corporate Bond UCITS DR 2D Hedged - IE00BZ036J45 ", c: "" },
  { id: 33886, s: "XDJE.XETRA", n: "Xtrackers Nikkei 225 UCITS ETF 2D EUR Hedged - LU1875395870 ", c: "" },
  { id: 33887, s: "XDNE.F", n: "db x-trackers JPX-Nikkei 400 UCITS DR 3C - IE00BRB36B93 ", c: "" },
  { id: 33888, s: "XDNE.XETRA", n: "db x-trackers JPX-Nikkei 400 UCITS DR 3C - IE00BRB36B93 ", c: "" },
  { id: 33889, s: "XDNG.LSE", n: "db x-trackers JPX-Nikkei 400 UCITS - IE00BPVLQF37 ", c: "" },
  { id: 33890, s: "XDNU.LSE", n: "db x-trackers JPX-Nikkei 400 UCITS DR 4C - IE00BTGD1B38 ", c: "" },
  { id: 33891, s: "XDPC.SW", n: "Xtrackers S&P 500 UCITS ETF 3C - CHF Hedged - IE00B8KMSQ34 ", c: "" },
  { id: 33892, s: "XDPD.XETRA", n: "Xtrackers S&P 500 UCITS ETF 1D - EUR Hedged - IE00BGJWX091 ", c: "" },
  { id: 33893, s: "XDPE.F", n: "Xtrackers (IE) Public Limited Company - Xtrackers S&P 500 UCITS ETF - IE00BM67HW99 ", c: "" },
  { id: 33894, s: "XDPE.XETRA", n: "Xtrackers S&P 500 UCITS ETF 1C - EUR Hedged - IE00BM67HW99 ", c: "" },
  { id: 33895, s: "XDPG.LSE", n: "Xtrackers (IE) Public Limited Company - Xtrackers S&P 500 UCITS ETF - IE00BM67HX07 ", c: "" },
  { id: 33896, s: "XDPU.LSE", n: "Xtrackers S&P 500 UCITS ETF 4C - USD - IE000Z9SJA06 ", c: "" },
  { id: 33897, s: "XDPU.XETRA", n: "Xtrackers S&P 500 UCITS ETF 4C - USD EUR - IE000Z9SJA06 ", c: "" },
  { id: 33898, s: "XDUD.SW", n: "Xtrackers DAX ESG Screened UCITS ETF 2C USD Hedged - LU1221100792 ", c: "" },
  { id: 33899, s: "XDWG.LSE", n: "Xtrackers MSCI World UCITS ETF - IE00BZ1BS790 ", c: "" },
  { id: 33900, s: "XDWS.XETRA", n: "Xtrackers MSCI World Consumer Staples UCITS ETF 1C - IE00BM67HN09 ", c: "" },
  { id: 33901, s: "XEMD.LSE", n: "Xtrackers MSCI Emerging Markets UCITS ETF 1D - IE000GWA2J58 ", c: "" },
  { id: 33902, s: "XEMD.XETRA", n: "Xtrackers MSCI Emerging Markets UCITS ETF 1D - IE000GWA2J58 ", c: "" },
  { id: 33903, s: "XEON.SW", n: "Xtrackers II EUR Overnight Rate Swap UCITS ETF 1C EUR - LU0290358497 ", c: "" },
  { id: 33904, s: "XEOU.LSE", n: "db x-trackers MSCI Europe UCITS - LU1184092051 ", c: "" },
  { id: 33905, s: "XEOU.SW", n: "Xtrackers MSCI Europe UCITS ETF 2C - USD Hedged - LU1184092051 ", c: "" },
  { id: 33906, s: "XEPA.XETRA", n: "Xtrackers Europe Net Zero Pathway Paris Aligned UCITS ETF EUR Acc - IE0001JH5CB4 ", c: "" },
  { id: 33907, s: "XESX.SW", n: "Xtrackers Euro Stoxx 50 UCITS ETF 1D CHF - LU0274211217 ", c: "" },
  { id: 33908, s: "XEWG.LSE", n: "Xtrackers S&P 500 Equal Weight UCITS ETF 1D GBP Hedged - IE000N5GJDD7 ", c: "" },
  { id: 33909, s: "XFFE.LSE", n: "Xtrackers II - USD Overnight Rate Swap UCITS ETF - LU0321465469 ", c: "" },
  { id: 33910, s: "XFNT.XETRA", n: "Xtrackers MSCI Fintech Innovation UCITS ETF 1C USD Acc - IE000YDOORK7 ", c: "" },
  { id: 33911, s: "XG7G.SW", n: "Xtrackers II Global Inflation-Linked Bond UCITS ETF 4D - CHF Hedged - LU0641007421 ", c: "" },
  { id: 33912, s: "XG7U.LSE", n: "db x-trackers II Global Inflation-Linked UCITS 2C - LU0641007009 ", c: "" },
  { id: 33913, s: "XGBE.XETRA", n: "Xtrackers EUR Corporate Green Bond UCITS ETF 1C - IE000MCVFK47 ", c: "" },
  { id: 33914, s: "XGDD.LSE", n: "Xtrackers - Stoxx Global Select Dividend 100 Swap UCITS ETF - LU0292096186 ", c: "" },
  { id: 33915, s: "XGEN.XETRA", n: "Xtrackers MSCI Genomic Healthcare Innovation UCITS ETF 1C USD Acc - IE000KD0BZ68 ", c: "" },
  { id: 33916, s: "XGLU.XETRA", n: "Xtrackers II Eurozone Government Bond UCITS ETF 2C USD Hedged - LU2009147591 ", c: "" },
  { id: 33917, s: "XGUE.XETRA", n: "Xtrackers USD Corporate Green Bond UCITS ETF 1C EUR Hedged Acc - IE00028H9QJ8 ", c: "" },
  { id: 33918, s: "XGVD.XETRA", n: "Xtrackers II Global Government Bond UCITS ETF 1D - EUR Hedged - LU0690964092 ", c: "" },
  { id: 33919, s: "XHYG.XETRA", n: "Xtrackers II EUR High Yield Corporate Bond UCITS ETF 1D - LU1109942653 ", c: "" },
  { id: 33920, s: "XLB.MX", n: "The Select Sector SPDR Trust - The Materials Select Sector SPDR Fund - US81369Y1001 ", c: "" },
  { id: 33921, s: "XLDX.LSE", n: "Xtrackers LevDAX Daily Swap UCITS ETF 1C - LU0411075376 ", c: "" },
  { id: 33922, s: "XLDX.SW", n: "Xtrackers LevDAX Daily Swap UCITS ETF 1C CHF - LU0411075376 ", c: "" },
  { id: 33923, s: "XLE.MX", n: "The Select Sector SPDR Trust - The Energy Select Sector SPDR Fund - US81369Y5069 ", c: "" },
  { id: 33924, s: "XLF.MX", n: "The Select Sector SPDR Trust - The Financial Select Sector SPDR Fund - US81369Y6059 ", c: "" },
  { id: 33925, s: "XLFS.LSE", n: "Invesco Financials S&P US Select Sector UCITS ETF - IE00B42Q4896 ", c: "" },
  { id: 33926, s: "XLI.MX", n: "The Select Sector SPDR Trust - The Industrial Select Sector SPDR Fund - US81369Y7040 ", c: "" },
  { id: 33927, s: "XLK.MX", n: "The Select Sector SPDR Trust - The Technology Select Sector SPDR Fund - US81369Y8030 ", c: "" },
  { id: 33928, s: "XLP.MX", n: "The Select Sector SPDR Trust - The Consumer Staples Select Sector SPDR Fund - US81369Y3080 ", c: "" },
  { id: 33929, s: "XLU.MX", n: "The Select Sector SPDR Trust - The Utilities Select Sector SPDR Fund - US81369Y8865 ", c: "" },
  { id: 33930, s: "XLV.MX", n: "The Select Sector SPDR Trust - The Health Care Select Sector SPDR Fund - US81369Y2090 ", c: "" },
  { id: 33931, s: "XLY.MX", n: "The Select Sector SPDR Trust - The Consumer Discretionary Select Sector SPDR Fund - US81369Y4070 ", c: "" },
  { id: 33932, s: "XMAE.XETRA", n: "Xtrackers MSCI AC World ESG Screened UCITS ETF 2C - EUR Hedged - IE000VXC51U5 ", c: "" },
  { id: 33933, s: "XMAU.LSE", n: "Xtrackers MSCI AC World ESG Screened UCITS ETF 5C - USD Hedged - IE000WGF1X01 ", c: "" },
  { id: 33934, s: "XMAW.XETRA", n: "Xtrackers MSCI AC World ESG Screened UCITS ETF 1C - IE00BGHQ0G80 ", c: "" },
  { id: 33935, s: "XMCJ.SW", n: "Xtrackers MSCI Japan UCITS ETF 7C CHF Hedged - LU1215827756 ", c: "" },
  { id: 33936, s: "XMCX.LSE", n: "Xtrackers FTSE 250 UCITS ETF 1D - LU0292097317 ", c: "" },
  { id: 33937, s: "XME.MX", n: "SPDR Series Trust - SPDR S&P Metals & Mining ETF - US78464A7550 ", c: "" },
  { id: 33938, s: "XMES.LSE", n: "Xtrackers - MSCI Mexico UCITS ETF - LU0476289466 ", c: "" },
  { id: 33939, s: "XMEX.LSE", n: "Xtrackers MSCI Mexico UCITS ETF 1C - LU0476289466 ", c: "" },
  { id: 33940, s: "XMEX.SW", n: "Xtrackers MSCI Mexico UCITS ETF 1C CHF - LU0476289466 ", c: "" },
  { id: 33941, s: "XMID.LSE", n: "Xtrackers MSCI Indonesia Swap UCITS ETF 1C - LU0476289623 ", c: "" },
  { id: 33942, s: "XMK9.F", n: "Xtrackers - MSCI Japan UCITS ETF - LU0659580079 ", c: "" },
  { id: 33943, s: "XMK9.XETRA", n: "Xtrackers MSCI Japan UCITS ETF 4C EUR Hedged - LU0659580079 ", c: "" },
  { id: 33944, s: "XMLA.LSE", n: "Xtrackers MSCI EM Latin America ESG Swap UCITS ETF 1C - LU0292108619 ", c: "" },
  { id: 33945, s: "XMOV.SW", n: "Xtrackers Future Mobility UCITS ETF 1C - IE00BGV5VR99 ", c: "" },
  { id: 33946, s: "XMTW.LSE", n: "Xtrackers MSCI Taiwan UCITS ETF 1C - LU0292109187 ", c: "" },
  { id: 33947, s: "XMUJ.LSE", n: "Xtrackers - MSCI Japan UCITS ETF - LU0927735406 ", c: "" },
  { id: 33948, s: "XMXD.LSE", n: "db x-trackers MSCI EM EMEA UCITS - LU0292109005 ", c: "" },
  { id: 33949, s: "XNAS.LSE", n: "Xtrackers NASDAQ 100 UCITS ETF - IE00BMFKG444 ", c: "" },
  { id: 33950, s: "XNAS.SW", n: "Xtrackers NASDAQ 100 UCITS ETF 1C CHF - IE00BMFKG444 ", c: "" },
  { id: 33951, s: "XNAS.XETRA", n: "Xtrackers NASDAQ 100 UCITS ETF 1C - IE00BMFKG444 ", c: "" },
  { id: 33952, s: "XNGI.XETRA", n: "Xtrackers MSCI Next Generation Internet Innovation UCITS ETF 1C USD Acc - IE000XOQ9TK4 ", c: "" },
  { id: 33953, s: "XNNV.XETRA", n: "Xtrackers MSCI Innovation UCITS ETF 1C USD Acc - IE0006FFX5U1 ", c: "" },
  { id: 33954, s: "XNZE.XETRA", n: "Xtrackers EMU Net Zero Pathway Paris Aligned UCITS ETF - IE000Y6L6LE6 ", c: "" },
  { id: 33955, s: "XNZW.LSE", n: "Xtrackers World Net Zero Pathway Paris Aligned UCITS ETF 1C - IE000UZCJS58 ", c: "" },
  { id: 33956, s: "XNZW.SW", n: "Xtrackers World Net Zero Pathway Paris Aligned UCITS ETF 1C CHF - IE000UZCJS58 ", c: "" },
  { id: 33957, s: "XNZW.XETRA", n: "Xtrackers World Net Zero Pathway Paris Aligned UCITS ETF - IE000UZCJS58 ", c: "" },
  { id: 33958, s: "XOP.MX", n: "SPDR Series Trust - SPDR S&P Oil & Gas Exploration & Production ETF - US78464A7303 ", c: "" },
  { id: 33959, s: "XPHG.LSE", n: "Xtrackers MSCI Philippines UCITS ETF 1C - LU0592215403 ", c: "" },
  { id: 33960, s: "XPHI.LSE", n: "Xtrackers MSCI Philippines UCITS ETF 1C - LU0592215403 ", c: "" },
  { id: 33961, s: "XPQP.F", n: "Xtrackers MSCI Philippines UCITS ETF 1C - LU0592215403 ", c: "" },
  { id: 33962, s: "XPQP.XETRA", n: "Xtrackers MSCI Philippines UCITS ETF 1C - LU0592215403 ", c: "" },
  { id: 33963, s: "XREA.XETRA", n: "db x-trackers FTSE Developed Europe Ex UK Property UCITS DR 1C - IE00BP8FKB21 ", c: "" },
  { id: 33964, s: "XRME.XETRA", n: "Xtrackers MSCI USA ESG Screened UCITS ETF 2C EUR Hedged - IE000TSML5I8 ", c: "" },
  { id: 33965, s: "XRS2.XETRA", n: "Xtrackers Russell 2000 UCITS ETF 1C - IE00BJZ2DD79 ", c: "" },
  { id: 33966, s: "XS2D.LSE", n: "Xtrackers S&P 500 2x Leveraged Daily Swap UCITS ETF 1C - LU0411078552 ", c: "" },
  { id: 33967, s: "XS5E.XETRA", n: "Xtrackers S&P 500 Swap UCITS ETF 5C - EUR Hedged - LU2196472984 ", c: "" },
  { id: 33968, s: "XS5G.LSE", n: "Xtrackers S&P 500 Swap UCITS ETF 7C - GBP Hedged - LU2196473016 ", c: "" },
  { id: 33969, s: "XS7R.LSE", n: "Xtrackers MSCI Europe Financials ESG Screened UCITS ETF 1C - LU0292103651 ", c: "" },
  { id: 33970, s: "XSCD.LSE", n: "Xtrackers MSCI USA Consumer Discretionary UCITS ETF 1D - IE00BGQYRR35 ", c: "" },
  { id: 33971, s: "XSD.MX", n: "SPDR Series Trust - SPDR S&P Semiconductor ETF - US78464A8624 ", c: "" },
  { id: 33972, s: "XSD2.LSE", n: "db x-trackers ShortDAX x2 Daily UCITS 1C - LU0411075020 ", c: "" },
  { id: 33973, s: "XSDX.LSE", n: "Xtrackers ShortDAX Daily Swap UCITS ETF 1C - LU0292106241 ", c: "" },
  { id: 33974, s: "XSDX.SW", n: "Xtrackers ShortDAX Daily Swap UCITS ETF 1C CHF - LU0292106241 ", c: "" },
  { id: 33975, s: "XSEN.LSE", n: "db x-trackers MSCI USA Energy UCITS DR - IE00BCHWNS19 ", c: "" },
  { id: 33976, s: "XSFN.LSE", n: "db x-trackers MSCI USA Financials UCITS DR - IE00BCHWNT26 ", c: "" },
  { id: 33977, s: "XSGI.LSE", n: "Xtrackers S&P Global Infrastructure Swap UCITS ETF 1C - LU0322253229 ", c: "" },
  { id: 33978, s: "XSPD.LSE", n: "Xtrackers - S&P 500 Inverse Daily Swap UCITS ETF - LU0322251520 ", c: "" },
  { id: 33979, s: "XSPS.LSE", n: "Xtrackers S&P 500 Inverse Daily Swap UCITS ETF 1C - LU0322251520 ", c: "" },
  { id: 33980, s: "XSSX.LSE", n: "Xtrackers - Euro Stoxx 50 Short Daily Swap UCITS ETF - LU0292106753 ", c: "" },
  { id: 33981, s: "XSXD.LSE", n: "Xtrackers S&P 500 Swap UCITS ETF 1D - LU2009147757 ", c: "" },
  { id: 33982, s: "XSXD.XETRA", n: "Xtrackers S&P 500 Swap UCITS ETF 1D EUR - LU2009147757 ", c: "" },
  { id: 33983, s: "XSXE.XETRA", n: "Xtrackers Stoxx Europe 600 UCITS ETF 2C - EUR Hedged - LU1772333404 ", c: "" },
  { id: 33984, s: "XT2D.LSE", n: "db x-trackers S&P 500 2x Inverse Daily UCITS 1C - LU0411078636 ", c: "" },
  { id: 33985, s: "XU61.XETRA", n: "BNP Paribas Easy ECPI Global ESG Infrastructure UCITS ETF Capitalisation - LU1291109293 ", c: "" },
  { id: 33986, s: "XUCM.LSE", n: "Xtrackers MSCI USA Communication Services UCITS ETF 1D - IE00BNC1G707 ", c: "" },
  { id: 33987, s: "XUCM.XETRA", n: "Xtrackers MSCI USA Communication Services UCITS ETF 1D - IE00BNC1G707 ", c: "" },
  { id: 33988, s: "XUIN.LSE", n: "Xtrackers MSCI USA Industrials UCITS ETF 1D USD - IE00BCHWNV48 ", c: "" },
  { id: 33989, s: "XUIN.XETRA", n: "Xtrackers MSCI USA Industrials UCITS ETF 1D - IE00BCHWNV48 ", c: "" },
  { id: 33990, s: "XUKS.LSE", n: "db x-trackers FTSE 100 Short Daily UCITS - LU0328473581 ", c: "" },
  { id: 33991, s: "XUSD.LSE", n: "Xtrackers MSCI USA Swap UCITS ETF 1D - LU2581375073 ", c: "" },
  { id: 33992, s: "XUTE.F", n: "Xtrackers II - US Treasuries UCITS ETF - LU1399300455 ", c: "" },
  { id: 33993, s: "XUTE.XETRA", n: "db x-trackers II iBoxx $ Treasuries UCITS DR 2D - LU1399300455 ", c: "" },
  { id: 33994, s: "XWEH.F", n: "Xtrackers - MSCI World Swap UCITS ETF - LU0659579733 ", c: "" },
  { id: 33995, s: "XWEH.XETRA", n: "db x-trackers MSCI World UCITS 4C - LU0659579733 ", c: "" },
  { id: 33996, s: "XYLE.XETRA", n: "Xtrackers ESG USD Corporate Bond Short Duration UCITS ETF 2C - EUR Hedged - IE00BFMKQ930 ", c: "" },
  { id: 33997, s: "XZBE.XETRA", n: "Xtrackers ESG USD Corporate Bond UCITS ETF 2C - EUR hedged - IE00BL58LL31 ", c: "" },
  { id: 33998, s: "XZEC.XETRA", n: "Xtrackers MSCI Europe Consumer Discretionary ESG Screened UCITS ETF - IE00BNKF6C99 ", c: "" },
  { id: 33999, s: "XZEZ.SW", n: "Xtrackers MSCI EMU ESG UCITS ETF 1C CHF - IE00BNC1G699 ", c: "" },
  { id: 34000, s: "XZEZ.XETRA", n: "Xtrackers MSCI EMU ESG UCITS ETF 1C - IE00BNC1G699 ", c: "" },
  { id: 34001, s: "XZMD.LSE", n: "Xtrackers MSCI USA ESG UCITS ETF 1D - IE000UMV0L21 ", c: "" },
  { id: 34002, s: "XZMD.XETRA", n: "Xtrackers MSCI USA ESG UCITS ETF 1D EUR - IE000UMV0L21 ", c: "" },
  { id: 34003, s: "XZME.XETRA", n: "Xtrackers MSCI USA ESG UCITS ETF 2C EUR Hedged - IE0000MMQ5M5 ", c: "" },
  { id: 34004, s: "XZWE.XETRA", n: "Xtrackers MSCI World ESG UCITS ETF 2C - EUR Hedged - IE00BMY76136 ", c: "" },
  { id: 34005, s: "YODA.XETRA", n: "Procure Space ETF EUR - IE00BLH3CV30 ", c: "" },
  { id: 34006, s: "ZER0.XETRA", n: "HANetf S&P Global Clean Energy Select HANzero UCITS ETF EUR - IE00BLH3CQ86 ", c: "" },
  { id: 34007, s: "ZERP.LSE", n: "HANetf S&P Global Clean Energy Select HANzero™ UCITS ETF GBP - IE00BLH3CQ86 ", c: "" },
  { id: 34008, s: "ZGLD.SW", n: "ZKB Gold ETF AA CHF - CH0139101593 ", c: "" },
  { id: 34009, s: "ZGLDEU.SW", n: "ZKB Gold ETF AA EUR - CH0047533523 ", c: "" },
  { id: 34010, s: "ZGLDGB.SW", n: "ZKB Gold ETF AA GBP - CH0104493298 ", c: "" },
  { id: 34011, s: "ZGLDHC.SW", n: "ZKB Gold ETF AAH CHF - CH0139101601 ", c: "" },
  { id: 34012, s: "ZGLDHE.SW", n: "ZKB Gold ETF AAH EUR - CH0103326762 ", c: "" },
  { id: 34013, s: "ZGLDHG.SW", n: "ZKB Gold ETF AAH GBP - CH0104493306 ", c: "" },
  { id: 34014, s: "ZGLDUS.SW", n: "ZKB Gold ETF AA USD - CH0047533549 ", c: "" },
  { id: 34015, s: "ZPAL.SW", n: "ZKB Palladium ETF AA CHF - CH0183136065 ", c: "" },
  { id: 34016, s: "ZPD3.XETRA", n: "SPDR® S&P® Global Dividend Aristocrats ESG UCITS ETF - IE00BYTH5S21 ", c: "" },
  { id: 34017, s: "ZPD6.XETRA", n: "SPDR® S&P® U.S. Dividend Aristocrats ESG UCITS ETF - IE00BYTH5R14 ", c: "" },
  { id: 34018, s: "ZPD9.XETRA", n: "SPDR® S&P® Euro Dividend Aristocrats ESG UCITS ETF - IE00BYTH5T38 ", c: "" },
  { id: 34019, s: "ZPDW.F", n: "SPDR® MSCI Japan EUR Hdg UCITS ETF - IE00BZ0G8C04 ", c: "" },
  { id: 34020, s: "ZPDW.XETRA", n: "SPDR® MSCI Japan EUR Hdg UCITS ETF - IE00BZ0G8C04 ", c: "" },
  { id: 34021, s: "ZPLA.SW", n: "ZKB Platinum ETF AA CHF - CH0183136057 ", c: "" },
  { id: 34022, s: "ZPRI.XETRA", n: "SPDR Morningstar Multi-Asset Global Infrastructure UCITS - IE00BQWJFQ70 ", c: "" },
  { id: 34023, s: "ZSG0.XETRA", n: "AuAg ESG Gold Mining UCITS ETF - IE00BNTVVR89 ", c: "" },
  { id: 34024, s: "ZSIL.SW", n: "ZKB Silver ETF AA CHF - CH0183135976 ", c: "" },
  { id: 34025, s: "ZSILHC.SW", n: "ZKB Silver ETF AAH CHF - CH0183136024 ", c: "" },
  { id: 34026, s: "ZSILUS.SW", n: "ZKB Silver ETF AA USD - CH0183136008 ", c: "" },
  { id: 34027, s: "ZSRL.XETRA", n: "BNP Paribas Easy FTSE EPRA/NAREIT Developed Europe UCITS ETF QD H EUR - LU1859445063 ", c: "" },
];
