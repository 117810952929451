import React, { Component, useState, useEffect } from "react";
import "./styleMenu.scss";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBank, faBullhorn, faHome, faSearch, faDatabase } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const MenuSymbol = ({ onChangeMenu }) => {
  const [activeMenu, setActiveMenu] = useState("home");
  const location = useLocation();

  useEffect(() => {
    const menuQuery = new URLSearchParams(location.search).get("menu");

    if (menuQuery) {
      if (menuQuery !== activeMenu) {
        setActiveMenu(menuQuery);
      }
    }
  }, [location.search]);

  const subMenuOption = [
    { id: "home", name: "Home", icon: faHome },
    { id: "news", name: "News", icon: faBullhorn },
    { id: "analysis", name: "Analysis", icon: faSearch },
    { id: "financials", name: "Data", icon: faDatabase },
  ];
  return (
    <div id="stickyCard " className={isMobile ? "sticky-menu-symbol-mobile " : "sticky-menu-symbol "}>
      <div className="container mx-auto m-b-20">
        <div className="navig-container ">
          <div className="tablist d-flex justify-content-center">
            {subMenuOption.map((e) => {
              return (
                <div
                  key={`submenu_${e.id}`}
                  className={activeMenu === e.id ? "tablist-element tablist-element-active" : "tablist-element"}
                  onClick={() => {
                    setActiveMenu(e.id);
                    onChangeMenu(e.id);
                    // this.setState({ activeMenu: e.id });
                  }}
                >
                  <div className="tablist-element-icon">
                    <FontAwesomeIcon icon={e.icon} size="lg" />
                  </div>
                  <div className="tablist-element-text">{isMobile ? "" : <span className="m-l-10">{e.name}</span>}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuSymbol;
