import React from "react";
import { Radar } from "react-chartjs-2";

function drawTextAlongArc2(context, str, centerX, centerY, radius, angle, angleStart) {
  var text = "Stability";

  // 2PI / 4 = PI/2
  // 2PI/5
  context.save();
  context.translate(centerX, centerY);
  context.rotate((2 * Math.PI) / 5);
  context.translate(0, -1 * radius);
  context.fillText(text, 0, 0);
  context.restore();

  text = "Growth";

  context.save();
  context.translate(centerX, centerY);
  context.rotate((-2 * Math.PI) / 5);
  context.translate(0, -1 * radius);
  context.fillText(text, 0, 0);
  context.restore();

  text = "Timing";
  context.save();
  context.translate(centerX, centerY);
  context.rotate((1 * Math.PI) / 5);
  context.translate(0, 1 * radius + 12);
  context.fillText(text, 0, 0);
  context.restore();

  text = "Earning";
  context.save();
  context.translate(centerX, centerY);
  context.rotate(0);
  context.translate(0, -1 * radius);
  context.fillText(text, 0, 0);
  context.restore();

  text = "Price";
  context.save();
  context.translate(centerX, centerY);
  context.rotate((-1 * Math.PI) / 5);
  context.translate(0, 1 * radius + 12);
  context.fillText(text, 0, 0);
  context.restore();
}

const SummaryRadar = ({ valueChart }) => {
  var backgroundColor = "";
  var borderColor = "";

  // Calculer la moyenne
  var meanScore = 0;
  const sizeValueChart = valueChart.length;
  valueChart.forEach((e) => (meanScore += e * e));
  meanScore = Math.sqrt(meanScore / sizeValueChart);

  // Deduction de la couleur
  if (meanScore >= 0) {
    borderColor = "#f11";
    backgroundColor = "rgba(255, 17, 17, 0.25)";
  }
  if (meanScore >= 35) {
    borderColor = "#ff8800";
    backgroundColor = "rgba(255, 200, 0, 0.25)";
  }
  if (meanScore >= 70) {
    borderColor = "#1cce29";
    backgroundColor = "rgba(28, 206, 41, 0.25)";
  }

  const data = {
    labels: ["Earning", "Stability", "Price", "Timing", "Growth"],
    datasets: [
      {
        // label: '# of Votes',
        data: valueChart,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,

    maintainAspectRatio: false,
    tooltips: {
      mode: "nearest",
      intersect: false,

      callbacks: {
        title: (tooltipItem, data) => {
          const index = tooltipItem[0].index;

          return data.labels[index];
        },
        label: function (tooltipItem, data) {
          return ` Note : ${tooltipItem.value}/100`;
        },
        // afterLabel: function (tooltipItem, data) {
        //   // const index = tooltipItem[0].index;
        //   return "'(' + percent + '%)'";
        // },
      },
    },
    layout: {
      padding: 18,
    },
    legend: {
      display: false,
    },
    scale: {
      angleLines: {
        display: true,
        color: "#eeeeee",
        lineWidth: 3,
      },
      gridLines: {
        circular: true,
        color: "#e1e1e1",
      },

      pointLabels: {
        display: false,
        fontSize: "15",
      },
      ticks: {
        display: false,
        beginAtZero: true,
        max: 100,
        stepSize: 20,
        min: 0,
        // stepSize: 1,
      },
    },
  };
  const plugins = [
    {
      beforeDraw: (chart) => {
        const { ctx, scale } = chart;
        const { xCenter, yCenter, drawingArea: radius } = scale;
        const position = scale.chart.tooltip?._active;

        ctx.beginPath();
        ctx.fillStyle = "#e1e1e1";
        ctx.arc(xCenter, yCenter, radius, 0, Math.PI * 2);
        ctx.fill();

        // ctx.save();
        ctx.beginPath();
        ctx.fillStyle = "#eeeeee";
        ctx.arc(xCenter, yCenter, (4 * radius) / 5, 0, Math.PI * 2);
        ctx.fill();

        ctx.beginPath();
        ctx.arc(xCenter, yCenter, (3 * radius) / 5, 0, Math.PI * 2);
        ctx.fillStyle = "#e1e1e1";
        ctx.fill();

        ctx.beginPath();
        ctx.arc(xCenter, yCenter, (2 * radius) / 5, 0, Math.PI * 2);
        ctx.fillStyle = "#eeeeee";
        ctx.fill();

        ctx.beginPath();
        ctx.arc(xCenter, yCenter, (1 * radius) / 5, 0, Math.PI * 2);
        ctx.fillStyle = "#e1e1e1";
        ctx.fill();

        ctx.closePath();
        ctx.restore();

        if (position) {
          if (position.length > 0) {
            var deltaX = position[0]._model.x - xCenter;
            var deltaY = position[0]._model.y - yCenter;
            var rad = Math.atan2(deltaY, deltaX); // In radians

            ctx.beginPath();
            ctx.fillStyle = "#bababa66";
            ctx.moveTo(xCenter, yCenter);

            ctx.arc(xCenter, yCenter, radius, rad - 0.5, rad + 0.5);
            ctx.fill();
            ctx.closePath();
            ctx.restore();
          }
        }

        // Draw text along arc
        ctx.font = "14pt Roboto";
        ctx.textAlign = "center";
        ctx.fillStyle = "#344767";

        ctx.lineWidth = 4;
        drawTextAlongArc2(ctx, "Earning", xCenter, yCenter, radius + 2, Math.PI * 0.2, Math.PI / 2);
      },
    },
  ];
  return (
    <div style={{ height: "300px" }}>
      <Radar width={100} id="ctx" data={data} options={options} plugins={plugins} />
    </div>
  );
};

export default SummaryRadar;
