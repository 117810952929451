export const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#202124",
      fontSize: "14px",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f3f6ff",
      borderBottomColor: "#FFFFFF",
      borderRadius: "25px",
      outline: "1px solid #FFFFFF",
      cursor: "pointer",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

export const plugins = [
  {
    afterDraw: (chart) => {
      if (chart.tooltip?._active?.length) {
        let x = chart.tooltip._active[0]._model.x;
        let yAxis = chart.scales["y-axis-0"];

        let ctx = chart.ctx;
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = "#6d6d6d";
        ctx.moveTo(x, yAxis.top);
        ctx.lineTo(x, yAxis.bottom);
        ctx.lineWidth = 0.5;
        ctx.stroke();
        ctx.restore();

        // Outer circle
        for (let i = 0; i < chart.tooltip._active.length; i++) {
          ctx.beginPath();
          ctx.fillStyle = "#89898947";
          ctx.arc(x, chart.tooltip._active[i]._model.y, 10, 0, 2 * Math.PI);
          ctx.fill();

          ctx.closePath();
          // Inner circle
          ctx.beginPath();
          ctx.fillStyle = "#6d6d6d";
          ctx.arc(x, chart.tooltip._active[i]._model.y, 4, 0, 2 * Math.PI);
          ctx.fill();
          ctx.closePath();
        }
      }
    },
  },
];
