import { firebase_app } from "../data/config";

export const postSymbolWatchlist = (value) => {
  const uid = firebase_app.auth().currentUser.uid;
  const { symbol, name, add } = value;
  firebase_app.firestore().collection("users").doc(uid).collection("watchlist").doc(symbol).set({
    watchlist: add,
    name: name,
    price: 1,
    invested: 0,
    quantity: 0,
  });
};

export const updateSymbolWatchlist = (value) => {
  const uid = firebase_app.auth().currentUser.uid;
  const { symbol, quantity, invested, buyPrice } = value;
  firebase_app.firestore().collection("users").doc(uid).collection("watchlist").doc(symbol).update({
    price: buyPrice,
    invested,
    quantity,
  });
};

export const getSymbolWatchlist = (value) => {
  const uid = firebase_app.auth().currentUser.uid;
  const { symbol } = value;
  return firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("watchlist")
    .doc(symbol)
    .get()
    .then((c) => {
      return c.data().watchlist;
    })
    .catch(() => {
      return false;
    });
};

export const getAllSymbolWatchlist = async () => {
  const uid = firebase_app.auth().currentUser.uid;
  var watchlist = [];

  watchlist = await firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("watchlist")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().watchlist) {
          watchlist.push({ ...doc.data(), symbol: doc.id });
        }
      });

      return watchlist;
    })
    .catch(() => {
      return [];
    });

  return watchlist;
};
