import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

function geometricMean(arr, n) {
  let product = 1;
  for (let i = 0; i < n; i++) product = product * arr[i];
  let gm = Math.pow(product, 1 / n);
  return gm;
}

const RadialBarNoteMA = ({ data }) => {
  var note = 0;

  if (data?.note) {
    let array = [];
    Object.keys(data.note).forEach((key) => {
      array.push(data.note[key].value);
    });
    console.log(data.note);
    let n = array.length;
    note = geometricMean(array, n);
  }

  const state = {
    series: [note],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
      },

      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,

          track: {
            show: true,
            startAngle: undefined,
            endAngle: undefined,
            background: "#e1e1e1",
            strokeWidth: "97%",
            opacity: 1,
            margin: 5,
            dropShadow: {
              enabled: false,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: 0,
              fontSize: "34px",
              fontWeight: "bold",
              formatter: function (val) {
                return parseInt(val);
              },
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },

      stroke: {
        // dashArray: 4,
        lineCap: "round",
      },

      labels: ["Average Results"],
    },
  };

  return (
    <div className="text-center m-t-10">
      {/* <div id="chart">

        <ReactApexChart options={state.options} series={state.series} type="radialBar" height="350" />
      </div>{" "} */}
      <h4 style={{ fontSize: "1.25rem", fontWeight: "800", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>Rating : {note.toFixed(0)}/100</h4>
    </div>
  );
};

export default RadialBarNoteMA;
