import React, { Fragment, useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { firebase_app, googleProvider } from "../data/config";
// import firebase from "firebase";
import { ToastContainer, toast } from "react-toastify";
import GoogleLogo from "./googleIcon.svg";
import Background from "../assets/background/background.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/fontawesome-free-regular";

const Signup = () => {
  const history = useHistory();
  // let { affiliateID } = useParams();

  const [currentUser, setCurrentUser] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [inApp, setInApp] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    setInApp(isFacebookApp());
  }, []);

  function isFacebookApp() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = e.target.elements;
    try {
      firebase_app
        .auth()
        .createUserWithEmailAndPassword(email.value, password.value)
        .then((userCredential) => {
          userCredential.user.sendEmailVerification();
          setTimeout(() => {
            history.push(`${process.env.PUBLIC_URL}/`);
          }, 200);
        })
        .catch((err) => {
          setTimeout(() => {
            toast.error(err.message);
          }, 200);
        });
    } catch (error) {
      console.log("Erreur");

      // alert(error);
    }
  };

  if (currentUser) {
    return <Redirect to="/" />;
  }

  const SignInWithGoogle = () => {
    if (inApp) {
      setTimeout(() => {
        toast.error("Authentification with Google not allowed within the Facebook/Messenger/Instagram app. Open the site in your default browser.");
      }, 200);
    } else {
      firebase_app
        .auth()
        .signInWithPopup(googleProvider)
        .then(() => {
          setTimeout(() => {
            history.push(`${process.env.PUBLIC_URL}/`);
          }, 200);

          setCurrentUser(true);
        })
        .catch((err) => {});

      if (currentUser) {
        return <Redirect to="/" />;
      }
    }
  };
  return (
    <Fragment>
      <div style={{ backgroundImage: `url(${Background})`, width: "100%", height: "100%", position: "absolute", opacity: "5%" }}></div>
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* <!-- sign up page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-sm-12 p-0">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <h1>
                        <span>
                          market<span style={{ opacity: "0.6" }}>advisor</span>
                        </span>
                      </h1>
                    </div>
                    <div className="card mt-4 p-4">
                      <h4 className="text-center">{"Join Us !"}</h4>
                      <h6 className="text-center h6">{"Enter a valid email and password to register"}</h6>

                      <form className="theme-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="email" className="h6">
                            {"E-mail"}
                          </label>
                          <input className="form-control" type="email" name="email" placeholder="email@email.com" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password" className="h6">
                            {"Password"}
                          </label>
                          {/* <input className="form-control" type="password" name="password" placeholder="**********" /> */}

                          <div style={{ display: "flex", position: "relative" }}>
                            <input className="form-control" type={passwordShown ? "text" : "password"} name="password" placeholder="**********" />
                            <FontAwesomeIcon onClick={togglePasswordVisiblity} icon={faEye} style={{ position: "absolute", top: "30%", right: "4%" }} />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-12">
                            <button className="btn btnPrimary btn-block" type="submit">
                              {"Create Account"}
                            </button>
                            <button className={inApp ? "btn btn-light btnPrimary btn-block disabled" : "btn btn-light btnPrimary btn-block"} type="button" onClick={SignInWithGoogle}>
                              <img src={GoogleLogo} alt="logo Google" style={{ height: "20px" }} />
                              {/* <i className="fa fa-google"> </i> */}
                              {"  Continue with Google"}
                            </button>
                          </div>
                          <div className="col-12 m-t-20">
                            <div>
                              <a className="btn btn-light btn-block" href={`${process.env.PUBLIC_URL}/login`}>
                                {"Already Member ? Login"}
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- sign up page ends--> */}
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default Signup;
