export const fetchLogoUrl = (product) => {
  const splitTicker = product.split(".");
  var url = `https://storage.googleapis.com/marketadvisor-logo/noLogo.png`;

  if (splitTicker.length === 1) {
    url = `https://storage.googleapis.com/marketadvisor-logo/us/${product}.png`;
  } else {
    url = `https://storage.googleapis.com/marketadvisor-logo/${splitTicker[1]}/${splitTicker[0]}.png`;
  }

  return url;
};
