import React from "react";
// import Credit from "./component/credit";
import Profile from "./component/profil";

import SubscriptionCard from "./component/subscriptionCard";
import LimitationCard from "./component/LimitationCard";
import ApiKey from "./component/apiKey";

const ProfileComponent = () => {
  return (
    <div className={"fullPage"} style={{}}>
      <div className="container p-t-20">
        <Profile />

        <div className="m-20"></div>
        <SubscriptionCard />
        <ApiKey />
        <LimitationCard />
        {/* {!this.state.loading && <div>{!this.state.subscription ? <PaymentComponent onPayment={this.handlePayment} /> : <CancelPaypal onCancel={this.handleCancel} />}</div>} */}
        <div style={{ height: "100px" }}>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default ProfileComponent;
