import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ListBenefits = (value) => {
  const option = value.option;

  if (option === 0) {
    return (
      <div className="row">
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          {"No time limit"}
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          {"Free Plan"}
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          <span style={{ fontWeight: 800 }}>5,000</span> Tickers
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          <span style={{ fontWeight: 800 }}>5</span> Tickers /day
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          <span style={{ fontWeight: 800 }}>1</span> Screener
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          <span style={{ fontWeight: 800 }}>No</span> Guru Screener
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          Watchlist and Portfolio
        </div>

        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <div>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
            <span style={{ fontWeight: 800 }}>+30 years</span> Financial Data
          </div>
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <div>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
            Export Data to CSV
          </div>
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          {" -"}
        </div>
      </div>
    );
  }

  if (option === 1) {
    return (
      <div className="row">
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          {"7 days Trial"}
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          {"Cancel Anytime"}
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          <span style={{ fontWeight: 800 }}>+150,000</span> Tickers
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          <span style={{ fontWeight: 800 }}>300</span> Tickers /month
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          <span style={{ fontWeight: 800 }}>20</span> Screener /month
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          <span style={{ fontWeight: 800 }}>10</span> Guru Screener /month
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          Watchlist and Portfolio
        </div>

        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <div>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
            <span style={{ fontWeight: 800 }}>+30 years</span> Financial Data
          </div>
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <div>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
            Export Data to CSV
          </div>
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <div>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
            Dividends Calendar
          </div>
        </div>
      </div>
    );
  }
  if (option === 2) {
    return (
      <div className="row">
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          {"7 days Trial"}
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          {"Cancel Anytime"}
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          <span style={{ fontWeight: 800 }}>+150,000</span> Tickers
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          <span style={{ fontWeight: 800 }}>Unlimited</span> Tickers
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          <span style={{ fontWeight: 800 }}>Unlimited</span> Screener
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          <span style={{ fontWeight: 800 }}>Unlimited</span> Guru Screener
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
          Watchlist and Portfolio
        </div>

        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <div>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
            <span style={{ fontWeight: 800 }}>+30 years</span> Financial Data
          </div>
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <div>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
            Export Data to CSV
          </div>
        </div>
        <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
          <div>
            <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
            Dividends Calendar
          </div>
        </div>
      </div>
    );
  }

  // if (option === 3) {
  //   return (
  //     <div className="row">
  //       <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
  //         <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
  //         {"7 days Trial"}
  //       </div>
  //       <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
  //         <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
  //         {"Cancel Anytime"}
  //       </div>
  //       <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
  //         <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
  //         <span style={{ fontWeight: 800 }}>+150,000</span> Tickers
  //       </div>
  //       <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
  //         <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
  //         <span style={{ fontWeight: 800 }}>Unlimited</span> Tickers
  //       </div>
  //       <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
  //         <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
  //         <span style={{ fontWeight: 800 }}>Unlimited</span> Screener
  //       </div>
  //       <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
  //         <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
  //         <span style={{ fontWeight: 800 }}>Unlimited</span> Guru Screener
  //       </div>
  //       <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
  //         <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
  //         Watchlist and Portfolio
  //       </div>

  //       <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
  //         <div>
  //           <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
  //           +30 years Financial Data
  //         </div>
  //       </div>
  //       <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
  //         <div>
  //           <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
  //           Dividends Calendar
  //         </div>
  //       </div>
  //       <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
  //         <div>
  //           <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
  //           Export Data to CSV
  //         </div>
  //       </div>
  //       <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
  //         <div>
  //           <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
  //           Database Access
  //         </div>
  //       </div>
  //       <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }}>
  //         <div>
  //           <FontAwesomeIcon icon={faCheck} style={{ color: "#22c55e", marginRight: "5px", fontSize: "1.2rem" }} />
  //           Premium Support
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
};

export default ListBenefits;
