import { firebase_app } from "../data/config";

const freeAccess = ["AAPL", "MSFT"];

export const getAccessLimitation = async (user) => {
  var access = false;
  //  product = dividend, financialData, guruScreener, sreen , symbol

  await firebase_app
    .firestore()
    .collection("users")
    .doc(user.uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        access = data.access;
      }
    })
    .catch(() => {});

  return access;
};

export const requestAccessToProduct = async ({ user, product, ticker }) => {
  var authorisation = false;
  //  product = dividend, financialData, guruScreener, sreen , symbol

  if (ticker) {
    if (freeAccess.includes(ticker)) {
      return true;
    }
  }

  await firebase_app
    .firestore()
    .collection("users")
    .doc(user.uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        var access = data.access;

        // Handle Symbol Access
        if (product === "symbol") {
          if (access.symbol > 0) {
            access.symbol = parseInt(access.symbol - 1);
            authorisation = true;
            firebase_app.firestore().collection("users").doc(user.uid).update({ access: access });
          }
        }
        // Handle Screener Access
        if (product === "screener") {
          if (access.screener > 0) {
            access.screener = parseInt(access.screener - 1);
            authorisation = true;
            firebase_app.firestore().collection("users").doc(user.uid).update({ access: access });
          }
        }

        if (product === "dividend") {
          if (access.dividend) {
            authorisation = true;
          }
        }
        if (product === "guruScreener") {
          if (access.guruScreener) {
            authorisation = true;
          }
        }

        if (product === "portfolio") {
          if (access.portfolio) {
            authorisation = true;
          }
        }
        if (product === "financialData") {
          if (access.financialData) {
            authorisation = true;
          }
        }
      }
    })
    .catch(() => {});

  return authorisation;
};

export const getDailyVisualisation = async (user) => {
  var authorisation = false;
  var subscription = false;

  // Verifier si Subscription :
  await firebase_app
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("subscriptions")
    .get()
    .then((snapshot) => {
      snapshot.forEach((subscription) => {
        const hasCurrentSub = subscription.data().status?.toLowerCase().includes("active");

        if (hasCurrentSub === true) {
          subscription = true;
          authorisation = true;
        }
      });
    });

  if (subscription === false) {
    await firebase_app
      .firestore()
      .collection("users")
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const dailyVisualisation = data.dailyVisualisation;
          if (dailyVisualisation > 0) {
            authorisation = true;
            firebase_app
              .firestore()
              .collection("users")
              .doc(user.uid)
              .update({ dailyVisualisation: parseInt(dailyVisualisation - 1) });
          }
        }
      })
      .catch(() => {});
  }

  return authorisation;
};

export const hasSub = async (user) => {
  var hasSub = false;
  // var productId = "";
  await firebase_app
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("subscriptions")
    .get()
    .then((snapshot) => {
      snapshot.forEach((subscription) => {
        const hasCurrentSub = subscription.data().status?.toLowerCase().includes("active");
        const hasTrial = subscription.data().status?.toLowerCase().includes("trialing");
        if (hasCurrentSub === true || hasTrial === true) {
          hasSub = true;
          // productId = subscription.data().items[0].plan.product;
        }
      });
    });

  return hasSub;
};

export const updateLastSeen = (data) => {
  const user = data.user;
  const name = data.name;
  const symbol = data.symbol;
  var tmp = [];
  firebase_app
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("analytics")
    .doc("lastSeen")
    .get()
    .then((doc) => {
      if (!doc.exists) {
        tmp = [{ name, symbol }];
      } else {
        tmp = doc.data();
        tmp = tmp.symbol;
        tmp = tmp.filter((e) => e.symbol !== symbol);
        tmp.push({ name, symbol });
        if (tmp.length > 4) {
          tmp = tmp.slice(-4);
        }
      }

      firebase_app.firestore().collection("users").doc(user.uid).collection("analytics").doc("lastSeen").set({
        symbol: tmp,
      });
    });
};

export const getLastSeen = async (data) => {
  const user = data.user;

  var res = [];
  await firebase_app
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("analytics")
    .doc("lastSeen")
    .get()
    .then((doc) => {
      if (!doc.exists) {
        res = [];
      } else {
        res = doc.data();
        res = res.symbol;
      }
    })
    .catch(() => {});
  if (res.length > 4) {
    res = res.slice(-4);
  }
  return res;
};
