import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { listVariable } from "./listVariable";
import eToro from "../../../assets/images/logo/etoro_logo.svg";
// import Fidelity from "./logoExchange/Fidelity";
// import Fidelity from "../../../assets/images/logo/FidelityInternational_logo.svg";
import Fidelity from "../../../assets/images/logo/fidelity.png";
import Robinhood from "../../../assets/images/logo/Robinhood_logo.svg";
import Ameritrade from "../../../assets/images/logo/TD_Ameritrade_logo.svg";
import { isMobile } from "react-device-detect";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

const FilterScreener = ({ onChangeFilter, initialConfig }) => {
  const [variable, setVariable] = useState("PE");
  const [nameVariable, setNameVariable] = useState("PE");
  const [operators, setOperators] = useState([">", "<"]);
  const [operator, setOperator] = useState(">");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [listFilter, setListFilter] = useState(initialConfig);
  const [typeFilter, setTypeFilter] = useState("custom");
  const [exchange, setExchange] = useState("");
  const [typeCondition, setTypeCondition] = useState("input");
  const [formatCondition, setFormatCondition] = useState("number");
  const [conditions, setConditions] = useState();
  const [condition, setCondition] = useState("");
  const [somethingHasChanged, setSomethingHasChanged] = useState(false);

  useEffect(() => {
    if (initialConfig.length !== 0) {
      setListFilter(initialConfig);
    }
  }, [initialConfig]);

  // Handle change of Variable
  useEffect(() => {
    // Update the document title using the browser API

    const tmpVariable = listVariable.filter((e) => e.value === variable);

    setOperators(tmpVariable[0].operators);
    setOperator(tmpVariable[0].operators[0]);
    setTypeCondition(tmpVariable[0].condition.type);
    setFormatCondition(tmpVariable[0].condition.format);
    setConditions(tmpVariable[0].condition.option);

    // Reset
    if (tmpVariable[0].condition.type === "select") {
      setCondition(tmpVariable[0].condition.option[0]);
    } else {
      if (tmpVariable[0].condition.format === "number") setCondition(0);
      if (tmpVariable[0].condition.format === "text") setCondition("");
    }
  }, [variable]);

  const addTypeFilter = (source) => {
    if (typeFilter !== source) {
      var listFilerTmp = [];
      if (source === "marketadvisor") {
        listFilerTmp = [];

        const newFilter = [
          { id: Math.floor(Math.random() * 1000000), variable: "Note_Earning", operator: ">=", condition: 60, name: "Earning by MA" },
          { id: Math.floor(Math.random() * 1000000), variable: "Note_Stability", operator: ">=", condition: 60, name: "Stability by MA" },
          { id: Math.floor(Math.random() * 1000000), variable: "Note_Price", operator: ">=", condition: 60, name: "Price by MA" },
          { id: Math.floor(Math.random() * 1000000), variable: "Note_Momentum", operator: ">=", condition: 60, name: "Timing by MA" },
          { id: Math.floor(Math.random() * 1000000), variable: "Note", operator: ">=", condition: 60, name: "Global Note" },
          // { id: Math.floor(Math.random() * 1000000), variable: "Note_MA", operator: ">=", condition: "80", name: "Note by MA" },
        ];
        listFilerTmp.push(...newFilter);
      }

      setListFilter(listFilerTmp);
      setTypeFilter(source);
      setBtnDisabled(false);
    }
  };

  const addExchangeFilter = (exchangeChoice) => {
    var listFilerTmp = [...listFilter];
    listFilerTmp = listFilerTmp.filter((e) => String(e.id).includes("exchange") === false);

    if (exchange === exchangeChoice || exchangeChoice === "") {
      setExchange("");
      setListFilter(listFilerTmp);
    } else {
      const newFilter = { id: `exchange_${Math.floor(Math.random() * 1000000)}`, variable: exchangeChoice, operator: "=", condition: true, name: exchangeChoice };
      listFilerTmp.push(newFilter);
      setListFilter(listFilerTmp);
      setExchange(exchangeChoice);
    }

    setBtnDisabled(false);
  };

  const addFilter = () => {
    var listFilerTmp = [...listFilter];
    const newFilter = { id: Math.floor(Math.random() * 1000000), variable: variable, operator: operator, condition: condition, name: nameVariable };
    listFilerTmp.push(newFilter);
    setListFilter(listFilerTmp);
    setBtnDisabled(false);
    setSomethingHasChanged(false);
  };

  const handleChangeVariable = (event) => {
    const varTmp = listVariable.filter((e) => e.value === event.target.value);
    setVariable(event.target.value);
    setNameVariable(varTmp[0].name);
    setSomethingHasChanged(true);
  };

  const handleChangeOperator = (event) => {
    setOperator(event.target.value);
    setSomethingHasChanged(true);
  };

  const handleChangeCondition = (event) => {
    const tmpVariable = listVariable.filter((e) => e.value === variable);

    if (tmpVariable[0].condition.type === "select") {
      setCondition(event.target.value);
    } else {
      if (tmpVariable[0].condition.format === "number") setCondition(parseFloat(event.target.value));
      if (tmpVariable[0].condition.format === "text") setCondition(event.target.value);
    }
  };
  const deleteFilter = (id) => {
    var listFilerTmp = [...listFilter];
    listFilerTmp = listFilerTmp.filter((e) => e.id !== id);
    setListFilter(listFilerTmp);
    setBtnDisabled(false);
  };

  const transformOperatorToText = (operator) => {
    if (operator === ">=") return "greater than";
    if (operator === "<=") return "less than";
    // if (operator === "==") return "equal to";
    if (operator === "=") return "equal to";
    // if (operator === "!=") return "not equal to";
    if (operator === "<>") return "not equal to";
    if (operator === "display") return "display";
  };

  const getScreener = () => {
    onChangeFilter({ listFilter, typeFilter });
    setBtnDisabled(true);

    //Create List condition

    // Send to parent
  };
  return (
    <div className="container">
      <div className="card">
        <div className="card-body">
          {/* Partie 1 - Choix du type de filtre */}
          <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>BUILD YOUR SCREENER - ADD PARAMETERS</h2>

          <div className="m-20"></div>
          <h2 className="m-b-5" style={{ color: "black", fontSize: "1rem" }}>{`SELECT BROOKER`}</h2>
          <div className="m-b-15">
            <span>Add a brooker as a filter to only display the company available on your favorite platform. </span>
          </div>

          <div className="row justify-content-md-center">
            <div className={isMobile ? "col-12 p-b-10" : "col-2 p-r-0"}>
              <div
                id=""
                onClick={() => {
                  addExchangeFilter("");
                }}
                className={`btn buttonFilter ${exchange === "" && "buttonFilterActif"}`}
              >
                None
              </div>
            </div>
            <div className={isMobile ? "col-12 p-b-10" : "col-2 p-r-0"}>
              <div
                id="eToro"
                onClick={() => {
                  addExchangeFilter("eToro");
                }}
                className={`btn buttonFilter ${exchange === "eToro" && "buttonFilterActif"}`}
              >
                <img src={eToro} alt="logoEtoro" height={"25px"} />
              </div>
            </div>

            <div className={isMobile ? "col-12 p-b-10" : "col-2 p-r-0"}>
              <div
                id="fidelity"
                onClick={() => {
                  addExchangeFilter("fidelity");
                }}
                className={`btn buttonFilter ${exchange === "fidelity" && "buttonFilterActif"}`}
              >
                <img src={Fidelity} alt="logoEtoro" height={"25px"} />
              </div>
            </div>

            <div className={isMobile ? "col-12 p-b-10" : "col-2 p-r-0"}>
              <div
                id="tdameritrade"
                onClick={() => {
                  addExchangeFilter("tdameritrade");
                }}
                className={`btn buttonFilter ${exchange === "tdameritrade" && "buttonFilterActif"}`}
              >
                <img src={Ameritrade} alt="logoEtoro" height={"25px"} />
              </div>
            </div>

            <div className={isMobile ? "col-12 p-b-10" : "col-2 p-r-0"}>
              <div
                id="robinhood"
                onClick={() => {
                  addExchangeFilter("robinhood");
                }}
                className={`btn buttonFilter ${exchange === "robinhood" && "buttonFilterActif"}`}
              >
                <img src={Robinhood} alt="logoEtoro" height={"25px"} />
              </div>
            </div>
          </div>

          <h2 className="m-t-20 " style={{ color: "black", fontSize: "1rem" }}>
            SELECT PARAMETERS
          </h2>

          {/* Map current filter Version 2 */}

          <div className="row m-b-20 col">
            {listFilter.map((e) => {
              // On n'affiche pas les éléments qui ne sont pas dans la list
              var toMap = false;

              listVariable.forEach((elementVariable) => {
                if (e.variable === elementVariable.value) toMap = true;
              });

              if (toMap) {
                return (
                  <div key={e.id} className="pill-parameters" onClick={() => deleteFilter(e.id)}>
                    {`${e.name} ${e.operator} ${e.operator === "display" ? "" : e.condition}`}
                    <FontAwesomeIcon className="m-l-10" icon={faTrashAlt} />
                  </div>
                );
              }
            })}
          </div>

          {/* Map current filter  */}
          {/* <div>
            {listFilter.map((e) => {
              // On n'affiche pas les éléments qui ne sont pas dans la list
              var toMap = false;
              listVariable.forEach((elementVariable) => {
                if (e.variable === elementVariable.value) toMap = true;
              });
              if (toMap) {
                return (
                  <div key={e.id} className={isMobile ? "row m-l-5  p-b-10" : "row m-l-10  p-b-10 listSelectedFilter  "}>
                    {!isMobile && <div className="col-1 listSelectedFilterElement"></div>}
                    <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
                      <div className="rbt-input-main form-control rbt-input " style={{ opacity: "0.5" }}>
                        {e.name}
                      </div>
                    </div>
                    <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
                      <div className="rbt-input-main form-control rbt-input " style={{ opacity: "0.5" }}>
                        {transformOperatorToText(e.operator)}
                      </div>
                    </div>
                    <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
                      <div className="rbt-input-main form-control rbt-input " style={{ opacity: "0.5" }}>
                        {e.operator === "display" ? "N/A" : e.condition}
                      </div>
                    </div>
                    <div onClick={() => deleteFilter(e.id)} style={{ margin: "auto", textAlign: "center", cursor: "pointer", marginRight: "10px", fontSize: "large" }}>
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                  </div>
                );
              }
            })}
          </div> */}

          {/* Empty filter  */}
          <div className={isMobile ? "row m-l-5" : "row m-l-10"}>
            {/* {!isMobile && <div className="col-1 listSelectedFilterElementFinal"></div>} */}

            <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
              <select className="rbt-input-main form-control rbt-input " value={variable} onChange={handleChangeVariable}>
                <optgroup label="Market Advisor">
                  {listVariable.map((variable) => {
                    if (variable.group === "ma")
                      return (
                        <option key={variable.value} value={variable.value}>
                          {variable.name}
                        </option>
                      );
                  })}
                </optgroup>
                <optgroup label="Earning Ratio">
                  {listVariable.map((variable) => {
                    if (variable.group === "earning")
                      return (
                        <option key={variable.value} value={variable.value}>
                          {variable.name}
                        </option>
                      );
                  })}
                </optgroup>
                <optgroup label="Valuation">
                  {listVariable.map((variable) => {
                    if (variable.group === "valuation")
                      return (
                        <option key={variable.value} value={variable.value}>
                          {variable.name}
                        </option>
                      );
                  })}
                </optgroup>
                <optgroup label="Price Ratio">
                  {listVariable.map((variable) => {
                    if (variable.group === "price")
                      return (
                        <option key={variable.value} value={variable.value}>
                          {variable.name}
                        </option>
                      );
                  })}
                </optgroup>
                <optgroup label="General">
                  {listVariable.map((variable) => {
                    if (variable.group === "general")
                      return (
                        <option key={variable.value} value={variable.value}>
                          {variable.name}
                        </option>
                      );
                  })}
                </optgroup>
                <optgroup label="Value Investing">
                  {listVariable.map((variable) => {
                    if (variable.group === "valueinvesting")
                      return (
                        <option key={variable.value} value={variable.value}>
                          {variable.name}
                        </option>
                      );
                  })}
                </optgroup>
              </select>
            </div>
            <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
              <select className="rbt-input-main form-control rbt-input " value={operator} onChange={handleChangeOperator}>
                {operators.map((option) => {
                  return (
                    <option key={option} value={option}>
                      {transformOperatorToText(option)}
                    </option>
                  );
                })}
              </select>
            </div>

            {operator === "display" ? (
              <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
                <div className="rbt-input-main form-control rbt-input " style={{ opacity: "0.5" }}>
                  {"N/A"}
                </div>
              </div>
            ) : (
              <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
                {typeCondition === "input" && <input className="rbt-input-main form-control rbt-input " onChange={handleChangeCondition} onClick={() => setCondition("")} value={condition} type={formatCondition}></input>}

                {typeCondition === "select" && (
                  <select className="rbt-input-main form-control rbt-input " value={condition} onChange={handleChangeCondition}>
                    {conditions.map((option) => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
            )}
            <div className="" style={{ margin: "auto", textAlign: "center", opacity: "0", marginRight: "10px", fontSize: "large" }}>
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>

          <div className="row p-l-20 p-r-20  m-t-20 justify-content-md-center">
            <div className={isMobile ? "col-12 text-center m-t-10" : " col-6 text-center m-t-10"}>
              <button className="btn btnPrimary btn-light btn-block" onClick={addFilter}>
                <FontAwesomeIcon icon={faPlus} />
                {somethingHasChanged ? "  Add Filter - Changes detected" : "  Add Filter"}
              </button>

              <button className={`btn m-t-20 m-b-20 btn-block btnSecondary ${btnDisabled && "btnDisabled"}`} disabled={btnDisabled} onClick={getScreener}>
                {btnDisabled ? "No Change Detected" : `Get Screener (${listFilter.length} filter${listFilter.length > 1 ? "s" : ""})`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterScreener;
