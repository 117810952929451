import React, { Component, useContext, useEffect, useState } from "react";
import { ETFContext } from "../utils/etfContext";
import { Progress } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { fetchLogoUrl } from "../../../../tools/fetchLogoCompany";
import { conversionExchange } from "../utils/conversionListExchange";
import Loader from "../../tools/loader";
import { Link } from "react-router-dom";

const CardHoldingETF = () => {
  const data = useContext(ETFContext);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [seeAll, setSeeAll] = useState(false);

  useEffect(() => {
    const name = "Holdings";
    let dataTmp = [];
    if (Object.keys(data).length !== 0) {
      Object.keys(data[name]).forEach((element) => {
        dataTmp.push({ code: data[name][element]["Code"], exchange: data[name][element]["Exchange"], name: data[name][element]["Name"], value: parseInt(10 * parseFloat(data[name][element]["Assets_%"])) / 10 });
      });

      dataTmp.sort((a, b) => b.value - a.value);

      setDataToDisplay(dataTmp);
    }
  }, [data]);

  if (Object.keys(data).length === 0) {
    return <Loader />;
  }

  return (
    <div className="card">
      <div className="card-body">
        <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>Top 10 Holdings</h2>
        <ul>
          {dataToDisplay.map((element, index) => {
            const { value, name, code, exchange } = element;

            let symbol = "";

            if (exchange === "US") {
              symbol = code;
            } else {
              symbol = code + "." + conversionExchange[exchange];
            }
            var logoPath = fetchLogoUrl(symbol);
            if (!seeAll && index > 10) {
              return "";
            }
            return (
              <li className="tableETF" key={name}>
                <div className="row">
                  <div className="col-7">
                    {/* <FontAwesomeIcon icon={faLink} /> */}

                    <Link to={`${process.env.PUBLIC_URL}/symbol/${symbol}`} style={{ color: "black" }}>
                      <img
                        src={logoPath}
                        alt="new"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                        }}
                        style={{ maxHeight: "20px", marginRight: "5px" }}
                      />
                      {`${name} (${value}%):`}
                    </Link>
                  </div>
                  <div className="col-5">
                    <Progress value={value} striped />
                  </div>
                </div>
              </li>
            );
          })}
        </ul>

        <div className="text-center">
          <button
            className="btn btnPrimary m-t-10"
            onClick={() => {
              setSeeAll(!seeAll);
            }}
          >
            {seeAll ? "Hide" : "See All"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardHoldingETF;
