import React, { useState } from "react";
import { useEffect } from "react";
import { updateSymbolWatchlist } from "../../../../firebase-marketadvisor/watchlistSymbol";
import DataTable from "react-data-table-component";
import ChartPortfolio from "./chartPortfolio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const TablePortfolio = ({ data, lock }) => {
  const [myWatchlist, setMyWatchlist] = useState([]);
  const [update, setUpdate] = useState(false);
  const [symbolUpdated, setSymbolUpdated] = useState("");
  const [save, setSave] = useState(false);
  const [historical, setHistorical] = useState([]);

  useEffect(() => {
    data.map((ticker) => {
      ticker.globalNote = "";
      var meanScore = Math.sqrt((ticker.note?.earning ** 2 + ticker.note?.price ** 2 + ticker.note?.stability ** 2 + ticker.note?.timing ** 2) / 4);

      // Deduction de la couleur
      if (meanScore >= 0) {
        ticker.globalNote = <div style={{ color: "#f11" }}>Weak</div>;
      }
      if (meanScore >= 35) {
        ticker.globalNote = <div style={{ color: "#ff8800" }}>Neutral</div>;
      }
      if (meanScore >= 70) {
        ticker.globalNote = <div style={{ color: "#1cce29" }}>Strong</div>;
      }

      return ticker;
    });
    try {
      createHistorical(data);
    } catch {}

    // setHistorical([1, 2, 3, 4, 5, 6, 5, 4, 5, 4, 3, 2, 1, 5]);
    setMyWatchlist(data);
  }, [data]);

  // A revoir
  const createHistorical = (data) => {
    let minimumDayArray = [];
    let totalInvested = 0;

    data.forEach((ticker) => {
      minimumDayArray.push(ticker.price?.historical.length);
      totalInvested += ticker.invested;
    });

    const minimumDay = Math.min(...minimumDayArray);
    // const minimumDay = 2;

    // console.log(totalInvested);

    data.forEach((ticker, index) => {
      data[index].price.historical = ticker.price?.historical.map((e) => e * ticker.quantity);
    });

    let historical = [];
    let tmpDay = 0;
    for (var i = 0; i < minimumDay; i++) {
      tmpDay = 0;
      data.forEach((ticker) => {
        tmpDay += ticker.price?.historical[ticker.price?.historical.length - minimumDay + i];
      });
      historical.push(tmpDay);
    }
    // console.log(historical);
    setHistorical(historical);
  };

  const handleSave = () => {
    const uniqueSymbol = [...new Set(symbolUpdated)];

    if (uniqueSymbol.length > 0) {
      uniqueSymbol.forEach((symbol) => {
        myWatchlist.forEach((e) => {
          if (e.symbol === symbol) {
            updateSymbolWatchlist({ symbol: e.symbol, quantity: e.quantity, invested: e.invested, buyPrice: e.buyPrice });
          }
        });
      });
    }

    setSave(false);
  };
  const handleChangeQuantity = (event) => {
    let myWatchlistCopy = [...myWatchlist];
    myWatchlistCopy.map((e) => {
      if (e.symbol === event.target.id) {
        e.quantity = parseFloat(event.target.value);
        e.invested = parseInt(100 * e.quantity * e.buyPrice) / 100;

        let symbolUpdatedCopy = [...symbolUpdated];
        symbolUpdatedCopy.push(e.symbol);
        setSymbolUpdated(symbolUpdatedCopy);
        setSave(true);
      }

      return e;
    });
    setMyWatchlist(myWatchlistCopy);
  };

  const handleChangePrice = (event) => {
    let myWatchlistCopy = [...myWatchlist];
    myWatchlistCopy.map((e) => {
      if (e.symbol === event.target.id) {
        e.buyPrice = parseFloat(event.target.value);
        e.invested = parseInt(100 * e.quantity * e.buyPrice) / 100;

        let symbolUpdatedCopy = [...symbolUpdated];
        symbolUpdatedCopy.push(e.symbol);
        setSymbolUpdated(symbolUpdatedCopy);
        setSave(true);
      }

      return e;
    });
    setMyWatchlist(myWatchlistCopy);
  };

  const handleChangeTotalInvested = (event) => {
    let myWatchlistCopy = [...myWatchlist];
    myWatchlistCopy.map((e) => {
      if (e.symbol === event.target.id) {
        e.invested = parseFloat(event.target.value);
        e.quantity = parseInt((100 * e.invested) / e.buyPrice) / 100;

        let symbolUpdatedCopy = [...symbolUpdated];
        symbolUpdatedCopy.push(e.symbol);
        setSymbolUpdated(symbolUpdatedCopy);
        setSave(true);
      }

      return e;
    });
    setMyWatchlist(myWatchlistCopy);
  };

  const columns = [
    {
      name: `Asset (${myWatchlist.length}) `,
      selector: (row) => (
        <div>
          <Link className="m-r-5" to={`${process.env.PUBLIC_URL}/symbol/${row.symbol}`}>
            <FontAwesomeIcon icon={faExternalLink} />
          </Link>
          {row.name}
        </div>
      ),
      sortable: true,
      style: {
        fontSize: "14px",
        fontWeight: "600",
      },
    },
    {
      name: "Open Price",
      selector: (row) => {
        return <input id={row.symbol} style={{ fontSize: "14px" }} type="number" className="form-control" onChange={handleChangePrice} value={row.buyPrice} disabled={lock} />;
      },
    },

    {
      name: "Units (share)",
      selector: (row) => {
        return <input id={row.symbol} style={{ fontSize: "14px" }} type="number" className="form-control" onChange={handleChangeQuantity} value={row.quantity} disabled={lock} />;
      },
    },
    {
      name: "Invested",
      selector: (row) => {
        return <input id={row.symbol} style={{ fontSize: "14px" }} type="number" className="form-control" onChange={handleChangeTotalInvested} value={row.invested} disabled={lock} />;
      },
    },

    {
      name: "Last Price",
      selector: (row) => {
        return <div style={{ fontSize: "14px" }}>{`${row.price.price} ${row.price.currency} `}</div>;
      },
    },
    {
      name: "Note",
      selector: (row) => {
        return row.globalNote;
      },
    },

    {
      name: "Performance",
      selector: (row) => {
        const variation = parseInt((100 * 100 * (row.price.price - row.buyPrice)) / row.buyPrice) / 100;
        const gain = parseInt(100 * row.quantity * (row.price.price - row.buyPrice)) / 100;

        return (
          <div style={variation < 0 ? { fontSize: "14px", color: "#e8464a" } : { fontSize: "14px", color: "#3dae23" }}>
            <i className={variation < 0 ? "fa fa-long-arrow-down" : "fa fa-long-arrow-up"}></i>
            {`  ${gain} ${row.price.currency} `}

            {`  (${variation} %)`}
          </div>
        );
      },
    },
  ];
  return (
    <div className="card">
      <div onClick={handleSave} className={`${save ? "sticky-save" : "sticky-save sticky-save-hide "} `}>
        Save
      </div>

      <div className="card-body ">
        <div className="form-group">
          <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>PERFORMANCES</h2>
          <ChartPortfolio data={historical} />
          <DataTable columns={columns} data={myWatchlist} />
        </div>
      </div>
    </div>
  );
};

export default TablePortfolio;
