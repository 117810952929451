import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const customStyles = {
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      // justifyContent: "center",
    },
  },
  cells: {
    style: {
      // justifyContent: "center",
    },
  },
};

export const columns = [
  {
    name: "Name",
    selector: (row) => {
      const country = row.Country;
      const name = row.Name;
      let symbol = row.Code;
      if (country === "US") {
        symbol = symbol + ".US";
      } else {
        symbol = symbol + "." + row.Exchange;
      }

      return (
        <a href={`${process.env.PUBLIC_URL}/etf/${symbol}`} style={{ color: "black" }} target="_blank" rel="noreferrer noopener">
          <FontAwesomeIcon className="m-r-5" icon={faLink} />
          <span style={{ color: "black", overflow: "hidden", fontWeight: "500" }}>{`${row.Code} - ${row.Name}`}</span>
        </a>
      );
    },
  },
  {
    name: "Country",
    selector: (row) => {
      return row.Country;
    },
  },
  {
    name: "Currency",
    selector: (row) => {
      return row.Currency;
    },
  },
];
