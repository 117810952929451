import { firebase_app } from "../data/config";

export const getDataETF = async (etf) => {
  var dataETF = {};
  await firebase_app
    .firestore()
    .collection("etf")
    .doc(etf)
    .get()
    .then((doc) => {
      dataETF = doc.data();
    });
  return dataETF;
};
