import React, { useState } from "react";
import MiniSearch from "minisearch";
import { dbSearch } from "./dataSearch2";

import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";

const SearchEngineMarketAdvisorComparative = ({ onSubmitSymbol }) => {
  const [results, setResults] = useState([]);
  const [multiSelections, setMultiSelections] = useState([]);
  let miniSearch = new MiniSearch({
    fields: ["n", "s"], // fields to index for full-text search
    storeFields: ["n", "s", "c"], // fields to return with search results
  });

  miniSearch.addAll(dbSearch);

  const search = (e) => {
    const keyWord = e;
    let results = miniSearch.search(keyWord, { prefix: true });

    // results.sort(function (a, b) {
    //   var keyA = a.id,
    //     keyB = b.id;
    //   // Compare the 2 dates
    //   if (keyA < keyB) return -1;
    //   if (keyA > keyB) return 1;
    //   return 0;
    // });

    setResults(results.slice(0, 4));
  };

  const handleChange = (selectedOptions) => {
    setMultiSelections();
    const listSymbol = selectedOptions.map((e) => e.s);

    onSubmitSymbol(listSymbol);
  };
  return (
    <div>
      <Typeahead
        multiple
        id="keep-menu-open"
        onChange={handleChange}
        onInputChange={(text) => {
          search(text);
        }}
        selected={multiSelections}
        labelKey={(option) => `${option.n} ${option.s}`}
        options={results}
        placeholder="Search for comparison"
        renderMenuItemChildren={(option) => (
          <div onClick={() => {}}>
            {option.n}
            <div>
              <small>
                Symbol: {option.s} - {option.c}
              </small>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default SearchEngineMarketAdvisorComparative;
