import { sankey, sankeyJustify, sankeyLinkHorizontal } from "d3-sankey";

const MARGIN_Y = 20;
const MARGIN_X = 5;

export const Sankey = ({ width, height, data }) => {
  // Set the sankey diagram properties
  const sankeyGenerator = sankey() // TODO: find how to type the sankey() function
    .nodeWidth(25)
    .nodePadding(30)
    .extent([
      [MARGIN_X, MARGIN_Y],
      [width - MARGIN_X, height - MARGIN_Y],
    ])
    .nodeId((node) => node.name) // Accessor function: how to retrieve the id that defines each node. This id is then used for the source and target props of links
    .nodeAlign(sankeyJustify); // Algorithm used to decide node position

  // Compute nodes and links positions
  const { nodes, links } = sankeyGenerator(data);

  //
  // Draw the nodes
  //
  const allNodes = nodes.map((node) => {
    return (
      <g key={node.index}>
        <rect
          height={node.y1 - node.y0}
          width={sankeyGenerator.nodeWidth() - 8}
          x={node.x0 + 4}
          y={node.y0}
          // stroke={"white"}
          // stroke={node?.color ? node?.color : "#2599f3"}

          fill={"#344767"}
          fillOpacity={1}
          rx={5}
        />
      </g>
    );
  });

  //
  // Draw the links
  //
  const allLinks = links.map((link, i) => {
    const linkGenerator = sankeyLinkHorizontal();
    const path = linkGenerator(link);

    return <path key={i} d={path} stroke={link?.color ? link?.color : "#2599f3"} fill="none" strokeOpacity={0.7} strokeWidth={link.width} />;
  });

  //
  // Draw the Labels
  //
  const allLabels = nodes.map((node, i) => {
    const textAnchor = node.x0 < width / 2 ? "start" : "end";
    const valueNode = node.value.toLocaleString("en-US", { minimumFractionDigits: 0 }) + "M";
    const sizeTextinPx = 0.6 * (Math.max(...[node.name.length, String(valueNode).length + 1]) + 2) * 13;

    // const textAnchor = "end";
    return (
      <svg key={i}>
        <rect
          height={44}
          width={sizeTextinPx}
          // x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6}
          x={node.x0 < width / 2 ? node.x1 : node.x0 - sizeTextinPx}
          y={(node.y1 + node.y0) / 2 - 12}
          // stroke={"white"}
          // stroke={node?.color ? node?.color : "#2599f3"}

          fill={"white"}
          fillOpacity={0.7}
          rx={5}
        />
        <text fill={"#354766"} fontWeight="normal" x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} y={(node.y1 + node.y0) / 2} dy="0.35rem" textAnchor={textAnchor} fontSize={14}>
          {`${node.name} `}
        </text>
        <text fill={node?.color ? node?.color : "#354766"} fontWeight="bold" x={node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6} y={(node.y1 + node.y0) / 2 + 18} dy="0.35rem" textAnchor={textAnchor} fontSize={14}>
          {`${valueNode}`}
        </text>
      </svg>
    );
  });

  return (
    <div>
      <svg width={width} height={height}>
        {allLinks}
        {allNodes}
        {allLabels}
      </svg>
    </div>
  );
};
