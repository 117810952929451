import React, { Component, Fragment } from "react";
import DataTable from "react-data-table-component";
import Loader from "../tools/loader";
import { dataTableComparatif } from "./dataTable";
import { generateData } from "./functionComparativeAnalysis";
import { Line } from "react-chartjs-2";
import { calculFinancialData } from "../tools/functionCalcFinancialRatio";
import SearchEngineMarketAdvisorComparative from "../../../searchEngine/searchEngineComparative";
import { isMobile } from "react-device-detect";
import { fetchLogoUrl } from "../../../tools/fetchLogoCompany";
import CardHeader from "../../common/genericComponent/CardHeader";
import { Link } from "react-router-dom";

class ComparativeAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataFin: {},
      symbol: [],
      columns: [
        {
          name: "",
          selector: (row) =>
            row.plot ? (
              <strong onClick={() => this.plotGraph(row.variable, row.ratio)}>
                {row.ratio}
                <i className="icofont icofont-chart-bar-graph m-l-5"></i>
              </strong>
            ) : (
              <strong>{row.ratio}</strong>
            ),
        },
      ],

      data: dataTableComparatif,
      loaded: false,
      activePlot: false,
      namePlot: "",
      score: {},
    };
  }

  plotGraph = (ratio, name) => {
    const listSymbol = this.state.symbol;
    const fin = this.state.dataFin;
    var result = {};
    var resultCalcRatio = [];
    let years = [2010, 2011, 2012, 2013, 2015, 2016, 2017, 2018, 2019, 2020, 2021];
    var dataset = [];
    listSymbol.forEach((symbol) => {
      // years = Object.keys(fin[symbol]);
      resultCalcRatio = [];
      years.forEach((year) => {
        result = calculFinancialData(fin[symbol], year);
        resultCalcRatio.push(result[ratio]);
      });

      dataset.push({
        data: resultCalcRatio,
        label: symbol,
        backgroundColor: "rgba(68, 102, 242, 0.3)",
        fill: false,
        borderColor: "#" + Math.floor(Math.random() * 16777215).toString(16),
        borderWidth: 2,
      });
    });

    const lineChartData = {
      labels: years,
      datasets: dataset,
      plugins: {
        datalabels: {
          display: false,
          color: "white",
        },
      },
    };
    this.setState({ lineChartData, activePlot: true, namePlot: name });
  };

  componentDidMount = () => {
    const param = this.props.match.params.symbol.split("&");

    if (param.length > 0 && param[0] !== "new") {
      var listSym = [...this.state.symbol];
      var dataFin = this.state.dataFin;
      var data = this.state.data;
      var columns = this.state.columns;
      var score = this.state.score;

      const promisesArray = param.map((symbol) => {
        listSym.push(symbol);
        return generateData(symbol, data, columns).then((r) => {
          columns = r.columns;
          data = r.newData;
          dataFin[symbol] = r.fin1;
          score = r.score;
        });
      });

      Promise.all(promisesArray)
        .then(() => {
          this.setState({
            symbol: listSym,
            data: data,
            columns: columns,
            loaded: true,
            dataFin,
            score,
            activePlot: false,
          });
        })
        .catch((e) => console.error(e));
    } else {
      this.setState({ loaded: true });
    }
  };

  // Line Chart Options
  lineChartOptions = {
    maintainAspectRatio: true,
    legend: {
      display: true,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  datasetKeyProvider = () => {
    return Math.random();
  };

  // Handle new Symbol submit

  handleSubmit = async (symbol) => {
    this.setState({ loaded: false });
    var listSymOld = [...this.state.symbol];
    var listSym = [...symbol];

    var AddSymbol = listSym.filter(function (el) {
      return listSymOld.indexOf(el) < 0;
    });

    var DelSymbol = listSymOld.filter((x) => !listSym.includes(x));

    //  Handle Add Symvol
    if (AddSymbol.length > 0) {
      generateData(AddSymbol[0], this.state.data, this.state.columns)
        .then((r) => {
          var dataFin = this.state.dataFin;

          dataFin[symbol] = r.fin1;
          var score = r.score;

          this.setState({
            symbol: listSym,
            data: r.newData,
            columns: r.columns,
            loaded: true,
            dataFin,
            score,
            activePlot: false,
          });
        })
        .catch((e) => {
          this.setState({ loaded: true, symbol: listSym });
        });
    }
    // Handle Delete Symbol
    if (DelSymbol.length > 0) {
      listSym = listSym.filter((e) => e !== DelSymbol[0]);
      var columns = [...this.state.columns];
      var score = { ...this.state.score };
      delete score[DelSymbol[0]];

      columns = columns.filter((e) => e.name !== DelSymbol[0]);

      this.setState({
        symbol: listSym,
        columns,
        score,
        loaded: true,
        activePlot: false,
      });
    }

    //Send state to the server code
  };

  sortingScore = (score) => {
    let sortable = [];
    for (var ticker in score) {
      sortable.push({ symbol: ticker, score: score[ticker] });
    }

    sortable.sort(function (a, b) {
      return b["score"] - a["score"];
    });

    return sortable;
  };

  render() {
    return (
      <Fragment>
        <div className="container p-t-20 ">
          {/* Presentation */}

          <CardHeader title="Comparative Analysis" description="The comparative analysis is a strong tool to underline the strength of a company relatively to other companies." />

          {/* Form  */}
          <div className="card ">
            <div className="col-sm-12 col-xl-12">
              <div className="card-body ">
                <div className="form-group">
                  <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>ADD SYMBOL</h2>
                  <SearchEngineMarketAdvisorComparative
                    onSubmitSymbol={(symbol) => {
                      this.handleSubmit(symbol);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Specific Indicator  */}

          {/* Tableau  */}

          {!this.state.loaded && <Loader />}
          {this.state.columns.length > 1 && this.state.loaded ? (
            // Ranking
            <div>
              <div className="row">
                {this.sortingScore(this.state.score).map((symbol, index) => {
                  var logoPath = fetchLogoUrl(symbol.symbol);

                  return (
                    <div className={isMobile ? "col-6" : "col-3"} key={symbol.symbol}>
                      <div className="card card-body">
                        <div className="row">
                          <div className={isMobile ? "" : "col-1"}></div>
                          <div className={isMobile ? "col-12 mx-auto text-center" : "col-4"} style={isMobile ? {} : { display: "contents" }}>
                            <Link to={"/symbol/" + symbol.symbol}>
                              <img
                                src={logoPath}
                                alt="new"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                                }}
                                style={{ maxWidth: "40px", maxHeight: "35px" }}
                              />
                            </Link>
                          </div>
                          <div
                            className={isMobile ? "col-12 p-0 mx-auto" : "col-6 p-l-5 p-r-5 mx-auto"}
                            style={isMobile ? { textAlign: "center", whiteSpace: "nowrap", overflow: "hidden" } : { whiteSpace: "nowrap", overflow: "hidden" }}
                          >
                            <Link to={"/symbol/" + symbol.symbol} style={{ color: "#2f4050" }}>
                              <div style={{ fontWeight: "bold" }}>{`${symbol.symbol}`}</div>

                              <div style={{ fontWeight: "lighter", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`Rank ${index + 1}`}</div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="card ">
                <div className="col-sm-12 col-xl-12">
                  <div className="card-body ">
                    <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>RESULTS</h2>
                    <div className="datatable-react">
                      <DataTable
                        columns={this.state.columns}
                        data={this.state.data}
                        dense

                        //   conditionalRowStyles={this.conditionalRowStyles}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {/* Chart  */}

          {this.state.loaded && this.state.activePlot ? (
            <div className="card">
              <div className="card-header">
                <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>{`Chart for ${this.state.namePlot}`}</h2>
              </div>
              <div className="card-body chart-block">
                <Line data={this.state.lineChartData} options={this.lineChartOptions} datasetKeyProvider={this.datasetKeyProvider} width={778} height={300} />
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default ComparativeAnalysis;
