import React, { Component, useContext, useEffect, useState } from "react";
import { ETFContext } from "../utils/etfContext";
import { Progress } from "reactstrap";
import Loader from "../../tools/loader";
const CardCap = () => {
  const data = useContext(ETFContext);
  const [dataToDisplay, setDataToDisplay] = useState([]);

  useEffect(() => {
    const name = "Market_Capitalisation";
    let dataTmp = [];
    if (Object.keys(data).length !== 0) {
      Object.keys(data[name]).forEach((element) => {
        const value = parseInt(10 * parseFloat(data[name][element])) / 10;
        if (value > 0) {
          dataTmp.push({ name: element, value });
        }
      });

      dataTmp.sort((a, b) => b.value - a.value);

      setDataToDisplay(dataTmp);
    }
  }, [data]);

  if (Object.keys(data).length === 0) {
    return <Loader />;
  }

  return (
    <div className="card">
      <div className="card-body">
        <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>CAP</h2>
        <ul>
          {dataToDisplay.map((element) => {
            const value = element.value;
            const name = element.name;

            return (
              <li className="tableETF" key={name}>
                <div className="row">
                  <div className="col-6">{`${name} (${value}%):`}</div>
                  <div className="col-6">
                    <Progress value={value} striped />
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CardCap;
