var cov = require("compute-covariance");

export function reduceHistoricalToMinimal(watchlist) {
  let minimumDayArray = [];
  watchlist.forEach((ticker) => {
    minimumDayArray.push(ticker.price?.historical.length);
  });

  const minimumDay = Math.min(...minimumDayArray);
  watchlist.map((ticker) => {
    ticker.price.historical = ticker.price.historical.slice(-minimumDay);
    return ticker;
  });

  return watchlist;
}

export function transformToDailyReturn(watchlist) {
  watchlist.map((ticker) => {
    const historical = ticker.price.historical;
    let historicalPercent = [];

    for (let i = 1; i < historical.length; i++) {
      historicalPercent.push((historical[i] - historical[i - 1]) / historical[i - 1]);
    }

    ticker.price.historical = historicalPercent;
    return ticker;
  });

  return watchlist;
}

export function addDailyReturn(watchlist) {
  watchlist.map((ticker) => {
    const historical = ticker.price.historical;
    // (1+daily_returns.mean())**252 - 1

    const mean = historical.reduce((a, b) => a + b, 0) / historical.length;

    ticker.avgReturn = (1 + mean) ** 252 - 1;
    return ticker;
  });
  return watchlist;
}

export const getCovariance = (watchlist) => {
  let arrayOfHistorical = [];
  watchlist.forEach((ticker) => {
    arrayOfHistorical.push(ticker.price.historical);
  });
  let covariance = cov(arrayOfHistorical, { bias: true });

  return covariance;
};

export const simulatePortfolio = (myWatchlist, covariance, nbrSimulation, minimalAllocationPercent) => {
  const size = myWatchlist.length;
  let simulated = [];
  let randomWeight = [];
  let sumWeight = 0;
  let riskInvest = 0;
  let returnInvest = 0;
  let pushData = true;
  let indexPortfolio = 1;

  const minimalAllocation = minimalAllocationPercent / 100;
  const totalMinimalAlloc = minimalAllocation * size;

  for (let simulationIndex = 0; simulationIndex < nbrSimulation; simulationIndex++) {
    returnInvest = 0;
    riskInvest = 0;

    // Allocation

    randomWeight = Array.from({ length: size }, () => Math.random());
    sumWeight = randomWeight.reduce((partialSum, a) => partialSum + a, 0);

    randomWeight = randomWeight.map((w) => {
      return minimalAllocation + ((1 - totalMinimalAlloc) * w) / sumWeight;
    });

    for (let i = 0; i < size; i++) {
      for (let j = 0; j < size; j++) {
        riskInvest += randomWeight[i] * randomWeight[j] * covariance[i][j];
      }
    }
    riskInvest = Math.sqrt(252 * riskInvest);

    randomWeight.forEach((w, index) => (returnInvest += w * myWatchlist[index].avgReturn));

    pushData = true;
    simulated.every((e) => {
      if (e.y > returnInvest && e.x < riskInvest) {
        pushData = false;
        return false;
      }
      return true;
    });

    if (pushData) {
      simulated.push({ id: indexPortfolio, weight: randomWeight, x: parseInt(10000 * riskInvest) / 10000, y: parseInt(1000 * returnInvest) / 1000 });
      indexPortfolio += 1;
    }
  }
  const lengthSimulated = simulated.length;
  simulated = simulated.slice(-parseInt(lengthSimulated * 0.9));

  return simulated;
};

// export const covariance = (watchlist) => {
//   console.log("Debut Fonction Covariance");
//   // Calculate % per day :
//   let minimumDayArray = [];
//   let arrayOfHistorical = [];
//   let expectedReturn = [];
//   let std = [];
//   let weight = [];
//   let totalInvested = 0;

//   watchlist.forEach((ticker) => {
//     minimumDayArray.push(ticker.price?.historical.length);
//     totalInvested += ticker.invested;
//   });
//   //   minimumDayArray.push(252);
//   const minimumDay = Math.min(...minimumDayArray);

//   watchlist.forEach((ticker) => {
//     const tmpArray = ticker.price.historical.slice(-minimumDay);
//     let tmpArrayPercent = [];
//     for (let i = 1; i < minimumDay; i++) {
//       tmpArrayPercent.push((tmpArray[i] - tmpArray[i - 1]) / tmpArray[i - 1]);
//     }

//     expectedReturn.push(tmpArrayPercent.reduce((a, b) => a + b, 0) / tmpArrayPercent.length);
//     weight.push(ticker.invested / totalInvested);
//     std.push(getStandardDeviationAnnual(tmpArrayPercent));
//     arrayOfHistorical.push(tmpArrayPercent);
//   });

//   console.log(std);
//   console.log(weight);
//   console.log(arrayOfHistorical);

//   let covariance = cov(arrayOfHistorical, { bias: true });

//   return covariance;

// Calcul total result

//   let res = 0;
//   const size = watchlist.length;
//   for (let i = 0; i < size; i++) {
//     for (let j = 0; j < size; j++) {
//       res += weight[i] * weight[j] * covariance[i][j];
//     }
//   }

//   console.log(Math.sqrt(252 * res) * 100, "%");
// };
