import React, { Fragment } from "react";

import { BookOpen, FileText, MessageCircle } from "react-feather";
import one from "../../../assets/images/faq/1.jpg";
import two from "../../../assets/images/faq/2.jpg";
import three from "../../../assets/images/faq/3.jpg";
import four from "../../../assets/images/faq/4.jpg";
import FaqRender from "./faq_function";
import CardHeader from "../../common/genericComponent/CardHeader";

const FaqComponent = () => {
  return (
    <Fragment>
      <div className="container p-t-20">
        <CardHeader title="Contact & Help" />
        <div className="faq-wrap">
          <div className="row">
            <div className="col-xl-4 xl-100">
              <div className="card ">
                <div className="card-body">
                  <div className="media faq-widgets">
                    <div className="media-body">
                      <h5>{"Blog"}</h5>
                      <p>
                        {"Marketadvisor offers you a large content about finance and stock market available at"}
                        <a target="_blank" rel="noopener noreferrer" className="font-secondary" href="https://marketavdisor.io/blog">
                          {" "}
                          MarketAdvisor Blog{" "}
                        </a>{" "}
                      </p>
                    </div>
                    <FileText />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 xl-50 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <div className="media faq-widgets">
                    <div className="media-body">
                      <h5>{"Questions"}</h5>
                      <p>{"The frequently asked questions are available below. Please consult them to find the most frequent concerns and issues"}</p>
                    </div>
                    <BookOpen />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 xl-50 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <div className="media faq-widgets">
                    <div className="media-body">
                      <h5>{"Support"}</h5>
                      <p>
                        {"For any specific concerns about our solution, you can reach our team per email at "}
                        <a target="_blank" rel="noopener noreferrer" className="font-secondary" href="mailto:info@marketadvisor.io">
                          info@marketadvisor.io
                        </a>
                      </p>
                    </div>
                    <MessageCircle />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="header-faq">
                <h5 className="mb-0">{"FAQs"}</h5>
              </div>
              <div className="row default-according style-1 faq-accordion" id="accordionoc">
                <div className="col-xl-8 xl-60 col-lg-6 col-md-7">
                  <FaqRender theme={"start"} />
                </div>
              </div>
            </div>

            {/* Lien utiles vers le blog  */}
            <div className="col-lg-12">
              <div className="header-faq">
                <h5 className="mb-0">{"Popular Blog Post"}</h5>
              </div>
              <div className="row">
                <div className="col-xl-3 xl-50 col-md-6">
                  <div className="card features-faq product-box">
                    <div className="faq-image product-img">
                      <img className="img-fluid" src={one} alt="" />
                    </div>

                    <div className="card-body text-center">
                      <h6>{"When to Sell Stocks – Beginner’s Techniques to Optimize Your Investment"}</h6>
                      <div className="text-center">
                        <a href="https://marketadvisor.io/blog/when-to-sell-stocks-beginners-techniques-to-optimize-your-investment/" target="_blank" className="btn btn-menu ">
                          {" "}
                          Read
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 xl-50 col-md-6">
                  <div className="card features-faq product-box">
                    <div className="faq-image product-img">
                      <img className="img-fluid" src={two} alt="" />
                    </div>
                    <div className="card-body text-center">
                      <h6>{"The Myths about “Buy and Hold” and Being Fully Invested"}</h6>
                      <div className="text-center">
                        <a href="https://marketadvisor.io/blog/the-myths-about-buy-and-hold-and-being-fully-invested/" target="_blank" className="btn btn-menu ">
                          {" "}
                          Read
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 xl-50 col-md-6">
                  <div className="card features-faq product-box">
                    <div className="faq-image product-img">
                      <img className="img-fluid" src={three} alt="" />
                    </div>
                    <div className="card-body text-center">
                      <h6>{"How to balance my portfolio? – Getting Started with Investing"}</h6>
                      <div className="text-center">
                        <a href="https://marketadvisor.io/blog/how-to-balance-my-portfolio-getting-started-with-investing/" target="_blank" className="btn btn-menu ">
                          Read
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 xl-50 col-md-6">
                  <div className="card features-faq product-box">
                    <div className="faq-image product-img">
                      <img className="img-fluid" src={four} alt="" />
                    </div>
                    <div className="card-body text-center">
                      <h6>{"The 5 Best Investing Books That Can Change Your Life"}</h6>
                      <div className="text-center">
                        <a href="https://marketadvisor.io/blog/the-5-best-books-for-stock-investing/" target="_blank" className="btn btn-menu ">
                          {" "}
                          Read
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FaqComponent;
