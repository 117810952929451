export const listVariable = [
  // MarketAdvisor

  { group: "ma", value: "Note_Earning", name: "Earning by MA", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },

  { group: "ma", value: "Note_Price", name: "Price by MA", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "ma", value: "Note_Momentum", name: "Timing by MA", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "ma", value: "Note_Stability", name: "Stability by MA", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "ma", value: "Note", name: "Global Note MA", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },

  { group: "valuation", value: "earningYield", name: "Earning Yield(%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },
  { group: "valuation", value: "EV", name: "Entreprise Value", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valuation", value: "fScore", name: "F-Score", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valuation", value: "marketCap", name: "Market Cap", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valuation", value: "PayoutRatio", name: "Payout Ratio", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valuation", value: "ROC", name: "ROC (%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },
  { group: "valuation", value: "ROIC", name: "ROIC (%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },

  { group: "earning", value: "altmanZScore", name: "Altman Z-Score", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "earning", value: "eqAssetRatio", name: "Equity/Asset Ratio (%)", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "earning", value: "grossMargin", name: "Gross Margin (%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },
  { group: "earning", value: "interestCoverageRatio", name: "Interest Ratio (%)", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "earning", value: "netMargin", name: "Net Margin (%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },
  { group: "earning", value: "operatingMargin", name: "Operating Margin (%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },
  { group: "earning", value: "quickRatio", name: "Quick Ratio", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "earning", value: "ROA", name: "Return on Asset (%)", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "earning", value: "ROE", name: "Return on Equity (%)", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },

  { group: "price", value: "50DayMA", name: "50-D MA", operators: ["display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "price", value: "200DayMA", name: "200-D MA", operators: ["display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "price", value: "52WeekHigh", name: "52 Week High", operators: ["display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "price", value: "52WeekLow", name: "52 Week Low", operators: ["display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "price", value: "Beta", name: "Beta", operators: ["display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "price", value: "PE", name: "Price/Earning (PE)", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "price", value: "priceEquity", name: "Price/Equity", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "price", value: "priceSales", name: "Price/Sales", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "price", value: "lastPrice", name: "Share Price", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },

  { group: "general", value: "ADR", name: "ADR", operators: ["=", "display"], unit: "", condition: { type: "select", format: "text", option: ["true", "false"] } },
  {
    group: "general",
    value: "Country",
    name: "Country",
    operators: ["=", "display"],
    condition: {
      type: "select",
      format: "text",
      option: [
        "USA",
        "Australia",
        "Austria",
        "Belgium",
        "Brazil",
        "Canada",
        "China",
        "Denmark",
        "Finland",
        "France",
        "Germany",
        "Greece",
        "HongKong",
        "Hungary",
        "Italy",
        "Korea",
        "Mexico",
        "Netherlands",
        "Norway",
        "Poland",
        "Portugal",
        "Singapore",
        "Spain",
        "Sweden",
        "Switzerland",
        "Taiwan",
        "Turkey",
        "UK",
      ],
    },
  },
  { group: "general", value: "Currency", name: "Currency", operators: ["=", "display"], unit: "", condition: { type: "select", format: "text", option: ["USD", "EUR", "CAD", "AUD"] } },
  { group: "general", value: "DividendYield", name: " Dividend Yield (%)", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "general", value: "ExDividendDate", name: " Ex-Dividend", operators: ["display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "general", value: "exchange", name: "Exchange", operators: ["=", "display"], unit: "", condition: { type: "select", format: "text", option: ["NASDAQ", "NYSE"] } },
  { group: "general", value: "Insiders", name: " Insiders Holding (%)", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  {
    group: "general",
    value: "Sector",
    name: "Sector",
    operators: ["=", "<>"],
    condition: {
      type: "select",
      format: "text",
      option: [
        "Academic & Educational Services",
        "Basic Materials",
        "Communication Services",
        "Consumer Cyclical",
        "Consumer Defensive",
        "Consumer Goods",
        "Consumer Non-Cyclicals",
        "Energy",
        "Financial",
        "Financial Services",
        "Financials",
        "Healthcare",
        "Industrials",
        "Other",
        "Real Estate",
        "Services",
        "Technology",
        "Utilities",
      ],
    },
  },

  { group: "valueinvesting", value: "grahamSizeInSales", name: " Size in Sales", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valueinvesting", value: "grahamCurrentAssetLiabilites", name: "Graham's Current Asset/Liabilites", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valueinvesting", value: "grahamNetCurrentAssets", name: "Graham's Net Current Asset", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valueinvesting", value: "grahamEarningStability", name: "Earnings Stability", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valueinvesting", value: "grahamDividends", name: "Dividends Paid", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valueinvesting", value: "grahamGrowth", name: "Earning Growth (Graham)", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valueinvesting", value: "grahamNumber", name: "Graham Number", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valueinvesting", value: "serenityNumber", name: "Serenity Number", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valueinvesting", value: "NCAV", name: " NCAV", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valueinvesting", value: "grahamEquityDebt", name: "Graham's  Equity/Debt", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "valueinvesting", value: "grahamAssetsSize", name: "Graham's Assets Size", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
];
