import React, { Component, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ETFContext } from "./utils/etfContext";
import { getDataETF } from "../../../firebase-marketadvisor/firebase-ETF";
import CardPresentationETF from "./components/cardPresentationETF";
import CardSectorETF from "./components/cardSector";
import { isMobile } from "react-device-detect";
import CardCountriesETF from "./components/cardCountries";
import CardHoldingETF from "./components/cardHolding";
import CardDescriptionETF from "./components/cardDescriptionETF";
import CardKeyWords from "./components/cardKeyWords";
import CardPerformanceETF from "./components/cardPerformanceETF";
import CardCaracteristics from "./components/cardCaracteristics";
import CardCap from "./components/cardCap";
import { Helmet } from "react-helmet";

const ETFPresentation = () => {
  const params = useParams();
  const [dataETF, setDataETF] = useState({});
  useEffect(() => {
    getDataETF(params.etf)
      .then((res) => {
        setDataETF(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [params]);

  return (
    <ETFContext.Provider value={dataETF}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${dataETF.ISIN} | ${dataETF.Name} Perfomance & Composition ETF`}</title>

        <meta name="description" content={`${dataETF.ISIN} | Complete ${dataETF.Name} overview, analysis of financial data and composition for free.`} />
        <meta name="og:description" content={`${dataETF.ISIN} | Complete ${dataETF.Name} overview, analysis of financial data and composition for free.`} />
        <meta name="keywords" content={`ETF  investment  ${dataETF.Name} ${dataETF.ISIN} ${dataETF.Company_Name} rating wallstreet  `} />
        <link rel="canonical" href={`https://platform.marketadvisor.io/etf-analysis/${params.etf}`} />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Corporation",
            "@id": `https://platform.marketadvisor.io/etf-analysis/${params.etf}`,
            name: `${params.etf} ETF | ${dataETF.Name} Analysis & Financial Data | MarketAdvisor`,
            description: `${params.etf} | Complete ${dataETF.Name} analysis and composition for free`,
            alternateName: `${dataETF.Name}`,
            mainEntityOfPage: { "@type": "WebPage", url: `https://platform.marketadvisor.io/etf-analysis/${params.etf}`, isAccessibleForFree: true },
            tickerSymbol: `${params.etf}`,
          })}
        </script>
      </Helmet>

      <div className="container p-t-20">
        {/* <div>{params.etf}</div> */}
        <CardPresentationETF />

        <h3>{`Highlights of ${params.etf} `}</h3>
        <p style={{ fontSize: "1rem", color: "grey" }}>A brief introduction to the key features of ETFs.</p>
        <CardDescriptionETF />
        <div className="row">
          <div className={isMobile ? "col-12" : "col-6"}>
            <CardKeyWords />
          </div>{" "}
          <div className={isMobile ? "col-12" : "col-6"}>
            <CardCaracteristics />
          </div>
        </div>

        <h3>{`Composition of ${params.etf}`}</h3>
        <p style={{ fontSize: "1rem", color: "grey" }}>Understand what is in the ETF in terms of sectors, world regions and assets.</p>
        <div className="row">
          <div className={isMobile ? "col-12" : "col-6"}>
            <CardSectorETF />
            <CardCountriesETF />
          </div>

          <div className={isMobile ? "col-12" : "col-6"}>
            <CardHoldingETF />
            <CardCap />
          </div>
        </div>

        <h3>{`Performance of ${params.etf} `}</h3>
        <p style={{ fontSize: "1rem", color: "grey" }}>Overview of the Performance of the ETF.</p>
        <CardPerformanceETF />
      </div>
    </ETFContext.Provider>
  );
};

export default ETFPresentation;
