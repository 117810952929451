import React, { Fragment } from "react";
import CardHeader from "../../common/genericComponent/CardHeader";
import DefinitionETF from "./components/definitionETF";
import ScreenerETF from "./screenerETF/ScreenerETF";

const ETFPage = () => {
  return (
    <Fragment>
      <div className="container p-t-20">
        <CardHeader title={"ETF Tracker"} description={"A complete solution to find the perfect ETF for your investments"} />

        {/* <DefinitionETF /> */}

        <ScreenerETF />
      </div>
    </Fragment>
  );
};

export default ETFPage;
