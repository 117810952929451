export const getScore = ({ average, value, percent }) => {
  if (percent) value = value / 100;

  var note = 0;
  var range = 0;

  if (average?.dir === "asc") {
    if (value > average?.["75%"]) {
      range = 25 / (average?.["75%"] - average?.["50%"]);
      note = Math.min(75 + (value - average?.["75%"]) * range, 100);
      note = parseInt(note);
      return note;
    }
    if (value > average?.["50%"]) {
      range = 25 / (average?.["75%"] - average?.["50%"]);
      note = Math.min(50 + (value - average?.["50%"]) * range, 75);
      note = parseInt(note);
      return note;
    }
    if (value > average?.["25%"]) {
      range = 25 / (average?.["50%"] - average?.["25%"]);
      note = Math.min(25 + (value - average?.["25%"]) * range, 50);
      note = parseInt(note);
      return note;
    }

    range = 25 / (average?.["50%"] - average?.["25%"]);
    note = Math.max(25 + (value - average?.["25%"]) * range, 0);
    note = parseInt(note);
    return note;
  }

  if (average?.dir === "desc") {
    if (value > average?.["75%"]) {
      range = 25 / (average?.["75%"] - average?.["50%"]);
      note = Math.min(75 + (value - average?.["75%"]) * range, 100);
      note = 100 - parseInt(note);
      return note;
    }
    if (value > average?.["50%"]) {
      range = 25 / (average?.["75%"] - average?.["50%"]);
      note = Math.min(50 + (value - average?.["50%"]) * range, 75);
      note = 100 - parseInt(note);
      return note;
    }
    if (value > average?.["25%"]) {
      range = 25 / (average?.["50%"] - average?.["25%"]);
      note = Math.min(25 + (value - average?.["25%"]) * range, 50);
      note = 100 - parseInt(note);
      return note;
    }

    range = 25 / (average?.["50%"] - average?.["25%"]);
    note = Math.max(25 + (value - average?.["25%"]) * range, 0);
    note = 100 - parseInt(note);
    return note;
  }
  return 0;
};

export const getGlobalNote = ({ data }) => {
  // Calcul global note
  var globalNote = 0;
  var globalNoteGeo = 1;
  var globalNoteGeo2 = 0;
  var nbrNote = 0;

  data.forEach((e) => {
    if (!isNaN(e.score)) {
      globalNote = globalNote + e.score;
      globalNoteGeo = globalNoteGeo * Math.max(e.score, 10);
      globalNoteGeo2 = globalNoteGeo2 + e.score ** 2;
      nbrNote = nbrNote + 1;
    }
  });

  globalNote = parseInt(globalNote / nbrNote);
  globalNoteGeo = parseInt(Math.pow(globalNoteGeo, 1 / nbrNote));
  globalNoteGeo2 = parseInt(Math.sqrt(globalNoteGeo2 / nbrNote));

  var style = "";
  if (globalNoteGeo >= 0) {
    style = "notationBad";
  }
  if (globalNoteGeo >= 35) {
    style = "notationNeutral";
  }
  if (globalNoteGeo >= 70) {
    style = "notationGreat";
  }

  return { noteGlobal: globalNoteGeo, noteGlobalStyle: style };
};

export const getScorePE = ({ value }) => {
  if (value > 25) return 25;
  if (value <= 0) return 0;
  if (value < 10) return 75;
  return 50;
};

export const getScoreInterestCoverRatio = ({ value }) => {
  if (value > 2) return 90;
  if (value <= 0.6) return 20;
  if (value <= 1.5) return 40;
  return 60;
};

export const getScoreOLD = ({ average, value, percent }) => {
  if (percent) value = value / 100;

  if (average?.dir === "asc") {
    if (value > average?.["75%"]) return "A+";
    if (value > average?.["50%"]) return "B";
    if (value > average?.["25%"]) return "C";
    return "D";
  }
  if (average?.dir === "desc") {
    if (value < average?.["25%"]) return "A+";
    if (value < average?.["50%"]) return "B";
    if (value < average?.["75%"]) return "C";
    return "D";
  }
  return "N/A";
};

export const getGlobalNoteOLD = ({ data }) => {
  // Calcul global note
  var globalNote = 0;
  var nbrNote = 0;
  data.forEach((e) => {
    nbrNote = nbrNote + 1;

    if (e.score === "A+") globalNote = globalNote + 4;
    if (e.score === "B") globalNote = globalNote + 3;
    if (e.score === "C") globalNote = globalNote + 2;
    if (e.score === "D") globalNote = globalNote + 1;
  });

  globalNote = globalNote / nbrNote;

  var noteString = "";
  var style = "";
  if (globalNote >= 0) {
    noteString = "D";
    style = "notationBad";
  }
  if (globalNote >= 1.5) {
    noteString = "C";
    style = "notationNeutral";
  }
  if (globalNote >= 2.5) {
    noteString = "B";
    style = "notationGreat";
  }
  if (globalNote >= 3.5) {
    noteString = "A+";
    style = "notationGreat";
  }

  return { noteGlobal: noteString, noteGlobalStyle: style };
};

export const getScorePEOLD = ({ value }) => {
  if (value > 25) return "C";
  if (value <= 0) return "D";
  if (value < 10) return "A+";
  return "B";
};

const calculateItemsSum = (data, start, stop) => {
  let sum = 0;
  for (let j = start; j < stop; ++j) {
    sum += data[j];
  }
  return sum;
};

export const caculateMovingAverage = (data, window) => {
  const steps = data.length - window;
  const result = [];
  for (let i = 0; i < steps; ++i) {
    const sum = calculateItemsSum(data, i, i + window);
    result.push(sum / window);
  }
  return result;
};

export const calculateStoch = (data, window) => {
  data = data.slice(-window);

  // 100 * (close - lowest(low, length)) / (highest(high, length) - lowest(low, length)).

  let lowestLow = Number.MAX_VALUE;
  let highestHigh = Number.MIN_VALUE;

  // Find the lowest low and the highest high in the period
  for (let i = 0; i < data.length; i++) {
    lowestLow = Math.min(lowestLow, data[i].low);
    highestHigh = Math.max(highestHigh, data[i].high);
  }

  // Calculate the STOCH
  const stoch = (100 * (data[data.length - 1].close - lowestLow)) / (highestHigh - lowestLow);

  return stoch;
};

export const calculateRSI = (data, window) => {
  let previous_close = 0;
  let current_close = 0;
  let upChange = 0;
  let changeUp = 0;
  let last_closeHigh = 0;
  let last_closeLow = 0;
  let downChange = 0;
  let changeDown = 0;

  for (let i = 0; i < data.length; i++) {
    if (i === data.length - 1) {
      for (let x = 0; x < data.length; x++) {
        previous_close = parseFloat(data[x - 1]);
        current_close = parseFloat(data[x]);
        // HIGH
        if (current_close > previous_close) {
          upChange = current_close - previous_close;
          changeUp += upChange;
          if (x === data.length - 1) {
            last_closeHigh = current_close - previous_close;
          }
        }
        // LOW
        if (previous_close > current_close) {
          downChange = previous_close - current_close;
          changeDown += downChange;
          if (x === data.length - 1) {
            last_closeLow = previous_close - current_close;
          }
        }
        if (x === data.length - 1) {
          let AVGHigh = changeUp / window;
          let AVGLow = changeDown / window;
          let Upavg = (AVGHigh * (window - 1) + last_closeHigh) / window;
          let Downavg = (AVGLow * (window - 1) + last_closeLow) / window;
          let RS = Upavg / Downavg;
          let RSI = 100 - 100 / (1 + RS);

          return RSI;
        }
      }
    }
  }

  // const closingPrices = data.slice(-window);
  // // Calculate the average of the upward price changes
  // let avgUpwardChange = 0;
  // for (let i = 1; i < closingPrices.length; i++) {
  //   avgUpwardChange += Math.max(0, closingPrices[i] - closingPrices[i - 1]);
  // }
  // avgUpwardChange /= closingPrices.length;

  // // Calculate the average of the downward price changes
  // let avgDownwardChange = 0;
  // for (let i = 1; i < closingPrices.length; i++) {
  //   avgDownwardChange += Math.max(0, closingPrices[i - 1] - closingPrices[i]);
  // }
  // avgDownwardChange /= closingPrices.length;

  // // Calculate the RSI
  // const rsi = 100 - 100 / (1 + avgUpwardChange / avgDownwardChange);

  // return rsi;
};

export const linearRegression = (y) => {
  var lr = {};
  var n = y.length;
  var sum_x = 0;
  var sum_y = 0;
  var sum_xy = 0;
  var sum_xx = 0;
  var sum_yy = 0;

  for (var i = 0; i < y.length; i++) {
    sum_x += i;
    sum_y += y[i];
    sum_xy += i * y[i];
    sum_xx += i * i;
    sum_yy += y[i] * y[i];
  }

  lr["slope"] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
  lr["intercept"] = (sum_y - lr.slope * sum_x) / n;
  lr["r2"] = Math.pow((n * sum_xy - sum_x * sum_y) / Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)), 2);

  return lr;
};
