import { firebase_app } from "../data/config";

export const postScreener = (value) => {
  const uid = value.user.uid;
  const { filter, ranking, name } = value;
  // const idScreener = (Math.random() + 1).toString(36).substring(2) + (Math.random() + 1).toString(36).substring(2);
  const idScreener = "bagger";

  firebase_app.firestore().collection("publicScreener").doc(idScreener).set({
    filter,
    ranking,
  });

  var description = "";
  var start = true;
  // Add description based on filter
  filter.forEach((e) => {
    if (start) {
      description = description + e.name;
    } else {
      description = description + ", " + e.name;
    }
    start = false;
  });

  if (description.length > 50) description = description.slice(0, 50) + "...";

  firebase_app.firestore().collection("users").doc(uid).collection("myScreener").doc(idScreener).set({
    name,
    id: idScreener,
    description: description,
  });
};

export const getScreenerById = async (idScreener) => {
  var res = { filter: [], ranking: {} };
  var exist = false;
  await firebase_app
    .firestore()
    .collection("publicScreener")
    .doc(idScreener)
    .get()
    .then((doc) => {
      if (doc.exists) {
        res = doc.data();
        exist = true;
      }
    })
    .catch(() => {});
  return { ...res, exist };
};

export const getMyScreener = async ({ user }) => {
  const uid = user.uid;
  var myScreener = [];
  await firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("myScreener")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        myScreener.push(doc.data());
      });
    })
    .catch(() => {});
  // return { ...res, exist };
  return myScreener;
};
