import React, { Component } from "react";
import { Line } from "react-chartjs-2";

class ChartDataExplorer extends Component {
  render() {
    const lineChartData = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 100);
      gradient.addColorStop(0, "#f8fdf6");
      gradient.addColorStop(1, "#ffffff");

      return {
        labels: this.props.datas.labels,
        datasets: [
          {
            data: [...this.props.datas.data],
            backgroundColor: gradient,
            borderColor: "#13c636",
            borderWidth: 0.8,
            pointRadius: 0,
            fill: "start",
          },
        ],

        plugins: [
          {
            datalabels: {
              display: false,
              color: "white",
            },
          },
        ],
      };
    };

    const lineChartOptions = {
      responsive: true,
      interaction: {
        intersect: false,
        mode: "index",
      },
      tooltips: {
        mode: "index",

        displayColors: false,
        intersect: false,
        callbacks: {
          title: (tooltipItems) => {
            return "FY" + tooltipItems[0].xLabel.split(" ")[0];
          },
          label: (tooltipItems, data) => {
            var i = tooltipItems.index;
            return data.datasets[0].data[i] + this.props.datas.unit;
          },
        },
      },
      // onHover: (e, elements) => {

      // },
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              display: true, //this will remove only the label
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              display: true, //this will remove only the label
            },
          },
        ],
      },
    };

    const plugins = [
      {
        afterDraw: (chart) => {
          // X Axes

          let yAxis = chart.scales["y-axis-0"];
          let ctx = chart.ctx;
          let xAxis = chart.scales["x-axis-0"];
          // X Axes
          ctx.beginPath();
          ctx.moveTo(xAxis.left, yAxis.bottom);
          ctx.lineTo(xAxis.right, yAxis.bottom);
          ctx.lineWidth = 0.5;
          ctx.strokeStyle = "grey";
          ctx.stroke();
          ctx.restore();
          // Y Axes
          ctx.beginPath();
          ctx.moveTo(xAxis.left, yAxis.bottom);
          ctx.lineTo(xAxis.left, yAxis.top);
          ctx.lineWidth = 0.5;
          ctx.strokeStyle = "grey";
          ctx.stroke();
          ctx.restore();

          if (chart.tooltip?._active?.length) {
            let x = chart.tooltip._active[0]._model.x;
            ctx.save();
            ctx.beginPath();
            ctx.setLineDash([5, 7]);
            ctx.moveTo(x, chart.tooltip._active[0]._model.y);
            ctx.lineTo(x, yAxis.bottom);
            ctx.lineWidth = 0.5;
            ctx.stroke();
            ctx.restore();

            ctx.beginPath();
            ctx.moveTo(x, yAxis.top);
            ctx.lineTo(x, chart.tooltip._active[0]._model.y);
            ctx.lineWidth = 0.5;
            ctx.stroke();
            ctx.restore();

            // YAxes

            // Outer circle
            ctx.beginPath();
            ctx.fillStyle = "#13c63653";
            ctx.arc(x, chart.tooltip._active[0]._model.y, 10, 0, 2 * Math.PI);
            ctx.fill();

            ctx.closePath();
            // Inner circle
            ctx.beginPath();
            ctx.fillStyle = "#13c636";
            ctx.arc(x, chart.tooltip._active[0]._model.y, 4, 0, 2 * Math.PI);
            ctx.fill();
            ctx.closePath();
          }
        },
      },
    ];

    return (
      <div className="chart-block">
        <Line id="ctx" data={lineChartData} plugins={plugins} options={lineChartOptions} datasetKeyProvider={this.datasetKeyProvider} height={200} />
      </div>
    );
  }
}

export default ChartDataExplorer;
