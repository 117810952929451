import React, { Component, useEffect, useState, useCallback, useRef } from "react";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { sectors } from "./constant";

const TableComparisonETF = ({ data, onWeightChange }) => {
  const [dataForTable, setDataForTable] = useState([]);

  const refInput = useRef(null);

  useEffect(() => {
    const listETF = data.listETF;
    const dataETF = data.dataETF;
    let numberETF = 0;

    if (listETF) {
      let dataForTableTmp = [];

      listETF.forEach((etf) => {
        if (etf in dataETF) {
          numberETF += 1;
          dataForTableTmp.push({ ...dataETF[etf], codeWithEx: etf });
        }
      });

      setDataForTable(dataForTableTmp);
    }
  }, [data]);

  const calculateTotal = () => {
    let total = {
      code: "Total",
    };
    return total;
  };

  const changeWeight = ({ code, increment }) => {
    let weights = { ...data.weightsETF };
    let sumWeight = 0;

    Object.keys(weights).forEach((key) => {
      sumWeight = sumWeight + weights[key];
    });

    if (sumWeight + increment <= 100 && sumWeight + increment >= 0) {
      if (weights[code] + increment > 0) {
        weights[code] = weights[code] + increment;
        onWeightChange(weights);
      }
    }
  };

  const handleChangeWeight = ({ code }) => {
    const newValue = refInput.current.value;

    let weights = { ...data.weightsETF };
    let sumWeight = 0;

    // Cas 1 : Poids supérieur à 100
    weights[code] = newValue;
    onWeightChange(weights);
  };

  const columns = [
    {
      name: "Weight (%)",
      width: "200px",
      selector: (row) => {
        return (
          <div>
            <input className="rbt-input-main form-control rbt-input " type="number" ref={refInput} value={data.weightsETF[row.codeWithEx]} onChange={() => handleChangeWeight({ code: row.codeWithEx })} />
          </div>
        );
      },
    },
    {
      name: "ETF",
      selector: (row) => {
        return row.codeWithEx;
      },
    },
    {
      name: "Expense Ratio",
      selector: (row) => {
        return (100 * parseFloat(row.NetExpenseRatio)).toFixed(2) + "%";
      },
    },
    {
      name: "Yield",
      selector: (row) => {
        return parseFloat(row.Yield).toFixed(2) + "%";
      },
    },
    {
      name: "Return (%)",
      selector: (row) => {
        if (parseInt(row.Performance["Returns_1Y"]) === 0) {
          return "-";
        } else {
          if (parseInt(row.Performance["Returns_1Y"]) > 0) {
            return (
              <div style={{ color: "green", fontWeight: "600" }}>
                <FontAwesomeIcon className="m-r-5" icon={faArrowUp} />
                {`${row.Performance["Returns_1Y"]}%`}
              </div>
            );
          } else {
            return (
              <div style={{ color: "red", fontWeight: "600" }}>
                <FontAwesomeIcon className="m-r-5" icon={faArrowDown} />
                {`${row.Performance["Returns_1Y"]}%`}
              </div>
            );
          }
        }
      },
    },

    {
      name: "Risk (Volatility %)",
      selector: (row) => {
        if (parseInt(row.Performance["3y_Volatility"]) === 0) {
          return "-";
        } else {
          return <div style={{ color: "black", fontWeight: "600" }}>{`${row.Performance["3y_Volatility"]}%`}</div>;
        }
      },
    },
  ];
  sectors.forEach((sector) => {
    columns.push({
      name: sector,
      selector: (row) => {
        return parseFloat(row["Sector_Weights"][sector]["Equity_%"]).toFixed(2) + "%";
      },
    });
  });

  return (
    <div className="card">
      <div className="card-body">
        <DataTable columns={columns} data={dataForTable} />
      </div>
    </div>
  );
};

export default TableComparisonETF;
