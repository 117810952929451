import React, { Component, Fragment } from "react";
import MyWatchlist from "./MyWatchlist";
import SubscribeNow from "./subscribeNow";
import SearchEngineMarketAdvisor from "../../../searchEngine/searchEngine";
import CitationComponent from "./citationComponent";

import { isMobile } from "react-device-detect";
import BigMover from "./BigMover/BigMover";
import MostViewed from "./BannerMostViewed/MostViewed";
import LastSeenSymbol from "./BannerMostViewed/LastSeen";
import EHMarketMover from "./EarlyHourMarketMover/EHMarketMover";

class HomePageMarketAdvisor extends Component {
  state = {};
  render() {
    return (
      <Fragment>
        <div className="container p-t-20">
          <div className="row">
            <h1 className=" ">Hello world!</h1>
            <div className="col-12">
              <SubscribeNow />
            </div>

            <div className="col-12">
              <LastSeenSymbol />
            </div>
            <div className="col-12">
              <MostViewed />
            </div>
            <div className="col-12">
              <BigMover />
            </div>
            <div className="col-12">
              <EHMarketMover />
            </div>
            <div className={isMobile ? "col-12" : "col-8"}>
              <MyWatchlist />
            </div>
            <div className={isMobile ? "col-12" : "col-4"}>
              <CitationComponent />
            </div>
          </div>

          {/* {isMobile ? "" : <CalendarComponent />} */}
        </div>
      </Fragment>
    );
  }
}

export default HomePageMarketAdvisor;
