import React from "react";
import Modal from "react-bootstrap/Modal";
import Rocket from "../../../assets/images/rocket.png";

const ModalSubscribeNow = ({ show }) => {
  return (
    <Modal show={show} dialogClassName="modalCustomContainer p-0" centered size="lg" aria-labelledby="contained-modal-title-vcenter">
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-6 " style={{ backgroundColor: "#f3f6ff", borderRadius: "10px 0px 0px 10px" }}>
            <div className="h4 ms-3 mt-3">
              <span>market</span>
              <span style={{ opacity: "0.6" }}>advisor</span>
            </div>
            <div></div>
            <div className="h5 ms-3 m-t-30 text-center">
              Simple, Smart & Effective <span className="text-gradient-primary">Stock Analysis</span>
            </div>
            <div className="ms-3  text-center">
              <ul className="list-unstyled pb-3 mb-3 mt-1">
                <li className="align-items-center mb-3">
                  <i className="fa fa-check fa-lg text-primary me-2 mt-2"></i>
                  Key insight, analysis, for +40,000 stocks, as well as a robust stock screener.
                </li>
                <li className="align-items-center mb-3">
                  <i className="fa fa-check fs-xl text-primary me-2"></i>
                  Discover & watch hidden gems: automated stock analysis and intuitive data visualizations.
                </li>
                <li className="align-items-center mb-3">
                  <i className="fa fa-check fs-xl text-primary me-2"></i>
                  Build custom stock screens to find shares that meet your criteria or pick screener from acclaimed investment books.
                </li>
                <li className="align-items-center mb-3">
                  <i className="fas fa-check fs-xl text-primary me-2"></i>
                  Latest and most up-to-date financial information. 📈
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-6 py-4 my-auto text-center d-flex flex-column justify-content-center">
            <div className="h3">Free Trial: Unlock Now!</div>
            <div className="mt-2">Free 7-day trial of Premium access </div>
            <div className="mt-4">
              <a type="button" href={`${process.env.PUBLIC_URL}/subscription`} className="btn btnSecondary">
                Start my Trial !
              </a>
            </div>
            {/* <div className="mt-4">
              {" "}
              Already have an account? <a href={`${process.env.PUBLIC_URL}/signin`}>Sign In</a>
            </div> */}
            <div className="mt-4 text-gradient-primary">Invest Smarter, Grow Wealth Faster.</div>
            <div className="col-md-12 text-center d-flex flex-column justify-content-center mt-lg-4 d-none d-sm-block">
              <img src={Rocket} width="175" alt="Layer" />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSubscribeNow;
