import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const getColor = (style) => {
  if (style === "notationGreat") {
    return { colorPrimary: "#36e18e", colorSecondary: "rgba(28, 206, 41, 0.15)", colorGradient: "#36e150" };
  }
  if (style === "notationNeutral") {
    return { colorPrimary: "#ff8800", colorSecondary: "rgba(255, 200, 0, 0.15)", colorGradient: "#ff8850" };
  }
  if (style === "notationBad") {
    return { colorPrimary: "#f11", colorSecondary: "rgba(255, 17, 17, 0.15)", colorGradient: "#f11" };
  }
  return { colorPrimary: "#36e18e", colorSecondary: "rgba(28, 206, 41, 0.15)", colorGradient: "rgba(28, 206, 41, 0.55)" };
};

const ApexGraphNote = ({ data }) => {
  const [dataLocal, setDataLocal] = useState({ noteGlobal: 0, noteGlobalStyle: "notationBad" });
  useEffect(() => {
    if (!isNaN(data.noteGlobal)) {
      setDataLocal(data);
    }
  }, [data]);

  const { colorPrimary, colorSecondary, colorGradient } = getColor(dataLocal.noteGlobalStyle);

  const dataGraph = {
    series: [dataLocal.noteGlobal],

    options: {
      colors: [colorPrimary],
      chart: {
        height: 350,
        type: "radialBar",
        offsetY: -10,
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          track: { background: colorSecondary },
          dataLabels: {
            name: {
              show: false,
              fontSize: "16px",
              color: undefined,
              offsetY: -10,
            },
            value: {
              offsetY: 10,
              fontSize: "30px",
              color: colorPrimary,
              formatter: function (val) {
                return val;
              },
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          gradientToColors: [colorGradient],
          stops: [0, 100],
        },
      },
      stroke: {
        // dashArray: 4,
        lineCap: "round",
      },
      labels: ["Median Ratio"],
    },
  };

  return (
    <div id="chart" className="p-0 m-0">
      <ReactApexChart options={dataGraph.options} series={dataGraph.series} type="radialBar" height={250} />
    </div>
  );
};

export default ApexGraphNote;
