export const prebuildETF = {
  world: {
    listFilter: [
      {
        id: 846568,
        variable: "Category",
        operator: "=",
        condition: "Global Large-Cap Blend Equity",
        name: "Thematic",
      },

      {
        id: 293044,
        variable: "Company_Name",
        operator: "display",
        condition: "iShares",
        name: "Distributed by...",
      },
      {
        id: 401354,
        variable: "Returns_3Y",
        operator: ">=",
        condition: 0,
        name: "Return 3 Years  (%)",
      },
      {
        id: 763101,
        variable: "Returns_5Y",
        operator: ">=",
        condition: 0,
        name: "Return 5 Years  (%)",
      },
      {
        id: 422036,
        variable: "Volatility_3y",
        operator: ">=",
        condition: 0,
        name: "Volatility 3 Years (%)",
      },
    ],

    listRank: [
      {
        id: 35964,
        variable: "Returns_3Y",
        operator: "Descending",
        name: "Return 3 Years  (%)",
      },
      {
        id: 623473,
        variable: "Returns_5Y",
        operator: "Descending",
        name: "Return 5 Years  (%)",
      },
    ],
  },

  // USA

  usa: {
    listFilter: [
      {
        id: 384347,
        variable: "Category",
        operator: "=",
        condition: "US Large-Cap Blend Equity",
        name: "Thematic",
      },
      {
        id: 719241,
        variable: "Age",
        operator: ">=",
        condition: 4,
        name: "Age (year)",
      },
      {
        id: 803130,
        variable: "Company_Name",
        operator: "display",
        condition: "iShares",
        name: "Distributed by...",
      },
      {
        id: 474619,
        variable: "Returns_5Y",
        operator: ">=",
        condition: 0,
        name: "Return 5 Years  (%)",
      },
      {
        id: 455715,
        variable: "Returns_3Y",
        operator: ">=",
        condition: 0,
        name: "Return 3 Years  (%)",
      },
    ],

    listRank: [
      {
        id: 950321,
        variable: "Returns_3Y",
        operator: "Descending",
        name: "Return 3 Years  (%)",
      },
      {
        id: 593889,
        variable: "Returns_5Y",
        operator: "Descending",
        name: "Return 5 Years  (%)",
      },
    ],
  },

  highReturn: {
    listFilter: [
      {
        id: 803130,
        variable: "Company_Name",
        operator: "display",
        condition: "iShares",
        name: "Distributed by...",
      },
      {
        id: 474619,
        variable: "Returns_5Y",
        operator: ">=",
        condition: 0,
        name: "Return 5 Years  (%)",
      },
      {
        id: 455715,
        variable: "Returns_3Y",
        operator: ">=",
        condition: 0,
        name: "Return 3 Years  (%)",
      },
      {
        id: 545154,
        variable: "Returns_1Y",
        operator: ">=",
        condition: 0,
        name: "Return 1 Year (%)",
      },
    ],
    listRank: [
      {
        id: 566889,
        variable: "Returns_1Y",
        operator: "Descending",
        name: "Return 1 Years  (%)",
      },
      {
        id: 950321,
        variable: "Returns_3Y",
        operator: "Descending",
        name: "Return 3 Years  (%)",
      },
      {
        id: 593889,
        variable: "Returns_5Y",
        operator: "Descending",
        name: "Return 5 Years  (%)",
      },
    ],
  },

  stable: {
    listFilter: [
      {
        id: 474619,
        variable: "Returns_5Y",
        operator: ">=",
        condition: 0,
        name: "Return 5 Years  (%)",
      },
      {
        id: 455715,
        variable: "Returns_3Y",
        operator: ">=",
        condition: 10,
        name: "Return 3 Years  (%)",
      },
      {
        id: 545154,
        variable: "Returns_1Y",
        operator: ">=",
        condition: 5,
        name: "Return 1 Year (%)",
      },
      {
        id: 529557,
        variable: "Age",
        operator: ">=",
        condition: 4,
        name: "Age (year)",
      },
      {
        id: 373216,
        variable: "hasLeverage",
        operator: "=",
        condition: "false",
        name: "Has Leverage?",
      },
      {
        id: 400094,
        variable: "Volatility_3y",
        operator: ">=",
        condition: 0,
        name: "Volatility 3 Years (%)",
      },
      {
        id: 180119,
        variable: "Volatility_1y",
        operator: ">=",
        condition: 0,
        name: "Volatility 1 Year(%)",
      },

      {
        id: 469492,
        variable: "Volatility_1y",
        operator: "<=",
        condition: 30,
        name: "Volatility 1 Year(%)",
      },
    ],
    listRank: [
      {
        id: 950321,
        variable: "Returns_3Y",
        operator: "Descending",
        name: "Return 3 Years  (%)",
      },
      {
        id: 593889,
        variable: "Returns_5Y",
        operator: "Descending",
        name: "Return 5 Years  (%)",
      },
      {
        id: 458344,
        variable: "Returns_1Y",
        operator: "Descending",
        name: "Return 1 Year (%)",
      },
      {
        id: 863483,
        variable: "Volatility_1y",
        operator: "Ascending",
        name: "Volatility 1 Year(%)",
      },
      {
        id: 101582,
        variable: "Volatility_3y",
        operator: "Ascending",
        name: "Volatility 3 Years (%)",
      },
    ],
  },
};
