import React, { Component, Fragment } from "react";

import { firebase_app } from "../../../data/config";
import { getAllSymbolWatchlist } from "../../../firebase-marketadvisor/watchlistSymbol";
import { getNoteForTickers } from "../tools/functionCalculNoteTickers";
// import MyWatchlist from "./../homePage/MyWatchlist";
import { isMobile } from "react-device-detect";
// import Loader from "../tools/loader";
import CardWithRadar from "./components/cardWithRadar";
import TablePortfolio from "./components/tablePortfolio";
// import { requestAccessToProduct } from "../../../firebase-marketadvisor/firebase-data-user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { getNoteSymbolFirebase, saveNoteSymbol } from "../../../firebase-marketadvisor/firebase-CRUD-note";
import { fakePortfolio } from "./fakePortfolio";
// import { covariance } from "./function/functions";
import PortFolioOptimisation from "./components/portfolioOptimisation";
import CardHeader from "../../common/genericComponent/CardHeader";

// const MyPortfolioPage = () => {

// const [myyWatchlist, setMyWatchlist] = useState([])

//   return (  );
// }

class MyPortfolioPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retrieveData: "Calculation in Progress...",
      myWatchlist: [],
      fakeWatchlist: fakePortfolio,
      loading: true,
      access: false,
    };
  }

  retrieveData = async (ticker) => {
    let { valid, data } = await getNoteSymbolFirebase(ticker.symbol);
    let { note, price, PE } = data;

    if (!valid) {
      let { note, price, PE } = await getNoteForTickers({ symbol: ticker.symbol });
      saveNoteSymbol({ ticker: ticker.symbol, data: { note, price, PE } });
    }

    const elementWatchlist = {
      symbol: ticker.symbol,
      quantity: ticker.quantity,
      buyPrice: ticker.price,
      invested: ticker.invested,
      name: ticker.name,
      note,
      price,
      PE,
    };
    return elementWatchlist;
  };

  componentDidMount = () => {
    let myWatchlist = [];
    let promises = [];
    firebase_app.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // const access = await requestAccessToProduct({ user, product: "portfolio" });
        const access = true;
        if (access) {
          this.setState({ access });
          const watchlist = await getAllSymbolWatchlist();

          if (watchlist.length > 0) {
            let countRetrieveData = 0;
            const lengthDataToRetrieve = watchlist.length;

            for (const ticker of watchlist) {
              countRetrieveData += 1;
              promises.push(this.retrieveData(ticker));

              // myWatchlist.push(elementWatchlist);
            }

            Promise.all(promises).then((values) => {
              values.sort((a, b) => a.name.localeCompare(b.name));
              this.setState({ access, myWatchlist: values, loading: false });
            });
          } else {
            this.setState({ access, myWatchlist: [], loading: false });
          }

          //
        } else {
          this.setState({ access, loading: false });
        }
      }
    });
  };

  render() {
    return (
      <Fragment>
        <div className="container p-t-20 ">
          {/* Presentation */}

          <CardHeader title={"Advanced Portfolio"} description={"A quick and detailed view of the assets in your portfolio to make decisions and track performance."} />

          {this.state.access && (
            <div>
              {this.state.loading ? (
                <div className="row justify-content-md-center">
                  <div className={isMobile ? "col-12" : "col-12"}>
                    <div className="card col-sm-12 col-md-12 p-l-0 p-r-0">
                      <div className="p-l-0 p-r-0">
                        <div className="text-center m-t-50 m-b-50">
                          <span className="loader"></span>
                          <div className="m-t-5">{this.state.retrieveData}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {this.state.myWatchlist.length > 0 ? (
                    <div>
                      <TablePortfolio data={this.state.myWatchlist} lock={false} />
                      <div className="card m-t-20">
                        <div className="card-body ">
                          <h2>In-Depth Analysis</h2>
                          <p>A quick and detailed view of the assets in your portfolio to make decisions and track performance.</p>
                        </div>
                      </div>
                      <PortFolioOptimisation data={this.state.myWatchlist} />
                      <div className="card">
                        <div className="card-body ">
                          <div className="form-group">
                            <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>ASSETS ANALYSIS</h2>
                            <span>Track the performance of assets inside your portfolio.</span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {this.state.myWatchlist.map((elementWatchlist, index) => {
                          return <CardWithRadar key={index} data={elementWatchlist} />;
                        })}
                      </div>{" "}
                    </div>
                  ) : (
                    <div>
                      <div>
                        <div className="card m-t-20">
                          <div className="container">
                            <div className="col-sm-12 col-xl-12">
                              <div className="card-body text-center">
                                {/* <FontAwesomeIcon icon={faLock} /> */}
                                <h5>Empty Portfolio - Start Browsing !</h5>
                                <p>Your portfolio is currently empty, start looking for tickers !</p>
                                <div className=" text-center p-t-10 m-l-20 m-r-20 ">
                                  <a className="btn btnSecondary btn-lg " href={`${process.env.PUBLIC_URL}/screener`} style={{ color: "white" }}>
                                    Search with screener
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {this.state.fakeWatchlist.map((elementWatchlist, index) => {
                            return <CardWithRadar key={index} data={elementWatchlist} />;
                          })}
                        </div>
                        <TablePortfolio data={this.state.fakeWatchlist} lock={true} />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {!this.state.access && (
            <div>
              <div className="card m-t-20">
                <div className="container">
                  <div className="col-sm-12 col-xl-12">
                    <div className="card-body text-center">
                      <FontAwesomeIcon icon={faLock} />
                      <h5>Premium Feature</h5>
                      <p>The advanced portfolio is only available on paid plan. Upgrade your account to enjoy this feature</p>
                      <div className=" text-center p-t-10 m-l-20 m-r-20 ">
                        <a className="btn btnSecondary btn-lg " href={`${process.env.PUBLIC_URL}/subscription`} style={{ color: "white" }}>
                          Upgrade
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {this.state.fakeWatchlist.map((elementWatchlist, index) => {
                  return <CardWithRadar key={index} data={elementWatchlist} />;
                })}
              </div>
              <TablePortfolio data={this.state.fakeWatchlist} lock={true} />{" "}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default MyPortfolioPage;
