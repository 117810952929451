import React, { Component, useContext, useEffect, useState } from "react";
import { getAllSymbolWatchlist } from "../../../firebase-marketadvisor/watchlistSymbol";
import { firebase_app } from "../../../data/config";
import { postSymbolWatchlist } from "../../../firebase-marketadvisor/watchlistSymbol";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Loader from "../tools/loader";
import { fetchLogoUrl } from "../../../tools/fetchLogoCompany";
import { UserContext } from "../../../context/UserContext";
import { Link } from "react-router-dom";

const MyWatchlist = () => {
  const user = useContext(UserContext);
  const [watchlist, setWatchlist] = useState([]);
  const [price, setPrice] = useState({});
  const [loading, setLoading] = useState({ list: true, price: true });

  useEffect(() => {
    if (user) {
      const getPriceMultipleSymbol = firebase_app.functions().httpsCallable("getPriceMultipleSymbol");
      getAllSymbolWatchlist().then((watchlist) => {
        const watchlistReduce = watchlist.slice(0, 15);
        const symbols = watchlistReduce.map((e) => e.symbol);
        setWatchlist(watchlistReduce);
        setLoading({ list: false, price: true });
        if (symbols.length > 0) {
          getPriceMultipleSymbol({ symbol: symbols }).then((price) => {
            setPrice(price.data);
            setLoading({ list: false, price: false });
          });
        }
      });
    }
  }, [user]);

  // Handle Delete
  const handleDeleteWatchlist = ({ name, symbol }) => {
    var newWatchlist = [...watchlist].filter((e) => e.symbol !== symbol);
    postSymbolWatchlist({ name, add: false, symbol });
    setWatchlist(newWatchlist);
  };

  if (loading.list) {
    return <Loader />;
  } else {
    return (
      <div className="card ">
        <div className="container ">
          <div className="col-sm-12 col-xl-12 ">
            <div className="card-body ">
              <h5>{`My Watchlist (${watchlist.length})`}</h5>
            </div>
            {watchlist.length > 0 ? (
              <div>
                <div className="table-responsive col-12 ">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">
                          <div>{"Asset"}</div>
                        </th>

                        <th scope="col">
                          <div className="row justify-content-center ">{"Price"}</div>
                        </th>
                        <th scope="col">
                          <div className="row justify-content-center ">{"Delete"}</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {watchlist.map((symbol, index) => {
                        var logoPath = fetchLogoUrl(symbol.symbol);
                        if (index > 5) return "";
                        return (
                          <tr key={symbol.symbol} style={{ marginBottom: "0px" }}>
                            <th className="col-5">
                              <div className="row">
                                <div className="col-3" style={{ display: "contents" }}>
                                  <Link to={"/symbol/" + symbol.symbol}>
                                    <img
                                      src={logoPath}
                                      alt="new"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                                      }}
                                      style={{ maxWidth: "40px", maxHeight: "35px" }}
                                    />
                                  </Link>
                                </div>

                                <div className={isMobile ? "col-8 p-0" : "col-8"} style={isMobile ? { textAlign: "center" } : {}}>
                                  <Link to={"/symbol/" + symbol.symbol} style={{ color: "#2f4050" }}>
                                    <div>{`${symbol.symbol}`}</div>

                                    <span style={{ fontWeight: "lighter", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${symbol.name}`}</span>
                                  </Link>
                                </div>
                              </div>
                            </th>

                            {loading.price ? (
                              <td className={"font-success"}>
                                <div className="row justify-content-center" style={{ fontWeight: "bold" }}>
                                  {"- USD"}
                                </div>
                                <div className="row justify-content-center">{" - %"}</div>
                              </td>
                            ) : (
                              <td className={price[symbol.symbol].variationPercent > 0 ? "font-success" : "font-danger"}>
                                <div className="row justify-content-center" style={{ fontWeight: "bold" }}>
                                  {price[symbol.symbol].price + price[symbol.symbol].currencySymbol}
                                </div>
                                <div className="row justify-content-center">{Math.round(10000 * price[symbol.symbol].variationPercent) / 100 + "%"}</div>
                              </td>
                            )}

                            <td>
                              <div className="row justify-content-center ">
                                <div onClick={() => handleDeleteWatchlist({ name: symbol.name, symbol: symbol.symbol })} style={{ cursor: "pointer", fontSize: "large" }}>
                                  <FontAwesomeIcon icon={faXmark} />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="col my-auto m-b-20" style={{ textAlign: "center" }}>
                  <a href={"/myportfolio/"}>
                    <button className="m-t-20 btn btnPrimary btn-light">Detailed View</button>
                  </a>
                </div>
              </div>
            ) : (
              <div>
                <div className="col my-auto m-b-20" style={{ textAlign: "center" }}>
                  <span className="m-t-20 ">No symbol in your watchlist, start browsing !</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default MyWatchlist;

// class MyWatchlist extends Component {
//   state = { loading: true, watchlist: [], price: {}, symbols: [] };

//   componentDidMount = () => {
//     const getPriceMultipleSymbol = firebase_app.functions().httpsCallable("getPriceMultipleSymbol");
//     firebase_app.auth().onAuthStateChanged((user) => {
//       if (user) {
//         getAllSymbolWatchlist().then((watchlist) => {
//           const watchlistReduce = watchlist.slice(0, 15);
//           const symbols = watchlistReduce.map((e) => e.symbol);

//           if (symbols.length > 0) {
//             getPriceMultipleSymbol({ symbol: symbols }).then((price) => {
//               this.setState({ loading: false, watchlist: watchlistReduce, price: price.data, symbols });
//             });
//           } else {
//             this.setState({ loading: false, watchlist: watchlistReduce, price: {}, symbols });
//           }
//         });
//       }
//     });
//   };

//   handleDeleteWatchlist = ({ name, symbol }) => {
//     var newWatchlist = [...this.state.watchlist];
//     const symbols = newWatchlist.map((e) => e.symbol);
//     newWatchlist = newWatchlist.filter((e) => e.symbol !== symbol);
//     postSymbolWatchlist({ name, add: false, symbol });
//     this.setState({ watchlist: newWatchlist, symbols });
//   };
//   render() {
//     if (this.state.loading) {
//       return <Loader />;
//     } else {
//       return (
//         <div className="card ">
//           <div className="container ">
//             <div className="col-sm-12 col-xl-12 ">
//               <div className="card-body ">
//                 <h5>{`My Watchlist (${this.state.watchlist.length})`}</h5>
//               </div>
//               {this.state.watchlist.length > 0 ? (
//                 <div>
//                   <div className="table-responsive col-12 ">
//                     <table className="table">
//                       <thead>
//                         <tr>
//                           <th scope="col">
//                             <div>{"Asset"}</div>
//                           </th>

//                           <th scope="col">
//                             <div className="row justify-content-center ">{"Price"}</div>
//                           </th>
//                           <th scope="col">
//                             <div className="row justify-content-center ">{"Delete"}</div>
//                           </th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {this.state.watchlist.map((symbol, index) => {
//                           var logoPath = fetchLogoUrl(symbol.symbol);
//                           if (index > 5) return "";
//                           return (
//                             <tr key={symbol.symbol} style={{ marginBottom: "0px" }}>
//                               <th className="col-5">
//                                 <div className="row">
//                                   <div className="col-3" style={{ display: "contents" }}>
//                                     <a href={"/symbol/" + symbol.symbol}>
//                                       <img
//                                         src={logoPath}
//                                         alt="new"
//                                         onError={({ currentTarget }) => {
//                                           currentTarget.onerror = null; // prevents looping
//                                           currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
//                                         }}
//                                         style={{ maxWidth: "40px", maxHeight: "35px" }}
//                                       />
//                                     </a>
//                                   </div>
//                                   <div className={isMobile ? "col-8 p-0" : "col-8"} style={isMobile ? { textAlign: "center" } : {}}>
//                                     <a href={"/symbol/" + symbol.symbol} style={{ color: "#2f4050" }}>
//                                       <div>{`${symbol.symbol}`}</div>

//                                       <p style={{ fontWeight: "lighter", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${symbol.name}`}</p>
//                                     </a>
//                                   </div>
//                                 </div>
//                               </th>

//                               <td className={this.state.price[symbol.symbol].variationPercent > 0 ? "font-success" : "font-danger"}>
//                                 <div className="row justify-content-center" style={{ fontWeight: "bold" }}>
//                                   {this.state.price[symbol.symbol].price + this.state.price[symbol.symbol].currencySymbol}
//                                 </div>
//                                 <div className="row justify-content-center">{Math.round(10000 * this.state.price[symbol.symbol].variationPercent) / 100 + "%"}</div>
//                               </td>

//                               <td>
//                                 <div className="row justify-content-center ">
//                                   <div onClick={() => this.handleDeleteWatchlist({ name: symbol.name, symbol: symbol.symbol })} style={{ cursor: "pointer", fontSize: "large" }}>
//                                     <FontAwesomeIcon icon={faXmark} />
//                                   </div>
//                                 </div>
//                               </td>
//                             </tr>
//                           );
//                         })}
//                       </tbody>
//                     </table>
//                   </div>

//                   <div className="col my-auto m-b-20" style={{ textAlign: "center" }}>
//                     <a href={"/myportfolio/"}>
//                       <button className="m-t-20 btn btnPrimary btn-light">Detailed View</button>
//                     </a>
//                   </div>
//                 </div>
//               ) : (
//                 <div>
//                   <div className="col my-auto m-b-20" style={{ textAlign: "center" }}>
//                     <span className="m-t-20 ">No symbol in your watchlist, start browsing !</span>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       );
//     }
//   }
// }

// export default MyWatchlist;
