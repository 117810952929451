import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment, useContext } from "react";
import Form from "react-bootstrap/Form";
import { UserContext } from "../../../../context/UserContext";
import { toast } from "react-toastify";

const ApiKey = () => {
  const user = useContext(UserContext);

  return (
    <Fragment>
      <div className="card m-t-10">
        <div className="container">
          <div className="col-sm-12 col-xl-12">
            <div className="card-body ">
              <h4>
                <strong>Secret Key</strong>
              </h4>
              <div className="text-center">
                <p>Your key is an unique ID of your account and allow you to connect to Marketadvisor's tools such as Chrome Plugin. Don't share the key.</p>
                <div>
                  <Form.Control type="text" placeholder={user.uid.split("").reverse().join("")} aria-label="Disabled input example" disabled readOnly />
                  <button
                    className="btn btnPrimary m-t-20"
                    onClick={() => {
                      navigator.clipboard.writeText(user.uid.split("").reverse().join(""));
                      toast.info("Copy to Clipboard");
                    }}
                  >
                    <FontAwesomeIcon className="m-r-10" icon={faCopy} />
                    {"Copy Key "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ApiKey;
