import React from "react";

const PortFolioOptimisation = () => {
  // Insert Scatter Chart
  return (
    <div className="card">
      <div className="card-body ">
        <div className="form-group">
          <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>PORTFOLIO OPTIMISATION</h2>
          <span>
            For every asset, you got an expected return and a risk implied with this asset. But by combining multiple asset, you could find an optimal balance between every asset to maximize profit and reduce the global risk.
          </span>

          <div className="text-center m-t-50">
            <a className="btn btnSecondary" href={`${process.env.PUBLIC_URL}/portfoliomanagement`}>
              {" "}
              Optimize my Portfolio
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortFolioOptimisation;
