function convertArrayOfObjectsToCSV(screener) {
  // Initialisation du fichier :
  let fileCSV;
  const columnDelimiter = ";";
  const lineDelimiter = "\n";
  let listVariable = Object.keys(screener[0]);
  listVariable = listVariable.filter((e) => {
    return e !== "rank";
  });

  // Ajout du header
  fileCSV = "" + columnDelimiter;
  fileCSV += listVariable.join(columnDelimiter);
  fileCSV += lineDelimiter;

  screener.forEach((ticker, index) => {
    let ctr = 0;
    fileCSV += parseInt(index + 1) + columnDelimiter + ticker["Symbol"] + columnDelimiter + ticker["Name"] + columnDelimiter;

    listVariable.forEach((variable) => {
      if (variable !== "rank" && variable !== "Symbol" && variable !== "Name") {
        if (ctr > 0) fileCSV += columnDelimiter;
        const value = ticker[variable];

        var valueToPush = value;
        if (isNaN(value)) {
          valueToPush = value;
        } else {
          valueToPush = parseFloat(value);
        }
        fileCSV += valueToPush;

        ctr++;
      }
    });
    fileCSV += lineDelimiter;
  });

  return fileCSV;
}

function convertName(name) {
  let nameToConvert = name.replace(/([A-Z])/g, " $1").trim();
  nameToConvert = nameToConvert.charAt(0).toUpperCase() + nameToConvert.slice(1);

  return nameToConvert;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export function downloadCSV(screener) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(screener);
  if (csv == null) return;

  const filename = "My Screener.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}
