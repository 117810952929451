import React, { Component } from "react";
import SummaryRadar from "../../symbolPage/analysis/widgetAnalysis/summaryRadar";
import { fetchLogoUrl } from "../../../../tools/fetchLogoCompany";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

const CardWithRadar = ({ data }) => {
  const elementWatchlist = data;

  var logoPath = fetchLogoUrl(elementWatchlist.symbol);
  var dataToPlot = [elementWatchlist.note?.earning, elementWatchlist.note?.stability, elementWatchlist.note?.price, elementWatchlist.note?.timing];

  return (
    <div className={isMobile ? "col-12" : "col-4"} key={elementWatchlist.symbol}>
      <div className="card card-body">
        <div className="row">
          <div className={isMobile ? "" : "col-1"}></div>
          <div className={isMobile ? "col-12 mx-auto text-center" : "col-4"} style={isMobile ? {} : { display: "contents" }}>
            <Link to={"/symbol/" + elementWatchlist.symbol}>
              <img
                src={logoPath}
                alt="new"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                }}
                style={{ maxWidth: "50px" }}
              />
            </Link>
          </div>
          <div className={isMobile ? "col-12 p-0 mx-auto" : "col-6 p-l-5 p-r-5 mx-auto"} style={isMobile ? { textAlign: "center", whiteSpace: "nowrap", overflow: "hidden" } : { whiteSpace: "nowrap", overflow: "hidden" }}>
            <div className="" style={{ fontSize: "0.9rem", fontWeight: "400" }}>
              <div className="m-r-10" style={{ fontSize: "1rem", fontWeight: "600" }}>{`${elementWatchlist.name} `}</div>
              <div className="m-r-10">{`${elementWatchlist.symbol} / ${elementWatchlist?.price?.price}${elementWatchlist?.price?.currency}`}</div>

              <div style={elementWatchlist?.price?.variation < 0 ? { color: "#e8464a" } : { color: "#3dae23" }}>
                <i className={elementWatchlist?.price?.variation < 0 ? "fa fa-long-arrow-down" : "fa fa-long-arrow-up"}></i>
                {`  ${parseInt(100 * 100 * elementWatchlist?.price?.variation) / 100} %`}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 m-t-20">
          <SummaryRadar valueChart={dataToPlot} />
        </div>
      </div>
    </div>
  );
};

export default CardWithRadar;
