import React, { Fragment, useState } from "react";
import { firebase_app } from "../../../data/config";

const UserPanel = () => {
  const [name, setName] = useState("");

  const getName = () => {
    if (name !== "") {
      return 0;
    }
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        setName(user.email);
      }
    });
  };
  getName();
  return (
    <Fragment>
      <div className="sidebar-user text-center " style={{ position: "unset", height: "17vh", paddingTop: "3vh" }}>
        <p style={{ color: "white", fontSize: "16px", fontWeight: "400" }}>{"Welcome"}</p>
        <p style={{ color: "white", fontSize: "12px", fontWeight: "400" }}>{name}</p>
        <div className="d-flex justify-content-center m-t-10">
          <a href={`${process.env.PUBLIC_URL}/profile`} style={{ display: "flex", alignItems: "center", fontSize: "16px", color: "white", textDecoration: "underline" }}>
            See Profile
          </a>
        </div>
      </div>
    </Fragment>
  );
};

export default UserPanel;
