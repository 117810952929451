import { firebase_app } from "../data/config";
import { getAverageIndustry, getFinancialData, getFinancialDataQuarter } from "../firebase-marketadvisor/firebase-financial-data";
import { getDividends } from "./../firebase-marketadvisor/firebase-data-dividends";

// GET INFO FROM FIREBASE

export const getHistorical = firebase_app.functions().httpsCallable("getHistorical");

export const getFinancial = ({ symbol, data }) => {
  return new Promise((res, rej) => {
    Promise.all([getFinancialDataQuarter(symbol), getFinancialData(symbol), getAverageIndustry({ sector: data.sector, industry: data.industry })])
      .then(([resDataFinancialQuarter, resDataFinancial, averageSector]) => {
        var myKey = Object.keys(resDataFinancial).sort().reverse();
        var listYearAvailable = [];
        for (let i = 0; i < myKey.length; i++) {
          listYearAvailable.push(myKey[i]);
        }
        var tmpSelectedValueYear = myKey[0];

        res({
          financialQuarter: resDataFinancialQuarter,
          financial: resDataFinancial,
          year: tmpSelectedValueYear,
          listYearAvailable,
          averageSector: averageSector,
        });
      })
      .catch(() => {
        rej("Error occured");
      });
  });
};

export const getInformationTicker = ({ symbol }) => {
  // console.log("USER", user, data);

  return new Promise((res, rej) => {
    const getPriceAndDataSymbol = firebase_app.functions().httpsCallable("getPriceAndDataSymbol");

    getPriceAndDataSymbol({ symbol: symbol })
      .then((result) => {
        let informationTicker = result.data;
        informationTicker.variationPercent = Math.round(parseFloat(informationTicker.variationPercent) * 10000) / 100;
        res(informationTicker);
      })
      .catch(() => {
        rej("Symbol not valid");
      });
  });
};

export const getNewsQuery = ({ symbol }) => {
  return new Promise((res, rej) => {});
};
