let listFaq = [
  //Start
  {
    theme: "FAQ",
    title: "What services does your website offer?",
    answer: "We offer online subscription services that provide private investors with stock market screening, ranking, and portfolio analytics to help them beat stock markets all over the world.",
  },
  {
    theme: "FAQ",
    title: "How does MarketAdvisor help me beat the stock market?",
    answer:
      "Our website provides you with massive data power, allowing you to screen and rank stocks based on your investment criteria. You can also analyze your portfolio to identify strengths and weaknesses and make informed investment decisions.",
  },
  {
    theme: "FAQ",
    title: "What kind of data do you provide",
    answer: "We provide a wide range of data, including stock prices, market trends, financial statements, and news and analysis from reputable sources.",
  },
  {
    theme: "FAQ",
    title: "How do I get started with your services?",
    answer: "To get started, simply sign up for one of our subscription plans and create an account. You'll then have access to our suite of tools and resources to help you beat the stock market.",
  },
  {
    theme: "FAQ",
    title: "What kind of investors is your website designed for?",
    answer:
      "Our website is designed for individual private investors who are looking to take their investment strategies to the next level. Whether you're a beginner or an experienced investor, our tools and resources can help you make smarter investment decisions.",
  },
  {
    theme: "FAQ",
    title: "How do you ensure the accuracy and reliability of your data?",
    answer: "We use a combination of automated data feeds and human analysis to ensure the accuracy and reliability of our data. We also partner with reputable data providers to ensure that our data is up-to-date and relevant.",
  },
  {
    theme: "FAQ",
    title: "Can I cancel my subscription at any time?",
    answer: "Yes, of course, you can cancel your subscription at any time. However, please note that we do not offer refunds for partial months of service.",
  },
];

export function getFaq(theme) {
  let listFaqfinal = [];

  switch (theme) {
    default:
      break;
    case "start":
      listFaqfinal = listFaq.filter((t) => t.theme === "FAQ");
      break;
  }

  for (var i = 0; i < listFaqfinal.length; i++) {
    listFaqfinal[i]["collapse"] = i;
    listFaqfinal[i]["stateCollapse"] = false;
  }

  return listFaqfinal;
}

export function getFaqTitle(theme) {
  let Faqtitle = "";

  switch (theme) {
    default:
      break;
    case "start":
      Faqtitle = "FAQ";
      break;
    case "security":
      Faqtitle = "Sécurité de mes informations";
      break;
    case "work":
      Faqtitle = "Fonctionnement de Qwantic Bot Trading";
      break;
    case "subscription":
      Faqtitle = "Abonnement et résiliation";
      break;
  }

  return Faqtitle;
}
