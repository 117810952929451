export const abonnement = [
  {
    name: "Free",
    prices: 0,
    idPaypal: "",
    productID: "",
    option: 0,
  },
  {
    name: "Starter",
    prices: 9.0,
    idPaypal: "P-9AN21981EY588751SMEYOOFY",
    productID: "prod_NGqm67B9TRBlh5",
    option: 1,
  },
  {
    name: "Investor",
    prices: 14.9,
    idPaypal: "P-72490630HG145620CME24ZFQ",
    productID: "prod_NGqmCHNgkxfG5C",
    option: 2,
  },
  // {
  //   name: "Pro",
  //   prices: 50,
  //   idPaypal: "P-7TA6003454913381FMFMEB4Q",
  //   productID: "prod_NGqoOx2dgB9vrW",
  //   option: 3,
  // },
];
