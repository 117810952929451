import React, { Component, useState, useEffect } from "react";
import SearchEngineCompareETF from "../../../../searchEngine/searchEngineCompareETF";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

const FindAndCompareETF = ({ onAddETF, data }) => {
  const [currentETFSelect, setCurrentETFSelect] = useState("");
  const [currentListETFSelected, setCurrentListETFSelected] = useState([]);

  useEffect(() => {
    if (data?.listETF) {
      setCurrentListETFSelected(data.listETF);
    }
  }, [data]);

  const handleSelectETF = (etf) => {
    setCurrentETFSelect(etf);
  };

  const handleClickAddETF = () => {
    if (currentETFSelect !== "") {
      onAddETF({ code: currentETFSelect, add: true });
      setCurrentETFSelect("");
    }
  };

  return (
    <div className="card m-t-20 ">
      <div className="card-body ">
        {/* List des code actuellement choisies  */}
        <div className="row m-b-20 col">
          {currentListETFSelected.map((e) => {
            return (
              <div key={e} className="pill-parameters" onClick={() => onAddETF({ code: e, add: false })}>
                {`${e}`}
                <FontAwesomeIcon className="m-l-10" icon={faTrashAlt} />
              </div>
            );
          })}
        </div>

        <SearchEngineCompareETF onSelectETF={handleSelectETF} />
        <div className="text-center m-t-20">
          <button className="btn btnSecondary" onClick={handleClickAddETF}>
            {`Add ${currentETFSelect}`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FindAndCompareETF;
