import React, { Fragment, useEffect, useContext } from "react";
import Header from "./common/header-component/header";
import Sidebar from "./common/sidebar-component/sidebar";
import { useLocation } from "react-router-dom";
import Footer from "./common/footer";
import { isMobile } from "react-device-detect";
import { ToastContainer } from "react-toastify";
import MenuSideBar from "../menu/menu";
import { useState } from "react";
import Cookies from "js-cookie";
import { UserContext } from "../context/UserContext";

// import Loader from "./common/loader";
import ModalJoinUs from "./marketadvisor/modal/modalJoinUs";

const AppLayout = (props) => {
  const [menuOpen, setMenuOpen] = useState(!isMobile);
  const [popUp, setPopUp] = useState(false);
  const [blockRender, setBlockRender] = useState(false);
  const user = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      let nbr_visit = Cookies.get("nbr_visit");

      if (!nbr_visit) {
        Cookies.set("nbr_visit", "1", { expires: 1 });
      } else {
        try {
          nbr_visit = parseInt(nbr_visit) + 1;
          Cookies.set("nbr_visit", nbr_visit, { expires: 1 });
        } catch {
          Cookies.set("nbr_visit", "1", { expires: 1 });
        }
      }

      if (nbr_visit > 1000) {
        setPopUp(true);
        setBlockRender(true);
      }

      setTimeout(() => setPopUp(true), 60000);
    }
  }, []);

  const handleClickMenu = () => {
    if (isMobile) {
      setMenuOpen(!menuOpen);
    }
  };

  let location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [location]);

  return (
    <Fragment>
      {/* <Loader /> */}
      <ModalJoinUs show={popUp} />

      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <MenuSideBar menuOpen={menuOpen} />

          <div className="page-body" onClick={handleClickMenu}>
            <Header onClickMenu={handleClickMenu} />
            {!blockRender && <div>{props.children}</div>}
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AppLayout;
