import React, { useContext, useState } from "react";
import { SymbolContext } from "../../utils/symbolContext";

const InfoComponent = () => {
  const [displayMore, setDisplayMore] = useState(true);
  const { data, symbol, isLoading, error } = useContext(SymbolContext);

  return (
    <div className="card ">
      <div className="container">
        <div className="col-sm-12 col-xl-12">
          <div className="m-10 "></div>

          <div className="card-body ">
            <div className="row">
              <div className="col">
                {displayMore && data.description ? (
                  <p>
                    <strong>{data.name + " - "}</strong>
                    {data.description.length > 250 ? data.description.slice(0, 250) + "..." : data.description}
                  </p>
                ) : (
                  <p>
                    <strong>{data.name + " - "}</strong>
                    {data.description}
                  </p>
                )}

                {!data.description && <p>Nothing to show here !</p>}
                <button
                  className="btn btnPrimary btn-light m-b-15"
                  onClick={() => {
                    setDisplayMore(!displayMore);
                  }}
                >
                  {displayMore ? "Read more ..." : "Show less..."}
                </button>
                <div className="p-t-10" style={{ borderTop: "0.5px solid ", borderColor: "#7777774f" }}>
                  <h5>
                    <span className="badge badge-light">{data.industry}</span>
                    <span className="badge badge-light">{data.sector}</span>
                    <span className="badge badge-light">{data.country}</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoComponent;

// class InfoComponent extends Component {
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       description: this.props.data.description,
//       industry: this.props.data.industry,
//       sector: this.props.data.sector,
//       name: this.props.data.name,
//       country: this.props.data.country,
//       website: this.props.data.website,
//       displayMore: true,
//     };
//   }

//   render() {
//     return (
//       <div className="card ">
//         <div className="container">
//           <div className="col-sm-12 col-xl-12">
//             <div className="m-10 "></div>

//             <div className="card-body ">
//               <div className="row">

//                 <div className="col">
//                   {displayMore && data.description ? (
//                     <p>
//                       <strong>{data.name + " - "}</strong>
//                       {data.description.length > 250 ? data.description.slice(0, 250) + "..." : this.state.description}
//                     </p>
//                   ) : (
//                     <p>
//                       <strong>{this.state.name + " - "}</strong>
//                       {this.state.description}
//                     </p>
//                   )}

//                   {!this.state.description && <p>Nothing to show here !</p>}
//                   <button
//                     className="btn btn-light m-b-15"
//                     onClick={() => {
//                       this.setState({ displayMore: !this.state.displayMore });
//                     }}
//                   >
//                     {this.state.displayMore ? "Read more ..." : "Show less..."}
//                   </button>
//                   <div className="p-t-10" style={{ borderTop: "0.5px solid ", borderColor: "#7777774f" }}>
//                     <h5>
//                       <span className="badge badge-light">{this.state.industry}</span>
//                       <span className="badge badge-light">{this.state.sector}</span>
//                       <span className="badge badge-light">{this.state.country}</span>
//                     </h5>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default InfoComponent;
