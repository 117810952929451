import {
  dataFinancialIncomeStatement1,
  dataFinancialIncomeStatement2,
  dataFinancialIncomeStatement3,
  dataFinancialIncomeStatement4,
  dataFinancialIncomeStatement5,
  dataFinancialBalanceSheet1,
  dataFinancialBalanceSheet2,
  dataFinancialBalanceSheet3,
  dataFinancialBalanceSheet4,
  dataFinancialBalanceSheet5,
  dataFinancialBalanceSheet6,
  dataFinancialBalanceSheet7,
  dataFinancialCashFlow1,
  dataFinancialCashFlow2,
  dataFinancialCashFlow3,
  dataFinancialCashFlow4,
  dataFinancialCashFlow5,
  dataFinancialCashFlow6,
} from "./variableForFinancialData";

function convertArrayOfObjectsToCSV(financial) {
  // Initialisation du fichier :
  let fileCSV;
  const columnDelimiter = ";";
  const lineDelimiter = "\n";
  const fiscalPeriods = Object.keys(financial);

  // Ajout du header
  fileCSV = "Fiscal Period" + columnDelimiter;
  fileCSV += fiscalPeriods.join(columnDelimiter);
  fileCSV += lineDelimiter;

  const listToUse = [
    [{ variable: "delimiter", name: "/---------------------------------Income Statement---------------------------------/" }],
    dataFinancialIncomeStatement1,
    dataFinancialIncomeStatement2,
    dataFinancialIncomeStatement3,
    dataFinancialIncomeStatement4,
    dataFinancialIncomeStatement5,
    [{ variable: "delimiter", name: "/----------------------------------Balance Sheet----------------------------------/" }],
    dataFinancialBalanceSheet1,
    dataFinancialBalanceSheet2,
    dataFinancialBalanceSheet3,
    dataFinancialBalanceSheet4,
    dataFinancialBalanceSheet5,
    dataFinancialBalanceSheet6,
    dataFinancialBalanceSheet7,
    [{ variable: "delimiter", name: "/-----------------------------------Cash Flow-----------------------------------/" }],
    dataFinancialCashFlow1,
    dataFinancialCashFlow2,
    dataFinancialCashFlow3,
    dataFinancialCashFlow4,
    dataFinancialCashFlow5,
    dataFinancialCashFlow6,
  ];

  listToUse.forEach((listVariableFinance) => {
    listVariableFinance.forEach((variableFinancials) => {
      const { variable, name } = variableFinancials;

      fileCSV += name + columnDelimiter;
      let ctr = 0;
      // For Each Fiscal Period :
      if (variable !== "delimiter") {
        fiscalPeriods.forEach((period) => {
          if (ctr > 0) fileCSV += columnDelimiter;

          if (variable in financial[period]) {
            const value = financial[period][variable];

            if (value === null || isNaN(value)) {
              fileCSV += "";
            } else {
              var valueToPush = value;
              if (isNaN(value)) {
                valueToPush = value;
              } else {
                valueToPush = parseFloat(value);
              }
              fileCSV += valueToPush;
            }
          } else {
            fileCSV += "";
          }

          ctr++;
        });
      }
      // Saut à la ligne
      fileCSV += lineDelimiter;
    });
  });
  return fileCSV;
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export function downloadCSV(financial, symbol) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(financial);
  if (csv == null) return;

  const filename = "financial" + symbol.split(".")[0] + ".csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}
