import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { getLastSeen } from "../../../../firebase-marketadvisor/firebase-data-user";
import { firebase_app } from "../../../../data/config";
import { fetchLogoUrl } from "../../../../tools/fetchLogoCompany";
import { Link } from "react-router-dom";

class LastSeenSymbol extends Component {
  state = {
    mostViewed: [],
    loading: true,
  };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        getLastSeen({ user }).then((res) => {
          this.setState({ mostViewed: res, loading: false });
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <div></div>;
    }

    return (
      <div className="">
        <h5>My Recent Search</h5>
        <div className="row">
          {this.state.mostViewed.map((symbol, index) => {
            if (index <= 5) {
              var logoPath = fetchLogoUrl(symbol.symbol);
              // try {
              //   try {
              //     logoPath = require(`../../../../assets/images/logo/us/${symbol.symbol}.png`).default;
              //   } catch {
              //     logoPath = require(`../../../../assets/images/logo/other/${symbol.symbol.split(".")[0]}.png`).default;
              //   }
              // } catch {
              //   logoPath = require(`../../../../assets/images/logo/noLogo.png`).default;
              // }

              return (
                <div className={isMobile ? "col-6" : "col-3"} key={symbol.symbol}>
                  <div className="card card-body">
                    <div className="row">
                      <div className={isMobile ? "" : "col-1"}></div>
                      <div className={isMobile ? "col-12 mx-auto text-center" : "col-4"} style={isMobile ? {} : { display: "contents" }}>
                        <Link to={"/symbol/" + symbol.symbol}>
                          <img
                            src={logoPath}
                            alt="new"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                            }}
                            style={{ maxWidth: "40px", maxHeight: "35px" }}
                          />
                        </Link>
                      </div>
                      <div
                        className={isMobile ? "col-12 p-0 mx-auto mainColor" : "col-6 p-l-5 p-r-5 mx-auto"}
                        style={isMobile ? { textAlign: "center", whiteSpace: "nowrap", overflow: "hidden" } : { whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        <Link to={"/symbol/" + symbol.symbol} className={"maincolortext"}>
                          <div style={{ fontWeight: "bold" }}>{`${symbol.symbol}`}</div>

                          <div className={"secondcolortext"} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${symbol.name}`}</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

export default LastSeenSymbol;
