import React, { Fragment, useEffect, useState } from "react";
import CardHeader from "../../common/genericComponent/CardHeader";
import FindAndCompareETF from "./component/FindAndAddETF";
import { getDataETF } from "../../../firebase-marketadvisor/firebase-ETF";
import TableComparisonETF from "./component/TableComparisonETF";
import GraphicComparisonETF from "./component/GraphicComparisonETF";

const PageCompareETF = () => {
  const [listETF, setListETF] = useState([]);
  const [dataETF, setDataETF] = useState({});
  const [weightsETF, setWeightsETF] = useState({});

  useEffect(() => {
    // Extract New Data
    let dataETFTmp = { ...dataETF };
    let weightsETF_tmp = {};
    let numberETF = 0;
    // let isUpdated = false;

    if (listETF.length > 0) {
      listETF.forEach((etf) => {
        if (!(etf in dataETFTmp)) {
          getDataETF(etf)
            .then((res) => {
              //   isUpdated = true;

              if (res) {
                dataETFTmp[etf] = res;

                listETF.forEach((etf) => {
                  if (etf in dataETFTmp) {
                    numberETF += 1;
                  }
                });

                // Handle Weight
                listETF.forEach((etf) => {
                  if (etf in dataETFTmp) {
                    weightsETF_tmp[etf] = parseInt(100 / numberETF);
                  }
                });
                // Add total Line

                setDataETF(dataETFTmp);
                setWeightsETF(weightsETF_tmp);
              } else {
                handleAddETF({ code: etf, add: false });
              }
            })
            .catch((e) => {
              handleAddETF({ code: etf, add: false });
            });
        }
      });
    }
  }, [listETF]);

  const handleAddETF = ({ code, add }) => {
    let listETFTmp = [...listETF];
    if (add) {
      // Add ETF to list

      if (!listETF.includes(code)) {
        listETFTmp.push(code);
        setListETF(listETFTmp);
      }
    } else {
      // Delete ETF to list
      if (listETF.includes(code)) {
        listETFTmp = listETFTmp.filter((e) => e !== code);
        setListETF(listETFTmp);
      }
    }
  };

  const handleWeightChange = (weights) => {
    // console.log(weights);
    setWeightsETF(weights);
  };
  return (
    <Fragment>
      <div className="container p-t-20">
        <CardHeader title={"Find & Compare ETF"} description={"A solution to compare ETF inside your portfolio"} />
        <h2>Find and Add ETF</h2>
        <FindAndCompareETF onAddETF={handleAddETF} data={{ listETF }} />
        <h2>Results</h2>

        <TableComparisonETF data={{ dataETF, listETF, weightsETF }} onWeightChange={handleWeightChange} />
        <GraphicComparisonETF data={{ dataETF, listETF, weightsETF }} />
      </div>
    </Fragment>
  );
};

export default PageCompareETF;
