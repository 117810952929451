import React, { useContext, useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import ChartDataExplorer from "../assetForDataExplorer/chartDataExplorer";
import { calculFinancialData } from "../../../tools/functionCalcFinancialRatio";
import { getGlobalNote, caculateMovingAverage, calculateStoch, calculateRSI } from "./toolWidget";
import LinearGauge from "./linearGauge";
import { firebase_app } from "../../../../../data/config";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { SymbolContext } from "../../utils/symbolContext";
import ApexGraphNote from "./components/apexGraphNote";

const MomentumWidget = ({ dataFinancial, onNote }) => {
  const { data, symbol, isLoading, error, queryHistorical } = useContext(SymbolContext);

  const [note, setNote] = useState({ noteGlobal: "", noteGlobalStyle: "" });
  const [dataTable, setDataTable] = useState({ data: [], columns: [] });

  useEffect(() => {
    if (queryHistorical.isPending === false) {
      const historicalPrice = queryHistorical.data.data;
      const dataPriceOHLC = historicalPrice;

      if (historicalPrice.length > 0) {
        const dataPrice = dataPriceOHLC.map((e) => {
          return e.close;
        });

        const MA89 = caculateMovingAverage(dataPrice, 89);
        const MA21 = caculateMovingAverage(dataPrice, 21);
        const stoch89 = calculateStoch(dataPriceOHLC, 89);
        const RSI89 = calculateRSI(dataPrice, 89);

        const currentPos89 = dataPrice.slice(-1)[0] - MA89.slice(-1)[0];
        const currentPos21 = dataPrice.slice(-1)[0] - MA21.slice(-1)[0];

        // Note Momentum
        var valueMomentum = "";
        var noteMomentum = "";
        if (currentPos89 > 0 && currentPos21 > 0) {
          valueMomentum = "Uptrend";
          noteMomentum = 85;
        }
        if (currentPos89 > 0 && currentPos21 < 0) {
          valueMomentum = "Uncertain";
          noteMomentum = 65;
        }
        if (currentPos89 < 0 && currentPos21 > 0) {
          valueMomentum = "Uncertain";
          noteMomentum = 35;
        }
        if (currentPos89 < 0 && currentPos21 < 0) {
          valueMomentum = "Downtrend";
          noteMomentum = 15;
        }

        // Note Stochastic
        var valueStochastic = "";
        var noteStochastic = "";
        if (RSI89 > 45) {
          if (stoch89 > 95) {
            valueStochastic = "Overbought";
            noteStochastic = 35;
          }
          if (stoch89 > 50 && stoch89 <= 95) {
            valueStochastic = "Uptrend";
            noteStochastic = 85;
          }
          if (stoch89 > 30 && stoch89 <= 50) {
            valueStochastic = "Uptrend";
            noteStochastic = 60;
          }
          if (stoch89 <= 30) {
            valueStochastic = "Downtrend";
            noteStochastic = 35;
          }
        } else {
          if (stoch89 > 50) {
            valueStochastic = "Uncertain";
            noteStochastic = 35;
          }
          if (stoch89 > 30 && stoch89 <= 50) {
            valueStochastic = "Downtrend";
            noteStochastic = 35;
          }
          if (stoch89 <= 30) {
            valueStochastic = "Downtrend";
            noteStochastic = 15;
          }
        }

        var columns = [];
        if (isMobile) {
          columns = [
            {
              name: "Indicator",
              selector: (row) => (
                <div className="p-t-5 p-b-5">
                  <strong>{row.indicator}</strong>
                  <div>{row.value + row.symbol}</div>
                </div>
              ),
            },
            {
              name: "VS Sector",
              selector: (row) => {
                if (isNaN(row.averageSector)) return "N/A";
                var position = String(row.score) + "%";
                if (row.score > 90) position = String(90) + "%";
                return <LinearGauge position={position} />;
                // return (100 * Math.round(row.averageSector * 10000)) / 10000 + row.symbol;
              },
            },
          ];
        } else {
          columns = [
            {
              name: "Indicator",
              selector: (row) => <strong>{row.indicator}</strong>,
            },
            {
              name: "Value",
              selector: (row) => row.value + row.symbol,
            },
            {
              name: "VS Sector",
              selector: (row) => {
                if (isNaN(row.averageSector)) return "N/A";
                var position = String(row.score) + "%";
                if (row.score > 90) position = String(90) + "%";
                return <LinearGauge position={position} />;
              },
            },
          ];
        }
        const data = [
          {
            id: 1,
            indicator: "Momentum",
            value: valueMomentum,
            symbol: "",
            definition: "The Momentum is a combination of Moving Average 89 and 21",
            plot: false,
            variable: "Momentum",
            averageSector: "",
            score: noteMomentum,
          },
          {
            id: 2,
            indicator: "Trends",
            value: valueStochastic,
            symbol: "",
            definition: "The trends is determined by the Stochastic level",
            plot: false,
            variable: "stochastic",
            averageSector: "",
            score: noteStochastic,
          },
        ];

        const { noteGlobal, noteGlobalStyle } = getGlobalNote({ data });
        onNote({ type: "momentum", value: noteGlobal, style: noteGlobalStyle });

        setNote({ noteGlobal, noteGlobalStyle });
        setDataTable({ data, columns });
      }
    }
  }, [queryHistorical]);

  const ExpandedComponent = ({ data }) => {
    return (
      <div className="m-t-10">
        <div className="m-10">{data.definition}</div>
      </div>
    );
  };

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#EEEEEE",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
    <div className="card">
      <div className="card-body" style={{ padding: "15px" }}>
        <h4 style={{ fontSize: "1.25rem", fontWeight: "800", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>Timing</h4>
        <div className="m-20"></div>
        <div className="row">
          <div className={isMobile ? "col-6 p-r-0" : "col-6 p-r-0"}>
            {dataFinancial.subscription ? (
              <ApexGraphNote data={{ noteGlobal: note.noteGlobal, noteGlobalStyle: note.noteGlobalStyle }} />
            ) : (
              <div className={`notation notationLock`}>
                <FontAwesomeIcon icon={faLock} />
              </div>
            )}
          </div>
          <div className="col ">
            <p>Measure of the speed and direction of price movements over a specified period of time. It helps to identify trends and potential buying or selling opportunities.</p>
          </div>
        </div>
        {/* Table */}
        <div className="m-20"></div>
        <DataTable columns={dataTable.columns} data={dataTable.data} dense={true} customStyles={customStyles} highlightOnHover expandableRows expandableRowsComponent={ExpandedComponent} />
        <div className="m-20"></div>
      </div>
    </div>
  );
};

export default MomentumWidget;
