import React, { Component, useState } from "react";
import { isMobile } from "react-device-detect";
import { fetchLogoUrl } from "../../../../../tools/fetchLogoCompany";
import { postSymbolWatchlist } from "../../../../../firebase-marketadvisor/watchlistSymbol";

const HeaderPageSymbol = ({ data }) => {
  const [isInWatchlist, setIsInWatchlist] = useState(data.watchlist);

  const { symbol, name, industry, sector, variationPercent, price, currencySymbol, variationPrice, watchlist } = data;

  const logoPath = fetchLogoUrl(symbol);

  const addToWatchlist = () => {
    postSymbolWatchlist({
      symbol: symbol,
      add: !isInWatchlist,
      name: name,
    });

    setIsInWatchlist(!isInWatchlist);
    // this.setState({ watchlist: !this.state.watchlist });
  };

  return (
    <div className="p-t-20 m-20">
      <div className={"card header-symbol"}>
        <div className="col-12 ">
          <div className="card-body ">
            <div className="row">
              <div className={isMobile ? "col-2 p-0 my-auto" : "col-1 my-auto"}>
                <img
                  src={logoPath}
                  alt={`Logo icon format for ${symbol} ${name} company`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                  }}
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <div className="col">
                <span>{isMobile ? `${industry} / ${symbol}` : `Stocks / ${sector}  / ${industry} / ${symbol}`}</span>
                <h5 className="m-t-15">{name}</h5>
                <div className="row">
                  <div className="col" style={{ placeItems: "center" }}>
                    {price === null ? (
                      <div className="m-t-10 float-left">
                        <span className="h2 m-r-10">{`-`}</span>
                        <span className="badgePricePos">
                          <i className="fa fa-long-arrow-up"></i>
                          {"( - %)"}
                        </span>
                      </div>
                    ) : (
                      <div className="m-t-10 float-left">
                        <span className="h2 m-r-10">{`${price}${currencySymbol}`}</span>
                        <span className={variationPercent < 0 ? "badgePriceNeg" : "badgePricePos"}>
                          <i className={variationPercent < 0 ? "fa fa-long-arrow-down" : "fa fa-long-arrow-up"}></i>
                          {price.toString().split(".").length > 1 ? ` ${variationPrice.toFixed(price.toString().split(".")[1].length)} (${variationPercent} %)` : ` ${variationPrice.toFixed(0)} (${variationPercent} %)`}
                        </span>
                      </div>
                    )}

                    <button className={isMobile ? "btn btnPrimary btn-light float-right btn-block m-t-10" : "btn btnPrimary btn-light float-right"} onClick={addToWatchlist}>
                      <i className="fa fa-plus"></i> {isInWatchlist ? "Delete from Watchlist" : "Add to Watchlist"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderPageSymbol;
