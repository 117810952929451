import React from "react";
import { firebase_app } from "../../../data/config";
import { calculFinancialData } from "../tools/functionCalcFinancialRatio";
import { getFinancialData } from "../../../firebase-marketadvisor/firebase-financial-data";

const getInfoAnalysisSymbol = firebase_app.functions().httpsCallable("getInfoAnalysisSymbol");

export const generateData = async (symbol, data, columns) => {
  // Add Columns
  const tmp = {
    name: symbol,
    // selector: symbol + "_style",
    selector: (row) => {
      return row[symbol + "_style"];
    },
  };
  columns.push(tmp);

  // Add Data

  var dataClean = [];

  // Extract firebase
  const fin1 = await getFinancialData(symbol);
  const fin2 = await getInfoAnalysisSymbol({ symbol });

  // Calcul
  const res0 = calculFinancialData(fin1, "TTM");
  const res = { ...res0, ...fin2.data };

  data.forEach((e) => {
    e[symbol] = res[e.variable];
    e[symbol + "_style"] = res[e.variable];
    dataClean.push(e);
  });

  data = dataClean;

  var newData = [];

  // Score Global
  let keys = Object.keys(data[0]);
  keys = keys.filter((item) => item !== "id");
  keys = keys.filter((item) => item !== "ratio");
  keys = keys.filter((item) => item !== "condition1");
  keys = keys.filter((item) => item !== "condition2");
  keys = keys.filter((item) => item !== "variable");
  keys = keys.filter((item) => item !== "sign");
  keys = keys.filter((item) => item !== "plot");
  keys = keys.filter((item) => !item.includes("_style"));
  let score = Object.fromEntries(keys.map((k) => [k, 0]));
  // Add Style

  data.forEach((e) => {
    let winner = "";
    let looser = "";

    // Add sign % or different

    keys.forEach((elementSymbol) => {
      e[elementSymbol + "_style"] = <div className="badgeRankNeutral">{e[elementSymbol] + e.sign}</div>;
    });
    // Condition looking for the max
    if (e.condition1 === "max" && keys.length > 1) {
      let maxi = -10000000000000;
      let mini = +10000000000000;

      keys.forEach((elementSymbol) => {
        // Look for the max
        if (e[elementSymbol] > maxi) {
          winner = elementSymbol;
          maxi = e[elementSymbol];
        }
        // Look for the min
        if (e[elementSymbol] < mini) {
          looser = elementSymbol;
          mini = e[elementSymbol];
        }
      });

      e[winner + "_style"] = <div className="badgeRankPos">{e[winner] + e.sign}</div>;

      e[looser + "_style"] = <div className="badgeRankNeg">{e[looser] + e.sign}</div>;
      score[winner] = score[winner] + 1;
      score[looser] = score[looser] - 1;
    }

    // Condition looking for the min
    if (e.condition1 === "min" && keys.length > 1) {
      let maxi = -10000000000000;
      let mini = +10000000000000;

      keys.forEach((elementSymbol) => {
        // Look for the max
        if (e[elementSymbol] > maxi) {
          looser = elementSymbol;
          maxi = e[elementSymbol];
        }
        // Look for the min
        if (e[elementSymbol] < mini) {
          winner = elementSymbol;
          mini = e[elementSymbol];
        }
      });
      e[winner + "_style"] = <div className="badgeRankPos">{e[winner] + e.sign}</div>;
      e[looser + "_style"] = <div className="badgeRankNeg">{e[looser] + e.sign}</div>;
      score[winner] = score[winner] + 1;
      score[looser] = score[looser] - 1;
    }
    // Fix condition
    if (e.condition1.slice(0, 3) === "fix" || e.condition2.slice(0, 3) === "fix") {
      let condition = [];
      e.condition1.slice(0, 3) === "fix" ? (condition = e.condition1.split("_")) : (condition = e.condition2.split("_"));

      let typeCondition = condition[1];
      let limit = parseFloat(condition[2]);

      keys.forEach((elementSymbol) => {
        e[elementSymbol + "_style"] = <div className="badgeRankNeg">{e[elementSymbol] + e.sign}</div>;
        score[elementSymbol] = score[elementSymbol] - 1;

        // Look for the max
        if (typeCondition === "sup") {
          if (e[elementSymbol] > limit) {
            e[elementSymbol + "_style"] = <div className="badgeRankPos">{e[elementSymbol] + e.sign}</div>;
            score[elementSymbol] = score[elementSymbol] + 2;
          }
        }
        // Look for the min
        if (typeCondition === "inf") {
          if (e[elementSymbol] < limit) {
            e[elementSymbol + "_style"] = <div className="badgeRankPos">{e[elementSymbol] + e.sign}</div>;
            score[elementSymbol] = score[elementSymbol] + 2;
          }
        }
      });
    }

    newData.push(e);
  });
  // console.log(newData);
  // console.log(score);
  return { newData, columns, fin1, score };
};
