import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ChartDataExplorer from "../assetForDataExplorer/chartDataExplorer";
import { calculFinancialData } from "../../../tools/functionCalcFinancialRatio";
import { getScore, getScorePE, getGlobalNote } from "./toolWidget";
import LinearGauge from "./linearGauge";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { getHistoricalEarning, getHistoricalDividend } from "../../../../../firebase-marketadvisor/firebase-CRUD-earning";
import { linearRegression } from "./toolWidget";
import ApexGraphNote from "./components/apexGraphNote";

// Est ce que la companie existe depuis au moins X années
// est ce que les chiffres sont cohérent ou exceptionnel
const calculateItemsSum = (data, start, stop) => {
  let sum = 0;
  for (let j = start; j < stop; ++j) {
    sum += data[j];
  }
  return sum;
};

const caculateMovingAverage = (data, window) => {
  const steps = data.length - window;
  const result = [];
  for (let i = 0; i < steps; ++i) {
    const sum = calculateItemsSum(data, i, i + window);
    result.push(sum / window);
  }
  return result;
};

const GrowthAndResilienceWidget = ({ dataFinancial, onNote }) => {
  const [note, setNote] = useState({ noteGlobal: "", noteGlobalStyle: "" });
  const [earnings, setEarnings] = useState({ value: [], loading: true });
  const [noteEarningStability, setNoteEarningStability] = useState();
  const [noteDividend, setNoteDividend] = useState();
  const [noteEarningGrowth, setNoteEarningGrowth] = useState();
  const [dividends, setDividends] = useState({ value: [], loading: true });
  const [dataTable, setDataTable] = useState({ data: [], columns: [] });

  // Get Earning

  useEffect(() => {
    // Calcul 1y
    const symbol = dataFinancial.symbol;

    getHistoricalEarning(symbol).then((earningRes) => {
      var labels = [];
      var datas = [];
      labels = Object.keys(earningRes);
      labels.sort(function (a, b) {
        return new Date(b) - new Date(a);
      });
      labels.reverse();
      datas = labels.map((year) => {
        return earningRes[year].epsActual;
      });

      setEarnings({ value: datas, loading: false });
    });

    getHistoricalDividend(symbol).then((res) => {
      if (res) {
        var labelShort = [];
        var datas = [];
        const dividend = res.NumberDividendsByYear;

        if (dividend) {
          if (Object.keys(dividend).length !== 0) {
            let dividendCorrected = {};
            labelShort = Object.keys(dividend).map((index) => {
              dividendCorrected[dividend[index]["Year"]] = dividend[index]["Count"];
              return parseInt(dividend[index]["Year"]);
            });

            const startYear = Math.min(...labelShort);
            const currentYear = parseInt(new Date().getFullYear());

            let years = [...Array(currentYear - startYear + 1).keys()].map((e) => e + startYear);

            datas = years.map((year) => {
              const div = dividendCorrected?.[year];
              if (div) {
                return div;
              }

              return 0;
            });

            setDividends({ value: datas, loading: false });
          } else {
            setDividends({ value: [], loading: false });
          }
        } else {
          setDividends({ value: [], loading: false });
        }
      } else {
        setDividends({ value: [], loading: false });
      }
    });

    // onNote({ type: "growth", value: noteGlobal, style: noteGlobalStyle });
  }, []);

  useEffect(() => {
    if (earnings.loading === false) {
      const earningTmp = earnings.value;
      if (earningTmp.length > 0) {
        const sizeEarning = Math.min(10, earningTmp.length);

        // Stability

        let positiveEarning = 0;
        const tmpEarning = earningTmp.slice(-sizeEarning);

        tmpEarning.forEach((e) => {
          if (e > 0) {
            positiveEarning += 1;
          }
        });

        const notationStability = Math.min(100, parseInt((100 * positiveEarning) / sizeEarning));
        setNoteEarningStability(notationStability);

        // Growth
        if (earningTmp.length > 3) {
          let earningAvg = caculateMovingAverage(earningTmp, 2);
          // const a = linearRegression(earningTmp.slice(-sizeEarning));

          const b = linearRegression(earningAvg.slice(-sizeEarning));

          let growth = 0;
          if (b.slope > 0) {
            growth = Math.min(100, parseInt((100 * b.slope * sizeEarning) / 0.33));
          }
          setNoteEarningGrowth(growth);
        } else {
          setNoteEarningGrowth(0);
        }
      } else {
        setNoteEarningGrowth(0);
        setNoteEarningStability(0);
      }
    }
  }, [earnings]);

  useEffect(() => {
    if (dividends.loading === false) {
      const dividendTmp = dividends.value;

      if (dividendTmp.length > 0) {
        const sizeDiv = Math.min(20, dividendTmp.length);
        let positiveDiv = 0;
        const tmpDiv = dividendTmp.slice(-sizeDiv);

        tmpDiv.forEach((e) => {
          if (e > 0) {
            positiveDiv += 1;
          }
        });

        const noteDiv = Math.min(100, parseInt(5 * positiveDiv));
        setNoteDividend(noteDiv);
      } else {
        setNoteDividend(0);
      }
    }
  }, [dividends]);

  useEffect(() => {
    const note = parseInt((noteEarningStability + noteDividend + noteEarningGrowth) / 3);
    var style = "";
    if (note >= 0) {
      style = "notationBad";
    }
    if (note >= 35) {
      style = "notationNeutral";
    }
    if (note >= 70) {
      style = "notationGreat";
    }
    setNote({ noteGlobal: note, noteGlobalStyle: style });
    onNote({ type: "growth", value: note, style: style });
  }, [noteEarningStability, noteDividend, noteEarningGrowth]);

  const ExpandedComponent = ({ data }) => {
    if (data.plot) {
      const listYearAvailable = [...dataFinancial.listYearAvailable].reverse();
      let dataPlot = [];

      for (const y of listYearAvailable) {
        dataPlot.push(calculFinancialData(dataFinancial.financial, y)[data.variable]);
      }

      return (
        <div className="m-t-10">
          <div className="m-10">{data.definition}</div>
          <h4 style={{ fontSize: "16px", fontWeight: "600", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>{data.indicator}</h4>
          <ChartDataExplorer datas={{ labels: listYearAvailable, data: dataPlot, unit: data.symbol }} />
        </div>
      );
    } else {
      return (
        <div className="m-t-10">
          <h4 style={{ fontSize: "16px", fontWeight: "600", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>{data.indicator}</h4>
          <div className="m-10">{data.definition}</div>
        </div>
      );
    }
  };
  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#EEEEEE",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  const columns = [
    {
      name: "Indicator",
      selector: (row) => <strong>{row.indicator}</strong>,
    },
    {
      name: "Value",
      selector: (row) => Math.round(100 * row.value) / 100 + row.symbol,
    },
    // {
    //   name: "VS Sector",
    //   selector: (row) => {
    //     if (isNaN(row.averageSector)) return "N/A";
    //     var position = String(row.score) + "%";
    //     if (row.score > 90) position = String(90) + "%";
    //     return <LinearGauge position={position} />;
    //   },
    // },
  ];
  const data = [
    {
      id: 1,
      indicator: "Earnings Stability",
      value: noteEarningStability,
      symbol: "%",
      definition: "Earnings Stability represents the number of years of positive earnings over the last ten years. If the company is younger, we applied the calculation to the company's lifetime.",
      plot: false,
      variable: "PE",
      averageSector: "",
      score: 0,
    },
    {
      id: 2,
      indicator: "Earning Growth",
      value: noteEarningGrowth,
      symbol: "%",
      definition: "For a note of growth of 100%, the company shows an average trend of growth of +3% per year for the last ten years.",
      plot: false,
      variable: "priceSales",
      averageSector: "",
      score: 0,
    },
    {
      id: 3,
      indicator: "Dividend Record",
      value: noteDividend,
      symbol: "%",
      definition: "100% corresponds to a 20 years of paid dividends",
      plot: false,
      variable: "priceEquity",
      averageSector: "",
      score: 0,
    },
  ];

  return (
    <div className="card">
      <div className="card-body" style={{ padding: "15px" }}>
        <h4 style={{ fontSize: "1.25rem", fontWeight: "800", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>Growth & Resilience</h4>
        <div className="m-20"></div>
        <div className="row">
          <div className={isMobile ? "col-6 p-r-0" : "col-6 p-r-0"}>
            {dataFinancial.subscription ? (
              <ApexGraphNote data={{ noteGlobal: note.noteGlobal, noteGlobalStyle: note.noteGlobalStyle }} />
            ) : (
              <div className={`notation notationLock`}>
                <FontAwesomeIcon icon={faLock} />
              </div>
            )}
          </div>
          <div className="col">
            <p>Measure the ability of a company to grow its revenue over time and to sustain itself over the long term. The Growth & Resilience factor represents the confidence we can have in predicting future behavior.</p>
          </div>
        </div>
        {/* Table */}
        <div className="m-20"></div>
        <DataTable columns={columns} data={data} dense={true} customStyles={customStyles} highlightOnHover expandableRows expandableRowsComponent={ExpandedComponent} />
        <div className="m-20"></div>
      </div>
    </div>
  );
};

export default GrowthAndResilienceWidget;
