import React, { useState, useEffect } from "react";
import { firebase_app } from "../../../../../data/config";
import { Bar } from "react-chartjs-2";
import { getHistoricalDividend } from "../../../../../firebase-marketadvisor/firebase-CRUD-earning";
import Loader from "../../../tools/loader";

const HistoricDividend = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [dividend, setDividend] = useState();
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    getHistoricalDividend(data.symbol).then((res) => {
      if (res) {
        var labelShort = [];
        var datas = [];

        const dividend = res?.NumberDividendsByYear;
        if (dividend) {
          if (Object.keys(dividend).length !== 0) {
            let dividendCorrected = {};

            labelShort = Object.keys(dividend).map((index) => {
              dividendCorrected[dividend[index]["Year"]] = dividend[index]["Count"];
              return parseInt(dividend[index]["Year"]);
            });

            const startYear = Math.min(...labelShort);
            const currentYear = parseInt(new Date().getFullYear());

            let years = [...Array(currentYear - startYear + 1).keys()].map((e) => e + startYear);

            datas = years.map((year) => {
              const div = dividendCorrected?.[year];
              if (div) {
                return div;
              }

              return 0;
            });

            const data = {
              labels: years,
              datasets: [
                {
                  label: "Dataset 1",
                  data: datas,
                  // backgroundColor: "rgba(255, 99, 132, 0.5)",
                  backgroundColor: (color) => {
                    let colors = color.dataIndex === years.length - 1 ? "#63b6f6" : "#bbdefb";

                    return colors;
                  },
                },
              ],
            };

            setDividend(data);
            setLoading(false);
          } else {
            setNoData(true);
            setLoading(false);
          }
        } else {
          setNoData(true);
          setLoading(false);
        }
      }
    });
  }, []);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      enabled: false, // <-- this option disables tooltips
    },

    legend: {
      display: false,
    },

    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
            display: true, //this will remove only the label
            callback: function (value) {
              if (value % 1 === 0) {
                return value;
              }
            },
          },
        },
      ],
    },
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="card col-sm-12 col-md-12 p-l-0 p-r-0">
      <div className="container p-l-0 p-r-0">
        <div className=" " style={{ paddingLeft: "0px !important", paddingRight: "0px !important" }}>
          {/* Section Affichage % */}
          <div className="card-body">
            <h6>Dividend Payment Record</h6>

            {/* Section Affichage Courbe */}
            <div className="row m-l-5 m-r-5 m-b-0">
              <div className="col my-auto p-l-0 p-r-0">
                {!noData && (
                  <div className="text-center">
                    <p className="font-weight-bold">Number of Dividend paid per Year</p>
                  </div>
                )}
                {noData ? (
                  <div className="text-center">
                    <p>No Data available</p>
                  </div>
                ) : (
                  <div className="chart-block" id="myChart" style={{ maxHeight: "150px" }}>
                    <Bar options={options} data={dividend} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoricDividend;
