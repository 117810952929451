import { firebase_app } from "../data/config";

export const getHistoricalEarning = async (symbol) => {
  var earning = {};
  await firebase_app
    .firestore()
    .collection("ticker")
    .doc(symbol)
    .collection("financial")
    .doc("earning")
    .get()
    .then((doc) => {
      if (doc.exists) {
        earning = doc.data();
      }
    })
    .catch(() => {
      earning = {};
    });
  return earning;
};

export const getHistoricalDividend = async (symbol) => {
  var dividends = {};
  await firebase_app
    .firestore()
    .collection("ticker")
    .doc(symbol)
    .collection("financial")
    .doc("dividends")
    .get()
    .then((doc) => {
      if (doc.exists) {
        dividends = doc.data();
      }
    });
  return dividends;
};
