import { firebase_app } from "../data/config";

export const getDividends = async (dataDividend) => {
  var dividends = [];
  var tmp = [];

  const dates = dataDividend.dates;
  const location = dataDividend.location;

  for (const element of dates) {
    await firebase_app
      .firestore()
      .collection("dividends")
      .doc(element)
      .get()
      .then((doc) => {
        if (doc.exists) {
          tmp = doc.data();
          dividends = dividends.concat(tmp[location]);
        }
      })
      .catch(() => {});
  }

  return dividends;
};
