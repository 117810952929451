import React, { Fragment, Component } from "react";
import { citation } from "./citation";

class CitationComponent extends Component {
  state = {};

  render() {
    const rand = Math.floor(Math.random() * (citation.length - 1));

    return (
      <Fragment>
        <div className="card">
          <div className="card-body ">
            <div className="col my-auto m-t-15" style={{ textAlign: "center", fontWeight: "400" }}>
              <div>
                <span>{"  " + citation[rand]["citation"] + "  "}</span>
              </div>
            </div>

            <div className="m-t-15">
              <h6 className="text-muted" style={{ textAlign: "right" }}>
                {"- " + citation[rand]["author"]}
              </h6>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CitationComponent;
