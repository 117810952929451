import React, { Fragment, useState, useContext } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import "./menu3.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faHouse, faMagnifyingGlass, faScaleBalanced, faCreditCard, faCircleQuestion, faBook, faWallet, faUser } from "@fortawesome/free-solid-svg-icons";
import ContactLink from "../components/common/sidebar-component/contactLink";
import { UserContext } from "../context/UserContext";

const MenuSideBar = ({ menuOpen }) => {
  const [menuActif, setMenuActif] = useState(1);
  const user = useContext(UserContext);

  const CardMenu = ({ url, name, logo, idMenu }) => {
    return (
      <Link to={`${process.env.PUBLIC_URL}${url}`}>
        <div
          className={`card-menu ${menuActif === idMenu ? "card-menu-actif" : ""}`}
          onClick={() => {
            setMenuActif(idMenu);
          }}
        >
          <div className="container-logo text-center">
            <FontAwesomeIcon icon={logo} style={{ height: "1.1rem", width: "1.1em " }} />
          </div>
          <div className="container-text-menu ">{name}</div>
        </div>
      </Link>
    );
  };

  if (!menuOpen) {
    return <Fragment></Fragment>;
  }
  return (
    <Fragment>
      <div id="sidebar" className={isMobile ? "page-sidebar page-sidebar-mobile" : "page-sidebar"}>
        <div className="card m-t-5 m-l-10 ">
          <div className="card-body p-0">
            <div className="my-auto card-body" style={{ height: "80px", paddingTop: "25px", display: "flex", placeContent: "center", alignItems: "center" }}>
              <div className="text-center my-auto ">
                <Link to={`${process.env.PUBLIC_URL}/`}>
                  <h2 style={{ fontSize: "1.6rem", fontWeight: "600" }}>
                    <span>
                      {"market"}
                      <span style={{ opacity: "0.6" }}>{"advisor"}</span>
                    </span>
                  </h2>
                </Link>
                {/* <div style={{ color: "black", fontSize: "1rem", fontWeight: "400" }}>{"Welcome"}</div> */}
                {/* <div style={{ color: "black", fontSize: "0.8rem", fontWeight: "400" }}>{user.email}</div> */}
              </div>
            </div>

            <div className="separator-menu"></div>
            <div className="sidebar">
              {/* Element du Menu  */}

              <CardMenu name="Home" url="/" logo={faHouse} idMenu={1} />
              <div className="subtitleMenu">Account</div>
              <CardMenu name="Portfolio" url="/myportfolio" logo={faWallet} idMenu={2} />
              <CardMenu name="Profile" url="/profile" logo={faUser} idMenu={9} />
              <CardMenu name="Subscription" url="/subscription" logo={faCreditCard} idMenu={3} />
              <div className="subtitleMenu">Stock Market</div>
              <CardMenu name="Screener" url="/screener" logo={faMagnifyingGlass} idMenu={4} />
              <CardMenu name="Screener Lib" url="/screenerlib" logo={faBook} idMenu={5} />
              <CardMenu name="Compare Stock" url="/comparative/new" logo={faScaleBalanced} idMenu={6} />
              <CardMenu name="Dividends" url="/dividends" logo={faMoneyBill} idMenu={7} />
              <div className="subtitleMenu">ETF</div>
              <CardMenu name="Find ETF" url="/etf" logo={faMagnifyingGlass} idMenu={10} />
              <CardMenu name="Compare ETF" url="/compare-etf" logo={faScaleBalanced} idMenu={11} />
              <div className="subtitleMenu">Help</div>
              <CardMenu name="FAQ" url="/faq" logo={faCircleQuestion} idMenu={8} />
            </div>
            <div className="separator-menu"></div>
            <ContactLink />
            <div className="text-center m-15">
              <a className="btn-menu" href={`${process.env.PUBLIC_URL}/subscription`}>
                Start Your Trial
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MenuSideBar;
