import React, { Fragment, useState, useEffect, useContext } from "react";
import { getFinancialData, getAverageIndustry } from "../../../../firebase-marketadvisor/firebase-financial-data";
import "rc-slider/assets/index.css";
import DataExplorer from "./DataExplorer";
import EarningPowerWidget from "./widgetAnalysis/earningPowerWidget";
import SummaryNote from "./widgetAnalysis/summaryNote";
import FinancialStabilityWidget from "./widgetAnalysis/financialStabilityWidget";
import PriceWidget from "./widgetAnalysis/priceWidget";
import MomentumWidget from "./widgetAnalysis/momentumWidget";
import { isMobile } from "react-device-detect";
import Loader from "../../tools/loader";
import { hasSub } from "../../../../firebase-marketadvisor/firebase-data-user";
import KeyPoints from "./keyPoints";
import { UserContext } from "../../../../context/UserContext";
import GrowthAndResilienceWidget from "./widgetAnalysis/growthAndResilience";
import SankeyDiagramm from "../sankeyDiagramm/SankeyDiagram";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import RadialBarNoteMA from "./widgetAnalysis/RadialBarNoteMA";
import { SymbolContext } from "../utils/symbolContext";

const PageAnalysis = ({ data, short, financial }) => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["user"]);

  const [subscription, setSubscription] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [activeMenu, setActiveMenu] = useState(1);
  const [dataForAnalyse, setDataForAnalyse] = useState({ financial: [], year: [], listYearAvailable: [], averageSector: [] });
  const [note, setNote] = useState({ earning: { value: 0 } });
  const [price, setPrice] = useState();

  const { data: dataSymbol, symbol, isLoading, error: errorLoading } = useContext(SymbolContext);
  // Ajouter un useEffect sur Data (chargemetn du prix, etc..)

  useEffect(() => {
    setPrice(data.price);
  }, [data]);

  useEffect(() => {
    if (!financial.queryFinancial.isPending) {
      setDataForAnalyse(financial.queryFinancial.data);
      setLoading(false);
    }
  }, [financial]);

  useEffect(() => {
    // Handle Subscription access
    if (user) {
      hasSub(user).then((r) => setSubscription(r));
    }
  }, [user]);

  const handleNote = (data) => {
    const type = data.type;
    let value = data.value;

    if (isNaN(value)) value = 0;

    var noteTmp = { ...note };
    noteTmp[type] = { value };
    setNote((prev) => {
      prev[type] = { value };
      return { ...prev };
    });
  };

  if (error) {
    return "";
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h4 style={{ fontSize: "1.25rem", fontWeight: "800", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>Overview for {dataSymbol.name}</h4>
          <div className="row ">
            <div className={isMobile ? "col-12 my-auto" : "col-6 "}>
              {dataSymbol?.description ? (
                <div className="m-l-10 m-b-20 ">
                  <span>{dataSymbol.description.split(".")[0] + "."}</span>
                </div>
              ) : (
                ""
              )}
              <KeyPoints data={{ subscription, note }} />
            </div>
            <div className={isMobile ? "col-12 my-auto" : "col-6  "}>
              <SummaryNote data={{ subscription, note }} />
              <RadialBarNoteMA data={{ note }} />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          {/* Widget  */}
          {/* <div className={isMobile ? "col-0" : "col-3"}></div> */}

          <div className="row " style={short ? { display: "none" } : { display: "flex" }}>
            <div className={isMobile ? "col-12" : "col-12"}>
              <EarningPowerWidget
                data={{
                  subscription,
                  symbol: data.symbol,
                  financial: dataForAnalyse.financial,
                  year: dataForAnalyse.year,
                  listYearAvailable: dataForAnalyse.listYearAvailable,
                  averageSector: dataForAnalyse.averageSector,
                  name: data.name,
                }}
                onNote={handleNote}
              />{" "}
            </div>
            <div className={isMobile ? "col-12" : "col-12"}>
              <FinancialStabilityWidget
                data={{
                  subscription,
                  symbol: data.symbol,
                  financial: dataForAnalyse.financial,
                  year: dataForAnalyse.year,
                  listYearAvailable: dataForAnalyse.listYearAvailable,
                  averageSector: dataForAnalyse.averageSector,
                  marketCap: data.marketCap,
                  name: data.name,
                }}
                onNote={handleNote}
              />
            </div>
            <div className={isMobile ? "col-12" : "col-12"}>
              <PriceWidget
                dataFinancial={{
                  subscription,
                  symbol: data.symbol,
                  financial: dataForAnalyse.financial,
                  year: dataForAnalyse.year,
                  listYearAvailable: dataForAnalyse.listYearAvailable,
                  averageSector: dataForAnalyse.averageSector,
                  marketCap: data.marketCap,
                  PE: data.PE,
                  name: data.name,
                  price: price,
                }}
                onNote={handleNote}
              />
            </div>
            <div className={isMobile ? "col-12" : "col-12"}>
              <MomentumWidget
                dataFinancial={{
                  subscription,
                  symbol: data.symbol,
                  financial: dataForAnalyse.financial,
                  year: dataForAnalyse.year,
                  listYearAvailable: dataForAnalyse.listYearAvailable,
                  averageSector: dataForAnalyse.averageSector,
                  marketCap: data.marketCap,
                  PE: data.PE,
                  name: data.name,
                  price: price,
                }}
                onNote={handleNote}
              />
            </div>

            <div className={isMobile ? "col-12" : "col-12"}>
              <GrowthAndResilienceWidget
                dataFinancial={{
                  subscription,
                  symbol: data.symbol,
                  financial: dataForAnalyse.financial,
                  year: dataForAnalyse.year,
                  listYearAvailable: dataForAnalyse.listYearAvailable,
                  averageSector: dataForAnalyse.averageSector,
                  marketCap: data.marketCap,
                  PE: data.PE,
                  name: data.name,
                  price: price,
                }}
                onNote={handleNote}
              />
            </div>

            <div className={"col-12"}>
              <SankeyDiagramm
                data={{
                  subscription,
                  symbol: data.symbol,
                  financial: dataForAnalyse.financial,
                  year: dataForAnalyse.year,
                  listYearAvailable: dataForAnalyse.listYearAvailable,
                  averageSector: dataForAnalyse.averageSector,
                  name: data.name,
                }}
              />{" "}
            </div>
            {/* End Widget */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageAnalysis;
