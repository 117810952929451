import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import { firebase_app } from "./data/config";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from "history";

import Faq from "./components/marketadvisor/FAQ/faq";
import App from "./components/app";
// import FreeApp from "./components/freeApp";
import Signin from "./auth/signin";
import Signup from "./auth/signup";
import ResetPwd from "./auth/resetPwd";
import SearchStockComponent from "./components/marketadvisor/tools/searchStock";
import ComparativeAnalysis from "./components/marketadvisor/comparativeAnalysis/comparativeAnalysis";
import HomePageMarketAdvisor from "./components/marketadvisor/homePage/HomePage";
import PageSubscription from "./components/marketadvisor/subscriptions/pageSubscriptions";
// import CalendarPage from "./components/marketadvisor/CalendarPage/CalendarPage";
import ScreenerPage from "./components/marketadvisor/Screener/ScreenerPage";
import GuruScreenerPage from "./components/marketadvisor/guruScreener/guruPage";
import ProfileComponent from "./components/marketadvisor/profile/ProfileComponent";
import DividendPage from "./components/marketadvisor/dividend/DividendPage";
import MyPortfolioPage from "./components/marketadvisor/myportfolio/myPortfolioPage";
import PagePortfolioManagement from "./components/marketadvisor/portfoliomanagement/PagePortfolioManagement";

// Analytics
import { analytics } from "./data/config";
import { clarity } from "react-microsoft-clarity";

import MainPageSymbol from "./components/marketadvisor/symbolPage/MainPageSymbol";
import { UserContext } from "./context/UserContext";
import ETFPage from "./components/marketadvisor/etf/ETFPage";
import ETFPresentation from "./components/marketadvisor/etf/ETFPresentation";

// React Query
import { useQuery, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getUser } from "./api/getUser";
import PageCompareETF from "./components/marketadvisor/compareETF/PageCompareETF";

const queryClient = new QueryClient();

const Root = () => {
  const [currentUser, setCurrentUser] = useState(false);
  const history = createBrowserHistory();
  const { status, error, data: user } = useQuery({ queryKey: ["user"], queryFn: getUser });

  useEffect(() => {
    firebase_app.auth().onAuthStateChanged(setCurrentUser);
    analytics.logEvent("new_connection_platform");
    clarity.init("intvys8cg6");
    // clarity.identify('USER_ID', { userProperty: 'value' });
    clarity.consent();
  }, []);

  if (currentUser === false) {
    return null;
  }

  return (
    <UserContext.Provider value={currentUser}>
      <div className="App">
        <BrowserRouter basename={`/`}>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Signin} />
            <Route exact path={`${process.env.PUBLIC_URL}/pages/signup`} component={Signup} />
            <Route exact path={`${process.env.PUBLIC_URL}/pages/resetPwd`} component={ResetPwd} />

            <Route path={`${process.env.PUBLIC_URL}/ticker`}>
              <App>
                <Route exact path={`${process.env.PUBLIC_URL}/ticker/:symbol`} component={MainPageSymbol} />
              </App>
            </Route>

            <Route path={`${process.env.PUBLIC_URL}/stock-price`}>
              <App>
                <Route exact path={`${process.env.PUBLIC_URL}/stock-price/earning/:symbol`} component={MainPageSymbol} />
              </App>
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/etf-analysis`}>
              <App>
                <Route exact path={`${process.env.PUBLIC_URL}/etf-analysis/:etf`} component={ETFPresentation} />
              </App>
            </Route>

            {currentUser !== null ? (
              <App>
                <Route exact path={`${process.env.PUBLIC_URL}/symbol/:symbol`} component={MainPageSymbol} />
                <Route exact path={`${process.env.PUBLIC_URL}/etf`} component={ETFPage} />
                <Route exact path={`${process.env.PUBLIC_URL}/compare-etf`} component={PageCompareETF} />
                <Route exact path={`${process.env.PUBLIC_URL}/etf/:etf`} component={ETFPresentation} />
                <Route exact path={`${process.env.PUBLIC_URL}/search`} component={SearchStockComponent} />
                {/* <Route exact path={`${process.env.PUBLIC_URL}/calendar`} component={CalendarPage} /> */}
                <Route exact path={`${process.env.PUBLIC_URL}/comparative/:symbol`} component={ComparativeAnalysis} />
                <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />
                <Route exact path={`${process.env.PUBLIC_URL}/dividends/`} component={DividendPage} />
                <Route exact path={`${process.env.PUBLIC_URL}/screener/:idScreener`} component={ScreenerPage} />
                <Route exact path={`${process.env.PUBLIC_URL}/screener/`} component={ScreenerPage} />
                <Route exact path={`${process.env.PUBLIC_URL}/screenerlib/`} component={GuruScreenerPage} />
                <Route exact path={`${process.env.PUBLIC_URL}/profile/`} component={ProfileComponent} />
                <Route exact path={`${process.env.PUBLIC_URL}/myportfolio/`} component={MyPortfolioPage} />
                <Route exact path={`${process.env.PUBLIC_URL}/portfoliomanagement/`} component={PagePortfolioManagement} />
                <Route exact path={`${process.env.PUBLIC_URL}/subscription`} component={PageSubscription} />
                <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePageMarketAdvisor} />
                <Route exact path={`${process.env.PUBLIC_URL}/pages/signup`} component={HomePageMarketAdvisor} />
                <Route exact path={`${process.env.PUBLIC_URL}/pages/signin`} component={HomePageMarketAdvisor} />
                <Route exact path={`${process.env.PUBLIC_URL}/login`} component={HomePageMarketAdvisor} />

                {/* <Redirect to={`${process.env.PUBLIC_URL}/`} /> */}
              </App>
            ) : (
              <Redirect to={`${process.env.PUBLIC_URL}/login`} />
            )}
          </Switch>
        </BrowserRouter>
      </div>
    </UserContext.Provider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <QueryClientProvider client={queryClient}>
    <Root />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

serviceWorker.unregister();
