import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { firebase_app } from "../data/config";
import SweetAlert from "sweetalert2";
import Background from "../assets/background/background.svg";

const ResetPwd = () => {
  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email } = e.target.elements;

    if (email.value) {
      firebase_app.auth().sendPasswordResetEmail(email.value).then(history.push("/login"));
    } else {
      SweetAlert.fire({
        title: "Error !",
        text: "Please enter a valid email adress",
        icon: "error",
      });
    }
  };

  return (
    <Fragment>
      <div style={{ backgroundImage: `url(${Background})`, width: "100%", height: "100%", position: "absolute", opacity: "5%" }}></div>
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* <!-- Reset Password page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12 p-0">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <h1>
                        <span>
                          market<span style={{ opacity: "0.6" }}>advisor</span>
                        </span>
                      </h1>
                    </div>
                    <div className="card mt-4 p-4">
                      <h5 className="text-center">{"Forgot your password ? "}</h5>
                      <form className="theme-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input className="form-control" type="email" name="email" placeholder="email@email.com" />
                        </div>
                        <div className="row justify-content-center">
                          <div className="form-group form-row mb-0">
                            <div className="col-md-2">
                              <button className="btn btnPrimary" type="submit">
                                {"Reset your password"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Reset Password page end--> */}
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPwd;
