import React, { Component } from "react";
import { Line } from "react-chartjs-2";

const ChartPortfolio = ({ data }) => {
  const historical = data;
  const labels = historical.map((e, index) => {
    return `${historical.length - index} days ago`;
  });
  const datasetKeyProvider = () => {
    return Math.random();
  };

  const lineChartData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 100);
    gradient.addColorStop(0, true ? "#f8fdf6" : "#fef2f2");
    gradient.addColorStop(1, "#ffffff");

    return {
      labels: labels,
      datasets: [
        {
          data: historical,
          backgroundColor: gradient,
          borderColor: true ? "#13c636" : "#e1191d",
          borderWidth: 0.8,
          pointRadius: 0,
          fill: "start",
        },
      ],

      plugins: [
        {
          datalabels: {
            display: false,
            color: "white",
          },
        },
      ],
    };
  };

  const lineChartOptions = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: "index",
    },
    tooltips: {
      mode: "index",

      displayColors: false,
      intersect: false,
    },
    // onHover: (e, elements) => {
    //   console.log(e);
    // },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false, //this will remove only the label
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false, //this will remove only the label
          },
        },
      ],
    },
  };

  //   const plugins = [
  //     {
  //       afterDraw: (chart) => {
  //         if (chart.tooltip?._active?.length) {
  //           let x = chart.tooltip._active[0]._model.x;
  //           let yAxis = chart.scales["y-axis-0"];

  //           let ctx = chart.ctx;
  //           ctx.save();
  //           ctx.beginPath();
  //           ctx.setLineDash([5, 7]);
  //           ctx.moveTo(x, chart.tooltip._active[0]._model.y);
  //           ctx.lineTo(x, yAxis.bottom);
  //           ctx.lineWidth = 0.5;
  //           ctx.stroke();
  //           ctx.restore();

  //           ctx.beginPath();
  //           ctx.moveTo(x, yAxis.top);
  //           ctx.lineTo(x, chart.tooltip._active[0]._model.y);
  //           ctx.lineWidth = 0.5;
  //           ctx.stroke();
  //           ctx.restore();
  //           // Outer circle
  //           ctx.beginPath();
  //           ctx.fillStyle = true ? "#13c63653" : "#e1191d53";
  //           ctx.arc(x, chart.tooltip._active[0]._model.y, 10, 0, 2 * Math.PI);
  //           ctx.fill();

  //           ctx.closePath();
  //           // Inner circle
  //           ctx.beginPath();
  //           ctx.fillStyle = true ? "#13c636" : "#e1191d";
  //           ctx.arc(x, chart.tooltip._active[0]._model.y, 4, 0, 2 * Math.PI);
  //           ctx.fill();
  //           ctx.closePath();
  //         }
  //       },
  //     },
  //   ];

  return (
    <div className="row m-l-0 m-r-0">
      <div className="col my-auto p-l-0 p-r-0">
        <div className="chart-block" id="myChart">
          <Line id="ctx" data={lineChartData} options={lineChartOptions} datasetKeyProvider={datasetKeyProvider} height={100} />{" "}
        </div>
      </div>
    </div>
  );
};

export default ChartPortfolio;
