import React, { Fragment, Component } from "react";

import { firebase_app } from "../../../../data/config";
import { hasSub } from "../../../../firebase-marketadvisor/firebase-data-user";

class SubscriptionCard extends Component {
  state = {
    active: false,
    name: "",
    price: 0,
    subscription: false,
    loading: true,
    payment: false,
    cancel_at_period_end: false,
  };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        hasSub(user).then((subscription) => {
          this.setState({ user, subscription, loading: false });
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <div className="card m-t-10">
          <div className="container">
            <div className="col-sm-12 col-xl-12">
              <div className="card-body ">
                <h4>
                  <strong>Subscription</strong>
                </h4>
                {this.state.subscription ? (
                  <div>
                    <div className=" text-center m-t-20">
                      <h6>You have an active Subscription</h6>
                      <span className="m-t-20">You can easily change your payment preferences, change your current plan, cancel your subscription, or view your billing information by clicking the button below</span>
                    </div>
                    <div className=" row justify-content-center text-center m-b-10 m-t-20">
                      <a className="btn btnSecondary" href="https://billing.stripe.com/p/login/14k9BIbmT2XVc2keUU" style={{ display: "flex", alignItems: "center" }}>
                        Manage Subscription
                      </a>
                    </div>
                  </div>
                ) : (
                  <div>
                    {" "}
                    <div className=" text-center">
                      <h6>You are on a Free Plan</h6>
                    </div>
                    <div className=" row justify-content-center text-center m-b-10 m-t-10">
                      <a className="btn btnSecondary" href={`${process.env.PUBLIC_URL}/subscription`} style={{ display: "flex", alignItems: "center" }}>
                        Upgrade Now
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SubscriptionCard;
