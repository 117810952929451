import React, { Component, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { fetchLogoUrl } from "../../../../tools/fetchLogoCompany";

// import CardWithRadar from "../../myportfolio/components/cardWithRadar";
import CardRadarSimple from "./CardRadarSimple";

const MostViewed = () => {
  const mostViewed = [
    { id: 0, symbol: "TSLA", name: "Tesla Inc", c: "US" },
    { id: 1, symbol: "AAPL", name: "Apple Inc", c: "US" },
    { id: 2, symbol: "NVDA", name: "NVIDIA Corporation", c: "US" },
    { id: 3, symbol: "AMZN", name: "Amazon.com Inc", c: "US" },
    { id: 4, symbol: "PLTR", name: "Palantir Technologies Inc", c: "US" },
    { id: 5, symbol: "NIO", name: "Nio Inc Class A ADR", c: "US" },
    { id: 6, symbol: "AMD", name: "Advanced Micro Devices Inc", c: "US" },
    { id: 7, symbol: "MSFT", name: "Microsoft Corporation", c: "US" },
    { id: 8, symbol: "TGT", name: "Target Corporation", c: "US" },
    { id: 9, symbol: "SOFI", name: "SoFi Technologies Inc.", c: "US" },
    { id: 10, symbol: "AI", name: "C3 Ai Inc", c: "US" },
    { id: 11, symbol: "F", name: "Ford Motor Company", c: "US" },
    { id: 12, symbol: "CVNA", name: "Carvana", c: "US" },
    { id: 13, symbol: "META", name: "Meta Platforms Inc.", c: "US" },
    { id: 14, symbol: "GOOG", name: "Alphabet Inc Class C", c: "US" },
    { id: 15, symbol: "DIS", name: "Walt Disney Company", c: "US" },
    { id: 16, symbol: "INTC", name: "Intel Corporation", c: "US" },
    { id: 17, symbol: "T", name: "AT&T Inc", c: "US" },
    { id: 18, symbol: "PYPL", name: "PayPal Holdings Inc", c: "US" },
    { id: 19, symbol: "NFLX", name: "Netflix Inc", c: "US" },
    { id: 20, symbol: "VZ", name: "Verizon Communications Inc", c: "US" },
    { id: 21, symbol: "COIN", name: "Coinbase Global Inc", c: "US" },
    { id: 22, symbol: "ADBE", name: "Adobe Systems Incorporated", c: "US" },
    { id: 23, symbol: "BABA", name: "Alibaba Group Holding", c: "US" },
    { id: 24, symbol: "GME", name: "GameStop Co", c: "US" },
    { id: 25, symbol: "ORCL", name: "Oracle Corporation", c: "US" },
    { id: 26, symbol: "BAC", name: "Bank of America Co", c: "US" },
    { id: 27, symbol: "BA", name: "The Boeing Company", c: "US" },
  ];

  const [shuffledMostViewed, setShuffledMostViewed] = useState([]);
  const [myWatchlist, setMyWatchlist] = useState([]);

  useEffect(() => {
    const tmpShuffle = mostViewed.sort((a, b) => 0.5 - Math.random()).slice(0, 3);
    setShuffledMostViewed(tmpShuffle);
  }, []);

  return (
    <div className="">
      <h5>Popular among investors</h5>

      <div className="row">
        {shuffledMostViewed.map((symbol, index) => {
          return <CardRadarSimple key={index} data={symbol} />;
        })}
      </div>
    </div>
  );
};

export default MostViewed;
