import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ChartDataExplorer from "../assetForDataExplorer/chartDataExplorer";
import { calculFinancialData } from "../../../tools/functionCalcFinancialRatio";
import { getScore, getGlobalNote, getScoreInterestCoverRatio } from "./toolWidget";
import LinearGauge from "./linearGauge";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import ApexGraphNote from "./components/apexGraphNote";

class FinancialStabilityWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: this.props.data.subscription,
      financial: this.props.data.financial,
      year: this.props.data.year,
      listYearAvailable: this.props.data.listYearAvailable,
      averageSector: this.props.data.averageSector,
      marketCap: this.props.data.marketCap,
      keypoints: [],
    };
  }

  getKeypoints = () => {
    const keypoints = [
      { id: 1, text: "Gross Margin stable over time", style: "negativeKeyPoint" },
      { id: 2, text: "test", style: "positiveKeyPoint" },
    ];
    return keypoints;
  };

  componentDidMount = () => {
    // Calcul 1y
    const fin = this.state.financial;
    const year = this.state.year;
    const listYearAvailable = this.state.listYearAvailable;

    let { interestCoverageRatio, quickRatio, eqAssetRatio, altmanZScore } = calculFinancialData(fin, year, { marketCap: this.state.marketCap });
    const data_1 = calculFinancialData(fin, listYearAvailable[1], { marketCap: this.state.marketCap });
    // const dateAvg = this.state.listYearAvailable.slice(0, Math.min(5, this.state.listYearAvailable.length));

    if (isNaN(interestCoverageRatio)) {
      interestCoverageRatio = data_1.interestCoverageRatio;
    }
    if (isNaN(quickRatio)) {
      quickRatio = data_1.quickRatio;
    }

    if (isNaN(eqAssetRatio)) {
      eqAssetRatio = data_1.eqAssetRatio;
    }

    if (isNaN(altmanZScore)) {
      altmanZScore = data_1.altmanZScore;
    }

    var columns = [];
    if (isMobile) {
      columns = [
        {
          name: "Indicator",
          selector: (row) => (
            <div className="p-t-5 p-b-5">
              <strong>{row.indicator}</strong>
              <div>{row.value + row.symbol}</div>
            </div>
          ),
        },
        {
          name: "VS Sector",
          selector: (row) => {
            if (isNaN(row.averageSector)) return "N/A";
            var position = String(row.score) + "%";
            if (row.score > 90) position = String(90) + "%";
            return <LinearGauge position={position} />;
            // return (100 * Math.round(row.averageSector * 10000)) / 10000 + row.symbol;
          },
        },
      ];
    } else {
      columns = [
        {
          name: "Indicator",
          selector: (row) => <strong>{row.indicator}</strong>,
        },
        {
          name: "Value",
          selector: (row) => row.value + row.symbol,
        },
        {
          name: "VS Sector",
          selector: (row) => {
            if (isNaN(row.averageSector)) return "N/A";
            var position = String(row.score) + "%";
            if (row.score > 90) position = String(90) + "%";
            return <LinearGauge position={position} />;
          },
        },
      ];
    }

    const data = [
      {
        id: 1,
        indicator: "Interest Coverage",
        value: interestCoverageRatio,
        symbol: "",
        definition:
          "A measure of how many times a company can pay its interest expense from its earnings; if the interest coverage ratio is less than 1, the company is not generating enough profit from its operations to cover its interest obligations.",
        plot: true,
        variable: "interestCoverageRatio",
        averageSector: "",
        score: getScoreInterestCoverRatio({ value: interestCoverageRatio }),
      },
      {
        id: 2,
        indicator: "Quick Ratio",
        value: quickRatio,
        symbol: "%",
        definition:
          "A measure of how many times a company can pay its current liabilities using only its cash. If the ratio falls below 1, it means that the company has less cash than current liabilities and may have difficulty meeting its current liabilities in the near future.",
        plot: true,
        variable: "quickRatio",
        averageSector: this.state.averageSector?.quickRatio?.["50%"],
        score: getScore({ average: { "25%": 0.7, "50%": 0.9, "75%": 1, dir: "asc" }, value: quickRatio, percent: true }),
      },
      {
        id: 3,
        indicator: "Equity To Assets",
        value: eqAssetRatio,
        symbol: "%",
        definition: "The relationship of the Total Assets of the Firm to the portion owned by shareholders and is an indicator of the level of the company’s leverage",
        plot: true,
        variable: "eqAssetRatio",
        averageSector: this.state.averageSector?.eqAssetRatio?.["50%"],
        score: getScore({ average: this.state.averageSector?.eqAssetRatio, value: eqAssetRatio, percent: true }),
      },
      {
        id: 4,
        indicator: "Altman Z-Score",
        value: altmanZScore,
        symbol: "",
        definition: "A metric used to describe the financial health of a company and the likelihood that it will experience financial distress.",
        plot: false,
        variable: "altmanZScore",
        averageSector: "N/A",
        score: getScore({ average: { "25%": 0, "50%": 2, "75%": 3, dir: "asc" }, value: altmanZScore, percent: false }),
      },
    ];

    const { noteGlobal, noteGlobalStyle } = getGlobalNote({ data });
    this.props.onNote({ type: "stability", value: noteGlobal, style: noteGlobalStyle });
    const keypoints = this.getKeypoints();

    this.setState({
      noteGlobal,
      keypoints,
      noteGlobalStyle,
      columns,
      data,
    });
  };

  ExpandedComponent = ({ data }) => {
    if (data.plot) {
      const listYearAvailable = [...this.state.listYearAvailable].reverse();
      let dataPlot = [];

      for (const y of listYearAvailable) {
        dataPlot.push(calculFinancialData(this.state.financial, y)[data.variable]);
      }

      return (
        <div className="m-t-10">
          <h4 style={{ fontSize: "16px", fontWeight: "600", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>{data.indicator}</h4>
          <div className="m-10">{data.definition}</div>
          <ChartDataExplorer datas={{ labels: listYearAvailable, data: dataPlot, unit: data.symbol }} />
        </div>
      );
    } else {
      return (
        <div className="m-t-10">
          <h4 style={{ fontSize: "16px", fontWeight: "600", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>{data.indicator}</h4>
          <div className="m-10">{data.definition}</div>
        </div>
      );
    }
  };

  render() {
    const { noteGlobal, noteGlobalStyle } = this.state;
    const customStyles = {
      headRow: {
        style: {
          border: "none",
        },
      },
      headCells: {
        style: {
          color: "#202124",
          fontSize: "14px",
        },
      },
      rows: {
        highlightOnHoverStyle: {
          backgroundColor: "#EEEEEE",
          borderBottomColor: "#FFFFFF",
          borderRadius: "25px",
          outline: "1px solid #FFFFFF",
          cursor: "pointer",
        },
      },
      pagination: {
        style: {
          border: "none",
        },
      },
    };

    return (
      <div className="card">
        <div className="card-body" style={{ padding: "15px" }}>
          <h4 style={{ fontSize: "1.25rem", fontWeight: "800", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>Financial Stability</h4>
          <div className="m-20"></div>
          <div className="row">
            <div className={isMobile ? "col-6 p-r-0" : "col-6 p-r-0"}>
              {/* <div className={`notation ${this.state.noteGlobalStyle}`}>{this.state.noteGlobal}</div> */}
              {/* <div id="chart">
                <ReactApexChart options={dataGraph.options} series={dataGraph.series} type="radialBar" />
              </div> */}

              <ApexGraphNote data={{ noteGlobal, noteGlobalStyle }} />
            </div>
            <div className="col">
              <p>A company's ability to maintain a sound and sustainable financial position over time. Ability to generate long-term returns and manage risk effectively.</p>
            </div>
          </div>
          {/* Table */}
          <div className="m-20"></div>
          <DataTable columns={this.state.columns} data={this.state.data} dense={true} customStyles={customStyles} highlightOnHover expandableRows expandableRowsComponent={this.ExpandedComponent} />
          <div className="m-20"></div>
          {/* <h4 style={{ fontSize: "14px", fontWeight: "600", margin: "10px" }}>Keypoints</h4>
          <div className="m-20"></div>

          {this.state.keypoints.map((e) => {
     
            return (
              <div key={e.id} className={e.style}>
                {e.text}
              </div>
            );
          })} */}
        </div>
      </div>
    );
  }
}

export default FinancialStabilityWidget;
