import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { sectors, countries } from "./constant";
import { volatility } from "./../../myportfolio/function/functions";

const GraphicComparisonETF = ({ data }) => {
  const [dataSectors, setDataSectors] = useState([]);
  const [dataCountries, setDataCountries] = useState([]);
  const [stateTreeMap, setStateTreeMap] = useState([]);
  const [dataRadialReturn, setDataRadialReturn] = useState([]);
  const [dataRadialVolatility, setDataRadialVolatility] = useState([]);
  const [dtaRadialExpense, setDataRadialExpense] = useState([]);
  const [randomKey, setRandomKey] = useState(1);

  const normalizeNumberWithArray = ({ gap, arrayNumber, number, inverse }) => {
    if (arrayNumber.length > 0) {
      const mini = Math.min(...arrayNumber) - gap;
      const maxi = Math.max(...arrayNumber) + gap;
      if (inverse === false) {
        const normalNumber = (100 * (number - mini)) / (maxi - mini);
        return normalNumber;
      } else {
        // return number;

        return parseFloat((number * (maxi - mini)) / 100 + mini).toFixed(2);
      }
    }
    return number;
  };

  const getTotalReturn = () => {
    const listETF = data.listETF;
    const dataETF = data.dataETF;
    let returnETF = 0;
    if (listETF) {
      listETF.forEach((etf) => {
        if (etf in dataETF) {
          const weight = data.weightsETF[etf] / 100;
          returnETF = returnETF + weight * parseFloat(dataETF[etf]["Performance"]["Returns_1Y"]);
        }
      });
    }

    return returnETF.toFixed(2);
  };
  const getTotalVolatility = () => {
    const listETF = data.listETF;
    const dataETF = data.dataETF;
    let returnETF = 0;
    if (listETF) {
      listETF.forEach((etf) => {
        if (etf in dataETF) {
          const weight = data.weightsETF[etf] / 100;
          returnETF = returnETF + weight * parseFloat(dataETF[etf]["Performance"]["1y_Volatility"]);
        }
      });
    }

    return returnETF.toFixed(2);
  };
  const getTotalExpense = () => {
    const listETF = data.listETF;
    const dataETF = data.dataETF;
    let returnETF = 0;
    if (listETF) {
      listETF.forEach((etf) => {
        if (etf in dataETF) {
          const weight = data.weightsETF[etf] / 100;
          returnETF = returnETF + weight * 100 * parseFloat(dataETF[etf]["NetExpenseRatio"]);
        }
      });
    }

    return returnETF.toFixed(2);
  };

  useEffect(() => {
    const listETF = data.listETF;
    const dataETF = data.dataETF;
    let countETF = 0;
    let dataSectors = [];
    let dataCountries = [];
    let dataTreeMapTmp = [];
    let labelETF = [];
    let returnETF = [];
    let volatilityETF = [];
    let expenseETF = [];
    const totalReturn = getTotalReturn();
    const totalVolatility = getTotalVolatility();

    let dataAssets = {};
    sectors.forEach((e) => dataSectors.push(0));
    countries.forEach((e) => dataCountries.push(0));

    if (listETF) {
      listETF.forEach((etf) => {
        if (etf in dataETF) {
          const weight = data.weightsETF[etf] / 100;
          countETF = countETF + 1;
          // Return ETF
          labelETF.push(etf);
          returnETF.push(parseFloat(dataETF[etf]["Performance"]["Returns_1Y"]));
          volatilityETF.push(parseFloat(dataETF[etf]["Performance"]["1y_Volatility"]));
          expenseETF.push(100 * parseFloat(dataETF[etf]["NetExpenseRatio"]));
          // Sectors
          sectors.forEach((sector, index) => {
            dataSectors[index] = dataSectors[index] + weight * parseFloat(dataETF[etf]["Sector_Weights"][sector]["Equity_%"]);
          });

          // Countries
          countries.forEach((country, index) => {
            dataCountries[index] = dataCountries[index] + weight * parseFloat(dataETF[etf]["World_Regions"][country]["Equity_%"]);
          });

          // Holdings
          Object.keys(dataETF[etf]["Holdings"]).forEach((key) => {
            if (key !== ".") {
              if (key in dataAssets) {
                dataAssets[key] = dataAssets[key] + weight * parseFloat(dataETF[etf]["Holdings"][key]["Assets_%"]);
              } else {
                dataAssets[key] = weight * parseFloat(dataETF[etf]["Holdings"][key]["Assets_%"]);
              }
            }
          });
        }
      });

      Object.keys(dataAssets).forEach((key) => {
        dataTreeMapTmp.push({
          x: key,
          y: dataAssets[key],
        });
      });

      const stateTreeMap = {
        series: [
          {
            data: dataTreeMapTmp,
          },
        ],
        options: {
          legend: {
            show: false,
          },
          chart: {
            height: 350,
            type: "treemap",
          },
        },
      };

      // Convertir avec Min Max
      const stateRadialReturn = {
        series: returnETF.map((e) => normalizeNumberWithArray({ gap: 10, arrayNumber: returnETF, number: e, inverse: false })),
        options: {
          chart: {
            height: 350,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "22px",
                },
                value: {
                  fontSize: "16px",
                  formatter: (val) => {
                    return normalizeNumberWithArray({ gap: 10, arrayNumber: returnETF, number: val, inverse: true }) + "%";
                  },
                },
                total: {
                  show: true,
                  label: "Total Return",
                  formatter: function (w) {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    return totalReturn + "%";
                  },
                },
              },
            },
          },
          labels: labelETF,
        },
      };
      const stateRadialVolatility = {
        series: volatilityETF.map((e) => normalizeNumberWithArray({ gap: 10, arrayNumber: volatilityETF, number: e, inverse: false })),
        options: {
          chart: {
            height: 350,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "22px",
                },
                value: {
                  fontSize: "16px",
                  formatter: (val) => {
                    return normalizeNumberWithArray({ gap: 10, arrayNumber: volatilityETF, number: val, inverse: true }) + "%";
                  },
                },
                total: {
                  show: true,
                  label: "Total Volatility",
                  formatter: function (w) {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    return totalVolatility + "%";
                  },
                },
              },
            },
          },
          labels: labelETF,
        },
      };
      const stateRadialExpense = {
        series: expenseETF.map((e) => normalizeNumberWithArray({ gap: 0.1, arrayNumber: expenseETF, number: e, inverse: false })),
        options: {
          chart: {
            height: 350,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "22px",
                },
                value: {
                  fontSize: "16px",
                  formatter: (val) => {
                    return normalizeNumberWithArray({ gap: 0.1, arrayNumber: expenseETF, number: val, inverse: true }) + "%";
                  },
                },
                total: {
                  show: true,
                  label: "Total Fees",
                  formatter: function (w) {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    return getTotalExpense() + "%";
                  },
                },
              },
            },
          },
          labels: labelETF,
        },
      };

      setDataRadialReturn(stateRadialReturn);
      setDataRadialVolatility(stateRadialVolatility);
      setDataRadialExpense(stateRadialExpense);
      setStateTreeMap(stateTreeMap);
      setDataSectors(dataSectors);
      setDataCountries(dataCountries);
      setRandomKey((Math.random() + 1).toString(36).substring(7));
    }
  }, [data]);

  const getOptions = (label) => {
    return {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: label,
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(2) + "%";
          },
          title: {
            formatter: function (seriesName) {
              return seriesName + ":";
            },
          },
        },
      },
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  };

  if (data.listETF.length === 0) return <div></div>;

  return (
    <div>
      <h2>Key Values</h2>
      <div className="row">
        {/* Return  */}

        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <div id="chart">
                <ReactApexChart key={randomKey} options={dataRadialReturn.options} series={dataRadialReturn.series} type="radialBar" height={350} />
              </div>
            </div>
          </div>
        </div>
        {/* Volatility */}
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <div id="chart">
                <ReactApexChart key={randomKey} options={dataRadialVolatility.options} series={dataRadialVolatility.series} type="radialBar" height={350} />
              </div>
            </div>
          </div>
        </div>
        {/* Fees  */}
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <div id="chart">
                <ReactApexChart key={randomKey} options={dtaRadialExpense.options} series={dtaRadialExpense.series} type="radialBar" height={350} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>Diversification</h2>
      {/* Sector  */}
      <div className="row">
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <div id="chart">
                <ReactApexChart options={getOptions(sectors)} series={dataSectors} type="pie" height={400} />
              </div>
            </div>
          </div>
        </div>

        {/* Country  */}
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <div id="chart">
                <ReactApexChart options={getOptions(countries)} series={dataCountries} type="pie" height={400} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>Assets</h2>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div id="chart">
                {/* <ReactApexChart options={treeMapOptions} series={[{ data: dataTreeMap }]} type="treemap" width={500} /> */}
                <ReactApexChart options={stateTreeMap.options} series={stateTreeMap.series} type="treemap" height={400} />
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default GraphicComparisonETF;
