export const citation = [
  { citation: "We simply attempt to be fearful when others are greedy and to be greedy only when others are fearful.", author: "Warren Buffet" },
  { citation: "It takes 20 years to build a reputation and five minutes to ruin it. If you think about that, you'll do things differently.", author: "Warren Buffet" },
  { citation: "Price is what you pay. Value is what you get.", author: "Warren Buffet" },
  { citation: "Someone's sitting in the shade today because someone planted a tree a long time ago.", author: "Warren Buffet" },
  { citation: "Rule No.1: Never lose money. Rule No.2: Never forget rule No.1.", author: "Warren Buffet" },
  { citation: "Risk comes from not knowing what you're doing.", author: "Warren Buffet" },
  { citation: "It's only when the tide goes out that you discover who's been swimming naked.", author: "Warren Buffet" },
  { citation: "It's far better to buy a wonderful company at a fair price than a fair company at a wonderful price.", author: "Warren Buffet" },
  { citation: "Our favorite holding period is forever.", author: "Warren Buffet" },
  { citation: "It's better to hang out with people better than you. Pick out associates whose behavior is better than yours and you'll drift in that direction.", author: "Warren Buffet" },
  { citation: "We have three baskets for investing: yes, no, and too tough to understand.", author: "Charlie Munger" },
  { citation: "Live within your income and save so you can invest. Learn what you need to learn.", author: "Charlie Munger" },
  {
    citation: "It's waiting that helps you as an investor and a lot of people just can't stand to wait. If you didn't get the deferred -gratification gene, you've got to work very hard to overcome that.",
    author: "Charlie Munger",
  },
  { citation: "A great business at a fair price is superior to a fair business at a great price.", author: "Charlie Munger" },
  { citation: "An intelligent investing is value investing, acquiring more than you are paying for.", author: "Charlie Munger" },
  { citation: "The big money is not in buying or selling, but in the waiting.", author: "Charlie Munger" },
  { citation: "A lot of people with high IQs are terrible investors because they've got terrible temperaments.", author: "Charlie Munger" },
  { citation: "When you borrow a man's car, always return it with a tank of gas.", author: "Charlie Munger" },
  { citation: "We're not interested in taking a substantial chance of taking a lot of very decent people back to 'Go' so we can have one more zero on our net worth.", author: "Charlie Munger" },
  { citation: "The liabilities are always 100 percent good. It's the assets you have to worry about.", author: "Charlie Munger" },
  { citation: "Take a simple idea, and take it seriously.", author: "Charlie Munger" },
  { citation: "Mimicking the herd invites regression to the mean.", author: "Charlie Munger" },
  { citation: "Wall Street has too much wealth and political power.", author: "Charlie Munger" },
  { citation: "We both (Warren Buffett) insist on a lot of time being available almost every day to just sit and think. That is very uncommon in American business. We read and think.", author: "Charlie Munger" },
  { citation: "Our game is to recognize a big idea when it comes along when one doesn't come along very often.", author: "Charlie Munger" },
  { citation: "Ninety-nine percent of the troubles that threaten our civilization come from too optimistic, therefore we should have a system where the accounting is a way more conservative.", author: "Charlie Munger" },
  { citation: "Everywhere there is a large commission, there is a high probability of a rip-off.", author: "Charlie Munger" },
];
