import React, { useState, useEffect, Fragment } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { firebase_app } from "../../../../data/config";
import { analytics } from "../../../../data/config";
import { abonnement } from "../data/infoAbonnement";
import ListBenefits from "./listBenefit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faLeaf, faDumbbell, faBolt } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const Stripe = ({ data }) => {
  const user = data.user;

  const [products, setProducts] = useState([]);
  const [subscription, setSubscription] = useState(false);
  const [currency, setCurrency] = useState("usd");
  const [loadingStripe, setLoadingStripe] = useState(false);

  // To check
  useEffect(() => {
    firebase_app
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("subscriptions")
      .get()
      .then((snapshot) => {
        snapshot.forEach((subscription) => {
          const hasCurrentSub = subscription.data().status?.toLowerCase().includes("active");

          if (hasCurrentSub === true) {
            setSubscription(true);
          } else {
            setSubscription(false);
          }
        });
      });
  }, [user]);

  useEffect(() => {
    firebase_app
      .firestore()
      .collection("products")
      .where("active", "==", true)
      .get()
      .then((snapshot) => {
        const productsFirebase = [];
        snapshot.forEach(async (productDoc) => {
          productsFirebase[productDoc.id] = productDoc.data();
          const priceSnapshot = await productDoc.ref.collection("prices").get();
          productsFirebase[productDoc.id].prices = {};

          priceSnapshot.forEach(async (priceDoc) => {
            productsFirebase[productDoc.id].prices[priceDoc.id] = {
              priceId: priceDoc.id,
              priceData: priceDoc.data(),
            };
          });
        });

        setProducts(productsFirebase);
      });
  }, []);

  const checkOut = async (productID) => {
    const price = products[productID].prices;
    setLoadingStripe(true);

    let priceId = "";
    Object.entries(price).forEach(([priceIdStripe, data]) => {
      if (data.priceData.currency === currency) {
        priceId = priceIdStripe;
      }
    });

    const docRef = await firebase_app.firestore().collection("users").doc(user.uid).collection("checkout_sessions").add({
      price: priceId,
      //   price: "price_1MQtFbI9QvxD3AYYigelhoim",
      allow_promotion_codes: true,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });
    docRef.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data();

      if (error) {
        alert(error.message);
      }
      if (sessionId) {
        const stripe = await loadStripe("pk_live_51MVxeDGANkSO3wFUneRgWeqV3HdhBxNUwxSGDQeloqlWIh1yWLLR6VD7Fmo2BkSI0c3kSVzr7hTbUZxa4bslm3nF00fK0nSpW8");
        // const stripe = await loadStripe("pk_test_51MQt2vI9QvxD3AYYxGZvqus56s2jybBaAsiAOYmfHu89XgfmsJgb91w6dmVMcxxhxqtfDMIlkSUpWJvHK94NKrYM00Z97MoKxi");
        setLoadingStripe(false);
        stripe.redirectToCheckout({ sessionId });
      }
    });
  };

  return (
    <Fragment>
      <div className="container">
        <div>
          {/* Has no Subscription  */}
          {!subscription && (
            <div>
              <div className="col-sm-12 col-xl-12 m-b-50">
                <div className="row justify-content-md-center">
                  <div className="navig-container ">
                    <div className="tablist d-flex justify-content-center">
                      <div onClick={() => setCurrency("usd")} className={currency === "usd" ? "tablist-element tablist-element-active" : "tablist-element"}>
                        {"Dollar (US$)"}
                      </div>
                      <div onClick={() => setCurrency("eur")} className={currency === "eur" ? "tablist-element tablist-element-active" : "tablist-element"}>
                        {"Euro (€)"}
                      </div>
                    </div>
                  </div>

                  {/* <div className="btn-group" role="group" aria-label="Basic outlined example">
                    <button type="button" className={`btn btn-currency-left ${currency === "usd" ? "btn-currency-active" : ""}`} onClick={() => setCurrency("usd")}>
                      Dollar (US$)
                    </button>

                    <button type="button" className={`btn btn-currency-right ${currency === "eur" ? "btn-currency-active" : ""}`} onClick={() => setCurrency("eur")}>
                      Euro (€)
                    </button>
                  </div> */}
                </div>
              </div>
              {/* // Map Product Stripe  */}

              <div className=" row justify-content-center m-b-10">
                {abonnement.map((prod) => {
                  if (!subscription) {
                    const priceInt = parseInt(prod.prices);
                    const priceDec = parseInt(100 * (prod.prices - priceInt));
                    return (
                      <div className={isMobile ? "col-12" : "col-4"} key={prod.productID}>
                        <div className="card p-20">
                          <div className="card-header">
                            {prod.name === "Investor" && (
                              <div className="popularAbo" style={{ width: "fit-content", fontSize: "10px" }}>
                                Popular
                              </div>
                            )}
                            <div className="row ">
                              <h2 style={{ marginBottom: "0", marginTop: "10px" }}>
                                {" "}
                                {prod.name === "Starter" && <FontAwesomeIcon style={{ fontSize: "20px", verticalAlign: "baseline" }} icon={faLeaf} />}
                                {prod.name === "Investor" && <FontAwesomeIcon style={{ fontSize: "20px", verticalAlign: "baseline" }} icon={faDumbbell} />}
                                {prod.name === "Pro" && <FontAwesomeIcon style={{ fontSize: "20px", verticalAlign: "baseline" }} icon={faBolt} />}
                                {`   ${prod.name}`}
                              </h2>
                            </div>
                            <span>
                              <div style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem" }} className="row ">
                                {"Subscription"}
                              </div>
                            </span>

                            <div className="row justify-content-center m-t-25">
                              <h2 style={{ fontWeight: "600" }}>
                                {prod.name === "Pro" && <span style={{ fontSize: "20px", fontWeight: "600" }}>{"From "}</span>}
                                <span>{currency === "usd" ? "$" : "€"}</span>
                                {priceInt}
                                <sup>{priceDec === 0 ? "" : "." + priceDec}</sup>
                                <span style={{ color: "#acacac", fontSize: "18px", fontWeight: "400" }}>/month</span>
                              </h2>
                            </div>
                          </div>

                          {prod.name === "Free" && (
                            <button disabled={loadingStripe} className={`btn btnSecondary btn-lg btn-block btn-disabled-submit`}>
                              {"Free     "}
                            </button>
                          )}

                          {prod.name === "Pro" && (
                            <button disabled={loadingStripe} className={`btn btnSecondary btn-lg btn-block btn-disabled-submit`}>
                              {"Contact Us     "}
                            </button>
                          )}

                          {prod.name !== "Free" && prod.name !== "Pro" && (
                            <button
                              disabled={loadingStripe}
                              className={`btn btnSecondary btn-lg btn-block ${loadingStripe && "btn-disabled-submit"}`}
                              onClick={() => {
                                checkOut(prod.productID);
                                analytics.logEvent("checkout_stripe", { mail: user.email });
                              }}
                            >
                              {"Start Trial     "}
                              <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                          )}

                          <div className="m-10"></div>
                          <h5>Includes :</h5>
                          <ListBenefits option={prod.option} />
                        </div>
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
              </div>
              {/* Create Portal Link */}
            </div>
          )}

          {/* Has a subscription */}
          {subscription && (
            <div>
              <div className=" row justify-content-center m-b-20">
                <div style={{ fontSize: "20px" }}>{"You have an active Subscription"}</div>
              </div>
              <div className=" row justify-content-center text-center m-b-10">
                <a className="btn-home-action-kid" href="https://billing.stripe.com/p/login/14k9BIbmT2XVc2keUU" style={{ display: "flex", alignItems: "center" }}>
                  Manage Subscription
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Stripe;
