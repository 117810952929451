export const fakePortfolio = [
  {
    symbol: "AAPL",
    quantity: 1,
    buyPrice: 165.22,
    invested: 165.22,
    name: "Apple Inc.",
    note: {
      earning: 92,
      stability: 45,
      price: 62,
      timing: 85,
    },
    price: {
      price: 193.22,
      variation: -0.0065809707,
      currency: "$",
      historical: [1, 1, 1],
    },
    PE: 32.804756,
  },

  {
    symbol: "MSFT",
    quantity: 0.5,
    buyPrice: 260.4,
    invested: 130.2,
    name: "Microsoft Corporation",
    note: {
      earning: 99,
      stability: 77,
      price: 62,
      timing: 74,
    },
    price: {
      price: 330.72,
      variation: -0.020872153,
      currency: "$",
      historical: [2, 2, 2],
    },
    PE: 35.830986,
  },

  {
    symbol: "TSLA",
    quantity: 0.8,
    buyPrice: 305.2,
    invested: 244.16,
    name: "Tesla, Inc.",
    note: {
      earning: 72,
      stability: 81,
      price: 62,
      timing: 74,
    },
    price: {
      price: 255.71,
      variation: 0.03268394,
      currency: "$",
      historical: [3, 4, 5],
    },
    PE: 83.02273,
  },
];
