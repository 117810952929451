import React, { Component, useContext } from "react";
import InfoComponent from "./components/infoComponent";
import SimpleChart from "./components/simpleChart";

import HistoricEarning from "./components/historicEarning";
import PersonalMemo from "./components/personalMemo";
import { SymbolContext } from "../utils/symbolContext";
import HistoricDividend from "./components/dividend";
import CardFinancialDataOverview from "./components/cardFinancialDataOverView";
import ChartPrice from "./components/ChartPrice";

const MenuInfoPage = () => {
  const { data, symbol, isLoading, error, historical } = useContext(SymbolContext);

  return (
    <div>
      {/* <h2>Presentation </h2>
      <p style={{ fontSize: "1rem", color: "grey" }}>A brief introduction to the asset, what the company does, share price, etc.</p> */}
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-8">
          <InfoComponent />
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">{data.user !== null && <PersonalMemo symbol={symbol} price={data.price} />}</div>
        <div className="col-12">
          <ChartPrice symbol={symbol} />
        </div>
      </div>
      <h2>Financial Overview</h2>
      <p style={{ fontSize: "1rem", color: "grey" }}>Key figures to understand the company's financial position, earnings and dividends</p>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <CardFinancialDataOverview data={{ name: data.name, symbol: symbol }} />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <HistoricEarning data={{ name: data.name, symbol: symbol }} />
          <HistoricDividend data={{ name: data.name, symbol: symbol }} />
        </div>
      </div>
    </div>
  );
};

export default MenuInfoPage;
