import React, { Component, useContext, useEffect, useState } from "react";
import { ETFContext } from "../utils/etfContext";
import Loader from "../../tools/loader";

const CardPresentationETF = () => {
  const data = useContext(ETFContext);
  const [leverage, setLeverage] = useState(1);
  const [dividend, setDividend] = useState();
  const [risky, setRisky] = useState(false);

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      let totAsset = 0;
      setDividend(parseFloat(data["Yield"]));

      setRisky(parseFloat(data["Performance"]["1y_Volatility"]) > 35);
      Object.keys(data["Holdings"]).forEach((element) => {
        totAsset = totAsset + parseInt(10 * parseFloat(data["Holdings"][element]["Assets_%"])) / 10;
      });

      setLeverage(Math.round(totAsset / 100));
    }
  }, [data]);

  if (Object.keys(data).length === 0) {
    return <Loader />;
  } else {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-2">
              <div className="text-center " style={{ backgroundColor: "#f3f6fc9e", border: "solid 2px #2599f3", color: "#2599f3", height: "100%", display: "grid", alignItems: "center", fontSize: "1.2rem", fontWeight: "600" }}>
                {" "}
                ETF{" "}
              </div>
            </div>
            <div className="col-10">
              <h5 style={{ fontWeight: "600", fontSize: "1.5rem" }}>{data.Name}</h5>
              <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                <span style={{ color: "#999" }}>ISIN</span>
                <span> {`${data.ISIN} | `}</span>
                <span style={{ color: "#999" }}>TICKER</span>
                <span> {`${data.Code}`}</span>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "20px", marginLeft: "10px", fontWeight: "600", fontSize: "1.4rem" }}>
            <span className="badge badge-info m-r-10">{data.Domicile}</span>
            <span className="badge badge-info m-r-10">{`Currency: ${data.CurrencyCode}`}</span>
            <span className="badge badge-info m-r-10">{data.Company_Name}</span>
            <span className="badge badge-success m-r-10">{dividend > 0 ? "Distributive" : "Cumulative"}</span>
            <span className="badge badge-danger m-r-10">{"Leverage x" + leverage}</span>

            {risky && <span className="badge badge-danger m-r-10">{"High Risk"}</span>}
          </div>
        </div>
      </div>
    );
  }
};

export default CardPresentationETF;
