import React, { Component, useContext, useEffect, useState } from "react";
import { ETFContext } from "../utils/etfContext";
import { Progress } from "reactstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../tools/loader";

const CardPerformanceETF = () => {
  const data = useContext(ETFContext);
  const [performances, setPerformances] = useState([]);

  useEffect(() => {
    let perf = [];

    if (Object.keys(data).length !== 0) {
      perf.push({ period: "Year To Date", return: data["Performance"]["Returns_YTD"], volatility: "0" });
      perf.push({ period: "1 Year", return: data["Performance"]["Returns_1Y"], volatility: data["Performance"]["1y_Volatility"] });
      perf.push({ period: "3 Years", return: data["Performance"]["Returns_3Y"], volatility: data["Performance"]["3y_Volatility"] });
      perf.push({ period: "5 Years", return: data["Performance"]["Returns_5Y"], volatility: "0" });
      perf.push({ period: "10 Years", return: data["Performance"]["Returns_10Y"], volatility: "0" });

      setPerformances(perf);
    }
  }, [data]);

  if (Object.keys(data).length === 0) {
    return <>Loading...</>;
  }

  const columns = [
    {
      name: "Period",
      selector: (row) => {
        return row.period;
      },
    },
    {
      name: "Return (%)",
      selector: (row) => {
        if (parseInt(row.return) === 0) {
          return "-";
        } else {
          if (parseInt(row.return) > 0) {
            return (
              <div style={{ color: "green", fontWeight: "600" }}>
                <FontAwesomeIcon className="m-r-5" icon={faArrowUp} />
                {`${row.return}%`}
              </div>
            );
          } else {
            return (
              <div style={{ color: "red", fontWeight: "600" }}>
                <FontAwesomeIcon className="m-r-5" icon={faArrowDown} />
                {`${row.return}%`}
              </div>
            );
          }
        }
      },
    },

    {
      name: "Risk (Volatility %)",
      selector: (row) => {
        if (parseInt(row.volatility) === 0) {
          return "-";
        } else {
          return <div style={{ color: "black", fontWeight: "600" }}>{`${row.volatility}%`}</div>;
        }
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  if (Object.keys(data).length === 0) {
    return <Loader />;
  }
  return (
    <div className="card">
      <div className="card-body">
        <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>RISK/RETURN</h2>

        <DataTable data={performances} columns={columns} customStyles={customStyles} />
      </div>
    </div>
  );
};

export default CardPerformanceETF;
