import React, { Component, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Line } from "react-chartjs-2";
import { downloadCSV } from "./exportCSV";
import {
  dataFinancialIncomeStatement1,
  dataFinancialIncomeStatement2,
  dataFinancialIncomeStatement3,
  dataFinancialIncomeStatement4,
  dataFinancialIncomeStatement5,
  dataFinancialBalanceSheet1,
  dataFinancialBalanceSheet2,
  dataFinancialBalanceSheet3,
  dataFinancialBalanceSheet4,
  dataFinancialBalanceSheet5,
  dataFinancialBalanceSheet6,
  dataFinancialBalanceSheet7,
  dataFinancialCashFlow1,
  dataFinancialCashFlow2,
  dataFinancialCashFlow3,
  dataFinancialCashFlow4,
  dataFinancialCashFlow5,
  dataFinancialCashFlow6,
} from "./variableForFinancialData";

import { getFinancialData, getFinancialDataQuarter } from "../../../../../firebase-marketadvisor/firebase-financial-data";
import Loader from "../../../tools/loader";
import { requestAccessToProduct } from "../../../../../firebase-marketadvisor/firebase-data-user";
import { firebase_app } from "../../../../../data/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faChartArea, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { customStyles, plugins } from "./functionsFinancial";

const PageFinancialData = ({ data, financial }) => {
  const { symbol } = useParams();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["user"]);

  // State

  const [financialData, setFinancialData] = useState(null);
  const [listTimeAvailable, setListTimeAvailable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeMenu, setActiveMenu] = useState(1);
  const [selectTimeFrame, setSelectTimeFrame] = useState("year");
  const [authorisation, setAuthorisation] = useState(true);
  const [columnsTable, setColumnsTable] = useState([]);

  useEffect(() => {
    if (!financial.queryFinancial.isPending) {
      let dataFin = financial.queryFinancial.data.financial;
      if (selectTimeFrame === "quarter") {
        dataFin = financial.queryFinancial.data.financialQuarter;
      }
      setFinancialData(dataFin);
    }
  }, [financial]);

  useEffect(() => {
    if (!financial.queryFinancial.isPending) {
      let dataFin = financial.queryFinancial.data.financial;
      if (selectTimeFrame === "quarter") {
        dataFin = financial.queryFinancial.data.financialQuarter;
      }
      setFinancialData(dataFin);
    }
  }, [selectTimeFrame]);

  useEffect(() => {
    if (financialData) {
      handleDataFinancial();
      setLoading(false);
    }
  }, [financialData]);

  const handleDataFinancial = () => {
    let dataFin = financial.queryFinancial.data.financial;
    if (selectTimeFrame === "quarter") {
      dataFin = financial.queryFinancial.data.financialQuarter;
    }

    const authorisation = true;

    var myKey = Object.keys(dataFin).sort().reverse();

    var listTimeAvailable = [];
    var columns = [
      {
        name: "",
        wrap: true,
        width: "200px",
        style: {
          letterSpacing: "normal",
        },
        selector: (row) => {
          return (
            <div id={row.variable}>
              {row.total ? (
                <strong onClick={() => this.plotGraph(row.name, row.variable)}>
                  <FontAwesomeIcon className="m-r-5" icon={faChartLine} size="lg" />
                  {row.name}
                </strong>
              ) : (
                <div onClick={() => this.plotGraph(row.name, row.variable)}>
                  <FontAwesomeIcon className="m-r-5" icon={faChartLine} size="lg" />
                  {row.name}
                </div>
              )}
            </div>
          );
        },
      },
    ];

    for (let i = 0; i < Math.min(myKey.length); i++) {
      listTimeAvailable.push(myKey[i]);
      columns.push({
        name: myKey[i],
        width: "100px",
        style: {
          letterSpacing: "normal",
          color: "rgba(0,0,0,.64)",
        },
        selector: (row) => {
          if (authorisation === false && i > 3) {
            return (
              <div className="text-center">
                <FontAwesomeIcon icon={faLock} />
              </div>
            );
          }

          if (financialData[myKey[i]][row.variable] === null) {
            return <div>-</div>;
          }
          if (isNaN(financialData[myKey[i]][row.variable])) {
            return <div>-</div>;
          }

          return (
            <div>
              {row.total ? (
                <strong>{(parseFloat(financialData[myKey[i]][row.variable]) / row.simplifier).toLocaleString("en-US", { minimumFractionDigits: 0 }) + " " + row.symbol}</strong>
              ) : (
                <div>{(parseFloat(financialData[myKey[i]][row.variable]) / row.simplifier).toLocaleString("en-US", { minimumFractionDigits: 0 }) + " " + row.symbol}</div>
              )}
            </div>
          );
        },
      });
    }

    setFinancialData(dataFin);
    setListTimeAvailable(Object.keys(dataFin));
    setColumnsTable(columns);
  };

  /////////// COLUMN

  if (loading) {
    return <Loader />;
  } else {
    return (
      <div>
        {/* Presentation  */}
        <div className="card col-sm-12 col-xl-12">
          <div className="card-header ">
            <h5>Financial Data</h5>
            <p className="m-t-10">
              {"MarketAdvisor give you an access to the financial datas for " + symbol + " within a range of "}
              <strong> {`${listTimeAvailable.length} ${selectTimeFrame}s.`} </strong>
              {" Financial Datas are company’s core financial statements that shows their profit and loss over a period of time"}
            </p>

            {authorisation ? (
              <button className="btn btnPrimary " onClick={() => downloadCSV(financialData, symbol)}>
                Download to CSV
              </button>
            ) : (
              <button className="btn btnPrimary " onClick={() => {}}>
                <FontAwesomeIcon icon={faLock} /> Download to CSV
              </button>
            )}
          </div>
          {/* Menu  */}
          <div className="elementMenuPageSymbol d-flex justify-content-center" style={{ boxShadow: "none" }}>
            <div
              href="#"
              className={activeMenu === 1 ? "active" : ""}
              onClick={() => {
                setActiveMenu(1);
              }}
            >
              Income Statement
            </div>
            <div
              href="#"
              className={activeMenu === 2 ? "active" : ""}
              onClick={() => {
                setActiveMenu(2);
              }}
            >
              Balance Sheet
            </div>
            <div
              href="#"
              className={activeMenu === 3 ? "active" : ""}
              onClick={() => {
                setActiveMenu(3);
              }}
            >
              Cash Flow
            </div>
          </div>
        </div>

        {/* POUR AJOUTER LE GRAPHIQUE _ A MODIFIER  */}

        {/* {this.state.loading === false && this.state.activePlot && this.state.lineChartData.datasets.length > 0 ? (
          <div className="card">
            <div className="card-body ">
              <div className="chart-block">
                <Line data={this.state.lineChartData} options={this.lineChartOptions} plugins={plugins} datasetKeyProvider={this.datasetKeyProvider} width={778} height={300} />
              </div>
              <div className="row justify-content-md-center m-t-10">
                <button
                  className="btn btnPrimary"
                  onClick={() => {
                    this.deleteGraph();
                  }}
                >
                  Close Plot
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )} */}

        {/* Income Statement  */}
        <div className="card">
          <div className="col-sm-12 col-xl-12">
            <div className="card-body">
              {/* Selection Quarter ou Year  */}
              <div>
                <div className="col-sm-12 col-xl-12 m-b-50">
                  <div className="row justify-content-md-center">
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                      <button type="button" className={`btn btn-currency-left ${selectTimeFrame === "year" ? "btn-currency-active" : ""}`} onClick={() => setSelectTimeFrame("year")}>
                        Year
                      </button>

                      <button type="button" className={`btn btn-currency-right ${selectTimeFrame === "quarter" ? "btn-currency-active" : ""}`} onClick={() => setSelectTimeFrame("quarter")}>
                        Quarter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="datatable-react">
                <span className="m-l-10">In Millions except per share items</span>
                {activeMenu === 1 ? (
                  <div>
                    <DataTable title={"Revenue"} columns={columnsTable} data={dataFinancialIncomeStatement1} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Operating Expenses & Income"} columns={columnsTable} data={dataFinancialIncomeStatement2} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Tax & Other"} columns={columnsTable} data={dataFinancialIncomeStatement3} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Net Income"} columns={columnsTable} data={dataFinancialIncomeStatement4} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Other Items"} columns={columnsTable} data={dataFinancialIncomeStatement5} customStyles={customStyles} highlightOnHover />
                  </div>
                ) : (
                  ""
                )}
                {/* Balance Sheet */}

                {activeMenu === 2 ? (
                  <div>
                    <DataTable title={"Cash & Short Term Investments"} columns={columnsTable} data={dataFinancialBalanceSheet1} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Receivables"} columns={columnsTable} data={dataFinancialBalanceSheet2} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Current Assets"} columns={columnsTable} data={dataFinancialBalanceSheet3} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Long-Term Assets"} columns={columnsTable} data={dataFinancialBalanceSheet4} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Current Liabilities"} columns={columnsTable} data={dataFinancialBalanceSheet5} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Common Equity"} columns={columnsTable} data={dataFinancialBalanceSheet6} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Other Items"} columns={columnsTable} data={dataFinancialBalanceSheet7} customStyles={customStyles} highlightOnHover />
                  </div>
                ) : (
                  ""
                )}

                {/* Cash Flow      */}
                {activeMenu === 3 ? (
                  <div>
                    <DataTable title={"Cash Flow From Operating Activities"} columns={columnsTable} data={dataFinancialCashFlow1} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Cash Flow From Investing Activities"} columns={columnsTable} data={dataFinancialCashFlow2} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Cash Flow From Financing Activities"} columns={columnsTable} data={dataFinancialCashFlow3} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Change In Cash & Equivalents"} columns={columnsTable} data={dataFinancialCashFlow4} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Cash Flow"} columns={columnsTable} data={dataFinancialCashFlow5} customStyles={customStyles} highlightOnHover />
                    <div className="m-50"></div>
                    <DataTable title={"Other Items"} columns={columnsTable} data={dataFinancialCashFlow6} customStyles={customStyles} highlightOnHover />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PageFinancialData;

// class PageFinancialData extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       loading: true,
//       authorisation: false,
//       selectTimeFrame: "year",
//       symbol: this.props.data.symbol,
//       financial: {},
//       financialYear: {},
//       financialQuarter: {},
//       activeMenu: 1,
//       title: "Income Statement",
//       listTimeAvailable: [],
//       listYearAvailable: [],
//       listQuarterAvailable: [],
//       activePlot: false,
//       currentPlot: [],
//       lineChartData: {
//         labels: [],
//         datasets: [],
//         plugins: {
//           datalabels: {
//             display: false,
//             color: "white",
//           },
//         },
//       },
//     };
//   }

//   componentDidMount = () => {
//     firebase_app.auth().onAuthStateChanged((user) => {
//       if (user) {
//         // requestAccessToProduct({ user, product: "financialData" })

//         getFinancialData(this.state.symbol)
//           .then((r) => {
//             this.handleChangeTimeFrame({ dataExtract: r, timeFrame: "year", authorisation: true });
//           })
//           .catch((e) => {});
//       }
//     });
//   };

//   handleChangeTimeFrame = (data) => {
//     const r = data.dataExtract;
//     const timeFrame = data.timeFrame;
//     const authorisation = data.authorisation;

//     var myKey = Object.keys(r).sort().reverse();
//     var listTimeAvailable = [];
//     var columns = [
//       {
//         name: "",
//         wrap: true,
//         width: "200px",

//         style: {
//           letterSpacing: "normal",
//         },
//         selector: (row) => {
//           return (
//             <div id={row.variable}>
//               {row.total ? (
//                 <strong onClick={() => this.plotGraph(row.name, row.variable)}>
//                   <FontAwesomeIcon className="m-r-5" icon={faChartLine} size="lg" />
//                   {row.name}

//                   {/* <i className="icofont icofont-chart-bar-graph m-l-5"></i> */}
//                 </strong>
//               ) : (
//                 <div onClick={() => this.plotGraph(row.name, row.variable)}>
//                   <FontAwesomeIcon className="m-r-5" icon={faChartLine} size="lg" />
//                   {row.name}
//                 </div>
//               )}
//             </div>
//           );
//         },
//       },
//     ];

//     for (let i = 0; i < Math.min(myKey.length); i++) {
//       listTimeAvailable.push(myKey[i]);
//       columns.push({
//         name: myKey[i],
//         width: "100px",
//         style: {
//           letterSpacing: "normal",
//           color: "rgba(0,0,0,.64)",
//         },
//         selector: (row) => {
//           if (authorisation === false && i > 3) {
//             return (
//               <div className="text-center">
//                 <FontAwesomeIcon icon={faLock} />
//               </div>
//             );
//           }

//           if (this.state.financial[myKey[i]][row.variable] === null) {
//             return <div>-</div>;
//           }
//           if (isNaN(this.state.financial[myKey[i]][row.variable])) {
//             return <div>-</div>;
//           }

//           return (
//             <div>
//               {row.total ? (
//                 <strong>{(parseFloat(this.state.financial[myKey[i]][row.variable]) / row.simplifier).toLocaleString("en-US", { minimumFractionDigits: 0 }) + " " + row.symbol}</strong>
//               ) : (
//                 <div>{(parseFloat(this.state.financial[myKey[i]][row.variable]) / row.simplifier).toLocaleString("en-US", { minimumFractionDigits: 0 }) + " " + row.symbol}</div>
//               )}
//             </div>
//           );
//         },
//       });
//     }
//     if (timeFrame === "year") {
//       this.setState({
//         authorisation,
//         financial: r,
//         financialYear: r,
//         loading: false,
//         columns,
//         columnsYear: columns,
//         listTimeAvailable,
//         selectTimeFrame: "year",
//         listYearAvailable: listTimeAvailable,
//       });
//     } else {
//       this.setState({
//         authorisation,
//         financial: r,
//         financialQuarter: r,
//         loading: false,
//         columns,
//         columnsQuarter: columns,
//         listTimeAvailable,
//         selectTimeFrame: "quarter",
//         listQuarterAvailable: listTimeAvailable,
//       });
//     }
//   };

//   generateRandomColor = (mix) => {
//     var red = (Math.random() * 256) >> 0;
//     var green = (Math.random() * 256) >> 0;
//     var blue = (Math.random() * 256) >> 0;

//     // mix the color
//     if (mix != null) {
//       red = ((red + mix.red) / 2) >> 0;
//       green = ((green + mix.green) / 2) >> 0;
//       blue = ((blue + mix.blue) / 2) >> 0;
//     }
//     var rr = red.toString(16);
//     if (rr.length === 1) {
//       rr = "0" + rr[0];
//     }
//     var gg = green.toString(16);
//     if (gg.length === 1) {
//       gg = "0" + gg[0];
//     }
//     var bb = blue.toString(16);
//     if (bb.length === 1) {
//       bb = "0" + bb[0];
//     }
//     return "#" + rr + gg + bb;
//   };

//   setTimeFrame = (d) => {
//     const timeFrame = d.timeFrame;
//     let listTimeAvailable = [];
//     let financial = {};
//     let columns = [];

//     if (timeFrame !== this.state.selectTimeFrame) {
//       this.setState({ loading: true });
//       if (timeFrame === "year") {
//         financial = this.state.financialYear;
//         listTimeAvailable = this.state.listYearAvailable;
//         columns = this.state.columnsYear;

//         this.setState({
//           selectTimeFrame: timeFrame,
//           financial,
//           loading: false,
//           listTimeAvailable,
//           columns,
//         });
//       } else {
//         if (this.state.listQuarterAvailable.length === 0) {
//           getFinancialDataQuarter(this.state.symbol)
//             .then((r) => {
//               this.handleChangeTimeFrame({ dataExtract: r, timeFrame: "quarter", authorisation: this.state.authorisation });
//             })
//             .catch((e) => {});
//         } else {
//           financial = this.state.financialQuarter;
//           listTimeAvailable = this.state.listQuarterAvailable;
//           columns = this.state.columnsQuarter;

//           this.setState({
//             selectTimeFrame: timeFrame,
//             columns,
//             loading: false,
//             financial,
//             listTimeAvailable,
//           });
//         }
//       }
//     }
//   };

//   lineChartOptions = {
//     maintainAspectRatio: true,
//     interaction: {
//       intersect: false,
//       mode: "index",
//     },
//     tooltips: {
//       enabled: true,
//       intersect: false,
//       mode: "index",
//       callbacks: {
//         label: (ticks, tooltipItem) => {
//           const label = `${tooltipItem.datasets[ticks.datasetIndex].label} ${(ticks.yLabel / 1000000).toLocaleString("en-US", { minimumFractionDigits: 0 }) + " M$"}`;
//           return label;
//         },
//       },
//     },
//     legend: { display: true },

//     scales: {
//       xAxes: [
//         {
//           gridLines: {
//             display: true,
//           },
//           stacked: true,
//         },
//       ],
//       yAxes: [
//         {
//           gridLines: {
//             display: true,
//           },
//           stacked: false,
//           ticks: {
//             callback: function (value, index, ticks) {
//               return (value / 1000000).toLocaleString("en-US", { minimumFractionDigits: 0 }) + " M$";
//             },
//           },
//         },
//       ],
//     },
//     plugins: { datalabels: { display: false } },
//   };

//   datasetKeyProvider = () => {
//     return Math.random();
//   };

//   deleteGraph = () => {
//     var lineChartData = this.state.lineChartData;
//     lineChartData.datasets = [];
//     const currentPlot = this.state.currentPlot;

//     currentPlot.forEach((variable) => {
//       document.getElementById(variable).style.color = "#000000de";
//     });

//     this.setState({ lineChartData, activePlot: true, currentPlot: [] });
//   };
//   plotGraph = (name, variable) => {
//     const fin = this.state.financial;
//     var lineChartData = this.state.lineChartData;
//     let years = [...this.state.listTimeAvailable].reverse();
//     var currentPlot = [...this.state.currentPlot];
//     var resultCalcRatio = [];

//     if (this.state.authorisation === false) {
//       years = years.slice(Math.max(-4, -years.length));
//     }
//     if (currentPlot.includes(variable)) {
//       currentPlot = currentPlot.filter((value) => value !== variable);
//       lineChartData.labels = years;
//       lineChartData.datasets = lineChartData.datasets.filter((value) => value.label !== name);
//       document.getElementById(variable).style.color = null;
//     } else {
//       currentPlot.push(variable);
//       years.forEach((year) => {
//         resultCalcRatio.push(fin[year][variable]);
//       });

//       lineChartData.labels = years;
//       lineChartData.datasets.push({
//         data: resultCalcRatio,
//         label: name,
//         backgroundColor: "rgba(68, 102, 242, 0.3)",
//         fill: false,
//         pointRadius: 0,
//         borderColor: this.generateRandomColor({ red: 19, green: 198, blue: 54 }), //({ red: 99, green: 102, blue: 241 }),  generateRandomColor({red: 19, green: 198, blue: 54});//"#" +Math.floor(Math.random() * 16777215).toString(16),
//         borderWidth: 2,
//       });
//       document.getElementById(variable).style.color = "#0081e4";
//     }

//     this.setState({ lineChartData, activePlot: true, currentPlot });
//   };

//   render() {

//     if (this.state.loading) {
//       return <Loader />;
//     } else {
//       return (
//         <div>
//           {/* Presentation  */}
//           <div className="card col-sm-12 col-xl-12">
//             <div className="card-header ">
//               <h5>Financial Data</h5>
//               <p className="m-t-10">
//                 {"MarketAdvisor give you an access to the financial datas for " + this.state.symbol + " within a range of "}
//                 <strong> {`${this.state.listTimeAvailable.length} ${this.state.selectTimeFrame}s.`} </strong>
//                 {" Financial Datas are company’s core financial statements that shows their profit and loss over a period of time"}
//               </p>

//               {this.state.authorisation ? (
//                 <button className="btn btnPrimary " onClick={() => downloadCSV(this.state.financial, this.state.symbol)}>
//                   Download to CSV
//                 </button>
//               ) : (
//                 <button className="btn btnPrimary " onClick={() => {}}>
//                   <FontAwesomeIcon icon={faLock} /> Download to CSV
//                 </button>
//               )}
//             </div>
//             {/* Menu  */}
//             <div className="elementMenuPageSymbol d-flex justify-content-center" style={{ boxShadow: "none" }}>
//               <div
//                 href="#"
//                 className={this.state.activeMenu === 1 ? "active" : ""}
//                 onClick={() => {
//                   this.setState({ activeMenu: 1, title: "Income Statement" });
//                 }}
//               >
//                 Income Statement
//               </div>
//               <div
//                 href="#"
//                 className={this.state.activeMenu === 2 ? "active" : ""}
//                 onClick={() => {
//                   this.setState({ activeMenu: 2, title: "Balance Sheet" });
//                 }}
//               >
//                 Balance Sheet
//               </div>
//               <div
//                 href="#"
//                 className={this.state.activeMenu === 3 ? "active" : ""}
//                 onClick={() => {
//                   this.setState({ activeMenu: 3, title: "Cash Flow" });
//                 }}
//               >
//                 Cash Flow
//               </div>
//             </div>
//           </div>

//           {/* Bulle d'information  */}
//           {/*
//           <div className="card">
//             <i className="icon-info-alt"></i>

//           </div> */}
//           {/* Chart  */}

//           {this.state.loading === false && this.state.activePlot && this.state.lineChartData.datasets.length > 0 ? (
//             <div className="card">
//               <div className="card-body ">
//                 <div className="chart-block">
//                   <Line data={this.state.lineChartData} options={this.lineChartOptions} plugins={plugins} datasetKeyProvider={this.datasetKeyProvider} width={778} height={300} />
//                 </div>
//                 <div className="row justify-content-md-center m-t-10">
//                   <button
//                     className="btn btnPrimary"
//                     onClick={() => {
//                       this.deleteGraph();
//                     }}
//                   >
//                     Close Plot
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <div></div>
//           )}

//           {/* Income Statement  */}
//           <div className="card">
//             <div className="col-sm-12 col-xl-12">
//               <div className="card-body">
//                 {/* Selection Quarter ou Year  */}
//                 <div>
//                   <div className="col-sm-12 col-xl-12 m-b-50">
//                     <div className="row justify-content-md-center">
//                       <div className="btn-group" role="group" aria-label="Basic outlined example">
//                         <button type="button" className={`btn btn-currency-left ${this.state.selectTimeFrame === "year" ? "btn-currency-active" : ""}`} onClick={() => this.setTimeFrame({ timeFrame: "year" })}>
//                           Year
//                         </button>

//                         <button type="button" className={`btn btn-currency-right ${this.state.selectTimeFrame === "quarter" ? "btn-currency-active" : ""}`} onClick={() => this.setTimeFrame({ timeFrame: "quarter" })}>
//                           Quarter
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="datatable-react">
//                   <span className="m-l-10">In Millions except per share items</span>
//                   {this.state.activeMenu === 1 ? (
//                     <div>
//                       <DataTable title={"Revenue"} columns={this.state.columns} data={dataFinancialIncomeStatement1} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Operating Expenses & Income"} columns={this.state.columns} data={dataFinancialIncomeStatement2} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Tax & Other"} columns={this.state.columns} data={dataFinancialIncomeStatement3} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Net Income"} columns={this.state.columns} data={dataFinancialIncomeStatement4} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Other Items"} columns={this.state.columns} data={dataFinancialIncomeStatement5} customStyles={customStyles} highlightOnHover />
//                     </div>
//                   ) : (
//                     ""
//                   )}
//                   {/* Balance Sheet */}

//                   {this.state.activeMenu === 2 ? (
//                     <div>
//                       <DataTable title={"Cash & Short Term Investments"} columns={this.state.columns} data={dataFinancialBalanceSheet1} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Receivables"} columns={this.state.columns} data={dataFinancialBalanceSheet2} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Current Assets"} columns={this.state.columns} data={dataFinancialBalanceSheet3} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Long-Term Assets"} columns={this.state.columns} data={dataFinancialBalanceSheet4} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Current Liabilities"} columns={this.state.columns} data={dataFinancialBalanceSheet5} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Common Equity"} columns={this.state.columns} data={dataFinancialBalanceSheet6} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Other Items"} columns={this.state.columns} data={dataFinancialBalanceSheet7} customStyles={customStyles} highlightOnHover />
//                     </div>
//                   ) : (
//                     ""
//                   )}

//                   {/* Cash Flow      */}
//                   {this.state.activeMenu === 3 ? (
//                     <div>
//                       <DataTable title={"Cash Flow From Operating Activities"} columns={this.state.columns} data={dataFinancialCashFlow1} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Cash Flow From Investing Activities"} columns={this.state.columns} data={dataFinancialCashFlow2} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Cash Flow From Financing Activities"} columns={this.state.columns} data={dataFinancialCashFlow3} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Change In Cash & Equivalents"} columns={this.state.columns} data={dataFinancialCashFlow4} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Cash Flow"} columns={this.state.columns} data={dataFinancialCashFlow5} customStyles={customStyles} highlightOnHover />
//                       <div className="m-50"></div>
//                       <DataTable title={"Other Items"} columns={this.state.columns} data={dataFinancialCashFlow6} customStyles={customStyles} highlightOnHover />
//                     </div>
//                   ) : (
//                     ""
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//     }
//   }
// }

// export default PageFinancialData;
