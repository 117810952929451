export const dataFinancialIncomeStatement1 = [
  { id: 0, name: "Cost Of Revenue", variable: "costOfRevenue", total: false, simplifier: 1000000, symbol: "" },
  { id: 1, name: "Gross Profit", variable: "grossProfit", total: false, simplifier: 1000000, symbol: "" },
  { id: 2, name: "Total Revenue", variable: "totalRevenue", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialIncomeStatement2 = [
  { id: 3, name: "Selling Marketing Expenses", variable: "sellingAndMarketingExpenses", total: false, simplifier: 1000000, symbol: "" },
  { id: 4, name: "Selling General Admin Expenses", variable: "sellingGeneralAdministrative", total: false, simplifier: 1000000, symbol: "" },
  { id: 5, name: "R&D Expenses", variable: "researchDevelopment", total: false, simplifier: 1000000, symbol: "" },
  { id: 6, name: "Other Operating Expenses", variable: "otherOperatingExpenses", total: false, simplifier: 1000000, symbol: "" },
  { id: 7, name: "Net Income Other Non-Operating", variable: "nonOperatingIncomeNetOther", total: false, simplifier: 1000000, symbol: "" },
  { id: 8, name: "Total Other Income Expense Net", variable: "totalOtherIncomeExpenseNet", total: false, simplifier: 1000000, symbol: "" },
  { id: 9, name: "Total Expenses", variable: "totalOperatingExpenses", total: true, simplifier: 1000000, symbol: "" },
  { id: 10, name: "Operating Income", variable: "operatingIncome", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialIncomeStatement3 = [
  { id: 11, name: "Interest Expense", variable: "interestExpense", total: false, simplifier: 1000000, symbol: "" },
  { id: 12, name: "Interest And Investment Income", variable: "interestIncome", total: false, simplifier: 1000000, symbol: "" },
  { id: 13, name: "Net Interest Income", variable: "netInterestIncome", total: false, simplifier: 1000000, symbol: "" },
  { id: 14, name: "Income Before Tax", variable: "incomeBeforeTax", total: false, simplifier: 1000000, symbol: "" },
  { id: 15, name: "Tax Expense", variable: "incomeTaxExpense", total: false, simplifier: 1000000, symbol: "" },
  { id: 16, name: "Tax Provision", variable: "taxProvision", total: false, simplifier: 1000000, symbol: "" },
  { id: 17, name: "Earning From Continuing Operations", variable: "netIncomeFromContinuingOps", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialIncomeStatement4 = [
  { id: 18, name: "Net Income", variable: "netIncome", total: false, simplifier: 1000000, symbol: "" },
  { id: 19, name: "Net Income to Common Sharesholders", variable: "netIncomeApplicableToCommonShares", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialIncomeStatement5 = [
  { id: 20, name: "Average Shares", variable: "commonStockSharesOutstanding", total: false, simplifier: 1000000, symbol: "" },
  { id: 21, name: "Depreciation", variable: "depreciationAndAmortization", total: false, simplifier: 1000000, symbol: "" },
  { id: 22, name: "Reconciled Depreciation", variable: "reconciledDepreciation", total: false, simplifier: 1000000, symbol: "" },
  { id: 23, name: "Discountinued Op", variable: "discontinuedOperations", total: false, simplifier: 1000000, symbol: "" },
  { id: 24, name: "EBIT", variable: "ebit", total: false, simplifier: 1000000, symbol: "" },
  { id: 25, name: "EBITDA", variable: "ebitda", total: false, simplifier: 1000000, symbol: "" },
  { id: 26, name: "Extraordinary Items", variable: "extraordinaryItems", total: false, simplifier: 1000000, symbol: "" },
  { id: 27, name: "Minority Interest", variable: "minorityInterest", total: false, simplifier: 1000000, symbol: "" },
  { id: 28, name: "Non Recurring", variable: "nonRecurring", total: false, simplifier: 1000000, symbol: "" },
  { id: 29, name: "Other Items", variable: "otherItems", total: false, simplifier: 1000000, symbol: "" },
  { id: 30, name: "Effect of Accounting Charges", variable: "effectOfAccountingCharges", total: false, simplifier: 1000000, symbol: "" },
];

export const dataFinancialBalanceSheet1 = [
  { id: 0, name: "Cash And Cash Equivalents", variable: "cash", total: false, simplifier: 1000000, symbol: "" },
  { id: 1, name: "Total Cash and ST Investments", variable: "cashAndShortTermInvestments", total: true, simplifier: 1000000, symbol: "" },
  { id: 2, name: "Other Short Term Investments", variable: "shortTermInvestments", total: false, simplifier: 1000000, symbol: "" },
  { id: 3, name: "Cash and Equivalents", variable: "cashAndEquivalents", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialBalanceSheet2 = [
  { id: 4, name: "Inventory", variable: "inventory", total: false, simplifier: 1000000, symbol: "" },
  { id: 5, name: "Total Receivables", variable: "netReceivables", total: false, simplifier: 1000000, symbol: "" },
  { id: 6, name: "Cash and Equivalents", variable: "cashAndEquivalents", total: false, simplifier: 1000000, symbol: "" },
  { id: 7, name: "Other Current Assets", variable: "otherCurrentAssets", total: false, simplifier: 1000000, symbol: "" },
  { id: 8, name: "Total Current Assets", variable: "totalCurrentAssets", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialBalanceSheet3 = [
  { id: 9, name: "Property, Plant & Equipment", variable: "propertyPlantEquipment", total: false, simplifier: 1000000, symbol: "" },
  { id: 10, name: "Gross Property, Plant & Equipment", variable: "propertyPlantAndEquipmentGross", total: false, simplifier: 1000000, symbol: "" },
  { id: 11, name: "Accumulated Depreciation", variable: "accumulatedDepreciation", total: false, simplifier: 1000000, symbol: "" },
  { id: 12, name: "Accumulated Amortization", variable: "accumulatedAmortization", total: false, simplifier: 1000000, symbol: "" },
  { id: 13, name: "Net Property, Plant & Equipment", variable: "propertyPlantAndEquipmentNet", total: true, simplifier: 1000000, symbol: "" },
  { id: 14, name: "Intangible Assets", variable: "intangibleAssets", total: false, simplifier: 1000000, symbol: "" },
  { id: 15, name: "Deferred Long-Term Assets", variable: "deferredLongTermAssetCharges", total: false, simplifier: 1000000, symbol: "" },
  { id: 16, name: "Long-Term Investments", variable: "longTermInvestments", total: false, simplifier: 1000000, symbol: "" },
  { id: 17, name: "Other Non-Current Assets", variable: "nonCurrrentAssetsOther", total: false, simplifier: 1000000, symbol: "" },
  { id: 18, name: "Total Non-Current Assets", variable: "nonCurrentAssetsTotal", total: true, simplifier: 1000000, symbol: "" },
  { id: 19, name: "Other Assets", variable: "otherAssets", total: false, simplifier: 1000000, symbol: "" },
  { id: 20, name: "Total Assets", variable: "totalAssets", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialBalanceSheet4 = [
  { id: 21, name: "Account Payable", variable: "accountsPayable", total: false, simplifier: 1000000, symbol: "" },
  { id: 22, name: "Capital Leases", variable: "capitalLeaseObligations", total: false, simplifier: 1000000, symbol: "" },
  { id: 23, name: "Short Term Debt", variable: "shortTermDebt", total: false, simplifier: 1000000, symbol: "" },
  { id: 24, name: "Current Deferred Revenue", variable: "currentDeferredRevenue", total: false, simplifier: 1000000, symbol: "" },
  { id: 25, name: "Other Current Liabilities", variable: "otherCurrentLiab", total: false, simplifier: 1000000, symbol: "" },
  { id: 26, name: "Total Current Liabilities", variable: "totalCurrentLiabilities", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialBalanceSheet5 = [
  { id: 27, name: "Long-Term Debt", variable: "longTermDebt", total: false, simplifier: 1000000, symbol: "" },
  { id: 28, name: "Total Long-Term Debt", variable: "longTermDebtTotal", total: true, simplifier: 1000000, symbol: "" },
  { id: 29, name: "Non-Current Deferred Liabilities", variable: "deferredLongTermLiab", total: false, simplifier: 1000000, symbol: "" },
  { id: 30, name: "Other Non-Current Liabilities", variable: "nonCurrentLiabilitiesOther", total: false, simplifier: 1000000, symbol: "" },
  { id: 31, name: "Total Non-Current Liabilities", variable: "nonCurrentLiabilitiesTotal", total: true, simplifier: 1000000, symbol: "" },
  { id: 32, name: "Other Liabilities", variable: "otherLiab", total: false, simplifier: 1000000, symbol: "" },
  { id: 33, name: "Total Liabilities", variable: "totalLiab", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialBalanceSheet6 = [
  { id: 34, name: "Common Stock", variable: "commonStock", total: false, simplifier: 1000000, symbol: "" },
  { id: 35, name: "Capital Stock", variable: "capitalStock", total: true, simplifier: 1000000, symbol: "" },
  { id: 36, name: "Retained Earning", variable: "retainedEarnings", total: false, simplifier: 1000000, symbol: "" },
  { id: 37, name: "Retained Earning Total Equity", variable: "retainedEarningsTotalEquity", total: true, simplifier: 1000000, symbol: "" },
  { id: 38, name: "Other Stockholder Equities", variable: "otherStockholderEquity", total: false, simplifier: 1000000, symbol: "" },
  { id: 39, name: "Total Equity for Preferred Stock", variable: "preferredStockTotalEquity", total: false, simplifier: 1000000, symbol: "" },
  { id: 40, name: "Total Equity for Common Stock", variable: "commonStockTotalEquity", total: false, simplifier: 1000000, symbol: "" },
  { id: 41, name: "Total Equity", variable: "totalStockholderEquity", total: true, simplifier: 1000000, symbol: "" },
  { id: 42, name: "Total Common Equity", variable: "totalPermanentEquity", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialBalanceSheet7 = [
  { id: 43, name: "Capital Surpluse", variable: "capitalSurpluse", total: false, simplifier: 1000000, symbol: "" },
  { id: 44, name: "Additional Paid in Capital", variable: "additionalPaidInCapital", total: false, simplifier: 1000000, symbol: "" },
  { id: 45, name: "Comprehensive Income and Other", variable: "accumulatedOtherComprehensiveIncome", total: false, simplifier: 1000000, symbol: "" },
  { id: 46, name: "Earning Assets", variable: "earningAssets", total: false, simplifier: 1000000, symbol: "" },
  { id: 47, name: "Goodwill", variable: "goodWill", total: false, simplifier: 1000000, symbol: "" },
  { id: 48, name: "Negative Goodwill", variable: "negativeGoodwill", total: false, simplifier: 1000000, symbol: "" },
  { id: 49, name: "Net Debt", variable: "netDebt", total: false, simplifier: 1000000, symbol: "" },
  { id: 50, name: "Invested Capital", variable: "netInvestedCapital", total: false, simplifier: 1000000, symbol: "" },
  { id: 51, name: "Net Tangible Assets", variable: "netTangibleAssets", total: false, simplifier: 1000000, symbol: "" },
  { id: 52, name: "Working Capital", variable: "netWorkingCapital", total: false, simplifier: 1000000, symbol: "" },
  { id: 53, name: "Total Long & Short Term Debt", variable: "shortLongTermDebtTotal", total: false, simplifier: 1000000, symbol: "" },
  { id: 54, name: "Treasury Stock", variable: "treasuryStock", total: false, simplifier: 1000000, symbol: "" },
  { id: 55, name: "Warrents", variable: "warrants", total: false, simplifier: 1000000, symbol: "" },
  { id: 56, name: "Shares Number", variable: "commonStockSharesOutstanding", total: false, simplifier: 1000000, symbol: "" },
];

export const dataFinancialCashFlow1 = [
  { id: 0, name: "Depreciation & Amortization", variable: "depreciation", total: false, simplifier: 1000000, symbol: "" },
  { id: 1, name: "Change In Accounts Receivable", variable: "changeToAccountReceivables", total: false, simplifier: 1000000, symbol: "" },
  { id: 2, name: "Change in Receivables", variable: "changeReceivables", total: true, simplifier: 1000000, symbol: "" },
  { id: 3, name: "Change In Inventor", variable: "changeToInventory", total: false, simplifier: 1000000, symbol: "" },
  { id: 4, name: "Change in Liabilities", variable: "changeToLiabilities", total: false, simplifier: 1000000, symbol: "" },
  { id: 5, name: "Change in Working Capital", variable: "changeInWorkingCapital", total: false, simplifier: 1000000, symbol: "" },
  { id: 6, name: "Other Non Cash Items", variable: "otherNonCashItems", total: false, simplifier: 1000000, symbol: "" },
  { id: 7, name: "Change In Operating Activities", variable: "changeToOperatingActivities", total: false, simplifier: 1000000, symbol: "" },
  { id: 8, name: "Net Other Operating Charges", variable: "cashFlowsOtherOperating", total: false, simplifier: 1000000, symbol: "" },
  { id: 9, name: "Total Cash From Operating Activities", variable: "totalCashFromOperatingActivities", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialCashFlow2 = [
  { id: 12, name: "Investments", variable: "investments", total: false, simplifier: 1000000, symbol: "" },
  { id: 13, name: "Change in Net Income", variable: "changeToNetincome", total: false, simplifier: 1000000, symbol: "" },
  { id: 14, name: "Capital Excpenditures", variable: "capitalExpenditures", total: false, simplifier: 1000000, symbol: "" },
  { id: 15, name: "Net Other Investing Changes", variable: "otherCashflowsFromInvestingActivities", total: false, simplifier: 1000000, symbol: "" },
  { id: 16, name: "Total Cash Flow From Investing", variable: "totalCashflowsFromInvestingActivities", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialCashFlow3 = [
  { id: 19, name: "Net Issuance Payments of Debt", variable: "netBorrowings", total: false, simplifier: 1000000, symbol: "" },
  { id: 20, name: "Dividends Paid", variable: "dividendsPaid", total: false, simplifier: 1000000, symbol: "" },
  { id: 21, name: "Purchase Of Stock", variable: "salePurchaseOfStock", total: false, simplifier: 1000000, symbol: "" },
  { id: 22, name: "Net Other Financing Charges", variable: "otherCashflowsFromFinancingActivities", total: false, simplifier: 1000000, symbol: "" },
  { id: 23, name: "Total Cash Flow From Financing", variable: "totalCashFromFinancingActivities", total: true, simplifier: 1000000, symbol: "" },
];
export const dataFinancialCashFlow4 = [{ id: 25, name: "Change In Cash & Equivalents", variable: "cashAndCashEquivalentsChanges", total: false, simplifier: 1000000, symbol: "" }];
export const dataFinancialCashFlow5 = [
  { id: 27, name: "Cash Flow in Begin Period", variable: "beginPeriodCashFlow", total: false, simplifier: 1000000, symbol: "" },
  { id: 29, name: "Changes in Cash", variable: "changeInCash ", total: true, simplifier: 1000000, symbol: "" },
  { id: 28, name: "Cash Flow in End Period", variable: "endPeriodCashFlow", total: false, simplifier: 1000000, symbol: "" },
];
export const dataFinancialCashFlow6 = [
  { id: 31, name: "Change in Exchange Rate", variable: "exchangeRateChanges", total: false, simplifier: 1000000, symbol: "" },
  { id: 32, name: "Free Cash Flow", variable: "freeCashFlow", total: false, simplifier: 1000000, symbol: "" },
];
