import React, { Component, Fragment, useState, useEffect, useContext } from "react";
import { getMyScreener } from "../../../firebase-marketadvisor/firebase-screener";
import { firebase_app } from "../../../data/config";
import { faFlagUsa, faChartLine, faMicrochip, faEuroSign, faXmark, faDumbbell, faDownLong, faPeopleGroup, faCoins, faLock, faWandMagic, faF, faShieldHalved, faMoneyBill, faStar, faE } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "react-device-detect";
import { requestAccessToProduct } from "../../../firebase-marketadvisor/firebase-data-user";
import CardHeader from "../../common/genericComponent/CardHeader";
import { UserContext } from "../../../context/UserContext";

const GuruScreenerPage = () => {
  const user = useContext(UserContext);
  const [myScreener, setMyScreener] = useState([]);
  const [access, setAccess] = useState();

  useEffect(() => {
    requestAccessToProduct({ user, product: "guruScreener" }).then((responseAccess) => {
      setAccess(responseAccess);
      if (responseAccess) {
        getMyScreener({ user }).then((resScreener) => {
          setMyScreener(resScreener);
        });
      }
    });
  }, []);

  const guruScreener = [
    { title: "Magic Formula", icon: faWandMagic, author: "Joel Greenblatt", description: "The Magic Forumla has been established by Joel Greenblatt in this book. ", url: "magicformula", available: true },
    { title: "F-Score", icon: faF, author: "Piotroski", description: "The Magic Forumla has been established by Joel Greenblatt in this book. ", url: "piotroski", available: true },
    { title: "Defensive Stock", icon: faShieldHalved, author: "Benjamin Graham", description: "The Defensive approach from The Intelligent Investor ", url: "grahamdefensive", img: "valueinvesting.png" },
    { title: "Enterprising Stock ", icon: faE, author: "Benjamin Graham", description: "The Enterprising approach from The Intelligent Investor", url: "grahamenterprising", img: "valueinvesting.png" },
    { title: "NCAV", icon: faMoneyBill, author: "Benjamin Graham", description: "The NCAV approach from The Intelligent Investor ", url: "ncav", img: "valueinvesting.png" },
    { title: "Market Advisor", icon: faStar, author: "MarketAdvisor", description: "The Magic Forumla has been established by Joel Greenblatt in this book. ", url: "marketadvisor", available: true },
  ];

  const popularScreener = [
    { title: "Top USA", icon: faFlagUsa, author: "ma", description: "Find out which stocks are leading in the US market. ", url: "topusa", available: true },
    { title: "Top Dividend", icon: faChartLine, author: "ma", description: "Explore the highest-ranking stocks that offer reliable and consistent dividends. ", url: "topdividend", available: true },
    { title: "Top Tech", icon: faMicrochip, author: "ma", description: "Find out which stocks are leading in the Technology Sector. ", url: "toptech", available: true },
    { title: "Top Europe", icon: faEuroSign, author: "ma", description: "Find out which stocks are leading in the European market. ", url: "topeurope", available: true },

    {
      title: "Penny Stock (USA)",
      icon: faCoins,
      author: "ma",
      description: "Companies in which the major investors are also its leaders",
      url: "penny",
      available: true,
    },
    {
      title: "Founder Led Companies",
      icon: faPeopleGroup,
      author: "ma",
      description: "Companies in which the major investors are also its leaders",
      url: "founder",
      available: false,
    },

    { title: "x100 Bagger", icon: faXmark, author: "ma", description: "Explore stocks with a potential for x100 return, characterized by high gains but also high risks.  ", url: "bagger", available: false },
    {
      title: "Near Bankruptcy",
      icon: faDownLong,
      author: "ma",
      description: "Potentially promising investment opportunity to be found in companies on the brink of bankruptcy.",
      url: "bankrupt",
      available: false,
    },
    {
      title: "Strong Earning but bad year",
      icon: faDumbbell,
      author: "ma",
      description: "Loss happens and it could be a great opportunity to analyse company with a strong and consistent earning with one bad year.",
      url: "badyear",
      available: false,
    },
  ];

  return (
    <Fragment>
      {/* Presentation du screener  */}
      <div className="container p-t-20">
        {/* GURU SCREENER  */}

        <CardHeader title="Screener Library" description={""} />
        <div className="card">
          <div className="card-body">
            <h2>Guru Screener</h2>
            <p>
              Guru Screener comprises a collection of screeners that draw inspiration from numerous renowned books on stock-picking strategies. The objective is to present a continually updated roster of stocks aligned with the
              strategies delineated in these books, using the latest values for each stock.
            </p>
          </div>
        </div>
        {/* <div className="card">
          <div className="card-body">
            <ul>
              <li className="m-b-5">
                <strong>Check our ressources :</strong>
              </li>
              <li className="m-b-5">
                <a href="https://marketadvisor.io/blog/7-essential-rules-to-master-grahams-defensive-investment-the-complete-guide/" target="_blank">
                  Joel Greenblatt - Understand the Magical Formula
                </a>
              </li>
              <li className="m-b-5">
                <a href="https://marketadvisor.io/blog/7-essential-rules-to-master-grahams-defensive-investment-the-complete-guide/" target="_blank">
                  Benjamin Graham - Understand the Defensive Stock Investment
                </a>
              </li>
              <li className="m-b-5">
                <a href="https://marketadvisor.io/blog/7-essential-rules-to-master-grahams-defensive-investment-the-complete-guide/" target="_blank">
                  Benjamin Graham - Understand the Enterprising Stock Investment
                </a>
              </li>
            </ul>
          </div>
        </div> */}

        <div className="row">
          {guruScreener.map((screener) => {
            // const logoPath = require(`../../../assets/book/${screener.img}`);
            return (
              <div key={screener.url} className={isMobile ? "col-12" : "col-3 d-flex"}>
                <div className="card card-body ">
                  <div className="text-center m-b-10">
                    <FontAwesomeIcon icon={screener.icon} style={{ height: "50px", marginBottom: "10px", color: "#c5e7fd", padding: "20px", border: "solid", borderRadius: "10px", marginBottom: "15px", width: "50px" }} />
                    {/* <img
                      src={logoPath}
                      alt="Logo Stock"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                      }}
                      style={{ maxWidth: "80%", height: "200px", marginBottom: "10px" }}
                    /> */}
                    <h5>{screener.title}</h5>
                    <p style={{ fontWeight: "500", fontSize: "0.8rem" }}>{`${screener.author}`}</p>
                  </div>

                  {access ? (
                    <a className="btn btn-block btnSecondary  mt-auto " href={`${process.env.PUBLIC_URL}/screener/${screener.url}`}>
                      Discover
                    </a>
                  ) : (
                    <a className="btn btn-block btnSecondary  mt-auto " href={`${process.env.PUBLIC_URL}/subscription`}>
                      <FontAwesomeIcon icon={faLock} />
                      {"  Upgrade"}
                    </a>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* POPULAR SCREENER */}
        <div className="card">
          <div className="card-body">
            <h2>Thematic Screener</h2>
            <p>Marketadvisor consistently provides the most up-to-date list of stocks, including popular screeners that are highly valued and beneficial for selecting stocks based on specific themes.</p>
          </div>
        </div>

        <div className="row">
          {popularScreener.map((screener) => {
            return (
              <div key={screener.url} className={isMobile ? "col-12" : "col-3 d-flex"}>
                <div className="card card-body">
                  <div className="text-center m-b-10">
                    <FontAwesomeIcon icon={screener.icon} style={{ height: "50px", marginBottom: "10px", color: "#c5e7fd", padding: "20px", border: "solid", borderRadius: "10px", marginBottom: "15px", width: "50px" }} />
                    <h5>{screener.title}</h5>
                    <p>{screener.description}</p>
                  </div>

                  {access ? (
                    <>
                      {screener.available ? (
                        <a className="btn btn-block btnSecondary  mt-auto " href={`${process.env.PUBLIC_URL}/screener/${screener.url}`}>
                          Discover
                        </a>
                      ) : (
                        <button className="btn btn-block btnSecondary  mt-auto disabled">Coming Soon</button>
                      )}
                    </>
                  ) : (
                    <a className="btn btn-block btnSecondary  mt-auto " href={`${process.env.PUBLIC_URL}/subscription`}>
                      <FontAwesomeIcon icon={faLock} />
                      {"  Upgrade"}
                    </a>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* My SCREENER */}
        <div className="card">
          <div className="card-body">
            <h2>{`My Screener (${myScreener.length})`}</h2>
            <p>Your personal collection of screener</p>
          </div>
        </div>

        <div className="row">
          {myScreener.map((screener) => {
            return (
              <div key={screener.url} className={isMobile ? "col-12" : "col-3 d-flex"}>
                <div className="card card-body">
                  <div className="text-center m-b-10">
                    <h5>{screener.name}</h5>
                    <p>{screener.description}</p>
                  </div>
                  <a className="btn btn-block btnSecondary mt-auto " href={`${process.env.PUBLIC_URL}/screener/${screener.id}`}>
                    Continue
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default GuruScreenerPage;
