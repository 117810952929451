import React, { useState, useContext, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { SymbolContext } from "../../utils/symbolContext";
import { useQueryClient } from "@tanstack/react-query";

const ChartPrice = ({ symbol }) => {
  const ApexCharts = window.ApexCharts;
  const [timeLine, setTimeLine] = useState("one_month");
  const [dataChart, setDataChart] = useState([]);
  const [listOfSupport, setListOfSupport] = useState([]);
  const [dateEarning, setDateEarning] = useState([]);
  const [dateDividends, setDateDividends] = useState([]);

  const { data, isLoading, queryHistorical } = useContext(SymbolContext);

  const queryClient = useQueryClient();
  const earningQuery = queryClient.getQueryData(["symbol", "earnings", symbol]);
  const dividendsQuery = queryClient.getQueryData(["symbol", "dividends", symbol]);

  useEffect(() => {
    if (queryHistorical.isPending === false) {
      const dataCharts = queryHistorical.data.data.map((e) => {
        return [new Date(e.date).getTime(), e.close];
      });
      setDataChart(dataCharts);
    }
  }, [queryHistorical]);

  useEffect(() => {
    console.log(data);
    let listOfSupport = [
      {
        y: data.fiftyTwoWeekLow,
        borderColor: "#999",
        label: {
          show: true,
          position: "center",
          text: "52W Low",
          style: {
            color: "#fff",
            background: "red",
          },
        },
      },
      {
        y: data.fiftyTwoWeekHigh,
        borderColor: "#999",
        label: {
          show: true,
          position: "center",
          text: "52W High",
          style: {
            color: "#fff",
            background: "#00E396",
          },
        },
      },

      {
        y: data.twoHundredDayAverage,
        borderColor: "#999",
        label: {
          show: true,
          position: "center",
          text: "200D MA",
          style: {
            color: "#fff",
            background: "grey",
          },
        },
      },
    ];
    setListOfSupport(listOfSupport);
  }, [data]);

  useEffect(() => {
    if (earningQuery) {
      let listEarning = [];
      Object.keys(earningQuery).forEach((key) => {
        listEarning.push({
          x: new Date(key).getTime(),
          borderColor: "#999",
          yAxisIndex: 0,
          label: {
            show: true,
            position: "bottom",
            text: "EPS " + earningQuery[key]["epsActual"],
            style: {
              color: "#fff",
              background: "#775DD0",
            },
          },
        });
      });

      setDateEarning(listEarning);
    }
  }, [earningQuery]);

  useEffect(() => {
    if (dividendsQuery) {
      console.log("test", dividendsQuery);
      let listDividends = [];

      listDividends.push({
        x: new Date(dividendsQuery["DividendDate"]).getTime(),
        borderColor: "#999",
        yAxisIndex: 0,
        label: {
          show: true,
          position: "bottom",
          text: "Dividend " + parseInt(10000 * dividendsQuery["ForwardAnnualDividendYield"]) / 100 + "%",
          style: {
            color: "#fff",
            background: "green",
          },
        },
      });
      listDividends.push({
        x: new Date(dividendsQuery["ExDividendDate"]).getTime(),
        borderColor: "#999",
        yAxisIndex: 0,
        label: {
          show: true,
          position: "bottom",
          text: "Ex-Dividend",
          style: {
            color: "#fff",
            background: "green",
          },
        },
      });
      listDividends.push({
        x: new Date(dividendsQuery["LastSplitDate"]).getTime(),
        borderColor: "#999",
        yAxisIndex: 0,
        label: {
          show: true,
          position: "bottom",
          text: "Split" + dividendsQuery["LastSplitFactor"],
          style: {
            color: "#fff",
            background: "green",
          },
        },
      });

      // Object.keys(earningQuery).forEach((key) => {

      // });

      setDateDividends(listDividends);
    }
  }, [dividendsQuery]);

  const state = {
    series: [
      {
        name: "Close Price",
        data: dataChart,
      },
    ],
    options: {
      stroke: {
        curve: "straight",
        width: 2,
      },
      chart: {
        id: "area-datetime",
        type: "area",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
      },
      annotations: {
        yaxis: listOfSupport,
        xaxis: [...dateEarning, ...dateDividends],
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      yaxis: {
        // min: 0,
        tickAmount: 4,
        labels: {
          show: true,
          formatter: (value) => {
            return value.toFixed(2);
          },
        },
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
  };
  const updateData = (timeline) => {
    setTimeLine(timeLine);

    var today = new Date();
    var previousDay = new Date();
    switch (timeline) {
      case "one_month":
        previousDay.setDate(previousDay.getDate() - 30);
        ApexCharts.exec("area-datetime", "zoomX", previousDay.getTime(), today.getTime());
        break;
      case "six_months":
        previousDay.setDate(previousDay.getDate() - 6 * 30);
        ApexCharts.exec("area-datetime", "zoomX", previousDay.getTime(), today.getTime());
        break;
      case "one_year":
        previousDay.setDate(previousDay.getDate() - 365);
        ApexCharts.exec("area-datetime", "zoomX", previousDay.getTime(), today.getTime());
        break;
      case "ytd":
        ApexCharts.exec("area-datetime", "zoomX", new Date(new Date().getFullYear(), 0, 1).getTime(), today.getTime());
        break;
      case "all":
        ApexCharts.exec("area-datetime", "zoomX", dataChart[0][0], new Date().getTime());
        break;
      default:
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <div id="chart">
          <div className=" text-center">
            <button id="one_month" onClick={() => updateData("one_month")} className={"btn btn-light"}>
              1M
            </button>
            &nbsp;
            <button id="six_months" onClick={() => updateData("six_months")} className={"btn btn-light"}>
              6M
            </button>
            &nbsp;
            <button id="one_year" onClick={() => updateData("one_year")} className={"btn btn-light"}>
              1Y
            </button>
            &nbsp;
            <button id="ytd" onClick={() => updateData("ytd")} className={"btn btn-light"}>
              YTD
            </button>
            &nbsp;
            <button id="all" onClick={() => updateData("all")} className={"btn btn-light"}>
              ALL
            </button>
          </div>

          <div id="chart-timeline">
            <ReactApexChart options={state.options} series={state.series} type="area" height={350} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartPrice;
