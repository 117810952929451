import React, { Component } from "react";

import { firebase_app } from "../../../../data/config";
import { getBigMover } from "../../../../firebase-marketadvisor/firebase-financial-data";
import { isMobile } from "react-device-detect";
import Loader from "../../tools/loader";
import { fetchLogoUrl } from "../../../../tools/fetchLogoCompany";
import { Link } from "react-router-dom";

class BigMover extends Component {
  state = { loading: true, bigGainer: [], bigLooser: [], price: {}, symbols: [] };

  componentDidMount = () => {
    // const getBigMover = firebase_app.functions().httpsCallable("getPriceMultipleSymbol");

    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        getBigMover().then((res) => {
          this.setState({
            loading: false,
            bigGainer: res.bigGainer,
            bigLooser: res.bigLooser,
          });
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <h5>{`End of The Day - Big Mover`}</h5>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="card ">
                <div className="container ">
                  <div className="card-body ">
                    <div className={"table-responsive col-12 m-t-20"}>
                      {/* <h5>{`Big Gainer`}</h5> */}
                      <span className="h6">Higher gain in the last 24 hours</span>
                      <table className="table m-t-20">
                        <thead></thead>
                        <tbody>
                          {this.state.bigGainer.map((symbol, index) => {
                            if (index <= 5) {
                              var logoPath = fetchLogoUrl(symbol.symbol);
                              return (
                                <tr key={symbol.symbol}>
                                  <th className="col-7">
                                    <div className="row">
                                      <div className="col-4" style={{ display: "contents" }}>
                                        <Link to={"/symbol/" + symbol.symbol}>
                                          <img
                                            src={logoPath}
                                            alt="new"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null; // prevents looping
                                              currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                                            }}
                                            style={{ maxWidth: "40px", maxHeight: "35px" }}
                                          />
                                        </Link>
                                      </div>

                                      <div className={isMobile ? "col-8 p-0" : "col-8"} style={isMobile ? { textAlign: "center" } : {}}>
                                        <Link to={"/symbol/" + symbol.symbol} className="maincolortext">
                                          <div>{`${symbol.symbol}`}</div>
                                          <div style={{ fontWeight: "lighter", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${symbol.name}`}</div>
                                        </Link>
                                      </div>
                                    </div>
                                  </th>

                                  <td className={symbol.variation > 0 ? "font-success" : "font-danger"}>
                                    <div className="row justify-content-center" style={{ fontWeight: "bold" }}>
                                      {symbol.price + symbol.currency}
                                    </div>
                                    <div className="row justify-content-center">{Math.round(10000 * symbol.variation) / 100 + "%"}</div>
                                  </td>
                                </tr>
                              );
                            }
                            return "";
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6">
              <div className="card ">
                <div className="container ">
                  <div className="card-body ">
                    <div className={"table-responsive col-12 m-t-20"}>
                      {/* <h5>{`Big Looser`}</h5> */}
                      <span className="h6">Higher loss in the last 24 hours</span>
                      <table className="table m-t-20">
                        <thead></thead>
                        <tbody>
                          {this.state.bigLooser.map((symbol, index) => {
                            if (index <= 5) {
                              var logoPath = fetchLogoUrl(symbol.symbol);
                              return (
                                <tr key={symbol.symbol}>
                                  <th className="col-7">
                                    <div className="row">
                                      <div className="col-4" style={{ display: "contents" }}>
                                        <Link to={"/symbol/" + symbol.symbol}>
                                          <img
                                            src={logoPath}
                                            alt="new"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null; // prevents looping
                                              currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                                            }}
                                            style={{ maxWidth: "40px", maxHeight: "35px" }}
                                          />
                                        </Link>
                                      </div>
                                      <div className={isMobile ? "col-8 p-0" : "col-8"} style={isMobile ? { textAlign: "center" } : {}}>
                                        <Link to={"/symbol/" + symbol.symbol} style={{ color: "#2f4050" }}>
                                          <div>{`${symbol.symbol}`}</div>
                                          <div style={{ fontWeight: "lighter", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${symbol.name}`}</div>
                                        </Link>
                                      </div>
                                    </div>
                                  </th>

                                  <td className={symbol.variation > 0 ? "font-success" : "font-danger"}>
                                    <div className="row justify-content-center" style={{ fontWeight: "bold" }}>
                                      {symbol.price + symbol.currency}
                                    </div>
                                    <div className="row justify-content-center">{Math.round(10000 * symbol.variation) / 100 + "%"}</div>
                                  </td>
                                </tr>
                              );
                            }
                            return "";
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default BigMover;
