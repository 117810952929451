import React, { Component } from "react";

class LinearGauge extends Component {
  state = {};
  render() {
    let positionCursor = this.props.position;
    if (positionCursor === "%" || parseInt(positionCursor.split("%")[0]) < 10) positionCursor = "10%";

    return (
      //   <div classNameName="gauge">
      //     <div classNameName="price-range">

      //       <div classNameName="gauge-bar">
      //         <span classNameName="arrow-gauge "></span>
      //       </div>
      //     </div>
      //   </div>

      <div className="tableRange">
        <et-fifty-two-weeks className="instrument-cell ng-star-inserted" _nghost-kqm-c644="">
          <div automation-id="fifty-two-weeks-range" className="fiftytwo-weeks-progress-bar">
            <div className="price-range">
              {/* <div automation-id="fifty-two-weeks-range-min" className="low">
                1
              </div>
              <div automation-id="fifty-two-weeks-range-max" className="high">
                0
              </div> */}
            </div>
            <div automation-id="fifty-two-weeks-bar" className="bar-wrap">
              <span automation-id="fifty-two-weeks-indicator" className="measure-arrow" style={{ left: positionCursor }}></span>
            </div>
          </div>
        </et-fifty-two-weeks>
      </div>
    );
  }
}

export default LinearGauge;
