import React, { Fragment, useState, useEffect, useContext } from "react";
import PageAnalysis from "./analysis/pageAnalysisV2";
import Loader from "../tools/loader";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { SymbolContext } from "./utils/symbolContext";
import { UserContext } from "../../../context/UserContext";
import MenuInfoPage from "./menuInfo/MenuInfoPage";
import ModalSubscribeNow from "../modal/modalSubscribeNow";
import MenuSymbol from "./components/menuSymbol/MenuSymbol";
import HeaderPageSymbol from "./components/headerPageSymbol/HeaderPageSymbol";
import NewsComponent from "./pages/componentNews/NewsComponent";
import PageFinancialData from "./pages/componentDataFinancial/PageFinancialData";
import { useHistory } from "react-router-dom";
import ModalTickerNotFound from "../modal/modalTickerNotFound";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { getHistorical, getInformationTicker, getFinancial } from "../../../api/getSymbolElement";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getHistoricalDividend, getHistoricalEarning } from "../../../firebase-marketadvisor/firebase-CRUD-earning";

// import SankeyDiagramm from "./sankeyDiagramm/SankeyDiagram";

const MainPageSymbol = () => {
  const { symbol } = useParams();
  // const queryClient = useQueryClient();
  const location = useLocation();
  // const user = queryClient.getQueryData(["user"]);
  const user = useContext(UserContext);
  const [authorisation, setAuthorisation] = useState(true);
  const [activeMenu, setActiveMenu] = useState("home");
  const [notExisting, setNotExisting] = useState(false);
  const [watchlist, setWatchlist] = useState(false);

  let history = useHistory();
  // Initialisation quand l'utilisateur est chargé

  const queryInformationTicker = useQuery({ queryKey: ["symbol", "informationTicker", symbol], queryFn: () => getInformationTicker({ symbol }) });
  const queryHistorical = useQuery({ queryKey: ["symbol", "historicalPrice", symbol], queryFn: () => getHistorical({ symbol }) });
  const queryFinancial = useQuery({ enabled: !!queryInformationTicker?.data, queryKey: ["symbol", "financial", symbol], queryFn: () => getFinancial({ symbol, data: queryInformationTicker?.data }) });
  const queryDividends = useQuery({ queryKey: ["symbol", "dividends", symbol], queryFn: () => getHistoricalDividend(symbol) });
  const queryEarnings = useQuery({ queryKey: ["symbol", "earnings", symbol], queryFn: () => getHistoricalEarning(symbol) });

  useEffect(() => {
    if (user) {
      setAuthorisation(true);
    }
  }, [user, symbol]);

  useEffect(() => {
    const menuQuery = new URLSearchParams(location.search).get("menu");

    if (menuQuery) {
      if (menuQuery !== activeMenu) {
        setActiveMenu(menuQuery);
      }
    } else {
      setActiveMenu("home");
    }
  }, [symbol, location.search]);

  useEffect(() => {
    window.history.pushState(null, "", `?menu=${activeMenu}`);
  }, [activeMenu]);
  // Modification structure "symbol"

  const handleChangeMenu = (activeMenu) => {
    if (user === null) {
      history.push("/pages/signup");
    } else {
      setActiveMenu(activeMenu);
    }
  };

  // Si chargement
  if (queryInformationTicker.isPending) {
    return <Loader fullScreen={true} />;
  }
  if (queryInformationTicker.isPending === false && queryInformationTicker.isError) {
    return <ModalTickerNotFound show={true} />;
  }

  const { name, variationPercent, price, variationPrice, currencySymbol, industry, sector, marketCap, fiftyTwoWeekLow, fiftyTwoWeekHigh, PE, twoHundredDayAverage } = queryInformationTicker.data;

  if (authorisation === false) {
    return <ModalSubscribeNow show={true} />;
  } else {
    return (
      <SymbolContext.Provider value={{ symbol, error: notExisting, isLoading: queryInformationTicker.isPending, data: queryInformationTicker.data, queryHistorical }}>
        <Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`${symbol} Stock Price | ${name} Analysis & Financial Data`}</title>

            <meta name="description" content={`${symbol} | Complete ${name} stock analysis and up to +30 years of financial data for free`} />
            <meta name="og:description" content={`View the latest ${name} (${symbol}) stock price, news, historical charts, analyst ratings and financial information from marketadvisor.io`} />
            <meta
              name="keywords"
              content={`market share investment advice stock ${name} ${symbol} ${industry} ${sector} buy timing sell rating wallstreet seeking alpha marketwatch etoro best momentum eps PE gross price net ratio quick dollar DCA money tdameritrade brooker data income statement balance sheet cash flow  `}
            />
            <link rel="canonical" href={`https://platform.marketadvisor.io/stock-price/earning/${symbol}`} />

            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Corporation",
                "@id": `https://platform.marketadvisor.io/stock-price/earning/${symbol}`,
                name: `${symbol} Stock Price | ${name} Analysis & Financial Data | MarketAdvisor`,
                description: `${symbol} | Complete ${name} stock analysis and up to +30 years of financial data for free`,
                alternateName: `${name}`,
                mainEntityOfPage: { "@type": "WebPage", url: `https://platform.marketadvisor.io/stock-price/earning/${symbol}`, isAccessibleForFree: true },
                tickerSymbol: `${symbol}`,
              })}
            </script>
          </Helmet>

          <HeaderPageSymbol data={{ symbol, name, industry, sector, variationPercent, price, currencySymbol, variationPrice, watchlist: watchlist }} />
          <MenuSymbol onChangeMenu={handleChangeMenu} />
          <div className="container">
            <div className="">
              {activeMenu === "home" && (
                <div>
                  <PageAnalysis short={true} data={{ symbol, sector, industry, marketCap, PE, name, price, twoHundredDayAverage, fiftyTwoWeekLow, fiftyTwoWeekHigh }} financial={{ queryFinancial }} />
                  <MenuInfoPage />
                </div>
              )}
              {activeMenu === "news" && <NewsComponent symbol={symbol} name={name} />}
              {activeMenu === "analysis" && <PageAnalysis short={false} data={{ symbol, sector, industry, marketCap, PE, name, price, twoHundredDayAverage, fiftyTwoWeekLow, fiftyTwoWeekHigh }} financial={{ queryFinancial }} />}
              {activeMenu === "financials" && <PageFinancialData data={{ symbol }} financial={{ queryFinancial }} />}
            </div>
          </div>
        </Fragment>
      </SymbolContext.Provider>
    );
  }
};

export default MainPageSymbol;
