import React, { Fragment, useState, useEffect } from "react";
import { getDividends } from "../../../firebase-marketadvisor/firebase-data-dividends";
import DataTable from "react-data-table-component";
import { DayPicker } from "react-day-picker";
import { format } from "date-fns";
import "react-day-picker/dist/style.css";
import Loader from "../tools/loader";
import CardHeader from "../../common/genericComponent/CardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function DividendPage() {
  // const [selected, setSelected] = useState([new Date()]);
  const [selected, setSelected] = useState({ from: new Date(), to: new Date() });
  const [dateToSelect, setDateToSelect] = useState(false);
  const [dividends, setDividends] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [location, setLocation] = useState("US");

  const handleResetClick = () => setSelected({});
  const changeDate = (date) => {
    if (date !== undefined) {
      setSelected(date);
    } else {
      setDateToSelect(true);
    }
  };

  // Error if same date selected
  useEffect(() => {
    var dates = [];
    var breakloop = 0;
    const datesSelected = { ...selected };

    if (Object.keys(datesSelected).length !== 0) {
      if (datesSelected.from !== undefined && datesSelected.to !== undefined) {
        setLoaded(false);
        const fromDate = format(datesSelected.from, "yyyy-MM-dd");
        const toDate = format(datesSelected.to, "yyyy-MM-dd");

        var loopDate = new Date(datesSelected.from);
        dates.push(fromDate);

        while (format(loopDate, "yyyy-MM-dd") !== toDate && breakloop < 100) {
          breakloop = breakloop + 1;
          loopDate = new Date(loopDate.setDate(loopDate.getDate() + 1));
          dates.push(format(loopDate, "yyyy-MM-dd"));
        }

        setDateToSelect(false);
      } else {
        setDateToSelect(true);
      }
    } else {
      setDateToSelect(true);
    }

    // const dateToExtract = format(datesSelected, "yyyy-MM-dd");
    getDividends({ dates, location }).then((r) => {
      const dividendsFilter = r.filter((e) => {
        if (e.paymentDate === null) return false;
        if (e.paymentDate === null) return false;

        return true;
      });
      setDividends(dividendsFilter);
      setLoaded(true);
    });
  }, [selected, location]);

  const sortYield = (rowA, rowB) => {
    const a = parseFloat(rowA.yield.replace("%", "").replace("-", "0"));
    const b = parseFloat(rowB.yield.replace("%", "").replace("-", "0"));

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "Symbol",
      selector: (row) => {
        return (
          <Link to={`${process.env.PUBLIC_URL}/symbol/${row.code}`}>
            <FontAwesomeIcon icon={faExternalLink} />
            <strong className="m-l-5">{row.code}</strong>
          </Link>
        );
      },
      sortable: true,
    },
    {
      name: "Annual Yield",
      selector: (row) => {
        if (row.yield !== "N/A") return `${row.yield}%`;
        return "-";
      },
      sortable: true,
      direction: "asc",
      sortFunction: sortYield,
    },
    {
      name: "Dividend",
      selector: (row) => {
        return `${row.dividend} ${row.currency}`;
      },
    },

    {
      name: "Annual Dividend",
      selector: (row) => {
        if (row.annual !== "N/A" && row.annual !== undefined) return `${row.annual} ${row.currency}`;
        return "-";
      },
    },
    {
      name: "Ex-Date",
      selector: (row) => {
        return `${row.date}`;
      },
    },

    {
      name: "Payment Date",
      selector: (row) => {
        return `${row.paymentDate}`;
      },
    },

    {
      name: "Period",
      selector: (row) => {
        return `${row.period}`;
      },
    },
  ];

  return (
    <Fragment>
      <div className="container p-t-20">
        <CardHeader title="Dividends - US & Europe" />
      </div>
      <div className="container ">
        <div className="row flex">
          <div className=" col-auto">
            <div className="card">
              <div className="card-body">
                <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>PICK A DAY</h2>
                <DayPicker
                  mode="range"
                  min={0}
                  max={6}
                  selected={selected}
                  onSelect={changeDate}
                  styles={{
                    "rdp-day_selected": { backgroundColor: "red" },
                  }}
                />
                <button className="btn btnSecondary" onClick={handleResetClick}>
                  Reset
                </button>

                <h2 className="m-t-30" style={{ color: "#1098F7", fontSize: "1rem" }}>
                  PICK A LOCATION
                </h2>
                <div className="row ">
                  <div className=" col-6 text-center m-t-10">
                    <button className="btn btn-block btnSecondary" style={location === "US" ? {} : { opacity: "0.6" }} onClick={() => setLocation("US")}>
                      USA
                    </button>
                  </div>
                  <div className=" col-6 text-center m-t-10">
                    <button className="btn btn-block btnSecondary" style={location === "EU" ? {} : { opacity: "0.6" }} onClick={() => setLocation("EU")}>
                      Europe
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-auto">
            <div className="card">
              <div className="card-body">
                <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>DEFINITION</h2>
                <p>
                  <strong>Ex-Date:</strong> The stock must have been bought <strong>before</strong> the ex-date to receive the upcoming dividend.
                </p>
                <p>
                  <strong>Yield (%):</strong> Percentage of the stock price distributed in <strong>annual</strong> dividends.
                </p>
                <p>
                  <strong>Dividend:</strong> Amount of dividends (in local currency) paid on the Payment Date
                </p>
                <p>
                  <strong>Annual Dividend:</strong> Amount (in local currency) of dividends paid in a fiscal year
                </p>

                <p>
                  <strong>Period:</strong> Period during two payments of dividends (Monthly, Quaterly, etc.).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {loaded === false ? (
          <Loader />
        ) : (
          <div className="card">
            <div className="card-body">
              <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>DIVIDENDS</h2>
              {dateToSelect && <div className="text-center m-t-20 m-b-20">Please select a date</div>}

              {dateToSelect === false && <DataTable columns={columns} data={dividends} defaultSortAsc={false} defaultSortFieldId={2} pagination />}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
