import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import Loader from "../../../tools/loader";
import { getFinancialData } from "../../../../../firebase-marketadvisor/firebase-financial-data";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

const CardFinancialDataOverview = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [dataChart, setDataChart] = useState({});
  const [financialData, setFinancialData] = useState({});
  const [availableYears, setAvailableYears] = useState([""]);
  const [earning, setEarning] = useState([""]);

  useEffect(() => {
    getFinancialData(data.symbol).then((fin) => {
      let availableYears = Object.keys(fin);

      let financial = {
        labels: [],
        netIncome: [],
        totalRevenue: [],
      };

      availableYears.map((year) => {
        if (year > 2019 || year === "TTM") {
          financial.labels.push(year);
          financial.netIncome.push(fin[year]["netIncome"]);
          financial.totalRevenue.push(fin[year]["totalRevenue"]);
        }
      });

      setFinancialData(fin);
      setDataChart(financial);
      setAvailableYears(availableYears);
      setLoading(false);
    });

    // getHistoricalEarning(data.symbol).then((earning) => {});
  }, []);

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      enabled: false, // <-- this option disables tooltips
    },

    legend: {
      display: true,
    },

    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            callback: (v) => parseInt(v / 1000000000) + "Md",
            beginAtZero: true,
            display: true, //this will remove only the label
          },
        },
      ],
    },
  };

  const dataGraphBar = {
    labels: dataChart.labels,
    datasets: [
      {
        label: "Total Revenue",
        data: dataChart.totalRevenue,
        backgroundColor: "#68b7f3",
        barThickness: 16,
      },
      {
        label: "Net Income",
        data: dataChart.netIncome,
        backgroundColor: "#ffc107",
        barThickness: 16,
      },
    ],
  };

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#f3f6ff",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  const columns = [
    { name: "", selector: (row) => row.name },
    {
      name: availableYears.slice(-2)[0],
      selector: (row) => {
        return parseInt(financialData[availableYears.slice(-2)[0]][row.variable] / 1000000).toLocaleString("en-US", { minimumFractionDigits: 0 }) + "M";
      },
    },
    {
      name: "Variation Y/Y",
      selector: (row) => {
        try {
          const lastYearBefore = parseFloat(financialData[availableYears.slice(-3)[0]][row.variable]);
          const lastYear = parseFloat(financialData[availableYears.slice(-2)[0]][row.variable]);
          const variation = parseInt((10000 * (lastYear - lastYearBefore)) / lastYearBefore) / 100;

          return (
            <div style={variation < 0 ? { color: "red", fontWeight: 500 } : { color: "green", fontWeight: 500 }}>
              {variation < 0 ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
              {/* <FontAwesomeIcon icon={faArrowDown} /> */}
              <span> {variation + "%"} </span>
            </div>
          );
        } catch {
          return "-";
        }
      },
    },
  ];
  const dataTable = [
    { variable: "totalRevenue", name: "Total Revenue" },
    { variable: "costOfRevenue", name: "Cost of Revenue" },
    { variable: "netIncome", name: "Net Income" },
    { variable: "totalStockholderEquity", name: "Equity" },
    { variable: "freeCashFlow", name: "Cash Flow", value: 10, variation: "-" },
    // { variable: "", name: "EBITDA", value: 10, variation: 10 },
  ];

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="card col-sm-12 col-md-12 p-l-0 p-r-0">
      <div className="container p-l-0 p-r-0">
        <div className=" " style={{ paddingLeft: "0px !important", paddingRight: "0px !important" }}>
          {/* Section Affichage % */}
          <div className="card-body">
            <h6>Financial highlights </h6>
            {/* <p>{` Discover the last ${""} years of earning for ${data.name} `} </p> */}
          </div>
          <div className=""></div>
          {/* Section Affichage Courbe */}
          <div className="row m-l-5 m-r-5 m-b-10">
            <div className="col my-auto p-l-0 p-r-0">
              <div className="chart-block" id="myChart">
                <Bar options={options} data={dataGraphBar} />
              </div>
            </div>
          </div>
          {/* Data COmparison  */}
          <div className="card-body">
            <DataTable columns={columns} data={dataTable} customStyles={customStyles} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFinancialDataOverview;
