import React, { Component, useEffect, useState } from "react";
import { firebase_app } from "./../../../../data/config";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBalanceScale, faEarthAmerica, faGaugeHigh, faGlobe, faLink, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { listVariableETFScreener } from "./listVariableETFScreener";
import { isMobile } from "react-device-detect";
import Loader from "../../tools/loader";
import { columns, customStyles } from "./variableComponent";
import { prebuildETF } from "./preBuildETFScreener";

const ScreenerETF = () => {
  const [resScreener, setResScreener] = useState([]);
  const [resScreenerRaw, setResScreenerRaw] = useState([]);
  const [variable, setVariable] = useState("leverage");
  const [nameVariable, setNameVariable] = useState("Leverage");
  const [operators, setOperators] = useState([">", "<"]);
  const [operator, setOperator] = useState(">");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [listFilter, setListFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState("custom");
  const [extraColumn, setExtraColumn] = useState([]);
  const [typeCondition, setTypeCondition] = useState("input");
  const [formatCondition, setFormatCondition] = useState("number");
  const [conditions, setConditions] = useState();
  const [condition, setCondition] = useState("");
  const [somethingHasChanged, setSomethingHasChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [operatorRank, setOperatorRank] = useState("Ascending");
  const [typeRank, setTypeRank] = useState("geometric");
  const [listRank, setListRank] = useState([]);

  const rankingOperator = ["Ascending", "Descending"];

  useEffect(() => {
    // Update the document title using the browser API

    const tmpVariable = listVariableETFScreener.filter((e) => e.value === variable);
    setOperators(tmpVariable[0].operators);
    setOperator(tmpVariable[0].operators[0]);
    setTypeCondition(tmpVariable[0].condition.type);
    setFormatCondition(tmpVariable[0].condition.format);
    setConditions(tmpVariable[0].condition.option);
    // Reset
    if (tmpVariable[0].condition.type === "select") {
      setCondition(tmpVariable[0].condition.option[0]);
    } else {
      if (tmpVariable[0].condition.format === "number") setCondition(0);
      if (tmpVariable[0].condition.format === "text") setCondition("");
    }
  }, [variable]);

  useEffect(() => {
    if (listRank.length > 0) {
      handleChangeRanking({ listRank });
    } else {
      setResScreener(resScreenerRaw);
    }
  }, [resScreenerRaw]);

  const addFilter = () => {
    var listFilerTmp = [...listFilter];
    const newFilter = { id: Math.floor(Math.random() * 1000000), variable: variable, operator: operator, condition: condition, name: nameVariable };
    listFilerTmp.push(newFilter);
    setListFilter(listFilerTmp);
    setBtnDisabled(false);
    setSomethingHasChanged(false);
  };

  const handleChangeVariable = (event) => {
    const varTmp = listVariableETFScreener.filter((e) => e.value === event.target.value);
    setVariable(event.target.value);
    setNameVariable(varTmp[0].name);
    setSomethingHasChanged(true);
  };

  const handleChangeOperator = (event) => {
    setOperator(event.target.value);
    setSomethingHasChanged(true);
  };

  const handleChangeOperatorRank = (event) => {
    setOperatorRank(event.target.value);
  };

  const handleChangeCondition = (event) => {
    const tmpVariable = listVariableETFScreener.filter((e) => e.value === variable);

    if (tmpVariable[0].condition.type === "select") {
      setCondition(event.target.value);
    } else {
      if (tmpVariable[0].condition.format === "number") setCondition(parseFloat(event.target.value));
      if (tmpVariable[0].condition.format === "text") setCondition(event.target.value);
    }
  };

  const transformOperatorToText = (operator) => {
    if (operator === ">=") return "greater than";
    if (operator === "<=") return "less than";
    // if (operator === "==") return "equal to";
    if (operator === "=") return "equal to";
    // if (operator === "!=") return "not equal to";
    if (operator === "<>") return "not equal to";
    if (operator === "display") return "display";
  };
  const getScreenerResult = (listFilter) => {
    let extraCol = [];
    setLoading(true);
    const getScreener = firebase_app.functions().httpsCallable("getScreenerBigQueryETF");
    getScreener({ listQuery: listFilter })
      .then((res) => {
        setResScreenerRaw(res.data);

        listFilter.forEach((element) => {
          extraCol.push({
            name: element.name,
            selector: (row) => {
              const value = row[String(element.variable)];
              const unit = listVariableETFScreener.filter((e) => e.value === element.variable)[0]?.unit;
              if (!isNaN(value)) {
                return String(parseFloat(value).toFixed(2)) + " " + unit;
              } else {
                return value;
              }
            },
          });
        });

        setExtraColumn(extraCol);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const deleteFilter = (id) => {
    var listFilerTmp = [...listFilter];
    listFilerTmp = listFilerTmp.filter((e) => e.id !== id);
    setListFilter(listFilerTmp);
    setBtnDisabled(false);
  };

  const deleteRank = (id) => {
    var listRankTmp = [...listRank];
    listRankTmp = listRankTmp.filter((e) => e.id !== id);
    handleChangeRanking({ listRank: listRankTmp });
  };
  const addRanking = () => {
    var listRankTmp = [...listRank];
    listRankTmp = listRankTmp.filter((e) => e.variable !== variable);
    const newFilter = { id: Math.floor(Math.random() * 1000000), variable: variable, operator: operatorRank, name: nameVariable };
    listRankTmp.push(newFilter);

    handleChangeRanking({ listRank: listRankTmp });
  };

  const handleChangeRanking = ({ listRank }) => {
    var screenerTmp = [...resScreenerRaw];
    if (resScreenerRaw === undefined) {
      screenerTmp = [...resScreener];
    }
    var arrayTmp = 0;
    // Initialisation
    for (const element of screenerTmp) {
      element.rank = [];
    }

    // Loop Ranking
    var indexCondition = 0;
    listRank.forEach((rankCondition, index) => {
      // Convert to number
      for (const element of screenerTmp) {
        element[rankCondition.variable] = parseFloat(element[rankCondition.variable]);
      }

      // Sort
      if (rankCondition.operator === "Ascending") {
        screenerTmp.sort((a, b) => {
          return a[rankCondition.variable] - b[rankCondition.variable];
        });
      }
      if (rankCondition.operator === "Descending") {
        screenerTmp.sort((a, b) => {
          return b[rankCondition.variable] - a[rankCondition.variable];
        });
      }
      //
      // Add Note
      var index = 1;
      for (const element of screenerTmp) {
        element.rank.push(index);
        index = index + 1;
      }
    });

    // Classement Global

    for (const element of screenerTmp) {
      arrayTmp = element.rank;
      if (typeRank === "arithmetic") {
        element.rank = arrayTmp.reduce((a, b) => a + b, 0) / arrayTmp.length;
      }
      if (typeRank === "geometric") {
        element.rank = arrayTmp.reduce((a, b) => a * b, 1);
      }
    }
    screenerTmp.sort((a, b) => {
      return a.rank - b.rank;
    });

    setResScreener(screenerTmp);
    setListRank(listRank);
  };

  const handleSelectionScreener = (id) => {
    setListFilter(prebuildETF[id].listFilter);
    setListRank(prebuildETF[id].listRank);
    getScreenerResult(prebuildETF[id].listFilter);
  };

  return (
    <div>
      {/* ADD FILTER  */}
      <h2>Pre-Build Screener </h2>
      <p style={{ fontSize: "1rem", color: "grey" }}>Select a popular pre-build Screener</p>
      <div className="row">
        <div className=" col-3">
          <div className="card ">
            <div className="card-body text-center">
              <FontAwesomeIcon className="m-b-10" icon={faGlobe} size={"2xl"} />
              <h4>World ETF</h4>
              <button
                className="btn btnSecondary"
                onClick={() => {
                  handleSelectionScreener("world");
                }}
              >
                Discover
              </button>
            </div>
          </div>
        </div>
        <div className=" col-3">
          <div className="card ">
            <div className="card-body text-center">
              <FontAwesomeIcon className="m-b-10" icon={faEarthAmerica} size={"2xl"} />
              <h4>USA ETF</h4>
              <button
                className="btn btnSecondary"
                onClick={() => {
                  handleSelectionScreener("usa");
                }}
              >
                Discover
              </button>
            </div>
          </div>
        </div>
        <div className=" col-3">
          <div className="card ">
            <div className="card-body text-center">
              <FontAwesomeIcon
                className="m-b-10"
                icon={faGaugeHigh}
                size={"2xl"}
                onClick={() => {
                  handleSelectionScreener("highReturn");
                }}
              />
              <h4>High Return ETF</h4>
              <button className="btn btnSecondary"> Discover</button>
            </div>
          </div>
        </div>
        <div className=" col-3">
          <div className="card ">
            <div className="card-body text-center">
              <FontAwesomeIcon className="m-b-10" icon={faBalanceScale} size={"2xl"} />
              <h4>Stable ETF</h4>
              <button
                className="btn btnSecondary"
                onClick={() => {
                  handleSelectionScreener("stable");
                }}
              >
                {" "}
                Discover
              </button>
            </div>
          </div>
        </div>
      </div>

      <h2>Custom ETF Screener </h2>
      <p style={{ fontSize: "1rem", color: "grey" }}>Find the right ETF among +6000 ETFs</p>
      <div className="card">
        <div className="card-body">
          <h5>Find your ETF</h5>
          <div className="row m-b-20 col">
            {listFilter.map((e) => {
              // On n'affiche pas les éléments qui ne sont pas dans la list
              var toMap = false;

              listVariableETFScreener.forEach((elementVariable) => {
                if (e.variable === elementVariable.value) toMap = true;
              });

              if (toMap) {
                return (
                  <div key={e.id} className="pill-parameters" onClick={() => deleteFilter(e.id)}>
                    {`${e.name} ${e.operator} ${e.operator === "display" ? "" : e.condition}`}
                    <FontAwesomeIcon className="m-l-10" icon={faTrashAlt} />
                  </div>
                );
              }
            })}
          </div>
          <div className={isMobile ? "row m-l-5" : "row m-l-10"}>
            <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
              <select className="rbt-input-main form-control rbt-input " value={variable} onChange={handleChangeVariable}>
                <optgroup label="Performances...">
                  {listVariableETFScreener.map((variable) => {
                    if (variable.group === "perf")
                      return (
                        <option key={variable.value} value={variable.value}>
                          {variable.name}
                        </option>
                      );
                  })}
                </optgroup>
                <optgroup label="Characteristics...">
                  {listVariableETFScreener.map((variable) => {
                    if (variable.group === "charac")
                      return (
                        <option key={variable.value} value={variable.value}>
                          {variable.name}
                        </option>
                      );
                  })}
                </optgroup>
                <optgroup label="Properties...">
                  {listVariableETFScreener.map((variable) => {
                    if (variable.group === "prop")
                      return (
                        <option key={variable.value} value={variable.value}>
                          {variable.name}
                        </option>
                      );
                  })}
                </optgroup>
              </select>
            </div>
            <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
              <select className="rbt-input-main form-control rbt-input " value={operator} onChange={handleChangeOperator}>
                {operators.map((option) => {
                  return (
                    <option key={option} value={option}>
                      {transformOperatorToText(option)}
                    </option>
                  );
                })}
              </select>
            </div>

            {operator === "display" ? (
              <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
                <div className="rbt-input-main form-control rbt-input " style={{ opacity: "0.5" }}>
                  {"N/A"}
                </div>
              </div>
            ) : (
              <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
                {typeCondition === "input" && <input className="rbt-input-main form-control rbt-input " onChange={handleChangeCondition} onClick={() => setCondition("")} value={condition} type={formatCondition}></input>}

                {typeCondition === "select" && (
                  <select className="rbt-input-main form-control rbt-input " value={condition} onChange={handleChangeCondition}>
                    {conditions.map((option) => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
            )}
          </div>

          <div className="row p-l-20 p-r-20  m-t-20 justify-content-md-center">
            <div className={isMobile ? "col-12 text-center m-t-10" : " col-6 text-center m-t-10"}>
              <button className="btn btnPrimary btn-light btn-block" onClick={addFilter}>
                <FontAwesomeIcon icon={faPlus} />
                {somethingHasChanged ? "  Add Filter - Changes detected" : "  Add Filter"}
              </button>

              <button className={`btn m-t-20 m-b-20 btn-block btnSecondary ${btnDisabled && "btnDisabled"}`} disabled={btnDisabled} onClick={() => getScreenerResult(listFilter)}>
                {btnDisabled ? "No Change Detected" : `Get Screener (${listFilter.length} filter${listFilter.length > 1 ? "s" : ""})`}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* // RANK ELEMENT IN SCREENER  */}

      <div className="">
        <div className="card">
          <div className="card-body">
            {/* Partie 1 - Choix du type de Ranking system Arithm or Geometric */}
            <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>SORTING</h2>

            {/* Choix des parametres Ranking  */}
            <h2 className="m-t-20 " style={{ color: "black", fontSize: "1rem" }}>
              PARAMETERS
            </h2>
            <p>The ranking is limited to the selected parameters only.</p>
            {/* Map current filter Version 2 */}
            <div className="row m-b-20 col">
              {listRank.map((e) => {
                return (
                  <div key={e.id} className="pill-parameters" onClick={() => deleteRank(e.id)}>
                    {`${e.name} : ${e.operator === "Ascending" ? "Lower the better" : "Higher the better"}`}
                    <FontAwesomeIcon className="m-l-10" icon={faTrashAlt} />
                  </div>
                );
              })}
            </div>
            {/* Map current filter  */}

            {/* Empty filter  */}
            <div className={isMobile ? "row m-l-5" : "row m-l-10"}>
              {/* {!isMobile && <div className="col-1 listSelectedFilterElementFinal"></div>} */}
              <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
                <select className="rbt-input-main form-control rbt-input " value={variable} onChange={handleChangeVariable}>
                  <option value={""}>Select an Option</option>
                  {listFilter.map((variable) => {
                    if (typeof variable.condition === "number") {
                      return (
                        <option key={variable.id} value={variable.variable}>
                          {variable.name}
                        </option>
                      );
                    }
                    return "";
                  })}
                </select>
              </div>

              <div className="col" style={isMobile ? { paddingRight: "0px", paddingLeft: "5px" } : {}}>
                <select className="rbt-input-main form-control rbt-input " value={operatorRank} onChange={handleChangeOperatorRank}>
                  {rankingOperator.map((option, index) => {
                    return (
                      <option key={index} value={option}>
                        {option === "Ascending" ? "Lower the better" : "Higher the better"}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="" style={{ margin: "auto", textAlign: "center", opacity: "0", marginRight: "10px", fontSize: "large" }}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
            </div>

            <div className="row p-l-20 p-r-20  m-t-20 justify-content-md-center">
              <div className={isMobile ? "col-12 text-center m-t-20" : " col-6 text-center m-t-20"}>
                <button className={`btn btn-block btnSecondary ${variable === "" && "btnDisabled"}`} disabled={variable === ""} onClick={addRanking}>
                  {/* <FontAwesomeIcon icon={faPlus} /> */}

                  {somethingHasChanged ? "  Apply" : "  Apply"}
                </button>
                {/* <button className={`btn m-t-20 m-b-20 btn-block btnSecondary ${btnDisabled && "btnDisabled"}`} disabled={btnDisabled} onClick={getRanking}>
                  {btnDisabled ? "Sorted !" : "Sort Tickers"}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>Your results : </h2>
      {loading ? (
        <div className="container">
          <Loader />
        </div>
      ) : (
        <div className="card">
          <div className="card-body">
            <h5>{`Results`}</h5>
            <span>{`Total of ${resScreener.length} ETFs found`}</span>
            <DataTable data={resScreener} columns={columns.concat(extraColumn)} customStyles={customStyles} pagination />{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default ScreenerETF;
