import React, { Component } from "react";

const CardHeader = ({ title, description }) => {
  return (
    <div className="card m-t-20 card-presentation">
      <div className="card-body ">
        <h2 style={{ fontSize: "3rem" }}>{title}</h2>
        <p style={{ fontSize: "0.9rem" }}>{description}</p>
      </div>
    </div>
  );
};

export default CardHeader;
