import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ChartDataExplorer from "../assetForDataExplorer/chartDataExplorer";
import { calculFinancialData } from "../../../tools/functionCalcFinancialRatio";
import { getScore, getGlobalNote } from "./toolWidget";
import LinearGauge from "./linearGauge";
import { isMobile } from "react-device-detect";
import ReactApexChart from "react-apexcharts";
import ApexGraphNote from "./components/apexGraphNote";

class EarningPowerWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: this.props.subscription,
      financial: this.props.data.financial,
      year: this.props.data.year,
      listYearAvailable: this.props.data.listYearAvailable,
      averageSector: this.props.data.averageSector,
      keypoints: [],
      noteGlobal: 0,
    };
  }

  componentDidMount = () => {
    // Calcul 1y
    const fin = this.state.financial;
    const year = this.state.year;
    const listYearAvailable = this.state.listYearAvailable;
    let { grossMargin, operatingMargin, netMargin, ROA, ROE } = calculFinancialData(fin, year);
    if (isNaN(grossMargin)) {
      ({ grossMargin, operatingMargin, netMargin, ROA, ROE } = calculFinancialData(fin, listYearAvailable[1]));
    }

    // const dateAvg = this.state.listYearAvailable.slice(0, Math.min(5, this.state.listYearAvailable.length));
    // const { lengthAvg, grossMarginAvg, operatingMarginAvg, netMarginAvg, ROAAvg, ROEAvg } = calculFinancialDataAvg(fin, dateAvg);

    var columns = [];
    if (isMobile) {
      columns = [
        {
          name: "Indicator",
          selector: (row) => (
            <div className="p-t-5 p-b-5">
              <strong>{row.indicator}</strong>
              <div>{row.value + row.symbol}</div>
            </div>
          ),
        },
        {
          name: "VS Sector",
          selector: (row) => {
            if (isNaN(row.averageSector)) return "N/A";

            var position = String(row.score) + "%";
            if (row.score > 90) position = String(90) + "%";
            return <LinearGauge position={position} />;
            // return (100 * Math.round(row.averageSector * 10000)) / 10000 + row.symbol;
          },
        },
      ];
    } else {
      columns = [
        {
          name: "Indicator",
          selector: (row) => <strong>{row.indicator}</strong>,
        },
        {
          name: "Value",
          selector: (row) => row.value + row.symbol,
        },
        {
          name: "VS Sector",
          selector: (row) => {
            if (isNaN(row.averageSector)) return "N/A";
            var position = String(row.score) + "%";
            if (row.score > 90) position = String(90) + "%";
            return <LinearGauge position={position} />;
          },
        },
      ];
    }

    const data = [
      {
        id: 1,
        indicator: "Gross Margin",
        value: grossMargin,
        symbol: "%",
        definition:
          "A measure of how much income a comany has left after paying all direct production expenses. It is a good indication of how profitable a company is at the most fundamental level. Companies with higher gross margins will have more money left over to spend on other business operations, such as research and development or marketing.",
        plot: true,
        variable: "grossMargin",
        averageSector: this.state.averageSector?.grossMargin?.["50%"],
        score: getScore({ average: this.state.averageSector?.grossMargin, value: grossMargin, percent: true }),
      },
      {
        id: 2,
        indicator: "Operating Margin",
        value: operatingMargin,
        symbol: "%",
        definition:
          "A measure of how much income a company has left after paying its operating expenses, such as rent and salaries. A healthy operating margin is necessary for a company to be able to pay its fixed costs, such as interest on debt",
        plot: true,
        variable: "operatingMargin",
        averageSector: this.state.averageSector?.operatingMargin?.["50%"],
        score: getScore({ average: this.state.averageSector?.operatingMargin, value: operatingMargin, percent: true }),
      },
      {
        id: 3,
        indicator: "Net Margin",
        value: netMargin,
        symbol: "%",
        definition:
          "A measure of how much profit a company has left over after paying all of its expenses, including Interest and Taxes. After all expenses, including taxes and interest costs, are paid, net profit is what is left for shareholders",
        plot: true,
        variable: "netMargin",
        averageSector: this.state.averageSector?.netMargin?.["50%"],
        score: getScore({ average: this.state.averageSector?.netMargin, value: netMargin, percent: true }),
      },
      {
        id: 4,
        indicator: "ROA",
        value: ROA,
        symbol: "%",
        definition:
          "A measure of how efficiently a company uses its assets to generate income. ROA seeks to measure the company's performance on all assets, regardless of how they were acquired. A high figure often means that the company has a defensible advantage over its competitors.",
        plot: true,
        variable: "ROA",
        averageSector: this.state.averageSector?.ROA?.["50%"],
        score: getScore({ average: this.state.averageSector?.ROA, value: ROA, percent: true }),
      },
      {
        id: 5,
        indicator: "ROE",
        value: ROE,
        symbol: "%",
        definition: "Measures a company's efficiency in the use of shareholders' equity for profit generation",
        plot: true,
        variable: "ROE",
        averageSector: this.state.averageSector?.ROE?.["50%"],
        score: getScore({ average: this.state.averageSector?.ROE, value: ROE, percent: true }),
      },
    ];

    const { noteGlobal, noteGlobalStyle } = getGlobalNote({ data });

    this.props.onNote({ type: "earning", value: noteGlobal, style: noteGlobalStyle });

    // const keypoints = this.getKeypoints();

    this.setState({
      noteGlobal,
      // keypoints,
      noteGlobalStyle,
      columns,
      data,
    });
  };

  ExpandedComponent = ({ data }) => {
    if (data.plot) {
      const listYearAvailable = [...this.state.listYearAvailable].reverse();
      let dataPlot = [];

      for (const y of listYearAvailable) {
        dataPlot.push(calculFinancialData(this.state.financial, y)[data.variable]);
      }

      return (
        <div className="m-t-10">
          <h4 style={{ fontSize: "16px", fontWeight: "600", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>{data.indicator}</h4>
          <div className="m-10">{data.definition}</div>
          <ChartDataExplorer datas={{ labels: listYearAvailable, data: dataPlot, unit: data.symbol }} />
          <div></div>
        </div>
      );
    } else {
      return (
        <div className="m-t-10">
          <h4 style={{ fontSize: "16px", fontWeight: "600", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>{data.indicator}</h4>
          <div className="m-10">{data.definition}</div>
        </div>
      );
    }
  };

  render() {
    const { noteGlobal, noteGlobalStyle } = this.state;

    const customStyles = {
      headRow: {
        style: {
          border: "none",
        },
      },
      headCells: {
        style: {
          color: "#202124",
          fontSize: "14px",
        },
      },
      rows: {
        highlightOnHoverStyle: {
          backgroundColor: "#EEEEEE",
          borderBottomColor: "#FFFFFF",
          borderRadius: "25px",
          outline: "1px solid #FFFFFF",
          cursor: "pointer",
        },
      },
      pagination: {
        style: {
          border: "none",
        },
      },
    };

    const dataGraph = {
      series: [this.state.noteGlobal],

      options: {
        colors: ["#36e18e"],
        chart: {
          height: 350,
          type: "radialBar",
          offsetY: -10,
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            track: { background: "rgba(28, 206, 41, 0.05)" },
            dataLabels: {
              name: {
                show: false,
                fontSize: "16px",
                color: undefined,
                offsetY: -10,
              },
              value: {
                offsetY: 10,
                fontSize: "30px",
                color: "#36e18e",
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#36e18e"],
            stops: [0, 100],
          },
        },
        stroke: {
          // dashArray: 4,
          lineCap: "round",
        },
        labels: ["Median Ratio"],
      },
    };

    return (
      <div className="card">
        <div className="card-body" style={{ padding: "15px" }}>
          <h4 style={{ fontSize: "1.25rem", fontWeight: "800", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>Earning Power</h4>
          <div className="m-20"></div>
          <div className="row">
            <div className={isMobile ? "col-6 p-r-0" : "col-6 p-r-0"}>
              {/* <div className={`notation ${this.state.noteGlobalStyle}`}>{this.state.noteGlobal}</div> */}
              {/* <div id="chart">
                <ReactApexChart options={dataGraph.options} series={dataGraph.series} type="radialBar" />
              </div> */}

              <ApexGraphNote data={{ noteGlobal, noteGlobalStyle }} />
            </div>
            <div className="col">
              <p>A company's ability to generate profits over time. A measure of a company's long-term sustainability and profitability. </p>
            </div>
          </div>
          {/* Table */}
          <div className="m-20"></div>
          <DataTable columns={this.state.columns} data={this.state.data} dense={true} customStyles={customStyles} highlightOnHover expandableRows expandableRowsComponent={this.ExpandedComponent} />
          <div className="m-20"></div>
        </div>
      </div>
    );
  }
}

export default EarningPowerWidget;
