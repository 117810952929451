import React, { Component } from "react";
import { postMemo, getMemo, deleteMemo } from "../../../../../firebase-marketadvisor/personalNoteFirebase";
import { firebase_app } from "../../../../../data/config";
import Loader from "../../../tools/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

class PersonalMemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      memo: [],
      symbol: this.props.symbol,
      price: this.props.price,
      loading: true,
      restricted: true,
    };
  }

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        getMemo(this.state.symbol).then((r) => {
          this.setState({ memo: r, loading: false, restricted: false });
        });
      } else {
        this.setState({ memo: [], loading: false, restricted: true });
      }
    });
  };
  datasetKeyProvider = () => {
    return Math.random();
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const today = new Date();
    // Construct message
    const message = `${today.toDateString()} (${this.state.symbol} @${this.props.price})-${this.state.value}`;
    postMemo({ symbol: this.state.symbol, text: message });
    var currentMemo = [...this.state.memo];
    currentMemo.push(message);
    this.setState({ value: "", memo: currentMemo });
  };

  deleteMemo = (message) => {
    var currentMemo = [...this.state.memo];

    currentMemo = currentMemo.filter((m) => m !== message);
    deleteMemo({ symbol: this.state.symbol, text: message });
    this.setState({ memo: currentMemo });
  };
  render() {
    const memo = [...this.state.memo].reverse().slice(0, 8).reverse();

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="card col-12 p-l-0 p-r-0">
        <div className="container container p-l-0 p-r-0">
          <div className="card-body ">
            <h6>Personal Note</h6>
            {memo.length > 0 ? (
              memo.map((m) => {
                let dateMessageBrut = m.split("-")[0];
                const message = m.split("-")[1];
                const price = dateMessageBrut.replace(dateMessageBrut.split("(")[0], "");
                let dateMessage = new Date(dateMessageBrut.split("(")[0]).toLocaleDateString("en-US");

                return (
                  <div key={message} className="row justify-content-between memoStyle">
                    <div className="col-10">
                      <p>
                        <strong>{`${dateMessage} ${price}`}</strong> {`: ${message}`}
                      </p>
                    </div>
                    <div className="col-1">
                      <span
                        style={{ fontWeight: "bolder", cursor: "pointer" }}
                        onClick={() => {
                          this.deleteMemo(m);
                        }}
                      >
                        x
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col my-auto p-10" style={{ textAlign: "center" }}>
                <FontAwesomeIcon icon={faPencil} style={{ height: "50px", color: "#2599f37d" }} />
                {/* <span style={{ color: "#8d8d8d", fontStyle: "italic" }}>Type what you have in mind !</span> */}
              </div>
            )}
            <div className="m-20"></div>
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-12">
                  {/* <label> */}
                  <input className="rbt-input-main form-control rbt-input " type="text" value={this.state.value} onChange={this.handleChange} placeholder="Enter a personal thought" />
                  {/* </label> */}
                </div>
                <div className="col-12 m-t-20  text-center">
                  <input className="btn btnSecondary" type="submit" value="Add Note" disabled={this.state.value.length > 0 && !this.state.restricted ? false : true} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalMemo;
