import React, { Component, useEffect, useState } from "react";

// import { firebase_app } from "../../../../../data/config";
// import Sentiment from "sentiment";
// import { Bar } from "react-chartjs-2";
// import Loader from "../../../tools/loader";

import { firebase_app } from "../../../../../data/config";
import Loader from "../../../tools/loader";

const NewsComponent = ({ symbol, name }) => {
  const [news, setNews] = useState([]);
  const [internalNews, setInternalNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingInternal, setLoadingInternal] = useState(true);

  useEffect(() => {
    const getNewsAPI = firebase_app.functions().httpsCallable("getNewsAPI");
    getNewsAPI({ symbol, name })
      .then((res) => {
        setNews(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });

    getNewsAPI({ symbol, name, typeNews: "internal" })
      .then((res) => {
        setInternalNews(res.data);
        setLoadingInternal(false);
      })
      .catch((e) => {
        setLoadingInternal(false);
      });
  }, []);

  const getSentiment = (value) => {
    let sentiment = "Neutral";
    let color = "grey";
    if (value < -20) {
      sentiment = "Negative";
      color = "red";
    }
    if (value > 20) {
      sentiment = "Good";
      color = "#37b266";
    }

    return <h5 style={{ color: color, marginLeft: "10px", fontSize: "1rem" }}>{`Sentiment ${sentiment} (${Math.abs(value)}%)`}</h5>;
  };

  if (loading) {
    return <Loader fullScreen={false} />;
  } else {
    return (
      <div className="container">
        <div className="row">
          {/* List News */}
          <div className="col-8">
            {news.map((e) => (
              <div className="card" key={e.id}>
                <div className="card-body p-0 m-0">
                  <div className="row">
                    <div className="col-4 ">{e.imageUrls && <img style={{ borderRadius: "initial", height: "100%", objectFit: "cover", width: "100%" }} src={e.imageUrls[0]} />}</div>
                    <div className="col-8 p-20">
                      <h5 className="m-b-5"> {e.title}</h5>

                      <p className="m-10 ">
                        {" "}
                        Published {e.publishTime.split("T")[0]} by {e.domain_name}
                      </p>
                      {getSentiment(e.sentiment)}
                      <div>{e.description}</div>
                      <a className="btn btnSecondary m-t-10" href={e.url} target="_blank" rel="noopener noreferrer">
                        Read Original
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="col-4">
            <div className="card">
              <div className="card-body">
                <div style={{ fontWeight: "600", fontSize: "1.1rem", color: "black" }}>Investor Relations</div>
                {internalNews.map((news) => {
                  return (
                    <a className="" href={news.link} target="_blank" rel="noopener noreferrer" style={{ color: "initial" }}>
                      <div style={{ borderBottom: "1px solid #ededed", paddingTop: "20px", paddingBottom: "20px" }}>
                        <div style={{ fontWeight: "500", fontSize: "1rem" }}>
                          Press Release <span style={{ fontWeight: "400", fontSize: "0.9rem", color: "gray" }}> {` - ${news.generationDate.split("T")[0]}`}</span>
                        </div>
                        <div>{`${news.title}`}</div>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

// class NewsComponent extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       loading: true,
//       symbol: this.props.symbol,
//       news: [],
//       globalSentiment: 0,
//     };
//   }
//   componentDidMount() {
//     const getNewsSymbol = firebase_app.functions().httpsCallable("getNewsSymbol");

//     getNewsSymbol({ symbol: this.state.symbol })
//       .then((res) => {
//         var data = res.data;
//         var sentiment = new Sentiment();
//         var positifSentiment = 0;
//         var dataHistogramme = [];

//         data.forEach((e) => {
//           var text = e.title + e.description;
//           var result = sentiment.analyze(text);
//           if (result.score > 0) {
//             positifSentiment = positifSentiment + 1;
//           }
//           e.sentiment = result.score;
//           dataHistogramme.push(result.comparative);
//         });

//         // Data pour Histogramme Sentiment

//         const minHist = Math.min(...dataHistogramme);
//         const maxHist = Math.max(...dataHistogramme);
//         const step = 10;
//         const stepSize = (maxHist - minHist) / step;

//         var labelHistogramme = Array.from(Array(step).keys());
//         labelHistogramme = labelHistogramme.map((e) => e * stepSize + minHist);
//         var dataHisogrammePlot = new Array(step).fill(1);

//         var index = 0;
//         dataHistogramme.forEach((e) => {
//           index = Math.trunc(e / stepSize);
//           dataHisogrammePlot[index] = dataHisogrammePlot[index] + 1;
//         });

//         this.setState({
//           loading: false,
//           news: data,
//           globalSentiment: Math.round((100 * positifSentiment) / data.length),
//           dataHisogrammePlot,
//           labelHistogramme,
//         });
//       })

//       .catch((error) => this.setState({ loading: false, news: [] }));
//   }

//   render() {
//     if (this.state.loading) {
//       return <Loader />;
//     } else {
//       if (this.state.news.length > 0) {
//         const data = {
//           labels: this.state.labelHistogramme,
//           datasets: [
//             {
//               label: "Dataset 1",
//               data: this.state.dataHisogrammePlot,
//               backgroundColor: (color) => {
//                 let colors = color.chart.chart.config.data.labels[color.dataIndex] < 0 ? "#ff7dae99" : "#a9f8c2";
//                 return colors;
//               },
//             },
//           ],
//         };

//         const options = {
//           responsive: true,
//           maintainAspectRatio: true,
//           aspectRatio: 0.5,
//           plugins: {
//             datalabels: {
//               display: false,
//             },
//           },
//           tooltips: {
//             enabled: false, // <-- this option disables tooltips
//           },

//           legend: {
//             display: false,
//           },
//           layout: {
//             padding: {
//               bottom: 10,
//             },
//           },
//           scales: {
//             xAxes: [
//               {
//                 gridLines: {
//                   display: false,
//                 },
//                 ticks: {
//                   beginAtZero: true,
//                   display: false, //this will remove only the label

//                   maxRotation: 0,
//                   minRotation: 0,
//                 },
//               },
//             ],
//             yAxes: [
//               {
//                 gridLines: {
//                   display: false,
//                 },
//                 ticks: {
//                   beginAtZero: true,
//                   display: false, //this will remove only the label
//                 },
//               },
//             ],
//           },
//         };

//         return (
//           <div className="row">
//             {/* List News */}
//             <div className="col-sm-12 col-md-8 col-xl-8 order-md-1 order-sm-2">
//               {this.state.news.map((e) => (
//                 <div className="card">
//                   <div className="card-header ">
//                     <div className="row">
//                       <div className="col-3 col-md-2" style={{ borderRightStyle: "solid", borderRightWidth: "1.5px" }}>
//                         <div className="row text-center">
//                           <span className="col" style={{ fontSize: "x-large" }}>
//                             {e.date.split("+")[0].split(" ")[1]}
//                           </span>
//                         </div>
//                         <div className="row text-center">
//                           <span className="col" style={{ fontSize: "large" }}>
//                             {e.date.split("+")[0].split(" ")[2]}
//                           </span>
//                         </div>
//                       </div>
//                       <div className="col">
//                         <h5 className="m-b-5"> {e.title}</h5>
//                         {/* <span> {e.date.split("+")[0]}</span> */}
//                       </div>
//                     </div>
//                   </div>
//                   <div className="card-body p-t-5 m-t-10">
//                     <div>{e.description}</div>
//                     <a className="btn btn-light btnPrimary m-t-10" href={e.link} target="_blank" rel="noopener noreferrer">
//                       Read Original
//                     </a>
//                   </div>
//                 </div>
//               ))}
//             </div>

//             {/* Global Sentiment  */}
//             <div className="col-sm-12 col-md-4 col-xl-4 order-md-2 order-sm-1">
//               <div className="card">
//                 {/* <div className="card-header ">
//                 <h5 className="m-b-5"> Media Sentiment Note</h5>
//               </div> */}
//                 <div className="card-body">
//                   <h6>
//                     Smart Media Sentiment <sup>TM</sup>
//                   </h6>
//                   <span>{`The chart above shows the distribution of sentiment for each media news based on AI-powered algorithm. `}</span>
//                   <div className="m-15"></div>
//                   <div style={{ height: "100%" }}>
//                     <Bar options={options} data={data} />
//                   </div>
//                   <div className="row m-b-5">
//                     <div className="col-6 text-center">
//                       <p>
//                         <strong># Negative News</strong>
//                       </p>
//                     </div>
//                     <div className="col-6  text-center">
//                       <p>
//                         <strong># Positive News</strong>
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         );
//       } else {
//         return (
//           <div className="card">
//             <div className="card-body">
//               <div className="row text-center">
//                 <span className="col" style={{ fontSize: "large" }}>
//                   {`No news available for ${this.props.symbol}`}
//                 </span>
//               </div>
//             </div>
//           </div>
//         );
//       }
//     }
//   }
// }

export default NewsComponent;
