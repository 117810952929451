export const conversionExchange = {
  AMEX: "",
  AS: "AS",
  AT: "AT",
  AU: "AX",
  BA: "BA",
  BATS: "",
  BE: "DE",
  BK: "BK",
  BR: "BR",
  BSE: "BO",
  BUD: "BD",
  CM: "CM",
  CO: "CO",
  DU: "DU",
  EUFUND: "EUFUND",
  F: "F",
  HA: "HA",
  HE: "HE",
  HK: "HK",
  HM: "HM",
  IC: "IC",
  IL: "IL",
  IR: "IR",
  IS: "IS",
  JK: "JK",
  JSE: "JSE",
  KAR: "KAR",
  KLSE: "KLSE",
  KO: "KS",
  KQ: "KQ",
  LIM: "LIM",
  LS: "LS",
  LSE: "L",
  MC: "MC",
  MCX: "MCX",
  MI: "MI",
  MU: "MU",
  MX: "MX",
  NASDAQ: "",
  NEO: "NE",
  NSE: "NSE",
  NYSE: "",
  "NYSE ARCA": "",
  "NYSE MKT": "",
  OL: "OL",
  OTCBB: "",
  OTCCE: "",
  OTCGREY: "",
  OTCMKTS: "",
  OTCQB: "",
  OTCQX: "",
  PA: "PA",
  PINK: "",
  PR: "PR",
  PSE: "PSE",
  RO: "RO",
  SA: "SA",
  SG: "SI",
  SHE: "SZ",
  SHG: "SS",
  SN: "SN",
  SR: "SR",
  ST: "ST",
  STU: "SG",
  SW: "SW",
  TA: "TA",
  TO: "TO",
  TW: "TW",
  TWO: "TWO",
  V: "V",
  VI: "VI",
  VN: "VN",
  VX: "VX",
  WAR: "WA",
  XETRA: "DE",
  ZSE: "ZSE",
};
