import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faLock, faWarning, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

class KeyPoints extends Component {
  constructor(props) {
    super(props);

    this.state = {
      note: this.props.data.note,
      subscription: this.props.data.subscription,
    };
  }

  render() {
    return (
      <div className="">
        <div className="">
          <h4 style={{ fontSize: "1.25rem", fontWeight: "800", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>Key Points</h4>

          {/* Positive Feedback */}
          <div className="row m-l-20 m-t-30">
            {this.props.data.note?.earning?.value > 70 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faStar} style={{ color: "#22c55e", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Forecasts strong earnings in the future"}
              </div>
            )}
            {this.props.data.note?.stability?.value > 70 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faStar} style={{ color: "#22c55e", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Stable business, can last for years"}
              </div>
            )}
            {this.props.data.note?.price?.value > 70 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faStar} style={{ color: "#22c55e", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Very fair share price"}
              </div>
            )}
            {this.props.data.note?.momentum?.value > 70 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faStar} style={{ color: "#22c55e", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Good time for investment"}
              </div>
            )}
            {this.props.data.note?.growth?.value > 70 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faStar} style={{ color: "#22c55e", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Success in replicating past performance"}
              </div>
            )}

            {/* Neutral Feedback */}

            {this.props.data.note?.earning?.value <= 65 && this.props.data.note?.earning?.value >= 30 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "#ff8800", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Earnings are stable but may fluctuate"}
              </div>
            )}
            {this.props.data.note?.stability?.value <= 65 && this.props.data.note?.stability?.value >= 30 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "#ff8800", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Stable business but in need of more evidence (debts, etc.)"}
              </div>
            )}
            {this.props.data.note?.price?.value <= 65 && this.props.data.note?.price?.value >= 30 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "#ff8800", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Slightly overvalued share price"}
              </div>
            )}
            {this.props.data.note?.momentum?.value <= 65 && this.props.data.note?.momentum?.value >= 30 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "#ff8800", marginRight: "15px", fontSize: "1.2rem" }} />
                {"No clear direction for the share price"}
              </div>
            )}
            {this.props.data.note?.growth?.value <= 65 && this.props.data.note?.growth?.value >= 40 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "#ff8800", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Swings between good and bad years"}
              </div>
            )}

            {/* Negative Feedback */}

            {this.props.data.note?.earning?.value < 30 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faWarning} style={{ color: "#f11", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Company must prove its ability to make a profit"}
              </div>
            )}
            {this.props.data.note?.stability?.value < 30 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faWarning} style={{ color: "#f11", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Company struggling and needs to act to avoid financial distress"}
              </div>
            )}
            {this.props.data.note?.price?.value < 30 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faWarning} style={{ color: "#f11", marginRight: "15px", fontSize: "1.2rem" }} />
                {"The share price is clearly overvalued."}
              </div>
            )}
            {this.props.data.note?.momentum?.value < 30 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faWarning} style={{ color: "#f11", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Share price is tending to fall"}
              </div>
            )}
            {this.props.data.note?.growth?.value < 40 && (
              <div className="col-12 m-b-10" style={{ color: "#565973", textAlign: "left", fontSize: "0.9rem", fontWeight: "500" }}>
                <FontAwesomeIcon icon={faWarning} style={{ color: "#f11", marginRight: "15px", fontSize: "1.2rem" }} />
                {"Difficulty replicating its performance"}
              </div>
            )}
          </div>

          <div className="m-20"></div>
        </div>

        {/* {this.state.subscription === false && (
          <div className="text-center blurred" style={{ position: "absolute", bottom: "0", height: "80%", width: "100%", backgroundColor: "#ffffff96", backdropFilter: "blur(3px)" }}>
            <div style={{ position: "relative", top: "40%", transform: "translateY(-50%)", fontSize: "50px", color: "#898989" }}>
              <FontAwesomeIcon icon={faLock} />
            </div>
          </div>
        )} */}
      </div>
    );
  }
}

export default KeyPoints;
