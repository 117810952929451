import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { fetchLogoUrl } from "../../../../tools/fetchLogoCompany";
import { Link } from "react-router-dom";

const EHMarketMover = () => {
  const [marketMovers, setMarketMovers] = useState([]);
  const [gainer, setGainer] = useState([]);
  const [looser, setLooser] = useState([]);
  useEffect(() => {
    const url = "https://et3.bullsheet.me/ehMarketMovers";

    // const url2 = "https://www.etoro.com/sapi/candles/candles/desc.json/OneDay/1"; // get bundle current data if market open
    // const url3 = "https://api.etorostatic.com/sapi/instrumentsmetadata/V1.1/instruments/";

    fetch(url).then(async (res) => {
      const data = await res.json();

      setMarketMovers(data.prices);
      let gainer = [];
      let looser = [];
      data.prices.forEach((element) => {
        if (element.changePct > 0) {
          gainer.push(element);
        } else {
          looser.push(element);
        }
      });

      gainer = gainer.sort((a, b) => b.changePct - a.changePct);
      looser = looser.sort((a, b) => a.changePct - b.changePct);

      setGainer(gainer);
      setLooser(looser);
    });
  }, []);

  if (marketMovers.length === 0) {
    return (
      <div>
        <h5>{`Pre-market big movers - US`}</h5>
        <div className="row">
          <div className="col-12">
            <div className="card ">
              <div className="container ">
                <div className="card-body ">
                  <div className="text-center m-t-20 m-b-20">
                    <span> The market is open - no pre-market data available </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h5>{`Pre-market big movers - US`}</h5>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <div className="card ">
              <div className="container ">
                <div className="card-body ">
                  <div className={"table-responsive col-12 m-t-20"}>
                    {/* <h5>{`Big Gainer`}</h5> */}
                    <span className="h6">Bigger Gainer in Pre-Market</span>
                    <table className="table m-t-20">
                      <thead>
                        <tr>
                          <th className="">Asset</th>
                          <th className="text-center">Yesterday</th>
                          <th className="text-center">Pre-Market</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gainer.map((symbol, index) => {
                          if (index <= 5) {
                            var logoPath = fetchLogoUrl(symbol.ticker);
                            var logoPath = symbol.logo;
                            return (
                              <tr key={symbol.ticker}>
                                <th className="">
                                  <div className="row">
                                    <div className="col-4" style={{ display: "contents" }}>
                                      <Link to={"/symbol/" + symbol.ticker}>
                                        <img
                                          src={logoPath}
                                          alt="new"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                                          }}
                                          style={{ maxWidth: "40px", maxHeight: "35px" }}
                                        />
                                      </Link>
                                    </div>
                                    <div className={isMobile ? "col-8 p-0" : "col-8"} style={isMobile ? { textAlign: "center" } : {}}>
                                      <Link to={"/symbol/" + symbol.ticker} className="maincolortext">
                                        <div>{`${symbol.ticker}`}</div>
                                        <div style={{ fontWeight: "lighter", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${symbol.name}`}</div>
                                      </Link>
                                    </div>
                                  </div>
                                </th>

                                <td className={symbol.lastDayChangePct > 0 ? "font-success" : "font-danger"}>
                                  <div className="row justify-content-center" style={{ fontWeight: "bold" }}>
                                    {symbol.lastDayChangePct + "%"}
                                  </div>
                                </td>

                                <td className={symbol.changePct > 0 ? "font-success" : "font-danger"}>
                                  <div className="row justify-content-center" style={{ fontWeight: "bold" }}>
                                    {symbol.changePct + "%"}
                                  </div>
                                  {/* <div className="row justify-content-center">{symbol.changePct + "%"}</div> */}
                                </td>
                              </tr>
                            );
                          }
                          return "";
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-6">
            <div className="card ">
              <div className="container ">
                <div className="card-body ">
                  <div className={"table-responsive col-12 m-t-20"}>
                    <span className="h6">Bigger Loss in Pre-market</span>
                    <table className="table m-t-20">
                      <thead>
                        <tr>
                          <th className=" ">Asset</th>
                          <th className="text-center">Yesterday</th>
                          <th className="text-center">Pre-Market</th>
                        </tr>
                      </thead>
                      <tbody>
                        {looser.map((symbol, index) => {
                          if (index <= 5) {
                            // var logoPath = fetchLogoUrl(symbol.ticker);
                            const logoPath = symbol.logo;
                            return (
                              <tr key={symbol.ticker}>
                                <th className="col-6">
                                  <div className="row">
                                    <div className="col-4" style={{ display: "contents" }}>
                                      <Link to={"/symbol/" + symbol.ticker}>
                                        <img
                                          src={logoPath}
                                          alt="new"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                                          }}
                                          style={{ maxWidth: "40px", maxHeight: "35px" }}
                                        />
                                      </Link>
                                    </div>
                                    <div className={isMobile ? "col-8 p-0" : "col-8"} style={isMobile ? { textAlign: "center" } : {}}>
                                      <Link to={"/symbol/" + symbol.ticker} style={{ color: "#2f4050" }}>
                                        <div>{`${symbol.ticker}`}</div>
                                        <div style={{ fontWeight: "lighter", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${symbol.name}`}</div>
                                      </Link>
                                    </div>
                                  </div>
                                </th>

                                <td className={symbol.lastDayChangePct > 0 ? "font-success" : "font-danger"}>
                                  <div className="row justify-content-center" style={{ fontWeight: "bold" }}>
                                    {symbol.lastDayChangePct + "%"}
                                  </div>
                                  {/* <div className="row justify-content-center">{symbol.lastDayChangePct + "%"}</div> */}
                                </td>
                                <td className={symbol.changePct > 0 ? "font-success" : "font-danger"}>
                                  <div className="row justify-content-center" style={{ fontWeight: "bold" }}>
                                    {symbol.changePct + "%"}
                                  </div>
                                  {/* <div className="row justify-content-center">{symbol.changePct + "%"}</div> */}
                                </td>
                              </tr>
                            );
                          }
                          return "";
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default EHMarketMover;
