import React, { Component, Fragment } from "react";
import { firebase_app } from "../../../data/config";
import laptopInvest from "../../../assets/images/isometric/laptopInvest.jpg";
import { hasSub } from "../../../firebase-marketadvisor/firebase-data-user";

class SubscribeNow extends Component {
  state = { subscription: false };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        hasSub(user).then((subscription) => {
          this.setState({ subscription });
        });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <div className="card m-t-10">
          <div className="container">
            <div className="col-sm-12 col-xl-12">
              <div className="card-body ">
                <div className="row">
                  <div className="col my-auto" style={{ textAlign: "center" }}>
                    {this.state.subscription ? (
                      <div>
                        <h5>{"Finding and analyzing the stock market has never been easier with MarketAdvisor. You are currently on a paid plan"}</h5>
                      </div>
                    ) : (
                      <div>
                        <h5>{"Finding and analyzing the stock market has never been easier with MarketAdvisor. Start for just $7.90 per month"}</h5>
                        <button className="m-t-20 btn btnSecondary">Subscribe Now</button>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <img src={laptopInvest} alt="CTA" style={{ width: "100%" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SubscribeNow;
