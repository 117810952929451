import React, { Component, Fragment, createContext, useContext } from "react";
import { firebase_app } from "../../../data/config";
import Stripe from "./components/CardsStripe";
import { hasSub } from "./../../../firebase-marketadvisor/firebase-data-user";
import Loader from "../tools/loader";

class PageSubscription extends Component {
  state = {
    active: false,
    name: "",
    price: 0,
    subscription: false,
    loading: true,
    payment: false,
    cancel_at_period_end: false,
  };

  handleUpdate = () => {
    this.componentDidMount();
  };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        hasSub(user).then((subscription) => {
          this.setState({ user, subscription, loading: false });
        });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader fullScreen={true} />;
    }

    return (
      <Fragment>
        {this.state.subscription ? (
          <div>
            <div className="container p-t-50">
              <div className="card card-body">
                <div className=" text-center m-t-20">
                  <h3>You have an active Subscription</h3>
                  <h6 className="m-t-50" style={{ color: "#777777" }}>
                    You can easily change your payment preferences, change your current plan, cancel your subscription, or view your billing information by clicking the button below
                  </h6>
                </div>
                <div className=" row justify-content-center text-center m-b-10 m-t-50">
                  <a className="btn btnPrimary" href="https://billing.stripe.com/p/login/14k9BIbmT2XVc2keUU" style={{ display: "flex", alignItems: "center" }}>
                    Manage Subscription
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container p-t-50">
            <div className="text-center">
              <h2>Ready to get started?</h2>
              <p style={{ fontSize: "16px", color: "#777777" }}>Choose a plan tailored to your needs</p>
            </div>

            <div className="row justify-content-center">{this.state.user && <Stripe data={{ user: this.state.user }} />}</div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default PageSubscription;
