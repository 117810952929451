export const listVariableETFScreener = [
  // MarketAdvisor

  { group: "perf", value: "Returns_1Y", name: "Return 1 Year (%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },
  { group: "perf", value: "Returns_3Y", name: "Return 3 Years  (%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },
  { group: "perf", value: "Returns_5Y", name: "Return 5 Years  (%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },
  { group: "perf", value: "Returns_10Y", name: "Return 10 Years  (%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },
  { group: "perf", value: "Returns_YTD", name: "Return YTD  (%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },
  { group: "perf", value: "SharpRatio_3y", name: "Sharpe Ratio 3 Years", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "perf", value: "Volatility_1y", name: "Volatility 1 Year(%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },
  { group: "perf", value: "Volatility_3y", name: "Volatility 3 Years (%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },

  { group: "charac", value: "Age", name: "Age (year)", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "charac", value: "CountryName", name: "Country", operators: ["=", "display"], unit: "", condition: { type: "select", format: "text", option: ["USA", "Germany", "UK", "Switzerland", "France", "Netherlands", "Korea"] } },
  { group: "charac", value: "CurrencyCode", name: "Currency", operators: ["=", "display"], unit: "", condition: { type: "select", format: "text", option: ["USD", "EUR", "GBX", "CHF", "GBP", "KRW", "JPY", "CAD", "AUD", "SEK"] } },
  {
    group: "charac",
    value: "Company_Name",
    name: "Distributed by...",
    operators: ["=", "display"],
    unit: "",
    condition: { type: "select", format: "text", option: ["iShares", "Amundi", "BlackRock", "State Street", "Xtrackers", "Invesco", "UBS", "Vanguard", "First Trust", "ProShares", "WisdomTree", "BNP Paribas", "HSBC", "VanEck"] },
  },

  {
    group: "charac",
    value: "Category",
    name: "Thematic",
    operators: ["=", "display"],
    unit: "",
    condition: {
      type: "select",
      format: "text",
      option: [
        "US Large-Cap Blend Equity",
        "Global Large-Cap Blend Equity",
        "Europe Large-Cap Blend Equity",
        "Eurozone Large-Cap Equity",
        "Large Blend",
        "EUR Government Bond",
        "Global Emerging Markets Equity",
        "Japan Large-Cap Equity",
        "Sector Equity Technology",
        "Large Value",
        "EUR Corporate Bond",
        "Sector Equity Consumer Goods & Services",
        "Diversified Emerging Mkts",
        "Technology",
        "USD Government Bond",
        "Foreign Large Blend",
        "Trading--Inverse Equity",
        "Sector Equity Financial Services",
        "Large Growth",
        "Miscellaneous Region",
        "Trading--Leveraged Equity",
        "Sector Equity Industrial Materials",
        "USD Corporate Bond",
        "UK Large-Cap Equity",
        "Sector Equity Healthcare",
      ],
    },
  },
  { group: "charac", value: "Yield", name: "Dividends (%)", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },

  { group: "charac", value: "leverage", name: "Leverage Ratio", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },
  { group: "charac", value: "MorningStar", name: "Morning Star (Note)", operators: ["=", "display"], unit: "", condition: { type: "select", format: "text", option: ["1", "2", "3", "4", "5"] } },
  { group: "charac", value: "NetExpenseRatio", name: "Net Expense Ratio", operators: [">=", "<=", "display"], unit: "%", condition: { type: "input", format: "number", option: [] } },

  { group: "charac", value: "TotalAssets", name: "Total Assets (M$)", operators: [">=", "<=", "display"], unit: "", condition: { type: "input", format: "number", option: [] } },

  { group: "prop", value: "hasDividend", name: "Has Dividends", operators: ["=", "display"], unit: "", condition: { type: "select", format: "text", option: ["true", "false"] } },
  { group: "prop", value: "hasBond", name: "Has Bonds", operators: ["=", "display"], unit: "", condition: { type: "select", format: "text", option: ["true", "false"] } },
  { group: "prop", value: "hasStock", name: "Has Stock", operators: ["=", "display"], unit: "", condition: { type: "select", format: "text", option: ["true", "false"] } },
  { group: "prop", value: "hasShort", name: "Has Short Positions", operators: ["=", "display"], unit: "", condition: { type: "select", format: "text", option: ["true", "false"] } },
  { group: "prop", value: "hasLeverage", name: "Has Leverage?", operators: ["=", "display"], unit: "", condition: { type: "select", format: "text", option: ["true", "false"] } },
];
