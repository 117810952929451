import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { getHistoricalEarning } from "../../../../../firebase-marketadvisor/firebase-CRUD-earning";
import Loader from "../../../tools/loader";

const HistoricEarning = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [earning, setEarning] = useState();

  useEffect(() => {
    getHistoricalEarning(data.symbol).then((earning) => {
      var labels = [];
      var labelShort = [];
      var datas = [];
      var datasInflation = [];
      if (earning) {
        labels = Object.keys(earning);
        labels.sort(function (a, b) {
          return new Date(b) - new Date(a);
        });
        labels.reverse();

        labelShort = labels.map((year) => {
          return year.slice(0, 4);
        });

        datas = labels.map((year) => {
          return earning[year].epsActual;
        });

        const data = {
          labels: labelShort,
          datasets: [
            {
              label: "Dataset 1",
              data: datas,
              // backgroundColor: "rgba(255, 99, 132, 0.5)",
              backgroundColor: (color) => {
                let colors = color.dataIndex === labelShort.length - 1 ? "#63b6f6" : "#bbdefb";

                return colors;
              },
            },
          ],
        };

        setEarning(data);
        setLoading(false);
      }
    });
  }, []);

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      enabled: false, // <-- this option disables tooltips
    },

    legend: {
      display: false,
    },

    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
            display: true, //this will remove only the label
          },
        },
      ],
    },
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="card col-sm-12 col-md-12 p-l-0 p-r-0">
      <div className="container p-l-0 p-r-0">
        <div className=" " style={{ paddingLeft: "0px !important", paddingRight: "0px !important" }}>
          {/* Section Affichage % */}
          <div className="card-body">
            <h6>Historical Earning </h6>
            <p>{` Discover the last ${earning.labels.length} years of earning for ${data.name} `} </p>
          </div>

          <div className=""></div>
          {/* Section Affichage Courbe */}
          <div className="row m-l-5 m-r-5 m-b-10">
            <div className="col my-auto p-l-0 p-r-0">
              <div className="chart-block" id="myChart">
                <Bar options={options} data={earning} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoricEarning;
