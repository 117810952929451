var cov = require("compute-covariance");

function getStandardDeviationAnnual(array) {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(252) * Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
}

export const volatility = (watchlist) => {
  watchlist.map((element) => {
    const historical = element.price.historical;
    const std = getStandardDeviationAnnual(historical);
  });
};

export const covariance = (watchlist) => {
  // Calculate % per day :
  let minimumDayArray = [];
  let arrayOfHistorical = [];
  let expectedReturn = [];
  let std = [];
  let weight = [];
  let totalInvested = 0;

  watchlist.forEach((ticker) => {
    minimumDayArray.push(ticker.price?.historical.length);
    totalInvested += ticker.invested;
  });
  minimumDayArray.push(252);
  const minimumDay = Math.min(...minimumDayArray);

  watchlist.forEach((ticker) => {
    const tmpArray = ticker.price.historical.slice(-minimumDay);
    let tmpArrayPercent = [];
    for (let i = 1; i < minimumDay; i++) {
      tmpArrayPercent.push((tmpArray[i] - tmpArray[i - 1]) / tmpArray[i - 1]);
    }

    expectedReturn.push(tmpArrayPercent.reduce((a, b) => a + b, 0) / tmpArrayPercent.length);
    weight.push(ticker.invested / totalInvested);
    std.push(getStandardDeviationAnnual(tmpArrayPercent));
    arrayOfHistorical.push(tmpArrayPercent);
  });

  let covariance = cov(arrayOfHistorical, { bias: true });

  // Calcul total result

  let res = 0;
  const size = watchlist.length;
  for (let i = 0; i < size; i++) {
    for (let j = 0; j < size; j++) {
      res += weight[i] * weight[j] * covariance[i][j];
    }
  }
};
