import React, { Fragment, Component, useEffect, useState } from "react";
import { ResponsiveSankey } from "@nivo/sankey";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { Sankey } from "./Sankey2";
import { dataTest } from "./data";

const SankeyDiagramm = ({ data }) => {
  const [dataSankey, setDataSankey] = useState();
  const [activeMenu, setActiveMenu] = useState(1);
  const [rangeSlider, setRangeSlider] = useState({});
  const [selectedYear, setSelectedYear] = useState("TTM");
  const [selectedYearIndex, setSelectedYearIndex] = useState();
  const [negatifEarnings, setNegatifEarning] = useState(false);

  const positiveColor = "#354766"; // text
  const positiveColor2 = "#2599f3";
  const negativeColor = "#FF6978"; // text
  const negativeColor2 = "#FF6978";

  useEffect(() => {
    // sankeyIncomeStatement();
    sankeyIncomeStatement();
    let range = {};
    if (data?.listYearAvailable) {
      let tmpListYear = data.listYearAvailable;
      const size = tmpListYear.length;
      if (size > 10) tmpListYear = tmpListYear.slice(0, 10);

      tmpListYear.map((element, index) => {
        range[tmpListYear.length - index] = element;
      });
      setRangeSlider(range);
      setSelectedYearIndex(tmpListYear.length);
    }
  }, [data]);

  useEffect(() => {
    // sankeyIncomeStatement();
    if (activeMenu === 1) sankeyIncomeStatement();
    if (activeMenu === 2) sankeyBalanceSheet();
    if (activeMenu === 3) sankeyCashFlow();
  }, [activeMenu, selectedYearIndex]);

  const handleChangeSlider = (e) => {
    setSelectedYearIndex(e);
    setSelectedYear(rangeSlider[e]);
  };

  const sankeyIncomeStatement = () => {
    setNegatifEarning(false);
    const financial = data.financial;
    let totalRevenue = parseInt(financial["TTM"]["totalRevenue"] / 1e6);
    let year = rangeSlider[selectedYearIndex];
    if (!year) year = "TTM";

    if (year === "TTM") {
      year = data.listYearAvailable[1];

      if (totalRevenue) {
        year = "TTM";
      }
    }

    // console.log(data);

    const divider = totalRevenue < 50 ? 1e3 : 1e6;
    totalRevenue = parseInt(financial["TTM"]["totalRevenue"] / divider);
    const costOfRevenue = parseInt(financial[year]["costOfRevenue"] / divider);
    const grossProfit = parseInt(financial[year]["grossProfit"] / divider);
    const operatingIncome = parseInt(financial[year]["operatingIncome"] / divider);
    const opEx = parseInt(financial[year]["totalOperatingExpenses"] / divider);
    const netIncome = parseInt(financial[year]["netIncome"] / divider);
    const taxOther = parseInt(operatingIncome - netIncome);

    const researchDev = parseInt(financial[year]["researchDevelopment"] / divider);
    const sellingGen = parseInt(financial[year]["sellingGeneralAdministrative"] / divider);
    const otherExpenses = opEx - sellingGen - researchDev < 0 ? 0 : opEx - sellingGen - researchDev;

    let nodes = [
      {
        category: "1",
        name: "Total Revenue",
        color: positiveColor,
      },
      {
        category: "1",
        name: "R&D",
        color: negativeColor,
      },
      {
        category: "1",
        name: "HR & Other",
        color: negativeColor,
      },
      {
        category: "1",
        name: "Other Expenses",
        color: negativeColor,
      },
      {
        category: "1",
        name: "Cost Of Revenue",
        color: negativeColor,
      },
      {
        category: "1",
        name: "Gross Profit",
        color: positiveColor,
      },
      {
        category: "1",
        name: "Operating Income",
        color: positiveColor,
      },

      {
        category: "1",
        name: "Operating Expenses",
        color: negativeColor,
      },
      {
        category: "1",
        name: "Tax & Other",
        color: negativeColor,
      },

      {
        category: "1",
        name: "Net Income",
        color: positiveColor,
      },
    ];
    let links = [
      {
        source: "Total Revenue",
        target: "Gross Profit",
        value: grossProfit < 0 ? 0 : grossProfit,
        color: positiveColor2,
      },
      {
        source: "Total Revenue",
        target: "Cost Of Revenue",
        value: costOfRevenue < 0 ? 0 : costOfRevenue,
        color: negativeColor2,
      },

      {
        source: "Gross Profit",
        target: "Operating Income",
        value: operatingIncome < 0 ? 0 : operatingIncome,
        color: positiveColor2,
      },
      {
        source: "Gross Profit",
        target: "Operating Expenses",
        value: opEx < 0 ? 0 : opEx,
        color: negativeColor2,
      },
      {
        source: "Operating Expenses",
        target: "R&D",
        value: researchDev,
        color: negativeColor2,
      },
      {
        source: "Operating Expenses",
        target: "HR & Other",
        value: sellingGen,
        color: negativeColor2,
      },
      {
        source: "Operating Expenses",
        target: "Other Expenses",
        value: otherExpenses,
        color: negativeColor2,
      },

      {
        source: "Operating Income",
        target: "Tax & Other", // tax provision pretax income ...
        value: taxOther,
        color: negativeColor2,
      },

      {
        source: "Operating Income",
        target: "Net Income",
        value: netIncome < 0 ? 0 : netIncome,
        color: positiveColor2,
      },
    ];

    if (grossProfit < 0 || operatingIncome < 0 || netIncome < 0) {
      setNegatifEarning(true);
    }

    let dataSanKey = {
      nodes,
      links,
    };
    setDataSankey(dataSanKey);
  };

  const sankeyBalanceSheet = () => {
    setNegatifEarning(false);

    const financial = data.financial;
    const totalRevenue = parseInt(financial["TTM"]["totalRevenue"] / 1e6);
    let year = rangeSlider[selectedYearIndex];
    if (!year) year = "TTM";
    if (year === "TTM") {
      year = data.listYearAvailable[1];

      if (totalRevenue) {
        year = "TTM";
      }
    }
    const divider = totalRevenue < 50 ? 1e3 : 1e6;
    const totalAssets = parseInt(financial[year]["totalAssets"] / divider);
    const totalCurrentAssets = parseInt(financial[year]["totalCurrentAssets"] / divider);
    const cash = parseInt(financial[year]["cashAndShortTermInvestments"] / divider);
    const receivables = parseInt(financial[year]["netReceivables"] / divider);
    const inventory = parseInt(financial[year]["inventory"] / divider);
    const ppe = parseInt(financial[year]["propertyPlantAndEquipmentNet"] / divider);
    const longTermInvestments = parseInt(financial[year]["longTermInvestments"] / divider);

    const goodwill = parseInt(financial[year]["goodWill"] / divider);

    const totalNonCurrentAssets = totalAssets - totalCurrentAssets;
    const otherNCA = totalNonCurrentAssets - goodwill - ppe - longTermInvestments < 0 ? 0 : totalNonCurrentAssets - goodwill - ppe - longTermInvestments;

    const totalLiab = parseInt(financial[year]["totalLiab"] / divider);
    const totalCurrentLiabilities = parseInt(financial[year]["totalCurrentLiabilities"] / divider);
    const totalNonCurrentLiabilities = totalLiab - totalCurrentLiabilities;

    const longTermDebt = parseInt(financial[year]["longTermDebt"] / divider);
    const shortTermDebt = parseInt(financial[year]["shortTermDebt"] / divider);
    const accountsPayable = parseInt(financial[year]["accountsPayable"] / divider);
    const dataSanKey = {
      nodes: [
        {
          name: "Total Assets",
        },
        {
          name: "Cash",
        },
        {
          name: "Receivables",
        },
        {
          name: "Goodwill",
        },
        {
          name: "Inventory",
        },
        {
          name: "Property, Equipment",
        },
        {
          name: "Current Assets",
        },
        {
          name: "Non-Current Assets",
        },
        {
          name: "Other",
        },
        {
          name: "Liabilities",
          color: negativeColor,
        },
        {
          name: "Current Liabilities",
          color: negativeColor,
        },
        {
          name: "Non-Current Liabilities",
          color: negativeColor,
        },
        {
          name: "Investments",
          color: negativeColor,
        },
        {
          name: "Long-Term Debt",
          color: negativeColor,
        },
        {
          name: "Short-Term Debt",
          color: negativeColor,
        },
        {
          name: "Payables",
          color: negativeColor,
        },
      ],
      links: [
        {
          source: "Total Assets",
          target: "Current Assets",
          value: totalCurrentAssets,
        },
        {
          source: "Total Assets",
          target: "Non-Current Assets",
          value: totalNonCurrentAssets,
        },

        {
          source: "Current Assets",
          target: "Cash",
          value: cash,
        },
        {
          source: "Current Assets",
          target: "Receivables",
          value: receivables,
        },

        {
          source: "Current Assets",
          target: "Inventory",
          value: inventory,
        },

        {
          source: "Non-Current Assets",
          target: "Goodwill",
          value: goodwill,
        },

        {
          source: "Non-Current Assets",
          target: "Investments",
          value: longTermInvestments,
        },
        {
          source: "Non-Current Assets",
          target: "Property, Equipment",
          value: ppe,
        },

        {
          source: "Non-Current Assets",
          target: "Other",
          value: otherNCA,
        },

        {
          source: "Liabilities",
          target: "Current Liabilities",
          value: totalCurrentLiabilities,
          color: negativeColor,
        },
        {
          source: "Liabilities",
          target: "Non-Current Liabilities",
          value: totalNonCurrentLiabilities,
          color: negativeColor,
        },

        {
          source: "Non-Current Liabilities",
          target: "Long-Term Debt",
          value: longTermDebt,
          color: negativeColor,
        },
        {
          source: "Current Liabilities",
          target: "Short-Term Debt",
          value: shortTermDebt,
          color: negativeColor,
        },

        {
          source: "Current Liabilities",
          target: "Payables",
          value: accountsPayable,
          color: negativeColor,
        },
      ],
    };

    setDataSankey(dataSanKey);
  };
  const sankeyCashFlow = () => {
    setNegatifEarning(false);
    const financial = data.financial;
    const totalRevenue = parseInt(financial["TTM"]["totalRevenue"] / 1e6);
    let year = rangeSlider[selectedYearIndex];
    if (!year) year = "TTM";
    if (year === "TTM") {
      year = data.listYearAvailable[1];

      if (totalRevenue) {
        year = "TTM";
      }
    }
    const divider = totalRevenue < 50 ? 1e3 : 1e6;

    const totalCashFromOperatingActivities = parseInt(financial[year]["totalCashFromOperatingActivities"] / divider);
    const totalCashFromFinancingActivities = parseInt(financial[year]["totalCashFromFinancingActivities"] / divider);
    const totalCashflowsFromInvestingActivities = parseInt(financial[year]["totalCashflowsFromInvestingActivities"] / divider);
    const changeInCash = parseInt(financial[year]["changeInCash"] / divider);
    const endPeriodCashFlow = parseInt(financial[year]["endPeriodCashFlow"] / divider);
    const beginPeriodCashFlow = parseInt(financial[year]["beginPeriodCashFlow"] / divider);

    const positiveCF =
      (totalCashflowsFromInvestingActivities > 0 ? totalCashflowsFromInvestingActivities : 0) +
      (totalCashFromFinancingActivities > 0 ? totalCashFromFinancingActivities : 0) +
      (totalCashFromOperatingActivities > 0 ? totalCashFromOperatingActivities : 0);
    const negativeCF =
      (totalCashflowsFromInvestingActivities < 0 ? Math.abs(totalCashflowsFromInvestingActivities) : 0) +
      (totalCashFromFinancingActivities < 0 ? Math.abs(totalCashFromFinancingActivities) : 0) +
      (totalCashFromOperatingActivities < 0 ? Math.abs(totalCashFromOperatingActivities) : 0);

    let dataSanKey = {
      nodes: [
        {
          name: "Change",
        },
        {
          name: "Total Cash Flow",
        },
        {
          name: "Beginning Cash",
        },
        {
          name: "End Cash",
        },
        { name: "Operating" },
        { name: "Financing" },
        { name: "Investing" },

        { name: "Positive Cash Flow" },
        { name: "Negative Cash Flow" },
      ],

      links: [
        {
          source: "Operating",
          target: totalCashFromOperatingActivities > 0 ? "Positive Cash Flow" : "Negative Cash Flow",
          value: Math.abs(totalCashFromOperatingActivities),
        },
        {
          source: "Financing",
          target: totalCashFromFinancingActivities > 0 ? "Positive Cash Flow" : "Negative Cash Flow",
          value: Math.abs(totalCashFromFinancingActivities),
        },
        {
          source: "Investing",
          target: totalCashflowsFromInvestingActivities > 0 ? "Positive Cash Flow" : "Negative Cash Flow",
          value: Math.abs(totalCashflowsFromInvestingActivities),
        },
        {
          source: "Beginning Cash",
          target: "End Cash",
          value: beginPeriodCashFlow,
        },

        {
          source: "Positive Cash Flow",
          target: "Total Cash Flow",
          value: positiveCF,
        },
        {
          source: "Negative Cash Flow",
          target: "Total Cash Flow",
          value: negativeCF,
        },

        {
          source: "Total Cash Flow",
          target: "Change",
          value: Math.abs(changeInCash),
        },

        {
          source: changeInCash > 0 ? "Change" : "End Cash",
          target: changeInCash > 0 ? "End Cash" : "Change",
          value: Math.abs(changeInCash),
        },
      ],
    };

    setDataSankey(dataSanKey);
  };
  return (
    <Fragment>
      <div className="card">
        <div className="card-body">
          <h4 style={{ fontSize: "1.25rem", fontWeight: "800", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>FINANCIAL FLUX</h4>

          <div className="elementMenuPageSymbol d-flex justify-content-center" style={{ boxShadow: "none" }}>
            <div
              href="#"
              className={activeMenu === 1 ? "active" : ""}
              onClick={() => {
                setActiveMenu(1);
              }}
            >
              Earnings
            </div>
            <div
              href="#"
              className={activeMenu === 2 ? "active" : ""}
              onClick={() => {
                setActiveMenu(2);
              }}
            >
              Asset/Liabilities
            </div>
            {/* <div
              href="#"
              className={activeMenu === 3 ? "active" : ""}
              onClick={() => {
                setActiveMenu(3);
              }}
            >
              Cash
            </div> */}
          </div>
          {negatifEarnings && (
            <p className="text-center m-t-20" style={{ color: negativeColor, fontWeight: "500" }}>
              As earnings are negative, the following chart may misrepresent financial flows.
            </p>
          )}
          <div className="text-center">{dataSankey && <Sankey data={dataSankey} width={800} height={400} />}</div>
          {/* <div style={{ height: "400px" }}>
            <ResponsiveSankey
              data={dataSankey}
              margin={{ top: 40, right: 120, bottom: 40, left: 100 }}
              // align="justify"
              // colors={{ scheme: "blues" }}
              nodeOpacity={1}
              nodeHoverOthersOpacity={0.35}
              nodeThickness={18}
              nodeSpacing={24}
              nodeBorderWidth={0}
              nodeBorderColor={{
                from: "color",
                modifiers: [["darker", 0.8]],
              }}
              nodeBorderRadius={3}
              linkOpacity={0.5}
              linkHoverOthersOpacity={0.3}
              linkContract={3}
              enableLinkGradient={true}
              labelPosition="outside"
              labelOrientation="horizontal"
              labelPadding={1}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1]],
              }}
              // legends={[
              //   {
              //     anchor: "bottom-right",
              //     direction: "column",
              //     translateX: 130,
              //     itemWidth: 100,
              //     itemHeight: 14,
              //     itemDirection: "right-to-left",
              //     itemsSpacing: 2,
              //     itemTextColor: "#999",
              //     symbolSize: 14,
              //     effects: [
              //       {
              //         on: "hover",
              //         style: {
              //           itemTextColor: "#000",
              //         },
              //       },
              //     ],
              //   },
              // ]}
            />
          </div> */}
          <div className="row  m-20">
            <div className="col-12">
              <Slider min={1} max={Object.keys(rangeSlider).length} marks={rangeSlider} step={null} value={selectedYearIndex} onChange={handleChangeSlider} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SankeyDiagramm;
