import React, { Component, useContext, useEffect, useState } from "react";
import { ETFContext } from "../utils/etfContext";
import Loader from "../../tools/loader";

const CardKeyWords = () => {
  const data = useContext(ETFContext);

  const [dataToDisplay, setDataToDisplay] = useState([]);

  useEffect(() => {
    const listName = ["Sector_Weights", "World_Regions", "Asset_Allocation", "Holdings"];

    let dataTmp = [];

    if (Object.keys(data).length !== 0) {
      listName.forEach((name) => {
        Object.keys(data[name]).forEach((element) => {
          let value = 0;

          if (name === "World_Regions" || name === "Sector_Weights") {
            value = parseInt(10 * parseFloat(data[name][element]["Equity_%"])) / 10;
          }
          if (name === "Holdings") {
            value = parseInt(10 * parseFloat(data[name][element]["Assets_%"])) / 10;
          }
          if (name === "Asset_Allocation") {
            value = parseInt(10 * parseFloat(data[name][element]["Net_Assets_%"])) / 10;
          }

          if (value > 20) {
            dataTmp.push({ name: element, value });
          }
        });
      });

      dataTmp.sort((a, b) => b.value - a.value);

      setDataToDisplay(dataTmp);
    }
  }, [data]);

  if (Object.keys(data).length === 0) {
    return <Loader />;
  } else {
    return (
      <div className="card">
        <div className="card-body">
          <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>MAIN COMPOSITION</h2>
          <h5 className="m-t-10">
            {dataToDisplay.map((element, index) => {
              return (
                <span key={index} className="badge badge-light m-r-5">
                  {element.name}
                </span>
              );
            })}
          </h5>
        </div>
      </div>
    );
  }
};

export default CardKeyWords;
