import { FieldValue, firebase_app } from "../data/config";

export const postMemo = (value) => {
  const uid = firebase_app.auth().currentUser.uid;
  const { symbol, text } = value;

  firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("memo")
    .doc(symbol)
    .update({
      memo: FieldValue.arrayUnion(text),
    })
    .then()
    .catch(() => {
      firebase_app
        .firestore()
        .collection("users")
        .doc(uid)
        .collection("memo")
        .doc(symbol)
        .set({
          memo: [text],
        });
    });
};

export const deleteMemo = (value) => {
  const uid = firebase_app.auth().currentUser.uid;
  const { symbol, text } = value;

  firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("memo")
    .doc(symbol)
    .update({
      memo: FieldValue.arrayRemove(text),
    })
    .then()
    .catch();
};

export const getMemo = async (symbol) => {
  const uid = firebase_app.auth().currentUser.uid;
  var memo = [];
  await firebase_app
    .firestore()
    .collection("users")
    .doc(uid)
    .collection("memo")
    .doc(symbol)
    .get()
    .then((doc) => {
      if (doc.exists) {
        if (doc.data()["memo"] !== undefined) {
          memo = doc.data().memo;
        }
      }
    });

  return memo;
};
