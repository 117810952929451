import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import ChartDataExplorer from "../assetForDataExplorer/chartDataExplorer";
import { calculFinancialData } from "../../../tools/functionCalcFinancialRatio";
import { getScore, getScorePE, getGlobalNote } from "./toolWidget";
import LinearGauge from "./linearGauge";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import ApexGraphNote from "./components/apexGraphNote";

const PriceWidget = ({ dataFinancial, onNote }) => {
  const [note, setNote] = useState({ noteGlobal: "", noteGlobalStyle: "" });
  const [dataTable, setDataTable] = useState({ data: [], columns: [] });

  useEffect(() => {
    // Calcul 1y
    const fin = dataFinancial.financial;
    const year = dataFinancial.year;
    const PE = dataFinancial.PE;
    const { priceEquity, priceSales } = calculFinancialData(fin, year, { marketCap: dataFinancial.marketCap, price: dataFinancial.price });

    var columns = [];
    if (isMobile) {
      columns = [
        {
          name: "Indicator",
          selector: (row) => (
            <div className="p-t-5 p-b-5">
              <strong>{row.indicator}</strong>
              <div>{Math.round(100 * row.value) / 100 + row.symbol}</div>
            </div>
          ),
        },
        {
          name: "VS Sector",
          selector: (row) => {
            if (isNaN(row.averageSector)) return "N/A";
            var position = String(row.score) + "%";
            if (row.score > 90) position = String(90) + "%";
            return <LinearGauge position={position} />;
            // return (100 * Math.round(row.averageSector * 10000)) / 10000 + row.symbol;
          },
        },
      ];
    } else {
      columns = [
        {
          name: "Indicator",
          selector: (row) => <strong>{row.indicator}</strong>,
        },
        {
          name: "Value",
          selector: (row) => Math.round(100 * row.value) / 100 + row.symbol,
        },
        {
          name: "VS Sector",
          selector: (row) => {
            if (isNaN(row.averageSector)) return "N/A";
            var position = String(row.score) + "%";
            if (row.score > 90) position = String(90) + "%";
            return <LinearGauge position={position} />;
          },
        },
      ];
    }

    const data = [
      {
        id: 1,
        indicator: "PE",
        value: PE,
        symbol: "",
        definition:
          "The primary valuation ratio used by most equity investors. The PE ratio can be seen as being expressed in years, in the sense that it shows the number of years of earnings which would be required to pay back the purchase price.",
        plot: false,
        variable: "PE",
        averageSector: "",
        score: getScorePE({ value: PE }),
      },
      {
        id: 2,
        indicator: "Price/Equity",
        value: priceEquity,
        symbol: "",
        definition: "Is a financial ratio used to compare a company's Book Value to its current market price.",
        plot: false,
        variable: "priceEquity",
        averageSector: dataFinancial.averageSector?.priceEquity?.["50%"],
        score: getScore({ average: dataFinancial.averageSector?.priceEquity, value: priceEquity, percent: true }),
      },
      {
        id: 3,
        indicator: "Price/Sales",
        value: priceSales,
        symbol: "",
        definition: "It is the share price of a company divided by its sales per share. A low P/S can indicate a higher profit potential if the stock recovers.",
        plot: false,
        variable: "priceSales",
        averageSector: dataFinancial.averageSector?.priceEquity?.["50%"],
        score: getScore({ average: dataFinancial.averageSector?.priceEquity, value: priceEquity, percent: true }),
      },
    ];

    const { noteGlobal, noteGlobalStyle } = getGlobalNote({ data });
    onNote({ type: "price", value: noteGlobal, style: noteGlobalStyle });

    setNote({ noteGlobal, noteGlobalStyle });
    setDataTable({ data, columns });
  }, [dataFinancial.price]);

  const ExpandedComponent = ({ data }) => {
    if (data.plot) {
      const listYearAvailable = [...dataFinancial.listYearAvailable].reverse();
      let dataPlot = [];

      for (const y of listYearAvailable) {
        dataPlot.push(calculFinancialData(dataFinancial.financial, y)[data.variable]);
      }

      return (
        <div className="m-t-10">
          <div className="m-10">{data.definition}</div>
          <h4 style={{ fontSize: "16px", fontWeight: "600", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>{data.indicator}</h4>
          <ChartDataExplorer datas={{ labels: listYearAvailable, data: dataPlot, unit: data.symbol }} />
        </div>
      );
    } else {
      return (
        <div className="m-t-10">
          <h4 style={{ fontSize: "16px", fontWeight: "600", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>{data.indicator}</h4>
          <div className="m-10">{data.definition}</div>
        </div>
      );
    }
  };
  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#EEEEEE",
        borderBottomColor: "#FFFFFF",
        borderRadius: "25px",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
    <div className="card">
      <div className="card-body" style={{ padding: "15px" }}>
        <h4 style={{ fontSize: "1.25rem", fontWeight: "800", marginTop: "10px", marginBottom: "10px", marginLeft: "10px" }}>Valuation</h4>
        <div className="m-20"></div>
        <div className="row">
          <div className={isMobile ? "col-6 p-r-0" : "col-6 p-r-0"}>
            {/* <div className={`notation ${note.noteGlobalStyle}`}>{note.noteGlobal}</div> */}

            <ApexGraphNote data={{ noteGlobal: note.noteGlobal, noteGlobalStyle: note.noteGlobalStyle }} />
          </div>
          <div className="col">
            <p>Determining the current worth or fair value of a company, to make informed decisions about buying, holding, or selling. </p>
          </div>
        </div>
        {/* Table */}
        <div className="m-20"></div>
        <DataTable columns={dataTable.columns} data={dataTable.data} dense={true} customStyles={customStyles} highlightOnHover expandableRows expandableRowsComponent={ExpandedComponent} />
        <div className="m-20"></div>
      </div>
    </div>
  );
};

export default PriceWidget;
