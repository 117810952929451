import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { firebase_app } from "../../../data/config";
import { getAllSymbolWatchlist } from "../../../firebase-marketadvisor/watchlistSymbol";
import { getNoteSymbolFirebase, saveNoteSymbol } from "../../../firebase-marketadvisor/firebase-CRUD-note";
import { getNoteForTickers } from "../tools/functionCalculNoteTickers";
import { reduceHistoricalToMinimal, transformToDailyReturn, addDailyReturn, getCovariance, simulatePortfolio } from "./functions/functions";
import { Scatter } from "react-chartjs-2";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import CardHeader from "../../common/genericComponent/CardHeader";

const PagePortfolioManagement = () => {
  const [assets, setAssets] = useState([]);
  const [user, setUser] = useState();
  const [watchlist, setWatchlist] = useState([]);
  const [simulatedPortfolio, setSimulatedPortfolio] = useState([]);
  const [currentSelectAsset, setCurrentSelectAsset] = useState("");
  const [portfolioAmount, setPortfolioAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [minimalAllocation, setMinimalAllocation] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    firebase_app.auth().onAuthStateChanged(async (userFB) => {
      if (userFB) {
        setUser(userFB);
        let watchlistRes = await getAllSymbolWatchlist();
        // setCurrentSelectAsset(watchlistRes[0].ticker);
        setWatchlist(watchlistRes);
        if (watchlistRes.length > 0) {
          setCurrentSelectAsset(watchlistRes[0].symbol);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (assets.length > 0) {
      if (minimalAllocation > 60 / assets.length) {
        if (error === false) {
          setError(true);
        }
      } else {
        if (error === true) {
          setError(false);
        }
      }
    } else {
      if (error === true) {
        setError(false);
      }
    }
  }, [assets, minimalAllocation]);

  const handleChangeSelectAsset = (event) => {
    setCurrentSelectAsset(event.target.value);
  };

  const handleChangeAmount = (event) => {
    let { value } = event.target;
    if (value) {
      setPortfolioAmount(parseFloat(value));
    } else {
      setPortfolioAmount("");
    }
  };

  const handleMinimalAllocation = (event) => {
    let { value } = event.target;
    if (value) {
      setMinimalAllocation(parseFloat(value));
    } else {
      setMinimalAllocation("");
    }
  };

  const handleAddAsset = () => {
    let assetsTmp = [...assets];

    if (!assetsTmp.includes(currentSelectAsset)) {
      assetsTmp.push(currentSelectAsset);
      setAssets(assetsTmp);
    }
  };

  const deleteAsset = (assetToDelete) => {
    let assetsTmp = [...assets];
    assetsTmp = assetsTmp.filter((e) => e !== assetToDelete);
    setAssets(assetsTmp);
  };

  const retrieveData = async (ticker) => {
    let { valid, data } = await getNoteSymbolFirebase(ticker.symbol);
    let { note, price, PE } = data;

    if (!valid) {
      let { note, price, PE } = await getNoteForTickers({ symbol: ticker.symbol });
      saveNoteSymbol({ ticker: ticker.symbol, data: { note, price, PE } });
    }

    const elementWatchlist = {
      symbol: ticker.symbol,
      name: ticker.name,
      note,
      price,
    };
    return elementWatchlist;
  };

  const extractHistoric = async () => {
    let myWatchlist = [];
    let watchlistTmp = [...watchlist];
    let promises = [];

    watchlistTmp = watchlistTmp.filter((e) => {
      return assets.includes(e.symbol);
    });

    let countRetrieveData = 0;
    const lengthDataToRetrieve = watchlistTmp.length;

    for (const ticker of watchlistTmp) {
      countRetrieveData += 1;
      promises.push(retrieveData(ticker));
    }
    myWatchlist = await Promise.all(promises);

    return myWatchlist;
  };

  //   const getKeyInsights = (covariance) => {
  //     const size = assets.length;

  //     let maximum = { value: 0, i: 0, j: 0, inverse: false };

  //     for (let i = 0; i < size; i++) {
  //       for (let j = 0; j < size; j++) {
  //         if (i !== j) {
  //           if (maximum.value < Math.abs(covariance[i][j])) {
  //             maximum = { value: Math.abs(covariance[i][j]), i, j, inverse: covariance[i][j] < 0 };
  //           }
  //         }
  //       }
  //     }

  //     console.log(maximum);
  //   };

  const launchAnalysis = async () => {
    let myWatchlist = await extractHistoric();

    myWatchlist = reduceHistoricalToMinimal(myWatchlist);
    myWatchlist = transformToDailyReturn(myWatchlist);
    myWatchlist = addDailyReturn(myWatchlist);
    const covariance = getCovariance(myWatchlist);

    let nbrSimulation = 100000;
    if (myWatchlist.length === 1) nbrSimulation = 1;
    if (myWatchlist.length === 2) nbrSimulation = 10000;
    if (myWatchlist.length === 3) nbrSimulation = 100000;

    const res_simulatedPortfolio = simulatePortfolio(myWatchlist, covariance, nbrSimulation, minimalAllocation);
    // getKeyInsights(covariance);
    setSimulatedPortfolio(res_simulatedPortfolio);
  };

  const dataPlot = {
    datasets: [
      {
        label: "Risk VS Return - Efficient Frontier",
        data: simulatedPortfolio,
        backgroundColor: "rgba(255, 99, 132, 1)",
      },
    ],
  };

  const options = {
    responsive: true,

    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: false,

      callbacks: {
        title: (tooltipItem, data) => {
          //   console.log(data);
          const index = tooltipItem[0].index;

          return "Portfolio " + data.datasets[0].data[index].id;
        },
        label: function (tooltipItem, data) {
          return ` Risk / Return`;
        },
        afterLabel: function (tooltipItem, data) {
          const index = tooltipItem.index;

          return assets.map((ticker, indexAsset) => {
            const weight = data.datasets[0].data[index].weight[indexAsset];
            if (portfolioAmount === 0) {
              return `${ticker}: ${parseInt(100 * weight)} %`;
            } else {
              return `${ticker}: ${parseInt(portfolioAmount * weight)} $`;
            }
          });
        },
      },
    },
  };

  let columns = [
    {
      name: `Portfolio`,
      selector: (row) => row.id,
      sortable: true,
      style: {
        fontSize: "14px",
        fontWeight: "600",
      },
    },
    {
      name: "Expected Return",
      selector: (row) => parseInt(100 * row.y) + " %",
    },
    {
      name: "Risk (Volatility)",
      selector: (row) => parseInt(100 * row.x) + " %",
    },
    {
      name: "Sharpe Ratio",
      selector: (row) => parseInt((100 * row.y) / row.x) / 100,
      sortable: true,
    },
  ];

  assets.map((ticker, index) => {
    columns.push({
      name: ticker,
      selector: (row) => {
        if (portfolioAmount === 0) {
          return parseInt(100 * row.weight[index]) + " %";
        } else {
          return parseInt(portfolioAmount * row.weight[index]) + " $";
        }
      },
    });
  });

  return (
    <Fragment>
      <div className="container p-t-20 ">
        {/* Presentation */}

        <CardHeader title="Portforlio Management" />

        <div className="card m-t-20">
          <div className="card-body ">
            <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>CONFIGURATION</h2>

            <h2 className="m-b-10" style={{ color: "black", fontSize: "1rem" }}>{`PORTFOLIO SETTING`}</h2>
            <p>If amount set to 0, the results will be in %</p>
            <p>The minimal allocation set the minimal allocation per asset in the portfolio.</p>
            <span style={{ color: "black", fontWeight: "500" }}>{"Amount ($):"}</span>
            <div className="input-group input-group-sm m-b-20">
              <div className="input-group-prepend"></div>

              <input
                type="number"
                // onInput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')"
                className="form-control"
                aria-label="Enter the area"
                aria-describedby="inputGroup-sizing-sm"
                onChange={handleChangeAmount}
                // onClick={() => setHeight("")}
                value={portfolioAmount}
              />
            </div>
            <span style={{ color: "black", fontWeight: "500" }}> {"Min. Allocation per Asset(%)"}</span>
            <div className="input-group input-group-sm m-b-20">
              <input
                type="number"
                // onInput="this.value=this.value.replace(/[^0-9.,]+/gmi,'')"
                className="form-control"
                aria-label="Enter the area"
                aria-describedby="inputGroup-sizing-sm"
                onChange={handleMinimalAllocation}
                // onClick={() => setHeight("")}
                value={minimalAllocation}
              />
            </div>
            {/* <span>Test Error</span> */}
            {/* // SELECTION OF ASSET  */}
            <h2 className="m-b-10" style={{ color: "black", fontSize: "1rem" }}>{`ASSETS IN PORTFOLIO`}</h2>
            <p>Only assets in your watchlist are available</p>

            <div className="row m-10">
              {assets.map((e) => {
                // On n'affiche pas les éléments qui ne sont pas dans la list

                return (
                  <div key={e} className="pill-parameters" onClick={() => deleteAsset(e)}>
                    {`${e}`}
                  </div>
                );
              })}
            </div>

            <select className="rbt-input-main form-control rbt-input " value={currentSelectAsset} onChange={handleChangeSelectAsset}>
              {watchlist.map((ticker) => {
                return (
                  <option key={ticker.symbol} value={ticker.symbol}>
                    {ticker.name}
                  </option>
                );
              })}
            </select>

            <div className="row justify-content-md-center">
              <div className="col-6">
                <div className="m-20">
                  <button className="btn btnPrimary btn-block btn-light " onClick={handleAddAsset}>
                    Add
                  </button>
                </div>
                <div className="m-20">
                  <button className={`btn btn-block btnSecondary ${(assets.length === 0 || error) && "btnDisabled"}`} onClick={launchAnalysis} disabled={assets.length === 0 || error}>
                    Start
                  </button>
                </div>

                {error && (
                  <span style={{ color: "red" }}>
                    <FontAwesomeIcon icon={faWarning} />
                    {`  The minimal allocation per asset could not be greater than ${parseInt(60 / assets.length)}%, the calculation need free allocation to find solutions. `}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        {simulatedPortfolio.length > 0 && (
          <div className="card m-t-20">
            <div className="card-body ">
              <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>RESULTS</h2>
              <span>
                {`After a simulation of 100,000 unique portfolio, we keep ${simulatedPortfolio.length} optimised solutions. Those solutions have the best Risk/Return ratio, also called Sharpe Ratio. They create the Efficient Frontier.`}
              </span>
              <span>{` By setting a level of risk or a level of return, you are able to find the best wallet for the selectionned assets. `}</span>

              <div className="chart-block m-t-20 m-b-20" id="myChart" style={{ height: "400px" }}>
                <Scatter height={100} data={dataPlot} options={options} />
              </div>
              <span>{`All simulated portfolio are available here : `}</span>

              <DataTable columns={columns} data={simulatedPortfolio} pagination />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default PagePortfolioManagement;
