import React, { Component, Fragment } from "react";
import DataTable from "react-data-table-component";
import { firebase_app } from "../../../data/config";
import FilterScreener from "./FilterScreener";
import { isMobile } from "react-device-detect";
import RankScreener from "./RankScreener";
import Loader from "../tools/loader";
import { requestAccessToProduct } from "./../../../firebase-marketadvisor/firebase-data-user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { postScreener, getScreenerById } from "../../../firebase-marketadvisor/firebase-screener";
import { listVariable } from "./listVariable";
import { fetchLogoUrl } from "../../../tools/fetchLogoCompany";
import { downloadCSV } from "./exportToCSV";
import ModalSubscribeNow from "../modal/modalSubscribeNow";
import CardHeader from "../../common/genericComponent/CardHeader";
import { Link } from "react-router-dom";

class ScreenerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idScreener: this.props.match.params.idScreener,
      restricted: false,
      extraColumn: [],
      screener: [],
      ranking: { listRank: [], typeRank: "arithmetic" },
      saveAvailable: false,
      loading: true,
      query: [],
      nameScreener: "My Custom Screener",
    };
  }

  saveScreener = () => {
    postScreener({ user: this.state.user, filter: this.state.query, ranking: this.state.ranking, name: this.state.nameScreener });
    this.setState({ saveAvailable: false });
  };

  componentDidMount = () => {
    firebase_app.auth().onAuthStateChanged((user) => {
      if (user) {
        if (this.state.idScreener) {
          this.setState({ loading: true });
          getScreenerById(this.state.idScreener).then((res) => {
            const { filter, ranking, exist } = res;
            if (exist) {
              this.setState({ user, query: filter, ranking }, this.handleChangeFilter({ listFilter: filter, typeFilter: "idScreener", user }));
              this.scrollToBottom();
            } else {
              this.setState({ user, loading: false, idScreener: "" });
            }
          });
        } else {
          this.setState({ user, loading: false, idScreener: "" });
        }
      }
    });
  };

  scrollToBottom() {
    this.el.scrollIntoView({ behavior: "smooth" });
  }

  convertNote = (note) => {
    if (note >= 0.75) return "A+";
    if (note >= 0.5) return "B";
    if (note >= 0.25) return "C";
    if (note >= 0) return "D";
  };

  handleChangeCondition = (event) => {
    const input = event.target.value;
    this.setState({ nameScreener: input, saveAvailable: true });
  };
  // Handle Change in Ranking

  handleChangeRanking = ({ listRank, typeRank, screener, extraArg }) => {
    var screenerTmp = screener;
    if (screener === undefined) {
      screenerTmp = [...this.state.screener];
    }

    var arrayTmp = 0;
    const lengthScreener = screenerTmp.length;
    // Initialisation
    for (const element of screenerTmp) {
      element.rank = [];
    }

    // Loop Ranking
    var indexCondition = 0;
    listRank.forEach((rankCondition, index) => {
      // Convert to number
      for (const element of screenerTmp) {
        element[rankCondition.variable] = parseFloat(element[rankCondition.variable]);
      }

      // Sort

      if (rankCondition.operator === "Ascending") {
        screenerTmp.sort((a, b) => {
          return a[rankCondition.variable] - b[rankCondition.variable];
        });
      }
      if (rankCondition.operator === "Descending") {
        screenerTmp.sort((a, b) => {
          return b[rankCondition.variable] - a[rankCondition.variable];
        });
      }
      //

      // Add Note

      var index = 1;
      for (const element of screenerTmp) {
        element.rank.push(index);
        index = index + 1;
      }
    });

    // Classement Global

    for (const element of screenerTmp) {
      arrayTmp = element.rank;
      if (typeRank === "arithmetic") {
        element.rank = arrayTmp.reduce((a, b) => a + b, 0) / arrayTmp.length;
      }
      if (typeRank === "geometric") {
        element.rank = arrayTmp.reduce((a, b) => a * b, 1);
      }
    }
    screenerTmp.sort((a, b) => {
      return a.rank - b.rank;
    });

    this.setState({ loading: false, screener: screenerTmp, saveAvailable: true, ranking: { listRank, typeRank }, ...extraArg });
  };

  // Handle the change in the Filter (children element)

  handleChangeFilter = ({ listFilter, typeFilter, user }) => {
    if (!user) {
      user = this.state.user;
    }

    requestAccessToProduct({ user, product: "screener" }).then((authorisation) => {
      if (authorisation) {
        this.setState({ loading: true });
        var extraColumn = [];
        var link = "";
        var exchangeName = "";
        var specificCase = false;
        var unit = "";

        listFilter.forEach((element) => {
          specificCase = false;

          // Handle cas particuliers - Market Capitalisation - Entreprise Value
          if (element.variable === "EV" || element.variable === "marketCap") {
            specificCase = true;

            extraColumn.push({
              name: element.name,
              selector: (row) => {
                const value = row[String(element.variable)];

                if (!isNaN(value)) {
                  // return String((parseFloat(value) / 1000000).toFixed(0)).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "M " + row["Currency"];
                  return String((parseFloat(value) / 1000000).toFixed(0)) + "M " + row["Currency"];
                } else {
                  return value;
                }
              },
            });
          }
          // Handle cas particuliers - Exchanges
          if (String(element.id).includes("exchange")) {
            specificCase = true;
            if (element.name === "eToro") {
              link = "https://www.etoro.com/markets/";
              exchangeName = "eToro";
            }
            if (element.name === "fidelity") {
              link = "https://digital.fidelity.com/prgw/digital/research/quote/dashboard/summary?symbol=";
              exchangeName = "Fidelity";
            }
            if (element.name === "tdameritrade") {
              link = "https://research.tdameritrade.com/grid/public/research/stocks/summary?symbol=";
              exchangeName = "TD Ameritrade";
            }
            if (element.name === "robinhood") {
              link = "https://robinhood.com/stocks/";
              exchangeName = "RobinHood";
            }

            extraColumn.push({
              name: "Exchange",
              selector: (row) => (
                <a href={link + row.Symbol} target="_blank" rel="noopener noreferrer">
                  {exchangeName + " "}
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  {/* {row[String(element.variable)] ? "" : ""} */}
                </a>
              ),
            });
          }
          // Cas normaux
          if (specificCase === false) {
            if (true) {
              // if (!["ADR", "addressCountry"].includes(element.variable))
              extraColumn.push({
                name: element.name,
                selector: (row) => {
                  const value = row[String(element.variable)];
                  const unit = listVariable.filter((e) => e.value === element.variable)[0]?.unit;
                  if (!isNaN(value)) {
                    return String(parseFloat(value).toFixed(2)) + " " + unit;
                  } else {
                    return value;
                  }
                },
              });
            }
          }

          // Delete duplicate
          extraColumn = extraColumn.filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i);
        });

        // Extraction de la données
        const getScreener = firebase_app.functions().httpsCallable("getScreenerBigQuery");
        getScreener({ listQuery: listFilter })
          .then((e) => {
            var ranking = this.state.ranking;
            const screener = e.data;

            if (typeFilter === "marketadvisor") {
              // Test
              this.handleChangeRanking({
                listRank: [{ variable: "Note", operator: "Descending" }],
                typeRank: "arithmetic",
                screener,
                extraArg: { loading: false, query: listFilter, extraColumn, saveAvailable: true },
              });
            }

            if (typeFilter !== "marketadvisor") {
              // On conserve le ranking actuel, mais on le met à jour si des variables sont supprimés
              const localVariableInFilter = listFilter.map((e) => e.variable);

              if (ranking.listRank.length > 0) {
                var listRank = ranking.listRank.filter((e) => localVariableInFilter.includes(e.variable));
              } else {
                var listRank = ranking.listRank;
              }

              ranking = { listRank, typeRank: ranking.typeRank };

              this.handleChangeRanking({
                listRank: ranking.listRank,
                typeRank: ranking.typeRank,
                screener,
                extraArg: { loading: false, query: listFilter, extraColumn, saveAvailable: true },
              });
            }
          })
          .catch((e) => {});
      } else {
        this.setState({ restricted: true });
      }
    });
  };

  render() {
    const columns = [
      {
        name: "Symbol",
        selector: (row) => {
          const symbol = row.Symbol;

          var logoPath = fetchLogoUrl(symbol);

          return (
            <div className="row m-l-5">
              <div className="col-3" style={{ display: "contents" }}>
                <Link to={"/symbol/" + symbol}>
                  <img
                    src={logoPath}
                    alt="new"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "https://storage.googleapis.com/marketadvisor-logo/noLogo.png";
                    }}
                    style={{ maxWidth: "40px", maxHeight: "35px" }}
                  />
                </Link>
              </div>
              <div className={isMobile ? "col-8 p-0" : "col-8"} style={isMobile ? { textAlign: "center" } : {}}>
                <Link to={"/symbol/" + symbol} style={{ color: "#2f4050" }}>
                  <div>{`${symbol}`}</div>

                  <div style={{ fontWeight: "lighter", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{`${row.Name}`}</div>
                </Link>
              </div>
            </div>
          );
        },
      },
    ];

    if (this.state.restricted) {
      return <ModalSubscribeNow show={true} />;
    }
    return (
      <Fragment>
        <div onClick={this.saveScreener} className={`${this.state.saveAvailable ? "sticky-save" : "sticky-save sticky-save-hide "} `}>
          Save
        </div>

        {/* Presentation du screener  */}
        <div className="container p-t-20">
          <CardHeader
            title={"Screener"}
            description={
              " MarketAdvisor is a powerful tool designed to help investors identify the best investment opportunities from a vast universe of over 40,000 stocks. With a comprehensive range of filters, MarketAdvisor allows you to delve into the details and quickly narrow down your options to find the most promising investment opportunities."
            }
          />
        </div>

        {/* Filter Version 2 */}
        <FilterScreener onChangeFilter={this.handleChangeFilter} initialConfig={this.state.query} />

        {/* Ranking */}
        <RankScreener listFilter={this.state.query} onChangeRanking={this.handleChangeRanking} initialConfig={this.state.ranking} />
        {/* Filter  */}

        {this.state.loading ? (
          <div className="container">
            <Loader />
          </div>
        ) : (
          <div>
            {/* <LoaderBlurFullScreen /> */}
            {(this.state.screener.length !== 0 || this.state.query.length > 0) && (
              <div className="container">
                <div className="card">
                  <div className="card-body">
                    <h2 style={{ color: "#1098F7", fontSize: "1rem" }}>SCREENER</h2>
                    <h2 className="m-t-20 " style={{ color: "black", fontSize: "1rem" }}>
                      STATISTICS
                    </h2>
                    <p>{`${this.state.screener.length} ticker(s) are available.`}</p>

                    <div className="row m-b-20">
                      <div className="col-4 my-auto">
                        <input className="rbt-input-main form-control rbt-input " onChange={this.handleChangeCondition} value={this.state.nameScreener} type="text"></input>
                      </div>
                      <div className="col-4 my-auto">
                        <button className={`btn btn-block btnSecondary ${!this.state.saveAvailable && "btnDisabled"}`} disabled={!this.state.saveAvailable} onClick={this.saveScreener}>
                          {"Save Screener Setting"}
                        </button>
                      </div>
                      <div className="col-4 my-auto">
                        <button className={`btn btn-block btnSecondary`} onClick={() => downloadCSV(this.state.screener)}>
                          {"Export to CSV"}
                        </button>
                      </div>
                    </div>

                    <DataTable columns={columns.concat(this.state.extraColumn)} data={this.state.screener.length > 50 ? this.state.screener.slice(0, 50) : this.state.screener} pagination />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div
          ref={(el) => {
            this.el = el;
          }}
        />
      </Fragment>
    );
  }
}

export default ScreenerPage;
